import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
        },
        wrapper: { flex: 1 },
        content: {
            flex: 1,
            paddingHorizontal: scale(20),
            justifyContent: 'center',
        },
        button: {
            marginTop: verticalScale(16),
            alignSelf: 'center',
        },
        buttonColor: {
            color: Colors[theme].text.secondary,
        },
    });

export default styles;
