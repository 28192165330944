import * as React from 'react';

import { Text, View } from '@components/Themed';
import { useDimensions } from '@contexts/DimensionsContext';
import { waitForNavigation } from '@helpers/helpers';
import { isPaymentOptionInAppPurchase } from '@helpers/payments';
import { useNavigation } from '@navigation/useNavigation';

import StepOneImage from '../../../../assets/icons/mysteryBoxes/welcome/stepOne.svg';
import StepThreeImage from '../../../../assets/icons/mysteryBoxes/welcome/stepThree.svg';
import StepTwoImage from '../../../../assets/icons/mysteryBoxes/welcome/stepTwo.svg';
import BlockHeader from '../../../../components/BlockHeader/BlockHeader';
import ButtonsBottom from '../../../../components/ButtonsBottom';
import Carousel from '../../../../components/Carousel';
import { ICON_NAMES } from '../../../../components/Icons';
import CoinIcon from '../../../../components/Icons/CoinIcon';
import useMysteryBox from '../../../../hooks/mysteryBoxes/useMysteryBox';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import NavigationBarModal from '../../../../navigation/NavigationBar/NavigationBarModal';
import useNavigationListeners from '../../../../navigation/hooks/useNavigationListeners';
import ROUTES from '../../../../navigation/routes';
import stylesMain from './styles';

const data = ['stepOne', 'stepTwo', 'stepThree'];

const MysteryBoxesPurchaseWelcomeScreen = () => {
    useNavigationListeners();
    const { windowWidth } = useDimensions();
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const { parsedPrice, parsedToken, parsedTokenString, paymentOptions } =
        useMysteryBox();

    const isInAppPurchase = isPaymentOptionInAppPurchase(
        paymentOptions[0]?.payments[0]
    );

    const navigateToPurchase = async () => {
        navigation.pop();
        await waitForNavigation();

        navigation.navigate(ROUTES.MYSTERY_BOX_PURCHASE, {
            selectedOption: paymentOptions[0],
            allAvailableOptions: paymentOptions,
        });
    };

    const priceLoaded = React.useMemo(
        () => !!parsedPrice && !!parsedToken,
        [parsedPrice, parsedToken]
    );

    const renderItem = (item: string) => {
        const icon = () => {
            switch (item) {
                case 'stepOne':
                    return <StepOneImage />;
                case 'stepTwo':
                    return <StepTwoImage />;
                default:
                    return <StepThreeImage />;
            }
        };

        return (
            <View style={styles.itemContainer}>
                <View style={styles.content}>
                    <View style={styles.imgWrapper}>{icon()}</View>
                    <View
                        style={[
                            styles.price,
                            !priceLoaded && { backgroundColor: 'transparent' },
                        ]}>
                        {priceLoaded && (
                            <>
                                <Text
                                    style={
                                        styles.priceText
                                    }>{`${parsedPrice} ${parsedTokenString}`}</Text>
                                {!isInAppPurchase && (
                                    <CoinIcon slug={parsedToken} size={24} />
                                )}
                            </>
                        )}
                    </View>
                    <BlockHeader
                        title={i18n.t(
                            `mysteryBoxes.purchaseWelcome.${item}.title`
                        )}
                        text={i18n.t(
                            `mysteryBoxes.purchaseWelcome.${item}.text`
                        )}
                        type="emptyState"
                    />
                </View>
            </View>
        );
    };

    return (
        <View style={styles.main}>
            <View style={styles.header}>
                <NavigationBarModal
                    iconName={ICON_NAMES.CLOSE}
                    title=""
                    onBack={() => navigation.pop()}
                />
            </View>
            <Carousel
                data={data}
                sliderWidth={windowWidth}
                itemWidth={windowWidth}
                renderItem={(item) => renderItem(item)}
                fullHeight
                pagination
                centredVertical
            />
            <ButtonsBottom
                containerStyle={styles.buttonContainer}
                title={i18n.t('mysteryBoxes.purchaseWelcome.button')}
                onPress={navigateToPurchase}
                safe
                loading={!priceLoaded}
                disabled={!priceLoaded}
            />
        </View>
    );
};

export default MysteryBoxesPurchaseWelcomeScreen;
