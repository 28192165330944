import * as React from 'react';

import Svg from '../../assets/icons/buttons/like.svg';
import { IconType, iconSize } from './types';

const Like: React.FunctionComponent<IconType> = ({
    size = iconSize.default,
}) => <Svg height={size} width={size} />;

export default Like;
