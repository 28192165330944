import * as React from 'react';
import { useMemo } from 'react';
import { TouchableOpacity } from 'react-native';

import { PaymentOptionOutput, UserBalance } from '@Data/Models';
import { useWallet } from '@contexts/Wallet/WalletContext';
import {
    getPaymentTokenName,
    isPaymentOptionInAppPurchase,
} from '@helpers/payments';
import { balanceFromWei } from '@helpers/wallet';

import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import Button from '../../Button';
import Icon, { ICON_NAMES } from '../../Icons';
import CoinIcon from '../../Icons/CoinIcon';
import { Text, View } from '../../Themed';
import stylesMain from './styles';

interface IProps {
    payment: PaymentOptionOutput;
    userBalance?: UserBalance | undefined;
    isError: boolean;
    isBNBEnough: boolean;
    onPress?: () => void;
    onDepositPress: () => void;
    withDropDown?: boolean;
    disabled?: boolean;
}

const PurchasingFromInput = ({
    payment,
    userBalance,
    isError,
    isBNBEnough,
    onPress,
    onDepositPress,
    withDropDown,
    disabled,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { getCoinBalances } = useWallet();
    const isInAppPurchase = isPaymentOptionInAppPurchase(payment);

    const currentBalance = useMemo(() => {
        if (payment.tokenAddress) {
            const coinBalance = getCoinBalances().find(
                (value) => value.name === payment.token
            );
            return coinBalance?.presentationValueLong;
        }

        return userBalance?.total
            ? balanceFromWei(userBalance?.total).valueLong
            : '';
    }, [userBalance, payment, getCoinBalances]);

    const renderErrorText = () => {
        if (isError && payment.tokenAddress) {
            if (isBNBEnough) {
                return (
                    <Text style={styles.errorText}>
                        {i18n.t(
                            `checkout.errors.notEnoughCoinFunds${
                                withDropDown ? 'Multi' : ''
                            }`
                        )}
                    </Text>
                );
            }

            return (
                <Text style={styles.errorText}>
                    {i18n.t(`checkout.errors.bnbNotEnough`)}
                </Text>
            );
        }

        if (isError && !payment.tokenAddress) {
            return (
                <Text style={styles.errorText}>
                    {i18n.t(
                        `checkout.errors.notEnoughBalanceFunds${
                            withDropDown ? 'Multi' : ''
                        }`
                    )}
                </Text>
            );
        }

        return null;
    };

    const renderDepositButton = () => {
        if ((isError || !isBNBEnough) && payment.tokenAddress) {
            return (
                <Button
                    containerStyle={styles.deposit}
                    icon={
                        <Icon
                            name={ICON_NAMES.PLUS}
                            color={styles.iconGreen.color}
                        />
                    }
                    onPress={onDepositPress}
                    title={i18n.t('checkout.deposit')}
                    type="text"
                    size="md"
                />
            );
        }

        return null;
    };

    return (
        <View style={styles.purchasingInfoWrapper}>
            <View style={styles.purchasingInfoTextWrapper}>
                <Text style={styles.purchasingTitle}>
                    {i18n.t('checkout.purchasingFrom')}
                </Text>
                {!isInAppPurchase && (
                    <Text style={styles.purchasingInfo}>
                        {`${i18n.t('checkout.available')}: `}
                        {!!payment && (
                            <Text style={styles.purchasingValue}>
                                {`${currentBalance || ''} ${payment.token}`}
                            </Text>
                        )}
                    </Text>
                )}
            </View>
            {!!payment && (
                <>
                    <TouchableOpacity
                        style={[styles.walletSelector, isError && styles.error]}
                        activeOpacity={0.8}
                        onPress={onPress}
                        disabled={disabled}>
                        <View style={styles.walletContainer}>
                            <View style={styles.walletContainer}>
                                <CoinIcon slug={payment.token} size={20} />
                                <Text style={styles.walletName}>
                                    {isInAppPurchase
                                        ? getPaymentTokenName(payment.token)
                                        : i18n.t('checkout.wallet', {
                                              coin: payment.token,
                                          })}
                                </Text>
                            </View>
                            {withDropDown && (
                                <Icon
                                    name={ICON_NAMES.ARROW_DROP_DOWN}
                                    size={16}
                                />
                            )}
                        </View>
                    </TouchableOpacity>
                    {renderErrorText()}
                </>
            )}
            {renderDepositButton()}
        </View>
    );
};
export default PurchasingFromInput;
