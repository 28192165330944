import * as React from 'react';
import { FlatList, TouchableOpacity } from 'react-native';

import ModalBottom from '../../../../../components/ModalBottom';
import Selector from '../../../../../components/Selector';
import { Text, View } from '../../../../../components/Themed';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import stylesMain from './styles';

const FILTERS = ['Month', 'Week', 'Day'];

interface IProps {
    onSelect: (item: string) => void;
    selectedItem: string | undefined;
}

const Filter = ({ onSelect, selectedItem }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const [filterModalVisible, setFilterModalVisible] = React.useState(false);
    const showFilterModal = () => {
        setFilterModalVisible(true);
    };
    const hideFilterModal = () => {
        setFilterModalVisible(false);
    };

    const renderItem = React.useCallback(
        (item: string, index: number) => {
            const isSelected = item === selectedItem;
            return (
                <TouchableOpacity
                    activeOpacity={0.7}
                    onPress={() => {
                        onSelect(item);
                        hideFilterModal();
                    }}
                    style={[
                        styles.shadow,
                        index === 0 && styles.gap,
                        isSelected && styles.active,
                    ]}>
                    <View
                        style={[
                            styles.itemContainer,
                            isSelected && styles.activeContainer,
                        ]}>
                        <View style={styles.main}>
                            <Text style={styles.itemText}>
                                {i18n.t(`general.FILTERS.byDate.${item}`)}
                            </Text>
                        </View>
                    </View>
                </TouchableOpacity>
            );
        },
        [selectedItem, styles]
    );

    return (
        <Selector
            text={i18n.t(`general.FILTERS.byDate.${selectedItem}`)}
            onPress={showFilterModal}>
            <ModalBottom
                titleText={i18n.t('general.KEYS.sortedBy')}
                modalHeight={'50%'}
                isVisible={filterModalVisible}
                onClose={hideFilterModal}
                swipeDirection={'down'}>
                <FlatList
                    showsVerticalScrollIndicator={false}
                    data={FILTERS}
                    keyExtractor={(item) => item}
                    renderItem={({ item, index }) => renderItem(item, index)}
                />
            </ModalBottom>
        </Selector>
    );
};
export default Filter;
