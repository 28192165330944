import { StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import { scale } from '../../../../helpers/dimensions';
import font from '../../../../helpers/font';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {},
        imageContainer: {
            paddingHorizontal: scale(16),
            position: 'absolute',
            marginTop: 10,
            left: 0,
            right: 0,
        },
        image: {
            height: 100,
        },
        arrowIcon: {
            alignItems: 'center',
        },
        text: {
            textAlign: 'center',
            color: Colors[theme].text.primary,
            ...font({
                size: 12,
                height: 16,
                weight: '600',
            }),
        },
        arrowText: {
            textAlign: 'center',
            color: Colors[theme].text.primary,
            ...font({
                size: 11,
                height: 16,
                weight: '600',
            }),
        },
        indicatorStyle: {
            alignSelf: 'center',
            marginTop: -60,
            height: 60,
            justifyContent: 'center',
        },
    });

export default styles;
