import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        flex: {
            flex: 1,
        },
        container: {
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            marginHorizontal: scale(20),
            borderRadius: moderateScale(12),
            overflow: 'hidden',
        },
        bottomBorder: {
            borderBottomWidth: 1,
            borderColor: Colors[theme].border.primary,
        },
        button: {
            padding: scale(16),
        },
        text: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                weight: '600',
            }),
        },
    });

export default styles;
