import React from 'react';

import { dateCooldownExpired } from '@helpers/time';

export const useStakeFinishHook = (endDate: string) => {
    const [isStakeFinish, setIsStakeFinish] = React.useState(false);

    React.useEffect(() => {
        setIsStakeFinish(dateCooldownExpired(endDate));
    }, [endDate]);

    const setStakeFinish = React.useCallback(() => {
        if (isStakeFinish) {
            return;
        }
        setIsStakeFinish(true);
    }, [isStakeFinish]);

    return {
        setStakeFinish,
        isStakeFinish,
    };
};
