import ModalConfirmTransaction from '@components/ModalConfirmTransaction';
import TransactionSummary from '@components/TransactionSummary';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { dateFormatterYear, getFinishAt } from '@helpers/time';
import { feeWithCurrency } from '@helpers/wallet';
import useFeeInfo from '@hooks/useFeeInfo';
import i18n from '@i18n/i18n';

interface IProps {
    coin: string;
    onPress: () => void;
    close: () => void;
    setIsVisibleConfirmationModal: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    isVisible: boolean;
    stakingAmount: string | undefined;
    stakingBoost: number | string | null;
    durationDays: number;
    name: string;
    screen?: 'dailyBoostScreen' | 'alreadyScreen';
}

const StakingConfirmationModal = ({
    coin,
    onPress,
    close,
    setIsVisibleConfirmationModal,
    isVisible,
    stakingAmount,
    stakingBoost,
    durationDays,
    name,
    screen = 'dailyBoostScreen',
}: IProps) => {
    // fee info modalScreen
    const { renderFeeInfoIcon } = useFeeInfo(
        () => setIsVisibleConfirmationModal(false),
        () => setIsVisibleConfirmationModal(true)
    );

    const { fee } = useWallet();

    const transactionDetailsValues = [
        {
            key: i18n.t(`staking.${screen}.confirmationModal.keys.from`),
            value: i18n.t(`staking.${screen}.confirmationModal.values.from`),
        },
        {
            key: i18n.t(`staking.${screen}.confirmationModal.keys.duration`),
            value: i18n.t(
                `staking.${screen}.confirmationModal.values.duration`,
                {
                    plan: name,
                    days: durationDays,
                }
            ),
        },
        {
            key: i18n.t(`staking.${screen}.confirmationModal.keys.endingOn`),
            value: dateFormatterYear(getFinishAt(durationDays)),
        },
        {
            key: i18n.t(`staking.${screen}.confirmationModal.keys.dailyBoost`),
            value: `${stakingBoost}%`,
        },
        {
            key: i18n.t(`staking.${screen}.confirmationModal.keys.summary`),
            value: `${stakingAmount} ${coin}`,
        },
        {
            key: i18n.t('checkout.fields.fee'),
            value: feeWithCurrency(fee?.transferCoin[coin as keyof object]),
            icon: renderFeeInfoIcon(),
        },
    ];

    return (
        <ModalConfirmTransaction
            title={i18n.t(`staking.${screen}.confirmationModal.title`)}
            isVisible={isVisible}
            close={() => setIsVisibleConfirmationModal(false)}
            onConfirm={onPress}
            onCancel={close}
            TransactionDetailsComponent={
                <TransactionSummary values={transactionDetailsValues} />
            }
            modalHeight={transactionDetailsValues.length * 120}
        />
    );
};
export default StakingConfirmationModal;
