import * as React from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';

import _ from 'lodash';

import { useHaptic } from '@hooks/useHaptic';
import useThemedStyles from '@hooks/useThemedStyles';

import { ComponentAnyType } from '../../types';
import ActivityIndicator from '../ActivityIndicator';
import Icon, { ICON_NAMES } from '../Icons';
import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    title: string;
    onPress: () => void;
    disabled?: boolean;
    containerStyle?: StyleProp<ViewStyle>;
    iconName?: ICON_NAMES | null;
    icon?: React.ReactElement<SvgProps>;
    labelCount?: number;
    debouncedPress?: boolean;
    debounceTime?: number;
    loading?: boolean;
    loadingText?: string;
    withHaptic?: boolean;
}

const ButtonCard = ({
    title,
    onPress,
    disabled,
    containerStyle,
    iconName,
    icon,
    labelCount,
    debouncedPress,
    debounceTime = 2000,
    loading,
    loadingText,
    withHaptic = true,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { triggerMedium } = useHaptic();
    const onPressRef = React.useRef(onPress);

    React.useEffect(() => {
        onPressRef.current = onPress;
    }, [onPress]);

    const handlePress = async () => {
        if (withHaptic) await triggerMedium();
        onPressRef.current();
    };

    // debounce callback
    const handleDebounce = React.useCallback(
        _.debounce(
            () => {
                handlePress();
            },
            debounceTime,
            {
                leading: true,
                trailing: false,
            }
        ),
        [withHaptic, triggerMedium, debounceTime]
    );

    return (
        <>
            <TouchableOpacity
                activeOpacity={0.7}
                disabled={disabled || loading}
                style={[styles.shadow, containerStyle]}
                onPress={debouncedPress ? handleDebounce : handlePress}>
                {disabled && <View style={styles.disabled} />}
                {loading && (
                    <View style={styles.overlay}>
                        <ActivityIndicator loading={loading} />
                        {loadingText && (
                            <Text style={[styles.text, styles.loadingText]}>
                                {loadingText}
                            </Text>
                        )}
                    </View>
                )}
                <View style={styles.containerButton}>
                    {!!labelCount && (
                        <View style={styles.staked}>
                            <Text style={styles.stakedText}>{labelCount}</Text>
                        </View>
                    )}
                    <View style={styles.buttonShadow}>
                        <View style={styles.icon}>
                            {iconName ? <Icon name={iconName} /> : icon}
                        </View>
                    </View>
                    <Text style={styles.text}>{title}</Text>
                </View>
            </TouchableOpacity>
        </>
    );
};

export default ButtonCard;
