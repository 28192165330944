import * as React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { Text } from 'react-native';

import { LinearGradient } from 'expo-linear-gradient';

import MysteryBox from '../../assets/icons/mysteryBoxes/box.svg';
import TimeIcon from '../../assets/icons/mysteryBoxes/time.svg';
import { useDimensions } from '../../contexts/DimensionsContext';
import { verticalScale } from '../../helpers/dimensions';
import useThemedStyles from '../../hooks/useThemedStyles';
import i18n from '../../i18n/i18n';
import Timer from '../Timer';
import stylesMain from './styles';

interface IProps {
    onPress: () => void;
    isComingSoon?: {
        time: string;
        onEnd: () => void;
    };
    isNotAvailable?: boolean;
}

const BannerMysteryBox = ({
    onPress,
    isComingSoon,
    isNotAvailable,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { windowWidth } = useDimensions();

    const itemWidth = React.useMemo(
        () => windowWidth - verticalScale(20) * 2,
        [windowWidth]
    );

    const renderItem = React.useCallback(() => {
        if (isComingSoon) {
            return (
                <>
                    <Text style={styles.title}>
                        {i18n.t('mysteryBoxes.banner.isComing.title')}
                    </Text>
                    <View style={styles.timer}>
                        <TimeIcon />
                        <Timer
                            showDays
                            endOfTime={isComingSoon.time}
                            onEndTime={isComingSoon.onEnd}
                            timerStyles={[styles.text, styles.gap]}
                        />
                        <Text style={styles.text}>
                            {i18n.t('mysteryBoxes.banner.isComing.text')}
                        </Text>
                    </View>
                </>
            );
        }

        return (
            <>
                <Text style={styles.title}>
                    {isNotAvailable
                        ? i18n.t('mysteryBoxes.banner.isNotAvailable.title')
                        : i18n.t('mysteryBoxes.banner.isStart.title')}
                </Text>
                <Text style={styles.text}>
                    {isNotAvailable
                        ? i18n.t('mysteryBoxes.banner.isNotAvailable.text')
                        : i18n.t('mysteryBoxes.banner.isStart.text')}
                </Text>
            </>
        );
    }, [isComingSoon, isNotAvailable]);

    return (
        <TouchableOpacity
            activeOpacity={0.9}
            onPress={onPress}
            style={styles.mysteryBoxBanner}>
            <View style={[styles.content, { width: itemWidth }]}>
                <LinearGradient
                    colors={['#A77FFD', '#FEDCA9']}
                    style={[styles.gradientBackground, { width: itemWidth }]}
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 1 }}>
                    <View style={styles.left}>{renderItem()}</View>
                    <View style={styles.right}>
                        <View style={styles.image}>
                            <MysteryBox />
                        </View>
                    </View>
                </LinearGradient>
            </View>
        </TouchableOpacity>
    );
};
export default BannerMysteryBox;
