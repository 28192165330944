import * as React from 'react';
import { Image, StyleProp, ViewStyle } from 'react-native';

import Participants from '@assets/icons/mysteryGames/LuckyStrike/participants.svg';
import Time from '@assets/icons/mysteryGames/LuckyStrike/time.svg';
import Button from '@components/Button';
import { ICON_NAMES } from '@components/Icons';
import CoinIcon from '@components/Icons/CoinIcon';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT, TYPO_TEXT_COLOR } from '@components/TypoText/types';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { balanceFromWei } from '@helpers/wallet';
import { getAmountOfBoughtTickets } from '@hooks/useLuckyStrikePendingGames';
import useThemedStyles from '@hooks/useThemedStyles';
import { useTimer } from '@hooks/useTimer';
import i18n from '@i18n/i18n';
import {
    LuckyStrikeAvailableGameOutput,
    MYSTERY_GAMES,
} from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import { maxParticipants } from '../../LuckyStrikeFlow/helpers';
import DecorativeTag from '../DecorativeTag';
import stylesMain from './styles';

interface IProps {
    containerStyle?: StyleProp<ViewStyle>;
    isLast: boolean;
    game: LuckyStrikeAvailableGameOutput;
    onPress: (games: LuckyStrikeAvailableGameOutput) => void;
    loading?: boolean;
    onEndTime?: () => void;
    secondModalClose?: () => void;
    routeBack: ROUTES;
}

const LuckyStrikeSlot = ({
    containerStyle,
    isLast,
    game,
    loading,
    onPress,
    onEndTime,
    secondModalClose,
    routeBack,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const navigation = useNavigation();

    const {
        duration,
        iguAmountWei,
        userCount,
        texts,
        styles: gameStyles,
        endDate: endOfTime,
        id,
    } = game;

    const { luckyStrikeConfig } = useMysteryGames();

    const { timeText } = useTimer({
        endOfTime,
        onEndTime,
    });
    const amount = React.useMemo(
        () => balanceFromWei(iguAmountWei).valueShort,
        [iguAmountWei]
    );

    const maxCount = React.useMemo(
        () => maxParticipants(luckyStrikeConfig?.gameTypes, duration),
        [luckyStrikeConfig?.gameTypes, duration]
    );

    const users = React.useMemo(
        () => `${userCount}/${maxCount}`,
        [userCount, maxCount]
    );

    const openUsers = React.useCallback(() => {
        navigation.push(ROUTES.LUCKY_STRIKE_ALL_PARTICIPANTS, { id });
    }, [id]);

    const boughtTickets = React.useMemo(
        () => game.boughtTickets || 0,
        [game.boughtTickets]
    );

    const maxTickets = React.useMemo(
        () => luckyStrikeConfig?.maxTicketAmount || 0,
        [luckyStrikeConfig?.maxTicketAmount]
    );

    const enableToBuy = React.useMemo(
        () => boughtTickets < maxTickets,
        [boughtTickets, maxTickets]
    );

    const isMaxParticipants = React.useMemo(
        () => userCount === maxCount,
        [userCount, maxCount]
    );

    const showView = React.useMemo(() => !!boughtTickets, [boughtTickets]);

    const ticketsSoldOut = React.useMemo(() => {
        if (isMaxParticipants) {
            if (boughtTickets > 0 && enableToBuy) {
                return false;
            }
            return true;
        }
        return false;
    }, [isMaxParticipants, boughtTickets, enableToBuy]);

    const buttonTitle = React.useMemo(() => {
        if (isMaxParticipants) {
            if (boughtTickets > 0 && enableToBuy) {
                return i18n.t(`general.BUTTONS.purchaseMore`);
            }
            return i18n.t(`HomeScreen.${MYSTERY_GAMES.luckyStrike}.soldOut`);
        }

        if (showView) {
            return i18n.t(`general.BUTTONS.purchaseMore`);
        }
        return i18n.t(`HomeScreen.${MYSTERY_GAMES.luckyStrike}.purchaseTicket`);
    }, [isMaxParticipants, enableToBuy, showView]);

    const onViewPress = React.useCallback(() => {
        secondModalClose?.();
        navigation.push(ROUTES.LUCKY_STRIKE_WAITING_FOR_RESULT, {
            id,
            routeBack,
        });
    }, [id, routeBack]);

    const viewButtonText = React.useMemo(
        () =>
            enableToBuy
                ? i18n.t('general.BUTTONS.view')
                : `${boughtTickets} / ${maxTickets} ${i18n.t(
                      `HomeScreen.${MYSTERY_GAMES.luckyStrike}.ticketsGrabbed`
                  )}`,
        [boughtTickets, maxTickets]
    );

    return (
        <View
            style={[
                styles.container,
                { backgroundColor: gameStyles.backgroundColor },
                isLast && styles.isLast,
                containerStyle,
            ]}>
            <View style={styles.headerBlock}>
                <View style={styles.header}>
                    <TypoText
                        type={TYPO_TEXT.HEADING_H5}
                        colorType={TYPO_TEXT_COLOR.ON_DARK}
                        additionalStyles={styles.textGap}>
                        {texts.title}
                    </TypoText>
                    <TypoText
                        type={TYPO_TEXT.BODY_SMALL}
                        colorType={TYPO_TEXT_COLOR.ON_DARK}>
                        {texts.text}
                    </TypoText>
                </View>

                <Image
                    style={styles.image}
                    resizeMode="contain"
                    source={{ uri: gameStyles.imageUrl }}
                />
            </View>
            <View style={styles.tagsBlock}>
                <DecorativeTag
                    containerStyle={[styles.tag, styles.gap]}
                    icon={<Time width={20} height={20} />}
                    title={timeText}
                />
                <DecorativeTag
                    containerStyle={[styles.tag, styles.gap]}
                    icon={<CoinIcon slug={Coin.vigu} size={20} />}
                    title={amount}
                />
                <DecorativeTag
                    onPress={userCount ? openUsers : undefined}
                    filled={isMaxParticipants}
                    containerStyle={styles.tag}
                    icon={<Participants width={20} height={20} />}
                    title={users}
                />
            </View>
            {!!enableToBuy && (
                <Button
                    disabled={ticketsSoldOut || loading || !enableToBuy}
                    loading={loading}
                    size="md"
                    isOneColor
                    type="outline"
                    onPress={() => onPress(game)}
                    title={buttonTitle}
                    debouncedPress
                    containerStyle={styles.button}
                />
            )}

            {!!showView && (
                <Button
                    disabled={loading}
                    size="md"
                    isOneColor
                    type="text"
                    onPress={onViewPress}
                    iconName={ICON_NAMES.ARROW_RIGHT_SECONDARY}
                    iconColor={styles.viewButtonColor.color}
                    iconDirection="right"
                    iconSize={20}
                    title={viewButtonText}
                    debouncedPress
                    textStyles={styles.viewButtonColor}
                    containerStyle={styles.viewButton}
                />
            )}
        </View>
    );
};
export default React.memo(LuckyStrikeSlot);
