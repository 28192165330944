import React, { useEffect, useState } from 'react';

import { useAppLoading } from '@contexts/AppLoading/AppLoadingContext';
import { useDimensions } from '@contexts/DimensionsContext';
import { useTheme } from '@contexts/ThemeContext';

import ButtonsBottom from '../../../../components/ButtonsBottom';
import { View } from '../../../../components/Themed';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import NavigationBar from '../../../../navigation/NavigationBar';
import { useNavigation } from '../../../../navigation/useNavigation';
import { TradeChartProps } from '../../../../types';
import { getPair, config as linkConfig } from './helpers';
import stylesMain from './styles';

export const TradeChartScreen = ({ route }: TradeChartProps) => {
    const styles = useThemedStyles(stylesMain);
    const [pair, setPair] = useState<string>();
    const { theme } = useTheme();
    const navigation = useNavigation();
    const { setWebContainerIsVisible } = useDimensions();
    const { isSplashAnimationComplete } = useAppLoading();
    const { from, to } = route.params;

    const onBack = () => {
        navigation.goBack();
    };

    useEffect(() => {
        if (from && to) {
            setPair(getPair(from, to));
        }
    }, [from, to]);

    useEffect(() => {
        if (isSplashAnimationComplete) {
            setWebContainerIsVisible(false);
        }
        return () => setWebContainerIsVisible(true);
    }, [isSplashAnimationComplete, setWebContainerIsVisible]);

    return (
        <View style={styles.container}>
            <NavigationBar
                backIcon
                filled={false}
                containerStyle={styles.containerStyle}
                title={i18n.t('tradingView.title')}
            />
            <iframe
                title="TradingView"
                src={`${linkConfig.tradingViewUri}?symbol=${pair}&theme=${theme}`}
                style={styles.webview}
            />
            <View style={{ maxWidth: 300, alignSelf: 'center' }}>
                <ButtonsBottom
                    title={i18n.t('tradingView.back')}
                    onPress={onBack}
                    safe
                />
            </View>
        </View>
    );
};
