import { gql } from '@apollo/client';

export const GET_DAILY_TASKS_LEADERBOARD = gql`
    query QueryDailyTasksLeaderboard($rankingInterval: RankingInterval!) {
        rankingStats(rankingInterval: $rankingInterval) {
            totalTasksCount
            totalEnergyRewarded
        }
        rankingMe(rankingInterval: $rankingInterval) {
            rank
            totalTasksCount
            totalEnergyRewarded
            user {
                username
                walletAddress
                mainPet {
                    id
                    name
                    image {
                        id
                        filename
                        url
                        thumbnailUrl
                    }
                }
            }
        }
        rankingTopPlayers(rankingInterval: $rankingInterval) {
            rank
            totalTasksCount
            totalEnergyRewarded
            user {
                username
                walletAddress
                mainPet {
                    id
                    name
                    image {
                        id
                        filename
                        url
                        thumbnailUrl
                    }
                }
            }
        }
    }
`;

export const GET_PVP_GAMES_LEADERBOARD = gql`
    query QueryPvpGamesLeaderboard(
        $leaderboardRankingInterval: LeaderboardRankingInterval!
    ) {
        leaderboardRankingStats(
            leaderboardRankingInterval: $leaderboardRankingInterval
        ) {
            totalIguAmountWei
            totalGamesPlayed
        }
        leaderboardRankingMe(
            leaderboardRankingInterval: $leaderboardRankingInterval
        ) {
            rank
            totalIguAmountWei
            gamesCount
            winsCount
            lossesCount
            username
            avatarUrl
        }
        leaderboardRankingTopPlayers(
            leaderboardRankingInterval: $leaderboardRankingInterval
        ) {
            rank
            totalIguAmountWei
            gamesCount
            winsCount
            lossesCount
            username
            avatarUrl
        }
    }
`;
