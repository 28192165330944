import * as React from 'react';

import Icon from '@assets/icons/listingBanners/bybitLogo.svg';

import { IconType } from '../types';

interface IProps extends IconType {
    width: number;
    height: number;
    color: string;
}

const BybitLogo = ({ width, height, color }: IProps) => {
    return <Icon width={width} height={height} color={color} />;
};

export default BybitLogo;
