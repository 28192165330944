import React from 'react';

import Distance from '@assets/icons/play/tasks/move/distance.svg';
import Speed from '@assets/icons/play/tasks/move/speed.svg';
import Steps from '@assets/icons/play/tasks/move/steps.svg';
import { Text, View } from '@components/Themed';
import { PlayContext } from '@contexts/PlayContext';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    speed: number;
    walkStatus: { icon: React.FC; text: string };
    currentStepCount: number;
    distanceTraveled: string;
    pedometerDisabled: boolean;
}

export const WalkInfo = ({
    currentStepCount,
    walkStatus,
    speed,
    distanceTraveled,
    pedometerDisabled,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { moveTask } = React.useContext(PlayContext);

    const renderItem = (Icon: React.FC, text: string, disabled?: boolean) => {
        return (
            <View
                style={[
                    styles.itemContainer,
                    disabled ? styles.itemContainerDisabled : {},
                ]}>
                <Icon />
                <Text style={styles.itemText}>{text}</Text>
            </View>
        );
    };

    return (
        <View style={styles.container}>
            <View style={styles.row}>
                {renderItem(
                    Distance,
                    `${distanceTraveled} / ${
                        (moveTask?.currentTask?.maxDistanceMeters || 0) / 1000
                    } ${i18n.t('moveToEarn.km')}`
                )}
                {renderItem(
                    Speed,
                    `${speed > 0 ? speed : '0'} ${i18n.t('moveToEarn.kmH')}`
                )}
            </View>
            <View style={styles.row}>
                {renderItem(Steps, String(currentStepCount), pedometerDisabled)}
                {renderItem(walkStatus.icon, walkStatus.text)}
            </View>
        </View>
    );
};

export default WalkInfo;
