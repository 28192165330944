/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useCallback, useState } from 'react';

import { MoveTaskStatus, PlayTaskStatus } from '@Data/Models';
import LoadingComponent from '@components/LoadingComponent';
import PlayEnergy from '@components/PlayEnergy';
import PullToRefresh from '@components/PullToRefresh';
import SafeAreaTabContent from '@components/SafeAreaTabContent';
import { Text, View } from '@components/Themed';
import Timer from '@components/Timer';
import { ConfigContext } from '@contexts/ConfigContext';
import { usePlay } from '@contexts/PlayContext';
import { useUser } from '@contexts/UserContext';
import { errorsHandler } from '@helpers/errors';
import useGameTask from '@hooks/tasks/useGameTask';
import useGetPlayTasks from '@hooks/tasks/useGetPlayTasks';
import useGetSocialTask from '@hooks/tasks/useGetSocialTask';
import useVerifyTask from '@hooks/tasks/useVerifyTask';
import usePetsStatus from '@hooks/usePetsStatus';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { useFocusEffect } from '@react-navigation/native';

import { PlayTasksActiveProps } from '../../../../types';
import ModalConnectSocials from '../SocializeToEarn/components/ModalConnectSocials';
import MoveTaskCard from '../components/MoveTaskCard';
import PlayTaskCard from '../components/PlayTaskCard';
import SocialTaskCard from '../components/SocialTaskCard';
import VerifyTaskCard from '../components/VerifyTaskCard';
import stylesMain from './styles';

declare type TaskLoadingType = 'Share' | 'Verify' | 'Move' | 'Play';

export const PlayTasksActiveScreen = ({ route }: PlayTasksActiveProps) => {
    const navigation = useNavigation();
    const styles = useThemedStyles(stylesMain);
    const resetGoBack = route.params?.resetGoBack;

    const { config, refetchConfig, isRefetching } =
        React.useContext(ConfigContext);

    const { tasksConfig, tasksConfigLoading, refetchPlayTasks } =
        useGetPlayTasks();

    const { getSocialTask } = useGetSocialTask();
    const { getVerifyTask, createVerifyTask } = useVerifyTask();
    const { getGameTask } = useGameTask();

    const {
        setSocialTask,
        setVerifyTask,
        setMoveTask,
        setPlayTask,
        refetchEnergyReward,
        socialTask,
        moveTask,
        playTask,
        verifyTask,
    } = usePlay();

    const [isVisibleConnectSocials, setIsVisibleConnectSocials] =
        useState<boolean>(false);

    const { user, reloadUser } = useUser();

    const {
        renderAllDeadModal,
        renderSomePetsAreDeadModal,
        setCanShowModal,
        isAllDead,
        isSomeDead,
    } = usePetsStatus();

    const isLoaded = React.useMemo(() => !!tasksConfig, [tasksConfig]);
    const [isUpdating, setIsUpdating] = React.useState<TaskLoadingType | null>(
        null
    );

    const handleOpenConnectSocials = () => {
        setIsVisibleConnectSocials(true);
    };

    const handleConnectSocialsOnSuccess = () => {
        navigation.navigate(ROUTES.SOCIALIZE_TO_EARN_WELCOME_SHARE);
    };

    const fetchConfig = useCallback(() => {
        if (!isRefetching) {
            refetchConfig();
        }
    }, [isRefetching]);

    const onRefresh = async () => {
        refetchPlayTasks(false);
        reloadUser();
    };

    const onUpdate = async (loadingType: TaskLoadingType) => {
        setIsUpdating(loadingType);
        if (loadingType === 'Share') {
            await refetchEnergyReward();
        }
        refetchPlayTasks(true);
        setIsUpdating(null);
    };

    const onBack = () => {
        if (isUpdating) {
            return;
        }

        if (resetGoBack) {
            navigation.navigateToPlay();
        } else {
            navigation.goBack();
        }
    };

    useFocusEffect(
        React.useCallback(() => {
            setCanShowModal(true);
            refetchPlayTasks(true);
            return () => {
                setCanShowModal(false);
            };
        }, [])
    );

    // Set tasks
    React.useEffect(() => {
        if (tasksConfig?.socialTaskConfig) {
            setSocialTask(tasksConfig.socialTaskConfig);
        }
    }, [tasksConfig?.socialTaskConfig]);

    React.useEffect(() => {
        if (tasksConfig?.verificationTaskConfig) {
            setVerifyTask(tasksConfig.verificationTaskConfig);
        }
    }, [tasksConfig?.verificationTaskConfig]);

    React.useEffect(() => {
        if (tasksConfig?.moveTaskConfig) {
            setMoveTask(tasksConfig.moveTaskConfig);
        }
    }, [tasksConfig?.moveTaskConfig]);

    React.useEffect(() => {
        if (tasksConfig?.playTaskConfig) {
            setPlayTask(tasksConfig?.playTaskConfig);
        }
    }, [tasksConfig?.playTaskConfig]);

    // Deep link
    React.useEffect(() => {
        if (route.params?.success) {
            if (socialTask) {
                handleOnSuccessSocialTask();
            }
        }
    }, [route.params?.success, socialTask]);

    const moveTaskStatus: MoveTaskStatus | null = React.useMemo(() => {
        if (moveTask?.canStart) {
            return null;
        }
        return moveTask?.currentTask.status ?? null;
    }, [moveTask]);

    const playTaskStatus: PlayTaskStatus | null = React.useMemo(() => {
        if (playTask?.canStart) {
            return null;
        }
        if (playTask?.currentTask.status === 'CREATED') {
            return 'FAILED';
        }
        return playTask?.currentTask.status ?? null;
    }, [playTask]);

    const handleOnShareCardPress = React.useCallback(async () => {
        try {
            setIsUpdating('Share');
            const result = await getSocialTask();

            if (result.data?.socialTaskConfig) {
                setSocialTask(result.data.socialTaskConfig);
                await onUpdate('Share');
                if (result.data.socialTaskConfig.canStart) {
                    if (
                        user?.instagram ||
                        !!user?.tiktok ||
                        !!user?.twitter ||
                        !!user?.facebook
                    ) {
                        navigation.navigate(
                            ROUTES.SOCIALIZE_TO_EARN_WELCOME_SHARE
                        );
                    } else {
                        handleOpenConnectSocials();
                    }
                    return;
                }
                navigation.navigate(ROUTES.SOCIALIZE_TO_EARN_SHARE_YOUR_PHOTO);
            }
        } catch (error) {
            errorsHandler(error, true);
            setIsUpdating(null);
        }
    }, [user]);

    const handleOnMoveCardPress = async () => {
        await onUpdate('Move');
        navigation.navigate(ROUTES.MOVE_TO_EARN_WELCOME);
    };

    const handleOnPlayCardPress = async (status: boolean) => {
        const result = await getGameTask();
        if (result.data?.playTaskConfig) {
            setPlayTask(result.data?.playTaskConfig);
            await onUpdate('Play');
            navigation.navigate(ROUTES.PLAY_TO_EARN, {
                screen: ROUTES.PLAY_TO_EARN_WELCOME,
                params: {
                    status,
                },
            });
        }
        setIsUpdating(null);
    };

    const handleOnVerifyCardPress = async () => {
        setIsUpdating('Verify');
        const result = await getVerifyTask();

        if (result.data?.verificationTaskConfig) {
            setVerifyTask(result.data.verificationTaskConfig);
            await onUpdate('Verify');
            if (
                result.data.verificationTaskConfig.canStart &&
                result.data.verificationTaskConfig.tasks.length
            ) {
                createVerifyTask({
                    onError: () =>
                        navigation.navigate(
                            ROUTES.SOCIALIZE_TO_EARN_VERIFY_USER_ERROR
                        ),

                    onCompleted: () => {
                        navigation.navigate(
                            ROUTES.SOCIALIZE_TO_EARN_VERIFY_USER
                        );
                    },
                });
                return;
            }
            if (!result.data.verificationTaskConfig.canStart) {
                navigation.navigate(ROUTES.SOCIALIZE_TO_EARN_VERIFY_USER);
                return;
            }
            if (result.data.verificationTaskConfig.canStart) {
                navigation.navigate(ROUTES.SOCIALIZE_TO_EARN_WELCOME_VERIFY);
            }
        }
        setIsUpdating(null);
    };

    const handleOnSuccessSocialTask = React.useCallback(() => {
        navigation.navigate(ROUTES.PLAY_TASK_SUCCESS, {
            maxEnergy: socialTask?.maxEnergyReward || 0,
            earnedEnergy: socialTask?.maxEnergyReward || 0,
            petExperienceRewards: socialTask?.latestTask.petExperienceRewards,
            game: 'SOCIAL',
        });
        navigation.setParams({
            success: null,
        });
    }, [socialTask]);

    const renderSocialTask = React.useCallback(() => {
        return (
            <>
                {!!socialTask && (
                    <SocialTaskCard
                        disabled={!!isUpdating}
                        loading={isUpdating === 'Share'}
                        maxEnergyReward={socialTask.maxEnergyReward}
                        energyRewarded={socialTask.latestTask?.energyRewarded}
                        status={socialTask.latestTask?.status}
                        moderatorRejectReason={
                            socialTask.latestTask?.moderatorRejectReason
                        }
                        onButtonPress={handleOnShareCardPress}
                        onCompletedPress={handleOnSuccessSocialTask}
                        canStart={socialTask.canStart}
                        socialConnected={
                            !!user?.instagram ||
                            !!user?.tiktok ||
                            !!user?.twitter ||
                            !!user?.facebook
                        }
                        isModalVisible={isAllDead || isSomeDead}
                        taskId={socialTask.latestTask?.id || ''}
                    />
                )}
            </>
        );
    }, [
        socialTask,
        isUpdating,
        user?.instagram,
        user?.tiktok,
        user?.twitter,
        user?.facebook,
        handleOnShareCardPress,
        handleOnSuccessSocialTask,
        isAllDead,
        isSomeDead,
    ]);

    const renderVerifyTask = React.useCallback(() => {
        return (
            <>
                {!!verifyTask &&
                (verifyTask.canStart ||
                    !!verifyTask.currentTask ||
                    verifyTask.status !== 'CREATED') ? (
                    <VerifyTaskCard
                        disabled={!!isUpdating}
                        loading={isUpdating === 'Verify'}
                        maxEnergyReward={verifyTask.maxEnergyReward}
                        energyRewarded={verifyTask.totalEnergyRewarded || 0}
                        status={verifyTask.status}
                        canStart={verifyTask.canStart}
                        onButtonPress={handleOnVerifyCardPress}
                    />
                ) : null}
            </>
        );
    }, [verifyTask, isUpdating]);

    const renderMoveTask = React.useCallback(() => {
        return (
            <>
                {!!moveTask && (
                    <MoveTaskCard
                        disabled={!!isUpdating}
                        loading={isUpdating === 'Move'}
                        onButtonPress={handleOnMoveCardPress}
                        maxEnergyReward={moveTask.maxEnergyReward}
                        status={moveTaskStatus}
                        energyRewarded={moveTask.currentTask?.energyRewarded}
                    />
                )}
            </>
        );
    }, [moveTask, isUpdating, moveTaskStatus]);

    const renderPlayTask = React.useCallback(() => {
        return (
            <>
                {!!playTask && (
                    <PlayTaskCard
                        disabled={!!isUpdating}
                        loading={isUpdating === 'Play'}
                        maxEnergyReward={playTask.maxEnergyReward}
                        status={playTaskStatus}
                        energyRewarded={playTask.currentTask?.energyRewarded}
                        onButtonPress={handleOnPlayCardPress}
                    />
                )}
            </>
        );
    }, [playTask, playTaskStatus, isUpdating]);

    const renderItem = React.useCallback(() => {
        return (
            <View style={styles.container}>
                <View style={styles.headerText}>
                    <Text style={styles.title}>
                        {i18n.t('playScreen.dailyTasks')}
                    </Text>
                    <Text style={styles.text}>
                        {i18n.t('playScreen.dailyTasksInfo')}
                    </Text>
                </View>
                <View style={styles.energyTimeWrapper}>
                    <View style={styles.timerWrapper}>
                        <Text style={styles.timeToComplete}>
                            {i18n.t('playScreen.timeLeftToComplete')}
                        </Text>
                        <Timer
                            onEndTime={fetchConfig}
                            timerStyles={styles.timer}
                            endOfTime={config?.nextGameIterationDate}
                            thresholdTime={60}
                            thresholdReachedStyles={styles.timerDanger}
                        />
                    </View>
                    <PlayEnergy energy={user?.energy || 0} />
                </View>
                {!isLoaded && !tasksConfigLoading ? (
                    <LoadingComponent />
                ) : (
                    <>
                        {renderSocialTask()}
                        {renderVerifyTask()}
                        {renderMoveTask()}
                        {renderPlayTask()}
                    </>
                )}
            </View>
        );
    }, [
        isLoaded,
        tasksConfigLoading,
        renderSocialTask,
        renderMoveTask,
        renderVerifyTask,
        renderPlayTask,
        user?.energy,
        config?.nextGameIterationDate,
        fetchConfig,
    ]);

    return (
        <SafeAreaTabContent>
            <NavigationBar
                backIcon
                currentNavigation="ACTIVE"
                navigationWidget="TASKS"
                containerStyle={styles.navBg}
                onBack={onBack}
            />
            <PullToRefresh
                renderItem={renderItem}
                onRefresh={onRefresh}
                refreshing={tasksConfigLoading}
                textLoading={i18n.t('pullToRefresh.reloadTasks.text')}
            />
            <ModalConnectSocials
                isVisible={isVisibleConnectSocials}
                setIsVisible={setIsVisibleConnectSocials}
                onSuccess={handleConnectSocialsOnSuccess}
            />
            {renderAllDeadModal()}
            {renderSomePetsAreDeadModal()}
        </SafeAreaTabContent>
    );
};
export default PlayTasksActiveScreen;
