import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { scale, verticalScale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        safe: {
            backgroundColor: Colors[theme].background,
        },
        container: {
            flex: 1,
            backgroundColor: Colors[theme].background,
        },
        textWrapper: {
            marginTop: verticalScale(24),
            marginBottom: verticalScale(20),
            paddingHorizontal: scale(20),
        },
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 22,
                height: 28,
                weight: '700',
            }),
            marginBottom: verticalScale(8),
        },
        text: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
        purchaseItemWrapper: {
            marginHorizontal: scale(20),
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            borderWidth: 1,
            borderRadius: 12,
            borderColor: Colors[theme].border.primary,
        },
        totalSummaryWrapper: {
            paddingTop: verticalScale(16),
            paddingHorizontal: scale(16),
        },
        buttonsCheckoutWrapper: {},
    });

export default styles;
