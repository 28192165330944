import React from 'react';

import CustomHorizontalModal from '../../../../../../components/CustomHorizontalModal';
import Icon, { ICON_NAMES } from '../../../../../../components/Icons';
import { Text, View } from '../../../../../../components/Themed';
import useThemedStyles from '../../../../../../hooks/useThemedStyles';
import i18n from '../../../../../../i18n/i18n';
import { GameData } from '../../screens/playToEarnProgress/config';
import InfoContainer from '../InfoContainer';
import HeartIcon from '../assets/icons/HeartIcon';
import StarIcon from '../assets/icons/StarIcon';
import mainStyle from './style';

type Props = {
    onDismiss: () => void;
    onAccept: () => void;
    isVisible: boolean;
    modalStatus: string;
    gameData: GameData;
    livesCount: number;
};

export const GameModal = ({
    onDismiss,
    onAccept,
    isVisible,
    modalStatus,
    gameData,
    livesCount,
}: Props) => {
    const styles = useThemedStyles(mainStyle);

    return (
        <CustomHorizontalModal
            isVisible={isVisible}
            titleText={i18n.t(`playToEarn.modals.${modalStatus}.title`)}
            infoText={i18n.t(`playToEarn.modals.${modalStatus}.text`)}
            firstButtonText={i18n.t(`playToEarn.modals.${modalStatus}.accept`)}
            secondButtonText={i18n.t(`playToEarn.modals.${modalStatus}.cancel`)}
            onFirstButtonClick={onAccept}
            onSecondButtonClick={onDismiss}>
            <View style={styles.content}>
                <InfoContainer
                    icon={<StarIcon size={28} />}
                    text={gameData.points}
                    description={i18n.t(`playToEarn.modals.score`)}
                />
                <InfoContainer
                    icon={<HeartIcon isActive size={28} />}
                    text={`${
                        gameData.lives >= 0 ? gameData.lives : 0
                    } / ${livesCount}`}
                    description={i18n.t(`playToEarn.modals.savedLives`)}
                    containerStyle={styles.centralContainer}
                />
                <InfoContainer
                    icon={<Icon name={ICON_NAMES.FRUIT} size={28} />}
                    text={gameData.collectedFruits}
                    description={i18n.t(`playToEarn.modals.fruitCollected`)}
                />
            </View>
        </CustomHorizontalModal>
    );
};
