import * as React from 'react';

import useThemedStyles from '../../hooks/useThemedStyles';
import { View } from '../Themed';
import Timer from '../Timer';
import stylesMain from './styles';

interface IProps {
    endOfTime: string;
    onEnd: () => void;
    isLight?: boolean;
}

const TimerWithCaptions = ({ endOfTime, onEnd, isLight }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={styles.container}>
            <Timer
                endOfTime={endOfTime}
                showDays
                withCaptions
                captionDividerStyle={[
                    styles.captionDividerStyle,
                    isLight && styles.isLight,
                ]}
                captionItemStyle={[
                    styles.captionItemStyle,
                    isLight && styles.isLight,
                ]}
                captionTextStyle={[
                    styles.captionTextStyle,
                    isLight && styles.isLight,
                ]}
                captionDateStyle={[
                    styles.captionDateStyle,
                    isLight && styles.isLight,
                ]}
                thresholdReachedStyles={[
                    styles.timerBlockThreshold,
                    isLight && styles.isLight,
                ]}
                onEndTime={onEnd}
            />
        </View>
    );
};
export default TimerWithCaptions;
