import { StyleSheet } from 'react-native';

import { verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingLeft: 12,
            paddingTop: 12,
            borderRadius: 16,
            width: '48.5%',
            marginBottom: verticalScale(8),
            overflow: 'hidden',
            height: 120,
            justifyContent: 'space-between',
        },

        textBlock: {
            backgroundColor: 'transparent',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginRight: 24,
        },

        ordersBlock: {
            flexDirection: 'row',
            alignSelf: 'flex-start',
            backgroundColor: 'transparent',
            justifyContent: 'flex-end',
            zIndex: 1,
            marginBottom: 12,
        },

        iconGap: {
            marginLeft: 4,
        },

        block: {
            flexDirection: 'row',
            borderRadius: 50,
            backgroundColor: 'rgba(0, 0, 0, 0.15)',
            paddingHorizontal: 6,
            paddingVertical: 4,
            alignItems: 'center',
        },

        icon: {
            backgroundColor: 'transparent',
            position: 'absolute',
            right: 12,
            bottom: -20,
            width: 99,
            aspectRatio: 1,
            zIndex: 0,
        },
    });

export default styles;
