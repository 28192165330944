import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import WebView from 'react-native-webview';

import { setStatusBarHidden } from 'expo-status-bar';

import { PlayTaskResponseItem } from '@Data/Models';
import { useAccessibilitySettings } from '@contexts/AccessibilitySettingsContext';
import { useAppLoading } from '@contexts/AppLoading/AppLoadingContext';
import { useDimensions } from '@contexts/DimensionsContext';
import { PlayContext } from '@contexts/PlayContext';
import { useTheme } from '@contexts/ThemeContext';
import { isAndroid, isWeb } from '@helpers/app';
import { wait, waitForModal } from '@helpers/helpers';
import { playTaskCanBeComplete } from '@helpers/playTask';
import useGameTask from '@hooks/tasks/useGameTask';
import { useVisibleHook } from '@hooks/useVisibleHook';
import { useNavigation } from '@navigation/useNavigation';

import ROUTES from '../../../../../../navigation/routes';
import ScreenOrientationManager from '../../../../../../utils/ScreenOrientationManager';
import { GameData, P2E_CONFIG, loadingStatusesConfig } from './config';
import {
    P2E_STATUS,
    UnityMessage,
    getLaunchData,
    messageTypes,
    sender,
} from './config';

export const usePlayToEarnProgress = (status?: boolean) => {
    const unityWrapperRef = useRef<WebView & HTMLIFrameElement>();
    const { playTask } = useContext(PlayContext);
    const { theme } = useTheme();
    const navigation = useNavigation();
    navigation.blockHardwareBack();
    const { createGameTask, markGameTaskDone } = useGameTask();
    const { setIsRotated, setWebContainerIsVisible } = useDimensions();
    const [renderAttempt, setRenderAttempt] = useState<number>(1);
    const {
        isVisible: modalVisible,
        open: modalOpen,
        close: modalClose,
    } = useVisibleHook();
    const {
        isVisible: errorModal,
        open: errorModalOpen,
        close: errorModalClose,
    } = useVisibleHook();
    const { isSplashAnimationComplete } = useAppLoading();
    const gameResult = useRef<GameData>();
    const finishWithSuccessScreen = useRef<boolean>(false);
    const isSandBox = useRef<boolean>(false);
    const [errorText, setErrorText] = useState<string>('');
    const [gameVisible, setGameVisible] = useState<boolean>(true);
    const [fasterLoad, setFasterLoad] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const [showLoader, setShowLoader] = useState<boolean>(true);
    const [timerVisible, setTimerVisible] = useState(false);
    const [gameState, setGameState] = useState<P2E_STATUS>(P2E_STATUS.ON_PAUSE);
    const [gameData, setGameData] = useState<GameData>({
        lives: 0,
        points: 0,
        collectedFruits: 0,
    });
    const { setIsFeedbackDisabled } = useAccessibilitySettings();

    useEffect(() => {
        if (isWeb && isSplashAnimationComplete) {
            setIsRotated(true);
            setWebContainerIsVisible(false);
        }
    }, [isSplashAnimationComplete, setIsRotated, setWebContainerIsVisible]);

    const loadingStatus = useMemo(() => {
        const status = loadingStatusesConfig.find(
            (item) => progress <= item.count
        ) as any;
        return status.status;
    }, [progress]);

    useEffect(() => {
        setIsFeedbackDisabled(true);
        setStatusBarHidden(true, 'none');

        return () => {
            setStatusBarHidden(false, 'none');
            setIsRotated(false);
            setIsFeedbackDisabled(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onLoadingLineStop = () => {
        setShowLoader(false);
        setTimerVisible(true);
    };

    const onTimerEnd = () => {
        setTimerVisible(false);

        if (isWeb) unityWrapperRef.current?.focus();

        handleSendMessage({
            message: messageTypes.play,
            messageBody: undefined,
        });
    };

    const createTask = useCallback(async () => {
        createGameTask({
            onError: async () => {
                setIsRotated(false);
                await ScreenOrientationManager.setDefault();
                navigation.navigateToTasks(true);
            },
            onCompleted: () => {
                setFasterLoad(true);
                setGameState(P2E_STATUS.PLAYING);
            },
            onSanBox: () => {
                isSandBox.current = true;
                setFasterLoad(true);
                setGameState(P2E_STATUS.PLAYING);
            },
        });
    }, [createGameTask, navigation, setIsRotated]);

    const handleOnMessage = (unityMessage: UnityMessage) => {
        let { messageBody } = unityMessage;

        if (messageBody && typeof messageBody === 'string') {
            messageBody = JSON.parse(messageBody);
        }

        if (messageBody?.score)
            setGameData({
                points: messageBody.score,
                collectedFruits: messageBody.countEatenFruits,
                lives: messageBody.lives,
            });

        switch (unityMessage.message) {
            case 'setLoadingProgressValue': {
                setProgress(
                    messageBody.value < 0.95 ? messageBody.value : 0.99
                );
                break;
            }
            case 'onReady': {
                createTask();
                setProgress(1);
                setErrorText('');
                errorModalClose();
                break;
            }
            case 'gameInited': {
                handleLoadEnd();
                break;
            }
            case 'fruit-pause': {
                setGameState(P2E_STATUS.ON_PAUSE);
                gameResult.current = {
                    points: messageBody.score,
                    collectedFruits: messageBody.countEatenFruits,
                    lives: messageBody.lives,
                };
                modalOpen();
                break;
            }
            case 'loose': {
                setGameState(P2E_STATUS.LOOSE);
                gameResult.current = {
                    points: messageBody.score,
                    collectedFruits: messageBody.countEatenFruits,
                    lives: messageBody.lives,
                };
                markPlayTaskDone();
                modalOpen();
                break;
            }
            case 'win': {
                setGameState(P2E_STATUS.WIN);
                gameResult.current = {
                    points: messageBody.score,
                    collectedFruits: messageBody.countEatenFruits,
                    lives: messageBody.lives,
                };
                markPlayTaskDone();
                modalOpen();
                break;
            }
            case 'error': {
                setErrorText(messageBody.error.toString());
                setGameState(P2E_STATUS.ON_PAUSE);
                errorModalOpen();
                break;
            }
            default: {
                // console.error('undefined message title');
            }
        }
    };

    const onGameFinish = useCallback(
        async (updateTask?: PlayTaskResponseItem) => {
            updateTask = updateTask || playTask?.currentTask;
            await ScreenOrientationManager.setDefault();
            setIsRotated(false);
            await wait(200);
            if (finishWithSuccessScreen.current) {
                navigation.navigate(ROUTES.PLAY_TASK_SUCCESS, {
                    maxEnergy: playTask?.maxEnergyReward || 0,
                    earnedEnergy: updateTask?.energyRewarded || 0,
                    petExperienceRewards:
                        updateTask?.petExperienceRewards || [],
                    game: 'PLAY',
                    playGame: {
                        score: gameResult.current?.points,
                        savedLives: gameResult.current?.lives,
                        fruitsCollected: gameResult.current?.collectedFruits,
                        livesCount: 3,
                    },
                });
            } else {
                navigation.navigateToTasks(true);
            }
        },
        [
            playTask?.currentTask,
            playTask?.maxEnergyReward,
            setIsRotated,
            navigation,
        ]
    );

    const markPlayTaskDone = useCallback(
        async (withFinish = false) => {
            if (!gameResult.current) return null;
            const result = await markGameTaskDone(
                gameResult.current,
                Boolean(isSandBox.current || status)
            );
            finishWithSuccessScreen.current = !!result;
            if (withFinish && result) {
                await waitForModal();
                onGameFinish(result);
            }
        },
        [markGameTaskDone, status, onGameFinish]
    );

    const onModalAccept = useCallback(async () => {
        modalClose();
        setGameVisible(false);
        setWebContainerIsVisible(true);
        if (
            playTaskCanBeComplete(playTask) &&
            gameState === P2E_STATUS.ON_PAUSE
        ) {
            await markPlayTaskDone(true);
        } else {
            onGameFinish();
        }
    }, [
        modalClose,
        setWebContainerIsVisible,
        playTask,
        gameState,
        markPlayTaskDone,
        onGameFinish,
    ]);

    const handleOnDismiss = () => {
        modalClose();
        handleSendMessage({
            message: messageTypes.play,
            messageBody: undefined,
        });
    };

    const handleLoadEnd = async () => {
        handleSendMessage({
            message: messageTypes.getLaunchData,
            messageBody: getLaunchData(theme),
        });
    };

    const handleSendMessage = (unityMessage: UnityMessage) => {
        const { message, messageBody } = unityMessage;

        const data = {
            sender,
            message,
            data: messageBody,
        };

        if (isWeb) {
            unityWrapperRef.current?.contentWindow?.postMessage(
                JSON.stringify(data),
                '*'
            );
        } else if (isAndroid) {
            const jsonData = JSON.stringify(data);

            const INJECTED_JAVASCRIPT = `(function() {
                window.postMessage(JSON.stringify(${jsonData}),'*');
            })()`;

            unityWrapperRef.current?.injectJavaScript(INJECTED_JAVASCRIPT);
        } else {
            unityWrapperRef.current?.postMessage(JSON.stringify(data));
        }
    };

    const handleOnErrorAgree = useCallback(() => {
        setErrorText('');
        errorModalClose();
        setRenderAttempt((num) => num + 1);
    }, [errorModalClose]);

    const exitOnErrorModal = useCallback(async () => {
        setErrorText('');
        errorModalClose();
        finishWithSuccessScreen.current = false;
        await waitForModal();
        onGameFinish();
    }, [errorModalClose, onGameFinish]);
    return {
        gameResult,
        modalVisible,
        gameVisible,
        gameState,
        gameData,
        showLoader,
        fasterLoad,
        timerVisible,
        progress,
        onTimerEnd,
        onLoadingLineStop,
        handleOnMessage,
        handleOnDismiss,
        handleLoadEnd,
        setShowLoader,
        unityWrapperRef,
        onModalAccept,
        renderAttempt,
        loadingStatus,
        errorModalOpen,
        errorModal,
        handleOnErrorAgree,
        errorModalClose: exitOnErrorModal,
        errorText,
    };
};
