import * as React from 'react';
import { useCallback } from 'react';

import AvailableGradient from '@assets/icons/gradient/gaming/availableRewards.svg';
import PendingGradient from '@assets/icons/gradient/gaming/pendingRewards.svg';
import Carousel from '@components/Carousel';
import InfoIcon from '@components/InfoIcon/InfoIcon';
import ModalPvpBonus from '@components/ModalPvpBonus';
import ModalPvpBonusFreePet from '@components/ModalPvpBonusFreePet';
import { Text, View } from '@components/Themed';
import { useDimensions } from '@contexts/DimensionsContext';
import { usePlay } from '@contexts/PlayContext';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { scale } from '@helpers/dimensions';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import { GAMING_WALLET, IGamingWallet } from '@models/gamingWallet';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import PvpBonusButton from '../PvpBonusButton';
import stylesMain from './styles';

interface IProps {
    gamingWalletCarouselData: IGamingWallet[];
    pvpBonus: string | undefined;
}

const ratio = 1.61;

const GamingWalletCarousel = ({
    gamingWalletCarouselData,
    pvpBonus,
}: IProps) => {
    const { windowWidth } = useDimensions();
    const { isFreePets } = usePlay();
    const styles = useThemedStyles(stylesMain);

    const { priceInUsd } = useWallet();
    const navigation = useNavigation();

    const width = React.useMemo(
        () => windowWidth - scale(20 * 2),
        [windowWidth]
    );
    const height = React.useMemo(() => Math.floor(width / ratio), [width]);

    const itemCustomWidth = width + scale(12);

    const onQuestionPress = (id: string) => {
        if (id === GAMING_WALLET.available) {
            navigation.push(ROUTES.HOW_TO_GAMING_AVAILABLE_WALLET, {
                withButton: true,
            });
            return;
        }
        navigation.navigate(ROUTES.HOW_TO_GAMING_PENDING_WALLET);
    };

    const { isVisible, open, close } = useVisibleHook();

    const onPvpBonusPress = () => {
        close();
        navigation.navigate(ROUTES.HOME);
    };

    const {
        isVisible: pvpBonusFreePet,
        open: openPvpBonusFreePet,
        close: closePvpBonusFreePet,
    } = useVisibleHook();

    const onPressFreePet = () => {
        closePvpBonusFreePet();
        navigation.navigateToMint();
    };

    const renderItem = useCallback(
        (item: IGamingWallet) => {
            const isAvailable = item.id === GAMING_WALLET.available;

            const gradient = isAvailable ? (
                <AvailableGradient />
            ) : (
                <PendingGradient />
            );

            const usdPrice = priceInUsd(Coin.igu, item.coinAmount); // TODO change exchange rate on VIGU

            return (
                <View
                    style={[
                        {
                            width,
                        },
                        styles.container,
                    ]}>
                    <View
                        style={{
                            width,
                            height,
                        }}>
                        {gradient}
                    </View>

                    <View style={styles.content}>
                        {isAvailable && (
                            <PvpBonusButton
                                iguAmountWei={pvpBonus}
                                onPress={
                                    !isFreePets ? open : openPvpBonusFreePet
                                }
                            />
                        )}
                        <View style={styles.top}>
                            <View style={styles.titleWrapper}>
                                <InfoIcon
                                    iconColor={styles.infoIcon.color}
                                    onPress={() => onQuestionPress(item.id)}
                                />
                                <Text style={styles.title}>
                                    {i18n.t(
                                        `wallet.gaming.gamingWalletCarousel.${item.id}.title`
                                    )}
                                </Text>
                            </View>
                            <Text
                                style={styles.amount}
                                numberOfLines={1}
                                ellipsizeMode="middle">
                                {`${item.coinAmount || 0} ${Coin.vigu}`}
                            </Text>
                            <Text
                                style={styles.usd}
                                numberOfLines={1}
                                ellipsizeMode="middle">
                                {`≈ ${usdPrice || 0.0} $`}
                            </Text>
                        </View>

                        <Text style={styles.info} numberOfLines={3}>
                            {i18n.t(
                                `wallet.gaming.gamingWalletCarousel.${item.id}.text`
                            )}
                        </Text>
                    </View>
                </View>
            );
        },
        [height, width, windowWidth, pvpBonus, isFreePets]
    );

    return (
        <>
            <Carousel
                data={gamingWalletCarouselData}
                sliderWidth={windowWidth}
                itemWidth={itemCustomWidth}
                renderItem={(item) => renderItem(item)}
                height={Math.floor(windowWidth / ratio)}
                itemStyle={styles.itemStyle}
                pagination
            />
            <ModalPvpBonus
                isVisible={isVisible}
                onPress={onPvpBonusPress}
                onClose={close}
            />
            <ModalPvpBonusFreePet
                isVisible={pvpBonusFreePet}
                onClose={closePvpBonusFreePet}
                onPress={onPressFreePet}
            />
        </>
    );
};
export default React.memo(GamingWalletCarousel);
