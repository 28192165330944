import * as React from 'react';
import { useEffect, useMemo } from 'react';

import { useAppLoading } from '@contexts/AppLoading/AppLoadingContext';
import { useModal } from '@contexts/ModalContext';
import { useUser } from '@contexts/UserContext';
import { toastBannerMysteryBox, toastHide } from '@helpers/toastNotification';
import AsyncStorage from '@react-native-async-storage/async-storage';

enum BANNER_STATUS {
    initial,
    notShown,
    shown,
}

interface IProps {
    name: string;
    type: 'toast' | 'custom';
    autoHide?: number;
    perUser?: boolean;
    onPress?: () => void;
    onHide?: () => void;
}

export const useShowBanner = ({
    name,
    type = 'toast',
    autoHide = 10000,
    perUser = true,
    onPress = () => undefined,
    onHide = () => undefined,
}: IProps) => {
    const { isSplashAnimationComplete } = useAppLoading();
    const { user } = useUser();
    const { isVisible: isGlobalModalVisible } = useModal();

    const [bannerStatus, setBannerStatus] = React.useState<BANNER_STATUS>(
        BANNER_STATUS.initial
    );

    // storage key for banner
    const storageKey = `useShowBanner-${perUser ? `${user?.id}-` : ''}${name}`;

    // if can show banner, only after app fully loaded (very important on web)
    const canShowBanner = useMemo(
        () =>
            isSplashAnimationComplete &&
            !isGlobalModalVisible &&
            bannerStatus === BANNER_STATUS.notShown,
        [bannerStatus, isSplashAnimationComplete, isGlobalModalVisible]
    );

    // mark banner shown
    const markBannerHidden = () => {
        AsyncStorage.setItem(storageKey, BANNER_STATUS.shown.toString());
        setBannerStatus(BANNER_STATUS.shown);
    };

    // on press banner we mark as shown
    const handleOnPress = () => {
        markBannerHidden();
        onPress?.();
        if (type === 'toast') {
            toastHide();
        }
    };

    // on hide banner we run a callback
    const handleOnHide = () => {
        markBannerHidden();
        onHide?.();
    };

    // show banner based on type
    const showBanner = () => {
        switch (name) {
            case 'MysteryBoxBanner':
                toastBannerMysteryBox({
                    onPress: handleOnPress,
                    onHide: handleOnHide,
                    visibilityTime: autoHide || 0,
                });
                break;
            default:
                break;
        }
    };

    // hide banner based on type
    const hideBanner = () => {
        markBannerHidden();
        onHide?.();
        if (type === 'toast') {
            toastHide();
        }
    };

    // check initial banner state
    useEffect(() => {
        async function checkForBanner() {
            const status = await AsyncStorage.getItem(storageKey);

            setBannerStatus(
                status === null
                    ? BANNER_STATUS.notShown
                    : (Number(status) as BANNER_STATUS)
            );
        }

        checkForBanner();
    }, [storageKey]);

    return { canShowBanner, showBanner, hideBanner };
};
