import { Platform, StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import { scale, verticalScale } from '../../../../helpers/dimensions';
import font from '../../../../helpers/font';
import { stylesModalNavigationFix } from '../../../../helpers/style';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        safeArea: {
            flex: 1,
            backgroundColor: Colors[theme].background,
        },
        container: {
            flex: 1,
            ...stylesModalNavigationFix(),
        },
        navigation: {
            paddingHorizontal: scale(20),
        },
        header: {
            paddingHorizontal: scale(20),
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: verticalScale(16),
        },
        buttonExit: {
            position: 'absolute',
            top: verticalScale(16),
            left: scale(20),
            zIndex: 1,
        },
        headerText: {
            flex: 1,
            marginTop: verticalScale(4.5),
            textAlign: 'center',
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
        },
        dragWrapper: {
            paddingHorizontal: scale(20),
            marginTop: verticalScale(8),
            paddingTop: verticalScale(10),
            paddingBottom: verticalScale(14),
            borderBottomWidth: 1,
            borderBottomColor: Colors[theme].border.primary,
        },
        dragText: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
        },
        listWrapper: {
            flex: 1,
            paddingHorizontal: scale(20),
        },
        warning: {
            paddingHorizontal: scale(20),
            paddingTop: verticalScale(16),
        },
        errorText: {
            paddingHorizontal: scale(20),
            paddingTop: verticalScale(16),
            color: Colors[theme].text.critical,
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
        },
        buttonWrapper: {
            paddingHorizontal: scale(20),
            paddingVertical: verticalScale(16),
        },
    });

export default styles;
