export const PAUSE_GAME_TIMER_COUNT = 3;

export type UnityMessage = {
    message: string;
    messageBody: any;
};

export type GameData = {
    points: number;
    lives: number;
    collectedFruits: number;
};

export const sender = 'react-native-igu';

export const messageTypes = {
    getLaunchData: 'getLaunchData',
    setGameStatus: 'setGameStatus',
    play: 'play',
    pause: 'pause',
    restart: 'restart',
};

export const loadingStatusesConfig = [
    { status: 'downloading', count: 0.2 },
    { status: 'preparing', count: 0.4 },
    { status: 'loadingGraphics', count: 0.6 },
    { status: 'spawning', count: 0.8 },
    { status: 'loading', count: 1 },
];

export const P2E_CONFIG = {
    score: 1000,
    enableAudio: false,
    sandbox: false,
    hideMaxScore: false,
    showPause: true,
    showStats: true,
    lives: 3,
    difficult: 3,
};

export const getLaunchData = (theme: string) => {
    const config = {
        theme,
        ...P2E_CONFIG,
    };

    return config;
};

export enum P2E_STATUS {
    ON_PAUSE = 'pause',
    LOOSE = 'loose',
    WIN = 'win',
    PLAYING = 'playing',
}
