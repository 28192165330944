import React from 'react';
import { AppState, View } from 'react-native';
import { useCountdown } from 'react-native-countdown-circle-timer';
import Svg, { Path } from 'react-native-svg';

import { Text } from '../../../../../../components/Themed';
import Colors from '../../../../../../constants/Colors';
import { useTheme } from '../../../../../../contexts/ThemeContext';
import { isWeb } from '../../../../../../helpers/app';
import { scale } from '../../../../../../helpers/dimensions';
import { timeFormatter } from '../../../../../../helpers/time';
import useThemedStyles from '../../../../../../hooks/useThemedStyles';
import i18n from '../../../../../../i18n/i18n';
import stylesMain from './styles';

interface IProps {
    running: boolean;
    isWalkStopped: boolean;
    setRemainingTime: (remaining: number) => void;
    maxTimeSeconds: number;
    onComplete: () => void;
}

export const CircleTimer = ({
    running,
    isWalkStopped,
    setRemainingTime,
    maxTimeSeconds,
    onComplete,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { theme } = useTheme();

    const appState = React.useRef(AppState.currentState);

    React.useEffect(() => {
        if (!isWeb) {
            const subscription = AppState.addEventListener(
                'change',
                (nextAppState) => {
                    if (
                        appState.current.match(/inactive|background/) &&
                        nextAppState === 'active'
                    ) {
                    }

                    appState.current = nextAppState;
                }
            );

            return () => {
                subscription.remove();
            };
        }
    }, []);

    const {
        path,
        pathLength,
        stroke,
        strokeDashoffset,
        remainingTime,
        elapsedTime,
        size,
    } = useCountdown({
        isPlaying: running,
        duration: maxTimeSeconds,
        colors: isWalkStopped ? '#b5b7bc' : '#40BF95',
        size: scale(284),
        onComplete: () => {
            onComplete();
            setRemainingTime(0);
        },
    });

    const children = React.useCallback(
        ({ remainingTime }) => {
            const withLeadingZero = true;
            const hours = Math.floor(remainingTime / 3600);
            const minutes = Math.floor((remainingTime % 3600) / 60);
            const seconds = remainingTime % 60;

            const hoursText = timeFormatter(hours, withLeadingZero);
            const minutesText = timeFormatter(minutes, withLeadingZero);
            const secondsText = timeFormatter(seconds, withLeadingZero);

            return (
                <View style={styles.insideTimer}>
                    <View style={styles.insideCircle}>
                        <Svg width={size} height={size}>
                            <Path
                                d={path}
                                fill="none"
                                stroke={stroke}
                                strokeWidth={6}
                                strokeLinecap="round"
                            />

                            {elapsedTime !== maxTimeSeconds && (
                                <Path
                                    d={path}
                                    fill="none"
                                    stroke={Colors[theme].background}
                                    strokeLinecap="butt"
                                    strokeWidth={7}
                                    strokeDasharray={pathLength}
                                    strokeDashoffset={strokeDashoffset}
                                />
                            )}
                        </Svg>
                    </View>
                    <View style={styles.timeWrapper}>
                        <View style={styles.timeBlock}>
                            <View style={styles.withCaptionsItem}>
                                <Text style={styles.captionDateStyle}>
                                    {hoursText.replace(':', '')}
                                </Text>
                            </View>
                            <Text style={styles.captionDividerStyle}>:</Text>
                            <View style={styles.withCaptionsItem}>
                                <Text style={styles.captionDateStyle}>
                                    {minutesText.replace(':', '')}
                                </Text>
                            </View>
                            <Text style={styles.captionDividerStyle}>:</Text>
                            <View style={styles.withCaptionsItem}>
                                <Text style={styles.captionDateStyle}>
                                    {secondsText.replace(':', '')}
                                </Text>
                            </View>
                        </View>
                        <Text style={styles.timeLeftText}>
                            {i18n.t('moveToEarn.progress.timeLeft')}
                        </Text>
                    </View>
                </View>
            );
        },
        [stroke, pathLength, strokeDashoffset]
    );

    return (
        <>
            <View>
                <Svg width={size} height={size}>
                    <Path
                        d={path}
                        fill="none"
                        stroke={stroke}
                        strokeWidth={3}
                        strokeLinecap="round"
                        strokeDasharray="0.01 10"
                    />

                    {elapsedTime !== maxTimeSeconds && (
                        <Path
                            d={path}
                            opacity={0.8}
                            fill="none"
                            stroke={Colors[theme].background}
                            strokeLinecap="round"
                            strokeWidth={3}
                            strokeDasharray={pathLength}
                            strokeDashoffset={strokeDashoffset}
                        />
                    )}
                </Svg>
                {appState.current !== 'background' &&
                    children({ remainingTime })}

                {isWeb && children({ remainingTime })}
            </View>
        </>
    );
};

export default CircleTimer;
