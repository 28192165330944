import { IStage } from '..';

export enum SHARE_IN_SOCIAL_MEDIA_NAME {
    STEP_ONE = 'STEP_ONE',
    STEP_TWO = 'STEP_TWO',
}

type IShareInSocialMediaStages = {
    STEP_ONE: IStage;
    STEP_TWO: IStage;
};

export const shareInSocialMediaStages: IShareInSocialMediaStages = {
    STEP_ONE: {
        customFillIndex: 1,
        fill: ['100%', '0%'],
    },

    STEP_TWO: {
        customFillIndex: 2,

        fill: ['100%', '100%'],
    },
};
