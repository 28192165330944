import * as React from 'react';
import { FlatList } from 'react-native';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import FlowStepIndicator, {
    STEP_INDICATOR_FLOWS,
} from '@components/FlowStepIndicator';
import { STAKING_STAGE_NAME } from '@components/FlowStepIndicator/flows/stakingStages';
import StakingOptionsIcon from '@components/Icons/StakingOptionsIcon';
import { View } from '@components/Themed';
import { StakingOptions } from '@contexts/StakingContext';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';

import { StakingOptionsProps } from '../../../../types';
import SelectStakingCard from '../components/SelectStakingCard';
import { useStakingOptionsScreen } from './hook';
import stylesMain from './styles';

const StakingOptionsScreen = ({ route }: StakingOptionsProps) => {
    const styles = useThemedStyles(stylesMain);

    const {
        stakingOption,
        stageFill,
        handleBack,
        navigateToPlans,
        onOptionPress,
    } = useStakingOptionsScreen(route.params?.type, route.params?.plan);
    return (
        <View style={styles.main}>
            <NavigationBar
                onBack={handleBack}
                filled={false}
                backIcon="close"
                title={i18n.t('staking.optionsScreen.screenTitle')}
            />
            <FlowStepIndicator
                flow={STEP_INDICATOR_FLOWS.STAKING}
                stage={STAKING_STAGE_NAME.STEP_ONE}
                stageFill={stageFill}
            />
            <FlatList
                style={styles.container}
                ListHeaderComponent={
                    <BlockHeader
                        title={i18n.t('staking.optionsScreen.title')}
                        text={i18n.t('staking.optionsScreen.text')}
                        type={'primary'}
                        containerStyle={styles.header}
                    />
                }
                showsVerticalScrollIndicator={false}
                data={StakingOptions}
                keyExtractor={(item) => item.id}
                renderItem={({ item }) => (
                    <SelectStakingCard
                        icon={<StakingOptionsIcon slug={item.id} />}
                        disabled={item.disabled}
                        title={i18n.t(`staking.optionsScreen.${item.id}.title`)}
                        text={i18n.t(`staking.optionsScreen.${item.id}.text`)}
                        selectedOption={stakingOption}
                        onPress={onOptionPress}
                        id={item.id}
                    />
                )}
            />

            <ButtonsBottom
                title={i18n.t('staking.optionsScreen.button')}
                onPress={navigateToPlans}
                safe
                disabled={!stakingOption}
            />
        </View>
    );
};

export default StakingOptionsScreen;
