import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import LivesCount from '@assets/icons/mysteryGames/livesCount.svg';
import NftRoyalGameModsIcon from '@components/Icons/NftRoyalGameModsIcon';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT } from '@components/TypoText/types';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { NFT_ROYAL_GAME_MODE } from '@models/mysteryGames';

import stylesMain from './styles';

interface IProps {
    containerStyle?: StyleProp<ViewStyle>;
    score: number | null;
    lives: number | null;
    maxLives: number;
    fruits: number | null;
}

const NftRoyalGamesStatistics = ({
    containerStyle,
    score,
    lives,
    maxLives,
    fruits,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={[styles.container, containerStyle]}>
            <View style={styles.titleBlock}>
                <TypoText type={TYPO_TEXT.SUBHEAD_BIG_BOLD}>
                    {i18n.t(
                        'HomeScreen.NftRoyal.NftRoyalGameHistory.GameStatistics.title'
                    )}
                </TypoText>
            </View>

            <View style={[styles.item, styles.gap]}>
                <View style={styles.itemLeft}>
                    <View style={styles.icon}>
                        <NftRoyalGameModsIcon
                            slug={NFT_ROYAL_GAME_MODE.Score}
                            size={24}
                        />
                    </View>
                    <TypoText type={TYPO_TEXT.SUBHEAD_SMALL_SEMIBOLD}>
                        {i18n.t(
                            'HomeScreen.NftRoyal.NftRoyalGameHistory.GameStatistics.score'
                        )}
                    </TypoText>
                </View>
                <TypoText type={TYPO_TEXT.SUBHEAD_SMALL_BOLD}>
                    {score || 0}
                </TypoText>
            </View>
            <View style={[styles.item, styles.gap]}>
                <View style={styles.itemLeft}>
                    <View style={styles.icon}>
                        <LivesCount width={24} height={24} />
                    </View>
                    <TypoText type={TYPO_TEXT.SUBHEAD_SMALL_SEMIBOLD}>
                        {i18n.t(
                            'HomeScreen.NftRoyal.NftRoyalGameHistory.GameStatistics.lives'
                        )}
                    </TypoText>
                </View>
                <TypoText type={TYPO_TEXT.SUBHEAD_SMALL_BOLD}>
                    {`${lives || 0}/${maxLives}`}
                </TypoText>
            </View>
            <View style={[styles.item]}>
                <View style={styles.itemLeft}>
                    <View style={styles.icon}>
                        <NftRoyalGameModsIcon
                            slug={NFT_ROYAL_GAME_MODE.Fruit}
                            size={24}
                        />
                    </View>
                    <TypoText type={TYPO_TEXT.SUBHEAD_SMALL_SEMIBOLD}>
                        {i18n.t(
                            'HomeScreen.NftRoyal.NftRoyalGameHistory.GameStatistics.fruitCollected'
                        )}
                    </TypoText>
                </View>
                <TypoText type={TYPO_TEXT.SUBHEAD_SMALL_BOLD}>
                    {fruits || 0}
                </TypoText>
            </View>
        </View>
    );
};
export default NftRoyalGamesStatistics;
