import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import Button from '@components/Button';
import Icon, { ICON_NAMES } from '@components/Icons';
import { View } from '@components/Themed';
import { useConfig } from '@contexts/ConfigContext';
import { openUrlOrShowError } from '@helpers/linking';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    customStyles?: StyleProp<ViewStyle>;
    title?: string;
    url?: string;
    textColor?: string;
    iconColor?: string;
}

const ButtonKnowledgeBase = ({
    customStyles,
    title,
    url,
    textColor,
    iconColor,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { config } = useConfig();

    const onPressKnowledgeBase = React.useCallback(() => {
        if (config?.knowledgeBaseUrl) {
            openUrlOrShowError(url || config.knowledgeBaseUrl);
        }
    }, [config?.knowledgeBaseUrl, url]);

    return (
        <View style={[styles.container, customStyles]}>
            <Button
                title={title || i18n.t('general.knowledgeBaseButton')}
                type="text"
                textStyles={textColor ? { color: textColor } : undefined}
                iconDirection="right"
                onPress={onPressKnowledgeBase}
                icon={
                    <Icon
                        name={ICON_NAMES.EXPLORE}
                        size={20}
                        color={iconColor || styles.icon.color}
                    />
                }
            />
        </View>
    );
};
export default ButtonKnowledgeBase;
