import React from 'react';
import { TouchableOpacity } from 'react-native';

import NftIsDead from '@assets/icons/health/nftIsDead.svg';
import HealthIndicator from '@components/HealthIndicator';
import Icon, { ICON_NAMES } from '@components/Icons';
import ModalWhatIsHealth from '@components/ModalWhatIsHealth';
import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import { MarketplaceNftDetailsPetOutput } from '@models/marketplace';

import DetailsBlock from '../../../Collection/components/DetailsBlock';
import Styles from './styles';

interface IProps {
    pet: MarketplaceNftDetailsPetOutput;
}

const OverviewHealthDetailsBlock = ({ pet }: IProps) => {
    const styles = useThemedStyles(Styles);

    const { healthPercentage, dead } = pet;

    const { isVisible, open, close } = useVisibleHook();

    return (
        <DetailsBlock
            title={
                dead
                    ? i18n.t('detailedPet.health.titleDead')
                    : i18n.t('detailedPet.health.title')
            }
            description={
                dead
                    ? i18n.t('detailedPet.health.textDead')
                    : i18n.t('detailedPet.health.text')
            }
            icon={
                dead ? (
                    <NftIsDead width={48} height={48} />
                ) : (
                    <TouchableOpacity onPress={open} activeOpacity={1}>
                        <Icon name={ICON_NAMES.HEALTH} size={48} />
                    </TouchableOpacity>
                )
            }
            isDead={dead}
            onInfoIconPress={!dead ? open : undefined}>
            {!dead && (
                <View style={styles.gap}>
                    <HealthIndicator healthPercentage={healthPercentage} />
                </View>
            )}

            <ModalWhatIsHealth
                showButton={false}
                closeModal={close}
                isVisible={isVisible}
            />
        </DetailsBlock>
    );
};
export default OverviewHealthDetailsBlock;
