import React, { useCallback, useState } from 'react';

import _ from 'lodash';

import { useApolloClient } from '@apollo/client';
import { errorsHandler } from '@helpers/errors';
import { useLoadingHook } from '@hooks/useLoadingHook';
import { PvpGamesLeaderboard } from '@models/leaderboard';
import { GET_PVP_GAMES_LEADERBOARD } from '@requests/leaderboard';

const DEFAULT_FILTER = 'Month';

const usePvpGamesLeaderboard = () => {
    const client = useApolloClient();

    const { isLoading, startLoading, stopLoading } = useLoadingHook(true);
    const {
        isLoading: filterLoading,
        startLoading: startFilterLoading,
        stopLoading: stopFilterLoading,
    } = useLoadingHook();
    const {
        isLoading: pullToRefreshLoading,
        startLoading: startPullToRefreshLoading,
        stopLoading: stopPullToRefreshLoading,
    } = useLoadingHook();

    const [data, setData] = useState<PvpGamesLeaderboard>();
    const [filter, setFilter] = React.useState(DEFAULT_FILTER);

    const stopAllLoadings = () => {
        stopLoading();
        stopPullToRefreshLoading();
        stopFilterLoading();
    };

    const getData = useCallback(
        async (silent: boolean, item?: string) => {
            if (item) {
                if (item === filter) {
                    return;
                }
                setFilter(item);
                startFilterLoading();
            } else {
                silent ? startLoading() : startPullToRefreshLoading();
            }
            try {
                const result = await client.query<PvpGamesLeaderboard>({
                    query: GET_PVP_GAMES_LEADERBOARD,
                    fetchPolicy: 'network-only',
                    variables: {
                        leaderboardRankingInterval: item || filter,
                    },
                });
                if (result.data) {
                    setData(result.data);
                }
                stopAllLoadings();
            } catch (error) {
                stopAllLoadings();
                errorsHandler(error);
            }
        },
        [filter]
    );

    return {
        data,
        getData,
        filterLoading,
        filter,
        pullToRefreshLoading,
        isLoading,
    };
};

export default usePvpGamesLeaderboard;
