import React from 'react';

import {
    GAME_ITERATION_STATUS,
    GameIterationOutput,
    GameIterationOutputResponse,
} from '@Data/Models';
import { GET_CURRENT_GAME_ITERATION } from '@Data/Requests';
import { useLazyQuery } from '@apollo/client';
import { errorsHandler } from '@helpers/errors';

export const useGameIterationStatus = () => {
    const [gameIterationStatus, setGameIterationStatus] =
        React.useState<GameIterationOutput>(GAME_ITERATION_STATUS.COMPLETED);

    const [getGameIterationStatus] = useLazyQuery<GameIterationOutputResponse>(
        GET_CURRENT_GAME_ITERATION,
        {
            onError: (error) => errorsHandler(error),
            onCompleted: (data) =>
                setGameIterationStatus(data.currentGameIteration.status),
            pollInterval: 30000,

            fetchPolicy: 'network-only',
        }
    );
    return { getGameIterationStatus, gameIterationStatus };
};
