import React, { useCallback, useState } from 'react';
import Animated, {
    runOnJS,
    useAnimatedStyle,
    useSharedValue,
    withRepeat,
    withTiming,
} from 'react-native-reanimated';

import Bottle from '@assets/icons/mysteryGames/nftEvolution/bottle.svg';
import DNA from '@assets/icons/mysteryGames/nftEvolution/dna.svg';
import Pen from '@assets/icons/mysteryGames/nftEvolution/pen.svg';
import Animation from '@components/Animation';
import { View } from '@components/Themed';
import { NFT_EVOLUTION_COLOR } from '@contexts/MysteryGamesContext';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVibration } from '@hooks/useVibration';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import BreedingCircleAnimationItem from '../BreedingCircleAnimationItem';
import stylesMain from './styles';

interface IProps {
    canFinishAnimation?: boolean;
}

export const BreedingAnimationProcess = ({ canFinishAnimation }: IProps) => {
    const navigation = useNavigation();
    const styles = useThemedStyles(stylesMain);
    const [firstStageLoaded, setFirstStageLoaded] = useState<boolean>(false);
    const [startSecondStage, setStartSecondStage] = useState<boolean>(false);
    const [secondStageLoaded, setSecondStageLoaded] = useState<boolean>(false);
    const [startThirdStage, setStartThirdStage] = useState<boolean>(false);
    const { vibrate } = useVibration();

    const sharedFirstStep = useSharedValue(0);

    const sharedFirstStepStyle = useAnimatedStyle(() => {
        return {
            width: `${sharedFirstStep.value}%`,
        };
    });
    const sharedSecondStep = useSharedValue(0);

    const sharedSecondStepStyle = useAnimatedStyle(() => {
        return {
            width: `${sharedSecondStep.value}%`,
        };
    });

    const handleStartSecondStage = (value: boolean) => {
        setStartSecondStage(value);
    };
    const handleStartThirdStage = (value: boolean) => {
        setStartThirdStage(value);
    };
    const onCompleteFirstStage = () => {
        setFirstStageLoaded(true);
        sharedFirstStep.value = withRepeat(
            withTiming(
                100,
                {
                    duration: 500,
                },
                //@ts-ignore
                () => runOnJS(handleStartSecondStage)(true)
            ),
            1
        );
    };
    const onCompleteSecondStage = () => {
        setSecondStageLoaded(true);
        sharedSecondStep.value = withRepeat(
            withTiming(
                100,
                {
                    duration: 500,
                },
                //@ts-ignore
                () => runOnJS(handleStartThirdStage)(true)
            ),
            1
        );
    };

    const onCompleteThirdStage = useCallback(() => {
        if (canFinishAnimation) {
            vibrate();
            navigation.replace(ROUTES.NFT_EVOLUTION_SUCCESS);
            return;
        }
    }, [canFinishAnimation, vibrate]);

    return (
        <View style={styles.container}>
            <BreedingCircleAnimationItem
                isPlaying={true}
                isLoaded={firstStageLoaded}
                onComplete={onCompleteFirstStage}
                loadingComponent={
                    <Animation
                        animation={'bottleBreed'}
                        style={styles.iconSize}
                    />
                }
                staticComponent={
                    <Bottle width={32} height={32} color={'#fff'} />
                }
                loadedComponent={
                    <Bottle
                        width={32}
                        height={32}
                        color={NFT_EVOLUTION_COLOR}
                    />
                }
                isOneTheme
            />
            <View style={styles.dividerWrapper}>
                <View style={styles.divider} />
                <Animated.View
                    style={[styles.dividerAnim, sharedFirstStepStyle]}
                />
            </View>

            <BreedingCircleAnimationItem
                isPlaying={startSecondStage}
                isLoaded={secondStageLoaded}
                onComplete={onCompleteSecondStage}
                loadingComponent={
                    <Animation animation={'penBreed'} style={styles.iconSize} />
                }
                staticComponent={<Pen width={32} height={32} color={'#fff'} />}
                loadedComponent={
                    <Pen width={32} height={32} color={NFT_EVOLUTION_COLOR} />
                }
                isOneTheme
            />
            <View style={styles.dividerWrapper}>
                <View style={styles.divider} />
                <Animated.View
                    style={[styles.dividerAnim, sharedSecondStepStyle]}
                />
            </View>
            <BreedingCircleAnimationItem
                isPlaying={startThirdStage}
                isLoaded={false}
                onComplete={onCompleteThirdStage}
                loadingComponent={
                    <Animation animation={'dnaBreed'} style={styles.iconSize} />
                }
                staticComponent={<DNA width={32} height={32} color={'#fff'} />}
                loadedComponent={
                    <DNA width={32} height={32} color={NFT_EVOLUTION_COLOR} />
                }
                isOneTheme
                shouldRepeat
            />
        </View>
    );
};

export default BreedingAnimationProcess;
