import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Image } from 'react-native';
import Animated, {
    useAnimatedStyle,
    useSharedValue,
    withSpring,
} from 'react-native-reanimated';

import { LinearGradient } from 'expo-linear-gradient';

import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT, TYPO_TEXT_COLOR } from '@components/TypoText/types';
import { useDimensions } from '@contexts/DimensionsContext';
import { isIOS, isWeb } from '@helpers/app';
import { verticalScale } from '@helpers/dimensions';
import usePreventDoubleClick from '@hooks/usePreventDoubleClick';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    onPress: () => void;
    isVisible: boolean;
}

const config = {
    startPosition: -200,
    endPosition: isWeb
        ? verticalScale(125)
        : isIOS
        ? verticalScale(96)
        : verticalScale(116),
};

const MemBanner = ({ onPress, isVisible }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const bannerShare = useSharedValue(config.startPosition);

    React.useEffect(() => {
        if (isVisible) {
            bannerShare.value = withSpring(verticalScale(config.endPosition));
            return;
        }
        bannerShare.value = withSpring(verticalScale(config.startPosition));
    }, [isVisible]);

    const buttonSharedStyle = useAnimatedStyle(() => {
        return {
            bottom: bannerShare.value,
            zIndex: 1,
        };
    });

    const { windowWidth } = useDimensions();

    const { onClick, disabled } = usePreventDoubleClick(onPress, 3000);

    const itemWidth = React.useMemo(
        () => windowWidth - verticalScale(20) * 2,
        [windowWidth]
    );

    const image = require('../../../../../assets/icons/else/memBanner.png');

    if (!isVisible) {
        return null;
    }
    return (
        <Animated.View style={[styles.container, buttonSharedStyle]}>
            <TouchableOpacity
                activeOpacity={0.9}
                onPress={onClick}
                disabled={disabled}
                style={{ width: itemWidth }}>
                <LinearGradient
                    colors={['#B48464', '#563B29']}
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 1 }}
                    style={[styles.containerBanner]}>
                    <View style={styles.textBlock}>
                        <TypoText
                            type={TYPO_TEXT.HEADING_H6}
                            additionalStyles={styles.textGap}
                            colorType={TYPO_TEXT_COLOR.ON_DARK}>
                            {i18n.t('HomeScreen.MemsModal.banner.title')}
                        </TypoText>
                        <TypoText
                            numberOfLines={3}
                            type={TYPO_TEXT.BODY_SMALL}
                            colorType={TYPO_TEXT_COLOR.ON_DARK}>
                            {i18n.t('HomeScreen.MemsModal.banner.text')}
                        </TypoText>
                    </View>
                    <Image
                        source={image}
                        resizeMode={'contain'}
                        style={styles.image}
                    />
                </LinearGradient>
            </TouchableOpacity>
        </Animated.View>
    );
};

export default MemBanner;
