import * as React from 'react';
import { SafeAreaView } from 'react-native';

import FirstReferralImage from '../../../assets/icons/initial/initialReferrals.svg';
import BlockHeader from '../../../components/BlockHeader/BlockHeader';
import Button from '../../../components/Button';
import CustomModal from '../../../components/CustomModal';
import ImageIguana from '../../../components/ImageIguana';
import { View } from '../../../components/Themed';
import { useSession } from '../../../contexts/SessionContext';
import { useUser } from '../../../contexts/UserContext';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import { useNavigation } from '../../../navigation/useNavigation';
import stylesMain from './styles';

const InitialReferralScreen = () => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const { logout } = useUser();
    const [isLogoutModalVisible, setIsLogoutModalVisible] =
        React.useState<boolean>(false);
    const { petsCount, reloadUser } = useUser();

    const handleOnBack = React.useCallback(async () => {
        await reloadUser();
        if (!petsCount) {
            if (navigation.canGoBack()) {
                navigation.goBack();
            } else {
                navigation.navigateToMint();
            }
        } else {
            navigation.navigateToPlay();
        }
    }, [petsCount]);

    const renderItem = () => {
        return (
            <View style={styles.itemContainer}>
                <View style={styles.content}>
                    <View style={styles.imgWrapper}>
                        {<FirstReferralImage width={180} height={180} />}
                    </View>
                    <BlockHeader
                        title={i18n.t(`initial.initialReferral.title`)}
                        text={i18n.t(`initial.initialReferral.text`)}
                        type="emptyState"
                    />
                </View>
            </View>
        );
    };

    const renderLogOutModal = () => (
        <CustomModal
            isVisible={isLogoutModalVisible}
            icon={<ImageIguana type="cry" />}
            titleText={i18n.t('profile.logOutModal.title')}
            infoText={i18n.t('profile.logOutModal.description')}
            firstButtonText={i18n.t('profile.logOutModal.okText')}
            secondButtonText={i18n.t('profile.logOutModal.cancelText')}
            onFirstButtonClick={() => {
                setIsLogoutModalVisible(false);
                logout();
            }}
            firstButtonType="outline"
            onSecondButtonClick={() => {
                setIsLogoutModalVisible(false);
            }}
        />
    );

    return (
        <View style={styles.main}>
            <View style={styles.wrapper}>{renderItem()}</View>
            <View style={styles.buttonWrapper}>
                <Button
                    title={i18n.t('initial.initialReferral.button')}
                    iconColor="onDark"
                    onPress={handleOnBack}
                />
            </View>
            <SafeAreaView />
            {renderLogOutModal()}
        </View>
    );
};

export default InitialReferralScreen;
