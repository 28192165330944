import { StyleSheet } from 'react-native';

import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import { stylesSafeArea } from '@helpers/style';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        safe: {
            ...stylesSafeArea(theme),
        },
        container: {
            flex: 1,
        },
        blockHeader: { marginTop: verticalScale(20) },
        flatListWrapper: {
            paddingHorizontal: scale(20),
            flex: 1,
        },
        headerText: {
            paddingHorizontal: scale(0),
        },
        gap: {
            marginTop: verticalScale(16),
        },
        form: {
            paddingHorizontal: scale(20),
        },
        buttonWrapper: {
            paddingHorizontal: scale(20),
            paddingTop: verticalScale(16),
        },
        columnWrapperStyle: {
            justifyContent: 'space-between',
        },
        petNameWrapper: {
            marginBottom: verticalScale(20),
        },
        image: {
            marginHorizontal: scale(20),
        },
        imageGap: {
            width: scale(20 * 2),
        },
        imageContainer: {
            borderRadius: moderateScale(12),
            aspectRatio: 1,
        },
    });

export default styles;
