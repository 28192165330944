import React from 'react';
import Blur from 'react-blur';
import { ImageBackgroundProps, View } from 'react-native';

declare type ImgSourceType = {
    uri: string;
};

const ImageBackgroundBlur = (props: ImageBackgroundProps) => {
    const { children, source, style } = props;

    const styleObject = {
        ...(style as object),
    };

    return (
        <>
            <Blur
                img={(source as ImgSourceType).uri}
                blurRadius={65}
                style={styleObject}>
                {children}
            </Blur>
        </>
    );
};

export default ImageBackgroundBlur;
