import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            paddingHorizontal: scale(20),
        },
        image: {
            marginVertical: verticalScale(24),
        },
        timer: {
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(20),
            borderRadius: moderateScale(12),
            marginTop: verticalScale(20),
        },
        timerText: {
            textAlign: 'center',
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),

            color: Colors[theme].text.secondary,
            marginBottom: verticalScale(8),
        },
    });

export default styles;
