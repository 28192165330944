import { StyleSheet } from 'react-native';

import Colors from '../../../constants/Colors';
import { scale, verticalScale } from '../../../helpers/dimensions';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        shadow: {
            borderRadius: 1000,
            backgroundColor: Colors[theme].navigationBar.shadowColor,
            marginTop: verticalScale(16),
            marginHorizontal: scale(20),
            marginBottom: verticalScale(20),
        },
        container: {
            paddingVertical: verticalScale(4),
            paddingHorizontal: scale(4),
            backgroundColor: Colors[theme].navigationBar.elementsBackground,
            borderWidth: 1,
            borderRadius: 1000,
            marginBottom: verticalScale(3),
            borderColor: Colors[theme].navigationBar.elementsBorder,
            flexDirection: 'row',
        },
        containerItem: {
            flex: 1,
            paddingVertical: verticalScale(4),
            alignItems: 'center',
        },
    });

export default styles;
