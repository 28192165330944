import * as React from 'react';
import { ScrollView } from 'react-native';

import _ from 'lodash';

import { useMutation, useQuery } from '@apollo/client';

import {
    NotificationSettingResponse,
    NotificationSettings,
    SetNotificationSettingResponse,
} from '../../../Data/Models';
import {
    NOTIFICATION_SETTINGS,
    SET_NOTIFICATION_SETTINGS,
} from '../../../Data/Requests';
import LoadingComponent from '../../../components/LoadingComponent';
import Switch from '../../../components/Switch';
import { Text, View } from '../../../components/Themed';
import { toastSuccess } from '../../../helpers/toastNotification';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import NavigationBar from '../../../navigation/NavigationBar';
import stylesMain from '../styles';
import stylesNotifications from './styles';

const NotificationsSettings = () => {
    const mainStyles = useThemedStyles(stylesMain);
    const styles = useThemedStyles(stylesNotifications);

    const [settings, setSetting] = React.useState<NotificationSettings>();

    const { loading: loadingNotificationSettings, data } =
        useQuery<NotificationSettingResponse>(NOTIFICATION_SETTINGS, {
            fetchPolicy: 'network-only',
            onError: (error) => Console.error(error),
            onCompleted: (data) =>
                setSetting(_.omit(data.notificationSettings, '__typename')),
        });
    const [setNotificationSettings, { data: setNotifications }] =
        useMutation<SetNotificationSettingResponse>(SET_NOTIFICATION_SETTINGS, {
            onCompleted: () =>
                toastSuccess(
                    undefined,
                    i18n.t('profile.notificationsSettings.saved')
                ),
            onError: (error) => Console.error(error),
        });

    React.useEffect(() => {
        setNotifications &&
            setSetting(
                _.omit(setNotifications.setNotificationSettings, '__typename')
            );
    }, [setNotifications]);

    const updateNotification = React.useCallback(
        async (id: string) => {
            Console.log({
                ...settings,
                [id]: !settings?.[id as keyof object],
            });
            await setNotificationSettings({
                variables: {
                    setting: {
                        ...settings,
                        [id]: !settings?.[id as keyof object],
                    },
                },
            });
        },
        [settings]
    );

    const renderPushSettings = () => {
        if (!settings) {
            return null;
        }

        return Object.entries(settings)
            .filter((val) => val[0].includes('push'))
            .map((obj) => (
                <Switch
                    key={obj[0]}
                    id={obj[0]}
                    title={i18n.t(
                        `profile.notificationsSettings.notifications.${obj[0]}`
                    )}
                    isActive={obj[1]}
                    onChange={updateNotification}
                />
            ));
    };

    const renderEmailSettings = () => {
        if (!settings) {
            return null;
        }

        return Object.entries(settings)
            .filter((val) => val[0].includes('email'))
            .map((obj) => (
                <Switch
                    key={obj[0]}
                    id={obj[0]}
                    title={i18n.t(
                        `profile.notificationsSettings.notifications.${obj[0]}`
                    )}
                    isActive={obj[1]}
                    onChange={updateNotification}
                />
            ));
    };

    return (
        <View style={mainStyles.container}>
            <NavigationBar
                backIcon
                title={i18n.t('profile.notificationsSettings.title')}
                filled={false}
            />
            {loadingNotificationSettings ? (
                <LoadingComponent />
            ) : (
                !!settings && (
                    <ScrollView
                        style={mainStyles.page}
                        showsVerticalScrollIndicator={false}>
                        <View style={mainStyles.textContainer}>
                            <Text
                                style={[
                                    mainStyles.subtitle,
                                    styles.subtitleMargin,
                                ]}>
                                {i18n.t(
                                    'profile.notificationsSettings.pushNotificationTitle'
                                )}
                            </Text>

                            {renderPushSettings()}

                            <Text
                                style={[
                                    mainStyles.subtitle,
                                    styles.subtitleMargin,
                                ]}>
                                {i18n.t(
                                    'profile.notificationsSettings.emailNotificationTitle'
                                )}
                            </Text>

                            {renderEmailSettings()}
                        </View>
                    </ScrollView>
                )
            )}
        </View>
    );
};

export default NotificationsSettings;
