import * as React from 'react';

import ButtonsBottom from '../../../../../../components/ButtonsBottom';
import SocialMediaSelectItem from '../../../../../../components/SocialMedia/SocialMediaSelectItem';
import { View } from '../../../../../../components/Themed';
import { ISocial, SOCIALS } from '../../../../../../constants/Socials';
import useThemedStyles from '../../../../../../hooks/useThemedStyles';
import i18n from '../../../../../../i18n/i18n';
import { IExtendedSocials } from '../../WelcomeShare/WelcomeShareScreen';
import stylesMain from './styles';

interface IProps {
    onPress: (id: string) => void;
    socials: IExtendedSocials[];
}

const ChooseSocial = ({ onPress, socials }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const [selectedId, setSelectedId] = React.useState('');

    const handleSelected = (id: string) => {
        setSelectedId(id);
    };

    const renderItem = React.useCallback(
        (item: ISocial) => {
            const text = socials.find(
                (o) => o.socialName === item.id
            )?.username;

            const isSelected = selectedId === item.id;
            return (
                <SocialMediaSelectItem
                    key={item.id}
                    text={text}
                    id={item.id}
                    isSelected={isSelected}
                    actionWithParams={handleSelected}
                />
            );
        },
        [selectedId, socials]
    );

    return (
        <View style={styles.container}>
            <View style={styles.listStyle}>
                {socials.map((item) =>
                    renderItem(SOCIALS[item.socialName as keyof object])
                )}
            </View>
            <ButtonsBottom
                containerStyle={styles.buttons}
                title={i18n.t('socialMediaConnect.select')}
                disabled={!selectedId}
                onPress={() => onPress(selectedId)}
                safe
            />
        </View>
    );
};

export default ChooseSocial;
