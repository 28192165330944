import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';

import { theme } from '../../types';

const stylesMain = (theme: theme) =>
    StyleSheet.create({
        main: {
            alignSelf: 'center',
            backgroundColor: 'transparent',
            flexDirection: 'row',
        },
        bg: {
            color: Colors[theme].background,
        },
        texts: {
            backgroundColor: 'transparent',
        },
    });

export default stylesMain;
