import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import {
    RateVerificationSubtaskPet,
    VerificationTaskResponseItem,
} from '@Data/Models';
import { RATE_VERIFICATION_TASK_PET } from '@Data/Requests';
import { useMutation } from '@apollo/client';
import { Text, View } from '@components/Themed';
import { toastError } from '@helpers/toastNotification';

import Button from '../../../../../../components/Button';
import { ICON_NAMES } from '../../../../../../components/Icons';
import useThemedStyles from '../../../../../../hooks/useThemedStyles';
import i18n from '../../../../../../i18n/i18n';
import stylesMain from './styles';

interface IProps {
    containerStyle?: StyleProp<ViewStyle>;
    id: string | undefined;
    task: VerificationTaskResponseItem | null;
}

export const LikeDislikeButtons = ({ task, containerStyle, id }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const [rate, setRate] = React.useState<RateVerificationSubtaskPet>();
    const [sendRate] = useMutation(RATE_VERIFICATION_TASK_PET, {
        onError: (error) =>
            Console.log('[RATE_VERIFICATION_SUBTASK_PET]', error),
    });

    const [likeCount, setLikeCount] = React.useState(
        task?.socialTask.pet.likeCount || 0
    );
    const [dislikeCount, setDislikeCount] = React.useState(
        task?.socialTask.pet.dislikeCount || 0
    );

    const handleOnLikeDislike = React.useCallback(
        (status: RateVerificationSubtaskPet) => {
            if (rate) {
                toastError(
                    i18n.t('socializeToEarn.verifyUser.verifyMatch.alreadyRate')
                );
                return;
            }
            if (status === 'DISLIKE') {
                setDislikeCount((prev) => prev + 1);
            }
            if (status === 'LIKE') {
                setLikeCount((prev) => prev + 1);
            }
            setRate(status);
            sendRate({
                variables: {
                    taskId: id,
                    rate: status,
                },
            });
        },
        [rate, id]
    );

    return (
        <View style={[styles.container, containerStyle]}>
            <View style={[styles.buttonContainer, styles.gapRight]}>
                <Button
                    onPress={() => handleOnLikeDislike('LIKE')}
                    iconName={
                        rate === 'LIKE' ? ICON_NAMES.LIKE_FILL : ICON_NAMES.LIKE
                    }
                    type="outline"
                    size="md"
                    containerStyle={styles.likeButton}
                    debouncedPress
                />
                <Text style={styles.buttonText}>
                    {likeCount || dislikeCount
                        ? likeCount
                        : i18n.t('socializeToEarn.verifyUser.verifyMatch.like')}
                </Text>
            </View>
            <View style={[styles.buttonContainer, styles.gapLeft]}>
                <Button
                    onPress={() => handleOnLikeDislike('DISLIKE')}
                    iconName={
                        rate === 'DISLIKE'
                            ? ICON_NAMES.DISLIKE_FILL
                            : ICON_NAMES.DISLIKE
                    }
                    type="outline"
                    size="md"
                    containerStyle={styles.dislikeButton}
                    debouncedPress
                />
                <Text style={styles.buttonText}>
                    {likeCount || dislikeCount
                        ? dislikeCount
                        : i18n.t(
                              'socializeToEarn.verifyUser.verifyMatch.dislike'
                          )}
                </Text>
            </View>
        </View>
    );
};
export default LikeDislikeButtons;
