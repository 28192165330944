import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { scale } from '../../helpers/dimensions';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        titleWrapper: {
            backgroundColor: 'transparent',
            flexDirection: 'row',

            alignItems: 'center',
        },
        title: {
            marginRight: scale(4),
        },
        iconColor: {
            color: Colors[theme].icon.white,
        },
    });

export default styles;
