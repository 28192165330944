import * as React from 'react';

import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';

import { useNavigation } from '../../useNavigation';
import NavigationItem from '../NavigationItem';
import stylesMain from './styles';

interface IProps {
    currentNavigation: NAVIGATION_NFT_ROYAL;
}

export enum NAVIGATION_NFT_ROYAL {
    ORDERS = 'ORDERS',
    HISTORY = 'HISTORY',
}

const data: NAVIGATION_NFT_ROYAL[] = [
    NAVIGATION_NFT_ROYAL.ORDERS,
    NAVIGATION_NFT_ROYAL.HISTORY,
];

const NavigationNftRoyal = ({ currentNavigation }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const handleNavigation = (item: NAVIGATION_NFT_ROYAL) => {
        switch (item) {
            case NAVIGATION_NFT_ROYAL.ORDERS:
                navigation.navigate(ROUTES.NFT_ROYAL_ACTIVE_ORDERS);
                break;
            case NAVIGATION_NFT_ROYAL.HISTORY:
                navigation.navigate(ROUTES.NFT_ROYAL_HISTORY);
                break;
        }
    };

    return (
        <View style={styles.main}>
            <View style={styles.shadow}>
                <View style={styles.container}>
                    {data.map((item) => (
                        <NavigationItem
                            key={item}
                            onPress={() => handleNavigation(item)}
                            title={i18n.t(
                                `HomeScreen.GamesNavigationBar.${item}`
                            )}
                            isActive={currentNavigation === item}
                            containerStyles={styles.item}
                        />
                    ))}
                </View>
            </View>
        </View>
    );
};
export default NavigationNftRoyal;
