import React, { useCallback, useMemo } from 'react';

import { uniqBy, without } from 'lodash';

import { GET_MARKETPLACE_FILTERED_ACTIVE_ORDERS } from '@Data/Requests';
import { useApolloClient } from '@apollo/client';
import { errorsHandler } from '@helpers/errors';
import { useLoadingHook } from '@hooks/useLoadingHook';
import {
    IMarketplaceTags,
    MARKETPLACE_ORDERS_SORT_OPTION,
    MARKETPLACE_PET_TYPE,
    MARKETPLACE_TAGS_TYPES,
    MarketplaceOrderDataOutput,
    PaginatedMarketplaceSearchOutput,
} from '@models/marketplace';

interface IGetData {
    refresh: boolean;
    filter?: boolean;
}

const LOAD_ITEMS = 30;

const useMarketplaceFilteredActiveOrders = () => {
    const client = useApolloClient();

    const {
        isLoading: ordersLoading,
        startLoading: startOrdersLoading,
        stopLoading: stopOrdersLoading,
    } = useLoadingHook(true);

    const {
        isLoading: refreshLoading,
        startLoading: startRefreshLoading,
        stopLoading: stopRefreshLoading,
    } = useLoadingHook();

    const {
        isLoading: filterLoading,
        startLoading: startFilterLoading,
        stopLoading: stopFilterLoading,
    } = useLoadingHook();

    const {
        isLoading: loadMore,
        startLoading: startLoadMore,
        stopLoading: stopLoadMore,
    } = useLoadingHook();

    const [orders, setOrders] = React.useState<MarketplaceOrderDataOutput[]>();
    const [typeOfNft, setTypeOfNft] =
        React.useState<MARKETPLACE_PET_TYPE | null>(null);
    const [selectedRanks, setSelectedRanks] = React.useState<string[] | null>(
        null
    );
    const [sortedBy, setSortedBy] =
        React.useState<MARKETPLACE_ORDERS_SORT_OPTION | null>(null);
    const [searchKeyword, setSearchKeyword] = React.useState<string | null>(
        null
    );
    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);

    const getOrders = useCallback(
        async ({ refresh, filter }: IGetData) => {
            try {
                filter
                    ? startFilterLoading()
                    : refresh
                    ? startRefreshLoading()
                    : startOrdersLoading();

                const result =
                    await client.query<PaginatedMarketplaceSearchOutput>({
                        query: GET_MARKETPLACE_FILTERED_ACTIVE_ORDERS,
                        fetchPolicy: 'no-cache',
                        variables: {
                            searchInput: {
                                nftType: typeOfNft,
                                rankIds: selectedRanks,
                                searchKeyword: searchKeyword,
                                sort:
                                    sortedBy ||
                                    MARKETPLACE_ORDERS_SORT_OPTION.RecentlyListed,
                            },
                            paginationInput: {
                                limit: LOAD_ITEMS,
                            },
                        },
                    });

                if (result.data) {
                    setOrders(
                        result.data.marketplaceFilteredActiveOrders.items
                    );
                    setCurrentPage(
                        result.data.marketplaceFilteredActiveOrders.meta
                            .currentPage
                    );
                    setTotalPages(
                        result.data.marketplaceFilteredActiveOrders.meta
                            .totalPages
                    );
                }
                filter
                    ? stopFilterLoading()
                    : refresh
                    ? stopRefreshLoading()
                    : stopOrdersLoading();
            } catch (error) {
                filter
                    ? stopFilterLoading()
                    : refresh
                    ? stopRefreshLoading()
                    : stopOrdersLoading();
                errorsHandler(error, true);
            }
        },
        [client, typeOfNft, sortedBy, selectedRanks, searchKeyword]
    );

    const getOrdersLoadMore = useCallback(async () => {
        try {
            if (currentPage === totalPages || totalPages === 0) {
                return;
            }
            startLoadMore();
            const result = await client.query<PaginatedMarketplaceSearchOutput>(
                {
                    query: GET_MARKETPLACE_FILTERED_ACTIVE_ORDERS,
                    fetchPolicy: 'no-cache',
                    variables: {
                        searchInput: {
                            nftType: typeOfNft,
                            rankIds: selectedRanks,
                            searchKeyword: searchKeyword,
                            sort:
                                sortedBy ||
                                MARKETPLACE_ORDERS_SORT_OPTION.RecentlyListed,
                        },
                        paginationInput: {
                            limit: LOAD_ITEMS,
                            page: currentPage + 1,
                        },
                    },
                }
            );

            if (result.data) {
                setOrders((prev) => {
                    return prev
                        ? uniqBy(
                              [
                                  ...prev,
                                  ...result.data.marketplaceFilteredActiveOrders
                                      .items,
                              ],
                              (o) => o.id
                          )
                        : result.data.marketplaceFilteredActiveOrders.items;
                });

                setCurrentPage(
                    result.data.marketplaceFilteredActiveOrders.meta.currentPage
                );
                setTotalPages(
                    result.data.marketplaceFilteredActiveOrders.meta.totalPages
                );
            }
            stopLoadMore();
        } catch (error) {
            stopLoadMore();
            errorsHandler(error, true);
        }
    }, [
        client,
        typeOfNft,
        sortedBy,
        searchKeyword,
        selectedRanks,
        totalPages,
        currentPage,
    ]);

    const canLoadMore = useMemo(
        () => currentPage !== totalPages,
        [currentPage, totalPages]
    );

    const onApplyFilterPress = (
        type: MARKETPLACE_PET_TYPE | null,
        selectedRanks: string[] | null,
        sortedBy: MARKETPLACE_ORDERS_SORT_OPTION | null
    ) => {
        setTypeOfNft(type);
        setSelectedRanks(selectedRanks);
        setSortedBy(sortedBy);
    };

    const onClearPress = () => {
        setTypeOfNft(null);
        setSelectedRanks(null);
        setSortedBy(null);
    };

    const onTagClearPress = (item: IMarketplaceTags) => {
        switch (item.id) {
            case MARKETPLACE_TAGS_TYPES.MARKETPLACE_PET_TYPE:
                setTypeOfNft(null);
                break;
            case MARKETPLACE_TAGS_TYPES.MARKETPLACE_ORDERS_SORT_OPTION:
                setSortedBy(null);
                break;
            case MARKETPLACE_TAGS_TYPES.MARKETPLACE_RANKS:
                setSelectedRanks((prev) =>
                    prev
                        ? prev.length > 1
                            ? without(prev, item.key)
                            : null
                        : null
                );
                break;

            default:
                break;
        }
    };

    const clearSearchKeyword = () => {
        setSearchKeyword(null);
    };

    const searchByKeyword = (value: string) => {
        setSearchKeyword(value);
    };

    const isNotFound = useMemo(() => {
        return (
            !!searchKeyword ||
            !!selectedRanks?.length ||
            !!sortedBy ||
            !!typeOfNft
        );
    }, [searchKeyword, selectedRanks, sortedBy, typeOfNft]);

    return {
        ordersLoading,
        refreshLoading,
        filterLoading,
        loadMore,
        orders,
        getOrders,
        getOrdersLoadMore,
        canLoadMore,
        onApplyFilterPress,
        onClearPress,
        onTagClearPress,
        typeOfNft,
        searchKeyword,
        selectedRanks,
        sortedBy,
        clearSearchKeyword,
        searchByKeyword,
        isNotFound,
    };
};

export default useMarketplaceFilteredActiveOrders;
