import { StyleSheet } from 'react-native';

import { stylesRowSpaceBetween } from '@helpers/style';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            ...stylesRowSpaceBetween(),
        },
        itemWidth: {
            width: '48%',
        },
    });

export default styles;
