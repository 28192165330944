import * as React from 'react';

import {
    IUserMysteryBox,
    MysteryBoxOpenBoxesOutput,
} from '../../../../../Data/Models';
import MysteryBoxClosed from '../../../../../assets/icons/mysteryBoxes/box.svg';
import MysteryBoxOpened from '../../../../../assets/icons/mysteryBoxes/boxOpened.svg';
import Button from '../../../../../components/Button';
import RankIcon from '../../../../../components/Icons/RankIcon';
import { View } from '../../../../../components/Themed';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import stylesMain from './styles';

interface IProps {
    box: MysteryBoxOpenBoxesOutput | IUserMysteryBox;
    onPressClosedBox: (id: string) => void;
    onPressOpenedBox: (slug: string) => void;
}

const MysteryBoxCard = ({
    box,
    onPressClosedBox,
    onPressOpenedBox,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const typeGuardIsClosedBox = (
        pet: MysteryBoxOpenBoxesOutput | IUserMysteryBox
    ): pet is IUserMysteryBox => {
        return !!(pet as IUserMysteryBox)._hex;
    };

    const renderItem = React.useCallback(() => {
        if (typeGuardIsClosedBox(box)) {
            return (
                <>
                    <View style={styles.image}>
                        <MysteryBoxClosed />
                    </View>
                    <Button
                        title={i18n.t('mysteryBoxes.mysteryBoxCard.open')}
                        onPress={() => onPressClosedBox(box._hex)}
                        size="md"
                    />
                </>
            );
        }
        return (
            <>
                <View style={styles.rankIcon}>
                    <RankIcon slug={box.rankId} size={24} />
                </View>
                <View style={styles.image}>
                    <MysteryBoxOpened />
                </View>
                <Button
                    title={i18n.t('mysteryBoxes.mysteryBoxCard.mint')}
                    onPress={() => onPressOpenedBox(box.rankId)}
                    size="md"
                />
            </>
        );
    }, [box]);

    return (
        <View style={styles.shadow}>
            <View style={styles.container}>{renderItem()}</View>
        </View>
    );
};

export const MemoizedMysteryBoxCardItem = React.memo(MysteryBoxCard);
