import * as React from 'react';

import CopyToClipboardButton from '../../../../components/CopyToClipboardButton';
import ExplorerLink from '../../../../components/ExplorerLink';
import StatusIcon from '../../../../components/Icons/StatusIcon';
import NFTIcon from '../../../../components/NFTIcon/NFTIcon';
import { Text, View } from '../../../../components/Themed';
import TransactionSummary from '../../../../components/TransactionSummary';
import { feeWithCurrency } from '../../../../helpers/wallet';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import { ITransactionDetails } from '../../../../types';
import stylesMain from './styles';

interface IProps {
    transaction: ITransactionDetails;
}

const TransactionDetailsContent = ({ transaction }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const {
        type,
        status,
        addressFrom,
        addressTo,
        value,
        coinName,
        color,
        date,
        fee,
        explorerUrl,
        pet,
        tokenID,
    } = transaction;

    const transactionType = tokenID === undefined ? 'coin' : 'nft';

    const isNFT = () => {
        return transactionType === 'nft';
    };

    const textInfo = React.useMemo(() => {
        if (status === 'CONFIRMED') {
            return i18n.t(
                `wallet.transactions.${transactionType}.${type}.text`
            );
        } else {
            return i18n.t(
                `wallet.transactions.${transactionType}.${status}.text`
            );
        }
    }, [transaction, status, transactionType, type]);

    const icon = React.useMemo(() => {
        return <StatusIcon slug={status} />;
    }, [status]);

    const isWithdraw = type === 'WITHDRAW';
    const isDeposit = type === 'DEPOSIT';

    const transactionSummary = [
        isNFT()
            ? {
                  key: pet
                      ? i18n.t('wallet.nft.nft')
                      : i18n.t('wallet.nft.tokenId'),
                  value: pet ? (
                      <NFTIcon
                          pet={pet}
                          title={`${i18n.t(
                              `wallet.transactions.${transactionType}.${type}.name`
                          )} "${pet?.name}"`}
                      />
                  ) : (
                      `#${tokenID}`
                  ),
              }
            : undefined,
        {
            key: i18n.t('wallet.date'),
            value: date,
        },
        isWithdraw || isDeposit
            ? {
                  key: isWithdraw
                      ? i18n.t('wallet.toAddress')
                      : i18n.t('wallet.fromAddress'),
                  value: (
                      <View style={styles.addressWrapper}>
                          <Text style={[styles.infoText, styles.textWidth]}>
                              {isWithdraw ? addressTo : addressFrom}
                          </Text>
                          <CopyToClipboardButton
                              value={isWithdraw ? addressTo : addressFrom}
                          />
                      </View>
                  ),
              }
            : undefined,
        fee
            ? {
                  key: i18n.t('wallet.blockChainFee'),
                  value: feeWithCurrency(fee),
              }
            : undefined,
    ];

    return (
        <>
            <View style={styles.top}>
                <View style={[styles.statusWrapper, styles[status]]}>
                    {icon}
                    <Text style={[styles.statusText, styles[status]]}>
                        {i18n.t(
                            `wallet.transactions.${transactionType}.${status}.title`
                        )}
                    </Text>
                </View>
                {!isNFT() && (
                    <>
                        <Text style={styles.textAmount}>
                            {i18n.t('wallet.amount')}
                        </Text>
                        <Text style={[styles.textPrice, { color }]}>
                            {`${value} ${coinName}`}
                        </Text>
                    </>
                )}

                <Text style={styles.textInfo}>{textInfo}</Text>
                {!!explorerUrl && (
                    <ExplorerLink
                        explorerUrl={explorerUrl}
                        containerStyles={styles.explorerContainer}
                    />
                )}
            </View>
            <View style={styles.bottom}>
                <TransactionSummary
                    containerStyle={styles.transactionSummary}
                    values={transactionSummary}
                />
            </View>
        </>
    );
};

export default TransactionDetailsContent;
