import React from 'react';

export const useVisibleHook = (
    initialState?: boolean,
    onOpen?: () => void,
    onClose?: () => void
) => {
    const [isVisible, setIsVisible] = React.useState<boolean>(
        initialState || false
    );

    const close = () => {
        setIsVisible(false);
        onClose?.();
    };
    const open = () => {
        setIsVisible(true);
        onOpen?.();
    };

    return { isVisible, close, open, setIsVisible };
};
