import * as React from 'react';

import _ from 'lodash';

import { Text, View } from '@components/Themed';
import { IUpdate } from '@components/UpdateAppContent/updates';
import useThemedStyles from '@hooks/useThemedStyles';

import UpdateRow from '../UpdateRow';
import stylesMain from './styles';

interface IProps {
    block: IUpdate;
    lang: string;
}

const UpdateBlock = ({ block, lang }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { title, data } = block;

    const titleText = React.useMemo(
        () => title[lang as keyof object] || title['en'],
        [title]
    );

    return (
        <View style={styles.container}>
            <Text style={styles.title}>{titleText}</Text>
            {data.map((item) => {
                const text = item[lang as keyof object] || item['en'];
                return <UpdateRow text={text} key={text} />;
            })}
        </View>
    );
};

export default UpdateBlock;
