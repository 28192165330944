import React from 'react';

import SocialAccountsIcon from '../../../../../../assets/icons/profile/socialAccounts.svg';
import i18n from '../../../../../../i18n/i18n';
import ROUTES from '../../../../../../navigation/routes';
import { useNavigation } from '../../../../../../navigation/useNavigation';
import MenuItem from '../../../MenuItem';
import { RightComponent, iconSize } from '../../helpers';

const SocialAccountsMenuItem = () => {
    const navigation = useNavigation();

    return (
        <MenuItem
            leftIcon={<SocialAccountsIcon width={iconSize} height={iconSize} />}
            title={i18n.t('profile.menuItems.socialAccounts.title')}
            description={i18n.t('profile.menuItems.socialAccounts.description')}
            rightComponent={<RightComponent type="arrowRight" />}
            onPress={() =>
                navigation.navigate(ROUTES.PROFILE_SOCIAL_ACCOUNTS_SETTINGS)
            }
        />
    );
};

export default SocialAccountsMenuItem;
