import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { View } from '@components/Themed';
import TimerWithCaptions from '@components/TimerWithCaptions';
import TypoText from '@components/TypoText';
import { TYPO_TEXT, TYPO_TEXT_COLOR } from '@components/TypoText/types';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES, TOKEN_TELLER_GAME_STATUS } from '@models/mysteryGames';

import stylesMain from './styles';

interface IProps {
    date: string;
    containerStyle?: StyleProp<ViewStyle>;
    onEndOfTime: () => void;
    status: TOKEN_TELLER_GAME_STATUS;
}

const GAME = MYSTERY_GAMES.tokenteller;
const FLOW = 'TokenTellerTimerBlock';

const TokenTellerTimerBlock = ({
    date,
    containerStyle,
    onEndOfTime,
    status,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    return (
        <View style={[styles.container, containerStyle]}>
            <TypoText
                type={TYPO_TEXT.HEADING_H6}
                colorType={TYPO_TEXT_COLOR.ON_DARK}
                additionalStyles={styles.gapBott}>
                {i18n.t(`HomeScreen.${GAME}.${FLOW}.${status}.title`)}
            </TypoText>
            <TimerWithCaptions isLight endOfTime={date} onEnd={onEndOfTime} />
        </View>
    );
};
export default TokenTellerTimerBlock;
