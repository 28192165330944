import * as React from 'react';
import { Image } from 'react-native';

import Divider from '@components/Divider';
import RankIcon from '@components/Icons/RankIcon';
import { Text, View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    pet: {
        name: string;
        img: string;
        rank: string;
    };
}

const NftEvolutionMintDetails = ({ pet }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <>
            {!!pet.rank && (
                <View style={styles.verificationItem}>
                    <View style={styles.left}>
                        <View>
                            <RankIcon size={64} slug={pet.rank} />
                        </View>
                        <View style={styles.verificationTextContainer}>
                            <Text style={styles.verificationTitle}>
                                {i18n.t('mintCheckout.rank')}
                            </Text>
                            <Text style={styles.verificationSlug}>
                                {pet.rank}
                            </Text>
                        </View>
                    </View>
                </View>
            )}
            <Divider customStyles={styles.divider} />
            <View style={styles.verificationItem}>
                <View style={styles.left}>
                    <View>
                        <Image
                            style={styles.image}
                            source={{
                                uri: pet.img,
                            }}
                            resizeMode={'contain'}
                        />
                    </View>
                    <View style={styles.verificationTextContainer}>
                        <Text style={styles.verificationTitle}>
                            {i18n.t('mintCheckout.nftCustomization')}
                        </Text>
                        <Text
                            style={styles.verificationSlug}
                            ellipsizeMode="tail"
                            numberOfLines={1}>
                            {pet.name}
                        </Text>
                    </View>
                </View>
            </View>
        </>
    );
};
export default NftEvolutionMintDetails;
