import { useCallback, useEffect, useMemo, useState } from 'react';
import React from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useMutation } from '@apollo/client';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { errorsHandler } from '@helpers/errors';
import { waitForModal } from '@helpers/helpers';
import {
    balanceFromWei,
    inputNumberFormatter,
    isBiggerThanMax,
    valueToWei,
} from '@helpers/wallet';
import { useLoadingHook } from '@hooks/useLoadingHook';
import { useTimer } from '@hooks/useTimer';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import { TOKEN_TELLER_GAME_PREDICTION_DIRECTION } from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { TOKEN_TELLER_PREDICT_PRICE } from '@requests/tokenTeller';

import { toastGameExpired } from '../../../LuckyStrikeFlow/helpers';
import { calculateCommission } from '../../../TokenTellerFlow/helpers';
import { useTokenTellerAvailableGame } from '../../../hooks/useTokenTellerAvailableGame';

export const useTokenTellerPurchase = (
    id: string,
    isVisible: boolean,
    onClose: () => void,
    routeBack: ROUTES
) => {
    const navigation = useNavigation();

    const { tokenTellerConfig } = useMysteryGames();
    const { getGame, gameLoading, game, gameError } =
        useTokenTellerAvailableGame();

    const tokenTellerFee = tokenTellerConfig?.commisionFeePercentage || 0;

    React.useEffect(() => {
        getGame(id);
    }, [isVisible, id]);

    React.useEffect(() => {
        if (gameError) {
            onClose();
            waitForModal().then(() => 'top');
        }
    }, [gameError]);
    const {
        availableBalance,
        getGamingAvailableBalance,
        setTopUpFromMysteryGames,
    } = useMysteryGames();

    const maxAmount = useMemo(
        () => balanceFromWei(availableBalance?.available).value,
        [availableBalance?.available]
    );

    const maxAmountShort = useMemo(
        () => balanceFromWei(availableBalance?.available).valueShort,
        [availableBalance?.available]
    );

    const minAmount = useMemo(
        () => balanceFromWei(tokenTellerConfig?.minimalBetIguAmountWei).value,
        [tokenTellerConfig?.minimalBetIguAmountWei]
    );

    const handleClickAll = useCallback(async () => {
        await setFieldValue('amount', maxAmount);
    }, [maxAmount]);

    const topUp = () => {
        setTopUpFromMysteryGames(true);
        onClose();
        navigation.navigate(ROUTES.WALLET_ROOT, {
            screen: ROUTES.WALLET_GAMING_TAB,
            params: {
                screen: ROUTES.TOP_UP_GAMING_WALLET,
            },
        });
    };

    const onSelectCardPress = (amount: string) => {
        setFieldValue('amount', amount);
    };

    const schema = Yup.object({
        amount: Yup.string()
            .transform((_, value) => {
                if (value && value.includes('.')) {
                    return value;
                }
                return value && value.replace(/,/, '.');
            })

            .matches(
                /^\d*(\.\d+)?$/,
                i18n.t(
                    'wallet.gaming.TopUpGamingWalletScreen.inputSection.errors.digits'
                )
            )
            .required(i18n.t('withdrawScreen.errors.amountRequired'))
            .test(
                'maxAmount',
                i18n.t(
                    'wallet.gaming.TopUpGamingWalletScreen.inputSection.errors.max',
                    {
                        limit: maxAmount,
                        coin: Coin.vigu,
                    }
                ),
                (value) => Number(value) <= Number(maxAmount)
            )
            .test(
                'minAmount',
                i18n.t(
                    'wallet.gaming.TopUpGamingWalletScreen.inputSection.errors.min',
                    {
                        limit: minAmount,
                        coin: Coin.vigu,
                    }
                ),
                (value) => Number(value) >= Number(minAmount)
            ),
    });

    const {
        submitForm,
        values,
        errors,
        setFieldValue,
        setTouched,
        touched,
        isValid,
        dirty,
        resetForm,
    } = useFormik({
        initialValues: {
            amount: '',
        },
        validationSchema: schema,
        onSubmit: () => openConfirmModal(),
        enableReinitialize: true,
    });

    const handleChangeAmount = async (amount: string) => {
        setTouched({ amount: false });

        const convertedText = inputNumberFormatter(amount);
        if (!isBiggerThanMax(convertedText)) {
            await setFieldValue('amount', convertedText);
        }
    };

    const handleOnBlur = () => {
        setTouched({ amount: true });
    };

    const handleOnBack = () => {
        navigation.pop();
    };

    const {
        isLoading: purchaseLoading,
        startLoading,
        stopLoading,
    } = useLoadingHook();

    const [pricePredicted, setPricePredicted] = React.useState<boolean>(false);

    const handleTimerEnd = useCallback(async () => {
        if (
            isVisible &&
            !purchaseLoading &&
            !pricePredicted &&
            !game?.isPredicted
        ) {
            onClose();

            await toastGameExpired();
        }
    }, [isVisible, purchaseLoading, pricePredicted, game?.isPredicted]);

    const { timeText, isTimerEnd, isWarningTime } = useTimer({
        endOfTime: game?.endDate,
        onEndTime: handleTimerEnd,
    });

    const time = useMemo(
        () => `${timeText} ${i18n.t('general.KEYS.timeLeft')}`,
        [timeText]
    );

    const [direction, setDirection] =
        useState<TOKEN_TELLER_GAME_PREDICTION_DIRECTION>();

    const onButtonsPress = (
        direction: TOKEN_TELLER_GAME_PREDICTION_DIRECTION | null
    ) => {
        if (!direction) {
            return;
        }
        setDirection(direction);
        submitForm();
    };

    useEffect(() => {
        if (isVisible) {
            getGamingAvailableBalance(true);
        } else {
            resetForm();
        }
    }, [isVisible]);

    const {
        isVisible: confirmModal,
        open: openConfirmModal,
        close: closeConfirmModal,
    } = useVisibleHook();

    const [predictPrice] = useMutation(TOKEN_TELLER_PREDICT_PRICE);

    const onConfirmPress = useCallback(
        async (direction: TOKEN_TELLER_GAME_PREDICTION_DIRECTION) => {
            startLoading();
            try {
                await predictPrice({
                    variables: {
                        input: {
                            direction,
                            gameId: game?.id,
                            iguAmountWeiArg: valueToWei(values.amount),
                        },
                    },
                });
                setPricePredicted(true);
                stopLoading();
                onClose();
                navigation.push(ROUTES.TOKEN_TELLER_WAITING_FOR_RESULT, {
                    id: game?.id,
                    routeBack: ROUTES.TOKEN_TELLER_MAIN,
                });
            } catch (error) {
                stopLoading();
                onClose();
                await toastGameExpired();
                errorsHandler(error);
            }
        },
        [values, game?.id, routeBack]
    );

    const handleOnClose = useCallback(() => {
        if (purchaseLoading) {
            return;
        }
        onClose();
    }, [purchaseLoading]);

    React.useEffect(() => {
        if (isTimerEnd && (pricePredicted || game?.isPredicted)) {
            onClose();
            navigation.push(ROUTES.TOKEN_TELLER_WAITING_FOR_RESULT, {
                id: game?.id,
                routeBack: ROUTES.TOKEN_TELLER_MAIN,
            });
        }
    }, [game?.id, isTimerEnd, pricePredicted]);

    const isUpDirection = React.useMemo(
        () =>
            game?.predictedDirection ===
            TOKEN_TELLER_GAME_PREDICTION_DIRECTION.Up,
        [game?.predictedDirection]
    );

    const buttonPredictionTextAndStyle = React.useMemo(
        () =>
            isUpDirection
                ? {
                      text: TOKEN_TELLER_GAME_PREDICTION_DIRECTION.Up,
                      type: 'success',
                  }
                : {
                      text: TOKEN_TELLER_GAME_PREDICTION_DIRECTION.Down,
                      type: 'critical',
                  },
        [isUpDirection]
    );

    const commission = useMemo(
        () => calculateCommission(values.amount, tokenTellerFee),
        [tokenTellerFee, values.amount]
    );

    const totalSummary = useMemo(() => {
        return (Number(values.amount) + Number(commission)).toString();
    }, [values, commission]);
    const isEnoughBalance = React.useMemo(
        () => Number(maxAmount) >= Number(values.amount) + Number(commission),
        [commission, values, maxAmount]
    );

    const buttonDisabled = React.useMemo(
        () =>
            !isValid ||
            Number(values.amount) === 0 ||
            purchaseLoading ||
            isWarningTime,
        [isValid, values.amount, purchaseLoading, isWarningTime]
    );

    return {
        submitForm,
        values,
        errors,
        setFieldValue,
        setTouched,
        touched,
        isValid,
        dirty,
        handleChangeAmount,
        handleOnBlur,
        onSelectCardPress,
        handleOnBack,
        topUp,
        confirmModal,
        closeConfirmModal,
        openConfirmModal,
        purchaseLoading,
        handleOnClose,
        time,
        gameLoading,
        maxAmount,
        handleClickAll,
        maxAmountShort,
        buttonDisabled,
        onConfirmPress,
        direction,
        onButtonsPress,
        isWarningTime,
        game,
        buttonPredictionTextAndStyle,
        isEnoughBalance,
        totalSummary,
        commission,
        availableBalance,
    };
};
