import React from 'react';
import { Linking } from 'react-native';

import KnowledgeBaseIcon from '../../../../../../assets/icons/profile/knowledgeBase.svg';
import { useConfig } from '../../../../../../contexts/ConfigContext';
import i18n from '../../../../../../i18n/i18n';
import MenuItem from '../../../MenuItem';
import { RightComponent, iconSize } from '../../helpers';

const KnowledgeBaseMenuItem = () => {
    const { config } = useConfig();

    return (
        <MenuItem
            leftIcon={<KnowledgeBaseIcon width={iconSize} height={iconSize} />}
            title={i18n.t('profile.menuItems.knowledgeBase.title')}
            description={i18n.t('profile.menuItems.knowledgeBase.description')}
            rightComponent={<RightComponent type="explore" />}
            onPress={() => {
                if (config?.knowledgeBaseUrl) {
                    Linking.openURL(config.knowledgeBaseUrl);
                }
            }}
        />
    );
};

export default KnowledgeBaseMenuItem;
