import {
    DetectionResult,
    PaginationMeta,
    PaymentOptionOutput,
    PetAge,
    SignedTransactionOutput,
} from '@Data/Models';

export enum MARKETPLACE_PET_TYPE {
    AISystem = 'AISystem',
    FromPhoto = 'FromPhoto',
    Alive = 'Alive',
    Dead = 'Dead',
}

export enum MARKETPLACE_TAGS_TYPES {
    MARKETPLACE_PET_TYPE = 'MARKETPLACE_PET_TYPE',
    MARKETPLACE_ORDERS_SORT_OPTION = 'MARKETPLACE_ORDERS_SORT_OPTION',
    MARKETPLACE_RANKS = 'MARKETPLACE_RANKS',
}

export interface IMarketplaceTags {
    id: MARKETPLACE_TAGS_TYPES;
    key: string;
    title: string;
}

export enum MARKETPLACE_ORDERS_SORT_OPTION {
    FromLowestPrice = 'FromLowestPrice',
    FromHighestPrice = 'FromHighestPrice',
    RecentlyListed = 'RecentlyListed',
    EndingSoon = 'EndingSoon',
}

export type MarketplaceSaleablePetOutput = {
    id: string;
    imageUrl: string;
    rank: string;
    level: number;
    healthPercentage: number;
    ageDays: number;
    maxAgeDays: number;
    levelProgressPercentage: number;
    name: string;
};

export type MarketplaceSaleablePetOutputResponse = {
    marketplaceSaleablePets: MarketplaceSaleablePetOutput[];
};

export type MarketplaceOrderPetOutput = {
    id: string;
    imageUrl: string;
    rank: string;
    name: string;
    dead: boolean;
    ageDead: boolean;
};
export type MarketplaceOrderDataOutput = {
    id: string;
    pet: MarketplaceOrderPetOutput;
    sellerAddress: string;
    sellerUsername: string | null;
    priceIguAmountWei: string;
    deadline: string;
    cancelTransactionValuesStringified: string | null;
    createdAt: string;
};

export type PaginatedMarketplaceSearchOutput = {
    marketplaceFilteredActiveOrders: {
        items: MarketplaceOrderDataOutput[];
        meta: PaginationMeta;
    };
};

export type DataToSingOutput = {
    marketplaceOrderDataToSign: {
        domainDataStringified: string;
        typesStringified: string;
        valuesStringified: string;
    };
};

export type MarketplaceCreateOrder = {
    marketplaceCreateOrder: MarketplaceOrderDataOutput;
};

export type MarketplaceRanksOutput = {
    rank: string;
    count: number;
};

export type MarketplaceRanksOutputResponse = {
    marketplaceCountsForRanks: MarketplaceRanksOutput[];
};

export type MarketplaceNftDetailsOrderOutput = {
    priceIguAmountWei: string;
    deadline: string;
    createdAt: string;
    buyTransactionValuesStringified: string;
};

export type UserPublicProfileOutput = {
    username: string | null;
    walletAddress: string;
    avatarUrl: string | undefined;
    avatarThumbnailUrl: string | undefined;
    createdAt: string;
};

export type MarketplaceVolumeStatsOutput = {
    volumeIguAmountWei: string;
    volumeUsd: string;
    transactionCount: number;
};
export type MarketplaceOrdersStatsOutput = {
    boughtStats: MarketplaceVolumeStatsOutput;
    soldStats: MarketplaceVolumeStatsOutput;
};

export type MarketplaceNftDetailsSellerOutput = {
    profile: UserPublicProfileOutput;
    stats: MarketplaceOrdersStatsOutput;
};

export type MarketplaceNftDetailsFileMetadataDetectionResultOutput = {
    label: string;
    confidence: number;
};

export type MarketplaceNftDetailsPetOutput = {
    id: string;
    imageUrl: string;
    rank: string;
    level: number;
    healthPercentage: number;
    ageDays: number;
    maxAgeDays: number;
    levelProgressPercentage: number;
    name: string;
    isFromPhoto: boolean;
    nftDetectionResult: DetectionResult[] | null;
    currentLevelMaxExperience: number;
    dead: boolean;
    age: PetAge;
    ageDead: boolean;
    experience: number;
};

export type MarketplaceNftDetailsOutput = {
    order: MarketplaceNftDetailsOrderOutput;
    owner: MarketplaceNftDetailsSellerOutput;
    pet: MarketplaceNftDetailsPetOutput;
};
export type MarketplaceNftDetailsOutputResponse = {
    marketplaceNftDetails: MarketplaceNftDetailsOutput;
};

export type MarketplaceTransactionOutput = {
    priceIguAmountWei: string;
    soldUsdPrice: string;
    buyerAddress: string;
    boughtAt: string;
    buyer: UserPublicProfileOutput;
};

export type MarketplaceNftTransactionsOutput = {
    totalVolumeIgu: string;
    totalVolumeUsd: string;
    transactions: MarketplaceTransactionOutput[];
};

export type MarketplaceNftTransactionsOutputResponse = {
    marketplaceNftTransactionHistory: MarketplaceNftTransactionsOutput;
};

export type MarketplaceProfileOutput = {
    listedOrders: MarketplaceOrderDataOutput[];
    stats: MarketplaceOrdersStatsOutput;
    user: UserPublicProfileOutput;
};
export type MarketplaceProfileOutputResponse = {
    marketplaceProfile: MarketplaceProfileOutput;
};
