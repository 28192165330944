import * as React from 'react';
import { FlatList, TouchableOpacity } from 'react-native';

import Gallery from '../../../../../../assets/icons/play/gallery.svg';
import Photo from '../../../../../../assets/icons/play/photo.svg';
import { Text, View } from '../../../../../../components/Themed';
import { ImagePickerType } from '../../../../../../hooks/useImagePicker/types';
import useThemedStyles from '../../../../../../hooks/useThemedStyles';
import i18n from '../../../../../../i18n/i18n';
import stylesMain from './styles';

const Creation: ImagePickerType[] = ['camera', 'gallery'];

interface IProps {
    onPress: (imagePickerType: ImagePickerType) => void;
}

const CreationModalContent = ({ onPress }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const renderItem = (item: ImagePickerType, index: number) => {
        const icon = item === 'camera' ? <Photo /> : <Gallery />;

        return (
            <TouchableOpacity
                activeOpacity={0.7}
                onPress={() => onPress(item)}
                key={item}
                style={[styles.shadow, index === 0 && styles.gap]}>
                <View style={styles.itemContainer}>
                    {icon}
                    <Text style={styles.itemText}>
                        {i18n.t(
                            `socializeToEarn.shareYourPetPhoto.creationMethod.${item}`
                        )}
                    </Text>
                </View>
            </TouchableOpacity>
        );
    };

    return (
        <FlatList
            scrollEnabled={false}
            pagingEnabled={false}
            showsVerticalScrollIndicator={false}
            style={styles.listStyle}
            data={Creation}
            renderItem={({ item, index }) => renderItem(item, index)}
        />
    );
};

export default CreationModalContent;
