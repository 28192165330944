import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            paddingHorizontal: scale(20),
        },

        templateBlock: {
            flex: 1,
            overflow: 'hidden',
            borderRadius: moderateScale(12),
            marginVertical: verticalScale(20),
            marginHorizontal: scale(20),
            justifyContent: 'center',
        },
        template: {
            position: 'absolute',
            borderRadius: moderateScale(12),
        },
        safe: {
            backgroundColor: Colors[theme].background,
        },
        navBg: {
            backgroundColor: Colors[theme].background,
        },
        buttonWrapper: {
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
        },
        colorPickerWrapper: {
            paddingTop: verticalScale(16),
        },
    });

export default styles;
