import * as React from 'react';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';
import Animated, {
    cancelAnimation,
    useAnimatedStyle,
    useSharedValue,
    withDelay,
    withRepeat,
    withTiming,
} from 'react-native-reanimated';

import { Text, View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';

import stylesMain from './styles';

interface IProps {
    isLoading: boolean;
    staticText?: string;
    loadingText: string;
    textStyle?: StyleProp<TextStyle>;
    backgroundColor?: string;
    containerStyle?: StyleProp<ViewStyle>;
    allowFontScaling?: boolean;
}

export const AnimatedText = ({
    isLoading,
    staticText,
    loadingText,
    textStyle,
    backgroundColor,
    allowFontScaling,
    containerStyle,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const loadingTextShared = useSharedValue('100%');
    const loadingTextSharedStyle = useAnimatedStyle(() => {
        return {
            position: 'absolute',
            top: 0,
            right: 0,
            zIndex: 1,
            width: loadingTextShared.value,
            height: '100%',
            backgroundColor: backgroundColor || styles.bg.color,
        };
    });

    const cancel = () => {
        cancelAnimation(loadingTextShared);
        loadingTextShared.value = '100%';
    };
    React.useEffect(() => {
        loadingTextShared.value = withDelay(
            100,
            withRepeat(
                withTiming('0%', {
                    duration: 3000,
                }),
                -1
                // true
            )
        );
        if (!isLoading) {
            cancel();
        }
        return () => cancel();
    }, [isLoading]);

    return (
        <View style={[styles.main, containerStyle]}>
            {!!staticText && (
                <Text allowFontScaling={allowFontScaling} style={textStyle}>
                    {staticText}
                </Text>
            )}
            <View style={styles.texts}>
                <Text style={textStyle} allowFontScaling={allowFontScaling}>
                    {loadingText}
                </Text>
                <Animated.View style={loadingTextSharedStyle}></Animated.View>
            </View>
        </View>
    );
};
export default AnimatedText;
