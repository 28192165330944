import React from 'react';
import { SafeAreaView, ScrollView } from 'react-native';

import CustomModal from '@components/CustomModal';
import ImageIguana from '@components/ImageIguana';
import PlayEnergy from '@components/PlayEnergy';
import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import { MoveToEarnProgressProps } from '../../../../../types';
import PauseGameTimer from '../../PlayToEarn/components/PauseGameTimer';
import CircleTimer from '../components/CircleTimer';
import ProgressFooter from '../components/ProgressFooter';
import ProgressNavBar from '../components/ProgressNavBar';
import WalkInfo from '../components/WalkInfo';
import { MIN_SPEED } from '../constants';
import { formatDistanceTraveled } from '../helpers/moveHelper';
import { useProgressScreen } from './hook';
import stylesMain from './styles';

export const ProgressScreen = ({ route }: MoveToEarnProgressProps) => {
    const styles = useThemedStyles(stylesMain);

    const {
        problemWithLocationRenderModal,
        modalFirstButtonType,
        modalFirstButtonAction,
        handleModalSecondButton,
        isVisibleModal,
        modalType,
        moveTaskLoading,
        loadingCreateMove,
        onStop,
        onLeaveWithOutFinish,
        hideTimer,
        isPrepareTimerVisible,
        taskIsRunning,
        setRemainingTime,
        isPaused,
        speed,
        energyEarned,
        walkStatus,
        stepsWalked,
        distanceTraveledToShow,
        msg,
        togglePause,
        finishTask,
        moveTask,
    } = useProgressScreen(route.params.permissions);

    return (
        <View style={styles.container}>
            <ProgressNavBar onBack={onLeaveWithOutFinish} />
            <PauseGameTimer
                visibility={isPrepareTimerVisible}
                onTimerEnd={hideTimer}
                startTimer
                skip
                withOpacity
            />
            <ScrollView
                contentContainerStyle={styles.mainContent}
                showsVerticalScrollIndicator={false}>
                <CircleTimer
                    onComplete={finishTask}
                    setRemainingTime={setRemainingTime}
                    isWalkStopped={speed < MIN_SPEED || isPaused}
                    running={taskIsRunning}
                    maxTimeSeconds={
                        moveTask?.currentTask?.maxTimeSeconds || 900
                    }
                />

                <View style={styles.playEnergyWrapper}>
                    <PlayEnergy
                        energy={energyEarned}
                        maxEnergy={moveTask?.maxEnergyReward}
                    />
                </View>
                <WalkInfo
                    walkStatus={walkStatus}
                    speed={isPaused || speed < MIN_SPEED ? 0 : speed}
                    currentStepCount={stepsWalked}
                    distanceTraveled={formatDistanceTraveled(
                        distanceTraveledToShow
                    )}
                    pedometerDisabled={!route.params.permissions.pedometer}
                />
            </ScrollView>
            <ProgressFooter
                message={msg}
                onStop={onStop}
                isPaused={isPaused}
                onPause={togglePause}
            />
            <CustomModal
                isVisible={isVisibleModal}
                icon={<ImageIguana type="attention" />}
                titleText={i18n.t(`moveToEarn.modals.${modalType}`)}
                infoText={i18n.t(`moveToEarn.modals.${modalType}Text`)}
                firstButtonText={i18n.t(`moveToEarn.modals.${modalType}Btn1`)}
                secondButtonText={i18n.t(`moveToEarn.modals.${modalType}Btn2`)}
                firstButtonType={modalFirstButtonType}
                firstButtonLoading={moveTaskLoading || loadingCreateMove}
                firstButtonDisabled={moveTaskLoading || loadingCreateMove}
                onFirstButtonClick={modalFirstButtonAction}
                onSecondButtonClick={handleModalSecondButton}
            />
            {problemWithLocationRenderModal()}
            <SafeAreaView style={styles.safe} />
        </View>
    );
};

export default ProgressScreen;
