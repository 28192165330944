import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import { earnedExp } from '@helpers/calculateEarnedExp';
import { dateFormatter } from '@helpers/time';
import { StackActions, useNavigation } from '@react-navigation/native';

import Icon, { ICON_NAMES } from '../../../../components/Icons';
import TaskHistoryIcon from '../../../../components/Icons/TaskHistoryIcon';
import { Text, View } from '../../../../components/Themed';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import NavigationBar from '../../../../navigation/NavigationBar';
import ROUTES from '../../../../navigation/routes';
import { PlayTasksHistoryDetailsProps } from '../../../../types';
import stylesMain from './styles';

export const PlayTasksHistoryDetails = ({
    route,
}: PlayTasksHistoryDetailsProps) => {
    const styles = useThemedStyles(stylesMain);
    const {
        type,
        createdAt,
        energyRewarded,
        maxEnergyReward,
        petExperienceRewards,
    } = route.params.item;

    const date = React.useMemo(() => dateFormatter(createdAt), [createdAt]);

    const navigation = useNavigation();

    const exp = React.useMemo(
        () => earnedExp(petExperienceRewards),
        [petExperienceRewards]
    );

    const handleViewReward = () => {
        navigation.dispatch(StackActions.pop());
        navigation.dispatch(
            StackActions.replace(ROUTES.WALLET_ROOT, {
                screen: ROUTES.WALLET_REWARDS_TAB,
            })
        );
    };

    return (
        <>
            <NavigationBar
                title={i18n.t('playTasksHistory.historyTaskDetails.title')}
                backIcon
                containerStyle={styles.navBg}
            />
            <View style={styles.container}>
                <View style={styles.top}>
                    <View style={styles.energyIcon}>
                        <Icon name={ICON_NAMES.ENERGY} size={32} />
                    </View>
                    <View style={styles.energyCollectedWrapper}>
                        <Text style={styles.energyCollectedText}>
                            {i18n.t(
                                'playTasksHistory.historyTaskDetails.collectedEnergy',
                                {
                                    earnedEnergy: energyRewarded || 0,
                                    maxEnergy: maxEnergyReward || 0,
                                }
                            )}
                        </Text>
                    </View>

                    <Text style={styles.text}>
                        {i18n.t('playTasksHistory.historyTaskDetails.text')}
                    </Text>
                    <TouchableOpacity
                        onPress={handleViewReward}
                        activeOpacity={0.7}
                        style={styles.viewRewardWrapper}>
                        <Text style={styles.viewRewardText}>
                            {i18n.t(
                                'playTasksHistory.historyTaskDetails.viewRewardWaller'
                            )}
                        </Text>
                        <Icon
                            name={ICON_NAMES.ARROW_RIGHT_SECONDARY}
                            color={styles.icon.color}
                        />
                    </TouchableOpacity>
                </View>
                <View style={styles.detailsWrapper}>
                    <View style={styles.singleDetail}>
                        <Text style={styles.detailsName}>
                            {i18n.t(
                                'playTasksHistory.historyTaskDetails.details.type'
                            )}
                        </Text>
                        <View style={styles.detailsInfoWrapper}>
                            <Text style={styles.detailsInfo}>
                                {i18n.t(
                                    `playTasksHistory.historyTaskDetails.details.type_${type}`
                                )}
                            </Text>
                            <View style={styles.detailsIcon}>
                                <TaskHistoryIcon slug={type} size={20} />
                            </View>
                        </View>
                    </View>
                    <View style={styles.singleDetail}>
                        <Text style={styles.detailsName}>
                            {i18n.t(
                                'playTasksHistory.historyTaskDetails.details.petExp'
                            )}
                        </Text>
                        <View style={styles.detailsInfoWrapper}>
                            <Text
                                style={[
                                    styles.detailsInfo,
                                    exp && styles.detailsInfoAccent,
                                ]}>
                                {`${exp ? '+' : ''}${i18n.t(
                                    'playTasksHistory.historyTaskDetails.details.exp',
                                    {
                                        exp,
                                    }
                                )}`}
                            </Text>
                            <View style={styles.detailsIcon}>
                                <Icon name={ICON_NAMES.LEVEL} size={20} />
                            </View>
                        </View>
                    </View>
                    <View style={styles.singleDetail}>
                        <Text style={styles.detailsName}>
                            {i18n.t(
                                'playTasksHistory.historyTaskDetails.details.date'
                            )}
                        </Text>
                        <View style={styles.detailsInfoWrapper}>
                            <Text style={styles.detailsInfo}>{date}</Text>
                        </View>
                    </View>
                </View>
            </View>
        </>
    );
};
export default PlayTasksHistoryDetails;
