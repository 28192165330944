import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import Colors from '../../../../constants/Colors';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        divider: {
            marginVertical: verticalScale(16),
        },
        container: {
            paddingHorizontal: scale(0),
            borderWidth: 0,
        },
        petItem: {
            marginBottom: verticalScale(10),
        },
        transactionDetailsWrapper: {
            marginBottom: scale(20),
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            borderWidth: 1,
            borderRadius: 12,
            borderColor: Colors[theme].border.primary,
        },
    });

export default styles;
