import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';
import { LUCKY_STRIKE_GAMES_STATUS } from '@models/mysteryGames';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        safe: {
            flex: 1,
            backgroundColor: Colors[theme].background,
        },

        main: {
            flex: 1,
            backgroundColor: 'transparent',
            marginTop: verticalScale(32),
        },
        block: {
            flex: 1,
            backgroundColor: 'transparent',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            paddingHorizontal: scale(20),
        },

        transparent: {
            backgroundColor: 'transparent',
        },
        additionalButton: {
            marginTop: verticalScale(16),
            alignSelf: 'center',
        },
        additionalButtonText: {
            color: Colors[theme].text.onLight,
        },
        bold: {
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
        },
        accent: {
            color: Colors[theme].text.accent,
        },
        animation: {
            width: 230,
        },
        container: {
            paddingHorizontal: scale(20),
            marginTop: verticalScale(32),
        },
        divider: {
            marginVertical: verticalScale(24),
        },
        firstState: {
            position: 'absolute',
            zIndex: 100,
            width: '100%',
            height: '100%',
        },
    });

export default styles;
