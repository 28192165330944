import { REGEX_PATTERNS } from '../constants/Regex';
import { SOCIAL_BASE_LINKS } from '../constants/Socials';

const SOCIAL_PATTERN = '.com/';

export const getSocialLink = (platform: string, username: string) => {
    const upperCasePlatform = platform.toUpperCase();
    const link = `${
        SOCIAL_BASE_LINKS[upperCasePlatform as keyof object]
    }${username}`;
    return link;
};

export const getSocialUsername = (value: string, oldValue: string) => {
    let newValue = value;
    if (value.includes(SOCIAL_PATTERN)) {
        const index = value.indexOf(SOCIAL_PATTERN) + SOCIAL_PATTERN.length;
        newValue = value.substring(index);

        newValue = newValue.split(/\?|\//)[0];
    }
    if (!newValue.match(REGEX_PATTERNS.verifySocialAdmin.regex)) {
        return oldValue;
    }
    return newValue.replace('@', '');
};
