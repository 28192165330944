import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import { Pet } from '@Data/Models';
import Button from '@components/Button';
import { ICON_NAMES } from '@components/Icons';
import Tooltip from '@components/Tooltip';
import { waitForModal } from '@helpers/helpers';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import AsyncStorage from '@react-native-async-storage/async-storage';

import ModalCollectionMultiplePetsHealUpInfo from '../ModalCollectionMultiplePetsHealUpInfo';
import Styles from './styles';

interface IProps {
    pets: Pet[] | undefined;
}

const screenConfig = {
    multipleHealthInfoShowAgainStorageKey:
        'CollectionAllScreen.multipleHealthShowAgainStorageKey',
    timeout: 10000,
};

const HealAllButton = ({ pets }: IProps) => {
    const navigation = useNavigation();
    const styles = useThemedStyles(Styles);

    const {
        isVisible: isHealAllModalVisible,
        open: openHealAllModal,
        close: closeHealAllModal,
    } = useVisibleHook();
    const {
        isVisible: isTooltipVisible,
        open: openTooltip,
        close: closeTooltip,
    } = useVisibleHook();

    const showHealAllPets = React.useMemo(() => {
        if (!pets) return false;
        return (
            pets?.filter((p) => p.health < 100 && !p.dead && !p.ageDead)
                ?.length > 1
        );
    }, [pets]);

    const handleHealAll = async () => {
        const status = await AsyncStorage.getItem(
            screenConfig.multipleHealthInfoShowAgainStorageKey
        );

        if (status !== 'true') {
            openHealAllModal();
            return;
        }
        openMultiplePetsHealUpCheckout(false);
    };

    const openMultiplePetsHealUpCheckout = async (dontShowAgain: boolean) => {
        if (dontShowAgain) {
            await AsyncStorage.setItem(
                screenConfig.multipleHealthInfoShowAgainStorageKey,
                'true'
            );
        }
        closeHealAllModal();
        await waitForModal();
        navigation.navigate(ROUTES.COLLECTION_MULTIPLE_PETS_HEAL_UP_CHECKOUT);
    };

    return (
        <>
            <Tooltip
                placement="bottom"
                isVisible={isTooltipVisible}
                text={i18n.t('collectionsScreen.healAllButtonTooltip')}
                timeout={screenConfig.timeout}
                onClose={closeTooltip}>
                {!showHealAllPets && (
                    <TouchableOpacity
                        style={styles.hiddenButton}
                        activeOpacity={1}
                        onPress={openTooltip}
                    />
                )}
                <Button
                    size="md"
                    type="fill"
                    iconName={ICON_NAMES.FEED}
                    iconColor="onDark"
                    onPress={handleHealAll}
                    debouncedPress
                    disabled={!showHealAllPets}
                />
            </Tooltip>
            <ModalCollectionMultiplePetsHealUpInfo
                isVisible={isHealAllModalVisible}
                closeModal={closeHealAllModal}
                primaryActionModal={() => openMultiplePetsHealUpCheckout(false)}
                secondaryActionModal={() =>
                    openMultiplePetsHealUpCheckout(true)
                }
            />
        </>
    );
};
export default HealAllButton;
