import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { FlatList, TouchableOpacity } from 'react-native';

import { useWallet } from '../../contexts/Wallet/WalletContext';
import { CoinBalance } from '../../contexts/Wallet/WalletHelpers';
import useThemedStyles from '../../hooks/useThemedStyles';
import i18n from '../../i18n/i18n';
import Icon, { ICON_NAMES } from '../Icons';
import CoinIcon from '../Icons/CoinIcon';
import ModalBottom from '../ModalBottom';
import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    onPress: (coin: CoinBalance) => void;
    balances?: CoinBalance[];
    titleText?: string;
    modalHeight?: number;
    selectedCoin?: CoinBalance;
    children?: React.ReactNode;
}
interface IRenderItem {
    item: CoinBalance | undefined;
    index: number;
}

const ModalCoinSelect = ({
    isVisible,
    setIsVisible,
    onPress,
    balances,
    titleText = i18n.t('ModalCoinSelect.select'),
    modalHeight = 550,
    selectedCoin,
    children,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { getCoinBalances } = useWallet();

    const currentBalances = balances ?? getCoinBalances();

    const handleItemPress = (coin: CoinBalance) => {
        if (onPress) onPress(coin);
    };

    const renderItem = ({ item, index }: IRenderItem) => {
        if (!item) {
            return null;
        }

        const isSelected = selectedCoin?.name === item?.name;

        return (
            <TouchableOpacity
                activeOpacity={0.7}
                style={[
                    styles.shadow,
                    index + 1 !== currentBalances.length && styles.gap,
                    isSelected && styles.selectedShadow,
                ]}
                onPress={() => handleItemPress(item)}>
                <View
                    style={[
                        styles.itemContainer,
                        isSelected && styles.selected,
                    ]}>
                    <View style={styles.coin}>
                        <CoinIcon slug={item.name} size={32} />
                        <Text style={styles.coinTitle}>{item.name}</Text>
                    </View>
                    <View style={styles.rightWrapper}>
                        <Text
                            style={
                                styles.coinAmount
                            }>{`${item.presentationValueLong} ${item.name}`}</Text>
                        <Icon
                            name={ICON_NAMES.ARROW_RIGHT_SECONDARY}
                            color={styles.arrowRight.color}
                        />
                    </View>
                </View>
            </TouchableOpacity>
        );
    };

    return (
        <>
            {currentBalances && (
                <ModalBottom
                    isVisible={isVisible}
                    onClose={() => setIsVisible(false)}
                    modalHeight={modalHeight}
                    titleText={titleText}>
                    <>
                        <FlatList
                            scrollEnabled={false}
                            style={styles.listStyle}
                            data={currentBalances}
                            renderItem={renderItem}
                        />
                        {children}
                    </>
                </ModalBottom>
            )}
        </>
    );
};

export default ModalCoinSelect;
