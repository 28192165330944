import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import { TasksHistoryResponseType } from '@Data/Models';
import TaskHistoryIcon from '@components/Icons/TaskHistoryIcon';
import { Text, View } from '@components/Themed';
import { isWeb } from '@helpers/app';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import EnergyBlock from '../../../Play/components/EnergyBlock';
import stylesMain from './styles';

interface IProps {
    onPress: () => void;
    taskName: TasksHistoryResponseType;
    maxEnergy: number;
    collectedEnergy: number | undefined;
    isLast: boolean;

    getHeight: (height: number) => void;
    minHeight: number;
}

const DailyTaskCard = ({
    onPress,
    taskName,
    maxEnergy,
    collectedEnergy,
    isLast,
    getHeight,
    minHeight,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <TouchableOpacity
            onLayout={({ nativeEvent }) => {
                getHeight(nativeEvent.layout.height);
            }}
            activeOpacity={0.7}
            style={[styles.shadow, !isLast && styles.margin, { minHeight }]}
            onPress={!isWeb ? onPress : undefined}
            onLongPress={isWeb ? onPress : undefined}>
            <View style={[styles.container, minHeight > 150 && styles.flex]}>
                <View style={styles.top}>
                    <TaskHistoryIcon slug={taskName} size={40} />

                    <View style={styles.textWrapper}>
                        <Text style={styles.title} numberOfLines={1}>
                            {i18n.t(`HomeScreen.DailyTasks.${taskName}.title`)}
                        </Text>
                        <Text style={styles.text} numberOfLines={2}>
                            {i18n.t(`HomeScreen.DailyTasks.${taskName}.text`)}
                        </Text>
                    </View>
                </View>
                <EnergyBlock
                    energy={i18n.t(`HomeScreen.DailyTasks.collected`, {
                        collectedEnergy: collectedEnergy || 0,
                        maxEnergy,
                    })}
                    containerStyles={styles.energyBlock}
                    textStyles={styles.energyText}
                />
            </View>
        </TouchableOpacity>
    );
};
export default DailyTaskCard;
