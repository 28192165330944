import * as React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import Icon from '@assets/icons/emptyState/howToTopUp.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import GamingWalletChangeFeature from '@components/GamingWalletChangeFeature';
import ModalBottom from '@components/ModalBottom';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
    modalHeight?: number | string;
}

const HowToTopUpWallet = ({
    isVisible,
    onClose,
    modalHeight = 600,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <ModalBottom
            isVisible={isVisible}
            onClose={onClose}
            modalHeight={modalHeight}>
            <ScrollView
                style={styles.container}
                showsVerticalScrollIndicator={false}>
                <TouchableOpacity activeOpacity={1}>
                    <BlockHeader
                        type="emptyState"
                        title={i18n.t(
                            'wallet.gaming.TopUpGamingWalletScreen.howToModal.title'
                        )}
                        text={i18n.t(
                            'wallet.gaming.TopUpGamingWalletScreen.howToModal.text'
                        )}
                        emptyStateIcon={<Icon />}
                    />
                    <GamingWalletChangeFeature customStyles={styles.feature} />
                </TouchableOpacity>
            </ScrollView>
        </ModalBottom>
    );
};

export default HowToTopUpWallet;
