import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import RankIcon from '@components/Icons/RankIcon';
import { Text, View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT } from '@components/TypoText/types';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { BreedingPetsPairStatsOutputChances } from '@models/mysteryGames';

import NftEvolutionChance from '../NftEvolutionChance';
import stylesMain from './styles';

interface IProps {
    containerStyle?: StyleProp<ViewStyle>;

    data: BreedingPetsPairStatsOutputChances[];
    title: string;
    text: string;
}

const ChancesToBreedTable = ({ containerStyle, data, title, text }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const renderRow = (item: BreedingPetsPairStatsOutputChances) => {
        return (
            <View style={styles.row} key={item.rankId}>
                <View style={styles.rankRow}>
                    <RankIcon slug={item.rankId} size={32} />
                    <Text style={styles.rankName}>
                        {i18n.t(`ranks.list.${item.rankId}.title`)}
                    </Text>
                </View>

                <NftEvolutionChance amount={item.winChance} />
            </View>
        );
    };

    return (
        <View style={[styles.container, containerStyle]}>
            <View style={styles.description}>
                <TypoText
                    type={TYPO_TEXT.HEADING_H6}
                    textAlign="center"
                    additionalStyles={styles.textGap}>
                    {title}
                </TypoText>
                <TypoText type={TYPO_TEXT.BODY_SMALL} textAlign="center">
                    {text}
                </TypoText>
            </View>
            <View style={styles.header}>
                <Text style={[styles.headerText, styles.ranks]}>
                    {i18n.t('general.KEYS.ranks')}
                </Text>

                <Text style={[styles.headerText, styles.chance]}>
                    {i18n.t('general.KEYS.chance')}
                </Text>
            </View>
            {data.map((item) => renderRow(item))}
        </View>
    );
};
export default ChancesToBreedTable;
