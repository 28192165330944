import { StyleSheet } from 'react-native';

import { scale } from '../../helpers/dimensions';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        item: {
            flex: 1,
            flexDirection: 'row',
        },
        inputContainer: {
            width: '100%',
        },
        textareaInput: {
            textAlignVertical: 'top',
            paddingRight: scale(40),
        },
        copyButton: {
            backgroundColor: 'transparent',
            position: 'absolute',
            right: 15,
            top: 15,
            zIndex: 2,
            elevation: 2,
        },
    });

export default styles;
