import * as React from 'react';
import { SafeAreaView } from 'react-native';

import Success from '@assets/icons/play/successTaskShare.svg';
import Button from '@components/Button';
import PlayEnergy from '@components/PlayEnergy';
import { View } from '@components/Themed';
import { useUser } from '@contexts/UserContext';
import { toastSuccess } from '@helpers/toastNotification';
import useVerifyTask from '@hooks/tasks/useVerifyTask';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { StackActions } from '@react-navigation/native';

import EmptyStatePlay from '../../components/EmptyStatePlay';
import stylesMain from './styles';

export const ShareYourPhotoSuccessScreen = () => {
    const styles = useThemedStyles(stylesMain);
    const { user } = useUser();

    const navigation = useNavigation();

    const handleBackToSharing = () => {
        navigation.dispatch(StackActions.pop());
    };

    const handleBackToTask = () => {
        navigation.dispatch(StackActions.pop(2));
    };
    const {
        createVerifyTask,
        canStartNextTask,
        taskReadyToVerify,
        goToWelcomeScreen,
        createVerificationTaskLoading,
    } = useVerifyTask();
    const handleVerifyOtherUsers = React.useCallback(async () => {
        handleBackToTask();
        if (!canStartNextTask) {
            toastSuccess(
                i18n.t(
                    'socializeToEarn.shareYourPetPhoto.shareSuccess.toastSuccess'
                )
            );
            return;
        }
        if (canStartNextTask) {
            await createVerifyTask({
                onError: () =>
                    navigation.navigate(
                        ROUTES.SOCIALIZE_TO_EARN_VERIFY_USER_ERROR
                    ),
                onCompleted: () =>
                    navigation.navigate(ROUTES.SOCIALIZE_TO_EARN_VERIFY_USER),
            });
            return;
        }

        if (taskReadyToVerify) {
            navigation.navigate(ROUTES.SOCIALIZE_TO_EARN_VERIFY_USER);
            return;
        }

        if (goToWelcomeScreen) {
            navigation.navigate(ROUTES.SOCIALIZE_TO_EARN_WELCOME_VERIFY);
            return;
        }
    }, [canStartNextTask, taskReadyToVerify, goToWelcomeScreen]);

    return (
        <>
            <NavigationBar filled={false} backIcon="leftArrow" />
            <SafeAreaView style={styles.safe}>
                <View style={styles.main}>
                    <EmptyStatePlay
                        icon={<Success />}
                        title={i18n.t(
                            'socializeToEarn.shareYourPetPhoto.shareSuccess.title'
                        )}
                        text={i18n.t(
                            'socializeToEarn.shareYourPetPhoto.shareSuccess.text'
                        )}
                    />

                    <PlayEnergy
                        energy={user?.energy || 0}
                        containerStyle={styles.energyWrapper}
                    />
                </View>

                <View style={styles.buttonWrapper}>
                    {!canStartNextTask ? (
                        <Button
                            title={i18n.t(
                                'socializeToEarn.shareYourPetPhoto.shareSuccess.button3'
                            )}
                            onPress={handleBackToTask}
                            containerStyle={styles.gap}
                        />
                    ) : (
                        <Button
                            title={i18n.t(
                                'socializeToEarn.shareYourPetPhoto.shareSuccess.button1'
                            )}
                            onPress={handleVerifyOtherUsers}
                            debouncedPress
                            containerStyle={styles.gap}
                            disabled={createVerificationTaskLoading}
                            loading={createVerificationTaskLoading}
                        />
                    )}

                    <Button
                        title={i18n.t(
                            'socializeToEarn.shareYourPetPhoto.shareSuccess.button2'
                        )}
                        type="outline"
                        onPress={handleBackToSharing}
                    />
                </View>
            </SafeAreaView>
        </>
    );
};
export default ShareYourPhotoSuccessScreen;
