import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Keyboard } from 'react-native';

import { isDesktop } from '@helpers/app';

import useThemedStyles from '../../hooks/useThemedStyles';
import i18n from '../../i18n/i18n';
import Button from '../Button';
import ExtendedModal from '../ExtendedModal';
import LoadingComponent from '../LoadingComponent';
import { View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    text?: string | undefined;
    isVisible: boolean;
    setIsVisible?: Dispatch<SetStateAction<boolean>>;
    timeout?: number;
    useFullScreenOverlay?: boolean;
}

const config = {
    timeout: 25000,
};

const ModalLoader = ({
    text = undefined,
    isVisible,
    setIsVisible,
    timeout = config.timeout,
    useFullScreenOverlay = true,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const [loadingTimeout, setLoadingTimeout] = React.useState<boolean>(false);
    const [cancelClicked, setCancelClicked] = React.useState<boolean>(false);

    const showModal = isVisible && !cancelClicked;

    // Add cancel bottom which appears when modal is loading longer than timeout
    React.useEffect(() => {
        let isMounted = true;
        let timeoutHandler: ReturnType<typeof setTimeout>;

        if (isVisible) {
            Keyboard.dismiss();
            Console.log('[ModalLoading] Start loading');
            if (timeout) {
                timeoutHandler = setTimeout(() => {
                    Console.log('[ModalLoading] Loading timeout');
                    if (isMounted) setLoadingTimeout(true);
                }, timeout);
            }
        } else {
            setLoadingTimeout(false);
        }

        return () => {
            if (timeoutHandler) {
                clearTimeout(timeoutHandler);
            }
            isMounted = false;
        };
    }, [isVisible, timeout]);

    // cancel loading if timeout
    const cancelLoader = () => {
        // if not setter param than we handle it with inside state, however modal won't be opened second time
        if (!setIsVisible) {
            setCancelClicked(true);
        } else {
            setIsVisible(false);
        }
    };

    return (
        <ExtendedModal
            style={styles.modal}
            isVisible={showModal}
            customBackdrop={<></>}
            animationIn={isDesktop ? 'fadeIn' : 'bounceInLeft'}
            animationOut={isDesktop ? 'fadeOut' : 'bounceOutRight'}
            animationInTiming={300}
            animationOutTiming={700}
            backdropTransitionInTiming={100}
            backdropTransitionOutTiming={100}
            useFullScreenOverlay={useFullScreenOverlay}>
            <View style={styles.shadow}>
                <View style={styles.container}>
                    <LoadingComponent
                        text={text}
                        imageStyles={styles.iconWrapper}
                        isPlaying={isVisible && !cancelClicked}
                    />
                    {loadingTimeout && !!timeout && (
                        <View style={styles.cancelLoader}>
                            <Button
                                onPress={cancelLoader}
                                title={i18n.t('general.cancel')}
                                type="text"
                                size="md"
                                textStyles={styles.buttonLeave}
                                style={styles.buttonGap}
                                containerStyle={styles.buttonContainer}
                            />
                        </View>
                    )}
                </View>
            </View>
        </ExtendedModal>
    );
};
export default ModalLoader;
