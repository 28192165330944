import { useStaking } from '@contexts/StakingContext';
import { ITransactionResult } from '@contexts/Wallet/WalletContext';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

export const useUnStakingSuccessScreen = (
    transactionResult: ITransactionResult
) => {
    const navigation = useNavigation();
    const { refetchStakingPlans } = useStaking();

    const handleBackToWallet = async () => {
        await refetchStakingPlans();
        navigation.reset({
            index: 1,
            routes: [{ name: ROUTES.MAIN }, { name: ROUTES.WALLET_ROOT }],
        });
    };

    navigation.blockHardwareBack();
    return {
        handleBackToWallet,
        transactionResult,
    };
};
