import * as React from 'react';
import { StyleSheet, TextStyle, TouchableWithoutFeedback } from 'react-native';

import Colors from '../../constants/Colors';
import useThemedStyles from '../../hooks/useThemedStyles';
import { theme } from '../../types';
import { Text } from '../Themed';

interface ILink {
    onPress?: () => void;
    onLongPress?: () => void;
    children: string;
    textStyles?: TextStyle | undefined;
}

const Link: React.FunctionComponent<ILink> = ({
    onPress = () => null,
    onLongPress = () => null,
    children,
    textStyles = undefined,
}) => {
    const styles = useThemedStyles(stylesMain);
    return (
        <TouchableWithoutFeedback onPress={onPress} onLongPress={onLongPress}>
            <Text style={[styles.link, textStyles]}>{children}</Text>
        </TouchableWithoutFeedback>
    );
};

const stylesMain = (theme: theme) =>
    StyleSheet.create({
        link: {
            textDecorationLine: 'underline',
            color: Colors[theme].text.primary,
        },
    });

export default Link;
