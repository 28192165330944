import * as React from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';

import ArrowLeftYellow from '../../../../../assets/icons/play/arrowLeftYellow.svg';
import { Text, View } from '../../../../../components/Themed';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import stylesMain from './styles';

interface IProps {
    onPress: () => void;
    collectedExp: number;
    containerStyles?: StyleProp<ViewStyle>;
}

const SuccessTaskPetsExp = ({
    collectedExp,
    containerStyles,
    onPress,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={[styles.container, containerStyles]}>
            <Text style={styles.text}>{i18n.t('successTaskPetsExp.text')}</Text>
            <TouchableOpacity
                style={styles.energyCollected}
                activeOpacity={0.8}
                onPress={onPress}>
                <Text style={styles.textEnergyCollected}>
                    {i18n.t('successTaskPetsExp.collectedExp', {
                        collectedExp,
                    })}
                </Text>
                <ArrowLeftYellow />
            </TouchableOpacity>
        </View>
    );
};

export default SuccessTaskPetsExp;
