import * as React from 'react';

import { Text, View } from '../../../components/Themed';
import useThemedStyles from '../../../hooks/useThemedStyles';
import stylesMain from './styles';

interface IProps {
    title: string;
    color?: string;
    tail?: boolean;
}

const NavigationBarTitle: React.FunctionComponent<IProps> = ({
    title,
    color,
    tail,
}) => {
    const styles = useThemedStyles(stylesMain);
    return (
        <View style={styles.titleWrapper}>
            {tail ? (
                <Text
                    style={[styles.title, color && { color }]}
                    numberOfLines={1}
                    ellipsizeMode={'tail'}>
                    {title}
                </Text>
            ) : (
                <Text
                    style={[
                        styles.title,
                        color && { color },
                        !tail && styles.titleNoTail,
                    ]}>
                    {title}
                </Text>
            )}
        </View>
    );
};

export default NavigationBarTitle;
