import { StyleSheet } from 'react-native';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        carousel: {
            justifyContent: 'flex-start',
        },
    });

export default styles;
