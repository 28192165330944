import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import Spinner from 'react-spinkit';

import Colors from '../../constants/Colors';
import { useTheme } from '../../contexts/ThemeContext';
import useThemedStyles from '../../hooks/useThemedStyles';
import i18n from '../../i18n/i18n';
import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    text?: string;
    containerStyles?: StyleProp<ViewStyle>;
    imageStyles?: StyleProp<ViewStyle>;
    isPlaying?: boolean;
    fullPage?: boolean;
}

const LoadingComponent = ({
    text = i18n.t('general.loading'),
    containerStyles,
    imageStyles,
    isPlaying = true,
    fullPage = false,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { theme } = useTheme();

    const renderLoadingElement = () => (
        <View style={[styles.container, containerStyles]}>
            <View style={[styles.animationWrapper, { left: 0 }, imageStyles]}>
                <View style={styles.webWrapper}>
                    <Spinner
                        name="wave"
                        style={{ width: 30, height: 30 }}
                        color={Colors[theme].text.accent}
                        fadeIn="none"
                    />
                </View>
            </View>
            {text && <Text style={styles.text}>{text}</Text>}
        </View>
    );

    if (fullPage) {
        return (
            <SafeAreaView style={styles.fullPage}>
                {renderLoadingElement()}
            </SafeAreaView>
        );
    }

    return renderLoadingElement();
};
export default LoadingComponent;
