import React from 'react';

import LevelIndicator from '@components/LevelIndicator';
import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MarketplaceNftDetailsPetOutput } from '@models/marketplace';

import DetailsBlock from '../../../Collection/components/DetailsBlock';
import Styles from './styles';

interface IProps {
    pet: MarketplaceNftDetailsPetOutput;
}

const OverviewLevelDetailsBlock = ({ pet }: IProps) => {
    const styles = useThemedStyles(Styles);

    const {
        level,
        levelProgressPercentage,
        experience,
        currentLevelMaxExperience,
    } = pet;

    return (
        <DetailsBlock
            title={i18n.t('detailedPet.levelXP.title')}
            description={i18n.t('detailedPet.levelXP.text')}>
            <View style={styles.gap}>
                <LevelIndicator
                    level={level}
                    experience={experience}
                    maxExperience={currentLevelMaxExperience}
                    fillAmount={levelProgressPercentage}
                    fullView
                    iconSize={24}
                />
            </View>
        </DetailsBlock>
    );
};
export default OverviewLevelDetailsBlock;
