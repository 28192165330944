import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';
import { stylesAlignCenter } from '@helpers/style';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingLeft: scale(20),
        },
        searchContainer: {
            flexDirection: 'row',
        },
        filterIcon: {
            color: Colors[theme].icon.primary,
        },
        buttonWrapper: {
            marginRight: scale(20),
        },
        buttonBlock: {
            borderRadius: 8,
            marginLeft: scale(8),
            flex: 1,
            minWidth: 48,
        },
        button: {
            borderRadius: 8,
            flex: 1,
            minWidth: 0,
            minHeight: 0,
            marginBottom: 2,
        },
        input: {
            flex: 1,
        },
        amount: {
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),
            marginLeft: 4,
        },

        filterCount: {
            position: 'absolute',
            width: scale(20),
            height: scale(20),
            backgroundColor: Colors[theme].datavizProgressWeak,
            borderRadius: 1000,
            ...stylesAlignCenter(false),
            zIndex: 100,
            top: -8,
            right: -8,
        },
        filterCountText: {
            color: Colors[theme].text.onLight,
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),
        },
        searchTags: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: verticalScale(16),
        },
    });

export default styles;
