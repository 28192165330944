import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import Icon, { ICON_NAMES } from '@components/Icons';
import { Text, View } from '@components/Themed';
import { useHaptic } from '@hooks/useHaptic';
import useThemedStyles from '@hooks/useThemedStyles';

import stylesMain from './styles';

interface IProps {
    title: string;
    isLast?: boolean;
    onPress: () => void;
}

const FilterItem = ({ title, onPress }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { triggerLight } = useHaptic();

    const handleClick = () => {
        triggerLight();
        onPress();
    };

    return (
        <View style={styles.shadow}>
            <View style={styles.container}>
                <Text style={styles.title}>{title}</Text>
                <TouchableOpacity activeOpacity={0.7} onPress={handleClick}>
                    <Icon
                        name={ICON_NAMES.CLOSE}
                        size={12}
                        color={styles.icon.color}
                    />
                </TouchableOpacity>
            </View>
        </View>
    );
};

export default FilterItem;
