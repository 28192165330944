import * as React from 'react';

import { Rank } from '../../../../Data/Models';
import Animation from '../../../../components/Animation';
import CustomModal from '../../../../components/CustomModal';
import { useWallet } from '../../../../contexts/Wallet/WalletContext';
import { Coin } from '../../../../contexts/Wallet/WalletHelpers';
import { stylesAnimation } from '../../../../helpers/style';
import { balanceFromWei } from '../../../../helpers/wallet';
import i18n from '../../../../i18n/i18n';

interface IProps {
    isVisible: boolean;
    highestRank: Rank | undefined;
    actionFirst: () => void;
    actionSecond: () => void;
}

const ModalFreeRank = ({
    isVisible,
    highestRank,
    actionFirst,
    actionSecond,
}: IProps) => {
    const { priceInUsd } = useWallet();

    if (!highestRank?.mintBonus) {
        return null;
    }

    const bonusValueRank = balanceFromWei(
        highestRank?.mintBonus?.igupAmountWei
    ).valueLong;
    const bonusValueRankUsd = priceInUsd(Coin.igup, bonusValueRank);

    return (
        <CustomModal
            isVisible={isVisible}
            icon={<Animation animation="cry" style={stylesAnimation()} />}
            titleText={i18n.t('modal.ModalFreeRank.title')}
            infoText={i18n.t('modal.ModalFreeRank.text', {
                amount: bonusValueRank,
                dollarValue: bonusValueRankUsd,
            })}
            firstButtonText={i18n.t('modal.ModalFreeRank.highest')}
            secondButtonText={i18n.t('modal.ModalFreeRank.proceed')}
            onFirstButtonClick={actionFirst}
            onSecondButtonClick={actionSecond}
        />
    );
};
export default ModalFreeRank;
