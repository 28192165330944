import * as React from 'react';

import SuccessOrdersIcon from '@assets/icons/marketplace/successOrders.svg';
import CoinIcon from '@components/Icons/CoinIcon';
import OtherUserAvatar from '@components/OtherUserAvatar';
import StatisticsCard from '@components/StatisticsCard';
import { Text, View } from '@components/Themed';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { scale } from '@helpers/dimensions';
import { priceString } from '@helpers/payments';
import { dateFormatterDayMonthYear } from '@helpers/time';
import { truncateEthAddress } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import {
    MarketplaceOrdersStatsOutput,
    UserPublicProfileOutput,
} from '@models/marketplace';

import {
    calculateTotalOrders,
    calculateTotalSummary,
    calculateTotalSummaryUSD,
} from '../../helpers/helper';
import stylesMain from './styles';

interface IProps {
    stats: MarketplaceOrdersStatsOutput;
    userProfile: UserPublicProfileOutput;
}

const ProfileUserBlock = ({ stats, userProfile }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const totalSummary = React.useMemo(
        () =>
            calculateTotalSummary(
                stats.boughtStats.volumeIguAmountWei,
                stats.soldStats.volumeIguAmountWei
            ),
        [stats]
    );

    const totalSummaryUSD = React.useMemo(
        () =>
            calculateTotalSummaryUSD(
                stats.boughtStats.volumeUsd,
                stats.soldStats.volumeUsd
            ),
        [stats]
    );
    const totalOrders = React.useMemo(
        () =>
            calculateTotalOrders(
                stats.boughtStats.transactionCount,
                stats.soldStats.transactionCount
            ),
        [stats]
    );

    const date = React.useMemo(
        () => dateFormatterDayMonthYear(userProfile.createdAt),
        [userProfile.createdAt]
    );

    return (
        <View style={styles.container}>
            <View style={styles.ownersProfileWrapper}>
                <OtherUserAvatar
                    size={scale(88)}
                    imageUrl={userProfile?.avatarThumbnailUrl}
                    userId={userProfile.walletAddress}
                />
                <View style={styles.ownersProfile}>
                    <Text style={styles.ownersProfileTitle} numberOfLines={1}>
                        {truncateEthAddress(
                            userProfile.username || userProfile.walletAddress
                        )}
                    </Text>
                    <Text style={styles.ownersProfileLabel}>
                        {i18n.t('MarketPlace.MarketplaceProfile.joined', {
                            date,
                        })}
                    </Text>
                </View>
            </View>

            <View style={styles.summaryWrapper}>
                <StatisticsCard
                    containerStyle={styles.cardSize}
                    icon={<CoinIcon slug={Coin.igu} size={32} />}
                    title={i18n.t('general.KEYS.totalSummary')}
                    amount={priceString(totalSummary, Coin.igu)}
                    usdPrice={totalSummaryUSD}
                />
                <StatisticsCard
                    containerStyle={styles.cardSize}
                    icon={<SuccessOrdersIcon width={32} height={32} />}
                    title={i18n.t('general.KEYS.totalSuccessfulOrders')}
                    amount={`${totalOrders} ${i18n.t('general.KEYS.NFTs')}`}
                />
            </View>
        </View>
    );
};

export default ProfileUserBlock;
