import * as React from 'react';
import { TouchableOpacity } from 'react-native';
import Animated, {
    cancelAnimation,
    runOnJS,
    useAnimatedStyle,
    useSharedValue,
    withDelay,
    withRepeat,
    withTiming,
} from 'react-native-reanimated';

import StarsIcon from '@assets/icons/mysteryBoxes/stars.svg';
import ButtonsBottom from '@components/ButtonsBottom';
import ExtendedModal from '@components/ExtendedModal';
import CoinIcon from '@components/Icons/CoinIcon';
import { View } from '@components/Themed';
import Colors from '@constants/Colors';
import { useDimensions } from '@contexts/DimensionsContext';
import { useTheme } from '@contexts/ThemeContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { isWeb, useNativeDriver } from '@helpers/app';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import { QuantumSpinGameOutput } from '@models/mysteryGames';

import CustomModalBackdrop from '../../../../../web-src/components/CustomModalBackdrop';
import QuantumSpinDetailsModal from '../QuantumSpinDetailsModal';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
    data: QuantumSpinGameOutput;
}

const animationConfig = {
    scaleFrom: 0,
    scaleTo: 1,
    delay: 600,
    animationDuration: 1500,
};

const QuantumSpinPrizeAnimation = ({ isVisible, onClose, data }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { theme } = useTheme();
    const { dimensions } = useDimensions();

    const itemShare = useSharedValue(animationConfig.scaleFrom);

    const starsShare = useSharedValue(0);

    const starsSharedStyle = useAnimatedStyle(() => {
        return {
            opacity: starsShare.value,
            zIndex: 1,
        };
    });
    const itemSharedStyle = useAnimatedStyle(() => {
        return {
            transform: [{ scale: itemShare.value }],
        };
    });

    const [canClose, setCanClose] = React.useState<boolean>(false);

    const handleOnClose = React.useCallback(() => {
        if (canClose) {
            cancelAnimation(itemShare);
            cancelAnimation(starsShare);
            onClose();
        }
    }, [canClose]);

    const handleCanClose = (value: boolean) => {
        setCanClose(value);
    };

    React.useEffect(() => {
        if (isVisible) {
            itemShare.value = withDelay(
                animationConfig.delay,
                withRepeat(
                    withTiming(
                        1,
                        {
                            duration: animationConfig.animationDuration,
                        },

                        () => {
                            starsShare.value = withRepeat(
                                withTiming(
                                    1,
                                    {
                                        duration: 1000,
                                    },
                                    () => {}
                                ),
                                5,
                                true,
                                () => {
                                    runOnJS(handleCanClose)(true);
                                }
                            );
                        }
                    ),
                    1
                )
            );
        }
    }, [isVisible]);

    const {
        isVisible: detailsModal,
        close: closeDetailsModal,
        open: openDetailsModal,
    } = useVisibleHook();

    const handleOpenDetailsModal = () => {
        cancelAnimation(itemShare);
        cancelAnimation(starsShare);
        itemShare.value = 1;
        starsShare.value = 1;
        openDetailsModal();
    };

    const handleCloseClaimModal = () => {
        onClose();
        closeDetailsModal();
    };

    return (
        <ExtendedModal
            isVisible={isVisible}
            backdropColor={Colors[theme].modal.modalOverlay}
            backdropOpacity={Colors[theme].modal.opacity}
            customBackdrop={isWeb && <CustomModalBackdrop />}
            useNativeDriverForBackdrop={useNativeDriver}
            useNativeDriver={useNativeDriver}
            hideModalContentWhileAnimating
            deviceWidth={dimensions.screen.deviceWidth}
            deviceHeight={dimensions.screen.deviceHeight}
            animationIn="zoomIn"
            animationOut="slideInDown"
            animationInTiming={animationConfig.delay}
            animationOutTiming={animationConfig.delay}
            backdropTransitionInTiming={animationConfig.delay}
            backdropTransitionOutTiming={animationConfig.delay}
            style={styles.modal}>
            <TouchableOpacity
                style={styles.shadow}
                activeOpacity={0.9}
                onPress={handleOnClose}>
                <View style={styles.container}>
                    <View style={styles.box}>
                        <Animated.View style={[styles.stars, starsSharedStyle]}>
                            <StarsIcon />
                        </Animated.View>
                        <Animated.View style={[itemSharedStyle]}>
                            <CoinIcon slug={Coin.vigu} size={150} />
                        </Animated.View>
                    </View>
                </View>

                <ButtonsBottom
                    safe
                    containerStyle={styles.button}
                    title={i18n.t('general.BUTTONS.viewDetails')}
                    onPress={handleOpenDetailsModal}
                    isOneColor
                />
            </TouchableOpacity>
            <QuantumSpinDetailsModal
                isVisible={detailsModal}
                onClose={handleCloseClaimModal}
                handlePress={handleCloseClaimModal}
                data={data}
            />
        </ExtendedModal>
    );
};
export default QuantumSpinPrizeAnimation;
