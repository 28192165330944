import * as React from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';

import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT, TYPO_TEXT_COLOR } from '@components/TypoText/types';
import usePreventDoubleClick from '@hooks/usePreventDoubleClick';
import useThemedStyles from '@hooks/useThemedStyles';
import { useTouchableOpacityDebounce } from '@hooks/useTouchableOpacityDebounce';

import stylesMain from './styles';

interface IProps {
    icon: React.ReactElement;
    title: string | null;
    containerStyle?: StyleProp<ViewStyle>;
    filled?: boolean;
    onPress?: () => void;
}

const DecorativeTag = ({
    icon,
    title,
    containerStyle,
    filled,
    onPress,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { onClick, disabled } = usePreventDoubleClick(onPress);

    if (!title) {
        return null;
    }

    return (
        <TouchableOpacity
            activeOpacity={0.8}
            onPress={onClick}
            disabled={!onPress || disabled}
            style={[styles.container, containerStyle, filled && styles.filled]}>
            <View style={styles.icon}>{icon}</View>
            <TypoText
                type={TYPO_TEXT.SUBHEAD_LITTLE_BOLD}
                colorType={
                    filled ? TYPO_TEXT_COLOR.ACCENT : TYPO_TEXT_COLOR.ON_DARK
                }>
                {title}
            </TypoText>
        </TouchableOpacity>
    );
};
export default DecorativeTag;
