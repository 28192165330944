import * as React from 'react';
import { Linking } from 'react-native';

import * as Clipboard from 'expo-clipboard';

import { MainPetUserOutputReferral } from '@Data/Models';
import Divider from '@components/Divider';
import Icon, { ICON_NAMES } from '@components/Icons';
import SocialIcons from '@components/Icons/SocialIcons';
import { Text, View } from '@components/Themed';
import TouchableOpacity from '@components/TouchableOpacity';
import { SOCIAL_BASE_LINKS, SocialsTypes } from '@constants/Socials';
import { openUrlOrShowError } from '@helpers/linking';
import { toastSuccess } from '@helpers/toastNotification';
import { useHaptic } from '@hooks/useHaptic';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface ISocial {
    id: SocialsTypes;
    username: string;
}
interface IProps {
    user: MainPetUserOutputReferral;
    withDivider?: boolean;
}

const ReferralExtendedInfo = ({ user, withDivider }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { triggerMedium } = useHaptic();

    const socials = React.useMemo(() => {
        let usernames: ISocial[] = [];
        if (user.instagram?.username) {
            usernames.push({
                id: 'instagram',
                username: user.instagram.username,
            });
        }

        if (user.tiktok?.username) {
            usernames.push({
                id: 'tiktok',
                username: user.tiktok.username,
            });
        }
        if (user.twitter?.username) {
            usernames.push({
                id: 'twitter',
                username: user.twitter.username,
            });
        }
        if (user.facebook?.username) {
            usernames.push({
                id: 'facebook',
                username: user.facebook.username,
            });
        }
        return usernames;
    }, [user]);

    const explore = async ({ id, username }: ISocial) => {
        triggerMedium();
        const upperCasePlatform = id.toUpperCase();
        const link = `${
            SOCIAL_BASE_LINKS[upperCasePlatform as keyof object]
        }${username}`;
        const canOpen = await Linking.canOpenURL(link);
        if (!canOpen) {
            await Clipboard.setStringAsync(link);
            toastSuccess(
                i18n.t('socializeToEarn.shareYourPetPhoto.copyToClipboard')
            );
            return;
        }
        openUrlOrShowError(link);
    };

    return (
        <>
            {withDivider && <Divider customStyles={styles.divider} />}
            <View style={styles.item}>
                <View style={styles.left}>
                    <View style={styles.icon}>
                        <Icon name={ICON_NAMES.ENERGY} size={24} />
                    </View>
                    <Text style={styles.title}>
                        {i18n.t('referrals.modalReferee.energy')}
                    </Text>
                </View>
                <Text style={styles.energy}>{user.energy}</Text>
            </View>
            {!!socials.length &&
                socials.map(({ id, username }) => (
                    <TouchableOpacity
                        style={[styles.item, styles.gap]}
                        key={id}
                        activeOpacity={0.7}
                        onPress={() => explore({ id, username })}>
                        <View style={styles.left}>
                            <View style={styles.icon}>
                                <SocialIcons slug={id} size={24} />
                            </View>
                            <Text
                                style={styles.username}>{`@${username}`}</Text>
                        </View>
                        <Icon
                            name={ICON_NAMES.EXPLORE}
                            color={styles.username.color}
                        />
                    </TouchableOpacity>
                ))}
        </>
    );
};
export default ReferralExtendedInfo;
