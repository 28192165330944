import React, { useEffect } from 'react';
import { ScrollView, Text, TouchableOpacity, View } from 'react-native';

import { GetLevelupPaymentsResponse, Pet } from '@Data/Models';
import { GET_LEVELUP_PAYMENTS } from '@Data/Requests';
import { useApolloClient } from '@apollo/client';
import ButtonsBottom from '@components/ButtonsBottom';
import CardFeatureInfo from '@components/CardFeatureInfo/CardFeatureInfo';
import Icon, { ICON_NAMES } from '@components/Icons';
import RankIcon from '@components/Icons/RankIcon';
import LevelIndicator from '@components/LevelIndicator';
import { usePlay } from '@contexts/PlayContext';
import { useRanks } from '@contexts/RanksContext';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import {
    formatBonusReward,
    formatDailyReward,
    formatRewardModifier,
} from '@helpers';
import { isAndroid } from '@helpers/app';
import { isGraphqlError } from '@helpers/helpers';
import { toastError, toastErrorUnknown } from '@helpers/toastNotification';
import { balanceFromWei } from '@helpers/wallet';
import { useLevelUpBoostInfo } from '@hooks/useLevelUpBoostInfo';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { StackActions } from '@react-navigation/native';

import Animation from '../Animation';
import ModalBottom from '../ModalBottom';
import mainStyles from './styles';

interface IProps {
    pet: Pet;
    isVisible: boolean;
    handleCloseLevelUpModal: () => void;
    fromScreenModal?: boolean;
    navigateBackOnUpgrade?: ROUTES; // custom navigation after successful checkout
    fetch?: boolean;
}

const ModalNFTLevelUp = ({
    pet,
    isVisible,
    handleCloseLevelUpModal,
    fromScreenModal = false,
    navigateBackOnUpgrade,
    fetch,
}: IProps) => {
    const navigation = useNavigation();
    const client = useApolloClient();
    const { refetchPets } = usePlay();
    const { rankBySlug, nextRankBySlug } = useRanks();
    const { priceInUsd } = useWallet();

    const styles = useThemedStyles(mainStyles);

    const { getLevelUpBoostInfo, levelUpBoostInfo, levelUpBoostInfoLoading } =
        useLevelUpBoostInfo();

    // Loading by prop isVisible info on screen where render a lot of modals of this type,
    useEffect(() => {
        if (isVisible && !fetch && pet.levelUpAvailable) {
            getLevelUpBoostInfo({
                variables: {
                    petId: pet.id,
                },
            });
            return;
        }
    }, [isVisible, pet, fetch]);

    // Loading by prop fetch for screen CollectionDetailedPetScreen
    useEffect(() => {
        if (fetch && pet.levelUpAvailable) {
            getLevelUpBoostInfo({
                variables: {
                    petId: pet.id,
                },
            });
            return;
        }
    }, [pet, fetch]);

    const fetchPayments = async () => {
        const result = await client.query<GetLevelupPaymentsResponse>({
            query: GET_LEVELUP_PAYMENTS,
            variables: {
                petId: pet.id,
            },
        });
        return result.data.levelupPayments;
    };

    const handleOpenPetLevelUpCheckoutScreen = async () => {
        if (fromScreenModal) {
            navigation.dispatch(StackActions.pop());
        }
        handleCloseLevelUpModal();

        try {
            const payments = await fetchPayments();

            navigation.navigate(ROUTES.COLLECTION_PET_LEVEL_UP_CHECKOUT, {
                pet,
                payments,
                navigateBackOnUpgrade,
            });
        } catch (error: any) {
            if (isGraphqlError(error, 'PET_WRONG_STATE')) {
                toastError(i18n.t('petLevelUp.errors.PET_WRONG_STATE'));
                refetchPets(true);
            } else {
                toastErrorUnknown();
            }
        }
    };

    const getNextLevel = () => {
        return pet.level + 1;
    };
    const currentRank = React.useMemo(
        () => rankBySlug(pet.rank),
        [pet.rank, rankBySlug]
    );
    const nextRank = React.useMemo(
        () => nextRankBySlug(pet.rank),
        [pet.rank, nextRankBySlug]
    );

    const earnRankIGUP = React.useMemo(
        () =>
            `${formatDailyReward(currentRank?.baseIgupRewardWei)} ${Coin.igup}`,
        [currentRank?.baseIgupRewardWei]
    );
    const earnRankUSD = React.useMemo(
        () =>
            priceInUsd(
                Coin.igup,
                balanceFromWei(currentRank?.baseIgupRewardWei).value
            ),
        [currentRank?.baseIgupRewardWei, priceInUsd]
    );
    const boost = React.useMemo(
        () => ` (${formatRewardModifier(levelUpBoostInfo)}) `,
        [levelUpBoostInfo]
    );

    const bonusReward = React.useMemo(
        () =>
            formatBonusReward(levelUpBoostInfo, currentRank?.baseIgupRewardWei),
        [levelUpBoostInfo, currentRank?.levelRewardModifier]
    );

    const bonusRewardUSD = React.useMemo(
        () =>
            priceInUsd(
                Coin.igup,
                formatBonusReward(
                    levelUpBoostInfo,
                    currentRank?.baseIgupRewardWei,
                    true
                )
            ),
        [levelUpBoostInfo, currentRank?.levelRewardModifier, priceInUsd]
    );

    const levelEarningText = React.useMemo(
        () => `≈ ${bonusReward} ${Coin.igup} ≈ (${bonusRewardUSD}$)`,
        [bonusReward, bonusRewardUSD]
    );

    const rankName = React.useMemo(
        () => (nextRank ? i18n.t(`ranks.list.${nextRank.slug}.title`) : ''),
        [nextRank]
    );

    const levels = React.useMemo(() => {
        if (!nextRank) {
            return '';
        }
        return nextRank?.maxPetLevel - pet.level;
    }, [nextRank, pet.level]);

    return (
        <ModalBottom
            modalHeight={'96%'}
            isVisible={isVisible}
            onClose={handleCloseLevelUpModal}>
            <ScrollView showsVerticalScrollIndicator={false}>
                <TouchableOpacity
                    style={styles.modalContainer}
                    activeOpacity={1}>
                    <View style={styles.animationWrapper}>
                        <Animation
                            animation="dancing"
                            style={styles.animation}
                        />
                    </View>
                    <Text style={styles.titleModal}>
                        {i18n.t('petLevelUp.readyToUpModal.titleStart')}
                        <Text style={styles.highlighted}>
                            {i18n.t('petLevelUp.readyToUpModal.titleLevelUp', {
                                level: getNextLevel(),
                            })}
                        </Text>
                        {i18n.t('petLevelUp.readyToUpModal.titleEnd')}
                    </Text>
                    <Text style={styles.subTitleModal}>
                        {i18n.t('petLevelUp.readyToUpModal.subTitleStart')}
                        <Text style={styles.petName}>{`"${pet.name}"`}</Text>
                        {i18n.t('petLevelUp.readyToUpModal.subTitleEnd')}
                    </Text>
                    <View style={styles.containerLevelModal}>
                        <LevelIndicator
                            level={pet.level}
                            fillAmount={pet.levelProgressPercentage}
                            experience={pet.experience}
                            maxExperience={pet.currentLevelMaxExperience}
                            fullView
                            iconSize={24}
                            fromModal
                        />
                    </View>
                    <View style={styles.featureWrapper}>
                        <CardFeatureInfo
                            containerStyle={styles.gap}
                            icon={<RankIcon slug={pet.rank} size={48} />}
                            title={i18n.t(
                                `petLevelUp.readyToUpModal.featureRankEarnings.title`,
                                {
                                    igup: earnRankIGUP,
                                    usd: earnRankUSD,
                                }
                            )}
                            text={i18n.t(
                                'petLevelUp.readyToUpModal.featureRankEarnings.text'
                            )}
                        />

                        <CardFeatureInfo
                            loading={levelUpBoostInfoLoading}
                            containerStyle={styles.gap}
                            icon={<Icon name={ICON_NAMES.LEVEL} size={48} />}
                            text={i18n.t(
                                'petLevelUp.readyToUpModal.featureLevelEarnings.text'
                            )}
                            children={
                                <>
                                    {i18n.t(
                                        'petLevelUp.readyToUpModal.featureLevelEarnings.title'
                                    )}
                                    <Text style={styles.accent}>{boost}</Text>
                                    {levelEarningText}
                                </>
                            }
                        />

                        {!!nextRank && !!levels && (
                            <CardFeatureInfo
                                containerStyle={styles.gap}
                                icon={
                                    <RankIcon slug={nextRank.slug} size={48} />
                                }
                                title={i18n.t(
                                    `petLevelUp.readyToUpModal.featureNextRank.title`,
                                    {
                                        levels,
                                        rankName,
                                    }
                                )}
                                text={i18n.t(
                                    'petLevelUp.readyToUpModal.featureNextRank.text',
                                    { levels }
                                )}
                            />
                        )}
                    </View>
                </TouchableOpacity>
            </ScrollView>
            <ButtonsBottom
                onPress={handleOpenPetLevelUpCheckoutScreen}
                title={i18n.t('petLevelUp.readyToUpModal.button')}
                safe={!isAndroid}
            />
        </ModalBottom>
    );
};
export default ModalNFTLevelUp;
