import * as React from 'react';

import Svg from '../../assets/icons/buttons/dislikeFill.svg';
import { IconType, iconSize } from './types';

const DislikeFill: React.FunctionComponent<IconType> = ({
    size = iconSize.default,
}) => {
    return <Svg height={size} width={size} />;
};

export default DislikeFill;
