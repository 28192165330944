import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import {
    MYSTERY_LIKES_DRAW_COLOR,
    MYSTERY_LIKES_LOST_COLOR,
    MYSTERY_LIKES_WON_COLOR,
} from '@contexts/MysteryGamesContext';
import { scale, verticalScale } from '@helpers/dimensions';
import { stylesAlignCenter } from '@helpers/style';
import { MYSTERY_LIKES_STATUS } from '@models/mysteryGames';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        safe: {
            flex: 1,
            backgroundColor: Colors[theme].background,
        },

        main: {
            flex: 1,
            backgroundColor: 'transparent',
            marginTop: verticalScale(32),
        },
        block: {
            ...stylesAlignCenter(),
            backgroundColor: 'transparent',
            paddingHorizontal: scale(20),
        },

        container: {
            paddingHorizontal: scale(20),
            marginTop: verticalScale(32),
        },
        divider: {
            marginVertical: verticalScale(24),
        },
        firstState: {
            position: 'absolute',
            zIndex: 100,
            width: '100%',
            height: '100%',
        },
        [MYSTERY_LIKES_STATUS.Won]: {
            backgroundColor: MYSTERY_LIKES_WON_COLOR,
        },
        [MYSTERY_LIKES_STATUS.Lost]: {
            backgroundColor: MYSTERY_LIKES_LOST_COLOR,
        },
        [MYSTERY_LIKES_STATUS.Draw]: {
            backgroundColor: MYSTERY_LIKES_DRAW_COLOR,
        },
        gap: {
            marginTop: verticalScale(16),
        },
        list: {
            marginTop: verticalScale(16),
            paddingHorizontal: 0,
        },
        button: {
            alignSelf: 'center',
            marginBottom: verticalScale(16),
        },

        icon: {
            marginLeft: 4,
        },
    });

export default styles;
