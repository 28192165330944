import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { isAndroid, isIOS, release } from '@helpers/app';
import { dateDiffInSeconds } from '@helpers/time';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { User } from '../Data/Models';
import useLanguage from '../hooks/useLanguage';
import useNoNetworkConnection from '../hooks/useNoNetworkConnection';
import { State } from '../types';
import { useConfig } from './ConfigContext';
import { useApollo } from './IguApolloContext';
import { useSession } from './SessionContext';
import { useUser } from './UserContext';
import { useWallet } from './Wallet/WalletContext';
import { WalletData } from './Wallet/WalletHelpers';

type AppStateContextType = {
    state: State;
    isConnected: boolean;
    isUpdateRequired: boolean;
    isGlobalApolloError: string | null;
    isGameMaintenance: boolean;
    isFullMaintenance: boolean;
    isVisibleOptionalUpdate: boolean;
    setIsVisibleOptionalUpdate: Dispatch<SetStateAction<boolean>>;
    isMintForceSuccessScreen: boolean;
    setIsMintForceSuccessScreen: Dispatch<SetStateAction<boolean>>;
};

export const AppStateContext = React.createContext<AppStateContextType>({
    state: State.loading,
    isConnected: false,
    isUpdateRequired: false,
    isGlobalApolloError: '',
    isGameMaintenance: false,
    isFullMaintenance: false,
    isVisibleOptionalUpdate: false,
    setIsVisibleOptionalUpdate: () => undefined,
    isMintForceSuccessScreen: false,
    setIsMintForceSuccessScreen: () => undefined,
});

export const useAppState = () => React.useContext(AppStateContext);

const config = {
    timespanNoPet: 3 * 60 * 60 * 1000,
};

export const ONBOARDING_KEY = 'ONBOARDING_KEY.firstlyInstalledOnThisDeviceV1';

const AppStateProvider: React.FC<React.PropsWithChildren<unknown>> = ({
    children,
}) => {
    const { sessionToken } = useSession();
    const { isLoaded: userLoaded, user, petsCount } = useUser();
    const { isLoaded: walletLoaded, walletData } = useWallet();
    const { isLoaded: configLoaded, config } = useConfig();
    const { maybeSaveLanguage } = useLanguage();
    const [state, setState] = useState<State>(State.loading);
    const { isConnected } = useNoNetworkConnection();

    const [firstlyInstalledOnThisDevice, setFirstlyInstalledOnThisDevice] =
        useState<boolean>(false);

    useEffect(() => {
        const checkFirstlyInstalled = async () => {
            const status = await AsyncStorage.getItem(ONBOARDING_KEY);
            setFirstlyInstalledOnThisDevice(!status);
        };
        checkFirstlyInstalled();
    }, []);

    const {
        isUpdateRequired,
        isGlobalApolloError,
        isGameMaintenance,
        isFullMaintenance,
    } = useApollo();

    // Optional Update Modal
    const [isVisibleOptionalUpdate, setIsVisibleOptionalUpdate] =
        useState<boolean>(false);
    // force mint success screen
    const [isMintForceSuccessScreen, setIsMintForceSuccessScreen] =
        useState<boolean>(false);

    useEffect(() => {
        maybeSaveLanguage();
    }, [maybeSaveLanguage]);

    // Get App State based on User, Wallet, Config
    useEffect(() => {
        const newState = getState(
            sessionToken,
            user,
            userLoaded,
            configLoaded,
            walletLoaded,
            walletData,
            petsCount,
            isMintForceSuccessScreen,
            firstlyInstalledOnThisDevice
        );

        if (newState !== state && newState !== State.loading) {
            setState(newState);
            Console.log(
                '[AppStateStatus]',
                `oldState=${state}`,
                `newState=${newState}`,
                // `sessionToken=${sessionToken}`,
                `user=${user?.id}`,
                `configLoaded=${configLoaded}`,
                `userLoaded=${userLoaded}`,
                `walletLoaded=${walletLoaded}`,
                `walletData=${walletData?.wallet.address}`,
                `petsCount=${petsCount}`,
                `isForceSuccessScreen=${isMintForceSuccessScreen}`
            );
        }
    }, [
        sessionToken,
        configLoaded,
        userLoaded,
        user,
        walletLoaded,
        walletData,
        petsCount,
        isMintForceSuccessScreen,
        state,
        firstlyInstalledOnThisDevice,
    ]);

    // Handle Optional Update
    useEffect(() => {
        function checkVersions() {
            if (!config) {
                return;
            }

            if (isIOS) {
                if (Number(release) < Number(config.iosVersion)) {
                    setIsVisibleOptionalUpdate(true);
                }
            } else if (isAndroid) {
                if (Number(release) < Number(config.androidVersion)) {
                    setIsVisibleOptionalUpdate(true);
                }
            }
        }

        checkVersions();
    }, [config]);

    return (
        <AppStateContext.Provider
            value={{
                state,
                isConnected,
                isUpdateRequired,
                isGlobalApolloError,
                isGameMaintenance,
                isFullMaintenance,
                isVisibleOptionalUpdate,
                setIsVisibleOptionalUpdate,
                isMintForceSuccessScreen,
                setIsMintForceSuccessScreen,
            }}>
            {children}
        </AppStateContext.Provider>
    );
};

function getState(
    sessionToken: string | null,
    user: User | undefined,
    userLoaded: boolean,
    configLoaded: boolean,
    walletLoaded: boolean,
    walletData: WalletData | null,
    petsCount: number,
    isMintForceSuccessScreen: boolean,
    firstlyInstalledOnThisDevice: boolean
) {
    const isAppLoaded = userLoaded && configLoaded;
    const userLoggedIn = user && sessionToken;
    // get account creation timestamp difference
    const accountTimeDifference = dateDiffInSeconds(
        new Date(),
        user?.createdAt || ''
    );

    // User has no pet
    if (
        isAppLoaded &&
        user &&
        walletData &&
        ((!petsCount && accountTimeDifference <= config.timespanNoPet) ||
            isMintForceSuccessScreen)
    ) {
        return State.loggedInNoPet;
    }

    // User is logged in with wallet
    if (isAppLoaded && userLoggedIn && walletData) {
        return State.loggedInWallet;
    }

    // Create / Import wallet flow
    if (isAppLoaded && userLoggedIn && walletLoaded && !walletData) {
        return State.loggedInNoWallet;
    }
    // User is not logged in + new install
    if (isAppLoaded && !userLoggedIn && firstlyInstalledOnThisDevice) {
        return State.notLoggedInOnboarding;
    }
    // User is not logged in
    if (isAppLoaded && !userLoggedIn) {
        return State.notLoggedIn;
    }

    // Initial loading state
    return State.loading;
}

export default AppStateProvider;
