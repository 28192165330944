import { StyleSheet } from 'react-native';
import { height } from 'react-native-size-scaling';

import { head } from 'lodash';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            borderRadius: moderateScale(12),
            backgroundColor: 'transparent',
        },
        bottomGap: {
            marginBottom: verticalScale(20),
        },
        opacityContainer: {
            backgroundColor: Colors[theme].fillPrimaryMinimal,
        },
        defaultContainer: {
            borderColor: Colors[theme].border.primary,
            borderWidth: 1,
        },
        top: {
            backgroundColor: 'transparent',
            flexDirection: 'row',
            alignItems: 'center',
        },
        coinsNameWrapper: {
            backgroundColor: 'transparent',
            flexDirection: 'row',
            flex: 1,
            alignItems: 'center',
            marginRight: scale(16),
        },
        coinsName: {
            marginLeft: scale(6),
        },
        priceChangeWrapper: {
            backgroundColor: 'transparent',
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(6),
            borderRadius: 50,
        },
        opacityPriceChangeWrapper: {
            backgroundColor: Colors[theme].alwaysOneColor.background,
        },
        defaultPriceChangeWrapperDown: {
            backgroundColor: Colors[theme].surfaceDecorativeCriticalMinimal,
        },
        defaultPriceChangeWrapperUp: {
            backgroundColor: Colors[theme].surfaceDecorativeAccentMinimal,
        },
        bottom: {
            backgroundColor: 'transparent',
            marginTop: verticalScale(6),
        },
        btm: {
            backgroundColor: 'transparent',
            flexDirection: 'row',

            alignItems: 'center',
        },
        usd: {
            flex: 1,
            marginRight: scale(16),
        },
        onDark: {
            color: Colors[theme].text.onLight,
        },
        onLight: {
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            backgroundColor: 'transparent',
        },
        onLightTitle: {
            color: Colors[theme].text.secondary,
        },
        onLightText: {
            color: Colors[theme].text.primary,
        },
        imageWrapper: {
            backgroundColor: 'transparent',
        },
        title: {
            color: Colors[theme].text.onLight,
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
            marginTop: scale(2),
            textAlign: 'center',
        },
        amount: {
            color: Colors[theme].text.onLight,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
            marginTop: scale(6),
            textAlign: 'center',
        },
        predictions: {
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            borderRadius: moderateScale(12),
            backgroundColor: 'transparent',
            marginTop: verticalScale(16),
        },
    });

export default styles;
