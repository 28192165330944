import * as React from 'react';

import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';

import { GamingWalletTransactionDetailsProps } from '../../../../types';
import GamingWalletTransactionDetailsContent from '../components/GamingWalletTransactionDetailsContent';
import stylesMain from './styles';

const GamingWalletTransactionsDetailsScreen = ({
    route,
}: GamingWalletTransactionDetailsProps) => {
    const styles = useThemedStyles(stylesMain);
    const { transaction } = route.params;

    return (
        <View style={styles.container}>
            <NavigationBar
                backIcon
                title={i18n.t('wallet.transactionDetails')}
                containerStyle={styles.navBg}
            />
            <GamingWalletTransactionDetailsContent transaction={transaction} />
        </View>
    );
};

export default GamingWalletTransactionsDetailsScreen;
