import React, { useCallback, useMemo } from 'react';

import { useAppLoading } from '@contexts/AppLoading/AppLoadingContext';
import { useModal } from '@contexts/ModalContext';
import { useUser } from '@contexts/UserContext';
import { isIOS } from '@helpers/app';
import { dateDiffInDays } from '@helpers/time';
import useWalletRewards from '@hooks/useWalletRewards';
import { IGU_WALLET_TRANSACTIONS } from '@models/gamingWallet';
import AsyncStorage from '@react-native-async-storage/async-storage';

import ModalDailyRewards from '../components/ModalDailyRewards';
import useGamingWalletTransactionsHistory from './useGamingWalletTransactionsHistory';
import { useVisibleHook } from './useVisibleHook';

const hookConfig = {
    storageKey: 'useDailyRewardsModalAndBonus',
};

const useDailyRewardsModal = () => {
    const { user, userUpdated } = useUser();
    const { isSplashAnimationComplete } = useAppLoading();
    // if global modal is visible do not show this screen (prevent ios from freeze)
    const {
        isVisible: isGlobalModalVisible,
        setModalClosed,
        setModalOpened,
    } = useModal();

    const { transactionHistory, getTransactionHistoryFilter, filterLoading } =
        useWalletRewards();

    const {
        filterLoading: iguTransactionFilterLoading,
        transactionHistory: iguTransactionHistory,

        getIguTransactionHistoryFilter,
    } = useGamingWalletTransactionsHistory();

    const { isVisible, close, open } = useVisibleHook(
        undefined,
        setModalOpened,
        setModalClosed
    );

    const lastTransaction = useMemo(
        () => transactionHistory?.[0],
        [transactionHistory]
    );
    const lastPvpBonus = useMemo(
        () => iguTransactionHistory?.[0],
        [transactionHistory]
    );

    const isDailyReward = useMemo(
        () =>
            !!lastTransaction &&
            dateDiffInDays(new Date(), lastTransaction.createdAt) < 4,
        [lastTransaction]
    );

    const isPvpBonus = useMemo(
        () =>
            !!lastPvpBonus &&
            dateDiffInDays(new Date(), lastPvpBonus.createdAt) < 4,
        [lastPvpBonus]
    );

    const storageKey = useMemo(
        () =>
            `${hookConfig.storageKey}-${user?.id}-${lastTransaction?.id}-${lastPvpBonus?.id}`,
        [user, lastTransaction, lastPvpBonus]
    );

    const canShow = useCallback(async () => {
        if (
            !isSplashAnimationComplete ||
            (isIOS ? isGlobalModalVisible : false)
        ) {
            return false;
        }

        if (!isDailyReward && !isPvpBonus) {
            return false;
        }

        const status = await AsyncStorage.getItem(storageKey);

        return !status;
    }, [
        isSplashAnimationComplete,
        isGlobalModalVisible,
        storageKey,
        isDailyReward,
        isPvpBonus,
    ]);

    const setAlreadyShown = useCallback(async () => {
        if (storageKey) {
            await AsyncStorage.setItem(storageKey, 'shown');
        }
    }, [storageKey]);

    const onPress = async () => {
        close();
        setAlreadyShown();
    };

    const onClose = () => {
        close();
        setAlreadyShown();
    };

    React.useEffect(() => {
        if (user && userUpdated) {
            getTransactionHistoryFilter('GameIterationReward', true);
            getIguTransactionHistoryFilter(
                IGU_WALLET_TRANSACTIONS.GamesTurnoverReward,
                true
            );
        }
    }, [
        getTransactionHistoryFilter,
        getIguTransactionHistoryFilter,
        user,
        userUpdated,
    ]);

    React.useEffect(() => {
        async function check() {
            if (!user) {
                return;
            }

            const status = await canShow();
            if (status) {
                open();
                setAlreadyShown();
            }
        }

        check();
    }, [canShow, user]);

    const renderDailyRewardsModal = useCallback(() => {
        return (
            <ModalDailyRewards
                onClose={onClose}
                onPress={onPress}
                isVisible={isVisible}
                lastTransaction={lastTransaction}
                pvpBonus={lastPvpBonus}
                isDailyReward={isDailyReward}
                isPvpBonus={isPvpBonus}
                loading={iguTransactionFilterLoading || filterLoading}
            />
        );
    }, [
        isVisible,
        lastTransaction,
        lastPvpBonus,
        isDailyReward,
        isPvpBonus,
        iguTransactionFilterLoading,
        filterLoading,
    ]);

    return {
        renderDailyRewardsModal,
        setAlreadyShown,
    };
};

export default useDailyRewardsModal;
