import * as React from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { isWeb } from '../../helpers/app';
import useThemedStyles from '../../hooks/useThemedStyles';
import { View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    children?: React.ReactNode;
}

const SafeAreaCurvedTabContent = ({ children }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const insets = useSafeAreaInsets();

    return (
        <View
            style={[
                styles.container,
                insets.bottom || isWeb ? styles.safeAreaBottomInset : {},
            ]}>
            {children}
        </View>
    );
};
export default SafeAreaCurvedTabContent;
