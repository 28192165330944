import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { makeRedirectUri } from 'expo-auth-session';
import Constants from 'expo-constants';

import { LinkInstagramResponse } from '@Data/Models';
import { LINK_INSTAGRAM, UNLINK_INSTAGRAM } from '@Data/Requests';
import { ApolloError, useMutation } from '@apollo/client';
import { ConfigContext } from '@contexts/ConfigContext';
import { useUser } from '@contexts/UserContext';
import { isWeb, projectNameForProxy } from '@helpers/app';
import { waitForToast } from '@helpers/helpers';
import { toastErrorUnknown, toastSuccess } from '@helpers/toastNotification';
import i18n from '@i18n/i18n';

import Button from '../../Button';
import CustomModal from '../../CustomModal';
import ImageIguana from '../../ImageIguana';
import ModalLoader from '../../ModalLoader';
import { View } from '../../Themed';
import * as Instagram from '../Instagram';

interface IProps {
    onCompletedLinking?: (status: boolean) => void;
    onCompletedUnLinking?: (status: boolean) => void;
    containerStyle?: StyleProp<ViewStyle>;
}

const ConnectInstagram = ({
    onCompletedLinking,
    onCompletedUnLinking,
    containerStyle,
}: IProps) => {
    const { reloadUser } = useUser();
    const [disconnectModal, setDisconnectModal] = React.useState(false);
    const { config } = React.useContext(ConfigContext);
    const [linkRequest, { loading: instagramLinkLoading }] =
        useMutation<LinkInstagramResponse>(LINK_INSTAGRAM);
    const [unlinkRequest, { loading: instagramUnlinkLoading }] =
        useMutation(UNLINK_INSTAGRAM);

    const [instagramDuplicateModal, setInstagramDuplicateModal] =
        React.useState(false);

    const redirectUri = isWeb
        ? makeRedirectUri({ path: window.location.pathname })
        : makeRedirectUri({
              projectNameForProxy,
          });

    const [_, instagramResponse, instagramPromptAsync] =
        Instagram.useAuthRequest(
            {
                clientId: config?.instagramClientId,
                faceBookId: config?.facebookAppId,
                redirectUri,
            },
            { projectNameForProxy }
        );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleRequest = (code: any) => {
        linkRequest({
            variables: {
                authCode: code,
                redirectUri,
            },
            onCompleted: async () => {
                reloadUser();
                onCompletedLinking?.(true);
            },
            onError: (error) => handleErrors(error),
        });
    };

    React.useEffect(() => {
        if (instagramResponse?.type === 'success') {
            handleRequest(instagramResponse.params?.code);
        } else if (isWeb) {
            // fix for some web devices, which return url https://iguverse.ap.ngrok.io/?#code=EAAFX6jelaw4BAJqKo39zX7D0hOIj5oHjCUnEFAL0ZCpxecXli6PSMc9Y1CIfN4boSfbpVti07Y0mHBIauZCrHowB2BAWwZAPamIcZBhhorZCEg9xxQf2u3xIPmZCgR993n3ipWCBGqqd8TpDuBOxD94hg0oZBP4QGLn81rlUIxUzZAhanSS7kZBTPAeaHJvejB2NIwRUyQ0jGjAZDZD&data_access_expiration_time=1675067094&expires_in=5178580&state=uSCWUpyy62
            const urlParams = window.location.search
                ? new URLSearchParams(window.location.search.replace('?', ''))
                : new URLSearchParams(window.location.hash.replace('#', ''));

            if (urlParams.get('code')) {
                handleRequest(urlParams.get('code'));
            }
        }
    }, [handleRequest, instagramResponse]);

    const handleLinkInstagram = async () => {
        try {
            await instagramPromptAsync();
        } catch (error) {
            Console.error(error);
            //toastErrorUnknown();
        }
    };

    const handleUnLinkInstagram = async () => {
        await unlinkRequest({
            onCompleted: async () => {
                await waitForToast();
                toastSuccess(
                    i18n.t('socialMediaConnect.success.unLinkInstagram')
                );
                await reloadUser();
                onCompletedUnLinking?.(true);
            },
            onError: async (error) => {
                await waitForToast();
                Console.log('[UNLINK_INSTAGRAM]', error);
                toastErrorUnknown();
            },
        });
    };

    const handleErrors = (error: ApolloError) => {
        Console.log('[LINK_INSTAGRAM]', error);
        const instagramDuplicate = error.graphQLErrors.find(
            (err) => err.extensions.code === 'INSTAGRAM_ALREADY_LINKED'
        );
        if (instagramDuplicate) {
            setInstagramDuplicateModal(true);
            return;
        }
        toastErrorUnknown();
    };

    const handleSetDuplicateErrorFalse = () => {
        setInstagramDuplicateModal(false);
    };

    const handleOnConnectPress = () => {
        handleLinkInstagram();
    };
    const handleOnDisconnectPress = () => {
        setDisconnectModal(true);
    };

    const closeDisconnectModal = () => {
        setDisconnectModal(false);
    };
    const handleDisconnectButtonModal = () => {
        setDisconnectModal(false);
        handleUnLinkInstagram();
    };

    return (
        <View style={containerStyle}>
            {/* <SocialMediaItem
                social={SOCIALS.instagram}
                onConnectPress={handleOnConnectPress}
                onDisconnectPress={handleOnDisconnectPress}
            /> */}
            <Button
                title={i18n.t(
                    'socialMediaConnect.connectInstagramModal.viaInstagram'
                )}
                type="text"
                onPress={handleOnConnectPress}
            />
            <CustomModal
                isVisible={instagramDuplicateModal}
                icon={<ImageIguana type="redX" />}
                titleText={i18n.t('socialMediaConnect.errors.duplicate.title', {
                    social: 'Instagram',
                })}
                infoText={i18n.t('socialMediaConnect.errors.duplicate.text')}
                firstButtonType="outline"
                firstButtonText={i18n.t(
                    'socialMediaConnect.errors.duplicate.button'
                )}
                onFirstButtonClick={handleSetDuplicateErrorFalse}
            />
            <CustomModal
                isVisible={disconnectModal}
                icon={<ImageIguana type="cry" />}
                titleText={i18n.t('socialMediaConnect.errors.disconnect.title')}
                infoText={i18n.t('socialMediaConnect.errors.disconnect.text')}
                firstButtonType="critical"
                firstButtonText={i18n.t(
                    'socialMediaConnect.errors.disconnect.button1'
                )}
                secondButtonText={i18n.t(
                    'socialMediaConnect.errors.disconnect.button2'
                )}
                onFirstButtonClick={handleDisconnectButtonModal}
                onSecondButtonClick={closeDisconnectModal}
            />
            <ModalLoader
                isVisible={instagramLinkLoading || instagramUnlinkLoading}
                text={i18n.t('general.loading')}
            />
        </View>
    );
};

export default ConnectInstagram;
