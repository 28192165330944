import * as React from 'react';
import {
    FlatList,
    Image,
    SafeAreaView,
    ScrollView,
    TouchableOpacity,
    View,
} from 'react-native';

import { ImaginationFormResponseOptions } from '../../../../Data/Models';
import Button from '../../../../components/Button';
import Icon, { ICON_NAMES } from '../../../../components/Icons';
import ImageIguana from '../../../../components/ImageIguana';
import Input from '../../../../components/Input';
import ModalBottom from '../../../../components/ModalBottom';
import { Text } from '../../../../components/Themed';
import { isWeb } from '../../../../helpers/app';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    titleText: string;
    onClose: () => void;
    modalHeight?: number | string; // from 0 to 100
    data: ImaginationFormResponseOptions[] | undefined;
    selectedItem: string | string[];
    onSelectItemPress: (itemValue: string) => void;
    onMultiItemPress: (data: string[]) => void;
    maxSelections: number;
}

const ModalAiForm = ({
    isVisible,
    titleText,
    onClose,
    modalHeight = '95%',
    data,
    selectedItem,
    onSelectItemPress,
    onMultiItemPress,
    maxSelections,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    React.useEffect(() => {
        setFilteredArray(data);
    }, [isVisible]);

    const [filteredArray, setFilteredArray] = React.useState(data);
    const [multiSelect, setMultiSelect] = React.useState<
        Array<string> | undefined
    >();
    const [query, setQuery] = React.useState('');

    React.useEffect(() => {
        Array.isArray(selectedItem) && setMultiSelect(selectedItem);
    }, [isVisible]);

    const handleSearch = (value: string) => {
        setQuery(value);
        setFilteredArray(
            data?.filter((item) =>
                item.name.toLowerCase().includes(value.toLowerCase())
            )
        );
    };

    const renderItemRadio = (item: ImaginationFormResponseOptions) => {
        const isSelected = selectedItem === item.name;
        return (
            <TouchableOpacity
                key={item.id}
                activeOpacity={0.7}
                style={[styles.shadow, isSelected && styles.selectedShadow]}
                onPress={() => onSelectItemPress(item.name)}>
                <View
                    style={[
                        styles.radioContainer,
                        isSelected && styles.selected,
                    ]}>
                    <Text style={styles.itemText}>{item.name}</Text>
                    <Image
                        resizeMode="contain"
                        style={styles.image}
                        source={{
                            uri: item.icon,
                        }}
                    />
                </View>
            </TouchableOpacity>
        );
    };

    const handleMultiSelect = React.useCallback(
        (item: string) => {
            if (multiSelect?.find((val) => val === item)) {
                setMultiSelect(multiSelect.filter((val) => val !== item));
            } else {
                if (multiSelect?.length === maxSelections) {
                    return;
                }

                setMultiSelect((prev) => (prev ? [...prev, item] : [item]));
            }
        },
        [multiSelect]
    );

    const renderItemCheckbox = (item: ImaginationFormResponseOptions) => {
        const isSelected = multiSelect?.find((val) => val === item.name);
        return (
            <TouchableOpacity
                key={item.id}
                activeOpacity={0.7}
                style={[styles.shadow, isSelected && styles.selectedShadow]}
                onPress={() => handleMultiSelect(item.name)}>
                <View
                    style={[
                        styles.radioContainer,
                        isSelected && styles.selected,
                    ]}>
                    <View style={styles.groupedTitle}>
                        <View
                            style={[styles.radio, isSelected && styles.active]}>
                            {isSelected && (
                                <Icon name={ICON_NAMES.CHECK} size={12} />
                            )}
                        </View>
                        <Text style={styles.itemText}>{item.name}</Text>
                    </View>
                    <Image
                        resizeMode="contain"
                        style={styles.image}
                        source={{
                            uri: item.icon,
                        }}
                    />
                </View>
            </TouchableOpacity>
        );
    };

    return (
        <ModalBottom
            isVisible={isVisible}
            onClose={onClose}
            avoidKeyboard
            modalHeight={modalHeight}
            titleText={titleText}
            swipeDirection={['right', 'down']}>
            <View style={[styles.container]}>
                <View style={styles.searchWrapper}>
                    <Input
                        icon={
                            <Icon
                                name={ICON_NAMES.SEARCH}
                                color={styles.search.color}
                            />
                        }
                        style={styles.input}
                        placeholder={i18n.t('nftSelectGeneration.search')}
                        onChangeText={handleSearch}
                    />
                </View>
                {!!filteredArray?.length ? (
                    isWeb ? (
                        <ScrollView
                            style={styles.listStyle}
                            showsVerticalScrollIndicator={false}>
                            {filteredArray.map((item) =>
                                maxSelections > 1
                                    ? renderItemCheckbox(item)
                                    : renderItemRadio(item)
                            )}
                        </ScrollView>
                    ) : (
                        <FlatList
                            keyboardShouldPersistTaps={'always'}
                            removeClippedSubviews={false}
                            showsVerticalScrollIndicator={false}
                            style={styles.listStyle}
                            data={filteredArray}
                            renderItem={({ item }) =>
                                maxSelections > 1
                                    ? renderItemCheckbox(item)
                                    : renderItemRadio(item)
                            }
                            keyExtractor={(item) => item.id}
                        />
                    )
                ) : (
                    <View style={styles.noResult}>
                        <ImageIguana type="redX" />
                        <Text style={styles.noResultTitle}>
                            {i18n.t('nftSelectGeneration.noResult')}
                        </Text>
                        <Text style={styles.noResultText}>
                            {i18n.t('nftSelectGeneration.noResultText', {
                                query,
                            })}
                        </Text>
                    </View>
                )}
                {!!multiSelect && (
                    <View style={styles.bottomWrapper}>
                        <Text style={styles.maxSelect}>
                            {i18n.t('nftSelectGeneration.maximumAccessories', {
                                num: maxSelections,
                            })}
                        </Text>
                        <Button
                            title={i18n.t(
                                'nftSelectGeneration.selectAccessories'
                            )}
                            onPress={() => onMultiItemPress(multiSelect)}
                        />
                    </View>
                )}
                <SafeAreaView />
            </View>
        </ModalBottom>
    );
};
export default ModalAiForm;
