import * as React from 'react';
import { useState } from 'react';
import { Platform } from 'react-native';

import { makeRedirectUri } from 'expo-auth-session';
import * as Google from 'expo-auth-session/providers/google';
import Constants from 'expo-constants';

import { useMutation } from '@apollo/client';
import { ConfigContext } from '@contexts/ConfigContext';
import { SessionContext } from '@contexts/SessionContext';
import { isWeb, projectNameForProxy } from '@helpers/app';
import { isGraphqlError, waitForNavigation } from '@helpers/helpers';

import { LoginWithGoogleResponse } from '../../../../Data/Models';
import { LOGIN_WITH_GOOGLE } from '../../../../Data/Requests';
import MaintenanceModal from '../../../../components/AppState/FullMaintenanceState/components/MaintenanceModal';
import Button from '../../../../components/Button';
import CustomModal from '../../../../components/CustomModal';
import { ICON_NAMES } from '../../../../components/Icons';
import ImageIguana from '../../../../components/ImageIguana';
import ModalLoader from '../../../../components/ModalLoader';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import style from '../../styles';

const LoginWithGoogle: React.FunctionComponent = () => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isError, setIsError] = React.useState<boolean>(false);
    const styles = useThemedStyles(style);
    const sessionContext = React.useContext(SessionContext);
    const { config } = React.useContext(ConfigContext);
    const { setSessionToken } = sessionContext;
    const [isLoginMaintenance, setIsLoginMaintenance] =
        useState<boolean>(false);

    const [googleRequest, googleResponse, googlePromptAsync] =
        Google.useAuthRequest(
            {
                expoClientId: config?.googleWebClientId,
                iosClientId: config?.googleIosClientId,
                webClientId: config?.googleWebClientId,
                androidClientId: config?.googleAndroidClientId,
                redirectUri: Platform.select({
                    // iOS handles redirectUri perfectly fine on it's own
                    ios: undefined,
                    // Due to Expo's bug, we need to manually encode the redirectUri
                    // https://github.com/expo/expo/issues/12044
                    android: makeRedirectUri({
                        // intent filter set up in app.config.js
                        // must be the same as "package name" in Google Cloud Console
                        native: `${Constants.expoConfig?.android?.package}.auth://`,
                    }),
                    web: undefined,
                }),
            },
            {
                projectNameForProxy,
            }
        );

    const [request, { data }] =
        useMutation<LoginWithGoogleResponse>(LOGIN_WITH_GOOGLE);

    const handleGoogleLogin = async () => {
        try {
            await googlePromptAsync();
        } catch (error) {
            Console.error('[GOOGLE] handleGoogleLogin()', error);
            setIsError(true);
        }
    };

    const handleRequest = async (accessToken: any) => {
        try {
            setIsLoading(true);

            if (accessToken) {
                const { data } = await request({
                    variables: {
                        loginWithGoogleInput: {
                            accessToken,
                        },
                    },
                });
                if (data) {
                    await waitForNavigation();
                    setIsLoading(false);

                    setSessionToken(data.loginWithGoogle.access_token);
                }
            }
        } catch (error) {
            Console.log('[GOOGLE] handleRequest error', error);
            setIsLoading(false);

            if (isGraphqlError(error, 'FULL_MAINTENANCE')) {
                setIsLoginMaintenance(true);
            } else {
                setIsError(true);
            }
        }
    };

    React.useEffect(() => {
        if (googleResponse?.type === 'success') {
            // Console.log('[FACEBOOK]', googleResponse.authentication);
            handleRequest(googleResponse.authentication?.accessToken);
        } else if (isWeb) {
            // fix for some web devices, which return url like https://iguverse.ap.ngrok.io/?#access_token=EAAFX6jelaw4BAJqKo39zX7D0hOIj5oHjCUnEFAL0ZCpxecXli6PSMc9Y1CIfN4boSfbpVti07Y0mHBIauZCrHowB2BAWwZAPamIcZBhhorZCEg9xxQf2u3xIPmZCgR993n3ipWCBGqqd8TpDuBOxD94hg0oZBP4QGLn81rlUIxUzZAhanSS7kZBTPAeaHJvejB2NIwRUyQ0jGjAZDZD&data_access_expiration_time=1675067094&expires_in=5178580&state=uSCWUpyy62
            const urlParams = new URLSearchParams(
                window.location.hash.replace('#', '')
            );
            if (
                urlParams.get('scope')?.includes('google') &&
                urlParams.get('access_token')
            ) {
                handleRequest(urlParams.get('access_token'));
            }
        }
    }, [googleResponse]);

    return (
        <>
            <Button
                type="outline"
                onPress={handleGoogleLogin}
                containerStyle={styles.buttonGap}
                title={i18n.t('login.google')}
                iconName={ICON_NAMES.GOOGLE}
                debouncedPress
                social
            />
            <ModalLoader
                isVisible={isLoading}
                text={i18n.t('login.loading.loggingIn')}
            />
            <CustomModal
                isVisible={isError}
                icon={<ImageIguana type="cry" />}
                titleText={i18n.t('login.errors.loginFailed')}
                infoText={i18n.t('login.errors.smthWentWrong')}
                firstButtonText={i18n.t('general.ok')}
                onFirstButtonClick={() => {
                    setIsError(false);
                }}
            />
            <MaintenanceModal
                isVisible={isLoginMaintenance}
                setIsVisible={setIsLoginMaintenance}
                canClose
            />
        </>
    );
};

export default LoginWithGoogle;
