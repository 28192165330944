import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import Colors from '../../../../constants/Colors';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        safe: {
            backgroundColor: Colors[theme].background,
            flex: 1,
        },
        scrollView: {
            backgroundColor: Colors[theme].background,
        },
        container: {
            flex: 1,
            backgroundColor: Colors[theme].background,
            paddingTop: verticalScale(16),
            paddingHorizontal: scale(20),
        },
        body: {
            marginTop: verticalScale(24),
        },
        petItem: {
            marginBottom: verticalScale(10),
        },
        top: {
            marginTop: verticalScale(20),
            marginBottom: verticalScale(20),
        },
        errorToast: {
            marginTop: verticalScale(20),
            marginBottom: verticalScale(40),
        },
    });

export default styles;
