import * as React from 'react';
import {
    Image,
    KeyboardAvoidingView,
    SafeAreaView,
    ScrollView,
} from 'react-native';

import { ethers } from 'ethers';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import AlertCard from '@components/AlertCard';
import Button from '@components/Button';
import Divider from '@components/Divider';
import Icon, { ICON_NAMES } from '@components/Icons';
import Input from '@components/Input';
import ModalConfirmTransaction from '@components/ModalConfirmTransaction';
import ModalDeposit from '@components/ModalDeposit';
import ModalLoader from '@components/ModalLoader';
import PullToRefresh from '@components/PullToRefresh';
import { Text, View } from '@components/Themed';
import TransactionSummary from '@components/TransactionSummary';
import { PlayContext } from '@contexts/PlayContext';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { isIOS } from '@helpers/app';
import { feeWithCurrency } from '@helpers/wallet';
import useBlockchainErrorsHandler from '@hooks/useBlockchainErrorsHandler';
import useFeeInfo from '@hooks/useFeeInfo';
import { useLocalAuthentication } from '@hooks/useLocalAuthentication';
import useQrCodeScanner from '@hooks/useQrCodeScanner';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';

import { WalletNFTSWithdrawProps } from '../../../../types';
import stylesMain from './styles';

const WithdrawScreen = ({ route }: WalletNFTSWithdrawProps) => {
    const { pet: petParam } = route.params;
    const { pets } = React.useContext(PlayContext);
    const pet = petParam?.id
        ? petParam
        : pets?.find((item) => item.name === petParam.toString());
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const {
        reloadBalance,
        walletData,
        walletBalance,
        transferNFT,
        fee,
        balanceReloading,
    } = useWallet();
    const [isVisibleModalDeposit, setIsVisibleModalDeposit] =
        React.useState<boolean>(false);

    const [loading, setLoading] = React.useState<boolean>(false);

    const {
        isVisible: isVisibleConfirmationModal,
        close: closeConfirmationModal,
        open: openConfirmationModal,
    } = useVisibleHook();

    // fee info modalScreen
    const { renderFeeInfoIcon } = useFeeInfo(
        closeConfirmationModal,
        openConfirmationModal
    );

    const { handleBlockchainErrors, renderBlockchainErrorsModal } =
        useBlockchainErrorsHandler();

    const isBNBEnough = React.useMemo(() => {
        return Number(walletBalance?.bnb.value) >= Number(fee?.nftTransfer);
    }, [walletBalance?.bnb.value, fee]);

    const handleOnDepositPress = () => {
        setIsVisibleModalDeposit(true);
    };

    const handleScanned = async (scanned: string) => {
        await setFieldValue('address', scanned);
        setTouched({ address: true });
    };

    const { scanButton, scannerModal } = useQrCodeScanner(
        'wallet',
        handleScanned
    );

    const withdraw = async () => {
        if (!walletData?.wallet || !pet) {
            return;
        }

        setLoading(true);

        try {
            const result = await transferNFT(values.address, pet.tokenId);

            setLoading(false);

            navigation.navigate(ROUTES.WALLET_NFTS_WITHDRAW_SUCCESS, {
                pet,
                addressFrom: walletData?.wallet?.address,
                addressTo: values.address,
                transactionResult: result,
            });
        } catch (error) {
            Console.error(error);
            setLoading(false);

            handleBlockchainErrors(error);
        }
    };

    const onRefresh = React.useCallback(() => {
        reloadBalance(false);
    }, []);

    const formikData = useFormik({
        initialValues: {
            address: '',
        },
        validationSchema: Yup.object({
            address: Yup.string()
                .required(i18n.t('withdrawScreen.errors.address'))
                .test(
                    'is-valid-address',
                    i18n.t('withdrawScreen.errors.invalidAddress'),
                    (value = '') => ethers.utils.isAddress(value)
                )
                .test(
                    'is-not-same-address',
                    i18n.t('withdrawScreen.errors.sameWallet'),
                    (value = '') =>
                        value.toLowerCase() !==
                        walletData?.wallet?.address.toLowerCase()
                )
                .test(
                    'bnbNotEnough',
                    i18n.t('withdrawScreen.errors.bnbNotEnough'),
                    () => isBNBEnough
                ),
        }),
        onSubmit: () => openConfirmationModal(),
        enableReinitialize: true,
    });

    const {
        submitForm,
        values,
        errors,
        setFieldValue,
        setTouched,
        touched,
        isValid,
        dirty,
    } = formikData;

    const [isFirstLoading, setIsFirstLoading] = React.useState<boolean>(true);

    //refresh form on balance change
    React.useEffect(() => {
        if (isFirstLoading) {
            setIsFirstLoading((prev) => !prev);
            return;
        }
        const update = () => {
            handleChangeAddress(values.address);
        };
        update();
    }, [walletBalance]);

    // Not enough amount of tokens
    const isAmountError =
        touched.address &&
        errors.address === i18n.t('withdrawScreen.errors.bnbNotEnough');

    const handleChangeAddress = async (address: string) => {
        await setTouched({ address: false });
        await setFieldValue('address', address);
    };

    const renderItem = React.useCallback(() => {
        return (
            <ScrollView
                scrollEventThrottle={16}
                keyboardShouldPersistTaps="handled"
                contentContainerStyle={styles.page}>
                <Input
                    label={i18n.t('wallet.nft.withdrawNftLabelAddress')}
                    value={values.address}
                    error={touched.address ? errors.address : undefined}
                    onChangeText={(address) => handleChangeAddress(address)}
                    onBlur={() => setTouched({ address: true })}
                    placeholder={i18n.t(
                        'wallet.nft.withdrawNftPlaceholderAddress'
                    )}
                    stylesContainer={styles.input}
                    component={scanButton()}
                />
                {isAmountError && (
                    <Button
                        type="text"
                        title={i18n.t('withdrawScreen.depositButtonLabel')}
                        onPress={handleOnDepositPress}
                        icon={<Icon name={ICON_NAMES.PLUS} color="green" />}
                        containerStyle={styles.buttonDeposit}
                    />
                )}
                <AlertCard
                    text={i18n.t('wallet.nft.withdrawWarningText')}
                    containerStyle={styles.warning}
                />
            </ScrollView>
        );
    }, [touched, errors, values, isAmountError, styles]);

    const { renderPassCodeCheck, localAuth } = useLocalAuthentication();

    const renderConfirmModal = () => {
        const transactionDetailsValues = [
            {
                key: i18n.t('checkout.fields.transaction'),
                value: `${pet?.name}`,
            },
            {
                key: i18n.t('checkout.fields.from'),
                value: walletData?.wallet?.address,
            },
            {
                key: i18n.t('checkout.fields.to'),
                value: values.address,
            },
            {
                key: i18n.t('checkout.fields.fee'),
                value: feeWithCurrency(fee?.nftTransfer),
                icon: renderFeeInfoIcon(),
            },
        ];

        return (
            <ModalConfirmTransaction
                handleAuth={localAuth}
                isVisible={isVisibleConfirmationModal}
                close={closeConfirmationModal}
                onConfirm={withdraw}
                TransactionDetailsComponent={
                    <TransactionSummary values={transactionDetailsValues} />
                }
                modalHeight={680}
                textWarning={i18n.t('wallet.nft.withdrawWarningText')}
            />
        );
    };

    const renderDepositModal = () => {
        return (
            <ModalDeposit
                isVisible={isVisibleModalDeposit}
                setIsVisible={setIsVisibleModalDeposit}
                coin="BNB"
            />
        );
    };

    const transactionSummaryValues = [
        {
            key: i18n.t('wallet.nft.blockchainFee'),
            value: feeWithCurrency(fee?.nftTransfer),
            icon: renderFeeInfoIcon(true),
        },
    ];

    return (
        <KeyboardAvoidingView
            behavior={isIOS ? 'padding' : 'height'}
            style={styles.container}>
            <NavigationBar filled={false} backIcon>
                <View style={styles.titleWrapper}>
                    <View style={styles.imageWrapper}>
                        <Image
                            source={{ uri: pet?.image.thumbnailUrl }}
                            style={styles.image}
                            resizeMode="contain"
                        />
                    </View>

                    <Text style={styles.title}>
                        {i18n.t('wallet.nft.withdrawNftTitle')}
                    </Text>
                </View>
            </NavigationBar>
            <SafeAreaView style={styles.flex}>
                <PullToRefresh
                    refreshing={balanceReloading}
                    textLoading={i18n.t('pullToRefresh.reloadBalance.text')}
                    onRefresh={() => onRefresh()}
                    renderItem={renderItem}
                />
                <Divider customStyles={styles.divider} />
                <View style={styles.buttonContainer}>
                    <TransactionSummary
                        values={transactionSummaryValues}
                        containerStyle={styles.summary}
                    />
                    <Button
                        disabled={!(isValid && dirty) || loading}
                        title={i18n.t('wallet.nft.confirmButtonLabel')}
                        onPress={submitForm}
                        containerStyle={styles.button}
                    />
                </View>
            </SafeAreaView>
            {renderBlockchainErrorsModal()}
            {renderConfirmModal()}
            {renderDepositModal()}
            {scannerModal()}
            {renderPassCodeCheck()}
            <ModalLoader
                isVisible={loading}
                text={i18n.t('wallet.loadingWithdraw')}
            />
        </KeyboardAvoidingView>
    );
};

export default WithdrawScreen;
