import * as React from 'react';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import LeaderboardDailyTasksScreen from '../../screens/TabBar/Leaderboard/LeaderboardDailyTasks/LeaderboardDailyTasksScreen';
import LeaderboardPvpGamesScreen from '../../screens/TabBar/Leaderboard/LeaderboardPvpGames/LeaderboardPvpGamesScreen';
import { LeaderboardTabsParamList } from '../../types';
import ROUTES from '../routes';

const LeaderboardTabsStack =
    createBottomTabNavigator<LeaderboardTabsParamList>();

export const LeaderboardTabsNavigator = () => {
    return (
        <LeaderboardTabsStack.Navigator
            screenOptions={{
                headerShown: false,
            }}
            initialRouteName={ROUTES.LEADERBOARD_DAILY_TASKS}
            backBehavior="none"
            tabBar={() => null}>
            <LeaderboardTabsStack.Screen
                name={ROUTES.LEADERBOARD_DAILY_TASKS}
                component={LeaderboardDailyTasksScreen}
            />
            <LeaderboardTabsStack.Screen
                name={ROUTES.LEADERBOARD_PVP_GAMES}
                component={LeaderboardPvpGamesScreen}
            />
        </LeaderboardTabsStack.Navigator>
    );
};
