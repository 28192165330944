import * as React from 'react';
import { useMemo } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useConfig } from '@contexts/ConfigContext';
import { useGamingWallet } from '@contexts/GamingWalletContext';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { priceString } from '@helpers/payments';
import {
    balanceFromWei,
    feeWithCurrency,
    inputNumberFormatter,
    isBiggerThanMax,
} from '@helpers/wallet';
import useFeeInfo from '@hooks/useFeeInfo';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';

import { useWithdrawIgu } from '../../hooks/useWithdrawIgu';

const useWithdrawInputAmountScreen = () => {
    const { config } = useConfig();

    const { gamingBalance, selectedWithdrawToken, isBNBEnough } =
        useGamingWallet();

    const { reloadBalance, walletBalance, fee, balanceReloading } = useWallet();

    const {
        isVisible: isVisibleModalDeposit,
        close: closeIsVisibleModalDeposit,
        open: openIsVisibleModalDeposit,
    } = useVisibleHook();

    const {
        isVisible: isVisibleConfirmationModal,
        close: closeConfirmationModal,
        open: openConfirmationModal,
    } = useVisibleHook();

    // fee info modalScreen
    const { renderFeeInfoIcon } = useFeeInfo(
        closeConfirmationModal,
        openConfirmationModal
    );
    const { renderFeeInfoIcon: renderCommissionFeeInfoIcon } = useFeeInfo(
        closeConfirmationModal,
        openConfirmationModal,
        ROUTES.HOW_COMMISSION_FEE
    );

    const onRefresh = React.useCallback(() => {
        reloadBalance(false);
        setTouched({ amount: false });
    }, []);

    const estimatedFee = useMemo(
        () => fee?.executeTransaction.IGU,
        [fee?.executeTransaction.IGU]
    );

    const {
        withdrawLoading,
        handleConfirmWithdraw,
        renderBlockchainErrorsModal,
    } = useWithdrawIgu(closeConfirmationModal);

    const validationSchema = Yup.object({
        amount: Yup.string()
            .transform((_, value) => {
                if (value && value.includes('.')) {
                    return value;
                }
                return value && value.replace(/,/, '.');
            })

            .matches(/^\d*(\.\d+)?$/, i18n.t('withdrawScreen.errors.digits'))
            .required(i18n.t('withdrawScreen.errors.amountRequired'))
            .test(
                'maxAmount',
                i18n.t('withdrawScreen.errors.maxAmountError'),
                (value) =>
                    Number(value) <=
                    Number(
                        balanceFromWei(gamingBalance?.iguBalance.available)
                            .valueLong
                    )
            )
            .test(
                'bnbNotEnough',
                i18n.t('withdrawScreen.errors.bnbNotEnough'),
                () => isBNBEnough
            ),
    });

    const formikData = useFormik({
        initialValues: {
            amount: '',
        },
        validationSchema,
        onSubmit: () => openConfirmationModal(),
        enableReinitialize: true,
    });

    const {
        submitForm,
        values,
        errors,
        setFieldValue,
        setTouched,
        touched,
        isValid,
        dirty,
    } = formikData;

    const handleChangeAmount = async (amount: string) => {
        await setTouched({ amount: false });
        const convertedText = inputNumberFormatter(amount);
        if (!isBiggerThanMax(convertedText)) {
            await setFieldValue('amount', convertedText);
        }
    };

    const handleClickMax = async () => {
        await setFieldValue(
            'amount',
            balanceFromWei(gamingBalance?.iguBalance.available).valueLong
        );
        await setTouched({ amount: true });
    };
    const commissionFee = useMemo(() => {
        if (!config?.iguWalletWithdrawFeePercentage) {
            return 0;
        }
        return (
            Number(values.amount) *
            (config.iguWalletWithdrawFeePercentage / 100)
        );
    }, [config?.iguWalletWithdrawFeePercentage, values]);

    const totalSummary = useMemo(() => {
        switch (selectedWithdrawToken) {
            case Coin.busd:
                // TODO ADD LOGIC WITH BUSD
                return '0';

            case Coin.igu:
                return (Number(values.amount) - commissionFee).toString();

            default:
                return '0';
        }
    }, [selectedWithdrawToken, commissionFee, values]);

    const transactionSummaryValues = [
        {
            key: i18n.t('withdrawScreen.blockchainFee'),
            value: feeWithCurrency(estimatedFee),
            icon: renderFeeInfoIcon(true),
        },
        {
            key: i18n.t('checkout.fields.commissionFee'),
            value: feeWithCurrency(commissionFee, Coin.vigu),
            icon: renderCommissionFeeInfoIcon(true),
        },
        {
            key: i18n.t('withdrawScreen.totalSummary'),
            value: priceString(totalSummary, selectedWithdrawToken),
        },
    ];

    return {
        values,
        errors,
        setTouched,
        handleChangeAmount,
        gamingBalance,
        handleClickMax,
        estimatedFee,
        withdrawLoading,
        isBNBEnough,
        openIsVisibleModalDeposit,
        touched,
        walletBalance,
        balanceReloading,
        onRefresh,
        transactionSummaryValues,
        isValid,
        dirty,
        submitForm,
        isVisibleConfirmationModal,
        handleConfirmWithdraw,
        closeConfirmationModal,
        selectedWithdrawToken,
        renderFeeInfoIcon,
        renderCommissionFeeInfoIcon,
        closeIsVisibleModalDeposit,
        isVisibleModalDeposit,
        renderBlockchainErrorsModal,
    };
};
export default useWithdrawInputAmountScreen;
