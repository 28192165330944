import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import ProvablyFairIcon from '@assets/icons/mysteryGames/QuantumSpin/provablyFair.svg';
import CardFeatureInfo from '@components/CardFeatureInfo/CardFeatureInfo';
import CoinIcon from '@components/Icons/CoinIcon';
import InfoIcon from '@components/InfoIcon/InfoIcon';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT, TYPO_TEXT_COLOR } from '@components/TypoText/types';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import useFeeInfo from '@hooks/useFeeInfo';
import usePreventDoubleClick from '@hooks/usePreventDoubleClick';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';

import stylesMain from './styles';

interface IProps {
    customStyles?: StyleProp<ViewStyle>;
    type?: 'default' | 'opacity';
    onInfoIconPress: () => void;
}

const game = MYSTERY_GAMES.quantumSpin;

const QuantumSpinProvablyFair = ({
    customStyles,
    type = 'default',
    onInfoIconPress,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const isDefaultType = React.useMemo(() => type === 'default', [type]);

    const { onClick, disabled } = usePreventDoubleClick(
        onInfoIconPress,
        2000,
        false
    );

    return (
        <CardFeatureInfo
            type={type}
            containerStyle={customStyles}
            titleComponent={
                <View style={styles.titleWrapper}>
                    <TypoText
                        type={TYPO_TEXT.SUBHEAD_BIG_BOLD}
                        colorType={
                            isDefaultType
                                ? TYPO_TEXT_COLOR.PRIMARY
                                : TYPO_TEXT_COLOR.ON_DARK
                        }
                        additionalStyles={styles.title}>
                        {i18n.t(
                            `HomeScreen.${game}.QuantumSpinProvablyFair.card.title`
                        )}
                    </TypoText>
                    <InfoIcon
                        disabled={disabled}
                        iconColor={
                            !isDefaultType ? styles.iconColor.color : undefined
                        }
                        onPress={onClick}
                    />
                </View>
            }
            text={i18n.t(
                `HomeScreen.${game}.QuantumSpinProvablyFair.card.text`
            )}
            icon={<ProvablyFairIcon width={48} height={48} />}
        />
    );
};
export default QuantumSpinProvablyFair;
