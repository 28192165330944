import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { scale, verticalScale } from '../../helpers/dimensions';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            backgroundColor: 'transparent',
        },
        carousel: {
            justifyContent: 'center',
            alignItems: 'center',
        },
        paginationContainer: {
            flexDirection: 'row',
            justifyContent: 'center',
            backgroundColor: 'transparent',
        },
        paginationItem: {
            width: 8,
            height: 8,
            borderRadius: 4,
            marginHorizontal: scale(3),
            backgroundColor: Colors[theme].bullet,
            marginVertical: 16,
        },
        paginationItemActive: {
            backgroundColor: Colors[theme].bulletActive,
        },
    });

export default styles;
