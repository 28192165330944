import React from 'react';

import {
    NFT_EVOLUTION_COLOR,
    NFT_EVOLUTION_COLOR_SUCCESS,
} from '@contexts/MysteryGamesContext';

import CircleProgress from '../../components/CircleProgress';

interface IProps {
    isLoaded: boolean;
    onComplete: () => void;
    loadingComponent: React.ReactNode;
    staticComponent: React.ReactNode;
    loadedComponent: React.ReactNode;
    isOneTheme?: boolean;
    duration?: number;
    color?: string;
    trailColor?: string;
    shouldRepeat?: boolean;
    isPlaying: boolean;
}

export const BreedingCircleAnimationItem = ({
    loadingComponent,
    loadedComponent,
    onComplete,
    isLoaded,
    isOneTheme,
    duration = 4,
    color = NFT_EVOLUTION_COLOR,
    trailColor = '#fff',
    shouldRepeat,
    staticComponent,
    isPlaying,
}: IProps) => {
    return (
        <CircleProgress
            isPlaying={isPlaying}
            loadingComponent={loadingComponent}
            staticComponent={staticComponent}
            loadedComponent={loadedComponent}
            onComplete={onComplete}
            isLoaded={isLoaded}
            isOneTheme={isOneTheme}
            shouldRepeat={shouldRepeat}
            trailColor={trailColor}
            duration={duration}
            color={color}
        />
    );
};

export default BreedingCircleAnimationItem;
