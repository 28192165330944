import {
    CreatePaymentOutput,
    PaginationMeta,
    PaymentOptionOutput,
    SignedTransactionOutput,
    TokenTransactionStatus,
} from '@Data/Models';

export interface IGamingWallet {
    id: GAMING_WALLET;
    coinAmount: string;
}

export enum GAMING_WALLET {
    available = 'AvailableWallet',
    pending = 'PendingWallet',
}

export type GamingIguBalance = {
    total: string;
    available: string;
    locked: string;
};
export type GamingIguClaimBalanceOutput = {
    signedTransaction: SignedTransactionOutput;
    paymentOption: PaymentOptionOutput;
};
export type GamingIguBalanceResponse = {
    iguBalance: GamingIguBalance;
    iguPendingWithdraw: GamingIguClaimBalanceOutput | null;
};
export type CreateWithdrawIguResponse = {
    withdrawIgu: CreatePaymentOutput;
};
export type WithdrawIguPendingBalanceResponse = {
    iguPendingWithdraw: GamingIguClaimBalanceOutput | null;
};

export enum IGU_WALLET_TRANSACTIONS {
    All = 'All',
    Withdraw = 'Withdraw',
    Deposit = 'Deposit',
    RevertWithdraw = 'RevertWithdraw',
    MysteryLikesGamePayment = 'MysteryLikesGamePayment',
    MysteryLikesGameWin = 'MysteryLikesGameWin',
    MysteryLikesGameDraw = 'MysteryLikesGameDraw',
    MysteryLikesGameRefund = 'MysteryLikesGameRefund',
    DepositBonus = 'DepositBonus',
    MysteryLikesGameFee = 'MysteryLikesGameFee',
    DirectReferralDepositBonus = 'DirectReferralDepositBonus',
    IndirectReferralDepositBonus = 'IndirectReferralDepositBonus',
    MysteryLikesGameDirectReferralBonus = 'MysteryLikesGameDirectReferralBonus',
    MysteryLikesGameIndirectReferralBonus = 'MysteryLikesGameIndirectReferralBonus',
    Breeding = 'Breeding',
    GamesTurnoverReward = 'GamesTurnoverReward',
    NftRoyaleGamePayment = 'NftRoyaleGamePayment',
    NftRoyaleGameRefund = 'NftRoyaleGameRefund',
    NftRoyaleGameWin = 'NftRoyaleGameWin',
    NftRoyaleGameDraw = 'NftRoyaleGameDraw',
    NftRoyaleGameFee = 'NftRoyaleGameFee',
    LuckyStrikeGamePayment = 'LuckyStrikeGamePayment',
    LuckyStrikeGameWin = 'LuckyStrikeGameWin',
    QuantumSpin = 'QuantumSpin',
    QuantumSpinReward = 'QuantumSpinReward',
    TokentellerGamePredictWin = 'TokentellerGamePredictWin',
    TokentellerGamePredict = 'TokentellerGamePredict',
    TokentellerGamePredictFee = 'TokentellerGamePredictFee',
    TokentellerGamePredictRefund = 'TokentellerGamePredictRefund',
}

export type IguWalletTransaction = {
    id: string;
    type: IGU_WALLET_TRANSACTIONS;
    iguAmountWei: string;
    createdAt: string;
    locked: boolean;
};

export type PaginatedIguWalletTransactionsOutput = {
    iguTransactionHistory: {
        items: IguWalletTransaction[];
        meta: PaginationMeta;
    };
};
export interface IGamingWalletTransactionDetails {
    date: string;
    value: string;
    color: string;
    type: IGU_WALLET_TRANSACTIONS;
    status: TokenTransactionStatus;
    iguAmountWei: string;
}
