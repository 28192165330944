import * as React from 'react';

import { VerificationTaskStatus } from '../../../../../Data/Models';
import VerifyGameTask from '../../../../../assets/icons/play/taskVerify.svg';
import Button from '../../../../../components/Button';
import { Text, View } from '../../../../../components/Themed';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import EnergyBlock from '../EnergyBlock';
import TaskStatusBadge from '../TaskStatusBadge';
import stylesMain from './styles';

interface IProps {
    onButtonPress: () => void;
    maxEnergyReward: number | undefined;
    energyRewarded: number;
    canStart: boolean | undefined;
    status: VerificationTaskStatus;
    loading?: boolean;
    disabled?: boolean;
}

const VerifyTaskCard = ({
    maxEnergyReward,
    onButtonPress,
    canStart,
    energyRewarded,
    status,
    loading,
    disabled,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const buttonText = canStart
        ? i18n.t('playScreen.playCards.VERIFY_button2')
        : i18n.t('playScreen.playCards.VERIFY_button1');
    const maxEnergy = maxEnergyReward || 0;

    const renderStatutes = () => {
        switch (status) {
            case 'COMPLETED':
                return <TaskStatusBadge status={status} />;
            case 'FAILED':
                return <TaskStatusBadge status={status} />;

            default:
                return (
                    <EnergyBlock
                        size={16}
                        energy={`${energyRewarded} / ${maxEnergy}`}
                        textStyles={styles.energyCost}
                        containerStyles={styles.energyBlock}
                    />
                );
        }
    };

    return (
        <View style={styles.container}>
            <View style={styles.top}>
                <View style={styles.textWrapper}>
                    <View style={styles.statutes}>{renderStatutes()}</View>
                    <Text style={styles.title}>
                        {i18n.t('playScreen.playCards.VERIFY_title')}
                    </Text>
                    <Text style={styles.info}>
                        {i18n.t('playScreen.playCards.VERIFY_info')}
                    </Text>
                </View>
                <VerifyGameTask />
            </View>
            {!canStart && status !== 'CREATED' ? (
                <EnergyBlock
                    size={20}
                    energy={i18n.t('playScreen.playCards.completedEnergy', {
                        energyRewarded,
                        maxEnergyReward: maxEnergy,
                    })}
                    textStyles={styles.energyCost}
                    containerStyles={styles.completedEnergy}
                />
            ) : (
                <Button
                    title={buttonText}
                    onPress={onButtonPress}
                    debouncedPress
                    loading={loading}
                    disabled={disabled}
                />
            )}
        </View>
    );
};
export default VerifyTaskCard;
