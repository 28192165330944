import * as React from 'react';

import {
    BREEDING_MINT_PET_DRAFT,
    BREEDING_PET_DRAFT_UPDATE,
} from '@Data/Requests';
import { useMutation } from '@apollo/client';
import { useDimensions } from '@contexts/DimensionsContext';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { errorsHandler } from '@helpers/errors';
import { toastErrorUnknown } from '@helpers/toastNotification';
import useFeeInfo from '@hooks/useFeeInfo';
import { useLoadingHook } from '@hooks/useLoadingHook';
import { useVisibleHook } from '@hooks/useVisibleHook';
import {
    BreedingMintPetDraftResponse,
    BreedingPetDraftSelectableImage,
    BreedingPetDraftUpdate,
} from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

const NftEvolutionMintPetScreen = () => {
    const navigation = useNavigation();
    const { walletBalance, fee } = useWallet();
    const { breedingPetDraftDetails } = useMysteryGames();
    const { executeTransaction } = useWallet();
    const { windowWidth } = useDimensions();

    const [isNameError, setIsNameError] = React.useState<string>();
    const handleNameError = (status: string | undefined) => {
        setIsNameError(status);
    };

    const [imageId, setImageId] = React.useState<string>(
        breedingPetDraftDetails?.selectedImageId || ''
    );
    const handleOnSelectedImage = React.useCallback(
        (id: string) => {
            if (id === imageId) {
                return;
            }
            setImageId(id);
        },
        [imageId]
    );

    const isNew = React.useMemo(
        () => !!breedingPetDraftDetails?.editable,
        [breedingPetDraftDetails?.editable]
    );
    const initName = React.useMemo(
        () => (!isNew ? breedingPetDraftDetails?.name || null : null),
        [breedingPetDraftDetails, isNew]
    );

    const [name, setName] = React.useState<string | null>(initName);

    const cantOpenConfirmationModal = React.useMemo(
        () => (!imageId || !name) && isNew,
        [imageId, name]
    );

    const [draftUpdate] = useMutation<BreedingPetDraftUpdate>(
        BREEDING_PET_DRAFT_UPDATE
    );
    const [mintPetDraft] = useMutation<BreedingMintPetDraftResponse>(
        BREEDING_MINT_PET_DRAFT
    );

    const {
        isVisible: confirmationModal,
        open: openConfirmationModal,
        close: closeConfirmationModal,
    } = useVisibleHook();

    const { renderFeeInfoIcon } = useFeeInfo(
        closeConfirmationModal,
        openConfirmationModal
    );
    const { isLoading, startLoading, stopLoading } = useLoadingHook();

    const mint = async (
        petDraftId: string,
        selectableImages: BreedingPetDraftSelectableImage[] | [],
        imageUrl: string | null,
        rank: string
    ) => {
        const mintDraftResult = await mintPetDraft({
            variables: { petDraftId },
        });
        if (mintDraftResult.data?.breedingMintPetDraft) {
            const { signedTransaction, payment } =
                mintDraftResult.data.breedingMintPetDraft;

            const transactionResult = await executeTransaction(
                payment,
                signedTransaction
            );

            const img =
                selectableImages.find((item) => item.id === imageId)?.url ||
                imageUrl;

            navigation.replace(ROUTES.NFT_EVOLUTION_MINT_PET_SUCCESS, {
                transactionResult,
                payment,
                pet: {
                    name,
                    img,
                    rank,
                },
            });
        }
    };

    const confirmMint = React.useCallback(async () => {
        if (!breedingPetDraftDetails) {
            return toastErrorUnknown();
        }
        const {
            id: petDraftId,
            rank,
            selectableImages,
            imageUrl,
        } = breedingPetDraftDetails;
        startLoading();
        try {
            if (!isNew) {
                await mint(petDraftId, selectableImages, imageUrl, rank);
            } else {
                const draftUpdateResult = await draftUpdate({
                    variables: {
                        petDraftId,
                        name,
                        imageId,
                    },
                });

                if (draftUpdateResult.data?.breedingPetDraftUpdate) {
                    await mint(petDraftId, selectableImages, imageUrl, rank);
                } else {
                    toastErrorUnknown();
                }
            }

            stopLoading();
        } catch (error) {
            stopLoading();
            errorsHandler(error, true);
        }
    }, [imageId, name, breedingPetDraftDetails]);

    const estimatedFee = React.useMemo(
        () => fee?.executeTransaction.BNB,
        [fee]
    );
    const isBNBEnough = React.useMemo(() => {
        return Number(walletBalance?.bnb.value) >= Number(estimatedFee);
    }, [estimatedFee, walletBalance?.bnb]);

    return {
        isLoading,
        breedingPetDraftDetails,
        isNew,
        windowWidth,
        name,
        setName,
        handleNameError,
        isNameError,
        imageId,
        handleOnSelectedImage,
        cantOpenConfirmationModal,
        openConfirmationModal,
        confirmationModal,
        confirmMint,
        closeConfirmationModal,
        renderFeeInfoIcon,
        isBNBEnough,
        estimatedFee,
    };
};
export default NftEvolutionMintPetScreen;
