import * as React from 'react';
import { ScrollView } from 'react-native';

import ButtonsBottom from '@components/ButtonsBottom';
import ModalLoader from '@components/ModalLoader';
import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';

import BreedCost from '../../components/BreedCost';
import GamesNavigation from '../../components/GamesNavigation';
import NftEvolutionSelectPetsBlock from '../../components/NftEvolutionSelectPetsBlock';
import RankToBreedTable from '../../components/RankToBreedTable';
import useNftEvolutionSelectPetsScreen from './hooks/useNftEvolutionSelectPetsScreen';
import stylesMain from './styles';

const SCREEN = 'NftEvolutionSelectPets';

const NftEvolutionSelectPetsScreen = () => {
    const styles = useThemedStyles(stylesMain);

    const {
        onInfoPress,
        isButtonCanShow,
        onCalculatePress,
        breedingPetsPairStatsLoading,
        breedingRanksStats,
    } = useNftEvolutionSelectPetsScreen();

    return (
        <View style={styles.main}>
            <ModalLoader
                isVisible={breedingPetsPairStatsLoading}
                text={i18n.t(`HomeScreen.NftEvolution.${SCREEN}.loading`)}
            />
            <GamesNavigation
                type={MYSTERY_GAMES.nftEvolution}
                handleOnInfoIconPress={onInfoPress}
            />

            <ScrollView showsVerticalScrollIndicator={false}>
                <NftEvolutionSelectPetsBlock />

                <View style={styles.content}>
                    {!!breedingRanksStats && (
                        <RankToBreedTable
                            title={i18n.t(
                                'HomeScreen.NftEvolution.WelcomeNftEvolution.BestRankToBreed.title'
                            )}
                            text={i18n.t(
                                'HomeScreen.NftEvolution.WelcomeNftEvolution.BestRankToBreed.text'
                            )}
                            data={breedingRanksStats}
                            containerStyle={styles.table}
                        />
                    )}
                    <BreedCost />
                </View>
            </ScrollView>
            {isButtonCanShow && (
                <ButtonsBottom
                    title={i18n.t(`HomeScreen.NftEvolution.${SCREEN}.button`)}
                    onPress={onCalculatePress}
                    safe
                    disabled={breedingPetsPairStatsLoading}
                />
            )}
        </View>
    );
};

export default NftEvolutionSelectPetsScreen;
