import { gql } from '@apollo/client';

export const GET_USER_ACHIEVEMENTS = gql`
    query UserAchievements {
        userAchievements {
            type
            achievedAt
            progress
            targetProgress
            title
            description
            templateUrl
            backgroundColor
            shadowColor
        }
    }
`;

export const GET_RECENTLY_USER_ACHIEVEMENTS = gql`
    query RecentlyUserAchievements {
        recentlyUserAchievements {
            type
            achievedAt
            progress
            targetProgress
            title
            description
            templateUrl
            backgroundColor
            shadowColor
        }
    }
`;
