import { StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import { isIOS } from '../../../../helpers/app';
import { scale, verticalScale } from '../../../../helpers/dimensions';
import font from '../../../../helpers/font';
import { theme } from '../../../../types';

const style = (theme: theme) =>
    StyleSheet.create({
        closeButton: {
            position: 'absolute',
            right: scale(20),
        },
        inputGap: {
            marginTop: verticalScale(16),
        },
        button: {
            marginTop: verticalScale(15),
            marginBottom: verticalScale(30),
        },
        sendCodeButton: {
            position: 'absolute',
            top: isIOS ? verticalScale(10) : verticalScale(14),
            right: scale(16),
            zIndex: 100,
        },
        sendCodeText: {
            position: 'absolute',
            top: verticalScale(16),
            right: scale(16),
            zIndex: 5,
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
            color: Colors[theme].text.secondary,
        },
        sendCodeTextBold: {
            color: Colors[theme].text.primary,
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),
        },
        inputCode: {
            paddingRight: scale(100),
        },
        errorMessage: {
            color: Colors[theme].incorrect,
        },
        text: {
            textAlign: 'center',
            ...font({
                size: 13,
                height: 16,
                weight: '400',
            }),
        },
        buttonGap: {
            marginTop: verticalScale(24),
        },
        handleIndicator: {
            backgroundColor: '#9DA0AF',
            width: 56,
            height: 4,
        },
    });

export default style;
