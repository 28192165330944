import { useCallback, useState } from 'react';

import { waitForModal, waitForNavigation } from '@helpers/helpers';
import { useNavigation } from '@navigation/useNavigation';
import { useFocusEffect } from '@react-navigation/native';

import ROUTES from '../navigation/routes';

interface IProps {
    modalClose?: () => void;
    modalOpen?: () => void;
    route?: ROUTES;
}

const useOpenModalScreen = ({
    modalClose,
    modalOpen,
    route = ROUTES.HOW_TO_FEE,
}: IProps) => {
    const navigation = useNavigation();

    const [shouldShownModal, setShouldShownModal] = useState<boolean>(false);

    useFocusEffect(
        useCallback(() => {
            const showConfirmModal = async () => {
                await waitForNavigation();
                modalOpen?.();
                setShouldShownModal(false);
            };
            if (shouldShownModal) {
                showConfirmModal();
            }
        }, [shouldShownModal])
    );
    const handleOpenModalScreen = async (route: ROUTES, params?: any) => {
        modalClose?.();
        await waitForModal();
        navigation.push(route, params);
        setShouldShownModal(true);
    };

    return {
        handleOpenModalScreen,
    };
};

export default useOpenModalScreen;
