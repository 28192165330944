import { SvgProps } from 'react-native-svg';

import Pause from '../../../../assets/icons/play/tasks/move/pause.svg';
import Stopped from '../../../../assets/icons/play/tasks/move/stopped.svg';
import TooFast from '../../../../assets/icons/play/tasks/move/tooFast.svg';
import Walking from '../../../../assets/icons/play/tasks/move/walking.svg';
import i18n from '../../../../i18n/i18n';

export interface IWalkStatus {
    text: string;
    icon: React.FC<SvgProps>;
}

export interface IWalkStatuses {
    paused: IWalkStatus;
    walking: IWalkStatus;
    stopped: IWalkStatus;
    tooFast: IWalkStatus;
}

export const WALK_STATUSES: IWalkStatuses = {
    paused: { text: i18n.t('moveToEarn.progress.paused'), icon: Pause },
    walking: { text: i18n.t('moveToEarn.progress.walking'), icon: Walking },
    stopped: { text: i18n.t('moveToEarn.progress.stopped'), icon: Stopped },
    tooFast: { text: i18n.t('moveToEarn.progress.tooFast'), icon: TooFast },
};
export const INITIAL_COORDINATES = { latitude: 0, longitude: 0 };
export const BACKGROUND_LOCATION = 'BACKGROUND_LOCATION_TASK';
export const MODAL_TYPES = {
    prepare: 'prepare',
    leave: 'leave',
    leaveWithOutFinish: 'leaveWithOutFinish',
} as const;
export const MAX_SPEED = 20;
export const MIN_SPEED = 1;
export const WATCH_POSITION_DISTANCE_INTERVAL = 1.5;
export const MAX_INTERVAL_DISTANCE = (MAX_SPEED * 1000) / (60 * 60);
