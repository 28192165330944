import React from 'react';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import VerticalCentredContent from '@components/VerticalCentredContent';
import { isAndroid } from '@helpers/app';

import WhatIsRankIcon from '../../../assets/icons/ranks/whatIsRank.svg';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import ButtonsBottom from '../../ButtonsBottom';
import ModalBottom from '../../ModalBottom';
import mainStyles from './styles';

interface IProps {
    isVisible: boolean;
    buttonAllRanks?: boolean;
    closeModal: () => void;
    actionModal: () => void;
}

const ModalWhatIsRank = ({
    isVisible,
    buttonAllRanks = false,
    closeModal,
    actionModal,
}: IProps) => {
    const styles = useThemedStyles(mainStyles);

    const buttonText = React.useMemo(
        () =>
            buttonAllRanks
                ? i18n.t('ranks.modalWhatIsRank.buttonAll')
                : i18n.t('ranks.modalWhatIsRank.buttonAvailable'),
        [buttonAllRanks]
    );

    return (
        <ModalBottom
            modalHeight="85%"
            isVisible={isVisible}
            onClose={closeModal}>
            <VerticalCentredContent style={styles.container}>
                <BlockHeader
                    type="emptyState"
                    emptyStateIcon={<WhatIsRankIcon />}
                    title={i18n.t('ranks.modalWhatIsRank.title')}
                    text={i18n.t('ranks.modalWhatIsRank.text')}
                />
            </VerticalCentredContent>
            <ButtonsBottom
                title={buttonText}
                onPress={actionModal}
                safe={!isAndroid}
            />
        </ModalBottom>
    );
};
export default ModalWhatIsRank;
