import { Platform, StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            marginTop: Platform.OS === 'android' ? verticalScale(20) : 0,
            flex: 1,
            justifyContent: 'space-between',
        },

        header: {
            paddingHorizontal: scale(20),
        },
        headerText: {
            flex: 1,
            marginTop: verticalScale(4.5),
            textAlign: 'center',
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
        },
    });

export default styles;
