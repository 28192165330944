import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        selector: {
            paddingLeft: scale(8),
            paddingRight: scale(12),
        },
        title: {
            ...font({
                size: 14,
                height: 22,
                weight: '700',
            }),
            letterSpacing: 0.08,
            color: Colors[theme].text.primary,
            marginLeft: scale(6),
        },
        content: {
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: scale(4),
        },
    });

export default styles;
