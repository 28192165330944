import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { verticalScale } from '@helpers/dimensions';

import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            alignSelf: 'center',
            marginTop: verticalScale(32),
            backgroundColor: 'transparent',
        },

        icon: {
            color: Colors[theme].icon.green,
        },
    });

export default styles;
