import * as React from 'react';

import AgeIndicator from '@components/AgeIndicator';
import CircleDivider from '@components/CircleDivider';
import HealthIndicator from '@components/HealthIndicator';
import RankIcon from '@components/Icons/RankIcon';
import ImageExtended from '@components/ImageExtended';
import LevelIndicator from '@components/LevelIndicator';
import { Text, View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import { MarketplaceSaleablePetOutput } from '@models/marketplace';

import stylesMain from './styles';

interface IProps {
    pet: MarketplaceSaleablePetOutput;
}

const MarketPlaceSellablePetInfoCard = ({ pet }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={[styles.container]}>
            <View style={styles.imgWrapper}>
                <ImageExtended uri={pet?.imageUrl} borderRadius={6} />
            </View>

            <View style={styles.textWrapper}>
                <View style={styles.titleBlock}>
                    <RankIcon slug={pet.rank} size={24} />
                    <Text
                        style={styles.name}
                        numberOfLines={1}
                        ellipsizeMode="tail">
                        {pet.name}
                    </Text>
                </View>
                <LevelIndicator
                    isOneColor
                    level={pet.level}
                    fillAmount={pet.levelProgressPercentage}
                />
                <View style={styles.footer}>
                    <HealthIndicator
                        isOneColor
                        healthPercentage={pet.healthPercentage}
                        badge
                        showMaxHealth={false}
                    />
                    <CircleDivider />
                    <AgeIndicator
                        isOneColor
                        ageDays={pet.ageDays}
                        maxAgeDays={pet.maxAgeDays}
                        containerStyle={styles.ageWrapper}
                    />
                </View>
            </View>
        </View>
    );
};
export default MarketPlaceSellablePetInfoCard;
