import * as React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import useThemedStyles from '../../hooks/useThemedStyles';
import ModalBottom from '../ModalBottom';
import Selector from '../Selector';
import { Text, View } from '../Themed';
import stylesMain from './styles';

export interface IFilterData {
    key: string;
    label: string;
}

interface IProps {
    titleText: string;
    onSelect: (item: string) => void;
    selectedItem: string | undefined;
    data: IFilterData[];
}

const ListFilter = ({ titleText, onSelect, selectedItem, data }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const [filterModalVisible, setFilterModalVisible] =
        React.useState<boolean>(false);

    const showFilterModal = () => {
        setFilterModalVisible(true);
    };
    const hideFilterModal = () => {
        setFilterModalVisible(false);
    };

    const text = React.useMemo(
        () => data.find((item) => item.key === selectedItem)?.label || '',
        [data, selectedItem]
    );

    const renderItem = React.useCallback(
        (item: IFilterData, index: number) => {
            const isSelected = item.key === selectedItem;
            return (
                <TouchableOpacity
                    key={item.key}
                    activeOpacity={0.7}
                    onPress={() => {
                        onSelect(item.key);
                        hideFilterModal();
                    }}
                    style={[
                        styles.shadow,
                        index === 0 && styles.gap,
                        isSelected && styles.active,
                    ]}>
                    <View
                        style={[
                            styles.itemContainer,
                            isSelected && styles.activeContainer,
                        ]}>
                        <View style={styles.main}>
                            <Text style={styles.itemText}>{item.label}</Text>
                        </View>
                    </View>
                </TouchableOpacity>
            );
        },
        [selectedItem]
    );

    return (
        <View style={styles.container}>
            <Selector
                text={text}
                onPress={showFilterModal}
                maxWidth={100}
                containerStyle={styles.additionalStyles}>
                <ModalBottom
                    titleText={titleText}
                    modalHeight={550}
                    isVisible={filterModalVisible}
                    onClose={hideFilterModal}>
                    <ScrollView showsVerticalScrollIndicator={false}>
                        <TouchableOpacity activeOpacity={1}>
                            {data.map((item, index) => renderItem(item, index))}
                        </TouchableOpacity>
                    </ScrollView>
                </ModalBottom>
            </Selector>
        </View>
    );
};
export default React.memo(ListFilter);
