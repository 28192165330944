import * as React from 'react';
import { useEffect, useState } from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';

import AlertCard from '@components/AlertCard';
import { Text, View } from '@components/Themed';
import { useWallet } from '@contexts/Wallet/WalletContext';

import Button from '../../../../components/Button';
import EmptyList from '../../../../components/EmptyList';
import { ICON_NAMES } from '../../../../components/Icons';
import ModalBottom from '../../../../components/ModalBottom';
import WalletPrivateKey from '../../../../components/WalletPrivateKey';
import WalletSeedPhrase from '../../../../components/WalletSeedPhrase';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import NavigationBar from '../../../../navigation/NavigationBar';
import stylesMain from './styles';

const WalletBackupScreen = () => {
    const styles = useThemedStyles(stylesMain);

    const [mnemonicPhrase, setMnemonicPhrase] = useState<
        string | undefined | null
    >(null);
    const [privateKey, setPrivateKey] = useState<string | undefined | null>(
        null
    );
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [isMasked, setIsMasked] = useState<boolean>(true);
    const [isModalPrivateKeyVisible, setIsModalPrivateKeyVisible] =
        React.useState<boolean>(false);
    const { getMnemonic, getPrivateKey } = useWallet();

    const handleOpenPrivateKeyModal = () => {
        setIsModalPrivateKeyVisible(true);
    };

    const handleClosePrivateKeyModal = () => {
        setIsModalPrivateKeyVisible(false);
    };

    useEffect(() => {
        async function fetch() {
            setMnemonicPhrase(await getMnemonic());
            setPrivateKey(await getPrivateKey());
            setIsLoaded(true);
        }

        fetch();
    }, []);

    const handleOnPressReveal = () => {
        setIsMasked(false);
    };

    const handleOnHide = () => {
        setIsMasked(true);
    };

    const renderMnemonicSection = () => {
        return (
            <>
                <Text style={styles.title}>
                    {i18n.t('walletSettingsBackup.mnemonic.title')}
                </Text>
                <Text style={styles.text}>
                    {i18n.t('walletSettingsBackup.mnemonic.text')}
                </Text>
                <View style={styles.buttonWrapper}>
                    <Button
                        type="text"
                        title={i18n.t('walletSettingsBackup.viewPrivateKey')}
                        onPress={handleOpenPrivateKeyModal}
                        size="md"
                        iconName={ICON_NAMES.KEY}
                    />
                </View>
                <View style={styles.alert}>
                    <AlertCard
                        text={i18n.t('walletSettings.backup.modal.alert')}
                        type="exclamation"
                    />
                </View>
                <WalletSeedPhrase mnemonic={mnemonicPhrase} masked={isMasked} />
                <Button
                    iconName={
                        mnemonicPhrase
                            ? isMasked
                                ? ICON_NAMES.REVEALED
                                : ICON_NAMES.REVEAL
                            : undefined
                    }
                    iconColor="onDark"
                    disabled={!mnemonicPhrase}
                    onLongPress={handleOnPressReveal}
                    onPress={handleOnHide}
                    title={i18n.t(
                        `walletSettingsBackup.buttons.${
                            isMasked ? 'hidden' : 'visible'
                        }`
                    )}
                />
                {renderPrivateKeyModal()}
            </>
        );
    };

    const renderPrivateKeySection = () => {
        return (
            <>
                <Text style={styles.title}>
                    {i18n.t('walletSettingsBackup.privateKey.title')}
                </Text>
                <Text style={styles.text}>
                    {i18n.t('walletSettingsBackup.privateKey.text')}
                </Text>
                <View style={styles.alert}>
                    <AlertCard
                        text={i18n.t('walletSettings.backup.modal.alert')}
                        type="exclamation"
                    />
                </View>
                <WalletPrivateKey privateKey={privateKey} masked={isMasked} />
                <Button
                    iconName={
                        privateKey
                            ? isMasked
                                ? ICON_NAMES.REVEALED
                                : ICON_NAMES.REVEAL
                            : undefined
                    }
                    iconColor="onDark"
                    disabled={!privateKey}
                    onLongPress={handleOnPressReveal}
                    onPress={handleOnHide}
                    title={i18n.t(
                        `walletSettingsBackup.buttons.${
                            isMasked ? 'hidden' : 'visible'
                        }`
                    )}
                />
            </>
        );
    };

    const renderPrivateKeyModal = () => {
        return (
            <ModalBottom
                isVisible={isModalPrivateKeyVisible}
                titleText={i18n.t('walletSettingsBackup.privateKeyModal.title')}
                additionalText={i18n.t(
                    'walletSettingsBackup.privateKeyModal.text'
                )}
                onClose={handleClosePrivateKeyModal}
                avoidKeyboard
                modalHeight={650}>
                <View style={styles.privateKeyModal}>
                    <WalletPrivateKey privateKey={privateKey} masked={false} />
                </View>
            </ModalBottom>
        );
    };

    return (
        <SafeAreaView edges={['bottom']} style={{ ...styles.container }}>
            <NavigationBar filled={false} backIcon />
            {isLoaded && (
                <>
                    {mnemonicPhrase && (
                        <View style={styles.page}>
                            {renderMnemonicSection()}
                        </View>
                    )}
                    {!mnemonicPhrase && privateKey && (
                        <View style={styles.page}>
                            {renderPrivateKeySection()}
                        </View>
                    )}
                    {!mnemonicPhrase && !privateKey && (
                        <EmptyList
                            emptyTitle={i18n.t(
                                'walletSettingsBackup.emptyTitle'
                            )}
                            emptyText={i18n.t('walletSettingsBackup.emptyText')}
                            containerStyle={styles.emptyContainer}
                            withAnimation="cry"
                        />
                    )}
                </>
            )}
        </SafeAreaView>
    );
};

export default WalletBackupScreen;
