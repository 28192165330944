import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import GuideTooltipContent from '@components/GuideTooltipContent';
import HealthProgressIcon from '@components/Icons/HealthProgressIcon';
import { Text, View } from '@components/Themed';
import TooltipOnBoarding from '@components/TooltipOnBoarding';
import { isWeb } from '@helpers/app';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import LikesRangeItem from '../LikesRangeItem';
import stylesMain from './styles';

interface IProps {
    data: string[];
    onPress: (range: string) => void;
    selectedRange: string | undefined;
    isLoading: boolean;
    viewTip: boolean;
    closeTip: () => void;
}

const LikesRange = ({
    data,
    selectedRange,
    onPress,
    isLoading,
    viewTip,
    closeTip,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={styles.container}>
            {data.map((item, index) => {
                if (index === 0) {
                    return (
                        <TooltipOnBoarding
                            key={item}
                            closeOnChildInteraction
                            onClose={closeTip}
                            isVisible={viewTip}
                            isOneColor
                            showChildInTooltip
                            parentWrapperStyle={styles.parentWrapperStyle}
                            content={
                                <GuideTooltipContent
                                    text={i18n.t(
                                        `HomeScreen.MysteryLikes.MysteryLikesSelectRange.tooltip2`
                                    )}
                                    hint={2}
                                    maxHints={2}
                                    isOneColor
                                />
                            }
                            placement={isWeb ? 'top' : 'bottom'}>
                            <LikesRangeItem
                                index={index}
                                isActive={selectedRange === item}
                                onPress={onPress}
                                range={item}
                                isLoading={isLoading}
                                isTooltip
                            />
                        </TooltipOnBoarding>
                    );
                }
                return (
                    <LikesRangeItem
                        key={item}
                        index={index}
                        isActive={selectedRange === item}
                        onPress={onPress}
                        range={item}
                        isLoading={isLoading}
                    />
                );
            })}
        </View>
    );
};
export default LikesRange;
