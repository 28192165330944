import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
        },

        textWrapper: {
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
        },
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 22,
                height: 30,
                weight: '700',
            }),
            marginBottom: verticalScale(8),
        },
        text: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
            textAlign: 'center',
        },

        infoWrapper: {
            marginTop: verticalScale(8),
            marginBottom: verticalScale(20),
        },
        gap: {
            marginTop: verticalScale(12),
        },
    });

export default styles;
