import * as React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';
import YouTube from 'react-native-youtube-iframe';

import ICON from '@assets/icons/emptyState/memsModal.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import Button from '@components/Button';
import ButtonsBottom from '@components/ButtonsBottom';
import { ICON_NAMES } from '@components/Icons';
import ModalBottom from '@components/ModalBottom';
import { Text, View } from '@components/Themed';
import { isWeb } from '@helpers/app';
import { errorsHandler } from '@helpers/errors';
import { openUrlOrShowError } from '@helpers/linking';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
    modalHeight?: number | string;
}

const config = {
    linkDiscord: 'https://discord.com/invite/memefi',
    linkSite: 'https://www.memefi.club/',
    videoId: '2WskwY2Cp68',
    videoLink:
        'https://www.youtube.com/watch?v=2WskwY2Cp68&ab_channel=MemeFiClub',
};

const ModalMems = ({ isVisible, onClose, modalHeight = 999 }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const height = isWeb ? 250 : 200;

    const onLinkPress = async (uri: string) => {
        try {
            await openUrlOrShowError(uri);
        } catch (error) {
            errorsHandler(error, true);
        }
    };

    return (
        <ModalBottom
            isVisible={isVisible}
            onClose={onClose}
            modalHeight={modalHeight}>
            <ScrollView
                style={styles.container}
                showsVerticalScrollIndicator={false}>
                <TouchableOpacity activeOpacity={1}>
                    <BlockHeader
                        containerStyle={styles.header}
                        emptyStateIcon={<ICON />}
                        type="emptyState"
                        titleComponent={
                            <>
                                {i18n.t('HomeScreen.MemsModal.title1')}
                                <Text style={styles.accent}>{'$450.000'}</Text>
                                {i18n.t('HomeScreen.MemsModal.title2')}
                            </>
                        }
                        text={i18n.t('HomeScreen.MemsModal.text')}
                    />
                    <View style={[styles.video, { height }]}>
                        <YouTube videoId={config.videoId} height={height} />
                    </View>
                </TouchableOpacity>
            </ScrollView>

            <ButtonsBottom
                title={i18n.t(`HomeScreen.MemsModal.button1`)}
                onPress={() => onLinkPress(config.linkDiscord)}
                safe>
                <Button
                    containerStyle={styles.gap}
                    iconDirection="right"
                    onPress={() => onLinkPress(config.linkSite)}
                    type="outline"
                    title={i18n.t(`HomeScreen.MemsModal.button2`)}
                    iconName={ICON_NAMES.EXPLORE}
                />
            </ButtonsBottom>
        </ModalBottom>
    );
};

export default ModalMems;
