import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import GameTypeIcon from '@assets/icons/mysteryGames/LuckyStrike/history/gameType.svg';
import TicketCurrent from '@assets/icons/mysteryGames/LuckyStrike/history/ticketCurrent.svg';
import TicketWinIcon from '@assets/icons/mysteryGames/LuckyStrike/history/ticketWin.svg';
import Button from '@components/Button';
import { ICON_NAMES } from '@components/Icons';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT } from '@components/TypoText/types';
import { DECLINATIONS, getDeclination } from '@helpers/declination';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import {
    LuckyStrikeGameHistoryOutput,
    MYSTERY_GAMES,
} from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import stylesMain from './styles';

interface IProps {
    containerStyle?: StyleProp<ViewStyle>;
    data: LuckyStrikeGameHistoryOutput;
    gameId: string;
}

const GAME = MYSTERY_GAMES.luckyStrike;
const SCREEN = 'LuckyStrikeGameHistoryInfo';

const LuckyStrikeGameHistoryInfo = ({
    containerStyle,
    data,
    gameId,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const navigation = useNavigation();

    const numberOfTickets = React.useMemo(
        () => data.userTicketNumbers.length,
        [data.userTicketNumbers.length]
    );

    const navigateToTickets = React.useCallback(() => {
        navigation.push(ROUTES.LUCKY_STRIKE_USER_TICKETS, { id: gameId });
    }, [gameId]);

    const ticketText = React.useMemo(
        () =>
            numberOfTickets === 1
                ? i18n.t(`HomeScreen.${GAME}.${SCREEN}.yourTicket`)
                : i18n.t(`HomeScreen.${GAME}.${SCREEN}.yourTickets`),
        [numberOfTickets]
    );

    return (
        <View style={[styles.container, containerStyle]}>
            <View style={[styles.item, styles.gap]}>
                <View style={styles.itemLeft}>
                    <View style={styles.icon}>
                        <GameTypeIcon width={24} height={24} />
                    </View>
                    <TypoText type={TYPO_TEXT.SUBHEAD_SMALL_SEMIBOLD}>
                        {i18n.t(`HomeScreen.${GAME}.${SCREEN}.gameType`)}
                    </TypoText>
                </View>
                <TypoText type={TYPO_TEXT.SUBHEAD_SMALL_BOLD}>
                    {data.title}
                </TypoText>
            </View>
            <View style={[styles.item, styles.gap]}>
                <View style={styles.itemLeft}>
                    <View style={styles.icon}>
                        <TicketWinIcon width={24} height={24} />
                    </View>
                    <TypoText type={TYPO_TEXT.SUBHEAD_SMALL_SEMIBOLD}>
                        {i18n.t(`HomeScreen.${GAME}.${SCREEN}.correctTicket`)}
                    </TypoText>
                </View>
                <TypoText
                    type={TYPO_TEXT.SUBHEAD_SMALL_BOLD}
                    additionalStyles={styles.margin}>
                    {`#${data.wonTicketNumber}`}
                </TypoText>
            </View>
            <View style={[styles.item]}>
                <View style={styles.itemLeft}>
                    <View style={styles.icon}>
                        <TicketCurrent width={24} height={24} />
                    </View>
                    <TypoText type={TYPO_TEXT.SUBHEAD_SMALL_SEMIBOLD}>
                        {ticketText}
                    </TypoText>
                </View>
                {numberOfTickets > 1 ? (
                    <Button
                        type="text"
                        isOneColor
                        size="md"
                        title={`${numberOfTickets} ${getDeclination(
                            numberOfTickets,
                            DECLINATIONS.Ticket
                        )}`}
                        iconName={ICON_NAMES.ARROW_RIGHT_SECONDARY}
                        textStyles={styles.buttonTextStyle}
                        iconDirection="right"
                        iconSize={24}
                        onPress={navigateToTickets}
                        containerStyle={styles.button}
                        iconColor={styles.buttonTextStyle.color}
                    />
                ) : (
                    <TypoText type={TYPO_TEXT.SUBHEAD_SMALL_BOLD}>
                        {`#${data.userTicketNumbers[0]}`}
                    </TypoText>
                )}
            </View>
        </View>
    );
};
export default LuckyStrikeGameHistoryInfo;
