import React, {
    FC,
    PropsWithChildren,
    createContext,
    useEffect,
    useState,
} from 'react';

import AsyncStorage from '@react-native-async-storage/async-storage';

import SecureStore from '../utils/SecureStore';

export type SessionContextType = {
    sessionToken: string | null;
    setSessionToken: (token: string | null) => void;
    isLoaded: boolean;
};

export const SessionContext = createContext<SessionContextType>({
    sessionToken: null,
    setSessionToken: (token) => undefined,
    isLoaded: false,
});

export const useSession = () => React.useContext(SessionContext);

const config = {
    sessionKey: 'ACCESS_TOKEN',
};

const SessionProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const [sessionToken, setSessionTokenToState] = useState<string | null>(
        null
    );
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        async function loadToken() {
            const token = await loadSessionTokenFromStorage();
            Console.log(
                'SessionContext Auth token restored ',
                token?.substr(0, 5)
            );

            await setSessionToken(token);
            setIsLoaded(true);
        }
        loadToken();
    }, []);

    async function setSessionToken(token: string | null) {
        await saveSessionTokenToStorage(token);
        Console.log(`SessionContext.setSessionToken ${token?.substr(0, 5)}`);
        setSessionTokenToState(token);
    }

    return (
        <SessionContext.Provider
            value={{ sessionToken, setSessionToken, isLoaded }}>
            {children}
        </SessionContext.Provider>
    );
};

export default SessionProvider;

export async function loadSessionTokenFromStorage() {
    try {
        const secureToken = await SecureStore.getItemAsync(config.sessionKey);
        const storeToken = await AsyncStorage.getItem(config.sessionKey);
        // TODO: Keep this one for all user move key to SecureStore
        if (!secureToken && storeToken) {
            AsyncStorage.removeItem(config.sessionKey);
            saveSessionTokenToStorage(storeToken);
            return storeToken;
        }

        return secureToken;
    } catch (error) {
        Console.log(
            `loadSessionTokenFromStorage error ${JSON.stringify({ error })}`
        );
        return null;
    }
}

export async function saveSessionTokenToStorage(token: string | null) {
    try {
        if (token === null) {
            await clearSessionTokenToStorage();
        } else {
            await SecureStore.setItemAsync(config.sessionKey, token || '');
        }

        return true;
    } catch (error) {
        Console.log(
            `saveSessionTokenToStorage error ${JSON.stringify({ error })}`
        );
        return false;
    }
}

export async function clearSessionTokenToStorage() {
    try {
        await SecureStore.deleteItemAsync(config.sessionKey);
        return true;
    } catch (error) {
        Console.log(
            `saveSessionTokenToStorage error ${JSON.stringify({ error })}`
        );
        return false;
    }
}
