import { StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../../helpers/dimensions';
import font from '../../../../helpers/font';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
        },
        input: {
            paddingTop: verticalScale(8),
            paddingBottom: verticalScale(8),
            paddingLeft: verticalScale(38),
            paddingRight: verticalScale(12),
            borderRadius: moderateScale(10),
        },
        searchWrapper: {
            marginBottom: verticalScale(20),
        },
        topWrapper: {
            paddingVertical: verticalScale(16),
        },
        top: {
            flexDirection: 'row',
        },
        titleWrapper: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
        },
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
        },
        noResultTitle: {
            marginTop: verticalScale(20),
            color: Colors[theme].text.primary,
            ...font({
                size: 20,
                height: 26,
                weight: '700',
            }),
            marginBottom: verticalScale(8),
        },
        noResultText: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
            textAlign: 'center',
            letterSpacing: 0.16,
        },
        itemText: {
            flex: 1,
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
            marginLeft: verticalScale(12),
        },
        radio: {
            width: 20,
            height: 20,
            borderWidth: 1,
            borderRadius: 1000,
            borderColor: Colors[theme].radio.borderDefault,
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
        },
        groupedTitle: {
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
        },
        active: {
            borderColor: Colors[theme].radio.borderActive,
        },
        flatListWrapper: {
            marginTop: verticalScale(8),
            flex: 1,
        },

        listStyle: {
            marginTop: verticalScale(8),
            flex: 1,
        },

        buttonWrapper: {
            marginLeft: verticalScale(16),
        },
        bottomWrapper: {
            paddingHorizontal: scale(20),
            paddingVertical: verticalScale(16),
        },
        radioContainer: {
            backgroundColor: Colors[theme].petRankItem.backgroundDefault,
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].petRankItem.borderColorDefault,
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            marginBottom: verticalScale(4),
            flexDirection: 'row',
            alignItems: 'center',
        },
        shadow: {
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].petRankItem.shadowColorDefault,
            marginBottom: verticalScale(16),
        },
        selectedShadow: {
            backgroundColor: Colors[theme].petRankItem.shadowColorSelected,
        },
        selected: {
            backgroundColor: Colors[theme].petRankItem.backgroundSelected,
            borderColor: Colors[theme].petRankItem.borderColorSelected,
            shadowColor: Colors[theme].petRankItem.shadowColorSelected,
        },
        image: {
            width: scale(40),
            height: scale(40),
            borderRadius: 6,
        },
        maxSelect: {
            color: Colors[theme].text.warning,
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
            marginBottom: verticalScale(16),
        },
        noResult: {
            flex: 1,
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            paddingHorizontal: scale(36),
        },
        search: {
            color: Colors[theme].icon.secondary,
        },
    });

export default styles;
