import React, { useEffect, useState } from 'react';

import { waitForToast } from '@helpers/helpers';
import { toastSuccess } from '@helpers/toastNotification';
import { useLocalAuthentication } from '@hooks/useLocalAuthentication';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import { PassCodeConfirmProps } from '../../../../../types';
import { PassCodeWrapper } from '../../components/PassCodeWrapper';

export const PassCodeConfirmScreen = ({ route }: PassCodeConfirmProps) => {
    const [code, setCode] = useState<string>('');
    const [error, setError] = useState<string>('');
    const {
        checkPassCode,
        enablePassCode,
        checkBiometricAvailability,
        savePassCodeToStorage,
    } = useLocalAuthentication();

    const navigation = useNavigation();

    const navigateTo = (screen: ROUTES) => {
        navigation.pop();
        navigation.replace(screen);
    };

    const { change } = route.params;

    const changePassCode = async () => {
        await savePassCodeToStorage(code);
        navigation.pop();
        navigation.pop();
        await waitForToast();
        toastSuccess(i18n.t('passcode.toasts.change'), undefined, 'top');
    };

    useEffect(() => {
        if (code.length === 1) {
            setError('');
        }
        const check = async () => {
            const result = await checkPassCode(code, change);
            if (!result) {
                setCode('');
                setError(i18n.t('passcode.confirm.error'));
                return;
            }
            if (change) {
                await changePassCode();
                return;
            }
            await enablePassCode();
            const biometric = await checkBiometricAvailability();
            if (biometric) {
                navigateTo(ROUTES.PASSCODE_FACEID);
                return;
            }
            navigateTo(ROUTES.PASSCODE_SUCCESS);
        };
        if (code.length === 4) {
            check();
        }
    }, [code]);

    return (
        <PassCodeWrapper
            code={code}
            setCode={setCode}
            header={i18n.t('passcode.confirm.title')}
            title={i18n.t('passcode.confirm.title')}
            text={i18n.t('passcode.confirm.subTitle')}
            error={error}
        />
    );
};
