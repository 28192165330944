import * as React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import Icon from '@assets/icons/emptyState/howToClaim.svg';
import AlertCard from '@components/AlertCard';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import ModalBottom from '@components/ModalBottom';
import { isAndroid } from '@helpers/app';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onPress: () => void;
    onClose: () => void;
    modalHeight?: number | string;
}

const HowToClaimFreePetModal = ({
    isVisible,
    onPress,
    onClose,
    modalHeight = 750,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <ModalBottom
            isVisible={isVisible}
            onClose={onClose}
            modalHeight={modalHeight}>
            <ScrollView
                style={styles.container}
                showsVerticalScrollIndicator={false}>
                <TouchableOpacity activeOpacity={1}>
                    <BlockHeader
                        type="emptyState"
                        title={i18n.t('howToClaimFreePet.title')}
                        text={i18n.t('howToClaimFreePet.text')}
                        emptyStateIcon={<Icon />}
                    />
                </TouchableOpacity>
            </ScrollView>
            <AlertCard
                text={i18n.t('howToClaimFreePet.alert')}
                containerStyle={styles.alert}
            />
            <ButtonsBottom
                title={i18n.t('howToClaimFreePet.button')}
                onPress={onPress}
                safe={!isAndroid}
            />
        </ModalBottom>
    );
};

export default HowToClaimFreePetModal;
