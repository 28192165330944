import * as React from 'react';

import SocialIcons from '@components/Icons/SocialIcons';
import SelectorV2 from '@components/SelectorV2';
import { Text } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';

import SocialSelectModal from '../SocialSelectModal';
import stylesMain from './styles';

interface IProps {
    selectedSocial: string;
    socials: string[];
    onPress: (social: string) => void;
}

const SocialSelect = ({ onPress, selectedSocial, socials }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { isVisible, open, close } = useVisibleHook();

    return (
        <SelectorV2
            label={i18n.t('profile.adminReverification.labelSocial')}
            onPress={open}
            children={
                <>
                    <SocialIcons slug={selectedSocial} size={24} />

                    <Text style={styles.title}>
                        {i18n.t(
                            `profile.socialMediaSettings.media.socials.${selectedSocial}`
                        )}
                    </Text>
                </>
            }
            selectedItem={selectedSocial}
            modal={
                <SocialSelectModal
                    isVisible={isVisible}
                    close={close}
                    selectedSocial={selectedSocial}
                    socials={socials}
                    onPress={onPress}
                    modalHeight="70%"
                />
            }
        />
    );
};

export default SocialSelect;
