import * as React from 'react';
import { Linking } from 'react-native';

import { ActivityAction, startActivityAsync } from 'expo-intent-launcher';

import CustomModal from '@components/CustomModal';
import ImageIguana from '@components/ImageIguana';
import { isAndroid, isIOS, isWeb } from '@helpers/app';
import { openUrlOrShowError } from '@helpers/linking';
import i18n from '@i18n/i18n';

import { useVisibleHook } from './useVisibleHook';

export const useHandlePermission = (permission: string) => {
    const {
        isVisible: isModalNoPermissions,
        close: closeModalNoPermissions,
        open: openModalNoPermissions,
    } = useVisibleHook();

    const {
        isVisible: isModalNoPermissionsGlobal,
        close: closeModalNoPermissionsGlobal,
        open: openModalNoPermissionsGlobal,
    } = useVisibleHook();

    const {
        isVisible: isModalNoPermissionsGlobalWeb,
        close: closeModalNoPermissionsGlobalWeb,
        open: openModalNoPermissionsGlobalWeb,
    } = useVisibleHook();

    const [isGlobalLocationSettings, setIsGlobalLocationSetting] =
        React.useState<boolean>(false);

    const openAppSettings = () => {
        if (isIOS) {
            openUrlOrShowError('app-settings:');
        } else if (isAndroid) {
            Linking.openSettings();
        }
    };

    const openGlobalLocationSetting = () => {
        setIsGlobalLocationSetting(false);
        if (isIOS) {
            openUrlOrShowError('App-prefs:root=Privacy&path=LOCATION');
        } else if (isAndroid) {
            startActivityAsync(ActivityAction.LOCATION_SOURCE_SETTINGS);
        }
    };

    const renderItem = (action: () => void) => {
        const handleModalNoPermissionsAction = () => {
            closeModalNoPermissions();
            action();
        };

        const handleModalNoPermissionsActionGlobal = () => {
            closeModalNoPermissionsGlobal();
            if (isGlobalLocationSettings) {
                openGlobalLocationSetting();
            } else {
                openAppSettings();
            }
        };

        return (
            <>
                <CustomModal
                    isVisible={isModalNoPermissions}
                    icon={<ImageIguana type="redX" />}
                    titleText={i18n.t(
                        `modal.askFor${permission}Permissions.title`
                    )}
                    infoText={i18n.t(
                        `modal.askFor${permission}Permissions.text`
                    )}
                    firstButtonText={i18n.t('modal.askForPermissions')}
                    secondButtonText={i18n.t('modal.cancel')}
                    onFirstButtonClick={handleModalNoPermissionsAction}
                    onSecondButtonClick={closeModalNoPermissions}
                />
                <CustomModal
                    isVisible={isModalNoPermissionsGlobal}
                    icon={<ImageIguana type="redX" />}
                    titleText={i18n.t(
                        `modal.askFor${permission}Permissions.title`
                    )}
                    infoText={i18n.t(
                        `modal.askFor${permission}Permissions.text`
                    )}
                    firstButtonText={i18n.t('modal.goToSettings')}
                    secondButtonText={i18n.t('modal.cancel')}
                    onFirstButtonClick={handleModalNoPermissionsActionGlobal}
                    onSecondButtonClick={closeModalNoPermissionsGlobal}
                />
                {isWeb && (
                    <CustomModal
                        isVisible={isModalNoPermissionsGlobalWeb}
                        icon={<ImageIguana type="redX" />}
                        titleText={i18n.t(
                            'modal.askForLocationPermissionsWeb.title'
                        )}
                        infoText={i18n.t(
                            'modal.askForLocationPermissionsWeb.text'
                        )}
                        additionalText={i18n.t(
                            'modal.askForLocationPermissionsWeb.additionalText'
                        )}
                        firstButtonText={i18n.t(
                            'modal.askForLocationPermissionsWeb.button'
                        )}
                        firstButtonType="outline"
                        onFirstButtonClick={closeModalNoPermissionsGlobalWeb}
                    />
                )}
            </>
        );
    };

    return {
        closeModalNoPermissions,
        openModalNoPermissions,
        closeModalNoPermissionsGlobal,
        openModalNoPermissionsGlobal,
        renderItem,
        setIsGlobalLocationSetting,
        openModalNoPermissionsGlobalWeb,
    };
};
