import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { NFT_EVOLUTION_COLOR } from '@contexts/MysteryGamesContext';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        title: {
            textAlign: 'center',
            color: Colors[theme].text.onLight,
            ...font({
                size: 22,
                height: 30,
                weight: '800',
            }),
            marginBottom: verticalScale(8),
        },
        text: {
            textAlign: 'center',
            color: Colors[theme].text.onLight,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
        container: {
            justifyContent: 'center',
            flex: 1,
        },
        content: {
            marginTop: verticalScale(32),
            paddingHorizontal: scale(20),
            paddingBottom: verticalScale(24),
            backgroundColor: 'transparent',
        },
        main: {
            position: 'absolute',

            width: '100%',
            height: '100%',
            flex: 1,
            backgroundColor: NFT_EVOLUTION_COLOR,
            zIndex: 10000,
        },
        blockHeaderLoading: {
            backgroundColor: 'transparent',
        },
        containerStyle: {
            marginLeft: '5%',
        },
    });

export default styles;
