import React, { ReactElement } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';

import { Text, View } from '../../../../../../components/Themed';
import useThemedStyles from '../../../../../../hooks/useThemedStyles';
import mainStyle from './style';

type IProps = {
    icon: ReactElement<SvgProps>;
    description: string;
    text: string | number;
    containerStyle?: StyleProp<ViewStyle>;
};

const InfoContainer = ({ icon, description, text, containerStyle }: IProps) => {
    const styles = useThemedStyles(mainStyle);
    return (
        <View style={[styles.container, containerStyle]}>
            {icon}
            <Text style={styles.text}>{text}</Text>
            <Text style={styles.description}>{description}</Text>
        </View>
    );
};

export default InfoContainer;
