import * as React from 'react';
import { Linking } from 'react-native';

import { openUrlOrShowError } from '@helpers/linking';

import Link from '../../../../components/Link';
import { Text, View } from '../../../../components/Themed';
import { useConfig } from '../../../../contexts/ConfigContext';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import style from '../../styles';

const Agreement: React.FunctionComponent = () => {
    const { config } = useConfig();

    const onPressPrivacyPolicy = () => {
        if (config?.privacyPolicyUrl) {
            openUrlOrShowError(config.privacyPolicyUrl);
        }
    };

    const onPressTermsOfUse = () => {
        if (config?.termsOfUseUrl) {
            openUrlOrShowError(config.termsOfUseUrl);
        }
    };

    const styles = useThemedStyles(style);
    return (
        <View style={styles.privacyWrapper}>
            <Text style={styles.privacyText}>
                {i18n.t('login.creatingAccount')}{' '}
                <Link onPress={onPressPrivacyPolicy}>
                    {i18n.t('login.privacyPolicy')}
                </Link>{' '}
                {i18n.t('login.and')}{' '}
                <Link onPress={onPressTermsOfUse}>
                    {i18n.t('login.termsOfUse')}
                </Link>
            </Text>
        </View>
    );
};

export default Agreement;
