import * as React from 'react';

import HowLuckyStrikeWorkScreen from '../../screens/TabBar/Home/LuckyStrikeFlow/HowLuckyStrikeWork/HowLuckyStrikeWorkScreen';
import LuckyStrikeAllParticipantsScreen from '../../screens/TabBar/Home/LuckyStrikeFlow/LuckyStrikeAllParticipants/LuckyStrikeAllParticipantsScreen';
import LuckyStrikeAllWinnersScreen from '../../screens/TabBar/Home/LuckyStrikeFlow/LuckyStrikeAllWinners/LuckyStrikeAllWinnersScreen';
import LuckyStrikeGameHistoryScreen from '../../screens/TabBar/Home/LuckyStrikeFlow/LuckyStrikeGameHistory/LuckyStrikeGameHistoryScreen';
import LuckyStrikeUserTicketsScreen from '../../screens/TabBar/Home/LuckyStrikeFlow/LuckyStrikeUserTickets/LuckyStrikeUserTicketsScreen';
import LuckyStrikeUserTicketsWaitScreen from '../../screens/TabBar/Home/LuckyStrikeFlow/LuckyStrikeUserTicketsWait/LuckyStrikeUserTicketsWaitScreen';
import LuckyStrikeWaitingForResultScreen from '../../screens/TabBar/Home/LuckyStrikeFlow/LuckyStrikeWaitingForResult/LuckyStrikeWaitingForResultScreen';
import WelcomeLuckyStrikeScreen from '../../screens/TabBar/Home/LuckyStrikeFlow/WelcomeLuckyStrike/WelcomeLuckyStrikeScreen';
import ROUTES from '../routes';
import { LuckyStrikeTabsNavigator } from './LuckyStrikeTabsNavigator';

const LuckyStrikeGroup = (RootStack: any) => {
    return (
        <>
            <RootStack.Screen
                name={ROUTES.WELCOME_LUCKY_STRIKE}
                component={WelcomeLuckyStrikeScreen}
            />
            <RootStack.Screen
                name={ROUTES.LUCKY_STRIKE}
                component={LuckyStrikeTabsNavigator}
            />
            <RootStack.Screen
                name={ROUTES.HOW_LUCKY_STRIKE_WORK}
                component={HowLuckyStrikeWorkScreen}
                options={{
                    presentation: 'modal',
                    headerShown: false,
                }}
            />
            <RootStack.Screen
                name={ROUTES.LUCKY_STRIKE_ALL_PARTICIPANTS}
                component={LuckyStrikeAllParticipantsScreen}
                options={{
                    presentation: 'modal',
                    headerShown: false,
                }}
            />
            <RootStack.Screen
                name={ROUTES.LUCKY_STRIKE_WAITING_FOR_RESULT}
                component={LuckyStrikeWaitingForResultScreen}
            />
            <RootStack.Screen
                name={ROUTES.LUCKY_STRIKE_USER_TICKETS}
                component={LuckyStrikeUserTicketsScreen}
                options={{
                    presentation: 'modal',
                    headerShown: false,
                }}
            />
            <RootStack.Screen
                name={ROUTES.LUCKY_STRIKE_USER_TICKETS_WAIT}
                component={LuckyStrikeUserTicketsWaitScreen}
                options={{
                    presentation: 'modal',
                    headerShown: false,
                }}
            />
            <RootStack.Screen
                name={ROUTES.LUCKY_STRIKE_ALL_WINNERS}
                component={LuckyStrikeAllWinnersScreen}
                options={{
                    presentation: 'modal',
                    headerShown: false,
                }}
            />
            <RootStack.Screen
                name={ROUTES.LUCKY_STRIKE_GAME_HISTORY}
                component={LuckyStrikeGameHistoryScreen}
                options={{ gestureEnabled: false }}
            />
        </>
    );
};

export default LuckyStrikeGroup;
