import { StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import { scale, verticalScale } from '../../../../helpers/dimensions';
import font from '../../../../helpers/font';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        openCamera: {
            marginBottom: verticalScale(6),
        },
        or: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
        pickFromGallery: {
            marginTop: verticalScale(8),
            paddingVertical: verticalScale(0),
            paddingHorizontal: scale(0),
        },
        icon: {
            color: Colors[theme].icon.onDark,
        },
    });

export default styles;
