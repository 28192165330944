import * as React from 'react';
import { useCallback } from 'react';

import Button from '@components/Button';
import { ICON_NAMES } from '@components/Icons';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';

import HowToPurchaseIGUModal from '../components/HowToPurchaseIGUModal';

export const useHowToPurchaseIGU = () => {
    const { isVisible, open, close } = useVisibleHook();

    const renderHowToPurchaseIGU = useCallback(() => {
        return (
            <>
                <Button
                    onPress={open}
                    title={i18n.t('wallet.howToPurchaseIGU')}
                    type="text"
                    iconName={ICON_NAMES.QUESTION}
                    iconDirection="right"
                />
                <HowToPurchaseIGUModal isVisible={isVisible} close={close} />
            </>
        );
    }, [isVisible]);

    return { renderHowToPurchaseIGU };
};
