import * as React from 'react';
import { Linking, StyleProp, View, ViewStyle } from 'react-native';

import * as Clipboard from 'expo-clipboard';

import { SocialTaskOutput } from '@Data/Models';
import Button from '@components/Button';
import Icon, { ICON_NAMES } from '@components/Icons';
import SocialIcons from '@components/Icons/SocialIcons';
import { Text } from '@components/Themed';
import TouchableOpacity from '@components/TouchableOpacity/index.web';
import { SOCIAL_BASE_LINKS } from '@constants/Socials';
import { openUrlOrShowError } from '@helpers/linking';
import { toastSuccess } from '@helpers/toastNotification';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    socialTask: SocialTaskOutput;
    containerStyle?: StyleProp<ViewStyle>;
    withLink?: boolean;
}
const SocialNetworkCard = ({
    socialTask,
    containerStyle,
    withLink = false,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { platform, platformUsername } = socialTask;

    const handlePressExplore = async (explorerUrl: string) => {
        const canOpen = await Linking.canOpenURL(explorerUrl);
        if (!canOpen) {
            await Clipboard.setStringAsync(platformUsername);
            toastSuccess(
                i18n.t('socializeToEarn.shareYourPetPhoto.copyToClipboard')
            );
            return;
        }
        openUrlOrShowError(explorerUrl);
    };

    const renderItem = () => {
        const upperCasePlatform = platform.toUpperCase();
        const link = `${
            SOCIAL_BASE_LINKS[upperCasePlatform as keyof object]
        }${platformUsername}`;

        const icon = <SocialIcons slug={platform.toLowerCase()} size={32} />;

        return (
            <>
                <View style={styles.titleWrapper}>
                    {icon}
                    <TouchableOpacity
                        style={styles.titleContainer}
                        onPress={() => handlePressExplore(link)}>
                        <Text
                            style={styles.title}>{`@${platformUsername}`}</Text>
                    </TouchableOpacity>
                </View>
                {withLink && (
                    <Button
                        onPress={() => handlePressExplore(link)}
                        size="sm"
                        iconName={ICON_NAMES.EXPLORE}
                        iconColor="onDark"
                    />
                )}
            </>
        );
    };

    return (
        <View style={[styles.container, containerStyle]}>{renderItem()}</View>
    );
};
export default SocialNetworkCard;
