import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { moderateScale, scale, verticalScale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        modal: {
            flex: 1,
            margin: 0,
        },
        modalView: { flex: 1 },
        centeredView: {
            alignItems: 'center',
            marginTop: 'auto',
        },
        container: {
            backgroundColor: Colors[theme].modal.background,
            flex: 1,
            borderTopRightRadius: moderateScale(20),
            borderTopLeftRadius: moderateScale(20),
        },
        lineWrapper: {
            position: 'relative',
            height: verticalScale(50),
            bottom: -verticalScale(10),
            zIndex: 1,
        },
        line: {
            height: 4,
            width: 56,
            backgroundColor: Colors[theme].modalLine,
            borderRadius: 1000,
            position: 'absolute',
            bottom: verticalScale(18),
        },
        contentContainer: {
            height: '100%',
            paddingVertical: verticalScale(16),
            paddingHorizontal: verticalScale(20),
        },
        titleContainer: {
            selfAlign: 'center',
            alignItems: 'center',
            textAlign: 'center',
            marginBottom: verticalScale(16),
        },
        titleText: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                weight: '700',
            }),
        },
        closeButtonWrapper: {
            marginVertical: scale(20),
            marginHorizontal: scale(20),
        },
        closeButton: {
            position: 'absolute',
            right: 0,
            top: verticalScale(-5),
        },
        titleContainerBig: {
            marginVertical: verticalScale(12),
        },
        titleTextBig: {
            color: Colors[theme].text.primary,
            ...font({
                size: 22,
                height: 24,
                weight: '700',
            }),
            marginBottom: verticalScale(6),
        },
        additionalTextContainer: {
            marginBottom: verticalScale(20),
        },
        additionalText: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
    });

export default styles;
