/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    FC,
    PropsWithChildren,
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import _ from 'lodash';

import {
    GAME_ITERATION_STATUS,
    GameIterationOutput,
    MoveTaskItem,
    MoveTaskResponseItem,
    Pet,
    PetsResponse,
    PlayTaskItem,
    PlayTaskResponseItem,
    SingleEnergyEstimatedGameRewardResponse,
    SocialTaskItem,
    SocialTaskResponseItem,
    VerificationTaskItem,
    VerificationTaskResponseItem,
} from '@Data/Models';
import { GET_PETS } from '@Data/Requests';
import { useApolloClient } from '@apollo/client';
import {
    ISocialToEarnCard,
    defaultSocialToEarnCardTemplates,
} from '@constants/shareCardTemplatesNew';
import { isWeb } from '@helpers/app';
import { useGameIterationStatus } from '@hooks/useGameIterationStatus';

import usePetsEstimatedGameRewardData from '../hooks/usePetsEstimatedGameRewardData';
import { usePersistStorage } from '../vendor/react-native-user-persist-storage';
import { useRanks } from './RanksContext';
import { useUser } from './UserContext';

export const playContextConfig = {
    iterationTime: '10-15',
};

export type PlayContextType = {
    pets: Pet[] | undefined;
    playablePets: Pet[] | null;
    setPlayablePets: (pets: Pet[] | null) => void;
    oldPlayablePets: Pet[] | null;
    setOldPlayablePets: (pets: Pet[] | null) => void;

    socialTask: SocialTaskItem | null;
    setSocialTask: (task: SocialTaskItem | null) => void;
    updateSocialTask: (task: SocialTaskResponseItem) => void;
    verifyTask: VerificationTaskItem | null;
    setVerifyTask: (task: VerificationTaskItem | null) => void;
    updateVerifyTask: (
        currentTask: VerificationTaskResponseItem | null
    ) => void;
    getCurrentVerifySubtask: (
        task: VerificationTaskItem
    ) => VerificationTaskResponseItem | null;
    setMoveTask: (task: MoveTaskItem | null) => void;
    updateMoveTask: (task: MoveTaskResponseItem) => void;
    moveTask: MoveTaskItem | null;
    setPlayTask: (task: PlayTaskItem | null) => void;
    updatePlayTask: (task: PlayTaskResponseItem) => void;
    playTask: PlayTaskItem | null;
    isPlayGameStop: boolean;
    setIsPlayGameStop: (isStop: boolean) => void;
    petsLoading: boolean;
    petsLoaded: boolean;
    refetchPets: (silent: boolean) => Promise<PetsResponse>;
    isPetPlaying: (pet: Pet) => boolean;
    isAllDead: boolean;
    isSomeDead: boolean;
    setIsAllDead: (status: boolean) => void;
    setIsSomeDead: (status: boolean) => void;
    refetchEnergyReward: () => Promise<
        SingleEnergyEstimatedGameRewardResponse | undefined
    >;
    petsEstimatedGameRewardData: SingleEnergyEstimatedGameRewardResponse | null;
    gameIterationStatus: GameIterationOutput;
    isGameInIteration: boolean;
    templateColorSaved: ISocialToEarnCard;
    setTemplateColorSaved: (bg: ISocialToEarnCard) => void;
    isFreePets: boolean;
};

export const PlayContext = createContext<PlayContextType>({
    pets: undefined,
    playablePets: null,
    setPlayablePets: () => [],
    oldPlayablePets: null,
    setOldPlayablePets: () => [],
    petsLoading: false,
    petsLoaded: false,
    refetchPets: () => Promise.resolve({ pets: [] }),
    isPetPlaying: () => false,
    socialTask: null,
    setSocialTask: () => undefined,
    updateSocialTask: () => undefined,
    verifyTask: null,
    setVerifyTask: () => undefined,
    updateVerifyTask: () => undefined,
    getCurrentVerifySubtask: () => null,
    moveTask: null,
    setMoveTask: () => undefined,
    updateMoveTask: () => undefined,
    playTask: null,
    setPlayTask: () => undefined,
    updatePlayTask: () => undefined,
    isPlayGameStop: false,
    setIsPlayGameStop: () => undefined,
    isAllDead: false,
    isSomeDead: false,
    setIsAllDead: () => undefined,
    setIsSomeDead: () => undefined,
    refetchEnergyReward: () => Promise.resolve(undefined),
    petsEstimatedGameRewardData: null,
    gameIterationStatus: GAME_ITERATION_STATUS.COMPLETED,
    isGameInIteration: false,
    templateColorSaved: defaultSocialToEarnCardTemplates[0],
    setTemplateColorSaved: () => undefined,
    isFreePets: false,
});

export const usePlay = () => useContext(PlayContext);

const PlayProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const { user } = useUser();
    const { refetch: refetchRanks } = useRanks();
    const [socialTask, setSocialTask] =
        usePersistStorage<SocialTaskItem | null>(
            'PlayProvider.socialTask',
            null,
            {
                persist: isWeb,
            }
        );
    const [moveTask, setMoveTask] = usePersistStorage<MoveTaskItem | null>(
        'PlayProvider.moveTask',
        null,
        {
            persist: isWeb,
        }
    );
    const [verifyTask, setVerifyTask] =
        usePersistStorage<VerificationTaskItem | null>(
            'PlayProvider.verifyTask',
            null,
            {
                persist: isWeb,
            }
        );
    const [playTask, setPlayTask] = usePersistStorage<PlayTaskItem | null>(
        'PlayProvider.playTask',
        null,
        {
            persist: isWeb,
        }
    );
    const [templateColorSaved, setTemplateColorSaved] =
        usePersistStorage<ISocialToEarnCard>(
            'PlayProvider.templateColorSaved1',
            defaultSocialToEarnCardTemplates[0],
            {
                persist: isWeb,
            }
        );

    const [playablePets, setPlayablePets] = useState<Pet[] | null>(null);
    const [oldPlayablePets, setOldPlayablePets] = useState<Pet[] | null>(null);
    const [isPlayGameStop, setIsPlayGameStop] = useState<boolean>(false);
    const {
        fetchPets,
        petsResponse,
        setPetsResponse,
        petsLoading,
        setPetsLoading,
        petsLoaded,
        setPetsLoaded,
    } = useGetPets();

    const [isAllDead, setIsAllDead] = React.useState(false);
    const [isSomeDead, setIsSomeDead] = React.useState(false);

    const pets = useMemo(() => petsResponse?.pets, [petsResponse]);

    useEffect(() => {
        if (user?.petOrderedIds && pets) {
            setPlayablePets(
                _.compact(
                    user.petOrderedIds.map((item) =>
                        pets.find((pet) => pet.id === item)
                    )
                )
            );
        }
    }, [user?.petOrderedIds, pets]);

    useEffect(() => {
        if (!user) {
            reset();
        } else {
            refetchPets(true);
            refetchRanks();
        }
    }, [user]);

    const refetchPets = async (silent: boolean) => {
        return fetchPets(silent);
    };

    const isPetPlaying = (pet: Pet) =>
        !!user?.petOrderedIds.find((val) => pet.id === val);

    const updateSocialTask = React.useCallback(
        (task: SocialTaskResponseItem) => {
            setSocialTask({ ...socialTask, latestTask: task });
        },
        [socialTask]
    );

    const updateVerifyTask = React.useCallback(
        (currentTask: VerificationTaskResponseItem | null) => {
            if (verifyTask) {
                setVerifyTask({
                    ...verifyTask,
                    currentTask,
                });
            }
        },
        [verifyTask]
    );

    const getCurrentVerifySubtask = (task: VerificationTaskItem) => {
        if (!task?.tasks) {
            return null;
        }

        const currentTask = _.sortBy(task?.tasks, (val) => val.createdAt);
        return currentTask[currentTask.length - 1];
    };

    const updateMoveTask = React.useCallback(
        (task: MoveTaskResponseItem) => {
            setMoveTask({ ...moveTask, currentTask: task });
        },
        [moveTask]
    );

    const updatePlayTask = React.useCallback(
        (task: PlayTaskResponseItem) => {
            setPlayTask({ ...playTask, currentTask: task });
        },
        [playTask]
    );

    const reset = () => {
        setPetsResponse(null);
        setPetsLoading(false);
        setPetsLoaded(false);
        setPlayablePets(null);
        setSocialTask(null);
        setVerifyTask(null);
        setMoveTask(null);
        setPlayTask(null);
        setIsAllDead(false);
        setIsSomeDead(false);
        setTemplateColorSaved(defaultSocialToEarnCardTemplates[0]);
    };

    const { petsEstimatedGameRewardData, fetchEnergyReward } =
        usePetsEstimatedGameRewardData();

    const refetchEnergyReward = async () => {
        return fetchEnergyReward();
    };

    const { gameIterationStatus, getGameIterationStatus } =
        useGameIterationStatus();

    const [isGameInIteration, setIsGameInIteration] =
        React.useState<boolean>(false);

    React.useEffect(() => {
        if (user?.id) {
            getGameIterationStatus();
        }
    }, [user?.id]);

    useEffect(() => {
        setIsGameInIteration(
            gameIterationStatus !== GAME_ITERATION_STATUS.COMPLETED
        );
    }, [gameIterationStatus]);

    const isFreePets = useMemo(() => {
        if (!pets?.length) {
            return false;
        }
        return !pets.find((i) => i.rank !== 'free');
    }, [pets]);

    return (
        <PlayContext.Provider
            value={{
                pets,
                playablePets,
                setPlayablePets,
                petsLoading,
                petsLoaded,
                refetchPets,
                isPetPlaying,
                setSocialTask,
                socialTask,
                updateSocialTask,
                verifyTask,
                getCurrentVerifySubtask,
                setVerifyTask,
                updateVerifyTask,
                moveTask,
                setMoveTask,
                updateMoveTask,
                playTask,
                setPlayTask,
                updatePlayTask,
                isPlayGameStop,
                setIsPlayGameStop,
                isAllDead,
                isSomeDead,
                setIsAllDead,
                setIsSomeDead,
                oldPlayablePets,
                setOldPlayablePets,
                refetchEnergyReward,
                petsEstimatedGameRewardData,
                gameIterationStatus,
                isGameInIteration,
                templateColorSaved,
                setTemplateColorSaved,
                isFreePets,
            }}>
            {children}
        </PlayContext.Provider>
    );
};

export default PlayProvider;

const useGetPets = () => {
    const client = useApolloClient();
    const [petsLoaded, setPetsLoaded] = useState<boolean>(false);
    const [petsLoading, setPetsLoading] = useState<boolean>(false);
    const [petsResponse, setPetsResponse] = useState<PetsResponse | null>(null);

    async function fetchPets(silent: boolean) {
        if (!silent) setPetsLoading(true);

        const response = await client.query<PetsResponse>({
            query: GET_PETS,
            fetchPolicy: 'network-only',
        });
        setPetsResponse(response.data);
        setPetsLoaded(true);
        if (!silent) setPetsLoading(false);

        return response.data;
    }

    return {
        petsResponse,
        setPetsResponse,
        petsLoading,
        setPetsLoading,
        petsLoaded,
        setPetsLoaded,
        fetchPets,
    };
};
