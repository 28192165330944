/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { SafeAreaView, StyleProp, ViewProps } from 'react-native';

import { PaymentOptionOutput } from '@Data/Models';
import { useStorePurchase } from '@contexts/StorePurchaseContext';
import { ITransactionResult, useWallet } from '@contexts/Wallet/WalletContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { isGraphqlError } from '@helpers/helpers';
import { getPrice, isPaymentOptionInAppPurchase } from '@helpers/payments';
import { toastError } from '@helpers/toastNotification';
import { balanceFromWei, feeWithCurrency } from '@helpers/wallet';
import useBlockchainErrorsHandler from '@hooks/useBlockchainErrorsHandler';
import useFeeInfo from '@hooks/useFeeInfo';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';

import ButtonsBottom from '../ButtonsBottom';
import Divider from '../Divider';
import ModalBottom from '../ModalBottom';
import ModalConfirmTransaction from '../ModalConfirmTransaction';
import ModalDeposit from '../ModalDeposit';
import ModalLoader from '../ModalLoader';
import PullToRefresh from '../PullToRefresh';
import { Text, View } from '../Themed';
import TransactionSummary from '../TransactionSummary';
import CheckoutSummary from './CheckoutSummary';
import PurchasingFromCoins from './PurchasingFromCoins';
import PurchasingFromInput from './PurchasingFromInput';
import stylesMain from './styles';

interface IProps {
    title?: string;
    text?: string;
    payments: PaymentOptionOutput[];
    defaultPayment?: PaymentOptionOutput;
    isFree?: boolean;
    transactionsCount?: number;
    TransactionDetailsComponent?: ReactNode;
    withConfirmation?: string;
    containerStyle?: StyleProp<ViewProps>;
    transactionDetailsStyle?: StyleProp<ViewProps>;
    onPurchase: (
        payment: PaymentOptionOutput
    ) => Promise<ITransactionResult | null>;
    onPurchaseError?: (error: any) => void;
}

const CheckoutScreen = ({
    title = i18n.t('checkout.title'),
    text = i18n.t('checkout.text'),
    payments,
    defaultPayment,
    isFree,
    transactionsCount,
    TransactionDetailsComponent,
    withConfirmation,
    containerStyle,
    transactionDetailsStyle,
    onPurchase,
    onPurchaseError,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const {
        walletBalance,
        walletData,
        reloadBalance,
        fee,
        getCoinBalances,
        balanceReloading,
        userBalance,
        getUserBalance,
    } = useWallet();

    const { isStoreLoading } = useStorePurchase();

    // show deposit button if not enough funds
    const [notEnoughFundsError, setNotEnoughFundsError] =
        React.useState<boolean>(false);
    // show deposit modal
    const [isVisibleModalDeposit, setIsVisibleModalDeposit] =
        React.useState<boolean>(false);
    // show coin select modal
    const [isVisibleModalCoin, setIsVisibleModalCoin] =
        React.useState<boolean>(false);
    // show confirmation modal

    // current payment
    const [payment, setPayment] = useState<PaymentOptionOutput>(
        defaultPayment ?? payments[0]
    );

    // loading and refreshing
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    // disable button
    const [disableButton, setDisableButton] = React.useState(false);
    // whether payment is in-app-purchase
    const isInAppPurchase = isPaymentOptionInAppPurchase(payment);

    const {
        isVisible: isVisibleConfirmationModal,
        close: closeConfirmationModal,
        open: openConfirmationModal,
    } = useVisibleHook();

    // fee info modalScreen
    const { renderFeeInfoIcon } = useFeeInfo(
        closeConfirmationModal,
        openConfirmationModal
    );

    // use blockchain errors
    const { handleBlockchainErrors, renderBlockchainErrorsModal } =
        useBlockchainErrorsHandler(
            () => {
                setDisableButton(true);
            },
            () => {
                setDisableButton(false);
            }
        );

    // PullToRefresh logic, refresh wallet
    const onRefresh = React.useCallback(() => {
        reloadBalance(false);
        getUserBalance(false);
    }, []);

    // get coin by balance
    const estimatedTransactionFee = React.useMemo(() => {
        let transactionFee: string | undefined;
        switch (payment?.token) {
            case 'BNB':
                transactionFee = fee?.executeTransaction.BNB;
                break;
            default:
                transactionFee = fee?.executeTransaction.IGU;
        }
        if (transactionsCount) {
            return (Number(transactionFee) * transactionsCount).toString();
        }
        return transactionFee;
    }, [payment, fee, transactionsCount]);

    const isBNBEnough = React.useMemo(() => {
        if (payment?.token === 'BNB') {
            const valueWithFee =
                Number(balanceFromWei(payment.amountWei).value) +
                Number(estimatedTransactionFee);

            return Number(walletBalance?.bnb.value) >= valueWithFee;
        }

        return (
            Number(walletBalance?.bnb.value) >= Number(estimatedTransactionFee)
        );
    }, [estimatedTransactionFee, walletBalance, payment]);

    const purchaseCoin = React.useMemo(
        () => getCoinBalances().find((val) => val?.name === payment?.token),
        [payment, getCoinBalances]
    );

    // if button is disabled
    const isButtonDisabled = useMemo(() => {
        // if button explicitly disabled then we return true
        if (disableButton) {
            return true;
        }

        // for free, we always return enabled button
        if (isFree) {
            return false;
        }

        // check for error
        return (
            notEnoughFundsError ||
            (!payment?.tokenAddress && !userBalance) ||
            (!!payment?.tokenAddress && !walletBalance)
        );
    }, [
        disableButton,
        isFree,
        notEnoughFundsError,
        payment,
        userBalance,
        walletBalance,
    ]);

    // get initial balance
    useEffect(() => {
        getUserBalance(true);
    }, []);

    // set default coin
    useEffect(() => {
        const payment = defaultPayment || payments[0];
        setPayment(payment);
        if (!payment?.tokenAddress) {
            getUserBalance(true);
        }
    }, [defaultPayment, payments, isFree]);

    // show deposit button if not enough balance
    useEffect(() => {
        if (!payment) {
            return;
        }

        const paymentAmount = Number(balanceFromWei(payment.amountWei).value);

        if (payment.tokenAddress) {
            const coinBalanceAmount = Number(purchaseCoin?.value);
            setNotEnoughFundsError(
                coinBalanceAmount < paymentAmount || !isBNBEnough
            );
        } else {
            const userBalanceAmount = Number(
                balanceFromWei(userBalance?.userBalance?.total || '').valueLong
            );
            setNotEnoughFundsError(
                !!userBalance && userBalanceAmount < paymentAmount
            );
        }
    }, [payment, userBalance, purchaseCoin, isBNBEnough]);

    const handleDepositModalOpen = () => {
        setIsVisibleModalDeposit(true);
    };

    const handleCoinModalOpen = () => {
        // show modal with coins only if more than 1 option
        if (payments.length > 1) {
            setIsVisibleModalCoin(true);
        }
    };

    const handleCoinModalClose = () => {
        setIsVisibleModalCoin(false);
    };

    const handleOpenConfirmationModal = () => {
        setDisableButton(true);
        openConfirmationModal();
    };

    const handleCloseConfirmationModal = () => {
        setDisableButton(false);
    };

    const handleCoinPress = async (token: string) => {
        const currentPayment = payments.find((value) => value.token === token);

        if (currentPayment) {
            setPayment(currentPayment);
            handleCoinModalClose();
        }
    };

    const onPressPurchase = async () => {
        if (payment.token === 'STORE') {
            setDisableButton(false);
            await onPurchase(payment);
            return;
        }
        setDisableButton(true);
        setIsLoading(true);

        try {
            await onPurchase(payment);
            setIsLoading(false);
            setDisableButton(false);
        } catch (error: any) {
            Console.log(error);
            setDisableButton(false);
            setIsLoading(false);

            const blockChainErrors = handleBlockchainErrors(error);
            if (isGraphqlError(error, 'VIRTUAL_WALLET_INSUFFICIENT_FUNDS')) {
                toastError(
                    undefined,
                    i18n.t('checkout.errors.insufficientVirtualBalance')
                );
            } else if (!blockChainErrors) {
                if (onPurchaseError) onPurchaseError(error);
            }
        }
    };

    const renderItem = React.useCallback(() => {
        return (
            <>
                <View>
                    <View style={styles.textWrapper}>
                        <Text style={styles.title}>{title}</Text>
                        <Text style={styles.text}>{text}</Text>
                    </View>
                </View>
                {payment && !isFree && !isInAppPurchase && (
                    <PurchasingFromInput
                        payment={payment}
                        userBalance={userBalance?.userBalance}
                        onDepositPress={handleDepositModalOpen}
                        isError={notEnoughFundsError && !isLoading}
                        isBNBEnough={isBNBEnough}
                        onPress={handleCoinModalOpen}
                        withDropDown={payments.length > 1}
                    />
                )}
                <View
                    style={[
                        styles.purchaseItemWrapper,
                        transactionDetailsStyle,
                    ]}>
                    {TransactionDetailsComponent}
                </View>
            </>
        );
    }, [
        title,
        text,
        isFree,
        payment,
        payments,
        userBalance,
        notEnoughFundsError,
        isLoading,
        TransactionDetailsComponent,
        isBNBEnough,
        isInAppPurchase,
    ]);

    const renderDepositModal = () => {
        return (
            <>
                {!!purchaseCoin && walletData && (
                    <ModalDeposit
                        isVisible={isVisibleModalDeposit}
                        setIsVisible={setIsVisibleModalDeposit}
                        coin={isBNBEnough ? purchaseCoin.name : Coin.bnb}
                    />
                )}
            </>
        );
    };

    const renderChooseCoinModal = () => {
        return (
            <>
                {walletBalance && payments && (
                    <ModalBottom
                        isVisible={isVisibleModalCoin}
                        titleText={i18n.t('checkout.purchasingFrom')}
                        onClose={handleCoinModalClose}
                        modalHeight={500}>
                        <PurchasingFromCoins
                            selectedPayment={payment}
                            onCoinClick={handleCoinPress}
                            payments={payments}
                            userBalance={userBalance?.userBalance}
                        />
                    </ModalBottom>
                )}
            </>
        );
    };

    const renderConfirmModal = () => {
        if (!payment && !isFree) {
            return null;
        }

        const transactionDetailsValues = [
            {
                key: i18n.t('checkout.fields.transaction'),
                value: withConfirmation,
            },
            !isFree && !isInAppPurchase
                ? {
                      key: i18n.t('checkout.fields.from'),
                      value: i18n.t('checkout.wallet', {
                          coin: payment.token,
                      }),
                  }
                : undefined,
            !isFree && payment.tokenAddress
                ? {
                      key: i18n.t('checkout.fields.fee'),
                      value: feeWithCurrency(estimatedTransactionFee),
                      icon: renderFeeInfoIcon(),
                  }
                : undefined,
            {
                key: i18n.t('checkout.fields.totalSummary'),
                value: !isFree ? getPrice(payment) : i18n.t('checkout.free'),
            },
        ];

        return (
            <ModalConfirmTransaction
                isVisible={isVisibleConfirmationModal}
                close={closeConfirmationModal}
                onConfirm={onPressPurchase}
                onCancel={handleCloseConfirmationModal}
                TransactionDetailsComponent={
                    <TransactionSummary values={transactionDetailsValues} />
                }
                modalHeight={transactionDetailsValues.length * 120}
            />
        );
    };

    const renderLoadingModal = () => {
        return (
            <ModalLoader
                isVisible={isLoading}
                text={i18n.t('checkout.loadingPurchasing')}
                timeout={0}
            />
        );
    };

    return (
        <View style={[styles.container, containerStyle]}>
            <PullToRefresh
                textLoading={i18n.t('pullToRefresh.reloadBalance.text')}
                refreshing={balanceReloading}
                onRefresh={onRefresh}
                renderItem={renderItem}
            />
            <Divider />
            <View style={styles.totalSummaryWrapper}>
                <CheckoutSummary
                    payment={payment}
                    isFree={isFree}
                    estimatedTransactionFee={estimatedTransactionFee}
                />
            </View>
            <ButtonsBottom
                onPress={
                    withConfirmation
                        ? handleOpenConfirmationModal
                        : onPressPurchase
                }
                title={i18n.t('checkout.confirm')}
                disabled={isButtonDisabled || isStoreLoading}
            />
            {renderChooseCoinModal()}
            {renderDepositModal()}
            {renderConfirmModal()}
            {renderLoadingModal()}
            {renderBlockchainErrorsModal()}
            <SafeAreaView style={styles.safe} />
        </View>
    );
};
export default CheckoutScreen;
