import React, { useEffect } from 'react';
import { SafeAreaView, ScrollView, View } from 'react-native';

import { GetRankupPaymentsResponse, Pet } from '@Data/Models';
import { GET_RANK_UP_PAYMENTS } from '@Data/Requests';
import { useApolloClient } from '@apollo/client';
import { usePlay } from '@contexts/PlayContext';
import { useRanks } from '@contexts/RanksContext';
import { isGraphqlError } from '@helpers/helpers';
import {
    formatBonusReward,
    formatDailyReward,
    getDailyFoodConsumption,
    ranksConfig,
} from '@helpers/ranks';
import { toastError, toastErrorUnknown } from '@helpers/toastNotification';
import { useRanksUpgradeInfo } from '@hooks/useRanksUpgradeInfo';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { StackActions } from '@react-navigation/native';

import Button from '../../Button';
import ModalBottom from '../../ModalBottom';
import RankDetailedInfo from '../../Ranks/RankDetailedInfo';
import RankUpHeader from '../RankUpHeader';
import mainStyles from './styles';

interface IProps {
    isVisible: boolean;
    closeAction: () => void;
    pet: Pet;
    fromScreenModal?: boolean;
    modalHeight?: number | string;
    navigateBackOnUpgrade?: ROUTES;
    fetch?: boolean;
}

const ModalNFTRankUp = ({
    isVisible,
    pet,
    modalHeight = '95%',
    fromScreenModal = false,
    closeAction,
    navigateBackOnUpgrade,
    fetch,
}: IProps) => {
    const styles = useThemedStyles(mainStyles);
    const client = useApolloClient();
    const navigation = useNavigation();

    const { getRankUpgradeInfo, rankUpgradeInfo } = useRanksUpgradeInfo();

    // Loading by prop isVisible info on screen where render a lot of modals of this type,
    useEffect(() => {
        if (isVisible && !fetch && pet.rankUpAvailable) {
            getRankUpgradeInfo({
                variables: {
                    petId: pet.id,
                },
            });
            return;
        }
    }, [isVisible, pet, fetch]);

    // Loading by prop fetch for screen CollectionDetailedPetScreen
    useEffect(() => {
        if (fetch && pet.rankUpAvailable) {
            getRankUpgradeInfo({
                variables: {
                    petId: pet.id,
                },
            });
            return;
        }
    }, [pet, fetch]);

    const { refetchPets } = usePlay();
    const { nextRankBySlug, rankBySlug } = useRanks();
    const previousRank = rankBySlug(pet.rank);
    const nextRank = nextRankBySlug(pet.rank);

    const fetchPayments = async () => {
        const result = await client.query<GetRankupPaymentsResponse>({
            query: GET_RANK_UP_PAYMENTS,
            variables: {
                petId: pet.id,
            },
        });
        return result.data.rankupPayments;
    };

    const handleOpenPetRankUpCheckoutScreen = async () => {
        if (fromScreenModal) {
            navigation.dispatch(StackActions.pop());
        }
        closeAction();

        try {
            const payments = await fetchPayments();

            navigation.navigate(ROUTES.COLLECTION_PET_RANK_UP_CHECKOUT, {
                pet,
                payments,
                navigateBackOnUpgrade,
            });
        } catch (error: any) {
            if (isGraphqlError(error, 'PET_WRONG_STATE')) {
                toastError(i18n.t('petRankUp.errors.PET_WRONG_STATE'));
                refetchPets(true);
            } else {
                toastErrorUnknown();
            }
        }
    };

    return (
        <ModalBottom
            modalHeight={modalHeight}
            isVisible={isVisible}
            onClose={closeAction}>
            {!!nextRank && !!previousRank ? (
                <View style={styles.container}>
                    <ScrollView
                        style={styles.content}
                        showsVerticalScrollIndicator={false}
                        bounces={false}>
                        <RankUpHeader
                            previousRankSlug={previousRank.slug}
                            nextRankSlug={nextRank.slug}
                            maxPetLevel={nextRank.maxPetLevel}
                        />

                        <RankDetailedInfo
                            featureLoading={!rankUpgradeInfo}
                            maxAge={rankUpgradeInfo?.maxAgeDays}
                            rankSlug={nextRank.slug}
                            title={i18n.t('ranks.rankLongTitle', {
                                rank: i18n.t(
                                    `ranks.list.${nextRank.slug}.title`
                                ),
                            })}
                            text={i18n.t(`ranks.upgradeText`, {
                                rank: i18n.t(
                                    `ranks.list.${previousRank.slug}.title`
                                ),
                            })}
                            reward={formatDailyReward(
                                rankUpgradeInfo?.baseIgupRewardWei
                            )}
                            rate={rankUpgradeInfo?.dailyUnlockRate}
                            consumption={getDailyFoodConsumption(
                                rankUpgradeInfo?.rewardConsumptionPercentage
                            )}
                            unlockReward={formatBonusReward(
                                rankUpgradeInfo?.dailyUnlockRate || 1,
                                rankUpgradeInfo?.baseIgupRewardWei
                            )}
                        />
                    </ScrollView>
                    <View style={styles.buttonWrapper}>
                        <Button
                            onPress={() => handleOpenPetRankUpCheckoutScreen()}
                            title={i18n.t('ranks.upgradeRankButton')}
                        />
                    </View>
                    <SafeAreaView />
                </View>
            ) : null}
        </ModalBottom>
    );
};
export default ModalNFTRankUp;
