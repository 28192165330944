import * as React from 'react';
import { Image, View } from 'react-native';
import QRCode from 'react-native-qrcode-svg';

import Avatar from '../../assets/icons/avatars/transparent.svg';
import { Text } from '../../components/Themed';
import { isWeb } from '../../helpers/app';
import useThemedStyles from '../../hooks/useThemedStyles';
import i18n from '../../i18n/i18n';
import { getDeeplinkReferralsInvite } from '../../navigation/helpers';
import Icon, { ICON_NAMES } from '../Icons';
import stylesMain from './styles';

interface IProps {
    backgroundColor: {
        color: string;
        id: string;
    };
    petImage?: string;
    petName?: string;
    collectedEnergy: number;
    leaderBoardPlace: number | string;
    code: string;
    noPet: boolean;
}

const ReferralShareCard = ({
    backgroundColor,
    petImage,
    collectedEnergy,
    leaderBoardPlace,
    petName,
    code,
    noPet,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View
            collapsable={false}
            style={[
                styles.container,
                backgroundColor && { backgroundColor: backgroundColor.color },
                noPet && styles.noPetContainer,
            ]}>
            {noPet && (
                <View style={styles.shadow}>
                    <View style={styles.avatar}>
                        <Avatar
                            width={36}
                            height={48}
                            color={backgroundColor.color}
                        />
                    </View>
                </View>
            )}
            <View style={styles.qrCodeImage}>
                <QRCode value={getDeeplinkReferralsInvite(code)} size={124} />
            </View>
            <View style={styles.headerText}>
                <Text style={styles.qrCodeTitle}>{`#${code}`}</Text>
                <Text style={styles.qrCodeText}>
                    {i18n.t('referrals.inviteUsersScreen.text')}
                </Text>
            </View>
            {!noPet && (
                <View style={styles.cardWrapper}>
                    <View style={styles.card}>
                        {isWeb ? (
                            <img
                                src={petImage}
                                style={styles.petImage}
                                alt="Referral Code"
                            />
                        ) : (
                            <Image
                                source={{
                                    uri: petImage,
                                }}
                                style={styles.petImage}
                            />
                        )}
                        <View style={styles.textWrapper}>
                            <Text style={styles.value}>{petName}</Text>
                            <Text style={styles.key}>
                                {i18n.t('referrals.inviteUsersScreen.myNFT')}
                            </Text>
                        </View>
                    </View>
                    <View style={[styles.card, styles.gap]}>
                        <Icon name={ICON_NAMES.ENERGY} size={40} />
                        <View style={styles.textWrapper}>
                            <Text style={styles.value}>{collectedEnergy}</Text>
                            <Text style={styles.key}>
                                {i18n.t(
                                    'referrals.inviteUsersScreen.collectedEnergy'
                                )}
                            </Text>
                        </View>
                    </View>
                    {leaderBoardPlace !== 'N/A' && (
                        <View style={[styles.card, styles.gap]}>
                            <Icon name={ICON_NAMES.LEVEL} size={40} />
                            <View style={styles.textWrapper}>
                                <Text style={styles.value}>
                                    {leaderBoardPlace}
                                </Text>
                                <Text style={styles.key}>
                                    {i18n.t(
                                        'referrals.inviteUsersScreen.leaderBoard'
                                    )}
                                </Text>
                            </View>
                        </View>
                    )}
                </View>
            )}
        </View>
    );
};
export default ReferralShareCard;
