import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';

import { Text, View } from '../../../../../components/Themed';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import stylesMain from './styles';

interface IProps {
    amount: number | string;
    text: string;
    icon: React.ReactElement<SvgProps>;
    containerStyle?: StyleProp<ViewStyle>;
}

const ReferralTotalCard = ({ amount, text, icon, containerStyle }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={[styles.container, containerStyle]}>
            <View style={styles.imageWrapper}>{icon}</View>
            <Text style={styles.title}>{amount}</Text>
            <Text style={styles.text}>{text}</Text>
        </View>
    );
};
export default ReferralTotalCard;
