import { StyleSheet } from 'react-native';

import { verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        features: {
            flexDirection: 'row',
            backgroundColor: 'transparent',
            justifyContent: 'space-between',
            marginTop: verticalScale(16),
            marginBottom: verticalScale(12),
        },
        feature: {
            width: '47%',
        },
    });

export default styles;
