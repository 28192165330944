import * as React from 'react';
import { useMemo } from 'react';
import { Image, TouchableOpacity } from 'react-native';

import MysteryLikesGamesHistoryIcon from '@components/Icons/MysteryLikesGamesHistoryIcon';
import OtherUserAvatar from '@components/OtherUserAvatar';
import { Text, View } from '@components/Themed';
import { scale } from '@helpers/dimensions';
import { dateFormatter } from '@helpers/time';
import { balanceFromWei, truncateEthAddress } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import {
    MYSTERY_LIKES_STATUS,
    PaginatedMysteryLikesGamesHistoryItem,
} from '@models/mysteryGames';

import GameAmount from '../GameAmount';
import stylesMain from './styles';

interface IProps {
    item: PaginatedMysteryLikesGamesHistoryItem;
    onPress: (item: PaginatedMysteryLikesGamesHistoryItem) => void;
}

const MysteryGamesHistoryItem = ({ item, onPress }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const icon = useMemo(() => {
        if (
            item.status === MYSTERY_LIKES_STATUS.WaitingForMyself ||
            item.status === MYSTERY_LIKES_STATUS.WaitingForOpponent ||
            item.status === MYSTERY_LIKES_STATUS.Refund
        ) {
            return <MysteryLikesGamesHistoryIcon slug={item.status} />;
        }
        return (
            <OtherUserAvatar
                size={scale(40)}
                imageUrl={item.opponent?.mainPet?.image.thumbnailUrl}
                userId={item.opponent?.walletAddress}
            />
        );
    }, [item.status]);
    const title = useMemo(() => {
        if (item.opponent?.walletAddress) {
            return truncateEthAddress(
                item.opponent.username || item.opponent.walletAddress
            );
        }
        return i18n.t(`HomeScreen.GameHistoryScreen.status.${item.status}`);
    }, [item.status]);

    return (
        <TouchableOpacity
            style={[styles.itemContainer]}
            activeOpacity={0.7}
            disabled={item.status === MYSTERY_LIKES_STATUS.Refund}
            onPress={() => onPress(item)}>
            <View style={styles.left}>
                <View style={styles.imageWrapper}>{icon}</View>
                <View style={styles.infoBlock}>
                    <Text
                        style={styles.address}
                        ellipsizeMode={'middle'}
                        numberOfLines={1}>
                        {title}
                    </Text>
                    <Text
                        style={styles.date}
                        ellipsizeMode={'middle'}
                        numberOfLines={1}>
                        {dateFormatter(item.createdAt)}
                    </Text>
                </View>
            </View>
            <View style={styles.right}>
                <GameAmount
                    status={item.status}
                    amount={balanceFromWei(item.iguAmountWei).valueLong}
                />
            </View>
        </TouchableOpacity>
    );
};
export default MysteryGamesHistoryItem;
