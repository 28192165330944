import * as React from 'react';

import CoinIcon from '@components/Icons/CoinIcon';
import { Text, View } from '@components/Themed';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import { toStringUSD } from '../../helpers/helper';
import stylesMain from './styles';

interface IProps {
    totalVolumeIgu: string;
    totalVolumeUsd: string;
}

const MarketPlaceTotalPurchaseSummary = ({
    totalVolumeIgu,
    totalVolumeUsd,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={styles.overview}>
            <Text style={styles.title}>
                {i18n.t('general.KEYS.totalPurchasesSummary')}
            </Text>
            <View style={styles.amountBlock}>
                <CoinIcon slug={Coin.igu} size={32} />
                <Text style={styles.amount}>
                    {balanceFromWei(totalVolumeIgu).valueLong}
                </Text>
            </View>
            <Text style={styles.usd} numberOfLines={1}>
                {toStringUSD(totalVolumeUsd)}
            </Text>
        </View>
    );
};
export default MarketPlaceTotalPurchaseSummary;
