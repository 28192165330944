import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import { scale, verticalScale } from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const stylesMain = (theme: theme) =>
    StyleSheet.create({
        container: {
            position: 'absolute',
            left: scale(20),
        },
    });

export default stylesMain;
