import * as React from 'react';
import QRCode from 'react-native-qrcode-svg';

import { isWeb } from '../../helpers/app';
import { scale } from '../../helpers/dimensions';
import useThemedStyles from '../../hooks/useThemedStyles';
import { View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    link: string;
    name?: string;
}

const QrCodeImage = ({ link, name }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const canShowLogo = !isWeb;
    const qrLogo = React.useMemo(() => {
        switch (name) {
            case 'BNB':
                return require('../../assets/icons/coins/BNB.png');
            case 'BUSD':
                return require('../../assets/icons/coins/BUSD.png');
            case 'IGU':
                return require('../../assets/icons/coins/IGU.png');
            case 'IGUP':
                return require('../../assets/icons/coins/IGUP.png');

            default:
                return undefined;
        }
    }, [name]);
    const logoProps = canShowLogo
        ? {
              logo: qrLogo,
              logoBackgroundColor: name ? 'white' : 'transparent',
              logoMargin: name ? 6 : 0,
              logoBorderRadius: name ? 20 : 0,
          }
        : {};

    return (
        <View style={styles.shadow}>
            <View style={[styles.itemContainer, styles.qrCodeView]}>
                <QRCode value={link} size={scale(180)} />
            </View>
        </View>
    );
};
export default QrCodeImage;
