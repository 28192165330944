import React, {
    FC,
    PropsWithChildren,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';

import { BigNumber, Wallet, ethers } from 'ethers';

import {
    ExchangeRatesOutput,
    IUserMysteryBox,
    PaymentOptionOutput,
    SignedTransactionOutput,
    UserBalanceResponse,
} from '@Data/Models';
import { distributors } from '@Data/distributors.abi';
import { igu } from '@Data/erc20.abi';
import { iguWallet } from '@Data/iguWallet.abi';
import { igup } from '@Data/igup.abi';
import { marketplace } from '@Data/marketplace.abi';
import { mysteryBox } from '@Data/mysteryBox.abi';
import { abi } from '@Data/nft.abi';
import { staking } from '@Data/staking.abi';
import { balanceFromWei, feeFormatter, valueToWei } from '@helpers/wallet';
import useFee, { EstimatedTransactionFee } from '@hooks/useFee';
import useWalletRewards from '@hooks/useWalletRewards';
import useGetWalletFromStore, {
    createWalletFromMnemonic,
    createWalletFromPrivateKey,
} from '@hooks/wallet/useGetWalletFromStore';
import useLoadBalances from '@hooks/wallet/useLoadBalances';
import useLoadExchangeRates from '@hooks/wallet/useLoadExhangeRates';

import { ConfigContext } from '../ConfigContext';
import { UserContext } from '../UserContext';
import {
    Coin,
    CoinBalance,
    WalletBalance,
    WalletBalanceTokens,
    WalletData,
    approveAndExecuteTransaction,
    calculateExchangeRate,
    handleTransactionOverrides,
    mapBalances,
    sendTransaction,
} from './WalletHelpers';

export interface ITransactionResult {
    transactionFee: string;
    transactionFeeFormatted: string;
    transactionId: string;
    explorerUrl: string;
}

type WalletContextType = {
    walletData: WalletData | null;
    tmpWalletData: WalletData | null;
    isLoaded: boolean;
    balanceLoaded: boolean;
    generate: () => void;
    importWallet: (wallet: Wallet) => void;
    save: () => void;
    fromPrivateKey: (privateKey: string) => Wallet;
    fromMnemonic: (mnemonic: string) => Wallet;
    finalizeAddingProcess: () => void;
    getMnemonic: () => Promise<string | null | undefined>;
    getPrivateKey: () => Promise<string | null | undefined>;
    walletBalance: WalletBalance | null;
    clearWallet: () => void;
    reloadBalance: (silent: boolean) => Promise<void>;
    reloadExchanges: () => void;
    balanceReloading: boolean;
    setReloadDisabled: (state: boolean) => void;
    fee: EstimatedTransactionFee | null;
    transferNFT: (
        address: string,
        tokenId: string
    ) => Promise<ITransactionResult>;
    transferCoin: (
        toAddress: string,
        amount: string,
        coin: Coin
    ) => Promise<ITransactionResult>;
    executeTransaction: (
        payment: PaymentOptionOutput,
        signedTransaction: SignedTransactionOutput,
        minimumConfirmations?: boolean,
        isMysteryBox?: boolean
    ) => Promise<ITransactionResult | null>;
    collectReward: (
        signedTransaction: SignedTransactionOutput
    ) => Promise<ITransactionResult>;
    withdrawIGU: (
        signedTransaction: SignedTransactionOutput
    ) => Promise<ITransactionResult>;

    stakeIGUP: (
        payment: PaymentOptionOutput,
        signedTransaction: SignedTransactionOutput
    ) => Promise<ITransactionResult>;
    unstakeIGUP: () => Promise<ITransactionResult>;
    getCoinBalances: () => CoinBalance[];
    priceInUsd: (coin: Coin, amount: string) => string;
    iguToBNB: (amount: string) => string;
    priceInBUSD: (coin: Coin, amount: string) => string;
    getRate: (coin1: Coin, coin2: Coin) => number;
    getUserMysteryBoxes: () => Promise<IUserMysteryBox[] | null>;
    openMysteryBox: (
        signedTransaction: SignedTransactionOutput
    ) => Promise<ITransactionResult>;
    userBalance: UserBalanceResponse | undefined;
    getUserBalance: (silent: boolean) => Promise<void>;
    depositIGUonVIGU: (amount: string) => Promise<ITransactionResult>;
    approvalNftsForMarketplace: () => Promise<ITransactionResult | null>;
    buyFromMarketPlace: (
        buyTransactionValuesStringified: string,
        amountWei: string
    ) => Promise<ITransactionResult | null>;
    delistFromMarketPlace: (
        cancelTransactionValuesStringified: string
    ) => Promise<ITransactionResult | null>;
    isNFTApprovedForMarketplace: () => Promise<boolean>;
};

export const WalletContext = createContext<WalletContextType | undefined>(
    undefined
);

export const useWallet = () => {
    const context = useContext(WalletContext);
    if (context === undefined) {
        throw new Error('useWallet must be within WalletProvider');
    }

    return context;
};

const WalletProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const [walletData, setWalletData] = useState<WalletData | null>(null);
    const [tmpWalletData, setTmpWalletData] = useState<WalletData | null>(null);
    const [walletBalance, setWalletBalance] = useState<WalletBalance | null>(
        null
    );
    const { config } = useContext(ConfigContext);
    const { user } = useContext(UserContext);
    const [provider, setProvider] = useState<
        ethers.providers.JsonRpcProvider | undefined
    >(undefined);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [balanceLoaded, setBalanceLoaded] = useState<boolean>(false);
    const [balanceReloading, setBalanceReloading] = useState<boolean>(false);
    const [reloadDisabled, setReloadDisabled] = useState<boolean>(false);
    const { fee } = useFee(walletData?.wallet, config);
    const { balances, refetchWallet } = useLoadBalances(
        reloadDisabled,
        user,
        walletData,
        config
    );

    const { exchangeRates, refetchExchanges } = useLoadExchangeRates(
        reloadDisabled,
        walletData
    );
    const { userBalance, getUserBalance } = useWalletRewards();

    const {
        saveWallet,
        clearWalletInfo,
        getMnemonic,
        getPrivateKey,
        loadWalletFromPrivateKeyOrMnemonic,
    } = useGetWalletFromStore(user, provider);

    // generate wallet during wallet create process
    const generate = useCallback(() => {
        const wallet = provider
            ? Wallet.createRandom().connect(provider)
            : Wallet.createRandom();
        setTmpWalletData({ wallet });
    }, [provider]);

    // save wallet to device
    const saveWalletToDevice = useCallback(
        async (wallet: Wallet) => {
            Console.log('[WALLET] Saving wallet to keychain', wallet.address);

            return saveWallet(wallet.mnemonic?.phrase, wallet.privateKey);
        },
        [saveWallet]
    );

    // import wallet during wallet import process
    const importWallet = useCallback(
        (wallet: Wallet) => {
            if (!provider) {
                return;
            }
            Console.log('[WALLET] importWallet', wallet.address);
            setTmpWalletData({ wallet });
            saveWalletToDevice(wallet);
        },
        [provider, saveWalletToDevice]
    );

    // save wallet after generation
    const save = useCallback(() => {
        if (!tmpWalletData?.wallet) {
            return;
        }
        Console.log('[WALLET] save', tmpWalletData.wallet.address);

        saveWalletToDevice(tmpWalletData.wallet);
    }, [tmpWalletData, saveWalletToDevice]);

    const fromPrivateKey = useCallback(
        (privateKey: string): Wallet => {
            if (!provider) {
                throw "Can't load provider";
            }

            return createWalletFromPrivateKey(privateKey, provider);
        },
        [provider]
    );

    const fromMnemonic = useCallback(
        (mnemonic: string): Wallet => {
            if (!provider) {
                throw "Can't load provider";
            }

            return createWalletFromMnemonic(mnemonic, provider);
        },
        [provider]
    );

    // mark import/create wallet process completed
    const finalizeAddingProcess = useCallback(() => {
        if (!tmpWalletData) {
            return;
        }

        setWalletData({ wallet: tmpWalletData.wallet });
        setTmpWalletData(null);
    }, [tmpWalletData]);

    const clearWallet = useCallback(() => {
        clearWalletInfo();
        setTmpWalletData(null);
        setWalletData(null);
        setWalletBalance(null);
    }, [clearWalletInfo]);

    async function reloadBalance(silent: boolean) {
        !silent && setBalanceReloading(true);
        await refetchWallet();
        !silent && setBalanceReloading(false);
    }

    async function reloadExchanges() {
        await refetchExchanges();
    }

    // update provider when config is loaded
    useEffect(() => {
        if (config) {
            setProvider(
                new ethers.providers.JsonRpcProvider(
                    config.rpcPublicProviders[0],
                    config.chainId
                )
            );
        }
    }, [config]);

    // map and update balances
    useEffect(() => {
        setBalanceLoaded(false);
        if (user && config && balances && exchangeRates) {
            const walletBalance = mapBalances(balances, exchangeRates, config);
            setWalletBalance(walletBalance);
            // wallet is loaded after balances are loaded
            setBalanceLoaded(true);
        }
    }, [user, balances, exchangeRates, config]);

    // load user rewards wallet
    useEffect(() => {
        if (balanceLoaded) {
            getUserBalance(true);
        }
    }, [balanceLoaded]);

    // load wallet from keychain
    useEffect(() => {
        async function loadWallet() {
            if (!provider) {
                return;
            }

            // if we don't have user, wallet can't be loaded
            if (!user) {
                Console.log('[WALLET] loadWallet no user setIsLoaded to false');
                setWalletData(null);
                setWalletBalance(null);
                setIsLoaded(false);
                return;
            }

            // if we have temporary wallet or user don't have wallet, then do not load walletData
            if (tmpWalletData || !user.walletAddress) {
                setIsLoaded(true);
                return;
            }

            // We already have wallet in the state, return from state
            if (walletData) {
                Console.log(
                    '[WALLET] Wallet loaded from state, not loading from key store'
                );
                return;
            }

            // load wallet from keychain
            const wallet = await loadWalletFromPrivateKeyOrMnemonic();

            if (wallet) {
                // set wallet
                Console.log(`[WALLET] Wallet loaded! ${wallet.address}`);
                setWalletData({ wallet });
            } else {
                // user has not wallet
                Console.log(`[WALLET] Can't load wallet`);
                setWalletData(null);
            }

            setIsLoaded(true);
        }

        loadWallet();
    }, [
        config,
        provider,
        user,
        walletData,
        tmpWalletData,
        loadWalletFromPrivateKeyOrMnemonic,
    ]);

    // transfer nft to another wallet
    const transferNFT = useCallback(
        async (
            toAddress: string,
            tokenId: string
        ): Promise<ITransactionResult> => {
            if (!walletData?.wallet || !config) {
                throw 'Missing wallet or config';
            }
            const { wallet } = walletData;

            const transactionOverrides = { gasPrice: fee?.gasPrice };

            const nftContract = new ethers.Contract(
                config.nftIguverseContractAddress,
                abi,
                wallet
            );
            const result = await nftContract.transferFrom(
                wallet.address,
                toAddress,
                tokenId,
                transactionOverrides
            );
            const transactionResult = await result.wait(
                config.mintConfirmationsCount
            );

            return mapResult(
                result,
                transactionResult,
                config.blockExplorerURL,
                config.gasPriceConfig.defaultGasPriceGwei
            );
        },
        [config, walletData, fee]
    );

    // transfer coin to another wallet
    const transferCoin = useCallback(
        async (
            toAddress: string,
            amount: string,
            coin: string
        ): Promise<ITransactionResult> => {
            if (!walletData?.wallet || !config) {
                throw 'Missing wallet or config';
            }

            const result = await sendTransaction(
                walletData.wallet,
                config,
                coin,
                toAddress,
                amount
            );
            const transactionResult = await result.wait(
                config.mintConfirmationsCount
            );

            return mapResult(
                result,
                transactionResult,
                config.blockExplorerURL,
                config.gasPriceConfig.defaultGasPriceGwei
            );
        },
        [config, walletData]
    );

    const executeTransaction = useCallback(
        async (
            payment: PaymentOptionOutput,
            signedTransaction: SignedTransactionOutput,
            minimumConfirmations?: boolean,
            isMysteryBox?: boolean
        ): Promise<ITransactionResult | null> => {
            // Virtual tokens
            if (!payment?.tokenAddress) {
                return null;
            }

            if (!walletData?.wallet || !config) {
                throw 'Missing wallet or config';
            }
            const confirmations = minimumConfirmations
                ? 1
                : config.mintConfirmationsCount;
            const result = await approveAndExecuteTransaction(
                payment,
                signedTransaction,
                config,
                walletData.wallet,
                confirmations,
                isMysteryBox
            );
            const transactionResult = await result.wait(confirmations);

            return mapResult(
                result,
                transactionResult,
                config.blockExplorerURL,
                config.gasPriceConfig.defaultGasPriceGwei
            );
        },
        [config, walletData]
    );

    const approvalNftsForMarketplace =
        useCallback(async (): Promise<ITransactionResult | null> => {
            if (!walletData?.wallet || !config || !user) {
                throw 'Missing wallet or config';
            }
            const nftContract = new ethers.Contract(
                config.nftIguverseContractAddress,
                abi,
                walletData.wallet
            );

            const result = await nftContract.setApprovalForAll(
                config.marketplaceContractAddress,
                true
            );
            const transactionResult = await result.wait(
                config.mintConfirmationsCount
            );
            return mapResult(
                result,
                transactionResult,
                config.blockExplorerURL,
                config.gasPriceConfig.defaultGasPriceGwei
            );
        }, [config, walletData, user]);

    const isNFTApprovedForMarketplace =
        useCallback(async (): Promise<boolean> => {
            if (!walletData?.wallet || !config || !user) {
                throw 'Missing wallet or config';
            }
            const nftContract = new ethers.Contract(
                config.nftIguverseContractAddress,
                abi,
                walletData.wallet
            );
            const result = await nftContract.isApprovedForAll(
                user.walletAddress,
                config.marketplaceContractAddress
            );
            return result;
        }, [config, walletData, user]);

    const buyFromMarketPlace = useCallback(
        async (
            buyTransactionValuesStringified: string,
            amountWei: string
        ): Promise<ITransactionResult | null> => {
            if (!walletData?.wallet || !config || !user || !fee) {
                throw 'Missing wallet or config';
            }
            const transactionOverrides = { gasPrice: fee?.gasPrice };

            const iguContract = new ethers.Contract(
                config.iguTokenContractAddress,
                igu,
                walletData.wallet
            );

            const allowance: BigNumber = await iguContract.allowance(
                user.walletAddress,
                config.marketplaceContractAddress
            );

            if (allowance.lt(BigNumber.from(amountWei))) {
                const approveResult = await iguContract.approve(
                    config.marketplaceContractAddress,
                    config.marketplaceMaxPrice,
                    transactionOverrides
                );
                await approveResult.wait(config.mintConfirmationsCount);
            }

            const marketplaceContract = new ethers.Contract(
                config.marketplaceContractAddress,
                marketplace,
                walletData.wallet
            );

            const parsedTransaction = handleTransactionOverrides(
                buyTransactionValuesStringified,
                fee.gasPrice
            );

            const result = await marketplaceContract.buyToken(
                ...parsedTransaction
            );

            const transactionResult = await result.wait(
                config.mintConfirmationsCount
            );

            return mapResult(
                result,
                transactionResult,
                config.blockExplorerURL,
                config.gasPriceConfig.defaultGasPriceGwei
            );
        },
        [config, walletData, user, fee]
    );
    const delistFromMarketPlace = useCallback(
        async (
            cancelTransactionValuesStringified: string
        ): Promise<ITransactionResult | null> => {
            if (!walletData?.wallet || !config || !fee) {
                throw 'Missing wallet or config';
            }

            const marketplaceContract = new ethers.Contract(
                config.marketplaceContractAddress,
                marketplace,
                walletData.wallet
            );
            const parsedTransaction = handleTransactionOverrides(
                cancelTransactionValuesStringified,
                fee.gasPrice
            );

            const result = await marketplaceContract.cancelAskOrder(
                ...parsedTransaction
            );
            const transactionResult = await result.wait(
                config.mintConfirmationsCount
            );
            return mapResult(
                result,
                transactionResult,
                config.blockExplorerURL,
                config.gasPriceConfig.defaultGasPriceGwei
            );
        },
        [config, walletData, fee]
    );

    const collectReward = useCallback(
        async (
            signedTransaction: SignedTransactionOutput
        ): Promise<ITransactionResult> => {
            if (!walletData?.wallet || !config || !fee) {
                throw 'Missing wallet or config';
            }
            const transaction = handleTransactionOverrides(
                signedTransaction.execTransactionValuesStringified,
                fee.gasPrice
            );
            const distributorsContract = new ethers.Contract(
                signedTransaction.contractAddress,
                distributors,
                walletData.wallet
            );

            const result = await distributorsContract.collect(...transaction);

            const transactionResult = await result.wait(
                config.mintConfirmationsCount
            );

            return mapResult(
                result,
                transactionResult,
                config.blockExplorerURL,
                config.gasPriceConfig.defaultGasPriceGwei
            );
        },
        [config, walletData, fee]
    );
    const withdrawIGU = useCallback(
        async (
            signedTransaction: SignedTransactionOutput
        ): Promise<ITransactionResult> => {
            if (!walletData?.wallet || !config || !fee) {
                throw 'Missing wallet or config';
            }
            const transaction = handleTransactionOverrides(
                signedTransaction.execTransactionValuesStringified,
                fee.gasPrice
            );

            const iguWalletContract = new ethers.Contract(
                signedTransaction.contractAddress,
                iguWallet,
                walletData.wallet
            );

            const result = await iguWalletContract.withdraw(...transaction);

            const transactionResult = await result.wait(
                config.mintConfirmationsCount
            );

            return mapResult(
                result,
                transactionResult,
                config.blockExplorerURL,
                config.gasPriceConfig.defaultGasPriceGwei
            );
        },
        [config, walletData, fee]
    );

    const stakeIGUP = React.useCallback(
        async (
            payment: PaymentOptionOutput,
            signedTransaction: SignedTransactionOutput
        ): Promise<ITransactionResult> => {
            const wallet = walletData?.wallet;

            if (!wallet || !config || !fee) {
                throw 'Missing wallet or config';
            }

            const transactionOverrides = { gasPrice: fee.gasPrice };
            const transaction = handleTransactionOverrides(
                signedTransaction.execTransactionValuesStringified,
                fee.gasPrice
            );

            const igupContract = new ethers.Contract(
                config.igupContractAddress,
                igup,
                wallet
            );
            const approveResult = await igupContract.approve(
                signedTransaction.contractAddress,
                payment.amountWei,
                transactionOverrides
            );
            await approveResult.wait(config.mintConfirmationsCount);

            const contract = new ethers.Contract(
                signedTransaction.contractAddress,
                staking,
                wallet
            );

            const result = await contract.stake(...transaction);
            Console.log('[WalletProvider] contractResult', result);

            const stakeResult = await result.wait(
                config.mintConfirmationsCount
            );

            return mapResult(
                result,
                stakeResult,
                config.blockExplorerURL,
                config.gasPriceConfig.defaultGasPriceGwei
            );
        },
        [walletData, config, fee]
    );

    const depositIGUonVIGU = React.useCallback(
        async (amount: string): Promise<ITransactionResult> => {
            const amountWei = valueToWei(amount);

            const wallet = walletData?.wallet;

            if (!wallet || !config) {
                throw 'Missing wallet or config';
            }
            const transactionOverrides = { gasPrice: fee?.gasPrice };

            const iguContract = new ethers.Contract(
                config.iguTokenContractAddress,
                igu,
                wallet
            );
            const approveResult = await iguContract.approve(
                config.iguWalletContractAddress,
                amountWei,
                transactionOverrides
            );
            await approveResult.wait(config.mintConfirmationsCount);
            const iguWalletContract = new ethers.Contract(
                config.iguWalletContractAddress,
                iguWallet,
                wallet
            );
            const result = await iguWalletContract.deposit(
                amountWei,
                transactionOverrides
            );
            const transactionResult = await result.wait(
                config.mintConfirmationsCount
            );
            return mapResult(
                result,
                transactionResult,
                config.blockExplorerURL,
                config.gasPriceConfig.defaultGasPriceGwei
            );
        },
        [walletData, config, fee]
    );

    const unstakeIGUP = React.useCallback(async () => {
        const wallet = walletData?.wallet;
        if (!wallet || !config) {
            throw 'Missing wallet or config';
        }
        const transactionOverrides = { gasPrice: fee?.gasPrice };
        const contract = new ethers.Contract(
            config.boosterContractAddress,
            staking,
            wallet
        );

        const result = await contract.unstake(transactionOverrides);
        const unstakeResult = await result.wait(config.mintConfirmationsCount);
        return mapResult(
            result,
            unstakeResult,
            config.blockExplorerURL,
            config.gasPriceConfig.defaultGasPriceGwei
        );
    }, [walletData, config, fee]);

    const getUserMysteryBoxes = React.useCallback(async () => {
        const wallet = walletData?.wallet;
        if (!wallet || !config) {
            throw 'Missing wallet or config';
        }
        const transactionOverrides = { gasPrice: fee?.gasPrice };
        const contract = new ethers.Contract(
            config.lootboxContractAddress,
            mysteryBox,
            wallet
        );

        return contract.tokensOfOwner(wallet.address, transactionOverrides);
    }, [walletData, config, fee]);

    const openMysteryBox = useCallback(
        async (
            signedTransaction: SignedTransactionOutput
        ): Promise<ITransactionResult> => {
            const wallet = walletData?.wallet;
            if (!walletData?.wallet || !config || !fee) {
                throw 'Missing wallet or config';
            }
            const transaction = handleTransactionOverrides(
                signedTransaction.execTransactionValuesStringified,
                fee.gasPrice
            );

            const contract = new ethers.Contract(
                config.lootboxContractAddress,
                mysteryBox,
                wallet
            );

            const result = await contract.requestRandomForToken(...transaction);

            const transactionResult = await result.wait(
                config.mintConfirmationsCount
            );

            return mapResult(
                result,
                transactionResult,
                config.blockExplorerURL,
                config.gasPriceConfig.defaultGasPriceGwei
            );
        },
        [config, walletData, fee]
    );

    const getCoinBalances = useCallback(() => {
        return walletBalance
            ? [
                  walletBalance.bnb,
                  walletBalance.igu,
                  walletBalance.igup,
                  walletBalance.busd,
              ]
            : [];
    }, [walletBalance]);

    const priceInUsd = useCallback(
        (coin: Coin, amount: string) => {
            if (!walletBalance) {
                return '0.0';
            }

            return calculateExchangeRate(
                amount,
                walletBalance[coin.toLowerCase() as WalletBalanceTokens]
                    .exchangeRate
            );
        },
        [walletBalance]
    );
    const iguToBNB = useCallback(
        (amount: string) => {
            if (!walletBalance) {
                return '0.0';
            }
            return calculateExchangeRate(amount, getRate(Coin.igu, Coin.bnb));
        },
        [walletBalance]
    );
    const priceInBUSD = useCallback(
        (coin: Coin, amount: string) => {
            if (!walletBalance) {
                return '0.0';
            }

            return calculateExchangeRate(amount, getRate(coin, Coin.busd));
        },
        [walletBalance]
    );
    const getRate = useCallback(
        (coin1: Coin, coin2: Coin) => {
            if (!exchangeRates) {
                return 0;
            }
            const pair = `${coin1.toLowerCase()}${coin2.toLowerCase()}`;
            return Number(exchangeRates[pair as keyof ExchangeRatesOutput]);
        },
        [exchangeRates]
    );

    return (
        <WalletContext.Provider
            value={{
                walletData,
                tmpWalletData,
                isLoaded,
                balanceLoaded,
                generate,
                importWallet,
                save,
                fromPrivateKey,
                fromMnemonic,
                finalizeAddingProcess,
                getMnemonic,
                getPrivateKey,
                reloadBalance,
                reloadExchanges,
                balanceReloading,
                setReloadDisabled,
                walletBalance,
                clearWallet,
                fee,
                transferNFT,
                transferCoin,
                executeTransaction,
                collectReward,
                stakeIGUP,
                unstakeIGUP,
                getCoinBalances,
                priceInUsd,
                getRate,
                getUserMysteryBoxes,
                openMysteryBox,
                userBalance,
                getUserBalance,
                depositIGUonVIGU,
                iguToBNB,
                priceInBUSD,
                withdrawIGU,
                approvalNftsForMarketplace,
                buyFromMarketPlace,
                delistFromMarketPlace,
                isNFTApprovedForMarketplace,
            }}>
            {children}
        </WalletContext.Provider>
    );
};

function mapResult(
    result: any,
    transactionResult: any,
    explorerUrl: string,
    defaultGasPrice: string
): ITransactionResult {
    const { gasPrice } = result;
    const { effectiveGasPrice } = transactionResult;
    const { gasUsed } = transactionResult;
    const transactionId = transactionResult.transactionHash;
    const saveGasPrice = gasPrice || effectiveGasPrice || defaultGasPrice;

    const fee = ethers.utils.formatEther(saveGasPrice.mul(gasUsed));

    return {
        transactionFee: fee,
        transactionFeeFormatted: `${feeFormatter(fee)} BNB`,
        transactionId,
        explorerUrl: `${explorerUrl}/tx/${transactionId}`,
    };
}

export default WalletProvider;
