import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { moderateScale, scale, verticalScale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { stylesAlignCenter } from '../../helpers/style';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        content: {
            padding: 0,
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].background,
            maxWidth: 350,
            borderWidth: 0,
            borderColor: Colors[theme].border.primary,
        },
        contentText: {
            ...stylesAlignCenter(),
            textAlign: 'center',
            ...font({
                size: 13,
                height: 18,
                weight: '600',
            }),
            color: Colors[theme].text.primary,
        },
        shadow: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            backgroundColor: Colors[theme].shadow.default,
            zIndex: -1,
        },
        isOneColorShadow: {
            backgroundColor: Colors[theme].alwaysOneColor.shadow,
        },
        contentView: {
            borderRadius: moderateScale(12),
            flex: 1,
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(12),
            marginBottom: verticalScale(4),
            backgroundColor: Colors[theme].alwaysOneColor.background,
        },
        arrow: {
            zIndex: 1,
        },
        webShadow: {
            borderRadius: moderateScale(8),
            backgroundColor: Colors[theme].shadow.default,
            maxWidth: 300,
        },
        isOneColorContent: {
            backgroundColor: Colors[theme].alwaysOneColor.background,
        },
        isOneColorWebShadow: {
            backgroundColor: Colors[theme].alwaysOneColor.shadow,
        },
    });

export default styles;
