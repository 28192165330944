import React, { useCallback, useMemo } from 'react';
import { KeyboardAvoidingView, ScrollView } from 'react-native';

import { ASSIGN_USER_ROLE } from '@Data/Requests';
import { useMutation } from '@apollo/client/react/hooks/useMutation';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import CustomModal from '@components/CustomModal';
import ImageIguana from '@components/ImageIguana';
import { View } from '@components/Themed';
import { isIOS } from '@helpers/app';
import { errorsHandler } from '@helpers/errors';
import { isGraphqlError, waitForModal } from '@helpers/helpers';
import { toastError, toastSuccess } from '@helpers/toastNotification';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';

import { useRoleSelect } from '../hooks/useRoleSelect';
import { useWalletAddressInput } from '../hooks/useWalletAddressInput';
import stylesMain from './styles';

const AdminAssignRoleScreen = () => {
    const styles = useThemedStyles(stylesMain);
    const { isVisible, close, open } = useVisibleHook();
    const {
        renderWalletInput,
        checkWallet,
        walletError,
        walletValue,
        resetWalletInput,
    } = useWalletAddressInput();

    const {
        renderRoleSelect,
        checkRole,
        resetRoleSelect,
        roleError,
        roleValue,
    } = useRoleSelect();

    const disableButton = useMemo(
        () => !walletValue || !!walletError || !!roleError || !roleValue,

        [walletError, walletValue, roleValue, roleError]
    );

    const onButtonPress = useCallback(() => {
        if (checkWallet() && checkRole()) {
            open();
        }
    }, [checkWallet, checkRole]);

    const [assignUserRole, { loading }] = useMutation(ASSIGN_USER_ROLE);

    const onSendPress = React.useCallback(async () => {
        Console.info(walletValue);
        try {
            await assignUserRole({
                variables: {
                    assignRoleInput: {
                        role: roleValue,
                        walletAddress: walletValue,
                    },
                },
            });
            reset();
            close();
            await waitForModal();
            toastSuccess(i18n.t('profile.adminAssignRole.toastSuccess'));
        } catch (error) {
            reset();
            close();
            await waitForModal();
            if (isGraphqlError(error, 'NOT_FOUND')) {
                toastError(i18n.t('profile.adminAssignRole.toastError'));
                return;
            }
            errorsHandler(error, true);
        }
    }, [walletValue, roleValue]);

    const reset = () => {
        resetWalletInput();
        resetRoleSelect();
    };

    const onCancelPress = () => {
        reset();
        close();
    };

    return (
        <KeyboardAvoidingView
            behavior={isIOS ? 'padding' : 'height'}
            style={styles.mainContainer}>
            <NavigationBar
                backIcon
                filled={false}
                title={i18n.t('profile.adminAssignRole.screenTitle')}
            />

            <ScrollView
                keyboardShouldPersistTaps="handled"
                style={styles.content}
                showsVerticalScrollIndicator={false}>
                <BlockHeader
                    containerStyle={styles.paddingHorizontal}
                    type="primary"
                    title={i18n.t('profile.adminAssignRole.title')}
                    text={i18n.t('profile.adminAssignRole.text')}
                />

                <View style={styles.inputWrapper}>{renderWalletInput()}</View>
                <View style={styles.inputWrapper}>{renderRoleSelect()}</View>
            </ScrollView>
            <ButtonsBottom
                containerStyle={styles.buttons}
                title={i18n.t('profile.adminAssignRole.button')}
                onPress={onButtonPress}
                disabled={disableButton}
            />
            <CustomModal
                isVisible={isVisible}
                icon={<ImageIguana type="attention" />}
                titleText={i18n.t(
                    'profile.adminAssignRole.attentionModal.title'
                )}
                infoText={i18n.t('profile.adminAssignRole.attentionModal.text')}
                firstButtonText={i18n.t(
                    'profile.adminAssignRole.attentionModal.button1'
                )}
                secondButtonText={i18n.t(
                    'profile.adminAssignRole.attentionModal.button2'
                )}
                firstButtonLoading={loading}
                firstButtonDisabled={loading}
                firstButtonType="critical"
                onFirstButtonClick={onSendPress}
                onSecondButtonClick={onCancelPress}
            />
        </KeyboardAvoidingView>
    );
};

export default AdminAssignRoleScreen;
