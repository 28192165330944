import * as React from 'react';
import { View } from 'react-native';

import ComingSoonImage from '@assets/icons/marketplace/comingSoon.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import Button from '@components/Button';
import Icon, { ICON_NAMES } from '@components/Icons';
import { openUrlOrShowError } from '@helpers/linking';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { getWebAppLinkMarketPlace } from '@navigation/helpers';

import stylesMain from './styles';

const MarketPlaceNoTradeAvalibleScreen = () => {
    const styles = useThemedStyles(stylesMain);

    const handleOnTradePress = React.useCallback(async () => {
        const url = await getWebAppLinkMarketPlace();
        await openUrlOrShowError(url);
    }, []);

    return (
        <View style={styles.container}>
            <View style={styles.node}>
                <ComingSoonImage width={180} height={180} />
            </View>
            <BlockHeader
                title={i18n.t(
                    'MarketPlace.MarketPlaceNoTradeAvalibleScreen.title'
                )}
                text={i18n.t(
                    'MarketPlace.MarketPlaceNoTradeAvalibleScreen.description'
                )}
                type="emptyState"
            />
            <View style={styles.node}>
                <Button
                    size="md"
                    type="fill"
                    title={i18n.t(
                        'MarketPlace.MarketPlaceNoTradeAvalibleScreen.button'
                    )}
                    iconDirection="right"
                    onPress={handleOnTradePress}
                    icon={
                        <Icon
                            name={ICON_NAMES.EXPLORE}
                            color={styles.icon.color}
                            size={20}
                        />
                    }
                />
            </View>
            <View style={styles.node} />
        </View>
    );
};

export default MarketPlaceNoTradeAvalibleScreen;
