import * as React from 'react';
import { Text, View } from 'react-native';

import Button from '@components/Button';
import i18n from '@i18n/i18n';

import useThemedStyles from '../../hooks/useThemedStyles';
import stylesMain from './styles';

interface IProps {
    hint: number;
    maxHints: number;
    text: string;
    isOneColor?: boolean;
    action?: () => void;
}

const GuideTooltipContent = ({
    hint,
    maxHints,
    text,
    isOneColor,
    action,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <>
            <Text
                style={[
                    styles.tooltipText,
                    isOneColor && styles.isOneColorText,
                ]}>
                {text}
            </Text>
            <View style={styles.hintsBlock}>
                <Text style={styles.hintText}>
                    {i18n.t('general.hint', {
                        hint,
                        maxHints,
                    })}
                </Text>
                {!!action && (
                    <View style={styles.button}>
                        <Button
                            isOneColor={isOneColor}
                            debouncedPress
                            onPress={action}
                            title={i18n.t('general.continue')}
                            tailTitle
                        />
                    </View>
                )}
            </View>
        </>
    );
};
export default GuideTooltipContent;
