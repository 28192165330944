import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { scale, verticalScale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            backgroundColor: Colors[theme].background,
            borderRadius: 1000,
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(4),
            marginBottom: verticalScale(2),
            flexDirection: 'row',
            alignItems: 'center',
        },
        shadow: {
            borderRadius: 1000,
            backgroundColor: Colors[theme].shadow.default,
        },
        text: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
            letterSpacing: 0.08,
            textTransform: 'capitalize',
        },
        left: {
            backgroundColor: 'transparent',
            marginRight: scale(4),
        },
    });

export default styles;
