import React, { useMemo } from 'react';
import { Image, ScrollView, TouchableOpacity } from 'react-native';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import { useDimensions } from '@contexts/DimensionsContext';
import { scale } from '@helpers/dimensions';
import i18n from '@i18n/i18n';

import useThemedStyles from '../../hooks/useThemedStyles';
import ModalBottom from '../ModalBottom';
import mainStyles from './styles';

interface IProps {
    isVisible: boolean;
    closeModal: () => void;
    onAction?: () => void;
}

const imgSize = {
    width: 670,
    height: 750,
};

const ModalHowToConnectFacebook = ({
    isVisible,
    closeModal,
    onAction,
}: IProps) => {
    const styles = useThemedStyles(mainStyles);

    const { windowWidth } = useDimensions();

    const newWidth = useMemo(() => windowWidth - scale(20) * 2, [windowWidth]);

    const newHeight = useMemo(() => {
        const ratio = imgSize.width / imgSize.height;
        return newWidth / ratio;
    }, [newWidth]);

    return (
        <ModalBottom
            modalHeight="96%"
            isVisible={isVisible}
            onClose={closeModal}
            titleText={i18n.t('howConnectFacebookModal.screenTitle')}>
            <ScrollView
                contentContainerStyle={styles.container}
                showsVerticalScrollIndicator={false}>
                <TouchableOpacity activeOpacity={1}>
                    <BlockHeader
                        emptyStateIcon={
                            <Image
                                resizeMode="contain"
                                style={[
                                    styles.img,
                                    {
                                        width: newWidth,
                                        height: newHeight,
                                    },
                                ]}
                                source={require('@assets/icons/emptyState/howToConnectFacebook.png')}
                            />
                        }
                        title={i18n.t('howConnectFacebookModal.title')}
                        text={i18n.t('howConnectFacebookModal.text')}
                        type="emptyState"
                    />
                </TouchableOpacity>
            </ScrollView>
            <ButtonsBottom
                containerStyle={styles.buttons}
                onPress={onAction || closeModal}
                title={i18n.t('howConnectFacebookModal.button')}
            />
        </ModalBottom>
    );
};
export default ModalHowToConnectFacebook;
