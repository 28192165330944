import * as React from 'react';

import { useConfig } from '@contexts/ConfigContext';
import { useTrade } from '@contexts/Trade/TradeContext';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { openUrlOrShowError } from '@helpers/linking';
import { useVisibleHook } from '@hooks/useVisibleHook';

import CoinIcon from '../../../../components/Icons/CoinIcon';
import LoadingComponent from '../../../../components/LoadingComponent';
import ModalDeposit from '../../../../components/ModalDeposit';
import ModalMainnet from '../../../../components/ModalMainnet';
import PullToRefresh from '../../../../components/PullToRefresh';
import SafeAreaTabContent from '../../../../components/SafeAreaTabContent';
import { Text, View } from '../../../../components/Themed';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import NavigationBar from '../../../../navigation/NavigationBar';
import { getWebAppLinkTrade } from '../../../../navigation/helpers';
import ROUTES from '../../../../navigation/routes';
import { useNavigation } from '../../../../navigation/useNavigation';
import { WalletCoinsBalanceProps } from '../../../../types';
import WalletButtons from '../../components/WalletButtons';
import WalletCoinBalance from '../components/WalletCoinBalance';
import { WalletCoinsTransactionHistoryList } from '../components/WalletCoinsTransactionHistoryList';
import stylesMain from './styles';

const CoinBalanceScreen = ({ route }: WalletCoinsBalanceProps) => {
    const {
        isVisible: isVisibleMainnetModal,
        open: openMainnetModal,
        close: closeMainnetModal,
    } = useVisibleHook();
    const styles = useThemedStyles(stylesMain);
    const {
        walletData,
        walletBalance,
        reloadBalance,
        reloadExchanges,
        getCoinBalances,
        balanceReloading,
    } = useWallet();
    const navigation = useNavigation();
    const { isTradeAvailable } = useConfig();
    const { tradeTokens } = useTrade();

    const balance = React.useMemo(() => {
        return getCoinBalances().find(
            (item) => item.name === route.params.balance?.name
        );
    }, [getCoinBalances, route.params]);

    const onRefresh = React.useCallback(() => {
        reloadBalance(false);
        reloadExchanges();
    }, []);

    const handleOnDepositPress = () => {
        setIsVisibleModalDeposit(true);
    };

    const handleOnWithdrawPress = () => {
        navigation.navigate(ROUTES.WALLET_COINS_WITHDRAW, { balance });
    };

    const handleOnTradePress = async () => {
        if (!tradeTokens.length) {
            openMainnetModal();
            return;
        }

        if (!isTradeAvailable) {
            const url = await getWebAppLinkTrade();
            await openUrlOrShowError(url);
            return;
        }

        navigation.navigateToTrade({ balance });
    };

    const [isVisibleModalDeposit, setIsVisibleModalDeposit] =
        React.useState<boolean>(false);

    const handleBackToWallet = () => {
        navigation.navigateToCoinsWallet();
    };

    const renderItem = React.useCallback(() => {
        if (!balance) {
            return null;
        }

        return (
            <>
                <View style={styles.walletBalanceWrapper}>
                    <WalletCoinBalance balance={balance} />
                </View>
                <View style={[styles.historyWrapper]}>
                    <WalletCoinsTransactionHistoryList
                        coinName={balance.name}
                        onDepositPress={handleOnDepositPress}
                        stickyButtons={
                            <WalletButtons
                                onDepositPress={handleOnDepositPress}
                                onWithdrawPress={handleOnWithdrawPress}
                                onTradePress={handleOnTradePress}
                                coin={balance.name}
                            />
                        }
                        contractAddress={balance.contractAddress}
                        refreshing={balanceReloading}
                    />
                </View>
            </>
        );
    }, [walletData, balance, walletBalance, balanceReloading]);

    if (!walletData || !balance) {
        return <LoadingComponent fullPage />;
    }

    return (
        <SafeAreaTabContent>
            <View style={styles.container}>
                <NavigationBar backIcon onBack={handleBackToWallet}>
                    <View style={styles.titleWrapper}>
                        <CoinIcon slug={balance.name} size={32} />
                        <Text style={styles.title}>{balance.name}</Text>
                    </View>
                </NavigationBar>
                <PullToRefresh
                    refreshing={balanceReloading}
                    textLoading={i18n.t('pullToRefresh.reloadBalance.text')}
                    onRefresh={onRefresh}
                    renderItem={renderItem}
                />
                {!!walletData?.wallet?.address && (
                    <ModalDeposit
                        isVisible={isVisibleModalDeposit}
                        setIsVisible={setIsVisibleModalDeposit}
                        coin={balance.name}
                    />
                )}
            </View>
            <ModalMainnet
                isVisible={isVisibleMainnetModal}
                onClose={closeMainnetModal}
            />
        </SafeAreaTabContent>
    );
};

export default CoinBalanceScreen;
