import { useCallback, useEffect, useMemo, useState } from 'react';

import { TypedDataDomain, TypedDataField } from 'ethers';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
    MARKETPLACE_CREATE_ORDER,
    MARKETPLACE_ORDER_DATA_TO_SIGN,
} from '@Data/Requests';
import { useMutation } from '@apollo/client';
import { useConfig } from '@contexts/ConfigContext';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { errorsHandler } from '@helpers/errors';
import { wait } from '@helpers/helpers';
import {
    balanceFromWei,
    inputNumberFormatter,
    isBiggerThanMax,
    valueToWei,
} from '@helpers/wallet';
import useBlockchainErrorsHandler from '@hooks/useBlockchainErrorsHandler';
import useFeeInfo from '@hooks/useFeeInfo';
import { useLoadingHook } from '@hooks/useLoadingHook';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import {
    DataToSingOutput,
    MarketplaceSaleablePetOutput,
} from '@models/marketplace';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import { toStringUSD } from '../../helpers/helper';

export const useMarketPlaceSelectNftPriceScreen = (
    pet: MarketplaceSaleablePetOutput
) => {
    const navigation = useNavigation();

    const { config } = useConfig();

    const { renderBlockchainErrorsModal, handleBlockchainErrors } =
        useBlockchainErrorsHandler();

    const maxPrice = useMemo(
        () => balanceFromWei(config?.marketplaceMaxPrice).value,
        [config?.marketplaceMaxPrice]
    );

    const minPrice = useMemo(
        () => balanceFromWei(config?.marketplaceMinPrice).value,
        [config?.marketplaceMinPrice]
    );

    useEffect(() => {
        if (!pet) {
            navigation.pop();
        }
    }, [pet]);

    const schema = Yup.object({
        amount: Yup.string()
            .transform((_, value) => {
                if (value && value.includes('.')) {
                    return value;
                }
                return value && value.replace(/,/, '.');
            })

            .matches(
                /^\d*(\.\d+)?$/,
                i18n.t(
                    'wallet.gaming.TopUpGamingWalletScreen.inputSection.errors.digits'
                )
            )
            .required(i18n.t('withdrawScreen.errors.amountRequired'))
            .test(
                'maxAmount',
                i18n.t(
                    'wallet.gaming.TopUpGamingWalletScreen.inputSection.errors.max',
                    {
                        limit: maxPrice,
                        coin: Coin.igu,
                    }
                ),
                (value) => Number(value) <= Number(maxPrice)
            )
            .test(
                'minAmount',
                i18n.t(
                    'wallet.gaming.TopUpGamingWalletScreen.inputSection.errors.min',
                    {
                        limit: minPrice,
                        coin: Coin.igu,
                    }
                ),
                (value) => Number(value) >= Number(minPrice)
            ),
    });

    const { isVisible, open, close } = useVisibleHook();

    const { renderFeeInfoIcon } = useFeeInfo(close, open);

    const { submitForm, values, errors, setFieldValue, setTouched, isValid } =
        useFormik({
            initialValues: {
                amount: '',
            },
            validationSchema: schema,
            onSubmit: () => {
                open();
            },
            enableReinitialize: true,
        });

    const handleChangeAmount = async (amount: string) => {
        setTouched({ amount: false });

        const convertedText = inputNumberFormatter(amount);
        if (!isBiggerThanMax(convertedText)) {
            await setFieldValue('amount', convertedText);
        }
    };

    const handleOnBlur = () => {
        setTouched({ amount: true });
    };

    const handleOnBack = () => {
        navigation.pop();
    };
    const {
        walletData,
        approvalNftsForMarketplace,
        isNFTApprovedForMarketplace,
        walletBalance,
        fee,
        priceInUsd,
    } = useWallet();

    const { isLoading, startLoading, stopLoading } = useLoadingHook();
    const [signOrder] = useMutation<DataToSingOutput>(
        MARKETPLACE_ORDER_DATA_TO_SIGN
    );
    const [createOrder] = useMutation(MARKETPLACE_CREATE_ORDER);

    const [isApproved, setIsApproved] = useState<boolean>(false);

    const {
        isLoading: loadingCheckApprove,
        startLoading: startLoadingCheckApprove,
        stopLoading: stopLoadingCheckApprove,
    } = useLoadingHook();

    useEffect(() => {
        const check = async () => {
            try {
                startLoadingCheckApprove();
                const result = await isNFTApprovedForMarketplace();
                setIsApproved(result);
                stopLoadingCheckApprove();
            } catch (error) {
                stopLoadingCheckApprove();
                errorsHandler(error, true);
            }
        };
        check();
    }, []);

    const commissionFee = useMemo(() => {
        if (!config?.marketplaceTransactionFeePercentage) {
            return '0';
        }

        return (
            Number(values.amount) *
            (config.marketplaceTransactionFeePercentage / 100)
        ).toString();
    }, [config?.iguWalletWithdrawFeePercentage, values.amount]);

    const totalSummary = useMemo(() => {
        return (Number(values.amount) - Number(commissionFee)).toString();
    }, [values, commissionFee]);

    const confirm = useCallback(async () => {
        try {
            startLoading();
            let transactionResult = null;
            if (!isApproved) {
                transactionResult = await approvalNftsForMarketplace();
                await wait(5000);
            }
            const signResult = await signOrder({
                variables: {
                    petId: pet.id,
                    iguPriceWei: valueToWei(values.amount),
                },
            });
            if (signResult.data?.marketplaceOrderDataToSign) {
                const {
                    domainDataStringified,
                    typesStringified,
                    valuesStringified,
                } = signResult.data.marketplaceOrderDataToSign;
                const domain: TypedDataDomain = JSON.parse(
                    domainDataStringified
                );
                const types: Record<string, TypedDataField[]> =
                    JSON.parse(typesStringified);
                const signatureValues: Record<string, any> =
                    JSON.parse(valuesStringified);
                const signature = await walletData?.wallet._signTypedData(
                    domain,
                    types,
                    signatureValues
                );
                await createOrder({
                    variables: {
                        petId: pet.id,
                        signedData: {
                            signature,
                            valuesStringified:
                                signResult.data?.marketplaceOrderDataToSign
                                    .valuesStringified,
                        },
                    },
                });
                navigation.popToTop();
                navigation.navigate(ROUTES.MARKETPLACE_CREATE_ORDER_SUCCESS, {
                    pet,
                    price: totalSummary,
                    commission: commissionFee.toString(),
                    transactionResult,
                });
                stopLoading();
            }
            stopLoading();
        } catch (error) {
            stopLoading();
            if (!handleBlockchainErrors(error)) {
                errorsHandler(error, true);
            }
        }
    }, [values, pet, walletData, isApproved, commissionFee, totalSummary]);

    const estimatedFee = useMemo(
        () => (!isApproved ? fee?.executeTransaction.BNB : undefined),
        [fee, isApproved]
    );
    const isBNBEnough = useMemo(() => {
        return !isApproved
            ? Number(walletBalance?.bnb.value) >= Number(estimatedFee)
            : true;
    }, [estimatedFee, walletBalance?.bnb, isApproved]);

    const usdPrice = useMemo(
        () => toStringUSD(priceInUsd(Coin.igu, values.amount)),
        [priceInUsd, values]
    );

    return {
        handleOnBack,
        values,
        handleOnBlur,
        handleChangeAmount,
        errors,
        submitForm,
        isValid,
        close,
        isVisible,
        confirm,
        isLoading,
        loadingCheckApprove,
        renderBlockchainErrorsModal,
        estimatedFee,
        isBNBEnough,
        renderFeeInfoIcon,
        totalSummary,
        commissionFee,
        usdPrice,
    };
};
