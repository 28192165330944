import * as React from 'react';
import { LayoutRectangle, TouchableOpacity } from 'react-native';

import Ticket from '@assets/icons/mysteryGames/LuckyStrike/ticket.svg';
import Icon, { ICON_NAMES } from '@components/Icons';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT } from '@components/TypoText/types';
import { useSaveHeight } from '@hooks/useSaveHeight';
import useThemedStyles from '@hooks/useThemedStyles';
import { useTouchableOpacityDebounce } from '@hooks/useTouchableOpacityDebounce';
import { LuckyStrikeUserTicketOutput } from '@models/mysteryGames';

import stylesMain from './styles';

interface IProps {
    item: string | LuckyStrikeUserTicketOutput;
    fromWait?: boolean;
    setHeight: (height: number) => void;
    height: number;
    onPress?: () => void;
}

const LuckyStrikeTicketItem = ({
    item,
    fromWait,
    onPress,
    height,
    setHeight,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const typeGuard = (
        item: string | LuckyStrikeUserTicketOutput
    ): item is LuckyStrikeUserTicketOutput => {
        return !!(item as LuckyStrikeUserTicketOutput).number;
    };

    const isEmptyElement = React.useMemo(() => !typeGuard(item), [item]);

    const ticketNumber = React.useMemo(() => {
        if (typeGuard(item)) {
            return `#${item.number}`;
        }
        return null;
    }, [item]);
    const ticketStatus = React.useMemo(() => {
        if (typeGuard(item)) {
            if (fromWait) {
                return 'WAIT';
            }

            return item.isWon ? 'WON' : 'LOSE';
        }
        return 'WAIT';
    }, [item, fromWait]);

    const handleSetSize = React.useCallback(
        (layout: LayoutRectangle) => {
            if (isEmptyElement) {
                return;
            }
            setHeight(layout.height);
        },
        [isEmptyElement]
    );

    const { handleDebounce } = useTouchableOpacityDebounce(onPress);

    return (
        <TouchableOpacity
            onLayout={({ nativeEvent }) => handleSetSize(nativeEvent.layout)}
            activeOpacity={0.7}
            disabled={!isEmptyElement}
            style={[
                styles.container,
                styles[`${ticketStatus}block`],
                isEmptyElement && { height },
            ]}
            onPress={handleDebounce}>
            {isEmptyElement ? (
                <Icon
                    name={ICON_NAMES.PLUS}
                    size={20}
                    color={styles.plus.color}
                />
            ) : !!ticketStatus && !!ticketNumber ? (
                <View style={styles.ticketWrapper}>
                    <Ticket width={88} height={96} />
                    <View style={[styles.ticketCode, styles[ticketStatus]]}>
                        <TypoText
                            numberOfLines={1}
                            type={TYPO_TEXT.SUBHEAD_LITTLE_BOLD}
                            additionalStyles={styles[`${ticketStatus}text`]}>
                            {ticketNumber}
                        </TypoText>
                    </View>
                </View>
            ) : null}
        </TouchableOpacity>
    );
};
export default LuckyStrikeTicketItem;
