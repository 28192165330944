import * as React from 'react';

import StepFiveImage from '@assets/icons/onBoarding/login/stepFive.svg';
import StepFourImage from '@assets/icons/onBoarding/login/stepFour.svg';
import StepOneImage from '@assets/icons/onBoarding/login/stepOne.svg';
import StepThreeImage from '@assets/icons/onBoarding/login/stepThree.svg';
import StepTwoImage from '@assets/icons/onBoarding/login/stepTwo.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonKnowledgeBase from '@components/ButtonKnowledgeBase';
import ButtonsBottom from '@components/ButtonsBottom';
import Carousel from '@components/Carousel';
import CarouselScrollView from '@components/Carousel/components/CarouselScrollView';
import { View } from '@components/Themed';
import { ONBOARDING_KEY } from '@contexts/AppStateContext';
import { useDimensions } from '@contexts/DimensionsContext';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import useNavigationListeners from '@navigation/hooks/useNavigationListeners';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import AsyncStorage from '@react-native-async-storage/async-storage';

import stylesMain from './styles';

const data = ['stepOne', 'stepTwo', 'stepThree', 'stepFour', 'stepFive'];
const colors = ['#9683FC', '#F08975', '#8099FF', '#6BC79B', '#D5A881'];
const flow = 'login';

const LoginOnBoardingScreen = ({}) => {
    useNavigationListeners();

    const { windowWidth } = useDimensions();
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const getStarted = async () => {
        await AsyncStorage.setItem(ONBOARDING_KEY, 'show');
        navigation.replace(ROUTES.LOGIN);
    };

    const [backgroundColor, setBackgroundColor] = React.useState<string>(
        colors[0]
    );

    const onIndexChange = (index: number) => {
        setBackgroundColor(colors[index]);
    };

    const renderItem = (item: string) => {
        const icon = () => {
            switch (item) {
                case 'stepOne':
                    return <StepOneImage />;
                case 'stepTwo':
                    return <StepTwoImage />;
                case 'stepThree':
                    return <StepThreeImage />;
                case 'stepFour':
                    return <StepFourImage />;
                default:
                    return <StepFiveImage />;
            }
        };

        const block = () => {
            return (
                <BlockHeader
                    emptyStateIcon={icon()}
                    titleStyle={styles.title}
                    textStyle={styles.text}
                    title={i18n.t(`onBoarding.${flow}.${item}.title`)}
                    text={i18n.t(`onBoarding.${flow}.${item}.text`)}
                    type="emptyState"
                />
            );
        };

        return (
            <CarouselScrollView
                style={styles.spaceTop}
                contentContainerStyle={styles.justifyContent}>
                <View style={styles.itemContainer}>
                    <View style={styles.content}>{block()}</View>
                </View>
            </CarouselScrollView>
        );
    };

    return (
        <View style={[styles.main, { backgroundColor }]}>
            <Carousel
                data={data}
                sliderWidth={windowWidth}
                itemWidth={windowWidth}
                renderItem={renderItem}
                fullHeight
                pagination
                centredVertical
                bulletActiveStyle={styles.bulletActiveStyle}
                bulletStyle={styles.bulletStyle}
                onIndexChange={onIndexChange}
            />
            <ButtonsBottom
                title={i18n.t('general.BUTTONS.getStarted')}
                isOneColor
                onPress={getStarted}
                buttonType="outline"
                containerStyle={styles.buttons}
                safe>
                <ButtonKnowledgeBase
                    customStyles={styles.button}
                    iconColor={styles.buttonColor.color}
                    textColor={styles.buttonColor.color}
                />
            </ButtonsBottom>
        </View>
    );
};

export default LoginOnBoardingScreen;
