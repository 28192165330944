const cryIguana = require('../../assets/icons/iguana/cryIguana.png');
const attentionIguana = require('../../assets/icons/iguana/attentionIguana.png');
const happyIguana = require('../../assets/icons/iguana/happyIguana.png');
const redXIguana = require('../../assets/icons/iguana/redXIguana.png');
const successIguana = require('../../assets/icons/iguana/successIguana.png');

const images = {
    cry: {
        source: cryIguana,
        size: {
            big: { width: 233, height: 186 },
            small: { width: 141, height: 112 },
        },
    },
    attention: {
        source: attentionIguana,
        size: {
            big: {},
            small: { width: 135, height: 112 },
        },
    },
    happy: {
        source: happyIguana,
        size: {
            big: {},
            small: { width: 142, height: 193 },
        },
    },
    redX: {
        source: redXIguana,
        size: {
            big: {},
            small: { width: 113, height: 112 },
        },
    },
    success: {
        source: successIguana,
        size: {
            big: {},
            small: { width: 233, height: 172 },
        },
    },
};

export default images;
