import * as React from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';

import CopyToClipboardButton from '@components/CopyToClipboardButton';
import Icon, { ICON_NAMES } from '@components/Icons';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT, TYPO_TEXT_COLOR } from '@components/TypoText/types';
import { openUrlOrShowError } from '@helpers/linking';
import usePreventDoubleClick from '@hooks/usePreventDoubleClick';
import useThemedStyles from '@hooks/useThemedStyles';

import stylesMain from './styles';

interface IProps {
    containerStyle?: StyleProp<ViewStyle>;
    icon: React.ReactNode;
    title: string;
    text: string | null;
    copyText?: string | null;
    link?: string | null;
}

const QuantumSpinInfoItem = ({
    containerStyle,
    icon,
    title,
    text,
    copyText,
    link,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const isLink = React.useMemo(() => !!link, [link]);

    const linkPress = React.useCallback(async () => {
        if (!link) return;
        return openUrlOrShowError(link);
    }, [link]);

    const { onClick: onLinkPress } = usePreventDoubleClick(linkPress);

    const buttonActiveOpacity = React.useMemo(() => (isLink ? 0.8 : 1), [link]);

    return (
        <View style={[styles.container, containerStyle]}>
            <View style={styles.itemLeft}>
                <View style={styles.icon}>{icon}</View>
                <TypoText type={TYPO_TEXT.SUBHEAD_SMALL_SEMIBOLD}>
                    {title}
                </TypoText>
            </View>
            {!!text && (
                <>
                    {!isLink && (
                        <View style={styles.itemRight}>
                            <TypoText
                                numberOfLines={1}
                                type={TYPO_TEXT.SUBHEAD_SMALL_BOLD}
                                colorType={
                                    isLink
                                        ? TYPO_TEXT_COLOR.ACCENT
                                        : TYPO_TEXT_COLOR.PRIMARY
                                }
                                additionalStyles={styles.text}>
                                {text}
                            </TypoText>
                            {!!copyText && (
                                <View style={styles.additionalIcon}>
                                    <CopyToClipboardButton value={copyText} />
                                </View>
                            )}
                        </View>
                    )}
                    {isLink && (
                        <TouchableOpacity
                            disabled={!isLink}
                            onPress={onLinkPress}
                            style={styles.itemRight}
                            activeOpacity={buttonActiveOpacity}>
                            <TypoText
                                numberOfLines={1}
                                type={TYPO_TEXT.SUBHEAD_SMALL_BOLD}
                                colorType={
                                    isLink
                                        ? TYPO_TEXT_COLOR.ACCENT
                                        : TYPO_TEXT_COLOR.PRIMARY
                                }
                                additionalStyles={styles.text}>
                                {text}
                            </TypoText>
                            <View style={styles.additionalIcon}>
                                <Icon
                                    name={ICON_NAMES.EXPLORE}
                                    color={styles.link.color}
                                    size={20}
                                />
                            </View>
                        </TouchableOpacity>
                    )}
                </>
            )}
        </View>
    );
};
export default QuantumSpinInfoItem;
