import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { moderateScale, scale, verticalScale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { stylesAlignCenter, stylesOverlayBlock } from '../../helpers/style';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        restoreIcon: {
            color: Colors[theme].icon.onDarkSecondary,
        },
        paddings: {
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(8),
        },
        borderPaddings: {
            paddingLeft: scale(16),
            paddingRight: scale(16),
            paddingVertical: verticalScale(8),
        },
        itemContainer: {
            backgroundColor: Colors[theme].background,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        itemContainerWithBorder: {
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            paddingVertical: verticalScale(16),
            marginBottom: verticalScale(4),
        },
        petName: {
            flex: 1,
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '600',
            }),
            marginLeft: scale(6),
        },
        textWrapper: {
            backgroundColor: 'transparent',
            marginLeft: verticalScale(14),
            flex: 1,
        },
        imageWrapper: {
            width: scale(64),
            height: scale(64),
        },
        image: {
            width: '100%',
            height: '100%',
            borderRadius: 6,
        },
        titleWrapper: {
            backgroundColor: 'transparent',
            flexDirection: 'row',
            marginBottom: verticalScale(6),
            alignItems: 'center',
        },
        leftWrapper: {
            backgroundColor: 'transparent',
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
        },
        rightWrapper: {
            paddingLeft: scale(16),
            paddingRight: scale(16),
            height: '100%',
            justifyContent: 'center',
        },
        health: {
            backgroundColor: 'transparent',
            marginTop: verticalScale(4),
        },
        overlayButton: {
            position: 'absolute',
            width: scale(64),
            height: scale(64),
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
        },
        opacityBlock: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            opacity: 0.6,
            backgroundColor: Colors[theme].background,
            borderRadius: moderateScale(6),
        },
        explorerUrl: {
            marginTop: verticalScale(5),
            color: Colors[theme].text.secondary,
            ...font({
                size: 12,
                height: 16,
                weight: '700',
            }),
        },
        healthTag: {
            marginTop: verticalScale(5),
        },
        indicator: {
            borderRadius: 1000,
            height: 30,
            width: 30,
            backgroundColor: Colors[theme].buttonBackgroundOutline,
            borderColor: Colors[theme].buttonBorderOutline,
            justifyContent: 'center',
        },
    });

export default styles;
