import { StakingPlanOutput, UserStake } from '@Data/Models';

export const isPlanDisabled = (
    plan: StakingPlanOutput,
    currentPlan: UserStake | null
) => {
    return !plan.available || plan.plan.id === currentPlan?.stakingPlanId;
};

export const getStakingPlanName = (
    currentStakingPlanId: string,
    plans: StakingPlanOutput[]
) => {
    return (
        plans.find((i) => i.plan.id === currentStakingPlanId)?.plan.name || ''
    );
};

export const getStakingPlanColor = (
    currentStakingPlanId: string,
    plans: StakingPlanOutput[]
) => {
    return (
        plans.find((i) => i.plan.id === currentStakingPlanId)?.plan.color || ''
    );
};

export const getCurrentPlan = (
    currentStakingPlanId: string,
    plans: StakingPlanOutput[]
) => {
    return plans.find((i) => i.plan.id === currentStakingPlanId);
};

export const disableToUpgrade = (
    stakingPlanId: string,
    plans: StakingPlanOutput[]
) => {
    const availablePlans = plans.filter((i) => i.available);
    return !availablePlans.find((i) => i.plan.id !== stakingPlanId);
};
