import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            backgroundColor: Colors[theme].maxBoost.background,
            borderRadius: moderateScale(50),
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(8),
            flexDirection: 'row',
            alignItems: 'center',
            marginHorizontal: scale(20),
        },
        text: {
            textAlign: 'center',
            backgroundColor: 'transparent',
            color: Colors[theme].maxBoost.text,
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),
            marginRight: scale(4),
        },
        flex: {
            flex: 1,
        },
        modal: {
            paddingHorizontal: scale(20),
            paddingTop: verticalScale(32),
        },
        scrollGap: {
            paddingBottom: verticalScale(60),
        },

        blockHeader: {
            marginBottom: verticalScale(8),
        },
        cardFeatureInfo: {
            marginTop: verticalScale(12),
        },
    });

export default styles;
