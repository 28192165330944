import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';

import { theme } from '../../../../../types';

const stylesMain = (theme: theme) =>
    StyleSheet.create({
        container: {
            position: 'absolute',
            alignItems: 'center',
            paddingTop: 20,
            paddingBottom: 60,
            transform: [{ translateY: 60 }],
            backgroundColor: Colors[theme].background,
            width: '100%',
        },
    });

export default stylesMain;
