import { StyleSheet } from 'react-native';

import { stylesAbsoluteFullImage } from '../../../helpers/style';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        bgImage: {
            ...stylesAbsoluteFullImage(),
        },
    });

export default styles;
