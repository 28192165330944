import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(16),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
            alignItems: 'center',
        },
        imageWrapper: {
            marginBottom: verticalScale(8),
        },
        amount: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
            marginBottom: verticalScale(2),
        },
        usdPrice: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
            marginBottom: verticalScale(2),
        },
        title: {
            textAlign: 'center',
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
        },
    });

export default styles;
