import * as React from 'react';
import { SafeAreaView } from 'react-native';

import Button from '@components/Button';
import ImageSlider from '@components/ImageSlider';
import { verifyToEarn } from '@components/ImageSlider/data';
import { View } from '@components/Themed';
import { PlayContext } from '@contexts/PlayContext';
import useVerifyTask from '@hooks/tasks/useVerifyTask';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';
import { useNavigation } from '@navigation/useNavigation';

import stylesMain from './styles';

export const WelcomeVerifyScreen = () => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const { verifyTask } = React.useContext(PlayContext);

    const { verifyActionStatusButton, createVerificationTaskLoading } =
        useVerifyTask();

    const onBack = () => {
        navigation.pop();
    };

    return (
        <>
            <NavigationBar
                backIcon="close"
                containerStyle={styles.navBg}
                onBack={onBack}
            />

            <ImageSlider
                data={verifyToEarn}
                customEnergy={verifyTask?.maxEnergyReward}
            />

            <View style={styles.buttonWrapper}>
                <Button
                    title={i18n.t(
                        'socializeToEarn.welcomeSlidesVerify.startVerify'
                    )}
                    onPress={verifyActionStatusButton}
                    debouncedPress
                    loading={createVerificationTaskLoading}
                    loadingText={i18n.t(
                        'socializeToEarn.verifyUser.loadingVerifyUser'
                    )}
                />
            </View>
            <SafeAreaView style={styles.safe} />
        </>
    );
};
export default WelcomeVerifyScreen;
