import { StyleSheet } from 'react-native';

import Colors from '../../../../../../constants/Colors';
import { moderateScale, scale } from '../../../../../../helpers/dimensions';
import font from '../../../../../../helpers/font';
import { theme } from '../../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            marginBottom: scale(12),
            backgroundColor: Colors[theme].background,
        },
        message: {
            ...font({
                weight: '400',
                size: 13,
                height: 18,
            }),
            margin: scale(20),
            padding: scale(12),
            borderRadius: 8,
            overflow: 'hidden',
            color: Colors[theme].text.warning,
            backgroundColor: Colors[theme].backgroundWarning,
        },
        btnsContainer: {
            flexDirection: 'row',
            justifyContent: 'center',
            marginBottom: scale(12),
            backgroundColor: Colors[theme].background,
        },
        divider: {
            width: scale(12),
        },
        button: {
            width: 64,
            height: 64,
            borderRadius: 32,
        },
        icon: {
            color: Colors[theme].icon.onDarkSecondary,
        },
    });

export default styles;
