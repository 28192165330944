import { StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import { scale, verticalScale } from '../../../../helpers/dimensions';
import { stylesModalNavigationFix } from '../../../../helpers/style';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: Colors[theme].background,
            ...stylesModalNavigationFix(),
        },
        contentContainer: {
            paddingHorizontal: scale(20),
        },
        paddingBottom: {
            paddingBottom: 34,
        },
    });

export default styles;
