import * as React from 'react';
import { or } from 'react-native-reanimated';

import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import {
    MarketplaceNftDetailsOrderOutput,
    MarketplaceNftDetailsPetOutput,
    MarketplaceNftDetailsSellerOutput,
} from '@models/marketplace';
import ROUTES from '@navigation/routes';

import { useNavigation } from '../../useNavigation';
import NavigationItem from '../NavigationItem';
import stylesMain from './styles';

interface IProps {
    currentNavigation: NAVIGATION_MARKETPLACE_OVERVIEW;
    petId?: string;
    pet?: MarketplaceNftDetailsPetOutput;
    order?: MarketplaceNftDetailsOrderOutput;
    isOwner?: boolean;
    cancelTransactionValuesStringified: string | null;
    owner: MarketplaceNftDetailsSellerOutput | undefined;
}

export enum NAVIGATION_MARKETPLACE_OVERVIEW {
    OVERVIEW = 'OVERVIEW',
    OWNER_HISTORY = 'OWNER_HISTORY',
}

const data: NAVIGATION_MARKETPLACE_OVERVIEW[] = [
    NAVIGATION_MARKETPLACE_OVERVIEW.OVERVIEW,
    NAVIGATION_MARKETPLACE_OVERVIEW.OWNER_HISTORY,
];

const NavigationMarketPlaceOverview = ({
    currentNavigation,
    petId,
    pet,
    order,
    isOwner,
    cancelTransactionValuesStringified,
    owner,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const handleNavigation = (
        item: NAVIGATION_MARKETPLACE_OVERVIEW,
        petId: string | undefined,
        pet: MarketplaceNftDetailsPetOutput | undefined,
        order: MarketplaceNftDetailsOrderOutput | undefined,
        cancelTransactionValuesStringified: string | null,
        owner: MarketplaceNftDetailsSellerOutput | undefined,
        isOwner?: boolean
    ) => {
        switch (item) {
            case NAVIGATION_MARKETPLACE_OVERVIEW.OVERVIEW:
                petId &&
                    navigation.navigate(ROUTES.MARKETPLACE_OVERVIEW, {
                        petId,
                        cancelTransactionValuesStringified,
                    });
                break;
            case NAVIGATION_MARKETPLACE_OVERVIEW.OWNER_HISTORY:
                pet &&
                    order &&
                    owner &&
                    navigation.navigate(
                        ROUTES.MARKETPLACE_OVERVIEW_OWNER_HISTORY,
                        {
                            pet,
                            order,
                            isOwner,
                            cancelTransactionValuesStringified,
                            owner,
                        }
                    );
                break;
        }
    };

    return (
        <View style={styles.main}>
            <View style={styles.shadow}>
                <View style={styles.container}>
                    {data.map((item) => (
                        <NavigationItem
                            key={item}
                            onPress={() =>
                                handleNavigation(
                                    item,
                                    petId,
                                    pet,
                                    order,
                                    cancelTransactionValuesStringified,
                                    owner,
                                    isOwner
                                )
                            }
                            title={i18n.t(
                                `MarketPlace.navigationBarOverview.${item}`
                            )}
                            isActive={currentNavigation === item}
                            containerStyles={styles.item}
                        />
                    ))}
                </View>
            </View>
        </View>
    );
};
export default NavigationMarketPlaceOverview;
