import * as React from 'react';
import { useMemo } from 'react';

import { UserRole } from '@Data/Models';
import SelectorV2 from '@components/SelectorV2';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';

import RoleSelectModal from '../RoleSelectModal';

interface IProps {
    error: string | undefined;
    selectedRole: UserRole | undefined;
    roles: UserRole[] | undefined;
    onPress: (role: UserRole) => void;
    loading?: boolean;
}

const RoleSelect = ({
    error,
    onPress,
    selectedRole,
    roles,
    loading,
}: IProps) => {
    const { isVisible, open, close } = useVisibleHook();

    const filterRoles = useMemo(
        () => roles?.filter((i) => i !== 'ADMIN'),
        [roles]
    );

    return (
        <SelectorV2
            loading={loading}
            label={i18n.t('profile.adminAssignRole.role.label')}
            onPress={open}
            selectedItem={selectedRole?.toString()}
            placeHolder={i18n.t('profile.adminAssignRole.role.placeHolder')}
            error={error}
            modal={
                <>
                    {!!filterRoles && (
                        <RoleSelectModal
                            isVisible={isVisible}
                            close={close}
                            selectedRole={selectedRole}
                            roles={filterRoles}
                            onPress={onPress}
                            modalHeight="70%"
                        />
                    )}
                </>
            }
        />
    );
};

export default RoleSelect;
