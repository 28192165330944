import * as React from 'react';

import Button from '../../../../components/Button';
import { ICON_NAMES } from '../../../../components/Icons';
import { Text, View } from '../../../../components/Themed';
import { isDesktop } from '../../../../helpers/app';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import stylesMain from './styles';

interface IProps {
    openCamera: () => void;
    pickFromGallery: () => void;
}

const MintPickPhotoButtons = ({ openCamera, pickFromGallery }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <>
            <View style={styles.openCamera}>
                <Button
                    title={
                        isDesktop
                            ? i18n.t('mintPickPhoto.selectFromGallery')
                            : i18n.t('mintPickPhoto.takePhoto')
                    }
                    iconName={ICON_NAMES.CAMERA}
                    iconColor={styles.icon.color}
                    onPress={openCamera}
                />
            </View>
            {!isDesktop && (
                <>
                    <Text style={styles.or}> {i18n.t('mintPickPhoto.or')}</Text>
                    <View style={styles.pickFromGallery}>
                        <Button
                            onPress={pickFromGallery}
                            title={i18n.t('mintPickPhoto.selectFromGallery')}
                            type="text"
                            size="lg"
                        />
                    </View>
                </>
            )}
        </>
    );
};
export default MintPickPhotoButtons;
