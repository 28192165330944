import { StyleSheet } from 'react-native';

import { relative } from 'path/posix';

import Colors from '../../../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const inputWidth = 20;
const marginInput = 1;
const inputCount = 3;

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flexDirection: 'column',
        },
        title: {
            ...font({
                size: 18,
                height: 24,
                weight: '700',
            }),
            color: Colors[theme].text.primary,
        },
        mainText: {
            marginTop: verticalScale(4),
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
            color: Colors[theme].text.secondary,
        },
        button: {
            marginTop: verticalScale(20),
        },
        inputsBox: {
            marginTop: verticalScale(20),
        },
        inputsContainer: {
            flexDirection: 'row',
            position: 'relative',
        },
        inputButton: {
            height: verticalScale(40),
        },
        inputButtonContainer: {
            backgroundColor: Colors[theme].backgroundPrimary,
            width: `${inputWidth}%`,
            marginHorizontal: `${marginInput}%`,
            marginTop: verticalScale(5),
        },
        inputButtonContainerFocus: {
            backgroundColor: Colors[theme].inputActiveBorder,
            borderColor: Colors[theme].inputActiveBorder,
        },
        inputButtonFocus: {
            borderColor: Colors[theme].inputActiveBorder,
        },
        textInputContainer: {
            width: `${
                100 -
                inputWidth * inputCount -
                marginInput * ((inputCount + 1) * 2)
            }%`,
            marginHorizontal: `${marginInput}%`,
        },
        textInput: {
            position: 'relative',
            height: verticalScale(40),
            paddingRight: scale(10),
            ...font({
                size: 13,
                weight: '400',
                height: 18,
            }),
        },
        textInputActive: {
            borderColor: Colors[theme].inputActiveBorder,
        },
        textWarning: {
            ...font({
                size: 13,
                weight: '400',
                height: 18,
            }),
            marginTop: verticalScale(8),
            color: Colors[theme].text.danger,
        },
        clearInputButton: {
            position: 'absolute',
            zIndex: 2,
            right: scale(20),
            width: scale(10),
            top: verticalScale(8),
            height: verticalScale(10),
            backgroundColor: 'transparent',
        },
        clearInputButtonIcon: {
            backgroundColor: 'transparent',
            borderWidth: 0,
        },
    });

export default styles;
