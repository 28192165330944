import * as React from 'react';

import {
    CreateAgeRevivePaymentResponse,
    PaymentOptionOutput,
    Pet,
} from '@Data/Models';
import { CREATE_AGE_REVIVE_PAYMENT } from '@Data/Requests';
import { useMutation } from '@apollo/client';
import CheckoutScreen from '@components/CheckoutScreen';
import { PlayContext } from '@contexts/PlayContext';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { isGraphqlError } from '@helpers/helpers';
import { toastError } from '@helpers/toastNotification';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import { CollectionPetAgeReviveCheckoutProps } from '../../../../types';
import TransactionDetailsAgeRevive from '../components/TransactionDetailsAgeRevive';

const CollectionAgeReviveCheckoutScreen = ({
    route,
}: CollectionPetAgeReviveCheckoutProps) => {
    const { executeTransaction } = useWallet();
    const navigation = useNavigation();

    const { refetchPets } = React.useContext(PlayContext);

    const { pet, payments, navigateBackOnUpgrade, navigateBackFromCheckout } =
        route.params;
    const [createPaymentRequest] = useMutation<CreateAgeRevivePaymentResponse>(
        CREATE_AGE_REVIVE_PAYMENT
    );

    const createPayment = async (pet: Pet, payment: PaymentOptionOutput) => {
        const result = await createPaymentRequest({
            variables: {
                petId: pet.id,
                token: payment.token,
            },
        });
        return result.data?.createAgeRevivePayment;
    };

    const handleOnPurchase = async (payment: PaymentOptionOutput) => {
        const paymentResponse = await createPayment(pet, payment);
        if (!paymentResponse) {
            return null;
        }
        const result = await executeTransaction(
            paymentResponse.payment,
            paymentResponse.signedTransaction
        );

        await refetchPets(true);

        navigation.navigate(ROUTES.COLLECTION_PET_AGE_REVIVE_SUCCESS, {
            pet,
            transactionResult: result,
            payment: paymentResponse.payment,
            navigateBackOnUpgrade,
        });

        return result;
    };

    const handleOnError = (error: any) => {
        if (isGraphqlError(error, 'PET_WRONG_STATE')) {
            toastError(
                undefined,
                i18n.t('detailedPet.age.checkoutAgeRevive.errors.wrongState')
            );
            refetchPets(true);
        }
    };

    const navigateBack = React.useCallback(() => {
        if (navigateBackFromCheckout) {
            navigation.pop();
            navigation.navigate(navigateBackFromCheckout);
            return;
        }
        navigation.goBack();
    }, [navigateBackFromCheckout]);

    return (
        <>
            <NavigationBar backIcon wallet filled onBack={navigateBack} />
            <CheckoutScreen
                title={i18n.t('detailedPet.age.checkoutAgeRevive.title')}
                text={i18n.t('detailedPet.age.checkoutAgeRevive.text')}
                payments={payments}
                TransactionDetailsComponent={
                    <TransactionDetailsAgeRevive pet={pet} healthUp={100} />
                }
                withConfirmation={i18n.t(
                    'detailedPet.age.checkoutAgeRevive.transaction',
                    {
                        name: pet.name,
                    }
                )}
                onPurchase={handleOnPurchase}
                onPurchaseError={handleOnError}
            />
        </>
    );
};

export default CollectionAgeReviveCheckoutScreen;
