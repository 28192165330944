import * as React from 'react';

import EmptyStateTextAnimated from '@components/EmptyStateTextAnimated';
import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    isLoading: boolean;
    type?: 'prepare' | 'complete';
}

const MysteryLikesLoadingScreen = ({ isLoading, type = 'prepare' }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={styles.container}>
            <EmptyStateTextAnimated
                animationName="joinGame"
                isLoading={isLoading}
                loadingTitle={'...'}
                staticTitle={i18n.t(
                    `HomeScreen.MysteryLikes.MysteryLikesLoadingScreen.${type}.title`
                )}
                text={i18n.t(
                    `HomeScreen.MysteryLikes.MysteryLikesLoadingScreen.${type}.text`
                )}
                backgroundColor={styles.container.backgroundColor}
            />
        </View>
    );
};

export default MysteryLikesLoadingScreen;
