import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import { scale, verticalScale } from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        safe: {
            backgroundColor: Colors[theme].background,
            flex: 1,
        },
        main: {
            flex: 1,
            paddingHorizontal: scale(20),
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
        },

        buttonWrapper: {
            paddingVertical: verticalScale(16),
            paddingHorizontal: scale(20),
        },
        gap: {
            marginBottom: verticalScale(16),
        },
    });

export default styles;
