import * as React from 'react';
import { SafeAreaView } from 'react-native';

import _, { compact } from 'lodash';

import { useMutation } from '@apollo/client';

import { Pet, SetPetOrderedIdsResponse } from '../../../../Data/Models';
import { SET_PET_ORDERED_IDS } from '../../../../Data/Requests';
import AlertCard from '../../../../components/AlertCard';
import Button from '../../../../components/Button';
import CustomModal from '../../../../components/CustomModal';
import { ICON_NAMES } from '../../../../components/Icons';
import ImageIguana from '../../../../components/ImageIguana';
import ModalLoader from '../../../../components/ModalLoader';
import { Text, View } from '../../../../components/Themed';
import ToastNotification from '../../../../components/ToastNotification';
import { ConfigContext } from '../../../../contexts/ConfigContext';
import { usePlay } from '../../../../contexts/PlayContext';
import { useUser } from '../../../../contexts/UserContext';
import {
    waitForNavigation,
    waitForNotification,
} from '../../../../helpers/helpers';
import {
    toastErrorUnknown,
    toastSuccess,
} from '../../../../helpers/toastNotification';
import { useHaptic } from '../../../../hooks/useHaptic';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import NavigationBarModal from '../../../../navigation/NavigationBar/NavigationBarModal';
import { useNavigation } from '../../../../navigation/useNavigation';
import DraggablePetList from '../components/DraggablePetList';
import EditPetsList from '../components/EditPetsList';
import stylesMain from './styles';

const EditNFTSScreen = () => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const { pets, petsLoading } = usePlay();
    const { user, reloadUser } = useUser();
    const { config } = React.useContext(ConfigContext);
    const [modalSaveChanges, setModalSaveChangesVisible] =
        React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const { triggerHeavy } = useHaptic();

    navigation.blockHardwareBack();

    const [request] =
        useMutation<SetPetOrderedIdsResponse>(SET_PET_ORDERED_IDS);

    const [tmpOrderedIds, setTmpOrderedIds] = React.useState<
        string[] | undefined
    >();

    const checkPets = (
        arr1: string[] | undefined,
        arr2: string[] | undefined
    ) => {
        return _.intersection(arr1, arr2);
    };

    React.useEffect(() => {
        if (user && pets) {
            setTmpOrderedIds(
                checkPets(
                    user.petOrderedIds,
                    pets.map((o) => o.id)
                )
            );
        }
    }, [user, pets]);

    const isEqualIds = React.useMemo(
        () => _.isEqual(tmpOrderedIds, user?.petOrderedIds),
        [tmpOrderedIds, user?.petOrderedIds]
    );

    React.useEffect(() => {
        navigation.setOptions({
            gestureEnabled: isEqualIds,
        });
    }, [isEqualIds]);

    const maxNFTs = React.useMemo(() => config?.maxActiveNFTsPerUser, [config]);

    const addToTmpOrderedIds = React.useCallback(
        (id: string) => {
            if (tmpOrderedIds && maxNFTs) {
                if (tmpOrderedIds?.includes(id)) {
                    setTmpOrderedIds(tmpOrderedIds.filter((val) => val !== id));
                    return;
                }
                if (tmpOrderedIds.length < maxNFTs) {
                    setTmpOrderedIds([...tmpOrderedIds, id]);
                } else {
                    triggerHeavy();
                }
            }
        },
        [tmpOrderedIds, maxNFTs]
    );

    const deleteFromTmpOrderedIds = React.useCallback(
        (id: string) =>
            setTmpOrderedIds(tmpOrderedIds?.filter((val) => val !== id)),
        [tmpOrderedIds]
    );

    const updateTmpOrderedIds = (data: Pet[]) => {
        setTmpOrderedIds(() => [...data.map((val) => val.id)]);
    };

    const handleSaveChanges = React.useCallback(async () => {
        setIsLoading(true);
        try {
            const { data } = await request({
                variables: {
                    petOrderedIds: checkPets(
                        tmpOrderedIds,
                        pets?.map((o) => o.id)
                    ),
                },
            });
            if (data) {
                reloadUser();
            }
            waitForNotification().then(() => {
                navigation.pop();
                toastSuccess(i18n.t('editNFTsScreen.success'));
            });
        } catch (error) {
            setIsLoading(false);
            Console.log('[SAVE ORDERED_IDS]', error);
            toastErrorUnknown();
        }
    }, [request, tmpOrderedIds, pets, reloadUser, navigation]);
    const [navigateToPet, setNavigateToPet] = React.useState<Pet | null>(null);

    const closeSaveModal = React.useCallback(async () => {
        navigation.pop();
        if (navigateToPet) {
            await waitForNavigation();
            navigation.navigateToPetDetails(navigateToPet);
        }
    }, [navigateToPet]);

    const onDetailedPet = async (pet: Pet) => {
        setNavigateToPet(pet);
        navigation.pop();

        await waitForNavigation();
        navigation.navigateToPetDetails(pet);
    };
    const onExit = React.useCallback(() => {
        if (!isEqualIds) {
            setModalSaveChangesVisible(true);
            return;
        }
        navigation.pop();
    }, [isEqualIds]);

    const orderedPetsForPlay = React.useMemo(
        () =>
            compact(
                tmpOrderedIds?.map((item) =>
                    pets?.find((pet) => pet.id === item)
                )
            ),
        [tmpOrderedIds, pets]
    );

    return (
        <SafeAreaView style={styles.safeArea}>
            <View style={styles.container}>
                <View style={styles.navigation}>
                    <NavigationBarModal
                        title={i18n.t('editNFTsScreen.title')}
                        iconName={ICON_NAMES.CLOSE}
                        onBack={onExit}
                    />
                </View>
                {!!pets && tmpOrderedIds && (
                    <>
                        <View style={styles.dragWrapper}>
                            <Text style={styles.dragText}>
                                {i18n.t('editNFTsScreen.dragText')}
                            </Text>

                            <DraggablePetList
                                orderedPetsForPlay={orderedPetsForPlay}
                                maxPets={maxNFTs || 10}
                                tmpOrderedIds={tmpOrderedIds}
                                updateTmpOrderedIds={updateTmpOrderedIds}
                                onDelete={deleteFromTmpOrderedIds}
                            />
                        </View>

                        <View style={styles.listWrapper}>
                            {!isLoading && (
                                <EditPetsList
                                    onDetailedPet={onDetailedPet}
                                    pets={pets}
                                    onPress={addToTmpOrderedIds}
                                    tmpOrderedIds={tmpOrderedIds}
                                />
                            )}
                        </View>
                    </>
                )}
                <View style={styles.warning}>
                    <AlertCard
                        text={i18n.t('editNFTsScreen.warningMessage', {
                            count: maxNFTs,
                        })}
                    />
                </View>
                <View style={styles.buttonWrapper}>
                    {/* TODO:// Check why disable not works */}
                    <Button
                        disabled={petsLoading || isLoading}
                        debouncedPress
                        debounceTime={2000}
                        title={i18n.t('editNFTsScreen.button')}
                        onPress={handleSaveChanges}
                    />
                </View>
                <SafeAreaView />
                <CustomModal
                    isVisible={modalSaveChanges}
                    icon={<ImageIguana type="attention" />}
                    titleText={i18n.t(
                        'editNFTsScreen.errors.saveChanges.title'
                    )}
                    infoText={i18n.t('editNFTsScreen.errors.saveChanges.text')}
                    firstButtonText={i18n.t(
                        'editNFTsScreen.errors.saveChanges.button1'
                    )}
                    firstButtonType="outline"
                    secondButtonText={i18n.t(
                        'editNFTsScreen.errors.saveChanges.button2'
                    )}
                    onFirstButtonClick={() => {
                        closeSaveModal();
                    }}
                    onSecondButtonClick={() => {
                        setModalSaveChangesVisible(false);
                    }}
                />
                <ModalLoader
                    isVisible={isLoading}
                    text={i18n.t('editNFTsScreen.loading')}
                />
                <ToastNotification />
            </View>
        </SafeAreaView>
    );
};
export default EditNFTSScreen;
