import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
        },
        cardWrapper: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: verticalScale(16),
        },
        cardWrapperGap: {
            marginTop: verticalScale(12),
        },
        cardWidth: {
            width: '48%',
        },
        cardFull: {
            width: '100%',
            flex: 1,
        },
        list: {
            marginTop: verticalScale(12),
        },
        buttonInner: {
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: 'transparent',
        },
        text: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
            letterSpacing: 0.08,
            marginRight: scale(6),
        },
    });

export default styles;
