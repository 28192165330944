import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme, safeBottom: number) =>
    StyleSheet.create({
        wrapper: {
            flex: 1,
        },
        absolute: {
            position: 'absolute',
            zIndex: 100000,
            width: '100%',
            height: '100%',
        },
        container: {
            flex: 1,
            paddingHorizontal: scale(20),
        },
        title: {
            paddingTop: verticalScale(8),
            paddingBottom: verticalScale(6),
            color: Colors[theme].text.primary,
            ...font({
                size: 22,
                weight: '700',
            }),
        },
        description: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 15,
                height: 23,
            }),
        },
        passCode: {
            marginBottom: verticalScale(16),
            marginTop: verticalScale(32),
            alignItems: 'center',
        },
        error: {
            color: Colors[theme].text.danger,
            textAlign: 'center',
        },
    });

export default styles;
