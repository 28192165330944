import * as React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import { without } from 'lodash';

import { GET_MARKETPLACE_COUNTS_FOR_RANKS } from '@Data/Requests';
import { useLazyQuery } from '@apollo/client';
import Button from '@components/Button';
import ButtonsBottom from '@components/ButtonsBottom';
import ModalBottom from '@components/ModalBottom';
import { errorsHandler } from '@helpers/errors';
import { statisticsFormatter } from '@helpers/helpers';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import {
    MARKETPLACE_ORDERS_SORT_OPTION,
    MARKETPLACE_PET_TYPE,
    MarketplaceRanksOutputResponse,
} from '@models/marketplace';

import { SORTED_BY, TYPE_OF_NFT } from '../../constans/filters';
import Dropdown from '../Dropdown';
import DropdownItem from '../DropdownItem';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
    typeOfNft: MARKETPLACE_PET_TYPE | null;
    selectedRanks: string[] | null;
    sortedBy: MARKETPLACE_ORDERS_SORT_OPTION | null;
    onApplyFilterPress: (
        type: MARKETPLACE_PET_TYPE | null,
        selectedRanks: string[] | null,
        sortedBy: MARKETPLACE_ORDERS_SORT_OPTION | null
    ) => void;
    onClearPress: () => void;
}

const MODAL_HEIGHT = 550;

const ModalFilter = ({
    isVisible,
    onClose,
    typeOfNft,
    selectedRanks,
    sortedBy,
    onApplyFilterPress,
    onClearPress,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const [
        getRanksCount,
        { data: ranksCountData, loading: rankCountDataLoading },
    ] = useLazyQuery<MarketplaceRanksOutputResponse>(
        GET_MARKETPLACE_COUNTS_FOR_RANKS,
        {
            fetchPolicy: 'network-only',
            onError: (error) => errorsHandler(error, true),
        }
    );
    React.useEffect(() => {
        if (isVisible) {
            getRanksCount();
        }
    }, [isVisible]);
    const [modalHeight, setModalHeight] = React.useState(MODAL_HEIGHT);

    const handleOnClose = () => {
        setModalHeight(MODAL_HEIGHT);
        onClose();
    };

    const [tmpTypeOfNft, setTmpTypeOfNft] =
        React.useState<MARKETPLACE_PET_TYPE | null>(null);

    const selectTypeOfNft = React.useCallback(
        (item: MARKETPLACE_PET_TYPE) => {
            if (tmpTypeOfNft === item) {
                return setTmpTypeOfNft(null);
            }
            setTmpTypeOfNft(item);
        },
        [tmpTypeOfNft]
    );

    const [tmpSelectedRanks, setTmpSelectedRanks] = React.useState<
        string[] | null
    >(null);

    const selectRanks = React.useCallback(
        (item: string) => {
            setTmpSelectedRanks((prev) =>
                prev
                    ? prev.includes(item)
                        ? without(prev, item)
                        : [...prev, item]
                    : [item]
            );
        },
        [tmpSelectedRanks]
    );

    const isSelectedRank = React.useCallback(
        (item: string) => {
            return !!tmpSelectedRanks?.includes(item);
        },
        [tmpSelectedRanks]
    );

    const [tmpSortedBy, setTmpSortedBy] =
        React.useState<MARKETPLACE_ORDERS_SORT_OPTION | null>(null);

    const selectSortedBy = React.useCallback(
        (item: MARKETPLACE_ORDERS_SORT_OPTION) => {
            if (tmpSortedBy === item) {
                return setTmpSortedBy(null);
            }
            setTmpSortedBy(item);
        },
        [tmpSortedBy]
    );

    React.useEffect(() => {
        if (isVisible) {
            setTmpSelectedRanks(selectedRanks);
            setTmpSortedBy(sortedBy);
            setTmpTypeOfNft(typeOfNft);
        }
    }, [isVisible, selectedRanks, sortedBy, typeOfNft]);

    const handleOnApplyPress = React.useCallback(() => {
        onApplyFilterPress(
            tmpTypeOfNft,
            !!tmpSelectedRanks?.length ? tmpSelectedRanks : null,
            tmpSortedBy
        );
        handleOnClose();
    }, [tmpTypeOfNft, tmpSortedBy, tmpSelectedRanks]);

    const handleOnClearPress = () => {
        setTmpSelectedRanks(null);
        setTmpSortedBy(null);
        setTmpTypeOfNft(null);
        onClearPress();
        handleOnClose();
    };

    return (
        <ModalBottom
            dynamicHeight
            isVisible={isVisible}
            onClose={handleOnClose}
            modalHeight={modalHeight}
            titleText={i18n.t('MarketPlace.MarketPlaceSearch.modalTitle')}>
            <ScrollView
                style={styles.container}
                showsVerticalScrollIndicator={false}>
                <TouchableOpacity activeOpacity={1}>
                    <Dropdown
                        shouldBeOpened={!!typeOfNft}
                        containerStyle={styles.gap}
                        title={i18n.t('general.KEYS.typeOfNft')}
                        setModalHeight={setModalHeight}>
                        {TYPE_OF_NFT.map((item) => (
                            <DropdownItem
                                key={item}
                                title={i18n.t(
                                    `MarketPlace.MarketPlaceSearch.typeSection.${item}`
                                )}
                                isSelected={item === tmpTypeOfNft}
                                onPress={() => selectTypeOfNft(item)}
                            />
                        ))}
                    </Dropdown>
                    <Dropdown
                        shouldBeOpened={!!selectedRanks?.length}
                        containerStyle={styles.gap}
                        loading={rankCountDataLoading}
                        title={i18n.t('general.KEYS.rank')}
                        setModalHeight={setModalHeight}>
                        {ranksCountData?.marketplaceCountsForRanks
                            .map((item) => (
                                <DropdownItem
                                    key={item.rank}
                                    title={i18n.t(
                                        `ranks.list.${item.rank}.title`
                                    )}
                                    additionalTitle={` (${statisticsFormatter(
                                        item.count
                                    )})`}
                                    isSelected={isSelectedRank(item.rank)}
                                    onPress={() => selectRanks(item.rank)}
                                />
                            ))
                            .reverse()}
                    </Dropdown>
                    <Dropdown
                        shouldBeOpened={!!sortedBy}
                        containerStyle={styles.gap}
                        title={i18n.t('general.KEYS.sortedBy')}
                        setModalHeight={setModalHeight}>
                        {SORTED_BY.map((item) => (
                            <DropdownItem
                                key={item}
                                title={i18n.t(
                                    `MarketPlace.MarketPlaceSearch.sortBySection.${item}`
                                )}
                                isSelected={item === tmpSortedBy}
                                onPress={() => selectSortedBy(item)}
                            />
                        ))}
                    </Dropdown>
                </TouchableOpacity>
            </ScrollView>

            <ButtonsBottom
                containerStyle={styles.buttons}
                title={i18n.t('general.BUTTONS.apply')}
                onPress={handleOnApplyPress}>
                <Button
                    containerStyle={styles.button}
                    type="outline"
                    onPress={handleOnClearPress}
                    title={i18n.t('general.BUTTONS.clear')}
                />
            </ButtonsBottom>
        </ModalBottom>
    );
};

export default ModalFilter;
