import React from 'react';

import RankIcon from '@components/Icons/RankIcon';
import ModalWhatIsRank from '@components/Ranks/ModalWhatIsRank';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import { MarketplaceNftDetailsPetOutput } from '@models/marketplace';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import DetailsBlock from '../../../Collection/components/DetailsBlock';

interface IProps {
    pet: MarketplaceNftDetailsPetOutput;
}

const OverviewRankDetailsBlock = ({ pet }: IProps) => {
    const navigation = useNavigation();
    const { rank } = pet;

    const { isVisible, open, close } = useVisibleHook();

    const navigateToRanksDetails = (slug?: string) => {
        navigation.navigate(ROUTES.RANKS_DETAILED_INFO, {
            screenConfig: {
                showAll: true,
                selectRank: false,
                paginated: true,
                slug,
            },
        });
    };

    const onDetailedInfo = () => {
        close();
        navigateToRanksDetails();
    };

    const handleOnIconPress = () => {
        navigateToRanksDetails(rank);
    };

    return (
        <DetailsBlock
            title={i18n.t('detailedPet.rank.title', {
                rank: i18n.t(`ranks.list.${rank}.title`),
            })}
            description={i18n.t('detailedPet.rank.text')}
            icon={<RankIcon slug={rank} size={48} />}
            onInfoIconPress={open}
            onIconPress={handleOnIconPress}>
            <ModalWhatIsRank
                buttonAllRanks
                isVisible={isVisible}
                closeModal={close}
                actionModal={onDetailedInfo}
            />
        </DetailsBlock>
    );
};
export default OverviewRankDetailsBlock;
