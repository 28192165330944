import * as React from 'react';
import { useState } from 'react';
import { SafeAreaView } from 'react-native';

import { CreateSocialTaskResponse } from '@Data/Models';
import { CREATE_SOCIAL_TASK } from '@Data/Requests';
import { useMutation } from '@apollo/client';
import Button from '@components/Button';
import ImageSlider from '@components/ImageSlider';
import { socializeToEarn } from '@components/ImageSlider/data';
import ModalBottom from '@components/ModalBottom';
import { View } from '@components/Themed';
import { PlayContext } from '@contexts/PlayContext';
import { useUser } from '@contexts/UserContext';
import { getBottomModalHeighByItems, isGraphqlError } from '@helpers/helpers';
import { toastError, toastErrorUnknown } from '@helpers/toastNotification';
import useTasksErrorsHandler from '@hooks/tasks/useTasksErrorsHandler';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import ChooseSocial from '../components/ChooseSocial';
import ModalConnectSocials from '../components/ModalConnectSocials';
import stylesMain from './styles';

export interface IExtendedSocials {
    socialName: string;
    id?: string | undefined;
    username?: string | null | undefined;
    isVerified?: boolean | undefined;
    updatedAt?: string | undefined;
    createdAt?: string | undefined;
}

export const WelcomeShareScreen = () => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const { user, reloadUser } = useUser();
    const [createSocialTask, { loading }] =
        useMutation<CreateSocialTaskResponse>(CREATE_SOCIAL_TASK);
    const { taskWrongState } = useTasksErrorsHandler();

    const [socialModal, setSocialModalVisible] = React.useState<boolean>(false);
    const [socialPlatform, setSocialPlatform] = React.useState<string>('');
    const [isVisibleConnectSocials, setIsVisibleConnectSocials] =
        useState<boolean>(false);
    const { socialTask, updateSocialTask } = React.useContext(PlayContext);

    const handleOpenConnectSocials = () => {
        setIsVisibleConnectSocials(true);
    };

    const handleConnectSocialsOnSuccess = () => {
        handleButtonPress();
    };

    const onBack = () => {
        navigation.navigateToTasks();
    };

    const userLinkedSocials: IExtendedSocials[] = React.useMemo(() => {
        return [
            { ...user?.instagram, socialName: 'instagram' },
            { ...user?.tiktok, socialName: 'tiktok' },
            { ...user?.twitter, socialName: 'twitter' },
            { ...user?.facebook, socialName: 'facebook' },
        ].filter((o) => o?.id);
    }, [user]);

    const handleButtonPress = React.useCallback(() => {
        if (userLinkedSocials.length >= 2) {
            setSocialModalVisible(true);
            return;
        }
        if (!userLinkedSocials[0]?.socialName) {
            toastErrorUnknown();
            return;
        }
        return handleCreateSocialTask(userLinkedSocials[0].socialName);
    }, [user, userLinkedSocials]);

    const handleOnSelect = (id: string) => {
        handleCloseModal();
        setSocialPlatform(id);
        handleCreateSocialTask(id);
    };

    const handleCreateSocialTask = React.useCallback(
        (platform?: string) => {
            createSocialTask({
                variables: {
                    socialPlatform: platform || socialPlatform,
                },
                onError: async (error) => {
                    Console.log('[createSocialTask]', error);

                    if (isGraphqlError(error, 'USER_NO_MAIN_PET')) {
                        navigation.push(ROUTES.PLAY_EDIT_NFTS);
                        return;
                    }

                    if (
                        isGraphqlError(error, 'INSTAGRAM_REFRESH_ERROR') ||
                        isGraphqlError(error, 'INSTAGRAM_NOT_LINKED')
                    ) {
                        await reloadUser();

                        handleOpenConnectSocials();
                    } else if (isGraphqlError(error, 'FORBIDDEN')) {
                        toastError(
                            undefined,
                            i18n.t(
                                'socializeToEarn.welcomeSlidesShare.errors.FORBIDDEN.text'
                            )
                        );
                    } else {
                        taskWrongState(error);
                    }
                },
                onCompleted: (data) => {
                    updateSocialTask(data.createSocialTask);
                    navigation.replace(
                        ROUTES.SOCIALIZE_TO_EARN_SHARE_YOUR_PHOTO
                    );
                },
            });
        },
        [
            createSocialTask,
            navigation,
            reloadUser,
            socialPlatform,
            taskWrongState,
            updateSocialTask,
        ]
    );

    const handleCloseModal = () => {
        setSocialModalVisible(false);
    };

    return (
        <>
            <NavigationBar
                backIcon={'close'}
                containerStyle={styles.navBg}
                onBack={onBack}
            />
            <ImageSlider
                data={socializeToEarn}
                customEnergy={socialTask?.maxEnergyReward}
            />
            <View style={styles.buttonWrapper}>
                <Button
                    title={i18n.t(
                        'socializeToEarn.welcomeSlidesShare.startSharing'
                    )}
                    onPress={handleButtonPress}
                    debouncedPress
                    debounceTime={500}
                    loading={loading}
                />
            </View>
            <SafeAreaView style={styles.safe} />
            <ModalBottom
                titleText={i18n.t('socialMediaConnect.selectAccountToShare')}
                modalHeight={getBottomModalHeighByItems(
                    userLinkedSocials.length
                )}
                isVisible={socialModal}
                onClose={handleCloseModal}
                containerStyle={styles.modalContainer}
                swipeDirection={'down'}>
                <ChooseSocial
                    onPress={handleOnSelect}
                    socials={userLinkedSocials}
                />
            </ModalBottom>
            <ModalConnectSocials
                isVisible={isVisibleConnectSocials}
                setIsVisible={setIsVisibleConnectSocials}
                onSuccess={handleConnectSocialsOnSuccess}
            />
        </>
    );
};
export default WelcomeShareScreen;
