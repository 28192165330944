import * as React from 'react';

import CheckoutSuccessScreen from '../../../../components/CheckoutSuccessScreen';
import NFTIcon from '../../../../components/NFTIcon/NFTIcon';
import { View } from '../../../../components/Themed';
import TransactionSummary from '../../../../components/TransactionSummary';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import { useNavigation } from '../../../../navigation/useNavigation';
import { WalletNFTSWithdrawPropsProps } from '../../../../types';
import stylesMain from './styles';

const WithdrawSuccessScreen = ({ route }: WalletNFTSWithdrawPropsProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const { pet, addressFrom, addressTo, transactionResult } = route.params;

    const handleBackToWallet = () => {
        // to kill all previous screens
        navigation.popToTop();
        navigation.navigateToNFTSWallet();
    };

    navigation.blockHardwareBack();

    const renderTransactionDetailComponent = () => {
        return (
            <>
                <TransactionSummary
                    values={[
                        {
                            key: 'NFT',
                            value: (
                                <View style={styles.nftIcon}>
                                    <NFTIcon pet={pet} />
                                </View>
                            ),
                        },
                        {
                            key: i18n.t(
                                'wallet.nft.withdrawSuccess.addressFrom'
                            ),
                            value: addressFrom,
                        },
                        {
                            key: i18n.t('wallet.nft.withdrawSuccess.addressTo'),
                            value: addressTo,
                        },
                        {
                            key: i18n.t('wallet.nft.blockchainFee'),
                            value: transactionResult.transactionFeeFormatted,
                        },
                    ]}
                />
            </>
        );
    };

    return (
        <>
            <CheckoutSuccessScreen
                title={i18n.t('wallet.nft.withdrawSuccess.title')}
                text={i18n.t('wallet.nft.withdrawSuccess.text', {
                    name: pet.name,
                })}
                onPressButtonMain={handleBackToWallet}
                buttonMainText={i18n.t(
                    'wallet.nft.withdrawSuccess.backToWallet'
                )}
                TransactionDetailsComponent={renderTransactionDetailComponent()}
                explorerUrl={transactionResult.explorerUrl}
            />
        </>
    );
};

export default WithdrawSuccessScreen;
