import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        content: {
            backgroundColor: 'transparent',
            justifyContent: 'space-between',
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
        },
        gradientBackground: {
            borderRadius: moderateScale(12),
        },
        itemTitle: {
            color: Colors[theme].text.onLight,
            ...font({
                size: 18,
                height: 24,
                weight: '700',
            }),
        },
        itemText: {
            color: Colors[theme].text.onLight,
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
            letterSpacing: 0.08,
            marginTop: verticalScale(4),
        },
        itemTextBigCard: {
            color: Colors[theme].text.onLight,
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
            letterSpacing: 0.08,
            marginTop: verticalScale(4),
            width: '80%',
        },
        bold: {
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
            letterSpacing: 0,
        },
        top: {
            backgroundColor: 'transparent',
            zIndex: 2,
        },
        button: {
            alignSelf: 'flex-start',
            backgroundColor: Colors[theme].shadow.white,
            marginTop: verticalScale(16),
        },
        buttonStyle: {
            backgroundColor: Colors[theme].text.onLight,
            borderColor: Colors[theme].border.white,
        },
        darkText: {
            color: Colors[theme].text.onDark,
        },
        boostImage: {
            position: 'absolute',
            bottom: 0,
            right: verticalScale(8),
        },
    });

export default styles;
