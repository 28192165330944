import React, { useCallback, useContext } from 'react';
import { BackHandler } from 'react-native';

import { playContextConfig, usePlay } from '@contexts/PlayContext';
import { toastError } from '@helpers/toastNotification';
import i18n from '@i18n/i18n';
import { useNavigation as useNavigationBase } from '@react-navigation/core';
import { StackActions } from '@react-navigation/native';

import { Charity, Pet, PetExperienceRewards, PlayGames } from '../Data/Models';
import { useApollo } from '../contexts/IguApolloContext';
import { MintContext } from '../contexts/MintContext';
import ROUTES from './routes';

export function useNavigation() {
    const navigation = useNavigationBase<any>();
    const { reset: resetMint } = useContext(MintContext);

    const { isGameMaintenance, setIsGameMaintenanceModal } = useApollo();

    const blockHardwareBack = useCallback(() => {
        const backHandler = BackHandler.addEventListener(
            'hardwareBackPress',
            () => true
        );
        return () => backHandler.remove();
    }, []);

    const preventGoingBack = useCallback(() => {
        navigation.addListener('beforeRemove', (event: any) => {
            event.preventDefault();
        });
    }, []);

    const navigateToMint = (params?: any, reset?: boolean) => {
        if (reset) {
            resetMint();
        }
        navigation.navigate(ROUTES.MINT_ROOT, {
            screen: ROUTES.MINT_PET_RANKS,
            params,
        });
    };

    const navigateToInitialMint = () => {
        navigation.navigate(ROUTES.INITIAL_MINT);
    };

    const navigateToCollection = () => {
        navigation.navigate(ROUTES.MAIN, {
            screen: ROUTES.COLLECTION,
            params: {
                screen: ROUTES.COLLECTION_MAIN,
            },
        });
    };

    const navigateToPlayToEarn = () => {
        navigation.navigate(ROUTES.PLAY_TO_EARN);
    };

    const navigateToPetDetails = (pet: Pet, navigateBack?: ROUTES) => {
        navigation.navigate(ROUTES.COLLECTION_DETAILED_PET, {
            pet,
            navigateBack,
        });
    };

    const navigateToPlay = () => {
        navigation.navigate(ROUTES.MAIN, {
            screen: ROUTES.PLAY,
        });
    };

    const { isGameInIteration } = usePlay();

    const navigateToTasks = React.useCallback(
        (reset?: boolean) => {
            if (isGameInIteration) {
                navigation.reset({
                    index: 1,
                    routes: [{ name: ROUTES.MAIN }],
                });
                toastError(
                    i18n.t('gameIterationText.toastErrorTitle'),
                    i18n.t('gameIterationText.text', {
                        minutes: playContextConfig.iterationTime,
                    }),
                    'top',
                    12000
                );
                return;
            }
            if (isGameMaintenance) {
                setIsGameMaintenanceModal(true);
                return;
            }

            if (reset) {
                navigation.reset({
                    index: 1,
                    routes: [
                        { name: ROUTES.MAIN },
                        { name: ROUTES.PLAY_TASKS },
                    ],
                });
                return;
            }
            navigation.navigate(ROUTES.PLAY_TASKS);
        },
        [isGameMaintenance, isGameInIteration]
    );

    const navigatePlayEditNFTS = () => {
        navigation.navigate(ROUTES.PLAY_EDIT_NFTS);
    };

    const navigateToWallet = () => {
        navigation.navigate(ROUTES.WALLET_ROOT);
    };

    const navigateToCoinsWallet = () => {
        navigation.navigate(ROUTES.WALLET_COINS_MAIN);
    };

    const navigateToNFTSWallet = () => {
        navigation.navigate(ROUTES.WALLET_ROOT, {
            screen: ROUTES.WALLET_NFTS_MAIN,
        });
    };
    const navigateToGamingWallet = () => {
        navigation.navigate(ROUTES.WALLET_ROOT, {
            screen: ROUTES.WALLET_GAMING_TAB,
        });
    };

    const navigateToNFTSWalletWithdraw = (pet: Pet) => {
        navigation.navigate(ROUTES.WALLET_NFTS_WITHDRAW, { pet });
    };

    const navigateToTrade = (params?: any) => {
        navigation.navigate(ROUTES.WALLET_TRADE_MAIN, params);
    };

    const navigateToTradeChart = (params?: any) => {
        navigation.navigate(ROUTES.WALLET_TRADE_CHART, params);
    };

    const navigateToWalletSettings = () => {
        navigation.navigate(ROUTES.WALLET_SETTINGS);
    };

    const navigateToProfile = () => {
        navigation.navigate(ROUTES.PROFILE_ROOT);
    };

    const navigateToRewards = () => {
        navigation.navigate(ROUTES.WALLET_ROOT, {
            screen: ROUTES.WALLET_REWARDS_TAB,
        });
    };
    const navigateToGaming = () => {
        navigation.navigate(ROUTES.WALLET_ROOT, {
            screen: ROUTES.WALLET_GAMING_TAB,
        });
    };

    const navigateToPlaySuccess = (
        maxEnergy: number,
        earnedEnergy: number,
        petExperienceRewards: PetExperienceRewards[],
        game: PlayGames
    ) => {
        navigation.dispatch(
            StackActions.replace(ROUTES.PLAY_TASK_SUCCESS, {
                maxEnergy,
                earnedEnergy,
                petExperienceRewards,
                game,
            })
        );
    };

    const navigateToDetailedOrganization = (charity: string) => {
        navigation.navigate(ROUTES.CHARITY_DETAILED_ORGANIZATION, {
            charity,
        });
    };

    const navigateToEnergy = () => {
        navigation.navigate(ROUTES.ENERGY_INFO_SCREEN);
    };

    const navigateToAdminView = () => {
        navigation.navigate(ROUTES.ADMIN_ROOT);
    };

    const navigateToStaking = (
        stakingType: ROUTES.STAKING_ALREADY | ROUTES.STAKING_WELCOME,
        navigateBack?: ROUTES,
        params?: any
    ) => {
        navigation.navigate(ROUTES.WALLET_ROOT, {
            screen: ROUTES.WALLET_REWARDS_TAB,
            params: {
                screen: stakingType,
                params: { navigateBack, params },
            },
        });
    };
    const navigateToTopUp = () => {
        navigation.navigate(ROUTES.TOP_UP_GAMING_WALLET);
    };

    const navigateToMysteryLikesActiveOrder = () => {
        navigation.navigate(ROUTES.MYSTERY_LIKES_ACTIVE_ORDERS);
    };
    const navigateToMysteryLikesHistory = () => {
        navigation.navigate(ROUTES.MYSTERY_LIKES_HISTORY);
    };

    return {
        ...navigation,
        blockHardwareBack,
        preventGoingBack,
        navigateToMint,
        navigateToCollection,
        navigateToPetDetails,
        navigateToPlay,
        navigateToTasks,
        navigatePlayEditNFTS,
        navigateToWallet,
        navigateToCoinsWallet,
        navigateToNFTSWallet,
        navigateToNFTSWalletWithdraw,
        navigateToTrade,
        navigateToWalletSettings,
        navigateToProfile,
        navigateToRewards,
        navigateToPlayToEarn,
        navigateToPlaySuccess,
        navigateToDetailedOrganization,
        navigateToEnergy,
        navigateToAdminView,
        navigateToStaking,
        navigateToGamingWallet,
        navigateToTopUp,
        navigateToMysteryLikesActiveOrder,
        navigateToMysteryLikesHistory,
        navigateToGaming,
        navigateToInitialMint,
    };
}
