import { StyleSheet } from 'react-native';

import { moderateScale, scale, verticalScale } from '@helpers/dimensions';

import Colors from '../../../../../constants/Colors';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        itemContainer: {
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            // backgroundColor: 'transparent',
            marginBottom: verticalScale(4),
        },
        active: {
            borderColor: Colors[theme].border.accent,
        },
        activeShadow: { backgroundColor: Colors[theme].shadow.selected },
        left: {
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
        },
        textWrapper: {
            flex: 1,
            marginLeft: scale(12),
        },

        imageWrapper: {
            width: scale(40),
            height: scale(40),
            borderRadius: moderateScale(6),
        },
        address: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '600',
            }),
            marginTop: verticalScale(2),
        },
        date: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
        },

        list: {
            marginTop: verticalScale(12),
        },
        shadow: {
            borderRadius: moderateScale(12),
            backgroundColor: 'transparent',
            marginBottom: verticalScale(12),
        },
        indirectIcon: {
            position: 'absolute',
            bottom: -4,
            right: -4,
            borderRadius: 1000,
        },
        footerContainer: {
            marginTop: verticalScale(2),
            alignItems: 'center',
            flexDirection: 'row',
        },
        energyWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: 'transparent',
        },
        energyText: {
            color: Colors[theme].text.warning,
        },
        energyThresholdReached: {
            color: Colors[theme].text.accent,
        },
    });

export default styles;
