import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import { scale, verticalScale } from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: scale(8),
            paddingVertical: verticalScale(6),
            borderRadius: 50,
            backgroundColor: Colors[theme].backgroundEnergy,
        },
        energyText: {
            color: Colors[theme].text.warning,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
            marginLeft: verticalScale(6),
            paddingRight: verticalScale(4),
            textAlign: 'center',
        },
        textIconRight: {
            marginLeft: verticalScale(0),
        },
    });

export default styles;
