import * as React from 'react';
import { ScrollView } from 'react-native';

import CacheImage from '@components/CacheImage';
import { View } from '@components/Themed';
import { useDimensions } from '@contexts/DimensionsContext';
import useThemedStyles from '@hooks/useThemedStyles';
import NavigationMarketPlaceOverview, {
    NAVIGATION_MARKETPLACE_OVERVIEW,
} from '@navigation/NavigationBar/NavigationMarketPlaceOverview';

import { MarketPlaceOverviewOwnerHistoryProps } from '../../../../types';
import MarketPlacePurchaseHistory from '../components/MarketPlacePurchaseHistory';
import MarketPlaceTotalPurchaseSummary from '../components/MarketPlaceTotalPurchaseSummary';
import OverviewButtons from '../components/OverviewButtons';
import OverviewNavigation from '../components/OverviewNavigation';
import useMarketplaceNftTransactionHistory from '../hooks/useMarketplaceNftTransactionHistory';
import stylesMain from './styles';

const MarketPlaceOverviewOwnerHistoryScreen = ({
    route,
}: MarketPlaceOverviewOwnerHistoryProps) => {
    const styles = useThemedStyles(stylesMain);

    const { pet, order, isOwner, cancelTransactionValuesStringified, owner } =
        route.params;
    const { windowWidth } = useDimensions();

    const {
        getMarketplaceNftTransactionHistory,
        totalVolumeIgu,
        totalVolumeUsd,
        transactions,
        historyLoading,
    } = useMarketplaceNftTransactionHistory();

    React.useEffect(() => {
        getMarketplaceNftTransactionHistory(false, pet.id);
    }, [pet.id]);

    return (
        <>
            <OverviewNavigation />
            <ScrollView
                style={styles.container}
                bounces={false}
                showsVerticalScrollIndicator={false}>
                <View>
                    <CacheImage
                        resizeMode="cover"
                        style={{ height: windowWidth }}
                        source={pet.imageUrl}
                    />
                </View>

                <View style={styles.content}>
                    <NavigationMarketPlaceOverview
                        currentNavigation={
                            NAVIGATION_MARKETPLACE_OVERVIEW.OWNER_HISTORY
                        }
                        petId={pet.id}
                        cancelTransactionValuesStringified={
                            cancelTransactionValuesStringified
                        }
                        owner={owner}
                    />
                    {!!totalVolumeIgu &&
                        !!totalVolumeUsd &&
                        !!transactions?.length && (
                            <MarketPlaceTotalPurchaseSummary
                                totalVolumeIgu={totalVolumeIgu}
                                totalVolumeUsd={totalVolumeUsd}
                            />
                        )}

                    <MarketPlacePurchaseHistory
                        historyLoading={historyLoading}
                        transaction={transactions}
                    />
                </View>
            </ScrollView>
            <OverviewButtons
                order={order}
                pet={pet}
                isOwner={isOwner}
                cancelTransactionValuesStringified={
                    cancelTransactionValuesStringified
                }
                owner={owner}
            />
        </>
    );
};
export default MarketPlaceOverviewOwnerHistoryScreen;
