import * as React from 'react';

import AddWalletInitialScreen from '../../screens/CreateWallet/AddWalletInitial/AddWalletInitialScreen';
import CreateWalletFirstScreen from '../../screens/CreateWallet/CreateWalletFirst/CreateWalletFirstScreen';
import CreateWalletPassphraseScreen from '../../screens/CreateWallet/CreateWalletPassphrase/CreateWalletPassphraseScreen';
import CreateWalletSuccessScreen from '../../screens/CreateWallet/CreateWalletSuccess/CreateWalletSuccessScreen';
import CreateWalletVerificationScreen from '../../screens/CreateWallet/CreateWalletVerification/CreateWalletVerificationScreen';
import ImportWalletScreen from '../../screens/CreateWallet/ImportWallet/ImportWalletScreen';
import ROUTES from '../routes';

const CreateWalletGroup = (RootStack: any) => {
    return (
        <>
            <RootStack.Screen
                name={ROUTES.ADD_WALLET_INITIAL}
                component={AddWalletInitialScreen}
            />
            <RootStack.Screen
                name={ROUTES.IMPORT_WALLET}
                component={ImportWalletScreen}
            />
            <RootStack.Screen
                name={ROUTES.CREATE_WALLET_FIRST}
                component={CreateWalletFirstScreen}
            />
            <RootStack.Screen
                name={ROUTES.CREATE_WALLET_PASSPHRASE}
                component={CreateWalletPassphraseScreen}
            />
            <RootStack.Screen
                name={ROUTES.CREATE_WALLET_VERIFICATION}
                component={CreateWalletVerificationScreen}
            />
            <RootStack.Screen
                name={ROUTES.CREATE_WALLET_SUCCESS}
                component={CreateWalletSuccessScreen}
            />
        </>
    );
};

export default CreateWalletGroup;
