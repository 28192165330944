import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import { stylesRowAlign } from '@helpers/style';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        item: { ...stylesRowAlign(), backgroundColor: 'transparent' },
        itemLeft: { ...stylesRowAlign(true), backgroundColor: 'transparent' },
        icon: {
            marginRight: scale(8),
            backgroundColor: 'transparent',
        },
        gap: {
            marginBottom: verticalScale(16),
        },

        buttonTextStyle: {
            color: Colors[theme].text.primary,
        },
    });

export default styles;
