import * as React from 'react';
import { useMemo } from 'react';

import ModalConfirmTransaction from '@components/ModalConfirmTransaction';
import TransactionSummary from '@components/TransactionSummary';
import { useConfig } from '@contexts/ConfigContext';
import { useGamingWallet } from '@contexts/GamingWalletContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { priceString } from '@helpers/payments';
import { balanceFromWei, feeWithCurrency, valueToWei } from '@helpers/wallet';
import i18n from '@i18n/i18n';
import { GamingIguBalanceResponse } from '@models/gamingWallet';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
    onConfirm: (
        amount: string,
        coin: Coin,
        commission: number,
        total: string
    ) => void;
    amount?: string;
    fee: string | undefined;
    coin: Coin;
    isBNBEnough: boolean;
    infoIcon: (withoutModal?: boolean | undefined) => JSX.Element;
    commissionIcon: (withoutModal?: boolean | undefined) => JSX.Element;
}

const WithdrawConfirmationModal = ({
    isVisible,
    onClose,
    onConfirm,
    fee,
    infoIcon,
    coin,
    isBNBEnough,
    commissionIcon,
    amount,
}: IProps) => {
    const { config } = useConfig();

    const { isPendingWithdraw, gamingBalance } = useGamingWallet();

    const token = useMemo(() => {
        if (isPendingWithdraw) {
            const coin =
                gamingBalance?.iguPendingWithdraw?.paymentOption.token.toLowerCase();
            return Coin[coin as keyof object];
        }
        return coin;
    }, [gamingBalance, coin]);

    const commissionFee = useMemo(() => {
        if (!config?.iguWalletWithdrawFeePercentage) {
            return 0;
        }
        if (isPendingWithdraw) {
            return (
                Number(
                    balanceFromWei(
                        gamingBalance?.iguPendingWithdraw?.paymentOption
                            .amountWei
                    ).value
                ) *
                (config.iguWalletWithdrawFeePercentage / 100)
            );
        }
        return (
            Number(amount || 0) * (config.iguWalletWithdrawFeePercentage / 100)
        );
    }, [
        config?.iguWalletWithdrawFeePercentage,
        amount,
        isPendingWithdraw,
        gamingBalance,
    ]);

    const totalSummary = useMemo(() => {
        switch (token) {
            case Coin.busd:
                // TODO ADD LOGIC WITH BUSD
                return '0';

            case Coin.igu:
                return (
                    Number(
                        isPendingWithdraw
                            ? balanceFromWei(
                                  gamingBalance?.iguPendingWithdraw
                                      ?.paymentOption.amountWei
                              ).value
                            : amount
                    ) - commissionFee
                ).toString();

            default:
                return '0';
        }
    }, [token, commissionFee, isPendingWithdraw, amount, gamingBalance]);

    const transactionDetailsValues = useMemo(() => {
        return [
            {
                key: i18n.t('checkout.fields.from'),
                value: i18n.t('checkout.fields.gamingWallet'),
            },
            {
                key: i18n.t('checkout.fields.to'),
                value: i18n.t('checkout.fields.mainWallet'),
            },

            {
                key: i18n.t('checkout.fields.amount'),
                value: priceString(
                    isPendingWithdraw
                        ? balanceFromWei(
                              gamingBalance?.iguPendingWithdraw?.paymentOption
                                  .amountWei
                          ).valueLong
                        : amount || '0',
                    Coin.vigu
                ),
            },

            fee
                ? {
                      key: i18n.t('checkout.fields.fee'),
                      value: feeWithCurrency(fee),
                      icon: infoIcon(),
                  }
                : undefined,

            !!commissionFee
                ? {
                      key: i18n.t('checkout.fields.commissionFee'),
                      value: feeWithCurrency(commissionFee, Coin.vigu),
                      icon: commissionIcon(),
                  }
                : undefined,
            {
                key: i18n.t('checkout.fields.totalSummary'),
                value: priceString(totalSummary, token),
            },
        ];
    }, [amount, commissionFee, totalSummary, token, gamingBalance]);

    return (
        <ModalConfirmTransaction
            shouldCloseOnConfirm={false}
            title={i18n.t('checkout.fields.modalTitle')}
            isVisible={isVisible}
            close={onClose}
            onConfirm={() =>
                onConfirm(
                    valueToWei(amount),
                    token,
                    commissionFee,
                    totalSummary
                )
            }
            onCancel={onClose}
            disabled={!isBNBEnough}
            textWarning={
                !isBNBEnough
                    ? i18n.t('checkout.errors.bnbNotEnough')
                    : undefined
            }
            showDeposit={!isBNBEnough}
            TransactionDetailsComponent={
                <TransactionSummary values={transactionDetailsValues} />
            }
            modalHeight={transactionDetailsValues.length * 120}
        />
    );
};
export default WithdrawConfirmationModal;
