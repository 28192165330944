import * as React from 'react';
import { SafeAreaView } from 'react-native';

import StepOneImage from '@assets/icons/howTo/howToGamingPendingWallet/stepOne.svg';
import StepTwoImage from '@assets/icons/howTo/howToGamingPendingWallet/stepTwo.svg';
import AlertCard from '@components/AlertCard';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import Carousel from '@components/Carousel';
import { ICON_NAMES } from '@components/Icons';
import { View } from '@components/Themed';
import { useDimensions } from '@contexts/DimensionsContext';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBarModal from '@navigation/NavigationBar/NavigationBarModal';
import useNavigationListeners from '@navigation/hooks/useNavigationListeners';
import { useNavigation } from '@navigation/useNavigation';

import stylesMain from '../styles/styles';

const data = ['stepOne', 'stepTwo'];
const flow = 'howToGamingPendingWallet';

const HowToGamingPendingWalletScreen = () => {
    useNavigationListeners();
    const { windowWidth } = useDimensions();
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const handleBack = () => {
        navigation.pop();
    };

    const renderItem = (item: string) => {
        const icon = () => {
            switch (item) {
                case 'stepOne':
                    return <StepOneImage />;
                default:
                    return <StepTwoImage />;
            }
        };

        return (
            <View style={styles.itemContainer}>
                <View style={styles.content}>
                    <BlockHeader
                        emptyStateIcon={icon()}
                        title={i18n.t(`howTo.${flow}.${item}.title`)}
                        text={i18n.t(`howTo.${flow}.${item}.text`)}
                        type="emptyState"
                    />
                </View>
            </View>
        );
    };

    return (
        <View style={styles.main}>
            <View style={styles.header}>
                <NavigationBarModal
                    iconName={ICON_NAMES.CLOSE}
                    title={i18n.t(`howTo.${flow}.screenTitle`)}
                    onBack={handleBack}
                />
            </View>
            <Carousel
                data={data}
                sliderWidth={windowWidth}
                itemWidth={windowWidth}
                renderItem={renderItem}
                fullHeight
                pagination
                centredVertical
            />
            <AlertCard
                text={i18n.t(`howTo.${flow}.alert`)}
                containerStyle={styles.alert}
            />
            <SafeAreaView />
        </View>
    );
};

export default HowToGamingPendingWalletScreen;
