import * as React from 'react';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import TokenTellerHistoryScreen from '../../screens/TabBar/Home/TokenTellerFlow/TokenTellerHistory/TokenTellerHistoryScreen';
import TokenTellerMainScreen from '../../screens/TabBar/Home/TokenTellerFlow/TokenTellerMain/TokenTellerMainScreen';
import { TokenTellerTabsParamList } from '../../types';
import ROUTES from '../routes';

const TokenTellerTabsStack =
    createBottomTabNavigator<TokenTellerTabsParamList>();

export const TokenTellerTabsNavigator = () => {
    return (
        <TokenTellerTabsStack.Navigator
            screenOptions={{
                headerShown: false,
            }}
            initialRouteName={ROUTES.TOKEN_TELLER_MAIN}
            backBehavior="none"
            tabBar={() => null}>
            <TokenTellerTabsStack.Screen
                name={ROUTES.TOKEN_TELLER_MAIN}
                component={TokenTellerMainScreen}
            />
            <TokenTellerTabsStack.Screen
                name={ROUTES.TOKEN_TELLER_HISTORY}
                component={TokenTellerHistoryScreen}
            />
        </TokenTellerTabsStack.Navigator>
    );
};
