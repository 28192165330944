import { useCallback, useState } from 'react';

import _ from 'lodash';

import { useApolloClient, useQuery } from '@apollo/client';

import { GetTokenTransactionsResponse, TokenTransaction } from '../Data/Models';
import { GET_TOKEN_TRANSACTIONS } from '../Data/Requests';
import { toastErrorUnknown } from '../helpers/toastNotification';

const OFFSET = 10;

const useGetTokenTransactions = (
    contractAddress: string | undefined | null
) => {
    const client = useApolloClient();

    const [transactionsLoading, setTransactionsLoading] =
        useState<boolean>(false);
    const [pullToRefreshLoading, setPullToRefreshLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [firstAsk, setFirstAsk] = useState(true);
    const [canLoadMore, setCanLoadMore] = useState(true);
    const [loadMoreLoading, setLoadMoreLoading] = useState(false);
    const [tokenTransactions, setTokenTransactions] =
        useState<TokenTransaction[]>();

    const getTokenTransactions = useCallback(
        async (silent: boolean, walletReload?: boolean) => {
            if (!walletReload) {
                setFirstAsk(true);
                setCanLoadMore(true);
                setCurrentPage(1);
            }
            silent
                ? setTransactionsLoading(true)
                : setPullToRefreshLoading(true);

            const result = await client.query<GetTokenTransactionsResponse>({
                query: GET_TOKEN_TRANSACTIONS,
                fetchPolicy: 'network-only',
                variables: {
                    contractAddress,
                    bscscanPaginationInput: {
                        page: 1,
                        offset: 10,
                    },
                },
            });
            if (result.error) {
                Console.error(result.error);
                toastErrorUnknown();
            }
            if (result.data) {
                walletReload
                    ? setTokenTransactions((prev) => {
                          return prev
                              ? _.uniqBy(
                                    [...result.data.tokenTransactions, ...prev],
                                    (o) => o.hash
                                )
                              : result.data.tokenTransactions;
                      })
                    : setTokenTransactions(result.data.tokenTransactions);
            }
            setPullToRefreshLoading(false);
            setTransactionsLoading(false);
        },
        [contractAddress, currentPage]
    );

    const getTokenTransactionsLoadMore = useCallback(async () => {
        if (!canLoadMore) {
            return;
        }
        tokenTransactions &&
            tokenTransactions.length >= OFFSET &&
            setLoadMoreLoading(true);
        const result = await client.query<GetTokenTransactionsResponse>({
            query: GET_TOKEN_TRANSACTIONS,
            fetchPolicy: 'network-only',
            variables: {
                contractAddress,
                bscscanPaginationInput: {
                    page: currentPage + 1,
                    offset: OFFSET,
                },
            },
        });
        if (result.error) {
            Console.error(result.error);
            toastErrorUnknown();
        }
        if (result.data) {
            setFirstAsk(false);
            setCurrentPage((prev) => prev + 1);
            setTokenTransactions((prev) => {
                return prev
                    ? _.uniqBy(
                          [...prev, ...result.data.tokenTransactions],
                          (o) => o.hash
                      )
                    : result.data.tokenTransactions;
            });
            if (!result.data.tokenTransactions.length) {
                setCanLoadMore(false);
            }
        }
        setLoadMoreLoading(false);
    }, [
        contractAddress,
        currentPage,
        canLoadMore,
        firstAsk,
        tokenTransactions,
    ]);

    return {
        transactionsLoading,
        pullToRefreshLoading,
        getTokenTransactions,
        getTokenTransactionsLoadMore,
        loadMoreLoading,
        tokenTransactions,
    };
};

export default useGetTokenTransactions;
