import React, {
    FC,
    PropsWithChildren,
    createContext,
    useEffect,
    useState,
} from 'react';
import { ColorSchemeName, useColorScheme } from 'react-native';

import * as SystemUI from 'expo-system-ui';

import AsyncStorage from '@react-native-async-storage/async-storage';

import Colors from '../constants/Colors';
import { isAndroid } from '../helpers/app';

type ThemeContextType = {
    theme: NonNullable<ColorSchemeName>;
    updateTheme: (type: NonNullable<ColorSchemeName>) => void;
    isLight: boolean;
    isDark: boolean;
};

const config = {
    storageKey: 'theme',
    defaultTheme: 'light' as NonNullable<ColorSchemeName>,
};

export const ThemeContext = createContext<ThemeContextType>({
    theme: config.defaultTheme,
    updateTheme: () => undefined,
    isLight: false,
    isDark: false,
});

export const useTheme = () => React.useContext(ThemeContext);

const ThemeProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const colorScheme = useColorScheme();
    const [theme, setTheme] = useState<NonNullable<ColorSchemeName>>(
        config.defaultTheme
    );

    // update the theme
    const updateTheme = async (
        newTheme: NonNullable<ColorSchemeName>,
        save = true
    ) => {
        setTheme(newTheme);
        if (save) {
            await AsyncStorage.setItem(config.storageKey, newTheme);
        }
        if (isAndroid) {
            // Fix for f.default.setBackgroundColorAsync is not a function. (In 'f.default.setBackgroundColorAsync(t)', 'f.default.setBackgroundColorAsync' is undefined)
            if (SystemUI.setBackgroundColorAsync) {
                await SystemUI.setBackgroundColorAsync(
                    Colors[newTheme].background
                );
            }

            /*await NavigationBar.setBackgroundColorAsync(
                Colors[newTheme].background
            );
            await NavigationBar.setButtonStyleAsync(newTheme);*/
        }
    };

    const isLight = theme === 'light';
    const isDark = theme === 'dark';

    useEffect(() => {
        // load theme from settings or use default phone theme
        const loadTheme = async () => {
            // get theme from local storage
            const savedTheme = await AsyncStorage.getItem(config.storageKey);
            // set theme based on user settings or theme
            const currentTheme = (savedTheme ||
                (colorScheme ??
                    config.defaultTheme)) as NonNullable<ColorSchemeName>;
            // update
            await updateTheme(currentTheme, false);
        };

        loadTheme();
    }, [colorScheme]);

    return (
        <ThemeContext.Provider value={{ theme, updateTheme, isLight, isDark }}>
            {children}
        </ThemeContext.Provider>
    );
};

export default ThemeProvider;
