import * as React from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';

import InfoIcon from '@components/InfoIcon/InfoIcon';
import ModalHowToConnectFacebook from '@components/ModalHowToConnectFacebook';
import { waitForModal } from '@helpers/helpers';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';

import { Text } from '../Themed';
import stylesMain from './styles';

interface IProps {
    customStyles?: StyleProp<ViewStyle>;
    onAction?: () => void;
}

const FacebookInfo = ({ customStyles, onAction }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { isVisible, close, open } = useVisibleHook();

    const handleAction = async () => {
        close();
        await waitForModal();
        onAction?.();
    };

    return (
        <TouchableOpacity
            style={[styles.container, customStyles]}
            onPress={open}
            activeOpacity={0.9}>
            <Text style={styles.title}>
                {i18n.t('howConnectFacebookModal.info')}
            </Text>
            <InfoIcon iconColor="green" disabled />
            <ModalHowToConnectFacebook
                isVisible={isVisible}
                closeModal={close}
                onAction={onAction && handleAction}
            />
        </TouchableOpacity>
    );
};
export default FacebookInfo;
