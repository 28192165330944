import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import Icon, { ICON_NAMES } from '@components/Icons';
import CoinIcon from '@components/Icons/CoinIcon';
import { Text, View } from '@components/Themed';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { useHaptic } from '@hooks/useHaptic';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    onPress: () => void;
    second?: boolean;
}

const NftEvolutionEmptyPet = ({ onPress, second }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { triggerHeavy } = useHaptic();

    const handleOnPress = () => {
        triggerHeavy();
        onPress();
    };

    return (
        <TouchableOpacity
            onPress={handleOnPress}
            activeOpacity={0.7}
            style={styles.container}>
            <Icon name={ICON_NAMES.PLUS} size={20} color={styles.icon.color} />
            <Text style={styles.text}>
                {i18n.t(
                    `HomeScreen.NftEvolution.NftEvolutionSelectPets.emptyCard.${
                        second ? 'second' : 'first'
                    }`
                )}
            </Text>
        </TouchableOpacity>
    );
};
export default NftEvolutionEmptyPet;
