import { StyleSheet } from 'react-native';

import Colors from '../../../../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../../../../helpers/dimensions';
import font from '../../../../../../helpers/font';
import { theme } from '../../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        shadow: {
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].shadow.default,
        },
        itemContainer: {
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            marginBottom: 4,
            flexDirection: 'row',
            alignItems: 'center',
        },
        itemText: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
            marginLeft: scale(12),
        },
        listStyle: {
            marginTop: verticalScale(4),
            marginBottom: verticalScale(8),
        },
        gap: {
            marginBottom: verticalScale(16),
        },
    });

export default styles;
