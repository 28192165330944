import * as React from 'react';
import { Image, View } from 'react-native';

import { LinearGradient } from 'expo-linear-gradient';

import { Text } from '@components/Themed';
import { ISocialToEarnCard } from '@constants/shareCardTemplatesNew';
import { isWeb } from '@helpers/app';
import useLanguage from '@hooks/useLanguage';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

const FONT_SIZE = {
    en: {
        fontSize: 100,
        lineHeight: 120,
    },
    uk: {
        fontSize: 92,
        lineHeight: 115,
    },
    ru: {
        fontSize: 92,
        lineHeight: 115,
    },
    pl: {
        fontSize: 92,
        lineHeight: 115,
    },
    id: {
        fontSize: 85,
        lineHeight: 105,
    },
    es: {
        fontSize: 85,
        lineHeight: 105,
    },
    tr: {
        fontSize: 92,
        lineHeight: 115,
    },
};

interface IProps {
    config: {
        width: number | string;
        height: number | string;
    };
    backGround: ISocialToEarnCard;
    token: string | undefined;
    withBorderRadius?: boolean;
    url: string | undefined;
}

const DefaultTemplate = ({
    config,
    backGround,
    token,
    withBorderRadius,
    url,
}: IProps) => {
    const { width, height } = config;

    const {
        cardTypeId,
        title,
        background,
        qrCodeBackground,
        qrCodeTitle,
        linkBackground,
        linkBorder,
        linkTitle,
        opacityCircle,
        backgroundGradientFirst,
        backgroundGradientSecond,
    } = backGround;
    const styles = useThemedStyles(stylesMain);

    const { locale } = useLanguage();

    const fontStyle = React.useMemo(
        () => FONT_SIZE[locale as keyof object],
        [locale]
    );

    return (
        <LinearGradient
            colors={[backgroundGradientFirst, backgroundGradientSecond]}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 1 }}
            style={[
                {
                    width,
                    height,
                    overflow: 'hidden',
                    backgroundColor: background,
                },

                withBorderRadius && { borderRadius: 100 },
            ]}
            collapsable={false}>
            <View style={styles.imageBlock}>
                {isWeb ? (
                    <img src={url} style={styles.petImage} alt="Pet ID" />
                ) : (
                    <Image
                        source={{
                            uri: url,
                        }}
                        style={styles.petImage}
                    />
                )}
            </View>
            <View
                style={[styles.circle1, { backgroundColor: opacityCircle }]}
            />
            <View
                style={[styles.circle2, { backgroundColor: opacityCircle }]}
            />
            <View style={styles.content}>
                <View
                    style={[
                        styles.placeForLink,
                        {
                            borderColor: linkBorder,
                            backgroundColor: linkBackground,
                        },
                    ]}>
                    <Text
                        style={[styles.link, { color: linkTitle }]}
                        allowFontScaling={false}>
                        {i18n.t(
                            `socializeToEarn.createShareTemplate.placeForLink`
                        )}
                    </Text>
                </View>
                <View style={styles.titleBlock}>
                    <Text
                        style={[styles.title, { color: title }, fontStyle]}
                        allowFontScaling={false}>
                        {i18n.t(
                            `socializeToEarn.createShareTemplate.shareTemplate.${cardTypeId}`
                        )}
                    </Text>
                </View>
                <View
                    style={[
                        styles.qrCodeWrapper,
                        { backgroundColor: qrCodeBackground },
                    ]}>
                    <Text
                        allowFontScaling={false}
                        style={[
                            styles.qrCode,
                            { color: qrCodeTitle },
                        ]}>{`#${token}`}</Text>
                </View>
            </View>
        </LinearGradient>
    );
};
export default DefaultTemplate;
