import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';
import { stylesAlignCenter } from '@helpers/style';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            backgroundColor: Colors[theme].background,
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(12),
            marginBottom: 2,
        },
        shadow: {
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].shadow.default,
            width: '48%',
            marginBottom: verticalScale(16),
        },
        price: {
            marginTop: verticalScale(12),
            backgroundColor: 'transparent',
        },
        rankWrapper: {
            position: 'absolute',
            backgroundColor: Colors[theme].background,
            borderWidth: 1,
            borderRadius: 50,
            borderColor: Colors[theme].border.primary,
            width: scale(32),
            aspectRatio: 1,
            top: verticalScale(6),
            left: scale(6),
            ...stylesAlignCenter(),
        },
        buttonContainer: {},
        buttonStyles: {
            width: scale(32),
            aspectRatio: 1,
            marginBottom: 2,
            minWidth: 0,
            minHeight: 0,
        },
        buttonDelist: {
            flex: 1,
            position: 'absolute',
            backgroundColor: 'transparent',

            alignSelf: 'flex-start',
            top: verticalScale(6),
            right: scale(6),
        },
        binColor: {
            color: Colors[theme].icon.critical,
        },
        textWrapper: {
            marginTop: verticalScale(8),
            backgroundColor: 'transparent',
        },
        name: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
        },
        sub: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '600',
            }),
        },
        deadBlock: {
            width: '100%',

            aspectRatio: 1,
            position: 'absolute',
            backgroundColor: 'transparent',
            zIndex: 1,
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
        },
        deadOpacity: {
            width: '100%',

            aspectRatio: 1,
            position: 'absolute',

            opacity: 0.4,
        },
    });

export default styles;
