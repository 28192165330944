import * as React from 'react';
import { SafeAreaView } from 'react-native';

import FirstNFtImage from '@assets/icons/initial/initialMintOne.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import Button from '@components/Button';
import CustomModal from '@components/CustomModal';
import { ICON_NAMES } from '@components/Icons';
import ImageIguana from '@components/ImageIguana';
import { PassCodeWelcome } from '@components/PassCodeWelcome';
import { View } from '@components/Themed';
import { useUser } from '@contexts/UserContext';
import { useLoadingHook } from '@hooks/useLoadingHook';
import { useLocalAuthentication } from '@hooks/useLocalAuthentication';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';
import { useNavigation } from '@navigation/useNavigation';

import stylesMain from './styles';

const InitialMintScreen = () => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const { logout } = useUser();

    const { checkAuthEnabled } = useLocalAuthentication();
    const { isLoading, startLoading, stopLoading } = useLoadingHook(true);
    const [authEnabled, setAuthEnabled] = React.useState<boolean>(false);

    React.useLayoutEffect(() => {
        const check = async () => {
            startLoading();
            setAuthEnabled(await checkAuthEnabled());
            stopLoading();
        };
        check();
    }, []);

    const hide = () => {
        setAuthEnabled(true);
    };

    const [isLogoutModalVisible, setIsLogoutModalVisible] =
        React.useState<boolean>(false);

    const handleOnMintPress = () => {
        navigation.navigateToMint();
    };

    const renderItem = () => {
        return (
            <View style={styles.itemContainer}>
                <View style={styles.content}>
                    <View style={styles.imgWrapper}>
                        {<FirstNFtImage width={180} height={180} />}
                    </View>
                    <BlockHeader
                        title={i18n.t(`initial.initialMint.title`)}
                        text={i18n.t(`initial.initialMint.text`)}
                        type="emptyState"
                    />
                </View>
            </View>
        );
    };

    const renderLogOutModal = () => (
        <CustomModal
            isVisible={isLogoutModalVisible}
            icon={<ImageIguana type="cry" />}
            titleText={i18n.t('profile.logOutModal.title')}
            infoText={i18n.t('profile.logOutModal.description')}
            firstButtonText={i18n.t('profile.logOutModal.okText')}
            secondButtonText={i18n.t('profile.logOutModal.cancelText')}
            onFirstButtonClick={() => {
                setIsLogoutModalVisible(false);
                logout();
            }}
            firstButtonType="outline"
            onSecondButtonClick={() => {
                setIsLogoutModalVisible(false);
            }}
        />
    );

    return (
        <View style={styles.main}>
            {!isLoading ? (
                <>
                    {!authEnabled ? (
                        <PassCodeWelcome hide={hide} />
                    ) : (
                        <>
                            <NavigationBar
                                filled={false}
                                backIcon={ICON_NAMES.CLOSE}
                                title=""
                                onBack={() => setIsLogoutModalVisible(true)}
                            />
                            <View style={styles.wrapper}>{renderItem()}</View>
                            <View style={styles.buttonWrapper}>
                                <Button
                                    title={i18n.t('initial.initialMint.button')}
                                    iconName={ICON_NAMES.SHOP_BAG}
                                    iconColor="onDark"
                                    onPress={handleOnMintPress}
                                />
                            </View>

                            <SafeAreaView />
                            {renderLogOutModal()}
                        </>
                    )}
                </>
            ) : null}
        </View>
    );
};

export default InitialMintScreen;
