import React from 'react';

import { ICON_NAMES } from '@components/Icons';
import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBarModal from '@navigation/NavigationBar/NavigationBarModal';

import { TokenTellerChartProps } from '../../../../../types';
import ChartContent from './ChartContent';
import stylesMain from './styles';

export const TokenTellerChartScreen = ({ route }: TokenTellerChartProps) => {
    const styles = useThemedStyles(stylesMain);

    const { coin1, coin2 } = route.params;

    return (
        <View style={styles.container}>
            <View style={styles.containerTitle}>
                <NavigationBarModal
                    title={i18n.t('tradingView.title')}
                    iconName={ICON_NAMES.CLOSE}
                />
            </View>

            <ChartContent pair={coin1 + coin2} />
        </View>
    );
};
