import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        title: {
            ...font({
                size: 20,
                height: 26,
                weight: '700',
            }),

            color: Colors[theme].text.onLight,
        },
        text: {
            marginTop: verticalScale(4),
            marginBottom: verticalScale(16),
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
            color: Colors[theme].text.onLight,
            width: '70%',
        },
        button: {
            alignSelf: 'flex-start',
            backgroundColor: Colors[theme].shadow.white,
        },
        buttonStyle: {
            backgroundColor: Colors[theme].text.onLight,
            borderColor: Colors[theme].border.white,
        },
        darkText: {
            color: Colors[theme].text.onDark,
        },
        gradientBackground: {
            borderRadius: moderateScale(12),
            paddingVertical: verticalScale(16),
            paddingHorizontal: scale(16),
        },
        referralCodeImage: {
            position: 'absolute',
            bottom: 0,
            right: verticalScale(8),
        },
    });

export default styles;
