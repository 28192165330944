import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../../../helpers/dimensions';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        itemContainer: {
            paddingRight: scale(6),
            paddingVertical: verticalScale(6),
            marginRight: verticalScale(10),
        },
        image: {
            width: scale(64),
            height: scale(64),
            borderRadius: moderateScale(8),
        },
        main: {
            backgroundColor: 'transparent',
        },
        emptyElementWrapper: {
            paddingRight: scale(6),
            paddingVertical: verticalScale(6),
            marginRight: verticalScale(10),
            backgroundColor: 'transparent',
        },
        emptyElement: {
            width: scale(64),
            height: scale(64),
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
            borderWidth: 1,
            borderRadius: moderateScale(8),
            borderColor: Colors[theme].border.primary,
            backgroundColor: 'transparent',
            borderStyle: 'dashed',
        },
        delete: {
            position: 'absolute',
            right: 0,
            top: 0,
            backgroundColor: 'transparent',
        },
    });

export default styles;
