import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            marginBottom: verticalScale(16),
        },
        top: {
            alignItems: 'center',
            flexDirection: 'row',
            marginBottom: verticalScale(16),
        },
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
            marginBottom: verticalScale(4),
        },
        info: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
        textWrapper: {
            marginRight: scale(16),
            flex: 1,
            alignItems: 'flex-start',
        },
        energyCost: {
            color: Colors[theme].text.warning,
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),
            marginLeft: scale(4),
        },
        energyBlock: {
            paddingVertical: verticalScale(2),
            paddingLeft: scale(6),
            paddingRight: scale(8),
        },
        statutes: {
            marginBottom: verticalScale(8),
        },
        completedEnergy: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
        },
        failedModal: {
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            paddingHorizontal: scale(20),
            paddingTop: verticalScale(32),
        },
        failedModalTitle: {
            alignItems: 'center',
            textAlign: 'center',
            color: Colors[theme].text.primary,
            ...font({
                size: 22,
                height: 30,
                weight: '800',
            }),
            marginTop: verticalScale(24),
            marginBottom: verticalScale(8),
        },
        failedModalText: {
            textAlign: 'center',
            color: Colors[theme].text.secondary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
        animation: {
            width: 180,
            height: 180,
            alignSelf: 'center',
        },
        energyWrapper: {
            marginBottom: verticalScale(24),
        },
        failedButton: {
            marginHorizontal: scale(20),
        },
        rejectReasonAlertCard: {
            marginTop: verticalScale(32),
            marginBottom: verticalScale(16),
        },
        alertWrapper: {
            marginBottom: verticalScale(16),
        },
        knowledge: {
            marginVertical: verticalScale(16),
            alignSelf: 'center',
        },
    });

export default styles;
