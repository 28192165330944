import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import { scale, verticalScale } from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flexDirection: 'row',
            paddingVertical: verticalScale(8),
            alignItems: 'center',
        },
        icon: {
            width: 64,
            height: 64,
            marginRight: scale(12),
        },
        label: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '600',
            }),
            marginBottom: verticalScale(2),
        },
        text: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '600',
            }),
        },
        right: {
            flex: 1,
        },
        price: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
        },
        petContainer: {
            paddingHorizontal: 0,
        },
        divider: {
            marginVertical: verticalScale(12),
        },

        levelUpIcon: {
            marginRight: scale(18),
        },

        levelUpTitleText: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                weight: '600',
            }),
        },
        levelUpTextWrapper: {},
        levelUpText: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '600',
            }),
        },
    });

export default styles;
