import * as React from 'react';

import CustomModal from '../../../../components/CustomModal';
import ImageIguana from '../../../../components/ImageIguana';
import i18n from '../../../../i18n/i18n';

interface IProps {
    isVisible: boolean;
    actionFirst: () => void;
    actionSecond: () => void;
}

const ModalLeave = ({ isVisible, actionFirst, actionSecond }: IProps) => {
    return (
        <CustomModal
            isVisible={isVisible}
            icon={<ImageIguana type="cry" />}
            titleText={i18n.t('modal.mintPetLeave.title')}
            infoText={i18n.t('modal.mintPetLeave.text')}
            firstButtonText={i18n.t('modal.mintPetLeave.keep')}
            secondButtonText={i18n.t('modal.mintPetLeave.leave')}
            onFirstButtonClick={actionFirst}
            onSecondButtonClick={actionSecond}
        />
    );
};
export default ModalLeave;
