import React, { useCallback, useMemo } from 'react';

import _ from 'lodash';

import { useApolloClient } from '@apollo/client';
import { DESKTOP_LOAD_LIMIT } from '@constants/API';
import { isDesktop } from '@helpers/app';
import { errorsHandler } from '@helpers/errors';
import { useLoadingHook } from '@hooks/useLoadingHook';
import {
    PaginatedLuckyStrikeGamesWinnerOutput,
    PaginatedLuckyStrikeGamesWinnerOutputResponse,
} from '@models/mysteryGames';
import { GET_LUCKY_STRIKE_GAMES_WINNER } from '@requests/luckyStrike';

export const useLuckyStrikeGamesWinners = (maxToLoad?: number) => {
    const client = useApolloClient();

    const {
        isLoading: winnersLoading,
        startLoading: startWinnersLoading,
        stopLoading: stopWinnersLoading,
    } = useLoadingHook(true);

    const {
        isLoading: refreshWinnersLoading,
        startLoading: startRefreshWinnersLoading,
        stopLoading: stopRefreshWinnersLoading,
    } = useLoadingHook();

    const {
        isLoading: loadMoreWinners,
        startLoading: startLoadMoreWinners,
        stopLoading: stopLoadMoreWinners,
    } = useLoadingHook();

    const [winners, setWinners] =
        React.useState<PaginatedLuckyStrikeGamesWinnerOutput[]>();

    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [totalPages, setTotalPages] = React.useState<number>(1);

    const stop = () => {
        stopWinnersLoading();
        stopRefreshWinnersLoading();
    };

    const getWinners = useCallback(
        async (silent: boolean) => {
            try {
                silent ? startWinnersLoading() : startRefreshWinnersLoading();

                const result =
                    await client.query<PaginatedLuckyStrikeGamesWinnerOutputResponse>(
                        {
                            query: GET_LUCKY_STRIKE_GAMES_WINNER,
                            fetchPolicy: 'network-only',
                            variables: {
                                paginationInput: {
                                    limit:
                                        maxToLoad ||
                                        (isDesktop ? DESKTOP_LOAD_LIMIT : 10),
                                },
                            },
                        }
                    );

                if (result.data) {
                    setWinners(result.data.luckyStrikeGamesWinner.items);

                    setCurrentPage(
                        result.data.luckyStrikeGamesWinner.meta.currentPage
                    );
                    setTotalPages(
                        result.data.luckyStrikeGamesWinner.meta.totalPages
                    );
                }
            } catch (error) {
                errorsHandler(error, true);
            }
            stop();
        },
        [client]
    );

    const getWinnersLoadMore = useCallback(async () => {
        try {
            if (currentPage === totalPages || totalPages === 0) {
                return;
            }
            startLoadMoreWinners();

            const result =
                await client.query<PaginatedLuckyStrikeGamesWinnerOutputResponse>(
                    {
                        query: GET_LUCKY_STRIKE_GAMES_WINNER,
                        fetchPolicy: 'network-only',
                        variables: {
                            paginationInput: {
                                page: currentPage + 1,
                                limit:
                                    maxToLoad ||
                                    (isDesktop ? DESKTOP_LOAD_LIMIT : 10),
                            },
                        },
                    }
                );

            if (result.data) {
                setWinners((prev) => {
                    return prev
                        ? _.uniqBy(
                              [
                                  ...prev,
                                  ...result.data.luckyStrikeGamesWinner.items,
                              ],
                              (o) => o.number
                          )
                        : result.data.luckyStrikeGamesWinner.items;
                });

                setCurrentPage(
                    result.data.luckyStrikeGamesWinner.meta.currentPage
                );
                setTotalPages(
                    result.data.luckyStrikeGamesWinner.meta.totalPages
                );
            }
        } catch (error) {
            errorsHandler(error, true);
        }
        stopLoadMoreWinners();
    }, [client, totalPages, currentPage]);

    const canLoadMore = useMemo(
        () => currentPage !== totalPages,
        [currentPage, totalPages]
    );

    return {
        winners,
        winnersLoading,
        refreshWinnersLoading,
        loadMoreWinners,
        canLoadMore,
        getWinnersLoadMore,
        getWinners,
    };
};
