import * as React from 'react';

import useThemedStyles from '@hooks/useThemedStyles';
import NavigationBar from '@navigation/NavigationBar';

import stylesMain from './styles';

const OverviewNavigation = () => {
    const styles = useThemedStyles(stylesMain);

    return (
        <NavigationBar containerStyle={styles.navBar} backIcon="leftArrow" />
    );
};

export default OverviewNavigation;
