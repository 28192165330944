import * as React from 'react';

import CollectionAgeReviveCheckoutScreen from '../../screens/TabBar/Collection/CollectionAgeReviveCheckout/CollectionAgeReviveCheckoutScreen';
import CollectionAgeReviveSuccessScreen from '../../screens/TabBar/Collection/CollectionAgeReviveSuccess/CollectionAgeReviveSuccessScreen';
import CollectionDetailedPet from '../../screens/TabBar/Collection/CollectionDetailedPet/CollectionDetailedPetScreen';
import CollectionHealUpSuccessScreen from '../../screens/TabBar/Collection/CollectionHealUpSuccess/CollectionHealUpSuccessScreen';
import CollectionMultiplePetsHealUpCheckoutScreen from '../../screens/TabBar/Collection/CollectionMultiplePetsHealUpCheckout/CollectionMultiplePetsHealUpCheckoutScreen';
import CollectionMultiplePetsHealProceedingScreen from '../../screens/TabBar/Collection/CollectionMultiplePetsHealUpProceeding/CollectionMultiplePetsHealProceedingScreen';
import CollectionMultiplePetsHealUpSuccessScreen from '../../screens/TabBar/Collection/CollectionMultiplePetsHealUpSuccess/CollectionMultiplePetsHealUpSuccessScreen';
import CollectionPetHealUpCheckoutScreen from '../../screens/TabBar/Collection/CollectionPetHealUpCheckout/CollectionPetHealUpCheckoutScreen';
import CollectionPetLevelUpCheckout from '../../screens/TabBar/Collection/CollectionPetLevelUpCheckout/CollectionPetLevelUpCheckoutScreen';
import CollectionPetLevelUpSuccessScreen from '../../screens/TabBar/Collection/CollectionPetLevelUpSuccess/CollectionPetLevelUpSuccessScreen';
import CollectionPetRankUpCheckoutScreen from '../../screens/TabBar/Collection/CollectionPetRankUpCheckout/CollectionPetRankUpCheckoutScreen';
import CollectionPetRankUpSuccessScreen from '../../screens/TabBar/Collection/CollectionPetRankUpSuccess/CollectionPetRankUpSuccessScreen';
import CollectionReviveCheckoutScreen from '../../screens/TabBar/Collection/CollectionReviveCheckout/CollectionReviveCheckoutScreen';
import CollectionReviveSuccessScreen from '../../screens/TabBar/Collection/CollectionReviveSuccess/CollectionReviveSuccessScreen';
import ROUTES from '../routes';

const CollectionGroup = (RootStack: any) => {
    return (
        <>
            <RootStack.Screen
                name={ROUTES.COLLECTION_DETAILED_PET}
                options={{ gestureEnabled: true }}
                component={CollectionDetailedPet}
            />
            <RootStack.Screen
                name={ROUTES.COLLECTION_PET_LEVEL_UP_CHECKOUT}
                component={CollectionPetLevelUpCheckout}
            />
            <RootStack.Screen
                name={ROUTES.COLLECTION_PET_LEVEL_UP_SUCCESS}
                options={{ gestureEnabled: false }}
                component={CollectionPetLevelUpSuccessScreen}
            />
            <RootStack.Screen
                name={ROUTES.COLLECTION_PET_RANK_UP_CHECKOUT}
                options={{ gestureEnabled: false }}
                component={CollectionPetRankUpCheckoutScreen}
            />
            <RootStack.Screen
                name={ROUTES.COLLECTION_PET_RANK_UP_SUCCESS}
                options={{ gestureEnabled: false }}
                component={CollectionPetRankUpSuccessScreen}
            />
            <RootStack.Screen
                name={ROUTES.COLLECTION_PET_HEAL_UP_CHECKOUT}
                options={{ gestureEnabled: false }}
                component={CollectionPetHealUpCheckoutScreen}
            />
            <RootStack.Screen
                name={ROUTES.COLLECTION_PET_HEAL_UP_SUCCESS}
                options={{ gestureEnabled: false }}
                component={CollectionHealUpSuccessScreen}
            />
            <RootStack.Screen
                name={ROUTES.COLLECTION_MULTIPLE_PETS_HEAL_UP_CHECKOUT}
                options={{ gestureEnabled: false }}
                component={CollectionMultiplePetsHealUpCheckoutScreen}
            />
            <RootStack.Screen
                name={ROUTES.COLLECTION_MULTIPLE_PETS_HEAL_UP_PROCEEDING}
                options={{ gestureEnabled: false }}
                component={CollectionMultiplePetsHealProceedingScreen}
            />
            <RootStack.Screen
                name={ROUTES.COLLECTION_MULTIPLE_PETS_HEAL_UP_SUCCESS}
                options={{ gestureEnabled: false }}
                component={CollectionMultiplePetsHealUpSuccessScreen}
            />
            <RootStack.Screen
                name={ROUTES.COLLECTION_PET_REVIVE_CHECKOUT}
                options={{ gestureEnabled: false }}
                component={CollectionReviveCheckoutScreen}
            />
            <RootStack.Screen
                name={ROUTES.COLLECTION_PET_REVIVE_SUCCESS}
                options={{ gestureEnabled: false }}
                component={CollectionReviveSuccessScreen}
            />
            <RootStack.Screen
                name={ROUTES.COLLECTION_PET_AGE_REVIVE_CHECKOUT}
                options={{ gestureEnabled: false }}
                component={CollectionAgeReviveCheckoutScreen}
            />
            <RootStack.Screen
                name={ROUTES.COLLECTION_PET_AGE_REVIVE_SUCCESS}
                options={{ gestureEnabled: false }}
                component={CollectionAgeReviveSuccessScreen}
            />
        </>
    );
};

export default CollectionGroup;
