import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { errorsHandler } from '@helpers/errors';
import { waitForNavigation } from '@helpers/helpers';
import { useNftRoyaleMyPendingGames } from '@hooks/useNftRoyaleMyPendingGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import usePreviousGame from './usePreviousGame';

const useCreateNftRoyalGame = () => {
    const navigation = useNavigation();
    const { setNftRoyalCurrentGame, nftRoyaleGameConfig } = useMysteryGames();
    const {
        getNftRoyalMyPendingGamesLazy,
        getNftRoyalMyPendingGamesLazyLoading: createGameLoading,
    } = useNftRoyaleMyPendingGames();

    const handleCreateGame = async () => {
        try {
            const prevGames = await getNftRoyalMyPendingGamesLazy();

            if (prevGames.data?.nftRoyaleMyPendingGames.length) {
                setNftRoyalCurrentGame(
                    prevGames.data.nftRoyaleMyPendingGames[0]
                );
                await waitForNavigation();
                openPreviousGameModal();
                return;
            }
            navigation.navigate(ROUTES.NFT_ROYAL_CREATE_GAME_WELCOME);
        } catch (error) {
            errorsHandler(error, true);
        }
    };

    const handleFirstModalButton = () => {
        closePreviousGameModal();
        navigation.navigate(ROUTES.NFT_ROYAL_PLAY_GAME);
    };
    const handleSecondModalButton = () => {
        closePreviousGameModal();
    };

    const {
        openPreviousGameModal,
        closePreviousGameModal,
        renderPreviousGameModal,
    } = usePreviousGame(
        handleFirstModalButton,
        handleSecondModalButton,
        nftRoyaleGameConfig?.inactiveGameTerminationDays
    );

    return {
        handleCreateGame,
        renderPreviousGameModal,
        createGameLoading,
    };
};

export default useCreateNftRoyalGame;
