import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import Icon, { ICON_NAMES } from '../../Icons';
import SocialIcons from '../../Icons/SocialIcons';
import { Text, View } from '../../Themed';
import stylesMain from './styles';

interface IProps {
    id: string;
    actionWithParams?: (item: string) => void;
    actionWithOutParams?: () => void;
    isSelected?: boolean;
    text?: string | null;
    withLink?: boolean;
}

const SocialMediaSelectItem = ({
    id,
    actionWithParams,
    actionWithOutParams,
    isSelected,
    text,
    withLink,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <TouchableOpacity
            activeOpacity={0.7}
            onPress={() => actionWithParams?.(id) || actionWithOutParams?.()}
            style={[styles.shadow, styles.gap, isSelected && styles.active]}>
            <View
                style={[
                    styles.itemContainer,
                    isSelected && styles.activeContainer,
                ]}>
                <View style={styles.main}>
                    <View style={styles.left}>
                        <SocialIcons slug={id} size={32} />
                        {!!text ? (
                            <Text style={styles.itemText}>{`@${text}`}</Text>
                        ) : (
                            <Text style={styles.itemText}>
                                {i18n.t(
                                    `profile.socialMediaSettings.media.socials.${id}`
                                )}
                            </Text>
                        )}
                    </View>
                    {withLink && (
                        <View style={styles.link}>
                            <Text style={styles.linkText}>
                                {i18n.t(
                                    'socialMediaConnect.socialPreview.view'
                                )}
                            </Text>
                            <Icon
                                name={ICON_NAMES.EXPLORE}
                                size={20}
                                color={styles.linkText.color}
                            />
                        </View>
                    )}
                </View>
            </View>
        </TouchableOpacity>
    );
};

export default SocialMediaSelectItem;
