import * as React from 'react';

import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';
import NavigationBar from '@navigation/NavigationBar';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import TokenTellerHowItWorks from '../../components/TokenTellerHowItWorks';
import stylesMain from './styles';

const WelcomeTokenTellerScreen = () => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const handleNavigate = () => {
        navigation.replace(ROUTES.TOKEN_TELLER);
    };

    return (
        <View style={styles.main}>
            <NavigationBar
                isOneColor
                fillColor={styles.main.backgroundColor}
                filled={false}
                backIcon="close"
                title={i18n.t(
                    `HomeScreen.GamesBlock.games.${MYSTERY_GAMES.tokenteller}.title`
                )}
                titleColor={styles.title.color}
            />
            <TokenTellerHowItWorks onButtonPress={handleNavigate} />
        </View>
    );
};

export default WelcomeTokenTellerScreen;
