import * as React from 'react';
import ViewShot from 'react-native-view-shot';

import html2canvas from 'html2canvas';

import Permissions from '@constants/Permissions';
import { getRandomFilename } from '@helpers/media';
import { toastErrorUnknown } from '@helpers/toastNotification';

import { useHandlePermission } from '../useHandlePermission';

// Type of the object for sharing, is used by navigator.share
type ShareData = {
    url?: string;
    files?: File[];
    base64Image?: string; // custom property base64 of the image
};

const useSharePhoto = () => {
    const { renderItem: renderCameraRollPermissions } = useHandlePermission(
        Permissions.CAMERA_ROLL
    );

    const onShare = async (data: ShareData) => {
        try {
            // for http:// we need to check first if navigator.canShare is a function
            const canShareInWeb =
                navigator.canShare && navigator.canShare(data);

            if (canShareInWeb) {
                return navigator
                    .share(data)
                    .then(() => Console.log('[useShare] Share action'))
                    .catch((error) => {
                        // fallback download on share error
                        if (!error.toString().includes('cancellation')) {
                            downloadImage(data);
                        } else {
                            Console.error('[useShare] Share error', error);
                        }
                    });
            } else {
                Console.log(
                    '[useShare] Sharing is not working the browser, fallback to redirect'
                );

                return downloadImage(data);
            }
        } catch (error) {
            Console.error(error);
            toastErrorUnknown();
            return null;
        }
    };

    // Share link
    const handleShareLink = async (
        url: string,
        message?: string,
        title?: string
    ) => {
        return onShare({ url });
    };

    // Save to Camera Roll Link
    const handleSaveLink = async (url: string) => {
        // no implementation on web
    };

    // Share ViewShot
    const handleShareViewShot = async (
        viewShotRef: React.RefObject<HTMLElement>,
        message?: string,
        title?: string
    ) => {
        // if somehow ref is empty give an error in the console and exit
        if (!viewShotRef.current) {
            Console.error('View ref is empty');
            return null;
        }

        const canvas = await html2canvas(viewShotRef.current, {
            useCORS: true,
            allowTaint: false,
            backgroundColor: null,
        });

        // convert canvas to blob and invoke share function
        return canvas.toBlob(async (blob) => {
            const files = [
                new File([blob as BlobPart], getRandomFilename(), {
                    type: blob?.type,
                }),
            ];

            const data = {
                files,
                base64Image: canvas.toDataURL('image/png', 1.0),
            };

            return onShare(data);
        });
    };

    // Save to Camera Roll ViewShot
    const handleSaveViewShot = async (
        viewShotRef: React.RefObject<ViewShot>
    ) => {
        // No implementation on web, doing same as share
    };

    return {
        handleShareLink,
        handleSaveLink,
        handleShareViewShot,
        handleSaveViewShot,
        renderCameraRollPermissions,
    };
};

export default useSharePhoto;

export function downloadImage(data: ShareData) {
    if (data?.url) {
        window.location.href = data?.url;
        return;
    }

    const link = document.createElement('a');
    link.href = data.base64Image || '';
    link.download = getRandomFilename();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
