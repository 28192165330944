import * as React from 'react';
import { SafeAreaView } from 'react-native';

import Button from '@components/Button';
import LoadingComponent from '@components/LoadingComponent';
import ModalLoader from '@components/ModalLoader';
import PlayEnergy from '@components/PlayEnergy';
import { Text, View } from '@components/Themed';
import { PlayContext } from '@contexts/PlayContext';
import { prefetchImage } from '@helpers/media';
import useVerifyTask from '@hooks/tasks/useVerifyTask';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';

import { SocializeToEarnVerifyUserMatchProps } from '../../../../../types';
import LikeDislikeButtons from '../components/LikeDislikeButtons';
import MatchHandler from '../components/MatchHandler';
import stylesMain from './styles';

export const VerifyUserMatchScreen = ({
    route,
}: SocializeToEarnVerifyUserMatchProps) => {
    const styles = useThemedStyles(stylesMain);
    const { verifyTask, socialTask } = React.useContext(PlayContext);

    const { currentSubtask } = route.params;

    const [loading, setLoading] = React.useState<boolean>(true);

    React.useEffect(() => {
        const prefetchImages = async () => {
            if (
                currentSubtask &&
                socialTask?.latestTask.pet.image.thumbnailUrl &&
                currentSubtask.socialTask.pet.image.thumbnailUrl
            ) {
                await prefetchImage(
                    socialTask.latestTask.pet.image.thumbnailUrl
                );
                await prefetchImage(
                    currentSubtask.socialTask.pet.image.thumbnailUrl
                );
            }
            setLoading(false);
        };
        prefetchImages();
    }, [socialTask, currentSubtask]);

    const {
        verifyActionStatusButton,
        createVerificationTaskLoading,
        canStartNextTask,
    } = useVerifyTask();

    if (!currentSubtask || loading || !socialTask) {
        return <LoadingComponent fullPage />;
    }

    return (
        <>
            <NavigationBar backIcon={'close'} containerStyle={styles.navBg} />
            <View style={styles.main}>
                <View style={styles.matchWrapper}>
                    <MatchHandler
                        myPet={socialTask.latestTask.pet}
                        findPet={currentSubtask.socialTask.pet}
                    />
                </View>
            </View>
            <View style={styles.textWrapper}>
                <Text style={styles.title}>
                    {i18n.t('socializeToEarn.verifyUser.verifyMatch.title')}
                </Text>
                <Text style={styles.text}>
                    {i18n.t(
                        'socializeToEarn.verifyUser.verifyMatch.text.step1'
                    )}
                    <Text style={styles.textPet}>
                        {`"${socialTask?.latestTask.pet.name}"`}
                    </Text>
                    {i18n.t(
                        'socializeToEarn.verifyUser.verifyMatch.text.step2'
                    )}
                    <Text style={styles.textPet}>
                        {`"${currentSubtask?.socialTask.pet.name}"`}
                    </Text>
                    {i18n.t(
                        'socializeToEarn.verifyUser.verifyMatch.text.step3'
                    )}
                </Text>
            </View>
            <LikeDislikeButtons
                containerStyle={styles.likeDislikeButtons}
                id={currentSubtask?.id}
                task={currentSubtask}
            />
            {!!verifyTask && (
                <PlayEnergy
                    energy={verifyTask.totalEnergyRewarded || 0}
                    maxEnergy={verifyTask.maxEnergyReward}
                    containerStyle={styles.energyWrapper}
                />
            )}
            {!!verifyTask && (
                <View style={styles.buttonWrapper}>
                    <Button
                        title={
                            canStartNextTask
                                ? i18n.t(
                                      'socializeToEarn.verifyUser.verifyMatch.button1'
                                  )
                                : i18n.t(
                                      'socializeToEarn.verifyUser.verifyMatch.button2'
                                  )
                        }
                        onPress={verifyActionStatusButton}
                        debouncedPress
                        containerStyle={styles.gap}
                    />
                </View>
            )}
            <ModalLoader
                isVisible={createVerificationTaskLoading}
                text={i18n.t('general.loading')}
            />
            <SafeAreaView style={styles.safe} />
        </>
    );
};
export default VerifyUserMatchScreen;
