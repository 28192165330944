import React, { useMemo } from 'react';
import { TouchableOpacity } from 'react-native';

import { useDimensions } from '@contexts/DimensionsContext';
import { isDesktop } from '@helpers/app';

import { View } from '../../../components/Themed';
import useThemedStyles from '../../../hooks/useThemedStyles';
import stylesMain from './styles';
import IProps from './types';

const CustomModalBackdrop = ({ onClose, isHorizontal = false }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { windowHeight, windowWidth } = useDimensions();
    const horizontalStyles = useMemo(() => {
        if (isHorizontal)
            if (windowHeight < windowWidth) {
                return {
                    height: isDesktop ? '100%' : windowHeight,
                    width: isDesktop ? '100%' : windowWidth,
                };
            } else {
                return {
                    left: !isDesktop
                        ? windowWidth / 2 - windowHeight / 2
                        : undefined,
                    top: !isDesktop
                        ? windowHeight / 2 - windowWidth / 2
                        : undefined,
                    width: isDesktop ? '100%' : windowHeight,
                    height: isDesktop ? '100%' : windowWidth,
                };
            }

        return undefined;
    }, [isHorizontal, windowHeight, windowWidth]);

    return (
        <TouchableOpacity
            activeOpacity={1}
            onPress={() => {
                if (onClose) onClose();
            }}>
            <View style={[styles.backdrop, horizontalStyles]} />
        </TouchableOpacity>
    );
};

export default CustomModalBackdrop;
