import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { moderateScale, scale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        box: {
            width: scale(20),
            height: scale(20),
            borderRadius: moderateScale(4),
            padding: scale(4),
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            borderWidth: 1,
        },
        enabled: {
            backgroundColor: Colors[theme].checkBox.enabled.background,
            borderColor: Colors[theme].checkBox.enabled.border,
        },
        disabled: {
            backgroundColor: Colors[theme].checkBox.disabled.background,
            borderColor: Colors[theme].checkBox.disabled.border,
        },
        label: {
            flex: 1,
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
            marginLeft: scale(8),
        },
    });

export default styles;
