import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { moderateScale, scale, verticalScale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {},
        inputContainer: {
            position: 'relative',
        },
        input: {
            paddingTop: verticalScale(12),
            paddingBottom: verticalScale(12),
            paddingLeft: scale(16),
            paddingRight: scale(16),
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            color: Colors[theme].text.primary,
            backgroundColor: Colors[theme].inputBackground,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            zIndex: 2,
        },
        inputError: {
            borderColor: Colors[theme].incorrect,
        },
        inputFocused: {
            borderColor: Colors[theme].inputActiveBorder,
        },
        labelWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: verticalScale(6),
        },
        label: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
        },
        secondLabel: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
        },
        paddingLeft: {
            paddingLeft: scale(43),
        },
        paddingRight: {
            paddingRight: scale(50),
        },
        error: {
            paddingTop: verticalScale(4),
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
            color: Colors[theme].incorrect,
        },
        icon: {
            position: 'absolute',
            left: verticalScale(12),
            top: scale(12),
            zIndex: 5,
        },
    });

export default styles;
