import * as React from 'react';

import CheckoutSummary from '../../../components/CheckoutScreen/CheckoutSummary';
import CheckoutSuccessScreen from '../../../components/CheckoutSuccessScreen';
import Divider from '../../../components/Divider';
import { useAppState } from '../../../contexts/AppStateContext';
import { MintContext } from '../../../contexts/MintContext';
import { usePlay } from '../../../contexts/PlayContext';
import { useUser } from '../../../contexts/UserContext';
import { dateCooldownExpired, dateDiffInSeconds } from '../../../helpers/time';
import { useShowAppReview } from '../../../hooks/useShowAppReview';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import ROUTES from '../../../navigation/routes';
import { useNavigation } from '../../../navigation/useNavigation';
import { MintPetCheckoutSuccessProps } from '../../../types';
import TransactionDetailsBonusMint from '../components/TransactionDetailsBonusMint';
import TransactionDetailsMint from '../components/TransactionDetailsMint';
import stylesMain from './styles';

const config = {
    timespanReview: 60 * 60 * 1000,
};

const MintPetCheckoutSuccessScreen = ({
    route,
}: MintPetCheckoutSuccessProps) => {
    const { transactionResult, payment, isFree } = route.params;
    const { rank, reset } = React.useContext(MintContext);
    const { user, petsCount } = useUser();
    const { refetchPets } = usePlay();
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const { showReviewModal } = useShowAppReview();
    const { setIsMintForceSuccessScreen } = useAppState();
    const isFirstMint = petsCount <= 1;

    navigation.blockHardwareBack();

    React.useEffect(() => {
        refetchPets(true);
    }, []);

    // show review modal for new accounts with 1 pet
    const addReview = React.useCallback(() => {
        if (!user) {
            return;
        }

        if (
            isFirstMint &&
            dateDiffInSeconds(new Date(), user.createdAt) <=
                config.timespanReview
        ) {
            showReviewModal();
        }
    }, [user]);

    const resetMint = () => {
        setIsMintForceSuccessScreen(false);
        reset();
    };

    const handleBackToPlay = () => {
        addReview();

        if (isFirstMint) {
            navigation.reset({
                index: 1,
                routes: [{ name: ROUTES.MAIN }],
            });
            resetMint();
        } else {
            navigation.navigateToTasks(true);
            resetMint();
        }
    };

    const handleBackToCollection = () => {
        navigation.navigateToCollection();
        resetMint();
    };

    const isMintBonus = React.useMemo(
        () =>
            !!rank?.mintBonus && !dateCooldownExpired(rank?.mintBonus?.endsAt),
        [rank]
    );

    return (
        <CheckoutSuccessScreen
            title={i18n.t('mintCheckout.successTitle')}
            text={i18n.t('mintCheckout.successText')}
            onPressButtonMain={handleBackToPlay}
            buttonMainText={i18n.t('mintCheckout.backToPlaying')}
            onPressButtonAdditional={
                !isFirstMint ? handleBackToCollection : undefined
            }
            buttonAdditionalText={
                !isFirstMint
                    ? i18n.t('mintCheckout.backToCollection')
                    : undefined
            }
            explorerUrl={transactionResult?.explorerUrl}
            TransactionDetailsComponent={
                <>
                    <TransactionDetailsMint buttonsEdit={false} />
                    {isMintBonus && rank?.mintBonus && (
                        <>
                            <Divider customStyles={styles.divider} />
                            <TransactionDetailsBonusMint
                                mintBonus={rank.mintBonus}
                                slug={rank.slug}
                            />
                        </>
                    )}
                    <Divider customStyles={styles.divider} />
                    <CheckoutSummary
                        isFree={!!isFree}
                        payment={payment}
                        transactionResult={transactionResult ?? undefined}
                    />
                </>
            }
        />
    );
};

export default MintPetCheckoutSuccessScreen;
