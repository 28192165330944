import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import { BREEDING_BREED_PETS } from '@Data/Requests';
import { useMutation } from '@apollo/client';
import ICON from '@assets/icons/emptyState/nftEvolutionConfirmBreed.svg';
import AlertCard from '@components/AlertCard';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import GamingWalletChangeFeature from '@components/GamingWalletChangeFeature';
import { ICON_NAMES } from '@components/Icons';
import ModalBottom from '@components/ModalBottom';
import { Text } from '@components/Themed';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { errorsHandler } from '@helpers/errors';
import { wait } from '@helpers/helpers';
import { priceString } from '@helpers/payments';
import { balanceFromWei } from '@helpers/wallet';
import { useLoadingHook } from '@hooks/useLoadingHook';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import {
    BreedingPetDraftDetailsOutputResponse,
    MYSTERY_GAMES,
} from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import ConfirmationModal from '../ConfirmationModal';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
    modalHeight?: number | string;
    breedCost: string;
    onAction: () => void;
}

const ConfirmPlayModal = ({
    isVisible,
    onClose,
    modalHeight = 750,
    breedCost,
    onAction,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const {
        availableBalance,
        availableBalanceLoading,
        getGamingAvailableBalance,
        setTopUpFromMysteryGames,
    } = useMysteryGames();
    React.useEffect(() => {
        if (isVisible) {
            getGamingAvailableBalance(true);
        }
    }, [isVisible]);

    const enoughBalance = useMemo(
        () =>
            Number(balanceFromWei(breedCost).value) <=
            Number(balanceFromWei(availableBalance?.available).value),
        [availableBalance]
    );

    const {
        isVisible: isVisibleConfirmationModal,
        open: openConfirmationModal,
        close: closeConfirmationModal,
    } = useVisibleHook();

    const navigation = useNavigation();

    const topUp = () => {
        setTopUpFromMysteryGames(true);
        onClose();
        navigation.navigate(ROUTES.WALLET_ROOT, {
            screen: ROUTES.WALLET_GAMING_TAB,
            params: {
                screen: ROUTES.TOP_UP_GAMING_WALLET,
            },
        });
    };

    return (
        <ModalBottom
            isVisible={isVisible}
            onClose={onClose}
            modalHeight={modalHeight}>
            <ScrollView
                style={styles.container}
                showsVerticalScrollIndicator={false}>
                <TouchableOpacity activeOpacity={1}>
                    <BlockHeader
                        containerStyle={styles.header}
                        emptyStateIcon={<ICON />}
                        type="emptyState"
                        titleComponent={
                            <>
                                {i18n.t(
                                    `HomeScreen.NftEvolution.ConfirmBreedModal.title`
                                )}
                                <Text style={styles.accent}>
                                    {`${priceString(
                                        balanceFromWei(breedCost).valueLong,
                                        Coin.vigu
                                    )}`}
                                </Text>
                                {'?'}
                            </>
                        }
                        text={i18n.t(
                            `HomeScreen.NftEvolution.ConfirmBreedModal.text`
                        )}
                    />
                    <GamingWalletChangeFeature customStyles={styles.feature} />
                </TouchableOpacity>
            </ScrollView>
            {!enoughBalance && !availableBalanceLoading ? (
                <AlertCard
                    containerStyle={styles.alert}
                    text={i18n.t(`general.ALERTS.notEnoughCoinsOnGamingWallet`)}
                    type="error"
                    onAction={topUp}
                    actionButtonIcon={ICON_NAMES.DEPOSIT}
                    actionButtonType={'fill'}
                />
            ) : (
                <AlertCard
                    containerStyle={styles.alert}
                    text={i18n.t(`HomeScreen.NftEvolution.breedCost.alert`)}
                />
            )}

            <ButtonsBottom
                disabled={
                    availableBalanceLoading ||
                    !availableBalance ||
                    !enoughBalance
                }
                loading={availableBalanceLoading}
                title={i18n.t('general.BUTTONS.start')}
                onPress={openConfirmationModal}
                safe
            />

            <ConfirmationModal
                isVisible={isVisibleConfirmationModal}
                onClose={closeConfirmationModal}
                onConfirm={onAction}
                amount={balanceFromWei(breedCost).valueLong}
                game={MYSTERY_GAMES.nftEvolution}
            />
        </ModalBottom>
    );
};

export default ConfirmPlayModal;
