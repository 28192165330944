import * as React from 'react';
import { Image, ScrollView, TouchableOpacity } from 'react-native';

import { Exchange, ExchangesResponse } from '@Data/Models';
import { GET_EXCHANGES } from '@Data/Requests';
import { useQuery } from '@apollo/client';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { openUrlOrShowError } from '@helpers/linking';

import useThemedStyles from '../../hooks/useThemedStyles';
import i18n from '../../i18n/i18n';
import EmptyList from '../EmptyList';
import Icon, { ICON_NAMES } from '../Icons';
import CoinIcon from '../Icons/CoinIcon';
import LoadingComponent from '../LoadingComponent';
import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    coin?: Coin;
}

const ExchangesContent = ({ coin }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { data, loading } = useQuery<ExchangesResponse>(GET_EXCHANGES);
    const listData = data?.exchanges || [];

    const filteredData = React.useMemo(() => {
        if (coin) {
            return listData.filter((i) => i.tokens.includes(coin));
        }
        return listData;
    }, [listData, coin]);

    const handlePress = (link: string) => {
        openUrlOrShowError(link);
    };

    const renderItem = ({ item, index }: { item: Exchange; index: number }) => {
        return (
            <TouchableOpacity
                key={index}
                activeOpacity={0.8}
                style={[
                    styles.shadow,
                    index + 1 !== listData.length && styles.gap,
                ]}
                onPress={() => handlePress(item.url)}>
                <View style={[styles.itemContainer]}>
                    <View style={styles.left}>
                        <Image
                            style={{ width: 32, height: 32 }}
                            source={{ uri: item.iconUrl }}></Image>
                        <View style={styles.textWrapper}>
                            <Text style={styles.title}>{item.name}</Text>
                            <View style={styles.infoWrapper}>
                                <Text style={styles.infoText}>
                                    {`${i18n.t('wallet.available')} `}
                                </Text>
                                {item.tokens.map(
                                    (item: string, index: number) => (
                                        <View
                                            style={styles.coinItem}
                                            key={index}>
                                            <CoinIcon slug={item} />
                                            <Text style={styles.coinText}>
                                                {item}
                                            </Text>
                                        </View>
                                    )
                                )}
                            </View>
                        </View>
                    </View>
                    <View style={styles.exploreWrapper}>
                        <Text style={styles.textExplore}>
                            {i18n.t('wallet.view')}
                        </Text>
                        <Icon
                            name={ICON_NAMES.EXPLORE}
                            color={styles.iconGreen.color}
                            size={16}
                        />
                    </View>
                </View>
            </TouchableOpacity>
        );
    };

    return (
        <>
            {loading && <LoadingComponent />}
            <ScrollView showsVerticalScrollIndicator={false}>
                {filteredData.map((item, index) => renderItem({ item, index }))}
                {!loading && !filteredData.length && (
                    <EmptyList emptyTitle={i18n.t('general.comingSoon')} />
                )}
            </ScrollView>
        </>
    );
};
export default ExchangesContent;
