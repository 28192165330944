import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { Text, View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT } from '@components/TypoText/types';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { BreedingRanksStatsOutput } from '@models/mysteryGames';

import RankToBreedTableRow from '../RankToBreedTableRow';
import stylesMain from './styles';

interface IProps {
    containerStyle?: StyleProp<ViewStyle>;
    isOneColor?: boolean;
    data: BreedingRanksStatsOutput[];
    title: string;
    text: string;
}

const RankToBreedTable = ({
    containerStyle,
    isOneColor,
    data,
    title,
    text,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={[styles.container, containerStyle]}>
            <View style={styles.description}>
                <TypoText
                    type={TYPO_TEXT.HEADING_H6}
                    textAlign="center"
                    additionalStyles={styles.textGap}>
                    {title}
                </TypoText>
                <TypoText type={TYPO_TEXT.BODY_SMALL} textAlign="center">
                    {text}
                </TypoText>
            </View>

            <View style={styles.header}>
                <Text
                    numberOfLines={1}
                    style={[
                        styles.headerText,
                        styles.rank,
                        isOneColor && styles.isOneColorText,
                    ]}>
                    {i18n.t('general.KEYS.ranks')}
                </Text>

                <Text
                    numberOfLines={1}
                    style={[
                        styles.headerText,
                        styles.chance,
                        isOneColor && styles.isOneColorText,
                    ]}>
                    {i18n.t('general.KEYS.chance')}
                </Text>
            </View>
            {data.map((item, index) => (
                <RankToBreedTableRow
                    stat={item}
                    key={index}
                    isOneColor={isOneColor}
                    isLast={index === data.length - 1}
                />
            ))}
        </View>
    );
};
export default RankToBreedTable;
