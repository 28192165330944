import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { QUANTUM_SPIN_COLOR } from '@contexts/MysteryGamesContext';
import { scale, verticalScale } from '@helpers/dimensions';
import { stylesModalNavigationFix } from '@helpers/style';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
        },

        content: {
            flex: 1,
            paddingTop: verticalScale(20),
        },

        header: {
            paddingHorizontal: scale(20),
            marginBottom: verticalScale(16),
        },
        emptyStateWrapper: {
            marginTop: verticalScale(56),
        },
        emptyStateWrapperSmall: {
            marginTop: verticalScale(36),
        },
        emptyList: {
            marginTop: verticalScale(12),
            paddingBottom: 0,
        },
    });

export default styles;
