import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';
import { stylesAlignCenter } from '@helpers/style';

import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
        },
        content: {
            paddingHorizontal: scale(20),
            backgroundColor: 'transparent',
            ...stylesAlignCenter(),
            marginBottom: verticalScale(40),
        },

        titleEmptyState: {
            textAlign: 'center',
            color: Colors[theme].text.white,
            ...font({
                size: 32,
                height: 40,
                weight: '800',
            }),
            letterSpacing: -0.4,
            marginBottom: verticalScale(12),
        },
        textEmptyState: {
            textAlign: 'center',
            color: Colors[theme].text.white,
            ...font({
                size: 16,
                height: 24,
                weight: '500',
            }),
            letterSpacing: 0.16,
        },
        transparent: {
            backgroundColor: 'transparent',
        },
    });

export default styles;
