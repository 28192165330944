import * as React from 'react';
import { FlatList } from 'react-native';

import { Pet } from '@Data/Models';
import Button from '@components/Button';
import EmptyList from '@components/EmptyList';
import InfoIcon from '@components/InfoIcon/InfoIcon';
import PetCollectionListItem from '@components/PetCollectionListItem';
import { Text, View } from '@components/Themed';
import { sortPetsByLastTransferAndCreated } from '@helpers/sort';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import stylesMain from './styles';

interface IProps {
    pets: Pet[];
    loading?: boolean;
}

const WalletNFTCollection = ({ pets = [], loading = false }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const handleMintPetPress = () => {
        navigation.navigateToMint(undefined, true);
    };

    const handlePressViewAll = () => {
        navigation.navigate(ROUTES.WALLET_NFTS_LIST);
    };

    const handlePressPet = async (pet: any) => {
        navigation.navigateToPetDetails(pet);
    };

    return (
        <View style={styles.container}>
            <View style={styles.titleContainer}>
                <View style={styles.titleWrapper}>
                    <Text style={styles.title}>
                        {i18n.t('wallet.nft.myCollection')}
                    </Text>
                    <InfoIcon tooltipContent={i18n.t('wallet.nft.tooltip')} />
                </View>
                {pets.length > 3 && (
                    <Button
                        type={'text'}
                        title={i18n.t('wallet.nft.viewAll')}
                        onPress={handlePressViewAll}
                        size="md"
                        containerStyle={styles.viewAllButton}
                    />
                )}
            </View>
            <FlatList
                style={!!pets?.length && styles.flatList}
                data={pets.sort(sortPetsByLastTransferAndCreated).slice(0, 3)}
                renderItem={({ item }) => (
                    <PetCollectionListItem
                        style={styles.itemStyle}
                        pet={item}
                        canLvlUp={item.levelUpAvailable}
                        canRankUp={item.rankUpAvailable}
                        isDead={item.reviveAvailable}
                        isNeedToFeed={item.healUpAvailable}
                        isAgeDead={item.reviveAgeAvailable}
                        navigateBackOnUpgrade={ROUTES.WALLET_NFTS_MAIN}
                        onPressPet={() => handlePressPet(item)}
                    />
                )}
                keyExtractor={(item) => item.id}
                extraData={pets}
                numColumns={1}
                ListEmptyComponent={
                    <>
                        {!loading && (
                            <View style={styles.noNFTWrapper}>
                                <EmptyList
                                    emptyTitle={i18n.t('wallet.nft.noNFTYet')}
                                    emptyText={i18n.t(
                                        'wallet.nft.noNFTYetInfo'
                                    )}
                                    emptyButtonText={i18n.t(
                                        'wallet.nft.mintNewNft'
                                    )}
                                    emptyButtonStyles={styles.button}
                                    onPressButton={handleMintPetPress}
                                    withAnimation="cry"
                                />
                            </View>
                        )}
                    </>
                }
            />
        </View>
    );
};
export default WalletNFTCollection;
