import * as React from 'react';
import { useMemo } from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import * as Localization from 'expo-localization';

import Icon from '@assets/icons/emptyState/community.svg';
import Button from '@components/Button';
import { isAndroid } from '@helpers/app';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import LinkItem from '../../screens/Profile/SocialMedia/components/LinkItem';
import { getRecommendedChat } from '../../screens/Profile/SocialMedia/socials';
import BlockHeader from '../BlockHeader/BlockHeader';
import ButtonsBottom from '../ButtonsBottom';
import ModalBottom from '../ModalBottom';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onPress: () => void;
    onClose: () => void;
    onSave: () => void;
    modalHeight?: number | string;
}

const ModalCommunity = ({
    isVisible,
    onPress,
    onClose,
    onSave,
    modalHeight = '95%',
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const recommendedChat = useMemo(() => {
        return getRecommendedChat(Localization.locale);
    }, []);

    return (
        <ModalBottom
            isVisible={isVisible}
            onClose={onClose}
            modalHeight={modalHeight}>
            <ScrollView
                style={styles.container}
                showsVerticalScrollIndicator={false}>
                <TouchableOpacity activeOpacity={1}>
                    <BlockHeader
                        type="emptyState"
                        title={i18n.t('communityModal.title')}
                        text={i18n.t('communityModal.text')}
                        emptyStateIcon={<Icon />}
                    />
                </TouchableOpacity>
                <LinkItem
                    key={recommendedChat.id}
                    item={recommendedChat}
                    type="telegram"
                />
            </ScrollView>
            <ButtonsBottom
                title={i18n.t('communityModal.button1')}
                onPress={onPress}
                safe={!isAndroid}>
                <Button
                    containerStyle={styles.button}
                    debouncedPress
                    type="text"
                    textStyles={styles.textStyle}
                    title={i18n.t('communityModal.button2')}
                    onPress={onSave}
                />
            </ButtonsBottom>
        </ModalBottom>
    );
};

export default ModalCommunity;
