import * as React from 'react';
import { useCallback } from 'react';
import {
    Animated,
    FlatList,
    RefreshControl,
    SafeAreaView,
    ScrollView,
    StyleProp,
    TouchableOpacity,
    ViewStyle,
} from 'react-native';

import LoadingComponent from '@components/LoadingComponent';
import { Text, View } from '@components/Themed';
import { useAppState } from '@contexts/AppStateContext';
import { combineStyle, stylesLoadMore } from '@helpers/style';

import { isAndroid, isDesktop, isWeb, isWebAndroid } from '../../helpers/app';
import { wait } from '../../helpers/helpers';
import { toastError } from '../../helpers/toastNotification';
import useThemedStyles from '../../hooks/useThemedStyles';
import i18n from '../../i18n/i18n';
import { useNavigation } from '../../navigation/useNavigation';
import { ComponentAnyType } from '../../types';
import PullToRefreshArrow from './components/PullToRefreshArrow';
import PullToRefreshHeader from './components/PullToRefreshHeader';
import RefreshControlWeb from './components/RefreshControlWeb/index.web';
import Styles from './styles';

interface IProps<T> {
    renderItem: (props?: { item: T }) => JSX.Element | null;
    onRefresh: () => void;
    canRefresh?: boolean;
    refreshing: boolean;
    textLoading?: string;
    textReleasing?: string;
    textPulling?: string;
    keyboardDismiss?: boolean;
    FooterComponent?: ComponentAnyType;
    HeaderComponent?: ComponentAnyType;
    EmptyComponent?: ComponentAnyType;
    renderData: Array<T> | null;
    contentContainerStyle?: StyleProp<ViewStyle>;
    loadMore?: boolean;
    contentLoading?: boolean;
    onReachEnd?: () => void;
    shouldUseEmptyComponentInFooter?: boolean;
    canLoadMore?: boolean;
    columnWrapperStyle?: StyleProp<ViewStyle>;
    numOfColumns?: number;
    moveItemFromScreen?: boolean;
}

const config = {
    timeout: 30000, // time will work when the bad or slow request
    refreshingHeight: 100, // Refreshing height in px
    headerPaddingTop: 70, // The paddingTop of the header element with animation
    minAnimationDuration: 1000, // Minimum amount of time when animation will be running. If promise resolved in 300s, it will still run at least 1000
    slidingAnimationDuration: 500, // Animation sliding back duration time
};

const PullToRefresh = <T,>({
    renderItem,
    onRefresh,
    canRefresh = true,
    refreshing,
    textLoading = i18n.t('pullToRefresh.loading'),
    textPulling = i18n.t('pullToRefresh.pulling'),
    textReleasing = i18n.t('pullToRefresh.releasing'),
    keyboardDismiss = false,
    FooterComponent = null,
    renderData = [],
    HeaderComponent = null,
    contentContainerStyle,
    loadMore,
    contentLoading,
    EmptyComponent,
    onReachEnd,
    shouldUseEmptyComponentInFooter = false,
    canLoadMore,
    columnWrapperStyle,
    numOfColumns,
    moveItemFromScreen,
}: IProps<T>) => {
    const styles = useThemedStyles(Styles);
    const { isConnected } = useAppState();
    const navigation = useNavigation();

    const [startTimestamp, setStartTimestamp] = React.useState(0);
    const [height, setHeight] = React.useState(0);
    const [refreshingWeb, setRefreshingWeb] = React.useState(false);

    const [extraPaddingTop] = React.useState(new Animated.Value(0));
    const timerRef = React.useRef<any>(null);
    // special states which helps to make animation more smooth and run at least minAnimationDuration
    const [animationRefreshing, setAnimationRefreshing] =
        React.useState<boolean>(false);

    const offsetY = React.useRef<number>(0);
    const refresh = React.useRef<boolean>(true);

    const [progressViewOffset, setProgressViewOffset] = React.useState<
        undefined | number
    >(undefined);
    const goBackEventWasHandled = React.useRef(false);

    // end of animation refreshing
    const endAnimatedRefreshing = () => {
        Animated.timing(extraPaddingTop, {
            toValue: 0,
            duration: config.slidingAnimationDuration,
            useNativeDriver: false,
        }).start();
        setAnimationRefreshing(false);
        refresh.current = false;
    };

    React.useEffect(() => {
        let isMounted = true;

        if (refreshing && refresh.current) {
            if (!isConnected) {
                toastError(
                    'No connection',
                    'Make sure you have Internet connection'
                );
                return;
            }

            setAnimationRefreshing(true);

            Animated.timing(extraPaddingTop, {
                toValue: config.headerPaddingTop,
                duration: 0,
                useNativeDriver: false,
            }).start();

            timerRef.current = setTimeout(() => {
                if (isMounted) endAnimatedRefreshing();
            }, config.timeout);

            if (isMounted) setStartTimestamp(Date.now());
        } else if (refresh.current) {
            const endTimestamp = Date.now();
            const difference = endTimestamp - startTimestamp;

            wait(
                difference < config.minAnimationDuration
                    ? config.minAnimationDuration - difference
                    : 0
            ).then(() => {
                clearTimeout(timerRef.current);
                endAnimatedRefreshing();
            });
        }

        return () => {
            isMounted = false;
        };
    }, [refreshing, refresh, isConnected]);

    // fix for bug https://github.com/iguverse/tasks-app/issues/592
    // when the navigation event GO_BACK, move the RefreshControl off the screen
    React.useEffect(() => {
        if (isAndroid) {
            const unsubscribe = navigation.addListener(
                'beforeRemove',
                (event: any) => {
                    if (
                        event.data.action.type === 'GO_BACK' &&
                        !goBackEventWasHandled.current
                    ) {
                        event.preventDefault();
                        goBackEventWasHandled.current = true;
                        setProgressViewOffset(-1000);
                    }
                }
            );

            return unsubscribe;
        }
    }, [navigation]);

    // after RefreshControl is moved do navigation.goBack()
    React.useEffect(() => {
        if (isAndroid) {
            if (progressViewOffset !== undefined) {
                navigation.goBack();
            }
        }
    }, [navigation, progressViewOffset]);

    const [arrowType, setArrowType] = React.useState(false);

    function onScroll(event: { nativeEvent: any }) {
        if (!canRefresh) {
            return;
        }

        const { nativeEvent } = event;
        const { contentOffset } = nativeEvent;
        const { y } = contentOffset;
        offsetY.current = y;
        setArrowType(offsetY.current <= -config.refreshingHeight);
    }

    function onRelease() {
        if (!canRefresh) {
            return;
        }
        refresh.current = true;
        if (
            offsetY.current <= -config.refreshingHeight &&
            (!refreshing || !refresh.current)
        ) {
            onRefresh();
            Console.log('Pull To Refresh released. Running onRefresh()');
        }
    }

    // resets animation for Android
    const onRefreshReset = () => {
        refresh.current = !refresh.current;
        onRefresh();
    };

    const onRefreshWeb = () => {
        if (!isConnected) {
            toastError(
                'No connection',
                'Make sure you have Internet connection'
            );
            return;
        }
        setRefreshingWeb(true);
        onRefresh();
    };

    React.useEffect(() => {
        if (!refreshing && isWebAndroid) {
            setRefreshingWeb(false);
        }
    }, [refreshing]);

    // FOR DESKTOP
    const footerComponent = useCallback(() => {
        if (shouldUseEmptyComponentInFooter) {
            if (!renderData?.length) {
                return EmptyComponent;
            }
        }
        return FooterComponent;
    }, [
        EmptyComponent,
        renderData,
        FooterComponent,
        shouldUseEmptyComponentInFooter,
    ]);

    // we do not use this feature on web
    if (isDesktop) {
        return (
            <ScrollView
                showsVerticalScrollIndicator={false}
                contentContainerStyle={combineStyle(
                    { flex: 1 },
                    contentContainerStyle
                )}>
                <>
                    {HeaderComponent}
                    {contentLoading && <LoadingComponent />}
                    {moveItemFromScreen && (
                        <View style={styles.moveItemFromScreen} />
                    )}
                    {numOfColumns ? (
                        <View style={styles.moreColumns}>
                            {renderData?.map((item, key) => (
                                <React.Fragment key={key}>
                                    {renderItem({ item })}
                                </React.Fragment>
                            ))}
                        </View>
                    ) : (
                        renderData?.map((item, key) => (
                            <React.Fragment key={key}>
                                {renderItem({ item })}
                            </React.Fragment>
                        ))
                    )}
                    {canLoadMore ? (
                        !!renderData?.length ? (
                            loadMore ? (
                                <View style={stylesLoadMore()}>
                                    <LoadingComponent />
                                </View>
                            ) : (
                                <TouchableOpacity
                                    onPress={onReachEnd}
                                    activeOpacity={0.7}>
                                    <Text style={styles.loadMoreText}>
                                        {i18n.t('general.loadMore')}
                                    </Text>
                                </TouchableOpacity>
                            )
                        ) : (
                            <></>
                        )
                    ) : (
                        <></>
                    )}
                    {footerComponent()}
                </>
            </ScrollView>
        );
    }

    const renderRefreshControl = () => {
        if (isWebAndroid && canRefresh) {
            return (
                <RefreshControlWeb
                    onRefresh={onRefreshWeb}
                    refreshing={refreshingWeb}
                    progressViewOffset={progressViewOffset}
                    textReleasing={textReleasing || ''}
                    textPulling={textPulling || ''}
                    textLoading={textLoading || ''}
                />
            );
        }
        if (isAndroid) {
            return (
                <RefreshControl
                    onRefresh={onRefreshReset}
                    refreshing={false}
                    progressBackgroundColor={
                        styles.progressBackgroundColor.color
                    }
                    colors={[styles.progressIndicator.color]}
                    progressViewOffset={progressViewOffset}
                />
            );
        }
        return <></>;
    };

    return (
        <SafeAreaView style={styles.container}>
            <FlatList
                removeClippedSubviews={false}
                showsVerticalScrollIndicator={false}
                contentContainerStyle={combineStyle(
                    isWeb && !isDesktop && height > 0 && { height: height + 1 },
                    contentContainerStyle
                )}
                numColumns={numOfColumns}
                columnWrapperStyle={columnWrapperStyle}
                keyboardShouldPersistTaps={
                    keyboardDismiss ? 'handled' : 'never'
                }
                refreshControl={renderRefreshControl()}
                onScroll={onScroll}
                onResponderRelease={onRelease}
                data={renderData}
                style={styles.flatlist}
                onLayout={({
                    nativeEvent: {
                        layout: { height },
                    },
                }) => {
                    setHeight(height);
                }}
                onEndReached={onReachEnd}
                ListEmptyComponent={!contentLoading ? EmptyComponent : <></>}
                onEndReachedThreshold={0.1}
                renderItem={renderItem}
                ListHeaderComponent={
                    <>
                        {!isWebAndroid && canRefresh && (
                            <>
                                <PullToRefreshHeader
                                    textLoading={textLoading}
                                    isRefreshing={animationRefreshing}>
                                    <PullToRefreshArrow
                                        arrowType={arrowType}
                                        offsetY={offsetY}
                                        textLoading={textLoading}
                                        textReleasing={textReleasing}
                                        textPulling={textPulling}
                                        isRefreshing={animationRefreshing}
                                    />
                                </PullToRefreshHeader>
                                <Animated.View
                                    style={{ height: extraPaddingTop }}
                                />
                            </>
                        )}
                        {HeaderComponent}
                        {contentLoading && <LoadingComponent />}
                        {!isWeb && moveItemFromScreen && (
                            <View style={styles.moveItemFromScreen} />
                        )}
                    </>
                }
                ListFooterComponent={FooterComponent}
            />
            {loadMore && !contentLoading && !!renderData?.length && (
                <View style={stylesLoadMore()}>
                    <LoadingComponent />
                </View>
            )}
        </SafeAreaView>
    );
};

PullToRefresh.defaultProps = {
    // eslint-disable-next-line react/default-props-match-prop-types
    renderData: [' '],
};

export default PullToRefresh;
