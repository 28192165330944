import { useCallback, useEffect, useRef, useState } from 'react';
import WebView from 'react-native-webview';

import { setStatusBarHidden } from 'expo-status-bar';

import {
    GET_NFT_ROYAL_GAME_HISTORY,
    NFT_ROYAL_REGISTER_GAME_EVENT,
} from '@Data/Requests';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useAccessibilitySettings } from '@contexts/AccessibilitySettingsContext';
import { useDimensions } from '@contexts/DimensionsContext';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { useTheme } from '@contexts/ThemeContext';
import { useUser } from '@contexts/UserContext';
import { isAndroid, isWeb } from '@helpers/app';
import { errorsHandler } from '@helpers/errors';
import { wait } from '@helpers/helpers';
import { toastError, toastErrorUnknown } from '@helpers/toastNotification';
import { useLoadingHook } from '@hooks/useLoadingHook';
import { useNftRoyaleMyPendingGames } from '@hooks/useNftRoyaleMyPendingGames';
import { useVisibleHook } from '@hooks/useVisibleHook';
import {
    NFT_ROYAL_GAME_EVENT,
    NftRoyaleGameHistoryOutputResponse,
} from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import ScreenOrientationManager from '../../../../../utils/ScreenOrientationManager';
import {
    GameData,
    UnityMessage,
    messageTypes,
    sender,
} from '../../../Play/PlayToEarn/screens/playToEarnProgress/config';
import useNftRoyaleGameEventInputData from '../../hooks/useNftRoyaleGameEventInputData';
import { eventData, serializeGameConfig } from './helper';

const timerConfig = {
    initial: 1000,
    prepare: 2000,
    analyze: 80000,
    orientation: 500,
};

interface IEventMessage {
    distanceMeters: number;
    speedMetersPerSecond: number;
    fruitsCollected: number;
}

export const useNftRoyalGameProgress = (isNewCreated: boolean | undefined) => {
    const { theme } = useTheme();
    const navigation = useNavigation();

    navigation.blockHardwareBack();

    const unityWrapperRef = useRef<WebView & HTMLIFrameElement>();
    const { setIsRotated, setWebContainerIsVisible } = useDimensions();
    const [renderAttempt, setRenderAttempt] = useState<number>(1);

    const {
        isVisible: gameVisible,
        close: removeGame,
        open: showGame,
    } = useVisibleHook();

    const {
        isVisible: errorModal,
        open: errorModalOpen,
        close: errorModalClose,
    } = useVisibleHook();

    const gameResult = useRef<GameData>();

    const [errorText, setErrorText] = useState<string>('');
    const [progress, setProgress] = useState<number>(0);
    const [showLoader, setShowLoader] = useState<boolean>(true);

    const { setIsFeedbackDisabled } = useAccessibilitySettings();

    useEffect(() => {
        setIsFeedbackDisabled(true);
        setStatusBarHidden(true, 'none');
        return () => {
            setStatusBarHidden(false, 'none');
            setIsRotated(false);
            setIsFeedbackDisabled(false);
        };
    }, []);

    const [prepareLoading, setPrepareLoading] = useState<boolean>(true);
    const [finishLoading, setFinishLoading] = useState<boolean>(false);
    const [showTimerLoading, setShowTimerLoading] = useState<boolean>(false);

    const {
        isLoading: absoluteLoading,
        startLoading: startAbsoluteLoading,
        stopLoading: stopAbsoluteLoading,
    } = useLoadingHook();

    const {
        nftRoyalCurrentGame,
        setCurrentNftRoyalGameHistory,
        clearNftRoyal,
    } = useMysteryGames();
    const { user } = useUser();
    const { getEncryptionIV, getEncryptionKey, getSignature } =
        useNftRoyaleGameEventInputData();

    const [encryptionKey, setEncryptionKey] = useState<Uint8Array>();
    const [encryptionIV, setEncryptionIV] = useState<Uint8Array>();

    useEffect(() => {
        return () => clearNftRoyal();
    }, []);

    const { getNftRoyalMyPendingGamesLazy } = useNftRoyaleMyPendingGames();

    useEffect(() => {
        const initialLoading = async () => {
            await wait(timerConfig.initial);
            if (!nftRoyalCurrentGame || !user) {
                navigation.pop();
                return;
            }
            const pendingGames = await getNftRoyalMyPendingGamesLazy();

            if (
                !pendingGames.data?.nftRoyaleMyPendingGames.length ||
                pendingGames.data.nftRoyaleMyPendingGames.findIndex(
                    (i) => i.id === nftRoyalCurrentGame.id
                ) === -1
            ) {
                navigation.pop();
                return;
            }

            setEncryptionKey(
                await getEncryptionKey(`${user.id}:${nftRoyalCurrentGame.id}`)
            );
            setEncryptionIV(await getEncryptionIV(nftRoyalCurrentGame.id));
            showGame();
        };
        initialLoading();
    }, [nftRoyalCurrentGame, user?.id]);

    const setOrientation = async (type: 'landscape' | 'default') => {
        startAbsoluteLoading();
        if (type === 'default') {
            await ScreenOrientationManager.setDefault();
            setIsRotated(false);
            setWebContainerIsVisible(true);
        } else {
            await ScreenOrientationManager.setLandscape();
            setIsRotated(true);
            setWebContainerIsVisible(false);
        }
        await wait(timerConfig.orientation);
        stopAbsoluteLoading();
    };

    const onReady = async () => {
        await wait(timerConfig.prepare);
        setPrepareLoading(false);
        startAbsoluteLoading();
        await setOrientation('landscape');
        setShowTimerLoading(true);
        setErrorText('');
        errorModalClose();
    };

    const [registerEvent] = useMutation(NFT_ROYAL_REGISTER_GAME_EVENT, {
        fetchPolicy: 'network-only',
    });
    const [getNftRoyaleGameHistory] =
        useLazyQuery<NftRoyaleGameHistoryOutputResponse>(
            GET_NFT_ROYAL_GAME_HISTORY,
            {
                fetchPolicy: 'network-only',
            }
        );

    const [isGameEnd, setIsGameEnd] = useState<boolean>(false);

    const handleRegisterEvent = useCallback(
        async (event: NFT_ROYAL_GAME_EVENT, message?: IEventMessage) => {
            if (!encryptionKey || !encryptionIV) {
                return null;
            }
            await registerEvent({
                variables: {
                    input: {
                        data: getSignature(
                            encryptionKey,
                            encryptionIV,
                            eventData(event, message)
                        ),
                        gameId: nftRoyalCurrentGame?.id,
                    },
                },
            });
        },
        [encryptionIV, encryptionKey, nftRoyalCurrentGame?.id]
    );

    const onTimerEnd = useCallback(async () => {
        setShowTimerLoading(false);
        if (isWeb) unityWrapperRef.current?.focus();
        handleSendMessage({
            message: messageTypes.play,
            messageBody: undefined,
        });
        await handleRegisterEvent(NFT_ROYAL_GAME_EVENT.Start);
    }, [handleRegisterEvent]);

    const onGameFinishExit = useCallback(
        async (message: IEventMessage) => {
            setIsGameEnd(true);
            startAbsoluteLoading();
            removeGame();
            await handleRegisterEvent(
                NFT_ROYAL_GAME_EVENT.CactusCollided,
                message
            );
            await handleRegisterEvent(NFT_ROYAL_GAME_EVENT.Finish, message);
            await setOrientation('default');
            setFinishLoading(true);
            !isNewCreated && (await wait(timerConfig.analyze));
            const result = await getNftRoyaleGameHistory({
                variables: {
                    gameId: nftRoyalCurrentGame?.id,
                },
            });
            if (result.data?.nftRoyaleGameHistory) {
                setCurrentNftRoyalGameHistory(result.data.nftRoyaleGameHistory);
                navigation.replace(ROUTES.NFT_ROYAL_GAME_HISTORY);
            } else {
                toastErrorUnknown();
                navigation.pop();
            }
        },
        [nftRoyalCurrentGame, handleRegisterEvent, isNewCreated]
    );

    // TODO Trusted Events

    const handleOnMessage = useCallback(
        async (unityMessage: UnityMessage) => {
            if (isGameEnd) {
                return;
            }
            let { messageBody } = unityMessage;

            if (messageBody && typeof messageBody === 'string') {
                messageBody = JSON.parse(messageBody);
            }

            try {
                switch (unityMessage.message) {
                    case 'setLoadingProgressValue': {
                        setProgress(messageBody.value);
                        break;
                    }
                    case 'onReady': {
                        await onReady();
                        break;
                    }

                    case 'gameInited': {
                        handleLoadEnd();
                        break;
                    }

                    case NFT_ROYAL_GAME_EVENT.CactusCollided: {
                        await handleRegisterEvent(
                            NFT_ROYAL_GAME_EVENT.CactusCollided,
                            messageBody
                        );

                        break;
                    }
                    case NFT_ROYAL_GAME_EVENT.CactusAvoided: {
                        await handleRegisterEvent(
                            NFT_ROYAL_GAME_EVENT.CactusAvoided,
                            messageBody
                        );

                        break;
                    }

                    case NFT_ROYAL_GAME_EVENT.FruitAvoided: {
                        await handleRegisterEvent(
                            NFT_ROYAL_GAME_EVENT.FruitAvoided,
                            messageBody
                        );

                        break;
                    }

                    case NFT_ROYAL_GAME_EVENT.FruitCollided: {
                        await handleRegisterEvent(
                            NFT_ROYAL_GAME_EVENT.FruitCollided,
                            messageBody
                        );
                        break;
                    }

                    case 'loose': {
                        await onGameFinishExit(messageBody.eventData);
                        break;
                    }

                    case 'error': {
                        setErrorText(messageBody.error.toString());
                        errorModalOpen();
                        break;
                    }
                    default: {
                        console.error('undefined message title');
                    }
                }
            } catch (error) {
                setFinishLoading(false);
                navigation.pop();
                errorsHandler(error, true);
            }
        },
        [
            encryptionIV,
            encryptionKey,
            handleRegisterEvent,
            onGameFinishExit,
            isGameEnd,
        ]
    );

    const onGameExit = async () => {
        removeGame();
        await setOrientation('default');
        navigation.pop();
    };

    const handleLoadEnd = useCallback(async () => {
        if (!nftRoyalCurrentGame) {
            navigation.pop();
            toastError('Current game error');
            return;
        }
        handleSendMessage({
            message: messageTypes.getLaunchData,
            messageBody: {
                ...serializeGameConfig(nftRoyalCurrentGame.gameplayConfig),
                theme,
            },
        });
    }, [theme, nftRoyalCurrentGame]);
    const handleSendMessage = (unityMessage: UnityMessage) => {
        const { message, messageBody } = unityMessage;

        const data = {
            sender,
            message,
            data: messageBody,
        };

        if (isWeb) {
            unityWrapperRef.current?.contentWindow?.postMessage(
                JSON.stringify(data),
                '*'
            );
        } else if (isAndroid) {
            const jsonData = JSON.stringify(data);

            const INJECTED_JAVASCRIPT = `(function() {
                window.postMessage(JSON.stringify(${jsonData}),'*');
            })()`;

            unityWrapperRef.current?.injectJavaScript(INJECTED_JAVASCRIPT);
        } else {
            unityWrapperRef.current?.postMessage(JSON.stringify(data));
        }
    };

    const handleOnErrorAgree = useCallback(() => {
        setErrorText('');
        errorModalClose();
        setRenderAttempt((num) => num + 1);
    }, [errorModalClose]);

    const exitOnErrorModal = async () => {
        setErrorText('');
        errorModalClose();
        await onGameExit();
    };
    return {
        gameResult,
        showLoader,
        progress,
        onTimerEnd,
        handleOnMessage,
        handleLoadEnd,
        setShowLoader,
        unityWrapperRef,
        renderAttempt,
        errorModalOpen,
        errorModal,
        handleOnErrorAgree,
        exitOnErrorModal,
        errorText,
        prepareLoading,
        showTimerLoading,
        setShowTimerLoading,
        finishLoading,
        absoluteLoading,
        gameVisible,
        nftRoyalCurrentGame,
    };
};
