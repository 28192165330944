import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            backgroundColor: Colors[theme].background,
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(12),
            flexDirection: 'row',
            alignItems: 'center',
        },
        titleBlock: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        name: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '600',
            }),
            marginLeft: scale(6),
            flex: 1,
        },
        textWrapper: {
            flex: 1,
        },
        imgWrapper: {
            width: 64,
            marginRight: scale(12),
        },
        footer: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    });

export default styles;
