import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import useThemedStyles from '../../hooks/useThemedStyles';
import { ComponentAnyType } from '../../types';
import Animation from '../Animation';
import { AnimationType } from '../Animation/types';
import Button from '../Button';
import ImageIguana from '../ImageIguana';
import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    viewHeight?: number;
    emptyTitle?: string;
    emptyText?: string;
    emptyButtonText?: string;
    onPressButton?: () => void;
    containerStyle?: ViewStyle;
    emptyButtonStyles?: ViewStyle;
    ButtonComponent?: ComponentAnyType;
    withIcon?: boolean | ComponentAnyType;
    withAnimation?: AnimationType;
    big?: boolean;
    buttonWrapperStyles?: StyleProp<ViewStyle>;
    loading?: boolean;
    disabled?: boolean;
    tailTitle?: boolean;
}

const EmptyList = ({
    viewHeight,
    emptyTitle,
    emptyText,
    emptyButtonText,
    onPressButton,
    containerStyle,
    emptyButtonStyles,
    ButtonComponent,
    withIcon = true,
    withAnimation,
    big = false,
    buttonWrapperStyles,
    loading,
    disabled,
    tailTitle,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View
            style={[
                styles.emptyWrapper,
                containerStyle,
                viewHeight ? { height: viewHeight } : {},
            ]}>
            {!!withAnimation ? (
                <View
                    style={[
                        styles.emptyAnimation,
                        big
                            ? styles.emptyAnimationBig
                            : styles.emptyAnimationSmall,
                    ]}>
                    <Animation animation={withAnimation} isPlaying />
                </View>
            ) : (
                <>
                    {withIcon && (
                        <View
                            style={
                                big
                                    ? styles.emptyIconBig
                                    : styles.emptyIconSmall
                            }>
                            {withIcon === true ? (
                                <ImageIguana
                                    type="cry"
                                    size={big ? 'big' : 'small'}
                                />
                            ) : (
                                !!withIcon && <>{withIcon}</>
                            )}
                        </View>
                    )}
                </>
            )}
            <Text style={styles.emptyTitle}>{emptyTitle}</Text>
            <Text style={styles.emptyInfo}>{emptyText}</Text>
            <View style={[styles.buttonWrapper, buttonWrapperStyles]}>
                {onPressButton && (
                    <Button
                        onPress={onPressButton}
                        title={emptyButtonText}
                        style={emptyButtonStyles || {}}
                        debouncedPress
                        loading={loading}
                        disabled={disabled}
                        tailTitle={tailTitle}
                    />
                )}
                {ButtonComponent}
            </View>
        </View>
    );
};
export default EmptyList;
