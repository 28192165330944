import { ReactElement } from 'react';
import { SvgProps } from 'react-native-svg';

import AntiCheatingIcon from '../../assets/icons/play/tasks/move/imageCarousel/antiCheating.svg';
import CompleteIcon from '../../assets/icons/play/tasks/move/imageCarousel/complete.svg';
import WalkPetIcon from '../../assets/icons/play/tasks/move/imageCarousel/walkPet.svg';
import EnergyIcon from '../../assets/icons/play/tasks/play/imageCarousel/energy.svg';
import FruitsIcon from '../../assets/icons/play/tasks/play/imageCarousel/fruits.svg';
import ObstacleIcon from '../../assets/icons/play/tasks/play/imageCarousel/obstacle.svg';
import Referral from '../../assets/icons/play/tasks/social/imageCarousel/referral.svg';
import ShareIcon from '../../assets/icons/play/tasks/social/imageCarousel/sharing.svg';
import SocialMediaIcon from '../../assets/icons/play/tasks/social/imageCarousel/socialMedia.svg';
import BeResponsibleIcon from '../../assets/icons/play/tasks/verify/imageCarousel/beResponsible.svg';
import FindIcon from '../../assets/icons/play/tasks/verify/imageCarousel/find.svg';

export interface IImagesData {
    icon: ReactElement<SvgProps>;
    title: string;
    text: string;
    energyText?: string;
    energy?: number;
}
export type ImagesDataType = IImagesData[];

export const socializeToEarn: ImagesDataType = [
    {
        icon: <SocialMediaIcon />,
        title: 'socializeToEarn.welcomeSlidesShare.socialMedia',
        text: 'socializeToEarn.welcomeSlidesShare.socialMediaText',
        energyText: 'socializeToEarn.welcomeSlidesShare.socialMediaEnergy',
    },
    {
        icon: <Referral />,
        title: 'socializeToEarn.welcomeSlidesShare.referral',
        text: 'socializeToEarn.welcomeSlidesShare.referralText',
        energyText: 'socializeToEarn.welcomeSlidesShare.sharingEnergy',
    },
    {
        icon: <ShareIcon />,
        title: 'socializeToEarn.welcomeSlidesShare.sharing',
        text: 'socializeToEarn.welcomeSlidesShare.sharingText',
        energyText: 'socializeToEarn.welcomeSlidesShare.sharingEnergy',
    },
];

export const moveToEarn: ImagesDataType = [
    {
        icon: <WalkPetIcon />,
        title: 'moveToEarn.welcomeSlides.walkPet',
        text: 'moveToEarn.welcomeSlides.walkPetText',
        energyText: 'moveToEarn.welcomeSlides.energyTotal',
    },
    {
        icon: <CompleteIcon />,
        title: 'moveToEarn.welcomeSlides.complete',
        text: 'moveToEarn.welcomeSlides.completeText',
        energyText: 'moveToEarn.welcomeSlides.energyTotal',
    },
    {
        icon: <AntiCheatingIcon />,
        title: 'moveToEarn.welcomeSlides.antiCheating',
        text: 'moveToEarn.welcomeSlides.antiCheatingText',
        energyText: 'moveToEarn.welcomeSlides.energyTotal',
    },
];

export const playToEarnAgain: ImagesDataType = [
    {
        icon: <EnergyIcon />,
        title: 'playToEarn.welcomeSlides.energy',
        text: 'playToEarn.welcomeSlides.energyAgainText',
        energyText: 'playToEarn.welcomeSlides.energyAgainTotal',
    },
    {
        icon: <ObstacleIcon />,
        title: 'playToEarn.welcomeSlides.avoid',
        text: 'playToEarn.welcomeSlides.avoidText',
        energyText: 'playToEarn.welcomeSlides.energyAgainTotal',
    },
    {
        icon: <FruitsIcon />,
        title: 'playToEarn.welcomeSlides.food',
        text: 'playToEarn.welcomeSlides.foodText',
        energyText: 'playToEarn.welcomeSlides.energyAgainTotal',
    },
];

export const playToEarn: ImagesDataType = [
    {
        icon: <EnergyIcon />,
        title: 'playToEarn.welcomeSlides.energy',
        text: 'playToEarn.welcomeSlides.energyText',
        energyText: 'playToEarn.welcomeSlides.energyTotal',
    },
    {
        icon: <ObstacleIcon />,
        title: 'playToEarn.welcomeSlides.avoid',
        text: 'playToEarn.welcomeSlides.avoidText',
        energyText: 'playToEarn.welcomeSlides.energyTotal',
    },
    {
        icon: <FruitsIcon />,
        title: 'playToEarn.welcomeSlides.food',
        text: 'playToEarn.welcomeSlides.foodText',
        energyText: 'playToEarn.welcomeSlides.energyTotal',
    },
];

export const verifyToEarn: ImagesDataType = [
    {
        icon: <FindIcon />,
        title: 'socializeToEarn.welcomeSlidesVerify.find',
        text: 'socializeToEarn.welcomeSlidesVerify.findText',
        energyText: 'socializeToEarn.welcomeSlidesVerify.findEnergy',
    },
    {
        icon: <BeResponsibleIcon />,
        title: 'socializeToEarn.welcomeSlidesVerify.beResponsible',
        text: 'socializeToEarn.welcomeSlidesVerify.beResponsibleText',
        energyText: 'socializeToEarn.welcomeSlidesVerify.beResponsibleEnergy',
    },
];
