import * as React from 'react';
import { Image } from 'react-native';

import OtherUserAvatar from '@components/OtherUserAvatar';
import { Text, View } from '@components/Themed';
import { useUser } from '@contexts/UserContext';
import { scale } from '@helpers/dimensions';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    imageUrl: string | undefined | null;
    walletAddress: string | undefined;
    title: string | undefined;
    win?: boolean;

    label: string;
}

const VSItem = ({ imageUrl, walletAddress, title, win, label }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={styles.item}>
            <OtherUserAvatar
                size={scale(100)}
                imageUrl={imageUrl}
                userId={walletAddress}
            />
            <Text
                style={[styles.title, win && styles.win]}
                numberOfLines={1}
                ellipsizeMode="middle">
                {title || walletAddress}
            </Text>
            <Text style={styles.text}>{label}</Text>
        </View>
    );
};
export default VSItem;
