import { Pet } from '@Data/Models';
import Button from '@components/Button';
import Icon, { ICON_NAMES } from '@components/Icons';
import AgeIcon from '@components/Icons/AgeIcon';
import ModalNFTisAgeDead from '@components/ModalNFTisAgeDead';
import ModalWhatIsAge from '@components/ModalWhatIsAge';
import { Text, View } from '@components/Themed';
import { ageConfig } from '@helpers/age';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';

import DetailsBlock from '../DetailsBlock';
import Styles from './styles';

interface IProps {
    pet: Pet;
}

export const DISCOUNT = 20;

export const AgeDetailsBlock = ({ pet }: IProps) => {
    const styles = useThemedStyles(Styles);

    const {
        isVisible: whatIsAge,
        close: closeWhatIsAge,
        open: openWhatIsAge,
    } = useVisibleHook();
    const {
        isVisible: isAgeDead,
        close: closeAgeDead,
        open: openAgeDead,
    } = useVisibleHook();

    const {
        ageDead,
        reviveAgeAvailable,
        ageDays,
        maxAgeDays,
        age,
        rewardUnlockDaysPeriod,
    } = pet;

    return (
        <DetailsBlock
            title={i18n.t(`detailedPet.age.${age}`, {
                current: ageDays,
                max: maxAgeDays,
            })}
            highlighted={ageDead}
            description={
                ageDead
                    ? i18n.t('detailedPet.age.descriptionDead')
                    : i18n.t('detailedPet.age.description')
            }
            icon={<AgeIcon slug={age} size={40} />}
            onInfoIconPress={reviveAgeAvailable ? openAgeDead : openWhatIsAge}>
            {!ageDead && ageConfig.babyUnlockPeriod === rewardUnlockDaysPeriod && (
                <View style={styles.dailyUnlock}>
                    <Icon name={ICON_NAMES.LOCK_FILL} size={24} />
                    <Text style={styles.unlock}>
                        {i18n.t('detailedPet.age.dailyUnlock')}
                        <Text style={styles.unlockBold}>
                            {`${ageConfig.babyUnlockPercentage}%`}
                        </Text>
                    </Text>
                </View>
            )}
            {reviveAgeAvailable && (
                <View style={styles.buttonWrapper}>
                    <Button
                        style={styles.deadButton}
                        containerStyle={styles.buttonContainerStyle}
                        textStyles={styles.deadButtonText}
                        onPress={openAgeDead}
                        title={i18n.t('detailedPet.age.button')}
                        size="md"
                        type="outline"
                    />
                </View>
            )}
            <ModalWhatIsAge isVisible={whatIsAge} closeModal={closeWhatIsAge} />
            <ModalNFTisAgeDead
                isVisible={isAgeDead}
                closeModal={closeAgeDead}
                pet={pet}
            />
        </DetailsBlock>
    );
};
