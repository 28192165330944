import * as React from 'react';
import { IntercomProvider } from 'react-use-intercom';

import { useConfig } from '../../contexts/ConfigContext';
// Import Base ProfileScreen version
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line import/extensions
import BaseProfileScreen from './ProfileScreen.tsx';

const ProfileScreen: React.FunctionComponent = () => {
    const { config } = useConfig();

    return (
        <IntercomProvider
            appId={config?.intercomAppId || ''}
            initializeDelay={1000}>
            <BaseProfileScreen />
        </IntercomProvider>
    );
};

export default ProfileScreen;
