import * as React from 'react';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

import Box from '@assets/icons/mysteryBoxes/box.svg';
import { Text, View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';

import stylesMain from './styles';

interface IProps {
    customStyle?: StyleProp<ViewStyle>;
    textStyle?: StyleProp<TextStyle>;
    amount?: number;
}

const MintMysteryBox = ({ customStyle, textStyle, amount = 1 }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <>
            <View style={[styles.container, customStyle]}>
                <Text
                    style={[styles.text, textStyle]}
                    ellipsizeMode="tail"
                    numberOfLines={1}>
                    {`(${amount})`}
                </Text>
                <View style={styles.image}>
                    <Box />
                </View>
            </View>
        </>
    );
};
export default MintMysteryBox;
