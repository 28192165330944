import * as React from 'react';
import { useContext } from 'react';
import {
    ImageBackground,
    KeyboardAvoidingView,
    ScrollView,
} from 'react-native';

import Button from '@components/Button';
import ButtonsBottom from '@components/ButtonsBottom';
import CustomModal from '@components/CustomModal';
import { STEP_INDICATOR_FLOWS } from '@components/FlowStepIndicator';
import { MINT_PET_STAGES_NAME } from '@components/FlowStepIndicator/flows/mintNFTStages';
import Icon, { ICON_NAMES } from '@components/Icons';
import ImageIguana from '@components/ImageIguana';
import ModalNFTPreview from '@components/ModalNFTPreview';
import PetName from '@components/PetName';
import { View } from '@components/Themed';
import { useDimensions } from '@contexts/DimensionsContext';
import { MintContext } from '@contexts/MintContext';
import { useStorePurchase } from '@contexts/StorePurchaseContext';
import { isIOS } from '@helpers/app';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import MintContainer from '../components/MintContainer';
import MintHeaderText from '../components/MintHeaderText';
import MintPetDetectionInfo from '../components/MintPetDetectionInfo';
import stylesMain from './styles';

export const MintPetNameScreen = ({ route }: any) => {
    const styles = useThemedStyles(stylesMain);
    const {
        savedPhoto,
        name,
        detectionResult,
        AIImage,
        setAIImage,
        rank,
        setRank,
        setName,
    } = useContext(MintContext);

    const { petMintProducts } = useStorePurchase();
    const [isNameError, setIsNameError] = React.useState<string>();
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [isModalPreviewVisible, setIsModalPreviewVisible] =
        React.useState<boolean>(false);
    const navigation = useNavigation();
    const { windowWidth } = useDimensions();

    const handleNameError = (status: string | undefined) => {
        setIsNameError(status);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
    };

    const handlePressBin = () => {
        setIsModalVisible(true);
    };

    const handleDelete = () => {
        setAIImage(null);
        navigation.pop();
    };

    const goToCheckout = React.useCallback(() => {
        if (petMintProducts && rank) {
            setRank({
                ...rank,
                payments: rank.payments.map((r) => {
                    if (r.token === 'STORE') {
                        const priceString = petMintProducts.find(
                            (p) => p.identifier === r.productId
                        )?.priceString;
                        return { ...r, priceString };
                    }
                    return r;
                }),
            });
        }
        navigation.navigate(ROUTES.MINT_PET_CHECKOUT);
    }, [rank, petMintProducts]);

    return (
        <KeyboardAvoidingView
            behavior={isIOS ? 'padding' : 'height'}
            style={styles.container}>
            <MintContainer
                icon="leftArrow"
                flow={STEP_INDICATOR_FLOWS.MINT_PET}
                stage={MINT_PET_STAGES_NAME.STEP_TWO}
                stageFill={'100%'}>
                <ScrollView
                    style={styles.scrollView}
                    showsVerticalScrollIndicator={false}
                    keyboardShouldPersistTaps="handled">
                    <MintHeaderText
                        title={i18n.t('mintNamePhoto.title')}
                        text={i18n.t('mintNamePhoto.text')}
                    />
                    <View style={styles.petNameWrapper}>
                        <PetName
                            setError={handleNameError}
                            isNameError={isNameError}
                            name={name}
                            setName={setName}
                        />
                    </View>
                    {!!AIImage?.url ? (
                        <ImageBackground
                            borderRadius={12}
                            resizeMode="contain"
                            style={[
                                styles.image,
                                {
                                    width: windowWidth - styles.imageGap.width,
                                    height: windowWidth - styles.imageGap.width,
                                },
                            ]}
                            source={{ uri: AIImage.url }}>
                            <View style={styles.imgWrapper}>
                                <Button
                                    onPress={handlePressBin}
                                    type="outline"
                                    icon={<Icon name={ICON_NAMES.BIN} />}
                                />
                            </View>
                        </ImageBackground>
                    ) : (
                        <>
                            <View style={styles.petInfoContainer}>
                                <MintPetDetectionInfo
                                    detectionResult={detectionResult}
                                    onPreviewClick={() => {
                                        setIsModalPreviewVisible(true);
                                    }}
                                    onEditClick={() =>
                                        navigation.navigate(
                                            ROUTES.MINT_PET_EDIT_PHOTO
                                        )
                                    }
                                    savedPhoto={savedPhoto}
                                />
                            </View>
                            <ModalNFTPreview
                                isVisible={isModalPreviewVisible}
                                image={savedPhoto?.url}
                                onClose={() => setIsModalPreviewVisible(false)}
                            />
                        </>
                    )}
                </ScrollView>
                <ButtonsBottom
                    onPress={goToCheckout}
                    title={i18n.t('mintNamePhoto.buttonNext')}
                    disabled={!name || !!isNameError}
                />
                <CustomModal
                    isVisible={isModalVisible}
                    icon={<ImageIguana type="attention" />}
                    titleText={i18n.t('mintNamePhoto.deleteNFTphoto')}
                    infoText={i18n.t('mintNamePhoto.deleteNFTphotoText')}
                    firstButtonType={'critical'}
                    firstButtonText={i18n.t('mintNamePhoto.yesDelete')}
                    secondButtonText={i18n.t('mintNamePhoto.noKeep')}
                    onFirstButtonClick={handleDelete}
                    onSecondButtonClick={handleCloseModal}
                />
            </MintContainer>
        </KeyboardAvoidingView>
    );
};
export default MintPetNameScreen;
