import { StyleSheet } from 'react-native';

import { isWeb } from '@helpers/app';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';
import { stylesModalNavigationFix } from '@helpers/style';

import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            ...stylesModalNavigationFix(),
            flex: 1,
            paddingBottom: verticalScale(8),
        },
        header: {
            marginHorizontal: scale(16),
        },
        imgWrapper: {
            alignSelf: 'center',
            marginBottom: verticalScale(24),
        },
        textStyle: {
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
            marginRight: scale(6),
            marginLeft: scale(2),
        },
        mintPriceContainer: {
            paddingHorizontal: scale(8),
            paddingVertical: verticalScale(6),
            marginBottom: verticalScale(12),

            alignSelf: 'center',
        },
        currentRank: {
            marginBottom: verticalScale(12),
        },
        buttonWrapper: {
            paddingTop: verticalScale(16),
            paddingHorizontal: scale(20),
        },
        buttonExit: {
            position: 'absolute',
            top: verticalScale(16),
            left: scale(20),
            zIndex: 1,
        },
        currentLabel: {
            alignSelf: 'center',
        },
        safeArea: {
            flex: 1,
            justifyContent: 'space-between',
        },
        itemWrapper: {
            paddingHorizontal: scale(20),
        },
        itemWrapperWeb: {
            paddingHorizontal: isWeb ? scale(20) : 0,
        },
    });

export default styles;
