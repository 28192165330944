import * as React from 'react';

import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';

import { MarketPlaceProfileProps } from '../../../../types';
import MarketPlaceMyProfileRender from '../components/MarketPlaceMyProfileRender';

const MarketPlaceProfileScreen = ({ route }: MarketPlaceProfileProps) => {
    const { walletAddress } = route.params;

    return (
        <MarketPlaceMyProfileRender
            walletAddress={walletAddress}
            blockTitle={i18n.t('MarketPlace.MarketplaceProfile.exploreNFTs')}
            navigationComponent={
                <NavigationBar
                    filled={false}
                    backIcon="leftArrow"
                    title={i18n.t('general.KEYS.profile')}
                />
            }
        />
    );
};
export default MarketPlaceProfileScreen;
