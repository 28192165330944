import React from 'react';

import AgeIcon from '@components/Icons/AgeIcon';
import ModalWhatIsAge from '@components/ModalWhatIsAge';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import { MarketplaceNftDetailsPetOutput } from '@models/marketplace';

import DetailsBlock from '../../../Collection/components/DetailsBlock';
import OverviewModalNFTisAgeDead from '../OverviewModalNFTisAgeDead';

interface IProps {
    pet: MarketplaceNftDetailsPetOutput;
}

const OverviewAgeDetailsBlock = ({ pet }: IProps) => {
    const {
        isVisible: whatIsAge,
        close: closeWhatIsAge,
        open: openWhatIsAge,
    } = useVisibleHook();
    const {
        isVisible: isAgeDead,
        close: closeAgeDead,
        open: openAgeDead,
    } = useVisibleHook();

    const { ageDays, maxAgeDays, name, age, ageDead } = pet;

    return (
        <DetailsBlock
            title={i18n.t(`detailedPet.age.${age}`, {
                current: ageDays,
                max: maxAgeDays,
            })}
            isDead={ageDead}
            description={
                ageDead
                    ? i18n.t('detailedPet.age.descriptionDead')
                    : i18n.t('detailedPet.age.description')
            }
            icon={<AgeIcon slug={age} size={40} />}
            onInfoIconPress={ageDead ? openAgeDead : openWhatIsAge}>
            <ModalWhatIsAge isVisible={whatIsAge} closeModal={closeWhatIsAge} />
            <OverviewModalNFTisAgeDead
                isVisible={isAgeDead}
                closeModal={closeAgeDead}
                name={name}
            />
        </DetailsBlock>
    );
};
export default OverviewAgeDetailsBlock;
