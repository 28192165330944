import * as React from 'react';
import { Pressable, PressableProps, StyleProp, ViewStyle } from 'react-native';

import { Text } from '../../../components/Themed';
import { useHaptic } from '../../../hooks/useHaptic';
import useThemedStyles from '../../../hooks/useThemedStyles';
import stylesMain from './styles';

interface IProps extends PressableProps {
    containerStyles?: StyleProp<ViewStyle>;
    isActive?: boolean;
    title: string;
    oneColor?: boolean;
    disabled?: boolean;
}

const NavigationItem = ({
    containerStyles,
    isActive = false,
    title,
    oneColor,
    disabled,
    ...props
}: IProps) => {
    const styles = useThemedStyles(stylesMain, oneColor);
    const { onPress, ...others } = { ...props };
    const { triggerLight } = useHaptic();

    return (
        <Pressable
            disabled={disabled}
            onPress={(event) => {
                triggerLight();
                onPress && onPress(event);
            }}
            {...others}
            style={[
                styles.container,
                isActive && styles.isActive,
                containerStyles,
            ]}>
            <Text
                style={[styles.text, isActive && styles.isActive]}
                numberOfLines={1}>
                {title}
            </Text>
        </Pressable>
    );
};
export default NavigationItem;
