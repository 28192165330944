import { PetIdType } from '@Data/Models';

export interface ISocialToEarnCard {
    id: string;
    colorPicker: string;
    background: string;
    backgroundGradientFirst: string;
    backgroundGradientSecond: string;
    title: string;
    qrCodeBackground: string;
    qrCodeTitle: string;
    qrCodeImageColor: string;
    qrCodeImageBackground: string;
    cardTypeId: PetIdType;
    linkBackground: string;
    linkBorder: string;
    linkTitle: string;
    opacityCircle: string;
    logo: string;
}

export const defaultSocialToEarnCardTemplates: ISocialToEarnCard[] = [
    {
        cardTypeId: 'Default',
        id: '#9683FC',
        colorPicker: '#9683FC',
        background: '#9683FC',
        qrCodeTitle: '#8169FC',
        backgroundGradientFirst: '#B6AAF8',
        backgroundGradientSecond: '#9280F5',
        title: '#ffffff',
        qrCodeBackground: '#ffffff',
        qrCodeImageColor: '#000000',
        qrCodeImageBackground: '#ffffff',
        linkBackground: 'rgba(34, 36, 42, 0.2)',
        linkBorder: 'rgba(255, 255, 255, 0.4)',
        linkTitle: '#ffffff',
        opacityCircle: 'rgba(255, 255, 255, 0.2)',
        logo: '',
    },
    {
        cardTypeId: 'Default',
        id: '#C086EA',
        colorPicker: '#C086EA',
        background: '#C086EA',
        qrCodeTitle: '#B868F3',
        backgroundGradientFirst: '#D7AAF8',
        backgroundGradientSecond: '#C380F5',
        title: '#ffffff',
        qrCodeBackground: '#ffffff',
        qrCodeImageColor: '#000000',
        qrCodeImageBackground: '#ffffff',
        linkBackground: 'rgba(34, 36, 42, 0.2)',
        linkBorder: 'rgba(255, 255, 255, 0.4)',
        linkTitle: '#ffffff',
        opacityCircle: 'rgba(255, 255, 255, 0.2)',
        logo: '',
    },
    {
        cardTypeId: 'Default',
        id: '#F08975',
        colorPicker: '#F08975',
        background: '#F08975',
        qrCodeTitle: '#F37F68',
        backgroundGradientFirst: '#F7AEA1',
        backgroundGradientSecond: '#F59380',
        title: '#ffffff',
        qrCodeBackground: '#ffffff',
        qrCodeImageColor: '#000000',
        qrCodeImageBackground: '#ffffff',
        linkBackground: 'rgba(34, 36, 42, 0.2)',
        linkBorder: 'rgba(255, 255, 255, 0.4)',
        linkTitle: '#ffffff',
        opacityCircle: 'rgba(255, 255, 255, 0.2)',
        logo: '',
    },
    {
        cardTypeId: 'Default',
        id: '#8099FF',
        colorPicker: '#8099FF',
        background: '#8099FF',
        qrCodeTitle: '#6883F3',
        backgroundGradientFirst: '#C2CDFA',
        backgroundGradientSecond: '#8096F5',
        title: '#ffffff',
        qrCodeBackground: '#ffffff',
        qrCodeImageColor: '#000000',
        qrCodeImageBackground: '#ffffff',
        linkBackground: 'rgba(34, 36, 42, 0.2)',
        linkBorder: 'rgba(255, 255, 255, 0.4)',
        linkTitle: '#ffffff',
        opacityCircle: 'rgba(255, 255, 255, 0.2)',
        logo: '',
    },
    {
        cardTypeId: 'Default',
        id: '#6BC79B',
        colorPicker: '#6BC79B',
        background: '#6BC79B',
        qrCodeTitle: '#32B476',
        backgroundGradientFirst: '#99DBBB',
        backgroundGradientSecond: '#60C796',
        title: '#ffffff',
        qrCodeBackground: '#ffffff',
        qrCodeImageColor: '#000000',
        qrCodeImageBackground: '#ffffff',
        linkBackground: 'rgba(34, 36, 42, 0.2)',
        linkBorder: 'rgba(255, 255, 255, 0.4)',
        linkTitle: '#ffffff',
        opacityCircle: 'rgba(255, 255, 255, 0.2)',
        logo: '',
    },
    {
        cardTypeId: 'Default',
        id: '#DD888C',
        colorPicker: '#DD888C',
        background: '#DD888C',
        qrCodeTitle: '#F57A7F',
        backgroundGradientFirst: '#F8AAAD',
        backgroundGradientSecond: '#F69296',
        title: '#ffffff',
        qrCodeBackground: '#ffffff',
        qrCodeImageColor: '#000000',
        qrCodeImageBackground: '#ffffff',
        linkBackground: 'rgba(34, 36, 42, 0.2)',
        linkBorder: 'rgba(255, 255, 255, 0.4)',
        linkTitle: '#ffffff',
        opacityCircle: 'rgba(255, 255, 255, 0.2)',
        logo: '',
    },
    {
        cardTypeId: 'Default',
        id: '#A1BF69',
        colorPicker: '#A1BF69',
        background: '#A1BF69',
        qrCodeTitle: '#89B63F',
        backgroundGradientFirst: '#BFDF86',
        backgroundGradientSecond: '#A1C760',
        title: '#ffffff',
        qrCodeBackground: '#ffffff',
        qrCodeImageColor: '#000000',
        qrCodeImageBackground: '#ffffff',
        linkBackground: 'rgba(34, 36, 42, 0.2)',
        linkBorder: 'rgba(255, 255, 255, 0.4)',
        linkTitle: '#ffffff',
        opacityCircle: 'rgba(255, 255, 255, 0.2)',
        logo: '',
    },
    {
        cardTypeId: 'Default',
        id: '#D8BA6F',
        colorPicker: '#D8BA6F',
        background: '#D8BA6F',
        qrCodeTitle: '#D4AA3B',
        backgroundGradientFirst: '#E8D297',
        backgroundGradientSecond: '#DEBE69',
        title: '#ffffff',
        qrCodeBackground: '#ffffff',
        qrCodeImageColor: '#000000',
        qrCodeImageBackground: '#ffffff',
        linkBackground: 'rgba(34, 36, 42, 0.2)',
        linkBorder: 'rgba(255, 255, 255, 0.4)',
        linkTitle: '#ffffff',
        opacityCircle: 'rgba(255, 255, 255, 0.2)',
        logo: '',
    },
    {
        cardTypeId: 'Default',
        id: '#D5A881',
        colorPicker: '#D5A881',
        background: '#D5A881',
        qrCodeTitle: '#E7924B',
        backgroundGradientFirst: '#F3C9A5',
        backgroundGradientSecond: '#EDAE78',
        title: '#ffffff',
        qrCodeBackground: '#ffffff',
        qrCodeImageColor: '#000000',
        qrCodeImageBackground: '#ffffff',
        linkBackground: 'rgba(34, 36, 42, 0.2)',
        linkBorder: 'rgba(255, 255, 255, 0.4)',
        linkTitle: '#ffffff',
        opacityCircle: 'rgba(255, 255, 255, 0.2)',
        logo: '',
    },
    {
        cardTypeId: 'Default',
        id: '#EA9595',
        colorPicker: '#EA9595',
        background: '#EA9595',
        qrCodeTitle: '#E57676',
        backgroundGradientFirst: '#EDABAB',
        backgroundGradientSecond: '#EA9494',
        title: '#ffffff',
        qrCodeBackground: '#ffffff',
        qrCodeImageColor: '#000000',
        qrCodeImageBackground: '#ffffff',
        linkBackground: 'rgba(34, 36, 42, 0.2)',
        linkBorder: 'rgba(255, 255, 255, 0.4)',
        linkTitle: '#ffffff',
        opacityCircle: 'rgba(255, 255, 255, 0.2)',
        logo: '',
    },
    {
        cardTypeId: 'Default',
        id: '#A0A0A8',
        colorPicker: '#A0A0A8',
        background: '#A0A0A8',
        qrCodeTitle: '#6D6D79',
        backgroundGradientFirst: '#A2A2AA',
        backgroundGradientSecond: '#797986',
        title: '#ffffff',
        qrCodeBackground: '#ffffff',
        qrCodeImageColor: '#000000',
        qrCodeImageBackground: '#ffffff',
        linkBackground: 'rgba(34, 36, 42, 0.2)',
        linkBorder: 'rgba(255, 255, 255, 0.4)',
        linkTitle: '#ffffff',
        opacityCircle: 'rgba(255, 255, 255, 0.2)',
        logo: '',
    },
];
export const bybitSocialToEarnCardTemplates: ISocialToEarnCard[] = [
    {
        cardTypeId: 'Bybit',
        id: '#212121',
        colorPicker: '#212121',
        background: '#212121',
        qrCodeTitle: '#F4AF5C',
        backgroundGradientFirst: '',
        backgroundGradientSecond: '',
        logo: '#F4AF5C',
        title: '#F4AF5C',
        qrCodeBackground: '#3B3B3B',
        qrCodeImageColor: '',
        qrCodeImageBackground: '',
        linkBackground: '',
        linkBorder: '',
        linkTitle: '',
        opacityCircle: '',
    },
    {
        cardTypeId: 'Bybit',
        id: '#F8B769',
        colorPicker: '#F8B769',
        background: '#F8B769',
        qrCodeTitle: '#0D0D0D',
        backgroundGradientFirst: '',
        backgroundGradientSecond: '',
        title: '#0D0D0D',
        logo: '#0D0D0D',
        qrCodeBackground: '#FCDCB6',
        qrCodeImageColor: '',
        qrCodeImageBackground: '',
        linkBackground: '',
        linkBorder: '',
        linkTitle: '',
        opacityCircle: '',
    },
];
