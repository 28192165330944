import * as React from 'react';

import Icon, { ICON_NAMES } from '@components/Icons';
import { View } from '@components/Themed';
import {
    NFT_EVOLUTION_COLOR,
    useMysteryGames,
} from '@contexts/MysteryGamesContext';
import useThemedStyles from '@hooks/useThemedStyles';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import NftEvolutionEmptyPet from '../NftEvolutionEmptyPet';
import NftEvolutionPetCard from '../NftEvolutionPetCard';
import stylesMain from './styles';

interface IProps {
    disabled?: boolean;
}

const NftEvolutionSelectPetsBlock = ({ disabled }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const navigation = useNavigation();
    const {
        nftEvolutionFirstPet,
        nftEvolutionSecondPet,
        setNftEvolutionCurrentPetForChoose,
    } = useMysteryGames();

    const onCardPress = (card: 'first' | 'second') => {
        setNftEvolutionCurrentPetForChoose(card);
        navigation.navigate(ROUTES.NFT_EVOLUTION_SELECT_PET);
    };

    return (
        <View style={styles.container}>
            <View style={styles.cards}>
                <View style={styles.card}>
                    {!!nftEvolutionFirstPet ? (
                        <NftEvolutionPetCard
                            disabled={disabled}
                            pet={nftEvolutionFirstPet}
                            onPress={() => onCardPress('first')}
                        />
                    ) : (
                        <NftEvolutionEmptyPet
                            onPress={() => onCardPress('first')}
                        />
                    )}
                </View>
                <View style={styles.card}>
                    {!!nftEvolutionSecondPet ? (
                        <NftEvolutionPetCard
                            disabled={disabled}
                            pet={nftEvolutionSecondPet}
                            onPress={() => onCardPress('second')}
                        />
                    ) : (
                        <NftEvolutionEmptyPet
                            onPress={() => onCardPress('second')}
                            second
                        />
                    )}
                </View>
            </View>
        </View>
    );
};

export default NftEvolutionSelectPetsBlock;
