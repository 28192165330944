import { StyleSheet } from 'react-native';

import Colors from '../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../helpers/dimensions';
import font from '../../../helpers/font';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: 'transparent',
        },
        webviewContainer: {
            flex: 1,
        },
        previewContainer: {
            flex: 1,
        },
        bottomWrapper: {
            paddingHorizontal: scale(20),
        },
        image: {
            alignItems: 'center',
            paddingTop: verticalScale(36),
        },
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 22,
                height: 22,
                weight: '700',
            }),
            textAlign: 'center',
            paddingTop: verticalScale(24),
        },
        description: {
            paddingHorizontal: scale(20),
            color: Colors[theme].text.secondary,
            letterSpacing: 0.16,
            ...font({
                size: 15,
                height: 20,
            }),
            textAlign: 'center',
            paddingTop: verticalScale(8),
            paddingBottom: verticalScale(20),
        },
        button: {
            marginTop: verticalScale(16),
        },
    });

export default styles;
