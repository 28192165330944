import * as React from 'react';

import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import { NFT_ROYAL_GAME_HISTORY_STATUS } from '@models/mysteryGames';

import GamesHistoryButtons from '../GamesHistoryButtons';
import NftRoyalHistoryBlockHeader from '../NftRoyalHistoryBlockHeader';
import stylesMain from './styles';

interface IProps {
    status: NFT_ROYAL_GAME_HISTORY_STATUS;
    exploreAction: () => void;
    toHistoryAction: () => void;
}

const NftRoyalHistoryWaiting = ({
    status,
    exploreAction,
    toHistoryAction,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={styles.main}>
            <View style={styles.block}>
                <NftRoyalHistoryBlockHeader status={status} isBigHeader />
            </View>
            <GamesHistoryButtons
                firstButtonAction={exploreAction}
                secondButtonAction={toHistoryAction}
                white
                firstButtonType="outline"
                isOneTheme
            />
        </View>
    );
};

export default NftRoyalHistoryWaiting;
