import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { View } from '@components/Themed';
import { LuckyStrikeAvailableGameOutput } from '@models/mysteryGames';
import ROUTES from '@navigation/routes';

import LuckyStrikeSlot from '../LuckyStrikeSlot';

interface IProps {
    containerStyle?: StyleProp<ViewStyle>;
    onPress: (games: LuckyStrikeAvailableGameOutput) => void;
    games: LuckyStrikeAvailableGameOutput[];
    loading?: boolean;
    onEndTime?: () => void;
    secondModalClose?: () => void;
    routeBack: ROUTES;
}

const LuckyStrikeSlots = ({
    containerStyle,
    onPress,
    games,
    loading,
    onEndTime,
    secondModalClose,
    routeBack,
}: IProps) => {
    return (
        <View style={containerStyle}>
            {games.map((item, index) => (
                <LuckyStrikeSlot
                    routeBack={routeBack}
                    loading={loading}
                    key={item.id}
                    game={item}
                    onPress={onPress}
                    isLast={index === games.length - 1}
                    onEndTime={onEndTime}
                    secondModalClose={secondModalClose}
                />
            ))}
        </View>
    );
};
export default React.memo(LuckyStrikeSlots);
