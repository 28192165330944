import { StyleSheet } from 'react-native';

import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import Colors from '../../constants/Colors';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        emptyComponent: {
            paddingHorizontal: scale(16),
            paddingVertical: scale(16),
            alignItems: 'center',
            borderWidth: 1,
            borderRadius: moderateScale(8),
            borderColor: Colors[theme].border.primary,
            borderStyle: 'dashed',
            marginTop: verticalScale(8),
            marginHorizontal: scale(16),
        },
        iconGreen: {
            color: Colors[theme].icon.green,
        },
        containerBottom: {
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderTopWidth: 0,
            borderBottomLeftRadius: moderateScale(12),
            borderBottomRightRadius: moderateScale(12),
            paddingVertical: verticalScale(16),
            marginBottom: verticalScale(24),
        },
        emptyText: {
            textAlign: 'center',
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
            marginTop: verticalScale(8),
        },
    });
export default styles;
