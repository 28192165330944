import React, { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';

import * as Device from 'expo-device';

import SupportIcon from '../../../../../../assets/icons/profile/support.svg';
import { useUser } from '../../../../../../contexts/UserContext';
import {
    appVersion,
    getFullDeviceName,
    otaUpdateGroup,
} from '../../../../../../helpers/app';
import { getDeviceId } from '../../../../../../helpers/deviceId';
import i18n from '../../../../../../i18n/i18n';
import MenuItem from '../../../MenuItem';
import { RightComponent, iconSize } from '../../helpers';

const SupportMenuItem = () => {
    const { user } = useUser();
    const { boot: bootIntercom, show: showIntercom, shutdown } = useIntercom();

    useEffect(() => {
        return () => {
            shutdown();
        };
    }, []);

    const loadIntercom = async () => {
        if (!user) {
            return;
        }

        const customAttributes = {
            source: 'IguVerse App',
            appVersion,
            updateVersion: otaUpdateGroup,
            platform: 'web',
            deviceId: await getDeviceId(),
            deviceModel: getFullDeviceName(),
            deviceOs: Device.osName,
            ethAddress: user.walletAddress,
            mainPetId: user.mainPet?.id ? user.mainPet?.id : '-',
        };

        bootIntercom({
            userId: user?.id,
            email: user?.email,
            customAttributes,
        });
    };

    const handleOnSupport = async () => {
        await loadIntercom();
        await showIntercom();
    };

    return (
        <MenuItem
            leftIcon={<SupportIcon width={iconSize} height={iconSize} />}
            title={i18n.t('profile.menuItems.support.title')}
            description={i18n.t('profile.menuItems.support.description')}
            rightComponent={<RightComponent type="arrowRight" />}
            onPress={handleOnSupport}
        />
    );
};

export default SupportMenuItem;
