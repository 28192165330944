import { useCallback, useEffect, useRef } from 'react';
import WebView from 'react-native-webview';

import { isWeb } from '@helpers/app';

import {
    UnityMessage,
    sender,
} from '../PlayToEarn/screens/playToEarnProgress/config';

type Props = {
    onMessage: (message: UnityMessage) => void;
};

export const useUnityWrapper = ({ onMessage }: Props) => {
    const webViewRef = useRef<WebView>();
    const iframeRef = useRef<HTMLIFrameElement>();

    const handleOnMessage = (e: any) => {
        if (!e || e === 'undefined') return;
        const data = e && typeof e === 'string' ? JSON.parse(e) : e;
        if (data.sender !== sender) return;
        onMessage({ message: data.title, messageBody: data.data });
    };

    useEffect(() => {
        if (!isWeb) return;

        window.addEventListener('message', (e) => handleOnMessage(e.data));
        return () =>
            window.removeEventListener('message', (e) =>
                handleOnMessage(e.data)
            );
    }, []);

    return {
        webViewRef,
        iframeRef,
        handleOnMessage,
    };
};
