import * as React from 'react';
import { LayoutRectangle, TouchableOpacity } from 'react-native';

import Icon, { ICON_NAMES } from '@components/Icons';
import { useHaptic } from '@hooks/useHaptic';
import useThemedStyles from '@hooks/useThemedStyles';
import { useNavigation } from '@navigation/useNavigation';

import stylesMain from './styles';

interface IProps {
    size: LayoutRectangle;
}

const PetCollectionEmptyCard = ({ size }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const { triggerHeavy } = useHaptic();
    const { height, width } = size;

    const press = () => {
        triggerHeavy();
        navigation.navigateToMint();
    };

    return (
        <TouchableOpacity
            activeOpacity={0.7}
            style={[styles.container, { width, height }]}
            onPress={press}>
            <Icon name={ICON_NAMES.PLUS} size={20} color={styles.plus.color} />
        </TouchableOpacity>
    );
};
export default React.memo(PetCollectionEmptyCard);
