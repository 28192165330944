import * as React from 'react';

import Icon, { ICON_NAMES } from '../../../../../../components/Icons';
import { Text, View } from '../../../../../../components/Themed';
import { PlayContext } from '../../../../../../contexts/PlayContext';
import useThemedStyles from '../../../../../../hooks/useThemedStyles';
import i18n from '../../../../../../i18n/i18n';
import NavigationBar from '../../../../../../navigation/NavigationBar';
import stylesMain from './styles';

interface IProps {
    onBack: () => void;
}

export const ProgressNavBar = ({ onBack }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { moveTask } = React.useContext(PlayContext);

    return (
        <NavigationBar
            onBack={onBack}
            backIcon={'close'}
            containerStyle={styles.navBg}
            title={i18n.t('moveToEarn.progress.title')}
            settingsComponent={
                <View style={styles.energyBlock}>
                    <Icon name={ICON_NAMES.ENERGY} size={16} />
                    <Text style={styles.energyCost}>
                        {moveTask?.maxEnergyReward}
                    </Text>
                </View>
            }
        />
    );
};

export default ProgressNavBar;
