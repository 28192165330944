import { useCallback, useEffect, useMemo, useState } from 'react';

import { useApolloClient, useMutation } from '@apollo/client';
import { useUser } from '@contexts/UserContext';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { UPDATE_USER_SETTINGS } from '../Data/Requests';
import i18n, { LANGUAGES, getLanguage } from '../i18n/i18n';

const config = {
    storageKey: 'useLanguage.localeSaved',
    temporaryKey: 'useLanguage.temporaryKeyLang',
};

const useLanguage = () => {
    const client = useApolloClient();
    const { user } = useUser();
    const [saveLanguage] = useMutation(UPDATE_USER_SETTINGS);
    const [locale, setLocale] = useState<string>(i18n.locale);
    const storageKeyPerUser = useMemo(
        () => `${config.storageKey}${user?.id ?? ''}`,
        [user]
    );

    useEffect(() => {
        setLocale(i18n.locale);
    }, [locale]);

    // remove key on log out
    useEffect(() => {
        if (!user) {
            AsyncStorage.removeItem(storageKeyPerUser);
        }
    }, [user, storageKeyPerUser]);

    const maybeSaveLanguage = useCallback(async () => {
        const hasSavedToBackend = await AsyncStorage.getItem(storageKeyPerUser);

        if (user && !hasSavedToBackend) {
            Console.log('[useLanguage] Saving language to backend');
            const currentLanguage = await getLanguage();
            await saveLanguageToBackend(currentLanguage, false);
        } else {
            Console.log('[useLanguage] Skipping saving language to backend');
        }
    }, [user, storageKeyPerUser]);

    const saveLanguageToBackend = async (locale?: string, reset = false) => {
        const language = locale?.toUpperCase() || 'EN';

        // Resets query cache, so backend query with translations are not cached (Apollo doesn't cache headers unfortunately)
        if (reset) {
            client.stop();
            await client.clearStore();
        }

        await AsyncStorage.setItem(storageKeyPerUser, language);

        return saveLanguage({
            variables: {
                settings: {
                    // Set only known languages to backend, do not send other locales
                    language: LANGUAGES.includes(language.toLowerCase())
                        ? language
                        : 'EN',
                },
            },
        });
    };
    const temporarySaveLanguage = async (locale?: string, reset = false) => {
        const language = locale?.toUpperCase() || 'EN';

        // Resets query cache, so backend query with translations are not cached (Apollo doesn't cache headers unfortunately)
        if (reset) {
            client.stop();
            await client.clearStore();
        }

        await AsyncStorage.setItem(config.temporaryKey, language);
    };

    return {
        locale,
        saveLanguageToBackend,
        maybeSaveLanguage,
        temporarySaveLanguage,
        setLocale,
    };
};

export default useLanguage;
