import React, { useCallback, useMemo } from 'react';
import { KeyboardAvoidingView, ScrollView } from 'react-native';

import { NFT_ROYAL_REVOKE_BAN } from '@Data/Requests';
import { useMutation } from '@apollo/client/react/hooks/useMutation';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import CustomModal from '@components/CustomModal';
import ImageIguana from '@components/ImageIguana';
import { View } from '@components/Themed';
import { isIOS } from '@helpers/app';
import { errorsHandler } from '@helpers/errors';
import { waitForModal } from '@helpers/helpers';
import { toastSuccess } from '@helpers/toastNotification';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';

import { useUserIdInput } from '../hooks/useUserIdInput';
import { useWalletAddressInput } from '../hooks/useWalletAddressInput';
import stylesMain from './styles';

const AdminNftRoyaleRevokeBanScreen = () => {
    const styles = useThemedStyles(stylesMain);
    const { isVisible, close, open } = useVisibleHook();
    const {
        renderWalletInput,
        checkWallet,
        walletError,
        walletValue,
        resetWalletInput,
    } = useWalletAddressInput();

    const {
        value: userIdValue,
        error: userIdError,
        render: userIdRender,
        reset: userIdReset,
    } = useUserIdInput();

    const disableButton = useMemo(
        () => !walletValue || !!walletError || !!userIdError || !userIdValue,

        [walletError, walletValue, userIdValue, userIdError]
    );

    const onButtonPress = useCallback(() => {
        if (checkWallet()) {
            open();
        }
    }, [checkWallet]);

    const [revokeBan, { loading }] = useMutation(NFT_ROYAL_REVOKE_BAN);

    const onSendPress = React.useCallback(async () => {
        try {
            await revokeBan({
                variables: {
                    filter: {
                        userId: userIdValue,
                        userWalletAddress: walletValue,
                    },
                },
            });
            reset();
            close();
            await waitForModal();
            toastSuccess(i18n.t('profile.adminNftRoyaleRevokeBan.success'));
        } catch (error) {
            reset();
            close();
            errorsHandler(error, true);
        }
    }, [walletValue, userIdValue]);

    const reset = () => {
        resetWalletInput();
        userIdReset();
    };

    const onCancelPress = () => {
        reset();
        close();
    };

    return (
        <KeyboardAvoidingView
            behavior={isIOS ? 'padding' : 'height'}
            style={styles.mainContainer}>
            <NavigationBar
                backIcon
                filled={false}
                title={i18n.t('profile.adminNftRoyaleRevokeBan.screenTitle')}
            />

            <ScrollView
                keyboardShouldPersistTaps="handled"
                style={styles.content}
                showsVerticalScrollIndicator={false}>
                <BlockHeader
                    containerStyle={styles.paddingHorizontal}
                    type="primary"
                    title={i18n.t('profile.adminNftRoyaleRevokeBan.title')}
                    text={i18n.t('profile.adminNftRoyaleRevokeBan.text')}
                />

                <View style={styles.inputWrapper}>{renderWalletInput()}</View>
                <View style={styles.inputWrapper}>{userIdRender()}</View>
            </ScrollView>
            <ButtonsBottom
                containerStyle={styles.buttons}
                title={i18n.t('profile.adminNftRoyaleRevokeBan.button')}
                onPress={onButtonPress}
                disabled={disableButton}
            />
            <CustomModal
                isVisible={isVisible}
                icon={<ImageIguana type="attention" />}
                titleText={i18n.t(
                    'profile.adminNftRoyaleRevokeBan.attentionModal.title'
                )}
                infoText={i18n.t(
                    'profile.adminNftRoyaleRevokeBan.attentionModal.text'
                )}
                firstButtonText={i18n.t(
                    'profile.adminNftRoyaleRevokeBan.attentionModal.button1'
                )}
                secondButtonText={i18n.t(
                    'profile.adminNftRoyaleRevokeBan.attentionModal.button2'
                )}
                firstButtonLoading={loading}
                firstButtonDisabled={loading}
                firstButtonType="critical"
                onFirstButtonClick={onSendPress}
                onSecondButtonClick={onCancelPress}
            />
        </KeyboardAvoidingView>
    );
};

export default AdminNftRoyaleRevokeBanScreen;
