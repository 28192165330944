import { StyleSheet } from 'react-native';

import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../../helpers/dimensions';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        mainContainer: {
            flex: 1,
        },
        container: {
            flex: 1,
            backgroundColor: 'transparent',
        },
        viewShot: {
            paddingHorizontal: scale(20),

            paddingVertical: verticalScale(16),
        },
        noPetPadding: {
            paddingTop: verticalScale(38),
        },
        codeContainer: {
            alignSelf: 'center',
            maxWidth: scale(300),
            backgroundColor: 'transparent',
        },
        codeWrapper: {
            alignSelf: 'center',
            top: verticalScale(-12),
            backgroundColor: 'transparent',
            marginBottom: verticalScale(12),
        },
        top: {
            paddingBottom: verticalScale(20),
            paddingHorizontal: scale(37.5),
            backgroundColor: 'transparent',
        },
        wrapper: {
            flex: 1,
            borderBottomLeftRadius: moderateScale(40),
            borderBottomRightRadius: moderateScale(40),
        },
        bottom: {
            paddingTop: verticalScale(24),
            paddingHorizontal: scale(20),
        },
        buttonWrapper: {
            paddingTop: verticalScale(16),
            paddingBottom: verticalScale(8),
        },
        noPetContainer: {
            flex: 1,
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            borderBottomLeftRadius: moderateScale(40),
            borderBottomRightRadius: moderateScale(40),
        },
    });

export default styles;
