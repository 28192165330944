import React from 'react';

import Button from '../../../../../components/Button';
import CoinIcon from '../../../../../components/Icons/CoinIcon';
import SeekBar from '../../../../../components/SeekBar';
import { Text, View } from '../../../../../components/Themed';
import { useConfirmationModal } from '../../../../../hooks/useConfirmationModal';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import stylesMain from './styles';

interface IProps {
    defaultPercents?: number;
    onSave: (percents: number) => void;
}

const CharityPercent = ({ defaultPercents = 1, onSave }: IProps) => {
    const [percents, setPercents] = React.useState(defaultPercents);
    const [isDirty, setIsDirty] = React.useState<boolean>(false);
    const percentsArray = ['1%', '20%', '40%', '60%', '80%', '100%'];
    const styles = useThemedStyles(stylesMain);

    const {
        isVisibleModalConfirm,
        setIsVisibleModalConfirm,
        renderItem: confirmationModal,
    } = useConfirmationModal(
        i18n.t('charity.modals.confirmPercentage.title'),
        i18n.t('charity.modals.confirmPercentage.text')
    );

    const handleOnConfirm = () => {
        setIsVisibleModalConfirm(true);
    };

    const onChange = (value: number) => {
        setPercents(value);
        if (value !== defaultPercents) {
            setIsDirty(true);
        }
    };

    return (
        <View style={styles.percentsContainer}>
            <View style={styles.percentsTitleContainer}>
                <CoinIcon slug={'IGUP'} size={32} />
                <Text style={styles.percents}>{percents}%</Text>
            </View>
            <Text style={styles.description}>
                {i18n.t('charity.dailyDonation')}
            </Text>
            <SeekBar
                style={styles.seekBar}
                min={1}
                max={100}
                onChange={onChange}
                percents={percents}
            />
            <View style={styles.percentsArrayContainer}>
                {percentsArray.map((item) => (
                    <Text key={item} style={styles.percentsArrayText}>
                        {item}
                    </Text>
                ))}
            </View>
            {isDirty && (
                <Button
                    onPress={handleOnConfirm}
                    title={i18n.t('charity.buttonSave')}
                    containerStyle={styles.buttonSave}
                />
            )}
            {confirmationModal(() => {
                onSave(percents);
                setIsDirty(false);
            })}
        </View>
    );
};

export default CharityPercent;
