import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(16),
            paddingTop: verticalScale(16),
            paddingBottom: verticalScale(20),
            borderRadius: moderateScale(16),
            marginTop: verticalScale(12),
        },

        headerBlock: {
            flexDirection: 'row',
            backgroundColor: 'transparent',
        },
        header: {
            flex: 1,
            marginRight: scale(12),
            backgroundColor: 'transparent',
        },
        image: {
            width: 108,
            height: 116,
        },
        textGap: {
            marginBottom: verticalScale(4),
        },
        tagsBlock: {
            marginTop: verticalScale(12),
            backgroundColor: 'transparent',
            flexDirection: 'row',

            flexWrap: 'wrap',
        },
        gap: {
            marginRight: scale(4),
        },
        tag: {
            marginBottom: verticalScale(4),
        },
        button: {
            marginTop: verticalScale(20),
        },
        viewButton: {
            marginTop: verticalScale(12),
            alignSelf: 'center',
        },
        viewButtonColor: {
            color: Colors[theme].text.onLight,
        },
    });

export default styles;
