import { useCallback, useMemo } from 'react';

import { useDimensions } from '@contexts/DimensionsContext';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { isWeb } from '@helpers/app';
import { errorsHandler } from '@helpers/errors';
import { waitForNavigation } from '@helpers/helpers';
import { useDefaultScreenOrientation } from '@hooks/useDefaultScreenOrientation';
import { useNftRoyaleMyPendingGames } from '@hooks/useNftRoyaleMyPendingGames';
import { useVisibleHook } from '@hooks/useVisibleHook';
import {
    ACTIVE_ORDER_LIST,
    NftRoyaleAvailableGameOutput,
} from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { useFocusEffect } from '@react-navigation/native';

import ScreenOrientationManager from '../../../../../../utils/ScreenOrientationManager';
import useCreateNftRoyalGame from '../../../hooks/useCreateNftRoyalGame';
import { useNftRoyalActiveOrders } from '../../../hooks/useNftRoyalActiveOrders';
import usePreviousGame from '../../../hooks/usePreviousGame';

const useNftRoyalActiveOrderScreen = () => {
    const navigation = useNavigation();
    const { isSmallLayout } = useDimensions();

    useDefaultScreenOrientation();

    const {
        getNftRoyaleGameConfig,
        nftRoyaleGameConfigLoading,
        nftRoyaleGameConfigRefresh,
        setNftRoyalCurrentAvailableGame,
        setNftRoyalCurrentGame,
        availableBalance,
        getGamingAvailableBalance,
        nftRoyaleGameConfig,
    } = useMysteryGames();

    const {
        getOrders,
        getOrdersByFilter,
        getOrdersLoadMore,
        filter,
        filterLoading,
        loadMore,
        ordersLoading,
        refreshLoading,
        orders,
        canLoadMore,
    } = useNftRoyalActiveOrders();

    const {
        isVisible: confirmPlayModal,
        open: openConfirmPlay,
        close: closeConfirmPlay,
    } = useVisibleHook();

    const {
        getNftRoyalMyPendingGamesLazyLoading,
        getNftRoyalMyPendingGamesLazy,
    } = useNftRoyaleMyPendingGames();

    const loadData = useCallback(
        async (silent: boolean) => {
            await Promise.all([
                getNftRoyaleGameConfig(silent),
                getOrders(silent),
                getGamingAvailableBalance(silent),
            ]);
        },
        [getOrders]
    );

    const isLoading = useMemo(
        () => nftRoyaleGameConfigLoading || ordersLoading,
        [nftRoyaleGameConfigLoading, ordersLoading]
    );
    useFocusEffect(
        useCallback(() => {
            loadData(true);
        }, [loadData])
    );

    const isRefresh = useMemo(
        () => nftRoyaleGameConfigRefresh || refreshLoading,
        [refreshLoading, nftRoyaleGameConfigRefresh]
    );
    const handleRefresh = useCallback(async () => {
        loadData(false);
    }, [loadData]);

    const handleChooseFilter = (item: string) => {
        getOrdersByFilter(item as ACTIVE_ORDER_LIST);
    };

    const handleFirstModalButtonOrder = () => {
        closePreviousGameModalOrder();
        navigation.navigate(ROUTES.NFT_ROYAL_PLAY_GAME);
    };
    const handleSecondModalButtonOrder = async () => {
        closePreviousGameModalOrder();
    };
    const {
        openPreviousGameModal: openPreviousGameModalOrder,
        closePreviousGameModal: closePreviousGameModalOrder,
        renderPreviousGameModal: renderPreviousGameModalOrder,
    } = usePreviousGame(
        handleFirstModalButtonOrder,
        handleSecondModalButtonOrder,
        nftRoyaleGameConfig?.inactiveGameTerminationDays
    );

    const { renderPreviousGameModal, handleCreateGame, createGameLoading } =
        useCreateNftRoyalGame();

    const onOrderListItemPress = async (game: NftRoyaleAvailableGameOutput) => {
        try {
            setNftRoyalCurrentAvailableGame(game);
            const prevGames = await getNftRoyalMyPendingGamesLazy();

            if (prevGames.data?.nftRoyaleMyPendingGames.length) {
                setNftRoyalCurrentGame(
                    prevGames.data.nftRoyaleMyPendingGames[0]
                );
                await waitForNavigation();
                openPreviousGameModalOrder();
                return;
            }
            openConfirmPlay();
        } catch (error) {
            errorsHandler(error, true);
        }
    };

    const showModalLoader = useMemo(
        () => getNftRoyalMyPendingGamesLazyLoading || createGameLoading,
        [createGameLoading, getNftRoyalMyPendingGamesLazyLoading]
    );
    return {
        handleRefresh,
        isRefresh,
        isLoading,
        getOrdersByFilter,
        getOrdersLoadMore,
        filter,
        filterLoading,
        loadMore,
        ordersLoading,
        handleChooseFilter,
        orders,
        isSmallLayout,
        handleCreateGame,
        renderPreviousGameModal,
        showModalLoader,
        renderPreviousGameModalOrder,
        onOrderListItemPress,
        availableBalance,
        confirmPlayModal,
        closeConfirmPlay,
        canLoadMore,
        nftRoyaleGameConfig,
        createGameLoading,
    };
};

export default useNftRoyalActiveOrderScreen;
