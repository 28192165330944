import { useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { errorsHandler } from '@helpers/errors';
import { useLoadingHook } from '@hooks/useLoadingHook';
import {
    LuckyStrikeGameConfigOutput,
    LuckyStrikeGameConfigOutputResponse,
} from '@models/mysteryGames';
import { GET_LUCKY_STRIKE_GAME_CONFIG } from '@requests/luckyStrike';

export const useLuckyStrikeConfig = () => {
    const client = useApolloClient();

    const [luckyStrikeConfig, setLuckyStrikeConfig] =
        useState<LuckyStrikeGameConfigOutput>();

    const {
        isLoading: luckyStrikeConfigLoading,
        startLoading: startLuckyStrikeConfigLoading,
        stopLoading: stopLuckyStrikeConfigLoading,
    } = useLoadingHook(true);

    const {
        isLoading: luckyStrikeConfigRefreshLoading,
        startLoading: startLuckyStrikeConfigRefreshLoading,
        stopLoading: stopLuckyStrikeConfigRefreshLoading,
    } = useLoadingHook();

    const handleStopLoading = () => {
        stopLuckyStrikeConfigRefreshLoading();
        stopLuckyStrikeConfigLoading();
    };

    const getLuckyStrikeConfig = async (silent: boolean) => {
        try {
            silent
                ? startLuckyStrikeConfigLoading()
                : startLuckyStrikeConfigRefreshLoading();
            const result =
                await client.query<LuckyStrikeGameConfigOutputResponse>({
                    query: GET_LUCKY_STRIKE_GAME_CONFIG,
                    fetchPolicy: 'network-only',
                });
            if (result.data) {
                setLuckyStrikeConfig(result.data.luckyStrikeGameConfig);
            }
            handleStopLoading();
        } catch (error) {
            handleStopLoading();
            errorsHandler(error, true);
        }
    };

    return {
        luckyStrikeConfig,
        luckyStrikeConfigLoading,
        luckyStrikeConfigRefreshLoading,
        getLuckyStrikeConfig,
    };
};
