import * as React from 'react';

import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import { BreedingRanksStatsOutput } from '@models/mysteryGames';

import NftEvolutionChance from '../NftEvolutionChance';
import NftEvolutionRankItem from '../NftEvolutionRankItem';
import stylesMain from './styles';

interface IProps {
    isOneColor?: boolean;
    stat: BreedingRanksStatsOutput;
    isLast?: boolean;
}

const RankToBreedTableRow = ({ stat, isOneColor, isLast }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { rank1Id, rank2Id, winChance, winRankId } = stat;

    return (
        <View style={[styles.item, isLast && styles.isLast]}>
            <View style={[styles.rankColumn]}>
                <NftEvolutionRankItem
                    isOneColor={isOneColor}
                    rank1Id={rank1Id}
                    rank2Id={rank2Id}
                    winRankId={winRankId}
                />
            </View>

            <View style={[styles.chanceColumn]}>
                <NftEvolutionChance
                    isOneColor={isOneColor}
                    amount={winChance}
                />
            </View>
        </View>
    );
};
export default RankToBreedTableRow;
