import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            marginHorizontal: scale(20),
            marginTop: verticalScale(32),
        },
        buttonLeave: {
            alignSelf: 'center',
            color: Colors[theme].text.secondary,
        },
        buttonContainer: {
            alignSelf: 'auto',
            marginTop: verticalScale(10),
        },
    });

export default styles;
