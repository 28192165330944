import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import { scale, verticalScale } from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        navBg: { backgroundColor: Colors[theme].background },
        safe: { backgroundColor: Colors[theme].background },
        main: {
            flex: 1,
            paddingHorizontal: scale(20),
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },

        title: {
            marginTop: verticalScale(24),
            color: Colors[theme].text.primary,
            ...font({
                size: 22,
                height: 30,
                weight: '800',
            }),
            marginBottom: verticalScale(8),
        },
        titleEnergy: {
            color: Colors[theme].text.accent,
        },
        text: {
            textAlign: 'center',
            color: Colors[theme].text.secondary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
        textPet: {
            color: Colors[theme].text.primary,
        },
        buttonWrapper: {
            paddingVertical: verticalScale(16),
            paddingHorizontal: scale(20),
        },
        gap: {
            marginBottom: verticalScale(16),
        },
        energyWrapper: {
            paddingBottom: verticalScale(8),
            paddingHorizontal: scale(20),
            paddingTop: verticalScale(16),
        },
        matchWrapper: {
            flexDirection: 'row',
        },
        textWrapper: {
            paddingHorizontal: scale(20),
            alignContent: 'center',
            alignItems: 'center',
            paddingBottom: verticalScale(16),
        },
        likeDislikeButtons: {
            paddingHorizontal: scale(20),
            paddingBottom: verticalScale(10),
        },
    });

export default styles;
