import * as React from 'react';

import Svg from '@assets/icons/buttons/error.svg';

import { IconType, iconSize } from './types';

const Error: React.FunctionComponent<IconType> = ({
    size = iconSize.default,
}) => <Svg width={size} height={size} />;

export default Error;
