import * as React from 'react';
import { useEffect } from 'react';
import { SafeAreaView, ScrollView, TouchableOpacity } from 'react-native';

import { Wallet, utils } from 'ethers';
import * as Crypto from 'expo-crypto';

import { useMutation } from '@apollo/client';
import { UserContext } from '@contexts/UserContext';
import { useWallet } from '@contexts/Wallet/WalletContext';

import { VerifyWalletResponse } from '../../../Data/Models';
import { VERIFY_WALLET } from '../../../Data/Requests';
import Button from '../../../components/Button';
import ModalLoader from '../../../components/ModalLoader';
import { Text, View } from '../../../components/Themed';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import NavigationBar from '../../../navigation/NavigationBar';
import ROUTES from '../../../navigation/routes';
import { useNavigation } from '../../../navigation/useNavigation';
import ErrorReporting from '../../../utils/ErrorReporting';
import stylesMain from './styles';

const INDEXES_POSITIONS = [3, 5, 7, 12];

const CreateWalletVerificationScreen: React.FunctionComponent = () => {
    const styles = useThemedStyles(stylesMain);
    const { tmpWalletData, save } = useWallet();
    const { user, setUser } = React.useContext(UserContext);
    const [selectedItems, setSelectedItems] = React.useState(['']);
    const [error, setError] = React.useState<string>('');
    const [notCorrect, setNotCorrect] = React.useState<boolean[]>([]);
    const [randomPassphrase, setRandomPassphrase] = React.useState<
        string[] | null
    >(null);
    const [dataSource, setDataSource] = React.useState<ReturnType<
        typeof getDataSource
    > | null>();
    const [request] = useMutation<VerifyWalletResponse>(VERIFY_WALLET);
    const [isLoading, setIsLoading] = React.useState(false);
    const navigation = useNavigation();

    useEffect(() => {
        const passphrase = utils
            .entropyToMnemonic(Crypto.getRandomBytes(32))
            .split(/\s+/);
        setRandomPassphrase(passphrase);
    }, []);

    React.useEffect(() => {
        if (notCorrect.every((el) => !el)) {
            setError('');
        }
    }, [notCorrect]);

    const verifyCorrectPassphrase = async (
        correct: string[],
        selected: string[]
    ) => {
        const notCorrect = correct.map(
            (item, index) => selected[index] !== item && true
        );
        setNotCorrect(notCorrect);
        if (tmpWalletData?.wallet && user) {
            if (notCorrect.includes(true)) {
                throw 'words-order-incorrect';
            }
            const signature = await tmpWalletData.wallet.signMessage(user.id);
            return request({ variables: { signature: signature } });
        }
    };

    const shuffleArray = (array: string[]) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const getDataSource = (wallet: Wallet) => {
        const phrase = wallet.mnemonic.phrase.split(/\s+/);
        const indexes = [2, 4, 6, 11];

        const grid = indexes.map((index) => {
            const row = [phrase[index]];
            randomPassphrase?.forEach((value) => {
                if (row.length === 3) {
                    return true;
                }
                if (!row.includes(value)) {
                    row.push(value);
                    return false;
                }
                return true;
            });
            return shuffleArray(row);
        });
        const correctAnswer = phrase.filter((value, index, array) => {
            return indexes.includes(index);
        });
        Console.log('Correct answer: ' + correctAnswer);
        return { grid, correctAnswer };
    };

    const handleVerify = async () => {
        if (!dataSource) {
            return;
        }
        const correct = dataSource.correctAnswer;
        const selected = selectedItems;

        setIsLoading(true);

        try {
            const result = await verifyCorrectPassphrase(correct, selected);

            if (result?.data) {
                save();
                setUser(result.data.verifyWallet);

                setIsLoading(false);

                navigation.navigate(ROUTES.CREATE_WALLET_SUCCESS, {
                    type: 'create',
                });
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            if (error !== 'words-order-incorrect') {
                ErrorReporting.report(error);
            }
            setError(
                i18n.t('createWalletVerification.errors.passphraseNotCorrect')
            );
            setIsLoading(false);
        }
    };

    const getData = async () => {
        if (!tmpWalletData?.wallet || !randomPassphrase) {
            return;
        }
        setIsLoading(true);

        try {
            const data = getDataSource(tmpWalletData.wallet);
            setDataSource(data);
        } catch (error) {
            Console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    React.useEffect(() => {
        getData();
    }, [tmpWalletData, randomPassphrase]);

    const selectedItemsLength = selectedItems.filter((i) => i !== '').length;

    const _renderItem = ({ item, index }: any) => {
        const handlePress = (phrase: string) => {
            const newSelectedItems = [...selectedItems];
            newSelectedItems[index] =
                selectedItems[index] == phrase ? '' : phrase;
            setSelectedItems(newSelectedItems);

            const newCorrect = [...notCorrect];
            newCorrect[index] = false;
            setNotCorrect(newCorrect);
        };
        return (
            <View
                style={[
                    styles.listRow,
                    notCorrect[index] && styles.listRowIncorrect,
                ]}
                key={index}>
                <Text style={styles.indexesItem}>
                    {INDEXES_POSITIONS[index]}.
                </Text>
                <View style={styles.itemsWrapper}>
                    {item.map((phrase: string) => {
                        return (
                            <TouchableOpacity
                                activeOpacity={0.6}
                                style={[
                                    styles.shadow,
                                    phrase === selectedItems[index] &&
                                        styles.shadowSelected,
                                ]}
                                key={phrase}
                                onPress={() => handlePress(phrase)}>
                                <View
                                    style={[
                                        styles.item,
                                        phrase === selectedItems[index] &&
                                            styles.itemSelected,
                                    ]}>
                                    <Text style={styles.itemText}>
                                        {phrase}
                                    </Text>
                                </View>
                            </TouchableOpacity>
                        );
                    })}
                </View>
            </View>
        );
    };

    return (
        <>
            <NavigationBar filled={false} wallet={false} backIcon />
            <View style={styles.wrapper}>
                <Text style={styles.title}>
                    {i18n.t('createWalletVerification.title')}
                </Text>
                <Text style={styles.text}>
                    {i18n.t('createWalletVerification.text')}
                </Text>
                {!!dataSource && (
                    <>
                        <ScrollView
                            style={styles.listWrapper}
                            showsVerticalScrollIndicator={false}>
                            {dataSource.grid.map((row, index) =>
                                _renderItem({ item: row, index })
                            )}
                            <Text style={styles.textError}>{error}</Text>
                        </ScrollView>

                        <Button
                            title={i18n.t('createWalletVerification.button')}
                            disabled={
                                selectedItemsLength !==
                                    dataSource.grid.length || !!error
                            }
                            onPress={handleVerify}
                            debouncedPress
                        />
                    </>
                )}
            </View>
            <ModalLoader
                isVisible={isLoading}
                text={
                    // We run loader 2 times, once when we load data (dataSource not set) and second when verify. Use different texts for this case
                    dataSource
                        ? i18n.t('createWalletVerification.loading')
                        : i18n.t('general.loading')
                }
            />
            <SafeAreaView style={styles.safe} />
        </>
    );
};

export default CreateWalletVerificationScreen;
