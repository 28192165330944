import React, {
    FC,
    PropsWithChildren,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { Contract, ethers } from 'ethers';

import { busd } from '@Data/busd.abi';
import { igu } from '@Data/erc20.abi';
import { igup } from '@Data/igup.abi';
import { wbnb } from '@Data/wbnb.abi';
import {
    Fetcher,
    Percent,
    Route,
    Token,
    TokenAmount,
    Trade,
    TradeType,
} from '@biswap/sdk';
import { isProduction } from '@helpers/app';
import { balanceFromWei } from '@helpers/wallet';
import useFee from '@hooks/useFee';

import routerAbi from '../../Data/router.abi';
import { usePersistStorage } from '../../vendor/react-native-user-persist-storage';
import { ConfigContext } from '../ConfigContext';
import { useWallet } from '../Wallet/WalletContext';
import { Coin, CoinBalance } from '../Wallet/WalletHelpers';
import { formatTradeAmount } from './utils';

export interface TradeResultType {
    explorerUrl: string;
    gasFee: string;
}

type TradeContextType = {
    getAmountsOut: (from: Coin, to: Coin, amount: number) => Promise<string>;
    getAmountsIn: (from: Coin, to: Coin, amount: number) => Promise<string>;
    handleSwap: (
        from: Coin,
        to: Coin,
        exact: string,
        amount: string
    ) => Promise<TradeResultType | undefined>;
    setSlippage: (value: number) => void;
    slippage: number;
    mainToken: CoinBalance | undefined;
    tradeTokens: CoinBalance[];
    getMinTradeAmount: (coin: Coin | undefined) => number;
};

export const tradeConfig = {
    defaultSlippage: 3,
    minTradeAmounts: {
        BNB: 0.01,
        BUSD: 1,
        IGU: 10,
        IGUP: 10,
    },
};

export const tokenAbis = {
    BNB: wbnb,
    BUSD: busd,
    IGU: igu,
    IGUP: igup,
};

export const TradeContext = createContext<TradeContextType>({
    getAmountsOut: async () => '',
    getAmountsIn: async () => '',
    handleSwap: async () => undefined,
    setSlippage: () => undefined,
    slippage: tradeConfig.defaultSlippage,
    mainToken: undefined,
    tradeTokens: [],
    getMinTradeAmount: () => 0,
});

export const useTrade = () => React.useContext(TradeContext);

const TradeProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const { config } = useContext(ConfigContext);
    const { walletData, walletBalance, getCoinBalances } = useWallet();

    const { wallet } = walletData ?? {};
    const [provider, setProvider] = useState<
        ethers.providers.JsonRpcProvider | undefined
    >();
    const [router, setRouter] = useState<Contract | null>(null);
    const [tokens, setTokens] = useState<Record<string, Token>>();
    const [slippage, setSlippage] = usePersistStorage<number>(
        'TradeProvider.tradeSlippage',
        tradeConfig.defaultSlippage
    );
    const { fee } = useFee(walletData?.wallet, config);

    const mainToken = walletBalance?.bnb;

    const tradeTokens = useMemo(() => {
        // For testing purposes
        if (!isProduction()) {
            return getCoinBalances().filter((item) =>
                ['IGU', 'IGUP', 'BUSD'].includes(item.name)
            );
        }

        return getCoinBalances().filter((item) =>
            config?.tradeSupportedTokens.includes(item.name)
        );
    }, [config, getCoinBalances]);

    const getMinTradeAmount = (coin: Coin | undefined) => {
        if (!coin) {
            return 0;
        }

        return tradeConfig.minTradeAmounts[coin];
    };

    // update provider when config is loaded
    useEffect(() => {
        function init() {
            if (!config || !wallet) {
                return;
            }

            setRouter(
                new ethers.Contract(
                    config.swapRouterContractAddress,
                    routerAbi,
                    wallet
                )
            );

            setProvider(
                new ethers.providers.JsonRpcProvider(
                    config.rpcPublicProviders[0],
                    config.chainId
                )
            );
            setTokens({
                BNB: new Token(
                    config.chainId,
                    config.wbnbContractAddress,
                    config.tokenDecimals
                ),
                BUSD: new Token(
                    config.chainId,
                    config.busdContractAddress,
                    config.tokenDecimals
                ),
                IGU: new Token(
                    config.chainId,
                    config.iguTokenContractAddress,
                    config.tokenDecimals
                ),
                IGUP: new Token(
                    config.chainId,
                    config.igupContractAddress,
                    config.tokenDecimals
                ),
            });
        }

        init();
    }, [config, wallet]);

    // Get tokens path, handle the BUSD path for IGU and IGUP
    const getPath = useCallback(
        (from: Coin, to: Coin) => {
            if (tokens && router) {
                Console.info(`[TradeContext] getPath from=${from} to=${to}`);

                if ([from, to].includes(Coin.busd)) {
                    return [tokens[from].address, tokens[to].address];
                }

                return [
                    tokens[from].address,
                    tokens.BUSD.address,
                    tokens[to].address,
                ];
            }

            return [];
        },
        [tokens, router]
    );

    // Get tokens trade route
    const getRoute = useCallback(
        async (from: Coin, to: Coin): Promise<Route> => {
            if (tokens && router) {
                const pairs = [];

                // Direct swap
                if ([from, to].includes(Coin.busd)) {
                    pairs.push(
                        await Fetcher.fetchPairData(
                            tokens[from],
                            tokens[to],
                            provider
                        )
                    );
                    // Swap through BUSD pairs
                } else {
                    pairs.push(
                        await Fetcher.fetchPairData(
                            tokens[from],
                            tokens.BUSD,
                            provider
                        )
                    );
                    pairs.push(
                        await Fetcher.fetchPairData(
                            tokens.BUSD,
                            tokens[to],
                            provider
                        )
                    );
                }

                return new Route(pairs, tokens[from]);
            }

            throw {
                code: 'FETCH_ERROR',
                message: 'Tokens or router not ready',
            };
        },
        [provider, tokens, router]
    );

    const getAmountsOut = useCallback(
        async (from: Coin, to: Coin, amount: number) => {
            if (tokens && router) {
                const inputAmount = ethers.utils.parseEther(amount.toString());
                if (from !== to) {
                    const path = getPath(from, to);
                    Console.log(`[TradeContext] getAmountsOut path=${path}`);
                    const output = await router.getAmountsOut(
                        inputAmount,
                        path
                    );
                    const formattedOutput = balanceFromWei(
                        output[path.length - 1]
                    ).value;
                    Console.log(
                        `[TradeContext] getAmountsOut from=${from} to=${to} output=${formattedOutput}`
                    );
                    return formattedOutput;
                }
            }

            return '';
        },
        [tokens, router, getPath]
    );

    const getAmountsIn = useCallback(
        async (from: Coin, to: Coin, amount: number) => {
            if (tokens && router) {
                const outputAmount = ethers.utils.parseEther(amount.toString());
                if (from !== to) {
                    const path = getPath(from, to);
                    Console.log(`[TradeContext] getAmountsIn path=${path}`);
                    const input = await router.getAmountsIn(outputAmount, path);
                    const formattedOutput = balanceFromWei(input[0]).value;
                    Console.log(
                        `[TradeContext] getAmountsIn from=${from} to=${to} output=${formattedOutput}`
                    );
                    return formattedOutput;
                }
            }

            return '';
        },
        [tokens, router, getPath]
    );

    const getGasFeeFromResult = (result: any) => {
        if (!result?.gasLimit || !result?.gasPrice) {
            return '0';
        }

        return (
            Number(ethers.utils.formatEther(result.gasPrice)) *
            Number(result.gasLimit.toString())
        ).toString();
    };

    const handleSwap = useCallback(
        async (
            from: Coin,
            to: Coin,
            exact: string,
            amount: string
        ): Promise<TradeResultType | undefined> => {
            if (
                provider &&
                wallet &&
                router &&
                config &&
                tokens &&
                mainToken &&
                fee
            ) {
                const route = await getRoute(from, to);
                const slippageTolerance = new Percent(
                    Math.floor(slippage * 100),
                    '10000'
                );

                Console.info(from, '|', to, '|', exact, '|', amount, '|');
                const path = getPath(from, to);
                const toAddress = wallet?.address;
                const deadline = Math.floor(Date.now() / 1000) + 60 * 20;
                const trade = new Trade(
                    route,
                    new TokenAmount(
                        exact === 'from' ? tokens[from] : tokens[to],
                        formatTradeAmount(amount)
                    ),
                    exact === 'from'
                        ? TradeType.EXACT_INPUT
                        : TradeType.EXACT_OUTPUT
                );
                let result;
                let swapAmountIn;
                let swapAmountOut;

                if (exact === 'from') {
                    swapAmountOut = ethers.BigNumber.from(
                        trade.minimumAmountOut(slippageTolerance).raw.toString()
                    ).toHexString();
                    swapAmountIn = ethers.BigNumber.from(
                        trade.inputAmount.raw.toString()
                    ).toHexString();
                } else {
                    swapAmountIn = ethers.BigNumber.from(
                        trade.maximumAmountIn(slippageTolerance).raw.toString()
                    ).toHexString();
                    swapAmountOut = ethers.BigNumber.from(
                        trade.outputAmount.raw.toString()
                    ).toHexString();
                }

                const transactionOverrides = {
                    gasPrice: fee.gasPrice,
                };

                // Approve
                const fromTokenContract = new ethers.Contract(
                    tokens[from].address,
                    tokenAbis[from],
                    wallet
                );
                const approve = await fromTokenContract.approve(
                    router.address,
                    swapAmountIn,
                    transactionOverrides
                );
                await approve.wait();

                if (from === mainToken?.name) {
                    result = await router.swapExactETHForTokens(
                        swapAmountOut,
                        path,
                        toAddress,
                        deadline,
                        {
                            value: swapAmountIn,
                            gasPrice: fee.gasPrice,
                        }
                    );
                } else if (to === mainToken?.name) {
                    result = await router.swapExactTokensForETH(
                        swapAmountIn,
                        swapAmountOut,
                        path,
                        toAddress,
                        deadline,
                        transactionOverrides
                    );
                } else {
                    result = await router.swapExactTokensForTokens(
                        swapAmountIn,
                        swapAmountOut,
                        path,
                        toAddress,
                        deadline,
                        transactionOverrides
                    );
                }

                return {
                    explorerUrl: `${config?.blockExplorerURL}/tx/${result?.hash}`,
                    gasFee: getGasFeeFromResult(result),
                };
            }

            return undefined;
        },
        [
            provider,
            wallet,
            router,
            tokens,
            slippage,
            config,
            mainToken,
            getPath,
            getRoute,
            fee,
        ]
    );

    return (
        <TradeContext.Provider
            value={{
                getAmountsOut,
                getAmountsIn,
                handleSwap,
                setSlippage,
                slippage,
                mainToken,
                tradeTokens,
                getMinTradeAmount,
            }}>
            {children}
        </TradeContext.Provider>
    );
};

export default TradeProvider;
