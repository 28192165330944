import * as React from 'react';
import { useCallback } from 'react';

import AlertCard from '@components/AlertCard';
import { ConfigContext } from '@contexts/ConfigContext';
import { playContextConfig, usePlay } from '@contexts/PlayContext';
import { useUser } from '@contexts/UserContext';
import { useCommunityModal } from '@hooks/useCommunityModal';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import Button from '../Button';
import { ICON_NAMES } from '../Icons';
import PlayEnergy from '../PlayEnergy';
import { Text, View } from '../Themed';
import Timer from '../Timer';
import stylesMain from './styles';

// Uncomment for test purposes
// const testDate = new Date();
// testDate.setSeconds(testDate.getSeconds() + 10);

const PlayTimerBlock = () => {
    const styles = useThemedStyles(stylesMain);
    // const [test, setTest] = useState<boolean>(false);
    const { user, reloadUser } = useUser();
    const { playablePets, isGameInIteration } = usePlay();

    const { config, refetchConfig, isRefetching } =
        React.useContext(ConfigContext);
    const navigation = useNavigation();

    const { renderCommunityModal, canOpenCommunityModal, open } =
        useCommunityModal();

    const handleStartPlaying = React.useCallback(async () => {
        if (await canOpenCommunityModal()) {
            open();
            return;
        }

        navigation.navigateToTasks();
    }, [canOpenCommunityModal]);

    const handleMint = () => {
        navigation.navigateToMint();
    };

    const hasPets = !!playablePets?.length;

    const howToPlay = React.useCallback(() => {
        navigation.navigate(ROUTES.HOW_TO_PLAY, {
            canStart: hasPets && !isGameInIteration,
        });
    }, [hasPets, isGameInIteration]);

    const reload = useCallback(() => {
        if (!isRefetching) {
            refetchConfig();
            reloadUser();
        }
    }, [isRefetching]);

    return (
        <View style={styles.container}>
            <View style={styles.textWrapper}>
                <Text style={styles.timeToComplete}>
                    {i18n.t('playScreen.timeToComplete')}
                </Text>
                <Timer
                    onEndTime={reload}
                    timerStyles={styles.timer}
                    // endOfTime={testDate.toString()} // Uncomment for test purposes
                    endOfTime={config?.nextGameIterationDate}
                    thresholdTime={60}
                    thresholdReachedStyles={styles.timerDanger}
                />
            </View>

            <PlayEnergy energy={user?.energy || 0} />
            {isGameInIteration && (
                <AlertCard
                    text={i18n.t('gameIterationText.text', {
                        minutes: playContextConfig.iterationTime,
                    })}
                    containerStyle={styles.alert}
                />
            )}
            <View style={styles.buttonWrapper}>
                {hasPets ? (
                    <Button
                        containerStyle={styles.gap}
                        onPress={handleStartPlaying}
                        disabled={isGameInIteration}
                        title={i18n.t('playScreen.startPlaying')}
                    />
                ) : (
                    <Button
                        containerStyle={styles.gap}
                        onPress={handleMint}
                        title={i18n.t('playScreen.mintNew')}
                    />
                )}
                <Button
                    onPress={howToPlay}
                    type="text"
                    title={i18n.t('playScreen.howToPlay')}
                    iconName={ICON_NAMES.QUESTION}
                    iconSize={24}
                    containerStyle={styles.secondButton}
                />
            </View>
            {renderCommunityModal()}
        </View>
    );
};
export default PlayTimerBlock;
