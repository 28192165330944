import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import UsersIcon from '@assets/icons/app/users.svg';
import Animation from '@components/Animation';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT, TYPO_TEXT_COLOR } from '@components/TypoText/types';
import { LottieAssets } from '@contexts/AppLoading/types';
import usePreventDoubleClick from '@hooks/usePreventDoubleClick';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { IAllGamesData } from '@models/mysteryGames';

import stylesMain from './styles';

interface IProps {
    item: IAllGamesData;
    usersText?: string;
    loading?: boolean;
    onButtonAction?: () => void;
    buttonDisabled?: boolean;
    buttonLoading?: boolean;
    showUsers?: boolean;
}

const GameBannerSmall = ({
    item,
    usersText,
    onButtonAction,
    buttonDisabled,
    buttonLoading,
    showUsers = false,
    loading,
}: IProps) => {
    const { color: backgroundColor, id } = item;

    const styles = useThemedStyles(stylesMain);

    const { onClick, disabled } = usePreventDoubleClick(onButtonAction);

    return (
        <TouchableOpacity
            onPress={onClick}
            activeOpacity={0.8}
            disabled={disabled || buttonDisabled || buttonLoading || loading}
            style={[
                styles.container,
                {
                    backgroundColor,
                },
            ]}>
            <View style={styles.textBlock}>
                <TypoText
                    allowFontScaling={false}
                    type={TYPO_TEXT.HEADING_H6}
                    colorType={TYPO_TEXT_COLOR.ON_DARK}
                    numberOfLines={2}>
                    {i18n.t(`HomeScreen.GamesBlock.games.${id}.title`)}
                </TypoText>
            </View>
            {showUsers && !!usersText && (
                <View style={styles.ordersBlock}>
                    <View style={styles.block}>
                        <UsersIcon width={18} height={18} />
                        <TypoText
                            allowFontScaling={false}
                            type={TYPO_TEXT.SUBHEAD_LITTLE_BOLD}
                            colorType={TYPO_TEXT_COLOR.ON_DARK}
                            numberOfLines={1}
                            additionalStyles={styles.iconGap}>
                            {usersText}
                        </TypoText>
                    </View>
                </View>
            )}
            <View style={styles.icon}>
                <Animation animation={id as keyof LottieAssets} />
            </View>
        </TouchableOpacity>
    );
};
export default GameBannerSmall;
