import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { moderateScale, scale, verticalScale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: 'transparent',
            alignContent: 'center',

            alignItems: 'center',
            marginHorizontal: scale(16),
            marginTop: verticalScale(24),
        },
        chooseWrapper: {
            marginTop: verticalScale(20),
            paddingVertical: verticalScale(24),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
            width: '100%',
            paddingHorizontal: scale(16),
        },
        chooseBlock: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: verticalScale(16),
        },
        amountWrapper: {
            flexDirection: 'row',
            marginHorizontal: scale(8),
            alignItems: 'center',
        },
        amount: {
            color: Colors[theme].text.primary,
            ...font({
                size: 22,
                height: 30,
                weight: '700',
            }),
            marginRight: scale(2),
        },
        iconPlus: {
            color: Colors[theme].text.accent,
        },
        iconMinus: {
            color: Colors[theme].text.secondary,
        },
        label: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
            textAlign: 'center',
        },
        error: {
            color: Colors[theme].text.critical,
        },
        petContainer: {
            paddingHorizontal: 0,
            flexDirection: 'row',
            alignItems: 'center',
        },
        divider: {
            marginVertical: verticalScale(24),
        },
        petName: {
            flex: 1,
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '600',
            }),
            marginLeft: scale(6),
        },
        textWrapper: {
            backgroundColor: 'transparent',
            marginLeft: verticalScale(14),
            flex: 1,
        },
        imageWrapper: {
            width: scale(64),
            height: scale(64),
        },
        image: {
            width: '100%',
            height: '100%',
            borderRadius: 6,
        },
        titleWrapper: {
            backgroundColor: 'transparent',
            flexDirection: 'row',
            marginBottom: verticalScale(6),
            alignItems: 'center',
        },
    });

export default styles;
