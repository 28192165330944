import React, { useState } from 'react';

import AsyncStorage from '@react-native-async-storage/async-storage';

export const useSaveHeight = (name: string) => {
    const storageName = `SavedHeights.${name}`;

    React.useLayoutEffect(() => {
        const getSavedHeight = async () => {
            setHeight(Number(await AsyncStorage.getItem(storageName)));
        };
        getSavedHeight();
    }, []);

    const [height, setHeight] = useState<number>(0);

    const getHeight = (height: number) => {
        setHeight((prev) => (prev < height ? height : prev));
        AsyncStorage.setItem(storageName, height.toString());
    };

    return { getHeight, height };
};
