import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import Button from '@components/Button';
import { ICON_NAMES } from '@components/Icons';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT, TYPO_TEXT_COLOR } from '@components/TypoText/types';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';

import stylesMain from './styles';

interface IProps {
    containerStyle?: StyleProp<ViewStyle>;
    type:
        | 'iguBefore'
        | 'iguAfter'
        | 'totalPool'
        | 'participants'
        | 'prediction'
        | 'upPayout'
        | 'downPayout';
    text: string;
    icon: React.ReactElement;

    withGap?: boolean;
    onArrowClick?: () => void;
    textColor?: TYPO_TEXT_COLOR;
    titleColor?: TYPO_TEXT_COLOR;
}

const GAME = MYSTERY_GAMES.tokenteller;
const FLOW = 'TokenTellerGameHistoryInfoItem';

const TokenTellerGameHistoryInfoItem = ({
    type,
    text,
    icon,
    withGap = true,
    onArrowClick,
    textColor,
    titleColor,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={[styles.item, withGap && styles.gap]}>
            <View style={styles.itemLeft}>
                <View style={styles.icon}>{icon}</View>
                <TypoText
                    type={TYPO_TEXT.SUBHEAD_SMALL_SEMIBOLD}
                    colorType={textColor}>
                    {i18n.t(`HomeScreen.${GAME}.${FLOW}.${type}`)}
                </TypoText>
            </View>
            {!onArrowClick ? (
                <TypoText
                    type={TYPO_TEXT.SUBHEAD_SMALL_BOLD}
                    colorType={titleColor}>
                    {text}
                </TypoText>
            ) : (
                <Button
                    debouncedPress
                    type="text"
                    isOneColor
                    size="md"
                    title={text}
                    iconName={ICON_NAMES.ARROW_RIGHT_SECONDARY}
                    textStyles={styles.buttonTextStyle}
                    iconDirection="right"
                    iconSize={24}
                    onPress={onArrowClick}
                    iconColor={styles.buttonTextStyle.color}
                />
            )}
        </View>
    );
};
export default TokenTellerGameHistoryInfoItem;
