import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { LinearGradient } from 'expo-linear-gradient';

import Icon from '@assets/icons/wallet/pvpBonus.svg';
import Button from '@components/Button';
import { Text, View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import stylesMain from './styles';

const percent = 5;

interface IProps {
    onButtonPress: () => void;
    containerStyle?: StyleProp<ViewStyle>;
}

const PvpBonusBanner = ({ onButtonPress, containerStyle }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={[styles.container, containerStyle]}>
            <LinearGradient
                colors={['#EF9476', '#FAE1B9']}
                style={styles.gradientBackground}
                start={{ x: 0, y: 0 }}
                end={{ x: 1.2, y: 1 }}>
                <Text style={styles.title}>
                    {i18n.t('pvpBonus.title', {
                        percent,
                    })}
                </Text>
                <Text style={styles.text}>{i18n.t('pvpBonus.text')}</Text>

                <Button
                    title={i18n.t('general.BUTTONS.learnMore')}
                    type="outline"
                    size="md"
                    onPress={onButtonPress}
                    style={styles.buttonStyle}
                    containerStyle={styles.button}
                    textStyles={styles.darkText}
                />
                <Icon height={118} width={106} style={styles.icon} />
            </LinearGradient>
        </View>
    );
};
export default PvpBonusBanner;
