import * as React from 'react';

import Button from '../../../../../components/Button';
import Icon, { ICON_NAMES } from '../../../../../components/Icons';
import CoinIcon from '../../../../../components/Icons/CoinIcon';
import Input from '../../../../../components/Input';
import { Text, View } from '../../../../../components/Themed';
import { CoinBalance } from '../../../../../contexts/Wallet/WalletHelpers';
import { numberFormatter } from '../../../../../helpers/wallet';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import stylesMain from './styles';

interface IProps {
    type: 'from' | 'to';
    amount: string;
    isEstimated?: boolean;
    error?: boolean;
    token?: CoinBalance;
    tokenUsdPrice: string;
    onChangeText: (text: string) => void;
    onPressAvailableLabel: () => void;
    onPressSwapItem: () => void;
    onFocus?: () => void;
    onBlur?: () => void;
    forwardedRef?: any;
    showKeyboard?: boolean;
}

const TradeCoinInput = ({
    type,
    amount,
    isEstimated = false,
    token = undefined,
    error = false,
    tokenUsdPrice = '',
    onChangeText,
    onPressAvailableLabel,
    onPressSwapItem,
    onFocus,
    onBlur,
    forwardedRef = undefined,
    showKeyboard = true,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const [focused, setFocused] = React.useState<boolean>(false);

    let itemContainer;

    if (error) {
        itemContainer = styles.itemDangerContainer;
    } else if (focused) {
        itemContainer = styles.itemFocusedContainer;
    } else {
        itemContainer = styles.itemContainer;
    }

    let label = type === 'from' ? i18n.t('trade.from') : i18n.t('trade.to');
    label += isEstimated ? i18n.t('trade.estimate') : '';

    return (
        <Input
            keyboardType="decimal-pad"
            label={label}
            autoCorrect={false}
            autoComplete="off"
            placeholder={i18n.t('trade.enterAmount')}
            stylesContainer={itemContainer}
            style={styles.selectAvailable}
            isAvailableExternalStyle
            value={amount}
            forwardedRef={forwardedRef}
            showSoftInputOnFocus={showKeyboard}
            onChangeText={onChangeText}
            onFocus={() => {
                setFocused(true);
                onFocus?.();
            }}
            onBlur={() => {
                setFocused(false);
                onBlur?.();
            }}
            secondLabel={
                type === 'from' ? (
                    <Button
                        size="sm"
                        containerStyle={styles.availableButtonContainer}
                        style={styles.availableButtonBody}
                        onPress={onPressAvailableLabel}>
                        <View style={styles.secondLabel}>
                            <Text style={styles.secondLabelAvailableText}>
                                {`${i18n.t('trade.available')}: `}
                            </Text>
                            <Text style={styles.secondLabelAmountText}>
                                {`${numberFormatter(
                                    token?.presentationValueLong ?? '0'
                                )} ${token?.name ?? ''}`}
                            </Text>
                        </View>
                    </Button>
                ) : undefined
            }
            component={
                <>
                    <Button
                        containerStyle={styles.selectToken}
                        type="outline"
                        size="md"
                        onPress={onPressSwapItem}>
                        <View style={styles.selectButton}>
                            {token?.name && (
                                <CoinIcon slug={token.name} size={20} />
                            )}
                            <Text style={styles.coinTitle}>
                                {token?.name ?? ''}
                            </Text>
                            <Icon
                                name={ICON_NAMES.DOWN_ARROW}
                                size={16}
                                color={styles.icon.color}
                            />
                        </View>
                    </Button>
                    <Text
                        style={
                            styles.valueStyle
                        }>{`≈ ${tokenUsdPrice} $`}</Text>
                </>
            }
        />
    );
};
export default TradeCoinInput;
