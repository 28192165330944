import * as React from 'react';
import { ScrollView } from 'react-native';

import UpdateIcon from '@assets/icons/emptyState/updateApp.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import { appVersion, isWeb } from '@helpers/app';
import useLanguage from '@hooks/useLanguage';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import UpdateBlock from './components/UpdateBlock';
import stylesMain from './styles';
import { UPDATES_DATA } from './updates';

const UpdateAppContent = () => {
    const styles = useThemedStyles(stylesMain);

    const { locale } = useLanguage();

    return (
        <ScrollView showsVerticalScrollIndicator={false}>
            <BlockHeader
                title={i18n.t('updateModal.screen.title')}
                type="emptyState"
                text={i18n.t('updateModal.screen.text', {
                    version: isWeb ? '' : ` ${appVersion}`,
                })}
                emptyStateIcon={<UpdateIcon />}
                containerStyle={styles.header}
            />
            {UPDATES_DATA.map((item, index) => (
                <UpdateBlock block={item} lang={locale || 'en'} key={index} />
            ))}
        </ScrollView>
    );
};
export default UpdateAppContent;
