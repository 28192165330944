import { StyleSheet } from 'react-native';

import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import Colors from '../../constants/Colors';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(20),
            marginTop: verticalScale(32),
        },
        wallet: {
            marginHorizontal: scale(20),
            alignSelf: 'center',
            marginTop: 20,
        },
        alert: { marginHorizontal: scale(20), marginTop: verticalScale(16) },
        link: {
            marginHorizontal: scale(20),
            marginTop: verticalScale(20),
            marginBottom: verticalScale(32),
            alignSelf: 'center',
        },
    });

export default styles;
