import * as React from 'react';

import MagnifyingGlassIcon from '../../assets/icons/mint/magnifyingGlass.svg';
import DoneIcon from '../../assets/icons/statuses/done.svg';

const recognitionResultsColor = {
    animal: 'rgba(240, 210, 66, 0.2)',
    pet: 'rgba(233, 179, 98, 0.2)',
    random: [
        'rgba(146, 198, 236, 0.2)',
        'rgba(153, 204, 189, 0.2)',
        'rgba(176, 168, 240, 0.2)',
    ],
};

const recognitionResultsIcon = {
    animal: <DoneIcon width={20} height={20} />,
    pet: <DoneIcon width={20} height={20} />,
    random: <MagnifyingGlassIcon width={20} height={20} />,
};

export const recognitionResultsData = (label: string) => {
    switch (label) {
        case 'Animal':
            return {
                color: recognitionResultsColor.animal,
                icon: recognitionResultsIcon.animal,
            };

        case 'Pet':
            return {
                color: recognitionResultsColor.pet,
                icon: recognitionResultsIcon.pet,
            };

        default:
            return {
                color: recognitionResultsColor.random[
                    Math.floor(Math.random() * 3)
                ],
                icon: recognitionResultsIcon.random,
            };
    }
};
