import { StyleSheet } from 'react-native';

import font from '@helpers/font';

import Colors from '../../../../../../constants/Colors';
import { theme } from '../../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            borderWidth: 1,
            borderRadius: 12,
            borderColor: Colors[theme].border.primary,
            padding: 12,
            paddingTop: 16,
            flex: 1,
            marginHorizontal: 0,
            alignItems: 'center',
        },
        description: {
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
            color: Colors[theme].text.secondary,
            textAlign: 'center',
        },
        text: {
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
            marginTop: 4,
            color: Colors[theme].text.primary,
            textAlign: 'center',
        },
    });

export default styles;
