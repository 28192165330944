import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(20),
            paddingVertical: verticalScale(20),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
        },
        buttonWrapper: {
            marginTop: verticalScale(20),
        },

        textWrapper: {
            alignItems: 'center',
        },
        timeToComplete: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
            marginBottom: verticalScale(8),
        },
        timer: {
            color: Colors[theme].text.primary,
            ...font({
                size: 40,
                height: 48,
                weight: '600',
            }),
            marginBottom: verticalScale(20),
        },
        dangerColor: {
            color: Colors.light.text.danger,
        },
        secondButton: {
            alignSelf: 'center',
        },
        gap: {
            marginBottom: verticalScale(16),
        },
        timerDanger: {
            color: Colors[theme].text.danger,
        },
        alert: {
            marginTop: verticalScale(20),
        },
    });
export default styles;
