import * as React from 'react';
import { Image, ScrollView, View } from 'react-native';

import ActivityIndicator from '@components/ActivityIndicator';
import Button from '@components/Button';
import ButtonsBottom from '@components/ButtonsBottom';
import GuideTooltipContent from '@components/GuideTooltipContent';
import { ICON_NAMES } from '@components/Icons';
import ProgressBar from '@components/ProgressBar';
import TooltipOnBoarding from '@components/TooltipOnBoarding';
import { verticalScale } from '@helpers/dimensions';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import { MysteryLikesSelectRangeProps } from '../../../../../types';
import GameAmount from '../../components/GameAmount';
import LikesRange from '../../components/LikesRange';
import MysteryLikesLoadingScreen from '../../components/MysteryLikesLoadingScreen';
import useMysteryLikesSelectRangeScreen from './hooks/useMysteryLikesSelectRangeScreen';
import stylesMain from './styles';

const MysteryLikesSelectRangeScreen = ({
    route,
}: MysteryLikesSelectRangeProps) => {
    const styles = useThemedStyles(stylesMain);

    const {
        isLoading,
        onSelectRange,
        mysteryLikesGameStats,
        imageWidth,
        handleButton,
        currentMysteryLikesRound,
        selectedRange,
        taskNumber,
        top,
        mysteryLikesPendingGame,
        initialDataLoading,
        handleBack,
        buttonText,
        lastLoading,
        firstTip,
        actionFirstTip,
        secondTip,
        closeSecondTip,
    } = useMysteryLikesSelectRangeScreen(route.params?.isNewCreated);

    return (
        <View style={[styles.main]}>
            {initialDataLoading && (
                <MysteryLikesLoadingScreen isLoading={initialDataLoading} />
            )}
            {!route.params?.isNewCreated && lastLoading && (
                <MysteryLikesLoadingScreen
                    isLoading={lastLoading}
                    type="complete"
                />
            )}
            <View style={[styles.header, { paddingTop: verticalScale(top) }]}>
                <View style={styles.button}>
                    <Button
                        isOneColor
                        size="md"
                        type="outline"
                        iconName={ICON_NAMES.CLOSE}
                        style={styles.singleButton}
                        debouncedPress
                        onPress={handleBack}
                        disabled={lastLoading || isLoading}
                    />
                </View>
                <ProgressBar
                    amount={taskNumber}
                    maxAmount={mysteryLikesGameStats?.maxRoundsCount || 0}
                    containerStyle={styles.progress}
                    progressColor={styles.progressColor.color}
                    progressStyle={styles.progressStyle}
                    textStyle={styles.textStyle}
                />
                <GameAmount
                    amount={
                        balanceFromWei(mysteryLikesPendingGame?.iguAmountWei)
                            .valueLong
                    }
                    backgroundStyle={styles.backgroundStyle}
                    textStyle={styles.textStyle}
                />
            </View>
            {!!currentMysteryLikesRound && (
                <ScrollView showsVerticalScrollIndicator={false}>
                    <View
                        style={[
                            styles.imageBlock,
                            {
                                width: imageWidth,
                                height: imageWidth,
                            },
                        ]}>
                        <View style={styles.loadingBlock}>
                            {isLoading ? (
                                <ActivityIndicator
                                    loading={isLoading}
                                    size="large"
                                    color={styles.loading.color}
                                />
                            ) : (
                                <TooltipOnBoarding
                                    onClose={actionFirstTip}
                                    isVisible={firstTip}
                                    isOneColor
                                    showChildInTooltip
                                    childrenWrapperStyle={
                                        styles.imageTooltipStyle
                                    }
                                    content={
                                        <GuideTooltipContent
                                            text={i18n.t(
                                                `HomeScreen.MysteryLikes.MysteryLikesSelectRange.tooltip1`
                                            )}
                                            hint={1}
                                            maxHints={2}
                                            action={actionFirstTip}
                                            isOneColor
                                        />
                                    }
                                    placement="bottom">
                                    <Image
                                        style={[
                                            styles.image,
                                            {
                                                width: imageWidth,
                                                height: imageWidth,
                                            },
                                        ]}
                                        source={{
                                            uri: currentMysteryLikesRound.petImageUrl,
                                        }}
                                    />
                                </TooltipOnBoarding>
                            )}
                        </View>
                    </View>

                    <LikesRange
                        viewTip={secondTip}
                        closeTip={closeSecondTip}
                        isLoading={isLoading || lastLoading}
                        data={currentMysteryLikesRound.predefinedChoices}
                        onPress={onSelectRange}
                        selectedRange={selectedRange}
                    />
                </ScrollView>
            )}

            <ButtonsBottom
                title={buttonText}
                disabled={!selectedRange || isLoading || lastLoading}
                loading={isLoading || lastLoading}
                onPress={handleButton}
                safe
                tailTitle
                isOneColor
                containerStyle={styles.buttons}
                buttonType="outline"
            />
        </View>
    );
};

export default MysteryLikesSelectRangeScreen;
