import * as React from 'react';

import { StakingPlanOutput } from '@Data/Models';
import BoostImage from '@assets/icons/emptyState/stakingBoost.svg';
import CalculationIcon from '@assets/icons/staking/calculation.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import CardFeatureInfo from '@components/CardFeatureInfo/CardFeatureInfo';
import CarouselScrollView from '@components/Carousel/components/CarouselScrollView';
import Icon, { ICON_NAMES } from '@components/Icons';
import CoinIcon from '@components/Icons/CoinIcon';
import { View } from '@components/Themed';
import { stakingConfig } from '@contexts/StakingContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    item: StakingPlanOutput;
    isLast: boolean;
}

const StakingDetailedInfo = ({ item, isLast }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { durationDays, maxBoostRewardPercentage, name } = item.plan;

    return (
        <CarouselScrollView>
            <View style={styles.itemContainer}>
                <BlockHeader
                    emptyStateIcon={<BoostImage />}
                    title={name}
                    text={i18n.t('staking.plans.text')}
                    type="emptyState"
                />
                <View style={styles.featureWrapper}>
                    <CardFeatureInfo
                        containerStyle={styles.gap}
                        title={i18n.t('staking.plansScreen.calculation.title', {
                            boost: maxBoostRewardPercentage,
                        })}
                        text={i18n.t(
                            'staking.plansScreen.calculation.description'
                        )}
                        icon={<CalculationIcon width={48} height={48} />}
                        warningText={i18n.t(
                            'staking.plansScreen.calculation.alert'
                        )}
                    />
                    <CardFeatureInfo
                        containerStyle={styles.gap}
                        title={i18n.t('staking.plansScreen.lock.title', {
                            days: durationDays,
                        })}
                        text={i18n.t('staking.plansScreen.lock.description')}
                        icon={<Icon name={ICON_NAMES.LOCK_FILL} size={48} />}
                    />
                </View>
            </View>
        </CarouselScrollView>
    );
};
export default StakingDetailedInfo;
