import React from 'react';

import { useAccessibilitySettings } from '@contexts/AccessibilitySettingsContext';
import { useAppState } from '@contexts/AppStateContext';
import { useUser } from '@contexts/UserContext';

import FullMaintenanceState from '../FullMaintenanceState';
import GameMaintenanceState from '../GameMaintenanceState';
import GlobalErrorState from '../GlobalErrorState';
import NoNetworkConnectionState from '../NoNetworkConnectionState';
import UpdateRequiredState from '../UpdateRequiredState';

const AppStateHandler = () => {
    const {
        isUpdateRequired,
        isFullMaintenance,
        isGlobalApolloError,
        isGameMaintenance,
        isVisibleOptionalUpdate,
        setIsVisibleOptionalUpdate,
        isConnected,
    } = useAppState();
    const { isNetworkErrorDisabled } = useAccessibilitySettings();

    const { isAdmin } = useUser();

    const renderAppState = () => {
        /*Console.info(
            'renderAppState',
            `isUpdateRequired=${isUpdateRequired}`,
            `isFullMaintenance=${isFullMaintenance}`,
            `isGlobalApolloError=${isGlobalApolloError}`,
            `isGameMaintenance=${isGameMaintenance}`
        );*/

        if (isUpdateRequired) {
            return <UpdateRequiredState isVisible />;
        }

        if (isFullMaintenance) {
            if (isAdmin) {
                return null;
            }

            return <FullMaintenanceState />;
        }

        if (isGlobalApolloError) {
            return <GlobalErrorState />;
        }

        if (isGameMaintenance) {
            return <GameMaintenanceState />;
        }

        return null;
    };

    const renderOptionalUpdates = () => {
        if (isUpdateRequired) {
            return null;
        }

        return (
            <UpdateRequiredState
                isVisible={isVisibleOptionalUpdate}
                setIsVisible={setIsVisibleOptionalUpdate}
                isOptional
            />
        );
    };

    return (
        <>
            {renderAppState()}
            {renderOptionalUpdates()}
            {!isNetworkErrorDisabled && !isConnected && (
                <NoNetworkConnectionState />
            )}
        </>
    );
};

export default AppStateHandler;
