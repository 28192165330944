import React, { FC, PropsWithChildren, createContext } from 'react';

import * as Notifications from 'expo-notifications';

export type NotificationContextType = {
    scheduleMoveToEarnFinished: (remainingTime: number) => Promise<void>;
    cancelMoveToEarnFinished: () => Promise<void>;
};

export const NotificationContext = createContext<NotificationContextType>({
    scheduleMoveToEarnFinished: async (remainingTime: number) => undefined,
    cancelMoveToEarnFinished: async () => undefined,
});

export const useNotification = () => React.useContext(NotificationContext);

Notifications.setNotificationHandler({
    handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: false,
        shouldSetBadge: false,
    }),
});

const NotificationProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const scheduleMoveToEarnFinished = async () => {
        return undefined;
    };

    const cancelMoveToEarnFinished = async () => {
        return undefined;
    };

    return (
        <NotificationContext.Provider
            value={{ scheduleMoveToEarnFinished, cancelMoveToEarnFinished }}>
            {children}
        </NotificationContext.Provider>
    );
};

export default NotificationProvider;
