import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        paddings: {
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(8),
        },

        itemContainer: {
            backgroundColor: Colors[theme].background,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,

            paddingVertical: verticalScale(16),
            paddingHorizontal: scale(16),
            marginBottom: verticalScale(4),
        },

        petName: {
            flex: 1,
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '600',
            }),
            marginLeft: scale(6),
        },
        textWrapper: {
            backgroundColor: 'transparent',
            marginLeft: verticalScale(14),
            flex: 1,
        },
        imageWrapper: {
            width: scale(64),
            height: scale(64),
        },
        image: {
            width: '100%',
            height: '100%',
            borderRadius: 6,
        },
        titleWrapper: {
            backgroundColor: 'transparent',
            flexDirection: 'row',
            marginBottom: verticalScale(6),
            alignItems: 'center',
        },

        shadow: {
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].shadow.default,
            marginBottom: verticalScale(16),
            flex: 1,
        },
        leftWrapper: {
            backgroundColor: 'transparent',
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
        },
        rightWrapper: {
            paddingLeft: scale(16),
            paddingRight: scale(16),
            height: '100%',
            justifyContent: 'center',
        },

        health: {
            flexDirection: 'row',
            backgroundColor: 'transparent',
            marginTop: verticalScale(4),
        },
        ageWrapper: {
            marginLeft: scale(6),
        },
        selectedShadow: {
            backgroundColor: Colors[theme].shadow.selected,
        },
        selected: {
            borderColor: Colors[theme].border.accent,
        },
    });

export default styles;
