import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import { scale, verticalScale } from '../../../../../helpers/dimensions';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        safe: {
            backgroundColor: Colors[theme].background,
        },
        navBg: {
            padding: scale(20),
            backgroundColor: Colors[theme].background,
        },
        buttonWrapper: {
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
        },
    });

export default styles;
