import * as React from 'react';

import Fruit from '@components/Icons/Fruit';

import { Text, View } from '../../../../../../components/Themed';
import useThemedStyles from '../../../../../../hooks/useThemedStyles';
import i18n from '../../../../../../i18n/i18n';
import HeartIcon from '../assets/icons/HeartIcon';
import StarIcon from '../assets/icons/StarIcon';
import stylesMain from './styles';

interface IProps {
    score: number;
    savedLives: number;
    fruitsCollected: number;
    livesCount: number;
}

export const SummaryInfo = ({
    score,
    savedLives,
    fruitsCollected,
    livesCount,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={styles.main}>
            <Text style={styles.title}>
                {i18n.t('playToEarn.summary.title')}
            </Text>
            <View style={styles.infoRow}>
                <View style={styles.measureContainer}>
                    <StarIcon />
                    <Text style={styles.measureText}>
                        {i18n.t('playToEarn.summary.score')}
                    </Text>
                </View>
                <Text style={styles.valueText}>{score}</Text>
            </View>
            <View style={styles.infoRow}>
                <View style={styles.measureContainer}>
                    <HeartIcon isActive />
                    <Text style={styles.measureText}>
                        {i18n.t('playToEarn.summary.lives')}
                    </Text>
                </View>
                <Text style={styles.valueText}>
                    {`${savedLives}/${livesCount}`}
                </Text>
            </View>
            <View style={styles.infoRow}>
                <View style={styles.measureContainer}>
                    <Fruit />
                    <Text style={styles.measureText}>
                        {i18n.t('playToEarn.summary.fruitCollected')}
                    </Text>
                </View>
                <Text style={styles.valueText}>{fruitsCollected}</Text>
            </View>
        </View>
    );
};

export default SummaryInfo;
