import * as React from 'react';
import TooltipRN from 'react-native-walkthrough-tooltip';

import { isString } from 'lodash';

import { scale } from '@helpers/dimensions';

import useThemedStyles from '../../hooks/useThemedStyles';
import { ComponentAnyType } from '../../types';
import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    children: React.PropsWithChildren<unknown>['children'];
    isVisible: boolean;
    onClose: () => void;
    text: string | ComponentAnyType;
    timeout?: number;
    placement?: 'top' | 'bottom';
}

const Tooltip: React.FC<IProps> = ({
    children,
    isVisible,
    onClose,
    text = '',
    timeout = 2000,
    placement = 'top',
}) => {
    const styles = useThemedStyles(stylesMain);

    const [width, setWidth] = React.useState(0);

    React.useEffect(() => {
        let isMounted = true;

        if (!isVisible) {
            return;
        }

        const timer = setTimeout(() => {
            if (isMounted) onClose();
        }, timeout);

        return () => {
            isMounted = false;
            clearTimeout(timer);
        };
    }, [isVisible, timeout]);

    return (
        <TooltipRN
            isVisible={isVisible}
            onClose={onClose}
            backgroundColor="transparent"
            placement={placement}
            contentStyle={[
                styles.webShadow,
                {
                    width: width + scale(12) * 2 + 2,
                    height: 10,
                    flex: 1,
                },
            ]}
            arrowStyle={styles.arrow}
            showChildInTooltip={false}
            disableShadow>
            <TooltipRN
                isVisible={isVisible}
                onClose={onClose}
                content={
                    isString(text) ? (
                        <Text
                            style={styles.contentText}
                            onLayout={(event) =>
                                setWidth(event.nativeEvent.layout.width)
                            }>
                            {text}
                        </Text>
                    ) : (
                        <View
                            style={styles.contentView}
                            onLayout={(event) =>
                                setWidth(event.nativeEvent.layout.width)
                            }>
                            {text}
                        </View>
                    )
                }
                backgroundColor="transparent"
                placement={placement}
                contentStyle={[styles.content, { width: 'auto' }]}
                arrowStyle={styles.arrow}
                childContentSpacing={7}
                showChildInTooltip={false}
                disableShadow>
                {children}
            </TooltipRN>
        </TooltipRN>
    );
};

export default Tooltip;
