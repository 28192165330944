import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import Colors from '../../../../constants/Colors';
import font from '../../../../helpers/font';
import { theme } from '../../../../types';

const stylesMain = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: Colors[theme].background,
        },
        containerStyle: {
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        flex: {
            flex: 1,
        },
        row: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: verticalScale(24),
        },
        feePanel: {
            marginHorizontal: scale(20),
            marginBottom: verticalScale(16),
        },
        divider: {
            marginBottom: verticalScale(16),
        },
        rowSpaceBetween: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: verticalScale(8),
            marginBottom: verticalScale(16),
            marginHorizontal: scale(20),
        },
        labelFooter: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
        },
        valueFooter: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '600',
            }),
        },
        valueTotalFooter: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
        },
        button: {
            ...font({
                size: 15,
                weight: '700',
            }),
            marginHorizontal: scale(20),
            marginBottom: verticalScale(16),
        },

        mainContent: {
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'flex-start',
            marginBottom: verticalScale(20),
        },
        tradeButtonContainer: {
            flexDirection: 'row',
            justifyContent: 'center',
            marginTop: verticalScale(10),
        },
        tradeButton: {
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(12),
            backgroundColor: Colors[theme].background,
            borderColor: Colors[theme].border.primary,
        },
        errorContainer: {
            marginTop: verticalScale(4),
            marginHorizontal: scale(20),
        },
        errorText: {
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
            color: Colors[theme].text.danger,
        },
        newDeposit: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
        },
        depositButtonStyle: {
            ...font({
                size: 14,
                weight: '700',
                height: 20,
            }),
            color: Colors[theme].text.accent,
            marginLeft: 20,
            marginTop: 8,
        },
        keyboardVisible: {
            display: 'none',
        },
        icon: {
            color: Colors[theme].icon.green,
        },
        estimatedRateContainer: {
            marginHorizontal: scale(20),
            marginVertical: verticalScale(12),
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        estimatedContainer: {
            flexDirection: 'row',
            alignItems: 'center',
        },

        estimatedRatePrice: {
            color: Colors[theme].text.secondary,
        },
        estimatedRateText: {
            color: Colors[theme].text.primary,
            ...font({
                weight: '600',
                size: 15,
            }),
            marginRight: scale(6),
        },
        estimatedButton: {
            paddingHorizontal: scale(8),
            paddingVertical: verticalScale(8),
            backgroundColor: Colors[theme].background,
            borderColor: Colors[theme].border.primary,
        },
    });

export default stylesMain;
