import * as React from 'react';
import { ScrollView } from 'react-native';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import Button from '@components/Button';
import ButtonsBottom from '@components/ButtonsBottom';
import LoadingComponent from '@components/LoadingComponent';
import { View } from '@components/Themed';
import {
    NFT_EVOLUTION_COLOR_SUCCESS,
    useMysteryGames,
} from '@contexts/MysteryGamesContext';
import { useBreedingPetDraftDetails } from '@hooks/useBreedingPetDraftDetails';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';
import NavigationBar from '@navigation/NavigationBar';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import { NftEvolutionSuccessProps } from '../../../../../types';
import BreedingAnimatedCards from '../../components/BreedingAnimatedCards';
import BreedingProcess from '../../components/BreedingProcess';
import NftEvolutionSuccessRankAnimation from '../../components/NftEvolutionSuccessRankAnimation';
import stylesMain from './styles';

const SCREEN = 'NftEvolutionSuccess';

const NftEvolutionSuccessScreen = ({ route }: NftEvolutionSuccessProps) => {
    const styles = useThemedStyles(stylesMain);
    const { params } = route;
    const navigation = useNavigation();
    navigation.blockHardwareBack();

    const { breedingPetDraftDetails } = useMysteryGames();

    const { getBreedingPetDetails, getBreedingPetDetailsLoading } =
        useBreedingPetDraftDetails();

    React.useEffect(() => {
        if (params?.id) {
            getBreedingPetDetails(params.id);
        }
    }, [params?.id]);

    const navigateBack = React.useCallback(() => {
        if (params?.id) {
            navigation.pop();
            return;
        }
        navigation.navigate(ROUTES.HOME);
    }, [params]);

    const sameId = React.useMemo(
        () => params?.id === breedingPetDraftDetails?.id,
        [breedingPetDraftDetails?.id]
    );

    const {
        isVisible: rankAnimationVisible,
        setIsVisible: setRankAnimationVisible,
    } = useVisibleHook(!params?.id);

    const navigateMint = () => {
        navigation.popToTop();
        navigation.navigate(ROUTES.NFT_EVOLUTION_MINT_PET);
    };

    const isNew = React.useMemo(
        () => breedingPetDraftDetails?.editable,
        [breedingPetDraftDetails?.editable]
    );

    const firstButtonText = React.useMemo(
        () =>
            isNew
                ? i18n.t('general.BUTTONS.mintNewNft')
                : i18n.t('general.BUTTONS.continue'),
        [isNew]
    );

    return (
        <View
            style={[
                styles.main,
                { backgroundColor: NFT_EVOLUTION_COLOR_SUCCESS },
            ]}>
            <NavigationBar
                onBack={navigateBack}
                isOneColor
                fillColor={NFT_EVOLUTION_COLOR_SUCCESS}
                filled={false}
                backIcon="close"
                title={i18n.t(
                    `HomeScreen.GamesBlock.games.${MYSTERY_GAMES.nftEvolution}.title`
                )}
                titleColor={styles.title.color}
            />
            {!!params?.id && getBreedingPetDetailsLoading && !sameId ? (
                <LoadingComponent
                    containerStyles={styles.loading}
                    textStyle={styles.textStyle}
                />
            ) : (
                <>
                    <ScrollView
                        showsVerticalScrollIndicator={false}
                        bounces={false}
                        contentContainerStyle={styles.container}>
                        <BreedingAnimatedCards
                            withAnimation={false}
                            containerStyle={styles.containerStyle}
                        />

                        <View style={styles.content}>
                            {
                                <BlockHeader
                                    containerStyle={styles.transparent}
                                    type="emptyState"
                                    title={i18n.t(
                                        `HomeScreen.NftEvolution.${SCREEN}.title`,
                                        {
                                            rank: i18n.t(
                                                `ranks.list.${breedingPetDraftDetails?.rank}.title`
                                            ),
                                        }
                                    )}
                                    text={i18n.t(
                                        `HomeScreen.NftEvolution.${SCREEN}.text`,
                                        {
                                            rank: i18n.t(
                                                `ranks.list.${breedingPetDraftDetails?.rank}.title`
                                            ),
                                        }
                                    )}
                                    textStyle={styles.white}
                                    titleStyle={styles.white}
                                />
                            }
                        </View>
                        {breedingPetDraftDetails?.rank && (
                            <BreedingProcess
                                rank={breedingPetDraftDetails.rank}
                            />
                        )}
                    </ScrollView>

                    <ButtonsBottom
                        title={firstButtonText}
                        onPress={navigateMint}
                        buttonType="outline"
                        isOneColor
                        safe
                        tailTitle
                        containerStyle={styles.transparent}>
                        <Button
                            title={i18n.t('general.BUTTONS.continueLater')}
                            onPress={navigateBack}
                            type="text"
                            containerStyle={styles.button}
                            debouncedPress
                            textStyles={styles.white}
                            tailTitle
                        />
                    </ButtonsBottom>
                    {!!breedingPetDraftDetails && rankAnimationVisible && (
                        <NftEvolutionSuccessRankAnimation
                            isVisible={rankAnimationVisible}
                            rankId={breedingPetDraftDetails?.rank}
                            setIsVisible={setRankAnimationVisible}
                        />
                    )}
                </>
            )}
        </View>
    );
};

export default NftEvolutionSuccessScreen;
