import { isDesktop } from '@helpers/app';
import { useVisibleHook } from '@hooks/useVisibleHook';

export const useHideSummary = () => {
    const {
        open: showSummary,
        close: hideSummary,
        isVisible: isHideSummary,
    } = useVisibleHook();

    const handleInputFocus = () => {
        if (isDesktop) {
            return;
        }
        hideSummary();
    };

    return {
        handleInputFocus,
        isHideSummary,
        showSummary,
        hideSummary,
    };
};
