import { StyleSheet } from 'react-native';

import {
    MYSTERY_LIKES_COLOR,
    NFT_ROYAL_COLOR,
} from '@contexts/MysteryGamesContext';
import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            position: 'absolute',
            zIndex: 9999,
            width: '100%',
            height: '100%',
            flex: 1,
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: NFT_ROYAL_COLOR,
            paddingHorizontal: scale(20),
        },
        button: {
            marginTop: verticalScale(20),
        },
    });

export default styles;
