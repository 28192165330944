import { useCallback, useContext } from 'react';

import {
    CreatePlayTaskResponse,
    FeedTasksResponse,
    MarkPlayTaskDoneResponse,
} from '@Data/Models';
import {
    CREATE_PLAY_TASK,
    GET_PLAY_TASK,
    MARK_PLAY_TASK_DONE,
} from '@Data/Requests';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { PlayContext } from '@contexts/PlayContext';
import { UserContext } from '@contexts/UserContext';
import { isNetworkServerError } from '@helpers/errors';
import {
    playTaskCanBeComplete,
    playTaskDoneParameters,
    playTaskInSandBox,
} from '@helpers/playTask';

import { GameData } from '../../types';
import useTasksErrorsHandler from './useTasksErrorsHandler';

interface CreateGameTask {
    onError?: (error: ApolloError) => void;
    onCompleted?: (data: CreatePlayTaskResponse) => void;
    onSanBox?: (data: CreatePlayTaskResponse) => void;
}

const useGameTask = () => {
    const { user } = useContext(UserContext);
    const { taskWrongState, taskLostConnection } = useTasksErrorsHandler();
    const { updatePlayTask, setPlayTask } = useContext(PlayContext);

    const [createPlayTaskMutation] =
        useMutation<CreatePlayTaskResponse>(CREATE_PLAY_TASK);

    const [markPlayTaskDoneMutation] =
        useMutation<MarkPlayTaskDoneResponse>(MARK_PLAY_TASK_DONE);

    const [
        getGameTask,
        {
            loading: gameTaskLoading,
            error: gameTaskError,
            refetch: refetchGameTask,
        },
    ] = useLazyQuery<FeedTasksResponse>(GET_PLAY_TASK, {
        fetchPolicy: 'network-only',
    });

    const createGameTask = useCallback(
        async (arg?: CreateGameTask) => {
            try {
                const result = await getGameTask();
                if (!result.data) return;
                if (result.data?.playTaskConfig) {
                    setPlayTask(result.data.playTaskConfig);
                }
                if (playTaskInSandBox(result.data?.playTaskConfig)) {
                    updatePlayTask(result.data.playTaskConfig.currentTask);
                    arg?.onSanBox?.({
                        createPlayTask:
                            result.data?.playTaskConfig?.currentTask,
                    });
                } else {
                    createPlayTaskMutation({
                        onCompleted: async (data) => {
                            const result = await getGameTask();
                            if (result.data?.playTaskConfig) {
                                setPlayTask(result.data.playTaskConfig);
                            }
                            arg?.onCompleted?.(data);
                        },
                        onError: (error) => {
                            taskWrongState(error);
                            arg?.onError?.(error);
                        },
                    });
                }
            } catch (e) {
                taskWrongState(e as ApolloError);
            }
        },
        [
            createPlayTaskMutation,
            getGameTask,
            setPlayTask,
            taskWrongState,
            updatePlayTask,
        ]
    );

    const markGameTaskDone = useCallback(
        async (gameResult: GameData, isSandBox: boolean) => {
            try {
                if (isSandBox || !user || !gameResult) {
                    return false;
                }

                const task = await getGameTask();

                if (
                    !task.data?.playTaskConfig ||
                    !playTaskCanBeComplete(task.data.playTaskConfig)
                ) {
                    return false;
                }

                const variables = playTaskDoneParameters(
                    user,
                    task.data.playTaskConfig.currentTask,
                    gameResult?.points,
                    gameResult.lives,
                    gameResult.collectedFruits
                );
                const result = await markPlayTaskDoneMutation({
                    variables,
                });
                const playTaskDone = result.data?.markPlayTaskDone;

                if (playTaskDone) {
                    updatePlayTask(playTaskDone);
                    return playTaskDone;
                }
                return false;
            } catch (error: any) {
                if (!isNetworkServerError(error)) {
                    taskLostConnection();
                }
                return false;
            }
        },
        [user, markPlayTaskDoneMutation, updatePlayTask, taskLostConnection]
    );

    return {
        getGameTask,
        gameTaskLoading,
        gameTaskError,
        refetchGameTask,
        createGameTask,
        markGameTaskDone,
    };
};

export default useGameTask;
