import { useCallback, useMemo } from 'react';

import { useStaking } from '@contexts/StakingContext';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

export const useStakingSuccessScreen = () => {
    const navigation = useNavigation();

    const {
        currentPlan,
        stakingBoost,
        stakingAmount,
        stakingCoin,
        currentStakingPlan,
        refetchStakingPlans,
    } = useStaking();

    const handleBackToWallet = useCallback(async () => {
        await refetchStakingPlans();
        navigation.reset({
            index: 1,
            routes: [{ name: ROUTES.MAIN }, { name: ROUTES.WALLET_ROOT }],
        });
    }, [navigation, refetchStakingPlans]);

    navigation.blockHardwareBack();

    return {
        currentPlan,
        stakingBoost,
        stakingAmount,
        stakingCoin,
        currentStakingPlan,
        refetchStakingPlans,
        handleBackToWallet,
    };
};
