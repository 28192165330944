import * as React from 'react';

import CheckoutSummary from '../../../../components/CheckoutScreen/CheckoutSummary';
import CheckoutSuccessScreen from '../../../../components/CheckoutSuccessScreen';
import Divider from '../../../../components/Divider';
import ModalLoader from '../../../../components/ModalLoader';
import { PlayContext } from '../../../../contexts/PlayContext';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import { useNavigation } from '../../../../navigation/useNavigation';
import { CollectionPetLevelUpSuccessProps } from '../../../../types';
import TransactionDetailsLevelUp from '../components/TransactionDetailsLevelUp';
import stylesMain from './styles';

const CollectionPetLevelUpSuccessScreen = ({
    route,
}: CollectionPetLevelUpSuccessProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const { refetchPets, petsLoading } = React.useContext(PlayContext);

    const { pet, payment, transactionResult, navigateBackOnUpgrade } =
        route.params;

    const handleNavigateToPet = async () => {
        const newPets = await refetchPets(false);

        if (newPets.pets) {
            const updatedPet = newPets.pets.find((p) => p.id === pet.id);
            if (!navigateBackOnUpgrade) {
                navigation.pop();
                navigation.pop();
            }
            if (updatedPet) {
                navigation.navigateToPetDetails(
                    updatedPet,
                    navigateBackOnUpgrade
                );
                return;
            }
        }
    };

    return (
        <>
            <CheckoutSuccessScreen
                title={i18n.t('petLevelUp.petLevelUpSuccess.title')}
                text={i18n.t('petLevelUp.petLevelUpSuccess.text', {
                    name: pet.name,
                    level: pet.level + 1,
                })}
                onPressButtonMain={handleNavigateToPet}
                buttonMainText={i18n.t(
                    'petLevelUp.petLevelUpSuccess.backToPet'
                )}
                buttonAdditionalText={
                    !!navigateBackOnUpgrade ? i18n.t('general.back') : ''
                }
                onPressButtonAdditional={
                    navigateBackOnUpgrade
                        ? () => {
                              navigation.navigate(navigateBackOnUpgrade);
                          }
                        : undefined
                }
                TransactionDetailsComponent={
                    <>
                        <TransactionDetailsLevelUp pet={pet} lvlUp />
                        <Divider customStyles={styles.divider} />
                        <CheckoutSummary
                            payment={payment}
                            transactionResult={transactionResult ?? undefined}
                        />
                    </>
                }
                explorerUrl={transactionResult?.explorerUrl}
            />
            <ModalLoader
                isVisible={petsLoading}
                text={i18n.t('petLevelUp.petLevelUpSuccess.loading')}
            />
        </>
    );
};

export default CollectionPetLevelUpSuccessScreen;
