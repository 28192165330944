import { StyleSheet } from 'react-native';

import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            backgroundColor: 'transparent',
        },
    });

export default styles;
