import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import { stylesContainerWithBorderDefault } from '@helpers/style';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(16),
            paddingTop: verticalScale(20),
            paddingBottom: verticalScale(16),
            borderRadius: moderateScale(12),
        },
        default: {
            ...stylesContainerWithBorderDefault(theme),
            paddingTop: verticalScale(20),
            backgroundColor: Colors[theme].background,
        },
        opacity: {
            backgroundColor: Colors[theme].fillPrimaryMinimal,
        },
        header: {
            marginBottom: verticalScale(4),
            flexDirection: 'row',
            backgroundColor: 'transparent',
        },
        prizes: {
            flex: 1,
            marginRight: scale(12),
        },
        chance: {
            maxWidth: 100,
            textAlign: 'right',
        },
    });

export default styles;
