import * as React from 'react';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import QuantumSpinHistoryScreen from '../../screens/TabBar/Home/QuantumSpinFlow/QuantumSpinHistory/QuantumSpinHistoryScreen';
import QuantumSpinMainScreen from '../../screens/TabBar/Home/QuantumSpinFlow/QuantumSpinMain/QuantumSpinMainScreen';
import { QuantumSpinTabsParamList } from '../../types';
import ROUTES from '../routes';

const QuantumSpinTabsStack =
    createBottomTabNavigator<QuantumSpinTabsParamList>();

export const QuantumSpinTabsNavigator = () => {
    return (
        <QuantumSpinTabsStack.Navigator
            screenOptions={{
                headerShown: false,
            }}
            initialRouteName={ROUTES.QUANTUM_SPIN_MAIN}
            backBehavior="none"
            tabBar={() => null}>
            <QuantumSpinTabsStack.Screen
                name={ROUTES.QUANTUM_SPIN_MAIN}
                component={QuantumSpinMainScreen}
            />
            <QuantumSpinTabsStack.Screen
                name={ROUTES.QUANTUM_SPIN_HISTORY}
                component={QuantumSpinHistoryScreen}
            />
        </QuantumSpinTabsStack.Navigator>
    );
};
