import * as React from 'react';
import { ImageBackground, StyleSheet } from 'react-native';
import {
    GestureDetector,
    GestureHandlerRootView,
    GestureType,
    SimultaneousGesture,
} from 'react-native-gesture-handler';
import Animated from 'react-native-reanimated';

import GestureHandler from '../../../../components/GesturePanPinch';
import { moderateScale } from '../../../../helpers/dimensions';

interface IProps {
    gesture: SimultaneousGesture;
    gesturePan: GestureType;
    gesturePinch: GestureType;
    petImageUri: string;
    animatedStyles: {
        transform: (
            | {
                  translateX: number;
                  translateY?: undefined;
                  scale?: undefined;
              }
            | {
                  translateY: number;
                  translateX?: undefined;
                  scale?: undefined;
              }
            | {
                  scale: number;
                  translateX?: undefined;
                  translateY?: undefined;
              }
        )[];
    };
    imageDefaultStyles: {
        width: number;
        height: number;
        top: number;
        left: number;
    };
    backgroundImageUri: string;
    imageBackgroundSize: {
        width: number;
        height: number;
    };
    onImageLoadEnd: () => void;
}

const DraggableImage = ({
    gesture,
    gesturePan,
    gesturePinch,
    petImageUri,
    animatedStyles,
    imageDefaultStyles,
    backgroundImageUri,
    imageBackgroundSize,
    onImageLoadEnd,
}: IProps) => {
    const { width, height, top, left } = imageDefaultStyles;

    return (
        <ImageBackground
            style={[
                styles.backgroundImage,
                { height: imageBackgroundSize.width },
            ]}
            source={{
                uri: backgroundImageUri,
            }}
            resizeMode="cover">
            <GestureHandlerRootView>
                <GestureHandler
                    gesturePan={gesturePan}
                    gesturePinch={gesturePinch}
                    gesture={gesture}>
                    <Animated.Image
                        onLoadEnd={onImageLoadEnd}
                        source={{
                            uri: petImageUri,
                        }}
                        style={[
                            {
                                top,
                                left,
                                width,
                                height,
                            },
                            animatedStyles,
                        ]}
                    />
                </GestureHandler>
            </GestureHandlerRootView>
        </ImageBackground>
    );
};

const styles = StyleSheet.create({
    backgroundImage: {
        width: '100%',
        overflow: 'hidden',
        borderRadius: moderateScale(12),
    },
});

export default DraggableImage;
