import * as React from 'react';

import Svg from '../../assets/icons/social/tikTok.svg';
import { IconType, iconSize } from './types';

const TikTok: React.FunctionComponent<IconType> = ({
    size = iconSize.default,
}) => <Svg height={size} width={size} />;

export default TikTok;
