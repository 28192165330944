import { useCallback, useMemo, useState } from 'react';

import { useConfig } from '@contexts/ConfigContext';
import { useGamingWallet } from '@contexts/GamingWalletContext';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { errorsHandler } from '@helpers/errors';
import { isGraphqlError } from '@helpers/helpers';
import { priceString } from '@helpers/payments';
import { toastError } from '@helpers/toastNotification';
import { feeWithCurrency, valueToWei } from '@helpers/wallet';
import useBlockchainErrorsHandler from '@hooks/useBlockchainErrorsHandler';
import useFeeInfo from '@hooks/useFeeInfo';
import { useLoadingHook } from '@hooks/useLoadingHook';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

export const useTopUpCheckoutScreen = () => {
    const navigation = useNavigation();
    const { topUpAmount, customPayment, isBNBEnough } = useGamingWallet();

    const { config } = useConfig();
    const [disableButton, setDisableButton] = useState(false);
    const { handleBlockchainErrors, renderBlockchainErrorsModal } =
        useBlockchainErrorsHandler(
            () => {
                setDisableButton(true);
            },
            () => {
                setDisableButton(false);
            }
        );

    const {
        isVisible: depositModal,
        close: closeDepositModal,
        open: openDepositModal,
    } = useVisibleHook();
    const {
        isVisible: confirmationModal,
        close: closeConfirmationModal,
        open: openConfirmationModal,
    } = useVisibleHook();

    const {
        walletBalance,
        reloadBalance,
        fee,
        balanceReloading,
        depositIGUonVIGU,
    } = useWallet();

    const onRefresh = useCallback(() => {
        reloadBalance(false);
    }, []);

    const notEnoughFundsError = useMemo(
        () => Number(walletBalance?.igu?.value) < Number(topUpAmount),
        [walletBalance?.igu, topUpAmount]
    );

    const navigateBack = () => {
        navigation.pop();
    };

    const { isLoading, startLoading, stopLoading } = useLoadingHook();

    const onPressPurchase = useCallback(async () => {
        setDisableButton(true);
        startLoading();
        try {
            const transactionResult = await depositIGUonVIGU(topUpAmount);
            stopLoading();
            setDisableButton(false);
            navigation.popToTop();
            navigation.replace(ROUTES.TOP_UP_SUCCESS, { transactionResult });
        } catch (error: any) {
            errorsHandler(error);
            setDisableButton(false);
            stopLoading();

            handleBlockchainErrors(error);
            if (isGraphqlError(error, 'VIRTUAL_WALLET_INSUFFICIENT_FUNDS')) {
                toastError(
                    undefined,
                    i18n.t('checkout.errors.insufficientVirtualBalance')
                );
            }
        }
    }, [topUpAmount]);

    const { renderFeeInfoIcon } = useFeeInfo(
        closeConfirmationModal,
        openConfirmationModal
    );

    const canOpenConfirmTransaction = useMemo(
        () => disableButton || isLoading || notEnoughFundsError || !isBNBEnough,
        [disableButton, isLoading, notEnoughFundsError, isBNBEnough]
    );

    const summaryValues = useMemo(() => {
        return [
            {
                key: i18n.t('checkout.fields.fee'),
                value: feeWithCurrency(fee?.executeTransaction.IGU),
                icon: renderFeeInfoIcon(true),
            },
            {
                key: i18n.t('checkout.fields.totalSummary'),
                value: priceString(topUpAmount, Coin.igu),
            },
        ];
    }, [topUpAmount, fee?.executeTransaction.IGU]);

    return {
        navigateBack,
        renderFeeInfoIcon,
        isBNBEnough,
        notEnoughFundsError,
        onRefresh,
        disableButton,
        depositModal,
        closeDepositModal,
        openDepositModal,
        topUpAmount,
        config,
        balanceReloading,
        fee,
        isLoading,
        onPressPurchase,
        renderBlockchainErrorsModal,
        customPayment,
        confirmationModal,
        closeConfirmationModal,
        openConfirmationModal,
        canOpenConfirmTransaction,
        summaryValues,
    };
};
