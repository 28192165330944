import { StyleSheet } from 'react-native';

import { verticalScale } from '@helpers/dimensions';

import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        header: {
            marginBottom: verticalScale(4),
        },
    });

export default styles;
