import * as React from 'react';
import { FlatList, SafeAreaView } from 'react-native';

import { ICON_NAMES } from '@components/Icons';
import LoadingComponent from '@components/LoadingComponent';
import PetCollectionListItem from '@components/PetCollectionListItem';
import { View } from '@components/Themed';
import { PlayContext } from '@contexts/PlayContext';
import { waitForNavigation } from '@helpers/helpers';
import { sortPetsByLastTransferAndCreated } from '@helpers/sort';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBarModal from '@navigation/NavigationBar/NavigationBarModal';
import useNavigationListeners from '@navigation/hooks/useNavigationListeners';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { StackActions, useFocusEffect } from '@react-navigation/native';

import stylesMain from './styles';

const NFTSListScreen = () => {
    useNavigationListeners();
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const { pets, refetchPets, petsLoading } = React.useContext(PlayContext);

    const filteredPets = React.useMemo(
        () =>
            pets
                ?.filter((pet) => pet.isMinted)
                .sort(sortPetsByLastTransferAndCreated),
        [pets]
    );

    useFocusEffect(
        React.useCallback(() => {
            refetchPets(true);
        }, [])
    );

    const handlePressPet = async (pet: any) => {
        navigation.dispatch(StackActions.pop());
        await waitForNavigation();
        navigation.navigateToPetDetails(pet);
    };

    return (
        <View style={styles.container}>
            <NavigationBarModal
                iconName={ICON_NAMES.CLOSE}
                title={i18n.t('wallet.nft.allNFTs')}
                onBack={() => navigation.dispatch(StackActions.pop())}
            />
            <FlatList
                data={filteredPets}
                renderItem={({ item }) => (
                    <View style={styles.petContainer}>
                        <PetCollectionListItem
                            pet={item}
                            onPressPet={() => handlePressPet(item)}
                            canLvlUp={item.levelUpAvailable}
                            canRankUp={item.rankUpAvailable}
                            isDead={item.reviveAvailable}
                            isAgeDead={item.reviveAgeAvailable}
                            navigateBackOnUpgrade={ROUTES.WALLET_NFTS_MAIN}
                            fromScreenModal
                            isNeedToFeed={item.healUpAvailable}
                            border
                        />
                    </View>
                )}
                keyExtractor={(item) => item.id}
                extraData={filteredPets}
                numColumns={1}
                showsVerticalScrollIndicator={false}
                ListEmptyComponent={
                    petsLoading ? (
                        <LoadingComponent
                            containerStyles={styles.loadingWrapper}
                            text={i18n.t('general.loading')}
                        />
                    ) : null
                }
            />

            <SafeAreaView />
        </View>
    );
};

export default NFTSListScreen;
