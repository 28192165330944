import React from 'react';
import { TouchableOpacity } from 'react-native';

import { useApolloClient } from '@apollo/client';
import { toastErrorUnknown } from '@helpers/toastNotification';

import { GetRevivePaymentsResponse, Pet } from '../../../../../Data/Models';
import { GET_REVIVE_PAYMENTS } from '../../../../../Data/Requests';
import NftIsDead from '../../../../../assets/icons/health/nftIsDead.svg';
import Button from '../../../../../components/Button';
import HealthIndicator from '../../../../../components/HealthIndicator';
import Icon, { ICON_NAMES } from '../../../../../components/Icons';
import ModalChooseAmountOfHealUp from '../../../../../components/ModalChooseAmountOfHealUp';
import ModalNFTisDead from '../../../../../components/ModalNFTisDead';
import ModalWhatIsHealth from '../../../../../components/ModalWhatIsHealth';
import { View } from '../../../../../components/Themed';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import ROUTES from '../../../../../navigation/routes';
import { useNavigation } from '../../../../../navigation/useNavigation';
import DetailsBlock from '../DetailsBlock';
import Styles from './styles';

interface IProps {
    pet: Pet;
}

const HealthDetailsBlock = ({ pet }: IProps) => {
    const styles = useThemedStyles(Styles);
    const client = useApolloClient();
    const navigation = useNavigation();

    const { healUpAvailable, dead, healthPercentage, reviveAvailable } = pet;

    const fetchRevivePayments = async () => {
        const result = await client.query<GetRevivePaymentsResponse>({
            query: GET_REVIVE_PAYMENTS,
            variables: {
                petId: pet.id,
            },
        });
        return result.data.revivePayments;
    };

    const [healthInfoModal, setHealthInfoModalVisible] = React.useState(false);
    const [deadInfoModal, setDeadInfoModalVisible] = React.useState(false);
    const openHealthInfoModal = () => {
        setHealthInfoModalVisible(true);
    };
    const closeHealthInfoModal = () => {
        setHealthInfoModalVisible(false);
    };
    const openDeadInfoModal = () => {
        setDeadInfoModalVisible(true);
    };
    const closeDeadInfoModal = () => {
        setDeadInfoModalVisible(false);
    };

    const [showFeedModal, setShowFeedModal] = React.useState<boolean>(false);

    const handleShowFeedModal = () => {
        setShowFeedModal(true);
    };
    const handleCloseFeedModal = () => {
        setShowFeedModal(false);
    };

    const onFeedPress = async () => {
        handleShowFeedModal();
    };

    const onRestorePress = async () => {
        try {
            const payments = await fetchRevivePayments();

            navigation.navigate(ROUTES.COLLECTION_PET_REVIVE_CHECKOUT, {
                pet,
                payments,
                navigateBackOnUpgrade: ROUTES.COLLECTION,
            });
        } catch (error: any) {
            toastErrorUnknown();
        }
    };

    return (
        <DetailsBlock
            title={
                dead
                    ? i18n.t('detailedPet.health.titleDead')
                    : i18n.t('detailedPet.health.title')
            }
            description={
                dead
                    ? i18n.t('detailedPet.health.textDead')
                    : i18n.t('detailedPet.health.text')
            }
            icon={
                dead ? (
                    <NftIsDead width={48} height={48} />
                ) : (
                    <TouchableOpacity
                        onPress={openHealthInfoModal}
                        activeOpacity={1}>
                        <Icon name={ICON_NAMES.HEALTH} size={48} />
                    </TouchableOpacity>
                )
            }
            isDead={dead && reviveAvailable}
            onInfoIconPress={dead ? openDeadInfoModal : openHealthInfoModal}>
            {!dead && (
                <View style={styles.gap}>
                    <HealthIndicator healthPercentage={healthPercentage} />
                </View>
            )}
            {healUpAvailable && (
                <View style={styles.buttonWrapper}>
                    <Button
                        onPress={onFeedPress}
                        title={i18n.t('detailedPet.health.buttonFeed')}
                        size="md"
                    />
                </View>
            )}
            {reviveAvailable && (
                <View style={styles.buttonWrapper}>
                    <Button
                        onPress={onRestorePress}
                        title={i18n.t('detailedPet.health.buttonRestore')}
                        type={'outline'}
                        style={styles.buttonStyle}
                        containerStyle={styles.buttonContainerStyle}
                        textStyles={styles.restoreButton}
                        size="md"
                    />
                </View>
            )}
            <ModalWhatIsHealth
                onPress={onFeedPress}
                showButton={healUpAvailable}
                closeModal={closeHealthInfoModal}
                isVisible={healthInfoModal}
            />
            <ModalNFTisDead
                closeModal={closeDeadInfoModal}
                isVisible={deadInfoModal}
                pet={pet}
            />
            <ModalChooseAmountOfHealUp
                pet={pet}
                isVisible={showFeedModal}
                closeModal={handleCloseFeedModal}
            />
        </DetailsBlock>
    );
};
export default HealthDetailsBlock;
