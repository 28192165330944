import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { isAndroid } from '@helpers/app';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../types';

const stylesMain = (theme: theme) =>
    StyleSheet.create({
        scrollContainer: {
            backgroundColor: Colors[theme].background,
        },
        container: {
            flex: 1,
            backgroundColor: Colors[theme].background,
            paddingHorizontal: scale(20),
            paddingVertical: verticalScale(24),
        },
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 22,
                height: 30,
                weight: '700',
            }),
        },
        titleMargin: {
            marginBottom: verticalScale(6),
        },
        description: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
        },
        helperButton: {
            marginTop: verticalScale(10),
            marginBottom: verticalScale(20),
        },
        subtitle: {
            color: Colors[theme].text.primary,
            ...font({
                size: 18,
                height: 24,
                weight: '700',
            }),
        },
        editText: {
            ...font({
                size: 15,
                height: 24,
                weight: '700',
            }),
            color: Colors[theme].text.accent,
        },
        selectedOrganizationContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingTop: verticalScale(24),
            paddingBottom: verticalScale(16),
        },
        divider: {
            borderTopWidth: 0.5,
            borderTopColor: Colors[theme].border.primary,
        },
        paddingHorizontal: {
            paddingHorizontal: scale(20),
        },
        marginBottom: {
            marginBottom: isAndroid ? 50 : 20,
        },
        containerStyle: {
            marginBottom: verticalScale(20),
        },
    });

export default stylesMain;
