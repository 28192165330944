import React from 'react';

import BlueAvatar from '../../assets/icons/avatars/blue.svg';
import GreenAvatar from '../../assets/icons/avatars/green.svg';
import OrangeAvatar from '../../assets/icons/avatars/orange.svg';
import PurpleAvatar from '../../assets/icons/avatars/purple.svg';
import YellowAvatar from '../../assets/icons/avatars/yellow.svg';
import Colors from '../../constants/Colors';
import { useTheme } from '../../contexts/ThemeContext';
import { useUser } from '../../contexts/UserContext';
import useThemedStyles from '../../hooks/useThemedStyles';
import { View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    size?: number;
    type?: 'transparent' | 'white';
    userId?: string | null;
    isOneColor?: boolean;
}

const Avatar = ({
    size = 32,
    type = 'transparent',
    userId,
    isOneColor,
}: IProps) => {
    const { user } = useUser();
    const id = userId || user?.id;

    const sum = React.useMemo(() => {
        let s = 0;
        if (id) {
            id.replace(/\D/g, '')
                .split('')
                .map((item) => (s += +item));
        }
        return s;
    }, [id]);

    const styles = useThemedStyles(stylesMain);

    const { theme } = useTheme();

    const imageSize = { width: size - 8, height: size - 8 };

    const avatars = {
        purple: {
            image: <PurpleAvatar {...imageSize} />,
            backgroundColor: Colors[theme].avatarBackground.purple,
        },
        blue: {
            image: <BlueAvatar {...imageSize} />,
            backgroundColor: Colors[theme].avatarBackground.blue,
        },
        orange: {
            image: <OrangeAvatar {...imageSize} />,
            backgroundColor: Colors[theme].avatarBackground.orange,
        },
        green: {
            image: <GreenAvatar {...imageSize} />,
            backgroundColor: Colors[theme].avatarBackground.green,
        },
        yellow: {
            image: <YellowAvatar {...imageSize} />,
            backgroundColor: Colors[theme].avatarBackground.yellow,
        },
    };

    const avatar = React.useMemo(() => {
        if (sum % 5 === 0) {
            return avatars.orange;
        } else if (sum % 4 === 0) {
            return avatars.green;
        } else if (sum % 3 === 0) {
            return avatars.yellow;
        } else if (sum % 2 === 0) {
            return avatars.blue;
        }
        return avatars.purple;
    }, [sum]);

    return (
        <View
            style={
                type === 'white' && [
                    styles.shadow,
                    {
                        width: size,
                        height: size,
                        borderRadius: size / 2,
                        borderBottomWidth: size + 3,
                    },
                    isOneColor && styles.isOneColorShadow,
                ]
            }>
            <View
                style={[
                    styles.background,
                    {
                        width: size,
                        height: size,
                        borderRadius: size / 2,
                    },
                    type === 'transparent' && {
                        backgroundColor: avatar.backgroundColor,
                    },
                    type === 'white' && styles.whiteBackground,
                    isOneColor && type === 'white' && styles.isOneColor,
                ]}>
                {avatar.image}
            </View>
        </View>
    );
};

export default Avatar;
