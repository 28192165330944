import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import DOWN from '@assets/icons/mysteryGames/TokenTeller/down.svg';
import IGU_After from '@assets/icons/mysteryGames/TokenTeller/history/iguAfter.svg';
import IGU_Before from '@assets/icons/mysteryGames/TokenTeller/history/iguBefore.svg';
import Participants from '@assets/icons/mysteryGames/TokenTeller/history/participants.svg';
import TotalPool from '@assets/icons/mysteryGames/TokenTeller/history/pool.svg';
import Prediction from '@assets/icons/mysteryGames/TokenTeller/history/prediction.svg';
import UP from '@assets/icons/mysteryGames/TokenTeller/up.svg';
import { View } from '@components/Themed';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { priceString } from '@helpers/payments';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import {
    MYSTERY_GAMES,
    TOKEN_TELLER_GAME_PREDICTION_DIRECTION,
    TOKEN_TELLER_HISTORY_GAMES_STATUS,
    TokentellerGameHistoryOutput,
} from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import TokenTellerGameHistoryInfoItem from '../TokenTellerGameHistoryInfoItem';
import stylesMain from './styles';

interface IProps {
    containerStyle?: StyleProp<ViewStyle>;
    data: TokentellerGameHistoryOutput;
    gameId: string;
}

const GAME = MYSTERY_GAMES.tokenteller;

const TokenTellerGameHistoryInfo = ({
    containerStyle,
    data,
    gameId,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const navigation = useNavigation();

    const {
        beforePrice,
        afterPrice,
        winDirection,
        iguAmountWei,
        userPredictIguAmountWei,
        upCoefficient,
        downCoefficient,
        userCount,
        userDirection,
        status,
    } = data;

    const isUp = React.useMemo(
        () => data.winDirection === TOKEN_TELLER_GAME_PREDICTION_DIRECTION.Up,
        [winDirection]
    );

    const isDraw = React.useMemo(
        () => status === TOKEN_TELLER_HISTORY_GAMES_STATUS.Refund,
        [status]
    );

    const openUsers = React.useCallback(() => {
        navigation.push(ROUTES.TOKEN_TELLER_ALL_PARTICIPANTS, { id: gameId });
    }, [gameId]);

    return (
        <View style={containerStyle}>
            <View style={styles.container}>
                <TokenTellerGameHistoryInfoItem
                    type={'iguBefore'}
                    text={beforePrice.slice(0, 10)}
                    icon={<IGU_Before width={24} height={24} />}
                />
                <TokenTellerGameHistoryInfoItem
                    type={'iguAfter'}
                    text={afterPrice.slice(0, 10)}
                    icon={<IGU_After width={24} height={24} />}
                />
                <TokenTellerGameHistoryInfoItem
                    type={'totalPool'}
                    text={priceString(
                        balanceFromWei(iguAmountWei).valueShort,
                        Coin.vigu
                    )}
                    icon={<TotalPool width={24} height={24} />}
                />
                <TokenTellerGameHistoryInfoItem
                    withGap={false}
                    type={'participants'}
                    text={`${userCount} ${i18n.t('general.KEYS.users')}`}
                    onArrowClick={openUsers}
                    icon={<Participants width={24} height={24} />}
                />
            </View>
            <View style={[styles.container, styles.gap]}>
                <TokenTellerGameHistoryInfoItem
                    withGap={!isDraw}
                    type={'prediction'}
                    text={`${priceString(
                        balanceFromWei(userPredictIguAmountWei).valueLong,
                        Coin.vigu
                    )} / ${i18n.t(`HomeScreen.${GAME}.${userDirection}`)}`}
                    icon={<Prediction width={24} height={24} />}
                />
                {!isDraw && (
                    <TokenTellerGameHistoryInfoItem
                        withGap={false}
                        type={isUp ? 'upPayout' : 'downPayout'}
                        text={`${isUp ? upCoefficient : downCoefficient}X`}
                        icon={
                            isUp ? (
                                <UP width={24} height={24} />
                            ) : (
                                <DOWN width={24} height={24} />
                            )
                        }
                    />
                )}
            </View>
        </View>
    );
};
export default TokenTellerGameHistoryInfo;
