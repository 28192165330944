import * as React from 'react';
import { ReactElement } from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';

import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT } from '@components/TypoText/types';
import { isWeb } from '@helpers/app';
import { scale } from '@helpers/dimensions';
import useThemedStyles from '@hooks/useThemedStyles';

import stylesMain from './styles';

interface IProps {
    onPress: () => void;
    isLast?: boolean;
    getHeight?: (height: number) => void;
    minHeight?: number;
    title: string;
    text: string;
    icon: ReactElement<SvgProps>;
    isSelected?: boolean;
    containerStyle?: StyleProp<ViewStyle>;
}

const GameConfigCard = ({
    onPress,
    isLast,
    getHeight,
    minHeight,
    icon,
    title,
    text,
    isSelected,
    containerStyle,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const [width, setWidth] = React.useState<number>(0);

    return (
        <TouchableOpacity
            onLayout={({ nativeEvent }) => {
                getHeight?.(nativeEvent.layout.height);
                setWidth(nativeEvent.layout.width);
            }}
            activeOpacity={0.7}
            style={[
                styles.shadow,
                !isLast && styles.margin,
                { minHeight },
                isSelected && styles.shadowSelected,
                containerStyle,
            ]}
            onPress={onPress}>
            <View
                style={[
                    styles.container,
                    minHeight && minHeight > 150 && styles.flex,
                    isSelected && styles.containerSelected,
                ]}>
                <View style={styles.top}>
                    {icon}
                    <View style={styles.textWrapper}>
                        <TypoText
                            type={TYPO_TEXT.SUBHEAD_BIG_BOLD}
                            additionalStyles={[
                                styles.textGap,
                                isWeb && { maxWidth: width - scale(12) * 2 },
                            ]}
                            numberOfLines={1}>
                            {title}
                        </TypoText>
                        <TypoText
                            type={TYPO_TEXT.BODY_LITTLE}
                            numberOfLines={2}>
                            {text}
                        </TypoText>
                    </View>
                </View>
            </View>
        </TouchableOpacity>
    );
};
export default GameConfigCard;
