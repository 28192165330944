import React, { useCallback, useMemo } from 'react';

import _ from 'lodash';

import { GET_NFT_ROYAL_GAMES_HISTORY } from '@Data/Requests';
import { useApolloClient } from '@apollo/client';
import { DESKTOP_LOAD_LIMIT } from '@constants/API';
import { isDesktop } from '@helpers/app';
import { errorsHandler } from '@helpers/errors';
import { useLoadingHook } from '@hooks/useLoadingHook';
import {
    PaginatedLuckyStrikeGamesHistoryOutput,
    PaginatedLuckyStrikeGamesHistoryOutputResponse,
    PaginatedNftRoyaleGamesHistoryOutputResponse,
} from '@models/mysteryGames';
import { GET_LUCKY_STRIKE_GAMES_HISTORY } from '@requests/luckyStrike';

export const useLuckyStrikeGamesHistory = () => {
    const client = useApolloClient();

    const {
        isLoading: historyLoading,
        startLoading: startHistoryLoading,
        stopLoading: stopHistoryLoading,
    } = useLoadingHook(true);

    const {
        isLoading: refreshLoading,
        startLoading: startRefreshLoading,
        stopLoading: stopRefreshLoading,
    } = useLoadingHook();

    const {
        isLoading: loadMore,
        startLoading: startLoadMore,
        stopLoading: stopLoadMore,
    } = useLoadingHook();

    const [gamesHistory, setGamesHistory] =
        React.useState<PaginatedLuckyStrikeGamesHistoryOutput[]>();

    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);

    const getGamesHistory = useCallback(
        async (silent: boolean) => {
            try {
                silent ? startHistoryLoading() : startRefreshLoading();

                const result =
                    await client.query<PaginatedLuckyStrikeGamesHistoryOutputResponse>(
                        {
                            query: GET_LUCKY_STRIKE_GAMES_HISTORY,
                            fetchPolicy: 'network-only',
                            variables: {
                                paginationInput: {
                                    limit: isDesktop ? DESKTOP_LOAD_LIMIT : 10,
                                },
                            },
                        }
                    );

                if (result.data) {
                    setGamesHistory(result.data.luckyStrikeGamesHistory.items);

                    setCurrentPage(
                        result.data.luckyStrikeGamesHistory.meta.currentPage
                    );
                    setTotalPages(
                        result.data.luckyStrikeGamesHistory.meta.totalPages
                    );
                }
                stopRefreshLoading();
                stopHistoryLoading();
            } catch (error) {
                stopRefreshLoading();
                stopHistoryLoading();
                errorsHandler(error, true);
            }
        },
        [client]
    );

    const getGamesHistoryLoadMore = useCallback(async () => {
        try {
            if (currentPage === totalPages || totalPages === 0) {
                return;
            }
            startLoadMore();

            const result =
                await client.query<PaginatedLuckyStrikeGamesHistoryOutputResponse>(
                    {
                        query: GET_LUCKY_STRIKE_GAMES_HISTORY,
                        fetchPolicy: 'network-only',
                        variables: {
                            paginationInput: {
                                page: currentPage + 1,
                                limit: isDesktop ? DESKTOP_LOAD_LIMIT : 10,
                            },
                        },
                    }
                );

            if (result.data) {
                setGamesHistory((prev) => {
                    return prev
                        ? _.uniqBy(
                              [
                                  ...prev,
                                  ...result.data.luckyStrikeGamesHistory.items,
                              ],
                              (o) => o.id
                          )
                        : result.data.luckyStrikeGamesHistory.items;
                });

                setCurrentPage(
                    result.data.luckyStrikeGamesHistory.meta.currentPage
                );
                setTotalPages(
                    result.data.luckyStrikeGamesHistory.meta.totalPages
                );
            }
            stopLoadMore();
        } catch (error) {
            stopLoadMore();
            errorsHandler(error, true);
        }
    }, [client, totalPages, currentPage]);

    const canLoadMore = useMemo(
        () => currentPage !== totalPages,
        [currentPage, totalPages]
    );

    return {
        refreshLoading,
        historyLoading,
        loadMore,
        gamesHistory,
        getGamesHistory,
        getGamesHistoryLoadMore,
        canLoadMore,
    };
};
