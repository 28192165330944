/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';

import { usePlay } from '@contexts/PlayContext';
import { useUser } from '@contexts/UserContext';
import useStakingStartInfo from '@hooks/useStakingStartInfo';
import useYourPetsHungry from '@hooks/useYourPetsHungry';
import { useFocusEffect } from '@react-navigation/native';

export const usePlayScreen = () => {
    useYourPetsHungry();

    const { renderStakingModal } = useStakingStartInfo();
    const { refetchPets, playablePets, petsLoaded } = usePlay();
    const { reloadUser, userLoading } = useUser();

    const onRefresh = React.useCallback(() => {
        reloadUser();
        refetchPets(true);
    }, [reloadUser, refetchPets]);

    useFocusEffect(
        React.useCallback(() => {
            refetchPets(true);
        }, [])
    );

    return {
        petsLoaded,
        userLoading,
        onRefresh,
        playablePets,

        renderStakingModal,
    };
};
export default { usePlayScreen };
