import React from 'react';
import { SafeAreaView } from 'react-native';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import Carousel from '@components/Carousel';
import { ICON_NAMES } from '@components/Icons';
import StepOneImage from '@components/Icons/Fee/Fee';
import StepTwoImage from '@components/Icons/Fee/FeeTokens';
import { View } from '@components/Themed';
import { useDimensions } from '@contexts/DimensionsContext';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBarModal from '@navigation/NavigationBar/NavigationBarModal';
import { useNavigation } from '@navigation/useNavigation';

import stylesMain from '../styles/styles';

const data = ['stepOne', 'stepTwo'];

export const HowToFeeScreen = () => {
    const { windowWidth } = useDimensions();
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const renderItem = (item: string) => {
        const icon = () => {
            switch (item) {
                case 'stepOne':
                    return <StepOneImage size={175} />;
                case 'stepTwo':
                    return <StepTwoImage size={175} />;
            }
        };

        return (
            <View style={styles.itemContainer}>
                <View style={styles.content}>
                    <View style={styles.imgWrapper}>{icon()}</View>
                    <BlockHeader
                        title={i18n.t(`howTo.fee.${item}.title`)}
                        text={i18n.t(`howTo.fee.${item}.text`)}
                        type="emptyState"
                    />
                </View>
            </View>
        );
    };

    return (
        <View style={styles.main}>
            <View style={styles.header}>
                <NavigationBarModal
                    iconName={ICON_NAMES.CLOSE}
                    title=""
                    onBack={() => navigation.pop()}
                />
            </View>
            <Carousel
                data={data}
                sliderWidth={windowWidth}
                itemWidth={windowWidth}
                renderItem={(item) => renderItem(item)}
                fullHeight
                pagination
                centredVertical
            />
            <SafeAreaView />
        </View>
    );
};
