import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { SafeAreaView, ScrollView, TouchableOpacity } from 'react-native';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import { ICON_NAMES } from '@components/Icons';
import ModalBottom from '@components/ModalBottom';
import ConnectFacebookByUsername from '@components/SocialMedia/ConnectFacebookByUsername';
import ConnectInstagramByUsername from '@components/SocialMedia/ConnectInstagramByUsername';
import ConnectTikTok from '@components/SocialMedia/ConnectTikTok';
import ConnectTwitter from '@components/SocialMedia/ConnectTwitter';
import { Links } from '@constants/Links';
import { useConfig } from '@contexts/ConfigContext';
import { openUrlOrShowError } from '@helpers/linking';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    onSuccess?: () => void;
    onFail?: () => void;
}

const configModal = {
    socialModalHeight: '90%',
    tikTokModalHeight: '45%',
};

const ModalConnectSocials = ({
    isVisible,
    setIsVisible,
    onSuccess,
    onFail,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { config, isNewIosVersion } = useConfig();
    const [socialModalHeight, setSocialModalHeight] = React.useState<string>(
        configModal.socialModalHeight
    );

    const handleCloseModal = () => {
        setIsVisible(false);
    };

    const handleCompletedLinking = async (status: boolean) => {
        setIsVisible(false);

        if (status) {
            onSuccess?.();
        } else {
            onFail?.();
        }
    };
    const onPressKnowledgeBase = () => {
        if (config?.knowledgeBaseUrl) {
            openUrlOrShowError(
                config.knowledgeBaseUrl + Links.socializeToEarnRequirements
            );
        }
    };

    return (
        <ModalBottom
            isVisible={isVisible}
            modalHeight={socialModalHeight}
            onClose={handleCloseModal}
            changeHeightOnKeyboardVisible={false}
            isNestedModal>
            <ScrollView
                showsVerticalScrollIndicator={false}
                bounces={false}
                style={styles.container}>
                <TouchableOpacity activeOpacity={1}>
                    <BlockHeader
                        containerStyle={styles.header}
                        type="primary"
                        title={i18n.t(
                            'profile.socialAccountsSettings.headerTitle'
                        )}
                        text={i18n.t(
                            'profile.socialAccountsSettings.headerText'
                        )}
                        textInfo={
                            !isNewIosVersion
                                ? i18n.t(
                                      'profile.socialAccountsSettings.headerInfo'
                                  )
                                : ''
                        }
                        textInfoOnPress={onPressKnowledgeBase}
                        iconName={ICON_NAMES.EXPLORE}
                        iconSize={20}
                        iconDirection={'right'}
                    />
                    <ConnectInstagramByUsername
                        withArrow
                        swipeDirection={['down', 'right']}
                        animation={{
                            animationIn: 'slideInRight',
                            animationOut: 'slideOutRight',
                        }}
                        containerStyle={styles.gap}
                        setOuterModalHeightOnOpen={() => {
                            setSocialModalHeight(configModal.tikTokModalHeight);
                        }}
                        setOuterModalHeightOnClose={() => {
                            setSocialModalHeight(configModal.socialModalHeight);
                        }}
                        onCompletedLinking={handleCompletedLinking}
                    />
                    <ConnectFacebookByUsername
                        withArrow
                        containerStyle={styles.gap}
                        swipeDirection={['down', 'right']}
                        animation={{
                            animationIn: 'slideInRight',
                            animationOut: 'slideOutRight',
                        }}
                        setOuterModalHeightOnOpen={() => {
                            setSocialModalHeight(configModal.tikTokModalHeight);
                        }}
                        setOuterModalHeightOnClose={() => {
                            setSocialModalHeight(configModal.socialModalHeight);
                        }}
                        onCompletedLinking={handleCompletedLinking}
                    />
                    <ConnectTikTok
                        containerStyle={styles.gap}
                        withArrow
                        swipeDirection={['down', 'right']}
                        animation={{
                            animationIn: 'slideInRight',
                            animationOut: 'slideOutRight',
                        }}
                        setOuterModalHeightOnOpen={() => {
                            setSocialModalHeight(configModal.tikTokModalHeight);
                        }}
                        setOuterModalHeightOnClose={() => {
                            setSocialModalHeight(configModal.socialModalHeight);
                        }}
                        onCompletedLinking={handleCompletedLinking}
                        nestedModal
                    />
                    <ConnectTwitter
                        withArrow
                        swipeDirection={['down', 'right']}
                        animation={{
                            animationIn: 'slideInRight',
                            animationOut: 'slideOutRight',
                        }}
                        setOuterModalHeightOnOpen={() => {
                            setSocialModalHeight(configModal.tikTokModalHeight);
                        }}
                        setOuterModalHeightOnClose={() => {
                            setSocialModalHeight(configModal.socialModalHeight);
                        }}
                        onCompletedLinking={handleCompletedLinking}
                    />
                </TouchableOpacity>
                <SafeAreaView />
            </ScrollView>
        </ModalBottom>
    );
};

export default ModalConnectSocials;
