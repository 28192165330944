import React from 'react';
import { SafeAreaView } from 'react-native';

import StepOneImage from '@assets/icons/howTo/howToCommissionFee/stepOne.svg';
import StepTwoImage from '@assets/icons/howTo/howToCommissionFee/stepTwo.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import Carousel from '@components/Carousel';
import { ICON_NAMES } from '@components/Icons';
import { Text, View } from '@components/Themed';
import { useConfig } from '@contexts/ConfigContext';
import { useDimensions } from '@contexts/DimensionsContext';
import { P } from '@expo/html-elements';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBarModal from '@navigation/NavigationBar/NavigationBarModal';
import { useNavigation } from '@navigation/useNavigation';

import stylesMain from '../styles/styles';

const data = ['stepOne', 'stepTwo'];
const flow = 'howToCommissionFee';

export const HowToCommissionFeeScreen = () => {
    const { windowWidth } = useDimensions();
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const { config } = useConfig();

    const handleBack = () => {
        navigation.pop();
    };

    const renderItem = (item: string) => {
        const icon = () => {
            switch (item) {
                case 'stepOne':
                    return <StepOneImage />;
                case 'stepTwo':
                    return <StepTwoImage />;
            }
        };

        const component = () => {
            switch (item) {
                case 'stepOne':
                    return (
                        <BlockHeader
                            emptyStateIcon={icon()}
                            titleComponent={
                                <>
                                    <Text style={styles.accent}>{`${
                                        config?.iguWalletWithdrawFeePercentage ||
                                        0
                                    }%`}</Text>
                                    {i18n.t(`howTo.${flow}.${item}.title`)}
                                </>
                            }
                            text={i18n.t(`howTo.${flow}.${item}.text`, {
                                commission:
                                    config?.iguWalletWithdrawFeePercentage || 0,
                            })}
                            type="emptyState"
                        />
                    );

                default:
                    return (
                        <BlockHeader
                            emptyStateIcon={icon()}
                            title={i18n.t(`howTo.${flow}.${item}.title`)}
                            text={i18n.t(`howTo.${flow}.${item}.text`)}
                            type="emptyState"
                        />
                    );
            }
        };

        return (
            <View style={styles.itemContainer}>
                <View style={styles.content}>{component()}</View>
            </View>
        );
    };

    return (
        <View style={styles.main}>
            <View style={styles.header}>
                <NavigationBarModal
                    iconName={ICON_NAMES.CLOSE}
                    title={i18n.t(`howTo.${flow}.screenTitle`)}
                    onBack={handleBack}
                />
            </View>
            <Carousel
                data={data}
                sliderWidth={windowWidth}
                itemWidth={windowWidth}
                renderItem={(item) => renderItem(item)}
                fullHeight
                pagination
                centredVertical
            />
            <SafeAreaView />
        </View>
    );
};
