import React, { useCallback } from 'react';

import _ from 'lodash';

import { useApolloClient, useLazyQuery } from '@apollo/client';
import { errorsHandler } from '@helpers/errors';
import { useLoadingHook } from '@hooks/useLoadingHook';
import {
    TokentellerAvailableGameOutput,
    TokentellerAvailableGameOutputResponse,
    TokentellerPendingGameOutputResponse,
    TokentellerSingleAvailableGameOutputResponse,
} from '@models/mysteryGames';
import {
    GET_TOKEN_TELLER_AVAILABLE_GAME,
    GET_TOKEN_TELLER_AVAILABLE_GAMES,
    GET_TOKEN_TELLER_PENDING_GAMES,
} from '@requests/tokenTeller';

export const useTokenTellerAvailableGame = () => {
    const client = useApolloClient();

    const {
        isLoading: gameLoading,
        startLoading: startGameLoading,
        stopLoading: stopGameLoading,
    } = useLoadingHook();

    const [game, setGame] = React.useState<TokentellerAvailableGameOutput>();
    const [gameError, setGameError] = React.useState<boolean>(false);

    const [getPendingGames] =
        useLazyQuery<TokentellerPendingGameOutputResponse>(
            GET_TOKEN_TELLER_PENDING_GAMES,
            {
                fetchPolicy: 'no-cache',
            }
        );

    const updateGame = async (game: TokentellerAvailableGameOutput) => {
        const pendingGames = await getPendingGames();

        if (pendingGames.data?.tokentellerMyPendingGames.length) {
            const pendingGame =
                pendingGames.data.tokentellerMyPendingGames.find(
                    (i) => i.id === game.id
                );

            return !!pendingGame?.prediction
                ? {
                      ...game,
                      isPredicted: true,

                      predictedDirection: pendingGame.prediction.direction,

                      predictedAmountWei: pendingGame.prediction.iguAmountWei,
                  }
                : {
                      ...game,
                      isPredicted: false,
                      predictedDirection: null,
                      predictedAmountWei: null,
                  };
        }
        return game;
    };

    const getGame = useCallback(
        async (gameId: string) => {
            try {
                startGameLoading();

                const result =
                    await client.query<TokentellerSingleAvailableGameOutputResponse>(
                        {
                            query: GET_TOKEN_TELLER_AVAILABLE_GAME,
                            fetchPolicy: 'no-cache',
                            variables: {
                                gameId,
                            },
                        }
                    );

                if (result.data) {
                    const game = await updateGame(
                        result.data.tokentellerAvailableGame
                    );

                    setGame(game);
                    stopGameLoading();
                } else {
                    setGameError(true);
                    stopGameLoading();
                }
            } catch (error) {
                setGameError(true);
                stopGameLoading();
            }
        },
        [client, updateGame]
    );

    return {
        getGame,
        game,
        gameLoading,
        gameError,
    };
};
