import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        itemContainer: {
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: verticalScale(4),
        },
        left: {
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
        },
        right: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        place: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
            width: scale(24),
            textAlign: 'center',
        },
        image: {
            width: scale(40),
            height: scale(40),
            borderRadius: moderateScale(6),
        },
        imageWrapper: {
            marginLeft: scale(12),
            width: scale(40),
            height: scale(40),
            borderRadius: moderateScale(6),
        },
        address: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '600',
            }),
            marginLeft: scale(12),
            marginRight: scale(16),
            flex: 1,
        },
        energy: {
            color: Colors[theme].text.warning,
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),
            marginRight: scale(4),
        },
        list: {
            marginTop: verticalScale(12),
        },
        shadow: {
            borderRadius: moderateScale(12),
            backgroundColor: 'transparent',
            marginBottom: verticalScale(12),
        },
        active: {
            backgroundColor: Colors[theme].shadow.selected,
        },
        activeContainer: {
            borderColor: Colors[theme].border.accent,
        },
    });

export default styles;
