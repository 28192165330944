import * as React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import Icon from '@assets/icons/emptyState/completeVerification.svg';
import { isAndroid } from '@helpers/app';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import BlockHeader from '../BlockHeader/BlockHeader';
import ButtonsBottom from '../ButtonsBottom';
import ModalBottom from '../ModalBottom';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onPress: () => void;
    onClose: () => void;
    modalHeight?: number | string;
}

const ModalCompleteVerification = ({
    isVisible,
    onPress,
    onClose,
    modalHeight = 600,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <ModalBottom
            isVisible={isVisible}
            onClose={onClose}
            modalHeight={modalHeight}>
            <ScrollView
                style={styles.container}
                showsVerticalScrollIndicator={false}>
                <TouchableOpacity activeOpacity={1}>
                    <BlockHeader
                        type="emptyState"
                        title={i18n.t(
                            'profile.socialAccountsSettings.modal.title'
                        )}
                        text={i18n.t(
                            'profile.socialAccountsSettings.modal.text'
                        )}
                        emptyStateIcon={<Icon />}
                    />
                </TouchableOpacity>
            </ScrollView>
            <ButtonsBottom
                title={i18n.t('profile.socialAccountsSettings.modal.button')}
                onPress={onPress}
                safe={!isAndroid}
            />
        </ModalBottom>
    );
};

export default ModalCompleteVerification;
