import { StyleSheet } from 'react-native';

import { isDesktop, isWeb } from '@helpers/app';
import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
        },

        content: {
            flex: 1,
            marginTop: verticalScale(20),
        },
        header: {
            marginBottom: verticalScale(20),
            paddingHorizontal: scale(20),
        },
        columnWrapperStyle: {
            justifyContent: 'space-between',
            paddingHorizontal: scale(20),
        },
        search: {
            marginBottom: verticalScale(16),
        },
        emptyStateWrapper: {
            marginTop: verticalScale(24),
        },
        emptyStateWrapperSmall: {
            marginTop: verticalScale(24),
        },

        emptyList: {
            paddingBottom: isWeb ? 120 : 60,
        },
        footer: {
            height: isDesktop ? 120 : 60,
            backgroundColor: 'transparent',
        },
    });

export default styles;
