import * as React from 'react';
import { useState } from 'react';
import { ScrollView } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import AlertCard from '@components/AlertCard';
import CustomModal from '@components/CustomModal';
import { ICON_NAMES } from '@components/Icons';
import ImageIguana from '@components/ImageIguana';
import { View } from '@components/Themed';
import { PlayContext } from '@contexts/PlayContext';
import { useHandleNavigationBack } from '@hooks/useHandleNavigationBack';
import NavigationBar from '@navigation/NavigationBar';
import { useNavigation } from '@navigation/useNavigation';

import UnhealedPetsIcon from '../../../../assets/icons/health/unhealedPets.svg';
import BlockHeader from '../../../../components/BlockHeader/BlockHeader';
import HealthPetCollectionListItem from '../../../../components/HealthPetCollectionListItem';
import usePetsHeal from '../../../../hooks/usePetsHeal';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import ROUTES from '../../../../navigation/routes';
import { CollectionMultiplePetsHealUpProceedingProps } from '../../../../types';
import stylesMain from './styles';

const CollectionMultiplePetsHealProceedingScreen = ({
    route,
}: CollectionMultiplePetsHealUpProceedingProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const { refetchPets } = React.useContext(PlayContext);
    const { pets, paymentOption } = route.params;
    const [isModalVisible, setModalVisible] = useState<boolean>(false);

    const {
        transactions,
        retryFailedTransactions,
        healAllPets,
        completed,
        error,
    } = usePetsHeal(pets, paymentOption);

    navigation.blockHardwareBack();
    useHandleNavigationBack({
        setModalVisible,
        data: true,
        autoSkip: completed,
    });

    React.useEffect(() => {
        healAllPets();
    }, []);

    React.useEffect(() => {
        if (completed) {
            refetchPets(true);
            navigation.navigate(
                ROUTES.COLLECTION_MULTIPLE_PETS_HEAL_UP_SUCCESS,
                {
                    results: transactions,
                    payment: paymentOption,
                }
            );
        }
    }, [completed]);

    const goBack = () => {
        navigation.goBack();
    };

    return (
        <SafeAreaView edges={['bottom']} style={styles.safe}>
            <NavigationBar
                backIcon={ICON_NAMES.CLOSE}
                title=""
                filled={false}
            />
            <ScrollView
                style={styles.main}
                contentContainerStyle={styles.scrollView}
                showsVerticalScrollIndicator={false}>
                <View style={styles.container}>
                    <BlockHeader
                        type="emptyState"
                        title={i18n.t(
                            'detailedPet.health.proceedingMultipleHealUp.title'
                        )}
                        text={i18n.t(
                            'detailedPet.health.proceedingMultipleHealUp.text'
                        )}
                        emptyStateIcon={<UnhealedPetsIcon />}
                    />
                    <View style={styles.body}>
                        {transactions.map((item) => (
                            <HealthPetCollectionListItem
                                key={item.pet.id}
                                data={item}
                                style={styles.petItem}
                            />
                        ))}
                    </View>
                    {error && (
                        <View style={styles.errorToast}>
                            <AlertCard
                                text={i18n.t(
                                    'detailedPet.health.proceedingMultipleHealUp.error'
                                )}
                                type="error"
                                actionButtonIcon={ICON_NAMES.RESTORE}
                                onAction={retryFailedTransactions}
                            />
                        </View>
                    )}
                </View>
                <CustomModal
                    isVisible={isModalVisible}
                    icon={<ImageIguana type="cry" />}
                    titleText={i18n.t('detailedPet.health.healAllLeave.title')}
                    infoText={i18n.t('detailedPet.health.healAllLeave.text')}
                    firstButtonText={i18n.t(
                        'detailedPet.health.healAllLeave.keep'
                    )}
                    secondButtonText={i18n.t(
                        'detailedPet.health.healAllLeave.leave'
                    )}
                    onFirstButtonClick={() => setModalVisible(false)}
                    onSecondButtonClick={goBack}
                />
            </ScrollView>
        </SafeAreaView>
    );
};

export default CollectionMultiplePetsHealProceedingScreen;
