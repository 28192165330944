import * as React from 'react';
import { useCallback, useMemo } from 'react';

import InfoIcon from '@components/InfoIcon/InfoIcon';
import { usePlay } from '@contexts/PlayContext';
import { waitForModal } from '@helpers/helpers';
import { useVisibleHook } from '@hooks/useVisibleHook';
import { useNavigation } from '@navigation/useNavigation';

import HowToClaimFreePetModal from '../components/HowToClaimFreePetModal';
import HowToClaimOnButtonModal from '../components/HowToClaimOnButtonModal';

export const useHowToClaim = () => {
    const { pets, isFreePets } = usePlay();
    const {
        isVisible: isVisibleClaimOnButton,
        open: openClaimOnButton,
        close: closeClaimOnButton,
    } = useVisibleHook();
    const {
        isVisible: isVisibleClaimFreePet,
        open: openClaimFreePet,
        close: closeClaimFreePet,
    } = useVisibleHook();

    const isOnlyTransferred = useMemo(() => {
        if (!pets?.length) {
            return false;
        }
        const noFree = pets?.filter((i) => i.rank !== 'free');

        if (noFree?.length) {
            return !noFree.filter(
                (i) => i.currentOwnerAddress === i.creatorAddress
            ).length;
        }
        return true;
    }, [pets]);

    const disabledFreePets = useMemo(
        () => isFreePets || isOnlyTransferred,
        [isFreePets, isOnlyTransferred]
    );

    const navigation = useNavigation();

    const onPressFreePet = () => {
        closeClaimFreePet();
        navigation.navigateToMint();
    };

    const renderClaimInfoFreePet = useCallback(() => {
        return (
            <>
                {disabledFreePets && <InfoIcon onPress={openClaimFreePet} />}
                <HowToClaimFreePetModal
                    isVisible={isVisibleClaimFreePet}
                    onClose={closeClaimFreePet}
                    onPress={onPressFreePet}
                />
            </>
        );
    }, [isVisibleClaimFreePet, disabledFreePets]);

    const renderClaimInfoOnButton = useCallback(
        (action: () => void, disabled: boolean) => {
            const onClaim = async () => {
                closeClaimOnButton();
                await waitForModal();
                action();
            };

            return (
                <>
                    {!disabled && <InfoIcon onPress={openClaimOnButton} />}
                    <HowToClaimOnButtonModal
                        disabled={disabled}
                        isVisible={isVisibleClaimOnButton}
                        onPress={onClaim}
                        onClose={closeClaimOnButton}
                    />
                </>
            );
        },
        [isVisibleClaimOnButton]
    );

    return {
        renderClaimInfoFreePet,
        disabledFreePets,
        renderClaimInfoOnButton,
        openClaimOnButton,
        openClaimFreePet,
    };
};
