import * as React from 'react';
import { FlatList } from 'react-native';

import { UserRole } from '@Data/Models';
import ModalBottom from '@components/ModalBottom';
import SelectorItem from '@components/SelectorItem';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    selectedRole: UserRole | undefined;
    roles: UserRole[];
    onPress: (role: UserRole) => void;
    modalHeight?: number | string;
    close: () => void;
}

const RoleSelectModal = ({
    isVisible,
    onPress,
    selectedRole,
    roles,
    modalHeight = '70%',
    close,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const handleSelect = React.useCallback(
        (role: UserRole) => {
            if (selectedRole === role) {
                return;
            }
            onPress(role);
            close();
        },
        [selectedRole]
    );

    return (
        <>
            {!!roles && (
                <ModalBottom
                    isVisible={isVisible}
                    onClose={close}
                    modalHeight={modalHeight}
                    titleText={i18n.t(
                        'profile.adminAssignRole.roleModal.title'
                    )}>
                    <FlatList
                        showsVerticalScrollIndicator={false}
                        style={styles.listStyle}
                        data={roles}
                        renderItem={({ item }) => (
                            <SelectorItem
                                isSelected={item === selectedRole}
                                text={item}
                                handleSelect={() => handleSelect(item)}
                            />
                        )}
                        keyExtractor={(item) => item}
                    />
                </ModalBottom>
            )}
        </>
    );
};

export default RoleSelectModal;
