import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        gap: {
            marginTop: verticalScale(12),
            backgroundColor: 'transparent',
        },
    });

export default styles;
