import * as React from 'react';
import { FlatList, Image, Pressable } from 'react-native';

import { BackgroundImage, Rank } from '../../../../Data/Models';
import Carousel from '../../../../components/Carousel';
import { View } from '../../../../components/Themed';
import { useDimensions } from '../../../../contexts/DimensionsContext';
import { isDesktop } from '../../../../helpers/app';
import { scale } from '../../../../helpers/dimensions';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import DisabledBg from './DisabledBg';
import stylesMain from './styles';

interface IProps {
    initialBackground: BackgroundImage | null;
    backgrounds: BackgroundImage[];
    handleBg: (id: string) => void;
    currentRank: Rank;
    handleUnlock: (bgRanks: string[]) => void;
}

const ITEM_PADDING = 24;
const IMAGE_SIZE = 64;
const ITEM_GAP = 12;

const BackgroundPicker = ({
    initialBackground,
    backgrounds,
    handleBg,
    currentRank,
    handleUnlock,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const [currentBgId, setCurrentBgId] = React.useState<string | undefined>();
    const [isScroll, setIsScroll] = React.useState(false);
    const [isPressIn, setIsPressIn] = React.useState(false);
    const { windowWidth } = useDimensions();

    React.useEffect(() => {
        if (initialBackground) {
            handleItemOnClick(initialBackground.id);
        }
    }, [initialBackground?.id]);

    const initialScrollIndex = React.useMemo(
        () => backgrounds.findIndex((val) => val.id === currentBgId),
        [currentBgId, backgrounds]
    );

    const itemLayout = scale(IMAGE_SIZE + ITEM_PADDING + ITEM_GAP);
    const itemLayoutDisable = scale(itemLayout - ITEM_GAP + 2);

    const renderItem = (item: BackgroundImage) => {
        const isDisabled = item.ranks.find((id) => id === currentRank.slug);

        return (
            <Pressable
                key={item.id}
                disabled={!isDisabled}
                onPressIn={() => setIsPressIn(true)}
                style={[
                    styles.shadow,
                    // styles.itemWrapper,
                    // currentBgId === item.id && styles.active,
                    currentBgId === item.id && styles.activeShadow,
                ]}
                onPress={() => handleItemOnClick(item.id)}>
                <View
                    style={[
                        styles.itemWrapper,
                        currentBgId === item.id && styles.active,
                    ]}>
                    <Image style={styles.image} source={{ uri: item.icon }} />
                    {!isDisabled && (
                        <DisabledBg
                            width={itemLayoutDisable}
                            height={itemLayoutDisable}
                            handleOnPress={() => handleUnlock(item.ranks)}
                        />
                    )}
                </View>
            </Pressable>
        );
    };

    const handleItemOnClick = (id: string) => {
        if (!isScroll) {
            setCurrentBgId(id);
            handleBg(id);
        }
        setIsScroll(false);
        setIsPressIn(false);
    };

    return (
        <View style={styles.carousel}>
            {isDesktop ? (
                <Carousel
                    data={[...backgrounds]}
                    renderItem={(item) => renderItem(item)}
                    itemWidth={103}
                    height={95}
                    sliderWidth={windowWidth - 2 * scale(20)}
                    pagingEnabled={false}
                    snapEnabled={false}
                    windowSize={10}
                    panGestureProps={{
                        onBegan: () => isPressIn && setIsScroll(true),
                    }}
                    carouselStyle={{ justifyContent: 'flex-start' }}
                />
            ) : (
                backgrounds.length > 0 && (
                    <FlatList
                        getItemLayout={(data, index) => ({
                            length: itemLayout,
                            offset: itemLayout * index,
                            index,
                        })}
                        pagingEnabled={false}
                        initialScrollIndex={initialScrollIndex}
                        initialNumToRender={10}
                        horizontal
                        showsHorizontalScrollIndicator={false}
                        style={styles.listStyle}
                        data={[...backgrounds]}
                        renderItem={({ item }) => renderItem(item)}
                    />
                )
            )}
        </View>
    );
};
export default BackgroundPicker;
