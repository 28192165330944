import { IconColorType } from '../../constants/Colors';

export interface IconType {
    color?: IconColorType | string;
    size?: number | string;
}

export const iconSize = {
    small: 16,
    default: 20,
    primary: 24,
};
