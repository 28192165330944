import React, { useCallback, useLayoutEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native-gesture-handler';

import CustomModal from '@components/CustomModal';
import ImageIguana from '@components/ImageIguana';
import Switch from '@components/Switch';
import { Text, View } from '@components/Themed';
import { waitForNavigation } from '@helpers/helpers';
import { toastSuccess } from '@helpers/toastNotification';
import { useLocalAuthentication } from '@hooks/useLocalAuthentication';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import stylesMain from './styles';

export const PassCodeSettingsScreen = () => {
    const {
        isVisible: turnOffModal,
        open: openTurnOffModal,
        close: closeTurnOffModal,
    } = useVisibleHook();

    const {
        disablePassCode,
        checkBiometricEnabled,
        disableBiometric,
        handleEnableBiometric,
        checkBiometricAvailability,
    } = useLocalAuthentication();

    const [biometricEnabled, setBiometricEnabled] = useState<boolean>(false);
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const [isBiometricAvailable, setIsBiometricAvailable] =
        useState<boolean>(false);

    useLayoutEffect(() => {
        const check = async () => {
            setIsBiometricAvailable(await checkBiometricAvailability());
            setBiometricEnabled(await checkBiometricEnabled());
        };
        check();
    }, []);

    const handleTurnOff = async () => {
        closeTurnOffModal();
        await disablePassCode();
        navigation.pop();
        await waitForNavigation();
        toastSuccess(i18n.t('passcode.toasts.turnOff'), undefined, 'top');
    };

    const handleChangePassCode = () => {
        navigation.navigate(ROUTES.PASSCODE_CHANGE);
    };

    const handleTurnedOn = () => {
        toastSuccess(i18n.t('passcode.toasts.biometricOn'), undefined, 'top');
        setBiometricEnabled(true);
    };

    const handleSwitchChange = useCallback(async () => {
        if (biometricEnabled) {
            await disableBiometric();
            setBiometricEnabled(false);
            toastSuccess(
                i18n.t('passcode.toasts.biometricOff'),
                undefined,
                'top'
            );
        } else {
            await handleEnableBiometric(handleTurnedOn);
        }
    }, [biometricEnabled]);

    return (
        <View style={styles.flex}>
            <NavigationBar
                filled={false}
                backIcon
                title={i18n.t('passcode.passcode')}
            />

            <View style={styles.container}>
                <TouchableOpacity
                    style={[styles.button, styles.bottomBorder]}
                    onPress={handleChangePassCode}>
                    <Text style={styles.text}>
                        {i18n.t('passcode.change.title')}
                    </Text>
                </TouchableOpacity>
                <TouchableOpacity
                    style={[
                        styles.button,
                        isBiometricAvailable && styles.bottomBorder,
                    ]}
                    onPress={openTurnOffModal}>
                    <Text style={styles.text}>
                        {i18n.t('passcode.settings.turnOff')}
                    </Text>
                </TouchableOpacity>

                {isBiometricAvailable ? (
                    <Switch
                        withBorder={false}
                        id={'faceId'}
                        title={i18n.t('passcode.settings.biometric.title')}
                        description={i18n.t(
                            'passcode.settings.biometric.subTitle'
                        )}
                        withPadding={false}
                        isActive={biometricEnabled}
                        onChange={handleSwitchChange}
                    />
                ) : null}
            </View>

            <CustomModal
                isVisible={turnOffModal}
                onFirstButtonClick={handleTurnOff}
                onSecondButtonClick={closeTurnOffModal}
                icon={<ImageIguana type="attention" />}
                titleText={i18n.t('passcode.modalRemove.title')}
                infoText={i18n.t('passcode.modalRemove.subTitle')}
                firstButtonText={i18n.t('general.BUTTONS.turnOff')}
                secondButtonText={i18n.t('general.BUTTONS.cancel')}
            />
        </View>
    );
};
