import {
    CreateStakingResponse,
    StakingBack,
    StakingPlanOutput,
    UserStake,
} from '@Data/Models';
import { CREATE_STAKING } from '@Data/Requests';
import { useMutation } from '@apollo/client';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { toastErrorUnknown } from '@helpers/toastNotification';
import { valueToWei } from '@helpers/wallet';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { StackActions } from '@react-navigation/native';

import useBlockchainErrorsHandler from './useBlockchainErrorsHandler';
import { useLoadingHook } from './useLoadingHook';

export const useStakingHook = () => {
    const { isLoading, startLoading, stopLoading } = useLoadingHook();
    const navigation = useNavigation();
    const { unstakeIGUP, stakeIGUP } = useWallet();
    const [request] = useMutation<CreateStakingResponse>(CREATE_STAKING);
    const { handleBlockchainErrors, renderBlockchainErrorsModal } =
        useBlockchainErrorsHandler();

    const onStakePress = async (
        currentPlan: StakingPlanOutput,
        stakingAmount: string
    ) => {
        if (!currentPlan || !stakingAmount) {
            toastErrorUnknown();
            return;
        }
        startLoading();
        try {
            const staking = await request({
                variables: {
                    stakingPlanId: currentPlan.plan.id,
                    igupAmountWei: valueToWei(stakingAmount),
                },
            });

            if (!staking.data) {
                stopLoading();
                return;
            }
            const transactionResult = await stakeIGUP(
                staking.data.createStaking.payment,
                staking.data.createStaking.signedTransaction
            );
            stopLoading();
            navigation.popToTop();
            navigation.replace(ROUTES.STAKING_SUCCESS, {
                transactionResult,
            });
        } catch (error) {
            stopLoading();
            if (!handleBlockchainErrors(error)) {
                toastErrorUnknown();
            }
        }
    };

    const handleUnStake = async (currentStakingPlan: UserStake | null) => {
        if (!currentStakingPlan) {
            toastErrorUnknown();
            return;
        }
        startLoading();
        try {
            const transactionResult = await unstakeIGUP();
            stopLoading();
            navigation.dispatch(StackActions.popToTop());
            navigation.dispatch(
                StackActions.replace(ROUTES.UN_STAKING_SUCCESS, {
                    transactionResult,
                    currentStakingPlan,
                })
            );
        } catch (error) {
            Console.log(error);
            stopLoading();
            if (!handleBlockchainErrors(error)) {
                toastErrorUnknown();
            }
        }
    };

    const handleOnBack = (routeParams: StakingBack) => {
        if (routeParams) {
            const { navigateBack, params } = routeParams;
            navigation.pop();
            navigation.navigate(navigateBack, params);
        } else {
            navigation.goBack();
        }
    };

    return {
        isLoading,
        handleUnStake,
        renderBlockchainErrorsModal,
        onStakePress,
        handleOnBack,
    };
};
