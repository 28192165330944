import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        shadow: {
            backgroundColor: Colors[theme].shadow.selected,
            borderRadius: 1000,
            marginRight: scale(6),
            alignSelf: 'flex-start',
        },
        isLast: {
            marginRight: 0,
        },
        container: {
            borderRadius: 1000,
            borderWidth: 1,
            borderColor: Colors[theme].border.accent,
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(6),
            marginBottom: 2,
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: Colors[theme].backgroundSelectable,
        },

        title: {
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
            color: Colors[theme].text.primary,
            marginRight: scale(6),
        },
        icon: {
            color: Colors[theme].icon.accent,
        },
    });

export default styles;
