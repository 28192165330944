import * as React from 'react';
import { KeyboardAvoidingView, ScrollView } from 'react-native';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import Input from '@components/Input';
import { View } from '@components/Themed';
import { isIOS } from '@helpers/app';
import { getSocialUsername } from '@helpers/getSocialLinks';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';

import CheckProfileDetails from '../components/CheckProfileDetails';
import { useSocialSelect } from '../hooks/useSocialSelect';
import stylesMain from './styles';

const AdminReverificationScreen = () => {
    const styles = useThemedStyles(stylesMain);

    const [inputValue, setInputValue] = React.useState<string>('');

    const { socialValue, renderSocialSelect, resetSocialSelect } =
        useSocialSelect();

    const onChange = React.useCallback(
        (value: string) => {
            setInputValue(getSocialUsername(value, inputValue));
        },
        [inputValue]
    );

    const { isVisible, close, open } = useVisibleHook();

    const reset = () => {
        setInputValue('');
        resetSocialSelect();
    };

    return (
        <KeyboardAvoidingView
            behavior={isIOS ? 'padding' : 'height'}
            style={styles.mainContainer}>
            <NavigationBar backIcon filled={false} />

            <ScrollView
                keyboardShouldPersistTaps="handled"
                style={styles.content}
                showsVerticalScrollIndicator={false}>
                <BlockHeader
                    containerStyle={styles.paddingHorizontal}
                    type="primary"
                    title={i18n.t('profile.adminReverification.title')}
                    text={i18n.t('profile.adminReverification.text')}
                />
                <View style={styles.inputWrapper}>{renderSocialSelect()}</View>
                <View style={styles.inputWrapper}>
                    <Input
                        value={inputValue}
                        onChangeText={onChange}
                        placeholder={i18n.t(
                            'profile.adminReverification.placeHolder'
                        )}
                        label={i18n.t('profile.adminReverification.labelInput')}
                    />
                </View>
            </ScrollView>
            <ButtonsBottom
                containerStyle={styles.buttons}
                title={i18n.t('profile.adminReverification.button')}
                buttonType="critical"
                onPress={open}
                disabled={!inputValue}
            />
            {!!inputValue && (
                <CheckProfileDetails
                    visible={isVisible}
                    onClose={close}
                    selectedSocial={socialValue}
                    selectedUsername={inputValue}
                    reset={reset}
                />
            )}
        </KeyboardAvoidingView>
    );
};

export default AdminReverificationScreen;
