import * as React from 'react';
import { ScrollView } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import Button from '@components/Button';
import LanguageSelector from '@components/LanguageSelector';
import { Text, View } from '@components/Themed';
import { isIOS, isRunningInExpo } from '@helpers/app';
import { wait } from '@helpers/helpers';
import { useLoadingHook } from '@hooks/useLoadingHook';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import Agreement from './components/Agreement';
import LoginWithApple from './components/LoginWithApple';
import LoginWithEmail from './components/LoginWithEmail';
import LoginWithFacebook from './components/LoginWithFacebook';
import LoginWithGoogle from './components/LoginWithGoogle';
import Welcome from './components/Welcome';
import style from './styles';

const LoginScreen: React.FunctionComponent = () => {
    const styles = useThemedStyles(style);
    const [showEmail, setShowEmail] = React.useState<boolean>(false);
    const toggleEmail = (state: boolean) => setShowEmail(state);
    const { top, bottom } = useSafeAreaInsets();

    const renderSeparator = () => {
        return (
            <View style={styles.wrapper}>
                <View style={styles.line} />
                <View>
                    <Text style={[styles.text, styles.orText]}>
                        {i18n.t('general.or')}
                    </Text>
                </View>
                <View style={styles.line} />
            </View>
        );
    };

    const { isLoading, startLoading, stopLoading } = useLoadingHook();

    const onLangChange = async () => {
        startLoading();
        await wait(1000);
        stopLoading();
    };

    const renderContent = React.useCallback(() => {
        return (
            <View
                style={{
                    ...styles.page,
                    paddingTop: top,
                    paddingBottom: bottom,
                }}>
                <Welcome />

                <View style={styles.buttonWrapper}>
                    <LoginWithGoogle />
                    {isIOS && <LoginWithApple />}
                    <LoginWithFacebook />
                    {renderSeparator()}
                    <Button
                        onPress={() => toggleEmail(true)}
                        title={i18n.t('login.email')}
                    />
                    <Agreement />
                    <LanguageSelector
                        containerStyle={styles.languages}
                        onChange={async () => onLangChange()}
                        isLoading={isLoading}
                    />
                </View>
            </View>
        );
    }, [isLoading]);

    return (
        <>
            <ScrollView
                style={styles.container}
                showsVerticalScrollIndicator={false}>
                {renderContent()}
            </ScrollView>

            <LoginWithEmail showEmail={showEmail} toggleEmail={toggleEmail} />
        </>
    );
};

export default LoginScreen;
