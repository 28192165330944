// eslint-disable-next-line import/extensions
import basicFile from './index.js';

const en = {
    ...basicFile,
    title: {
        suffix: 'IguVerse',
        /**
         * BottomTab
         */
        Main: 'Main',
        Play: 'Play',
        Charity: 'Charity',
        Collection: 'Collection',
        Marketplace: 'Marketplace',
        Home: 'Home',

        //MysteryLikes
        HowMysteryLikesWork: 'The Mystery Of Likes - How it works',
        WelcomeHowMysteryLikesWork: 'The Mystery Of Likes - Welcome',
        MysteryLikesMain: 'The Mystery Of Likes - Main',
        MysteryLikesActiveOrder: 'The Mystery Of Likes - Active Orders',
        MysteryLikesHistory: 'The Mystery Of Likes - Games History',
        MysteryLikesCreateGameWelcome: 'The Mystery Of Likes - Create Welcome',
        MysteryLikesCreateGameInput: 'The Mystery Of Likes - Create',
        MysteryLikesCreateGameSuccess: 'The Mystery Of Likes - Success',
        MysteryLikesSelectRange: 'The Mystery Of Likes - Select Range',
        MysteryLikesSelectRangeFinish:
            'The Mystery Of Likes - Game Information',

        // NFT EVOLUTION
        HowNftEvolutionWelcome: 'NFT Evolution - How it works',
        NftEvolutionWelcome: 'NFT Evolution - Welcome',
        NftEvolutionSelectPets: 'NFT Evolution',
        NftEvolutionCheckout: 'NFT Evolution - Chances',
        NftEvolutionSuccess: 'NFT Evolution - Success',
        NftEvolutionSelectPet: 'NFT Evolution',
        NftEvolutionMintPet: 'NFT Evolution - Mint',
        NftEvolutionMintPetSuccess: 'NFT Evolution - Success',

        //NftRoyal
        HowNftRoyalWork: 'The Grand Race - How it works',
        WelcomeNftRoyalWork: 'The Grand Race - Welcome',
        NftRoyalMain: 'The Grand Race - Main',
        NftRoyalActiveOrder: 'The Grand Race - Vacant Rooms',
        NftRoyalCreateGameWelcome: 'The Grand Race - Create',
        NftRoyalGameConfigurations: 'The Grand Race - Game Settings',
        NftRoyalEnterCoinsAmount: 'The Grand Race - Select Coins Amount',
        NftRoyalCreateGameSuccess: 'The Grand Race - Create Game Success',
        NftRoyalHistory: 'The Grand Race - History',
        NftRoyalGameHistory: 'The Grand Race - Game History',
        NftRoyalPlayGame: 'The Grand Race',

        /**
         * Admin
         */
        AdminRoot: 'Admin',
        AdminVerify: 'Admin - Verify',
        AdminSendPushNotification: 'Admin - Send Push Notification',
        AdminReverification: 'Admin - Reverification',
        AdminBlockUser: 'Admin - Block User',
        AdminMaintenanceMode: 'Admin - Maintenance Mode',
        AdminAssignWhiteList: 'Admin - Assign Whitelist',
        AdminAssignRole: 'Admin - Assign Role',
        AdminNftRoyaleRevokeBan: 'Admin - Nft Royale Revoke Ban',
        /**
         * Mint
         */
        MintRoot: 'Mint',
        MintPetRanks: 'Mint Choose Ranks',
        MintPetSelectGeneration: 'Mint Select Method',
        MintGenerationInfo: 'Mint Info',
        MintSelectGeneratedNft: 'Mint Select NFT',
        MintPetPickPhoto: 'Mint Pick Photo',
        MintPetEditPhoto: 'Mint Edit Photo',
        MintPetName: 'Mint Choose Pet Name',
        MintPetCheckout: 'Mint Checkout',
        MintPetCheckoutSuccess: 'Mint Checkout Success',

        /**
         * CreateWallet
         */
        AddWalletInitial: 'Add Wallet',
        ImportWallet: 'Import Wallet',
        CreateWalletFirst: 'Create Wallet',
        CreateWalletPassphrase: 'Create Wallet Seed',
        CreateWalletVerification: 'Verify Seed',
        CreateWalletSuccess: 'Wallet Success',
        /**
         * LOGIN
         */
        Login: 'Login',
        /**
         * Wallet
         */
        WalletRoot: 'Wallet',
        // Tabs
        WalletCoinsMain: 'Wallet - Coins',
        WalletNFTSMain: 'Wallet - NFTs',
        WalletRewardsMain: 'Wallet - Rewards',
        //Gaming
        WalletGamingTab: 'Wallet - Gaming',
        WalletGamingMain: 'Wallet - Gaming',
        TopUpGamingWallet: 'Wallet - TopUp',
        WithdrawInputAmount: 'Wallet - Withdraw',
        TopUpCheckoutScreen: 'Wallet - TopUp Checkout',
        TopUpSuccessScreen: 'Wallet - TopUp Checkout Success',
        WithdrawIguSuccessScreen: 'Wallet - Withdraw Success',
        GamingWalletTransactionDetails: 'Wallet -  Transaction Details',
        // Coins
        WalletCoinsBalance: 'Wallet - Coin Balance',
        WalletCoinsWithdraw: 'Wallet - Withdraw',
        WalletCoinsWithdrawSuccess: 'Wallet - Withdraw Success',
        WalletCoinsTransactionDetails: 'Wallet - Transaction Details',
        // NFT
        WalletNFTSList: 'Wallet - NFTs List',
        WalletNFTSWithdraw: 'Wallet - NFTs Withdraw',
        WalletNFTSWithdrawSuccess: 'Wallet - NFTs Withdraw Success',
        WalletNFTSTransactionDetails: 'Wallet - NFTs Transaction Details',
        // Rewards
        WalletRewardsClaimSuccess: 'Wallet - Rewards Claim Success',
        WalletRewardsTransactionDetails: 'Wallet - Rewards Transaction Details',
        WalletRewardsTab: 'Wallet - Rewards',
        // Trade
        WalletTradeMain: 'Wallet - Trade Main',
        WalletTradeChart: 'Wallet - Trade Chart',
        WalletTradeSuccess: 'Wallet - Trade Success',
        // Settings
        WalletSettings: 'Wallet - Settings',
        WalletSettingsBackup: 'Wallet - Settings Backup',
        // Staking
        StakingWelcome: 'Wallet - Boost Welcome',
        StakingOptions: 'Wallet - Boost Options',
        StakingPlans: 'Wallet - Boost Plans',
        StakingDetailedInfo: 'Wallet - Boost Detailed Info',
        StakingCoinsInput: 'Wallet - Boost Coins Input',
        StakingDailyBoost: 'Wallet - Boost Daily Boost',
        StakingSuccess: 'Wallet - Boost Success',
        StakingAlready: 'Wallet - Boost Already',

        UnStakingSuccess: 'Wallet - UnBoost Success',

        /**
         * Play
         */
        PlayTasks: 'Tasks',
        PlayTasksActive: 'Tasks Active',
        PlayTasksHistory: 'Tasks History',
        PlayEditNFTs: 'Edit NFTs',
        PlayTasksHistoryDetails: 'Tasks History Details',
        PlayCollectedExperience: 'Collected Experience',
        PlaySuccessScreen: 'Task Success',
        /**
         * Socialize To Earn
         */
        SocializeToEarnWelcomeShare: 'SocializeToEarn - Welcome',
        SocializeToEarnShareYourPhoto: 'SocializeToEarn - Share Your Photo',
        SocializeToEarnCreateSharePhoto: 'SocializeToEarn - Create Share Photo',
        SocializeToEarnVerifyUser: 'SocializeToEarn - Verify User',
        SocializeToEarnCreateShareTemplate:
            'SocializeToEarn - Share with Template',

        SocializeToEarnVerifyUserError: 'SocializeToEarn - Verify User Error',
        SocializeToEarnShareYourPhotoSuccess:
            'SocializeToEarn - Share Your Photo Success',
        SocializeToEarnWelcomeVerify: 'SocializeToEarn - Welcome Verify',
        SocializeToEarnVerifyUserMatch: 'SocializeToEarn - Verify User Match',
        SocializeToEarnVerifyUserNotFound:
            'SocializeToEarnVerify - User Not Found',
        SocializeToEarnVerifyUserFailed: 'SocializeToEarn - Verify User Failed',
        SocializeToEarnVerifyUserAlreadyCompleted:
            'SocializeToEarn - Verify User Already Completed',
        /**
         * Move To Earn
         */
        MoveToEarnWelcome: 'MoveToEarn - Welcome',
        MoveToEarnProgress: 'MoveToEarn - Progress',
        MoveToEarnSummary: 'MoveToEarn - Summary',
        /**
         * Play to Earn
         */
        PlayToEarn: 'PlayToEarn',
        PlayToEarnWelcome: 'PlayToEarn - Welcome',
        PlayToEarnProgress: 'PlayToEarn - Play',
        /**
         * Charity
         */
        CharityOrganizations: 'Charity - Organizations',
        CharityDetailedOrganization: 'Charity - Detailed Organization',
        CharityOrganizationDonations: 'Charity - Organization Donations',
        CharityEditSelectedOrganization: 'Charity - Edit Selected Organization',
        CharityEditDetailedOrganization: 'Charity - Edit Detailed Organization',
        /**
         * Collection
         */
        CollectionMain: 'Collection',
        CollectionDetailedPet: 'Collection - My Pet',
        CollectionPetLevelUpCheckout: 'Collection - Pet Level Up Checkout',
        CollectionPetLevelUpSuccess: 'Collection - Pet Level Up Success',
        CollectionPetRankUpCheckout: 'Collection - Pet Rank Up Checkout',
        CollectionPetRankUpSuccess: 'Collection - Pet Rank Up Success',
        CollectionPetHealUpCheckout: 'Collection - Pet Rank Up Checkout',
        CollectionPetHealUpSuccess: 'Collection - Pet Heal Up Success',
        CollectionPetReviveCheckout: 'Collection - Pet Revive Checkout',
        CollectionPetReviveSuccess: 'Collection - Pet Revive Success',
        CollectionPetAgeReviveCheckout: 'Collection - Pet Age Revive Checkout',
        CollectionPetAgeReviveSuccess: 'Collection - Pet Age Revive Success',
        CollectionMultiplePetsHealUpProceeding: 'Collection - Heal All Pets',
        CollectionMultiplePetsHealUpCheckout: 'Collection - Pets Feed Checkout',
        CollectionMultiplePetsHealUpSuccess: 'Collection - Pets Feed Success',
        /**
         * Profile
         */
        ProfileRoot: 'Profile',
        ProfileMain: 'Profile - Main',
        ProfileLanguagesSettings: 'Profile - Languages Settings',
        ProfileNotificationsSettings: 'Profile - Notifications Settings',
        ProfileSocialAccountsSettings: 'Profile - SocialAccounts Settings',
        ProfileSocialAccessibilitySettings: 'Profile - Accessibility Settings',
        ProfileSocialMedia: 'Profile - Social Media',

        /**
         * Others
         */
        Dev: 'Dev',
        RanksDetailedInfo: 'Ranks Detailed Info',
        EnergyInfoScreen: 'Energy Info Screen',

        // REFERRALS

        Referrals: 'Referrals',
        ReferralsAll: 'Referrals - All',
        ReferralsInvites: 'Referrals - Invites',
        ReferralsInvite: 'Referrals - Invite',
        ReferralsHowItsWork: 'Referrals - How It Works',

        // LEADERBOARD
        Leaderboard: 'Leaderboard',
        LeaderboardDailyTasks: 'Leaderboard - Task',
        LeaderboardPvpGames: 'Leaderboard - PvP',

        //Mystery Box
        MysteryBoxMain: 'Mystery Boxes',
        MysteryBoxPurchaseWelcome: 'Mystery Boxes - Purchase Welcome',
        MysteryBoxPurchase: 'Mystery Boxes - Purchase',
        MysteryBoxPurchaseCheckout: 'Mystery Boxes - Purchase Checkout',
        MysteryBoxPurchaseSuccess: 'Mystery Boxes - Purchase Success',

        //HOW
        HowToCharity: 'How Charity Work',
        HowToPlay: 'How to Play',
        HowToReferrals: 'Referrals - How It Works',
        HowToAvailableRewards: 'How Available Rewards work',
        HowToLockedRewards: 'How Available Locked work',
        HowToSocializeToEarn: 'How to Share',
        HowToVerifyUser: 'How to find Pets IDs',
        HowToPurchaseBNB: 'How to purchase BNB',
        HowToGamingAvailableWallet: 'How Available Wallet work',
        HowToGamingPendingWallet: 'How Pending Wallet work',
        HowToCommissionFee: 'Fee',
        HowToFee: 'Fee',
        HowToPurchaseSpadeId: 'How to Use SPACE ID',
        //ONBOARDING
        MainWalletOnBoardingScreen: 'Main Wallet',
        LoginOnBoardingScreen: 'Welcome',

        // BONUS MINT RANK
        BonusMintRanksInfo: 'Mint - Bonus Ranks',
        BonusMintRanksCarousel: 'Mint - Bonus All Ranks',

        InitialMint: 'Mint Your First Pet',
        InitialReferral: 'You have been Invited to IguVerse',

        YourPetsAreHungry: 'Your Pets are Hungry!',
        UpdateApp: 'New Update',

        //MARKETPLACE
        MarketPlaceActiveOrders: 'Marketplace - Active Orders',
        MarketPlaceMyProfile: 'Marketplace - My Profile',
        MarketPlaceProfile: 'Marketplace - Profile',
        MarketPlaceWelcomeCreateOrder: 'Marketplace - Welcome',
        MarketPlaceSelectNft: 'Marketplace - Select NFT',
        MarketPlaceSelectNftPrice: 'Marketplace - Select Price',
        MarketPlaceOverviewGroup: 'Marketplace - Overview',
        MarketPlaceOverview: 'Marketplace - Overview',
        MarketPlaceOverviewOwnerHistory: 'Marketplace - Owners History',
        MarketPlaceBuyCheckout: 'Marketplace - Checkout',
        MarketPlaceCreateOrderSuccess: 'Marketplace - Success',
        MarketPlaceDelistSuccess: 'Marketplace - Success',
        MarketPlaceBuySuccess: 'Marketplace - Success',

        //Achievements
        Achievements: 'Achievements',
        AchievementsDetails: 'Achievements - Details',

        //PassCode
        PassCodeFaceId: 'PassCode - Biometric',
        PassCodeNew: 'PassCode - Enable',
        PassCodeChange: 'PassCode - Change',
        PassCodeSuccess: 'PassCode - Success',
        PassCodeSettings: 'PassCode - Settings',
        PassCodeConfirm: 'PassCode - Confirm',

        //Lucky Strike

        WelcomeLuckyStrike: 'Lucky Strike - Welcome',
        LuckyStrike: 'Lucky Strike',
        LuckyStrikeMain: 'Lucky Strike - Rounds',
        LuckyStrikeHistory: 'Lucky Strike - History',
        HowLuckyStrikeWork: 'Lucky Strike - How its work',
        LuckyStrikeAllParticipants: 'Lucky Strike - Participants',
        LuckyStrikeWaitingForResult: 'Lucky Strike - Result',
        LuckyStrikeUserTickets: 'Lucky Strike - Tickets',
        LuckyStrikeUserTicketsWait: 'Lucky Strike - Tickets',
        LuckyStrikeAllWinners: 'Lucky Strike - Winners',
        LuckyStrikeGameHistory: 'Lucky Strike - History',

        //Quantum Disk
        WelcomeQuantumSpin: 'Quantum Disk - Welcome',
        HowQuantumSpinWork: 'Quantum Disk - How its work',
        QuantumSpin: 'Quantum Disk',
        QuantumSpinMain: 'Quantum Disk - Wheel',
        QuantumSpinHistory: 'Quantum Disk - History',
        QuantumSpinGameHistory: 'Quantum Disk - History',
        QuantumSpinProbablyFair: 'Quantum Disk - Provably Fair',
        QuantumSpinProbablyFairHistory: 'Quantum Disk - History',
        //TokenTeller
        WelcomeTokenTeller: 'Tokenteller - Welcome',
        TokenTeller: 'Tokenteller',
        TokenTellerMain: 'Tokenteller - Rounds',
        TokenTellerHistory: 'Tokenteller - History',
        HowTokenTellerWork: 'Tokenteller - How its Work',
        TokenTellerChart: 'Tokenteller - Chart',
        TokenTellerAllParticipants: 'Tokenteller - Participants',
        TokenTellerWaitingForResult: 'Tokenteller - Wait',
        TokenTellerGameHistory: 'Tokenteller - History',
    },
};

export default en;
