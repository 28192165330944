import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import { stylesContainerWithBorderDefault } from '@helpers/style';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        iconWrapper: {
            width: 175,
            height: 188,
            marginBottom: verticalScale(24),
            justifyContent: 'center',
            alignItems: 'center',
        },
        alignCenter: {
            alignItems: 'center',
        },
        stars: {
            backgroundColor: 'transparent',
            position: 'absolute',
            zIndex: 10,
        },
        text: {
            marginTop: verticalScale(8),
        },
        info: {
            marginTop: verticalScale(20),
            width: '100%',
            ...stylesContainerWithBorderDefault(theme),
        },
        withProvably: {
            marginTop: verticalScale(12),
        },
        gap: {
            marginTop: verticalScale(16),
        },
        feature: {
            marginTop: verticalScale(20),
            width: '100%',
        },
    });

export default styles;
