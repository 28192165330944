import React, { useEffect } from 'react';
import { Image, ScrollView } from 'react-native';

import { useCharity } from '@contexts/CharityContexts';
import { useFocusEffect } from '@react-navigation/native';

import { DonationsHistoryResponseItem } from '../../../../Data/Models';
import Button from '../../../../components/Button';
import Divider from '../../../../components/Divider';
import { ICON_NAMES } from '../../../../components/Icons';
import ModalLoader from '../../../../components/ModalLoader';
import { Text, View } from '../../../../components/Themed';
import { useDimensions } from '../../../../contexts/DimensionsContext';
import { scale } from '../../../../helpers/dimensions';
import useGetDonationsHistory from '../../../../hooks/useGetDonationsHistory';
import useSetCharity from '../../../../hooks/useSetCharity';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import NavigationBarModal from '../../../../navigation/NavigationBar/NavigationBarModal';
import useNavigationListeners from '../../../../navigation/hooks/useNavigationListeners';
import ROUTES from '../../../../navigation/routes';
import { useNavigation } from '../../../../navigation/useNavigation';
import { CharityEditDetailedOrganization } from '../../../../types';
import OrganizationInfo from '../components/OrganizationInfo';
import TotalOrganizationDonations from '../components/TotalOrganizationDonations';
import UserDonate from '../components/UserDonate';
import stylesMain from './styles';

const CharityEditDetailedOrganizationScreen = ({
    route,
}: CharityEditDetailedOrganization) => {
    useNavigationListeners();
    const { getCharityById } = useCharity();
    const { windowWidth } = useDimensions();
    const { charity: charityID } = route.params;

    const charity = getCharityById(charityID);
    const navigation = useNavigation();
    const styles = useThemedStyles(stylesMain);
    const [historyData, setHistoryData] = React.useState<
        DonationsHistoryResponseItem[]
    >([]);
    const [imageSize, setImageSize] = React.useState({ height: 1, width: 1 });

    const { saveOrganization, isSetCharityLoading } = useSetCharity();

    const getDonationsHistory = useGetDonationsHistory();

    useFocusEffect(
        React.useCallback(() => {
            getDonationsHistory({
                page: 1,
                limit: 3,
                charityId: charity.id,
                onCompleted: ({ donationsHistory }) => {
                    setHistoryData(donationsHistory.items);
                },
            });
        }, [])
    );

    useEffect(() => {
        Image.getSize(charity.logoUrl, (width, height) => {
            setImageSize({ width, height });
        });
    }, [charity.logoUrl]);

    const onSavePress = () => {
        saveOrganization({
            charityId: charity.id,
        });
    };

    const renderUsersDonations = () => {
        return historyData.map((donation) => (
            <UserDonate donation={donation} key={donation.id} />
        ));
    };

    return (
        <>
            <View style={[styles.container, styles.paddingHorizontal]}>
                <NavigationBarModal
                    iconName={ICON_NAMES.ARROW_LEFT}
                    title={i18n.t('charity.organizationDetails')}
                />
                <ScrollView
                    style={styles.headerContainer}
                    showsVerticalScrollIndicator={false}>
                    <View>
                        <Image
                            source={{ uri: charity.logoUrl }}
                            style={[
                                styles.charityImage,
                                {
                                    height:
                                        imageSize.height /
                                        (imageSize.width /
                                            (windowWidth - 2 * scale(20))),
                                },
                            ]}
                            resizeMode="contain"
                        />
                    </View>

                    <OrganizationInfo charity={charity} />

                    <Divider customStyles={styles.divider} />
                    <View style={[styles.paddingBottom]}>
                        <View style={styles.donationsTitleContainer}>
                            <Text style={styles.subtitle}>
                                {i18n.t('charity.donations')}
                            </Text>
                            <Button
                                type="text"
                                title={i18n.t('charity.viewAll')}
                                onPress={() =>
                                    navigation.navigate(
                                        ROUTES.CHARITY_ORGANIZATION_DONATIONS,
                                        { charity }
                                    )
                                }
                            />
                        </View>
                        <TotalOrganizationDonations
                            walletAddress={charity.ethAddress}
                            totalDonations={charity.totalDonationsIgupWei}
                        />
                        {renderUsersDonations()}
                    </View>
                </ScrollView>
                <Divider />
                <View style={styles.buttonSaveContainer}>
                    <Button
                        title={i18n.t('charity.chooseOrganization')}
                        onPress={onSavePress}
                    />
                </View>
                <ModalLoader
                    isVisible={isSetCharityLoading}
                    text={i18n.t('charity.loadingOrganizationChange')}
                />
            </View>
        </>
    );
};

export default CharityEditDetailedOrganizationScreen;
