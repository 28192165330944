import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '../../helpers/dimensions';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        image: {
            borderRadius: 8,
            marginBottom: verticalScale(20),
        },
        imageGap: {
            width: scale(40),
        },
    });

export default styles;
