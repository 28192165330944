import React from 'react';

import { ethers } from 'ethers';

import Input from '@components/Input';
import i18n from '@i18n/i18n';

export const useWalletAddressInput = () => {
    const [walletValue, setWalletValue] = React.useState<string>('');
    const [walletError, setWalletError] = React.useState<string>('');

    const onChange = (value: string) => {
        setWalletError('');
        setWalletValue(value.replaceAll(' ', ''));
    };

    const checkWallet = React.useCallback(() => {
        if (!ethers.utils.isAddress(walletValue)) {
            setWalletError(i18n.t('profile.walletInputError'));
            return false;
        }
        return true;
    }, [walletValue]);

    const renderWalletInput = React.useCallback(() => {
        return (
            <Input
                value={walletValue}
                onChangeText={onChange}
                placeholder={i18n.t('profile.walletInput.placeHolder')}
                error={walletError}
                label={i18n.t('profile.walletInput.label')}
            />
        );
    }, [walletValue, walletError]);

    const resetWalletInput = () => {
        setWalletError('');
        setWalletValue('');
    };

    return {
        renderWalletInput,
        checkWallet,
        walletError,
        walletValue,
        resetWalletInput,
    };
};
