import * as React from 'react';
import { FlatList } from 'react-native';

import { useWallet } from '@contexts/Wallet/WalletContext';

import { TokenTransaction } from '../../../../../Data/Models';
import Button from '../../../../../components/Button';
import EmptyList from '../../../../../components/EmptyList';
import { ICON_NAMES } from '../../../../../components/Icons';
import LoadingComponent from '../../../../../components/LoadingComponent';
import { Text, View } from '../../../../../components/Themed';
import useGetTokenTransactions from '../../../../../hooks/useGetTokenTransactions';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import { MemoizedWalletCoinsTransactionHistoryListItem } from '../WalletCoinsTransactionHistoryListItem';
import stylesMain from './styles';

interface IProps {
    contractAddress: string | null;
    stickyButtons: JSX.Element;
    onDepositPress?: () => void;
    coinName: string;
    refreshing?: boolean;
}

interface IRenderItem {
    item: TokenTransaction;
}

export const WalletCoinsTransactionHistoryList = ({
    contractAddress,
    stickyButtons,
    onDepositPress,
    coinName,
    refreshing,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { walletBalance, walletData } = useWallet();

    const {
        getTokenTransactions,
        getTokenTransactionsLoadMore,
        transactionsLoading,
        tokenTransactions,
        loadMoreLoading,
    } = useGetTokenTransactions(contractAddress);

    React.useEffect(() => {
        getTokenTransactions(true);
    }, []);

    React.useEffect(() => {
        getTokenTransactions(false, true);
    }, [walletBalance]);

    React.useEffect(() => {
        if (refreshing) {
            getTokenTransactions(false);
            Console.log('Refreshing transactions');
        }
    }, [refreshing]);

    const renderItem = React.useCallback(
        ({ item }: IRenderItem) => {
            return (
                <MemoizedWalletCoinsTransactionHistoryListItem
                    item={item}
                    address={walletData?.wallet.address}
                    coinName={coinName}
                />
            );
        },
        [walletData, coinName]
    );

    return (
        <>
            <FlatList
                initialNumToRender={10}
                scrollEventThrottle={5}
                bounces={false}
                style={styles.list}
                ListHeaderComponent={
                    <>
                        <View style={styles.buttonWrapper}>
                            {stickyButtons}
                        </View>
                        <View style={[styles.divider]} />
                        <View style={styles.titleWrapper}>
                            <Text style={styles.title}>
                                {i18n.t('wallet.transactionHistory')}
                            </Text>
                        </View>
                    </>
                }
                ListEmptyComponent={
                    <>
                        {transactionsLoading ? (
                            <LoadingComponent
                                containerStyles={styles.loadingWrapper}
                                text={i18n.t('general.loading')}
                            />
                        ) : (
                            <View style={styles.noTransactionWrapper}>
                                <EmptyList
                                    emptyTitle={i18n.t(
                                        'wallet.noTransactionYet'
                                    )}
                                    emptyText={i18n.t(
                                        'wallet.noTransactionYetInfo'
                                    )}
                                    ButtonComponent={
                                        <Button
                                            type="text"
                                            title={i18n.t('wallet.deposit')}
                                            onPress={onDepositPress}
                                            iconName={ICON_NAMES.DEPOSIT}
                                            iconColor="green"
                                            containerStyle={styles.button}
                                        />
                                    }
                                    withAnimation="cry"
                                />
                            </View>
                        )}
                    </>
                }
                showsVerticalScrollIndicator={false}
                data={tokenTransactions}
                renderItem={renderItem}
                numColumns={1}
                onEndReached={getTokenTransactionsLoadMore}
                onEndReachedThreshold={0.1}
                keyExtractor={(_, index) =>
                    `{coins-transaction-history}-${index}`
                }
            />
            {loadMoreLoading && (
                <LoadingComponent
                    containerStyles={styles.loadingWrapper}
                    text={i18n.t('general.loading')}
                />
            )}
        </>
    );
};
