import * as React from 'react';

import { useAnalytics } from '@hooks/useAnalytics';
import { getActiveRouteName } from '@navigation/helpers';
import { NavigationState } from '@react-navigation/routers';

import SecureStore from '../../utils/SecureStore';

export const navigationContainerConfig = {
    persistenceKey: 'NAVIGATION_STATE',
};

export default function usePersistNavigationState() {
    const { onScreenView } = useAnalytics();
    const [isReady, setIsReady] = React.useState<boolean>(false);
    const [initialState, setInitialState] = React.useState();

    const restoreState = async () => {
        try {
            const savedStateString = await SecureStore.getItemAsync(
                navigationContainerConfig.persistenceKey
            );
            const state = savedStateString
                ? JSON.parse(savedStateString)
                : undefined;

            if (
                state !== undefined &&
                window.location.pathname !== '/' &&
                !window.location.pathname.startsWith('/u')
            ) {
                setInitialState(state);
            }
        } finally {
            setIsReady(true);
        }
    };

    const onStateChange = async (state: NavigationState | undefined) => {
        const name = getActiveRouteName(state);
        onScreenView(name);

        await SecureStore.setItemAsync(
            navigationContainerConfig.persistenceKey,
            JSON.stringify(state)
        );
    };

    const resetState = async () => {
        await SecureStore.deleteItemAsync(
            navigationContainerConfig.persistenceKey
        );
    };

    return {
        initialState,
        isReady,
        restoreState,
        onStateChange,
        resetState,
    };
}
