import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import CardFeatureInfo from '@components/CardFeatureInfo/CardFeatureInfo';
import CoinIcon from '@components/Icons/CoinIcon';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { priceString } from '@helpers/payments';
import { balanceFromWei } from '@helpers/wallet';
import i18n from '@i18n/i18n';

interface IProps {
    containerStyle?: StyleProp<ViewStyle>;
    maxAmount: string;
    minAmount: string;
}

const MaxMinimalBetFeature = ({
    containerStyle,
    maxAmount,
    minAmount,
}: IProps) => {
    const max = React.useMemo(
        () => priceString(balanceFromWei(maxAmount).valueLong, Coin.vigu),
        [maxAmount]
    );
    const min = React.useMemo(
        () => priceString(balanceFromWei(minAmount).valueLong, Coin.vigu),
        [minAmount]
    );

    return (
        <CardFeatureInfo
            containerStyle={containerStyle}
            icon={<CoinIcon slug={Coin.vigu} size={48} />}
            title={i18n.t('HomeScreen.MaxMinimalBetFeature.title', {
                max,
                min,
            })}
            text={i18n.t('HomeScreen.MaxMinimalBetFeature.text')}
        />
    );
};
export default MaxMinimalBetFeature;
