import { StyleSheet } from 'react-native';

import { isWeb } from '../../../helpers/app';
import { scale, verticalScale } from '../../../helpers/dimensions';
import { stylesModalNavigationFix } from '../../../helpers/style';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
            paddingBottom: verticalScale(16),
        },
        wrapper: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
        },
        itemContainer: {
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            paddingHorizontal: scale(20),
            backgroundColor: 'transparent',
        },
        imgWrapper: {
            alignSelf: 'center',

            marginBottom: verticalScale(24),
        },
        header: {
            paddingHorizontal: scale(16),
        },
        buttonWrapper: {
            marginTop: verticalScale(24),
            marginBottom: verticalScale(8),
            paddingHorizontal: scale(20),
        },
    });

export default styles;
