import * as React from 'react';

import { View } from '../../../components/Themed';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import ROUTES from '../../routes';
import { useNavigation } from '../../useNavigation';
import NavigationItem from '../NavigationItem';
import stylesMain from './styles';

interface IProps {
    currentNavigation: NavigationReferralsType;
}

export type NavigationReferralsType = 'INVITES' | 'ALL';

const NavigationReferrals = ({ currentNavigation }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const handleOnInvitesPress = () => {
        if (currentNavigation === 'INVITES') {
            return;
        }

        navigation.navigate(ROUTES.REFERRALS_INVITES);
    };

    const handleOnNFTPress = () => {
        if (currentNavigation === 'ALL') {
            return;
        }

        navigation.navigate(ROUTES.REFERRALS_ALL);
    };

    return (
        <View style={styles.shadow}>
            <View style={styles.container}>
                <NavigationItem
                    onPress={handleOnInvitesPress}
                    title={i18n.t('navigationBar.referrals.invites')}
                    isActive={currentNavigation === 'INVITES'}
                    containerStyles={styles.containerItem}
                />
                <NavigationItem
                    onPress={handleOnNFTPress}
                    title={i18n.t('navigationBar.referrals.all')}
                    isActive={currentNavigation === 'ALL'}
                    containerStyles={styles.containerItem}
                />
            </View>
        </View>
    );
};
export default NavigationReferrals;
