import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import ActivityIndicator from '@components/ActivityIndicator';
import HealthProgressIcon from '@components/Icons/HealthProgressIcon';
import { Text, View } from '@components/Themed';
import { useHaptic } from '@hooks/useHaptic';
import useThemedStyles from '@hooks/useThemedStyles';

import stylesMain from './styles';

interface IProps {
    range: string;
    onPress: (range: string) => void;
    index: number;
    isActive: boolean;
    isLoading: boolean;
    isTooltip?: boolean;
}

const LikesRangeItem = ({
    range,
    onPress,
    index,
    isActive,
    isLoading,
    isTooltip,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { triggerHeavy } = useHaptic();
    const press = () => {
        triggerHeavy();
        onPress(range);
    };

    return (
        <TouchableOpacity
            key={range}
            style={[
                styles.shadow,
                isActive && styles.active,
                isTooltip && styles.isTooltip,
            ]}
            activeOpacity={0.7}
            disabled={isLoading}
            onPress={press}>
            <View
                style={[
                    styles.itemContainer,
                    isActive && styles.activeContainer,
                ]}>
                <View style={styles.left}>
                    {isLoading ? (
                        <ActivityIndicator
                            loading={isLoading}
                            containerStyle={styles.loading}
                        />
                    ) : (
                        <Text style={styles.range}>{range}</Text>
                    )}
                </View>
                <View style={styles.right}>
                    <HealthProgressIcon slug={index} size={24} />
                </View>
            </View>
        </TouchableOpacity>
    );
};
export default LikesRangeItem;
