import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import Carousel from '@components/Carousel';
import { View } from '@components/Themed';
import { useDimensions } from '@contexts/DimensionsContext';
import { verticalScale } from '@helpers/dimensions';
import { useCarouselHeight } from '@hooks/useCarouselHeight';
import useThemedStyles from '@hooks/useThemedStyles';
import { NftRoyalGameLives } from '@models/mysteryGames';

import LivesCountItem from '../LivesCountItem';
import stylesMain from './styles';

interface IProps {
    data: NftRoyalGameLives[];
    onPress: (lives: number) => void;
    selectedLivesCount: number | null;
    containerStyle?: StyleProp<ViewStyle>;
}

const storageKey = 'NftRoyalGameLivesCarousel.height';

const NftRoyalGameLivesCarousel = ({
    data,
    onPress,
    selectedLivesCount,
    containerStyle,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { windowWidth } = useDimensions();

    const itemWidth = React.useMemo(() => {
        return 100;
    }, [windowWidth]);

    const { height, getHeight } = useCarouselHeight(storageKey);

    return (
        <View style={containerStyle}>
            <Carousel
                data={data}
                renderItem={(item, index) => (
                    <LivesCountItem
                        minHeight={height}
                        getHeight={getHeight}
                        key={item + index}
                        onPress={() => onPress(item.count)}
                        isSelected={item.count === selectedLivesCount}
                        isLast={index === data.length - 1}
                        lives={item}
                        index={index || 0}
                    />
                )}
                itemWidth={itemWidth}
                sliderWidth={windowWidth}
                height={height + verticalScale(4)}
                pagingEnabled={false}
                snapEnabled={false}
                windowSize={10}
                carouselStyle={styles.carousel}
            />
        </View>
    );
};
export default React.memo(NftRoyalGameLivesCarousel);
