import * as React from 'react';

import { PaymentOptionOutput } from '@Data/Models';
import { ITransactionResult } from '@contexts/Wallet/WalletContext';
import { getPrice } from '@helpers/payments';
import { feeWithCurrency } from '@helpers/wallet';
import useFeeInfo from '@hooks/useFeeInfo';
import i18n from '@i18n/i18n';

import TransactionSummary from '../../TransactionSummary';

interface IProps {
    isFree?: boolean;
    payment: PaymentOptionOutput | undefined;
    transactionResult?: ITransactionResult;
    estimatedTransactionFee?: string;
}

const CheckoutSummary = ({
    isFree,
    payment,
    transactionResult,
    estimatedTransactionFee,
}: IProps) => {
    const { renderFeeInfoIcon } = useFeeInfo();

    const hasGasFee = !isFree && payment?.tokenAddress;

    const gasFee = React.useMemo(() => {
        if (!hasGasFee) {
            return null;
        }
        return transactionResult
            ? transactionResult?.transactionFeeFormatted
            : feeWithCurrency(estimatedTransactionFee);
    }, [hasGasFee, transactionResult, estimatedTransactionFee]);

    const totalPrice = React.useMemo(() => {
        return getPrice(payment);
    }, [payment]);

    const values = [
        hasGasFee
            ? {
                  key: i18n.t('checkout.fields.fee'),
                  value: gasFee,
                  icon: renderFeeInfoIcon(true),
              }
            : undefined,
        {
            key: i18n.t('checkout.fields.amount'),
            value: !isFree ? totalPrice : i18n.t('checkout.free'),
        },
    ];

    return <TransactionSummary values={values} />;
};
export default CheckoutSummary;
