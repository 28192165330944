import * as React from 'react';
import { ScrollView } from 'react-native';

import { StakingPlanOutput } from '@Data/Models';
import Button from '@components/Button';
import { ICON_NAMES } from '@components/Icons';
import CoinIcon from '@components/Icons/CoinIcon';
import Input from '@components/Input';
import ModalDeposit from '@components/ModalDeposit';
import { Text, View } from '@components/Themed';
import { StakingCoin } from '@contexts/StakingContext';
import { Coin, CoinBalance } from '@contexts/Wallet/WalletHelpers';
import { isWeb } from '@helpers/app';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import MaxBoost from '../components/MaxBoost';
import stylesMain from './styles';

interface IProps {
    stakingCoin: StakingCoin;
    handleInputFocus: () => void;
    amount: string;
    errorAmount?: string | undefined;
    handleOnBlur: () => void;
    handleChangeAmount: (amount: string) => Promise<void>;
    coinWallet: CoinBalance | undefined;
    handleClickAll: () => Promise<void>;
    currentPlan: StakingPlanOutput | undefined;
    handleClickMaxBoost: () => Promise<void>;
    depositModal: boolean;
    openDepositModal: () => void;
    closeDepositModal: () => void;
    showDepositButton: boolean;
}

const StakingCoinsInputScreenRenderItem = ({
    stakingCoin,
    handleInputFocus,
    amount,
    errorAmount,
    coinWallet,
    handleOnBlur,
    handleChangeAmount,
    handleClickAll,
    currentPlan,
    handleClickMaxBoost,
    depositModal,
    closeDepositModal,
    openDepositModal,
    showDepositButton,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <ScrollView
            scrollEventThrottle={16}
            keyboardShouldPersistTaps="handled"
            contentContainerStyle={styles.page}>
            <View style={styles.inputWrapper}>
                <View style={styles.icon}>
                    <CoinIcon slug={stakingCoin} size={32} />
                </View>
                <Input
                    onFocus={handleInputFocus}
                    style={[
                        styles.inputContainer,
                        isWeb && {
                            width: `${Math.max(amount.length, 3)}ch`,
                        },
                    ]}
                    value={amount}
                    keyboardType="decimal-pad"
                    onBlur={handleOnBlur}
                    onChangeText={handleChangeAmount}
                    placeholder="0.0"
                    maxLength={11}
                    isAvailableExternalStyle
                />
            </View>

            <View style={styles.availableWrapper}>
                <Text style={styles.available}>
                    {i18n.t('staking.coinsInputScreen.available')}
                    <Text style={styles.amount}>{`${
                        coinWallet?.presentationValueShort || 0
                    } ${stakingCoin}`}</Text>
                </Text>
                <Button
                    onPress={handleClickAll}
                    title={i18n.t('staking.coinsInputScreen.maxButton')}
                    containerStyle={styles.buttonAll}
                    type="outline"
                    size="sm"
                />
            </View>
            {showDepositButton && (
                <View style={styles.gap}>
                    <Button
                        iconName={ICON_NAMES.PLUS}
                        iconColor="green"
                        onPress={openDepositModal}
                        title={i18n.t('checkout.deposit')}
                        type="text"
                        size="md"
                    />
                    <ModalDeposit
                        isVisible={depositModal}
                        setIsVisible={closeDepositModal}
                        coin={Coin.igup}
                    />
                </View>
            )}
            {!!errorAmount && (
                <View style={styles.errorsWrapper}>
                    <Text style={styles.error}>{errorAmount}</Text>
                </View>
            )}
            {!!currentPlan && (
                <MaxBoost
                    currentPlan={currentPlan}
                    coin={stakingCoin}
                    onPress={handleClickMaxBoost}
                    containerStyle={styles.gap}
                />
            )}
        </ScrollView>
    );
};

export default StakingCoinsInputScreenRenderItem;
