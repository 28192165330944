import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { Text } from 'react-native';

import useThemedStyles from '../../hooks/useThemedStyles';
import Icon, { ICON_NAMES } from '../Icons';
import stylesMain from './styles';

interface IProps {
    level: number;
    fillAmount: number;
    showLevelIndicator?: boolean;
    experience?: number;
    maxExperience?: number;
    fullView?: boolean;
    withIcon?: boolean;
    xpStyles?: StyleProp<ViewStyle>;
    height?: number;
    iconSize?: number;
    fromModal?: boolean;
    isOneColor?: boolean;
}

const LevelIndicator = ({
    level,
    fillAmount,
    showLevelIndicator = true,
    experience,
    maxExperience,
    fullView,
    withIcon = true,
    xpStyles = {},
    height = 8,
    iconSize = 20,
    fromModal = false,
    isOneColor,
}: IProps) => {
    const styles = useThemedStyles(stylesMain, isOneColor);

    const maxFill = fillAmount === 100;

    // separate view for full component, maybe should be refactored
    if (fullView) {
        return (
            <View style={[styles.fullWrapper]}>
                {showLevelIndicator && (
                    <View
                        style={[
                            styles.fullContainer,
                            fromModal && styles.extraMargin,
                        ]}>
                        <View
                            style={[
                                styles.fill,
                                styles.fullFill,
                                {
                                    width: `${
                                        fillAmount > 100 ? 100 : fillAmount
                                    }%`,
                                },
                            ]}></View>
                    </View>
                )}
                <View style={styles.textLevelContainer}>
                    <View style={styles.leftContent}>
                        {withIcon && (
                            <Icon name={ICON_NAMES.LEVEL} size={iconSize} />
                        )}
                        <Text
                            style={[
                                styles.level,
                                styles.fullViewLvl,
                                !withIcon && styles.noMargin,
                            ]}>{`LVL ${level}`}</Text>
                    </View>
                    {experience != null && (
                        <View style={styles.containerXP}>
                            <Text
                                style={[
                                    styles.actualXP,
                                    experience && styles.expEarned,
                                    maxFill && styles.maxFill,
                                    xpStyles,
                                ]}>
                                {`${experience}`}
                            </Text>
                            {!!maxExperience && (
                                <Text
                                    style={[
                                        styles.xp,
                                        maxFill && styles.maxFill,
                                        xpStyles,
                                    ]}>
                                    {` / ${maxExperience} `}
                                </Text>
                            )}
                            {<Text style={[styles.xp, xpStyles]}>XP</Text>}
                        </View>
                    )}
                </View>
            </View>
        );
    }

    return (
        <View style={styles.wrapper}>
            <View style={[styles.container, { height }]}>
                <View
                    style={[
                        styles.fill,
                        { width: `${fillAmount > 100 ? 100 : fillAmount}%` },
                        { height },
                    ]}></View>
            </View>
            <Text style={styles.level}>{`LVL ${level}`}</Text>
        </View>
    );
};
export default LevelIndicator;
