import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        mintPriceContainer: {
            paddingHorizontal: scale(8),
            paddingVertical: verticalScale(4),
            flexDirection: 'row',
            borderRadius: 50,
            backgroundColor:
                Colors[theme].mintPriceBackground.mintPriceContainer,
            alignItems: 'center',
        },
        margin: {
            marginRight: verticalScale(12),
        },
        mintPrice: {
            marginLeft: scale(2),
            color: Colors[theme].mintPriceBackground.mintPrice,
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),
            marginRight: scale(4),
        },
        mintPriceImage: {
            backgroundColor: 'transparent',
        },
    });

export default styles;
