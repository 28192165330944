import { StyleSheet } from 'react-native';

import { head } from 'lodash';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        content: {
            flexDirection: 'row',
            alignItems: 'center',

            justifyContent: 'space-between',
            borderRadius: moderateScale(12),

            height: '100%',
        },
        titleWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: verticalScale(2),
        },
        title: {
            flex: 1,
            color: Colors[theme].text.onLight,
            ...font({
                size: 18,
                height: 24,
                weight: '700',
            }),
            marginRight: verticalScale(16),
        },
        text: {
            color: Colors[theme].text.onLight,
            ...font({
                size: 15,
                height: 22,
                weight: '600',
            }),
        },

        container: {
            borderRadius: moderateScale(12),
        },
        image: {
            width: 48,
            height: 48,
        },
        timer: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        gap: {
            marginLeft: verticalScale(2),
        },
        gradientBackground: {
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(12),

            borderRadius: moderateScale(12),
        },
    });

export default styles;
