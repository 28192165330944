import * as React from 'react';

import Button from '@components/Button';
import { Text } from '@components/Themed';
import { toastComingSoon } from '@helpers/toastNotification';
import { truncateEthAddress } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    address: string;
}

const StatisticsUserAccount = ({ address }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const onProfile = () => {
        toastComingSoon();
        //TODO
    };

    return (
        <>
            <Text style={styles.title}>
                {i18n.t('general.KEYS.yourAccount')}
            </Text>
            <Text
                style={styles.address}
                numberOfLines={1}
                ellipsizeMode="middle">
                {truncateEthAddress(address)}
            </Text>
            <Button
                type="text"
                size="md"
                title={i18n.t('general.BUTTONS.viewProfile')}
                containerStyle={styles.button}
                onPress={onProfile}
                debouncedPress
            />
        </>
    );
};
export default StatisticsUserAccount;
