import * as React from 'react';
import {
    KeyboardAvoidingView,
    SafeAreaView,
    ScrollView,
    Text,
} from 'react-native';

import { ethers } from 'ethers';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import AlertCard from '@components/AlertCard';
import Button from '@components/Button';
import Divider from '@components/Divider';
import Icon, { ICON_NAMES } from '@components/Icons';
import CoinIcon from '@components/Icons/CoinIcon';
import Input from '@components/Input';
import ModalConfirmTransaction from '@components/ModalConfirmTransaction';
import ModalDeposit from '@components/ModalDeposit';
import ModalLoader from '@components/ModalLoader';
import PullToRefresh from '@components/PullToRefresh';
import { View } from '@components/Themed';
import TransactionSummary from '@components/TransactionSummary';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { Coin, getMaxValue } from '@contexts/Wallet/WalletHelpers';
import { isIOS } from '@helpers/app';
import {
    feeWithCurrency,
    inputNumberFormatter,
    isBiggerThanMax,
    numberFormatter,
} from '@helpers/wallet';
import useBlockchainErrorsHandler from '@hooks/useBlockchainErrorsHandler';
import useFeeInfo from '@hooks/useFeeInfo';
import { useLocalAuthentication } from '@hooks/useLocalAuthentication';
import useQrCodeScanner from '@hooks/useQrCodeScanner';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import { WalletCoinsWithdrawProps } from '../../../../types';
import stylesMain from './styles';

const screenConfig = {
    minWithdrawalAmount: {
        BNB: 0.001,
        BUSD: 1,
        IGU: 1,
        IGUP: 1,
        IGUAI: 0,
        VIGUP: 0,
        VIGU: 0,
    },
};

const WithdrawScreen = ({ route }: WalletCoinsWithdrawProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const {
        reloadBalance,
        walletBalance,
        walletData,
        transferCoin,
        fee,
        balanceReloading,
        getCoinBalances,
    } = useWallet();

    const balance = React.useMemo(() => {
        return (
            getCoinBalances().find(
                (item) => item.name === route.params.balance?.name
            ) || route.params.balance
        );
    }, [getCoinBalances, route.params, walletBalance]);

    const [isVisibleModalDeposit, setIsVisibleModalDeposit] =
        React.useState<boolean>(false);

    const {
        isVisible: isVisibleConfirmationModal,
        close: closeConfirmationModal,
        open: openConfirmationModal,
    } = useVisibleHook();

    // fee info modalScreen
    const { renderFeeInfoIcon } = useFeeInfo(
        closeConfirmationModal,
        openConfirmationModal
    );
    const { handleBlockchainErrors, renderBlockchainErrorsModal } =
        useBlockchainErrorsHandler();
    const [loading, setLoading] = React.useState<boolean>(false);

    const onRefresh = React.useCallback(() => {
        reloadBalance(false);
        setTouched({ amount: false });
    }, []);

    const estimatedTransactionFee = React.useMemo(() => {
        if (!balance.name) {
            return '';
        }
        return fee?.trade[balance.name];
    }, [balance, fee]);

    const minimumAmount = screenConfig.minWithdrawalAmount[balance.name];

    const handleOnDepositPress = () => {
        setIsVisibleModalDeposit(true);
    };

    const handleScanned = async (scanned: string) => {
        await setFieldValue('address', scanned);
        await setTouched({ address: true });
    };

    const { scanButton, scannerModal } = useQrCodeScanner(
        'wallet',
        handleScanned
    );

    const withdraw = async () => {
        setLoading(true);

        try {
            const value = values.amount.replace(/,/, '.');
            const result = await transferCoin(
                values.address,
                value,
                balance.name
            );

            setLoading(false);

            navigation.navigate(ROUTES.WALLET_COINS_WITHDRAW_SUCCESS, {
                transactionValue: values.amount,
                transactionCoin: balance.name,
                balance,
                addressFrom: values.address,
                addressTo: values.address,
                transactionResult: result,
            });
        } catch (error) {
            Console.error(error);
            setLoading(false);

            handleBlockchainErrors(error);
        }
    };

    const isBNBEnough = React.useMemo(() => {
        return (
            Number(walletBalance?.bnb.value) >= Number(estimatedTransactionFee)
        );
    }, [walletBalance?.bnb.value, estimatedTransactionFee]);

    const validationSchemaDefault = Yup.object({
        address: Yup.string()
            .required(i18n.t('withdrawScreen.errors.address'))
            .test(
                'is-valid-address',
                i18n.t('withdrawScreen.errors.invalidAddress'),
                (value = '') => ethers.utils.isAddress(value)
            )
            .test(
                'is-not-same-address',
                i18n.t('withdrawScreen.errors.sameWallet'),
                (value = '') =>
                    value.toLowerCase() !==
                    walletData?.wallet?.address.toLowerCase()
            ),
    });

    const baseSchema = Yup.string()
        .transform((_, value) => {
            if (value.includes('.')) {
                return value;
            }
            return value.replace(/,/, '.');
        })
        .matches(/^\d*(\.\d+)?$/, i18n.t('withdrawScreen.errors.digits'))
        .required(i18n.t('withdrawScreen.errors.amountRequired'));

    const bnbSchema = Yup.object({
        amount: baseSchema
            .test(
                'maxAmount',
                i18n.t('withdrawScreen.errors.maxAmountError'),
                (value) => {
                    return Number(value) <= Number(balance.value) - 0.0001;
                }
            )
            .test(
                'minLimit',
                i18n.t('withdrawScreen.errors.minLimit', {
                    limit: minimumAmount,
                    coin: balance.name,
                }),
                (value) => Number(value) >= minimumAmount
            ),
    });

    const tokenSchema = Yup.object({
        amount: baseSchema
            .test(
                'maxAmount',
                i18n.t('withdrawScreen.errors.maxAmountError'),
                (value) => Number(value) <= Number(balance.value)
            )
            .test(
                'bnbNotEnough',
                i18n.t('withdrawScreen.errors.bnbNotEnough'),
                () => isBNBEnough
            )
            .test(
                'minLimit',
                i18n.t('withdrawScreen.errors.minLimit', {
                    limit: minimumAmount,
                    coin: balance.name,
                }),
                (value) => Number(value) >= minimumAmount
            )
            .test(
                'bnbNotEnough',
                i18n.t('withdrawScreen.errors.bnbNotEnough'),
                () => isBNBEnough
            ),
    });

    const formikData = useFormik({
        initialValues: {
            address: '',
            amount: '',
        },
        validationSchema: validationSchemaDefault.concat(
            balance?.name === 'BNB' ? bnbSchema : tokenSchema
        ),
        onSubmit: () => openConfirmationModal(),
        enableReinitialize: true,
    });

    const {
        submitForm,
        values,
        errors,
        setFieldValue,
        setTouched,
        touched,
        isValid,
        dirty,
    } = formikData;

    // Not enough amount of tokens
    const isAmountError =
        touched.amount &&
        (errors.amount === i18n.t('withdrawScreen.errors.maxAmountError') ||
            errors.amount === i18n.t('withdrawScreen.errors.bnbNotEnough'));

    const handleChangeAmount = async (amount: string) => {
        await setTouched({ amount: false });
        const convertedText = inputNumberFormatter(amount);
        if (!isBiggerThanMax(convertedText)) {
            await setFieldValue('amount', convertedText);
        }
    };

    const handleChangeAddress = async (address: string) => {
        await setTouched({ address: false });
        await setFieldValue('address', address);
    };

    const [isFirstLoading, setIsFirstLoading] = React.useState<boolean>(true);

    //refresh form on balance change
    React.useEffect(() => {
        if (isFirstLoading) {
            setIsFirstLoading((prev) => !prev);
            return;
        }
        const update = () => {
            handleChangeAmount(values.amount);
            handleChangeAddress(values.address);
        };
        update();
    }, [walletBalance]);

    const handleClickMax = async () => {
        const maxValue = getMaxValue(balance, estimatedTransactionFee);
        await setFieldValue('amount', maxValue);
        await setTouched({ amount: true });
    };

    const renderItem = React.useCallback(() => {
        return (
            <ScrollView
                scrollEventThrottle={16}
                keyboardShouldPersistTaps="handled"
                contentContainerStyle={styles.page}>
                <Input
                    label={i18n.t('withdrawScreen.labelAddress')}
                    value={values.address}
                    error={!loading && errors.address}
                    onChangeText={(address) => handleChangeAddress(address)}
                    onBlur={() => setTouched({ address: true })}
                    placeholder={i18n.t('withdrawScreen.placeholderAddress')}
                    stylesContainer={styles.input}
                    component={scanButton()}
                />
                <Input
                    keyboardType="decimal-pad"
                    label={i18n.t('withdrawScreen.labelAmount')}
                    value={values.amount.toString()}
                    error={errors.amount}
                    onBlur={() => setTouched({ amount: true })}
                    onChangeText={(amount) => handleChangeAmount(amount)}
                    placeholder={i18n.t('withdrawScreen.placeholderAmount')}
                    secondLabel={
                        <View style={styles.wrapperLabel}>
                            <Text style={styles.label}>
                                {i18n.t('withdrawScreen.labelAvailable')}:{' '}
                            </Text>
                            <Text style={styles.labelBold}>
                                {numberFormatter(
                                    balance?.presentationValueLong
                                )}{' '}
                                {balance.name}
                            </Text>
                        </View>
                    }
                    component={
                        <Button
                            onPress={handleClickMax}
                            disabled={!estimatedTransactionFee || loading}
                            title={i18n.t('withdrawScreen.allButtonLabel')}
                            containerStyle={styles.buttonAll}
                            type="outline"
                            size="sm"
                        />
                    }
                />
                {isAmountError && (
                    <Button
                        type="text"
                        title={i18n.t('withdrawScreen.depositButtonLabel')}
                        onPress={handleOnDepositPress}
                        icon={<Icon name={ICON_NAMES.PLUS} color="green" />}
                        containerStyle={styles.buttonDeposit}
                    />
                )}
                <AlertCard
                    text={i18n.t('withdrawScreen.withdrawWarningText')}
                    containerStyle={styles.warning}
                />
            </ScrollView>
        );
    }, [
        touched,
        estimatedTransactionFee,
        errors,
        values,
        loading,
        balance,
        isAmountError,
        walletBalance,
    ]);

    const { renderPassCodeCheck, localAuth } = useLocalAuthentication();

    const renderConfirmModal = () => {
        const transactionDetailsValues = [
            {
                key: i18n.t('checkout.fields.transaction'),
                value: i18n.t('withdrawScreen.transaction'),
            },
            {
                key: i18n.t('checkout.fields.from'),
                value: i18n.t('checkout.wallet', {
                    coin: balance.name,
                }),
            },
            {
                key: i18n.t('checkout.fields.to'),
                value: values.address,
            },
            {
                key: i18n.t('checkout.fields.fee'),
                value: feeWithCurrency(estimatedTransactionFee),
                icon: renderFeeInfoIcon(),
            },
            {
                key: i18n.t('checkout.fields.totalSummary'),
                value: `${values.amount} ${balance.name}`,
            },
        ];

        return (
            <ModalConfirmTransaction
                handleAuth={localAuth}
                isVisible={isVisibleConfirmationModal}
                close={closeConfirmationModal}
                onConfirm={withdraw}
                modalHeight={650}
                TransactionDetailsComponent={
                    <TransactionSummary values={transactionDetailsValues} />
                }
                textWarning={i18n.t('withdrawScreen.withdrawWarningText')}
            />
        );
    };

    const renderDepositModal = () => {
        return (
            <ModalDeposit
                isVisible={isVisibleModalDeposit}
                setIsVisible={setIsVisibleModalDeposit}
                coin={isBNBEnough ? balance.name : Coin.bnb}
            />
        );
    };

    const transactionSummaryValues = [
        {
            key: i18n.t('withdrawScreen.blockchainFee'),
            value: feeWithCurrency(estimatedTransactionFee),
            icon: renderFeeInfoIcon(true),
        },
        {
            key: i18n.t('withdrawScreen.totalSummary'),
            value: `${numberFormatter(values.amount)} ${balance.name}`,
        },
    ];

    return (
        <KeyboardAvoidingView
            behavior={isIOS ? 'padding' : 'height'}
            style={styles.container}>
            <NavigationBar filled={false} backIcon>
                <View style={styles.titleWrapper}>
                    <CoinIcon slug={balance.name} size={32} />
                    <Text style={styles.title}>
                        {i18n.t('withdrawScreen.title')} {balance?.name}
                    </Text>
                </View>
            </NavigationBar>
            <PullToRefresh
                refreshing={balanceReloading}
                textLoading={i18n.t('pullToRefresh.reloadBalance.text')}
                onRefresh={() => onRefresh()}
                renderItem={renderItem}
                keyboardDismiss
            />
            <Divider customStyles={styles.divider} />
            <View style={styles.buttonContainer}>
                <TransactionSummary
                    values={transactionSummaryValues}
                    containerStyle={styles.summary}
                />
                <Button
                    disabled={
                        !(isValid && dirty) || !Number(values.amount) || loading
                    }
                    title={i18n.t('withdrawScreen.confirmButtonLabel')}
                    onPress={submitForm}
                    containerStyle={styles.button}
                />
            </View>
            <SafeAreaView />
            {renderBlockchainErrorsModal()}
            {renderDepositModal()}
            {renderConfirmModal()}
            {scannerModal()}
            {renderPassCodeCheck()}
            <ModalLoader
                isVisible={loading}
                text={i18n.t('wallet.loadingWithdraw')}
            />
        </KeyboardAvoidingView>
    );
};

export default WithdrawScreen;
