import * as React from 'react';
import { ScrollView, StyleProp, ViewStyle } from 'react-native';

import Button from '@components/Button';
import Icon, { ICON_NAMES } from '@components/Icons';
import { Text, View } from '@components/Themed';
import { REGEX_PATTERNS } from '@constants/Regex';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import {
    IMarketplaceTags,
    MARKETPLACE_ORDERS_SORT_OPTION,
    MARKETPLACE_PET_TYPE,
    MARKETPLACE_TAGS_TYPES,
} from '@models/marketplace';

import FilterItem from '../FilterItem';
import MarketPlaceInput from '../MarketPlaceInput';
import ModalFilter from '../ModalFilter';
import stylesMain from './styles';

interface IProps {
    containerStyle?: StyleProp<ViewStyle>;
    typeOfNft: MARKETPLACE_PET_TYPE | null;
    selectedRanks: string[] | null;
    sortedBy: MARKETPLACE_ORDERS_SORT_OPTION | null;
    onApplyFilterPress: (
        type: MARKETPLACE_PET_TYPE | null,
        selectedRanks: string[] | null,
        sortedBy: MARKETPLACE_ORDERS_SORT_OPTION | null
    ) => void;
    onClearPress: () => void;
    onTagClearPress: (item: IMarketplaceTags) => void;
    onClearSearchKeywords: () => void;
    searchByKeyword: (value: string) => void;
}

const MarketPlaceSearchBlock = ({
    containerStyle,
    typeOfNft,
    selectedRanks,
    sortedBy,
    onApplyFilterPress,
    onClearPress,
    onTagClearPress,
    onClearSearchKeywords,
    searchByKeyword,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const [value, setValue] = React.useState<string>('');
    const { isVisible, open, close } = useVisibleHook();

    const searchFilterCount = React.useMemo(() => {
        const type = !!typeOfNft ? 1 : 0;
        const sort = !!sortedBy ? 1 : 0;
        const ranks = selectedRanks?.length || 0;
        return type + sort + ranks;
    }, [typeOfNft, selectedRanks, sortedBy]);

    const searchTags = React.useMemo(() => {
        const tags: IMarketplaceTags[] = [];
        if (typeOfNft) {
            tags.push({
                id: MARKETPLACE_TAGS_TYPES.MARKETPLACE_PET_TYPE,
                key: typeOfNft,
                title: i18n.t(
                    `MarketPlace.MarketPlaceSearch.typeSection.${typeOfNft}`
                ),
            });
        }

        if (sortedBy) {
            tags.push({
                id: MARKETPLACE_TAGS_TYPES.MARKETPLACE_ORDERS_SORT_OPTION,
                key: sortedBy,
                title: i18n.t(
                    `MarketPlace.MarketPlaceSearch.sortBySection.${sortedBy}`
                ),
            });
        }
        if (selectedRanks?.length) {
            selectedRanks.forEach((i) =>
                tags.push({
                    id: MARKETPLACE_TAGS_TYPES.MARKETPLACE_RANKS,
                    key: i,
                    title: i18n.t(`ranks.list.${i}.title`),
                })
            );
        }
        return tags;
    }, [typeOfNft, sortedBy, selectedRanks]);

    const handleClear = () => {
        setValue('');
        onClearSearchKeywords();
    };

    const onChangeText = (text: string) => {
        if (text.match(REGEX_PATTERNS.verifyMarketplaceSearch.regex)) {
            setValue(text || '');
            text.length === 0 && handleClear();
            return;
        }
    };

    const onSubmit = React.useCallback(() => {
        searchByKeyword(value);
    }, [value]);

    return (
        <View style={[styles.container, containerStyle]}>
            <View style={styles.searchContainer}>
                <View style={styles.input}>
                    <MarketPlaceInput
                        value={value}
                        onChangeText={onChangeText}
                        onClearPress={handleClear}
                        onSubmitEditing={onSubmit}
                    />
                </View>
                <View style={styles.buttonWrapper}>
                    {!!searchFilterCount && (
                        <View style={styles.filterCount}>
                            <Text style={styles.filterCountText}>
                                {searchFilterCount}
                            </Text>
                        </View>
                    )}
                    <Button
                        onPress={open}
                        icon={
                            <Icon
                                name={ICON_NAMES.FILTER}
                                size={20}
                                color={styles.filterIcon.color}
                            />
                        }
                        type="outline"
                        size="md"
                        containerStyle={[styles.buttonBlock]}
                        style={styles.button}
                    />
                </View>
            </View>
            {!!searchTags.length && (
                <ScrollView
                    style={styles.searchTags}
                    horizontal
                    showsHorizontalScrollIndicator={false}>
                    {searchTags.map((i, index) => (
                        <FilterItem
                            key={index}
                            title={i.title}
                            onPress={() => onTagClearPress(i)}
                        />
                    ))}
                </ScrollView>
            )}

            <ModalFilter
                isVisible={isVisible}
                onClose={close}
                typeOfNft={typeOfNft}
                selectedRanks={selectedRanks}
                sortedBy={sortedBy}
                onApplyFilterPress={onApplyFilterPress}
                onClearPress={onClearPress}
            />
        </View>
    );
};
export default MarketPlaceSearchBlock;
