import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import Carousel from '@components/Carousel';
import DifficultyIcon from '@components/Icons/DifficultyIcon';
import { View } from '@components/Themed';
import { useDimensions } from '@contexts/DimensionsContext';
import { scale, verticalScale } from '@helpers/dimensions';
import { useCarouselHeight } from '@hooks/useCarouselHeight';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { GAME_DIFFICULTY } from '@models/mysteryGames';

import GameConfigCard from '../GameConfigCard';
import stylesMain from './styles';

interface IProps {
    data: GAME_DIFFICULTY[];
    onPress: (difficulty: GAME_DIFFICULTY) => void;
    selectedDifficulty: GAME_DIFFICULTY | null;
    containerStyle?: StyleProp<ViewStyle>;
}
const storageKey = 'GameDifficultyCarousel.height';
const GameDifficultyCarousel = ({
    data,
    onPress,
    selectedDifficulty,
    containerStyle,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { windowWidth } = useDimensions();

    const itemWidth = React.useMemo(() => {
        const clearWidth = windowWidth - scale(40);
        const width = clearWidth / 2;
        return width >= 160 ? width : 160;
    }, [windowWidth]);

    const { height, getHeight } = useCarouselHeight(storageKey);

    return (
        <View style={containerStyle}>
            <Carousel
                data={data}
                renderItem={(item, index) => (
                    <GameConfigCard
                        minHeight={height}
                        getHeight={getHeight}
                        key={item + index}
                        onPress={() => onPress(item)}
                        isSelected={item === selectedDifficulty}
                        isLast={index === data.length - 1}
                        title={i18n.t(
                            `HomeScreen.NftRoyal.NftRoyalGameSettings.difficultyType.${item}.title`
                        )}
                        text={i18n.t(
                            `HomeScreen.NftRoyal.NftRoyalGameSettings.difficultyType.${item}.text`
                        )}
                        icon={<DifficultyIcon slug={item} />}
                    />
                )}
                itemWidth={itemWidth}
                sliderWidth={windowWidth}
                height={height + verticalScale(4)}
                pagingEnabled={false}
                snapEnabled={false}
                windowSize={10}
                carouselStyle={styles.carousel}
            />
        </View>
    );
};
export default React.memo(GameDifficultyCarousel);
