import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Animated, PanResponder, View, findNodeHandle } from 'react-native';

import Icon, { ICON_NAMES } from '@components/Icons';
import LoadingComponent from '@components/LoadingComponent';
import { Text } from '@components/Themed';
import { useAppState } from '@contexts/AppStateContext';
import useThemedStyles from '@hooks/useThemedStyles';

import mainStyles from './styles';

interface IProps {
    onRefresh: () => void;
    progressViewOffset?: number;
    refreshing: boolean;
    style?: any;
    children?: any;
    textReleasing: string;
    textPulling: string;
    textLoading: string;
}

export default function RefreshControlWeb({
    refreshing,
    style,
    progressViewOffset = 0,
    children,
    onRefresh,
    textReleasing,
    textPulling,
    textLoading,
}: IProps) {
    const styles = useThemedStyles(mainStyles);
    const { isConnected } = useAppState();

    const containerRef = useRef<View>(null);
    const pullDownSwipeMargin = useRef(new Animated.Value(0));
    const [pullReached, setPullReached] = useState<boolean>(false);
    const [isPulling, setIsPulling] = useState(true);
    const onRefreshRef = useRef(onRefresh);
    useEffect(() => {
        onRefreshRef.current = onRefresh;
    }, [onRefresh]);
    const isConnectedRef = useRef(isConnected);
    useEffect(() => {
        isConnectedRef.current = isConnected;
    }, [isConnected]);
    const refreshingRef = useRef(refreshing);

    useEffect(() => {
        pullDownSwipeMargin.current.addListener(({ value }) => {
            setPullReached(value >= 100);
        });

        return () => pullDownSwipeMargin.current.removeAllListeners();
    }, [pullDownSwipeMargin.current]);

    useEffect(() => {
        refreshingRef.current = refreshing;
        if (refreshing) {
            setPullReached(false);
            setIsPulling(false);
        } else {
            Animated.timing(pullDownSwipeMargin.current, {
                toValue: 0,
                duration: 350,
                useNativeDriver: false,
            }).start();
            setTimeout(() => setIsPulling(true), 350);
        }
    }, [refreshing]);

    const onPanResponderFinish = useCallback(() => {
        const pullReached = (pullDownSwipeMargin.current as any)._value >= 100;
        if (pullReached && onRefreshRef.current) {
            onRefreshRef.current();
            Animated.timing(pullDownSwipeMargin.current, {
                toValue: 60,
                duration: 350,
                useNativeDriver: false,
            }).start();
        }
        if (
            (!pullReached && !refreshingRef.current) ||
            !isConnectedRef.current
        ) {
            Animated.timing(pullDownSwipeMargin.current, {
                toValue: 0,
                duration: 350,
                useNativeDriver: false,
            }).start();
        }
    }, []);

    const panResponder = useRef(
        PanResponder.create({
            onStartShouldSetPanResponder: () => false,
            onStartShouldSetPanResponderCapture: () => false,
            onMoveShouldSetPanResponder: () => {
                if (!containerRef.current) return false;
                const containerDOM = findNodeHandle(containerRef.current);
                if (!containerDOM) return false;
                //@ts-ignore
                return containerDOM.children[0].scrollTop === 0;
            },
            onMoveShouldSetPanResponderCapture: () => false,
            onPanResponderMove: (_, gestureState) => {
                if (gestureState.dy < 0) return;
                const adjustedDy =
                    (gestureState.dy * 150) / (gestureState.dy + 120); // Diminishing returns function
                pullDownSwipeMargin.current.setValue(adjustedDy);
            },
            onPanResponderTerminationRequest: () => true,
            onPanResponderRelease: onPanResponderFinish,
            onPanResponderTerminate: onPanResponderFinish,
        })
    );

    const containerStyle = useMemo(
        () => [
            style,
            {
                overflowY: 'hidden',
                overflow: 'hidden',
                paddingTop: progressViewOffset,
            },
        ],
        [progressViewOffset, style]
    );
    const indicatorTransformStyle = useMemo(
        () => [
            styles.indicatorStyle,
            {
                transform: [{ translateY: pullDownSwipeMargin.current }],
            },
        ],
        []
    );

    // This is messing with react-native-web's internal implementation
    // Will probably break if anything changes on their end
    const AnimatedContentContainer = useMemo(
        () =>
            withAnimated((childProps: any) => (
                <children.props.children.type {...childProps} />
            )),
        []
    );
    const newContentContainerStyle = useMemo(
        () => [
            children.props.children.props.style,
            { transform: [{ translateY: pullDownSwipeMargin.current }] },
        ],
        [children.props.children.props.style]
    );

    const newChildren = React.cloneElement(
        children,
        undefined,
        <>
            <Animated.View style={indicatorTransformStyle}>
                {refreshing ? (
                    <>
                        <LoadingComponent text={''} isPlaying />
                        <Text style={styles.text}>{textLoading}</Text>
                    </>
                ) : (
                    <>
                        {isPulling && (
                            <View style={styles.arrowIcon}>
                                <Icon
                                    name={
                                        pullReached
                                            ? ICON_NAMES.ARROW_UP
                                            : ICON_NAMES.ARROW_DOWN
                                    }
                                    size={16}
                                    color="secondary"
                                />
                                <Text style={styles.arrowText}>
                                    {!pullReached ? textPulling : textReleasing}
                                </Text>
                            </View>
                        )}
                    </>
                )}
            </Animated.View>
            <AnimatedContentContainer
                {...children.props.children.props}
                style={newContentContainerStyle}
            />
        </>
    );

    return (
        <View
            ref={containerRef}
            style={containerStyle}
            {...panResponder.current.panHandlers}>
            {newChildren}
        </View>
    );
}

function withAnimated(WrappedComponent: any) {
    const displayName =
        WrappedComponent.displayName || WrappedComponent.name || 'Component';

    class WithAnimated extends React.Component {
        static displayName = `WithAnimated(${displayName})`;

        render() {
            return <WrappedComponent {...this.props} />;
        }
    }

    return Animated.createAnimatedComponent(WithAnimated);
}
