import { StyleSheet } from 'react-native';

import { P } from '@expo/html-elements';
import { scale, verticalScale } from '@helpers/dimensions';
import { stylesModalNavigationFix } from '@helpers/style';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
            ...stylesModalNavigationFix(),
            paddingBottom: verticalScale(32),
        },

        loading: {
            flex: 1,
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
        },
        listWrapper: {
            flex: 1,
            paddingHorizontal: scale(20),
        },
        features: {
            flexDirection: 'row',
            backgroundColor: 'transparent',
            justifyContent: 'space-between',
        },
        feature: {
            width: '47%',
        },
        column: {
            justifyContent: 'space-between',
        },
        header: {
            paddingHorizontal: scale(20),
        },
        blockHeader: {
            marginBottom: verticalScale(20),
        },
        alert: {
            marginHorizontal: scale(20),
            marginTop: verticalScale(16),
        },
    });

export default styles;
