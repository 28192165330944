import { StyleSheet } from 'react-native';

import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import { stylesAlignCenter } from '@helpers/style';

import Colors from '../../constants/Colors';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        content: {
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(8),
            borderRadius: moderateScale(8),
            backgroundColor: Colors[theme].background,
            maxWidth: 300,
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
        },
        contentText: {
            ...stylesAlignCenter(),
            textAlign: 'center',
            ...font({
                size: 13,
                height: 18,
                weight: '600',
            }),
            color: Colors[theme].text.primary,
        },
        shadow: {
            backgroundColor: 'transparent',
            border: 0,
            shadowOffset: {
                width: 0,
                height: 3,
            },
            shadowOpacity: 0.2,
            shadowRadius: 0,
            elevation: 15,
            borderRadius: 50,
        },
        contentView: {
            flex: 1,
        },
        arrow: {
            zIndex: 1,
        },
        webShadow: {
            borderRadius: moderateScale(8),
            backgroundColor: Colors[theme].shadow.default,
            maxWidth: 300,
        },
    });

export default styles;
