import * as React from 'react';
import { LayoutChangeEvent, LayoutRectangle, ScrollView } from 'react-native';

import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';

import stylesMain from './styles';

interface IProps {
    children: React.ReactNode;
    isNarrowWeb: boolean;
    onLayout: (layout: number) => void;
}

const WheelWrapper = ({ children, isNarrowWeb, onLayout }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const getHeight = (event: LayoutChangeEvent) => {
        onLayout(event.nativeEvent.layout.height);
    };

    return isNarrowWeb ? (
        <ScrollView showsVerticalScrollIndicator={false} onLayout={getHeight}>
            {children}
        </ScrollView>
    ) : (
        <View style={styles.wrapper} onLayout={getHeight}>
            {children}
        </View>
    );
};

export default WheelWrapper;
