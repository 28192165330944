import * as React from 'react';

import EmptyList from '@components/EmptyList';
import LoadingComponent from '@components/LoadingComponent';
import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MarketplaceTransactionOutput } from '@models/marketplace';

import MarketPlacePurchaseHistoryItem from '../MarketPlacePurchaseHistoryItem';
import stylesMain from './styles';

interface IProps {
    transaction: MarketplaceTransactionOutput[] | undefined;
    historyLoading: boolean;
}

const MarketPlacePurchaseHistory = ({
    transaction,
    historyLoading,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={styles.container}>
            {historyLoading && !transaction ? (
                <LoadingComponent />
            ) : !!transaction?.length ? (
                transaction.map((transaction, index) => (
                    <MarketPlacePurchaseHistoryItem
                        transaction={transaction}
                        key={index}
                    />
                ))
            ) : (
                <EmptyList
                    containerStyle={styles.emptyList}
                    emptyTitle={i18n.t(
                        `MarketPlace.MarketPlaceOverviewOwnerHistoryScreen.empty.title`
                    )}
                    emptyText={i18n.t(
                        `MarketPlace.MarketPlaceOverviewOwnerHistoryScreen.empty.text`
                    )}
                    withAnimation="cry"
                />
            )}
        </View>
    );
};
export default MarketPlacePurchaseHistory;
