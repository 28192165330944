import * as React from 'react';
import { ScrollView, View } from 'react-native';

import { Text2ImageQuotaOutput } from '../../../../Data/Models';
import Daily from '../../../../assets/icons/mint/aiQuote/daily.svg';
import High from '../../../../assets/icons/mint/aiQuote/high.svg';
import Low from '../../../../assets/icons/mint/aiQuote/low.svg';
import Medium from '../../../../assets/icons/mint/aiQuote/medium.svg';
import Overloaded from '../../../../assets/icons/mint/aiQuote/overloaded.svg';
import System from '../../../../assets/icons/mint/aiQuote/system.svg';
import ImaginationIcon from '../../../../assets/icons/mint/generationAI.svg';
import BlockHeader from '../../../../components/BlockHeader/BlockHeader';
import CardFeatureInfo from '../../../../components/CardFeatureInfo/CardFeatureInfo';
import ModalBottom from '../../../../components/ModalBottom';
import { Text } from '../../../../components/Themed';
import { renderListLengthMax } from '../../../../helpers/helpers';
import { ISystemLoadType, IUsagesLeft } from '../../../../hooks/useAIInfo';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
    usagesLeft?: IUsagesLeft | null;
    dailySystemLoad?: ISystemLoadType | null;
    currentSystemLoad?: ISystemLoadType | null;
    aiQuota?: Text2ImageQuotaOutput | null;
}

const ModalAiInfo = ({
    isVisible,
    onClose,
    usagesLeft,
    dailySystemLoad,
    currentSystemLoad,
    aiQuota,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const systemLoadIcon = React.useMemo(() => {
        switch (currentSystemLoad) {
            case 'overloaded':
                return <Overloaded />;
            case 'medium':
                return <Medium />;
            case 'high':
                return <High />;

            default:
                return <Low />;
        }
    }, [currentSystemLoad]);

    const used =
        Number(aiQuota?.maxDailyUserUsage) - Number(usagesLeft?.usages) || 0;

    return (
        <ModalBottom
            isVisible={isVisible}
            onClose={onClose}
            modalHeight="96%"
            containerStyle={styles.containerStyle}
            swipeDirection={['down']}>
            <ScrollView
                style={[styles.container]}
                showsVerticalScrollIndicator={false}
                bounces={false}>
                <View style={styles.imageWrapper}>
                    <ImaginationIcon width={215} height={180} />
                </View>
                <BlockHeader
                    type="emptyState"
                    title={i18n.t('aiQuoteInfo.modalInfo.title')}
                    text={i18n.t('aiQuoteInfo.modalInfo.text')}
                />
                <View style={styles.cardWrapper}>
                    <CardFeatureInfo
                        containerStyle={styles.gap}
                        icon={<Daily />}
                        text={i18n.t('aiQuoteInfo.modalInfo.dailyText', {
                            amount: aiQuota?.maxDailyUserUsage || 0,
                        })}>
                        {i18n.t('aiQuoteInfo.modalInfo.daily')}
                        <Text style={!usagesLeft?.usages && styles.danger}>
                            {renderListLengthMax(
                                Number(used),
                                Number(aiQuota?.maxDailyUserUsage)
                            )}
                        </Text>
                    </CardFeatureInfo>
                    <CardFeatureInfo
                        containerStyle={styles.gap}
                        icon={systemLoadIcon}
                        title={i18n.t('aiQuoteInfo.modalInfo.systemLoad', {
                            load: i18n.t(
                                `aiQuoteInfo.modalInfo.load.${currentSystemLoad}`
                            ),
                        })}
                        text={i18n.t('aiQuoteInfo.modalInfo.systemLoadText')}
                    />
                    <CardFeatureInfo
                        containerStyle={styles.gap}
                        icon={<System />}
                        title={i18n.t('aiQuoteInfo.modalInfo.systemUsage', {
                            percentage:
                                aiQuota?.dailySystemUsagePercentage || 0,
                        })}
                        text={i18n.t('aiQuoteInfo.modalInfo.systemUsageText')}
                    />
                </View>
            </ScrollView>
        </ModalBottom>
    );
};
export default ModalAiInfo;
