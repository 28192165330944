import { useCallback } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { TOP_UP_MIN, useGamingWallet } from '@contexts/GamingWalletContext';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { inputNumberFormatter, isBiggerThanMax } from '@helpers/wallet';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

export const useTopUpGamingWalletScreen = () => {
    const navigation = useNavigation();
    const { setTopUpFromMysteryGames } = useMysteryGames();

    const {
        isVisible: howToTopUpVisible,
        close: closeHowToTopUp,
        open: openHowToTopUp,
    } = useVisibleHook();

    const { walletBalance } = useWallet();

    const { topUpAmount, setTopUpAmount, resetGamingWallet } =
        useGamingWallet();

    const schema = Yup.object({
        amount: Yup.string()
            .transform((_, value) => {
                if (value && value.includes('.')) {
                    return value;
                }
                return value && value.replace(/,/, '.');
            })

            .matches(
                /^\d*(\.\d+)?$/,
                i18n.t(
                    'wallet.gaming.TopUpGamingWalletScreen.inputSection.errors.digits'
                )
            )
            .required(i18n.t('withdrawScreen.errors.amountRequired'))
            .test(
                'maxAmount',
                i18n.t(
                    'wallet.gaming.TopUpGamingWalletScreen.inputSection.errors.max',
                    {
                        limit: walletBalance?.igu.presentationValueLong,
                        coin: Coin.vigu,
                    }
                ),
                (value) =>
                    Number(value) <=
                    Number(walletBalance?.igu.presentationValueLong)
            )
            .test(
                'minAmount',
                i18n.t(
                    'wallet.gaming.TopUpGamingWalletScreen.inputSection.errors.min',
                    {
                        limit: TOP_UP_MIN,
                        coin: Coin.vigu,
                    }
                ),
                (value) => Number(value) >= Number(TOP_UP_MIN)
            ),
    });

    const {
        submitForm,
        values,
        errors,
        setFieldValue,
        setTouched,
        touched,
        isValid,
        dirty,
    } = useFormik({
        initialValues: {
            amount: topUpAmount || '',
        },
        validationSchema: schema,
        onSubmit: ({ amount }) => {
            setTopUpAmount(amount);
            navigation.navigate(ROUTES.TOP_UP_CHECKOUT);
        },
        enableReinitialize: true,
    });

    const handleChangeAmount = async (amount: string) => {
        setTouched({ amount: false });

        const convertedText = inputNumberFormatter(amount);
        if (!isBiggerThanMax(convertedText)) {
            await setFieldValue('amount', convertedText);
        }
    };

    const handleOnBlur = () => {
        setTouched({ amount: true });
    };

    const onSelectCardPress = (amount: string) => {
        setFieldValue('amount', amount);
    };

    const handleOnBack = () => {
        navigation.pop();
        setTopUpFromMysteryGames(false);
        resetGamingWallet();
    };

    const handleClickAll = useCallback(async () => {
        await setFieldValue(
            'amount',
            walletBalance?.igu?.presentationValueLong
        );
    }, [walletBalance?.igu]);

    return {
        submitForm,
        values,
        errors,
        setFieldValue,
        setTouched,
        touched,
        isValid,
        dirty,
        handleChangeAmount,
        handleOnBlur,
        onSelectCardPress,
        howToTopUpVisible,
        closeHowToTopUp,
        openHowToTopUp,
        handleOnBack,
        walletBalance,
        handleClickAll,
    };
};
