import * as React from 'react';
import { useEffect } from 'react';
import { Keyboard } from 'react-native';

import { Coin } from '@contexts/Wallet/WalletHelpers';
import { useLocalAuthentication } from '@hooks/useLocalAuthentication';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import { ComponentAnyType } from '../../types';
import AlertCard, { AlertCardType } from '../AlertCard';
import Button, { ButtonType } from '../Button';
import { ICON_NAMES } from '../Icons';
import ModalBottom from '../ModalBottom';
import ModalDeposit from '../ModalDeposit';
import { View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    close: () => void;
    TransactionDetailsComponent: React.ReactNode;
    textWarning?: string;
    alertType?: AlertCardType;
    onCancel?: () => void;
    onConfirm: () => void;
    title?: string;
    modalHeight?: number | string;
    disabled?: boolean;
    showDeposit?: boolean;
    shouldCloseOnConfirm?: boolean;
    needAuth?: boolean;
    handleAuth?: (action?: (() => void) | undefined) => Promise<void>;
    actionButtonType?: ButtonType | undefined;
    handleActionAlertPress?: () => void;
    actionButtonIcon?: ICON_NAMES | undefined;
    disableSecondButton?: boolean;
    isLoading?: boolean;
}

const ModalConfirmTransaction = ({
    isVisible,
    close,
    TransactionDetailsComponent,
    textWarning,
    alertType = 'warning',
    handleAuth,
    onCancel,
    onConfirm,
    title,
    modalHeight = '70%',
    disabled = false,
    showDeposit,
    shouldCloseOnConfirm = true,
    actionButtonType,
    handleActionAlertPress,
    actionButtonIcon,
    disableSecondButton,
    isLoading,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    // show deposit modal
    const [isVisibleModalDeposit, setIsVisibleModalDeposit] =
        React.useState<boolean>(false);

    const handleOnConfirm = async () => {
        shouldCloseOnConfirm && close();
        if (handleAuth) {
            await handleAuth(onConfirm);
            return;
        }
        onConfirm();
    };

    const handleOnCancel = () => {
        close();
        if (onCancel) onCancel();
    };

    const onDepositPress = () => {
        setIsVisibleModalDeposit(true);
    };

    const renderDepositButton = () => {
        if (!showDeposit) {
            return null;
        }

        return (
            <>
                <Button
                    containerStyle={styles.deposit}
                    iconName={ICON_NAMES.PLUS}
                    iconColor="green"
                    onPress={onDepositPress}
                    title={i18n.t('checkout.deposit')}
                    type="text"
                    size="md"
                />
                <ModalDeposit
                    isVisible={isVisibleModalDeposit}
                    setIsVisible={setIsVisibleModalDeposit}
                    coin={Coin.bnb}
                    closeFirstModal={close}
                    secondModal
                />
            </>
        );
    };

    useEffect(() => {
        if (isVisible) {
            Keyboard.dismiss();
        }
    }, [isVisible]);

    return (
        <ModalBottom
            modalHeight={modalHeight}
            isVisible={isVisible}
            onClose={handleOnCancel}
            titleText={title || i18n.t('ModalConfirmTransaction.title')}>
            <>
                <View style={styles.container}>
                    <View style={styles.transactionDetails}>
                        {TransactionDetailsComponent}
                    </View>
                    {textWarning && (
                        <View style={styles.warning}>
                            <AlertCard
                                text={textWarning}
                                type={alertType}
                                onAction={handleActionAlertPress}
                                actionButtonIcon={actionButtonIcon}
                                actionButtonType={actionButtonType}
                            />
                        </View>
                    )}

                    {renderDepositButton()}
                </View>
                <View style={styles.buttonContainer}>
                    <Button
                        title={i18n.t('ModalConfirmTransaction.confirm')}
                        onPress={handleOnConfirm}
                        debouncedPress
                        debounceTime={2000}
                        containerStyle={styles.button}
                        disabled={disabled}
                        loading={isLoading}
                    />
                    <Button
                        disabled={disableSecondButton}
                        title={i18n.t('ModalConfirmTransaction.cancel')}
                        onPress={handleOnCancel}
                        debouncedPress
                        debounceTime={2000}
                        type="outline"
                        containerStyle={styles.button}
                    />
                </View>
            </>
        </ModalBottom>
    );
};

export default ModalConfirmTransaction;
