import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import PvpGame from '@assets/icons/leaderboard/pvpGame.svg';
import CoinIcon from '@components/Icons/CoinIcon';
import { View } from '@components/Themed';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import {
    LeaderboardRowOutput,
    LeaderboardStatsOutput,
} from '@models/leaderboard';
import ROUTES from '@navigation/routes';

import PvpGamesBoard from '../PvpGamesBoard';
import TotalCard from '../TotalCard';
import stylesMain from './styles';

interface IProps {
    rankingStat: LeaderboardStatsOutput;
    topPlayers: LeaderboardRowOutput[];
    containerStyle?: StyleProp<ViewStyle>;
}

const screen = ROUTES.LEADERBOARD_PVP_GAMES;

const PvpGamesStatistics = ({
    rankingStat,
    topPlayers,
    containerStyle,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={[styles.container, containerStyle]}>
            <View style={styles.cardWrapper}>
                <TotalCard
                    containerStyle={styles.cardWidth}
                    title={i18n.t(`Leaderboard.${screen}.totalTurnover`)}
                    amount={
                        balanceFromWei(rankingStat.totalIguAmountWei).valueShort
                    }
                    icon={<CoinIcon slug={Coin.igu} size={32} />}
                />
                <TotalCard
                    containerStyle={styles.cardWidth}
                    title={i18n.t(`Leaderboard.${screen}.games`)}
                    amount={rankingStat?.totalGamesPlayed || 0}
                    icon={<PvpGame width={32} height={32} />}
                />
            </View>
            {!!topPlayers && <PvpGamesBoard topPlayers={topPlayers} />}
        </View>
    );
};
export default PvpGamesStatistics;
