import * as React from 'react';

import { useQuery } from '@apollo/client';
import { balanceFromWei } from '@helpers/wallet';

import { ReferralTotalTurnoverResponse } from '../../../../../Data/Models';
import { REFERRAL_TOTAL_TURNOVER } from '../../../../../Data/Requests';
import CoinIcon from '../../../../../components/Icons/CoinIcon';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import ReferralTotalCard from '../ReferralTotalCard';
import stylesMain from './styles';

interface IProps {
    shouldRefresh: boolean;
}

const TurnoverCard = ({ shouldRefresh }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { data, refetch, error } = useQuery<ReferralTotalTurnoverResponse>(
        REFERRAL_TOTAL_TURNOVER
    );

    const amount =
        balanceFromWei(data?.referralTotalTurnover.busdAmountWei).valueShort ||
        0;

    React.useEffect(() => {
        if (shouldRefresh) {
            refetch();
        }
    }, [shouldRefresh]);

    if (!data || error) {
        return null;
    }

    return (
        <ReferralTotalCard
            icon={<CoinIcon slug="BUSD" size={32} />}
            containerStyle={styles.cardWidth}
            amount={`${amount} BUSD`}
            text={i18n.t('referrals.invitedReferrals.card.turnover')}
        />
    );
};
export default TurnoverCard;
