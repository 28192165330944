import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import ICON from '@assets/icons/mysteryGames/TokenTeller/up.svg';
import CardFeatureInfo from '@components/CardFeatureInfo/CardFeatureInfo';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import i18n from '@i18n/i18n';
import {
    MYSTERY_GAMES,
    TOKEN_TELLER_GAME_PREDICTION_DIRECTION,
    TokentellerAvailableGameOutput,
} from '@models/mysteryGames';

import { getTokenTellerCoefficient } from '../../TokenTellerFlow/helpers';

interface IProps {
    containerStyle?: StyleProp<ViewStyle>;
    type?: 'default' | 'opacity';
    game: TokentellerAvailableGameOutput;
}
const GAME = MYSTERY_GAMES.tokenteller;
const FLOW = 'TokenTellerUpPayout';

const TokenTellerUpPayout = ({
    containerStyle,
    type = 'default',
    game,
}: IProps) => {
    const { tokenTellerCoefficients } = useMysteryGames();

    const coefficient = React.useMemo(
        () =>
            getTokenTellerCoefficient(
                tokenTellerCoefficients,
                game.id,
                game.upCoefficient,
                game.status,
                TOKEN_TELLER_GAME_PREDICTION_DIRECTION.Up
            ),
        [tokenTellerCoefficients, game]
    );

    return (
        <CardFeatureInfo
            type={type}
            containerStyle={containerStyle}
            icon={<ICON width={48} height={48} />}
            title={i18n.t(`HomeScreen.${GAME}.${FLOW}.title`, {
                coefficient,
            })}
            text={i18n.t(`HomeScreen.${GAME}.${FLOW}.text`)}
        />
    );
};
export default TokenTellerUpPayout;
