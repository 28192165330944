import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import Colors from '../../constants/Colors';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: 'transparent',
            marginHorizontal: scale(20),
            marginTop: verticalScale(32),
        },
        imgWrapper: {
            alignItems: 'center',
        },
        blockHeader: {
            marginTop: verticalScale(24),
        },
        cardFeatureInfo: {
            marginTop: verticalScale(24),
        },
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 22,
                height: 30,
                weight: '800',
            }),

            marginBottom: verticalScale(8),
        },
        text: {
            color: Colors[theme].text.secondary,
            textAlign: 'center',
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            marginBottom: verticalScale(16),
        },
        buttonWrapper: {
            paddingHorizontal: scale(20),
            paddingVertical: verticalScale(16),
        },
    });

export default styles;
