import * as React from 'react';
import { useState } from 'react';

import * as AppleAuthentication from 'expo-apple-authentication';

import { ApolloError, useMutation } from '@apollo/client';

import { LoginWithAppleResponse } from '../../../../Data/Models';
import { LOGIN_WITH_APPLE } from '../../../../Data/Requests';
import MaintenanceModal from '../../../../components/AppState/FullMaintenanceState/components/MaintenanceModal';
import Button from '../../../../components/Button';
import CustomModal from '../../../../components/CustomModal';
import Icon, { ICON_NAMES } from '../../../../components/Icons';
import ImageIguana from '../../../../components/ImageIguana';
import ModalLoader from '../../../../components/ModalLoader';
import { SessionContext } from '../../../../contexts/SessionContext';
import { isGraphqlError, waitForNavigation } from '../../../../helpers/helpers';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import style from '../../styles';

const Index: React.FunctionComponent = () => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isError, setIsError] = React.useState<boolean>(false);
    const styles = useThemedStyles(style);
    const sessionContext = React.useContext(SessionContext);
    const { setSessionToken } = sessionContext;
    const [request, { data }] =
        useMutation<LoginWithAppleResponse>(LOGIN_WITH_APPLE);
    const [isLoginMaintenance, setIsLoginMaintenance] =
        useState<boolean>(false);

    const handleLogin = async () => {
        try {
            setIsLoading(true);

            const credential = await AppleAuthentication.signInAsync({
                requestedScopes: [
                    AppleAuthentication.AppleAuthenticationScope.EMAIL,
                ],
            });
            const { data } = await request({
                variables: {
                    loginWithAppleInput: {
                        identityToken: credential.identityToken,
                    },
                },
            });
            // success
            if (data) {
                await waitForNavigation();
                setIsLoading(false);

                setSessionToken(data.loginWithApple.access_token);
            }
        } catch (error: any) {
            setIsLoading(false);

            if (isGraphqlError(error, 'FULL_MAINTENANCE')) {
                setIsLoginMaintenance(true);
            } else if (error instanceof ApolloError) {
                setIsError(true);
            } else {
                Console.log('[APPLE] handlePress', error);
                if (error.code === 'ERR_CANCELED') {
                    // handle that the user canceled the sign-in flow
                } else {
                    // handle other errors
                }
            }
        }
    };

    return (
        <>
            <Button
                title={i18n.t('login.apple')}
                onPress={handleLogin}
                icon={<Icon name={ICON_NAMES.APPLE} />}
                type="outline"
                containerStyle={styles.buttonGap}
                debouncedPress
                social
            />
            <ModalLoader
                isVisible={isLoading}
                text={i18n.t('login.loading.loggingIn')}
            />
            <CustomModal
                isVisible={isError}
                icon={<ImageIguana type="cry" />}
                titleText={i18n.t('login.errors.loginFailed')}
                infoText={i18n.t('login.errors.smthWentWrong')}
                firstButtonText={i18n.t('general.ok')}
                onFirstButtonClick={() => {
                    setIsError(false);
                }}
            />
            <MaintenanceModal
                isVisible={isLoginMaintenance}
                setIsVisible={setIsLoginMaintenance}
                canClose
            />
        </>
    );
};

export default Index;
