import React from 'react';

import AppVersionIcon from '@assets/icons/profile/appVersion.svg';
import { appVersion } from '@helpers/app';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@react-navigation/native';

import MenuItem from '../../../MenuItem';
import { RightComponent, iconSize } from '../../helpers';

const AppVersionMenuItem = () => {
    const navigation = useNavigation();

    return (
        <MenuItem
            leftIcon={<AppVersionIcon width={iconSize} height={iconSize} />}
            title={i18n.t('profile.menuItems.appVersion.title')}
            description={i18n.t('profile.menuItems.appVersion.description', {
                version: appVersion,
            })}
            rightComponent={<RightComponent type="arrowRight" />}
            onPress={() =>
                navigation.navigate(ROUTES.UPDATE_APP, { reset: true })
            }
        />
    );
};

export default AppVersionMenuItem;
