import * as React from 'react';
import { Linking, ScrollView, TouchableOpacity } from 'react-native';
import { WebView } from 'react-native-webview';

import { useWallet } from '@contexts/Wallet/WalletContext';
import { isAndroid, isWeb } from '@helpers/app';
import { moderateScale } from '@helpers/dimensions';
import { errorsHandler } from '@helpers/errors';
import { openUrlOrShowError } from '@helpers/linking';

import BinanceIcon from '../../../assets/icons/wallet/binance.svg';
import BlockHeader from '../../../components/BlockHeader/BlockHeader';
import Icon, { ICON_NAMES } from '../../../components/Icons';
import { Text, View } from '../../../components/Themed';
import WalletAddress from '../../../components/WalletAddress';
import { useHaptic } from '../../../hooks/useHaptic';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n, { I18N_STORAGE_KEY, getLanguage } from '../../../i18n/i18n';
import NavigationBarModal from '../../../navigation/NavigationBar/NavigationBarModal';
import useNavigationListeners from '../../../navigation/hooks/useNavigationListeners';
import { useNavigation } from '../../../navigation/useNavigation';
import stylesMain from './styles';

const VIDEO_LINK = 'https://fast.wistia.net/embed/iframe/x460r4tnc7';
const height = isWeb ? 250 : 200;

interface ILinks {
    id: string;
    uri: string;
}

const HowToPurchaseBNBScreen = () => {
    useNavigationListeners();
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const { walletData } = useWallet();
    const { triggerMedium } = useHaptic();

    const [loadingLang, setLoadingLang] = React.useState<boolean>(true);

    const [links, setLinks] = React.useState<ILinks[]>();

    const getLang = async () => {
        const storageLang = await getLanguage();

        const lang = storageLang === 'uk' ? 'uk-UA' : storageLang;

        const linksObject = [
            {
                id: 'buy',
                uri: `https://www.binance.com/${lang}/how-to-buy/bnb`,
            },
            {
                id: 'withdraw',
                uri: `https://www.binance.com/${lang}/support/faq/how-to-withdraw-from-binance-115003670492`,
            },
            {
                id: 'status',
                uri: `https://www.binance.com/${lang}/support/faq/how-to-check-cryptocurrency-transaction-status-on-blockchain-a6f58adc6f5640f8af08aa70a55760f7`,
            },
        ];
        setLinks(linksObject);
        setLoadingLang(false);
    };
    React.useEffect(() => {
        getLang();
    }, []);

    const onLinkPress = async (uri: string) => {
        triggerMedium();
        try {
            await openUrlOrShowError(uri);
        } catch (error) {
            errorsHandler(error, true);
        }
    };

    const renderResourceItem = (item: { id: string; uri: string }) => {
        return (
            <TouchableOpacity
                key={item.id}
                style={styles.resource}
                activeOpacity={0.8}
                onPress={() => onLinkPress(item.uri)}>
                <View style={styles.right}>
                    <View style={styles.img}>
                        <BinanceIcon width={24} height={24} />
                    </View>
                    <Text style={styles.resourceText}>
                        {i18n.t(`howTo.purchaseBNB.resourceBlock.${item.id}`)}
                    </Text>
                </View>
                <View style={styles.linkImg}>
                    <Icon
                        name={ICON_NAMES.EXPLORE}
                        color={styles.iconGreen.color}
                        size={16}
                    />
                </View>
            </TouchableOpacity>
        );
    };

    if (loadingLang) {
        return null;
    }
    return (
        <View style={styles.main}>
            <View style={styles.header}>
                <NavigationBarModal
                    iconName={ICON_NAMES.CLOSE}
                    title={i18n.t('howTo.purchaseBNB.screenTitle')}
                    onBack={() => navigation.pop()}
                />
            </View>
            <ScrollView
                showsVerticalScrollIndicator={false}
                style={styles.container}>
                <BlockHeader
                    type="primary"
                    title={i18n.t('howTo.purchaseBNB.title')}
                    text={i18n.t('howTo.purchaseBNB.text')}
                />
                <View style={[styles.video, { height }]}>
                    {isWeb ? (
                        <iframe
                            src={VIDEO_LINK}
                            style={{
                                height,
                                borderRadius: moderateScale(8),
                                borderWidth: 0,
                            }}
                        />
                    ) : (
                        <WebView
                            source={{
                                uri: VIDEO_LINK,
                            }}
                            style={isAndroid && { opacity: 0.99 }} // fix android crash
                        />
                    )}
                </View>
                <View style={styles.guideWrapper}>
                    <Text style={styles.title}>
                        {i18n.t('howTo.purchaseBNB.guideBlock.title')}
                    </Text>
                    <View style={styles.guideBlock}>
                        <View style={styles.guide}>
                            <View style={styles.circle} />
                            <Text style={styles.text}>
                                {i18n.t('howTo.purchaseBNB.guideBlock.first')}
                            </Text>
                        </View>
                        <View style={styles.guide}>
                            <View style={styles.circle} />
                            <Text style={styles.text}>
                                {i18n.t('howTo.purchaseBNB.guideBlock.second')}
                            </Text>
                        </View>
                        <View style={styles.guide}>
                            <View style={styles.circle} />
                            <Text style={styles.text}>
                                {i18n.t('howTo.purchaseBNB.guideBlock.third')}
                            </Text>
                        </View>
                        <View style={styles.guide}>
                            <View style={styles.circle} />
                            <Text style={styles.text}>
                                {i18n.t('howTo.purchaseBNB.guideBlock.fourth')}
                            </Text>
                        </View>
                        <View style={styles.guide}>
                            <View style={styles.circle} />
                            <Text style={styles.text}>
                                {i18n.t(
                                    'howTo.purchaseBNB.guideBlock.fiveStepOne'
                                )}
                                <Text style={styles.bold}>
                                    {i18n.t(
                                        'howTo.purchaseBNB.guideBlock.fiveBold'
                                    )}
                                </Text>
                                {i18n.t(
                                    'howTo.purchaseBNB.guideBlock.fiveStepTwo'
                                )}
                            </Text>
                        </View>
                        {walletData && (
                            <View style={styles.walletAddressWrapper}>
                                <WalletAddress
                                    shouldTruncate={false}
                                    containerStyle={
                                        styles.walletAddressContainer
                                    }
                                    textStyle={styles.walletAddressText}
                                    address={walletData.wallet.address}
                                />
                            </View>
                        )}
                        <View style={styles.guide}>
                            <View style={styles.circle} />
                            <Text style={styles.text}>
                                {i18n.t('howTo.purchaseBNB.guideBlock.six')}
                            </Text>
                        </View>
                    </View>
                </View>
                {!!links && (
                    <View style={styles.resourceWrapper}>
                        <Text style={styles.title}>
                            {i18n.t('howTo.purchaseBNB.resourceBlock.title')}
                        </Text>
                        <View style={styles.resourceBlock}>
                            {links.map((item) => renderResourceItem(item))}
                        </View>
                    </View>
                )}
            </ScrollView>
        </View>
    );
};

export default HowToPurchaseBNBScreen;
