import * as React from 'react';
import { SafeAreaView } from 'react-native';

import { useUser } from '@contexts/UserContext';
import { useWallet } from '@contexts/Wallet/WalletContext';

import Animation from '../../../components/Animation';
import Button from '../../../components/Button';
import { Text, View } from '../../../components/Themed';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import { useNavigation } from '../../../navigation/useNavigation';
import { CreateWalletSuccessProps } from '../../../types';
import stylesMain from './styles';

const CreateWalletSuccessScreen = ({ route }: CreateWalletSuccessProps) => {
    const type = route?.params?.type || 'import';
    const navigation = useNavigation();
    const styles = useThemedStyles(stylesMain);
    const { finalizeAddingProcess } = useWallet();
    const { petsCount } = useUser();

    navigation.blockHardwareBack();

    const text = React.useMemo(() => {
        const texts = {
            create: {
                title: i18n.t('createWalletSuccess.title'),
                text: i18n.t('createWalletSuccess.text'),
                button: i18n.t('createWalletSuccess.button'),
                buttonNew: i18n.t('createWalletSuccess.buttonNew'),
            },
            import: {
                title: i18n.t('importWalletSuccess.title'),
                text: i18n.t('importWalletSuccess.text'),
                button: i18n.t('importWalletSuccess.button'),
                buttonNew: i18n.t('importWalletSuccess.buttonNew'),
            },
        };

        return texts[type as 'create' | 'import'];
    }, [type]);

    const handlePress = () => {
        finalizeAddingProcess();
    };

    return (
        <>
            <View style={styles.wrapper}>
                <View style={styles.body}>
                    <View style={styles.animationWrapper}>
                        <Animation
                            animation="dancing"
                            style={styles.animation}
                        />
                    </View>
                    <Text style={styles.title}>{text.title}</Text>
                    <Text style={styles.text}>{text.text}</Text>
                </View>

                <Button
                    title={!petsCount ? text.buttonNew : text.button}
                    onPress={handlePress}
                />
            </View>
            <SafeAreaView style={styles.safe} />
        </>
    );
};

export default CreateWalletSuccessScreen;
