import React, {
    Dispatch,
    FC,
    PropsWithChildren,
    SetStateAction,
    createContext,
    useState,
} from 'react';

type ModalContextType = {
    isVisible: boolean;
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    setModalOpened: () => void;
    setModalClosed: () => void;
};

export const ModalContext = createContext<ModalContextType>({
    isVisible: false,
    setIsVisible: () => undefined,
    setModalOpened: () => undefined,
    setModalClosed: () => undefined,
});

export const useModal = () => React.useContext(ModalContext);

const ModalProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);

    const setModalOpened = () => {
        setIsVisible(true);
    };
    const setModalClosed = () => {
        setIsVisible(false);
    };

    return (
        <ModalContext.Provider
            value={{
                isVisible,
                setIsVisible,
                setModalOpened,
                setModalClosed,
            }}>
            {children}
        </ModalContext.Provider>
    );
};

export default ModalProvider;
