import * as React from 'react';

import CheckoutSuccessScreen from '@components/CheckoutSuccessScreen';
import TransactionSummary from '@components/TransactionSummary';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { priceString } from '@helpers/payments';
import { toastErrorUnknown } from '@helpers/toastNotification';
import { balanceFromWei } from '@helpers/wallet';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

const SCREEN = 'MysteryLikesCreateGameSuccess';

const MysteryLikesCreateGameSuccessScreen = () => {
    const navigation = useNavigation();
    navigation.blockHardwareBack();
    const { currentMysteryLikesGameValue, currentMysteryLikesGameId } =
        useMysteryGames();

    const handleNavigatePlay = React.useCallback(() => {
        if (!currentMysteryLikesGameId) {
            navigation.pop();
            toastErrorUnknown();
            return;
        }
        navigation.replace(ROUTES.MYSTERY_LIKES_SELECT_RANGE, {
            isNewCreated: true,
        });
    }, [currentMysteryLikesGameId]);

    const transactionDetailsValues = [
        {
            key: i18n.t('general.KEYS.type'),
            value: i18n.t(
                'HomeScreen.EnterCoinsAmountScreen.confirmTransaction.valueType',
                {
                    game: i18n.t(
                        `HomeScreen.GamesBlock.games.${MYSTERY_GAMES.mysteryLikes}.title`
                    ),
                }
            ),
        },
        {
            key: i18n.t('checkout.fields.from'),
            value: i18n.t('checkout.fields.gamingWallet'),
        },

        {
            key: i18n.t('checkout.fields.totalSummary'),
            value: priceString(
                balanceFromWei(currentMysteryLikesGameValue).value,
                Coin.vigu
            ),
        },
    ];

    return (
        <CheckoutSuccessScreen
            title={i18n.t(`HomeScreen.MysteryLikes.${SCREEN}.title`)}
            text={i18n.t(`HomeScreen.MysteryLikes.${SCREEN}.text`)}
            onPressButtonMain={handleNavigatePlay}
            buttonMainText={i18n.t('general.BUTTONS.startPlaying')}
            TransactionDetailsComponent={
                <TransactionSummary values={transactionDetailsValues} />
            }
        />
    );
};

export default MysteryLikesCreateGameSuccessScreen;
