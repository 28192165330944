import { Coin } from '@contexts/Wallet/WalletHelpers';

import { PaymentOptionOutput } from '../Data/Models';
import i18n from '../i18n/i18n';
import { isIOS } from './app';
import { balanceFromWei } from './wallet';

export function isPaymentOptionInAppPurchase(
    payment: PaymentOptionOutput | undefined
) {
    return Boolean(payment && payment.productId);
}

export function getPaymentTokenName(token: string) {
    if (token === 'STORE') {
        return i18n.t(
            `checkout.purchasingFromCoins.inapp.${isIOS ? 'ios' : 'android'}`
        );
    }

    return token;
}

export function getPrice(payment: PaymentOptionOutput | undefined) {
    if (!payment) {
        return '';
    }

    if (payment.token === 'STORE') {
        return payment.priceString;
    }

    return `${balanceFromWei(payment.amountWei).valueLong} ${payment?.token}`;
}

export function priceString(
    amount: string | number,
    coin: Coin,
    toFixed?: number
) {
    return `${!!toFixed ? Number(amount).toFixed(toFixed) : amount} ${coin}`;
}

export function priceStringWithUSD(
    usdFunction: (coin: Coin, amount: string) => string,
    weiAmount: string | undefined,
    coin: Coin,
    coin2?: Coin
) {
    return `${priceString(
        balanceFromWei(weiAmount).valueLong,
        coin
    )} ≈ ${usdFunction(coin2 || coin, balanceFromWei(weiAmount).value)}$`;
}
