import * as React from 'react';

import { openUrlOrShowError } from '@helpers/linking';

import Button from '../../../../../components/Button';
import Icon, { ICON_NAMES } from '../../../../../components/Icons';
import { Text } from '../../../../../components/Themed';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import stylesMain from './styles';

interface IProps {
    withLink?: boolean;
}

const BiswapLogo = ({ withLink }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const handlePress = async () => {
        await openUrlOrShowError('https://biswap.org/');
    };

    return (
        <Button
            containerStyle={styles.row}
            style={styles.style}
            onPress={withLink ? handlePress : undefined}>
            <Text style={styles.powerByText}>{i18n.t('trade.poweredBy')}</Text>
            <Icon name={ICON_NAMES.BISWAP} />
        </Button>
    );
};
export default BiswapLogo;
