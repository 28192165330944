import React from 'react';
import Animated, {
    useAnimatedStyle,
    useSharedValue,
    withSpring,
} from 'react-native-reanimated';

import BannerUpdate from '@components/BannerUpdate';
import { verticalScale } from '@helpers/dimensions';
import useThemedStyles from '@hooks/useThemedStyles';

import stylesMain from './styles';

interface IProps {
    onPress: () => void;
    onCloseButton: () => void;
    isVisible: boolean;
}

const config = {
    startPosition: -200,
    endPosition: 110,
};

const AnimatedBannerUpdate = ({
    onPress,
    isVisible,
    onCloseButton,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const bannerShare = useSharedValue(config.startPosition);

    React.useEffect(() => {
        if (isVisible) {
            bannerShare.value = withSpring(verticalScale(config.endPosition));
            return;
        }
        bannerShare.value = withSpring(verticalScale(config.startPosition));
    }, [isVisible]);

    const buttonSharedStyle = useAnimatedStyle(() => {
        return {
            bottom: bannerShare.value,
            zIndex: 1,
        };
    });

    return (
        <Animated.View style={[styles.container, buttonSharedStyle]}>
            <BannerUpdate onPress={onPress} onCloseButton={onCloseButton} />
        </Animated.View>
    );
};

export default AnimatedBannerUpdate;
