import * as React from 'react';
import { FlatList } from 'react-native';

import EmptyList from '@components/EmptyList';
import { ICON_NAMES } from '@components/Icons';
import LoadingComponent from '@components/LoadingComponent';
import { View } from '@components/Themed';
import { stylesAlignCenter, stylesLoadMore } from '@helpers/style';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';
import NavigationBarModal from '@navigation/NavigationBar/NavigationBarModal';
import useNavigationListeners from '@navigation/hooks/useNavigationListeners';
import { useNavigation } from '@navigation/useNavigation';

import { QuantumSpinProbablyFairHistoryProps } from '../../../../../types';
import LuckyStrikeWinnerItem from '../../components/LuckyStrikeWinnerItem';
import LuckyStrikeWinners from '../../components/LuckyStrikeWinners';
import QuantumSpinProbablyFairHistoryItem from '../../components/QuantumSpinProbablyFairHistoryItem';
import { useLuckyStrikeGamesWinners } from '../../hooks/useLuckyStrikeGamesWinners';
import stylesMain from './styles';

const QuantumSpinProbablyFairHistoryScreen = ({
    route,
}: QuantumSpinProbablyFairHistoryProps) => {
    useNavigationListeners();

    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const { history } = route.params;

    const handleBack = () => {
        navigation.pop();
    };

    return (
        <View style={styles.main}>
            <View style={styles.header}>
                <NavigationBarModal
                    iconName={ICON_NAMES.CLOSE}
                    title={i18n.t('general.KEYS.gamesHistory')}
                    onBack={handleBack}
                    titleStyle={styles.title}
                />
            </View>

            <View style={styles.listWrapper}>
                <FlatList
                    showsVerticalScrollIndicator={false}
                    data={history}
                    initialNumToRender={10}
                    renderItem={({ item }) => (
                        <QuantumSpinProbablyFairHistoryItem item={item} />
                    )}
                    keyExtractor={(item) => item.id}
                />
            </View>
        </View>
    );
};

export default QuantumSpinProbablyFairHistoryScreen;
