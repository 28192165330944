import * as React from 'react';
import { FlatList, SafeAreaView } from 'react-native';

import _ from 'lodash';

import EmptyList from '../../../../components/EmptyList';
import LoadingComponent from '../../../../components/LoadingComponent';
import PullToRefresh from '../../../../components/PullToRefresh';
import SafeAreaTabContent from '../../../../components/SafeAreaTabContent';
import { Text, View } from '../../../../components/Themed';
import { stylesLoadMore } from '../../../../helpers/style';
import useTaskHistory from '../../../../hooks/useTaskHistory';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import NavigationBar from '../../../../navigation/NavigationBar';
import ROUTES from '../../../../navigation/routes';
import { useNavigation } from '../../../../navigation/useNavigation';
import { MemoizedHistoryCard } from '../components/HistoryCard';
import stylesMain from './styles';

export const PlayTasksHistoryScreen = () => {
    const styles = useThemedStyles(stylesMain);

    const navigation = useNavigation();

    const [viewHeight, setHeightView] = React.useState<number>(0);

    const {
        getTaskHistory,
        getTaskHistoryLoadMore,
        taskHistoryDataLoading,
        taskHistoryData,
        loadMore,
        pullToRefreshLoading,
    } = useTaskHistory();

    React.useEffect(() => {
        getTaskHistory(true);
    }, []);

    const isLoaded: boolean = React.useMemo(
        () => !!taskHistoryData,
        [taskHistoryData]
    );

    const dataLoading = React.useMemo(
        () => taskHistoryDataLoading,
        [taskHistoryDataLoading]
    );

    const onRefresh = () => {
        getTaskHistory(false);
    };

    const renderItem = React.useCallback(() => {
        return (
            <>
                {!isLoaded && dataLoading ? (
                    <LoadingComponent />
                ) : isLoaded ? (
                    <>
                        {taskHistoryData ? (
                            taskHistoryData.length ? (
                                <>
                                    <FlatList
                                        ListHeaderComponent={
                                            <>
                                                <View style={styles.headerText}>
                                                    <Text style={styles.title}>
                                                        {i18n.t(
                                                            'playTasksHistory.title'
                                                        )}
                                                    </Text>
                                                    <Text style={styles.text}>
                                                        {i18n.t(
                                                            'playTasksHistory.info'
                                                        )}
                                                    </Text>
                                                </View>
                                                {/*<DateTimePickerNative
                                            containerStyle={
                                                styles.mothPicker
                                            }
                                        />*/}
                                            </>
                                        }
                                        onEndReached={getTaskHistoryLoadMore}
                                        onEndReachedThreshold={0.1}
                                        showsVerticalScrollIndicator={false}
                                        data={taskHistoryData}
                                        keyExtractor={(item) => item.id}
                                        renderItem={({ item }) => (
                                            <MemoizedHistoryCard item={item} />
                                        )}
                                    />
                                    {loadMore && (
                                        <View style={stylesLoadMore()}>
                                            <LoadingComponent />
                                        </View>
                                    )}
                                </>
                            ) : (
                                <EmptyList
                                    viewHeight={viewHeight}
                                    emptyTitle={i18n.t(
                                        'playTasksHistory.emptyState.title'
                                    )}
                                    emptyText={i18n.t(
                                        'playTasksHistory.emptyState.text'
                                    )}
                                    emptyButtonText={i18n.t(
                                        'playTasksHistory.emptyState.button'
                                    )}
                                    onPressButton={() => {
                                        navigation.navigate(
                                            ROUTES.PLAY_TASKS_ACTIVE
                                        );
                                    }}
                                    withAnimation="cry"
                                />
                            )
                        ) : null}

                        <SafeAreaView />
                    </>
                ) : null}
            </>
        );
    }, [isLoaded, dataLoading, taskHistoryData, viewHeight, loadMore]);

    return (
        <SafeAreaTabContent>
            <NavigationBar
                backIcon
                currentNavigation={'HISTORY'}
                navigationWidget={'TASKS'}
                containerStyle={styles.navBg}
            />
            <View
                onLayout={(event) =>
                    setHeightView(event.nativeEvent.layout.height)
                }
                style={styles.container}>
                <PullToRefresh
                    renderItem={renderItem}
                    onRefresh={onRefresh}
                    refreshing={pullToRefreshLoading}
                    textLoading={i18n.t('pullToRefresh.reloadTasks.text')}
                />
            </View>
        </SafeAreaTabContent>
    );
};
export default PlayTasksHistoryScreen;
