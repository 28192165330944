import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import Button from '@components/Button';
import { ICON_NAMES } from '@components/Icons';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT, TYPO_TEXT_COLOR } from '@components/TypoText/types';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    containerStyle?: StyleProp<ViewStyle>;
    textType?: TYPO_TEXT;
    textColor?: TYPO_TEXT_COLOR;
    text: string;
    initNumberOfLines?: number;
}

const DropdownText = ({
    containerStyle,
    textColor = TYPO_TEXT_COLOR.SECONDARY,
    textType = TYPO_TEXT.BODY_MEDIUM,
    text,
    initNumberOfLines = 10,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const [numberOfLines, setNumberOfLines] = React.useState<
        number | undefined
    >(initNumberOfLines);

    const [isOpened, setIsOpened] = React.useState<boolean>(false);

    const onPress = React.useCallback(() => {
        if (isOpened) {
            setIsOpened(false);
            setNumberOfLines(initNumberOfLines);
            return;
        }
        setIsOpened(true);
        setNumberOfLines(undefined);
    }, [isOpened, initNumberOfLines]);

    if (!text) {
        return null;
    }

    return (
        <View style={[styles.container, containerStyle]}>
            <TypoText
                numberOfLines={numberOfLines}
                type={textType}
                textAlign="left"
                colorType={textColor}>
                {text}
            </TypoText>
            <Button
                title={
                    isOpened
                        ? i18n.t('general.BUTTONS.hide')
                        : i18n.t('general.BUTTONS.readMore')
                }
                iconName={
                    isOpened
                        ? ICON_NAMES.ARROW_DROP_UP
                        : ICON_NAMES.ARROW_DROP_DOWN
                }
                iconColor={styles.icon.color}
                iconSize={20}
                type="text"
                containerStyle={styles.button}
                size="md"
                onPress={onPress}
                iconDirection="right"
            />
        </View>
    );
};

export default DropdownText;
