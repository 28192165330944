import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: Colors[theme].background,
        },

        walletNftBalanceWrapper: {
            paddingHorizontal: scale(20),
            paddingTop: verticalScale(24),
            backgroundColor: Colors[theme].background,
        },
        walletAddressWrapper: {
            marginTop: verticalScale(20),
            marginHorizontal: scale(40),
            alignItems: 'center',
        },
        buttonsWrapper: {
            marginTop: verticalScale(20),
            alignItems: 'center',
        },
        divider: {
            marginVertical: verticalScale(24),
        },
        listWrapper: {
            paddingHorizontal: scale(20),
        },
        bottomGap: {
            marginBottom: verticalScale(16),
        },
        listStyle: {
            flex: 1,
        },
        banner: {
            marginHorizontal: scale(20),
            marginTop: verticalScale(20),
        },
    });

export default styles;
