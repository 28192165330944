import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        transparent: {
            backgroundColor: 'transparent',
        },
        additionalButton: {
            marginTop: verticalScale(16),
            alignSelf: 'center',
        },
        white: {
            color: Colors[theme].text.white,
        },
    });

export default styles;
