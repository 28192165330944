import * as React from 'react';
import {
    StyleProp,
    TextStyle,
    TouchableOpacity,
    ViewStyle,
} from 'react-native';

import Button from '../../../../../../components/Button';
import { ICON_NAMES } from '../../../../../../components/Icons';
import InfoIcon from '../../../../../../components/InfoIcon/InfoIcon';
import { Text, View } from '../../../../../../components/Themed';
import useThemedStyles from '../../../../../../hooks/useThemedStyles';
import i18n from '../../../../../../i18n/i18n';
import EnergyBlock from '../../../components/EnergyBlock';
import stylesMain from './styles';

interface IProps {
    title: string;
    text: string;
    containerStyle?: StyleProp<ViewStyle>;
    titleStyle?: StyleProp<TextStyle>;
    textStyle?: StyleProp<TextStyle>;
    energy?: number;
    textInfo?: string;
    textInfoOnPress?: () => void;
}

const HeaderText = ({
    title,
    text,
    containerStyle,
    titleStyle,
    textStyle,
    energy,
    textInfo,
    textInfoOnPress,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={[styles.textWrapper, containerStyle]}>
            <View style={styles.titleWrapper}>
                <Text style={[styles.title, titleStyle]}>{title}</Text>
                {!!energy && <EnergyBlock energy={energy} />}
            </View>
            <Text style={[styles.text, textStyle]}>{text}</Text>
            {!!textInfo && (
                <Button
                    onPress={textInfoOnPress}
                    type={'text'}
                    title={textInfo}
                    iconName={ICON_NAMES.QUESTION}
                    iconSize={24}
                    containerStyle={styles.infoWrapper}
                    style={styles.flex}
                />
            )}
        </View>
    );
};
export default HeaderText;
