import React, { Dispatch, SetStateAction } from 'react';

import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import Animation from '../../../../Animation';
import CustomModal from '../../../../CustomModal';
import { View } from '../../../../Themed';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    setIsVisible?: Dispatch<SetStateAction<boolean>>;
    canClose?: boolean;
}

const MaintenanceModal = ({
    isVisible,
    setIsVisible,
    canClose = false,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const closeModal = () => {
        setIsVisible?.(false);
    };

    return (
        <>
            <CustomModal
                isVisible={isVisible}
                icon={
                    <View style={styles.image}>
                        <Animation
                            animation={'maintenance'}
                            style={styles.image}
                        />
                    </View>
                }
                titleText={i18n.t('AppState.FullMaintenanceState.title')}
                infoText={i18n.t('AppState.FullMaintenanceState.text')}
                firstButtonText={canClose ? i18n.t('general.ok') : undefined}
                onFirstButtonClick={canClose ? closeModal : undefined}
            />
        </>
    );
};

export default MaintenanceModal;
