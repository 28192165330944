import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../types';

const stylesMain = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            paddingHorizontal: scale(20),
            paddingTop: verticalScale(16),
        },
        imgWrapper: {
            alignItems: 'center',
            marginBottom: verticalScale(24),
        },
        cardsInfoWrapper: {
            marginTop: verticalScale(20),
        },
    });

export default stylesMain;
