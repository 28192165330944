import { gql } from '@apollo/client';

export const ME = gql`
    query Me {
        petsCount
        me {
            id
            email
            referralCode
            walletAddress
            username
            role
            energy
            charity {
                id
                name
                logoUrl
                description
                country
                city
                friendlyAddress
                website
                instagram
                ethAddress
                updatedAt
                createdAt
                totalDonationsIgupWei
            }
            charityDonationPercentage
            charityChangedAt
            petOrderedIds
            instagram {
                id
                username
                updatedAt
                createdAt
                isVerified
            }
            tiktok {
                id
                updatedAt
                createdAt
                username
                isVerified
            }
            twitter {
                id
                updatedAt
                createdAt
                username
                isVerified
            }
            facebook {
                id
                username
                isVerified
                updatedAt
                createdAt
            }
            mainPet {
                id
                name
                image {
                    url
                    thumbnailUrl
                }
            }
            updatedAt
            createdAt
            isReferred
            directReferralPercentage
            indirectReferralPercentage
            boost
            referralUsageAvailable
        }
    }
`;

export const LOGIN_WITH_GOOGLE = gql`
    mutation LoginWithGoogle($loginWithGoogleInput: LoginWithGoogleInput!) {
        loginWithGoogle(loginWithGoogleInput: $loginWithGoogleInput) {
            access_token
        }
    }
`;

export const LOGIN_WITH_APPLE = gql`
    mutation LoginWithApple($loginWithAppleInput: LoginWithAppleInput!) {
        loginWithApple(loginWithAppleInput: $loginWithAppleInput) {
            access_token
        }
    }
`;

export const LOGIN_WITH_FACEBOOK = gql`
    mutation LoginWithFacebook(
        $loginWithFacebookInput: LoginWithFacebookInput!
    ) {
        loginWithFacebook(loginWithFacebookInput: $loginWithFacebookInput) {
            access_token
        }
    }
`;

export const LOGIN_WITH_TWITTER = gql`
    mutation LoginWithTwitter($loginWithTwitterInput: LoginWithTwitterInput!) {
        loginWithTwitter(loginWithTwitterInput: $loginWithTwitterInput) {
            access_token
        }
    }
`;

export const DELETE_ACCOUNT = gql`
    mutation Mutation {
        deleteMe
    }
`;

export const LINK_INSTAGRAM = gql`
    mutation LinkInstagram($authCode: String!, $redirectUri: String!) {
        linkInstagram(authCode: $authCode, redirectUri: $redirectUri) {
            id
            username
            updatedAt
            createdAt
        }
    }
`;

export const UNLINK_INSTAGRAM = gql`
    mutation Mutation {
        unlinkInstagram
    }
`;

export const LOGIN_WITH_OTP = gql`
    mutation LoginWithOTP($loginWithOtpInput: LoginWithOTPInput!) {
        loginWithOTP(loginWithOTPInput: $loginWithOtpInput) {
            access_token
        }
    }
`;

export const SEND_OTP = gql`
    mutation SendOTP($email: String!) {
        sendOTP(email: $email) {
            id
            email
            resendInSeconds
            updatedAt
            createdAt
        }
    }
`;

export const GET_BACKGROUNDS = gql`
    query getBackgrounds {
        backgrounds {
            id
            en
            ru
            icon
            original
            ranks
        }
    }
`;

export const MARK_IMAGE_UPLOADED = gql`
    mutation MarkImageUploaded(
        $fileId: String!
        $metadata: FileMetadataInput!
    ) {
        markImageUploaded(fileId: $fileId, metadata: $metadata) {
            id
            url
            thumbnailUrl
            type
            status
            signedUploadUrl
            metadata {
                isFromCamera
                isFromGallery
                detectionResult {
                    label
                    confidence
                }
            }
            updatedAt
            createdAt
        }
    }
`;

export const IMAGE_UPLOAD_URL = gql`
    mutation ImageUploadUrl {
        imageUploadUrl {
            id
            url
            thumbnailUrl
            type
            status
            signedUploadUrl
            metadata {
                isFromCamera
                isFromGallery
                detectionResult {
                    label
                    confidence
                }
            }
            updatedAt
            createdAt
        }
    }
`;
export const CREATE_PET = gql`
    mutation CreatePet($createPetInput: CreatePetInput!) {
        createPet(createPetInput: $createPetInput) {
            signedTransaction {
                contractAddress
                execTransactionValuesStringified
            }
            payment {
                token
                tokenAddress
                amountWei
                productId
            }
            petId
        }
    }
`;

export const COMPOSE_NFT_IMAGE = gql`
    mutation ComposeNftImage($composeNftImageInput: ComposeNftImageInput!) {
        composeNftImage(composeNftImageInput: $composeNftImageInput) {
            id
            url
            thumbnailUrl
            type
            status
            signedUploadUrl
            metadata {
                isFromCamera
                isFromGallery
                detectionResult {
                    label
                    confidence
                }
            }
            updatedAt
            createdAt
        }
    }
`;

export const CREATE_NFT_IMAGE_FROM_GENERATED_IMAGE = gql`
    mutation CreateNftImageFromGeneratedImage(
        $createNftImageFromGeneratedImageId: String!
    ) {
        createNftImageFromGeneratedImage(
            id: $createNftImageFromGeneratedImageId
        ) {
            id
            filename
            url
            thumbnailUrl
            type
            status
            signedUploadUrl
            metadata {
                isFromCamera
                isFromGallery
                detectionResult {
                    label
                    confidence
                }
            }
            updatedAt
            createdAt
        }
    }
`;

export const GET_PETS = gql`
    query Pets {
        pets {
            id
            name
            image {
                id
                filename
                url
                thumbnailUrl
                type
                status
                signedUploadUrl
                metadata {
                    isFromCamera
                    isFromGallery
                    detectionResult {
                        label
                        confidence
                    }
                    backgroundId
                    text2ImageTaskId
                }
                updatedAt
                createdAt
            }
            rank
            tokenId
            level
            experience
            health
            likeCount
            dislikeCount
            currentOwnerAddress
            creatorAddress
            lastTransferAt
            updatedAt
            createdAt
            isMinted
            cooldownExpiresAt
            levelProgressPercentage
            healthPercentage
            currentLevelMaxExperience
            explorerUrl
            levelUpAvailable
            rankUpAvailable
            reviveAvailable
            healUpAvailable
            dead
            levelRewardBoostPercentage
            age
            ageDays
            ageDead
            reviveAgeAvailable
            maxAgeDays
            rewardUnlockDaysPeriod
        }
    }
`;

export const GET_RANKS = gql`
    query Ranks {
        ranks {
            id
            slug
            available
            baseIgupRewardWei
            rewardUnlockDaysPeriod
            dailyUnlockRate
            rewardConsumptionPercentage
            levelRewardModifier
            payments {
                token
                tokenAddress
                amountWei
                productId
            }
            whitelistFreeMintsCount
            mintPriceUsd
            maxPetLevel
            lootboxFreeMintsCount
            mintBonus {
                endsAt
                igupAmountWei
                lockedPeriodDays
            }
            maxAgeDays
        }
    }
`;

export const VERIFY_WALLET = gql`
    mutation VerifyWallet($signature: String!) {
        verifyWallet(signature: $signature) {
            id
            email
            referralCode
            walletAddress
            role
            energy
            charity {
                id
                name
                logoUrl
                description
                country
                city
                friendlyAddress
                website
                instagram
                ethAddress
                updatedAt
                createdAt
                totalDonationsIgupWei
            }
            charityDonationPercentage
            charityChangedAt
            petOrderedIds
            instagram {
                id
                username
                updatedAt
                createdAt
            }
            tiktok {
                id
                updatedAt
                createdAt
                username
                isVerified
            }
            mainPet {
                id
                name
                image {
                    url
                    thumbnailUrl
                }
            }
            updatedAt
            createdAt
            isReferred
            directReferralPercentage
            indirectReferralPercentage
            boost
        }
    }
`;

export const MARK_REFERRER = gql`
    mutation MarkReferrer($referralCode: String!) {
        markReferrer(referralCode: $referralCode) {
            id
            email
            referralCode
            walletAddress
            role
            energy
            charity {
                id
                name
                logoUrl
                description
                country
                city
                friendlyAddress
                website
                instagram
                ethAddress
                updatedAt
                createdAt
                totalDonationsIgupWei
            }
            charityDonationPercentage
            charityChangedAt
            petOrderedIds
            instagram {
                id
                username
                updatedAt
                createdAt
            }
            tiktok {
                id
                updatedAt
                createdAt
                username
                isVerified
            }
            mainPet {
                id
                name
                image {
                    url
                    thumbnailUrl
                }
            }
            updatedAt
            createdAt
            isReferred
            directReferralPercentage
            indirectReferralPercentage
            boost
        }
    }
`;

export const GET_CONFIG = gql`
    query Config {
        config {
            isFullMaintenance
            isGameMaintenance
            paymentsAvailable
            version
            androidVersion
            iosVersion
            newIosThemeVersion
            maxActiveNFTsPerUser
            rpcPublicProviders
            chainId
            mintConfirmationsCount
            nftIguverseContractAddress
            iguTokenContractAddress
            igupContractAddress
            boosterContractAddress
            blockExplorerURL
            gameIterationUTCHour
            nextGameIterationDate
            googleWebClientId
            googleAndroidClientId
            googleIosClientId
            facebookAppId
            instagramClientId
            twitterClientId
            intercomAppId
            privacyPolicyUrl
            termsOfUseUrl
            knowledgeBaseUrl
            supportEmail
            swapRouterContractAddress
            busdContractAddress
            wbnbContractAddress
            tokenDecimals
            lootboxContractAddress
            estimatedGasUse {
                bnb {
                    transfer
                }
                busd {
                    approve
                    transfer
                }
                igu {
                    approve
                    transfer
                }
                igup {
                    transfer
                }
                nft {
                    execTransaction
                    transferFrom
                }
            }
            userBoosts {
                id
                minReferralsCount
                rewardBonusPercentage
            }
            rewardEnergyThreshold
            iguDisabled
            igupDisabled
            tradeSupportedTokens
            enabledSocialPlatforms
            petIdType
            priceIncrease {
                amount
                startsAt
            }
            petAgeReviveBasePriceDiscountPercentage
            listing {
                exchange
                startsAt
                url
            }
            iguWalletContractAddress
            iguWalletWithdrawFeePercentage
            iguWithdrawDisabled
            iguDepositBonus {
                endsAt
                bonusPercentage
            }
            marketplaceContractAddress
            marketplaceMaxPrice
            marketplaceMinPrice
            marketplaceMinimalRank
            marketplaceTerminationDays
            marketplaceTransactionFeePercentage
            gasPriceConfig {
                defaultGasPriceGwei
                gasPriceMiltiplier
            }
        }
    }
`;

export const GET_TOKEN_TRANSACTIONS = gql`
    query TokenTransactions(
        $contractAddress: String
        $bscscanPaginationInput: BscscanPaginaitonInput
    ) {
        tokenTransactions(
            contractAddress: $contractAddress
            bscscanPaginationInput: $bscscanPaginationInput
        ) {
            from
            to
            value
            timeStamp
            status
            explorerUrl
            tokenID
            hash
            txCost
            type
        }
    }
`;

export const GET_AI_IMAGES = gql`
    query Text2Image($text2ImageInput: [Text2ImageInput!]!) {
        text2Image(text2ImageInput: $text2ImageInput) {
            id
            imageUrl
        }
    }
`;

export const GET_EXCHANGES = gql`
    query Exchanges {
        exchanges {
            id
            name
            url
            tokens
            iconUrl
        }
    }
`;

export const GET_EXCHANGES_RATES = gql`
    query ExchangeRates {
        exchangeRates {
            bnbusd
            bnbbusd
            busdbnb
            igubnb
            igupbnb
            busdusd
            iguusd
            igupusd
        }
    }
`;

export const GET_IMAGINATION_FORM_DATA = gql`
    query Text2ImageData {
        text2ImageData {
            id
            name
            isRequired
            maxSelections
            options {
                id
                name
                icon
            }
        }
    }
`;

export const SET_PET_ORDERED_IDS = gql`
    mutation SetOrderedPets($petOrderedIds: [String!]!) {
        setOrderedPets(petOrderedIds: $petOrderedIds) {
            petOrderedIds
        }
    }
`;

export const CREATE_SOCIAL_TASK = gql`
    mutation CreateSocialTask($socialPlatform: String!) {
        createSocialTask(socialPlatform: $socialPlatform) {
            id
            maxEnergyReward
            energyRewarded
            type
            status
            failureReason
            petExperienceRewards {
                id
                experienceIncrement
                pet {
                    id
                    name
                    image {
                        id
                        filename
                        url
                        thumbnailUrl
                        type
                        status
                        signedUploadUrl
                        metadata {
                            isFromCamera
                            isFromGallery
                            detectionResult {
                                label
                                confidence
                            }
                            backgroundId
                            text2ImageTaskId
                        }
                        updatedAt
                        createdAt
                    }
                    rank
                    tokenId
                    level
                    experience
                    health
                    likeCount
                    dislikeCount
                    currentOwnerAddress
                    creatorAddress
                    lastTransferAt
                    updatedAt
                    createdAt
                    isMinted
                    cooldownExpiresAt
                    levelProgressPercentage
                    currentLevelMaxExperience
                    explorerUrl
                    levelUpAvailable
                }
                updatedAt
                createdAt
            }
            updatedAt
            createdAt
            token
            platform
            platformUsername
            pet {
                id
                name
                image {
                    id
                    filename
                    url
                    thumbnailUrl
                    type
                    status
                    signedUploadUrl
                    thumbnailUrl
                    metadata {
                        isFromCamera
                        isFromGallery
                        detectionResult {
                            label
                            confidence
                        }
                        backgroundId
                        text2ImageTaskId
                    }
                    updatedAt
                    createdAt
                }
                rank
                tokenId
                level
                experience
                health
                likeCount
                dislikeCount
                currentOwnerAddress
                creatorAddress
                lastTransferAt
                updatedAt
                createdAt
                isMinted
                cooldownExpiresAt
                levelProgressPercentage
                currentLevelMaxExperience
                explorerUrl
                levelUpAvailable
            }
            sharedAt
        }
    }
`;

export const CREATE_MOVE_TASK = gql`
    mutation CreateMoveTask {
        createMoveTask {
            id
            maxEnergyReward
            energyRewarded
            type
            status
            failureReason
            petExperienceRewards {
                id
                experienceIncrement
                pet {
                    id
                    name
                    image {
                        id
                        filename
                        url
                        thumbnailUrl
                        type
                        status
                        signedUploadUrl
                        metadata {
                            isFromCamera
                            isFromGallery
                            detectionResult {
                                label
                                confidence
                            }
                            backgroundId
                            text2ImageTaskId
                        }
                        updatedAt
                        createdAt
                    }
                    rank
                    tokenId
                    level
                    experience
                    health
                    likeCount
                    dislikeCount
                    currentOwnerAddress
                    creatorAddress
                    lastTransferAt
                    updatedAt
                    createdAt
                    isMinted
                    cooldownExpiresInSeconds
                    cooldownExpiresAt
                    levelProgressPercentage
                    currentLevelMaxExperience
                    explorerUrl
                    levelUpAvailable
                    rankUpAvailable
                    reviveAvailable
                    healthPercentage
                    dead
                }
                updatedAt
                createdAt
            }
            updatedAt
            createdAt
            maxDistanceMeters
            maxTimeSeconds
            elapsedTimeSeconds
            traveledDistanceMeters
        }
    }
`;

export const CREATE_PLAY_TASK = gql`
    mutation CreatePlayTask {
        createPlayTask {
            id
            status
            maxEnergyReward
            maxPoints
            gainedPoints
            energyRewarded
            updatedAt
            createdAt
        }
    }
`;

export const MARK_SOCIAL_TASK_AS_COMPLETED = gql`
    mutation MarkSocialTaskAsCompleted($socialTaskId: String!) {
        markSocialTaskAsCompleted(socialTaskId: $socialTaskId) {
            id
            maxEnergyReward
            energyRewarded
            type
            status
            failureReason
            petExperienceRewards {
                id
                experienceIncrement
                pet {
                    id
                    name
                    image {
                        id
                        filename
                        url
                        thumbnailUrl
                        type
                        status
                        signedUploadUrl
                        metadata {
                            isFromCamera
                            isFromGallery
                            detectionResult {
                                label
                                confidence
                            }
                            backgroundId
                            text2ImageTaskId
                        }
                        updatedAt
                        createdAt
                    }
                    rank
                    tokenId
                    level
                    experience
                    health
                    likeCount
                    dislikeCount
                    currentOwnerAddress
                    creatorAddress
                    lastTransferAt
                    updatedAt
                    createdAt
                    isMinted
                    cooldownExpiresAt
                    levelProgressPercentage
                    currentLevelMaxExperience
                    explorerUrl
                    levelUpAvailable
                }
                updatedAt
                createdAt
            }
            updatedAt
            createdAt
            token
            platform
            platformUsername
            pet {
                id
                name
                image {
                    id
                    filename
                    url
                    thumbnailUrl
                    type
                    status
                    signedUploadUrl
                    metadata {
                        isFromCamera
                        isFromGallery
                        detectionResult {
                            label
                            confidence
                        }
                        backgroundId
                        text2ImageTaskId
                    }
                    updatedAt
                    createdAt
                }
                rank
                tokenId
                level
                experience
                health
                likeCount
                dislikeCount
                currentOwnerAddress
                creatorAddress
                lastTransferAt
                updatedAt
                createdAt
                isMinted
                cooldownExpiresAt
                levelProgressPercentage
                currentLevelMaxExperience
                explorerUrl
                levelUpAvailable
            }
            sharedAt
        }
    }
`;

export const CREATE_VERIFICATION_TASK = gql`
    mutation CreateVerificationTask {
        createVerificationTask {
            id
            status
            failureReason
            maxEnergyReward
            energyRewarded
            isRated
            petExperienceRewards {
                id
                experienceIncrement
                pet {
                    id
                    name
                    image {
                        id
                        filename
                        url
                        thumbnailUrl
                        type
                        status
                        signedUploadUrl
                        metadata {
                            isFromCamera
                            isFromGallery
                            detectionResult {
                                label
                                confidence
                            }
                            backgroundId
                            text2ImageTaskId
                        }
                        updatedAt
                        createdAt
                    }
                    rank
                    tokenId
                    level
                    experience
                    health
                    likeCount
                    dislikeCount
                    currentOwnerAddress
                    creatorAddress
                    lastTransferAt
                    updatedAt
                    createdAt
                    isMinted
                    cooldownExpiresAt
                    levelProgressPercentage
                    currentLevelMaxExperience
                    explorerUrl
                    levelUpAvailable
                    reviveAvailable
                    healthPercentage
                    dead
                }
                updatedAt
                createdAt
            }
            socialTask {
                id
                platform
                platformUsername
                pet {
                    id
                    name
                    image {
                        id
                        filename
                        url
                        thumbnailUrl
                        type
                        status
                        signedUploadUrl
                        metadata {
                            isFromCamera
                            isFromGallery
                            detectionResult {
                                label
                                confidence
                            }
                            backgroundId
                            text2ImageTaskId
                        }
                        updatedAt
                        createdAt
                    }
                    rank
                    tokenId
                    level
                    experience
                    health
                    likeCount
                    dislikeCount
                    currentOwnerAddress
                    creatorAddress
                    lastTransferAt
                    updatedAt
                    createdAt
                    isMinted
                    cooldownExpiresAt
                    levelProgressPercentage
                    currentLevelMaxExperience
                    explorerUrl
                }
            }
            updatedAt
            createdAt
            remainingVerificationAttempts
        }
    }
`;

export const COMPLETE_VERIFICATION_SUBTASK = gql`
    mutation CompleteVerificationTask($taskId: String!, $token: String) {
        completeVerificationTask(taskId: $taskId, token: $token) {
            status
            remainingVerificationAttempts
        }
    }
`;

export const GET_PlAY_TASKS_CONFIG = gql`
    query Query {
        socialTaskConfig {
            canStart
            maxEnergyReward
            latestTask {
                id
                status
                failureReason
                moderatorRejectReason
                maxEnergyReward
                energyRewarded
                token
                platform
                platformUsername
                pet {
                    id
                    name
                    image {
                        id
                        filename
                        url
                        thumbnailUrl
                        type
                        status
                        signedUploadUrl
                        metadata {
                            isFromCamera
                            isFromGallery
                            detectionResult {
                                label
                                confidence
                            }
                            backgroundId
                            text2ImageTaskId
                        }
                        updatedAt
                        createdAt
                    }
                    rank
                    tokenId
                    level
                    experience
                    health
                    likeCount
                    dislikeCount
                    currentOwnerAddress
                    creatorAddress
                    lastTransferAt
                    updatedAt
                    createdAt
                    isMinted
                    cooldownExpiresAt
                    levelProgressPercentage
                    currentLevelMaxExperience
                    explorerUrl
                }
                petExperienceRewards {
                    id
                    experienceIncrement
                    pet {
                        id
                        name
                        image {
                            id
                            filename
                            url
                            thumbnailUrl
                            type
                            status
                            signedUploadUrl
                            metadata {
                                isFromCamera
                                isFromGallery
                                detectionResult {
                                    label
                                    confidence
                                }
                                backgroundId
                                text2ImageTaskId
                            }
                            updatedAt
                            createdAt
                        }
                        rank
                        tokenId
                        level
                        experience
                        health
                        likeCount
                        dislikeCount
                        currentOwnerAddress
                        creatorAddress
                        lastTransferAt
                        updatedAt
                        createdAt
                        isMinted
                        cooldownExpiresAt
                        levelProgressPercentage
                        currentLevelMaxExperience
                        explorerUrl
                        levelUpAvailable
                        reviveAvailable
                        healthPercentage
                        dead
                    }
                    updatedAt
                    createdAt
                }
                sharedAt
                updatedAt
                createdAt
            }
        }
        verificationTaskConfig {
            status
            canStart
            maxEnergyReward
            totalEnergyRewarded
            currentTask {
                id
                status
                failureReason
                maxEnergyReward
                energyRewarded
                isRated
                petExperienceRewards {
                    id
                    experienceIncrement
                    pet {
                        id
                        name
                        image {
                            id
                            filename
                            url
                            thumbnailUrl
                            type
                            status
                            signedUploadUrl
                            metadata {
                                isFromCamera
                                isFromGallery
                                detectionResult {
                                    label
                                    confidence
                                }
                                backgroundId
                                text2ImageTaskId
                            }
                            updatedAt
                            createdAt
                        }
                        rank
                        tokenId
                        level
                        experience
                        health
                        likeCount
                        dislikeCount
                        currentOwnerAddress
                        creatorAddress
                        lastTransferAt
                        updatedAt
                        createdAt
                        isMinted
                        cooldownExpiresAt
                        levelProgressPercentage
                        currentLevelMaxExperience
                        explorerUrl
                        levelUpAvailable
                        reviveAvailable
                        healthPercentage
                        dead
                    }
                    updatedAt
                    createdAt
                }
                socialTask {
                    id
                    platform
                    platformUsername
                    pet {
                        id
                        name
                        image {
                            id
                            filename
                            url
                            thumbnailUrl
                            type
                            status
                            signedUploadUrl
                            metadata {
                                isFromCamera
                                isFromGallery
                                detectionResult {
                                    label
                                    confidence
                                }
                                backgroundId
                                text2ImageTaskId
                            }
                            updatedAt
                            createdAt
                        }
                        rank
                        tokenId
                        level
                        experience
                        health
                        likeCount
                        dislikeCount
                        currentOwnerAddress
                        creatorAddress
                        lastTransferAt
                        updatedAt
                        createdAt
                        isMinted
                        cooldownExpiresAt
                        levelProgressPercentage
                        currentLevelMaxExperience
                        explorerUrl
                    }
                }
                updatedAt
                createdAt
                remainingVerificationAttempts
            }
            tasks {
                id
                status
                failureReason
                maxEnergyReward
                energyRewarded
                isRated
                petExperienceRewards {
                    id
                    experienceIncrement
                    pet {
                        id
                        name
                        image {
                            id
                            filename
                            url
                            thumbnailUrl
                            type
                            status
                            signedUploadUrl
                            metadata {
                                isFromCamera
                                isFromGallery
                                detectionResult {
                                    label
                                    confidence
                                }
                                backgroundId
                                text2ImageTaskId
                            }
                            updatedAt
                            createdAt
                        }
                        rank
                        tokenId
                        level
                        experience
                        health
                        likeCount
                        dislikeCount
                        currentOwnerAddress
                        creatorAddress
                        lastTransferAt
                        updatedAt
                        createdAt
                        isMinted
                        cooldownExpiresAt
                        levelProgressPercentage
                        currentLevelMaxExperience
                        explorerUrl
                        levelUpAvailable
                        reviveAvailable
                        healthPercentage
                        dead
                    }
                    updatedAt
                    createdAt
                }
                socialTask {
                    id
                    platform
                    platformUsername
                    pet {
                        id
                        name
                        image {
                            id
                            filename
                            url
                            thumbnailUrl
                            type
                            status
                            signedUploadUrl
                            metadata {
                                isFromCamera
                                isFromGallery
                                detectionResult {
                                    label
                                    confidence
                                }
                                backgroundId
                                text2ImageTaskId
                            }
                            updatedAt
                            createdAt
                        }
                        rank
                        tokenId
                        level
                        experience
                        health
                        likeCount
                        dislikeCount
                        currentOwnerAddress
                        creatorAddress
                        lastTransferAt
                        updatedAt
                        createdAt
                        isMinted
                        cooldownExpiresAt
                        levelProgressPercentage
                        currentLevelMaxExperience
                        explorerUrl
                    }
                }
                updatedAt
                createdAt
                remainingVerificationAttempts
            }
        }
        playTaskConfig {
            canStart
            maxEnergyReward
            currentTask {
                id
                status
                maxEnergyReward
                maxPoints
                gainedPoints
                energyRewarded
                updatedAt
                createdAt
            }
        }
        moveTaskConfig {
            canStart
            maxEnergyReward
            currentTask {
                id
                maxEnergyReward
                energyRewarded
                type
                status
                failureReason
                petExperienceRewards {
                    id
                    experienceIncrement
                    pet {
                        id
                        name
                        image {
                            id
                            filename
                            url
                            thumbnailUrl
                            type
                            status
                            signedUploadUrl
                            metadata {
                                isFromCamera
                                isFromGallery
                                detectionResult {
                                    label
                                    confidence
                                }
                                backgroundId
                                text2ImageTaskId
                            }
                            updatedAt
                            createdAt
                        }
                        rank
                        tokenId
                        level
                        experience
                        health
                        likeCount
                        dislikeCount
                        currentOwnerAddress
                        creatorAddress
                        lastTransferAt
                        updatedAt
                        createdAt
                        isMinted
                        cooldownExpiresInSeconds
                        cooldownExpiresAt
                        levelProgressPercentage
                        currentLevelMaxExperience
                        explorerUrl
                        levelUpAvailable
                        rankUpAvailable
                        reviveAvailable
                        healthPercentage
                        dead
                    }
                    updatedAt
                    createdAt
                }
                updatedAt
                createdAt
                maxDistanceMeters
                maxTimeSeconds
                elapsedTimeSeconds
                traveledDistanceMeters
            }
        }
    }
`;

export const LINK_TIKTOK = gql`
    mutation LinkTiktokUsername($username: String!) {
        linkTiktokUsername(username: $username) {
            id
            username
            isVerified
            updatedAt
            createdAt
        }
    }
`;

export const UNLINK_TIKTOK = gql`
    mutation Mutation {
        unlinkTiktok
    }
`;

export const ITERATE_GAME = gql`
    mutation Mutation {
        iterateGame
    }
`;

export const FAIL_UNCOMPLETED_SOCIAL_TASKS = gql`
    mutation Mutation {
        failUncompletedSocialTasks
    }
`;

export const MARK_MOVE_TASK_DONE = gql`
    mutation MarkMoveTaskDone(
        $taskId: String!
        $elapsedTimeSeconds: Float!
        $traveledDistanceMeters: Float!
        $signature: String!
    ) {
        markMoveTaskDone(
            taskId: $taskId
            elapsedTimeSeconds: $elapsedTimeSeconds
            traveledDistanceMeters: $traveledDistanceMeters
            signature: $signature
        ) {
            id
            maxEnergyReward
            energyRewarded
            type
            status
            failureReason
            petExperienceRewards {
                id
                experienceIncrement
                pet {
                    id
                    name
                    image {
                        id
                        filename
                        url
                        thumbnailUrl
                        type
                        status
                        signedUploadUrl
                        metadata {
                            isFromCamera
                            isFromGallery
                            detectionResult {
                                label
                                confidence
                            }
                            backgroundId
                            text2ImageTaskId
                        }
                        updatedAt
                        createdAt
                    }
                    rank
                    tokenId
                    level
                    experience
                    health
                    likeCount
                    dislikeCount
                    currentOwnerAddress
                    creatorAddress
                    lastTransferAt
                    updatedAt
                    createdAt
                    isMinted
                    cooldownExpiresInSeconds
                    cooldownExpiresAt
                    levelProgressPercentage
                    currentLevelMaxExperience
                    explorerUrl
                    levelUpAvailable
                    rankUpAvailable
                    reviveAvailable
                    healthPercentage
                    dead
                }
                updatedAt
                createdAt
            }
            updatedAt
            createdAt
            maxDistanceMeters
            maxTimeSeconds
            elapsedTimeSeconds
            traveledDistanceMeters
        }
    }
`;

export const MARK_PLAY_TASK_DONE = gql`
    mutation MarkPlayTaskDone(
        $taskId: String!
        $signature: String!
        $gainedPoints: Float!
        $livesCount: Float!
        $fruitsCount: Float!
    ) {
        markPlayTaskDone(
            taskId: $taskId
            signature: $signature
            gainedPoints: $gainedPoints
            livesCount: $livesCount
            fruitsCount: $fruitsCount
        ) {
            id
            maxEnergyReward
            energyRewarded
            type
            status
            failureReason
            petExperienceRewards {
                id
                experienceIncrement
                pet {
                    id
                    name
                    image {
                        id
                        filename
                        url
                        thumbnailUrl
                        type
                        status
                        signedUploadUrl
                        metadata {
                            isFromCamera
                            isFromGallery
                            detectionResult {
                                label
                                confidence
                            }
                            backgroundId
                            text2ImageTaskId
                        }
                        updatedAt
                        createdAt
                    }
                    rank
                    tokenId
                    level
                    experience
                    health
                    likeCount
                    dislikeCount
                    currentOwnerAddress
                    creatorAddress
                    lastTransferAt
                    updatedAt
                    createdAt
                    isMinted
                    cooldownExpiresInSeconds
                    cooldownExpiresAt
                    levelProgressPercentage
                    currentLevelMaxExperience
                    explorerUrl
                    levelUpAvailable
                    rankUpAvailable
                    reviveAvailable
                    healthPercentage
                    dead
                }
                updatedAt
                createdAt
            }
            updatedAt
            createdAt
            maxPoints
            gainedPoints
            livesCount
            fruitsCount
        }
    }
`;

export const RATE_VERIFICATION_TASK_PET = gql`
    mutation RateVerificationTaskPet(
        $taskId: String!
        $rate: VerificationTaskPetRate!
    ) {
        rateVerificationTaskPet(taskId: $taskId, rate: $rate)
    }
`;

export const GET_TASK_HISTORY = gql`
    query TasksHistory($paginationInput: TaskPaginationInput!) {
        tasksHistory(paginationInput: $paginationInput) {
            items {
                id
                maxEnergyReward
                energyRewarded
                type
                status
                failureReason
                petExperienceRewards {
                    id
                    experienceIncrement
                    updatedAt
                    createdAt
                }
                updatedAt
                createdAt
            }
            meta {
                itemCount
                totalItems
                itemsPerPage
                totalPages
                currentPage
            }
        }
    }
`;

export const CHARITIES = gql`
    query Charities {
        charities {
            id
            name
            logoUrl
            totalDonationsIgupWei
            description
            country
            city
            friendlyAddress
            website
            instagram
            twitter
            email
            ethAddress
            updatedAt
            createdAt
        }
    }
`;

export const SET_CHARITY = gql`
    mutation SetCharity($charityId: String!, $donationPercent: Float!) {
        setCharity(charityId: $charityId, donationPercent: $donationPercent) {
            id
        }
    }
`;

export const GET_LEVELUP_PAYMENTS = gql`
    query LevelupPayments($petId: String!) {
        levelupPayments(petId: $petId) {
            token
            tokenAddress
            amountWei
        }
    }
`;

export const CREATE_LEVELUP_PAYMENT = gql`
    mutation CreateLevelUpPayment($petId: String!, $token: String!) {
        createLevelUpPayment(petId: $petId, token: $token) {
            signedTransaction {
                contractAddress
                execTransactionValuesStringified
            }
            payment {
                token
                tokenAddress
                amountWei
            }
        }
    }
`;

export const GET_RANK_UP_PAYMENTS = gql`
    query rankupPayments($petId: String!) {
        rankupPayments(petId: $petId) {
            token
            tokenAddress
            amountWei
        }
    }
`;

export const CREATE_RANK_UP_PAYMENT = gql`
    mutation CreateRankUpPayment($petId: String!, $token: String!) {
        createRankUpPayment(petId: $petId, token: $token) {
            signedTransaction {
                contractAddress
                execTransactionValuesStringified
            }
            payment {
                token
                tokenAddress
                amountWei
            }
        }
    }
`;

export const GET_DONATIONS_HISTORY = gql`
    query Query($charityId: String!, $paginationInput: PaginationInput!) {
        donationsHistory(
            charityId: $charityId
            paginationInput: $paginationInput
        ) {
            items {
                id
                igupAmountWei
                updatedAt
                createdAt
                user {
                    mainPet {
                        image {
                            url
                            thumbnailUrl
                        }
                    }
                    username
                    walletAddress
                }
                txExplorerUrl
            }
            meta {
                itemCount
                totalItems
                itemsPerPage
                totalPages
                currentPage
            }
        }
    }
`;
export const GET_TRANSACTION_HISTORY = gql`
    query TransactionHistory(
        $paginationInput: VirtualWalletTransactionPaginationInput!
    ) {
        transactionHistory(paginationInput: $paginationInput) {
            items {
                id
                type
                igupAmountWei
                childTransactions {
                    id
                    type
                    igupAmountWei
                    unlockAt
                    createdAt
                    unlockedAmountWei
                    locked
                }
                unlockAt
                createdAt
                unlockedAmountWei
                locked
            }
            meta {
                itemCount
                totalItems
                itemsPerPage
                totalPages
                currentPage
            }
        }
    }
`;
export const GET_USER_BALANCE = gql`
    query UserBalance {
        userBalance {
            total
            available
            locked
        }
        pendingBalanceClaim {
            paymentOption {
                token
                tokenAddress
                amountWei
            }
            signedTransaction {
                contractAddress
                execTransactionValuesStringified
            }
        }
    }
`;

export const CLAIM_BALANCE = gql`
    mutation ClaimBalance {
        claimBalance {
            paymentOption {
                token
                tokenAddress
                amountWei
            }
            signedTransaction {
                contractAddress
                execTransactionValuesStringified
            }
        }
    }
`;

export const PENDING_CLAIM_BALANCE = gql`
    query PendingBalanceClaim {
        pendingBalanceClaim {
            paymentOption {
                token
                tokenAddress
                amountWei
            }
            signedTransaction {
                contractAddress
                execTransactionValuesStringified
            }
        }
    }
`;

export const GET_MODERATION_SOCIAL_TASK = gql`
    query ModerationSocialTask {
        moderationSocialTask {
            id
            platform
            platformUsername
            pet {
                id
                name
                image {
                    id
                    filename
                    url
                    thumbnailUrl
                    type
                    status
                    signedUploadUrl
                    metadata {
                        isFromCamera
                        isFromGallery
                        detectionResult {
                            label
                            confidence
                        }
                        backgroundId
                        text2ImageTaskId
                    }
                    updatedAt
                    createdAt
                }
                rank
                tokenId
                level
                experience
                health
                likeCount
                dislikeCount
                currentOwnerAddress
                creatorAddress
                lastTransferAt
                updatedAt
                createdAt
                isMinted
                cooldownExpiresAt
                levelProgressPercentage
                currentLevelMaxExperience
                explorerUrl
            }
        }
    }
`;

export const GET_MODERATION_SOCIAL_TASKS = gql`
    query ModerationSocialTasks {
        moderationSocialTasks {
            id
            platform
            platformUsername
            pet {
                tokenId
                id
            }
        }
    }
`;

export const MODERATE_SOCIAL_TASK = gql`
    mutation ModerateSocialTask(
        $taskId: String!
        $token: String
        $moderatorRejectReason: ModeratorRejectReason
    ) {
        moderateSocialTask(
            taskId: $taskId
            token: $token
            moderatorRejectReason: $moderatorRejectReason
        )
    }
`;

export const GET_REFERRAL_TRANSACTIONS = gql`
    query ReferralTransactions($paginationInput: PaginationInput!) {
        referralTransactions(paginationInput: $paginationInput) {
            items {
                id
                referred {
                    username
                    walletAddress
                    mainPet {
                        id
                        name
                        image {
                            thumbnailUrl
                        }
                    }
                }
                virtualWalletTransaction {
                    id
                    type
                    igupAmountWei
                    createdAt
                }
            }
            meta {
                itemCount
                totalItems
                itemsPerPage
                totalPages
                currentPage
            }
        }
    }
`;

export const GET_RANK_ME = gql`
    query RankingMe($rankingInterval: RankingInterval!) {
        rankingMe(rankingInterval: $rankingInterval) {
            rank
            totalTasksCount
            totalEnergyRewarded
        }
    }
`;

export const GET_GAME_MAINTENANCE_INFO = gql`
    query GameMaintenance {
        config {
            isGameMaintenance
        }
    }
`;

export const GET_FULL_MAINTENANCE_INFO = gql`
    query FullMaintenance {
        config {
            isFullMaintenance
        }
    }
`;

export const GET_REFERRAL_USERS = gql`
    query ReferralUsers($referralsPaginationInput: ReferralsPaginationInput!) {
        referralUsers(referralsPaginationInput: $referralsPaginationInput) {
            items {
                active
                id
                user {
                    username
                    walletAddress
                    mainPet {
                        id
                        name
                        image {
                            id
                            filename
                            url
                            thumbnailUrl
                            type
                            status
                            signedUploadUrl
                            metadata {
                                isFromCamera
                                isFromGallery
                                detectionResult {
                                    label
                                    confidence
                                }
                                backgroundId
                                text2ImageTaskId
                            }
                            updatedAt
                            createdAt
                        }
                        rank
                        tokenId
                        level
                        experience
                        health
                        likeCount
                        dislikeCount
                        currentOwnerAddress
                        creatorAddress
                        lastTransferAt
                        updatedAt
                        createdAt
                        isMinted
                        cooldownExpiresInSeconds
                        cooldownExpiresAt
                        levelProgressPercentage
                        currentLevelMaxExperience
                        explorerUrl
                        levelUpAvailable
                        rankUpAvailable
                        reviveAvailable
                        healUpAvailable
                        healthPercentage
                        dead
                        levelRewardBoostPercentage
                        agility
                        apetite
                        luck
                    }

                    energy
                    instagram {
                        username
                    }
                    tiktok {
                        username
                    }
                    twitter {
                        username
                    }
                    facebook {
                        username
                    }
                }
                type
                createdAt
            }
            meta {
                itemCount
                totalItems
                itemsPerPage
                totalPages
                currentPage
            }
        }
        referralUserCounts {
            directReferralsCount
            indirectReferralsCount
            activeDirectReferralsCount
        }
    }
`;
export const GET_AI_QUOTA = gql`
    query Text2ImageQuota {
        text2ImageQuota {
            dailySystemUsagePercentage
            maxDailyUserUsage
            currentDailyUserUsage
            currentSystemUsagePercentage
        }
    }
`;

export const GET_REVIVE_PAYMENTS = gql`
    query RevivePayments($petId: String!) {
        revivePayments(petId: $petId) {
            token
            tokenAddress
            amountWei
        }
    }
`;
export const GET_HEALUP_PAYMENTS = gql`
    query HealupPayments($petId: String!, $healthPoints: Int) {
        healupPayments(petId: $petId, healthPoints: $healthPoints) {
            token
            tokenAddress
            amountWei
        }
    }
`;

export const CREATE_HEALUP_PAYMENT = gql`
    mutation CreateHealUpPayment(
        $petId: String!
        $token: String!
        $healthPoints: Int
    ) {
        createHealUpPayment(
            petId: $petId
            token: $token
            healthPoints: $healthPoints
        ) {
            signedTransaction {
                contractAddress
                execTransactionValuesStringified
            }
            payment {
                token
                tokenAddress
                amountWei
            }
        }
    }
`;

export const CREATE_REVIVE_PAYMENT = gql`
    mutation CreateRevivePayment($petId: String!, $token: String!) {
        createRevivePayment(petId: $petId, token: $token) {
            signedTransaction {
                contractAddress
                execTransactionValuesStringified
            }
            payment {
                token
                tokenAddress
                amountWei
            }
        }
    }
`;

export const SAVE_DEVICE_TOKEN = gql`
    mutation SaveDeviceToken($token: String!) {
        saveDeviceToken(token: $token)
    }
`;

export const NOTIFICATION_SETTINGS = gql`
    query NotificationSettings {
        notificationSettings {
            pushDailyReminder
            pushRewardEarned
            pushSocialTaskCompleted
            pushPetLowHealth
            pushPetDead
            pushMysteryLikesGame
            emailPetDead
            emailSocialTaskCompleted
        }
    }
`;

export const SET_NOTIFICATION_SETTINGS = gql`
    mutation SetNotificationSettings($setting: UserNotificationSettingsInput!) {
        setNotificationSettings(setting: $setting) {
            pushDailyReminder
            pushRewardEarned
            pushSocialTaskCompleted
            pushPetLowHealth
            pushPetDead
            pushMysteryLikesGame
            emailPetDead
            emailSocialTaskCompleted
        }
    }
`;
export const GET_STAKING_PLANS = gql`
    query StakingMaxRewardPlanBoostsRequirements {
        stakingMaxRewardPlanBoostsRequirements {
            plan {
                id
                name
                color
                durationDays
                rewardMultiplierForMaxBoost
                maxBoostRewardPercentage
            }
            additionalIgupAmountWeiStake
            available
        }
    }
`;

export const GET_STAKING_PLAN_REWARD_BOOSTS = gql`
    query StakingRewardPlanBoosts($toStakeWei: String!) {
        stakingRewardPlanBoosts(toStakeWei: $toStakeWei) {
            plan {
                id
                name
                color
                durationDays
                rewardMultiplierForMaxBoost
                maxBoostRewardPercentage
                minIguAmountWei
                maxIguAmountWei
            }
            available
            estimatedPercentage
        }
    }
`;
export const GET_STAKING_PLAN_REWARD_BOOST = gql`
    query StakingRewardBoost {
        stakingRewardBoost {
            estimatedPercentage
        }
    }
`;

export const CREATE_STAKING = gql`
    mutation CreateStaking($stakingPlanId: String!, $igupAmountWei: String!) {
        createStaking(
            stakingPlanId: $stakingPlanId
            igupAmountWei: $igupAmountWei
        ) {
            payment {
                token
                tokenAddress
                amountWei
                productId
            }
            signedTransaction {
                contractAddress
                execTransactionValuesStringified
            }
        }
    }
`;
export const GET_USER_STAKING = gql`
    query Staked {
        staked {
            id
            amountWei
            durationDays
            endDate
            createdAt
            updatedAt
            stakingPlanId
        }
    }
`;

export const GET_SINGLE_ENERGY_ESTIMATED_GAME_REWARD = gql`
    query SingleEnergyEstimatedGameReward {
        singleEnergyEstimatedGameReward
    }
`;
export const GET_MYSTERY_BOX_PAYMENTS = gql`
    query MysteryBoxPayments($lootboxCount: Int!) {
        lootboxPayments(lootboxCount: $lootboxCount) {
            token
            tokenAddress
            amountWei
        }
    }
`;

export const GET_MYSTERY_BOX_PAYMENTS_OPTIONS = gql`
    query LootboxSaleOptions {
        lootboxSaleOptions {
            id
            count
            payments {
                token
                tokenAddress
                amountWei
                productId
            }
        }
    }
`;

export const CREATE_MYSTERY_BOX = gql`
    mutation CreateMysteryBox($createLootboxInput: CreateLootboxInput!) {
        createLootbox(createLootboxInput: $createLootboxInput) {
            payment {
                token
                tokenAddress
                amountWei
            }
            signedTransaction {
                contractAddress
                execTransactionValuesStringified
            }
        }
    }
`;

export const OPEN_MYSTERY_BOX = gql`
    mutation OpenMysteryBox($openLootboxInput: OpenLootboxInput!) {
        openLootbox(openLootboxInput: $openLootboxInput) {
            signedTransaction {
                contractAddress
                execTransactionValuesStringified
            }
        }
    }
`;

export const GET_MYSTERY_BOX_SALE_STATS = gql`
    query MysteryBoxSaleStats {
        lootboxSaleStats {
            inProgress
            startsAt
            soldCount
            totalSupply
        }
    }
`;

export const GET_MYSTERY_BOX_OPEN_BOXES = gql`
    query MysteryBoxOpenBoxes {
        lootboxRewards {
            id
            tokenId
            rankId
            consumedAt
            createdAt
            updatedAt
        }
    }
`;

export const SHARE_FEEDBACK = gql`
    mutation ShareFeedback($message: String!, $logs: String!) {
        shareFeedback(message: $message, logs: $logs)
    }
`;

export const UPDATE_USER_SETTINGS = gql`
    mutation updateUserSettings($settings: UserSettingsInput!) {
        updateUserSettings(settings: $settings) {
            language
        }
    }
`;

export const LINK_INSTAGRAM_USERNAME = gql`
    mutation LinkInstagramUsername($username: String!) {
        linkInstagramUsername(username: $username) {
            id
            instagramId
            username
            updatedAt
            createdAt
        }
    }
`;
export const LINK_TWITTER = gql`
    mutation LinkTwitterUsername($username: String!) {
        linkTwitterUsername(username: $username) {
            id
            username
            isVerified
            updatedAt
            createdAt
        }
    }
`;

export const UNLINK_TWITTER = gql`
    mutation Mutation {
        unlinkTwitter
    }
`;

export const GET_MOVE_TASK = gql`
    query MoveTaskConfig {
        moveTaskConfig {
            canStart
            maxEnergyReward
            currentTask {
                id
                maxEnergyReward
                energyRewarded
                type
                status
                failureReason
                petExperienceRewards {
                    id
                    experienceIncrement
                    pet {
                        id
                        name
                        image {
                            id
                            filename
                            url
                            thumbnailUrl
                            type
                            status
                            signedUploadUrl
                            metadata {
                                isFromCamera
                                isFromGallery
                                detectionResult {
                                    label
                                    confidence
                                }
                                backgroundId
                                text2ImageTaskId
                            }
                            updatedAt
                            createdAt
                        }
                        rank
                        tokenId
                        level
                        experience
                        health
                        likeCount
                        dislikeCount
                        currentOwnerAddress
                        creatorAddress
                        lastTransferAt
                        updatedAt
                        createdAt
                        isMinted
                        cooldownExpiresInSeconds
                        cooldownExpiresAt
                        levelProgressPercentage
                        currentLevelMaxExperience
                        explorerUrl
                        levelUpAvailable
                        rankUpAvailable
                        reviveAvailable
                        healthPercentage
                        dead
                    }
                    updatedAt
                    createdAt
                }
                updatedAt
                createdAt
                maxDistanceMeters
                maxTimeSeconds
                elapsedTimeSeconds
                traveledDistanceMeters
            }
        }
    }
`;

export const GET_PLAY_TASK = gql`
    query PlayTaskConfig {
        playTaskConfig {
            canStart
            maxEnergyReward
            currentTask {
                id
                status
                maxEnergyReward
                maxPoints
                gainedPoints
                energyRewarded
                updatedAt
                createdAt
            }
        }
    }
`;
export const GET_SOCIAL_TASK = gql`
    query SocialTaskConfig {
        socialTaskConfig {
            canStart
            maxEnergyReward
            latestTask {
                id
                status
                failureReason
                moderatorRejectReason
                maxEnergyReward
                energyRewarded
                token
                platform
                platformUsername
                pet {
                    id
                    name
                    image {
                        id
                        filename
                        url
                        thumbnailUrl
                        type
                        status
                        signedUploadUrl
                        metadata {
                            isFromCamera
                            isFromGallery
                            detectionResult {
                                label
                                confidence
                            }
                            backgroundId
                            text2ImageTaskId
                        }
                        updatedAt
                        createdAt
                    }
                    rank
                    tokenId
                    level
                    experience
                    health
                    likeCount
                    dislikeCount
                    currentOwnerAddress
                    creatorAddress
                    lastTransferAt
                    updatedAt
                    createdAt
                    isMinted
                    cooldownExpiresAt
                    levelProgressPercentage
                    currentLevelMaxExperience
                    explorerUrl
                }
                petExperienceRewards {
                    id
                    experienceIncrement
                    pet {
                        id
                        name
                        image {
                            id
                            filename
                            url
                            thumbnailUrl
                            type
                            status
                            signedUploadUrl
                            metadata {
                                isFromCamera
                                isFromGallery
                                detectionResult {
                                    label
                                    confidence
                                }
                                backgroundId
                                text2ImageTaskId
                            }
                            updatedAt
                            createdAt
                        }
                        rank
                        tokenId
                        level
                        experience
                        health
                        likeCount
                        dislikeCount
                        currentOwnerAddress
                        creatorAddress
                        lastTransferAt
                        updatedAt
                        createdAt
                        isMinted
                        cooldownExpiresAt
                        levelProgressPercentage
                        currentLevelMaxExperience
                        explorerUrl
                        levelUpAvailable
                        reviveAvailable
                        healthPercentage
                        dead
                    }
                    updatedAt
                    createdAt
                }
                sharedAt
                updatedAt
                createdAt
            }
        }
    }
`;

export const GET_VERIFICATION_TASK = gql`
    query Query {
        verificationTaskConfig {
            status
            canStart
            maxEnergyReward
            totalEnergyRewarded
            currentTask {
                id
                status
                failureReason
                maxEnergyReward
                energyRewarded
                isRated
                petExperienceRewards {
                    id
                    experienceIncrement
                    pet {
                        id
                        name
                        image {
                            id
                            filename
                            url
                            thumbnailUrl
                            type
                            status
                            signedUploadUrl
                            metadata {
                                isFromCamera
                                isFromGallery
                                detectionResult {
                                    label
                                    confidence
                                }
                                backgroundId
                                text2ImageTaskId
                            }
                            updatedAt
                            createdAt
                        }
                        rank
                        tokenId
                        level
                        experience
                        health
                        likeCount
                        dislikeCount
                        currentOwnerAddress
                        creatorAddress
                        lastTransferAt
                        updatedAt
                        createdAt
                        isMinted
                        cooldownExpiresAt
                        levelProgressPercentage
                        currentLevelMaxExperience
                        explorerUrl
                        levelUpAvailable
                        reviveAvailable
                        healthPercentage
                        dead
                    }
                    updatedAt
                    createdAt
                }
                socialTask {
                    id
                    platform
                    platformUsername
                    pet {
                        id
                        name
                        image {
                            id
                            filename
                            url
                            thumbnailUrl
                            type
                            status
                            signedUploadUrl
                            metadata {
                                isFromCamera
                                isFromGallery
                                detectionResult {
                                    label
                                    confidence
                                }
                                backgroundId
                                text2ImageTaskId
                            }
                            updatedAt
                            createdAt
                        }
                        rank
                        tokenId
                        level
                        experience
                        health
                        likeCount
                        dislikeCount
                        currentOwnerAddress
                        creatorAddress
                        lastTransferAt
                        updatedAt
                        createdAt
                        isMinted
                        cooldownExpiresAt
                        levelProgressPercentage
                        currentLevelMaxExperience
                        explorerUrl
                    }
                }
                updatedAt
                createdAt
                remainingVerificationAttempts
            }
            tasks {
                id
                status
                failureReason
                maxEnergyReward
                energyRewarded
                isRated
                petExperienceRewards {
                    id
                    experienceIncrement
                    pet {
                        id
                        name
                        image {
                            id
                            filename
                            url
                            thumbnailUrl
                            type
                            status
                            signedUploadUrl
                            metadata {
                                isFromCamera
                                isFromGallery
                                detectionResult {
                                    label
                                    confidence
                                }
                                backgroundId
                                text2ImageTaskId
                            }
                            updatedAt
                            createdAt
                        }
                        rank
                        tokenId
                        level
                        experience
                        health
                        likeCount
                        dislikeCount
                        currentOwnerAddress
                        creatorAddress
                        lastTransferAt
                        updatedAt
                        createdAt
                        isMinted
                        cooldownExpiresAt
                        levelProgressPercentage
                        currentLevelMaxExperience
                        explorerUrl
                        levelUpAvailable
                        reviveAvailable
                        healthPercentage
                        dead
                    }
                    updatedAt
                    createdAt
                }
                socialTask {
                    id
                    platform
                    platformUsername
                    pet {
                        id
                        name
                        image {
                            id
                            filename
                            url
                            thumbnailUrl
                            type
                            status
                            signedUploadUrl
                            metadata {
                                isFromCamera
                                isFromGallery
                                detectionResult {
                                    label
                                    confidence
                                }
                                backgroundId
                                text2ImageTaskId
                            }
                            updatedAt
                            createdAt
                        }
                        rank
                        tokenId
                        level
                        experience
                        health
                        likeCount
                        dislikeCount
                        currentOwnerAddress
                        creatorAddress
                        lastTransferAt
                        updatedAt
                        createdAt
                        isMinted
                        cooldownExpiresAt
                        levelProgressPercentage
                        currentLevelMaxExperience
                        explorerUrl
                    }
                }
                updatedAt
                createdAt
                remainingVerificationAttempts
            }
        }
    }
`;

export const SET_BROADCAST_PUSH_NOTIFICATION = gql`
    mutation BroadcastPushNotifications($broadcastInput: BroadcastInput!) {
        broadcastPushNotifications(broadcastInput: $broadcastInput)
    }
`;

export const REVOKE_USER_SOCIAL_PLATFORM = gql`
    mutation RevokeUserSocialPlatform(
        $platform: SocialPlatform!
        $username: String!
    ) {
        revokeUserSocialPlatform(platform: $platform, username: $username)
    }
`;

export const REFERRAL_TOTAL_TURNOVER = gql`
    query ReferralTotalTurnover {
        referralTotalTurnover {
            busdAmountWei
        }
    }
`;

export const GET_MY_REFERRER = gql`
    query MyReferrer {
        myReferrer {
            username
            walletAddress
            mainPet {
                image {
                    thumbnailUrl
                }
            }
            energy
            instagram {
                username
            }
            twitter {
                username
            }
            tiktok {
                username
            }
            facebook {
                username
            }
        }
    }
`;

export const ADMIN_BAN_USER = gql`
    mutation BanUserWithReason($userBanInput: UserBanInput!) {
        banUserWithReason(userBanInput: $userBanInput)
    }
`;

export const UNLINK_FACEBOOK = gql`
    mutation Mutation {
        unlinkFacebook
    }
`;
export const LINK_FACEBOOK = gql`
    mutation LinkFacebook($username: String!) {
        linkFacebook(username: $username) {
            id
            username
            isVerified
            updatedAt
            createdAt
        }
    }
`;

export const GET_WHITE_LIST_RANKS = gql`
    query WhitelistRanks {
        whitelistRanks {
            id
            slug
        }
    }
`;
export const CREATE_WHITE_LIST_ENTRIES = gql`
    mutation CreateWhitelistEntries($assignInput: WhitelistAssignInput!) {
        createWhitelistEntries(assignInput: $assignInput)
    }
`;

export const GET_AGE_REVIVE_PAYMENTS = gql`
    query AgeRevivePayments($petId: String!) {
        ageRevivePayments(petId: $petId) {
            token
            tokenAddress
            amountWei
            productId
        }
    }
`;

export const CREATE_AGE_REVIVE_PAYMENT = gql`
    mutation CreateAgeRevivePayment($petId: String!, $token: String!) {
        createAgeRevivePayment(petId: $petId, token: $token) {
            signedTransaction {
                contractAddress
                execTransactionValuesStringified
            }
            payment {
                token
                tokenAddress
                amountWei
                productId
            }
        }
    }
`;

export const GET_CURRENT_GAME_ITERATION = gql`
    query CurrentGameIteration {
        currentGameIteration {
            status
        }
    }
`;

export const GET_RANK_UP_INFO = gql`
    query RankupInfo($petId: String!) {
        rankupInfo(petId: $petId) {
            maxAgeDays
            baseIgupRewardWei
            dailyUnlockRate
            rewardConsumptionPercentage
        }
    }
`;
export const GET_USER_ROLES = gql`
    query Query {
        userRoles
    }
`;
export const ASSIGN_USER_ROLE = gql`
    mutation AssignUserRole($assignRoleInput: UserAssignRoleInput!) {
        assignUserRole(assignRoleInput: $assignRoleInput)
    }
`;

export const NFT_ROYAL_REVOKE_BAN = gql`
    mutation NftRoyaleRevokeBan($filter: NftRoyaleRevokeBanFilter!) {
        nftRoyaleRevokeBan(filter: $filter)
    }
`;

export const GET_PET_NEXT_LEVEL_INFO = gql`
    query PetNextLevelInfo($petId: String!) {
        petNextLevelInfo(petId: $petId) {
            levelupRewardBoostPercentage
        }
    }
`;

export const GET_BAN_REASONS = gql`
    query BanReasons {
        banReasons {
            reason
            label
        }
    }
`;

export const GET_IGU_GAMING_BALANCE = gql`
    query IguBalance {
        iguBalance {
            total
            available
            locked
        }
        iguPendingWithdraw {
            paymentOption {
                token
                tokenAddress
                amountWei
                productId
            }
            signedTransaction {
                contractAddress
                execTransactionValuesStringified
            }
        }
    }
`;
export const WITHDRAW_IGU = gql`
    mutation WithdrawIgu($amount: String!) {
        withdrawIgu(amount: $amount) {
            paymentOption {
                token
                tokenAddress
                amountWei
                productId
            }
            signedTransaction {
                contractAddress
                execTransactionValuesStringified
            }
        }
    }
`;
export const GET_IGU_PENDING_WITHDRAW = gql`
    query IguPendingWithdraw {
        iguPendingWithdraw {
            paymentOption {
                token
                tokenAddress
                amountWei
                productId
            }
            signedTransaction {
                contractAddress
                execTransactionValuesStringified
            }
        }
    }
`;

export const GET_IGU_TRANSACTION_HISTORY = gql`
    query IguTransactionHistory(
        $paginationInput: IguWalletTransactionPaginationInput!
    ) {
        iguTransactionHistory(paginationInput: $paginationInput) {
            items {
                id
                type
                iguAmountWei
                createdAt
                locked
            }
            meta {
                itemCount
                totalItems
                itemsPerPage
                totalPages
                currentPage
            }
        }
    }
`;

export const GET_MYSTERY_LIKES_GAME_STATS = gql`
    query MysteryLikesGameStats {
        mysteryLikesGameStats {
            activeGamesCount
            minimalBetIguAmountWei
            totalPricePoolIguAmountWei
            maxRoundsCount
            gameMaxLifetimeDays
        }
    }
`;

export const GET_MYSTERY_LIKES_AVAILABLE_GAMES = gql`
    query MysteryLikesAvailableGames(
        $paginationInput: MysteryLikesAvailableGamePaginationInput!
    ) {
        mysteryLikesAvailableGames(paginationInput: $paginationInput) {
            items {
                id
                iguAmountWei
                createdAt
                creator {
                    username
                    walletAddress
                    mainPet {
                        image {
                            thumbnailUrl
                        }
                    }
                }
            }
            meta {
                itemCount
                totalItems
                itemsPerPage
                totalPages
                currentPage
            }
        }
    }
`;

export const GET_IGU_AVAILABLE_BALANCE = gql`
    query IguAvailableBalance {
        iguBalance {
            available
        }
    }
`;

export const CREATE_MYSTERY_LIKE_GAME = gql`
    mutation MysteryLikesCreateGame($iguAmountWei: String!) {
        mysteryLikesCreateGame(iguAmountWei: $iguAmountWei) {
            id
            iguAmountWei
            roundsCompletedCount
            roundsCount
        }
    }
`;

export const GET_MYSTERY_LIKES_GAME_ROUND = gql`
    query MysteryLikesGameRound($gameId: String!) {
        mysteryLikesGameRound(gameId: $gameId) {
            id
            petImageUrl
            predefinedChoices
        }
    }
`;
export const SET_VOTE_GAME_ROUND = gql`
    mutation MysteryLikesVoteGameRound(
        $input: VoteMysteryLikesGameRoundInput!
    ) {
        mysteryLikesVoteGameRound(input: $input)
    }
`;
export const GET_MYSTERY_LIKES_PENDING_GAMES = gql`
    query MysteryLikesMyPendingGames {
        mysteryLikesMyPendingGames {
            id
            iguAmountWei
            roundsCompletedCount
            roundsCount
        }
    }
`;

export const GET_MYSTERY_LIKES_GAME_HISTORY = gql`
    query MysteryLikesGameHistory($gameId: String!) {
        mysteryLikesGameHistory(gameId: $gameId) {
            id
            opponent {
                username
                walletAddress
                mainPet {
                    image {
                        thumbnailUrl
                    }
                }
            }
            iguAmountWei
            status
            rounds {
                index
                petImageUrl
                choice
                correctChoice
            }
            opponentRounds {
                index
                petImageUrl
                choice
                correctChoice
            }
            createdAt
        }
    }
`;

export const MYSTERY_LIKES_JOIN_GAME = gql`
    mutation MysteryLikesJoinGame($gameId: String!) {
        mysteryLikesJoinGame(gameId: $gameId)
    }
`;

export const GET_MYSTERY_LIKES_GAMES_HISTORY = gql`
    query MysteryLikesGamesHistory(
        $paginationInput: MysteryLikesGameHistoryPaginationInput!
    ) {
        mysteryLikesGamesHistory(paginationInput: $paginationInput) {
            items {
                id
                opponent {
                    username
                    walletAddress
                    mainPet {
                        image {
                            thumbnailUrl
                        }
                    }
                }
                iguAmountWei
                status
                createdAt
            }
            meta {
                itemCount
                totalItems
                itemsPerPage
                totalPages
                currentPage
            }
        }
    }
`;

export const GET_MYSTERY_GAMES = gql`
    query MysteryGames {
        games {
            type
            name
            description
            enabled
            playersCount
        }
    }
`;

export const GET_BREEDING_RANKS_STATS = gql`
    query BreedingRanksStats {
        breedingRanksStats {
            rank1Id
            rank2Id
            winRankId
            winChance
            payment {
                token
                tokenAddress
                amountWei
                productId
            }
        }
    }
`;
export const GET_BREEDING_PETS = gql`
    query BreedingPets {
        breedingPets {
            id
            name
            level
            rank
            imageUrl
            healthPercentage
            ageDays
            maxAgeDays
            levelProgressPercentage
        }
    }
`;

export const GET_BREEDING_PETS_PAIR_STATS = gql`
    query BreedingPetsPairStats($pet1Id: String!, $pet2Id: String!) {
        breedingPetsPairStats(pet1Id: $pet1Id, pet2Id: $pet2Id) {
            viguAmountWei
            chances {
                rankId
                winChance
            }
        }
    }
`;

export const BREEDING_BREED_PETS = gql`
    mutation BreedingBreedPets($pet1Id: String!, $pet2Id: String!) {
        breedingBreedPets(pet1Id: $pet1Id, pet2Id: $pet2Id) {
            id
            name
            rank
            parent1 {
                id
                name
                level
                rank
                imageUrl
                healthPercentage
                ageDays
                maxAgeDays
                levelProgressPercentage
            }
            parent2 {
                id
                name
                level
                rank
                imageUrl
                healthPercentage
                ageDays
                maxAgeDays
                levelProgressPercentage
            }
            selectedImageId
            selectableImages {
                id
                url
            }
            imageUrl
            editable
            mintable
        }
    }
`;

export const GET_BREEDING_PET_DRAFTS = gql`
    query BreedingPetDrafts {
        breedingPetDrafts {
            id
            rank
        }
    }
`;

export const GET_BREEDING_PET_DRAFTS_DETAILS = gql`
    query BreedingPetDraftDetails($petDraftId: String!) {
        breedingPetDraftDetails(petDraftId: $petDraftId) {
            id
            name
            rank
            parent1 {
                id
                name
                level
                rank
                imageUrl
                healthPercentage
                ageDays
                maxAgeDays
                levelProgressPercentage
            }
            parent2 {
                id
                name
                level
                rank
                imageUrl
                healthPercentage
                ageDays
                maxAgeDays
                levelProgressPercentage
            }
            selectedImageId
            selectableImages {
                id
                url
            }
            imageUrl
            editable
            mintable
        }
    }
`;

export const BREEDING_PET_DRAFT_UPDATE = gql`
    mutation BreedingPetDraftUpdate(
        $petDraftId: String!
        $name: String!
        $imageId: String!
    ) {
        breedingPetDraftUpdate(
            petDraftId: $petDraftId
            name: $name
            imageId: $imageId
        )
    }
`;

export const BREEDING_MINT_PET_DRAFT = gql`
    mutation BreedingMintPetDraft($petDraftId: String!) {
        breedingMintPetDraft(petDraftId: $petDraftId) {
            payment {
                token
                tokenAddress
                amountWei
                productId
            }
            signedTransaction {
                contractAddress
                execTransactionValuesStringified
            }
            petId
        }
    }
`;

export const GET_ESTIMATED_GAMES_TURNOVER = gql`
    query EstimatedGamesTurnover {
        estimatedGamesTurnover {
            iguAmountWei
            gamesCount
        }
    }
`;

export const GET_LATEST_GAMES_TURNOVER_REWARD = gql`
    query LatestGamesTurnoverReward {
        latestGamesTurnoverReward {
            iguAmountWei
        }
    }
`;
export const GET_MYSTERY_LIKES_GAME_LAST_GAME = gql`
    query MysteryLikesGameLastGame {
        mysteryLikesGameLastGame {
            petImageUrl
            iguAmountWei
            status
        }
    }
`;

export const GET_MARKETPLACE_SALEABLE_PETS = gql`
    query MarketplaceSaleablePets {
        marketplaceSaleablePets {
            id
            imageUrl
            rank
            level
            healthPercentage
            ageDays
            maxAgeDays
            levelProgressPercentage
            name
        }
    }
`;

export const GET_MARKETPLACE_FILTERED_ACTIVE_ORDERS = gql`
    query MarketplaceFilteredActiveOrders(
        $searchInput: MarketplaceSearchInput!
        $paginationInput: MarketplaceSearchPaginationInput!
    ) {
        marketplaceFilteredActiveOrders(
            searchInput: $searchInput
            paginationInput: $paginationInput
        ) {
            meta {
                itemCount
                totalItems
                itemsPerPage
                totalPages
                currentPage
            }
            items {
                id
                pet {
                    id
                    imageUrl
                    rank
                    name
                    dead
                    ageDead
                }
                sellerAddress
                sellerUsername
                priceIguAmountWei
                deadline
                cancelTransactionValuesStringified
                createdAt
            }
        }
    }
`;

export const MARKETPLACE_ORDER_DATA_TO_SIGN = gql`
    mutation MarketplaceOrderDataToSign(
        $petId: String!
        $iguPriceWei: String!
    ) {
        marketplaceOrderDataToSign(petId: $petId, iguPriceWei: $iguPriceWei) {
            domainDataStringified
            typesStringified
            valuesStringified
        }
    }
`;

export const MARKETPLACE_CREATE_ORDER = gql`
    mutation MarketplaceCreateOrder(
        $petId: String!
        $signedData: SignedDataInput!
    ) {
        marketplaceCreateOrder(petId: $petId, signedData: $signedData) {
            id
            pet {
                id
                imageUrl
                rank
                name
            }
            sellerAddress
            priceIguAmountWei
            deadline
            cancelTransactionValuesStringified
            createdAt
        }
    }
`;
export const GET_MARKETPLACE_COUNTS_FOR_RANKS = gql`
    query MarketplaceCountsForRanks {
        marketplaceCountsForRanks {
            rank
            count
        }
    }
`;
export const GET_MARKETPLACE_NFT_DETAILS = gql`
    query MarketplaceNftDetails($petId: String!) {
        marketplaceNftDetails(petId: $petId) {
            pet {
                id
                imageUrl
                rank
                level
                healthPercentage
                ageDays
                maxAgeDays
                levelProgressPercentage
                name
                isFromPhoto
                currentLevelMaxExperience
                dead
                age
                ageDead
                experience
                nftDetectionResult {
                    label
                    confidence
                }
            }
            owner {
                profile {
                    username
                    walletAddress
                    avatarUrl
                    avatarThumbnailUrl
                    createdAt
                }
                stats {
                    soldStats {
                        volumeIguAmountWei
                        volumeUsd
                        transactionCount
                    }
                    boughtStats {
                        volumeIguAmountWei
                        volumeUsd
                        transactionCount
                    }
                }
            }
            order {
                priceIguAmountWei
                deadline
                createdAt
                buyTransactionValuesStringified
            }
        }
    }
`;

export const GET_MARKETPLACE_NFT_TRANSACTION_HISTORY = gql`
    query MarketplaceNftTransactionHistory($petId: String!) {
        marketplaceNftTransactionHistory(petId: $petId) {
            transactions {
                priceIguAmountWei
                soldUsdPrice
                buyerAddress
                buyer {
                    username
                    walletAddress
                    avatarUrl
                    avatarThumbnailUrl
                    createdAt
                }
                boughtAt
            }
            totalVolumeIgu
            totalVolumeUsd
        }
    }
`;

export const GET_MARKETPLACE_PROFILE = gql`
    query MarketplaceProfile($walletAddress: String!) {
        marketplaceProfile(walletAddress: $walletAddress) {
            user {
                username
                walletAddress
                avatarUrl
                avatarThumbnailUrl
                createdAt
            }
            stats {
                boughtStats {
                    volumeIguAmountWei
                    volumeUsd
                    transactionCount
                }
                soldStats {
                    volumeIguAmountWei
                    volumeUsd
                    transactionCount
                }
            }
            listedOrders {
                id
                pet {
                    id
                    imageUrl
                    rank
                    name
                }
                sellerAddress
                sellerUsername
                priceIguAmountWei
                deadline
                cancelTransactionValuesStringified
                createdAt
            }
        }
    }
`;

export const GET_NFT_ROYAL_GAME_CONFIG = gql`
    query NftRoyaleGameConfig {
        nftRoyaleGameConfig {
            minBetIguAmountWei
            maxBetIguAmountWei
            inactiveGameTerminationDays
            rewardUnlockDaysCount
            gameModes
            gameComplexities
            gameLives {
                count
                imageUrl
            }
            activeGamesCount
            totalPricePoolIguAmountWei
        }
    }
`;

export const GET_NFT_ROYAL_AVAILABLE_GAMES = gql`
    query NftRoyaleAvailableGames(
        $paginationInput: NftRoyaleAvailableGamePaginationInput!
    ) {
        nftRoyaleAvailableGames(paginationInput: $paginationInput) {
            items {
                id
                iguAmountWei
                creatorUsername
                creatorAvatarUrl
                mode
                complexity
                livesCount
                createdAt
            }
            meta {
                itemCount
                totalItems
                itemsPerPage
                totalPages
                currentPage
            }
        }
    }
`;

export const CREATE_NFT_ROYAL_GAME = gql`
    mutation NftRoyaleGameCreate($input: NftRoyaleCreateGameInput!) {
        nftRoyaleGameCreate(input: $input) {
            id
            mode
            complexity
            livesCount
            gameplayConfig {
                scorePerFruit
                scorePerMeter
                maxLives
                difficulty
                petAcceleration
                petJumpHeight
                petJumpDuration
                petMoveSpeedAtMinDifficult
                petMoveSpeedAtMaxDifficult
                petInvulnerabilityDuration
                obstacleMinGapDistanceAtMinDifficult
                obstacleMaxGapDistanceAtMinDifficult
                obstacleMinGapDistanceAtMaxDifficult
                obstacleMaxGapDistanceAtMaxDifficult
                fruitMinGapDistanceAtMinDifficult
                fruitMaxGapDistanceAtMinDifficult
                fruitMinGapDistanceAtMaxDifficult
                fruitMaxGapDistanceAtMaxDifficult
            }
        }
    }
`;

export const GET_NFT_ROYAL_MY_PENDING_GAMES = gql`
    query NftRoyaleMyPendingGames {
        nftRoyaleMyPendingGames {
            id
            mode
            complexity
            livesCount
            gameplayConfig {
                scorePerFruit
                scorePerMeter
                maxLives
                difficulty
                petAcceleration
                petJumpHeight
                petJumpDuration
                petMoveSpeedAtMinDifficult
                petMoveSpeedAtMaxDifficult
                petInvulnerabilityDuration
                obstacleMinGapDistanceAtMinDifficult
                obstacleMaxGapDistanceAtMinDifficult
                obstacleMinGapDistanceAtMaxDifficult
                obstacleMaxGapDistanceAtMaxDifficult
                fruitMinGapDistanceAtMinDifficult
                fruitMaxGapDistanceAtMinDifficult
                fruitMinGapDistanceAtMaxDifficult
                fruitMaxGapDistanceAtMaxDifficult
            }
        }
    }
`;

export const GET_NFT_ROYAL_GAMES_HISTORY = gql`
    query NftRoyaleGamesHistory(
        $paginationInput: NftRoyaleGameHistoryPaginationInput!
    ) {
        nftRoyaleGamesHistory(paginationInput: $paginationInput) {
            items {
                id
                opponentUsername
                opponentAvatarUrl
                iguAmountWei
                status
                createdAt
            }
            meta {
                itemCount
                totalItems
                itemsPerPage
                totalPages
                currentPage
            }
        }
    }
`;

export const GET_NFT_ROYAL_GAME_HISTORY = gql`
    query NftRoyaleGameHistory($gameId: String!) {
        nftRoyaleGameHistory(gameId: $gameId) {
            id
            opponentUsername
            opponentAvatarUrl
            iguAmountWei
            status
            myScore
            myFruitsCount
            myLivesCount
            opponentScore
            opponentFruitsCount
            opponentLivesCount
            createdAt
            mode
            livesCount
            complexity
        }
    }
`;

export const JOIN_NFT_ROYAL_GAME = gql`
    mutation NftRoyaleJoinGame($gameId: String!) {
        nftRoyaleJoinGame(gameId: $gameId) {
            id
            mode
            complexity
            livesCount
            gameplayConfig {
                scorePerFruit
                scorePerMeter
                maxLives
                difficulty
                petAcceleration
                petJumpHeight
                petJumpDuration
                petMoveSpeedAtMinDifficult
                petMoveSpeedAtMaxDifficult
                petInvulnerabilityDuration
                obstacleMinGapDistanceAtMinDifficult
                obstacleMaxGapDistanceAtMinDifficult
                obstacleMinGapDistanceAtMaxDifficult
                obstacleMaxGapDistanceAtMaxDifficult
                fruitMinGapDistanceAtMinDifficult
                fruitMaxGapDistanceAtMinDifficult
                fruitMinGapDistanceAtMaxDifficult
                fruitMaxGapDistanceAtMaxDifficult
            }
        }
    }
`;

export const NFT_ROYAL_REGISTER_GAME_EVENT = gql`
    mutation NftRoyaleRegisterGameEvent($input: NftRoyaleGameEventInput!) {
        nftRoyaleRegisterGameEvent(input: $input)
    }
`;
