import { StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import { scale, verticalScale } from '../../../../helpers/dimensions';
import font from '../../../../helpers/font';
import { theme } from '../../../../types';

const stylesMain = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
        },
        loading: {
            paddingTop: verticalScale(20),
        },
        carouselWrapper: {
            marginTop: verticalScale(20),
        },

        rewardsHistoryWrapper: {
            marginTop: verticalScale(10),
            marginBottom: verticalScale(20),
        },

        emptyStateWrapper: {
            marginTop: verticalScale(56),
        },
        emptyStateWrapperSmall: {
            marginTop: verticalScale(36),
        },
        emptyList: {
            paddingBottom: 0,
        },
        refreshButton: {
            alignSelf: 'center',
        },
        buttonsWrapper: {
            marginTop: verticalScale(20),
            marginBottom: verticalScale(24),
        },
        gestureContainer: {
            flex: 1,
        },
        headerTitle: {
            color: Colors[theme].text.primary,
            ...font({
                size: 18,
                height: 24,
                weight: '700',
            }),
            width: '65%',
        },
        headerText: {
            borderTopWidth: 1,
            borderTopColor: Colors[theme].border.primary,
            paddingTop: verticalScale(24),
            paddingHorizontal: scale(20),
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    });

export default stylesMain;
