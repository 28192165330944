import * as React from 'react';

import Button from '@components/Button';
import EmptyStateTextAnimated from '@components/EmptyStateTextAnimated';
import { View } from '@components/Themed';
import { reloadApp } from '@helpers/app';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    isLoading: boolean;
    type?: 'prepare' | 'complete';
}

const NftRoyalLoadingScreen = ({ isLoading, type = 'prepare' }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { isVisible, open } = useVisibleHook(false);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            open();
        }, 25000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <View style={styles.container}>
            <EmptyStateTextAnimated
                animationName="nftRoyalLoading"
                isLoading={isLoading}
                loadingTitle={'...'}
                staticTitle={i18n.t(
                    `HomeScreen.NftRoyal.NftRoyalLoadingScreen.${type}.title`
                )}
                text={i18n.t(
                    `HomeScreen.NftRoyal.NftRoyalLoadingScreen.${type}.text`
                )}
                backgroundColor={styles.container.backgroundColor}
            />
            {isVisible && type === 'prepare' && (
                <Button
                    containerStyle={styles.button}
                    onPress={reloadApp}
                    type="outline"
                    isOneColor
                    title={i18n.t('general.BUTTONS.reloadApp')}
                />
            )}
        </View>
    );
};

export default NftRoyalLoadingScreen;
