import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        shadow: {
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].shadow.default,
            marginBottom: verticalScale(16),
        },
        disabled: {
            opacity: 0.4,
        },
        itemContainer: {
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(16),
            marginBottom: verticalScale(4),
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        left: {
            backgroundColor: 'transparent',
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
        },
        right: {
            backgroundColor: 'transparent',
        },
        textWrapper: {
            backgroundColor: 'transparent',
            marginLeft: scale(12),
            marginRight: scale(16),
            flex: 1,
        },
        textGap: {
            marginBottom: verticalScale(2),
        },
        arrowRight: {
            color: Colors[theme].icon.secondary,
        },
    });

export default styles;
