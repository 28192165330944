import { useCallback, useEffect, useState } from 'react';

import { StakingPlanOutput } from '@Data/Models';
import { useStaking } from '@contexts/StakingContext';
import { isWeb } from '@helpers/app';
import { toastErrorUnknown } from '@helpers/toastNotification';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import { usePersistStorage } from '../../../../vendor/react-native-user-persist-storage';

export const useStakingPlansScreen = (plan?: StakingPlanOutput | undefined) => {
    const navigation = useNavigation();
    const [stageFill, setStageFill] = useState('0%');

    const {
        setCurrentPlan,
        currentPlan,
        stakingPlans,
        stakingCoin,
        currentStakingPlan,
        isIguDrop,
    } = useStaking();

    const [selectedPlan, setSelectedPlan] = usePersistStorage(
        'StakingProvider.selectedPlan',
        currentPlan?.plan.id || '',
        {
            persist: isWeb,
        }
    );

    useEffect(() => {
        return () => writePlan('');
    }, []);

    useEffect(() => {
        if (currentPlan?.plan.id) onPlanPress(currentPlan?.plan.id);
    }, [currentPlan]);

    const navigateToStakingInputScreen = useCallback(
        (planId?: string) => {
            const plan = stakingPlans?.find(
                (i) => i.plan.id === (planId || selectedPlan)
            );
            if (!plan) {
                toastErrorUnknown();
                return;
            }
            setCurrentPlan(plan);
            navigation.push(ROUTES.STAKING_COINS_INPUT);
        },
        [selectedPlan, stakingPlans]
    );

    const writePlan = (id: string) => {
        setSelectedPlan(id);
    };

    const onPlanPress = useCallback(
        (id: string) => {
            setStageFill('100%');
            if (selectedPlan === id) {
                return;
            }
            writePlan(id);
        },
        [selectedPlan]
    );

    const handleInfoPress = () => {
        navigation.push(ROUTES.STAKING_DETAILED_INFO);
    };

    const handleBack = () => {
        navigation.goBack();
        setCurrentPlan(undefined);
    };

    useEffect(() => {
        if (plan) {
            onPlanPress(plan.plan.id);
            navigateToStakingInputScreen(plan.plan.id);
            navigation.setParams({
                plan: undefined,
            });
        }
    }, []);

    return {
        stakingCoin,
        navigateToStakingInputScreen,
        handleInfoPress,
        stakingPlans,
        currentStakingPlan,
        stageFill,
        navigation,
        handleBack,
        selectedPlan,
        onPlanPress,
        isIguDrop,
    };
};
