import * as React from 'react';
import { useState } from 'react';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

import { wait } from '../../helpers/helpers';
import { timeFormatter } from '../../helpers/time';
import { useCountdown } from '../../hooks/useCountDown';
import useThemedStyles from '../../hooks/useThemedStyles';
import i18n from '../../i18n/i18n';
import { Text, View } from '../Themed';
import stylesMain from './styles';

const SECONDS_IN_DAY = 86400;
const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_MINUTE = 60;

interface IProps {
    endOfTime: string | undefined;
    timerStyles?: StyleProp<TextStyle>;
    showHours?: boolean;
    showDays?: boolean;
    showMinutes?: boolean;
    showSeconds?: boolean;
    withLeadingZero?: boolean;
    withCaptions?: boolean;
    captionItemStyle?: StyleProp<ViewStyle>;
    captionTextStyle?: StyleProp<TextStyle>;
    captionDateStyle?: StyleProp<TextStyle>;
    captionDividerStyle?: StyleProp<TextStyle>;
    thresholdReachedStyles?: StyleProp<TextStyle>;
    thresholdTime?: number;
    onEndTime?: () => void;
}

const Timer = ({
    timerStyles,
    endOfTime,
    showSeconds = true,
    showMinutes = true,
    showHours = true,
    showDays = false,
    withLeadingZero = true,
    withCaptions = false,
    captionItemStyle,
    captionTextStyle,
    captionDateStyle,
    captionDividerStyle,
    thresholdReachedStyles,
    thresholdTime,
    onEndTime,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const [days, hours, minutes, seconds] = useCountdown(endOfTime || '');
    const [isThresholdReached, setIsThresholdReached] =
        useState<boolean>(false);
    const daysText = React.useMemo(
        () => (showDays ? timeFormatter(days, showDays) : ''),
        [days, showDays]
    );
    const hoursText = React.useMemo(
        () => (showHours ? timeFormatter(hours, withLeadingZero) : ''),
        [hours, showHours, withLeadingZero]
    );
    const minutesText = React.useMemo(
        () => (showMinutes ? timeFormatter(minutes, withLeadingZero) : ''),
        [minutes, showMinutes, withLeadingZero]
    );
    const secondsText = showSeconds
        ? timeFormatter(seconds, withLeadingZero)
        : '';

    React.useEffect(() => {
        if (hours == 0 && minutes == 0 && seconds == 0) {
            wait(1000).then(() => {
                Console.log('[Timer] Reach end');
                onEndTime?.();
            });
        }

        // set special color to the timer when reaching threshold time limit
        if (
            thresholdTime &&
            Number(days) * SECONDS_IN_DAY +
                Number(hours) * SECONDS_IN_HOUR +
                Number(minutes) * SECONDS_IN_MINUTE +
                Number(seconds) <=
                thresholdTime
        ) {
            setIsThresholdReached(true);
        } else {
            setIsThresholdReached(false);
        }
    }, [days, hours, minutes, seconds]);

    const timeText = (daysText + hoursText + minutesText + secondsText).slice(
        1
    );

    return (
        <>
            {withCaptions ? (
                <>
                    {!!daysText && (
                        <>
                            <View
                                style={[
                                    styles.withCaptionsItem,
                                    captionItemStyle,
                                ]}>
                                <Text
                                    style={[
                                        captionDateStyle,
                                        isThresholdReached &&
                                            thresholdReachedStyles,
                                    ]}>
                                    {daysText.replace(':', '')}
                                </Text>
                                <Text style={captionTextStyle}>
                                    {i18n.t('timer.captions.day')}
                                </Text>
                            </View>
                            <Text style={captionDividerStyle}>:</Text>
                        </>
                    )}
                    <View style={[styles.withCaptionsItem, captionItemStyle]}>
                        <Text
                            style={[
                                captionDateStyle,
                                isThresholdReached && thresholdReachedStyles,
                            ]}>
                            {hoursText.replace(':', '')}
                        </Text>
                        <Text style={captionTextStyle}>
                            {i18n.t('timer.captions.hour')}
                        </Text>
                    </View>
                    <Text style={captionDividerStyle}>:</Text>
                    <View style={[styles.withCaptionsItem, captionItemStyle]}>
                        <Text
                            style={[
                                captionDateStyle,
                                isThresholdReached && thresholdReachedStyles,
                            ]}>
                            {minutesText.replace(':', '')}
                        </Text>
                        <Text style={captionTextStyle}>
                            {i18n.t('timer.captions.min')}
                        </Text>
                    </View>
                    <Text style={captionDividerStyle}>:</Text>
                    <View style={[styles.withCaptionsItem, captionItemStyle]}>
                        <Text
                            style={[
                                captionDateStyle,
                                isThresholdReached && thresholdReachedStyles,
                            ]}>
                            {secondsText.replace(':', '')}
                        </Text>
                        <Text style={captionTextStyle}>
                            {i18n.t('timer.captions.sec')}
                        </Text>
                    </View>
                </>
            ) : (
                <Text
                    style={[
                        timerStyles,
                        isThresholdReached && thresholdReachedStyles,
                    ]}>
                    {timeText}
                </Text>
            )}
        </>
    );
};
export default Timer;
