import * as React from 'react';
import { StyleProp, TouchableOpacity, ViewProps } from 'react-native';

import { Text, View } from '../../components/Themed';
import useThemedStyles from '../../hooks/useThemedStyles';
import Icon, { ICON_NAMES } from '../Icons';
import stylesMain from './styles';

interface IProps {
    onPress: () => void;
    containerStyle?: StyleProp<ViewProps>;
    text?: string;
    children?: React.ReactNode;
    content?: React.ReactNode;
    iconName?: ICON_NAMES;
    maxWidth?: number;
}

const Selector = ({
    containerStyle,
    onPress,
    text,
    children,
    content,
    iconName = ICON_NAMES.ARROW_DROP_DOWN,
    maxWidth,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    return (
        <>
            <TouchableOpacity
                activeOpacity={0.7}
                style={styles.shadow}
                onPress={onPress}>
                <View style={[styles.container, containerStyle]}>
                    <View style={styles.left}>
                        {content || (
                            <Text
                                style={[styles.text, maxWidth && { maxWidth }]}
                                numberOfLines={1}>
                                {text}
                            </Text>
                        )}
                    </View>

                    <Icon name={iconName} color="secondary" size={16} />
                </View>
            </TouchableOpacity>
            {children}
        </>
    );
};
export default Selector;
