import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme, safeBottom: number) =>
    StyleSheet.create({
        container: {
            flex: 1,
            paddingBottom: safeBottom,
        },
        center: {
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            paddingHorizontal: scale(20),
        },
        button: {
            marginTop: verticalScale(16),
            alignSelf: 'center',
        },
        buttonColor: {
            color: Colors[theme].text.secondary,
        },
    });

export default styles;
