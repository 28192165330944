import { gql } from '@apollo/client';

export const GET_TOKEN_TELLER_GAME_CONFIG = gql`
    query TokentellerGameConfig {
        tokentellerGameConfig {
            minimalBetIguAmountWei
            commisionFeePercentage
            gameDurationMinutes
        }
    }
`;

export const GET_TOKEN_TELLER_AVAILABLE_GAMES = gql`
    query TokentellerAvailableGames {
        tokentellerAvailableGames {
            id
            iguAmountWei
            userCount
            status
            backgroundColor
            imageUrl
            title
            text
            upCoefficient
            downCoefficient
            endDate
            createdAt
        }
    }
`;
export const TOKEN_TELLER_PREDICT_PRICE = gql`
    mutation TokentellerGamePredict($input: TokentellerGamePredictInput!) {
        tokentellerGamePredict(input: $input)
    }
`;

export const GET_TOKEN_TELLER_PENDING_GAMES = gql`
    query TokentellerMyPendingGames {
        tokentellerMyPendingGames {
            id
            status
            prediction {
                direction
                iguAmountWei
            }
            iguAmountWei
        }
    }
`;
export const GET_TOKEN_TELLER_AVAILABLE_GAME = gql`
    query TokentellerAvailableGame($gameId: String!) {
        tokentellerAvailableGame(gameId: $gameId) {
            id
            iguAmountWei
            userCount
            status
            backgroundColor
            imageUrl
            title
            text
            upCoefficient
            downCoefficient
            endDate
            createdAt
            beforePrice
        }
    }
`;
export const GET_PAYMENT_TOKEN_PRICE_HISTORY = gql`
    query PaymentsTokensPriceHistory {
        paymentsTokensPriceHistory {
            iguusdt {
                percent_change_24h
                price
            }
        }
    }
`;

export const GET_TOKEN_TELLER_GAME_COEFFICIENTS = gql`
    query TokentellerGameCoefficients {
        tokentellerGameCoefficients {
            gameId
            upCoefficient
            downCoefficient
            userCount
            iguAmountWei
        }
    }
`;

export const GET_TOKENTELLER_PARTICIPANTS = gql`
    query TokentellerGameParticipants($gameId: String!) {
        tokentellerGameParticipants(gameId: $gameId) {
            iguAmountWei
            userCount
            participants {
                id
                username
                avatarUrl
                iguAmountWei
                direction
            }
        }
    }
`;
export const GET_TOKEN_TELLER_GAMES_HISTORY = gql`
    query TokentellerGamesHistory(
        $paginationInput: TokentellerGameHistoryPaginationInput!
    ) {
        tokentellerGamesHistory(paginationInput: $paginationInput) {
            items {
                id
                iguAmountWei
                status
                createdAt
            }
            meta {
                itemCount
                totalItems
                itemsPerPage
                totalPages
                currentPage
            }
        }
    }
`;

export const GET_TOKEN_TELLER_GAME_HISTORY = gql`
    query TokentellerGameHistory($gameId: String!) {
        tokentellerGameHistory(gameId: $gameId) {
            afterPrice
            beforePrice
            iguAmountWei
            upCoefficient
            downCoefficient
            userCount
            userDirection
            winDirection
            status
            userPredictIguAmountWei
            userAffectedIguAmountWei
        }
    }
`;

export const GET_IGU_USDT_EXCHANGE_RATE = gql`
    query ExchangeRates {
        exchangeRates {
            iguusdt
        }
    }
`;
