import { Dispatch, SetStateAction } from 'react';
import { Platform } from 'react-native';

import Constants from 'expo-constants';

import {
    ApolloClient,
    ApolloLink,
    HttpLink,
    InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { loadSessionTokenFromStorage } from '@contexts/SessionContext';
import { APP_ENV, GRAPHQL_ENDPOINT } from '@env';
import {
    appVersion,
    getFullDeviceName,
    isIOS,
    otaUpdateGroup,
} from '@helpers/app';
import { getDeviceId } from '@helpers/deviceId';
import { isNetworkServerError } from '@helpers/errors';
import { getLanguage } from '@i18n/i18n';

import ErrorReporting from '../utils/ErrorReporting';

const GlobalErrorEndpoints = ['Me', 'Config'];

const config = {
    apiVersion: '29.0.0',
};

function createApolloClient(
    setSessionToken: (token: string | null) => void,
    setIsGlobalApolloError: Dispatch<SetStateAction<string | null>>,
    setIsUpdateRequired: Dispatch<SetStateAction<boolean>>,
    setIsGameMaintenance: Dispatch<SetStateAction<boolean>>,
    setIsFullMaintenance: Dispatch<SetStateAction<boolean>>
) {
    const authLink = setContext(async (request, { headers }) => {
        // return the headers to the context so httpLink can read them
        const uniqueId = await getDeviceId();
        // get session ID
        const sessionToken = await loadSessionTokenFromStorage();
        // OTA Group
        const updateGroup = otaUpdateGroup;
        // Get locale
        const locale = await getLanguage();
        // For iOS in order to disable in-app purchases, we need to change platform from original ios
        const platform =
            APP_ENV === 'local' && isIOS ? 'ios-local' : Platform.OS;
        // For notifications
        const packageName = Constants.expoConfig?.owner
            ? `${Constants.expoConfig?.owner}/${Constants.expoConfig?.slug}`
            : null;

        // Log request
        Console.log(
            '[ApolloClient]',
            `x-app-version=${appVersion}`,
            `x-platform=${Platform.OS}`,
            `x-api-version=${config.apiVersion}`,
            `operation=${request.operationName}` // + `, sessionToken=${sessionToken}`
        );

        return {
            headers: {
                ...headers,
                'x-locale': locale.toUpperCase(),
                'x-api-version': config.apiVersion,
                'x-platform': platform,
                'x-device-id': uniqueId,
                'x-app-version': appVersion,
                'x-ota-update-group': updateGroup,
                'x-device-name': getFullDeviceName(),
                'x-package-name': packageName,
                authorization: sessionToken ? `Bearer ${sessionToken}` : '',
            },
        };
    });

    const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
        if (graphQLErrors) {
            for (const error of graphQLErrors) {
                const { code, action } = error.extensions;

                // Error message for GraphQL errors
                const message = `[ApolloClient] GraphQL error. Message: ${
                    error.message
                }, Location: ${JSON.stringify(error.locations)}, Path: ${
                    error.path
                }, Extensions: ${code}, ${action}`;

                // Log it
                Console.error(message);

                switch (action) {
                    case 'LOGOUT':
                        Console.info(
                            '[ApolloClient] Force logout',
                            `operation=${operation.operationName}`
                        );
                        setSessionToken(null);
                        return;

                    case 'FORCE_UPDATE':
                        Console.info(
                            '[ApolloClient] setIsUpdateRequired(true)',
                            `operation=${operation.operationName}`
                        );
                        setIsUpdateRequired(true);
                        return;

                    case 'GAME_MAINTENANCE': {
                        Console.info(
                            '[ApolloClient] setIsGameMaintenance(true)',
                            `operation=${operation.operationName}`
                        );
                        setIsGameMaintenance(true);
                        return;
                    }

                    case 'FULL_MAINTENANCE': {
                        Console.info(
                            '[ApolloClient] setIsFullMaintenance(true)',
                            `operation=${operation.operationName}`
                        );
                        setIsFullMaintenance(true);
                        return;
                    }

                    default:
                        break;
                }

                switch (code) {
                    case 'UNAUTHENTICATED':
                        Console.info(
                            '[ApolloClient] Force logout',
                            `operation=${operation.operationName}`
                        );
                        setSessionToken(null);
                        return;

                    default:
                        // Set Global Error State if error matches certain endpoints from config
                        if (
                            GlobalErrorEndpoints.includes(
                                operation.operationName
                            )
                        ) {
                            Console.info(
                                `[ApolloClient] setIsGlobalApolloError ${error.message}`,
                                `operation=${operation.operationName}`
                            );
                            if (
                                code === 'NOT_FOUND' &&
                                operation.operationName === 'Me'
                            ) {
                                setSessionToken(null);
                                return;
                            }

                            setIsGlobalApolloError(operation.operationName);
                        }

                        ErrorReporting.report(new Error(message));
                        break;
                }
            }
        }

        if (networkError && isNetworkServerError(networkError)) {
            ErrorReporting.report(networkError);

            Console.error(
                `[ApolloClient] Server error: ${networkError}`,
                `operation=${operation.operationName}`
            );

            if (GlobalErrorEndpoints.includes(operation.operationName)) {
                Console.info(
                    `[ApolloClient] setIsGlobalApolloError ${networkError.message}`,
                    `operation=${operation.operationName}`
                );

                setIsGlobalApolloError(operation.operationName);
            }
            // true network connection error
        } else if (networkError) {
            Console.error(
                `[ApolloClient] Network error: ${networkError}`,
                `operation=${operation.operationName}`
            );
        }
    });

    const retryLink = new RetryLink({
        delay: {
            initial: 5000,
            max: 10000,
        },
        attempts: {
            max: Infinity,
            retryIf: (error, operation) => {
                const shouldRetry =
                    !!error &&
                    GlobalErrorEndpoints.includes(operation.operationName);

                if (shouldRetry) {
                    console.error(
                        'retryLink',
                        `operation=${operation.operationName}`,
                        `shouldRetry=${shouldRetry}`,
                        error
                    );
                }

                return shouldRetry;
            },
        },
    });

    const responseLink = new ApolloLink((operation, forward) => {
        return forward(operation).map((response) => {
            if (operation.operationName === 'Me') {
                if (response?.data) {
                    // Console.info('[ApolloClient] setIsFullMaintenance(false)');
                    setIsFullMaintenance(false);
                }
            }

            if (operation.operationName === 'GameMaintenance') {
                if (
                    response?.data &&
                    !response?.data?.config.isGameMaintenance
                ) {
                    // Console.info('[ApolloClient] setIsGameMaintenance(false)');
                    setIsGameMaintenance(false);
                }
            }

            if (
                GlobalErrorEndpoints.includes(operation.operationName) &&
                response.data
            ) {
                // Console.info('[ApolloClient] setIsGlobalApolloError(null)');
                setIsGlobalApolloError(null);
            }

            return response;
        });
    });

    const httpLink = new HttpLink({
        // Uncomment for testing production locally
        // uri: 'https://api-gw.iguverse.io/graphql',
        uri: GRAPHQL_ENDPOINT,
    });

    return new ApolloClient({
        link: authLink
            .concat(retryLink)
            .concat(errorLink)
            .concat(responseLink)
            .concat(httpLink),
        cache: new InMemoryCache({
            typePolicies: {
                Query: {
                    fields: {
                        pets: {
                            merge(existing = [], incoming = []) {
                                return incoming;
                            },
                        },
                    },
                },
            },
        }),
    });
}

export default createApolloClient;
