import * as React from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';

import useThemedStyles from '../../hooks/useThemedStyles';
import Icon, { ICON_NAMES } from '../Icons';
import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    customStyles?: StyleProp<ViewStyle>;
    label: string;
    enabled: boolean;
    onPress: () => void;
}

const Checkbox = ({ customStyles, label, enabled, onPress }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const boxStyle = React.useMemo(() => {
        return enabled ? styles.enabled : styles.disabled;
    }, [enabled]);

    return (
        <TouchableOpacity
            style={[styles.container, customStyles]}
            onPress={onPress}
            activeOpacity={1}>
            <View style={[styles.box, boxStyle]}>
                {enabled && <Icon name={ICON_NAMES.CHECK} />}
            </View>
            <Text style={styles.label}>{label}</Text>
        </TouchableOpacity>
    );
};
export default Checkbox;
