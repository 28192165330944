import * as React from 'react';

import T1 from '@assets/icons/mysteryGames/LuckyStrike/tickets/1.svg';
import T2 from '@assets/icons/mysteryGames/LuckyStrike/tickets/2.svg';
import T3 from '@assets/icons/mysteryGames/LuckyStrike/tickets/3.svg';
import T4 from '@assets/icons/mysteryGames/LuckyStrike/tickets/4.svg';
import T5 from '@assets/icons/mysteryGames/LuckyStrike/tickets/5.svg';
import T6 from '@assets/icons/mysteryGames/LuckyStrike/tickets/6.svg';

import { IconType, iconSize } from '../types';

interface IProps extends IconType {
    slug: number;
}

const LuckyStrikePurchaseIcon = ({ slug, size = iconSize.primary }: IProps) => {
    switch (slug) {
        case 1:
            return <T1 width={size} height={size} />;
        case 2:
            return <T2 width={size} height={size} />;
        case 3:
            return <T3 width={size} height={size} />;
        case 4:
            return <T4 width={size} height={size} />;
        case 5:
            return <T5 width={size} height={size} />;
        case 6:
            return <T6 width={size} height={size} />;
        default:
            return <T6 width={size} height={size} />;
    }
};

export default LuckyStrikePurchaseIcon;
