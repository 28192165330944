import { useCallback, useMemo, useState } from 'react';

import { STEP_INDICATOR_FLOWS } from '@components/FlowStepIndicator';
import { STAKING_ADD_MORE_NAME } from '@components/FlowStepIndicator/flows/stakingAddMore';
import { STAKING_STAGE_NAME } from '@components/FlowStepIndicator/flows/stakingStages';
import { useFocusEffect } from '@react-navigation/native';

export const useFlowIndicator = (isAddMoreCoins: boolean) => {
    const [stageFill, setStageFill] = useState('0%');
    useFocusEffect(
        useCallback(() => {
            setStageFill('0%');
        }, [])
    );

    const indicatorFlow = useMemo(
        () =>
            isAddMoreCoins
                ? STEP_INDICATOR_FLOWS.STAKING_ADD_MORE
                : STEP_INDICATOR_FLOWS.STAKING,
        [isAddMoreCoins]
    );
    const indicatorStage = useMemo(
        () =>
            isAddMoreCoins
                ? STAKING_ADD_MORE_NAME.STEP_ONE
                : STAKING_STAGE_NAME.STEP_THREE,
        [isAddMoreCoins]
    );

    return {
        setStageFill,
        stageFill,
        indicatorFlow,
        indicatorStage,
    };
};
