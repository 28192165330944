import * as React from 'react';
import { useState } from 'react';
import { KeyboardAvoidingView, SafeAreaView, ScrollView } from 'react-native';

import { LinearGradient } from 'expo-linear-gradient';

import {
    CompleteVerificationTaskItem,
    VerificationTaskItem,
    VerificationTaskResponseItem,
} from '@Data/Models';
import { ApolloError } from '@apollo/client';
import Button from '@components/Button';
import CustomModal from '@components/CustomModal';
import ImageIguana from '@components/ImageIguana';
import Input from '@components/Input';
import ModalLoader from '@components/ModalLoader';
import PetCollectionListItem from '@components/PetCollectionListItem';
import { View } from '@components/Themed';
import VerificationTips from '@components/VerificationTips';
import Colors from '@constants/Colors';
import { REGEX_PATTERNS } from '@constants/Regex';
import { PlayContext } from '@contexts/PlayContext';
import { useTheme } from '@contexts/ThemeContext';
import { useUser } from '@contexts/UserContext';
import { isIOS, isWeb, isWebAndroid } from '@helpers/app';
import { errorsHandler } from '@helpers/errors';
import { sliceLink, waitForDeepLink } from '@helpers/helpers';
import { toastError, toastErrorUnknown } from '@helpers/toastNotification';
import useTasksErrorsHandler from '@hooks/tasks/useTasksErrorsHandler';
import useVerifyTask from '@hooks/tasks/useVerifyTask';
import useQrCodeScanner from '@hooks/useQrCodeScanner';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { StackActions } from '@react-navigation/native';

import { SocializeToEarnVerifyUserProps } from '../../../../../types';
import HeaderText from '../components/HeaderText';
import SocialNetworkCard from '../components/SocialNetworkCard';
import stylesMain from './styles';

type TOKEN_STATE = 'NOT_FOUND' | 'FINAL' | 'FAILED' | 'ERROR';

export const VerifyUserScreen = ({ route }: SocializeToEarnVerifyUserProps) => {
    const navigation = useNavigation();
    const { theme } = useTheme();
    const styles = useThemedStyles(stylesMain);
    const { verifyTask, getCurrentVerifySubtask, setVerifyTask } =
        React.useContext(PlayContext);
    const { reloadUser } = useUser();

    const { completeVerificationTask, refetchVerifyTask } = useVerifyTask();

    const [verifyLoading, setVerifyLoading] = React.useState<boolean>(false);

    const [idNotFoundModal, setIdNotFoundModalVisible] =
        React.useState<boolean>(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const showContent = !isFocused || (isWeb && !isWebAndroid);
    const [petID, setPetID] = React.useState(route.params?.petId ?? '');
    const [errorID, setErrorID] = React.useState('');

    const [lastDeepLinkId, setLastDeepLinkId] = React.useState('');

    const { taskWrongState } = useTasksErrorsHandler();

    const scanAction = (code: string) => {
        const slicedLink = sliceLink(code);
        if (!slicedLink) {
            toastErrorUnknown('top');
        } else {
            setPetID(slicedLink);
            handleVerification(false, slicedLink);
        }
    };
    const { scanButton, scannerModal } = useQrCodeScanner('verify', scanAction);

    // Handle deep links
    React.useLayoutEffect(() => {
        if (route.params?.petId) {
            if (lastDeepLinkId === route.params?.petId) {
                return;
            }
            setLastDeepLinkId(route.params?.petId);
            if (verifyTask?.currentTask?.id) {
                waitForDeepLink().then(() => {
                    setPetID(route.params.petId || '');
                    handleVerification(false, route.params.petId);
                });
            } else {
                if (!navigation.canGoBack()) {
                    waitForDeepLink().then(() => {
                        navigation.replace(ROUTES.MAIN);
                        navigation.navigateToTasks();
                        toastError(
                            i18n.t(
                                'socializeToEarn.verifyUser.toastNoPetVerification.title'
                            ),
                            i18n.t(
                                'socializeToEarn.verifyUser.toastNoPetVerification.text'
                            ),
                            'top'
                        );
                    });
                } else {
                    waitForDeepLink().then(() => {
                        navigation.pop();
                        navigation.navigateToTasks();
                        toastError(
                            i18n.t(
                                'socializeToEarn.verifyUser.toastNoPetVerification.title'
                            ),
                            i18n.t(
                                'socializeToEarn.verifyUser.toastNoPetVerification.text'
                            ),
                            'top'
                        );
                    });
                }
            }
        }
    }, [verifyTask, lastDeepLinkId, route.params?.petId]);

    const handleNotFoundPress = () => {
        setIdNotFoundModalVisible(true);
    };

    const handleChangeText = React.useCallback(
        (text: string) => {
            setPetID(text.toUpperCase());
            if (!text.match(REGEX_PATTERNS.verifyId.regex)) {
                setPetID(petID);
                setErrorID(i18n.t('socializeToEarn.verifyUser.regexError'));
                return;
            }
            setErrorID('');
        },
        [petID]
    );

    const handleRightToken = (
        task: CompleteVerificationTaskItem,
        token: string | null
    ): TOKEN_STATE => {
        if (!token) {
            return 'NOT_FOUND';
        }
        if (task.status === 'CREATED') {
            setErrorID(
                i18n.t('socializeToEarn.verifyUser.errorMessage', {
                    attempts: task.remainingVerificationAttempts,
                })
            );
            return 'ERROR';
        }
        if (task.status === 'FAILED') {
            return 'FAILED';
        }
        return 'FINAL';
    };

    const handleErrors = (error: ApolloError) => {
        setVerifyLoading(false);
        if (!taskWrongState(error)) {
            toastErrorUnknown();
        }
    };

    const handleCompleteVerificationSubtask = async (
        token: string | null,
        currentSubtask: VerificationTaskItem | null
    ) => {
        await completeVerificationTask({
            variables: {
                taskId: currentSubtask?.currentTask?.id,
                token,
            },
            onError: (error) => handleErrors(error),
            onCompleted: async (data) => {
                reloadUser();
                const result = handleRightToken(
                    data.completeVerificationTask,
                    token
                );
                if (result !== 'ERROR') {
                    const taskData = await refetchVerifyTask();
                    if (taskData.data) {
                        setVerifyLoading(false);
                        setVerifyTask(taskData.data.verificationTaskConfig);
                        handleFinish(
                            getCurrentVerifySubtask(
                                taskData.data.verificationTaskConfig
                            ),
                            result
                        );
                    }
                    setVerifyLoading(false);
                    if (taskData.error) {
                        errorsHandler(taskData.error, true);
                        navigation.pop();
                    }
                }
                setVerifyLoading(false);
            },
        });
    };

    const handleVerification = React.useCallback(
        (notFound: boolean, id?: string) => {
            setVerifyLoading(true);
            setErrorID('');
            if (notFound) {
                handleCompleteVerificationSubtask(null, verifyTask);
                return;
            }
            handleCompleteVerificationSubtask(id || petID, verifyTask);
        },
        [petID, verifyTask]
    );

    const handleFinish = (
        currentSubtask: VerificationTaskResponseItem | null,
        state: TOKEN_STATE
    ) => {
        if (state === 'NOT_FOUND') {
            navigation.dispatch(
                StackActions.replace(
                    ROUTES.SOCIALIZE_TO_EARN_VERIFY_USER_NOT_FOUND
                )
            );
            return;
        }
        if (state === 'FAILED') {
            navigation.dispatch(
                StackActions.replace(
                    ROUTES.SOCIALIZE_TO_EARN_VERIFY_USER_FAILED
                )
            );
            return;
        }
        if (state === 'FINAL') {
            navigation.dispatch(
                StackActions.replace(
                    ROUTES.SOCIALIZE_TO_EARN_VERIFY_USER_MATCH,
                    {
                        currentSubtask,
                    }
                )
            );
            return;
        }
    };

    return (
        <View style={styles.mainContainer}>
            <NavigationBar
                backIcon
                containerStyle={styles.navBg}
                title={i18n.t('socializeToEarn.verifyUser.title')}
            />
            {showContent && (
                <LinearGradient
                    colors={[
                        Colors[theme].gradient.start,
                        Colors[theme].gradient.end,
                    ]}
                    style={styles.gradientBackground}
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 1 }}
                />
            )}
            <KeyboardAvoidingView
                style={styles.container}
                behavior={isIOS ? 'padding' : 'height'}
                contentContainerStyle={styles.avoidingView}>
                {!!verifyTask?.currentTask && (
                    <>
                        <View style={styles.main}>
                            {showContent && (
                                <View style={[styles.shadowContainer]}>
                                    <View style={styles.playCardWrapper}>
                                        <PetCollectionListItem
                                            pet={
                                                verifyTask.currentTask
                                                    .socialTask.pet
                                            }
                                            style={styles.playCard}
                                        />
                                    </View>
                                    <View style={styles.shadow} />
                                </View>
                            )}
                            <ScrollView
                                style={styles.wrapper}
                                showsVerticalScrollIndicator={false}
                                keyboardShouldPersistTaps="handled">
                                {showContent && (
                                    <HeaderText
                                        title={i18n.t(
                                            'socializeToEarn.verifyUser.title'
                                        )}
                                        text={i18n.t(
                                            'socializeToEarn.verifyUser.text',
                                            {
                                                energy: verifyTask.currentTask
                                                    .maxEnergyReward,
                                            }
                                        )}
                                        textInfo={i18n.t(
                                            'socializeToEarn.verifyUser.info'
                                        )}
                                        textInfoOnPress={() =>
                                            navigation.navigate(
                                                ROUTES.HOW_TO_VERIFY_USER
                                            )
                                        }
                                        containerStyle={styles.header}
                                    />
                                )}
                                <SocialNetworkCard
                                    containerStyle={styles.socialCardWrapper}
                                    socialTask={
                                        verifyTask.currentTask.socialTask
                                    }
                                    withLink
                                />
                                <View style={styles.petIdWrapper}>
                                    <Input
                                        label={i18n.t(
                                            'socializeToEarn.verifyUser.label'
                                        )}
                                        autoCapitalize="characters"
                                        style={styles.input}
                                        value={petID.toUpperCase() || ''}
                                        onChangeText={(text) =>
                                            handleChangeText(text)
                                        }
                                        component={scanButton()}
                                        placeholder={i18n.t(
                                            'socializeToEarn.verifyUser.placeHolder'
                                        )}
                                        placeholderTextColor={
                                            Colors[theme].text.secondary
                                        }
                                        maxLength={20}
                                        error={errorID}
                                        onFocus={() => {
                                            setIsFocused(true);
                                        }}
                                        onBlur={() => {
                                            setIsFocused(false);
                                        }}
                                    />
                                </View>
                                <VerificationTips
                                    containerStyle={styles.tips}
                                />
                            </ScrollView>
                        </View>
                        <View style={styles.buttonWrapper}>
                            <Button
                                title={i18n.t(
                                    'socializeToEarn.verifyUser.buttonVerify'
                                )}
                                onPress={() => handleVerification(false)}
                                containerStyle={styles.gap}
                                disabled={!petID || !!errorID || verifyLoading}
                            />
                            <Button
                                title={i18n.t(
                                    'socializeToEarn.verifyUser.buttonNotFound'
                                )}
                                type={'outline'}
                                onPress={handleNotFoundPress}
                            />
                        </View>
                        <SafeAreaView />
                    </>
                )}
            </KeyboardAvoidingView>
            <CustomModal
                isVisible={idNotFoundModal}
                icon={<ImageIguana type="attention" />}
                titleText={i18n.t(
                    'socializeToEarn.verifyUser.modalPetIdNotFound.title'
                )}
                infoText={i18n.t(
                    'socializeToEarn.verifyUser.modalPetIdNotFound.text'
                )}
                firstButtonText={i18n.t(
                    'socializeToEarn.verifyUser.modalPetIdNotFound.button1'
                )}
                secondButtonText={i18n.t(
                    'socializeToEarn.verifyUser.modalPetIdNotFound.button2'
                )}
                onFirstButtonClick={() => {
                    setIdNotFoundModalVisible(false);
                    handleVerification(true);
                }}
                onSecondButtonClick={() => setIdNotFoundModalVisible(false)}
            />
            <ModalLoader
                isVisible={verifyLoading}
                text={i18n.t('socializeToEarn.verifyUser.loadingVerifyUser')}
            />
            {scannerModal()}
        </View>
    );
};
export default VerifyUserScreen;
