import * as React from 'react';
import { StyleProp, TextLayoutLine, TextStyle } from 'react-native';

import { Text, TextProps } from '../../components/Themed';
import useThemedStyles from '../../hooks/useThemedStyles';
import stylesColor from './colorStyles';
import { TYPO_TEXT, TYPO_TEXT_COLOR } from './types';
import stylesTypo from './typoStyles';

interface IProps extends TextProps {
    additionalStyles?: StyleProp<TextStyle>;
    type: TYPO_TEXT;
    colorType?: TYPO_TEXT_COLOR;
    textAlign?: 'auto' | 'left' | 'right' | 'center' | 'justify';
    oneTheme?: boolean;
    flex?: boolean;
}

const TypoText = ({
    type = TYPO_TEXT.DEFAULT,
    additionalStyles,
    colorType,
    textAlign,
    oneTheme,
    flex = false,
    ...props
}: IProps) => {
    const typoStyles = useThemedStyles(stylesTypo, oneTheme);
    const colorStyles = useThemedStyles(stylesColor, oneTheme);
    return (
        <Text
            style={[
                typoStyles[type],
                colorType && colorStyles[colorType],
                !!textAlign && { textAlign },
                flex && { flex: 1 },
                additionalStyles,
            ]}
            {...props}
        />
    );
};
export default TypoText;
