import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';
import { stylesModalNavigationFix } from '@helpers/style';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
            ...stylesModalNavigationFix(),
        },

        header: {
            paddingHorizontal: scale(20),
            backgroundColor: 'transparent',
        },

        listWrapper: {
            flex: 1,
            paddingHorizontal: scale(20),
        },
    });

export default styles;
