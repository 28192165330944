import * as React from 'react';
import { useState } from 'react';
import { Platform } from 'react-native';
import {
    GestureDetector,
    GestureHandlerRootView,
} from 'react-native-gesture-handler';
import Animated from 'react-native-reanimated';

import { ResizeMode, Video } from 'expo-av';
import { BlurView } from 'expo-blur';

import Button from '@components/Button';
import ColorPicker from '@components/ColorPicker';
import GestureHandler from '@components/GesturePanPinch';
import { ICON_NAMES } from '@components/Icons';
import ImageBackgroundBlur from '@components/ImageBackgroundBlur';
import { View } from '@components/Themed';
import ViewShot from '@components/ViewShot';
import ZoomButtons from '@components/ZoomButtons';
import { useDimensions } from '@contexts/DimensionsContext';
import { isDesktop } from '@helpers/app';
import { verticalScale } from '@helpers/dimensions';
import { useBackGroundDraggable } from '@hooks/useBackGroundDraggable';
import { usePetIdDraggable } from '@hooks/usePetIdDraggable';
import useSharePhoto from '@hooks/useShare';
import useTemplateColors from '@hooks/useTemplateColors';
import useThemedStyles from '@hooks/useThemedStyles';
import { useNavigation } from '@navigation/useNavigation';

import { SocializeToEarnCreateSharePhotoProps } from '../../../../../types';
import PetCardIdNew from '../../components/PetCardIdNew';
import ModalShareYourPhoto from '../components/ModalShareYourPhoto';
import stylesMain from './styles';

export const CreateSharePhotoScreen = ({
    route,
}: SocializeToEarnCreateSharePhotoProps) => {
    const styles = useThemedStyles(stylesMain);
    const { windowWidth, windowHeight } = useDimensions();
    const { safeTop, safeBottom } = useDimensions();
    const [colorPickerHeight, setColorPickerHeight] = React.useState<number>(0);
    const { templatesColors, changeTemplateColor, templateColor } =
        useTemplateColors();
    const { uri, type } = route.params;
    const navigation = useNavigation();

    const viewShotRef = React.useRef<any>(null);
    const refVideo = React.useRef<Video>(null);

    const [isVisibleModalShare, setIsVisibleModalShare] =
        useState<boolean>(false);

    const handleShareYourPhotoModalOpen = () => {
        setIsVisibleModalShare(true);
    };

    const {
        handleShareViewShot,
        handleSaveViewShot,
        renderCameraRollPermissions,
    } = useSharePhoto();

    const handleSave = async () => {
        return handleSaveViewShot(viewShotRef);
    };

    // show loader only on web as we are downloading and image
    const handleShare = async () => {
        return handleShareViewShot(viewShotRef);
    };

    const handleGoBack = () => {
        navigation.goBack();
    };

    const { petIdGesture, animatedPetIdStyles, setCardDefaultPosition } =
        usePetIdDraggable({
            colorPickerHeight,
        });

    const [imageHeight, setImageHeight] = React.useState(0);

    const {
        gesturePan,
        gesturePinch,
        animatedBackgroundStyles,
        zoomMinus,
        zoomPlus,
        gesture,
    } = useBackGroundDraggable({ imageHeight });

    const renderColorPicker = () => {
        return (
            <BlurView
                onLayout={(eve) =>
                    setColorPickerHeight(eve.nativeEvent.layout.height)
                }
                intensity={50}
                style={[
                    styles.colorPickerWrapper,
                    {
                        paddingBottom:
                            Platform.OS === 'android'
                                ? verticalScale(safeBottom + 16)
                                : verticalScale(safeBottom),
                    },
                ]}>
                {!!templatesColors && !!templateColor && (
                    <ColorPicker
                        handleBgColor={changeTemplateColor}
                        backgrounds={templatesColors}
                        templateColor={templateColor}
                    />
                )}
            </BlurView>
        );
    };

    const renderVideoEditor = () => (
        <Video
            ref={refVideo}
            style={[
                styles.max,
                {
                    width: windowWidth,
                    height: windowHeight,
                },
            ]}
            source={{
                uri,
            }}
            shouldPlay
            resizeMode={ResizeMode.COVER}
            isLooping
        />
    );

    const renderImageEditor = () => (
        <ImageBackgroundBlur
            source={{ uri }}
            style={{
                ...styles.max,
                ...{
                    width: windowWidth,
                    height: windowHeight,
                },
            }}
            blurRadius={200}
            resizeMethod="resize">
            <GestureHandler
                gesturePan={gesturePan}
                gesturePinch={gesturePinch}
                gesture={gesture}>
                <Animated.Image
                    onLoad={(event) => {
                        // TODO: somehow on the web it's not present, fix it
                        if (event.nativeEvent.source) {
                            const ratio =
                                event.nativeEvent.source.height /
                                event.nativeEvent.source.width;
                            setImageHeight(windowWidth * ratio);
                        }
                    }}
                    resizeMode="contain"
                    source={{ uri }}
                    style={[
                        styles.max,
                        {
                            width: windowWidth,
                            height: windowHeight,
                        },
                        animatedBackgroundStyles,
                    ]}
                />
            </GestureHandler>
        </ImageBackgroundBlur>
    );

    const renderPetId = () => {
        return (
            <GestureDetector gesture={petIdGesture}>
                <Animated.View
                    onLayout={(eve) =>
                        setCardDefaultPosition(eve.nativeEvent.layout)
                    }
                    style={[
                        styles.card,
                        {
                            bottom: colorPickerHeight + verticalScale(24),
                            width: windowWidth - styles.cardGap.width,
                        },
                        animatedPetIdStyles,
                    ]}>
                    <PetCardIdNew templateColor={templateColor} />
                </Animated.View>
            </GestureDetector>
        );
    };

    return (
        <>
            <View
                style={[
                    styles.arrowBack,
                    { top: verticalScale(safeTop + 16) },
                ]}>
                <Button
                    onPress={handleGoBack}
                    type="outline"
                    size="md"
                    iconName={ICON_NAMES.ARROW_LEFT}
                />
            </View>
            <View style={[styles.share, { top: verticalScale(safeTop + 16) }]}>
                <Button
                    onPress={handleShareYourPhotoModalOpen}
                    type="fill"
                    iconColor="onDark"
                    size="md"
                    iconName={ICON_NAMES.SHARE}
                />
                {isDesktop && (
                    <ZoomButtons
                        onPressPlus={zoomPlus}
                        onPressMinus={zoomMinus}
                    />
                )}
            </View>

            {renderColorPicker()}

            <GestureHandlerRootView>
                <ViewShot
                    ref={viewShotRef}
                    options={{
                        format: 'png',
                        quality: 1,
                    }}>
                    <View
                        style={[
                            styles.container,
                            {
                                width: windowWidth,
                                height: windowHeight,
                            },
                        ]}
                        collapsable={false}>
                        {type === 'video'
                            ? renderVideoEditor()
                            : renderImageEditor()}
                        {renderPetId()}
                    </View>
                </ViewShot>
            </GestureHandlerRootView>

            <ModalShareYourPhoto
                modalHeight={450}
                isSmallCard
                isVisible={isVisibleModalShare}
                setIsVisible={setIsVisibleModalShare}
                handleSave={handleSave}
                handleShare={handleShare}>
                {renderCameraRollPermissions(handleSave)}
            </ModalShareYourPhoto>
        </>
    );
};
export default CreateSharePhotoScreen;
