import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { scale, verticalScale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        wrapper: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        container: {
            flex: 1,
            borderRadius: 1000,
            height: 8,
            backgroundColor: Colors[theme].levelIndicatorBackGround,
        },
        level: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),
            textTransform: 'uppercase',
            marginLeft: scale(6),
        },
        fullViewLvl: {
            color: Colors[theme].text.warning,
        },
        noMargin: {
            marginLeft: 0,
        },
        fill: {
            borderTopRightRadius: 1000,
            borderTopLeftRadius: 1000,
            borderBottomLeftRadius: 1000,
            borderBottomRightRadius: 1000,
            position: 'absolute',
            left: 0,
            top: 0,
            height: 8,
            backgroundColor: Colors[theme].levelIndicatorFillColor,
        },
        fullWrapper: {
            zIndex: 10,
            justifyContent: 'space-between',
        },
        fullContainer: {
            width: '100%',
            borderRadius: 1000,
            height: 12,
            backgroundColor: Colors[theme].levelIndicatorBackGround,
            marginBottom: verticalScale(6),
        },
        extraMargin: {
            marginBottom: verticalScale(18),
        },
        fullFill: {
            height: 12,
        },
        leftContent: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        xp: {
            color: Colors[theme].levelProgress.low,
            ...font({
                size: 13,
                height: 18,
                weight: '600',
            }),
            textTransform: 'uppercase',
        },
        actualXP: {
            color: Colors[theme].levelProgress.low,
            ...font({
                size: 13,
                height: 18,
                weight: '600',
            }),
            textTransform: 'uppercase',
        },
        containerXP: {
            flexDirection: 'row',
        },
        textLevelContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        maxFill: {
            color: Colors[theme].text.accent,
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),
        },
        expEarned: {
            color: Colors[theme].text.accent,
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),
        },
    });

export default styles;
