import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import { verticalScale } from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            alignItems: 'center',
        },
        textTotal: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
        textBalance: {
            color: Colors[theme].text.primary,
            ...font({
                size: 28,
                height: 36,
                weight: '700',
            }),
        },
        textBalanceDollar: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
        gap: {
            marginBottom: verticalScale(6),
        },
    });

export default styles;
