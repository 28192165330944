import * as React from 'react';
import { FlatList, TouchableOpacity, View } from 'react-native';

import { ImaginationFormResponseOptions } from '../../../../Data/Models';
import Icon, { ICON_NAMES } from '../../../../components/Icons';
import { Text } from '../../../../components/Themed';
import { useDimensions } from '../../../../contexts/DimensionsContext';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import ModalAiForm from '../ModalAiForm';
import stylesMain from './styles';

interface IProps {
    name: string;
    value: string | string[];
    isRequired: boolean;
    setFieldValue: (
        field: string,
        value: string | string[],
        shouldValidate?: boolean | undefined
    ) => void;
    data: ImaginationFormResponseOptions[] | undefined;
    maxSelections: number;
}

const SelectableFormOption = ({
    isRequired,
    setFieldValue,
    name,
    value,
    data,
    maxSelections,
}: IProps) => {
    const { windowHeight } = useDimensions();
    const styles = useThemedStyles(stylesMain);
    const [isModalVisible, setModalVisible] = React.useState<boolean>(false);
    const handleCloseModal = () => {
        setModalVisible(false);
    };

    const placeHolder = i18n.t(`nftSelectGeneration.form.${name}.placeHolder`);

    const findValueId = React.useCallback(
        (value: string) => {
            const id = data?.find((o) => o.name === value)?.id;

            return id || '';
        },
        [data]
    );

    const handlePressOnSelect = React.useCallback(
        (itemValue: string) => {
            if (itemValue === value) {
                setFieldValue(name, '');
                if (name === 'animal') {
                    setFieldValue('animalId', '');
                }
            } else {
                setFieldValue(name, itemValue);
                if (name === 'animal') {
                    setFieldValue('animalId', findValueId(itemValue));
                }
            }

            handleCloseModal();
        },
        [value]
    );
    const handlePressOnMulti = (data: string[]) => {
        setFieldValue(name, data);
        handleCloseModal();
    };
    const handleDelete = (deleteValue: string) => {
        const newValues =
            Array.isArray(value) && value?.filter((val) => val !== deleteValue);
        setFieldValue(name, newValues || []);
    };

    const modalHeight = React.useMemo(
        () =>
            name === 'animal' || name === 'accessories'
                ? windowHeight * 0.85
                : 600,
        [windowHeight, name]
    );

    const handleClearValue = () => {
        setFieldValue(name, '');
    };
    return (
        <View style={styles.gap}>
            <Text style={styles.label}>
                {i18n.t(`nftSelectGeneration.form.${name}.label`)}
                {isRequired && <Text style={styles.required}>*</Text>}
            </Text>
            <TouchableOpacity
                onPress={() => setModalVisible(true)}
                style={styles.container}
                activeOpacity={0.7}>
                <View style={styles.inputWrapper}>
                    <View style={styles.right}>
                        {Array.isArray(value) && value?.length > 0 ? (
                            <FlatList
                                removeClippedSubviews={false}
                                showsHorizontalScrollIndicator={false}
                                keyboardShouldPersistTaps={'handled'}
                                horizontal
                                numColumns={1}
                                data={value}
                                renderItem={({ item, index }) => (
                                    <TouchableOpacity
                                        key={index}
                                        style={styles.multiselectItem}
                                        activeOpacity={0.7}
                                        onPress={() => handleDelete(item)}>
                                        <Text
                                            style={styles.multiselectItemText}>
                                            {item}
                                        </Text>
                                        <Icon
                                            name={ICON_NAMES.CLOSE}
                                            size={16}
                                        />
                                    </TouchableOpacity>
                                )}
                            />
                        ) : (
                            <Text
                                style={[
                                    styles.value,
                                    !value?.length && styles.placeHolder,
                                ]}>
                                {!!value?.length ? value : placeHolder}
                            </Text>
                        )}
                    </View>
                    {!Array.isArray(value) && !!value ? (
                        <TouchableOpacity
                            activeOpacity={0.7}
                            onPress={handleClearValue}>
                            <Icon name={ICON_NAMES.CLOSE} size={16} />
                        </TouchableOpacity>
                    ) : (
                        <Icon
                            name={ICON_NAMES.DOWN_ARROW}
                            size={16}
                            color={styles.icon.color}
                        />
                    )}
                </View>
            </TouchableOpacity>
            <ModalAiForm
                maxSelections={maxSelections}
                selectedItem={value}
                modalHeight={modalHeight}
                data={data}
                isVisible={isModalVisible}
                titleText={i18n.t(
                    `nftSelectGeneration.form.${name}.modalTitle`
                )}
                onClose={handleCloseModal}
                onSelectItemPress={handlePressOnSelect}
                onMultiItemPress={handlePressOnMulti}
            />
        </View>
    );
};
export default SelectableFormOption;
