import React from 'react';
import { SafeAreaView, ScrollView, View } from 'react-native';

import { useRanks } from '@contexts/RanksContext';
import {
    formatBonusReward,
    formatDailyReward,
    getDailyFoodConsumption,
    ranksConfig,
} from '@helpers/ranks';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import ModalBottom from '../../ModalBottom';
import RankDetailedInfo from '../RankDetailedInfo';
import RankUpHeader from '../RankUpHeader';
import mainStyles from './styles';

interface IProps {
    isVisible: boolean;
    closeAction: () => void;
    rankSlug: string;
    modalHeight?: number | string;
}

const ModalNFTRankLocked = ({
    isVisible,
    closeAction,
    rankSlug,
    modalHeight = '95%',
}: IProps) => {
    const styles = useThemedStyles(mainStyles);

    const { nextRankBySlug, rankBySlug } = useRanks();
    const previousRank = rankBySlug(rankSlug);
    const nextRank = nextRankBySlug(rankSlug);

    return (
        <ModalBottom
            modalHeight={modalHeight}
            isVisible={isVisible}
            onClose={closeAction}>
            {!!previousRank ? (
                <View style={styles.container}>
                    <ScrollView
                        style={styles.content}
                        showsVerticalScrollIndicator={false}
                        bounces={false}>
                        <RankUpHeader
                            previousRankSlug={previousRank.slug}
                            nextRankSlug={nextRank?.slug}
                            maxPetLevel={previousRank?.maxPetLevel}
                        />
                        <RankDetailedInfo
                            maxAge={previousRank.maxAgeDays}
                            rankSlug={previousRank.slug}
                            title={i18n.t('ranks.rankLongTitle', {
                                rank: i18n.t(
                                    `ranks.list.${previousRank.slug}.title`
                                ),
                            })}
                            text={i18n.t(`ranks.lockedText`, {
                                rank: i18n.t(
                                    `ranks.list.${previousRank.slug}.title`
                                ),
                            })}
                            reward={formatDailyReward(
                                previousRank.baseIgupRewardWei
                            )}
                            rate={previousRank.dailyUnlockRate}
                            consumption={getDailyFoodConsumption(
                                previousRank.rewardConsumptionPercentage
                            )}
                            unlockReward={formatBonusReward(
                                previousRank?.dailyUnlockRate || 1,
                                previousRank?.baseIgupRewardWei
                            )}
                        />
                    </ScrollView>

                    <SafeAreaView />
                </View>
            ) : null}
        </ModalBottom>
    );
};
export default ModalNFTRankLocked;
