import * as React from 'react';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import Button from '@components/Button';
import EmptyList from '@components/EmptyList';
import { ICON_NAMES } from '@components/Icons';
import LoadingComponent from '@components/LoadingComponent';
import PullToRefresh from '@components/PullToRefresh';
import SafeAreaCurvedTabContent from '@components/SafeAreaCurvedTabContent';
import { View } from '@components/Themed';
import { useConfig } from '@contexts/ConfigContext';
import { useUser } from '@contexts/UserContext';
import useGetMyReferrer from '@hooks/useGetMyReferrer';
import useReferrals from '@hooks/useReferrals';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationReferrals from '@navigation/NavigationBar/NavigationReferrals';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import BoostCard from '../components/BoostCard';
import ModalHowBoostWorks from '../components/ModalHowBoostWorks';
import MyReferrer from '../components/MyReferrer';
import ReferralInvitedContent from '../components/ReferralInvitedContent';
import stylesMain from './styles';

const ReferralsAllInvitedScreen = () => {
    const styles = useThemedStyles(stylesMain);

    const { user } = useUser();
    const { config } = useConfig();

    const [viewHeight, setHeightView] = React.useState<number>(0);
    const [modal, setModalVisible] = React.useState<boolean>(false);

    const { refetchMyReferrer, myReferrer, myReferrerError } =
        useGetMyReferrer();

    const {
        getReferralsPaginated,
        pullToRefreshLoading,
        loading,
        getReferrals,
        referralsData,
        loadMore,
        totalReferralUserCountsOutput,
        filter,
        handleChooseFilter,
    } = useReferrals();

    React.useEffect(() => {
        getReferrals(true);
    }, []);

    const onRefresh = () => {
        getReferrals(false);
        refetchMyReferrer();
    };

    const navigation = useNavigation();
    const onViewRewardPress = () => {
        navigation.navigate(ROUTES.WALLET_ROOT, {
            screen: ROUTES.WALLET_REWARDS_TAB,
            params: {
                screen: ROUTES.WALLET_REWARDS_MAIN,
                params: {
                    filter: 'ReferralReward',
                },
            },
        });
    };
    const handleOnSharePress = () => {
        navigation.navigate(ROUTES.REFERRALS_INVITE);
    };

    const handleHowItsWork = () => {
        setModalVisible(true);
    };
    const closeModal = () => {
        setModalVisible(false);
    };

    const currentBoost = React.useMemo(() => {
        return config?.userBoosts.find((o) => o.id === user?.boost);
    }, [user, config]);

    const renderItem = React.useCallback(() => {
        return (
            <>
                <NavigationReferrals currentNavigation="ALL" />

                <View style={styles.content}>
                    <BlockHeader
                        type="primary"
                        title={i18n.t(
                            'referrals.invitedReferrals.header.title'
                        )}
                        text={i18n.t('referrals.invitedReferrals.header.text')}
                        textInfo={i18n.t(
                            'referrals.invitedReferrals.header.infoText'
                        )}
                        textInfoOnPress={handleHowItsWork}
                    />
                    {!referralsData && loading ? (
                        <LoadingComponent containerStyles={styles.empty} />
                    ) : (
                        <>
                            {!!myReferrer?.myReferrer && !myReferrerError && (
                                <MyReferrer user={myReferrer.myReferrer} />
                            )}
                            {!!currentBoost && currentBoost.id !== 'None' && (
                                <BoostCard
                                    containerStyle={styles.card}
                                    type="big"
                                    level={currentBoost.id}
                                    boost={currentBoost.rewardBonusPercentage}
                                    onButtonPress={onViewRewardPress}
                                />
                            )}
                            {!!referralsData && !!referralsData?.length ? (
                                <ReferralInvitedContent
                                    shouldRefresh={pullToRefreshLoading}
                                    filter={filter}
                                    handleChooseFilter={handleChooseFilter}
                                    totalUsers={totalReferralUserCountsOutput}
                                    referrals={referralsData}
                                    onReachEnd={() => getReferralsPaginated()}
                                    onReachEndLoading={loadMore}
                                />
                            ) : (
                                <EmptyList
                                    containerStyle={styles.empty}
                                    emptyTitle={i18n.t(
                                        'referrals.emptyList.title'
                                    )}
                                    emptyText={i18n.t(
                                        'referrals.emptyList.text'
                                    )}
                                    ButtonComponent={
                                        <Button
                                            containerStyle={styles.emptyButton}
                                            title={i18n.t(
                                                'referrals.buttonShare'
                                            )}
                                            iconName={ICON_NAMES.INVITE}
                                            iconColor="onDark"
                                            size="md"
                                            onPress={handleOnSharePress}
                                        />
                                    }
                                    withAnimation="cry"
                                />
                            )}
                        </>
                    )}
                </View>
            </>
        );
    }, [
        loading,
        myReferrer,
        myReferrerError,
        referralsData,
        loadMore,
        viewHeight,
        currentBoost,
        totalReferralUserCountsOutput,
        filter,
        pullToRefreshLoading,
    ]);

    return (
        <SafeAreaCurvedTabContent>
            <View
                onLayout={(event) =>
                    setHeightView(event.nativeEvent.layout.height)
                }
                style={styles.container}>
                <PullToRefresh
                    refreshing={pullToRefreshLoading}
                    textLoading={i18n.t('pullToRefresh.reloadReferrals')}
                    onRefresh={onRefresh}
                    renderItem={renderItem}
                />
            </View>
            <ModalHowBoostWorks
                isVisible={modal}
                closeAction={closeModal}
                boosts={config?.userBoosts}
            />
        </SafeAreaCurvedTabContent>
    );
};
export default ReferralsAllInvitedScreen;
