import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';
import { stylesAlignCenter } from '@helpers/style';

import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: { flex: 1 },
        icon: {
            color: Colors[theme].icon.accent,
        },
        button: {
            marginTop: verticalScale(8),
        },
    });

export default styles;
