import React, { useMemo, useState } from 'react';
import { Animated, TouchableOpacity } from 'react-native';

import { debounce } from 'lodash';

import { useHaptic } from '../../hooks/useHaptic';
import useThemedStyles from '../../hooks/useThemedStyles';
import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    id: string;
    title: string;
    description?: string;
    isActive: boolean;
    onChange: (id: string) => void;
    withBorder?: boolean;
    withPadding?: boolean;
}

const config = {
    animationDuration: 150,
};

const Switch = ({
    id,
    isActive,
    title,
    onChange,
    withBorder = true,
    description,
    withPadding = true,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const [isFirstTime, setFirstTime] = useState<boolean>(true);
    const { triggerMedium } = useHaptic();

    React.useEffect(() => {
        isActive ? positionOn() : positionOff();
        if (isFirstTime) setFirstTime(false);
    }, [isActive, isFirstTime]);

    const positionAnimation = React.useRef(new Animated.Value(0)).current;

    const positionOn = () => {
        Animated.timing(positionAnimation, {
            toValue: 18,
            duration: isFirstTime ? 0 : config.animationDuration,
            useNativeDriver: false,
        }).start();
    };

    const positionOff = () => {
        Animated.timing(positionAnimation, {
            toValue: -2,
            duration: isFirstTime ? 0 : config.animationDuration,
            useNativeDriver: false,
        }).start();
    };

    const handleOnPress = useMemo(
        () =>
            debounce(() => {
                triggerMedium();
                onChange(id);
            }, 500),
        [id, onChange]
    );

    return (
        <View
            style={[
                styles.optionContainer,
                { borderWidth: withBorder ? 1 : 0 },
                withPadding && styles.paddingBottom,
            ]}>
            <View style={styles.textContainer}>
                <Text style={styles.itemTitle}>{title}</Text>
                {!!description && (
                    <Text style={styles.itemDesc}>{description}</Text>
                )}
            </View>
            <TouchableOpacity
                style={styles.switchContainer}
                activeOpacity={1}
                onPress={handleOnPress}>
                <Animated.View
                    style={[styles.shadow, { left: positionAnimation }]}>
                    <View style={styles.switch} />
                </Animated.View>
                <View
                    style={[
                        styles.background,
                        isActive && styles.activeBackground,
                    ]}
                />
            </TouchableOpacity>
        </View>
    );
};

export default Switch;
