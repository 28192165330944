import * as React from 'react';
import { FlatList } from 'react-native';

import { useQuery } from '@apollo/client';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import EmptyList from '@components/EmptyList';
import { ICON_NAMES } from '@components/Icons';
import LoadingComponent from '@components/LoadingComponent';
import { View } from '@components/Themed';
import { stylesAlignCenter } from '@helpers/style';
import { useSaveHeight } from '@hooks/useSaveHeight';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import {
    LuckyStrikeUserTicketOutputResponse,
    MYSTERY_GAMES,
} from '@models/mysteryGames';
import NavigationBarModal from '@navigation/NavigationBar/NavigationBarModal';
import useNavigationListeners from '@navigation/hooks/useNavigationListeners';
import { useNavigation } from '@navigation/useNavigation';
import { GET_LUCKY_STRIKE_USER_TICKETS } from '@requests/luckyStrike';

import { LuckyStrikeUserTicketsProps } from '../../../../../types';
import LuckyStrikeTicketItem from '../../components/LuckyStrikeTicketItem';
import useSecureHashModal from '../../hooks/useSecureHashModal';
import stylesMain from './styles';

const LuckyStrikeUserTicketsScreen = ({
    route,
}: LuckyStrikeUserTicketsProps) => {
    useNavigationListeners();

    const { id: gameId } = route.params;

    const { data, loading } = useQuery<LuckyStrikeUserTicketOutputResponse>(
        GET_LUCKY_STRIKE_USER_TICKETS,
        {
            fetchPolicy: 'network-only',
            variables: {
                gameId,
            },
        }
    );

    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const handleBack = () => {
        navigation.pop();
    };

    const { renderSecureHashModal, openSecureHashModal } = useSecureHashModal();

    const { height, getHeight } = useSaveHeight('userTicket');

    return (
        <View style={styles.main}>
            <View style={styles.header}>
                <NavigationBarModal
                    isOneColor
                    iconName={ICON_NAMES.CLOSE}
                    title={i18n.t('general.KEYS.tickets')}
                    onBack={handleBack}
                    titleStyle={styles.title}
                />
            </View>
            {loading ? (
                <LoadingComponent containerStyles={styles.loading} />
            ) : (
                <>
                    <View style={styles.listWrapper}>
                        <FlatList
                            ListHeaderComponent={
                                !!data?.luckyStrikeUserTickets.length ? (
                                    <BlockHeader
                                        containerStyle={styles.blockHeader}
                                        type={'primary'}
                                        title={i18n.t('general.KEYS.tickets')}
                                        text={i18n.t(
                                            `HomeScreen.${MYSTERY_GAMES.luckyStrike}.LuckyStrikeUserTickets.text`
                                        )}
                                        textInfoOnPress={openSecureHashModal}
                                    />
                                ) : null
                            }
                            columnWrapperStyle={styles.column}
                            numColumns={2}
                            showsVerticalScrollIndicator={false}
                            data={data?.luckyStrikeUserTickets || []}
                            initialNumToRender={10}
                            renderItem={({ item, index }) => (
                                <LuckyStrikeTicketItem
                                    key={index}
                                    item={item}
                                    height={height}
                                    setHeight={getHeight}
                                />
                            )}
                            contentContainerStyle={
                                !data?.luckyStrikeUserTickets.length && {
                                    ...stylesAlignCenter(),
                                }
                            }
                            ListEmptyComponent={
                                <EmptyList
                                    emptyTitle={i18n.t(
                                        'general.errors.emptyErrorScreen.title'
                                    )}
                                    emptyText={i18n.t(
                                        'general.errors.emptyErrorScreen.text'
                                    )}
                                    withAnimation="cry"
                                    tailTitle
                                />
                            }
                        />
                    </View>
                </>
            )}
            {renderSecureHashModal()}
        </View>
    );
};

export default LuckyStrikeUserTicketsScreen;
