import * as React from 'react';

import GamingWalletProvider from '@contexts/GamingWalletContext';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import HowToAvailableRewardsScreen from '../../screens/HowTo/HowToAvailableRewards/HowToAvailableRewardsScreen';
import HowToGamingAvailableWalletScreen from '../../screens/HowTo/HowToGamingAvailableWallet/HowToGamingAvailableWalletScreen';
import HowToGamingPendingWalletScreen from '../../screens/HowTo/HowToGamingPendingWallet/HowToGamingPendingWalletScreen';
import HowToLockedRewardsScreen from '../../screens/HowTo/HowToLockedRewards/HowToLockedRewardsScreen';
import HowToPurchaseBNBScreen from '../../screens/HowTo/HowToPurchaseBNB/HowToPurchaseBNBScreen';
import HowToPurchaseSpadeIdScreen from '../../screens/HowTo/HowToPurchaseSpadeId/HowToPurchaseSpadeIdScreen';
import MainWalletOnBoardingScreen from '../../screens/OnBoarding/MainWalletOnBoarding/MainWalletOnBoardingScreen';
import CoinBalanceScreen from '../../screens/Wallet/Coins/CoinBalance/CoinBalanceScreen';
import CoinsMainScreen from '../../screens/Wallet/Coins/CoinsMain/CoinsMainScreen';
import CoinsTransactionDetailsScreen from '../../screens/Wallet/Coins/TransactionDetails/TransactionDetailsScreen';
import CoinsWithdrawScreen from '../../screens/Wallet/Coins/Withdraw/WithdrawScreen';
import CoinsWithdrawSuccessScreen from '../../screens/Wallet/Coins/WithdrawSuccess/WithdrawSuccessScreen';
import GamingMainScreen from '../../screens/Wallet/Gaming/GamingMain/GamingMainScreen';
import GamingWalletTransactionsDetailsScreen from '../../screens/Wallet/Gaming/GamingWalletTransactionsDetails/GamingWalletTransactionsDetailsScreen';
import TopUpCheckoutScreen from '../../screens/Wallet/Gaming/TopUpCheckout/TopUpCheckoutScreen';
import TopUpGamingWalletScreen from '../../screens/Wallet/Gaming/TopUpGamingWallet/TopUpGamingWalletScreen';
import TopUpSuccessScreen from '../../screens/Wallet/Gaming/TopUpSuccess/TopUpSuccessScreen';
import WithdrawInputAmountScreen from '../../screens/Wallet/Gaming/WithdrawInputAmount/WithdrawInputAmountScreen';
import WithdrawSuccessScreen from '../../screens/Wallet/Gaming/WithdrawSuccess/WithdrawSuccessScreen';
import NFTSListScreen from '../../screens/Wallet/NFTS/NFTSList/NFTSListScreen';
import NFTSMainScreen from '../../screens/Wallet/NFTS/NFTSMain/NFTSMainScreen';
import NFTSTransactionDetailsScreen from '../../screens/Wallet/NFTS/TransactionDetails/TransactionDetailsScreen';
import NFTSWithdrawScreen from '../../screens/Wallet/NFTS/Withdraw/WithdrawScreen';
import NFTSWithdrawSuccessScreen from '../../screens/Wallet/NFTS/WithdrawSuccess/WithdrawSuccessScreen';
import RewardsClaimSuccessScreen from '../../screens/Wallet/Rewards/RewardsClaimSuccess/RewardsClaimSuccessScreen';
import RewardsMainScreen from '../../screens/Wallet/Rewards/RewardsMain/RewardsMainScreen';
import RewardsTransactionDetailsScreen from '../../screens/Wallet/Rewards/RewardsTransactionDetails/RewardsTransactionDetailsScreen';
import WalletBackupScreen from '../../screens/Wallet/Settings/WalletBackup/WalletBackupScreen';
import WalletSettingsScreen from '../../screens/Wallet/Settings/WalletSettings/WalletSettingsScreen';
import StakingAlreadyScreen from '../../screens/Wallet/Staking/StakingAlready/StakingAlreadyScreen';
import StakingCoinsInputScreen from '../../screens/Wallet/Staking/StakingCoinsInput/StakingCoinsInputScreen';
import StakingDailyBoostScreen from '../../screens/Wallet/Staking/StakingDailyBoost/StakingDailyBoostScreen';
import StakingDetailedInfoScreen from '../../screens/Wallet/Staking/StakingDetailedInfo/StakingDetailedInfoScreen';
import StakingOptionsScreen from '../../screens/Wallet/Staking/StakingOptions/StakingOptionsScreen';
import StakingPlansScreen from '../../screens/Wallet/Staking/StakingPlans/StakingPlansScreen';
import StakingSuccessScreen from '../../screens/Wallet/Staking/StakingSuccess/StakingSuccessScreen';
import StakingWelcomeScreen from '../../screens/Wallet/Staking/StakingWelcome/StakingWelcomeScreen';
import UnStakingSuccessScreen from '../../screens/Wallet/Staking/UnStakingSuccess/UnStakingSuccessScreen';
import { TradeChartScreen } from '../../screens/Wallet/Trade/TradeChartScreen';
import TradeMainScreen from '../../screens/Wallet/Trade/TradeMainScreen';
import TradeSuccessScreen from '../../screens/Wallet/Trade/TradeSuccessScreen';
import { RootStackParamList, WalletTabsParamList } from '../../types';
import ROUTES from '../routes';

const WalletTabsStack = createBottomTabNavigator<WalletTabsParamList>();

const WalletTabsNavigator = () => {
    return (
        <WalletTabsStack.Navigator
            screenOptions={{
                headerShown: false,
                // lazy: true,
                // unmountOnBlur: true,
            }}
            initialRouteName={ROUTES.WALLET_COINS_MAIN}
            backBehavior="none"
            tabBar={() => null}>
            <WalletTabsStack.Screen
                name={ROUTES.WALLET_COINS_MAIN}
                component={CoinsMainScreen}
            />
            <WalletTabsStack.Screen
                name={ROUTES.WALLET_NFTS_MAIN}
                component={NFTSMainScreen}
            />
            <WalletTabsStack.Screen
                name={ROUTES.WALLET_REWARDS_TAB}
                component={RewardStack}
            />
            <WalletTabsStack.Screen
                name={ROUTES.WALLET_GAMING_TAB}
                component={GamingStack}
            />
        </WalletTabsStack.Navigator>
    );
};

const RewardStack = () => {
    const RewardStack = createNativeStackNavigator<RootStackParamList>();
    return (
        <RewardStack.Navigator
            initialRouteName={ROUTES.WALLET_REWARDS_MAIN}
            screenOptions={{
                headerShown: false,
            }}>
            <RewardStack.Screen
                name={ROUTES.WALLET_REWARDS_MAIN}
                component={RewardsMainScreen}
                options={{ gestureEnabled: false }}
            />
            <RewardStack.Screen
                name={ROUTES.HOW_TO_AVAILABLE_REWARDS}
                component={HowToAvailableRewardsScreen}
                options={{
                    presentation: 'modal',
                }}
            />
            <RewardStack.Screen
                name={ROUTES.HOW_TO_LOCKED_REWARDS}
                component={HowToLockedRewardsScreen}
                options={{
                    presentation: 'modal',
                }}
            />
            {/* Staking Wallet */}
            <RewardStack.Screen
                name={ROUTES.STAKING_WELCOME}
                component={StakingWelcomeScreen}
            />
            <RewardStack.Screen
                name={ROUTES.STAKING_OPTIONS}
                component={StakingOptionsScreen}
            />
            <RewardStack.Screen
                name={ROUTES.STAKING_PLANS}
                component={StakingPlansScreen}
            />
            <RewardStack.Screen
                name={ROUTES.STAKING_DETAILED_INFO}
                component={StakingDetailedInfoScreen}
                options={{
                    presentation: 'modal',
                }}
            />
            <RewardStack.Screen
                name={ROUTES.STAKING_COINS_INPUT}
                component={StakingCoinsInputScreen}
            />
            <RewardStack.Screen
                name={ROUTES.STAKING_DAILY_BOOST}
                component={StakingDailyBoostScreen}
            />
            <RewardStack.Screen
                name={ROUTES.STAKING_SUCCESS}
                component={StakingSuccessScreen}
                options={{ gestureEnabled: false }}
            />
            <RewardStack.Screen
                name={ROUTES.UN_STAKING_SUCCESS}
                component={UnStakingSuccessScreen}
                options={{ gestureEnabled: false }}
            />
            <RewardStack.Screen
                name={ROUTES.STAKING_ALREADY}
                component={StakingAlreadyScreen}
            />

            {/* Rewards Wallet */}
            <RewardStack.Screen
                name={ROUTES.WALLET_REWARDS_CLAIM_SUCCESS}
                component={RewardsClaimSuccessScreen}
                options={{ gestureEnabled: false }}
            />
            <RewardStack.Screen
                name={ROUTES.WALLET_REWARDS_TRANSACTION_DETAILS}
                component={RewardsTransactionDetailsScreen}
            />
        </RewardStack.Navigator>
    );
};

const GamingStack = () => {
    const GamingStack = createNativeStackNavigator<RootStackParamList>();
    return (
        <GamingWalletProvider>
            <GamingStack.Navigator
                initialRouteName={ROUTES.WALLET_REWARDS_MAIN}
                screenOptions={{
                    headerShown: false,
                }}>
                <GamingStack.Screen
                    name={ROUTES.WALLET_GAMING_MAIN}
                    component={GamingMainScreen}
                    options={{ gestureEnabled: false }}
                />
                <GamingStack.Screen
                    name={ROUTES.TOP_UP_GAMING_WALLET}
                    component={TopUpGamingWalletScreen}
                />
                <GamingStack.Screen
                    name={ROUTES.TOP_UP_CHECKOUT}
                    component={TopUpCheckoutScreen}
                />
                <GamingStack.Screen
                    name={ROUTES.TOP_UP_SUCCESS}
                    component={TopUpSuccessScreen}
                    options={{ gestureEnabled: false }}
                />
                <GamingStack.Screen
                    name={ROUTES.WITHDRAW_IGU_SUCCESS}
                    component={WithdrawSuccessScreen}
                    options={{ gestureEnabled: false }}
                />
                <GamingStack.Screen
                    name={ROUTES.WITHDRAW_INPUT_AMOUNT}
                    component={WithdrawInputAmountScreen}
                />
                <GamingStack.Screen
                    name={ROUTES.HOW_TO_GAMING_PENDING_WALLET}
                    component={HowToGamingPendingWalletScreen}
                    options={{
                        presentation: 'modal',
                    }}
                />
                <GamingStack.Screen
                    name={ROUTES.GAMING_WALLET_TRANSACTION_DETAILS}
                    component={GamingWalletTransactionsDetailsScreen}
                />
            </GamingStack.Navigator>
        </GamingWalletProvider>
    );
};

const WalletGroup = (RootStack: any) => {
    return (
        <>
            {/* Wallet Navigation */}
            <RootStack.Screen
                name={ROUTES.WALLET_ROOT}
                component={WalletTabsNavigator}
            />
            {/* Wallet Coins */}
            <RootStack.Screen
                name={ROUTES.WALLET_COINS_BALANCE}
                component={CoinBalanceScreen}
            />
            <RootStack.Screen
                name={ROUTES.WALLET_COINS_TRANSACTION_DETAILS}
                component={CoinsTransactionDetailsScreen}
            />
            <RootStack.Screen
                name={ROUTES.WALLET_COINS_WITHDRAW}
                component={CoinsWithdrawScreen}
            />
            <RootStack.Screen
                name={ROUTES.WALLET_COINS_WITHDRAW_SUCCESS}
                component={CoinsWithdrawSuccessScreen}
                options={{ gestureEnabled: false }}
            />
            {/* NFT Wallet */}
            <RootStack.Screen
                name={ROUTES.WALLET_NFTS_LIST}
                component={NFTSListScreen}
                options={{
                    presentation: 'modal',
                }}
            />
            <RootStack.Screen
                name={ROUTES.WALLET_NFTS_WITHDRAW}
                component={NFTSWithdrawScreen}
            />
            <RootStack.Screen
                name={ROUTES.WALLET_NFTS_WITHDRAW_SUCCESS}
                component={NFTSWithdrawSuccessScreen}
                options={{ gestureEnabled: false }}
            />
            <RootStack.Screen
                name={ROUTES.WALLET_NFTS_TRANSACTION_DETAILS}
                component={NFTSTransactionDetailsScreen}
            />
            <RootStack.Screen
                name={ROUTES.HOW_TO_PURCHASE_BNB}
                component={HowToPurchaseBNBScreen}
                options={{ presentation: 'modal' }}
            />
            <RootStack.Screen
                name={ROUTES.HOW_TO_PURCHASE_SPACE_ID}
                component={HowToPurchaseSpadeIdScreen}
                options={{ presentation: 'modal' }}
            />
            {/* Trade */}
            <RootStack.Screen
                name={ROUTES.WALLET_TRADE_MAIN}
                component={TradeMainScreen}
            />
            <RootStack.Screen
                name={ROUTES.WALLET_TRADE_CHART}
                component={TradeChartScreen}
                options={{
                    presentation: 'modal',
                }}
            />
            <RootStack.Screen
                name={ROUTES.WALLET_TRADE_SUCCESS}
                component={TradeSuccessScreen}
                options={{ gestureEnabled: false }}
            />
            <RootStack.Screen
                name={ROUTES.WALLET_SETTINGS}
                component={WalletSettingsScreen}
            />
            <RootStack.Screen
                name={ROUTES.WALLET_SETTINGS_BACKUP}
                component={WalletBackupScreen}
            />

            {/* ONBOARDING */}
            <RootStack.Screen
                name={ROUTES.MAIN_WALLET_ONBOARDING_SCREEN}
                component={MainWalletOnBoardingScreen}
                options={{ presentation: 'modal' }}
            />
        </>
    );
};

export default WalletGroup;
