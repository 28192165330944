import { StyleSheet } from 'react-native';

import Colors from '../../../../../../constants/Colors';
import { scale, verticalScale } from '../../../../../../helpers/dimensions';
import font from '../../../../../../helpers/font';
import { theme } from '../../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flexDirection: 'row',
            justifyContent: 'center',
        },
        gapRight: {
            marginRight: scale(10),
        },
        gapLeft: {
            marginLeft: scale(10),
        },
        buttonContainer: {
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
        },
        buttonText: {
            textAlign: 'center',
            marginTop: verticalScale(8),
            color: Colors[theme].text.primary,
            ...font({
                size: 12,
                height: 16,
                weight: '600',
            }),
        },
    });

export default styles;
