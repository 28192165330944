import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            backgroundColor: Colors[theme].background,
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(12),
            marginBottom: verticalScale(4),
        },
        shadow: {
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].shadow.default,
            width: '48%',
            marginBottom: verticalScale(16),
        },
        selectedShadow: {
            backgroundColor: Colors[theme].shadow.selected,
        },
        selectedContainer: {
            borderColor: Colors[theme].border.accent,
        },

        rankWrapper: {
            flex: 1,
            position: 'absolute',
            backgroundColor: Colors[theme].background,
            borderWidth: 1,
            borderRadius: 50,
            borderColor: Colors[theme].border.primary,
            paddingHorizontal: verticalScale(4),
            paddingVertical: verticalScale(4),
            alignSelf: 'flex-start',
            top: verticalScale(6),
            left: scale(6),
        },

        lvlWrapper: {
            marginHorizontal: scale(6),
            backgroundColor: 'transparent',
        },

        lvlUpgradeText: {
            ...font({
                size: 12,
                weight: '700',
            }),
        },
        lvlIcon: {
            width: 12,
        },

        image: {
            width: '100%',
            borderRadius: 6,
            aspectRatio: 1,
        },
        opacityBlock: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: Colors[theme].background,
            opacity: 0.6,
            borderRadius: moderateScale(6),
        },
        textWrapper: {
            marginTop: verticalScale(8),
            backgroundColor: 'transparent',
        },
        name: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
        },
        wrapper: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            alignContent: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
        },
        infoTitle: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
        },
        infoAmount: {
            color: Colors[theme].text.primary,
            ...font({
                size: 13,
                height: 18,
                weight: '600',
            }),
        },
        infoWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        roiWrapper: {
            flexDirection: 'row',
            alignContent: 'center',
        },
        divider: {
            width: 5,
            height: 5,
            borderRadius: 1000,
            backgroundColor: Colors[theme].petRankItem.divider,
            marginHorizontal: scale(6),
        },
        mintPriceContainer: {
            position: 'absolute',
            top: verticalScale(-4),
            right: scale(-4),
            paddingHorizontal: scale(6),
            paddingVertical: verticalScale(2),
            flexDirection: 'row',
            borderRadius: 50,
        },
        footer: {
            marginTop: verticalScale(6),
            flexDirection: 'row',
            backgroundColor: 'transparent',
        },
        ageWrapper: {
            marginLeft: scale(6),
            backgroundColor: 'transparent',
        },
        iconDark: {
            color: Colors[theme].icon.onDark,
        },
    });

export default styles;
