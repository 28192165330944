import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';

import { theme } from '../../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            position: 'absolute',
            height: '100%',
            width: '100%',
            backgroundColor: Colors[theme].background,
            zIndex: 1,
        },
    });

export default styles;
