import * as React from 'react';

import Button from '@components/Button';
import { Text, View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';

import stylesMain from './styles';

interface IProps {
    boost: number | null;
    color: string;
    onPress: () => void;
}

const ActiveStakingButton = ({ boost, color, onPress }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <Button
            onPress={onPress}
            size="sm"
            type="outline"
            debouncedPress
            containerStyle={styles.stakeButtonContainer}
            style={styles.stakeButton}>
            <View style={styles.stakeButtonContent}>
                <Text style={[styles.stakeButtonText, { color }]}>{`+${
                    boost || 0
                }%`}</Text>
            </View>
        </Button>
    );
};
export default ActiveStakingButton;
