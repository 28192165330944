import * as React from 'react';

import { WhitelistRanksOutput } from '@Data/Models';
import RankIcon from '@components/Icons/RankIcon';
import SelectorV2 from '@components/SelectorV2';
import { Text } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';

import RankSelectModal from '../RankSelectModal';
import stylesMain from './styles';

interface IProps {
    error: string | undefined;
    selectedRank: string;
    ranks: WhitelistRanksOutput[] | undefined;
    onPress: (rankSlug: string) => void;
    loading?: boolean;
}

const RankSelect = ({
    error,
    onPress,
    selectedRank,
    ranks,
    loading,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { isVisible, open, close } = useVisibleHook();

    return (
        <SelectorV2
            loading={loading}
            label={i18n.t('profile.adminAssignWhiteList.rank.label')}
            onPress={open}
            children={
                <>
                    <RankIcon slug={selectedRank} size={24} />
                    <Text style={styles.rank}>
                        {i18n.t(`ranks.list.${selectedRank}.title`)}
                    </Text>
                </>
            }
            selectedItem={selectedRank}
            placeHolder={i18n.t(
                'profile.adminAssignWhiteList.rank.placeHolder'
            )}
            error={error}
            modal={
                <>
                    {!!ranks && (
                        <RankSelectModal
                            isVisible={isVisible}
                            close={close}
                            selectedRank={selectedRank}
                            ranks={ranks}
                            onPress={onPress}
                            modalHeight="96%"
                        />
                    )}
                </>
            }
        />
    );
};

export default RankSelect;
