import * as React from 'react';
import { ScrollView, Text } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import _ from 'lodash';

import { Pet } from '../../../../Data/Models';
import Animation from '../../../../components/Animation';
import Button from '../../../../components/Button';
import { View } from '../../../../components/Themed';
import { PlayContext } from '../../../../contexts/PlayContext';
import { earnedExp } from '../../../../helpers/calculateEarnedExp';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import ROUTES from '../../../../navigation/routes';
import { useNavigation } from '../../../../navigation/useNavigation';
import { PlaySuccessScreenProps } from '../../../../types';
import SummaryInfo from '../MoveToEarn/components/SummaryInfo';
import PlayToEarnSummaryInfo from '../PlayToEarn/components/SummaryInfo';
import EmptyStatePlay from '../components/EmptyStatePlay';
import SuccessTaskEnergyBlock from '../components/SuccessTaskEnergyBlock';
import SuccessTaskPetsExp from '../components/SuccessTaskPetsExp';
import stylesMain from './styles';

export const PlaySuccessScreen = ({ route }: PlaySuccessScreenProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const { game, petExperienceRewards, maxEnergy, earnedEnergy, playGame } =
        route.params;
    const { verifyTask, moveTask, playTask } = React.useContext(PlayContext);
    const isSuccess = React.useMemo(() => !!earnedEnergy, [earnedEnergy]);

    navigation.blockHardwareBack();

    const handleViewMoreTask = () => {
        navigation.navigate(ROUTES.PLAY_TASKS_ACTIVE);
    };

    const handleBackToPlay = () => {
        navigation.navigateToPlay();
    };

    const collectedExp = React.useMemo(() => {
        // for verify task we need to summarize all XP
        if (game === 'VERIFY') {
            let exp = 0;
            verifyTask?.tasks.map(
                (val) => (exp += earnedExp(val.petExperienceRewards))
            );
            return exp;
        }

        return earnedExp(petExperienceRewards);
    }, [verifyTask, game, petExperienceRewards]);

    const playPets = React.useMemo(() => {
        const pets: Pet[] = [];

        if (!petExperienceRewards) {
            return pets;
        }

        if (game === 'VERIFY') {
            verifyTask?.tasks.map((val) =>
                val.petExperienceRewards.map((o) => pets.push(o.pet))
            );
        } else {
            petExperienceRewards.map((o) => pets.push(o.pet));
        }

        return _.uniqBy(pets, (o) => o.id);
    }, [verifyTask, game, petExperienceRewards]);

    const onPetsXpPress = React.useCallback(() => {
        navigation.navigate(ROUTES.PLAY_COLLECTED_EXPERIENCE, {
            pets: playPets,
        });
    }, [playPets]);

    const additionalComponent = React.useCallback(() => {
        switch (game) {
            case 'MOVE':
                return (
                    route.params.walkGame && (
                        <SummaryInfo
                            distanceTraveled={
                                route.params.walkGame.distanceTraveled || '0'
                            }
                            averageSpeed={route.params.walkGame.averageSpeed}
                            stepsCount={route.params.walkGame.stepsWalked}
                        />
                    )
                );
            case 'PLAY': {
                return (
                    route.params.playGame && (
                        <PlayToEarnSummaryInfo
                            score={playGame?.score || 0}
                            savedLives={playGame?.savedLives || 0}
                            fruitsCollected={playGame?.fruitsCollected || 0}
                            livesCount={playGame?.livesCount || 0}
                        />
                    )
                );
            }

            default:
                return <></>;
        }
    }, [route.params, playTask, game]);

    const renderItem = React.useMemo(() => {
        if (!isSuccess) {
            return (
                <>
                    <EmptyStatePlay
                        icon={
                            <View style={styles.animationWrapper}>
                                <Animation
                                    animation="cry"
                                    style={styles.animation}
                                />
                            </View>
                        }
                        title={i18n.t(
                            `playSuccessScreen.emptyStates.${game}.failTitle`
                        )}
                        text={i18n.t(
                            `playSuccessScreen.emptyStates.${game}.failText`
                        )}
                    />
                </>
            );
        }

        return (
            <>
                <EmptyStatePlay
                    icon={
                        <View style={styles.animationWrapper}>
                            <Animation
                                animation="money"
                                style={styles.animation}
                            />
                        </View>
                    }
                    title={i18n.t(
                        `playSuccessScreen.emptyStates.${game}.title`
                    )}
                    text={i18n.t(`playSuccessScreen.emptyStates.${game}.text`)}
                />
            </>
        );
    }, [isSuccess, game]);

    return (
        <SafeAreaView style={styles.safe}>
            <ScrollView
                showsVerticalScrollIndicator={false}
                style={styles.main}
                contentContainerStyle={styles.justify}>
                <View style={styles.imgText}>{renderItem}</View>

                <SuccessTaskEnergyBlock
                    earnedEnergy={earnedEnergy || 0}
                    maxEnergy={maxEnergy || 0}
                />
                {isSuccess && (
                    <SuccessTaskPetsExp
                        onPress={onPetsXpPress}
                        collectedExp={collectedExp}
                        containerStyles={styles.petsXpWrapper}
                    />
                )}
                {additionalComponent()}
            </ScrollView>
            <View style={styles.buttonWrapper}>
                <Button
                    title={i18n.t('playSuccessScreen.button')}
                    onPress={handleViewMoreTask}
                    containerStyle={styles.gap}
                />
                <Button
                    title={i18n.t(`playSuccessScreen.button2`)}
                    type={'outline'}
                    onPress={handleBackToPlay}
                />
            </View>
        </SafeAreaView>
    );
};
export default PlaySuccessScreen;
