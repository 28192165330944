import { StyleSheet } from 'react-native';

import { verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        gap: {
            marginTop: verticalScale(16),
            backgroundColor: 'transparent',
        },
    });

export default styles;
