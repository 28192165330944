import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale } from '@helpers/dimensions';
import { stylesModalNavigationFix } from '@helpers/style';

import { theme } from '../../../../../types';

const stylesMain = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: Colors[theme].background,
            ...stylesModalNavigationFix(),
        },
        containerTitle: {
            paddingHorizontal: scale(20),
        },
    });

export default stylesMain;
