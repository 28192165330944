import * as React from 'react';

import SuccessOrdersIcon from '@assets/icons/marketplace/successOrders.svg';
import Button from '@components/Button';
import CoinIcon from '@components/Icons/CoinIcon';
import OtherUserAvatar from '@components/OtherUserAvatar';
import { Text, View } from '@components/Themed';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { scale } from '@helpers/dimensions';
import { priceString } from '@helpers/payments';
import { toastComingSoon } from '@helpers/toastNotification';
import { truncateEthAddress } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MarketplaceNftDetailsSellerOutput } from '@models/marketplace';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import {
    calculateTotalOrders,
    calculateTotalSummary,
} from '../../helpers/helper';
import stylesMain from './styles';

interface IProps {
    owner: MarketplaceNftDetailsSellerOutput;
}

const OverviewOwnerSection = ({ owner }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { profile, stats } = owner;

    const navigation = useNavigation();

    const totalSummary = React.useMemo(
        () =>
            calculateTotalSummary(
                stats.boughtStats.volumeIguAmountWei,
                stats.soldStats.volumeIguAmountWei
            ),
        [stats]
    );
    const totalOrders = React.useMemo(
        () =>
            calculateTotalOrders(
                stats.boughtStats.transactionCount,
                stats.soldStats.transactionCount
            ),
        [stats]
    );

    const handleOnPress = React.useCallback(() => {
        navigation.push(ROUTES.MARKETPLACE_PROFILE, {
            walletAddress: profile.walletAddress,
        });
    }, [profile]);

    return (
        <View style={styles.container}>
            <View style={styles.ownersProfileWrapper}>
                <View style={styles.ownersProfileLeft}>
                    <OtherUserAvatar
                        size={scale(56)}
                        imageUrl={profile?.avatarThumbnailUrl}
                        userId={profile.walletAddress}
                    />
                </View>
                <View style={styles.ownersProfileRight}>
                    <Text style={styles.ownersProfileLabel}>
                        {i18n.t(
                            'MarketPlace.MarketPlaceOverview.OverviewOwnerSection.profileLabel'
                        )}
                    </Text>
                    <Text style={styles.ownersProfileTitle} numberOfLines={1}>
                        {truncateEthAddress(
                            profile.username || profile.walletAddress
                        )}
                    </Text>
                </View>
            </View>
            <Button
                type="outline"
                size="md"
                debouncedPress
                textStyles={styles.buttonText}
                containerStyle={styles.button}
                title={i18n.t('general.BUTTONS.viewProfile')}
                onPress={handleOnPress}
            />
            <View style={styles.summaryWrapper}>
                <View style={[styles.summaryRow, styles.gap]}>
                    <View style={styles.summaryLeft}>
                        <CoinIcon slug={Coin.igu} size={24} />
                        <Text style={styles.summaryLabel}>
                            {i18n.t('general.KEYS.totalSummary')}
                        </Text>
                    </View>
                    <Text style={styles.summaryTitle} numberOfLines={1}>
                        {priceString(totalSummary, Coin.igu)}
                    </Text>
                </View>
                <View style={styles.summaryRow}>
                    <View style={styles.summaryLeft}>
                        <SuccessOrdersIcon width={24} height={24} />
                        <Text style={styles.summaryLabel}>
                            {i18n.t('general.KEYS.totalSuccessfulOrders')}
                        </Text>
                    </View>
                    <Text style={styles.summaryTitle} numberOfLines={1}>
                        {`${totalOrders} ${i18n.t('general.KEYS.NFTs')}`}
                    </Text>
                </View>
            </View>
        </View>
    );
};

export default OverviewOwnerSection;
