import * as React from 'react';

import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { MYSTERY_LIKES_STATUS } from '@models/mysteryGames';
import { useNavigation } from '@navigation/useNavigation';

import { MysteryLikesSelectRangeFinishProps } from '../../../../../types';
import MysteryLikesHistoryWrapper from '../../components/MysteryLikesHistoryWrapper';

const MysteryLikesSelectRangeFinishScreen = ({
    route,
}: MysteryLikesSelectRangeFinishProps) => {
    const { currentMysteryLikesGameHistory, mysteryLikesGameStats } =
        useMysteryGames();

    const navigation = useNavigation();
    navigation.blockHardwareBack();

    const renderContent = React.useCallback(() => {
        if (!currentMysteryLikesGameHistory) {
            return null;
        }
        switch (currentMysteryLikesGameHistory.status) {
            case MYSTERY_LIKES_STATUS.Refund:
                return null;
            case MYSTERY_LIKES_STATUS.WaitingForMyself:
                return null;

            default:
                return (
                    <MysteryLikesHistoryWrapper
                        history={currentMysteryLikesGameHistory}
                        force={route.params?.fromHistory}
                    />
                );
        }
    }, [currentMysteryLikesGameHistory, route.params?.fromHistory]);

    return renderContent();
};

export default MysteryLikesSelectRangeFinishScreen;
