import * as React from 'react';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import EmptyList from '@components/EmptyList';
import LoadingComponent from '@components/LoadingComponent';
import PullToRefresh from '@components/PullToRefresh';
import SafeAreaCurvedTabContent from '@components/SafeAreaCurvedTabContent';
import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationLeaderboard from '@navigation/NavigationBar/NavigationLeaderboard';
import ROUTES from '@navigation/routes';

import DailyTasksStatistics from '../components/DailyTasksStatistics';
import Filter from '../components/Filter';
import UserDailyTasks from '../components/UserDailyTasks';
import useDailyTasksLeaderboard from '../hooks/useDailyTasksLeaderboard';
import stylesMain from './styles';

const screen = ROUTES.LEADERBOARD_DAILY_TASKS;

const LeaderboardDailyTasksScreen = () => {
    const styles = useThemedStyles(stylesMain);

    const [viewHeight, setHeightView] = React.useState(0);

    const {
        getData,
        filter,
        filterLoading,
        data,
        pullToRefreshLoading,
        isLoading,
    } = useDailyTasksLeaderboard();

    React.useEffect(() => {
        getData(true);
    }, []);

    const onRefresh = () => {
        getData(false);
    };

    const handleChooseFilter = (item: string) => {
        getData(false, item);
    };

    const renderItem = React.useCallback(() => {
        return (
            <>
                <NavigationLeaderboard currentNavigation={screen} />
                <BlockHeader
                    type={'primary'}
                    title={i18n.t(`Leaderboard.${screen}.title`)}
                    text={i18n.t(`Leaderboard.${screen}.text`)}
                />
                <View style={styles.filter}>
                    <Filter
                        selectedItem={filter}
                        onSelect={handleChooseFilter}
                    />
                </View>
                {(!data && isLoading) || filterLoading ? (
                    <LoadingComponent containerStyles={styles.loading} />
                ) : data && !!data.rankingTopPlayers.length ? (
                    <>
                        <UserDailyTasks
                            containerStyle={styles.gap}
                            rankMe={data.rankingMe}
                        />
                        <DailyTasksStatistics
                            containerStyle={styles.gap}
                            rankingStat={data.rankingStats}
                            topPlayers={data.rankingTopPlayers}
                            userAddress={data.rankingMe?.user?.walletAddress}
                        />
                    </>
                ) : (
                    <EmptyList
                        containerStyle={styles.content}
                        emptyTitle={i18n.t(
                            `Leaderboard.${screen}.emptyList.title`
                        )}
                        emptyText={i18n.t(
                            `Leaderboard.${screen}.emptyList.text`
                        )}
                        withAnimation="cry"
                    />
                )}
            </>
        );
    }, [isLoading, data, filterLoading, filter, viewHeight, styles]);

    return (
        <SafeAreaCurvedTabContent>
            <View
                onLayout={(event) =>
                    setHeightView(event.nativeEvent.layout.height)
                }
                style={styles.container}>
                <PullToRefresh
                    refreshing={pullToRefreshLoading}
                    textLoading={i18n.t('pullToRefresh.reloadStatistics')}
                    onRefresh={onRefresh}
                    renderItem={renderItem}
                />
            </View>
        </SafeAreaCurvedTabContent>
    );
};
export default LeaderboardDailyTasksScreen;
