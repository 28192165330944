import { useCallback, useEffect, useState } from 'react';
import BackgroundGeolocation from 'react-native-background-geolocation';

import * as LocationExpo from 'expo-location';

import CustomModal from '@components/CustomModal';
import ImageIguana from '@components/ImageIguana';
import { isWeb } from '@helpers/app';
import i18n from '@i18n/i18n';
import { useNavigation } from '@navigation/useNavigation';

const config = {
    shouldStartBackground: !isWeb,
    checkGlobalPermission: 5000,
};

export const useLocationProblem = () => {
    const navigation = useNavigation();
    const [isLocationProblem, setIsLocationProblem] = useState(false);

    useEffect(() => {
        if (config.shouldStartBackground) {
            BackgroundGeolocation.onProviderChange((event) => {
                if (event.status === 2) {
                    setIsLocationProblem(true);
                }
            });
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(async () => {
            if (!isLocationProblem) {
                const result = await LocationExpo.hasServicesEnabledAsync();
                if (!result) {
                    setIsLocationProblem(true);
                }
            }
        }, config.checkGlobalPermission);
        return () => clearInterval(interval);
    }, [isLocationProblem]);

    const handleProblemLocationAction = () => {
        setIsLocationProblem(false);
        navigation.pop();
    };

    const problemWithLocationRenderModal = useCallback(
        () => (
            <CustomModal
                isVisible={isLocationProblem}
                titleText={i18n.t('moveToEarn.locationProblem.title')}
                icon={<ImageIguana type="cry" />}
                infoText={i18n.t('moveToEarn.locationProblem.text')}
                firstButtonText={i18n.t('moveToEarn.locationProblem.button')}
                onFirstButtonClick={handleProblemLocationAction}
            />
        ),
        [isLocationProblem]
    );

    return {
        isLocationProblem,
        setIsLocationProblem,
        problemWithLocationRenderModal,
    };
};
