import React from 'react';

import ButtonsBottom from '@components/ButtonsBottom';
import { useStaking } from '@contexts/StakingContext';
import { isAndroid } from '@helpers/app';
import { waitForModal } from '@helpers/helpers';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import ModalBottom from '../../ModalBottom';
import StakingWelcomeInfo from '../StakingWelcomeInfo';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    closeAction: () => void;
    withNavigation?: boolean;
}

const ModalStakingInfo = ({
    isVisible,
    closeAction,
    withNavigation = false,
}: IProps) => {
    const navigation = useNavigation();
    const styles = useThemedStyles(stylesMain);
    const { currentStakingPlan, userStakeLoading } = useStaking();

    const navigateToStake = React.useCallback(() => {
        closeAction();
        waitForModal().then(() => {
            if (currentStakingPlan) {
                return navigation.navigate(ROUTES.WALLET_ROOT, {
                    screen: ROUTES.WALLET_REWARDS_TAB,
                    params: {
                        screen: ROUTES.STAKING_ALREADY,
                    },
                });
            }
            return navigation.navigate(ROUTES.WALLET_ROOT, {
                screen: ROUTES.WALLET_REWARDS_TAB,
                params: {
                    screen: ROUTES.STAKING_OPTIONS,
                },
            });
        });
    }, [currentStakingPlan]);
    return (
        <ModalBottom
            modalHeight={'96%'}
            isVisible={isVisible}
            onClose={closeAction}>
            <StakingWelcomeInfo containerStyle={styles.gap} />
            {withNavigation && (
                <ButtonsBottom
                    onPress={navigateToStake}
                    title={i18n.t('staking.welcomeScreen.button')}
                    safe={!isAndroid}
                    loading={userStakeLoading}
                    disabled={userStakeLoading}
                />
            )}
        </ModalBottom>
    );
};
export default ModalStakingInfo;
