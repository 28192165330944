import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import { scale, verticalScale } from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        petContainer: {
            paddingHorizontal: 0,
        },
        divider: {
            marginVertical: verticalScale(12),
        },
        levelUpWrapper: {
            flexDirection: 'row',
            paddingVertical: verticalScale(8),
            alignItems: 'center',
        },
        levelUpIcon: {
            marginRight: scale(18),
        },
        levelUpRight: {},
        levelUpTitleWrapper: {},
        levelUpTitleText: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                weight: '600',
            }),
        },
        levelUpTextWrapper: {},
        levelUpText: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '600',
            }),
        },
    });

export default styles;
