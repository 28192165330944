import * as React from 'react';

import { Text, View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';

import stylesMain from './styles';

interface IProps {
    amount: string | undefined;
    isOneColor?: boolean;
}

const NftEvolutionChance = ({ amount, isOneColor }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={[styles.amountBlock, isOneColor && styles.isOneColor]}>
            <Text
                style={[styles.amount, isOneColor && styles.isOneColorText]}
                numberOfLines={1}
                ellipsizeMode="tail">
                {`${amount || 0}%`}
            </Text>
        </View>
    );
};
export default NftEvolutionChance;
