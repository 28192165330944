import * as React from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';

import { Pet, Rank } from '@Data/Models';
import AgeIndicator from '@components/AgeIndicator';
import ModalNFTisAgeDead from '@components/ModalNFTisAgeDead';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import ROUTES from '@navigation/routes';

import Button from '../Button';
import CacheImage from '../CacheImage';
import HealthIndicator from '../HealthIndicator';
import Icon, { ICON_NAMES } from '../Icons';
import RankIcon from '../Icons/RankIcon';
import LevelIndicator from '../LevelIndicator';
import ModalChooseAmountOfHealUp from '../ModalChooseAmountOfHealUp';
import ModalNFTLevelUp from '../ModalNFTLevelUp';
import ModalNFTisDead from '../ModalNFTisDead';
import ModalNFTRankUp from '../Ranks/ModalNFTRankUp';
import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    pet: Pet;
    onPressPet?: () => void;
    border?: boolean;
    style?: StyleProp<ViewStyle>;
    fullViewLvl?: boolean;
    canLvlUp?: boolean;
    canRankUp?: boolean;
    playablePetConfirmation?: boolean; // whether we display pet
    onPressPlayablePet?: () => void; // whether we display pet
    showDivider?: boolean;
    fromScreenModal?: boolean;
    navigateBackOnUpgrade?: ROUTES;
    lvlUp?: boolean;
    nextRank?: Rank;
    health?: number;
    isDead?: boolean;
    isAgeDead?: boolean;
    onArrowClick?: () => void;
    withSelection?: boolean;
    isSelected?: boolean;
    isOnCooldown?: boolean;
    onCooldownClick?: () => void;
    navigateBackFromCheckout?: ROUTES;
    shouldCallRevive?: boolean;
    shouldCallFeed?: boolean;
    isNeedToFeed?: boolean;
    disabled?: boolean;
    showAge?: boolean;
}

const PetCollectionListItem = ({
    pet,
    onPressPet,
    border,
    style,
    fullViewLvl = false,
    canLvlUp = false,
    playablePetConfirmation = false,
    onPressPlayablePet,
    canRankUp = false,
    showDivider = false,
    fromScreenModal = false,
    navigateBackOnUpgrade,
    lvlUp,
    nextRank,
    health,
    isDead,
    onArrowClick,
    withSelection,
    isSelected,
    isOnCooldown,
    onCooldownClick,
    navigateBackFromCheckout,
    shouldCallRevive,
    isNeedToFeed,
    shouldCallFeed,
    disabled,
    isAgeDead,
    showAge,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const {
        image,
        id,
        level,
        levelProgressPercentage,
        name,
        currentLevelMaxExperience,
        experience,
    } = pet;

    const levelText = React.useMemo(() => {
        if (lvlUp) {
            return level + 1;
        }
        if (nextRank) {
            return 1;
        }
        return level;
    }, [level, lvlUp, nextRank]);
    const levelProgressPercentageText = React.useMemo(() => {
        if (lvlUp || nextRank) {
            return 0;
        }
        return levelProgressPercentage;
    }, [lvlUp, nextRank, levelProgressPercentage]);
    const [isVisibleLevelUpModal, setIsVisibleLevelUpModal] =
        React.useState(false);
    const [isVisibleRankUpModal, setIsVisibleRankUpModal] =
        React.useState(false);
    const [isVisibleRestoreModal, setIsVisibleRestoreModal] =
        React.useState(false);

    const [isVisibleFeedModal, setIsVisibleFeedModal] = React.useState(false);

    const handleCloseLevelUpModal = () => {
        setIsVisibleLevelUpModal(false);
    };
    const handleOpenLevelUpModal = () => {
        setIsVisibleLevelUpModal(true);
    };

    const handleCloseRankUpModal = () => {
        setIsVisibleRankUpModal(false);
    };
    const handleOpenRankUpModal = () => {
        setIsVisibleRankUpModal(true);
    };

    const handleCloseRestoreModal = () => {
        setIsVisibleRestoreModal(false);
    };
    const handleOpenRestoreModal = () => {
        setIsVisibleRestoreModal(true);
    };

    const handleCloseFeedModal = () => {
        setIsVisibleFeedModal(false);
    };
    const handleOpenFeedModal = () => {
        setIsVisibleFeedModal(true);
    };

    const {
        isVisible: ageDeadModal,
        close: closeAgeDeadModal,
        open: openAgeDeadModal,
    } = useVisibleHook();

    const renderPetState = () => {
        if (isAgeDead) {
            return (
                <View style={styles.lvlUpItem}>
                    <View style={styles.opacityBlock} />

                    <Button
                        onPress={openAgeDeadModal}
                        iconName={ICON_NAMES.IS_AGE_DEAD}
                        iconColor={styles.restoreIcon.color}
                        size="md"
                        type="critical"
                    />
                </View>
            );
        }
        if (isDead) {
            return (
                <View style={styles.lvlUpItem}>
                    <View style={styles.opacityBlock} />

                    <Button
                        onPress={handleOpenRestoreModal}
                        iconName={ICON_NAMES.IS_DEAD}
                        iconColor={styles.restoreIcon.color}
                        size="md"
                        type="critical"
                    />
                </View>
            );
        }
        if (isNeedToFeed) {
            return (
                <View style={styles.lvlUpItem}>
                    <View style={styles.opacityBlock} />

                    <Button
                        onPress={handleOpenFeedModal}
                        icon={
                            <Icon
                                name={ICON_NAMES.FEED}
                                color={styles.iconDark.color}
                            />
                        }
                        size="md"
                    />
                </View>
            );
        }
        if (canLvlUp || canRankUp) {
            return (
                <View style={styles.lvlUpItem}>
                    <View style={styles.opacityBlock} />

                    <Button
                        onPress={
                            canLvlUp
                                ? handleOpenLevelUpModal
                                : handleOpenRankUpModal
                        }
                        icon={
                            <Icon
                                name={ICON_NAMES.ARROW_UPGRADE}
                                color={styles.iconDark.color}
                            />
                        }
                        size="md"
                    />
                </View>
            );
        }
    };

    const renderPet = () => {
        return (
            <>
                {withSelection && (
                    <>
                        {!isOnCooldown && !isDead && !isAgeDead && (
                            <View
                                style={[
                                    styles.radio,
                                    isSelected && styles.selected,
                                ]}>
                                {isSelected && (
                                    <Icon name={ICON_NAMES.CHECK} size={12} />
                                )}
                            </View>
                        )}
                        {isOnCooldown && (
                            <TouchableOpacity
                                onPress={onCooldownClick}
                                style={styles.radioIcon}
                                activeOpacity={0.7}>
                                <Icon name={ICON_NAMES.COOLDOWN} />
                            </TouchableOpacity>
                        )}
                        {isAgeDead ? (
                            <TouchableOpacity
                                onPress={openAgeDeadModal}
                                style={styles.radioIcon}
                                activeOpacity={0.7}>
                                <Icon name={ICON_NAMES.IS_AGE_DEAD} />
                            </TouchableOpacity>
                        ) : (
                            isDead && (
                                <TouchableOpacity
                                    onPress={handleOpenRestoreModal}
                                    style={styles.radioIcon}
                                    activeOpacity={0.7}>
                                    <Icon name={ICON_NAMES.IS_DEAD} />
                                </TouchableOpacity>
                            )
                        )}
                    </>
                )}
                <View style={styles.imageWrapper}>
                    <CacheImage
                        source={image.thumbnailUrl}
                        style={styles.image}
                        resizeMode="contain"
                    />
                    {renderPetState()}
                </View>
                <View style={styles.textWrapper}>
                    <View style={styles.titleWrapper}>
                        <RankIcon
                            slug={nextRank ? nextRank.slug : pet.rank}
                            size={24}
                        />
                        <Text
                            style={styles.petName}
                            numberOfLines={1}
                            ellipsizeMode="tail">
                            {name}
                        </Text>
                    </View>
                    {health !== undefined ? (
                        <HealthIndicator healthPercentage={health} />
                    ) : fullViewLvl ? (
                        <LevelIndicator
                            level={levelText}
                            fillAmount={levelProgressPercentageText}
                            fullView
                            withIcon={false}
                            maxExperience={currentLevelMaxExperience}
                            experience={experience}
                        />
                    ) : (
                        <>
                            <LevelIndicator
                                level={levelText}
                                fillAmount={levelProgressPercentageText}
                            />
                            <View style={styles.health}>
                                <HealthIndicator
                                    badge
                                    healthPercentage={pet.health}
                                />
                                {showAge && (
                                    <AgeIndicator
                                        ageDays={pet.ageDays}
                                        maxAgeDays={pet.maxAgeDays}
                                        containerStyle={styles.ageWrapper}
                                    />
                                )}
                            </View>
                        </>
                    )}
                </View>
            </>
        );
    };

    const renderLevelUpModal = () => {
        return (
            <>
                {canLvlUp && !canRankUp && (
                    <ModalNFTLevelUp
                        handleCloseLevelUpModal={handleCloseLevelUpModal}
                        isVisible={isVisibleLevelUpModal}
                        pet={pet}
                        fromScreenModal={fromScreenModal}
                        navigateBackOnUpgrade={navigateBackOnUpgrade}
                    />
                )}
            </>
        );
    };

    const renderRankUpModal = () => {
        return (
            <>
                {canRankUp && (
                    <ModalNFTRankUp
                        closeAction={handleCloseRankUpModal}
                        isVisible={isVisibleRankUpModal}
                        pet={pet}
                        fromScreenModal={fromScreenModal}
                        navigateBackOnUpgrade={navigateBackOnUpgrade}
                    />
                )}
            </>
        );
    };
    const renderReviveModal = () => {
        return (
            <>
                {isDead && (
                    <ModalNFTisDead
                        closeModal={handleCloseRestoreModal}
                        isVisible={isVisibleRestoreModal}
                        pet={pet}
                        fromScreenModal={fromScreenModal}
                        navigateBackOnUpgrade={navigateBackOnUpgrade}
                        navigateBackFromCheckout={navigateBackFromCheckout}
                    />
                )}
            </>
        );
    };
    const renderAgeReviveModal = () => {
        return (
            <>
                {isAgeDead && (
                    <ModalNFTisAgeDead
                        closeModal={closeAgeDeadModal}
                        isVisible={ageDeadModal}
                        pet={pet}
                        fromScreenModal={fromScreenModal}
                        navigateBackOnUpgrade={navigateBackOnUpgrade}
                        navigateBackFromCheckout={navigateBackFromCheckout}
                    />
                )}
            </>
        );
    };

    const renderFeedModal = () => {
        return (
            <>
                {isNeedToFeed && (
                    <ModalChooseAmountOfHealUp
                        closeModal={handleCloseFeedModal}
                        isVisible={isVisibleFeedModal}
                        pet={pet}
                        fromScreenModal={fromScreenModal}
                        navigateBackOnUpgrade={navigateBackOnUpgrade}
                        navigateBackFromCheckout={navigateBackFromCheckout}
                    />
                )}
            </>
        );
    };

    const handleOnPressPet = React.useCallback(() => {
        if (shouldCallRevive) {
            handleOpenRestoreModal();
            return;
        }
        if (shouldCallFeed) {
            handleOpenFeedModal();
            return;
        }
        onPressPet?.();
    }, [onPressPet, shouldCallRevive, shouldCallFeed]);

    return (
        <>
            <TouchableOpacity
                activeOpacity={0.7}
                disabled={!handleOnPressPet || disabled}
                style={[
                    !border ? styles.paddings : {},
                    border ? styles.shadow : {},
                    border && isSelected && styles.selectedShadow,
                    style,
                ]}
                key={id}
                onPress={handleOnPressPet}>
                {playablePetConfirmation && (
                    <View style={styles.overlay}>
                        <Button
                            size="lg"
                            type="outline"
                            iconName={ICON_NAMES.TASK}
                            style={styles.playingPetButton}
                            onPress={() => {
                                if (onPressPlayablePet) onPressPlayablePet();
                            }}
                        />
                    </View>
                )}
                <View
                    style={[
                        styles.itemContainer,
                        border ? styles.borderPaddings : {},
                        border ? styles.itemContainerWithBorder : {},
                        border && isSelected && styles.selected,
                    ]}>
                    <View style={styles.leftWrapper}>{renderPet()}</View>
                    {border && (
                        <TouchableOpacity
                            disabled={!onArrowClick}
                            style={styles.rightWrapper}
                            activeOpacity={0.7}
                            onPress={onArrowClick}>
                            <Icon
                                name={ICON_NAMES.ARROW_RIGHT_SECONDARY}
                                color={styles.arrowRight.color}
                            />
                        </TouchableOpacity>
                    )}
                </View>
            </TouchableOpacity>
            {showDivider && <View style={styles.divider} />}
            {renderLevelUpModal()}
            {renderRankUpModal()}
            {renderReviveModal()}
            {renderFeedModal()}
            {renderAgeReviveModal()}
        </>
    );
};
export default React.memo(PetCollectionListItem);
