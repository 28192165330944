import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import { scale, verticalScale } from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        buttonWrapper: {
            flex: 1,
        },
        button: {},
        explorerLink: {
            alignSelf: 'center',
        },
        coolDown: {},
        coolDownBlock: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        countdownText: {
            color: Colors[theme].text.warning,
            marginHorizontal: scale(5),
            alignItems: 'center',
            ...font({
                size: 14,
                weight: '600',
            }),
        },
        countdown: {
            color: Colors[theme].text.warning,
            ...font({
                size: 14,
                weight: '600',
            }),
        },
        countdownTooltip: {
            color: Colors[theme].text.warning,
        },
        tokenId: {
            flexDirection: 'row',
            alignItems: 'center',
            marginVertical: verticalScale(12),
        },
        tokenIdTitle: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
            letterSpacing: 0.08,
        },
        tokenIdText: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
        },
        margin: {
            marginTop: verticalScale(12),
        },
    });

export default styles;
