import * as React from 'react';

import InfoIcon from '@components/InfoIcon/InfoIcon';
import { View } from '@components/Themed';
import { useConfig } from '@contexts/ConfigContext';
import { useWallet } from '@contexts/Wallet/WalletContext';
import useFeeInfo from '@hooks/useFeeInfo';
import { useVisibleHook } from '@hooks/useVisibleHook';

import ButtonCard from '../../../../../components/ButtonCard';
import Icon, { ICON_NAMES } from '../../../../../components/Icons';
import ModalConfirmTransaction from '../../../../../components/ModalConfirmTransaction';
import ModalMainnet from '../../../../../components/ModalMainnet';
import TooltipGuide from '../../../../../components/TooltipGuide';
import TransactionSummary from '../../../../../components/TransactionSummary';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import { useHowToClaim } from '../../hooks/useHowToClaim';
import stylesMain from './styles';

interface IProps {
    // stakeActive?: boolean;
    // data: IRewardCard[];
    // disabled?: boolean;
    loading?: boolean;
    onTopUp: () => void;
    onWithdraw: () => void;
    withdrawDisabled: boolean;
}

const WalletGamingButtons = ({
    loading,
    onTopUp,
    onWithdraw,
    withdrawDisabled,
}: IProps) => {
    // const {
    //     isVisible: isVisibleMainnetModal,
    //     open: openMainnetModal,
    //     close: closeMainnetModal,
    // } = useVisibleHook();
    const styles = useThemedStyles(stylesMain);
    // const { fee, walletBalance } = useWallet();
    // const { isRewardsDisabled } = useConfig();

    // const {
    //     isVisible: isVisibleConfirmationModal,
    //     close: closeConfirmationModal,
    //     open: openConfirmationModal,
    // } = useVisibleHook();

    // // fee info modalScreen
    // const { renderFeeInfoIcon } = useFeeInfo(
    //     closeConfirmationModal,
    //     openConfirmationModal
    // );

    // const isBNBEnough = React.useMemo(() => {
    //     return Number(walletBalance?.bnb?.value) >= Number(fee?.nftTransfer);
    // }, [walletBalance, fee]);

    // const onClaimPress = () => {
    //     if (isRewardsDisabled) {
    //         openMainnetModal();
    //         return;
    //     }

    //     openConfirmationModal();
    // };

    // const renderConfirmModal = React.useCallback(() => {
    //     const transactionDetailsValues = [
    //         {
    //             key: i18n.t('wallet.rewards.confirmationModal.keys.from'),
    //             value: i18n.t(
    //                 'wallet.rewards.confirmationModal.values.rewardsWallet'
    //             ),
    //         },
    //         {
    //             key: i18n.t('wallet.rewards.confirmationModal.keys.to'),
    //             value: i18n.t(
    //                 'wallet.rewards.confirmationModal.values.mainWallet'
    //             ),
    //         },
    //         {
    //             key: i18n.t('wallet.rewards.confirmationModal.keys.fee'),
    //             value: `${fee?.nftTransfer} BNB`,
    //             icon: renderFeeInfoIcon(),
    //         },
    //         {
    //             key: i18n.t('wallet.rewards.confirmationModal.keys.total'),
    //             value: `${data[0]?.igupAmount || 0} IGUP`,
    //         },
    //     ];

    //     return (
    //         <ModalConfirmTransaction
    //             modalHeight="600"
    //             title={i18n.t('wallet.rewards.confirmationModal.title')}
    //             isVisible={isVisibleConfirmationModal}
    //             close={closeConfirmationModal}
    //             onConfirm={onClaimConfirmPress}
    //             TransactionDetailsComponent={
    //                 <TransactionSummary values={transactionDetailsValues} />
    //             }
    //             textWarning={i18n.t(
    //                 isBNBEnough
    //                     ? 'wallet.rewards.confirmationModal.warning'
    //                     : 'checkout.errors.bnbNotEnough'
    //             )}
    //             alertType={isBNBEnough ? 'warning' : 'error'}
    //             disabled={!isBNBEnough}
    //             showDeposit={!isBNBEnough}
    //         />
    //     );
    // }, [isVisibleConfirmationModal, isBNBEnough, fee, data]);

    return (
        <View style={styles.container}>
            {/* <View style={styles.buttonContainer}> */}
            <ButtonCard
                onPress={onTopUp}
                debouncedPress
                containerStyle={styles.button}
                icon={<Icon name={ICON_NAMES.DEPOSIT} color="onDark" />}
                title={i18n.t('wallet.gaming.gamingWalletButtons.topUp')}
            />
            {/* </View> */}
            <ButtonCard
                onPress={onWithdraw}
                debouncedPress
                containerStyle={styles.button}
                icon={<Icon name={ICON_NAMES.CLAIM} color="onDark" />}
                title={i18n.t('wallet.gaming.gamingWalletButtons.withdraw')}
                loading={loading}
                disabled={withdrawDisabled}
            />
            {/* {renderConfirmModal()}

            <ModalMainnet
                isVisible={isVisibleMainnetModal}
                onClose={closeMainnetModal}
            /> */}
        </View>
    );
};

export default React.memo(WalletGamingButtons);
