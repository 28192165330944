import * as React from 'react';
import { Image } from 'react-native';

import StatusIcon from '@components/Icons/StatusIcon';
import { Text, View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MysteryLikesGameHistoryOutput } from '@models/mysteryGames';

import stylesMain from './styles';

interface IProps {
    item: MysteryLikesGameHistoryOutput;
}

const StatisticsGameItem = ({ item }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View
            style={[
                styles.itemContainer,
                styles[item.correctChoice.toString()],
            ]}>
            <View style={styles.left}>
                <Image
                    style={styles.image}
                    source={{
                        uri: item.petImageUrl,
                    }}
                />

                <View style={styles.textBlock}>
                    <Text style={styles.number}>{`${item.index}.`}</Text>
                    <Text style={styles.choice}>
                        {`${item.choice} ${i18n.t(
                            `HomeScreen.MysteryLikes.MysteryLikesSelectRangeFinish.likes`
                        )}`}
                    </Text>
                </View>
            </View>
            <StatusIcon slug={item.correctChoice ? 'CONFIRMED' : 'FAILED'} />
        </View>
    );
};
export default StatisticsGameItem;
