import { StyleSheet } from 'react-native';

import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        fullScreenOverlay: {
            position: 'absolute',
            width: '100%',
            height: '100%',
        },
        wrapper: {
            flex: 1,
            width: '100%',
            backgroundColor: 'transparent',
            alignItems: 'center',
            alignSelf: 'center',
            justifyContent: 'center',
        },
        content: {
            backgroundColor: 'transparent',
        },
    });

export default styles;
