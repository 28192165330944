import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        flex: {
            flex: 1,
        },
        modal: {
            paddingHorizontal: scale(20),
            paddingTop: verticalScale(32),
        },
        scrollGap: {
            paddingBottom: verticalScale(60),
        },

        blockHeader: {
            marginBottom: verticalScale(8),
        },
        cardFeatureInfo: {
            marginTop: verticalScale(12),
        },
    });

export default styles;
