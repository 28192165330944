import React from 'react';
import { Linking } from 'react-native';

import { openUrlOrShowError } from '@helpers/linking';

import TermsOfUseIcon from '../../../../../../assets/icons/profile/termsOfUse.svg';
import { useConfig } from '../../../../../../contexts/ConfigContext';
import i18n from '../../../../../../i18n/i18n';
import MenuItem from '../../../MenuItem';
import { RightComponent, iconSize } from '../../helpers';

const TermsOfUseMenuItem = () => {
    const { config } = useConfig();

    return (
        <MenuItem
            leftIcon={<TermsOfUseIcon width={iconSize} height={iconSize} />}
            title={i18n.t('profile.menuItems.termsOfUse.title')}
            description={i18n.t('profile.menuItems.termsOfUse.description')}
            rightComponent={<RightComponent type="explore" />}
            onPress={() => {
                if (config?.termsOfUseUrl) {
                    openUrlOrShowError(config.termsOfUseUrl);
                }
            }}
        />
    );
};

export default TermsOfUseMenuItem;
