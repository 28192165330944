import React, {
    FC,
    PropsWithChildren,
    createContext,
    useCallback,
    useEffect,
    useMemo,
} from 'react';

import { PaymentOptionOutput } from '@Data/Models';
import { isWeb } from '@helpers/app';
import { valueToWei } from '@helpers/wallet';
import useGamingWalletTransactionsHistory from '@hooks/useGamingWalletTransactionsHistory';
import { useGamingBalances } from '@hooks/wallet/useGamingBalances';
import {
    GamingIguBalanceResponse,
    IGU_WALLET_TRANSACTIONS,
    IGamingWallet,
    IguWalletTransaction,
} from '@models/gamingWallet';

import { usePersistStorage } from '../vendor/react-native-user-persist-storage';
import { useConfig } from './ConfigContext';
import { useWallet } from './Wallet/WalletContext';
import { Coin } from './Wallet/WalletHelpers';

export type GamingWalletContextType = {
    gamingWalletCarouselData: IGamingWallet[];
    gamingBalanceLoading: boolean;
    getGamingBalance: (silent: boolean) => Promise<void>;
    canWithdraw: boolean;
    gamingBalanceRefreshLoading: boolean;
    gamingBalance: GamingIguBalanceResponse | undefined;
    topUpAmount: string;
    setTopUpAmount: (amount: string) => void;
    resetGamingWallet: () => void;
    customPayment: PaymentOptionOutput | undefined;
    selectedWithdrawToken: Coin;
    setSelectedWithdrawToken: (amount: Coin) => void;

    isBNBEnough: boolean;
    isPendingWithdraw: boolean;
    transactionHistoryLoading: boolean;
    transactionHistoryRefreshLoading: boolean;
    filterLoading: boolean;
    loadMore: boolean;
    transactionHistory: IguWalletTransaction[] | undefined;
    getIguTransactionHistory: (silent: boolean) => Promise<void>;
    getIguTransactionHistoryFilter: (
        item: IGU_WALLET_TRANSACTIONS,
        force?: boolean
    ) => Promise<void>;
    getIguTransactionHistoryLoadMore: () => Promise<void>;
    filter: IGU_WALLET_TRANSACTIONS;
    isLoading: boolean;
    isRefresh: boolean;
    loadData: (silent: boolean) => Promise<void>;
    canLoadMore: boolean;
};

export const useGamingWallet = () => React.useContext(GamingWalletContext);

export const GamingWalletContext = createContext<GamingWalletContextType>({
    gamingWalletCarouselData: [],
    gamingBalanceLoading: true,
    getGamingBalance: async (silent: boolean) => undefined,
    canWithdraw: false,
    gamingBalanceRefreshLoading: false,
    gamingBalance: undefined,
    topUpAmount: '',
    setTopUpAmount: (amount: string) => undefined,
    resetGamingWallet: () => undefined,
    customPayment: undefined,
    selectedWithdrawToken: Coin.igu,
    setSelectedWithdrawToken: (amount: Coin) => undefined,
    isBNBEnough: false,
    isPendingWithdraw: false,
    transactionHistoryLoading: true,
    transactionHistoryRefreshLoading: false,
    filterLoading: false,
    loadMore: false,
    transactionHistory: undefined,
    getIguTransactionHistory: async (silent: boolean) => undefined,
    getIguTransactionHistoryFilter: async (
        item: IGU_WALLET_TRANSACTIONS,
        force?: boolean
    ) => undefined,
    getIguTransactionHistoryLoadMore: async () => undefined,
    filter: IGU_WALLET_TRANSACTIONS.All,
    isLoading: true,
    isRefresh: false,
    loadData: async (silent: boolean) => undefined,
    canLoadMore: false,
});

export const TOP_UP_MAX = 99999999999;
export const TOP_UP_MIN = 1;

const GamingWalletProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const { config } = useConfig();
    const {
        gamingWalletCarouselData,
        gamingBalanceLoading,
        getGamingBalance,
        canWithdraw,
        refreshLoading: gamingBalanceRefreshLoading,
        gamingBalance,
    } = useGamingBalances();

    const {
        transactionHistoryLoading,
        refreshLoading: transactionHistoryRefreshLoading,
        filterLoading,
        loadMore,
        transactionHistory,
        getIguTransactionHistory,
        getIguTransactionHistoryFilter,
        getIguTransactionHistoryLoadMore,
        filter,
        canLoadMore,
    } = useGamingWalletTransactionsHistory();

    const { walletBalance, fee } = useWallet();

    const loadData = useCallback(
        async (silent: boolean) => {
            await Promise.all([
                getGamingBalance(silent),
                getIguTransactionHistory(silent),
            ]);
        },
        [getIguTransactionHistory]
    );

    const [topUpAmount, setTopUpAmount] = usePersistStorage<string>(
        'GamingWalletProvider.topUpAmount',
        '',
        {
            persist: isWeb,
        }
    );
    const [selectedWithdrawToken, setSelectedWithdrawToken] =
        usePersistStorage<Coin>(
            'GamingWalletProvider.selectedWithdrawToken',
            Coin.igu,
            {
                persist: isWeb,
            }
        );

    const resetGamingWallet = () => {
        setTopUpAmount('');
        setSelectedWithdrawToken(Coin.igu);
    };

    const customPayment = useMemo(() => {
        return {
            token: Coin.igu,
            tokenAddress: config?.iguTokenContractAddress || '',
            amountWei: valueToWei(topUpAmount),
            productId: null,
            priceString: undefined,
        };
    }, [config, topUpAmount]);

    const isBNBEnough = useMemo(() => {
        return (
            Number(walletBalance?.bnb.value) >=
            Number(fee?.executeTransaction.IGU)
        );
    }, [fee?.executeTransaction.IGU, walletBalance?.bnb]);

    const isPendingWithdraw = useMemo(
        () => !!gamingBalance?.iguPendingWithdraw,
        [gamingBalance]
    );

    const isLoading = useMemo(
        () => transactionHistoryLoading || gamingBalanceLoading,
        [transactionHistoryLoading, gamingBalanceLoading]
    );

    const isRefresh = useMemo(
        () => transactionHistoryRefreshLoading || gamingBalanceRefreshLoading,
        [transactionHistoryRefreshLoading, gamingBalanceRefreshLoading]
    );

    return (
        <GamingWalletContext.Provider
            value={{
                gamingWalletCarouselData,
                gamingBalanceLoading,
                getGamingBalance,
                canWithdraw,
                gamingBalanceRefreshLoading,
                gamingBalance,
                topUpAmount,
                setTopUpAmount,
                resetGamingWallet,
                customPayment,
                selectedWithdrawToken,
                setSelectedWithdrawToken,
                isBNBEnough,
                isPendingWithdraw,
                transactionHistoryLoading,
                transactionHistoryRefreshLoading,
                filterLoading,
                loadMore,
                transactionHistory,
                getIguTransactionHistory,
                getIguTransactionHistoryFilter,
                getIguTransactionHistoryLoadMore,
                filter,
                isLoading,
                isRefresh,
                loadData,
                canLoadMore,
            }}>
            {children}
        </GamingWalletContext.Provider>
    );
};

export default GamingWalletProvider;
