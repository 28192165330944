import * as React from 'react';
import { useEffect, useState } from 'react';
import {
    KeyboardAvoidingView,
    SafeAreaView,
    VirtualizedList,
} from 'react-native';

import { LinearGradient } from 'expo-linear-gradient';

import { useMutation } from '@apollo/client';

import {
    ModerateSocialTaskResponse,
    ModerationSocialTask,
    RejectReasonItem,
} from '../../../Data/Models';
import { MODERATE_SOCIAL_TASK } from '../../../Data/Requests';
import Button from '../../../components/Button';
import EmptyList from '../../../components/EmptyList';
import Input from '../../../components/Input';
import LoadingComponent from '../../../components/LoadingComponent';
import ModalBottom from '../../../components/ModalBottom';
import ModalLoader from '../../../components/ModalLoader';
import PetCollectionListItem from '../../../components/PetCollectionListItem';
import PullToRefresh from '../../../components/PullToRefresh';
import { View } from '../../../components/Themed';
import Colors from '../../../constants/Colors';
import { REGEX_PATTERNS } from '../../../constants/Regex';
import { useKeyboard } from '../../../contexts/KeyboardContext';
import { useTheme } from '../../../contexts/ThemeContext';
import { useUser } from '../../../contexts/UserContext';
import { isIOS, isWeb, isWebAndroid } from '../../../helpers/app';
import { sliceLink } from '../../../helpers/helpers';
import {
    toastError,
    toastErrorUnknown,
    toastSuccess,
} from '../../../helpers/toastNotification';
import useGetModerationSocialTask from '../../../hooks/admin/useGetModerationSocialTask';
import useQrCodeScanner from '../../../hooks/useQrCodeScanner';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import NavigationBar from '../../../navigation/NavigationBar';
import HeaderText from '../../TabBar/Play/SocializeToEarn/components/HeaderText';
import SocialNetworkCard from '../../TabBar/Play/SocializeToEarn/components/SocialNetworkCard';
import RejectReason from '../components/RejectReason';
import { moderationRejectReasons } from '../constants';
import stylesMain from './styles';

interface RenderItemIProps {
    item: RejectReasonItem;
}

export default function AdminVerifyScreen() {
    const { theme } = useTheme();
    const styles = useThemedStyles(stylesMain);
    const { reloadUser } = useUser();

    const { isKeyboardVisible } = useKeyboard();

    const [idNotFoundModal, setIdNotFoundModalVisible] =
        React.useState<boolean>(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const showContent = !isFocused || (isWeb && !isWebAndroid);
    const [petID, setPetID] = React.useState<string>('');
    const [chosenReason, setChosenReason] = React.useState<RejectReasonItem>();
    const [errorID, setErrorID] = React.useState('');

    const {
        getModerationSocialTask,
        moderationTask,
        moderationSocialTaskLoading,
        moderationSocialTaskRefreshLoading,
    } = useGetModerationSocialTask();

    const [moderateSocialTask, { loading: moderateSocialTaskLoading }] =
        useMutation<ModerateSocialTaskResponse>(MODERATE_SOCIAL_TASK);

    const scanAction = (code: string) => {
        const slicedLink = sliceLink(code);
        if (!slicedLink) {
            toastErrorUnknown('top');
            return;
        }
        setPetID(slicedLink || '');
        handleVerification(false, slicedLink);
    };

    const { scanButton, scannerModal } = useQrCodeScanner('verify', scanAction);

    useEffect(() => {
        getModerationSocialTask();
    }, []);

    const onRefresh = () => {
        getModerationSocialTask(true);
    };

    const handleNotFoundPress = () => {
        setIdNotFoundModalVisible(true);
    };

    const handleChangeText = (text: string) => {
        setPetID(text.toUpperCase());
        if (!text.match(REGEX_PATTERNS.verifyId.regex)) {
            setPetID(petID);
            setErrorID(i18n.t('admin.verify.regexError'));
            return;
        }
        setErrorID('');
    };

    const verify = async (
        token: string | null,
        currentSubtask: ModerationSocialTask
    ) => {
        Console.log('token', token);

        await moderateSocialTask({
            variables: {
                taskId: currentSubtask.id,
                token,
                moderatorRejectReason: chosenReason?.status,
            },
            onError: () => {
                setPetID('');
                setErrorID(i18n.t('admin.verify.messages.error'));
                toastError(undefined, i18n.t('admin.verify.messages.failed'));
            },
            onCompleted: (response) => {
                Console.log(response);
                if (response.moderateSocialTask) {
                    setPetID('');
                    if (token) {
                        toastSuccess(
                            undefined,
                            i18n.t('admin.verify.messages.success')
                        );
                    } else {
                        toastSuccess(
                            undefined,
                            i18n.t('admin.verify.messages.notFound')
                        );
                    }

                    getModerationSocialTask();
                    reloadUser();

                    // reset view
                    setIsFocused(false);
                } else {
                    setErrorID(i18n.t('admin.verify.messages.error'));
                    toastError(
                        undefined,
                        i18n.t('admin.verify.messages.error')
                    );
                }
            },
        });
    };

    const handleVerification = (notFound: boolean, id?: string) => {
        // Keyboard.dismiss();
        if (moderationTask) {
            setErrorID('');
            if (notFound) {
                verify(null, moderationTask);
                return;
            }
            verify(id || petID, moderationTask);
        }
    };

    const renderItem = React.useCallback(() => {
        return (
            <>
                {showContent && (
                    <HeaderText
                        title={i18n.t('admin.verify.subtitle')}
                        text={i18n.t('admin.verify.text')}
                        containerStyle={[styles.header]}
                    />
                )}
            </>
        );
    }, [showContent]);

    const renderFooter = () => {
        if (!moderationTask) {
            return null;
        }

        return (
            <>
                <SocialNetworkCard
                    containerStyle={styles.socialCardWrapper}
                    socialTask={moderationTask}
                    withLink
                />
                <View style={styles.petIdWrapper}>
                    <Input
                        component={scanButton()}
                        label={i18n.t('admin.verify.label')}
                        autoCapitalize="characters"
                        style={styles.input}
                        value={petID || ''}
                        onChangeText={(text) => handleChangeText(text)}
                        placeholder={i18n.t('admin.verify.placeHolder')}
                        placeholderTextColor={Colors[theme].text.secondary}
                        maxLength={20}
                        error={errorID}
                        onFocus={() => {
                            setIsFocused(true);
                        }}
                        onBlur={() => {
                            setIsFocused(false);
                        }}
                    />
                </View>
            </>
        );
    };

    const renderEmptyList = () => {
        return (
            <View style={styles.emptyContainer}>
                <EmptyList
                    emptyTitle={i18n.t('admin.verify.emptyTitle')}
                    emptyText={i18n.t('admin.verify.emptyText')}
                    withAnimation="cry"
                />
            </View>
        );
    };

    return (
        <View style={styles.mainContainer}>
            <NavigationBar
                backIcon
                containerStyle={styles.navBg}
                filled={false}
                title={i18n.t('admin.verify.title')}
            />

            {!!moderationTask && showContent && (
                <LinearGradient
                    colors={[
                        Colors[theme].gradient.start,
                        Colors[theme].gradient.end,
                    ]}
                    style={[styles.gradientBackground]}
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 1 }}
                />
            )}

            <KeyboardAvoidingView
                style={styles.container}
                behavior={isIOS ? 'padding' : 'height'}
                contentContainerStyle={styles.avoidingView}>
                <View style={styles.main}>
                    {!!moderationTask && showContent && (
                        <View style={[styles.shadowContainer]}>
                            <View style={styles.playCardWrapper}>
                                <PetCollectionListItem
                                    pet={moderationTask.pet}
                                    style={styles.playCard}
                                />
                            </View>
                            <View style={styles.shadow} />
                        </View>
                    )}

                    <>
                        {moderationSocialTaskLoading &&
                        !moderationSocialTaskRefreshLoading ? (
                            <LoadingComponent />
                        ) : (
                            <>
                                {moderationTask ? (
                                    <View style={styles.wrapper}>
                                        <PullToRefresh
                                            renderItem={renderItem}
                                            onRefresh={onRefresh}
                                            refreshing={
                                                moderationSocialTaskRefreshLoading
                                            }
                                            canRefresh={!isKeyboardVisible}
                                            keyboardDismiss
                                            FooterComponent={renderFooter()}
                                        />
                                    </View>
                                ) : (
                                    <PullToRefresh
                                        renderItem={renderEmptyList}
                                        onRefresh={onRefresh}
                                        refreshing={
                                            moderationSocialTaskRefreshLoading
                                        }
                                        canRefresh={!isKeyboardVisible}
                                        keyboardDismiss
                                    />
                                )}
                            </>
                        )}
                    </>
                </View>
                {!!moderationTask && (
                    <View style={styles.buttonWrapper}>
                        <Button
                            title={i18n.t('admin.verify.buttonVerify')}
                            onPress={() => handleVerification(false)}
                            containerStyle={styles.gap}
                            disabled={
                                !petID ||
                                !!errorID ||
                                moderateSocialTaskLoading ||
                                moderationSocialTaskLoading
                            }
                        />
                        <Button
                            title={i18n.t('admin.verify.buttonNotFound')}
                            type="outline"
                            onPress={handleNotFoundPress}
                        />
                    </View>
                )}
                <SafeAreaView />
            </KeyboardAvoidingView>
            <ModalBottom
                isVisible={idNotFoundModal}
                onClose={() => setIdNotFoundModalVisible(false)}
                modalHeight="100%"
                titleText={i18n.t('admin.verify.modalPetIdNotFound.title')}
                additionalText={i18n.t('admin.verify.modalPetIdNotFound.text')}>
                <VirtualizedList
                    showsVerticalScrollIndicator={false}
                    data={moderationRejectReasons}
                    getItem={(data, index) => data[index]}
                    getItemCount={(data) => data.length}
                    keyExtractor={(item) => item.text}
                    renderItem={({ item }: RenderItemIProps) => (
                        <RejectReason
                            item={item}
                            isSelected={item.text === chosenReason?.text}
                            onSelectItem={setChosenReason}
                        />
                    )}
                />
                <Button
                    title={i18n.t('admin.verify.modalPetIdNotFound.button1')}
                    onPress={() => {
                        setIdNotFoundModalVisible(false);
                        handleVerification(true);
                    }}
                    containerStyle={styles.notFoundButton}
                    disabled={!chosenReason}
                />
            </ModalBottom>
            <ModalLoader
                isVisible={moderateSocialTaskLoading}
                text={i18n.t('admin.verify.loadingVerifyUser')}
            />
            {scannerModal()}
        </View>
    );
}
