import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { MAX_WINDOW_WIDTH } from '../../contexts/DimensionsContext';
import { scale, verticalScale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        modal: {
            width: '100%',
            margin: 0,
            padding: 0,
            maxWidth: MAX_WINDOW_WIDTH,
            alignSelf: 'center',
        },
        container: {
            flex: 1,
            paddingHorizontal: scale(16),
        },
        scannerWrapper: {
            marginTop: verticalScale(24),
            height: verticalScale(285),
            width: '100%',

            borderWidth: 2,
            borderRadius: 10,
            borderColor: Colors[theme].text.onLight,
            overflow: 'hidden',
        },
        scannerScanned: {
            borderColor: Colors[theme].border.accent,
        },
        scanner: {
            flex: 1,
        },
        android: {
            position: 'absolute',
            width: '100%',
        },
        title: {
            marginTop: verticalScale(40),
            marginBottom: verticalScale(8),
            textAlign: 'center',
            color: Colors[theme].text.onLight,
            ...font({
                size: 22,
                height: 28,
                weight: '700',
            }),
        },
        text: {
            textAlign: 'center',
            color: Colors[theme].text.onLight,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
        },
        backButton: {
            paddingHorizontal: scale(8),
            paddingVertical: verticalScale(8),
        },
        errorContainer: {
            flex: 1,
            paddingTop: verticalScale(200),
        },
        button: {
            marginTop: verticalScale(16),
        },
    });

export default styles;
