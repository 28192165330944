import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { moderateScale, scale, verticalScale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        modal: {
            alignItems: 'center',
        },
        shadow: {
            borderRadius: moderateScale(20),
            backgroundColor: Colors[theme].modal.shadowColor,
            width: '65%',
            maxWidth: scale(600),
        },
        container: {
            backgroundColor: Colors[theme].modal.background,
            borderRadius: moderateScale(20),
            paddingHorizontal: scale(16),
            paddingTop: verticalScale(24),
            paddingBottom: verticalScale(16),
            marginBottom: verticalScale(4),
        },
        iconWrapper: {
            marginBottom: verticalScale(20),
            alignItems: 'center',
        },
        buttonWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: verticalScale(20),
        },
        title: {
            textAlign: 'center',
            marginBottom: verticalScale(6),
            color: Colors[theme].text.primary,
            ...font({
                size: 18,
                height: 24,
                weight: '700',
            }),
        },
        textWrapper: {
            marginBottom: verticalScale(20),
        },
        text: {
            textAlign: 'center',
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
        buttonLeave: {
            alignSelf: 'center',
            color: Colors[theme].text.secondary,
        },
        buttonContainer: {
            width: '49%',
        },
    });

export default styles;
