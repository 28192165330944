import * as React from 'react';

import _ from 'lodash';

import ButtonsBottom from '@components/ButtonsBottom';
import SafeAreaTabContent from '@components/SafeAreaTabContent';
import StakingWelcomeInfo from '@components/Staking/StakingWelcomeInfo';
import { useStaking } from '@contexts/StakingContext';
import { useStakingHook } from '@hooks/useStakingHook';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { StackActions } from '@react-navigation/native';

import { StakingWelcomeProps } from '../../../../types';

const StakingWelcomeScreen = ({ route }: StakingWelcomeProps) => {
    const navigation = useNavigation();

    const { handleOnBack } = useStakingHook();
    const { refetchStakingPlans, stakingPlansLoading } = useStaking();

    const navigateToStakingPlan = async () => {
        await refetchStakingPlans();
        navigation.dispatch(StackActions.replace(ROUTES.STAKING_OPTIONS));
    };

    return (
        <SafeAreaTabContent>
            <NavigationBar
                filled={false}
                backIcon
                title={i18n.t('staking.welcomeScreen.title')}
                onBack={() => handleOnBack(route?.params)}
            />

            <StakingWelcomeInfo />

            <ButtonsBottom
                title={i18n.t('staking.welcomeScreen.button')}
                onPress={navigateToStakingPlan}
                safe
                loading={stakingPlansLoading}
                disabled={stakingPlansLoading}
            />
        </SafeAreaTabContent>
    );
};

export default StakingWelcomeScreen;
