// eslint-disable-next-line import/extensions
import basicFile from './index.js';

const ru = {
    ...basicFile,
    title: {
        suffix: 'IguVerse',
        /**
         * BottomTab
         */
        Main: 'Главная',
        Play: 'Играть',
        Charity: 'Благотворительность',
        Collection: 'Коллекция',
        Marketplace: 'Маркетплейс',
        Feed: 'Стена',
        Home: 'Главная',
        //MysteryLikes
        HowMysteryLikesWork: 'Загадочные лайки - Как это работает',
        WelcomeHowMysteryLikesWork: 'Загадочные лайки - Добро пожаловать',
        MysteryLikesMain: 'Загадочные лайки - Главная',
        MysteryLikesActiveOrder: 'Загадочные лайки - Активные заказы',
        MysteryLikesHistory: 'Загадочные лайки - История игр',
        MysteryLikesCreateGameWelcome: 'Загадочные лайки - Создать',
        MysteryLikesCreateGameInput: 'Загадочные лайки - Создать',
        MysteryLikesCreateGameSuccess: 'Загадочные лайки - Успех',
        MysteryLikesSelectRange: 'Загадочные лайки - Выбрать диапазон',
        MysteryLikesSelectRangeFinish: 'Загадочные лайки - Информация об игре',

        //
        // NFT - эволюция
        HowNftEvolutionWelcome: 'NFT - эволюция - Как это работает',
        NftEvolutionWelcome: 'NFT - эволюция - Добро пожаловать',
        NftEvolutionSelectPets: 'NFT - эволюция',
        NftEvolutionCheckout: 'NFT - эволюция - Шансы',
        NftEvolutionSuccess: 'NFT - эволюция - Успех',
        NftEvolutionSelectPet: 'NFT - эволюция',
        NftEvolutionMintPet: 'NFT - эволюция - Mint',
        NftEvolutionMintPetSuccess: 'NFT - эволюция - Успех',
        //NftRoyal
        HowNftRoyalWork: 'Фруктовая гонка - Как это работает',
        WelcomeNftRoyalWork: 'Фруктовая гонка - Добро пожаловать',
        NftRoyalMain: 'Фруктовая гонка - Главная',
        NftRoyalActiveOrder: 'Фруктовая гонка - Свободные комнаты',
        NftRoyalCreateGameWelcome: 'Фруктовая гонка - Создать',
        NftRoyalGameConfigurations: 'Фруктовая гонка - Настройки игры',
        NftRoyalEnterCoinsAmount: 'Фруктовая гонка - Указать количество монет',
        NftRoyalCreateGameSuccess: 'Фруктовая гонка - Успех',
        NftRoyalHistory: 'Фруктовая гонка - История',
        NftRoyalGameHistory: 'Фруктовая гонка - История игры',
        NftRoyalPlayGame: 'Фруктовая гонка',
        //
        /**
         * Admin
         */
        AdminRoot: 'Админ',
        AdminVerify: 'Админ - Верификация',
        AdminSendPushNotification: 'Админ - Отправить Push-уведомление',
        AdminReverification: 'Админ - Реверификация',
        AdminBlockUser: 'Админ - Заблокировать пользователя',
        AdminMaintenanceMode: 'Admin - Режим обслуживания',
        AdminAssignWhiteList: 'Admin - Отправить Вайтлист',
        AdminAssignRole: 'Admin - Назначить Роль',
        /**
         * Mint
         */
        MintRoot: 'Минт',
        MintPetRanks: 'Минт — Выбор ранга',
        MintPetSelectGeneration: 'Минт — Выбор способа',
        MintGenerationInfo: 'Информация о минте',
        MintSelectGeneratedNft: 'Минт — Выбрать NFT',
        MintPetPickPhoto: 'Минт — Выбрать фото',
        MintPetEditPhoto: 'Минт — Редактировать фото',
        MintPetName: 'Минт — Выбрать имя питомца',
        MintPetCheckout: 'Минт — Оплата',
        MintPetCheckoutSuccess: 'Минт — Успех',

        /**
         * CreateWallet
         */
        AddWalletInitial: 'Добавить кошелек',
        ImportWallet: 'Импортировать кошелек',
        CreateWalletFirst: 'Создать кошелек',
        CreateWalletPassphrase: 'Создать сид-фразу',
        CreateWalletVerification: 'Верификация кошелька',
        CreateWalletSuccess: 'Успешное создание кошелька',
        /**
         * LOGIN
         */
        Login: 'Логин',
        /**
         * Wallet
         */
        WalletRoot: 'Кошелек',
        // Tabs
        WalletCoinsMain: 'Кошелек — Токены',
        WalletNFTSMain: 'Кошелек - NFT',
        WalletRewardsMain: 'Кошелек - Награды',
        //Gaming
        WalletGamingTab: 'Кошелек - Игровой',
        WalletGamingMain: 'Кошелек - Игровой',
        TopUpGamingWallet: 'Кошелек - Пополнить',
        TopUpCheckoutScreen: 'Кошелек - Оплата',
        TopUpSuccessScreen: 'Кошелек - Успех',
        WithdrawIguSuccessScreen: 'Кошелек - Успех',
        GamingWalletTransactionDetails: 'Кошелек -  Детали транзакции',
        WithdrawInputAmount: 'Кошелек - Вывод',
        // Coins
        WalletCoinsBalance: 'Кошелек - Баланс',
        WalletCoinsWithdraw: 'Кошелек - Вывод',
        WalletCoinsWithdrawSuccess: 'Кошелек - Успешный вывод',
        WalletCoinsTransactionDetails: 'Кошелек - Детали транзакции',
        // NFT
        WalletNFTSList: 'Кошелек - Список NFT',
        WalletNFTSWithdraw: 'Кошелек - Вывод NFT',
        WalletNFTSWithdrawSuccess: 'Кошелек - Успешный вывод NFT',
        WalletNFTSTransactionDetails: 'Кошелек - детали NFT-транзакции',
        // Rewards
        WalletRewardsClaimSuccess: 'Кошелек - Успешное получение наград',
        WalletRewardsTransactionDetails: 'Кошелек - Детали получения наград',
        WalletRewardsTab: 'Кошелек - Награды',
        // Trade
        WalletTradeMain: 'Кошелек - Главная',
        WalletTradeChart: 'Кошелек - торговый график',
        WalletTradeSuccess: 'Кошелек - Успешный обмен',
        // Settings
        WalletSettings: 'Кошелек - Настройки',
        WalletSettingsBackup: 'Кошелек - Резервное копирование настроек',
        // Staking
        StakingWelcome: 'Кошелек - Буст',
        StakingOptions: 'Кошелек - Опции Буста',
        StakingPlans: 'Кошелек - Планы Буста',
        StakingDetailedInfo: 'Кошелек - Подробная информация о Бусте',
        StakingCoinsInput: 'Кошелек - Ввести количество для Буста',
        StakingDailyBoost: 'Кошелек - Ежедневный буст',
        StakingSuccess: 'Кошелек - Успешное начало Буста',
        StakingAlready: 'Кошелек - Уже',

        UnStakingSuccess: 'Кошелек - Успешное прекращение Буста',

        /**
         * Play
         */
        PlayTasks: 'Задания',
        PlayTasksActive: 'Активные задания',
        PlayTasksHistory: 'История заданий',
        PlayEditNFTs: 'Редактировать NFT',
        PlayTasksHistoryDetails: 'Подробная история заданий',
        PlayCollectedExperience: 'Заработанные очки опыта',
        PlaySuccessScreen: 'Успешное выполнение задания',
        /**
         * Socialize To Earn
         */
        SocializeToEarnWelcomeShare: 'SocializeToEarn - Добро пожаловать',
        SocializeToEarnShareYourPhoto:
            'SocializeToEarn - Поделиться фотографией',
        SocializeToEarnCreateSharePhoto:
            'SocializeToEarn - Редактировать фотографию',
        SocializeToEarnVerifyUser:
            'SocializeToEarn - Верифицировать пользователя',

        SocializeToEarnCreateShareTemplate:
            'SocializeToEarn - Поделиться с помощью шаблона',

        SocializeToEarnVerifyUserError: 'SocializeToEarn - Ошибка верификации',
        SocializeToEarnShareYourPhotoSuccess:
            'SocializeToEarn - Поделиться фото: Успех',
        SocializeToEarnWelcomeVerify: 'SocializeToEarn - Верификация',
        SocializeToEarnVerifyUserMatch:
            'SocializeToEarn - Верифицировать пользователя',
        SocializeToEarnVerifyUserNotFound:
            'SocializeToEarnVerify - Пользователь не найден',
        SocializeToEarnVerifyUserFailed:
            'SocializeToEarn - Верификация пользователя не прошла',
        SocializeToEarnVerifyUserAlreadyCompleted:
            'SocializeToEarn - Верификация пользователя уже завершена',
        /**
         * Move To Earn
         */
        MoveToEarnWelcome: 'MoveToEarn',
        MoveToEarnProgress: 'MoveToEarn - Прогресс',
        MoveToEarnSummary: 'MoveToEarn - Описание',
        /**
         * Play to Earn
         */
        PlayToEarn: 'PlayToEarn',
        PlayToEarnWelcome: 'PlayToEarn - Главная',
        PlayToEarnProgress: 'PlayToEarn - Прогресс',
        /**
         * Charity
         */
        CharityOrganizations: 'Благотворительность — Организации',
        CharityDetailedOrganization:
            'Благотворительность - Подробно об организации',
        CharityOrganizationDonations:
            'Благотворительность - Пожертвования благотворительной организации',
        CharityEditSelectedOrganization:
            'Благотворительность - Редактировать выбранную организацию',
        CharityEditDetailedOrganization:
            'Благотворительность - Редактировать детали организации',
        /**
         * Collection
         */
        CollectionMain: 'Коллекция',
        CollectionDetailedPet: 'Коллекция — Мой питомец',
        CollectionPetLevelUpCheckout: 'Коллекция — Оплата повышения уровня',
        CollectionPetLevelUpSuccess: 'Коллекция - Успешное повышение уровня',
        CollectionPetRankUpCheckout: 'Коллекция - Оплата повышения ранга',
        CollectionPetRankUpSuccess: 'Коллекция - Успешное повышение ранга',
        CollectionPetHealUpCheckout: 'Коллекция - Оплата лечения',
        CollectionPetHealUpSuccess: 'Коллекция - Успешное лечение',
        CollectionPetReviveCheckout: 'Коллекция - Оплата восстановления',
        CollectionPetReviveSuccess: 'Коллекция - Успешное восстановление',
        CollectionPetAgeReviveCheckout: 'Коллекция - Оплата восстановления',
        CollectionPetAgeReviveSuccess: 'Коллекция - Успешное восстановление',
        CollectionMultiplePetsHealUpProceeding:
            'Коллекция - Кормить всех питомцев',
        CollectionMultiplePetsHealUpCheckout: 'Коллекция - Оплата кормления',
        CollectionMultiplePetsHealUpSuccess:
            'Коллекция - Кормление завершено успехом',
        /**
         * Profile
         */
        ProfileRoot: 'Профиль',
        ProfileMain: 'Профиль — Главная',
        ProfileLanguagesSettings: 'Профиль — Языковые настройки',
        ProfileNotificationsSettings: 'Профиль - Настройки',
        ProfileSocialAccountsSettings:
            'Профиль - Настройки социальных аккаунтов',
        ProfileSocialAccessibilitySettings: 'Профиль - Настройки доступности',
        ProfileSocialMedia: 'Профиль - Социальные сети',
        ProfileSendPushNotification: 'Профиль - Отправить Push-уведомление',

        /**
         * Others
         */
        Dev: 'Разработка',
        RanksDetailedInfo: 'Подробная информация о рангах',
        EnergyInfoScreen: 'Информация об энергии',

        // REFERRALS

        Referrals: 'Рефералы',
        ReferralsAll: 'Рефералы - Все',
        ReferralsInvites: 'Рефералы - Приглашения',
        ReferralsInvite: 'Рефералы - Пригласить',
        ReferralsHowItsWork: 'Рефералы - Как это устроено',

        // LEADERBOARD
        Leaderboard: 'Таблица лидеров',
        LeaderboardDailyTasks: 'Таблица лидеров - Задания',
        LeaderboardPvpGames: 'Таблица лидеров - PvP',
        //

        //Mystery Box
        MysteryBoxMain: 'Загадочные Коробки',
        MysteryBoxPurchaseWelcome: 'Загадочные Коробки - Купить',
        MysteryBoxPurchase: 'Загадочные Коробки - Купить',
        MysteryBoxPurchaseCheckout: 'Загадочные Коробки - Оплатить',
        MysteryBoxPurchaseSuccess: 'Загадочные Коробки - Успех',

        //HOW
        HowToCharity: 'Как устроена благотворительность',
        HowToPlay: 'Как играть',
        HowToReferrals: 'Рефералы - Как это устроено',
        HowToAvailableRewards: 'Как устроены доступные награды',
        HowToLockedRewards: 'Как устроены заблокированные награды',
        HowToGamingAvailableWallet: 'Как работает Доступный кошелек',
        HowToGamingPendingWallet: 'Как работает Кошелек ожидания',
        HowToSocializeToEarn: 'Как поделиться фото',
        HowToVerifyUser: 'Как находить Pet ID',
        HowToPurchaseBNB: 'Как купить BNB',
        HowToCommissionFee: 'Плата за услуги',
        HowToFee: 'Плата за услуги',
        HowToPurchaseSpadeId: 'Как использовать SPACE ID',

        // BONUS MINT RANK
        BonusMintRanksInfo: 'Минт - Бонусные ранги',
        BonusMintRanksCarousel: 'Минт - Все бонусные ранги',

        InitialMint: 'Приобретите Своего Первого Питомца',
        InitialReferral: 'Вы были приглашены в IguVerse',
        YourPetsAreHungry: 'Ваши питомцы голодны!',
        UpdateApp: 'Новое обновление',
        //ONBOARDING
        MainWalletOnBoardingScreen: 'Основной Кошелек',
        LoginOnBoardingScreen: 'Добро пожаловать',

        //MARKETPLACE
        MarketPlaceActiveOrders: 'Маркетплейс - Активные ордера',
        MarketPlaceMyProfile: 'Маркетплейс - Мой профиль',
        MarketPlaceProfile: 'Маркетплейс - Профиль',
        MarketPlaceWelcomeCreateOrder: 'Маркетплейс - Добро пожаловать',
        MarketPlaceSelectNft: 'Маркетплейс - Выбрать NFT',
        MarketPlaceSelectNftPrice: 'Маркетплейс - Выбрать цену',
        MarketPlaceOverviewGroup: 'Маркетплейс - Обзор',
        MarketPlaceOverview: 'Маркетплейс - Обзор',
        MarketPlaceOverviewOwnerHistory: 'Маркетплейс - История владельцев',
        MarketPlaceBuyCheckout: 'Маркетплейс - Оформление заказа',
        MarketPlaceCreateOrderSuccess: 'Маркетплейс - Успех',
        MarketPlaceDelistSuccess: 'Маркетплейс - Успех',
        MarketPlaceBuySuccess: 'Маркетплейс - Успех',

        //Achievements
        Achievements: 'Достижения',
        AchievementsDetails: 'Достижения - Детали',

        //PassCode
        PassCodeFaceId: 'Пароль - Биометрический',
        PassCodeNew: 'Пароль - Включить',
        PassCodeChange: 'Пароль - Изменить',
        PassCodeSuccess: 'Пароль - Успех',
        PassCodeSettings: 'Пароль - Настройки',
        PassCodeConfirm: 'Пароль - Подтвердить',

        //Lucky Strike
        WelcomeLuckyStrike: 'Лаки Страйк - Добро пожаловать',
        LuckyStrike: 'Лаки Страйк',
        LuckyStrikeMain: 'Лаки Страйк - Раунды',
        LuckyStrikeHistory: 'Лаки Страйк - История',
        HowLuckyStrikeWork: 'Лаки Страйк - Как это работает',
        LuckyStrikeAllParticipants: 'Лаки Страйк - Участники',
        LuckyStrikeWaitingForResult: 'Лаки Страйк - Результат',
        LuckyStrikeUserTickets: 'Лаки Страйк - Билеты',
        LuckyStrikeUserTicketsWait: 'Лаки Страйк - Билеты',
        LuckyStrikeAllWinners: 'Лаки Страйк - Победители',
        LuckyStrikeGameHistory: 'Лаки Страйк - История',

        //Quantum Spin
        WelcomeQuantumSpin: 'Квантовый диск - Добро пожаловать',
        HowQuantumSpinWork: 'Квантовый диск - Как это работает',
        QuantumSpin: 'Квантовый диск',
        QuantumSpinMain: 'Квантовый диск - Колесо',
        QuantumSpinHistory: 'Квантовый диск - История',
        QuantumSpinGameHistory: 'Квантовый диск - История',
        QuantumSpinProbablyFair:
            'Квантовый диск - Проверяемый алгоритм рандомизации',
        QuantumSpinProbablyFairHistory: 'Квантовый диск - История',

        //TokenTeller
        WelcomeTokenTeller: 'Игумания - Добро пожаловать',
        TokenTeller: 'Игумания',
        TokenTellerMain: 'Игумания - Раунды',
        TokenTellerHistory: 'Игумания - История',
        HowTokenTellerWork: 'Игумания - Как это работает',
        TokenTellerChart: 'Игумания - Диаграмма',
        TokenTellerAllParticipants: 'Игумания - Участники',
        TokenTellerWaitingForResult: 'Игумания - Wait',
        TokenTellerGameHistory: 'Игумания - Ожидание',
    },
};

export default ru;
