import { useState } from 'react';

import _ from 'lodash';

import { useApolloClient } from '@apollo/client';

import { SingleEnergyEstimatedGameRewardResponse } from '../Data/Models';
import { GET_SINGLE_ENERGY_ESTIMATED_GAME_REWARD } from '../Data/Requests';
import { isWeb } from '../helpers/app';
import { errorsHandler } from '../helpers/errors';
import { usePersistStorage } from '../vendor/react-native-user-persist-storage';

const usePetsEstimatedGameRewardData = () => {
    const client = useApolloClient();

    const [petsEstimatedGameRewardData, setPetsEstimatedGameRewardData] =
        usePersistStorage<SingleEnergyEstimatedGameRewardResponse | null>(
            'PlayProvider.petsEstimatedGameRewardData',
            null,
            {
                persist: isWeb,
            }
        );

    const fetchEnergyReward = async () => {
        try {
            const response =
                await client.query<SingleEnergyEstimatedGameRewardResponse>({
                    query: GET_SINGLE_ENERGY_ESTIMATED_GAME_REWARD,
                    fetchPolicy: 'network-only',
                });
            setPetsEstimatedGameRewardData(response.data);
            return response.data;
        } catch (error) {
            errorsHandler(error);
        }
    };

    return {
        petsEstimatedGameRewardData,
        fetchEnergyReward,
    };
};

export default usePetsEstimatedGameRewardData;
