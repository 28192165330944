import * as React from 'react';
import { SafeAreaView } from 'react-native';

import StepFourImage from '@assets/icons/mysteryGames/NftEvolutionHowItWorks/stepFour.svg';
import StepOneImage from '@assets/icons/mysteryGames/NftEvolutionHowItWorks/stepOne.svg';
import StepThreeImage from '@assets/icons/mysteryGames/NftEvolutionHowItWorks/stepThree.svg';
import StepTwoImage from '@assets/icons/mysteryGames/NftEvolutionHowItWorks/stepTwo.svg';
import ButtonsBottom from '@components/ButtonsBottom';
import Carousel from '@components/Carousel';
import CarouselScrollView from '@components/Carousel/components/CarouselScrollView';
import { Text, View } from '@components/Themed';
import { useDimensions } from '@contexts/DimensionsContext';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

const data = ['stepOne', 'stepTwo', 'stepThree', 'stepFour'];
const flow = 'WelcomeNftEvolution';

interface IProps {
    onButtonPress?: () => void;
    isLoading?: boolean;
}

const NftEvolutionHowItWorks = ({ onButtonPress, isLoading }: IProps) => {
    const { windowWidth } = useDimensions();
    const styles = useThemedStyles(stylesMain);

    const renderItem = (item: string) => {
        const icon = () => {
            switch (item) {
                case 'stepOne':
                    return <StepOneImage />;
                case 'stepTwo':
                    return <StepTwoImage />;
                case 'stepThree':
                    return <StepThreeImage />;

                default:
                    return <StepFourImage />;
            }
        };

        return (
            <View style={styles.itemContainer}>
                <View style={styles.content}>
                    <View style={styles.image}>{icon()}</View>

                    <View style={styles.textBlock}>
                        <Text style={styles.title}>
                            {i18n.t(
                                `HomeScreen.NftEvolution.${flow}.${item}.title`
                            )}
                        </Text>

                        <Text style={styles.text}>
                            {i18n.t(
                                `HomeScreen.NftEvolution.${flow}.${item}.text`
                            )}
                        </Text>
                    </View>
                </View>
            </View>
        );
    };

    return (
        <>
            <Carousel
                data={data}
                sliderWidth={windowWidth}
                itemWidth={windowWidth}
                renderItem={renderItem}
                fullHeight
                pagination
                centredVertical
                bulletActiveStyle={styles.bulletActive}
                bulletStyle={styles.bulletStyle}
            />
            {!!onButtonPress ? (
                <View style={styles.buttonWrapper}>
                    <ButtonsBottom
                        disabled={isLoading}
                        loading={isLoading}
                        isOneColor
                        title={i18n.t('general.BUTTONS.startPlaying')}
                        onPress={onButtonPress}
                        safe
                        containerStyle={styles.button}
                        buttonType="outline"
                    />
                </View>
            ) : (
                <SafeAreaView />
            )}
        </>
    );
};

export default NftEvolutionHowItWorks;
