export function formatTradeAmount(amount: string) {
    const amountStrArray = Number(amount).toString().split('.');
    let finalAmountStr =
        Number(amountStrArray[0]) > 0
            ? Number(amountStrArray[0]).toString()
            : '';
    if (amountStrArray[1]) finalAmountStr += amountStrArray[1];
    for (
        let i = 0;
        i < 18 - (amountStrArray[1] ? amountStrArray[1].length : 0);
        i++
    )
        finalAmountStr += '0';

    return finalAmountStr;
}
