import * as React from 'react';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { DonationsHistoryResponseItem } from '../../../../../Data/Models';
import ExplorerLink from '../../../../../components/ExplorerLink';
import Icon, { ICON_NAMES } from '../../../../../components/Icons';
import CoinIcon from '../../../../../components/Icons/CoinIcon';
import OtherUserAvatar from '../../../../../components/OtherUserAvatar';
import { Text, View } from '../../../../../components/Themed';
import { useWallet } from '../../../../../contexts/Wallet/WalletContext';
import { Coin } from '../../../../../contexts/Wallet/WalletHelpers';
import {
    balanceShortFormatterFromWei,
    truncateEthAddress,
    valueFromWei,
} from '../../../../../helpers/wallet';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import stylesMain from './styles';

dayjs.extend(relativeTime);

interface IProps {
    donation: DonationsHistoryResponseItem;
}

const UserDonate = ({ donation }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { priceInUsd } = useWallet();
    const isPayout = !donation?.user;

    return (
        <View style={styles.userDonateContainer}>
            {isPayout ? (
                <>
                    <View>
                        <Icon name={ICON_NAMES.HEART} size={40} />
                    </View>
                    <View style={styles.userIdContainer}>
                        <Text style={styles.userId}>
                            {i18n.t('charity.payout')}
                        </Text>
                        <Text style={styles.time}>
                            {dayjs(donation.createdAt).fromNow()}
                        </Text>
                    </View>
                    <View style={styles.valueWrapper}>
                        <View style={styles.igupContainer}>
                            <Text style={styles.userDonate}>
                                {balanceShortFormatterFromWei(
                                    donation.igupAmountWei
                                )}
                            </Text>
                            <CoinIcon slug="IGUP" size={24} />
                        </View>
                        <Text style={styles.textBalanceDollar}>{`≈ ${priceInUsd(
                            Coin.igup,
                            valueFromWei(donation.igupAmountWei)
                        )} $`}</Text>
                        <View>
                            <ExplorerLink
                                explorerUrl={donation.txExplorerUrl}
                                linkStyles={styles.explorerUrl}
                                iconType="secondary"
                            />
                        </View>
                    </View>
                </>
            ) : (
                <>
                    <OtherUserAvatar
                        imageUrl={donation.user.mainPet?.image.thumbnailUrl}
                        userId={donation.user.walletAddress}
                        size={40}
                    />
                    <View style={styles.userIdContainer}>
                        <Text
                            style={styles.userId}
                            numberOfLines={1}
                            ellipsizeMode="middle">
                            {truncateEthAddress(
                                donation.user.username ||
                                    donation.user.walletAddress
                            )}
                        </Text>
                        <Text style={styles.time}>
                            {dayjs(donation.createdAt).fromNow()}
                        </Text>
                    </View>
                    <View style={styles.valueWrapper}>
                        <View style={styles.igupContainer}>
                            <Text style={styles.userDonate}>
                                +
                                {balanceShortFormatterFromWei(
                                    donation.igupAmountWei
                                )}
                            </Text>
                            <CoinIcon slug={'IGUP'} size={24} />
                        </View>
                        <Text style={styles.textBalanceDollar}>{`≈ ${priceInUsd(
                            Coin.igup,
                            valueFromWei(donation.igupAmountWei)
                        )} $`}</Text>
                    </View>
                </>
            )}
        </View>
    );
};

export default UserDonate;
