import { useCallback, useContext, useMemo, useState } from 'react';

import {
    CreateHealUpPaymentOutput,
    CreateHealUpPaymentResponse,
    PaymentOptionOutput,
    Pet,
} from '@Data/Models';
import { CREATE_HEALUP_PAYMENT } from '@Data/Requests';
import { useMutation } from '@apollo/client';
import { PlayContext } from '@contexts/PlayContext';
import { ITransactionResult, useWallet } from '@contexts/Wallet/WalletContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { isGraphqlError } from '@helpers/helpers';

export type HealTransactionData = {
    pet: Pet;
    state: 'initial' | 'loading' | 'error' | 'finished';
    healthToRestore: number;
    transactionResult?: ITransactionResult | null;
    paymentOption?: PaymentOptionOutput | null;
};

const usePetsHeal = (pets: Pet[], paymentOption: PaymentOptionOutput) => {
    const { refetchPets } = useContext(PlayContext);
    const [transactions, setTransactions] = useState<HealTransactionData[]>(
        pets.map((pet) => {
            const data: HealTransactionData = {
                pet,
                healthToRestore: 100 - pet.health,
                state: 'initial',
                paymentOption,
            };
            return data;
        })
    );
    const [error, setError] = useState<boolean>(false);
    const [createPaymentRequest] = useMutation<CreateHealUpPaymentResponse>(
        CREATE_HEALUP_PAYMENT
    );
    const { executeTransaction } = useWallet();

    const anyErrorTransaction = () => {
        return transactions.filter((t) => t.state === 'error').length >= 1;
    };

    const createPayment = async (pet: Pet, payment: PaymentOptionOutput) => {
        const result = await createPaymentRequest({
            variables: {
                petId: pet.id,
                token: payment.token,
                healthPoints: 100 - pet.health,
            },
        });
        return result.data?.createHealUpPayment;
    };

    const fetchPet = async (id: string) => {
        const petsResponse = await refetchPets(true);
        const updatedPet = petsResponse.pets.filter((p) => p.id === id);
        if (updatedPet.length > 0) {
            return updatedPet[0];
        }
        return null;
    };

    const healTransaction = async (
        transaction: HealTransactionData,
        index: number
    ) => {
        if (transaction.state === 'finished') return;

        function updateTransactions(updatedTransaction: HealTransactionData) {
            const updatedTransactions = [...transactions];
            updatedTransactions[index] = updatedTransaction;
            setTransactions(updatedTransactions);
        }

        let paymentResponse: CreateHealUpPaymentOutput | undefined;
        try {
            paymentResponse = await createPayment(
                transaction.pet,
                paymentOption
            );
        } catch (error: any) {
            if (isGraphqlError(error, 'PET_WRONG_STATE')) {
                const updatedPet = await fetchPet(transaction.pet.id);
                if (updatedPet) {
                    transaction.pet = updatedPet;
                    transaction.state =
                        transaction.pet.health === 100 ? 'finished' : 'error';
                }
            } else {
                transaction.state = 'error';
            }
            updateTransactions(transaction);
            return;
        }

        if (!paymentResponse) {
            transaction.state = 'error';
            updateTransactions(transaction);
            return;
        }

        transaction.state = 'loading';
        updateTransactions(transaction);

        // NO need transactions for Vigup
        if ((paymentResponse.payment.token = Coin.vigup)) {
            transaction.state = 'finished';
            transaction.pet = { ...transaction.pet, health: 100 };
            updateTransactions(transaction);
            return;
        }

        try {
            const result = await executeTransaction(
                paymentResponse.payment,
                paymentResponse.signedTransaction,
                false
            );

            if (result) {
                transaction.state = 'finished';
                transaction.transactionResult = result;
                transaction.pet = { ...transaction.pet, health: 100 };
            } else {
                transaction.state = 'error';
            }

            updateTransactions(transaction);
        } catch (error: any) {
            transaction.state = 'error';
        }
    };

    const completed = useMemo(() => {
        if (!transactions) return false;
        return !transactions.find((t) => t.state !== 'finished');
    }, [transactions]);

    async function healAllPets() {
        for (const [index, transaction] of transactions.entries()) {
            await healTransaction(transaction, index);
        }
        setError(anyErrorTransaction());
    }

    const retryFailedTransactions = useCallback(async () => {
        setError(false);
        for (const [index, transaction] of transactions.entries()) {
            if (transaction.state === 'error') {
                await healTransaction(transaction, index);
            }
        }
        setError(anyErrorTransaction());
    }, [transactions]);

    return {
        healAllPets,
        transactions,
        retryFailedTransactions,
        completed,
        error,
    };
};

export default usePetsHeal;
