import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const stylesMain = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            flexDirection: 'row',
            paddingHorizontal: scale(15),
            justifyContent: 'space-between',
        },
        buttonContainer: {
            flex: 1,
        },
        button: {
            flex: 1,
            marginHorizontal: scale(5),
        },
        infoClaim: {
            position: 'absolute',
            zIndex: 10000,
            backgroundColor: 'transparent',
            right: scale(13),
            top: verticalScale(8),
        },
    });

export default stylesMain;
