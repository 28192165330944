import * as React from 'react';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

import { Rank } from '@Data/Models';
import MintPetDollarIcon from '@assets/icons/general/dollar.svg';
import { Text, View } from '@components/Themed';
import { useStorePurchase } from '@contexts/StorePurchaseContext';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    rank: Rank | null;
    customStyle?: StyleProp<ViewStyle>;
    iconSize?: number;
    textStyle?: StyleProp<TextStyle>;
    withMargin?: boolean;
}

const MintPriceBackground = ({
    rank,
    customStyle,
    iconSize = 20,
    textStyle,
    withMargin = true,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { petMintProducts } = useStorePurchase();

    const mintPriceUsdText = React.useMemo(() => {
        if (rank?.payments.length === 1 && rank.payments[0].productId) {
            return petMintProducts?.find(
                (s) => s.identifier === rank.payments[0].productId
            )?.priceString;
        }

        return Number(rank?.mintPriceUsd)
            ? `$${Number(rank?.mintPriceUsd).toFixed(2)}`
            : i18n.t('mintPetRank.free');
    }, [rank, petMintProducts]);

    return (
        <>
            <View
                style={[
                    styles.mintPriceContainer,
                    withMargin && styles.margin,
                    customStyle,
                ]}>
                <Text style={[styles.mintPrice, textStyle]}>
                    {mintPriceUsdText}
                </Text>

                <View style={styles.mintPriceImage}>
                    <MintPetDollarIcon width={iconSize} height={iconSize} />
                </View>
            </View>
        </>
    );
};
export default MintPriceBackground;
