import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '../../../helpers/dimensions';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
        },
        flatListWrapper: {
            paddingHorizontal: scale(20),
            flex: 1,
        },
        headerText: {
            paddingHorizontal: scale(0),
        },
        gap: {
            marginTop: verticalScale(16),
        },
        form: {
            paddingHorizontal: scale(20),
        },
        buttonWrapper: {
            paddingHorizontal: scale(20),
            paddingTop: verticalScale(16),
        },
        columnWrapperStyle: {
            justifyContent: 'space-between',
        },
    });

export default styles;
