import aesjs from 'aes-js';
import { Buffer } from 'buffer/';
import * as Crypto from 'expo-crypto';

const useNftRoyaleGameEventInputData = () => {
    const getEncryptionKey = async (key: string) => {
        const digest = await Crypto.digestStringAsync(
            Crypto.CryptoDigestAlgorithm.SHA512,
            key
        );
        return aesjs.utils.utf8.toBytes(digest.substring(0, 32));
    };

    const getEncryptionIV = async (iv: string) => {
        const digest = await Crypto.digestStringAsync(
            Crypto.CryptoDigestAlgorithm.SHA512,
            iv
        );
        return aesjs.utils.utf8.toBytes(digest.substring(0, 16));
    };

    const getSignature = (key: Uint8Array, iv: Uint8Array, data: string) => {
        const aesCtr = new aesjs.ModeOfOperation.cbc(key, iv);

        const encrypt = aesCtr.encrypt(
            aesjs.padding.pkcs7.pad(aesjs.utils.utf8.toBytes(data))
        );

        const encryptHex = aesjs.utils.hex.fromBytes(encrypt);

        return new Buffer(encryptHex).toString('base64');
    };

    return { getEncryptionIV, getEncryptionKey, getSignature };
};

export default useNftRoyaleGameEventInputData;
