import { GET_BREEDING_PETS_PAIR_STATS } from '@Data/Requests';
import { useLazyQuery } from '@apollo/client';
import { BreedingPetsPairStatsOutputResponse } from '@models/mysteryGames';

export const useBreedingPetsPairStats = () => {
    const [
        getBreedingPetsPairStats,
        {
            data: breedingPetsPairStatsData,
            loading: breedingPetsPairStatsLoading,
        },
    ] = useLazyQuery<BreedingPetsPairStatsOutputResponse>(
        GET_BREEDING_PETS_PAIR_STATS,
        {
            fetchPolicy: 'network-only',
        }
    );

    return {
        breedingPetsPairStatsData,
        getBreedingPetsPairStats,
        breedingPetsPairStatsLoading,
    };
};
