import * as React from 'react';
import { useCallback } from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import Button from '@components/Button';
import { ICON_NAMES } from '@components/Icons';
import CoinIcon from '@components/Icons/CoinIcon';
import GamesNavigationIcon from '@components/Icons/GamesNavigationIcon';
import { Text, View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT, TYPO_TEXT_COLOR } from '@components/TypoText/types';
import { allGamesData, useMysteryGames } from '@contexts/MysteryGamesContext';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { verticalScale } from '@helpers/dimensions';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { useFocusEffect } from '@react-navigation/native';

import stylesMain from './styles';

interface IProps {
    type: MYSTERY_GAMES;
    containerStyle?: StyleProp<ViewStyle>;
    backIconName?: ICON_NAMES;
    handleOnInfoIconPress: () => void;
    buttonTitle?: string;
    onPress?: () => void;
    loading?: boolean;
    isOpacityBg?: boolean;
    buttonComponent?: React.ReactElement;
    disabled?: boolean;
}
const GamesNavigation = ({
    type,
    containerStyle,
    backIconName = ICON_NAMES.ARROW_LEFT,
    handleOnInfoIconPress,
    onPress,
    loading,
    buttonTitle,
    isOpacityBg,
    buttonComponent,
    disabled,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { walletBalance } = useWallet();
    const navigation = useNavigation();
    const { top } = useSafeAreaInsets();

    const { availableBalance, getGamingAvailableBalance } = useMysteryGames();
    useFocusEffect(
        useCallback(() => {
            getGamingAvailableBalance(false);
        }, [])
    );

    const handleOnBack = () => {
        navigation.goBack();
    };

    const handleOnWallet = () => {
        navigation.navigate(ROUTES.WALLET_ROOT, {
            screen: ROUTES.WALLET_GAMING_TAB,
        });
    };

    const backgroundColor = React.useMemo(
        () => allGamesData.find((i) => i.id === type)?.color,
        [type, allGamesData]
    );

    return (
        <View style={[styles.container, { backgroundColor }, containerStyle]}>
            <View
                style={[
                    styles.additionalBackGround,
                    isOpacityBg && styles.opacityBg,
                ]}>
                <View
                    style={[
                        styles.walletBlock,
                        { paddingTop: verticalScale(top + 16) },
                    ]}>
                    <View style={styles.innerContainer}>
                        <View style={styles.navigationBlock}>
                            <View style={styles.buttonWrapper}>
                                <Button
                                    disabled={disabled}
                                    isOneColor
                                    size="md"
                                    type="outline"
                                    iconName={backIconName}
                                    style={styles.singleButton}
                                    debouncedPress
                                    onPress={handleOnBack}
                                />
                            </View>
                        </View>
                        <View style={styles.navigationBlock}>
                            <View style={styles.coinsWrapper}>
                                <Button
                                    disabled={disabled}
                                    isOneColor
                                    size="md"
                                    type="outline"
                                    style={styles.itemPadding}
                                    onPress={handleOnWallet}>
                                    <View style={styles.coinWrapper}>
                                        <View
                                            style={[
                                                styles.item,
                                                styles.itemGap,
                                            ]}>
                                            <CoinIcon
                                                slug={Coin.bnb}
                                                size={styles.icon.width}
                                            />
                                            <Text style={[styles.text]}>
                                                {walletBalance?.bnb
                                                    .presentationValueShort ??
                                                    '-'}
                                            </Text>
                                        </View>
                                        <View
                                            style={[
                                                styles.item,
                                                styles.itemGap,
                                            ]}>
                                            <CoinIcon
                                                slug={Coin.igu}
                                                size={styles.icon.width}
                                            />
                                            <Text style={[styles.text]}>
                                                {walletBalance?.igu
                                                    .presentationValueShort ??
                                                    '-'}
                                            </Text>
                                        </View>
                                        <View style={styles.item}>
                                            <CoinIcon
                                                slug={Coin.vigu}
                                                size={styles.icon.width}
                                            />
                                            <Text style={[styles.text]}>
                                                {balanceFromWei(
                                                    availableBalance?.available
                                                ).valueShort ?? '-'}
                                            </Text>
                                        </View>
                                    </View>
                                </Button>
                            </View>
                        </View>
                    </View>
                </View>
                <View style={styles.extendedBlock}>
                    <View style={styles.headerTextBlock}>
                        <TypoText
                            allowFontScaling={false}
                            type={TYPO_TEXT.HEADING_H3}
                            colorType={TYPO_TEXT_COLOR.ON_DARK}
                            numberOfLines={2}
                            additionalStyles={styles.textGap}>
                            {i18n.t(
                                `HomeScreen.GamesBlock.games.${type}.title`
                            )}
                        </TypoText>
                        <TypoText
                            allowFontScaling={false}
                            type={TYPO_TEXT.BODY_BIG}
                            numberOfLines={2}
                            colorType={TYPO_TEXT_COLOR.ON_DARK}>
                            {i18n.t(`HomeScreen.GamesBlock.games.${type}.text`)}
                        </TypoText>
                    </View>
                    <View style={styles.buttonBlock}>
                        {!!buttonComponent && buttonComponent}
                        {!!onPress && (
                            <Button
                                isOneColor
                                onPress={onPress}
                                containerStyle={styles.button}
                                debouncedPress
                                type="outline"
                                title={buttonTitle}
                                disabled={loading}
                            />
                        )}
                        <Button
                            disabled={disabled}
                            onPress={handleOnInfoIconPress}
                            type={'text'}
                            title={i18n.t('general.howItWorks')}
                            iconName={ICON_NAMES.QUESTION}
                            iconSize={24}
                            containerStyle={styles.howItWorks}
                            iconDirection={'right'}
                            textStyles={styles.howItWorksText}
                            debouncedPress
                            iconColor={styles.howItWorksText.color}
                        />
                    </View>
                </View>
                <View style={[styles.flowIconBlock, styles.iconLeft]}>
                    <GamesNavigationIcon slug={type} type="left" />
                </View>
                <View style={[styles.flowIconBlock, styles.iconRight]}>
                    <GamesNavigationIcon slug={type} type="right" />
                </View>
            </View>
        </View>
    );
};

export default GamesNavigation;
