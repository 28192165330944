import * as React from 'react';

import { useMutation } from '@apollo/client';

import {
    CreateMysteryBoxResponse,
    PaymentOptionOutput,
} from '../../../../Data/Models';
import { CREATE_MYSTERY_BOX } from '../../../../Data/Requests';
import CheckoutScreen from '../../../../components/CheckoutScreen';
import StorePurchase from '../../../../components/CheckoutScreen/StorePurchase/StorePurchase';
import { useStorePurchase } from '../../../../contexts/StorePurchaseContext';
import {
    ITransactionResult,
    useWallet,
} from '../../../../contexts/Wallet/WalletContext';
import { waitForNavigation } from '../../../../helpers/helpers';
import useMysteryBoxList from '../../../../hooks/mysteryBoxes/useMysteryBoxList';
import useMysteryBoxPurchase from '../../../../hooks/mysteryBoxes/useMysteryBoxPurchase';
import i18n from '../../../../i18n/i18n';
import NavigationBar from '../../../../navigation/NavigationBar';
import useNavigationListeners from '../../../../navigation/hooks/useNavigationListeners';
import ROUTES from '../../../../navigation/routes';
import { useNavigation } from '../../../../navigation/useNavigation';
import { MysteryBoxPurchaseCheckoutProps } from '../../../../types';
import TransactionDetailsPurchaseBox from '../components/TransactionDetailsPurchaseBox';

const MysteryBoxPurchaseCheckoutScreen = ({
    route,
}: MysteryBoxPurchaseCheckoutProps) => {
    useNavigationListeners();
    const { selectedOption, allAvailableOptions } = route.params;
    const { purchaseMysteryBox } = useMysteryBoxPurchase();
    const {
        isStoreLoading,
        isStoreError,
        storeErrorType,
        storeLoadingType,
        storeErrorCode,
        hideModal,
    } = useStorePurchase();
    const quantity = selectedOption.count;
    const navigation = useNavigation();

    const { executeTransaction } = useWallet();
    const [createMysteryBox] =
        useMutation<CreateMysteryBoxResponse>(CREATE_MYSTERY_BOX);

    const handleOnSuccess = async (
        payment: PaymentOptionOutput,
        result: ITransactionResult | null
    ) => {
        await waitForNavigation();

        navigation.popToTop();
        navigation.navigate(ROUTES.MYSTERY_BOX_PURCHASE_SUCCESS, {
            transactionResult: result,
            payment,
            amount: quantity,
        });
    };

    const purchaseMysteryBoxCall = async (token: string, count: number) => {
        const response = await createMysteryBox({
            variables: {
                createLootboxInput: {
                    token,
                    count,
                },
            },
        });
        if (response.data) {
            const { payment, signedTransaction } = response.data.createLootbox;
            const result = await executeTransaction(
                payment,
                signedTransaction,
                false,
                true
            );
            return result;
        }
        return null;
    };

    const handleOnPurchase = async (
        payment: PaymentOptionOutput
    ): Promise<ITransactionResult | null> => {
        if (payment.productId) {
            const result = await purchaseMysteryBox(payment.productId);
            if (result) {
                await handleOnSuccess(payment, null);
            }
        } else {
            const result = await purchaseMysteryBoxCall(
                payment.token,
                quantity
            );
            await handleOnSuccess(payment, result);
        }

        return null;
    };

    const handleNavigateBack = () => {
        navigation.pop();
        navigation.navigate(ROUTES.MYSTERY_BOX_PURCHASE, {
            selectedOption: selectedOption,
            allAvailableOptions: allAvailableOptions,
        });
    };

    return (
        <>
            <NavigationBar onBack={handleNavigateBack} backIcon filled wallet />
            <CheckoutScreen
                title={i18n.t('mysteryBoxes.purchaseCheckout.title')}
                text={i18n.t('mysteryBoxes.purchaseCheckout.text')}
                payments={selectedOption.payments}
                TransactionDetailsComponent={
                    <TransactionDetailsPurchaseBox amount={quantity} />
                }
                withConfirmation={i18n.t(
                    'mysteryBoxes.purchaseCheckout.transactionDetails.transaction',
                    {
                        amount: quantity,
                    }
                )}
                onPurchase={handleOnPurchase}
            />
            <StorePurchase
                isStoreError={isStoreError}
                isStoreLoading={isStoreLoading}
                errorType={storeErrorType}
                loadingType={storeLoadingType}
                errorCode={storeErrorCode}
                action={hideModal}
            />
        </>
    );
};

export default MysteryBoxPurchaseCheckoutScreen;
