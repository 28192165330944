import * as React from 'react';

import Off from '@assets/icons/emptyState/maintenanceOff.svg';
import On from '@assets/icons/emptyState/maintenanceOn.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import CustomModal from '@components/CustomModal';
import ImageIguana from '@components/ImageIguana';
import { View } from '@components/Themed';
import { useAppState } from '@contexts/AppStateContext';
import { errorsHandler } from '@helpers/errors';
import { waitForModal } from '@helpers/helpers';
import { toastSuccess } from '@helpers/toastNotification';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';

import stylesMain from './styles';

const AdminMaintenanceModeScreen = () => {
    const styles = useThemedStyles(stylesMain);

    const { isFullMaintenance } = useAppState();

    const maintenanceStatus = React.useMemo(
        () => (isFullMaintenance ? 'off' : 'on'),
        [isFullMaintenance]
    );

    const icon = React.useMemo(
        () => (isFullMaintenance ? <Off /> : <On />),
        [isFullMaintenance]
    );

    const buttonType = React.useMemo(
        () => (isFullMaintenance ? 'fill' : 'critical'),
        [isFullMaintenance]
    );

    // const [toggleMaintenanceMode, { loading }] = useMutation(TOGGLE_MAINTENANCE_MODE);

    const { isVisible, close, open } = useVisibleHook();

    const onPress = React.useCallback(async () => {
        try {
            // await toggleMaintenanceMode();
            close();
            await waitForModal();
            toastSuccess(
                i18n.t(
                    `profile.adminMaintenanceMode.${maintenanceStatus}.toastSuccess`
                )
            );
        } catch (error) {
            close();
            await waitForModal();
            errorsHandler(error, true);
        }
    }, [isFullMaintenance, maintenanceStatus]);

    return (
        <View style={styles.mainContainer}>
            <NavigationBar
                backIcon
                filled={false}
                title={i18n.t('profile.adminMaintenanceMode.screenTitle')}
            />

            <View style={styles.content}>
                <BlockHeader
                    emptyStateIcon={icon}
                    containerStyle={styles.paddingHorizontal}
                    type="emptyState"
                    title={i18n.t(
                        `profile.adminMaintenanceMode.${maintenanceStatus}.title`
                    )}
                    text={i18n.t(
                        `profile.adminMaintenanceMode.${maintenanceStatus}.text`
                    )}
                />
                <ButtonsBottom
                    containerStyle={styles.buttons}
                    title={i18n.t(
                        `profile.adminMaintenanceMode.${maintenanceStatus}.button`
                    )}
                    buttonType={buttonType}
                    // disabled={loading}
                    onPress={open}
                />
            </View>

            <CustomModal
                isVisible={isVisible}
                icon={<ImageIguana type="attention" />}
                titleText={i18n.t(
                    `profile.adminMaintenanceMode.${maintenanceStatus}.modal.title`
                )}
                infoText={i18n.t(
                    `profile.adminMaintenanceMode.${maintenanceStatus}.modal.text`
                )}
                firstButtonText={i18n.t(
                    `profile.adminMaintenanceMode.${maintenanceStatus}.modal.button1`
                )}
                secondButtonText={i18n.t(
                    `profile.adminMaintenanceMode.${maintenanceStatus}.modal.button2`
                )}
                // firstButtonLoading={loading}
                // firstButtonDisabled={loading}
                firstButtonType={buttonType}
                onFirstButtonClick={onPress}
                onSecondButtonClick={close}
            />
        </View>
    );
};

export default AdminMaintenanceModeScreen;
