import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import { openUrlOrShowError } from '@helpers/linking';
import { useHaptic } from '@hooks/useHaptic';

import Icon, { ICON_NAMES } from '../../../../../components/Icons';
import FlagIcons from '../../../../../components/Icons/FlagIcons';
import SocialIcons from '../../../../../components/Icons/SocialIcons';
import { Text, View } from '../../../../../components/Themed';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import { ISocialMedia } from '../../socials';
import stylesMain from './styles';

type SocialType = 'communities' | 'media' | 'telegram';

interface IProps {
    item: ISocialMedia;
    type: SocialType;
    onTelegramPress?: () => void;
}

const LinkItem = ({ item, type, onTelegramPress }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { triggerMedium } = useHaptic();
    const { id, url } = item;
    const isTelegram = React.useMemo(() => id === 'telegram', [id]);
    const isTelegramType = React.useMemo(() => type === 'telegram', [type]);

    const onPress = async () => {
        triggerMedium();

        if (isTelegram) {
            return onTelegramPress?.();
        }

        return openUrlOrShowError(url);
    };

    return (
        <TouchableOpacity
            style={styles.shadow}
            onPress={onPress}
            activeOpacity={0.8}>
            <View style={styles.itemContainer}>
                <View style={styles.right}>
                    <View style={styles.icon}>
                        {isTelegramType ? (
                            id === 'main' ? (
                                <SocialIcons slug="telegram" size={24} />
                            ) : (
                                <FlagIcons slug={id} size={24} />
                            )
                        ) : (
                            <SocialIcons slug={id} size={24} />
                        )}
                    </View>
                    <Text style={styles.title}>
                        {isTelegramType
                            ? i18n.t(
                                  `profile.socialMediaSettings.${type}.${id}`
                              )
                            : i18n.t(
                                  `profile.socialMediaSettings.${type}.socials.${id}`
                              )}
                    </Text>
                </View>
                <View style={styles.arrow}>
                    {isTelegram ? (
                        <Icon
                            name={ICON_NAMES.ARROW_RIGHT_SECONDARY}
                            color={styles.icon.color}
                            size={20}
                        />
                    ) : (
                        <Icon
                            name={ICON_NAMES.EXPLORE}
                            color={styles.icon.color}
                            size={20}
                        />
                    )}
                </View>
            </View>
        </TouchableOpacity>
    );
};

export default LinkItem;
