import { StyleSheet } from 'react-native';

import Colors from '../../../constants/Colors';
import { isWeb } from '../../../helpers/app';
import { scale, verticalScale } from '../../../helpers/dimensions';
import { stylesWebOutlines } from '../../../helpers/style';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
            backgroundColor: Colors[theme].background,
        },
        bottomBar: {
            backgroundColor: 'transparent',
        },
        btnCircleContainer: {
            width: 60,
            height: 60,
            borderRadius: 30,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: Colors[theme].shadow.default,
            bottom: 18,
            paddingBottom: verticalScale(4),
            paddingTop: scale(1),
            paddingLeft: scale(1),
            paddingRight: scale(1),
            marginTop: isWeb ? 10 : 0,
        },
        btnCircleContainerActive: {
            backgroundColor: Colors[theme].text.accent,
        },
        btnCircle: {
            flex: 1,
            borderRadius: 30,
            width: '98.5%',
            height: '100%',
            alignItems: 'center',
            alignSelf: 'center',
            justifyContent: 'center',
            backgroundColor: Colors[theme].background,
            ...stylesWebOutlines(),
        },
        btnCircleActive: {
            backgroundColor: Colors[theme].text.accent,
        },
        routeLink: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: !isWeb ? 10 : 0,
            paddingTop: isWeb ? 10 : 0,
            ...stylesWebOutlines(),
        },

        headerBg: {
            color: Colors[theme].navigationBar.accent,
        },
    });

export default styles;
