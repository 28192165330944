import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { scale, verticalScale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            height: 'auto',
            minHeight: verticalScale(50),
            backgroundColor: Colors[theme].background,
        },
        content: {
            height: 'auto',
            paddingVertical: verticalScale(5),
            minHeight: verticalScale(50),
        },
        text1: {
            ...font({
                size: 15,
                weight: '700',
            }),
            color: Colors[theme].text.primary,
        },
        text2: {
            ...font({
                size: 12,
            }),
            color: Colors[theme].text.secondary,
        },
        success: {
            borderLeftColor: Colors[theme].text.accent,
        },
        error: {
            borderLeftColor: Colors[theme].text.critical,
        },
        warning: {
            borderLeftColor: Colors[theme].text.warning,
        },
        info: {
            borderLeftColor: Colors[theme].text.secondary,
        },
    });

export default styles;
