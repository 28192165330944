import * as React from 'react';

import { useDimensions } from '../../contexts/DimensionsContext';
import useThemedStyles from '../../hooks/useThemedStyles';
import i18n from '../../i18n/i18n';
import EnergyBlock from '../../screens/TabBar/Play/components/EnergyBlock';
import Carousel from '../Carousel';
import { Text, View } from '../Themed';
import { IImagesData, ImagesDataType } from './data';
import stylesMain from './styles';

interface IProps {
    data: ImagesDataType;
    customEnergy?: number | string;
    paddingEnabled?: boolean;
}

const ImageSlider = ({ data, customEnergy }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { windowWidth } = useDimensions();

    const renderItem = React.useCallback(
        (item: IImagesData) => {
            const energy = customEnergy || item.energy;

            return (
                <View style={styles.container}>
                    <View style={styles.imgWrapper}>{item.icon}</View>
                    <Text style={styles.title}>{i18n.t(item.title)}</Text>
                    <Text style={styles.description}>{i18n.t(item.text)}</Text>
                    <View style={styles.energyWrapper}>
                        {energy && (
                            <EnergyBlock
                                energy={energy}
                                text={i18n.t(item.energyText)}
                            />
                        )}
                        {customEnergy === '' && (
                            <EnergyBlock text={i18n.t(item.energyText)} />
                        )}
                    </View>
                </View>
            );
        },
        [windowWidth]
    );

    return (
        <View style={{ flex: 1 }}>
            <Carousel
                data={data}
                sliderWidth={windowWidth}
                itemWidth={windowWidth}
                renderItem={(item) => renderItem(item)}
                fullHeight
                pagination
                centredVertical
            />
        </View>
    );
};

export default ImageSlider;
