import { GET_BREEDING_RANKS_STATS } from '@Data/Requests';
import { useLazyQuery } from '@apollo/client';
import { BreedingRanksStatsOutputResponse } from '@models/mysteryGames';

export const useBreedingRanksStats = () => {
    const [
        getBreedingRanksStats,
        { data: breedingRanksStatsData, loading: breedingRanksStatsLoading },
    ] = useLazyQuery<BreedingRanksStatsOutputResponse>(
        GET_BREEDING_RANKS_STATS,
        {
            fetchPolicy: 'network-only',
        }
    );

    return {
        breedingRanksStatsLoading,
        getBreedingRanksStats,
        breedingRanksStatsData,
    };
};
