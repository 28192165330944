if (!window.localStorage) {
    setTimeout(() => {
        alert(
            'Your browser does not support localStorage, switching to in-memory storage. All saved data will be lost on refresh'
        );
    }, 5000);

    const localStorage = {
        _data: {},
        setItem: function (id, val) {
            return (this._data[id] = String(val));
        },
        getItem: function (id) {
            return this._data.hasOwnProperty(id) ? this._data[id] : undefined;
        },
        removeItem: function (id) {
            return delete this._data[id];
        },
        clear: function () {
            return (this._data = {});
        },
    };

    Object.defineProperty(window, 'localStorage', {
        value: localStorage,
        writable: true,
    });
}
