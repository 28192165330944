import * as React from 'react';
import { useState } from 'react';
import { SafeAreaView } from 'react-native';
import BackgroundGeolocation, {
    State,
} from 'react-native-background-geolocation';

import * as Location from 'expo-location';
import { Pedometer } from 'expo-sensors';

import Button from '@components/Button';
import CustomModal from '@components/CustomModal';
import ImageIguana from '@components/ImageIguana';
import ImageSlider from '@components/ImageSlider';
import { moveToEarn } from '@components/ImageSlider/data';
import { View } from '@components/Themed';
import Permissions from '@constants/Permissions';
import { PlayContext } from '@contexts/PlayContext';
import { isDesktop, isIOS, isRunningInExpo, isWeb } from '@helpers/app';
import { wait } from '@helpers/helpers';
import { toastNotAllowedInExpo } from '@helpers/toastNotification';
import { useHandlePermission } from '@hooks/useHandlePermission';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import stylesMain from './styles';

export const WelcomeScreen = () => {
    const [isVisibleInformUserModal, setIsVisibleInformUserModal] =
        useState<boolean>(false);
    const [isVisibleNotSupportedModal, setIsVisibleNotSupportedModal] =
        useState<boolean>(false);

    const navigation = useNavigation();
    const styles = useThemedStyles(stylesMain);
    const { moveTask } = React.useContext(PlayContext);
    const {
        openModalNoPermissionsGlobalWeb,
        openModalNoPermissionsGlobal,
        renderItem: renderLocationPermissions,
        setIsGlobalLocationSetting,
    } = useHandlePermission(Permissions.LOCATION);

    const onBack = () => {
        navigation.navigateToTasks();
    };

    const handleWebPermissionRequests = async (informUser = true) => {
        if (isDesktop) {
            return setIsVisibleNotSupportedModal(true);
        }
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                () => {
                    navigation.replace(ROUTES.MOVE_TO_EARN_PROGRESS, {
                        permissions: {
                            pedometer: true,
                        },
                    });
                },
                (error) => {
                    return openModalNoPermissionsGlobalWeb();
                }
            );
        } else {
            return setIsVisibleNotSupportedModal(true);
        }
    };

    const [permissionGetting, setPermissionGetting] =
        React.useState<boolean>(false);
    const initBackgroundGeolocation = async () => {
        const state: State = await BackgroundGeolocation.ready({
            desiredAccuracy: BackgroundGeolocation.DESIRED_ACCURACY_NAVIGATION,
            stopAfterElapsedMinutes: 20,
            disableLocationAuthorizationAlert: true,
            locationAuthorizationRequest: 'WhenInUse',
            stationaryRadius: 0,
            showsBackgroundLocationIndicator: true,
            locationUpdateInterval: 1000,
            allowIdenticalLocations: true, //
            stopTimeout: 15,
            foregroundService: true,
            isMoving: true,
            speedJumpFilter: 100,
        });
    };

    const handleMobilePermissionRequests = async (informUser = true) => {
        // TODO: check order
        if (isRunningInExpo()) {
            toastNotAllowedInExpo();
            navigation.pop();
            return false;
        }
        setPermissionGetting(true);
        await initBackgroundGeolocation();

        const result = await Location.hasServicesEnabledAsync();
        if (!result) {
            setPermissionGetting(false);
            setIsGlobalLocationSetting(true);
            return openModalNoPermissionsGlobal();
        }

        try {
            const status = await BackgroundGeolocation.requestPermission();
            if (status === 2) {
                setPermissionGetting(false);
                return openModalNoPermissionsGlobal();
            }
            let pedometer = true;
            if (isIOS) {
                const { granted: pedometerPermission } =
                    await Pedometer.requestPermissionsAsync();
                pedometer = pedometerPermission;
            }
            setPermissionGetting(false);
            return navigation.replace(ROUTES.MOVE_TO_EARN_PROGRESS, {
                permissions: {
                    pedometer,
                },
            });
        } catch (status) {
            setPermissionGetting(false);
            if (status === 2) {
                return openModalNoPermissionsGlobal();
            }
        }
    };

    const handlePermissionRequests = async (informUser = true) => {
        if (isWeb) {
            return handleWebPermissionRequests(informUser);
        }

        return handleMobilePermissionRequests(informUser);
    };

    const renderInformUser = () => (
        <CustomModal
            isVisible={isVisibleInformUserModal}
            titleText=""
            icon={<ImageIguana type="attention" />}
            infoText={i18n.t('moveToEarn.modals.permissionsInfoText')}
            firstButtonText={i18n.t('moveToEarn.modals.permissionsInfoBtn1')}
            secondButtonText={i18n.t('moveToEarn.modals.permissionsInfoBtn2')}
            onFirstButtonClick={async () => {
                setIsVisibleInformUserModal(false);
                wait(500).then(() => handlePermissionRequests(false));
            }}
            onSecondButtonClick={() => {
                setIsVisibleInformUserModal(false);
            }}
        />
    );

    const renderNotSupported = () => (
        <CustomModal
            isVisible={isVisibleNotSupportedModal}
            titleText={i18n.t('moveToEarn.notSupported.title')}
            icon={<ImageIguana type="cry" />}
            infoText={i18n.t('moveToEarn.notSupported.text')}
            firstButtonText={i18n.t('moveToEarn.notSupported.button')}
            onFirstButtonClick={() => {
                setIsVisibleNotSupportedModal(false);
            }}
        />
    );

    return (
        <>
            <NavigationBar
                onBack={onBack}
                backIcon="close"
                containerStyle={styles.navBg}
            />
            <ImageSlider
                data={moveToEarn}
                customEnergy={moveTask?.maxEnergyReward}
            />
            <View style={styles.buttonWrapper}>
                <Button
                    disabled={permissionGetting}
                    onPress={handlePermissionRequests}
                    title={i18n.t('moveToEarn.startWalking')}
                />
            </View>

            {renderLocationPermissions(handlePermissionRequests)}
            {renderInformUser()}
            {renderNotSupported()}
            <SafeAreaView style={styles.safe} />
        </>
    );
};

export default WelcomeScreen;
