import * as React from 'react';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import MarketPlaceOverviewScreen from '../../screens/TabBar/Marketplace/MarketPlaceOverview/MarketPlaceOverviewScreen';
import MarketPlaceOverviewOwnerHistoryScreen from '../../screens/TabBar/Marketplace/MarketPlaceOverviewOwnerHistory/MarketPlaceOverviewOwnerHistoryScreen';
import { MarketPlaceOverviewParamList } from '../../types';
import ROUTES from '../routes';

const MarketPlaceTabsStack =
    createBottomTabNavigator<MarketPlaceOverviewParamList>();

export const MarketPlaceOverviewTabsNavigator = () => {
    return (
        <MarketPlaceTabsStack.Navigator
            screenOptions={{
                headerShown: false,
            }}
            initialRouteName={ROUTES.MARKETPLACE_OVERVIEW}
            backBehavior="none"
            tabBar={() => null}>
            <MarketPlaceTabsStack.Screen
                name={ROUTES.MARKETPLACE_OVERVIEW}
                component={MarketPlaceOverviewScreen}
            />
            <MarketPlaceTabsStack.Screen
                name={ROUTES.MARKETPLACE_OVERVIEW_OWNER_HISTORY}
                component={MarketPlaceOverviewOwnerHistoryScreen}
            />
        </MarketPlaceTabsStack.Navigator>
    );
};
