import React, { useCallback } from 'react';

import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import SocialProofItem from '../screens/TabBar/Home/components/SocialProofItem';
import { useHaptic } from './useHaptic';
import { useMysteryLikesGameLastGame } from './useMysteryLikesGameLastGame';

const useSocialProof = () => {
    const { MysteryLikesGameLastData } = useMysteryLikesGameLastGame();
    const navigation = useNavigation();
    const { triggerHeavy } = useHaptic();
    const onPress = () => {
        triggerHeavy();
        navigation.navigate(ROUTES.WELCOME_HOW_MYSTERY_LIKES_WORK);
    };

    const renderSocialProof = useCallback(() => {
        return (
            <SocialProofItem
                item={MysteryLikesGameLastData?.mysteryLikesGameLastGame}
                onPress={onPress}
            />
        );
    }, [MysteryLikesGameLastData]);

    return {
        renderSocialProof,
    };
};

export default useSocialProof;
