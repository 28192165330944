import { StyleSheet } from 'react-native';

import { scale } from '../../../helpers/dimensions';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        textContainer: {
            flex: 1,
        },
        subtitleMargin: {
            marginTop: scale(24),
            marginBottom: scale(24),
        },
    });

export default styles;
