import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import Colors from '../../../constants/Colors';
import font from '../../../helpers/font';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        coinWrapper: {
            backgroundColor: 'transparent',
            flexDirection: 'row',
        },
        item: {
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: 'transparent',
        },
        text: {
            color: Colors[theme].text.primary,
            ...font({
                size: 12,
                height: 16,
                weight: '600',
            }),
            marginLeft: scale(4),
        },
        itemGap: {
            marginRight: scale(8),
        },
        itemPadding: {
            paddingHorizontal: scale(8),
            paddingVertical: verticalScale(6),
        },
        energyWrapper: {
            backgroundColor: 'transparent',
            flexDirection: 'row',
        },
        gap: {
            marginRight: scale(6),
        },
        coinsWrapper: {
            backgroundColor: 'transparent',
            flexDirection: 'row',
            flex: 1,
        },
        icon: {
            width: scale(20),
            height: scale(20),
        },
        energyThresholdReached: {
            color: Colors[theme].text.accent,
        },
        isOneColorText: {
            color: Colors[theme].alwaysOneColor.text,
        },
    });

export default styles;
