import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { MAX_WINDOW_WIDTH } from '@contexts/DimensionsContext';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        modal: {
            width: '100%',
            margin: 0,
            maxWidth: MAX_WINDOW_WIDTH,
            alignSelf: 'center',
            padding: scale(10),
        },
        shadow: {
            borderRadius: moderateScale(20),
            backgroundColor: Colors[theme].modal.shadowColor,
        },
        container: {
            width: '100%',
            backgroundColor: Colors[theme].modal.background,
            borderRadius: moderateScale(20),
            paddingHorizontal: scale(16),
            paddingTop: verticalScale(40),
            paddingBottom: verticalScale(30),
            marginBottom: verticalScale(4),
            alignSelf: 'center',
        },
        iconWrapper: {
            marginBottom: verticalScale(20),
            alignItems: 'center',
        },
        buttonWrapper: {
            marginTop: verticalScale(20),
            // alignContent: 'center',
            // alignItems: 'center',
            // justifyContent: 'center',
        },
        title: {
            textAlign: 'center',
            marginBottom: verticalScale(6),
            color: Colors[theme].text.primary,
            ...font({
                size: 18,
                height: 26,
                weight: '700',
            }),
        },
        text: {
            textAlign: 'center',
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
        buttonLeave: {
            alignSelf: 'center',
            color: Colors[theme].text.secondary,
        },
        buttonGap: {
            height: verticalScale(46),
        },
        buttonContainer: {
            marginTop: verticalScale(8),
            alignSelf: 'auto',
        },
        alert: {
            marginVertical: verticalScale(6),
        },
        checkbox: {
            marginVertical: verticalScale(6),
        },
    });

export default styles;
