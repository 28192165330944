import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';
import { stylesModalNavigationFix } from '@helpers/style';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        itemContainer: {
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            paddingHorizontal: scale(20),
            backgroundColor: 'transparent',
        },

        buttonWrapper: {
            marginTop: verticalScale(24),
            paddingHorizontal: scale(20),
            backgroundColor: 'transparent',
        },
        bulletActive: {
            backgroundColor: Colors[theme].text.onLight,
        },
        button: {
            backgroundColor: 'transparent',
            paddingHorizontal: 0,
        },
        content: {
            backgroundColor: 'transparent',
        },
        image: {
            backgroundColor: 'transparent',
            alignItems: 'center',
            marginBottom: verticalScale(20),
        },
        textBlock: {
            backgroundColor: 'transparent',
        },

        title: {
            textAlign: 'center',
            color: Colors[theme].text.onLight,
            ...font({
                size: 22,
                height: 30,
                weight: '800',
            }),
            marginBottom: verticalScale(8),
        },
        text: {
            textAlign: 'center',
            color: Colors[theme].text.onLight,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
        divider: {
            backgroundColor: Colors[theme].alwaysOneColor.background,
            marginVertical: verticalScale(24),
        },

        bulletStyle: { backgroundColor: Colors[theme].bulletOnColor },
    });

export default styles;
