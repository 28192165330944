import * as React from 'react';

import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import ROUTES from '../../routes';
import { useNavigation } from '../../useNavigation';
import NavigationItem from '../NavigationItem';
import stylesMain from './styles';

interface IProps {
    currentNavigation:
        | ROUTES.LEADERBOARD_DAILY_TASKS
        | ROUTES.LEADERBOARD_PVP_GAMES;
}

const NavigationLeaderboard = ({ currentNavigation }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const handleTask = () => {
        if (currentNavigation !== ROUTES.LEADERBOARD_DAILY_TASKS) {
            navigation.navigate(ROUTES.LEADERBOARD_DAILY_TASKS);
        }
    };

    const handlePvp = () => {
        if (currentNavigation !== ROUTES.LEADERBOARD_PVP_GAMES) {
            navigation.navigate(ROUTES.LEADERBOARD_PVP_GAMES);
        }
    };

    return (
        <View style={styles.shadow}>
            <View style={styles.container}>
                <NavigationItem
                    onPress={handleTask}
                    title={i18n.t(
                        `Leaderboard.${ROUTES.LEADERBOARD_DAILY_TASKS}.title`
                    )}
                    isActive={
                        currentNavigation === ROUTES.LEADERBOARD_DAILY_TASKS
                    }
                    containerStyles={styles.containerItem}
                />
                <NavigationItem
                    onPress={handlePvp}
                    title={i18n.t(
                        `Leaderboard.${ROUTES.LEADERBOARD_PVP_GAMES}.title`
                    )}
                    isActive={
                        currentNavigation === ROUTES.LEADERBOARD_PVP_GAMES
                    }
                    containerStyles={styles.containerItem}
                />
            </View>
        </View>
    );
};
export default NavigationLeaderboard;
