import { StyleSheet } from 'react-native';

import Colors from '../../../../../../constants/Colors';
import { scale, verticalScale } from '../../../../../../helpers/dimensions';
import font from '../../../../../../helpers/font';
import { theme } from '../../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            padding: scale(25),
        },
        digitTxtStyle: {
            color: Colors[theme].text.primary,
            ...font({
                size: 40,
                height: 48,
                weight: '600',
            }),
        },
        timeLeftText: {
            marginTop: verticalScale(8),
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
        },
        digitStyle: {
            backgroundColor: Colors[theme].background,

            // setting size to undefined to avoid text being cut off when font is large
            width: undefined,
            height: undefined,
        },
        withCaptionsItem: {
            alignItems: 'center',
        },
        timeWrapper: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'transparent',
        },
        timeBlock: {
            backgroundColor: 'transparent',

            // alignContent: 'center',
            // alignItems: 'center',

            flexDirection: 'row',
        },
        captionDateStyle: {
            ...font({
                size: 40,
                height: 48,
                weight: '600',
            }),

            color: Colors[theme].text.primary,
        },
        captionTextStyle: {
            ...font({
                size: 13,
                height: 18,
                weight: '600',
            }),

            color: Colors[theme].text.secondary,
        },
        captionDividerStyle: {
            ...font({
                size: 40,
                height: 48,
                weight: '600',
            }),
            marginHorizontal: scale(4),

            color: Colors[theme].text.primary,
        },
        insideCircle: {
            transform: [{ scale: 0.92 }],
        },
        insideTimer: {
            position: 'absolute',
        },
    });

export default styles;
