import * as React from 'react';
import { Animated, Text, View } from 'react-native';

import { isWeb, useNativeDriver } from '../../../../helpers/app';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import Animation from '../../../Animation';
import LoadingComponent from '../../../LoadingComponent/index';
import mainStyles from './styles';

interface IProps {
    isRefreshing?: boolean;
    textLoading?: string;
    children?: React.ReactNode;
}

const DefaultHeaderComponent: React.FC<IProps> = ({
    isRefreshing,
    textLoading,
    children,
}) => {
    const styles = useThemedStyles(mainStyles);

    const [fadeAnim] = React.useState(new Animated.Value(0));
    React.useEffect(() => {
        if (isRefreshing) {
            Animated.timing(fadeAnim, {
                toValue: 1,
                duration: 100,
                useNativeDriver: useNativeDriver,
            }).start();
        } else {
            Animated.timing(fadeAnim, {
                toValue: 0,
                duration: 100,
                useNativeDriver: useNativeDriver,
            }).start();
        }
    }, [isRefreshing]);

    return (
        <View style={styles.container}>
            <Animated.View
                style={[styles.imageContainer, { opacity: fadeAnim }]}>
                <View style={styles.image}>
                    {isRefreshing && <LoadingComponent text={''} isPlaying />}
                </View>
                <Text style={styles.text}>{textLoading}</Text>
            </Animated.View>
            {children}
        </View>
    );
};

export default DefaultHeaderComponent;
