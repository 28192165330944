import React, { useCallback, useMemo } from 'react';

import { useAppLoading } from '@contexts/AppLoading/AppLoadingContext';
import { useConfig } from '@contexts/ConfigContext';
import { useModal } from '@contexts/ModalContext';
import { usePlay } from '@contexts/PlayContext';
import { useUser } from '@contexts/UserContext';
import { dateDiffInSeconds } from '@helpers/time';
import { useNavigation } from '@navigation/useNavigation';
import AsyncStorage from '@react-native-async-storage/async-storage';

import ROUTES from '../navigation/routes';

const hookConfig = {
    storageKey: 'useYourPetsHungry',
    showWhenTimeLeft: 12 * 60 * 60 * 1000,
};

const useYourPetsHungry = () => {
    const navigation = useNavigation();
    const { config } = useConfig();
    const { playablePets } = usePlay();
    const { user } = useUser();
    const { isSplashAnimationComplete } = useAppLoading();
    // if global modal is visible do not show this screen (prevent ios from freeze)
    const { isVisible: isGlobalModalVisible, setModalOpened } = useModal();

    const storageKey = useMemo(() => {
        if (!config) {
            return null;
        }
        return `${hookConfig.storageKey}-${user?.id}-${config.gameIterationUTCHour}`;
    }, [config, user]);

    const pets = React.useMemo(() => {
        return playablePets?.filter((pet) => pet.health < 100);
    }, [playablePets]);

    // check whether we can show Daily Rewards Screen
    const canShow = useCallback(async () => {
        if (!pets || !pets.length || !config) {
            return false;
        }
        if (!isSplashAnimationComplete || isGlobalModalVisible) {
            return false;
        }

        if (!storageKey) {
            return false;
        }

        // show only when approaching certain time
        const timeDiff = dateDiffInSeconds(
            config.nextGameIterationDate,
            new Date()
        );
        if (timeDiff > hookConfig.showWhenTimeLeft) {
            return false;
        }

        const status = await AsyncStorage.getItem(storageKey);

        Console.info('[useYourPetsHungry] canShow', storageKey, status);

        return !status;
    }, [
        isSplashAnimationComplete,
        isGlobalModalVisible,
        storageKey,
        pets,
        config,
    ]);

    const setAlreadyShown = useCallback(async () => {
        Console.info('[useYourPetsHungry] setAlreadyShown', storageKey);

        if (storageKey) {
            await AsyncStorage.setItem(storageKey, 'shown');
        }
    }, [storageKey]);

    const navigateToScreen = async () => {
        setModalOpened();
        navigation.navigate(ROUTES.YOUR_PETS_ARE_HUNGRY);
        await setAlreadyShown();
    };

    React.useEffect(() => {
        async function check() {
            if (!user) {
                return;
            }

            const status = await canShow();
            if (status) {
                await navigateToScreen();
            }
        }

        check();
    }, [canShow, user]);

    return {
        navigateToScreen,
    };
};

export default useYourPetsHungry;
