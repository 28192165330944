import * as React from 'react';
import { ScrollView } from 'react-native';

import Divider from '@components/Divider';
import { View } from '@components/Themed';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import {
    MYSTERY_LIKES_STATUS,
    MysteryLikesGameHistory,
} from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import { calculateRounds } from '../../helpers';
import GamesHistoryButtons from '../GamesHistoryButtons';
import MysteryLikesHistoryBlockHeader from '../MysteryLikesHistoryBlockHeader';
import MysteryLikesHistoryWaiting from '../MysteryLikesHistoryWaiting';
import PlayVSBlock from '../PlayVSBlock';
import StatisticsGames from '../StatisticsGames';
import stylesMain from './styles';

const SCREEN = 'MysteryLikesSelectRangeFinish';

interface IProps {
    history: MysteryLikesGameHistory;
    force?: boolean | undefined;
}

const MysteryLikesHistoryWrapper = ({ history, force }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const { mysteryLikesGameStats, clearMysteryLikes } = useMysteryGames();

    const navigateToGame = () => {
        clearMysteryLikes();
        navigation.navigateToMysteryLikesActiveOrder();
    };
    const backToHistory = () => {
        clearMysteryLikes();
        navigation.navigateToMysteryLikesHistory();
    };
    const retry = () => {
        navigation.replace(ROUTES.MYSTERY_LIKES_CREATE_GAME_INPUT);
    };

    const { status, rounds, opponentRounds, iguAmountWei, opponent } = history;

    if (status === MYSTERY_LIKES_STATUS.WaitingForOpponent) {
        return (
            <MysteryLikesHistoryWaiting
                status={status}
                exploreAction={navigateToGame}
                toHistoryAction={backToHistory}
            />
        );
    }

    const { isVisible, close } = useVisibleHook(true);

    const yourRounds = calculateRounds(rounds);
    const opponentsRounds = calculateRounds(opponentRounds);

    const canRetry = status === MYSTERY_LIKES_STATUS.Lost;
    const isDraw = status === MYSTERY_LIKES_STATUS.Draw;
    const maxRoundsCount = mysteryLikesGameStats?.maxRoundsCount || 0;

    const renderFirstState = () => {
        if (force) {
            return null;
        }

        return (
            <View style={[styles.firstState, styles[status]]}>
                <View style={styles.block}>
                    <MysteryLikesHistoryBlockHeader
                        status={status}
                        iguAmountWei={iguAmountWei}
                        isBigHeader
                    />
                </View>
                <GamesHistoryButtons
                    firstButtonAction={canRetry ? retry : navigateToGame}
                    firstButtonTitle={
                        canRetry
                            ? i18n.t('general.BUTTONS.tryAgain')
                            : i18n.t('general.BUTTONS.exploreMoreGames')
                    }
                    secondButtonTitle={i18n.t('general.BUTTONS.viewStatistics')}
                    secondButtonAction={close}
                    firstButtonType="outline"
                    isOneTheme
                    white
                />
            </View>
        );
    };

    return (
        <View style={styles.safe}>
            {isVisible && renderFirstState()}
            <View style={styles.main}>
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    style={styles.container}>
                    <MysteryLikesHistoryBlockHeader
                        status={status}
                        iguAmountWei={iguAmountWei}
                    />
                    <PlayVSBlock
                        userWin={!isDraw ? yourRounds > opponentsRounds : true}
                        creatorWin={
                            !isDraw ? yourRounds < opponentsRounds : true
                        }
                        userLabel={i18n.t('general.KEYS.yourPoints')}
                        userTitle={`${yourRounds} / ${maxRoundsCount || 0}`}
                        creatorLabel={i18n.t('general.KEYS.opponentsPoints')}
                        creatorTitle={`${opponentsRounds} / ${
                            maxRoundsCount || 0
                        }`}
                        creatorUri={opponent?.mainPet?.image.thumbnailUrl}
                        creatorWalletAddress={opponent?.walletAddress}
                        alert={i18n.t(
                            'HomeScreen.MysteryLikes.MysteryLikesConfirmPlay.warning'
                        )}
                    />
                    <Divider customStyles={styles.divider} />
                    {!!rounds && <StatisticsGames data={rounds} />}
                </ScrollView>
            </View>
            <GamesHistoryButtons
                firstButtonAction={navigateToGame}
                secondButtonAction={backToHistory}
            />
        </View>
    );
};

export default MysteryLikesHistoryWrapper;
