import * as React from 'react';
import { View } from 'react-native';
import Animated, {
    useAnimatedStyle,
    useSharedValue,
    withDelay,
    withRepeat,
    withTiming,
} from 'react-native-reanimated';

import { Pet } from '../../../../../../Data/Models';
import Hearts from '../../../../../../assets/icons/play/hearts.svg';
import MatchCard from '../../../components/MatchCard';

interface IProps {
    myPet: Pet;
    findPet: Pet;
}

export const MatchHandler = ({ myPet, findPet }: IProps) => {
    const myPetShared = useSharedValue(0);
    const myPetSharedStyle = useAnimatedStyle(() => {
        return {
            transform: [
                { rotate: `${myPetShared.value}deg` },
                {
                    translateY: -40,
                },
                { translateX: 15 },
            ],
            zIndex: 1,
        };
    });
    const findPetShared = useSharedValue(0);
    const findPetSharedStyle = useAnimatedStyle(() => {
        return {
            transform: [{ rotate: `${findPetShared.value}deg` }],
        };
    });

    const heartsShared = useSharedValue(0);
    const heartsSharedStyle = useAnimatedStyle(() => {
        return {
            position: 'absolute',
            top: -140,
            left: 60,
            zIndex: 2,
            opacity: heartsShared.value,
        };
    });
    React.useEffect(() => {
        myPetShared.value = withDelay(
            100,
            withRepeat(
                withTiming(-8, {
                    duration: 1000,
                }),
                -1,
                true
            )
        );
        findPetShared.value = withDelay(
            100,
            withRepeat(
                withTiming(4, {
                    duration: 1000,
                }),
                -1,
                true
            )
        );
        heartsShared.value = withDelay(
            100,
            withRepeat(
                withTiming(1, {
                    duration: 1000,
                }),
                -1,
                true
            )
        );
    }, []);

    return (
        <>
            <Animated.View style={heartsSharedStyle}>
                <Hearts />
            </Animated.View>
            <MatchCard pet={myPet} containerStyle={myPetSharedStyle} />
            <MatchCard pet={findPet} containerStyle={findPetSharedStyle} />
        </>
    );
};
export default MatchHandler;
