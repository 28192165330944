import { Platform, StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../../helpers/dimensions';
import font from '../../../../helpers/font';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            marginTop: Platform.OS === 'android' ? verticalScale(20) : 0,
        },
        header: {
            paddingHorizontal: scale(20),
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: verticalScale(16),
        },
        buttonExit: {
            position: 'absolute',
            top: verticalScale(16),
            left: scale(20),
            zIndex: 1,
        },
        headerText: {
            flex: 1,
            marginTop: verticalScale(4.5),
            textAlign: 'center',
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
        },
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 22,
                height: 30,
                weight: '700',
            }),
        },
        text: {
            marginTop: verticalScale(6),
            color: Colors[theme].text.secondary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
        content: {
            flex: 1,
            marginTop: verticalScale(8),
            paddingHorizontal: scale(20),
        },
        petsWrapper: {
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
            marginTop: verticalScale(16),
        },
        petItem: {
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
        },
        divider: {
            width: '100%',
            height: 1,
            backgroundColor: Colors[theme].divider,
        },
    });

export default styles;
