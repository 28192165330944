import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import {
    LUCKY_STRIKE_COLOR,
    LUCKY_STRIKE_LOST_COLOR,
    LUCKY_STRIKE_WON_COLOR,
    MYSTERY_LIKES_LOST_COLOR,
    MYSTERY_LIKES_WON_COLOR,
} from '@contexts/MysteryGamesContext';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            backgroundColor: Colors[theme].controlSecondary,
            paddingVertical: verticalScale(20),
            paddingHorizontal: scale(20),
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            borderStyle: 'dashed',
            width: '48%',
            marginBottom: verticalScale(16),
        },
        plus: {
            color: Colors[theme].icon.green,
        },
        ticketWrapper: {
            backgroundColor: 'transparent',
            alignItems: 'center',
        },
        ticketCode: {
            marginTop: verticalScale(20),
            paddingHorizontal: scale(8),
            paddingVertical: verticalScale(4),
            alignSelf: 'center',
            borderRadius: 50,
        },
        WAITblock: {
            backgroundColor: Colors[theme].controlSecondary,
        },
        WAIT: {
            backgroundColor: LUCKY_STRIKE_COLOR,
        },
        WAITtext: {
            color: Colors[theme].text.onLight,
        },

        WON: { backgroundColor: Colors[theme].alwaysOneColor.background },
        WONblock: { backgroundColor: LUCKY_STRIKE_WON_COLOR },
        WONtext: { color: LUCKY_STRIKE_WON_COLOR },

        LOSE: {
            backgroundColor: LUCKY_STRIKE_LOST_COLOR,
        },
        LOSEblock: { backgroundColor: Colors[theme].controlSecondary },
        LOSEtext: { color: Colors[theme].text.onLight },
    });

export default styles;
