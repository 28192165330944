import { StyleSheet } from 'react-native';

import { head } from 'lodash';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';
import { stylesWebOutlines } from '@helpers/style';

import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        shadow: {
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].shadow.default,
            ...stylesWebOutlines(),
        },
        container: {
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(12),
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            backgroundColor: Colors[theme].background,
            marginBottom: verticalScale(4),
        },
        top: {
            flexDirection: 'row',
            alignContent: 'center',
            alignItems: 'center',
        },
        textBlock: {
            flex: 1,
            marginLeft: scale(8),
        },
        textGap: {
            marginBottom: verticalScale(2),
        },

        progressBar: {
            marginHorizontal: 0,
            marginTop: verticalScale(8),
        },
    });

export default styles;
