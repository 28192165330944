import * as React from 'react';
import { KeyboardAvoidingView, ScrollView, Text } from 'react-native';

import Button from '@components/Button';
import ButtonsBottom from '@components/ButtonsBottom';
import Divider from '@components/Divider';
import GamingWalletChangeFeature from '@components/GamingWalletChangeFeature';
import Icon, { ICON_NAMES } from '@components/Icons';
import CoinIcon from '@components/Icons/CoinIcon';
import Input from '@components/Input';
import ModalDeposit from '@components/ModalDeposit';
import ModalLoader from '@components/ModalLoader';
import PullToRefresh from '@components/PullToRefresh';
import { View } from '@components/Themed';
import TransactionSummary from '@components/TransactionSummary';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { isIOS } from '@helpers/app';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';

import WithdrawConfirmationModal from '../components/WithdrawConfirmationModal';
import useWithdrawInputAmountScreen from './hooks/useWithdrawInputAmountScreen';
import stylesMain from './styles';

const WithdrawScreen = () => {
    const styles = useThemedStyles(stylesMain);

    const {
        values,
        errors,
        setTouched,
        handleChangeAmount,
        gamingBalance,
        handleClickMax,
        estimatedFee,
        withdrawLoading,
        isBNBEnough,
        openIsVisibleModalDeposit,
        touched,
        walletBalance,
        balanceReloading,
        onRefresh,
        transactionSummaryValues,
        isValid,
        dirty,
        submitForm,
        isVisibleConfirmationModal,
        handleConfirmWithdraw,
        closeConfirmationModal,
        selectedWithdrawToken,
        renderFeeInfoIcon,
        renderCommissionFeeInfoIcon,
        closeIsVisibleModalDeposit,
        isVisibleModalDeposit,
        renderBlockchainErrorsModal,
    } = useWithdrawInputAmountScreen();

    const renderItem = React.useCallback(() => {
        return (
            <ScrollView
                scrollEventThrottle={16}
                keyboardShouldPersistTaps="handled"
                contentContainerStyle={styles.page}>
                <Input
                    keyboardType="decimal-pad"
                    label={i18n.t('withdrawScreen.labelAmount')}
                    value={values.amount.toString()}
                    error={errors.amount}
                    onBlur={() => setTouched({ amount: true })}
                    onChangeText={(amount) => handleChangeAmount(amount)}
                    placeholder={i18n.t('withdrawScreen.placeholderAmount')}
                    secondLabel={
                        <View style={styles.wrapperLabel}>
                            <Text style={styles.label}>
                                {i18n.t('withdrawScreen.labelAvailable')}:{' '}
                            </Text>
                            <Text style={styles.labelBold}>
                                {`${
                                    balanceFromWei(
                                        gamingBalance?.iguBalance.available
                                    ).valueLong
                                } ${Coin.vigu}`}
                            </Text>
                        </View>
                    }
                    component={
                        <Button
                            onPress={handleClickMax}
                            disabled={!estimatedFee || withdrawLoading}
                            title={i18n.t('withdrawScreen.allButtonLabel')}
                            containerStyle={styles.buttonAll}
                            type="outline"
                            size="sm"
                        />
                    }
                />
                {!isBNBEnough && (
                    <Button
                        type="text"
                        title={i18n.t('withdrawScreen.depositButtonLabel')}
                        onPress={openIsVisibleModalDeposit}
                        icon={<Icon name={ICON_NAMES.PLUS} color="green" />}
                        containerStyle={styles.buttonDeposit}
                    />
                )}
                {selectedWithdrawToken === Coin.igu && (
                    <GamingWalletChangeFeature customStyles={styles.feature} />
                )}
            </ScrollView>
        );
    }, [
        touched,
        selectedWithdrawToken,
        estimatedFee,
        errors,
        values,
        withdrawLoading,
        walletBalance,
    ]);

    return (
        <KeyboardAvoidingView
            behavior={isIOS ? 'padding' : 'height'}
            style={styles.container}>
            <NavigationBar filled={false} backIcon>
                <View style={styles.titleWrapper}>
                    <CoinIcon slug={Coin.vigu} size={32} />
                    <Text style={styles.title}>
                        {i18n.t('withdrawScreen.title')} {Coin.vigu}
                    </Text>
                </View>
            </NavigationBar>
            <PullToRefresh
                refreshing={balanceReloading}
                textLoading={i18n.t('pullToRefresh.reloadBalance.text')}
                onRefresh={onRefresh}
                renderItem={renderItem}
                keyboardDismiss
            />

            <Divider customStyles={styles.divider} />
            <View style={styles.buttonContainer}>
                <TransactionSummary
                    values={transactionSummaryValues}
                    containerStyle={styles.summary}
                />
                <ButtonsBottom
                    containerStyle={styles.button}
                    disabled={
                        !(isValid && dirty) ||
                        !Number(values.amount) ||
                        withdrawLoading
                    }
                    title={i18n.t('withdrawScreen.confirmButtonLabel')}
                    onPress={submitForm}
                />
            </View>

            <WithdrawConfirmationModal
                isVisible={isVisibleConfirmationModal}
                onClose={closeConfirmationModal}
                onConfirm={handleConfirmWithdraw}
                amount={values.amount}
                fee={estimatedFee}
                coin={selectedWithdrawToken}
                isBNBEnough={isBNBEnough}
                infoIcon={renderFeeInfoIcon}
                commissionIcon={renderCommissionFeeInfoIcon}
            />

            {renderBlockchainErrorsModal()}
            <ModalDeposit
                isVisible={isVisibleModalDeposit}
                setIsVisible={() => closeIsVisibleModalDeposit()}
                coin={Coin.bnb}
            />
            <ModalLoader
                isVisible={withdrawLoading}
                text={i18n.t('wallet.loadingWithdraw')}
            />
        </KeyboardAvoidingView>
    );
};

export default WithdrawScreen;
