import * as React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import ICON from '@assets/icons/emptyState/LuckyStrikePurchaseTicket.svg';
import InputIcon from '@assets/icons/mysteryGames/LuckyStrike/inputIcon.svg';
import Time from '@assets/icons/mysteryGames/LuckyStrike/time.svg';
import AlertCard from '@components/AlertCard';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import Button from '@components/Button';
import ButtonsBottom from '@components/ButtonsBottom';
import GamingWalletChangeFeature from '@components/GamingWalletChangeFeature';
import { ICON_NAMES } from '@components/Icons';
import Input from '@components/Input';
import ModalBottom from '@components/ModalBottom';
import TagStatus from '@components/TagStatus';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT, TYPO_TEXT_COLOR } from '@components/TypoText/types';
import { isWeb } from '@helpers/app';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import {
    LuckyStrikeAvailableGameOutput,
    MYSTERY_GAMES,
} from '@models/mysteryGames';
import ROUTES from '@navigation/routes';

import HashSecuritySystem from '../HashSecuritySystem';
import LuckyStrikeConfirmationModal from '../LuckyStrikeConfirmationModal';
import LuckyStrikeParticipants from '../LuckyStrikeParticipants';
import LuckyStrikePricePool from '../LuckyStrikePricePool';
import SelectAmountOfLuckyStrikeTickets from '../SelectAmountOfLuckyStrikeTickets';
import { useLuckyStrikePurchase } from './hook/useLuckyStrikePurchase';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
    modalHeight?: number | string;
    game: LuckyStrikeAvailableGameOutput;
    onViewAllParticipants: (route: ROUTES, params?: any) => Promise<void>;
    routeBack: ROUTES;
}

const flow = 'LuckyStrikePurchaseModal';
const tickets = [1, 3, 5, 7, 9, 10];

const LuckyStrikePurchaseModal = ({
    isVisible,
    onClose,
    modalHeight = 999,
    game,
    onViewAllParticipants,
    routeBack,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const {
        values,
        errors,
        isValid,
        handleChangeAmount,
        handleOnBlur,
        onSelectCardPress,
        submitForm,
        participantsText,
        iguAmountWei,
        buttonText,
        time,
        showButton,
        enoughBalance,
        topUp,
        price,
        closeConfirmModal,
        confirmModal,
        purchaseLoading,
        onConfirmPress,
        userCount,
        texts,
        max,
        ticketsGrabbedText,
        onMaxPress,
        handleOnClose,
        isWarningTime,
    } = useLuckyStrikePurchase(game, isVisible, onClose, routeBack);

    return (
        <ModalBottom
            avoidKeyboard
            isVisible={isVisible}
            onClose={handleOnClose}
            modalHeight={modalHeight}>
            <ScrollView
                style={styles.container}
                keyboardShouldPersistTaps="never"
                showsVerticalScrollIndicator={false}>
                <TouchableOpacity activeOpacity={1}>
                    <BlockHeader
                        componentBetweenImageAndText={
                            <TagStatus
                                bottomGap={16}
                                text={time}
                                status="DEFAULT"
                                icon={<Time width={24} height={24} />}
                                alignSelf="center"
                            />
                        }
                        emptyStateIcon={<ICON />}
                        containerStyle={styles.header}
                        type="emptyState"
                        title={i18n.t(
                            `HomeScreen.${MYSTERY_GAMES.luckyStrike}.${flow}.title`,
                            {
                                type: texts.title,
                            }
                        )}
                        text={i18n.t(
                            `HomeScreen.${MYSTERY_GAMES.luckyStrike}.${flow}.text`
                        )}
                    />
                    <View style={styles.inputMainContainer}>
                        <View style={styles.inputWrapper}>
                            <View style={styles.icon}>
                                <InputIcon width={32} height={32} />
                            </View>
                            <Input
                                style={[
                                    styles.inputContainer,
                                    isWeb && {
                                        width: `${Math.max(
                                            values.amount.length,
                                            3
                                        )}ch`,
                                    },
                                ]}
                                value={values.amount}
                                keyboardType="decimal-pad"
                                onBlur={handleOnBlur}
                                onChangeText={handleChangeAmount}
                                placeholder="0"
                                maxLength={11}
                                isAvailableExternalStyle
                                autoComplete="off"
                                importantForAutofill="no"
                                autoCorrect={false}
                            />
                        </View>
                        <View style={styles.availableWrapper}>
                            <TypoText type={TYPO_TEXT.BODY_SMALL}>
                                {`${i18n.t(
                                    `HomeScreen.${MYSTERY_GAMES.luckyStrike}.ticketsGrabbed`
                                )}: `}
                                <TypoText
                                    colorType={TYPO_TEXT_COLOR.PRIMARY}
                                    type={TYPO_TEXT.SUBHEAD_SMALL_SEMIBOLD}>
                                    {ticketsGrabbedText}
                                </TypoText>
                            </TypoText>

                            <Button
                                onPress={onMaxPress}
                                title={i18n.t(
                                    'staking.coinsInputScreen.maxButton'
                                )}
                                containerStyle={styles.buttonAll}
                                type="outline"
                                size="sm"
                            />
                        </View>
                        {!!errors.amount && (
                            <TypoText
                                additionalStyles={styles.error}
                                type={TYPO_TEXT.BODY_LITTLE}
                                colorType={TYPO_TEXT_COLOR.INCORRECT}>
                                {errors.amount}
                            </TypoText>
                        )}
                        <SelectAmountOfLuckyStrikeTickets
                            onPress={onSelectCardPress}
                            amount={values.amount}
                            boughtTickets={game.boughtTickets || 0}
                            tickets={tickets}
                        />
                        {!!max && (
                            <AlertCard
                                containerStyle={styles.alert}
                                text={i18n.t(
                                    `HomeScreen.${MYSTERY_GAMES.luckyStrike}.${flow}.alert`,
                                    {
                                        maxTickets: max,
                                    }
                                )}
                            />
                        )}
                    </View>
                    <HashSecuritySystem containerStyle={styles.feature} />
                    <LuckyStrikePricePool
                        containerStyle={styles.feature}
                        amount={iguAmountWei}
                    />
                    <LuckyStrikeParticipants
                        containerStyle={styles.feature}
                        text={participantsText}
                        handlePress={
                            userCount
                                ? () =>
                                      onViewAllParticipants(
                                          ROUTES.LUCKY_STRIKE_ALL_PARTICIPANTS,
                                          { id: game.id }
                                      )
                                : undefined
                        }
                    />
                    <GamingWalletChangeFeature customStyles={styles.feature} />
                </TouchableOpacity>
            </ScrollView>
            {showButton && (
                <>
                    {!enoughBalance && (
                        <AlertCard
                            containerStyle={styles.alertBalance}
                            text={i18n.t(
                                `general.ALERTS.notEnoughCoinsOnGamingWallet`
                            )}
                            type="error"
                            onAction={topUp}
                            actionButtonIcon={ICON_NAMES.DEPOSIT}
                            actionButtonType={'fill'}
                        />
                    )}
                    <ButtonsBottom
                        title={buttonText}
                        loading={purchaseLoading}
                        disabled={
                            !isValid ||
                            Number(values.amount) === 0 ||
                            !enoughBalance ||
                            purchaseLoading ||
                            isWarningTime
                        }
                        onPress={submitForm}
                        safe
                    />
                </>
            )}
            <LuckyStrikeConfirmationModal
                isVisible={confirmModal}
                onClose={closeConfirmModal}
                onConfirm={onConfirmPress}
                amount={price}
                tickets={values.amount}
                game={MYSTERY_GAMES.luckyStrike}
                disabled={isWarningTime}
            />
        </ModalBottom>
    );
};

export default LuckyStrikePurchaseModal;
