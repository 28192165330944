import React, { useCallback, useMemo, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { useDimensions } from '@contexts/DimensionsContext';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { errorsHandler } from '@helpers/errors';
import { toastErrorUnknown } from '@helpers/toastNotification';
import { useLoadingHook } from '@hooks/useLoadingHook';
import { useVisibleHook } from '@hooks/useVisibleHook';
import {
    LUCKY_STRIKE_GAMES_STATUS,
    LuckyStrikeAvailableGameOutput,
    LuckyStrikeGameHistoryOutputResponse,
    LuckyStrikePendingGameOutputResponse,
    PaginatedLuckyStrikeGamesHistoryOutput,
} from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { useFocusEffect } from '@react-navigation/native';
import {
    GET_LUCKY_STRIKE_GAME_HISTORY,
    GET_LUCKY_STRIKE_PENDING_GAMES,
} from '@requests/luckyStrike';

import { useLuckyStrikeGamesHistory } from '../../../hooks/useLuckyStrikeGamesHistory';
import useLuckyStrikePurchaseModal from '../../../hooks/useLuckyStrikePurchaseModal';
import { toastGameExpired, toastGamePending } from '../../helpers';

const useLuckyStrikeHistoryScreen = (
    game?: LuckyStrikeAvailableGameOutput | undefined
) => {
    const navigation = useNavigation();
    const { isSmallLayout } = useDimensions();

    const [firstLoad, setFirsLoad] = useState<boolean>(true);

    const {
        availableBalance,
        getGamingAvailableBalance,
        luckyStrikeGames,
        luckyStrikeGamesLoading,
        luckyStrikeGamesRefreshLoading,
        getLuckyStrikeGames,
        refetchLuckyStrikeGames,
        luckyStrikeGamesRefetchLoading,
    } = useMysteryGames();
    const {
        gamesHistory,
        getGamesHistory,
        getGamesHistoryLoadMore,
        canLoadMore,
        loadMore,
        historyLoading,
        refreshLoading: historyRefreshLoading,
    } = useLuckyStrikeGamesHistory();

    const loadData = useCallback(
        async (silent: boolean) => {
            await Promise.all([
                getLuckyStrikeGames(silent),
                getGamingAvailableBalance(silent),
            ]);
        },
        [getLuckyStrikeGames]
    );

    useFocusEffect(
        useCallback(() => {
            getGamesHistory(firstLoad);
            setFirsLoad(false);
        }, [firstLoad])
    );

    const isLoadingGames = useMemo(
        () =>
            luckyStrikeGamesLoading ||
            luckyStrikeGamesRefreshLoading ||
            luckyStrikeGamesRefetchLoading,
        [
            luckyStrikeGamesLoading,
            luckyStrikeGamesRefreshLoading,
            luckyStrikeGamesRefetchLoading,
        ]
    );

    useFocusEffect(
        useCallback(() => {
            loadData(true);
        }, [loadData])
    );

    const isRefresh = useMemo(
        () => luckyStrikeGamesRefreshLoading || historyRefreshLoading,
        [luckyStrikeGamesRefreshLoading, historyRefreshLoading]
    );
    const handleRefresh = useCallback(async () => {
        loadData(false);
        await getGamesHistory(false);
    }, [loadData]);

    const [currentGame, setCurrentGame] =
        React.useState<LuckyStrikeAvailableGameOutput>();

    const {
        isVisible: slotsModal,
        open: openSlotsModal,
        close: closeSlotsModal,
    } = useVisibleHook();

    const resetCurrentGame = () => {
        setCurrentGame(undefined);
    };

    const { renderPurchaseModal, openPurchaseModal } =
        useLuckyStrikePurchaseModal(
            ROUTES.LUCKY_STRIKE_HISTORY,
            resetCurrentGame
        );

    const handOnHowItWorks = () => {
        navigation.push(ROUTES.HOW_LUCKY_STRIKE_WORK);
    };
    const onPurchaseTicket = (game: LuckyStrikeAvailableGameOutput) => {
        setCurrentGame(game);
        openPurchaseModal(closeSlotsModal);
    };

    const onEndTime = useCallback(() => {
        refetchLuckyStrikeGames();
        getGamesHistory(false);
    }, [refetchLuckyStrikeGames]);

    React.useEffect(() => {
        const check = async () => {
            const currentGame = luckyStrikeGames?.find(
                (i) => i.id === game?.id
            );
            if (!currentGame) {
                await toastGameExpired();
            } else {
                setCurrentGame(currentGame);
                openPurchaseModal();
            }
            navigation.setParams({
                game: undefined,
            });
        };

        if (game) {
            check();
        }
    }, [game, luckyStrikeGames]);

    const isReloading = useMemo(
        () => isRefresh || historyLoading || isLoadingGames,
        [isRefresh, historyLoading, isLoadingGames]
    );

    const {
        isLoading: onPressLoading,
        startLoading,
        stopLoading,
    } = useLoadingHook();

    const [getPendingGames] =
        useLazyQuery<LuckyStrikePendingGameOutputResponse>(
            GET_LUCKY_STRIKE_PENDING_GAMES,
            {
                fetchPolicy: 'no-cache',
            }
        );

    const [getHistoryItem] = useLazyQuery<LuckyStrikeGameHistoryOutputResponse>(
        GET_LUCKY_STRIKE_GAME_HISTORY,
        {
            fetchPolicy: 'network-only',
        }
    );

    const onHistoryItemPress = async (
        item: PaginatedLuckyStrikeGamesHistoryOutput
    ) => {
        startLoading();
        try {
            if (item.status !== LUCKY_STRIKE_GAMES_STATUS.WaitingForResults) {
                const result = await getHistoryItem({
                    variables: {
                        gameId: item.id,
                    },
                });
                if (result.data?.luckyStrikeGameHistory) {
                    navigation.navigate(ROUTES.LUCKY_STRIKE_GAME_HISTORY, {
                        game: result.data.luckyStrikeGameHistory,
                        force: true,
                        id: item.id,
                    });
                }
            } else {
                const result = await getPendingGames();
                if (result.data?.luckyStrikeMyPendingGames.length) {
                    const find = result.data.luckyStrikeMyPendingGames.find(
                        (i) => i.id === item.id
                    );
                    if (find) {
                        navigation.navigate(
                            ROUTES.LUCKY_STRIKE_WAITING_FOR_RESULT,
                            {
                                id: item.id,
                                routeBack: ROUTES.LUCKY_STRIKE_HISTORY,
                            }
                        );
                    } else {
                        toastGamePending();
                        getGamesHistory(true);
                    }
                } else {
                    toastGamePending();
                    getGamesHistory(true);
                }
            }
        } catch (error) {
            errorsHandler(error, true);
        }
        stopLoading();
    };

    return {
        handleRefresh,
        isRefresh,
        isSmallLayout,
        availableBalance,
        handOnHowItWorks,
        slotsModal,
        openSlotsModal,
        closeSlotsModal,
        renderPurchaseModal,
        onPurchaseTicket,
        luckyStrikeGames,
        currentGame,
        onEndTime,
        isLoadingGames,
        gamesHistory,
        getGamesHistoryLoadMore,
        canLoadMore,
        loadMore,
        historyLoading,
        onHistoryItemPress,
        onPressLoading,
        luckyStrikeGamesRefetchLoading,
        isReloading,
    };
};

export default useLuckyStrikeHistoryScreen;
