import { useCallback, useEffect, useMemo } from 'react';

import { EstimatedGamesTurnoverOutput } from '@Data/Models';
import { GET_ESTIMATED_GAMES_TURNOVER } from '@Data/Requests';
import { useLazyQuery } from '@apollo/client';
import { useConfig } from '@contexts/ConfigContext';
import { useDimensions } from '@contexts/DimensionsContext';
import { useGamingWallet } from '@contexts/GamingWalletContext';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { errorsHandler } from '@helpers/errors';
import useFeeInfo from '@hooks/useFeeInfo';
import { gamingWalletTransactionsFilterConfig } from '@hooks/useGamingWalletTransactionsHistory';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import { IGU_WALLET_TRANSACTIONS } from '@models/gamingWallet';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import { useWithdrawIgu } from '../../hooks/useWithdrawIgu';

export const useGamingMainScreen = () => {
    const navigation = useNavigation();

    const { isSmallLayout } = useDimensions();

    const {
        gamingBalanceLoading,
        gamingWalletCarouselData,
        canWithdraw,
        gamingBalance,
        selectedWithdrawToken,
        isBNBEnough,
        isPendingWithdraw,
        isLoading,
        isRefresh,
        loadData,
        filter,
        filterLoading,
        getIguTransactionHistoryFilter,
        getIguTransactionHistoryLoadMore,
        loadMore,
        transactionHistory,
        canLoadMore,
    } = useGamingWallet();

    const [getEstimatedTurnover, { data: estimatedTurnoverData }] =
        useLazyQuery<EstimatedGamesTurnoverOutput>(
            GET_ESTIMATED_GAMES_TURNOVER,
            {
                onError: (error) => errorsHandler(error, true),
                fetchPolicy: 'cache-and-network',
            }
        );

    useEffect(() => {
        loadData(true);
        getEstimatedTurnover();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRefresh = useCallback(async () => {
        loadData(false);
        getEstimatedTurnover();
    }, [loadData]);

    const { fee } = useWallet();

    const handleSettingsPress = () => {
        navigation.navigate(ROUTES.WALLET_SETTINGS);
    };

    const handleTopUpPress = () => {
        navigation.navigateToTopUp();
    };

    const {
        isVisible: isVisibleMainnetModal,
        open: openMainnetModal,
        close: closeMainnetModal,
    } = useVisibleHook();

    const {
        isVisible: withdrawModalVisible,
        open: openWithdrawModal,
        close: closeWithdrawModal,
    } = useVisibleHook();

    const estimatedFee = useMemo(
        () => fee?.executeTransaction.IGU,
        [fee?.executeTransaction.IGU]
    );

    const { isWithdrawDisabled } = useConfig();

    const handleWithdrawPress = useCallback(() => {
        if (isWithdrawDisabled) {
            return openMainnetModal();
        }
        if (isPendingWithdraw) {
            return openWithdrawConfirmationModal();
        }
        openWithdrawModal();
    }, [isWithdrawDisabled, isPendingWithdraw]);

    const {
        isVisible: withdrawConfirmationVisible,
        open: openWithdrawConfirmationModal,
        close: closeWithdrawConfirmationModal,
    } = useVisibleHook();

    const handleOpenWithdrawConfirmModal = async () => {
        closeWithdrawModal();
        navigation.navigate(ROUTES.WITHDRAW_INPUT_AMOUNT);
    };

    const { renderFeeInfoIcon } = useFeeInfo(
        closeWithdrawConfirmationModal,
        openWithdrawConfirmationModal
    );
    const { renderFeeInfoIcon: renderCommissionFeeInfoIcon } = useFeeInfo(
        closeWithdrawConfirmationModal,
        openWithdrawConfirmationModal,
        ROUTES.HOW_COMMISSION_FEE
    );

    const {
        withdrawLoading,
        handleConfirmWithdraw,
        renderBlockchainErrorsModal,
    } = useWithdrawIgu(closeWithdrawConfirmationModal);

    const handleChooseFilter = (item: string) => {
        getIguTransactionHistoryFilter(item as IGU_WALLET_TRANSACTIONS);
    };

    const filterData = useMemo(() => {
        return gamingWalletTransactionsFilterConfig.filters.map(
            (item, index) => {
                return {
                    key: item,
                    label:
                        index === 0
                            ? i18n.t(
                                  `wallet.gaming.transactionHistory.filter.${item}`
                              )
                            : i18n.t(
                                  `wallet.gaming.transactionHistory.transactions.${item}.title`
                              ),
                };
            }
        );
    }, [i18n, gamingWalletTransactionsFilterConfig]);

    const lastPendingId = useMemo(() => {
        if (!gamingBalance?.iguPendingWithdraw) {
            return null;
        }

        const transaction = transactionHistory?.find(
            (item) => item.type === IGU_WALLET_TRANSACTIONS.Withdraw
        );

        return transaction?.id;
    }, [transactionHistory, gamingBalance?.iguPendingWithdraw]);

    return {
        navigation,
        isSmallLayout,
        handleSettingsPress,
        handleTopUpPress,
        closeMainnetModal,
        isVisibleMainnetModal,
        handleWithdrawPress,
        gamingBalanceLoading,
        gamingWalletCarouselData,
        canWithdraw,
        gamingBalance,
        closeWithdrawModal,
        withdrawModalVisible,
        withdrawConfirmationVisible,
        openWithdrawConfirmationModal,
        closeWithdrawConfirmationModal,
        handleOpenWithdrawConfirmModal,
        selectedWithdrawToken,
        isPendingWithdraw,
        estimatedFee,
        isBNBEnough,
        renderFeeInfoIcon,
        renderCommissionFeeInfoIcon,
        renderBlockchainErrorsModal,
        handleConfirmWithdraw,
        withdrawLoading,
        isLoading,
        isRefresh,
        handleRefresh,
        filterLoading,
        loadMore,
        transactionHistory,
        getIguTransactionHistoryFilter,
        getIguTransactionHistoryLoadMore,
        handleChooseFilter,
        filter,
        filterData,
        lastPendingId,
        canLoadMore,
        estimatedTurnoverData,
    };
};
