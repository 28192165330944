import { useEffect } from 'react';

import Accelerometer from 'expo-sensors/build/Accelerometer';

import { useAccessibilitySettings } from '@contexts/AccessibilitySettingsContext';
import { wait } from '@helpers/helpers';
import { useHaptic } from '@hooks/useHaptic';

const config = {
    sensibility: 7,
    shakingTimeout: 10000,
};
// Detect shaking to prevent dishonest performance of a task
export const useDetectShaking = (
    shakeDetected: React.MutableRefObject<boolean>
) => {
    const { setIsFeedbackDisabled } = useAccessibilitySettings();
    const { triggerHeavy } = useHaptic();

    const detectShaking = async () => {
        shakeDetected.current = true;
        await wait(config.shakingTimeout);
        shakeDetected.current = false;
    };

    useEffect(() => {
        setIsFeedbackDisabled(true);
        const subscription = Accelerometer.addListener((accelerometerData) => {
            const acceleration = Math.sqrt(
                accelerometerData.x * accelerometerData.x +
                    accelerometerData.y * accelerometerData.y +
                    accelerometerData.z * accelerometerData.z
            );
            if (acceleration >= config.sensibility) {
                triggerHeavy();
                detectShaking();
            }
        });

        return () => {
            setIsFeedbackDisabled(false);
            if (subscription) subscription.remove();
        };
    }, []);
};
