import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';

import LivesCount from '@assets/icons/mysteryGames/livesCount.svg';
import Mode from '@assets/icons/mysteryGames/mode.svg';
import DifficultyIcon from '@components/Icons/DifficultyIcon';
import InfoIcon from '@components/InfoIcon/InfoIcon';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT } from '@components/TypoText/types';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import { GAME_DIFFICULTY, NFT_ROYAL_GAME_MODE } from '@models/mysteryGames';

import NftRoyalGameSettingsModal from '../NftRoyalGameSettingsModal';
import stylesMain from './styles';

interface IProps {
    containerStyle?: StyleProp<ViewStyle>;
    mode: NFT_ROYAL_GAME_MODE;
    difficulty: GAME_DIFFICULTY;
    livesCount: number;
}

const NftRoyalGamesSettings = ({
    containerStyle,
    mode,
    difficulty,
    livesCount,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { isVisible, open, close } = useVisibleHook();

    const renderSettingItem = (
        icon: React.ReactElement<SvgProps>,
        key: string,
        value: string | number,
        withGap?: boolean
    ) => {
        return (
            <View style={[styles.item, withGap && styles.gap]}>
                <View style={styles.itemLeft}>
                    <View style={styles.icon}>{icon}</View>
                    <TypoText type={TYPO_TEXT.SUBHEAD_SMALL_SEMIBOLD}>
                        {i18n.t(`general.KEYS.${key}`)}
                    </TypoText>
                </View>

                <TypoText type={TYPO_TEXT.SUBHEAD_SMALL_BOLD}>{value}</TypoText>
            </View>
        );
    };

    return (
        <View style={[styles.container, containerStyle]}>
            <View style={styles.titleBlock}>
                <TypoText type={TYPO_TEXT.SUBHEAD_BIG_BOLD}>
                    {i18n.t('general.KEYS.gameSettings')}
                </TypoText>
                <InfoIcon onPress={open} containerStyle={styles.infoIcon} />
            </View>

            {renderSettingItem(
                <Mode width={24} height={24} />,
                'mode',
                i18n.t(
                    `HomeScreen.NftRoyal.NftRoyalGameSettings.gameMode.${mode}.title`
                ),
                true
            )}
            {renderSettingItem(
                <DifficultyIcon slug={difficulty} size={24} />,
                'difficulty',
                i18n.t(
                    `HomeScreen.NftRoyal.NftRoyalGameSettings.difficultyType.${difficulty}.title`
                ),
                true
            )}
            {renderSettingItem(
                <LivesCount width={24} height={24} />,
                'livesCount',
                livesCount
            )}
            <NftRoyalGameSettingsModal isVisible={isVisible} onClose={close} />
        </View>
    );
};
export default NftRoyalGamesSettings;
