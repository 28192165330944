import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import { Text, View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';

import stylesMain from './styles';

interface IProps {
    isSelected: boolean;
    handleSelect: () => void;
    text?: string;
    children?: React.ReactNode;
}

const SelectorItem = ({ isSelected, handleSelect, text, children }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    return (
        <TouchableOpacity
            activeOpacity={0.8}
            style={[styles.shadow, isSelected && styles.selectedShadow]}
            onPress={handleSelect}>
            <View style={[styles.itemContainer, isSelected && styles.selected]}>
                {children || <Text style={styles.text}>{text}</Text>}
            </View>
        </TouchableOpacity>
    );
};
export default SelectorItem;
