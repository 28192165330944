import * as React from 'react';
import { StyleSheet } from 'react-native';

import { useDimensions } from '@contexts/DimensionsContext';
import { scale, verticalScale } from '@helpers/dimensions';

import Frame1 from '../../../assets/icons/createImportWallet/1.svg';
import Frame2 from '../../../assets/icons/createImportWallet/2.svg';
import Frame3 from '../../../assets/icons/createImportWallet/3.svg';
import Carousel from '../../../components/Carousel';
import { Text, View } from '../../../components/Themed';
import Colors from '../../../constants/Colors';
import font from '../../../helpers/font';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import { theme } from '../../../types';

interface IList {
    frame: number;
    title: string;
    description: string;
}

interface IFrame {
    index: number;
    [property: string]: any;
}

const Image: React.FunctionComponent<IFrame> = ({ index, ...rest }) => {
    const frame = {
        0: <Frame1 {...rest} />,
        1: <Frame2 {...rest} />,
        2: <Frame3 {...rest} />,
    };
    return frame[index as keyof typeof frame];
};

const ListItem: React.FunctionComponent<IList> = ({
    title,
    description,
    frame,
}) => {
    const styles = useThemedStyles(style);
    return (
        <View style={styles.listItem}>
            <Image index={frame} style={styles.image} />
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.description}>{description}</Text>
        </View>
    );
};

const Slider: React.FunctionComponent = () => {
    const { windowWidth, isSmallLayout } = useDimensions();
    const styles = useThemedStyles(style);

    const list: Array<IList> = [1, 2, 3].map((item, index) => ({
        frame: index,
        title: i18n.t(`createWalletInit.slider.titleSlide${item}`),
        description: i18n.t(`createWalletInit.slider.textSlide${item}`),
    }));

    return (
        <Carousel
            data={list}
            sliderWidth={windowWidth}
            itemWidth={windowWidth}
            renderItem={(item) => <ListItem key={item.title} {...item} />}
            fullHeight
            pagination
            centredVertical
        />
    );
};

const style = (theme: theme) =>
    StyleSheet.create({
        image: {
            alignSelf: 'center',
            marginBottom: verticalScale(24),
        },

        title: {
            color: Colors[theme].text.primary,
            marginBottom: verticalScale(8),
            textAlign: 'center',
            ...font({
                size: 22,
                height: 28,
                weight: '800',
            }),
        },
        description: {
            color: Colors[theme].text.secondary,
            textAlign: 'center',
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
        },
        listItem: {
            paddingHorizontal: scale(20),
        },
    });

export default Slider;
