import React, { useEffect, useState } from 'react';
import { Text, View } from 'react-native';

import { ICON_NAMES } from '@components/Icons';
import ImageHeaderScreen from '@components/ImageHeaderScreen';
import ModalShareOrSave from '@components/ModalShareOrSave';
import useSharePhoto from '@hooks/useShare';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';
import { useNavigation } from '@navigation/useNavigation';

import { CollectionDetailedPetProps } from '../../../../types';
import { AgeDetailsBlock } from '../components/AgeDetailsBlock';
import AnimalTypeDetailsBlock from '../components/AnimalTypeDetailsBlock';
import BlockchainInfoBlock from '../components/BlockchainInfoBlock';
import EarningsDetailsBlock from '../components/EarningsDetailsBlock';
import HealthDetailsBlock from '../components/HealthDetailsBlock';
import LevelDetailsBlock from '../components/LevelDetailsBlock';
import MatchingDetailsBlock from '../components/MatchingDetailsBlock';
import RankDetailsBlock from '../components/RankDetailsBlock';
import Styles from './styles';

const CollectionDetailedPet = ({ route }: CollectionDetailedPetProps) => {
    const styles = useThemedStyles(Styles);
    const { pet, navigateBack } = route.params;
    const navigation = useNavigation();
    const { handleShareLink, handleSaveLink, renderCameraRollPermissions } =
        useSharePhoto();

    const [isVisibleModalShare, setIsVisibleModalShare] =
        useState<boolean>(false);

    const onShare = () => {
        setIsVisibleModalShare(true);
    };

    const handleSaveToCameraRoll = async () => {
        return handleSaveLink(pet.image.url);
    };

    const handleShare = async () => {
        return handleShareLink(pet.image.url);
    };

    useEffect(() => {
        navigation.setOptions({
            gestureEnabled: !navigateBack,
        });
    });

    return (
        <>
            <NavigationBar
                containerStyle={styles.navBar}
                backIcon="leftArrow"
                settingsIcon={ICON_NAMES.SHARE}
                onPressSettings={onShare}
                onBack={() =>
                    navigateBack
                        ? navigation.navigate(navigateBack)
                        : navigation.goBack()
                }
            />

            <ImageHeaderScreen image={pet.image.url}>
                <View style={styles.headerContainer}>
                    <Text style={styles.headerTitle}>{pet.name}</Text>
                    <Text style={styles.headerSubtitle}>
                        {i18n.t('detailedPet.headerSubtitle')}
                    </Text>
                </View>
                <HealthDetailsBlock pet={pet} />
                <LevelDetailsBlock pet={pet} />
                <RankDetailsBlock pet={pet} />
                <AgeDetailsBlock pet={pet} />
                <EarningsDetailsBlock pet={pet} />
                <AnimalTypeDetailsBlock pet={pet} />
                <MatchingDetailsBlock pet={pet} />
                <BlockchainInfoBlock pet={pet} />
                <ModalShareOrSave
                    isVisible={isVisibleModalShare}
                    setIsVisible={setIsVisibleModalShare}
                    handleSaveToCameraRoll={handleSaveToCameraRoll}
                    handleShare={handleShare}
                    renderPermissions={renderCameraRollPermissions}
                />
            </ImageHeaderScreen>
        </>
    );
};
export default CollectionDetailedPet;
