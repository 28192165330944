import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { LUCKY_STRIKE_COLOR } from '@contexts/MysteryGamesContext';
import { scale } from '@helpers/dimensions';
import { stylesModalNavigationFix } from '@helpers/style';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
            ...stylesModalNavigationFix(),
        },
        header: {
            paddingHorizontal: scale(20),
            backgroundColor: 'transparent',
        },
        loading: {
            flex: 1,
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
        },
        listWrapper: {
            flex: 1,
            paddingHorizontal: scale(20),
        },
        features: {
            flexDirection: 'row',
            backgroundColor: 'transparent',
            justifyContent: 'space-between',
        },
        feature: {
            width: '47%',
        },
    });

export default styles;
