import { StyleSheet } from 'react-native';

import { verticalScale } from '../../../../helpers/dimensions';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        divider: {
            marginVertical: verticalScale(16),
        },
    });

export default styles;
