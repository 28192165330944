import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
        },
        top: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: verticalScale(16),
        },
        text: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
        },
        energyCollected: {
            paddingHorizontal: scale(8),
            paddingVertical: verticalScale(4),
            backgroundColor: Colors[theme].backgroundEnergy,
            borderRadius: 50,
        },
        textEnergyCollected: {
            color: Colors[theme].text.warning,
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),
        },
    });

export default styles;
