import React from 'react';

import Input from '@components/Input';
import i18n from '@i18n/i18n';

const config = {
    max: 30,
    min: 1,
};

export const useRankQuantitySelect = () => {
    const [quantityValue, setQuantityValue] = React.useState<string>(
        config.min.toString()
    );
    const [quantityError, setQuantityError] = React.useState<string>('');

    const onChange = React.useCallback(
        (value: string) => {
            setQuantityError('');

            const valueToNumber = Number(value);

            if (valueToNumber < config.min) {
                setQuantityError(
                    i18n.t('profile.adminAssignWhiteList.error.quantity.min', {
                        quantity: config.min,
                    })
                );
                setQuantityValue(value);
                return;
            }
            if (valueToNumber > config.max) {
                setQuantityError(
                    i18n.t('profile.adminAssignWhiteList.error.quantity.max', {
                        quantity: config.max,
                    })
                );
                setQuantityValue(value);
                return;
            }
            if (!valueToNumber) {
                setQuantityError(
                    i18n.t(
                        'profile.adminAssignWhiteList.error.quantity.numbers'
                    )
                );
                setQuantityValue('');
                return;
            }

            setQuantityValue(value);
        },
        [quantityValue]
    );

    const renderQuantityInput = React.useCallback(() => {
        return (
            <Input
                keyboardType={'numeric'}
                value={quantityValue}
                onChangeText={onChange}
                error={quantityError}
                label={i18n.t('profile.adminAssignWhiteList.quantity.label')}
            />
        );
    }, [quantityValue, quantityError]);

    const resetQuantityInput = () => {
        setQuantityValue(config.min.toString());
        setQuantityError('');
    };

    return {
        renderQuantityInput,
        resetQuantityInput,
        quantityError,
        quantityValue,
    };
};
