import * as React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import Boost from '@assets/icons/staking/boostCard.svg';
import Icon from '@assets/icons/staking/modalIcon.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import CardFeatureInfo from '@components/CardFeatureInfo/CardFeatureInfo';
import CoinIcon from '@components/Icons/CoinIcon';
import ModalBottom from '@components/ModalBottom';
import { StakingCoin } from '@contexts/StakingContext';
import { isAndroid } from '@helpers/app';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    close: () => void;
    onPress: () => void;
    boost: number;
    amount: string;
    coin: StakingCoin;
}

const MaxBoostModal = ({
    isVisible,
    close,
    onPress,
    boost,
    amount,
    coin,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <ModalBottom modalHeight={'99%'} isVisible={isVisible} onClose={close}>
            <TouchableOpacity activeOpacity={1} style={styles.flex}>
                <ScrollView
                    style={styles.modal}
                    contentContainerStyle={styles.scrollGap}
                    showsVerticalScrollIndicator={false}>
                    <BlockHeader
                        emptyStateIcon={<Icon />}
                        containerStyle={styles.blockHeader}
                        type="emptyState"
                        title={i18n.t(
                            'staking.coinsInputScreen.maxBoostModal.title'
                        )}
                        text={i18n.t(
                            'staking.coinsInputScreen.maxBoostModal.text'
                        )}
                    />
                    <CardFeatureInfo
                        containerStyle={styles.cardFeatureInfo}
                        title={i18n.t(
                            'staking.coinsInputScreen.maxBoostModal.deposit.title',
                            {
                                amount,
                                coin,
                            }
                        )}
                        text={i18n.t(
                            'staking.coinsInputScreen.maxBoostModal.deposit.text'
                        )}
                        icon={<CoinIcon slug={coin} size={44} />}
                    />
                    <CardFeatureInfo
                        containerStyle={styles.cardFeatureInfo}
                        title={i18n.t(
                            'staking.coinsInputScreen.maxBoostModal.boost.title',
                            {
                                boost,
                            }
                        )}
                        text={i18n.t(
                            'staking.coinsInputScreen.maxBoostModal.boost.text'
                        )}
                        icon={<Boost width={44} height={44} />}
                    />
                </ScrollView>
            </TouchableOpacity>
            <ButtonsBottom
                onPress={onPress}
                title={i18n.t('staking.coinsInputScreen.maxBoostModal.button')}
                safe={!isAndroid}
            />
        </ModalBottom>
    );
};
export default MaxBoostModal;
