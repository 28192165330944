import * as React from 'react';
import { Image } from 'react-native';

import {
    getAppStoreUrl,
    getPlayStoreUrl,
    isWebAndroid,
    isWebIos,
} from '@helpers/app';
import { useShowBanner } from '@hooks/useShowBanner';

import Button from '../../../components/Button';
import { Text, View } from '../../../components/Themed';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import stylesMain from './styles';

export default function RelatedApps() {
    const styles = useThemedStyles(stylesMain);
    const { canShowBanner, hideBanner } = useShowBanner({
        name: 'RelatedAppsBanner',
        type: 'custom',
        autoHide: 60000,
        perUser: false,
    });

    // TODO: Add later
    const isDownloaded = false;

    const relatedAppsText = React.useMemo(() => {
        if (isDownloaded) {
            return 'RelatedApps.switchToApp';
        }
        return 'RelatedApps.downloadApp';
    }, [isDownloaded]);

    const onClose = () => {
        hideBanner();
    };

    const onDownload = async () => {
        const url = isWebIos ? getAppStoreUrl(true) : getPlayStoreUrl(true);
        window.open(url, '_blank');
        hideBanner();
    };

    if (!canShowBanner || (!isWebAndroid && !isWebIos)) {
        return null;
    }

    return (
        <View style={styles.container}>
            <View style={styles.headerContent}>
                <Image
                    source={require('../../../assets/icons/app/iconSmall.png')}
                    style={styles.iconImage}
                />
                <View style={styles.headerText}>
                    <Text style={styles.titleText}>
                        {i18n.t(
                            `${relatedAppsText}.title${
                                isWebIos ? 'iOS' : 'Android'
                            }`
                        )}
                    </Text>
                    <Text style={styles.descriptionText}>
                        {i18n.t(`${relatedAppsText}.description`)}
                    </Text>
                </View>
            </View>

            <View style={styles.buttonContainer}>
                <Button
                    containerStyle={styles.button}
                    onPress={onClose}
                    title={i18n.t(`${relatedAppsText}.buttonCancel`)}
                    type="outline"
                    size="md"
                />
                <Button
                    containerStyle={styles.button}
                    onPress={onDownload}
                    title={i18n.t(`${relatedAppsText}.buttonOk`)}
                    type="fill"
                    size="md"
                />
            </View>
        </View>
    );
}
