import * as React from 'react';
import { ScrollView } from 'react-native';

import _ from 'lodash';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import ModalLoader from '@components/ModalLoader';
import SafeAreaTabContent from '@components/SafeAreaTabContent';
import ModalStakingInfo from '@components/Staking/ModalStakingInfo';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { getStakingPlanName } from '@helpers/staking';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';

import { StakingAlreadyProps } from '../../../../types';
import StakingCardStakedAlready from '../components/StakingCardStakedAlready/StakingCardStakedAlready';
import StakingConfirmationModal from '../components/StakingConfirmationModal';
import { useStakingAlreadyScreen } from './hook';
import stylesMain from './styles';

const StakingAlreadyScreen = ({ route }: StakingAlreadyProps) => {
    const styles = useThemedStyles(stylesMain);

    const {
        currentStakingPlan,
        stakingPlans,
        setStakingOption,
        setIsAddMoreCoins,
        currentPlanBoost,
        modalVisible,
        handleOpenModal,
        handleCloseModal,
        handleOpenConfirmationModal,
        handleCloseConfirmationModal,
        isVisibleConfirmationModal,
        setIsVisibleConfirmationModal,
        isLoading,
        handleUnStake,
        renderBlockchainErrorsModal,
        handleOnBack,
    } = useStakingAlreadyScreen();

    return (
        <SafeAreaTabContent>
            <NavigationBar
                filled={false}
                backIcon
                title={i18n.t('staking.alreadyScreen.title')}
                onBack={() => handleOnBack(route?.params)}
            />

            <ScrollView
                style={styles.container}
                showsVerticalScrollIndicator={false}>
                <BlockHeader
                    containerStyle={styles.header}
                    title={i18n.t('staking.alreadyScreen.headerTitle')}
                    text={i18n.t('staking.alreadyScreen.headerText')}
                    type={'primary'}
                    textInfo={i18n.t('staking.alreadyScreen.headerInfoText')}
                    textInfoOnPress={handleOpenModal}
                />
                {!!currentStakingPlan && stakingPlans && (
                    <StakingCardStakedAlready
                        coin={Coin.igup} //TODO: make dynamic in future
                        data={currentStakingPlan}
                        plans={stakingPlans}
                        onUnstake={handleOpenConfirmationModal}
                        setOption={setStakingOption}
                        onAddMoreCoins={setIsAddMoreCoins}
                        currentBoost={currentPlanBoost}
                    />
                )}
            </ScrollView>
            <ModalStakingInfo
                isVisible={modalVisible}
                closeAction={handleCloseModal}
            />
            <ModalLoader
                isVisible={isLoading}
                text={i18n.t('staking.unStakeLoading')}
            />
            {!!currentStakingPlan && stakingPlans && (
                <StakingConfirmationModal
                    name={getStakingPlanName(
                        currentStakingPlan.stakingPlanId,
                        stakingPlans
                    )}
                    durationDays={currentStakingPlan.durationDays}
                    coin={Coin.igup} //TODO: make dynamic in future
                    onPress={() => handleUnStake(currentStakingPlan)}
                    close={handleCloseConfirmationModal}
                    setIsVisibleConfirmationModal={
                        setIsVisibleConfirmationModal
                    }
                    isVisible={isVisibleConfirmationModal}
                    stakingAmount={
                        balanceFromWei(currentStakingPlan.amountWei).valueLong
                    }
                    stakingBoost={currentPlanBoost}
                    screen="alreadyScreen"
                />
            )}
            {renderBlockchainErrorsModal()}
        </SafeAreaTabContent>
    );
};

export default StakingAlreadyScreen;
