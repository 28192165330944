import * as React from 'react';
import { TouchableOpacity } from 'react-native';
import Animated, {
    cancelAnimation,
    runOnJS,
    useAnimatedStyle,
    useSharedValue,
    withDelay,
    withRepeat,
    withTiming,
} from 'react-native-reanimated';

import StarsIcon from '@assets/icons/mysteryBoxes/stars.svg';
import ExtendedModal from '@components/ExtendedModal';
import RankIcon from '@components/Icons/RankIcon';
import { View } from '@components/Themed';
import Colors from '@constants/Colors';
import { useDimensions } from '@contexts/DimensionsContext';
import { useTheme } from '@contexts/ThemeContext';
import { isWeb, useNativeDriver } from '@helpers/app';
import useThemedStyles from '@hooks/useThemedStyles';

import CustomModalBackdrop from '../../../../../web-src/components/CustomModalBackdrop';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    rankId: string;
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const animationConfig = {
    rankScaleFrom: 0,
    rankScaleTo: 1,
    delay: 600,
    rankAnimationDuration: 1500,
};

const NftEvolutionSuccessRankAnimation = ({
    isVisible,
    rankId,
    setIsVisible,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { theme } = useTheme();
    const { dimensions } = useDimensions();

    const sharedRankScale = useSharedValue(animationConfig.rankScaleFrom);

    const starsShare = useSharedValue(0);

    const starsSharedStyle = useAnimatedStyle(() => {
        return {
            opacity: starsShare.value,
            zIndex: 1,
        };
    });
    const rankSharedStyle = useAnimatedStyle(() => {
        return {
            transform: [{ scale: sharedRankScale.value }],
        };
    });

    const [canClose, setCanClose] = React.useState<boolean>(false);

    const handleClose = (value: boolean) => {
        cancelAnimation(sharedRankScale);
        cancelAnimation(starsShare);
        setIsVisible(!value);
    };

    const handleOnClose = React.useCallback(() => {
        if (canClose) {
            handleClose(canClose);
        }
    }, [canClose]);

    const handleCanClose = (value: boolean) => {
        setCanClose(value);
    };

    React.useEffect(() => {
        if (isVisible) {
            sharedRankScale.value = withDelay(
                animationConfig.delay,
                withRepeat(
                    withTiming(
                        1,
                        {
                            duration: animationConfig.rankAnimationDuration,
                        },

                        () => {
                            starsShare.value = withRepeat(
                                withTiming(
                                    1,
                                    {
                                        duration: 1000,
                                    },
                                    () => {}
                                ),
                                5,
                                true,
                                () => {
                                    runOnJS(handleCanClose)(true);
                                    runOnJS(handleClose)(true);
                                }
                            );
                        }
                    ),
                    1
                )
            );
        }
    }, [isVisible]);

    return (
        <ExtendedModal
            isVisible={isVisible}
            backdropColor={Colors[theme].modal.modalOverlay}
            backdropOpacity={Colors[theme].modal.opacity}
            customBackdrop={isWeb && <CustomModalBackdrop />}
            useNativeDriverForBackdrop={useNativeDriver}
            useNativeDriver={useNativeDriver}
            hideModalContentWhileAnimating
            deviceWidth={dimensions.screen.deviceWidth}
            deviceHeight={dimensions.screen.deviceHeight}
            animationIn="zoomIn"
            animationOut="slideInDown"
            animationInTiming={animationConfig.delay}
            animationOutTiming={animationConfig.delay}
            backdropTransitionInTiming={animationConfig.delay}
            backdropTransitionOutTiming={animationConfig.delay}
            style={styles.modal}>
            <TouchableOpacity
                style={styles.shadow}
                activeOpacity={0.9}
                onPress={handleOnClose}>
                <View style={styles.container}>
                    <View style={styles.box}>
                        <Animated.View style={[styles.stars, starsSharedStyle]}>
                            <StarsIcon />
                        </Animated.View>
                        <Animated.View style={[rankSharedStyle]}>
                            <RankIcon slug={rankId} size={150} />
                        </Animated.View>
                    </View>
                </View>
            </TouchableOpacity>
        </ExtendedModal>
    );
};
export default NftEvolutionSuccessRankAnimation;
