import { ReactElement } from 'react';
import { SvgProps } from 'react-native-svg';

import ConfirmedIcon from '../../../assets/icons/statuses/completed.svg';
import FailedIcon from '../../../assets/icons/statuses/failed.svg';
import PendingIcon from '../../../assets/icons/statuses/pending.svg';
import { iconSize } from '../types';

interface IIconsData {
    icon: ReactElement<SvgProps>;
}

export type IconsData = {
    CONFIRMED: IIconsData;
    FAILED: IIconsData;
    PENDING: IIconsData;
    UNLOCK: IIconsData;
};

export const iconsData: IconsData = {
    CONFIRMED: {
        icon: (
            <ConfirmedIcon width={iconSize.default} height={iconSize.default} />
        ),
    },
    PENDING: {
        icon: (
            <PendingIcon width={iconSize.default} height={iconSize.default} />
        ),
    },
    UNLOCK: {
        icon: (
            <PendingIcon width={iconSize.default} height={iconSize.default} />
        ),
    },
    FAILED: {
        icon: <FailedIcon width={iconSize.default} height={iconSize.default} />,
    },
};
