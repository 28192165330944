import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { scale, verticalScale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        imgWrapper: {
            alignItems: 'center',
        },
        title: {
            textAlign: 'center',
            marginTop: verticalScale(24),
            marginBottom: verticalScale(8),
            color: Colors[theme].text.primary,
            ...font({
                size: 22,
                height: 30,
                weight: '800',
            }),
        },
        description: {
            textAlign: 'center',
            color: Colors[theme].text.secondary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
        energyWrapper: {
            marginTop: verticalScale(24),
            alignSelf: 'center',
        },
        container: {
            paddingHorizontal: scale(20),
        },
    });

export default styles;
