import * as React from 'react';
import { useCallback, useState } from 'react';

import { uniqBy } from 'lodash';

import { useLazyQuery } from '@apollo/client';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { wait } from '@helpers/helpers';
import useGetPlayTasks from '@hooks/tasks/useGetPlayTasks';
import useDailyRewardsModal from '@hooks/useDailyRewardsModal';
import { useLoadingHook } from '@hooks/useLoadingHook';
import { useMysteryGamesData } from '@hooks/useMysteryGamesData';
import { useMysteryLikesMyPendingGames } from '@hooks/useMysteryLikesMyPendingGames';
import { useNftRoyaleMyPendingGames } from '@hooks/useNftRoyaleMyPendingGames';
import { useUpdateModal } from '@hooks/useUpdateModal';
import { useUserAchievements } from '@hooks/useUserAchievements';
import { RecentlyUserAchievementsResponse } from '@models/achievements';
import { useFocusEffect } from '@react-navigation/native';
import { GET_RECENTLY_USER_ACHIEVEMENTS } from '@requests/achievements';

import { useContinueMysteryGamesBanner } from '../../hooks/useContinueMysteryGamesBanner';
import useMemsModal from '../../hooks/useMemsModal';

const useHomeMainScreen = () => {
    const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

    const {
        getMysteryLikesGameStats,
        setCurrentMysteryLikesGameId,
        setNftRoyalCurrentGame,
        getNftRoyaleGameConfig,
        setMysteryLikesPendingGame,
        getLuckyStrikeConfig,
        getTokenTellerConfig,
    } = useMysteryGames();

    const { getMysteryGames, mysteryGamesLoading, mysteryGames } =
        useMysteryGamesData();

    const { getMysteryLikesMyPendingGames, mysteryLikesMyPendingGames } =
        useMysteryLikesMyPendingGames();
    const { getNftRoyalMyPendingGames, nftRoyalMyPendingGames } =
        useNftRoyaleMyPendingGames();

    const { renderBanner, isVisible: continueGameBannerVisible } =
        useContinueMysteryGamesBanner();
    const { renderUpdateBanner } = useUpdateModal();
    // const { renderPasscodeBanner } = usePassCodeModal();

    const { renderMemsModal, renderMemBanner } = useMemsModal();

    const [getRecentlyUserAchievements, { data: recentlyAchievements }] =
        useLazyQuery<RecentlyUserAchievementsResponse>(
            GET_RECENTLY_USER_ACHIEVEMENTS,
            { fetchPolicy: 'network-only' }
        );

    const { getUserAchievements, userAchievementsData } = useUserAchievements();

    useFocusEffect(
        useCallback(() => {
            getMysteryLikesGameStats(isFirstLoad);
            refetchPlayTasks(isFirstLoad);
            getMysteryLikesMyPendingGames(isFirstLoad);
            getNftRoyalMyPendingGames(isFirstLoad);
            getNftRoyaleGameConfig(isFirstLoad);
            getLuckyStrikeConfig(isFirstLoad);
            getTokenTellerConfig(isFirstLoad);
            getRecentlyUserAchievements();
            getUserAchievements();
            getMysteryGames(true);
            setIsFirstLoad(false);
        }, [])
    );

    const {
        isLoading: isRefresh,
        startLoading,
        stopLoading,
    } = useLoadingHook();

    const handleRefresh = async () => {
        startLoading();
        await Promise.all([
            getMysteryLikesGameStats(false),
            getNftRoyaleGameConfig(false),
            getLuckyStrikeConfig(false),
            getTokenTellerConfig(false),
            refetchPlayTasks(false),
            getMysteryLikesMyPendingGames(false),
            getNftRoyalMyPendingGames(false),
            getRecentlyUserAchievements(),
            getUserAchievements(),
            getMysteryGames(true),
        ]);
        stopLoading();
    };

    const { refetchPlayTasks, tasksConfig } = useGetPlayTasks();

    const [additionalLoading, setAdditionalLoading] = useState<boolean>(true);

    React.useEffect(() => {
        const moreLoad = async () => {
            await wait(1000);
            setAdditionalLoading(false);
        };
        if (tasksConfig && additionalLoading) {
            moreLoad();
        }
    }, [tasksConfig, additionalLoading]);

    React.useEffect(() => {
        if (mysteryLikesMyPendingGames?.length) {
            setMysteryLikesPendingGame(mysteryLikesMyPendingGames[0]);
            setCurrentMysteryLikesGameId(mysteryLikesMyPendingGames[0].id);
        }
    }, [mysteryLikesMyPendingGames]);

    React.useEffect(() => {
        if (nftRoyalMyPendingGames?.length) {
            setNftRoyalCurrentGame(nftRoyalMyPendingGames[0]);
        }
    }, [nftRoyalMyPendingGames]);

    const { renderDailyRewardsModal } = useDailyRewardsModal();

    const userAchievements = React.useMemo(() => {
        if (
            !recentlyAchievements?.recentlyUserAchievements ||
            !userAchievementsData?.userAchievements.length
        ) {
            return;
        }
        return uniqBy(
            [
                ...recentlyAchievements.recentlyUserAchievements,
                ...userAchievementsData.userAchievements,
            ],
            'type'
        ).slice(0, 3);
    }, [recentlyAchievements, userAchievementsData]);

    return {
        tasksConfig,
        additionalLoading,
        mysteryGamesLoading,
        mysteryGames,
        mysteryLikesMyPendingGames,
        isRefresh,
        handleRefresh,
        renderBanner,
        renderDailyRewardsModal,
        renderUpdateBanner,
        nftRoyalMyPendingGames,
        userAchievements,
        // renderPasscodeBanner,
        renderMemsModal,
        renderMemBanner,
        continueGameBannerVisible,
    };
};

export default useHomeMainScreen;
