import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import { stylesAlignCenter } from '@helpers/style';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            marginTop: verticalScale(32),
        },
        main: {
            flex: 1,
        },
        actionButton: {
            color: Colors[theme].icon.green,
        },
        header: {
            paddingHorizontal: scale(20),
            backgroundColor: 'transparent',
        },
        accent: {
            color: Colors[theme].text.accent,
        },
        alert: {
            marginTop: verticalScale(16),
            marginHorizontal: scale(20),
        },
        loading: {
            ...stylesAlignCenter(),
        },
        pvb: {
            marginHorizontal: scale(20),
        },
        feature: { marginHorizontal: scale(20), marginTop: verticalScale(12) },
    });

export default styles;
