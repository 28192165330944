import * as React from 'react';

import { wait } from '@helpers/helpers';
import { timeFormatter } from '@helpers/time';
import { useCountdown } from '@hooks/useCountDown';

interface IProps {
    endOfTime: string | undefined;
    showHours?: boolean;
    showDays?: boolean;
    showMinutes?: boolean;
    showSeconds?: boolean;
    withLeadingZero?: boolean;
    onEndTime?: () => void;
    endTimeWarning?: number;
    delay?: number;
}

export const useTimer = ({
    endOfTime,
    showSeconds = true,
    showMinutes = true,
    showHours = true,
    showDays = false,
    withLeadingZero = true,
    endTimeWarning = 5,
    delay = 1000,
    onEndTime,
}: IProps) => {
    const [isWarningTime, setIsWarningTime] = React.useState<boolean>(false);
    const [isTimerEnd, setIsTimerEnd] = React.useState<boolean>(false);
    const [days, hours, minutes, seconds] = useCountdown(endOfTime || '');

    const daysText = React.useMemo(
        () => (showDays ? timeFormatter(days, showDays) : ''),
        [days, showDays]
    );
    const hoursText = React.useMemo(
        () => (showHours ? timeFormatter(hours, withLeadingZero) : ''),
        [hours, showHours, withLeadingZero]
    );
    const minutesText = React.useMemo(
        () => (showMinutes ? timeFormatter(minutes, withLeadingZero) : ''),
        [minutes, showMinutes, withLeadingZero]
    );
    const secondsText = showSeconds
        ? timeFormatter(seconds, withLeadingZero)
        : '';

    React.useEffect(() => {
        if (hours == 0 && minutes == 0 && seconds === endTimeWarning)
            setIsWarningTime(true);
        if (hours == 0 && minutes == 0 && seconds == 0) {
            setIsTimerEnd(true);
            wait(delay).then(() => {
                setIsWarningTime(false);
                Console.log('[Timer] Reach end');
                onEndTime?.();
            });
        }
    }, [days, hours, minutes, seconds, delay]);

    const timeText = (daysText + hoursText + minutesText + secondsText).slice(
        1
    );

    return { timeText, isWarningTime, isTimerEnd };
};
