import { useCallback, useMemo } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { errorsHandler } from '@helpers/errors';
import { priceString } from '@helpers/payments';
import {
    balanceFromWei,
    inputNumberFormatter,
    isBiggerThanMax,
    valueToWei,
} from '@helpers/wallet';
import { useCreateMysteryLikeGame } from '@hooks/useCreateMysteryLikeGame';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { useFocusEffect } from '@react-navigation/native';

export const useMysteryLikesCreateGameInput = () => {
    const navigation = useNavigation();

    const {
        availableBalance,
        mysteryLikesGameStats,
        setCreatedMysteryLikesGame,
        setCurrentMysteryLikesGameId,
        setCurrentMysteryLikesGameValue,
        setTopUpFromMysteryGames,
        getGamingAvailableBalance,
        availableBalanceLoading,
    } = useMysteryGames();

    const { createMysteryGame, createdMysteryLikesGameLoading } =
        useCreateMysteryLikeGame();

    useFocusEffect(
        useCallback(() => {
            setTopUpFromMysteryGames(false);
            getGamingAvailableBalance(true);
        }, [])
    );

    const minAmount = useMemo(
        () =>
            balanceFromWei(mysteryLikesGameStats?.minimalBetIguAmountWei).value,
        [mysteryLikesGameStats?.minimalBetIguAmountWei]
    );

    const maxAmount = useMemo(
        () => balanceFromWei(availableBalance?.available).value,
        [availableBalance?.available]
    );

    const schema = Yup.object({
        amount: Yup.string()
            .transform((_, value) => {
                if (value && value.includes('.')) {
                    return value;
                }
                return value && value.replace(/,/, '.');
            })
            .matches(
                /^\d*(\.\d+)?$/,
                i18n.t('HomeScreen.EnterCoinsAmountScreen.error.digits')
            )
            .required(i18n.t('withdrawScreen.errors.amountRequired'))
            .test(
                'maxAmount',
                i18n.t('HomeScreen.EnterCoinsAmountScreen.error.max'),
                (value) => Number(value) <= Number(maxAmount)
            )
            .test(
                'minAmount',
                i18n.t('HomeScreen.EnterCoinsAmountScreen.error.minStake', {
                    amount: priceString(minAmount, Coin.vigu),
                }),
                (value) => Number(value) >= Number(minAmount)
            ),
    });

    const {
        isVisible: confirmationModalVisible,
        open: openConfirmationModal,
        close: closeConfirmationModal,
    } = useVisibleHook();

    const createGame = () => {
        openConfirmationModal();
    };

    const {
        submitForm,
        values,
        errors,
        setFieldValue,
        setTouched,
        touched,
        isValid,
        dirty,
    } = useFormik({
        initialValues: {
            amount: '',
        },
        validationSchema: schema,
        onSubmit: () => {
            createGame();
        },
        enableReinitialize: true,
    });

    const handleChangeAmount = async (amount: string) => {
        setTouched({ amount: false });

        const convertedText = inputNumberFormatter(amount);
        if (!isBiggerThanMax(convertedText)) {
            await setFieldValue('amount', convertedText);
        }
    };

    const handleClickAll = useCallback(async () => {
        await setFieldValue('amount', maxAmount);
    }, [maxAmount]);

    const handleOnBlur = () => {
        setTouched({ amount: true });
    };

    const handleDeposit = () => {
        setTopUpFromMysteryGames(true);
        navigation.navigate(ROUTES.WALLET_ROOT, {
            screen: ROUTES.WALLET_GAMING_TAB,
            params: {
                screen: ROUTES.TOP_UP_GAMING_WALLET,
            },
        });
    };

    const handleOnConfirm = async (amount: string) => {
        try {
            const res = await createMysteryGame({
                variables: {
                    iguAmountWei: valueToWei(amount),
                },
            });

            if (res.data) {
                setCreatedMysteryLikesGame(res.data.mysteryLikesCreateGame);
                setCurrentMysteryLikesGameId(
                    res.data.mysteryLikesCreateGame.id
                ),
                    setCurrentMysteryLikesGameValue(
                        res.data.mysteryLikesCreateGame.iguAmountWei
                    ),
                    navigation.replace(
                        ROUTES.MYSTERY_LIKES_CREATE_GAME_SUCCESS
                    );
            }
        } catch (error) {
            errorsHandler(error, true);
        }
    };
    const formattedValue = useMemo(() => values.amount.slice(0, 11), [values]);
    return {
        submitForm,
        values,
        errors,
        setFieldValue,
        setTouched,
        touched,
        isValid,
        dirty,
        handleChangeAmount,
        handleClickAll,
        handleOnBlur,
        maxAmount,
        handleDeposit,
        confirmationModalVisible,
        closeConfirmationModal,
        handleOnConfirm,
        createdMysteryLikesGameLoading,
        availableBalanceLoading,
        formattedValue,
    };
};
