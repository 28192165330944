import React, { useState } from 'react';

import { useTheme } from '@contexts/ThemeContext';
import { wait } from '@helpers/helpers';

import ThemeIcon from '../../../../../../assets/icons/profile/theme.svg';
import ModalLoader from '../../../../../../components/ModalLoader';
import TextSwitch from '../../../../../../components/TextSwitch';
import i18n from '../../../../../../i18n/i18n';
import MenuItem from '../../../MenuItem';
import { iconSize } from '../../helpers';

const ThemeMenuItem = () => {
    const { updateTheme, theme } = useTheme();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const changeTheme = async (theme: 'light' | 'dark') => {
        setIsLoading(true);
        await wait(500);
        await updateTheme(theme);
        setIsLoading(false);
    };

    return (
        <>
            <MenuItem
                leftIcon={<ThemeIcon width={iconSize} height={iconSize} />}
                title={i18n.t('profile.menuItems.theme.title')}
                description={i18n.t('profile.menuItems.theme.description')}
                rightComponent={
                    <TextSwitch
                        defaultActiveOption={theme}
                        firstOption="light"
                        secondOption="dark"
                        firstOptionLabel={i18n.t('profile.lightTheme')}
                        secondOptionLabel={i18n.t('profile.darkTheme')}
                        onFirstOptionActive={() => changeTheme('light')}
                        onSecondOptionActive={() => changeTheme('dark')}
                    />
                }
            />
            <ModalLoader isVisible={isLoading} />
        </>
    );
};

export default ThemeMenuItem;
