import { GET_MYSTERY_LIKES_GAME_ROUND } from '@Data/Requests';
import { useLazyQuery } from '@apollo/client';
import { MysteryLikesGameRoundOutputResponse } from '@models/mysteryGames';

export const useGetMysteryLikeGameRound = () => {
    const [
        getMysteryLikeGameRound,
        { loading: getMysteryLikeGameRoundLoading },
    ] = useLazyQuery<MysteryLikesGameRoundOutputResponse>(
        GET_MYSTERY_LIKES_GAME_ROUND,
        {
            fetchPolicy: 'network-only',
        }
    );

    return {
        getMysteryLikeGameRound,
        getMysteryLikeGameRoundLoading,
    };
};
