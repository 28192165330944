import { StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../../helpers/dimensions';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        shadow: {
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].shadow.default,
            marginRight: verticalScale(12),
        },
        activeShadow: {
            backgroundColor: Colors[theme].shadow.selected,
        },
        itemWrapper: {
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(12),
            marginBottom: verticalScale(4),
            borderRadius: moderateScale(12),
        },
        image: {
            borderRadius: moderateScale(6),
            width: scale(64),
            height: verticalScale(64),
        },
        active: {
            borderColor: Colors[theme].inputActiveBorder,
        },
    });

export default styles;
