import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../types';

const stylesMain = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: Colors[theme].background,
        },
        inputMainContainer: {
            marginTop: verticalScale(20),
            paddingHorizontal: scale(20),
            paddingVertical: verticalScale(20),
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
        },
        infoText: {
            textAlign: 'center',
            marginTop: verticalScale(8),
            marginBottom: verticalScale(12),
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
        },
        infoTextError: {
            color: Colors[theme].text.danger,
        },
        feature: {
            marginTop: verticalScale(16),
        },
        inputWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center',
        },
        inputContainer: {
            ...font({
                size: 22,
                weight: '700',
            }),
            marginLeft: scale(8),
            minWidth: 45,
            color: Colors[theme].text.primary,
        },
        icon: {
            marginTop: verticalScale(6),
        },
        inputWebContainer: {
            marginLeft: 0,
            textAlign: 'center',
        },
        page: {
            paddingHorizontal: scale(20),
        },
        availableWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: verticalScale(16),
        },
        buttonAll: { marginLeft: scale(8) },
        available: {
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
            letterSpacing: 0.08,
            color: Colors[theme].text.secondary,
        },
        amount: {
            ...font({
                size: 22,
                height: 30,
                weight: '700',
            }),

            color: Colors[theme].text.primary,
        },
        amountAll: {
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),

            color: Colors[theme].text.primary,
        },
        gap: {
            marginTop: verticalScale(16),
        },
        cardWrapper: {
            marginHorizontal: scale(20),
            marginBottom: verticalScale(8),
        },

        errorsWrapper: {
            marginTop: verticalScale(12),
            paddingHorizontal: scale(20),
        },
        error: {
            textAlign: 'center',
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
            color: Colors[theme].incorrect,
        },
    });

export default stylesMain;
