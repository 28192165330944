import { StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import { verticalScale } from '../../../../helpers/dimensions';
import font from '../../../../helpers/font';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        navBar: {
            position: 'absolute',
            backgroundColor: 'transparent',
            width: '100%',
        },
        headerContainer: {
            marginTop: verticalScale(24),
            marginBottom: verticalScale(16),
        },
        headerTitle: {
            color: Colors[theme].text.primary,
            ...font({
                size: 22,
                height: 30,
                weight: '700',
            }),
        },
        headerSubtitle: {
            marginTop: verticalScale(6),
            color: Colors[theme].text.secondary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
    });

export default styles;
