import { Linking } from 'react-native';

import { toastError } from '@helpers/toastNotification';

import i18n from '../i18n/i18n';

export function openUrlOrShowError(url: string) {
    return Linking.openURL(url).catch((error) => {
        Console.error(error);
        toastError(
            i18n.t('general.errors.error.title'),
            i18n.t('general.errors.cantOpenUrl.text', {
                url,
            })
        );
    });
}
