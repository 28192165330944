import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import { JOIN_NFT_ROYAL_GAME } from '@Data/Requests';
import { useMutation } from '@apollo/client';
import ICON from '@assets/icons/emptyState/mysteryLikesConfirmPlay.svg';
import AlertCard from '@components/AlertCard';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import GamingWalletChangeFeature from '@components/GamingWalletChangeFeature';
import { ICON_NAMES } from '@components/Icons';
import ModalBottom from '@components/ModalBottom';
import QuantumSpinProvablyFair from '@components/QuantumSpinProvablyFair';
import { Text } from '@components/Themed';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { errorsHandler } from '@helpers/errors';
import { isGraphqlError } from '@helpers/helpers';
import { priceString } from '@helpers/payments';
import { toastErrorUnknown } from '@helpers/toastNotification';
import { balanceFromWei } from '@helpers/wallet';
import { useLoadingHook } from '@hooks/useLoadingHook';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES, nftRoyalJoinGameResponse } from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import { toastBanned, toastGameAlreadyStarted } from '../../helpers';
import ConfirmationModal from '../ConfirmationModal';
import NftRoyalGamesSettings from '../NftRoyalGamesSettings';
import PlayVSBlock from '../PlayVSBlock';
import QuantumSpinChancesTable from '../QuantumSpinChancesTable';
import QuantumSpinConfirmationModal from '../QuantumSpinConfirmationModal';
import QuantumSpinProbablyFairVerificationExample from '../QuantumSpinProbablyFairVerificationExample';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
    modalHeight?: number | string;
    spinAmount: string;
    onPurchaseConfirm: () => void;
    isLoading?: boolean;
    handleOpenModalScreen: (route: ROUTES, params?: any) => Promise<void>;
}

const QuantumSpinConfirmPlayModal = ({
    isVisible,
    onClose,
    modalHeight = 999,
    spinAmount,
    onPurchaseConfirm,
    isLoading,
    handleOpenModalScreen,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const {
        availableBalance,
        availableBalanceLoading,
        getGamingAvailableBalance,
        setTopUpFromMysteryGames,
    } = useMysteryGames();

    React.useEffect(() => {
        if (isVisible) {
            getGamingAvailableBalance(true);
        }
    }, [isVisible]);

    const enoughBalance = useMemo(
        () =>
            Number(balanceFromWei(spinAmount).value) <=
            Number(balanceFromWei(availableBalance?.available).value),
        [spinAmount, availableBalance]
    );

    const {
        isVisible: isVisibleConfirmationModal,
        open: openConfirmationModal,
        close: closeConfirmationModal,
    } = useVisibleHook();

    const navigation = useNavigation();

    const topUp = () => {
        setTopUpFromMysteryGames(true);
        onClose();
        navigation.navigate(ROUTES.WALLET_ROOT, {
            screen: ROUTES.WALLET_GAMING_TAB,
            params: {
                screen: ROUTES.TOP_UP_GAMING_WALLET,
            },
        });
    };

    return (
        <ModalBottom
            isVisible={isVisible}
            onClose={onClose}
            modalHeight={modalHeight}>
            <ScrollView
                style={styles.container}
                showsVerticalScrollIndicator={false}>
                <TouchableOpacity activeOpacity={1}>
                    <BlockHeader
                        containerStyle={styles.header}
                        emptyStateIcon={<ICON />}
                        type="emptyState"
                        titleComponent={
                            <>
                                {i18n.t('HomeScreen.ConfirmPlayModal.title2')}
                                <Text style={styles.accent}>
                                    {`${priceString(
                                        balanceFromWei(spinAmount).valueLong,
                                        Coin.vigu
                                    )}`}
                                </Text>
                                {'?'}
                            </>
                        }
                        text={i18n.t('HomeScreen.ConfirmPlayModal.text')}
                    />
                    <QuantumSpinProvablyFair
                        onInfoIconPress={() =>
                            handleOpenModalScreen(
                                ROUTES.QUANTUM_SPIN_PROBABLY_FAIR
                            )
                        }
                        customStyles={styles.feature}
                    />
                    <QuantumSpinChancesTable containerStyle={styles.feature} />
                    <GamingWalletChangeFeature customStyles={styles.feature} />
                </TouchableOpacity>
            </ScrollView>

            {!enoughBalance && !availableBalanceLoading && (
                <AlertCard
                    containerStyle={styles.alert}
                    text={i18n.t(`general.ALERTS.notEnoughCoinsOnGamingWallet`)}
                    type="error"
                    onAction={topUp}
                    actionButtonIcon={ICON_NAMES.DEPOSIT}
                    actionButtonIconColor={styles.actionButton.color}
                    actionButtonType={'outline'}
                />
            )}
            <ButtonsBottom
                disabled={
                    availableBalanceLoading ||
                    !availableBalance ||
                    !enoughBalance ||
                    isLoading
                }
                loading={availableBalanceLoading || isLoading}
                title={i18n.t(`general.BUTTONS.startPlaying`)}
                onPress={openConfirmationModal}
                safe
            />

            <QuantumSpinConfirmationModal
                isVisible={isVisibleConfirmationModal}
                onClose={closeConfirmationModal}
                onConfirm={onPurchaseConfirm}
                amount={spinAmount}
            />
        </ModalBottom>
    );
};

export default QuantumSpinConfirmPlayModal;
