import * as React from 'react';
import { ScrollView } from 'react-native';

import LockIcon from '@assets/icons/wallet/lock.svg';
import ShieldIcon from '@assets/icons/wallet/shield.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import CustomModal from '@components/CustomModal';
import ImageIguana from '@components/ImageIguana';
import { View } from '@components/Themed';
import { useLocalAuthentication } from '@hooks/useLocalAuthentication';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import SettingItem from '../components/SettingItem';
import stylesMain from './styles';

const WalletSettingsScreen = () => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const {
        isVisible: isBackupModalVisible,
        close: closeBackupModal,
        open: openBackupModal,
    } = useVisibleHook();

    const {
        renderPassCodeCheck,
        localAuth,
        authLoading,
        isAuth,
        checkAuthEnabled,
    } = useLocalAuthentication();

    React.useEffect(() => {
        localAuth();
    }, []);

    const navigateToSettings = () => {
        navigation.navigate(ROUTES.PASSCODE_SETTINGS);
    };

    const handlePressPassCode = React.useCallback(async () => {
        if (!isAuth) {
            await localAuth(navigateToSettings);
            return;
        }
        if (await checkAuthEnabled()) {
            navigateToSettings();
            return;
        }
        navigation.navigate(ROUTES.PASSCODE_WELCOME);
    }, [isAuth]);

    const handlePressBackup = () => {
        if (!isAuth) {
            localAuth(openBackupModal);
            return;
        }
        openBackupModal();
    };

    const handlePressConfirm = async () => {
        closeBackupModal();
        navigation.navigate(ROUTES.WALLET_SETTINGS_BACKUP);
    };

    return (
        <View style={styles.container}>
            <NavigationBar
                backIcon
                filled={false}
                title={i18n.t('walletSettings.title')}
            />
            <ScrollView
                style={styles.page}
                showsVerticalScrollIndicator={false}>
                <BlockHeader
                    type={'primary'}
                    title={i18n.t('walletSettings.header')}
                    text={i18n.t('walletSettings.description')}
                />

                <View style={styles.list}>
                    <SettingItem
                        disabled={authLoading}
                        onPress={handlePressBackup}
                        icon={<ShieldIcon width={32} height={32} />}
                        title={i18n.t('walletSettings.backup.title')}
                        text={i18n.t('walletSettings.backup.description')}
                    />
                    <SettingItem
                        disabled={authLoading}
                        onPress={handlePressPassCode}
                        icon={<LockIcon width={32} height={32} />}
                        title={i18n.t('walletSettings.passcode.title')}
                        text={i18n.t('walletSettings.passcode.description')}
                    />
                </View>
            </ScrollView>
            <CustomModal
                isVisible={isBackupModalVisible}
                icon={<ImageIguana type="attention" />}
                titleText={i18n.t('walletSettings.backup.modal.title')}
                infoText={i18n.t('walletSettings.backup.modal.text')}
                firstButtonText={i18n.t('walletSettings.backup.modal.yes')}
                secondButtonText={i18n.t('walletSettings.backup.modal.no')}
                alertText={i18n.t('walletSettings.backup.modal.alert')}
                alertType="exclamation"
                checkboxText={i18n.t('walletSettings.backup.modal.checkbox')}
                disableFirstButtonIfNotCheckboxEnabled
                onFirstButtonClick={handlePressConfirm}
                onSecondButtonClick={closeBackupModal}
            />
            {renderPassCodeCheck()}
        </View>
    );
};

export default WalletSettingsScreen;
