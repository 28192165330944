import React from 'react';

import Icon from '../../../../../../assets/icons/profile/socialMedia.svg';
import i18n from '../../../../../../i18n/i18n';
import ROUTES from '../../../../../../navigation/routes';
import { useNavigation } from '../../../../../../navigation/useNavigation';
import MenuItem from '../../../MenuItem';
import { RightComponent, iconSize } from '../../helpers';

const SocialMediaMenuItem = () => {
    const navigation = useNavigation();

    return (
        <MenuItem
            leftIcon={<Icon width={iconSize} height={iconSize} />}
            title={i18n.t('profile.menuItems.socialMedia.title')}
            description={i18n.t('profile.menuItems.socialMedia.description')}
            rightComponent={<RightComponent type="arrowRight" />}
            onPress={() => navigation.navigate(ROUTES.PROFILE_SOCIAL_MEDIA)}
        />
    );
};

export default SocialMediaMenuItem;
