import * as React from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';

import ActivityIndicator from '@components/ActivityIndicator';
import Icon, { ICON_NAMES } from '@components/Icons';
import { Text, View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';

import stylesMain from './styles';

interface IProps {
    title: string;
    containerStyle?: StyleProp<ViewStyle>;
    setModalHeight: React.Dispatch<React.SetStateAction<number>>;
    children: React.ReactNode;
    loading?: boolean;
    shouldBeOpened?: boolean;
}

const Dropdown = ({
    title,
    containerStyle,
    setModalHeight,
    children,
    loading,
    shouldBeOpened = false,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const [isOpened, setIsOpened] = React.useState<boolean>(shouldBeOpened);
    const [height, setHeight] = React.useState<number>(0);

    const handleClick = React.useCallback(() => {
        if (isOpened) {
            setModalHeight((prev) => prev - height);
        } else {
            setModalHeight((prev) => prev + height);
        }
        setIsOpened(!isOpened);
    }, [isOpened, height]);

    const [oneTime, setOneTime] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (shouldBeOpened && height && oneTime) {
            setOneTime((prev) => !prev);
            setModalHeight((prev) => prev + height);
        }
    }, [shouldBeOpened, height]);

    return (
        <View style={[styles.container, containerStyle]}>
            {loading && (
                <View style={styles.loading}>
                    <ActivityIndicator loading={true} size="small" />
                </View>
            )}
            <TouchableOpacity
                disabled={loading}
                onPress={handleClick}
                activeOpacity={0.8}
                style={styles.titleBlock}>
                <Text style={styles.title}>{title}</Text>
                <View style={{}}>
                    <Icon
                        name={ICON_NAMES.ARROW_DROP_DOWN}
                        size={20}
                        color={styles.iconColor.color}
                    />
                </View>
            </TouchableOpacity>
            {isOpened && (
                <View style={isOpened && styles.dropdownBlock}>{children}</View>
            )}
            <View
                onLayout={({ nativeEvent }) =>
                    setHeight(Math.floor(nativeEvent.layout.height))
                }
                style={[styles.dropdownBlock, styles.hiddenView]}>
                {children}
            </View>
        </View>
    );
};

export default Dropdown;
