import * as React from 'react';
import { LayoutChangeEvent } from 'react-native';

import Button from '../../../../components/Button';
import { ICON_NAMES } from '../../../../components/Icons';
import { View } from '../../../../components/Themed';
import { verticalScale } from '../../../../helpers/dimensions';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import stylesMain from './styles';

interface IProps {
    onPressPlus: () => void;
    onPressMinus: () => void;
    onPressBin: () => void;
}

const MintEditPhotoButtons = ({
    onPressPlus,
    onPressMinus,
    onPressBin,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={styles.buttonsContainer}>
            <View style={styles.button}>
                <Button
                    type="outline"
                    iconName={ICON_NAMES.PLUS}
                    onPress={onPressPlus}
                />
            </View>
            <View style={styles.button}>
                <Button
                    type="outline"
                    iconName={ICON_NAMES.MINUS}
                    onPress={onPressMinus}
                />
            </View>
            <View style={styles.button}>
                <Button
                    type="outline"
                    iconName={ICON_NAMES.BIN}
                    iconColor={styles.binIcon.color}
                    onPress={onPressBin}
                />
            </View>
        </View>
    );
};
export default MintEditPhotoButtons;
