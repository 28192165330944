import * as React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import ICON from '@assets/icons/emptyState/secureHashSystem.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import ModalBottom from '@components/ModalBottom';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';

import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
}

const ModalSecureHashSystem = ({ isVisible, onClose }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <ModalBottom isVisible={isVisible} onClose={onClose} modalHeight={500}>
            <ScrollView
                style={styles.container}
                showsVerticalScrollIndicator={false}>
                <TouchableOpacity activeOpacity={1}>
                    <BlockHeader
                        containerStyle={styles.header}
                        emptyStateIcon={<ICON />}
                        type="emptyState"
                        title={i18n.t(
                            `HomeScreen.${MYSTERY_GAMES.luckyStrike}.HashSecuritySystem.title`
                        )}
                        text={i18n.t(
                            `HomeScreen.${MYSTERY_GAMES.luckyStrike}.HashSecuritySystem.text`
                        )}
                    />
                </TouchableOpacity>
            </ScrollView>
        </ModalBottom>
    );
};

export default ModalSecureHashSystem;
