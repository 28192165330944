import { StyleSheet } from 'react-native';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingLeft: 20,
            paddingTop: 12,
            borderRadius: 16,
        },

        textBlock: {
            backgroundColor: 'transparent',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginRight: 16,
        },
        left: {
            paddingTop: 4,
            backgroundColor: 'transparent',
            maxWidth: '75%',
        },
        ordersBlock: {
            flexDirection: 'row',
            maxWidth: '25%',
            alignSelf: 'flex-start',
            backgroundColor: 'transparent',
            justifyContent: 'flex-end',
        },
        textGap: {
            marginBottom: 4,
        },
        iconGap: {
            marginLeft: 4,
        },
        buttonWrapper: {
            marginVertical: 20,
            alignSelf: 'flex-start',
            backgroundColor: 'transparent',
            zIndex: 1,
        },

        block: {
            flexDirection: 'row',
            borderRadius: 50,
            backgroundColor: 'rgba(0, 0, 0, 0.15)',
            paddingHorizontal: 6,
            paddingVertical: 4,
            alignItems: 'center',
        },

        icon: {
            backgroundColor: 'transparent',
            position: 'absolute',
            right: 16,
            bottom: -18,
            width: 115,
            aspectRatio: 1,
            zIndex: 0,
        },
    });

export default styles;
