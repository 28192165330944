import { gql } from '@apollo/client';

export const GET_LUCKY_STRIKE_AVAILABLE_GAMES = gql`
    query LuckyStrikeAvailableGames {
        luckyStrikeAvailableGames {
            id
            iguAmountWei
            duration
            userCount
            ticketCount
            createdAt
            texts {
                title
                text
            }
            styles {
                backgroundColor
                imageUrl
            }
            ticketsLeftCount
            endDate
        }
    }
`;

export const GET_LUCKY_STRIKE_GAME_CONFIG = gql`
    query LuckyStrikeGameConfig {
        luckyStrikeGameConfig {
            maxTicketAmount
            minTicketAmount
            ticketPriceWei
            commisionFeePercentage
            gameTypes {
                type
                maxUsers
            }
        }
    }
`;
export const LUCKY_STRIKE_GAME_BUY_TICKET = gql`
    mutation LuckyStrikeGameBuyTicket($input: LuckyStrikeGameBuyTicketInput!) {
        luckyStrikeGameBuyTicket(input: $input)
    }
`;
export const GET_LUCKY_STRIKE_GAME_PARTICIPANTS = gql`
    query LuckyStrikeGameParticipants($gameId: String!) {
        luckyStrikeGameParticipants(gameId: $gameId) {
            iguAmountWei
            userCount
            maxUsers
            participants {
                id
                username
                avatarUrl
                iguAmountWei
                ticketsCount
                isWinner
            }
        }
    }
`;

export const GET_LUCKY_STRIKE_PENDING_GAMES = gql`
    query LuckyStrikeMyPendingGames {
        luckyStrikeMyPendingGames {
            id
            duration
            iguAmountWei
        }
    }
`;
export const GET_LUCKY_STRIKE_AVAILABLE_GAME = gql`
    query LuckyStrikeAvailableGame($gameId: String!) {
        luckyStrikeAvailableGame(gameId: $gameId) {
            id
            iguAmountWei
            duration
            userCount
            ticketCount
            createdAt
            texts {
                title
                text
            }
            styles {
                backgroundColor
                imageUrl
            }
            ticketsLeftCount
            endDate
        }
    }
`;

export const GET_LUCKY_STRIKE_USER_TICKETS = gql`
    query LuckyStrikeUserTickets($gameId: String!) {
        luckyStrikeUserTickets(gameId: $gameId) {
            number
            isWon
        }
    }
`;
export const GET_LUCKY_STRIKE_GAMES_STATISTICS = gql`
    query LuckyStrikeGamesStatistic {
        luckyStrikeGamesStatistic {
            totalIguAmountWei
            totalGamesCount
        }
    }
`;

export const GET_LUCKY_STRIKE_GAMES_WINNER = gql`
    query LuckyStrikeGamesWinner(
        $paginationInput: LuckyStrikeGamesWinnerPaginationInput!
    ) {
        luckyStrikeGamesWinner(paginationInput: $paginationInput) {
            items {
                number
                duration
                username
                avatarUrl
                iguAmountWei
                title
            }
            meta {
                itemCount
                totalItems
                itemsPerPage
                totalPages
                currentPage
            }
        }
    }
`;

export const GET_LUCKY_STRIKE_GAMES_HISTORY = gql`
    query LuckyStrikeGamesHistory(
        $paginationInput: LuckyStrikeGameHistoryPaginationInput!
    ) {
        luckyStrikeGamesHistory(paginationInput: $paginationInput) {
            items {
                id
                iguAmountWei
                status
                title
                createdAt
            }
            meta {
                itemCount
                totalItems
                itemsPerPage
                totalPages
                currentPage
            }
        }
    }
`;

export const GET_LUCKY_STRIKE_GAME_HISTORY = gql`
    query LuckyStrikeGameHistory($gameId: String!) {
        luckyStrikeGameHistory(gameId: $gameId) {
            iguAmountWei
            title
            duration
            wonTicketNumber
            userTicketNumbers
            isUserWinner
        }
    }
`;
