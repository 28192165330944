import * as React from 'react';

import { StakingPlanOutput, UserStake } from '@Data/Models';
import Button from '@components/Button';
import ButtonsBottom from '@components/ButtonsBottom';
import CoinIcon from '@components/Icons/CoinIcon';
import { Text, View } from '@components/Themed';
import TimerWithCaptions from '@components/TimerWithCaptions';
import { StakingCoin, StakingOptionsType } from '@contexts/StakingContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { disableToUpgrade, getStakingPlanName } from '@helpers/staking';
import { dateFormatterYear } from '@helpers/time';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import StakingCardFeature from '../StakingCardFeature/StakingCardFeature';
import { useModalsHook } from './hooks/useModalsHook';
import { useStakeFinishHook } from './hooks/useStakeFinishHook';
import stylesMain from './styles';

interface IProps {
    data: UserStake;
    coin: StakingCoin;
    plans: StakingPlanOutput[];
    onUnstake: () => void;
    setOption: (option: StakingOptionsType | null) => void;
    onAddMoreCoins: (status: boolean) => void;
    currentBoost: number | null;
}

const StakingCardStakedAlready = ({
    data,
    coin,
    plans,
    onUnstake,
    setOption,
    onAddMoreCoins,
    currentBoost,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const navigation = useNavigation();

    const { stakingPlanId, amountWei, endDate, createdAt } = data;

    const {
        closeAddMoreCoinsModal,
        closeUpgradePlanModal,
        openAddMoreCoinsModal,
        openUpgradePlanModal,
        renderCoinsModal,
        renderUpgradeModal,
    } = useModalsHook();

    const { isStakeFinish, setStakeFinish } = useStakeFinishHook(endDate);

    const handleSetOption = React.useCallback(
        (status: boolean) => {
            if (coin === Coin.igup) {
                setOption(Coin.igup);
            }
            onAddMoreCoins(status);
        },
        [coin]
    );

    const disableUpgrade = React.useMemo(
        () => disableToUpgrade(stakingPlanId, plans),
        [plans, stakingPlanId]
    );

    const buttonMainTitle = React.useMemo(() => {
        if (isStakeFinish) {
            return i18n.t('staking.alreadyScreen.staked.buttonUnstake');
        }
        return i18n.t('staking.alreadyScreen.staked.buttonAdd');
    }, [isStakeFinish]);

    const buttonMainAction = React.useCallback(() => {
        if (isStakeFinish) {
            return onUnstake();
        }
        return openAddMoreCoinsModal();
    }, [isStakeFinish]);

    const handleOnAddInModal = React.useCallback(() => {
        handleSetOption(true);
        closeAddMoreCoinsModal();
        navigation.navigate(ROUTES.STAKING_COINS_INPUT);
    }, [handleSetOption]);

    const handleOnUpgradeInModal = React.useCallback(() => {
        handleSetOption(false);
        closeUpgradePlanModal();
        navigation.navigate(ROUTES.STAKING_PLANS);
    }, [handleSetOption]);

    return (
        <View style={styles.container}>
            <View style={styles.titleWrapper}>
                <CoinIcon slug={coin} size={32} />
                <View style={styles.titleTextWrapper}>
                    <Text style={styles.title}>
                        {i18n.t('staking.alreadyScreen.staked.title', {
                            coin,
                        })}
                    </Text>
                    <Text style={styles.text}>
                        {i18n.t('staking.alreadyScreen.staked.text')}
                    </Text>
                </View>
            </View>

            <View style={styles.timerWrapper}>
                <Text style={styles.timeTitle}>
                    {i18n.t('staking.alreadyScreen.staked.timerTitle')}
                </Text>
                <View style={styles.timerBlock}>
                    <TimerWithCaptions
                        endOfTime={endDate}
                        onEnd={setStakeFinish}
                    />
                </View>
            </View>

            <View style={styles.featuresWrapper}>
                <StakingCardFeature
                    title={i18n.t('staking.alreadyScreen.staked.staking')}
                    value={getStakingPlanName(stakingPlanId, plans)}
                />
                <StakingCardFeature
                    title={i18n.t('staking.alreadyScreen.staked.deposit')}
                    value={dateFormatterYear(createdAt)}
                />
                <StakingCardFeature
                    title={i18n.t('staking.alreadyScreen.staked.end')}
                    value={dateFormatterYear(endDate)}
                />
                <StakingCardFeature
                    title={i18n.t('staking.alreadyScreen.staked.boost')}
                    value={`${currentBoost || 0}%`}
                />
                <StakingCardFeature
                    title={i18n.t('staking.alreadyScreen.staked.summary')}
                    value={`${balanceFromWei(amountWei).valueLong} ${coin}`}
                />
            </View>
            <ButtonsBottom
                title={buttonMainTitle}
                onPress={buttonMainAction}
                containerStyle={styles.buttons}>
                <Button
                    debouncedPress
                    containerStyle={styles.gap}
                    title={i18n.t('staking.alreadyScreen.staked.buttonUpgrade')}
                    type="outline"
                    disabled={disableUpgrade}
                    onPress={openUpgradePlanModal}
                />
            </ButtonsBottom>

            {renderCoinsModal(handleOnAddInModal)}
            {renderUpgradeModal(handleOnUpgradeInModal)}
        </View>
    );
};
export default StakingCardStakedAlready;
