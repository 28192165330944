import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';

import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
        },
        navBg: {
            backgroundColor: Colors[theme].background,
        },
    });

export default styles;
