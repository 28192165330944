import * as React from 'react';
import { useCallback, useState } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import Carousel from '@components/Carousel';
import ReferralBanner from '@components/ReferralBanner';
import SpaceIdCard from '@components/SpaceIdCard';
import { View } from '@components/Themed';
import { useDimensions } from '@contexts/DimensionsContext';
import { scale } from '@helpers/dimensions';
import useThemedStyles from '@hooks/useThemedStyles';
import AsyncStorage from '@react-native-async-storage/async-storage';

import stylesMain from './styles';

interface IProps {
    containerStyle?: StyleProp<ViewStyle>;
    stakingBannerToHome?: boolean;
}

const enum CAROUSEL_DATA {
    SpaceID = 'SpaceID',
    Invite = 'Invite',
}

const DATA: CAROUSEL_DATA[] = [CAROUSEL_DATA.SpaceID, CAROUSEL_DATA.Invite];

const BannersCarousel = ({ containerStyle }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { windowWidth } = useDimensions();

    const itemWidth = React.useMemo(
        () => windowWidth - scale(20 * 2),
        [windowWidth]
    );

    React.useLayoutEffect(() => {
        const getSavedHeight = async () => {
            setHeight(
                Number(await AsyncStorage.getItem('BannersCarousel.height1'))
            );
        };
        getSavedHeight();
    }, []);

    const itemCustomWidth = itemWidth - scale(12);

    const [height, setHeight] = useState<number>(0);

    const getHeight = (height: number) => {
        setHeight((prev) => (prev < height ? height : prev));
        AsyncStorage.setItem('BannersCarousel.height1', height.toString());
    };

    const renderItem = useCallback(
        (item: CAROUSEL_DATA) => {
            switch (item) {
                case CAROUSEL_DATA.SpaceID:
                    return (
                        <SpaceIdCard
                            getHeight={getHeight}
                            minHeight={height}
                            itemWidth={itemCustomWidth}
                        />
                    );

                case CAROUSEL_DATA.Invite:
                    return (
                        <ReferralBanner
                            itemWidth={itemCustomWidth}
                            minHeight={height}
                            getHeight={getHeight}
                        />
                    );
                default:
                    return null;
            }
        },
        [itemWidth, itemCustomWidth, height]
    );

    return (
        <View style={containerStyle}>
            <Carousel
                data={DATA}
                sliderWidth={windowWidth}
                itemWidth={itemWidth}
                renderItem={renderItem}
                itemStyle={styles.itemStyle}
                height={height}
                pagination
                containerStyle={styles.carousel}
            />
        </View>
    );
};
export default BannersCarousel;
