import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { stylesAlignCenter } from '@helpers/style';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            width: 56,
            height: 56,
            backgroundColor: Colors[theme].background,
            ...stylesAlignCenter(false),
            borderRadius: 1000,
        },
    });

export default styles;
