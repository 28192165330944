import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        singleButton: {
            paddingHorizontal: scale(8),
            paddingVertical: verticalScale(8),
            maxWidth: scale(32),
            maxHeight: scale(32),
        },
        container: {
            position: 'relative',
            zIndex: 10000,
            backgroundColor: Colors[theme].surfaceDecorative_Blue_Strong,

            borderBottomRightRadius: moderateScale(32),
            borderBottomLeftRadius: moderateScale(32),
            overflow: 'hidden',
        },
        additionalBackGround: {
            position: 'relative',
            backgroundColor: 'transparent',
        },
        opacityBg: {
            backgroundColor: Colors[theme].fillPrimaryMinimal,
        },
        walletBlock: {
            alignItems: 'center',
            paddingHorizontal: scale(16),
            paddingBottom: verticalScale(16),
            flexDirection: 'row',
            backgroundColor: 'transparent',
        },
        innerContainer: {
            backgroundColor: 'transparent',
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        navigationBlock: {
            backgroundColor: 'transparent',
        },
        spaceBetween: {
            justifyContent: 'space-between',
        },
        containerFilled: {
            backgroundColor: Colors[theme].navigationBar.background,
        },
        buttonWrapper: {
            backgroundColor: 'transparent',
        },
        withChildren: {
            backgroundColor: Colors[theme].background,
        },
        buttonWrapperChildren: {
            backgroundColor: 'transparent',
        },
        emptyIcon: {
            width: scale(32),
            backgroundColor: 'transparent',
        },

        coinWrapper: {
            backgroundColor: 'transparent',
            flexDirection: 'row',
        },
        item: {
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: 'transparent',
        },
        text: {
            color: Colors[theme].alwaysOneColor.text,
            ...font({
                size: 12,
                height: 16,
                weight: '600',
            }),
            marginLeft: scale(4),
        },
        itemGap: {
            marginRight: scale(8),
        },
        itemPadding: {
            paddingHorizontal: scale(8),
            paddingVertical: verticalScale(6),
        },
        energyWrapper: {
            backgroundColor: 'transparent',
            flexDirection: 'row',
        },
        gap: {
            marginRight: scale(6),
        },
        coinsWrapper: {
            backgroundColor: 'transparent',
            flexDirection: 'row',
            flex: 1,
        },
        icon: {
            width: scale(20),
            height: scale(20),
        },

        extendedBlock: {
            marginTop: verticalScale(12),
            backgroundColor: 'transparent',
            borderBottomRightRadius: moderateScale(32),
            borderBottomLeftRadius: moderateScale(32),
            zIndex: 1,
        },
        headerTextBlock: {
            backgroundColor: 'transparent',
            marginHorizontal: scale(30),
        },
        textGap: {
            marginBottom: verticalScale(6),
        },
        buttonBlock: {
            marginHorizontal: scale(20),
            backgroundColor: 'transparent',
            marginBottom: verticalScale(24),
            marginTop: verticalScale(20),
        },
        button: {
            maxWidth: '80%',
            alignSelf: 'center',
            marginBottom: verticalScale(16),
        },
        howItWorks: {
            alignSelf: 'center',
        },
        howItWorksText: {
            color: Colors[theme].text.white,
        },
        flowIconBlock: {
            position: 'absolute',
            backgroundColor: 'transparent',

            bottom: 0,
            zIndex: 0,
        },
        iconRight: {
            right: 0,
        },
        iconLeft: {
            left: 0,
        },
    });

export default styles;
