import { StyleSheet } from 'react-native';

import Colors from '../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../helpers/dimensions';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
        },
        scrollView: {},
        petNameWrapper: {
            marginBottom: verticalScale(20),
            paddingHorizontal: scale(20),
        },
        petInfoContainer: {
            marginHorizontal: scale(20),
            borderWidth: 1,
            borderRadius: moderateScale(12),
            borderColor: Colors[theme].border.primary,
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(20),
        },
        imgWrapper: {
            position: 'absolute',
            alignSelf: 'flex-start',
            right: scale(16),
            top: verticalScale(16),
            backgroundColor: 'transparent',
        },
        image: {
            marginHorizontal: scale(20),
        },
        imageGap: {
            width: scale(20 * 2),
        },
    });

export default styles;
