import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { scale, verticalScale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        container: {
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(7),
            borderRadius: 50,

            marginRight: verticalScale(6),
            marginBottom: scale(8),
        },
        wrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: 'transparent',
        },
        noMargin: {
            marginRight: verticalScale(0),
        },
        icon: {
            backgroundColor: 'transparent',
        },
        text: {
            color: Colors[theme].text.primary,
            ...font({
                size: 13,
                height: 18,
                weight: '600',
            }),
            marginLeft: scale(6),
            backgroundColor: 'transparent',
        },
    });

export default styles;
