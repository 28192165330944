import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';

import { Text, View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';

import stylesMain from './styles';

interface IProps {
    title: string;
    amount: string;
    icon: React.ReactElement<SvgProps>;
    usd?: string;
    containerStyle?: StyleProp<ViewStyle>;
    onLight?: boolean;
}

const GamesStatsInfoCard = ({
    title,
    amount,
    icon,
    containerStyle,
    usd,
    onLight,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View
            style={[
                styles.container,
                onLight && styles.onLight,
                containerStyle,
            ]}>
            <View style={styles.imageWrapper}>{icon}</View>
            <Text style={[styles.amount, onLight && styles.onLightText]}>
                {amount}
            </Text>
            {!!usd && (
                <Text
                    style={[
                        styles.title,
                        onLight && styles.onLightTitle,
                    ]}>{`≈ ${usd} $`}</Text>
            )}
            <Text style={[styles.title, onLight && styles.onLightTitle]}>
                {title}
            </Text>
        </View>
    );
};
export default GamesStatsInfoCard;
