import * as React from 'react';

import ModalCommunity from '@components/ModalCommunity';
import { useUser } from '@contexts/UserContext';
import { isWeb } from '@helpers/app';
import { waitForModal } from '@helpers/helpers';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { useVisibleHook } from './useVisibleHook';

export const useCommunityModal = () => {
    const navigation = useNavigation();

    const { user, isNewUser } = useUser();

    const { open, close, isVisible } = useVisibleHook();

    const storageKey = React.useMemo(
        () => `useCommunityModal-${`${user?.id}`}}`,
        [user?.id]
    );

    const canShow = React.useCallback(async () => {
        const status = await AsyncStorage.getItem(storageKey);
        return !status;
    }, [storageKey]);

    const setShown = React.useCallback(async () => {
        await AsyncStorage.setItem(storageKey, 'shown');
    }, [storageKey]);

    const canOpenCommunityModal = React.useCallback(async () => {
        const result = await canShow();

        if (!result) {
            return false;
        }

        if (!isNewUser) {
            return false;
        }

        return true;
    }, [canShow, isNewUser]);

    const handleNavigate = () => {
        close();
        waitForModal().then(() =>
            navigation.navigate(ROUTES.PROFILE_ROOT, {
                screen: ROUTES.PROFILE_SOCIAL_MEDIA,
            })
        );
    };

    const handleSave = React.useCallback(() => {
        setShown();
        close();
        waitForModal().then(() => navigation.navigateToTasks());
    }, [setShown]);

    const handleClose = () => {
        close();
        waitForModal().then(() => navigation.navigateToTasks());
    };

    const renderCommunityModal = React.useCallback(() => {
        return (
            <ModalCommunity
                isVisible={isVisible}
                onClose={handleClose}
                onSave={handleSave}
                onPress={handleNavigate}
            />
        );
    }, [isVisible, isWeb, handleSave]);

    return { renderCommunityModal, canOpenCommunityModal, open };
};
