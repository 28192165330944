import * as React from 'react';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

import { Text, View } from '../../../../components/Themed';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import stylesMain from './styles';

interface IProps {
    title: string;
    text: string;
    containerStyle?: StyleProp<ViewStyle>;
    titleStyle?: StyleProp<TextStyle>;
    textStyle?: StyleProp<TextStyle>;
}

const MintHeaderText = ({
    title,
    text,
    containerStyle,
    titleStyle,
    textStyle,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={[styles.textWrapper, containerStyle]}>
            <Text style={[styles.title, titleStyle]}>{title}</Text>
            <Text style={[styles.text, textStyle]}>{text}</Text>
        </View>
    );
};
export default MintHeaderText;
