import * as React from 'react';
import { useCallback } from 'react';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import EmptyList from '@components/EmptyList';
import LoadingComponent from '@components/LoadingComponent';
import ModalLoader from '@components/ModalLoader';
import PullToRefresh from '@components/PullToRefresh';
import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationMysteryLikes, {
    NAVIGATION_MYSTERY_LIKES,
} from '@navigation/NavigationBar/NavigationMysteryLikes';

import MysteryGamesHistoryItemHandler from '../../components/MysteryGamesHistoryItemHandler';
import MysteryLikesHeader from '../../components/MysteryLikesHeader';
import useMysteryLikesHistoryScreen from './hooks/useMysteryLikesHistoryScreen';
import stylesMain from './styles';

const SCREEN = 'MysteryLikesHistory';

const MysteryLikesHistoryScreen = () => {
    const styles = useThemedStyles(stylesMain);

    const {
        isSmallLayout,
        getGamesHistoryLoadMore,
        loadMore,
        createGameLoading,
        gamesHistory,
        historyLoading,
        onHistoryItemPress,
        refreshLoading,
        renderPreviousGameModal,
        handleCreateGame,
        getMysteryLikesGameHistoryLoading,
        handleRefresh,
        canLoadMore,
    } = useMysteryLikesHistoryScreen();

    const headerComponent = () => {
        return (
            <BlockHeader
                containerStyle={styles.header}
                type="primary"
                title={i18n.t('HomeScreen.GameHistoryScreen.title')}
                text={i18n.t('HomeScreen.GameHistoryScreen.text')}
                iconDirection="right"
            />
        );
    };

    const emptyComponent = useCallback(() => {
        return (
            <View
                style={
                    isSmallLayout
                        ? styles.emptyStateWrapperSmall
                        : styles.emptyStateWrapper
                }>
                <EmptyList
                    containerStyle={styles.emptyList}
                    emptyTitle={i18n.t('HomeScreen.EmptyGameHistory.title')}
                    emptyText={i18n.t('HomeScreen.EmptyGameHistory.text')}
                    emptyButtonText={i18n.t('general.BUTTONS.createNewGame')}
                    withAnimation="cry"
                    onPressButton={handleCreateGame}
                    loading={createGameLoading}
                    disabled={createGameLoading}
                />
            </View>
        );
    }, [isSmallLayout, createGameLoading, handleCreateGame]);

    return (
        <View style={styles.main}>
            <MysteryLikesHeader />

            <ModalLoader
                isVisible={
                    createGameLoading || getMysteryLikesGameHistoryLoading
                }
            />
            <NavigationMysteryLikes
                currentNavigation={NAVIGATION_MYSTERY_LIKES.HISTORY}
            />
            <View style={styles.content}>
                {historyLoading ? (
                    <LoadingComponent text={i18n.t('general.loading')} />
                ) : (
                    <PullToRefresh
                        refreshing={refreshLoading}
                        textLoading={i18n.t('pullToRefresh.loading')}
                        onRefresh={handleRefresh}
                        EmptyComponent={emptyComponent()}
                        renderData={gamesHistory || []}
                        HeaderComponent={headerComponent()}
                        loadMore={loadMore}
                        onReachEnd={getGamesHistoryLoadMore}
                        //@ts-ignore ignore item type any
                        renderItem={({ item }) => (
                            <MysteryGamesHistoryItemHandler
                                action={onHistoryItemPress}
                                key={item.id}
                                item={item}
                            />
                        )}
                        canLoadMore={canLoadMore}
                        shouldUseEmptyComponentInFooter
                    />
                )}
            </View>
            {renderPreviousGameModal()}
        </View>
    );
};

export default MysteryLikesHistoryScreen;
