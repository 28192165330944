import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import Task from '@assets/icons/general/task.svg';
import Icon, { ICON_NAMES } from '@components/Icons';
import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { RankingRow, RankingStats } from '@models/leaderboard';
import ROUTES from '@navigation/routes';

import DailyTasksBoard from '../DailyTasksBoard';
import TotalCard from '../TotalCard';
import stylesMain from './styles';

interface IProps {
    rankingStat: RankingStats;
    topPlayers: RankingRow[];
    userAddress: string | undefined;
    containerStyle?: StyleProp<ViewStyle>;
}
const screen = ROUTES.LEADERBOARD_DAILY_TASKS;
const DailyTasksStatistics = ({
    rankingStat,
    topPlayers,
    userAddress,
    containerStyle,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={[styles.container, containerStyle]}>
            <View style={styles.cardWrapper}>
                <TotalCard
                    containerStyle={styles.cardWidth}
                    title={i18n.t(`Leaderboard.${screen}.energy`)}
                    amount={rankingStat?.totalEnergyRewarded || 0}
                    icon={<Icon name={ICON_NAMES.ENERGY} size={32} />}
                />
                <TotalCard
                    containerStyle={styles.cardWidth}
                    title={i18n.t(`Leaderboard.${screen}.task`)}
                    amount={rankingStat?.totalTasksCount || 0}
                    icon={<Task width={32} height={32} />}
                />
            </View>
            {!!topPlayers && (
                <DailyTasksBoard
                    topPlayers={topPlayers}
                    userAddress={userAddress || ''}
                />
            )}
        </View>
    );
};
export default DailyTasksStatistics;
