import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import { scale, verticalScale } from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        wrapper: {
            marginTop: verticalScale(12),
            flexDirection: 'row',
            alignItems: 'center',
        },
        item: {
            alignItems: 'center',
            flexDirection: 'row',
        },
        text: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
            marginLeft: scale(4),
        },
    });

export default styles;
