import * as React from 'react';
import { KeyboardAvoidingView, ScrollView, TextInput } from 'react-native';

import { LootboxSaleOption } from '../../../../Data/Models';
import AmountButton from '../../../../components/AmountButton';
import BlockHeader from '../../../../components/BlockHeader/BlockHeader';
import ButtonsBottom from '../../../../components/ButtonsBottom';
import { ICON_NAMES } from '../../../../components/Icons';
import Input from '../../../../components/Input';
import { Text, View } from '../../../../components/Themed';
import ToastNotification from '../../../../components/ToastNotification';
import { isIOS } from '../../../../helpers/app';
import { waitForNavigation } from '../../../../helpers/helpers';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import NavigationBarModal from '../../../../navigation/NavigationBar/NavigationBarModal';
import useNavigationListeners from '../../../../navigation/hooks/useNavigationListeners';
import ROUTES from '../../../../navigation/routes';
import { useNavigation } from '../../../../navigation/useNavigation';
import { MysteryBoxPurchaseProps } from '../../../../types';
import stylesMain from './styles';

const MysteryBoxesPurchaseScreen = ({ route }: MysteryBoxPurchaseProps) => {
    useNavigationListeners();
    const inputRef = React.useRef<TextInput>(null);
    React.useEffect(() => {
        inputRef.current?.focus();
    }, []);

    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const [option, setOption] = React.useState<LootboxSaleOption | null>(
        route.params.selectedOption
    );
    const [prevOption, setPrevOption] =
        React.useState<LootboxSaleOption | null>(null);
    const [errorText, setErrorText] = React.useState('');

    const buttonId = React.useMemo(() => {
        return option?.count.toString() ?? '';
    }, [option]);

    const { allAvailableOptions } = route.params;
    const sortedValues = allAvailableOptions
        .map((o) => o.count)
        .sort((a, b) => a - b);
    const minValue = sortedValues[0];
    const maxValue = sortedValues[sortedValues.length - 1];

    const detailedOptions = React.useMemo(() => {
        if (allAvailableOptions.length === 10) {
            const quantities = [1, 2, 5, 7, 9, 10];
            return allAvailableOptions.filter(
                (option) => quantities.filter((q) => q === option.count).length
            );
        } else {
            return allAvailableOptions;
        }
    }, [allAvailableOptions]);

    const presentInput = allAvailableOptions.length !== detailedOptions.length;

    const handleChangeAmount = React.useCallback(
        (option: LootboxSaleOption | undefined, value: number) => {
            setErrorText('');

            if (!option) {
                if (value > maxValue) {
                    setErrorText(
                        i18n.t('mysteryBoxes.purchaseScreen.errors.max')
                    );
                    setOption(null);
                }
                if (value < minValue) {
                    setErrorText(
                        i18n.t('mysteryBoxes.purchaseScreen.errors.min')
                    );
                    setOption(null);
                }
                return;
            }

            if (!option.count) {
                setErrorText(
                    i18n.t('mysteryBoxes.purchaseScreen.errors.noSymbols')
                );
                return;
            }
            setPrevOption(option);
            setOption(option);
        },
        [prevOption]
    );

    const handleClickOnAmount = React.useCallback(
        (option: LootboxSaleOption) => {
            setErrorText('');
            if (buttonId === option.count.toString()) {
                return;
            }
            setOption(option);
            setPrevOption(option);
        },
        [buttonId]
    );

    const handleOnCheckout = async () => {
        if (!option) return;

        navigation.pop();
        await waitForNavigation();
        navigation.navigate(ROUTES.MYSTERY_BOX_PURCHASE_CHECKOUT, {
            selectedOption: option,
            allAvailableOptions,
        });
    };

    return (
        <KeyboardAvoidingView
            behavior={isIOS ? 'padding' : 'height'}
            style={styles.container}>
            <View style={styles.main}>
                <View style={styles.header}>
                    <NavigationBarModal
                        iconName={ICON_NAMES.CLOSE}
                        title=""
                        onBack={() => navigation.pop()}
                    />
                </View>

                <ScrollView
                    keyboardShouldPersistTaps="always"
                    showsVerticalScrollIndicator={false}
                    style={styles.list}>
                    <BlockHeader
                        type="primary"
                        title={i18n.t('mysteryBoxes.purchaseScreen.title')}
                        text={i18n.t('mysteryBoxes.purchaseScreen.text')}
                    />
                    <View style={styles.inputWrapper}>
                        <View style={styles.inputZone}>
                            {presentInput && (
                                <Input
                                    forwardedRef={inputRef}
                                    style={styles.inputContainer}
                                    value={option?.count.toString()}
                                    keyboardType="numeric"
                                    onChangeText={(amount) => {
                                        const option = allAvailableOptions.find(
                                            (option) => {
                                                return (
                                                    option.count ===
                                                    Number(amount)
                                                );
                                            }
                                        );
                                        handleChangeAmount(
                                            option,
                                            Number(amount)
                                        );
                                    }}
                                    maxLength={2}
                                    isAvailableExternalStyle
                                />
                            )}
                            <Text
                                style={[
                                    styles.label,
                                    !!errorText && styles.error,
                                ]}>
                                {errorText
                                    ? errorText
                                    : i18n.t(
                                          `mysteryBoxes.purchaseScreen.${
                                              presentInput
                                                  ? 'label'
                                                  : 'labelNoInput'
                                          }`
                                      )}
                            </Text>
                        </View>
                        <View style={styles.buttonZone}>
                            {detailedOptions.map((option) => (
                                <AmountButton
                                    key={option.count.toString()}
                                    active={
                                        buttonId === option.count.toString()
                                    }
                                    text={option.count.toString()}
                                    onPress={() => handleClickOnAmount(option)}
                                />
                            ))}
                        </View>
                    </View>
                </ScrollView>
                <ButtonsBottom
                    containerStyle={styles.buttonContainer}
                    title={i18n.t('mysteryBoxes.purchaseScreen.button')}
                    disabled={!option || !!errorText}
                    onPress={handleOnCheckout}
                    safe
                />
            </View>
            <ToastNotification />
        </KeyboardAvoidingView>
    );
};

export default MysteryBoxesPurchaseScreen;
