import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import Colors from '../../../constants/Colors';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        mainContainer: {
            flex: 1,
            backgroundColor: Colors[theme].background,
        },
        paddingHorizontal: {
            paddingHorizontal: scale(20),
        },
        content: {},
        selectLangWrapper: {
            marginTop: verticalScale(12),
            paddingHorizontal: scale(20),
        },
        inputWrapper: {
            paddingHorizontal: scale(20),
            marginTop: verticalScale(20),
        },
        divider: {
            width: '100%',
        },
        buttons: {
            marginBottom: verticalScale(16),
        },
    });

export default styles;
