import * as React from 'react';
import { ImageBackground, TouchableOpacity, View } from 'react-native';

import ActivityIndicator from '@components/ActivityIndicator';
import Button from '@components/Button';
import { ICON_NAMES } from '@components/Icons';
import ModalNFTPreview from '@components/ModalNFTPreview';
import { isWeb } from '@helpers/app';
import { useLoadingHook } from '@hooks/useLoadingHook';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    image: string | null;
    id: string;
    selectedImage: string | undefined;
    onSelectImage: (id: string) => void;
}

const MintGeneratedImage = ({
    image,
    id,
    selectedImage,
    onSelectImage,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const isSelected = React.useMemo(
        () => selectedImage === id,
        [id, selectedImage]
    );

    const [imageSize, setImageSize] = React.useState(0);
    const [isModalVisible, setIsModalVisible] = React.useState(false);

    const handleOpenModal = () => {
        setIsModalVisible(true);
    };
    const handleCloseModal = () => {
        setIsModalVisible(false);
    };

    const { isLoading, startLoading, stopLoading } = useLoadingHook(!isWeb);

    return (
        <TouchableOpacity
            activeOpacity={0.7}
            style={[styles.shadow, isSelected && styles.selectedShadow]}
            onPress={() => onSelectImage(id)}>
            <View style={[styles.container, isSelected && styles.selected]}>
                {isLoading && (
                    <View style={styles.loadingWrapper}>
                        <ActivityIndicator
                            loading={true}
                            size="large"
                            containerStyle={styles.loading}
                        />
                    </View>
                )}
                {!!image && (
                    <ImageBackground
                        borderRadius={6}
                        resizeMode="contain"
                        source={{ uri: image }}
                        style={{ height: imageSize }}
                        onLoadStart={!isWeb ? startLoading : undefined}
                        onLoad={!isWeb ? stopLoading : undefined}
                        onLayout={(event) =>
                            setImageSize(event.nativeEvent.layout.width)
                        }>
                        <View style={styles.imgWrapper}>
                            <Button
                                onPress={handleOpenModal}
                                type="outline"
                                size="md"
                                iconName={ICON_NAMES.FULL_SIZE}
                            />
                        </View>
                    </ImageBackground>
                )}
            </View>
            <ModalNFTPreview
                isVisible={isModalVisible}
                image={image}
                button={
                    <Button
                        onPress={() => {
                            onSelectImage(id);
                            handleCloseModal();
                        }}
                        title={i18n.t('nftSelectGeneration.select')}
                    />
                }
                onClose={handleCloseModal}
            />
        </TouchableOpacity>
    );
};
export default MintGeneratedImage;
