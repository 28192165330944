enum ROUTES {
    /**
     * BottomTab
     */
    MAIN = 'Main',
    PLAY = 'Play',
    CHARITY = 'Charity',
    COLLECTION = 'Collection',
    MARKETPLACE = 'Marketplace',
    FEED = 'Feed',
    HOME = 'Home',

    //MysteryLikes
    MYSTERY_LIKES_MAIN = 'MysteryLikesMain',
    HOW_MYSTERY_LIKES_WORK = 'HowMysteryLikesWork',
    WELCOME_HOW_MYSTERY_LIKES_WORK = 'WelcomeHowMysteryLikesWork',
    MYSTERY_LIKES_ACTIVE_ORDERS = 'MysteryLikesActiveOrder',
    MYSTERY_LIKES_HISTORY = 'MysteryLikesHistory',
    MYSTERY_LIKES_CREATE_GAME_WELCOME = 'MysteryLikesCreateGameWelcome',
    MYSTERY_LIKES_CREATE_GAME_INPUT = 'MysteryLikesCreateGameInput',
    MYSTERY_LIKES_CREATE_GAME_SUCCESS = 'MysteryLikesCreateGameSuccess',
    MYSTERY_LIKES_SELECT_RANGE = 'MysteryLikesSelectRange',
    MYSTERY_LIKES_SELECT_RANGE_FINISH = 'MysteryLikesSelectRangeFinish',

    //NFT Evolution
    HOW_NFT_EVOLUTION_WELCOME = 'HowNftEvolutionWelcome',
    NFT_EVOLUTION_WELCOME = 'NftEvolutionWelcome',
    NFT_EVOLUTION_SELECT_PETS = 'NftEvolutionSelectPets',
    NFT_EVOLUTION_CHECKOUT = 'NftEvolutionCheckout',
    NFT_EVOLUTION_SUCCESS = 'NftEvolutionSuccess',
    NFT_EVOLUTION_SELECT_PET = 'NftEvolutionSelectPet',
    NFT_EVOLUTION_MINT_PET = 'NftEvolutionMintPet',
    NFT_EVOLUTION_MINT_PET_SUCCESS = 'NftEvolutionMintPetSuccess',

    //NFT_ROYAL

    HOW_NFT_ROYAL_WORK = 'HowNftRoyalWork',
    WELCOME_HOW_NFT_ROYAL_WORK = 'WelcomeNftRoyalWork',
    NFT_ROYAL_MAIN = 'NftRoyalMain',
    NFT_ROYAL_ACTIVE_ORDERS = 'NftRoyalActiveOrder',
    NFT_ROYAL_CREATE_GAME_WELCOME = 'NftRoyalCreateGameWelcome',
    NFT_ROYAL_GAME_CONFIGURATIONS = 'NftRoyalGameConfigurations',
    NFT_ROYAL_ENTER_COINS_AMOUNT = 'NftRoyalEnterCoinsAmount',
    NFT_ROYAL_CREATE_GAME_SUCCESS = 'NftRoyalCreateGameSuccess',
    NFT_ROYAL_HISTORY = 'NftRoyalHistory',
    NFT_ROYAL_PLAY_GAME = 'NftRoyalPlayGame',
    NFT_ROYAL_GAME_HISTORY = 'NftRoyalGameHistory',

    //LuckyStrike

    WELCOME_LUCKY_STRIKE = 'WelcomeLuckyStrike',
    LUCKY_STRIKE = 'LuckyStrike',
    LUCKY_STRIKE_MAIN = 'LuckyStrikeMain',
    LUCKY_STRIKE_HISTORY = 'LuckyStrikeHistory',
    HOW_LUCKY_STRIKE_WORK = 'HowLuckyStrikeWork',
    LUCKY_STRIKE_ALL_PARTICIPANTS = 'LuckyStrikeAllParticipants',
    LUCKY_STRIKE_WAITING_FOR_RESULT = 'LuckyStrikeWaitingForResult',
    LUCKY_STRIKE_USER_TICKETS = 'LuckyStrikeUserTickets',
    LUCKY_STRIKE_USER_TICKETS_WAIT = 'LuckyStrikeUserTicketsWait',
    LUCKY_STRIKE_ALL_WINNERS = 'LuckyStrikeAllWinners',
    LUCKY_STRIKE_GAME_HISTORY = 'LuckyStrikeGameHistory',

    //Quantum Spin

    WELCOME_QUANTUM_SPIN = 'WelcomeQuantumSpin',
    QUANTUM_SPIN = 'QuantumSpin',
    QUANTUM_SPIN_MAIN = 'QuantumSpinMain',
    QUANTUM_SPIN_HISTORY = 'QuantumSpinHistory',
    HOW_QUANTUM_SPIN_WORK = 'HowQuantumSpinWork',
    QUANTUM_SPIN_GAME_HISTORY = 'QuantumSpinGameHistory',
    QUANTUM_SPIN_PROBABLY_FAIR = 'QuantumSpinProbablyFair',
    QUANTUM_SPIN_PROBABLY_FAIR_HISTORY = 'QuantumSpinProbablyFairHistory',

    //TokenTeller
    WELCOME_TOKEN_TELLER = 'WelcomeTokenTeller',
    TOKEN_TELLER = 'TokenTeller',
    TOKEN_TELLER_MAIN = 'TokenTellerMain',
    TOKEN_TELLER_HISTORY = 'TokenTellerHistory',
    HOW_TOKEN_TELLER_WORK = 'HowTokenTellerWork',
    TOKEN_TELLER_CHART = 'TokenTellerChart',
    TOKEN_TELLER_ALL_PARTICIPANTS = 'TokenTellerAllParticipants',
    TOKEN_TELLER_WAITING_FOR_RESULT = 'TokenTellerWaitingForResult',
    TOKEN_TELLER_GAME_HISTORY = 'TokenTellerGameHistory',

    /**
     * Admin
     */
    ADMIN_ROOT = 'AdminRoot',
    ADMIN_VERIFY = 'AdminVerify',
    ADMIN_SEND_PUSH_NOTIFICATION = 'AdminSendPushNotification',
    ADMIN_REVERIFICATION = 'AdminReverification',
    ADMIN_BLOCK_USER = 'AdminBlockUser',
    ADMIN_MAINTENANCE_MODE = 'AdminMaintenanceMode',
    ADMIN_ASSIGN_WHITE_LIST = 'AdminAssignWhiteList',
    ADMIN_ASSIGN_ROLE = 'AdminAssignRole',
    ADMIN_NFT_ROYAL_REVOKE_BAN = 'AdminNftRoyaleRevokeBan',

    /**
     * Mint
     */
    MINT_ROOT = 'MintRoot',
    MINT_PET_RANKS = 'MintPetRanks',
    MINT_PET_SELECT_GENERATION = 'MintPetSelectGeneration',
    MINT_GENERATION_INFO = 'MintGenerationInfo',
    MINT_SELECT_GENERATED_NFT = 'MintSelectGeneratedNft',
    MINT_PET_PICK_PHOTO = 'MintPetPickPhoto',
    MINT_PET_EDIT_PHOTO = 'MintPetEditPhoto',
    MINT_PET_NAME = 'MintPetName',
    MINT_PET_CHECKOUT = 'MintPetCheckout',
    MINT_PET_CHECKOUT_SUCCESS = 'MintPetCheckoutSuccess',

    /**
     * CreateWallet
     */
    ADD_WALLET_INITIAL = 'AddWalletInitial',
    IMPORT_WALLET = 'ImportWallet',
    CREATE_WALLET_FIRST = 'CreateWalletFirst',
    CREATE_WALLET_PASSPHRASE = 'CreateWalletPassphrase',
    CREATE_WALLET_VERIFICATION = 'CreateWalletVerification',
    CREATE_WALLET_SUCCESS = 'CreateWalletSuccess',
    /**
     * LOGIN
     */
    LOGIN = 'Login',
    /**
     * Wallet
     */
    WALLET_ROOT = 'WalletRoot',
    // Tabs
    WALLET_COINS_MAIN = 'WalletCoinsMain',
    WALLET_NFTS_MAIN = 'WalletNFTSMain',
    WALLET_REWARDS_TAB = 'WalletRewardsTab',
    // Coins
    WALLET_COINS_BALANCE = 'WalletCoinsBalance',
    WALLET_COINS_WITHDRAW = 'WalletCoinsWithdraw',
    WALLET_COINS_WITHDRAW_SUCCESS = 'WalletCoinsWithdrawSuccess',
    WALLET_COINS_TRANSACTION_DETAILS = 'WalletCoinsTransactionDetails',
    // NFT
    WALLET_NFTS_LIST = 'WalletNFTSList',
    WALLET_NFTS_WITHDRAW = 'WalletNFTSWithdraw',
    WALLET_NFTS_WITHDRAW_SUCCESS = 'WalletNFTSWithdrawSuccess',
    WALLET_NFTS_TRANSACTION_DETAILS = 'WalletNFTSTransactionDetails',
    // Rewards
    WALLET_REWARDS_MAIN = 'WalletRewardsMain',
    WALLET_REWARDS_CLAIM_SUCCESS = 'WalletRewardsClaimSuccess',
    WALLET_REWARDS_TRANSACTION_DETAILS = 'WalletRewardsTransactionDetails',

    // Gaming
    WALLET_GAMING_TAB = 'WalletGamingTab',
    WALLET_GAMING_MAIN = 'WalletGamingMain',
    TOP_UP_GAMING_WALLET = 'TopUpGamingWallet',
    TOP_UP_CHECKOUT = 'TopUpCheckoutScreen',
    TOP_UP_SUCCESS = 'TopUpSuccessScreen',
    WITHDRAW_IGU_SUCCESS = 'WithdrawIguSuccessScreen',
    WITHDRAW_INPUT_AMOUNT = 'WithdrawInputAmount',
    GAMING_WALLET_TRANSACTION_DETAILS = 'GamingWalletTransactionDetails',

    // Trade
    WALLET_TRADE_MAIN = 'WalletTradeMain',
    WALLET_TRADE_CHART = 'WalletTradeChart',
    WALLET_TRADE_SUCCESS = 'WalletTradeSuccess',
    // Settings
    WALLET_SETTINGS = 'WalletSettings',
    WALLET_SETTINGS_BACKUP = 'WalletSettingsBackup',
    // Staking
    STAKING_WELCOME = 'StakingWelcome',
    STAKING_OPTIONS = 'StakingOptions',
    STAKING_PLANS = 'StakingPlans',
    STAKING_DETAILED_INFO = 'StakingDetailedInfo',
    STAKING_COINS_INPUT = 'StakingCoinsInput',
    STAKING_DAILY_BOOST = 'StakingDailyBoost',
    STAKING_SUCCESS = 'StakingSuccess',
    STAKING_ALREADY = 'StakingAlready',

    UN_STAKING_SUCCESS = 'UnStakingSuccess',

    /**
     * Play
     */
    PLAY_TASKS = 'PlayTasks',
    PLAY_TASKS_ACTIVE = 'PlayTasksActive',
    PLAY_TASKS_HISTORY = 'PlayTasksHistory',
    PLAY_EDIT_NFTS = 'PlayEditNFTs',
    PLAY_TASKS_HISTORY_DETAILS = 'PlayTasksHistoryDetails',
    PLAY_COLLECTED_EXPERIENCE = 'PlayCollectedExperience',
    PLAY_TASK_SUCCESS = 'PlaySuccessScreen',
    /**
     * Socialize To Earn
     */
    SOCIALIZE_TO_EARN_WELCOME_SHARE = 'SocializeToEarnWelcomeShare',
    SOCIALIZE_TO_EARN_SHARE_YOUR_PHOTO = 'SocializeToEarnShareYourPhoto',
    SOCIALIZE_TO_EARN_CREATE_SHARE_PHOTO = 'SocializeToEarnCreateSharePhoto',
    SOCIALIZE_TO_EARN_VERIFY_USER = 'SocializeToEarnVerifyUser',
    SOCIALIZE_TO_EARN_CREATE_SHARE_TEMPLATE = 'SocializeToEarnCreateShareTemplate',

    SOCIALIZE_TO_EARN_VERIFY_USER_ERROR = 'SocializeToEarnVerifyUserError',
    SOCIALIZE_TO_EARN_SHARE_YOUR_PHOTO_SUCCESS = 'SocializeToEarnShareYourPhotoSuccess',
    SOCIALIZE_TO_EARN_WELCOME_VERIFY = 'SocializeToEarnWelcomeVerify',
    SOCIALIZE_TO_EARN_VERIFY_USER_MATCH = 'SocializeToEarnVerifyUserMatch',
    SOCIALIZE_TO_EARN_VERIFY_USER_NOT_FOUND = 'SocializeToEarnVerifyUserNotFound',
    SOCIALIZE_TO_EARN_VERIFY_USER_FAILED = 'SocializeToEarnVerifyUserFailed',
    SOCIALIZE_TO_EARN_VERIFY_USER_ALREADY_COMPLETED = 'SocializeToEarnVerifyUserAlreadyCompleted',

    /**
     * Move To Earn
     */
    MOVE_TO_EARN_WELCOME = 'MoveToEarnWelcome',
    MOVE_TO_EARN_PROGRESS = 'MoveToEarnProgress',
    MOVE_TO_EARN_SUMMARY = 'MoveToEarnSummary',
    /**
     * Play to Earn
     */
    PLAY_TO_EARN = 'PlayToEarn',
    PLAY_TO_EARN_WELCOME = 'PlayToEarnWelcome',
    PLAY_TO_EARN_PROGRESS = 'PlayToEarnProgress',
    /**
     * Charity
     */
    CHARITY_ORGANIZATIONS = 'CharityOrganizations',
    CHARITY_DETAILED_ORGANIZATION = 'CharityDetailedOrganization',
    CHARITY_ORGANIZATION_DONATIONS = 'CharityOrganizationDonations',
    CHARITY_EDIT_SELECTED_ORGANIZATION = 'CharityEditSelectedOrganization',
    CHARITY_EDIT_DETAILED_ORGANIZATION = 'CharityEditDetailedOrganization',

    /**
     * Collection
     */
    COLLECTION_MAIN = 'CollectionMain',
    COLLECTION_DETAILED_PET = 'CollectionDetailedPet',
    COLLECTION_PET_LEVEL_UP_CHECKOUT = 'CollectionPetLevelUpCheckout',
    COLLECTION_PET_LEVEL_UP_SUCCESS = 'CollectionPetLevelUpSuccess',
    COLLECTION_PET_RANK_UP_CHECKOUT = 'CollectionPetRankUpCheckout',
    COLLECTION_PET_RANK_UP_SUCCESS = 'CollectionPetRankUpSuccess',
    COLLECTION_PET_HEAL_UP_CHECKOUT = 'CollectionPetHealUpCheckout',
    COLLECTION_PET_HEAL_UP_SUCCESS = 'CollectionPetHealUpSuccess',
    COLLECTION_MULTIPLE_PETS_HEAL_UP_CHECKOUT = 'CollectionMultiplePetsHealUpCheckout',
    COLLECTION_MULTIPLE_PETS_HEAL_UP_PROCEEDING = 'CollectionMultiplePetsHealUpProceeding',
    COLLECTION_MULTIPLE_PETS_HEAL_UP_SUCCESS = 'CollectionMultiplePetsHealUpSuccess',
    COLLECTION_PET_REVIVE_CHECKOUT = 'CollectionPetReviveCheckout',
    COLLECTION_PET_REVIVE_SUCCESS = 'CollectionPetReviveSuccess',
    COLLECTION_PET_AGE_REVIVE_CHECKOUT = 'CollectionPetAgeReviveCheckout',
    COLLECTION_PET_AGE_REVIVE_SUCCESS = 'CollectionPetAgeReviveSuccess',
    /**
     * Profile
     */
    PROFILE_ROOT = 'ProfileRoot',
    PROFILE_MAIN = 'ProfileMain',
    PROFILE_LANGUAGES_SETTINGS = 'ProfileLanguagesSettings',
    PROFILE_NOTIFICATIONS_SETTINGS = 'ProfileNotificationsSettings',
    PROFILE_SOCIAL_ACCOUNTS_SETTINGS = 'ProfileSocialAccountsSettings',
    PROFILE_ACCESSIBILITY_SETTINGS = 'ProfileSocialAccessibilitySettings',
    PROFILE_SOCIAL_MEDIA = 'ProfileSocialMedia',

    /**
     * PassCode
     */
    PASSCODE_WELCOME = 'PassCodeWelcome',
    PASSCODE_FACEID = 'PassCodeFaceId',
    PASSCODE_NEW = 'PassCodeNew',
    PASSCODE_CHANGE = 'PassCodeChange',
    PASSCODE_CONFIRM = 'PassCodeConfirm',
    PASSCODE_SUCCESS = 'PassCodeSuccess',
    PASSCODE_SETTINGS = 'PassCodeSettings',

    /**
     * Others
     */
    DEV = 'Dev',
    RANKS_DETAILED_INFO = 'RanksDetailedInfo',
    ENERGY_INFO_SCREEN = 'EnergyInfoScreen',

    // REFERRALS

    REFERRALS = 'Referrals',
    REFERRALS_ALL = 'ReferralsAll',
    REFERRALS_INVITES = 'ReferralsInvites',
    REFERRALS_INVITE = 'ReferralsInvite',

    // LEADERBOARD

    LEADERBOARD = 'Leaderboard',
    LEADERBOARD_DAILY_TASKS = 'LeaderboardDailyTasks',
    LEADERBOARD_PVP_GAMES = 'LeaderboardPvpGames',

    //Mystery Box
    MYSTERY_BOX_MAIN = 'MysteryBoxMain',
    MYSTERY_BOX_PURCHASE_WELCOME = 'MysteryBoxPurchaseWelcome',
    MYSTERY_BOX_PURCHASE = 'MysteryBoxPurchase',
    MYSTERY_BOX_PURCHASE_CHECKOUT = 'MysteryBoxPurchaseCheckout',
    MYSTERY_BOX_PURCHASE_SUCCESS = 'MysteryBoxPurchaseSuccess',

    // HOW TO
    HOW_TO_CHARITY = 'HowToCharity',
    HOW_TO_FEE = 'HowToFee',
    HOW_TO_PLAY = 'HowToPlay',
    HOW_TO_REFERRALS = 'HowToReferrals',
    HOW_TO_AVAILABLE_REWARDS = 'HowToAvailableRewards',
    HOW_TO_LOCKED_REWARDS = 'HowToLockedRewards',
    HOW_TO_SOCIALIZE_TO_EARN = 'HowToSocializeToEarn',
    HOW_TO_VERIFY_USER = 'HowToVerifyUser',
    HOW_TO_PURCHASE_BNB = 'HowToPurchaseBNB',
    HOW_TO_GAMING_AVAILABLE_WALLET = 'HowToGamingAvailableWallet',
    HOW_TO_GAMING_PENDING_WALLET = 'HowToGamingPendingWallet',
    HOW_COMMISSION_FEE = 'HowToCommissionFee',
    HOW_TO_PURCHASE_SPACE_ID = 'HowToPurchaseSpadeId',
    HOW_TO_COLLECTIONS = 'HowToCollections',

    // ONBOARDING
    MAIN_WALLET_ONBOARDING_SCREEN = 'MainWalletOnBoardingScreen',
    LOGIN_ONBOARDING_SCREEN = 'LoginOnBoardingScreen',

    // BONUS MINT RANK

    BONUS_MINT_RANKS_INFO = 'BonusMintRanksInfo',
    BONUS_MINT_RANKS_CAROUSEL = 'BonusMintRanksCarousel',

    // Initial Screens
    INITIAL_MINT = 'InitialMint',
    INITIAL_REFERRAL = 'InitialReferral',

    // Global
    YOUR_PETS_ARE_HUNGRY = 'YourPetsAreHungry',
    UPDATE_APP = 'UpdateApp',
    ACHIEVEMENTS = 'Achievements',
    ACHIEVEMENTS_DETAILS = 'AchievementsDetails',

    //MarketPlace
    MARKETPLACE_ACTIVE_ORDERS = 'MarketPlaceActiveOrders',
    MARKETPLACE_MY_PROFILE = 'MarketPlaceMyProfile',
    MARKETPLACE_PROFILE = 'MarketPlaceProfile',
    MARKETPLACE_WELCOME_CREATE_ORDER = 'MarketPlaceWelcomeCreateOrder',
    MARKETPLACE_SELECT_NFT = 'MarketPlaceSelectNft',
    MARKETPLACE_SELECT_NFT_PRICE = 'MarketPlaceSelectNftPrice',
    MARKETPLACE_CREATE_ORDER_SUCCESS = 'MarketPlaceCreateOrderSuccess',
    MARKETPLACE_OVERVIEW_GROUP = 'MarketPlaceOverviewGroup',
    MARKETPLACE_OVERVIEW = 'MarketPlaceOverview',
    MARKETPLACE_OVERVIEW_OWNER_HISTORY = 'MarketPlaceOverviewOwnerHistory',
    MARKETPLACE_DELIST_SUCCESS = 'MarketPlaceDelistSuccess',
    MARKETPLACE_BUY_CHECKOUT = 'MarketPlaceBuyCheckout',
    MARKETPLACE_BUY_SUCCESS = 'MarketPlaceBuySuccess',
}
export default ROUTES;
