import React, { useCallback, useState } from 'react';

import InfoIcon from '@components/InfoIcon/InfoIcon';
import { waitForModal, waitForNavigation } from '@helpers/helpers';
import { useNavigation } from '@navigation/useNavigation';
import { useFocusEffect } from '@react-navigation/native';

import ROUTES from '../navigation/routes';

const useFeeInfo = (
    modalClose?: () => void,
    modalOpen?: () => void,
    route = ROUTES.HOW_TO_FEE
) => {
    const navigation = useNavigation();

    const [shouldShownModal, setShouldShownModal] = useState<boolean>(false);

    useFocusEffect(
        useCallback(() => {
            const showConfirmModal = async () => {
                await waitForNavigation();
                modalOpen?.();
                setShouldShownModal(false);
            };
            if (shouldShownModal) {
                showConfirmModal();
            }
        }, [shouldShownModal])
    );

    const handleFeeIconPressWithoutModal = async () => {
        navigation.navigate(route);
    };
    const handleFeeIconPress = async () => {
        modalClose?.();
        await waitForModal();
        navigation.navigate(route);
        setShouldShownModal(true);
    };

    const renderFeeInfoIcon = (withoutModal?: boolean) => {
        return (
            <InfoIcon
                onPress={
                    withoutModal
                        ? handleFeeIconPressWithoutModal
                        : handleFeeIconPress
                }
                iconColor="secondary"
            />
        );
    };

    return {
        renderFeeInfoIcon,
    };
};

export default useFeeInfo;
