import {
    GestureDetector,
    GestureType,
    SimultaneousGesture,
} from 'react-native-gesture-handler';

interface IProps {
    gesture: SimultaneousGesture;
    gesturePan: GestureType;
    gesturePinch: GestureType;
    children: React.ReactNode;
}
const GestureHandler = ({ gesturePan, gesturePinch, children }: IProps) => {
    return (
        <GestureDetector gesture={gesturePan}>
            <GestureDetector gesture={gesturePinch}>{children}</GestureDetector>
        </GestureDetector>
    );
};
export default GestureHandler;
