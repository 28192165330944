import * as React from 'react';

import ComingSoonImage from '../../../../../assets/icons/mysteryBoxes/comingSoon.svg';
import BlockHeader from '../../../../../components/BlockHeader/BlockHeader';
import { Text, View } from '../../../../../components/Themed';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import stylesMain from './styles';

interface IProps {}

const MysteryBoxSaleClosed = ({}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={styles.container}>
            <View style={styles.image}>
                <ComingSoonImage />
            </View>
            <BlockHeader
                title={i18n.t('mysteryBoxes.saleClosed.title')}
                text={i18n.t('mysteryBoxes.saleClosed.text')}
                type="emptyState"
            />
        </View>
    );
};

export default MysteryBoxSaleClosed;
