import { balanceFromWei } from '@helpers/wallet';
import {
    TOKEN_TELLER_GAME_PREDICTION_DIRECTION,
    TOKEN_TELLER_GAME_STATUS,
    TokentellerGameCoefficientOutputResponse,
} from '@models/mysteryGames';

export const getTokenTellerCoefficient = (
    coefficientData: TokentellerGameCoefficientOutputResponse | undefined,
    currentGameId: string,
    currentGameCoefficient: string,
    roundStatus: TOKEN_TELLER_GAME_STATUS,
    coefficientDirection: TOKEN_TELLER_GAME_PREDICTION_DIRECTION
) => {
    const isClosedRound = roundStatus === TOKEN_TELLER_GAME_STATUS.Closed;

    const isUpDirection =
        coefficientDirection === TOKEN_TELLER_GAME_PREDICTION_DIRECTION.Up;

    if (coefficientData?.tokentellerGameCoefficients) {
        const { gameId, downCoefficient, upCoefficient } =
            coefficientData.tokentellerGameCoefficients;

        if (gameId === currentGameId && !isClosedRound)
            return isUpDirection ? upCoefficient : downCoefficient;
    }
    return currentGameCoefficient;
};

export const getTokenTellerUserCount = (
    data: TokentellerGameCoefficientOutputResponse | undefined,
    currentGameId: string,
    currentUserCount: number,
    roundStatus: TOKEN_TELLER_GAME_STATUS
) => {
    const isClosedRound = roundStatus === TOKEN_TELLER_GAME_STATUS.Closed;

    if (data) {
        const { gameId, userCount } = data.tokentellerGameCoefficients;

        if (gameId === currentGameId && !isClosedRound) return userCount;
    }
    return currentUserCount || 0;
};

export const getTokenTellerAmountOfWei = (
    data: TokentellerGameCoefficientOutputResponse | undefined,
    currentGameId: string,
    currentAmountOfWei: string,
    roundStatus: TOKEN_TELLER_GAME_STATUS,
    format = true
) => {
    const isClosedRound = roundStatus === TOKEN_TELLER_GAME_STATUS.Closed;

    if (data) {
        const { gameId, iguAmountWei } = data.tokentellerGameCoefficients;

        if (gameId === currentGameId && !isClosedRound)
            return format
                ? balanceFromWei(iguAmountWei).valueShort
                : iguAmountWei;
    }
    return format
        ? balanceFromWei(currentAmountOfWei).valueShort
        : currentAmountOfWei;
};

export const calculateCommission = (
    amount: string | undefined,
    fee: number
) => {
    return (Number(amount) * (fee / 100)).toString();
};
