import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        topWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: 'transparent',
            paddingHorizontal: scale(20),
            paddingVertical: verticalScale(16),
        },
        title: {
            flex: 1,
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
            textAlign: 'center',
        },

        inputWrapper: {
            marginHorizontal: verticalScale(8),
            paddingHorizontal: scale(20),
            paddingBottom: verticalScale(16),
        },
        buttonWrapper: {
            flex: 1,
            paddingHorizontal: scale(20),
            justifyContent: 'flex-end',
        },
        facebookInfo: {
            marginHorizontal: verticalScale(8),
            paddingHorizontal: scale(20),
            marginTop: verticalScale(8),
        },
        termText: {
            textAlign: 'center',
            marginTop: verticalScale(16),
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
        },
        link: {
            color: Colors[theme].text.primary,
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
        },
        container: {
            flex: 1,
            backgroundColor: 'transparent',
        },
        connectVia: {
            alignSelf: 'center',
            marginTop: verticalScale(12),
        },
    });

export default styles;
