import * as React from 'react';

import { PaginatedMysteryLikesGamesHistoryItem } from '@models/mysteryGames';

import MysteryGamesHistoryItem from '../MysteryGamesHistoryItem';

interface IProps {
    item: PaginatedMysteryLikesGamesHistoryItem;

    action: (game: PaginatedMysteryLikesGamesHistoryItem) => void;
}

const MysteryGamesHistoryItemHandler = ({ item, action }: IProps) => {
    return <MysteryGamesHistoryItem item={item} onPress={action} />;
};
export default MysteryGamesHistoryItemHandler;
