import * as React from 'react';

import High from '@assets/icons/mysteryGames/likes/high.svg';
import Low from '@assets/icons/mysteryGames/likes/low.svg';
import Max from '@assets/icons/mysteryGames/likes/max.svg';
import Mid from '@assets/icons/mysteryGames/likes/mid.svg';

import { IconType } from '../types';

interface IProps extends IconType {
    slug: number;
}

const HealthProgressIcon = ({ slug, size }: IProps) => {
    switch (slug) {
        case 0:
            return <Low width={size} height={size} />;
        case 1:
            return <Mid width={size} height={size} />;
        case 2:
            return <High width={size} height={size} />;
        case 3:
            return <Max width={size} height={size} />;

        default:
            return <Max width={size} height={size} />;
    }
};

export default HealthProgressIcon;
