import React from 'react';

import { useQuery } from '@apollo/client';

import { Text2ImageQuotaResponse } from '../Data/Models';
import { GET_AI_QUOTA } from '../Data/Requests';
import { AlertCardType } from '../components/AlertCard';

export interface IUsagesLeft {
    usages: number;
    alertType: AlertCardType;
}

export type ISystemLoadType = 'low' | 'medium' | 'high' | 'overloaded';

export const useAIInfo = () => {
    const { data: aiQuotaData } = useQuery<Text2ImageQuotaResponse>(
        GET_AI_QUOTA,
        {
            pollInterval: 10000,
            fetchPolicy: 'cache-and-network',
            onError: (error) => Console.log('Text2ImageQuotaResponse', error),
        }
    );
    const aiQuota = React.useMemo(
        () => aiQuotaData?.text2ImageQuota,
        [aiQuotaData]
    );

    const usagesLeft: IUsagesLeft | null = React.useMemo(() => {
        if (aiQuota) {
            const usagesLeft =
                aiQuota.maxDailyUserUsage - aiQuota.currentDailyUserUsage;
            if (usagesLeft === 0) {
                return {
                    usages: usagesLeft,
                    alertType: 'exclamation',
                };
            }
            return {
                usages: usagesLeft,
                alertType: 'warning',
            };
        }
        return null;
    }, [aiQuota]);

    const currentSystemLoad: ISystemLoadType | null = React.useMemo(() => {
        if (aiQuota) {
            return loadLevel(aiQuota.currentSystemUsagePercentage);
        }
        return null;
    }, [aiQuota]);

    const dailySystemLoad: ISystemLoadType | null = React.useMemo(() => {
        if (aiQuota) {
            return loadLevel(aiQuota.dailySystemUsagePercentage);
        }
        return null;
    }, [aiQuota]);

    return { aiQuota, usagesLeft, currentSystemLoad, dailySystemLoad };
};

const loadLevel = (loadPercent: number): ISystemLoadType => {
    if (loadPercent >= 0 && loadPercent < 50) {
        return 'low';
    }
    if (loadPercent >= 50 && loadPercent < 90) {
        return 'medium';
    }
    if (loadPercent >= 90 && loadPercent < 100) {
        return 'high';
    }
    return 'overloaded';
};
