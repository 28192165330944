// eslint-disable-next-line import/extensions
import basicFile from './index.js';

const id = {
    ...basicFile,
    title: {
        suffix: 'IguVerse',
        /**
         * BottomTab
         */
        Main: 'Utama',
        Play: 'Bermain',
        Charity: 'Amal',
        Collection: 'Koleksi',
        Marketplace: 'Pasar',
        Home: 'Beranda',
        //MysteryLikes
        HowMysteryLikesWork: 'The Mystery Of Likes - Bagaimana cara kerjanya',
        WelcomeHowMysteryLikesWork: 'The Mystery Of Likes - Selamat datang',
        MysteryLikesMain: 'The Mystery Of Likes - Utama',
        MysteryLikesActiveOrder: 'The Mystery Of Likes - Pesanan Aktif',
        MysteryLikesHistory: 'The Mystery Of Likes - Sejarah Permainan',
        MysteryLikesCreateGameWelcome:
            'The Mystery Of Likes - Buat Selamat Datang',
        MysteryLikesCreateGameInput: 'The Mystery Of Likes - Membuat',
        MysteryLikesCreateGameSuccess: 'The Mystery Of Likes - Sukses',
        MysteryLikesSelectRange: 'The Mystery Of Likes - Pilih Rentang',
        MysteryLikesSelectRangeFinish:
            'The Mystery Of Likes - Informasi Permainan',

        //

        // NFT Evolution
        HowNftEvolutionWelcome: 'NFT Evolution - Bagaimana cara kerjanya',
        NftEvolutionWelcome: 'NFT Evolution - Selamat datang',
        NftEvolutionSelectPets: 'NFT Evolution',
        NftEvolutionCheckout: 'NFT Evolution - Kesempatan',
        NftEvolutionSuccess: 'NFT Evolution - Sukses',
        NftEvolutionSelectPet: 'NFT Evolution',
        NftEvolutionMintPet: 'NFT Evolution - Mint',
        NftEvolutionMintPetSuccess: 'NFT Evolution - Sukses',
        //
        //NftRoyal
        HowNftRoyalWork: 'The Grand Race - Bagaimana cara kerjanya',
        WelcomeNftRoyalWork: 'The Grand Race - Selamat Datang',
        NftRoyalMain: 'The Grand Race - Main',
        NftRoyalActiveOrder: 'The Grand Race - Kamar Kosong',
        NftRoyalCreateGameWelcome: 'The Grand Race - Create',
        NftRoyalGameConfigurations: 'The Grand Race - Pengaturan Game',
        NftRoyalEnterCoinsAmount: 'The Grand Race - Pilih Jumlah Koin',
        NftRoyalCreateGameSuccess: 'The Grand Race - Buat Kesuksesan Game',
        NftRoyalHistory: 'The Grand Race - Sejarah',
        NftRoyalGameHistory: 'The Grand Race - Sejarah Game',
        NftRoyalPlayGame: 'The Grand Race',
        //
        /**
         * Admin
         */
        AdminRoot: 'Admin',
        AdminVerify: 'Admin - Verifikasi',
        AdminSendPushNotification: 'Admin - Kirim Pemberitahuan Push',
        AdminReverification: 'Admin - Reverifikasi',
        AdminBlockUser: 'Admin - Blokir Pengguna',
        AdminMaintenanceMode: 'Admin - Modus Pemeliharaan',
        AdminAssignWhiteList: 'Admin - Tetapkan Daftar Putih',
        AdminAssignRole: 'Admin - Menetapkan Peran',
        /**
         * Mint
         */
        MintRoot: 'Mint',
        MintPetRanks: 'Mint Pilih Peringkat',
        MintPetSelectGeneration: 'Metode Pemilihan Mint',
        MintGenerationInfo: 'Mint Info',
        MintSelectGeneratedNft: 'Mint Pilih NFT',
        MintPetPickPhoto: 'Foto Mint Pick',
        MintPetEditPhoto: 'Mint Edit Foto',
        MintPetName: 'Mint Pilih Nama Hewan Peliharaan',
        MintPetCheckout: 'Mint Checkout',
        MintPetCheckoutSuccess: 'Keberhasilan Checkout Mint',
        /**
         * CreateWallet
         */
        AddWalletInitial: 'Tambahkan Dompet',
        ImportWallet: 'Dompet Impor',
        CreateWalletFirst: 'Buat Dompet',
        CreateWalletPassphrase: 'Buat Benih Dompet',
        CreateWalletVerification: 'Verifikasi Benih',
        CreateWalletSuccess: 'Dompet Sukses',
        /**
         * LOGIN
         */
        Login: 'Masuk',
        /**
         * Wallet
         */
        WalletRoot: 'Dompet',
        // Tabs
        WalletCoinsMain: 'Dompet - Koin',
        WalletNFTSMain: 'Dompet - NFT',
        WalletRewardsMain: 'Dompet - Penghargaan',
        //Gaming
        WalletGamingTab: 'Dompet - Permainan',
        WalletGamingMain: 'Dompet - Permainan',
        TopUpGamingWallet: 'Dompet - Isi ulang',
        WithdrawInputAmount: 'Dompet - Penarikan',
        TopUpCheckoutScreen: 'Dompet - Isi ulang Checkout',
        TopUpSuccessScreen: 'Dompet - Isi ulang Keberhasilan Checkout',
        WithdrawIguSuccessScreen: 'Dompet - Menarik diri Sukses',
        GamingWalletTransactionDetails: 'Dompet - Transaksi Hadiah',
        // Coins
        WalletCoinsBalance: 'Dompet - Saldo Koin',
        WalletCoinsWithdraw: 'Dompet - Penarikan',
        WalletCoinsWithdrawSuccess: 'Dompet - Penarikan Berhasil',
        WalletCoinsTransactionDetails: 'Dompet - Detail Transaksi',
        // NFT
        WalletNFTSList: 'Dompet - Daftar NFT',
        WalletNFTSWithdraw: 'Dompet - Penarikan NFT',
        WalletNFTSWithdrawSuccess: 'Dompet - Keberhasilan Penarikan NFT',
        WalletNFTSTransactionDetails: 'Dompet - Rincian Transaksi NFT',
        // Rewards
        WalletRewardsClaimSuccess: 'Dompet - Keberhasilan Klaim Hadiah',
        WalletRewardsTransactionDetails: 'Dompet - Rincian Transaksi Hadiah',
        WalletRewardsTab: 'Dompet - Hadiah',
        // Trade
        WalletTradeMain: 'Dompet - Perdagangan Utama',
        WalletTradeChart: 'Dompet - Bagan Perdagangan',
        WalletTradeSuccess: 'Dompet - Keberhasilan Perdagangan',
        // Settings
        WalletSettings: 'Dompet - Pengaturan',
        WalletSettingsBackup: 'Dompet - Pengaturan Cadangan',
        // Staking
        StakingWelcome: 'Dompet - Strike Welcome',
        StakingOptions: 'Dompet - Strike Opsi',
        StakingPlans: 'Dompet - Rencana Pengintaian',
        StakingDetailedInfo: 'Dompet - Mengintai Info Detail',
        StakingCoinsInput: 'Dompet - Mengintai Koin Input',
        StakingDailyBoost: 'Dompet - Mengintai Peningkatan Harian',
        StakingSuccess: 'Dompet - Mengintai Keberhasilan',
        StakingAlready: 'Dompet - Sudah Mengintai',
        /**
         * Play
         */
        UnStakingSuccess: 'Dompet - Kesuksesan yang Tak Terbendung',
        PlayTasks: 'Tugas',
        PlayTasksActive: 'Tugas Aktif',
        PlayTasksHistory: 'Riwayat Tugas',
        PlayEditNFTs: 'Edit NFT',
        PlayTasksHistoryDetails: 'Rincian Riwayat Tugas',
        PlayCollectedExperience: 'Pengalaman yang Dikumpulkan',
        PlaySuccessScreen: 'Keberhasilan Tugas',
        /**
         * Socialize To Earn
         */
        SocializeToEarnWelcomeShare: 'SocializeToEarn - Selamat Datang',
        SocializeToEarnShareYourPhoto: 'SocializeToEarn - Bagikan Foto Anda',
        SocializeToEarnCreateSharePhoto: 'SocializeToEarn - Buat Foto Berbagi',
        SocializeToEarnVerifyUser: 'SocializeToEarn - Verifikasi Pengguna',
        SocializeToEarnCreateShareTemplate:
            'SocializeToEarn - Berbagi dengan Templat',

        SocializeToEarnVerifyUserError:
            'SocializeToEarn - Verifikasi Kesalahan Pengguna',
        SocializeToEarnShareYourPhotoSuccess:
            'SocializeToEarn - Bagikan Keberhasilan Foto Anda',
        SocializeToEarnWelcomeVerify:
            'SocializeToEarn - Selamat Datang Verifikasi',
        SocializeToEarnVerifyUserMatch:
            'SocializeToEarn - Verifikasi Kecocokan Pengguna',
        SocializeToEarnVerifyUserNotFound:
            'SocializeToEarnVerify - Pengguna Tidak Ditemukan',
        SocializeToEarnVerifyUserFailed:
            'SocializeToEarn - Verifikasi Pengguna Gagal',
        SocializeToEarnVerifyUserAlreadyCompleted:
            'SocializeToEarn - Verifikasi Pengguna Sudah Selesai',
        /**
         * Move To Earn
         */
        MoveToEarnWelcome: 'MoveToEarn - Selamat Datang',
        MoveToEarnProgress: 'MoveToEarn - Kemajuan',
        MoveToEarnSummary: 'MoveToEarn - Ringkasan',
        /**
         * Play to Earn
         */
        PlayToEarn: 'PlayToEarn',
        PlayToEarnWelcome: 'PlayToEarn - Selamat Datang',
        PlayToEarnProgress: 'PlayToEarn - Bermain',
        /**
         * Charity
         */
        CharityOrganizations: 'Amal - Organisasi',
        CharityDetailedOrganization: 'Amal - Organisasi Terperinci',
        CharityOrganizationDonations: 'Amal - Sumbangan Organisasi',
        CharityEditSelectedOrganization: 'Amal - Edit Organisasi yang Dipilih',
        CharityEditDetailedOrganization: 'Amal - Edit Organisasi Terperinci',
        /**
         * Collection
         */
        CollectionMain: 'Koleksi',
        CollectionDetailedPet: 'Koleksi - Hewan Peliharaan Saya',
        CollectionPetLevelUpCheckout:
            'Koleksi - Checkout Peningkatan Level Hewan Peliharaan',
        CollectionPetLevelUpSuccess:
            'Koleksi - Keberhasilan Peningkatan Level Hewan Peliharaan',
        CollectionPetRankUpCheckout:
            'Koleksi - Pemeriksaan Naik Pangkat Hewan Peliharaan',
        CollectionPetRankUpSuccess:
            'Koleksi - Keberhasilan Naik Pangkat Hewan Peliharaan',
        CollectionPetHealUpCheckout:
            'Koleksi - Pemeriksaan Naik Pangkat Hewan Peliharaan',
        CollectionPetHealUpSuccess:
            'Koleksi - Kesuksesan Penyembuhan Hewan Peliharaan',
        CollectionPetReviveCheckout: 'Koleksi - Pembayaran Pet Revive',
        CollectionPetReviveSuccess:
            'Koleksi - Hewan Peliharaan Menghidupkan Kembali Kesuksesan',
        CollectionPetAgeReviveCheckout: 'Koleksi - Pembayaran Pet Revive',
        CollectionPetAgeReviveSuccess:
            'Koleksi - Hewan Peliharaan Menghidupkan Kembali Kesuksesan',
        CollectionMultiplePetsHealUpProceeding:
            'Koleksi - Beri Makan Semua Hewan Peliharaan',
        CollectionMultiplePetsHealUpCheckout:
            'Koleksi - Pembayaran Pakan Hewan Peliharaan',
        CollectionMultiplePetsHealUpSuccess:
            'Koleksi - Keberhasilan Pakan Hewan Peliharaan',
        /**
         * Profile
         */
        ProfileRoot: 'Profil',
        ProfileMain: 'Profil - Utama',
        ProfileLanguagesSettings: 'Profil - Pengaturan Bahasa',
        ProfileNotificationsSettings: 'Profil - Pengaturan Notifikasi',
        ProfileSocialAccountsSettings: 'Profil - Pengaturan Akun Sosial',
        ProfileSocialAccessibilitySettings: 'Profil - Pengaturan Aksesibilitas',
        ProfileSocialMedia: 'Profil - Media Sosial',

        /**
         * Others
         */
        Dev: 'Dev',
        RanksDetailedInfo: 'Info Terperinci Jenjang Kepangkatan',
        EnergyInfoScreen: 'Layar Info Energi',

        // REFERRALS

        Referrals: 'Rujukan',
        ReferralsAll: 'Rujukan - Semua',
        ReferralsInvites: 'Rujukan - Undangan',
        ReferralsInvite: 'Rujukan - Undang',
        ReferralsHowItsWork: 'Rujukan - Bagaimana Cara Kerjanya',

        // LEADERBOARD
        Leaderboard: 'Papan Peringkat',
        LeaderboardDailyTasks: 'Papan Peringkat - Tugas',
        LeaderboardPvpGames: 'Papan Peringkat - PvP',
        //

        //Mystery Box
        MysteryBoxMain: 'Kotak Misteri',
        MysteryBoxPurchaseWelcome: 'Kotak Misteri - Selamat Datang Pembelian',
        MysteryBoxPurchase: 'Kotak Misteri - Pembelian',
        MysteryBoxPurchaseCheckout: 'Kotak Misteri - Pembelian Checkout',
        MysteryBoxPurchaseSuccess: 'Kotak Misteri - Keberhasilan Pembelian',

        //HOW
        HowToCharity: 'Bagaimana Amal Bekerja',
        HowToPlay: 'Cara Bermain',
        HowToReferrals: 'Rujukan - Bagaimana Cara Kerjanya',
        HowToAvailableRewards: 'Cara Kerja Hadiah yang Tersedia',
        HowToLockedRewards: 'Bagaimana cara kerja Available Locked',
        HowToGamingAvailableWallet: 'Cara kerja Dompet yang Tersedia',
        HowToGamingPendingWallet: 'Cara kerja Dompet Tertunda',
        HowToSocializeToEarn: 'Cara Berbagi',
        HowToVerifyUser: 'Cara menemukan ID Hewan Peliharaan',
        HowToPurchaseBNB: 'Cara Membeli BNB',
        HowToCommissionFee: 'Biaya',
        HowToFee: 'Biaya',
        HowToPurchaseSpadeId: 'Cara Menggunakan SPACE ID',

        // BONUS MINT RANK
        BonusMintRanksInfo: 'Mint - Peringkat Bonus',
        BonusMintRanksCarousel: 'Mint - Bonus Semua Peringkat',

        InitialMint: 'Mint Hewan Peliharaan Pertama Anda',
        InitialReferral: 'Anda telah diundang ke IguVerse',

        YourPetsAreHungry: 'Hewan Peliharaan Anda Lapar!',
        UpdateApp: 'Pembaruan Baru',

        //ONBOARDING
        MainWalletOnBoardingScreen: 'Utama Dompet',
        LoginOnBoardingScreen: 'Selamat Datang',

        //MARKETPLACE
        MarketPlaceActiveOrders: 'Pasar - Pesanan Aktif',
        MarketPlaceMyProfile: 'Pasar - Profil Saya',
        MarketPlaceProfile: 'Pasar - Profil',
        MarketPlaceWelcomeCreateOrder: 'Pasar - Selamat Datang',
        MarketPlaceSelectNft: 'Pasar - Pilih NFT',
        MarketPlaceSelectNftPrice: 'Pasar - Pilih Harga',
        MarketPlaceOverviewGroup: 'Pasar - Gambaran Umum',
        MarketPlaceOverview: 'Pasar - Gambaran Umum',
        MarketPlaceOverviewOwnerHistory: 'Pasar - Riwayat Pemilik',
        MarketPlaceBuyCheckout: 'Pasar - Pembayaran',
        MarketPlaceCreateOrderSuccess: 'Pasar - Sukses',
        MarketPlaceDelistSuccess: 'Pasar - Sukses',
        MarketPlaceBuySuccess: 'Pasar - Sukses',

        //Achievements
        Achievements: 'Prestasi',
        AchievementsDetails: 'Prestasi - Rincian',

        //PassCode
        PassCodeFaceId: 'Kode sandi - Biometrik',
        PassCodeNew: 'Kode sandi - Aktifkan',
        PassCodeChange: 'Kode sandi - Ubah',
        PassCodeSuccess: 'Kode sandi - Berhasil',
        PassCodeSettings: 'Kode sandi - Pengaturan',
        PassCodeConfirm: 'Kode sandi - Konfirmasi',

        //Lucky Strike
        WelcomeLuckyStrike: 'Lucky Strike - Selamat datang',
        LuckyStrike: 'Lucky Strike',
        LuckyStrikeMain: 'Lucky Strike - Putaran',
        LuckyStrikeHistory: 'Lucky Strike - Sejarah',
        HowLuckyStrikeWork: 'Lucky Strike - Bagaimana cara kerjanya',
        LuckyStrikeAllParticipants: 'Lucky Strike - Peserta',
        LuckyStrikeWaitingForResult: 'Lucky Strike - Hasil',
        LuckyStrikeUserTickets: 'Lucky Strike - Tiket',
        LuckyStrikeUserTicketsWait: 'Lucky Strike - Tiket',
        LuckyStrikeAllWinners: 'Lucky Strike - Pemenang',
        LuckyStrikeGameHistory: 'Lucky Strike - Sejarah',

        //Quantum Disk
        WelcomeQuantumSpin: 'Quantum Disk - Selamat datang',
        HowQuantumSpinWork: 'Quantum Disk - Bagaimana cara kerjanya',
        QuantumSpin: 'Quantum Disk',
        QuantumSpinMain: 'Quantum Disk - Roda',
        QuantumSpinHistory: 'Quantum Disk - Sejarah',
        QuantumSpinGameHistory: 'Quantum Disk - Sejarah',
        QuantumSpinProbablyFair: 'Quantum Disk - Terbukti Adil',
        QuantumSpinProbablyFairHistory: 'Quantum Disk - Sejarah',

        //TokenTeller
        WelcomeTokenTeller: 'Tokenteller - Selamat datang',
        TokenTeller: 'Tokenteller',
        TokenTellerMain: 'Tokenteller - Putaran',
        TokenTellerHistory: 'Tokenteller - Sejarah',
        HowTokenTellerWork: 'Tokenteller - Bagaimana Cara Kerjanya',
        TokenTellerChart: 'Tokenteller - Bagan',
        TokenTellerAllParticipants: 'Tokenteller - Peserta',
        TokenTellerWaitingForResult: 'Tokenteller - Menunggu',
        TokenTellerGameHistory: 'Tokenteller - Sejarah',
    },
};

export default id;
