import { StyleSheet } from 'react-native';

import { scale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
        },
        content: {
            paddingHorizontal: scale(20),
            alignItems: 'center',
            justifyContent: 'center',
        },
    });

export default styles;
