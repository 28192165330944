import * as React from 'react';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import MysteryLikesActiveOrderScreen from '../../screens/TabBar/Home/MysteryLikesFlow/MysteryLikesActiveOrder/MysteryLikesActiveOrderScreen';
import MysteryLikesHistoryScreen from '../../screens/TabBar/Home/MysteryLikesFlow/MysteryLikesHistory/MysteryLikesHistoryScreen';
import { MysteryLikesTabsParamList } from '../../types';
import ROUTES from '../routes';

const MysteryLikesTabsStack =
    createBottomTabNavigator<MysteryLikesTabsParamList>();

export const MysteryLikesTabsNavigator = () => {
    return (
        <MysteryLikesTabsStack.Navigator
            screenOptions={{
                headerShown: false,
            }}
            initialRouteName={ROUTES.MYSTERY_LIKES_ACTIVE_ORDERS}
            backBehavior="none"
            tabBar={() => null}>
            <MysteryLikesTabsStack.Screen
                name={ROUTES.MYSTERY_LIKES_ACTIVE_ORDERS}
                component={MysteryLikesActiveOrderScreen}
            />
            <MysteryLikesTabsStack.Screen
                name={ROUTES.MYSTERY_LIKES_HISTORY}
                component={MysteryLikesHistoryScreen}
            />
        </MysteryLikesTabsStack.Navigator>
    );
};
