import { StyleSheet } from 'react-native';

import { head } from 'lodash';

import Colors from '../../constants/Colors';
import { moderateScale, scale, verticalScale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        content: {
            flexDirection: 'row',
            alignItems: 'center',

            justifyContent: 'space-between',
            borderRadius: moderateScale(12),

            height: '100%',
        },
        left: {
            backgroundColor: 'transparent',
            justifyContent: 'space-between',
            flex: 1,
            marginRight: scale(16),
        },
        title: {
            color: Colors[theme].text.onLight,
            ...font({
                size: 18,
                height: 24,
                weight: '700',
            }),
            marginBottom: verticalScale(2),
        },
        text: {
            color: Colors[theme].text.onLight,
            ...font({
                size: 15,
                height: 22,
                weight: '600',
            }),
        },
        right: {
            backgroundColor: 'transparent',
            alignItems: 'flex-end',
        },
        mysteryBoxBanner: {
            borderRadius: moderateScale(12),
        },
        image: {
            width: 48,
            height: 48,
        },
        timer: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        gap: {
            marginLeft: verticalScale(2),
        },
        gradientBackground: {
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(9),
            flexDirection: 'row',
            alignItems: 'center',

            justifyContent: 'space-between',

            borderRadius: moderateScale(12),
        },
    });

export default styles;
