import { useCallback } from 'react';

import { useStorePurchase } from '@contexts/StorePurchaseContext';
import { wait } from '@helpers/helpers';

import useMysteryBoxList from './useMysteryBoxList';

const useMysteryBoxPurchase = () => {
    const { purchaseMysterybox, setLoading, setError, stopLoading } =
        useStorePurchase();
    const { getUserMysteryBoxesList } = useMysteryBoxList();

    const purchaseMysteryBox = useCallback(
        async (productId: string) => {
            const listCount =
                (await getUserMysteryBoxesList(true))?.length ?? 0;
            const result = await purchaseMysterybox(productId);
            if (!result) return false;

            setLoading('FINALIZE');

            let saveStop = 0;
            let leave = false;
            do {
                const newCount =
                    (await getUserMysteryBoxesList(true))?.length ?? 0;

                leave = newCount > listCount;

                saveStop++;
                await wait(3000);
            } while (!leave && saveStop !== 20);

            if (saveStop === 20) {
                setError('FINALIZE');
                return false;
            }
            stopLoading();
            return true;
        },
        [purchaseMysterybox, getUserMysteryBoxesList]
    );

    return {
        purchaseMysteryBox,
    };
};

export default useMysteryBoxPurchase;
