import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';
import { stylesModalNavigationFix } from '@helpers/style';

import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
            paddingBottom: verticalScale(16),
            ...stylesModalNavigationFix(),
        },
        wrapper: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
        },
        itemContainer: {
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            paddingHorizontal: scale(20),
            backgroundColor: 'transparent',
        },
        imgWrapper: {
            alignSelf: 'center',

            marginBottom: verticalScale(24),
        },

        header: {
            paddingHorizontal: scale(16),
        },
        buttonWrapper: {
            marginTop: verticalScale(24),
            paddingHorizontal: scale(20),
        },
        accent: {
            color: Colors[theme].text.accent,
        },
        link: {
            marginTop: verticalScale(20),
        },
        alert: {
            marginTop: verticalScale(32),
            marginHorizontal: scale(20),
        },
        cardFeature: {
            marginTop: verticalScale(12),
            marginHorizontal: scale(20),
        },

        timerContainer: {
            marginTop: verticalScale(20),
            marginHorizontal: scale(20),
            alignItems: 'center',
            paddingHorizontal: scale(20),
            paddingVertical: verticalScale(20),
            borderColor: Colors[theme].border.primary,
            borderWidth: 1,
            borderRadius: moderateScale(12),
        },

        title: {
            textAlign: 'center',
            color: Colors[theme].text.primary,
            ...font({
                size: 18,
                height: 24,
                weight: '700',
            }),
            marginBottom: verticalScale(6),
        },
        text: {
            textAlign: 'center',
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 21,
                weight: '400',
            }),
            letterSpacing: 0.08,
            marginBottom: verticalScale(16),
        },
        pvpBonus: {
            marginTop: verticalScale(20),
        },
    });

export default styles;
