import { useCallback, useEffect, useState } from 'react';

import { StakingPlanOutput } from '@Data/Models';
import { StakingOptionsType, useStaking } from '@contexts/StakingContext';
import { toastErrorUnknown } from '@helpers/toastNotification';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

export const useStakingOptionsScreen = (
    type?: StakingOptionsType | undefined,
    plan?: StakingPlanOutput | undefined
) => {
    const navigation = useNavigation();
    const [stageFill, setStageFill] = useState('0%');

    const {
        stakingOption,
        setStakingOption,
        stakingPlans,
        currentStakingPlan,
    } = useStaking();

    //in some case to throw user from this screen if they have active staking plan
    // useEffect(() => {
    //     if (currentStakingPlan) {
    //         navigation.pop();
    //         toastError(i18n.t('staking.optionsScreen.toastAlready'));
    //     }
    // }, [currentStakingPlan]);

    useEffect(() => {
        return () => setStakingOption(null);
    }, []);

    useEffect(() => {
        stakingOption && onOptionPress(stakingOption);
    }, [stakingOption]);

    const onOptionPress = useCallback(
        (id: StakingOptionsType) => {
            setStageFill('100%');
            if (stakingOption === id) {
                return;
            }
            setStakingOption(id);
        },
        [stakingOption]
    );

    const navigateToPlans = useCallback(() => {
        if (stakingPlans?.length) {
            navigation.navigate(ROUTES.STAKING_PLANS);
            return;
        }
        toastErrorUnknown();
    }, [stakingPlans]);

    const handleBack = () => {
        navigation.goBack();
        setStakingOption(null);
    };

    useEffect(() => {
        if (type && plan) {
            navigation.setParams({
                type: undefined,
                plan: undefined,
            });
            onOptionPress(type);
            navigation.navigate(ROUTES.STAKING_PLANS, {
                plan,
            });
        }
    }, []);

    return {
        stakingOption,
        setStakingOption,
        stakingPlans,
        currentStakingPlan,
        stageFill,
        handleBack,
        navigateToPlans,
        onOptionPress,
    };
};
