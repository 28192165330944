import { isArray } from 'lodash';

import { State } from '../../../types';
import { DeferDeepLinkType } from './types';

export function matchState(state: State, match: State | State[] | undefined) {
    if (!match) {
        return true;
    }

    if (isArray(match)) {
        return match.includes(state);
    }

    return state === match;
}

// get route configuration based on path
export function getRoute(
    Linking: Array<DeferDeepLinkType>,
    path: string | null | undefined
) {
    return Linking.find((item) => item.regex.test(path || ''));
}
