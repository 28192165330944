import { ReactElement } from 'react';
import { SvgProps } from 'react-native-svg';

import IgupCoin from '@assets/icons/staking/igupCoin.svg';

export type IconsData = {
    IGUP: ReactElement<SvgProps>;
    IGU: ReactElement<SvgProps>;
};

export const iconsData: IconsData = {
    IGUP: <IgupCoin />,
    IGU: <IgupCoin />, // change in future to IGU
};
