import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        max: {
            position: 'absolute',
            top: 0,
            left: 0,
            flex: 1,
        },
        container: {
            overflow: 'hidden',
            minWidth: '100%',
            minHeight: '100%',
        },
        arrowBack: {
            position: 'absolute',
            zIndex: 1,
            left: scale(16),
            backgroundColor: 'transparent',
        },
        share: {
            position: 'absolute',
            zIndex: 1,
            right: scale(16),
            backgroundColor: 'transparent',
        },
        colorPickerWrapper: {
            position: 'absolute',
            zIndex: 1,
            bottom: 0,
            paddingTop: verticalScale(16),
            paddingHorizontal: scale(20),
            width: '100%',
        },
        card: {
            position: 'absolute',
            zIndex: 25,
            left: scale(20),
        },
        cardGap: {
            width: scale(20 * 2),
        },
    });

export default styles;
