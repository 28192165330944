import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingLeft: scale(6),
            paddingVertical: verticalScale(4),
            paddingRight: scale(8),
            borderRadius: 50,
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: Colors[theme].surfaceDecorativeDarkMinimal,
        },
        filled: {
            backgroundColor: Colors[theme].alwaysOneColor.background,
        },
        icon: {
            backgroundColor: 'transparent',
            marginRight: scale(4),
        },
    });

export default styles;
