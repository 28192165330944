import React from 'react';
import { Text, View } from 'react-native';

import { useApolloClient } from '@apollo/client';
import { usePlay } from '@contexts/PlayContext';
import { isAndroid } from '@helpers/app';
import { errorsHandler } from '@helpers/errors';
import {
    isGraphqlError,
    waitForModal,
    waitForNavigation,
} from '@helpers/helpers';
import { toastError } from '@helpers/toastNotification';

import { GetHealupPaymentsResponse, Pet } from '../../Data/Models';
import { GET_HEALUP_PAYMENTS } from '../../Data/Requests';
import useThemedStyles from '../../hooks/useThemedStyles';
import i18n from '../../i18n/i18n';
import ROUTES from '../../navigation/routes';
import { useNavigation } from '../../navigation/useNavigation';
import BlockHeader from '../BlockHeader/BlockHeader';
import Button from '../Button';
import ButtonsBottom from '../ButtonsBottom';
import CacheImage from '../CacheImage';
import Divider from '../Divider';
import HealthIndicator from '../HealthIndicator';
import Icon, { ICON_NAMES } from '../Icons';
import RankIcon from '../Icons/RankIcon';
import ModalBottom from '../ModalBottom';
import mainStyles from './styles';

const config = {
    maxHealth: 100,
    min: 10,
    step: 10,
};

type ErrorTypes = 'MIN' | 'MAX';

interface IProps {
    isVisible: boolean;
    closeModal: () => void;
    pet: Pet;

    navigateBackFromCheckout?: ROUTES;
    navigateBackOnUpgrade?: ROUTES;
    fromScreenModal?: boolean;
}

const ModalChooseAmountOfHealUp = ({
    isVisible,
    closeModal,
    pet,

    navigateBackFromCheckout,
    navigateBackOnUpgrade,
    fromScreenModal,
}: IProps) => {
    const styles = useThemedStyles(mainStyles);

    const client = useApolloClient();
    const navigation = useNavigation();

    const { refetchPets } = usePlay();

    const [error, setError] = React.useState<ErrorTypes | null>(null);

    const [loading, setLoading] = React.useState<boolean>(false);

    const { health, rank, name, image } = pet;

    const maxAmount = React.useMemo(() => config.maxHealth - health, [health]);

    const [amount, setAmount] = React.useState<number>(maxAmount);

    const reset = React.useCallback(() => {
        setError(null);
        setAmount(maxAmount);
        setLoading(false);
    }, [maxAmount]);

    React.useEffect(() => {
        reset();
    }, [maxAmount, isVisible]);

    const handleIncreaseAmount = React.useCallback(() => {
        if (amount === maxAmount) {
            setError('MAX');
            return;
        }
        setError(null);
        setAmount(amount + config.step);
    }, [amount, maxAmount]);
    const handleDecreaseAmount = React.useCallback(() => {
        if (amount === config.min) {
            setError('MIN');
            return;
        }
        setError(null);
        setAmount(amount - config.step);
    }, [amount]);

    const fetchHealUpPayments = async (healthPoints: number) => {
        const result = await client.query<GetHealupPaymentsResponse>({
            fetchPolicy: 'network-only',
            query: GET_HEALUP_PAYMENTS,
            variables: {
                petId: pet.id,
                healthPoints,
            },
        });

        return result.data.healupPayments;
    };

    const onCheckout = React.useCallback(async () => {
        setLoading(true);
        try {
            const payments = await fetchHealUpPayments(amount);
            setLoading(false);
            closeModal();
            await waitForModal();
            if (fromScreenModal) {
                navigation.pop();
                await waitForNavigation();
            }
            navigation.navigate(ROUTES.COLLECTION_PET_HEAL_UP_CHECKOUT, {
                pet,
                payments,
                navigateBackOnUpgrade,
                healthPoints: amount,
                navigateBackFromCheckout,
            });
        } catch (error) {
            setLoading(false);
            if (isGraphqlError(error, 'PET_WRONG_STATE')) {
                toastError(
                    undefined,
                    i18n.t('petIsDead.errors.PET_WRONG_STATE')
                );
                await refetchPets(true);
                return;
            }
            errorsHandler(error, true);
        }
    }, [
        amount,
        pet,
        fromScreenModal,
        navigateBackOnUpgrade,
        navigateBackFromCheckout,
    ]);

    const errorText = React.useMemo(() => {
        const amount = error === 'MAX' ? maxAmount : config.min;
        return i18n.t(`detailedPet.health.healUpAmountChoose.errors.${error}`, {
            amount,
        });
    }, [error, maxAmount]);

    return (
        <ModalBottom
            modalHeight="700"
            isVisible={isVisible}
            onClose={closeModal}>
            <View style={styles.container}>
                <BlockHeader
                    type="primary"
                    title={i18n.t(
                        'detailedPet.health.healUpAmountChoose.title'
                    )}
                    text={i18n.t('detailedPet.health.healUpAmountChoose.text')}
                />
                <View style={styles.chooseWrapper}>
                    <View style={styles.chooseBlock}>
                        <Button
                            disabled={error === 'MIN'}
                            type="outline"
                            iconName={ICON_NAMES.MINUS}
                            iconColor={styles.iconMinus.color}
                            onPress={handleDecreaseAmount}
                        />
                        <View style={styles.amountWrapper}>
                            <Text style={styles.amount}>{`+${amount}`}</Text>
                            <Icon name={ICON_NAMES.HEALTH} size={32} />
                        </View>
                        <Button
                            disabled={error === 'MAX'}
                            type="outline"
                            iconName={ICON_NAMES.PLUS}
                            iconColor={styles.iconPlus.color}
                            onPress={handleIncreaseAmount}
                        />
                    </View>
                    <Text style={[styles.label, !!error && styles.error]}>
                        {error
                            ? errorText
                            : i18n.t(
                                  'detailedPet.health.healUpAmountChoose.label'
                              )}
                    </Text>
                    <Divider customStyles={styles.divider} />
                    <View style={styles.petContainer}>
                        <View style={styles.imageWrapper}>
                            <CacheImage
                                source={image.thumbnailUrl}
                                style={styles.image}
                                resizeMode="contain"
                            />
                        </View>
                        <View style={styles.textWrapper}>
                            <View style={styles.titleWrapper}>
                                <RankIcon slug={rank} size={24} />
                                <Text
                                    style={styles.petName}
                                    numberOfLines={1}
                                    ellipsizeMode="tail">
                                    {name}
                                </Text>
                            </View>

                            <HealthIndicator
                                healthPercentage={health + amount}
                            />
                        </View>
                    </View>
                </View>
            </View>
            <ButtonsBottom
                title={i18n.t('detailedPet.health.healUpAmountChoose.button')}
                loading={loading}
                withLoadingText
                loadingText={i18n.t(
                    'detailedPet.health.healUpAmountChoose.loading'
                )}
                disabled={loading}
                onPress={onCheckout}
                safe={!isAndroid}
            />
        </ModalBottom>
    );
};
export default ModalChooseAmountOfHealUp;
