import * as React from 'react';

import Button from '@components/Button';
import { ICON_NAMES } from '@components/Icons';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    onPress: () => void;
    textInfo?: string;
    iconName?: ICON_NAMES;
    iconSize?: number;
    iconDirection?: 'right' | 'left';
}

const HowItWorks = ({
    onPress,
    iconName = ICON_NAMES.QUESTION,
    iconSize = 24,
    iconDirection,
    textInfo,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <Button
            onPress={onPress}
            type={'text'}
            title={textInfo ? textInfo : i18n.t('general.howItWorks')}
            iconName={iconName}
            iconSize={iconSize}
            containerStyle={styles.infoWrapper}
            style={styles.flex}
            iconDirection={iconDirection}
            debouncedPress
        />
    );
};

export default HowItWorks;
