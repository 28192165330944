import * as React from 'react';
import { KeyboardAvoidingView, ScrollView } from 'react-native';

import Button from '@components/Button';
import ButtonsBottom from '@components/ButtonsBottom';
import { ICON_NAMES } from '@components/Icons';
import CoinIcon from '@components/Icons/CoinIcon';
import Input from '@components/Input';
import ModalLoader from '@components/ModalLoader';
import SafeAreaTabContent from '@components/SafeAreaTabContent';
import { Text, View } from '@components/Themed';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { isIOS, isWeb } from '@helpers/app';
import { priceString } from '@helpers/payments';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';
import NavigationBar from '@navigation/NavigationBar';

import CreateGameConfirmationModal from '../../components/CreateGameConfirmationModal';
import MaxMinimalBetFeature from '../../components/MaxMinimalBetFeature';
import { useNftRoyalEnterCoinsAmountScreen } from './hooks/useNftRoyalEnterCoinsAmountScreen';
import stylesMain from './styles';

const NftRoyalEnterCoinsAmount = () => {
    const styles = useThemedStyles(stylesMain);

    const {
        submitForm,
        values,
        isValid,
        dirty,
        handleChangeAmount,
        handleClickAll,
        handleOnBlur,
        errors,
        handleDeposit,
        confirmationModalVisible,
        closeConfirmationModal,
        createNftRoyalGameLoading,
        handleOnConfirm,
        availableBalanceLoading,
        nftRoyaleGameConfig,
        max,
        formattedValue,
    } = useNftRoyalEnterCoinsAmountScreen();

    return (
        <KeyboardAvoidingView
            behavior={isIOS ? 'padding' : 'height'}
            style={styles.container}>
            <ModalLoader isVisible={createNftRoyalGameLoading} />
            <SafeAreaTabContent>
                <NavigationBar
                    filled={false}
                    backIcon="leftArrow"
                    title={i18n.t(
                        'HomeScreen.EnterCoinsAmountScreen.screenTitle'
                    )}
                />

                <ScrollView
                    scrollEventThrottle={16}
                    keyboardShouldPersistTaps="handled"
                    contentContainerStyle={styles.page}>
                    <View style={styles.inputWrapper}>
                        <View style={styles.icon}>
                            <CoinIcon slug={Coin.vigu} size={32} />
                        </View>
                        <Input
                            style={[
                                styles.inputContainer,
                                isWeb && {
                                    width: `${Math.max(
                                        formattedValue.length,
                                        3
                                    )}ch`,
                                },
                            ]}
                            value={formattedValue}
                            keyboardType="decimal-pad"
                            onBlur={handleOnBlur}
                            onChangeText={handleChangeAmount}
                            placeholder="0.0"
                            maxLength={11}
                            isAvailableExternalStyle
                        />
                    </View>

                    <View style={styles.availableWrapper}>
                        <Text style={styles.available} numberOfLines={1}>
                            {i18n.t('staking.coinsInputScreen.available')}
                            <Text style={styles.amount}>
                                {priceString(max, Coin.vigu, 2)}
                            </Text>
                        </Text>
                        <Button
                            onPress={handleClickAll}
                            title={i18n.t('staking.coinsInputScreen.maxButton')}
                            containerStyle={styles.buttonAll}
                            type="outline"
                            size="sm"
                        />
                    </View>
                    {!!errors.amount && (
                        <View style={styles.errorsWrapper}>
                            <Text style={styles.error}>{errors.amount}</Text>
                        </View>
                    )}
                    <Button
                        onPress={handleDeposit}
                        title={i18n.t('general.BUTTONS.topUpBalance')}
                        containerStyle={styles.topUp}
                        type="text"
                        size="md"
                        iconName={ICON_NAMES.DEPOSIT}
                    />
                    {!!nftRoyaleGameConfig && (
                        <MaxMinimalBetFeature
                            containerStyle={styles.feature}
                            maxAmount={nftRoyaleGameConfig.maxBetIguAmountWei}
                            minAmount={nftRoyaleGameConfig.minBetIguAmountWei}
                        />
                    )}
                </ScrollView>

                <ButtonsBottom
                    title={i18n.t('general.BUTTONS.createGame')}
                    onPress={submitForm}
                    safe
                    disabled={
                        !(isValid && dirty) ||
                        Number(values.amount) === 0 ||
                        availableBalanceLoading
                    }
                />
                <CreateGameConfirmationModal
                    game={MYSTERY_GAMES.nftRoyal}
                    isVisible={confirmationModalVisible}
                    onClose={closeConfirmationModal}
                    onConfirm={handleOnConfirm}
                    amount={values.amount}
                />
            </SafeAreaTabContent>
        </KeyboardAvoidingView>
    );
};

export default NftRoyalEnterCoinsAmount;
