import * as React from 'react';
import { View } from 'react-native';

import { RejectReasonItem } from '../../../../Data/Models';
import { Text } from '../../../../components/Themed';
import TouchableOpacity from '../../../../components/TouchableOpacity';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import stylesMain from './styles';

interface IProps {
    item: RejectReasonItem;
    isSelected: boolean;
    onSelectItem: (item: RejectReasonItem) => void;
}

const RejectReason = ({ item, onSelectItem, isSelected }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <TouchableOpacity
            activeOpacity={0.7}
            style={[styles.shadow, isSelected && styles.selectedShadow]}
            onPress={() => onSelectItem(item)}>
            <View style={[styles.container, isSelected && styles.selected]}>
                <View style={styles.wrapper}>
                    <Text style={styles.title}>
                        {i18n.t(
                            `admin.verify.modalPetIdNotFound.reasons.${item.text}.title`
                        )}
                    </Text>
                    <Text style={styles.text}>
                        {i18n.t(
                            `admin.verify.modalPetIdNotFound.reasons.${item.text}.text`
                        )}
                    </Text>
                </View>
            </View>
        </TouchableOpacity>
    );
};
export default RejectReason;
