import * as React from 'react';

import CheckoutSuccessScreen from '@components/CheckoutSuccessScreen';
import ImageWithName from '@components/ImageWithName';
import TransactionSummary from '@components/TransactionSummary';
import { useConfig } from '@contexts/ConfigContext';
import { useUser } from '@contexts/UserContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { priceString } from '@helpers/payments';
import { feeWithCurrency } from '@helpers/wallet';
import useFeeInfo from '@hooks/useFeeInfo';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import { MarketPlaceCreateOrderSuccessProps } from '../../../../types';

const SCREEN = 'MarketPlaceCreateOrderSuccessScreen';
const MarketPlaceCreateOrderSuccessScreen = ({
    route,
}: MarketPlaceCreateOrderSuccessProps) => {
    const navigation = useNavigation();
    navigation.blockHardwareBack();

    const { reloadUser } = useUser();
    const { config } = useConfig();

    React.useEffect(() => {
        reloadUser();
    }, []);
    const { renderFeeInfoIcon } = useFeeInfo();

    const { pet, price, transactionResult, commission } = route.params;

    const handleNavigateToMarketPlace = () => {
        navigation.navigate(ROUTES.MARKETPLACE, {
            screen: ROUTES.MARKETPLACE_ACTIVE_ORDERS,
        });
    };

    const transactionDetailsValues = [
        {
            key: i18n.t('checkout.fields.nft'),
            value: <ImageWithName petImg={pet.imageUrl} petName={pet.name} />,
        },
        {
            key: i18n.t('checkout.fields.from'),
            value: i18n.t('checkout.fields.nftWallet'),
        },
        {
            key: i18n.t('checkout.fields.to'),
            value: i18n.t('checkout.fields.nftMarketplace'),
        },
        {
            key: `${i18n.t('checkout.fields.commissionFee')} (${
                config?.marketplaceTransactionFeePercentage || 0
            }%)`,
            value: feeWithCurrency(commission, Coin.igu),
        },
        !!transactionResult?.transactionFeeFormatted
            ? {
                  key: i18n.t('checkout.fields.fee'),
                  value: transactionResult?.transactionFeeFormatted,
                  icon: renderFeeInfoIcon(true),
              }
            : undefined,
        {
            key: i18n.t('checkout.fields.totalSummary'),
            value: priceString(price, Coin.igu),
        },
    ];

    return (
        <CheckoutSuccessScreen
            title={i18n.t(`MarketPlace.${SCREEN}.title`)}
            text={i18n.t(`MarketPlace.${SCREEN}.text`)}
            onPressButtonMain={handleNavigateToMarketPlace}
            buttonMainText={i18n.t(`general.BUTTONS.viewMarketplace`)}
            TransactionDetailsComponent={
                <TransactionSummary values={transactionDetailsValues} />
            }
            explorerUrl={transactionResult?.explorerUrl}
        />
    );
};

export default MarketPlaceCreateOrderSuccessScreen;
