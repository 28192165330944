import { StyleSheet } from 'react-native';

import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import { stylesAlignCenter, stylesOverlayBlock } from '@helpers/style';

import Colors from '../../constants/Colors';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        paddings: {
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(8),
        },
        borderPaddings: {
            paddingLeft: scale(16),
            paddingRight: scale(0),
            paddingVertical: verticalScale(8),
        },
        itemContainer: {
            backgroundColor: Colors[theme].background,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        itemContainerWithBorder: {
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            paddingVertical: verticalScale(16),
            marginBottom: verticalScale(4),
        },
        petName: {
            flex: 1,
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '600',
            }),
            marginLeft: scale(6),
        },
        textWrapper: {
            backgroundColor: 'transparent',
            marginLeft: verticalScale(14),
            flex: 1,
        },
        imageWrapper: {
            width: scale(64),
            height: scale(64),
        },
        image: {
            width: '100%',
            height: '100%',
            borderRadius: 6,
        },
        titleWrapper: {
            backgroundColor: 'transparent',
            flexDirection: 'row',
            marginBottom: verticalScale(6),
            alignItems: 'center',
        },
        iconDark: {
            color: Colors[theme].icon.onDark,
        },
        shadow: {
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].shadow.default,
            marginBottom: verticalScale(16),
            flex: 1,
        },
        leftWrapper: {
            backgroundColor: 'transparent',
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
        },
        rightWrapper: {
            paddingLeft: scale(16),
            paddingRight: scale(16),
            height: '100%',
            justifyContent: 'center',
        },
        lvlUpItem: {
            position: 'absolute',
            width: scale(64),
            height: scale(64),
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
        },
        opacityBlock: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            opacity: 0.6,
            backgroundColor: Colors[theme].background,
            borderRadius: moderateScale(6),
        },
        overlay: {
            ...stylesOverlayBlock(theme, 0.6),
            ...stylesAlignCenter(),
            borderRadius: moderateScale(6),
            zIndex: 1,
        },
        overlayButton: {
            position: 'relative',
        },
        health: {
            flexDirection: 'row',
            backgroundColor: 'transparent',
            marginTop: verticalScale(4),
        },
        ageWrapper: {
            marginLeft: scale(6),
        },
        radio: {
            width: scale(20),
            height: scale(20),
            borderWidth: 1,
            borderRadius: 1000,
            borderColor: Colors[theme].border.primary,
            marginRight: verticalScale(16),
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
        },
        selectedShadow: {
            backgroundColor: Colors[theme].shadow.selected,
        },
        selected: {
            borderColor: Colors[theme].border.accent,
        },
        radioIcon: {
            width: scale(20),
            height: scale(20),
            marginRight: verticalScale(16),
        },
        arrowRight: {
            color: Colors[theme].icon.secondary,
        },
        restoreIcon: {
            color: Colors[theme].icon.onDarkSecondary,
        },
    });

export default styles;
