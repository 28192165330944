import { StyleSheet } from 'react-native';

import Colors from '../../../../../../constants/Colors';
import { scale, verticalScale } from '../../../../../../helpers/dimensions';
import {
    stylesAbsoluteFullImage,
    stylesSafeArea,
} from '../../../../../../helpers/style';
import { theme } from '../../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        safe: {
            ...stylesSafeArea(theme),
        },
        navBg: {
            padding: scale(20),
            backgroundColor: Colors[theme].background,
        },
        buttonWrapper: {
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
        },
        emptyBackgroundContainer: {
            flex: 1,
        },
        emptyBackground: {
            ...stylesAbsoluteFullImage(),
            resizeMode: 'stretch',
        },
    });

export default styles;
