import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '../../helpers/dimensions';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        buttonsContainer: {
            position: 'absolute',
            backgroundColor: 'transparent',
            top: verticalScale(35),
        },
        button: {
            backgroundColor: 'transparent',
            marginTop: verticalScale(12),
        },
    });

export default styles;
