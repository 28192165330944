import * as Sentry from 'sentry-expo';

import { APP_ENV, SENTRY_DSN } from '@env';
import { getDeviceId } from '@helpers/deviceId';
import { SeverityLevel } from '@sentry/types/types/severity';

export default class ErrorReporting {
    static async setup() {
        if (!SENTRY_DSN) {
            Console.info(
                '[ErrorReporting] Sentry is not initialized, as SENTRY_DSN is empty'
            );
            return;
        }

        const deviceId = await getDeviceId();
        Sentry.Browser.init({
            dsn: SENTRY_DSN,
            debug: false, // If `true`, Sentry will try to print out useful debugging information
            environment: APP_ENV,
            ignoreErrors: [
                'Network request failed',
                'Failed to fetch',
                'NetworkError',
                'Image is not animal',
            ],
        });
        Sentry.Browser.setTag('deviceId', deviceId);
    }

    static setUser(user: { id: string; email: string }) {
        if (!SENTRY_DSN) {
            return;
        }

        Sentry.Browser.setUser(user);
    }

    static report(error: any) {
        if (!SENTRY_DSN) {
            return;
        }

        Sentry.Browser.captureException(error);
    }

    static message(message?: any, severity?: SeverityLevel) {
        if (!SENTRY_DSN) {
            return;
        }

        Sentry.Browser.captureMessage(message);
    }
}
