import * as React from 'react';
import TooltipGuideRN from 'react-native-walkthrough-tooltip';

import { isString } from 'lodash';

import { scale } from '@helpers/dimensions';

import useThemedStyles from '../../hooks/useThemedStyles';
import { ComponentAnyType } from '../../types';
import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    children: React.PropsWithChildren<unknown>['children'];
    content: string | ComponentAnyType;
    placement?: 'top' | 'bottom';
    isVisible: boolean;
    onClose: () => void;
}

const TooltipSocial: React.FC<IProps> = ({
    children,
    content = '',
    isVisible,
    placement = 'top',
    onClose,
}) => {
    const styles = useThemedStyles(stylesMain);

    const [width, setWidth] = React.useState<number>(0);

    return (
        <TooltipGuideRN
            isVisible={isVisible}
            backgroundColor="transparent"
            placement={placement}
            contentStyle={[
                styles.webShadow,
                {
                    width: width + scale(12) * 2 + 2,
                    height: 10,
                    flex: 1,
                },
            ]}
            arrowStyle={styles.arrow}
            showChildInTooltip={false}
            disableShadow>
            <TooltipGuideRN
                isVisible={isVisible}
                onClose={onClose}
                content={
                    isString(content) ? (
                        <Text
                            style={styles.contentText}
                            onLayout={(event) =>
                                setWidth(event.nativeEvent.layout.width)
                            }>
                            {content}
                        </Text>
                    ) : (
                        <View
                            style={styles.contentView}
                            onLayout={(event) =>
                                setWidth(event.nativeEvent.layout.width)
                            }>
                            {content}
                        </View>
                    )
                }
                placement={placement}
                contentStyle={[styles.content, { width: 'auto' }]}
                arrowStyle={styles.arrow}
                childContentSpacing={7}
                showChildInTooltip={false}
                disableShadow>
                {children}
            </TooltipGuideRN>
        </TooltipGuideRN>
    );
};

export default TooltipSocial;
