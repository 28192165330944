import * as React from 'react';

import Button from '../../../../../components/Button';
import { ICON_NAMES } from '../../../../../components/Icons';
import { View } from '../../../../../components/Themed';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import stylesMain from './styles';

interface IProps {
    width: number;
    height: number;
    handleOnPress: () => void;
}

const DisabledBg = ({ width, height, handleOnPress }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    return (
        <View
            style={[
                styles.container,
                {
                    width: width,
                    height: height,
                },
            ]}>
            <View style={styles.opacityBlock}></View>

            <Button
                onPress={handleOnPress}
                iconName={ICON_NAMES.LOCK}
                type="outline"
                size="md"
            />
        </View>
    );
};
export default DisabledBg;
