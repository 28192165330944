import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
        },
        checkoutContainer: {
            paddingHorizontal: scale(0),
            borderWidth: 0,
        },
        petItem: {
            marginBottom: verticalScale(10),
        },
    });

export default styles;
