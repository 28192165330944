import * as React from 'react';

import StarSvg from '../images/star.svg';

interface IProps {
    size?: 20 | 16 | 25 | number;
}

const StarIcon: React.FunctionComponent<IProps> = ({ size = 28 }) =>
    React.useMemo(() => <StarSvg width={size} height={size} />, [size]);

export default StarIcon;
