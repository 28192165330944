import React from 'react';

import { BanReasons } from '@Data/Models';
import i18n from '@i18n/i18n';

import BanComment from '../components/BanComment';

export const useBanReasonComment = () => {
    const [banComment, setBanComment] = React.useState<string>('');
    const [banCommentError, setBanCommentError] = React.useState<string>('');

    const onTextChange = (val: string) => {
        setBanCommentError('');
        setBanComment(val);
    };

    const renderBanComment = React.useCallback(() => {
        return (
            <BanComment
                banComment={banComment}
                onTextChange={onTextChange}
                error={banCommentError}
            />
        );
    }, [banComment, banCommentError]);

    const checkBanComment = React.useCallback(
        (banReason: BanReasons) => {
            if (banReason === 'Other') {
                if (!banComment) {
                    setBanCommentError(
                        i18n.t('profile.adminBlockUser.error.banComment')
                    );
                    return false;
                }
            }
            return true;
        },
        [banComment]
    );

    const resetBanComment = () => {
        setBanComment('');
        setBanCommentError('');
    };

    return {
        resetBanComment,
        renderBanComment,
        checkBanComment,
        banCommentError,
        banComment,
    };
};
