import { StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import { scale, verticalScale } from '../../../../helpers/dimensions';
import font from '../../../../helpers/font';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        navBg: {
            backgroundColor: Colors[theme].navigationBar.background,
        },
        container: {
            flex: 1,
            paddingHorizontal: scale(20),

            backgroundColor: Colors[theme].background,
            alignContent: 'center',
            justifyContent: 'center',
        },
        headerText: {
            paddingHorizontal: 0,
            paddingTop: verticalScale(24),
            marginBottom: verticalScale(12),
        },
        mothPicker: {
            marginBottom: verticalScale(20),
        },
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 22,
                height: 28,
                weight: '700',
            }),
            marginBottom: verticalScale(8),
        },
        text: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
        footerLoader: {
            height: 50,
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
        },
    });

export default styles;
