import React from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native';

import { GetAgeRevivePaymentsResponse, Pet } from '@Data/Models';
import { GET_AGE_REVIVE_PAYMENTS } from '@Data/Requests';
import { useApolloClient } from '@apollo/client';
import Discount from '@assets/icons/ages/discount.svg';
import Icon from '@assets/icons/emptyState/oldAge.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import CardFeatureInfo from '@components/CardFeatureInfo/CardFeatureInfo';
import { useConfig } from '@contexts/ConfigContext';
import { usePlay } from '@contexts/PlayContext';
import { isAndroid } from '@helpers/app';
import { isGraphqlError } from '@helpers/helpers';
import { toastError, toastErrorUnknown } from '@helpers/toastNotification';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import ModalBottom from '../ModalBottom';
import mainStyles from './styles';

interface IProps {
    isVisible: boolean;
    closeModal: () => void;
    navigateBackOnUpgrade?: ROUTES;
    fromScreenModal?: boolean;
    pet: Pet;
    navigateBackFromCheckout?: ROUTES;
}

const ModalNFTisAgeDead = ({
    isVisible,
    closeModal,
    fromScreenModal = false,
    pet,
    navigateBackOnUpgrade,
    navigateBackFromCheckout,
}: IProps) => {
    const styles = useThemedStyles(mainStyles);
    const { config } = useConfig();
    const client = useApolloClient();
    const navigation = useNavigation();

    const { refetchPets } = usePlay();

    const fetchRevivePayments = async () => {
        const result = await client.query<GetAgeRevivePaymentsResponse>({
            query: GET_AGE_REVIVE_PAYMENTS,
            variables: {
                petId: pet.id,
            },
        });
        return result.data.ageRevivePayments;
    };

    const onRestorePress = async () => {
        if (fromScreenModal) {
            navigation.pop();
        }
        closeModal();
        try {
            const payments = await fetchRevivePayments();

            navigation.navigate(ROUTES.COLLECTION_PET_AGE_REVIVE_CHECKOUT, {
                pet,
                payments,
                navigateBackOnUpgrade,
                navigateBackFromCheckout,
            });
        } catch (error: any) {
            if (isGraphqlError(error, 'PET_WRONG_STATE')) {
                toastError(
                    undefined,
                    i18n.t('petIsDead.errors.PET_WRONG_STATE')
                );
                refetchPets(true);
            } else {
                toastErrorUnknown();
            }
        }
    };

    return (
        <ModalBottom
            modalHeight="90%"
            isVisible={isVisible}
            onClose={closeModal}>
            <ScrollView
                style={styles.container}
                showsVerticalScrollIndicator={false}>
                <TouchableOpacity activeOpacity={1}>
                    <BlockHeader
                        title={i18n.t(
                            'detailedPet.age.modalNFTisAgeDead.title'
                        )}
                        text={i18n.t('detailedPet.age.modalNFTisAgeDead.text', {
                            name: pet.name,
                        })}
                        type="emptyState"
                        emptyStateIcon={<Icon />}
                    />
                    {config && (
                        <View style={styles.featureWrapper}>
                            <CardFeatureInfo
                                icon={<Discount width={48} height={48} />}
                                title={i18n.t(
                                    'detailedPet.age.modalNFTisAgeDead.feature.title',
                                    {
                                        discount:
                                            config?.petAgeReviveBasePriceDiscountPercentage,
                                    }
                                )}
                                text={i18n.t(
                                    'detailedPet.age.modalNFTisAgeDead.feature.text',
                                    {
                                        discount:
                                            config?.petAgeReviveBasePriceDiscountPercentage,
                                    }
                                )}
                                warningText={i18n.t(
                                    'detailedPet.age.modalWhatIsAge.feature.alert'
                                )}
                            />
                        </View>
                    )}
                </TouchableOpacity>
            </ScrollView>
            <ButtonsBottom
                safe={!isAndroid}
                onPress={onRestorePress}
                title={i18n.t('detailedPet.age.modalNFTisAgeDead.button')}
            />
        </ModalBottom>
    );
};
export default ModalNFTisAgeDead;
