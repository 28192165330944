import * as React from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';

import { StakingPlanOutput } from '@Data/Models';
import InfoIcon from '@components/InfoIcon/InfoIcon';
import { Text } from '@components/Themed';
import { StakingCoin } from '@contexts/StakingContext';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';

import MaxBoostModal from '../MaxBoostModal';
import stylesMain from './styles';

interface IProps {
    currentPlan: StakingPlanOutput;
    coin: StakingCoin;
    onPress: () => void;
    containerStyle?: StyleProp<ViewStyle>;
}

const MaxBoost = ({ currentPlan, coin, onPress, containerStyle }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { additionalIgupAmountWeiStake, plan } = currentPlan;
    const { open, isVisible, close } = useVisibleHook();

    const handlePress = () => {
        onPress();
        close();
    };

    const boost = React.useMemo(() => plan.maxBoostRewardPercentage, [plan]);
    const amount = React.useMemo(
        () => balanceFromWei(additionalIgupAmountWeiStake).valueLong,
        [additionalIgupAmountWeiStake]
    );

    if (additionalIgupAmountWeiStake === '0') {
        return null;
    }

    return (
        //TODO:// CX styles please
        <TouchableOpacity
            activeOpacity={0.7}
            style={[styles.container, containerStyle]}
            onPress={open}>
            <Text style={styles.text}>
                {i18n.t('staking.coinsInputScreen.maxBoost', {
                    amount,
                    boost,
                    coin,
                })}
            </Text>
            <InfoIcon disabled iconColor={styles.text.color} />
            <MaxBoostModal
                isVisible={isVisible}
                coin={coin}
                amount={amount}
                boost={boost}
                onPress={handlePress}
                close={close}
            />
        </TouchableOpacity>
    );
};
export default MaxBoost;
