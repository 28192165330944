import { StyleSheet } from 'react-native';

import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
        },
        wrapper: {
            flex: 1,
            justifyContent: 'center',
            alignSelf: 'center',
            alignItems: 'center',
        },
    });

export default styles;
