import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            position: 'absolute',
            backgroundColor: 'transparent',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 10001,
            width: '100%',
            height: '100%',
        },
        withoutBg: {
            backgroundColor: 'transparent',
        },
        opacity: {
            opacity: 0.8,
            position: 'absolute',
            width: '100%',
            height: '100%',
        },
        innerContainer: {
            backgroundColor: Colors[theme].background,

            flex: 1,
            justifyContent: 'center',
            alignSelf: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
        },
        timer: {
            color: Colors[theme].text.accent,
            ...font({
                size: 150,
                height: 160,
                weight: '500',
            }),
            zIndex: 1,
        },
        text: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                weight: '500',
            }),
            marginBottom: verticalScale(16),
        },
        buttonContainer: {
            zIndex: 1,
            width: '100%',
        },
        btnText: {
            color: Colors[theme].text.secondary,
        },
    });

export default styles;
