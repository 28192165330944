import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import Termination from '@assets/icons/mysteryGames/termination.svg';
import CardFeatureInfo from '@components/CardFeatureInfo/CardFeatureInfo';
import { TERMINATION_DAYS } from '@contexts/MysteryGamesContext';
import i18n from '@i18n/i18n';

interface IProps {
    containerStyle?: StyleProp<ViewStyle>;
    termination: number | undefined;
}

const GameTermination = ({ containerStyle, termination }: IProps) => {
    return (
        <CardFeatureInfo
            containerStyle={containerStyle}
            icon={<Termination width={48} height={48} />}
            title={i18n.t('HomeScreen.GameTermination.title', {
                days: termination || TERMINATION_DAYS,
            })}
            text={i18n.t('HomeScreen.GameTermination.text')}
            warningText={i18n.t('HomeScreen.GameTermination.alert')}
        />
    );
};
export default GameTermination;
