import * as React from 'react';

import { DetectionResult } from '../../Data/Models';
import useThemedStyles from '../../hooks/useThemedStyles';
import { Text, View } from '../Themed';
import { recognitionResultsData } from './helpers';
import stylesMain from './styles';

interface IProps {
    detectionResult: DetectionResult[];
}

const RecognitionResults = ({ detectionResult }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const renderText = (label: string, confidence: number) => {
        const withConfidence = !(label === 'Animal' || label === 'Pet');
        return withConfidence ? `${label} ${Math.round(confidence)}%` : label;
    };

    return (
        <View style={styles.main}>
            {detectionResult.map(({ label, confidence }, index) => {
                const { color, icon } = recognitionResultsData(label);
                return (
                    <View
                        key={index}
                        style={[
                            styles.container,
                            {
                                backgroundColor: color,
                            },
                            index + 1 === detectionResult.length &&
                                styles.noMargin,
                        ]}>
                        <View style={styles.wrapper}>
                            <View style={styles.icon}>{icon}</View>
                            <Text style={styles.text}>
                                {renderText(label, confidence)}
                            </Text>
                        </View>
                    </View>
                );
            })}
        </View>
    );
};
export default RecognitionResults;
