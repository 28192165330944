import React from 'react';
import { Text, View } from 'react-native';

import { Pet } from '../../../../../Data/Models';
import AlertCard from '../../../../../components/AlertCard';
import Button from '../../../../../components/Button';
import ExplorerLink from '../../../../../components/ExplorerLink';
import Icon, { ICON_NAMES } from '../../../../../components/Icons';
import InfoIcon from '../../../../../components/InfoIcon/InfoIcon';
import ModalPetIsPlaying from '../../../../../components/ModalPetIsPlaying';
import Timer from '../../../../../components/Timer';
import { PlayContext } from '../../../../../contexts/PlayContext';
import { dateCooldownExpired } from '../../../../../helpers/time';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import { useNavigation } from '../../../../../navigation/useNavigation';
import DetailsBlock from '../DetailsBlock';
import Styles from './styles';

interface IProps {
    pet: Pet;
}

const BlockchainInfoBlock = ({ pet }: IProps) => {
    const { image, explorerUrl } = pet;
    const styles = useThemedStyles(Styles);
    const navigation = useNavigation();

    const { isPetPlaying } = React.useContext(PlayContext);

    const [isPlayingVisible, setIsPlayingVisible] =
        React.useState<boolean>(false);
    const [endTimer, setEndTimer] = React.useState<boolean>(false);

    const isFreeRank = pet.rank === 'free';

    const handleWithdraw = () => {
        if (isPetPlaying(pet) && !isPlayingVisible) {
            setIsPlayingVisible(true);
        } else {
            navigation.navigateToNFTSWalletWithdraw(pet);
        }
    };

    const renderPetIsPlayingModal = () => {
        return (
            <>
                <ModalPetIsPlaying
                    isVisible={isPlayingVisible}
                    pet={pet}
                    onClose={() => {
                        setIsPlayingVisible(false);
                    }}
                    onPress={handleWithdraw}
                />
            </>
        );
    };

    return (
        <DetailsBlock
            title={i18n.t('detailedPet.blockchainInfo.title')}
            description={
                !isFreeRank
                    ? i18n.t('detailedPet.blockchainInfo.description')
                    : i18n.t('detailedPet.blockchainInfo.descriptionFree')
            }>
            {!isFreeRank ? (
                <>
                    <View style={styles.tokenId}>
                        <Text style={styles.tokenIdTitle}>
                            {i18n.t('detailedPet.blockchainInfo.tokenId') +
                                `: `}
                        </Text>
                        <Text style={styles.tokenIdText}>#{pet.tokenId}</Text>
                    </View>
                    <View style={styles.coolDown}>
                        {!dateCooldownExpired(pet.cooldownExpiresAt) &&
                        !endTimer ? (
                            <AlertCard withIcon={false}>
                                <View style={styles.coolDownBlock}>
                                    <Text style={styles.countdownText}>
                                        {i18n.t(
                                            'detailedPet.blockchainInfo.cooldown'
                                        )}
                                    </Text>
                                    <Timer
                                        timerStyles={styles.countdown}
                                        endOfTime={pet.cooldownExpiresAt ?? ''}
                                        onEndTime={() => {
                                            setEndTimer(true);
                                        }}
                                    />
                                    <InfoIcon
                                        tooltipContent={i18n.t(
                                            'detailedPet.blockchainInfo.warningText'
                                        )}
                                        iconColor={
                                            styles.countdownTooltip.color
                                        }
                                    />
                                </View>
                            </AlertCard>
                        ) : (
                            <AlertCard
                                text={i18n.t(
                                    'detailedPet.blockchainInfo.warningText'
                                )}
                            />
                        )}
                    </View>
                    <View style={[styles.buttonWrapper, styles.margin]}>
                        <Button
                            iconName={ICON_NAMES.SHARE}
                            size="lg"
                            type={'outline'}
                            onPress={handleWithdraw}
                            title={i18n.t(
                                'detailedPet.blockchainInfo.withdraw'
                            )}
                        />
                    </View>
                    <View style={[styles.explorerLink, styles.margin]}>
                        <ExplorerLink
                            explorerUrl={explorerUrl}
                            containerStyles={styles.explorerButton}
                        />
                    </View>
                </>
            ) : (
                <></>
            )}

            {renderPetIsPlayingModal()}
        </DetailsBlock>
    );
};
export default BlockchainInfoBlock;
