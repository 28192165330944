import * as React from 'react';
import { useMemo } from 'react';
import { FlatList } from 'react-native';

import { stylesLoadMore } from '@helpers/style';
import { referralsConfig } from '@hooks/useReferrals';
import useThemedStyles from '@hooks/useThemedStyles';

import { ReferralUsersItem } from '../../../../../Data/Models';
import ListFilter, { IFilterData } from '../../../../../components/ListFilter';
import LoadingComponent from '../../../../../components/LoadingComponent';
import { Text, View } from '../../../../../components/Themed';
import i18n from '../../../../../i18n/i18n';
import ReferralListItem from '../ReferralListItem';
import stylesMain from './styles';

interface IProps {
    referrals: ReferralUsersItem[] | undefined;
    onReachEnd?: () => void;
    onReachEndLoading?: boolean;
    onOpenReferee: (item: ReferralUsersItem) => void;
    filter: string;
    handleChooseFilter: (item: string) => void;
}

const ReferralList = ({
    referrals,
    onReachEndLoading,
    onReachEnd,
    onOpenReferee,
    handleChooseFilter,
    filter,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const filterData = [
        {
            key: referralsConfig.filters[0],
            label: i18n.t(
                `referrals.invitedReferrals.header.filter.${referralsConfig.filters[0]}`
            ),
        },
        {
            key: referralsConfig.filters[1],
            label: i18n.t(
                `referrals.invitedReferrals.header.filter.${referralsConfig.filters[1]}`
            ),
        },
    ] as IFilterData[];

    return (
        <>
            <View style={styles.headerText}>
                <Text style={styles.headerTitle}>
                    {i18n.t('referrals.invitedReferrals.header.list')}
                </Text>

                <ListFilter
                    onSelect={handleChooseFilter}
                    selectedItem={filter}
                    data={filterData}
                    titleText={i18n.t('referrals.invitedReferrals.header.sort')}
                />
            </View>
            <FlatList
                onEndReached={onReachEnd}
                onEndReachedThreshold={0.1}
                showsVerticalScrollIndicator={false}
                data={referrals}
                initialNumToRender={10}
                renderItem={({ item, index }) => (
                    <ReferralListItem
                        item={item}
                        onPress={() => onOpenReferee(item)}
                        key={index}
                    />
                )}
            />
            {onReachEndLoading && (
                <LoadingComponent
                    containerStyles={{ ...stylesLoadMore() }}
                    text={i18n.t('general.loading')}
                />
            )}
        </>
    );
};
export default React.memo(ReferralList);
