/**
 * Learn more about using TypeScript with React Navigation:
 * https://reactnavigation.org/docs/typescript/
 */
import * as React from 'react';
import { ColorSchemeName } from 'react-native';

import { StakingOptionsType } from '@contexts/StakingContext';
import { USER_ACHIEVEMENTS, UserAchievementOutput } from '@models/achievements';
import { IGamingWalletTransactionDetails } from '@models/gamingWallet';
import {
    MarketplaceNftDetailsOrderOutput,
    MarketplaceNftDetailsPetOutput,
    MarketplaceNftDetailsSellerOutput,
    MarketplaceSaleablePetOutput,
} from '@models/marketplace';
import {
    LuckyStrikeAvailableGameOutput,
    LuckyStrikeGameHistoryOutput,
    QuantumSpinGameOutput,
    QuantumSpinGameProvableFairGameOutput,
    TokentellerAvailableGameOutput,
    TokentellerGameHistoryOutput,
} from '@models/mysteryGames';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import {
    Charity,
    LootboxSaleOption,
    MoveTaskStatus,
    PaymentOptionOutput,
    Pet,
    PetExperienceRewards,
    PlayGames,
    Rank,
    RankMintBonus,
    RewardsTransactionHistoryResponseItem,
    RewardsTransactionType,
    StakingPlanOutput,
    TasksHistoryResponseItem,
    TokenTransactionStatus,
    TokenTransactionType,
    UserStake,
    VerificationTaskResponseItem,
} from './Data/Models';
import { ITransactionResult } from './contexts/Wallet/WalletContext';
import { Coin, CoinBalance } from './contexts/Wallet/WalletHelpers';
import { HealTransactionData } from './hooks/usePetsHeal';
import ROUTES from './navigation/routes';

declare global {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace ReactNavigation {
        // eslint-disable-next-line @typescript-eslint/no-empty-interface
        interface RootParamList extends RootStackParamList {}
    }
}

export type ComponentAnyType =
    | React.ComponentType<any>
    | React.ReactElement
    | null
    | undefined;

export type theme = NonNullable<ColorSchemeName>;

export enum State {
    loading,
    notLoggedIn,
    loggedInNoWallet,
    loggedInNoPet,
    loggedInWallet,
    notLoggedInOnboarding,
}

export interface ITransactionDetails {
    date: string;
    value: string;
    color: string;
    type: TokenTransactionType;
    addressFrom: string;
    addressTo: string;
    fee: string;
    coinName: string;
    status: TokenTransactionStatus;
    explorerUrl: string;
    pet?: Pet;
    tokenID?: string;
}

export interface IRewardsTransactionDetails {
    date: string;
    value: string;
    color: string;
    type: RewardsTransactionType;
    status: TokenTransactionStatus;
    unlockedValue: string | null;
    childTransactions: RewardsTransactionHistoryResponseItem[] | null;
    total: string;
    totalUnlocked: string | null;
}

// Root
export type RootStackParamList = {
    Play: undefined;
    Charity: undefined;
    Collection: undefined;
    Marketplace: undefined;
    Admin: undefined;
    Referrals: undefined;
    Home: undefined;

    Main: undefined;
    WalletRoot: undefined;
    ProfileRoot: undefined;
    MintRoot: undefined;
    AdminRoot: undefined;

    AddWalletInitial: undefined;
    CreateWalletPassphrase: undefined;
    CreateWalletVerification: undefined;
    CreateWalletFirst: undefined;
    ImportWallet: undefined;
    CreateWalletSuccess: { type: 'create' | 'import' };

    /**
     * WALLET
     */
    // Coins
    WalletCoinsBalance: { balance: CoinBalance };
    WalletCoinsWithdraw: { balance: CoinBalance };
    WalletCoinsWithdrawSuccess: {
        transactionValue: string;
        transactionCoin: string;
        balance: CoinBalance;
        addressFrom: string;
        addressTo: string;
        transactionResult: ITransactionResult;
    };
    WalletCoinsTransactionDetails: {
        transaction: ITransactionDetails;
    };
    // NFTS
    WalletNFTSList: undefined;
    WalletNFTSBalance: undefined;
    WalletNFTSWithdraw: { pet: Pet };
    WalletNFTSWithdrawSuccess: {
        pet: Pet;
        addressFrom: string;
        addressTo: string;
        transactionResult: ITransactionResult;
    };
    WalletNFTSTransactionDetails: {
        transaction: ITransactionDetails;
    };
    // Rewards

    WalletRewardsMain: { filter?: string };
    WalletRewardsClaimSuccess: {
        total: string;
        transactionResult: ITransactionResult;
    };
    WalletRewardsTransactionDetails: {
        transaction: IRewardsTransactionDetails;
    };
    // Gaming
    WalletGamingMain: undefined;
    TopUpGamingWallet: undefined;
    TopUpCheckoutScreen: undefined;
    TopUpSuccessScreen: { transactionResult: ITransactionResult };
    WithdrawInputAmount: undefined;
    WithdrawIguSuccessScreen: {
        amount: string;
        coin: Coin;
        commission: number;
        transactionResult: ITransactionResult;
    };
    GamingWalletTransactionDetails: {
        transaction: IGamingWalletTransactionDetails;
    };
    // Trade
    WalletTradeMain: { balance?: CoinBalance };
    WalletTradeChart: {
        from: string;
        to: string;
    };
    WalletTradeSuccess: {
        estimate: string;
        from: string;
        to: string;
        fee: string;
        totalSummary: string;
        explorerUrl: string;
        balance: CoinBalance;
    };
    // Settings
    WalletSettings: undefined;
    WalletSettingsBackup: undefined;

    //Staking
    StakingWelcome: {
        navigateBack?: ROUTES;
        params?: { screen?: ROUTES };
    };
    StakingOptions: {
        type?: StakingOptionsType;
        plan?: StakingPlanOutput;
    };
    StakingPlans: {
        plan?: StakingPlanOutput;
    };
    StakingDetailedInfo: undefined;
    StakingCoinsInput: undefined;
    StakingDailyBoost: undefined;
    StakingSuccess: {
        transactionResult: ITransactionResult;
    };
    UnStakingSuccess: {
        transactionResult: ITransactionResult;
        currentStakingPlan: UserStake;
    };
    StakingAlready: {
        navigateBack?: ROUTES;
        params?: { screen?: ROUTES };
    };

    /**
     * END WALLET
     */

    CollectionMain: undefined;

    CollectionDetailedPet: {
        pet: Pet;
        navigateBack?: ROUTES;
    };
    CollectionPetLevelUpCheckout: {
        pet: Pet;
        payments: [PaymentOptionOutput];
        navigateBackOnUpgrade: ROUTES;
    };
    CollectionPetLevelUpSuccess: {
        pet: Pet;
        transactionResult: ITransactionResult | null;
        payment: PaymentOptionOutput;
        navigateBackOnUpgrade: ROUTES;
    };

    CollectionPetRankUpCheckout: {
        pet: Pet;
        payments: [PaymentOptionOutput];
        navigateBackOnUpgrade: ROUTES;
    };
    CollectionPetRankUpSuccess: {
        pet: Pet;
        transactionResult: ITransactionResult | null;
        payment: PaymentOptionOutput;
        navigateBackOnUpgrade: ROUTES;
    };
    CollectionPetHealUpCheckout: {
        pet: Pet;
        payments: [PaymentOptionOutput];
        navigateBackOnUpgrade: ROUTES;
        healthPoints: number;
        navigateBackFromCheckout?: ROUTES;
    };
    CollectionPetHealUpSuccess: {
        pet: Pet;
        transactionResult: ITransactionResult | null;
        payment: PaymentOptionOutput;
        navigateBackOnUpgrade: ROUTES;
        healthPoints: number;
    };
    CollectionMultiplePetsHealUpCheckout: undefined;
    CollectionMultiplePetsHealUpProceeding: {
        pets: Pet[];
        paymentOption: PaymentOptionOutput;
    };
    CollectionMultiplePetsHealUpSuccess: {
        results: HealTransactionData[];
        payment: PaymentOptionOutput;
    };

    CollectionPetReviveCheckout: {
        pet: Pet;
        payments: [PaymentOptionOutput];
        navigateBackOnUpgrade: ROUTES;
        navigateBackFromCheckout?: ROUTES;
    };
    CollectionPetReviveSuccess: {
        pet: Pet;
        transactionResult: ITransactionResult | null;
        payment: PaymentOptionOutput;
        navigateBackOnUpgrade: ROUTES;
    };
    CollectionPetAgeReviveCheckout: {
        pet: Pet;
        payments: [PaymentOptionOutput];
        navigateBackOnUpgrade: ROUTES;
        navigateBackFromCheckout?: ROUTES;
    };
    CollectionPetAgeReviveSuccess: {
        pet: Pet;
        transactionResult: ITransactionResult | null;
        payment: PaymentOptionOutput;
        navigateBackOnUpgrade: ROUTES;
    };

    MoveToEarnWelcome: undefined;
    MoveToEarnProgress: {
        permissions: {
            pedometer: boolean;
        };
    };
    MoveToEarnSummary: {
        energyEarned: number;
        averageSpeed: number;
        distanceTravelled: string;
        stepsCount: number;
        status: MoveTaskStatus;
    };

    PlayEditNFTs: undefined;
    PlayTasksHistoryDetails: { item: TasksHistoryResponseItem };
    PlayCollectedExperience: { pets: Pet[] };
    PlaySuccessScreen: {
        maxEnergy?: number;
        earnedEnergy?: number;
        petExperienceRewards?: PetExperienceRewards[];
        game: PlayGames;
        walkGame?: {
            stepsWalked: number;
            distanceTraveled: string;
            averageSpeed: string | number;
        };
        playGame?: {
            score: number;
            savedLives: number;
            fruitsCollected: number;
            livesCount: number;
        };
    };

    PlayToEarn: undefined;

    SocializeToEarnWelcomeShare: undefined;
    SocializeToEarnWelcomeVerify: undefined;
    SocializeToEarnShareYourPhoto: undefined;
    SocializeToEarnCreateSharePhoto: {
        uri: string;
        type: 'image' | 'video' | undefined;
    };
    SocializeToEarnCreateShareTemplate: undefined;
    SocializeToEarnVerifyUser: { petId?: string };
    SocializeToEarnVerifyUserSuccess: undefined;
    SocializeToEarnVerifyUserError: undefined;
    SocializeToEarnVerifyUserMatch: {
        currentSubtask: VerificationTaskResponseItem | null;
    };
    SocializeToEarnVerifyUserNotFound: undefined;
    SocializeToEarnVerifyUserFailed: undefined;
    SocializeToEarnVerifyUserAlreadyCompleted: undefined;
    SocializeToEarnShareYourPhotoSuccess: undefined;

    CharityOrganizations: { charities: Charity[] };
    CharityDetailedOrganization: { charity: string };
    CharityOrganizationDonations: { charity: string };
    CharityEditDetailedOrganization: { charity: string };
    CharityEditSelectedOrganization: { charities: Charity[] };

    PassCodeFaceId: undefined;
    PassCodeNew: undefined;
    PassCodeChange: undefined;
    PassCodeSuccess: undefined;
    PassCodeSettings: undefined;
    PassCodeConfirm: {
        change: boolean;
    };

    //
    Login: undefined;
    Dev: undefined;
    RanksDetailedInfo: {
        screenConfig: {
            slug: string;
            showAll?: boolean;
            selectRank?: boolean;
            paginated?: boolean;
            fromLootBox?: boolean;
            single?: boolean;
        };
    };
    ReferralsInvite: undefined;

    MysteryBoxMain: undefined;
    MysteryBoxPurchaseWelcome: undefined;
    MysteryBoxPurchase: {
        selectedOption: LootboxSaleOption;
        allAvailableOptions: LootboxSaleOption[];
    };
    MysteryBoxPurchaseCheckout: {
        selectedOption: LootboxSaleOption;
        allAvailableOptions: LootboxSaleOption[];
    };
    MysteryBoxPurchaseSuccess: {
        transactionResult: ITransactionResult | null;
        payment: PaymentOptionOutput;
        amount: number;
    };

    PlayTasks: undefined;

    // HOW TO
    HowToFindPetsId: undefined;
    HowToFee: undefined;
    HowToSocializeToEarn: undefined;
    HowToCharity: undefined;
    HowToPlay: { canStart: boolean };
    HowToReferrals: { direct: number; inDirect: number };
    HowToAvailableRewards: { slide?: number };
    HowToLockedRewards: undefined;
    HowToPurchaseBNB: undefined;
    HowToGamingAvailableWallet: { withButton: boolean };
    HowToGamingPendingWallet: undefined;
    HowToCommissionFee: undefined;
    HowToPurchaseSpadeId: undefined;
    HowToCollections: undefined;

    //On Boarding

    MainWalletOnBoardingScreen: undefined;
    LoginOnBoardingScreen: undefined;
    //

    EnergyInfoScreen: undefined;

    BonusMintRanksInfo: {
        screenInfo: {
            mintBonus: RankMintBonus;
            slug: string;
            withBtn: boolean;
        };
    };
    BonusMintRanksCarousel: {
        ranks: Rank[];
    };

    InitialMint: undefined;
    InitialReferral: undefined;

    YourPetsAreHungry: undefined;
    UpdateApp: { reset?: boolean };
    Achievements: undefined;
    AchievementsDetails: { achievement: UserAchievementOutput };

    // MysteryLikes
    MysteryLikesMain: undefined;
    HowMysteryLikesWork: undefined;
    WelcomeHowMysteryLikesWork: undefined;
    MysteryLikesCreateGameWelcome: undefined;
    MysteryLikesCreateGameInput: undefined;
    MysteryLikesCreateGameSuccess: undefined;
    MysteryLikesSelectRange: { isNewCreated?: boolean };
    MysteryLikesSelectRangeFinish: { fromHistory?: boolean };

    // NFT EVOLUTION
    HowNftEvolutionWelcome: undefined;
    NftEvolutionWelcome: undefined;
    NftEvolutionSelectPets: undefined;
    NftEvolutionCheckout: undefined;
    NftEvolutionSuccess: { id?: string };
    NftEvolutionSelectPet: undefined;
    NftEvolutionMintPet: undefined;
    NftEvolutionMintPetSuccess: {
        transactionResult: ITransactionResult;
        payment: PaymentOptionOutput;
        pet: {
            img: string;
            name: string;
            rank: string;
        };
    };

    // Nft Royal
    HowNftRoyalWork: undefined;
    WelcomeHowNftRoyalWork: undefined;
    NftRoyalCreateGameWelcome: undefined;
    NftRoyalGameConfigurations: undefined;
    NftRoyalEnterCoinsAmount: undefined;
    NftRoyalCreateGameSuccess: undefined;
    NftRoyalPlayGame: { isNewCreated?: boolean };
    NftRoyalGameHistory: { fromHistory?: boolean };

    // LuckyStrike
    WelcomeLuckyStrike: undefined;
    HowLuckyStrikeWork: undefined;
    LuckyStrikeAllParticipants: { id: string };
    LuckyStrikeWaitingForResult: { id: string; routeBack: ROUTES };
    LuckyStrikeUserTickets: {
        id: string;
    };
    LuckyStrikeUserTicketsWait: {
        game: LuckyStrikeAvailableGameOutput;
    };
    LuckyStrikeAllWinners: undefined;
    LuckyStrikeGameHistory: {
        game: LuckyStrikeGameHistoryOutput;
        force: boolean;
        id: string;
    };

    // Quantum Spin
    WelcomeQuantumSpin: undefined;
    HowQuantumSpinWork: undefined;
    QuantumSpinGameHistory: {
        data: QuantumSpinGameOutput;
    };
    QuantumSpinProbablyFair: undefined;
    QuantumSpinProbablyFairHistory: {
        history: QuantumSpinGameProvableFairGameOutput[];
    };

    // TokenTeller

    WelcomeTokenTeller: undefined;
    HowTokenTellerWork: undefined;
    TokenTellerChart: { coin1: Coin; coin2: Coin };
    TokenTellerAllParticipants: { id: string };
    TokenTellerWaitingForResult: { id: string; routeBack: ROUTES };
    TokenTellerGameHistory: {
        game: TokentellerGameHistoryOutput;
        force: boolean;
        id: string;
    };

    //MARKETPLACE
    MarketPlaceWelcomeCreateOrder: undefined;
    MarketPlaceSelectNft: undefined;
    MarketPlaceSelectNftPrice: {
        pet: MarketplaceSaleablePetOutput;
    };
    MarketPlaceCreateOrderSuccess: {
        pet: MarketplaceSaleablePetOutput;
        price: string;
        transactionResult: ITransactionResult | null;
        commission: string;
    };
    MarketPlaceProfile: {
        walletAddress: string;
    };
    MarketPlaceDelistSuccess: {
        petName: string;
        petImg: string;
        transactionResult: ITransactionResult | null;
    };
    MarketPlaceBuyCheckout: {
        pet: MarketplaceNftDetailsPetOutput;
        order: MarketplaceNftDetailsOrderOutput;
        owner: MarketplaceNftDetailsSellerOutput;
    };
    MarketPlaceBuySuccess: {
        pet: MarketplaceNftDetailsPetOutput;
        total: string;
        owner: MarketplaceNftDetailsSellerOutput;
        transactionResult: ITransactionResult | null;
    };
};

// Tasks tabs
export type TasksTabsParamList = {
    PlayTasksActive: {
        resetGoBack?: boolean;
        success?: boolean;
    };
    PlayTasksHistory: undefined;
};

// PlayToEarn tabs
export type PlayToEarnParamList = {
    PlayToEarnWelcome: {
        navigateBack?: ROUTES;
        status?: boolean;
    };
    PlayToEarnProgress: {
        height: number | undefined;
        width: number | undefined;
        status?: boolean;
    };
};

// Wallet tabs
export type WalletTabsParamList = {
    WalletCoinsMain: undefined;
    WalletNFTSMain: undefined;
    WalletRewardsTab: { filter?: string };
    WalletGamingTab: undefined;
};

// Admin
export type AdminStackParamList = {
    AdminVerify: undefined;
};

// Mint
export type MintStackParamList = {
    MintPetRanks: {
        rank?: Rank;
        skip?: boolean;
        goNext?: boolean;
    };
    MintPetSelectGeneration: undefined;
    MintGenerationInfo: undefined;
    MintPetPickPhoto: undefined;
    MintPetEditPhoto: undefined;
    MintPetName: undefined;
    MintPetCheckout: undefined;
    MintPetCheckoutSuccess: {
        transactionResult: ITransactionResult | null;
        isFree?: boolean;
        payment: PaymentOptionOutput;
    };
    MintSelectGeneratedNft: undefined;
};

// Profile
export type ProfileStackParamList = {
    ProfileMain: undefined;
    ProfileLanguagesSettings: undefined;
    ProfileNotificationsSettings: undefined;
    ProfileSocialAccountsSettings: undefined;
    ProfileSocialAccessibilitySettings: undefined;
    ProfileSocialMedia: undefined;
    AdminSendPushNotification: undefined;
    AdminReverification: undefined;
    AdminBlockUser: undefined;
    AdminMaintenanceMode: undefined;
    AdminAssignWhiteList: undefined;
    AdminAssignRole: undefined;
    AdminNftRoyaleRevokeBan: undefined;
};

export type ReferralsTabsParamList = {
    ReferralsInvites: { refId?: string };
    ReferralsAll: undefined;
};

export type LeaderboardTabsParamList = {
    LeaderboardDailyTasks: undefined;
    LeaderboardPvpGames: undefined;
};

export type MarketPlaceTabsParamList = {
    MarketPlaceActiveOrders: undefined;
    MarketPlaceMyProfile: undefined;
};

export type MarketPlaceOverviewParamList = {
    MarketPlaceOverview: {
        petId: string;
        cancelTransactionValuesStringified: string | null;
    };
    MarketPlaceOverviewOwnerHistory: {
        pet: MarketplaceNftDetailsPetOutput;
        order: MarketplaceNftDetailsOrderOutput;
        isOwner: boolean;
        cancelTransactionValuesStringified: string | null;
        owner: MarketplaceNftDetailsSellerOutput;
    };
};

export type MysteryLikesTabsParamList = {
    MysteryLikesActiveOrder: undefined;
    MysteryLikesHistory: undefined;
};

export type NftRoyalTabsParamList = {
    NftRoyalActiveOrder: undefined;
    NftRoyalHistory: undefined;
};

export type LuckyStrikeTabsParamList = {
    LuckyStrikeMain: { game?: LuckyStrikeAvailableGameOutput };
    LuckyStrikeHistory: { game?: LuckyStrikeAvailableGameOutput };
};

export type TokenTellerTabsParamList = {
    TokenTellerMain: { game?: TokentellerAvailableGameOutput };
    TokenTellerHistory: undefined;
};

export type QuantumSpinTabsParamList = {
    QuantumSpinMain: undefined;
    QuantumSpinHistory: undefined;
};

export type CollectionTabsParamList = {
    ReferralsInvites: { refId?: string };
    ReferralsAll: undefined;
};

export type RootStackScreenProps<Screen extends keyof RootStackParamList> =
    NativeStackScreenProps<RootStackParamList, Screen>;

export type CreateWalletSuccessProps = NativeStackScreenProps<
    RootStackParamList,
    'CreateWalletSuccess'
>;

export type CollectionDetailedPetProps = NativeStackScreenProps<
    RootStackParamList,
    'CollectionDetailedPet'
>;

export type CollectionPetLevelUpCheckoutProps = NativeStackScreenProps<
    RootStackParamList,
    'CollectionPetLevelUpCheckout'
>;

export type CollectionPetLevelUpSuccessProps = NativeStackScreenProps<
    RootStackParamList,
    'CollectionPetLevelUpSuccess'
>;

export type CollectionPetRankUpCheckoutProps = NativeStackScreenProps<
    RootStackParamList,
    'CollectionPetRankUpCheckout'
>;

export type CollectionPetHealUpSuccessProps = NativeStackScreenProps<
    RootStackParamList,
    'CollectionPetHealUpSuccess'
>;

export type CollectionMultiplePetsHealUpSuccessProps = NativeStackScreenProps<
    RootStackParamList,
    'CollectionMultiplePetsHealUpSuccess'
>;

export type CollectionPetHealUpCheckoutProps = NativeStackScreenProps<
    RootStackParamList,
    'CollectionPetHealUpCheckout'
>;

export type CollectionMultiplePetsHealUpCheckoutProps = NativeStackScreenProps<
    RootStackParamList,
    'CollectionMultiplePetsHealUpCheckout'
>;

export type CollectionMultiplePetsHealUpProceedingProps =
    NativeStackScreenProps<
        RootStackParamList,
        'CollectionMultiplePetsHealUpProceeding'
    >;

export type CollectionPetReviveSuccessProps = NativeStackScreenProps<
    RootStackParamList,
    'CollectionPetHealUpSuccess'
>;

export type CollectionPetReviveCheckoutProps = NativeStackScreenProps<
    RootStackParamList,
    'CollectionPetReviveCheckout'
>;

export type CollectionPetAgeReviveSuccessProps = NativeStackScreenProps<
    RootStackParamList,
    'CollectionPetAgeReviveSuccess'
>;

export type CollectionPetAgeReviveCheckoutProps = NativeStackScreenProps<
    RootStackParamList,
    'CollectionPetAgeReviveCheckout'
>;
export type CollectionPetRankUpSuccessProps = NativeStackScreenProps<
    RootStackParamList,
    'CollectionPetRankUpSuccess'
>;

export type MoveToEarnSummaryProps = NativeStackScreenProps<
    RootStackParamList,
    'MoveToEarnSummary'
>;

export type MoveToEarnProgressProps = NativeStackScreenProps<
    RootStackParamList,
    'MoveToEarnProgress'
>;

export type PlayToEarnWelcomeProps = NativeStackScreenProps<
    PlayToEarnParamList,
    'PlayToEarnWelcome'
>;

export type PlayToEarnProgressProps = NativeStackScreenProps<
    PlayToEarnParamList,
    'PlayToEarnProgress'
>;

export type SocializeToEarnVerifyUserProps = NativeStackScreenProps<
    RootStackParamList,
    'SocializeToEarnVerifyUser'
>;
export type SocializeToEarnVerifyUserMatchProps = NativeStackScreenProps<
    RootStackParamList,
    'SocializeToEarnVerifyUserMatch'
>;

export type SocializeToEarnCreateSharePhotoProps = NativeStackScreenProps<
    RootStackParamList,
    'SocializeToEarnCreateSharePhoto'
>;

export type MintPetCheckoutSuccessProps = NativeStackScreenProps<
    MintStackParamList,
    'MintPetCheckoutSuccess'
>;

export type MysteryBoxPurchaseCheckoutProps = NativeStackScreenProps<
    RootStackParamList,
    'MysteryBoxPurchaseCheckout'
>;

export type MysteryBoxPurchaseProps = NativeStackScreenProps<
    RootStackParamList,
    'MysteryBoxPurchase'
>;

export type MysteryBoxPurchaseSuccessProps = NativeStackScreenProps<
    RootStackParamList,
    'MysteryBoxPurchaseSuccess'
>;

export type WalletCoinsBalanceProps = NativeStackScreenProps<
    RootStackParamList,
    'WalletCoinsBalance'
>;

export type WalletCoinsWithdrawProps = NativeStackScreenProps<
    RootStackParamList,
    'WalletCoinsWithdraw'
>;

export type WithdrawSuccessProps = NativeStackScreenProps<
    RootStackParamList,
    'WalletCoinsWithdrawSuccess'
>;

export type WalletCoinsTransactionDetailsProps = NativeStackScreenProps<
    RootStackParamList,
    'WalletCoinsTransactionDetails'
>;

export type WalletNFTSWithdrawProps = NativeStackScreenProps<
    RootStackParamList,
    'WalletNFTSWithdraw'
>;

export type WalletNFTSTransactionDetailsProps = NativeStackScreenProps<
    RootStackParamList,
    'WalletNFTSTransactionDetails'
>;

export type WalletNFTSWithdrawPropsProps = NativeStackScreenProps<
    RootStackParamList,
    'WalletNFTSWithdrawSuccess'
>;

export type WalletRewardsClaimSuccessProps = NativeStackScreenProps<
    RootStackParamList,
    'WalletRewardsClaimSuccess'
>;

export type HowToAvailableRewardsProps = NativeStackScreenProps<
    RootStackParamList,
    'HowToAvailableRewards'
>;

export type TradeSuccessProps = NativeStackScreenProps<
    RootStackParamList,
    'WalletTradeSuccess'
>;

export type CharityOrganizationsProps = NativeStackScreenProps<
    RootStackParamList,
    'CharityOrganizations'
>;

export type CharityDetailedOrganizationProps = NativeStackScreenProps<
    RootStackParamList,
    'CharityDetailedOrganization'
>;

export type CharityOrganizationDonationsProps = NativeStackScreenProps<
    RootStackParamList,
    'CharityOrganizationDonations'
>;

export type CharityEditSelectedOrganizationProps = NativeStackScreenProps<
    RootStackParamList,
    'CharityEditSelectedOrganization'
>;

export type CharityEditDetailedOrganization = NativeStackScreenProps<
    RootStackParamList,
    'CharityEditDetailedOrganization'
>;

export type PlayTasksActiveProps = NativeStackScreenProps<
    TasksTabsParamList,
    'PlayTasksActive'
>;

export type PlayTasksHistoryDetailsProps = NativeStackScreenProps<
    RootStackParamList,
    'PlayTasksHistoryDetails'
>;

export type PlayCollectedExperienceProps = NativeStackScreenProps<
    RootStackParamList,
    'PlayCollectedExperience'
>;
export type PlaySuccessScreenProps = NativeStackScreenProps<
    RootStackParamList,
    'PlaySuccessScreen'
>;

export type WalletRewardsTransactionDetailsProps = NativeStackScreenProps<
    RootStackParamList,
    'WalletRewardsTransactionDetails'
>;
export type GamingWalletTransactionDetailsProps = NativeStackScreenProps<
    RootStackParamList,
    'GamingWalletTransactionDetails'
>;

export type RanksDetailedInfoProps = NativeStackScreenProps<
    RootStackParamList,
    'RanksDetailedInfo'
>;
export type MintPetRanksProps = NativeStackScreenProps<
    MintStackParamList,
    'MintPetRanks'
>;

export type StakingSuccessProps = NativeStackScreenProps<
    RootStackParamList,
    'StakingSuccess'
>;

export type UnStakingSuccessProps = NativeStackScreenProps<
    RootStackParamList,
    'UnStakingSuccess'
>;

export type WalletRewardsMainProps = NativeStackScreenProps<
    RootStackParamList,
    'WalletRewardsMain'
>;
export type HowToReferralsProps = NativeStackScreenProps<
    RootStackParamList,
    'HowToReferrals'
>;

export type ReferralsInvitesProps = NativeStackScreenProps<
    ReferralsTabsParamList,
    'ReferralsInvites'
>;
export type HowToPlayProps = NativeStackScreenProps<
    RootStackParamList,
    'HowToPlay'
>;
export type TradeMainProps = NativeStackScreenProps<
    RootStackParamList,
    'WalletTradeMain'
>;

export type TradeChartProps = NativeStackScreenProps<
    RootStackParamList,
    'WalletTradeChart'
>;

export type BonusMintRanksInfoProps = NativeStackScreenProps<
    RootStackParamList,
    'BonusMintRanksInfo'
>;

export type BonusMintRanksCarouselProps = NativeStackScreenProps<
    RootStackParamList,
    'BonusMintRanksCarousel'
>;

export type UpdateAppProps = NativeStackScreenProps<
    RootStackParamList,
    'UpdateApp'
>;
export type StakingWelcomeProps = NativeStackScreenProps<
    RootStackParamList,
    'StakingWelcome'
>;
export type StakingAlreadyProps = NativeStackScreenProps<
    RootStackParamList,
    'StakingAlready'
>;
export type StakingOptionsProps = NativeStackScreenProps<
    RootStackParamList,
    'StakingOptions'
>;
export type StakingPlansProps = NativeStackScreenProps<
    RootStackParamList,
    'StakingPlans'
>;

type Enumerate<
    N extends number,
    Acc extends number[] = []
> = Acc['length'] extends N
    ? Acc[number]
    : Enumerate<N, [...Acc, Acc['length']]>;
export type Range<F extends number, T extends number> = Exclude<
    Enumerate<T>,
    Enumerate<F>
>;

export type GameData = {
    points: number;
    lives: number;
    collectedFruits: number;
};

export type TopUpSuccessScreenProps = NativeStackScreenProps<
    RootStackParamList,
    'TopUpSuccessScreen'
>;
export type WithdrawIguSuccessScreenProps = NativeStackScreenProps<
    RootStackParamList,
    'WithdrawIguSuccessScreen'
>;
export type HowToGamingAvailableWalletProps = NativeStackScreenProps<
    RootStackParamList,
    'HowToGamingAvailableWallet'
>;
export type NftEvolutionSuccessProps = NativeStackScreenProps<
    RootStackParamList,
    'NftEvolutionSuccess'
>;

export type NftEvolutionMintPetSuccessProps = NativeStackScreenProps<
    RootStackParamList,
    'NftEvolutionMintPetSuccess'
>;

export type MarketPlaceSelectNftPriceProps = NativeStackScreenProps<
    RootStackParamList,
    'MarketPlaceSelectNftPrice'
>;
export type MarketPlaceCreateOrderSuccessProps = NativeStackScreenProps<
    RootStackParamList,
    'MarketPlaceCreateOrderSuccess'
>;
export type MarketPlaceDelistSuccessProps = NativeStackScreenProps<
    RootStackParamList,
    'MarketPlaceDelistSuccess'
>;

export type MarketPlaceOverviewProps = NativeStackScreenProps<
    MarketPlaceOverviewParamList,
    'MarketPlaceOverview'
>;
export type MarketPlaceOverviewOwnerHistoryProps = NativeStackScreenProps<
    MarketPlaceOverviewParamList,
    'MarketPlaceOverviewOwnerHistory'
>;

export type MarketPlaceProfileProps = NativeStackScreenProps<
    RootStackParamList,
    'MarketPlaceProfile'
>;

export type MarketPlaceBuySuccessProps = NativeStackScreenProps<
    RootStackParamList,
    'MarketPlaceBuySuccess'
>;
export type MarketPlaceBuyCheckoutProps = NativeStackScreenProps<
    RootStackParamList,
    'MarketPlaceBuyCheckout'
>;
export type MysteryLikesSelectRangeProps = NativeStackScreenProps<
    RootStackParamList,
    'MysteryLikesSelectRange'
>;

export type MysteryLikesSelectRangeFinishProps = NativeStackScreenProps<
    RootStackParamList,
    'MysteryLikesSelectRangeFinish'
>;

export type NftRoyalGameHistoryProps = NativeStackScreenProps<
    RootStackParamList,
    'NftRoyalGameHistory'
>;
export type NftRoyalPlayGameProps = NativeStackScreenProps<
    RootStackParamList,
    'NftRoyalPlayGame'
>;
export type AchievementsDetailsProps = NativeStackScreenProps<
    RootStackParamList,
    'AchievementsDetails'
>;
export type PassCodeConfirmProps = NativeStackScreenProps<
    RootStackParamList,
    'PassCodeConfirm'
>;

export type LuckyStrikeAllParticipantsProps = NativeStackScreenProps<
    RootStackParamList,
    'LuckyStrikeAllParticipants'
>;

export type LuckyStrikeWaitingForResultProps = NativeStackScreenProps<
    RootStackParamList,
    'LuckyStrikeWaitingForResult'
>;

export type LuckyStrikeUserTicketsProps = NativeStackScreenProps<
    RootStackParamList,
    'LuckyStrikeUserTickets'
>;
export type LuckyStrikeMainProps = NativeStackScreenProps<
    LuckyStrikeTabsParamList,
    'LuckyStrikeMain'
>;
export type LuckyStrikeHistoryProps = NativeStackScreenProps<
    LuckyStrikeTabsParamList,
    'LuckyStrikeHistory'
>;
export type LuckyStrikeUserTicketsWaitProps = NativeStackScreenProps<
    RootStackParamList,
    'LuckyStrikeUserTicketsWait'
>;
export type LuckyStrikeGameHistoryProps = NativeStackScreenProps<
    RootStackParamList,
    'LuckyStrikeGameHistory'
>;

export type QuantumSpinGameHistoryProps = NativeStackScreenProps<
    RootStackParamList,
    'QuantumSpinGameHistory'
>;
export type QuantumSpinProbablyFairHistoryProps = NativeStackScreenProps<
    RootStackParamList,
    'QuantumSpinProbablyFairHistory'
>;

export type TokenTellerMainProps = NativeStackScreenProps<
    TokenTellerTabsParamList,
    'TokenTellerMain'
>;
export type TokenTellerHistoryProps = NativeStackScreenProps<
    TokenTellerTabsParamList,
    'TokenTellerHistory'
>;
export type TokenTellerChartProps = NativeStackScreenProps<
    RootStackParamList,
    'TokenTellerChart'
>;

export type TokenTellerAllParticipantsProps = NativeStackScreenProps<
    RootStackParamList,
    'TokenTellerAllParticipants'
>;
export type TokenTellerWaitingForResultProps = NativeStackScreenProps<
    RootStackParamList,
    'TokenTellerWaitingForResult'
>;

export type TokenTellerGameHistoryProps = NativeStackScreenProps<
    RootStackParamList,
    'TokenTellerGameHistory'
>;
