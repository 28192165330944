import * as React from 'react';

import Distance from '../../../../../../assets/icons/play/tasks/move/distance.svg';
import Speed from '../../../../../../assets/icons/play/tasks/move/speed.svg';
import Steps from '../../../../../../assets/icons/play/tasks/move/steps.svg';
import { Text, View } from '../../../../../../components/Themed';
import { PlayContext } from '../../../../../../contexts/PlayContext';
import { isWeb } from '../../../../../../helpers/app';
import useThemedStyles from '../../../../../../hooks/useThemedStyles';
import i18n from '../../../../../../i18n/i18n';
import stylesMain from './styles';

interface IProps {
    stepsCount: number;
    averageSpeed: number | string;
    distanceTraveled: string;
}

export const SummaryInfo = ({
    distanceTraveled,
    stepsCount,
    averageSpeed,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { moveTask } = React.useContext(PlayContext);

    return (
        <View style={styles.main}>
            <Text style={styles.title}>
                {i18n.t('moveToEarn.summary.title')}
            </Text>
            <View style={styles.infoRow}>
                <View style={styles.measureContainer}>
                    <Distance />
                    <Text style={styles.measureText}>
                        {i18n.t('moveToEarn.summary.distance')}
                    </Text>
                </View>
                <Text style={styles.valueText}>
                    {distanceTraveled} /{' '}
                    {(moveTask?.currentTask?.maxDistanceMeters || 0) / 1000}{' '}
                    {i18n.t('moveToEarn.km')}
                </Text>
            </View>
            <View style={styles.infoRow}>
                <View style={styles.measureContainer}>
                    <Speed />
                    <Text style={styles.measureText}>
                        {i18n.t('moveToEarn.summary.averageSpeed')}
                    </Text>
                </View>
                <Text style={styles.valueText}>
                    {averageSpeed < 0 ? 0 : averageSpeed}{' '}
                    {i18n.t('moveToEarn.kmH')}
                </Text>
            </View>
            <View style={styles.infoRow}>
                <View style={styles.measureContainer}>
                    <Steps />
                    <Text style={styles.measureText}>
                        {i18n.t('moveToEarn.summary.steps')}
                    </Text>
                </View>
                <Text style={styles.valueText}>{stepsCount}</Text>
            </View>
        </View>
    );
};

export default SummaryInfo;
