import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Animated, {
    cancelAnimation,
    useAnimatedStyle,
    useSharedValue,
    withDelay,
    withRepeat,
    withTiming,
} from 'react-native-reanimated';

import { useMysteryGames } from '@contexts/MysteryGamesContext';
import useThemedStyles from '@hooks/useThemedStyles';

import NftEvolutionPetCard from '../NftEvolutionPetCard';
import stylesMain from './styles';

interface IProps {
    withAnimation?: boolean;
    containerStyle?: StyleProp<ViewStyle>;
}

export const BreedingAnimatedCards = ({
    withAnimation,
    containerStyle,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const firstPet = useSharedValue(withAnimation ? 0 : -8);
    const firstPetStyle = useAnimatedStyle(() => {
        return {
            transform: [
                { rotate: `${firstPet.value}deg` },
                {
                    translateY: -20,
                },
                { translateX: 15 },
            ],
            zIndex: -1,
        };
    });
    const secondPet = useSharedValue(withAnimation ? 0 : 4);
    const secondPetStyle = useAnimatedStyle(() => {
        return {
            transform: [{ rotate: `${secondPet.value}deg` }],
        };
    });

    const cancel = () => {
        cancelAnimation(firstPet);
        cancelAnimation(secondPet);
    };

    React.useEffect(() => {
        if (withAnimation) {
            firstPet.value = withDelay(
                100,
                withRepeat(
                    withTiming(-8, {
                        duration: 1000,
                    }),
                    -1,
                    true
                )
            );
            secondPet.value = withDelay(
                100,
                withRepeat(
                    withTiming(4, {
                        duration: 1000,
                    }),
                    -1,
                    true
                )
            );
        }

        return () => cancel();
    }, [withAnimation]);

    const { nftEvolutionFirstPet, nftEvolutionSecondPet } = useMysteryGames();

    return (
        <View style={[styles.container, containerStyle]}>
            <Animated.View style={[styles.card, firstPetStyle]}>
                {!!nftEvolutionFirstPet && (
                    <NftEvolutionPetCard disabled pet={nftEvolutionFirstPet} />
                )}
            </Animated.View>
            <Animated.View style={[styles.card, secondPetStyle]}>
                {!!nftEvolutionSecondPet && (
                    <NftEvolutionPetCard disabled pet={nftEvolutionSecondPet} />
                )}
            </Animated.View>
        </View>
    );
};
export default BreedingAnimatedCards;
