import React from 'react';
import { FlatList } from 'react-native';

import { useCharity } from '@contexts/CharityContexts';

import { View } from '../../../../components/Themed';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import NavigationBar from '../../../../navigation/NavigationBar';
import { CharityOrganizationsProps } from '../../../../types';
import OrganizationCard from '../components/OrganizationCard';
import stylesMain from './styles';

const CharityOrganizationsScreen = ({ route }: CharityOrganizationsProps) => {
    const { charities } = useCharity();
    const styles = useThemedStyles(stylesMain);
    return (
        <>
            <NavigationBar
                filled={false}
                wallet={false}
                backIcon="leftArrow"
                title={i18n.t('charity.organizationsTitle')}
            />
            <View style={styles.container}>
                <FlatList
                    pagingEnabled={false}
                    initialNumToRender={5}
                    data={charities}
                    showsVerticalScrollIndicator={false}
                    keyExtractor={(item) => item.id}
                    renderItem={({ item }) => (
                        <OrganizationCard
                            charity={item}
                            containerStyle={styles.card}
                        />
                    )}
                />
            </View>
        </>
    );
};

export default CharityOrganizationsScreen;
