import * as React from 'react';

import ButtonsBottom from '@components/ButtonsBottom';
import CustomModal from '@components/CustomModal';
import ImageIguana from '@components/ImageIguana';
import ModalLoader from '@components/ModalLoader';
import { useUser } from '@contexts/UserContext';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { errorsHandler } from '@helpers/errors';
import { priceString } from '@helpers/payments';
import { toastComingSoon } from '@helpers/toastNotification';
import { balanceFromWei } from '@helpers/wallet';
import useBlockchainErrorsHandler from '@hooks/useBlockchainErrorsHandler';
import { useLoadingHook } from '@hooks/useLoadingHook';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import {
    MarketplaceNftDetailsOrderOutput,
    MarketplaceNftDetailsPetOutput,
    MarketplaceNftDetailsSellerOutput,
} from '@models/marketplace';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import DelistNftModal from '../DelistNftModal';

interface IProps {
    pet: MarketplaceNftDetailsPetOutput;
    order: MarketplaceNftDetailsOrderOutput;
    isOwner: boolean;
    cancelTransactionValuesStringified: string | null;
    owner: MarketplaceNftDetailsSellerOutput;
}

const OverviewButtons = ({
    pet,
    order,
    isOwner,
    cancelTransactionValuesStringified,
    owner,
}: IProps) => {
    const navigation = useNavigation();
    const {
        isVisible: buyModal,
        open: openBuyModal,
        close: closeBuyModal,
    } = useVisibleHook();

    const {
        isVisible: delistModal,
        open: openDelistModal,
        close: closeDelistModal,
    } = useVisibleHook();

    const delistButton = React.useMemo(
        () => isOwner && !!cancelTransactionValuesStringified,
        [isOwner, cancelTransactionValuesStringified]
    );

    const onConfirmPress = () => {
        closeBuyModal();
        navigation.push(ROUTES.MARKETPLACE_BUY_CHECKOUT, {
            pet,
            order,
            owner,
        });
    };

    const amount = React.useMemo(
        () =>
            priceString(
                balanceFromWei(order.priceIguAmountWei).valueLong,
                Coin.igu
            ),
        [order]
    );

    return (
        <>
            {delistButton ? (
                <ButtonsBottom
                    title={i18n.t('general.BUTTONS.delistNft')}
                    onPress={openDelistModal}
                    safe
                    buttonType="critical"
                />
            ) : (
                !isOwner && (
                    <ButtonsBottom
                        title={i18n.t(`general.BUTTONS.buyForWithAmount`, {
                            amount,
                        })}
                        onPress={openBuyModal}
                        safe
                    />
                )
            )}
            {!isOwner && (
                <CustomModal
                    isVisible={buyModal}
                    icon={<ImageIguana type="attention" />}
                    titleText={i18n.t(
                        'MarketPlace.MarketPlaceOverview.proceedToCheckoutModal.title'
                    )}
                    infoText={i18n.t(
                        'MarketPlace.MarketPlaceOverview.proceedToCheckoutModal.text'
                    )}
                    firstButtonText={i18n.t('general.BUTTONS.continue')}
                    secondButtonText={i18n.t('general.BUTTONS.cancel')}
                    onFirstButtonClick={onConfirmPress}
                    onSecondButtonClick={closeBuyModal}
                />
            )}
            {delistButton && (
                <DelistNftModal
                    isVisible={delistModal}
                    onClose={closeDelistModal}
                    petName={pet.name}
                    petImg={pet.imageUrl}
                    cancelTransactionValuesStringified={
                        cancelTransactionValuesStringified!
                    }
                    shouldPop
                />
            )}
        </>
    );
};

export default OverviewButtons;
