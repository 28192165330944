import * as React from 'react';

import EmptyList from '@components/EmptyList';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

const NoResultFound = () => {
    const styles = useThemedStyles(stylesMain);

    return (
        <EmptyList
            containerStyle={styles.emptyList}
            emptyTitle={i18n.t(
                `MarketPlace.MarketPlaceActiveOrdersScreen.notFound.title`
            )}
            emptyText={i18n.t(
                `MarketPlace.MarketPlaceActiveOrdersScreen.notFound.text`
            )}
            withAnimation="cry"
        />
    );
};
export default NoResultFound;
