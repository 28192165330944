import { ISocial } from '../../../constants/Socials';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import Button from '../../Button';
import { Text, View } from '../../Themed';
import stylesMain from './style';

interface IProps {
    social: ISocial;
    onPress: () => void;
    title: string;
}

export const SocialMediaPreviewItem = ({ social, onPress, title }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    return (
        <View style={styles.shadow}>
            <View style={styles.itemContainer}>
                <View style={styles.main}>
                    <View style={styles.left}>
                        <View
                            style={{
                                width: styles.icon.width,
                                height: styles.icon.height,
                            }}>
                            {social.icon}
                        </View>
                        <Text style={styles.itemText}>{title}</Text>
                    </View>
                    <Button
                        size="sm"
                        onPress={onPress}
                        title={i18n.t('socialMediaConnect.socialPreview.view')}
                    />
                </View>
            </View>
        </View>
    );
};
