import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import { stylesAlignCenter } from '@helpers/style';

import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        loading: {
            ...stylesAlignCenter(),
        },
        container: {
            flex: 1,
            backgroundColor: Colors[theme].background,
        },
        header: {
            marginTop: verticalScale(16),
        },
        additionalMargin: { marginBottom: verticalScale(16) },
        content: {
            flex: 1,
            paddingHorizontal: scale(20),
            backgroundColor: Colors[theme].background,
            borderTopLeftRadius: moderateScale(20),
            borderTopRightRadius: moderateScale(20),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderBottomColor: Colors[theme].background,
            width: '100%',
            top: verticalScale(-20),
        },
    });

export default styles;
