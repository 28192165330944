import { Linking } from 'react-native';

import * as MailComposer from 'expo-mail-composer';
import { MailComposerStatus } from 'expo-mail-composer';

import { openUrlOrShowError } from '@helpers/linking';

interface MailOption {
    name: string;
    value: string;
}

declare type SendEmailResult = MailComposerStatus | 'error';

const useSendEmail = () => {
    // Stringify parameters into mail body
    const stringify = (data: MailOption[]): string => {
        let concatString = '\n'.repeat(5);

        for (let i = 0; i < data.length; ++i) {
            concatString += `${data[i].name}: ${data[i].value}\n`;
        }

        return concatString;
    };

    // Create special signature from user data
    const createSignature = (stringToHash: string): string => {
        return stringToHash
            .split('')
            .reduce(
                (prevHash, currVal) =>
                    ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0,
                0
            )
            .toString();
    };

    // Send using inline client
    const sendWithInline = async (
        to: string,
        cc: string,
        subject: string,
        bodyWithSignature: string
    ) => {
        return MailComposer.composeAsync({
            ccRecipients: cc !== to ? [cc] : undefined,
            subject,
            recipients: [to],
            body: bodyWithSignature,
        });
    };

    // Send using Linking
    const sendWithLinking = async (
        to: string,
        cc: string,
        subject: string,
        bodyWithSignature: string
    ) => {
        const url = `mailto:${to}?subject=${subject}&body=${bodyWithSignature}`;
        const canSend = await Linking.canOpenURL(url);

        if (!canSend) {
            return false;
        }

        return openUrlOrShowError(
            `mailto:${to}?subject=${subject}&body=${bodyWithSignature}${
                cc !== to ? cc : ''
            }`
        );
    };

    const send = async (
        to: string,
        cc: string,
        subject: string,
        additionalInfo: MailOption[]
    ): Promise<SendEmailResult> => {
        const body = stringify(additionalInfo);
        const signature = createSignature(body);
        const bodyWithSignature = stringify([
            ...additionalInfo,
            {
                name: 'Signature',
                value: signature,
            },
        ]);

        try {
            const canSend = await MailComposer.isAvailableAsync();
            if (canSend) {
                const result = await sendWithInline(
                    to,
                    cc,
                    subject,
                    bodyWithSignature
                );

                return result.status;
            }
            Console.log('Error open inline email, using Linking');

            const result = await sendWithLinking(
                to,
                cc,
                subject,
                bodyWithSignature
            );

            if (!result) {
                return MailComposerStatus.UNDETERMINED;
            }

            return MailComposerStatus.SENT;
        } catch (error: any) {
            Console.log(error);
            return 'error';
        }
    };

    return { send };
};

export default useSendEmail;
