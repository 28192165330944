import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import LinkIcon from '@assets/icons/general/linkIcon.svg';
import AlertCard from '@components/AlertCard';
import CopyToClipboardButton from '@components/CopyToClipboardButton';
import SocialIcons from '@components/Icons/SocialIcons';
import { Text } from '@components/Themed';
import { Links } from '@constants/Links';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    platform: string;
    platformUsername: string;
    containerStyle?: StyleProp<ViewStyle>;
}
const ShareYourSocialBlock = ({
    platform,
    platformUsername,
    containerStyle,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={[styles.container, containerStyle]}>
            <View style={styles.row}>
                <View style={styles.left}>
                    <View style={styles.iconBlock}>
                        <SocialIcons slug={platform.toLowerCase()} size={24} />
                    </View>
                    <Text style={styles.label}>{platform}</Text>
                </View>
                <Text
                    style={styles.title}
                    numberOfLines={1}>{`@${platformUsername}`}</Text>
            </View>
            <View style={styles.row}>
                <View style={styles.left}>
                    <View style={styles.iconBlock}>
                        <LinkIcon width={24} height={24} />
                    </View>
                    <Text style={styles.label}>
                        {i18n.t(`socializeToEarn.shareYourPetPhoto.link`)}
                    </Text>
                </View>
                <Text style={styles.title} numberOfLines={1}>
                    {Links.webSite}
                </Text>
                <CopyToClipboardButton value={Links.webSite} />
            </View>
            <AlertCard
                text={i18n.t(`socializeToEarn.shareYourPetPhoto.alert`)}
            />
        </View>
    );
};
export default ShareYourSocialBlock;
