/* eslint-disable react-hooks/exhaustive-deps */
import {
    MutableRefObject,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

import { DocumentNode, useLazyQuery } from '@apollo/client';

const MAX_COUNT = 20;
const MAX_TIME = 100000;

export const useLongPolls = <T>(
    query: DocumentNode
): {
    startPolling: (delay: number) => void;
    stopPolling: () => void;
    condition: MutableRefObject<((data: T) => boolean) | undefined>;
    onSuccess: MutableRefObject<((data: T) => void) | undefined>;
    onError: MutableRefObject<any>;
} => {
    const timerRef = useRef<ReturnType<typeof setTimeout>>();
    const condition = useRef<(data: T) => boolean>();
    const onSuccess = useRef<(data: T) => void>();
    const onError = useRef<(error: any) => void>();
    const [counter, setCounter] = useState<number>(0);

    const [_, { data, startPolling, stopPolling }] = useLazyQuery<T>(query, {
        fetchPolicy: 'no-cache',
        onCompleted: () => {
            setCounter((cn) => cn + 1);
        },
    });

    const breakPolling = useCallback(
        (error?: any) => {
            clearTimeout(timerRef.current);
            setCounter(0);
            stopPolling();
            if (error && onError.current) {
                onError.current(error);
            }
        },
        [stopPolling]
    );

    const start = useCallback(
        (delay: number) => {
            startPolling(delay);
            timerRef.current = setTimeout(
                () => breakPolling('EXCIDE TIME OF CALLS TO PULL'),
                MAX_TIME
            );
        },
        [breakPolling, startPolling]
    );

    useEffect(() => {
        if (data && condition.current && condition.current(data)) {
            if (onSuccess.current) onSuccess.current(data);
            stopPolling();
        }
    }, [data, stopPolling]);

    useEffect(() => {
        if (counter > MAX_COUNT) {
            breakPolling('EXCIDE NUMBER OF CALLS TO PULL');
        }
    }, [counter, breakPolling]);

    useEffect(() => {
        return () => breakPolling();
    }, []);

    return {
        startPolling: start,
        stopPolling,
        condition,
        onSuccess,
        onError,
    };
};
