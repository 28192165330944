import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import Colors from '../../../constants/Colors';
import font from '../../../helpers/font';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        safe: {
            backgroundColor: Colors[theme].background,
        },
        wrapper: {
            flex: 1,
            paddingHorizontal: scale(20),
            justifyContent: 'space-between',
            paddingBottom: verticalScale(16),
        },
        body: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
        },
        animation: {
            width: 240,
            height: 200,
        },
        title: {
            textAlign: 'center',
            ...font({
                size: 22,
                height: 28,
                weight: '800',
            }),
            color: Colors[theme].text.primary,
            marginTop: verticalScale(24),
        },
        text: {
            marginTop: verticalScale(8),
            textAlign: 'center',
            color: Colors[theme].text.secondary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
    });

export default styles;
