import * as React from 'react';
import { FlatList } from 'react-native';

import { BigNumber } from 'ethers';

import {
    GetHealupPaymentsResponse,
    PaymentOptionOutput,
    Pet,
} from '@Data/Models';
import { GET_HEALUP_PAYMENTS } from '@Data/Requests';
import { useApolloClient } from '@apollo/client';
import { PlayContext } from '@contexts/PlayContext';
import { isGraphqlError } from '@helpers/helpers';
import { toastError } from '@helpers/toastNotification';
import { useNavigation } from '@navigation/useNavigation';

import CheckoutScreen from '../../../../components/CheckoutScreen';
import HealthPetCollectionListItem from '../../../../components/HealthPetCollectionListItem';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import NavigationBar from '../../../../navigation/NavigationBar';
import ROUTES from '../../../../navigation/routes';
import { CollectionMultiplePetsHealUpCheckoutProps } from '../../../../types';
import stylesMain from './styles';

const CollectionMultiplePetsHealUpCheckoutScreen = ({
    route,
}: CollectionMultiplePetsHealUpCheckoutProps) => {
    const styles = useThemedStyles(stylesMain);
    const client = useApolloClient();
    const navigation = useNavigation();
    const { refetchPets, pets: allPets } = React.useContext(PlayContext);

    const [paymentsForPets, setPaymentsForPets] = React.useState<
        {
            pet: Pet;
            payments: PaymentOptionOutput[];
        }[]
    >([]);

    const pets: Pet[] = React.useMemo(() => {
        return (
            allPets?.filter(
                (pet) => pet.healUpAvailable && pet.health < 100 && !pet.dead
            ) ?? []
        );
    }, [allPets]);

    React.useEffect(() => {
        async function fetchPetPayment(pet: Pet) {
            const healthPoints = 100 - pet.health;
            return client.query<GetHealupPaymentsResponse>({
                query: GET_HEALUP_PAYMENTS,
                variables: {
                    petId: pet.id,
                    healthPoints,
                },
            });
        }
        async function loadPaymentsForPets(pets: Pet[]) {
            const petsPromises = pets.map(async (pet) => {
                const result = await fetchPetPayment(pet);
                return { pet, payments: result.data.healupPayments };
            });

            const resolvedPromises = await Promise.all(petsPromises);
            setPaymentsForPets(resolvedPromises);
        }

        if (!pets) {
            return;
        }

        loadPaymentsForPets(pets);
    }, [pets, client]);

    const sumPayments = React.useMemo(() => {
        if (!paymentsForPets.length) return [];

        return paymentsForPets
            .map((p) => p.payments)
            .reduce((prev, current) => {
                return prev
                    .map((prevPayment): PaymentOptionOutput => {
                        const currPayment = current.find(
                            (c) => c.token === prevPayment.token
                        );
                        if (!currPayment) return prevPayment;

                        const prevBigNumber = BigNumber.from(
                            prevPayment.amountWei
                        );
                        const currBigNumber = BigNumber.from(
                            currPayment.amountWei
                        );
                        const amountWei = prevBigNumber
                            .add(currBigNumber)
                            .toString();

                        return { ...prevPayment, amountWei };
                    })
                    .filter((item) => !item.tokenAddress);
            });
    }, [paymentsForPets]);

    const handleOnPurchase = async (payment: PaymentOptionOutput) => {
        setTimeout(
            () =>
                navigation.navigate(
                    ROUTES.COLLECTION_MULTIPLE_PETS_HEAL_UP_PROCEEDING,
                    {
                        pets,
                        paymentOption: payment,
                    }
                ),
            100
        );
        return null;
    };

    const handleOnError = (error: any) => {
        if (isGraphqlError(error, 'PET_WRONG_STATE')) {
            toastError(
                undefined,
                i18n.t('detailedPet.health.checkoutHealUp.errors.wrongState')
            );
            refetchPets(true);
        }
    };

    const navigateBack = React.useCallback(() => {
        navigation.pop();
    }, [navigation]);

    return (
        <>
            <NavigationBar backIcon wallet filled onBack={navigateBack} />
            <CheckoutScreen
                title={i18n.t(
                    'detailedPet.health.checkoutMultipleHealUp.title'
                )}
                text={i18n.t('detailedPet.health.checkoutMultipleHealUp.text')}
                payments={sumPayments}
                transactionsCount={pets.length}
                TransactionDetailsComponent={
                    <FlatList
                        data={pets}
                        showsVerticalScrollIndicator={false}
                        style={styles.container}
                        renderItem={({ item }) => (
                            <HealthPetCollectionListItem
                                key={item.id}
                                data={{
                                    pet: item,
                                    state: 'initial',
                                    healthToRestore: 100 - item.health,
                                }}
                                style={styles.petItem}
                            />
                        )}
                    />
                }
                transactionDetailsStyle={styles.checkoutContainer}
                withConfirmation={i18n.t(
                    'detailedPet.health.checkoutMultipleHealUp.transaction',
                    {
                        count: pets.length,
                    }
                )}
                onPurchase={handleOnPurchase}
                onPurchaseError={handleOnError}
            />
        </>
    );
};

export default CollectionMultiplePetsHealUpCheckoutScreen;
