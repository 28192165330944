import { DAILY_REWARD_STATUS } from '@Data/Models';
import { IGU_WALLET_TRANSACTIONS } from '@models/gamingWallet';
import {
    MARKETPLACE_ORDERS_SORT_OPTION,
    MARKETPLACE_PET_TYPE,
} from '@models/marketplace';
import {
    GAME_DIFFICULTY,
    LUCKY_STRIKE_GAMES_STATUS,
    MYSTERY_GAMES,
    MYSTERY_LIKES_STATUS,
    NFT_ROYAL_GAME_HISTORY_STATUS,
    NFT_ROYAL_GAME_MODE,
    TOKEN_TELLER_GAME_PREDICTION_DIRECTION,
    TOKEN_TELLER_GAME_STATUS,
    TOKEN_TELLER_HISTORY_GAMES_STATUS,
} from '@models/mysteryGames';
import ROUTES from '@navigation/routes';

const en = {
    createWalletPassphrase: {
        title: 'Your Seed Phrase',
        text: 'Please save these 12 words on a piece of paper. Mind their order when noting them down. Keep it in secure place.',
        button: 'Continue',
        loading: 'Generating seed phrase..',
    },
    createWalletVerification: {
        title: 'Verification',
        text: 'Please select correct words based on their numbers (the order they were written on the previous screen)',
        button: 'Continue',
        errors: {
            passphraseNotCorrect:
                'Words order is incorrect. Choose the right words according to their order',
        },
        loading: 'Checking seed phrase..',
    },
    createWalletFirst: {
        title: 'Write Down Your Seed Phrase',
        text: 'If your device is lost or stolen, you will be able to retrieve access to your funds using seed phrase. If you lost your seed phrase it will result in funds loss.',
        button: 'I Am Ready, Continue',
    },
    importWalletInit: {
        title: 'We Found Your Account',
        text: "You have logged from another device, and you don't have access to connected wallet. Please, provide 12 word seed phrase of your wallet to continue using the app",
        pets: 'Pets',
        import: 'Import Wallet',
        loading: 'Importing wallet..',
    },
    importWalletScreen: {
        title: 'Import a wallet',
        text: 'Enter your 12 word seed phrase, to be able to use your wallet inside the app',
        listTitle: 'Seed phrase',
        inputPlaceholder: 'Type here',
        clearAll: 'Clear all',
        importWallet: 'Import',
        privateKeyButton: 'Import from Private Key',
        privateKeyModal: {
            title: 'Import from Private Key',
            text: "If you don't have a seed phrase, you can import your wallet using a private key",
            label: 'Private Key',
            placeholder: 'Enter private key',
            button: 'Import',
        },
        errors: {
            mnemonicError:
                'Wallet with that 12 words seed phrase does not exists. Please double-check it again or provide another phrase',
            privateKeyError:
                'Wallet with that private key does not exists. Please double-check it again or provide another private key',
            walletNotEqual:
                "The wallet provided does not match the account's wallet",
            walletAlreadyVerified:
                "This wallet belongs to another account, can't import",
        },
        success: 'Wallet successfully imported',
    },
    createWalletInit: {
        createButton: 'Create a Wallet',
        importButton: 'Import a Wallet',
        slider: {
            titleSlide1: 'Connect a Wallet',
            textSlide1:
                'Create a new wallet or import an existing one by providing your 12 words seed phrase',
            titleSlide2: 'Self-Custodial Wallets',
            textSlide2:
                'IguVerse operates on a self-custodial wallet, where you have sole control of your private keys, which in turn control your cryptocurrency and prove the funds are yours',
            titleSlide3: 'Restore account',
            textSlide3:
                "If you lose access to the device and your seed phrase, we won't be able to restore your wallet and funds will be lost.",
        },
    },
    createWalletSuccess: {
        title: 'Wallet Created',
        text: 'Congrats! Your wallet was successfully created, you can start using your account now',
        button: 'Go to Final Step',
        buttonNew: 'Continue',
    },
    importWalletSuccess: {
        title: 'Wallet Imported',
        text: 'Congrats! Your wallet was successfully imported, you can start using your account',
        button: 'Continue',
        buttonNew: 'Go to Final Step',
    },
    login: {
        facebook: 'Continue with Facebook',
        google: 'Continue with Google',
        apple: 'Continue with Apple',
        email: 'Continue with email',
        twitter: 'Continue with Twitter',
        emailTitle: 'Log In / Sign Up',
        creatingAccount:
            'By creating account I agree to processing of my personal data according to',
        and: 'and',
        privacyPolicy: 'Privacy Policy',
        termsOfUse: 'Terms of Use',
        placeholderEmail: 'Enter email',
        labelEmail: 'Email',
        placeholderVerificationCode: 'Enter verification code',
        labelVerificationCode: 'Verification code will be sent to your email',
        sendCode: 'Send',
        resendIn: 'Resend in',
        seconds: 'sec',
        welcomeTo: 'Welcome to',
        codeSent: 'One Time Password sent!',
        errors: {
            invalidEmailAddress: 'Invalid email address',
            emailIsRequired: 'Email field is required',
            verificationCodeIsRequired:
                'One time password is required to log in to your account',
            verificationCodeIsBadOrExpired:
                'One time password is wrong or expired. Try again',
            verificationCodeTooMuchAttempts:
                'Too much attempts. Please try again later',
            loginFailed: 'Login failed',
            smthWentWrong:
                'Something went wrong during the log in. Try again later, or try a different method',
        },
        loading: {
            sendingCode: 'Sending code..',
            loggingIn: 'Logging in..',
        },
    },
    mintPetRank: {
        title: 'Select Your Pet Rank',
        text: 'Rank defines how much rewards your pet will earn for completing every day tasks',
        free: 'Free',
        roi: 'ROI ≈ ',
        dailyReward: 'Daily Reward ≈ ',
        buttonNext: 'Continue to Next Step',
        disabled: 'Only one Free rank per account is allowed',
        info: 'What is Rank?',
        whiteList: 'Whitelist ({{amount}})',
        mysteryBox: 'Mystery Box',
        mysteryBoxWithAmount: 'Mystery Box ({{amount}})',
    },
    mintPickPhoto: {
        title: 'Choose Your Pet Photo',
        text: 'Take the photo of your pet or choose your favorite pic from the gallery',
        or: 'or',
        takePhoto: 'Open Camera',
        selectFromGallery: 'Choose from Gallery',
        tips: {
            title: 'Guidelines for Perfect Photo',
            visible: 'Correct',
            cut: 'Cut',
            blurry: 'Blurry',
            glare: 'Glare',
            tipOne: 'Good light is essential',
            tipTwo: 'Try to make a shot with neutral background',
            tipThree: 'Make sure that pet is the only one in the photo',
        },
        analyzingPhoto: 'Analyzing your photo..',
        errors: {
            explicitContent: {
                title: 'Forbidden content',
                text: 'Image contains forbidden content. These actions may lead to account ban',
            },
            uploadError: {
                title: 'Upload failed',
                text: 'Check your internet connection and retry',
            },
            processImageFailed: {
                title: 'Image Error',
                text: 'Can not process your image at the moment. Try again or use different photo',
            },
            notAnimal: {
                title: 'No Animal Here',
                text: "Our algorithms can't recognize animal on your photo. Please, try again with different photo",
                try: 'Try Again',
                cancel: 'Cancel',
            },
        },
    },
    mintEditPhoto: {
        title: 'Customize Your Pet',
        text: 'Pick up the background for your NFT pet',
        loading: 'Preparing your image..',
    },
    mintNamePhoto: {
        title: 'Give Your Pet a Name',
        text: 'Your pet deserves the best name. Keep your name no longer than 20 characters',
        inputLabel: 'Name',
        placeHolder: 'e.g. Charlie',
        buttonNext: 'Continue to Next Step',
        infoText:
            'Our algorithms are able to recognize animals in pictures with 99% probability',
        deleteNFTphoto: 'Remove Photo',
        deleteNFTphotoText:
            'Are you sure you want to leave? Your progress will be lost',
        yesDelete: 'Yes, Take Me Back',
        noKeep: 'No, Continue',
        errors: {
            startSpace: 'Space can not be the first character of the name',
            specialCharacters: 'Name can not contain special characters',
            latinCharacters: 'Name can contain only characters from A to Z',
            numbers: 'Name can not contain numbers',
            doubleSpaces: 'Name can not contain double spaces',
            maxLength: 'Name can not be longer than 20 characters',
            minLength: 'Name can not be shorter than 2 symbols',
            mint: {
                title: 'Failed to Mint NFT',
                petRankWrongState: 'Pet rank is not available for mint',
                obscenePetName:
                    'Pet name can not contain bad words. Your account may be banned',
            },
        },
    },
    mintCheckout: {
        transaction: 'NFT mint',
        rank: 'Rank',
        nftCustomization: 'NFT',
        successTitle: 'New NFT Pet Created',
        successText:
            'You become a proud owner of a new pet. Remember to take a good care of it!',
        backToPlaying: 'Start Playing',
        backToCollection: 'View Collection',
    },
    wallet: {
        screenTitle: 'Wallet',
        loading: 'Loading wallet..',
        emptyTitle: "Can't load wallet",
        emptyText: 'Error while loading wallet',
        total: 'Total',
        deposit: 'Deposit',
        withdraw: 'Withdraw',
        trade: 'Trade',
        balances: 'Balances',
        exchanges: 'Exchanges',
        exchangesInfo:
            'You can exchange our tokens on any of the below listed exchanges',
        available: 'Available:',
        view: 'View',
        depositTo: 'Deposit To',
        depositBEP: 'Deposit {{coin}} (BEP-20)',
        testnet: 'Testnet',
        testnetWarning:
            'App is currently running in Testnet network, do not deposit real tokens here',
        howToPurchaseBNB: 'How to Purchase BNB',
        howToPurchaseIGU: 'How to Purchase IGU',
        warning:
            'Make sure you deposit your tokens to the correct address and network (BEP-20)',
        withdrawFrom: 'Withdraw From',
        transactionHistory: 'Transaction history',
        viewOnBscScan: 'View on BscScan',
        noTransactionYet: 'No Transactions Yet',
        noTransactionYetInfo: "You haven't made any transactions yet",
        transactionDetails: 'Transaction Details',
        amount: 'Amount',
        type: 'Type',
        date: 'Date',
        fromAddress: 'From Address',
        toAddress: 'To Address',
        blockChainFee: 'Blockchain fee',
        loadingWithdraw: 'Sending transaction..',
        transactions: {
            coin: {
                DEPOSIT: {
                    name: 'Deposit',
                    text: 'Crypto has arrived in your wallet. View transactions history for more details',
                },
                WITHDRAW: {
                    name: 'Withdrawal',
                    text: 'Crypto transferred out of your wallet. Please contact the recipient platform for you transaction receipt',
                },
                SWAP: {
                    name: 'Swap',
                    text: 'Crypto was swapped to another crypto using built-in DEX.',
                },
                CLAIM: {
                    name: 'Claimed Reward',
                    text: 'Reward claimed and transferred to your wallet balance',
                },
                NFT_PAYMENT: {
                    name: 'NFT Payment',
                    text: 'Crypto was used to pay in the app for mint, heal or upgrade. View transactions history for more details',
                },
                UNKNOWN: {
                    name: 'Other',
                    text: 'Any other network transaction including approve, fee etc. View transactions history for more details',
                },
                CONFIRMED: {
                    title: 'Completed',
                },
                FAILED: {
                    title: 'Failed',
                    text: 'Due to network issues, we were unable to process your transaction',
                },
                PENDING: {
                    title: 'Pending',
                    text: 'Transaction is not yet confirmed by the network. Usually, it takes from 5 min up to 1 hour',
                },
            },
            nft: {
                DEPOSIT: {
                    name: 'Deposit',
                    text: 'NFT has arrived in your wallet. View transactions history for more details',
                },
                WITHDRAW: {
                    name: 'NFT Withdrawal',
                    text: 'NFT transferred from IguVerse. Please contact the recipient platform for you transaction receipt',
                },
                MINT: {
                    name: 'Mint',
                    text: 'NFT was minted in IguVerse app',
                },
                CONFIRMED: {
                    title: 'Completed',
                },
                FAILED: {
                    title: 'Failed',
                    text: 'Due to technical work on the network, we were unable to process your transaction',
                },
                PENDING: {
                    title: 'Pending',
                    text: 'Transaction is not yet confirmed by the network. Usually, it takes from 5 min up to 1 hour',
                },
            },
            reward: {
                GameIterationReward: {
                    title: 'Tasks Reward',
                    text: 'Tasks locked reward for completing daily tasks',
                },
                ReferralReward: {
                    title: 'Referral Reward',
                    text: 'Referral reward for other user app activity',
                },
                Claim: {
                    title: 'Claim Reward',
                    text: 'The claim transaction converts your available virtual VIGUP rewards into your IGUP Main Wallet',
                },
                PetLevelUpPayment: {
                    title: 'Pet Level Up',
                    text: 'Internal payment for leveling up the pet',
                },
                PetRankUpPayment: {
                    title: 'Pet Rank Up',
                    text: 'Internal payment for ranking up the pet',
                },
                PetRevivePayment: {
                    title: 'Pet Restore',
                    text: 'Internal payment for restoring the pet',
                },
                PetHealUpPayment: {
                    title: 'Pet Healing',
                    text: 'Internal payment for healing the pet',
                },
                MintBonus: {
                    title: 'Mint Bonus',
                    text: 'Bonus Reward for NFT Mint',
                },
                CharityDonation: {
                    title: 'Charity Donation',
                    text: 'Donation to your selected Charity Organization',
                },
                GameIterationRewardSubtransaction: {
                    title: 'Tasks Reward',
                    text: 'Tasks locked reward for completing daily tasks',
                },
                CONFIRMED: {
                    title: 'Completed',
                },
                PENDING: {
                    title: 'Pending',
                    text: "The claim transaction was issued, however wasn't finished. You need to finish this transaction before you can make a new claim",
                },
                UNLOCK: {
                    title: 'Locked Rewards',
                    text: 'These rewards are pending and will be unlocked and available for Claim according to the unlock schedule.',
                    locked: '{{amount}} {{coin}} are Locked',
                },

                unlockTransactions: {
                    title: 'Unlocked Transactions',
                },
                UNKNOWN: {
                    name: 'Other',
                    text: 'Other type of transaction',
                },
                GameRewardStakeBonus: {
                    title: 'Boost Bonus',
                    text: 'Locked bonuses for boost',
                },
                GameRewardStakeBonusSubtransaction: {
                    title: 'Boost Bonus',
                    text: 'Locked bonuses for boost',
                },
            },
        },
        nft: {
            nft: 'NFT',
            tokenId: 'Token ID',
            myCollection: 'Collectibles',
            tooltip: 'Here you can view your NFT collectibles',
            noNFTYet: 'No Collectibles',
            noNFTYetInfo: 'Empty list',
            mintNewNft: 'Mint New NFT',
            NFTs: '{{balance}} NFTs',
            noNFTs: 'N/A',
            deposit: 'Deposit NFT',
            viewAll: 'View All',
            allNFTs: 'All NFTs',
            withdraw: 'Withdraw NFT',
            withdrawNftLabelAddress: 'Address',
            withdrawNftPlaceholderAddress: 'Enter address',
            withdrawNftTitle: 'Withdraw NFT',
            blockchainFee: 'Blockchain fee',
            confirmButtonLabel: 'Confirm Withdraw',
            confirmModal: {
                title: 'Confirm NFT Withdraw',
                nftName: 'NFT',
                addressFrom: 'From (Wallet)',
                addressTo: 'To Address',
                send: 'Confirm',
                cancel: 'Cancel',
            },
            withdrawSuccess: {
                title: 'NFT Withdrawal is Processing',
                text: 'NFT {{name}} was successfully withdrawn. Usually it takes from 5 min up to 1 hour',
                addressFrom: 'From (Wallet)',
                addressTo: 'To Address',
                backToWallet: 'Back to Wallet',
            },
            transactionNft: 'NFT Withdrawal',
            withdrawWarningText:
                'Make sure you withdraw your tokens to correct address and network (BEP-20). If you send you NFT to any other network or exchange wallet, you may permanently lose it',
            transactionHistory: 'Transaction History',
            noTransactionYet: 'No Transactions Yet',
            noTransactionYetInfo:
                "You haven't made any transactions yet. But it's not a problem, lest do it right now",
        },
        rewards: {
            tooltipText:
                'The Claim button allows you to convert your virtual reward tokens to on-chain IGUP tokens. The button will be available once you have pending rewards. ',
            claimButton: 'Claim',
            reclaimButton: 'Reclaim',
            stakeButton: 'Boost',
            rewardsWallet: 'Rewards Wallet',
            mainWallet: 'Main Wallet',
            transactionHistoryText: 'Transaction History',
            rewardsCarousel: {
                availableRewards: 'Available Rewards',
                availableRewards_text:
                    'Rewards available to be converted to IGUP tokens',
                lockedRewards: 'Locked Rewards',
                lockedRewards_text:
                    'Rewards will be available according to your unlock schedule',
                pendingRewards: 'Pending Claim Rewards',
                pendingRewards_text: 'Finish your claiming transaction',
                claimReward: 'Claim Reward',
            },
            transactionHistory: {
                Claim: 'Claimed reward',
                GameIterationReward: 'Tasks reward',
                ReferralReward: 'Referral reward',
                PetLevelUpPayment: 'Pet Level Up',
                PetRankUpPayment: 'Pet Rank Up',
                PetRevivePayment: 'Pet Restore',
                PetHealUpPayment: 'Pet Healing',
                MintBonus: 'Mint Bonus',
                CharityDonation: 'Charity Donation',
                GameIterationRewardSubtransaction: 'Unlock',
                GameRewardStakeBonus: 'Boost Bonus',
                GameRewardStakeBonusSubtransaction: 'Unlock',
            },
            filter: {
                title: 'Sort By',
                ALL: 'All',
                Claim: 'Claimed Reward',
                GameIterationReward: 'Tasks Reward',
                ReferralReward: 'Referral Reward',
                MintBonus: 'Mint Bonus',
                GameRewardStakeBonus: 'Boost Bonus',
            },
            empty: {
                title: 'No Transactions Yet',
                text: 'No worries and stay tuned. When you get rewards it will be displayed here',
                button: 'Refresh',
            },
            confirmationModal: {
                title: 'Confirm Claim',
                keys: {
                    from: 'From',
                    to: 'To (Wallet)',
                    fee: 'Blockchain Fee',
                    total: 'Total Summary',
                },
                values: {
                    rewardsWallet: 'Rewards Wallet',
                    mainWallet: 'IGUP Wallet',
                },
                warning:
                    'Make sure you have enough funds and BNB to cover a gas fee',
            },
            claimSuccess: {
                title: 'Reward Claim is Processing',
                text: 'You have initiated new claim transaction from your Rewards Wallet. Usually it takes from 5 min up to 1 hour',
                backToWallet: 'Back to Wallet',
            },
            claimLoading: 'Performing claim, do not close the app..',
            claimBusy:
                'Network is busy currently, please try again after some time',
        },
        gaming: {
            gamingWalletChangeFeatureText:
                'This is an approximate exchange ratio from IGU to VIGU',
            gamingWalletCarousel: {
                AvailableWallet: {
                    title: 'Available Wallet',
                    text: 'To take part, top up your gaming wallet with IGU',
                },
                PendingWallet: {
                    title: 'Pending Wallet',
                    text: 'The tokens will be frozen until you finish the game',
                },
            },
            gamingWalletButtons: {
                topUp: 'Top Up',
                withdraw: 'Withdraw',
            },
            TopUpGamingWalletScreen: {
                screenTitle: 'Top Up',
                title: 'Top Up The Gaming Wallet',
                text: 'To take part, top up your gaming wallet with IGU and receive VIGU',
                infoText: 'How it Works',
                button: 'Checkout',
                inputSection: {
                    infoText: 'Enter or select quantity you want to purchase',
                    errors: {
                        digits: 'Must be only digits',
                        min: 'Amount must be at least {{limit}} {{coin}}',
                        max: 'Amount must be at maximum {{limit}} {{coin}}',
                    },
                },
                howToModal: {
                    title: 'Top Up The Gaming Wallet',
                    text: 'To take part, top up your gaming wallet with IGU and receive VIGU. Use the button below to top up tokens',
                },
            },
            SelectWithdrawTokenModal: {
                title: 'Select Withdrawal Token',
                text: "You can select the preferred token, in which you'll receive your rewards in the Main Wallet",
                button: 'Select',
            },

            TopUpSuccessScreen: {
                title: 'Top Up Successful',
                text: "You've successfully deposited tokens to the Game Wallet. Join competitive games now!",
                button: 'Explore Games',
                buttonBack: 'Back to Game',
                button2: 'View Transactions History',
            },

            transactionHistory: {
                filter: {
                    title: 'Sort by',
                    [IGU_WALLET_TRANSACTIONS.All]: 'All',
                    [IGU_WALLET_TRANSACTIONS.Deposit]: 'Top Up',
                    [IGU_WALLET_TRANSACTIONS.Withdraw]: 'Withdraw',
                    [IGU_WALLET_TRANSACTIONS.RevertWithdraw]: 'Revert Withdraw',
                    [IGU_WALLET_TRANSACTIONS.MysteryLikesGamePayment]:
                        'Payment',
                    [IGU_WALLET_TRANSACTIONS.MysteryLikesGameRefund]: 'Expired',
                    [IGU_WALLET_TRANSACTIONS.MysteryLikesGameDraw]: 'Draw',
                    [IGU_WALLET_TRANSACTIONS.MysteryLikesGameWin]: 'Win',
                    [IGU_WALLET_TRANSACTIONS.MysteryLikesGameFee]: 'Fee',
                    [IGU_WALLET_TRANSACTIONS.DepositBonus]: 'Top Up Bonus',
                },
                transactions: {
                    [IGU_WALLET_TRANSACTIONS.Deposit]: {
                        title: 'Top Up',
                        text: 'Crypto are transferring to Gaming Wallet. Check more details on bscscan',
                    },
                    [IGU_WALLET_TRANSACTIONS.Withdraw]: {
                        title: 'Withdraw',
                        text: 'Crypto are transferring from Gaming Wallet. Check more details on bscscan',
                    },
                    [IGU_WALLET_TRANSACTIONS.RevertWithdraw]: {
                        title: 'Revert Withdraw',
                        text: 'Crypto are transferring from Gaming Wallet. Check more details on bscscan',
                    },
                    [IGU_WALLET_TRANSACTIONS.MysteryLikesGameDraw]: {
                        title: 'The Mystery of Likes Draw',
                        text: 'Crypto are transferring to Gaming Wallet. Check more details on bscscan',
                    },
                    [IGU_WALLET_TRANSACTIONS.MysteryLikesGamePayment]: {
                        title: 'The Mystery of Likes Payment',
                        text: 'Crypto are transferring from Gaming Wallet. Check more details on bscscan',
                    },
                    [IGU_WALLET_TRANSACTIONS.MysteryLikesGameRefund]: {
                        title: 'The Mystery of Likes Expired',
                        text: 'Crypto are transferring to Gaming Wallet. Check more details on bscscan',
                    },
                    [IGU_WALLET_TRANSACTIONS.MysteryLikesGameWin]: {
                        title: 'The Mystery of Likes Win',
                        text: 'Crypto are transferring to Gaming Wallet. Check more details on bscscan',
                    },
                    [IGU_WALLET_TRANSACTIONS.MysteryLikesGameFee]: {
                        title: 'The Mystery of Likes Fee',
                        text: 'Crypto are transferring to Gaming Wallet. Check more details on bscscan',
                    },
                    [IGU_WALLET_TRANSACTIONS.DepositBonus]: {
                        title: 'Top Up Bonus',
                        text: 'Crypto are transferring to Gaming Wallet. Check more details on bscscan',
                    },
                    [IGU_WALLET_TRANSACTIONS.DirectReferralDepositBonus]: {
                        title: 'Top Up Bonus (Direct)',
                        text: 'Crypto are transferring to Gaming Wallet. Check more details on bscscan',
                    },
                    [IGU_WALLET_TRANSACTIONS.IndirectReferralDepositBonus]: {
                        title: 'Top Up Bonus (Indirect)',
                        text: 'Crypto are transferring to Gaming Wallet. Check more details on bscscan',
                    },
                    [IGU_WALLET_TRANSACTIONS.MysteryLikesGameDirectReferralBonus]:
                        {
                            title: 'Referral Reward (Direct)',
                            text: 'Crypto are transferring to Gaming Wallet. Check more details on bscscan',
                        },
                    [IGU_WALLET_TRANSACTIONS.MysteryLikesGameIndirectReferralBonus]:
                        {
                            title: 'Referral Reward (Indirect)',
                            text: 'Crypto are transferring to Gaming Wallet. Check more details on bscscan',
                        },
                    [IGU_WALLET_TRANSACTIONS.Breeding]: {
                        title: 'NFT Evolution Mint',
                        text: 'Crypto are transferring from Gaming Wallet. Check more details on bscscan',
                    },
                    [IGU_WALLET_TRANSACTIONS.GamesTurnoverReward]: {
                        title: 'PVP Bonus',
                        text: 'Crypto are transferring to Gaming Wallet. Check more details on bscscan',
                    },
                    [IGU_WALLET_TRANSACTIONS.NftRoyaleGameDraw]: {
                        title: 'The Grand Race - Draw',
                        text: 'Crypto are transferring to Gaming Wallet. Check more details on bscscan',
                    },
                    [IGU_WALLET_TRANSACTIONS.NftRoyaleGamePayment]: {
                        title: 'The Grand Race - Payment',
                        text: 'Crypto are transferring from Gaming Wallet. Check more details on bscscan',
                    },
                    [IGU_WALLET_TRANSACTIONS.NftRoyaleGameRefund]: {
                        title: 'The Grand Race - Expired',
                        text: 'Crypto are transferring to Gaming Wallet. Check more details on bscscan',
                    },
                    [IGU_WALLET_TRANSACTIONS.NftRoyaleGameWin]: {
                        title: 'The Grand Race - Win',
                        text: 'Crypto are transferring to Gaming Wallet. Check more details on bscscan',
                    },
                    [IGU_WALLET_TRANSACTIONS.NftRoyaleGameFee]: {
                        title: 'The Grand Race - Fee',
                        text: 'Crypto are transferring to Gaming Wallet. Check more details on bscscan',
                    },
                    [IGU_WALLET_TRANSACTIONS.LuckyStrikeGameWin]: {
                        title: 'Lucky Strike - Win',
                        text: 'Crypto are transferring to Gaming Wallet. Check more details on bscscan',
                    },
                    [IGU_WALLET_TRANSACTIONS.LuckyStrikeGamePayment]: {
                        title: 'Lucky Strike - Ticket',
                        text: 'Crypto are transferring from Gaming Wallet. Check more details on bscscan',
                    },
                    [IGU_WALLET_TRANSACTIONS.QuantumSpinReward]: {
                        title: 'Quantum Disk - Reward',
                        text: 'Crypto are transferring to Gaming Wallet. Check more details on bscscan',
                    },
                    [IGU_WALLET_TRANSACTIONS.QuantumSpin]: {
                        title: 'Quantum Disk - Payment',
                        text: 'Crypto are transferring from Gaming Wallet. Check more details on bscscan',
                    },

                    [IGU_WALLET_TRANSACTIONS.TokentellerGamePredictWin]: {
                        title: 'Tokenteller - Reward',
                        text: 'Crypto are transferring to Gaming Wallet. Check more details on bscscan',
                    },
                    [IGU_WALLET_TRANSACTIONS.TokentellerGamePredict]: {
                        title: 'Tokenteller - Payment',
                        text: 'Crypto are transferring from Gaming Wallet. Check more details on bscscan',
                    },
                    [IGU_WALLET_TRANSACTIONS.TokentellerGamePredictFee]: {
                        title: 'Tokenteller - Fee',
                        text: 'Crypto are transferring from Gaming Wallet. Check more details on bscscan',
                    },
                    [IGU_WALLET_TRANSACTIONS.TokentellerGamePredictRefund]: {
                        title: 'Tokenteller - Draw',
                        text: 'Crypto are transferring to Gaming Wallet. Check more details on bscscan',
                    },
                },
            },
            WithdrawInputAmount: {
                screenTitle: 'Withdraw VIGU',
            },
        },
        stakingBanner: {
            title: 'Boost rewards and get bonuses!',
            text: 'Boosting with IGUP is available in the Rewards tab now!',
            button: 'Enable Boost',
        },
    },
    trade: {
        poweredBy: 'Powered by',
        from: 'From',
        to: 'To',
        estimate: ' (Estimate)',
        enterAmount: 'Enter amount',
        available: 'Max',
        selectToken: 'Select Token',
        deposit: 'Deposit',
        trade: 'Swap',
        rate: 'Exchange Rate',
        confirmTrade: 'Confirm Trade',
        cancel: 'Cancel',
        settings: 'Settings',
        settingTitle: 'Slippage Tolerance',
        slippageMainText:
            'Your transaction will revert if the price changes unfavorably by more than percentage bellow',
        save: 'Save',
        percentFour: 'Enter %',
        inputWarning:
            'Slippage Tolerance must be between {{minAmount}}% and {{maxAmount}}%. Please enter another percentage',
        checkout: {
            estimate: 'You pay',
            to: 'You receive',
        },
        processingTrade: 'Processing trade..',
        calculatingTrade: 'Calculating..',
        button: 'Trade',
        errors: {
            isRequired: 'The value is required.',
            maxAmountError:
                'Not enough tokens on balance. Please enter another amount or deposit',
            minLimit: 'Swap amount must be at least {{limit}} {{coin}}',
            bnbNotEnough:
                'Not enough BNB on your balance to cover a gas fee. Please enter another amount or deposit BNB',
            transaction: 'Transaction failed',
            DS_MATH_SUB_UNDERFLOW:
                'Amount of tokens is too big, try to input different amount',
        },
        tradeSuccess: {
            title: 'Swap Completed',
            text: 'Congrats! You have successfully swapped tokens. Your tokens are available on your balance. View more in transaction history',
            backToWallet: 'Back To Wallet',
            from: 'From',
            to: 'To',
            estimate: '(Estimate)',
            blockchainFee: 'Blockchain Fee',
            totalSummary: 'Total Summary',
            back: 'Continue Trading',
        },
    },
    tradingView: {
        title: 'Chart',
        back: 'Back to Trading',
    },
    walletSettings: {
        title: 'Wallet settings',
        header: 'Security Settings',
        description:
            'View your seed phrase and private key, as well as manage your passcode here',
        backup: {
            title: 'Seed Phrase',
            description:
                'Never show your seed phrase to anyone! It controls all your funds',
            modal: {
                title: 'Wallet Recovery Phrase / Key',
                text: 'Make sure nobody can see your secret data. By exposing it to anyone you risk to lose your funds',
                yes: 'View',
                no: 'Back',
                alert: "IguVerse team will never ask you about your seed phrase or private key. If someone asks you it's 100% a scammer, be careful!",
                checkbox:
                    'I acknowledge that I understand the risk if I share a seed phrase / private key with someone my tokens and pets will be stolen',
            },
        },
        passcode: {
            title: 'Passcode and Biometrics',
            description:
                'Set or change the passcode and toggle biometric identification',
        },
    },
    passcode: {
        passcode: 'Passcode',
        modal: {
            title: 'Enable Passcode',
            subTitle:
                'The four-digit code will enhance your security and will be used for confirming important actions',
        },
        welcome: {
            title: 'Enable Passcode',
            subTitle:
                'Enter the passcode, which you will use for accessing the app and confirming important actions',
        },
        new: {
            title: 'Enable Passcode',
            subTitle:
                'Enter the passcode, which you will use for accessing the app and confirming important actions',
        },
        confirm: {
            title: 'Confirm Passcode',
            subTitle:
                'Enter the passcode, which you will use for accessing the app and confirming important actions',
            error: "Incorrect code. Enter the same code you've typed in the previous step",
        },
        check: {
            title: 'Enter Passcode',
            subTitle: 'Enter yours current passcode to confirm',
            error: 'Incorrect code. Try again or use biometric identification',
        },
        success: {
            title: 'Your Account is Protected!',
            subTitle:
                'Now your IguVerse account and wallet are protected from malicious activity. You can always change the passcode in the Settings',
            startPlaying: 'Start Playing',
            backToSettings: 'Back to Settings',
        },
        change: {
            title: 'Change Passcode',
            subTitle:
                'Enter the new passcode, which will be used to confirm important actions',
        },
        faceId: {
            title: 'Enable Biometric Identification',
            subTitle:
                'Confirm important actions with simpler and faster verification. Your information won’t be collected by IguVerse',
        },
        settings: {
            turnOff: 'Turn Off Passcode',
            biometric: {
                title: 'Biometric Identification',
                subTitle:
                    'Use Face ID or your fingerprint to confirm important actions in the app',
            },
        },
        modalRemove: {
            title: 'Turn Off Passcode?',
            subTitle:
                'All your data is stored securely with the passcode. You can set it again in the settings at any time',
        },
        toasts: {
            turnOff: 'Success! Passcode turned off!',
            biometricOff: 'Success! Biometric off!',
            biometricOn: 'Success! Biometric on!',
            change: 'Success! Passcode changed!',
            biometricError: 'Error! Try Again!',
        },
    },
    walletSettingsBackup: {
        mnemonic: {
            title: 'Your Seed Phrase',
            text: 'Please save these 12 words on a piece of paper. Mind their order when noting them down. Keep it in secure place.',
            reveal: 'Hold to Reveal Phrase',
        },
        privateKey: {
            title: 'Your Private Key',
            text: 'Private key is a randomly-generated number that gives a user control over all funds linked to a wallet. Keep it secret',
            reveal: 'Hold to Reveal Private Key',
        },
        privateKeyModal: {
            title: 'Private Key',
            text: 'Write down your Private Key. Store it in the secure place',
        },
        buttons: {
            hidden: 'Hold to Reveal',
            visible: 'Hide',
        },
        viewPrivateKey: 'View your Private Key',
        emptyTitle: 'Error',
        emptyText: "Can't load wallet",
    },
    withdrawScreen: {
        title: 'Withdraw',
        labelAddress: 'Address',
        labelAmount: 'Amount',
        placeholderAddress: 'Enter address',
        placeholderAmount: 'Enter amount',
        labelAvailable: 'Available',
        allButtonLabel: 'Max',
        confirmButtonLabel: 'Confirm transaction',
        depositButtonLabel: 'Deposit',
        depositBEP: 'Deposit {{coin}} (BEP-20)',
        blockchainFee: 'Blockchain fee',
        totalSummary: 'Total summary',
        errors: {
            address: 'Address can not be empty',
            amountRequired: 'Amount can not be empty',
            invalidAddress: 'Invalid address wallet',
            sameWallet: 'Can not withdraw to your own wallet',
            digits: 'Must be only digits',
            maxAmountError:
                'Not enough tokens on balance. Please enter another amount or deposit',
            minLimit: 'Withdrawal must be at least {{limit}} {{coin}}',
            bnbNotEnough:
                'Not enough BNB on your balance to cover a gas fee. Please enter another amount or deposit BNB',
        },
        withdrawWarningText:
            'Make sure you withdraw your tokens to correct address and network (BEP-20)',
        withdrawWarningTextNFT:
            'If you send you NFT to any other network or exchange wallet, you may permanently lose it',
        transaction: 'Withdrawal',
        withdrawSuccess: {
            title: 'Withdrawal is Processing',
            text: 'You have initiated new withdrawal from your wallet. Usually it takes from 5 min up to 1 hour',
            backToWallet: 'Back to Wallet',
            viewTransactionHistory: 'View Transaction History',
        },
    },
    ModalConfirmTransaction: {
        title: 'Confirm transaction',
        confirm: 'Confirm',
        cancel: 'Cancel',
    },
    ModalCoinSelect: {
        select: 'Select Token',
    },
    ModalCropImage: {
        title: 'Crop Image',
        crop: 'Crop and Continue',
    },
    qrCodeScanner: {
        openSettings: 'Settings',
        noAccessToCamera: 'No access to camera',
        title: 'Position QR-Code in the frame',

        wallet: {
            titleScanned: 'Address scanned',
            text: "Scan QR-code of token's address. Make sure that all details are visible",
        },
        referral: {
            titleScanned: 'Referral code scanned',
            text: 'Scan QR-code of Referral code. Make sure that all details are visible',
        },
        verify: {
            titleScanned: 'Verify code scanned',
            text: 'Scan QR-code of Verify code. Make sure that all details are visible',
        },
    },
    nftSelectGeneration: {
        continue: 'Continue',
        search: 'Search',
        pageTitle: 'Create Your NFT Pet',
        pageInfo: 'You can choose the way your NFT will be created',
        nftFromPhoto: 'NFT from Photo',
        nftFromPhotoText:
            'Create a beautiful NFT from your own pet photo. Choose one of our splendid backgrounds for your future companion',
        nftFromImagination: 'NFT from Your Imagination',
        nftFromImaginationText:
            'Our AI / ML algorithms can draw your virtual pet exactly like you imagine it. Describe features of your pet and enjoy the result',
        provideMoreInformation: 'Describe Your Dream Pet',
        provideMoreInformationInfo:
            'Select from one of the suggested options below so that created NFT will be the perfect match to what you imagine',
        selectAccessories: 'Select Accessories',
        maximumAccessories: 'You can select maximum {{num}} accessories',
        selectGeneratedNFT: 'Choose Your Pet',
        selectGeneratedNFTInfo:
            'We created several unique pets as per your request. Choose the one you like most of all',
        continueNextStep: 'Continue to Next Step',
        generateNewNFT: 'Retry',
        generateNFTButton: 'Generate NFT',
        loadingModalText: 'Loading...',
        randomize: 'Randomize',
        clear: 'Clear',
        select: 'Select',
        noResult: 'No Results',
        noResultText:
            'There was no results for “{{query}}". Enter another keywords to search',
        form: {
            animal: {
                label: 'Animal',
                placeHolder: 'Select animal',
                modalTitle: 'Select Animal',
            },
            'drawing-type': {
                label: 'Type of Drawing',
                placeHolder: 'Select type of drawing',
                modalTitle: 'Select Type of Drawing',
            },
            cat: {
                label: 'Cat Breed',
                placeHolder: 'Select cat breed',
                modalTitle: 'Select Cat Breed',
            },
            dog: {
                label: 'Dog Breed',
                placeHolder: 'Select dog breed',
                modalTitle: 'Select Dog Breed',
            },
            'maturity-size': {
                label: 'Maturity / Size',
                placeHolder: 'Select maturity or size',
                modalTitle: 'Select Maturity or Size',
            },
            color: {
                label: 'Color',
                placeHolder: 'Select color',
                modalTitle: 'Select Color',
            },
            emotion: {
                label: 'Emotion',
                placeHolder: 'Select emotion',
                modalTitle: 'Select Emotion',
            },
            accessories: {
                label: 'Accessories',
                placeHolder: 'Select accessories',
                modalTitle: 'Select Accessories',
            },
            context: {
                label: 'Context',
                placeHolder: 'Select context',
                modalTitle: 'Select Context',
            },
            style: {
                label: 'Style',
                placeHolder: 'Select style',
                modalTitle: 'Select Style',
            },
        },
        errors: {
            maintenance: {
                title: 'Our AI models are overloaded',
                text: "Unfortunately our AI models couldn't serve your request at the moment. Please try again",
            },
        },
    },
    bottomNavigation: {
        play: 'Play',
        charity: 'Charity',
        statistics: 'Stats',
        collection: 'Collection',
        marketplace: 'Shop',
    },
    playScreen: {
        timeToComplete: 'Game Round Ends in',
        startPlaying: 'Start Playing',
        howToPlay: 'How to Play?',
        nftToPlay: 'Your Pets',
        edit: 'Edit',
        nftToPlayInfo:
            'Select your pets which will participate in the game and earn rewards',
        nftToPlayHint:
            'The order of your pets matters. The first pet will be the pet visible on your Pet ID in sharing game. The first pet is also getting the most experience for performing the tasks',
        noNFTyet: 'Nobody Lives Here',
        noNFTyetInfo:
            "Unfortunately, you don't have animal companion yet. Get your first pet and start playing",
        exploreShop: 'Explore Shop',
        mintNew: 'Get Your First Pet',
        dailyTasks: 'Daily Tasks',
        dailyTasksInfo:
            'Complete your daily tasks to earn energy and then exchange it to tokens',
        timeLeftToComplete: 'Game Round Ends In',
        selectNFT: 'Add more pets',
        playCards: {
            SHARE_title: 'Share Your Pet ',
            SHARE_info:
                'Your pets need daily attention.\nShare it in social media',
            SHARE_button: 'Open Social Task',
            addSocialAccount: 'Add Social Account',
            socialAlert:
                'Social task verification may last up to 12 hours, completing it later may result in Energy credited in the next game iteration',
            MOVE_title: 'Walk Your Pets',
            MOVE_info: 'Your pets need to walk everyday.\nWalk with your pets',
            MOVE_button: 'Open Walking Task',
            PLAY_title: 'Play with Your Pets',
            PLAY_info: 'Play with your pets in reaction-based arcade mini-game',
            PLAY_button: 'Open Playing Task',
            VERIFY_title: 'Find Other Pets',
            VERIFY_info: "Find other user's pets in social media",
            VERIFY_button1: 'Continue Find Task',
            VERIFY_button2: 'Find Other Pets',
            completedEnergy:
                '{{energyRewarded}} / {{maxEnergyReward}} Energy Collected',
            SHARE_failed_title: "Your Post hasn't been Verified",
            SHARE_failed_text:
                "When verifying your post, other users did not manage to find or see your Pet ID. We had a look at the post and weren't able to verify it either. Sadly, you will not receive any energy from this task today. Please, try to be more attentive next time",
            SHARE_failed_button: 'Back to Play',
            knowledgeBase: 'View Knowledge Base',

            SHARE_failed_reasons: {
                notEnoughFollowers:
                    'It seems that you account is not active enough. Please, choose another account or try to develop this one',
                petIdNotFound:
                    "Other users said they couldn't find Pet ID posted on your account. Please, do not forget to post next time",
                petIdIncorrectPosition:
                    'Your Pet ID is placed in such a way that other users cannot make it out. Try to be more careful next time',
                userNotFound:
                    'Both users and admins could not find your account. Did anything happen to it?',
            },

            SHARE_success_title: 'Sharing Task Completed',
            SHARE_success_text:
                'Congrats! You shared a photo and it passed verification. Come back tomorrow for more',
            SHARE_success_button: 'View Details',
        },
        modalAllDead: {
            title: 'Your Pets Are Dead',
            text: 'You can not play tasks until at least one of your pet is in the good shape. Restore your pets and come back',
            button: 'Restore Pets',
        },
        modalSomeDead: {
            title: 'Some Of Your Pets Are Dead',
            text: "Some of your pets are dead, and they won't be earning rewards. Are you sure you want to continue?",
            button: 'Restore Pets',
            button2: 'Continue Play',
        },
    },
    collectionsScreen: {
        title: 'My Collection',
        text: 'This is a home for your awesome pets',
        mintNewNft: 'Get Your First Pet',
        mintPet: 'Create a Pet',
        noCollectionsYet: 'Nobody Lives Here',
        noCollectionsYetInfo:
            "Unfortunately, you don't have animal companion yet. How can you live without a pet?",
        levelUp: 'Level Up',
        rankUp: 'Rank Up',
        restore: 'Restore',
        feed: 'Feed',
        healAllButtonTooltip:
            'You can use mass pet healing only if you have at least 2 pets with health lower than 100%',
    },
    detailedPet: {
        headerSubtitle:
            'This is your NFT pet, you can complete daily tasks with the help of it and get tokens rewards. Take good care of it',
        levelXP: {
            title: 'Level & XP',
            text: 'Earn XP for completing tasks and increase level of your pet. After reaching certain level pet can upgrade to higher Rank',
            textUpgrade:
                "You've achieved all experience in {{level}} level. Upgrade your NFT to new level to earn more tokens",
            upgrade: 'Upgrade to Level {{level}}',
        },
        animalType: {
            title: 'Animal Type',
            text: 'Our algorithms are able to recognize animals on pictures with high probability',
        },
        rank: {
            title: 'Rank {{rank}}',
            text: 'Rank is one of the main pet characteristic. Higher Ranks earn more rewards and have higher Reward Unlock rate',
            upgrade: 'Upgrade to "{{rank}}" Rank',
            textUpgrade:
                'You\'ve achieved all levels in "{{rank}}" rank. Upgrade to new rank to earn more tokens',
        },
        age: {
            button: 'Restore NFT',
            Baby: 'Baby = {{current}} / {{max}} Days',
            Young: 'Young = {{current}} / {{max}} Days',
            Adult: 'Adult = {{current}} / {{max}} Days',
            Old: 'Old = {{current}} / {{max}} Days',
            description:
                'The age of your pet is considered from the moment of its creation. Once your NFT grows old, it will die and will need to be restored',
            descriptionDead:
                'Pet has lived a long and happy life and passed away. No need to be upset, you can restore it and play again!',
            dailyUnlock: 'Daily Unlock = ',
            daysMore: 'days more',

            modalWhatIsAge: {
                title: 'What is Age?',
                text: 'Once you create your NFT pet, it will start living and aging. As it grows older, it will eventually die. In this case, it will need to be restored. The longevity of your virtual pet depends on its Rank',
                feature: {
                    title: 'Discount On Restoring = {{discount}}%',
                    text: 'After the natural death of the NFT, you will be able to restore it with a {{discount}}% discount (retaining the Rank)',
                    alert: "A Rank Up will reset the age of your pet back to 0, and the upgraded virtual pet will live according to the age limit of the new Rank. After restoring the NFT, you'll start from Level 1",
                },
            },
            modalNFTisAgeDead: {
                button: 'Restore',

                title: 'Pet Was Old And Died',
                text: 'NFT pet "{{name}}" has lived a long and happy life and passed away. No need to be upset, you can restore it and play again!',
                feature: {
                    title: 'Discount On Restoring = {{discount}}%',
                    text: 'After the natural death of the NFT, you will be able to restore it with a {{discount}}% discount (retaining the Rank)',
                    alert: "A Rank Up will reset the age of your pet back to 0, and the upgraded virtual pet will live according to the age limit of the new Rank. After restoring the NFT, you'll start from Level 1",
                },
            },
            checkoutAgeRevive: {
                title: 'Restore Pet',
                text: "Your pet was old and died. It isn't available for daily tasks. Restore it to play and earn more tokens",
                restoreTo: 'Restore to',
                transaction: 'Restore "{{name}}" NFT',
                errors: {
                    wrongState: 'Something is wrong, can not revive pet.',
                },
            },
            successAgeRevive: {
                title: 'Pet Restored!',
                text: 'Pet was successfully restored. Play daily tasks and earn even more tokens with your happy pet',
                backToPet: 'View Pet',
            },
        },
        earnings: {
            title: 'Earnings',
            text: "NFT earnings depends on pet's rank and level",
            rank: 'Rank Earnings = ',
            level: 'Level Bonus = ',
        },
        matching: {
            title: 'Matches',
            text: 'In daily tasks, other users can rate your NFT and match with each other',
            like: '{{like}} Likes',
            dislike: '{{dislike}} Dislikes',
        },
        health: {
            title: 'Health',
            text: 'Keep your pet fed, otherwise its health may decline. If its health reaches zero, the pet will pass away',
            titleDead: 'NFT is Dead',
            textDead:
                "You have not fed your animal for a long time and now it is dead. You won't be able to collect experience, rewards and play with your pet. But no worries, you can revive it and continue playing. Hurry up!",
            buttonFeed: 'Feed Pet',
            buttonRestore: 'Restore NFT',
            modalWhatIsHealth: {
                title: 'What is Health?',
                text: "Your pet health decrease every day if you don't feed it. Keep your health always at its maximum to earn more.",
                card: {
                    title: 'Pet Health = Daily Reward',
                    text: "Rewards are proportional to the HP, e.g. with 80 HP you'll earn 80% of rewards. Take good care of your pet!",
                },
            },
            modalNFTisDead: {
                title: 'NFT is Dead',
                text: 'You have not fed your animal "{{petName}" for a long time and now it is dead. You won\'t be able to collect experience, rewards and play with your pet. But no worries, you can revive it and continue playing. Hurry up!',
                button: 'Restore NFT',
            },
            checkoutRevive: {
                title: 'Restore NFT',
                text: "Your NFT isn't available for daily tasks. Restore it to play and earn more tokens",
                restoreTo: 'Restore to',
                transaction: 'Restore "{{name}}" NFT',
                max: '100 Health',
                errors: {
                    wrongState: 'Something is wrong, can not revive pet.',
                },
            },
            successRevive: {
                title: 'NFT Restored!',
                text: 'NFT was successfully restored. Play daily tasks and earn even more tokens',
                backToPet: 'View Pet',
            },

            checkoutHealUp: {
                title: 'NFT Feed',
                text: "Your animal's health depends on daily feeding. With full health, your animal earns more coins",
                restoreTo: 'Restored',
                transaction: 'Restore "{{name}}" NFT',
                max: '{{amount}} Health',
                errors: {
                    wrongState: 'Something is wrong, can not heal pet.',
                },
            },
            infoMultipleHealUp: {
                title: 'Feed Multiple Pets',
                text: "Use one button to feed all your pets! The pets will be healed one by one. All the transactions take place on the blockchain, so you'll be able to track them",
                primaryButton: 'Feed Pets',
                secondaryButton: 'Don’t show this again',
            },
            checkoutMultipleHealUp: {
                title: 'Feed Multiple Pets',
                text: 'Restore Health of all your pets. NFTs with more Health will earn more rewards',
                transaction: 'Restore {{count}} NFTs',
            },
            proceedingMultipleHealUp: {
                title: 'Health is being Restored',
                text: 'Please, wait a bit until all the transactions finalize. Do not exit the screen, as transactions may fail! Once they are complete, you will be able to exit',
                error: 'Some NFT transactions failed due to blockchain issues. Try again',
            },
            multipleSuccessHealUp: {
                title: 'NFTs Health Restored!',
                text: "Your pets' Health was restored. Click on the button below for more details on each transaction",
                backToCollection: 'Back to Collection',
                item: 'Feeding {{length}} pets',
            },
            successHealUp: {
                title: 'NFT Health Restored!',
                text: 'NFT was successfully fed and health restored. Play daily tasks and earn even more tokens',
                backToPet: 'View Pet',
            },
            healUpAmountChoose: {
                title: 'Feed to Restore Health',
                text: 'Choose the option with the quantity of health points you want to restore',
                button: 'Checkout',
                loading: 'Calculating summary..',
                label: 'Select the appropriate number',

                errors: {
                    MAX: 'Maximum available to restore {{amount}} health',
                    MIN: 'Minimum available to restore {{amount}} health',
                },
            },
            healthPetCollectionList: {
                health: '{{health}} Health',
            },
            healAllLeave: {
                title: 'Are You Sure You Want To Leave?',
                text: 'Your pets healing is in progress, leaving during the process may result in errors, double spending or incorrect balance deduction. Proceed with caution',
                leave: 'Leave anyway',
                keep: 'Finish Healing',
            },
        },
        blockchainInfo: {
            tokenId: 'Token ID',
            title: 'NFT Overview',
            description:
                'Your pet NFT asset is stored on blockchain. You can withdraw it from the wallet any time',
            descriptionFree:
                'Your pet is Rank Free and not an actual NFT. Level up and you will be able to make NFT from it',
            warningText:
                "After withdrawing NFT will enter Cooldown mode and won't be available for play for 24 hours",
            cooldown: 'Cooldown ends in',
            withdraw: 'Withdraw',
        },
    },
    mysteryBoxes: {
        title: 'Mystery Boxes',
        emptyTitle: 'No Mystery Boxes Yet',
        emptyText: "You don't own any Mystery Boxes",
        emptyButton: 'Purchase a Box',
        prepareToOpen:
            'Opening a box, waiting for ChainLink Oracle random result..',
        banner: {
            isComing: {
                title: 'Mystery Boxes Sale is Coming',
                text: ' time remaining',
            },
            isStart: {
                title: 'Purchase Mystery Box',
                text: 'Try your luck and win the highest ranks',
            },
            isNotAvailable: {
                title: 'Mystery Boxes Sale is closed',
                text: "Mystery Boxes sales are rare occasions and it's not available until further notice",
            },
        },
        header: {
            title: 'Mystery Boxes',
            text: 'Your purchased boxed will be stored here. Open them anytime and mint NFT',
        },
        mysteryBoxCard: {
            open: 'Open Box',
            mint: 'Mint NFT',
        },
        error: {
            title: 'Error',
            text: 'Something went wrong. Try to reload',
        },
        openRank: {
            button: 'View Details',
        },
        openBoxModal: {
            title: 'Do You Want to Open a Box?',
            text: 'After opening the box, you will get randomized rank and will be able to mint NFT for free',
            button1: 'Yes, Open',
            button2: 'No, Cancel',
        },
        purchaseWelcome: {
            stepOne: {
                title: 'Try Your Luck with Mystery Boxes',
                text: 'Mystery Boxes give you a chance to get the best NFT Rank for the fixed price. Try your luck!',
            },
            stepTwo: {
                title: 'Chance To Get a Highest Rank',
                text: 'After you bought Mystery Box you can open it anytime and then continue minting process without any additional costs',
            },
            stepThree: {
                title: 'True Randomness',
                text: 'Mystery Box chances to win different ranks are fully random and using ChainLink Oracle technology to generate true random number on blockchain',
            },
            button: 'Select Box Quantity',
        },
        purchaseScreen: {
            title: 'Select Quantity',
            text: 'You can buy multiply boxes at one time',
            label: 'Enter or select amount',
            labelNoInput: 'Select amount of Mystery boxes',
            errors: {
                max: 'Maximum available is 10 boxes',
                min: 'Minimum available is 1 box',
                noSymbols: 'Only numbers allowed',
            },
            button: 'Checkout',
            loading: 'Calculating summary..',
        },
        purchaseCheckout: {
            title: 'Buy Mystery Boxes',
            text: 'Mystery Boxes give you a chance to get the best NFT Rank for the fixed price. Try your luck!',
            transactionDetails: {
                label: 'Box',
                text: '{{amount}} Mystery Box',
                transaction: 'Purchase {{amount}} Mystery Box',
            },
        },
        purchaseSuccess: {
            title: 'Mystery Boxes Purchased!',
            text: 'Mystery boxes was successfully purchased. You can open it from Mystery Boxes tab',
            button: 'View all',
        },
        comingSoon: {
            title: 'Mystery Boxes Coming Soon',
            text: 'Mystery Boxes sale is coming. Stay tuned and try your luck!',
            timer: 'Time Remaining',
        },
        saleClosed: {
            title: 'Mystery Boxes Unavailable',
            text: 'Mystery Boxes sale is unavailable at this moment. Stay tuned for official announcements',
        },
        errors: {
            forbidden: {
                title: 'Sale is over',
                text: 'Sale is over, no Mystery Boxes left to buy. Try your luck next time',
            },
        },
        modalMysteryBoxSaleInfo: {
            title: 'Try your Luck with Mystery Boxes',
            text: 'The NFT value is guaranteed to be no less than the Mystery Box price. Mystery Boxes will be sold for the price',
            timerTitle: 'Time Until Sale',
            timerText:
                'This is a sale of a limited collection, which will last limited time. Only ',
            timerAdditionalText: '{{amount} NFTs are available',
            chanceTitle: 'Chances to Win',
            chanceText:
                'Win an NFT with a certain probability. Here are the chances:',
            button: 'To Mystery Boxes',
        },
    },
    errorBoundary: {
        navigation: 'Error',
        title: 'Client side error',
        text: 'Unfortunately something went wrong on our side. If you keep seeing this error, please contact our support',
        button: 'Go to Main page',
        reloadApp: 'Reload App',
    },
    pullToRefresh: {
        reloadBalance: {
            text: 'Refreshing balance..',
            modalText: 'Refreshing..',
        },
        reloadPets: {
            text: 'Refreshing pets..',
            modalText: 'Refreshing..',
        },
        reloadMysteryBox: {
            text: 'Refreshing mystery boxes..',
            modalText: 'Refreshing..',
        },
        reloadTasks: {
            text: 'Refreshing tasks..',
            modalText: 'Refreshing..',
        },
        reloadVerifyUsers: {
            text: 'Looking for pets..',
            modalText: 'Refreshing..',
        },
        reloadRewards: 'Refreshing rewards...',
        reloadStatistics: 'Refreshing statistics...',
        reloadReferrals: 'Refreshing referrals...',
        loading: 'Refreshing..',
        pulling: 'Pull To Refresh',
        releasing: 'Release',
    },
    petLevelUp: {
        readyToUpModal: {
            titleStart: 'NFT is Ready to',
            titleLevelUp: ' {{level}} Level ',
            titleEnd: 'Up',
            subTitleStart:
                'Congrats! You have collected all experience and NFT ',
            subTitleEnd: ' is ready to level up',
            button: 'Upgrade',
            featureRankEarnings: {
                title: 'Rank Earnings ≈ {{igup}} ≈ ({{usd}}$)',
                text: "It's basic value, that comes only from rank",
            },
            featureLevelEarnings: {
                title: 'Level Earnings',
                text: "It's a value, that comes additional to the rank earnings",
            },
            featureNextRank: {
                title: '{{levels}} Levels More to “{{rankName}}”',
                text: 'You need to level up your NFT {{levels}} more times to be able to upgrade it to the next Rank',
            },
        },
        petLevelUpSuccess: {
            title: 'NFT Leveled Up!',
            text: 'NFT {{name}} was successfully leveled up to Level {{level}}. Play daily tasks and earn even more tokens with upgraded NFT',
            backToPet: 'View Pet',
            loading: 'Updating pet...',
        },
        headerTitle: 'NFT Level Up',
        checkout: {
            upgrade: 'Upgrade to',
            level: 'Level {{level}}',
            transaction: 'Level up for {{name}} NFT',
        },
        errors: {
            PET_WRONG_STATE: 'Level up is not available',
        },
    },
    petRankUp: {
        readyToUpModal: {
            title: 'Upgrade to {{rank}} Rank',
            subTitle:
                'You achieved all levels in {{rank}} rank and your Pet is ready to upgrade',
            button: 'Upgrade',
        },
        petLevelUpSuccess: {
            title: 'NFT Ranked Up!',
            text: 'NFT {{name}} was successfully ranked up to rank {{rank}}. Play daily tasks and earn even more tokens with upgraded NFT',
            backToCollection: 'Back to Collection',
            loading: 'Update pet...',
        },
        headerTitle: 'NFT Level Up',
        checkout: {
            upgrade: 'Upgrade to',
            level: 'Level {{level}}',
            transaction: 'Level up for {{name}} NFT',
        },
        errors: {
            PET_WRONG_STATE: 'Rank up not available',
        },
    },
    petIsPlaying: {
        title: 'Your Pet Is Playing',
        text: 'If you withdraw it from your account before completing the tasks, you will not get rewards for today and your pet will enter Cooldown mode for the next 24 hours',
        button: 'I Understand',
    },
    petOnCooldown: {
        title: 'Cooldown NFT',
        text: 'Your NFT is being transferred from another exchange and is not yet playable, the estimated transfer time is shown above. We try our best to make it faster, thanks for your understanding',
    },
    petIsDead: {
        title: 'NFT is Dead',
        text: 'You have not fed your animal "{{petName}" for a long time and now it is dead. You won\'t be able to collect experience, rewards and play with your pet. But no worries, you can revive it and continue playing. Hurry up!',
        errors: {
            PET_WRONG_STATE: 'Pet restore not available',
        },
    },
    socializeToEarn: {
        welcomeSlidesShare: {
            startSharing: 'Start Sharing',
            sharing: 'Sharing is Caring',
            sharingText:
                'Every day your pet needs attention from you. Give it to them by sharing it in social media with your friends',
            sharingEnergy: ' Total Energy',
            socialMedia: 'Share Your Pet in Social Media',
            socialMediaText:
                'Choose your social media account and attach a Pet ID to your social media content and let other users find your pet',
            socialMediaEnergy: ' Total Energy',
            referral: 'Add Your Referral Link',
            referralText:
                "Your referral link should be attached to Pet ID in the story. Other players won't verify your task if the link is not present!",
            errors: {
                FORBIDDEN: {
                    text: 'You can not start social task now with this social account. Try again tomorrow',
                },
            },
        },
        shareYourPetPhoto: {
            copyToClipboard: 'Username copied to clipboard',
            title: 'Share Your Pet Photo',
            texts: {
                INSTAGRAM:
                    'Share your content with attached Pet ID card in Instagram and let other users find your pet',
                TIKTOK: 'Share your content with attached Pet ID card in TikTok and let other users find your pet',
                TWITTER:
                    'Share your content with attached Pet ID card in Twitter and let other users find your pet',
                FACEBOOK:
                    'Share your content with attached Pet ID card in FACEBOOK and let other users find your pet',
            },
            info: 'How to Share?',

            download: 'Download',
            share: 'Share',
            createPhoto: 'Open Editor',
            button: 'Share and Finish',
            warningText:
                'Make sure your Pet ID is available and easy to find for other user until your task is completed',
            selectCreationMethod: 'Select Photo',
            creationMethod: {
                camera: 'Take a Photo',
                gallery: 'Select from Gallery',
            },
            modalPetIdVisible: {
                title: 'Pet ID And The Link Are Visible?',
                text: 'Your story will be checked by other users. If they cannot find Pet ID or the link or cannot discern its content, your task completion will be rejected',
                button1: 'Yes, Continue',
                button2: 'No, Check Again',
            },
            modalShareYourPhoto: {
                title: 'Select Sharing Method',
                shareTemplate: {
                    title: 'Share Template',
                    text: 'Use the template we created to share your Pet ID faster',
                },
                editToShare: {
                    title: 'Edit to Share',
                    text: 'Upload an image from the gallery or take a photo for custom Pet ID editing',
                },
                downloadPetId: {
                    title: 'Download Pet ID',
                    text: 'Style the post as you wish without using our tools',
                },

                submit: 'I have Shared Pet ID',
                loading: 'Preparing content..',
                saveSuccess: 'Photo successfully saved',
            },
            petIdVisibleLoading: 'Creating Find Task..',
            shareSuccess: {
                title: 'Find Other Pets',
                text: "You have successfully shared photo of your pet. Your task is in verification and usually it takes between 6 to 12 hours. Now it's your turn to find other pets in social media",
                button1: 'Find Other Pets',
                button2: 'Back to Sharing',
                button3: 'Back to Tasks',
                toastSuccess: 'All verifying task completed',
            },
            link: 'Link',
            alert: 'The task will be considered complete only if you place the link to the app',
        },
        createShareTemplate: {
            screenTitle: 'Template Sharing',
            title: 'Use Template to Share',
            text: 'Make use of our pre-designed template to share content fast and conveniently',

            shareTemplate: {
                Default: 'Every Day I Play IguVerse and\nEarn Crypto',
                BinanceNft: 'Get a Virtual Pet & Start Earning with IguVerse',
                BnbChain: 'Get a Virtual Pet & Start Earning with IguVerse',
                Okx: 'IGU Moon Landing: Launching On The OKX Exchange',
                KuCoin: 'IGU Moon Landing: Launching On The KUCOIN Exchange',
                Bybit: 'Vote for IguVerse\n& Share $121,000\nin Rewards!',
            },
            placeForLink: 'Place for Link',
            socialCardTemplate: {
                Default: 'I Play IguVerse\nand Earn Crypto',
                Bybit: 'Vote for IguVerse\n& Share $121,000\nin Rewards!',
            },
        },
        welcomeSlidesVerify: {
            startVerify: 'Start Find Task',
            find: 'Find Other Pets',
            findText:
                'Your pet needs attention from other pets. Find other players pets and enjoy rewards together. Make no mistake while looking for other pets, they rely on you. Your daily goal is to find all pets.',
            findEnergy: ' Total Energy',
            beResponsible: 'Be Responsible',
            beResponsibleText:
                'Play fair and do not try to cheat by misleading the system by providing wrong IDs or claiming that you can not find. Your account may be flagged once you do this ',
            beResponsibleEnergy: ' Total Energy',
        },
        verifyUser: {
            title: 'Find Pet',
            text: "You need to find and enter other's user Pet ID, that can be found in social media. For every successful match you will get {{energy}} Energy",
            info: 'How to Find Pet ID?',
            label: 'Pet ID',
            placeHolder: 'Enter Pet ID',
            question: 'How to Find Pet ID?',
            buttonVerify: 'Verify',
            buttonNotFound: 'Pet ID not Found',
            errorMessage:
                'Incorrect Pet ID code. Please try again. Attempts left {{attempts}}!',
            loadingVerifyUser: 'Checking..',
            errors: {
                noTasksText1: 'No available tasks now',
                noTasksText2: 'Come back later',
                gameIterationExists1: 'You are not allowed to create',
                gameIterationExists2: 'more tasks in this game iteration',
            },
            toastNoPetVerification: {
                title: 'Error',
                text: 'There is not available pet for verification ',
            },

            toastSuccess: 'Congrats! User {{user}} is verified',
            toastFailedVerify: 'Fail! User {{user}} not verified',
            regexError: 'Only digits and A-Z characters are allowed',

            verifyEmpty: {
                title: 'No More Pets',
                text: 'It looks like nobody is playing now. We will notify you when more pets are available',
                button1: 'Explore Other Tasks',
                button2: 'Try again',
                refresh: 'Refresh',
            },
            verifyNotFound: {
                title: 'Pet Not Found',
                text: 'Hope you were right. If pet was really there than we will give you a warning',
                button1: 'Find Next',
                button2: 'Finish Playing',
            },
            verifyAlreadyCompleted: {
                title: 'Already Completed',
                text: 'Text Already Completed',
                button1: 'Verify Next',
                button2: 'Finish Playing',
            },
            verifyMatch: {
                title: 'Pets Matched!',
                text: {
                    step1: 'Congrats! Your pet ',
                    step2: ' matched with pet ',
                    step3: '! Now you will be able to connect inside the app',
                },
                button1: 'Find Next',
                button2: 'Finish Playing',
                like: 'Like',
                dislike: 'Dislike',
                alreadyRate: "Can't change your decision",
            },
            verifyFailed: {
                title: 'Failed',
                text: 'Unfortunately you failed to provide the correct Pet ID code',
                button1: 'Find Next',
                button2: 'Finish Playing',
            },
            modalPetIdNotFound: {
                title: 'Pet ID not Found?',
                text: "Please try to find Pet ID on the user`s photo. If Pet ID was there and you didn't provide it, then you will not earn your Energy points and may receive a warning.",
                button1: 'Yes, Confirm',
                button2: 'Cancel',
            },
            tips: {
                title: 'Verification Tips',
                alert: "If Pet ID doesn't meet the rules, click the 'Pet ID not found' button",
                post: 'Pet ID should be placed in a story',
                picture:
                    'Pet ID and all the info on it should be visible on the content',
                changes:
                    'Pet ID can be changed, but the changes should not run counter to other rules',
                cropping:
                    'No cropping or hiding the picture or its parts is allowed',
                overlaying: 'No overlaying or excessive downsizing is allowed',
            },
        },

        petCardId: {
            link: 'Download IguVerse app at ',
            earnStart: 'My pets earn ',
            earnEnd: ' every day',
            freeEarn: 'My free pet earn ',
            donateMessage: 'A part of my earning is donated to ',
            binanceMessage: 'IguVerse x Binance NFT Sale',
            bnbMessage:
                'IguVerse x BNB Chain: Join the new viral trend in Web3 and social media',
            okxKuCoinMessage: 'IGU Token Going Live on ',
            defaultMessage:
                'Pioneering an AI-powered marketplace and an ad network — join now!',
        },
    },
    moveToEarn: {
        welcomeSlides: {
            walkPet: 'Walk Your Pets',
            walkPetText:
                'Your pets need to walk every day to keep healthy as much as you do. Walk your pet and you will be rewarded with Energy points',
            energyTotal: ' Energy Total',
            complete: 'Complete Your Walking Goal',
            completeText:
                'Complete your walking goal, track your performance and enjoy healthy lifestyle',
            antiCheating: 'Anti-Cheating Algorithms',
            antiCheatingText:
                "Our anti-cheating algorithms are able to detect suspicious activity from user. User's who are cheating will lose their energy points",
        },
        modals: {
            prepare: 'Prepare to Walk',
            prepareText:
                "You can complete walking task once per day. After you finish or close the task, you won't be able to play more this day",
            leave: 'Do you Want to Leave?',
            leaveText:
                'Your will finish the task with your current Energy progress. You can start the task again tomorrow',
            leaveBtn1: 'Yes, Leave',
            leaveBtn2: 'No, Continue',
            prepareBtn1: 'Start Walking',
            prepareBtn2: 'Cancel',
            permissionsInfoText:
                'IguVerse collects location data even when the app is closed or not in use. The App may access your location in the background to ensure the functionality of Walking Task',
            permissionsInfoBtn1: 'Continue',
            permissionsInfoBtn2: 'Cancel',
            leaveWithOutFinish: 'Do you Want to Exit?',
            leaveWithOutFinishText:
                'After you close the task, your progress will disappear and you should start again',
            leaveWithOutFinishBtn1: 'Exit',
            leaveWithOutFinishBtn2: 'Cancel',
        },
        notSupported: {
            title: 'Geolocation Not Available',
            text: 'Your device does not support geolocation API, you can not complete Walking Task',
            button: 'OK',
        },
        locationProblem: {
            title: 'Geolocation Problem',
            text: "We have noticed, that your device's geolocation feature stopped working as expected, you need to restart the task",
            button: 'Got It',
        },
        progress: {
            paused: 'Paused',
            walking: 'Walking',
            stopped: 'Stopped',
            tooFast: 'Too Fast',
            title: 'Walking Your Pet',
            timeLeft: 'Time Left to Walk',
            tooFastMessage:
                "Your speed can't be more than 20 km/h. Progress will resume when speed become lower",
            walkStoppedMessage:
                'Seems like you stopped walking. Your progress will resume once you continue walking',
        },
        summary: {
            title: 'Walking Statistics',
            youEarned: 'You Earned',
            energy: 'Energy',
            subtitle:
                'Congrats! You found all pets. Come back tomorrow for more',
            distance: 'Distance',
            steps: 'Steps',
            averageSpeed: 'Average Speed',
            viewMoreTasks: 'View More Tasks',
            finishPlaying: 'Finish Playing',
        },
        startWalking: 'Start Walking',
        skip: 'Tap to Skip',
        km: 'km',
        kmH: 'km/h',
    },
    playToEarn: {
        welcomeSlides: {
            energy: 'Restore Your Energy',
            energyText:
                'Collect fruits and jump over obstacles and restore your daily Energy level. Be careful, you have only one attempt per day',
            energyAgainText:
                'Exercise your skill in the game to earn more rewards. The task is available once per day, however you can play the game at any time',
            energyTotal: ' Total Energy',
            energyAgainTotal: 'Sandbox',
            avoid: 'Avoid Obstacles',
            avoidText:
                'Your main goal is jump over obstacles and collect points. Be careful!',
            food: 'Collect Food',
            foodText:
                'Collected food gives you additional scores. After reaching the score target, the game finishes and your Energy level gets restored immediately',
        },
        modals: {
            prepareToPlay: {
                title: 'Prepare to Play',
                text: "In your first game you will play to restore energy. If you can't collect all energy you will be able to play game, but without energy progress",
                accept: 'Start Playing',
                cancel: 'Cancel',
            },
            playAgain: {
                title: 'Prepare to Play',
                text: 'You have already collected the energy in this game today. Now you can just play for upgrading skills for the next days',
                accept: 'Start Playing',
                cancel: 'Cancel',
            },
            loading: {
                text: 'Preparing the game..',
            },
            gameTimer: {
                text: 'Tap to jump over obstacles. Earn score and collect fruits',
                textWebDesktop:
                    'Press space or mouse left-click to jump over obstacles. Earn score and collect fruits',
                textWebMobile:
                    'Tap to jump over obstacles. Earn score and collect fruits',
            },
            pause: {
                title: 'Do You Want to Leave?',
                text: 'Your current progress will be converted to Energy, however you will not be able to play any more this day',
                accept: 'Yes, Leave',
                cancel: 'No, Continue',
            },
            win: {
                title: 'Task Completed',
                text: 'Congrats! You have successfully completed your daily task. You can continue to play for fun, however it will not affect your Energy level',
                accept: 'Finish',
                cancel: 'Play More',
            },
            loose: {
                title: 'Game is Finished',
                text: 'You failed your daily task. You can continue to play for fun, however it will not affect your Energy level',
                accept: 'Finish',
                cancel: 'Play More',
            },
            score: 'Your Score',
            energy: 'Energy Restored',
            savedLives: 'Saved Lives',
            fruitCollected: 'Fruit Collected',
        },
        summary: {
            title: 'Playing Statistics',
            score: 'You Score',
            lives: 'Saved Lives',
            fruitCollected: 'Fruit Collected',
        },
        startPlaying: 'Start Playing',
        loadingStatuses: {
            downloading: 'Downloading Assets',
            preparing: 'Preparing Textures',
            loadingGraphics: 'Loading Graphics',
            spawning: 'Spawning Game Objects',
            loading: "Loading (Don't Close the App)",
        },
    },
    editNFTsScreen: {
        title: 'Choose Pets To Play',
        dragText:
            'Pets which will participate in games. Drag it to change the order',
        selectList: 'Select pets from list',
        warningMessage:
            'The first pet in order will be used in your Social task as Pet ID',
        cooldownMessage:
            "Your NFT is in Cooldown mode and can't participate in the game. Wait until Cooldown mode expires",
        button: 'Save',
        errors: {
            saveChanges: {
                title: 'Unsaved Changes',
                text: 'You have unsaved changes, are you sure you want to leave?',
                button1: 'Leave',
                button2: 'Go back and save',
            },
        },
        loading: 'Saving changes..',
        success: 'NFT order saved!',
    },
    statuses: {
        COMPLETED: {
            title: 'Completed',
        },
        AWAITS_VERIFICATION: {
            title: 'Awaits verification',
        },
        MODERATOR_VERIFICATION_REQUIRED: {
            title: 'Awaits verification',
        },
        FAILED: {
            title: 'Failed',
        },
    },
    profile: {
        title: 'Settings',
        logOut: 'Log Out',
        deleteAccount: 'Delete Account',
        logOutModal: {
            title: 'Already Leaving?',
            description:
                'You will need to log in again or sign up new account to use IguVerse App',
            okText: 'Yes, Log Out',
            cancelText: 'Cancel',
        },
        deleteAccountModal: {
            title: 'Delete Account?',
            description:
                "You can permanently delete your account, but be aware, that you won't be able to log in and use it any more",
            okText: 'Delete Account',
            inputLabel: 'Confirmation Phrase',
            placeholder: 'Enter confirmation phrase',
            preConfirmationPhrase: 'Type ',
            afterConfirmationPhrase: ' to confirm action',
            success: 'Your account successfully deleted',
            loading: 'Deleting your account..',
        },
        lightTheme: 'Light',
        darkTheme: 'Dark',
        menuItems: {
            admin: {
                title: 'Verify users',
                description: 'Verify users social media',
            },
            security: {
                title: 'Security',
                description: 'Customize your security settings',
            },
            socialAccounts: {
                title: 'Social Accounts',
                description:
                    'Connect your social media to attend in Social game',
            },
            assignRole: {
                title: 'Assign Role',
                description: 'Select and assign an account role to the user',
            },
            nftRoyaleRevokeBan: {
                title: 'NFT Royale Revoke Ban',
                description: 'Revoke ban of user in NFT Royale',
            },
            language: {
                title: 'Language',
                description: 'Set displaying language',
            },
            theme: {
                title: 'Theme',
                description: 'Select a theme of interface',
            },
            notifications: {
                title: 'Notifications',
                description:
                    'Select kinds of notifications you want to get from the app',
            },
            support: {
                title: 'Support via Chat',
                description:
                    'Leave your request and our specialist will answer you in a chat',
            },
            supportEmail: {
                title: 'Support via Email',
                description: 'Leave us an email if you need any help',
            },
            knowledgeBase: {
                title: 'Knowledge Base',
                description:
                    'Check other helpful information of IguVerse app to learn more about our privacy',
            },
            privacyPolicy: {
                title: 'Privacy Policy',
                description: 'Check more about our Privacy Policy',
            },
            termsOfUse: {
                title: 'Terms of Conditions',
                description: 'Check more about our Terms of Use',
            },
            appVersion: {
                title: 'App Version',
                description: 'Newest {{version}}',
            },
            webVersion: {
                title: 'Web App',
                description: 'Login to Web Application',
            },
            accessibility: {
                title: 'Accessibility',
                description: 'Customize the interface to suit your preferences',
            },
            socialMedia: {
                title: 'Our Social Media',
                description: 'Follow IguVerse on social media to stay tuned',
            },
            adminSendPushNotification: {
                title: 'Send Push Notification',
                description:
                    'Create a one-time notification that will be displayed to the all users',
            },
            adminReverification: {
                title: 'Reverification',
                description:
                    'As an admin, you can send a social account for a follow-up verification',
            },
            adminBlockUser: {
                title: 'Block the User',
                description:
                    'Block the account of the user who violated the rules of IguVerse playing',
            },
            adminMaintenanceMode: {
                title: 'Maintenance Mode',
                description:
                    'In case of technical work, put the application in the maintenance mode',
            },
            adminAssignWhiteList: {
                title: 'Assign Whitelist',
                description:
                    'Choose the number and rank of NFTs to send the whitelist to the winner',
            },
        },
        languageSettings: {
            title: 'Languages',
            subtitle: 'Select Language',
            description:
                'You can select preferable language to display content in IguVerse app',
            options: {
                pl: {
                    name: 'Polish',
                    change: 'Polish',
                },
                en: {
                    name: 'English',
                    change: 'English',
                },
                ru: {
                    name: 'Russian',
                    change: 'Russian',
                },
                uk: {
                    name: 'Ukrainian',
                    change: 'Ukrainian',
                },
                cn: {
                    name: 'Chinese',
                    change: 'Chinese',
                },
                id: {
                    name: 'Indonesian',
                    change: 'Indonesian',
                },
                es: {
                    name: 'Spanish',
                    change: 'Spanish',
                },
                tr: {
                    name: 'Turkish',
                    change: 'Turkish',
                },
            },
            switchLanguageModal: {
                title: 'Switch to {{localeId}} language',
                description:
                    'All content in IguVerse app will switch to {{localeId}}. You can change it anytime',
            },
        },
        notificationsSettings: {
            title: 'Notification',
            pushNotificationTitle: 'Push Notifications',
            emailNotificationTitle: 'Email Notifications',
            notifications: {
                pushDailyReminder: 'Push Daily Reminder',
                pushRewardEarned: 'Push Reward Earned',
                pushSocialTaskCompleted: 'Push Social Task Completed',
                pushPetLowHealth: 'Push Pet Low Health',
                pushPetDead: 'Push Pet is Dead',
                pushMysteryLikesGame: 'The Mystery Of Likes',
                emailPetDead: 'Email Pet is Dead',
                emailSocialTaskCompleted: 'Email Social Task Completed',
            },
            saved: 'Notifications settings saved!',
        },
        socialAccountsSettings: {
            title: 'Social Media',
            headerTitle: 'Connect Accounts',
            headerText:
                'Connect your social media account to be able to complete the social tasks. Learn more about account requirements in our Knowledge Base',
            headerInfo: 'View Knowledge Base',
            alert: 'After connecting your account, you need to complete the social task for the account to be verified',
            modal: {
                title: 'Complete Verification',
                text: "You've successfully connected your social account. Complete the social task for the account to be verified!",
                button: 'Navigate To Tasks',
            },
        },
        roles: {
            PLAYER: 'Player',
            ADMIN: 'Admin',
            MODERATOR: 'Moderator',
        },
        support: {
            success: {
                title: 'Message sent!',
                text: 'We will get back to you soon',
            },
            notSent: {
                text: 'Message not sent',
            },
            errorOpen: {
                text: 'Error while opening Mail client app',
            },
        },
        accessibilitySettings: {
            title: 'Accessibility',
            faceId: 'Biometric / Passcode',
            shake: 'Send Your Feedback On Shaking',
            vibration: 'Vibration Response',
            saved: 'Accessibility settings saved!',
        },
        socialMediaSettings: {
            screenTitle: 'IguVerse Media',
            communities: {
                title: 'Official Communities',
                text: 'Join our amazing community of pet lovers, get help & socialize',
                socials: {
                    discord: 'Discord',
                    telegram: 'Telegram',
                },
            },
            media: {
                title: 'Official Media',
                text: 'Follow our social media channels to receive breaking news & announcements',
                socials: {
                    twitter: 'Twitter',
                    instagram: 'Instagram',
                    youtube: 'Youtube',
                    tiktok: 'TikTok',
                    website: 'Website',
                    facebook: 'Facebook',
                },
            },
            telegram: {
                title: 'Telegram Communities',
                main: 'Official Channel',
                en: 'English Chat',
                uk: 'Ukrainian Chat',
                ru: 'Russian Chat',
                cn: 'Chinese Chat',
                tr: 'Turkish Chat',
                id: 'Indonesian Chat',
                kr: 'Korean Chat',
                jp: 'Japanese Chat',
                ab: 'Arabic Chat',
                in: 'Indian Chat',
                pp: 'Philippines Chat',
                vt: 'Vietnam Chat',
                bg: 'Bangladesh Chat',
                pl: 'Polish Chat',
                es: 'Spanish Chat',
                fr: 'French Chat',
                pt: 'Portuguese Chat',
            },
        },
        adminSendPushNotification: {
            title: 'Send Push Notification',
            text: 'Create a one-time notification that will be displayed to the all users',
            button: 'Send',
            selectLanguage: {
                label: 'Language',
                placeHolder: 'Select notification language',
                modalTitle: 'Select Language',
                button: 'Save',
                title: '{{amount}} Language Selected',
            },
            titleInput: {
                label: 'Title (less than {{amount}} symbols)',
                placeHolder: 'New Update!',
            },
            bodyInput: {
                label: 'Description (less than {{amount}} symbols)',
                placeHolder: 'Explore our new update with mystery boxes',
            },
            errors: {
                required: 'This field is required',
            },
        },
        adminReverification: {
            screenTitle: 'Reverification',
            title: 'Send for Reverification',
            text: "Select a social network and enter the user's account below to send it to repeat moderation",
            button: 'Send',
            labelSocial: 'Social Media',
            labelInput: 'Account Link',
            placeHolder: 'Enter account link',
            socialMediaModal: {
                title: 'Select Social Media',
            },
            checkModal: {
                title: 'Check Profile Details',
                text: "Take a look at the social account below and check if it's the correct one. Be attentive, as this action cannot be undone",
                button1: 'Send for Reverification',
                button2: 'Wrong Account, Cancel',
            },
            toastSuccess: 'Account was send for reverification',
        },
        adminBlockUser: {
            screenTitle: 'Block the User',
            title: 'Enter Wallet Address',
            text: 'Enter the wallet address bound to the account that violated the IguVerse rules',
            button: 'Block',
            modal: {
                title: 'Block User?',
                text: "Make sure that you're blocking the right user. You should be very carefully with the users that you block. This action cannot be undone!",
                button1: 'Send',
                button2: 'Cancel',
            },
            toastSuccess: 'The user was successfully blocked',
            toastError: 'Could not find User',
            banReason: {
                screenTitle: 'Select Block Reason',
                label: 'Block Reason',
                placeHolder: 'Select block reason',
            },
            banComment: {
                label: 'Comment',
                placeHolder: 'Add comment if necessary',
            },
            error: {
                banComment: 'Should add comment',
                banReason: 'Please select ban reason',
            },
        },
        adminMaintenanceMode: {
            screenTitle: 'Maintenance Mode',
            on: {
                title: 'Turn On Maintenance Mode',
                text: "You can put the application in the maintenance mode and users won't be able to access it. To make the available for users, click Turn Off Maintenance Mode in the Settings",
                button: 'Turn On',
                modal: {
                    title: 'Turn On Maintenance Mode?',
                    text: 'Users will not be able to access the app if you activate the maintenance mode. To make the app available again, click Turn Off Maintenance Mode in the Settings',
                    button1: 'Turn On',
                    button2: 'Cancel',
                },
                toastSuccess: 'Maintenance Mode On',
            },
            off: {
                title: 'Turn Off Maintenance Mode',
                text: "The app is in the maintenance mode and users cannot access it at the time. Disable it as soon as the technical work is done it by clicking the button below. Don't forget to confirm!",
                button: 'Turn Off',
                modal: {
                    title: 'Turn Off Maintenance Mode?',
                    text: 'Make sure that the technical work is done and the the app will work correctly. To make the app available again, click on the button below',
                    button1: 'Turn Off',
                    button2: 'Cancel',
                },
                toastSuccess: 'Maintenance Mode Off',
            },
        },
        adminAssignWhiteList: {
            screenTitle: 'Assign Whitelist',
            button: 'Assign',
            title: 'Assign Whitelist',
            text: "Enter the user's wallet address and select the rank and quantity of NFTs",
            rank: {
                label: 'NFT Rank',
                placeHolder: 'Select rank',
            },
            quantity: {
                label: 'Quantity',
            },
            modal: {
                screenTitle: 'Select Rank',
                title: 'Assign Whitelist?',
                text: 'Check everything carefully: the NFT Rank and the number of NFTs, as well as the wallet address. You should be confident when confirming this action, as it cannot be undone',
                button1: 'Assign',
                button2: 'Cancel',
            },
            toastSuccess: 'The Whitelist was successfully assigned',
            error: {
                quantity: {
                    max: 'Max quantity {{quantity}}',
                    min: 'Min quantity {{quantity}}',
                    numbers: 'Only numbers',
                },
                rank: 'Rank should be selected',
            },
            toastError: 'Could not find User',
        },
        adminAssignRole: {
            screenTitle: 'Assign Role',
            button: 'Assign',
            title: 'Assign Role',
            text: "Enter the user's wallet address and select the role to be assigned",

            role: {
                label: 'Role',
                placeHolder: 'Select role',
            },
            roleModal: {
                title: 'Select role',
            },
            attentionModal: {
                title: 'Assign Role?',
                text: "Carefully check all the data provided: the wallet address and the role. Make sure that you're not making a mistake. This action cannot be undone!",
                button1: 'Assign',
                button2: 'Cancel',
            },
            error: {
                role: 'Role should be selected',
            },
            success: 'The Role was successfully assigned',
            toastError: 'Could not find User',
            toastSuccess: 'Role was successfully assigned',
        },
        adminNftRoyaleRevokeBan: {
            screenTitle: 'Revoke Ban',
            button: 'Revoke ban',
            title: 'Revoke Ban',
            text: "Enter the user's wallet address and user id to revoke ban",
            attentionModal: {
                title: 'Revoke user ban?',
                text: "Carefully check all the data provided: the wallet address and user id. Make sure that you're not making a mistake. This action cannot be undone!",
                button1: 'Revoke ban',
                button2: 'Cancel',
            },
            success: 'Revoke user ban successfully!',
        },
        walletInput: {
            label: 'Wallet Address',
            placeHolder: 'Enter the wallet address',
        },
        userIdInput: {
            label: 'User ID',
            placeHolder: 'Enter the User ID',
        },
        walletInputError: 'Is not wallet address',
    },
    admin: {
        title: 'Admin',
        verify: {
            title: 'Verify Users',
            subtitle: 'Verify user social media',
            text: 'Open social media account and provide the Pet ID for successful verification',
            emptyTitle: 'No more tasks',
            emptyText:
                'There are no available tasks to verify, come back later',
            messages: {
                error: 'Incorrect Pet ID, try again',
                success: 'Congrats! User is verified',
                notFound: 'User is rejected',
                failed: 'Error occurred, please report it to admin',
            },
            modalPetIdNotFound: {
                title: 'Pet Id not Found?',
                text: 'Please, carefully examine Pet ID and if you are not able to verify, select one of the following reasons for verification failure',
                reasons: {
                    notEnoughFollowers: {
                        title: 'Not Enough Followers',
                        text: 'Your account is not active enough on social media, is not open and/or has few followers',
                    },
                    petIdNotFound: {
                        title: 'Pet ID Not Found',
                        text: 'Pet ID is not visible in the picture or has not been posted',
                    },
                    petIdIncorrectPosition: {
                        title: 'Pet ID Incorrect Position',
                        text: 'Pet ID is cropped or is incorrectly placed, so that it is not possible to see the code',
                    },
                    userNotFound: {
                        title: 'User Could Not Be Found',
                        text: 'The account with the post has been either deleted or linked incorrectly and can not be checked',
                    },
                },
                button1: 'Yes, Confirm',
            },
            label: 'Pet ID',
            placeHolder: 'Enter Pet ID',
            buttonVerify: 'Verify',
            buttonNotFound: 'Pet ID not Found',
            loadingVerifyUser: 'Checking..',
            regexError: 'Only digits and A-Z characters are allowed',
        },
    },
    socialMediaConnect: {
        addSocialAccount: 'Add Social Account',
        selectAccountToShare: 'Select Account to Share',
        select: 'Select',
        connect: 'Connect',
        disconnect: 'Disconnect',
        unavailable: 'Currently unavailable',
        status: {
            moderating:
                'Your account has been added and will be checked once you post Pet ID for completing the social task!',
            success:
                'The account has been successfully verified by our moderators. Now you can use it to complete the Socialize to Earn task',
            reject: 'The account has not not approved by our moderators. Please, connect the account that is in line with our rules',
        },
        modalTerms: {
            start: 'By connecting social account I agree to ',
            and: ' and ',
            privacyPolicy: 'Privacy Policy',
            termOfUse: 'Terms of Use',
        },
        connectTiktokModal: {
            title: 'Connect TikTok Account',
            label: 'Username',
            placeHolder: 'Enter TikTok username',
            button: 'Connect',
            errors: {
                notExist:
                    "This account doesn't exist. Please enter valid TikTok username",
                somethingWrong: 'Something went wrong, please try again',
                likeFollowers:
                    'Not enough activity on your account. Can connect only live account',
                cannotLoad: 'Can not load user account. Please try again',
                alreadyLinked:
                    'The provided username is already in use by other user',
                notLinked: 'Account is already unlinked',
            },
        },
        socialPreview: {
            checkProfile: 'Check Your Profile',
            takeALook: `Take a look at the social media account below and
            check if this account is yours`,
            view: 'View',
        },
        connectInstagramModal: {
            title: 'Connect Instagram Account',
            label: 'Username',
            placeHolder: 'Enter account link',
            addAccount: 'It’s Me, Add Account',
            cancel: 'It’s not Me, Cancel',
            button: 'Connect',
            errors: {
                somethingWrong: 'Something went wrong, please try again',
                alreadyLinked:
                    'The provided username is already in use by other user',
            },
            viaInstagram: 'or Login via Instagram',
        },
        connectTwitterModal: {
            title: 'Connect Twitter Account',
            label: 'Username',
            placeHolder: 'Enter Twitter username',
            button: 'Connect',
            errors: {
                notExist:
                    "This account doesn't exist. Please enter valid Twitter username",
                alreadyLinked:
                    'The provided username is already in use by other user',
                somethingWrong: 'Something went wrong, please try again',
            },
        },
        connectFacebookModal: {
            title: 'Connect Facebook Account',
            label: 'Username',
            placeHolder: 'Enter Facebook username',
            button: 'Connect',
            errors: {
                notExist:
                    "This account doesn't exist. Please enter valid Facebook username",
                alreadyLinked:
                    'The provided username is already in use by other user',
                somethingWrong: 'Something went wrong, please try again',
            },
            via: 'or Login via Facebook',
        },
        terms: {
            instagram: {
                one: 'Public profile',
                two: 'Active account with at least 100 followers',
                three: 'At least 5 posts older than 3 months',
            },
            tikTok: {
                one: 'At least 500 followers',
                two: 'Active account with at least 5 videos older than 3 months',
                three: 'At least 500 likes on account',
            },
            twitter: {
                one: 'Public profile',
                two: 'Active account with at least 500 followers',
                three: 'At least 5 posts older than 3 months',
            },
            facebook: {
                one: 'Public profile',
                two: 'Active account with at least 100 friends',
                three: 'At least 5 posts older than 3 months',
                four: 'Stories should be public',
            },
        },
        errors: {
            duplicate: {
                title: 'Your {{social}} is already connected to other account',
                text: 'Your social media profile is connected to other account. Disconnect it first and try again',
                button: 'Close',
            },
            disconnect: {
                title: 'Disconnect Account?',
                text: 'You will be able to use your new account for completing the social task, once the new game day starts',
                button1: 'Yes, Disconnect',
                button2: 'No, Keep It',
            },
            userNameError:
                'Forbidden symbols. Only latin characters, digits, dots and underscores are allowed',
        },
        success: {
            linkInstagram: 'Instagram successfully connected',
            unLinkInstagram: 'Instagram disconnected',
            linkTiktok: 'TikTok successfully connected',
            unLinkTiktok: 'TikTok disconnected',
            linkTwitter: 'Twitter successfully connected',
            unLinkTwitter: 'Twitter disconnected',
            linkFacebook: 'Facebook successfully connected',
            unLinkFacebook: 'Facebook disconnected',
        },
    },
    playTasksHistory: {
        title: 'Tasks History',
        info: 'Here are you can see history of your tasks and your progress',
        historyCard: {
            SocialTask: 'Social Task',
            VerificationTask: 'Find Task',
            MoveTask: 'Walking Task',
            PlayTask: 'Playing Task',
        },
        emptyState: {
            title: 'No Tasks Yet',
            text: "You haven't completed any daily tasks yet. But it's not a problem let's do it right now",
            button: 'Start Playing',
        },
        historyTaskDetails: {
            title: 'Task Details',
            text: 'Energy collected will be converted to tokens rewards in your Reward wallet. View more details about tasks bellow',
            collectedEnergy:
                '{{earnedEnergy}} / {{maxEnergy}} Energy Collected',
            viewRewardWaller: 'View in Reward Wallet',
            details: {
                type: 'Type:',
                type_SocialTask: 'Social Task',
                type_VerificationTask: 'Find Task',
                type_MoveTask: 'Walking Task',
                type_PlayTask: 'Playing Task',
                petExp: 'Pets Experience:',
                exp: '{{exp}} XP',
                date: 'Date:',
            },
        },
    },
    successTaskEnergyBlock: {
        text: 'Energy',
        collectedEnergy: '{{earnedEnergy}} / {{maxEnergy}} Energy Collected',
    },
    successTaskPetsExp: {
        text: 'Collected XP',
        collectedExp: '{{collectedExp}} XP',
    },
    checkout: {
        title: 'Verify Transaction',
        text: 'Double-check the information and confirm your transaction',
        confirm: 'Confirm and Purchase',
        try: 'Try Again',
        cancel: 'Cancel',
        purchasingFrom: 'Payment Method',
        available: 'Balance',
        deposit: 'Deposit',
        wallet: '{{coin}} Wallet',
        loadingPurchasing:
            "Finalizing transaction, please don't close the app..",
        free: 'Free',
        whiteList: 'Whitelist (1)',
        explorerUrl: 'View on BscScan',
        fields: {
            transaction: 'Transaction',
            value: 'Value',
            amount: 'Amount',
            totalSummary: 'Total Summary',
            from: 'From (Wallet)',
            to: 'To Address',
            fee: 'Blockchain fee',
            modalTitle: 'Confirm transaction',
            toWallet: 'To (Wallet)',
            gamingWallet: 'Gaming Wallet',
            mainWallet: 'Main Wallet',
            confirm: 'Confirm',
            commissionFee: 'Commission Fee',
            nft: 'NFT',
            nftWallet: 'NFT Wallet',
            nftMarketplace: 'NFT Marketplace',
        },
        errors: {
            notEnoughCoinFunds:
                'Not enough funds. Deposit funds to your wallet',
            notEnoughCoinFundsMulti:
                'Not enough funds. Choose another payment option or deposit funds to your wallet',
            bnbNotEnough:
                'Not enough BNB on your balance to cover a gas fee. Please deposit BNB to your wallet',
            notEnoughBalanceFunds: 'Not enough funds',
            notEnoughBalanceFundsMulti:
                'Not enough funds. Choose another payment option',
            insufficientVirtualBalance:
                'Insufficient funds on your virtual balance',
        },
        purchasingFromCoins: {
            coins: 'Tokens',
            virtual: 'E-Wallet',
            inapp: {
                ios: 'Apple',
                android: 'Google Pay',
            },
        },
        storePurchase: {
            loadings: {
                PURCHASE: "Completing payment, please don't close the app..",
                FINALIZE:
                    "Finalizing transaction, please don't close the app..",
            },
            button: 'Okay',

            storePurchaseError: {
                PURCHASE: {
                    title: 'Something went wrong',
                    message:
                        'Something went wrong during purchase process. Please try again\nError Code: {{code}}',
                },
                FINALIZE: {
                    title: 'Something went wrong',
                    message:
                        'Something went wrong during finalizing transaction. Please try again',
                },
            },
        },
    },
    charity: {
        title: 'Charity',
        description:
            'IguVerse users pay donations from their daily income to one preselected Charity organizations',
        current: 'My Choice',
        dailyDonation: 'Daily Donations from Your Rewards',
        howItWorks: 'How it Works?',
        selectedOrganization: 'Selected Organization',
        edit: 'Edit',
        organizations: 'Organizations',
        viewAll: 'View All',
        donated: 'Donated',
        buttonSave: 'Save',
        loadingPercentage: 'Processing..',
        saveSuccess: 'Daily donations percentage was changed',
        organizationsTitle: 'All Organizations',
        infoRows: {
            address: 'Address',
            website: 'Website',
            email: 'Email',
            instagram: 'Instagram',
            twitter: 'Twitter',
        },
        readMore: 'Read More',
        hide: 'Hide',
        donations: 'Donations',
        totalSummary: 'Total Summary',
        time: {
            minuteAgo: 'minute ago',
            minutesAgo: 'minutes ago',
            hourAgo: 'hour ago',
            hoursAgo: 'hours ago',
            dayAgo: 'day ago',
            daysAgo: 'days ago',
        },
        payout: 'Charity Payout',
        noDonations: 'No Donations Yet',
        allDonations: 'All Donations',
        modals: {
            confirmPercentage: {
                title: 'Confirm Your Action',
                text: 'The donation percentage will affect your earnings and transfer them directly to charity organization, are you sure you want change your donation rate?',
            },
        },
        editOrganization: 'Edit Organization',
        editOrganizationNotChosen:
            'You need to select minimum one organization to donate',
        loadingOrganizationChange: 'Processing..',
        changeOrganizationSuccess: 'Charity organization saved',
        organizationDetails: 'Organization Details',
        selectDescription: 'Select organization from list bellow',
        chooseOrganization: 'Choose Organization',
    },
    Marketplace: {
        title: 'Marketplace',
        text: 'You can buy or sell pets to other users in the marketplace',
        description: 'Coming soon',
    },
    Feed: {
        title: 'Social Feed',
        text: 'You can view all available pets and interact with them',
        description: 'Coming soon',
    },
    Leaderboard: {
        [ROUTES.LEADERBOARD_DAILY_TASKS]: {
            title: 'Task Leaderboard',
            text: 'Follow the progress of other users and compete with them doing daily tasks',
            emptyList: {
                title: 'No Users in Task Leaderboard',
                text: 'Sad to say, but no users completed any tasks yet. But no worries it will be fixed quickly',
            },
            energy: 'Collected Energy',
            task: 'Tasks Completed',
        },
        [ROUTES.LEADERBOARD_PVP_GAMES]: {
            title: 'PvP Leaderboard',
            text: "Follow other user's progress and compete by playing and winning PvP games",
            emptyList: {
                title: 'No Users in PvP Leaderboard',
                text: 'Sad to say, but no users completed any games yet. But no worries it will be fixed quickly',
            },
            turnover: 'IGU Turnover',
            totalTurnover: 'Total IGU Turnover',
            pnl: 'PNL (win/loss)',
            games: 'PvP Games Played',
        },
        emptyScreen: {
            title: 'Oops!',
            text: 'Something Wrong. But no worries it will be fixed quickly. Try to pull.',
        },
    },

    playCollectedExperience: {
        screenTitle: 'Collected Experience',
        title: 'Pets Experience',
        text: 'After completed task your pets collected new XP to progress the level',
    },
    playSuccessScreen: {
        button: 'View More Tasks',
        button2: 'Back to Main',
        emptyStates: {
            VERIFY: {
                title: 'Find Task Completed',
                text: 'Congrats! You completed Find task for today. Come back tomorrow for more',
                failTitle: 'Find Task Failed',
                failText:
                    'You failed Find task for today. Come back tomorrow for more',
            },
            MOVE: {
                title: 'Walking Task Completed',
                text: 'Congrats! You walked all distance to complete the task. Come back tomorrow for more',
                failTitle: 'Walking Task Failed',
                failText:
                    'You failed Walking task for today. Come back tomorrow and try better',
            },
            SOCIAL: {
                title: 'Sharing Task Completed',
                text: 'Congrats! You shared a photo and it passed verification. Come back tomorrow for more',
            },
            PLAY: {
                title: 'Game Completed',
                text: 'Congrats! You completed the game for today. Come back for more tomorrow',
                failTitle: 'Game Failed',
                failText:
                    'Unfortunately you failed the game. Try better next time',
            },
        },
    },
    general: {
        howItWorks: 'How it Works',
        comingSoon: 'Coming soon',
        comingSoonDesc: 'This feature is not ready yet.',
        copiedToClipboard: 'Copied to Clipboard',
        createPet: 'Create a Pet',
        next: 'Next',
        or: 'or',
        ok: 'OK',
        loading: 'Loading..',
        loadMore: 'Load More',
        errors: {
            unknown: {
                title: 'Unknown error',
                message: 'Please try again',
            },
            error: {
                title: 'Error',
            },
            networkRequestFailed: {
                title: 'Network error',
                message: 'Network request failed. Try again',
            },
            TASK_WRONG_STATE: {
                title: 'Error',
                message: 'Task is expired, please try again',
            },
            TASK_CREATE_FORBIDDEN: {
                title: 'Error',
                message: 'Task is expired, please try again',
            },
            UNPREDICTABLE_GAS_LIMIT: {
                title: 'Wallet Issues',
                message:
                    'Cannot estimate gas fees, the transaction will not going through. Make sure you have enough funds and BNB to cover a gas fee and then try again',
            },
            INSUFFICIENT_FUNDS: {
                title: 'Not Enough Funds',
                message:
                    'Make sure you have enough funds and BNB to cover transaction fee. Deposit crypto to your wallet and try again',
            },
            fileIsTooBig: {
                title: 'File is too big',
                text: 'Image file must be less than {{size}} MB',
            },
            cantOpenUrl: {
                text: 'Your device can not open URL {{url}}. Copy URL and follow it manually',
            },
            gameAlreadyStarted: {
                text1: 'Game already started',
                text2: 'Please choose another game',
            },
            gameBanned: {
                text1: "You're banned from playing this game",
                text2: 'Wait until further notice',
            },
            wrongGameStatus: {
                text: 'Wrong game status',
            },
            emptyErrorScreen: {
                title: 'Oops!',
                text: 'Something Wrong. But no worries it will be fixed quickly',
            },
            ops: {
                text1: 'Oops!',
                text2: 'Something goes wrong :(',
            },
        },
        success: {
            title: 'Success',
        },
        socialLoading: 'Linking..',
        cancel: 'Cancel',
        back: 'Back',
        current: 'Current',
        knowledgeBaseButton: 'Learn More in Knowledgebase',
        timerTitle: 'Time Remaining',
        hint: '{{hint}}/{{maxHints}} Hints',
        continue: 'Continue', // TODO REMOVE
        skip: 'Skip',
        // TODO: ADD OTHER BUTTON TEXTS
        BUTTONS: {
            mintNewNft: 'Mint New NFT',
            continue: 'Continue',
            continueLater: 'Continue Later',
            mintNft: 'Mint NFT',
            select: 'Select',
            startPlaying: 'Start Playing',
            start: 'Start',
            learnMore: 'Learn More',
            playPvpGames: 'Play PVP Games',
            goToWallet: 'Go to Wallet',
            createNewOrder: 'Create New Order',
            apply: 'Apply',
            clear: 'Clear',
            viewProfile: 'View Profile',
            buyForWithAmount: 'Buy for {{amount}}',
            cancel: 'Cancel',
            delistNft: 'Delist the NFT',
            delist: 'Delist',
            viewMarketplace: 'View Marketplace',
            getStarted: 'Get Started',
            reloadApp: 'Reload App',
            createNewGame: 'Create New Game',
            exploreMoreGames: 'Explore More Games',
            viewGamesHistory: 'View Games History',
            viewStatistics: 'View Statistics',
            tryAgain: 'Try Again',
            createGame: 'Create Game',
            topUpBalance: 'Top Up Balance',
            continueToNextStep: 'Continue to Next Step',
            finishGame: 'Finish Game',
            continueShopping: 'Continue Shopping',
            enableNow: 'Enable Now',
            maybeLater: 'Maybe Later',
            backToSettings: 'Back to Settings',
            turnOff: 'Turn Off',
            purchaseFor: 'Purchase for {{amount}}',
            purchase: 'Purchase',
            viewAll: 'View All',
            view: 'View',
            purchaseMore: 'Purchase More',
            viewDetails: 'View Details',
            readMore: 'Read More',
            hide: 'Hide',
            viewChart: 'View Chart',
        },
        KEYS: {
            ranks: 'Ranks',
            price: 'Price',
            chance: 'Chance',
            rank: 'Rank',
            sortedBy: 'Sorted By',
            typeOfNft: 'Type of NFT',
            totalSummary: 'Total Summary',
            totalSuccessfulOrders: 'Total Successful Orders',
            totalPurchasesSummary: 'Total Purchases Summary',
            NFTs: 'NFTs',
            profile: 'Profile',
            details: 'Details',
            numberAndLevel: '{{level}} Level',
            numberAndHealth: '{{health}} Health',
            deadByAge: 'Pet Was Old And Died',
            numberAndDaysAge: '{{days}} Days Age',
            yourNFT: 'Your NFT',
            vs: 'VS',
            opponentsNFT: 'Opponent’s NFT',
            yourPoints: 'Your Points',
            opponentsPoints: 'Opponent’s Points',
            type: 'Type',
            gameSettings: 'Game Settings',
            difficulty: 'Difficulty',
            mode: 'Mode',
            livesCount: 'Life Count',
            yourAccount: 'Your Account',
            leaderboardPlace: 'Leaderboard Place',
            timeLeft: 'Time Left',
            ticketsAmount: 'Tickets Amount',
            tickets: 'Tickets',
            winner: 'Winner',
            prizes: 'Prizes',
            won: 'Won',
            hash: 'Hash',
            date: 'Date',
            roundId: 'Round ID',
            chainResults: 'Results',
            globalSeed: 'Global Seed',
            gamesHistory: 'Games History',
            users: 'Users',
        },
        FILTERS: {
            byDate: {
                Day: 'Today',
                Week: 'Last 7 days',
                Month: 'Last 30 days',
            },
        },
        ALERTS: {
            notEnoughCoinsOnGamingWallet:
                'Not enough coins on the gaming wallet to join the game. Top up to start playing',
        },
        MERGED: {
            selectText: 'Select {{text}}',
        },
        DECLINATION: {
            Ticket: {
                one: 'Ticket',
                two: 'Tickets',
                five: 'Tickets',
            },
        },
    },
    modal: {
        mintPetLeave: {
            title: 'Do You Want to Leave?',
            text: "Your almost finished creating your pet. Don't give up now!",
            keep: 'Finish Your Pet',
            leave: 'Yes, Leave',
        },
        ModalFreeRank: {
            title: 'You Are Missing Out On Huge Bonus!',
            text: 'Are you sure you want to miss out {{amount}} IGUP (${{dollarValue}}) bonus for mint of highest possible ranks?',
            highest: 'Get My Bonus',
            proceed: 'Proceed Without Bonus',
        },
        askForPermissions: 'Ask For Permissions',
        goToSettings: 'Go to settings',
        try: 'Try Again',
        cancel: 'Cancel',
        deletePhoto: 'Delete NFT Photo?',
        deletePhotoInfo: 'Are you sure you want to delete your photo?',
        yesDelete: 'Yes, Delete',
        noCancel: 'No, Cancel',
        changeRank: 'Do You Want to Change Rank?',
        changeRankInfo:
            'You will be navigated to the first step of the mint process',
        changeRankGo: 'Change Rank',
        upgradeRank: {
            title: 'Upgrade to Rank "{{slug}}"',
            text: 'Some features are unlocked with higher Ranks. Upgrade your Rank to access to this feature',
            upgradeToRank: 'Upgrade to Rank',
        },
        savingName:
            "Saving name, it takes some time. Please don't close the app",
        generatingNFT: 'Doing some magic. Usually it takes up to few minutes..',
        generateNewNFT: {
            title: 'Return to The Previous Step?',
            text: "You will be returned back and lose your current progress. Your pet is unique, we won't be able to create exactly the same",
            yesGenerate: 'Yes, Take Me Back',
            noKeep: 'No, Stay Here',
        },
        askForCameraPermissions: {
            title: 'No Permissions For Camera',
            text: 'You have to grant permissions for camera to be able to use this feature',
        },
        askForLocationPermissions: {
            title: 'No Permissions For Location',
            text: 'You have to grant permissions for location to be able to use this feature',
        },
        askForCameraRollPermissions: {
            title: 'No Permissions For Camera Roll',
            text: 'You have to grant permissions for Camera Roll to be able to use this feature',
        },
        askForLocationPermissionsWeb: {
            title: 'No Permissions For Location',
            text: 'You have to grant permissions for location to be able to use this feature',
            additionalText:
                '. Go to setting and turn on permissions for location',
            button: 'Close',
        },
        nftPreview: {
            title: 'NFT Preview',
            text: 'This a preview of your pet',
        },
        confirmation: {
            title: 'Are You Sure?',
            text: 'Please confirm your action to proceed',
            yes: 'Yes, Confirm',
            cancel: 'Cancel',
        },
        modalChangeLanguage: {
            screenTitle: 'Change Language',
            button: 'Change',
        },
    },
    navigationBar: {
        wallet: {
            MAIN: 'Main',
            NFT: 'NFT',
            REWARDS: 'Rewards',
            GAMING: 'Gaming',
        },
        tasks: {
            active: 'Active',
            history: 'History',
        },
        admin: {
            verify: 'Verify',
            dashboard: 'Dashboard',
        },
        referrals: {
            invites: 'Invites',
            all: 'My Referrals',
        },
        collections: {
            collection: 'Collection',
            mystery: 'Mystery Boxes',
        },
        tooltip:
            'This is the wallet tab. Go here to see your balance and rewards. In the tab, you can withdraw, deposit, claim and swap tokens',
    },
    AppState: {
        GameMaintenanceState: {
            title: 'Game Maintenance',
            text: 'IguVerse Tasks are under maintenance and currently unavailable. Sorry for inconveniences and come back soon',
            button: 'Got It',
        },
        FullMaintenanceState: {
            title: 'App Is Under Maintenance',
            text: 'IguVerse App is under maintenance and currently unavailable. Sorry for inconveniences and come back soon. Stay in touch in our social media',
        },
        GlobalErrorState: {
            title: 'Unscheduled Maintenance',
            text: 'IguVerse App is under development and currently we have unplanned maintenance. Sorry for inconveniences and come back soon',
            button: 'Retry',
        },
        UpdateRequiredState: {
            title: 'Update Available',
            text: 'This version of the app is outdated, please update the app from the {{store}} Store to continue using it',
            button: 'Update',
            cancel: 'Not Now',
            error: "Can't open the link, open {{store}} Store manually and download the newest version",
        },
        UpdateRequiredStateWeb: {
            title: 'Updating the App..',
            text: 'App is currently in the middle of the update, please try again in a few minutes',
            button: 'Try again',
        },
        NoNetworkConnectionState: {
            title: 'No Connection',
            text: 'Looks like you have some troubles with internet connection. Internet is required for the app to work properly',
            button: 'Got It',
            reconnected: 'Reconnected!',
            reconnecting: 'Connection lost. Reconnecting..',
        },
    },
    ranks: {
        rankLongTitle: '{{rank}} Rank',
        level: '{{level}} LVL',
        upgradeRankButton: 'Upgrade Rank',
        upgradeText:
            "You achieved all Levels in your current Rank. It's time to upgrade to {{rank}} Rank and boost your performance",
        lockedText:
            '{{rank}} Rank is not available for mint. You can achieve it by leveling up and progress through the game',
        perDay: 'Daily',
        dailyUnlock: 'Daily Unlock',
        foodConsumption: 'Daily Consumption',
        maxAge: 'Max. Age',
        days: 'Days',
        list: {
            free: {
                title: 'Free',
                infoText:
                    'The Free Rank will give you the opportunity to explore the app and its functions. However, you cannot claim or withdraw rewards before purchasing or ranking up your NFT',
            },
            starter: {
                title: 'Starter',
            },
            common: {
                title: 'Common',
            },
            uncommon: {
                title: 'Uncommon',
            },
            unusual: {
                title: 'Unusual',
            },
            rare: {
                title: 'Rare',
            },
            mystical: {
                title: 'Mystical',
            },
            legendary: {
                title: 'Legendary',
            },
            epic: {
                title: 'Epic',
            },
            exotic: {
                title: 'Exotic',
            },
            royal: {
                title: 'Royal',
            },
            diamond: {
                title: 'Diamond',
            },
        },
        ranksDetailedInfoScreen: {
            button: 'Mint NFT',
            current: 'Current',
        },
        rankDetailedInfo: {
            reward: {
                title: 'Daily Reward ≈ {{reward}} ≈ {{usd}}',
                text: 'Estimated daily reward in tokens if all daily tasks are completed',
                warning:
                    'The value is only an estimation of the pet earning capacity and it may vary',
                warningFree:
                    'Free rank has a random chance to earn reward in the game. There are no guaranteed rewards for Free rank',
            },
            rate: {
                title: 'Daily Unlock Rate ≈ {{reward}} ({{rate}}) ≈ {{usd}}',
                text: 'You can transfer {{percent}}% of your available VIGUP rewards to your main wallet. Once claimed, the token can be exchanged, sent to a different wallet or withdrawn',
                alert: 'The rest of the VIGUP tokens are pending and will be stored in the Locked Wallet',
            },
            consumption: {
                title: 'Food Consumption = {{consumption}}',
                text: 'Your pet consume tokens every day. If pet is not fed on time it loses health and may die',
            },
            age: {
                title: 'Maximum Age = {{maxAge}} Days',
                text: 'Once your NFT gets to this age, it will die and will need to be restored',
                alert: "A Rank Up will reset the age of your NFT back to 0, and the upgraded virtual pet will live according to the age limit of the new Rank. After restoring the NFT, you'll start from Level 1",
            },
        },
        modalWhatIsRank: {
            title: 'What is Rank?',
            text: 'Rank affects your pets earning capacity and other important characteristics like Daily Unlock Rate and Pet Consumption Rate. The higher the rank the better characteristics',
            buttonAvailable: 'View Available Ranks',
            buttonAll: 'View All Ranks',
        },
        checkoutUpgradeRank: {
            title: 'Upgrade Rank',
            text: 'The higher the rank, the more rewards your pet will earn',
            upgradeTo: 'Upgrade to',
            transaction: 'Upgrade Rank to {{rank}} Rank for "{{name}}" NFT',
        },
        successUpgradeRank: {
            title: 'Rank Upgraded!',
            text: "NFT's rank was successfully upgraded. Play daily tasks and earn even more rewards",
            backToPet: 'View pet',
        },
    },
    localNotifications: {
        moveToEarnFinished: {
            title: 'Walking task finished',
            message: 'Open app and see how much Energy you collected',
        },
        noPets: {
            title: 'Hurry up to get your pet',
            message:
                'Get your first pet and start your amazing journey in IguVerse',
        },
    },
    monthPicker: {
        month1: 'January',
        month2: 'February',
        month3: 'March',
        month4: 'April',
        month5: 'May',
        month6: 'June',
        month7: 'July',
        month8: 'August',
        month9: 'September',
        month10: 'October',
        month11: 'November',
        month12: 'December',
        selectMonth: 'Select',
    },
    staking: {
        stakeLoading: 'Enabling boost, do not close the app...',

        unStakeLoading: 'Removing boost, do not close the app...',
        general: {
            dailyBoost: 'Daily Boost',
            ended: 'Ended On',
            summary: 'Total',
            days: '{{days}} Days',
            current: 'Current Boost',
        },
        plans: {
            title: 'Options Details',
            text: 'Each Boost option determines the lock period and the bonus',
            days: ' Days',
            boost: 'Boost up to {{boost}}%',
        },
        welcomeScreen: {
            title: 'Boost',
            headerTitleStart: 'Earn ',
            headerTitleCenter: 'Up to {{number}}%',
            headerTitleEnd: ' with Boost',
            headerText:
                'You can boost your in-game rewards by locking your tokens for a certain period of time',
            stake: {
                title: 'Boost Options',
                text: 'Invest your IGU and IGUP tokens for a reward boost or interest',
            },
            lock: {
                title: 'Lock Period',
                text: 'Your tokens will be locked for a period of time specified in the option',
            },
            calculation: {
                title: 'Boost Calculation',
                text: 'Boost is recalculated every day depending on your daily rewards',
                alert: 'If your rewards have changed, the boost will also change. You will always be able to see the current boost',
            },
            button: 'Enable Boost',
        },
        optionsScreen: {
            toastAlready: 'The boost option is already active',
            button: 'Continue',
            screenTitle: 'Boost Option',
            title: 'Select Boost Option',
            text: 'Choose a boost option for your tokens. You can engage several boost options at the same time',
            IGUP: {
                title: 'Boost with IGUP',
                text: 'Lock your IGUP for a certain period of time and receive a boost to your daily rewards!',
            },
            // IGU_REWARD: {
            //     title: 'Stake IGU for Boosted Rewards',
            //     text: 'Lock your IGU for a while to boost your rewards — increase your earnings by X% with the longest staking plan',
            // },
            // IGU_INTEREST: {
            //     title: 'Stake IGU for Interest',
            //     text: 'Lock your IGU for some time and get an interest on your tokens',
            // },
        },
        plansScreen: {
            button: 'Continue',
            selectPlan: 'Select Option',
            title: 'Select Boost Option',
            headerTitle: 'Select Option',
            headerText:
                'The amount you choose will be locked in the smart contract until the end of the period. Only one plan can be invested at the same time',
            headerInfoText: 'Which Plan to Select?',
            lock: {
                title: 'Lock Period = {{days}} Days',
                description:
                    'The lock period is the period of time when your tokens will be locked and the bonus will be active',
            },
            boost: {
                title: 'Boost = ~{{boost}} %',
                description:
                    'Rewards Boost is a modifier that increases your in-game rewards by a certain percentage',
            },
            calculation: {
                title: 'Boost = ~{{boost}} %',
                description:
                    'Boost is recalculated every day based on your rewards',
                alert: 'If your rewards have changed, the boost will also change. You will always be able to see the current boost',
            },
        },
        coinsInputScreen: {
            title: 'Select Amount of Tokens',
            button: 'Calculate Daily Boost',
            button2: 'Skip Step',
            loading: 'Calculating daily boost..',
            available: 'Available: ',
            maxButton: 'Max',
            errors: {
                digits: 'Must be only digits',
                max: 'Not enough tokens on your balance. Please enter another amount or deposit',
                min: 'The sum must be at least {{limit}} {{coin}}',
                maxStack: 'The sum must be at maximum {{limit}} {{coin}}',
            },
            maxBoost: 'Invest {{amount}} {{coin}} to Get {{boost}}% Boost',
            maxBoostModal: {
                title: 'Max out your boost',
                text: 'Lock the sum suggested and receive the maximum boost possible in this option!',
                button: 'Add Coins',
                deposit: {
                    title: 'Optimal Deposit = {{amount}} {{coin}}',
                    text: 'The optimal number of tokens for the highest yield. The value depends on the number of pets and their stats',
                },
                boost: {
                    title: 'Maximum Daily Boost = {{boost}}%',
                    text: "The daily boost after the optimal deposit is invested. The boost will be recalculated each day depending on your pets' stats. You can always add tokens if needed!",
                },
            },
        },
        dailyBoostScreen: {
            title: 'Daily Boost',
            headerTitle: 'Daily Boost = ',
            headerText:
                'The boost will be active within the option lock period, after which the tokens will become claimable',

            button: 'Confirm',

            confirmationModal: {
                title: 'Confirm',
                keys: {
                    from: 'From',
                    duration: 'Duration',
                    endingOn: 'Ending On',
                    dailyBoost: 'Daily Boost',
                    summary: 'Amount',
                },
                values: {
                    from: 'Main Wallet',
                    duration: '{{plan}}, {{days}} Days',
                },
            },
        },
        successScreen: {
            title: 'Enabling boost Successfully',
            text: 'Your tokens are invested and will be held until the end of the plan. After the end of the plan, you have to use "Get Tokens" button to release the tokens back to your wallet',
            button: 'Back to Wallet',
        },
        successUnStakeScreen: {
            title: 'Removing boost successfully',
            text: "You've successfully removing boost. Tokens are returned to your Main Wallet",
            button: 'Back to Wallet',
        },
        alreadyScreen: {
            title: 'Boost',
            headerTitle: 'Plan Details',
            headerText:
                'You have invested your tokens and it will be held until the end of the plan. After the end of the plan, the tokens can be released to the Main wallet',
            headerInfoText: 'How it Works?',

            confirmationModal: {
                title: 'Confirm transaction',
                keys: {
                    from: 'To Wallet',
                    duration: 'Duration',
                    endingOn: 'Ended On',
                    dailyBoost: 'Daily Boost',
                    summary: 'Total Summary',
                },
                values: {
                    from: 'Rewards Wallet',
                    duration: '{{plan}}, {{days}} Days',
                },
            },
            staked: {
                title: '{{coin}} Boost',
                text: 'Active plan. You can add more tokens',
                timerTitle: 'Time Remaining',
                staking: 'Boost:',
                deposit: 'Deposited On:',
                end: 'Ended On:',
                boost: 'Daily Boost:',
                summary: 'Summary:',
                buttonAdd: 'Add More Tokens',
                buttonUpgrade: 'Upgrade Plan',
                buttonUnstake: 'Get Tokens',

                modalAddCoins: {
                    title: 'Add More Tokens?',
                    text: 'After you add tokens, your boost option will be recalculated based on the deposited amount and the timer will restart',
                    button1: 'Add Tokens',
                    button2: 'Cancel',
                },
                modalUpgrade: {
                    title: 'Upgrade Plan?',
                    text: 'After you upgrade your boost option, it will be recalculated based on the deposited amount and the timer will restart',
                    button1: 'Upgrade',
                    button2: 'Cancel',
                },
            },
        },
    },
    timer: {
        captions: {
            day: 'Days',
            hour: 'Hours',
            min: 'Min',
            sec: 'Sec',
        },
        newDay: 'New day',
        refreshed: 'Your tasks refreshed',
    },
    referrals: {
        myReferrer: 'You are invited by this user',
        referralCode: 'Your Ref. Code',
        referralLink: 'Your Ref. Link',
        header: {
            title: 'Invite New Users',
            text: 'Invite other users and earn even more with our referral programme. See how it works bellow',
            infoText: 'How it Works?',
        },
        direct: {
            title: 'Direct Referral - {{directPercentage}}%',
            text: 'You will get {{directPercentage}}% of all rewards earned by friends',
        },
        indirect: {
            title: 'Indirect Referral - {{inDirectPercentage}}%',
            text: 'You will get {{inDirectPercentage}}% of all rewards earned by friends of friends',
        },
        gaming: {
            title: 'Gaming Bonuses',
            text: 'The referral bonuses are active both for PvP games and game tasks',
        },
        buttonShare: 'Invite Users',
        invitedReferrals: {
            card: {
                direct: 'Direct Referrals',
                indirect: 'Indirect Referrals',
                active: 'Active Referrals (Direct)',
                turnover: 'Total Turnover',
            },
            viewReward: 'View Rewards',
            indirectModal: {
                title: 'Indirect Referral',
                text: 'This user was invited by one of your friends',
            },
            header: {
                title: 'Invited Referrals',
                text: 'Here you can see all invited referrals and your daily boost if you done all terms',
                infoText: 'How Daily Boost Works?',
                list: 'Referrals',
                sort: 'Sort By',
                filter: {
                    CreatedAt: 'Latest',
                    UserEnergy: 'Energy',
                },
            },
        },
        emptyScreen: {
            title: 'Oops!',
            text: 'Something Wrong. But no worries it will be fixed quickly. Try to pull.',
        },
        emptyList: {
            title: ' No Invited Referrals Yet',
            text: "You haven't invited any users yet. But it's not a problem, let's do it right now",
        },
        allInvitedReferralsScreen: {
            title: 'All Invited Referrals',
        },
        inviteUsersScreen: {
            title: 'Invite User',
            text: 'Download IguVerse app and provide the referral code',
            myNFT: 'My Pet NFT',
            collectedEnergy: 'Collected Energy',
            leaderBoard: 'Leaderboard Place',
            button: 'Share Code',
            download: 'Download IguVerse App',
        },
        referralCard: {
            title: 'Use Referral Code',
            text: 'Enter code the referral to get additional bonuses',
            button: 'Enter Code',
        },
        referrerModal: {
            title: 'Referral Code',
            text: 'Enter code the referral and you will receive different discounts and bonuses',
            label: 'Referral Code',
            placeHolder: 'Enter referral code',
            noCode: "Don't have a code? ",
            apply: 'Read more about referrals',
            button: 'Submit Referral Code',
            success: {
                toastSuccess: {
                    title: 'Referral code submitted',
                    text: 'Now all functions is available to you',
                },
            },
            error: {
                empty: 'Referral code can no be empty',
                incorrectCode:
                    "Incorrect Code. Referral code doesn't exist or is expired",
                toastError: {
                    title: 'Incorrect Code',
                    text: "Referral code doesn't exist or is expired",
                },
                forbidden: 'Can not use referral code on this device',
                toastForbidden: {
                    title: 'Forbidden',
                    text: 'Can not use referral code on this device',
                },
                toastAlreadyLinked: {
                    title: 'Already used',
                    text: 'Your account already used Referral code',
                },
            },
        },

        boostCards: {
            cardTitle: 'Daily Boost = {{boost}}%',
            cardText: 'You need to invite ',
            users: ' Users',
            text: 'The more users you invite, the more daily boost you will get',
            button: 'View Rewards',
        },
        howBoostWorks: {
            title: 'Get Boost to Daily Rewards',
            text: 'Invite users and get boost to daily rewards after reaching exact number of referrals. Boost will automatically applied when you achieve it',
            alert: 'Boost is recalculated every day and is valid only for active referrals, who play every day and achieve {{rewardEnergyThreshold}} Energy',
        },
        modalReferee: {
            title: 'Meet your Referral',
            text: "You can visit the invited user's social media to learn more about your referral or get to know them",
            energy: 'Collected Energy (24h)',
        },
    },
    aiQuoteInfo: {
        alertCard: {
            overload: {
                title: 'System Overloaded',
            },
            dailyAttempts: {
                title: '{{attempts}} Attempts Left',
            },
        },
        modalInfo: {
            title: 'NFT from Your Imagination',
            text: 'You can create NFT using AI technology but this operation is very costly. Sometimes due to high load it may be temporary unavailable',
            daily: 'Last 24 hours Usage = ',
            dailyText:
                'You can generate no more than {{amount}} unique pets using AI technology for the 24 hours period',
            systemLoad: 'System Load = {{load}}',
            systemLoadText:
                'Sometimes system may be overloaded due to big demand. You will have to wait a little bit',
            load: {
                low: 'Low',
                medium: 'Medium',
                high: 'High',
                overloaded: 'Overloaded',
            },
            systemUsage: 'System Usage = {{percentage}}%',
            systemUsageText:
                'The system is in Beta version and currently is available only certain amounts per day',
        },
        modalErrorNoMints: {
            title: 'No Mints Left',
            text: 'We regret your inform that all mints have been spent for today. But no worried, you can try to mint NFT using AI technology tomorrow',
            button1: 'Mint from Photo',
        },
        modalErrorOverLoad: {
            title: 'System Overloaded',
            text: "We regret your inform that AI mint is overloaded now from from other users. We will let you know, when it's available",
            button1: 'Mint from Photo',
        },
    },
    howTo: {
        fee: {
            stepOne: {
                title: 'Blockchain Fee is Approximate',
                text: 'All transactions in the app take place on the blockchain and have a commission fee in BNB. The fee amount can only be known at the moment when transaction is executed and we only show you an estimate',
            },
            stepTwo: {
                title: 'Higher Fee For Tokens',
                text: 'On the blockchain, you have to approve using tokens before executing a certain transaction. Paying with BNB will take only one transaction, while spending other tokens requires two transactions: approval and execution',
            },
        },
        charity: {
            stepOne: {
                title: 'Help Animal Organizations',
                text: 'You are able to select charity organizations from the list and donate to it to help animals across the world',
            },
            stepTwo: {
                title: 'Set Preferable Percentage',
                text: 'You can choose the percentage from the rewards to be donated (from 1% to 100%). Also you can change this value anytime',
            },
        },
        play: {
            button: 'Start Playing',
            stepOne: {
                title: 'Choose Pets to Complete Tasks',
                text: 'Choose pets in the section below which will participate in the game. The first pet in the ordered will be used in the Social task and will be displayed as your main Pet.',
            },
            stepTwo: {
                title: 'Restore Energy',
                text: 'You have 24 hours to restore your Energy level by completing the tasks before the end of the day. 100% Energy means you will earn 100% from your maximum possible rewards based on pets stats. Every day when timer reaches 00:00, Energy level resets',
            },
            stepThree: {
                title: 'Earn Rewards',
                text: 'After completing tasks your Energy will be converted to Rewards based on your pets stats',
            },
            stepFour: {
                title: 'Boost your NFTs',
                text: 'Level up your pets, mint new pets, upgrade ranks to enjoy more benefits. Get to the highest position in Leaderboard and enjoy even more rewards',
            },
        },
        referrals: {
            button: 'Invite Users',
            stepOne: {
                title: 'Share Your Referral Code',
                text: 'Invite users to IguVerse app and earn from their playing. For direct referrals you will get - {{direct}}% and for indirect referrals - {{inDirect}}%',
            },
            stepTwo: {
                title: 'Get Boost to Daily Tasks',
                text: 'Invite users and get boost to daily tasks after reaching exact number of invites. Find more details in “My Referrals” tab',
            },
            stepThree: {
                title: 'Receive More Discounts',
                text: 'In the block “NFT to Play” choose minimum one NFT to play games. Be aware that first NFT will collect the most level experience',
            },
            stepFour: {
                title: 'Get Gaming Bonuses',
                text: 'For every game won and for every top-up, direct referrals will give you {{direct}}% of the sum, while indirect referrals — {{inDirect}}%',
            },
        },
        availableRewards: {
            stepOne: {
                titleOne: 'Claim ',
                titleTwo: ' Of Your Rewards',
                text: 'You can transfer {{percent}}% of your available VIGUP rewards to your main wallet. Once claimed, the token can be exchanged, sent to a different wallet or withdrawn',
            },
            stepTwo: {
                title: 'Claim Operation',
                text: 'Claim is blockchain operation which gives you the right to convert your VIGUP balance to your IGUP main wallet. After successful claim funds are available immediately',
            },
            stepThree: {
                title: 'Exchange VIGUP for ',
                text: 'You will be able to exchanged the tokens earnt for the IGUAI token once the marketplace is released. By using the app, you become an early adopter of our top-layer solution',
            },
            stepFour: {
                title: 'Negative Balance',
                text: 'Sometimes your available balance can be negative, which means that you used your locked rewards for different in-game transactions like Healing and Level Up. We allow your available rewards to go below zero, up to value of your locked balance. Once you tokens unlock, available balance will go up too',
            },
            alert: 'The rest of the VIGUP tokens are pending and will be stored in the Locked Wallet',
        },
        lockedRewards: {
            stepOne: {
                title: 'Locked Rewards',
                text: 'Earned rewards are locked according to the pets stats (Rank and Level). Upgrade your pets to decrease lock period',
            },
            stepTwo: {
                title: 'Converting to Available Rewards',
                text: 'Locked rewards are unlocking automatically and once unlocked, will be available under Available Rewards balance',
            },
            stepThree: {
                title: 'Exchange VIGUP for ',
                text: 'You will be able to exchanged the tokens earnt for the IGUAI token once the marketplace is released. By using the app, you become an early adopter of our top-layer solution',
            },
        },
        socializeToEarn: {
            CONFIRMED: 'Correct',
            FAILED: 'Incorrect',
            step: {
                title: 'IguVerse Link Added',
                text: 'Always add the link to the IguVerse app, which can be found in the Share Screen, when sharing Pet ID. The link should be attached to the story itself.',
            },
            stepOne: {
                title: 'Pet ID And Link Are Fully Visible',
                text: 'Pet ID and the link should be identifiable by the verifier without an difficulty. The link to the app should be active',
            },
            stepTwo: {
                title: 'Slight modifications are OK',
                text: 'You can change the size and color of Pet ID or its tilt. Naturally, these modifications should not contradict the first rule',
            },
            stepThree: {
                title: 'Pet ID is hard to make out',
                text: 'We do not allow any modifications of Pet ID, including cropping and hiding the picture or its parts. Swipe to learn more',
            },
            stepFour: {
                title: 'Pet ID is covered',
                text: 'No overlaying or overpainting the picture, as well as excessive downsizing is allowed',
            },
        },
        verifyUser: {
            stepOne: {
                title: 'Find Pet ID in social media',
                text: "Follow other user's social media account and check the content and locate the Pet ID. Type the code in the app and complete the task",
            },
            stepTwo: {
                title: 'Provide Found Pet ID',
                text: 'Provide the code from Pet ID or scan it using QR code scanner. In-case its not there, click Pet Not Found button. Find all users to complete task',
            },
            stepThree: {
                title: 'Be Responsible',
                text: 'Play fair and do not try to cheat by misleading the system by providing wrong IDs or claiming that you can not find. Your account may be flagged or banned if you continue to do this',
            },
        },
        purchaseBNB: {
            screenTitle: 'How to Purchase BNB',
            title: 'Purchase BNB on Binance',
            text: 'BNB is a cryptocurrency that is used to pay fees on Binance Smart Chain, the blockchain we use. Watch the video below to learn more about depositing BNB into the in-game wallet',
            guideBlock: {
                title: 'Purchase & Withdrawal Guide',
                first: "Log into the Binance app. Download, register and verify yourself if you don't have it",
                second: 'Buy crypto with a card or the p2p service. You can choose BNB right away!',
                third: 'Open the Wallet tab and tap Withdraw',
                fourth: 'Choose the cryptocurrency you want to withdraw. In our case, BNB',
                fiveStepOne:
                    'Paste the address you want to withdraw to and select the network: ',
                fiveBold: 'BNB Smart Chain (BEP-20)',
                fiveStepTwo:
                    '. Your wallet address can be found in the wallet tab of the IguVerse app. It starts with 0x and is a combination of letters and numbers',
                six: "Wait a few seconds for the transaction to be processed and it's done!",
            },
            resourceBlock: {
                title: 'Useful Resources',
                buy: 'How to Buy BNB (BNB) Guide',
                withdraw: 'How to Withdraw from Binance',
                status: 'How to Check Cryptocurrency Transaction Status on Blockchain',
            },
        },
        petOrderId: {
            title: 'Put Pets In Order',
            text: 'Choose the Playable Character Pets in the "NFT to Play" section and order the rest! The ordering of pets will determine the XP they receive',
            cardFeatureInfo: {
                title: 'Pet Priority',
                text: "The first Playable Character Pet will represent you in your Pet ID, in Pet's voting, in the leaderboard",
            },
            button: 'Reorder Pets',
        },
        howToGamingAvailableWallet: {
            screenTitle: 'Available Wallet',
            button: 'Top Up',
            stepOne: {
                title: 'Play Competitive Games',
                text: 'The gaming wallet allows you to compete with other players for rewards and win IGU. Use the gaming tab to see available games',
            },
            stepTwo: {
                title: 'Top Up The Gaming Wallet',
                text: 'To take part, top up your gaming wallet with IGU and receive VIGU. Use the button below to top up tokens',
            },
            stepThree: {
                title: 'Withdraw to the Main Wallet',
                text: 'The rewards won in competitive games will be stored in the gaming wallet. You can withdraw these rewards to the main wallet at any time',
            },
            stepFour: {
                title: 'Get Bonuses from Referrals',
                text: 'For every game won and for every top-up, direct referrals will give you {{direct}}% of the sum, while indirect referrals — {{indirect}}%',
            },
        },
        howToGamingPendingWallet: {
            screenTitle: 'Pending Wallet',
            alert: 'The tokens in the Pending Gaming Wallet are not held by IguVerse and are locked in a smart-contract for the duration of the game',
            stepOne: {
                title: 'Frozen While Playing',
                text: 'The funds in the Gaming Wallet will be frozen until you finish the game. This measure has been implemented to prevent scams',
            },
            stepTwo: {
                title: 'Automatic Unfreezing',
                text: "If you've won the game, your funds will be automatically returned to the Gaming Wallet. Otherwise, they will be given to the winner",
            },
        },
        howToCommissionFee: {
            screenTitle: 'Commission Fee',
            stepOne: {
                title: ' Commission Fee',
                text: 'We charge a {{commission}}% commission fee for each withdrawal from the Gaming Wallet',
            },
            stepTwo: {
                title: 'Improved Tokenomics',
                text: 'The PvP games follow the pool economic model, which is superior to the economies of the most GameFi projects. The commission fee will be deducted to further strengthen the economy',
            },
        },
        HowToCollections: {
            stepOne: {
                title: 'Virtual Pet Companion',
                text: 'Create an NFT pet with the help of AI that will help you earn on social media. You can also upload a photo of your own pet',
            },
            stepTwo: {
                title: 'Restore Energy',
                text: 'You have 24 hours to restore your Energy level by completing the tasks before the end of the day. 100% Energy means you will earn 100% from your maximum possible rewards based on pets stats. Every day when timer reaches 00:00, Energy level resets',
            },
            stepThree: {
                title: 'Exchange VIGUP for ',
                text: 'You will be able to exchanged the tokens earned for the IGUAI token once the marketplace is released. By using the app, you become an early adopter of our top-layer solution',
            },
        },
    },
    energyInfoScreen: {
        title: 'Collect Energy and Exchange it to Tokens',
        text: 'Your Energy/IGUP exchange rate depends on amount of pets, ranks, levels and other modifiers like boost',
        button: 'Start Playing',
        features: {
            first: {
                title: '1 Energy ≈ {{amount}} IGUP',
                text: 'This is the approximate exchange rate which may change depending on different conditions',
            },
            second: {
                title: 'Energy threshold = {{energy}}',
                text: "If you don't reach minimum threshold of {{energy}} Energy points, half of your Energy points will be burned, and another half transferred to the next day",
            },
        },
    },
    initial: {
        initialMint: {
            title: 'Create Your First Pet and Start Your Journey in IguVerse',
            text: 'This is the last step before your amazing journey in IguVerse. Create your first pet and start playing and earning rewards for every day activity with your pet',
            button: 'Create Your First Pet',
        },
        initialReferral: {
            title: 'Enjoy Referral Mint Discount!',
            text: 'You`ve been invited to our app. Enjoy discounts and bonuses for playing IguVerse. Hurry up to create your NFT pet',
            button: 'Continue',
        },
    },
    ModalShareOrSave: {
        title: 'Save Your Content',
        text: 'Save your favourite IguVerse content to camera roll or share it with your friends',
        save: 'Save to Camera Roll',
        share: 'Share',
        loading: 'Preparing content..',
        saveSuccess: 'Content saved to Camera Roll',
    },
    RelatedApps: {
        downloadApp: {
            titleiOS: 'App available on iOS',
            titleAndroid: 'App available on Android',
            description: 'Enjoy better experience in mobile app',
            buttonOk: 'Download App',
            buttonCancel: 'Not Now',
        },
        switchToApp: {
            titleiOS: 'Switch to iOS App',
            titleAndroid: 'Switch to Android App',
            description: 'Enjoy better experience in mobile app',
            buttonOk: 'Switch to App',
            buttonCancel: 'Not Now',
        },
    },
    ShareYourFeedback: {
        title: 'Share Your Feedback',
        text: 'You can share your feedback with us, and by submitting this form you will send or client side logs to us, so we can help identify your problem',
        placeholder: 'Describe your problems in details',
        button: 'Submit',
        loading: 'Sending..',
        accessibility: 'Disable feature "Send Your Feedback On Shaking"',
    },
    ModalMainnet: {
        title: 'Feature Unavailable',
        text: 'This feature is unavailable until IGU goes live on exchanges. Exactly date of listing will be announced soon',
        button: 'OK',
    },
    ModalDailyRewards: {
        [DAILY_REWARD_STATUS.Bonus]: {
            title: 'PvP Bonus Credited',
            text: "For your activity in PvP games, you've been given a bonus that is deposited straight into the Gaming Wallet",
        },
        [DAILY_REWARD_STATUS.Daily]: {
            title: 'Daily Reward Available In Your Wallet',
            text: "Your rewards for yesterday's tasks have arrived!",
        },
        [DAILY_REWARD_STATUS.Both]: {
            title: 'You Received Daily Reward & PvP Bonus',
            text: "Your daily rewards have arrived and already stared to be unlocked! You've also received bonus tokens for playing PvP games",
        },

        dailyReward: {
            title: 'Available Rewards {{availableAmount}} / {{totalAmount}} IGUP',
            text: '{{availableAmount}} IGUP is available immediately in your wallet',
        },
        unlocks: {
            title: 'Locked Tokens',
            text: '{{restAmount}} IGUP will be unlocked gradually during the unlock period',
        },
    },
    MintBonusScreen: {
        mintSuccess: {
            title: 'Bonus Program',
            text: 'Mint Bonus',
        },
        BonusMintRanksDetailedInfoScreen: {
            title: 'Mint NFT - Get instant IGUP Bonus',
            text: 'We offer you to mint NFT and get a bonus that you can withdraw in the future. Continue to next step and finish mint process',
            button1: 'Continue to Next Step',
            button2: 'View All Bonuses',
            timeRemaining: 'Time Remaining',
            features: {
                bonus: {
                    key: 'Rank Bonus = {{amount}} IGUP (${{dollarValue}})',
                    value: 'The tokens which will be credited to your account after successful mint',
                },
                lock: {
                    key: 'Lock Period = {{days}} Days',
                    value: 'Tokens will be available to withdraw after lock period finishes',
                },
            },
        },
        BonusMintRanksCarouselScreen: {
            screenTitle: 'Rank Bonus',
            title: '{{slug}} Rank',
            button1: 'Select Rank',
            features: {
                bonus: {
                    key: 'Rank Bonus = {{amount}} IGUP (${{dollarValue}})',
                    value: 'The tokens which will be credited to your account after successful mint',
                },
                lock: {
                    key: 'Lock Period = {{days}} Days',
                    value: 'Tokens will be available to withdraw after lock period finishes',
                },
            },
        },
    },
    YourPetsAreHungry: {
        title: 'Your Pets are Hungry!',
        text: 'Lost Health will decrease your rewards and can make your NFTs unusable after a while. Feed your pets to earn consistently!',
    },
    priceUpModal: {
        title: 'Price Increase Up To ',
        text: 'Due to the high demand and rapid growth of the project our team decided to raise the prices of the NFTs to reward the early adopters and make sure that the economy remains sustainable and balanced in the long run',
        priceUpdate: 'Price Increase In',
        button: 'Mint New NFT',
    },
    updateModal: {
        banner: {
            title: 'New Update is Out!',
            text: 'Check new {{version}} version of the app',
        },
        screen: {
            screenTitle: 'New Update',
            title: 'New Update is Out!',
            text: 'The IguVerse team has been working hard to improve your experience of the app and develop new features. Enjoy these improvements in the new{{version}} version',
            button: 'Start Playing',
        },
    },
    communityModal: {
        title: 'Get Help From Our Community',
        text: 'Join our official community chats to get your questions answered from other players. Learn how to play, maximize your profit and enjoy IguVerse together!',
        button1: 'View More',
        button2: "Don't Show it Again",
    },
    socialTooltip: {
        text: 'Click on the "Failed" tag to know why your account or Pet ID did not meet our criteria and was rejected. You will access Knowledge Base to learn about your next steps',
    },
    gameIterationText: {
        text: "Yesterday's rewards are being counted, which usually takes about {{minutes}} minutes. You'll be able to play again once this process ends",
        toastErrorTitle: "Yesterday's rewards are being counted",
    },
    howConnectFacebookModal: {
        info: 'How to Connect',
        screenTitle: 'How to Connect Facebook',
        title: 'Finding Facebook ID',
        text: "To connect your Facebook account, you need to find and copy your Facebook ID. Visit your profile page and copy the part of the link after / — it's your Facebook ID",
        button: 'Connect Account',
    },
    listingIgu: {
        iguListing: 'IGU Listing',
        title: ' is getting ready for ',
        textOne: 'The listing on the {{exchange}} exchange will take place on ',
        textTwo: ". Fasten your seatbelt, Iguanas, we're taking off!",
        button: 'Explore in Exchange',
        listed: {
            title: ' is listed on ',
            text: "The long-awaited IGU launch has finally come — it's only the start of our Space Odyssey! Buy and trade the IGU coin on the {{exchange}} exchange right now",
        },
    },

    bybitVote: {
        title1: 'Vote for IGU & Share ',
        title2: ' in Rewards!',
        text: 'IguVerse chosen to take part in ByVotes, where you can vote for the next ByVit listing. Vote for IGU & receive rewards!',
        banner: {
            title1: 'Vote & Win from ',
            title2: '',
        },
        button: 'Learn More',
    },
    howToPurchaseIGU: {
        modalTitle: 'How to Purchase IGU',
        exchangesModalTitle: 'Buy IGU on Exchanges',
        cardDeposit: {
            title: 'Deposit',
            text: 'You can deposit supported tokens from any BSC BEP-20 wallet',
        },
        cardExchange: {
            title: 'Buy On Exchanges',
            text: 'Buy & trade IGU on the exchanges it has been listed on',
        },
        cardTrade: {
            title: 'Trade In The App',
            text: 'Fast & conveniently trade IGU right in the app',
        },
    },
    howToClaimFreePet: {
        title: 'Buy An NFT To Claim',
        text: "You cannot claim rewards, as you don't have any NFTs on your account. A Free Pet is not an NFT, as it is not on the blockchain. Buying an NFT pet or ranking up the one you have will give you access to rewards",
        alert: "All the rewards you've earn previously are tracked and you will be able to claim them after the purchase",
        button: 'Mint New NFT',
    },
    howToClaimOnButton: {
        titleOne: 'Claim ',
        titleTwo: ' Of Your Rewards',
        text: 'You can transfer {{percent}}% of your available VIGUP rewards to your main wallet. Once claimed, the token can be exchanged, sent to a different wallet or withdrawn',
        alert: 'The rest of the VIGUP tokens are pending and will be stored in the Locked Wallet',
        button: 'Claim Your Reward',
    },

    onBoarding: {
        mainWallet: {
            title: 'Main Wallet',
            stepOne: {
                title: 'All Your Crypto Is Here',
                text: 'This wallet stores all the supported tokens available in your account. Unsupported tokens are not displayed. All your rewards will be transferred here after claim',
            },
            stepTwo: {
                title: 'Navigate Between Wallets',
                text: 'From the Main Wallet tab, you can open all the other wallets available in your account by swiping. These wallets include the NFT Wallet, the Reward Wallet, and the Gaming Wallet',
            },
        },
        login: {
            stepOne: {
                title: 'New Way To Earn Crypto',
                text: 'Use your daily social media routine to earn crypto tokens! The tasks include sharing content online, walking and playing an arcade game',
            },
            stepTwo: {
                title: 'Virtual Pet Companion',
                text: 'Create an NFT pet with the help of AI that will help you earn on social media. You can also upload a photo of your own pet',
            },
            stepThree: {
                title: 'Competitive Games',
                text: 'Want to earn much more? Challenge others in our PvP game modes and earn big',
            },
            stepFour: {
                title: 'Donations for Animals',
                text: '1% of your daily rewards goes to animal welfare. You can increase the donation size in the app',
            },
            stepFive: {
                title: 'Future Products',
                text: 'The app is just the first product in a series. Become an early adopter of our AI-powered marketplace and a decentralized ad network once they release',
            },
        },
    },

    HomeScreen: {
        MemsModal: {
            title1: '',
            title2: ' Drop on MemeFi Discord: Join Now!',
            text: "MemeFi's just launched an NFT Giveaway Campaign on Discord. A simple task will get you Royale Tickets for NFTs with $MEMF drops",

            button1: 'Join Discord',
            button2: 'Explore Website',
            banner: {
                title: '$450.000 Drop',
                text: "MemeFi's just launched an NFT Giveaway Campaign on Discord",
            },
        },
        MysteryGameNotification: {
            Won: {
                title: 'PvP Game Won!',
                text: 'A player won PvP game',
            },
            Lost: {
                title: 'PvP Game Lost!',
                text: 'A player lost PvP game',
            },
        },
        exploreGames: 'Explore Games',
        minimalBet: {
            title: 'Minimum Stake = {{amount}}',
            text: 'The smallest sum you can stake on one game',
        },
        WelcomeHeader: {
            title: 'Welcome Back!',
            text: 'Play & Earn Crypto Daily',
        },
        ModalGameComingSoon: {
            title: 'Game Coming Soon',
            text: 'Our team are working hard on the new PvP game and it will be released soon. Take a look at other fun games in our library!',
        },
        GamesBlock: {
            header: 'Competitive Games',
            button: 'Play Game',
            buttonContinue: 'Continue Playing',
            buttonComingSoon: 'Coming Soon...',
            orders: '{{orders}} Rooms',
            games: {
                [MYSTERY_GAMES.mysteryLikes]: {
                    title: 'The Mystery of Likes',
                    text: 'Guess the correct number of likes for an NFT',
                },

                [MYSTERY_GAMES.nftRoyal]: {
                    title: 'The Grand Race',
                    text: 'Race to victory on a fruit-strewn path of dangerous obstacles',
                },
                [MYSTERY_GAMES.nftEvolution]: {
                    title: 'NFT Evolution',
                    text: 'Choose two NFTs for breeding and get an NFT of a random Rank',
                },
                [MYSTERY_GAMES.luckyStrike]: {
                    title: 'Lucky Strike',
                    text: 'Grab tickets to luck out and claim it all in a thrilling game of fortune',
                },
                [MYSTERY_GAMES.quantumSpin]: {
                    title: 'Quantum Disk',
                    text: 'Spin your quantum wheel to charm the dime in swing',
                },
                [MYSTERY_GAMES.tokenteller]: {
                    title: 'Tokenteller',
                    text: 'Take your crystal ball and guess the future IGU prices',
                },
                socialProof:
                    '{{amount}} won in the {{game}} PvP game right now',
            },
        },
        GamesFeatures: {
            orders: 'Vacant Game Rooms',
            prizePool: 'Total Prize Pool',
            turnover: 'Turnover in {{coin}}',
            totalSlots: 'Total rounds',
        },
        VacantRoomsEmpty: {
            title: 'No Vacant Games',
            text: 'There are no active game requests for now. Do you want to create one? Any player will be able to challenge you',
        },
        EmptyGameHistory: {
            title: 'No Games Yet',
            text: "You haven't played or created any games. Create one now and be challenged by a random player!",
        },
        GamesNavigationBar: {
            ORDERS: 'Vacant Rooms',
            HISTORY: 'History',
            ROUNDS: 'Rounds',
            WHEEL: 'Disk',
        },
        ActiveOrdersFilters: {
            title: 'Vacant Rooms',
            modalTitle: 'Sort by',
            Newest: 'Newest',
            Oldest: 'Oldest',
            Highest: 'Highest Prize',
            Lowest: 'Lowest Prize',
        },
        ConfirmPlayModal: {
            title: 'Stake ',
            title2: 'Spin for ',
            text: 'By joining the game, you confirm the stake to be withdrawn to The Pending Game Wallet',
        },
        GamesCreateWelcome: {
            screenTitle: 'Create Order for the "{{game}}"',
            title: 'Challenge Others',
            text: 'Create a game with the preferred stake and finish it, so that other players could take on your challenge',
        },
        MaxMinimalBetFeature: {
            title: 'Minimum Stake = {{min}}, Maximum Stake = {{max}}',
            text: 'The smallest and the biggest sums you can stake in one game',
        },
        GameTermination: {
            title: 'Automatic Termination = {{days}} Days',
            text: 'The time after which the game expires if no other player challenges you',
            alert: 'If nobody challenged you within seven days, the game will be automatically deleted and the deposit would be returned to your balance',
        },
        EnterCoinsAmountScreen: {
            screenTitle: 'Enter Coins Amount',
            error: {
                max: 'Not enough tokens on the balance, top up it to continue',
                maxStake:
                    'Maximum stake {{amount}}, please decrease stake amount',
                minStake:
                    'Minimal stake {{amount}}, please increase stake amount',
                digits: 'Must be only digits',
            },
            confirmTransaction: {
                valueType: 'The "{{game}}" game order creation',
            },
        },
        PreviousGameModal: {
            title: 'Finish Previous Game?',
            text: "Complete the game you've joined in order for other users to be able to challenge you. The game will automatically expire in {{days}} days",
            button1: 'Finish previous game',
        },
        GameHistoryScreen: {
            title: 'History',
            text: "Here's the history of the games you've completed. You can view them by month",
            status: {
                [MYSTERY_LIKES_STATUS.WaitingForMyself]:
                    'Waiting for Your Turn',
                [MYSTERY_LIKES_STATUS.WaitingForOpponent]:
                    'Waiting for Opponent',
                [MYSTERY_LIKES_STATUS.Refund]: 'Game Expired',
            },
        },
        MysteryGamesBanner: {
            oneGame: {
                title: 'Continue Playing!',
                text: 'Finish playing “{{game}}”',
            },
            more: {
                title: 'Continue Playing!',
                text: 'There are 2 unfinished game',
            },
        },
        MysteryGamesContinueModal: {
            title: 'Continue Playing',
            text: 'There are 2 unfinished game that you need to complete in order to learn the game results',
        },
        MysteryLikes: {
            MysteryLikesHowItWorks: {
                stepOne: {
                    title: 'Guess The Number Of Likes',
                    text: 'Each game round has {{gamesAmount}} games against the other player. Guess right more times than your opponent to win',
                },
                stepTwo: {
                    title: 'Join and Create Game',
                    text: 'Users can join and create games. When creating a game, you have to choose the stake in VIGU tokens. By joining a game, you agree to stake the amount of tokens specified by the game creator',
                },
                stepThree: {
                    title: 'Cheating is Impossible',
                    text: "The number of likes for an NFT is inaccessible to users, so no cheating is possible. If you seem to be having a stroke of bad luck, it's what it is. Play a bit more and the tide will change for sure",
                },
            },
            MysteryLikesCreateGameSuccess: {
                title: 'A Game Was Created!',
                text: "You've successfully created a game! Please, finish the round, so that we could compare your answers to those of your future opponent",
                button: 'Start Playing',
            },
            MysteryLikesSelectRange: {
                title: 'Select The Range',
                text: 'Choose the range that contains the correct number of likes',
                button: 'Continue to Next Image',
                toastError: 'Already Voted',
                buttonFinish: 'Finish Game',
                tooltip1:
                    "This is your opponent's NFT. You need to guess how many likes it has. The likes are invisible both to you and your opponent",
                tooltip2:
                    'To choose the number of likes, click on one of the cards and move to the next picture with the button below',
            },
            MysteryLikesSelectRangeFinish: {
                [MYSTERY_LIKES_STATUS.WaitingForOpponent]: {
                    title: 'Waiting for Opponent',
                    text: 'You have completed the game and now it remains to wait for your opponent to join. If nobody challenged you within seven days, the game will be automatically deleted and the deposit would be returned',
                },
                [MYSTERY_LIKES_STATUS.Won]: {
                    title: "You've Won!",
                    textOne:
                        "Congrats! You've managed to outmatch your opponent. ",
                    textTwo: ' will be transferred to your wallet immediately',
                },
                [MYSTERY_LIKES_STATUS.Draw]: {
                    title: "Wow, it's a Draw!",
                    text: 'As unlikely as it may seem, you both won an equal amount of points. Your stake has been returned to your wallet. Care for another round?',
                },
                [MYSTERY_LIKES_STATUS.Lost]: {
                    title: "You've Lost :(",
                    text: 'Sadly, your opponent managed to outmatch you in this game round. Your stake has been transferred to their wallet. Well, you can always win next time!',
                },
                statistics: 'Your Statistics',
                alert: 'If nobody challenged you within seven days, the game will be automatically deleted and the deposit would be returned to your balance',
                likes: 'Likes',
            },
            MysteryLikesConfirmPlay: {
                warning:
                    'The number of likes for an NFT is inaccessible to users, so no cheating is possible',
            },
            MysteryLikesLoadingScreen: {
                prepare: {
                    title: 'Preparing NFTs',
                    text: "We're tossing around the NFTs, so that you'd get a random layout. The likes are invisible to the players! Please, wait a bit and don't leave the game or the app",
                },
                complete: {
                    title: 'Analysing Results',
                    text: "Processing your and your opponent's likes to announce the results. Please, wait a bit and don't leave the game or the app",
                },
            },
        },

        NftEvolution: {
            breedCost: {
                title: 'Breeding Cost{{amount}}',
                text: 'The cost of the next Rank NFT after breeding with a 40% discount',
                alert: 'Getting the next Rank is not guaranteed',
            },
            WelcomeNftEvolution: {
                BestRankToBreed: {
                    title: 'Best Ranks To Breed',
                    text: "Here's a list of the top Rank combinations for breeding",
                },
                stepOne: {
                    title: 'Breed NFTs',
                    text: 'Choose two NFTs for breeding and get an NFT of a random Rank with a certain probability. The NFTs do not disappear!',
                },
                stepTwo: {
                    title: 'DNA Cloning',
                    text: 'Our high-tech labs can now take DNA of dead animals and clone them! So, take a couple of dead NFTs and breed them. Health will not be restored',
                },
                stepThree: {
                    title: 'Rank Chances',
                    text: "Learn what are the chances to get each Rank when breeding.The NFTs don't have to be of the same Rank. However, you have an opportunity to win an NFT of any Rank, including higher Ranks",
                },
                stepFour: {
                    title: 'Breeding Cost',
                    text: 'The cost of the next Rank NFT after breeding with a 40% discount. You will receive a unique NFT generated by AI. Generation from a photo is not available',
                },
            },
            NftEvolutionSelectPets: {
                text: "Here's a list of the top Rank combinations for breeding",
                button: 'Calculate Chances',
                emptyCard: {
                    first: 'Select first NFT for breeding',
                    second: 'Select second NFT for breeding',
                },
                loading: 'Analyzing...',
            },
            NftEvolutionSelectPet: {
                title: 'Select NFT',
            },
            NftEvolutionCheckout: {
                title: 'Chances to Breed',
                text: 'Learn more, what you’ll get with breeding',
                button: 'Breed for {{amount}}',
                loading: {
                    title: 'Breeding',
                    text: 'Please, wait while the NFTs are being bred. Do not close the application or exit the tab. The breeding process usually takes up to five minutes',
                },
            },
            ConfirmBreedModal: {
                title: 'Breed for ',
                text: 'When joining the game, you agree to breed two NFTs to receive a new NFT of a random Rank',
            },
            NftEvolutionSuccess: {
                title: '{{rank}} Rank Created!',
                text: "Congratulations! You've successfully bred your NFTs and received an NFT of the {{rank}} Rank",
            },
            NftEvolutionMintPet: {
                title: 'Customize New NFT',
                text: "We've automatically generated four images of the NFT for you. Check them out, choose the best one and type the name of your new NFT",
            },
            noPetModal: {
                title: 'Mint A New NFT To Play',
                text: 'In order to take part in the game, at least two pets are necessary. Mint a new one and start playing',
            },
        },

        NftRoyal: {
            lockPeriodWarning:
                'To deter cheating, we use a smart anti-cheat detection system',

            NftRoyalHowItWorks: {
                stepOne: {
                    title: 'Play a Fun Arcade Game',
                    text: "This is a variation of the game from the daily tasks, where you'll face strong opponents. We offer several gaming modes to enhance your experience. You'll have only one opponent",
                },
                stepTwo: {
                    title: 'Join and Create Games',
                    text: 'Users can join and create games. When creating a game, you have to choose the stake in VIGU tokens. By joining a game, you agree to stake the amount of tokens specified by the game creator',
                },
                stepThree: {
                    title: 'Cheating is impossible',
                    text: 'To prevent cheating, we use a smart anti-cheat detection system that guarantees only skill and luck determine the winner',
                },
            },
            NftRoyalGameSettings: {
                gameMode: {
                    [NFT_ROYAL_GAME_MODE.Score]: {
                        title: 'Score',
                        text: 'Players will compete for points',
                    },
                    [NFT_ROYAL_GAME_MODE.Fruit]: {
                        title: 'Fruit',
                        text: 'Players will compete for fruits',
                    },
                },
                difficultyType: {
                    [GAME_DIFFICULTY.Easy]: {
                        title: 'Easy',
                        text: 'Low speed and low rate of cacti spawn',
                    },
                    [GAME_DIFFICULTY.Medium]: {
                        title: 'Medium',
                        text: 'Medium speed and medium rate of cacti spawn',
                    },
                    [GAME_DIFFICULTY.Hard]: {
                        title: 'Hard',
                        text: 'High speed and high rate of cacti spawn',
                    },
                },
                lives: 'The maximum number of lives in the game',
            },
            NftRoyalGameSettingsModal: {
                title: 'Game Settings',
                text: 'There is a variety of different parameters for more exciting gameplay',
                feature: {
                    mode: {
                        description:
                            "In the score mode you're competing for points and in the fruit mode you're competing for the number of fruits collected",
                    },
                    difficulty: {
                        description:
                            'The three difficulty levels (easy, medium and hard) control the speed and the spawn of cacti',
                    },
                    livesCount: {
                        description:
                            'The number of lives in the game. The game ends with the loss of the last life by one of the players',
                    },
                },
            },

            NftRoyalCreateGameSuccess: {
                title: "You've successfully created a game!",
                text: 'Please, finish the game, so that we could compare your results to those of your future opponent',
            },

            NftRoyalGameHistory: {
                [NFT_ROYAL_GAME_HISTORY_STATUS.WaitingForOpponent]: {
                    title: 'Waiting for Opponent',
                    text: 'You have completed the game and now it remains to wait for your opponent to join. If nobody challenged you within seven days, the game will be automatically deleted and the deposit would be returned',
                },
                [NFT_ROYAL_GAME_HISTORY_STATUS.Won]: {
                    title: "You've Won!",
                    textOne:
                        "Congrats! You've managed to outmatch your opponent. ",
                    textTwo:
                        ' will be transferred your Gaming Wallet immediately',
                },
                [NFT_ROYAL_GAME_HISTORY_STATUS.Draw]: {
                    title: "Wow, it's a Draw!",
                    text: 'As unlikely as it may seem, you both won an equal amount of points. Your stake has been returned to your wallet. Care for another round?',
                },
                [NFT_ROYAL_GAME_HISTORY_STATUS.Lost]: {
                    title: "You've Lost :(",
                    text: 'Sadly, your opponent managed to outmatch you in this game round. Your stake has been transferred to their wallet. Well, you can always win next time!',
                },
                GameStatistics: {
                    title: 'Playing Statistics',
                    score: 'You Score',
                    lives: 'Saved Lives',
                    fruitCollected: 'Fruit Collected',
                },
            },
            NftRoyalLoadingScreen: {
                prepare: {
                    title: 'Preparing Game',
                    text: "We're preparing game. No cheating is possible, because of game configurations! Please, wait a bit and don't leave the game or the app",
                },
                complete: {
                    title: 'Analysing Results',
                    text: "Processing your and your opponent's status to announce the results. Please, wait a bit and don't leave the game or the app",
                },
            },
            emptyScreen: {
                title: 'Oops!',
                text: 'Something Wrong. But no worries it will be fixed quickly',
            },
        },

        [MYSTERY_GAMES.luckyStrike]: {
            keys: {
                participants: 'Participants',
                winners: 'Lucky Winners',
            },

            purchaseTicket: 'Grab Tickets',
            ticketsGrabbed: 'Tickets Grabbed',
            soldOut: 'All Tickets are Sold Out',
            LuckyStrikeTimerBlock: {
                title: 'Time Until the End',
                text: 'Round will be finished in this time',
            },
            HashSecuritySystem: {
                title: 'Secure Code System',
                text: 'Each ticket has a unique code that is hidden from other players. The code decides the winner!',
            },
            LuckyStrikePricePool: {
                title: 'Prize = {{text}}',
                text: 'The total pool collected from ticket premiums. One winner take it all. The reward is paid to the Gaming Wallet',
            },
            LuckyStrikeParticipants: {
                title: 'Participants = {{text}}',
                text: 'Players to take part and the player limit. Once the limit is reached, new tickets become unavailable',
            },
            LuckyStrikeHowItWorks: {
                stepOne: {
                    title: 'Introducing Lucky Strike',
                    text: 'Play a round and win a handsome prize! Every ticket you grab in each round boosts your odds of winning',
                },
                stepTwo: {
                    title: 'Boost Your Chances',
                    text: 'Players have a chance to win the pool made from tickets premiums. The more tickets you collet, the higher the chance of receiving the reward',
                },
                stepThree: {
                    title: 'Cheating is Impossible',
                    text: "Since a ticket code is inaccessible to other users, no cheating is possible. If you seem to be having a stroke of bad luck, that's what it is. Play a bit more and the tide will change for sure",
                },
            },
            LuckyStrikeMain: {
                title: 'Lucky Rounds',
                text: 'Rounds vary in the duration, the number of players and the chance of winning',
                emptyList: {
                    title: 'No Users in Winners Leaderboard',
                    text: 'Sad to say, but no users that win any round yet. But no worries it will be fixed quickly',
                },
            },
            LuckyStrikeWinners: {
                title: 'Lucky Winners',
                text: 'Take a look at the lucky winners',
            },
            LuckyStrikePurchaseModal: {
                title: 'Grab The "{{type}}" Ticket',
                text: 'Premiums are collected form the game pool. The winner takes the whole pool and is determined by the ticket hash. The more tickets you grab, the higher the chance of receiving the reward',
                alert: 'You can grab a maximum of {{maxTickets}} tickets per one game to prevent cheating and improve the game experience',
            },
            LuckyStrikeWaitingForResult: {
                title: 'Waiting for Results!',
                text: 'Wait until the round end to learn the result of the game. You can grab more tickets to increase your winning chances',
            },
            LuckyStrikeUserTickets: {
                text: 'Each ticket has a unique code that is hidden from other players and helps us guarantee the fairness of the game',
                alert: 'You already own a maximum of {{text}} tickets allowed per game',
            },

            LuckyStrikeHistoryItem: {
                [LUCKY_STRIKE_GAMES_STATUS.Won]: '{{round}} Won',
                [LUCKY_STRIKE_GAMES_STATUS.Lost]: '{{round}} Lost',
                [LUCKY_STRIKE_GAMES_STATUS.WaitingForResults]:
                    'Waiting for Results',
            },
            LuckyStrikeHistoryBlockHeader: {
                [LUCKY_STRIKE_GAMES_STATUS.Won]: {
                    title: "You've Won!",
                    textOne:
                        'Congrats! Your ticket is the lucky one and you win the whole prize pool. ',
                    textTwo: ' will be deposited to your wallet instantly',
                },

                [LUCKY_STRIKE_GAMES_STATUS.Lost]: {
                    title: "You've Lost :(",
                    text: "Unfortunately, this time you didn't manage to win the round. Good luck to you in the next one!",
                },
            },
            LuckyStrikeGameHistoryInfo: {
                gameType: 'Game Type',
                correctTicket: 'Correct Ticket',
                yourTicket: 'Your Ticket',
                yourTickets: 'Your Tickets',
            },
            toasts: {
                soldOut: {
                    text1: 'Error!',
                    text2: 'All Tickets are Sold Out!',
                },
                gameExpired: {
                    text1: 'Error!',
                    text2: 'Round Expired!',
                },
                gamePending: {
                    text1: 'Oops!',
                    text2: 'Something goes wrong',
                },
            },
        },

        [MYSTERY_GAMES.quantumSpin]: {
            keys: {
                spin: 'Spin',
                spinning: 'Spinning',
            },
            QuantumSpinProvablyFair: {
                card: {
                    title: 'Provably Fair',
                    text: 'All the results are calculated through a cryptographic function, which allows anyone to check the fairness of the game',
                },

                title: 'Provably Fair',
                text: 'The results are chained, which makes it impossible to cheat the system. As we released the first hash on Twitter, you can prove the fairness of the results yourself',
                algorithm: {
                    title: 'Algorithm',
                    text: 'All game results for the next {{rounds}} rounds have been predetermined. Before each round, we reveal a hash of the result, making it impossible for us to change the result after the fact.\n\nWe have actually chained all results, making it impossible for us to even change future results. And before generating the chain of results, we revealed the first hash, published this to Twitter, and mixed in a future Bitcoin blockhash we could not have known. This makes all results provably fair.\n\nBefore the first round of the game began, we created a chain of hashes. Each hash is a 256 bit value. The chain begins with a randomly chosen 256 bit value and every subsequent value is calculated using the BLAKE2b hash of the previous value.\n\nUsing a cryptographic hash function ensures that one can easily calculate any value in the chain using the previous value, but one can not easily calculate values in the reverse direction. The last value in the precalculated hash chain is what we publically published and used for the first round of the game. The second round of the game used the second to last hash value, the third round used the third to last hash value, etc. By traversing the chain in this manner, one can verify that the outcome of all past game rounds were consistent with the precalculated chain, but the outcome of future rounds remains unpredictable to users.\n\nIn order to eliminate the possibility that we intentionally generated the chain using an initial value that biased the games results in our favor, each value in the chain is appended to the global seed and the resulting value is hashed again using BLAKE2b to create the final value that will determine the outcome of each game round. This global seed is set to the hash of the Bitcoin block that we announced prior to its mining. The inclusion of the global seed ensures that the outcome of the games was unknowable to us when the hash value of the first game was published',
                },
                verification: {
                    title: 'Verification Example',
                    text: 'Anyone can verify that the outcomes of all prior game rounds were generated using the provably fair algorithm with the following python code',
                },
            },
            QuantumSpinHowItWorks: {
                stepOne: {
                    title: 'Spin the Quantum Wheel',
                    text: "The wheel is in superposition — we don't know what it will bring. Spin the wheel to break the uncertainty and charm the biggest prize",
                },
                stepTwo: {
                    title: 'Each quantum spin requires energy, that is, tokens',
                    text: 'Your prize might be of lower or higher value than your stake. Naturally, you can we a huge reward!',
                },
                stepThree: {
                    title: 'Cheating is Impossible',
                    text: 'The results of a spin are determined at the very moment of the game through a random process and are unknown to the team. We value fairness and transparency',
                },
            },
            QuantumSuccess: {
                coin: "Congrats! You've won {{coin}} in rewards, which will be transferred to your wallet immediately. Try your luck again and share your results in socials",
                rank: "Congrats! You've won an {{rank}} Rank NFT, which will be transferred to your wallet immediately. Try your luck again and share your results in socials",
            },
            QuantumSpinGameHistory: {
                again: 'Spin Again',
            },
            QuantumSpinDetailsModal: {
                button: 'Back to Disk',
            },
            QuantumSpinHistory: {
                title: 'No History Yet',
                text: "You haven't played any games yet",
            },
        },
        [MYSTERY_GAMES.tokenteller]: {
            predictPrice: 'Predict Price',
            addMoreTokens: 'Add More Tokens',
            priceDirection: 'Price Direction',

            [TOKEN_TELLER_GAME_PREDICTION_DIRECTION.Up]: 'UP',
            [TOKEN_TELLER_GAME_PREDICTION_DIRECTION.Down]: 'DOWN',
            TokenTellerConfirmationModal: {
                [TOKEN_TELLER_GAME_PREDICTION_DIRECTION.Up]: 'UP Multiplier',
                [TOKEN_TELLER_GAME_PREDICTION_DIRECTION.Down]:
                    'DOWN Multiplier',
            },
            TokenTellerHowItWorks: {
                stepOne: {
                    title: 'Introducing Tokenteller',
                    text: 'Test your forecasting skills in this fast-paced game. Predict IGU price movement in a {{min}}-minute round. Use any tools necessary: crystal balls, animal oracles and even black magic',
                },
                stepTwo: {
                    title: 'Reward Size',
                    text: 'In one game, you either choose up or down. Your reward size is dependent on the multiplier, which is the ratio of the bull pool to the bear pool. The multiplier is shown in the Prediction Tab and may change until the round goes live',
                },
                stepThree: {
                    title: 'Cheating is Impossible',
                    text: 'We employ a reliable oracle that takes the current {{coin}} price from exchanges. You can always check the price at an exchange',
                },
            },
            TokenTellerMainScreen: {
                title: 'Rounds',
                text: 'You can only join an upcoming round',
            },
            TokenTellerPurchaseModal: {
                title: 'Predict Price Movement',
                text: 'Join a round to predict where {{coin}} is heading. Select the stake and direction below',
                alert: "You've already made the prediction that the token will go {{direction}}. Now you can only increase your position in this direction",
            },

            TokenTellerPricePool: {
                title: 'Pool = {{text}}',
                text: 'The pool is collected from users stakes. The reward depends on the ratio between the total bull and bear stakes',
            },
            TokenTellerUpPayout: {
                title: 'UP Multiplier = {{coefficient}}X',
                text: 'Winner multiplier if the price goes UP',
            },
            TokenTellerDownPayout: {
                title: 'DOWN Multiplier = {{coefficient}}X',
                text: 'Winner multiplier if the price goes DOWN',
            },
            TokenTellerParticipants: {
                title: 'Participants = {{text}}',
                text: 'The number of players taking part in the round',
            },

            TokenTellerTimerBlock: {
                [TOKEN_TELLER_GAME_STATUS.Pending]: {
                    title: 'Round Starts In',
                },
                [TOKEN_TELLER_GAME_STATUS.Closed]: {
                    title: 'Round Ends In',
                },
            },
            TokenTellerHistoryItem: {
                [TOKEN_TELLER_HISTORY_GAMES_STATUS.Won]: 'Won',
                [TOKEN_TELLER_HISTORY_GAMES_STATUS.Lost]: 'Lost',
                [TOKEN_TELLER_HISTORY_GAMES_STATUS.WaitingForResults]:
                    'Waiting for Result',
                [TOKEN_TELLER_HISTORY_GAMES_STATUS.Refund]: 'Draw',
            },

            TokenTellerHistoryBlockHeader: {
                [TOKEN_TELLER_HISTORY_GAMES_STATUS.Won]: {
                    title: "You've Won!",
                    textOne:
                        "Congrats! You've predicted the price movement correctly. ",
                    textTwo: ' will be deposited to your wallet instantly',
                },

                [TOKEN_TELLER_HISTORY_GAMES_STATUS.Lost]: {
                    title: "You've Lost :(",
                    text: "Unfortunately, you didn't manage to win this time. A good reason to prove yourself in the next round!",
                },
                [TOKEN_TELLER_HISTORY_GAMES_STATUS.Refund]: {
                    title: "Wow, it's a Draw!",
                    text: "As unlikely as it may seem, it's a draw.Your stake has been returned to your wallet. Care for another round?",
                },
            },

            TokenTellerGameHistoryInfoItem: {
                iguBefore: 'Initial Price',
                iguAfter: 'Closing Price',
                prediction: 'Position',
                totalPool: 'Total Pool',
                participants: 'Participants',
                upPayout: 'UP Multiplier',
                downPayout: 'DOWN Multiplier',
            },
        },
        DailyTasks: {
            header: 'Daily Tasks',
            collected: '{{collectedEnergy}} / {{maxEnergy}} Collected',
            SocialTask: {
                title: 'Social Task',
                text: 'Make posts on social media',
            },
            VerificationTask: {
                title: 'Find Task',
                text: 'Check the posts of other users',
            },
            MoveTask: {
                title: 'Walking Task',
                text: 'Enjoy walking outside with your pet',
            },
            PlayTask: {
                title: 'Playing Task',
                text: 'Play a fun arcade game',
            },
        },
        ReferralCard: {
            title: '{{percent}}% off NFT prices!',
            text: 'Invite new users to the IguVerse app and get a mint discount on NFTs',
            button: 'Invite Users',
        },
        TimerTopUp: {
            title: 'for Every Top-Up',
            text1: 'Deposit tokens now and receive {{percent}}% more {{coin}} to your wallet! Available only before:',
            text2: 'Start playing our new competitive games and get bonus tokens on your balance!',
        },
    },
    pvpBonus: {
        cardButton: 'PVP bonus',
        title: 'PvP Bonuses',
        text: 'NFT owners are entitled to incentives for playing PvP. Start now and get your bonuses right away!',
        pvpBonusModal: {
            title: 'How To Earn More In PvP',
            text: "The return of IguVerse from PvP will be shared with you proportionally to your turnover! Get an NFT and you'll automatically receive bonuses for playing",
            features: {
                games: {
                    title: 'Game Counter = {{games}}',
                    text: "The games you've played today. Don't stop yourself now!",
                },
                bonus: {
                    title: 'PvP Bonuses = {{amount}}',
                    text: "These are the extra tokens you've received for playing PvP games. Clash it out with others and earn even more!",
                },
            },
        },
        pvpBonusModalFreePet: {
            title: 'Upgrade Rank To Receive More Tokens In PvP',
            text: 'Your pet of the Free Rank needs to be upgraded to a higher Rank for you to be eligible to the bonuses from PvP',
        },
    },
    MarketPlace: {
        navigationBar: {
            ORDERS: 'Active Orders',
            MY_PROFILE: 'My Profile',
        },
        MarketPlaceActiveOrdersScreen: {
            title: 'Marketplace',
            text: 'Here is a place to trade NFTs',
            empty: {
                title: 'No NFTs For Sale',
                text: 'No sell orders have been created yet. Be the first to create one!',
            },
            notFound: {
                title: 'No Results Found',
                text: "We haven't found any such orders. Enter another key word to search",
            },
        },
        MarketPlaceSearch: {
            modalTitle: 'Filters',
            placeHolder: 'Search for nft, collection, user...',
            typeSection: {
                [MARKETPLACE_PET_TYPE.AISystem]: 'AI System',
                [MARKETPLACE_PET_TYPE.FromPhoto]: 'From Photo',
                [MARKETPLACE_PET_TYPE.Alive]: 'Alive',
                [MARKETPLACE_PET_TYPE.Dead]: 'Dead',
            },

            sortBySection: {
                [MARKETPLACE_ORDERS_SORT_OPTION.FromHighestPrice]:
                    'From Highest Price',
                [MARKETPLACE_ORDERS_SORT_OPTION.FromLowestPrice]:
                    'From Lowest Price',
                [MARKETPLACE_ORDERS_SORT_OPTION.RecentlyListed]:
                    'Recently Listed',
                [MARKETPLACE_ORDERS_SORT_OPTION.EndingSoon]: 'Ending Soon',
            },
        },
        MarketPlaceWelcomeCreateOrderScreen: {
            screenTitle: 'Create New Order',
            title: 'Introduce your NFT to the Big World',
            text: 'Here you can list your NFT and sell it',
            features: {
                rank: {
                    title: 'Minimum Rank – {{rank}}',
                    text: 'Your NFT should have a Rank higher than the {{lowRank}} Rank. Only NFTs purchased in the app can be traded on the marketplace',
                },
                termination: {
                    title: 'Automatic Termination = {{days}} Days',
                    text: 'The time in which the order will expire if not purchased before',
                    alert: 'If nobody purchased NFT within {{days}} days, the order will be automatically delisted from Marketplace. During listing you can continue playing and earning',
                },
                commission: {
                    title: 'Commission Fee = {{commission}}%',
                    text: 'Each successfully sold NFT is subject to a commission fee of {{commission}}%',
                },
            },
        },
        MarketPlaceModalNoPet: {
            title: 'Mint An NFT To Sell',
            text: 'In order to sell an NFT, you need to have at least one pet. Mint one now!',
        },
        MarketPlaceSelectNftScreen: {
            screenTitle: 'Select NFT',
        },
        MarketPlaceSelectNftPriceScreen: {
            screenTitle: 'Enter NFT Price',
        },
        MarketPlaceCreateOrderSuccessScreen: {
            title: 'NFT Listed on the Marketplace',
            text: "You've successfully listed your NFT. Now other users will be able to purchase it",
        },
        navigationBarOverview: {
            OVERVIEW: 'Overview',
            OWNER_HISTORY: 'Owners History',
        },
        MarketPlaceOverview: {
            text: 'This is an NFT of another user. You can see its characteristics below:',
            textOwner:
                'This is your NFT. You can see its characteristics below:',
            OverviewOwnerSection: {
                profileLabel: 'Owner’s Profile',
            },
            proceedToCheckoutModal: {
                title: 'Proceed to Checkout?',
                text: "After buying an NFT, it will be in the cooldown mode for 24 hours. Make sure you've checked all of its properties before buying",
            },
        },
        DelistNftModal: {
            title: 'Delist the NFT?',
            text: 'If delisted, the NFT will leave the marketplace and be no longer available for purchase',
        },
        MarketplaceProfile: {
            joined: 'Joined {{date}}',
            myNFTs: 'My NFTs',
            exploreNFTs: 'Explore NFTs',
            emptyUser: {
                title: 'No Orders',
                text: 'No marketplace orders have been created yet',
            },
            empty: {
                title: 'No Orders',
                text: "This user hasn't created any order yet",
            },
        },
        MarketPlaceDelistSuccessScreen: {
            title: 'NFT "{{name}}" has been successfully delisted from the marketplace',
        },
        MarketPlaceBuySuccessScreen: {
            title: 'New NFT Joined the Family!',
            text: "You've successfully purchased a new NFT! Now your collection has got a new member and is much stronger now",
        },
        MarketPlaceOverviewOwnerHistoryScreen: {
            empty: {
                title: 'No NFT History',
                text: 'The NFT has not been traded yet. You can be the first to buy it!',
            },
        },
        MarketPlaceNoTradeAvalibleScreen: {
            title: 'Update Out!',
            description:
                'The internal marketplace is already here! Trade your NFTs with no constraints.',
            button: 'Explore in Web App',
        },
    },
    SpaceId: {
        card: {
            title: 'Get SPACE ID',
            text: 'Show off your custom nickname everywhere in the app and beyond',
        },
        how: {
            screenTitle: 'How to Use SPACE ID',
            title: 'Register Digital Identity',
            text: 'Tired of complex unreadable addresses? Change yours with a human-readable identity (domain name) with SPACE ID!',
            guideBlock: {
                first: 'Import your IguVerse wallet to any non-custodial DeFi Wallet (e.g. Trustwallet, Metamask) through your seed-phrase',
                second: 'Add the BEP-20 network and connect your wallet to the website',
                third: 'Search for the desired domain name on the website',
                fourth: 'Purchase the desired domain name and update the IguVerse app. You will be able to see your custom nickname in the wallet, in the leaderboard and in any other place where it is used',
                alert: 'To buy a domain, it is necessary to have some BNB in your wallet',
            },
            button1: 'Explore SPACE ID domains',
        },
        modal: {
            title: 'Unique SPACE ID!',
            text: 'You have successfully purchased and registered a custom domain! From now on, your unique name will be shown everywhere in the app. Your real address will stay the same and you can copy it by clicking "Copy" in your wallet',
            link: 'Learn More in SPACE ID',
        },
    },
    Achievements: {
        title: 'Achievements',
        text: 'Thrilling tasks that track your unique progress and let you show off your skills and gaming experience',
        unlocked: '{{achievement}} Unlocked!',
        unlockedText:
            "Congrats! You've managed to unlock an achievement. Share it with your friends on social media!",
        button: 'Share Achievement',
        modal: {
            title: 'Stand out with achievements',
            text: "Complete the tasks to get achievements. It's much more entertaining to play with a bit of a challenge and a chest full of medals",
        },
    },
};

export default en;
