import * as React from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';

import ActivityIndicator from '@components/ActivityIndicator';
import Button from '@components/Button';
import i18n from '@i18n/i18n';

import useThemedStyles from '../../hooks/useThemedStyles';
import AlertCard from '../AlertCard';
import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    title?: string;
    text: string;
    icon?: React.ReactElement<SvgProps>;
    warningText?: string;
    containerStyle?: StyleProp<ViewStyle>;
    children?: React.ReactNode;
    loading?: boolean;
    type?: 'default' | 'opacity';
    additionalButtonTitle?: string;
    onAdditionalButton?: () => void;
    titleComponent?: React.ReactNode;
}

const CardFeatureInfo = ({
    title,
    text,
    icon,
    warningText,
    containerStyle,
    children,
    loading,
    type = 'default',
    additionalButtonTitle,
    onAdditionalButton,
    titleComponent,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const typeStyles = React.useMemo(
        () =>
            type === 'default'
                ? null
                : { block: styles.opacityBlock, text: styles.opacityTextColor },
        [type, styles]
    );

    return (
        <TouchableOpacity
            style={[styles.infoItem, typeStyles?.block, containerStyle]}
            activeOpacity={1}>
            {loading && (
                <View style={styles.loading}>
                    <ActivityIndicator loading={loading} size="large" />
                </View>
            )}
            <View style={styles.top}>
                <View style={styles.left}>
                    <Text style={[styles.infoTitle, typeStyles?.text]}>
                        {titleComponent || children || title}
                    </Text>
                    <Text style={[styles.infoText, typeStyles?.text]}>
                        {text}
                    </Text>
                </View>
                {!!icon && icon}
            </View>
            {!!onAdditionalButton && (
                <Button
                    isOneColor={type === 'opacity'}
                    onPress={onAdditionalButton}
                    type="outline"
                    size="md"
                    debouncedPress
                    title={
                        additionalButtonTitle ||
                        i18n.t('general.BUTTONS.viewAll')
                    }
                    containerStyle={styles.button}
                />
            )}
            {!!warningText && (
                <AlertCard
                    containerStyle={styles.warningBlock}
                    text={warningText}
                />
            )}
        </TouchableOpacity>
    );
};

export default CardFeatureInfo;
