import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { TOKENTELLER_COLOR } from '@contexts/MysteryGamesContext';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
            backgroundColor: TOKENTELLER_COLOR,
        },
        title: {
            color: Colors[theme].text.onLight,
        },
    });

export default styles;
