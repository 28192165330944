export enum USER_ACHIEVEMENTS {
    FruitSlasher = 'FruitSlasher',
    Ghostrider = 'Ghostrider',
    MoneyBag = 'MoneyBag',
    LuckyStroke = 'LuckyStroke',
    LoyalFighter = 'LoyalFighter',
    UndercoverMillionaire = 'UndercoverMillionaire',
    LeaderboardMaster = 'LeaderboardMaster',
}

export type UserAchievementOutput = {
    type: USER_ACHIEVEMENTS;
    achievedAt: string | null;
    progress: number | null;
    targetProgress: number | null;
    title: string;
    description: string;
    templateUrl: string;
    backgroundColor: string;
    shadowColor: string;
};

export type UserAchievementOutputResponse = {
    userAchievements: UserAchievementOutput[];
};
export type RecentlyUserAchievementsResponse = {
    recentlyUserAchievements: UserAchievementOutput[];
};
