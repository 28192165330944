import React, {
    Dispatch,
    FC,
    PropsWithChildren,
    SetStateAction,
    createContext,
    useRef,
    useState,
} from 'react';

import { ApolloProvider } from '@apollo/client';
import { ApolloClient } from '@apollo/client/core';

import createApolloClient from '../apollo/client';
import { useSession } from './SessionContext';

type IguApolloType = {
    isGlobalApolloError: string | null;
    isUpdateRequired: boolean;
    setIsUpdateRequired: Dispatch<SetStateAction<boolean>>;
    isGameMaintenance: boolean;
    setIsGameMaintenance: Dispatch<SetStateAction<boolean>>;
    isGameMaintenanceModal: boolean;
    setIsGameMaintenanceModal: Dispatch<SetStateAction<boolean>>;
    isFullMaintenance: boolean;
    setIsFullMaintenance: Dispatch<SetStateAction<boolean>>;
};

export const IguApolloContext = createContext<IguApolloType>({
    isGlobalApolloError: null,
    isUpdateRequired: false,
    setIsUpdateRequired: () => undefined,
    isGameMaintenance: false,
    setIsGameMaintenance: () => undefined,
    isGameMaintenanceModal: true,
    setIsGameMaintenanceModal: () => undefined,
    isFullMaintenance: false,
    setIsFullMaintenance: () => undefined,
});

export const useApollo = () => React.useContext(IguApolloContext);

const IguApolloProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const { setSessionToken } = useSession();
    const [isUpdateRequired, setIsUpdateRequired] = useState<boolean>(false);
    const [isGameMaintenance, setIsGameMaintenance] = useState<boolean>(false);
    const [isGameMaintenanceModal, setIsGameMaintenanceModal] =
        useState<boolean>(true);
    const [isFullMaintenance, setIsFullMaintenance] = useState<boolean>(false);
    const [isGlobalApolloError, setIsGlobalApolloError] = useState<
        string | null
    >(null);
    const clientRef = useRef<ApolloClient<any> | null>();

    Console.log('[APOLLO CONTEXT] Render');

    if (!clientRef.current) {
        clientRef.current = createApolloClient(
            setSessionToken,
            setIsGlobalApolloError,
            setIsUpdateRequired,
            setIsGameMaintenance,
            setIsFullMaintenance
        );
    }

    return (
        <IguApolloContext.Provider
            value={{
                isUpdateRequired,
                setIsUpdateRequired,
                isGlobalApolloError,
                isGameMaintenance,
                setIsGameMaintenance,
                isGameMaintenanceModal,
                setIsGameMaintenanceModal,
                isFullMaintenance,
                setIsFullMaintenance,
            }}>
            <ApolloProvider client={clientRef.current}>
                {children}
            </ApolloProvider>
        </IguApolloContext.Provider>
    );
};

export default IguApolloProvider;
