import * as React from 'react';

import ModalConfirmTransaction from '@components/ModalConfirmTransaction';
import TransactionSummary from '@components/TransactionSummary';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { priceString } from '@helpers/payments';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
    onConfirm: () => void;
    amount: string;
    game: MYSTERY_GAMES;
}

const ConfirmationModal = ({
    isVisible,
    onClose,
    onConfirm,
    amount,
    game,
}: IProps) => {
    const transactionDetailsValues = [
        {
            key: i18n.t('general.KEYS.type'),
            value: i18n.t(`HomeScreen.GamesBlock.games.${game}.title`),
        },
        {
            key: i18n.t('checkout.fields.from'),
            value: i18n.t('checkout.fields.gamingWallet'),
        },

        {
            key: i18n.t('checkout.fields.totalSummary'),
            value: priceString(amount, Coin.vigu),
        },
    ];

    return (
        <ModalConfirmTransaction
            title={i18n.t('checkout.fields.modalTitle')}
            isVisible={isVisible}
            close={onClose}
            onConfirm={onConfirm}
            onCancel={onClose}
            TransactionDetailsComponent={
                <TransactionSummary values={transactionDetailsValues} />
            }
            modalHeight={(transactionDetailsValues.length + 1) * 120}
        />
    );
};
export default ConfirmationModal;
