import { SocialTasksResponse } from '@Data/Models';
import { GET_SOCIAL_TASK } from '@Data/Requests';
import { useLazyQuery } from '@apollo/client';

const useGetSocialTask = () => {
    const [
        getSocialTask,
        {
            loading: socialTaskLoading,
            error: socialTaskError,
            refetch: refetchSocialTask,
        },
    ] = useLazyQuery<SocialTasksResponse>(GET_SOCIAL_TASK, {
        fetchPolicy: 'network-only',
    });

    return {
        getSocialTask,
        socialTaskLoading,
        socialTaskError,
        refetchSocialTask,
    };
};

export default useGetSocialTask;
