import * as React from 'react';

import LOSE from '@assets/icons/mysteryGames/LuckyStrike/history/lose.svg';
import WAIT from '@assets/icons/mysteryGames/LuckyStrike/history/wait.svg';
import WIN from '@assets/icons/mysteryGames/LuckyStrike/history/win.svg';
import { LUCKY_STRIKE_GAMES_STATUS } from '@models/mysteryGames';

import { IconType } from '../types';

interface IProps extends IconType {
    slug: LUCKY_STRIKE_GAMES_STATUS;
}

const LuckyStrikeHistoryIcon = ({ slug, size = 40 }: IProps) => {
    switch (slug) {
        case LUCKY_STRIKE_GAMES_STATUS.Won:
            return <WIN width={size} height={size} />;
        case LUCKY_STRIKE_GAMES_STATUS.Lost:
            return <LOSE width={size} height={size} />;
        case LUCKY_STRIKE_GAMES_STATUS.WaitingForResults:
            return <WAIT width={size} height={size} />;

        default:
            return <></>;
    }
};

export default LuckyStrikeHistoryIcon;
