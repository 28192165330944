import * as React from 'react';
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { Animated } from 'react-native';

import { useConfig } from '@contexts/ConfigContext';
import { useUser } from '@contexts/UserContext';
import { isWeb, useNativeDriver } from '@helpers/app';

import useThemedStyles from '../../../hooks/useThemedStyles';
import LinearLoader from '../../LinearLoader';
import ImageLoader from '../ImageLoader';
import stylesMain from './styles';

const loadedImages = {
    splash: require('../../../assets/icons/app/splash.png'),
    sheets: require('../../../assets/icons/app/splash/sheets.png'),
    clouds: require('../../../assets/icons/app/splash/clouds.png'),
    flakes: require('../../../assets/icons/app/splash/flakes.png'),
};

interface ISplashState {
    isSplashScreenHidden: boolean;
    isSplashAnimationComplete: boolean;
    setIsSplashAnimationComplete: Dispatch<SetStateAction<boolean>>;
    setIsSplashReady: Dispatch<SetStateAction<boolean>>;
}

const config = {
    resizeMode: 'cover',
    animationDuration: 1500,
    fadeDuration: 500,
} as const;

const SplashState = ({
    isSplashScreenHidden,
    isSplashAnimationComplete,
    setIsSplashAnimationComplete,
    setIsSplashReady,
}: ISplashState) => {
    const styles = useThemedStyles(stylesMain);
    const animation = new Animated.Value(1);
    const animatedValueRef = useRef(animation);
    const [isImagesLoaded, setIsImagesLoaded] = React.useState<boolean>(false);
    // Linear loading progress bar for web only
    const [linearLoaderEnded, setLinearLoaderEnded] = React.useState<boolean>(
        !isWeb
    );

    // Other Global States
    const { isLoaded: userLoaded, user } = useUser();
    const { isLoaded: configLoaded } = useConfig();

    // resources are loaded
    useEffect(() => {
        // do not check wallet loaded for unlogged user
        const appReady = configLoaded && userLoaded;

        if (
            isSplashScreenHidden &&
            isImagesLoaded &&
            !isSplashAnimationComplete &&
            appReady
        ) {
            Console.log(
                '[LOADING] Start animation of SplashState fading',
                `isSplashScreenHidden=${isSplashScreenHidden}`,
                `isImagesLoaded=${isImagesLoaded}`,
                `isSplashAnimationComplete=${isSplashAnimationComplete}`,
                `configLoaded=${configLoaded}`,
                `userLoaded=${userLoaded}`,
                `appReady=${appReady}`
            );

            Animated.timing(animatedValueRef.current, {
                toValue: 0,
                duration: config.fadeDuration,
                useNativeDriver,
            }).start(() => {
                Console.log('[LOADING] setIsSplashAnimationComplete(true)');
                setIsSplashAnimationComplete(true);
            });
        }
    }, [
        isSplashScreenHidden,
        isImagesLoaded,
        isSplashAnimationComplete,
        userLoaded,
        configLoaded,
        user,
    ]);

    // background image is loaded
    const onMainBackgroundLoaded = React.useCallback(() => {
        Console.log('[LOADING] setIsSplashReady(true)');
        setIsSplashReady(true);
    }, []);

    const onImagesLoaded = React.useCallback(() => {
        setIsImagesLoaded(true);
    }, []);

    const onLoadingLineStop = React.useCallback(() => {
        setLinearLoaderEnded(true);
    }, []);

    return (
        <Animated.View
            pointerEvents="none"
            style={[
                styles.bgImage,
                {
                    opacity: animatedValueRef.current,
                },
            ]}>
            <Animated.Image
                source={loadedImages.splash}
                resizeMode={config.resizeMode}
                style={styles.bgImage}
                onLoadEnd={onMainBackgroundLoaded}
            />
            {isWeb && (
                <LinearLoader
                    fasterLoad={isSplashScreenHidden}
                    onLoadingLineStop={onLoadingLineStop}
                />
            )}
            {isSplashScreenHidden && linearLoaderEnded && (
                <>
                    <ImageLoader
                        source={loadedImages.sheets}
                        style={styles.bgImage}
                        duration={config.animationDuration}
                    />
                    <ImageLoader
                        source={loadedImages.clouds}
                        style={styles.bgImage}
                        duration={config.animationDuration}
                    />
                    <ImageLoader
                        source={loadedImages.flakes}
                        style={styles.bgImage}
                        duration={config.animationDuration}
                        onLoadEnd={onImagesLoaded}
                    />
                </>
            )}
        </Animated.View>
    );
};

export default SplashState;
