import { useState } from 'react';

import { GET_BREEDING_PET_DRAFTS } from '@Data/Requests';
import { useApolloClient } from '@apollo/client';
import {
    BreedingPetDraftOutput,
    BreedingPetDraftOutputResponse,
} from '@models/mysteryGames';

import { useLoadingHook } from './useLoadingHook';

export const useBreedingPetDrafts = () => {
    const client = useApolloClient();

    const [breedingPetDrafts, setBreedingPetDrafts] = useState<
        BreedingPetDraftOutput[] | undefined
    >();

    const {
        isLoading: breedingPetDraftsLoading,
        startLoading,
        stopLoading,
    } = useLoadingHook();

    const getBreedingPetDrafts = async (silent: boolean) => {
        if (!silent) startLoading();

        const response = await client.query<BreedingPetDraftOutputResponse>({
            query: GET_BREEDING_PET_DRAFTS,
            fetchPolicy: 'no-cache',
        });
        setBreedingPetDrafts(response?.data?.breedingPetDrafts);
        if (!silent) stopLoading();
    };

    return {
        getBreedingPetDrafts,
        breedingPetDrafts,
        breedingPetDraftsLoading,
    };
};
