import { StyleSheet } from 'react-native';

import font from '../../../../../../helpers/font';
import { theme } from '../../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            overflow: 'hidden',
        },
        titleBlock: {
            marginVertical: 48,
        },
        title: {
            ...font({
                height: 120,
                weight: '900',
            }),
            letterSpacing: -0.16,
            fontSize: 100,
        },
        content: {
            marginTop: 210,
            marginLeft: 90,
            marginRight: 63,

            zIndex: 2,
        },

        qrCodeWrapper: {
            paddingHorizontal: 42,
            paddingVertical: 28,
            borderRadius: 140,
            alignSelf: 'flex-start',
        },
        qrCode: {
            ...font({
                height: 84,
                weight: '700',
            }),
            fontSize: 63,
            backgroundColor: 'transparent',
            textTransform: 'uppercase',
        },
        imageBlock: {
            position: 'absolute',
            width: 1232,
            aspectRatio: 1,
            top: 1037,
            left: -75,
            borderRadius: 1000,
            zIndex: 3,
        },
        petImage: {
            borderRadius: 1000,
            width: '100%',
            height: '100%',
        },
        bg: {
            position: 'absolute',
            zIndex: 1,
        },
    });

export default styles;
