import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';
import { convertHexToRGBA, stylesWebOutlines } from '@helpers/style';

import { theme } from '../../types';

const stylesMain = (theme: theme) =>
    StyleSheet.create({
        containerButton: {
            backgroundColor: Colors[theme].background,
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            paddingHorizontal: scale(4),
            paddingVertical: verticalScale(12),
            marginBottom: verticalScale(2),
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
        },
        overlay: {
            zIndex: 1,
            position: 'absolute',
            width: '100%',
            height: '100%',
            borderRadius: moderateScale(12),
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            backgroundColor: convertHexToRGBA(Colors[theme].background, 0.9),
        },
        shadow: {
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].shadow.default,
            ...stylesWebOutlines(),
        },
        buttonShadow: {
            borderRadius: 1000,
            backgroundColor: Colors[theme].buttonShadow,
            marginBottom: verticalScale(8),
        },
        icon: {
            paddingHorizontal: 12,
            paddingVertical: 12,
            backgroundColor: Colors[theme].buttonBackground,
            borderColor: Colors[theme].buttonBackground,
            borderRadius: 1000,
            marginBottom: verticalScale(3),
        },
        text: {
            color: Colors[theme].text.primary,
            ...font({
                size: 12,
                height: 16,
                weight: '600',
            }),
        },
        loadingText: {
            textAlign: 'center',
        },
        disabled: {
            opacity: 0.7,
            position: 'absolute',
            zIndex: 1,
            width: '100%',
            height: '100%',
            borderRadius: moderateScale(12),
        },
        staked: {
            position: 'absolute',
            width: 20,
            height: 20,
            alignItems: 'center',
            backgroundColor: Colors[theme].notificationBackground,
            borderRadius: 1000,
            right: scale(8),
            top: verticalScale(8),
        },
        stakedText: {
            color: Colors[theme].text.onLight,
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),
        },
    });

export default stylesMain;
