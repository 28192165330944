import * as React from 'react';
import { useCallback } from 'react';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import PurchasingFromInput from '@components/CheckoutScreen/PurchasingFromInput';
import Divider from '@components/Divider';
import ModalConfirmTransaction from '@components/ModalConfirmTransaction';
import ModalDeposit from '@components/ModalDeposit';
import ModalLoader from '@components/ModalLoader';
import PullToRefresh from '@components/PullToRefresh';
import { View } from '@components/Themed';
import TransactionSummary from '@components/TransactionSummary';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';

import { MarketPlaceBuyCheckoutProps } from '../../../../types';
import GamingWalletCheckoutSummary from '../../../Wallet/Gaming/components/GamingWalletCheckoutSummary';
import TransactionDetailsBuy from '../components/TransactionDetailsBuy';
import { useMarketPlaceBuyCheckoutScreen } from './hooks/useMarketPlaceBuyCheckoutScreen';
import stylesMain from './styles';

const MarketPlaceBuyCheckoutScreen = ({
    route,
}: MarketPlaceBuyCheckoutProps) => {
    const styles = useThemedStyles(stylesMain);

    const { pet, order, owner } = route.params;

    const {
        isBNBEnough,
        customPayment,
        onRefresh,
        depositModal,
        closeDepositModal,
        openDepositModal,
        balanceReloading,
        notEnoughFundsError,
        onPressPurchase,
        isLoading,
        renderBlockchainErrorsModal,
        closeConfirmationModal,
        confirmationModal,
        openConfirmationModal,
        canOpenConfirmTransaction,
        summaryValues,
        transactionDetailsValues,
    } = useMarketPlaceBuyCheckoutScreen(pet, order, owner);

    const renderItem = useCallback(() => {
        return (
            <>
                <BlockHeader
                    containerStyle={styles.header}
                    title={i18n.t('checkout.title')}
                    text={i18n.t('checkout.text')}
                    type="primary"
                />

                <PurchasingFromInput
                    payment={customPayment}
                    isError={notEnoughFundsError || !isBNBEnough}
                    isBNBEnough={isBNBEnough}
                    onDepositPress={openDepositModal}
                    disabled
                />

                <TransactionDetailsBuy pet={pet} order={order} owner={owner} />
            </>
        );
    }, [customPayment, notEnoughFundsError, isBNBEnough, pet, order, owner]);

    return (
        <View style={styles.container}>
            <ModalLoader
                isVisible={isLoading}
                text={i18n.t('checkout.loadingPurchasing')}
                timeout={0}
            />
            <NavigationBar wallet backIcon="leftArrow" />
            <PullToRefresh
                textLoading={i18n.t('pullToRefresh.reloadBalance.text')}
                refreshing={balanceReloading}
                onRefresh={onRefresh}
                renderItem={renderItem}
            />
            <Divider />
            <View style={styles.summary}>
                <GamingWalletCheckoutSummary values={summaryValues} />
            </View>

            <ButtonsBottom
                onPress={openConfirmationModal}
                loading={isLoading}
                title={i18n.t('general.BUTTONS.continue')}
                safe
                disabled={canOpenConfirmTransaction}
            />

            <ModalDeposit
                isVisible={depositModal}
                setIsVisible={() => closeDepositModal()}
                coin={isBNBEnough ? Coin.igu : Coin.bnb}
            />

            <ModalConfirmTransaction
                title={i18n.t('checkout.fields.modalTitle')}
                isVisible={confirmationModal}
                close={closeConfirmationModal}
                onConfirm={onPressPurchase}
                onCancel={closeConfirmationModal}
                TransactionDetailsComponent={
                    <TransactionSummary values={transactionDetailsValues} />
                }
                modalHeight={transactionDetailsValues.length * 120}
            />

            {renderBlockchainErrorsModal()}
        </View>
    );
};

export default MarketPlaceBuyCheckoutScreen;
