import React, { Dispatch, SetStateAction, useState } from 'react';

import AsyncStorage from '@react-native-async-storage/async-storage';

import { wait } from '../helpers/helpers';
import { toastSuccess } from '../helpers/toastNotification';
import i18n from '../i18n/i18n';

export enum USER_ACCESSIBILITY_SETTINGS {
    SHAKE = 'shake',
    VIBRATION = 'vibration',
}

const defaultSettings: IUserAccessibilityItem[] = [
    {
        id: USER_ACCESSIBILITY_SETTINGS.SHAKE,
        enabled: true,
    },
    {
        id: USER_ACCESSIBILITY_SETTINGS.VIBRATION,
        enabled: true,
    },
];

interface IUserAccessibilityItem {
    id: USER_ACCESSIBILITY_SETTINGS;
    enabled: boolean;
}

const STORE_KEY_USER_ACCESSIBILITY = 'userAccessibilitySettings';

export type AccessibilitySettingsContextType = {
    isFeedbackDisabled: boolean;
    isNetworkErrorDisabled: boolean;
    setIsFeedbackDisabled: Dispatch<SetStateAction<boolean>>;
    setIsNetworkErrorDisabled: Dispatch<SetStateAction<boolean>>;
    updateAccessibilitySettings: (
        id: string,
        updateStore?: any
    ) => Promise<void>;
    getStatusById: (id: USER_ACCESSIBILITY_SETTINGS) => boolean;
    writeAccessibilitySettingsToStore: (
        values?: IUserAccessibilityItem[]
    ) => Promise<void>;
    accessibilitySettings: IUserAccessibilityItem[];
    loading: boolean;
    isHapticDisabled: boolean;
};

export const AccessibilitySettingsContext =
    React.createContext<AccessibilitySettingsContextType>({
        isFeedbackDisabled: false,
        isNetworkErrorDisabled: false,
        setIsFeedbackDisabled: () => undefined,
        setIsNetworkErrorDisabled: () => undefined,
        updateAccessibilitySettings: async () => undefined,
        writeAccessibilitySettingsToStore: async () => undefined,
        getStatusById: () => true,
        accessibilitySettings: defaultSettings,
        loading: true,
        isHapticDisabled: false,
    });

export const useAccessibilitySettings = () =>
    React.useContext(AccessibilitySettingsContext);

const AccessibilitySettingsProvider: React.FC<
    React.PropsWithChildren<unknown>
> = ({ children }) => {
    const [loading, setLoading] = React.useState<boolean>(true);

    const [accessibilitySettings, setAccessibilitySettings] =
        React.useState<IUserAccessibilityItem[]>(defaultSettings);

    // Disable Feedback Screen
    const [isFeedbackDisabled, setIsFeedbackDisabled] =
        useState<boolean>(false);

    // Disable Network Error
    const [isNetworkErrorDisabled, setIsNetworkErrorDisabled] =
        useState<boolean>(false);

    // Disable Haptic Screen
    const [isHapticDisabled, setIsHapticDisabled] = useState<boolean>(false);

    const updateStates = (values: IUserAccessibilityItem[]) => {
        values.map((o) => {
            if (o.id === USER_ACCESSIBILITY_SETTINGS.SHAKE) {
                setIsFeedbackDisabled(!o.enabled);
            }
            if (o.id === USER_ACCESSIBILITY_SETTINGS.VIBRATION) {
                setIsHapticDisabled(!o.enabled);
            }
        });
    };

    const writeAccessibilitySettingsToStore = async (
        values?: IUserAccessibilityItem[],
        silent = false
    ) => {
        if (values) {
            const stringifyData = JSON.stringify(values);
            updateStates(values);
            await AsyncStorage.setItem(
                STORE_KEY_USER_ACCESSIBILITY,
                stringifyData
            );
            !silent &&
                toastSuccess(
                    undefined,
                    i18n.t('profile.accessibilitySettings.saved')
                );
            return;
        }
        const stringifyData = JSON.stringify(defaultSettings);
        await AsyncStorage.setItem(STORE_KEY_USER_ACCESSIBILITY, stringifyData);
    };

    const loadSettingFromStorage = async () => {
        const settings = await AsyncStorage.getItem(
            STORE_KEY_USER_ACCESSIBILITY
        );
        if (!settings) {
            await writeAccessibilitySettingsToStore();
            setLoading(false);
            return;
        }
        const parseSettings = JSON.parse(settings);
        updateStates(parseSettings);
        setAccessibilitySettings(parseSettings);
        await writeAccessibilitySettingsToStore(parseSettings, true);
        setLoading(false);
    };

    const updateAccessibilitySettings = React.useCallback(
        async (id: string, updateStore = true) => {
            const updatedSetting = accessibilitySettings.map((o) => {
                if (o.id === id) {
                    return {
                        ...o,
                        enabled: !o.enabled,
                    };
                }
                return o;
            });

            setAccessibilitySettings(updatedSetting);
            await wait(500);
            updateStore &&
                (await writeAccessibilitySettingsToStore(updatedSetting));
        },
        [accessibilitySettings]
    );

    const getStatusById = React.useCallback(
        (id: USER_ACCESSIBILITY_SETTINGS) => {
            return !!accessibilitySettings.find((o) => o.id === id)?.enabled;
        },
        [accessibilitySettings]
    );

    React.useEffect(() => {
        loadSettingFromStorage();
    }, []);

    return (
        <AccessibilitySettingsContext.Provider
            value={{
                isNetworkErrorDisabled,
                isFeedbackDisabled,
                setIsNetworkErrorDisabled,
                setIsFeedbackDisabled,
                updateAccessibilitySettings,
                writeAccessibilitySettingsToStore,
                getStatusById,
                loading,
                accessibilitySettings,
                isHapticDisabled,
            }}>
            {children}
        </AccessibilitySettingsContext.Provider>
    );
};
export default AccessibilitySettingsProvider;
