import ImaginationIcon from '../assets/icons/mint/generationAI.svg';
import PhotoIcon from '../assets/icons/mint/generationPhoto.svg';
import { ISelectableWay } from '../screens/MintNFT/components/SelectableWay/types';

type NFT_GENERATION_OPTIONS_TYPE = ISelectableWay[];

export type NFT_GENERATION_OPTIONS_NAME = 'PHOTO' | 'IMAGINATION';

export const NFT_GENERATION_OPTIONS: NFT_GENERATION_OPTIONS_TYPE = [
    {
        id: 'PHOTO',
        title: 'nftSelectGeneration.nftFromPhoto',
        text: 'nftSelectGeneration.nftFromPhotoText',
        icon: <PhotoIcon width={64} height={64} />,
    },
    {
        id: 'IMAGINATION',
        title: 'nftSelectGeneration.nftFromImagination',
        text: 'nftSelectGeneration.nftFromImaginationText',
        icon: <ImaginationIcon width={64} height={64} />,
    },
];
