import * as React from 'react';

import { PaginatedMysteryLikesAvailableGamesItem } from '@models/mysteryGames';

import ActiveOrderItem from '../ActiveOrderItem';

interface IProps {
    item: PaginatedMysteryLikesAvailableGamesItem;
    loading: boolean;
    action: (game: PaginatedMysteryLikesAvailableGamesItem) => void;
}

const ActiveOrdersListItemHandler = ({ item, loading, action }: IProps) => {
    if (loading) {
        return <></>;
    }
    return <ActiveOrderItem item={item} onPress={action} />;
};
export default ActiveOrdersListItemHandler;
