import ExplorerLink from '../../../../../components/ExplorerLink';
import CoinIcon from '../../../../../components/Icons/CoinIcon';
import { Text, View } from '../../../../../components/Themed';
import WalletAddress from '../../../../../components/WalletAddress';
import { useConfig } from '../../../../../contexts/ConfigContext';
import { useWallet } from '../../../../../contexts/Wallet/WalletContext';
import { Coin } from '../../../../../contexts/Wallet/WalletHelpers';
import {
    balanceShortFormatterFromWei,
    valueFromWei,
} from '../../../../../helpers/wallet';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import stylesMain from './styles';

interface IProps {
    totalDonations: number;
    walletAddress: string;
}

const TotalOrganizationDonations = ({
    totalDonations,
    walletAddress,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { priceInUsd } = useWallet();
    const { config } = useConfig();

    return (
        <View style={styles.donationsContainer}>
            <Text style={styles.totalSummary}>
                {i18n.t('charity.totalSummary')}
            </Text>
            <View style={styles.donationsCountContainer}>
                <CoinIcon slug={'IGUP'} size={32} />
                <Text style={styles.donations}>
                    {balanceShortFormatterFromWei(totalDonations)}
                </Text>
            </View>
            <Text style={styles.textBalanceDollar}>{`≈ ${priceInUsd(
                Coin.igup,
                valueFromWei(totalDonations)
            )} $`}</Text>
            <View style={styles.margin}>
                <WalletAddress address={walletAddress} />
            </View>
            <View style={styles.margin}>
                <ExplorerLink walletAddress={walletAddress} />
            </View>
        </View>
    );
};

export default TotalOrganizationDonations;
