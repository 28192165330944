import React, { useMemo } from 'react';
import {
    useAnimatedStyle,
    useSharedValue,
    withTiming,
} from 'react-native-reanimated';

import { isWeb } from '@helpers/app';
import i18n from '@i18n/i18n';

import { IProps } from './index';

export function useLinearLoader({
    progress,
    onLoadingLineStop,
    statusText,
}: IProps) {
    const sharedValue = useSharedValue<number>(0);

    const progressStyle = useAnimatedStyle(() => {
        return {
            maxWidth: isWeb
                ? `${sharedValue.value}%`
                : withTiming(`${sharedValue.value}%`, {
                      duration: 100,
                  }),
        };
    }, [sharedValue]);

    React.useEffect(() => {
        const progressPercent = progress * 100;
        sharedValue.value = progressPercent;
    }, [progress]);

    React.useEffect(() => {
        if (progress === 1) {
            onLoadingLineStop?.();
        }
    }, [progress]);

    const status = useMemo(() => {
        return `${i18n.t(`playToEarn.loadingStatuses.${statusText}`)} ${(
            progress * 100
        ).toFixed(0)}%`;
    }, [statusText, progress]);

    return {
        status,
        progressStyle,
    };
}
