import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(12),
            paddingVertical: scale(6),
            borderRadius: 50,
            alignItems: 'center',
            flexDirection: 'row',
        },
        text: {
            marginLeft: verticalScale(6),
        },
        PENDING: {
            backgroundColor: Colors[theme].statutes.pending.background,
        },
        FAILED: {
            backgroundColor: Colors[theme].statutes.failed.background,
        },
        CONFIRMED: {
            backgroundColor: Colors[theme].statutes.confirmed.background,
        },
        UNLOCK: {
            backgroundColor: Colors[theme].statutes.pending.background,
        },
        DEFAULT: {
            backgroundColor: Colors[theme].statutes.default.background,
        },
        PENDINGtext: {
            color: Colors[theme].statutes.pending.text,
        },
        FAILEDtext: {
            color: Colors[theme].statutes.failed.text,
        },
        CONFIRMEDtext: {
            color: Colors[theme].statutes.confirmed.text,
        },
        UNLOCKtext: {
            color: Colors[theme].statutes.pending.text,
        },
        DEFAULTtext: {
            color: Colors[theme].statutes.default.text,
        },
    });

export default styles;
