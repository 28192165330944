import * as React from 'react';

import TipAcceptIcon from '../../../../assets/icons/mint/tipAccept.svg';
import TipDeclineIcon from '../../../../assets/icons/mint/tipDecline.svg';
import { TipsImages } from '../../../../components/Icons/TipsImages';
import { Text, View } from '../../../../components/Themed';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import stylesMain from './styles';

const MintPhotoTips = () => {
    const styles = useThemedStyles(stylesMain);

    return (
        <>
            <Text style={styles.tipsTitle}>
                {i18n.t('mintPickPhoto.tips.title')}
            </Text>
            <View style={styles.imageWrapper}>
                <View style={[styles.imageBlock, styles.imageGap]}>
                    <View style={styles.imageContainer}>
                        <TipsImages type="default" />
                    </View>
                    <View style={styles.tipInfo}>
                        <TipAcceptIcon width={20} height={20} />
                    </View>
                    <Text style={styles.imageText}>
                        {i18n.t('mintPickPhoto.tips.visible')}
                    </Text>
                </View>
                <View style={[styles.imageBlock, styles.imageGap]}>
                    <View style={styles.imageContainer}>
                        <View style={styles.outOfContainer}>
                            <TipsImages type="cut" />
                        </View>
                    </View>
                    <View style={styles.tipInfo}>
                        <TipDeclineIcon width={20} height={20} />
                    </View>
                    <Text style={styles.imageText}>
                        {i18n.t('mintPickPhoto.tips.cut')}
                    </Text>
                </View>
                <View style={[styles.imageBlock, styles.imageGap]}>
                    <View style={styles.imageContainer}>
                        <TipsImages type="blur" />
                    </View>
                    <View style={styles.tipInfo}>
                        <TipDeclineIcon width={20} height={20} />
                    </View>
                    <Text style={styles.imageText}>
                        {i18n.t('mintPickPhoto.tips.blurry')}
                    </Text>
                </View>
                <View style={styles.imageBlock}>
                    <View style={styles.imageContainer}>
                        <TipsImages type="glare" />
                    </View>
                    <View style={styles.tipInfo}>
                        <TipDeclineIcon width={20} height={20} />
                    </View>
                    <Text style={styles.imageText}>
                        {i18n.t('mintPickPhoto.tips.glare')}
                    </Text>
                </View>
            </View>
            <View style={[styles.tipWrapper, styles.tipGap]}>
                <View style={styles.circle} />
                <Text style={styles.tipsText}>
                    {i18n.t('mintPickPhoto.tips.tipOne')}
                </Text>
            </View>
            <View style={[styles.tipWrapper, styles.tipGap]}>
                <View style={[styles.circle]} />
                <Text style={styles.tipsText}>
                    {i18n.t('mintPickPhoto.tips.tipTwo')}
                </Text>
            </View>
            <View style={[styles.tipWrapper, styles.noAlign]}>
                <View style={[styles.circle, styles.top]} />
                <Text style={styles.tipsText}>
                    {i18n.t('mintPickPhoto.tips.tipThree')}
                </Text>
            </View>
        </>
    );
};
export default MintPhotoTips;
