import { StyleSheet } from 'react-native';

import Colors from '../../../constants/Colors';
import { scale, verticalScale } from '../../../helpers/dimensions';
import font from '../../../helpers/font';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        purchasingInfoWrapper: {
            paddingHorizontal: scale(20),
            marginBottom: verticalScale(20),
        },
        purchasingInfoTextWrapper: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        purchasingTitle: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
        },
        purchasingInfo: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
        purchasingValue: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
        },
        walletSelector: {
            marginTop: verticalScale(6),
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(14),
            borderRadius: 12,
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            backgroundColor: Colors[theme].inputBackground,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        },
        walletContainer: {
            backgroundColor: 'transparent',
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
        },
        walletName: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
            marginLeft: verticalScale(8),
        },
        deposit: {
            paddingVertical: 0,
            paddingHorizontal: 0,

            justifyContent: 'flex-start',
            marginTop: verticalScale(8),
        },
        error: {
            borderColor: Colors[theme].text.critical,
        },
        errorText: {
            color: Colors[theme].text.critical,
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
            marginTop: verticalScale(4),
        },
        iconGreen: {
            color: Colors[theme].icon.green,
        },
    });

export default styles;
