import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(8),
            paddingVertical: verticalScale(4),
            flexDirection: 'row',
            borderRadius: 50,
            backgroundColor: Colors[theme].backGroundWhiteList,
            alignItems: 'center',
        },
        text: {
            color: Colors[theme].text.whiteList,
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),
            marginRight: scale(7),
        },
        image: {
            backgroundColor: 'transparent',
        },
    });

export default styles;
