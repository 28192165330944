import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingVertical: verticalScale(16),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
        },
        imgWrapper: { alignItems: 'center', paddingHorizontal: scale(16) },
        accountWrapper: {
            alignItems: 'center',
            marginTop: verticalScale(8),
            paddingHorizontal: scale(16),
        },

        statisticsWrapper: {
            paddingHorizontal: scale(16),
        },
        gap: {
            marginTop: verticalScale(16),
        },
        divider: {
            marginVertical: verticalScale(16),
        },
    });

export default styles;
