import i18n from '@i18n/i18n';

export enum DECLINATIONS {
    Ticket = 'Ticket',
}

export const getDeclination = (num: number, flow: DECLINATIONS) => {
    let n = Math.abs(num);
    n %= 100;
    if (n >= 5 && n <= 20) {
        return i18n.t(`general.DECLINATION.${flow}.five`);
    }
    n %= 10;
    if (n === 1) {
        return i18n.t(`general.DECLINATION.${flow}.one`);
    }
    if (n >= 2 && n <= 4) {
        return i18n.t(`general.DECLINATION.${flow}.two`);
    }
    return i18n.t(`general.DECLINATION.${flow}.five`);
};
