import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flexDirection: 'row',
            backgroundColor: 'transparent',
            alignItems: 'center',
            marginHorizontal: scale(32),
        },
        dividerWrapper: {
            flex: 1,
            backgroundColor: 'transparent',
            marginHorizontal: scale(8),
        },
        divider: {
            height: 1,
            backgroundColor: Colors[theme].alwaysOneColor.background,
        },
    });

export default styles;
