import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
        },

        content: {
            flex: 1,
            marginBottom: verticalScale(16),
            paddingHorizontal: scale(20),
        },

        header: {
            paddingHorizontal: scale(20),
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: verticalScale(16),
        },
        card: { marginTop: verticalScale(20) },
        emptyButton: {
            alignSelf: 'center',
        },
        emptyImg: {
            alignSelf: 'center',
            marginBottom: verticalScale(24),
        },
        emptyWrapper: {
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
        },
        empty: {
            marginVertical: verticalScale(40),
        },
    });

export default styles;
