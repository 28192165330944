import {
    TouchableOpacity as BaseTouchableOpacity,
    TouchableOpacityProps,
} from 'react-native';

const TouchableOpacity = (props: TouchableOpacityProps) => {
    const { disabled, onPress } = props;

    return (
        <BaseTouchableOpacity
            {...props}
            activeOpacity={disabled ? 1 : undefined}
            disabled={false}
            onPress={disabled ? () => undefined : onPress}
        />
    );
};

export default TouchableOpacity;
