import * as React from 'react';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import NftRoyalActiveOrderScreen from '../../screens/TabBar/Home/NftRoyalFlow/NftRoyalActiveOrder/NftRoyalActiveOrderScreen';
import NftRoyalHistoryScreen from '../../screens/TabBar/Home/NftRoyalFlow/NftRoyalHistory/NftRoyalHistoryScreen';
import { NftRoyalTabsParamList } from '../../types';
import ROUTES from '../routes';

const NftRoyalTabsStack = createBottomTabNavigator<NftRoyalTabsParamList>();

export const NftRoyalTabsNavigator = () => {
    return (
        <NftRoyalTabsStack.Navigator
            screenOptions={{
                headerShown: false,
            }}
            initialRouteName={ROUTES.NFT_ROYAL_ACTIVE_ORDERS}
            backBehavior="none"
            tabBar={() => null}>
            <NftRoyalTabsStack.Screen
                name={ROUTES.NFT_ROYAL_ACTIVE_ORDERS}
                component={NftRoyalActiveOrderScreen}
            />
            <NftRoyalTabsStack.Screen
                name={ROUTES.NFT_ROYAL_HISTORY}
                component={NftRoyalHistoryScreen}
            />
        </NftRoyalTabsStack.Navigator>
    );
};
