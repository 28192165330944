import { GET_BREEDING_PETS, GET_BREEDING_RANKS_STATS } from '@Data/Requests';
import { useLazyQuery } from '@apollo/client';
import { BreedingPetParentOutputResponse } from '@models/mysteryGames';

export const useBreedingPets = () => {
    const [
        getBreedingPets,
        { data: breedingPetsData, loading: breedingPetsLoading },
    ] = useLazyQuery<BreedingPetParentOutputResponse>(GET_BREEDING_PETS, {
        fetchPolicy: 'network-only',
    });

    return {
        breedingPetsData,
        getBreedingPets,
        breedingPetsLoading,
    };
};
