import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';
import { stylesContainerWithBorderDefault } from '@helpers/style';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            marginTop: verticalScale(24),
            marginBottom: verticalScale(24),
        },
        codeWrapper: {
            ...stylesContainerWithBorderDefault(theme),
            marginTop: verticalScale(16),
            height: 240,
        },
        button: {
            position: 'absolute',
            right: scale(18),
            top: scale(18),
            zIndex: 1,
        },
    });

export default styles;
