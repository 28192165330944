import * as React from 'react';
import Toast, {
    BaseToast,
    ErrorToast,
    InfoToast,
    SuccessToast,
} from 'react-native-toast-message';

import useThemedStyles from '../../hooks/useThemedStyles';
import BannerMysteryBox from '../BannerMysteryBox';
import stylesMain from './styles';

interface IProps {}

const ToastNotification = ({}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const toastConfig = {
        success: (props: any) => (
            <SuccessToast
                {...props}
                style={[styles.container, styles.success]}
                contentContainerStyle={styles.content}
                text1Style={styles.text1}
                text2Style={styles.text2}
                text2NumberOfLines={4}
            />
        ),
        error: (props: any) => (
            <ErrorToast
                {...props}
                style={[styles.container, styles.error]}
                contentContainerStyle={styles.content}
                text1Style={styles.text1}
                text2Style={styles.text2}
                text2NumberOfLines={4}
            />
        ),
        info: (props: any) => (
            <InfoToast
                {...props}
                style={[styles.container, styles.info]}
                contentContainerStyle={styles.content}
                text1Style={styles.text1}
                text2Style={styles.text2}
                text2NumberOfLines={4}
            />
        ),
        warning: (props: any) => (
            <BaseToast
                {...props}
                style={[styles.container, styles.warning]}
                contentContainerStyle={styles.content}
                text1Style={styles.text1}
                text2Style={styles.text2}
                text2NumberOfLines={4}
            />
        ),
        mysteryBoxBanner: (props: any) => <BannerMysteryBox {...props} />,
    };

    return (
        <Toast
            config={toastConfig}
            position="bottom"
            visibilityTime={3000}
            bottomOffset={50}
            topOffset={50}
        />
    );
};

export default ToastNotification;
