import { StyleSheet } from 'react-native';

import Colors from '../../../constants/Colors';
import { MAX_WINDOW_WIDTH } from '../../../contexts/DimensionsContext';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../helpers/dimensions';
import font from '../../../helpers/font';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            // @ts-ignore
            position: 'fixed',
            bottom: 0,
            width: '100%',
            maxWidth: MAX_WINDOW_WIDTH,
            zIndex: 1,
            borderTopLeftRadius: moderateScale(12),
            borderTopRightRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            padding: scale(20),
        },
        iconImage: {
            width: 48,
            height: 48,
            borderRadius: moderateScale(8),
        },
        titleText: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                weight: '700',
                height: 24,
            }),
        },
        descriptionText: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                weight: '400',
                height: 20,
            }),
        },
        buttonContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingTop: verticalScale(15),
        },
        button: {
            width: '48%',
        },
        headerContent: {
            flexDirection: 'row',
        },
        headerText: {
            justifyContent: 'space-between',
            paddingLeft: scale(12),
        },
    });

export default styles;
