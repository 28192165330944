import * as React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import { UsersBoosts } from '@Data/Models';
import { View } from '@components/Themed';
import { useConfig } from '@contexts/ConfigContext';
import { useTheme } from '@contexts/ThemeContext';

import DailyBoost from '../../../../../assets/icons/referrals/dailyBoost.svg';
import AlertCard from '../../../../../components/AlertCard';
import BlockHeader from '../../../../../components/BlockHeader/BlockHeader';
import ModalBottom from '../../../../../components/ModalBottom';
import Colors from '../../../../../constants/Colors';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import BoostCard from '../BoostCard';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    closeAction: () => void;
    boosts: UsersBoosts[] | undefined;
}

const ModalHowBoostWorks = ({ isVisible, closeAction, boosts }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { theme } = useTheme();
    const { config } = useConfig();
    const rewardEnergyThreshold = config?.rewardEnergyThreshold;

    return (
        <ModalBottom
            modalHeight="96%"
            isVisible={isVisible}
            backdropOpacity={Colors[theme].modal.opacity}
            onClose={closeAction}>
            <ScrollView style={styles.modalContainer}>
                <View style={styles.imgWrapper}>
                    <DailyBoost />
                </View>
                <BlockHeader
                    type="emptyState"
                    title={i18n.t('referrals.howBoostWorks.title')}
                    text={i18n.t('referrals.howBoostWorks.text')}
                />
                <View style={styles.alert}>
                    <AlertCard
                        text={i18n.t('referrals.howBoostWorks.alert', {
                            rewardEnergyThreshold,
                        })}
                    />
                </View>
                <TouchableOpacity style={styles.cardWrapper} activeOpacity={1}>
                    {boosts?.map((o) => {
                        if (o.id !== 'None') {
                            return (
                                <BoostCard
                                    key={o.id}
                                    type="small"
                                    boost={o.rewardBonusPercentage}
                                    invites={o.minReferralsCount}
                                    level={o.id}
                                    containerStyle={styles.gap}
                                />
                            );
                        }
                    })}
                </TouchableOpacity>
            </ScrollView>
        </ModalBottom>
    );
};
export default ModalHowBoostWorks;
