import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        itemsGap: {
            marginBottom: verticalScale(20),
        },
        contentContainer: {
            paddingHorizontal: scale(20),
            paddingTop: verticalScale(20),
            paddingBottom: verticalScale(20),
        },
        linkContainer: {
            alignSelf: 'center',
            marginTop: verticalScale(12),
        },
        header: { paddingHorizontal: scale(20) },
        alert: {
            marginHorizontal: scale(20),
            marginVertical: scale(20),
        },
    });

export default styles;
