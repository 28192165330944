import { useCallback, useMemo, useState } from 'react';
import React from 'react';

import ImageWithName from '@components/ImageWithName';
import { useConfig } from '@contexts/ConfigContext';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { errorsHandler } from '@helpers/errors';
import { priceString } from '@helpers/payments';
import { balanceFromWei, feeWithCurrency } from '@helpers/wallet';
import useBlockchainErrorsHandler from '@hooks/useBlockchainErrorsHandler';
import useFeeInfo from '@hooks/useFeeInfo';
import { useLoadingHook } from '@hooks/useLoadingHook';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import {
    MarketplaceNftDetailsOrderOutput,
    MarketplaceNftDetailsPetOutput,
    MarketplaceNftDetailsSellerOutput,
} from '@models/marketplace';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

export const useMarketPlaceBuyCheckoutScreen = (
    pet: MarketplaceNftDetailsPetOutput,
    order: MarketplaceNftDetailsOrderOutput,
    owner: MarketplaceNftDetailsSellerOutput
) => {
    const navigation = useNavigation();

    const { config } = useConfig();
    const {
        walletBalance,
        reloadBalance,
        fee,
        balanceReloading,
        buyFromMarketPlace,
    } = useWallet();
    const [disableButton, setDisableButton] = useState(false);

    const estimatedFee = useMemo(() => fee?.nftTransfer, [fee?.nftTransfer]);

    const isBNBEnough = useMemo(() => {
        return Number(walletBalance?.bnb.value) >= Number(estimatedFee);
    }, [estimatedFee, walletBalance?.bnb]);

    const notEnoughFundsError = useMemo(
        () =>
            Number(walletBalance?.igu?.value) <
            Number(balanceFromWei(order.priceIguAmountWei).value),
        [walletBalance?.igu, order]
    );

    const customPayment = useMemo(() => {
        return {
            token: Coin.igu,
            tokenAddress: config?.marketplaceContractAddress || '',
            amountWei: order.priceIguAmountWei,
            productId: null,
            priceString: undefined,
        };
    }, [config, order]);

    const {
        isVisible: depositModal,
        open: openDepositModal,
        close: closeDepositModal,
    } = useVisibleHook();

    const {
        isVisible: confirmationModal,
        open: openConfirmationModal,
        close: closeConfirmationModal,
    } = useVisibleHook();

    const { handleBlockchainErrors, renderBlockchainErrorsModal } =
        useBlockchainErrorsHandler(
            () => {
                setDisableButton(true);
            },
            () => {
                setDisableButton(false);
            }
        );

    const onRefresh = useCallback(() => {
        reloadBalance(false);
    }, []);

    const navigateBack = () => {
        navigation.pop();
    };

    const { isLoading, startLoading, stopLoading } = useLoadingHook();

    const { renderFeeInfoIcon } = useFeeInfo(
        closeConfirmationModal,
        openConfirmationModal
    );

    const canOpenConfirmTransaction = useMemo(
        () => disableButton || isLoading || notEnoughFundsError || !isBNBEnough,
        [disableButton, isLoading, notEnoughFundsError, isBNBEnough]
    );

    const totalSummary = useMemo(
        () =>
            priceString(
                balanceFromWei(order.priceIguAmountWei).valueLong,
                Coin.igu
            ),
        [order]
    );

    const summaryValues = useMemo(() => {
        return [
            {
                key: i18n.t('checkout.fields.fee'),
                value: feeWithCurrency(estimatedFee),
                icon: renderFeeInfoIcon(true),
            },

            {
                key: i18n.t('checkout.fields.totalSummary'),
                value: totalSummary,
            },
        ];
    }, [order, estimatedFee]);

    const transactionDetailsValues = [
        {
            key: i18n.t('checkout.fields.nft'),
            value: <ImageWithName petImg={pet.imageUrl} petName={pet.name} />,
        },
        {
            key: i18n.t('wallet.fromAddress'),
            value: owner.profile.username || owner.profile.walletAddress,
        },
        {
            key: i18n.t('checkout.fields.to'),
            value: i18n.t('checkout.fields.nftWallet'),
        },

        {
            key: i18n.t('checkout.fields.fee'),
            value: feeWithCurrency(estimatedFee),
            icon: renderFeeInfoIcon(),
        },
        {
            key: i18n.t('checkout.fields.totalSummary'),
            value: totalSummary,
        },
    ];

    const onPressPurchase = useCallback(async () => {
        try {
            startLoading();
            const transactionResult = await buyFromMarketPlace(
                order.buyTransactionValuesStringified,
                order.priceIguAmountWei
            );
            if (transactionResult) {
                stopLoading();
                navigation.popToTop();
                navigation.navigate(ROUTES.MARKETPLACE_BUY_SUCCESS, {
                    pet,
                    total: totalSummary,
                    owner,
                    transactionResult,
                });
            }

            stopLoading();
        } catch (error) {
            errorsHandler(error, true);
            stopLoading();
            if (!handleBlockchainErrors(error)) {
                errorsHandler(error, true);
            }
        }
    }, [totalSummary, order, pet]);

    return {
        navigateBack,
        renderFeeInfoIcon,
        isBNBEnough,
        notEnoughFundsError,
        onRefresh,
        disableButton,
        depositModal,
        closeDepositModal,
        openDepositModal,
        config,
        balanceReloading,
        fee,
        isLoading,
        onPressPurchase,
        renderBlockchainErrorsModal,
        customPayment,
        confirmationModal,
        closeConfirmationModal,
        openConfirmationModal,
        canOpenConfirmTransaction,
        summaryValues,
        transactionDetailsValues,
    };
};
