import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        modalContainer: {
            marginVertical: verticalScale(32),
            paddingHorizontal: scale(20),
        },
        imgWrapper: {
            alignSelf: 'center',
            marginBottom: verticalScale(24),
        },

        cardWrapper: {
            marginTop: verticalScale(8),
        },
        gap: {
            marginBottom: verticalScale(12),
        },
        alert: {
            marginVertical: verticalScale(12),
        },
    });

export default styles;
