import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(8),
            paddingVertical: verticalScale(4),
            flexDirection: 'row',
            borderRadius: 50,
            backgroundColor:
                Colors[theme].mintPriceBackground.mintPriceContainer,
            alignItems: 'center',
        },
        text: {
            color: Colors[theme].mintPriceBackground.mintPrice,
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),
            marginRight: scale(7),
            maxWidth: scale(100),
        },
        image: {
            width: 20,
            height: 20,
            backgroundColor: 'transparent',
        },
    });

export default styles;
