import * as React from 'react';
import { ScrollView } from 'react-native';

import { useLazyQuery, useQuery } from '@apollo/client';
import IconDown from '@assets/icons/mysteryGames/TokenTeller/down.svg';
import IconUp from '@assets/icons/mysteryGames/TokenTeller/up.svg';
import ActivityIndicator from '@components/ActivityIndicator';
import Animation from '@components/Animation';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import Button from '@components/Button';
import ButtonsBottom from '@components/ButtonsBottom';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT, TYPO_TEXT_COLOR } from '@components/TypoText/types';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { errorsHandler } from '@helpers/errors';
import { wait } from '@helpers/helpers';
import { priceString } from '@helpers/payments';
import { toastErrorUnknown } from '@helpers/toastNotification';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import { useTimer } from '@hooks/useTimer';
import i18n from '@i18n/i18n';
import {
    MYSTERY_GAMES,
    TOKEN_TELLER_GAME_PREDICTION_DIRECTION,
    TOKEN_TELLER_GAME_STATUS,
    TokentellerGameCoefficientOutputResponse,
    TokentellerGameHistoryOutputResponse,
} from '@models/mysteryGames';
import NavigationBar from '@navigation/NavigationBar';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import {
    GET_TOKEN_TELLER_GAME_COEFFICIENTS,
    GET_TOKEN_TELLER_GAME_HISTORY,
} from '@requests/tokenTeller';

import { TokenTellerWaitingForResultProps } from '../../../../../types';
import TokenTellerDownPayout from '../../components/TokenTellerDownPayout';
import TokenTellerParticipants from '../../components/TokenTellerParticipants';
import TokenTellerPricePool from '../../components/TokenTellerPricePool';
import TokenTellerPriceStatistic from '../../components/TokenTellerPriceStatistic';
import TokenTellerTimerBlock from '../../components/TokenTellerTimerBlock';
import TokenTellerUpPayout from '../../components/TokenTellerUpPayout';
import { useTokenTellerAvailableGame } from '../../hooks/useTokenTellerAvailableGame';
import { getTokenTellerAmountOfWei, getTokenTellerUserCount } from '../helpers';
import stylesMain from './styles';

const GAME = MYSTERY_GAMES.tokenteller;
const LIVE_BACKGROUND = '#F08975';
const COMING_BACKGROUND = '#8099FF';

const TokenTellerWaitingForResultScreen = ({
    route,
}: TokenTellerWaitingForResultProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const { id: gameId, routeBack } = route.params;

    const { setTokenTellerCoefficients } = useMysteryGames();

    const { game, gameError, gameLoading, getGame } =
        useTokenTellerAvailableGame();

    const { data: coefficientData } =
        useQuery<TokentellerGameCoefficientOutputResponse>(
            GET_TOKEN_TELLER_GAME_COEFFICIENTS,
            {
                fetchPolicy: 'cache-and-network',
            }
        );

    React.useEffect(() => {
        if (coefficientData) {
            setTokenTellerCoefficients(coefficientData);
        }
    }, [coefficientData]);

    React.useEffect(() => {
        getGame(gameId);
    }, [gameId]);

    React.useEffect(() => {
        if (gameError) {
            navigation.pop();
            toastErrorUnknown();
        }
    }, [gameError]);

    const isComingGame = React.useMemo(
        () => game?.status === TOKEN_TELLER_GAME_STATUS.Pending,
        [game?.status]
    );

    const renderTopTag = React.useCallback(() => {
        if (!game?.isPredicted) {
            return null;
        }

        const { predictedAmountWei, predictedDirection } = game;

        const isUp =
            predictedDirection === TOKEN_TELLER_GAME_PREDICTION_DIRECTION.Up;
        const title = `${priceString(
            balanceFromWei(predictedAmountWei).valueLong,
            Coin.vigu
        )} / ${i18n.t(`HomeScreen.${GAME}.${predictedDirection}`)}`;

        const buttonIcon = isUp ? (
            <IconUp width={24} height={24} />
        ) : (
            <IconDown width={24} height={24} />
        );

        const textStyles = isUp ? styles.accent : styles.critical;

        return (
            <Button
                type="outline"
                isOneColor
                size="md"
                title={title}
                icon={buttonIcon}
                textStyles={textStyles}
                iconSize={24}
                containerStyle={styles.button}
                customIconStyles={styles.icon}
                disabledWithoutChangeStyles
            />
        );
    }, [game, styles]);

    const backgroundColor = React.useMemo(() => {
        if (!game || gameLoading) {
            return styles.main.backgroundColor;
        }
        return isComingGame ? COMING_BACKGROUND : LIVE_BACKGROUND;
    }, [isComingGame, game, gameLoading]);

    const onViewAllParticipants = React.useCallback(() => {
        navigation.push(ROUTES.TOKEN_TELLER_ALL_PARTICIPANTS, {
            id: game?.id,
        });
    }, [game?.id]);

    const onPurchaseMore = React.useCallback(() => {
        navigation.navigate(routeBack, {
            game,
        });
    }, [game, routeBack]);

    const { isWarningTime } = useTimer({
        endOfTime: game?.endDate,
    });

    const [removeButton, setRemoveButton] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (isWarningTime) setRemoveButton(isWarningTime);
    }, [isWarningTime]);

    const [getHistoryItem] = useLazyQuery<TokentellerGameHistoryOutputResponse>(
        GET_TOKEN_TELLER_GAME_HISTORY,
        {
            fetchPolicy: 'network-only',
        }
    );

    const onEndOfTime = React.useCallback(async () => {
        if (isComingGame) {
            getGame(gameId);
            setRemoveButton(false);
            return;
        }
        await wait(2000);
        try {
            const result = await getHistoryItem({
                variables: {
                    gameId,
                },
            });
            if (result.data?.tokentellerGameHistory) {
                navigation.navigate(ROUTES.TOKEN_TELLER_GAME_HISTORY, {
                    game: result.data.tokentellerGameHistory,
                    force: false,
                    id: gameId,
                });
            } else {
                navigation.pop();
                toastErrorUnknown();
            }
        } catch (error) {
            errorsHandler(error);
        }
        setRemoveButton(false);
    }, [isComingGame, gameId]);

    const onBack = React.useCallback(() => {
        if (removeButton) return;
        navigation.pop();
    }, [removeButton]);

    const { tokenTellerCoefficients } = useMysteryGames();

    const currentUserCount = React.useMemo(() => {
        if (!game) return 0;

        const { id, userCount, status } = game;
        return getTokenTellerUserCount(
            tokenTellerCoefficients,
            id,
            userCount,
            status
        );
    }, [tokenTellerCoefficients, game]);

    const currentAmountOfWei = React.useMemo(() => {
        if (!game) return '';

        const { id, iguAmountWei, status } = game;
        return getTokenTellerAmountOfWei(
            tokenTellerCoefficients,
            id,
            iguAmountWei,
            status,
            false
        );
    }, [tokenTellerCoefficients, game]);

    return (
        <View style={[styles.main, { backgroundColor }]}>
            <NavigationBar
                isOneColor
                fillColor={backgroundColor}
                filled={false}
                onBack={onBack}
                backIcon="close"
            />
            {gameLoading ? (
                <>
                    <ActivityIndicator
                        size={'large'}
                        containerStyle={styles.loading}
                        loading={gameLoading}
                    />
                </>
            ) : (
                game && (
                    <>
                        <ScrollView
                            style={styles.container}
                            showsVerticalScrollIndicator={false}>
                            <BlockHeader
                                containerStyle={styles.header}
                                emptyStateIcon={
                                    <Animation
                                        animation="TokenTellerRoundComing"
                                        style={styles.animation}
                                    />
                                }
                                type="emptyState"
                                titleComponent={
                                    <TypoText
                                        type={TYPO_TEXT.HEADING_H2}
                                        colorType={TYPO_TEXT_COLOR.ON_DARK}>
                                        {game.title}
                                    </TypoText>
                                }
                                textComponent={
                                    <TypoText
                                        type={TYPO_TEXT.BODY_BIG}
                                        colorType={TYPO_TEXT_COLOR.ON_DARK}>
                                        {game.text}
                                    </TypoText>
                                }
                                componentBetweenImageAndText={renderTopTag()}
                            />
                            <TokenTellerTimerBlock
                                date={game.endDate}
                                status={game.status}
                                onEndOfTime={onEndOfTime}
                            />
                            <TokenTellerPriceStatistic
                                predictedDirection={game.predictedDirection}
                                predictedAmount={game.predictedAmountWei}
                                initialPrice={game.beforePrice}
                                containerStyle={styles.feature}
                                type="opacity"
                            />
                            <TokenTellerPricePool
                                containerStyle={styles.feature}
                                type="opacity"
                                amount={currentAmountOfWei}
                            />

                            <TokenTellerUpPayout
                                containerStyle={styles.feature}
                                type="opacity"
                                game={game}
                            />
                            <TokenTellerDownPayout
                                containerStyle={styles.feature}
                                type="opacity"
                                game={game}
                            />

                            <TokenTellerParticipants
                                containerStyle={[
                                    styles.feature,
                                    styles.marginBottom,
                                ]}
                                type="opacity"
                                text={`${currentUserCount}`}
                                handlePress={onViewAllParticipants}
                            />
                        </ScrollView>
                        {isComingGame && !removeButton && (
                            <ButtonsBottom
                                safe
                                isOneColor
                                onPress={onPurchaseMore}
                                buttonType="outline"
                                containerStyle={styles.ButtonsBottom}
                                title={i18n.t(
                                    `HomeScreen.${GAME}.addMoreTokens`
                                )}
                            />
                        )}
                    </>
                )
            )}
        </View>
    );
};

export default TokenTellerWaitingForResultScreen;
