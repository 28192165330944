import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import { scale, verticalScale } from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        donationsContainer: {
            borderWidth: 1,
            borderRadius: 12,
            borderColor: Colors[theme].border.primary,
            paddingVertical: verticalScale(16),
            paddingHorizontal: scale(16),
            alignItems: 'center',
            marginBottom: verticalScale(12),
        },
        donationsCountContainer: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        donations: {
            color: Colors[theme].text.primary,
            ...font({
                size: 20,
                height: 26,
                weight: '700',
            }),
            marginLeft: scale(8),
        },
        totalSummary: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
            marginBottom: scale(8),
        },
        textBalanceDollar: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
            letterSpacing: 0.08,
            paddingTop: verticalScale(5),
        },
        margin: {
            marginTop: verticalScale(10),
        },
    });

export default styles;
