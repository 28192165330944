import * as React from 'react';
import { useMemo } from 'react';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

import CoinIcon from '@components/Icons/CoinIcon';
import { Text, View } from '@components/Themed';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import useThemedStyles from '@hooks/useThemedStyles';
import {
    LUCKY_STRIKE_GAMES_STATUS,
    MYSTERY_LIKES_STATUS,
    NFT_ROYAL_GAME_HISTORY_STATUS,
    TOKEN_TELLER_GAME_STATUS,
    TOKEN_TELLER_HISTORY_GAMES_STATUS,
} from '@models/mysteryGames';

import stylesMain from './styles';

interface IProps {
    amount: string;
    status?:
        | MYSTERY_LIKES_STATUS
        | NFT_ROYAL_GAME_HISTORY_STATUS
        | LUCKY_STRIKE_GAMES_STATUS
        | TOKEN_TELLER_HISTORY_GAMES_STATUS;
    backgroundStyle?: StyleProp<ViewStyle>;
    textStyle?: StyleProp<TextStyle>;
    oneTheme?: boolean;
}

const GameAmount = ({
    amount,
    status,
    backgroundStyle,
    textStyle,
    oneTheme,
}: IProps) => {
    const styles = useThemedStyles(stylesMain, oneTheme);

    const newStyles = useMemo(() => {
        switch (status) {
            case MYSTERY_LIKES_STATUS.Won:
                return {
                    background: styles.wonBg,
                    color: styles.wonText,
                };
            case MYSTERY_LIKES_STATUS.Lost:
                return {
                    background: styles.lostBg,
                    color: styles.lostText,
                };
            case MYSTERY_LIKES_STATUS.WaitingForMyself:
                return {
                    background: styles.waitBg,
                    color: styles.waitText,
                };
            case LUCKY_STRIKE_GAMES_STATUS.WaitingForResults:
                return {
                    background: styles.waitBg,
                    color: styles.waitText,
                };
            case MYSTERY_LIKES_STATUS.WaitingForOpponent:
                return {
                    background: styles.waitBg,
                    color: styles.waitText,
                };

            default:
                return {
                    background: styles.elseBg,
                    color: styles.elseText,
                };
        }
    }, [status, styles]);

    return (
        <View
            style={[styles.amountBlock, newStyles.background, backgroundStyle]}>
            <Text style={[styles.amount, newStyles.color, textStyle]}>
                {amount}
            </Text>
            <CoinIcon slug={Coin.vigu} size={20} />
        </View>
    );
};
export default GameAmount;
