import * as React from 'react';

import Animation from '@components/Animation';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import TypoText from '@components/TypoText';
import { TYPO_TEXT, TYPO_TEXT_COLOR } from '@components/TypoText/types';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { priceString } from '@helpers/payments';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { NFT_ROYAL_GAME_HISTORY_STATUS } from '@models/mysteryGames';

import stylesMain from './styles';

const SCREEN = 'NftRoyalGameHistory';

interface IProps {
    status: NFT_ROYAL_GAME_HISTORY_STATUS;
    iguAmountWei?: string;
    isBigHeader?: boolean;
}

const NftRoyalHistoryBlockHeader = ({
    status,
    iguAmountWei,
    isBigHeader,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { nftRoyaleGameConfig } = useMysteryGames();

    const renderBigHeader = () => {
        const textComponent = () => {
            if (status === NFT_ROYAL_GAME_HISTORY_STATUS.Won) {
                return (
                    <TypoText
                        type={TYPO_TEXT.BODY_BIG}
                        colorType={TYPO_TEXT_COLOR.ON_DARK}>
                        {i18n.t(
                            `HomeScreen.NftRoyal.${SCREEN}.${status}.textOne`
                        )}
                        <TypoText
                            type={TYPO_TEXT.SUBHEAD_MEDIUM_BOLD}
                            colorType={TYPO_TEXT_COLOR.ON_DARK}>
                            {priceString(
                                balanceFromWei(iguAmountWei).valueLong,
                                Coin.vigu
                            )}
                        </TypoText>
                        {i18n.t(
                            `HomeScreen.NftRoyal.${SCREEN}.${status}.textTwo`
                        )}
                    </TypoText>
                );
            }
            return (
                <TypoText
                    type={TYPO_TEXT.BODY_BIG}
                    colorType={TYPO_TEXT_COLOR.ON_DARK}>
                    {i18n.t(`HomeScreen.NftRoyal.${SCREEN}.${status}.text`)}
                </TypoText>
            );
        };

        return (
            <BlockHeader
                containerStyle={styles.header}
                emptyStateIcon={
                    <Animation
                        animation={status as keyof object}
                        style={styles.animation}
                    />
                }
                type="emptyState"
                titleComponent={
                    <TypoText
                        type={TYPO_TEXT.HEADING_H2}
                        colorType={TYPO_TEXT_COLOR.ON_DARK}>
                        {i18n.t(
                            `HomeScreen.NftRoyal.${SCREEN}.${status}.title`
                        )}
                    </TypoText>
                }
                textComponent={textComponent()}
            />
        );
    };

    const renderDefaultHeader = () => {
        const textComponent = () => {
            if (status === NFT_ROYAL_GAME_HISTORY_STATUS.Won) {
                return (
                    <>
                        {i18n.t(
                            `HomeScreen.NftRoyal.${SCREEN}.${status}.textOne`
                        )}
                        <TypoText
                            type={TYPO_TEXT.SUBHEAD_MEDIUM_BOLD}
                            colorType={TYPO_TEXT_COLOR.ACCENT}>
                            {priceString(
                                balanceFromWei(iguAmountWei).valueLong,
                                Coin.vigu
                            )}
                        </TypoText>
                        {i18n.t(
                            `HomeScreen.NftRoyal.${SCREEN}.${status}.textTwo`
                        )}
                    </>
                );
            }
            return (
                <>{i18n.t(`HomeScreen.NftRoyal.${SCREEN}.${status}.text`)}</>
            );
        };
        return (
            <BlockHeader
                emptyStateIcon={
                    <Animation
                        animation={status as keyof object}
                        style={styles.animation}
                    />
                }
                type="emptyState"
                title={i18n.t(`HomeScreen.NftRoyal.${SCREEN}.${status}.title`)}
                textComponent={textComponent()}
            />
        );
    };

    return isBigHeader ? renderBigHeader() : renderDefaultHeader();
};

export default NftRoyalHistoryBlockHeader;
