import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { LUCKY_STRIKE_COLOR } from '@contexts/MysteryGamesContext';
import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
            backgroundColor: Colors[theme].background,
        },

        animation: {
            width: 250,
        },
        container: {
            paddingHorizontal: scale(20),
        },
        button: {
            alignSelf: 'center',
            marginBottom: verticalScale(16),
        },
        buttonTextStyle: {
            color: LUCKY_STRIKE_COLOR,
        },
        accent: { color: Colors[theme].text.accent },
        critical: { color: Colors[theme].text.critical },

        feature: { marginTop: verticalScale(12) },
        ButtonsBottom: {
            backgroundColor: 'transparent',
        },
        bottomGap: {
            paddingBottom: verticalScale(32),
        },
        marginBottom: {
            marginBottom: verticalScale(24),
        },
        loading: {
            flex: 1,
        },
    });

export default styles;
