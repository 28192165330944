import * as React from 'react';

import { PetAge } from '@Data/Models';
import AdultIcon from '@assets/icons/ages/adult.svg';
import BabyIcon from '@assets/icons/ages/baby.svg';
import OldIcon from '@assets/icons/ages/old.svg';
import YoungIcon from '@assets/icons/ages/young.svg';

import { IconType } from '../types';

interface IProps extends IconType {
    slug: PetAge;
}

const AgeIcon = ({ slug, size = 72 }: IProps) => {
    switch (slug) {
        case 'Baby':
            return <BabyIcon width={size} height={size} />;
        case 'Young':
            return <YoungIcon width={size} height={size} />;
        case 'Adult':
            return <AdultIcon width={size} height={size} />;
        case 'Old':
            return <OldIcon width={size} height={size} />;
        default:
            return <></>;
    }
};

export default AgeIcon;
