import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import { stylesAlignCenter } from '@helpers/style';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
        },
        container: { paddingHorizontal: scale(20) },
        gapText: {
            marginBottom: verticalScale(12),
        },
        divider: {
            marginVertical: verticalScale(24),
        },
        margin: {
            marginTop: verticalScale(6),
        },
    });

export default styles;
