import * as React from 'react';
import { KeyboardAvoidingView, ScrollView } from 'react-native';

import { FieldArray, FormikProvider, getIn, useFormik } from 'formik';
import * as Yup from 'yup';

import { useMutation } from '@apollo/client';
import { isIOS } from '@helpers/app';
import { errorsHandler } from '@helpers/errors';
import { toastSuccess } from '@helpers/toastNotification';
import { useVisibleHook } from '@hooks/useVisibleHook';

import { SET_BROADCAST_PUSH_NOTIFICATION } from '../../../Data/Requests';
import BlockHeader from '../../../components/BlockHeader/BlockHeader';
import ButtonsBottom from '../../../components/ButtonsBottom';
import Divider from '../../../components/Divider';
import Selector from '../../../components/Selector';
import { View } from '../../../components/Themed';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import NavigationBar from '../../../navigation/NavigationBar';
import NotificationForm from '../components/NotificationForm';
import SelectLanguageModal from '../components/SelectLanguageModal';
import { IInitialMessage } from '../types';
import stylesMain from './styles';

const initialMessage: IInitialMessage = {
    title: '',
    body: '',
    language: '',
};

const AdminSendPushNotificationScreen = () => {
    const styles = useThemedStyles(stylesMain);

    const { isVisible, close, open } = useVisibleHook();

    const [setBroadcastPushNotifications, { loading }] = useMutation(
        SET_BROADCAST_PUSH_NOTIFICATION
    );

    const formikData = useFormik({
        initialValues: {
            messages: [initialMessage],
        },
        validationSchema: Yup.object({
            messages: Yup.array(
                Yup.object({
                    title: Yup.string().required(
                        i18n.t(
                            'profile.adminSendPushNotification.errors.required'
                        )
                    ),
                    body: Yup.string().required(
                        i18n.t(
                            'profile.adminSendPushNotification.errors.required'
                        )
                    ),
                })
            ),
        }),

        onSubmit: ({ messages }) => handleSubmit(messages),
        enableReinitialize: true,
    });

    const [languagesSelected, setLanguagesSelected] = React.useState<string[]>(
        []
    );

    const isLanguagesEmpty = React.useMemo(
        () => !languagesSelected.length,
        [languagesSelected]
    );

    const updateSelectedLanguages = (ids: string[]) => {
        setLanguagesSelected(ids);
    };
    const handleSubmit = async (values: IInitialMessage[]) => {
        const messages = values.map((item) => {
            return {
                ...item,
                language: item.language.toUpperCase(),
            };
        });

        try {
            setBroadcastPushNotifications({
                variables: {
                    broadcastInput: {
                        messages,
                    },
                },
            });
            updateSelectedLanguages([]);
            resetForm();
            toastSuccess();
        } catch (error) {
            errorsHandler(error, true);
        }
    };

    const {
        submitForm,
        values,
        errors,
        handleChange,
        touched,
        isValid,
        setTouched,
        resetForm,
    } = formikData;

    const selectorText = React.useMemo(
        () =>
            isLanguagesEmpty
                ? i18n.t(
                      'profile.adminSendPushNotification.selectLanguage.placeHolder'
                  )
                : i18n.t(
                      'profile.adminSendPushNotification.selectLanguage.title',
                      {
                          amount: languagesSelected.length,
                      }
                  ),
        [isLanguagesEmpty, languagesSelected]
    );

    const setError = React.useCallback(
        (index: number, key: string) => {
            return (
                touched.messages &&
                getIn(touched.messages[index], key) &&
                errors.messages &&
                getIn(errors.messages[index], key)
            );
        },
        [touched, errors]
    );

    const setOnTouched = React.useCallback(
        (index: number, key: string) => {
            if (!touched.messages) {
                setTouched({
                    messages: [{ [key]: true }],
                });
                return;
            }
            const array = touched.messages;
            if (touched.messages[index] !== undefined) {
                const currentArray = array[index];
                array[index] = {
                    ...currentArray,
                    [key]: true,
                };
                setTouched({ messages: array });
                return;
            }
            array.push({ [key]: true });

            setTouched({ messages: array });
        },
        [touched]
    );

    return (
        <KeyboardAvoidingView
            behavior={isIOS ? 'padding' : 'height'}
            style={styles.mainContainer}>
            <NavigationBar backIcon filled={false} />
            <FormikProvider value={formikData}>
                <ScrollView
                    keyboardShouldPersistTaps={'handled'}
                    style={styles.content}
                    showsVerticalScrollIndicator={false}>
                    <BlockHeader
                        containerStyle={styles.paddingHorizontal}
                        type="primary"
                        title={i18n.t(
                            'profile.adminSendPushNotification.title'
                        )}
                        text={i18n.t('profile.adminSendPushNotification.text')}
                    />

                    <FieldArray
                        name="messages"
                        render={(arrayHelpers) => (
                            <>
                                <View style={styles.selectLangWrapper}>
                                    <Selector
                                        onPress={open}
                                        text={selectorText}
                                    />
                                </View>
                                {!isLanguagesEmpty &&
                                    formikData.values.messages.map(
                                        (_, index) => {
                                            const showDivider =
                                                values.messages.length > 1 &&
                                                index !==
                                                    values.messages.length - 1;
                                            return (
                                                <View
                                                    key={
                                                        values.messages[index]
                                                            .language
                                                    }>
                                                    <NotificationForm
                                                        valueLanguage={
                                                            values.messages[
                                                                index
                                                            ].language
                                                        }
                                                        valueTitle={
                                                            values.messages[
                                                                index
                                                            ].title
                                                        }
                                                        valueBody={
                                                            values.messages[
                                                                index
                                                            ].body
                                                        }
                                                        onChangeTitle={handleChange(
                                                            `messages[${index}].title`
                                                        )}
                                                        onChangeBody={handleChange(
                                                            `messages[${index}].body`
                                                        )}
                                                        errorTitle={setError(
                                                            index,
                                                            'title'
                                                        )}
                                                        errorBody={setError(
                                                            index,
                                                            'body'
                                                        )}
                                                        onTouchedTitle={() =>
                                                            setOnTouched(
                                                                index,
                                                                'title'
                                                            )
                                                        }
                                                        onTouchedBody={() =>
                                                            setOnTouched(
                                                                index,
                                                                'body'
                                                            )
                                                        }
                                                    />
                                                    {showDivider && (
                                                        <Divider
                                                            key={
                                                                values.messages[
                                                                    index
                                                                ].language
                                                            }
                                                            customStyles={
                                                                styles.divider
                                                            }
                                                        />
                                                    )}
                                                </View>
                                            );
                                        }
                                    )}
                                <SelectLanguageModal
                                    initialMessage={initialMessage}
                                    arrayHelpers={arrayHelpers}
                                    isVisible={isVisible}
                                    onClose={close}
                                    languages={languagesSelected}
                                    updateSelectedLanguages={
                                        updateSelectedLanguages
                                    }
                                    initialValues={values.messages[0]}
                                />
                            </>
                        )}
                    />
                </ScrollView>
                <ButtonsBottom
                    containerStyle={styles.buttons}
                    title={i18n.t('profile.adminSendPushNotification.button')}
                    onPress={submitForm}
                    disabled={!isValid || loading || isLanguagesEmpty}
                    loading={loading}
                />
            </FormikProvider>
        </KeyboardAvoidingView>
    );
};

export default AdminSendPushNotificationScreen;
