import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { scale, verticalScale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        item: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(12),
            backgroundColor: Colors[theme].surface.tertiary,
            marginBottom: verticalScale(12),
            borderColor: Colors[theme].border.primary,
            borderWidth: 1,
            borderRadius: 100,
        },
        itemIndex: {
            marginTop: verticalScale(2),
            ...font({
                size: 13,
                weight: '400',
            }),
            color: Colors[theme].text.secondary,
            marginRight: scale(6),
        },
        itemText: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                weight: '700',
            }),
        },
        itemGap: {
            marginLeft: scale(8),
        },
    });

export default styles;
