import * as React from 'react';
import { ScrollView } from 'react-native';

import ICON from '@assets/icons/emptyState/marketplaceCreateNewOrder.svg';
import Commission from '@assets/icons/marketplace/commission.svg';
import Termination from '@assets/icons/marketplace/termination.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import CardFeatureInfo from '@components/CardFeatureInfo/CardFeatureInfo';
import RankIcon from '@components/Icons/RankIcon';
import { View } from '@components/Themed';
import { useConfig } from '@contexts/ConfigContext';
import { useRanks } from '@contexts/RanksContext';
import { errorsHandler } from '@helpers/errors';
import { useLoadingHook } from '@hooks/useLoadingHook';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import MarketPlaceModalNoPet from '../components/MarketPlaceModalNoPet';
import { useMarketplaceSaleablePets } from '../hooks/useMarketplaceSaleablePets';
import stylesMain from './styles';

const SCREEN = 'MarketPlaceWelcomeCreateOrderScreen';

const MarketPlaceWelcomeCreateOrderScreen = () => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const { isVisible, open, close } = useVisibleHook();
    const { isLoading, startLoading, stopLoading } = useLoadingHook();

    const { getMarketplaceSaleablePets } = useMarketplaceSaleablePets();
    const { config } = useConfig();
    const { ranks } = useRanks();

    const handleNavigate = async () => {
        try {
            startLoading();
            const result = await getMarketplaceSaleablePets();
            if (result.data?.marketplaceSaleablePets.length) {
                navigation.replace(ROUTES.MARKETPLACE_SELECT_NFT);
                stopLoading();
                return;
            }
            open();
            stopLoading();
        } catch (error) {
            stopLoading();
            errorsHandler(error, true);
        }
    };

    const lowestRank = React.useMemo(() => {
        const index = ranks.findIndex(
            (item) => item.slug === config?.marketplaceMinimalRank
        );
        return ranks[index - 1]?.slug || 'free';
    }, [config?.marketplaceMinimalRank, ranks]);

    return (
        <View style={styles.main}>
            <NavigationBar
                filled={false}
                backIcon="leftArrow"
                title={i18n.t(`MarketPlace.${SCREEN}.screenTitle`)}
            />
            <ScrollView
                showsVerticalScrollIndicator={false}
                style={styles.content}>
                <BlockHeader
                    containerStyle={styles.header}
                    emptyStateIcon={<ICON />}
                    type="emptyState"
                    title={i18n.t(`MarketPlace.${SCREEN}.title`)}
                    text={i18n.t(`MarketPlace.${SCREEN}.text`)}
                />
                {!!config?.marketplaceMinimalRank && (
                    <CardFeatureInfo
                        icon={
                            <RankIcon
                                slug={config?.marketplaceMinimalRank}
                                size={48}
                            />
                        }
                        title={i18n.t(
                            `MarketPlace.${SCREEN}.features.rank.title`,
                            {
                                rank: i18n.t(
                                    `ranks.list.${config?.marketplaceMinimalRank}.title`
                                ),
                            }
                        )}
                        text={i18n.t(
                            `MarketPlace.${SCREEN}.features.rank.text`,
                            {
                                lowRank: i18n.t(
                                    `ranks.list.${lowestRank}.title`
                                ),
                            }
                        )}
                        containerStyle={styles.gap}
                    />
                )}
                <CardFeatureInfo
                    icon={<Termination width={48} height={48} />}
                    title={i18n.t(
                        `MarketPlace.${SCREEN}.features.termination.title`,
                        {
                            days: config?.marketplaceTerminationDays || 7,
                        }
                    )}
                    text={i18n.t(
                        `MarketPlace.${SCREEN}.features.termination.text`
                    )}
                    warningText={i18n.t(
                        `MarketPlace.${SCREEN}.features.termination.alert`,
                        {
                            days: config?.marketplaceTerminationDays || 7,
                        }
                    )}
                    containerStyle={styles.gap}
                />
                {!!config?.marketplaceTransactionFeePercentage && (
                    <CardFeatureInfo
                        icon={<Commission width={48} height={48} />}
                        title={i18n.t(
                            `MarketPlace.${SCREEN}.features.commission.title`,
                            {
                                commission:
                                    config.marketplaceTransactionFeePercentage,
                            }
                        )}
                        text={i18n.t(
                            `MarketPlace.${SCREEN}.features.commission.text`,
                            {
                                commission:
                                    config.marketplaceTransactionFeePercentage,
                            }
                        )}
                    />
                )}
            </ScrollView>
            <ButtonsBottom
                title={i18n.t('general.BUTTONS.continue')}
                loading={isLoading}
                disabled={isLoading}
                onPress={handleNavigate}
                safe
            />

            <MarketPlaceModalNoPet isVisible={isVisible} onClose={close} />
        </View>
    );
};

export default MarketPlaceWelcomeCreateOrderScreen;
