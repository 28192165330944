import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        block: {
            flex: 0,
            flexDirection: 'row',
            alignItems: 'center',
            borderRadius: 50,
            paddingVertical: verticalScale(2),
            paddingLeft: scale(6),
            paddingRight: scale(8),
        },
        text: {
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),
            textTransform: 'capitalize',
            marginLeft: verticalScale(4),
        },
        infoIcon: {
            marginLeft: verticalScale(4),
        },
        icon: { width: 16, height: 16, backgroundColor: 'transparent' },
        COMPLETED_BG: {
            backgroundColor: Colors[theme].statutes.confirmed.background,
        },
        COMPLETED_TEXT: {
            color: Colors[theme].statutes.confirmed.text,
        },
        AWAITS_VERIFICATION_BG: {
            backgroundColor: Colors[theme].statutes.pending.background,
        },
        AWAITS_VERIFICATION_TEXT: {
            color: Colors[theme].statutes.pending.text,
        },
        MODERATOR_VERIFICATION_REQUIRED_BG: {
            backgroundColor: Colors[theme].statutes.pending.background,
        },
        MODERATOR_VERIFICATION_REQUIRED_TEXT: {
            color: Colors[theme].statutes.pending.text,
        },
        FAILED_BG: {
            backgroundColor: Colors[theme].statutes.failed.background,
        },
        FAILED_TEXT: {
            color: Colors[theme].statutes.failed.text,
        },
    });

export default styles;
