import { StyleSheet } from 'react-native';

import Colors from '../../../constants/Colors';
import { scale, verticalScale } from '../../../helpers/dimensions';
import font from '../../../helpers/font';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        header: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingTop: verticalScale(16),
            paddingBottom: verticalScale(20),
            backgroundColor: 'transparent',
        },
        headerText: {
            flex: 1,
            textAlign: 'center',
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
            marginRight: scale(32),
        },
        buttonExit: {
            maxHeight: scale(32),
            maxWidth: scale(32),
        },
    });

export default styles;
