import * as React from 'react';
import { FlatList } from 'react-native';

import ButtonsBottom from '@components/ButtonsBottom';
import EmptyList from '@components/EmptyList';
import { ICON_NAMES } from '@components/Icons';
import LoadingComponent from '@components/LoadingComponent';
import { View } from '@components/Themed';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { stylesAlignCenter } from '@helpers/style';
import { useBreedingPets } from '@hooks/useBreedingPets';
import { useLoadingHook } from '@hooks/useLoadingHook';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { BreedingPetParentOutput } from '@models/mysteryGames';
import NavigationBarModal from '@navigation/NavigationBar/NavigationBarModal';
import { useNavigation } from '@navigation/useNavigation';

import NftEvolutionPetItem from '../../components/NftEvolutionPetItem';
import stylesMain from './styles';

const NftEvolutionSelectPetScreen = () => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const { breedingPetsData, getBreedingPets } = useBreedingPets();

    const {
        nftEvolutionCurrentPetForChoose,
        setNftEvolutionFirstPet,
        setNftEvolutionSecondPet,
        nftEvolutionFirstPet,
        nftEvolutionSecondPet,
    } = useMysteryGames();

    const { isLoading, stopLoading } = useLoadingHook(true);

    React.useEffect(() => {
        const load = async () => {
            await getBreedingPets();
            stopLoading();
        };
        load();
    }, []);

    const [currentSelectedPet, setCurrentSelectedPet] = React.useState<
        BreedingPetParentOutput | undefined
    >();
    React.useEffect(() => {
        if (nftEvolutionCurrentPetForChoose === 'first') {
            return setCurrentSelectedPet(nftEvolutionFirstPet);
        }
        setCurrentSelectedPet(nftEvolutionSecondPet);
    }, []);

    const closeScreen = () => {
        navigation.pop();
    };

    const onPress = React.useCallback(
        (pet: BreedingPetParentOutput) => {
            const selectedPet =
                pet.id === currentSelectedPet?.id ? undefined : pet;
            setCurrentSelectedPet(selectedPet);
        },
        [currentSelectedPet]
    );

    const disableSave = React.useMemo(() => {
        if (nftEvolutionCurrentPetForChoose === 'first') {
            return nftEvolutionFirstPet?.id === currentSelectedPet?.id;
        }
        return nftEvolutionSecondPet?.id === currentSelectedPet?.id;
    }, [
        nftEvolutionSecondPet,
        nftEvolutionFirstPet,
        currentSelectedPet,
        nftEvolutionCurrentPetForChoose,
    ]);

    const onSave = React.useCallback(() => {
        if (nftEvolutionCurrentPetForChoose === 'first') {
            navigation.pop();
            setNftEvolutionFirstPet(currentSelectedPet);
            return;
        }
        setNftEvolutionSecondPet(currentSelectedPet);
        navigation.pop();
    }, [nftEvolutionCurrentPetForChoose, currentSelectedPet]);

    const isSelected = React.useCallback(
        (id: string) => {
            return id === currentSelectedPet?.id;
        },
        [currentSelectedPet]
    );

    const filteredPets = React.useMemo(() => {
        let pet: BreedingPetParentOutput | undefined;
        nftEvolutionCurrentPetForChoose === 'first'
            ? (pet = nftEvolutionSecondPet)
            : (pet = nftEvolutionFirstPet);

        return breedingPetsData?.breedingPets?.filter(
            (item) => item.id !== pet?.id
        );
    }, [
        breedingPetsData,
        nftEvolutionCurrentPetForChoose,
        nftEvolutionFirstPet,
        nftEvolutionSecondPet,
    ]);

    const onNewMintPress = () => {
        navigation.pop();
        navigation.navigateToMint();
    };

    return (
        <View style={styles.main}>
            <View style={styles.header}>
                <NavigationBarModal
                    title={i18n.t(
                        'HomeScreen.NftEvolution.NftEvolutionSelectPet.title'
                    )}
                    iconName={ICON_NAMES.CLOSE}
                    onBack={closeScreen}
                />
            </View>

            {isLoading ? (
                <LoadingComponent containerStyles={styles.loading} />
            ) : (
                <View style={styles.listWrapper}>
                    <FlatList
                        showsVerticalScrollIndicator={false}
                        data={filteredPets}
                        initialNumToRender={10}
                        renderItem={({ item }) => (
                            <NftEvolutionPetItem
                                pet={item}
                                isSelected={isSelected(item.id)}
                                onPress={() => onPress(item)}
                            />
                        )}
                        keyExtractor={(item) => item.id}
                        contentContainerStyle={
                            !filteredPets?.length && {
                                ...stylesAlignCenter(),
                            }
                        }
                        ListEmptyComponent={
                            <EmptyList
                                emptyTitle={i18n.t(
                                    'collectionsScreen.noCollectionsYet'
                                )}
                                emptyText={i18n.t(
                                    'collectionsScreen.noCollectionsYetInfo'
                                )}
                                emptyButtonText={i18n.t(
                                    'collectionsScreen.mintNewNft'
                                )}
                                onPressButton={onNewMintPress}
                                withAnimation="cry"
                                tailTitle
                            />
                        }
                    />
                </View>
            )}
            {!!filteredPets?.length && (
                <ButtonsBottom
                    title={i18n.t('general.BUTTONS.select')}
                    onPress={onSave}
                    disabled={disableSave}
                    safe
                />
            )}
        </View>
    );
};
export default NftEvolutionSelectPetScreen;
