import React from 'react';

import Bottle from '@assets/icons/mysteryGames/nftEvolution/bottle.svg';
import Pen from '@assets/icons/mysteryGames/nftEvolution/pen.svg';
import RankIcon from '@components/Icons/RankIcon';
import { View } from '@components/Themed';
import { NFT_EVOLUTION_COLOR } from '@contexts/MysteryGamesContext';
import useThemedStyles from '@hooks/useThemedStyles';

import BreedingCircleItem from '../BreedingCircleItem';
import stylesMain from './styles';

interface IProps {
    rank: string;
}

export const BreedingProcess = ({ rank }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={styles.container}>
            <BreedingCircleItem
                icon={
                    <Bottle
                        width={32}
                        height={32}
                        color={NFT_EVOLUTION_COLOR}
                    />
                }
                isOneTheme
            />

            <View style={styles.dividerWrapper}>
                <View style={styles.divider} />
            </View>
            <BreedingCircleItem
                icon={
                    <Pen width={32} height={32} color={NFT_EVOLUTION_COLOR} />
                }
                isOneTheme
            />

            <View style={styles.dividerWrapper}>
                <View style={styles.divider} />
            </View>
            <BreedingCircleItem
                isOneTheme
                icon={<RankIcon size={32} slug={rank} />}
            />
        </View>
    );
};

export default BreedingProcess;
