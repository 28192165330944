import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import _ from 'lodash';
import RangeSlider from 'rn-range-slider';

import { View } from '../../components/Themed';
import useThemedStyles from '../../hooks/useThemedStyles';
import stylesMain from './styles';

interface IProps {
    onChange: (low: number) => void;
    min: number;
    max: number;
    style?: StyleProp<ViewStyle>;
    percents: number;
    onStartChange?: () => void;
    onEndChange?: () => void;
}

const SeekBar = ({
    onChange = () => {},
    min,
    max,
    style = {},
    percents,
    onStartChange = () => {},
    onEndChange = () => {},
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const renderThumb = () => (
        <View style={styles.shadow}>
            <View style={styles.thumb} />
        </View>
    );

    const renderRail = () => <View style={styles.rail} />;

    const renderRailSelected = () => <View style={styles.railSelected} />;

    return (
        <RangeSlider
            style={[styles.slider, style]}
            min={min}
            max={max}
            step={1}
            low={percents}
            disableRange
            renderThumb={renderThumb}
            renderRail={renderRail}
            renderRailSelected={renderRailSelected}
            onValueChanged={React.useCallback((value) => {
                onChange(value);
            }, [])}
            onTouchStart={onStartChange}
            onTouchEnd={onEndChange}
        />
    );
};

export default SeekBar;
