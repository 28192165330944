import { useState } from 'react';

import { GET_MYSTERY_LIKES_GAME_STATS } from '@Data/Requests';
import { useApolloClient } from '@apollo/client';
import { errorsHandler } from '@helpers/errors';
import { useLoadingHook } from '@hooks/useLoadingHook';
import {
    MysteryLikesGameStats,
    MysteryLikesGameStatsResponse,
} from '@models/mysteryGames';

export const useMysteryLikesData = () => {
    const client = useApolloClient();

    const [mysteryLikesGameStats, setMysteryLikesGameStats] =
        useState<MysteryLikesGameStats>();

    const {
        isLoading: mysteryLikesGameStatsLoading,
        startLoading: startLoadingMysteryLikesGameStats,
        stopLoading: stopLoadingMysteryLikesGameStats,
    } = useLoadingHook(true);

    const {
        isLoading: mysteryLikesGameStatsRefreshLoading,
        startLoading: startMysteryLikesGameStatsRefreshLoading,
        stopLoading: stopMysteryLikesGameStatsRefreshLoading,
    } = useLoadingHook();

    const handleStopLoading = () => {
        stopLoadingMysteryLikesGameStats();
        stopMysteryLikesGameStatsRefreshLoading();
    };

    const getMysteryLikesGameStats = async (silent: boolean) => {
        try {
            silent
                ? startLoadingMysteryLikesGameStats()
                : startMysteryLikesGameStatsRefreshLoading();
            const result = await client.query<MysteryLikesGameStatsResponse>({
                query: GET_MYSTERY_LIKES_GAME_STATS,
                fetchPolicy: 'network-only',
            });
            if (result.data) {
                setMysteryLikesGameStats(result.data.mysteryLikesGameStats);
            }
            handleStopLoading();
        } catch (error) {
            handleStopLoading();
            errorsHandler(error, true);
        }
    };

    return {
        mysteryLikesGameStats,
        mysteryLikesGameStatsLoading,
        mysteryLikesGameStatsRefreshLoading,
        getMysteryLikesGameStats,
    };
};
