import { LOCAL_STORAGE_PRIVATE_KEY } from '@env';
import AsyncStorage from '@react-native-async-storage/async-storage';

const CryptoJS = require('crypto-js');

if (!LOCAL_STORAGE_PRIVATE_KEY) {
    throw '[UniversalStorage] Local Storage private key can not be empty';
}

export default class SecureStore {
    static async getItemAsync(key: string) {
        const hashedKey = hash(key);

        const encryptedValue = await AsyncStorage.getItem(hashedKey);

        if (!encryptedValue) {
            return '';
        }

        return decrypt(encryptedValue);
    }

    static async setItemAsync(key: string, value: string) {
        const hashedKey = hash(key);
        const encryptedValue = encrypt(value);

        return AsyncStorage.setItem(hashedKey, encryptedValue);
    }

    static async deleteItemAsync(key: string) {
        const hashedKey = hash(key);
        return AsyncStorage.removeItem(hashedKey);
    }
}

function hash(text: string) {
    return CryptoJS.SHA3(text).toString();
}

function encrypt(text: string) {
    return CryptoJS.AES.encrypt(text, LOCAL_STORAGE_PRIVATE_KEY).toString();
}

function decrypt(text: string): string {
    try {
        const bytes = CryptoJS.AES.decrypt(text, LOCAL_STORAGE_PRIVATE_KEY);
        return bytes.toString(CryptoJS.enc.Utf8);
    } catch (error) {
        Console.error(
            '[UniversalStorage] Unable to decrypt value with a key',
            error,
            text
        );
        return '';
    }
}
