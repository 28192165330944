export const ReferralCardBackgrounds = [
    {
        color: '#9683FC',
        opacity: 'rgba(150, 131, 252, 0.15)',
        id: '9683FC',
    },
    {
        color: '#C086EA',
        opacity: 'rgba(192, 134, 234, 0.15)',
        id: 'C086EA',
    },
    {
        color: '#F08975',
        opacity: 'rgba(240, 137, 117, 0.15)',
        id: 'F08975',
    },
    {
        color: '#8099FF',
        opacity: 'rgba(28, 153, 255, 0.15)',
        id: '8099FF',
    },
    {
        color: '#6BC79B',
        opacity: 'rgba(107, 199, 155, 0.15)',
        id: '6BC79B',
    },
    {
        color: '#DD888C',
        opacity: 'rgba(221, 136, 140, 0.15)',
        id: 'DD888C',
    },
    {
        color: '#A1BF69',
        opacity: 'rgba(161, 191, 105, 0.15)',
        id: 'A1BF69',
    },
    {
        color: '#D8BA6F',
        opacity: 'rgba(216, 186, 111, 0.15)',
        id: 'D8BA6F',
    },
    {
        color: '#D5A881',
        opacity: 'rgba(213, 168, 129, 0.15)',
        id: 'D5A881',
    },
    {
        color: '#CD9898',
        opacity: 'rgba(205, 152, 152, 0.15)',
        id: 'CD9898',
    },
];
