import * as React from 'react';

import Icon from '@assets/icons/pvpBonus/pvpBonus.svg';
import Button from '@components/Button';
import { Text, View } from '@components/Themed';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    iguAmountWei: string | undefined;
    onPress: () => void;
}

const PvpBonusButton = ({ iguAmountWei, onPress }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { priceInUsd } = useWallet();
    const amount = React.useMemo(
        () =>
            iguAmountWei && iguAmountWei !== '0'
                ? `≈ ${priceInUsd(
                      Coin.igu,
                      balanceFromWei(iguAmountWei).value
                  )} $`
                : i18n.t('pvpBonus.cardButton'),
        [priceInUsd, iguAmountWei]
    );

    return (
        <Button
            isOneColor
            onPress={onPress}
            size="sm"
            type="outline"
            debouncedPress
            containerStyle={styles.container}
            style={styles.button}>
            <View style={styles.content}>
                <Icon width={20} height={20} />
                <Text style={styles.text}>{amount}</Text>
            </View>
        </Button>
    );
};
export default PvpBonusButton;
