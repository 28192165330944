import * as React from 'react';

import UsersIcon from '@assets/icons/app/users.svg';
import Animation from '@components/Animation';
import Button from '@components/Button';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT, TYPO_TEXT_COLOR } from '@components/TypoText/types';
import { LottieAssets } from '@contexts/AppLoading/types';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { IAllGamesData } from '@models/mysteryGames';

import stylesMain from './styles';

interface IProps {
    item: IAllGamesData;
    usersText?: string;
    loading?: boolean;
    onButtonAction?: () => void;
    buttonDisabled?: boolean;
    buttonLoading?: boolean;
    isComingSoon?: boolean;
    width: number;
    getHeight: (height: number) => void;
    minHeight: number;
    continuePlay?: boolean;
    showUsers?: boolean;
}

const GameBannerBig = ({
    item,
    usersText,
    onButtonAction,
    buttonDisabled,
    buttonLoading,
    isComingSoon,
    width,
    getHeight,
    minHeight,
    continuePlay,
    showUsers = false,
}: IProps) => {
    const { color: backgroundColor, id } = item;

    const styles = useThemedStyles(stylesMain);

    const buttonTitle = isComingSoon
        ? i18n.t('HomeScreen.GamesBlock.buttonComingSoon')
        : continuePlay
        ? i18n.t('HomeScreen.GamesBlock.buttonContinue')
        : i18n.t('HomeScreen.GamesBlock.button');

    return (
        <View
            onLayout={({ nativeEvent }) => {
                getHeight(nativeEvent.layout.height);
            }}
            style={[
                styles.container,
                {
                    backgroundColor,
                    width,
                    minHeight,
                },
            ]}>
            <View style={styles.textBlock}>
                <View style={styles.left}>
                    <TypoText
                        allowFontScaling={false}
                        type={TYPO_TEXT.HEADING_H6}
                        colorType={TYPO_TEXT_COLOR.ON_DARK}
                        additionalStyles={styles.textGap}
                        numberOfLines={1}>
                        {i18n.t(`HomeScreen.GamesBlock.games.${id}.title`)}
                    </TypoText>
                    <TypoText
                        allowFontScaling={false}
                        type={TYPO_TEXT.BODY_SMALL}
                        colorType={TYPO_TEXT_COLOR.ON_DARK}
                        numberOfLines={2}>
                        {i18n.t(`HomeScreen.GamesBlock.games.${id}.text`)}
                    </TypoText>
                </View>
                {showUsers && !!usersText && (
                    <View style={styles.ordersBlock}>
                        <View style={styles.block}>
                            <UsersIcon width={18} height={18} />
                            <TypoText
                                allowFontScaling={false}
                                type={TYPO_TEXT.SUBHEAD_LITTLE_BOLD}
                                colorType={TYPO_TEXT_COLOR.ON_DARK}
                                numberOfLines={1}
                                additionalStyles={styles.iconGap}>
                                {usersText}
                            </TypoText>
                        </View>
                    </View>
                )}
            </View>
            <View style={styles.buttonWrapper}>
                <Button
                    isOneColor
                    debouncedPress
                    disabled={buttonDisabled}
                    loading={buttonLoading}
                    type="outline"
                    title={buttonTitle}
                    size="md"
                    onPress={onButtonAction}
                />
            </View>

            <View style={styles.icon}>
                <Animation animation={id as keyof LottieAssets} />
            </View>
        </View>
    );
};
export default GameBannerBig;
