import * as React from 'react';
import { useMemo } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import Colors from '../../constants/Colors';
import { useConfig } from '../../contexts/ConfigContext';
import { useTheme } from '../../contexts/ThemeContext';
import useThemedStyles from '../../hooks/useThemedStyles';
import Icon, { ICON_NAMES } from '../Icons';
import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    energy: number;
    maxEnergy?: number;
    containerStyle?: StyleProp<ViewStyle>;
}

const PlayEnergy = ({ energy, maxEnergy = 100, containerStyle }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { theme } = useTheme();
    const { config } = useConfig();

    const thresholdEnergy = useMemo(() => {
        if (!config?.rewardEnergyThreshold) {
            return 0;
        }

        return (maxEnergy / 100) * config?.rewardEnergyThreshold;
    }, [maxEnergy, config?.rewardEnergyThreshold]);

    const progressColor = React.useMemo(() => {
        // empty config
        if (!thresholdEnergy || !energy) {
            return Colors[theme].energyProgress.low;
        }

        // low
        if (energy < thresholdEnergy / 2) {
            return Colors[theme].energyProgress.low;
        }

        // below threshold
        if (energy < thresholdEnergy) {
            return Colors[theme].energyProgress.high;
        }

        // full threshold
        return Colors[theme].energyProgress.full;
    }, [energy, thresholdEnergy]);

    return (
        <View style={[styles.container, containerStyle]}>
            <Icon name={ICON_NAMES.ENERGY} size={24} />
            <View style={styles.progress}>
                <View
                    style={[
                        styles.fill,
                        {
                            width: `${(energy / maxEnergy) * 100}%`,
                            backgroundColor: progressColor,
                        },
                    ]}></View>
            </View>
            <Text style={styles.energy}>{`${energy} / ${maxEnergy}`}</Text>
        </View>
    );
};
export default PlayEnergy;
