import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { FlatList, TouchableOpacity } from 'react-native';

import { useStorePurchase } from '@contexts/StorePurchaseContext';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { isIOS, isWeb } from '@helpers/app';
import { isPaymentOptionInAppPurchase } from '@helpers/payments';
import { balanceFromWei } from '@helpers/wallet';

import { PaymentOptionOutput, UserBalance } from '../../../Data/Models';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import CoinIcon from '../../Icons/CoinIcon';
import { Text, View } from '../../Themed';
import stylesMain from './styles';

interface IProps {
    onCoinClick: (token: string) => void;
    selectedPayment: PaymentOptionOutput;
    payments: PaymentOptionOutput[];
    userBalance: UserBalance | undefined;
}
interface IRenderItem {
    item: PaymentOptionOutput;
    index: number;
}

const PurchasingFromCoins = ({
    onCoinClick,
    selectedPayment,
    payments,
    userBalance,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { getCoinBalances } = useWallet();
    const { inAppPurchasesAvailable, petMintProducts } = useStorePurchase();

    const renderItem = useCallback(
        ({ item, index }: IRenderItem) => {
            const isSelected = item.token === selectedPayment?.token;
            const isInAppPurchase = isPaymentOptionInAppPurchase(item);

            const coinBalance = getCoinBalances().find(
                (value) => value.name === item.token
            );

            const currentBalance = item.tokenAddress
                ? coinBalance?.presentationValueLong || ''
                : userBalance?.total
                ? balanceFromWei(userBalance?.total).valueLong
                : '';

            return (
                <TouchableOpacity
                    activeOpacity={0.8}
                    style={[
                        styles.shadow,
                        isSelected && styles.selectedShadow,
                        index + 1 !== payments.length && styles.gap,
                    ]}
                    onPress={() => onCoinClick(item.token)}>
                    <View
                        style={[
                            styles.itemContainer,
                            isSelected && styles.selected,
                        ]}>
                        <View style={styles.coin}>
                            <CoinIcon slug={item.token} size={32} />
                            {isInAppPurchase ? (
                                <Text style={styles.coinTitle}>
                                    {i18n.t(
                                        `checkout.purchasingFromCoins.inapp.${
                                            isIOS ? 'ios' : 'android'
                                        }`
                                    )}
                                </Text>
                            ) : (
                                <Text style={styles.coinTitle}>
                                    {item.token}
                                </Text>
                            )}
                        </View>
                        {!isInAppPurchase && (
                            <Text style={styles.coinAmount}>
                                {currentBalance}
                            </Text>
                        )}
                    </View>
                </TouchableOpacity>
            );
        },
        [userBalance]
    );

    const tokens = payments.filter((value) => value.tokenAddress);
    const virtualTokens = payments.filter(
        (value) => !value.tokenAddress && !isPaymentOptionInAppPurchase(value)
    );
    const inAppPurchase = payments.find(
        (value) => isPaymentOptionInAppPurchase(value) && !isWeb
    );

    return (
        <>
            {inAppPurchasesAvailable && inAppPurchase && petMintProducts && (
                <View>
                    <FlatList
                        scrollEnabled={false}
                        style={styles.listStyle}
                        data={[inAppPurchase]}
                        renderItem={renderItem}
                    />
                </View>
            )}
            <View>
                <Text style={styles.labelWallet}>
                    {i18n.t('checkout.purchasingFromCoins.coins')}
                </Text>
                <FlatList
                    scrollEnabled={false}
                    style={styles.listStyle}
                    data={tokens}
                    renderItem={renderItem}
                />
            </View>
            <View>
                {!!virtualTokens.length && (
                    <Text style={styles.labelWallet}>
                        {i18n.t('checkout.purchasingFromCoins.virtual')}
                    </Text>
                )}
                <FlatList
                    scrollEnabled={false}
                    style={styles.listStyle}
                    data={virtualTokens}
                    renderItem={renderItem}
                />
            </View>
        </>
    );
};
export default PurchasingFromCoins;
