import { StyleSheet } from 'react-native';

import Colors from '../../../../../../constants/Colors';
import { scale, verticalScale } from '../../../../../../helpers/dimensions';
import font from '../../../../../../helpers/font';
import { theme } from '../../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        navBg: {
            padding: scale(20),
            justifyContent: 'space-between',
            backgroundColor: Colors[theme].background,
        },
        energyBlock: {
            borderRadius: 50,
            flexDirection: 'row',
            alignItems: 'center',
            paddingLeft: scale(12),
            paddingRight: scale(8),
            marginLeft: scale(-20),
            paddingVertical: verticalScale(6),
            backgroundColor: Colors[theme].backgroundEnergy,
        },
        energyCost: {
            color: Colors[theme].text.warning,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
            marginHorizontal: scale(3),
        },
    });

export default styles;
