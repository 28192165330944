import { StyleSheet } from 'react-native';

import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        image: {
            borderRadius: 6,
            backgroundColor: 'transparent',
        },
    });

export default styles;
