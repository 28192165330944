import * as React from 'react';
import { FlatList, ScrollView } from 'react-native';

import { useQuery } from '@apollo/client';
import Button from '@components/Button';
import { ICON_NAMES } from '@components/Icons';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT } from '@components/TypoText/types';
import {
    LUCKY_STRIKE_LOST_COLOR,
    LUCKY_STRIKE_WON_COLOR,
} from '@contexts/MysteryGamesContext';
import { DECLINATIONS, getDeclination } from '@helpers/declination';
import { errorsHandler } from '@helpers/errors';
import { toastComingSoon } from '@helpers/toastNotification';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import {
    LUCKY_STRIKE_GAMES_STATUS,
    LuckyStrikeGameHistoryOutput,
    LuckyStrikeGameParticipantsOutputResponse,
    MYSTERY_GAMES,
} from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { GET_LUCKY_STRIKE_GAME_PARTICIPANTS } from '@requests/luckyStrike';

import { useLuckyStrikeAvailableGames } from '../../hooks/useLuckyStrikeAvailableGames';
import useSecureHashModal from '../../hooks/useSecureHashModal';
import GamesHistoryButtons from '../GamesHistoryButtons';
import LuckyStrikeAllParticipantsComponent from '../LuckyStrikeAllParticipantsComponent';
import LuckyStrikeGameHistoryInfo from '../LuckyStrikeGameHistoryInfo';
import LuckyStrikeHistoryBlockHeader from '../LuckyStrikeHistoryBlockHeader';
import stylesMain from './styles';

interface IProps {
    history: LuckyStrikeGameHistoryOutput;
    force?: boolean | undefined;
    id: string;
}

const LuckyStrikeHistoryWrapper = ({ history, force, id }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const navigateToGame = () => {
        navigation.navigate(ROUTES.LUCKY_STRIKE_MAIN);
    };
    const backToHistory = () => {
        navigation.navigate(ROUTES.LUCKY_STRIKE_HISTORY);
    };

    const { getGames, gamesLoading } = useLuckyStrikeAvailableGames();

    const retry = async () => {
        try {
            const games = await getGames(true);
            if (games) {
                const find = games.find((i) => i.duration === history.duration);
                if (find) {
                    navigation.navigate(ROUTES.LUCKY_STRIKE_MAIN, {
                        game: find,
                    });
                }
            }
        } catch (error) {
            errorsHandler(error, true);
        }
    };

    const { isUserWinner, iguAmountWei, userTicketNumbers } = history;

    const { isVisible, close } = useVisibleHook(true);

    const status = React.useMemo(
        () =>
            isUserWinner
                ? LUCKY_STRIKE_GAMES_STATUS.Won
                : LUCKY_STRIKE_GAMES_STATUS.Lost,
        [isUserWinner]
    );

    const handleNavigateToTickets = () => {
        navigation.push(ROUTES.LUCKY_STRIKE_USER_TICKETS, {
            id,
        });
    };

    const { renderSecureHashModal, openSecureHashModal } = useSecureHashModal();

    const ticketsButton = () => {
        const color =
            status === LUCKY_STRIKE_GAMES_STATUS.Won
                ? LUCKY_STRIKE_WON_COLOR
                : LUCKY_STRIKE_LOST_COLOR;

        const isOneTicket = userTicketNumbers.length === 1;
        const buttonTitle = isOneTicket
            ? `#${userTicketNumbers[0]}`
            : `${userTicketNumbers.length} ${getDeclination(
                  userTicketNumbers.length,
                  DECLINATIONS.Ticket
              )}`;

        const buttonIcon = isOneTicket
            ? ICON_NAMES.QUESTION
            : ICON_NAMES.ARROW_RIGHT_SECONDARY;

        const onButtonPress = () => {
            if (isOneTicket) {
                return openSecureHashModal();
            }
            return handleNavigateToTickets();
        };

        return (
            <Button
                type="outline"
                isOneColor
                size="md"
                title={buttonTitle}
                iconName={buttonIcon}
                textStyles={{ color }}
                iconDirection="right"
                iconSize={24}
                onPress={onButtonPress}
                containerStyle={styles.button}
                iconColor={color}
                customIconStyles={styles.icon}
                disabled={gamesLoading}
            />
        );
    };

    const renderFirstState = () => {
        if (force) {
            return null;
        }

        return (
            <View style={[styles.firstState, styles[status]]}>
                <View style={styles.block}>
                    <LuckyStrikeHistoryBlockHeader
                        status={status}
                        iguAmountWei={iguAmountWei}
                        isBigHeader
                        componentBetweenImageAndText={ticketsButton()}
                    />
                </View>
                <GamesHistoryButtons
                    loading={gamesLoading}
                    firstButtonAction={!isUserWinner ? retry : navigateToGame}
                    firstButtonTitle={
                        !isUserWinner
                            ? i18n.t('general.BUTTONS.tryAgain')
                            : i18n.t('general.BUTTONS.exploreMoreGames')
                    }
                    secondButtonTitle={i18n.t('general.BUTTONS.viewStatistics')}
                    secondButtonAction={close}
                    firstButtonType="outline"
                    isOneTheme
                    white
                />
            </View>
        );
    };

    return (
        <View style={styles.safe}>
            {isVisible && renderFirstState()}
            <View style={styles.main}>
                <FlatList
                    showsVerticalScrollIndicator={false}
                    style={styles.container}
                    ListHeaderComponent={
                        <>
                            <LuckyStrikeHistoryBlockHeader
                                status={status}
                                iguAmountWei={iguAmountWei}
                            />
                            <LuckyStrikeGameHistoryInfo
                                containerStyle={styles.gap}
                                data={history}
                                gameId={id}
                            />
                            <LuckyStrikeAllParticipantsComponent
                                gameId={id}
                                containerStyle={styles.list}
                                elementsToLoad={10}
                            />
                        </>
                    }
                    data={null}
                    renderItem={null}
                />
            </View>
            <GamesHistoryButtons
                firstButtonAction={navigateToGame}
                secondButtonAction={backToHistory}
            />
            {renderSecureHashModal()}
        </View>
    );
};

export default LuckyStrikeHistoryWrapper;
