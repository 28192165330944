import { StyleSheet } from 'react-native';

import { scale } from '@helpers/dimensions';

import Colors from '../../constants/Colors';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        badgeText: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),
            marginLeft: scale(4),
        },
        badgeWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    });

export default styles;
