import * as React from 'react';

import { useLazyQuery } from '@apollo/client';
import { ICON_NAMES } from '@components/Icons';
import ModalConfirmTransaction from '@components/ModalConfirmTransaction';
import TransactionSummary from '@components/TransactionSummary';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { priceString } from '@helpers/payments';
import { feeWithCurrency } from '@helpers/wallet';
import i18n from '@i18n/i18n';
import {
    IguUsdtExchangeRateResponse,
    MYSTERY_GAMES,
    TOKEN_TELLER_GAME_PREDICTION_DIRECTION,
} from '@models/mysteryGames';
import { GET_IGU_USDT_EXCHANGE_RATE } from '@requests/tokenTeller';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
    onConfirm: (direction: TOKEN_TELLER_GAME_PREDICTION_DIRECTION) => void;
    amount: string;
    game: MYSTERY_GAMES;
    disabled?: boolean;
    direction: TOKEN_TELLER_GAME_PREDICTION_DIRECTION;
    totalSummary: string;
    commission: string;
    isAddTokens: boolean;
    isEnoughBalance: boolean;
    onTopUp: () => void;
}

const GAME = MYSTERY_GAMES.tokenteller;

const TokenTellerConfirmationModal = ({
    isVisible,
    onClose,
    onConfirm,
    amount,
    game,
    direction,
    disabled,
    commission,
    totalSummary,
    isAddTokens,
    isEnoughBalance,
    onTopUp,
}: IProps) => {
    const { tokenTellerConfig } = useMysteryGames();

    const [getRates, { data, stopPolling }] =
        useLazyQuery<IguUsdtExchangeRateResponse>(GET_IGU_USDT_EXCHANGE_RATE, {
            fetchPolicy: 'no-cache',
            pollInterval: 5000,
        });
    React.useEffect(() => {
        if (isVisible && !isAddTokens) {
            getRates();
        }
        if (!isVisible) {
            stopPolling();
        }
    }, [isVisible, isAddTokens]);
    const position = React.useMemo(() => {
        if (!data?.exchangeRates.iguusdt) {
            return i18n.t(`HomeScreen.${GAME}.${direction}`);
        }
        return `${data.exchangeRates.iguusdt.slice(0, 10)} / ${i18n.t(
            `HomeScreen.${GAME}.${direction}`
        )}`;
    }, [data?.exchangeRates.iguusdt]);

    const handleTopUp = () => {
        onClose();
        onTopUp();
    };

    const transactionDetailsValues = [
        {
            key: i18n.t('general.KEYS.type'),
            value: i18n.t(`HomeScreen.GamesBlock.games.${game}.title`),
        },
        {
            key: i18n.t('checkout.fields.from'),
            value: i18n.t('checkout.fields.gamingWallet'),
        },
        !isAddTokens
            ? {
                  key: i18n.t(
                      `HomeScreen.${GAME}.TokenTellerGameHistoryInfoItem.prediction`
                  ),
                  value: position,
              }
            : undefined,

        {
            key: i18n.t('checkout.fields.amount'),
            value: priceString(amount, Coin.vigu),
        },
        {
            key: `${i18n.t('checkout.fields.commissionFee')} (${
                tokenTellerConfig?.commisionFeePercentage || 0
            }%)`,
            value: feeWithCurrency(commission, Coin.vigu),
        },
        {
            key: i18n.t('checkout.fields.totalSummary'),
            value: priceString(totalSummary, Coin.vigu),
        },
    ];

    return (
        <ModalConfirmTransaction
            title={i18n.t('checkout.fields.modalTitle')}
            isVisible={isVisible}
            close={onClose}
            onConfirm={() => onConfirm(direction)}
            onCancel={onClose}
            disabled={disabled || !isEnoughBalance}
            textWarning={
                !isEnoughBalance
                    ? i18n.t(`general.ALERTS.notEnoughCoinsOnGamingWallet`)
                    : undefined
            }
            alertType="error"
            handleActionAlertPress={handleTopUp}
            actionButtonIcon={ICON_NAMES.DEPOSIT}
            actionButtonType={'fill'}
            TransactionDetailsComponent={
                <TransactionSummary values={transactionDetailsValues} />
            }
            modalHeight={(transactionDetailsValues.length + 1) * 100}
        />
    );
};
export default TokenTellerConfirmationModal;
