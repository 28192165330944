import { StyleSheet } from 'react-native';

import { MYSTERY_LIKES_WAIT_COLOR } from '@contexts/MysteryGamesContext';
import { scale } from '@helpers/dimensions';
import { stylesAlignCenter } from '@helpers/style';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
            backgroundColor: MYSTERY_LIKES_WAIT_COLOR,
        },
        block: {
            ...stylesAlignCenter(),
            backgroundColor: 'transparent',
            paddingHorizontal: scale(20),
        },
    });

export default styles;
