import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { scale, verticalScale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        imageWrapper: {
            width: scale(32),
            height: scale(32),
        },
        image: {
            width: '100%',
            height: '100%',
            borderRadius: 6,
        },
        title: {
            marginLeft: verticalScale(8),
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                weight: '600',
            }),
        },
    });

export default styles;
