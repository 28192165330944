import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import FlowStepIndicator, {
    IStageState,
    STEP_INDICATOR_FLOWS,
} from '../../../../components/FlowStepIndicator';
import { MINT_PET_STAGES_NAME } from '../../../../components/FlowStepIndicator/flows/mintNFTStages';
import { View } from '../../../../components/Themed';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import NavigationBar from '../../../../navigation/NavigationBar';
import stylesMain from './styles';

interface IProps {
    icon?: 'close' | 'leftArrow' | undefined;
    flow: STEP_INDICATOR_FLOWS;
    stage: MINT_PET_STAGES_NAME;
    stageFill?: IStageState | undefined;
    children: React.ReactNode;
    containerStyle?: StyleProp<ViewStyle>;
    flowStyle?: StyleProp<ViewStyle>;
    onBack?: () => void;
}

const MintContainer = ({
    icon,
    stageFill,
    flow,
    stage,
    children,
    containerStyle,
    flowStyle,
    onBack,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <SafeAreaView style={styles.safe} edges={['bottom']}>
            <View style={[styles.container, containerStyle]}>
                <NavigationBar backIcon={icon} wallet onBack={onBack} />
                <FlowStepIndicator
                    flow={flow}
                    stage={stage}
                    stageFill={stageFill}
                    containerStyle={flowStyle}
                />
                {children}
            </View>
        </SafeAreaView>
    );
};
export default MintContainer;
