import * as React from 'react';
import { Gesture } from 'react-native-gesture-handler';
import {
    Extrapolation,
    interpolate,
    useAnimatedStyle,
    useSharedValue,
    withSpring,
} from 'react-native-reanimated';

import { isWeb } from '@helpers/app';

import PET_IMAGE from '../constants/PetImage';
import { IImageSize } from '../screens/MintNFT/types';

interface IProps {
    savedScale: number | undefined;
    savedTopPosition: number | undefined;
    savedLeftPosition: number | undefined;
}

export const useImageDraggable = ({
    savedScale,
    savedTopPosition,
    savedLeftPosition,
}: IProps) => {
    const [imageBackgroundSize, setImageBackgroundSize] = React.useState({
        width: 0,
        height: 0,
    });
    const [petImageSize, setPetImageSize] = React.useState({
        width: 0,
        height: 0,
    });

    const { width: backgroundWidth, height: backgroundHeight } =
        imageBackgroundSize;

    const currentScale = useSharedValue(savedScale || 1);

    const translateX = useSharedValue(0);
    const translateY = useSharedValue(0);

    const offset = useSharedValue({
        x: savedLeftPosition || 0,
        y: savedTopPosition || 0,
    });
    const start = useSharedValue({
        x: savedLeftPosition || 0,
        y: savedTopPosition || 0,
    });

    const calculateScaleSpace = (
        imageSize: number,
        defaultImageSize: number
    ) => {
        'worklet';
        return Math.round((imageSize - defaultImageSize) / 2);
    };

    const calculateScaledSize = (defaultSize: number, scaleAmount: number) => {
        'worklet';
        return Math.round(defaultSize * scaleAmount);
    };

    const calculateMovedRegionStart = (
        imageSize: number,
        defaultImageSize: number,
        defaultPosition: number
    ) => {
        'worklet';
        const scaleSpace = calculateScaleSpace(imageSize, defaultImageSize);
        const moveOutSpace = Math.round(
            imageSize * PET_IMAGE.MOVED_OUT_CONTAINER
        );

        return scaleSpace - moveOutSpace - defaultPosition;
    };

    const calculateMovedRegionEnd = (
        containerSize: number,
        imageSize: number,
        defaultImageSize: number,
        defaultPosition: number
    ) => {
        'worklet';
        const scaleSpace = calculateScaleSpace(imageSize, defaultImageSize);
        const moveOutSpace =
            imageSize - Math.round(imageSize * PET_IMAGE.MOVED_OUT_CONTAINER);

        return containerSize - moveOutSpace + scaleSpace - defaultPosition;
    };

    const calculateSizeDifference = (
        defaultSize: number,
        currentSize: number
    ) => {
        return defaultSize / currentSize;
    };

    const calculateCenterOfImage = (imageSide: number) => {
        return Math.round(imageSide / 2);
    };

    const calculateCurrentPosition = (
        startPosition: number,
        translate: number,
        spaces: number
    ) => {
        return startPosition + translate - spaces;
    };

    const calculatePositionToSend = (
        positionCurrent: number,
        sizeDifference: number
    ) => {
        return Math.round(positionCurrent * sizeDifference);
    };

    const calculateImagePositionCenter = (view: number, img: number) => {
        return calculateCenterOfImage(view) - calculateCenterOfImage(img);
    };

    const imageStartPositionTop = React.useMemo(
        () =>
            calculateImagePositionCenter(backgroundHeight, petImageSize.height),
        [backgroundHeight, petImageSize]
    );
    const imageStartPositionLeft = React.useMemo(
        () => calculateImagePositionCenter(backgroundWidth, petImageSize.width),
        [backgroundWidth, petImageSize]
    );

    const calculateInputRangeX = () => {
        'worklet';
        const imgScaledWidth = calculateScaledSize(
            petImageSize.width,
            currentScale.value
        );
        const leftMovedOut = calculateMovedRegionEnd(
            backgroundWidth,
            imgScaledWidth,
            petImageSize.width,
            imageStartPositionLeft
        );

        const rightMovedOut = calculateMovedRegionStart(
            imgScaledWidth,
            petImageSize.width,
            imageStartPositionLeft
        );

        const start = leftMovedOut > rightMovedOut ? rightMovedOut : 0;
        const end = leftMovedOut > rightMovedOut ? leftMovedOut : 0;

        return [start, end];
    };

    const calculateInputRangeY = () => {
        'worklet';
        const imgScaledHeight = calculateScaledSize(
            petImageSize.height,
            currentScale.value
        );

        const rightMovedOut = calculateMovedRegionStart(
            imgScaledHeight,
            petImageSize.height,
            imageStartPositionTop
        );

        const leftMovedOut = calculateMovedRegionEnd(
            backgroundHeight,
            imgScaledHeight,
            petImageSize.height,
            imageStartPositionTop
        );

        const start = leftMovedOut > rightMovedOut ? rightMovedOut : 0;
        const end = leftMovedOut > rightMovedOut ? leftMovedOut : 0;

        return [start, end];
    };

    const zoomPlus = () => {
        'worklet';
        currentScale.value += PET_IMAGE.SCALE_STEP;
        if (currentScale.value >= PET_IMAGE.MAX_SCALE) {
            currentScale.value = PET_IMAGE.MAX_SCALE;
        }
    };
    const zoomMinus = () => {
        'worklet';

        currentScale.value -= PET_IMAGE.SCALE_STEP;
        if (currentScale.value <= PET_IMAGE.MIN_SCALE) {
            currentScale.value = PET_IMAGE.MIN_SCALE;
        }
        Console.info(currentScale.value);
    };

    const calculateWidthToSend = (
        sizeDifference: number,
        width: number,
        bgImageDefault: IImageSize
    ) => Math.round(width * sizeDifference);

    const calculateHeightToSend = (
        sizeDifference: number,
        height: number,
        bgImageDefault: IImageSize
    ) => Math.round(height * sizeDifference);

    const animatedStyles = useAnimatedStyle(() => {
        const rangeX = calculateInputRangeX();
        const rangeY = calculateInputRangeY();

        const interpolateX = interpolate(
            offset.value.x,
            rangeX,
            rangeX,
            Extrapolation.CLAMP
        );
        translateX.value = interpolateX;

        const interpolateY = interpolate(
            offset.value.y,
            rangeY,
            rangeY,
            Extrapolation.CLAMP
        );
        translateY.value = interpolateY;

        return {
            transform: [
                { translateX: interpolateX },
                { translateY: interpolateY },
                {
                    scale: isWeb
                        ? currentScale.value
                        : withSpring(currentScale.value),
                },
            ],
        };
    });

    const calculateImageDataToServer = (bgImageDefault: IImageSize) => {
        const imageSizeHeight = calculateScaledSize(
            petImageSize.height,
            currentScale.value
        );
        const scaleSpaceTop = calculateScaleSpace(
            imageSizeHeight,
            petImageSize.height
        );

        const imageSizeWidth = calculateScaledSize(
            petImageSize.width,
            currentScale.value
        );

        const scaleSpaceLeft = calculateScaleSpace(
            imageSizeWidth,
            petImageSize.width
        );

        const sizeDifferenceWidth = calculateSizeDifference(
            bgImageDefault.width,
            backgroundWidth
        );

        const sizeDifferenceHeight = calculateSizeDifference(
            bgImageDefault.height,
            backgroundHeight
        );

        const topPositionCurrent = calculateCurrentPosition(
            imageStartPositionTop,
            translateY.value,
            scaleSpaceTop
        );

        const leftPositionCurrent = calculateCurrentPosition(
            imageStartPositionLeft,
            translateX.value,
            scaleSpaceLeft
        );

        const topPositionToSend = calculatePositionToSend(
            topPositionCurrent,
            sizeDifferenceHeight
        );

        const leftPositionToSend = calculatePositionToSend(
            leftPositionCurrent,
            sizeDifferenceWidth
        );

        const widthToSend = calculateWidthToSend(
            sizeDifferenceWidth,
            imageSizeWidth,
            bgImageDefault
        );

        const heightToSend = calculateHeightToSend(
            sizeDifferenceHeight,
            imageSizeHeight,
            bgImageDefault
        );

        return {
            top: topPositionToSend,
            left: leftPositionToSend,
            height: widthToSend,
            width: heightToSend,
        };
    };
    const calculateEditPhotoSavedSize = () => {
        return {
            top: offset.value.y,
            left: offset.value.x,
            scale: currentScale.value,
            height: petImageSize.height,
            width: petImageSize.width,
        };
    };

    const gesturePan = Gesture.Pan()
        .onUpdate((event) => {
            offset.value = {
                x: event.translationX + start.value.x,
                y: event.translationY + start.value.y,
            };
        })
        .onEnd(() => {
            const rangeX = calculateInputRangeX();
            const rangeY = calculateInputRangeY();
            start.value = {
                x: Math.min(rangeX[1], Math.max(offset.value.x, rangeX[0])),
                y: Math.min(rangeY[1], Math.max(offset.value.y, rangeY[0])),
            };
        });

    const gesturePinch = Gesture.Pinch()
        .onUpdate((event) => {
            currentScale.value = event.scale;
        })
        .onEnd((event) => {
            currentScale.value = Math.max(
                Math.min(event.scale, PET_IMAGE.MAX_SCALE),
                PET_IMAGE.MIN_SCALE
            );
        });

    const gesture = Gesture.Simultaneous(gesturePan, gesturePinch);
    return {
        gesture,
        gesturePan,
        gesturePinch,
        calculateImageDataToServer,
        animatedStyles,
        imageDefaultStyles: {
            width: petImageSize.width,
            height: petImageSize.height,
            top: imageStartPositionTop,
            left: imageStartPositionLeft,
        },
        setImageBackgroundSize,
        setPetImageSize,
        imageBackgroundSize,
        petImageSize,
        zoomPlus,
        zoomMinus,
        calculateEditPhotoSavedSize,
    };
};
