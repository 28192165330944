import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { scale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            alignItems: 'center',
            flexDirection: 'row',
            width: '100%',
        },
        progress: {
            backgroundColor: Colors[theme].energyProgress.background,
            borderRadius: 1000,
            height: 12,
            flex: 1,
            marginHorizontal: scale(8),
        },
        energy: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '600',
            }),
        },
        fill: {
            borderRadius: 1000,
            height: 12,
        },
    });

export default styles;
