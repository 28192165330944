import * as React from 'react';

import MoveToEarnProgressScreen from '../../screens/TabBar/Play/MoveToEarn/Progress/ProgressScreen';
import MoveToEarnWelcomeScreen from '../../screens/TabBar/Play/MoveToEarn/Welcome/WelcomeScreen';
import ROUTES from '../routes';

const MoveToEarnGroup = (RootStack: any) => {
    return (
        <>
            <RootStack.Screen
                name={ROUTES.MOVE_TO_EARN_WELCOME}
                component={MoveToEarnWelcomeScreen}
                options={{ gestureEnabled: false }}
            />
            <RootStack.Screen
                name={ROUTES.MOVE_TO_EARN_PROGRESS}
                options={{ gestureEnabled: false }}
                component={MoveToEarnProgressScreen}
            />
        </>
    );
};

export default MoveToEarnGroup;
