import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import Icon from '@assets/icons/mysteryGames/LuckyStrike/ticket.svg';
import CardFeatureInfo from '@components/CardFeatureInfo/CardFeatureInfo';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';

interface IProps {
    containerStyle?: StyleProp<ViewStyle>;
    type?: 'default' | 'opacity';
    currentTickets: number;
}

const LuckyStrikeTickets = ({
    containerStyle,
    type = 'default',
    currentTickets,
}: IProps) => {
    const { luckyStrikeConfig } = useMysteryGames();

    const title = React.useMemo(
        () =>
            `${i18n.t('general.KEYS.tickets')} = ${currentTickets}/${
                luckyStrikeConfig?.maxTicketAmount
            }`,
        [currentTickets, luckyStrikeConfig]
    );

    return (
        <CardFeatureInfo
            type={type}
            containerStyle={containerStyle}
            icon={<Icon width={48} height={48} />}
            title={title}
            text={i18n.t(
                `HomeScreen.${MYSTERY_GAMES.luckyStrike}.LuckyStrikePurchaseModal.alert`,
                {
                    maxTickets: luckyStrikeConfig?.maxTicketAmount,
                }
            )}
        />
    );
};
export default LuckyStrikeTickets;
