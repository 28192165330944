import * as React from 'react';

import CustomModal from '@components/CustomModal';
import ImageIguana from '@components/ImageIguana';
import ImageWithName from '@components/ImageWithName';
import ModalConfirmTransaction from '@components/ModalConfirmTransaction';
import ModalLoader from '@components/ModalLoader';
import TransactionSummary from '@components/TransactionSummary';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { errorsHandler } from '@helpers/errors';
import { feeWithCurrency } from '@helpers/wallet';
import useBlockchainErrorsHandler from '@hooks/useBlockchainErrorsHandler';
import useFeeInfo from '@hooks/useFeeInfo';
import { useLoadingHook } from '@hooks/useLoadingHook';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
    petName: string;
    petImg: string;
    cancelTransactionValuesStringified: string;
    shouldPop?: boolean;
}

const DelistNftModal = ({
    isVisible,
    onClose,
    petName,
    petImg,
    cancelTransactionValuesStringified,
    shouldPop,
}: IProps) => {
    const navigation = useNavigation();
    const { walletBalance, fee, delistFromMarketPlace } = useWallet();

    const { renderBlockchainErrorsModal, handleBlockchainErrors } =
        useBlockchainErrorsHandler();

    const estimatedFee = React.useMemo(() => fee?.nftTransfer, [fee]);
    const isBNBEnough = React.useMemo(() => {
        return Number(walletBalance?.bnb.value) >= Number(estimatedFee);
    }, [estimatedFee, walletBalance?.bnb]);
    const {
        isVisible: confirmationModal,
        close: closeConfirmationModal,
        open: openConfirmationModal,
    } = useVisibleHook();
    const { renderFeeInfoIcon } = useFeeInfo(
        closeConfirmationModal,
        openConfirmationModal
    );

    const transactionDetailsValues = [
        {
            key: i18n.t('checkout.fields.nft'),
            value: <ImageWithName petImg={petImg} petName={petName} />,
        },
        {
            key: i18n.t('checkout.fields.from'),
            value: i18n.t('checkout.fields.nftMarketplace'),
        },
        {
            key: i18n.t('checkout.fields.to'),
            value: i18n.t('checkout.fields.nftWallet'),
        },
        {
            key: i18n.t('checkout.fields.fee'),
            value: feeWithCurrency(estimatedFee),
            icon: renderFeeInfoIcon(),
        },
    ];

    const handleOpenConfirmationModal = () => {
        onClose();
        openConfirmationModal();
    };

    const { isLoading, startLoading, stopLoading } = useLoadingHook();

    const handleOnConfirm = async () => {
        try {
            startLoading();
            const transactionResult = await delistFromMarketPlace(
                cancelTransactionValuesStringified
            );
            if (transactionResult) {
                stopLoading();
                shouldPop && navigation.popToTop();
                navigation.navigate(ROUTES.MARKETPLACE_DELIST_SUCCESS, {
                    petName,
                    petImg,
                    transactionResult,
                });
            }
            stopLoading();
        } catch (error) {
            stopLoading();
            if (!handleBlockchainErrors(error)) {
                errorsHandler(error, true);
            }
        }
    };

    return (
        <>
            <ModalLoader
                isVisible={isLoading}
                text={i18n.t('checkout.loadingPurchasing')}
                timeout={0}
            />
            <CustomModal
                isVisible={isVisible}
                icon={<ImageIguana type="attention" />}
                titleText={i18n.t('MarketPlace.DelistNftModal.title')}
                infoText={i18n.t('MarketPlace.DelistNftModal.text')}
                firstButtonText={i18n.t('general.BUTTONS.delist')}
                secondButtonText={i18n.t('general.BUTTONS.cancel')}
                onFirstButtonClick={handleOpenConfirmationModal}
                onSecondButtonClick={onClose}
            />
            <ModalConfirmTransaction
                title={i18n.t('checkout.fields.modalTitle')}
                isVisible={confirmationModal}
                close={closeConfirmationModal}
                onConfirm={handleOnConfirm}
                onCancel={closeConfirmationModal}
                disabled={!isBNBEnough}
                textWarning={
                    !isBNBEnough
                        ? i18n.t('checkout.errors.bnbNotEnough')
                        : undefined
                }
                showDeposit={!isBNBEnough}
                TransactionDetailsComponent={
                    <TransactionSummary values={transactionDetailsValues} />
                }
                modalHeight={(transactionDetailsValues.length + 1) * 120}
            />
            {renderBlockchainErrorsModal()}
        </>
    );
};
export default DelistNftModal;
