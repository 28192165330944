import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import { scale, verticalScale } from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        userDonateContainer: {
            borderWidth: 1,
            borderRadius: 12,
            borderColor: Colors[theme].border.primary,
            paddingVertical: verticalScale(12),
            paddingHorizontal: scale(12),
            alignItems: 'center',
            marginBottom: verticalScale(12),
            flexDirection: 'row',
        },
        userId: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
        },
        userIdContainer: {
            paddingLeft: scale(12),
            flex: 1,
        },
        time: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 12,
                height: 16,
                weight: '400',
            }),
        },
        userDonate: {
            color: Colors[theme].text.primary,
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),
            paddingRight: scale(4),
        },
        textBalanceDollar: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 12,
                height: 16,
                weight: '600',
            }),
            paddingTop: verticalScale(5),
            textAlign: 'right',
        },
        valueWrapper: {
            alignItems: 'flex-end',
        },
        igupContainer: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        explorerUrl: {
            marginTop: verticalScale(5),
            color: Colors[theme].text.secondary,
            ...font({
                size: 12,
                height: 16,
                weight: '700',
            }),
        },
    });

export default styles;
