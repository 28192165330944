import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import {
    LUCKY_STRIKE_COLOR,
    QUANTUM_SPIN_COLOR,
} from '@contexts/MysteryGamesContext';
import { scale } from '@helpers/dimensions';
import { stylesModalNavigationFix } from '@helpers/style';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
            ...stylesModalNavigationFix(),
            backgroundColor: QUANTUM_SPIN_COLOR,
        },
        header: {
            paddingHorizontal: scale(20),
            backgroundColor: 'transparent',
        },
        title: {
            color: Colors[theme].text.onLight,
        },
    });

export default styles;
