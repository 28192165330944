import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import Button from '@components/Button';
import Icon, { ICON_NAMES } from '@components/Icons';
import RankIcon from '@components/Icons/RankIcon';
import ImageExtended from '@components/ImageExtended';
import { Text, View } from '@components/Themed';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { isWeb } from '@helpers/app';
import { scale } from '@helpers/dimensions';
import { dateFromNow } from '@helpers/time';
import { balanceFromWei, truncateEthAddress } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import { useTouchableOpacityDebounce } from '@hooks/useTouchableOpacityDebounce';
import { useVisibleHook } from '@hooks/useVisibleHook';
import { MarketplaceOrderDataOutput } from '@models/marketplace';

import DelistNftModal from '../DelistNftModal';
import MarketPlacePriceBlock from '../MarketPlacePriceBlock';
import stylesMain from './styles';

interface IProps {
    data: MarketplaceOrderDataOutput;
    onPress: (
        petId: string,
        cancelTransactionValuesStringified: string | null
    ) => void;
    showSellerAddress?: boolean;
    itsMe: boolean;
    loading?: boolean;
}

const MarketPlacePetCard = ({
    data,
    onPress,
    showSellerAddress,
    itsMe,
    loading,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const {
        priceIguAmountWei,
        createdAt,
        sellerAddress,
        sellerUsername,
        pet,
        cancelTransactionValuesStringified,
    } = data;

    const dateText = React.useMemo(() => dateFromNow(createdAt), [createdAt]);

    const subText = React.useMemo(
        () =>
            showSellerAddress
                ? truncateEthAddress(sellerUsername || sellerAddress)
                : dateText,
        [showSellerAddress, sellerAddress, dateText]
    );

    const [priceMaxWidth, setPriceMaxWidth] = React.useState<number>(0);

    const canDelist = React.useMemo(
        () => itsMe && !!cancelTransactionValuesStringified,
        [itsMe, cancelTransactionValuesStringified]
    );

    const {
        isVisible: delistModal,
        open: openDelistModal,
        close: closeDelistModal,
    } = useVisibleHook();

    const { handleDebounce } = useTouchableOpacityDebounce(() =>
        onPress(pet.id, cancelTransactionValuesStringified)
    );

    const isDead = React.useMemo(() => pet.ageDead || pet.dead, [pet]);

    if (loading && isWeb) {
        return <></>;
    }

    return (
        <TouchableOpacity
            activeOpacity={0.7}
            style={styles.shadow}
            onPress={handleDebounce}>
            <View style={styles.container}>
                <View
                    style={styles.imgWrapper}
                    onLayout={({ nativeEvent }) =>
                        setPriceMaxWidth(nativeEvent.layout.width)
                    }>
                    {isDead && (
                        <View style={styles.deadBlock}>
                            <View style={styles.deadOpacity} />
                            <Button
                                disabledWithoutChangeStyles
                                tailTitle
                                size="md"
                                type="critical"
                                iconName={ICON_NAMES.IS_DEAD}
                            />
                        </View>
                    )}
                    <ImageExtended uri={pet?.imageUrl} borderRadius={8} />
                    <View style={styles.rankWrapper}>
                        <RankIcon size={24} slug={pet?.rank} />
                    </View>
                    {canDelist && (
                        <View style={styles.buttonDelist}>
                            <Button
                                onPress={openDelistModal}
                                debouncedPress
                                type="outline"
                                style={styles.buttonStyles}
                                containerStyle={styles.buttonContainer}
                                icon={
                                    <Icon
                                        name={ICON_NAMES.BIN}
                                        size={16}
                                        color={styles.binColor.color}
                                    />
                                }
                            />
                        </View>
                    )}
                </View>
                <View style={styles.textWrapper}>
                    <Text
                        style={styles.name}
                        numberOfLines={1}
                        ellipsizeMode="tail">
                        {pet?.name}
                    </Text>
                    <Text
                        style={styles.sub}
                        numberOfLines={1}
                        ellipsizeMode="middle">
                        {subText}
                    </Text>
                </View>
                <View style={styles.price}>
                    <MarketPlacePriceBlock
                        amount={balanceFromWei(priceIguAmountWei).valueLong}
                        coin={Coin.igu}
                        maxWidth={Math.round(priceMaxWidth - scale(12) * 2)}
                    />
                </View>
            </View>
            {canDelist && (
                <DelistNftModal
                    isVisible={delistModal}
                    petName={pet.name}
                    petImg={pet.imageUrl}
                    cancelTransactionValuesStringified={
                        cancelTransactionValuesStringified!
                    }
                    onClose={closeDelistModal}
                />
            )}
        </TouchableOpacity>
    );
};
export default MarketPlacePetCard;
