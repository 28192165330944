import { useCallback, useMemo } from 'react';
import { Platform } from 'react-native';

import { isRunningInExpo } from '@helpers/app';

const analytics = isRunningInExpo()
    ? () => null
    : require('@react-native-firebase/analytics');

export const useAnalytics = () => {
    const platform = useMemo(() => Platform.OS, []);

    const onScreenView = useCallback(
        async (name?: string | null) => {
            if (!name || isRunningInExpo()) return;
            try {
                analytics()?.logEvent('screen_view', { name, platform });
            } catch (error) {
                Console.log(error);
            }
        },
        [platform]
    );

    const setUser = useCallback(async (id?: string) => {
        if (!id || isRunningInExpo()) return;
        try {
            analytics()?.setUserId(id);
        } catch (error) {
            Console.log(error);
        }
    }, []);

    return {
        onScreenView,
        setUser,
    };
};
