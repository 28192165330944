import React from 'react';

import WalletIcon from '@assets/icons/wallet/security.svg';
import i18n from '@i18n/i18n';
import { useNavigation } from '@navigation/useNavigation';

import MenuItem from '../../../MenuItem';
import { RightComponent, iconSize } from '../../helpers';

const WalletMenuItem = () => {
    const navigation = useNavigation();

    const handlePress = () => {
        navigation.navigateToWalletSettings();
    };

    return (
        <MenuItem
            leftIcon={<WalletIcon width={iconSize} height={iconSize} />}
            title={i18n.t('profile.menuItems.security.title')}
            description={i18n.t('profile.menuItems.security.description')}
            rightComponent={<RightComponent type="arrowRight" />}
            onPress={handlePress}
        />
    );
};

export default WalletMenuItem;
