import * as Linking from 'expo-linking';

import { NavigationState, PartialState } from '@react-navigation/native';

import { loadSessionTokenFromStorage } from '../contexts/SessionContext';
import { isRunningInExpo, webAppUrl, websiteUrl } from '../helpers/app';

export function getActiveRouteName(
    navigationState: NavigationState | PartialState<NavigationState> | undefined
): string | null {
    if (!navigationState || navigationState.index == undefined) {
        return null;
    }
    const route = navigationState.routes[navigationState.index];

    // Parse the nested navigators
    if (route.state?.routes) {
        return getActiveRouteName(route.state);
    }

    return route.name;
}

export function getDeeplinkReferralsInvite(code?: string) {
    const path = `/download/r/${code ? code.toUpperCase() : ''}`;

    if (isRunningInExpo()) {
        return Linking.createURL(path);
    }

    return `${websiteUrl}${path}`;
}

export function getDeeplinkSocializeToEarn(code?: string) {
    const path = `/download/verify/${code ? code.toUpperCase() : ''}`;

    if (isRunningInExpo()) {
        return Linking.createURL(path);
    }

    return `${websiteUrl}${path}`;
}

export async function getWebAppAuthUrl(path?: string) {
    const sessionToken = await loadSessionTokenFromStorage();
    return `${webAppUrl}${path || ''}?s=${sessionToken}`;
}

export async function getWebAppLinkMarketPlace() {
    const path = `/Marketplace/MarketPlaceActiveOrders`;

    return getWebAppAuthUrl(path);
}

export async function getWebAppLinkTrade() {
    const path = `/u/trade`;

    return getWebAppAuthUrl(path);
}

export function ReferralsInviteLink(link: string) {
    const code = link.toLowerCase().split('/');

    let path = `https://...${code[code.length - 1]}`;

    if (isRunningInExpo()) {
        path = `exp://...${code[code.length - 1]}`;
    }

    return path;
}
