import { waitForToast } from '@helpers/helpers';
import { toastError } from '@helpers/toastNotification';
import i18n from '@i18n/i18n';
import {
    LUCKY_STRIKE_GAME_DURATION,
    LuckyStrikeGameConfigRowOutput,
    MYSTERY_GAMES,
} from '@models/mysteryGames';

export const maxParticipants = (
    games: LuckyStrikeGameConfigRowOutput[] | undefined,
    type: string | LUCKY_STRIKE_GAME_DURATION
) => {
    if (!games?.length) {
        return 0;
    }
    return games.find((i) => i.type === type)?.maxUsers || 0;
};

export const toastSoldOut = async () => {
    await waitForToast();
    toastError(
        i18n.t(`HomeScreen.${MYSTERY_GAMES.luckyStrike}.toasts.soldOut.text1`),
        i18n.t(`HomeScreen.${MYSTERY_GAMES.luckyStrike}.toasts.soldOut.text2`),
        'top',
        10000
    );
};

export const toastGameExpired = async () => {
    await waitForToast();
    toastError(
        i18n.t(
            `HomeScreen.${MYSTERY_GAMES.luckyStrike}.toasts.gameExpired.text1`
        ),
        i18n.t(
            `HomeScreen.${MYSTERY_GAMES.luckyStrike}.toasts.gameExpired.text2`
        ),
        'top',
        10000
    );
};

export const toastGamePending = async (id?: string) => {
    await waitForToast();
    toastError(
        i18n.t(
            `HomeScreen.${MYSTERY_GAMES.luckyStrike}.toasts.gamePending.text1`
        ),
        i18n.t(
            `HomeScreen.${MYSTERY_GAMES.luckyStrike}.toasts.gamePending.text2`
        ),
        'top',
        10000
    );
};
