import * as React from 'react';
import { FlatList, TouchableOpacity } from 'react-native';

import Icon, { ICON_NAMES } from '@components/Icons';
import { View } from '@components/Themed';
import Colors from '@constants/Colors';
import { useTheme } from '@contexts/ThemeContext';
import { wait } from '@helpers/helpers';
import { getPaymentTokenName, getPrice } from '@helpers/payments';
import { feeFormatter } from '@helpers/wallet';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import CheckoutSuccessScreen from '../../../../components/CheckoutSuccessScreen';
import HealthPetCollectionListItem from '../../../../components/HealthPetCollectionListItem';
import TransactionSummary from '../../../../components/TransactionSummary';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import { CollectionMultiplePetsHealUpSuccessProps } from '../../../../types';
import stylesMain from './styles';

const CollectionMultiplePetsHealUpSuccessScreen = ({
    route,
}: CollectionMultiplePetsHealUpSuccessProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const { theme } = useTheme();

    const { results, payment } = route.params;

    const handleNavigateToPetsCollection = async () => {
        navigation.navigateToCollection();
    };

    const feesFormatted = React.useMemo(() => {
        const blockchainFees = results
            .map((r) => Number(r.transactionResult?.transactionFee ?? '0'))
            .reduce((prev, curr) => {
                return prev + curr;
            });
        return `${feeFormatter(blockchainFees)} BNB`;
    }, [results]);

    const totalSummary = React.useMemo(() => {
        return getPrice(payment);
    }, [payment]);

    const handleFeeIconPress = async () => {
        await wait(100);
        navigation.navigate(ROUTES.HOW_TO_FEE);
    };

    const values = [
        {
            key: i18n.t('checkout.fields.transaction'),
            value: i18n.t('detailedPet.health.multipleSuccessHealUp.item', {
                length: results.length,
            }),
        },
        {
            key: i18n.t('checkout.fields.from'),
            value: getPaymentTokenName(payment?.token),
        },
        {
            key: i18n.t('checkout.fields.fee'),
            value: feesFormatted,
            icon: (
                <TouchableOpacity onPress={handleFeeIconPress}>
                    <Icon
                        name={ICON_NAMES.QUESTION}
                        color={Colors[theme].text.secondary}
                    />
                </TouchableOpacity>
            ),
        },
        {
            key: i18n.t('checkout.fields.totalSummary'),
            value: totalSummary,
        },
    ];

    return (
        <CheckoutSuccessScreen
            title={i18n.t('detailedPet.health.multipleSuccessHealUp.title')}
            text={i18n.t('detailedPet.health.multipleSuccessHealUp.text')}
            onPressButtonMain={handleNavigateToPetsCollection}
            buttonMainText={i18n.t(
                'detailedPet.health.multipleSuccessHealUp.backToCollection'
            )}
            TransactionDetailsComponent={
                <>
                    <View style={styles.transactionDetailsWrapper}>
                        <TransactionSummary values={values} />
                    </View>
                    <View style={styles.container}>
                        {results.map((item) => (
                            <HealthPetCollectionListItem
                                key={item.pet.id}
                                data={item}
                                style={styles.petItem}
                            />
                        ))}
                    </View>
                </>
            }
            transactionDetailsStyle={styles.container}
        />
    );
};

export default CollectionMultiplePetsHealUpSuccessScreen;
