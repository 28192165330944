import Constants from 'expo-constants';
import * as Device from 'expo-device';
import * as Linking from 'expo-linking';
import { Platform } from 'expo-modules-core';
import * as Updates from 'expo-updates';
import { isEqual, unset } from 'lodash';

import {
    APP_BUILD_APK,
    APP_ENV,
    CACTUS_GAME_ENDPOINT,
    GRAPHQL_ENDPOINT,
} from '@env';

export const cactusGameEndpoint = 'https://unity-cactuses.iguverse.com/';
//   'https://unity-cactuses-git-stage-iguverse.vercel.app/';

export const osName = Device.osName || '';
export const isIOS = Platform.OS === 'ios';
export const isAndroid = Platform.OS === 'android';
export const isWeb = Platform.OS === 'web';
export const isDesktop = isWeb && !['iOS', 'Android'].includes(osName);
export const isWebAndroid = isWeb && osName === 'Android';
export const isWebIos = isWeb && osName === 'iOS';
export const useNativeDriver = !isWeb;
export const otaUpdateGroup = // @ts-ignore get ota update group
    Constants.manifest2?.metadata?.['updateGroup'] || '';

export const release = Platform.select({
    ios: Constants.expoConfig?.ios?.buildNumber || '1',
    android: Constants.expoConfig?.android?.versionCode?.toString() || '1',
    web: '',
});

export const appVersion = `${Constants.expoConfig?.version || '1.0.0'}${
    release ? ` (${release})` : ''
}`;

export const websiteUrl = Constants?.expoConfig?.extra?.websiteUrl;
export const webAppUrl = Constants?.expoConfig?.extra?.webAppUrl;
export const appScheme = Constants.expoConfig?.scheme;

export const downloadUrl = `${websiteUrl}/download`;
export const downloadShortUrl = 'iguv.cc/d';

export const isRunningInExpo = () => {
    return Constants.appOwnership === 'expo';
};

// hacked function which returns true if device is not emulator and not expo
export const isProduction = () => {
    return APP_ENV === 'production';
};

export const isDevelopment = () => {
    return !isProduction();
};

export const environmentMismatch = () => {
    return (
        (isDevelopment() &&
            GRAPHQL_ENDPOINT !==
                'https://backend.stage.igumetinfra.net/graphql') ||
        (isProduction() &&
            GRAPHQL_ENDPOINT !== 'https://api-gw.iguverse.io/graphql')
    );
};

export const getFullDeviceName = () => {
    const deviceModel = Device.modelName || '';
    const deviceName = Device.deviceName || '';
    const deviceYearClass = Device.deviceYearClass || '';

    return (
        deviceModel +
        (deviceName ? ` ${deviceName}` : '') +
        (deviceYearClass ? ` ${deviceYearClass}` : '')
    );
};

export function getPlayStoreUrl(http = false) {
    const httpUrl = Constants.expoConfig?.android?.playStoreUrl || '';

    if (http) {
        return httpUrl;
    }

    return `market://details?id=${Constants.expoConfig?.android?.package}`;
}

export function getAppStoreUrl(http = false) {
    const httpUrl = Constants.expoConfig?.ios?.appStoreUrl || '';

    if (http) {
        return httpUrl;
    }

    return httpUrl?.replace('https://', 'itms-apps://');
}

export function getApkUrl() {
    return `${websiteUrl}/downloads/android-app.apk`;
}

export const reloadApp = async () => {
    // APK app has no expo-updates
    if (isWeb) {
        window.location.reload();
    } else if (!APP_BUILD_APK) {
        await Updates.reloadAsync();
    }
};

export const getLinkingPrefixes = (withWebUrl: boolean) => {
    const prefixes = [Linking.createURL('/')];
    if (appScheme) {
        prefixes.push(appScheme);
    }
    if (websiteUrl && withWebUrl) {
        prefixes.push(websiteUrl);
    }
    return prefixes;
};

export const isEqualExcept = (
    value?: Record<string, unknown>,
    other?: Record<string, unknown>,
    paths?: string[]
) => {
    const [mutValue, mutOther] = [{ ...value }, { ...other }];
    paths?.forEach((path) => {
        unset(mutValue, path);
        unset(mutOther, path);
    });
    return isEqual(mutValue, mutOther);
};

export const projectNameForProxy = isProduction()
    ? '@iguverse/iguverse-app'
    : '@iguverse/iguverse-dev';
