import { PixelRatio, Platform, TextStyle } from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';

import { SIZE_MATTERS_BASE_WIDTH } from '@env';

import { isAndroid, isWeb } from './app';

const dpi = PixelRatio.get();

type FontWeight = '400' | '500' | '600' | '700' | '800' | '900';

const fontWeight = (weight: FontWeight): any => {
    const weights = ['500', '600', '700', '800', '900'];

    const fontFamily = {
        '400': 'Nunito-Regular',
        '500': 'Nunito-Medium',
        '600': 'Nunito-SemiBold',
        '700': 'Nunito-Bold',
        '800': 'Nunito-ExtraBold',
        '900': 'Nunito-Black',
    };

    if (weight && weights.includes(weight)) {
        return Platform.select({
            ios: {
                fontFamily: fontFamily[weight],
            },
            android: {
                fontFamily: fontFamily[weight],
            },
            web: {
                fontFamily: fontFamily[weight],
            },
        });
    }

    return Platform.select({
        default: { fontWeight: weight },
    });
};

const fontSize = (size: number): number => {
    if (isWeb) {
        return size;
    }

    // all size values are referred to DPI = 1, for dpi diffent from 1, we should scale/downscale the current size,
    // isAndroid true
    return isAndroid
        ? size
        : RFValue(size / (dpi >= 3 ? 2 : dpi), Number(SIZE_MATTERS_BASE_WIDTH));
};

export const MAIN_FONT = 'Nunito-Regular';

interface IFont {
    size?: number;
    height?: number;
    weight?: FontWeight;
    style?: 'normal' | 'italic';
    family?: string;
}

export default function font({
    size = fontSize(14),
    height,
    weight = '400',
    style = 'normal',
    family = MAIN_FONT,
}: IFont): TextStyle {
    return {
        fontFamily: family,
        ...fontWeight(weight),
        ...(size ? { fontSize: fontSize(size) } : {}),
        ...(height ? { lineHeight: fontSize(height) } : {}),
        fontStyle: style,
    };
}
