import * as React from 'react';
import { useEffect } from 'react';

import { useWallet } from '@contexts/Wallet/WalletContext';

import CheckoutSuccessScreen from '../../../../components/CheckoutSuccessScreen';
import TransactionSummary from '../../../../components/TransactionSummary';
import i18n from '../../../../i18n/i18n';
import { useNavigation } from '../../../../navigation/useNavigation';
import { TradeSuccessProps } from '../../../../types';

const TradeSuccessScreen = ({ route }: TradeSuccessProps) => {
    const navigation = useNavigation();
    const { estimate, from, to, fee, totalSummary, explorerUrl, balance } =
        route.params;
    const { reloadBalance } = useWallet();

    useEffect(() => {
        reloadBalance(true);

        return () => {
            reloadBalance(true);
        };
    }, []);

    navigation.blockHardwareBack();

    const handleBack = async () => {
        navigation.goBack();
    };

    const handleBackToWallet = async () => {
        navigation.pop();
        navigation.navigateToCoinsWallet();
    };

    const renderTransactionDetailComponent = () => {
        let fromKey;
        let toKey;
        if (estimate === 'from') {
            fromKey = `${i18n.t('trade.tradeSuccess.from')} ${i18n.t(
                'trade.tradeSuccess.estimate'
            )}`;
            toKey = i18n.t('trade.tradeSuccess.to');
        } else {
            toKey = `${i18n.t('trade.tradeSuccess.to')} ${i18n.t(
                'trade.tradeSuccess.estimate'
            )}`;
            fromKey = i18n.t('trade.tradeSuccess.from');
        }

        return (
            <TransactionSummary
                values={[
                    {
                        key: fromKey,
                        value: from,
                    },
                    {
                        key: toKey,
                        value: to,
                    },
                    {
                        key: i18n.t('trade.tradeSuccess.blockchainFee'),
                        value: fee,
                    },
                    {
                        key: i18n.t('trade.tradeSuccess.totalSummary'),
                        value: totalSummary,
                    },
                ]}
            />
        );
    };

    return (
        <CheckoutSuccessScreen
            animation="money"
            title={i18n.t('trade.tradeSuccess.title')}
            text={i18n.t('trade.tradeSuccess.text')}
            onPressButtonMain={handleBackToWallet}
            buttonMainText={i18n.t('trade.tradeSuccess.backToWallet')}
            onPressButtonAdditional={handleBack}
            buttonAdditionalText={i18n.t('trade.tradeSuccess.back')}
            TransactionDetailsComponent={renderTransactionDetailComponent()}
            explorerUrl={explorerUrl}
        />
    );
};

export default TradeSuccessScreen;
