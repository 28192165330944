import { StyleSheet } from 'react-native';

import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import Colors from '../../../../constants/Colors';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        shadow: {
            marginTop: verticalScale(16),
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].shadow.default,
        },
        itemContainer: {
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            marginBottom: 4,
            flexDirection: 'row',
            alignItems: 'center',
        },
        right: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: scale(16),
            backgroundColor: 'transparent',
        },
        icon: {
            color: Colors[theme].icon.secondary,
            marginRight: scale(8),
            backgroundColor: 'transparent',
        },
        title: {
            backgroundColor: 'transparent',
            flex: 1,
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
        },
        shadowActive: {
            backgroundColor: Colors[theme].shadow.selected,
        },
        itemContainerActive: {
            borderColor: Colors[theme].border.accent,
        },
        radio: {
            width: 20,
            height: 20,
            borderWidth: 1,
            borderRadius: 1000,
            borderColor: Colors[theme].radio.borderDefault,
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: scale(8),
        },

        active: {
            borderColor: Colors[theme].radio.borderActive,
        },
    });

export default styles;
