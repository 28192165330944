import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';
import { convertHexToRGBA, stylesWebOutlines } from '@helpers/style';

import { theme } from '../../types';

const stylesMain = (theme: theme) =>
    StyleSheet.create({
        containerButton: {
            backgroundColor: Colors[theme].background,
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            marginBottom: verticalScale(2),

            alignItems: 'center',
            flexDirection: 'row',
        },
        overlay: {
            zIndex: 1,
            position: 'absolute',
            width: '100%',
            height: '100%',
            borderRadius: moderateScale(12),
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            backgroundColor: convertHexToRGBA(Colors[theme].background, 0.9),
        },
        shadow: {
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].shadow.default,
            ...stylesWebOutlines(),
        },
        left: {
            marginRight: scale(12),
            backgroundColor: 'transparent',
            flexDirection: 'row',
        },
        right: { flex: 1, backgroundColor: 'transparent' },

        text: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
            marginBottom: verticalScale(2),
        },
        label: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 21,
                weight: '400',
            }),
            letterSpacing: 0.08,
        },
        loadingText: {
            textAlign: 'center',
        },
        disabled: {
            opacity: 0.5,
        },
        arrowRight: {
            color: Colors[theme].icon.secondary,
        },
        arrow: {
            backgroundColor: 'transparent',
            marginLeft: scale(16),
        },
        selectedShadow: {
            backgroundColor: Colors[theme].shadow.selected,
        },
        selectedContainer: {
            borderColor: Colors[theme].border.accent,
        },
        iconNameText: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
            marginLeft: scale(8),
        },
    });

export default stylesMain;
