import { StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import { scale, verticalScale } from '../../../../helpers/dimensions';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
        },

        content: {
            paddingHorizontal: scale(20),
            marginBottom: verticalScale(40),
        },
        referralEnterWrapper: {
            marginTop: verticalScale(20),
        },
        referralCodeWrapper: {
            marginTop: verticalScale(16),
        },

        gap: {
            marginTop: verticalScale(12),
        },
        cardWrapper: {
            marginTop: verticalScale(20),
            marginBottom: verticalScale(16),
        },

        emptyWrapper: {
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
        },

        titleWrapper: {
            flexDirection: 'row',
        },
        iconColor: {
            color: Colors[theme].text.accent,
        },
        infoWrapper: {
            marginLeft: scale(20),
        },
    });

export default styles;
