import * as React from 'react';
import { Dimensions, ScrollView } from 'react-native';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';
import { SharedValue, useDerivedValue } from 'react-native-reanimated';

import {
    MAX_WINDOW_WIDTH,
    useDimensions,
} from '../../../../../contexts/DimensionsContext';
import { isWeb } from '../../../../../helpers/app';
import { scale } from '../../../../../helpers/dimensions';

interface IProps {
    children: React.ReactNode;
    disableScroll: SharedValue<boolean>;
}

const DraggableContainer = ({ children, disableScroll }: IProps) => {
    const scrollRef = React.useRef<ScrollView>(null);
    const intervalRef = React.useRef<ReturnType<typeof setInterval>>();
    const currentPositionRef = React.useRef(0);
    const [scrollViewWidth, setScrollViewWidth] = React.useState<number>(0);
    const [scrollViewContentWidth, setScrollViewContentWidth] =
        React.useState<number>(0);

    const isLongPressed = useDerivedValue(() => disableScroll.value);

    const newPosition = (currentPosition: number, translationX: number) =>
        Math.max(
            Math.min(
                currentPosition + -translationX,
                scrollViewContentWidth - scrollViewWidth
            ),
            0
        );

    const windowDimensions = Dimensions.get('window');

    const widthDelta = React.useMemo(() => {
        if (windowDimensions.width > MAX_WINDOW_WIDTH) {
            return (windowDimensions.width - MAX_WINDOW_WIDTH) / 2;
        }
        return 0;
    }, [windowDimensions]);

    const gesturePan = Gesture.Pan()
        .onUpdate((event) => {
            const absoluteX = event.absoluteX - widthDelta - scale(20);
            const scrollZoneWidth = 30;
            if (!isLongPressed.value) {
                scrollRef.current?.scrollTo({
                    x: newPosition(
                        currentPositionRef.current,
                        event.translationX
                    ),
                    animated: false,
                });
            } else {
                if (absoluteX < scrollZoneWidth && !intervalRef.current) {
                    intervalRef.current = setInterval(() => {
                        const nextPosition = newPosition(
                            currentPositionRef.current,
                            2
                        );
                        scrollRef.current?.scrollTo({
                            x: nextPosition,
                            animated: false,
                        });
                        currentPositionRef.current = nextPosition;
                    }, 5);
                } else if (
                    absoluteX > scrollViewWidth - scrollZoneWidth &&
                    !intervalRef.current
                ) {
                    intervalRef.current = setInterval(() => {
                        const nextPosition = newPosition(
                            currentPositionRef.current,
                            -2
                        );
                        scrollRef.current?.scrollTo({
                            x: nextPosition,
                            animated: false,
                        });
                        currentPositionRef.current = nextPosition;
                    }, 5);
                } else if (
                    absoluteX > scrollZoneWidth &&
                    absoluteX < scrollViewWidth - scrollZoneWidth
                ) {
                    clearInterval(intervalRef.current);
                    intervalRef.current = undefined;
                }
            }
        })
        .onEnd((event) => {
            if (!isLongPressed.value) {
                currentPositionRef.current = newPosition(
                    currentPositionRef.current,
                    event.translationX
                );
            }
            clearInterval(intervalRef.current);
            intervalRef.current = undefined;
        });

    if (isWeb) {
        return (
            <GestureDetector gesture={gesturePan}>
                <ScrollView
                    scrollEnabled={false}
                    horizontal
                    ref={scrollRef}
                    onLayout={(e) =>
                        setScrollViewWidth(e.nativeEvent.layout.width)
                    }
                    onContentSizeChange={(w) => setScrollViewContentWidth(w)}>
                    {children}
                </ScrollView>
            </GestureDetector>
        );
    }

    return <>{children}</>;
};

export default DraggableContainer;
