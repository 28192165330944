import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(12),
            marginBottom: verticalScale(12),
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
            borderColor: Colors[theme].border.primary,
            borderWidth: 1,
            borderRadius: moderateScale(12),
        },
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '600',
            }),
        },
        date: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 12,
                height: 16,
                weight: '400',
            }),
            marginTop: verticalScale(2),
        },
        titleWrapper: {
            marginLeft: scale(12),
        },
        left: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        energyBlock: {
            backgroundColor: 'transparent',
        },
        imageWrapper: {
            paddingHorizontal: scale(8),
            paddingVertical: scale(8),
            borderRadius: 1000,
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
        },
        TaskBgSocialTask: {
            backgroundColor: Colors[theme].taskHistoryBg.socialTask,
        },
        TaskBgVerificationTask: {
            backgroundColor: Colors[theme].taskHistoryBg.verificationTask,
        },
        TaskBgMoveTask: {
            backgroundColor: Colors[theme].taskHistoryBg.moveTask,
        },
        TaskBgPlayTask: {
            backgroundColor: Colors[theme].taskHistoryBg.playTask,
        },
    });

export default styles;
