import { StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import { scale, verticalScale } from '../../../../helpers/dimensions';
import font from '../../../../helpers/font';
import { theme } from '../../../../types';

const stylesMain = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingTop: verticalScale(8),
            paddingBottom: verticalScale(24),
            paddingHorizontal: scale(20),
            backgroundColor: Colors[theme].background,
            flex: 1,
        },
        card: {
            marginBottom: verticalScale(16),
        },
    });

export default stylesMain;
