import * as React from 'react';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

import Button from '../../../components/Button';
import { ICON_NAMES } from '../../../components/Icons';
import { Text, View } from '../../../components/Themed';
import useThemedStyles from '../../../hooks/useThemedStyles';
import { useNavigation } from '../../useNavigation';
import stylesMain from './styles';

interface IProps {
    iconName?: ICON_NAMES;
    title?: string;
    onBack?: () => void;
    containerStyle?: StyleProp<ViewStyle>;
    titleStyle?: StyleProp<TextStyle>;
    isOneColor?: boolean;
}

const NavigationBarModal = ({
    iconName = ICON_NAMES.ARROW_LEFT,
    title,
    onBack,
    containerStyle,
    titleStyle,
    isOneColor,
}: IProps) => {
    const navigation = useNavigation();
    const styles = useThemedStyles(stylesMain);
    return (
        <View style={[styles.header, containerStyle]}>
            <Button
                isOneColor={isOneColor}
                style={styles.buttonExit}
                onPress={() => (onBack ? onBack() : navigation.goBack())}
                debouncedPress
                type="outline"
                size="md"
                iconName={iconName}
            />
            {!!title && (
                <Text style={[styles.headerText, titleStyle]}>{title}</Text>
            )}
        </View>
    );
};

export default NavigationBarModal;
