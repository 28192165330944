import {
    ColorSchemeName,
    StatusBar,
    StyleProp,
    TextStyle,
    ViewStyle,
} from 'react-native';

import { compact } from 'lodash';

import Colors from '../constants/Colors';
import { theme } from '../types';
import { isAndroid } from './app';
import { moderateScale, scale, verticalScale } from './dimensions';
import font from './font';

export const stylesModalNavigationFix = () => {
    return {
        paddingTop: isAndroid ? StatusBar.currentHeight : 0,
    };
};

export const stylesSafeArea = (theme: NonNullable<ColorSchemeName>) => {
    return {
        flex: 1,
        backgroundColor: Colors[theme].background,
    };
};

export const stylesAlignCenter = (flex = true): ViewStyle | TextStyle => {
    if (flex) {
        return {
            flex: 1,
            alignItems: 'center',
            alignSelf: 'center',
            justifyContent: 'center',
        };
    }
    return {
        alignItems: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
    };
};

export const stylesLoadMore = (): ViewStyle => {
    return {
        marginBottom: verticalScale(20),
    };
};

export const stylesOverlayBlock = (
    theme: NonNullable<ColorSchemeName>,
    opacity = 0.6
): ViewStyle => {
    const backgroundColor =
        theme === 'dark'
            ? `rgba(35, 36, 42, ${opacity})`
            : `rgba(255, 255, 255, ${opacity})`;
    return {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor,
    };
};

export const stylesAbsoluteFullImage = (): ViewStyle => {
    return {
        flex: 1,
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
    };
};

export const stylesWebOutlines = (): ViewStyle => {
    return {
        // @ts-ignore
        outlineWidth: 0,
        outlineStyle: 'none',
    };
};

export const stylesAnimation = (): ViewStyle => {
    return {
        width: 180,
        height: 180,
    };
};

export const stylesFlex = (): ViewStyle => {
    return {
        flex: 1,
    };
};

export const stylesPriceInUsd = (
    theme: NonNullable<ColorSchemeName>
): TextStyle => {
    return {
        color: Colors[theme].text.secondary,
        ...font({
            size: 12,
            height: 16,
            weight: '600',
        }),
    };
};

/**
 * ECMA2016 / ES6
 */
export const convertHexToRGBA = (hexCode: string, opacity = 1) => {
    let hex = hexCode.replace('#', '');

    if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    let outputOpacity = opacity;

    /* Backward compatibility for whole number based opacity values. */
    if (opacity > 1 && opacity <= 100) {
        outputOpacity = opacity / 100;
    }

    return `rgba(${r},${g},${b},${outputOpacity})`;
};

export const combineStyle = (...args: StyleProp<ViewStyle>[]) => compact(args);

export const stylesContainerWithBorderDefault = (theme: theme): ViewStyle => {
    return {
        borderRadius: moderateScale(12),
        paddingHorizontal: scale(16),
        paddingVertical: verticalScale(16),
        borderWidth: 1,
        borderColor: Colors[theme].border.primary,
    };
};

export const stylesRowAlign = (withFlex?: boolean): ViewStyle => {
    return {
        flexDirection: 'row',
        alignItems: 'center',
        flex: withFlex ? 1 : undefined,
    };
};
export const stylesRowSpaceBetween = (): ViewStyle => {
    return {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    };
};
