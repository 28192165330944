import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: Colors[theme].background,
        },
        page: {
            paddingHorizontal: scale(20),
        },
        list: {
            marginTop: verticalScale(16),
        },
    });

export default styles;
