import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { scale, verticalScale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: 'transparent',
        },
        titleContainer: {
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: 'transparent',
        },
        titleInRow: {
            flexDirection: 'row',
        },
        titleEmptyState: {
            textAlign: 'center',
            color: Colors[theme].text.primary,
            ...font({
                size: 22,
                height: 30,
                weight: '800',
            }),
            marginBottom: verticalScale(8),
        },
        textEmptyState: {
            textAlign: 'center',
            color: Colors[theme].text.secondary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },

        titlePrimary: {
            color: Colors[theme].text.primary,
            ...font({
                size: 22,
                height: 30,
                weight: '700',
            }),
            marginBottom: verticalScale(6),
            alignSelf: 'flex-start',
        },
        textPrimary: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
            alignSelf: 'flex-start',
        },
        additionalTitle: {
            color: Colors[theme].text.accent,
            ...font({
                size: 22,
                height: 30,
                weight: '800',
            }),
        },
        infoWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: 'transparent',
            marginTop: verticalScale(12),
        },
        info: {
            color: Colors[theme].text.accent,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
            letterSpacing: 0.08,
            marginLeft: scale(4),
        },
        additionalText: {
            color: Colors[theme].text.accent,
            ...font({
                weight: '700',
            }),
        },
        flex: {
            flex: 1,
            justifyContent: 'flex-start',
            backgroundColor: 'transparent',
        },
        image: {
            backgroundColor: 'transparent',
            alignItems: 'center',
            marginBottom: verticalScale(24),
        },
        transparent: {
            backgroundColor: 'transparent',
        },
        isOnlyWhite: {
            color: Colors[theme].text.white,
        },
    });

export default styles;
