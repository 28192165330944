import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        itemContainer: {
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: verticalScale(4),
        },
        shadow: {
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].shadow.default,
            marginBottom: verticalScale(12),
            marginHorizontal: scale(20),
        },

        left: {
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
            backgroundColor: 'transparent',
        },
        right: {
            flexDirection: 'row',
            alignItems: 'center',
            alignSelf: 'flex-start',
            backgroundColor: 'transparent',
        },
        place: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
            width: scale(24),
            textAlign: 'center',
        },

        imageWrapper: {
            width: scale(48),
            height: scale(48),
            borderRadius: moderateScale(6),
            backgroundColor: 'transparent',
        },
        infoBlock: {
            marginLeft: scale(12),
            marginRight: scale(12),
            flex: 1,
            backgroundColor: 'transparent',
        },
        address: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '600',
            }),
        },
        date: {
            marginTop: verticalScale(2),
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '600',
            }),
        },

        list: {
            marginTop: verticalScale(12),
        },

        active: {
            backgroundColor: Colors[theme].shadow.selected,
        },
        activeContainer: {
            borderColor: Colors[theme].border.accent,
        },
    });

export default styles;
