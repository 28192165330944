import { StyleSheet } from 'react-native';

import { stylesSafeArea } from '../../../../helpers/style';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        safe: {
            ...stylesSafeArea(theme),
        },
        container: {
            flex: 1,
        },
    });

export default styles;
