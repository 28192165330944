import React, { useCallback, useState } from 'react';

import _, { filter } from 'lodash';

import { useApolloClient, useQuery } from '@apollo/client';

import {
    RewardsTransactionHistoryResponse,
    TasksHistoryResponse,
    TasksHistoryResponseItem,
} from '../Data/Models';
import { GET_TASK_HISTORY, GET_TRANSACTION_HISTORY } from '../Data/Requests';
import { toastErrorUnknown } from '../helpers/toastNotification';

const STATUSES = ['FAILED', 'COMPLETED'];

const useTaskHistory = () => {
    const client = useApolloClient();
    const [taskHistoryData, setTaskHistoryData] =
        React.useState<TasksHistoryResponseItem[]>();
    const [pullToRefreshLoading, setPullToRefreshLoading] = useState(false);
    const [taskHistoryDataLoading, setTaskHistoryDataLoading] = useState(false);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);
    const [loadMore, setLoadMore] = React.useState(false);

    const getTaskHistory = async (silent: boolean) => {
        silent
            ? setTaskHistoryDataLoading(true)
            : setPullToRefreshLoading(true);

        const result = await client.query<TasksHistoryResponse>({
            query: GET_TASK_HISTORY,
            fetchPolicy: 'network-only',
            variables: {
                paginationInput: {
                    statuses: STATUSES,
                },
            },
        });
        if (result.error) {
            Console.error(result.error);
            toastErrorUnknown();
        }
        if (result.data) {
            setTaskHistoryData(result.data.tasksHistory.items);
            setCurrentPage(result.data.tasksHistory.meta.currentPage);
            setTotalPages(result.data.tasksHistory.meta.totalPages);
        }
        setPullToRefreshLoading(false);
        setTaskHistoryDataLoading(false);
    };

    const getTaskHistoryLoadMore = useCallback(async () => {
        if (currentPage === totalPages) {
            return;
        }
        setLoadMore(true);

        const result = await client.query<TasksHistoryResponse>({
            query: GET_TASK_HISTORY,
            fetchPolicy: 'network-only',
            variables: {
                paginationInput: {
                    statuses: STATUSES,
                    page: currentPage + 1,
                },
            },
        });
        if (result.error) {
            Console.log(result.error);
            toastErrorUnknown();
        }
        if (result.data) {
            setTaskHistoryData((prev) => {
                return prev
                    ? _.uniqBy(
                          [...prev, ...result.data.tasksHistory.items],
                          (o) => o.id
                      )
                    : result.data.tasksHistory.items;
            });

            setCurrentPage(result.data.tasksHistory.meta.currentPage);
            setTotalPages(result.data.tasksHistory.meta.totalPages);
        }
        setLoadMore(false);
    }, [filter, totalPages, currentPage]);

    return {
        getTaskHistory,
        getTaskHistoryLoadMore,
        loadMore,
        pullToRefreshLoading,
        taskHistoryData,
        taskHistoryDataLoading,
    };
};

export default useTaskHistory;
