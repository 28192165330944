import * as React from 'react';
import { useMemo } from 'react';

import CheckoutSuccessScreen from '@components/CheckoutSuccessScreen';
import { useGamingWallet } from '@contexts/GamingWalletContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { priceString } from '@helpers/payments';
import { feeWithCurrency } from '@helpers/wallet';
import useFeeInfo from '@hooks/useFeeInfo';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import { WithdrawIguSuccessScreenProps } from '../../../../types';
import GamingWalletCheckoutSummary from '../components/GamingWalletCheckoutSummary';

const WithdrawSuccessScreen = ({ route }: WithdrawIguSuccessScreenProps) => {
    const navigation = useNavigation();

    const { resetGamingWallet, topUpAmount, isLoading, loadData } =
        useGamingWallet();

    const { renderFeeInfoIcon } = useFeeInfo();
    const { renderFeeInfoIcon: renderCommissionInfoIcon } = useFeeInfo(
        undefined,
        undefined,
        ROUTES.HOW_COMMISSION_FEE
    );

    const { transactionResult, amount, coin, commission } = route.params;

    const handleNavigateBack = async () => {
        await loadData(true);
        navigation.pop();
        resetGamingWallet();
    };
    const summaryValues = useMemo(() => {
        return [
            {
                key: i18n.t('checkout.fields.from'),
                value: i18n.t('checkout.fields.gamingWallet'),
            },
            {
                key: i18n.t('checkout.fields.to'),
                value: i18n.t('checkout.fields.mainWallet'),
            },
            {
                key: i18n.t('checkout.fields.fee'),
                value: transactionResult?.transactionFeeFormatted,
                icon: renderFeeInfoIcon(),
            },
            {
                key: i18n.t('checkout.fields.commissionFee'),
                value: feeWithCurrency(commission, Coin.vigu),
                icon: renderCommissionInfoIcon(),
            },
            {
                key: i18n.t('checkout.fields.totalSummary'),
                value: priceString(amount, coin),
            },
        ];
    }, [topUpAmount, transactionResult]);

    return (
        <CheckoutSuccessScreen
            buttonDisabled={isLoading}
            buttonLoading={isLoading}
            title={i18n.t('withdrawScreen.withdrawSuccess.title')}
            text={i18n.t('withdrawScreen.withdrawSuccess.text')}
            onPressButtonMain={handleNavigateBack}
            buttonMainText={i18n.t(
                'withdrawScreen.withdrawSuccess.backToWallet'
            )}
            TransactionDetailsComponent={
                <GamingWalletCheckoutSummary values={summaryValues} />
            }
            explorerUrl={transactionResult?.explorerUrl}
        />
    );
};

export default WithdrawSuccessScreen;
