import * as React from 'react';
import { useMemo } from 'react';

import _ from 'lodash';

import { Text, View } from '../../../../../components/Themed';
import { useWallet } from '../../../../../contexts/Wallet/WalletContext';
import {
    Coin,
    CoinBalance,
    calculateExchangeRate,
} from '../../../../../contexts/Wallet/WalletHelpers';
import { numberFormatter } from '../../../../../helpers/wallet';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import stylesMain from './styles';

interface IProps {
    balance: CoinBalance | CoinBalance[];
}

const WalletCoinBalance = ({ balance }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { priceInUsd, getRate } = useWallet();

    // sum all balances or show specific balance
    const sumBalance = useMemo(() => {
        if (_.isArray(balance)) {
            let sumInBnb = 0;
            let sumInUsd = 0;
            balance.forEach((item) => {
                if (item.name === Coin.bnb) {
                    sumInBnb += Number(item.value);
                } else {
                    sumInBnb += Number(
                        calculateExchangeRate(
                            item.value,
                            getRate(item.name, Coin.bnb)
                        )
                    );
                }

                sumInUsd += Number(priceInUsd(item.name, item.value));
            });

            return {
                presentationValueLong: numberFormatter(sumInBnb),
                name: 'BNB',
                valueDollars: numberFormatter(sumInUsd),
            };
        }

        return {
            presentationValueLong: balance.presentationValueLong,
            name: balance.name,
            valueDollars: balance.valueDollars,
        };
    }, [balance, priceInUsd, getRate]);

    return (
        <View style={styles.container}>
            <Text style={[styles.textTotal, styles.gap]}>
                {i18n.t('wallet.total')}
            </Text>
            <Text
                style={[
                    styles.textBalance,
                    styles.gap,
                ]}>{`≈ ${sumBalance.presentationValueLong} ${sumBalance.name}`}</Text>
            <Text
                style={
                    styles.textBalanceDollar
                }>{`≈ ${sumBalance.valueDollars} $`}</Text>
        </View>
    );
};
export default WalletCoinBalance;
