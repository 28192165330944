import React from 'react';

import { Pet } from '../../../../../Data/Models';
import Button from '../../../../../components/Button';
import Icon, { ICON_NAMES } from '../../../../../components/Icons';
import LevelIndicator from '../../../../../components/LevelIndicator';
import ModalNFTLevelUp from '../../../../../components/ModalNFTLevelUp';
import { View } from '../../../../../components/Themed';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import { useVisibleHook } from '../../../../../hooks/useVisibleHook';
import i18n from '../../../../../i18n/i18n';
import DetailsBlock from '../DetailsBlock';
import Styles from './styles';

interface IProps {
    pet: Pet;
}

const LevelDetailsBlock = ({ pet }: IProps) => {
    const styles = useThemedStyles(Styles);
    const {
        isVisible,
        close: handleCloseLevelUpModal,
        open: handleOpenLevelUpModal,
    } = useVisibleHook();

    const {
        levelUpAvailable,
        level,
        experience,
        currentLevelMaxExperience,
        levelProgressPercentage,
    } = pet;

    return (
        <>
            <DetailsBlock
                title={i18n.t('detailedPet.levelXP.title')}
                description={
                    levelUpAvailable
                        ? i18n.t('detailedPet.levelXP.textUpgrade', {
                              level,
                          })
                        : i18n.t('detailedPet.levelXP.text')
                }
                highlighted={levelUpAvailable}
                icon={
                    levelUpAvailable ? (
                        <Icon name={ICON_NAMES.LEVEL} size={48} />
                    ) : undefined
                }>
                {levelUpAvailable ? (
                    <View style={styles.gap}>
                        <Button
                            icon={
                                <Icon
                                    name={ICON_NAMES.ARROW_UPGRADE}
                                    color={styles.icon.color}
                                />
                            }
                            onPress={handleOpenLevelUpModal}
                            title={i18n.t('detailedPet.levelXP.upgrade', {
                                level: level + 1,
                            })}
                            type="outline"
                            style={styles.buttonStyle}
                            containerStyle={styles.buttonContainerStyle}
                            textStyles={styles.lvlUpColor}
                            iconColor={styles.lvlUpColor.color}
                        />
                    </View>
                ) : (
                    <View style={styles.gap}>
                        <LevelIndicator
                            level={level}
                            experience={experience}
                            maxExperience={currentLevelMaxExperience}
                            fillAmount={levelProgressPercentage}
                            fullView
                            iconSize={24}
                        />
                    </View>
                )}
            </DetailsBlock>
            <ModalNFTLevelUp
                fetch
                handleCloseLevelUpModal={handleCloseLevelUpModal}
                isVisible={isVisible}
                pet={pet}
            />
        </>
    );
};
export default LevelDetailsBlock;
