import * as React from 'react';

import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { useVisibleHook } from '@hooks/useVisibleHook';
import { GAME_DIFFICULTY, NFT_ROYAL_GAME_MODE } from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

export const useNftRoyalGameConfigurationsScreen = () => {
    const navigation = useNavigation();

    const { nftRoyaleGameConfig, handleSaveNftRoyalGameConfiguration } =
        useMysteryGames();

    const { isVisible, open, close } = useVisibleHook();

    const [gameMode, setGameMode] = React.useState<NFT_ROYAL_GAME_MODE | null>(
        null
    );
    const [gameDifficulty, setGameDifficulty] =
        React.useState<GAME_DIFFICULTY | null>(null);
    const [gameLivesCount, setGameLivesCount] = React.useState<number | null>(
        null
    );

    const onSelectGameMode = React.useCallback(
        (mode: NFT_ROYAL_GAME_MODE) => {
            if (mode === gameMode) {
                return;
            }
            setGameMode(mode);
        },
        [gameMode]
    );

    const onSelectGameDifficulty = React.useCallback(
        (difficulty: GAME_DIFFICULTY) => {
            if (difficulty === gameDifficulty) {
                return;
            }
            setGameDifficulty(difficulty);
        },
        [gameDifficulty]
    );

    const onSelectGameLivesCount = React.useCallback(
        (lives: number) => {
            if (lives === gameLivesCount) {
                return;
            }
            setGameLivesCount(lives);
        },
        [gameLivesCount]
    );

    const canGoNext = React.useMemo(
        () => !!gameMode && !!gameDifficulty && !!gameLivesCount,
        [gameMode, gameDifficulty, gameLivesCount]
    );

    const navigateToInput = React.useCallback(() => {
        if (!gameMode || !gameDifficulty || !gameLivesCount) {
            return;
        }
        handleSaveNftRoyalGameConfiguration(
            gameMode,
            gameDifficulty,
            gameLivesCount
        );
        navigation.navigate(ROUTES.NFT_ROYAL_ENTER_COINS_AMOUNT);
    }, [gameDifficulty, gameLivesCount, gameMode]);

    return {
        canGoNext,
        onSelectGameLivesCount,
        onSelectGameDifficulty,
        onSelectGameMode,
        navigateToInput,
        isVisible,
        close,
        open,
        nftRoyaleGameConfig,
        gameDifficulty,
        gameMode,
        gameLivesCount,
    };
};
