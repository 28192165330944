import * as React from 'react';
import { useCallback } from 'react';

import AvailableRewards from '@assets/icons/gradient/availableRewards.svg';
import LockedRewards from '@assets/icons/gradient/pendingRewards.svg';
import Carousel from '@components/Carousel';
import InfoIcon from '@components/InfoIcon/InfoIcon';
import { Text, View } from '@components/Themed';
import { useDimensions } from '@contexts/DimensionsContext';
import { useStaking } from '@contexts/StakingContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { scale } from '@helpers/dimensions';
import { priceString } from '@helpers/payments';
import { getStakingPlanColor } from '@helpers/staking';
import { dateCooldownExpired } from '@helpers/time';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import { howToSlideAboutNegativeBalance } from '../../../../HowTo/HowToAvailableRewards/HowToAvailableRewardsScreen';
import ActiveStakingButton from '../ActiveStakingButton';
import stylesMain from './styles';

export interface IRewardCard {
    id: 'availableRewards' | 'pendingRewards' | 'lockedRewards';
    igupAmount: string;
    usdPrice: string;
    withFAQ?: boolean;
    canClaim?: boolean;
    reclaim?: boolean;
}

interface IProps {
    data: IRewardCard[];
}

const RewardsCarousel = ({ data }: IProps) => {
    const { currentStakingPlan, currentPlanBoost, stakingPlans } = useStaking();
    const { windowWidth } = useDimensions();
    const styles = useThemedStyles(stylesMain);
    const itemWidth = React.useMemo(
        () => windowWidth - scale(20 * 2),
        [windowWidth]
    );
    const navigation = useNavigation();
    const itemCustomWidth = itemWidth + scale(12);

    const onQuestionPress = (id: string, slide?: number) => {
        if (id === 'availableRewards') {
            navigation.navigate(ROUTES.HOW_TO_AVAILABLE_REWARDS, {
                slide,
            });
            return;
        }
        navigation.navigate(ROUTES.HOW_TO_LOCKED_REWARDS);
    };

    const showActiveStaking = React.useMemo(() => {
        if (!currentStakingPlan) {
            return false;
        }

        return !dateCooldownExpired(currentStakingPlan.endDate);
    }, [currentStakingPlan]);

    const renderItem = useCallback(
        (item: IRewardCard) => {
            const isAvailableRewards =
                item.id === 'availableRewards' || item.id === 'pendingRewards';

            const isNegativeBalance = Number(item.igupAmount) < 0;

            return (
                <View
                    style={{
                        width: itemWidth,
                        backgroundColor: 'transparent',
                    }}>
                    <View
                        style={{
                            width: itemWidth,
                            height: Math.floor(itemWidth / 1.61),
                        }}>
                        {isAvailableRewards ? (
                            <AvailableRewards />
                        ) : (
                            <LockedRewards />
                        )}
                    </View>
                    <View style={styles.content}>
                        <View style={styles.top}>
                            <View style={styles.titleWrapper}>
                                {item.withFAQ && (
                                    <InfoIcon
                                        iconColor={styles.infoIcon.color}
                                        onPress={() =>
                                            onQuestionPress(
                                                item.id,
                                                isNegativeBalance
                                                    ? howToSlideAboutNegativeBalance
                                                    : 0
                                            )
                                        }
                                    />
                                )}
                                <Text style={styles.title}>
                                    {i18n.t(
                                        `wallet.rewards.rewardsCarousel.${item.id}`
                                    )}
                                </Text>
                            </View>
                            <Text
                                style={styles.amount}
                                numberOfLines={1}
                                ellipsizeMode="middle">
                                {priceString(item.igupAmount, Coin.vigup)}
                            </Text>
                            {isAvailableRewards && (
                                <Text
                                    style={styles.usd}
                                    numberOfLines={1}
                                    ellipsizeMode="middle">
                                    {`≈ ${item.usdPrice || 0.0} $`}
                                </Text>
                            )}
                        </View>

                        <Text style={styles.info}>
                            {i18n.t(
                                `wallet.rewards.rewardsCarousel.${item.id}_text`
                            )}
                        </Text>
                        {showActiveStaking &&
                            !!currentPlanBoost &&
                            !!currentStakingPlan &&
                            !!stakingPlans && (
                                <ActiveStakingButton
                                    boost={currentPlanBoost}
                                    color={getStakingPlanColor(
                                        currentStakingPlan.stakingPlanId,
                                        stakingPlans
                                    )}
                                    onPress={() =>
                                        navigation.navigate(
                                            ROUTES.STAKING_ALREADY
                                        )
                                    }
                                />
                            )}
                    </View>
                </View>
            );
        },
        [
            windowWidth,
            itemWidth,
            currentPlanBoost,
            currentStakingPlan,
            stakingPlans,
            showActiveStaking,
        ]
    );

    return (
        <Carousel
            data={data}
            sliderWidth={windowWidth}
            itemWidth={itemCustomWidth}
            renderItem={(item) => renderItem(item)}
            pagination
            height={Math.floor(windowWidth / 1.61)}
            itemStyle={styles.itemStyle}
        />
    );
};
export default React.memo(RewardsCarousel);
