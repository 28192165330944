import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';
import { stylesAlignCenter } from '@helpers/style';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            borderBottomWidth: 1,
            borderBottomColor: Colors[theme].border.primary,
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: 'transparent',
        },
        last: {
            borderBottomWidth: 0,
        },
        shadow: {
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].shadow.default,
        },
        image: {
            width: 72,
            height: 72,
            ...stylesAlignCenter(false),
            marginBottom: 4,
            borderRadius: moderateScale(12),
            borderWidth: 1,
            backgroundColor: Colors[theme].background,
            borderColor: Colors[theme].border.primary,
        },
        left: {
            backgroundColor: 'transparent',
            marginRight: scale(12),
        },
        right: { backgroundColor: 'transparent', flex: 1 },

        title: {
            marginBottom: verticalScale(2),
        },

        progressBar: {
            marginTop: verticalScale(8),
        },
    });

export default styles;
