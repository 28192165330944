import * as React from 'react';

import _ from 'lodash';

import { Charities, Charity } from '@Data/Models';
import { CHARITIES } from '@Data/Requests';
import { useLazyQuery } from '@apollo/client';
import { toastErrorUnknown } from '@helpers/toastNotification';

import { useUser } from './UserContext';

export type RankContextType = {
    charities: Charity[];
    refetch: () => void;
};

const CharityContext = React.createContext<RankContextType>({
    charities: [],
    refetch: () => null,
});

const CharityProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const { user } = useUser();
    const [charities, setCharities] = React.useState<Charity[]>([]);
    const [getCharities] = useLazyQuery<Charities>(CHARITIES);

    const getData = React.useCallback(() => {
        getCharities({
            onError: (error) => {
                Console.log(error);
                toastErrorUnknown();
            },
            onCompleted: (result) => {
                console.log({ result });
                setCharities(result.charities);
            },
        });
    }, [getCharities]);

    React.useEffect(() => {
        if (user?.id) getData();
    }, [user?.id, getData]);

    return (
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        <CharityContext.Provider value={{ charities, refetch: getData }}>
            {children}
        </CharityContext.Provider>
    );
};

export default CharityProvider;

export const useCharity = () => {
    const { charities } = React.useContext(CharityContext);

    const getCharityById = React.useCallback(
        (id: string) =>
            charities.find((item) => item.id === id) || charities[0],
        [charities]
    );

    return {
        charities,
        getCharityById,
    };
};
