import * as React from 'react';
import { KeyboardAvoidingView, ScrollView } from 'react-native';

import ButtonsBottom from '@components/ButtonsBottom';
import FlowStepIndicator, {
    STEP_INDICATOR_FLOWS,
} from '@components/FlowStepIndicator';
import { MARKETPLACE_CREATE_ORDER_NAME } from '@components/FlowStepIndicator/flows/marketplaceCreateOrder';
import CoinIcon from '@components/Icons/CoinIcon';
import Input from '@components/Input';
import ModalLoader from '@components/ModalLoader';
import SafeAreaTabContent from '@components/SafeAreaTabContent';
import { Text, View } from '@components/Themed';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { isIOS, isWeb } from '@helpers/app';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';

import { MarketPlaceSelectNftPriceProps } from '../../../../types';
import MarketPlaceCreateOrderConfirmationModal from '../components/MarketPlaceCreateOrderConfirmationModal';
import MarketPlaceSellablePetInfoCard from '../components/MarketPlaceSellablePetInfoCard';
import { useMarketPlaceSelectNftPriceScreen } from './hooks/useMarketPlaceSelectNftPriceScreen';
import stylesMain from './styles';

const SCREEN = 'MarketPlaceSelectNftPriceScreen';

const MarketPlaceSelectNftPriceScreen = ({
    route,
}: MarketPlaceSelectNftPriceProps) => {
    const styles = useThemedStyles(stylesMain);
    const { pet } = route.params;

    const {
        handleOnBack,
        values,
        handleOnBlur,
        handleChangeAmount,
        errors,
        submitForm,
        isValid,
        close,
        isVisible,
        confirm,
        isLoading,
        loadingCheckApprove,
        renderBlockchainErrorsModal,
        estimatedFee,
        isBNBEnough,
        renderFeeInfoIcon,
        commissionFee,
        totalSummary,
        usdPrice,
    } = useMarketPlaceSelectNftPriceScreen(pet);

    return (
        <KeyboardAvoidingView
            behavior={isIOS ? 'padding' : 'height'}
            style={styles.container}>
            <SafeAreaTabContent>
                <ModalLoader
                    isVisible={isLoading}
                    text={i18n.t('checkout.loadingPurchasing')}
                    timeout={0}
                />
                <NavigationBar
                    onBack={handleOnBack}
                    filled={false}
                    backIcon="leftArrow"
                    title={i18n.t(`MarketPlace.${SCREEN}.screenTitle`)}
                />
                <FlowStepIndicator
                    flow={STEP_INDICATOR_FLOWS.MARKETPLACE_CREATE_ORDER}
                    stage={MARKETPLACE_CREATE_ORDER_NAME.STEP_TWO}
                />
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    scrollEventThrottle={16}
                    keyboardShouldPersistTaps="handled"
                    contentContainerStyle={styles.page}>
                    <View style={styles.inputMainContainer}>
                        <View style={styles.inputWrapper}>
                            <View style={styles.icon}>
                                <CoinIcon slug={Coin.igu} size={32} />
                            </View>
                            <Input
                                style={[
                                    styles.inputContainer,
                                    isWeb && {
                                        width: `${Math.max(
                                            values.amount.length,
                                            3
                                        )}ch`,
                                    },
                                ]}
                                value={values.amount}
                                keyboardType="decimal-pad"
                                onBlur={handleOnBlur}
                                onChangeText={handleChangeAmount}
                                placeholder="0"
                                maxLength={11}
                                isAvailableExternalStyle
                                autoComplete="off"
                                importantForAutofill="no"
                                autoCorrect={false}
                            />
                        </View>
                        <Text style={styles.usdPrice}>{usdPrice}</Text>

                        <Text
                            style={[
                                styles.infoText,
                                !!errors.amount && styles.infoTextError,
                            ]}>
                            {errors.amount}
                        </Text>
                    </View>
                    <MarketPlaceSellablePetInfoCard pet={pet} />
                </ScrollView>

                <ButtonsBottom
                    title={i18n.t('general.BUTTONS.continue')}
                    onPress={submitForm}
                    loading={loadingCheckApprove}
                    safe
                    disabled={
                        !isValid ||
                        Number(values.amount) === 0 ||
                        loadingCheckApprove
                    }
                />
                <MarketPlaceCreateOrderConfirmationModal
                    amount={totalSummary}
                    pet={pet}
                    isVisible={isVisible}
                    onClose={close}
                    onConfirm={confirm}
                    estimatedFee={estimatedFee}
                    isBNBEnough={isBNBEnough}
                    infoIcon={renderFeeInfoIcon}
                    commission={commissionFee}
                />
                {renderBlockchainErrorsModal()}
            </SafeAreaTabContent>
        </KeyboardAvoidingView>
    );
};

export default MarketPlaceSelectNftPriceScreen;
