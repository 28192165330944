import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';
import { stylesAlignCenter } from '@helpers/style';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            borderColor: Colors[theme].border.primary,
            borderRadius: 12,
            borderWidth: 1,
        },
        titleBlock: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
        },
        title: {
            flex: 1,
            marginRight: scale(16),
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
            color: Colors[theme].text.primary,
        },
        iconColor: {
            color: Colors[theme].icon.secondary,
        },

        hiddenView: {
            width: '100%',
            position: 'absolute',
            zIndex: -1,
            opacity: 0,
        },
        dropdownBlock: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            borderRadius: 12,
            paddingLeft: scale(10),
            paddingRight: scale(16),
            paddingTop: verticalScale(20),
            paddingBottom: verticalScale(4),
            borderColor: Colors[theme].border.primary,
            borderTopWidth: 1,
        },
        loading: {
            ...stylesAlignCenter(false),
            position: 'absolute',
            width: '100%',
            height: '100%',
            opacity: 0.6,
            zIndex: 1,
        },
    });

export default styles;
