import * as React from 'react';

import Input from '@components/Input';
import Colors from '@constants/Colors';
import { useTheme } from '@contexts/ThemeContext';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    banComment: string;
    onTextChange: (val: string) => void;
    error: string;
}

const BanComment = ({ banComment, onTextChange, error }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { theme } = useTheme();

    return (
        <Input
            style={styles.input}
            onChangeText={(text) => onTextChange(text)}
            value={banComment}
            placeholder={i18n.t(
                'profile.adminBlockUser.banComment.placeHolder'
            )}
            label={i18n.t('profile.adminBlockUser.banComment.label')}
            placeholderTextColor={Colors[theme].text.secondary}
            numberOfLines={10}
            error={error}
            multiline
            maxLength={1000}
            autoCapitalize="sentences"
        />
    );
};

export default BanComment;
