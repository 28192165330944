import * as React from 'react';
import { useEffect } from 'react';
import { FlatList, ScrollView, View } from 'react-native';

import { Pet } from '@Data/Models';
import Divider from '@components/Divider';
import ModalBottom from '@components/ModalBottom';
import ModalDeposit from '@components/ModalDeposit';
import ModalPetIsPlaying from '@components/ModalPetIsPlaying';
import ModalSpaceId from '@components/ModalSpaceId';
import PetCollectionListItem from '@components/PetCollectionListItem';
import PullToRefresh from '@components/PullToRefresh';
import SafeAreaTabContent from '@components/SafeAreaTabContent';
import SpaceIdCard from '@components/SpaceIdCard';
import WalletAddress from '@components/WalletAddress';
import { PlayContext } from '@contexts/PlayContext';
import { useUser } from '@contexts/UserContext';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { isWeb } from '@helpers/app';
import useGetTokenTransactions from '@hooks/useGetTokenTransactions';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';
import NavigationWallet, {
    NAVIGATION_WALLET,
} from '@navigation/NavigationBar/NavigationWallet';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import WalletButtons from '../../components/WalletButtons';
import WalletNFTBalance from '../components/WalletNFTBalance';
import WalletNFTCollection from '../components/WalletNFTCollection';
import WalletNFTSTransactionHistoryList from '../components/WalletNFTSTransactionHistoryList';
import stylesMain from './styles';

const NFTSMainScreen = () => {
    const { walletBalance, walletData } = useWallet();
    const navigation = useNavigation();
    const styles = useThemedStyles(stylesMain);
    const { reloadUser } = useUser();

    const [isVisibleModalDeposit, setIsVisibleModalDeposit] =
        React.useState<boolean>(false);
    const [isVisibleModalWithdraw, setIsVisibleModalWithdraw] =
        React.useState<boolean>(false);
    const [petSelected, setPetSelected] = React.useState<Pet | null>(null);

    const { pets, refetchPets, petsLoading, isPetPlaying } =
        React.useContext(PlayContext);

    const contractAddress = walletBalance?.nft?.contractAddress;

    const {
        tokenTransactions,
        getTokenTransactions,
        getTokenTransactionsLoadMore,
        loadMoreLoading,
        pullToRefreshLoading,
        transactionsLoading,
    } = useGetTokenTransactions(contractAddress);

    const filteredPets = React.useMemo(
        () => pets?.filter((pet) => pet.isMinted) || [],
        [pets, walletBalance]
    );

    useEffect(() => {
        getTokenTransactions(true);
    }, []);

    useEffect(() => {
        refetchPets(true);
        getTokenTransactions(true);
    }, [walletBalance]);

    const onRefresh = React.useCallback(() => {
        refetchPets(false);
        getTokenTransactions(false);
    }, []);

    const handleSettingsPress = () => {
        navigation.navigateToWalletSettings();
    };

    const handleCloseModalWithdraw = () => {
        setIsVisibleModalWithdraw(false);
    };

    const handleOnDepositPress = () => {
        setIsVisibleModalDeposit(true);
    };
    const handleOnWithdrawPress = () => {
        setIsVisibleModalWithdraw(true);
    };

    const handleOnPetPress = async (pet: Pet) => {
        handleCloseModalWithdraw();

        navigation.navigate(ROUTES.WALLET_NFTS_WITHDRAW, { pet });
    };

    const handleOnOpenPetIsPlaying = async (pet: Pet) => {
        handleCloseModalWithdraw();

        setPetSelected(pet);
    };

    const {
        isVisible: spaceIdModal,
        open: openSpaceIdModal,
        close: closeSpaceIdModal,
    } = useVisibleHook();
    const renderItem = React.useCallback(() => {
        return (
            <>
                <View style={styles.walletNftBalanceWrapper}>
                    <WalletNFTBalance balance={filteredPets?.length || 0} />
                </View>
                {!!walletData?.wallet?.address && (
                    <View style={styles.walletAddressWrapper}>
                        <WalletAddress
                            address={walletData.wallet.address}
                            shouldUseUsername
                            onInfoIcon={openSpaceIdModal}
                            children={
                                <ModalSpaceId
                                    isVisible={spaceIdModal}
                                    onClose={closeSpaceIdModal}
                                />
                            }
                        />
                    </View>
                )}

                <View style={styles.buttonsWrapper}>
                    <WalletButtons
                        onDepositPress={handleOnDepositPress}
                        onWithdrawPress={handleOnWithdrawPress}
                        disableWithdraw={filteredPets?.length === 0}
                    />
                </View>

                <SpaceIdCard containerStyle={styles.banner} />

                <Divider customStyles={styles.divider} />

                <View style={styles.listWrapper}>
                    <WalletNFTCollection
                        pets={filteredPets}
                        loading={petsLoading}
                    />
                </View>
                <Divider customStyles={styles.divider} />
                {!!filteredPets.length && (
                    <View style={styles.listWrapper}>
                        <WalletNFTSTransactionHistoryList
                            transactions={tokenTransactions}
                            pets={filteredPets}
                            onDepositPress={handleOnDepositPress}
                            loading={transactionsLoading}
                            loadMore={loadMoreLoading}
                            onReachEnd={getTokenTransactionsLoadMore}
                        />
                    </View>
                )}
            </>
        );
    }, [
        walletData,
        filteredPets,
        tokenTransactions,
        petsLoading,
        transactionsLoading,
        loadMoreLoading,
        getTokenTransactionsLoadMore,
        spaceIdModal,
    ]);

    const renderDepositModal = () => (
        <ModalDeposit
            isVisible={isVisibleModalDeposit}
            setIsVisible={setIsVisibleModalDeposit}
            titleText={i18n.t('wallet.nft.deposit')}
        />
    );

    const renderModalItem = (item: Pet, index: number) => {
        const isPlaying = isPetPlaying(item);

        return (
            <PetCollectionListItem
                pet={item}
                onPressPet={() =>
                    !isPlaying
                        ? handleOnPetPress(item)
                        : handleOnOpenPetIsPlaying(item)
                }
                border
                style={index === filteredPets.length - 1 && styles.bottomGap}
                playablePetConfirmation={isPlaying}
                onPressPlayablePet={() => {
                    handleOnOpenPetIsPlaying(item);
                }}
                key={index}
            />
        );
    };

    const renderWithdrawModal = () => (
        <ModalBottom
            isVisible={isVisibleModalWithdraw}
            onClose={handleCloseModalWithdraw}
            titleText={i18n.t('wallet.nft.withdraw')}
            modalHeight="75%">
            <View style={styles.listStyle}>
                {isWeb ? (
                    <ScrollView showsVerticalScrollIndicator={false}>
                        {filteredPets.map((item, index) =>
                            renderModalItem(item, index)
                        )}
                    </ScrollView>
                ) : (
                    <FlatList
                        keyboardShouldPersistTaps={'always'}
                        removeClippedSubviews={false}
                        showsVerticalScrollIndicator={false}
                        data={filteredPets}
                        renderItem={({ item, index }) =>
                            renderModalItem(item, index)
                        }
                        keyExtractor={(item) => item.id}
                        extraData={pets}
                    />
                )}
                {renderPetIsPlayingModal()}
            </View>
        </ModalBottom>
    );

    const renderPetIsPlayingModal = () => {
        return (
            <>
                {!!petSelected && (
                    <ModalPetIsPlaying
                        isVisible={!!petSelected}
                        pet={petSelected}
                        onClose={() => {
                            setPetSelected(null);
                        }}
                        onPress={() => handleOnPetPress(petSelected)}
                    />
                )}
            </>
        );
    };

    return (
        <SafeAreaTabContent>
            <View style={styles.container}>
                <NavigationBar
                    backIcon
                    title={i18n.t('wallet.screenTitle')}
                    settingsIcon
                    onPressSettings={handleSettingsPress}
                />
                <NavigationWallet currentNavigation={NAVIGATION_WALLET.NFT} />
                <PullToRefresh
                    refreshing={pullToRefreshLoading}
                    textLoading={i18n.t('pullToRefresh.reloadBalance.text')}
                    onRefresh={onRefresh}
                    renderItem={renderItem}
                />
                {renderDepositModal()}
                {renderWithdrawModal()}
                {renderPetIsPlayingModal()}
            </View>
        </SafeAreaTabContent>
    );
};

export default NFTSMainScreen;
