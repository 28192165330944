import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import Icon from '@assets/icons/emptyState/howToTopUp.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import ClickableCard from '@components/ClickableCard';
import GamingWalletChangeFeature from '@components/GamingWalletChangeFeature';
import CoinIcon from '@components/Icons/CoinIcon';
import ModalBottom from '@components/ModalBottom';
import { useGamingWallet } from '@contexts/GamingWalletContext';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
    modalHeight?: number | string;
    available: string;
    onConfirm: () => void;
}

const SelectWithdrawTokenModal = ({
    isVisible,
    onClose,
    modalHeight = 700,
    available,
    onConfirm,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { priceInUsd, iguToBNB, priceInBUSD } = useWallet();

    const { selectedWithdrawToken, setSelectedWithdrawToken } =
        useGamingWallet();

    const balanceIgu = useMemo(
        () => balanceFromWei(available).valueLong,
        [available]
    );

    const balanceIguInUSD = useMemo(
        () => `≈ ${priceInUsd(Coin.igu, balanceFromWei(available).value)}$`,
        [available]
    );

    const balanceBUSD = useMemo(
        () => priceInBUSD(Coin.bnb, iguToBNB(balanceFromWei(available).value)),
        [available]
    );

    const balanceBUSDInUSD = useMemo(
        () => `≈ ${priceInUsd(Coin.busd, balanceBUSD)}$`,
        [available]
    );

    const handleSelect = useCallback(
        (id: Coin) => {
            if (selectedWithdrawToken === id) {
                return;
            }
            setSelectedWithdrawToken(id);
        },
        [selectedWithdrawToken]
    );

    return (
        <ModalBottom
            isVisible={isVisible}
            onClose={onClose}
            modalHeight={modalHeight}>
            <ScrollView
                style={styles.container}
                showsVerticalScrollIndicator={false}>
                <TouchableOpacity activeOpacity={1}>
                    <BlockHeader
                        containerStyle={styles.header}
                        type="primary"
                        title={i18n.t(
                            'wallet.gaming.SelectWithdrawTokenModal.title'
                        )}
                        text={i18n.t(
                            'wallet.gaming.SelectWithdrawTokenModal.text'
                        )}
                        emptyStateIcon={<Icon />}
                    />

                    <ClickableCard
                        containerStyle={styles.card}
                        icon={<CoinIcon size={32} slug={Coin.igu} />}
                        iconNameText={Coin.igu}
                        title={balanceIgu}
                        text={balanceIguInUSD}
                        onPress={() => handleSelect(Coin.igu)}
                        isSelected={selectedWithdrawToken === Coin.igu}
                        debouncedPress
                        withArrow={false}
                        labelStyle={styles.label}
                        textStyle={styles.text}
                    />
                    <ClickableCard
                        disabled
                        containerStyle={styles.card}
                        icon={<CoinIcon size={32} slug={Coin.busd} />}
                        iconNameText={Coin.busd}
                        title={balanceBUSD}
                        text={balanceBUSDInUSD}
                        onPress={() => handleSelect(Coin.busd)}
                        isSelected={selectedWithdrawToken === Coin.busd}
                        debouncedPress
                        withArrow={false}
                        labelStyle={styles.label}
                        textStyle={styles.text}
                    />

                    <GamingWalletChangeFeature customStyles={styles.feature} />
                </TouchableOpacity>
            </ScrollView>
            <ButtonsBottom
                title={i18n.t('wallet.gaming.SelectWithdrawTokenModal.button')}
                safe
                onPress={onConfirm}
            />
        </ModalBottom>
    );
};

export default SelectWithdrawTokenModal;
