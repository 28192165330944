import React, { useCallback } from 'react';
import { View } from 'react-native';
import { CountdownCircleTimer } from 'react-native-countdown-circle-timer';

import useThemedStyles from '@hooks/useThemedStyles';

import stylesMain from './styles';

interface IProps {
    isLoaded: boolean;
    onComplete: () => void;
    loadingComponent: React.ReactNode;
    loadedComponent: React.ReactNode;
    staticComponent: React.ReactNode;
    isOneTheme?: boolean;
    duration?: number;
    color?: string;
    trailColor?: string;
    shouldRepeat?: boolean;
    isPlaying: boolean;
}

export const CircleProgress = ({
    loadingComponent,
    loadedComponent,
    staticComponent,
    onComplete,
    isLoaded,
    isOneTheme,
    duration = 0,
    color,
    trailColor,
    shouldRepeat,
    isPlaying,
}: IProps) => {
    const styles = useThemedStyles(stylesMain, isOneTheme);

    const renderTime = useCallback(() => {
        return (
            <View
                style={[
                    styles.inside,
                    isLoaded ? styles.loaded : styles.loading,
                ]}>
                {isPlaying
                    ? isLoaded
                        ? loadedComponent
                        : loadingComponent
                    : staticComponent}
            </View>
        );
    }, [isLoaded, isPlaying]);

    return (
        <CountdownCircleTimer
            isPlaying={isPlaying}
            duration={duration}
            //@ts-ignore
            colors={color}
            strokeWidth={3}
            size={56}
            //@ts-ignore
            trailColor={trailColor}
            onComplete={() => {
                onComplete();
                return { shouldRepeat };
            }}>
            {renderTime}
        </CountdownCircleTimer>
    );
};

export default CircleProgress;
