import React from 'react';

import Off from '@assets/icons/profile/admin/maintenanceOff.svg';
import On from '@assets/icons/profile/admin/maintenanceOn.svg';
import { useAppState } from '@contexts/AppStateContext';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import MenuItem from '../../../MenuItem';
import { RightComponent, iconSize } from '../../helpers';
import stylesMain from './styles';

const AdminMaintenanceModeMenuItem = () => {
    const styles = useThemedStyles(stylesMain);
    const { isFullMaintenance } = useAppState();
    const navigation = useNavigation();

    const icon = React.useMemo(
        () =>
            isFullMaintenance ? (
                <On width={iconSize} height={iconSize} />
            ) : (
                <Off width={iconSize} height={iconSize} />
            ),
        [isFullMaintenance]
    );

    return (
        <MenuItem
            leftIcon={icon}
            title={i18n.t('profile.menuItems.adminMaintenanceMode.title')}
            description={i18n.t(
                'profile.menuItems.adminMaintenanceMode.description'
            )}
            rightComponent={<RightComponent type="arrowRight" />}
            onPress={() => navigation.navigate(ROUTES.ADMIN_MAINTENANCE_MODE)}
            titleStyle={styles.titleStyle}
            textStyle={styles.textStyle}
        />
    );
};

export default AdminMaintenanceModeMenuItem;
