import * as React from 'react';

import CopyToClipboardButton from '@components/CopyToClipboardButton';
import { Text, View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';

import stylesMain from './styles';

interface IProps {
    code: string;
    text?: string;
    defaultBg?: boolean;
}

const ReferralCode = ({ code, text, defaultBg = false }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const referralCode = code.toUpperCase();

    return (
        <View style={[styles.container, defaultBg && styles.defaultBg]}>
            {!!text && <Text style={styles.text}>{text}</Text>}
            <View style={styles.right}>
                <Text style={styles.code}>{`#${referralCode}`}</Text>
                <CopyToClipboardButton value={referralCode} />
            </View>
        </View>
    );
};
export default ReferralCode;
