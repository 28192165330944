import * as React from 'react';

import { LinearGradient } from 'expo-linear-gradient';

import ReferralCodeBanner from '../../../../../assets/icons/referrals/referralCode.svg';
import Button from '../../../../../components/Button';
import { Text } from '../../../../../components/Themed';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import stylesMain from './styles';

interface IProps {
    onButtonPress: () => void;
}

const EnterReferralCode = ({ onButtonPress }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <LinearGradient
            colors={['#63A8DA', '#E5FFE2']}
            style={styles.gradientBackground}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 1 }}>
            <Text style={styles.title}>
                {i18n.t('referrals.referralCard.title')}
            </Text>
            <Text style={styles.text}>
                {i18n.t('referrals.referralCard.text')}
            </Text>

            <Button
                title={i18n.t('referrals.referralCard.button')}
                type="outline"
                size="md"
                onPress={onButtonPress}
                style={styles.buttonStyle}
                containerStyle={styles.button}
                textStyles={styles.darkText}
            />
            <ReferralCodeBanner
                height={118}
                width={100}
                style={styles.referralCodeImage}
            />
        </LinearGradient>
    );
};
export default EnterReferralCode;
