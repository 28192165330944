import { StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import { scale, verticalScale } from '../../../../helpers/dimensions';
import font from '../../../../helpers/font';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        navBar: {
            position: 'absolute',
            backgroundColor: 'transparent',
            justifyContent: 'space-between',
            width: '100%',
        },
        subtitle: {
            color: Colors[theme].text.primary,
            ...font({
                size: 18,
                height: 24,
                weight: '700',
            }),
        },
        donationsTitleContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingBottom: verticalScale(16),
        },
        divider: {
            marginTop: verticalScale(4),
            marginBottom: verticalScale(16),
        },
        paddingHorizontal: {
            paddingHorizontal: scale(20),
        },
        paddingBottom: {
            paddingBottom: verticalScale(34),
        },
        heartContainer: {
            width: 32,
            height: 32,
            backgroundColor: Colors[theme].background,
            borderColor: Colors[theme].border.primary,
            borderWidth: 1,
            borderRadius: 16,
            alignItems: 'center',
            justifyContent: 'center',
        },
    });

export default styles;
