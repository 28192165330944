import * as React from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';

import { StatusBar } from 'expo-status-bar';
import * as WebBrowser from 'expo-web-browser';
import 'resize-observer-polyfill';
import 'setimmediate';
import 'text-encoding-polyfill';

import '@ethersproject/shims';

import CharityProvider from '@contexts/CharityContexts';
import MysteryGamesProvider from '@contexts/MysteryGamesContext';
import { setLanguage } from '@i18n/i18n';

import ErrorBoundary from './components/ErrorBoundary';
import ToastNotification from './components/ToastNotification';
import AccessibilitySettingsProvider from './contexts/AccessibilitySettingsContext';
import AppLoadingProvider from './contexts/AppLoading/AppLoadingContext';
import AppStateProvider from './contexts/AppStateContext';
import ConfigProvider from './contexts/ConfigContext';
import DimensionsProvider from './contexts/DimensionsContext';
import IguApolloProvider from './contexts/IguApolloContext';
import KeyboardProvider from './contexts/KeyboardContext';
import PlayProvider from './contexts/PlayContext';
import RankProvider from './contexts/RanksContext';
import SessionProvider from './contexts/SessionContext';
import StakingProvider from './contexts/StakingContext';
import ThemeProvider from './contexts/ThemeContext';
import TradeProvider from './contexts/Trade/TradeContext';
import UserProvider from './contexts/UserContext';
import WalletProvider from './contexts/Wallet/WalletContext';
import './global';
import NavigationContainer from './navigation/NavigationContainer';
import './polyfills/localstorage-polyfill';
import ErrorReporting from './utils/ErrorReporting';
import useDisablePinchZoomEffect from './web-src/hooks/useDisablePinchZoomEffect';

setLanguage();

WebBrowser.maybeCompleteAuthSession();

function App() {
    useDisablePinchZoomEffect();

    React.useEffect(() => {
        ErrorReporting.setup();
    }, []);

    return (
        <ThemeProvider>
            <ErrorBoundary>
                <SessionProvider>
                    <IguApolloProvider>
                        <ConfigProvider>
                            <UserProvider>
                                <RankProvider>
                                    <WalletProvider>
                                        <SafeAreaProvider>
                                            <DimensionsProvider>
                                                <KeyboardProvider>
                                                    <AppLoadingProvider>
                                                        <AppStateProvider>
                                                            <AccessibilitySettingsProvider>
                                                                <PlayProvider>
                                                                    <TradeProvider>
                                                                        <StakingProvider>
                                                                            <MysteryGamesProvider>
                                                                                <CharityProvider>
                                                                                    <NavigationContainer />
                                                                                    <StatusBar />
                                                                                    <ToastNotification />
                                                                                </CharityProvider>
                                                                            </MysteryGamesProvider>
                                                                        </StakingProvider>
                                                                    </TradeProvider>
                                                                </PlayProvider>
                                                            </AccessibilitySettingsProvider>
                                                        </AppStateProvider>
                                                    </AppLoadingProvider>
                                                </KeyboardProvider>
                                            </DimensionsProvider>
                                        </SafeAreaProvider>
                                    </WalletProvider>
                                </RankProvider>
                            </UserProvider>
                        </ConfigProvider>
                    </IguApolloProvider>
                </SessionProvider>
            </ErrorBoundary>
        </ThemeProvider>
    );
}

export default App;
