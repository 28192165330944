import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { verticalScale } from '../../helpers/dimensions';
import { theme } from '../../types';

const stylesMain = (theme: theme) =>
    StyleSheet.create({
        container: {
            marginTop: verticalScale(12),
            width: '32%',
        },
        textStyles: {
            textAlign: 'center',
        },
        activeBg: {
            backgroundColor: Colors[theme].shadow.selected,
        },
        activeBorder: {
            borderColor: Colors[theme].border.accent,
        },
    });

export default stylesMain;
