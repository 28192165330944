import * as React from 'react';

import AppVersionIcon from '../../../../../../assets/icons/profile/appVersion.svg';
import { useUser } from '../../../../../../contexts/UserContext';
import { isProduction } from '../../../../../../helpers/app';
import ROUTES from '../../../../../../navigation/routes';
import { useNavigation } from '../../../../../../navigation/useNavigation';
import MenuItem from '../../../MenuItem';
import { RightComponent, iconSize } from '../../helpers';

const DevMenuItem = () => {
    const navigation = useNavigation();
    const { isAdmin } = useUser();
    // hide dev options for other users
    const hasAccess = isAdmin || !isProduction();

    if (!hasAccess) {
        return null;
    }

    const title = 'Developers';
    const description = 'Developer tools for building and testing IguVerse app';

    return (
        <MenuItem
            leftIcon={<AppVersionIcon width={iconSize} height={iconSize} />}
            title={title}
            description={description}
            rightComponent={<RightComponent type="arrowRight" />}
            onPress={() => navigation.navigate(ROUTES.DEV)}
        />
    );
};

export default DevMenuItem;
