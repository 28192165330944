import { StyleSheet } from 'react-native';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        image: {
            justifyContent: 'center',
            alignItems: 'center',
            width: 180,
            height: 180,
            right: -10,
        },
    });

export default styles;
