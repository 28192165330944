import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            marginTop: verticalScale(32),
            paddingHorizontal: scale(20),
        },
        main: {
            flex: 1,
        },
        header: {
            marginBottom: verticalScale(20),
        },
        divider: {
            marginVertical: verticalScale(16),
        },
        itemWrapper: {
            paddingVertical: verticalScale(16),
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            backgroundColor: Colors[theme].background,
        },
        itemContainer: {
            paddingHorizontal: scale(16),
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: 'transparent',
        },

        left: {
            flex: 1,
            marginRight: scale(12),
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: 'transparent',
        },

        iconColor: {
            color: Colors[theme].icon.secondary,
        },
        textBlock: {
            flex: 1,
            backgroundColor: 'transparent',
            marginLeft: scale(12),
        },
        textGap: {
            marginBottom: verticalScale(2),
        },

        progressBar: {
            marginHorizontal: 0,
            marginTop: verticalScale(8),
            backgroundColor: 'transparent',
        },
    });

export default styles;
