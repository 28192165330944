import { StyleSheet } from 'react-native';

import { isWeb } from '@helpers/app';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        card: {
            width: '45%',
        },

        container: {
            flexDirection: 'row',
            marginLeft: isWeb ? '5%' : 0,
        },
    });

export default styles;
