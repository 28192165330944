import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { isWeb } from '@helpers/app';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const stylesMain = (theme: theme) =>
    StyleSheet.create({
        container: {
            position: 'absolute',
            alignItems: 'center',
            paddingTop: 20,
            paddingBottom: 60,
            transform: [{ translateY: 60 }],
            backgroundColor: Colors[theme].background,
            width: '100%',
        },

        containerBanner: {
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(12),
            borderRadius: moderateScale(16),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            backgroundColor: Colors[theme].background,
            marginBottom: verticalScale(4),
            overflow: 'hidden',
        },
        top: {
            flexDirection: 'row',
            alignContent: 'center',
            alignItems: 'center',
        },
        textBlock: {
            flex: 1,
            marginLeft: scale(8),
            backgroundColor: 'transparent',
            width: '75%',
            zIndex: 2,
        },
        textGap: {
            marginBottom: verticalScale(4),
        },
        image: {
            position: 'absolute',
            right: -20,
            bottom: isWeb ? -70 : -60,
            width: 150,
            height: 150,
            zIndex: 1,
        },
    });

export default stylesMain;
