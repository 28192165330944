import * as React from 'react';
import { ScrollView } from 'react-native';

import { Text, View } from '@components/Themed';

import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import NavigationBar from '../../../navigation/NavigationBar';
import LanguageOptions from '../components/LanguagesOptions';
import stylesMain from '../styles';
import languagesSettingsStyles from './styles';

const LanguagesSettingsScreen = () => {
    const mainStyles = useThemedStyles(stylesMain);
    const styles = useThemedStyles(languagesSettingsStyles);

    return (
        <View style={mainStyles.container}>
            <NavigationBar
                backIcon
                title={i18n.t('profile.languageSettings.title')}
                filled={false}
            />
            <ScrollView style={mainStyles.page}>
                <View style={mainStyles.textContainer}>
                    <Text style={mainStyles.subtitle}>
                        {i18n.t('profile.languageSettings.subtitle')}
                    </Text>
                    <Text
                        style={[
                            mainStyles.description,
                            styles.languageDescription,
                        ]}>
                        {i18n.t('profile.languageSettings.description')}
                    </Text>
                </View>
                <LanguageOptions />
            </ScrollView>
        </View>
    );
};

export default LanguagesSettingsScreen;
