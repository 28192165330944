import React from 'react';

import { useQuery } from '@apollo/client';

import { GET_GAME_MAINTENANCE_INFO } from '../../../Data/Requests';
import { useApollo } from '../../../contexts/IguApolloContext';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import Animation from '../../Animation';
import CustomModal from '../../CustomModal';
import { View } from '../../Themed';
import stylesMain from './styles';

const GameMaintenanceState = () => {
    const styles = useThemedStyles(stylesMain);

    const { isGameMaintenanceModal, setIsGameMaintenanceModal } = useApollo();

    const { data } = useQuery(GET_GAME_MAINTENANCE_INFO, {
        pollInterval: 10000,
        fetchPolicy: 'network-only',
    });

    const handleModalClose = () => {
        setIsGameMaintenanceModal(false);
    };

    return (
        <>
            <CustomModal
                isVisible={isGameMaintenanceModal}
                icon={
                    <View style={styles.image}>
                        <Animation
                            animation="maintenance"
                            style={styles.image}
                        />
                    </View>
                }
                titleText={i18n.t('AppState.GameMaintenanceState.title')}
                infoText={i18n.t('AppState.GameMaintenanceState.text')}
                firstButtonText={i18n.t('AppState.GameMaintenanceState.button')}
                onFirstButtonClick={handleModalClose}
            />
        </>
    );
};

export default GameMaintenanceState;
