import * as React from 'react';
import { useEffect } from 'react';
import { FlatList } from 'react-native';

import _ from 'lodash';

import { MintContext } from '@contexts/MintContext';
import { prefetchImage } from '@helpers/media';
import { toastErrorUnknown } from '@helpers/toastNotification';
import { useAIInfo } from '@hooks/useAIInfo';

import {
    ImaginationFormItem,
    ImaginationFormResponse,
} from '../../../Data/Models';
import ButtonsBottom from '../../../components/ButtonsBottom';
import { STEP_INDICATOR_FLOWS } from '../../../components/FlowStepIndicator';
import { MINT_PET_STAGES_NAME } from '../../../components/FlowStepIndicator/flows/mintNFTStages';
import { View } from '../../../components/Themed';
import {
    NFT_GENERATION_OPTIONS,
    NFT_GENERATION_OPTIONS_NAME,
} from '../../../constants/NFTGenerationOptions';
import useImaginationFormData from '../../../hooks/useImaginationFormData';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import ROUTES from '../../../navigation/routes';
import { useNavigation } from '../../../navigation/useNavigation';
import MintContainer from '../components/MintContainer';
import MintHeaderText from '../components/MintHeaderText';
import SelectableWay from '../components/SelectableWay';
import stylesMain from './styles';

const MintPetSelectGenerationScreen = () => {
    const styles = useThemedStyles(stylesMain);

    const navigation = useNavigation();
    const [selectableOption, setSelectableOption] =
        React.useState<NFT_GENERATION_OPTIONS_NAME | null>(null);
    const { setAIFormData, setCurrentFlow, resetPet } =
        React.useContext(MintContext);
    const { aiQuota, dailySystemLoad, currentSystemLoad, usagesLeft } =
        useAIInfo();
    const { getImaginationFormData, error } = useImaginationFormData();
    // loading of the state
    const [loading, setLoading] = React.useState<boolean>(false);
    // whether state was chosen
    const [pressedNext, setPressedNext] = React.useState<boolean>(false);
    // state to store form data
    const [generationData, setGenerationData] = React.useState<
        ImaginationFormResponse | undefined
    >();

    const preloadForm = async () => {
        setLoading(true);
        try {
            const { data } = await getImaginationFormData();
            if (data) {
                const urls = _.compact(
                    data.text2ImageData
                        .map((o) => o.options.map((i) => i.icon))
                        .flat()
                );
                await prefetchImage(urls);
                setLoading(false);
            }
            setGenerationData(data);
        } finally {
            setLoading(false);
        }
    };

    const handleSelectOption = (id: NFT_GENERATION_OPTIONS_NAME) => {
        if (id === selectableOption) {
            return;
        }
        setSelectableOption(id);
    };

    // navigate on loading finished
    useEffect(() => {
        if (pressedNext && !loading && generationData) {
            setPressedNext(false);
            navigateToMintGeneration(generationData?.text2ImageData);
        }
    }, [pressedNext, generationData, loading]);

    const handleGoNext = async () => {
        resetPet();
        setCurrentFlow(selectableOption);
        if (selectableOption === 'IMAGINATION') {
            setPressedNext(true);
        } else {
            navigation.navigate(ROUTES.MINT_PET_PICK_PHOTO);
        }
    };

    const navigateToMintGeneration = (
        formsData: ImaginationFormItem[] | undefined
    ) => {
        setAIFormData(formsData);

        navigation.navigate(ROUTES.MINT_GENERATION_INFO);
    };

    // start loading for in background
    useEffect(() => {
        preloadForm();
    }, []);

    useEffect(() => {
        if (error) {
            Console.log(error);
            toastErrorUnknown();
        }
    }, [error]);

    return (
        <>
            <MintContainer
                icon="leftArrow"
                flow={STEP_INDICATOR_FLOWS.MINT_PET}
                stage={MINT_PET_STAGES_NAME.STEP_ONE}
                stageFill="75%">
                <MintHeaderText
                    title={i18n.t('nftSelectGeneration.pageTitle')}
                    text={i18n.t('nftSelectGeneration.pageInfo')}
                />

                <FlatList
                    bounces={false}
                    style={styles.listStyle}
                    data={NFT_GENERATION_OPTIONS}
                    showsHorizontalScrollIndicator={false}
                    renderItem={({ item, index }) => (
                        <View
                            style={
                                index + 1 !== NFT_GENERATION_OPTIONS.length &&
                                styles.gap
                            }>
                            <SelectableWay
                                key={index}
                                option={item}
                                selectedOption={selectableOption}
                                onPress={handleSelectOption}
                                aiQuota={aiQuota}
                                dailySystemLoad={dailySystemLoad}
                                currentSystemLoad={currentSystemLoad}
                                usagesLeft={usagesLeft}
                            />
                        </View>
                    )}
                />
                <ButtonsBottom
                    title={i18n.t('nftSelectGeneration.continue')}
                    onPress={handleGoNext}
                    disabled={!selectableOption}
                    loading={pressedNext && loading}
                    loadingText={i18n.t('nftSelectGeneration.loadingModalText')}
                />
            </MintContainer>
        </>
    );
};
export default MintPetSelectGenerationScreen;
