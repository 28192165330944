import { useMemo } from 'react';

import { STEP_INDICATOR_FLOWS } from '@components/FlowStepIndicator';
import { STAKING_ADD_MORE_NAME } from '@components/FlowStepIndicator/flows/stakingAddMore';
import { STAKING_STAGE_NAME } from '@components/FlowStepIndicator/flows/stakingStages';
import { useStaking } from '@contexts/StakingContext';
import { useStakingHook } from '@hooks/useStakingHook';
import { useVisibleHook } from '@hooks/useVisibleHook';

export const useStakingDailyBoostScreen = () => {
    const {
        currentPlan,
        stakingBoost,
        stakingAmount,
        stakingCoin,
        currentStakingPlan,
        isAddMoreCoins,
    } = useStaking();

    const {
        open: handleOpenConfirmationModal,
        close: handleCloseConfirmationModal,
        isVisible: isVisibleConfirmationModal,
        setIsVisible: setIsVisibleConfirmationModal,
    } = useVisibleHook();

    const isLoaded = useMemo(
        () => !!currentPlan && stakingBoost >= 0 && !!stakingAmount,
        [currentPlan, stakingBoost, stakingAmount]
    );

    const { onStakePress, isLoading, renderBlockchainErrorsModal } =
        useStakingHook();

    const indicatorFlow = useMemo(
        () =>
            isAddMoreCoins
                ? STEP_INDICATOR_FLOWS.STAKING_ADD_MORE
                : STEP_INDICATOR_FLOWS.STAKING,
        [isAddMoreCoins]
    );
    const indicatorStage = useMemo(
        () =>
            isAddMoreCoins
                ? STAKING_ADD_MORE_NAME.STEP_TWO
                : STAKING_STAGE_NAME.STEP_FOUR,
        [isAddMoreCoins]
    );

    return {
        currentPlan,
        stakingBoost,
        stakingAmount,
        stakingCoin,
        currentStakingPlan,
        isAddMoreCoins,
        handleOpenConfirmationModal,
        handleCloseConfirmationModal,
        isVisibleConfirmationModal,
        setIsVisibleConfirmationModal,
        onStakePress,
        isLoading,
        renderBlockchainErrorsModal,
        indicatorFlow,
        indicatorStage,
        isLoaded,
    };
};
