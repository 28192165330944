import React, { useCallback, useMemo } from 'react';

import _ from 'lodash';

import { GET_IGU_TRANSACTION_HISTORY } from '@Data/Requests';
import { useApolloClient } from '@apollo/client';
import { DESKTOP_LOAD_LIMIT } from '@constants/API';
import { isDesktop } from '@helpers/app';
import { errorsHandler } from '@helpers/errors';
import {
    IGU_WALLET_TRANSACTIONS,
    IguWalletTransaction,
    PaginatedIguWalletTransactionsOutput,
} from '@models/gamingWallet';

import { useLoadingHook } from './useLoadingHook';

export const gamingWalletTransactionsFilterConfig: {
    defaultFilter: IGU_WALLET_TRANSACTIONS;
    filters: IGU_WALLET_TRANSACTIONS[];
} = {
    defaultFilter: IGU_WALLET_TRANSACTIONS.All,
    filters: [
        IGU_WALLET_TRANSACTIONS.All,
        IGU_WALLET_TRANSACTIONS.Deposit,
        IGU_WALLET_TRANSACTIONS.Withdraw,
        IGU_WALLET_TRANSACTIONS.DepositBonus,
        IGU_WALLET_TRANSACTIONS.MysteryLikesGameWin,
        IGU_WALLET_TRANSACTIONS.MysteryLikesGamePayment,
        IGU_WALLET_TRANSACTIONS.MysteryLikesGameDraw,
        IGU_WALLET_TRANSACTIONS.Breeding,
        IGU_WALLET_TRANSACTIONS.NftRoyaleGameWin,
        IGU_WALLET_TRANSACTIONS.NftRoyaleGamePayment,
        IGU_WALLET_TRANSACTIONS.NftRoyaleGameDraw,
        IGU_WALLET_TRANSACTIONS.NftRoyaleGameFee,
        IGU_WALLET_TRANSACTIONS.NftRoyaleGameRefund,
        IGU_WALLET_TRANSACTIONS.LuckyStrikeGameWin,
        IGU_WALLET_TRANSACTIONS.LuckyStrikeGamePayment,
        IGU_WALLET_TRANSACTIONS.QuantumSpin,
        IGU_WALLET_TRANSACTIONS.QuantumSpinReward,
        IGU_WALLET_TRANSACTIONS.TokentellerGamePredictWin,
        IGU_WALLET_TRANSACTIONS.TokentellerGamePredict,
        IGU_WALLET_TRANSACTIONS.TokentellerGamePredictFee,
        IGU_WALLET_TRANSACTIONS.TokentellerGamePredictRefund,
        IGU_WALLET_TRANSACTIONS.GamesTurnoverReward,
        IGU_WALLET_TRANSACTIONS.MysteryLikesGameRefund,
        IGU_WALLET_TRANSACTIONS.MysteryLikesGameFee,
        IGU_WALLET_TRANSACTIONS.MysteryLikesGameDirectReferralBonus,
        IGU_WALLET_TRANSACTIONS.MysteryLikesGameIndirectReferralBonus,
        IGU_WALLET_TRANSACTIONS.DirectReferralDepositBonus,
        IGU_WALLET_TRANSACTIONS.IndirectReferralDepositBonus,
    ],
};

const useGamingWalletTransactionsHistory = (
    startFilter?: IGU_WALLET_TRANSACTIONS
) => {
    const client = useApolloClient();

    const [filter, setFilter] = React.useState<IGU_WALLET_TRANSACTIONS>(
        startFilter || gamingWalletTransactionsFilterConfig.defaultFilter
    );

    const {
        isLoading: transactionHistoryLoading,
        startLoading: startTransactionHistoryLoading,
        stopLoading: stopTransactionHistoryLoading,
    } = useLoadingHook();

    const {
        isLoading: refreshLoading,
        startLoading: startRefreshLoading,
        stopLoading: stopRefreshLoading,
    } = useLoadingHook();

    const {
        isLoading: filterLoading,
        startLoading: startFilterLoading,
        stopLoading: stopFilterLoading,
    } = useLoadingHook();
    const {
        isLoading: loadMore,
        startLoading: startLoadMore,
        stopLoading: stopLoadMore,
    } = useLoadingHook();

    const [transactionHistory, setTransactionHistory] =
        React.useState<IguWalletTransaction[]>();

    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);

    const setTypes = (filter?: string) => {
        return filter && filter !== IGU_WALLET_TRANSACTIONS.All
            ? [filter]
            : null;
    };

    const getIguTransactionHistory = useCallback(
        async (silent: boolean) => {
            try {
                silent
                    ? startTransactionHistoryLoading()
                    : startRefreshLoading();

                const result =
                    await client.query<PaginatedIguWalletTransactionsOutput>({
                        query: GET_IGU_TRANSACTION_HISTORY,
                        fetchPolicy: 'network-only',
                        variables: {
                            paginationInput: {
                                types: setTypes(filter),
                                limit: isDesktop ? DESKTOP_LOAD_LIMIT : 10,
                            },
                        },
                    });

                if (result.data) {
                    setTransactionHistory(
                        result.data.iguTransactionHistory.items
                    );
                    setCurrentPage(
                        result.data.iguTransactionHistory.meta.currentPage
                    );
                    setTotalPages(
                        result.data.iguTransactionHistory.meta.totalPages
                    );
                }
                stopRefreshLoading();
                stopTransactionHistoryLoading();
            } catch (error) {
                stopRefreshLoading();
                stopTransactionHistoryLoading();
                errorsHandler(error, true);
            }
        },
        [client, filter]
    );

    const getIguTransactionHistoryFilter = useCallback(
        async (item: IGU_WALLET_TRANSACTIONS, force = false) => {
            try {
                if (item === filter && !force) {
                    return;
                }
                setFilter(item);
                startFilterLoading();

                const result =
                    await client.query<PaginatedIguWalletTransactionsOutput>({
                        query: GET_IGU_TRANSACTION_HISTORY,
                        fetchPolicy: 'network-only',
                        variables: {
                            paginationInput: {
                                types: setTypes(item),
                                limit: isDesktop ? DESKTOP_LOAD_LIMIT : 10,
                            },
                        },
                    });

                if (result.data) {
                    setTransactionHistory(
                        result.data.iguTransactionHistory.items
                    );
                    setCurrentPage(
                        result.data.iguTransactionHistory.meta.currentPage
                    );
                    setTotalPages(
                        result.data.iguTransactionHistory.meta.totalPages
                    );
                }
                stopFilterLoading();
            } catch (error) {
                stopFilterLoading();
                errorsHandler(error, true);
            }
        },
        [client, filter]
    );

    const getIguTransactionHistoryLoadMore = useCallback(async () => {
        try {
            if (currentPage === totalPages || totalPages === 0) {
                return;
            }
            startLoadMore();

            const result =
                await client.query<PaginatedIguWalletTransactionsOutput>({
                    query: GET_IGU_TRANSACTION_HISTORY,
                    fetchPolicy: 'network-only',
                    variables: {
                        paginationInput: {
                            types: setTypes(filter),
                            page: currentPage + 1,
                            limit: isDesktop ? DESKTOP_LOAD_LIMIT : 10,
                        },
                    },
                });

            if (result.data) {
                setTransactionHistory((prev) => {
                    return prev
                        ? _.uniqBy(
                              [
                                  ...prev,
                                  ...result.data.iguTransactionHistory.items,
                              ],
                              (o) => o.id
                          )
                        : result.data.iguTransactionHistory.items;
                });

                setCurrentPage(
                    result.data.iguTransactionHistory.meta.currentPage
                );
                setTotalPages(
                    result.data.iguTransactionHistory.meta.totalPages
                );
            }
            stopLoadMore();
        } catch (error) {
            stopLoadMore();
            errorsHandler(error, true);
        }
    }, [client, filter, totalPages, currentPage]);

    const canLoadMore = useMemo(
        () => currentPage !== totalPages,
        [currentPage, totalPages]
    );

    return {
        transactionHistoryLoading,
        refreshLoading,
        filterLoading,
        loadMore,
        transactionHistory,
        getIguTransactionHistory,
        getIguTransactionHistoryFilter,
        getIguTransactionHistoryLoadMore,
        filter,
        canLoadMore,
    };
};

export default useGamingWalletTransactionsHistory;
