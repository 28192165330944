import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
            textAlign: 'center',
        },
        titleNoTail: {
            textAlign: 'center',
            backgroundColor: 'transparent',
        },
        titleWrapper: {
            backgroundColor: 'transparent',
            marginHorizontal: scale(16),
        },
    });

export default styles;
