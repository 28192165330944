import React from 'react';

import AssignRoleIcon from '@assets/icons/profile/admin/assignRole.svg';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import MenuItem from '../../../MenuItem';
import { RightComponent, iconSize } from '../../helpers';
import stylesMain from './styles';

const AdminRoleMenuItem = () => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    return (
        <MenuItem
            leftIcon={<AssignRoleIcon width={iconSize} height={iconSize} />}
            title={i18n.t('profile.menuItems.assignRole.title')}
            description={i18n.t('profile.menuItems.assignRole.description')}
            rightComponent={<RightComponent type="arrowRight" />}
            onPress={() => navigation.navigate(ROUTES.ADMIN_ASSIGN_ROLE)}
            titleStyle={styles.titleStyle}
            textStyle={styles.textStyle}
        />
    );
};

export default AdminRoleMenuItem;
