import * as React from 'react';
import { ScrollView } from 'react-native';

import { View } from '@components/Themed';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import {
    NFT_ROYAL_GAME_HISTORY_STATUS,
    NFT_ROYAL_GAME_MODE,
    NftRoyaleGameHistoryOutput,
} from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import GamesHistoryButtons from '../GamesHistoryButtons';
import NftRoyalGamesSettings from '../NftRoyalGamesSettings';
import NftRoyalGamesStatistics from '../NftRoyalGamesStatistics';
import NftRoyalHistoryBlockHeader from '../NftRoyalHistoryBlockHeader';
import NftRoyalHistoryWaiting from '../NftRoyalHistoryWaiting';
import PlayVSBlock from '../PlayVSBlock';
import stylesMain from './styles';

interface IProps {
    history: NftRoyaleGameHistoryOutput;
    force?: boolean | undefined;
}

const NftRoyalHistoryWrapper = ({ history, force }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const { nftRoyaleGameConfig } = useMysteryGames();

    const navigateToGame = () => {
        navigation.navigate(ROUTES.NFT_ROYAL_ACTIVE_ORDERS);
    };
    const backToHistory = () => {
        navigation.navigate(ROUTES.NFT_ROYAL_HISTORY);
    };
    const retry = () => {
        navigation.replace(ROUTES.NFT_ROYAL_GAME_CONFIGURATIONS);
    };

    const {
        status,
        iguAmountWei,
        opponentUsername,
        opponentAvatarUrl,
        opponentScore,
        myFruitsCount,
        myLivesCount,
        myScore,
        complexity,
        mode,
        opponentFruitsCount,
        livesCount,
    } = history;

    if (status === NFT_ROYAL_GAME_HISTORY_STATUS.WaitingForOpponent) {
        return (
            <NftRoyalHistoryWaiting
                status={status}
                exploreAction={navigateToGame}
                toHistoryAction={backToHistory}
            />
        );
    }

    const { isVisible, close } = useVisibleHook(true);

    const canRetry = status === NFT_ROYAL_GAME_HISTORY_STATUS.Lost;
    const isDraw = status === NFT_ROYAL_GAME_HISTORY_STATUS.Draw;
    const isFruitGame = mode === NFT_ROYAL_GAME_MODE.Fruit;

    Console.info(isFruitGame);

    const renderFirstState = () => {
        if (force) {
            return null;
        }

        return (
            <View style={[styles.firstState, styles[status]]}>
                <View style={styles.block}>
                    <NftRoyalHistoryBlockHeader
                        status={status}
                        iguAmountWei={iguAmountWei}
                        isBigHeader
                    />
                </View>
                <GamesHistoryButtons
                    firstButtonAction={canRetry ? retry : navigateToGame}
                    firstButtonTitle={
                        canRetry
                            ? i18n.t('general.BUTTONS.tryAgain')
                            : i18n.t('general.BUTTONS.exploreMoreGames')
                    }
                    secondButtonTitle={i18n.t('general.BUTTONS.viewStatistics')}
                    secondButtonAction={close}
                    firstButtonType="outline"
                    isOneTheme
                    white
                />
            </View>
        );
    };

    const userWin = !isDraw
        ? (isFruitGame ? myFruitsCount || 0 : myScore || 0) >
          (isFruitGame ? opponentFruitsCount || 0 : opponentScore || 0)
        : true;

    const creatorWin = !isDraw
        ? (isFruitGame ? myFruitsCount || 0 : myScore || 0) <
          (isFruitGame ? opponentFruitsCount || 0 : opponentScore || 0)
        : true;

    return (
        <View style={styles.safe}>
            {isVisible && renderFirstState()}
            <View style={styles.main}>
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    style={styles.container}>
                    <NftRoyalHistoryBlockHeader
                        status={status}
                        iguAmountWei={iguAmountWei}
                    />
                    <PlayVSBlock
                        userWin={userWin}
                        creatorWin={creatorWin}
                        userLabel={i18n.t('general.KEYS.yourPoints')}
                        userTitle={`${
                            isFruitGame ? myFruitsCount || 0 : myScore || 0
                        }`}
                        creatorLabel={i18n.t('general.KEYS.opponentsPoints')}
                        creatorTitle={`${
                            isFruitGame
                                ? opponentFruitsCount || 0
                                : opponentScore || 0
                        }`}
                        creatorUri={opponentAvatarUrl}
                        creatorWalletAddress={opponentUsername || ''}
                        alert={i18n.t('HomeScreen.NftRoyal.lockPeriodWarning', {
                            lockedDays:
                                nftRoyaleGameConfig?.rewardUnlockDaysCount || 0,
                        })}
                    />
                    <NftRoyalGamesStatistics
                        containerStyle={styles.gap}
                        score={myScore}
                        lives={myLivesCount}
                        maxLives={livesCount}
                        fruits={myFruitsCount}
                    />
                    <NftRoyalGamesSettings
                        containerStyle={styles.gap}
                        mode={mode}
                        livesCount={livesCount}
                        difficulty={complexity}
                    />
                </ScrollView>
            </View>
            <GamesHistoryButtons
                firstButtonAction={navigateToGame}
                secondButtonAction={backToHistory}
            />
        </View>
    );
};

export default NftRoyalHistoryWrapper;
