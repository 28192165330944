import * as React from 'react';
import { TouchableOpacity } from 'react-native';
import Animated, {
    cancelAnimation,
    useAnimatedStyle,
    useSharedValue,
    withDelay,
    withRepeat,
    withSequence,
    withSpring,
    withTiming,
} from 'react-native-reanimated';

import MysteryBox from '../../../../../assets/icons/mysteryBoxes/box.svg';
import StarsIcon from '../../../../../assets/icons/mysteryBoxes/stars.svg';
import ButtonsBottom from '../../../../../components/ButtonsBottom';
import ExtendedModal from '../../../../../components/ExtendedModal';
import { ICON_NAMES } from '../../../../../components/Icons';
import RankIcon from '../../../../../components/Icons/RankIcon';
import { View } from '../../../../../components/Themed';
import Colors from '../../../../../constants/Colors';
import { useDimensions } from '../../../../../contexts/DimensionsContext';
import { useTheme } from '../../../../../contexts/ThemeContext';
import { isWeb, useNativeDriver } from '../../../../../helpers/app';
import { wait } from '../../../../../helpers/helpers';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import NavigationBarModal from '../../../../../navigation/NavigationBar/NavigationBarModal';
import CustomModalBackdrop from '../../../../../web-src/components/CustomModalBackdrop';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    close: () => void;
    rankId: string;
    onAnimationEndUpdate: () => void;
    onButtonPress: () => void;
}

const config = {
    amountOfClick: 3,
    wait: 5000,
};

const MysteryBoxOpenModal = ({
    isVisible,
    close,
    rankId,
    onAnimationEndUpdate,
    onButtonPress,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { theme } = useTheme();
    const { dimensions } = useDimensions();
    const showBox = useSharedValue(0);
    const showRank = useSharedValue(1);
    const rankOpacity = useSharedValue(0);
    const clickAmount = React.useRef<number>(0);
    const buttonShare = useSharedValue(0);
    const starsShare = useSharedValue(0);
    const boxShared = useSharedValue(8);
    const boxSharedScale = useSharedValue(1);
    const starsSharedScale = useSharedValue(1);
    const boxSharedStyle = useAnimatedStyle(() => {
        return {
            transform: [
                { rotate: `${boxShared.value}deg` },
                { scale: withSpring(boxSharedScale.value) },
            ],
            display: showBox.value ? 'none' : 'flex',
            zIndex: 1,
        };
    });

    const starsSharedStyle = useAnimatedStyle(() => {
        return {
            opacity: starsShare.value,
            zIndex: 1,
            transform: [{ scale: withSpring(starsSharedScale.value) }],
        };
    });
    const rankSharedStyle = useAnimatedStyle(() => {
        return {
            display: showRank.value ? 'none' : 'flex',
            opacity: rankOpacity.value,
        };
    });
    const buttonSharedStyle = useAnimatedStyle(() => {
        return {
            opacity: buttonShare.value,
            zIndex: 1,
        };
    });

    React.useEffect(() => {
        boxShared.value = withDelay(
            100,
            withRepeat(
                withTiming(-8, {
                    duration: 1000,
                }),
                -1,
                true
            )
        );
    }, []);

    React.useEffect(() => {
        const autoOpen = async () => {
            await wait(config.wait);
            openAnimation();
        };
        autoOpen();
    }, []);

    const openAnimation = () => {
        cancelAnimation(buttonShare);
        boxSharedScale.value = withTiming(1.6);
        starsShare.value = 0;
        showBox.value = withDelay(1000, withSpring(1));
        rankOpacity.value = withDelay(400, withSpring(1));
        showRank.value = withDelay(410, withSpring(0));
        starsSharedScale.value = withTiming(1);
        starsShare.value = withDelay(
            1020,
            withSequence(
                withTiming(1),
                withTiming(1, {
                    duration: 1300,
                }),
                withTiming(0)
            )
        );
        buttonShare.value = withDelay(
            1500,
            withTiming(1, {
                duration: 1000,
            })
        );
        onAnimationEndUpdate();
    };

    const boxPress = () => {
        if (clickAmount.current === config.amountOfClick) {
            return;
        }
        boxSharedScale.value = withSequence(
            withTiming(1.4, {
                duration: 300,
            }),
            withTiming(1)
        );
        starsSharedScale.value = withSequence(
            withTiming(1.4, {
                duration: 300,
            }),
            withTiming(1)
        );
        starsShare.value = withSequence(
            withTiming(1),
            withTiming(1, {
                duration: 300,
            }),
            withTiming(0)
        );
        clickAmount.current = clickAmount.current + 1;
        if (clickAmount.current === config.amountOfClick) {
            openAnimation();
            return;
        }
    };

    const handleViewDetails = async () => {
        close();

        onButtonPress();
    };

    return (
        <ExtendedModal
            isVisible={isVisible}
            backdropColor={Colors[theme].modal.modalOverlay}
            backdropOpacity={Colors[theme].modal.opacity}
            customBackdrop={isWeb && <CustomModalBackdrop />}
            useNativeDriverForBackdrop={useNativeDriver}
            useNativeDriver={useNativeDriver}
            hideModalContentWhileAnimating
            deviceWidth={dimensions.screen.deviceWidth}
            deviceHeight={dimensions.screen.deviceHeight}
            animationIn="zoomIn"
            animationOut="slideInDown"
            animationInTiming={600}
            animationOutTiming={600}
            backdropTransitionInTiming={600}
            backdropTransitionOutTiming={600}
            style={styles.modal}>
            <View style={styles.shadow}>
                <Animated.View style={[styles.header, buttonSharedStyle]}>
                    <NavigationBarModal
                        iconName={ICON_NAMES.CLOSE}
                        title={''}
                        onBack={close}
                    />
                </Animated.View>
                <View style={styles.container}>
                    <View style={styles.box}>
                        <Animated.View style={[styles.stars, starsSharedStyle]}>
                            <StarsIcon />
                        </Animated.View>
                        <Animated.View style={[boxSharedStyle]}>
                            <TouchableOpacity
                                activeOpacity={1}
                                style={styles.mysteryBoxesIcon}
                                onPress={boxPress}
                                onLongPress={openAnimation}>
                                <MysteryBox />
                            </TouchableOpacity>
                        </Animated.View>
                        <Animated.View style={[rankSharedStyle]}>
                            <RankIcon slug={rankId} size={170} />
                        </Animated.View>
                    </View>
                </View>
                <Animated.View style={buttonSharedStyle}>
                    <ButtonsBottom
                        containerStyle={styles.button}
                        title={i18n.t('mysteryBoxes.openRank.button')}
                        onPress={handleViewDetails}
                        safe
                    />
                </Animated.View>
            </View>
        </ExtendedModal>
    );
};
export default MysteryBoxOpenModal;
