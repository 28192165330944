import * as React from 'react';
import { FlatList } from 'react-native';

import StateIcon from '@assets/icons/yourPetsAreHungry/state.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import { ICON_NAMES } from '@components/Icons';
import PetCollectionListItem from '@components/PetCollectionListItem';
import { View } from '@components/Themed';
import { useModal } from '@contexts/ModalContext';
import { usePlay } from '@contexts/PlayContext';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBarModal from '@navigation/NavigationBar/NavigationBarModal';
import useNavigationListeners from '@navigation/hooks/useNavigationListeners';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import stylesMain from './styles';

const YourPetsAreHungryScreen = () => {
    useNavigationListeners();
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const { setModalClosed } = useModal();

    const { playablePets } = usePlay();

    const pets = React.useMemo(() => {
        if (playablePets) {
            return playablePets.filter((pet) => pet.health < 100);
        }
    }, [playablePets]);

    React.useEffect(() => {
        () => setModalClosed();
    }, []);

    const onBack = () => {
        navigation.pop();
    };

    return (
        <View style={styles.main}>
            <View style={styles.header}>
                <NavigationBarModal
                    iconName={ICON_NAMES.CLOSE}
                    onBack={onBack}
                />
            </View>
            {!!pets && (
                <FlatList
                    data={pets}
                    showsVerticalScrollIndicator={false}
                    style={styles.container}
                    ListHeaderComponent={
                        <View style={styles.top}>
                            <View style={styles.img}>
                                <StateIcon />
                            </View>
                            <BlockHeader
                                type="emptyState"
                                title={i18n.t('YourPetsAreHungry.title')}
                                text={i18n.t('YourPetsAreHungry.text')}
                            />
                        </View>
                    }
                    renderItem={({ item }) => (
                        <PetCollectionListItem
                            key={item.id}
                            pet={item}
                            isNeedToFeed={item.healUpAvailable}
                            fromScreenModal
                            style={styles.petItem}
                            isDead={item.reviveAvailable}
                            isAgeDead={item.reviveAgeAvailable}
                            border
                            navigateBackOnUpgrade={ROUTES.PLAY}
                            navigateBackFromCheckout={
                                ROUTES.YOUR_PETS_ARE_HUNGRY
                            }
                            shouldCallRevive={item.reviveAvailable}
                            shouldCallFeed={item.healUpAvailable}
                            // onPressPet={() => handleOnPetPress(item)}
                        />
                    )}
                />
            )}
        </View>
    );
};

export default YourPetsAreHungryScreen;
