import { ethers } from 'ethers';

import {
    MoveTaskItem,
    MoveTaskResponseItem,
    PlayTaskItem,
    PlayTaskResponseItem,
    User,
} from '@Data/Models';
import { OperationVariables } from '@apollo/client';

export const playTaskDoneParameters = (
    user: User,
    playTask: PlayTaskResponseItem,
    gainedPoints: number,
    livesCount: number,
    fruitsCount: number
): OperationVariables => {
    const signatureString = `${user?.id}${playTask.id}${gainedPoints}${livesCount}${fruitsCount}`;
    const bytes = new TextEncoder().encode(signatureString);
    const signature = ethers.utils.sha256(bytes).split('0x')[1];
    return {
        taskId: playTask.id,
        signature: signature,
        gainedPoints,
        livesCount,
        fruitsCount,
    };
};

export const moveTaskCanStart = (playTask?: MoveTaskItem) => playTask?.canStart;

export const playTaskInSandBox = (playTask?: PlayTaskItem) =>
    !!playTask?.currentTask?.status;

export const playTaskCanBeComplete = (playTask: PlayTaskItem | null) =>
    playTask?.currentTask?.status === 'CREATED';
