import * as React from 'react';
import { useCallback, useContext } from 'react';
import { FlatList, LayoutRectangle } from 'react-native';

import { Pet } from '@Data/Models';
import Button from '@components/Button';
import EmptyList from '@components/EmptyList';
import HowItWorks from '@components/HowItWorks';
import { ICON_NAMES } from '@components/Icons';
import LoadingComponent from '@components/LoadingComponent';
import PullToRefresh from '@components/PullToRefresh';
import SafeAreaCurvedTabContent from '@components/SafeAreaCurvedTabContent';
import { Text, View } from '@components/Themed';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { PlayContext } from '@contexts/PlayContext';
import { useTheme } from '@contexts/ThemeContext';
import { isWeb } from '@helpers/app';
import { isEmptyCardPets } from '@helpers/helpers';
import { sortPetsByLastTransferAndCreated } from '@helpers/sort';
import useMysteryBoxStatSale from '@hooks/mysteryBoxes/useMysteryBoxStatSale';
import { useShowBanner } from '@hooks/useShowBanner';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { BreedingPetDraftOutput } from '@models/mysteryGames';
import NavigationCollections from '@navigation/NavigationBar/NavigationCollections';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { useFocusEffect } from '@react-navigation/native';

import HealAllButton from '../components/HealAllButton';
import PetCollectionBreedDraftCard from '../components/PetCollectionBreedDraftCard';
import PetCollectionCard from '../components/PetCollectionCard';
import PetCollectionEmptyCard from '../components/PetCollectionEmptyCard';
import stylesMain from './styles';

const screenConfig = {
    emptyCards: ['1', '2', '3'],
};

export const CollectionAllScreen = () => {
    const navigation = useNavigation();
    const styles = useThemedStyles(stylesMain);
    const { theme } = useTheme();
    const { pets, refetchPets, petsLoading, petsLoaded } =
        useContext(PlayContext);

    const {
        breedingPetDrafts,
        getBreedingPetDrafts,
        breedingPetDraftsLoading,
    } = useMysteryGames();
    const [viewHeight, setViewHeight] = React.useState<number>(0);

    const [cardSize, setCardSize] = React.useState<LayoutRectangle>();

    const { showBanner, canShowBanner, hideBanner } = useShowBanner({
        name: 'MysteryBoxBanner',
        type: 'toast',
        autoHide: 10000,
        onPress: () => {
            navigation.navigate(ROUTES.MYSTERY_BOX_MAIN);
        },
    });

    const { statSaleData } = useMysteryBoxStatSale();

    // fetch pets
    useFocusEffect(
        useCallback(() => {
            refetchPets(true);
            getBreedingPetDrafts(true);
            return () => {
                hideBanner();
            };
        }, [])
    );

    // show mystery boxes banner
    useFocusEffect(
        useCallback(() => {
            if (statSaleData?.lootboxSaleStats?.startsAt && canShowBanner) {
                showBanner();
            }
        }, [canShowBanner, statSaleData])
    );

    const onRefresh = React.useCallback(() => {
        refetchPets(false);
        getBreedingPetDrafts(false);
    }, []);

    const handleMintPetPress = () => {
        navigation.navigateToMint(undefined, true);
    };

    const handleOpenNftDetailed = (pet: Pet) => {
        navigation.navigateToPetDetails(pet);
    };

    const isAddEmptyCards = React.useMemo(
        () => !!pets?.length && isEmptyCardPets(pets),
        [pets]
    );
    const sortedPets = React.useMemo(
        () =>
            pets?.length
                ? [...pets].sort(sortPetsByLastTransferAndCreated)
                : [],
        [pets]
    );
    const petsData = React.useMemo(() => {
        if (breedingPetDrafts?.length) {
            return [
                ...breedingPetDrafts,
                ...sortedPets,
                ...(isAddEmptyCards
                    ? screenConfig.emptyCards.slice(sortedPets.length - 1)
                    : []),
            ];
        }

        return [
            ...sortedPets,
            ...(isAddEmptyCards
                ? screenConfig.emptyCards.slice(sortedPets.length - 1)
                : []),
        ];
    }, [sortedPets, isAddEmptyCards, breedingPetDrafts]);

    const typeGuardIsPet = (
        pet: Pet | string | BreedingPetDraftOutput
    ): pet is Pet => {
        return !!(pet as Pet).creatorAddress;
    };

    const handleHowItWorks = () => {
        navigation.push(ROUTES.HOW_TO_COLLECTIONS);
    };

    const renderCard = (
        item: Pet | string | BreedingPetDraftOutput,
        size: LayoutRectangle | undefined
    ) => {
        if (typeof item === 'string') {
            if (size) {
                return <PetCollectionEmptyCard size={size} />;
            }
            return null;
        }

        if (!typeGuardIsPet(item)) {
            return size?.width ? (
                <PetCollectionBreedDraftCard size={size} pet={item} />
            ) : null;
        }
        return (
            <PetCollectionCard
                onLayout={isWeb ? setCardSize : !size ? setCardSize : undefined}
                pet={item}
                onPressPet={() => handleOpenNftDetailed(item)}
            />
        );
    };
    // TODO: rework that
    const renderItem = React.useCallback(() => {
        return (
            <>
                <NavigationCollections currentNavigation="COLLECTIONS" />

                {petsLoaded && !pets?.length && !!viewHeight && (
                    <EmptyList
                        viewHeight={viewHeight}
                        emptyTitle={i18n.t(
                            'collectionsScreen.noCollectionsYet'
                        )}
                        emptyText={i18n.t(
                            'collectionsScreen.noCollectionsYetInfo'
                        )}
                        emptyButtonText={i18n.t('collectionsScreen.mintNewNft')}
                        onPressButton={handleMintPetPress}
                        withAnimation="cry"
                    />
                )}

                {petsLoaded && (
                    <FlatList
                        refreshing={petsLoading || breedingPetDraftsLoading}
                        showsVerticalScrollIndicator={false}
                        initialNumToRender={10}
                        ListHeaderComponentStyle={styles.headerText}
                        ListHeaderComponent={
                            <>
                                {pets?.length ? (
                                    <View style={styles.headerText}>
                                        <View style={styles.titleContainer}>
                                            <Text style={styles.title}>
                                                {i18n.t(
                                                    'collectionsScreen.title'
                                                )}
                                            </Text>
                                            <View
                                                style={styles.buttonsContainer}>
                                                <View
                                                    style={
                                                        styles.buttonHealAll
                                                    }>
                                                    <HealAllButton
                                                        pets={pets}
                                                    />
                                                </View>
                                                <Button
                                                    size="md"
                                                    type="fill"
                                                    iconName={ICON_NAMES.PLUS}
                                                    iconColor="onDark"
                                                    style={styles.buttonMint}
                                                    onPress={handleMintPetPress}
                                                />
                                            </View>
                                        </View>
                                        <Text style={styles.text}>
                                            {i18n.t('collectionsScreen.text')}
                                        </Text>
                                        <HowItWorks
                                            iconDirection="right"
                                            onPress={handleHowItWorks}
                                        />
                                    </View>
                                ) : (
                                    <></>
                                )}
                            </>
                        }
                        style={styles.listStyle}
                        data={petsData}
                        ListFooterComponentStyle={styles.footerStyle}
                        columnWrapperStyle={styles.columnWrapperStyle}
                        numColumns={2}
                        renderItem={({ item }) => renderCard(item, cardSize)}
                    />
                )}

                {!petsLoaded && !petsLoading && !breedingPetDraftsLoading && (
                    <LoadingComponent />
                )}
            </>
        );
    }, [
        pets,
        petsLoaded,
        petsLoading,
        viewHeight,
        theme,
        petsData,
        cardSize,
        breedingPetDraftsLoading,
    ]);

    return (
        <SafeAreaCurvedTabContent>
            <View
                onLayout={(event) => {
                    setViewHeight(event.nativeEvent.layout.height - 100);
                }}
                style={styles.container}>
                <PullToRefresh
                    refreshing={petsLoading}
                    textLoading={i18n.t('pullToRefresh.reloadPets.text')}
                    onRefresh={onRefresh}
                    renderItem={renderItem}
                />
            </View>
        </SafeAreaCurvedTabContent>
    );
};

export default CollectionAllScreen;
