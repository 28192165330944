import { StyleSheet } from 'react-native';

import { moderateScale, scale, verticalScale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const stylesMain = (theme: theme) =>
    StyleSheet.create({
        warningContainer: {
            paddingVertical: verticalScale(12),
            paddingHorizontal: scale(12),
            borderRadius: moderateScale(8),
            flexDirection: 'row',
            alignItems: 'center',
        },
        textContainer: {
            flexShrink: 1,
            backgroundColor: 'transparent',
        },
        text: {
            paddingLeft: 7,
            ...font({
                size: 13,
                weight: '400',
            }),
        },
        warningChildren: {
            backgroundColor: 'transparent',
        },
        infoIcon: {
            backgroundColor: 'transparent',
            marginLeft: scale(4),
        },
        button: {
            marginLeft: scale(12),
        },
    });

export default stylesMain;
