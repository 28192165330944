import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: 'transparent',
            justifyContent: 'space-between',
            marginHorizontal: scale(20),
            marginTop: verticalScale(32),
        },
        featureWrapper: {
            marginVertical: verticalScale(20),
        },
        buttons: {
            paddingHorizontal: 0,
        },
    });

export default styles;
