import * as React from 'react';

import CoinIcon from '@components/Icons/CoinIcon';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT, TYPO_TEXT_COLOR } from '@components/TypoText/types';
import { useTheme } from '@contexts/ThemeContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { priceString } from '@helpers/payments';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import { QuantumSpinGamePrizeOutput } from '@models/mysteryGames';

import stylesMain from './styles';

interface IProps {
    prize: QuantumSpinGamePrizeOutput;
    type?: 'default' | 'opacity';
}

const QuantumSpinChancesTableRow = ({ prize, type = 'default' }: IProps) => {
    const styles = useThemedStyles(stylesMain, true);

    const { iguAmountWei, chance } = prize;

    const { theme } = useTheme();

    const chanceText = React.useMemo(() => `${chance || 0}%`, [chance]);

    const isDefault = React.useMemo(() => type === 'default', [type]);
    const amountBlockStyles = React.useMemo(
        () => (isDefault ? styles.default : styles.opacity),
        [isDefault]
    );

    const isDarkDefault = React.useMemo(
        () => theme === 'dark' && isDefault,
        [theme, isDefault]
    );

    return (
        <View style={styles.container}>
            <View style={styles.prizesColumn}>
                <CoinIcon size={32} slug={Coin.vigu} />
                <TypoText
                    numberOfLines={1}
                    textAlign="left"
                    type={TYPO_TEXT.SUBHEAD_MEDIUM_BOLD}
                    colorType={
                        isDefault
                            ? TYPO_TEXT_COLOR.PRIMARY
                            : TYPO_TEXT_COLOR.ON_DARK
                    }
                    additionalStyles={styles.prize}>
                    {priceString(
                        balanceFromWei(iguAmountWei).valueShort,
                        Coin.vigu
                    )}
                </TypoText>
            </View>

            <View style={styles.chanceColumn}>
                <View style={[styles.amountBlock, amountBlockStyles]}>
                    <TypoText
                        type={TYPO_TEXT.SUBHEAD_SMALL_BOLD}
                        additionalStyles={[
                            styles.chance,
                            isDarkDefault && styles.darkDefault,
                        ]}
                        numberOfLines={1}
                        ellipsizeMode="tail">
                        {chanceText}
                    </TypoText>
                </View>
            </View>
        </View>
    );
};
export default QuantumSpinChancesTableRow;
