import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { scale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            position: 'absolute',
            width: 335,
            borderRadius: 12,
            alignItems: 'center',
            flexDirection: 'column',
            zIndex: 12,
            alignSelf: 'center',
            bottom: 50,
            padding: 8,
            paddingTop: 6,
            backgroundColor: Colors[theme].background,
        },
        progress: {
            backgroundColor: Colors[theme].energyProgress.background,
            minWidth: '100%',
            borderRadius: 1000,
            height: 12,
            position: 'relative',
            flex: 1,
            marginVertical: scale(8),
        },
        text: {
            color: Colors[theme].text.accent,
            ...font({
                size: 14,
                height: 18,
                weight: '600',
            }),
        },
        fill: {
            backgroundColor: Colors[theme].levelIndicatorFillColor,
            borderRadius: 1000,
            height: 12,
        },
    });

export default styles;
