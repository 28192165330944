import * as React from 'react';

import { StakingPlanOutput } from '@Data/Models';
import StakingCardIcon from '@components/Icons/StakingCardIcon';
import { Text, View } from '@components/Themed';
import { StakingCoin } from '@contexts/StakingContext';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import ParticipateInAirDrop from '../ParticipateInAirDrop';
import stylesMain from './styles';

interface IProps {
    data: StakingPlanOutput;
    disabled: boolean;
    coin: StakingCoin;
    isLast: boolean;
}

const StakingCardPlan = ({ data, disabled, coin, isLast }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const {
        color: backgroundColor,
        name,
        maxBoostRewardPercentage: boost,
        durationDays,
    } = data.plan;

    return (
        <View
            style={[
                styles.container,
                { backgroundColor },
                disabled && styles.disabled,
            ]}>
            <View style={styles.coin}>{<StakingCardIcon slug={coin} />}</View>
            <Text style={styles.title}>{name}</Text>
            <Text style={styles.boost}>
                {i18n.t('staking.plans.boost', {
                    boost,
                })}
            </Text>
            <View style={styles.lock}>
                <Text style={styles.number}>
                    {durationDays}
                    <Text style={styles.days}>
                        {i18n.t('staking.plans.days')}
                    </Text>
                </Text>
            </View>
        </View>
    );
};
export default StakingCardPlan;
