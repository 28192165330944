import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { ReferralUsersItem, TotalReferralUserCountsOutput } from '@Data/Models';
import CardActive from '@assets/icons/referrals/cardActive.svg';
import CardDirect from '@assets/icons/referrals/cardDirect.svg';
import CardIndirect from '@assets/icons/referrals/cardIndirect.svg';
import { View } from '@components/Themed';
import { useUser } from '@contexts/UserContext';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import ModalReferee from '../ModalReferee';
import ReferralList from '../ReferralList';
import ReferralTotalCard from '../ReferralTotalCard';
import TurnoverCard from '../TurnoverCard';
import stylesMain from './styles';

interface IProps {
    referrals: ReferralUsersItem[] | undefined;
    totalUsers: TotalReferralUserCountsOutput | undefined;
    containerStyle?: StyleProp<ViewStyle>;
    onReachEnd?: () => void;
    onReachEndLoading?: boolean;
    filter: string;
    handleChooseFilter: (item: string) => void;
    shouldRefresh: boolean;
}

const ReferralInvitedContent = ({
    containerStyle,
    referrals,
    onReachEnd,
    onReachEndLoading,
    totalUsers,
    filter,
    handleChooseFilter,
    shouldRefresh,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { isAdvancedLeader, isAdmin } = useUser();

    const [currentItem, setCurrentItem] = React.useState<ReferralUsersItem>();

    const [modalReferee, setModalReferee] = React.useState(false);

    const onOpenModalReferee = React.useCallback(
        (item: ReferralUsersItem) => {
            setModalReferee(true);
            if (item === currentItem) {
                return;
            }
            setCurrentItem(item);
        },
        [currentItem]
    );

    const onCloseModalReferee = () => {
        setModalReferee(false);
    };

    return (
        <View style={[styles.container, containerStyle]}>
            <View style={styles.cardWrapper}>
                <ReferralTotalCard
                    icon={<CardDirect width={32} height={32} />}
                    containerStyle={styles.cardWidth}
                    amount={totalUsers?.directReferralsCount || 0}
                    text={i18n.t('referrals.invitedReferrals.card.direct')}
                />
                <ReferralTotalCard
                    icon={<CardIndirect width={32} height={32} />}
                    containerStyle={styles.cardWidth}
                    amount={totalUsers?.indirectReferralsCount || 0}
                    text={i18n.t('referrals.invitedReferrals.card.indirect')}
                />
            </View>
            <View style={[styles.cardWrapperGap, styles.cardWrapper]}>
                <ReferralTotalCard
                    icon={<CardActive width={32} height={32} />}
                    containerStyle={styles.cardFull}
                    amount={totalUsers?.activeDirectReferralsCount || 0}
                    text={i18n.t('referrals.invitedReferrals.card.active')}
                />
            </View>
            {(isAdvancedLeader || isAdmin) && (
                <View style={[styles.cardWrapperGap, styles.cardWrapper]}>
                    <TurnoverCard shouldRefresh={shouldRefresh} />
                </View>
            )}
            <View style={styles.list}>
                <ReferralList
                    filter={filter}
                    handleChooseFilter={handleChooseFilter}
                    referrals={referrals}
                    onReachEnd={onReachEnd}
                    onReachEndLoading={onReachEndLoading}
                    onOpenReferee={onOpenModalReferee}
                />
            </View>
            {currentItem && (
                <ModalReferee
                    isVisible={modalReferee}
                    closeAction={onCloseModalReferee}
                    user={currentItem.user}
                />
            )}
        </View>
    );
};
export default ReferralInvitedContent;
