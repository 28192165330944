import React from 'react';
import { FlatList } from 'react-native';

import { useCharity } from '@contexts/CharityContexts';
import { useFocusEffect } from '@react-navigation/native';

import { DonationsHistoryResponseItem } from '../../../../Data/Models';
import EmptyList from '../../../../components/EmptyList';
import { ICON_NAMES } from '../../../../components/Icons';
import LoadingComponent from '../../../../components/LoadingComponent';
import { View } from '../../../../components/Themed';
import { stylesLoadMore } from '../../../../helpers/style';
import useGetDonationsHistory from '../../../../hooks/useGetDonationsHistory';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import NavigationBarModal from '../../../../navigation/NavigationBar/NavigationBarModal';
import useNavigationListeners from '../../../../navigation/hooks/useNavigationListeners';
import { CharityOrganizationDonationsProps } from '../../../../types';
import TotalOrganizationDonations from '../components/TotalOrganizationDonations';
import UserDonate from '../components/UserDonate';
import stylesMain from './styles';

const CharityOrganizationDonationsScreen = ({
    route,
}: CharityOrganizationDonationsProps) => {
    useNavigationListeners();
    const { getCharityById } = useCharity();
    const { charity: id } = route.params;
    const charity = getCharityById(id);
    const styles = useThemedStyles(stylesMain);
    const getDonationsHistory = useGetDonationsHistory();
    const [historyData, setHistoryData] = React.useState<
        DonationsHistoryResponseItem[]
    >([]);
    const [loadMore, setLoadMore] = React.useState(false);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);
    const [limit, setLimit] = React.useState(0);
    const [initialLoading, setInitialLoading] = React.useState(true);

    useFocusEffect(
        React.useCallback(() => {
            getDonationsHistory({
                page: 1,
                limit: 20,
                charityId: charity.id,
                onCompleted: ({ donationsHistory }) => {
                    setHistoryData(donationsHistory.items);
                    setCurrentPage(donationsHistory.meta.currentPage);
                    setTotalPages(donationsHistory.meta.totalPages);
                    setLimit(donationsHistory.meta.itemsPerPage);
                    setInitialLoading(false);
                },
                onError: () => {
                    setLoadMore(false);
                    setInitialLoading(false);
                },
            });
        }, [])
    );

    const onReachEnd = () => {
        if (currentPage === totalPages) {
            return;
        }
        setLoadMore(true);

        getDonationsHistory({
            page: currentPage + 1,
            limit,
            charityId: charity.id,
            onCompleted: ({ donationsHistory }) => {
                setLoadMore(false);
                setHistoryData((prevData) => [
                    ...prevData,
                    ...donationsHistory.items,
                ]);
                setCurrentPage(donationsHistory.meta.currentPage);
            },
            onError: () => setLoadMore(false),
        });
    };

    const renderUsersDonations = () => (
        <>
            {initialLoading ? (
                <LoadingComponent />
            ) : (
                <FlatList
                    pagingEnabled={false}
                    initialNumToRender={20}
                    contentContainerStyle={[styles.paddingBottom]}
                    onEndReached={onReachEnd}
                    onEndReachedThreshold={0}
                    data={historyData}
                    showsVerticalScrollIndicator={false}
                    keyExtractor={(item) => item.id}
                    renderItem={({ item }) => (
                        <UserDonate donation={item} key={item.id} />
                    )}
                    ListEmptyComponent={
                        <EmptyList
                            emptyTitle={i18n.t('charity.noDonations')}
                            withAnimation="cry"
                        />
                    }
                />
            )}
        </>
    );
    return (
        <View style={[styles.container, styles.contentContainer]}>
            <NavigationBarModal
                iconName={ICON_NAMES.CLOSE}
                title={i18n.t('charity.allDonations')}
            />
            <View>
                <TotalOrganizationDonations
                    walletAddress={charity.ethAddress}
                    totalDonations={charity.totalDonationsIgupWei}
                />
            </View>
            {renderUsersDonations()}
            {loadMore && (
                <View style={stylesLoadMore()}>
                    <LoadingComponent />
                </View>
            )}
        </View>
    );
};

export default CharityOrganizationDonationsScreen;
