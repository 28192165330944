import React from 'react';
import { Text, View } from 'react-native';

import MysteryBox from '../../../../../assets/icons/mysteryBoxes/box.svg';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import Styles from './styles';

interface IProps {
    amount: number;
}

const TransactionDetailsPurchaseBox = ({ amount }: IProps) => {
    const styles = useThemedStyles(Styles);

    return (
        <View style={styles.container}>
            <View style={styles.icon}>
                <MysteryBox />
            </View>
            <View style={styles.right}>
                <Text style={styles.label}>
                    {i18n.t(
                        'mysteryBoxes.purchaseCheckout.transactionDetails.label'
                    )}
                </Text>
                <Text style={styles.text}>
                    {i18n.t(
                        'mysteryBoxes.purchaseCheckout.transactionDetails.text',
                        {
                            amount,
                        }
                    )}
                </Text>
            </View>
        </View>
    );
};
export default TransactionDetailsPurchaseBox;
