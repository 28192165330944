import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { NFT_ROYAL_COLOR } from '@contexts/MysteryGamesContext';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
        },
        title: {
            color: Colors[theme].text.onLight,
        },
        absoluteLoading: {
            position: 'absolute',
            zIndex: 9999,
            width: '100%',
            height: '100%',
            flex: 1,
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: NFT_ROYAL_COLOR,
        },
    });

export default styles;
