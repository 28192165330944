import React from 'react';

import Input from '@components/Input';
import i18n from '@i18n/i18n';

export const useUserIdInput = () => {
    const [value, setValue] = React.useState<string>('');
    const [error, setError] = React.useState<string>('');

    const onChange = (value: string) => {
        setError('');
        setValue(value.replaceAll(' ', ''));
    };

    const render = React.useCallback(() => {
        return (
            <Input
                value={value}
                onChangeText={onChange}
                placeholder={i18n.t('profile.userIdInput.placeHolder')}
                error={error}
                label={i18n.t('profile.userIdInput.label')}
            />
        );
    }, [value, error]);

    const reset = () => {
        setError('');
        setValue('');
    };

    return {
        render,
        reset,
        value,
        error,
    };
};
