import * as React from 'react';

import Svg from '../../assets/icons/play/unmuteAudio.svg';
import { IconType, iconSize } from './types';

const UnmuteAudio: React.FunctionComponent<IconType> = ({
    size = iconSize.default,
}) => <Svg width={size} height={size} />;

export default UnmuteAudio;
