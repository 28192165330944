import * as React from 'react';
import { Dispatch, SetStateAction, useState } from 'react';
import { ScrollView } from 'react-native';

import { MarkSocialTaskAsCompleted } from '@Data/Models';
import { MARK_SOCIAL_TASK_AS_COMPLETED } from '@Data/Requests';
import { useMutation } from '@apollo/client';
import DownloadIcon from '@assets/icons/sharingMethods/download.svg';
import EditIcon from '@assets/icons/sharingMethods/edit.svg';
import TemplateIcon from '@assets/icons/sharingMethods/template.svg';
import AlertCard from '@components//AlertCard';
import ButtonCard from '@components//ButtonCard';
import ButtonsBottom from '@components//ButtonsBottom';
import ClickableCard from '@components//ClickableCard';
import CustomModal from '@components//CustomModal';
import Icon, { ICON_NAMES } from '@components//Icons';
import ImageIguana from '@components//ImageIguana';
import ModalBottom from '@components//ModalBottom';
import { View } from '@components/Themed';
import Colors from '@constants/Colors';
import { PlayContext } from '@contexts/PlayContext';
import { useTheme } from '@contexts/ThemeContext';
import { isWeb } from '@helpers/app';
import { errorsHandler } from '@helpers/errors';
import { waitForNavigation, waitForShare } from '@helpers/helpers';
import { toastSuccess } from '@helpers/toastNotification';
import useGetSocialTask from '@hooks/tasks/useGetSocialTask';
import useTasksErrorsHandler from '@hooks/tasks/useTasksErrorsHandler';
import useVerifyTask from '@hooks/tasks/useVerifyTask';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { StackActions } from '@react-navigation/native';

import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    handleSave: () => Promise<any>;
    handleShare: () => Promise<any>;
    handleOpenEditor?: () => void;
    children: React.ReactNode;
    isSmallCard?: boolean;
    modalHeight?: number;
}

const ModalShareYourPhoto = ({
    isVisible,
    setIsVisible,
    handleSave,
    handleShare,
    handleOpenEditor,
    children,
    isSmallCard,
    modalHeight = 750,
}: IProps) => {
    const { theme } = useTheme();
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const { socialTask, updateSocialTask, setVerifyTask, setSocialTask } =
        React.useContext(PlayContext);

    const { getVerifyTask, verifyTaskLoading } = useVerifyTask();
    const { refetchSocialTask, socialTaskLoading } = useGetSocialTask();
    const { taskWrongState } = useTasksErrorsHandler();

    const [markSocialTaskAsCompleted, { loading: markCompletedLoading }] =
        useMutation<MarkSocialTaskAsCompleted>(MARK_SOCIAL_TASK_AS_COMPLETED);

    const [isLoadingDownload, setIsLoadingDownload] = useState<boolean>(false);
    const [isLoadingShare, setIsLoadingShare] = useState<boolean>(false);
    const [sharedIdModal, setSharedIdModalVisible] =
        React.useState<boolean>(false);

    const handleNavigateToSuccess = async () => {
        try {
            const result = await getVerifyTask();
            if (result.data?.verificationTaskConfig) {
                setVerifyTask(result.data.verificationTaskConfig);
            }
            setIsVisible(false);
            setSharedIdModalVisible(false);
            await waitForNavigation();
            navigation.dispatch(
                StackActions.push(
                    ROUTES.SOCIALIZE_TO_EARN_SHARE_YOUR_PHOTO_SUCCESS
                )
            );
        } catch (error) {
            errorsHandler(error, true);
        }
    };

    const handleButtonPress = async () => {
        try {
            const result = await refetchSocialTask();
            Console.info(result);
            if (result.data?.socialTaskConfig) {
                setSocialTask(result.data.socialTaskConfig);
                if (
                    result.data.socialTaskConfig.latestTask.status !== 'CREATED'
                ) {
                    handleNavigateToSuccess();
                    return;
                }
                setSharedIdModalVisible(true);
            }
        } catch (error) {
            errorsHandler(error, true);
        }
    };

    const handleSubmit = async () => {
        return markSocialTaskAsCompleted({
            variables: {
                socialTaskId: socialTask?.latestTask.id,
            },
            onError: (error) => {
                Console.log('[markSocialTaskAsCompleted]', error);
                taskWrongState(error);
            },
            onCompleted: (data) => {
                updateSocialTask(data.markSocialTaskAsCompleted);
                handleNavigateToSuccess();
            },
        });
    };

    const toastOnSave = () => {
        toastSuccess(
            undefined,
            i18n.t(
                'socializeToEarn.shareYourPetPhoto.modalShareYourPhoto.saveSuccess'
            ),
            'top'
        );
    };

    const handleDownload = async () => {
        setIsLoadingDownload(true);
        await waitForShare();

        if (isWeb) {
            return handleShare().finally(() => setIsLoadingDownload(false));
        }

        return handleSave()
            .then(() => toastOnSave())
            .finally(() => setIsLoadingDownload(false));
    };

    const handleOnShare = async () => {
        setIsLoadingShare(true);
        await waitForShare();

        return handleShare().finally(() => setIsLoadingShare(false));
    };

    const handleOnTemplate = () => {
        setIsVisible(false);
        navigation.dispatch(
            StackActions.push(ROUTES.SOCIALIZE_TO_EARN_CREATE_SHARE_TEMPLATE)
        );
    };

    const handleClose = () => {
        setIsVisible(false);
    };

    const renderBigCard = React.useCallback(() => {
        return (
            <View style={styles.buttonWrapper}>
                <ClickableCard
                    onPress={handleOnTemplate}
                    debouncedPress
                    containerStyle={styles.button}
                    icon={<TemplateIcon width={32} height={32} />}
                    title={i18n.t(
                        'socializeToEarn.shareYourPetPhoto.modalShareYourPhoto.shareTemplate.title'
                    )}
                    text={i18n.t(
                        'socializeToEarn.shareYourPetPhoto.modalShareYourPhoto.shareTemplate.text'
                    )}
                />
                {handleOpenEditor && (
                    <ClickableCard
                        onPress={handleOpenEditor}
                        debouncedPress
                        containerStyle={styles.button}
                        icon={<EditIcon width={32} height={32} />}
                        title={i18n.t(
                            'socializeToEarn.shareYourPetPhoto.modalShareYourPhoto.editToShare.title'
                        )}
                        text={i18n.t(
                            'socializeToEarn.shareYourPetPhoto.modalShareYourPhoto.editToShare.text'
                        )}
                    />
                )}
                <ClickableCard
                    onPress={handleDownload}
                    debouncedPress
                    containerStyle={styles.button}
                    icon={<DownloadIcon width={32} height={32} />}
                    title={i18n.t(
                        'socializeToEarn.shareYourPetPhoto.modalShareYourPhoto.downloadPetId.title'
                    )}
                    text={i18n.t(
                        'socializeToEarn.shareYourPetPhoto.modalShareYourPhoto.downloadPetId.text'
                    )}
                    loading={isLoadingDownload}
                    loadingText={i18n.t(
                        'socializeToEarn.shareYourPetPhoto.modalShareYourPhoto.loading'
                    )}
                />
            </View>
        );
    }, [isLoadingDownload, handleOpenEditor]);

    const renderSmallCard = React.useCallback(() => {
        return (
            <View style={styles.buttonWrapperSmall}>
                <ButtonCard
                    onPress={handleDownload}
                    debouncedPress
                    containerStyle={styles.buttonSmall}
                    icon={
                        <Icon
                            name={ICON_NAMES.DOWNLOAD}
                            size={16}
                            color={Colors[theme].icon.onDark}
                        />
                    }
                    title={i18n.t('socializeToEarn.shareYourPetPhoto.download')}
                    loading={isLoadingDownload}
                    loadingText={i18n.t(
                        'socializeToEarn.shareYourPetPhoto.modalShareYourPhoto.loading'
                    )}
                />
                {!isWeb && (
                    <ButtonCard
                        onPress={handleOnShare}
                        debouncedPress
                        containerStyle={styles.buttonSmall}
                        icon={
                            <Icon
                                name={ICON_NAMES.SHARE}
                                size={16}
                                color={Colors[theme].icon.onDark}
                            />
                        }
                        title={i18n.t(
                            'socializeToEarn.shareYourPetPhoto.share'
                        )}
                        loading={isLoadingShare}
                        loadingText={i18n.t(
                            'socializeToEarn.shareYourPetPhoto.modalShareYourPhoto.loading'
                        )}
                    />
                )}
                {handleOpenEditor && (
                    <ButtonCard
                        onPress={handleOpenEditor}
                        debouncedPress
                        containerStyle={styles.buttonSmall}
                        icon={
                            <Icon
                                name={ICON_NAMES.PENCIL}
                                size={16}
                                color={Colors[theme].icon.onDark}
                            />
                        }
                        title={i18n.t(
                            'socializeToEarn.shareYourPetPhoto.createPhoto'
                        )}
                    />
                )}
            </View>
        );
    }, [handleOpenEditor, isWeb, isLoadingDownload, isLoadingShare]);

    return (
        <>
            <ModalBottom
                isVisible={isVisible}
                onClose={handleClose}
                titleText={i18n.t(
                    'socializeToEarn.shareYourPetPhoto.modalShareYourPhoto.title'
                )}
                modalHeight={modalHeight}>
                <ScrollView
                    style={styles.container}
                    showsVerticalScrollIndicator={false}
                    bounces={false}>
                    {isSmallCard ? renderSmallCard() : renderBigCard()}
                </ScrollView>
                <CustomModal
                    isVisible={sharedIdModal}
                    icon={<ImageIguana type="attention" />}
                    titleText={i18n.t(
                        'socializeToEarn.shareYourPetPhoto.modalPetIdVisible.title'
                    )}
                    infoText={i18n.t(
                        'socializeToEarn.shareYourPetPhoto.modalPetIdVisible.text'
                    )}
                    firstButtonText={i18n.t(
                        'socializeToEarn.shareYourPetPhoto.modalPetIdVisible.button1'
                    )}
                    secondButtonText={i18n.t(
                        'socializeToEarn.shareYourPetPhoto.modalPetIdVisible.button2'
                    )}
                    onFirstButtonClick={() => {
                        setSharedIdModalVisible(false);
                        handleSubmit();
                    }}
                    onSecondButtonClick={() => setSharedIdModalVisible(false)}
                    isNestedModal
                />
                {children}
                <AlertCard
                    containerStyle={styles.alertCard}
                    text={i18n.t('socializeToEarn.shareYourPetPhoto.alert')}
                />
                <ButtonsBottom
                    title={i18n.t(
                        'socializeToEarn.shareYourPetPhoto.modalShareYourPhoto.submit'
                    )}
                    onPress={handleButtonPress}
                    containerStyle={styles.buttonBottomWrapper}
                    loading={
                        markCompletedLoading ||
                        verifyTaskLoading ||
                        socialTaskLoading
                    }
                    loadingText={i18n.t(
                        markCompletedLoading
                            ? 'socializeToEarn.shareYourPetPhoto.petIdVisibleLoading'
                            : 'general.loading'
                    )}
                />
            </ModalBottom>
        </>
    );
};

export default ModalShareYourPhoto;
