import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import CardFeatureInfo from '@components/CardFeatureInfo/CardFeatureInfo';
import CoinIcon from '@components/Icons/CoinIcon';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import i18n from '@i18n/i18n';

interface IProps {
    customStyles?: StyleProp<ViewStyle>;
}

const GamingWalletChangeFeature = ({ customStyles }: IProps) => {
    const { priceInUsd } = useWallet();

    const cardTitle = `1 ${Coin.igu} = 1 ${Coin.vigu} ≈ ${priceInUsd(
        Coin.igu,
        '1'
    )}$`;

    return (
        <CardFeatureInfo
            containerStyle={customStyles}
            title={cardTitle}
            text={i18n.t('wallet.gaming.gamingWalletChangeFeatureText')}
            icon={<CoinIcon slug={Coin.vigu} size={48} />}
        />
    );
};
export default GamingWalletChangeFeature;
