import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { NFT_EVOLUTION_COLOR } from '@contexts/MysteryGamesContext';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import { stylesModalNavigationFix } from '@helpers/style';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
        },
        navigation: {
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
        },
        content: {
            paddingHorizontal: scale(20),
            paddingBottom: verticalScale(24),
        },
        dna: {
            backgroundColor: Colors[theme].alwaysOneColor.background,
            padding: 10,
            borderRadius: 100,
            alignSelf: 'center',
            position: 'absolute',
            bottom: -26,
        },
        table: { marginVertical: verticalScale(20) },
        container: {
            backgroundColor: NFT_EVOLUTION_COLOR,
            paddingTop: verticalScale(20),
            paddingBottom: verticalScale(56),
            borderBottomRightRadius: moderateScale(20),
            borderBottomLeftRadius: moderateScale(20),

            paddingHorizontal: scale(20),
        },
        cards: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            backgroundColor: 'transparent',
        },
        card: {
            width: '48%',
            backgroundColor: 'transparent',
            minHeight: 235,
        },
    });

export default styles;
