import React from 'react';

import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import Animation from '../../Animation';
import CustomModal from '../../CustomModal';
import { View } from '../../Themed';
import stylesMain from './styles';
import { IProps } from './types';

const UpdateRequiredState = ({ isVisible, setIsVisible }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const handleAppUpdatePress = () => {
        window.location.href = '';
    };

    return (
        <CustomModal
            isVisible={isVisible}
            icon={
                <View style={styles.image}>
                    <Animation animation="dancing" style={styles.image} />
                </View>
            }
            titleText={i18n.t('AppState.UpdateRequiredStateWeb.title')}
            infoText={i18n.t('AppState.UpdateRequiredStateWeb.text')}
            firstButtonText={i18n.t('AppState.UpdateRequiredStateWeb.button')}
            onFirstButtonClick={handleAppUpdatePress}
        />
    );
};

export default UpdateRequiredState;
