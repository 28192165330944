import * as React from 'react';

import {
    STORE_ERROR_TYPES,
    STORE_LOADING_TYPES,
} from '@contexts/StorePurchaseContext';
import { stylesAnimation } from '@helpers/style';

import i18n from '../../../i18n/i18n';
import Animation from '../../Animation';
import CustomModal from '../../CustomModal';
import ModalLoader from '../../ModalLoader';

interface IProps {
    isStoreError: boolean;
    isStoreLoading: boolean;
    errorType: STORE_ERROR_TYPES;
    errorCode?: string;
    loadingType: STORE_LOADING_TYPES;
    action: () => void;
}

const StorePurchase = ({
    isStoreError,
    isStoreLoading,
    errorType,
    errorCode,
    loadingType,
    action,
}: IProps) => {
    const renderErrorModal = React.useCallback(() => {
        const infoText = errorCode
            ? i18n.t(
                  `checkout.storePurchase.storePurchaseError.${errorType}.message`,
                  {
                      code: errorCode,
                  }
              )
            : i18n.t(
                  `checkout.storePurchase.storePurchaseError.${errorType}.message`
              );

        return (
            <CustomModal
                isVisible={isStoreError}
                icon={<Animation animation="cry" style={stylesAnimation()} />}
                titleText={i18n.t(
                    `checkout.storePurchase.storePurchaseError.${errorType}.title`
                )}
                infoText={infoText}
                firstButtonText={i18n.t('checkout.storePurchase.button')}
                onFirstButtonClick={action}
            />
        );
    }, [action, isStoreError, errorType, errorCode]);

    const renderLoadingModal = React.useCallback(() => {
        return (
            <ModalLoader
                isVisible={isStoreLoading}
                text={i18n.t(`checkout.storePurchase.loadings.${loadingType}`)}
                timeout={0}
            />
        );
    }, [isStoreLoading, loadingType]);

    return (
        <>
            {renderErrorModal()}
            {renderLoadingModal()}
        </>
    );
};

export default StorePurchase;
