import * as React from 'react';
import { StyleProp } from 'react-native';
import { SvgProps } from 'react-native-svg';

import * as Clipboard from 'expo-clipboard';

import Tooltip from '@components/Tooltip';
import { openUrlOrShowError } from '@helpers/linking';
import { useHaptic } from '@hooks/useHaptic';
import i18n from '@i18n/i18n';

import Link from '../../../../../components/Link';
import { Text, View, ViewProps } from '../../../../../components/Themed';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import stylesMain from './styles';

interface IProps {
    image: React.ReactElement<SvgProps>;
    title: string;
    info: string;
    containerStyle?: StyleProp<ViewProps>;
    linkType?: 'web' | 'instagram' | 'twitter' | 'email';
    disabledCopy?: boolean;
}

const InfoRow = ({
    image,
    title,
    info,
    linkType,
    containerStyle = {},
    disabledCopy = false,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const links = {
        web: {
            baseUrl: '',
        },
        instagram: {
            baseUrl: 'https://instagram.com/',
        },
        twitter: {
            baseUrl: 'https://twitter.com/',
        },
        email: {
            baseUrl: 'mailto:',
        },
    };

    const [copied, setCopied] = React.useState<boolean>(false);
    const { triggerHeavy } = useHaptic();

    const copyToClipboard = async () => {
        if (disabledCopy) {
            return;
        }
        triggerHeavy();
        await Clipboard.setStringAsync(info);
        setCopied(true);
    };
    // TODO: rework this
    React.useEffect(() => {
        if (!copied) return;
        const timer = setTimeout(() => {
            setCopied(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, [copied]);

    const onPressLink = async () => {
        if (!linkType) {
            return null;
        }

        const url = `${links[linkType].baseUrl}${info}`;

        try {
            return openUrlOrShowError(url);
        } catch (e) {
            Console.log(e);
            return null;
        }
    };

    if (!info) {
        return null;
    }

    return (
        <View style={[styles.container, containerStyle]}>
            <View style={styles.titleContainer}>
                {image}
                <Text style={styles.title}>{title}</Text>
            </View>
            {linkType ? (
                <Tooltip
                    isVisible={copied}
                    text={
                        <Text style={styles.contentText}>
                            {i18n.t('general.copiedToClipboard')}
                        </Text>
                    }
                    placement="top"
                    onClose={() => {
                        setCopied(false);
                    }}>
                    <View style={{ width: '100%' }}>
                        <Link
                            textStyles={styles.info}
                            onPress={onPressLink}
                            onLongPress={copyToClipboard}>
                            {info}
                        </Link>
                    </View>
                </Tooltip>
            ) : (
                <Text style={styles.info}>{info}</Text>
            )}
        </View>
    );
};

export default InfoRow;
