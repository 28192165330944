import { StyleSheet } from 'react-native';

import Colors from '../../../../../../constants/Colors';
import { theme } from '../../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        titleStyle: {
            color: Colors[theme].text.critical,
        },
        textStyle: {
            color: Colors[theme].text.critical,
        },
    });

export default styles;
