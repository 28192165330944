import React, {
    FC,
    PropsWithChildren,
    createContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { Config, GetConfigResponse } from '@Data/Models';
import { GET_CONFIG } from '@Data/Requests';
import { useApolloClient } from '@apollo/client';
import { APP_MAINNET } from '@env';
import { isIOS, release } from '@helpers/app';

import ErrorReporting from '../utils/ErrorReporting';
import { useApollo } from './IguApolloContext';

type ConfigContextType = {
    isLoaded: boolean;
    config: Config | undefined;
    refetchConfig: () => void;
    isRefetching: boolean;
    canUsePayments: boolean;
    isRewardsDisabled: boolean;
    isTradeAvailable: boolean;
    isNewIosVersion: boolean;
    isWithdrawDisabled: boolean;
};

export const ConfigContext = createContext<ConfigContextType>({
    isLoaded: false,
    config: undefined,
    isRefetching: false,
    refetchConfig: () => null,
    canUsePayments: false,
    isRewardsDisabled: false,
    isTradeAvailable: false,
    isNewIosVersion: false,
    isWithdrawDisabled: false,
});

export const useConfig = () => React.useContext(ConfigContext);

const ConfigProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const [isRefetching, setIsRefetching] = useState<boolean>(false);
    const client = useApolloClient();
    const [config, setConfig] = useState<Config>();
    const isLoaded = useMemo(() => !!config, [config]);
    const { isFullMaintenance } = useApollo();

    const fetchConfig = () => {
        client
            .query<GetConfigResponse>({
                query: GET_CONFIG,
                fetchPolicy: 'network-only',
            })
            .then((result) => {
                setConfig({
                    ...result.data.config,
                    /*
                    mintConfirmationsCount: 1,
                    rpcPublicProviders: [
                        'https://nd-386-890-746.p2pify.com/3f431cbf7b8d113165030c45bf48c08b',
                    ],*/
                });
            })
            .catch((error) => {
                Console.error('[CONFIG ERROR]', error);
                ErrorReporting.report(error);
            });
    };

    useEffect(() => {
        fetchConfig();
    }, [isFullMaintenance]);

    const refetchConfig = async () => {
        setIsRefetching(true);
        await fetchConfig();
        setIsRefetching(false);
    };

    const canUsePayments = useMemo(() => {
        return !!config?.paymentsAvailable;
    }, [config]);

    const isRewardsDisabled = useMemo(
        () => APP_MAINNET && !!config?.igupDisabled,
        [config]
    );

    const isWithdrawDisabled = useMemo(
        () => APP_MAINNET && !!config?.iguWithdrawDisabled,
        [config]
    );

    const isTradeAvailable = useMemo(() => !isIOS, []);

    // Disable some features in iOS
    const isNewIosVersion = useMemo(
        () => isIOS && Number(config?.newIosThemeVersion) === Number(release),
        [config]
    );

    return (
        <ConfigContext.Provider
            value={{
                isLoaded,
                config,
                refetchConfig,
                isRefetching,
                canUsePayments,
                isRewardsDisabled,
                isTradeAvailable,
                isNewIosVersion,
                isWithdrawDisabled,
            }}>
            {children}
        </ConfigContext.Provider>
    );
};

export default ConfigProvider;
