import React from 'react';
import { Text, View } from 'react-native';

import { Pet } from '../../../../../Data/Models';
import RecognitionResults from '../../../../../components/RecognitionResults';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import DetailsBlock from '../DetailsBlock';
import Styles from './styles';

interface IProps {
    pet: Pet;
}
const AnimalTypeDetailsBlock = ({ pet }: IProps) => {
    const styles = useThemedStyles(Styles);

    const metadataPet = pet?.image?.metadata?.detectionResult;

    return metadataPet ? (
        <DetailsBlock
            title={i18n.t('detailedPet.animalType.title')}
            description={i18n.t('detailedPet.animalType.text')}>
            <View style={styles.metadataContainer}>
                {metadataPet && (
                    <RecognitionResults detectionResult={metadataPet} />
                )}
            </View>
        </DetailsBlock>
    ) : (
        <></>
    );
};
export default AnimalTypeDetailsBlock;
