import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import ActivityIndicator from '@components/ActivityIndicator';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT, TYPO_TEXT_COLOR } from '@components/TypoText/types';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { wait } from '@helpers/helpers';
import { useLoadingHook } from '@hooks/useLoadingHook';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import QuantumSpinChancesTableRow from '../QuantumSpinChancesTableRow';
import stylesMain from './styles';

interface IProps {
    containerStyle?: StyleProp<ViewStyle>;
    type?: 'default' | 'opacity';
}

const QuantumSpinChancesTable = ({
    containerStyle,
    type = 'default',
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { quantumSpinGamePrizes } = useMysteryGames();

    const isDefault = React.useMemo(() => type === 'default', [type]);

    const headerColor = React.useMemo(
        () => (isDefault ? TYPO_TEXT_COLOR.SECONDARY : TYPO_TEXT_COLOR.ON_DARK),
        [isDefault]
    );

    const containerStyles = React.useMemo(
        () => (isDefault ? styles.default : styles.opacity),
        [isDefault]
    );

    return !!quantumSpinGamePrizes ? (
        <View style={[styles.container, containerStyles, containerStyle]}>
            <View style={styles.header}>
                <TypoText
                    type={TYPO_TEXT.BODY_SMALL}
                    numberOfLines={1}
                    additionalStyles={styles.prizes}
                    colorType={headerColor}>
                    {i18n.t('general.KEYS.prizes')}
                </TypoText>
                <TypoText
                    type={TYPO_TEXT.BODY_SMALL}
                    numberOfLines={1}
                    additionalStyles={styles.chance}
                    colorType={headerColor}>
                    {i18n.t('general.KEYS.chance')}
                </TypoText>
            </View>
            {[...quantumSpinGamePrizes].reverse().map((item, index) => (
                <QuantumSpinChancesTableRow
                    type={type}
                    prize={item}
                    key={index}
                />
            ))}
        </View>
    ) : null;
};
export default QuantumSpinChancesTable;
