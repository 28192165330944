import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import UserAchievementsIcon from '@components/Icons/UserAchievementsIcon';
import ProgressBar from '@components/ProgressBar';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT } from '@components/TypoText/types';
import usePreventDoubleClick from '@hooks/usePreventDoubleClick';
import useThemedStyles from '@hooks/useThemedStyles';
import { UserAchievementOutput } from '@models/achievements';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import stylesMain from './styles';

interface IProps {
    achievement: UserAchievementOutput;
    isLast: boolean;
}

const Achievement = ({ achievement, isLast }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const navigation = useNavigation();

    const { backgroundColor, shadowColor, title, description } = achievement;
    const handlePress = () => {
        navigation.push(ROUTES.ACHIEVEMENTS_DETAILS, {
            achievement,
        });
    };

    const { onClick, disabled } = usePreventDoubleClick(handlePress);

    return (
        <TouchableOpacity
            activeOpacity={0.8}
            onPress={onClick}
            disabled={disabled || !achievement.achievedAt}
            style={[styles.container, isLast && styles.last]}>
            <View style={styles.left}>
                <View
                    style={[
                        styles.shadow,
                        !!achievement.achievedAt && {
                            backgroundColor: shadowColor,
                        },
                    ]}>
                    <View
                        style={[
                            styles.image,
                            !!achievement.achievedAt && {
                                backgroundColor,
                                borderColor: backgroundColor,
                            },
                        ]}>
                        <UserAchievementsIcon
                            slug={achievement.type}
                            achieved={!!achievement.achievedAt}
                        />
                    </View>
                </View>
            </View>
            <View style={styles.right}>
                <TypoText
                    type={TYPO_TEXT.SUBHEAD_BIG_BOLD}
                    additionalStyles={styles.title}>
                    {title}
                </TypoText>
                <TypoText type={TYPO_TEXT.SUBHEAD_SMALL_SEMIBOLD}>
                    {description}
                </TypoText>
                {!achievement.achievedAt &&
                    achievement.progress !== null &&
                    !!achievement.targetProgress && (
                        <ProgressBar
                            amount={achievement.progress}
                            maxAmount={achievement.targetProgress}
                            containerStyle={styles.progressBar}
                        />
                    )}
            </View>
        </TouchableOpacity>
    );
};
export default Achievement;
