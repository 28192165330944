import * as React from 'react';
import { SafeAreaView, ScrollView } from 'react-native';

import { useConfig } from '@contexts/ConfigContext';
import { MintContext } from '@contexts/MintContext';
import { useRanks } from '@contexts/RanksContext';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { waitForNavigation } from '@helpers/helpers';
import { isAvailableForMint } from '@helpers/ranks';
import { dateCooldownExpired } from '@helpers/time';
import { balanceFromWei } from '@helpers/wallet';

import RankMintBonusImage from '../../../assets/icons/rankMintBonus/rankMintBonus.svg';
import BlockHeader from '../../../components/BlockHeader/BlockHeader';
import Button from '../../../components/Button';
import ButtonsBottom from '../../../components/ButtonsBottom';
import CardFeatureInfo from '../../../components/CardFeatureInfo/CardFeatureInfo';
import Icon, { ICON_NAMES } from '../../../components/Icons';
import RankIcon from '../../../components/Icons/RankIcon';
import LoadingComponent from '../../../components/LoadingComponent';
import { Text, View } from '../../../components/Themed';
import TimerWithCaptions from '../../../components/TimerWithCaptions';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import NavigationBarModal from '../../../navigation/NavigationBar/NavigationBarModal';
import useNavigationListeners from '../../../navigation/hooks/useNavigationListeners';
import ROUTES from '../../../navigation/routes';
import { useNavigation } from '../../../navigation/useNavigation';
import { BonusMintRanksInfoProps } from '../../../types';
import stylesMain from './styles';

const BonusMintRanksDetailedInfoScreen = ({
    route,
}: BonusMintRanksInfoProps) => {
    useNavigationListeners();
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const { priceInUsd } = useWallet();
    const { editPhoto } = React.useContext(MintContext);
    const { canUsePayments } = useConfig();
    const { mintBonus, slug, withBtn } = route.params.screenInfo;
    const [timerEnd, setTimerEnd] = React.useState<boolean>(false);
    const { ranks, refetch: rankRefetch } = useRanks();
    const bonusValueRank = balanceFromWei(mintBonus.igupAmountWei).valueLong;
    const bonusValueRankUsd = priceInUsd(Coin.igup, bonusValueRank);

    React.useEffect(() => {
        rankRefetch();
    }, []);

    const additionalBonusRanks = React.useMemo(() => {
        return ranks
            .filter(
                (value) =>
                    value.mintBonus &&
                    !dateCooldownExpired(value.mintBonus.endsAt)
            )
            .filter((value) => isAvailableForMint(value, canUsePayments));
    }, [ranks, canUsePayments]);

    const isLoading = !additionalBonusRanks.length || !bonusValueRankUsd;

    const handleOnContinue = React.useCallback(async () => {
        navigation.pop();
        await waitForNavigation();
        if (editPhoto) {
            navigation.navigate(ROUTES.MINT_PET_EDIT_PHOTO);
            return;
        }
        navigation.navigate(ROUTES.MINT_PET_SELECT_GENERATION);
    }, [editPhoto]);

    const handleOnViewOther = async () => {
        navigation.pop();
        await waitForNavigation();
        navigation.navigate(ROUTES.BONUS_MINT_RANKS_CAROUSEL, {
            ranks: additionalBonusRanks,
        });
    };

    return (
        <View style={styles.main}>
            <SafeAreaView style={styles.safeArea}>
                <View style={styles.header}>
                    <NavigationBarModal
                        iconName={ICON_NAMES.CLOSE}
                        title=""
                        onBack={() => navigation.pop()}
                    />
                </View>
                {isLoading ? (
                    <LoadingComponent fullPage />
                ) : (
                    <>
                        <ScrollView
                            style={styles.content}
                            showsVerticalScrollIndicator={false}>
                            <View style={styles.imgWrapper}>
                                <RankMintBonusImage />
                            </View>
                            <BlockHeader
                                type="emptyState"
                                title={i18n.t(
                                    'MintBonusScreen.BonusMintRanksDetailedInfoScreen.title'
                                )}
                                text={i18n.t(
                                    'MintBonusScreen.BonusMintRanksDetailedInfoScreen.text'
                                )}
                            />

                            <View style={styles.timer}>
                                <Text style={styles.timerText}>
                                    {i18n.t(
                                        'MintBonusScreen.BonusMintRanksDetailedInfoScreen.timeRemaining'
                                    )}
                                </Text>
                                <TimerWithCaptions
                                    endOfTime={mintBonus.endsAt}
                                    onEnd={() => setTimerEnd(true)}
                                />
                            </View>
                            <View style={styles.features}>
                                <CardFeatureInfo
                                    containerStyle={styles.gap}
                                    title={i18n.t(
                                        'MintBonusScreen.BonusMintRanksDetailedInfoScreen.features.bonus.key',
                                        {
                                            amount: bonusValueRank,
                                            dollarValue: bonusValueRankUsd,
                                        }
                                    )}
                                    text={i18n.t(
                                        'MintBonusScreen.BonusMintRanksDetailedInfoScreen.features.bonus.value'
                                    )}
                                    icon={<RankIcon slug={slug} size={48} />}
                                />
                                <CardFeatureInfo
                                    containerStyle={styles.gap}
                                    title={i18n.t(
                                        'MintBonusScreen.BonusMintRanksDetailedInfoScreen.features.lock.key',
                                        {
                                            days: mintBonus.lockedPeriodDays,
                                        }
                                    )}
                                    text={i18n.t(
                                        'MintBonusScreen.BonusMintRanksDetailedInfoScreen.features.lock.value'
                                    )}
                                    icon={
                                        <Icon
                                            name={ICON_NAMES.LOCK_FILL}
                                            size={48}
                                        />
                                    }
                                />
                            </View>
                        </ScrollView>
                        {withBtn && (
                            <ButtonsBottom
                                onPress={handleOnContinue}
                                title={i18n.t(
                                    'MintBonusScreen.BonusMintRanksDetailedInfoScreen.button1'
                                )}
                                disabled={timerEnd}>
                                <Button
                                    containerStyle={styles.gap}
                                    onPress={handleOnViewOther}
                                    type="outline"
                                    title={i18n.t(
                                        'MintBonusScreen.BonusMintRanksDetailedInfoScreen.button2'
                                    )}
                                />
                            </ButtonsBottom>
                        )}
                    </>
                )}
            </SafeAreaView>
        </View>
    );
};

export default BonusMintRanksDetailedInfoScreen;
