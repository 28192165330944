import React, { useEffect, useState } from 'react';

import NetInfo from '@react-native-community/netinfo';

const useNoNetworkConnection = () => {
    const [isConnected, setIsConnected] = useState<boolean>(true);

    useEffect(() => {
        const unsubscribe = NetInfo.addEventListener((state) => {
            NetInfo.fetch().then((state) => {
                setIsConnected((old) =>
                    state.isConnected !== old && state.isConnected !== null
                        ? state.isConnected
                        : old
                );

                Console.log(
                    `[useNoNetworkConnection] Change network state (${state.isConnected}, ${state.type})`
                );
            });
        });

        return () => {
            unsubscribe();
        };
    }, []);

    return { isConnected };
};

export default useNoNetworkConnection;
