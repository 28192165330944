import * as React from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';

import dayjs from 'dayjs';

import { useNavigation } from '@react-navigation/native';

import { TasksHistoryResponseItem } from '../../../../../Data/Models';
import TaskHistoryIcon from '../../../../../components/Icons/TaskHistoryIcon';
import { Text, View } from '../../../../../components/Themed';
import { useTheme } from '../../../../../contexts/ThemeContext';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import ROUTES from '../../../../../navigation/routes';
import EnergyBlock from '../EnergyBlock';
import stylesMain from './styles';

interface IProps {
    item: TasksHistoryResponseItem;
    containerStyle?: StyleProp<ViewStyle>;
}

const HistoryCard = ({ item, containerStyle }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { theme } = useTheme();

    const navigation = useNavigation();

    const title = React.useMemo(
        () => i18n.t(`playTasksHistory.historyCard.${item.type}`),
        [item]
    );

    const textDate = React.useMemo(
        () => dayjs(item.createdAt).format('DD MMM'),
        [item]
    );

    return (
        <TouchableOpacity
            style={[styles.container, containerStyle]}
            onPress={() =>
                navigation.navigate(ROUTES.PLAY_TASKS_HISTORY_DETAILS, {
                    item,
                })
            }
            activeOpacity={0.8}>
            <View style={styles.left}>
                <View
                    style={[styles.imageWrapper, styles['TaskBg' + item.type]]}>
                    <TaskHistoryIcon slug={item.type} size={20} />
                </View>
                <View style={styles.textWrapper}>
                    <View style={styles.titleWrapper}>
                        <Text style={styles.title}>{title}</Text>
                        <Text style={styles.date}>{textDate}</Text>
                    </View>
                </View>
            </View>
            <EnergyBlock
                energy={`${item.energyRewarded || 0} / ${item.maxEnergyReward}`}
                containerStyles={styles.energyBlock}
                iconRight
            />
        </TouchableOpacity>
    );
};

export const MemoizedHistoryCard = React.memo(HistoryCard);
