import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
        },
        listStyle: {
            paddingHorizontal: scale(20),
        },
        headerText: {
            paddingBottom: verticalScale(16),
        },
        textHeader: {
            paddingHorizontal: 0,
        },
        columnWrapperStyle: {
            justifyContent: 'space-between',
        },
        footerStyle: {
            height: 16,
        },
        titleContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        buttonsContainer: {
            flexDirection: 'row',
            justifyContent: 'space-around',
        },
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 22,
                weight: '700',
            }),
            marginBottom: verticalScale(8),
        },
        buttonHealAll: {
            marginHorizontal: scale(10),
        },
        text: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 15,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
        loadingWrapper: {
            flex: 1,
        },
        banner: {
            position: 'absolute',
            width: '100%',
            left: scale(20),
        },
        infoWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: 'transparent',
            marginTop: verticalScale(12),
        },
    });

export default styles;
