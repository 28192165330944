import * as React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import { isAndroid } from '@helpers/app';

import PetPriority from '../../assets/icons/play/petPriority.svg';
import PutPetsInOrder from '../../assets/icons/play/putPetsInOrder.svg';
import useThemedStyles from '../../hooks/useThemedStyles';
import i18n from '../../i18n/i18n';
import BlockHeader from '../BlockHeader/BlockHeader';
import ButtonsBottom from '../ButtonsBottom';
import CardFeatureInfo from '../CardFeatureInfo/CardFeatureInfo';
import ModalBottom from '../ModalBottom';
import { View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
    onAction: () => void;
    modalHeight?: number | string;
}

const ModalNftToPlayInfo = ({
    isVisible,
    onClose,
    onAction,
    modalHeight = 750,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <ModalBottom
            isVisible={isVisible}
            onClose={onClose}
            modalHeight={modalHeight}>
            <View style={styles.container}>
                <ScrollView showsVerticalScrollIndicator={false}>
                    <TouchableOpacity activeOpacity={1}>
                        <View style={styles.image}>
                            <PutPetsInOrder />
                        </View>
                        <BlockHeader
                            type="emptyState"
                            title={i18n.t('howTo.petOrderId.title')}
                            text={i18n.t('howTo.petOrderId.text')}
                        />

                        <View style={styles.card}>
                            <CardFeatureInfo
                                title={i18n.t(
                                    'howTo.petOrderId.cardFeatureInfo.title'
                                )}
                                text={i18n.t(
                                    'howTo.petOrderId.cardFeatureInfo.text'
                                )}
                                icon={<PetPriority width={48} height={48} />}
                            />
                        </View>
                    </TouchableOpacity>
                </ScrollView>
            </View>
            <ButtonsBottom
                title={i18n.t('howTo.petOrderId.button')}
                onPress={onAction}
                safe={!isAndroid}
                containerStyle={styles.button}
            />
        </ModalBottom>
    );
};

export default ModalNftToPlayInfo;
