import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { Text, View } from '@components/Themed';
import TimerWithCaptions from '@components/TimerWithCaptions';
import TypoText from '@components/TypoText';
import { TYPO_TEXT, TYPO_TEXT_COLOR } from '@components/TypoText/types';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';

import stylesMain from './styles';

interface IProps {
    date: string;
    containerStyle?: StyleProp<ViewStyle>;
    onEndOfTime: () => void;
}

const LuckyStrikeTimerBlock = ({
    date,
    containerStyle,
    onEndOfTime,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    return (
        <View style={[styles.container, containerStyle]}>
            <TypoText
                type={TYPO_TEXT.HEADING_H6}
                colorType={TYPO_TEXT_COLOR.ON_DARK}
                additionalStyles={styles.gap}>
                {i18n.t(
                    `HomeScreen.${MYSTERY_GAMES.luckyStrike}.LuckyStrikeTimerBlock.title`
                )}
            </TypoText>
            <TypoText
                additionalStyles={styles.gapBott}
                type={TYPO_TEXT.BODY_SMALL}
                colorType={TYPO_TEXT_COLOR.ON_DARK}>
                {i18n.t(
                    `HomeScreen.${MYSTERY_GAMES.luckyStrike}.LuckyStrikeTimerBlock.text`
                )}
            </TypoText>

            <TimerWithCaptions isLight endOfTime={date} onEnd={onEndOfTime} />
        </View>
    );
};
export default LuckyStrikeTimerBlock;
