import * as React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import ICON from '@assets/icons/howTo/howToGamingAvailableWallet/stepOne.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import GamingWalletChangeFeature from '@components/GamingWalletChangeFeature';
import ModalBottom from '@components/ModalBottom';
import ModalPvpBonus from '@components/ModalPvpBonus';
import ModalPvpBonusFreePet from '@components/ModalPvpBonusFreePet';
import PvpBonusBanner from '@components/PvpBonusBanner';
import { Text, View } from '@components/Themed';
import TimerWithCaptions from '@components/TimerWithCaptions';
import { useConfig } from '@contexts/ConfigContext';
import { usePlay } from '@contexts/PlayContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import { useNavigation } from '@navigation/useNavigation';

import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
}

const flow = 'howToGamingAvailableWallet';

const ModalCompetitiveGames = ({ isVisible, onClose }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { config } = useConfig();
    const { isFreePets } = usePlay();
    const percent = React.useMemo(
        () => config?.iguDepositBonus?.bonusPercentage || 0,
        [config?.iguDepositBonus?.bonusPercentage]
    );
    const { isVisible: pvpBonusModal, open, close } = useVisibleHook();

    const onPvpBonusButton = () => {
        close();
        onClose();
    };

    const {
        isVisible: pvpBonusFreePet,
        open: openPvpBonusFreePet,
        close: closePvpBonusFreePet,
    } = useVisibleHook();

    const navigation = useNavigation();

    const onPressFreePet = () => {
        closePvpBonusFreePet();
        onClose();
        navigation.navigateToMint();
    };
    return (
        <ModalBottom
            isVisible={isVisible}
            onClose={onClose}
            modalHeight={'100%'}>
            <ScrollView
                style={styles.container}
                showsVerticalScrollIndicator={false}>
                <TouchableOpacity activeOpacity={1}>
                    <BlockHeader
                        containerStyle={styles.header}
                        emptyStateIcon={<ICON />}
                        type="emptyState"
                        title={i18n.t(`howTo.${flow}.stepOne.title`)}
                        text={i18n.t(`howTo.${flow}.stepOne.text`)}
                    />
                    <PvpBonusBanner
                        containerStyle={styles.pvpBonus}
                        onButtonPress={!isFreePets ? open : openPvpBonusFreePet}
                    />
                    {!!config?.iguDepositBonus && (
                        <View style={styles.timerContainer}>
                            <Text style={styles.title}>
                                <Text
                                    style={
                                        styles.accent
                                    }>{`${percent}% `}</Text>
                                {i18n.t('HomeScreen.TimerTopUp.title')}
                            </Text>
                            <Text style={styles.text}>
                                {i18n.t('HomeScreen.TimerTopUp.text1', {
                                    percent,
                                    coin: Coin.vigu,
                                })}
                            </Text>
                            {!!config?.iguDepositBonus && (
                                <TimerWithCaptions
                                    endOfTime={config.iguDepositBonus?.endsAt}
                                    onEnd={() => undefined}
                                />
                            )}
                        </View>
                    )}

                    <GamingWalletChangeFeature customStyles={styles.feature} />
                </TouchableOpacity>
            </ScrollView>
            <ButtonsBottom
                safe
                title={i18n.t('general.BUTTONS.playPvpGames')}
                onPress={onClose}
            />
            <ModalPvpBonus
                isVisible={pvpBonusModal}
                onClose={close}
                onPress={onPvpBonusButton}
            />
            <ModalPvpBonusFreePet
                isVisible={pvpBonusFreePet}
                onClose={closePvpBonusFreePet}
                onPress={onPressFreePet}
            />
        </ModalBottom>
    );
};

export default ModalCompetitiveGames;
