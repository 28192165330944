import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';
import { stylesModalNavigationFix } from '@helpers/style';

import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
            paddingBottom: verticalScale(16),
            ...stylesModalNavigationFix(),
        },
        itemContainer: {
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            paddingHorizontal: scale(20),
            backgroundColor: 'transparent',
        },

        header: {
            paddingHorizontal: scale(16),
        },
    });

export default styles;
