// eslint-disable-next-line import/extensions
import basicFile from './index.js';

const tr = {
    ...basicFile,
    title: {
        suffix: 'IguVerse',
        Main: 'Ana',
        Play: 'Oyun',
        Charity: 'Hayırseverlik',
        Collection: 'Koleksiyon',
        Marketplace: 'Pazar Yeri',
        Home: 'Ev',
        //MysteryLikes
        HowMysteryLikesWork: 'The Mystery Of Likes - Nasıl çalışır',
        WelcomeHowMysteryLikesWork: 'The Mystery Of Likes - Hoş geldiniz',
        MysteryLikesMain: 'The Mystery Of Likes - Ana Sayfa',
        MysteryLikesActiveOrder: 'The Mystery Of Likes - Aktif Siparişler',
        MysteryLikesHistory: 'The Mystery Of Likes - Oyun Geçmişi',
        MysteryLikesCreateGameWelcome:
            'The Mystery Of Likes - Hoş Geldiniz Oluşturun',
        MysteryLikesCreateGameInput: 'The Mystery Of Likes - Oluştur',
        MysteryLikesCreateGameSuccess: 'The Mystery Of Likes - Başarı',
        MysteryLikesSelectRange: 'The Mystery Of Likes - Aralık Seçin',
        MysteryLikesSelectRangeFinish: 'The Mystery Of Likes - Oyun Bilgileri',

        //
        // NFT Evolution
        HowNftEvolutionWelcome: 'NFT Evolution - Nasıl çalışır',
        NftEvolutionWelcome: 'NFT Evolution - Hoş geldiniz',
        NftEvolutionSelectPets: 'NFT Evolution',
        NftEvolutionCheckout: 'NFT Evolution - Şanslar',
        NftEvolutionSuccess: 'NFT Evolution - Başarı',
        NftEvolutionSelectPet: 'NFT Evolution',
        NftEvolutionMintPet: 'NFT Evolution - Mint',
        NftEvolutionMintPetSuccess: 'NFT Evolution - Başarı',
        //
        //NftRoyal
        HowNftRoyalWork: 'The Grand Race - How it works',
        WelcomeNftRoyalWork: 'The Grand Race - Welcome',
        NftRoyalMain: 'The Grand Race - Main',
        NftRoyalActiveOrder: 'The Grand Race - Vacant Rooms',
        NftRoyalCreateGameWelcome: 'The Grand Race - Create',
        NftRoyalGameConfigurations: 'The Grand Race - Game Settings',
        NftRoyalEnterCoinsAmount: 'The Grand Race - Select Coins Amount',
        NftRoyalCreateGameSuccess: 'The Grand Race - Create Game Success',
        NftRoyalHistory: 'The Grand Race - History',
        NftRoyalGameHistory: 'The Grand Race - Game History',
        NftRoyalPlayGame: 'The Grand Race',
        //
        AdminRoot: 'Yönetici',
        AdminVerify: 'Yönetici - Doğrula',
        AdminSendPushNotification: 'Yönetici - Anında Bildirim Gönder',
        AdminReverification: 'Yönetici - Yineleme',
        AdminBlockUser: 'Yönetici - Kullanıcıyı Engelle',
        AdminMaintenanceMode: 'Yönetici - Bakım Modu',
        AdminAssignWhiteList: 'Yönetici - Beyaz Liste Atama',
        AdminAssignRole: 'Yönetici - Rol Atama',
        MintRoot: 'Nane',
        MintPetRanks: 'Mint Sıralamaları Seçin',
        MintPetSelectGeneration: 'Nane Seçme Yöntemi',
        MintGenerationInfo: 'Nane Bilgisi',
        MintSelectGeneratedNft: 'Mint Select NFT',
        MintPetPickPhoto: 'Nane Seçimi Fotoğrafı',
        MintPetEditPhoto: 'Mint Fotoğraf Düzenle',
        MintPetName: 'Nane Evcil Hayvan Adı Seçin',
        MintPetCheckout: 'Mint Ödeme',
        MintPetCheckoutSuccess: 'Mint Ödeme Başarısı',
        AddWalletInitial: 'Cüzdan Ekle',
        ImportWallet: 'İthalat Cüzdanı',
        CreateWalletFirst: 'Cüzdan Oluştur',
        CreateWalletPassphrase: 'Cüzdan Tohumu Oluşturun',
        CreateWalletVerification: 'Tohum Doğrulama',
        CreateWalletSuccess: 'Cüzdan Başarısı',
        Login: 'Giriş',
        WalletRoot: 'Cüzdan',
        WalletCoinsMain: 'Cüzdan - Madeni Paralar',
        WalletNFTSMain: "Cüzdan - NFT'ler",
        WalletRewardsMain: 'Cüzdan -Ödüller',
        //Gaming
        WalletGamingTab: 'Cüzdan - Oyun',
        WalletGamingMain: 'Cüzdan - Oyun',
        TopUpGamingWallet: 'Cüzdan - Tamamla',
        TopUpCheckoutScreen: 'Cüzdan - Tamamla Ödeme',
        TopUpSuccessScreen: 'Cüzdan - Tamamla Ödeme Başarısı',
        WithdrawIguSuccessScreen: 'Cüzdan - Geri Çekilme Başarısı',
        GamingWalletTransactionDetails: 'Cüzdan - İşlem Ayrıntıları',
        WithdrawInputAmount: 'Cüzdan - Para Çekme',
        //
        WalletCoinsBalance: 'Cüzdan - Madeni Para Bakiyesi',
        WalletCoinsWithdraw: 'Cüzdan - Para Çekme',
        WalletCoinsWithdrawSuccess: 'Cüzdan - Para Çekme Başarısı',
        WalletCoinsTransactionDetails: 'Cüzdan - İşlem Ayrıntıları',
        WalletNFTSList: "Cüzdan - NFT'ler Listesi",
        WalletNFTSWithdraw: "Cüzdan - NFT'ler Para Çekme",
        WalletNFTSWithdrawSuccess: "Cüzdan - NFT'ler Para Çekme Başarısı",
        WalletNFTSTransactionDetails: "Cüzdan - NFT'ler İşlem Detayları",
        WalletRewardsClaimSuccess: 'Cüzdan - Ödül Talebi Başarısı',
        WalletRewardsTransactionDetails: 'Cüzdan - Ödül İşlemi Ayrıntıları',
        WalletRewardsTab: 'Cüzdan - Ödüller',
        WalletTradeMain: 'Cüzdan - Ticaret Ana',
        WalletTradeChart: 'Cüzdan - Ticaret Tablosu',
        WalletTradeSuccess: 'Cüzdan - Ticaret Başarısı',
        WalletSettings: 'Cüzdan - Ayarlar',
        WalletSettingsBackup: 'Cüzdan - Ayarlar Yedekleme',
        StakingWelcome: 'Cüzdan - Greve Hoş Geldiniz',
        StakingOptions: 'Cüzdan - Boost Seçenekleri',
        StakingPlans: 'Cüzdan - Boost Planları',
        StakingDetailedInfo: 'Cüzdan - Boost Detaylı Bilgi',
        StakingCoinsInput: 'Cüzdan - Boost Coin Girişi',
        StakingDailyBoost: 'Cüzdan - Günlük Boost Artışı',
        StakingSuccess: 'Cüzdan - Boost Başarısı',
        StakingAlready: 'Cüzdan - Şimdiden Stake Etme',
        UnStakingSuccess: 'Cüzdan - Sarsılmaz Başarı',
        PlayTasks: 'Görevler',
        PlayTasksActive: 'Görevler Aktif',
        PlayTasksHistory: 'Görev Geçmişi',
        PlayEditNFTs: "NFT'leri düzenleyin",
        PlayTasksHistoryDetails: 'Görev Geçmişi Ayrıntıları',
        PlayCollectedExperience: 'Birikmiş Tecrübe',
        PlaySuccessScreen: 'Görev Başarısı',
        SocializeToEarnWelcomeShare: 'SocializeToEarn - Hoş Geldiniz',
        SocializeToEarnShareYourPhoto:
            'SocializeToEarn - Fotoğrafınızı Paylaşın',
        SocializeToEarnCreateSharePhoto:
            'SocializeToEarn - Fotoğraf Paylaşımı Oluşturun',
        SocializeToEarnVerifyUser: 'SocializeToEarn - Kullanıcı Doğrulama',
        SocializeToEarnCreateShareTemplate:
            'SocializeToEarn - Şablon ile Paylaş',
        SocializeToEarnVerifyUserError:
            'SocializeToEarn - Kullanıcı Hatasını Doğrula',
        SocializeToEarnShareYourPhotoSuccess:
            'SocializeToEarn - Fotoğraf Başarınızı Paylaşın',
        SocializeToEarnWelcomeVerify:
            'SocializeToEarn - Hoş Geldiniz Doğrulama',
        SocializeToEarnVerifyUserMatch:
            'SocializeToEarn - Kullanıcı Eşleşmesini Doğrula',
        SocializeToEarnVerifyUserNotFound:
            'SocializeToEarnVerify - Kullanıcı Bulunamadı',
        SocializeToEarnVerifyUserFailed:
            'SocializeToEarn - Kullanıcı Doğrulama Başarısız',
        SocializeToEarnVerifyUserAlreadyCompleted:
            'SocializeToEarn - Kullanıcı Doğrulama İşlemi Tamamlandı',
        MoveToEarnWelcome: 'MoveToEarn - Hoş Geldiniz',
        MoveToEarnProgress: 'MoveToEarn - İlerleme',
        MoveToEarnSummary: 'MoveToEarn - Özet',
        PlayToEarn: 'PlayToEarn',
        PlayToEarnWelcome: 'PlayToEarn - Hoşgeldiniz',
        PlayToEarnProgress: 'PlayToEarn - Oyna',
        CharityOrganizations: 'Hayırseverlik - Organizasyonlar',
        CharityDetailedOrganization: 'Hayırseverlik - Detaylı Organizasyon',
        CharityOrganizationDonations:
            'Hayır Kurumları - Organizasyon Bağışları',
        CharityEditSelectedOrganization:
            'Hayır Kurumu - Seçili Kuruluşu Düzenle',
        CharityEditDetailedOrganization:
            'Hayır Kurumu - Detaylı Organizasyonu Düzenle',
        CollectionMain: 'Koleksiyon',
        CollectionDetailedPet: 'Koleksiyon - Evcil Hayvanım',
        CollectionPetLevelUpCheckout:
            'Koleksiyon - Evcil Hayvan Seviye Yükseltme Ödeme',
        CollectionPetLevelUpSuccess:
            'Koleksiyon - Evcil Hayvan Seviye Yükseltme Başarısı',
        CollectionPetRankUpCheckout: 'Koleksiyon - Pet Rank Up Checkout',
        CollectionPetRankUpSuccess:
            'Koleksiyon - Evcil Hayvan Sıralama Başarısı',
        CollectionPetHealUpCheckout: 'Koleksiyon - Pet Rank Up Checkout',
        CollectionPetHealUpSuccess:
            'Koleksiyon - Evcil Hayvan İyileştirme Başarısı',
        CollectionPetReviveCheckout: 'Koleksiyon - Pet Revive Ödeme',
        CollectionPetReviveSuccess: 'Koleksiyon - Pet Revive Success',
        CollectionPetAgeReviveCheckout: 'Koleksiyon - Pet Age Revive Ödeme',
        CollectionPetAgeReviveSuccess: 'Koleksiyon - Pet Age Revive Başarı',
        CollectionMultiplePetsHealUpProceeding:
            'Koleksiyon - Tüm Evcil Hayvanları İyileştirin',
        CollectionMultiplePetsHealUpCheckout:
            'Koleksiyon - Evcil Hayvan Yemi Ödeme',
        CollectionMultiplePetsHealUpSuccess:
            'Koleksiyon - Evcil Hayvanlar Başarıyı Besler',
        ProfileRoot: 'Profil',
        ProfileMain: 'Profil - Ana',
        ProfileLanguagesSettings: 'Profil - Diller Ayarları',
        ProfileNotificationsSettings: 'Profil - Bildirim Ayarları',
        ProfileSocialAccountsSettings: 'Profil - Sosyal Hesap Ayarları',
        ProfileSocialAccessibilitySettings: 'Profil - Erişilebilirlik Ayarları',
        ProfileSocialMedia: 'Profil - Sosyal Medya',
        Dev: 'Dev',
        RanksDetailedInfo: 'Rütbeler Detaylı Bilgi',
        EnergyInfoScreen: 'Enerji Bilgi Ekranı',
        Referrals: 'Yönlendirmeler',
        ReferralsAll: 'Yönlendirmeler - Tümü',
        ReferralsInvites: 'Yönlendirmeler - Davetler',
        ReferralsInvite: 'Yönlendirmeler - Davet Et',
        ReferralsHowItsWork: 'Yönlendirmeler - Nasıl Çalışır',
        // LEADERBOARD
        Leaderboard: 'Liderlik Tablosu',
        LeaderboardDailyTasks: 'Liderlik Tablosu - Görev',
        LeaderboardPvpGames: 'Liderlik Tablosu - PvP',
        //
        MysteryBoxMain: 'Gizemli Kutular',
        MysteryBoxPurchaseWelcome: 'Gizemli Kutular - Satın Alma Hoş Geldiniz',
        MysteryBoxPurchase: 'Gizemli Kutular - Satın Alma',
        MysteryBoxPurchaseCheckout: 'Gizemli Kutular - Satın Alma İşlemi',
        MysteryBoxPurchaseSuccess: 'Gizemli Kutular - Satın Alma Başarısı',
        HowToCharity: 'Hayır İşleri Nasıl Yürür?',
        HowToPlay: 'Nasıl Oynanır',
        HowToReferrals: 'Yönlendirmeler - Nasıl Çalışır',
        HowToAvailableRewards: 'Mevcut Ödüller nasıl çalışır?',
        HowToLockedRewards: 'Available Locked nasıl çalışır?',
        HowToGamingAvailableWallet: 'Mevcut Cüzdan nasıl çalışır?',
        HowToGamingPendingWallet: 'Bekleyen Cüzdan nasıl çalışır?',
        HowToSocializeToEarn: 'Nasıl Paylaşılır',
        HowToVerifyUser: 'Evcil Hayvan Kimlikleri Nasıl Bulunur?',
        HowToPurchaseBNB: 'BNB nasıl satın alınır',
        HowToPurchaseSpadeId: 'SPACE ID Nasıl Kullanılır',
        HowToCommissionFee: 'Ücret',
        HowToFee: 'Ücret',
        BonusMintRanksInfo: 'Mint - Bonus Rütbeleri',
        BonusMintRanksCarousel: 'Mint - Bonus Tüm Rütbeler',
        InitialMint: 'İlk Evcil Hayvanınızı Nane',
        InitialReferral: "IguVerse'e Davet Edildiniz",
        YourPetsAreHungry: 'Evcil Hayvanlarınız Aç!',
        UpdateApp: 'Yeni Güncelleme',
        //ONBOARDING
        MainWalletOnBoardingScreen: 'Ana Cüzdan',
        LoginOnBoardingScreen: 'Hoş Geldiniz',

        //MARKETPLACE
        MarketPlaceActiveOrders: 'Pazar Yeri - Aktif Siparişler',
        MarketPlaceMyProfile: 'Pazar Yeri - Profilim',
        MarketPlaceProfile: 'Pazar Yeri - Profil',
        MarketPlaceWelcomeCreateOrder: 'Pazar Yeri - Hoş geldiniz',
        MarketPlaceSelectNft: "Pazar Yeri - NFT'yi seçin",
        MarketPlaceSelectNftPrice: 'Pazar Yeri - Fiyat Seçiniz',
        MarketPlaceOverviewGroup: 'Pazar Yeri - Genel Bakış',
        MarketPlaceOverview: 'Pazar Yeri - Genel Bakış',
        MarketPlaceOverviewOwnerHistory: 'Pazar Yeri - Sahiplerin Geçmişi',
        MarketPlaceBuyCheckout: 'Pazar Yeri - Ödeme',
        MarketPlaceCreateOrderSuccess: 'Pazar Yeri - Başarı',
        MarketPlaceDelistSuccess: 'Pazar Yeri - Başarı',
        MarketPlaceBuySuccess: 'Pazar Yeri - Başarı',

        //Achievements
        Achievements: 'Başarılar',
        AchievementsDetails: 'Başarılar - Ayrıntılar',

        //PassCode
        PassCodeFaceId: 'Geçiş kodu - Biyometrik',
        PassCodeNew: 'Geçiş kodu - Etkinleştir',
        PassCodeChange: 'Geçiş kodu - Değişim',
        PassCodeSuccess: 'Geçiş kodu - Başarı',
        PassCodeSettings: 'Geçiş kodu - Ayarlar',
        PassCodeConfirm: 'Geçiş kodu - Onaylayın',

        //Lucky Strike
        WelcomeLuckyStrike: 'Lucky Strike - Hoş geldiniz',
        LuckyStrike: 'Lucky Strike',
        LuckyStrikeMain: 'Lucky Strike - Turlar',
        LuckyStrikeHistory: 'Lucky Strike - Tarih',
        HowLuckyStrikeWork: 'Lucky Strike - Nasıl çalışır',
        LuckyStrikeAllParticipants: 'Lucky Strike - Katılımcılar',
        LuckyStrikeWaitingForResult: 'Lucky Strike - Sonuç',
        LuckyStrikeUserTickets: 'Lucky Strike - Biletler',
        LuckyStrikeUserTicketsWait: 'Lucky Strike - Biletler',
        LuckyStrikeAllWinners: 'Lucky Strike - Kazananlar',
        LuckyStrikeGameHistory: 'Lucky Strike - Tarih',

        //Quantum Disk
        WelcomeQuantumSpin: 'Quantum Disk - Hoş geldiniz',
        HowQuantumSpinWork: 'Quantum Disk - Nasıl çalışır',
        QuantumSpin: 'Quantum Disk',
        QuantumSpinMain: 'Quantum Disk - Teker',
        QuantumSpinHistory: 'Quantum Disk - Tarih',
        QuantumSpinGameHistory: 'Quantum Disk - Tarih',
        QuantumSpinProbablyFair: 'Quantum Disk - Kesin Olarak Adil',
        QuantumSpinProbablyFairHistory: 'Quantum Disk - Tarih',

        //TokenTeller
        WelcomeTokenTeller: 'Tokenteller - Hoş geldiniz',
        TokenTeller: 'Tokenteller',
        TokenTellerMain: 'Tokenteller - Turlar',
        TokenTellerHistory: 'Tokenteller - Tarih',
        HowTokenTellerWork: 'Tokenteller - Nasıl Çalışır',
        TokenTellerChart: 'Tokenteller - Grafik',
        TokenTellerAllParticipants: 'Tokenteller - Katılımcılar',
        TokenTellerWaitingForResult: 'Tokenteller - Bekle',
        TokenTellerGameHistory: 'Tokenteller - Tarih',
    },
};

export default tr;
