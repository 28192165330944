import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '../../../../helpers/dimensions';
import { stylesModalNavigationFix } from '../../../../helpers/style';
import { theme } from '../../../../types';

const stylesMain = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            paddingHorizontal: scale(20),
            ...stylesModalNavigationFix(),
        },
        petContainer: {},
    });

export default stylesMain;
