import * as React from 'react';

import Icon, { ICON_NAMES } from '@components/Icons';
import RankIcon from '@components/Icons/RankIcon';
import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';

import stylesMain from './styles';

interface IProps {
    isOneColor?: boolean;
    rank1Id: string;
    rank2Id: string;
    winRankId: string;
}

const NftEvolutionRankItem = ({
    isOneColor,
    rank1Id,
    rank2Id,
    winRankId,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <>
            <View style={styles.rankOne}>
                <RankIcon slug={rank1Id} size={32} />
            </View>
            <View style={styles.rankTwo}>
                <RankIcon slug={rank2Id} size={30} />
            </View>
            <Icon
                name={ICON_NAMES.ARROW_RIGHT}
                color={
                    isOneColor ? styles.oneColorArrow.color : styles.arrow.color
                }
                size={20}
            />
            <View style={styles.rankThree}>
                <RankIcon slug={winRankId} size={32} />
            </View>
        </>
    );
};
export default NftEvolutionRankItem;
