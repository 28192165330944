import * as React from 'react';
import { useMemo } from 'react';
import { ScrollView, StyleProp, ViewStyle } from 'react-native';

import BoostImage from '@assets/icons/emptyState/stakingBoost.svg';
import CalculationIcon from '@assets/icons/staking/calculation.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import CardFeatureInfo from '@components/CardFeatureInfo/CardFeatureInfo';
import { View } from '@components/Themed';
import { useStaking } from '@contexts/StakingContext';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import Icon, { ICON_NAMES } from '../../Icons';
import CoinIcon from '../../Icons/CoinIcon';
import stylesMain from './styles';

interface IProps {
    containerStyle?: StyleProp<ViewStyle>;
}

const StakingWelcomeInfo = ({ containerStyle }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { stakingPlans } = useStaking();

    const maxBoost = useMemo(() => {
        if (!stakingPlans) {
            return '';
        }

        return stakingPlans[stakingPlans.length - 1].plan
            .maxBoostRewardPercentage;
    }, [stakingPlans]);

    return (
        <ScrollView
            style={[styles.container, containerStyle]}
            showsVerticalScrollIndicator={false}
            bounces={false}>
            {maxBoost && (
                <BlockHeader
                    emptyStateIcon={<BoostImage />}
                    type="emptyState"
                    title={i18n.t('staking.welcomeScreen.headerTitleStart')}
                    additionalTitle={i18n.t(
                        'staking.welcomeScreen.headerTitleCenter',
                        {
                            number: maxBoost,
                        }
                    )}
                    text={i18n.t('staking.welcomeScreen.headerText')}
                />
            )}
            <View style={styles.cardsInfoWrapper}>
                <CardFeatureInfo
                    title={i18n.t('staking.welcomeScreen.calculation.title')}
                    text={i18n.t('staking.welcomeScreen.calculation.text')}
                    warningText={i18n.t(
                        'staking.welcomeScreen.calculation.alert'
                    )}
                    icon={<CalculationIcon width={48} height={48} />}
                />
                <CardFeatureInfo
                    containerStyle={styles.gap}
                    title={i18n.t('staking.welcomeScreen.stake.title')}
                    text={i18n.t('staking.welcomeScreen.stake.text')}
                    icon={<CoinIcon slug={'VIGUP'} size={48} />}
                />
                <CardFeatureInfo
                    containerStyle={styles.gap}
                    title={i18n.t('staking.welcomeScreen.lock.title')}
                    text={i18n.t('staking.welcomeScreen.lock.text')}
                    icon={<Icon name={ICON_NAMES.LOCK_FILL} size={48} />}
                />
            </View>
        </ScrollView>
    );
};

export default StakingWelcomeInfo;
