import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import AgeIndicator from '@components/AgeIndicator';
import CacheImage from '@components/CacheImage';
import HealthIndicator from '@components/HealthIndicator';
import RankIcon from '@components/Icons/RankIcon';
import LevelIndicator from '@components/LevelIndicator';
import { Text, View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import { BreedingPetParentOutput } from '@models/mysteryGames';

import stylesMain from './styles';

interface IProps {
    pet: BreedingPetParentOutput;
    onPress: () => void;
    isSelected: boolean;
}

const NftEvolutionPetItem = ({ pet, onPress, isSelected }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const {
        name,
        level,
        levelProgressPercentage,
        healthPercentage,
        ageDays,
        maxAgeDays,
        imageUrl,
        rank,
    } = pet;

    return (
        <TouchableOpacity
            activeOpacity={0.7}
            style={[styles.shadow, isSelected && styles.selectedShadow]}
            onPress={onPress}>
            <View style={[styles.itemContainer, isSelected && styles.selected]}>
                <View style={styles.leftWrapper}>
                    <View style={styles.imageWrapper}>
                        <CacheImage
                            source={imageUrl}
                            style={styles.image}
                            resizeMode="contain"
                        />
                    </View>
                    <View style={styles.textWrapper}>
                        <View style={styles.titleWrapper}>
                            <RankIcon slug={rank} size={24} />
                            <Text
                                style={styles.petName}
                                numberOfLines={1}
                                ellipsizeMode="tail">
                                {name}
                            </Text>
                        </View>

                        <LevelIndicator
                            level={level}
                            fillAmount={levelProgressPercentage}
                        />
                        <View style={styles.health}>
                            <HealthIndicator
                                badge
                                healthPercentage={healthPercentage}
                            />

                            <AgeIndicator
                                ageDays={ageDays}
                                maxAgeDays={maxAgeDays}
                                containerStyle={styles.ageWrapper}
                            />
                        </View>
                    </View>
                </View>
            </View>
        </TouchableOpacity>
    );
};
export default React.memo(NftEvolutionPetItem);
