import React from 'react';

import _ from 'lodash';

import { Pet } from '@Data/Models';
import { usePlay } from '@contexts/PlayContext';
import { isDeadPet } from '@helpers/helpers';
import { stylesAnimation } from '@helpers/style';
import { useNavigation } from '@navigation/useNavigation';

import Animation from '../components/Animation';
import CustomModal from '../components/CustomModal';
import ImageIguana from '../components/ImageIguana';
import i18n from '../i18n/i18n';
import ROUTES from '../navigation/routes';

const usePetsStatus = () => {
    const navigation = useNavigation();

    const {
        playablePets,
        setIsAllDead,
        setIsSomeDead,
        isAllDead,
        isSomeDead,
        oldPlayablePets,
        setOldPlayablePets,
    } = usePlay();

    const getIdAndHealth = (pets: Pet[] | null) => {
        if (!pets) {
            return null;
        }
        return pets.map((p) => {
            return {
                id: p.id,
                health: p.healthPercentage,
            };
        });
    };

    const [canShowModal, setCanShowModal] = React.useState<boolean>(false);

    React.useEffect(() => {
        const checkDeadPets = () => {
            if (!playablePets?.length) {
                return;
            }
            const petsNew = getIdAndHealth(playablePets);
            const petsOld = getIdAndHealth(oldPlayablePets);

            if (!_.isEqual(petsOld, petsNew)) {
                const deadArray = playablePets.filter(isDeadPet);
                if (!deadArray.length) {
                    setIsAllDead(false);
                    setIsSomeDead(false);
                    setOldPlayablePets(playablePets);
                    return;
                }
                if (
                    deadArray.length &&
                    deadArray.length < playablePets.length
                ) {
                    setIsAllDead(false);
                    setIsSomeDead(true);
                }
                setOldPlayablePets(playablePets);
            }
            if (!_.isEqual(petsOld, petsNew) || !isAllDead) {
                const deadArray = playablePets.filter(isDeadPet);
                if (!deadArray.length) {
                    setIsAllDead(false);
                    setIsSomeDead(false);
                    setOldPlayablePets(playablePets);
                    return;
                }
                if (deadArray.length === playablePets.length) {
                    setIsSomeDead(false);
                    setIsAllDead(true);
                }
                setOldPlayablePets(playablePets);
            }
        };
        if (canShowModal) {
            checkDeadPets();
        }
    }, [playablePets, oldPlayablePets, canShowModal]);
    // specifically do not include isAllDead to prevent reload for useEffect

    const renderSomePetsAreDeadModal = React.useCallback(() => {
        return (
            <CustomModal
                isVisible={isSomeDead}
                icon={<ImageIguana type="attention" />}
                titleText={i18n.t('playScreen.modalSomeDead.title')}
                infoText={i18n.t('playScreen.modalSomeDead.text')}
                firstButtonText={i18n.t('playScreen.modalSomeDead.button')}
                secondButtonText={i18n.t('playScreen.modalSomeDead.button2')}
                onFirstButtonClick={() => {
                    setIsSomeDead(false);
                    navigation.navigate(ROUTES.MAIN);
                }}
                onSecondButtonClick={() => {
                    setIsSomeDead(false);
                }}
                useFullScreenOverlay
            />
        );
    }, [isSomeDead]);

    const renderAllDeadModal = React.useCallback(() => {
        return (
            <CustomModal
                isVisible={isAllDead}
                icon={<Animation animation="cry" style={stylesAnimation()} />}
                titleText={i18n.t('playScreen.modalAllDead.title')}
                infoText={i18n.t('playScreen.modalAllDead.text')}
                firstButtonText={i18n.t('playScreen.modalAllDead.button')}
                onFirstButtonClick={() => {
                    setIsAllDead(false);
                    navigation.navigate(ROUTES.MAIN);
                }}
                useFullScreenOverlay
            />
        );
    }, [isAllDead]);

    return {
        renderAllDeadModal,
        renderSomePetsAreDeadModal,
        setCanShowModal,
        isSomeDead,
        isAllDead,
    };
};

export default usePetsStatus;
