import * as React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import { EstimatedGamesTurnoverOutput } from '@Data/Models';
import { GET_ESTIMATED_GAMES_TURNOVER } from '@Data/Requests';
import { useLazyQuery } from '@apollo/client';
import ICON from '@assets/icons/emptyState/pvpBonus.svg';
import Games from '@assets/icons/pvpBonus/games.svg';
import ViguGold from '@assets/icons/pvpBonus/viguGold.svg';
import GamingWalletChangeFeature from '@components/GamingWalletChangeFeature';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { errorsHandler } from '@helpers/errors';
import { priceStringWithUSD } from '@helpers/payments';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import BlockHeader from '../BlockHeader/BlockHeader';
import ButtonsBottom from '../ButtonsBottom';
import CardFeatureInfo from '../CardFeatureInfo/CardFeatureInfo';
import ModalBottom from '../ModalBottom';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onPress: () => void;
    onClose: () => void;
}

const ModalPvpBonus = ({ isVisible, onPress, onClose }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { priceInUsd } = useWallet();

    const [getGamesTurnOver, { data, loading }] =
        useLazyQuery<EstimatedGamesTurnoverOutput>(
            GET_ESTIMATED_GAMES_TURNOVER,
            {
                onError: (error) => errorsHandler(error),
                fetchPolicy: 'no-cache',
            }
        );

    React.useEffect(() => {
        if (isVisible) {
            getGamesTurnOver();
        }
    }, [isVisible]);

    const amount = React.useMemo(
        () =>
            priceStringWithUSD(
                priceInUsd,
                data?.estimatedGamesTurnover.iguAmountWei,
                Coin.vigu,
                Coin.igu
            ),
        [data, priceInUsd]
    );

    return (
        <ModalBottom
            isVisible={isVisible}
            onClose={onClose}
            modalHeight={'100%'}>
            <ScrollView
                style={styles.container}
                showsVerticalScrollIndicator={false}>
                <TouchableOpacity activeOpacity={1}>
                    <BlockHeader
                        emptyStateIcon={<ICON />}
                        type="emptyState"
                        title={i18n.t('pvpBonus.pvpBonusModal.title')}
                        text={i18n.t('pvpBonus.pvpBonusModal.text')}
                    />
                    <CardFeatureInfo
                        loading={loading}
                        containerStyle={styles.gap}
                        icon={<Games width={48} height={48} />}
                        title={i18n.t(
                            'pvpBonus.pvpBonusModal.features.games.title',
                            {
                                games:
                                    data?.estimatedGamesTurnover?.gamesCount ||
                                    0,
                            }
                        )}
                        text={i18n.t(
                            'pvpBonus.pvpBonusModal.features.games.text'
                        )}
                    />
                    <CardFeatureInfo
                        loading={loading}
                        containerStyle={styles.gap}
                        icon={<ViguGold width={48} height={48} />}
                        title={i18n.t(
                            'pvpBonus.pvpBonusModal.features.bonus.title',
                            {
                                amount,
                            }
                        )}
                        text={i18n.t(
                            'pvpBonus.pvpBonusModal.features.bonus.text'
                        )}
                    />
                    <GamingWalletChangeFeature customStyles={styles.gap} />
                </TouchableOpacity>
            </ScrollView>

            <ButtonsBottom
                title={i18n.t('general.BUTTONS.playPvpGames')}
                onPress={onPress}
                safe
                containerStyle={styles.button}
            />
        </ModalBottom>
    );
};

export default ModalPvpBonus;
