import { GraphQLError } from 'graphql/error/GraphQLError';

import { Pet } from '@Data/Models';
import i18n from '@i18n/i18n';

import { isIOS } from './app';
import { toastError } from './toastNotification';

export const wait = (timeout: any = 1500) => {
    return new Promise((resolve) => setTimeout(resolve, timeout));
};

export const waitWallet = () => {
    return wait(800);
};
export const waitForToast = () => {
    return wait(!isIOS ? 1000 : 0);
};
export const waitForModal = () => {
    return wait(1000);
};

export const waitForDeepLink = () => {
    return wait(1000);
};

export const waitForNavigation = () => {
    return wait(500);
};

export const waitForClick = () => {
    return wait(600);
};

export const waitForShare = () => {
    return wait(600);
};

export const waitForNotification = () => {
    return wait(1500);
};

export const waitBanner = () => {
    return wait(500);
};

export const toastOops = async () => {
    await waitForToast();
    toastError(
        i18n.t('general.errors.ops.text1'),
        i18n.t('general.errors.ops.text2'),
        'top',
        10000
    );
};

export const isServerError = (error: any) => {
    return error.networkError?.name === 'ServerError';
};

export const isGraphqlError = (error: any, errorSlug?: string) => {
    if (!errorSlug) {
        return error?.graphQLErrors?.find((e: GraphQLError) => true);
    }

    return error?.graphQLErrors?.find((e: GraphQLError) => {
        return e.extensions.code === errorSlug;
    });
};

export const graphQlErrorIncludes = (
    error: any,
    errorSlug: string,
    message: string
) => {
    const errorGraphQl = error?.graphQLErrors?.find((e: GraphQLError) => {
        return e.extensions.code === errorSlug;
    });

    return errorGraphQl?.message.includes(message);
};

export const isOdd = (index: number) => index % 2 !== 0;

export const truncate = (input: string, maxLength = 10, ellipsisSign = '..') =>
    input.length > maxLength
        ? `${input.substring(0, maxLength)}${ellipsisSign}`
        : input;

export const renderListLengthMax = (
    length?: number,
    max?: number,
    separator = '/'
) => {
    if (!length) {
        return `0 ${separator} ${max}`;
    }

    return `${length} ${separator} ${max}`;
};

export const statisticsFormatter = (num: number) => {
    if (num >= 1000000) {
        return (num / 1000000).toFixed(2).toString() + 'M';
    } else if (num >= 1000 && num < 1000000) {
        return (num / 1000).toFixed(2).toString() + 'K';
    } else if (num < 1000 && num >= 10) {
        return Math.floor(num).toString();
    } else if (num < 10) {
        return (Math.trunc(num * 100) / 100).toString();
    }
    return '0';
};

export const sliceLink = (url: string) => {
    const index = url.lastIndexOf('/');
    return index !== -1 ? url.slice(index + 1).toUpperCase() : null;
};

// Forces signing on a number, returned as a string
export function numberWithSign(value: number | string) {
    const numberValue = typeof value !== 'string' ? value.toString() : value;

    if (numberValue[0] !== '-') {
        return `+${value}`;
    }
    return numberValue;
}

export const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key: any, value: any) => {
        if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};

export const getBottomModalHeighByItems = (count: number) =>
    `${40 + count * 10}%`;

export function toFixedIfNecessary(value: any, decimals: number) {
    return +parseFloat(value).toFixed(decimals);
}

export const isEmptyCardPets = (pets: Pet[]) => {
    // show empty card if user amount of NFT more than 1 and less than 4
    return pets.length >= 1 && pets.length < 4;
};

export const isDeadPet = (pet: Pet) => pet.dead || pet.ageDead;
