import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: Colors[theme].controlSecondary,
            borderRadius: moderateScale(12),
            borderStyle: 'dashed',
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            flex: 1,
        },
        icon: {
            color: Colors[theme].icon.accent,
        },
        text: {
            marginTop: verticalScale(8),
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
            color: Colors[theme].text.secondary,
            textAlign: 'center',
        },
    });

export default styles;
