import { useCallback, useMemo, useState } from 'react';

import { useDimensions } from '@contexts/DimensionsContext';
import { QuantumSpinGameOutput } from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { useFocusEffect } from '@react-navigation/native';

import { useQuantumSpinGamesHistory } from '../../../hooks/useQuantumSpinGamesHistory';

const useQuantumSpinHistoryScreen = () => {
    const navigation = useNavigation();
    const { isSmallLayout } = useDimensions();

    const [firstLoad, setFirsLoad] = useState<boolean>(true);

    const {
        gamesHistory,
        getGamesHistory,
        getGamesHistoryLoadMore,
        canLoadMore,
        loadMore,
        historyLoading,
        refreshLoading: historyRefreshLoading,
    } = useQuantumSpinGamesHistory();

    useFocusEffect(
        useCallback(() => {
            getGamesHistory(firstLoad);
            setFirsLoad(false);
        }, [firstLoad])
    );

    const isRefresh = useMemo(
        () => historyRefreshLoading,
        [historyRefreshLoading]
    );
    const handleRefresh = useCallback(async () => {
        await getGamesHistory(false);
    }, []);

    const onHowItWorksPress = () => {
        navigation.push(ROUTES.HOW_QUANTUM_SPIN_WORK);
    };

    const onHistoryItemPress = (game: QuantumSpinGameOutput) => {
        navigation.navigate(ROUTES.QUANTUM_SPIN_GAME_HISTORY, {
            data: game,
        });
    };

    return {
        handleRefresh,
        isRefresh,
        isSmallLayout,
        gamesHistory,
        getGamesHistoryLoadMore,
        canLoadMore,
        loadMore,
        historyLoading,
        onHowItWorksPress,
        onHistoryItemPress,
    };
};

export default useQuantumSpinHistoryScreen;
