import * as React from 'react';

import ImageExtended from '@components/ImageExtended';

import useThemedStyles from '../../hooks/useThemedStyles';
import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    petImg: string;
    petName: string;
}

const ImageWithName = ({ petImg, petName }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={styles.nft}>
            <ImageExtended
                uri={petImg}
                containerStyles={styles.image}
                borderRadius={6}
            />
            <Text style={styles.title}>{petName}</Text>
        </View>
    );
};
export default ImageWithName;
