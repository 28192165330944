import * as React from 'react';

import { PlayTaskStatus } from '../../../../../Data/Models';
import PlayGameTask from '../../../../../assets/icons/play/taskPlay.svg';
import Button from '../../../../../components/Button';
import { Text, View } from '../../../../../components/Themed';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import EnergyBlock from '../EnergyBlock';
import TaskStatusBadge from '../TaskStatusBadge';
import stylesMain from './styles';

interface IProps {
    energyRewarded: number | null;
    maxEnergyReward: number | undefined;
    status: PlayTaskStatus | null;
    onButtonPress: (status: boolean) => void;
    loading?: boolean;
    disabled?: boolean;
}

const PlayTaskCard = ({
    energyRewarded,
    status,
    maxEnergyReward,
    onButtonPress,
    loading,
    disabled,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const rewardedEnergy = energyRewarded || 0;
    const maxEnergy = maxEnergyReward || 0;

    const renderStatutes = () => {
        switch (status) {
            case 'COMPLETED':
                return <TaskStatusBadge status={status} />;
            case 'FAILED':
                return <TaskStatusBadge status={status} />;

            default:
                return (
                    <EnergyBlock
                        size={16}
                        energy={`${rewardedEnergy} / ${maxEnergy}`}
                        textStyles={styles.energyCost}
                        containerStyles={styles.energyBlock}
                    />
                );
        }
    };

    return (
        <View style={styles.container}>
            <View style={styles.top}>
                <View style={styles.textWrapper}>
                    <View style={styles.statutes}>{renderStatutes()}</View>
                    <Text style={styles.title}>
                        {i18n.t('playScreen.playCards.PLAY_title')}
                    </Text>
                    <Text style={styles.info}>
                        {i18n.t('playScreen.playCards.PLAY_info')}
                    </Text>
                </View>
                <PlayGameTask />
            </View>
            {status ? (
                <>
                    <EnergyBlock
                        size={20}
                        energy={i18n.t('playScreen.playCards.completedEnergy', {
                            energyRewarded: rewardedEnergy,
                            maxEnergyReward: maxEnergy,
                        })}
                        textStyles={styles.energyCost}
                        containerStyles={styles.completedEnergy}
                    />
                    <Button
                        title={i18n.t('playScreen.playCards.PLAY_button')}
                        onPress={() => onButtonPress(true)}
                        loading={loading}
                        disabled={disabled}
                    />
                </>
            ) : (
                <Button
                    title={i18n.t('playScreen.playCards.PLAY_button')}
                    onPress={() => onButtonPress(false)}
                    loading={loading}
                    disabled={disabled}
                />
            )}
        </View>
    );
};
export default PlayTaskCard;
