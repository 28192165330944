import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        itemContainer: {
            backgroundColor: 'transparent',
            paddingHorizontal: scale(20),
        },
        featureWrapper: { marginTop: verticalScale(20) },
        gap: { marginBottom: verticalScale(12) },
    });

export default styles;
