import * as React from 'react';
import { Linking, ScrollView, TouchableOpacity } from 'react-native';

import * as Clipboard from 'expo-clipboard';

import { useMutation } from '@apollo/client';
import { isAndroid } from '@helpers/app';
import { errorsHandler } from '@helpers/errors';
import { getSocialLink } from '@helpers/getSocialLinks';
import { openUrlOrShowError } from '@helpers/linking';
import { toastSuccess } from '@helpers/toastNotification';

import { REVOKE_USER_SOCIAL_PLATFORM } from '../../../../Data/Requests';
import BlockHeader from '../../../../components/BlockHeader/BlockHeader';
import Button from '../../../../components/Button';
import ButtonsBottom from '../../../../components/ButtonsBottom';
import ImageIguana from '../../../../components/ImageIguana';
import ModalBottom from '../../../../components/ModalBottom';
import SocialMediaSelectItem from '../../../../components/SocialMedia/SocialMediaSelectItem';
import { View } from '../../../../components/Themed';
import { SOCIAL_BASE_LINKS } from '../../../../constants/Socials';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import stylesMain from './styles';

interface IProps {
    visible: boolean;
    onClose: () => void;
    selectedSocial: string;
    selectedUsername: string;
    reset: () => void;
}

const CheckProfileDetails = ({
    visible,
    onClose,
    selectedSocial,
    selectedUsername,
    reset,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const [loading, setLoading] = React.useState<boolean>(false);

    const [revoke] = useMutation(REVOKE_USER_SOCIAL_PLATFORM);

    const handleRevoke = React.useCallback(async () => {
        setLoading(true);
        try {
            await revoke({
                variables: {
                    platform: selectedSocial.toUpperCase(),
                    username: selectedUsername,
                },
            });
            setLoading(false);
            reset();
            onClose();
            toastSuccess(i18n.t('profile.adminReverification.toastSuccess'));
        } catch (error) {
            setLoading(false);
            errorsHandler(error, true);
        }
    }, [selectedSocial, selectedUsername]);

    const handlePressExplore = React.useCallback(async () => {
        const link = getSocialLink(selectedSocial, selectedUsername);

        const canOpen = await Linking.canOpenURL(link);
        if (!canOpen) {
            await Clipboard.setStringAsync(selectedUsername);
            toastSuccess(
                i18n.t('socializeToEarn.shareYourPetPhoto.copyToClipboard')
            );
            return;
        }
        openUrlOrShowError(link);
    }, [selectedSocial, selectedUsername]);

    return (
        <ModalBottom
            modalHeight={'85%'}
            isVisible={visible}
            onClose={onClose}
            swipeDirection={'down'}>
            <ScrollView
                showsVerticalScrollIndicator={false}
                style={styles.main}>
                <TouchableOpacity style={styles.container} activeOpacity={1}>
                    <View style={styles.image}>
                        <ImageIguana type="attention" />
                    </View>
                    <BlockHeader
                        type="emptyState"
                        title={i18n.t(
                            'profile.adminReverification.checkModal.title'
                        )}
                        text={i18n.t(
                            'profile.adminReverification.checkModal.text'
                        )}
                    />
                </TouchableOpacity>
                <View style={styles.user}>
                    <SocialMediaSelectItem
                        id={selectedSocial}
                        withLink
                        actionWithOutParams={handlePressExplore}
                        text={selectedUsername}
                        isSelected={false}
                    />
                </View>
            </ScrollView>
            <ButtonsBottom
                tailTitle
                safe={!isAndroid}
                title={i18n.t('profile.adminReverification.checkModal.button1')}
                buttonType="critical"
                loading={loading}
                disabled={loading}
                onPress={handleRevoke}>
                <Button
                    containerStyle={styles.gap}
                    title={i18n.t(
                        'profile.adminReverification.checkModal.button2'
                    )}
                    type="outline"
                    onPress={onClose}
                />
            </ButtonsBottom>
        </ModalBottom>
    );
};

export default CheckProfileDetails;
