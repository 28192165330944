import * as React from 'react';
import { Image } from 'react-native';

import { DetectionResult, ImageFile } from '../../../../Data/Models';
import Button from '../../../../components/Button';
import { ICON_NAMES } from '../../../../components/Icons';
import RecognitionResults from '../../../../components/RecognitionResults';
import { Text, View } from '../../../../components/Themed';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import stylesMain from './styles';

interface IProps {
    detectionResult: DetectionResult[] | null;
    onPreviewClick: () => void;
    onEditClick: () => void;
    savedPhoto: ImageFile | null;
}

const MintPetDetectionInfo = ({
    detectionResult,
    onPreviewClick,
    onEditClick,
    savedPhoto,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    return (
        <>
            <View style={styles.imageWrapper}>
                <View style={styles.buttons}>
                    <View style={styles.singleButton}>
                        <Button
                            type="outline"
                            iconName={ICON_NAMES.FULL_SIZE}
                            size={'md'}
                            onPress={onPreviewClick}
                        />
                    </View>
                    <View style={styles.singleButton}>
                        <Button
                            type="outline"
                            iconName={ICON_NAMES.PENCIL}
                            size={'md'}
                            onPress={onEditClick}
                        />
                    </View>
                </View>
                <Image
                    style={styles.image}
                    source={{ uri: savedPhoto?.url }}
                    resizeMode={'contain'}
                />
            </View>
            <View style={styles.petInfoWrapper}>
                {!!detectionResult && (
                    <RecognitionResults detectionResult={detectionResult} />
                )}
                <View style={styles.infoWrapper}>
                    <Text style={styles.infoText}>
                        {i18n.t('mintNamePhoto.infoText')}
                    </Text>
                </View>
            </View>
        </>
    );
};
export default MintPetDetectionInfo;
