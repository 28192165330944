import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import { scale, verticalScale } from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const stylesMain = (theme: theme) =>
    StyleSheet.create({
        container: {
            borderBottomColor: 'transparent',
            borderBottomWidth: 5,
        },
        selectedContainer: {
            marginVertical: verticalScale(5),
        },
        cardTitleText: {
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),

            marginBottom: verticalScale(4),
            color: Colors[theme].text.primary,
        },
        organizationImage: {
            width: '100%',
            height: 200,
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
        },
        cardContentContainer: {
            paddingVertical: verticalScale(16),
            paddingHorizontal: scale(16),
            borderRadius: 12,
        },
        cardDescriptionText: {
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            color: Colors[theme].text.secondary,
        },
        donatedText: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 24,
                weight: '700',
            }),
            marginLeft: scale(4),
        },
        organizationDonateContainer: {
            marginTop: verticalScale(14),
            flexDirection: 'row',
            alignItems: 'center',
        },
        borderOrganizationContainer: {
            borderWidth: 1,
            borderRadius: 12,
            borderColor: Colors[theme].border.primary,
            zIndex: 2,
        },
        shadow: {
            position: 'absolute',
            bottom: -4,
            height: 24,
            width: '100%',
            borderBottomLeftRadius: 12,
            borderBottomRightRadius: 12,
            backgroundColor: Colors[theme].shadow.default,
            zIndex: 1,
        },
        heartContainer: {
            position: 'absolute',
            zIndex: 2,
            top: 16,
            left: 16,
            backgroundColor: 'transparent',
        },
    });

export default stylesMain;
