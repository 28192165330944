import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { moderateScale, scale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        nft: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        image: {
            width: 32,
            marginRight: scale(8),
        },
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '600',
            }),
        },
    });

export default styles;
