import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { verticalScale } from 'react-native-size-matters';

import { ISocial } from '../../../constants/Socials';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import Button from '../../Button';
import ImageIguana from '../../ImageIguana';
import ModalBottom from '../../ModalBottom';
import { Text } from '../../Themed';
import { SocialMediaPreviewItem } from '../SocialMediaPreviewItem';
import stylesMain from './styles';

type Props = {
    visible: boolean;
    handleCloseModal: () => void;
    successModalButton: () => void;
    link: string;
    value: string;
    social: ISocial;
};

export const SocialMediaPreview = ({
    visible,
    handleCloseModal,
    successModalButton,
    link,
    value,
    social,
}: Props) => {
    const styles = useThemedStyles(stylesMain);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        if (!visible) {
            setIsLoading(false);
        }
    }, [visible]);

    const createPopup = () => {
        const dualScreenLeft =
            window.screenLeft !== undefined
                ? window.screenLeft
                : window.screenX;
        const dualScreenTop =
            window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width = window.innerWidth
            ? window.innerWidth
            : document.documentElement.clientWidth
            ? document.documentElement.clientWidth
            : screen.width;
        const height = window.innerHeight
            ? window.innerHeight
            : document.documentElement.clientHeight
            ? document.documentElement.clientHeight
            : screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - 520) / 2 / systemZoom + dualScreenLeft;
        const top = (height - 750) / 2 / systemZoom + dualScreenTop;
        window.open(
            link,
            undefined,
            `width=520,height=750,top=${top},left=${left}`
        );
    };

    return (
        <View>
            <ModalBottom
                avoidKeyboard
                isNestedModal
                onClose={handleCloseModal}
                isVisible={visible}
                backdropOpacity={0.7}
                modalHeight="700"
                withToasts={false}>
                <ScrollView>
                    <View style={styles.container}>
                        <View style={{ flex: 1 }}>
                            <View style={styles.image}>
                                <ImageIguana type="attention" />
                            </View>
                            <Text style={styles.title}>
                                {i18n.t(
                                    'socialMediaConnect.socialPreview.checkProfile'
                                )}
                            </Text>
                            <Text style={styles.description}>
                                {i18n.t(
                                    'socialMediaConnect.socialPreview.takeALook'
                                )}
                            </Text>
                            <View>
                                <SocialMediaPreviewItem
                                    social={social}
                                    onPress={createPopup}
                                    title={value}
                                />
                            </View>
                        </View>

                        <View
                            style={[
                                styles.bottomWrapper,
                                {
                                    paddingBottom: verticalScale(4),
                                },
                            ]}>
                            <Button
                                title={i18n.t(
                                    'socialMediaConnect.connectInstagramModal.addAccount'
                                )}
                                containerStyle={styles.button}
                                onPress={() => {
                                    setIsLoading(true);
                                    successModalButton();
                                }}
                                loading={isLoading}
                            />
                            <Button
                                type="outline"
                                title={i18n.t(
                                    'socialMediaConnect.connectInstagramModal.cancel'
                                )}
                                containerStyle={styles.button}
                                onPress={handleCloseModal}
                            />
                        </View>
                    </View>
                </ScrollView>
            </ModalBottom>
        </View>
    );
};
