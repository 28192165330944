import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        shadow: {
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].shadow.default,
        },
        container: {
            backgroundColor: Colors[theme].background,
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(16),
            marginBottom: verticalScale(4),
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        textWrapper: {
            marginTop: verticalScale(8),
        },
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
            marginBottom: verticalScale(2),
            textAlign: 'center',
        },
        text: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
            textAlign: 'center',
        },
        energyBlock: {
            backgroundColor: 'transparent',
        },
        energyText: {
            textAlign: 'center',
        },
        margin: {
            marginRight: scale(12),
        },
        top: {
            alignItems: 'center',
            marginBottom: verticalScale(16),
        },
        flex: {
            flex: 1,
        },
    });

export default styles;
