import { StyleSheet } from 'react-native';

import { isWeb } from '@helpers/app';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        content: {
            marginTop: 12,
            minHeight: 110,
        },
        textsBlock: {
            alignSelf: 'flex-start',
        },
        imageBlock: {
            position: 'absolute',
            zIndex: 1,
            borderTopRightRadius: 1000,
            borderTopLeftRadius: 1000,
            borderBottomLeftRadius: 28,
            borderBottomRightRadius: 28,
            right: -22,
        },
        webImage: {
            borderTopRightRadius: 1000,
            borderTopLeftRadius: 1000,
            borderBottomLeftRadius: 28,
            borderBottomRightRadius: 28,
            aspectRatio: 1,
            width: 212,
        },
        petImage: {
            width: isWeb ? undefined : 232,
            borderTopRightRadius: 1000,
            borderTopLeftRadius: 1000,
            borderBottomLeftRadius: 28,
            borderBottomRightRadius: 28,
            height: isWeb ? undefined : 212,
        },

        titleBlock: {
            marginRight: 16,
        },
        title: {
            ...font({
                size: 22,
                height: 28,
                weight: '900',
            }),

            backgroundColor: 'transparent',
        },
        qrCodeWrapper: {
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(8),
            borderRadius: 40,
            alignSelf: 'flex-start',
        },
        qrCode: {
            ...font({
                size: 18,
                height: 22,
                weight: '700',
            }),
            backgroundColor: 'transparent',
            textTransform: 'uppercase',
        },
        logo: {
            marginBottom: 6,
        },
    });

export default styles;
