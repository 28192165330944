import { useCallback, useState } from 'react';

import { GET_NFT_ROYAL_GAME_HISTORY } from '@Data/Requests';
import { useLazyQuery } from '@apollo/client';
import { useDimensions } from '@contexts/DimensionsContext';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { errorsHandler } from '@helpers/errors';
import { toastError, toastErrorUnknown } from '@helpers/toastNotification';
import { useDefaultScreenOrientation } from '@hooks/useDefaultScreenOrientation';
import { useLoadingHook } from '@hooks/useLoadingHook';
import { useNftRoyaleMyPendingGames } from '@hooks/useNftRoyaleMyPendingGames';
import i18n from '@i18n/i18n';
import {
    NFT_ROYAL_GAME_HISTORY_STATUS,
    NftRoyaleGameHistoryOutputResponse,
    PaginatedNftRoyaleGamesHistoryRowOutput,
} from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { useFocusEffect } from '@react-navigation/native';

import useCreateNftRoyalGame from '../../../hooks/useCreateNftRoyalGame';
import { useNftRoyalGamesHistory } from '../../../hooks/useNftRoyalGamesHistory';

const useMysteryLikesHistoryScreen = () => {
    const { setCurrentNftRoyalGameHistory, setNftRoyalCurrentGame } =
        useMysteryGames();

    const { isSmallLayout } = useDimensions();
    useDefaultScreenOrientation();

    const navigation = useNavigation();
    const {
        gamesHistory,
        getGamesHistory,
        historyLoading,
        loadMore,
        getGamesHistoryLoadMore,
        refreshLoading,
        canLoadMore,
    } = useNftRoyalGamesHistory();

    const [getNftRoyaleGameHistory] =
        useLazyQuery<NftRoyaleGameHistoryOutputResponse>(
            GET_NFT_ROYAL_GAME_HISTORY,
            {
                fetchPolicy: 'network-only',
            }
        );

    const { getNftRoyalMyPendingGamesLazy } = useNftRoyaleMyPendingGames();

    const handleRefresh = async () => {
        await getGamesHistory(false);
    };

    const { renderPreviousGameModal, handleCreateGame, createGameLoading } =
        useCreateNftRoyalGame();

    const { availableBalance } = useMysteryGames();

    const [firstLoad, setFirsLoad] = useState<boolean>(true);

    useFocusEffect(
        useCallback(() => {
            getGamesHistory(firstLoad);
            setFirsLoad(false);
        }, [firstLoad])
    );

    const { isLoading, startLoading, stopLoading } = useLoadingHook();

    const onHistoryItemPress = async (
        game: PaginatedNftRoyaleGamesHistoryRowOutput
    ) => {
        startLoading();
        try {
            if (
                game.status === NFT_ROYAL_GAME_HISTORY_STATUS.WaitingForMyself
            ) {
                const games = await getNftRoyalMyPendingGamesLazy();

                if (games.data?.nftRoyaleMyPendingGames.length) {
                    const pendingGame = games.data.nftRoyaleMyPendingGames.find(
                        (i) => i.id === game.id
                    );

                    if (pendingGame) {
                        setNftRoyalCurrentGame(pendingGame);
                        navigation.navigate(ROUTES.NFT_ROYAL_PLAY_GAME);
                    }
                } else {
                    toastError(
                        i18n.t('general.errors.wrongGameStatus.text'),
                        undefined,
                        'top'
                    );
                }
                stopLoading();
                return;
            }
            const result = await getNftRoyaleGameHistory({
                variables: {
                    gameId: game.id,
                },
            });
            if (result.data?.nftRoyaleGameHistory) {
                setCurrentNftRoyalGameHistory(result.data.nftRoyaleGameHistory);
                navigation.navigate(ROUTES.NFT_ROYAL_GAME_HISTORY, {
                    fromHistory: true,
                });
            }
            stopLoading();
        } catch (error) {
            stopLoading();
            errorsHandler(error, true);
        }
    };

    return {
        isSmallLayout,
        getGamesHistoryLoadMore,
        loadMore,
        createGameLoading,
        gamesHistory,
        historyLoading,
        onHistoryItemPress,
        refreshLoading,
        renderPreviousGameModal,
        handleCreateGame,
        isLoading,
        handleRefresh,
        availableBalance,
        canLoadMore,
    };
};

export default useMysteryLikesHistoryScreen;
