import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: Colors[theme].background,
            position: 'relative',
            zIndex: 2,
        },
        page: {
            flex: 1,
            justifyContent: 'space-between',
        },
        buttonWrapper: {
            paddingHorizontal: scale(20),
            paddingBottom: verticalScale(12),
            position: 'relative',
        },
        buttonGap: {
            marginTop: verticalScale(9),
        },
        wrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            marginVertical: verticalScale(16),
        },
        line: {
            flex: 1,
            height: 1,
            backgroundColor: Colors[theme].border.primary,
        },
        text: {
            paddingHorizontal: scale(12),
            textAlign: 'center',
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
            color: Colors[theme].text.primary,
        },
        orText: {
            color: Colors[theme].text.secondary,
        },
        privacyText: {
            ...font({
                size: 14,
                height: 18,
            }),
            textAlign: 'center',
            paddingVertical: verticalScale(16),
            color: Colors[theme].text.secondary,
        },
        privacyWrapper: {
            paddingHorizontal: scale(20),
        },
        languages: {
            alignSelf: 'center',
        },
        loadingIndicator: {
            flex: 1,
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
        },
    });

export default styles;
