import { StyleSheet } from 'react-native';

import { moderateScale, scale, verticalScale } from '@helpers/dimensions';

import Colors from '../../constants/Colors';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        shadow: {
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].shadow.default,
            marginBottom: verticalScale(16),
        },
        active: {
            backgroundColor: Colors[theme].shadow.selected,
        },
        activeContainer: {
            borderColor: Colors[theme].border.accent,
        },
        itemContainer: {
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            marginBottom: 4,
        },
        main: {
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: 'transparent',
        },
        itemText: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
        },
        container: {
            flex: 1,
            alignItems: 'flex-end',
        },
    });

export default styles;
