import * as React from 'react';
import { StyleProp, TouchableOpacity, ViewProps } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useUser } from '@contexts/UserContext';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { verticalScale } from '@helpers/dimensions';
import { useHaptic } from '@hooks/useHaptic';

import Avatar from '../../components/Avatar';
import Button, { ButtonType } from '../../components/Button';
import Divider from '../../components/Divider';
import { ICON_NAMES } from '../../components/Icons';
import { View } from '../../components/Themed';
import UserRole from '../../components/UserRole';
import useThemedStyles from '../../hooks/useThemedStyles';
import { ComponentAnyType } from '../../types';
import ROUTES from '../routes';
import { useNavigation } from '../useNavigation';
import NavigationBarTitle from './NavigationBarTitle';
import NavigationTasks, { NavigationTasksType } from './NavigationTasks';
import Wallet from './Wallet';
import stylesMain from './styles';

export type NavigationWidgetType = 'WALLET' | 'TASKS' | 'ADMIN' | undefined;

interface IProps {
    backIcon?: 'close' | 'leftArrow' | boolean;
    onBack?: () => void;
    filled?: boolean;
    wallet?: boolean;
    settingsIcon?: boolean | ICON_NAMES;
    settingsIconType?: ButtonType;
    settingsIconColor?: string | null;
    settingsComponent?: ComponentAnyType;
    navigationWidget?: NavigationWidgetType;
    currentNavigation?: NavigationTasksType;
    title?: string;
    children?: React.ReactNode;
    containerStyle?: StyleProp<ViewProps>;
    account?: boolean;
    onPressSettings?: () => void;
    showDivider?: boolean;
    fillColor?: string;
    titleColor?: string;
    needTail?: boolean;
    isOneColor?: boolean;
    settingsIconSize?: number;
}

const NavigationBar = ({
    backIcon = undefined,
    onBack = undefined,
    filled = true,
    wallet = false,
    account = false,
    settingsIcon = false,
    settingsIconType,
    settingsIconColor,
    settingsComponent,
    navigationWidget = undefined,
    currentNavigation = undefined,
    title,
    children,
    containerStyle,
    onPressSettings,
    showDivider,
    fillColor,
    titleColor,
    needTail = true,
    isOneColor,
    settingsIconSize,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { user } = useUser();
    const { walletBalance } = useWallet();
    const navigation = useNavigation();
    const { top } = useSafeAreaInsets();
    const { triggerLight } = useHaptic();

    // default back navigation function
    const handleOnBack = () => {
        if (onBack) {
            onBack();
        } else if (navigation.canGoBack()) {
            navigation.goBack();
        } else {
            navigation.navigate(ROUTES.MAIN, {
                screen: ROUTES.PLAY,
            });
        }
    };

    const handleOnAccount = () => {
        navigation.navigateToProfile();
    };

    const renderBackButton = () => {
        return (
            <View style={styles.buttonWrapper}>
                <Button
                    isOneColor={isOneColor}
                    size="md"
                    type="outline"
                    iconName={
                        backIcon === 'close'
                            ? ICON_NAMES.CLOSE
                            : ICON_NAMES.ARROW_LEFT
                    }
                    style={styles.singleButton}
                    debouncedPress
                    onPress={handleOnBack}
                />
            </View>
        );
    };

    const renderAccountIcon = () => {
        return (
            <TouchableOpacity
                onPress={() => {
                    triggerLight();
                    handleOnAccount();
                }}
                hitSlop={{ top: 10, right: 10, bottom: 10, left: 10 }}>
                <Avatar
                    size={styles.avatar.width}
                    isOneColor={isOneColor}
                    type="white"
                />
                <View style={styles.roleWrapper}>
                    <UserRole size={styles.role.width} />
                </View>
            </TouchableOpacity>
        );
    };

    const renderSettingsIcon = () => {
        return (
            <View style={styles.buttonWrapper}>
                <Button
                    size="md"
                    type={settingsIconType || 'outline'}
                    iconName={
                        typeof settingsIcon === 'boolean'
                            ? ICON_NAMES.SETTINGS
                            : settingsIcon
                    }
                    iconSize={settingsIconSize}
                    iconColor={settingsIconColor}
                    style={styles.singleButton}
                    onPress={
                        onPressSettings ||
                        (() => Console.log('Navigation settings'))
                    }
                />
            </View>
        );
    };

    const renderLeftPart = () => {
        return (
            <View style={styles.navigationBlock}>
                {/* Back button on the left without children */}
                {(onBack || backIcon) && renderBackButton()}
                {/* Profile */}
                {account && renderAccountIcon()}
            </View>
        );
    };

    const renderMiddlePart = () => {
        return (
            <View style={!!title ? styles.middleBlock : styles.navigationBlock}>
                {/* Tasks Navigation */}
                {navigationWidget === 'TASKS' && (
                    <NavigationTasks
                        currentNavigation={
                            currentNavigation as NavigationTasksType
                        }
                    />
                )}

                {/* Navigation title */}
                {!!title && (
                    <NavigationBarTitle
                        title={title}
                        color={titleColor}
                        tail={needTail}
                    />
                )}

                {/* Custom children */}
                {children}
            </View>
        );
    };

    const renderRightPart = () => {
        return (
            <View
                style={
                    settingsComponent
                        ? styles.settingsBLock
                        : styles.navigationBlock
                }>
                {/* Wallet */}
                {wallet && (
                    <Wallet
                        user={user}
                        walletBalance={walletBalance}
                        isOneColor={isOneColor}
                    />
                )}
                {/* Settings icon */}
                {!!settingsIcon && renderSettingsIcon()}
                {settingsComponent && settingsComponent}
                {!settingsComponent && !wallet && !settingsIcon && (
                    <View style={styles.emptyIcon} />
                )}
            </View>
        );
    };

    return (
        <>
            <View
                style={[
                    styles.container,
                    filled && styles.containerFilled,
                    children && styles.withChildren,
                    containerStyle,
                    fillColor && { backgroundColor: fillColor },
                    { paddingTop: verticalScale(top + 16) },
                ]}>
                <View style={styles.innerContainer}>
                    {renderLeftPart()}
                    {renderMiddlePart()}
                    {renderRightPart()}
                </View>
            </View>
            {showDivider && <Divider />}
        </>
    );
};

export default NavigationBar;
