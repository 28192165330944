import { useCallback } from 'react';
import React from 'react';

import {
    authenticateAsync,
    hasHardwareAsync,
    isEnrolledAsync,
} from 'expo-local-authentication';

import { waitForToast } from '@helpers/helpers';
import { toastError } from '@helpers/toastNotification';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import { PassCodeCheck } from '../components/PassCodeCheck';
import SecureStore from '../utils/SecureStore';
import { useLoadingHook } from './useLoadingHook';
import { useVisibleHook } from './useVisibleHook';

const config = {
    authEnabled: 'Auth_Enabled',
    passCode: 'Pass_Code',
    tmpPassCode: 'TMP_Pass_Code',
    biometricEnabled: 'Biometric_Enabled',
};

export const useLocalAuthentication = () => {
    const {
        isLoading: authLoading,
        startLoading,
        stopLoading,
    } = useLoadingHook();

    const navigation = useNavigation();

    const [isAuth, setIsAuth] = React.useState<boolean>(false);

    const checkAuthEnabled = async () => {
        return !!(await SecureStore.getItemAsync(config.authEnabled));
    };

    const checkBiometricEnabled = async () => {
        return !!(await SecureStore.getItemAsync(config.biometricEnabled));
    };

    const savePassCodeToStorage = async (value: string) => {
        await SecureStore.setItemAsync(config.passCode, value);
    };
    const saveTmpPassCodeToStorage = async (value: string) => {
        await SecureStore.setItemAsync(config.tmpPassCode, value);
    };

    const enablePassCode = async () => {
        await SecureStore.setItemAsync(config.authEnabled, 'true');
    };

    const enableBiometric = async () => {
        await SecureStore.setItemAsync(config.biometricEnabled, 'true');
    };
    const disableBiometric = async () => {
        await SecureStore.deleteItemAsync(config.biometricEnabled);
    };

    const disablePassCode = async () => {
        await SecureStore.deleteItemAsync(config.authEnabled);
        await SecureStore.deleteItemAsync(config.passCode);
        await SecureStore.deleteItemAsync(config.tmpPassCode);
        await SecureStore.deleteItemAsync(config.biometricEnabled);
    };

    const checkPassCode = async (value: string, tmp?: boolean) => {
        const storage = await SecureStore.getItemAsync(
            tmp ? config.tmpPassCode : config.passCode
        );
        return storage === value;
    };

    const checkBiometricAvailability = async () => {
        const hardware = await hasHardwareAsync();
        const enrolled = await isEnrolledAsync();
        return hardware && enrolled;
    };

    const [nextAction, setNextAction] = React.useState<() => void>();

    const localAuth = useCallback(async (action?: () => void) => {
        setNextAction(() => action);
        startLoading();
        if (!(await checkAuthEnabled())) {
            stopLoading();
            setIsAuth(true);
            action?.();
            return;
        }
        if (
            (await checkBiometricAvailability()) &&
            (await checkBiometricEnabled())
        ) {
            const { success } = await authenticateAsync();
            setIsAuth(success);
            if (!success) {
                openPassCodeCheck();
            } else {
                action?.();
            }
        } else {
            openPassCodeCheck();
        }
        stopLoading();
    }, []);

    const {
        isVisible: passCodeCheck,
        open: openPassCodeCheck,
        close: closePassCodeCheck,
    } = useVisibleHook();

    const renderPassCodeCheck = useCallback(() => {
        return passCodeCheck ? (
            <PassCodeCheck
                setIsAuth={setIsAuth}
                closeCheck={closePassCodeCheck}
                nextAction={nextAction}
                checkPassCode={checkPassCode}
            />
        ) : null;
    }, [passCodeCheck, nextAction]);

    const handleEnableBiometric = async (
        action?: () => void,
        navigate?: boolean
    ) => {
        startLoading();
        const { success } = await authenticateAsync();
        if (success) {
            await enableBiometric();
            stopLoading();
            action?.();
            return;
        }
        stopLoading();
        if (navigate) {
            navigation.replace(ROUTES.PASSCODE_SETTINGS);
            await waitForToast();
        }
        toastError(i18n.t('passcode.toasts.biometricError'), undefined, 'top');
    };

    return {
        localAuth,
        authLoading,
        isAuth,
        renderPassCodeCheck,
        checkPassCode,
        savePassCodeToStorage,
        enablePassCode,
        checkBiometricAvailability,
        checkAuthEnabled,
        saveTmpPassCodeToStorage,
        disablePassCode,
        handleEnableBiometric,
        disableBiometric,
        checkBiometricEnabled,
    };
};
