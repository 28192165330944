import * as React from 'react';
import { useCallback } from 'react';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import EmptyList from '@components/EmptyList';
import { ICON_NAMES } from '@components/Icons';
import LoadingComponent from '@components/LoadingComponent';
import PullToRefresh from '@components/PullToRefresh';
import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';
import NavigationTokenTeller, {
    NAVIGATION_TOKEN_TELLER,
} from '@navigation/NavigationBar/NavigationTokenTeller';
import ROUTES from '@navigation/routes';

import { TokenTellerMainProps } from '../../../../../types';
import GamesNavigation from '../../components/GamesNavigation';
import TokenTellerPriceStatistic from '../../components/TokenTellerPriceStatistic';
import TokenTellerRound from '../../components/TokenTellerRound';
import TokenTellerRoundsModal from '../../components/TokenTellerRoundsModal';
import useTokenTellerMainScreen from './hooks/useTokenTellerMainScreen';
import stylesMain from './styles';

const GAME = MYSTERY_GAMES.tokenteller;
const FLOW = 'TokenTellerMainScreen';

const TokenTellerMainScreen = ({ route }: TokenTellerMainProps) => {
    const styles = useThemedStyles(stylesMain);
    const {
        handleRefresh,
        isRefresh,
        isSmallLayout,
        isLoading,
        handOnHowItWorks,
        roundsModal,
        openRoundsModal,
        closeRoundsModal,
        onPurchaseTicket,
        renderPurchaseModal,
        tokenTellerGames,
        currentGameId,
        onEndTime,
        isReloading,
        purchaseModal,
    } = useTokenTellerMainScreen(route.params?.game);

    const headerComponent = useCallback(() => {
        return (
            <BlockHeader
                containerStyle={styles.header}
                type="primary"
                title={i18n.t(`HomeScreen.${GAME}.${FLOW}.title`)}
                text={i18n.t(`HomeScreen.${GAME}.${FLOW}.text`)}
            />
        );
    }, [styles]);

    const emptyComponent = useCallback(() => {
        return (
            <EmptyList
                containerStyle={styles.emptyList}
                emptyTitle={i18n.t('general.errors.emptyErrorScreen.title')}
                emptyText={i18n.t('general.errors.emptyErrorScreen.text')}
                withAnimation="cry"
            />
        );
    }, [isSmallLayout]);

    return (
        <View style={styles.main}>
            <GamesNavigation
                backIconName={ICON_NAMES.CLOSE}
                type={MYSTERY_GAMES.tokenteller}
                onPress={openRoundsModal}
                loading={isReloading}
                handleOnInfoIconPress={handOnHowItWorks}
                buttonTitle={i18n.t(`HomeScreen.${GAME}.predictPrice`)}
            />
            <NavigationTokenTeller
                currentNavigation={NAVIGATION_TOKEN_TELLER.ROUNDS}
            />
            <View style={styles.content}>
                {isLoading && !tokenTellerGames ? (
                    <LoadingComponent text={i18n.t('general.loading')} />
                ) : (
                    <PullToRefresh
                        contentContainerStyle={styles.list}
                        refreshing={isRefresh}
                        textLoading={i18n.t('pullToRefresh.loading')}
                        onRefresh={handleRefresh}
                        HeaderComponent={headerComponent()}
                        EmptyComponent={emptyComponent()}
                        renderData={tokenTellerGames || []}
                        //@ts-ignore ignore item type any
                        renderItem={({ item }) => (
                            <TokenTellerRound
                                routeBack={ROUTES.TOKEN_TELLER_MAIN}
                                loading={isReloading}
                                key={item.id}
                                game={item}
                                onPress={onPurchaseTicket}
                                onEndTime={onEndTime}
                                inBackground={purchaseModal || roundsModal}
                            />
                        )}
                        FooterComponent={
                            tokenTellerGames?.length ? (
                                <TokenTellerPriceStatistic
                                    withBottomGap
                                    containerStyle={styles.priceStatistic}
                                />
                            ) : null
                        }
                    />
                )}
            </View>

            <TokenTellerRoundsModal
                isVisible={roundsModal}
                onClose={closeRoundsModal}
                openModal={openRoundsModal}
                onPurchaseTicket={onPurchaseTicket}
                loading={isReloading}
                routeBack={ROUTES.TOKEN_TELLER_MAIN}
            />

            {!!currentGameId && renderPurchaseModal(currentGameId)}
        </View>
    );
};

export default TokenTellerMainScreen;
