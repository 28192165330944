import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        headerTitle: {
            color: Colors[theme].text.primary,
            ...font({
                size: 18,
                height: 24,
                weight: '700',
            }),
            width: '60%',
            marginRight: scale(12),
        },
        headerText: {
            marginTop: verticalScale(20),
            marginHorizontal: scale(20),
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: verticalScale(16),
        },
    });

export default styles;
