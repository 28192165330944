import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { scale, verticalScale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        switch: {
            width: 24,
            height: 24,
            borderRadius: 12,
            borderWidth: 1,
            backgroundColor: Colors[theme].buttonBackgroundOutline,
            borderColor: Colors[theme].border.primary,
        },
        shadow: {
            width: 24,
            height: 24,
            borderRadius: 12,
            borderBottomWidth: 26,
            borderColor: Colors[theme].buttonShadowOutline,
            position: 'absolute',
            zIndex: 2,
            top: -2,
            left: -2,
        },
        background: {
            width: 40,
            height: 20,
            backgroundColor: Colors[theme].buttonBorderOutline,
            borderRadius: 10,
        },
        activeBackground: {
            backgroundColor: Colors[theme].switchOn,
        },
        switchContainer: {
            position: 'relative',
        },
        optionContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            borderRadius: 12,
            borderColor: Colors[theme].border.primary,
            paddingHorizontal: scale(16),
            paddingTop: verticalScale(16),
            zIndex: 1,
            justifyContent: 'space-between',
            marginBottom: verticalScale(16),
        },
        paddingBottom: {
            paddingBottom: verticalScale(16),
        },
        itemTitle: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '600',
            }),
        },
        textContainer: {
            flex: 1,

            marginRight: scale(12),
        },
        itemDesc: {
            marginTop: verticalScale(4),
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 21,
                weight: '400',
            }),
        },
    });

export default styles;
