import { Image } from 'react-native';

import images from './images';

export type ImageIguanaType =
    | 'cry'
    | 'attention'
    | 'happy'
    | 'redX'
    | 'success';

interface IProps {
    type: ImageIguanaType;
    size?: 'big' | 'small';
}

const ImageIguana = ({ type, size = 'small' }: IProps) => {
    return (
        <Image source={images[type].source} style={images[type].size[size]} />
    );
};
export default ImageIguana;
