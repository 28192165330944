import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            marginBottom: verticalScale(16),
            borderRadius: moderateScale(12),
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            width: '100%',
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
        },
        card: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            flex: 1,
        },
        cardWrapper: {
            flex: 1,
            marginRight: scale(16),
        },
        cardHeader: {
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: verticalScale(4),
        },
        cardTitle: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                weight: '700',
                height: 24,
            }),
        },
        infoIcon: {
            marginLeft: scale(4),
        },
        cardBody: {
            flexDirection: 'row',
        },

        textDescription: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
            letterSpacing: 0.08,
        },
        highlightedContainer: {
            backgroundColor: Colors[theme].surface.highlighted,
        },
        dead: {
            backgroundColor: Colors[theme].surface.danger,
        },
        deadText: {
            color: Colors[theme].text.onLight,
        },

        highlightedText: {
            color: Colors[theme].text.onLight,
        },
        highlightedInfoIcon: {
            color: Colors[theme].text.onLight,
        },
    });

export default styles;
