import { useState } from 'react';

import {
    GET_MYSTERY_LIKES_PENDING_GAMES,
    GET_NFT_ROYAL_MY_PENDING_GAMES,
} from '@Data/Requests';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import { errorsHandler } from '@helpers/errors';
import { useLoadingHook } from '@hooks/useLoadingHook';
import {
    NftRoyalePendingGameOutput,
    NftRoyalePendingGameOutputResponse,
} from '@models/mysteryGames';

export const useNftRoyaleMyPendingGames = () => {
    const client = useApolloClient();

    const [nftRoyalMyPendingGames, setNftRoyalMyPendingGames] =
        useState<NftRoyalePendingGameOutput[]>();

    const {
        isLoading: nftRoyalMyPendingGamesLoading,
        startLoading: startNftRoyalMyPendingGames,
        stopLoading: stopNftRoyalMyPendingGames,
    } = useLoadingHook();

    const [
        getNftRoyalMyPendingGamesLazy,
        { loading: getNftRoyalMyPendingGamesLazyLoading },
    ] = useLazyQuery<NftRoyalePendingGameOutputResponse>(
        GET_NFT_ROYAL_MY_PENDING_GAMES,
        {
            fetchPolicy: 'no-cache',
        }
    );

    const getNftRoyalMyPendingGames = async (silent?: boolean) => {
        try {
            !silent && startNftRoyalMyPendingGames();
            const result =
                await client.query<NftRoyalePendingGameOutputResponse>({
                    query: GET_NFT_ROYAL_MY_PENDING_GAMES,
                    fetchPolicy: 'no-cache',
                });
            if (result.data) {
                setNftRoyalMyPendingGames(result.data.nftRoyaleMyPendingGames);
            }
            stopNftRoyalMyPendingGames();
        } catch (error) {
            stopNftRoyalMyPendingGames();
            errorsHandler(error, true);
        }
    };

    return {
        getNftRoyalMyPendingGames,
        nftRoyalMyPendingGamesLoading,
        nftRoyalMyPendingGames,
        getNftRoyalMyPendingGamesLazy,
        getNftRoyalMyPendingGamesLazyLoading,
    };
};
