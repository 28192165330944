import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            backgroundColor: Colors[theme].petRankItem.backgroundDefault,
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].petRankItem.borderColorDefault,
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            marginBottom: verticalScale(4),
        },
        shadow: {
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].petRankItem.shadowColorDefault,
            marginBottom: verticalScale(16),
        },
        disabled: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            backgroundColor: Colors[theme].background,
            opacity: 0.7,
        },
        selectedShadow: {
            backgroundColor: Colors[theme].petRankItem.shadowColorSelected,
        },
        selected: {
            backgroundColor: Colors[theme].petRankItem.backgroundSelected,
            borderColor: Colors[theme].petRankItem.borderColorSelected,
            shadowColor: Colors[theme].petRankItem.shadowColorSelected,
        },
        wrapper: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        textWrapper: {
            marginLeft: scale(12),
            flex: 1,
        },
        title: {
            marginBottom: verticalScale(8),
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
            textTransform: 'capitalize',
        },
        infoTitle: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '600',
            }),
        },
        infoAmount: {
            color: Colors[theme].text.primary,
            ...font({
                size: 13,
                height: 18,
                weight: '600',
            }),
            marginLeft: scale(4),
        },
        infoBlock: {
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: verticalScale(8),
        },
        infoWrapper: {},
        infoIcon: {
            marginRight: scale(4),
        },
        textDesc: {
            flex: 1,
            color: Colors[theme].text.secondary,
            ...font({
                size: 12,
                height: 16,
                weight: '600',
            }),
        },
        mintPriceContainer: {
            position: 'absolute',
            top: verticalScale(-4),
            right: 0,
            flexDirection: 'row',
            borderRadius: 50,
        },
        rankLocked: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
        },
        opacityBlock: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: Colors[theme].background,
            opacity: 0.6,
            borderRadius: moderateScale(12),
        },
    });

export default styles;
