import React, { useContext } from 'react';
import { StyleProp } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';

import { Charity } from '../../../../../Data/Models';
import CacheImage from '../../../../../components/CacheImage';
import CurrentLabel from '../../../../../components/CurrentLabel';
import GestureRootView from '../../../../../components/GestureRootView';
import CoinIcon from '../../../../../components/Icons/CoinIcon';
import { Text, View, ViewProps } from '../../../../../components/Themed';
import { useUser } from '../../../../../contexts/UserContext';
import { balanceShortFormatterFromWei } from '../../../../../helpers/wallet';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import { useNavigation } from '../../../../../navigation/useNavigation';
import stylesMain from './styles';

interface IProps {
    charity: Charity;
    containerStyle?: StyleProp<ViewProps>;
    disabled?: boolean;
}

const OrganizationCard = ({
    charity,
    containerStyle = {},
    disabled = false,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const { user } = useUser();
    const { name, logoUrl, description, totalDonationsIgupWei, id } = charity;

    const isSelected = user?.charity?.id === id;

    return (
        <GestureRootView>
            <TouchableOpacity
                disabled={disabled}
                activeOpacity={0.8}
                onPress={() => navigation.navigateToDetailedOrganization(id)}
                style={[styles.container, containerStyle]}>
                <View style={styles.borderOrganizationContainer}>
                    <CacheImage
                        source={logoUrl}
                        style={styles.organizationImage}
                    />
                    <View style={styles.cardContentContainer}>
                        {isSelected && (
                            <View style={styles.selectedContainer}>
                                <CurrentLabel
                                    text={i18n.t('charity.current')}
                                />
                            </View>
                        )}
                        <Text style={styles.cardTitleText}>{name}</Text>
                        <Text
                            style={styles.cardDescriptionText}
                            numberOfLines={2}
                            ellipsizeMode="tail">
                            {description}
                        </Text>
                        <View style={styles.organizationDonateContainer}>
                            <CoinIcon slug="IGUP" size={24} />
                            <Text style={styles.donatedText}>
                                {`${balanceShortFormatterFromWei(
                                    totalDonationsIgupWei
                                )} IGUP ${i18n.t('charity.donated')}`}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={styles.shadow} />
            </TouchableOpacity>
        </GestureRootView>
    );
};

export default OrganizationCard;
