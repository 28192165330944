import { StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import { verticalScale } from '../../../../helpers/dimensions';
import font from '../../../../helpers/font';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
        },
        title: {
            marginLeft: verticalScale(8),
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
        },
        titleWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
        },
        divider: {
            backgroundColor: Colors[theme].text.critical,
            height: 1,
        },
        walletBalanceWrapper: {
            marginTop: verticalScale(20),
        },
        historyWrapper: {
            flex: 1,
            marginTop: verticalScale(20),
        },
    });

export default styles;
