import { useCallback, useMemo } from 'react';

import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { errorsHandler } from '@helpers/errors';
import { wait } from '@helpers/helpers';
import { toastErrorUnknown } from '@helpers/toastNotification';
import { useBreedingPetsPairStats } from '@hooks/useBreedingPetsPairStats';
import { useLoadingHook } from '@hooks/useLoadingHook';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

const useNftEvolutionSelectPetsScreen = () => {
    const navigation = useNavigation();

    const {
        nftEvolutionFirstPet,
        nftEvolutionSecondPet,
        breedingRanksStats,
        setBreedingPetsPairStats,
    } = useMysteryGames();

    const { getBreedingPetsPairStats, breedingPetsPairStatsLoading } =
        useBreedingPetsPairStats();

    const onInfoPress = () => {
        navigation.navigate(ROUTES.HOW_NFT_EVOLUTION_WELCOME);
    };

    const isButtonCanShow = useMemo(
        () => !!nftEvolutionFirstPet && !!nftEvolutionSecondPet,
        [nftEvolutionFirstPet, nftEvolutionSecondPet]
    );

    const onCalculatePress = useCallback(async () => {
        if (nftEvolutionFirstPet?.id && nftEvolutionSecondPet?.id) {
            try {
                const result = await getBreedingPetsPairStats({
                    variables: {
                        pet1Id: nftEvolutionFirstPet.id,
                        pet2Id: nftEvolutionSecondPet.id,
                    },
                });
                if (result.data) {
                    setBreedingPetsPairStats(result.data.breedingPetsPairStats);
                    navigation.navigate(ROUTES.NFT_EVOLUTION_CHECKOUT);
                }
            } catch (error) {
                errorsHandler(error, true);
            }
            return;
        }
        toastErrorUnknown();
    }, [nftEvolutionFirstPet, nftEvolutionSecondPet]);
    return {
        onInfoPress,
        isButtonCanShow,
        onCalculatePress,
        breedingPetsPairStatsLoading,
        breedingRanksStats,
    };
};

export default useNftEvolutionSelectPetsScreen;
