import { useCallback } from 'react';
import { Vibration } from 'react-native';

import { useAccessibilitySettings } from '@contexts/AccessibilitySettingsContext';

export const useVibration = () => {
    const { isHapticDisabled } = useAccessibilitySettings();

    const vibrate = useCallback(() => {
        if (isHapticDisabled) {
            return;
        }
        Vibration.vibrate();
    }, [isHapticDisabled]);

    return { vibrate };
};
