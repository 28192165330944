import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';

import { Text, View } from '@components/Themed';
import { statisticsFormatter } from '@helpers/helpers';
import useThemedStyles from '@hooks/useThemedStyles';

import stylesMain from './styles';

interface IProps {
    title: string;
    amount: number | string;
    icon: React.ReactElement<SvgProps>;
    containerStyle?: StyleProp<ViewStyle>;
}

const TotalCard = ({ title, amount, icon, containerStyle }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={[styles.container, containerStyle]}>
            <View style={styles.imageWrapper}>{icon}</View>
            <Text style={styles.amount}>
                {typeof amount === 'string'
                    ? amount
                    : statisticsFormatter(Number(amount))}
            </Text>
            <Text style={styles.title}>{title}</Text>
        </View>
    );
};
export default TotalCard;
