import * as React from 'react';
import Animated, {
    Easing,
    useAnimatedStyle,
    useSharedValue,
    withTiming,
} from 'react-native-reanimated';

import { isWeb } from '@helpers/app';

import useThemedStyles from '../../../hooks/useThemedStyles';
import { View } from '../../Themed';
import stylesMain from './styles';

interface IProps {
    lastChild: boolean;
    stageFill: string;
    isDynamicStageFill: boolean;
    dynamicStageFill?: string;
}

const StepIndicator = ({
    lastChild,
    stageFill,
    isDynamicStageFill,
    dynamicStageFill,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const widthEnd =
        isDynamicStageFill && dynamicStageFill ? dynamicStageFill : stageFill;

    const width = useSharedValue(isDynamicStageFill ? '0%' : widthEnd);

    React.useEffect(() => {
        width.value = widthEnd;
    }, [isDynamicStageFill, dynamicStageFill]);

    const animatedStyles = useAnimatedStyle(() => {
        return {
            width: isWeb
                ? width?.value
                : withTiming(width?.value, {
                      duration: isDynamicStageFill ? 500 : 0,
                      easing: Easing.bezier(0.25, 0.1, 0.25, 1),
                  }),
        };
    });

    return (
        <View style={[styles.dash, lastChild && styles.lastChild]}>
            <Animated.View
                style={[styles.dashFill, animatedStyles]}></Animated.View>
        </View>
    );
};
export default StepIndicator;
