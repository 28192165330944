import React, { useCallback } from 'react';

import CustomModal from '@components/CustomModal';
import ImageIguana from '@components/ImageIguana';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';

const usePreviousGame = (
    firstButtonAction: () => void,
    secondButtonAction: () => void,
    days?: number
) => {
    const {
        isVisible,
        open: openPreviousGameModal,
        close: closePreviousGameModal,
    } = useVisibleHook();

    const renderPreviousGameModal = useCallback(() => {
        return (
            <CustomModal
                isVisible={isVisible}
                icon={<ImageIguana type="attention" />}
                titleText={i18n.t(`HomeScreen.PreviousGameModal.title`)}
                infoText={i18n.t(`HomeScreen.PreviousGameModal.text`, {
                    days,
                })}
                firstButtonText={i18n.t(`HomeScreen.PreviousGameModal.button1`)}
                secondButtonText={i18n.t(`general.cancel`)}
                onFirstButtonClick={firstButtonAction}
                onSecondButtonClick={secondButtonAction}
            />
        );
    }, [isVisible, days]);

    return {
        openPreviousGameModal,
        closePreviousGameModal,
        renderPreviousGameModal,
    };
};

export default usePreviousGame;
