import * as React from 'react';

import { MainPetUserOutputReferral } from '@Data/Models';
import OtherUserAvatar from '@components/OtherUserAvatar';
import { Text, View } from '@components/Themed';
import { scale } from '@helpers/dimensions';
import { truncateEthAddress } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import ReferralExtendedInfo from '../ReferralExtendedInfo';
import stylesMain from './styles';

interface IProps {
    user: MainPetUserOutputReferral;
}

const MyReferrer = ({ user }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={styles.container}>
            <View style={styles.left}>
                <View style={styles.imageWrapper}>
                    <OtherUserAvatar
                        size={scale(40)}
                        imageUrl={user.mainPet?.image?.thumbnailUrl}
                        userId={user.walletAddress}
                    />
                </View>
                <View style={styles.textWrapper}>
                    <Text
                        style={styles.address}
                        ellipsizeMode="middle"
                        numberOfLines={1}>
                        {truncateEthAddress(
                            user.username || user.walletAddress
                        )}
                    </Text>
                    <Text style={styles.text}>
                        {i18n.t('referrals.myReferrer')}
                    </Text>
                </View>
            </View>
            <ReferralExtendedInfo withDivider user={user} />
        </View>
    );
};
export default MyReferrer;
