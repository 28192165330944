import { StyleSheet } from 'react-native';

import Colors from '../../../constants/Colors';
import { MAX_WINDOW_WIDTH } from '../../../contexts/DimensionsContext';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        outerContainer: {
            flex: 1,
            alignItems: 'center',
            overflow: 'hidden',
            backgroundColor: Colors[theme].backgroundOuter,
            padding: 0,
        },
        innerContainer: {
            flex: 1,
            width: '100%',
        },
        innerContainerBoundary: {
            maxWidth: MAX_WINDOW_WIDTH,
        },
    });

export default styles;
