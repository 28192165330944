import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { scale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        wrapper: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: 'transparent',
        },
        container: {
            flex: 1,
            borderRadius: 1000,
            height: 12,
            backgroundColor: Colors[theme].levelIndicatorBackGround,
            marginRight: scale(6),
        },
        health: {
            textTransform: 'uppercase',
        },
        healthCurrent: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '600',
            }),
        },
        healthMax: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '600',
            }),
        },
        noMaxHealth: {
            color: Colors[theme].text.danger,
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),
        },
        fill: {
            borderTopRightRadius: 1000,
            borderTopLeftRadius: 1000,
            borderBottomLeftRadius: 1000,
            borderBottomRightRadius: 1000,
            position: 'absolute',
            left: 0,
            top: 0,
            height: 12,
            backgroundColor: Colors[theme].healthIndicatorFillColor,
        },
        lowHealth: {
            backgroundColor: Colors[theme].text.critical,
        },
        badgeText: {
            color: Colors[theme].text.critical,
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),
            marginLeft: scale(4),
        },
        badgeWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    });

export default styles;
