import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(20),
            marginTop: verticalScale(32),
        },
        alert: {
            marginHorizontal: scale(20),
        },
        accent: {
            color: Colors[theme].text.accent,
        },
        link: {
            marginTop: verticalScale(20),
        },
    });

export default styles;
