import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import ActivityIndicator from '@components/ActivityIndicator';
import { Text, View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';

import Icon, { ICON_NAMES } from '../Icons';
import stylesMain from './styles';

interface IProps {
    label?: string;
    placeHolder?: string;
    onPress: () => void;
    error?: string;
    children?: React.ReactNode;
    selectedItem: string | undefined;
    modal: React.ReactNode;
    loading?: boolean;
}

const SelectorV2 = ({
    label,
    placeHolder,
    onPress,
    error,
    selectedItem,
    children,
    modal,
    loading,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    return (
        <View style={styles.container}>
            {!!label && <Text style={styles.label}>{label}</Text>}
            <TouchableOpacity
                disabled={loading}
                onPress={onPress}
                style={[styles.selectContainer, !!error && styles.errorBorder]}
                activeOpacity={0.8}>
                {loading && (
                    <View style={styles.loading}>
                        <ActivityIndicator loading={loading} />
                    </View>
                )}
                <View style={styles.left}>
                    {!!selectedItem
                        ? children || (
                              <Text style={styles.item}>{selectedItem}</Text>
                          )
                        : !!placeHolder && (
                              <Text style={styles.placeHolder}>
                                  {placeHolder}
                              </Text>
                          )}
                </View>
                <Icon
                    name={ICON_NAMES.ARROW_DROP_DOWN}
                    size={20}
                    color={styles.dropDownColor.color}
                />
            </TouchableOpacity>
            {!!error && <Text style={styles.error}>{error}</Text>}
            {modal}
        </View>
    );
};
export default SelectorV2;
