import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import { scale, verticalScale } from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        row: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: verticalScale(24),
            backgroundColor: Colors[theme].background,
        },
        powerByText: {
            ...font({
                size: 14,
                weight: '400',
            }),
            color: Colors[theme].text.secondary,
            paddingRight: scale(8),
        },
        style: {
            backgroundColor: Colors[theme].background,
            color: Colors[theme].background,
            borderColor: Colors[theme].background,
        },
    });

export default styles;
