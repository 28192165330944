import { useCallback, useEffect, useLayoutEffect, useMemo } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { StakingPlanOutput } from '@Data/Models';
import { useKeyboard } from '@contexts/KeyboardContext';
import { MIN_STACK_VALUE, useStaking } from '@contexts/StakingContext';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { getCurrentPlan } from '@helpers/staking';
import { toastErrorUnknown } from '@helpers/toastNotification';
import {
    balanceFromWei,
    inputNumberFormatter,
    isBiggerThanMax,
} from '@helpers/wallet';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import { useFlowIndicator } from './useFlowIndicator';
import { useHideSummary } from './useHideSummary';

export const useStakingCoinsInputScreen = () => {
    const navigation = useNavigation();

    const { isKeyboardVisible } = useKeyboard();

    const {
        currentPlan,
        getStakingPlanRewardBoost,
        calculateBoostLoading,
        stakingCoin,
        currentStakingPlan,
        setCurrentPlan,
        stakingPlans,
        isAddMoreCoins,
    } = useStaking();

    const { setStageFill, stageFill, indicatorFlow, indicatorStage } =
        useFlowIndicator(isAddMoreCoins);

    const { handleInputFocus, showSummary, isHideSummary } = useHideSummary();

    const { reloadBalance, walletBalance, balanceReloading } = useWallet();

    useLayoutEffect(() => {
        if (currentStakingPlan && stakingPlans && !currentPlan) {
            setCurrentPlan(
                getCurrentPlan(currentStakingPlan.stakingPlanId, stakingPlans)
            );
        }
    }, [currentStakingPlan, stakingPlans, currentPlan]);

    useEffect(() => {
        reloadBalance(true);
    }, [walletBalance]);

    const onRefresh = useCallback(() => {
        reloadBalance(false);
        setTouched({ amount: false });
    }, []);

    const coinWallet = useMemo(
        () =>
            stakingCoin === Coin.igup
                ? walletBalance?.igup
                : walletBalance?.igu,
        [stakingCoin, walletBalance]
    );

    const igupSchema = Yup.object({
        amount: Yup.string()
            .transform((_, value) => {
                if (value && value.includes('.')) {
                    return value;
                }
                return value && value.replace(/,/, '.');
            })
            .matches(
                /^\d*(\.\d+)?$/,
                i18n.t('staking.coinsInputScreen.errors.digits')
            )
            .required(i18n.t('withdrawScreen.errors.amountRequired'))
            .test(
                'maxAmount',
                i18n.t('staking.coinsInputScreen.errors.max'),
                (value) => Number(value) <= Number(coinWallet?.value)
            )
            .test(
                'minAmount',
                i18n.t('staking.coinsInputScreen.errors.min', {
                    limit: MIN_STACK_VALUE,
                    coin: stakingCoin,
                }),
                (value) => Number(value) >= Number(MIN_STACK_VALUE)
            ),
    });

    const goToBoostScreen = (
        currentPlan: StakingPlanOutput | undefined,
        amount = '0'
    ) => {
        setStageFill('100%');
        if (!currentPlan) {
            toastErrorUnknown();
            return;
        }

        getStakingPlanRewardBoost(currentPlan.plan.id, amount, () =>
            navigation.push(ROUTES.STAKING_DAILY_BOOST)
        );
    };

    const {
        submitForm,
        values,
        errors,
        setFieldValue,
        setTouched,
        touched,
        isValid,
        dirty,
    } = useFormik({
        initialValues: {
            amount: '',
        },
        validationSchema: igupSchema,
        onSubmit: ({ amount }) => {
            goToBoostScreen(currentPlan, amount);
        },
        enableReinitialize: true,
    });

    const onSkipPress = useCallback(() => {
        setStageFill('100%');
        goToBoostScreen(currentPlan);
    }, [currentPlan]);

    const handleChangeAmount = async (amount: string) => {
        setTouched({ amount: false });

        const convertedText = inputNumberFormatter(amount);
        if (!isBiggerThanMax(convertedText)) {
            await setFieldValue('amount', convertedText);
        }
    };

    const handleClickAll = useCallback(async () => {
        await setFieldValue('amount', coinWallet?.presentationValueLong);
    }, [coinWallet]);

    const handleClickMaxBoost = useCallback(async () => {
        await setFieldValue(
            'amount',
            balanceFromWei(currentPlan?.additionalIgupAmountWeiStake).valueLong
        );
    }, [currentPlan]);

    useEffect(() => {
        if (!isKeyboardVisible) {
            showSummary();
        }
    }, [isKeyboardVisible]);

    const handleOnBlur = () => {
        setTouched({ amount: true });
    };

    const {
        isVisible: depositModal,
        open: openDepositModal,
        close: closeDepositModal,
    } = useVisibleHook();

    const enoughFunds = useMemo(
        () => Number(values.amount) <= Number(coinWallet?.value),
        [values, coinWallet?.value]
    );

    return {
        currentPlan,
        getStakingPlanRewardBoost,
        calculateBoostLoading,
        stakingCoin,
        currentStakingPlan,
        setCurrentPlan,
        stakingPlans,
        isAddMoreCoins,
        onRefresh,
        submitForm,
        values,
        errors,
        setFieldValue,
        setTouched,
        touched,
        isValid,
        dirty,
        onSkipPress,
        handleChangeAmount,
        handleClickAll,
        handleClickMaxBoost,
        coinWallet,
        walletBalance,
        balanceReloading,
        stageFill,
        indicatorFlow,
        indicatorStage,
        handleInputFocus,
        handleOnBlur,
        isHideSummary,
        depositModal,
        openDepositModal,
        closeDepositModal,
        enoughFunds,
    };
};
