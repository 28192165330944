import React, { useMemo } from 'react';

import { useDimensions } from '@contexts/DimensionsContext';
import { isDesktop } from '@helpers/app';

import { View } from '../../../components/Themed';
import useThemedStyles from '../../../hooks/useThemedStyles';
import ScreenOrientationManager from '../../../utils/ScreenOrientationManager';
import stylesMain from './styles';
import IProps from './types';

export default function WebContainer({
    isVisible,
    children,
    isRotated,
}: IProps) {
    const styles = useThemedStyles(stylesMain);
    const { windowHeight, windowWidth } = useDimensions();

    const rotatedStyles = useMemo(() => {
        if (isRotated)
            if (windowHeight < windowWidth) {
                ScreenOrientationManager.setDefault();
                return {
                    height: isDesktop ? '100%' : windowHeight,
                    width: isDesktop ? '100%' : windowWidth,
                };
            } else {
                ScreenOrientationManager.setLandscape();
                return {
                    height: isDesktop ? '100%' : windowWidth,
                    width: isDesktop ? '100%' : windowHeight,
                    left: !isDesktop
                        ? windowWidth / 2 - windowHeight / 2
                        : undefined,
                    top: !isDesktop
                        ? windowHeight / 2 - windowWidth / 2
                        : undefined,
                };
            }

        return styles.outerContainer;
    }, [isRotated, windowHeight, windowWidth, styles.outerContainer]);

    return (
        <View style={rotatedStyles}>
            <View
                style={[
                    styles.innerContainer,
                    isVisible && styles.innerContainerBoundary,
                ]}>
                {children}
            </View>
        </View>
    );
}
