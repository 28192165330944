import { StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import { isWeb } from '../../../../helpers/app';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../../helpers/dimensions';
import font from '../../../../helpers/font';
import { stylesModalNavigationFix } from '../../../../helpers/style';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
            paddingBottom: isWeb ? verticalScale(20) : 0,
            ...stylesModalNavigationFix(),
        },
        container: {
            flex: 1,
        },
        list: {
            flex: 1,
            paddingHorizontal: scale(20),
        },
        inputWrapper: {
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(20),
            marginTop: verticalScale(20),
        },
        label: {
            textAlign: 'center',
            marginTop: verticalScale(8),
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
            marginBottom: verticalScale(4),
        },
        error: {
            color: Colors[theme].text.danger,
        },
        inputContainer: {
            textAlign: 'center',
            ...font({
                size: 22,

                weight: '700',
            }),
            marginLeft: scale(8),
            minWidth: 45,
            color: Colors[theme].text.primary,
        },
        buttonZone: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
        },
        header: {
            paddingHorizontal: scale(20),
        },

        buttonContainer: {
            marginBottom: verticalScale(24),
        },
    });

export default styles;
