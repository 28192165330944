import * as React from 'react';
import { ScrollView } from 'react-native';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import FlowStepIndicator, {
    STEP_INDICATOR_FLOWS,
} from '@components/FlowStepIndicator';
import { MARKETPLACE_CREATE_ORDER_NAME } from '@components/FlowStepIndicator/flows/marketplaceCreateOrder';
import LoadingComponent from '@components/LoadingComponent';
import { View } from '@components/Themed';
import { toastErrorUnknown } from '@helpers/toastNotification';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MarketplaceSaleablePetOutput } from '@models/marketplace';
import NavigationBar from '@navigation/NavigationBar';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import MarketPlaceSellablePetCard from '../components/MarketPlaceSellablePetCard';
import { useMarketplaceSaleablePets } from '../hooks/useMarketplaceSaleablePets';
import stylesMain from './styles';

const SCREEN = 'MarketPlaceSelectNftScreen';

const MarketPlaceSelectNftScreen = () => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const {
        getMarketplaceSaleablePets,
        loadingMarketplaceSaleablePets,
        dataMarketplaceSaleablePets,
    } = useMarketplaceSaleablePets();

    React.useEffect(() => {
        getMarketplaceSaleablePets();
    }, []);

    const [selectedPet, setSelectedPet] =
        React.useState<MarketplaceSaleablePetOutput>();
    const [stageFill, setStageFill] = React.useState<string>('0%');

    const onPetPress = React.useCallback(
        (pet: MarketplaceSaleablePetOutput) => {
            if (pet.id === selectedPet?.id) {
                return;
            }
            setStageFill('100%');
            setSelectedPet(pet);
        },
        [selectedPet]
    );

    const handleNavigate = React.useCallback(() => {
        if (!selectedPet) {
            return toastErrorUnknown();
        }
        navigation.navigate(ROUTES.MARKETPLACE_SELECT_NFT_PRICE, {
            pet: selectedPet,
        });
    }, [selectedPet]);

    return (
        <View style={styles.main}>
            <NavigationBar
                filled={false}
                backIcon="leftArrow"
                title={i18n.t(`MarketPlace.${SCREEN}.screenTitle`)}
            />
            {loadingMarketplaceSaleablePets ? (
                <LoadingComponent containerStyles={styles.loading} />
            ) : (
                <>
                    <ScrollView
                        showsVerticalScrollIndicator={false}
                        style={styles.content}>
                        <FlowStepIndicator
                            flow={STEP_INDICATOR_FLOWS.MARKETPLACE_CREATE_ORDER}
                            stage={MARKETPLACE_CREATE_ORDER_NAME.STEP_ONE}
                            stageFill={stageFill}
                            containerStyle={styles.flowIndicator}
                        />

                        <View style={styles.petsBlock}>
                            {dataMarketplaceSaleablePets?.marketplaceSaleablePets.map(
                                (item) => (
                                    <MarketPlaceSellablePetCard
                                        isSelected={item.id === selectedPet?.id}
                                        key={item.id}
                                        pet={item}
                                        onPress={onPetPress}
                                    />
                                )
                            )}
                        </View>
                    </ScrollView>
                    <ButtonsBottom
                        disabled={!selectedPet}
                        title={i18n.t('general.BUTTONS.continue')}
                        onPress={handleNavigate}
                        safe
                    />
                </>
            )}
        </View>
    );
};

export default MarketPlaceSelectNftScreen;
