import { useCallback, useState } from 'react';

import { useMysteryGames } from '@contexts/MysteryGamesContext';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { useFocusEffect } from '@react-navigation/native';

const useQuantumSpinMainScreen = () => {
    const navigation = useNavigation();
    navigation.blockHardwareBack();

    const {
        getGamingAvailableBalance,
        quantumSpinGameConfig,
        quantumSpinGamePrizes,
    } = useMysteryGames();

    const [wheelVisible, setWheelVisible] = useState<boolean>(false);

    const [isWheelAnimation, setIsWheelAnimation] = useState<boolean>(false);

    useFocusEffect(
        useCallback(() => {
            setWheelVisible(true);
            getGamingAvailableBalance(true);
            return () => setWheelVisible(false);
        }, [])
    );

    const onHowItWorksPress = () => {
        navigation.push(ROUTES.HOW_QUANTUM_SPIN_WORK);
    };

    return {
        onHowItWorksPress,
        wheelVisible,
        setIsWheelAnimation,
        isWheelAnimation,
        quantumSpinGameConfig,
        quantumSpinGamePrizes,
    };
};

export default useQuantumSpinMainScreen;
