import { useCallback, useState } from 'react';

import { useDimensions } from '@contexts/DimensionsContext';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { errorsHandler } from '@helpers/errors';
import { useGetMysteryLikeGameHistory } from '@hooks/useGetMysteryLikeGameHistory';
import useMysteryLikesGamesHistory from '@hooks/useMysteryLikesGamesHistory';
import {
    MYSTERY_LIKES_STATUS,
    PaginatedMysteryLikesGamesHistoryItem,
} from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { useFocusEffect } from '@react-navigation/native';

import useCreateMysteryLikesGame from '../../../hooks/useCreateMysteryLikesGame';

const useMysteryLikesHistoryScreen = () => {
    const { setCurrentMysteryLikesGameHistory, setCurrentMysteryLikesGameId } =
        useMysteryGames();

    const { isSmallLayout } = useDimensions();

    const navigation = useNavigation();

    const handleRefresh = async () => {
        await getGamesHistory(false);
    };

    const { renderPreviousGameModal, handleCreateGame, createGameLoading } =
        useCreateMysteryLikesGame();

    const { availableBalance } = useMysteryGames();

    const {
        gamesHistory,
        getGamesHistory,
        historyLoading,
        loadMore,
        getGamesHistoryLoadMore,
        refreshLoading,
        canLoadMore,
    } = useMysteryLikesGamesHistory();

    const { getMysteryLikesGameHistory, getMysteryLikesGameHistoryLoading } =
        useGetMysteryLikeGameHistory();

    const [firstLoad, setFirsLoad] = useState<boolean>(true);

    useFocusEffect(
        useCallback(() => {
            getGamesHistory(firstLoad);
            setFirsLoad(false);
        }, [firstLoad])
    );

    const onHistoryItemPress = async (
        game: PaginatedMysteryLikesGamesHistoryItem
    ) => {
        if (game.status === MYSTERY_LIKES_STATUS.WaitingForMyself) {
            setCurrentMysteryLikesGameId(game.id);
            navigation.navigate(ROUTES.MYSTERY_LIKES_SELECT_RANGE);
            return;
        }
        try {
            const result = await getMysteryLikesGameHistory({
                variables: {
                    gameId: game.id,
                },
            });
            if (result.data?.mysteryLikesGameHistory) {
                setCurrentMysteryLikesGameHistory(
                    result.data.mysteryLikesGameHistory
                );
                navigation.navigate(ROUTES.MYSTERY_LIKES_SELECT_RANGE_FINISH, {
                    fromHistory: true,
                });
            }
        } catch (error) {
            errorsHandler(error, true);
        }
    };

    return {
        isSmallLayout,
        getGamesHistoryLoadMore,
        loadMore,
        createGameLoading,
        gamesHistory,
        historyLoading,
        onHistoryItemPress,
        refreshLoading,
        renderPreviousGameModal,
        handleCreateGame,
        getMysteryLikesGameHistoryLoading,
        handleRefresh,
        availableBalance,
        canLoadMore,
    };
};

export default useMysteryLikesHistoryScreen;
