import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flexDirection: 'row',
            alignContent: 'center',
            alignItems: 'center',
            borderRadius: 50,
            paddingLeft: 4,
            paddingRight: 8,
            paddingVertical: 2,
            alignSelf: 'flex-start',
        },

        amount: {
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),
            marginLeft: 4,
        },
        iguText: {
            color: Colors[theme].marketplace.price.textIGU,
        },
        iguBg: {
            backgroundColor: Colors[theme].marketplace.price.backgroundIGU,
        },
    });

export default styles;
