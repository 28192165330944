import { StyleSheet } from 'react-native';

import font from '../../../../../../helpers/font';
import { theme } from '../../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            overflow: 'hidden',
        },
        titleBlock: {
            marginVertical: 48,
        },
        title: {
            ...font({
                height: 124,
                weight: '900',
            }),
            fontSize: 105,
        },
        content: {
            marginTop: 210,
            marginHorizontal: 72,
        },
        placeForLink: {
            borderStyle: 'dashed',
            borderWidth: 3.58691,
            borderRadius: 17.9346,
            paddingHorizontal: 25,
            paddingVertical: 19,
            alignSelf: 'flex-start',
        },
        link: {
            ...font({
                height: 50,
                weight: '700',
            }),
            fontSize: 39,
        },
        qrCodeWrapper: {
            paddingHorizontal: 42,
            paddingVertical: 28,
            borderRadius: 140,
            alignSelf: 'flex-start',
        },
        qrCode: {
            ...font({
                height: 84,
                weight: '700',
            }),
            fontSize: 63,
            backgroundColor: 'transparent',
            textTransform: 'uppercase',
        },
        imageBlock: {
            position: 'absolute',
            width: 1232,
            aspectRatio: 1,
            top: 1037,
            left: -75,
            borderRadius: 1000,
            zIndex: 1000,
        },
        petImage: {
            borderRadius: 1000,
            width: '100%',
            height: '100%',
        },
        circle1: {
            position: 'absolute',
            width: 1280,
            height: 1280,
            left: 511,
            top: 798,
            zIndex: 999,
            borderRadius: 1000,
        },
        circle2: {
            position: 'absolute',
            width: 1280,
            height: 1280,
            left: -740,
            top: 1057,
            zIndex: 999,
            borderRadius: 1000,
        },
    });

export default styles;
