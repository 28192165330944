import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';
import { stylesWebOutlines } from '@helpers/style';

import { theme } from '../../types';

const stylesMain = (theme: theme) =>
    StyleSheet.create({
        containerButton: {
            backgroundColor: Colors[theme].background,
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(12),
            marginBottom: verticalScale(2),
            alignItems: 'center',
        },

        shadow: {
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].shadow.default,
            ...stylesWebOutlines(),
        },
        selectS: {
            backgroundColor: Colors[theme].shadow.selected,
        },
        selectC: {
            borderColor: Colors[theme].border.accent,
        },
        top: { marginBottom: scale(8), backgroundColor: 'transparent' },

        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),
            textAlign: 'center',
        },

        disabled: {
            opacity: 0.7,
        },
    });

export default stylesMain;
