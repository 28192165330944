import { StyleSheet } from 'react-native';

import { verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        flex: {
            flex: 1,
        },
        gap: {
            marginBottom: verticalScale(16),
        },
    });

export default styles;
