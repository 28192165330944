import {
    PaginationMeta,
    PaymentOptionOutput,
    SignedTransactionOutput,
} from '@Data/Models';

export enum MYSTERY_GAMES {
    mysteryLikes = 'MysteriousLikes',
    nftRoyal = 'NftRoyale',
    nftEvolution = 'Breeding',
    luckyStrike = 'LuckyStrike',
    quantumSpin = 'QuantumSpin',
    tokenteller = 'Tokenteller',
}

export enum ACTIVE_ORDER_LIST {
    Newest = 'Newest',
    Oldest = 'Oldest',
    Highest = 'Highest',
    Lowest = 'Lowest',
}

export const activeOrderFilterConfig: {
    defaultFilter: ACTIVE_ORDER_LIST;
    filters: ACTIVE_ORDER_LIST[];
} = {
    defaultFilter: ACTIVE_ORDER_LIST.Newest,
    filters: [
        ACTIVE_ORDER_LIST.Newest,
        ACTIVE_ORDER_LIST.Oldest,
        ACTIVE_ORDER_LIST.Highest,
        ACTIVE_ORDER_LIST.Lowest,
    ],
};

export interface IAllGamesData {
    id: MYSTERY_GAMES;
    color: string;
}

export type MysteryLikesGameStats = {
    activeGamesCount: number;
    minimalBetIguAmountWei: string;
    totalPricePoolIguAmountWei: string;
    maxRoundsCount: number;
    gameMaxLifetimeDays: number;
};

export type MysteryLikesGameStatsResponse = {
    mysteryLikesGameStats: MysteryLikesGameStats;
};

export type MysteryLikesGameOpponent = {
    walletAddress: string;
    username: string | null;
    mainPet: {
        name?: string;
        image: {
            thumbnailUrl: string;
        };
    } | null;
};

export type PaginatedMysteryLikesAvailableGamesItem = {
    id: string;
    iguAmountWei: string;
    createdAt: string;
    creator: MysteryLikesGameOpponent;
};

export type PaginatedMysteryLikesAvailableGamesOutput = {
    items: PaginatedMysteryLikesAvailableGamesItem[];
    meta: PaginationMeta;
};
export type PaginatedMysteryLikesAvailableGamesOutputResponse = {
    mysteryLikesAvailableGames: PaginatedMysteryLikesAvailableGamesOutput;
};

export type IguAvailableBalance = {
    available: string;
};
export type IguAvailableBalanceResponse = {
    iguBalance: IguAvailableBalance;
};

export type MysteryLikesCreateGame = {
    id: string;
    iguAmountWei: string;
    roundsCompletedCount: number;
    roundsCount: number;
};

export type MysteryLikesCreateGameResponse = {
    mysteryLikesCreateGame: MysteryLikesCreateGame;
};

export type MysteryLikesGameRoundOutput = {
    id: string;
    petImageUrl: string;
    predefinedChoices: string[];
};

export type MysteryLikesGameRoundOutputResponse = {
    mysteryLikesGameRound: MysteryLikesGameRoundOutput;
};

export type MysteryLikesMyPendingGame = {
    id: string;
    iguAmountWei: string;
    roundsCompletedCount: number;
    roundsCount: number;
};

export type MysteryLikesMyPendingGamesResponse = {
    mysteryLikesMyPendingGames: MysteryLikesMyPendingGame[];
};

export enum MYSTERY_LIKES_STATUS {
    Lost = 'Lost',
    Won = 'Won',
    Draw = 'Draw',
    Refund = 'Refund',
    WaitingForMyself = 'WaitingForMyself',
    WaitingForOpponent = 'WaitingForOpponent',
}

export type MysteryLikesGameHistoryOutput = {
    index: number;
    petImageUrl: string;
    choice: string;
    correctChoice: boolean;
};

export type MysteryLikesGameHistory = {
    id: string;
    opponent: MysteryLikesGameOpponent | null;
    iguAmountWei: string;
    status: MYSTERY_LIKES_STATUS;
    rounds: MysteryLikesGameHistoryOutput[];
    opponentRounds: MysteryLikesGameHistoryOutput[];
    createdAt: string;
};

export type MysteryLikesGameHistoryResponse = {
    mysteryLikesGameHistory: MysteryLikesGameHistory;
};

export type mysteryLikesJoinGameResponse = {
    mysteryLikesJoinGame: boolean;
};

export type PaginatedMysteryLikesGamesHistoryItem = {
    id: string;
    opponent: MysteryLikesGameOpponent | null;
    iguAmountWei: string;
    status: MYSTERY_LIKES_STATUS;
    createdAt: string;
};

export type PaginatedMysteryLikesGamesHistoryResponse = {
    mysteryLikesGamesHistory: {
        items: PaginatedMysteryLikesGamesHistoryItem[];
        meta: PaginationMeta;
    };
};

export type GameOutput = {
    description: string;
    enabled: boolean;
    name: string;
    playersCount: number;
    type: MYSTERY_GAMES;
};

export type GameOutputResponse = {
    games: GameOutput[];
};

export type BreedingRanksStatsOutput = {
    payment: PaymentOptionOutput;
    rank1Id: string;
    rank2Id: string;
    winChance: string;
    winRankId: string;
};

export type BreedingRanksStatsOutputResponse = {
    breedingRanksStats: BreedingRanksStatsOutput[] | null;
};

export type BreedingPetParentOutput = {
    id: string;
    name: string;
    level: number;
    rank: string;
    imageUrl: string;
    healthPercentage: number;
    ageDays: number;
    maxAgeDays: number;
    levelProgressPercentage: number;
};

export type BreedingPetParentOutputResponse = {
    breedingPets: BreedingPetParentOutput[] | null;
};

export type BreedingPetsPairStatsOutputChances = {
    rankId: string;
    winChance: string;
};

export type BreedingPetsPairStatsOutput = {
    viguAmountWei: string;
    chances: BreedingPetsPairStatsOutputChances[];
};

export type BreedingPetsPairStatsOutputResponse = {
    breedingPetsPairStats: BreedingPetsPairStatsOutput | null;
};

export type BreedingPetDraftSelectableImage = {
    id: string;
    url: string;
};

export type BreedingPetDraftDetailsOutput = {
    id: string;
    name: string;
    rank: string;
    parent1: BreedingPetParentOutput;
    parent2: BreedingPetParentOutput;
    selectedImageId: string | null;
    selectableImages: BreedingPetDraftSelectableImage[] | [];
    imageUrl: string | null;
    editable: boolean;
    mintable: boolean;
};

export type BreedingPetDraftDetailsOutputResponse = {
    breedingBreedPets: BreedingPetDraftDetailsOutput | null;
};

export type BreedingPetDraftOutput = {
    id: string;
    rank: string;
};
export type BreedingPetDraftOutputResponse = {
    breedingPetDrafts: BreedingPetDraftOutput[];
};

export type BreedingPetDraftDetails = {
    breedingPetDraftDetails: BreedingPetDraftDetailsOutput | null;
};

export type BreedingPetDraftUpdate = {
    breedingPetDraftUpdate: boolean;
};
export type BreedingMintPetDraft = {
    petId: string;
    signedTransaction: SignedTransactionOutput;
    payment: PaymentOptionOutput;
};

export type BreedingMintPetDraftResponse = {
    breedingMintPetDraft: BreedingMintPetDraft;
};

export enum MysteryGamesStatus {
    Won = 'Won',
    Lost = 'Lost',
}

export type MysteryLikesGameLastGameOutput = {
    petImageUrl: string;
    iguAmountWei: string;
    status: MysteryGamesStatus;
};
export type MysteryLikesGameLastGameOutputResponse = {
    mysteryLikesGameLastGame: MysteryLikesGameLastGameOutput | null;
};

export enum GAME_DIFFICULTY {
    Hard = 'Hard',
    Medium = 'Medium',
    Easy = 'Easy',
}
export enum NFT_ROYAL_GAME_MODE {
    Score = 'Score',
    Fruit = 'Fruits',
}

export type NftRoyalGameConfigurations = {
    mode: NFT_ROYAL_GAME_MODE;
    complexity: GAME_DIFFICULTY;
    livesCount: number;
};

export type NftRoyalGameLives = {
    count: number;
    imageUrl: string;
};

export type NftRoyaleGameStatsOutput = {
    minBetIguAmountWei: string;
    maxBetIguAmountWei: string;
    inactiveGameTerminationDays: number;
    rewardUnlockDaysCount: number;
    gameModes: NFT_ROYAL_GAME_MODE[];
    gameComplexities: GAME_DIFFICULTY[];
    gameLives: NftRoyalGameLives[];
    activeGamesCount: number;
    totalPricePoolIguAmountWei: string;
};

export type NftRoyaleGameStatsOutputResponse = {
    nftRoyaleGameConfig: NftRoyaleGameStatsOutput;
};

export type NftRoyaleAvailableGameOutput = {
    id: string;
    iguAmountWei: string;
    creatorUsername: string;
    creatorAvatarUrl: string | null;
    mode: NFT_ROYAL_GAME_MODE;
    complexity: GAME_DIFFICULTY;
    livesCount: number;
    createdAt: string;
};

export type PaginatedNftRoyaleAvailableGamesOutput = {
    items: NftRoyaleAvailableGameOutput[];
    meta: PaginationMeta;
};
export type PaginatedNftRoyaleAvailableGamesOutputResponse = {
    nftRoyaleAvailableGames: PaginatedNftRoyaleAvailableGamesOutput;
};

export type NftRoyaleGameplayConfigOutput = {
    scorePerFruit: number;
    scorePerMeter: number;
    maxLives: number;
    difficulty: number;
    petAcceleration: number;
    petJumpHeight: number;
    petJumpDuration: number;
    petMoveSpeedAtMinDifficult: number;
    petMoveSpeedAtMaxDifficult: number;
    petInvulnerabilityDuration: number;
    obstacleMinGapDistanceAtMinDifficult: number;
    obstacleMaxGapDistanceAtMinDifficult: number;
    obstacleMinGapDistanceAtMaxDifficult: number;
    obstacleMaxGapDistanceAtMaxDifficult: number;
    fruitMinGapDistanceAtMinDifficult: number;
    fruitMaxGapDistanceAtMinDifficult: number;
    fruitMinGapDistanceAtMaxDifficult: number;
    fruitMaxGapDistanceAtMaxDifficult: number;
};

export type NftRoyalePendingGameOutput = {
    id: string;
    mode: NFT_ROYAL_GAME_MODE;
    complexity: GAME_DIFFICULTY;
    livesCount: number;
    gameplayConfig: NftRoyaleGameplayConfigOutput;
    gameValue?: string;
};

export type NftRoyalCreateGameResponse = {
    nftRoyaleGameCreate: NftRoyalePendingGameOutput;
};

export type NftRoyalePendingGameOutputResponse = {
    nftRoyaleMyPendingGames: NftRoyalePendingGameOutput[];
};

export enum NFT_ROYAL_GAME_HISTORY_STATUS {
    Lost = 'Lost',
    Won = 'Won',
    Draw = 'Draw',
    Refund = 'Refund',
    WaitingForMyself = 'WaitingForMyself',
    WaitingForOpponent = 'WaitingForOpponent',
}

export type PaginatedNftRoyaleGamesHistoryRowOutput = {
    id: string;
    opponentUsername: string;
    opponentAvatarUrl: string | null;
    iguAmountWei: string;
    status: NFT_ROYAL_GAME_HISTORY_STATUS;
    createdAt: string;
};

export type PaginatedNftRoyaleGamesHistoryOutputResponse = {
    nftRoyaleGamesHistory: {
        items: PaginatedNftRoyaleGamesHistoryRowOutput[];
        meta: PaginationMeta;
    };
};

export type NftRoyaleGameHistoryOutput = {
    id: string;
    opponentUsername: string | null;
    opponentAvatarUrl: string | null;
    iguAmountWei: string;
    status: NFT_ROYAL_GAME_HISTORY_STATUS;
    myScore: number | null;
    myFruitsCount: number | null;
    myLivesCount: number | null;
    opponentScore: number | null;
    opponentFruitsCount: number | null;
    opponentLivesCount: number | null;
    createdAt: number | null;
    mode: NFT_ROYAL_GAME_MODE;
    complexity: GAME_DIFFICULTY;
    livesCount: number;
};

export type NftRoyaleGameHistoryOutputResponse = {
    nftRoyaleGameHistory: NftRoyaleGameHistoryOutput;
};

export type nftRoyalJoinGameResponse = {
    nftRoyaleJoinGame: NftRoyalePendingGameOutput;
};

export enum NFT_ROYAL_GAME_EVENT {
    Start = 'start',
    CactusCollided = 'obstacle-collided',
    CactusAvoided = 'obstacle-avoided',
    FruitCollided = 'fruit-collected',
    FruitAvoided = 'fruit-avoided',
    Finish = 'end',
}

export enum MYSTERY_GAMES_CONTINUE_BANNER_RENDER_TYPE {
    MLG = 'MLG',
    ROYAL = 'ROYAL',
    BOTH = 'BOTH',
}

export type LuckyStrikeAvailableGameTexts = {
    title: string;
    text: string;
};

export type LuckyStrikeAvailableGameStyles = {
    backgroundColor: string;
    imageUrl: string;
};

export type LuckyStrikeAvailableGameOutput = {
    id: string;
    iguAmountWei: string;
    duration: string;
    userCount: number;
    ticketCount: number;
    createdAt: string;
    texts: LuckyStrikeAvailableGameTexts;
    styles: LuckyStrikeAvailableGameStyles;
    ticketsLeftCount: number;
    endDate: string;
    boughtTickets?: number;
};

export type LuckyStrikeAvailableGameOutputResponse = {
    luckyStrikeAvailableGames: LuckyStrikeAvailableGameOutput[];
};

export type LuckyStrikeGameConfigRowOutput = {
    type: string;
    maxUsers: number;
};

export type LuckyStrikeGameConfigOutput = {
    maxTicketAmount: number;
    minTicketAmount: number;
    ticketPriceWei: string;
    commisionFeePercentage: number;
    gameTypes: LuckyStrikeGameConfigRowOutput[];
};

export type LuckyStrikeGameConfigOutputResponse = {
    luckyStrikeGameConfig: LuckyStrikeGameConfigOutput;
};

export type LuckyStrikeGameParticipantRowOutput = {
    id: string;
    username: string | null;
    avatarUrl: string | null;
    iguAmountWei: string;
    ticketsCount: number;
    isWinner: boolean;
};

export type LuckyStrikeGameParticipantsOutput = {
    iguAmountWei: string;
    userCount: number;
    maxUsers: number;
    participants: LuckyStrikeGameParticipantRowOutput[];
};

export type LuckyStrikeGameParticipantsOutputResponse = {
    luckyStrikeGameParticipants: LuckyStrikeGameParticipantsOutput;
};

export type LuckyStrikePendingGameOutput = {
    id: string;
    duration: string;
    iguAmountWei: string;
};

export type LuckyStrikePendingGameOutputResponse = {
    luckyStrikeMyPendingGames: LuckyStrikePendingGameOutput[];
};

export type LuckyStrikeUserTicketOutput = {
    number: number;
    isWon: boolean;
};

export type LuckyStrikeUserTicketOutputResponse = {
    luckyStrikeUserTickets: LuckyStrikeUserTicketOutput[];
};

export type LuckyStrikeSingeAvailableGameOutput = {
    luckyStrikeAvailableGame: LuckyStrikeAvailableGameOutput;
};

export type LuckyStrikeGamesStatisticOutput = {
    totalIguAmountWei: string;
    totalGamesCount: number;
};

export type LuckyStrikeGamesStatisticOutputResponse = {
    luckyStrikeGamesStatistic: LuckyStrikeGamesStatisticOutput;
};

export type PaginatedLuckyStrikeGamesWinnerOutput = {
    number: number;
    duration: string;
    username: string | null;
    avatarUrl: string | null;
    iguAmountWei: string;
    title: string;
};

export type PaginatedLuckyStrikeGamesWinnerOutputResponse = {
    luckyStrikeGamesWinner: {
        items: PaginatedLuckyStrikeGamesWinnerOutput[];
        meta: PaginationMeta;
    };
};

export enum LUCKY_STRIKE_GAME_DURATION {
    FiveMinute = 'FiveMinute',
    Hourly = 'Hourly',
}

export enum LUCKY_STRIKE_GAMES_STATUS {
    Lost = 'Lost',
    Won = 'Won',
    WaitingForResults = 'WaitingForResults',
}

export type PaginatedLuckyStrikeGamesHistoryOutput = {
    id: string;
    iguAmountWei: string;
    status: LUCKY_STRIKE_GAMES_STATUS;
    createdAt: string;
    title: string;
};

export type PaginatedLuckyStrikeGamesHistoryOutputResponse = {
    luckyStrikeGamesHistory: {
        items: PaginatedLuckyStrikeGamesHistoryOutput[];
        meta: PaginationMeta;
    };
};

export type LuckyStrikeGameHistoryOutput = {
    iguAmountWei: string;
    title: string;
    duration: string;
    wonTicketNumber: number;
    userTicketNumbers: number[];
    isUserWinner: boolean;
};

export type LuckyStrikeGameHistoryOutputResponse = {
    luckyStrikeGameHistory: LuckyStrikeGameHistoryOutput;
};

export type QuantumSpinGameConfigOutput = {
    spinPriceIguAmountWei: string;
    globalSeed: string | null;
    pregeneratedHashesCount: number;
    twitterBlockPostUrl: string | null;
};

export type QuantumSpinGameConfigOutputResponse = {
    quantumSpinGameConfig: QuantumSpinGameConfigOutput;
};

export type QuantumSpinGamePrizeOutput = {
    chance: string;
    iguAmountWei: string | null;
    backgroundColor: string;
    borderColor: string;
};

export type QuantumSpinGamePrizeOutputResponse = {
    quantumSpinGamePrizes: QuantumSpinGamePrizeOutput[];
};

export type PaginatedQuantumSpinGamesHistoryOutputResponse = {
    quantumSpinGameHistory: {
        items: QuantumSpinGameOutput[];
        meta: PaginationMeta;
    };
};

export type QuantumSpinGameOutput = {
    id: string;
    iguAmountWei: string;
    hash: string;
    date: string;
};

export type QuantumSpinGameSpinOutputResponse = {
    quantumSpinGameSpin: QuantumSpinGameOutput;
};
export type QuantumSpinGameProvableFairGameOutput = {
    id: string;
    hash: string;
    date: string;
};
export type QuantumSpinGameProvableFairGameOutputResponse = {
    quantumSpinGamesLastGames: QuantumSpinGameProvableFairGameOutput[];
};

export type TokentellerGameConfigOutput = {
    minimalBetIguAmountWei: string;
    commisionFeePercentage: number;
    gameDurationMinutes: number;
};

export type TokentellerGameConfigOutputResponse = {
    tokentellerGameConfig: TokentellerGameConfigOutput;
};

export enum TOKEN_TELLER_GAME_STATUS {
    Pending = 'Pending',
    Closed = 'Closed',
    Completed = 'Completed',
    Refund = 'Refund',
}
export enum TOKEN_TELLER_GAME_PREDICTION_DIRECTION {
    Up = 'Up',
    Down = 'Down',
    Equal = 'Equal',
}

export enum TOKEN_TELLER_HISTORY_GAMES_STATUS {
    Lost = 'Lost',
    Won = 'Won',
    WaitingForResults = 'WaitingForResults',
    Refund = 'Refund',
}

export type TokentellerAvailableGameOutput = {
    id: string;
    iguAmountWei: string;
    userCount: number;
    status: TOKEN_TELLER_GAME_STATUS;
    backgroundColor: string;
    imageUrl: string;
    title: string;
    text: string;
    upCoefficient: string;
    downCoefficient: string;
    endDate: string;
    createdAt: string;
    isPredicted: boolean;
    predictedDirection: TOKEN_TELLER_GAME_PREDICTION_DIRECTION | null;
    predictedAmountWei: string | null;
    beforePrice?: string | null;
};

export type TokentellerAvailableGameOutputResponse = {
    tokentellerAvailableGames: TokentellerAvailableGameOutput[];
};

export type TokentellerGamePredictionOutput = {
    direction: TOKEN_TELLER_GAME_PREDICTION_DIRECTION;
    iguAmountWei: string;
};

export type TokentellerPendingGameOutput = {
    id: string;
    status: TOKEN_TELLER_GAME_STATUS;
    prediction: TokentellerGamePredictionOutput | null;
    iguAmountWei: string;
};

export type TokentellerPendingGameOutputResponse = {
    tokentellerMyPendingGames: TokentellerPendingGameOutput[];
};

export type TokentellerGamePredictOutput = {
    tokentellerGamePerdict: boolean;
};

export type TokentellerSingleAvailableGameOutputResponse = {
    tokentellerAvailableGame: TokentellerAvailableGameOutput;
};

export type TokenPriceHistoryOutput = {
    paymentsTokensPriceHistory: {
        iguusdt: {
            percent_change_24h: number;
            price: number;
        };
    };
};

export type TokentellerGameCoefficientOutput = {
    gameId: string;
    upCoefficient: string;
    downCoefficient: string;
    userCount: number;
    iguAmountWei: string;
};

export type TokentellerGameCoefficientOutputResponse = {
    tokentellerGameCoefficients: TokentellerGameCoefficientOutput;
};

export type TokentellerGameParticipantRowOutput = {
    id: string;
    username: string | null;
    avatarUrl: string | null;
    iguAmountWei: string;
    direction: TOKEN_TELLER_GAME_PREDICTION_DIRECTION;
};

export type TokentellerGameParticipantsOutput = {
    iguAmountWei: string;
    userCount: number;
    participants: TokentellerGameParticipantRowOutput[];
};

export type TokentellerGameParticipantsOutputResponse = {
    tokentellerGameParticipants: TokentellerGameParticipantsOutput;
};

export type PaginatedTokentellerGamesHistoryOutput = {
    id: string;
    iguAmountWei: string;
    status: TOKEN_TELLER_HISTORY_GAMES_STATUS;
    createdAt: string;
};

export type PaginatedTokentellerGamesHistoryOutputResponse = {
    tokentellerGamesHistory: {
        items: PaginatedTokentellerGamesHistoryOutput[];
        meta: PaginationMeta;
    };
};

export type TokentellerGameHistoryOutput = {
    afterPrice: string;
    beforePrice: string;
    iguAmountWei: string;
    upCoefficient: string;
    downCoefficient: string;
    userCount: number;
    userPredictIguAmountWei: string;
    userDirection: TOKEN_TELLER_GAME_PREDICTION_DIRECTION;
    winDirection: TOKEN_TELLER_GAME_PREDICTION_DIRECTION;
    status: TOKEN_TELLER_HISTORY_GAMES_STATUS;
    userAffectedIguAmountWei: string;
};

export type TokentellerGameHistoryOutputResponse = {
    tokentellerGameHistory: TokentellerGameHistoryOutput;
};

export type IguUsdtExchangeRateResponse = {
    exchangeRates: {
        iguusdt: string;
    };
};
