import { StyleSheet } from 'react-native';

import Colors from '../../../constants/Colors';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        backdrop: {
            // only for web
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            position: 'fixed',
            flex: 1,
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
            opacity: 0.7,
            backgroundColor: Colors[theme].modal.modalOverlay,
        },
    });

export default styles;
