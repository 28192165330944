import useThemedStyles from '../../hooks/useThemedStyles';
import Button from '../Button';
import stylesMain from './styles';

interface IProps {
    text: string;
    onPress: () => void;
    active: boolean;
    disable?: boolean;
}

const AmountButton = ({ text, onPress, active, disable }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    return (
        <Button
            disabled={disable}
            containerStyle={[styles.container, active && styles.activeBg]}
            onPress={onPress}
            title={text}
            type={'outline'}
            size="md"
            style={active && styles.activeBorder}
            textStyles={styles.textStyles}
        />
    );
};

export default AmountButton;
