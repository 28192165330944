import * as React from 'react';

import useThemedStyles from '../../hooks/useThemedStyles';
import { View } from '../Themed';
import SelectorSegmentItem from './SwitchItem';
import stylesMain from './styles';

interface IProps {
    defaultActiveOption: string;
    firstOption: string;
    secondOption: string;
    firstOptionLabel: string;
    secondOptionLabel: string;
    onFirstOptionActive: () => void;
    onSecondOptionActive: () => void;
}

const TextSwitch = ({
    defaultActiveOption,
    firstOption,
    secondOption,
    firstOptionLabel,
    secondOptionLabel,
    onFirstOptionActive,
    onSecondOptionActive,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const [activeOption, setActiveOption] = React.useState(defaultActiveOption);

    const handleOption = (option: string) => {
        setActiveOption(option);
        if (option === firstOption) onFirstOptionActive();
        else if (option === secondOption) onSecondOptionActive();
    };

    return (
        <View style={styles.shadow}>
            <View style={styles.container}>
                <SelectorSegmentItem
                    onPress={() => handleOption(firstOption)}
                    title={firstOptionLabel}
                    isActive={activeOption === firstOption}
                />
                <SelectorSegmentItem
                    onPress={() => handleOption(secondOption)}
                    title={secondOptionLabel}
                    isActive={activeOption === secondOption}
                />
            </View>
        </View>
    );
};
export default TextSwitch;
