import { useEffect } from 'react';

import { useModal } from '@contexts/ModalContext';
import { isIOS } from '@helpers/app';

export default function useNavigationListeners() {
    // This hooks disables other global modals on modal screens, which prevents ios Freeze bug to happen
    const { setIsVisible } = useModal();

    useEffect(() => {
        if (!isIOS) {
            return;
        }

        setIsVisible(true);

        return () => {
            setIsVisible(false);
        };
    }, [setIsVisible]);
}
