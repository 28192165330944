import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { moderateScale, scale, verticalScale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        infoItem: {
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            backgroundColor: 'transparent',
        },
        infoTitle: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
            marginBottom: verticalScale(4),
        },
        infoText: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
        },
        warningBlock: {
            marginTop: verticalScale(16),
        },
        top: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            backgroundColor: 'transparent',
        },
        left: {
            flex: 1,
            marginRight: scale(16),
            backgroundColor: 'transparent',
        },
        loading: {
            position: 'absolute',
            zIndex: 1000,
            width: '100%',
            height: '100%',
            top: verticalScale(16),
            left: scale(16),
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
        },
        button: {
            marginTop: verticalScale(16),
        },
        opacityTextColor: {
            color: Colors[theme].text.white,
        },
        opacityBlock: {
            borderColor: 'transparent',
            backgroundColor: Colors[theme].fillPrimaryMinimal,
        },
    });

export default styles;
