import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { ICON_NAMES } from '@components/Icons';
import ModalLoader from '@components/ModalLoader';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import useCreateMysteryLikesGame from '../../hooks/useCreateMysteryLikesGame';
import GamesNavigation from '../GamesNavigation';

const MysteryLikesHeader = () => {
    const navigation = useNavigation();

    const handleOnInfoIconPress = () => {
        navigation.push(ROUTES.HOW_MYSTERY_LIKES_WORK);
    };

    const { handleCreateGame, createGameLoading, renderPreviousGameModal } =
        useCreateMysteryLikesGame();

    return (
        <>
            <ModalLoader isVisible={createGameLoading} />
            <GamesNavigation
                backIconName={ICON_NAMES.CLOSE}
                type={MYSTERY_GAMES.mysteryLikes}
                onPress={handleCreateGame}
                handleOnInfoIconPress={handleOnInfoIconPress}
                buttonTitle={i18n.t('general.BUTTONS.createNewGame')}
            />
            {renderPreviousGameModal()}
        </>
    );
};

export default MysteryLikesHeader;
