import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        avatarWrapper: {
            flex: 1,
            backgroundColor: 'transparent',
        },
        avatarInner: {
            backgroundColor: 'transparent',
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        roleText: {
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
        },
        MODERATOR: {
            color: Colors[theme].text.danger,
        },
        ADMIN: {
            color: Colors[theme].text.warning,
        },
        USER: {
            color: Colors[theme].text.primary,
        },
    });

export default styles;
