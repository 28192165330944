import * as React from 'react';

import Banner1 from '@assets/icons/shareTemplates/bybit1.svg';
import Banner2 from '@assets/icons/shareTemplates/bybit2.svg';

import { IconType } from '../types';

interface IProps extends IconType {
    slug: string; //'#212121' | '#F8B769';
}

const BybitBackground = ({ slug }: IProps) => {
    switch (slug) {
        case '#212121':
            return <Banner1 />;
        case '#F8B769':
            return <Banner2 />;

        default:
            return <Banner1 />;
    }
};

export default BybitBackground;
