import React, { FC, PropsWithChildren, createContext, useEffect } from 'react';
import { Platform } from 'react-native';
import Purchases, {
    PURCHASES_ERROR_CODE,
    PURCHASE_TYPE,
    PurchasesStoreProduct,
} from 'react-native-purchases';

import { REVENUE_CAT_APIKEY_ANDROID, REVENUE_CAT_APIKEY_IOS } from '@env';
import { isIOS, isProduction, isRunningInExpo } from '@helpers/app';
import { errorsHandler } from '@helpers/errors';

import { useUser } from './UserContext';

export type STORE_ERROR_TYPES = 'FINALIZE' | 'PURCHASE' | 'NONE';

export type STORE_LOADING_TYPES = 'PURCHASE' | 'FINALIZE' | 'NONE';

type StorePurchaseContextType = {
    purchasePet: (
        productId: string,
        petId: string
    ) => Promise<boolean | undefined>;
    purchaseMysterybox: (productId: string) => Promise<boolean>;
    inAppPurchasesAvailable: boolean;
    reconfigureStoreWithItemId: (itemId: string) => Promise<boolean>;
    getMysteyboxPrices: (ids: string[]) => Promise<PurchasesStoreProduct[]>;
    getMintPrices: (ids: string[]) => Promise<PurchasesStoreProduct[]>;
    isConfigured: boolean;
    isStoreError: boolean;
    isStoreLoading: boolean;
    storeErrorType: STORE_ERROR_TYPES;
    storeLoadingType: STORE_LOADING_TYPES;
    storeErrorCode: string;
    hideModal: () => void;
    setLoading: (type: STORE_LOADING_TYPES) => void;
    stopLoading: () => void;
    setError: (type: STORE_ERROR_TYPES) => void;
    petMintProducts: PurchasesStoreProduct[];
    mysteryboxProducts: PurchasesStoreProduct[];
};

export const StorePurchaseContext = createContext<StorePurchaseContextType>({
    purchasePet: async () => undefined,
    purchaseMysterybox: async () => false,
    reconfigureStoreWithItemId: async () => false,
    getMysteyboxPrices: async () => [],
    getMintPrices: async () => [],
    inAppPurchasesAvailable: false,
    isConfigured: false,
    isStoreError: false,
    isStoreLoading: false,
    storeErrorType: 'NONE',
    storeLoadingType: 'NONE',
    storeErrorCode: '',
    hideModal: () => undefined,
    setLoading: () => undefined,
    stopLoading: () => undefined,
    setError: () => undefined,
    petMintProducts: [],
    mysteryboxProducts: [],
});

export const useStorePurchase = () => React.useContext(StorePurchaseContext);

declare type APIKeyType = 'android' | 'ios';

const APIKeys = {
    ios: REVENUE_CAT_APIKEY_IOS,
    android: REVENUE_CAT_APIKEY_ANDROID,
};

const StorePurchaseProvider: FC<PropsWithChildren<unknown>> = ({
    children,
}) => {
    // whether feature is available
    const inAppPurchasesAvailable: boolean = !isRunningInExpo() && isIOS;

    const [isConfigured, setIsConfigured] = React.useState<boolean>(false);

    const [isStoreError, setIsStoreError] = React.useState<boolean>(false);
    const [isStoreLoading, setIsStoreLoading] = React.useState<boolean>(false);

    const [storeErrorType, setStoreErrorType] =
        React.useState<STORE_ERROR_TYPES>('NONE');
    const [storeLoadingType, setStoreLoadingType] =
        React.useState<STORE_LOADING_TYPES>('NONE');

    const [storeErrorCode, setStoreErrorCode] = React.useState<string>('');
    const { user } = useUser();

    useEffect(() => {
        const initialStoreLoading = async () => {
            Console.log(
                '[StorePurchase] initialStoreLoading',
                inAppPurchasesAvailable
            );
            if (!inAppPurchasesAvailable) {
                return;
            }
            try {
                if (!isProduction()) {
                    await Purchases.setDebugLogsEnabled(true);
                }

                Console.log('API KEY', APIKeys[Platform.OS as APIKeyType]);
                Purchases.configure({
                    apiKey: APIKeys[Platform.OS as APIKeyType],
                });
                const result = await Purchases.isConfigured();
                setIsConfigured(result);
            } catch (error) {
                setIsConfigured(false);
                errorsHandler(error);
            }
        };

        initialStoreLoading();
    }, [inAppPurchasesAvailable]);

    const setError = (type: STORE_ERROR_TYPES) => {
        setIsStoreLoading(false);
        setStoreErrorType(type);
        setIsStoreError(true);
    };
    const setLoading = (type: STORE_LOADING_TYPES) => {
        setIsStoreLoading(true);
        setStoreLoadingType(type);
    };
    const stopLoading = () => {
        setStoreLoadingType('NONE');
        setIsStoreLoading(false);
    };

    const hideModal = () => {
        setIsStoreError(false);
        setStoreErrorType('NONE');
        setStoreErrorCode('');
    };

    const reconfigureStoreWithItemId = React.useCallback(
        async (itemId: string): Promise<boolean> => {
            if (!user) {
                return false;
            }
            return reconfigureStoreWithUserId(`${user.id}|${itemId}`);
        },
        [user]
    );

    const reconfigureStore = async (): Promise<boolean> => {
        if (!user) {
            return false;
        }
        return reconfigureStoreWithUserId(user?.id);
    };

    const reconfigureStoreWithUserId = async (
        userId: string
    ): Promise<boolean> => {
        if (!inAppPurchasesAvailable) {
            return false;
        }
        try {
            Purchases.configure({
                apiKey: APIKeys[Platform.OS as APIKeyType],
                appUserID: userId,
            });
            const isConfigured = await Purchases.isConfigured();
            if (isConfigured) {
                return true;
            }
            setError('PURCHASE');
            setStoreErrorCode('CONFIGURE_STORE');

            return false;
        } catch (error) {
            errorsHandler(error);
            setError('PURCHASE');
            setStoreErrorCode('CONFIGURE_STORE');
            return false;
        }
    };

    const [petMintProducts, setPetMintProducts] = React.useState<
        PurchasesStoreProduct[]
    >([]);
    const [mysteryboxProducts, setMysteryboxProducts] = React.useState<
        PurchasesStoreProduct[]
    >([]);

    const getMysteyboxPrices = async (ids: string[]) => {
        const prices = await getStorePrice(ids);
        setMysteryboxProducts(prices);
        return prices;
    };

    const getMintPrices = async (ids: string[]) => {
        const prices = await getStorePrice(ids);
        setPetMintProducts(prices);
        return prices;
    };

    const getStorePrice = React.useCallback(
        async (ids: string[]): Promise<PurchasesStoreProduct[]> => {
            if (!inAppPurchasesAvailable || !ids?.length) {
                return [];
            }
            try {
                if (!isConfigured) {
                    await reconfigureStore();
                }
                const products = await Purchases.getProducts(
                    ids,
                    PURCHASE_TYPE.INAPP
                );
                Console.log('[StorePurchaseContext]', products, ids);
                return products;
            } catch (error) {
                errorsHandler(error);
                return [];
            }
        },
        [reconfigureStore]
    );

    const purchasePet = async (productId: string, petId: string) => {
        if (!inAppPurchasesAvailable) {
            return false;
        }
        setLoading('PURCHASE');
        try {
            await Purchases.setAttributes({ petId });
            await Purchases.purchaseProduct(
                productId,
                null,
                PURCHASE_TYPE.INAPP
            );
            return true;
        } catch (error: any) {
            errorsHandler(error);
            const indexOfS = Object.values(PURCHASES_ERROR_CODE).indexOf(
                error?.code as unknown as PURCHASES_ERROR_CODE
            );
            if (indexOfS === 1) {
                setIsStoreLoading(false);
                return false;
            }
            setStoreErrorCode(Object.keys(PURCHASES_ERROR_CODE)[indexOfS]);
            setError('PURCHASE');
            return false;
        }
    };

    const purchaseMysterybox = async (productId: string) => {
        if (!inAppPurchasesAvailable) {
            return false;
        }
        setLoading('PURCHASE');
        if (!(await reconfigureStore())) {
            return false;
        }
        try {
            await Purchases.purchaseProduct(
                productId,
                null,
                PURCHASE_TYPE.INAPP
            );
            return true;
        } catch (error: any) {
            errorsHandler(error);
            const indexOfS = Object.values(PURCHASES_ERROR_CODE).indexOf(
                error?.code as unknown as PURCHASES_ERROR_CODE
            );
            if (indexOfS === 1) {
                setIsStoreLoading(false);
                return false;
            }
            setStoreErrorCode(Object.keys(PURCHASES_ERROR_CODE)[indexOfS]);
            setError('PURCHASE');
            return false;
        }
    };

    return (
        <StorePurchaseContext.Provider
            value={{
                purchasePet,
                purchaseMysterybox,
                inAppPurchasesAvailable,
                reconfigureStoreWithItemId,
                isConfigured,
                isStoreError,
                isStoreLoading,
                storeErrorType,
                storeLoadingType,
                storeErrorCode,
                hideModal,
                setError,
                setLoading,
                stopLoading,
                getMintPrices,
                getMysteyboxPrices,
                petMintProducts,
                mysteryboxProducts,
            }}>
            {children}
        </StorePurchaseContext.Provider>
    );
};

export default StorePurchaseProvider;
