import React from 'react';

import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';

import stylesMain from './styles';

interface IProps {
    icon: React.ReactNode;
    isOneTheme?: boolean;
}

export const BreedingCircleItem = ({ icon, isOneTheme }: IProps) => {
    const styles = useThemedStyles(stylesMain, isOneTheme);
    return <View style={styles.container}>{icon}</View>;
};

export default BreedingCircleItem;
