import { useEffect, useState } from 'react';

import _ from 'lodash';

import { useApolloClient } from '@apollo/client';
import { ApolloClient } from '@apollo/client/core';
import { useUser } from '@contexts/UserContext';
import { WalletData } from '@contexts/Wallet/WalletHelpers';

import { ExchangeRatesOutput, ExchangeRatesResponse } from '../../Data/Models';
import { GET_EXCHANGES_RATES } from '../../Data/Requests';

const settings = {
    reloadInterval: 60 * 1000,
};

const useLoadExchangeRates = (
    reloadDisabled: boolean,
    walletData: WalletData | null
) => {
    const [exchangeRates, setExchangeRates] =
        useState<ExchangeRatesOutput | null>(null);
    const client = useApolloClient();
    const { user, userUpdated } = useUser();

    useEffect(() => {
        if (!user || !userUpdated || !walletData) {
            return;
        }

        Console.log(
            '[useLoadExchangeRates] load exchanges',
            `walletData=${walletData?.wallet.address}`
        );

        refetch(client).then((response) => {
            setExchangeRates(response.data.exchangeRates);
        });
    }, [client, user, userUpdated, walletData]);

    useEffect(() => {
        let isMounted = true;

        if (!user || !walletData) {
            return;
        }

        if (reloadDisabled) {
            return;
        }

        const interval = setInterval(() => {
            Console.log('Updating exchange rates..');
            refetch(client).then((response) => {
                const newRates = response.data.exchangeRates;

                if (!_.isEqual(exchangeRates, newRates)) {
                    if (isMounted) setExchangeRates(newRates);
                    Console.log('Exchange rates NOT equal, updating');
                } else {
                    Console.log('Exchange rates equal, NOT updating');
                }
            });
        }, settings.reloadInterval);

        return () => {
            isMounted = false;
            clearInterval(interval);
        };
    }, [client, user, walletData, exchangeRates, reloadDisabled]);

    async function refetchExchanges() {
        if (user) {
            await refetch(client).then((response) => {
                const newRates = response.data.exchangeRates;

                if (!_.isEqual(exchangeRates, newRates)) {
                    setExchangeRates(newRates);
                }
            });
        }
    }

    return { exchangeRates, refetchExchanges };
};

function refetch(client: ApolloClient<object>) {
    return client.query<ExchangeRatesResponse>({
        query: GET_EXCHANGES_RATES,
        fetchPolicy: 'network-only',
    });
}

export default useLoadExchangeRates;
