import React, { useEffect, useState } from 'react';
import { Pressable } from 'react-native';

import { Camera } from 'expo-camera';
import _ from 'lodash';
import styles from 'rn-range-slider/styles';

import Icon, { ICON_NAMES } from '../components/Icons';
import QrCodeScanner from '../components/QrCodeScanner';
import Colors from '../constants/Colors';
import { useTheme } from '../contexts/ThemeContext';
import { isDesktop, isIOS, isWeb } from '../helpers/app';
import { scale, verticalScale } from '../helpers/dimensions';
import i18n from '../i18n/i18n';

export type ScannerType = 'wallet' | 'referral' | 'verify';

const useQrCodeScanner = (
    type: ScannerType,
    scanAction: (info: string) => void
) => {
    const { theme } = useTheme();

    const [isVisibleQrCodeScanner, setIsVisibleQrCodeScanner] =
        React.useState<boolean>(false);
    const [canShowQrCode, setCanShowQrCode] = useState<boolean>(true);

    useEffect(() => {
        async function setPermissions() {
            // we always show it for mobile
            if (!isWeb) {
                return;
            }
            const isCameraAvailable = await Camera.isAvailableAsync();
            // we do not show qrCode scanner on Desktop
            setCanShowQrCode(isCameraAvailable);
        }

        setPermissions();
    }, []);

    const openQrCodeScanner = () => {
        setIsVisibleQrCodeScanner(true);
    };

    const handleCloseQrCodeScanner = () => {
        setIsVisibleQrCodeScanner(false);
    };

    const handleScanned = async (scanned: string) => {
        scanAction(scanned);
        handleCloseQrCodeScanner();
    };

    const scanButton = () => {
        if (!canShowQrCode) {
            return undefined;
        }

        return (
            <Pressable
                style={{
                    position: 'absolute',
                    top: isIOS ? verticalScale(13) : verticalScale(14),
                    right: scale(16),
                    zIndex: 5,
                }}
                hitSlop={15}
                onPress={openQrCodeScanner}>
                <Icon
                    name={ICON_NAMES.SCAN}
                    color={Colors[theme].icon.secondary}
                />
            </Pressable>
        );
    };

    const scannerModal = () => (
        <QrCodeScanner
            titleScanned={i18n.t(`qrCodeScanner.${type}.titleScanned`)}
            title={i18n.t('qrCodeScanner.title')}
            text={i18n.t(`qrCodeScanner.${type}.text`)}
            isVisible={isVisibleQrCodeScanner}
            onClose={handleCloseQrCodeScanner}
            onScanned={handleScanned}
        />
    );

    return { scannerModal, scanButton };
};

export default useQrCodeScanner;
