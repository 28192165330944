import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale } from '@helpers/dimensions';
import { stylesAlignCenter } from '@helpers/style';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        iconSize: {
            width: 32,
            height: 32,
            ...stylesAlignCenter(false),
        },
        container: {
            flexDirection: 'row',
            backgroundColor: 'transparent',
            alignItems: 'center',
            marginHorizontal: scale(32),
        },
        dividerWrapper: {
            flex: 1,
            backgroundColor: 'transparent',
            marginHorizontal: scale(8),
        },
        divider: {
            height: 1,
            backgroundColor: Colors[theme].backgroundPrimaryMinimal,
        },
        dividerAnim: {
            position: 'absolute',

            height: 1,
            backgroundColor: '#fff',
        },
    });

export default styles;
