import * as React from 'react';
import { FlatList } from 'react-native';

import { isArray } from 'lodash';

import { isOdd } from '../../helpers/helpers';
import useThemedStyles from '../../hooks/useThemedStyles';
import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    mnemonic: Array<string> | string | null | undefined;
    masked?: boolean;
}

const WalletSeedPhrase = ({ mnemonic, masked }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const words = isArray(mnemonic)
        ? mnemonic
        : mnemonic
        ? mnemonic.trim().split(/\s+/)
        : [];

    const renderItem = ({ item, index }: any) => {
        const maskedWord = '-'.repeat(6);

        return (
            <View
                key={item}
                style={[styles.item, isOdd(index) && styles.itemGap]}>
                <Text style={styles.itemIndex}>{index + 1}.</Text>
                <Text style={styles.itemText}>
                    {masked ? maskedWord : item}
                </Text>
            </View>
        );
    };

    return <FlatList data={words} renderItem={renderItem} numColumns={2} />;
};

export default WalletSeedPhrase;
