import { isDesktop } from '../../helpers/app';

export default class ScreenOrientationManager {
    static async setDefault() {
        if (!isDesktop) {
            setTimeout(() => {
                document.body.style.setProperty('-webkit-transform', 'none');
                document.body.style.setProperty('transform', 'none');
            }, 0);
        }
    }

    static async setLandscape() {
        if (!isDesktop) {
            document.body.style.setProperty(
                '-webkit-transform',
                'rotate(-90deg)'
            );
            document.body.style.setProperty('transform', 'rotate(-90deg)');
        }
    }
}
