import * as React from 'react';
import { ScrollView } from 'react-native';

import _ from 'lodash';

import BoostImage from '@assets/icons/emptyState/stakingCalcBoost.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import FlowStepIndicator from '@components/FlowStepIndicator';
import ModalLoader from '@components/ModalLoader';
import SafeAreaTabContent from '@components/SafeAreaTabContent';
import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';

import StakingCardSummary from '../components/StakingCardSummary/StakingCardSummary';
import StakingConfirmationModal from '../components/StakingConfirmationModal';
import { useStakingDailyBoostScreen } from './hook';
import stylesMain from './styles';

const StakingDailyBoostScreen = () => {
    const styles = useThemedStyles(stylesMain);

    const {
        currentPlan,
        stakingBoost,
        stakingAmount,
        stakingCoin,
        currentStakingPlan,
        handleOpenConfirmationModal,
        handleCloseConfirmationModal,
        isVisibleConfirmationModal,
        setIsVisibleConfirmationModal,
        onStakePress,
        isLoading,
        renderBlockchainErrorsModal,
        indicatorFlow,
        indicatorStage,
        isLoaded,
    } = useStakingDailyBoostScreen();

    return (
        <SafeAreaTabContent>
            <NavigationBar
                filled={false}
                backIcon="leftArrow"
                title={i18n.t('staking.dailyBoostScreen.title')}
            />
            <FlowStepIndicator
                flow={indicatorFlow}
                stage={indicatorStage}
                stageFill={'100%'}
                isDynamicStageFill={false}
            />

            <ScrollView
                style={styles.container}
                showsVerticalScrollIndicator={false}
                bounces={false}>
                <BlockHeader
                    emptyStateIcon={<BoostImage />}
                    title={i18n.t('staking.dailyBoostScreen.headerTitle')}
                    additionalTitle={`${stakingBoost || 0}%`}
                    text={i18n.t('staking.dailyBoostScreen.headerText')}
                    type={'emptyState'}
                />
                {isLoaded && currentPlan && (
                    <View style={styles.cardsInfoWrapper}>
                        <StakingCardSummary
                            data={currentPlan}
                            stakingBoost={stakingBoost}
                            stakingAmount={stakingAmount}
                            coin={stakingCoin}
                            current={currentStakingPlan?.amountWei}
                        />
                    </View>
                )}
            </ScrollView>
            <ButtonsBottom
                title={i18n.t('staking.welcomeScreen.button')}
                onPress={handleOpenConfirmationModal}
                safe
                disabled={isLoading || !isLoaded}
                loading={isLoading}
            />
            {!!currentPlan && !!stakingAmount && (
                <StakingConfirmationModal
                    name={currentPlan.plan.name}
                    durationDays={currentPlan.plan.durationDays}
                    coin={stakingCoin}
                    onPress={() => onStakePress(currentPlan, stakingAmount)}
                    close={handleCloseConfirmationModal}
                    setIsVisibleConfirmationModal={
                        setIsVisibleConfirmationModal
                    }
                    isVisible={isVisibleConfirmationModal}
                    stakingAmount={stakingAmount}
                    stakingBoost={stakingBoost}
                />
            )}
            {renderBlockchainErrorsModal()}
            <ModalLoader
                isVisible={isLoading}
                text={i18n.t('staking.stakeLoading')}
            />
        </SafeAreaTabContent>
    );
};

export default StakingDailyBoostScreen;
