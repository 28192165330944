import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(20),
            marginTop: verticalScale(24),
        },
        gap: {
            marginBottom: verticalScale(16),
        },
        header: {
            marginBottom: verticalScale(20),
        },
    });

export default styles;
