import React from 'react';

import EmptyList from '../../components/EmptyList';
import { View } from '../../components/Themed';
import useThemedStyles from '../../hooks/useThemedStyles';
import i18n from '../../i18n/i18n';
import WebContainer from '../../web-src/components/WebContainer';
import stylesMain from './styles';

const ErrorBoundary = () => {
    const styles = useThemedStyles(stylesMain);

    return (
        <WebContainer isVisible>
            <View style={styles.wrapper}>
                <EmptyList
                    emptyTitle={i18n.t('errorBoundary.title')}
                    emptyText={i18n.t('errorBoundary.text')}
                    emptyButtonText={i18n.t('errorBoundary.button')}
                    onPressButton={() => {
                        window.location.href = '/';
                    }}
                />
            </View>
        </WebContainer>
    );
};

export default ErrorBoundary;
