import { useMutation } from '@apollo/client';

import { SET_CHARITY } from '../Data/Requests';
import { useUser } from '../contexts/UserContext';
import { toastErrorUnknown, toastSuccess } from '../helpers/toastNotification';
import i18n from '../i18n/i18n';
import ROUTES from '../navigation/routes';
import { useNavigation } from '../navigation/useNavigation';

interface onSaveOrganizationProps {
    charityId: string;
    onError?: () => void;
    onSuccess?: () => void;
}

const useSetCharity = () => {
    const { user, reloadUser } = useUser();
    const navigation = useNavigation();

    const [setCharity, { loading: isSetCharityLoading }] = useMutation(
        SET_CHARITY,
        {
            onError: (error) => Console.log('[SET_CHARITY]', error),
        }
    );

    const savePercents = (percents: number) => {
        setCharity({
            variables: {
                charityId: user?.charity?.id,
                donationPercent: percents,
            },
            onError: (error) => {
                Console.error(error);
                toastErrorUnknown();
            },
            onCompleted: () => {
                toastSuccess(undefined, i18n.t('charity.saveSuccess'));
                reloadUser();
            },
        });
    };

    const saveOrganization = ({
        charityId,
        onError = () => {},
        onSuccess = () => {},
    }: onSaveOrganizationProps) => {
        if (charityId === user?.charity?.id) {
            navigation.navigate(ROUTES.CHARITY);
            return;
        }
        setCharity({
            variables: {
                charityId,
                donationPercent: user?.charityDonationPercentage,
            },
            onError: (error) => {
                Console.error(error);
                toastErrorUnknown();
                onError();
            },
            onCompleted: async () => {
                onSuccess();
                await reloadUser();
                navigation.navigate(ROUTES.CHARITY);

                toastSuccess(i18n.t('charity.changeOrganizationSuccess'));
            },
        });
    };

    return { savePercents, saveOrganization, isSetCharityLoading };
};

export default useSetCharity;
