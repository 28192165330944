import React, { useCallback } from 'react';

import useOpenModalScreen from '@hooks/useOpenModalScreen';
import { useVisibleHook } from '@hooks/useVisibleHook';
import { LuckyStrikeAvailableGameOutput } from '@models/mysteryGames';
import ROUTES from '@navigation/routes';

import LuckyStrikePurchaseModal from '../components/LuckyStrikePurchaseModal';

const useLuckyStrikePurchaseModal = (
    routeBack: ROUTES,
    action?: () => void
) => {
    const {
        isVisible: purchaseModal,
        open,
        close: closePurchaseModal,
    } = useVisibleHook();

    const openPurchaseModal = async (handleClosePrevModal?: () => void) => {
        if (handleClosePrevModal) {
            handleClosePrevModal?.();
        }

        open();
    };

    const { handleOpenModalScreen } = useOpenModalScreen({
        modalOpen: open,
        modalClose: closePurchaseModal,
        route: ROUTES.LUCKY_STRIKE_ALL_PARTICIPANTS,
    });

    const handleClose = () => {
        closePurchaseModal();
        action?.();
    };

    const renderPurchaseModal = useCallback(
        (game: LuckyStrikeAvailableGameOutput) => {
            return (
                <LuckyStrikePurchaseModal
                    game={game}
                    isVisible={purchaseModal}
                    onClose={handleClose}
                    onViewAllParticipants={handleOpenModalScreen}
                    routeBack={routeBack}
                />
            );
        },
        [purchaseModal, routeBack]
    );

    return {
        openPurchaseModal,
        closePurchaseModal,
        renderPurchaseModal,
        handleOpenModalScreen,
        purchaseModal,
    };
};

export default useLuckyStrikePurchaseModal;
