import React from 'react';

import { IUserMysteryBox } from '@Data/Models';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { toastErrorUnknown } from '@helpers/toastNotification';

const useMysteryBoxList = () => {
    const [list, setList] = React.useState<IUserMysteryBox[]>();
    const [loadingList, setLoadingList] = React.useState(false);
    const [pullToRefresh, setPullToRefresh] = React.useState(false);
    const [errorList, setErrorList] = React.useState(false);

    const { getUserMysteryBoxes } = useWallet();

    const getUserMysteryBoxesList = async (silent: boolean) => {
        setErrorList(false);

        silent ? setPullToRefresh(true) : setLoadingList(true);

        let result: IUserMysteryBox[] | null = null;
        try {
            result = await getUserMysteryBoxes();

            if (result) {
                setList(result);
            }
        } catch (error) {
            setErrorList(true);
            toastErrorUnknown();
            silent ? setPullToRefresh(false) : setLoadingList(false);
        }
        silent ? setPullToRefresh(false) : setLoadingList(false);
        return result;
    };

    return {
        list,
        loadingList,
        errorList,
        pullToRefresh,
        getUserMysteryBoxesList,
    };
};

export default useMysteryBoxList;
