import * as React from 'react';
import { useCallback } from 'react';
import { ScrollView } from 'react-native';

import DepositIcon from '@assets/icons/wallet/howToPurchaseIGU/deposit.svg';
import ExchangeIcon from '@assets/icons/wallet/howToPurchaseIGU/exchange.svg';
import TradeIcon from '@assets/icons/wallet/howToPurchaseIGU/trade.svg';
import ClickableCard from '@components/ClickableCard';
import ExchangesContent from '@components/ExchangesContent';
import ModalBottom from '@components/ModalBottom';
import ModalDeposit from '@components/ModalDeposit';
import ModalMainnet from '@components/ModalMainnet';
import { useConfig } from '@contexts/ConfigContext';
import { useTrade } from '@contexts/Trade/TradeContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { waitForModal } from '@helpers/helpers';
import { openUrlOrShowError } from '@helpers/linking';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import { getWebAppLinkTrade } from '@navigation/helpers';
import { useNavigation } from '@navigation/useNavigation';

import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    close: () => void;
}

const HowToPurchaseIGUModal = ({ isVisible, close }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const { tradeTokens } = useTrade();
    const { isTradeAvailable } = useConfig();

    const {
        isVisible: isVisibleDepositModal,
        open: openDepositModal,
        close: closeDepositModal,
    } = useVisibleHook();

    const {
        isVisible: isVisibleMainnetModal,
        open: openMainnetModal,
        close: closeMainnetModal,
    } = useVisibleHook();

    const {
        isVisible: isVisibleExchangeModal,
        open: openExchangeModal,
        close: closeExchangeModal,
    } = useVisibleHook();

    const handleOnTradePress = useCallback(async () => {
        if (!tradeTokens.length) {
            openMainnetModal();
            return;
        }

        if (!isTradeAvailable) {
            const url = await getWebAppLinkTrade();
            await openUrlOrShowError(url);
            return;
        }
        close();
        await waitForModal();
        navigation.navigateToTrade(Coin.igu);
    }, [tradeTokens.length, isTradeAvailable]);

    return (
        <ModalBottom
            modalHeight={580}
            isVisible={isVisible}
            onClose={close}
            titleText={i18n.t('howToPurchaseIGU.modalTitle')}>
            <ScrollView showsVerticalScrollIndicator={false}>
                <ClickableCard
                    containerStyle={styles.gap}
                    title={i18n.t('howToPurchaseIGU.cardDeposit.title')}
                    text={i18n.t('howToPurchaseIGU.cardDeposit.text')}
                    onPress={openDepositModal}
                    icon={<DepositIcon width={32} height={32} />}
                    debouncedPress
                    withHaptic
                    withArrow
                />
                <ClickableCard
                    containerStyle={styles.gap}
                    title={i18n.t('howToPurchaseIGU.cardExchange.title')}
                    text={i18n.t('howToPurchaseIGU.cardExchange.text')}
                    onPress={openExchangeModal}
                    icon={<ExchangeIcon width={32} height={32} />}
                    debouncedPress
                    withHaptic
                    withArrow
                />
                <ClickableCard
                    title={i18n.t('howToPurchaseIGU.cardTrade.title')}
                    text={i18n.t('howToPurchaseIGU.cardTrade.text')}
                    onPress={handleOnTradePress}
                    icon={<TradeIcon width={32} height={32} />}
                    debouncedPress
                    withHaptic
                    withArrow
                />
            </ScrollView>
            <ModalDeposit
                isVisible={isVisibleDepositModal}
                handleClose={closeDepositModal}
                coin={Coin.igu}
            />
            <ModalMainnet
                isVisible={isVisibleMainnetModal}
                onClose={closeMainnetModal}
            />
            <ModalBottom
                isVisible={isVisibleExchangeModal}
                titleText={i18n.t('howToPurchaseIGU.exchangesModalTitle')}
                onClose={closeExchangeModal}
                modalHeight={800}>
                <ExchangesContent coin={Coin.igu} />
            </ModalBottom>
        </ModalBottom>
    );
};
export default HowToPurchaseIGUModal;
