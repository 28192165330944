import * as React from 'react';
import { FlatList } from 'react-native';

import { UserRole } from '@Data/Models';
import ButtonsBottom from '@components/ButtonsBottom';
import FlagIcons from '@components/Icons/FlagIcons';
import ModalBottom from '@components/ModalBottom';
import SelectorItem from '@components/SelectorItem';
import { Text } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    selectedLang: string;
    langs: string[];
    onSelect: (lang: string) => void;
    onPress: () => void;
    modalHeight?: number | string;
    close: () => void;
    disabled?: boolean;
    isLoading?: boolean;
}

const LanguageSelectorModal = ({
    isVisible,
    onPress,
    selectedLang,
    langs,
    modalHeight = '80%',
    close,
    onSelect,
    disabled,
    isLoading,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <ModalBottom
            isVisible={isVisible}
            onClose={close}
            modalHeight={modalHeight}
            titleText={i18n.t('modal.modalChangeLanguage.screenTitle')}>
            <FlatList
                showsVerticalScrollIndicator={false}
                style={styles.listStyle}
                data={langs}
                renderItem={({ item }) => (
                    <SelectorItem
                        isSelected={item === selectedLang}
                        children={
                            <>
                                <FlagIcons slug={item} size={24} />
                                <Text style={styles.title}>
                                    {i18n.t(
                                        `profile.languageSettings.options.${item}.name`
                                    )}
                                </Text>
                            </>
                        }
                        handleSelect={() => onSelect(item)}
                    />
                )}
                keyExtractor={(item) => item}
            />
            <ButtonsBottom
                containerStyle={styles.buttons}
                title={i18n.t('modal.modalChangeLanguage.button')}
                onPress={onPress}
                disabled={disabled}
                loading={isLoading}
            />
        </ModalBottom>
    );
};

export default LanguageSelectorModal;
