import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { moderateScale, scale, verticalScale } from '../../helpers/dimensions';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        itemContainer: {
            backgroundColor: Colors[theme].purchasingFrom.backgroundDefault,
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].purchasingFrom.borderColorDefault,
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            marginBottom: verticalScale(4),
        },
        qrCodeView: {
            backgroundColor: 'white',
        },
        shadow: {
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].purchasingFrom.shadowColorDefault,
        },
    });

export default styles;
