import React from 'react';
import { Image } from 'react-native';

import useThemedStyles from '../../hooks/useThemedStyles';
import Avatar from '../Avatar';
import stylesMain from './styles';

interface IProps {
    size?: number;
    imageUrl?: string | null;
    userId?: string | null;
}

const OtherUserAvatar = ({ imageUrl, userId, size }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    if (!imageUrl) {
        return <Avatar userId={userId} size={size} />;
    }
    return (
        <Image
            source={{ uri: imageUrl }}
            style={[{ width: size, height: size }, styles.image]}
        />
    );
};

export default OtherUserAvatar;
