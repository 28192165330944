import * as React from 'react';
import { Image } from 'react-native';

import { Pet } from '../../Data/Models';
import { truncate } from '../../helpers/helpers';
import useThemedStyles from '../../hooks/useThemedStyles';
import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    pet: Pet;
    title?: string;
}

const NFTIcon = ({ pet, title }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={styles.container}>
            <View style={styles.imageWrapper}>
                <Image
                    source={{ uri: pet.image.thumbnailUrl }}
                    style={styles.image}
                    resizeMode="contain"
                />
            </View>

            <Text style={styles.title}>
                {title ? title : truncate(pet.name)}
            </Text>
        </View>
    );
};
export default NFTIcon;
