const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';
const black = '#000';

export declare type IconColorType =
    | 'primary'
    | 'green'
    | 'onDark'
    | 'onDarkSecondary'
    | 'apple'
    | 'secondary'
    | 'warning'
    | 'white';

const lightTheme = {
    alwaysOneColor: {
        background: '#FFFFFF',
        selectableBackground: '#FAFFFD',
        shadow: '#DDDFE3',
        border: '#ECECEF',
        text: '#393C46',
        textSecondary: '#73788C',
        shadowSelected: '#C3EFE0',
        borderSelected: '#40BF95',
    },
    notificationBackground: '#E87373',
    modalLine: '#9DA0AF',
    background: '#fff',
    backgroundOuter: '#fafafa',
    backgroundWarning: '#FFF9EB',
    backgroundEnergy: 'rgba(240, 210, 66, 0.2)',
    backgroundSelectable: '#FAFFFD',
    backgroundPrimary: '#ECECEF',
    backgroundCritical: '#FFF5F5',
    backgroundSuccess: '#F1FAF0',
    backGroundWhiteList: 'rgba(176, 168, 240, 0.2)',
    backGroundPriceMysteryBox: 'rgba(159, 223, 202, 0.2)',
    backgroundSurfaceStatusSuccess: 'rgba(147, 241, 131, 0.1)',
    backgroundSurfaceStatusCritical: 'rgba(255, 163, 163, 0.1)',

    tint: tintColorLight,
    tabIconDefault: '#ccc',
    iconHeart: '#E64D4D',
    tabIconSelected: tintColorLight,
    backgroundPlayTimer: 'rgba(35, 36, 42, 0.8)',

    buttonBackground2: '#dbfefa',
    main: '#ecfefe',
    incorrect: '#E23636',
    mintStageActive: '#01B47C',
    mintStageDefault: '#CCCCCC',
    controlSecondary: '#F9FAFA',
    surfaceDecorativeGrayMinimal: 'rgba(146, 198, 236, 0.2)',
    surfaceDecorativeYellowMedium: 'rgba(238, 176, 0, 0.2)',

    marketplace: {
        price: {
            textIGU: '#4B8F9B',
            backgroundIGU: '#E9F8FB',
        },
    },

    text: {
        primary: '#393C46',
        secondary: '#73788C',
        accent: '#40BF95',
        critical: '#E54D4D',
        warning: '#C28C00',
        danger: '#E64D4D',
        onDark: '#000000',
        onLight: '#ffffff',
        completed: '#439834',
        whiteList: '#816AFB',
        gray: '#5D7989',
        white: '#ffffff',
        incorrect: '#E23636',
        bybit: '#F4AF5C',
    },
    icon: {
        primary: '#40434F',
        green: '#40BF95',
        onDark: '#393C46',
        onDarkSecondary: '#40434F',
        apple: '#FFFFFF',
        secondary: '#818598',
        warning: '#E64D4D',
        decorativePrimary: '#B9BBC6',
        accent: '#40BF95',
        critical: '#E96363',
        white: '#fff',
    },
    surface: {
        tertiary: '#FCFCFD',
        highlighted: '#40BF95',
        danger: '#DD888C',
    },
    border: {
        primary: '#ECECEF',
        accent: '#40BF95',
        critical: '#E54D4D',
        white: '#ECECEF',
        successMinimal: '#98D88D',
        criticalMinimal: '#F6BCBC',
    },
    shadow: {
        selected: '#C3EFE0',
        default: '#DEDFE3',
        white: '#DDDFE3',
    },

    // buttons
    buttonText: '#393C46',
    buttonTextDisabled: '#8F93A3',

    buttonBackground: '#BDFFE9',
    buttonShadow: '#86DFC2',
    buttonBorder: '#BDFFE9',
    buttonBackgroundPress: '#99FFDD',
    buttonBackgroundDisabled: '#EBFFF8',
    buttonShadowDisabled: '#C3EFE0',

    buttonBackgroundOutline: '#FFFFFF',
    buttonBorderOutline: '#ECECEF',
    buttonShadowOutline: '#DDDFE3',
    buttonBackgroundPressOutline: '#F4F4F6',
    buttonBackgroundOutlineDisabled: '#FFFFFF',
    buttonShadowDisabledOutline: '#E9E9ED',

    buttonBackgroundCritical: '#FFBDBD',
    buttonBorderCritical: '#FFBDBD',
    buttonShadowCritical: '#E69999',
    buttonBackgroundPressCritical: '#FFADAD',
    buttonBackgroundCriticalDisabled: '#FFEBEB',
    buttonShadowDisabledCritical: '#F5D6D6',
    buttonTextCriticalDisabled: '#818598',

    buttonBackgroundSuccess: '#C6F4BE',
    buttonBorderSuccess: '#C6F4BE',
    buttonShadowSuccess: '#98D98C',
    buttonBackgroundPressSuccess: '#B2F0A8',
    buttonBackgroundSuccessDisabled: '#E0F9DC',
    buttonShadowDisabledSuccess: '#CBECC6',
    buttonTextSuccessDisabled: '#818598',

    buttonTextTexted: '#40BF95',
    buttonTextTextedPress: '#39AC86',
    buttonTextTextedDisabled: '#8F93A3',

    bulletActive: '#47C299',
    bullet: '#EEEFF1',
    bulletOnColor: 'rgba(255, 255, 255, 0.4)',

    inputBackground: '#FCFCFD',
    inputPlaceHolder: '#9A9A9A',
    inputActiveBorder: '#40BF95',

    stageIndicatorDefault: '#E9E9ED',
    stageIndicatorActive: '#8CC982',
    petCardIdBorderColor: '#000',
    levelIndicatorBackGround: '#E9E9ED',
    levelIndicatorFillColor: '#40BF95',
    healthIndicatorFillColor: '#8CC982',

    switchOn: '#65C454',

    petRankItem: {
        backgroundDefault: '#fff',
        borderColorDefault: '#E3E4E8',
        shadowColorDefault: '#DEDFE3',
        backgroundSelected: '#FAFFFD',
        borderColorSelected: '#40BF95',
        shadowColorSelected: '#C3EFE0',
        divider: '#E3E4E8',
    },
    modal: {
        modalOverlay: '#1F222E',
        background: '#fff',
        shadowColor: '#DEDFE3',
        qrScannerOverlay: '#181B25',
        opacity: 0.7,
    },
    navigationBar: {
        background: '#EBFFF8',
        elementsBackground: '#fff',
        elementsBorder: '#ECECEF',
        shadowColor: '#DDDFE3',
        accent: '#40BF95',
    },
    divider: '#ECECEF',
    circle: '#E3E4E8',
    purchasingFrom: {
        backgroundDefault: '#fff',
        borderColorDefault: '#E3E4E8',
        shadowColorDefault: '#DEDFE3',
        backgroundSelected: '#FAFFFD',
        borderColorSelected: '#40BF95',
        shadowColorSelected: '#C3EFE0',
    },
    mintPriceBackground: {
        mintPriceContainer: 'rgba(238, 176, 0, 0.2)',
        mintPrice: '#B78504',
    },
    statutes: {
        confirmed: {
            background: '#F1FAF0',
            text: '#439834',
        },
        failed: {
            background: '#FFF5F5',
            text: '#E54D4D',
        },
        pending: {
            background: '#FFF9EB',
            text: '#C28C00',
        },
        default: {
            background: 'rgba(146, 198, 236, 0.20)',
            text: '#5D7989',
        },
    },
    radio: {
        backgroundDefault: '#FCFCFD',
        borderDefault: '#E3E4E8',
        borderActive: '#40BF95',
        backgroundActive: '#40BF95',
    },
    energyProgress: {
        full: '#8CC982',
        high: '#E7C66F',
        low: '#E87373',
        background: '#E9E9ED',
    },
    levelProgress: {
        text: {
            level: '#C28C00',
            full: '#8CC982',
        },
        full: '#40BF95',
        background: '#E9E9ED',
        low: '#73788C',
    },
    avatarBackground: {
        purple: 'rgba(176, 168, 240, 0.2)',
        blue: 'rgba(146, 198, 236, 0.2)',
        green: 'rgba(188, 209, 148, 0.2)',
        orange: 'rgba(233, 179, 98, 0.2)',
        yellow: 'rgba(240, 210, 66, 0.2)',
    },
    taskHistoryBg: {
        moveTask: 'rgba(176, 168, 240, 0.2)',
        playTask: 'rgba(240, 210, 66, 0.2)',
        socialTask: 'rgba(233, 179, 98, 0.2)',
        verificationTask: 'rgba(146, 198, 236, 0.2)',
    },
    staking: {
        standard: '#6685FF',
        medium: '#816AFB',
        high: '#C28C00',
    },
    gradient: {
        start: '#EBF4FE',
        end: '#C4DEFD',
    },
    checkBox: {
        enabled: {
            background: '#FAFFFD',
            border: '#40BF95',
        },
        disabled: {
            background: '#F9FAFA',
            border: '#E3E4E8',
        },
    },
    petCardId: {
        border: '#ECECEF',
        imgCircle: '#fff',
        background: {
            onGray: '#fff',
            opacity: 'rgba(255, 255, 255, 0.15)',
            opacityOnGray: '#F2F2F2',
        },

        text: {
            default: '#fff',
            onGray: '#393C46',
            accent: '#40BF95',
        },
    },
    maxBoost: {
        background: 'rgba(147, 241, 131, 0.2)',
        text: '#439834',
    },

    backgroundPrimaryMinimal: 'rgba(34, 36, 42, 0.2)',

    surfaceDecorative_Blue_Strong: '#8099FF',
    datavizProgressWeak: '#E87373',
    fillPrimaryMinimal: 'rgba(34, 36, 42, 0.20)',
    surfaceDecorativeDarkMinimal: 'rgba(0, 0, 0, 0.15)',
    surfaceDecorativeAccentMinimal: 'rgba(159, 223, 202, 0.20)',
    surfaceDecorativeCriticalMinimal: 'rgba(255, 163, 163, 0.20)',
};

const darkTheme = {
    alwaysOneColor: {
        background: '#FFFFFF',
        selectableBackground: '#FAFFFD',
        shadow: '#DDDFE3',
        border: '#ECECEF',
        text: '#393C46',
        textSecondary: '#73788C',
        shadowSelected: '#C3EFE0',
        borderSelected: '#40BF95',
    },
    backgroundOpacity: 'rgba(255, 255, 255, 0.2)',
    notificationBackground: '#E87373',
    modalLine: '#9DA0AF',
    background: '#23242A',
    backgroundOuter: '#0e0e0e',
    backgroundWarning: '#F7DF6E33',
    backgroundEnergy: 'rgba(247, 232, 161, 0.2)',
    backgroundSelectable: '#2E3038',
    backgroundPrimary: '#393C46',
    backgroundCritical: '#AF6A6A33',
    backgroundSuccess: '#343E32',
    backGroundWhiteList: 'rgba(168, 158, 250, 0.2)',
    backGroundPriceMysteryBox: 'rgba(117, 240, 199, 0.2)',
    tint: tintColorLight,
    tabIconDefault: '#ccc',
    iconHeart: '#E64D4D',
    tabIconSelected: tintColorLight,
    backgroundPlayTimer: 'rgba(35, 36, 42, 0.8)',
    backgroundSurfaceStatusSuccess: 'rgba(167, 214, 159, 0.1)',
    backgroundSurfaceStatusCritical: 'rgba(227, 191, 191, 0.1)',
    buttonBackground2: '#dbfefa',
    main: '#ecfefe',
    incorrect: '#E96363',
    mintStageActive: '#01B47C',
    mintStageDefault: '#CCCCCC',
    controlSecondary: '#2E3038',
    surfaceDecorativeGrayMinimal: 'rgba(146, 198, 236, 0.2)',

    text: {
        primary: '#F4F4F6',
        secondary: '#ABAEBA',
        accent: '#39AC86',
        critical: '#EA6C6C',
        warning: '#FFC01A',
        danger: '#E96363',
        onDark: '#000000',
        onLight: '#ffffff',
        completed: '#76CB67',
        whiteList: '#AB9CFC',
        gray: '#D1DBE0',
        white: '#ffffff',
        incorrect: '#E96363',
        bybit: '#F4AF5C',
    },
    icon: {
        primary: '#F4F4F6',
        green: '#39AC86',
        onDark: '#535766',
        onDarkSecondary: '#40434F',
        apple: '#27292F',
        secondary: '#8F93A3',
        warning: '#EA6C6C',
        decorativePrimary: '#B9BBC6',
        accent: '#40BF95',
        critical: '#E96363',
        white: '#fff',
    },
    surface: {
        tertiary: '#353741',
        highlighted: '#39AC86',
        danger: '#DD888C',
    },
    border: {
        primary: '#393C46',
        accent: '#39AC86',
        critical: '#EF8F8F',
        white: '#ECECEF',
        successMinimal: '#376B2E',
        criticalMinimal: '#8F3D3D',
    },
    shadow: {
        selected: '#1F604B',
        default: '#454854',
        white: '#DDDFE3',
    },

    // buttons
    buttonText: '#F4F4F6',
    buttonTextDisabled: '#73788C',

    buttonBackground: '#00D18C',
    buttonShadow: '#056143',
    buttonBorder: '#00D18C',
    buttonBackgroundPress: '#00C282',
    buttonBackgroundDisabled: '#393C46',
    buttonShadowDisabled: '#454854',

    buttonBackgroundOutline: '#23242A',
    buttonBorderOutline: '#393C46',
    buttonShadowOutline: '#393C46',
    buttonBackgroundPressOutline: '#2E3038',
    buttonBackgroundOutlineDisabled: '#393C46',
    buttonShadowDisabledOutline: '#454854',

    buttonBackgroundCritical: '#FF9999',
    buttonBorderCritical: '#FF9999',
    buttonShadowCritical: '#BD7575',
    buttonBackgroundPressCritical: '#FF8A8A',
    buttonBackgroundCriticalDisabled: '#393C46',
    buttonShadowDisabledCritical: '#515462',
    buttonTextCriticalDisabled: '#818598',

    buttonBackgroundSuccess: '#B3F0A8',
    buttonBorderSuccess: '#B3F0A8',
    buttonShadowSuccess: '#83B87A',
    buttonBackgroundPressSuccess: '#9FEC93',
    buttonBackgroundSuccessDisabled: '#393C46',
    buttonShadowDisabledSuccess: '#515462',
    buttonTextSuccessDisabled: '#818598',

    buttonTextTexted: '#39AC86',
    buttonTextTextedPress: '#339977',
    buttonTextTextedDisabled: '#73788C',

    bulletActive: '#47C299',
    bullet: '#505462',
    bulletOnColor: 'rgba(255, 255, 255, 0.4)',

    inputBackground: '#2E3038',
    inputPlaceHolder: '#9A9A9A',
    inputActiveBorder: '#39AC86',

    stageIndicatorDefault: '#454854',
    stageIndicatorActive: '#8CC982',
    petCardIdBorderColor: '#000',
    levelIndicatorBackGround: '#454854',
    levelIndicatorFillColor: '#40BF95',
    healthIndicatorFillColor: '#8CC982',

    switchOn: '#65C454',

    petRankItem: {
        backgroundDefault: '#23242A',
        borderColorDefault: '#40434F',
        shadowColorDefault: '#454854',
        backgroundSelected: '#2E3038',
        borderColorSelected: '#39AC86',
        shadowColorSelected: '056143',
        divider: '#393C46',
    },
    modal: {
        modalOverlay: '#17181C',
        background: '#23242A',
        shadowColor: '#454854',
        qrScannerOverlay: '#181B25',
        opacity: 0.85,
    },
    navigationBar: {
        background: '#2E3038',
        elementsBackground: '#23242A',
        elementsBorder: '#393C46',
        shadowColor: '#454854',
        accent: '#40BF95',
    },
    divider: '#393C46',
    circle: '#505462',
    purchasingFrom: {
        backgroundDefault: '#23242A',
        borderColorDefault: '#505462',
        shadowColorDefault: '#454854',
        backgroundSelected: '#2E3038',
        borderColorSelected: '#39AC86',
        shadowColorSelected: '#056143',
    },
    mintPriceBackground: {
        mintPriceContainer: 'rgba(166, 139, 13, 0.2)',
        mintPrice: '#B78504',
    },
    statutes: {
        confirmed: {
            background: '#343E32',
            text: '#76CB67',
        },
        failed: {
            background: '#AF6A6A33',
            text: '#EA6C6C',
        },
        pending: {
            background: '#343E32',
            text: '#FFC01A',
        },
        default: {
            background: 'rgba(146, 198, 236, 0.20)',
            text: '#FFFFFF',
        },
    },
    radio: {
        backgroundDefault: '#FCFCFD',
        borderDefault: '#393C46',
        borderActive: '#39AC86',
        backgroundActive: '#39AC86',
    },
    energyProgress: {
        full: '#8CC982',
        high: '#E7C66F',
        low: '#E87373',
        background: '#454854',
    },
    levelProgress: {
        text: {
            level: '#FFC01A',
            full: '#8CC982',
        },
        full: '#39AC86',
        background: '#454854',
        low: '#ABAEBA',
    },
    avatarBackground: {
        purple: 'rgba(168, 158, 250, 0.2)',
        blue: 'rgba(110, 189, 247, 0.2)',
        green: 'rgba(190, 217, 140, 0.2)',
        orange: 'rgba(247, 192, 110, 0.2)',
        yellow: 'rgba(142, 119, 11, 0.2)',
    },
    taskHistoryBg: {
        moveTask: 'rgba(168, 158, 250, 0.2)',
        playTask: 'rgba(142, 119, 11, 0.2)',
        socialTask: 'rgba(247, 192, 110, 0.2)',
        verificationTask: 'rgba(110, 189, 247, 0.2)',
    },
    staking: {
        standard: '#6685FF',
        medium: '#816AFB',
        high: '#C28C00',
    },
    gradient: {
        start: '#6988AB',
        end: '#334784',
    },
    checkBox: {
        enabled: {
            background: '#FAFFFD',
            border: '#40BF95',
        },
        disabled: {
            background: '#F9FAFA',
            border: '#E3E4E8',
        },
    },
    petCardId: {
        border: '#ECECEF',
        imgCircle: '#fff',
        background: {
            onGray: '#fff',
            opacity: 'rgba(255, 255, 255, 0.15)',
            opacityOnGray: '#F2F2F2',
        },

        text: {
            default: '#fff',
            onGray: '#393C46',
            accent: '#40BF95',
        },
    },
    maxBoost: {
        background: 'rgba(147, 241, 131, 0.2)',
        text: '#439834',
    },
    backgroundPrimaryMinimal: 'rgba(34, 36, 42, 0.2)',
    surfaceDecorative_Blue_Strong: '#8099FF',
    surfaceDecorativeYellowMedium: 'rgba(238, 176, 0, 0.2)',
    marketplace: {
        price: {
            textIGU: '#5CC4D6',
            backgroundIGU: '#2F4346',
        },
    },
    datavizProgressWeak: '#E87373',
    fillPrimaryMinimal: 'rgba(34, 36, 42, 0.20)',
    surfaceDecorativeDarkMinimal: 'rgba(0, 0, 0, 0.15)',
    surfaceDecorativeAccentMinimal: 'rgba(117, 240, 199, 0.20)',
    surfaceDecorativeCriticalMinimal: 'rgba(227, 191, 191, 0.20)',
};

const Colors = {
    light: lightTheme,
    // currently no dark theme
    dark: darkTheme,
};

export default Colors;
