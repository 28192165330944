import * as React from 'react';
import { Text, View } from 'react-native';

import useThemedStyles from '../../hooks/useThemedStyles';
import Icon, { ICON_NAMES } from '../Icons';
import stylesMain from './styles';

interface IProps {
    healthPercentage: number;
    badge?: boolean;
    showMaxHealth?: boolean;
    isOneColor?: boolean;
}

const HealthIndicator = ({
    healthPercentage,
    badge,
    showMaxHealth = true,
    isOneColor,
}: IProps) => {
    const styles = useThemedStyles(stylesMain, isOneColor);
    const maxHealth = 100;

    const isMaxHealth = healthPercentage >= 100;
    const lowHealth = healthPercentage <= 30;

    return (
        <>
            {badge ? (
                <View style={styles.badgeWrapper}>
                    <Icon name={ICON_NAMES.HEALTH} size={20} />
                    <Text style={styles.badgeText}>{`${healthPercentage}${
                        showMaxHealth ? ` / ${maxHealth}` : ''
                    }`}</Text>
                </View>
            ) : (
                <View style={styles.wrapper}>
                    <View style={styles.container}>
                        <View
                            style={[
                                styles.fill,
                                {
                                    width: `${
                                        isMaxHealth ? 100 : healthPercentage
                                    }%`,
                                },
                                lowHealth && styles.lowHealth,
                            ]}></View>
                    </View>
                    <Text
                        style={[
                            styles.level,
                            styles.healthCurrent,
                            !isMaxHealth && styles.noMaxHealth,
                        ]}>
                        {healthPercentage}
                        <Text
                            style={[
                                styles.healthMax,
                            ]}>{` / ${maxHealth}`}</Text>
                    </Text>
                </View>
            )}
        </>
    );
};
export default HealthIndicator;
