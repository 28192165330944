import * as React from 'react';
import { useEffect } from 'react';
import { TouchableOpacity } from 'react-native';
import Modal, { ModalProps } from 'react-native-modal/dist/modal';
import { FullWindowOverlay } from 'react-native-screens';

import { useDimensions } from '@contexts/DimensionsContext';
import { useModal } from '@contexts/ModalContext';
import { Portal } from '@gorhom/portal';
import { isIOS, useNativeDriver } from '@helpers/app';
import { convertHexToRGBA } from '@helpers/style';

import useThemedStyles from '../../hooks/useThemedStyles';
import useDisableBodyScroll from '../../web-src/hooks/useDisableBodyScroll';
import { View } from '../Themed';
import ToastNotification from '../ToastNotification';
import stylesMain from './styles';

interface IProps extends Partial<ModalProps> {
    children: React.ReactNode;
    isVisible: boolean;
    isNestedModal?: boolean;
    ignoreGlobalModal?: boolean;
    useFullScreenOverlay?: boolean;
    withToasts?: boolean;
    horizontalModal?: boolean;
}

const ExtendedModal = ({
    children,
    isVisible,
    isNestedModal = false,
    ignoreGlobalModal = false,
    useFullScreenOverlay,
    withToasts = false,
    horizontalModal = false,
    ...props
}: IProps) => {
    useDisableBodyScroll(!horizontalModal ? isVisible : false);
    const styles = useThemedStyles(stylesMain);
    const { dimensions } = useDimensions();
    const { setIsVisible: setIsGlobalModalVisible } = useModal();

    useEffect(() => {
        if (isIOS && !ignoreGlobalModal) {
            setIsGlobalModalVisible(isVisible);
        }
    }, [isVisible, ignoreGlobalModal]);

    if (isIOS && !isVisible) {
        return null;
    }

    if (isIOS && useFullScreenOverlay) {
        return (
            <Portal>
                <FullWindowOverlay style={styles.fullScreenOverlay}>
                    <View pointerEvents="box-none" style={[styles.wrapper]}>
                        <TouchableOpacity
                            style={[
                                styles.wrapper,
                                props.backdropColor
                                    ? {
                                          backgroundColor: convertHexToRGBA(
                                              props.backdropColor,
                                              props.backdropOpacity || 0.7
                                          ),
                                      }
                                    : {},
                                props.style,
                            ]}
                            activeOpacity={1}
                            onPress={() => {
                                if (props.onModalHide) {
                                    props.onModalHide();
                                }
                            }}>
                            <TouchableOpacity activeOpacity={1}>
                                <View style={styles.content}>{children}</View>
                            </TouchableOpacity>
                        </TouchableOpacity>
                    </View>
                    {withToasts && <ToastNotification />}
                </FullWindowOverlay>
            </Portal>
        );
    }

    return (
        <Modal
            isVisible={isVisible}
            {...props}
            useNativeDriver={useNativeDriver}
            useNativeDriverForBackdrop={useNativeDriver}
            deviceWidth={dimensions.screen.deviceWidth}
            deviceHeight={dimensions.screen.deviceHeight}
            hideModalContentWhileAnimating>
            {children}
            {withToasts && <ToastNotification />}
        </Modal>
    );
};

export default ExtendedModal;
