import { StyleSheet } from 'react-native';

import Colors from '../../../constants/Colors';
import { scale, verticalScale } from '../../../helpers/dimensions';
import font from '../../../helpers/font';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
        },
        listStyle: {
            paddingHorizontal: scale(20),
        },
        headerText: {
            paddingHorizontal: 0,
            marginBottom: verticalScale(12),
        },
        rankInfoWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: verticalScale(20),
        },
        rankInfo: {
            color: Colors[theme].text.accent,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
            letterSpacing: 0.08,
            marginLeft: scale(4),
        },
    });

export default styles;
