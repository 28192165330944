import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import { scale, verticalScale } from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        statusIcon: {
            position: 'absolute',
            bottom: verticalScale(-4),
            right: scale(-6),
            backgroundColor: 'transparent',
        },

        left: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        right: {
            flex: 1,
            marginLeft: verticalScale(12),
        },

        itemContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: verticalScale(12),
        },
        label: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '600',
            }),
            marginBottom: verticalScale(2),
        },
        bottom: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        dateText: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 12,
                height: 16,
                weight: '400',
            }),
        },
        bscScan: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 12,
                height: 16,
                weight: '700',
            }),
        },
        icon: {},
    });

export default styles;
