import * as React from 'react';

import Arabic from '@assets/icons/flags/ab.svg';
import Bangladesh from '@assets/icons/flags/bg.svg';
import China from '@assets/icons/flags/cn.svg';
import English from '@assets/icons/flags/en.svg';
import Spain from '@assets/icons/flags/es.svg';
import France from '@assets/icons/flags/fr.svg';
import Indonesia from '@assets/icons/flags/id.svg';
import India from '@assets/icons/flags/in.svg';
import Japan from '@assets/icons/flags/jp.svg';
import Korea from '@assets/icons/flags/kr.svg';
import Poland from '@assets/icons/flags/pl.svg';
import Philippines from '@assets/icons/flags/pp.svg';
import Portugal from '@assets/icons/flags/pt.svg';
import Russia from '@assets/icons/flags/ru.svg';
import Turkey from '@assets/icons/flags/tr.svg';
import Ukraine from '@assets/icons/flags/uk.svg';
import Vietnam from '@assets/icons/flags/vt.svg';

import { IconType, iconSize } from '../types';

interface IProps extends IconType {
    slug: string;
}

const FlagIcons = ({ slug, size = iconSize.primary }: IProps) => {
    switch (slug) {
        case 'ab':
            return <Arabic width={size} height={size} />;
        case 'bg':
            return <Bangladesh width={size} height={size} />;
        case 'cn':
            return <China width={size} height={size} />;
        case 'en':
            return <English width={size} height={size} />;
        case 'id':
            return <Indonesia width={size} height={size} />;
        case 'in':
            return <India width={size} height={size} />;
        case 'jp':
            return <Japan width={size} height={size} />;
        case 'kr':
            return <Korea width={size} height={size} />;
        case 'pl':
            return <Poland width={size} height={size} />;
        case 'pp':
            return <Philippines width={size} height={size} />;
        case 'ru':
            return <Russia width={size} height={size} />;
        case 'tr':
            return <Turkey width={size} height={size} />;
        case 'uk':
            return <Ukraine width={size} height={size} />;
        case 'vt':
            return <Vietnam width={size} height={size} />;
        case 'es':
            return <Spain width={size} height={size} />;
        case 'fr':
            return <France width={size} height={size} />;
        case 'pt':
            return <Portugal width={size} height={size} />;
        default:
            Console.error(`No coin icon with slug ${slug} is available`);
            return null;
    }
};

export default FlagIcons;
