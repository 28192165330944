import * as React from 'react';

import LS_L from '@assets/icons/mysteryGames/navigationIcons/ls/left.svg';
import LS_R from '@assets/icons/mysteryGames/navigationIcons/ls/right.svg';
import ML_L from '@assets/icons/mysteryGames/navigationIcons/ml/left.svg';
import ML_R from '@assets/icons/mysteryGames/navigationIcons/ml/right.svg';
import NE_L from '@assets/icons/mysteryGames/navigationIcons/ne/left.svg';
import NE_R from '@assets/icons/mysteryGames/navigationIcons/ne/right.svg';
import NR_L from '@assets/icons/mysteryGames/navigationIcons/nr/left.svg';
import NR_R from '@assets/icons/mysteryGames/navigationIcons/nr/right.svg';
import QS_L from '@assets/icons/mysteryGames/navigationIcons/qs/left.svg';
import QS_R from '@assets/icons/mysteryGames/navigationIcons/qs/right.svg';
import TT_L from '@assets/icons/mysteryGames/navigationIcons/tt/left.svg';
import TT_R from '@assets/icons/mysteryGames/navigationIcons/tt/right.svg';
import { MYSTERY_GAMES } from '@models/mysteryGames';

import { IconType } from '../types';

interface IProps extends IconType {
    slug: MYSTERY_GAMES;
    type: 'left' | 'right';
}

const GamesNavigationIcon = ({ slug, type }: IProps) => {
    switch (slug) {
        case MYSTERY_GAMES.mysteryLikes:
            return type === 'right' ? <ML_R /> : <ML_L />;

        case MYSTERY_GAMES.nftEvolution:
            return type === 'right' ? <NE_R /> : <NE_L />;
        case MYSTERY_GAMES.nftRoyal:
            return type === 'right' ? <NR_R /> : <NR_L />;
        case MYSTERY_GAMES.luckyStrike:
            return type === 'right' ? <LS_R /> : <LS_L />;
        case MYSTERY_GAMES.quantumSpin:
            return type === 'right' ? <QS_R /> : <QS_L />;
        case MYSTERY_GAMES.tokenteller:
            return type === 'right' ? <TT_R /> : <TT_L />;

        default:
            return null;
    }
};

export default GamesNavigationIcon;
