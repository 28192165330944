import { ethers } from 'ethers';

import { MoveTaskResponseItem } from '@Data/Models';
import { OperationVariables } from '@apollo/client/core/types';

export const formatDistanceTraveled = (
    distanceTraveled: number,
    shouldDivide?: boolean
) => {
    if (shouldDivide) {
        return (distanceTraveled / 1000).toFixed(2);
    }

    return distanceTraveled.toFixed(2);
};

export const moveTaskDoneParameters = (
    id: string,
    remainingTime: number,
    moveTask: MoveTaskResponseItem,
    distanceTravelled: number
): OperationVariables => {
    const elapsedTimeSeconds = Math.floor(
        (moveTask.maxTimeSeconds || 0) - remainingTime
    );
    const traveledDistanceMeters = Math.floor(distanceTravelled * 1000);
    const signatureString = `${id}${moveTask.id}${elapsedTimeSeconds}${traveledDistanceMeters}`;
    const bytes = new TextEncoder().encode(signatureString);
    const signature = ethers.utils.sha256(bytes).split('0x')[1];
    return {
        taskId: moveTask.id,
        elapsedTimeSeconds,
        traveledDistanceMeters,
        signature,
    };
};

export const calculateAverageSpeed = (speedHistory: number[]) => {
    const length = speedHistory.length;

    if (!length) {
        return 0;
    }
    return (speedHistory.reduce((a, b) => a + b) / length).toFixed(2);
};

export const calculateEnergy = (
    maxEnergyReward: number,
    maxDistanceMeters: number,
    distanceTraveled: number
) => {
    const rewardForOnePercent = maxEnergyReward / 100;
    const maxDistanceKilometers = maxDistanceMeters / 1000;

    const distanceTraveledInPercents =
        (distanceTraveled / maxDistanceKilometers) * 100;

    const energy = Math.round(rewardForOnePercent * distanceTraveledInPercents);
    return energy <= maxEnergyReward ? energy : maxEnergyReward;
};

export const countSteps = (distanceTraveled: number, count: number) => {
    return Math.round((distanceTraveled * 1000) / count);
};
