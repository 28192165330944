import { Wallet, ethers } from 'ethers';

import {
    Config,
    ExchangeRatesOutput,
    PaymentOptionOutput,
    SignedTransactionOutput,
} from '../../Data/Models';
import { igu } from '../../Data/erc20.abi';
import { igup } from '../../Data/igup.abi';
import { mysteryBox } from '../../Data/mysteryBox.abi';
import { abi } from '../../Data/nft.abi';
import {
    BalanceNumber,
    IBalance,
    balanceFromWei,
    getGasPrice,
    numberToString,
} from '../../helpers/wallet';

export interface IWalletBalances {
    bnb: IBalance;
    busd: IBalance;
    igu: IBalance;
    igup: IBalance;
    nftsCount: number;
    tokens: string[];
}

export type WalletData = {
    wallet: Wallet;
};

export enum Coin {
    bnb = 'BNB',
    busd = 'BUSD',
    igu = 'IGU',
    igup = 'IGUP',
    iguai = 'IGUAI',
    vigup = 'VIGUP',
    vigu = 'VIGU',
    crd = 'VIGU', // TODO CHANGE ON CRD
    iguGold = 'IGU_GOLD',
    usdt = 'USDT',
}

export const REWARD_UNLOCK_PERCENT = 1;

export type NFTBalance = {
    name: string;
    value: number;
    tokens: string[];
    contractAddress: string;
};

export type CoinBalance = {
    name: Coin;
    value: string;
    presentationValueLong: string;
    presentationValueShort: string;
    valueDollars: string;
    contractAddress: string | null;
    exchangeRate: string | undefined;
};

export type WalletBalanceTokens = 'bnb' | 'busd' | 'igu' | 'igup';

export type WalletBalance = {
    bnb: CoinBalance;
    busd: CoinBalance;
    igu: CoinBalance;
    igup: CoinBalance;
    nft: NFTBalance;
};

export const mapBalances = (
    balances: IWalletBalances,
    rates: ExchangeRatesOutput,
    config: Config
): WalletBalance => {
    const bnbBalance = balances.bnb;
    const busdBalance = balances.busd;
    const iguBalance = balances.igu;
    const igupBalance = balances.igup;

    return {
        bnb: {
            name: Coin.bnb,
            value: bnbBalance.value,
            presentationValueLong: bnbBalance.valueLong,
            presentationValueShort: bnbBalance.valueShort,
            valueDollars: calculateExchangeRate(bnbBalance.value, rates.bnbusd),
            contractAddress: null,
            exchangeRate: rates.bnbusd,
        },
        busd: {
            name: Coin.busd,
            value: busdBalance.value,
            presentationValueLong: busdBalance.valueLong,
            presentationValueShort: busdBalance.valueShort,
            valueDollars: calculateExchangeRate(
                busdBalance.value,
                rates.busdusd
            ),
            contractAddress: config?.busdContractAddress,
            exchangeRate: rates.busdusd,
        },
        igu: {
            name: Coin.igu,
            value: iguBalance.value,
            presentationValueLong: iguBalance.valueLong.toString(),
            presentationValueShort: iguBalance.valueShort,
            valueDollars: calculateExchangeRate(iguBalance.value, rates.iguusd),
            contractAddress: config.iguTokenContractAddress,
            exchangeRate: rates.iguusd,
        },
        igup: {
            name: Coin.igup,
            value: igupBalance.value,
            presentationValueLong: igupBalance.valueLong.toString(),
            presentationValueShort: igupBalance.valueShort,
            valueDollars: calculateExchangeRate(
                igupBalance.value,
                rates.igupusd
            ),
            contractAddress: config.igupContractAddress,
            exchangeRate: rates.igupusd,
        },
        nft: {
            name: 'NFTS',
            value: balances.nftsCount,
            tokens: balances.tokens,
            contractAddress: config.nftIguverseContractAddress,
        },
    };
};

export const getMaxValue = (
    balance: CoinBalance | undefined,
    maxEstimatedFee?: string
) => {
    if (balance?.name === 'BNB' && maxEstimatedFee) {
        const value = ethers.utils
            .parseEther(balance.value)
            .sub(ethers.utils.parseEther(maxEstimatedFee));
        const formatted = balanceFromWei(value);

        return Number(formatted.valueLong) <= 0
            ? '0'
            : formatted.valueLong.toString() || '0';
    }

    return balance?.presentationValueLong || '0';
};

export const calculateExchangeRate = (
    value: BalanceNumber,
    rate: BalanceNumber
) => {
    const numberValue = Number(numberToString(value));
    const numberRate = Number(numberToString(rate));

    if (!rate) {
        return '0.0';
    }

    const amount = numberValue * numberRate;
    return amount.toFixed(amount < 0.01 ? 3 : 2);
};

export const sendTransaction = async (
    wallet: Wallet,
    config: Config,
    coin: string,
    to: string,
    amount: string
): Promise<ethers.providers.TransactionResponse> => {
    const parsedValue = ethers.utils.parseEther(amount);
    const gasPrice = await getGasPrice(wallet, config.gasPriceConfig);
    const transactionOverrides = { gasPrice };

    switch (coin) {
        case 'BNB':
            return wallet.sendTransaction({
                from: wallet.address,
                to,
                value: parsedValue,
                gasPrice,
            });

        case 'BUSD':
            return new ethers.Contract(
                config.busdContractAddress,
                igu,
                wallet
            ).transfer(to, parsedValue, transactionOverrides);

        case 'IGU':
            return new ethers.Contract(
                config.iguTokenContractAddress,
                igu,
                wallet
            ).transfer(to, parsedValue, transactionOverrides);

        case 'IGUP':
            return new ethers.Contract(
                config.igupContractAddress,
                igu,
                wallet
            ).transfer(to, parsedValue, transactionOverrides);
        default:
            throw `Undefined coin ${coin}`;
    }
};

export const approveAndExecuteTransaction = async (
    payment: PaymentOptionOutput,
    signedTransaction: SignedTransactionOutput,
    config: Config,
    wallet: Wallet,
    confirmations: number,
    isMysteryBox?: boolean
): Promise<any> => {
    const gasPrice = await getGasPrice(wallet, config.gasPriceConfig);
    const transactionOverrides = { gasPrice };
    const transaction = handleTransactionOverrides(
        signedTransaction.execTransactionValuesStringified,
        gasPrice
    );

    // IGU approve
    if (payment.token === Coin.igu) {
        const iguContract = new ethers.Contract(
            config.iguTokenContractAddress,
            igu,
            wallet
        );
        const approveResult = await iguContract.approve(
            signedTransaction.contractAddress,
            payment.amountWei,
            transactionOverrides
        );
        await approveResult.wait(confirmations);
        // IGUP approve
    } else if (payment.token === Coin.igup) {
        const iguContract = new ethers.Contract(
            config.igupContractAddress,
            igup,
            wallet
        );
        const approveResult = await iguContract.approve(
            signedTransaction.contractAddress,
            payment.amountWei,
            transactionOverrides
        );
        await approveResult.wait(config.mintConfirmationsCount);
    }

    let result;
    if (isMysteryBox) {
        const mysteryBoxContract = new ethers.Contract(
            config.lootboxContractAddress,
            mysteryBox,
            wallet
        );
        result = await mysteryBoxContract.getToken(...transaction);
    } else {
        const nftContract = new ethers.Contract(
            config.nftIguverseContractAddress,
            abi,
            wallet
        );
        result = await nftContract.execTransaction(...transaction);
    }

    return result;
};

// Take the last array element from transactio and if it is an object - add/rewrite gasPrice into it,
// if it is null replace it with an object with {gasPrice},
// if it is not an object then add {gasPrice} object as the last array element.
export function handleTransactionOverrides(
    signedTransaction: string,
    gasPrice: string
) {
    const transaction = JSON.parse(signedTransaction);

    const lastElement = transaction[transaction.length - 1];

    if (lastElement !== null && typeof lastElement === 'object') {
        lastElement.gasPrice = gasPrice;
    } else if (lastElement === null) {
        transaction[transaction.length - 1] = { gasPrice };
    } else {
        transaction.push({ gasPrice });
    }

    return transaction;
}
