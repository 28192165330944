import { useCallback, useMemo } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { CREATE_NFT_ROYAL_GAME } from '@Data/Requests';
import { useMutation } from '@apollo/client';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { errorsHandler } from '@helpers/errors';
import { isGraphqlError } from '@helpers/helpers';
import { priceString } from '@helpers/payments';
import { toastErrorUnknown } from '@helpers/toastNotification';
import {
    balanceFromWei,
    inputNumberFormatter,
    isBiggerThanMax,
    valueToWei,
} from '@helpers/wallet';
import { useCreateMysteryLikeGame } from '@hooks/useCreateMysteryLikeGame';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import { NftRoyalCreateGameResponse } from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { useFocusEffect } from '@react-navigation/native';

import { toastBanned } from '../../../helpers';

export const useNftRoyalEnterCoinsAmountScreen = () => {
    const navigation = useNavigation();

    const {
        availableBalance,
        nftRoyaleGameConfig,
        setTopUpFromMysteryGames,
        getGamingAvailableBalance,
        availableBalanceLoading,
        nftRoyalGameConfiguration,
        clearNftRoyalGameConfiguration,
        setNftRoyalCurrentGame,
    } = useMysteryGames();

    const [createNftRoyalGame, { loading: createNftRoyalGameLoading }] =
        useMutation<NftRoyalCreateGameResponse>(CREATE_NFT_ROYAL_GAME);

    useFocusEffect(
        useCallback(() => {
            setTopUpFromMysteryGames(false);
            getGamingAvailableBalance(true);
        }, [])
    );

    const minAmount = useMemo(
        () => balanceFromWei(nftRoyaleGameConfig?.minBetIguAmountWei).value,
        [nftRoyaleGameConfig?.minBetIguAmountWei]
    );

    const maxAmount = useMemo(
        () => balanceFromWei(nftRoyaleGameConfig?.maxBetIguAmountWei).value,
        [nftRoyaleGameConfig?.maxBetIguAmountWei]
    );

    const max = useMemo(
        () => balanceFromWei(availableBalance?.available).value,
        [availableBalance?.available]
    );

    const schema = Yup.object({
        amount: Yup.string()
            .transform((_, value) => {
                if (value && value.includes('.')) {
                    return value;
                }
                return value && value.replace(/,/, '.');
            })
            .matches(
                /^\d*(\.\d+)?$/,
                i18n.t('HomeScreen.EnterCoinsAmountScreen.error.digits')
            )
            .required(i18n.t('withdrawScreen.errors.amountRequired'))
            .test(
                'max',
                i18n.t('HomeScreen.EnterCoinsAmountScreen.error.max'),
                (value) => Number(value) <= Number(max)
            )
            .test(
                'maxAmount',
                i18n.t('HomeScreen.EnterCoinsAmountScreen.error.maxStake', {
                    amount: priceString(maxAmount, Coin.vigu),
                }),
                (value) => Number(value) <= Number(maxAmount)
            )
            .test(
                'minAmount',
                i18n.t('HomeScreen.EnterCoinsAmountScreen.error.minStake', {
                    amount: priceString(minAmount, Coin.vigu),
                }),
                (value) => Number(value) >= Number(minAmount)
            ),
    });

    const {
        isVisible: confirmationModalVisible,
        open: openConfirmationModal,
        close: closeConfirmationModal,
    } = useVisibleHook();

    const createGame = () => {
        openConfirmationModal();
    };

    const {
        submitForm,
        values,
        errors,
        setFieldValue,
        setTouched,
        touched,
        isValid,
        dirty,
    } = useFormik({
        initialValues: {
            amount: '',
        },
        validationSchema: schema,
        onSubmit: () => {
            createGame();
        },
        enableReinitialize: true,
    });

    const handleChangeAmount = async (amount: string) => {
        setTouched({ amount: false });

        const convertedText = inputNumberFormatter(amount);
        if (!isBiggerThanMax(convertedText)) {
            await setFieldValue('amount', convertedText);
        }
    };

    const handleClickAll = useCallback(async () => {
        if (Number(max) < Number(maxAmount)) {
            await setFieldValue('amount', max);
            return;
        }
        await setFieldValue('amount', maxAmount);
    }, [maxAmount, max]);

    const handleOnBlur = () => {
        setTouched({ amount: true });
    };

    const handleDeposit = () => {
        setTopUpFromMysteryGames(true);
        navigation.navigate(ROUTES.WALLET_ROOT, {
            screen: ROUTES.WALLET_GAMING_TAB,
            params: {
                screen: ROUTES.TOP_UP_GAMING_WALLET,
            },
        });
    };

    const handleOnConfirm = useCallback(
        async (amount: string) => {
            if (!nftRoyalGameConfiguration) {
                toastErrorUnknown();
                return;
            }
            try {
                const res = await createNftRoyalGame({
                    variables: {
                        input: {
                            gameComplexity:
                                nftRoyalGameConfiguration.complexity,
                            gameLiveCount: nftRoyalGameConfiguration.livesCount,
                            gameMode: nftRoyalGameConfiguration.mode,
                            iguAmountWei: valueToWei(amount),
                        },
                    },
                });

                if (res.data) {
                    setNftRoyalCurrentGame({
                        ...res.data.nftRoyaleGameCreate,
                        gameValue: amount,
                    });

                    navigation.pop();
                    navigation.replace(ROUTES.NFT_ROYAL_CREATE_GAME_SUCCESS);
                }
            } catch (error) {
                if (isGraphqlError(error, 'NFTROYALE_BANNED')) {
                    navigation.pop();
                    navigation.pop();
                    toastBanned();
                    return;
                }
                errorsHandler(error, true);
            }
        },
        [nftRoyalGameConfiguration]
    );

    const formattedValue = useMemo(() => values.amount.slice(0, 11), [values]);

    return {
        submitForm,
        values,
        errors,
        setFieldValue,
        setTouched,
        touched,
        isValid,
        dirty,
        handleChangeAmount,
        handleClickAll,
        handleOnBlur,
        maxAmount,
        handleDeposit,
        confirmationModalVisible,
        closeConfirmationModal,
        handleOnConfirm,
        createNftRoyalGameLoading,
        availableBalanceLoading,
        nftRoyaleGameConfig,
        max,
        formattedValue,
    };
};
