import * as React from 'react';
import { TouchableOpacity } from 'react-native';
import Animated from 'react-native-reanimated';
import Svg, { ForeignObject, G, Path } from 'react-native-svg';

import ButtonIcon from '@assets/icons/mysteryGames/QuantumSpin/button.svg';
import PinIcon from '@assets/icons/mysteryGames/QuantumSpin/pin.svg';
import WheelIcon from '@assets/icons/mysteryGames/QuantumSpin/wheel.svg';
import AnimatedTextLoading from '@components/AnimatedTextLoading';
import CoinIcon from '@components/Icons/CoinIcon';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT, TYPO_TEXT_COLOR } from '@components/TypoText/types';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { isWebIos } from '@helpers/app';
import { priceString } from '@helpers/payments';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import {
    MYSTERY_GAMES,
    QuantumSpinGamePrizeOutput,
} from '@models/mysteryGames';

import { useQuantumSpinWheel } from '../../hooks/useQuantumSpinWheel';
import QuantumSpinConfirmPlayModal from '../QuantumSpinConfirmPlayModal';
import QuantumSpinPrizeAnimation from '../QuantumSpinPrizeAnimation';
import stylesMain from './styles';
import WheelWrapper from './wrapper';

const AnimatedSvg = Animated.createAnimatedComponent(Svg);

interface IProps {
    isWheelAnimation: boolean;
    setIsWheelAnimation: React.Dispatch<React.SetStateAction<boolean>>;
    prizes: QuantumSpinGamePrizeOutput[];
    spinAmount: string;
}

const QuantumSpinWheel = ({
    isWheelAnimation,
    setIsWheelAnimation,
    prizes,
    spinAmount,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const {
        animatedStyles,
        leftSpace,
        innerWidth,
        winPrize,
        closePrizeAnimation,
        purchaseLoading,
        onClick,
        clickDisabled,
        confirmationModal,
        onPurchaseConfirm,
        handleCloseConfirmModal,
        width,
        wheelConfig,
        lengthOfData,
        angleOffset,
        prizeAnimation,
        makeWheel,
        handleOpenModalScreen,
        getHeight,
        newSizes,
        isNarrowWeb,
    } = useQuantumSpinWheel(prizes, setIsWheelAnimation);

    const renderPrize = (
        x: number,
        y: number,
        item: QuantumSpinGamePrizeOutput,
        rotateZ: number
    ) => (
        <ForeignObject x={x - 28} y={y - 28} width={56} height={56}>
            <View
                style={[
                    styles.prize,
                    { transform: [{ rotateZ: `${rotateZ}deg` }] },
                ]}>
                <CoinIcon slug={Coin.vigu} size={24} />

                <TypoText
                    allowFontScaling={false}
                    numberOfLines={1}
                    type={TYPO_TEXT.SUBHEAD_LITTLE_BOLD}
                    colorType={TYPO_TEXT_COLOR.ON_DARK}>
                    {balanceFromWei(item.iguAmountWei).valueShort}
                </TypoText>
            </View>
        </ForeignObject>
    );

    return (
        <WheelWrapper onLayout={getHeight} isNarrowWeb={isNarrowWeb}>
            <View
                style={[
                    styles.container,
                    {
                        width,
                        left: leftSpace,
                        transform: [{ scale: newSizes.scale }],
                        top: newSizes.top,
                    },
                ]}>
                <TouchableOpacity
                    disabled={isWheelAnimation || clickDisabled}
                    style={styles.buttonWrapper}
                    activeOpacity={0.9}
                    onPress={onClick}>
                    <View style={styles.buttonIcon}>
                        <ButtonIcon width={179} height={179} />
                    </View>
                    <View style={styles.texts}>
                        {isWheelAnimation ? (
                            <AnimatedTextLoading
                                isLoading={true}
                                staticText={i18n.t(
                                    `HomeScreen.${MYSTERY_GAMES.quantumSpin}.keys.spinning`
                                )}
                                loadingText={'...'}
                                backgroundColor={'#EDF6FC'}
                                textStyle={styles.spinning}
                            />
                        ) : (
                            <>
                                <TypoText
                                    numberOfLines={1}
                                    allowFontScaling={false}
                                    type={TYPO_TEXT.HEADING_H4}
                                    additionalStyles={styles.textColor}>
                                    {i18n.t(
                                        `HomeScreen.${MYSTERY_GAMES.quantumSpin}.keys.spin`
                                    )}
                                </TypoText>
                                <View style={styles.amountWrapper}>
                                    <CoinIcon slug={Coin.vigu} size={20} />
                                    <TypoText
                                        allowFontScaling={false}
                                        numberOfLines={1}
                                        type={TYPO_TEXT.SUBHEAD_LITTLE_BOLD}
                                        additionalStyles={styles.amountText}>
                                        {priceString(
                                            balanceFromWei(spinAmount)
                                                .valueShort,
                                            Coin.vigu
                                        )}
                                    </TypoText>
                                </View>
                            </>
                        )}
                    </View>
                </TouchableOpacity>

                <View style={styles.wheelBorder}>
                    <View style={styles.pin}>
                        <PinIcon />
                    </View>
                    <WheelIcon width={width} height={width} />
                </View>

                <Animated.View style={[styles.innerWheel, animatedStyles]}>
                    <AnimatedSvg
                        width={innerWidth}
                        height={innerWidth}
                        viewBox={`-${innerWidth / 2} -${
                            innerWidth / 2
                        } ${innerWidth} ${innerWidth}`}>
                        <G y={innerWidth / 2} x={innerWidth / 2}>
                            {makeWheel(prizes, 0, innerWidth / 2, 0).map(
                                (arc, i) => {
                                    return (
                                        <G key={`arc-${i}`}>
                                            <Path
                                                d={arc.path}
                                                strokeWidth={2}
                                                fill={arc.color}
                                            />
                                        </G>
                                    );
                                }
                            )}
                            {makeWheel(
                                prizes,
                                0.1,
                                (innerWidth - wheelConfig.outerRadius) / 2,
                                100,
                                true
                            ).map((arc, i) => {
                                const [x, y] = arc.centroid;

                                const rX = isWebIos ? x + innerWidth / 2 : x;
                                const rY = isWebIos ? y + innerWidth / 2 : y;
                                return (
                                    <G key={`arc-${i}`}>
                                        <Path
                                            d={arc.path}
                                            strokeWidth={2}
                                            fill={arc.color}
                                        />

                                        {renderPrize(
                                            rX,
                                            rY,
                                            arc.value,
                                            (i * wheelConfig.wheelRotation) /
                                                lengthOfData +
                                                angleOffset
                                        )}
                                    </G>
                                );
                            })}
                        </G>
                    </AnimatedSvg>
                </Animated.View>
                <QuantumSpinConfirmPlayModal
                    onClose={handleCloseConfirmModal}
                    isVisible={confirmationModal}
                    spinAmount={spinAmount}
                    onPurchaseConfirm={onPurchaseConfirm}
                    isLoading={purchaseLoading}
                    handleOpenModalScreen={handleOpenModalScreen}
                />

                {prizeAnimation && winPrize && (
                    <QuantumSpinPrizeAnimation
                        isVisible={prizeAnimation}
                        data={winPrize}
                        onClose={closePrizeAnimation}
                    />
                )}
            </View>
        </WheelWrapper>
    );
};

export default QuantumSpinWheel;
