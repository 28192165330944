import * as React from 'react';
import { TouchableOpacity } from 'react-native';
import Animated, {
    cancelAnimation,
    useAnimatedStyle,
    useSharedValue,
    withTiming,
} from 'react-native-reanimated';

import GamesNotificationsIcon from '@components/Icons/GamesNotificationsIcon';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT, TYPO_TEXT_COLOR } from '@components/TypoText/types';
import { useDimensions } from '@contexts/DimensionsContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { isWeb } from '@helpers/app';
import { priceString } from '@helpers/payments';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import {
    MYSTERY_GAMES,
    MysteryLikesGameLastGameOutput,
} from '@models/mysteryGames';

import stylesMain from './styles';

interface IProps {
    item: MysteryLikesGameLastGameOutput | undefined | null;
    onPress: () => void;
}

const config = {
    speed: 13000,
    delay: 200,
};

const SocialProofItem = ({ item, onPress }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { windowWidth: startPosition } = useDimensions();

    const itemGameId: MYSTERY_GAMES = MYSTERY_GAMES.mysteryLikes; // tmp

    const [lineWidth, setLineWidth] = React.useState<number>(0);

    const line = useSharedValue<number>(startPosition);

    const lineStyle = useAnimatedStyle(() => {
        return {
            transform: [{ translateX: line.value }],
        };
    });

    const amount = React.useMemo(
        () =>
            priceString(
                balanceFromWei(item?.iguAmountWei).valueShort,
                Coin.vigu
            ),
        [item?.iguAmountWei]
    );

    const [element, setElement] = React.useState<React.ReactElement | null>(
        null
    );

    const generateLine = React.useCallback(() => {
        return (
            <>
                <GamesNotificationsIcon slug={itemGameId} />
                <TypoText
                    allowFontScaling={false}
                    textAlign="auto"
                    additionalStyles={styles.textGap}
                    type={TYPO_TEXT.SUBHEAD_MEDIUM_BOLD}
                    colorType={TYPO_TEXT_COLOR.ON_DARK}>
                    {`${i18n.t('HomeScreen.GamesBlock.games.socialProof', {
                        amount,
                        game: i18n.t(
                            `HomeScreen.GamesBlock.games.${itemGameId}.title`
                        ),
                    })}`}
                </TypoText>
            </>
        );
    }, [amount, itemGameId]);

    const [isFirstLoad, setIsFirstLoad] = React.useState<boolean>(true);

    const animate = React.useCallback(() => {
        line.value = startPosition;
        line.value = withTiming(-lineWidth, {
            duration: config.speed,
        });
    }, [lineWidth]);

    React.useEffect(() => {
        if (!item) {
            return;
        }
        if (isFirstLoad) {
            setElement(generateLine());
            if (lineWidth) {
                animate();
                setIsFirstLoad(false);
            }
        }
    }, [isFirstLoad, item, generateLine, animate, lineWidth]);

    React.useEffect(() => {
        if (!isFirstLoad) {
            const interval = setInterval(() => {
                setElement(generateLine());
                animate();
            }, config.speed);

            return () => clearInterval(interval);
        }
    }, [generateLine, animate, isFirstLoad]);

    React.useEffect(() => {
        return () => cancelAnimation(line);
    }, []);

    return (
        <View style={styles.container}>
            <View style={styles.empty} />
            <View style={styles.lineBlock}>
                <Animated.View style={lineStyle}>
                    <TouchableOpacity
                        onPress={onPress}
                        activeOpacity={0.8}
                        style={[
                            styles.line,
                            { width: lineWidth + (isWeb ? 30 : 0) },
                        ]}>
                        {element}
                    </TouchableOpacity>
                </Animated.View>
            </View>
            <View style={styles.hiddenBlock}>
                <View
                    style={styles.hidden}
                    onLayout={({ nativeEvent }) =>
                        setLineWidth(nativeEvent.layout.width)
                    }>
                    {element}
                </View>
            </View>
        </View>
    );
};
export default SocialProofItem;
