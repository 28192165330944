import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';

import { theme } from '../../types';
import { TYPO_TEXT_COLOR } from './types';

const styles = (theme: theme) =>
    StyleSheet.create({
        [TYPO_TEXT_COLOR.PRIMARY]: {
            color: Colors[theme].text.primary,
        },
        [TYPO_TEXT_COLOR.SECONDARY]: {
            color: Colors[theme].text.secondary,
        },
        [TYPO_TEXT_COLOR.ACCENT]: {
            color: Colors[theme].text.accent,
        },
        [TYPO_TEXT_COLOR.ON_DARK]: {
            color: Colors[theme].text.white,
        },
        [TYPO_TEXT_COLOR.INCORRECT]: {
            color: Colors[theme].text.incorrect,
        },
        [TYPO_TEXT_COLOR.GRAY]: {
            color: Colors[theme].text.gray,
        },
    });

export default styles;
