import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        animation: {
            width: 200,
            height: 200,
        },
        modalContainer: {
            flex: 1,
            paddingTop: verticalScale(32),
            paddingHorizontal: verticalScale(20),
            alignItems: 'center',
        },
        titleModal: {
            textAlign: 'center',
            marginTop: verticalScale(24),
            color: Colors[theme].text.primary,
            ...font({
                size: 22,
                height: 30,
                weight: '800',
            }),
        },
        petName: {
            color: Colors[theme].text.primary,
        },
        highlighted: {
            color: Colors[theme].text.accent,
        },
        subTitleModal: {
            marginTop: verticalScale(8),
            textAlign: 'center',
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
        },
        containerLevelModal: {
            marginTop: verticalScale(24),
            width: '100%',
        },
        buttonContainerModal: {
            paddingHorizontal: scale(20),
            paddingVertical: verticalScale(16),
        },
        featureWrapper: {
            width: '100%',
            marginVertical: verticalScale(8),
        },
        gap: {
            marginTop: verticalScale(12),
        },
        accent: {
            color: Colors[theme].text.accent,
        },
    });

export default styles;
