import * as React from 'react';
import { useMemo } from 'react';

import ListFilter from '@components/ListFilter';
import { Text, View } from '@components/Themed';
import { gamingWalletTransactionsFilterConfig } from '@hooks/useGamingWalletTransactionsHistory';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import {
    ACTIVE_ORDER_LIST,
    activeOrderFilterConfig,
} from '@models/mysteryGames';

import stylesMain from './styles';

interface IProps {
    onSelect: (item: string) => void;
    selectedItem: ACTIVE_ORDER_LIST;
}

const ActiveOrderFilter = ({ onSelect, selectedItem }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const filterData = useMemo(() => {
        return [
            {
                key: activeOrderFilterConfig.filters[0],
                label: i18n.t(
                    `HomeScreen.ActiveOrdersFilters.${activeOrderFilterConfig.filters[0]}`
                ),
            },
            {
                key: activeOrderFilterConfig.filters[1],
                label: i18n.t(
                    `HomeScreen.ActiveOrdersFilters.${activeOrderFilterConfig.filters[1]}`
                ),
            },
            {
                key: activeOrderFilterConfig.filters[2],
                label: i18n.t(
                    `HomeScreen.ActiveOrdersFilters.${activeOrderFilterConfig.filters[2]}`
                ),
            },
            {
                key: activeOrderFilterConfig.filters[3],
                label: i18n.t(
                    `HomeScreen.ActiveOrdersFilters.${activeOrderFilterConfig.filters[3]}`
                ),
            },
        ];
    }, [i18n, gamingWalletTransactionsFilterConfig]);

    return (
        <View style={styles.headerText}>
            <Text style={styles.headerTitle}>
                {i18n.t('HomeScreen.ActiveOrdersFilters.title')}
            </Text>

            <ListFilter
                titleText={i18n.t('HomeScreen.ActiveOrdersFilters.modalTitle')}
                onSelect={onSelect}
                selectedItem={selectedItem}
                data={filterData}
            />
        </View>
    );
};
export default ActiveOrderFilter;
