import * as React from 'react';
import { ReactNode } from 'react';
import { Image } from 'react-native';

import { useDimensions } from '../../contexts/DimensionsContext';
import useThemedStyles from '../../hooks/useThemedStyles';
import i18n from '../../i18n/i18n';
import ModalBottom from '../ModalBottom';
import { View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    image: string | undefined | null;
    title?: string;
    text?: string;
    button?: ReactNode;
    onClose: () => void;
}

function ModalNFTPreview({
    isVisible,
    image,
    title,
    text,
    onClose,
    button,
}: IProps) {
    const styles = useThemedStyles(stylesMain);
    const { windowWidth, windowHeight } = useDimensions();

    return (
        <ModalBottom
            isVisible={isVisible}
            onClose={onClose}
            modalHeight="700"
            titleText={title || i18n.t('modal.nftPreview.title')}
            additionalText={text || i18n.t('modal.nftPreview.text')}>
            <View style={styles.bottomWrapper}>
                {!!image && (
                    <Image
                        style={[
                            styles.image,
                            {
                                width: windowWidth - styles.imageGap.width,
                                height: windowWidth - styles.imageGap.width,
                            },
                        ]}
                        source={{ uri: image }}
                    />
                )}
                {button}
            </View>
        </ModalBottom>
    );
}

export default ModalNFTPreview;
