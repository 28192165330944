import { useCallback, useMemo, useState } from 'react';

import { useDimensions } from '@contexts/DimensionsContext';
import { useStaking } from '@contexts/StakingContext';
import { isPlanDisabled } from '@helpers/staking';
import { toastErrorUnknown } from '@helpers/toastNotification';
import useNavigationListeners from '@navigation/hooks/useNavigationListeners';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

export const useStakingDetailedInfoScreen = () => {
    useNavigationListeners();
    const [snapItem, setSnapItem] = useState(0);
    const { setCurrentPlan, stakingPlans, currentStakingPlan, isIguDrop } =
        useStaking();
    const { windowWidth } = useDimensions();
    const navigation = useNavigation();

    const handleSelectPlan = useCallback(() => {
        if (!stakingPlans) {
            toastErrorUnknown();
            return;
        }

        setCurrentPlan(stakingPlans[snapItem]);
        navigation.navigate(ROUTES.STAKING_PLANS);
    }, [snapItem, stakingPlans]);

    const isDisabled = useMemo(() => {
        if (!stakingPlans) {
            return true;
        }
        return isPlanDisabled(stakingPlans[snapItem], currentStakingPlan);
    }, [snapItem, currentStakingPlan]);

    return {
        windowWidth,
        navigation,
        setSnapItem,
        snapItem,
        stakingPlans,
        currentStakingPlan,
        handleSelectPlan,
        isDisabled,
        isIguDrop,
    };
};
