import * as React from 'react';
import { Linking, SafeAreaView, Text, View } from 'react-native';

import { BarCodeScanner } from 'expo-barcode-scanner';
import { Camera } from 'expo-camera';
import { StatusBar } from 'expo-status-bar';

import Colors from '../../constants/Colors';
import { useDimensions } from '../../contexts/DimensionsContext';
import { useTheme } from '../../contexts/ThemeContext';
import { isAndroid, isDesktop, isWeb } from '../../helpers/app';
import { stylesFlex } from '../../helpers/style';
import useThemedStyles from '../../hooks/useThemedStyles';
import i18n from '../../i18n/i18n';
import NavigationBarModal from '../../navigation/NavigationBar/NavigationBarModal';
import Button from '../Button';
import ExtendedModal from '../ExtendedModal';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
    onScanned: (scanned: string) => void;
    title: string;
    titleScanned: string;
    text: string;
}

const barCodeTypes: string[] = [BarCodeScanner.Constants.BarCodeType.qr];

const QrCodeScanner: React.FunctionComponent<IProps> = ({
    isVisible,
    onClose,
    onScanned,
    title,
    titleScanned,
    text,
}) => {
    const styles = useThemedStyles(stylesMain);
    const { windowWidth } = useDimensions();
    const { theme } = useTheme();

    const [hasPermission, setHasPermission] = React.useState<boolean | null>(
        null
    );
    const [scanned, setScanned] = React.useState<boolean>(false);

    const handleOpenSettings = () => {
        Linking.openSettings();
    };

    const handleBarCodeScanned = ({ data }: { data: string }) => {
        setScanned(true);
        setTimeout(() => onScanned(data), 1000);
    };

    React.useEffect(() => {
        if (!isVisible) {
            return;
        }
        setScanned(false);

        const getBarCodeScannerPermissions = async () => {
            if (isWeb) {
                setHasPermission(true);
                return;
            }

            const { status } = await BarCodeScanner.requestPermissionsAsync();

            setHasPermission(status === 'granted');
        };

        getBarCodeScannerPermissions();
    }, [isVisible]);

    return (
        <ExtendedModal
            isVisible={isVisible}
            backdropColor={Colors[theme].modal.qrScannerOverlay}
            backdropOpacity={1}
            animationIn="slideInRight"
            animationOut="slideOutRight"
            style={styles.modal}>
            <SafeAreaView style={stylesFlex()}>
                <StatusBar style="light" />
                <View style={styles.container}>
                    <NavigationBarModal onBack={onClose} />
                    {hasPermission === false ? (
                        <View style={styles.errorContainer}>
                            <Text style={styles.title}>
                                {i18n.t('qrCodeScanner.noAccessToCamera')}
                            </Text>
                            <Button
                                onPress={handleOpenSettings}
                                type="outline"
                                containerStyle={styles.button}
                                title={i18n.t('qrCodeScanner.openSettings')}
                            />
                        </View>
                    ) : (
                        <View style={styles.wrapper}>
                            <View
                                style={[
                                    styles.scannerWrapper,
                                    scanned && styles.scannerScanned,
                                ]}>
                                {!!hasPermission && (
                                    <Camera
                                        ratio="1:1"
                                        onBarCodeScanned={
                                            scanned
                                                ? undefined
                                                : handleBarCodeScanned
                                        }
                                        style={[
                                            styles.scanner,
                                            isAndroid && {
                                                ...styles.android,
                                                height: windowWidth,
                                            },
                                        ]}
                                        barCodeScannerSettings={{
                                            barCodeTypes,
                                        }}
                                    />
                                )}
                            </View>

                            <Text style={styles.title}>
                                {scanned ? titleScanned : title}
                            </Text>
                            <Text style={styles.text}>{text}</Text>
                        </View>
                    )}
                </View>
            </SafeAreaView>
        </ExtendedModal>
    );
};

export default QrCodeScanner;
