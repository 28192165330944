import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        shadow: {
            backgroundColor: Colors[theme].shadow.default,
            borderRadius: 1000,
            marginLeft: scale(6),
            marginBottom: verticalScale(12),
        },
        isLast: {
            marginRight: 0,
        },
        shadowSelected: {
            backgroundColor: Colors[theme].shadow.selected,
        },
        container: {
            borderRadius: 1000,
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(6),
            marginBottom: 2,
        },
        containerSelected: {
            borderColor: Colors[theme].border.accent,
        },
        title: {
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
            color: Colors[theme].text.primary,
        },
        additionalTitle: {
            color: Colors[theme].text.secondary,
        },
    });

export default styles;
