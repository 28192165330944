import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import AgeIndicator from '@components/AgeIndicator';
import HealthIndicator from '@components/HealthIndicator';
import RankIcon from '@components/Icons/RankIcon';
import ImageExtended from '@components/ImageExtended';
import LevelIndicator from '@components/LevelIndicator';
import { Text, View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import { MarketplaceSaleablePetOutput } from '@models/marketplace';

import stylesMain from './styles';

interface IProps {
    pet: MarketplaceSaleablePetOutput;
    onPress: (pet: MarketplaceSaleablePetOutput) => void;
    isSelected: boolean;
}

const MarketPlaceSellablePetCard = ({ pet, onPress, isSelected }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <TouchableOpacity
            activeOpacity={0.8}
            style={[styles.shadow, isSelected && styles.selectedShadow]}
            onPress={() => onPress(pet)}>
            <View
                style={[
                    styles.container,
                    isSelected && styles.selectedContainer,
                ]}>
                <View style={styles.imgWrapper}>
                    <ImageExtended uri={pet?.imageUrl} borderRadius={6} />
                    <View style={styles.rankWrapper}>
                        <RankIcon size={24} slug={pet.rank} />
                    </View>
                </View>

                <View style={styles.textWrapper}>
                    <Text
                        style={styles.name}
                        numberOfLines={1}
                        ellipsizeMode="tail">
                        {pet.name}
                    </Text>
                    <LevelIndicator
                        isOneColor
                        level={pet.level}
                        fillAmount={pet.levelProgressPercentage}
                    />
                    <View style={styles.footer}>
                        <HealthIndicator
                            isOneColor
                            healthPercentage={pet.healthPercentage}
                            badge
                            showMaxHealth={false}
                        />
                        <AgeIndicator
                            isOneColor
                            ageDays={pet.ageDays}
                            maxAgeDays={pet.maxAgeDays}
                            containerStyle={styles.ageWrapper}
                        />
                    </View>
                </View>
            </View>
        </TouchableOpacity>
    );
};
export default MarketPlaceSellablePetCard;
