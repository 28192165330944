import { StyleSheet } from 'react-native';

import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import Colors from '../../../../constants/Colors';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {},
        container: {
            paddingHorizontal: scale(20),
            marginTop: verticalScale(20),
        },
        image: {
            alignItems: 'center',
            marginTop: verticalScale(12),
            marginBottom: verticalScale(24),
        },
        user: {
            paddingHorizontal: scale(20),
            marginTop: verticalScale(20),
        },
        gap: {
            marginTop: verticalScale(16),
        },
    });

export default styles;
