import { StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../../helpers/dimensions';
import font from '../../../../helpers/font';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        tipsTitle: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
        },
        circle: {
            width: scale(6),
            height: scale(6),
            borderRadius: 1000,
            backgroundColor: Colors[theme].circle,
            marginRight: scale(6),
        },
        top: {
            top: verticalScale(7),
        },
        tipWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        noAlign: {
            alignItems: 'flex-start',
        },
        tipGap: {
            marginBottom: verticalScale(12),
        },
        tipsText: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
        imageWrapper: {
            marginTop: verticalScale(12),
            marginBottom: verticalScale(16),
            flexDirection: 'row',
        },
        imageContainer: {
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            backgroundColor: Colors[theme].surface.tertiary,
            borderRadius: moderateScale(8),
            minHeight: scale(80),
            minWidth: scale(80),
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
        },
        imageBlock: { flex: 1, alignItems: 'center' },
        imageText: {
            color: Colors[theme].text.primary,
            ...font({
                size: 11,
                height: 16,
                weight: '600',
            }),
        },
        imageGap: {
            marginRight: scale(8),
        },
        outOfContainer: {
            right: scale(-16),
        },
        tipInfo: {
            top: verticalScale(-10),
            backgroundColor: 'transparent',
        },
    });

export default styles;
