import * as React from 'react';

import { RewardsTransactionHistoryResponseItem } from '@Data/Models';
import ButtonKnowledgeBase from '@components/ButtonKnowledgeBase';
import StatusIcon from '@components/Icons/StatusIcon';
import InfoIcon from '@components/InfoIcon/InfoIcon';
import { Text, View } from '@components/Themed';
import TransactionSummary from '@components/TransactionSummary';
import { Links } from '@constants/Links';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import { IRewardsTransactionDetails } from '../../../../../types';
import { MemoizedRewardsTransactionHistoryItem } from '../RewardsTransactionHistoryItem';
import stylesMain from './styles';

interface IProps {
    transaction: IRewardsTransactionDetails;
}
interface IRenderItem {
    item: RewardsTransactionHistoryResponseItem;
}

const RewardsTransactionDetailsContent = ({ transaction }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const {
        type,
        status,
        value,
        color,
        date,
        unlockedValue,
        childTransactions,
        total,
        totalUnlocked,
    } = transaction;
    const coinName = Coin.vigup;

    const navigation = useNavigation();

    const textInfo = React.useMemo(() => {
        if (status === 'CONFIRMED') {
            return i18n.t(`wallet.transactions.reward.${type}.text`, {
                defaultValue: i18n.t('wallet.transactions.reward.UNKNOWN.text'),
            });
        }

        return i18n.t(`wallet.transactions.reward.${status}.text`, {
            defaultValue: i18n.t('wallet.transactions.reward.UNKNOWN.text'),
        });
    }, [status, type]);

    const isPayment = [
        'PetLevelUpPayment',
        'PetRankUpPayment',
        'PetRevivePayment',
        'PetHealUpPayment',
    ].includes(type);
    const skipWallet = ['CharityDonation'].includes(type);

    const transactionSummary = [
        {
            key: i18n.t('wallet.type'),
            value: i18n.t(`wallet.transactions.reward.${type}.title`, {
                defaultValue: i18n.t('wallet.transactions.reward.UNKNOWN.name'),
            }),
        },
        {
            key: i18n.t('wallet.date'),
            value: date,
        },
        !skipWallet
            ? isPayment
                ? {
                      key: i18n.t('wallet.fromAddress'),
                      value:
                          type === 'Claim'
                              ? i18n.t('wallet.rewards.mainWallet')
                              : i18n.t('wallet.rewards.rewardsWallet'),
                  }
                : {
                      key: i18n.t('wallet.toAddress'),
                      value:
                          type === 'Claim'
                              ? i18n.t('wallet.rewards.mainWallet')
                              : i18n.t('wallet.rewards.rewardsWallet'),
                  }
            : undefined,
    ];

    const renderTransactionValue = () => {
        return (
            <Text style={[styles.textPrice, { color }]}>{`${
                status === 'UNLOCK' ? unlockedValue || value : value
            } ${coinName}`}</Text>
        );
    };

    const renderItem = React.useCallback(({ item }: IRenderItem) => {
        return (
            <MemoizedRewardsTransactionHistoryItem
                item={item}
                lastPendingClaimId={undefined}
                showUnlockDate
                disabledOpacity={!!item.locked}
            />
        );
    }, []);

    const isUnlockStatus = React.useMemo(
        () => status === 'UNLOCK' && !!childTransactions?.length,
        [status, childTransactions]
    );

    const statusText = React.useMemo(() => {
        if (isUnlockStatus) {
            const amount = (
                Number(balanceFromWei(total).value) -
                Number(balanceFromWei(totalUnlocked).value)
            ).toFixed(2);

            return i18n.t(`wallet.transactions.reward.${status}.locked`, {
                amount,
                coin: coinName,
            });
        }
        return i18n.t(`wallet.transactions.reward.${status}.title`);
    }, [status, isUnlockStatus]);

    const handleLockedDetails = () => {
        navigation.navigate(ROUTES.HOW_TO_AVAILABLE_REWARDS);
    };

    return (
        <>
            <View style={styles.top}>
                <View style={[styles.statusWrapper, styles[status]]}>
                    <StatusIcon slug={status} />
                    <Text style={[styles.statusText, styles[status]]}>
                        {statusText}
                    </Text>
                    {isUnlockStatus && (
                        <InfoIcon
                            containerStyle={styles.info}
                            iconColor={styles[status].color}
                            onPress={handleLockedDetails}
                        />
                    )}
                </View>
                <Text style={styles.textAmount}>{i18n.t('wallet.amount')}</Text>
                {renderTransactionValue()}
                <Text style={styles.textInfo}>{textInfo}</Text>
                <ButtonKnowledgeBase
                    customStyles={styles.knowledgebase}
                    url={Links.rewardFormula}
                />
            </View>
            <View style={styles.bottom}>
                <TransactionSummary
                    containerStyle={styles.transactionSummary}
                    values={transactionSummary}
                />
            </View>
            {/* {!!childTransactions && (
                <View style={styles.unlockTransactions}>
                    <FlatList
                        ListHeaderComponent={
                            <Text style={styles.unlockTitle}>
                                {i18n.t(
                                    `wallet.transactions.reward.unlockTransactions.title`
                                )}
                            </Text>
                        }
                        showsVerticalScrollIndicator={false}
                        data={[...childTransactions].sort(sortByDateReverse)}
                        keyExtractor={(item) => item.id}
                        renderItem={renderItem}
                    />
                </View>
            )} */}
        </>
    );
};

export default RewardsTransactionDetailsContent;
