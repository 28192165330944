import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../../types';

const stylesMain = (theme: theme) =>
    StyleSheet.create({
        webview: {
            border: 0,
            flex: 1,
            marginBottom: verticalScale(26),
            backgroundColor: Colors[theme].background,
        },
    });

export default stylesMain;
