import * as React from 'react';
import { BackHandler } from 'react-native';

import { useFocusEffect, useNavigation } from '@react-navigation/native';

interface IProps {
    setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
    data: any;
    autoSkip?: boolean;
}

export const useHandleNavigationBack = ({
    setModalVisible,
    data,
    autoSkip = false,
}: IProps) => {
    const navigation = useNavigation();

    const [canGoBack, setCanGoBack] = React.useState<boolean>(false);

    useFocusEffect(
        React.useCallback(() => {
            const onBackPress = () => {
                if (autoSkip) {
                    return false;
                }
                if (!data) {
                    return false;
                }
                setModalVisible(true);
                return true;
            };

            const subscription = BackHandler.addEventListener(
                'hardwareBackPress',
                onBackPress
            );

            return () => subscription.remove();
        }, [])
    );
    React.useEffect(
        () =>
            navigation.addListener('beforeRemove', (e) => {
                if (autoSkip) {
                    return;
                }
                if (canGoBack) {
                    return;
                }
                if (!data) {
                    return;
                }
                setModalVisible(true);
                setCanGoBack(true);
                e.preventDefault();
            }),
        [navigation, data, canGoBack, autoSkip]
    );

    return { setCanGoBack };
};
