import React, { useCallback, useEffect, useState } from 'react';

import { useLocalAuthentication } from '@hooks/useLocalAuthentication';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useFocusEffect, useNavigation } from '@react-navigation/native';

import { PassCodeWrapper } from '../../components/PassCodeWrapper';

export const PassCodeChangeScreen = () => {
    const [code, setCode] = useState<string>('');
    const navigation = useNavigation();

    const { saveTmpPassCodeToStorage } = useLocalAuthentication();

    useFocusEffect(
        useCallback(() => {
            setCode('');
        }, [])
    );

    useEffect(() => {
        if (code.length === 4) {
            saveTmpPassCodeToStorage(code);
            navigation.navigate(ROUTES.PASSCODE_CONFIRM, {
                change: true,
            });
        }
    }, [code]);

    return (
        <PassCodeWrapper
            code={code}
            setCode={setCode}
            title={i18n.t('passcode.change.title')}
            header={i18n.t('passcode.change.title')}
            text={i18n.t('passcode.change.subTitle')}
        />
    );
};
