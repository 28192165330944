import { useLazyQuery } from '@apollo/client';
import { errorsHandler } from '@helpers/errors';
import { UserAchievementOutputResponse } from '@models/achievements';
import { GET_USER_ACHIEVEMENTS } from '@requests/achievements';

export const useUserAchievements = () => {
    const [
        getUserAchievements,
        {
            data: userAchievementsData,
            loading: loadingUserAchievements,
            error: errorUserAchievements,
        },
    ] = useLazyQuery<UserAchievementOutputResponse>(GET_USER_ACHIEVEMENTS, {
        onError: (error) => errorsHandler(error),
        fetchPolicy: 'network-only',
    });
    return {
        getUserAchievements,
        userAchievementsData,
        loadingUserAchievements,
        errorUserAchievements,
    };
};
