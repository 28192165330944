import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { isWeb } from '@helpers/app';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            borderRadius: moderateScale(12),
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
        },
        title: {
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
            letterSpacing: 0.08,
            color: Colors[theme].text.onLight,
        },
        subTitle: {
            marginTop: verticalScale(2),
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),

            color: Colors[theme].text.onLight,
        },

        left: {
            backgroundColor: 'transparent',
            marginRight: scale(24),
        },
        right: {
            backgroundColor: 'transparent',
        },
        inner: {
            backgroundColor: 'transparent',
            flexDirection: 'row',
        },
        coin: {
            position: 'absolute',
            width: 65,
            height: isWeb ? 'auto' : 96,
            right: 8,
            bottom: 0,
            backgroundColor: 'transparent',
        },
    });

export default styles;
