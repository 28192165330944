import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import TipAcceptIcon from '../../assets/icons/mint/tipAccept.svg';
import TipDeclineIcon from '../../assets/icons/mint/tipDecline.svg';
import useThemedStyles from '../../hooks/useThemedStyles';
import i18n from '../../i18n/i18n';
import AlertCard from '../AlertCard';
import { Text } from '../Themed';
import stylesMain from './styles';

interface ITips {
    id: 'post' | 'picture' | 'changes' | 'cropping' | 'overlaying';
    accept: boolean;
}

const TIPS: ITips[] = [
    {
        id: 'post',
        accept: true,
    },
    {
        id: 'picture',
        accept: true,
    },
    {
        id: 'changes',
        accept: true,
    },
    {
        id: 'cropping',
        accept: false,
    },
    {
        id: 'overlaying',
        accept: false,
    },
];

interface IProps {
    containerStyle?: StyleProp<ViewStyle>;
}
const VerificationTips = ({ containerStyle }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const renderItem = (item: ITips) => {
        const { id, accept } = item;
        return (
            <View style={styles.item} key={id}>
                <View style={styles.icon}>
                    {accept ? (
                        <TipAcceptIcon width={21} height={20} />
                    ) : (
                        <TipDeclineIcon width={21} height={20} />
                    )}
                </View>
                <Text style={styles.text}>
                    {i18n.t(`socializeToEarn.verifyUser.tips.${id}`)}
                </Text>
            </View>
        );
    };

    return (
        <View style={[styles.container, containerStyle]}>
            <Text style={styles.title}>
                {i18n.t('socializeToEarn.verifyUser.tips.title')}
            </Text>
            <View style={styles.tips}>
                {TIPS.map((item) => renderItem(item))}
            </View>
            <AlertCard text={i18n.t('socializeToEarn.verifyUser.tips.alert')} />
        </View>
    );
};
export default VerificationTips;
