import * as React from 'react';
import { Vibration } from 'react-native';
import { Gesture } from 'react-native-gesture-handler';
import {
    Extrapolation,
    interpolate,
    useAnimatedStyle,
    useSharedValue,
} from 'react-native-reanimated';

import { useDimensions } from '@contexts/DimensionsContext';

const MAX_IMAGE_SCALE = 10;
const MIN_IMAGE_SCALE = 0.5;
const SCALE_STEP = 0.1;

interface IProps {
    imageHeight: number;
}

export const useBackGroundDraggable = ({ imageHeight }: IProps) => {
    const { windowWidth, windowHeight } = useDimensions();

    const translateX = useSharedValue(0);
    const translateY = useSharedValue(0);

    const offset = useSharedValue({
        x: 0,
        y: 0,
    });
    const start = useSharedValue({
        x: 0,
        y: 0,
    });
    const xPrevious = useSharedValue(0);
    const yPrevious = useSharedValue(0);
    const scalePrevious = useSharedValue(1);
    const scaleCurrent = useSharedValue(1);

    const gesturePan = Gesture.Pan()
        .onUpdate((event) => {
            offset.value = {
                x: event.translationX + start.value.x,
                y: event.translationY + start.value.y,
            };
        })
        .onEnd(() => {
            start.value = {
                x: translateX.value,
                y: translateY.value,
            };
        });

    const vibration = (arg: number) => {
        'worklet';
        Vibration.vibrate(arg);
    };
    const gesturePinch = Gesture.Pinch()
        .onUpdate((event) => {
            scaleCurrent.value = scalePrevious.value * event.scale;
        })
        .onEnd(() => {
            if (scaleCurrent.value <= MIN_IMAGE_SCALE) {
                scaleCurrent.value = MIN_IMAGE_SCALE;
            } else if (scaleCurrent.value >= MAX_IMAGE_SCALE) {
                scaleCurrent.value = MAX_IMAGE_SCALE;
            }
            scalePrevious.value = scaleCurrent.value;
        });

    const gesture = Gesture.Simultaneous(gesturePan, gesturePinch);

    const calculateInputRangeX = React.useCallback(() => {
        'worklet';
        const space = (windowWidth * scaleCurrent.value - windowWidth) / 2;
        const start = space - xPrevious.value;
        const end = -xPrevious.value - space;

        return [start, end];
    }, [windowWidth, scaleCurrent.value]);

    const calculateInputRangeY = React.useCallback(() => {
        'worklet';
        const space = (imageHeight * scaleCurrent.value - windowHeight) / 2;
        const start = space - xPrevious.value;
        const end = -yPrevious.value - space;

        return [start, end];
    }, [imageHeight, windowHeight, yPrevious.value, scaleCurrent.value]);

    const zoomPlus = () => {
        'worklet';
        scaleCurrent.value += SCALE_STEP;
        if (scaleCurrent.value >= MAX_IMAGE_SCALE) {
            scaleCurrent.value = MAX_IMAGE_SCALE;
        }
    };
    const zoomMinus = () => {
        'worklet';
        scaleCurrent.value -= SCALE_STEP;
        if (scaleCurrent.value <= MIN_IMAGE_SCALE) {
            scaleCurrent.value = MIN_IMAGE_SCALE;
        }
    };

    const animatedBackgroundStyles = useAnimatedStyle(() => {
        const rangeX = calculateInputRangeX();
        const rangeY = calculateInputRangeY();

        const interpolateX = interpolate(
            offset.value.x,
            rangeX,
            rangeX,
            Extrapolation.CLAMP
        );
        translateX.value = interpolateX;

        const interpolateY = interpolate(
            offset.value.y,
            rangeY,
            rangeY,
            Extrapolation.CLAMP
        );
        translateY.value = interpolateY;

        return {
            transform: [{ scale: scaleCurrent.value }],
            top: interpolateY,
            left: interpolateX,
        };
    });

    return {
        gesturePan,
        gesturePinch,
        animatedBackgroundStyles,
        zoomPlus,
        zoomMinus,
        gesture,
    };
};
