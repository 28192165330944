import React from 'react';
import { SafeAreaView } from 'react-native';

import StepOneImage from '@assets/icons/howTo/howToCollections/stepOne.svg';
import StepThreeImage from '@assets/icons/howTo/howToCollections/stepThree.svg';
import StepTwoImage from '@assets/icons/howTo/howToCollections/stepTwo.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import Button from '@components/Button';
import Carousel from '@components/Carousel';
import { ICON_NAMES } from '@components/Icons';
import { View } from '@components/Themed';
import { useDimensions } from '@contexts/DimensionsContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { waitForNavigation } from '@helpers/helpers';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBarModal from '@navigation/NavigationBar/NavigationBarModal';
import { useNavigation } from '@navigation/useNavigation';

import stylesMain from '../styles/styles';

const data = ['stepOne', 'stepTwo', 'stepThree'];
const screen = 'HowToCollections';

export const HowToCollectionsScreen = () => {
    const { windowWidth } = useDimensions();
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const handleButton = async () => {
        navigation.pop();
        await waitForNavigation();
        navigation.navigateToMint();
    };

    const renderItem = (item: string) => {
        const icon = () => {
            switch (item) {
                case 'stepOne':
                    return <StepOneImage />;
                case 'stepTwo':
                    return <StepTwoImage />;
                case 'stepThree':
                    return <StepThreeImage />;

                default:
                    return null;
            }
        };

        const component = () => {
            switch (item) {
                case 'stepThree':
                    return (
                        <BlockHeader
                            emptyStateIcon={icon()}
                            title={i18n.t(`howTo.${screen}.${item}.title`)}
                            additionalTitle={Coin.iguai}
                            text={i18n.t(`howTo.${screen}.${item}.text`)}
                            type="emptyState"
                        />
                    );

                default:
                    return (
                        <BlockHeader
                            emptyStateIcon={icon()}
                            title={i18n.t(`howTo.${screen}.${item}.title`)}
                            text={i18n.t(`howTo.${screen}.${item}.text`)}
                            type="emptyState"
                        />
                    );
            }
        };

        return (
            <View style={styles.itemContainer}>
                <View style={styles.content}>{component()}</View>
            </View>
        );
    };

    return (
        <View style={styles.main}>
            <View style={styles.header}>
                <NavigationBarModal
                    iconName={ICON_NAMES.CLOSE}
                    title={i18n.t('general.howItWorks')}
                    onBack={() => navigation.pop()}
                />
            </View>
            <Carousel
                data={data}
                sliderWidth={windowWidth}
                itemWidth={windowWidth}
                renderItem={(item) => renderItem(item)}
                fullHeight
                pagination
                centredVertical
            />
            <View style={styles.buttonWrapper}>
                <Button
                    title={i18n.t('general.BUTTONS.mintNewNft')}
                    onPress={handleButton}
                />
            </View>
            <SafeAreaView />
        </View>
    );
};
