import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import { Text, View } from '@components/Themed';
import { useHaptic } from '@hooks/useHaptic';
import useThemedStyles from '@hooks/useThemedStyles';

import stylesMain from './styles';

interface IProps {
    title: string;
    isSelected: boolean;
    additionalTitle?: string;

    onPress: () => void;
}

const DropdownItem = ({
    title,
    isSelected,
    additionalTitle,
    onPress,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { triggerLight } = useHaptic();

    const handleClick = () => {
        triggerLight();
        onPress();
    };

    return (
        <TouchableOpacity
            style={[styles.shadow, isSelected && styles.shadowSelected]}
            activeOpacity={0.7}
            onPress={handleClick}>
            <View
                style={[
                    styles.container,
                    isSelected && styles.containerSelected,
                ]}>
                <Text style={styles.title}>
                    {title}
                    {!!additionalTitle && (
                        <Text style={styles.additionalTitle}>
                            {additionalTitle}
                        </Text>
                    )}
                </Text>
            </View>
        </TouchableOpacity>
    );
};

export default DropdownItem;
