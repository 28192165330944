import * as React from 'react';
import { SvgProps } from 'react-native-svg';

import { useDimensions } from '@contexts/DimensionsContext';
import { useTheme } from '@contexts/ThemeContext';
import { isWeb, isWebAndroid, isWebIos } from '@helpers/app';

import Colors from '../../constants/Colors';
import useThemedStyles from '../../hooks/useThemedStyles';
import CustomModalBackdrop from '../../web-src/components/CustomModalBackdrop';
import Button, { ButtonType } from '../Button';
import ExtendedModal from '../ExtendedModal';
import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    icon?: React.ReactElement<SvgProps>;
    titleText: string;
    infoText: string;
    firstButtonText?: string;
    secondButtonText?: string;
    onFirstButtonClick?: () => void;
    onSecondButtonClick?: () => void;
    firstButtonType?: ButtonType;
    children?: React.ReactNode;
}

const CustomHorizontalModal = ({
    isVisible,
    icon,
    titleText,
    infoText,
    firstButtonText,
    secondButtonText,
    onFirstButtonClick,
    onSecondButtonClick,
    firstButtonType,
    children,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { windowHeight, windowWidth } = useDimensions();
    const { theme } = useTheme();

    const modalStyles = React.useMemo(() => {
        if (windowHeight < windowWidth) {
            return undefined;
        } else {
            return {
                width: isWebIos || isWebAndroid ? 700 : undefined,
                left: isWebIos || isWebAndroid ? -700 / 4.5 : undefined,
            };
        }
    }, [windowHeight, windowWidth]);

    return (
        <ExtendedModal
            horizontalModal
            isVisible={isVisible}
            backdropColor={Colors[theme].modal.modalOverlay}
            backdropOpacity={Colors[theme].modal.opacity}
            customBackdrop={
                isWeb && <CustomModalBackdrop isHorizontal={true} />
            }
            withToasts
            style={[styles.modal, modalStyles]}>
            <View style={[styles.shadow]}>
                <View style={styles.container}>
                    {icon ? (
                        <View style={styles.iconWrapper}>{icon}</View>
                    ) : null}
                    <View style={styles.textWrapper}>
                        <Text style={styles.title}>{titleText}</Text>
                        <Text style={styles.text}>{infoText}</Text>
                    </View>

                    {children}

                    <View style={styles.buttonWrapper}>
                        {secondButtonText && onSecondButtonClick && (
                            <Button
                                onPress={onSecondButtonClick}
                                title={secondButtonText}
                                type="outline"
                                textStyles={styles.buttonLeave}
                                containerStyle={styles.buttonContainer}
                                debouncedPress
                            />
                        )}
                        {firstButtonText && onFirstButtonClick && (
                            <Button
                                containerStyle={styles.buttonContainer}
                                title={firstButtonText}
                                onPress={onFirstButtonClick}
                                type={firstButtonType}
                                debouncedPress
                            />
                        )}
                    </View>
                </View>
            </View>
        </ExtendedModal>
    );
};
export default CustomHorizontalModal;
