import * as React from 'react';
import { SafeAreaView } from 'react-native';

import Empty from '@assets/icons/play/emptyVerify.svg';
import Button from '@components/Button';
import { ICON_NAMES } from '@components/Icons';
import PlayEnergy from '@components/PlayEnergy';
import PullToRefresh from '@components/PullToRefresh';
import { View } from '@components/Themed';
import { PlayContext } from '@contexts/PlayContext';
import useVerifyTask from '@hooks/tasks/useVerifyTask';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import EmptyStatePlay from '../../components/EmptyStatePlay';
import stylesMain from './styles';

export const VerifyUserErrorScreen = () => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const [viewHeight, setViewHeight] = React.useState<number>(0);

    const { verifyTask } = React.useContext(PlayContext);

    const {
        canStartNextTask,
        createVerifyTask,
        createVerificationTaskLoading,
    } = useVerifyTask();

    const handleViewMoreTask = () => {
        navigation.pop();
    };

    const onRefresh = React.useCallback(async () => {
        if (!canStartNextTask) {
            navigation.pop();
            return;
        }
        await createVerifyTask({
            withNavigation: false,
            onCompleted: () => {
                navigation.replace(ROUTES.SOCIALIZE_TO_EARN_VERIFY_USER);
            },
        });
    }, [verifyTask, canStartNextTask, createVerifyTask]);

    const renderItem = React.useCallback(() => {
        return (
            <View style={[styles.main, { height: viewHeight }]}>
                <EmptyStatePlay
                    icon={<Empty />}
                    title={i18n.t(
                        'socializeToEarn.verifyUser.verifyEmpty.title'
                    )}
                    text={i18n.t('socializeToEarn.verifyUser.verifyEmpty.text')}
                />
                <Button
                    iconName={ICON_NAMES.REFRESH}
                    iconColor="green"
                    title={i18n.t(
                        'socializeToEarn.verifyUser.verifyEmpty.refresh'
                    )}
                    type="text"
                    size="md"
                    containerStyle={styles.refreshButton}
                    onPress={onRefresh}
                    debouncedPress
                />
            </View>
        );
    }, [verifyTask, viewHeight]);

    return (
        <>
            <NavigationBar
                backIcon="close"
                containerStyle={styles.navBg}
                onBack={handleViewMoreTask}
            />
            <View
                onLayout={(event) =>
                    setViewHeight(event.nativeEvent.layout.height)
                }
                style={styles.container}>
                <PullToRefresh
                    renderItem={renderItem}
                    onRefresh={onRefresh}
                    refreshing={createVerificationTaskLoading}
                    textLoading={i18n.t('pullToRefresh.reloadTasks.text')}
                />
            </View>
            <View style={styles.buttonWrapper}>
                {!!verifyTask && (
                    <PlayEnergy
                        energy={verifyTask.totalEnergyRewarded || 0}
                        maxEnergy={verifyTask.maxEnergyReward}
                        containerStyle={styles.energyWrapper}
                    />
                )}
                <Button
                    title={i18n.t(
                        'socializeToEarn.verifyUser.verifyEmpty.button1'
                    )}
                    onPress={handleViewMoreTask}
                    containerStyle={styles.energyWrapper}
                />
            </View>
            <SafeAreaView style={styles.safe} />
        </>
    );
};
export default VerifyUserErrorScreen;
