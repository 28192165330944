import React from 'react';

import Animation from '@components/Animation';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import { View } from '@components/Themed';
import { useUser } from '@contexts/UserContext';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { useNavigation } from '@navigation/useNavigation';

import stylesMain from './styles';

export const PassCodeSuccessScreen = () => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const { user } = useUser();

    const handlePress = () => {
        !user?.mainPet
            ? navigation.navigateToInitialMint()
            : navigation.navigateToWalletSettings();
    };

    return (
        <View style={styles.container}>
            <View style={styles.wrapper}>
                <BlockHeader
                    type="emptyState"
                    emptyStateIcon={
                        <View style={styles.image}>
                            <Animation
                                animation="dancing"
                                style={styles.animation}
                            />
                        </View>
                    }
                    title={i18n.t('passcode.success.title')}
                    text={i18n.t('passcode.success.subTitle')}
                />
            </View>
            <ButtonsBottom
                title={
                    !user?.mainPet
                        ? i18n.t('general.BUTTONS.startPlaying')
                        : i18n.t('general.BUTTONS.backToSettings')
                }
                onPress={handlePress}
                safe
            />
        </View>
    );
};
