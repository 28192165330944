import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
        },
        listStyle: {
            paddingHorizontal: scale(20),
            marginBottom: verticalScale(30),
        },

        columnWrapperStyle: {
            justifyContent: 'space-between',
        },
        footerStyle: {
            height: 16,
        },
        headerText: {
            marginBottom: verticalScale(16),
        },

        mysteryBoxesIcon: {
            width: 150,
            height: 150,
        },
        bottomGap: {
            height: 10,
        },
        comingSoonContainer: {
            alignItems: 'center',
            alignSelf: 'center',
        },
        contentContainer: {
            justifyContent: 'space-between',
        },
    });

export default styles;
