import * as React from 'react';

import { View } from '@components/Themed';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { errorsHandler } from '@helpers/errors';
import { waitForNavigation } from '@helpers/helpers';
import { useBreedingPets } from '@hooks/useBreedingPets';
import { useLoadingHook } from '@hooks/useLoadingHook';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';
import NavigationBar from '@navigation/NavigationBar';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { useFocusEffect } from '@react-navigation/native';

import NftEvolutionHowItWorks from '../../components/NftEvolutionHowItWorks';
import NftEvolutionModalNoPet from '../../components/NftEvolutionModalNoPet';
import stylesMain from './styles';

const flow = 'WelcomeNftEvolution';

const WelcomeNftEvolutionScreen = () => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const { clearNftEvolution } = useMysteryGames();

    useFocusEffect(
        React.useCallback(() => {
            clearNftEvolution();
        }, [])
    );

    const { isVisible, open, close } = useVisibleHook();
    const { isLoading, startLoading, stopLoading } = useLoadingHook();

    const { getBreedingPets } = useBreedingPets();

    const handleNavigate = async () => {
        try {
            startLoading();
            const result = await getBreedingPets();
            if (result.data?.breedingPets) {
                if (result.data.breedingPets.length > 1) {
                    navigation.replace(ROUTES.NFT_EVOLUTION_SELECT_PETS);
                    return;
                }
                open();
            }
            stopLoading();
        } catch (error) {
            errorsHandler(error, true);
        }
    };

    return (
        <View style={styles.main}>
            <NavigationBar
                isOneColor
                fillColor={styles.main.backgroundColor}
                filled={false}
                backIcon="close"
                title={i18n.t(
                    `HomeScreen.GamesBlock.games.${MYSTERY_GAMES.nftEvolution}.title`
                )}
                titleColor={styles.title.color}
            />
            <NftEvolutionHowItWorks
                onButtonPress={handleNavigate}
                isLoading={isLoading}
            />
            <NftEvolutionModalNoPet isVisible={isVisible} onClose={close} />
        </View>
    );
};

export default WelcomeNftEvolutionScreen;
