import * as React from 'react';
import { useCallback, useContext } from 'react';
import { TouchableOpacity, View } from 'react-native';

import { ConfigContext } from '@contexts/ConfigContext';
import { usePlay } from '@contexts/PlayContext';
import { useUser } from '@contexts/UserContext';
import { useHaptic } from '@hooks/useHaptic';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { useNavigation } from '@navigation/useNavigation';

import Icon, { ICON_NAMES } from '../Icons';
import { Text } from '../Themed';
import stylesMain from './styles';

const PlayNFTBlockFooter = () => {
    const { playablePets, petsLoaded } = usePlay();
    const { petsCount } = useUser();
    const { config } = useContext(ConfigContext);
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const maxPets = config?.maxActiveNFTsPerUser;

    const { triggerLight } = useHaptic();

    const handleOnAddMorePets = useCallback(() => {
        if (!playablePets || !maxPets) {
            return;
        }

        triggerLight();

        if (playablePets?.length < petsCount) {
            navigation.navigatePlayEditNFTS();
        } else {
            navigation.navigateToMint();
        }
    }, [navigation, triggerLight, maxPets, playablePets, petsCount]);

    const canAddMorePets = Number(playablePets?.length) < Number(maxPets);

    return petsLoaded ? (
        <View style={styles.containerBottom}>
            {canAddMorePets && (
                <TouchableOpacity
                    onPress={handleOnAddMorePets}
                    style={styles.emptyComponent}
                    activeOpacity={0.7}>
                    <Icon
                        name={ICON_NAMES.PLUS}
                        color={styles.iconGreen.color}
                    />
                    <Text style={styles.emptyText}>
                        {i18n.t('playScreen.selectNFT')}
                    </Text>
                </TouchableOpacity>
            )}
        </View>
    ) : null;
};
export default PlayNFTBlockFooter;
