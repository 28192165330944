import { gql } from '@apollo/client';

export const GET_QUANTUM_SPIN_GAME_CONFIG = gql`
    query QuantumSpinGameConfig {
        quantumSpinGameConfig {
            spinPriceIguAmountWei
            pregeneratedHashesCount
            globalSeed
            twitterBlockPostUrl
        }
    }
`;

export const GET_QUANTUM_SPIN_GAME_PRIZES = gql`
    query QuantumSpinGamePrizes {
        quantumSpinGamePrizes {
            chance
            iguAmountWei
            backgroundColor
            borderColor
        }
    }
`;
export const GET_QUANTUM_SPIN_GAMES_HISTORY = gql`
    query QuantumSpinGameHistory(
        $paginationInput: QuantumSpinGameHistoryPaginationInput!
    ) {
        quantumSpinGameHistory(paginationInput: $paginationInput) {
            items {
                id
                iguAmountWei
                hash
                date
            }
            meta {
                itemCount
                totalItems
                itemsPerPage
                totalPages
                currentPage
            }
        }
    }
`;
export const QUANTUM_SPIN_GAME_SPIN = gql`
    mutation QuantumSpinGameSpin {
        quantumSpinGameSpin {
            id
            iguAmountWei
            hash
            date
        }
    }
`;

export const GET_QUANTUM_SPIN_LAST_GAMES = gql`
    query QuantumSpinGamesLastGames {
        quantumSpinGamesLastGames {
            id
            hash
            date
        }
    }
`;
