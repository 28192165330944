import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { moderateScale, scale, verticalScale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        itemContainer: {
            backgroundColor: Colors[theme].purchasingFrom.backgroundDefault,
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].purchasingFrom.borderColorDefault,
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            marginBottom: verticalScale(4),
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        selectedShadow: {
            backgroundColor: Colors[theme].purchasingFrom.shadowColorSelected,
        },
        selected: {
            backgroundColor: Colors[theme].purchasingFrom.backgroundSelected,
            borderColor: Colors[theme].purchasingFrom.borderColorSelected,
            shadowColor: Colors[theme].purchasingFrom.shadowColorSelected,
        },
        shadow: {
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].purchasingFrom.shadowColorDefault,
        },
        gap: {
            marginBottom: scale(12),
        },
        coin: {
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: 'transparent',
        },
        coinTitle: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
            marginLeft: verticalScale(8),
        },
        coinAmount: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
            letterSpacing: 0.16,
            marginRight: verticalScale(8),
        },
        rightWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: 'transparent',
        },
        arrowRight: {
            color: Colors[theme].icon.secondary,
        },
    });

export default styles;
