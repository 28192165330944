import { useEffect, useState } from 'react';

import { addSeconds } from '../helpers/time';

const useCountdown = (targetDate: string | number) => {
    const countDownDate =
        typeof targetDate === 'string'
            ? new Date(targetDate).getTime()
            : addSeconds(targetDate).getTime();

    const [countDown, setCountDown] = useState(
        countDownDate - new Date().getTime()
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDownDate - new Date().getTime());
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownDate]);

    return getReturnValues(countDown);
};

const getReturnValues = (countDown: number) => {
    const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
        (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return [
        Math.max(days, 0),
        Math.max(hours, 0),
        Math.max(minutes, 0),
        Math.max(seconds, 0),
    ];
};

export { useCountdown };
