import * as React from 'react';
import { TouchableOpacity } from 'react-native';
import { SvgProps } from 'react-native-svg';

import Icon, { ICON_NAMES } from '@components/Icons';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT } from '@components/TypoText/types';
import useThemedStyles from '@hooks/useThemedStyles';
import { useTouchableOpacityDebounce } from '@hooks/useTouchableOpacityDebounce';

import stylesMain from './styles';

interface IProps {
    onPress: () => void;
    icon: React.ReactElement<SvgProps>;
    title: string;
    text: string;
    disabled?: boolean;
}

const SettingItem = ({ onPress, icon, title, text, disabled }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { handleDebounce } = useTouchableOpacityDebounce(onPress);

    return (
        <TouchableOpacity
            disabled={disabled}
            activeOpacity={0.7}
            style={[styles.shadow, disabled && styles.disabled]}
            onPress={handleDebounce}>
            <View style={styles.itemContainer}>
                <View style={styles.left}>
                    {icon}
                    <View style={styles.textWrapper}>
                        <TypoText
                            additionalStyles={styles.textGap}
                            type={TYPO_TEXT.SUBHEAD_BIG_BOLD}>
                            {title}
                        </TypoText>
                        <TypoText type={TYPO_TEXT.BODY_SMALL}>{text}</TypoText>
                    </View>
                </View>
                <View style={styles.right}>
                    <Icon
                        name={ICON_NAMES.ARROW_RIGHT_SECONDARY}
                        color={styles.arrowRight.color}
                    />
                </View>
            </View>
        </TouchableOpacity>
    );
};

export default SettingItem;
