import * as React from 'react';

import { Text, View } from '../../../../../components/Themed';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import stylesMain from './styles';

interface IProps {
    balance: number;
}

const WalletNFTBalance = ({ balance }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const value = i18n.t('wallet.nft.NFTs', {
        balance: balance,
    });

    return (
        <View style={styles.container}>
            <Text style={[styles.textTotal, styles.gap]}>
                {i18n.t('wallet.total')}
            </Text>
            <Text style={[styles.textBalance, styles.gap]}>{value}</Text>
        </View>
    );
};
export default WalletNFTBalance;
