import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import { scale, verticalScale } from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        selectedContainer: {
            marginTop: verticalScale(24),
            alignSelf: 'flex-start',
        },
        selectedLabel: {
            marginBottom: verticalScale(10),
        },
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 22,
                height: 30,
                weight: '700',
            }),
        },
        titleMargin: {
            marginBottom: verticalScale(6),
        },
        description: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
        },
        infoRowMargin: {
            marginBottom: verticalScale(20),
        },
        firstRow: {
            marginTop: verticalScale(20),
        },
        readMore: {
            marginTop: verticalScale(12),
        },
        container: {
            borderRadius: 20,
        },
    });

export default styles;
