import * as React from 'react';

import { View } from '../../Themed';
import { IconsData, iconsData } from './iconsData';

interface IProps {
    name: string;
    active?: boolean | undefined;
}

const NavigationIcon = ({ name, active }: IProps) => {
    // return iconsData[name as keyof IconsData].icon;

    return (
        <View
            style={{
                width: 30,
                height: 30,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'transparent',
            }}>
            {active
                ? iconsData[name as keyof IconsData].iconActive
                : iconsData[name as keyof IconsData].icon}
        </View>
    );
};

export default NavigationIcon;
