import * as React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import Icon from '@assets/icons/emptyState/spaceIdDomain.svg';
import ExplorerLink from '@components/ExplorerLink';
import { View } from '@components/Themed';
import WalletAddress from '@components/WalletAddress';
import { useWallet } from '@contexts/Wallet/WalletContext';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import { SPACE_ID_LINK } from '../../screens/HowTo/HowToPurchaseSpadeId/HowToPurchaseSpadeIdScreen';
import BlockHeader from '../BlockHeader/BlockHeader';
import ModalBottom from '../ModalBottom';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;

    onClose: () => void;
}

const ModalSpaceId = ({ isVisible, onClose }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { walletData } = useWallet();

    return (
        <ModalBottom isVisible={isVisible} onClose={onClose} modalHeight={660}>
            <ScrollView
                style={styles.container}
                showsVerticalScrollIndicator={false}>
                <TouchableOpacity activeOpacity={1}>
                    <BlockHeader
                        emptyStateIcon={<Icon />}
                        type="emptyState"
                        title={i18n.t('SpaceId.modal.title')}
                        text={i18n.t('SpaceId.modal.text')}
                    />
                    {!!walletData?.wallet?.address && (
                        <View style={styles.wallet}>
                            <WalletAddress
                                address={walletData?.wallet.address}
                            />
                        </View>
                    )}
                </TouchableOpacity>
            </ScrollView>
            <ExplorerLink
                containerStyles={styles.link}
                title={i18n.t('SpaceId.modal.link')}
                explorerUrl={SPACE_ID_LINK}
            />
        </ModalBottom>
    );
};

export default ModalSpaceId;
