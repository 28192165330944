import React, { useState } from 'react';
import Cropper from 'react-cropper';
import { View } from 'react-native';

import 'cropperjs/dist/cropper.css';

import Button from '../../../components/Button';
import ExtendedModal from '../../../components/ExtendedModal';
import Colors from '../../../constants/Colors';
import { useDimensions } from '../../../contexts/DimensionsContext';
import { useTheme } from '../../../contexts/ThemeContext';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import NavigationBarModal from '../../../navigation/NavigationBar/NavigationBarModal';
import stylesMain from './styles';
import IProps from './types';

export default function ModalCropImage({
    src,
    initialAspectRatio,
    isVisible,
    setCroppedImage,
    setCropModalVisibility,
}: IProps) {
    const styles = useThemedStyles(stylesMain);
    const { theme } = useTheme();
    const { windowWidth, windowHeight } = useDimensions();

    const [cropper, setCropper] = useState<any>();

    const onCancel = () => {
        setCroppedImage('');
        setCropModalVisibility(false);
    };

    const onChoose = () => {
        if (typeof cropper !== 'undefined') {
            const imageDataUri = cropper.getCroppedCanvas().toDataURL();
            setCropper(undefined);
            setCropModalVisibility(false);

            setCroppedImage(imageDataUri);
        }
    };

    return (
        <ExtendedModal
            isVisible={isVisible}
            backdropColor={Colors[theme].modal.qrScannerOverlay}
            backdropOpacity={1}
            animationIn="slideInRight"
            animationOut="slideOutRight"
            style={styles.modal}>
            <NavigationBarModal
                title={i18n.t('ModalCropImage.title')}
                onBack={onCancel}
                containerStyle={styles.navbar}
                titleStyle={styles.title}
            />
            <View
                style={[
                    styles.container,
                    {
                        width: windowWidth,
                        height: windowHeight,
                    },
                ]}>
                <View style={styles.background}>
                    {!!src && (
                        <Cropper
                            style={{
                                maxHeight: windowHeight * 0.75,
                            }}
                            zoomTo={0}
                            initialAspectRatio={initialAspectRatio || undefined}
                            src={src}
                            viewMode={1}
                            minCropBoxHeight={200}
                            minCropBoxWidth={200}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false}
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                            guides={true}
                        />
                    )}
                </View>
                {!!src && (
                    <View style={styles.buttonContainer}>
                        <Button
                            onPress={onChoose}
                            title={i18n.t('ModalCropImage.crop')}
                            type="fill"
                            containerStyle={styles.buttonGap}
                        />
                    </View>
                )}
            </View>
        </ExtendedModal>
    );
}
