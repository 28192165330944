import * as React from 'react';

import EmptyList from '@components/EmptyList';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { stylesFlex } from '@helpers/style';
import { toastErrorUnknown } from '@helpers/toastNotification';
import i18n from '@i18n/i18n';
import { NFT_ROYAL_GAME_HISTORY_STATUS } from '@models/mysteryGames';
import { useNavigation } from '@navigation/useNavigation';

import { NftRoyalGameHistoryProps } from '../../../../../types';
import NftRoyalHistoryWrapper from '../../components/NftRoyalHistoryWrapper';

const NftRoyalGameHistoryScreen = ({ route }: NftRoyalGameHistoryProps) => {
    const { currentNftRoyalGameHistory } = useMysteryGames();

    const navigation = useNavigation();
    navigation.blockHardwareBack();

    const leaveFromScreen = () => {
        navigation.pop();
    };

    const renderContent = React.useCallback(() => {
        if (!currentNftRoyalGameHistory) {
            navigation.pop();
            toastErrorUnknown();
            return;
        }
        switch (currentNftRoyalGameHistory.status) {
            case NFT_ROYAL_GAME_HISTORY_STATUS.Refund:
                return (
                    <EmptyList
                        containerStyle={stylesFlex()}
                        emptyTitle={i18n.t(
                            'HomeScreen.NftRoyal.emptyScreen.title'
                        )}
                        emptyText={i18n.t(
                            'HomeScreen.NftRoyal.emptyScreen.text'
                        )}
                        emptyButtonText={i18n.t(
                            'general.BUTTONS.exploreMoreGames'
                        )}
                        onPressButton={leaveFromScreen}
                        big
                        withAnimation="cry"
                    />
                );
            case NFT_ROYAL_GAME_HISTORY_STATUS.WaitingForMyself:
                return (
                    <EmptyList
                        containerStyle={stylesFlex()}
                        emptyTitle={i18n.t(
                            'HomeScreen.NftRoyal.emptyScreen.title'
                        )}
                        emptyText={i18n.t(
                            'HomeScreen.NftRoyal.emptyScreen.text'
                        )}
                        emptyButtonText={i18n.t(
                            'general.BUTTONS.exploreMoreGames'
                        )}
                        onPressButton={leaveFromScreen}
                        big
                        withAnimation="cry"
                    />
                );

            default:
                return (
                    <NftRoyalHistoryWrapper
                        history={currentNftRoyalGameHistory}
                        force={route.params?.fromHistory}
                    />
                );
        }
    }, [currentNftRoyalGameHistory, route.params?.fromHistory]);

    return renderContent();
};

export default NftRoyalGameHistoryScreen;
