import * as React from 'react';
import { useContext } from 'react';
import { Image } from 'react-native';

import { useMutation } from '@apollo/client';
import { MintContext } from '@contexts/MintContext';
import { prefetchImage } from '@helpers/media';

import { CreatePetImageResponse } from '../../../Data/Models';
import { COMPOSE_NFT_IMAGE } from '../../../Data/Requests';
import Button from '../../../components/Button';
import ModalLoader from '../../../components/ModalLoader';
import i18n from '../../../i18n/i18n';
import ROUTES from '../../../navigation/routes';
import { useNavigation } from '../../../navigation/useNavigation';
import ErrorReporting from '../../../utils/ErrorReporting';
import { IImageSize, IPositionPetImage } from '../types';

export interface SavePhotoParams {
    calculateImageDataToServer: (
        bgImageDefault: IImageSize
    ) => IPositionPetImage;
    navigation: any;
    calculateEditPhotoSavedSize: () => IPositionPetImage;
    loading: boolean;
}

export const SaveEditedPhoto = (params: SavePhotoParams) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const { setSavedPhoto, setEditPhoto, editPhoto, background } =
        useContext(MintContext);
    const [request] = useMutation<CreatePetImageResponse>(COMPOSE_NFT_IMAGE);
    const { calculateImageDataToServer, calculateEditPhotoSavedSize, loading } =
        params;
    const navigation = useNavigation();

    const savePhoto = () => {
        setIsLoading(true);
        Image.getSize(background!.original, async (widthBg, heightBg) => {
            const { top, left, width, height } = calculateImageDataToServer({
                width: widthBg,
                height: heightBg,
            });
            const editPhotoSize = calculateEditPhotoSavedSize();
            setEditPhoto({
                file: editPhoto!.file,
                position: {
                    height: editPhotoSize.height,
                    width: editPhotoSize.width,
                    top: editPhotoSize.top,
                    left: editPhotoSize.left,
                    scale: editPhotoSize.scale,
                },
                uri: editPhoto!.file.url,
            });

            executeRequest(top, left, width, height).catch((error) => {
                Console.log(error);
                ErrorReporting.report(error);
                setIsLoading(false);
            });
        });
    };
    const executeRequest = async (
        top: number,
        left: number,
        width: number,
        height: number
    ) => {
        const result = await request({
            variables: {
                composeNftImageInput: {
                    fileId: editPhoto?.file.id,
                    backgroundId: background?.id,
                    top,
                    left,
                    width,
                    height,
                },
            },
        });

        const composeNftImage = result.data?.composeNftImage;
        if (composeNftImage) {
            await prefetchImage(composeNftImage.url);
            setSavedPhoto(composeNftImage);
            setIsLoading(false);
            navigation.navigate(ROUTES.MINT_PET_NAME);
        }
    };

    return (
        <>
            <ModalLoader
                isVisible={isLoading}
                text={i18n.t('mintEditPhoto.loading')}
            />
            <Button
                loading={loading}
                disabled={!request}
                title={i18n.t('general.next')}
                onPress={() => savePhoto()}
            />
        </>
    );
};
