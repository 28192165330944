import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';
import { stylesAlignCenter } from '@helpers/style';

import Colors from '../../constants/Colors';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            backgroundColor: 'transparent',
            flex: 1,
        },
        flatlist: {
            backgroundColor: 'transparent',
        },
        progressBackgroundColor: {
            color: Colors[theme].background,
        },
        progressIndicator: {
            color: Colors[theme].icon.primary,
        },
        loadMoreText: {
            textAlign: 'center',
            color: Colors[theme].text.accent,
            ...font({
                size: 16,
                weight: '700',
            }),
            marginVertical: verticalScale(16),
        },
        moreColumns: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            paddingHorizontal: scale(20),
        },
        moveItemFromScreen: {
            width: '100%',
            backgroundColor: Colors[theme].background,
            height: 10000,
        },
    });

export default styles;
