import * as React from 'react';

import LoadingComponent from '../../../components/LoadingComponent';
import Switch from '../../../components/Switch';
import { View } from '../../../components/Themed';
import { useAccessibilitySettings } from '../../../contexts/AccessibilitySettingsContext';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import NavigationBar from '../../../navigation/NavigationBar';
import stylesMain from '../styles';

const AccessibilitySettingsScreen = () => {
    const mainStyles = useThemedStyles(stylesMain);

    const { accessibilitySettings, loading, updateAccessibilitySettings } =
        useAccessibilitySettings();

    const renderSettings = React.useCallback(() => {
        return accessibilitySettings.map((obj) => {
            const { id, enabled } = obj;
            return (
                <Switch
                    key={id}
                    id={id}
                    title={i18n.t(`profile.accessibilitySettings.${id}`)}
                    isActive={enabled}
                    onChange={updateAccessibilitySettings}
                />
            );
        });
    }, [accessibilitySettings]);

    return (
        <View style={mainStyles.container}>
            <NavigationBar
                backIcon
                title={i18n.t('profile.accessibilitySettings.title')}
                filled={false}
            />
            {loading ? (
                <LoadingComponent />
            ) : (
                <View style={mainStyles.page}>
                    <View style={mainStyles.textContainer}>
                        {renderSettings()}
                    </View>
                </View>
            )}
        </View>
    );
};

export default AccessibilitySettingsScreen;
