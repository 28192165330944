import * as React from 'react';

import ClickableSelectCard from '@components/ClickableSelectCard';
import LuckyStrikePurchaseIcon from '@components/Icons/LuckyStrikePurchaseIcon';
import { View } from '@components/Themed';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import useThemedStyles from '@hooks/useThemedStyles';

import stylesMain from './styles';

interface IProps {
    onPress: (amount: number) => void;
    amount: string;
    tickets: number[];
    boughtTickets: number;
}

const SelectAmountOfLuckyStrikeTickets = ({
    tickets,
    onPress,
    boughtTickets,
    amount,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { luckyStrikeConfig } = useMysteryGames();

    const handleDisabled = React.useCallback(
        (item: number) => {
            const ticketsLeft =
                (luckyStrikeConfig?.maxTicketAmount || 0) - boughtTickets;
            return !(item <= ticketsLeft);
        },
        [luckyStrikeConfig?.maxTicketAmount, boughtTickets]
    );

    return (
        <View style={styles.container}>
            {tickets.map((item, index) => {
                return (
                    <ClickableSelectCard
                        key={index}
                        debounceTime={0}
                        isSelect={item === Number(amount)}
                        containerStyle={styles.card}
                        title={item.toString()}
                        disabled={handleDisabled(item)}
                        icon={
                            <LuckyStrikePurchaseIcon slug={index} size={32} />
                        }
                        onPress={() => onPress(item)}
                    />
                );
            })}
        </View>
    );
};
export default SelectAmountOfLuckyStrikeTickets;
