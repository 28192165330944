import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            marginTop: verticalScale(16),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
        },
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
        },
    });

export default styles;
