import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { scale } from '../../helpers/dimensions';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        divider: {
            backgroundColor: Colors[theme].circle,
            borderRadius: 1000,
            width: 5,
            height: 5,
            marginHorizontal: scale(6),
        },
    });

export default styles;
