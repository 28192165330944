import React, { Dispatch, SetStateAction } from 'react';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import VerticalCentredContent from '@components/VerticalCentredContent';
import { isAndroid } from '@helpers/app';

import AvailableSoon from '../../assets/icons/mainnet/availableSoon.svg';
import useThemedStyles from '../../hooks/useThemedStyles';
import i18n from '../../i18n/i18n';
import BiswapLogo from '../../screens/Wallet/Trade/components/BiswapLogo';
import ModalBottom from '../ModalBottom';
import mainStyles from './styles';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
}

const ModalMainnet = ({ isVisible, onClose }: IProps) => {
    const styles = useThemedStyles(mainStyles);

    return (
        <ModalBottom modalHeight="90%" isVisible={isVisible} onClose={onClose}>
            <VerticalCentredContent style={styles.container}>
                <BlockHeader
                    containerStyle={styles.blockHeaderContainer}
                    type="emptyState"
                    emptyStateIcon={<AvailableSoon width={215} height={180} />}
                    title={i18n.t('ModalMainnet.title')}
                    text={i18n.t('ModalMainnet.text')}
                />
                <BiswapLogo withLink />
            </VerticalCentredContent>
            <ButtonsBottom
                title={i18n.t('ModalMainnet.button')}
                onPress={onClose}
                safe={!isAndroid}
            />
        </ModalBottom>
    );
};
export default ModalMainnet;
