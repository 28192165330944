import { useEffect, useState } from 'react';

import { BigNumber, Wallet, ethers } from 'ethers';

import { getGasPrice } from '@helpers/wallet';

import { Config } from '../Data/Models';

export type EstimatedTransactionFee = {
    transferCoin: {
        BNB: string;
        BUSD: string;
        IGU: string;
        IGUP: string;
        IGUAI?: string;
        VIGU?: string;
        VIGUP?: string;
    };
    trade: {
        BNB: string;
        BUSD: string;
        IGU: string;
        IGUP: string;
        IGUAI?: string;
        VIGU?: string;
        VIGUP?: string;
    };
    executeTransaction: {
        BNB: string;
        IGU: string;
    };
    nftTransfer: string;
    gasPrice: string;
};

const useFee = (wallet: Wallet | undefined, config: Config | undefined) => {
    const [fee, setFee] = useState<EstimatedTransactionFee | null>(null);

    const estimateFee = async (
        wallet: Wallet,
        config: Config
    ): Promise<EstimatedTransactionFee> => {
        const { estimatedGasUse, gasPriceConfig } = config;
        const gasPrice = await getGasPrice(wallet, gasPriceConfig);

        const estimatedTransactionFee = {
            transferCoin: {
                BNB: ethers.utils.formatEther(
                    BigNumber.from(estimatedGasUse.bnb.transfer).mul(gasPrice)
                ),
                BUSD: ethers.utils.formatEther(
                    BigNumber.from(estimatedGasUse.igup.transfer).mul(gasPrice)
                ),
                IGU: ethers.utils.formatEther(
                    BigNumber.from(estimatedGasUse.igu.transfer).mul(gasPrice)
                ),
                IGUP: ethers.utils.formatEther(
                    BigNumber.from(estimatedGasUse.igup.transfer).mul(gasPrice)
                ),
            },
            // TODO: later add from backend
            trade: {
                BNB: '0.003',
                BUSD: '0.003',
                IGU: '0.003',
                IGUP: '0.003',
            },
            executeTransaction: {
                BNB: ethers.utils.formatEther(
                    BigNumber.from(estimatedGasUse.nft.execTransaction).mul(
                        gasPrice
                    )
                ),
                IGU: ethers.utils.formatEther(
                    BigNumber.from(estimatedGasUse.igu.transfer).mul(gasPrice)
                ),
            },
            nftTransfer: ethers.utils.formatEther(
                BigNumber.from(estimatedGasUse.nft.transferFrom).mul(gasPrice)
            ),
            gasPrice,
        };

        Console.info('estimatedTransactionFee', estimatedTransactionFee);

        return estimatedTransactionFee;
    };

    useEffect(() => {
        if (wallet && config) {
            estimateFee(wallet, config).then((fee) => {
                Console.log('calculated fee', fee);
                setFee(fee);
            });
        }
    }, [wallet, config]);

    return { fee };
};

export default useFee;
