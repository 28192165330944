import * as React from 'react';

import HowQuantumSpinWorkScreen from '../../screens/TabBar/Home/QuantumSpinFlow/HowQuantumSpinWork/HowQuantumSpinWorkScreen';
import QuantumSpinGameHistoryScreen from '../../screens/TabBar/Home/QuantumSpinFlow/QuantumSpinGameHistory/QuantumSpinGameHistoryScreen';
import QuantumSpinProbablyFairScreen from '../../screens/TabBar/Home/QuantumSpinFlow/QuantumSpinProbablyFair/QuantumSpinProbablyFairScreen';
import QuantumSpinProbablyFairHistoryScreen from '../../screens/TabBar/Home/QuantumSpinFlow/QuantumSpinProbablyFairHistory/QuantumSpinProbablyFairHistoryScreen';
import WelcomeQuantumSpinScreen from '../../screens/TabBar/Home/QuantumSpinFlow/WelcomeQuantumSpin/WelcomeQuantumSpinScreen';
import ROUTES from '../routes';
import { QuantumSpinTabsNavigator } from './QuantumSpinTabsNavigator';

const QuantumSpinGroup = (RootStack: any) => {
    return (
        <>
            <RootStack.Screen
                name={ROUTES.WELCOME_QUANTUM_SPIN}
                component={WelcomeQuantumSpinScreen}
            />
            <RootStack.Screen
                name={ROUTES.QUANTUM_SPIN}
                options={{ gestureEnabled: false }}
                component={QuantumSpinTabsNavigator}
            />
            <RootStack.Screen
                name={ROUTES.HOW_QUANTUM_SPIN_WORK}
                component={HowQuantumSpinWorkScreen}
                options={{
                    presentation: 'modal',
                    headerShown: false,
                }}
            />
            <RootStack.Screen
                name={ROUTES.QUANTUM_SPIN_GAME_HISTORY}
                component={QuantumSpinGameHistoryScreen}
            />
            <RootStack.Screen
                name={ROUTES.QUANTUM_SPIN_PROBABLY_FAIR}
                component={QuantumSpinProbablyFairScreen}
                options={{
                    presentation: 'modal',
                    headerShown: false,
                }}
            />
            <RootStack.Screen
                name={ROUTES.QUANTUM_SPIN_PROBABLY_FAIR_HISTORY}
                component={QuantumSpinProbablyFairHistoryScreen}
                options={{
                    presentation: 'modal',
                    headerShown: false,
                }}
            />
        </>
    );
};

export default QuantumSpinGroup;
