import React from 'react';

import { useUser } from '@contexts/UserContext';

import useThemedStyles from '../../hooks/useThemedStyles';
import i18n from '../../i18n/i18n';
import UserRoleIcon from '../Icons/UserRoleIcon';
import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    size?: number;
    withName?: boolean;
}

const UserRole = ({ withName, size = 20 }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { user, isAdmin, isModerator } = useUser();

    return (
        <View style={styles.avatarWrapper}>
            <View style={styles.avatarInner}>
                {user?.role && <UserRoleIcon size={size} role={user.role} />}
                {withName && (
                    <Text
                        style={[
                            styles.roleText,
                            // add color of the role to the styles
                            user?.role ? styles[user.role] : {},
                        ]}>
                        {i18n.t(`profile.roles.${user?.role}`)}
                    </Text>
                )}
            </View>
        </View>
    );
};

export default UserRole;
