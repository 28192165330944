import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { isWeb } from '../../helpers/app';
import { moderateScale, scale, verticalScale } from '../../helpers/dimensions';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        modal: {
            alignItems: 'center',
        },
        shadow: {
            top: verticalScale(-24),
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].modal.shadowColor,
            maxWidth: scale(240),
            minWidth: scale(150),
        },
        container: {
            backgroundColor: Colors[theme].modal.background,
            borderRadius: moderateScale(12),
            marginBottom: verticalScale(4),
            paddingHorizontal: scale(20),
            paddingVertical: verticalScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            alignItems: 'center',
            maxWidth: scale(200),
        },
        iconWrapper: !isWeb
            ? {
                  height: 60,
              }
            : {},
        cancelLoader: isWeb
            ? {
                  marginTop: verticalScale(5),
              }
            : {},
    });

export default styles;
