import * as React from 'react';

import ImageWithName from '@components/ImageWithName';
import ModalConfirmTransaction from '@components/ModalConfirmTransaction';
import TransactionSummary from '@components/TransactionSummary';
import { useConfig } from '@contexts/ConfigContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { priceString } from '@helpers/payments';
import { feeWithCurrency } from '@helpers/wallet';
import i18n from '@i18n/i18n';
import { MarketplaceSaleablePetOutput } from '@models/marketplace';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
    onConfirm: () => void;
    amount: string;
    pet: MarketplaceSaleablePetOutput;
    estimatedFee: string | undefined;
    commission: string;
    isBNBEnough: boolean;
    infoIcon: (withoutModal?: boolean | undefined) => JSX.Element;
}

const MarketPlaceCreateOrderConfirmationModal = ({
    isVisible,
    onClose,
    onConfirm,
    amount,
    pet,
    estimatedFee,
    isBNBEnough,
    infoIcon,
    commission,
}: IProps) => {
    const { config } = useConfig();
    const transactionDetailsValues = [
        {
            key: i18n.t('checkout.fields.nft'),
            value: <ImageWithName petImg={pet.imageUrl} petName={pet.name} />,
        },
        {
            key: i18n.t('checkout.fields.from'),
            value: i18n.t('checkout.fields.nftWallet'),
        },
        {
            key: i18n.t('checkout.fields.to'),
            value: i18n.t('checkout.fields.nftMarketplace'),
        },
        {
            key: `${i18n.t('checkout.fields.commissionFee')} (${
                config?.marketplaceTransactionFeePercentage || 0
            }%)`,
            value: feeWithCurrency(commission, Coin.igu),
        },
        !!estimatedFee
            ? {
                  key: i18n.t('checkout.fields.fee'),
                  value: feeWithCurrency(estimatedFee),
                  icon: infoIcon(),
              }
            : undefined,
        {
            key: i18n.t('checkout.fields.totalSummary'),
            value: priceString(amount, Coin.igu),
        },
    ];

    return (
        <ModalConfirmTransaction
            title={i18n.t('checkout.fields.modalTitle')}
            isVisible={isVisible}
            close={onClose}
            onConfirm={onConfirm}
            onCancel={onClose}
            disabled={!isBNBEnough}
            textWarning={
                !isBNBEnough
                    ? i18n.t('checkout.errors.bnbNotEnough')
                    : undefined
            }
            showDeposit={!isBNBEnough}
            TransactionDetailsComponent={
                <TransactionSummary values={transactionDetailsValues} />
            }
            modalHeight={transactionDetailsValues.length * 120}
        />
    );
};
export default MarketPlaceCreateOrderConfirmationModal;
