import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(20),
            marginTop: verticalScale(32),
        },
        gap: {
            marginTop: verticalScale(12),
        },
        content: {
            justifyContent: 'center',
            flex: 1,
        },
    });

export default styles;
