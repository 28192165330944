import * as React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import { LinearGradient } from 'expo-linear-gradient';

import Button from '@components/Button';
import { ICON_NAMES } from '@components/Icons';
import { useDimensions } from '@contexts/DimensionsContext';
import { appVersion } from '@helpers/app';
import { verticalScale } from '@helpers/dimensions';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    onPress: () => void;
    onCloseButton: () => void;
}

const BannerUpdate = ({ onPress, onCloseButton }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { windowWidth } = useDimensions();

    const itemWidth = React.useMemo(
        () => windowWidth - verticalScale(20) * 2,
        [windowWidth]
    );

    return (
        <TouchableOpacity
            activeOpacity={0.9}
            onPress={onPress}
            style={styles.container}>
            <View style={[styles.content, { width: itemWidth }]}>
                <LinearGradient
                    colors={['#A77FFD', '#FEDCA9']}
                    style={[styles.gradientBackground, { width: itemWidth }]}
                    start={{ x: 0, y: 0 }}
                    end={{ x: 1, y: 1 }}>
                    <View style={styles.titleWrapper}>
                        <Text style={styles.title}>
                            {i18n.t('updateModal.banner.title')}
                        </Text>
                        <Button
                            isOneColor
                            type="outline"
                            iconName={ICON_NAMES.CLOSE}
                            size="sm"
                            onPress={onCloseButton}
                        />
                    </View>

                    <Text style={styles.text}>
                        {i18n.t('updateModal.banner.text', {
                            version: appVersion,
                        })}
                    </Text>
                </LinearGradient>
            </View>
        </TouchableOpacity>
    );
};
export default BannerUpdate;
