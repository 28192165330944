import { StyleSheet } from 'react-native';

import { isIOS } from '@helpers/app';
import { scale, verticalScale } from '@helpers/dimensions';

import Colors from '../../../../constants/Colors';
import font from '../../../../helpers/font';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: Colors[theme].background,
        },
        page: {
            backgroundColor: Colors[theme].background,
            paddingHorizontal: scale(20),
            paddingTop: verticalScale(16),
        },
        flex: { flex: 1 },
        title: {
            marginLeft: verticalScale(8),
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
        },
        titleWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
        },
        divider: {
            marginBottom: verticalScale(16),
        },
        buttonContainer: {
            paddingHorizontal: scale(20),
            marginBottom: verticalScale(16),
        },
        summary: {
            marginBottom: verticalScale(16),
        },
        button: {
            marginTop: verticalScale(4),
        },
        input: {
            marginBottom: verticalScale(20),
        },
        warning: {
            marginTop: verticalScale(20),
        },
        scanIcon: {
            position: 'absolute',
            top: isIOS ? verticalScale(13) : verticalScale(14),
            right: scale(16),
            zIndex: 5,
        },
        buttonAll: {
            position: 'absolute',
            top: isIOS ? verticalScale(12) : verticalScale(14),
            right: scale(16),
            zIndex: 5,
        },
        wrapperLabel: {
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: verticalScale(6),
        },
        label: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
        },
        labelBold: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
        },
        buttonDeposit: {
            marginTop: verticalScale(8),
        },
    });

export default styles;
