import { StyleSheet } from 'react-native';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        navBar: {
            position: 'absolute',
            backgroundColor: 'transparent',
            width: '100%',
        },
    });

export default styles;
