import * as React from 'react';

import ModalConfirmTransaction from '@components/ModalConfirmTransaction';
import TransactionSummary from '@components/TransactionSummary';
import { feeWithCurrency } from '@helpers/wallet';
import i18n from '@i18n/i18n';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
    onConfirm: () => void;
    infoIcon: (withoutModal?: boolean | undefined) => JSX.Element;
    isBNBEnough: boolean;
    estimatedFee: string | undefined;
}

const NftEvolutionConfirmationMintModal = ({
    isVisible,
    onClose,
    onConfirm,
    infoIcon,
    isBNBEnough,
    estimatedFee,
}: IProps) => {
    const transactionDetailsValues = [
        {
            key: i18n.t('checkout.fields.transaction'),
            value: i18n.t('mintCheckout.transaction'),
        },
        {
            key: i18n.t('checkout.fields.fee'),
            value: feeWithCurrency(estimatedFee),
            icon: infoIcon(),
        },
        {
            key: i18n.t('checkout.fields.totalSummary'),
            value: i18n.t('checkout.free'),
        },
    ];

    return (
        <ModalConfirmTransaction
            shouldCloseOnConfirm={true}
            title={i18n.t('checkout.fields.modalTitle')}
            isVisible={isVisible}
            close={onClose}
            onConfirm={onConfirm}
            onCancel={onClose}
            disabled={!isBNBEnough}
            textWarning={
                !isBNBEnough
                    ? i18n.t('checkout.errors.bnbNotEnough')
                    : undefined
            }
            showDeposit={!isBNBEnough}
            TransactionDetailsComponent={
                <TransactionSummary values={transactionDetailsValues} />
            }
            modalHeight={550}
        />
    );
};
export default NftEvolutionConfirmationMintModal;
