import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        title: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 21,
                weight: '400',
            }),
            letterSpacing: 0.08,
            marginBottom: verticalScale(2),
        },
        address: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '500',
            }),
            letterSpacing: 0.16,
        },
        button: {
            marginTop: verticalScale(8),
            alignSelf: 'center',
        },
    });

export default styles;
