import * as React from 'react';
import { Linking, StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import { Direction } from 'react-native-modal';

import { LINK_TIKTOK, UNLINK_TIKTOK } from '@Data/Requests';
import { ApolloError, useMutation } from '@apollo/client';
import Colors from '@constants/Colors';
import { SOCIALS } from '@constants/Socials';
import { useConfig } from '@contexts/ConfigContext';
import { useDimensions } from '@contexts/DimensionsContext';
import { useTheme } from '@contexts/ThemeContext';
import { useUser } from '@contexts/UserContext';
import { isWeb } from '@helpers/app';
import { verticalScale } from '@helpers/dimensions';
import { errorsHandler } from '@helpers/errors';
import { getSocialLink, getSocialUsername } from '@helpers/getSocialLinks';
import {
    graphQlErrorIncludes,
    isGraphqlError,
    wait,
    waitForToast,
} from '@helpers/helpers';
import { openUrlOrShowError } from '@helpers/linking';
import { toastErrorUnknown, toastSuccess } from '@helpers/toastNotification';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import Button from '../../Button';
import CustomModal from '../../CustomModal';
import Icon, { ICON_NAMES } from '../../Icons';
import ImageIguana from '../../ImageIguana';
import Input from '../../Input';
import Link from '../../Link';
import ModalBottom from '../../ModalBottom';
import ModalLoader from '../../ModalLoader';
import { Text, View } from '../../Themed';
import SocialMediaItem from '../SocialMediaItem';
import { SocialMediaPreview } from '../SocialMediaPreview';
import stylesMain from './styles';

interface IProps {
    withArrow?: boolean;
    onCompletedLinking?: (status: boolean) => void;
    onCompletedUnLinking?: (status: boolean) => void;
    setOuterModalHeightOnOpen?: () => void;
    setOuterModalHeightOnClose?: () => void;
    backdropOpacity?: number;
    swipeDirection?: Direction[] | Direction;
    animation?: any;
    containerStyle?: StyleProp<ViewStyle>;
    nestedModal?: boolean;
    withPreview?: boolean;
}

const ConnectTikTok = ({
    onCompletedLinking,
    onCompletedUnLinking,
    setOuterModalHeightOnOpen,
    setOuterModalHeightOnClose,
    swipeDirection,
    backdropOpacity,
    animation,
    withArrow = false,
    containerStyle,
    nestedModal = false,
    withPreview = true,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { theme } = useTheme();
    const { safeBottom } = useDimensions();

    const { reloadUser } = useUser();
    const [unlinkTiktok, { loading: unlinkTiktokLoading }] =
        useMutation(UNLINK_TIKTOK);
    const [linkTiktok, { loading: linkTiktokLoading }] =
        useMutation(LINK_TIKTOK);
    const { config } = useConfig();

    const [value, setValue] = React.useState('');

    const [disconnectModal, setDisconnectModal] = React.useState(false);
    const [textErrorLinkTiktok, setTextErrorLinkTiktok] = React.useState('');
    const [tiktokModal, setTiktokModal] = React.useState(false);
    const [socialPreviewVisible, setSocialPreviewVisible] =
        React.useState<boolean>(false);
    const [previewLink, setPreviewLink] = React.useState<string>('');

    React.useEffect(() => {
        if (previewLink) {
            setSocialPreviewVisible(true);
        }
    }, [previewLink]);

    const handleUnlinkTiktok = async () => {
        await unlinkTiktok({
            onCompleted: async () => {
                await waitForToast();
                toastSuccess(
                    i18n.t('socialMediaConnect.success.unLinkTiktok'),
                    undefined
                );
                await reloadUser();
            },
            onError: (error) => {
                toastErrorUnknown();
                errorsHandler(error);
            },
        });
    };

    const onTextChange = React.useCallback(
        (text: string) => {
            setTextErrorLinkTiktok('');
            if (text === value) {
                return;
            }
            setValue(getSocialUsername(text, value));
        },
        [value]
    );

    const handleLinkTiktok = async (username: string) => {
        await linkTiktok({
            variables: {
                username,
            },
            onError: (error) => handleErrors(error),
            onCompleted: async () => {
                await reloadUser();
                onCompletedLinking?.(true);
                handleCloseModal();
                handleCloseSocialPreview();
            },
        });
    };

    const handleErrors = (error: ApolloError) => {
        setSocialPreviewVisible(false);
        if (isGraphqlError(error, 'TIKTOK_USERNAME_NOT_FOUND')) {
            setTextErrorLinkTiktok(
                i18n.t('socialMediaConnect.connectTiktokModal.errors.notExist')
            );
            return;

            // Followers not enough error
        } else if (
            graphQlErrorIncludes(
                error,
                'TIKTOK_USERNAME_VALIDATION_FAILED',
                'followers'
            )
        ) {
            setTextErrorLinkTiktok(
                i18n.t(
                    'socialMediaConnect.connectTiktokModal.errors.likeFollowers'
                )
            );
            return;

            // cannot load error
        } else if (isGraphqlError(error, 'TIKTOK_USERNAME_VALIDATION_FAILED')) {
            setTextErrorLinkTiktok(
                i18n.t(
                    'socialMediaConnect.connectTiktokModal.errors.cannotLoad'
                )
            );
            return;
        } else if (isGraphqlError(error, 'TIKTOK_NOT_LINKED')) {
            setTextErrorLinkTiktok(
                i18n.t('socialMediaConnect.connectTiktokModal.errors.notLinked')
            );
            return;
        } else if (isGraphqlError(error, 'TIKTOK_ALREADY_LINKED')) {
            setTextErrorLinkTiktok(
                i18n.t(
                    'socialMediaConnect.connectTiktokModal.errors.alreadyLinked'
                )
            );
            return;
        }

        setTextErrorLinkTiktok(
            i18n.t(
                'socialMediaConnect.connectTiktokModal.errors.somethingWrong'
            )
        );
        return;
    };

    const handleOnConnectPress = () => {
        setTiktokModal(true);
        setOuterModalHeightOnOpen?.();
    };
    const handleOnDisconnectPress = () => {
        setDisconnectModal(true);
    };

    const handleOnModalConnect = React.useCallback(() => {
        if (withPreview) {
            setPreviewLink(getSocialLink('tiktok', value));
            return;
        }
        handleLinkTiktok(value);
    }, [value, withPreview]);

    const handleCloseSocialPreview = () => {
        setPreviewLink('');
        setSocialPreviewVisible(false);
    };

    const handleSocialPreview = React.useCallback(() => {
        handleLinkTiktok(value);
    }, [value]);

    const closeDisconnectModal = () => {
        setDisconnectModal(false);
    };
    const handleDisconnectButtonModal = () => {
        setDisconnectModal(false);
        handleUnlinkTiktok();
    };

    const handleCloseModal = () => {
        setOuterModalHeightOnClose?.();
        setTiktokModal(false);
    };

    const onPressPrivacyPolicy = () => {
        if (config?.privacyPolicyUrl) {
            openUrlOrShowError(config.privacyPolicyUrl);
        }
    };

    const onPressTermsOfUse = () => {
        if (config?.termsOfUseUrl) {
            openUrlOrShowError(config.termsOfUseUrl);
        }
    };

    return (
        <View style={containerStyle}>
            <SocialMediaItem
                social={SOCIALS.tiktok}
                onConnectPress={handleOnConnectPress}
                onDisconnectPress={handleOnDisconnectPress}
            />
            <CustomModal
                isVisible={disconnectModal}
                icon={<ImageIguana type="cry" />}
                titleText={i18n.t('socialMediaConnect.errors.disconnect.title')}
                infoText={i18n.t('socialMediaConnect.errors.disconnect.text')}
                firstButtonType="critical"
                firstButtonText={i18n.t(
                    'socialMediaConnect.errors.disconnect.button1'
                )}
                secondButtonText={i18n.t(
                    'socialMediaConnect.errors.disconnect.button2'
                )}
                onFirstButtonClick={handleDisconnectButtonModal}
                onSecondButtonClick={closeDisconnectModal}
                isNestedModal={nestedModal}
            />
            <ModalLoader
                isVisible={unlinkTiktokLoading}
                text={i18n.t('general.loading')}
            />
            <ModalBottom
                avoidKeyboard
                onClose={handleCloseModal}
                isVisible={tiktokModal}
                swipeDirection={swipeDirection || 'down'}
                backdropOpacity={
                    backdropOpacity === 0
                        ? backdropOpacity
                        : backdropOpacity || 0.7
                }
                animation={animation}
                modalHeight={isWeb ? '440' : '55%'}
                isNestedModal={nestedModal}
                withToasts={false}>
                <View style={styles.container}>
                    <View style={styles.topWrapper}>
                        {withArrow && (
                            <TouchableOpacity
                                onPress={handleCloseModal}
                                activeOpacity={0.7}>
                                <Icon name={ICON_NAMES.ARROW_LEFT} />
                            </TouchableOpacity>
                        )}
                        <Text style={styles.title}>
                            {i18n.t(
                                'socialMediaConnect.connectTiktokModal.title'
                            )}
                        </Text>
                    </View>
                    <View style={styles.inputWrapper}>
                        <Input
                            label={i18n.t(
                                'socialMediaConnect.connectTiktokModal.label'
                            )}
                            style={styles.input}
                            onChangeText={(text) => onTextChange(text)}
                            value={value}
                            placeholder={i18n.t(
                                'socialMediaConnect.connectTiktokModal.placeHolder'
                            )}
                            placeholderTextColor={Colors[theme].text.secondary}
                            error={textErrorLinkTiktok}
                        />
                    </View>
                    <View
                        style={[
                            styles.buttonWrapper,
                            { paddingBottom: verticalScale(16 + safeBottom) },
                        ]}>
                        <Button
                            title={i18n.t(
                                'socialMediaConnect.connectTiktokModal.button'
                            )}
                            disabled={!value || !!textErrorLinkTiktok}
                            onPress={handleOnModalConnect}
                        />
                        <Text style={styles.termText}>
                            {i18n.t('socialMediaConnect.modalTerms.start')}
                            <Link
                                onPress={onPressPrivacyPolicy}
                                textStyles={styles.link}>
                                {i18n.t(
                                    'socialMediaConnect.modalTerms.privacyPolicy'
                                )}
                            </Link>
                            {i18n.t('socialMediaConnect.modalTerms.and')}
                            <Link
                                onPress={onPressTermsOfUse}
                                textStyles={styles.link}>
                                {i18n.t(
                                    'socialMediaConnect.modalTerms.termOfUse'
                                )}
                            </Link>
                        </Text>
                    </View>
                    <ModalLoader
                        isVisible={linkTiktokLoading}
                        text={i18n.t('general.loading')}
                    />
                </View>
                {previewLink ? (
                    <SocialMediaPreview
                        visible={socialPreviewVisible}
                        link={previewLink}
                        social={SOCIALS.tiktok}
                        value={value}
                        successModalButton={handleSocialPreview}
                        handleCloseModal={handleCloseSocialPreview}
                    />
                ) : null}
            </ModalBottom>
        </View>
    );
};

export default ConnectTikTok;
