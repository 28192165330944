import * as React from 'react';

import CheckoutSuccessScreen from '../../../../components/CheckoutSuccessScreen';
import TransactionSummary from '../../../../components/TransactionSummary';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import ROUTES from '../../../../navigation/routes';
import { useNavigation } from '../../../../navigation/useNavigation';
import { WithdrawSuccessProps } from '../../../../types';
import stylesMain from './styles';

const NFTSWithdrawSuccessScreen = ({ route }: WithdrawSuccessProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const {
        addressFrom,
        addressTo,
        transactionValue,
        transactionCoin,
        transactionResult,
        balance,
    } = route.params;

    const handleViewTransactionsHistory = () => {
        navigation.pop();
        navigation.navigate(ROUTES.WALLET_COINS_BALANCE, { balance });
    };

    const handleBackToWallet = () => {
        navigation.pop();
        navigation.navigateToCoinsWallet();
    };

    navigation.blockHardwareBack();

    const renderTransactionDetailComponent = () => {
        return (
            <>
                <TransactionSummary
                    values={[
                        {
                            key: i18n.t('checkout.fields.from'),
                            value: i18n.t('checkout.wallet', {
                                coin: transactionCoin,
                            }),
                        },
                        {
                            key: i18n.t('checkout.fields.to'),
                            value: addressTo,
                        },
                        {
                            key: i18n.t('checkout.fields.value'),
                            value: `${transactionValue} ${transactionCoin}`,
                        },
                        {
                            key: i18n.t('checkout.fields.fee'),
                            value: transactionResult.transactionFeeFormatted,
                        },
                    ]}
                />
            </>
        );
    };

    return (
        <>
            <CheckoutSuccessScreen
                title={i18n.t('withdrawScreen.withdrawSuccess.title')}
                text={i18n.t('withdrawScreen.withdrawSuccess.text')}
                onPressButtonMain={handleBackToWallet}
                buttonMainText={i18n.t(
                    'withdrawScreen.withdrawSuccess.backToWallet'
                )}
                onPressButtonAdditional={handleViewTransactionsHistory}
                buttonAdditionalText={i18n.t(
                    'withdrawScreen.withdrawSuccess.viewTransactionHistory'
                )}
                TransactionDetailsComponent={renderTransactionDetailComponent()}
                explorerUrl={transactionResult.explorerUrl}
            />
        </>
    );
};

export default NFTSWithdrawSuccessScreen;
