import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        dailyUnlock: {
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: verticalScale(12),
        },
        unlock: {
            color: Colors[theme].text.accent,
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
            letterSpacing: 0.08,
            marginLeft: scale(6),
        },
        unlockBold: {
            color: Colors[theme].text.accent,
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
        },
        buttonWrapper: {
            backgroundColor: 'transparent',
            marginTop: verticalScale(16),
        },
        deadButtonText: {
            color: Colors[theme].text.accent,
        },
        deadButton: {
            backgroundColor: Colors[theme].text.onLight,
            borderColor: Colors[theme].border.white,
        },
        buttonContainerStyle: {
            backgroundColor: Colors[theme].shadow.white,
        },
        wrapper: {
            marginTop: verticalScale(10),
        },
        item: {
            alignItems: 'center',
            flexDirection: 'row',
            marginBottom: verticalScale(10),
        },
        text: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
        },
        textContainer: {
            marginLeft: scale(4),
            flex: 1,
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        value: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
        },
        valueHighlight: {
            color: Colors[theme].text.accent,
        },
    });

export default styles;
