export interface IUpdateData {
    en: string;
    uk?: string;
    pl?: string;
    ru?: string;
    id?: string;
    tr?: string;
    es?: string;
}

export interface IUpdate {
    title: IUpdateData;
    data: IUpdateData[];
}

export const SHOW_UPDATE = true;

export const UPDATES_DATA: IUpdate[] = [
    {
        title: {
            en: 'New Features',
            uk: 'Функціонал',
            pl: 'Nowe funkcjonalności',
            ru: 'Новый функционал',
            id: 'Fitur Baru',
            tr: 'Yeni Özellikler',
            es: 'Novedades',
        },
        data: [
            {
                en: 'Tokenteller Release',
                ru: 'Выход Игумании',
                uk: 'Вихід Ігуманії',
                pl: 'Wydanie Tokentellera',
                id: 'Wydanie Tokentellera',
                tr: 'Tokenteller Sürümü',
                es: 'Lanzamiento del Tokenteller',
            },
        ],
    },
    {
        title: {
            en: 'Bug fixes',
            uk: 'Баг-фікси',
            pl: 'Usunięcie usterek',
            ru: 'Баг-фиксы',
            id: 'Perbaikan bug',
            tr: 'Hata düzeltmeleri',
            es: 'Perbaikan bug',
        },
        data: [
            {
                en: 'Bug Fixes and Improvements',
                uk: 'Інші виправлення помилок та покращення',
                pl: 'Inne poprawki błędów i ulepszenia',
                ru: 'Другие исправления ошибок и улучшения',
                id: 'Perbaikan dan peningkatan bug lainnya',
                tr: 'Diğer hata düzeltmeleri ve iyileştirmeler',
                es: 'Otras correcciones de errores y mejoras',
            },
            {
                en: 'Lucky Strike Issues Fixed',
                uk: 'Виправлені помилки в Лакі Страйк',
                pl: 'Naprawiono problemy  w Lucky Strike',
                ru: 'Исправлены ошибки в Лаки Страйк',
                id: 'Masalah Lucky Strike Diperbaiki',
                tr: 'Lucky Strike Sorunları Düzeltildi',
                es: 'Problemas de Lucky Strike solucionados',
            },
        ],
    },
];
