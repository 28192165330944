import * as React from 'react';
import { useMemo } from 'react';

import {
    ClaimBalanceResponse,
    PendingClaimBalanceResponse,
} from '@Data/Models';
import { CLAIM_BALANCE, PENDING_CLAIM_BALANCE } from '@Data/Requests';
import { useLazyQuery } from '@apollo/client';
import { useMutation } from '@apollo/client/react/hooks/useMutation';
import EmptyList from '@components/EmptyList';
import GestureRootView from '@components/GestureRootView';
import ListFilter, { IFilterData } from '@components/ListFilter';
import LoadingComponent from '@components/LoadingComponent';
import ModalLoader from '@components/ModalLoader';
import PullToRefresh from '@components/PullToRefresh';
import SafeAreaTabContent from '@components/SafeAreaTabContent';
import { Text, View } from '@components/Themed';
import { useDimensions } from '@contexts/DimensionsContext';
import { useStaking } from '@contexts/StakingContext';
import { useUser } from '@contexts/UserContext';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { isGraphqlError } from '@helpers/helpers';
import { toastError, toastErrorUnknown } from '@helpers/toastNotification';
import { balanceFromWei } from '@helpers/wallet';
import useBlockchainErrorsHandler from '@hooks/useBlockchainErrorsHandler';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import useWalletRewards, {
    RewardsFilterType,
    rewardsConfig,
} from '@hooks/useWalletRewards';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';
import NavigationWallet, {
    NAVIGATION_WALLET,
} from '@navigation/NavigationBar/NavigationWallet';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import { WalletRewardsMainProps } from '../../../../types';
import ErrorReporting from '../../../../utils/ErrorReporting';
import RewardsButtons from '../RewardsButtons/RewardsButtons';
import HowToClaimFreePetModal from '../components/HowToClaimFreePetModal';
import RewardsCarousel, { IRewardCard } from '../components/RewardsCarousel';
import RewardsTransactionHistory from '../components/RewardsTransactionHistory';
import stylesMain from './styles';

const RewardsMainScreen = ({ route }: WalletRewardsMainProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const { isSmallLayout } = useDimensions();
    const { isLeader } = useUser();
    const {
        isVisible: isVisibleHowToClaimFree,
        open: openHowToClaimFree,
        close: closeHowToClaimFree,
    } = useVisibleHook();

    const [claimBalance] = useMutation<ClaimBalanceResponse>(CLAIM_BALANCE);
    const [pendingClaimBalance] = useLazyQuery<PendingClaimBalanceResponse>(
        PENDING_CLAIM_BALANCE
    );

    const { priceInUsd, collectReward } = useWallet();

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const {
        currentStakingPlan,
        userStakeLoading,
        stakingPlansLoading,
        refetchStakingPlans,
        currentStakingBoostLoading,
    } = useStaking();

    const {
        userBalance,
        pullToRefreshLoading,
        transactionHistory,
        transactionHistoryLoading,
        filter,
        filterLoading,
        loadMore,
        getUserBalance,
        getTransactionHistory,
        getTransactionHistoryFilter,
        getTransactionHistoryLoadMore,
    } = useWalletRewards(route.params?.filter);

    const filterData = useMemo(
        () =>
            [
                {
                    key: rewardsConfig.filters[0],
                    label: i18n.t(
                        `wallet.rewards.filter.${rewardsConfig.filters[0]}`
                    ),
                },
                {
                    key: rewardsConfig.filters[1],
                    label: i18n.t(
                        `wallet.rewards.filter.${rewardsConfig.filters[1]}`
                    ),
                },
                {
                    key: rewardsConfig.filters[2],
                    label: i18n.t(
                        `wallet.rewards.filter.${rewardsConfig.filters[2]}`
                    ),
                },
                {
                    key: rewardsConfig.filters[3],
                    label: i18n.t(
                        `wallet.rewards.filter.${rewardsConfig.filters[3]}`
                    ),
                },

                isLeader
                    ? {
                          key: rewardsConfig.filters[4],
                          label: i18n.t(
                              `wallet.rewards.filter.${rewardsConfig.filters[4]}`
                          ),
                      }
                    : null,
                {
                    key: rewardsConfig.filters[5],
                    label: i18n.t(
                        `wallet.rewards.filter.${rewardsConfig.filters[5]}`
                    ),
                },
            ].filter((item) => !!item) as IFilterData[],
        [isLeader]
    );

    React.useEffect(() => {
        getUserBalance(true);
        getTransactionHistory(true);
    }, [getTransactionHistory]);

    // use blockchain errors
    const { handleBlockchainErrors, renderBlockchainErrorsModal } =
        useBlockchainErrorsHandler();

    const reloadData = async (silent: boolean) => {
        const balance = getUserBalance(silent);
        const transaction = getTransactionHistory(silent);
        const plans = refetchStakingPlans();
        return Promise.all([balance, transaction, plans]);
    };

    const [isRefresh, setIsRefresh] = React.useState<boolean>(false);
    const onRefresh = async () => {
        setIsRefresh(true);
        await reloadData(false);
        setIsRefresh(false);
    };

    const handleSettingsPress = () => {
        navigation.navigate(ROUTES.WALLET_SETTINGS);
    };

    const handleChooseFilter = (item: string) => {
        getTransactionHistoryFilter(item as RewardsFilterType);
    };

    const handleClaim = async () => {
        const response = await claimBalance();
        if (!response.data) {
            return undefined;
        }
        const claimResult = await collectReward(
            response.data.claimBalance.signedTransaction
        );
        return {
            result: claimResult,
            amount: response.data.claimBalance.paymentOption.amountWei,
        };
    };

    const handlePendingClaim = async () => {
        const response = await pendingClaimBalance();
        if (!response.data) {
            return undefined;
        }
        const pendingClaimResult = await collectReward(
            response.data.pendingBalanceClaim.signedTransaction
        );
        return {
            result: pendingClaimResult,
            amount: response.data.pendingBalanceClaim.paymentOption.amountWei,
        };
    };

    const onClaimBalance = React.useCallback(async () => {
        try {
            setIsLoading(true);
            const response = userBalance?.pendingBalanceClaim
                ? await handlePendingClaim()
                : await handleClaim();

            await reloadData(false);
            setIsLoading(false);
            if (!response) return;

            navigation.replace(ROUTES.WALLET_REWARDS_CLAIM_SUCCESS, {
                total: balanceFromWei(response.amount).valueLong,
                transactionResult: response.result,
            });
        } catch (error) {
            Console.error(error);
            setIsLoading(false);

            if (isGraphqlError(error, 'CLAIM_NO_SELF_MINTED_PETS')) {
                openHowToClaimFree();
            } else if (
                // Not enough tokens on contract balance
                error instanceof Error &&
                error
                    .toString()
                    .indexOf(
                        'execution reverted: ERC20: transfer amount exceeds balance'
                    )
            ) {
                toastError(undefined, i18n.t('wallet.rewards.claimBusy'));
            } else if (!handleBlockchainErrors(error)) {
                ErrorReporting.report(error);
                toastErrorUnknown();
            }

            await reloadData(false);
        }
    }, [userBalance]);

    const rewardsCardsData = React.useMemo((): IRewardCard[] => {
        if (!userBalance) {
            return [];
        }

        const claimAmount = balanceFromWei(
            userBalance?.pendingBalanceClaim?.paymentOption?.amountWei ??
                userBalance?.userBalance.available
        );
        const lockedAmount = balanceFromWei(userBalance?.userBalance.locked);

        return [
            {
                id: userBalance.pendingBalanceClaim
                    ? 'pendingRewards'
                    : 'availableRewards',
                igupAmount: claimAmount.valueLong,
                usdPrice: priceInUsd(Coin.igup, claimAmount.value),
                canClaim: Number(claimAmount.value) > 0,
                withFAQ: true,
                reclaim: !!userBalance.pendingBalanceClaim,
            },
            {
                id: 'lockedRewards',
                igupAmount: lockedAmount.valueLong,
                usdPrice: priceInUsd(Coin.igup, lockedAmount.value),
                withFAQ: true,
            },
        ];
    }, [userBalance, priceInUsd]);

    const handleStakePress = React.useCallback(async () => {
        if (currentStakingPlan) {
            await refetchStakingPlans();
            navigation.navigate(ROUTES.STAKING_ALREADY);
            return;
        }
        navigation.navigate(ROUTES.STAKING_WELCOME);
    }, [currentStakingPlan]);

    const stakingButtonLoading = React.useMemo(() => {
        if (isRefresh) {
            return false;
        }
        return (
            userStakeLoading ||
            currentStakingBoostLoading ||
            stakingPlansLoading
        );
    }, [
        isRefresh,
        userStakeLoading,
        currentStakingBoostLoading,
        stakingPlansLoading,
    ]);

    const renderItem = React.useCallback(() => {
        return (
            <>
                <View style={styles.carouselWrapper}>
                    <RewardsCarousel data={rewardsCardsData} />
                </View>
                <View style={styles.buttonsWrapper}>
                    <RewardsButtons
                        data={rewardsCardsData}
                        onClaimConfirmPress={onClaimBalance}
                        onStakePress={handleStakePress}
                        disabled={userStakeLoading || stakingPlansLoading}
                        stakeActive={!!currentStakingPlan}
                        loading={stakingButtonLoading}
                    />
                </View>
                <View style={styles.headerText}>
                    <Text style={styles.headerTitle}>
                        {i18n.t('wallet.rewards.transactionHistoryText')}
                    </Text>

                    <ListFilter
                        titleText={i18n.t('wallet.rewards.filter.title')}
                        onSelect={handleChooseFilter}
                        selectedItem={filter}
                        data={filterData}
                    />
                </View>
                {filterLoading || transactionHistoryLoading ? (
                    <LoadingComponent text={i18n.t('general.loading')} />
                ) : transactionHistory ? (
                    transactionHistory.length ? (
                        <View style={styles.rewardsHistoryWrapper}>
                            <RewardsTransactionHistory
                                data={transactionHistory}
                                userBalance={userBalance}
                                onReachEnd={getTransactionHistoryLoadMore}
                                loadMore={loadMore}
                            />
                        </View>
                    ) : (
                        <View
                            style={
                                isSmallLayout
                                    ? styles.emptyStateWrapperSmall
                                    : styles.emptyStateWrapper
                            }>
                            <EmptyList
                                containerStyle={styles.emptyList}
                                emptyTitle={i18n.t(
                                    'wallet.rewards.empty.title'
                                )}
                                emptyText={i18n.t('wallet.rewards.empty.text')}
                                withAnimation="cry"
                            />
                        </View>
                    )
                ) : null}
            </>
        );
    }, [
        styles,
        rewardsCardsData,
        onClaimBalance,
        handleStakePress,
        userStakeLoading,
        stakingPlansLoading,
        currentStakingPlan,
        handleChooseFilter,
        filter,
        filterData,
        filterLoading,
        transactionHistoryLoading,
        transactionHistory,
        userBalance,
        loadMore,
        isSmallLayout,
        getTransactionHistoryLoadMore,
        stakingButtonLoading,
    ]);

    const onPressMintPet = () => {
        closeHowToClaimFree();
        navigation.navigateToMint();
    };

    return (
        <SafeAreaTabContent>
            <View style={styles.container}>
                <NavigationBar
                    backIcon
                    title={i18n.t('wallet.screenTitle')}
                    settingsIcon
                    onPressSettings={handleSettingsPress}
                />
                <NavigationWallet
                    currentNavigation={NAVIGATION_WALLET.REWARDS}
                />
                <GestureRootView style={styles.gestureContainer}>
                    <PullToRefresh
                        refreshing={pullToRefreshLoading}
                        textLoading={i18n.t('pullToRefresh.reloadRewards')}
                        onRefresh={onRefresh}
                        renderItem={renderItem}
                    />
                </GestureRootView>
            </View>
            {renderBlockchainErrorsModal()}
            <ModalLoader
                isVisible={isLoading}
                text={i18n.t('wallet.rewards.claimLoading')}
            />
            <HowToClaimFreePetModal
                isVisible={isVisibleHowToClaimFree}
                onClose={closeHowToClaimFree}
                onPress={onPressMintPet}
            />
        </SafeAreaTabContent>
    );
};

export default RewardsMainScreen;
