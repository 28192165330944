import { StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import { scale, verticalScale } from '../../../../helpers/dimensions';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        divider: {
            borderTopWidth: 0.5,
            borderTopColor: Colors[theme].border.primary,
            marginVertical: verticalScale(12),
        },
        titleAdminStyle: {
            color: Colors[theme].text.critical,
        },
        textAdminStyle: {
            color: Colors[theme].text.critical,
        },
        icon: {
            color: Colors[theme].icon.secondary,
        },
    });

export default styles;
