import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import NftRoyalGameModsIcon from '@components/Icons/NftRoyalGameModsIcon';
import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { NFT_ROYAL_GAME_MODE } from '@models/mysteryGames';

import GameConfigCard from '../GameConfigCard';
import stylesMain from './styles';

interface IProps {
    modes: NFT_ROYAL_GAME_MODE[];
    onPress: (mode: NFT_ROYAL_GAME_MODE) => void;
    selectedMode: NFT_ROYAL_GAME_MODE | null;
    containerStyle?: StyleProp<ViewStyle>;
}

const NftRoyalGameModes = ({
    modes,
    onPress,
    selectedMode,
    containerStyle,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={[styles.container, containerStyle]}>
            {modes.map((i, index) => (
                <GameConfigCard
                    containerStyle={styles.itemWidth}
                    key={i + index}
                    onPress={() => onPress(i)}
                    title={i18n.t(
                        `HomeScreen.NftRoyal.NftRoyalGameSettings.gameMode.${i}.title`
                    )}
                    text={i18n.t(
                        `HomeScreen.NftRoyal.NftRoyalGameSettings.gameMode.${i}.text`
                    )}
                    icon={<NftRoyalGameModsIcon slug={i} />}
                    isSelected={selectedMode === i}
                />
            ))}
        </View>
    );
};
export default React.memo(NftRoyalGameModes);
