import { StyleSheet } from 'react-native';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        cardWidth: {
            width: '100%',
            flex: 1,
        },
    });

export default styles;
