import * as React from 'react';

import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';

import { useNavigation } from '../../useNavigation';
import NavigationItem from '../NavigationItem';
import stylesMain from './styles';

interface IProps {
    currentNavigation: NAVIGATION_TOKEN_TELLER;
}

export enum NAVIGATION_TOKEN_TELLER {
    ROUNDS = 'ROUNDS',
    HISTORY = 'HISTORY',
}

const data: NAVIGATION_TOKEN_TELLER[] = [
    NAVIGATION_TOKEN_TELLER.ROUNDS,
    NAVIGATION_TOKEN_TELLER.HISTORY,
];

const NavigationTokenTeller = ({ currentNavigation }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const handleNavigation = (item: NAVIGATION_TOKEN_TELLER) => {
        switch (item) {
            case NAVIGATION_TOKEN_TELLER.ROUNDS:
                navigation.navigate(ROUTES.TOKEN_TELLER_MAIN);
                break;
            case NAVIGATION_TOKEN_TELLER.HISTORY:
                navigation.navigate(ROUTES.TOKEN_TELLER_HISTORY);
                break;
        }
    };

    return (
        <View style={styles.main}>
            <View style={styles.shadow}>
                <View style={styles.container}>
                    {data.map((item) => (
                        <NavigationItem
                            key={item}
                            onPress={() => handleNavigation(item)}
                            title={i18n.t(
                                `HomeScreen.GamesNavigationBar.${item}`
                            )}
                            isActive={currentNavigation === item}
                            containerStyles={styles.item}
                        />
                    ))}
                </View>
            </View>
        </View>
    );
};
export default NavigationTokenTeller;
