import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: Colors[theme].background,
        },
        safe: {
            backgroundColor: Colors[theme].background,
        },
        mainContent: {
            alignItems: 'center',
            paddingHorizontal: scale(20),
            backgroundColor: Colors[theme].background,
        },
        playEnergyWrapper: {
            width: '100%',
            marginVertical: verticalScale(35),
        },
    });

export default styles;
