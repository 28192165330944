import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { moderateScale, scale, verticalScale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        textWrapper: {
            marginTop: verticalScale(24),
            marginBottom: verticalScale(20),
        },
        inputWrapper: {
            marginBottom: verticalScale(16),
        },
        noCode: {
            alignContent: 'center',
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
        apply: {
            color: Colors[theme].text.accent,
            ...font({
                size: 14,
                weight: '700',
            }),
            letterSpacing: 0.16,
        },

        buttonWrapper: {
            paddingVertical: verticalScale(16),
        },
        noCodeWrapper: {
            flexDirection: 'row',
            marginTop: verticalScale(8),
            alignItems: 'center',
        },
    });

export default styles;
