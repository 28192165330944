import * as React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import MediumLogo from '@assets/icons/wallet/mediumLogo.svg';
import AlertCard from '@components/AlertCard';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import Button from '@components/Button';
import ButtonsBottom from '@components/ButtonsBottom';
import Icon, { ICON_NAMES } from '@components/Icons';
import { Text, View } from '@components/Themed';
import { reloadApp } from '@helpers/app';
import { errorsHandler } from '@helpers/errors';
import { openUrlOrShowError } from '@helpers/linking';
import { useHaptic } from '@hooks/useHaptic';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBarModal from '@navigation/NavigationBar/NavigationBarModal';
import useNavigationListeners from '@navigation/hooks/useNavigationListeners';
import { useNavigation } from '@navigation/useNavigation';

import stylesMain from './styles';

export const SPACE_ID_LINK = 'https://space.id/';

const links = [
    {
        id: 'first',
        text: 'Join the Movement: Introducing SPACE ID DAO & $ID Token Buyback',
        uri: 'https://blog.space.id/join-the-movement-introducing-space-id-dao-id-token-buyback-6833d379c767',
    },
    {
        id: 'second',
        text: 'From Beta to Better: the Full Release of SPACE ID 2.0 & Voyage Season 2 — Striking',
        uri: 'https://blog.space.id/from-beta-to-better-the-full-release-of-space-id-2-0-voyage-season-2-striking-5f3cf7b75435',
    },
    {
        id: 'third',
        text: 'Introducing SPACE ID Token (ID), Landing on Binance Launchpad',
        uri: 'https://blog.space.id/introducing-space-id-token-id-landing-on-binance-launchpad-10abe10779a2',
    },
];

const HowToPurchaseSpadeIdScreen = () => {
    useNavigationListeners();
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const { triggerMedium } = useHaptic();

    const onLinkPress = async (uri: string) => {
        triggerMedium();
        try {
            await openUrlOrShowError(uri);
        } catch (error) {
            errorsHandler(error, true);
        }
    };

    const renderResourceItem = (item: {
        id: string;
        uri: string;
        text: string;
    }) => {
        return (
            <TouchableOpacity
                key={item.id}
                style={styles.resource}
                activeOpacity={0.8}
                onPress={() => onLinkPress(item.uri)}>
                <View style={styles.right}>
                    <View style={styles.img}>
                        <MediumLogo
                            width={24}
                            height={24}
                            color={styles.icon.color}
                        />
                    </View>
                    <Text style={styles.resourceText}>{item.text}</Text>
                </View>
                <View style={styles.linkImg}>
                    <Icon
                        name={ICON_NAMES.EXPLORE}
                        color={styles.icon.color}
                        size={16}
                    />
                </View>
            </TouchableOpacity>
        );
    };

    return (
        <View style={styles.main}>
            <View style={styles.header}>
                <NavigationBarModal
                    iconName={ICON_NAMES.CLOSE}
                    title={i18n.t('SpaceId.how.screenTitle')}
                    onBack={() => navigation.pop()}
                />
            </View>
            <ScrollView
                showsVerticalScrollIndicator={false}
                style={styles.container}>
                <BlockHeader
                    type="primary"
                    title={i18n.t('SpaceId.how.title')}
                    text={i18n.t('SpaceId.how.text')}
                />

                <View style={styles.guideBlock}>
                    <View style={styles.guide}>
                        <View style={styles.circle} />
                        <Text style={styles.text}>
                            {i18n.t('SpaceId.how.guideBlock.first')}
                        </Text>
                    </View>
                    <View style={styles.guide}>
                        <View style={styles.circle} />
                        <Text style={styles.text}>
                            {i18n.t('SpaceId.how.guideBlock.second')}
                        </Text>
                    </View>
                    <View style={styles.guide}>
                        <View style={styles.circle} />
                        <Text style={styles.text}>
                            {i18n.t('SpaceId.how.guideBlock.third')}
                        </Text>
                    </View>
                    <View style={styles.guide}>
                        <View style={styles.circle} />
                        <Text style={styles.text}>
                            {i18n.t('SpaceId.how.guideBlock.fourth')}
                        </Text>
                    </View>
                    <AlertCard
                        containerStyle={styles.alertCard}
                        text={i18n.t('SpaceId.how.guideBlock.alert')}
                    />
                </View>
                {!!links && (
                    <View style={styles.resourceWrapper}>
                        <Text style={styles.title}>
                            {i18n.t('howTo.purchaseBNB.resourceBlock.title')}
                        </Text>
                        <View style={styles.resourceBlock}>
                            {links.map((item) => renderResourceItem(item))}
                        </View>
                    </View>
                )}
            </ScrollView>
            <ButtonsBottom
                containerStyle={styles.buttons}
                title={i18n.t('SpaceId.how.button1')}
                onPress={() => onLinkPress(SPACE_ID_LINK)}
                tailTitle
                safe>
                <Button
                    onPress={reloadApp}
                    title={i18n.t('general.BUTTONS.reloadApp')}
                    debouncedPress
                    type="outline"
                    containerStyle={styles.button}
                />
            </ButtonsBottom>
        </View>
    );
};

export default HowToPurchaseSpadeIdScreen;
