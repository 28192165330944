import * as React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import ICON from '@assets/icons/emptyState/whatIsAchievements.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import ModalBottom from '@components/ModalBottom';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
    modalHeight?: number | string;
    onAction: () => void;
}

const WhatIsAchievementsModal = ({
    isVisible,
    onClose,
    onAction,
    modalHeight = 650,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <ModalBottom
            isVisible={isVisible}
            onClose={onClose}
            modalHeight={modalHeight}>
            <ScrollView
                style={styles.container}
                showsVerticalScrollIndicator={false}>
                <TouchableOpacity activeOpacity={1}>
                    <BlockHeader
                        containerStyle={styles.header}
                        emptyStateIcon={<ICON />}
                        type="emptyState"
                        title={i18n.t('Achievements.modal.title')}
                        text={i18n.t('Achievements.modal.text')}
                    />
                </TouchableOpacity>
            </ScrollView>

            <ButtonsBottom
                title={i18n.t(`wallet.nft.viewAll`)}
                onPress={onAction}
                safe
            />
        </ModalBottom>
    );
};

export default WhatIsAchievementsModal;
