import React, { useCallback } from 'react';

import _ from 'lodash';

import { useApolloClient, useLazyQuery } from '@apollo/client';
import { errorsHandler } from '@helpers/errors';
import { useLoadingHook } from '@hooks/useLoadingHook';
import {
    LuckyStrikeAvailableGameOutput,
    LuckyStrikeAvailableGameOutputResponse,
    LuckyStrikePendingGameOutputResponse,
    LuckyStrikeUserTicketOutputResponse,
} from '@models/mysteryGames';
import {
    GET_LUCKY_STRIKE_AVAILABLE_GAMES,
    GET_LUCKY_STRIKE_PENDING_GAMES,
    GET_LUCKY_STRIKE_USER_TICKETS,
} from '@requests/luckyStrike';

export const useLuckyStrikeAvailableGames = () => {
    const client = useApolloClient();

    const {
        isLoading: gamesLoading,
        startLoading: startGamesLoading,
        stopLoading: stopGamesLoading,
    } = useLoadingHook();

    const {
        isLoading: refreshLoading,
        startLoading: startRefreshLoading,
        stopLoading: stopRefreshLoading,
    } = useLoadingHook();

    const {
        isLoading: refetchLoading,
        startLoading: startRefetchLoading,
        stopLoading: stopRefetchLoading,
    } = useLoadingHook();

    const [games, setGames] =
        React.useState<LuckyStrikeAvailableGameOutput[]>();

    const [getPendingGames] =
        useLazyQuery<LuckyStrikePendingGameOutputResponse>(
            GET_LUCKY_STRIKE_PENDING_GAMES,
            {
                fetchPolicy: 'no-cache',
            }
        );

    const getUserTickets = useCallback(
        async (gameId: string) => {
            const result =
                await client.query<LuckyStrikeUserTicketOutputResponse>({
                    query: GET_LUCKY_STRIKE_USER_TICKETS,
                    fetchPolicy: 'no-cache',
                    variables: {
                        gameId,
                    },
                });
            return result;
        },
        [client]
    );

    const updateGames = useCallback(
        async (games: LuckyStrikeAvailableGameOutput[]) => {
            const pendingGames = await getPendingGames();

            if (pendingGames.data?.luckyStrikeMyPendingGames.length) {
                const tickets = await Promise.all(
                    pendingGames.data?.luckyStrikeMyPendingGames.map(
                        async (i) => {
                            const data = await getUserTickets(i.id);
                            return {
                                id: i.id,
                                tickets:
                                    data?.data?.luckyStrikeUserTickets
                                        ?.length || 0,
                            };
                        }
                    )
                );

                if (tickets.length) {
                    return games.map((game, index) => {
                        const find = tickets.find((i) => i.id === game.id);
                        if (find) {
                            return {
                                ...game,
                                boughtTickets: find.tickets,
                            };
                        }
                        return {
                            ...game,
                            boughtTickets: 0,
                        };
                    });
                }
            }
            return games;
        },
        [getUserTickets]
    );

    const getGames = useCallback(
        async (silent: boolean) => {
            try {
                silent ? startGamesLoading() : startRefreshLoading();

                const result =
                    await client.query<LuckyStrikeAvailableGameOutputResponse>({
                        query: GET_LUCKY_STRIKE_AVAILABLE_GAMES,
                        fetchPolicy: 'no-cache',
                    });

                if (result.data) {
                    const games = await updateGames(
                        result.data.luckyStrikeAvailableGames
                    );

                    setGames(games);
                    stopRefreshLoading();
                    stopGamesLoading();
                    return games;
                }
                stopRefreshLoading();
                stopGamesLoading();
            } catch (error) {
                stopRefreshLoading();
                stopGamesLoading();
                errorsHandler(error, true);
            }
        },
        [client, updateGames]
    );
    const refetchGames = useCallback(async () => {
        try {
            startRefetchLoading();

            const result =
                await client.query<LuckyStrikeAvailableGameOutputResponse>({
                    query: GET_LUCKY_STRIKE_AVAILABLE_GAMES,
                    fetchPolicy: 'network-only',
                });

            if (result.data) {
                const games = await updateGames(
                    result.data.luckyStrikeAvailableGames
                );
                setGames(games);
                stopRefetchLoading();
                return games;
            }
            stopRefetchLoading();
        } catch (error) {
            stopRefetchLoading();
            errorsHandler(error, true);
        }
    }, [client, updateGames]);

    return {
        getGames,
        games,
        gamesLoading,
        refreshLoading,
        refetchGames,
        refetchLoading,
    };
};
