import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { moderateScale, scale, verticalScale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        emptyWrapper: {
            paddingHorizontal: scale(20),
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: verticalScale(32),
        },
        emptyTitle: {
            textAlign: 'center',
            alignItems: 'center',
            color: Colors[theme].text.primary,
            ...font({
                size: 22,
                height: 30,
                weight: '800',
            }),
            marginTop: verticalScale(24),
            marginBottom: verticalScale(8),
        },
        emptyInfo: {
            textAlign: 'center',
            color: Colors[theme].text.secondary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
            marginBottom: verticalScale(8),
        },
        buttonWrapper: {
            marginTop: verticalScale(8),
        },
        emptyIconSmall: {},
        emptyIconBig: {},
        emptyAnimation: {
            marginBottom: verticalScale(-20),
        },
        emptyAnimationSmall: {
            width: 180,
            height: 180,
        },
        emptyAnimationBig: {
            width: 250,
            height: 250,
        },
    });

export default styles;
