import { StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import { scale, verticalScale } from '../../../../helpers/dimensions';
import font from '../../../../helpers/font';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        safe: {
            backgroundColor: Colors[theme].background,
            flex: 1,
        },
        justify: {
            justifyContent: 'center',
        },
        main: {
            flex: 1,
            paddingHorizontal: scale(20),
        },
        imgText: {
            alignContent: 'center',
            alignItems: 'center',
        },
        buttonWrapper: {
            paddingVertical: verticalScale(16),
            paddingHorizontal: scale(20),
        },
        gap: {
            marginBottom: verticalScale(16),
        },
        petsXpWrapper: {
            marginTop: verticalScale(16),
        },
        animationWrapper: {},
        animation: {
            width: 240,
            height: 200,
        },
    });

export default styles;
