import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            position: 'absolute',
            top: verticalScale(15),
            backgroundColor: 'transparent',
            right: scale(12),
            zIndex: 1000,
        },
        button: {
            paddingTop: verticalScale(4),
            paddingBottom: verticalScale(4),
            paddingLeft: scale(6),
            paddingRight: scale(8),
        },
        content: {
            backgroundColor: 'transparent',
            flexDirection: 'row',
            alignItems: 'center',
        },
        text: {
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),
            color: Colors[theme].text.warning,
            marginLeft: scale(4),
        },
    });

export default styles;
