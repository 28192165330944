import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        itemContainer: {
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            borderWidth: 1,
            borderColor: Colors[theme].alwaysOneColor.border,
            backgroundColor: Colors[theme].alwaysOneColor.background,
            borderRadius: moderateScale(12),
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: verticalScale(4),
        },
        isTooltip: {
            width: '100%',
        },
        shadow: {
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].alwaysOneColor.shadow,
            marginBottom: verticalScale(12),
            width: '48%',
        },

        active: {
            backgroundColor: Colors[theme].alwaysOneColor.shadowSelected,
        },
        activeContainer: {
            borderColor: Colors[theme].alwaysOneColor.borderSelected,
        },
        left: {
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
            marginRight: scale(16),
            backgroundColor: 'transparent',
            height: 26,
        },
        right: {
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: 'transparent',
        },
        loading: {
            minHeight: verticalScale(24),
        },
        range: {
            color: Colors[theme].alwaysOneColor.text,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
        },
    });

export default styles;
