import { StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import { scale, verticalScale } from '../../../../helpers/dimensions';
import font from '../../../../helpers/font';
import { stylesModalNavigationFix } from '../../../../helpers/style';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: Colors[theme].background,
            ...stylesModalNavigationFix(),
        },
        contentContainer: {
            paddingHorizontal: scale(20),
        },
        paddingBottom: {
            paddingBottom: 34,
        },
        organizationContainer: {
            marginBottom: verticalScale(16),
        },
        organizationImage: {
            width: 40,
            height: 40,
            borderRadius: 6,
            marginRight: scale(12),
        },
        organizationTitleContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            flex: 0.8,
            backgroundColor: 'transparent',
        },
        organizationTitle: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '600',
            }),
        },
        selectedOrganizationContainer: {
            borderColor: Colors[theme].inputActiveBorder,
            backgroundColor: Colors[theme].backgroundSelectable,
        },
        selectedShadow: {
            backgroundColor: Colors[theme].shadow.selected,
        },
        borderOrganizationContainer: {
            borderColor: Colors[theme].border.primary,
            borderWidth: 1,
            borderRadius: 12,
            paddingVertical: verticalScale(12),
            paddingHorizontal: scale(16),
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            zIndex: 2,
        },
        shadow: {
            position: 'absolute',
            bottom: -4,
            height: 24,
            width: '100%',
            borderBottomLeftRadius: 12,
            borderBottomRightRadius: 12,
            backgroundColor: Colors[theme].shadow.default,
            zIndex: 1,
        },
        buttonSaveContainer: {
            paddingTop: verticalScale(16),
            paddingBottom: verticalScale(50),
        },
        failedText: {
            marginBottom: verticalScale(16),
            color: Colors[theme].text.critical,
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
        },
        selectDescription: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
            paddingTop: verticalScale(8),
            paddingBottom: verticalScale(16),
        },
        buttonContainer: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        buttonCharity: {
            marginLeft: scale(12),
        },
        icon: {
            color: Colors[theme].icon.secondary,
        },
    });

export default styles;
