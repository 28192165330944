import * as React from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';

import ActivityIndicator from '@components/ActivityIndicator';
import TypoText from '@components/TypoText';
import { TYPO_TEXT } from '@components/TypoText/types';
import { verticalScale } from '@helpers/dimensions';

import useThemedStyles from '../../hooks/useThemedStyles';
import AlertCard from '../AlertCard';
import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    text: string;
    icon?: React.ReactElement<SvgProps>;
    status?: 'PENDING' | 'FAILED' | 'CONFIRMED' | 'UNLOCK' | 'DEFAULT';
    bottomGap?: number;
    alignSelf?: StyleProp<AlignSetting>;
}

const TagStatus = ({
    text,
    icon,
    status = 'CONFIRMED',
    bottomGap = 0,
    alignSelf,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View
            style={[
                styles.container,
                styles[status],
                { marginBottom: verticalScale(bottomGap), alignSelf },
            ]}>
            {icon}
            <TypoText
                type={TYPO_TEXT.SUBHEAD_SMALL_BOLD}
                additionalStyles={[styles.text, styles[`${status}text`]]}>
                {text}
            </TypoText>
        </View>
    );
};

export default TagStatus;
