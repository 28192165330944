import * as React from 'react';
import {
    Dispatch,
    FC,
    PropsWithChildren,
    SetStateAction,
    createContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { useLazyQuery } from '@apollo/client';

import {
    BackgroundImage,
    DetectionResult,
    GetBackgroundResponse,
    ImageFile,
    ImaginationFormItem,
    Rank,
} from '../Data/Models';
import { GET_BACKGROUNDS } from '../Data/Requests';
import { NFT_GENERATION_OPTIONS_NAME } from '../constants/NFTGenerationOptions';
import { isWeb } from '../helpers/app';
import { usePersistStorage } from '../vendor/react-native-user-persist-storage';
import { useRanks } from './RanksContext';

export type PhotoPosition = {
    top: number;
    left: number;
    width: number;
    height: number;
    scale?: number;
};

export type EditPhoto = {
    file: ImageFile;
    uri: string;
    position: PhotoPosition | null;
};

export type AIImage = {
    id: string;
    localUrl: string | null;
    url: string;
};
export type AIImages = AIImage[];

export type MintContextType = {
    rank: Rank | null;
    setRank: (rank: Rank) => void;
    editPhoto: EditPhoto | null;
    setEditPhoto: (photo: EditPhoto | null) => void;
    background: BackgroundImage | null;
    backgrounds: BackgroundImage[];
    setBackground: (background: BackgroundImage) => void;
    savedPhoto: ImageFile | null;
    setSavedPhoto: (photo: ImageFile) => void;
    name: string | null;
    setName: (name: string) => void;
    reset: () => void;
    resetPet: () => void;
    detectionResult: DetectionResult[] | null;
    setDetectionResult: (detectionResult: DetectionResult[] | null) => void;
    setAIImages: (images: AIImages | null) => void;
    AIImages: AIImages | null;
    setAIImage: (image: ImageFile | null) => void;
    AIImage: ImageFile | null;
    setAIFormData: (formData: ImaginationFormItem[] | undefined) => void;
    AIFormData: ImaginationFormItem[] | undefined;
    currentFlow: NFT_GENERATION_OPTIONS_NAME | null;
    setCurrentFlow: (flow: NFT_GENERATION_OPTIONS_NAME | null) => void;
    changeRankCheckOut: boolean;
    setChangeRankCheckOut: Dispatch<SetStateAction<boolean>>;
    isDisabledModalGenerateNewNft: boolean;
    setDisabledModalGenerateNewNft: Dispatch<SetStateAction<boolean>>;
    isRetry: boolean;
    setIsRetry: Dispatch<SetStateAction<boolean>>;
};

export const MintContext = createContext<MintContextType>({
    rank: null,
    setRank: () => undefined,
    editPhoto: null,
    setEditPhoto: () => undefined,
    background: null,
    backgrounds: [],
    setBackground: () => undefined,
    savedPhoto: null,
    setSavedPhoto: () => undefined,
    name: null,
    setName: () => undefined,
    reset: () => undefined,
    resetPet: () => undefined,
    detectionResult: null,
    setDetectionResult: () => undefined,
    setAIImage: () => undefined,
    AIImage: null,
    setAIFormData: () => undefined,
    AIFormData: undefined,
    setAIImages: () => undefined,
    AIImages: null,
    currentFlow: null,
    setCurrentFlow: () => undefined,
    changeRankCheckOut: false,
    setChangeRankCheckOut: () => undefined,
    isDisabledModalGenerateNewNft: false,
    setDisabledModalGenerateNewNft: () => undefined,
    isRetry: false,
    setIsRetry: () => undefined,
});

const MintProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const [rank, setRank] = usePersistStorage<Rank | null>(
        'MintProvider.rank',
        null,
        {
            persist: isWeb,
        }
    );
    const [AIImage, setAIImage] = usePersistStorage<ImageFile | null>(
        'MintProvider.AIImage',
        null,
        { persist: isWeb }
    );

    const [AIImages, setAIImages] = usePersistStorage<AIImages | null>(
        'MintProvider.AIImages',
        null,
        { persist: isWeb }
    );

    const [AIFormData, setAIFormData] = usePersistStorage<
        ImaginationFormItem[] | undefined
    >('MintProvider.AIFormData', undefined, { persist: isWeb });

    const [name, setName] = usePersistStorage<string | null>(
        'MintProvider.name',
        null,
        {
            persist: isWeb,
        }
    );

    const [editPhoto, setEditPhoto] = usePersistStorage<EditPhoto | null>(
        'MintProvider.editPhoto',
        null,
        {
            persist: isWeb,
        }
    );

    const [background, setBackground] =
        usePersistStorage<BackgroundImage | null>(
            'MintProvider.background',
            null,
            {
                persist: isWeb,
            }
        );

    const [savedPhoto, setSavedPhoto] = usePersistStorage<ImageFile | null>(
        'MintProvider.savedPhoto',
        null,
        {
            persist: isWeb,
        }
    );

    const [detectionResult, setDetectionResult] = usePersistStorage<
        DetectionResult[] | null
    >('MintProvider.detectionResult', null, {
        persist: isWeb,
    });

    const [currentFlow, setCurrentFlow] =
        usePersistStorage<NFT_GENERATION_OPTIONS_NAME | null>(
            'MintProvider.currentFlow',
            null,
            {
                persist: isWeb,
            }
        );

    const [changeRankCheckOut, setChangeRankCheckOut] =
        useState<boolean>(false);
    const [isDisabledModalGenerateNewNft, setDisabledModalGenerateNewNft] =
        useState<boolean>(false);
    const [isRetry, setIsRetry] = useState<boolean>(false);

    const [requestBackgrounds, { data: backgroundsResult }] =
        useLazyQuery<GetBackgroundResponse>(GET_BACKGROUNDS);

    const backgrounds = useMemo(
        () => backgroundsResult?.backgrounds ?? [],
        [backgroundsResult]
    );

    // reset mint when exiting
    useEffect(() => {
        return () => {
            reset();
        };
    }, []);

    useEffect(() => {
        const bg = backgrounds[0];
        if (bg) {
            setBackground(bg);
        }
    }, [backgrounds]);

    useEffect(() => {
        if (rank && !backgrounds.length) {
            requestBackgrounds();
        }
    }, [rank, backgrounds]);

    function reset() {
        setRank(null);
        setEditPhoto(null);
        setBackground(backgrounds[0] ?? null);
        setSavedPhoto(null);
        setName(null);
        setDetectionResult(null);
        setAIImage(null);
        setAIFormData(undefined);
        setAIImages(null);
        setCurrentFlow(null);
        setChangeRankCheckOut(false);
        setDisabledModalGenerateNewNft(false);
    }

    function resetPet() {
        setEditPhoto(null);
        setSavedPhoto(null);
        setName(null);
        setDetectionResult(null);
        setAIImage(null);
        setAIImages(null);
        setBackground(backgrounds[0] ?? null);
    }

    return (
        <MintContext.Provider
            value={{
                rank,
                setRank,
                editPhoto,
                setEditPhoto,
                background,
                backgrounds,
                setBackground,
                savedPhoto,
                setSavedPhoto,
                name,
                setName,
                reset,
                resetPet,
                detectionResult,
                setDetectionResult,
                AIImage,
                setAIImage,
                AIFormData,
                setAIFormData,
                AIImages,
                setAIImages,
                currentFlow,
                setCurrentFlow,
                changeRankCheckOut,
                setChangeRankCheckOut,
                isDisabledModalGenerateNewNft,
                setDisabledModalGenerateNewNft,
                isRetry,
                setIsRetry,
            }}>
            {children}
        </MintContext.Provider>
    );
};

export default MintProvider;
