import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import useThemedStyles from '../../hooks/useThemedStyles';
import { View } from '../Themed';
import StepIndicator from './components/StepIndicator';
import {
    MARKETPLACE_CREATE_ORDER_NAME,
    marketplaceCreateOrder,
} from './flows/marketplaceCreateOrder';
import { MINT_PET_STAGES_NAME, mintNFTstages } from './flows/mintNFTStages';
import {
    SHARE_IN_SOCIAL_MEDIA_NAME,
    shareInSocialMediaStages,
} from './flows/shareInSocialMediaStages';
import { STAKING_ADD_MORE_NAME, stakingAddMore } from './flows/stakingAddMore';
import { STAKING_STAGE_NAME, stakingStages } from './flows/stakingStages';
import stylesMain from './styles';

export type IStageState = '0%' | '25%' | '50%' | '75%' | '100%';

export interface IStage {
    fill: Array<IStageState>;
    customFillIndex?: number;
}

export enum STEP_INDICATOR_FLOWS {
    MINT_PET = 'MINT_PET',
    SHARE_IN_SOCIAL_MEDIA = 'SHARE_IN_SOCIAL_MEDIA',
    STAKING = 'STAKING',
    STAKING_ADD_MORE = 'STAKING_ADD_MORE',
    MARKETPLACE_CREATE_ORDER = 'MARKETPLACE_CREATE_ORDER',
}

interface IProps {
    flow: STEP_INDICATOR_FLOWS;
    stage:
        | MINT_PET_STAGES_NAME
        | SHARE_IN_SOCIAL_MEDIA_NAME
        | STAKING_STAGE_NAME
        | STAKING_ADD_MORE_NAME
        | MARKETPLACE_CREATE_ORDER_NAME;
    stageFill?: string;
    containerStyle?: StyleProp<ViewStyle>;
    isDynamicStageFill?: boolean;
}

const setRightStages = ({ flow, stage }: IProps): IStage => {
    switch (flow) {
        case STEP_INDICATOR_FLOWS.MINT_PET:
            return mintNFTstages[stage as keyof object];
        case STEP_INDICATOR_FLOWS.SHARE_IN_SOCIAL_MEDIA:
            return shareInSocialMediaStages[stage as keyof object];
        case STEP_INDICATOR_FLOWS.STAKING:
            return stakingStages[stage as keyof object];
        case STEP_INDICATOR_FLOWS.STAKING_ADD_MORE:
            return stakingAddMore[stage as keyof object];
        case STEP_INDICATOR_FLOWS.MARKETPLACE_CREATE_ORDER:
            return marketplaceCreateOrder[stage as keyof object];
        default:
            return shareInSocialMediaStages[stage as keyof object];
    }
};

const FlowStepIndicator = ({
    flow,
    stage,
    stageFill,
    containerStyle,
    isDynamicStageFill = true,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const stages = setRightStages({ flow, stage });

    return (
        <View style={[styles.container, containerStyle]}>
            {stages.fill.map((item, index) => (
                <StepIndicator
                    key={index}
                    lastChild={stages.fill.length === index + 1}
                    stageFill={item}
                    isDynamicStageFill={
                        stages.customFillIndex === index + 1 &&
                        isDynamicStageFill
                    }
                    dynamicStageFill={stageFill}
                />
            ))}
        </View>
    );
};
export default FlowStepIndicator;
