import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            paddingHorizontal: scale(20),
            marginBottom: verticalScale(32),
        },

        listBlock: {
            marginTop: verticalScale(12),
            borderWidth: 1,
            borderRadius: moderateScale(12),
            borderColor: Colors[theme].border.primary,
        },
        titleBlock: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        title: {
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: scale(16),
            flex: 1,
        },
        gap: {
            marginRight: scale(4),
        },
    });

export default styles;
