import { useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { errorsHandler } from '@helpers/errors';
import { useLoadingHook } from '@hooks/useLoadingHook';
import {
    TokentellerGameConfigOutput,
    TokentellerGameConfigOutputResponse,
} from '@models/mysteryGames';
import { GET_TOKEN_TELLER_GAME_CONFIG } from '@requests/tokenTeller';

export const useTokenTellerConfig = () => {
    const client = useApolloClient();

    const [tokenTellerConfig, setTokenTellerConfig] =
        useState<TokentellerGameConfigOutput>();

    const {
        isLoading: tokenTellerConfigLoading,
        startLoading: startTokenTellerConfigLoading,
        stopLoading: stopTokenTellerConfigLoading,
    } = useLoadingHook(true);

    const {
        isLoading: tokenTellerConfigRefreshLoading,
        startLoading: startTokenTellerConfigRefreshLoading,
        stopLoading: stopTokenTellerConfigRefreshLoading,
    } = useLoadingHook();

    const handleStopLoading = () => {
        stopTokenTellerConfigRefreshLoading();
        stopTokenTellerConfigLoading();
    };

    const getTokenTellerConfig = async (silent: boolean) => {
        try {
            silent
                ? startTokenTellerConfigLoading()
                : startTokenTellerConfigRefreshLoading();
            const result =
                await client.query<TokentellerGameConfigOutputResponse>({
                    query: GET_TOKEN_TELLER_GAME_CONFIG,
                    fetchPolicy: 'network-only',
                });
            if (result.data) {
                setTokenTellerConfig(result.data.tokentellerGameConfig);
            }
            handleStopLoading();
        } catch (error) {
            handleStopLoading();
            errorsHandler(error, true);
        }
    };

    return {
        tokenTellerConfig,
        tokenTellerConfigLoading,
        tokenTellerConfigRefreshLoading,
        getTokenTellerConfig,
    };
};
