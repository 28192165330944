import React, {
    Dispatch,
    FC,
    PropsWithChildren,
    SetStateAction,
    createContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { Dimensions, ScaledSize } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { SIZE_MATTERS_BASE_WIDTH } from '@env';

import { isWeb } from '../helpers/app';
import WebContainer from '../web-src/components/WebContainer';

const windowDimensions = Dimensions.get('window');
const screenDimensions = Dimensions.get('screen');
export const MAX_WINDOW_WIDTH = 500;
export const SAFE_BOTTOM_WEB = 20;

type DimensionsContextType = {
    dimensions: any;
    isSmallLayout: boolean;
    isRotated: boolean;
    windowWidth: number;
    windowHeight: number;
    safeBottom: number;
    safeTop: number;
    webContainerIsVisible: boolean;
    setIsRotated: Dispatch<SetStateAction<boolean>>;
    setWebContainerIsVisible: Dispatch<SetStateAction<boolean>>;
};

export const DimensionsContext = createContext<DimensionsContextType>({
    dimensions: undefined,
    isSmallLayout: false,
    windowWidth: 0,
    windowHeight: 0,
    safeBottom: 0,
    safeTop: 0,
    isRotated: false,
    webContainerIsVisible: true,
    setIsRotated: () => undefined,
    setWebContainerIsVisible: () => undefined,
});

export const useDimensions = () => React.useContext(DimensionsContext);

const DimensionsProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
    // dimensions
    const [dimensions, setDimensions] = useState<{
        window: ScaledSize;
        screen: ScaledSize;
    }>({ window: windowDimensions, screen: screenDimensions });
    // safe area insets
    const { bottom: safeBottom, top: safeTop } = useSafeAreaInsets();
    // web container visibility
    const [webContainerIsVisible, setWebContainerIsVisible] =
        useState<boolean>(true);
    const [isRotated, setIsRotated] = useState<boolean>(false);

    // resize window change dimensions
    useEffect(() => {
        const subscription = Dimensions.addEventListener(
            'change',
            ({ window, screen }) => {
                setDimensions({ window, screen });
            }
        );
        return () => subscription?.remove();
    });

    // get viewport window width
    const getWindowWidth = () => {
        if (!isWeb || !webContainerIsVisible) {
            return dimensions.window.width;
        }

        return Math.min(dimensions.window.width, MAX_WINDOW_WIDTH);
    };

    return (
        <DimensionsContext.Provider
            value={{
                dimensions,
                isSmallLayout:
                    dimensions.window.width <= Number(SIZE_MATTERS_BASE_WIDTH),
                windowWidth: getWindowWidth(),
                windowHeight: dimensions.window.height,
                safeBottom: !isWeb ? safeBottom : SAFE_BOTTOM_WEB,
                safeTop,
                isRotated,
                webContainerIsVisible,
                setWebContainerIsVisible,
                setIsRotated,
            }}>
            <WebContainer
                isVisible={webContainerIsVisible}
                isRotated={isRotated}>
                {children}
            </WebContainer>
        </DimensionsContext.Provider>
    );
};

export default DimensionsProvider;
