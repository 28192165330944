import * as React from 'react';

import { useUser } from '@contexts/UserContext';
import i18n from '@i18n/i18n';
import NavigationMarketPlace, {
    NAVIGATION_MARKETPLACE,
} from '@navigation/NavigationBar/NavigationMarketPlace';

import MarketPlaceMyProfileRender from '../components/MarketPlaceMyProfileRender';

const MarketPlaceMyProfileScreen = () => {
    const { user } = useUser();

    return (
        <MarketPlaceMyProfileRender
            margin
            shouldPoll
            walletAddress={user?.walletAddress}
            blockTitle={i18n.t('MarketPlace.MarketplaceProfile.myNFTs')}
            navigationComponent={
                <NavigationMarketPlace
                    currentNavigation={NAVIGATION_MARKETPLACE.MY_PROFILE}
                />
            }
        />
    );
};

export default MarketPlaceMyProfileScreen;
