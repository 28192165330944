import React, { useMemo } from 'react';

import { Pet } from '@Data/Models';
import Icon, { ICON_NAMES } from '@components/Icons';
import LevelIcon from '@components/Icons/Level';
import RankIcon from '@components/Icons/RankIcon';
import { Text, View } from '@components/Themed';
import { useRanks } from '@contexts/RanksContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import {
    formatBonusReward,
    formatDailyReward,
    formatRewardModifier,
} from '@helpers/ranks';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import DetailsBlock from '../DetailsBlock';
import Styles from './styles';

interface IProps {
    pet: Pet;
}

const EarningsDetailsBlock = ({ pet }: IProps) => {
    const styles = useThemedStyles(Styles);
    const { rankBySlug } = useRanks();
    const rank = rankBySlug(pet.rank);

    const baseReward = useMemo(
        () => formatDailyReward(rank?.baseIgupRewardWei),
        [rank]
    );

    const bonusReward = useMemo(() => {
        if (!rank?.baseIgupRewardWei || !pet.levelRewardBoostPercentage) {
            return '';
        }

        return formatBonusReward(
            pet.levelRewardBoostPercentage,
            rank.baseIgupRewardWei
        );
    }, [pet, rank]);

    if (!rank) {
        return null;
    }

    return (
        <DetailsBlock
            title={i18n.t('detailedPet.earnings.title')}
            description={i18n.t('detailedPet.earnings.text')}
            icon={<Icon name={ICON_NAMES.DOLLAR} size={40} />}>
            <View style={styles.wrapper}>
                <View style={styles.item}>
                    <RankIcon slug={pet.rank} size={24} />
                    <View style={styles.textContainer}>
                        <Text style={styles.text}>
                            {i18n.t('detailedPet.earnings.rank')}
                        </Text>
                        <Text style={styles.value}>
                            {`${baseReward} `}
                            <>
                                {bonusReward && (
                                    <Text style={styles.valueHighlight}>
                                        {`(+${bonusReward}) `}
                                    </Text>
                                )}
                            </>
                            {Coin.igup}
                        </Text>
                    </View>
                </View>
                <View style={styles.item}>
                    <LevelIcon size={24} />
                    <View style={styles.textContainer}>
                        <Text style={styles.text}>
                            {i18n.t('detailedPet.earnings.level')}
                        </Text>
                        <Text
                            style={[
                                styles.value,
                                pet.levelRewardBoostPercentage
                                    ? styles.valueHighlight
                                    : {},
                            ]}>
                            {formatRewardModifier(
                                pet.levelRewardBoostPercentage
                            )}
                        </Text>
                    </View>
                </View>
            </View>
        </DetailsBlock>
    );
};
export default EarningsDetailsBlock;
