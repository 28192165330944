import { PetExperienceRewards } from '../Data/Models';

export const earnedExp = (array?: PetExperienceRewards[]) => {
    let exp = 0;

    if (!array) {
        return 0;
    }

    if (array?.length) {
        array.map((val) => {
            exp += val.experienceIncrement;
        });
    }
    return exp;
};
