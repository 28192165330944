import React, { useState } from 'react';

import useThemedStyles from '../../../hooks/useThemedStyles';
import { useVisibleHook } from '../../../hooks/useVisibleHook';
import i18n from '../../../i18n/i18n';
import Animation from '../../Animation';
import CustomModal from '../../CustomModal';
import { View } from '../../Themed';
import stylesMain from './styles';

const NoNetworkConnectionState = () => {
    const { isVisible, close: handleModalClose } = useVisibleHook(true);

    const styles = useThemedStyles(stylesMain);

    return (
        <>
            <CustomModal
                isVisible={isVisible}
                icon={
                    <View style={styles.image}>
                        <Animation animation="cry" style={styles.image} />
                    </View>
                }
                titleText={i18n.t('AppState.NoNetworkConnectionState.title')}
                infoText={i18n.t('AppState.NoNetworkConnectionState.text')}
                firstButtonText={i18n.t(
                    'AppState.NoNetworkConnectionState.button'
                )}
                onFirstButtonClick={handleModalClose}
            />
        </>
    );
};

export default NoNetworkConnectionState;
