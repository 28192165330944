import * as React from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';

import { View } from '../Themed';

interface IProps {
    children?: React.ReactNode;
}

const SafeAreaTabContent = ({ children }: IProps) => {
    return (
        /**
         * Hacked view for createBottomTabNavigator which displays in the top to prevent bottom status bar on ios to be empty.
         * Remove once MaterialTobTabs will allow to disable animation
         * **/
        <SafeAreaView
            edges={['left', 'right']}
            style={{ height: '100%', top: 100 }}>
            <View style={{ top: -100, height: '100%' }}>{children}</View>
        </SafeAreaView>
    );
};
export default SafeAreaTabContent;
