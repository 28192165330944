import * as React from 'react';
import { ScrollView } from 'react-native';

import ButtonsBottom from '@components/ButtonsBottom';
import Divider from '@components/Divider';
import { ICON_NAMES } from '@components/Icons';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT } from '@components/TypoText/types';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';

import GameDifficultyCarousel from '../../components/GameDifficultyCarousel';
import NftRoyalGameLivesCarousel from '../../components/NftRoyalGameLivesCarousel';
import NftRoyalGameModes from '../../components/NftRoyalGameModes';
import NftRoyalGameSettingsModal from '../../components/NftRoyalGameSettingsModal';
import { useNftRoyalGameConfigurationsScreen } from './hooks/useNftRoyalGameConfigurationsScreen';
import stylesMain from './styles';

const NftRoyalGameConfigurationsScreen = () => {
    const styles = useThemedStyles(stylesMain);
    const {
        canGoNext,
        onSelectGameLivesCount,
        onSelectGameDifficulty,
        onSelectGameMode,
        navigateToInput,
        isVisible,
        close,
        open,
        nftRoyaleGameConfig,
        gameDifficulty,
        gameMode,
        gameLivesCount,
    } = useNftRoyalGameConfigurationsScreen();

    return (
        <View style={styles.main}>
            <NavigationBar
                backIcon="leftArrow"
                filled={false}
                title={i18n.t('general.KEYS.gameSettings')}
                settingsIcon={ICON_NAMES.QUESTION}
                settingsIconSize={20}
                onPressSettings={open}
            />
            <ScrollView
                showsVerticalScrollIndicator={false}
                style={styles.container}>
                {!!nftRoyaleGameConfig?.gameModes.length && (
                    <View style={styles.modeBlock}>
                        <TypoText
                            type={TYPO_TEXT.HEADING_H6}
                            numberOfLines={1}
                            additionalStyles={styles.gapText}>
                            {i18n.t('general.MERGED.selectText', {
                                text: i18n.t('general.KEYS.mode'),
                            })}
                        </TypoText>

                        <NftRoyalGameModes
                            modes={nftRoyaleGameConfig.gameModes}
                            onPress={onSelectGameMode}
                            selectedMode={gameMode}
                        />
                    </View>
                )}
                <Divider customStyles={styles.divider} />
                {!!nftRoyaleGameConfig?.gameComplexities.length && (
                    <View style={styles.difficultyBlock}>
                        <TypoText
                            type={TYPO_TEXT.HEADING_H6}
                            numberOfLines={1}
                            additionalStyles={styles.gapText}>
                            {i18n.t('general.MERGED.selectText', {
                                text: i18n.t('general.KEYS.difficulty'),
                            })}
                        </TypoText>
                        <GameDifficultyCarousel
                            data={nftRoyaleGameConfig.gameComplexities}
                            onPress={onSelectGameDifficulty}
                            selectedDifficulty={gameDifficulty}
                        />
                    </View>
                )}
                <Divider customStyles={styles.divider} />
                {!!nftRoyaleGameConfig?.gameLives.length && (
                    <View style={styles.livesBlock}>
                        <View style={styles.gapText}>
                            <TypoText
                                type={TYPO_TEXT.HEADING_H6}
                                numberOfLines={1}>
                                {i18n.t('general.MERGED.selectText', {
                                    text: i18n.t('general.KEYS.livesCount'),
                                })}
                            </TypoText>
                            <TypoText
                                additionalStyles={styles.margin}
                                type={TYPO_TEXT.BODY_SMALL}>
                                {i18n.t(
                                    'HomeScreen.NftRoyal.NftRoyalGameSettings.lives'
                                )}
                            </TypoText>
                        </View>
                        <NftRoyalGameLivesCarousel
                            data={nftRoyaleGameConfig.gameLives}
                            onPress={onSelectGameLivesCount}
                            selectedLivesCount={gameLivesCount}
                        />
                    </View>
                )}
            </ScrollView>
            <ButtonsBottom
                title={i18n.t('general.BUTTONS.continueToNextStep')}
                onPress={navigateToInput}
                disabled={!canGoNext}
                safe
            />
            <NftRoyalGameSettingsModal isVisible={isVisible} onClose={close} />
        </View>
    );
};

export default NftRoyalGameConfigurationsScreen;
