import React from 'react';

import PassCode from '@assets/icons/passcode/passcode.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import Button from '@components/Button';
import ButtonsBottom from '@components/ButtonsBottom';
import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import mainStyles from './styles';

interface IProps {
    hide?: () => void;
}

export const PassCodeWelcome = ({ hide }: IProps) => {
    const styles = useThemedStyles(mainStyles);
    const navigation = useNavigation();

    const handleEnablePress = () => {
        hide?.();
        hide
            ? navigation.push(ROUTES.PASSCODE_NEW)
            : navigation.replace(ROUTES.PASSCODE_NEW);
    };

    return (
        <View style={styles.wrapper}>
            <NavigationBar
                filled={false}
                backIcon
                onBack={hide}
                title={i18n.t('passcode.passcode')}
            />
            <View style={styles.container}>
                <View style={styles.content}>
                    <BlockHeader
                        emptyStateIcon={<PassCode />}
                        type="emptyState"
                        title={i18n.t('passcode.welcome.title')}
                        text={i18n.t('passcode.welcome.subTitle')}
                    />
                </View>

                <ButtonsBottom
                    safe
                    title={i18n.t('general.BUTTONS.enableNow')}
                    onPress={handleEnablePress}>
                    {!!hide && (
                        <Button
                            debouncedPress
                            type="text"
                            onPress={hide}
                            containerStyle={styles.button}
                            textStyles={styles.buttonColor}
                            title={i18n.t('general.BUTTONS.maybeLater')}
                        />
                    )}
                </ButtonsBottom>
            </View>
        </View>
    );
};
