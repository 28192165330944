import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '../../../helpers/dimensions';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
        },
        gap: {
            marginBottom: verticalScale(20),
        },
        listStyle: {
            paddingHorizontal: scale(20),
        },
    });

export default styles;
