import React from 'react';
import { ScrollView, StyleProp, ViewStyle } from 'react-native';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';

import { isWebAndroid } from '../../../../helpers/app';

interface CarouselScrollViewProps {
    children: React.ReactNode;
    style: StyleProp<ViewStyle>;
}

const CarouselScrollView = ({
    children,
    style = {},
}: CarouselScrollViewProps) => {
    const scrollRef = React.useRef<ScrollView>(null);
    const [currentPosition, setCurrentPosition] = React.useState(0);
    const [scrollViewHeight, setScrollViewHeight] = React.useState<number>(0);
    const [scrollViewContentHeight, setScrollViewContentHeight] =
        React.useState<number>(0);

    const newPosition = (currentPosition: number, translationY: number) =>
        Math.max(
            Math.min(
                currentPosition + -translationY,
                scrollViewContentHeight - scrollViewHeight
            ),
            0
        );

    const gesturePan = Gesture.Pan()
        .onUpdate((event) => {
            scrollRef.current?.scrollTo({
                y: newPosition(currentPosition, event.translationY),
                animated: false,
            });
        })
        .onEnd((event) => {
            setCurrentPosition((prev) => newPosition(prev, event.translationY));
        });

    return (
        <GestureDetector gesture={gesturePan}>
            <ScrollView
                scrollEnabled={false}
                bounces={true}
                style={style}
                onLayout={(e) =>
                    setScrollViewHeight(e.nativeEvent.layout.height)
                }
                onContentSizeChange={(w, h) => setScrollViewContentHeight(h)}
                showsVerticalScrollIndicator={false}
                ref={scrollRef}>
                {children}
            </ScrollView>
        </GestureDetector>
    );
};

export default CarouselScrollView;
