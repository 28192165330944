import * as React from 'react';

import CheckoutSummary from '@components/CheckoutScreen/CheckoutSummary';
import CheckoutSuccessScreen from '@components/CheckoutSuccessScreen';
import Divider from '@components/Divider';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { usePlay } from '@contexts/PlayContext';
import { useUser } from '@contexts/UserContext';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { useNavigation } from '@navigation/useNavigation';

import { NftEvolutionMintPetSuccessProps } from '../../../../../types';
import NftEvolutionMintDetails from '../../components/NftEvolutionMintDetails';
import stylesMain from './styles';

const NftEvolutionMintPetSuccessScreen = ({
    route,
}: NftEvolutionMintPetSuccessProps) => {
    const { transactionResult, pet, payment } = route.params;

    const { refetchPets } = usePlay();
    const { reloadUser } = useUser();
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const { clearNftEvolution, getBreedingPetDrafts } = useMysteryGames();

    navigation.blockHardwareBack();

    React.useEffect(() => {
        reloadUser();
        refetchPets(true);
        getBreedingPetDrafts(true);
    }, []);

    const handleBackToCollection = () => {
        navigation.navigateToCollection();
        clearNftEvolution();
    };

    return (
        <CheckoutSuccessScreen
            title={i18n.t('mintCheckout.successTitle')}
            text={i18n.t('mintCheckout.successText')}
            onPressButtonMain={handleBackToCollection}
            buttonMainText={i18n.t('mintCheckout.backToCollection')}
            explorerUrl={transactionResult?.explorerUrl}
            TransactionDetailsComponent={
                <>
                    <NftEvolutionMintDetails pet={pet} />
                    <Divider customStyles={styles.divider} />
                    <CheckoutSummary
                        payment={payment}
                        transactionResult={transactionResult}
                    />
                </>
            }
        />
    );
};

export default NftEvolutionMintPetSuccessScreen;
