import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import TokenTellerHistoryIcon from '@components/Icons/TokenTellerHistoryIcon';
import { Text, View } from '@components/Themed';
import { dateFormatter } from '@helpers/time';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import {
    MYSTERY_GAMES,
    PaginatedTokentellerGamesHistoryOutput,
} from '@models/mysteryGames';

import GameAmount from '../GameAmount';
import stylesMain from './styles';

interface IProps {
    item: PaginatedTokentellerGamesHistoryOutput;
    onPress: (item: PaginatedTokentellerGamesHistoryOutput) => void;
    disabled?: boolean;
}

const GAME = MYSTERY_GAMES.tokenteller;
const FLOW = 'TokenTellerHistoryItem';

const TokenTellerHistoryItem = ({ item, onPress, disabled }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <TouchableOpacity
            style={[styles.itemContainer]}
            disabled={disabled}
            activeOpacity={0.7}
            onPress={() => onPress(item)}>
            {disabled && <View style={styles.opacity} />}
            <View style={styles.left}>
                <View style={styles.imageWrapper}>
                    <TokenTellerHistoryIcon slug={item.status} />
                </View>
                <View style={styles.infoBlock}>
                    <Text style={styles.address} numberOfLines={1}>
                        {i18n.t(`HomeScreen.${GAME}.${FLOW}.${item.status}`)}
                    </Text>
                    <Text style={styles.date} numberOfLines={1}>
                        {dateFormatter(item.createdAt)}
                    </Text>
                </View>
            </View>
            <View style={styles.right}>
                <GameAmount
                    status={item.status}
                    amount={balanceFromWei(item.iguAmountWei).valueLong}
                />
            </View>
        </TouchableOpacity>
    );
};
export default TokenTellerHistoryItem;
