import * as React from 'react';
import { FlatList } from 'react-native';
import DraggableFlatList, {
    OpacityDecorator,
    ScaleDecorator,
} from 'react-native-draggable-flatlist';
import { TouchableOpacity } from 'react-native-gesture-handler';
import Animated, { useSharedValue } from 'react-native-reanimated';

import _ from 'lodash';

import { isWeb, isWebAndroid } from '@helpers/app';

import { Pet } from '../../../../../Data/Models';
import DeletePet from '../../../../../assets/icons/play/deletePet.svg';
import Empty from '../../../../../assets/icons/play/petEmpty.svg';
import GestureRootView from '../../../../../components/GestureRootView';
import { View } from '../../../../../components/Themed';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import DraggableContainer from '../DraggableContainer';
import stylesMain from './styles';

interface IProps {
    orderedPetsForPlay: Pet[];
    tmpOrderedIds: string[];
    updateTmpOrderedIds: (data: Pet[]) => void;
    onDelete: (id: string) => void;
    maxPets: number;
}
interface IRenderItem {
    item: Pet;
    drag: () => void;
}

const DraggablePetList = ({
    orderedPetsForPlay,
    tmpOrderedIds,
    updateTmpOrderedIds,
    onDelete,
    maxPets,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const isLongPressed = useSharedValue(false);

    const emptyData = React.useMemo(() => {
        const arr = [];
        for (let index = 0; index < maxPets - tmpOrderedIds.length; index++) {
            arr.push('empty' + index);
        }
        return arr;
    }, [tmpOrderedIds, maxPets]);

    const renderEmptyComponent = () => {
        return (
            <View style={styles.emptyElementWrapper}>
                <View style={styles.emptyElement}>
                    <Empty />
                </View>
            </View>
        );
    };

    const renderItem = ({ item, drag }: IRenderItem) => {
        return (
            <OpacityDecorator>
                <ScaleDecorator>
                    <TouchableOpacity
                        onLongPress={() => {
                            isLongPressed.value = true;
                            drag();
                        }}
                        activeOpacity={0.8}
                        style={styles.itemContainer}>
                        <Animated.Image
                            source={{ uri: item?.image.thumbnailUrl }}
                            style={styles.image}
                        />
                        {isWebAndroid && (
                            <View
                                style={[
                                    {
                                        position: 'absolute',
                                        backgroundColor: 'transparent',
                                    },
                                    styles.image,
                                ]}
                            />
                        )}
                        <View style={styles.delete}>
                            <TouchableOpacity
                                activeOpacity={0.8}
                                onPress={() => onDelete(item.id)}>
                                <DeletePet />
                            </TouchableOpacity>
                        </View>
                    </TouchableOpacity>
                </ScaleDecorator>
            </OpacityDecorator>
        );
    };

    return (
        <GestureRootView>
            <DraggableContainer disableScroll={isLongPressed}>
                <DraggableFlatList
                    scrollEnabled={!isWeb}
                    horizontal
                    data={orderedPetsForPlay}
                    renderItem={renderItem}
                    keyExtractor={(item) => item.id}
                    onDragEnd={({ data }) => {
                        updateTmpOrderedIds(data);
                        isLongPressed.value = false;
                    }}
                    renderPlaceholder={renderEmptyComponent}
                    ListFooterComponent={() => (
                        <FlatList
                            style={{ zIndex: 1 }}
                            scrollEnabled={isWeb && !isWebAndroid}
                            contentContainerStyle={{
                                flexDirection: 'row',
                            }}
                            data={emptyData}
                            keyExtractor={(item) => item}
                            renderItem={renderEmptyComponent}
                        />
                    )}
                />
            </DraggableContainer>
        </GestureRootView>
    );
};
export default DraggablePetList;
