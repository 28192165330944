import { consoleTransport, logger } from 'react-native-logs';

import { APP_ENV, REMOTE_LOGS_ENDPOINT } from '@env';

declare type SeverityType = 'error' | 'warning' | 'log' | 'info';

export interface ConsoleType {
    log(message?: any, ...optionalParams: any[]): void;
    info(message?: any, ...optionalParams: any[]): void;
    error(message?: any, ...optionalParams: any[]): void;
    getLocalLogs(): Array<{ message: string; params: any }>;
    addLocalLog(
        message: any,
        severity: SeverityType,
        ...optionalParams: any[]
    ): void;
    remoteLog(
        endpoint: string,
        message: string,
        severity: SeverityType,
        ...optionalParams: any[]
    ): void;
}

const defaultConfig = {
    levels: {
        debug: 0,
        info: 1,
        warn: 2,
        error: 3,
    },
    severity: 'debug',
    transport: consoleTransport,
    transportOptions: {
        colors: {
            info: 'blueBright',
            warn: 'yellowBright',
            error: 'redBright',
        },
    },
    async: true,
    dateFormat: 'time',
    printLevel: true,
    printDate: true,
    enabled: true,
};

const Logger = logger.createLogger(defaultConfig);

export default class Console implements ConsoleType {
    localLogs: Array<{ message: string; params: any }>;

    endpoint: string;

    public constructor() {
        this.localLogs = [];
        this.endpoint = REMOTE_LOGS_ENDPOINT;
    }

    log(message?: any, ...optionalParams: any[]) {
        if (APP_ENV !== 'production') Logger.debug(message, ...optionalParams);

        this.addLocalLog(message, 'log', optionalParams);

        if (this.endpoint)
            this.remoteLog(this.endpoint, message, 'log', optionalParams);
    }

    info(message?: any, ...optionalParams: any[]) {
        if (APP_ENV !== 'production') Logger.info(message, ...optionalParams);

        this.addLocalLog(message, 'info', optionalParams);

        if (this.endpoint)
            this.remoteLog(this.endpoint, message, 'info', optionalParams);
    }

    warn(message?: any, ...optionalParams: any[]) {
        if (APP_ENV !== 'production') Logger.warn(message, ...optionalParams);

        this.addLocalLog(message, 'warning', optionalParams);

        if (this.endpoint)
            this.remoteLog(this.endpoint, message, 'warning', optionalParams);
    }

    error(message?: any, ...optionalParams: any[]) {
        if (APP_ENV !== 'production') Logger.error(message, ...optionalParams);

        this.addLocalLog(message, 'error', optionalParams);

        if (this.endpoint)
            this.remoteLog(this.endpoint, message, 'error', optionalParams);
    }

    getLocalLogs() {
        return this.localLogs;
    }

    addLocalLog(
        message: any,
        severity: SeverityType,
        ...optionalParams: any[]
    ) {
        this.localLogs.push({
            message,
            params: optionalParams,
        });

        if (this.localLogs.length > 200) {
            this.localLogs.shift();
        }
    }

    // remote log to ngrok or other remote logging services
    remoteLog(
        endpoint: string,
        message: string,
        severity: SeverityType,
        ...optionalParams: any[]
    ) {
        fetch(endpoint, {
            method: 'POST',
            body: JSON.stringify({
                message,
                params: optionalParams,
            }),
        })
            .then(() => undefined)
            .catch(() => undefined);
    }
}
