import * as React from 'react';
import { Animated } from 'react-native';

import { useNativeDriver } from '../../../helpers/app';

export default class ImageLoader extends React.Component<any, any> {
    state = {
        opacity: new Animated.Value(0),
    };

    onLoad = (onLoadEnd: () => void) => {
        Animated.timing(this.state.opacity, {
            toValue: 1,
            delay: 0,
            duration: this.props.duration ?? 2000,
            useNativeDriver: useNativeDriver,
        }).start(() => {
            onLoadEnd && onLoadEnd();
        });
    };

    render() {
        const { source, onLoadEnd, ...other } = this.props;

        return (
            <Animated.Image
                onLoad={() => {
                    this.onLoad(onLoadEnd);
                }}
                source={source}
                {...other}
                style={[
                    {
                        opacity: this.state.opacity,
                        transform: [
                            {
                                scaleX: this.state.opacity.interpolate({
                                    inputRange: [0, 1],
                                    outputRange: [1.2, 1],
                                }),
                            },
                        ],
                    },
                    this.props.style,
                ]}
            />
        );
    }
}
