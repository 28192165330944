import * as React from 'react';

import { UserRole } from '../../../Data/Models';
import AdminIcon from '../../../assets/icons/profile/roles/admin.svg';
import ModeratorIcon from '../../../assets/icons/profile/roles/admin.svg';
import { IconType, iconSize } from '../types';

interface IProps extends IconType {
    role?: UserRole;
}

const UserRoleIcon = ({ role, size = iconSize.default }: IProps) => {
    if (role === 'ADMIN') {
        return <AdminIcon width={size} height={size} />;
    }

    if (role === 'MODERATOR') {
        return <ModeratorIcon width={size} height={size} />;
    }

    // normal user has no Role Icon
    return null;
};

export default UserRoleIcon;
