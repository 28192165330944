import * as React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import ICON from '@assets/icons/emptyState/gameComingSoon.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import ModalBottom from '@components/ModalBottom';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
}

const ModalGameComingSoon = ({ isVisible, onClose }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <ModalBottom isVisible={isVisible} onClose={onClose} modalHeight={600}>
            <ScrollView
                style={styles.container}
                showsVerticalScrollIndicator={false}>
                <TouchableOpacity activeOpacity={1}>
                    <BlockHeader
                        containerStyle={styles.header}
                        emptyStateIcon={<ICON />}
                        type="emptyState"
                        title={i18n.t('HomeScreen.ModalGameComingSoon.title')}
                        text={i18n.t('HomeScreen.ModalGameComingSoon.text')}
                    />
                </TouchableOpacity>
            </ScrollView>
            <ButtonsBottom
                safe
                title={i18n.t('general.BUTTONS.playPvpGames')}
                onPress={onClose}
            />
        </ModalBottom>
    );
};

export default ModalGameComingSoon;
