import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';

import Colors from '@constants/Colors';
import { useTheme } from '@contexts/ThemeContext';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { APP_MAINNET } from '@env';
import { waitForModal } from '@helpers/helpers';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import AlertCard from '../AlertCard';
import Button from '../Button';
import CurrentLabel from '../CurrentLabel';
import { ICON_NAMES } from '../Icons';
import InfoIcon from '../InfoIcon/InfoIcon';
import ModalBottom from '../ModalBottom';
import QrCodeImage from '../QrCodeImage';
import { View } from '../Themed';
import WalletAddress from '../WalletAddress';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    setIsVisible?: Dispatch<SetStateAction<boolean>>;
    closeFirstModal?: Dispatch<SetStateAction<boolean>>;
    titleText?: string;
    coin?: string;
    secondModal?: boolean;
    modalHeight?: number | string;
    handleClose?: () => void;
}

const ModalDeposit = ({
    isVisible,
    setIsVisible,
    titleText,
    coin,
    secondModal,
    closeFirstModal,
    modalHeight = 700,
    handleClose,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { theme } = useTheme();
    const { walletData } = useWallet();
    const address = walletData?.wallet?.address;

    const navigation = useNavigation();

    const onPurchaseBNB = () => {
        setIsVisible?.(false);
        closeFirstModal?.(false);
        waitForModal().then(() => navigation.push(ROUTES.HOW_TO_PURCHASE_BNB));
    };

    // get modal title
    const title =
        titleText ||
        (coin
            ? i18n.t('wallet.depositBEP', {
                  coin,
              })
            : i18n.t('wallet.deposit'));

    const handleDepositModalClose = () => {
        setIsVisible?.(false);
    };

    return (
        <>
            {address && (
                <ModalBottom
                    isVisible={isVisible}
                    onClose={handleClose || handleDepositModalClose}
                    titleText={title}
                    modalHeight={modalHeight}
                    isNestedModal={secondModal}
                    animation={
                        secondModal
                            ? {
                                  animationIn: 'slideInRight',
                                  animationOut: 'slideOutRight',
                              }
                            : undefined
                    }
                    swipeDirection={secondModal ? ['right', 'down'] : ['down']}>
                    <View style={styles.container}>
                        <View style={styles.topWrapper}>
                            {!APP_MAINNET && (
                                <View style={styles.network}>
                                    <CurrentLabel
                                        text={i18n.t('wallet.testnet')}
                                        withIcon={false}>
                                        <InfoIcon
                                            tooltipContent={i18n.t(
                                                'wallet.testnetWarning'
                                            )}
                                            iconColor={
                                                Colors[theme].text.danger
                                            }
                                        />
                                    </CurrentLabel>
                                </View>
                            )}
                            <QrCodeImage link={address} name={coin} />
                            <View style={styles.walletAddressWrapper}>
                                <WalletAddress address={address} />
                            </View>
                        </View>
                        <AlertCard text={i18n.t('wallet.warning')} />
                        {coin === Coin.bnb && (
                            <View style={styles.button}>
                                <Button
                                    onPress={onPurchaseBNB}
                                    title={i18n.t('wallet.howToPurchaseBNB')}
                                    type="text"
                                    iconName={ICON_NAMES.QUESTION}
                                />
                            </View>
                        )}
                    </View>
                </ModalBottom>
            )}
        </>
    );
};

export default ModalDeposit;
