import ROUTES from '@navigation/routes';

export type LoginWithGoogleResponse = {
    loginWithGoogle: Auth;
};

export type LoginWithAppleResponse = {
    loginWithApple: Auth;
};

export type LoginWithFacebookResponse = {
    loginWithFacebook: Auth;
};

export type LoginWithTwitterResponse = {
    loginWithTwitter: Auth;
};

export type LoginWithOTPResponse = {
    loginWithOTP: Auth;
};

export type SendOTPResponse = {
    sendOTP: EmailOtp;
};

export type Auth = {
    access_token: string;
};

export type MeResponse = {
    petsCount: number;
    me: User;
};

export type PetIdType =
    | 'Default'
    | 'BinanceNft'
    | 'BnbChain'
    | 'KuCoin'
    | 'Okx'
    | 'Bybit';

export type UserSocial = {
    id: string;
    username: string | null;
    isVerified: boolean;
    updatedAt: string;
    createdAt: string;
};

export type LinkInstagramResponse = {
    linkInstagram: UserSocial;
};

export type LinkFacebookResponse = {
    linkFacebook: UserSocial;
};

export type EmailOtp = {
    id: string;
    email: string;
    resendInSeconds: number;
    updatedAt: string;
    createdAt: string;
};

export type Charity = {
    id: string;
    name: string;
    description: string;
    country: string;
    city: string;
    friendlyAddress: string;
    email: string;
    website: string;
    instagram: string;
    twitter: string;
    ethAddress: string;
    updatedAt: string;
    createdAt: string;
    totalDonationsIgupWei: number;
    logoUrl: string;
};

export type Charities = {
    charities: Charity[];
};

export type UserRole =
    | 'ADMIN'
    | 'MODERATOR'
    | 'PLAYER'
    | 'CHARITY'
    | 'LEADER'
    | 'ADVANCED_LEADER';

export type UserBoost = 'None' | 'First' | 'Second' | 'Third';

export type User = {
    id: string;
    email: string;
    createdAt: string;
    referralCode: string;
    walletAddress: string;
    username: string | null;
    role: UserRole;
    energy: number;
    charity: Charity;
    charityDonationPercentage: number;
    charityChangedAt: string;
    petOrderedIds: string[];
    instagram: UserSocial;
    tiktok: UserSocial;
    twitter: UserSocial;
    facebook: UserSocial;
    updatedAt: string;
    isReferred: boolean;
    mainPet: Pet;
    directReferralPercentage: number;
    indirectReferralPercentage: number;
    boost: UserBoost;
    referralUsageAvailable: boolean;
};

export type FileMetadata = {
    isFromCamera: boolean;
    isFromGallery: boolean;
    detectionResult: DetectionResult[];
    backgroundId: string;
    text2ImageTaskId: string | null;
};

export type DetectionResult = {
    label: string;
    confidence: number;
};

export type ImageFile = {
    id: string;
    filename: string;
    url: string;
    thumbnailUrl: string;
    type: string;
    status: string;
    signedUploadUrl: string;
    updatedAt: string;
    createdAt: string;
    metadata: FileMetadata;
};

export type ImageUploadUrlResponse = {
    imageUploadUrl: ImageFile;
};

export type MarkImageUploadedResponse = {
    markImageUploaded: ImageFile;
};

export type BackgroundImage = {
    id: string;
    icon: string;
    original: string;
    ranks: string[];
};

export type GetBackgroundResponse = {
    backgrounds: BackgroundImage[];
};

export type CreatePetImageResponse = {
    composeNftImage: ImageFile;
};

export type CreatePetImageFromUrlResponse = {
    createNftImageFromGeneratedImage: ImageFile;
};

export type PetsResponse = {
    pets: Pet[];
};

export type CreatePetResponse = {
    createPet: {
        payment: PaymentOptionOutput;
        signedTransaction?: SignedTransactionOutput;
        petId: string;
    };
};

export type VerifyWalletResponse = {
    verifyWallet: User;
};

export type PetAge = 'Baby' | 'Young' | 'Adult' | 'Old';

export type Pet = {
    id: string;
    name: string;
    image: ImageFile;
    rank: string;
    tokenId: string;
    health: number;
    likeCount: number;
    dislikeCount: number;
    currentOwnerAddress: string;
    creatorAddress: string;
    lastTransferAt: string | null;
    isMinted: boolean;
    level: number;
    experience: number;
    updatedAt: string;
    createdAt: string;
    explorerUrl: string;
    levelProgressPercentage: number;
    healthPercentage: number;
    cooldownExpiresAt: string | null;
    currentLevelMaxExperience: number;
    levelUpAvailable: boolean;
    rankUpAvailable: boolean;
    reviveAvailable: boolean;
    healUpAvailable: boolean;
    dead: boolean;
    levelRewardBoostPercentage: number;
    age: PetAge;
    ageDays: number;
    maxAgeDays: number;
    ageDead: boolean;
    reviveAgeAvailable: boolean;
    rewardUnlockDaysPeriod: number;
};

export type GetRanksResponse = {
    ranks: Rank[];
};
export type GetAIImagesResponseItem = {
    id: string;
    imageUrl: string;
};
export type GetAIImagesResponse = {
    text2Image: GetAIImagesResponseItem[];
};

export type RankMintBonus = {
    endsAt: string;
    igupAmountWei: string;
    lockedPeriodDays: number;
};
export type Rank = {
    id: number;
    slug: string;
    available: boolean;
    baseIgupRewardWei: string;
    rewardUnlockDaysPeriod: number;
    dailyUnlockRate: number;
    rewardConsumptionPercentage: number;
    levelRewardModifier: number;
    payments: PaymentOptionOutput[];
    whitelistFreeMintsCount: number;
    mintPriceUsd: string;
    maxPetLevel: number;
    lootboxFreeMintsCount: number;
    mintBonus: RankMintBonus | null;
    maxAgeDays: number;
};

export type MarkReferrerResponse = {
    markReferrer: User;
};

export type PaymentOptionOutput = {
    token: string;
    tokenAddress: string;
    amountWei: string;
    productId: string | null;
    priceString?: string | null;
};

export type SignedTransactionOutput = {
    contractAddress: string;
    execTransactionValuesStringified: string;
};

export type MintPetOutput = {
    pet: Pet;
    payment: PaymentOptionOutput;
    signedTransaction: SignedTransactionOutput;
};

export type GetConfigResponse = {
    config: Config;
};

export type EstimatedGasUse = {
    bnb: BnbEstimatedGasUse;
    busd: BusdEstimatedGasUse;
    igu: IguEstimatedGasUse;
    igup: IgupEstimatedGasUse;
    nft: NftEstimatedGasUse;
};

export type BnbEstimatedGasUse = {
    transfer: number;
};

export type BusdEstimatedGasUse = {
    approve: number;
    transfer: number;
};

export type IguEstimatedGasUse = {
    approve: number;
    transfer: number;
};

export type IgupEstimatedGasUse = { transfer: number };

export type NftEstimatedGasUse = {
    execTransaction: number;
    transferFrom: number;
};

export type UsersBoosts = {
    id: UserBoost;
    minReferralsCount: number;
    rewardBonusPercentage: number;
};

export type ListingExchange = 'Default' | 'Okx' | 'KuCoin';

export type GasPriceConfig = {
    defaultGasPriceGwei: string;
    gasPriceMiltiplier: string;
};

export type Config = {
    isFullMaintenance: boolean;
    isGameMaintenance: boolean;
    paymentsAvailable: boolean;
    version: string;
    androidVersion: string;
    iosVersion: string;
    newIosThemeVersion: string;
    maxActiveNFTsPerUser: number;
    rpcPublicProviders: string[];
    chainId: number;
    mintConfirmationsCount: number;
    nftIguverseContractAddress: string;
    iguTokenContractAddress: string;
    boosterContractAddress: string;
    blockExplorerURL: string;
    igupContractAddress: string;
    lootboxContractAddress: string;
    gameIterationUTCHour: number;
    googleAndroidClientId: string;
    googleWebClientId: string;
    googleIosClientId: string;
    facebookAppId: string;
    nextGameIterationDate: string;
    instagramClientId: string;
    twitterClientId: string;
    intercomAppId: string;
    termsOfUseUrl: string;
    privacyPolicyUrl: string;
    knowledgeBaseUrl: string;
    supportEmail: string;
    estimatedGasUse: EstimatedGasUse;
    busdContractAddress: string;
    swapRouterContractAddress: string;
    wbnbContractAddress: string;
    tokenDecimals: number;
    userBoosts: UsersBoosts[];
    rewardEnergyThreshold: number;
    iguDisabled: boolean;
    igupDisabled: boolean;
    tradeSupportedTokens: string[];
    enabledSocialPlatforms: string[];
    petIdType: PetIdType;
    priceIncrease: {
        amount: number;
        startsAt: string;
    } | null;
    petAgeReviveBasePriceDiscountPercentage: string;
    listing: {
        exchange: ListingExchange;
        startsAt: string;
        url: string;
    } | null;
    iguWalletContractAddress: string;
    iguWalletWithdrawFeePercentage: number;
    iguWithdrawDisabled: boolean;
    iguDepositBonus: {
        endsAt: string;
        bonusPercentage: number;
    } | null;
    marketplaceContractAddress: string;
    marketplaceMaxPrice: string;
    marketplaceMinPrice: string;
    marketplaceMinimalRank: string;
    marketplaceTerminationDays: number;
    marketplaceTransactionFeePercentage: number;
    gasPriceConfig: GasPriceConfig;
};

export type GetTokenTransactionsResponse = {
    tokenTransactions: TokenTransaction[];
};

export type TokenTransactionStatus =
    | 'CONFIRMED'
    | 'FAILED'
    | 'PENDING'
    | 'UNLOCK';

// Token blockchain transaction
export type TokenTransactionType =
    | 'DEPOSIT'
    | 'WITHDRAW'
    | 'NFT_PAYMENT'
    | 'MINT'
    | 'SWAP'
    | 'UNKNOWN'
    | 'CLAIM';

export type RewardsTransactionType =
    | 'GameIterationReward'
    | 'ReferralReward'
    | 'Claim'
    | 'PetLevelUpPayment'
    | 'PetRankUpPayment'
    | 'PetRevivePayment'
    | 'PetHealUpPayment'
    | 'MintBonus'
    | 'CharityDonation'
    | 'GameIterationRewardSubtransaction'
    | 'GameRewardStakeBonus'
    | 'GameRewardStakeBonusSubtransaction';

// Internal blockchain transaction
export type TokenTransaction = {
    from: string;
    to: string;
    value: string;
    timeStamp: string;
    status: TokenTransactionStatus;
    explorerUrl: string;
    tokenID: string;
    hash: string;
    txCost: string;
    type: TokenTransactionType;
};

export type NFTTrait = {
    trait_type: string;
    value: string;
};

export type NFTObject = {
    name: string;
    image: string;
    external_url: string;
    description: string;
    attributes: NFTTrait[];
};

export type ExchangesResponse = {
    exchanges: Exchange[];
};

export type Exchange = {
    id: string;
    name: string;
    url: string;
    tokens: string[];
    iconUrl: string;
};

export type ExchangeRatesOutput = {
    bnbusd: string;
    bnbbusd: string;
    busdbnb: string;
    igubnb: string;
    igupbnb: string;
    busdusd: string;
    iguusd: string;
    igupusd: string;
};

export type ExchangeRatesResponse = {
    exchangeRates: ExchangeRatesOutput;
};

export type ImaginationFormResponseOptions = {
    id: string;
    name: string;
    icon: string;
};

export type ImaginationFormItem = {
    id: string;
    name: string;
    isRequired: boolean;
    maxSelections: number;
    options: ImaginationFormResponseOptions[];
};

export type ImaginationFormResponse = {
    text2ImageData: Array<ImaginationFormItem>;
};
export type SetPetOrderedIdsResponse = {
    petOrderedIds: string[];
};

export type SocialPlatform = 'TWITTER' | 'INSTAGRAM' | 'TIKTOK';
export type PetExperienceRewards = {
    id: string;
    experienceIncrement: number;
    pet: Pet;
    updatedAt: string;
    createdAt: string;
};

export type TaskStatus = 'CREATED' | 'FAILED' | 'COMPLETED';

export type SocialTaskStatus =
    | 'CREATED'
    | 'AWAITS_VERIFICATION'
    | 'MODERATOR_VERIFICATION_REQUIRED'
    | 'FAILED'
    | 'COMPLETED';

export type RejectReasonStatus =
    | 'NOT_ENOUGH_FOLLOWERS'
    | 'PET_ID_NOT_FOUND'
    | 'PET_ID_INCORRECT_POSITION'
    | 'USER_NOT_FOUND';

export type SocialTaskResponseItem = {
    id: string;
    status: SocialTaskStatus;
    maxEnergyReward: number;
    energyRewarded: number | null;
    token: string;
    platform: SocialPlatform;
    platformUsername: string;
    sharedAt: string;
    updatedAt: string;
    createdAt: string;
    pet: Pet;
    petExperienceRewards: PetExperienceRewards[];
    moderatorRejectReason: RejectReasonStatus;
};

export type RejectReasonItem = {
    text: string;
    status: RejectReasonStatus;
};

export type MoveTaskStatus = 'CREATED' | 'FAILED' | 'COMPLETED';
export type MoveTaskResponseItem = {
    id: string;
    status: MoveTaskStatus;
    maxEnergyReward: number;
    energyRewarded: number | null;
    maxDistanceMeters: number;
    maxTimeSeconds: number;
    elapsedTimeSeconds: number;
    petExperienceRewards: PetExperienceRewards[];
    updatedAt: string;
    createdAt: string;
};
export type PlayTaskStatus = 'CREATED' | 'FAILED' | 'COMPLETED';
export type PlayTaskResponseItem = {
    id: string;
    status: PlayTaskStatus;
    maxEnergyReward: number;
    energyRewarded: number | null;
    livesCount: number;
    maxPoints: number;
    fruitsCount: number;
    gainedPoints: number;
    updatedAt: string;
    createdAt: string;
    petExperienceRewards: PetExperienceRewards[];
};

export type VerificationTaskStatus =
    | 'CREATED'
    | 'AWAITS_VERIFICATION'
    | 'MODERATOR_VERIFICATION_REQUIRED'
    | 'FAILED'
    | 'COMPLETED';

export type TaskFailureReason =
    | 'TIMEOUT'
    | 'NO_CODE'
    | 'BAD_CODE'
    | 'MODERATOR_REJECTED';

export type SocialTaskOutput = {
    id: string;
    platform: string;
    platformUsername: string;
    pet: Pet;
};
export type VerificationTaskResponseItem = {
    id: string;
    status: VerificationTaskStatus;
    failureReason: TaskFailureReason;
    maxEnergyReward: number;
    energyRewarded: number | null;
    isRated: boolean;
    socialTask: SocialTaskOutput;
    remainingVerificationAttempts: number;
    updatedAt: string;
    createdAt: string;
    petExperienceRewards: PetExperienceRewards[];
};

export interface SocialTaskItem {
    latestTask: SocialTaskResponseItem;
    canStart?: boolean | undefined;
    maxEnergyReward?: number | undefined;
}

export interface VerificationTaskItem {
    status: VerificationTaskStatus;
    currentTask: VerificationTaskResponseItem | null;
    canStart: boolean;
    maxEnergyReward: number;
    totalEnergyRewarded: number | null;
    tasks: VerificationTaskResponseItem[];
}

export interface MoveTaskItem {
    currentTask: MoveTaskResponseItem;
    canStart?: boolean | undefined;
    maxEnergyReward?: number | undefined;
}

export interface PlayTaskItem {
    currentTask: PlayTaskResponseItem;
    canStart?: boolean | undefined;
    maxEnergyReward?: number | undefined;
}

export type TasksResponse = {
    socialTaskConfig: SocialTaskItem;
    verificationTaskConfig: VerificationTaskItem;

    moveTaskConfig: MoveTaskItem;

    playTaskConfig: PlayTaskItem;
};
export type VerificationTasksResponse = {
    verificationTaskConfig: VerificationTaskItem;
};
export type FeedTasksResponse = {
    playTaskConfig: PlayTaskItem;
};
export type MoveTasksResponse = {
    moveTaskConfig: MoveTaskItem;
};
export type SocialTasksResponse = {
    socialTaskConfig: SocialTaskItem;
};

export type CreateSocialTaskResponse = {
    createSocialTask: SocialTaskResponseItem;
};
export type MarkSocialTaskAsCompleted = {
    markSocialTaskAsCompleted: SocialTaskResponseItem;
};
export type CreateVerificationTaskResponse = {
    createVerificationTask: VerificationTaskResponseItem;
};

export type CompleteVerificationTaskItem = {
    id: string;
    status: VerificationTaskStatus;
    remainingVerificationAttempts: number;
};

export type CompleteVerificationTaskResponse = {
    completeVerificationTask: CompleteVerificationTaskItem;
};
export type MarkMoveTaskDoneResponse = {
    markMoveTaskDone: MoveTaskResponseItem;
};
export type CreateMoveTaskResponse = {
    createMoveTask: MoveTaskResponseItem;
};
export type MarkPlayTaskDoneResponse = {
    markPlayTaskDone: PlayTaskResponseItem;
};
export type CreatePlayTaskResponse = {
    createPlayTask: PlayTaskResponseItem;
};
export type RateVerificationSubtaskPet = 'LIKE' | 'DISLIKE';

export type PaginationMeta = {
    itemCount: number;
    totalItems: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
};
export type TasksHistoryResponseType =
    | 'SocialTask'
    | 'VerificationTask'
    | 'PlayTask'
    | 'MoveTask';
export type TasksHistoryResponseItem = {
    id: string;
    maxEnergyReward: number;
    energyRewarded: number;
    type: TasksHistoryResponseType;
    updatedAt: string;
    createdAt: string;
    status: TaskStatus;
    failureReason: TaskFailureReason;
    petExperienceRewards: PetExperienceRewards[];
};

export type TasksHistoryResponse = {
    tasksHistory: {
        items: TasksHistoryResponseItem[];
        meta: PaginationMeta;
    };
};

export type GetLevelupPaymentsResponse = {
    levelupPayments: [PaymentOptionOutput];
};

export type GetRankupPaymentsResponse = {
    rankupPayments: [PaymentOptionOutput];
};

export type CreatePaymentOutput = {
    signedTransaction: SignedTransactionOutput;
    payment: PaymentOptionOutput;
};

export type CreateLevelupPaymentResponse = {
    createLevelUpPayment: CreatePaymentOutput;
};
export type CreateRankUpPaymentResponse = {
    createRankUpPayment: CreatePaymentOutput;
};

export type DonationsHistoryResponseItem = {
    id: string;
    igupAmountWei: number;
    updatedAt: string;
    createdAt: string;
    user: {
        mainPet: {
            image: {
                url: string;
                thumbnailUrl: string;
            };
        };
        walletAddress: string;
        username: string | null;
    };
    txExplorerUrl?: string;
};

export type DonationsHistoryResponsePaginationMeta = {
    itemCount: number;
    totalItems: number;
    itemsPerPage: number;
    totalPages: number;
    currentPage: number;
};
export type DonationsHistoryResponse = {
    donationsHistory: {
        items: DonationsHistoryResponseItem[];
        meta: DonationsHistoryResponsePaginationMeta;
    };
};

export type RewardsTransactionHistoryResponseItem = {
    id: string;
    type: RewardsTransactionType;
    igupAmountWei: string;
    createdAt: string;
    unlockAt: string | null;
    unlockedAmountWei: string | null;
    locked: boolean | null;
    childTransactions: RewardsTransactionHistoryResponseItem[];
};

export type RewardsTransactionHistoryResponse = {
    transactionHistory: {
        items: RewardsTransactionHistoryResponseItem[];
        meta: PaginationMeta;
    };
};

export type UserBalance = {
    total: string;
    available: string;
    locked: string;
};

export type UserBalanceResponse = {
    userBalance: UserBalance;
    pendingBalanceClaim: ClaimBalanceOutput | null;
};

export type ClaimBalanceOutput = {
    signedTransaction: SignedTransactionOutput;
    paymentOption: PaymentOptionOutput;
};

export type ClaimBalanceResponse = {
    claimBalance: ClaimBalanceOutput;
};

export type PendingClaimBalanceOutput = {
    signedTransaction: SignedTransactionOutput;
    paymentOption: PaymentOptionOutput;
};

export type PendingClaimBalanceResponse = {
    pendingBalanceClaim: PendingClaimBalanceOutput;
};

export type ModerationSocialTask = {
    id: string;
    platform: string;
    platformUsername: string;
    pet: Pet;
};

export type ModerationSocialTaskResponse = {
    moderationSocialTask: ModerationSocialTask;
};

export type ModerationSocialTasksResponse = {
    moderationSocialTasks: ModerationSocialTask[];
};

export type ModerateSocialTaskResponse = {
    moderateSocialTask: boolean;
};

export type PlayGames = 'SOCIAL' | 'VERIFY' | 'MOVE' | 'PLAY';

export type MainPetUserOutput = {
    walletAddress: string;
    mainPet: Pet;
    energy: number;
    username: string | null;
};

export type UserSocialPublicOutput = {
    username: string;
};

export type MainPetUserOutputReferral = {
    walletAddress: string;
    username: string | null;
    mainPet: Pet;
    energy: number;
    instagram: UserSocialPublicOutput | null;
    tiktok: UserSocialPublicOutput | null;
    twitter: UserSocialPublicOutput | null;
    facebook: UserSocialPublicOutput | null;
};

export type GetMyReferrerResponse = {
    myReferrer: MainPetUserOutputReferral | null;
};

export type RankingMeResponseItem = {
    rank: number;
    totalTasksCount: number;
    totalEnergyRewarded: number;
    user: MainPetUserOutput;
};

export type VirtualWalletTransactionType =
    | 'GameIterationReward'
    | 'ReferralReward'
    | 'Claim'
    | 'PetLevelUpPayment'
    | 'PetRankUpPayment'
    | 'MintBonus';

export type VirtualWalletTransaction = {
    id: string;
    type: VirtualWalletTransactionType;
    igupAmountWei: string;
    createdAt: string;
};

export type ReferralTransactionsItem = {
    id: string;
    referred: MainPetUserOutput;
    virtualWalletTransaction: VirtualWalletTransaction;
};

export type ReferralTransactionsResponse = {
    referralTransactions: {
        items: ReferralTransactionsItem[];
        meta: PaginationMeta;
    };
};

export type ReferralUsersType = 'Direct' | 'Indirect';

export type ReferralUsersItem = {
    active: boolean;
    id: string;
    user: MainPetUserOutputReferral;
    type: ReferralUsersType;
    createdAt: string;
};

export type TotalReferralUserCountsOutput = {
    directReferralsCount: number;
    indirectReferralsCount: number;
    activeDirectReferralsCount: number;
};

export type ReferralUsersResponse = {
    referralUsers: {
        items: ReferralUsersItem[];
        meta: PaginationMeta;
    };
    referralUserCounts: TotalReferralUserCountsOutput;
};

export type Text2ImageQuotaOutput = {
    dailySystemUsagePercentage: number;
    maxDailyUserUsage: number;
    currentDailyUserUsage: number;
    currentSystemUsagePercentage: number;
};

export type Text2ImageQuotaResponse = {
    text2ImageQuota: Text2ImageQuotaOutput;
};

export type GetRevivePaymentsResponse = {
    revivePayments: [PaymentOptionOutput];
};
export type GetAgeRevivePaymentsResponse = {
    ageRevivePayments: [PaymentOptionOutput];
};
export type GetHealupPaymentsResponse = {
    healupPayments: [PaymentOptionOutput];
};
export type CreateRevivePaymentOutput = {
    signedTransaction: SignedTransactionOutput;
    payment: PaymentOptionOutput;
};

export type CreateRevivePaymentResponse = {
    createRevivePayment: CreateRevivePaymentOutput;
};
export type CreateAgeRevivePaymentResponse = {
    createAgeRevivePayment: CreateRevivePaymentOutput;
};

export type CreateHealUpPaymentOutput = {
    signedTransaction: SignedTransactionOutput;
    payment: PaymentOptionOutput;
};

export type CreateHealUpPaymentResponse = {
    createHealUpPayment: CreateHealUpPaymentOutput;
};

export type NotificationSettings = {
    pushDailyReminder: boolean;
    pushRewardEarned: boolean;
    pushPetLowHealth: boolean;
    pushSocialTaskCompleted: boolean;
    pushPetDead: boolean;
    emailPetDead: boolean;
    emailSocialTaskCompleted: boolean;
};

export type NotificationSettingResponse = {
    notificationSettings: NotificationSettings;
};
export type SetNotificationSettingResponse = {
    setNotificationSettings: NotificationSettings;
};

export type StakingPlan = {
    id: string;
    durationDays: number;
    rewardMultiplierForMaxBoost: number;
    maxBoostRewardPercentage: number;
    name: string;
    color: string;
};
export type StakingPlanOutput = {
    plan: StakingPlan;
    additionalIgupAmountWeiStake: string;
    available: boolean;
};

export type StakingPlansResponse = {
    stakingMaxRewardPlanBoostsRequirements: StakingPlanOutput[] | null;
};

export type StakingPlanRewardBoostResponse = {
    stakingRewardPlanBoosts: {
        plan: StakingPlan;
        available: boolean;
        estimatedPercentage: number;
    }[];
};

export type CurrentStakingPlanRewardBoost = {
    estimatedPercentage: number | null;
};
export type CurrentStakingPlanRewardBoostResponse = {
    stakingRewardBoost: CurrentStakingPlanRewardBoost;
};

export type UserStake = {
    id: string;
    amountWei: string;
    durationDays: number;
    endDate: string;
    createdAt: string;
    updatedAt: string;
    stakingPlanId: string;
};

export type UserStakeResponse = {
    staked: UserStake;
};

export type CreateStakingResponse = {
    createStaking: CreateStakingOutput;
};

export type CreateStakingOutput = {
    signedTransaction: SignedTransactionOutput;
    payment: PaymentOptionOutput;
};

export type StakingBack = {
    navigateBack?: ROUTES;
    params?: { screen?: ROUTES };
};

export type SingleEnergyEstimatedGameRewardResponse = {
    singleEnergyEstimatedGameReward: string;
};

export type MysteryBoxPaymentsResponse = {
    lootboxPayments: PaymentOptionOutput[];
};

export type LootboxSaleOption = {
    count: number;
    id: string;
    payments: PaymentOptionOutput[];
};

export type MysteryBoxPaymentsOptionsResponse = {
    lootboxSaleOptions: [LootboxSaleOption];
};

export type CreateMysteryBoxResponse = {
    createLootbox: {
        payment: PaymentOptionOutput;
        signedTransaction: SignedTransactionOutput;
    };
};

export type OpenMysteryBoxResponse = {
    openLootbox: {
        signedTransaction: SignedTransactionOutput;
    };
};

export type IUserMysteryBox = {
    _type: string;
    _hex: string;
};

export type MysteryBoxSaleStatsOutput = {
    inProgress: boolean;
    startsAt: string;
    soldCount: number;
    totalSupply: number;
};

export type MysteryBoxSaleStatsResponse = {
    lootboxSaleStats?: MysteryBoxSaleStatsOutput;
};

export type MysteryBoxOpenBoxesOutput = {
    id: string;
    tokenId: string;
    rankId: string;
    consumedAt: string;
    createdAt: string;
    updatedAt: string;
};

export type MysteryBoxOpenBoxesResponse = {
    lootboxRewards: MysteryBoxOpenBoxesOutput[];
};

export type ReferralTotalTurnoverResponse = {
    referralTotalTurnover: {
        busdAmountWei: string;
    };
};

export type WhitelistRanksOutput = {
    id: string;
    slug: string;
};

export type WhitelistRanksOutputResponse = {
    whitelistRanks: WhitelistRanksOutput[];
};

export enum GAME_ITERATION_STATUS {
    PENDING = 'PENDING',
    FAILED = 'FAILED',
    COMPLETED = 'COMPLETED',
}

export type GameIterationOutput = 'PENDING' | 'FAILED' | 'COMPLETED';

export type GameIterationOutputResponse = {
    currentGameIteration: {
        status: GameIterationOutput;
    };
};

export type RankupInfo = {
    maxAgeDays: number;
    baseIgupRewardWei: string;
    dailyUnlockRate: number;
    rewardConsumptionPercentage: number;
};

export type RankupInfoResponse = {
    rankupInfo: RankupInfo;
};
export type UserRoleResponse = {
    userRoles: UserRole[];
};
export type LevelupInfo = {
    levelupRewardBoostPercentage: number;
};

export type LevelupInfoResponse = {
    petNextLevelInfo: LevelupInfo;
};

export type BanReasons = 'Stealing' | 'Scammer' | 'ProjectViolated' | 'Other';

export type BanReasonsOutput = {
    reason: BanReasons;
    label: string;
};

export type BanReasonsOutputResponse = {
    banReasons: BanReasonsOutput[];
};

export type EstimatedGamesTurnoverOutput = {
    estimatedGamesTurnover: {
        iguAmountWei: string;
        gamesCount: number;
    };
};

export type GamesTurnoverLatestRewardOutput = {
    latestGamesTurnoverReward: {
        iguAmountWei: string;
    } | null;
};
export enum DAILY_REWARD_STATUS {
    Bonus = 'Bonus',
    Daily = 'Daily',
    Both = 'Both',
    Unknown = 'Unknown',
}
