import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { TouchableOpacity, ViewStyle } from 'react-native';

import { IconColorType } from '../../constants/Colors';
import useThemedStyles from '../../hooks/useThemedStyles';
import Icon, { ICON_NAMES } from '../Icons';
import { View } from '../Themed';
import Tooltip from '../Tooltip';
import stylesMain from './styles';

interface IProps {
    tooltipContent?: any;
    onPress?: () => void;
    containerStyle?: ViewStyle | undefined;
    icon?: any;
    iconColor?: IconColorType | string;
    iconSize?: number;
    disabled?: boolean;
    isOpened?: boolean;
    setIsTooltipOpened?: Dispatch<SetStateAction<boolean>>;
}

const config = {
    timeout: 5000,
};

const InfoIcon: React.FunctionComponent<IProps> = ({
    tooltipContent,
    onPress,
    containerStyle,
    icon,
    iconColor = '#818598',
    iconSize = 24,
    disabled = false,
    isOpened = false,
    setIsTooltipOpened,
}) => {
    const [tooltip, setTooltip] = React.useState<boolean>(false);
    const styles = useThemedStyles(stylesMain);

    const handleOnPress = () => {
        setTooltip(true);

        if (onPress) {
            onPress();
        }
    };

    const renderIconButton = () => {
        return (
            <View style={[styles.container, styles.containerStyle]}>
                <TouchableOpacity
                    onPress={handleOnPress}
                    disabled={disabled}
                    activeOpacity={0.7}
                    style={containerStyle}>
                    {icon || (
                        <Icon
                            name={ICON_NAMES.QUESTION}
                            size={iconSize}
                            color={iconColor}
                        />
                    )}
                </TouchableOpacity>
            </View>
        );
    };

    if (!tooltipContent) {
        return renderIconButton();
    }

    return (
        <Tooltip
            isVisible={tooltip || isOpened}
            text={tooltipContent}
            timeout={config.timeout}
            onClose={() => {
                setTooltip(false);
                setIsTooltipOpened?.(false);
            }}>
            {renderIconButton()}
        </Tooltip>
    );
};

export default InfoIcon;
