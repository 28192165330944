import * as React from 'react';

import { Text, View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';

import stylesMain from './styles';

interface IProps {
    title: string;
    value: string;
}

const StakingCardFeature = ({ title, value }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={styles.feature}>
            <Text style={styles.key}>{title}</Text>
            <Text style={styles.value}>{value}</Text>
        </View>
    );
};
export default StakingCardFeature;
