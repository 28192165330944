import { StyleSheet } from 'react-native';

import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import Colors from '../../../../constants/Colors';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(20),
            marginVertical: verticalScale(20),
        },
        langBlock: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(12),
            backgroundColor: Colors[theme].inputBackground,
            alignItems: 'center',
            marginTop: verticalScale(6),
        },

        label: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
        },
        placeHolder: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 15,
                height: 24,
                weight: '400',
            }),
            letterSpacing: 0.16,
            marginRight: scale(16),

            flex: 1,
        },
        right: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: 'transparent',
        },
        icon: {
            color: Colors[theme].icon.secondary,
            marginRight: scale(8),
            backgroundColor: 'transparent',
        },
        titleInputWrapper: { marginTop: verticalScale(20) },
        input: {
            textAlignVertical: 'top',
        },
        bodyInputWrapper: { marginTop: verticalScale(20) },
    });

export default styles;
