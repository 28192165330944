import * as React from 'react';
import { LayoutRectangle, TouchableOpacity } from 'react-native';

import _ from 'lodash';

import CoinIcon from '@components/Icons/CoinIcon';
import StatusIcon from '@components/Icons/StatusIcon';
import TransactionStatusIcon from '@components/Icons/TransactionTypeIcon';
import { Text, View } from '@components/Themed';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { numberWithSign } from '@helpers/helpers';
import { dateFormatter } from '@helpers/time';
import { balanceFromWei } from '@helpers/wallet';
import { useHaptic } from '@hooks/useHaptic';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import {
    IGU_WALLET_TRANSACTIONS,
    IguWalletTransaction,
} from '@models/gamingWallet';
import ROUTES from '@navigation/routes';
import { StackActions, useNavigation } from '@react-navigation/native';

import stylesMain from './styles';

interface IProps {
    item: IguWalletTransaction;
    lastPendingClaimId: string | null | undefined;
    disabledOpacity?: boolean;
}

const GamingWalletTransactionHistoryItem = ({
    item,
    lastPendingClaimId,
    disabledOpacity,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const { triggerLight } = useHaptic();
    const { id, createdAt, iguAmountWei, type } = item;

    const status = id === lastPendingClaimId ? 'PENDING' : 'CONFIRMED';

    const date = dateFormatter(createdAt);

    const title = i18n.t(
        `wallet.gaming.transactionHistory.transactions.${type}.title`,
        {
            defaultValue: i18n.t('wallet.transactions.reward.UNKNOWN.name'),
        }
    );

    const transactionValueWithOutSign = (amount: string | null) => {
        return balanceFromWei(amount || '').valueShort;
    };

    const transactionValue = disabledOpacity
        ? numberWithSign(balanceFromWei(iguAmountWei).valueShort)
        : transactionValueWithOutSign(iguAmountWei);

    const statusIcon = status !== 'CONFIRMED' && <StatusIcon slug={status} />;

    const color =
        transactionValue[0] === '-'
            ? type === IGU_WALLET_TRANSACTIONS.Withdraw
                ? styles[status as keyof object].color
                : styles.paymentColor.color
            : styles[status as keyof object].color;

    const renderTransactionValue = () => {
        return (
            <>
                {disabledOpacity ? (
                    <Text style={[styles.value, { color }]}>
                        {transactionValue}
                    </Text>
                ) : (
                    <Text style={[styles.value, { color }]}>
                        {transactionValue}
                    </Text>
                )}
            </>
        );
    };

    const navigateToDetails = () => {
        navigation.dispatch(
            StackActions.push(ROUTES.GAMING_WALLET_TRANSACTION_DETAILS, {
                transaction: {
                    type,
                    date,
                    status,
                    color,
                    value: transactionValue,
                    iguAmountWei,
                },
            })
        );
    };

    const handlePress = async () => {
        await triggerLight();
        navigateToDetails();
    };

    // debounce function memorize
    const debounced = React.useMemo(
        () =>
            _.debounce(
                () => {
                    handlePress();
                },
                500,
                {
                    leading: true,
                    trailing: false,
                }
            ),
        [navigateToDetails, triggerLight]
    );

    // debounce callback
    const handleDebounce = React.useCallback(() => {
        debounced();
    }, [debounced]);

    const [transactionSize, setTransactionSize] =
        React.useState<LayoutRectangle>();

    return (
        <TouchableOpacity
            style={styles.itemContainer}
            activeOpacity={0.7}
            disabled={disabledOpacity}
            onPress={handleDebounce}
            onLayout={({ nativeEvent }) =>
                setTransactionSize(nativeEvent.layout)
            }>
            {disabledOpacity && (
                <View
                    style={[
                        styles.opacity,
                        {
                            width: transactionSize?.width,
                            height: transactionSize?.height,
                        },
                    ]}
                />
            )}
            <View style={styles.statusIcon}>{statusIcon}</View>

            <View style={styles.left}>
                <View style={styles.imgWrapper}>
                    <TransactionStatusIcon type={type} size={40} />
                </View>
                <View style={styles.textWrapper}>
                    <Text style={styles.title} numberOfLines={1}>
                        {title}
                    </Text>
                    <Text style={styles.date}>{date}</Text>
                </View>
            </View>
            <View style={styles.right}>
                {renderTransactionValue()}
                <CoinIcon slug={Coin.vigu} size={24} />
            </View>
        </TouchableOpacity>
    );
};

export const MemoizedGamingWalletTransactionHistoryItem = React.memo(
    GamingWalletTransactionHistoryItem
);
