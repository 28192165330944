import * as React from 'react';
import { useCallback } from 'react';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import PurchasingFromInput from '@components/CheckoutScreen/PurchasingFromInput';
import Divider from '@components/Divider';
import ModalDeposit from '@components/ModalDeposit';
import ModalLoader from '@components/ModalLoader';
import PullToRefresh from '@components/PullToRefresh';
import { View } from '@components/Themed';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';

import GamingWalletCheckoutSummary from '../components/GamingWalletCheckoutSummary';
import TopUpConfirmationModal from '../components/TopUpConfirmationModal';
import TransactionDetailsTopUp from '../components/TransactionDetailsTopUp';
import { useTopUpCheckoutScreen } from './hooks/useTopUpCheckoutScreen';
import stylesMain from './styles';

const TopUpCheckoutScreen = () => {
    const styles = useThemedStyles(stylesMain);

    const {
        navigateBack,
        renderFeeInfoIcon,
        isBNBEnough,
        notEnoughFundsError,
        onRefresh,
        depositModal,
        closeDepositModal,
        openDepositModal,
        balanceReloading,
        fee,
        onPressPurchase,
        isLoading,
        renderBlockchainErrorsModal,
        topUpAmount,
        customPayment,
        closeConfirmationModal,
        confirmationModal,
        openConfirmationModal,
        canOpenConfirmTransaction,
        summaryValues,
    } = useTopUpCheckoutScreen();

    const renderItem = useCallback(() => {
        return (
            <>
                <BlockHeader
                    containerStyle={styles.header}
                    title={i18n.t('checkout.title')}
                    text={i18n.t('checkout.text')}
                    type="primary"
                />
                {!!customPayment && (
                    <PurchasingFromInput
                        payment={customPayment}
                        isError={notEnoughFundsError || !isBNBEnough}
                        isBNBEnough={isBNBEnough}
                        onDepositPress={openDepositModal}
                        disabled
                    />
                )}
                <TransactionDetailsTopUp
                    onEditAmountPress={navigateBack}
                    amount={topUpAmount}
                    withContainer
                />
            </>
        );
    }, [topUpAmount, customPayment, notEnoughFundsError, isBNBEnough]);

    return (
        <View style={styles.container}>
            <ModalLoader
                isVisible={isLoading}
                text={i18n.t('checkout.loadingPurchasing')}
                timeout={0}
            />
            <NavigationBar filled={false} backIcon="leftArrow" />
            <PullToRefresh
                textLoading={i18n.t('pullToRefresh.reloadBalance.text')}
                refreshing={balanceReloading}
                onRefresh={onRefresh}
                renderItem={renderItem}
            />
            <Divider />
            <View style={styles.summary}>
                <GamingWalletCheckoutSummary values={summaryValues} />
            </View>

            <ButtonsBottom
                onPress={openConfirmationModal}
                loading={isLoading}
                title={i18n.t('wallet.gaming.gamingWalletButtons.topUp')}
                safe
                disabled={canOpenConfirmTransaction}
            />

            <ModalDeposit
                isVisible={depositModal}
                setIsVisible={() => closeDepositModal()}
                coin={isBNBEnough ? Coin.igu : Coin.bnb}
            />
            <TopUpConfirmationModal
                isVisible={confirmationModal}
                onClose={closeConfirmationModal}
                onConfirm={onPressPurchase}
                amount={topUpAmount}
                fee={fee?.executeTransaction.IGU}
                infoIcon={renderFeeInfoIcon}
            />
            {renderBlockchainErrorsModal()}
        </View>
    );
};

export default TopUpCheckoutScreen;
