import * as React from 'react';
import { LegacyRef, PropsWithChildren } from 'react';
import { View, ViewStyle } from 'react-native';

interface IProps {
    options: any;
    style?: ViewStyle;
}

const ViewShot = React.forwardRef((props: PropsWithChildren<IProps>, ref) => {
    return (
        <View ref={ref as LegacyRef<any>} style={props.style}>
            {props.children}
        </View>
    );
});

export default ViewShot;
