import * as React from 'react';
import { SafeAreaView } from 'react-native';

import StepFourImage from '@assets/icons/howTo/howToGamingAvailableWallet/stepFour.svg';
import StepOneImage from '@assets/icons/howTo/howToGamingAvailableWallet/stepOne.svg';
import StepThreeImage from '@assets/icons/howTo/howToGamingAvailableWallet/stepThree.svg';
import StepTwoImage from '@assets/icons/howTo/howToGamingAvailableWallet/stepTwo.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import Button from '@components/Button';
import Carousel from '@components/Carousel';
import CarouselScrollView from '@components/Carousel/components/CarouselScrollView';
import GamingWalletChangeFeature from '@components/GamingWalletChangeFeature';
import { ICON_NAMES } from '@components/Icons';
import ModalPvpBonus from '@components/ModalPvpBonus';
import ModalPvpBonusFreePet from '@components/ModalPvpBonusFreePet';
import PvpBonusBanner from '@components/PvpBonusBanner';
import { Text, View } from '@components/Themed';
import TimerWithCaptions from '@components/TimerWithCaptions';
import { useConfig } from '@contexts/ConfigContext';
import { useDimensions } from '@contexts/DimensionsContext';
import { usePlay } from '@contexts/PlayContext';
import { useUser } from '@contexts/UserContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { waitForNavigation } from '@helpers/helpers';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import NavigationBarModal from '@navigation/NavigationBar/NavigationBarModal';
import useNavigationListeners from '@navigation/hooks/useNavigationListeners';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import { HowToGamingAvailableWalletProps } from '../../../types';
import stylesMain from '../styles/styles';

const data = ['stepOne', 'stepTwo', 'stepThree', 'stepFour'];
const flow = 'howToGamingAvailableWallet';

const HowToGamingAvailableWalletScreen = ({
    route,
}: HowToGamingAvailableWalletProps) => {
    useNavigationListeners();
    const { withButton } = route.params;

    const { windowWidth } = useDimensions();
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const { user } = useUser();
    const { config } = useConfig();
    const { isFreePets } = usePlay();

    const handleBack = () => {
        navigation.pop();
    };

    const topUp = async () => {
        handleBack();
        await waitForNavigation();
        navigation.navigateToTopUp();
    };

    const direct = React.useMemo(
        () => user?.directReferralPercentage || 0,
        [user]
    );
    const indirect = React.useMemo(
        () => user?.indirectReferralPercentage || 0,
        [user]
    );
    const percent = config?.iguDepositBonus?.bonusPercentage || 0;

    const { isVisible, open, close } = useVisibleHook();
    const {
        isVisible: pvpBonusFreePet,
        open: openPvpBonusFreePet,
        close: closePvpBonusFreePet,
    } = useVisibleHook();

    const onPress = async () => {
        handleBack();
        await waitForNavigation();
        navigation.navigate(ROUTES.HOME);
    };

    const onPressFreePet = async () => {
        handleBack();
        await waitForNavigation();
        navigation.navigateToMint();
    };

    const renderItem = (item: string) => {
        const icon = () => {
            switch (item) {
                case 'stepOne':
                    return <StepOneImage />;
                case 'stepTwo':
                    return <StepTwoImage />;
                case 'stepThree':
                    return <StepThreeImage />;
                default:
                    return <StepFourImage />;
            }
        };

        const block = () => {
            switch (item) {
                case 'stepFour':
                    return (
                        <BlockHeader
                            emptyStateIcon={icon()}
                            title={i18n.t(`howTo.${flow}.${item}.title`)}
                            text={i18n.t(`howTo.${flow}.${item}.text`, {
                                direct,
                                indirect,
                            })}
                            type="emptyState"
                        />
                    );

                default:
                    return (
                        <BlockHeader
                            emptyStateIcon={icon()}
                            title={i18n.t(`howTo.${flow}.${item}.title`)}
                            text={i18n.t(`howTo.${flow}.${item}.text`)}
                            type="emptyState"
                        />
                    );
            }
        };

        return (
            <CarouselScrollView>
                <View style={styles.itemContainer}>
                    <View style={styles.content}>{block()}</View>
                </View>
                <PvpBonusBanner
                    containerStyle={styles.pvpBonus}
                    onButtonPress={!isFreePets ? open : openPvpBonusFreePet}
                />
                {!!config?.iguDepositBonus && (
                    <View style={styles.timerContainer}>
                        <Text style={styles.title}>
                            <Text style={styles.accent}>{`${percent}% `}</Text>
                            {i18n.t('HomeScreen.TimerTopUp.title')}
                        </Text>
                        <Text style={styles.text}>
                            {i18n.t('HomeScreen.TimerTopUp.text1', {
                                percent,
                                coin: Coin.vigu,
                            })}
                        </Text>
                        {!!config?.iguDepositBonus && (
                            <TimerWithCaptions
                                endOfTime={config.iguDepositBonus?.endsAt}
                                onEnd={() => undefined}
                            />
                        )}
                    </View>
                )}
                <GamingWalletChangeFeature customStyles={styles.cardFeature} />
            </CarouselScrollView>
        );
    };

    return (
        <View style={styles.main}>
            <View style={styles.header}>
                <NavigationBarModal
                    iconName={ICON_NAMES.CLOSE}
                    title={i18n.t(`howTo.${flow}.screenTitle`)}
                    onBack={handleBack}
                />
            </View>
            <Carousel
                data={data}
                sliderWidth={windowWidth}
                itemWidth={windowWidth}
                renderItem={renderItem}
                fullHeight
                pagination
                centredVertical
            />
            {withButton && (
                <View style={styles.buttonWrapper}>
                    <Button
                        title={i18n.t(`howTo.${flow}.button`)}
                        onPress={topUp}
                    />
                </View>
            )}
            <ModalPvpBonus
                isVisible={isVisible}
                onClose={close}
                onPress={onPress}
            />
            <ModalPvpBonusFreePet
                isVisible={pvpBonusFreePet}
                onClose={closePvpBonusFreePet}
                onPress={onPressFreePet}
            />
            <SafeAreaView />
        </View>
    );
};

export default HowToGamingAvailableWalletScreen;
