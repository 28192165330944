import * as React from 'react';

import { View } from '../../../../components/Themed';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import NavigationBar from '../../../../navigation/NavigationBar';
import { WalletCoinsTransactionDetailsProps } from '../../../../types';
import TransactionDetailsContent from '../../components/TransactionDetailsContent';
import stylesMain from './styles';

const TransactionDetailsScreen = ({
    route,
}: WalletCoinsTransactionDetailsProps) => {
    const styles = useThemedStyles(stylesMain);
    const { transaction } = route.params;

    return (
        <View style={styles.container}>
            <NavigationBar
                backIcon
                title={i18n.t('wallet.transactionDetails')}
                containerStyle={styles.navBg}
            />
            <TransactionDetailsContent transaction={transaction} />
        </View>
    );
};

export default TransactionDetailsScreen;
