import * as React from 'react';
import {
    Pressable,
    StyleProp,
    Text,
    TextStyle,
    View,
    ViewStyle,
} from 'react-native';
import { SvgProps } from 'react-native-svg';

import _ from 'lodash';

import Colors from '../../constants/Colors';
import { useTheme } from '../../contexts/ThemeContext';
import { isWeb } from '../../helpers/app';
import { stylesWebOutlines } from '../../helpers/style';
import { useHaptic } from '../../hooks/useHaptic';
import useThemedStyles from '../../hooks/useThemedStyles';
import i18n from '../../i18n/i18n';
import ActivityIndicator from '../ActivityIndicator';
import Icon, { ICON_NAMES } from '../Icons';
import stylesMain from './styles';

export type ButtonType = 'fill' | 'outline' | 'text' | 'critical' | 'success';

export interface IButton {
    onPress?: () => void;
    onLongPress?: () => void;
    onPressOut?: () => void;
    title?: string;
    disabled?: boolean;
    style?: ViewStyle | undefined;
    textStyles?: TextStyle | undefined;
    containerStyle?: ViewStyle | undefined | StyleProp<ViewStyle>;
    children?: React.ReactNode;
    type?: ButtonType;
    size?: 'sm' | 'md' | 'lg';
    icon?: React.ReactElement<SvgProps>;
    iconDirection?: 'left' | 'right';
    iconName?: ICON_NAMES | null;
    iconColor?: string | null;
    iconSize?: number | null;
    social?: boolean;
    customIconStyles?: StyleProp<ViewStyle>;
    withHaptic?: boolean;
    pressedStyle?: boolean;
    debouncedPress?: boolean;
    debounceTime?: number;
    loading?: boolean;
    disableIfLoading?: boolean;
    loadingText?: string;
    withLoadingText?: boolean;
    tailTitle?: boolean;
    isOneColor?: boolean;
    disabledWithoutChangeStyles?: boolean;
}

const Button: React.FunctionComponent<IButton> = ({
    onPress = () => null,
    onLongPress = () => null,
    onPressOut = () => null,
    title = undefined,
    type = 'fill',
    style = undefined,
    textStyles = undefined,
    children,
    disabled = false,
    size = 'lg',
    icon,
    iconDirection = 'left',
    iconName = null,
    iconColor = undefined,
    iconSize = undefined,
    social = false,
    containerStyle = undefined,
    customIconStyles,
    withHaptic = true,
    pressedStyle = true,
    debouncedPress,
    debounceTime = 1000,
    loading,
    disableIfLoading = true,
    loadingText,
    withLoadingText = true,
    tailTitle = false,
    isOneColor,
    disabledWithoutChangeStyles = false,
}) => {
    const styles = useThemedStyles(stylesMain, isOneColor);
    const { theme } = useTheme();
    const isDisabled = disabled || (disableIfLoading && loading);
    const { triggerHeavy } = useHaptic();
    const onPressRef = React.useRef(onPress);

    const rightTheme = React.useMemo(
        () => (isOneColor ? 'light' : theme),
        [isOneColor, theme]
    );

    React.useEffect(() => {
        onPressRef.current = onPress;
    }, [onPress]);

    const buttonStyles = React.useMemo(() => {
        const types = {
            fill: {
                button: styles.buttonFilled,
                buttonPressed: styles.buttonFilledPressed,
                text: styles.buttonFilledText,
                textPressed: {},
                color: Colors[rightTheme].buttonText,
                colorPressed: {},
                shadow: styles.buttonFilledShadow,
            },
            outline: {
                button: styles.buttonOutlined,
                buttonPressed: styles.buttonOutlinedPressed,
                text: styles.buttonText,
                textPressed: styles.buttonTextedTextPressed,
                color: Colors[rightTheme].buttonText,
                colorPressed: {},
                shadow: styles.buttonOutlinedShadow,
            },
            critical: {
                button: styles.buttonCritical,
                buttonPressed: styles.buttonCriticalPressed,
                text: styles.buttonCriticalText,
                textPressed: {},
                color: Colors[rightTheme].buttonText,
                colorPressed: {},
                shadow: styles.buttonCriticalShadow,
            },

            success: {
                button: styles.buttonSuccess,
                buttonPressed: styles.buttonSuccessPressed,
                text: styles.buttonSuccessText,
                textPressed: {},
                color: Colors[rightTheme].buttonText,
                colorPressed: {},
                shadow: styles.buttonSuccessShadow,
            },
            text: {
                button: styles.buttonTexted,
                buttonPressed: styles.buttonTextedPressed,
                text: styles.buttonTextedText,
                textPressed: styles.buttonTextedTextPressed,
                color: Colors[rightTheme].buttonTextTexted,
                colorPressed: Colors[rightTheme].buttonTextTextedPress,
                shadow: { alignSelf: 'flex-start' },
            },
        };

        const typesDisabled = {
            fill: {
                button: styles.buttonFilledDisabled,
                buttonPressed: styles.buttonFilledDisabled,
                text: styles.buttonTextDisabled,
                textPressed: {},
                color: Colors[rightTheme].buttonTextDisabled,
                colorPressed: {},
                shadow: styles.buttonFilledShadowDisabled,
            },
            outline: {
                button: styles.buttonOutlinedDisabled,
                buttonPressed: styles.buttonOutlinedDisabled,
                text: styles.buttonTextDisabled,
                textPressed: {},
                color: Colors[rightTheme].text.onDark,
                colorPressed: {},
                shadow: styles.buttonOutlinedShadowDisabled,
            },
            critical: {
                button: styles.buttonCriticalDisabled,
                buttonPressed: styles.buttonCriticalDisabled,
                text: styles.buttonCriticalTextDisabled,
                textPressed: {},
                color: Colors[rightTheme].buttonTextCriticalDisabled,
                colorPressed: {},
                shadow: styles.buttonCriticalShadowDisabled,
            },
            success: {
                button: styles.buttonSuccessDisabled,
                buttonPressed: styles.buttonSuccessDisabled,
                text: styles.buttonSuccessTextDisabled,
                textPressed: {},
                color: Colors[rightTheme].buttonTextSuccessDisabled,
                colorPressed: {},
                shadow: styles.buttonSuccessShadowDisabled,
            },
            text: {
                button: styles.buttonTexted,
                buttonPressed: styles.buttonTexted,
                text: styles.buttonTextedTextDisabled,
                textPressed: {},
                color: Colors[rightTheme].buttonTextTextedDisabled,
                colorPressed: {},
                shadow: {},
            },
        };

        return isDisabled ? typesDisabled[type] : types[type];
    }, [type, isDisabled, rightTheme]);

    const textSize = React.useMemo(() => {
        const sizes: {
            [key: string]: TextStyle;
        } = {
            sm: styles.buttonTextSm,
            md: styles.buttonTextMd,
            lg: styles.buttonTextLg,
        };
        return sizes[size];
    }, [size]);

    const buttonSize = React.useMemo(() => {
        const sizes: {
            [key: string]: TextStyle;
        } = {
            sm: styles.buttonSm,
            md: styles.buttonMd,
            lg: styles.buttonLg,
        };
        return sizes[size];
    }, [size]);

    const buttonIconSize = React.useMemo(() => {
        const sizes: {
            [key: string]: TextStyle;
        } = {
            sm: styles.buttonIconSm,
            md: styles.buttonIconMd,
            lg: styles.buttonIconLg,
        };
        return sizes[size];
    }, [size]);

    const iconSizes = React.useMemo(() => {
        const sizes: {
            [key: string]: number;
        } = {
            sm: 12,
            md: 16,
            lg: 20,
        };
        return sizes[size];
    }, [size]);

    const renderIcon = () => {
        if (!iconName && !icon) {
            return null;
        }

        return (
            <View
                style={[
                    iconDirection === 'left'
                        ? styles.iconLeft
                        : styles.iconRight,
                    social && styles.iconSocial,
                    (iconName || !!icon) &&
                        title === undefined &&
                        children === undefined &&
                        styles.buttonIcon,
                    customIconStyles,
                ]}>
                {icon || (
                    <Icon
                        name={iconName!}
                        color={iconColor || buttonStyles.color}
                        stroke={iconColor || buttonStyles.color}
                        size={iconSize || iconSizes}
                    />
                )}
            </View>
        );
    };

    const handlePress = async () => {
        if (withHaptic) await triggerHeavy();
        onPressRef.current();
    };

    // debounce callback
    const handleDebounce = React.useCallback(
        _.debounce(
            () => {
                handlePress();
            },
            debounceTime,
            {
                leading: true,
                trailing: false,
            }
        ),
        [withHaptic, triggerHeavy, debounceTime]
    );

    const renderText = () => {
        if (!loading || !withLoadingText) {
            return title;
        }

        return loadingText ?? i18n.t('general.loading');
    };

    return (
        <Pressable
            onPress={debouncedPress ? handleDebounce : handlePress}
            onPressOut={onPressOut}
            onLongPress={() => {
                // give feedback on long press
                if (withHaptic) triggerHeavy();
                onLongPress?.();
            }}
            style={[
                buttonStyles.shadow,
                containerStyle,
                isWeb && stylesWebOutlines(),
            ]}
            disabled={isDisabled || disabledWithoutChangeStyles}>
            {({ pressed }) => (
                <View
                    style={[
                        styles.button,
                        type !== 'text' && buttonSize,
                        buttonStyles.button,
                        pressed && buttonStyles.buttonPressed,
                        (iconName || !!icon) &&
                            title === undefined &&
                            children === undefined &&
                            buttonIconSize,
                        style,
                    ]}>
                    {loading && (
                        <View
                            style={[
                                styles.loader,
                                (!!iconName || !!icon) && styles.loaderNoLeft,
                            ]}>
                            <ActivityIndicator loading={loading} />
                        </View>
                    )}

                    {iconDirection === 'left' && renderIcon()}

                    {title ? (
                        tailTitle ? (
                            <Text
                                numberOfLines={1}
                                ellipsizeMode={'tail'}
                                style={[
                                    textSize,
                                    buttonStyles.text,
                                    textStyles,
                                    // pressed && buttonStyles.textPressed,
                                    pressed && pressedStyle
                                        ? buttonStyles.textPressed
                                        : null,
                                ]}>
                                {renderText()}
                            </Text>
                        ) : (
                            <Text
                                numberOfLines={1}
                                ellipsizeMode={'tail'}
                                style={[
                                    textSize,
                                    buttonStyles.text,
                                    textStyles,
                                    // pressed && buttonStyles.textPressed,
                                    pressed && pressedStyle
                                        ? buttonStyles.textPressed
                                        : null,
                                ]}>
                                {renderText()}
                            </Text>
                        )
                    ) : (
                        children
                    )}

                    {iconDirection === 'right' && renderIcon()}
                </View>
            )}
        </Pressable>
    );
};

export default Button;
