import * as React from 'react';
import { ScrollView } from 'react-native';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import { View } from '@components/Themed';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { priceString } from '@helpers/payments';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';

import BreedCost from '../../components/BreedCost';
import ChancesToBreedTable from '../../components/ChancesToBreedTable';
import ConfirmBreedModal from '../../components/ConfirmBreedModal';
import GamesNavigation from '../../components/GamesNavigation';
import ModalAiInfo from '../../components/ModalAiOverload';
import NftEvolutionBreedProcess from '../../components/NftEvolutionBreedProcess';
import NftEvolutionSelectPetsBlock from '../../components/NftEvolutionSelectPetsBlock';
import useNftEvolutionCheckoutScreen from './hooks/useNftEvolutionCheckoutScreen';
import stylesMain from './styles';

const SCREEN = 'NftEvolutionCheckout';

const NftEvolutionCheckoutScreen = () => {
    const styles = useThemedStyles(stylesMain);

    const {
        onInfoPress,
        breedingPetsPairStats,
        confirmationModal,
        breedCost,
        closeConfirmationModal,
        openConfirmationModal,
        handConfirmBreed,
        isLoading,
        breedLoaded,
        aiOverload,
        closeAiOverload,
    } = useNftEvolutionCheckoutScreen();

    return (
        <View style={styles.main}>
            <GamesNavigation
                type={MYSTERY_GAMES.nftEvolution}
                handleOnInfoIconPress={onInfoPress}
            />
            <ScrollView showsVerticalScrollIndicator={false}>
                <NftEvolutionSelectPetsBlock disabled />

                <View style={styles.content}>
                    {!!breedingPetsPairStats?.chances && (
                        <ChancesToBreedTable
                            title={i18n.t(
                                `HomeScreen.NftEvolution.${SCREEN}.title`
                            )}
                            text={i18n.t(
                                `HomeScreen.NftEvolution.${SCREEN}.text`
                            )}
                            data={breedingPetsPairStats?.chances}
                            containerStyle={styles.table}
                        />
                    )}

                    <BreedCost amount={breedCost} />
                </View>
            </ScrollView>

            <ButtonsBottom
                title={i18n.t(`HomeScreen.NftEvolution.${SCREEN}.button`, {
                    amount: priceString(breedCost, Coin.vigu),
                })}
                onPress={openConfirmationModal}
                tailTitle
                safe
            />
            {!!breedingPetsPairStats?.viguAmountWei && (
                <ConfirmBreedModal
                    breedCost={breedingPetsPairStats?.viguAmountWei}
                    isVisible={confirmationModal}
                    onClose={closeConfirmationModal}
                    onAction={handConfirmBreed}
                />
            )}
            {isLoading && (
                <NftEvolutionBreedProcess breedLoaded={breedLoaded} />
            )}
            <ModalAiInfo isVisible={aiOverload} onClose={closeAiOverload} />
        </View>
    );
};

export default NftEvolutionCheckoutScreen;
