import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(20),
            marginTop: verticalScale(32),
            marginBottom: verticalScale(16),
        },
        main: {
            flex: 1,
        },
        header: { marginBottom: verticalScale(20) },

        slots: {
            marginTop: verticalScale(20),
        },
        inputWebContainer: {
            marginLeft: 0,
            textAlign: 'center',
        },
        inputMainContainer: {
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(20),
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
        },
        inputWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center',
        },
        inputContainer: {
            ...font({
                size: 22,
                weight: '700',
            }),
            marginLeft: scale(8),
            minWidth: 45,
            color: Colors[theme].text.primary,
        },
        icon: {
            marginTop: verticalScale(6),
        },

        error: {
            marginTop: verticalScale(8),
            marginBottom: verticalScale(4),
        },
        feature: { marginTop: verticalScale(12) },
        alert: {
            marginTop: verticalScale(16),
        },
        alertBalance: {
            marginHorizontal: scale(20),
        },
        availableWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: verticalScale(16),
        },
        buttonAll: { marginLeft: scale(8) },
    });

export default styles;
