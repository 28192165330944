import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { isWeb } from '@helpers/app';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            borderRadius: moderateScale(12),
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
        },
        disabled: {
            opacity: 0.5,
        },
        title: {
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
            letterSpacing: 0.08,
            color: Colors[theme].text.onLight,
        },

        boost: {
            marginTop: verticalScale(2),
            ...font({
                size: 18,
                height: 24,
                weight: '700',
            }),
            color: Colors[theme].text.onLight,
        },
        lock: {
            flexDirection: 'row',
            marginTop: verticalScale(8),
            backgroundColor: 'transparent',
            alignItems: 'flex-end',
        },
        number: {
            ...font({
                size: 48,
                height: 56,
                weight: '700',
            }),
            color: Colors[theme].text.onLight,
        },
        days: {
            ...font({
                size: 22,
                height: 56,
                weight: '700',
            }),
            color: Colors[theme].text.onLight,
        },
        left: {
            backgroundColor: 'transparent',
            marginRight: scale(24),
        },
        right: {
            backgroundColor: 'transparent',
        },
        inner: {
            backgroundColor: 'transparent',
            flexDirection: 'row',
        },
        coin: {
            position: 'absolute',
            width: 106,
            height: isWeb ? 'auto' : 122,
            right: 8,
            bottom: 0,
            backgroundColor: 'transparent',
        },
    });

export default styles;
