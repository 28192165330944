import * as React from 'react';

import Button, { ButtonType } from '@components/Button';
import ButtonsBottom from '@components/ButtonsBottom';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    firstButtonTitle?: string;
    secondButtonTitle?: string;
    firstButtonAction: () => void;
    secondButtonAction: () => void;
    isOneTheme?: boolean;
    firstButtonType?: ButtonType;
    white?: boolean;
    safe?: boolean;
    loading?: boolean;
}

const GamesHistoryButtons = ({
    firstButtonAction,
    secondButtonAction,
    firstButtonType,
    isOneTheme,
    firstButtonTitle,
    secondButtonTitle,
    white,
    safe = true,
    loading,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <ButtonsBottom
            title={
                firstButtonTitle || i18n.t('general.BUTTONS.exploreMoreGames')
            }
            onPress={firstButtonAction}
            containerStyle={styles.transparent}
            isOneColor={isOneTheme}
            buttonType={firstButtonType}
            safe={safe}
            loading={loading}
            disabled={loading}>
            <Button
                loading={loading}
                disabled={loading}
                isOneColor={isOneTheme}
                textStyles={white && styles.white}
                debouncedPress
                type="text"
                containerStyle={styles.additionalButton}
                title={
                    secondButtonTitle ||
                    i18n.t('general.BUTTONS.viewGamesHistory')
                }
                onPress={secondButtonAction}
            />
        </ButtonsBottom>
    );
};

export default GamesHistoryButtons;
