import * as React from 'react';
import { useState } from 'react';

import * as Facebook from 'expo-auth-session/providers/facebook';

import { useMutation } from '@apollo/client';
import { ConfigContext } from '@contexts/ConfigContext';
import { SessionContext } from '@contexts/SessionContext';
import { isWeb, projectNameForProxy } from '@helpers/app';
import { isGraphqlError } from '@helpers/helpers';

import { LoginWithFacebookResponse } from '../../../../Data/Models';
import { LOGIN_WITH_FACEBOOK } from '../../../../Data/Requests';
import MaintenanceModal from '../../../../components/AppState/FullMaintenanceState/components/MaintenanceModal';
import Button from '../../../../components/Button';
import CustomModal from '../../../../components/CustomModal';
import { ICON_NAMES } from '../../../../components/Icons';
import ImageIguana from '../../../../components/ImageIguana';
import ModalLoader from '../../../../components/ModalLoader';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import style from '../../styles';

const LoginWithFacebook: React.FunctionComponent = () => {
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isError, setIsError] = React.useState<boolean>(false);
    const { config } = React.useContext(ConfigContext);
    const styles = useThemedStyles(style);
    const sessionContext = React.useContext(SessionContext);
    const { setSessionToken } = sessionContext;
    const [request, { data }] =
        useMutation<LoginWithFacebookResponse>(LOGIN_WITH_FACEBOOK);
    const [isLoginMaintenance, setIsLoginMaintenance] =
        useState<boolean>(false);

    const [facebookRequest, response, promptAsync] = Facebook.useAuthRequest(
        {
            clientId: config?.facebookAppId,
            expoClientId: config?.facebookAppId,
            webClientId: config?.facebookAppId,
        },
        { projectNameForProxy }
    );

    const handleLogin = async () => {
        try {
            await promptAsync();
        } catch (error) {
            Console.error('[FACEBOOK] handleLogin', error);
            setIsError(true);
        }
    };

    const handleRequest = (accessToken: any) => {
        setIsLoading(true);
        request({
            variables: {
                loginWithFacebookInput: { accessToken },
            },
        })
            .then(async (result) => {
                setIsLoading(false);
                if (result.data) {
                    setSessionToken(result.data.loginWithFacebook.access_token);
                }
            })
            .catch(async (error) => {
                Console.error('[FACEBOOK] response=', error);
                setIsLoading(false);

                if (isGraphqlError(error, 'FULL_MAINTENANCE')) {
                    setIsLoginMaintenance(true);
                } else {
                    setIsError(true);
                }
            });
    };

    React.useEffect(() => {
        if (response?.type === 'success') {
            // Console.log('[FACEBOOK]', response.params);
            handleRequest(response.params?.access_token);
        } else if (isWeb) {
            // fix for some web devices, which return url https://iguverse.ap.ngrok.io/?#access_token=EAAFX6jelaw4BAJqKo39zX7D0hOIj5oHjCUnEFAL0ZCpxecXli6PSMc9Y1CIfN4boSfbpVti07Y0mHBIauZCrHowB2BAWwZAPamIcZBhhorZCEg9xxQf2u3xIPmZCgR993n3ipWCBGqqd8TpDuBOxD94hg0oZBP4QGLn81rlUIxUzZAhanSS7kZBTPAeaHJvejB2NIwRUyQ0jGjAZDZD&data_access_expiration_time=1675067094&expires_in=5178580&state=uSCWUpyy62
            const urlParams = new URLSearchParams(
                window.location.hash.replace('#', '')
            );

            if (
                urlParams.get('data_access_expiration_time') &&
                urlParams.get('access_token')
            ) {
                handleRequest(urlParams.get('access_token'));
            }
        }
    }, [response]);

    return (
        <>
            <Button
                type="outline"
                onPress={handleLogin}
                containerStyle={styles.buttonGap}
                title={i18n.t('login.facebook')}
                iconName={ICON_NAMES.FACEBOOK}
                social
                debouncedPress
            />
            <ModalLoader
                isVisible={isLoading}
                text={i18n.t('login.loading.loggingIn')}
            />
            <CustomModal
                isVisible={isError}
                icon={<ImageIguana type="cry" />}
                titleText={i18n.t('login.errors.loginFailed')}
                infoText={i18n.t('login.errors.smthWentWrong')}
                firstButtonText={i18n.t('general.ok')}
                onFirstButtonClick={() => {
                    setIsError(false);
                }}
            />
            <MaintenanceModal
                isVisible={isLoginMaintenance}
                setIsVisible={setIsLoginMaintenance}
                canClose
            />
        </>
    );
};

export default LoginWithFacebook;
