import React, { useCallback, useMemo } from 'react';

import _ from 'lodash';

import { GET_NFT_ROYAL_AVAILABLE_GAMES } from '@Data/Requests';
import { useApolloClient } from '@apollo/client';
import { DESKTOP_LOAD_LIMIT } from '@constants/API';
import { isDesktop } from '@helpers/app';
import { errorsHandler } from '@helpers/errors';
import { useLoadingHook } from '@hooks/useLoadingHook';
import {
    ACTIVE_ORDER_LIST,
    NftRoyaleAvailableGameOutput,
    PaginatedNftRoyaleAvailableGamesOutputResponse,
    activeOrderFilterConfig,
} from '@models/mysteryGames';

export const useNftRoyalActiveOrders = () => {
    const client = useApolloClient();

    const [filter, setFilter] = React.useState<ACTIVE_ORDER_LIST>(
        activeOrderFilterConfig.defaultFilter
    );

    const {
        isLoading: ordersLoading,
        startLoading: startOrdersLoading,
        stopLoading: stopOrdersLoading,
    } = useLoadingHook(true);

    const {
        isLoading: refreshLoading,
        startLoading: startRefreshLoading,
        stopLoading: stopRefreshLoading,
    } = useLoadingHook();

    const {
        isLoading: filterLoading,
        startLoading: startFilterLoading,
        stopLoading: stopFilterLoading,
    } = useLoadingHook();
    const {
        isLoading: loadMore,
        startLoading: startLoadMore,
        stopLoading: stopLoadMore,
    } = useLoadingHook();

    const [orders, setOrders] =
        React.useState<NftRoyaleAvailableGameOutput[]>();

    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);

    const getOrders = useCallback(
        async (silent: boolean) => {
            try {
                silent ? startOrdersLoading() : startRefreshLoading();

                const result =
                    await client.query<PaginatedNftRoyaleAvailableGamesOutputResponse>(
                        {
                            query: GET_NFT_ROYAL_AVAILABLE_GAMES,
                            fetchPolicy: 'network-only',
                            variables: {
                                paginationInput: {
                                    order: filter,
                                    limit: isDesktop ? DESKTOP_LOAD_LIMIT : 10,
                                },
                            },
                        }
                    );

                if (result.data) {
                    setOrders(result.data.nftRoyaleAvailableGames.items);
                    setCurrentPage(
                        result.data.nftRoyaleAvailableGames.meta.currentPage
                    );
                    setTotalPages(
                        result.data.nftRoyaleAvailableGames.meta.totalPages
                    );
                }
                stopRefreshLoading();
                stopOrdersLoading();
            } catch (error) {
                stopRefreshLoading();
                stopOrdersLoading();
                errorsHandler(error, true);
            }
        },
        [client, filter]
    );

    const getOrdersByFilter = useCallback(
        async (item: ACTIVE_ORDER_LIST, force = false) => {
            try {
                if (item === filter && !force) {
                    return;
                }
                setFilter(item);
                startFilterLoading();

                const result =
                    await client.query<PaginatedNftRoyaleAvailableGamesOutputResponse>(
                        {
                            query: GET_NFT_ROYAL_AVAILABLE_GAMES,
                            fetchPolicy: 'network-only',
                            variables: {
                                paginationInput: {
                                    order: item,
                                    limit: isDesktop ? DESKTOP_LOAD_LIMIT : 10,
                                },
                            },
                        }
                    );

                if (result.data) {
                    setOrders(result.data.nftRoyaleAvailableGames.items);
                    setCurrentPage(
                        result.data.nftRoyaleAvailableGames.meta.currentPage
                    );
                    setTotalPages(
                        result.data.nftRoyaleAvailableGames.meta.totalPages
                    );
                }
                stopFilterLoading();
            } catch (error) {
                stopFilterLoading();
                errorsHandler(error, true);
            }
        },
        [client, filter]
    );

    const getOrdersLoadMore = useCallback(async () => {
        try {
            if (currentPage === totalPages || totalPages === 0) {
                return;
            }
            startLoadMore();

            const result =
                await client.query<PaginatedNftRoyaleAvailableGamesOutputResponse>(
                    {
                        query: GET_NFT_ROYAL_AVAILABLE_GAMES,
                        fetchPolicy: 'network-only',
                        variables: {
                            paginationInput: {
                                order: filter,
                                page: currentPage + 1,
                                limit: isDesktop ? DESKTOP_LOAD_LIMIT : 10,
                            },
                        },
                    }
                );

            if (result.data) {
                setOrders((prev) => {
                    return prev
                        ? _.uniqBy(
                              [
                                  ...prev,
                                  ...result.data.nftRoyaleAvailableGames.items,
                              ],
                              (o) => o.id
                          )
                        : result.data.nftRoyaleAvailableGames.items;
                });

                setCurrentPage(
                    result.data.nftRoyaleAvailableGames.meta.currentPage
                );
                setTotalPages(
                    result.data.nftRoyaleAvailableGames.meta.totalPages
                );
            }
            stopLoadMore();
        } catch (error) {
            stopLoadMore();
            errorsHandler(error, true);
        }
    }, [client, filter, totalPages, currentPage]);

    const canLoadMore = useMemo(
        () => currentPage !== totalPages,
        [currentPage, totalPages]
    );

    return {
        ordersLoading,
        refreshLoading,
        filterLoading,
        loadMore,
        orders,
        getOrders,
        getOrdersByFilter,
        getOrdersLoadMore,
        filter,
        canLoadMore,
    };
};
