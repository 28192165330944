import React from 'react';
import Animated, {
    useAnimatedStyle,
    useSharedValue,
    withSpring,
} from 'react-native-reanimated';

import { MysteryBoxSaleStatsOutput } from '../../../../../Data/Models';
import BannerMysteryBox from '../../../../../components/BannerMysteryBox';
import { verticalScale } from '../../../../../helpers/dimensions';
import { wait } from '../../../../../helpers/helpers';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import stylesMain from './styles';

interface IProps {
    onPress: () => void;
    isVisible: boolean;

    onEndOfTime: () => void;
    saleStat?: MysteryBoxSaleStatsOutput | undefined;
}

const config = {
    time: 7000,
    startPosition: -100,
    endPosition: 110,
};

const AnimatedBannerMysteryBox = ({
    onPress,
    isVisible,
    saleStat,
    onEndOfTime,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const bannerShare = useSharedValue(config.startPosition);

    React.useEffect(() => {
        if (isVisible) {
            bannerShare.value = withSpring(verticalScale(config.endPosition));
            wait(config.time).then(() => onPress());
            return;
        }
        bannerShare.value = withSpring(verticalScale(config.startPosition));
    }, [isVisible]);

    const buttonSharedStyle = useAnimatedStyle(() => {
        return {
            bottom: bannerShare.value,
            zIndex: 1,
        };
    });

    const isComingSoon = React.useMemo(() => {
        if (!saleStat?.inProgress && saleStat?.startsAt) {
            return {
                time: saleStat.startsAt,
                onEnd: onEndOfTime,
            };
        }
    }, [saleStat]);

    const isNotAvailable = React.useMemo(() => {
        if (!saleStat) {
            return true;
        }
        return false;
    }, [saleStat]);

    return (
        <Animated.View style={[styles.container, buttonSharedStyle]}>
            <BannerMysteryBox
                onPress={onPress}
                isComingSoon={isComingSoon}
                isNotAvailable={isNotAvailable}
            />
        </Animated.View>
    );
};

export default AnimatedBannerMysteryBox;
