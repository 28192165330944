import React from 'react';
import { StyleProp, ViewProps } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';

import { isAndroid } from '@helpers/app';

interface IProps {
    children: React.ReactNode;
    style?: StyleProp<ViewProps>;
}

const GestureRootView = ({ children, style }: IProps) => {
    if (isAndroid) {
        return (
            <GestureHandlerRootView style={style}>
                {children}
            </GestureHandlerRootView>
        );
    }
    return <>{children}</>;
};

export default GestureRootView;
