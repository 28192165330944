import { useMemo, useState } from 'react';

import {
    GET_IGU_GAMING_BALANCE,
    GET_IGU_PENDING_WITHDRAW,
    WITHDRAW_IGU,
} from '@Data/Requests';
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import { errorsHandler } from '@helpers/errors';
import { balanceFromWei } from '@helpers/wallet';
import { useLoadingHook } from '@hooks/useLoadingHook';
import {
    CreateWithdrawIguResponse,
    GAMING_WALLET,
    GamingIguBalanceResponse,
    IGamingWallet,
    WithdrawIguPendingBalanceResponse,
} from '@models/gamingWallet';

export const useGamingBalances = () => {
    const [getWithdrawIguPendingBalance] =
        useLazyQuery<WithdrawIguPendingBalanceResponse>(
            GET_IGU_PENDING_WITHDRAW
        );
    const [withdrawIgu] = useMutation<CreateWithdrawIguResponse>(WITHDRAW_IGU);
    const client = useApolloClient();
    const [gamingBalance, setGamingBalance] =
        useState<GamingIguBalanceResponse>();
    const {
        isLoading: gamingBalanceLoading,
        startLoading: startLoadingGamingBalance,
        stopLoading: stopLoadingGamingBalance,
    } = useLoadingHook();

    const {
        isLoading: refreshLoading,
        startLoading: startRefreshLoading,
        stopLoading: stopRefreshLoading,
    } = useLoadingHook();

    const handleStopLoadingGamingBalance = () => {
        stopLoadingGamingBalance();
        stopRefreshLoading();
    };

    const getGamingBalance = async (silent: boolean) => {
        try {
            silent ? startLoadingGamingBalance() : startRefreshLoading();
            const result = await client.query<GamingIguBalanceResponse>({
                query: GET_IGU_GAMING_BALANCE,
                fetchPolicy: 'no-cache',
            });
            if (result.data) {
                setGamingBalance(result.data);
            }
        } catch (error) {
            handleStopLoadingGamingBalance();
            errorsHandler(error, true);
        }
        handleStopLoadingGamingBalance();
    };

    const gamingWalletCarouselData: IGamingWallet[] = useMemo(() => {
        if (!gamingBalance) {
            return [];
        }

        const availableAmount = balanceFromWei(
            gamingBalance?.iguBalance.available
        );
        const pendingAmount = balanceFromWei(gamingBalance?.iguBalance.locked);

        return [
            {
                id: GAMING_WALLET.available,
                coinAmount: availableAmount.valueLong,
            },
            {
                id: GAMING_WALLET.pending,
                coinAmount: pendingAmount.valueLong,
            },
        ];
    }, [gamingBalance]);

    const canWithdraw = useMemo(
        () =>
            gamingBalance?.iguBalance.available !== '0' ||
            !!gamingBalance?.iguPendingWithdraw,
        [gamingBalance]
    );

    return {
        gamingWalletCarouselData,
        gamingBalanceLoading,
        getGamingBalance,
        canWithdraw,
        refreshLoading,
        gamingBalance,
        getWithdrawIguPendingBalance,
        withdrawIgu,
    };
};
