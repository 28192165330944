import * as React from 'react';
import { useEffect, useState } from 'react';
import { SafeAreaView, ScrollView } from 'react-native';
import { width } from 'react-native-size-scaling';

import Button from '@components/Button';
import ColorPicker from '@components/ColorPicker';
import LoadingComponent from '@components/LoadingComponent';
import ModalBottom from '@components/ModalBottom';
import { View } from '@components/Themed';
import ViewShot from '@components/ViewShot';
import { PlayContext } from '@contexts/PlayContext';
import { isDesktop } from '@helpers/app';
import useImagePicker from '@hooks/useImagePicker';
import useSharePhoto from '@hooks/useShare';
import useTemplateColors from '@hooks/useTemplateColors';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import PetCardIdNew from '../../components/PetCardIdNew';
import ShareYourSocialBlock from '../../components/ShareYourSocialBlock';
import CreationModalContent from '../components/CreationModalContent';
import HeaderText from '../components/HeaderText';
import ModalShareYourPhoto from '../components/ModalShareYourPhoto';
import stylesMain from './styles';

export const ShareYourPhotoScreen = () => {
    const navigation = useNavigation();
    const styles = useThemedStyles(stylesMain);
    const {
        openCameraOrFromGallery,
        renderCropper,
        renderCameraPermissions,
        image,
    } = useImagePicker();

    const [isVisibleModalShare, setIsVisibleModalShare] =
        useState<boolean>(false);
    const [modalVisible, setModalVisible] = React.useState(false);
    const handleCloseModal = () => {
        setModalVisible(false);
    };

    const { playablePets, socialTask } = React.useContext(PlayContext);
    const { templatesColors, changeTemplateColor, templateColor } =
        useTemplateColors();

    const viewShotRef = React.useRef<any>(null);

    const {
        handleShareViewShot,
        handleSaveViewShot,
        renderCameraRollPermissions,
    } = useSharePhoto();

    const handleShareYourPhotoModalOpen = () => {
        setIsVisibleModalShare(true);
    };

    const handleShareYourPhotoModalClose = () => {
        setIsVisibleModalShare(false);
    };

    const handleSave = async () => {
        return handleSaveViewShot(viewShotRef);
    };

    // show loader only on web as we are downloading and image
    const handleShare = async () => {
        return handleShareViewShot(viewShotRef);
    };

    const handleOpenEditor = async () => {
        if (isDesktop) {
            openCameraOrFromGallery('gallery');
        } else {
            setModalVisible(true);
        }
    };

    const renderHiddenViewShot = () => (
        <ViewShot
            style={styles.viewShot}
            ref={viewShotRef}
            options={{
                format: 'png',
                quality: 1,
            }}>
            <PetCardIdNew
                templateColor={templateColor}
                tiktok={socialTask?.latestTask.platform === 'TIKTOK'}
            />
        </ViewShot>
    );

    useEffect(() => {
        if (image) {
            setModalVisible(false);
            handleShareYourPhotoModalClose();
            navigation.navigate(ROUTES.SOCIALIZE_TO_EARN_CREATE_SHARE_PHOTO, {
                uri: image.uri,
                type: image.type,
            });
        }
    }, [image]);

    if (!playablePets) {
        return <LoadingComponent fullPage />;
    }

    return (
        <>
            <NavigationBar backIcon="close" containerStyle={styles.navBg} />
            <View style={styles.container}>
                <ScrollView
                    style={styles.wrapper}
                    showsVerticalScrollIndicator={false}>
                    <HeaderText
                        titleStyle={{
                            width: width / 1.5,
                        }}
                        energy={socialTask?.maxEnergyReward}
                        title={i18n.t(
                            'socializeToEarn.shareYourPetPhoto.title'
                        )}
                        text={
                            socialTask?.latestTask
                                ? i18n.t(
                                      `socializeToEarn.shareYourPetPhoto.texts.${socialTask.latestTask.platform}`
                                  )
                                : ''
                        }
                        textInfo={i18n.t(
                            'socializeToEarn.shareYourPetPhoto.info'
                        )}
                        textInfoOnPress={() =>
                            navigation.navigate(ROUTES.HOW_TO_SOCIALIZE_TO_EARN)
                        }
                    />
                    {!!socialTask && (
                        <ShareYourSocialBlock
                            platform={socialTask.latestTask.platform}
                            platformUsername={
                                socialTask.latestTask.platformUsername
                            }
                        />
                    )}
                    <View style={styles.card}>
                        <PetCardIdNew
                            templateColor={templateColor || templatesColors[0]}
                        />

                        {renderHiddenViewShot()}
                        <View style={styles.colorPickerWrapper}>
                            <ColorPicker
                                templateColor={
                                    templateColor || templatesColors[0]
                                }
                                backgrounds={templatesColors}
                                handleBgColor={changeTemplateColor}
                            />
                        </View>
                    </View>
                </ScrollView>

                <View style={styles.buttonWrapper}>
                    <Button
                        title={i18n.t(
                            'socializeToEarn.shareYourPetPhoto.button'
                        )}
                        onPress={handleShareYourPhotoModalOpen}
                    />
                </View>
                <SafeAreaView />
            </View>
            <ModalShareYourPhoto
                isVisible={isVisibleModalShare}
                setIsVisible={setIsVisibleModalShare}
                handleSave={handleSave}
                handleShare={handleShare}
                handleOpenEditor={handleOpenEditor}>
                <ModalBottom
                    titleText={i18n.t(
                        'socializeToEarn.shareYourPetPhoto.selectCreationMethod'
                    )}
                    modalHeight={400}
                    isVisible={modalVisible}
                    onClose={handleCloseModal}
                    isNestedModal>
                    <CreationModalContent onPress={openCameraOrFromGallery} />
                    {renderCameraPermissions(() =>
                        openCameraOrFromGallery('camera')
                    )}
                </ModalBottom>
                {renderCropper()}
                {renderCameraRollPermissions(handleSave)}
            </ModalShareYourPhoto>
        </>
    );
};
export default ShareYourPhotoScreen;
