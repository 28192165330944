import * as React from 'react';
import { FlatList } from 'react-native';

import { useQuery } from '@apollo/client';
import AlertCard from '@components/AlertCard';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import EmptyList from '@components/EmptyList';
import { ICON_NAMES } from '@components/Icons';
import LoadingComponent from '@components/LoadingComponent';
import { View } from '@components/Themed';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { waitForNavigation } from '@helpers/helpers';
import { stylesAlignCenter } from '@helpers/style';
import { useSaveHeight } from '@hooks/useSaveHeight';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import {
    LuckyStrikeUserTicketOutputResponse,
    MYSTERY_GAMES,
} from '@models/mysteryGames';
import NavigationBarModal from '@navigation/NavigationBar/NavigationBarModal';
import useNavigationListeners from '@navigation/hooks/useNavigationListeners';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { GET_LUCKY_STRIKE_USER_TICKETS } from '@requests/luckyStrike';

import {
    LuckyStrikeUserTicketsProps,
    LuckyStrikeUserTicketsWaitProps,
} from '../../../../../types';
import LuckyStrikeTicketItem from '../../components/LuckyStrikeTicketItem';
import useSecureHashModal from '../../hooks/useSecureHashModal';
import stylesMain from './styles';

const DEFAULT_ARRAY = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

const LuckyStrikeUserTicketsWaitScreen = ({
    route,
}: LuckyStrikeUserTicketsWaitProps) => {
    useNavigationListeners();

    const { game } = route.params;

    const { luckyStrikeConfig } = useMysteryGames();

    const { data, loading } = useQuery<LuckyStrikeUserTicketOutputResponse>(
        GET_LUCKY_STRIKE_USER_TICKETS,
        {
            fetchPolicy: 'network-only',
            variables: {
                gameId: game.id,
            },
        }
    );

    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const handleBack = () => {
        navigation.pop();
    };
    const tickets = React.useMemo(() => {
        if (
            !data?.luckyStrikeUserTickets.length ||
            !luckyStrikeConfig?.maxTicketAmount
        ) {
            return [];
        }

        return [...data.luckyStrikeUserTickets, ...DEFAULT_ARRAY].slice(
            0,
            luckyStrikeConfig.maxTicketAmount
        );
    }, [luckyStrikeConfig, data]);

    const { renderSecureHashModal, openSecureHashModal } = useSecureHashModal();

    const alertText = React.useMemo(() => {
        if (
            !data?.luckyStrikeUserTickets.length ||
            !luckyStrikeConfig?.maxTicketAmount
        ) {
            return null;
        }

        const isMax =
            data.luckyStrikeUserTickets.length ===
            luckyStrikeConfig.maxTicketAmount;
        return isMax
            ? `${data.luckyStrikeUserTickets.length}/${luckyStrikeConfig.maxTicketAmount}`
            : null;
    }, [data, luckyStrikeConfig]);

    const { height, getHeight } = useSaveHeight('userTicket');

    const onEmptyTicketPress = async () => {
        navigation.pop();
        await waitForNavigation();
        navigation.navigate(ROUTES.LUCKY_STRIKE_MAIN, { game });
    };

    return (
        <View style={styles.main}>
            <View style={styles.header}>
                <NavigationBarModal
                    isOneColor
                    iconName={ICON_NAMES.CLOSE}
                    title={i18n.t('general.KEYS.tickets')}
                    onBack={handleBack}
                    titleStyle={styles.title}
                />
            </View>
            {loading ? (
                <LoadingComponent containerStyles={styles.loading} />
            ) : (
                <>
                    <View style={styles.listWrapper}>
                        <FlatList
                            ListHeaderComponent={
                                tickets.length ? (
                                    <BlockHeader
                                        containerStyle={styles.blockHeader}
                                        type={'primary'}
                                        title={i18n.t('general.KEYS.tickets')}
                                        text={i18n.t(
                                            `HomeScreen.${MYSTERY_GAMES.luckyStrike}.LuckyStrikeUserTickets.text`
                                        )}
                                        textInfoOnPress={openSecureHashModal}
                                    />
                                ) : null
                            }
                            columnWrapperStyle={styles.column}
                            numColumns={2}
                            showsVerticalScrollIndicator={false}
                            data={tickets}
                            initialNumToRender={10}
                            renderItem={({ item, index }) => (
                                <LuckyStrikeTicketItem
                                    key={index}
                                    item={item}
                                    fromWait
                                    height={height}
                                    setHeight={getHeight}
                                    onPress={onEmptyTicketPress}
                                />
                            )}
                            contentContainerStyle={
                                !tickets.length && {
                                    ...stylesAlignCenter(),
                                }
                            }
                            ListEmptyComponent={
                                <EmptyList
                                    emptyTitle={i18n.t(
                                        'general.errors.emptyErrorScreen.title'
                                    )}
                                    emptyText={i18n.t(
                                        'general.errors.emptyErrorScreen.text'
                                    )}
                                    withAnimation="cry"
                                    tailTitle
                                />
                            }
                        />
                    </View>
                    {!!alertText && (
                        <AlertCard
                            containerStyle={styles.alert}
                            text={i18n.t(
                                `HomeScreen.${MYSTERY_GAMES.luckyStrike}.LuckyStrikeUserTickets.alert`,
                                {
                                    text: alertText,
                                }
                            )}
                        />
                    )}
                </>
            )}
            {renderSecureHashModal()}
        </View>
    );
};

export default LuckyStrikeUserTicketsWaitScreen;
