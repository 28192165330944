import React, { useCallback } from 'react';

import { useStaking } from '@contexts/StakingContext';
import { useStakingHook } from '@hooks/useStakingHook';
import { useVisibleHook } from '@hooks/useVisibleHook';
import { useFocusEffect } from '@react-navigation/native';

export const useStakingAlreadyScreen = () => {
    const {
        currentStakingPlan,
        stakingPlans,
        setStakingOption,
        setIsAddMoreCoins,
        clearStaking,
        currentPlanBoost,
        setCurrentPlan,
        refetchCurrentBoost,
    } = useStaking();

    React.useEffect(() => {
        refetchCurrentBoost();
    }, []);

    useFocusEffect(
        useCallback(() => {
            setCurrentPlan(undefined);
        }, [])
    );

    const {
        isVisible: modalVisible,
        open: handleOpenModal,
        close: handleCloseModal,
    } = useVisibleHook();

    const {
        open: handleOpenConfirmationModal,
        close: handleCloseConfirmationModal,
        isVisible: isVisibleConfirmationModal,
        setIsVisible: setIsVisibleConfirmationModal,
    } = useVisibleHook();

    const {
        isLoading,
        handleUnStake,
        renderBlockchainErrorsModal,
        handleOnBack,
    } = useStakingHook();

    return {
        currentStakingPlan,
        stakingPlans,
        setStakingOption,
        setIsAddMoreCoins,
        clearStaking,
        currentPlanBoost,
        modalVisible,
        handleOpenModal,
        handleCloseModal,
        handleOpenConfirmationModal,
        handleCloseConfirmationModal,
        isVisibleConfirmationModal,
        setIsVisibleConfirmationModal,
        isLoading,
        handleUnStake,
        renderBlockchainErrorsModal,
        handleOnBack,
    };
};
