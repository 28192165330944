export enum TYPO_TEXT {
    DEFAULT = 'DEFAULT',
    SUBHEAD_BIG_BOLD = 'SUBHEAD_BIG_BOLD',
    SUBHEAD_SMALL_SEMIBOLD = 'SUBHEAD_SMALL_SEMIBOLD',
    SUBHEAD_LITTLE_SEMIBOLD = 'SUBHEAD_LITTLE_SEMIBOLD',
    SUBHEAD_SMALL_BOLD = 'SUBHEAD_SMALL_BOLD',
    SUBHEAD_LITTLE_BOLD = 'SUBHEAD_LITTLE_BOLD',
    HEADING_H2 = 'HEADING_H2',
    HEADING_H2_SEMIBOLD = 'HEADING_H2_SEMIBOLD',
    HEADING_H3 = 'HEADING_H3',
    HEADING_H4 = 'HEADING_H4',
    HEADING_H5 = 'HEADING_H5',
    SUBHEAD_MEDIUM_BOLD = 'SUBHEAD_MEDIUM_BOLD',
    SUBHEAD_MEDIUM_SEMIBOLD = 'SUBHEAD_MEDIUM_SEMIBOLD',
    BODY_BIG = 'BODY_BIG',
    HEADING_H6 = 'HEADING_H6',
    BODY_LITTLE = 'BODY_LITTLE',
    BODY_SMALL = 'BODY_SMALL',
    BODY_MEDIUM = 'BODY_MEDIUM',
}

export enum TYPO_TEXT_COLOR {
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY',
    ACCENT = 'ACCENT',
    ON_DARK = 'ON_DARK',
    INCORRECT = 'INCORRECT',
    GRAY = 'GRAY',
}
