import * as React from 'react';

import CircleDivider from '@components/CircleDivider';
import OtherUserAvatar from '@components/OtherUserAvatar';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT } from '@components/TypoText/types';
import { scale } from '@helpers/dimensions';
import { balanceFromWei, truncateEthAddress } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import { PaginatedLuckyStrikeGamesWinnerOutput } from '@models/mysteryGames';

import GameAmount from '../GameAmount';
import stylesMain from './styles';

interface IProps {
    item: PaginatedLuckyStrikeGamesWinnerOutput;
}

const LuckyStrikeWinnerItem = ({ item }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={[styles.itemContainer]}>
            <View style={styles.left}>
                <View style={styles.imageWrapper}>
                    <OtherUserAvatar
                        size={scale(48)}
                        imageUrl={item.avatarUrl}
                    />
                </View>
                <View style={styles.infoBlock}>
                    <TypoText
                        type={TYPO_TEXT.BODY_BIG}
                        textAlign="left"
                        ellipsizeMode={'middle'}
                        numberOfLines={1}
                        additionalStyles={styles.removeAlign}>
                        {truncateEthAddress(item.username)}
                    </TypoText>
                    <View style={styles.sub}>
                        <TypoText
                            type={TYPO_TEXT.SUBHEAD_LITTLE_SEMIBOLD}
                            numberOfLines={1}
                            additionalStyles={styles.roundName}>
                            {item.title}
                        </TypoText>
                        <CircleDivider />
                        <TypoText
                            type={TYPO_TEXT.SUBHEAD_LITTLE_SEMIBOLD}
                            numberOfLines={1}
                            additionalStyles={styles.tickets}>
                            {`#${item.number}`}
                        </TypoText>
                    </View>
                </View>
            </View>
            <View style={styles.right}>
                <GameAmount
                    amount={balanceFromWei(item.iguAmountWei).valueLong}
                />
            </View>
        </View>
    );
};
export default React.memo(LuckyStrikeWinnerItem);
