import React, { ReactElement } from 'react';
import { StyleProp, TextProps, TouchableOpacity } from 'react-native';
import { SvgProps } from 'react-native-svg';

import { Text, View } from '../../../../components/Themed';
import { useHaptic } from '../../../../hooks/useHaptic';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import stylesMain from './styles';

interface IProps {
    leftIcon: ReactElement<SvgProps>;
    rightComponent?: ReactElement<SvgProps>;
    title: string;
    description: string;
    onPress?: () => void;
    titleStyle?: StyleProp<TextProps>;
    textStyle?: StyleProp<TextProps>;
    disabled?: boolean;
}

const MenuItem = ({
    leftIcon,
    rightComponent,
    title,
    description,
    onPress,
    titleStyle,
    textStyle,
    disabled = false,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { triggerLight } = useHaptic();

    const handleOnPress = () => {
        triggerLight();
        onPress?.();
    };

    return (
        <TouchableOpacity
            style={[styles.itemContainer, disabled && styles.disabled]}
            onPress={handleOnPress}
            disabled={disabled}
            activeOpacity={0.7}>
            {leftIcon}
            <View style={styles.contentContainer}>
                <View style={styles.textContainer}>
                    <Text style={[styles.title, titleStyle]}>{title}</Text>
                    <Text style={[styles.description, textStyle]}>
                        {description}
                    </Text>
                </View>
                {rightComponent}
            </View>
        </TouchableOpacity>
    );
};

export default MenuItem;
