import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            width: '100%',
            backgroundColor: Colors[theme].shadow.default,
            borderRadius: moderateScale(12),
        },

        searchIconColor: {
            color: Colors[theme].icon.secondary,
        },

        inputContainer: {
            paddingVertical: verticalScale(9),
            paddingHorizontal: scale(12),
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: Colors[theme].controlSecondary,
            marginBottom: 2,
        },
        icon: {
            marginRight: scale(8),
        },
        input: {
            flex: 1,
            backgroundColor: 'transparent',
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
        focused: {
            borderColor: Colors[theme].border.accent,
        },
        delete: {
            marginLeft: verticalScale(16),
        },
    });

export default styles;
