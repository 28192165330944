import { StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import { scale, verticalScale } from '../../../../helpers/dimensions';
import font from '../../../../helpers/font';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
        },
        disabled: {
            opacity: 0.4,
        },
        description: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
        },
        itemContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingVertical: verticalScale(12),
            paddingHorizontal: scale(20),
        },
        contentContainer: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        textContainer: {
            maxWidth: '90%',
            paddingLeft: scale(14),
        },
    });

export default styles;
