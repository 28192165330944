import { useCallback, useMemo, useState } from 'react';
import { Image } from 'react-native';

import { BREEDING_BREED_PETS } from '@Data/Requests';
import { useMutation } from '@apollo/client';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { errorsHandler } from '@helpers/errors';
import { isGraphqlError, wait } from '@helpers/helpers';
import { balanceFromWei } from '@helpers/wallet';
import { useLoadingHook } from '@hooks/useLoadingHook';
import { useVisibleHook } from '@hooks/useVisibleHook';
import { BreedingPetDraftDetailsOutputResponse } from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

const useNftEvolutionCheckoutScreen = () => {
    const navigation = useNavigation();

    const {
        breedingPetsPairStats,
        nftEvolutionFirstPet,
        nftEvolutionSecondPet,
        setBreedingPetDraftDetails,
        getBreedingPetDrafts,
    } = useMysteryGames();

    navigation.blockHardwareBack();

    const {
        isVisible: confirmationModal,
        open: openConfirmationModal,
        close: closeConfirmationModal,
    } = useVisibleHook();

    const onInfoPress = () => {
        navigation.navigate(ROUTES.HOW_NFT_EVOLUTION_WELCOME);
    };

    const breedCost = useMemo(
        () => balanceFromWei(breedingPetsPairStats?.viguAmountWei).valueShort,
        [breedingPetsPairStats?.viguAmountWei]
    );
    const { isLoading, startLoading, stopLoading } = useLoadingHook();

    const [breed] =
        useMutation<BreedingPetDraftDetailsOutputResponse>(BREEDING_BREED_PETS);

    const [breedLoaded, setBreedLoaded] = useState(false);

    const {
        isVisible: aiOverload,
        close: closeAiOverload,
        open: openAiOverload,
    } = useVisibleHook();

    const handConfirmBreed = useCallback(async () => {
        closeConfirmationModal();
        startLoading();
        await wait(2000);
        try {
            const result = await breed({
                variables: {
                    pet1Id: nftEvolutionFirstPet?.id,
                    pet2Id: nftEvolutionSecondPet?.id,
                },
            });
            if (result.data?.breedingBreedPets) {
                await Image.prefetch(
                    result.data.breedingBreedPets.parent1.imageUrl
                );
                await Image.prefetch(
                    result.data.breedingBreedPets.parent2.imageUrl
                );
                await getBreedingPetDrafts(true);
                setBreedLoaded(true);
                setBreedingPetDraftDetails(result.data.breedingBreedPets);
            }
        } catch (error: any) {
            stopLoading();
            if (
                isGraphqlError(error, 'TEXT2IMAGE_SERVICE_BUSY') ||
                isGraphqlError(error, 'TEXT2IMAGE_MAINTENANCE')
            ) {
                return openAiOverload();
            }

            errorsHandler(error, true);
        }
    }, [nftEvolutionSecondPet, nftEvolutionFirstPet]);

    return {
        onInfoPress,
        breedingPetsPairStats,
        breedCost,
        confirmationModal,
        closeConfirmationModal,
        openConfirmationModal,
        handConfirmBreed,
        isLoading,
        breedLoaded,
        aiOverload,
        closeAiOverload,
    };
};

export default useNftEvolutionCheckoutScreen;
