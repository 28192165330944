import * as React from 'react';
import { SafeAreaView } from 'react-native';

import StepThreeImage from '@assets/icons/howTo/howToAvailableRewards/stepThree.svg';
import StepOneImage from '@assets/icons/howTo/howToLockedRewards/stepOne.svg';
import StepTwoImage from '@assets/icons/howTo/howToLockedRewards/stepTwo.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonKnowledgeBase from '@components/ButtonKnowledgeBase';
import Carousel from '@components/Carousel';
import { ICON_NAMES } from '@components/Icons';
import { View } from '@components/Themed';
import { Links } from '@constants/Links';
import { useDimensions } from '@contexts/DimensionsContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBarModal from '@navigation/NavigationBar/NavigationBarModal';
import useNavigationListeners from '@navigation/hooks/useNavigationListeners';
import { useNavigation } from '@navigation/useNavigation';

import stylesMain from '../styles/styles';

const data = ['stepOne', 'stepTwo', 'stepThree'];

const HowToLockedRewardsScreen = () => {
    useNavigationListeners();
    const { windowWidth } = useDimensions();
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const renderItem = (item: string) => {
        const icon = () => {
            switch (item) {
                case 'stepOne':
                    return <StepOneImage />;
                case 'stepTwo':
                    return <StepTwoImage />;
                case 'stepThree':
                    return <StepThreeImage />;
            }
        };

        const component = () => {
            switch (item) {
                case 'stepThree':
                    return (
                        <BlockHeader
                            title={i18n.t(
                                `howTo.availableRewards.${item}.title`
                            )}
                            additionalTitle={Coin.iguai}
                            text={i18n.t(`howTo.lockedRewards.${item}.text`)}
                            type="emptyState"
                        />
                    );

                default:
                    return (
                        <BlockHeader
                            title={i18n.t(`howTo.lockedRewards.${item}.title`)}
                            text={i18n.t(`howTo.lockedRewards.${item}.text`)}
                            type="emptyState"
                        />
                    );
            }
        };

        return (
            <View style={styles.itemContainer}>
                <View style={styles.content}>
                    <View style={styles.imgWrapper}>{icon()}</View>
                    {component()}
                </View>
            </View>
        );
    };

    return (
        <View style={styles.main}>
            <View style={styles.header}>
                <NavigationBarModal
                    iconName={ICON_NAMES.CLOSE}
                    title={''}
                    onBack={() => navigation.pop()}
                />
            </View>
            <Carousel
                data={data}
                sliderWidth={windowWidth}
                itemWidth={windowWidth}
                renderItem={(item) => renderItem(item)}
                fullHeight
                pagination
                centredVertical
            />
            <ButtonKnowledgeBase url={Links.rewardFormula} />
            <SafeAreaView />
        </View>
    );
};

export default HowToLockedRewardsScreen;
