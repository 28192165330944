import * as React from 'react';
import { FlatList, StyleProp, ViewStyle } from 'react-native';

import { useQuery } from '@apollo/client';
import Participants from '@assets/icons/mysteryGames/LuckyStrike/participants.svg';
import Button from '@components/Button';
import EmptyList from '@components/EmptyList';
import CoinIcon from '@components/Icons/CoinIcon';
import LoadingComponent from '@components/LoadingComponent';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT } from '@components/TypoText/types';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { statisticsFormatter } from '@helpers/helpers';
import { priceString } from '@helpers/payments';
import { stylesAlignCenter } from '@helpers/style';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import {
    LuckyStrikeGameParticipantsOutputResponse,
    MYSTERY_GAMES,
} from '@models/mysteryGames';
import useNavigationListeners from '@navigation/hooks/useNavigationListeners';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { GET_LUCKY_STRIKE_GAME_PARTICIPANTS } from '@requests/luckyStrike';

import GamesStatsInfoCard from '../GamesStatsInfoCard';
import LuckyStrikeParticipantItem from '../LuckyStrikeParticipantItem';
import stylesMain from './styles';

interface IProps {
    gameId: string;
    containerStyle?: StyleProp<ViewStyle>;
    elementsToLoad?: number;
}

const LuckyStrikeAllParticipantsComponent = ({
    gameId,
    containerStyle,
    elementsToLoad,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    useNavigationListeners();

    const navigation = useNavigation();

    const { priceInUsd } = useWallet();

    const { data, loading } =
        useQuery<LuckyStrikeGameParticipantsOutputResponse>(
            GET_LUCKY_STRIKE_GAME_PARTICIPANTS,
            {
                fetchPolicy: 'network-only',
                variables: {
                    gameId,
                },
            }
        );

    const sortData = React.useMemo(() => {
        if (!data?.luckyStrikeGameParticipants.participants) return [];

        const arr = [...data?.luckyStrikeGameParticipants.participants].sort(
            (a, b) => Number(b.isWinner) - Number(a.isWinner)
        );

        return elementsToLoad ? [...arr].slice(0, elementsToLoad) : arr;
    }, [data?.luckyStrikeGameParticipants, elementsToLoad]);

    const amount = React.useMemo(
        () =>
            balanceFromWei(data?.luckyStrikeGameParticipants.iguAmountWei)
                .value,
        [data?.luckyStrikeGameParticipants.iguAmountWei]
    );

    const viewAll = React.useCallback(() => {
        navigation.push(ROUTES.LUCKY_STRIKE_ALL_PARTICIPANTS, { id: gameId });
    }, [gameId]);

    return (
        <View style={[styles.main, containerStyle]}>
            {loading ? (
                <LoadingComponent containerStyles={styles.loading} />
            ) : (
                <>
                    {!!elementsToLoad && (
                        <View style={styles.titleBlock}>
                            <View style={styles.title}>
                                <TypoText type={TYPO_TEXT.HEADING_H4}>
                                    {i18n.t(
                                        `HomeScreen.${MYSTERY_GAMES.luckyStrike}.keys.participants`
                                    )}
                                </TypoText>
                            </View>
                            <View>
                                <Button
                                    title={i18n.t('general.BUTTONS.viewAll')}
                                    type="text"
                                    onPress={viewAll}
                                    debouncedPress
                                />
                            </View>
                        </View>
                    )}
                    <View style={styles.listWrapper}>
                        {data?.luckyStrikeGameParticipants && (
                            <FlatList
                                ListHeaderComponent={
                                    <View style={styles.features}>
                                        <GamesStatsInfoCard
                                            containerStyle={styles.feature}
                                            onLight
                                            title={`≈ ${priceInUsd(
                                                Coin.igu,
                                                amount
                                            )} $`}
                                            amount={priceString(
                                                statisticsFormatter(
                                                    Number(amount)
                                                ),
                                                Coin.vigu
                                            )}
                                            icon={
                                                <CoinIcon
                                                    slug={Coin.vigu}
                                                    size={32}
                                                />
                                            }
                                        />
                                        <GamesStatsInfoCard
                                            onLight
                                            containerStyle={styles.feature}
                                            title={i18n.t(
                                                `HomeScreen.${MYSTERY_GAMES.luckyStrike}.keys.participants`
                                            )}
                                            amount={`${data.luckyStrikeGameParticipants.userCount}/${data.luckyStrikeGameParticipants.maxUsers}`}
                                            icon={
                                                <Participants
                                                    width={32}
                                                    height={32}
                                                />
                                            }
                                        />
                                    </View>
                                }
                                showsVerticalScrollIndicator={false}
                                data={sortData}
                                initialNumToRender={10}
                                renderItem={({ item }) => (
                                    <LuckyStrikeParticipantItem
                                        item={item}
                                        prize={
                                            data.luckyStrikeGameParticipants
                                                .iguAmountWei
                                        }
                                    />
                                )}
                                keyExtractor={(item) => item.id}
                                contentContainerStyle={
                                    !sortData?.length && {
                                        ...stylesAlignCenter(),
                                    }
                                }
                                ListEmptyComponent={
                                    <EmptyList
                                        emptyTitle={i18n.t(
                                            'general.errors.emptyErrorScreen.title'
                                        )}
                                        emptyText={i18n.t(
                                            'general.errors.emptyErrorScreen.text'
                                        )}
                                        withAnimation="cry"
                                        tailTitle
                                    />
                                }
                            />
                        )}
                    </View>
                </>
            )}
        </View>
    );
};

export default LuckyStrikeAllParticipantsComponent;
