import { StyleSheet } from 'react-native';

import Colors from '../../../constants/Colors';
import { verticalScale } from '../../../helpers/dimensions';
import font from '../../../helpers/font';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingVertical: verticalScale(2),
            paddingHorizontal: verticalScale(8),
            borderRadius: 1000,
            borderWidth: 1,
            borderColor: Colors[theme].background,
            justifyContent: 'center',
        },
        oneColor: {
            borderColor: Colors[theme].alwaysOneColor.background,
            backgroundColor: Colors[theme].alwaysOneColor.background,
        },
        isActive: {
            borderColor: Colors[theme].border.accent,
            backgroundColor: Colors[theme].backgroundSelectable,
        },
        isActiveText: {
            color: Colors[theme].text.accent,
        },
        oneColorActive: {
            backgroundColor: Colors[theme].alwaysOneColor.selectableBackground,
        },
        text: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
        },
    });

export default styles;
