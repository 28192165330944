import * as React from 'react';
import {
    Keyboard,
    KeyboardAvoidingView,
    SafeAreaView,
    ScrollView,
    TextInput,
} from 'react-native';

import { Wallet } from 'ethers';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useMutation } from '@apollo/client';
import ButtonsBottom from '@components/ButtonsBottom';
import { UserContext } from '@contexts/UserContext';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { isIOS } from '@helpers/app';
import { isGraphqlError, waitWallet } from '@helpers/helpers';
import { toastError } from '@helpers/toastNotification';
import { useHaptic } from '@hooks/useHaptic';

import { User, VerifyWalletResponse } from '../../../Data/Models';
import { VERIFY_WALLET } from '../../../Data/Requests';
import Button from '../../../components/Button';
import { ICON_NAMES } from '../../../components/Icons';
import Input from '../../../components/Input';
import ModalBottom from '../../../components/ModalBottom';
import ModalLoader from '../../../components/ModalLoader';
import { Text, View } from '../../../components/Themed';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import NavigationBar from '../../../navigation/NavigationBar';
import ROUTES from '../../../navigation/routes';
import { useNavigation } from '../../../navigation/useNavigation';
import stylesMain from './styles';

const INIT_PHRASE = Array<string>(12).fill('');

const ImportWalletScreen: React.FunctionComponent = () => {
    const navigation = useNavigation();
    const styles = useThemedStyles(stylesMain);
    const { user, setUser } = React.useContext(UserContext);
    const { importWallet, fromMnemonic, fromPrivateKey } = useWallet();
    const [verifyWallet] = useMutation<VerifyWalletResponse>(VERIFY_WALLET);

    const initPhrase = React.useRef(INIT_PHRASE);
    const [error, setError] = React.useState<string | null>(null);
    const [isModalPrivateKeyVisible, setIsModalPrivateKeyVisible] =
        React.useState<boolean>(false);

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [privateKey, setPrivateKey] = React.useState<string>('');
    const [privateKeyError, setPrivateKeyError] = React.useState<string | null>(
        null
    );
    const { triggerLight, triggerHeavy } = useHaptic();

    const handleOpenPrivateKeyModal = () => {
        triggerLight();
        setIsModalPrivateKeyVisible(true);
    };

    const handleClosePrivateKeyModal = () => {
        setPrivateKey('');
        setPrivateKeyError(null);
        setIsModalPrivateKeyVisible(false);
    };

    const verifyWalletSignature = async (wallet: Wallet, user: User) => {
        if (
            user.walletAddress &&
            user.walletAddress.toLocaleLowerCase() !==
                wallet.address.toLocaleLowerCase()
        ) {
            Console.log('[IMPORT] verifyWalletSignature walletNotEqual');
            throw 'WALLET_NOT_EQUAL';
        }

        if (user.walletAddress) {
            importWallet(wallet);
            Console.log('[IMPORT WALLET] Importing wallet for existing user');
            return;
        }

        const signature = await wallet.signMessage(user.id);

        const result = await verifyWallet({
            variables: { signature },
        });

        if (result.data) {
            importWallet(wallet);
            setUser(result.data.verifyWallet);
            Console.log(
                '[IMPORT WALLET] Importing wallet from external source'
            );
        }
    };

    const handleImportFromMnemonic = async (phrase: string) => {
        setError(null);
        if (!user) {
            return;
        }

        Keyboard.dismiss();
        setIsLoading(true);

        await waitWallet();

        try {
            Console.log('[IMPORT] Phrase', phrase);
            await verifyWalletSignature(fromMnemonic(phrase), user);
            navigation.navigate(ROUTES.CREATE_WALLET_SUCCESS, {
                type: 'import',
            });
        } catch (error) {
            Console.log(error);
            triggerHeavy();

            if (isGraphqlError(error, 'WALLET_ALREADY_VERIFIED')) {
                toastError(
                    undefined,
                    i18n.t('importWalletScreen.errors.walletAlreadyVerified')
                );
            } else if (error === 'WALLET_NOT_EQUAL') {
                setError(i18n.t('importWalletScreen.errors.walletNotEqual'));
                toastError(
                    undefined,
                    i18n.t('importWalletScreen.errors.walletNotEqual')
                );
            } else {
                setError(i18n.t('importWalletScreen.errors.mnemonicError'));
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleImportFromPrivateKey = async () => {
        setPrivateKeyError(null);
        if (!user) {
            return;
        }

        Keyboard.dismiss();
        setIsLoading(true);
        await waitWallet();
        try {
            Console.log('[IMPORT WALLET] PrivateKey', privateKey);
            await verifyWalletSignature(fromPrivateKey(privateKey), user);
            handleClosePrivateKeyModal();

            navigation.navigate(ROUTES.CREATE_WALLET_SUCCESS, {
                type: 'import',
            });
        } catch (error) {
            Console.log(error);
            triggerHeavy();

            if (isGraphqlError(error, 'WALLET_ALREADY_VERIFIED')) {
                toastError(
                    undefined,
                    i18n.t('importWalletScreen.errors.walletAlreadyVerified')
                );
            } else if (error === 'WALLET_NOT_EQUAL') {
                setPrivateKeyError(
                    i18n.t('importWalletScreen.errors.walletNotEqual')
                );
                toastError(
                    undefined,
                    i18n.t('importWalletScreen.errors.walletNotEqual'),
                    'top'
                );
            } else {
                setPrivateKeyError(
                    i18n.t('importWalletScreen.errors.privateKeyError')
                );
            }
        } finally {
            setIsLoading(false);
        }
    };

    const formikData = useFormik({
        initialValues: {
            phrase: INIT_PHRASE,
        },
        validationSchema: Yup.object({
            phrase: Yup.array().of(Yup.string().min(2).required()),
        }),

        onSubmit: (values) => handleImportFromMnemonic(values.phrase.join(' ')),
        enableReinitialize: true,
    });

    const { submitForm, values, isValid, dirty, setFieldValue, resetForm } =
        formikData;
    const inputsRef = React.useRef(
        INIT_PHRASE.map(() => React.createRef<TextInput>())
    );

    // handle Ctrl+v
    React.useEffect(() => {
        const updateList = async () => {
            const { phrase } = values;
            const index = phrase.findIndex((item, i) => {
                return item !== initPhrase.current[i];
            });

            if (index === -1) {
                return;
            }

            const splitted = phrase[index]
                .trim()
                .split(/\s+/)
                .filter((el) => el !== '');

            if (splitted.length > 1) {
                for (let i = 0; i < 12; i++) {
                    if (splitted[i]) {
                        await setFieldValue(`phrase[${i}]`, splitted[i]);
                    } else await setFieldValue(`phrase[${i}]`, '');
                }
            }
            initPhrase.current = values.phrase;
        };
        updateList();
        setError(null);
    }, [values, setFieldValue]);

    const onChangeText = (e: string, index: number) => {
        const countSpace = e.length - e.replace(/ /g, '').length;
        if (e.includes(' ') && countSpace === 1)
            inputsRef?.current[index + 1]?.current?.focus();
        else setFieldValue(`phrase[${index}]`, e);
    };

    const onChangePrivateKey = (value: string) => {
        setPrivateKey(value);
    };

    const renderPrivateKeyModal = () => {
        return (
            <ModalBottom
                isVisible={isModalPrivateKeyVisible}
                titleText={i18n.t('importWalletScreen.privateKeyModal.title')}
                additionalText={i18n.t(
                    'importWalletScreen.privateKeyModal.text'
                )}
                onClose={handleClosePrivateKeyModal}
                avoidKeyboard
                modalHeight={650}
                smartHandleExpand={true}
                containerStyle={styles.modal}>
                <View style={styles.privateKeyModal}>
                    <Input
                        label={i18n.t(
                            'importWalletScreen.privateKeyModal.label'
                        )}
                        style={styles.textareaInput}
                        value={privateKey || ''}
                        placeholder={i18n.t(
                            'importWalletScreen.privateKeyModal.placeholder'
                        )}
                        maxLength={128}
                        multiline
                        numberOfLines={10}
                        error={privateKeyError}
                        onChangeText={onChangePrivateKey}
                    />
                </View>
                <ButtonsBottom
                    title={i18n.t('importWalletScreen.privateKeyModal.button')}
                    onPress={handleImportFromPrivateKey}
                    safe
                    disabled={!privateKey || isLoading}
                    containerStyle={styles.buttons}
                />
                <ModalLoader
                    isVisible={isLoading && isModalPrivateKeyVisible}
                    text={i18n.t('importWalletInit.loading')}
                />
            </ModalBottom>
        );
    };

    return (
        <KeyboardAvoidingView
            behavior={isIOS ? 'padding' : 'height'}
            style={styles.container}>
            <NavigationBar filled={false} wallet={false} backIcon="leftArrow" />
            <ScrollView
                keyboardShouldPersistTaps="handled"
                contentContainerStyle={styles.wrapper}
                showsVerticalScrollIndicator={false}>
                <Text style={styles.title}>
                    {i18n.t('importWalletScreen.title')}
                </Text>
                <Text style={styles.text}>
                    {i18n.t('importWalletScreen.text')}
                </Text>
                <View style={styles.buttonWrapper}>
                    <Button
                        type="text"
                        title={i18n.t('importWalletScreen.privateKeyButton')}
                        onPress={handleOpenPrivateKeyModal}
                        size="md"
                        iconName={ICON_NAMES.KEY}
                    />
                </View>

                <View style={styles.listHeader}>
                    <Text style={styles.listTitle}>
                        {i18n.t('importWalletScreen.listTitle')}
                    </Text>
                    <Button
                        type="text"
                        title={i18n.t('importWalletScreen.clearAll')}
                        onPress={() => resetForm()}
                        size="md"
                    />
                </View>

                <View style={styles.list}>
                    {values.phrase.map((item, index) => (
                        <Input
                            forwardedRef={inputsRef.current[index]}
                            key={index}
                            value={item.toLowerCase()}
                            onChangeText={(e) => onChangeText(e, index)}
                            placeholder={`${index + 1}. ${i18n.t(
                                'importWalletScreen.inputPlaceholder'
                            )}`}
                            returnKeyType="next"
                            blurOnSubmit={false}
                            onSubmitEditing={() =>
                                inputsRef?.current[index + 1]?.current?.focus()
                            }
                            style={styles.input}
                        />
                    ))}
                </View>
                {renderPrivateKeyModal()}
            </ScrollView>
            <View style={styles.buttonContainer}>
                {error && <Text style={styles.textError}>{error}</Text>}
                <Button
                    disabled={!(isValid && dirty) || !!error || isLoading}
                    title={i18n.t('importWalletScreen.importWallet')}
                    onPress={submitForm}
                    debouncedPress
                    containerStyle={styles.button}
                />
            </View>
            <ModalLoader
                isVisible={isLoading}
                text={i18n.t('importWalletInit.loading')}
            />
            <SafeAreaView style={styles.safe} />
        </KeyboardAvoidingView>
    );
};

export default ImportWalletScreen;
