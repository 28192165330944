import * as React from 'react';

import { RankMintBonus } from '../../../../Data/Models';
import BonusIcon from '../../../../assets/icons/rankMintBonus/rankBonus.svg';
import InfoIcon from '../../../../components/InfoIcon/InfoIcon';
import { Text, View } from '../../../../components/Themed';
import { Coin } from '../../../../contexts/Wallet/WalletHelpers';
import { balanceFromWei } from '../../../../helpers/wallet';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import ROUTES from '../../../../navigation/routes';
import { useNavigation } from '../../../../navigation/useNavigation';
import stylesMain from './styles';

interface IProps {
    mintBonus: RankMintBonus;
    slug: string;
}

const TransactionDetailsBonusMint = ({ mintBonus, slug }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const navigation = useNavigation();

    const totalBonus = React.useMemo(
        () =>
            `${balanceFromWei(mintBonus.igupAmountWei).valueShort} ${
                Coin.igup
            }`,

        [mintBonus]
    );

    const onInfoPress = () => {
        navigation.navigate(ROUTES.BONUS_MINT_RANKS_INFO, {
            screenInfo: {
                mintBonus,
                slug,
                withBtn: false,
            },
        });
    };

    return (
        <View style={styles.verificationItem}>
            <View style={styles.left}>
                <View>
                    <BonusIcon width={64} height={64} />
                </View>
                <View style={styles.verificationTextContainer}>
                    <Text style={styles.verificationTitle}>
                        {i18n.t('MintBonusScreen.mintSuccess.title')}
                    </Text>
                    <View style={styles.withInfo}>
                        <Text style={styles.verificationSlug}>
                            {i18n.t('MintBonusScreen.mintSuccess.text')}
                        </Text>
                        <InfoIcon onPress={onInfoPress} />
                    </View>
                </View>
            </View>
            <View style={styles.right}>
                <Text style={styles.price}>{totalBonus}</Text>
            </View>
        </View>
    );
};
export default TransactionDetailsBonusMint;
