import React, { useCallback, useState } from 'react';

import _ from 'lodash';

import {
    RewardsTransactionHistoryResponse,
    RewardsTransactionHistoryResponseItem,
    UserBalanceResponse,
} from '@Data/Models';
import { GET_TRANSACTION_HISTORY, GET_USER_BALANCE } from '@Data/Requests';
import { useApolloClient } from '@apollo/client';
import { toastErrorUnknown } from '@helpers/toastNotification';

export declare type RewardsFilterType =
    | 'ALL'
    | 'GameIterationReward'
    | 'ReferralReward'
    | 'Claim'
    | 'MintBonus'
    | 'GameRewardStakeBonus';

export const rewardsConfig: {
    defaultFilter: string;
    filters: RewardsFilterType[];
} = {
    defaultFilter: 'ALL',
    filters: [
        'ALL',
        'GameIterationReward',
        'ReferralReward',
        'Claim',
        'MintBonus',
        'GameRewardStakeBonus',
    ],
};

const useWalletRewards = (startFilter?: string) => {
    const client = useApolloClient();

    const [filter, setFilter] = React.useState(
        startFilter || rewardsConfig.defaultFilter
    );

    const [userBalance, setUserBalance] = useState<UserBalanceResponse>();
    const [userBalanceLoading, setUserBalanceLoading] = useState(false);
    const [pullToRefreshLoading, setPullToRefreshLoading] = useState(false);

    const [transactionHistory, setTransactionHistory] =
        React.useState<RewardsTransactionHistoryResponseItem[]>();

    const [transactionHistoryLoading, setTransactionHistoryLoading] =
        useState(false);
    const [filterLoading, setFilterLoading] = useState(false);
    const [loadMore, setLoadMore] = React.useState(false);

    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);

    const setTypes = (filter?: string) => {
        return filter && filter !== 'ALL' ? [filter] : null;
    };

    const getUserBalance = async (silent: boolean) => {
        if (silent) {
            setUserBalanceLoading(true);
        } else {
            setPullToRefreshLoading(true);
        }

        const result = await client.query<UserBalanceResponse>({
            query: GET_USER_BALANCE,
            fetchPolicy: 'network-only',
        });
        if (result.error) {
            Console.error(result.error);
        }
        if (result.data) {
            setUserBalance(result.data);
        }
        setPullToRefreshLoading(false);
        setUserBalanceLoading(false);
    };

    const getTransactionHistory = useCallback(
        async (silent: boolean) => {
            silent
                ? setTransactionHistoryLoading(true)
                : setPullToRefreshLoading(true);

            const result =
                await client.query<RewardsTransactionHistoryResponse>({
                    query: GET_TRANSACTION_HISTORY,
                    fetchPolicy: 'network-only',
                    variables: {
                        paginationInput: {
                            types: setTypes(filter),
                        },
                    },
                });
            if (result.error) {
                Console.error(result.error);
                toastErrorUnknown();
            }
            if (result.data) {
                setTransactionHistory(result.data.transactionHistory.items);
                setCurrentPage(result.data.transactionHistory.meta.currentPage);
                setTotalPages(result.data.transactionHistory.meta.totalPages);
            }
            setPullToRefreshLoading(false);
            setTransactionHistoryLoading(false);

            return result;
        },
        [client, filter]
    );

    const getTransactionHistoryFilter = useCallback(
        async (item: RewardsFilterType, force = false) => {
            if (item === filter && !force) {
                return;
            }
            setFilter(item);
            setFilterLoading(true);

            const result =
                await client.query<RewardsTransactionHistoryResponse>({
                    query: GET_TRANSACTION_HISTORY,
                    fetchPolicy: 'network-only',
                    variables: {
                        paginationInput: {
                            types: setTypes(item),
                        },
                    },
                });
            if (result.error) {
                Console.log(result.error);
                toastErrorUnknown();
            }
            if (result.data) {
                setTransactionHistory(result.data.transactionHistory.items);
                setCurrentPage(result.data.transactionHistory.meta.currentPage);
                setTotalPages(result.data.transactionHistory.meta.totalPages);
            }
            setFilterLoading(false);

            return result;
        },
        [client, filter]
    );

    const getTransactionHistoryLoadMore = useCallback(async () => {
        if (currentPage === totalPages) {
            return;
        }
        setLoadMore(true);

        const result = await client.query<RewardsTransactionHistoryResponse>({
            query: GET_TRANSACTION_HISTORY,
            fetchPolicy: 'network-only',
            variables: {
                paginationInput: {
                    types: setTypes(filter),
                    page: currentPage + 1,
                },
            },
        });
        if (result.error) {
            Console.log(result.error);
            toastErrorUnknown();
        }
        if (result.data) {
            setTransactionHistory((prev) => {
                return prev
                    ? _.uniqBy(
                          [...prev, ...result.data.transactionHistory.items],
                          (o) => o.id
                      )
                    : result.data.transactionHistory.items;
            });

            setCurrentPage(result.data.transactionHistory.meta.currentPage);
            setTotalPages(result.data.transactionHistory.meta.totalPages);
        }
        setLoadMore(false);
    }, [client, filter, totalPages, currentPage]);

    return {
        getUserBalance,
        userBalance,
        userBalanceLoading,
        filterLoading,
        loadMore,
        setFilter,
        filter,
        getTransactionHistory,
        getTransactionHistoryFilter,
        getTransactionHistoryLoadMore,
        transactionHistory,
        transactionHistoryLoading,
        pullToRefreshLoading,
    };
};

export default useWalletRewards;
