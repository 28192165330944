import React, { useContext, useEffect, useState } from 'react';
import { SafeAreaView } from 'react-native-safe-area-context';

import { playToEarn, playToEarnAgain } from '@components/ImageSlider/data';
import { View } from '@components/Themed';
import { useDimensions } from '@contexts/DimensionsContext';
import { PlayContext } from '@contexts/PlayContext';
import { isWeb } from '@helpers/app';
import { wait } from '@helpers/helpers';
import { useDefaultScreenOrientation } from '@hooks/useDefaultScreenOrientation';
import { useNavigation } from '@navigation/useNavigation';
import { useFocusEffect } from '@react-navigation/native';

import Button from '../../../../../../components/Button';
import CustomModal from '../../../../../../components/CustomModal';
import ImageIguana from '../../../../../../components/ImageIguana';
import ImageSlider from '../../../../../../components/ImageSlider';
import useThemedStyles from '../../../../../../hooks/useThemedStyles';
import i18n from '../../../../../../i18n/i18n';
import NavigationBar from '../../../../../../navigation/NavigationBar';
import ROUTES from '../../../../../../navigation/routes';
import { PlayToEarnWelcomeProps } from '../../../../../../types';
import ScreenOrientationManager from '../../../../../../utils/ScreenOrientationManager';
import mainStyles from './styles';

export const PlayToEarnWelcomeScreen = ({ route }: PlayToEarnWelcomeProps) => {
    const navigation = useNavigation();
    const styles = useThemedStyles(mainStyles);
    const { playTask } = useContext(PlayContext);
    const [visible, setVisible] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { setWebContainerIsVisible, setIsRotated } = useDimensions();

    const [modalVisibility, setModalVisibility] = useState(false);

    useFocusEffect(
        React.useCallback(() => {
            setVisible(true);
        }, [])
    );
    useDefaultScreenOrientation();

    useEffect(() => {
        if (isWeb && !visible) {
            setIsRotated(true);
            setWebContainerIsVisible(false);
        }
    }, [setIsRotated, setWebContainerIsVisible, visible]);

    const onBack = () => {
        // for DEV purposes
        if (route.params?.navigateBack) {
            navigation.navigate(route.params?.navigateBack);
        } else {
            navigation.navigateToTasks();
        }
    };

    const navigateToGame = async (status: boolean) => {
        setVisible(false);
        setIsLoading(false);

        if (!isWeb) {
            ScreenOrientationManager.setLandscape().then(async () => {
                await wait(1000);
                navigation.navigate(ROUTES.PLAY_TO_EARN_PROGRESS, {
                    status,
                });
            });
        } else {
            navigation.navigate(ROUTES.PLAY_TO_EARN_PROGRESS, {
                status,
            });
        }
    };

    const handleButtonPress = async () => {
        setModalVisibility(false);
        await wait(1000);
        navigateToGame(false);
    };

    const renderModal = () => {
        let name = 'prepareToPlay';
        if (route.params?.status) {
            name = 'playAgain';
        }

        return (
            <CustomModal
                isVisible={modalVisibility}
                icon={<ImageIguana type="attention" />}
                titleText={i18n.t(`playToEarn.modals.${name}.title`)}
                infoText={i18n.t(`playToEarn.modals.${name}.text`)}
                firstButtonText={i18n.t(`playToEarn.modals.${name}.accept`)}
                secondButtonText={i18n.t(`playToEarn.modals.${name}.cancel`)}
                onFirstButtonClick={handleButtonPress}
                onSecondButtonClick={() => setModalVisibility(false)}
            />
        );
    };

    if (!visible)
        return <View style={[styles.emptyBackgroundContainer, styles.navBg]} />;

    return (
        <SafeAreaView style={styles.safe} edges={['bottom']}>
            <NavigationBar
                onBack={onBack}
                backIcon="close"
                containerStyle={styles.navBg}
            />
            <ImageSlider
                data={!route.params?.status ? playToEarn : playToEarnAgain}
                customEnergy={
                    !route.params?.status ? playTask?.maxEnergyReward : ''
                }
            />
            <View style={styles.buttonWrapper}>
                <Button
                    onPress={() => {
                        setModalVisibility(true);
                    }}
                    title={i18n.t('playToEarn.startPlaying')}
                    loading={isLoading}
                    loadingText={i18n.t('playToEarn.modals.loading.text')}
                />
            </View>
            {renderModal()}
        </SafeAreaView>
    );
};
