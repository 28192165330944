/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { FlatList } from 'react-native';

import { BigNumber } from 'ethers';

import { useMutation } from '@apollo/client';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { errorsHandler } from '@helpers/errors';
import { wait } from '@helpers/helpers';
import { toastErrorUnknown } from '@helpers/toastNotification';
import { useLongPolls } from '@hooks/useLongPolls';
import { useFocusEffect } from '@react-navigation/native';

import {
    MysteryBoxOpenBoxesResponse,
    OpenMysteryBoxResponse,
} from '../../../../Data/Models';
import {
    GET_MYSTERY_BOX_OPEN_BOXES,
    OPEN_MYSTERY_BOX,
} from '../../../../Data/Requests';
import MysteryBox from '../../../../assets/icons/mysteryBoxes/box.svg';
import BlockHeader from '../../../../components/BlockHeader/BlockHeader';
import Button from '../../../../components/Button';
import CustomModal from '../../../../components/CustomModal';
import EmptyList from '../../../../components/EmptyList';
import { ICON_NAMES } from '../../../../components/Icons';
import ImageIguana from '../../../../components/ImageIguana';
import LoadingComponent from '../../../../components/LoadingComponent';
import ModalLoader from '../../../../components/ModalLoader';
import PullToRefresh from '../../../../components/PullToRefresh';
import SafeAreaCurvedTabContent from '../../../../components/SafeAreaCurvedTabContent';
import { View } from '../../../../components/Themed';
import useMysteryBoxList from '../../../../hooks/mysteryBoxes/useMysteryBoxList';
import useMysteryBoxOpenList from '../../../../hooks/mysteryBoxes/useMysteryBoxOpenList';
import useMysteryBoxStatSale from '../../../../hooks/mysteryBoxes/useMysteryBoxStatSale';
import useBlockchainErrorsHandler from '../../../../hooks/useBlockchainErrorsHandler';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import NavigationBar from '../../../../navigation/NavigationBar';
import NavigationCollections from '../../../../navigation/NavigationBar/NavigationCollections';
import ROUTES from '../../../../navigation/routes';
import { useNavigation } from '../../../../navigation/useNavigation';
import AnimatedBannerMysteryBox from '../components/AnimatedBannerMysteryBox';
import { MemoizedMysteryBoxCardItem } from '../components/MysteryBoxCard';
import MysteryBoxComingSoon from '../components/MysteryBoxComingSoon';
import MysteryBoxOpenModal from '../components/MysteryBoxOpenModal';
import MysteryBoxSaleClosed from '../components/MysteryBoxSaleClosed';
import stylesMain from './styles';

const conditionFunc =
    (tokenId: string) => (results: MysteryBoxOpenBoxesResponse) =>
        results.lootboxRewards.find((o) => o.tokenId === tokenId);

export const MysteryBoxesMainScreen = () => {
    const { startPolling, condition, onSuccess, onError } =
        useLongPolls<MysteryBoxOpenBoxesResponse>(GET_MYSTERY_BOX_OPEN_BOXES);
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const [viewHeight, setViewHeight] = React.useState<number>(0);

    const { handleBlockchainErrors, renderBlockchainErrorsModal } =
        useBlockchainErrorsHandler();

    const {
        getUserMysteryBoxesList,
        list,
        loadingList,
        errorList,
        pullToRefresh,
    } = useMysteryBoxList();

    const {
        getAllOpenedMysteryBoxes,
        loadingOpenList,
        errorOpenList,
        openList,
    } = useMysteryBoxOpenList();

    const { statError, statLoading, statRefetch, statSaleData } =
        useMysteryBoxStatSale();

    const isInSale = React.useMemo(
        () =>
            !!statSaleData?.lootboxSaleStats?.inProgress &&
            !!statSaleData?.lootboxSaleStats?.startsAt,
        [statSaleData]
    );

    const { openMysteryBox } = useWallet();
    const [openMysteryBoxMutation] =
        useMutation<OpenMysteryBoxResponse>(OPEN_MYSTERY_BOX);
    const [openLoading, setOpenLoading] = React.useState(false);

    const [currentOpenBoxId, setCurrentOpenBoxId] = React.useState('');
    const [openBoxModal, setOpenBoxModal] = React.useState(false);

    const [mysteryBoxAnimationModal, setMysteryBoxAnimationModal] =
        React.useState(false);

    const [currentMysteryBoxRankId, setCurrentMysteryBoxRankId] =
        React.useState('common');

    const onClosedBoxPress = React.useCallback(
        (id: string) => {
            if (id === currentOpenBoxId) {
                setOpenBoxModal(true);
                return;
            }
            setCurrentOpenBoxId(id);
            setOpenBoxModal(true);
        },
        [currentOpenBoxId]
    );
    const handleCloseOpenBoxModal = () => {
        setOpenBoxModal(false);
    };

    const handleInModalOpenBoxPress = () => {
        handleCloseOpenBoxModal();
        openMysteryBoxCall();
    };

    const handleOpenAnimationOpenMysteryBox = async () => {
        setMysteryBoxAnimationModal(true);
    };
    const handleCloseAnimationOpenMysteryBox = () => {
        setMysteryBoxAnimationModal(false);
    };

    const handleOnAnimationOpenEnd = async () => {
        await wait();
        getUserMysteryBoxesList(false);
        getAllOpenedMysteryBoxes(false);
    };

    const navigateToRanksDetails = React.useCallback(
        (slug?: string) => {
            navigation.navigate(ROUTES.RANKS_DETAILED_INFO, {
                screenConfig: {
                    slug: slug || currentMysteryBoxRankId,
                    paginated: false,
                    fromLootBox: true,
                    single: true,
                },
            });
        },
        [currentMysteryBoxRankId]
    );

    const openMysteryBoxCall = React.useCallback(async () => {
        setCurrentMysteryBoxRankId('');
        setOpenLoading(true);

        const tokenId = BigNumber.from(currentOpenBoxId).toString();

        condition.current = (results) =>
            Boolean(conditionFunc(tokenId)(results));

        onSuccess.current = (results) => {
            const token = conditionFunc(tokenId)(results);
            if (token) setCurrentMysteryBoxRankId(token.rankId);
            setOpenLoading(false);
            handleOpenAnimationOpenMysteryBox();
        };

        onError.current = (error: any) => {
            if (!handleBlockchainErrors(error)) {
                toastErrorUnknown();
            }
            setOpenLoading(false);
        };

        try {
            const resultOpen = await openMysteryBoxMutation({
                variables: {
                    openLootboxInput: {
                        tokenId,
                    },
                },
            });
            if (resultOpen.data) {
                await openMysteryBox(
                    resultOpen.data.openLootbox.signedTransaction
                );
            }

            startPolling(2000);
        } catch (error) {
            onError.current(error);
        }
    }, [
        condition,
        currentOpenBoxId,
        handleBlockchainErrors,
        onError,
        onSuccess,
        openMysteryBox,
        openMysteryBoxMutation,
        startPolling,
    ]);

    useFocusEffect(
        React.useCallback(() => {
            getUserMysteryBoxesList(false);
            getAllOpenedMysteryBoxes(false);
        }, [])
    );

    const onRefresh = React.useCallback(() => {
        statRefetch();
        getUserMysteryBoxesList(true);
        getAllOpenedMysteryBoxes(true);
    }, []);

    const isEmptyList = React.useMemo(
        () =>
            !errorList && !list?.length && !errorOpenList && !openList?.length,
        [errorList, list, errorOpenList, openList]
    );

    const isLoading = React.useMemo(
        () => loadingList || loadingOpenList || statLoading,
        [loadingList, loadingOpenList, statLoading]
    );

    const isComingSoon = React.useMemo(
        () => !isInSale && !!statSaleData?.lootboxSaleStats?.startsAt,
        [isInSale, statSaleData]
    );

    const isNotAvailable = React.useMemo(
        () => !isInSale && !statSaleData?.lootboxSaleStats?.startsAt,
        [isInSale, statSaleData]
    );

    const [showBanner, setShowBanner] = React.useState(false);

    const hideBanner = () => {
        setShowBanner(false);
    };

    const [onPurchaseLoading, setOnPurchaseLoading] =
        React.useState<boolean>(false);

    const handleNavigatePurchase = async () => {
        setOnPurchaseLoading(true);
        try {
            const result = await statRefetch();
            if (result.data.lootboxSaleStats?.inProgress) {
                navigation.navigate(ROUTES.MYSTERY_BOX_PURCHASE_WELCOME);
            } else {
                setShowBanner(true);
            }
            setOnPurchaseLoading(false);
        } catch (error) {
            setOnPurchaseLoading(false);
            errorsHandler(error, true);
        }
    };

    const boxList = React.useMemo(() => {
        if (openList && list) {
            const filteredOpenList = openList
                .filter((v) => !v.consumedAt)
                .reverse();

            return [...filteredOpenList, ...list];
        }

        return null;
    }, [list, openList]);

    const handleOnEndOfTime = () => {
        statRefetch();
    };

    const isError = React.useMemo(
        () => errorList || errorOpenList || statError,
        [errorList, errorOpenList, statError]
    );

    const isComingSoonScreen = React.useMemo(
        () => !isInSale && isEmptyList,
        [isInSale, isEmptyList]
    );

    const isBoxList = React.useMemo(
        () => !!boxList && !!boxList.length,
        [boxList]
    );

    const isCentred = React.useMemo(
        () => (!isBoxList || isError) && !(!boxList && isLoading),
        [isBoxList, isError, isLoading]
    );

    const renderItemContent = () => {
        if (!viewHeight) {
            return null;
        }
        if (!boxList && isLoading) {
            return <LoadingComponent />;
        }
        if (isError) {
            return (
                <EmptyList
                    emptyTitle={i18n.t('mysteryBoxes.error.title')}
                    emptyText={i18n.t('mysteryBoxes.error.text')}
                    withAnimation="cry"
                />
            );
        }
        if (isComingSoonScreen && !isBoxList) {
            if (!statSaleData) {
                return null;
            }
            return (
                <View style={styles.comingSoonContainer}>
                    {statSaleData?.lootboxSaleStats?.startsAt ? (
                        <MysteryBoxComingSoon
                            endOfTime={statSaleData?.lootboxSaleStats?.startsAt}
                            onEnd={handleOnEndOfTime}
                        />
                    ) : (
                        <MysteryBoxSaleClosed />
                    )}
                </View>
            );
        }
        if (!isBoxList) {
            if (!isEmptyList) {
                return null;
            }
            return (
                <EmptyList
                    emptyTitle={i18n.t('mysteryBoxes.emptyTitle')}
                    emptyText={i18n.t('mysteryBoxes.emptyText')}
                    withIcon={
                        <View style={styles.mysteryBoxesIcon}>
                            <MysteryBox />
                        </View>
                    }
                    ButtonComponent={
                        <Button
                            onPress={handleNavigatePurchase}
                            title={i18n.t('mysteryBoxes.emptyButton')}
                            iconName={ICON_NAMES.SHOP_BAG}
                            iconColor="onDark"
                            iconSize={20}
                            disabled={onPurchaseLoading}
                            loading={onPurchaseLoading}
                        />
                    }
                />
            );
        }
        return (
            <FlatList
                refreshing={pullToRefresh}
                showsVerticalScrollIndicator={false}
                initialNumToRender={10}
                ListHeaderComponentStyle={styles.headerText}
                ListHeaderComponent={
                    <BlockHeader
                        title={i18n.t('mysteryBoxes.header.title')}
                        text={i18n.t('mysteryBoxes.header.text')}
                        type="primary"
                        onAdditionalButtonPress={handleNavigatePurchase}
                        disabled={onPurchaseLoading}
                    />
                }
                style={styles.listStyle}
                data={boxList}
                ListFooterComponentStyle={styles.footerStyle}
                columnWrapperStyle={styles.columnWrapperStyle}
                numColumns={2}
                keyExtractor={(_, index) => `mysteryBox-${index}`}
                renderItem={({ item }) => (
                    <MemoizedMysteryBoxCardItem
                        box={item}
                        onPressClosedBox={onClosedBoxPress}
                        onPressOpenedBox={navigateToRanksDetails}
                    />
                )}
            />
        );
    };

    const renderItem = React.useCallback(() => {
        return (
            <View
                style={[
                    styles.contentContainer,
                    isCentred && {
                        height: viewHeight,
                    },
                ]}>
                <NavigationCollections currentNavigation="MYSTERY" />
                {renderItemContent()}
                <View style={styles.bottomGap} />
            </View>
        );
    }, [
        isBoxList,
        boxList,
        viewHeight,
        isLoading,
        isError,
        isComingSoonScreen,
        statSaleData,
        isEmptyList,
    ]);

    return (
        <SafeAreaCurvedTabContent>
            <View
                onLayout={(event) => {
                    setViewHeight(event.nativeEvent.layout.height);
                }}
                style={styles.container}>
                <PullToRefresh
                    refreshing={pullToRefresh}
                    textLoading={i18n.t('pullToRefresh.reloadMysteryBox.text')}
                    onRefresh={onRefresh}
                    renderItem={renderItem}
                />
            </View>
            {renderBlockchainErrorsModal()}
            <ModalLoader
                timeout={0}
                isVisible={openLoading}
                text={i18n.t('mysteryBoxes.prepareToOpen')}
            />
            {mysteryBoxAnimationModal && (
                <MysteryBoxOpenModal
                    onButtonPress={navigateToRanksDetails}
                    isVisible={mysteryBoxAnimationModal}
                    close={handleCloseAnimationOpenMysteryBox}
                    rankId={currentMysteryBoxRankId}
                    onAnimationEndUpdate={handleOnAnimationOpenEnd}
                />
            )}
            <CustomModal
                isVisible={openBoxModal}
                icon={<ImageIguana type="attention" />}
                titleText={i18n.t('mysteryBoxes.openBoxModal.title')}
                infoText={i18n.t('mysteryBoxes.openBoxModal.text')}
                firstButtonText={i18n.t('mysteryBoxes.openBoxModal.button1')}
                secondButtonText={i18n.t('mysteryBoxes.openBoxModal.button2')}
                onFirstButtonClick={handleInModalOpenBoxPress}
                onSecondButtonClick={handleCloseOpenBoxModal}
            />
            {!!statSaleData && (
                <AnimatedBannerMysteryBox
                    saleStat={statSaleData.lootboxSaleStats}
                    isVisible={showBanner}
                    onPress={hideBanner}
                    onEndOfTime={handleOnEndOfTime}
                />
            )}
        </SafeAreaCurvedTabContent>
    );
};

export default MysteryBoxesMainScreen;
