import { useCallback } from 'react';

import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { errorsHandler } from '@helpers/errors';
import { waitForNavigation } from '@helpers/helpers';
import { useMysteryLikesMyPendingGames } from '@hooks/useMysteryLikesMyPendingGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import usePreviousGame from './usePreviousGame';

const useCreateMysteryLikesGame = () => {
    const navigation = useNavigation();
    const { setCurrentMysteryLikesGameId, mysteryLikesGameStats } =
        useMysteryGames();
    const {
        getMysteryLikesMyPendingGamesLazy,
        mysteryLikesMyPendingGames,
        getMysteryLikesMyPendingGamesLazyLoading: createGameLoading,
    } = useMysteryLikesMyPendingGames();

    const handleCreateGame = useCallback(async () => {
        try {
            const prevGames = await getMysteryLikesMyPendingGamesLazy();
            if (prevGames.data?.mysteryLikesMyPendingGames.length) {
                setCurrentMysteryLikesGameId(
                    prevGames.data?.mysteryLikesMyPendingGames[0].id
                );
                await waitForNavigation();
                openPreviousGameModal();
                return;
            }
            navigation.navigate(ROUTES.MYSTERY_LIKES_CREATE_GAME_WELCOME);
        } catch (error) {
            errorsHandler(error, true);
        }
    }, [mysteryLikesMyPendingGames]);

    const handleFirstModalButton = () => {
        closePreviousGameModal();
        navigation.navigate(ROUTES.MYSTERY_LIKES_SELECT_RANGE);
    };
    const handleSecondModalButton = () => {
        closePreviousGameModal();
    };

    const {
        openPreviousGameModal,
        closePreviousGameModal,
        renderPreviousGameModal,
    } = usePreviousGame(
        handleFirstModalButton,
        handleSecondModalButton,
        mysteryLikesGameStats?.gameMaxLifetimeDays
    );

    return {
        handleCreateGame,
        renderPreviousGameModal,
        createGameLoading,
    };
};

export default useCreateMysteryLikesGame;
