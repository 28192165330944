import { StyleSheet } from 'react-native';

import Colors from '../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../helpers/dimensions';
import font from '../../../helpers/font';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        shadow: {
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].shadow.default,
        },
        itemContainer: {
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            marginBottom: 4,
        },
        main: {
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: 'transparent',
        },
        left: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: 'transparent',
        },
        itemText: {
            marginLeft: verticalScale(8),
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
        },
        gap: {
            marginBottom: verticalScale(16),
        },
        active: {
            backgroundColor: Colors[theme].shadow.selected,
        },
        activeContainer: {
            borderColor: Colors[theme].border.accent,
        },
        link: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        linkText: {
            marginRight: verticalScale(4),
            color: Colors[theme].text.accent,
            ...font({
                size: 14,
                height: 22,
                weight: '700',
            }),
            letterSpacing: 0.08,
        },
    });

export default styles;
