import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import {
    LUCKY_STRIKE_COLOR,
    QUANTUM_SPIN_COLOR,
} from '@contexts/MysteryGamesContext';
import { scale, verticalScale } from '@helpers/dimensions';
import {
    stylesContainerWithBorderDefault,
    stylesModalNavigationFix,
} from '@helpers/style';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
            ...stylesModalNavigationFix(),
        },
        header: {
            paddingHorizontal: scale(20),
            backgroundColor: 'transparent',
        },
        container: {
            marginTop: verticalScale(16),
            paddingHorizontal: scale(20),
        },

        gap: {
            marginTop: verticalScale(16),
        },
    });

export default styles;
