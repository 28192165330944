import * as React from 'react';

import { NftRoyaleAvailableGameOutput } from '@models/mysteryGames';

import NftRoyalActiveOrderItem from '../NftRoyalActiveOrderItem';

interface IProps {
    item: NftRoyaleAvailableGameOutput;
    loading: boolean;
    action: (game: NftRoyaleAvailableGameOutput) => void;
}

const NftRoyalActiveOrdersListItemHandler = ({
    item,
    loading,
    action,
}: IProps) => {
    if (loading) {
        return <></>;
    }
    return <NftRoyalActiveOrderItem item={item} onPress={action} />;
};
export default NftRoyalActiveOrdersListItemHandler;
