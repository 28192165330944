// eslint-disable-next-line import/extensions
import basicFile from './index.js';

const pl = {
    ...basicFile,
    title: {
        suffix: 'IguVerse',
        Main: 'Główna',
        Play: 'Odtwórz',
        Charity: 'Charytatywnie',
        Collection: 'Kolekcja',
        Marketplace: 'Rynek',
        Home: 'Strona główna',
        //MysteryLikes
        HowMysteryLikesWork: 'The Mystery Of Likes - Jak to działa',
        WelcomeHowMysteryLikesWork: 'The Mystery Of Likes - Witamy',
        MysteryLikesMain: 'The Mystery Of Likes - Główna',
        MysteryLikesActiveOrder: 'The Mystery Of Likes - Aktywne zamówienia',
        MysteryLikesHistory: 'The Mystery Of Likes - Historia gier',
        MysteryLikesCreateGameWelcome:
            'The Mystery Of Likes - Stwórz powitanie',
        MysteryLikesCreateGameInput: 'The Mystery Of Likes - Utwórz',
        MysteryLikesCreateGameSuccess: 'The Mystery Of Likes - Sukces',
        MysteryLikesSelectRange: 'The Mystery Of Likes - Wybierz zakres',
        MysteryLikesSelectRangeFinish:
            'The Mystery Of Likes - Informacje o grze',

        //
        // NFT Evolution
        HowNftEvolutionWelcome: 'NFT Evolution - Jak to działa',
        NftEvolutionWelcome: 'NFT Evolution - Witamy',
        NftEvolutionSelectPets: 'NFT Evolution',
        NftEvolutionCheckout: 'NFT Evolution - Szanse',
        NftEvolutionSuccess: 'NFT Evolution - Sukces',
        NftEvolutionSelectPet: 'NFT Evolution',
        NftEvolutionMintPet: 'NFT Evolution - Mint',
        NftEvolutionMintPetSuccess: 'NFT Evolution - Sukces',
        //
        //NftRoyal
        HowNftRoyalWork: 'The Grand Race - Jak to działa',
        WelcomeNftRoyalWork: 'The Grand Race - Witamy',
        NftRoyalMain: 'The Grand Race - Main',
        NftRoyalActiveOrder: 'The Grand Race - Vacant Rooms',
        NftRoyalCreateGameWelcome: 'The Grand Race - Create',
        NftRoyalGameConfigurations: 'The Grand Race - Game Settings',
        NftRoyalEnterCoinsAmount: 'The Grand Race - Select Coins Amount',
        NftRoyalCreateGameSuccess: 'The Grand Race - Create Game Success',
        NftRoyalHistory: 'The Grand Race - History',
        NftRoyalGameHistory: 'The Grand Race - Game History',
        NftRoyalPlayGame: 'The Grand Race',
        //
        AdminRoot: 'Administrator',
        AdminVerify: 'Administrator - Weryfikacja',
        AdminSendPushNotification: 'Administrator - Wyślij powiadomienie Push',
        AdminReverification: 'Admin - Rewersja',
        AdminBlockUser: 'Admin - Zablokować użytkownika',
        AdminMaintenanceMode: 'Admin - Tryb konserwacji',
        AdminAssignWhiteList: 'Admin - Przypisz białą listę',
        AdminAssignRole: 'Admin - Przypisać rolę',
        MintRoot: 'Mint NFT',
        MintPetRanks: 'Mint NFT - Wybór Rangi',
        MintPetSelectGeneration: 'Mint NFT - Wybierz metodę',
        MintGenerationInfo: 'Mint NFT - Info',
        MintSelectGeneratedNft: 'Mint NFT - Wybierz NFT',
        MintPetPickPhoto: 'Mint NFT - Wybierz zdjęcie',
        MintPetEditPhoto: 'Mint NFT - Edycja',
        MintPetName: 'Mint NFT - Wybierz imię dla zwierzaka',
        MintPetCheckout: 'Mint NFT -Opłać Stworzenie Zwierzaka',
        MintPetCheckoutSuccess: 'Mint NFT -Sukces Stworzenia',
        AddWalletInitial: 'Dodaj portfel',
        ImportWallet: 'Importuj portfel',
        CreateWalletFirst: 'Utwórz portfel',
        CreateWalletPassphrase: 'Tworzenie nasion portfela',
        CreateWalletVerification: 'Zweryfikuj seed frazę',
        CreateWalletSuccess: 'Sukces stworzenia portfela',
        Login: 'Login',
        WalletRoot: 'Portfel',
        WalletCoinsMain: 'Portfel - Monety',
        WalletNFTSMain: 'Portfel - NFT',
        WalletRewardsMain: 'Portfel - Nagrody',
        //Gaming
        WalletGamingTab: 'Portfel - Gry',
        WalletGamingMain: 'Portfel - Gry',
        TopUpGamingWallet: 'Portfel - Uzupełnij',
        TopUpCheckoutScreen: 'Portfel - Uzupełnij Checkout',
        TopUpSuccessScreen: 'Portfel - Uzupełnij Sukces Checkout',
        WithdrawIguSuccessScreen: 'Portfel - Wypłać Sukces',
        GamingWalletTransactionDetails: 'Portfel - Szczegóły transakcji',
        WithdrawInputAmount: 'Portfel - Wypłata',
        //
        WalletCoinsBalance: 'Portfel - Saldo tokenów',
        WalletCoinsWithdraw: 'Portfel - Wypłata',
        WalletCoinsWithdrawSuccess: 'Portfel - Wypłata udana',
        WalletCoinsTransactionDetails: 'Portfel - Szczegóły transakcji',
        WalletNFTSList: 'Portfel - Lista NFT',
        WalletNFTSWithdraw: 'Portfel - Wypłata NFT',
        WalletNFTSWithdrawSuccess: 'Portfel - Wypłata NFT udana',
        WalletNFTSTransactionDetails: 'Portfel - Szczegóły transakcji NFT',
        WalletRewardsClaimSuccess: 'Portfel - Nagroda odebrana',
        WalletRewardsTransactionDetails:
            'Portfel - Szczegóły transakcji nagród',
        WalletRewardsTab: 'Portfel - Nagrody',
        WalletTradeMain: 'Portfel - Handel Główny',
        WalletTradeChart: 'Portfel - wykres handlowy',
        WalletTradeSuccess: 'Portfel - sukces handlowy',
        WalletSettings: 'Portfel - Ustawienia',
        WalletSettingsBackup: 'Portfel - Kopia zapasowa ustawień',
        StakingWelcome: 'Portfel - Boost Witamy',
        StakingOptions: 'Portfel - Opcje Boost',
        StakingPlans: 'Portfel - plany tyczenia',
        StakingDetailedInfo: 'Portfel - Boost Szczegółowe informacje',
        StakingCoinsInput: 'Portfel - Boost Monet Wejście',
        StakingDailyBoost: 'Portfel - Boost',
        StakingSuccess: 'Portfel - sukces w Boost',
        StakingAlready: 'Portfel - Boost już',
        UnStakingSuccess: 'Portfel - Niewątpliwy sukces',
        PlayTasks: 'Zadania',
        PlayTasksActive: 'Zadania Aktywne',
        PlayTasksHistory: 'Historia zadań',
        PlayEditNFTs: 'Edycja NFT',
        PlayTasksHistoryDetails: 'Szczegóły historii zadań',
        PlayCollectedExperience: 'Zebrane doświadczenia',
        PlaySuccessScreen: 'Zadanie Sukces',
        SocializeToEarnWelcomeShare: 'SocializeToEarn - Witamy',
        SocializeToEarnShareYourPhoto:
            'SocializeToEarn - Podziel się swoim zdjęciem',
        SocializeToEarnCreateSharePhoto: 'SocializeToEarn - Stwórz Zdjęcie',
        SocializeToEarnVerifyUser: 'SocializeToEarn - Weryfikacja użytkownika',
        SocializeToEarnCreateShareTemplate:
            'SocializeToEarn - Podziel się z szablonem',
        SocializeToEarnVerifyUserError:
            'SocializeToEarn - Weryfikacja nieudana',
        SocializeToEarnShareYourPhotoSuccess:
            'SocializeToEarn - Zadanie Spółeczne Pomyślnie Udane',
        SocializeToEarnWelcomeVerify: 'SocializeToEarn - Welcome Verify',
        SocializeToEarnVerifyUserMatch:
            'SocializeToEarn - Weryfikacja dopasowania użytkownika',
        SocializeToEarnVerifyUserNotFound:
            'SocializeToEarnVerify - Nie znaleziono użytkownika',
        SocializeToEarnVerifyUserFailed:
            'SocializeToEarn - Weryfikacja użytkownika nie powiodła się',
        SocializeToEarnVerifyUserAlreadyCompleted:
            'SocializeToEarn - Weryfikacja użytkownika już zakończona',
        MoveToEarnWelcome: 'MoveToEarn - Witamy',
        MoveToEarnProgress: 'MoveToEarn - Postęp',
        MoveToEarnSummary: 'MoveToEarn - Podsumowanie',
        PlayToEarn: 'PlayToEarn',
        PlayToEarnWelcome: 'PlayToEarn - Witamy',
        PlayToEarnProgress: 'PlayToEarn - Graj',
        CharityOrganizations: 'Działalność charytatywna - organizacje',
        CharityDetailedOrganization:
            'Działalność charytatywna - organizacja szczegółowa',
        CharityOrganizationDonations:
            'Organizacja charytatywna - darowizny dla organizacji',
        CharityEditSelectedOrganization:
            'Organizacja charytatywna - Edytuj wybraną organizację',
        CharityEditDetailedOrganization:
            'Organizacja charytatywna - Edytuj szczegółową organizację',
        CollectionMain: 'Kolekcja',
        CollectionDetailedPet: 'Kolekcja - Mój zwierzak',
        CollectionPetLevelUpCheckout:
            'Kolekcja - Podsumowanie Podniesienia Levelu',
        CollectionPetLevelUpSuccess: 'Zbiórka - Podniesienia Levelu Udane',
        CollectionPetRankUpCheckout:
            'Kolekcja - Podsumowanie Podniesienia Rangi',
        CollectionPetRankUpSuccess: 'Kolekcja - Podniesienie Rangi Udane',
        CollectionPetHealUpCheckout: 'Kolekcja - Podsumowanie Karmienia',
        CollectionPetHealUpSuccess: 'Kolekcja - Karmienie Pomyślnie Udane',
        CollectionPetReviveCheckout: 'Kolekcja - Podsumowanie Odnawiania',
        CollectionPetReviveSuccess:
            'Kolekcja - Odnowienia Zwierzaka Pomyślnie Udane',
        CollectionPetAgeReviveCheckout: 'Kolekcja - Podsumowanie Odnawiania',
        CollectionPetAgeReviveSuccess:
            'Kolekcja - Odnowienia Zwierzaka Pomyślnie Udane',
        CollectionMultiplePetsHealUpProceeding:
            'Kolekcja - Nakarm wszystkie zwierzęta',
        CollectionMultiplePetsHealUpCheckout:
            'Kolekcja - Podsumowanie Karmienia',
        CollectionMultiplePetsHealUpSuccess:
            'Kolekcja - Karmienie Pomyślnie Udane',
        ProfileRoot: 'Profil',
        ProfileMain: 'Profil - Główny',
        ProfileLanguagesSettings: 'Profil - Ustawienia języków',
        ProfileNotificationsSettings: 'Profil - Ustawienia powiadomień',
        ProfileSocialAccountsSettings:
            'Profil - Ustawienia kont społecznościowych',
        ProfileSocialAccessibilitySettings: 'Profil - Ustawienia dostępności',
        ProfileSocialMedia: 'Profil - Media Społeczne',

        Dev: 'Dev',
        RanksDetailedInfo: 'Rangi Szczegółowe informacje',
        EnergyInfoScreen: 'Ekran informacji o energii',
        Referrals: 'Odsyłacze',
        ReferralsAll: 'Polecenia - wszystkie',
        ReferralsInvites: 'Polecenia - Zaproszenia',
        ReferralsInvite: 'Polecenia - Zaproszenie',
        ReferralsHowItsWork: 'Polecenia - jak to działa',
        // LEADERBOARD
        Leaderboard: 'Tabela liderów',
        LeaderboardDailyTasks: 'Tabela liderów - Zadanie',
        LeaderboardPvpGames: 'Tabela liderów - PvP',
        //
        MysteryBoxMain: 'Mystery Boxes',
        MysteryBoxPurchaseWelcome: 'Tajemnicze Pudełka - Zapraszamy do zakupu',
        MysteryBoxPurchase: 'Mystery Boxes - Zakup',
        MysteryBoxPurchaseCheckout: 'Tajemnicze Pudełka - Kasa Zapomogowa',
        MysteryBoxPurchaseSuccess: 'Mystery Boxes - Sukces zakupu',
        HowToCharity: 'Jak działa dobroczynność',
        HowToPlay: 'Jak grać',
        HowToReferrals: 'Polecenia - jak to działa',
        HowToAvailableRewards: 'Jak działają Dostępne Nagrody',
        HowToLockedRewards: 'Jak działa Available Locked',
        HowToGamingAvailableWallet: 'Jak działa Dostępny portfel',
        HowToGamingPendingWallet: 'Jak działa Portfel na czekanie',
        HowToSocializeToEarn: 'Jak się dzielić',
        HowToVerifyUser: 'Jak znaleźć identyfikatory zwierząt domowych',
        HowToPurchaseBNB: 'Jak kupić BNB',
        HowToPurchaseSpadeId: 'Jak korzystać z SPACE ID',
        HowToCommissionFee: 'Opłata',
        HowToFee: 'Opłata',
        BonusMintRanksInfo: 'Mint - Rangi premiowe',
        BonusMintRanksCarousel: 'Mint - Bonus dla wszystkich rang',
        InitialMint: 'Pomyśl o swoim pierwszym zwierzaku',
        InitialReferral: 'Zostałeś zaproszony do IguVerse',
        YourPetsAreHungry: 'Twoje zwierzęta są głodne!',
        UpdateApp: 'Nowa aktualizacja',
        //ONBOARDING
        MainWalletOnBoardingScreen: 'Główny Portfel',
        LoginOnBoardingScreen: 'Witamy',

        //MARKETPLACE
        MarketPlaceActiveOrders: 'Rynek - Aktywne zamówienia',
        MarketPlaceMyProfile: 'Rynek - Mój profil',
        MarketPlaceProfile: 'Rynek - Profil',
        MarketPlaceWelcomeCreateOrder: 'Rynek - Witamy',
        MarketPlaceSelectNft: 'Rynek - Wybierz NFT',
        MarketPlaceSelectNftPrice: 'Rynek - Wybierz cenę',
        MarketPlaceOverviewGroup: 'Rynek - Przegląd',
        MarketPlaceOverview: 'Rynek - Przegląd',
        MarketPlaceOverviewOwnerHistory: 'Rynek - Historia Właścicieli',
        MarketPlaceBuyCheckout: 'Rynek - Wykup',
        MarketPlaceCreateOrderSuccess: 'Rynek - Sukces',
        MarketPlaceDelistSuccess: 'Rynek - Sukces',
        MarketPlaceBuySuccess: 'Rynek - Sukces',

        //Achievements
        Achievements: 'Osiągnięcie',
        AchievementsDetails: 'Osiągnięcie - Szczegóły',

        //PassCode
        PassCodeFaceId: 'Kod dostępu - Biometryczny',
        PassCodeNew: 'Kod dostępu - Włącz',
        PassCodeChange: 'Kod dostępu - Zmiana',
        PassCodeSuccess: 'Kod dostępu - Sukces',
        PassCodeSettings: 'Kod dostępu - Ustawienia',
        PassCodeConfirm: 'Kod dostępu - Potwierdź',

        //Lucky Strike
        WelcomeLuckyStrike: 'Lucky Strike - Witamy',
        LuckyStrike: 'Lucky Strike',
        LuckyStrikeMain: 'Lucky Strike - Rundy',
        LuckyStrikeHistory: 'Lucky Strike - Historia',
        HowLuckyStrikeWork: 'Lucky Strike - Jak to działa',
        LuckyStrikeAllParticipants: 'Lucky Strike - Uczestnicy',
        LuckyStrikeWaitingForResult: 'Lucky Strike - Wynik',
        LuckyStrikeUserTickets: 'Lucky Strike - Bilety',
        LuckyStrikeUserTicketsWait: 'Lucky Strike - Bilety',
        LuckyStrikeAllWinners: 'Lucky Strike - Zwycięzcy',
        LuckyStrikeGameHistory: 'Lucky Strike - Historia',

        //Quantum Disk
        WelcomeQuantumSpin: 'Quantum Disk - Witamy',
        HowQuantumSpinWork: 'Quantum Disk - Jak to działa',
        QuantumSpin: 'Quantum Disk',
        QuantumSpinMain: 'Quantum Disk - Koło',
        QuantumSpinHistory: 'Quantum Disk - Historia',
        QuantumSpinGameHistory: 'Quantum Disk - Historia',
        QuantumSpinProbablyFair:
            'Quantum Disk - Sprawdzalny algorytm randomizacji',
        QuantumSpinProbablyFairHistory: 'Quantum Disk - Historia',

        //TokenTeller
        WelcomeTokenTeller: 'Tokenteller - Witamy',
        TokenTeller: 'Tokenteller',
        TokenTellerMain: 'Tokenteller - Rundy',
        TokenTellerHistory: 'Tokenteller - Historia',
        HowTokenTellerWork: 'Tokenteller - Jak to działa',
        TokenTellerChart: 'Tokenteller - Wykres',
        TokenTellerAllParticipants: 'Tokenteller - Uczestnicy',
        TokenTellerWaitingForResult: 'Tokenteller - Czekaj',
        TokenTellerGameHistory: 'Tokenteller - Historia',
    },
};

export default pl;
