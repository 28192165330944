import * as React from 'react';

import Default from '@assets/icons/mysteryGames/bothContinueIcon.svg';
import MysteryLikes from '@assets/icons/mysteryGames/mysteryLikesContinueIcon.svg';
import NftRoyal from '@assets/icons/mysteryGames/nftRoyalContinueIcon.svg';
import { MYSTERY_GAMES } from '@models/mysteryGames';

import { IconType } from '../types';

interface IProps extends IconType {
    slug?: MYSTERY_GAMES;
}

const MysteryGamesContinueIcon = ({ slug, size = 48 }: IProps) => {
    switch (slug) {
        case MYSTERY_GAMES.mysteryLikes:
            return <MysteryLikes width={size} height={size} />;
        case MYSTERY_GAMES.nftRoyal:
            return <NftRoyal width={size} height={size} />;

        default:
            return <Default width={size} height={size} />;
    }
};

export default MysteryGamesContinueIcon;
