import * as React from 'react';
import { SvgProps } from 'react-native-svg';

import { Text } from '../../../../../components/Themed';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import { ComponentAnyType } from '../../../../../types';
import stylesMain from './styles';

interface IProps {
    title: string;
    text: string;
    icon: React.ReactElement<SvgProps> | ComponentAnyType;
}

const EmptyStatePlay = ({ title, text, icon }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <>
            {icon}
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.text}>{text}</Text>
        </>
    );
};
export default EmptyStatePlay;
