import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { isDesktop, isWeb } from '@helpers/app';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
        },

        content: {
            flex: 1,
        },
        margin: {
            marginTop: verticalScale(24),
        },
        header: {
            marginBottom: verticalScale(20),
            paddingHorizontal: scale(20),
        },
        columnWrapperStyle: {
            justifyContent: 'space-between',
            paddingHorizontal: scale(20),
        },
        search: {
            marginBottom: verticalScale(16),
        },
        divider: {
            marginVertical: verticalScale(24),
        },
        emptyList: {
            marginTop: verticalScale(24),
            paddingBottom: isWeb ? 120 : 60,
        },
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 18,
                height: 24,
                weight: '700',
            }),
            paddingHorizontal: scale(20),
            marginBottom: verticalScale(20),
        },

        footer: {
            height: isWeb ? 120 : 60,
            backgroundColor: 'transparent',
        },
    });

export default styles;
