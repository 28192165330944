import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '../../helpers/dimensions';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
        },
        buttonWrapper: {
            flexDirection: 'row',
            marginBottom: verticalScale(20),
        },
        button: {
            flex: 1,
            marginHorizontal: scale(5),
        },
        buttonBottomWrapper: {
            width: '100%',
            position: 'absolute',
            bottom: 0,
            paddingHorizontal: 0,
        },
    });

export default styles;
