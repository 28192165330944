import React from 'react';

import ICON from '../../../../../../assets/icons/profile/admin/reverification.svg';
import useThemedStyles from '../../../../../../hooks/useThemedStyles';
import i18n from '../../../../../../i18n/i18n';
import ROUTES from '../../../../../../navigation/routes';
import { useNavigation } from '../../../../../../navigation/useNavigation';
import MenuItem from '../../../MenuItem';
import { RightComponent, iconSize } from '../../helpers';
import stylesMain from './styles';

const AdminReverificationMenuItem = () => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    return (
        <MenuItem
            leftIcon={<ICON width={iconSize} height={iconSize} />}
            title={i18n.t('profile.menuItems.adminReverification.title')}
            description={i18n.t(
                'profile.menuItems.adminReverification.description'
            )}
            rightComponent={<RightComponent type="arrowRight" />}
            onPress={() => navigation.navigate(ROUTES.ADMIN_REVERIFICATION)}
            titleStyle={styles.titleStyle}
            textStyle={styles.textStyle}
        />
    );
};

export default AdminReverificationMenuItem;
