import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            marginTop: verticalScale(20),
        },
        text: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '600',
            }),
        },
        imageWrapper: {
            width: scale(40),
            height: scale(40),
            borderRadius: moderateScale(6),
        },
        address: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 23,
                weight: '600',
            }),
            marginBottom: verticalScale(2),
        },
        left: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        textWrapper: {
            flex: 1,
            marginLeft: scale(12),
        },
    });

export default styles;
