import React, { useCallback, useEffect, useState } from 'react';
import { Keyboard } from 'react-native';

import i18n from '@i18n/i18n';
import { useFocusEffect } from '@react-navigation/native';

import { PassCodeWrapper } from '../../screens/Wallet/Settings/components/PassCodeWrapper';

interface IProps {
    setIsAuth: (status: boolean) => void;
    closeCheck: () => void;
    nextAction?: () => void;
    checkPassCode: (value: string, tmp?: boolean) => Promise<boolean>;
}

export const PassCodeCheck = ({
    setIsAuth,
    closeCheck,
    nextAction,
    checkPassCode,
}: IProps) => {
    const [code, setCode] = useState<string>('');
    const [error, setError] = useState<string>('');

    useEffect(() => {
        if (code.length === 1) {
            setError('');
        }
        const check = async () => {
            const result = await checkPassCode(code);
            if (!result) {
                setCode('');
                setError(i18n.t('passcode.check.error'));

                return;
            }
            setIsAuth(true);
            closeCheck();
            nextAction?.();
        };
        if (code.length === 4) {
            check();
        }
    }, [code]);

    return (
        <PassCodeWrapper
            backIcon="close"
            code={code}
            setCode={setCode}
            title={i18n.t('passcode.check.title')}
            text={i18n.t('passcode.check.subTitle')}
            error={error}
            onClose={closeCheck}
            absolute
        />
    );
};
