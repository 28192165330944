import * as React from 'react';
import { useCallback } from 'react';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import EmptyList from '@components/EmptyList';
import { ICON_NAMES } from '@components/Icons';
import LoadingComponent from '@components/LoadingComponent';
import ModalLoader from '@components/ModalLoader';
import PullToRefresh from '@components/PullToRefresh';
import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';
import { NAVIGATION_LUCKY_STRIKE } from '@navigation/NavigationBar/NavigationLuckyStrike';
import NavigationLuckyStrike from '@navigation/NavigationBar/NavigationLuckyStrike';
import ROUTES from '@navigation/routes';

import { LuckyStrikeHistoryProps } from '../../../../../types';
import GamesNavigation from '../../components/GamesNavigation';
import LuckyStrikeHistoryItem from '../../components/LuckyStrikeHistoryItem';
import LuckyStrikeSlotsModal from '../../components/LuckyStrikeSlotsModal';
import useLuckyStrikeHistoryScreen from './hooks/useLuckyStrikeHistoryScreen';
import stylesMain from './styles';

const LuckyStrikeHistoryScreen = ({ route }: LuckyStrikeHistoryProps) => {
    const styles = useThemedStyles(stylesMain);
    const {
        handleRefresh,
        isRefresh,
        isSmallLayout,
        handOnHowItWorks,
        slotsModal,
        openSlotsModal,
        closeSlotsModal,
        onPurchaseTicket,
        renderPurchaseModal,
        luckyStrikeGames,
        currentGame,
        onEndTime,
        gamesHistory,
        getGamesHistoryLoadMore,
        canLoadMore,
        loadMore,
        historyLoading,
        onHistoryItemPress,
        onPressLoading,
        isReloading,
    } = useLuckyStrikeHistoryScreen(route.params?.game);

    const headerComponent = () => {
        return (
            <BlockHeader
                containerStyle={styles.header}
                type="primary"
                title={i18n.t('HomeScreen.GameHistoryScreen.title')}
                text={i18n.t('HomeScreen.GameHistoryScreen.text')}
                iconDirection="right"
            />
        );
    };
    const emptyComponent = useCallback(() => {
        return (
            <View
                style={
                    isSmallLayout
                        ? styles.emptyStateWrapperSmall
                        : styles.emptyStateWrapper
                }>
                <EmptyList
                    containerStyle={styles.emptyList}
                    emptyTitle={i18n.t('HomeScreen.EmptyGameHistory.title')}
                    emptyText={i18n.t('HomeScreen.EmptyGameHistory.text')}
                    emptyButtonText={i18n.t('general.BUTTONS.createNewGame')}
                    withAnimation="cry"
                />
            </View>
        );
    }, [isSmallLayout]);

    return (
        <View style={styles.main}>
            <ModalLoader isVisible={onPressLoading} />
            <GamesNavigation
                backIconName={ICON_NAMES.CLOSE}
                type={MYSTERY_GAMES.luckyStrike}
                onPress={openSlotsModal}
                loading={isReloading}
                handleOnInfoIconPress={handOnHowItWorks}
                buttonTitle={i18n.t(
                    `HomeScreen.${MYSTERY_GAMES.luckyStrike}.purchaseTicket`
                )}
            />
            <NavigationLuckyStrike
                currentNavigation={NAVIGATION_LUCKY_STRIKE.HISTORY}
            />
            <View style={styles.content}>
                {historyLoading ? (
                    <LoadingComponent text={i18n.t('general.loading')} />
                ) : (
                    <PullToRefresh
                        refreshing={isRefresh}
                        textLoading={i18n.t('pullToRefresh.loading')}
                        onRefresh={handleRefresh}
                        HeaderComponent={headerComponent()}
                        EmptyComponent={emptyComponent()}
                        renderData={gamesHistory || []}
                        //@ts-ignore ignore item type any
                        renderItem={({ item, index }) => (
                            <LuckyStrikeHistoryItem
                                key={index}
                                item={item}
                                onPress={onHistoryItemPress}
                                disabled={isReloading}
                            />
                        )}
                        shouldUseEmptyComponentInFooter
                        loadMore={loadMore}
                        onReachEnd={getGamesHistoryLoadMore}
                        canLoadMore={canLoadMore}
                    />
                )}
            </View>
            {luckyStrikeGames && (
                <LuckyStrikeSlotsModal
                    routeBack={ROUTES.LUCKY_STRIKE_HISTORY}
                    isVisible={slotsModal}
                    onClose={closeSlotsModal}
                    openModal={openSlotsModal}
                    onPurchaseTicket={onPurchaseTicket}
                    loading={isReloading}
                    games={luckyStrikeGames}
                    onEndTime={onEndTime}
                />
            )}
            {!!currentGame && renderPurchaseModal(currentGame)}
        </View>
    );
};

export default LuckyStrikeHistoryScreen;
