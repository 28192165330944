import { StyleSheet } from 'react-native';

import Colors from '../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../helpers/dimensions';
import font from '../../../helpers/font';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        shadow: {
            marginHorizontal: scale(20),
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].shadow.default,
        },
        itemContainer: {
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            marginBottom: 4,
        },
        main: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        left: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
        },
        icon: {
            width: 32,
            height: 32,
        },
        itemText: {
            marginLeft: scale(8),
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
        },
        termsWrapper: {
            marginTop: verticalScale(6),
        },
        term: {
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: verticalScale(6),
        },
        circle: {
            width: 6,
            height: 6,
            borderRadius: 1000,
            backgroundColor: Colors[theme].circle,
        },
        termText: {
            marginLeft: scale(6),
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
            letterSpacing: 0.08,
        },
        alignStart: {
            alignItems: 'flex-start',
        },
        textWrapper: {},
        account: {
            marginLeft: scale(8),
            marginTop: verticalScale(2),
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
        },
        itemTextMargin: {
            marginRight: scale(5),
        },
        socialContainer: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        tooltipText: {
            ...font({
                size: 13,
                height: 18,
                weight: '600',
            }),
            color: Colors[theme].text.primary,
        },
    });

export default styles;
