import {
    moderateScale as nativeModerateScale,
    moderateVerticalScale as nativeModerateVerticalScale,
    scale as nativeScale,
    verticalScale as nativeVerticalScale,
} from 'react-native-size-matters/extend';

import { isWeb } from './app';

const scale = (size: number) => {
    if (isWeb) {
        return size;
    }

    return nativeScale(size);
};

const verticalScale = (size: number) => {
    if (isWeb) {
        return size;
    }

    return nativeVerticalScale(size);
};

const moderateScale = (size: number, factor?: number) => {
    if (isWeb) {
        return size;
    }

    return nativeModerateScale(size, factor);
};

const moderateVerticalScale = (size: number, factor?: number) => {
    if (isWeb) {
        return size;
    }

    return nativeModerateVerticalScale(size, factor);
};

export { scale, verticalScale, moderateScale, moderateVerticalScale };
