import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            paddingHorizontal: scale(20),
        },
        headerText: {
            marginTop: verticalScale(24),
            marginBottom: verticalScale(12),
        },
        gap: {
            marginTop: verticalScale(20),
        },
        content: {
            alignContent: 'center',
            alignItems: 'center',
            marginTop: verticalScale(65),
            justifyContent: 'center',
            paddingHorizontal: 0,
        },
        filter: {
            alignSelf: 'flex-start',
            marginTop: verticalScale(12),
        },
        loading: {
            marginTop: verticalScale(20),
        },
    });

export default styles;
