import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        itemContainer: {
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            paddingHorizontal: scale(20),
            backgroundColor: 'transparent',
        },

        buttonWrapper: {
            marginTop: verticalScale(24),
            paddingHorizontal: scale(20),
            backgroundColor: 'transparent',
        },
        bulletActive: {
            backgroundColor: Colors[theme].text.onLight,
        },
        button: {
            backgroundColor: 'transparent',
            paddingHorizontal: 0,
        },
        content: {
            backgroundColor: 'transparent',
        },
        feature: {
            marginHorizontal: scale(20),
            marginTop: verticalScale(24),
            width: '100%',
        },

        bulletStyle: { backgroundColor: Colors[theme].bulletOnColor },
        table: {
            marginTop: verticalScale(24),
            width: '100%',
        },
    });

export default styles;
