import * as React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import ICON from '@assets/icons/emptyState/pvpBonusFreePet.svg';
import GamingWalletChangeFeature from '@components/GamingWalletChangeFeature';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import BlockHeader from '../BlockHeader/BlockHeader';
import ButtonsBottom from '../ButtonsBottom';
import ModalBottom from '../ModalBottom';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onPress: () => void;
    onClose: () => void;
}

const ModalPvpBonusFreePet = ({ isVisible, onPress, onClose }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <ModalBottom
            isVisible={isVisible}
            onClose={onClose}
            modalHeight={'100%'}>
            <ScrollView
                style={styles.container}
                contentContainerStyle={styles.content}
                showsVerticalScrollIndicator={false}>
                <TouchableOpacity activeOpacity={1}>
                    <BlockHeader
                        emptyStateIcon={<ICON />}
                        type="emptyState"
                        title={i18n.t('pvpBonus.pvpBonusModalFreePet.title')}
                        text={i18n.t('pvpBonus.pvpBonusModalFreePet.text')}
                    />
                    <GamingWalletChangeFeature customStyles={styles.gap} />
                </TouchableOpacity>
            </ScrollView>

            <ButtonsBottom
                title={i18n.t('general.BUTTONS.mintNewNft')}
                onPress={onPress}
                safe
                containerStyle={styles.button}
            />
        </ModalBottom>
    );
};

export default ModalPvpBonusFreePet;
