import * as React from 'react';

import CheckoutSuccessScreen from '../../../../components/CheckoutSuccessScreen';
import TransactionSummary from '../../../../components/TransactionSummary';
import i18n from '../../../../i18n/i18n';
import ROUTES from '../../../../navigation/routes';
import { useNavigation } from '../../../../navigation/useNavigation';
import { WalletRewardsClaimSuccessProps } from '../../../../types';

const RewardsClaimSuccessScreen = ({
    route,
}: WalletRewardsClaimSuccessProps) => {
    const navigation = useNavigation();
    const { total, transactionResult } = route.params;
    navigation.blockHardwareBack();

    const handleBackToWallet = () => {
        navigation.pop();
        navigation.navigate(ROUTES.WALLET_ROOT, {
            screen: ROUTES.WALLET_REWARDS_TAB,
        });
    };

    const renderTransactionDetailComponent = React.useCallback(() => {
        return (
            <>
                <TransactionSummary
                    values={[
                        {
                            key: i18n.t(
                                'wallet.rewards.confirmationModal.keys.to'
                            ),
                            value: i18n.t(
                                'wallet.rewards.confirmationModal.values.rewardsWallet'
                            ),
                        },
                        {
                            key: i18n.t(
                                'wallet.rewards.confirmationModal.keys.from'
                            ),
                            value: i18n.t(
                                'wallet.rewards.confirmationModal.values.mainWallet'
                            ),
                        },
                        {
                            key: i18n.t(
                                'wallet.rewards.confirmationModal.keys.fee'
                            ),
                            value: `${transactionResult.transactionFee} BNB`,
                        },
                        {
                            key: i18n.t(
                                'wallet.rewards.confirmationModal.keys.total'
                            ),
                            value: `${total} IGUP`,
                        },
                    ]}
                />
            </>
        );
    }, [transactionResult, total]);

    return (
        <CheckoutSuccessScreen
            animation={'money'}
            title={i18n.t('wallet.rewards.claimSuccess.title')}
            text={i18n.t('wallet.rewards.claimSuccess.text')}
            onPressButtonMain={handleBackToWallet}
            buttonMainText={i18n.t('wallet.rewards.claimSuccess.backToWallet')}
            TransactionDetailsComponent={renderTransactionDetailComponent()}
            explorerUrl={transactionResult.explorerUrl}
        />
    );
};

export default RewardsClaimSuccessScreen;
