import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            backgroundColor: 'transparent',
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(20),
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
        },
        header: {
            flexDirection: 'row',
            backgroundColor: 'transparent',
            justifyContent: 'space-between',
        },
        headerText: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 21,
                weight: '400',
            }),
            letterSpacing: 0.08,
        },
        rankName: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
            marginLeft: scale(6),
            flex: 1,
        },
        ranks: {
            flex: 1,
            marginRight: scale(12),
        },
        chance: {
            maxWidth: 100,
            textAlign: 'right',
        },
        rankRow: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: scale(12),
        },

        row: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: verticalScale(16),
        },
        textGap: {
            marginBottom: verticalScale(6),
        },
        description: {
            marginBottom: verticalScale(20),
        },
    });

export default styles;
