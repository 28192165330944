import * as React from 'react';

import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';

import { useNavigation } from '../../useNavigation';
import NavigationItem from '../NavigationItem';
import stylesMain from './styles';

interface IProps {
    currentNavigation: NAVIGATION_MARKETPLACE;
}

export enum NAVIGATION_MARKETPLACE {
    ORDERS = 'ORDERS',
    MY_PROFILE = 'MY_PROFILE',
}

const data: NAVIGATION_MARKETPLACE[] = [
    NAVIGATION_MARKETPLACE.ORDERS,
    NAVIGATION_MARKETPLACE.MY_PROFILE,
];

const NavigationMarketPlace = ({ currentNavigation }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const handleNavigation = (item: NAVIGATION_MARKETPLACE) => {
        switch (item) {
            case NAVIGATION_MARKETPLACE.ORDERS:
                navigation.navigate(ROUTES.MARKETPLACE_ACTIVE_ORDERS);
                break;
            case NAVIGATION_MARKETPLACE.MY_PROFILE:
                navigation.navigate(ROUTES.MARKETPLACE_MY_PROFILE);
                break;
        }
    };

    return (
        <View style={styles.main}>
            <View style={styles.shadow}>
                <View style={styles.container}>
                    {data.map((item) => (
                        <NavigationItem
                            key={item}
                            onPress={() => handleNavigation(item)}
                            title={i18n.t(`MarketPlace.navigationBar.${item}`)}
                            isActive={currentNavigation === item}
                            containerStyles={styles.item}
                        />
                    ))}
                </View>
            </View>
        </View>
    );
};
export default NavigationMarketPlace;
