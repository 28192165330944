import { StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import { scale, verticalScale } from '../../../../helpers/dimensions';
import font from '../../../../helpers/font';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            marginTop: verticalScale(20),
        },
        gap: {
            width: 1,
            height: 40,
            backgroundColor: 'transparent',
        },
        textHeader: {
            paddingHorizontal: 0,
        },
        titleContainer: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 22,
                weight: '700',
            }),
            marginBottom: verticalScale(8),
        },
        text: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
            paddingBottom: verticalScale(16),
        },
        content: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
        },

        wrapper: {
            width: '100%',
            height: '100%',
            zIndex: 1,
            position: 'absolute',
            backgroundColor: Colors[theme].background,
        },
        banners: {
            marginBottom: verticalScale(32),
        },
    });

export default styles;
