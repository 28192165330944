export const VERIFICATION_EXAMPLE_CODE = `import math, pyblake2

# The global seed is the hash of the Bitcoin block published by Rollbit
global_seed = bytes.fromhex("00000000000000000004ab6468e03c35500b5f59b8ef58ef9873f67ffbf12bcd")

# In this example, we'll start with the 369,862nd game round and work backwards.
round = 369862

# The round_hash is initially set to the value published for the 369,862nd round.
round_hash = bytes.fromhex("344893385fa113a48edafe61cc0e52a11fba9c1cd2dc4a92f7e919be87945ec5")

for i in range (round, round - 10, -1):
    # For every round, we append the global_seed to the round_hash to get a final has value.
    round_final_hash = pyblake2.blake2b(round_hash + global_seed, digest_size=32).digest()

    # We then convert that hex value to a floating point number 'x', which is uniformly distributed in the unit interval
    x = float(int.from_bytes(round_final_hash[:8], byteorder='little', signed=False)) / float(2**64 - 1)

    # The round's outcome is calculated by applying the house's 5% edge, rounding to 0.01 precision, and restricting the outcome range to [1.0, 1000000.0)
    outcome = min(1e6, max(1.0, math.floor(100.0*(1.0-0.05)/x) / 100.0))
    print("Round: ", i)
    print("Round Hash: ", round_hash.hex())
    print("Round Outcome: ", outcome)

    # For the next round, we calculate the next value in the chain of hashes.
    round_hash = pyblake2.blake2b(round_hash, digest_size=32).digest()`;
