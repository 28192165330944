import * as React from 'react';
import { useRef } from 'react';
import { FlatList, View } from 'react-native';

import { Asset } from 'expo-asset';
import { Formik, FormikValues } from 'formik';

import { useLazyQuery } from '@apollo/client';
import { useFocusEffect } from '@react-navigation/native';

import { GetAIImagesResponse } from '../../../Data/Models';
import { GET_AI_IMAGES } from '../../../Data/Requests';
import Button from '../../../components/Button';
import ButtonsBottom from '../../../components/ButtonsBottom';
import CustomModal from '../../../components/CustomModal';
import { STEP_INDICATOR_FLOWS } from '../../../components/FlowStepIndicator';
import { MINT_PET_STAGES_NAME } from '../../../components/FlowStepIndicator/flows/mintNFTStages';
import { ICON_NAMES } from '../../../components/Icons';
import ImageIguana from '../../../components/ImageIguana';
import ModalLoader from '../../../components/ModalLoader';
import { MintContext } from '../../../contexts/MintContext';
import {
    isGraphqlError,
    isServerError,
    wait,
    waitForNavigation,
} from '../../../helpers/helpers';
import {
    toastError,
    toastErrorUnknown,
} from '../../../helpers/toastNotification';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import ROUTES from '../../../navigation/routes';
import { useNavigation } from '../../../navigation/useNavigation';
import ErrorReporting from '../../../utils/ErrorReporting';
import MintContainer from '../components/MintContainer';
import MintHeaderText from '../components/MintHeaderText';
import SelectableFormOption from '../components/SelectableFormOption';
import stylesMain from './styles';

type IRandom = (
    values: React.SetStateAction<FormikValues>,
    shouldValidate?: boolean | undefined
) => void;

interface IText2Image {
    id: string;
    optionIds: string[];
}

const MintPetGenerationInfoScreen = () => {
    const styles = useThemedStyles(stylesMain);
    const [noMintLeftModal, setNoMintLeftModalVisible] =
        React.useState<boolean>(false);
    const [overloadModal, setOverloadModalVisible] =
        React.useState<boolean>(false);
    const [isRandomLoading, setIsRandomLoading] =
        React.useState<boolean>(false);

    const navigation = useNavigation();
    const {
        setAIImages,
        setAIImage,
        AIFormData: formsData,
        setChangeRankCheckOut,
        isRetry,
        setIsRetry,
    } = React.useContext(MintContext);
    const [getGeneratedImages] =
        useLazyQuery<GetAIImagesResponse>(GET_AI_IMAGES);

    useFocusEffect(
        React.useCallback(() => {
            setAIImage(null);
            setAIImages(null);
        }, [])
    );

    const [isLoading, setIsLoading] = React.useState(false);
    const [text2ImageMaintenance, setText2ImageMaintenance] =
        React.useState(false);

    const formikRef = useRef<any>();

    const getID = React.useCallback(
        (key: string, value: string) => {
            return formsData
                ?.find((val) => val.id === key)
                ?.options.find((opt) => opt.name === value)?.id;
        },
        [formsData]
    );

    const generateTextImage = React.useCallback(
        (values: Record<string, string | string[]>) => {
            const text2ImageSectionInput: IText2Image[] = [];
            Object.entries(values).forEach(([key, value]) => {
                if (key === 'animalId') {
                    return;
                }

                if (key === 'cat') {
                    if (values.animalId !== 'cat') {
                        return;
                    }
                }
                if (key === 'dog') {
                    if (values.animalId !== 'dog') {
                        return;
                    }
                }
                if (value && value.length) {
                    const optionIds: string[] = [];

                    if (Array.isArray(value)) {
                        value.forEach((element) => {
                            const id = getID(key, element);
                            if (id) optionIds.push(id);
                        });
                    } else {
                        const id = getID(key, value);
                        if (id) optionIds.push(id);
                    }
                    text2ImageSectionInput.push({
                        id: key,
                        optionIds,
                    });
                }
            });

            return text2ImageSectionInput;
        },
        [getID]
    );

    const generateImages = React.useCallback(
        async (values: Record<string, string | string[]>) => {
            setChangeRankCheckOut(false);
            const text2ImageInput = generateTextImage(values);

            const { data, error } = await getGeneratedImages({
                fetchPolicy: isRetry ? 'cache-and-network' : undefined,
                variables: {
                    text2ImageInput,
                },
            });
            setIsRetry(false);

            if (error) {
                Console.error(error);

                throw error;
            } else if (data) {
                await Promise.all(
                    data.text2Image.map(async (val) => {
                        Console.log(val.imageUrl);

                        return {
                            id: val.id,
                            localUrl: (
                                await Asset.fromModule(
                                    val.imageUrl
                                ).downloadAsync()
                            ).localUri,
                            url: val.imageUrl,
                        };
                    })
                )
                    .then((data) => {
                        setAIImages(data);
                        waitForNavigation().then(() =>
                            navigation.navigate(
                                ROUTES.MINT_SELECT_GENERATED_NFT
                            )
                        );
                    })
                    .catch((error) => {
                        ErrorReporting.report(error);
                        throw error;
                    });
            }
        },
        [isRetry, generateTextImage]
    );

    const formInitialValue = React.useMemo(() => {
        const init: Record<string, string | string[]> = {};
        formsData?.map((data) =>
            data.maxSelections > 1 ? (init[data.id] = []) : (init[data.id] = '')
        );
        init.animalId = '';
        return init;
    }, [formsData]);

    const requiredField = React.useMemo(
        () => formsData?.filter((val) => val.isRequired),
        [formsData]
    );

    const isDisabledButton = (values: Record<string, string | string[]>) => {
        return requiredField?.map((val) => !!values[val.id]).includes(false);
    };

    const randomNumber = (to: number) => {
        return Math.floor(Math.random() * to);
    };

    const generateRandomNFTS = (setValues: IRandom) => {
        setIsRandomLoading(true);
        const values: FormikValues = {};
        // TODO: move that for helper function
        formsData?.map((val) => {
            const { maxSelections, options } = val;
            let randomText: string | string[] = maxSelections > 1 ? [] : '';
            if (maxSelections > 1) {
                const randomSelection = randomNumber(maxSelections + 1);
                while (randomSelection !== randomText.length) {
                    let random = '';
                    if (Array.isArray(randomText)) {
                        random = options[randomNumber(options.length)].name;
                        if (randomText.indexOf(random) === -1) {
                            randomText.push(random);
                        }
                    }
                }
                values[val.id] = randomText;
            } else {
                randomText = options[randomNumber(options.length)].name;
                const id = val.options?.find((o) => o.name === randomText)?.id;
                values.animalId = id || '';
                values[val.id] = randomText;
            }
            setValues(values, false);
        });
        setIsRandomLoading(false);
    };

    const handleGenerateImages = (values: FormikValues) => {
        setIsLoading(true);

        generateImages(values)
            .then(() => {
                setIsLoading(false);
            })
            .catch(async (error) => {
                ErrorReporting.report(error);
                setIsLoading(false);
                Console.log(error);

                if (
                    isServerError(error) ||
                    isGraphqlError(error, 'TEXT2IMAGE_MAINTENANCE') ||
                    isGraphqlError(error, 'INTERNAL_SERVER_ERROR')
                ) {
                    setText2ImageMaintenance(true);
                } else if (
                    isGraphqlError(error, 'TEXT2IMAGE_USER_RATE_LIMIT')
                ) {
                    setNoMintLeftModalVisible(true);
                } else if (isGraphqlError(error, 'TEXT2IMAGE_SERVICE_BUSY')) {
                    setOverloadModalVisible(true);
                } else {
                    // network error, e.g. request failed
                    if (error.networkError) {
                        toastError(
                            i18n.t('general.errors.networkRequestFailed.title'),
                            i18n.t(
                                'general.errors.networkRequestFailed.message'
                            )
                        );
                    } else {
                        toastErrorUnknown();
                    }
                }
            });
    };

    const renderOption = (
        item: any,
        values: any,
        setFieldValue: any,
        formsData: any
    ) => {
        return (
            <SelectableFormOption
                name={item.id}
                value={values[item.id]}
                setFieldValue={setFieldValue}
                data={formsData.find((o: any) => o.id === item.id)?.options}
                isRequired={item.isRequired}
                maxSelections={item.maxSelections}
            />
        );
    };

    return (
        <>
            <MintContainer
                icon="leftArrow"
                flow={STEP_INDICATOR_FLOWS.MINT_PET}
                stage={MINT_PET_STAGES_NAME.STEP_TWO}
                stageFill="25%"
                onBack={() => {
                    navigation.pop();
                }}>
                <Formik
                    innerRef={formikRef}
                    initialValues={formInitialValue}
                    onSubmit={handleGenerateImages}>
                    {({ submitForm, values, setFieldValue, setValues }) => (
                        <>
                            {formsData && (
                                <FlatList
                                    removeClippedSubviews={false}
                                    keyboardShouldPersistTaps={'handled'}
                                    style={styles.form}
                                    showsVerticalScrollIndicator={false}
                                    ListHeaderComponent={
                                        <>
                                            <MintHeaderText
                                                containerStyle={
                                                    styles.headerText
                                                }
                                                title={i18n.t(
                                                    'nftSelectGeneration.provideMoreInformation'
                                                )}
                                                text={i18n.t(
                                                    'nftSelectGeneration.provideMoreInformationInfo'
                                                )}
                                            />
                                            <View style={styles.headerButtons}>
                                                <Button
                                                    style={styles.headerButton}
                                                    title={i18n.t(
                                                        'nftSelectGeneration.randomize'
                                                    )}
                                                    type="text"
                                                    iconName={ICON_NAMES.RANDOM}
                                                    iconColor={
                                                        styles.iconRandom.color
                                                    }
                                                    onPress={() =>
                                                        generateRandomNFTS(
                                                            setValues
                                                        )
                                                    }
                                                    debouncedPress
                                                />
                                                <Button
                                                    textStyles={
                                                        styles.buttonClear
                                                    }
                                                    style={styles.headerButton}
                                                    title={i18n.t(
                                                        'nftSelectGeneration.clear'
                                                    )}
                                                    type="text"
                                                    iconName={ICON_NAMES.BIN}
                                                    iconColor={
                                                        styles.iconClear.color
                                                    }
                                                    onPress={() =>
                                                        formikRef.current?.resetForm()
                                                    }
                                                    pressedStyle={false}
                                                    debouncedPress
                                                />
                                            </View>
                                        </>
                                    }
                                    data={formsData}
                                    renderItem={({ item }) => {
                                        if (item.id === 'dog') {
                                            if (values.animalId === 'dog') {
                                                return renderOption(
                                                    item,
                                                    values,
                                                    setFieldValue,
                                                    formsData
                                                );
                                            }
                                        } else if (item.id === 'cat') {
                                            if (values.animalId === 'cat') {
                                                return renderOption(
                                                    item,
                                                    values,
                                                    setFieldValue,
                                                    formsData
                                                );
                                            }
                                        } else {
                                            return renderOption(
                                                item,
                                                values,
                                                setFieldValue,
                                                formsData
                                            );
                                        }

                                        return null;
                                    }}
                                />
                            )}
                            <ButtonsBottom
                                title={i18n.t(
                                    'nftSelectGeneration.generateNFTButton'
                                )}
                                onPress={submitForm}
                                disabled={isDisabledButton(values) || isLoading}
                                loading={isRandomLoading || isLoading}
                            />
                        </>
                    )}
                </Formik>
            </MintContainer>
            <ModalLoader
                isVisible={isLoading}
                text={i18n.t('modal.generatingNFT')}
            />
            <CustomModal
                isVisible={text2ImageMaintenance}
                icon={<ImageIguana type="attention" />}
                titleText={i18n.t(
                    'nftSelectGeneration.errors.maintenance.title'
                )}
                infoText={i18n.t('nftSelectGeneration.errors.maintenance.text')}
                firstButtonText={i18n.t('modal.try')}
                secondButtonText={i18n.t('modal.cancel')}
                onFirstButtonClick={() => {
                    setText2ImageMaintenance(false);
                    handleGenerateImages(formikRef.current.values);
                }}
                onSecondButtonClick={() => setText2ImageMaintenance(false)}
            />
            <CustomModal
                isVisible={noMintLeftModal}
                icon={<ImageIguana type="cry" />}
                titleText={i18n.t(`aiQuoteInfo.modalErrorNoMints.title`)}
                infoText={i18n.t(`aiQuoteInfo.modalErrorNoMints.text`)}
                firstButtonText={i18n.t(
                    'aiQuoteInfo.modalErrorNoMints.button1'
                )}
                onFirstButtonClick={() => {
                    setNoMintLeftModalVisible(false);
                    navigation.navigate(ROUTES.MINT_PET_SELECT_GENERATION);
                }}
            />
            <CustomModal
                isVisible={overloadModal}
                icon={<ImageIguana type="attention" />}
                titleText={i18n.t(`aiQuoteInfo.modalErrorOverLoad.title`)}
                infoText={i18n.t(`aiQuoteInfo.modalErrorOverLoad.text`)}
                firstButtonText={i18n.t(
                    'aiQuoteInfo.modalErrorOverLoad.button1'
                )}
                onFirstButtonClick={() => {
                    setOverloadModalVisible(false);
                    navigation.navigate(ROUTES.MINT_PET_SELECT_GENERATION);
                }}
            />
        </>
    );
};
export default MintPetGenerationInfoScreen;
