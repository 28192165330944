import * as React from 'react';

import HowToCharityScreen from '../../screens/HowTo/HowToCharity/HowToCharityScreen';
import CharityDetailedOrganizationScreen from '../../screens/TabBar/Charity/CharityDetailedOrganizationScreen';
import CharityEditDetailedOrganizationScreen from '../../screens/TabBar/Charity/CharityEditDetailedOrganizationScreen';
import CharityEditSelectedOrganizationScreen from '../../screens/TabBar/Charity/CharityEditSelectedOrganizationScreen';
import CharityOrganizationDonationsScreen from '../../screens/TabBar/Charity/CharityOrganizationDonationsScreen';
import CharityOrganizationsScreen from '../../screens/TabBar/Charity/CharityOrganizationsScreen';
import ROUTES from '../routes';

const CharityGroup = (RootStack: any) => {
    return (
        <>
            <RootStack.Screen
                name={ROUTES.CHARITY_ORGANIZATIONS}
                component={CharityOrganizationsScreen}
            />
            <RootStack.Screen
                name={ROUTES.CHARITY_DETAILED_ORGANIZATION}
                component={CharityDetailedOrganizationScreen}
            />
            <RootStack.Screen
                name={ROUTES.CHARITY_ORGANIZATION_DONATIONS}
                component={CharityOrganizationDonationsScreen}
                options={{
                    presentation: 'modal',
                }}
            />
            <RootStack.Screen
                name={ROUTES.CHARITY_EDIT_SELECTED_ORGANIZATION}
                component={CharityEditSelectedOrganizationScreen}
                options={{
                    presentation: 'modal',
                }}
            />
            <RootStack.Screen
                name={ROUTES.CHARITY_EDIT_DETAILED_ORGANIZATION}
                component={CharityEditDetailedOrganizationScreen}
                options={{
                    presentation: 'modal',
                }}
            />
            <RootStack.Screen
                name={ROUTES.HOW_TO_CHARITY}
                component={HowToCharityScreen}
                options={{
                    presentation: 'modal',
                    headerShown: false,
                }}
            />
        </>
    );
};

export default CharityGroup;
