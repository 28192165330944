import * as React from 'react';
import { FlatList } from 'react-native';

import FirstPlace from '@assets/icons/leaderboard/firstPlace.svg';
import PvpGame from '@assets/icons/leaderboard/pvpGame.svg';
import SecondPlace from '@assets/icons/leaderboard/secondPlace.svg';
import ThirdPlace from '@assets/icons/leaderboard/thirdPlace.svg';
import OtherUserAvatar from '@components/OtherUserAvatar';
import { Text, View } from '@components/Themed';
import { useUser } from '@contexts/UserContext';
import { scale } from '@helpers/dimensions';
import { statisticsFormatter } from '@helpers/helpers';
import { truncateEthAddress } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import { LeaderboardRowOutput } from '@models/leaderboard';

import stylesMain from './styles';

interface IProps {
    topPlayers: LeaderboardRowOutput[];
}

const PvpGamesBoard = ({ topPlayers }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { user } = useUser();

    const placeView = (place: string) => {
        switch (place) {
            case '1':
                return <FirstPlace width={24} height={24} />;
            case '2':
                return <SecondPlace width={24} height={24} />;
            case '3':
                return <ThirdPlace width={24} height={24} />;

            default:
                return <Text style={styles.place}>{place}</Text>;
        }
    };

    const renderItem = React.useCallback(
        (item: LeaderboardRowOutput) => {
            const { rank, username, avatarUrl, gamesCount } = item;
            const inBoard =
                user?.username === username || user?.walletAddress === username;

            return (
                <View style={[styles.shadow, inBoard && styles.active]}>
                    <View
                        style={[
                            styles.itemContainer,
                            inBoard && styles.activeContainer,
                        ]}>
                        <View style={styles.left}>
                            {placeView(rank)}
                            <View style={styles.imageWrapper}>
                                <OtherUserAvatar
                                    size={scale(40)}
                                    imageUrl={avatarUrl}
                                    userId={username}
                                />
                            </View>
                            <Text
                                style={styles.address}
                                ellipsizeMode={'middle'}
                                numberOfLines={1}>
                                {truncateEthAddress(username)}
                            </Text>
                        </View>
                        <View style={styles.right}>
                            <Text style={styles.energy}>
                                {statisticsFormatter(gamesCount)}
                            </Text>
                            <PvpGame width={20} height={20} />
                        </View>
                    </View>
                </View>
            );
        },
        [styles]
    );

    return (
        <FlatList
            showsVerticalScrollIndicator={false}
            style={styles.list}
            data={topPlayers}
            initialNumToRender={10}
            renderItem={({ item }) => renderItem(item)}
            keyExtractor={(item) => item.rank}
        />
    );
};
export default PvpGamesBoard;
