import * as React from 'react';

import Svg from '../../assets/icons/buttons/arrowDown.svg';
import Colors from '../../constants/Colors';
import { useTheme } from '../../contexts/ThemeContext';
import { IconType, iconSize } from './types';

const ArrowDown: React.FunctionComponent<IconType> = ({
    color,
    size = iconSize.default,
}) => {
    const { theme } = useTheme();
    return (
        <Svg
            height={size}
            width={size}
            color={color ?? Colors[theme].icon.primary}
        />
    );
};

export default ArrowDown;
