import * as React from 'react';
import { useMemo } from 'react';
import { TouchableOpacity } from 'react-native';

import NftRoyalGamesHistoryIcon from '@components/Icons/NftRoyalGamesHistoryIcon';
import OtherUserAvatar from '@components/OtherUserAvatar';
import { Text, View } from '@components/Themed';
import { scale } from '@helpers/dimensions';
import { dateFormatter } from '@helpers/time';
import { balanceFromWei, truncateEthAddress } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import {
    NFT_ROYAL_GAME_HISTORY_STATUS,
    PaginatedNftRoyaleGamesHistoryRowOutput,
} from '@models/mysteryGames';

import GameAmount from '../GameAmount';
import stylesMain from './styles';

interface IProps {
    item: PaginatedNftRoyaleGamesHistoryRowOutput;
    onPress: (item: PaginatedNftRoyaleGamesHistoryRowOutput) => void;
}

const MysteryGamesHistoryItem = ({ item, onPress }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const icon = useMemo(() => {
        Console.info(item.status);
        if (
            item.status === NFT_ROYAL_GAME_HISTORY_STATUS.WaitingForMyself ||
            item.status === NFT_ROYAL_GAME_HISTORY_STATUS.WaitingForOpponent ||
            item.status === NFT_ROYAL_GAME_HISTORY_STATUS.Refund
        ) {
            return <NftRoyalGamesHistoryIcon slug={item.status} />;
        }
        return (
            <OtherUserAvatar
                size={scale(40)}
                imageUrl={item.opponentAvatarUrl}
            />
        );
    }, [item.status]);
    const title = useMemo(() => {
        if (
            item.opponentUsername &&
            item.status !== NFT_ROYAL_GAME_HISTORY_STATUS.WaitingForMyself &&
            item.status !== NFT_ROYAL_GAME_HISTORY_STATUS.WaitingForOpponent &&
            item.status !== NFT_ROYAL_GAME_HISTORY_STATUS.Refund
        ) {
            return truncateEthAddress(item.opponentUsername);
        }
        return i18n.t(`HomeScreen.GameHistoryScreen.status.${item.status}`);
    }, [item]);

    return (
        <TouchableOpacity
            style={[styles.itemContainer]}
            activeOpacity={0.7}
            disabled={item.status === NFT_ROYAL_GAME_HISTORY_STATUS.Refund}
            onPress={() => onPress(item)}>
            <View style={styles.left}>
                <View style={styles.imageWrapper}>{icon}</View>
                <View style={styles.infoBlock}>
                    <Text
                        style={styles.address}
                        ellipsizeMode={'middle'}
                        numberOfLines={1}>
                        {title}
                    </Text>
                    <Text
                        style={styles.date}
                        ellipsizeMode={'middle'}
                        numberOfLines={1}>
                        {dateFormatter(item.createdAt)}
                    </Text>
                </View>
            </View>
            <View style={styles.right}>
                <GameAmount
                    status={item.status}
                    amount={balanceFromWei(item.iguAmountWei).valueLong}
                />
            </View>
        </TouchableOpacity>
    );
};
export default MysteryGamesHistoryItem;
