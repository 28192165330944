import { StyleSheet } from 'react-native';

import { isWeb } from '@helpers/app';
import { verticalScale } from '@helpers/dimensions';

import Colors from '../../constants/Colors';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        textWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        keyText: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
            letterSpacing: 0.08,
        },
        keyView: {
            flex: 1,
            alignItems: 'center',
            flexDirection: 'row',
        },
        valueText: {
            flex: 1,
            flexWrap: 'wrap',
            textAlign: 'right',
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '600',
            }),
            ...(isWeb
                ? {
                      overflowWrap: 'anywhere',
                      wordBreak: 'break-all',
                  }
                : {}),
        },
        valueComponent: {
            flex: 1,
            alignItems: 'flex-end',
        },
        gap: {
            marginBottom: verticalScale(12),
        },
    });

export default styles;
