import { StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import { scale, verticalScale } from '../../../../helpers/dimensions';
import font from '../../../../helpers/font';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: Colors[theme].background,
        },
        page: {
            flex: 1,
            paddingHorizontal: scale(20),
            marginBottom: verticalScale(16),
            backgroundColor: Colors[theme].background,
        },
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 22,
                height: 28,
                weight: '700',
            }),
            marginTop: verticalScale(4),
            marginBottom: verticalScale(8),
        },
        text: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            marginBottom: verticalScale(8),
        },
        buttonWrapper: {
            marginBottom: verticalScale(16),
        },
        emptyContainer: {
            flex: 1,
        },
        emptyText: {
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
        },
        alert: {
            marginBottom: verticalScale(16),
        },
    });

export default styles;
