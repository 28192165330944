import React, { useEffect } from 'react';

import { useAppLoading } from '@contexts/AppLoading/AppLoadingContext';
import { useDimensions } from '@contexts/DimensionsContext';
import { useTheme } from '@contexts/ThemeContext';
import useThemedStyles from '@hooks/useThemedStyles';

import { tradeViewUrl } from './config';
import stylesMain from './styles';

const ChartContent = ({ pair }: { pair: string }) => {
    const styles = useThemedStyles(stylesMain);
    const { theme } = useTheme();
    const { setWebContainerIsVisible } = useDimensions();
    const { isSplashAnimationComplete } = useAppLoading();

    useEffect(() => {
        if (isSplashAnimationComplete) {
            setWebContainerIsVisible(false);
        }
        return () => setWebContainerIsVisible(true);
    }, [isSplashAnimationComplete, setWebContainerIsVisible]);

    return (
        <iframe
            title="TradingView"
            src={`${tradeViewUrl}?symbol=${pair}&theme=${theme}`}
            style={styles.webview}
        />
    );
};
export default ChartContent;
