import React, { useCallback, useState } from 'react';

import _ from 'lodash';

import { useApolloClient } from '@apollo/client';
import { errorsHandler } from '@helpers/errors';

import {
    ReferralUsersItem,
    ReferralUsersResponse,
    TotalReferralUserCountsOutput,
} from '../Data/Models';
import { GET_REFERRAL_USERS } from '../Data/Requests';

export declare type ReferralsFilterType = 'CreatedAt' | 'UserEnergy';

export const referralsConfig: {
    defaultFilter: string;
    filters: ReferralsFilterType[];
} = {
    defaultFilter: 'CreatedAt',
    filters: ['CreatedAt', 'UserEnergy'],
};

const useReferrals = (startFilter?: string) => {
    const client = useApolloClient();

    const [pullToRefreshLoading, setPullToRefreshLoading] =
        useState<boolean>(false);

    const [referralsData, setReferralsData] = useState<ReferralUsersItem[]>();
    const [totalReferralUserCountsOutput, setTotalReferralUserCountsOutput] =
        useState<TotalReferralUserCountsOutput>();
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [filter, setFilter] = React.useState(
        startFilter || referralsConfig.defaultFilter
    );

    const getReferrals = async (silent: boolean, orderFilter?: string) => {
        setError(false);
        setCurrentPage(1);

        if (silent) {
            setLoading(true);
        } else {
            setPullToRefreshLoading(true);
        }

        try {
            const result = await client.query<ReferralUsersResponse>({
                query: GET_REFERRAL_USERS,
                fetchPolicy: 'network-only',
                variables: {
                    referralsPaginationInput: {
                        page: 1,
                        order: orderFilter || filter,
                    },
                },
            });

            if (result.data) {
                setReferralsData(result.data.referralUsers.items);
                setTotalReferralUserCountsOutput(
                    result.data.referralUserCounts
                );
                setCurrentPage(result.data.referralUsers.meta.currentPage);
                setTotalPages(result.data.referralUsers.meta.totalPages);
            }

            setPullToRefreshLoading(false);
            setLoading(false);
        } catch (error) {
            setError(true);
            setPullToRefreshLoading(false);
            setLoading(false);
            errorsHandler(error);
        }
    };

    const [loadMore, setLoadMore] = useState(false);
    const [loadingPaginated, setLoadingPaginated] = useState(true);

    const getReferralsPaginated = useCallback(async () => {
        if (currentPage === totalPages) {
            return;
        }
        setLoadMore(true);
        try {
            const result = await client.query<ReferralUsersResponse>({
                query: GET_REFERRAL_USERS,
                fetchPolicy: 'network-only',
                variables: {
                    referralsPaginationInput: {
                        page: currentPage + 1,
                        order: filter,
                    },
                },
            });

            if (result.data) {
                setReferralsData((prev) => {
                    return prev
                        ? _.uniqBy(
                              [...prev, ...result.data.referralUsers.items],
                              (o) => o.user.walletAddress
                          )
                        : result.data.referralUsers.items;
                });

                setCurrentPage(result.data.referralUsers.meta.currentPage);
                setTotalPages(result.data.referralUsers.meta.totalPages);
            }

            setLoadMore(false);
            setLoadingPaginated(false);
        } catch (error) {
            setLoadMore(false);
            setLoadingPaginated(false);
            errorsHandler(error);
        }
    }, [currentPage, totalPages, filter]);

    const handleChooseFilter = async (item: string) => {
        setFilter(item);
        await getReferrals(false, item);
    };

    return {
        referralsData,
        getReferrals,
        pullToRefreshLoading,
        loading,
        error,
        totalReferralUserCountsOutput,
        loadMore,
        loadingPaginated,
        getReferralsPaginated,
        filter,
        handleChooseFilter,
    };
};

export default useReferrals;
