import * as React from 'react';
import { FlatList, Pressable } from 'react-native';

import { PetIdType } from '@Data/Models';
import { ISocialToEarnCard } from '@constants/shareCardTemplatesNew';
import { isDesktop } from '@helpers/app';
import useThemedStyles from '@hooks/useThemedStyles';

import Carousel from '../Carousel';
import { View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    backgrounds: ISocialToEarnCard[];
    templateColor: ISocialToEarnCard;
    handleBgColor: (background: ISocialToEarnCard) => void;
}

const ColorPicker = ({ backgrounds, templateColor, handleBgColor }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const [isScroll, setIsScroll] = React.useState(false);
    const [isPressIn, setIsPressIn] = React.useState(false);

    const onItemPress = (item: ISocialToEarnCard) => {
        if (!isScroll) {
            handleBgColor(item);
        }
        setIsScroll(false);
        setIsPressIn(false);
    };

    const renderItem = (item: ISocialToEarnCard) => {
        const isSelected = item.id === templateColor.id;

        return (
            <Pressable
                key={item.id}
                onPress={() => onItemPress(item)}
                onPressIn={() => setIsPressIn(true)}
                style={[styles.shadow, isSelected && styles.activeShadow]}>
                <View style={[styles.renderItem, isSelected && styles.active]}>
                    <View
                        style={[
                            styles.fill,
                            { backgroundColor: item.colorPicker },
                        ]}></View>
                </View>
            </Pressable>
        );
    };

    return (
        <>
            {backgrounds.length < 5 ? (
                <View style={styles.center}>
                    {backgrounds.map((i) => renderItem(i))}
                </View>
            ) : isDesktop ? (
                <Carousel
                    data={[...backgrounds]}
                    renderItem={(item) => renderItem(item)}
                    itemWidth={51}
                    height={45}
                    pagingEnabled={false}
                    snapEnabled={false}
                    windowSize={10}
                    panGestureProps={{
                        onBegan: () =>
                            isDesktop && isPressIn && setIsScroll(true),
                    }}
                    carouselStyle={{ justifyContent: 'flex-start' }}
                />
            ) : (
                <FlatList
                    pagingEnabled={false}
                    initialNumToRender={backgrounds.length}
                    horizontal
                    showsHorizontalScrollIndicator={false}
                    data={[...backgrounds]}
                    renderItem={({ item }) => renderItem(item)}
                    keyExtractor={(item) => item.id}
                />
            )}
        </>
    );
};
export default ColorPicker;
