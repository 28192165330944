import * as React from 'react';
import { SafeAreaView } from 'react-native';

import WalletPenIcon from '../../../assets/icons/createImportWallet/walletPen.svg';
import Button from '../../../components/Button';
import { Text, View } from '../../../components/Themed';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import NavigationBar from '../../../navigation/NavigationBar';
import ROUTES from '../../../navigation/routes';
import { useNavigation } from '../../../navigation/useNavigation';
import mainStyles from './styles';

const CreateWalletFirstScreen: React.FunctionComponent = () => {
    const navigation = useNavigation();
    const styles = useThemedStyles(mainStyles);

    const handlePress = () => {
        navigation.navigate(ROUTES.CREATE_WALLET_PASSPHRASE);
    };

    const handleGoBack = () => navigation.goBack();

    return (
        <>
            <NavigationBar
                filled={false}
                wallet={false}
                onBack={handleGoBack}
            />
            <View style={styles.wrapper}>
                <View style={styles.body}>
                    <WalletPenIcon />
                    <Text style={styles.title}>
                        {i18n.t('createWalletFirst.title')}
                    </Text>
                    <Text style={styles.text}>
                        {i18n.t('createWalletFirst.text')}
                    </Text>
                </View>

                <Button
                    title={i18n.t('createWalletFirst.button')}
                    onPress={handlePress}
                />
            </View>
            <SafeAreaView style={styles.safe} />
        </>
    );
};

export default CreateWalletFirstScreen;
