import { Asset } from 'expo-asset';

export const assetsFonts = () => {
    return {
        'Nunito-Regular': require('../../assets/fonts/Nunito-Regular.ttf'),
        'Nunito-Medium': require('../../assets/fonts/Nunito-Medium.ttf'),
        'Nunito-Black': require('../../assets/fonts/Nunito-Black.ttf'),
        'Nunito-Bold': require('../../assets/fonts/Nunito-Bold.ttf'),
        'Nunito-SemiBold': require('../../assets/fonts/Nunito-SemiBold.ttf'),
        'Nunito-ExtraBold': require('../../assets/fonts/Nunito-ExtraBold.ttf'),
        'space-mono': require('../../assets/fonts/SpaceMono-Regular.ttf'),
    };
};

export const assetsImages = async () => {
    return Asset.loadAsync([
        require('../../assets/icons/app/splash/sheets.png'),
        require('../../assets/icons/app/splash/clouds.png'),
        require('../../assets/icons/app/splash/flakes.png'),
        require('../../assets/icons/app/splashFull.png'),
    ]);
};

export const assetsLotties = async (loadRequired: boolean = false) => {
    if (loadRequired) {
        return {
            start: {
                asset: await loadLottie(
                    require('../../assets/animations/start.lottie')
                ),
                speed: 0.25,
            },
            running: {
                asset: await loadLottie(
                    require('../../assets/animations/running.lottie')
                ),
                speed: 0.9,
            },
        };
    }

    return {
        /*welcome: {
            asset: await loadLottie(
                require('../../assets/animations/welcome.lottie')
            ),
            speed: 0.3,
        },
        loading: {
            asset: await loadLottie(
                require('../../assets/animations/loading.lottie')
            ),
            speed: 0.3,
        },*/
        dancing: {
            asset: await loadLottie(
                require('../../assets/animations/dancing.lottie')
            ),
            speed: 0.25,
        },
        cry: {
            asset: await loadLottie(
                require('../../assets/animations/cry.lottie')
            ),
            speed: 0.3,
        },
        maintenance: {
            asset: await loadLottie(
                require('../../assets/animations/maintenance.lottie')
            ),
            speed: 0.5,
        },
        money: {
            asset: await loadLottie(
                require('../../assets/animations/money.lottie')
            ),
            speed: 0.5,
        },
        joinGame: {
            asset: await loadLottie(
                require('../../assets/animations/joinGame.lottie')
            ),
            speed: 0.99,
        },
        nftRoyalLoading: {
            asset: await loadLottie(
                require('../../assets/animations/nftRoyalLoading.lottie')
            ),
            speed: 0.99,
        },
        MysteriousLikes: {
            asset: await loadLottie(
                require('../../assets/animations/pvpGames/mysteryLikes.lottie')
            ),
            speed: 0.99,
        },
        Breeding: {
            asset: await loadLottie(
                require('../../assets/animations/pvpGames/nftEvolution.lottie')
            ),
            speed: 0.99,
        },
        NftRoyale: {
            asset: await loadLottie(
                require('../../assets/animations/pvpGames/nftRoyal.lottie')
            ),
            speed: 0.99,
        },
        LuckyStrike: {
            asset: await loadLottie(
                require('../../assets/animations/pvpGames/luckyStrike.lottie')
            ),
            speed: 0.99,
        },
        QuantumSpin: {
            asset: await loadLottie(
                require('../../assets/animations/pvpGames/quantumSpin.lottie')
            ),
            speed: 0.99,
        },
        Tokenteller: {
            asset: await loadLottie(
                require('../../assets/animations/pvpGames/tokenteller.lottie')
            ),
            speed: 0.99,
        },
        Won: {
            asset: await loadLottie(
                require('../../assets/animations/won.lottie')
            ),
            speed: 0.99,
        },
        Lost: {
            asset: await loadLottie(
                require('../../assets/animations/lost.lottie')
            ),
            speed: 0.99,
        },
        Draw: {
            asset: await loadLottie(
                require('../../assets/animations/draw.lottie')
            ),
            speed: 0.99,
        },
        Refund: {
            asset: await loadLottie(
                require('../../assets/animations/refundTokenTeller.lottie')
            ),
            speed: 0.99,
        },
        WaitingForOpponent: {
            asset: await loadLottie(
                require('../../assets/animations/waitingForOpponent.lottie')
            ),
            speed: 0.99,
        },
        bottleBreed: {
            asset: await loadLottie(
                require('../../assets/animations/bottleBreed.lottie')
            ),
            speed: 0.99,
        },
        dnaBreed: {
            asset: await loadLottie(
                require('../../assets/animations/dnaBreed.lottie')
            ),
            speed: 0.99,
        },
        penBreed: {
            asset: await loadLottie(
                require('../../assets/animations/penBreed.lottie')
            ),
            speed: 0.99,
        },
        FruitSlasher: {
            asset: await loadLottie(
                require('../../assets/animations/FruitSlasher.lottie')
            ),
            speed: 0.99,
        },
        Ghostrider: {
            asset: await loadLottie(
                require('../../assets/animations/Ghostrider.lottie')
            ),
            speed: 0.99,
        },
        MoneyBag: {
            asset: await loadLottie(
                require('../../assets/animations/MoneyBag.lottie')
            ),
            speed: 0.99,
        },
        LuckyStroke: {
            asset: await loadLottie(
                require('../../assets/animations/LuckyStroke.lottie')
            ),
            speed: 0.99,
        },
        LoyalFighter: {
            asset: await loadLottie(
                require('../../assets/animations/LoyalFighter.lottie')
            ),
            speed: 0.99,
        },
        UndercoverMillionaire: {
            asset: await loadLottie(
                require('../../assets/animations/UndercoverMillionaire.lottie')
            ),
            speed: 0.99,
        },
        LeaderboardMaster: {
            asset: await loadLottie(
                require('../../assets/animations/LeaderboardMaster.lottie')
            ),
            speed: 0.99,
        },
        WaitingForOpponentLuckyStrike: {
            asset: await loadLottie(
                require('../../assets/animations/waitingForOpponentLuckyStrike.lottie')
            ),
            speed: 0.99,
        },
        TokenTellerRoundComing: {
            asset: await loadLottie(
                require('../../assets/animations/tokenTellerRoundComing.lottie')
            ),
            speed: 0.99,
        },
        TokenTellerRoundLive: {
            asset: await loadLottie(
                require('../../assets/animations/tokenTellerRoundLive.lottie')
            ),
            speed: 0.99,
        },
    };
};

async function loadLottie(file: any) {
    const response = await fetch(file);
    return response.json();
}
