import React, { useCallback, useEffect, useMemo } from 'react';

import { useDimensions } from '@contexts/DimensionsContext';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { useVisibleHook } from '@hooks/useVisibleHook';
import { LuckyStrikeAvailableGameOutput } from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { useFocusEffect } from '@react-navigation/native';

import { useLuckyStrikeGamesWinners } from '../../../hooks/useLuckyStrikeGamesWinners';
import useLuckyStrikePurchaseModal from '../../../hooks/useLuckyStrikePurchaseModal';
import { useLuckyStrikeWinnersStatistics } from '../../../hooks/useLuckyStrikeWinnersStatistics';
import { toastGameExpired } from '../../helpers';

const useLuckyStrikeMainScreen = (
    game?: LuckyStrikeAvailableGameOutput | undefined
) => {
    const navigation = useNavigation();
    const { isSmallLayout } = useDimensions();

    const {
        availableBalance,
        getGamingAvailableBalance,
        luckyStrikeGames,
        luckyStrikeGamesLoading,
        luckyStrikeGamesRefreshLoading,
        getLuckyStrikeGames,
        refetchLuckyStrikeGames,
        luckyStrikeGamesRefetchLoading,
    } = useMysteryGames();

    const {
        getStatistics,
        statisticsLoading,
        refreshStatisticsLoading,
        statistics,
    } = useLuckyStrikeWinnersStatistics();

    const { getWinners, winners, winnersLoading, refreshWinnersLoading } =
        useLuckyStrikeGamesWinners(10);

    const loadData = useCallback(
        async (silent: boolean) => {
            await Promise.all([
                getLuckyStrikeGames(silent),
                getGamingAvailableBalance(silent),
                getStatistics(silent),
                getWinners(silent),
            ]);
        },
        [getLuckyStrikeGames]
    );

    const isLoading = useMemo(
        () => luckyStrikeGamesLoading || statisticsLoading || winnersLoading,
        [luckyStrikeGamesLoading, statisticsLoading, winnersLoading]
    );
    useFocusEffect(
        useCallback(() => {
            loadData(true);
        }, [loadData])
    );

    const isRefresh = useMemo(
        () =>
            luckyStrikeGamesRefreshLoading ||
            refreshStatisticsLoading ||
            refreshWinnersLoading,
        [
            luckyStrikeGamesRefreshLoading,
            refreshStatisticsLoading,
            refreshWinnersLoading,
        ]
    );
    const handleRefresh = useCallback(async () => {
        loadData(false);
    }, [loadData]);

    const [currentGame, setCurrentGame] =
        React.useState<LuckyStrikeAvailableGameOutput>();

    const {
        isVisible: slotsModal,
        open: openSlotsModal,
        close: closeSlotsModal,
    } = useVisibleHook();

    const resetCurrentGame = () => {
        setCurrentGame(undefined);
    };

    const { renderPurchaseModal, openPurchaseModal } =
        useLuckyStrikePurchaseModal(ROUTES.LUCKY_STRIKE_MAIN, resetCurrentGame);

    const handOnHowItWorks = () => {
        navigation.push(ROUTES.HOW_LUCKY_STRIKE_WORK);
    };
    const onPurchaseTicket = useCallback(
        (game: LuckyStrikeAvailableGameOutput) => {
            setCurrentGame(game);
            openPurchaseModal(slotsModal ? closeSlotsModal : undefined);
        },
        [slotsModal]
    );

    const onEndTime = useCallback(() => {
        refetchLuckyStrikeGames();
    }, [refetchLuckyStrikeGames]);

    React.useEffect(() => {
        const check = async () => {
            const currentGame = luckyStrikeGames?.find(
                (i) => i.id === game?.id
            );
            if (!currentGame) {
                await toastGameExpired();
            } else {
                setCurrentGame(currentGame);
                openPurchaseModal();
            }
            navigation.setParams({
                game: undefined,
            });
        };

        if (game) {
            check();
        }
    }, [game, luckyStrikeGames]);

    const isReloading = useMemo(
        () => isRefresh || isLoading || luckyStrikeGamesRefetchLoading,
        [isRefresh, isLoading, luckyStrikeGamesRefetchLoading]
    );

    return {
        handleRefresh,
        isRefresh,
        isLoading,
        isSmallLayout,
        availableBalance,
        handOnHowItWorks,
        slotsModal,
        openSlotsModal,
        closeSlotsModal,
        renderPurchaseModal,
        onPurchaseTicket,
        luckyStrikeGames,
        luckyStrikeGamesRefetchLoading,
        currentGame,
        onEndTime,
        statistics,
        winners,
        isReloading,
    };
};

export default useLuckyStrikeMainScreen;
