import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '../../../helpers/dimensions';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
        },
        textWrapper: {
            marginTop: verticalScale(24),
            paddingHorizontal: scale(20),
            marginBottom: verticalScale(20),
        },

        buttonWrapper: {
            paddingHorizontal: scale(16),
            alignItems: 'center',
        },
        selectFromGallery: {
            paddingVertical: verticalScale(0),
            paddingHorizontal: scale(0),
        },
        divider: {
            marginHorizontal: scale(20),
            marginVertical: verticalScale(20),
        },
        tipsWrapper: {
            paddingBottom: verticalScale(24),
            paddingHorizontal: scale(20),
        },
    });

export default styles;
