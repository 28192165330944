import * as React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import ButtonsBottom from '@components/ButtonsBottom';
import ModalBottom from '@components/ModalBottom';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES, QuantumSpinGameOutput } from '@models/mysteryGames';

import QuantumSpinPrizeInfo from '../QuantumSpinPrizeInfo';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
    modalHeight?: number | string;
    handlePress: () => void;
    data: QuantumSpinGameOutput;
}

const QuantumSpinDetailsModal = ({
    isVisible,
    onClose,
    modalHeight = 999,
    handlePress,
    data,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <ModalBottom
            isVisible={isVisible}
            onClose={onClose}
            modalHeight={modalHeight}>
            <ScrollView
                style={styles.container}
                showsVerticalScrollIndicator={false}>
                <TouchableOpacity activeOpacity={1} style={styles.alignCenter}>
                    <QuantumSpinPrizeInfo data={data} />
                </TouchableOpacity>
            </ScrollView>

            <ButtonsBottom
                title={i18n.t(
                    `HomeScreen.${MYSTERY_GAMES.quantumSpin}.QuantumSpinDetailsModal.button`
                )}
                onPress={handlePress}
                safe
            />
        </ModalBottom>
    );
};

export default QuantumSpinDetailsModal;
