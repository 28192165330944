import * as React from 'react';
import { Image, SafeAreaView } from 'react-native';

import step from '@assets/icons/howTo/howToSocializeToEarn/step.png';
import stepFour from '@assets/icons/howTo/howToSocializeToEarn/stepFour.png';
import stepOne from '@assets/icons/howTo/howToSocializeToEarn/stepOne.png';
import stepThree from '@assets/icons/howTo/howToSocializeToEarn/stepThree.png';
import stepTwo from '@assets/icons/howTo/howToSocializeToEarn/stepTwo.png';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import Carousel from '@components/Carousel';
import CarouselScrollView from '@components/Carousel/components/CarouselScrollView';
import { ICON_NAMES } from '@components/Icons';
import StatusIcon from '@components/Icons/StatusIcon';
import { Text, View } from '@components/Themed';
import { useDimensions } from '@contexts/DimensionsContext';
import { scale } from '@helpers/dimensions';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBarModal from '@navigation/NavigationBar/NavigationBarModal';
import { useNavigation } from '@navigation/useNavigation';

import stylesMain from './styles';

const data = ['step', 'stepOne', 'stepTwo', 'stepThree', 'stepFour'];

const HowToSocializeToEarnScreen = () => {
    const { windowWidth } = useDimensions();
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const itemWidth = windowWidth - scale(20) * 2;

    const renderStatus = (status: 'CONFIRMED' | 'FAILED') => (
        <View style={[styles.statusWrapper, styles[status]]}>
            <StatusIcon slug={status} />
            <Text style={[styles.statusText, styles[`${status}text`]]}>
                {i18n.t(`howTo.socializeToEarn.${status}`)}
            </Text>
        </View>
    );

    const renderItem = (item: string) => {
        const icon = () => {
            switch (item) {
                case 'step':
                    return (
                        <>
                            <Image
                                style={{
                                    width: itemWidth,
                                    height: itemWidth,
                                }}
                                resizeMode="contain"
                                source={step}
                            />
                            {renderStatus('CONFIRMED')}
                        </>
                    );
                case 'stepOne':
                    return (
                        <>
                            <Image
                                style={{
                                    width: itemWidth,
                                    height: itemWidth,
                                }}
                                resizeMode="contain"
                                source={stepOne}
                            />
                            {renderStatus('CONFIRMED')}
                        </>
                    );
                case 'stepTwo':
                    return (
                        <>
                            <Image
                                style={{
                                    width: itemWidth,
                                    height: itemWidth,
                                }}
                                resizeMode="contain"
                                source={stepTwo}
                            />
                            {renderStatus('CONFIRMED')}
                        </>
                    );
                case 'stepThree':
                    return (
                        <>
                            <Image
                                style={{
                                    width: itemWidth,
                                    height: itemWidth,
                                }}
                                resizeMode="contain"
                                source={stepThree}
                            />
                            {renderStatus('FAILED')}
                        </>
                    );
                case 'stepFour':
                    return (
                        <>
                            <Image
                                style={{
                                    width: itemWidth,
                                    height: itemWidth,
                                }}
                                resizeMode="contain"
                                source={stepFour}
                            />
                            {renderStatus('FAILED')}
                        </>
                    );
            }
        };

        return (
            <CarouselScrollView>
                <View style={styles.itemContainer}>
                    <View style={styles.content}>
                        <View style={styles.imgWrapperWithInfo}>{icon()}</View>
                        <BlockHeader
                            title={i18n.t(
                                `howTo.socializeToEarn.${item}.title`
                            )}
                            text={i18n.t(`howTo.socializeToEarn.${item}.text`)}
                            type="emptyState"
                        />
                    </View>
                </View>
            </CarouselScrollView>
        );
    };

    return (
        <View style={styles.main}>
            <View style={styles.header}>
                <NavigationBarModal
                    iconName={ICON_NAMES.CLOSE}
                    title={''}
                    onBack={() => navigation.pop()}
                />
            </View>
            <Carousel
                data={data}
                sliderWidth={windowWidth}
                itemWidth={windowWidth}
                renderItem={(item) => renderItem(item)}
                fullHeight
                pagination
                centredVertical
            />
            <SafeAreaView />
        </View>
    );
};

export default HowToSocializeToEarnScreen;
