import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { isWeb } from '@helpers/app';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        // container: { marginBottom: 56 },
        header: {
            ...font({
                size: 18,
                height: 24,
                weight: '700',
            }),
            color: Colors[theme].text.primary,
            marginRight: scale(8),
        },

        carousel: {
            paddingBottom: 0,
            marginBottom: 56,
        },
        headerBlock: {
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: verticalScale(12),
            paddingHorizontal: scale(20),
        },
        defaultCards: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            marginHorizontal: scale(20),
            marginBottom: 24,
        },
    });

export default styles;
