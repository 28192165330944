import React from 'react';

import { Pet } from '../../../../../Data/Models';
import MatchingIcon from '../../../../../assets/icons/general/matching.svg';
import CircleDivider from '../../../../../components/CircleDivider';
import Icon, { ICON_NAMES } from '../../../../../components/Icons';
import { Text, View } from '../../../../../components/Themed';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import DetailsBlock from '../DetailsBlock';
import Styles from './styles';

interface IProps {
    pet: Pet;
}

const MatchingDetailsBlock = ({ pet }: IProps) => {
    const styles = useThemedStyles(Styles);

    const { likeCount, dislikeCount } = pet;

    return (
        <DetailsBlock
            title={i18n.t('detailedPet.matching.title')}
            description={i18n.t('detailedPet.matching.text')}
            icon={<MatchingIcon width={48} height={48} />}>
            <View style={styles.wrapper}>
                <View style={styles.item}>
                    <Icon name={ICON_NAMES.LIKE} />
                    <Text style={styles.text}>
                        {i18n.t('detailedPet.matching.like', {
                            like: likeCount || 0,
                        })}
                    </Text>
                </View>
                <CircleDivider />
                <View style={styles.item}>
                    <Icon name={ICON_NAMES.DISLIKE} />
                    <Text style={styles.text}>
                        {i18n.t('detailedPet.matching.dislike', {
                            dislike: dislikeCount || 0,
                        })}
                    </Text>
                </View>
            </View>
        </DetailsBlock>
    );
};
export default MatchingDetailsBlock;
