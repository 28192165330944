import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        shadow: {
            borderRadius: 1000,
            backgroundColor: Colors[theme].shadow.default,
            marginRight: verticalScale(8),
        },
        disabled: { opacity: 0.5 },
        activeShadow: {
            backgroundColor: Colors[theme].shadow.selected,
        },
        fill: {
            width: scale(32),
            height: scale(32),
            borderRadius: 1000,
        },
        active: {
            borderColor: Colors[theme].inputActiveBorder,
        },
        renderItem: {
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            paddingHorizontal: scale(4),
            paddingVertical: verticalScale(4),
            marginBottom: verticalScale(2),
            borderRadius: 1000,
        },
        center: {
            flexDirection: 'row',
            justifyContent: 'center',
            backgroundColor: 'transparent',
        },
    });

export default styles;
