import * as React from 'react';
import { useEffect } from 'react';
import { StyleSheet, View } from 'react-native';

import {
    CreateMoveTaskResponse,
    CreatePlayTaskResponse,
    MarkMoveTaskDoneResponse,
    MarkPlayTaskDoneResponse,
} from '@Data/Models';
import {
    CREATE_MOVE_TASK,
    CREATE_PLAY_TASK,
    FAIL_UNCOMPLETED_SOCIAL_TASKS,
    ITERATE_GAME,
    MARK_MOVE_TASK_DONE,
    MARK_PLAY_TASK_DONE,
} from '@Data/Requests';
import { useMutation } from '@apollo/client';
import Button from '@components/Button';
import ModalLoader from '@components/ModalLoader';
import TransactionSummary from '@components/TransactionSummary';
import Colors from '@constants/Colors';
import { useUser } from '@contexts/UserContext';
import { useWallet } from '@contexts/Wallet/WalletContext';
import {
    APP_ENV,
    GRAPHQL_ENDPOINT,
    REMOTE_LOGS_ENDPOINT,
    SENTRY_DSN,
} from '@env';
import { isProduction, otaUpdateGroup } from '@helpers/app';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import { errorsHandler } from '@helpers/errors';
import { playTaskDoneParameters } from '@helpers/playTask';
import { toastErrorUnknown, toastSuccess } from '@helpers/toastNotification';
import { useLoadingHook } from '@hooks/useLoadingHook';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';
import { useNavigation } from '@navigation/useNavigation';
import { useFocusEffect } from '@react-navigation/native';

import { theme } from '../../types';
import { moveTaskDoneParameters } from '../TabBar/Play/MoveToEarn/helpers/moveHelper';
import { useDevM2EP2EEarn } from './hooks/useDevM2EP2EEarn';

const DevScreen: React.FunctionComponent = () => {
    const { clearWallet } = useWallet();
    const { isAdmin } = useUser();
    const styles = useThemedStyles(stylesMain);
    const { reloadUser, user } = useUser();
    const navigation = useNavigation();
    const hasAccess = isAdmin || !isProduction();

    const [iterateGame, { loading: gameIterationLoading }] = useMutation(
        ITERATE_GAME,
        {
            onCompleted: (data) => Console.log('[ITERATE_GAME]', data),
            onError: (error) => {
                toastErrorUnknown();
                Console.log('[ITERATE_GAME]', error);
            },
        }
    );

    const [
        failUncompletedSocialTasks,
        { loading: failUncompletedSocialTaskLoading },
    ] = useMutation(FAIL_UNCOMPLETED_SOCIAL_TASKS, {
        onCompleted: (data) =>
            Console.log('[FAIL_UNCOMPLETED_SOCIAL_TASKS]', data),
        onError: (error) => {
            toastErrorUnknown();
            Console.log('[FAIL_UNCOMPLETED_SOCIAL_TASKS]', error);
        },
    });

    const devSummary = [
        {
            key: 'APP_ENV',
            value: APP_ENV,
        },
        {
            key: 'GRAPHQL_ENDPOINT',
            value: GRAPHQL_ENDPOINT,
        },
        {
            key: 'SENTRY_DSN',
            value: SENTRY_DSN,
        },
        REMOTE_LOGS_ENDPOINT
            ? {
                  key: 'REMOTE_LOGS_ENDPOINT',
                  value: REMOTE_LOGS_ENDPOINT,
              }
            : undefined,
        otaUpdateGroup
            ? {
                  key: 'OTA Update group',
                  value: otaUpdateGroup,
              }
            : undefined,
        {
            key: 'User ID',
            value: user?.id,
        },
        {
            key: 'User Role',
            value: user?.role,
        },
    ];

    useFocusEffect(
        React.useCallback(() => {
            reloadUser();
        }, [])
    );

    useEffect(() => {
        if (!hasAccess) {
            navigation.navigateToPlay();
        }
    }, [hasAccess]);

    if (!hasAccess) {
        return null;
    }

    // TO GET MAX ENERGY FOR M2E, P2E
    const { isMoveLoading, isPlayLoading, earnMoveEnergy, earnPlayEnergy } =
        useDevM2EP2EEarn();

    return (
        <View style={styles.container}>
            <NavigationBar title={'Dev'} backIcon={'leftArrow'} />
            <View style={styles.page}>
                <View>
                    <TransactionSummary
                        values={devSummary}
                        containerStyle={styles.devSummary}
                    />
                </View>
                <Button
                    title={'Earn Energy Move'}
                    disabled={isMoveLoading}
                    loading={isMoveLoading}
                    debouncedPress
                    onPress={earnMoveEnergy}
                    containerStyle={styles.buttonGap}
                />
                <Button
                    title={'Earn Energy Play'}
                    onPress={earnPlayEnergy}
                    disabled={isPlayLoading}
                    loading={isPlayLoading}
                    containerStyle={styles.buttonGap}
                />

                <Button
                    title={'Clear wallet'}
                    onPress={() => {
                        clearWallet();
                    }}
                    containerStyle={styles.buttonGap}
                />
                <Button
                    title={'Iterate Game'}
                    onPress={async () => {
                        await iterateGame();
                        toastSuccess();
                    }}
                    containerStyle={styles.buttonGap}
                />
                <Button
                    title={'Fail Uncompleted Social Tasks'}
                    onPress={async () => {
                        await failUncompletedSocialTasks();
                        toastSuccess();
                    }}
                    containerStyle={styles.buttonGap}
                />

                <ModalLoader
                    text={i18n.t('general.loading')}
                    isVisible={gameIterationLoading}
                />

                <ModalLoader
                    text={i18n.t('general.loading')}
                    isVisible={failUncompletedSocialTaskLoading}
                />
            </View>
        </View>
    );
};

const stylesMain = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: Colors[theme].background,
        },
        page: {
            flex: 1,
            padding: scale(20),
        },
        buttonGap: {
            marginBottom: verticalScale(20),
        },
        devSummary: {
            borderWidth: 1,
            padding: verticalScale(10),
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(10),
            marginBottom: verticalScale(20),
        },
    });

export default DevScreen;
