import * as React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import ModalBottom from '../../../../../components/ModalBottom';
import i18n from '../../../../../i18n/i18n';
import { TELEGRAM_CHATS } from '../../socials';
import LinkItem from '../LinkItem';

interface IProps {
    visible: boolean;
    onClose: () => void;
}

const TelegramExtendedModal = ({ visible, onClose }: IProps) => {
    return (
        <ModalBottom
            isVisible={visible}
            onClose={onClose}
            modalHeight={700}
            titleText={i18n.t('profile.socialMediaSettings.telegram.title')}>
            <ScrollView showsVerticalScrollIndicator={false}>
                <TouchableOpacity activeOpacity={1}>
                    {TELEGRAM_CHATS.map((item) => (
                        <LinkItem key={item.id} item={item} type="telegram" />
                    ))}
                </TouchableOpacity>
            </ScrollView>
        </ModalBottom>
    );
};

export default TelegramExtendedModal;
