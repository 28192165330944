import { GET_MARKETPLACE_SALEABLE_PETS } from '@Data/Requests';
import { useLazyQuery } from '@apollo/client';
import { MarketplaceSaleablePetOutputResponse } from '@models/marketplace';

export const useMarketplaceSaleablePets = () => {
    const [
        getMarketplaceSaleablePets,
        {
            data: dataMarketplaceSaleablePets,
            loading: loadingMarketplaceSaleablePets,
        },
    ] = useLazyQuery<MarketplaceSaleablePetOutputResponse>(
        GET_MARKETPLACE_SALEABLE_PETS,
        {
            fetchPolicy: 'no-cache',
        }
    );

    return {
        getMarketplaceSaleablePets,
        dataMarketplaceSaleablePets,
        loadingMarketplaceSaleablePets,
    };
};
