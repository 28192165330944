import * as React from 'react';
import { StyleProp, Text, View, ViewStyle } from 'react-native';

import useThemedStyles from '../../hooks/useThemedStyles';
import Icon, { ICON_NAMES } from '../Icons';
import stylesMain from './styles';

interface IProps {
    ageDays: number;
    maxAgeDays: number;
    containerStyle?: StyleProp<ViewStyle>;
    isOneColor?: boolean;
}

const AgeIndicator = ({
    ageDays,
    maxAgeDays,
    containerStyle,
    isOneColor,
}: IProps) => {
    const styles = useThemedStyles(stylesMain, isOneColor);

    return (
        <View style={[styles.badgeWrapper, containerStyle]}>
            <Icon name={ICON_NAMES.IS_AGE_DEAD} size={20} />
            <Text style={styles.badgeText}>{`${ageDays} / ${maxAgeDays}`}</Text>
        </View>
    );
};
export default AgeIndicator;
