import * as React from 'react';
import { ScrollView } from 'react-native';

import ButtonsBottom from '@components/ButtonsBottom';
import { View } from '@components/Themed';
import { toastErrorUnknown } from '@helpers/toastNotification';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';
import NavigationBar from '@navigation/NavigationBar';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import { QuantumSpinGameHistoryProps } from '../../../../../types';
import QuantumSpinPrizeInfo from '../../components/QuantumSpinPrizeInfo';
import stylesMain from './styles';

const game = MYSTERY_GAMES.quantumSpin;
const flow = 'QuantumSpinGameHistory';

const QuantumSpinGameHistoryScreen = ({
    route,
}: QuantumSpinGameHistoryProps) => {
    const styles = useThemedStyles(stylesMain);
    const { data } = route.params;
    const navigation = useNavigation();

    Console.info(data);

    React.useEffect(() => {
        if (!data) {
            navigation.pop();
            toastErrorUnknown();
        }
    }, [data]);

    const onPressSpinAgain = () => {
        navigation.navigate(ROUTES.QUANTUM_SPIN_MAIN);
    };

    return (
        <View style={styles.container}>
            <NavigationBar
                filled={false}
                backIcon
                title={i18n.t('HomeScreen.GamesNavigationBar.HISTORY')}
            />
            <ScrollView
                showsVerticalScrollIndicator={false}
                contentContainerStyle={styles.content}>
                <QuantumSpinPrizeInfo data={data} withProvably />
            </ScrollView>
            <ButtonsBottom
                safe
                onPress={onPressSpinAgain}
                title={i18n.t(`HomeScreen.${game}.${flow}.again`)}
            />
        </View>
    );
};

export default QuantumSpinGameHistoryScreen;
