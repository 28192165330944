import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            backgroundColor: Colors[theme].controlSecondary,
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            borderStyle: 'dashed',
        },
        plus: {
            color: Colors[theme].icon.green,
        },
    });

export default styles;
