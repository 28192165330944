import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';
import { stylesContainerWithBorderDefault } from '@helpers/style';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            marginTop: verticalScale(16),
            paddingHorizontal: scale(20),
        },
        info: {
            ...stylesContainerWithBorderDefault(theme),
            marginTop: verticalScale(20),
        },

        gap: {
            marginTop: verticalScale(16),
        },
    });

export default styles;
