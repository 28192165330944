import React from 'react';

import * as Haptics from 'expo-haptics';

import { useAccessibilitySettings } from '../contexts/AccessibilitySettingsContext';
import { isWeb } from '../helpers/app';

export const useHaptic = () => {
    const { isHapticDisabled } = useAccessibilitySettings();
    const isDisabled = React.useMemo(
        () => isWeb || isHapticDisabled,
        [isHapticDisabled]
    );

    const triggerLight = React.useCallback(async () => {
        if (isDisabled) {
            return undefined;
        }

        return Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    }, [isDisabled]);

    const triggerMedium = React.useCallback(async () => {
        if (isDisabled) {
            return undefined;
        }

        return Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
    }, [isDisabled]);

    const triggerHeavy = React.useCallback(async () => {
        if (isDisabled) {
            return undefined;
        }

        return Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy);
    }, [isDisabled]);

    return {
        triggerLight,
        triggerMedium,
        triggerHeavy,
    };
};
