import {
    NFT_ROYAL_GAME_EVENT,
    NftRoyaleGameplayConfigOutput,
} from '@models/mysteryGames';

export const serializeGameConfig = (
    gameConfig: NftRoyaleGameplayConfigOutput
) => {
    const {
        scorePerFruit,
        scorePerMeter,
        maxLives: lives,
        difficulty: difficult,
        petAcceleration: acceleration,
        petJumpHeight: jumpHeight,
        petJumpDuration: jumpDuration,
        petMoveSpeedAtMaxDifficult: moveSpeedAtMaxDifficult,
        petMoveSpeedAtMinDifficult: moveSpeedAtMinDifficult,
        petInvulnerabilityDuration: invulnerabilityDuration,
        obstacleMaxGapDistanceAtMaxDifficult,
        obstacleMaxGapDistanceAtMinDifficult,
        obstacleMinGapDistanceAtMaxDifficult,
        obstacleMinGapDistanceAtMinDifficult,
        fruitMaxGapDistanceAtMaxDifficult,
        fruitMaxGapDistanceAtMinDifficult,
        fruitMinGapDistanceAtMaxDifficult,
        fruitMinGapDistanceAtMinDifficult,
    } = gameConfig;

    const settings = {
        enableAudio: false,
        sandbox: false,
        hideMaxScore: true,
        showPause: false,
        showStats: false,
    };

    const petData = {
        acceleration,
        jumpHeight,
        jumpDuration,
        moveSpeedAtMaxDifficult,
        moveSpeedAtMinDifficult,
        invulnerabilityDuration,
        viewOrgin: {
            x: 0.0,
            y: 0.0,
        },
        viewUniformScale: 1.35,
    };

    const obstacles = {
        key: 'Obstacles',
        orgin: {
            x: 20.0,
            y: 0.0,
        },
        gapAtMinDifficult: {
            minGapDistance: obstacleMinGapDistanceAtMinDifficult,
            maxGapDistance: obstacleMaxGapDistanceAtMinDifficult,
        },
        gapAtMaxDifficult: {
            minGapDistance: obstacleMinGapDistanceAtMaxDifficult,
            maxGapDistance: obstacleMaxGapDistanceAtMaxDifficult,
        },
    };
    const fruits = {
        key: 'Fruits',
        orgin: {
            x: 12.0,
            y: 3.0,
        },
        gapAtMinDifficult: {
            minGapDistance: fruitMinGapDistanceAtMinDifficult,
            maxGapDistance: fruitMaxGapDistanceAtMinDifficult,
        },
        gapAtMaxDifficult: {
            minGapDistance: fruitMinGapDistanceAtMaxDifficult,
            maxGapDistance: fruitMaxGapDistanceAtMaxDifficult,
        },
    };

    const lineDatas = [obstacles, fruits];

    return {
        ...settings,
        scorePerFruit,
        scorePerMeter,
        lives,
        difficult,
        petData,
        lineDatas,
    };
};

export const eventData = (event: NFT_ROYAL_GAME_EVENT, message?: any) => {
    if (!message) {
        return JSON.stringify({
            type: event,
            distanceMeters: 0,
            speedMetersPerSecond: 0,
            fruitsCollected: 0,
            date: new Date(),
        });
    }
    return JSON.stringify({
        type: event,
        ...message,
        date: new Date(),
    });
};
