import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            backgroundColor: 'transparent',
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(20),
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
        },
        header: {
            flexDirection: 'row',
            backgroundColor: 'transparent',
            justifyContent: 'space-between',
            marginBottom: verticalScale(16),
        },
        headerText: {
            color: Colors[theme].text.onLight,
            ...font({
                size: 14,
                height: 21,
                weight: '400',
            }),
            letterSpacing: 0.08,
        },
        item: {
            backgroundColor: 'transparent',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: verticalScale(24),
        },
        isLast: {
            marginBottom: verticalScale(0),
        },

        rankColumn: {
            backgroundColor: 'transparent',
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
            marginRight: scale(12),
        },
        chanceColumn: {
            backgroundColor: 'transparent',
        },
    });

export default styles;
