import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {},
        label: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
            marginBottom: verticalScale(6),
        },
        selectContainer: {
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(12),
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: Colors[theme].controlSecondary,
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: 'transparent',
        },
        errorBorder: {
            borderColor: Colors[theme].incorrect,
        },
        left: {
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: scale(16),
            flex: 1,
            backgroundColor: 'transparent',
        },
        item: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 23,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },

        placeHolder: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 15,
                height: 23,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
        dropDownColor: {
            color: Colors[theme].icon.secondary,
        },

        error: {
            marginTop: verticalScale(4),
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
            color: Colors[theme].incorrect,
        },
        loading: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 100,
            left: scale(16),
            backgroundColor: Colors[theme].controlSecondary,
        },
    });

export default styles;
