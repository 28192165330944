import React from 'react';

import ModalNftToPlayInfo from '../components/ModalNftToPlayInfo';
import ROUTES from '../navigation/routes';
import { useNavigation } from '../navigation/useNavigation';

const useShowPetOrderedIdModal = () => {
    const navigation = useNavigation();

    const [showPetOderIdModal, setShowPetOrderIdModal] =
        React.useState<boolean>(false);

    const showOrderModal = () => {
        setShowPetOrderIdModal(true);
    };

    const onCloseShowOrderModal = () => {
        setShowPetOrderIdModal(false);
    };

    const onActionShowOrderModal = () => {
        onCloseShowOrderModal();
        navigation.navigate(ROUTES.PLAY_EDIT_NFTS);
    };

    const renderOrderModal = React.useCallback(() => {
        return (
            <ModalNftToPlayInfo
                isVisible={showPetOderIdModal}
                onClose={onCloseShowOrderModal}
                onAction={onActionShowOrderModal}
            />
        );
    }, [showPetOderIdModal]);

    return {
        renderOrderModal,
        showOrderModal,
    };
};

export default useShowPetOrderedIdModal;
