import React from 'react';
import { ScrollView, View } from 'react-native';

import { isAndroid } from '@helpers/app';

import WhatIsHealthIcon from '../../assets/icons/health/whatIsHealth.svg';
import useThemedStyles from '../../hooks/useThemedStyles';
import i18n from '../../i18n/i18n';
import BlockHeader from '../BlockHeader/BlockHeader';
import ButtonsBottom from '../ButtonsBottom';
import CardFeatureInfo from '../CardFeatureInfo/CardFeatureInfo';
import CoinIcon from '../Icons/CoinIcon';
import ModalBottom from '../ModalBottom';
import mainStyles from './styles';

interface IProps {
    isVisible: boolean;
    closeModal: () => void;
    onPress?: () => void;
    showButton: boolean;
}

const ModalWhatIsHealth = ({
    isVisible,
    closeModal,
    onPress,
    showButton,
}: IProps) => {
    const styles = useThemedStyles(mainStyles);

    const handleFeed = () => {
        closeModal();
        onPress?.();
    };

    return (
        <ModalBottom
            modalHeight="700"
            isVisible={isVisible}
            onClose={closeModal}>
            <ScrollView style={styles.container}>
                <View style={styles.imgWrapper}>
                    <WhatIsHealthIcon />
                </View>

                <BlockHeader
                    containerStyle={styles.blockHeader}
                    type="emptyState"
                    title={i18n.t('detailedPet.health.modalWhatIsHealth.title')}
                    text={i18n.t('detailedPet.health.modalWhatIsHealth.text')}
                />
                <CardFeatureInfo
                    containerStyle={styles.cardFeatureInfo}
                    title={i18n.t(
                        'detailedPet.health.modalWhatIsHealth.card.title'
                    )}
                    text={i18n.t(
                        'detailedPet.health.modalWhatIsHealth.card.text'
                    )}
                    icon={<CoinIcon slug="VIGUP" size={44} />}
                />
            </ScrollView>
            {showButton && (
                <ButtonsBottom
                    onPress={handleFeed}
                    title={i18n.t('detailedPet.health.buttonFeed')}
                    safe={!isAndroid}
                />
            )}
        </ModalBottom>
    );
};
export default ModalWhatIsHealth;
