import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { ICON_NAMES } from '@components/Icons';
import { useDimensions } from '@contexts/DimensionsContext';
import { verticalScale } from '@helpers/dimensions';

import useThemedStyles from '../../hooks/useThemedStyles';
import Button, { ButtonType } from '../Button';
import { View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    title: string;
    onPress: () => void;
    disabled?: boolean;
    containerStyle?: StyleProp<ViewStyle>;
    children?: React.ReactNode;
    safe?: boolean;
    loading?: boolean;
    loadingText?: string;
    withLoadingText?: boolean;
    buttonType?: ButtonType;
    tailTitle?: boolean;
    iconName?: ICON_NAMES | null | undefined;
    iconDirection?: 'left' | 'right';
    iconColor?: string | null;
    isOneColor?: boolean;
    buttonStyle?: StyleProp<ViewStyle>;
}

const ButtonsBottom = ({
    title,
    onPress,
    disabled,
    containerStyle,
    children,
    safe = false,
    loading,
    loadingText,
    withLoadingText,
    buttonType,
    tailTitle,
    iconName,
    iconDirection,
    iconColor,
    isOneColor,
    buttonStyle,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { safeBottom } = useDimensions();

    return (
        <View
            style={[
                styles.buttonWrapper,
                containerStyle,
                safe && {
                    paddingBottom: verticalScale(
                        safeBottom + verticalScale(16)
                    ),
                },
            ]}>
            <Button
                containerStyle={buttonStyle}
                isOneColor={isOneColor}
                iconDirection={iconDirection}
                iconName={iconName}
                iconColor={iconColor}
                tailTitle={tailTitle}
                type={buttonType}
                onPress={onPress}
                debouncedPress
                title={title}
                disabled={disabled}
                loading={loading}
                loadingText={loadingText}
                withLoadingText={withLoadingText}
            />
            {!!children && children}
        </View>
    );
};
export default ButtonsBottom;
