import dayjs from 'dayjs';
import * as Localization from 'expo-localization';
import { I18n } from 'i18n-js';

import AsyncStorage from '@react-native-async-storage/async-storage';

import en from './en';
import es from './es';
import id from './id';
import pl from './pl';
import ru from './ru';
import tr from './tr';
import uk from './uk';

require('dayjs/locale/en');
require('dayjs/locale/es');
require('dayjs/locale/id');
require('dayjs/locale/pl');
require('dayjs/locale/ru');
require('dayjs/locale/uk');
require('dayjs/locale/tr');

const i18n = new I18n({
    en,
    es,
    id,
    pl,
    ru,
    uk,
    tr,
});

export const LANGUAGES = ['en', 'es', 'id', 'pl', 'ru', 'uk', 'tr'];

export const I18N_STORAGE_KEY = 'locale';

// get language from local storage or expo-locale
export async function getLanguage() {
    const locale =
        (await AsyncStorage.getItem(I18N_STORAGE_KEY)) || Localization.locale;
    return locale.split('-')[0] || 'en';
}

export async function setLanguage(locale?: string) {
    // if there is parameter then set it, otherwise get from local storage
    i18n.locale = locale ?? (await getLanguage());
    dayjs.locale(i18n.locale);
    i18n.enableFallback = true;
    // save choice to local storage
    if (locale) {
        AsyncStorage.setItem(I18N_STORAGE_KEY, locale);
    }
}

export default i18n;
