import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        itemContainer: {
            height: scale(102),
            marginTop: verticalScale(12),
            marginHorizontal: scale(20),
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(12),
            backgroundColor: Colors[theme].controlSecondary,
            borderRadius: moderateScale(12),
            borderColor: Colors[theme].border.primary,
            borderWidth: 1,
        },
        itemFocusedContainer: {
            height: scale(102),
            marginTop: verticalScale(12),
            marginHorizontal: scale(20),
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(12),
            backgroundColor: Colors[theme].controlSecondary,
            borderRadius: moderateScale(12),
            borderColor: Colors[theme].inputActiveBorder,
            borderWidth: 1,
        },
        itemDangerContainer: {
            height: scale(102),
            marginTop: verticalScale(12),
            marginHorizontal: scale(20),
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(12),
            backgroundColor: Colors[theme].controlSecondary,
            borderRadius: moderateScale(12),
            borderColor: Colors[theme].border.critical,
            borderWidth: 1,
        },
        selectAvailable: {
            paddingTop: verticalScale(6),
            paddingBottom: verticalScale(12),
            color: Colors[theme].text.primary,
            backgroundColor: Colors[theme].inputBackground,

            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            zIndex: 2,
        },
        availableButtonContainer: {
            backgroundColor: Colors[theme].controlSecondary,
            height: verticalScale(15),
        },
        availableButtonBody: {
            backgroundColor: Colors[theme].controlSecondary,
            borderColor: Colors[theme].controlSecondary,
            paddingHorizontal: 0,
            width: '100%',
            height: verticalScale(40),
        },
        secondLabel: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'flex-end',
            backgroundColor: Colors[theme].controlSecondary,
            marginTop: verticalScale(-25),
        },
        secondLabelAvailableText: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                weight: '400',
            }),
        },
        secondLabelAmountText: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                weight: '600',
            }),
        },
        selectToken: {
            position: 'absolute',
            top: verticalScale(3),
            right: scale(0),
            zIndex: 5,
        },
        valueStyle: {
            position: 'absolute',
            top: verticalScale(32),
            left: scale(0),
            zIndex: 5,
            color: Colors[theme].text.secondary,
        },
        selectButton: {
            backgroundColor: 'transparent',
            flexDirection: 'row',
            alignItems: 'center',
        },
        coinTitle: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '700',
            }),
            marginHorizontal: scale(8),
        },
        icon: {
            color: Colors[theme].icon.secondary,
        },
    });

export default styles;
