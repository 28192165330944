import * as React from 'react';
import { useState } from 'react';
import TooltipGuideRN from 'react-native-walkthrough-tooltip';

import { isString } from 'lodash';

import { useAppLoading } from '@contexts/AppLoading/AppLoadingContext';
import { scale } from '@helpers/dimensions';
import { useShowBanner } from '@hooks/useShowBanner';

import useThemedStyles from '../../hooks/useThemedStyles';
import { ComponentAnyType } from '../../types';
import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    children: React.PropsWithChildren<unknown>['children'];
    content: string | ComponentAnyType;
    timeout?: number;
    placement?: 'top' | 'bottom';
    showKey: string;
    shouldShow?: boolean;
}

const TooltipGuide: React.FC<IProps> = ({
    children,
    content = '',
    timeout = 0,
    placement = 'top',
    showKey,
    shouldShow = true,
}) => {
    const [isVisible, setIsVisible] = useState<boolean>(shouldShow);
    const styles = useThemedStyles(stylesMain);
    const { isSplashAnimationComplete } = useAppLoading();
    const { canShowBanner, hideBanner, showBanner } = useShowBanner({
        name: showKey,
        type: 'custom',
        perUser: true,
    });

    const onClose = () => {
        setIsVisible(false);
        hideBanner();
    };

    const [width, setWidth] = React.useState<number>(0);

    React.useEffect(() => {
        let isMounted = true;
        let timer: NodeJS.Timeout;

        if (!isVisible || !isSplashAnimationComplete) {
            return;
        }

        if (isVisible) {
            showBanner();
        }

        if (timeout) {
            timer = setTimeout(() => {
                if (isMounted) onClose();
            }, timeout);
        }

        return () => {
            isMounted = false;
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [isVisible, timeout, isSplashAnimationComplete]);

    return (
        <TooltipGuideRN
            isVisible={canShowBanner && isVisible}
            backgroundColor="transparent"
            placement={placement}
            contentStyle={[
                styles.webShadow,
                {
                    width: width + scale(12) * 2 + 2,
                    height: 10,
                    flex: 1,
                },
            ]}
            arrowStyle={styles.arrow}
            showChildInTooltip={false}
            disableShadow>
            <TooltipGuideRN
                isVisible={canShowBanner && isVisible}
                onClose={onClose}
                content={
                    isString(content) ? (
                        <Text
                            style={styles.contentText}
                            onLayout={(event) =>
                                setWidth(event.nativeEvent.layout.width)
                            }>
                            {content}
                        </Text>
                    ) : (
                        <View
                            style={styles.contentView}
                            onLayout={(event) =>
                                setWidth(event.nativeEvent.layout.width)
                            }>
                            {content}
                        </View>
                    )
                }
                placement={placement}
                contentStyle={[styles.content, { width: 'auto' }]}
                arrowStyle={styles.arrow}
                childContentSpacing={7}
                showChildInTooltip={false}
                disableShadow>
                {children}
            </TooltipGuideRN>
        </TooltipGuideRN>
    );
};

export default TooltipGuide;
