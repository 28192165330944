import * as React from 'react';

import CheckoutSuccessScreen from '@components/CheckoutSuccessScreen';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import { UnStakingSuccessProps } from '../../../../types';
import { useUnStakingSuccessScreen } from './hook';
import stylesMain from './styles';

const UnStakingSuccessScreen = ({ route }: UnStakingSuccessProps) => {
    const styles = useThemedStyles(stylesMain);

    const { handleBackToWallet, transactionResult } = useUnStakingSuccessScreen(
        route.params.transactionResult
    );
    return (
        <CheckoutSuccessScreen
            title={i18n.t('staking.successUnStakeScreen.title')}
            text={i18n.t('staking.successUnStakeScreen.text')}
            onPressButtonMain={handleBackToWallet}
            buttonMainText={i18n.t('staking.successUnStakeScreen.button')}
            transactionDetailsStyle={styles.container}
            explorerUrl={transactionResult.explorerUrl}
        />
    );
};

export default UnStakingSuccessScreen;
