import { useLazyQuery } from '@apollo/client';

import { DonationsHistoryResponse } from '../Data/Models';
import { GET_DONATIONS_HISTORY } from '../Data/Requests';
import { toastErrorUnknown } from '../helpers/toastNotification';

interface IProps {
    page: number;
    limit: number;
    charityId: string;
    onCompleted: (donationsHistory: DonationsHistoryResponse) => void;
    onError?: () => void;
}

const useGetDonationsHistory = () => {
    const [getHistory] = useLazyQuery<DonationsHistoryResponse>(
        GET_DONATIONS_HISTORY
    );

    const getDonationsHistory = ({
        page,
        limit,
        charityId,
        onCompleted,
        onError = () => {},
    }: IProps) => {
        getHistory({
            fetchPolicy: 'network-only',
            variables: {
                charityId: charityId,
                paginationInput: {
                    page,
                    limit,
                },
            },
            onError: (error) => {
                Console.error(error);
                onError();
                toastErrorUnknown();
            },
            onCompleted: (donationsHistory) => {
                onCompleted(donationsHistory);
            },
        });
    };

    return getDonationsHistory;
};

export default useGetDonationsHistory;
