import * as React from 'react';
import { Animated } from 'react-native';

import useThemedStyles from '../../hooks/useThemedStyles';
import i18n from '../../i18n/i18n';
import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    fasterLoad?: boolean | false;
    onLoadingLineStop?: () => void;
    onRestart?: () => void;
    ordinaryInterval?: number;
    fasterInterval?: number;
    stopCount?: number | undefined;
    finishCount?: number;
}

// loader config
const config = {
    finishCount: 100,
    stopCount: 80,
    fasterInterval: 5,
    ordinaryInterval: 200,
};

const LinearLoader = ({
    fasterLoad,
    onLoadingLineStop,
    onRestart,
    finishCount = config.finishCount,
    stopCount = config.stopCount,
    fasterInterval = config.fasterInterval,
    ordinaryInterval = config.ordinaryInterval,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const [count, setCount] = React.useState<number>(0);

    const countInterval = React.useRef<any>(null);
    const counter = React.useRef(new Animated.Value(0)).current;
    const interval = fasterLoad ? fasterInterval : ordinaryInterval;

    React.useEffect(() => {
        countInterval.current = setInterval(
            () => setCount((prev) => Math.min(prev + 1, 100)),
            interval
        );
        return () => {
            clearInterval(countInterval.current); // when user exits, clear this interval.
        };
    }, [fasterLoad, interval]);

    const load = (count: any) => {
        Animated.timing(counter, {
            toValue: count, // final value
            duration: interval,
            useNativeDriver: false,
        }).start();
    };

    React.useEffect(() => {
        load(count);
        if (stopCount && !fasterLoad && count >= stopCount) {
            // if loading is very slow - stop interval
            clearInterval(countInterval.current);
            onRestart?.();
        } else if (count >= finishCount) {
            onLoadingLineStop?.();
            clearInterval(countInterval.current);
        }
    }, [count, finishCount, stopCount, fasterLoad]);

    const maxWidth = counter.interpolate({
        inputRange: [0, 100],
        outputRange: ['0%', '100%'],
        extrapolate: 'clamp',
    });

    return (
        <View style={styles.container}>
            <Text style={styles.text}>{`${i18n.t(
                'general.loading'
            )} ${count}%`}</Text>
            <View style={styles.progress}>
                <Animated.View style={[styles.fill, { maxWidth }]} />
            </View>
        </View>
    );
};
export default LinearLoader;
