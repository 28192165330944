import * as React from 'react';

import { EnableFaceIdScreen } from '../../screens/Wallet/Settings/WalletPasscode/EnableFaceId/EnableFaceIdScreen';
import { PassCodeChangeScreen } from '../../screens/Wallet/Settings/WalletPasscode/PassCodeChange/PassCodeChange';
import { PassCodeConfirmScreen } from '../../screens/Wallet/Settings/WalletPasscode/PassCodeConfirm/PassCodeConfirmScreen';
import { PassCodeNewScreen } from '../../screens/Wallet/Settings/WalletPasscode/PassCodeNew/PassCodeNewScreen';
import { PassCodeSuccessScreen } from '../../screens/Wallet/Settings/WalletPasscode/PassCodeSuccess/PassCodeSuccessScreen';
import { PassCodeWelcomeScreen } from '../../screens/Wallet/Settings/WalletPasscode/PassCodeWelcome/PassCodeWelcomeScreen';
import { PassCodeSettingsScreen } from '../../screens/Wallet/Settings/WalletPasscode/PasscodeSettings/PassCodeSettingsScreen';
import ROUTES from '../routes';

const PassCodeGroup = (RootStack: any) => {
    return (
        <>
            <RootStack.Screen
                name={ROUTES.PASSCODE_WELCOME}
                component={PassCodeWelcomeScreen}
            />
            <RootStack.Screen
                name={ROUTES.PASSCODE_NEW}
                component={PassCodeNewScreen}
            />
            <RootStack.Screen
                name={ROUTES.PASSCODE_CHANGE}
                component={PassCodeChangeScreen}
            />
            <RootStack.Screen
                name={ROUTES.PASSCODE_CONFIRM}
                component={PassCodeConfirmScreen}
            />
            <RootStack.Screen
                name={ROUTES.PASSCODE_FACEID}
                component={EnableFaceIdScreen}
            />
            <RootStack.Screen
                name={ROUTES.PASSCODE_SUCCESS}
                options={{ gestureEnabled: false }}
                component={PassCodeSuccessScreen}
            />

            <RootStack.Screen
                name={ROUTES.PASSCODE_SETTINGS}
                component={PassCodeSettingsScreen}
            />
        </>
    );
};

export default PassCodeGroup;
