import * as React from 'react';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';

import DropdownText from '@components/DropdownText';
import HowItWorks from '@components/HowItWorks';
import Colors from '@constants/Colors';
import { useTheme } from '@contexts/ThemeContext';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import { ComponentAnyType } from '../../types';
import Button from '../Button';
import Icon, { ICON_NAMES } from '../Icons';
import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    type: 'primary' | 'emptyState';
    title?: string;
    text?: string;
    additionalText?: string;
    textInfo?: string;
    additionalTitle?: string;
    containerStyle?: StyleProp<ViewStyle>;
    textInfoOnPress?: () => void;
    onAdditionalButtonPress?: () => void;
    disabled?: boolean;
    iconName?: ICON_NAMES;
    iconSize?: number;
    iconDirection?: 'right' | 'left';
    emptyStateIcon?: React.ReactElement<SvgProps> | ComponentAnyType;
    titleColor?: string;
    titleComponent?: ComponentAnyType;
    textComponent?: ComponentAnyType;
    additionalComponent?: ComponentAnyType;
    loading?: boolean;
    titleContainerStyle?: StyleProp<ViewStyle>;
    titleStyle?: StyleProp<TextStyle>;
    textStyle?: StyleProp<TextStyle>;
    isOnlyWhite?: boolean;
    componentBetweenImageAndText?: React.ReactNode;
    dropDownText?: string;
}

const BlockHeader = ({
    title,
    text,
    containerStyle,
    textInfo,
    additionalTitle,
    textInfoOnPress,
    type = 'primary',
    onAdditionalButtonPress,
    disabled = false,
    additionalText,
    iconName = ICON_NAMES.QUESTION,
    iconSize = 24,
    iconDirection = 'left',
    emptyStateIcon,
    titleColor,
    titleComponent,
    textComponent,
    loading,
    additionalComponent,
    titleContainerStyle,
    titleStyle,
    textStyle,
    isOnlyWhite,
    componentBetweenImageAndText,
    dropDownText,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { theme } = useTheme();

    const typeStyles = React.useMemo(() => {
        switch (type) {
            case 'emptyState':
                return {
                    title: styles.titleEmptyState,
                    text: styles.textEmptyState,
                };

            default:
                return {
                    title: styles.titlePrimary,
                    text: styles.textPrimary,
                };
        }
    }, [type, styles]);

    const isAdditionalButton = React.useMemo(
        () => !!onAdditionalButtonPress,
        [onAdditionalButtonPress]
    );

    return (
        <>
            {!!emptyStateIcon && type === 'emptyState' && (
                <View style={styles.image}>{emptyStateIcon}</View>
            )}
            {componentBetweenImageAndText}
            <View style={[styles.transparent, containerStyle]}>
                <View
                    style={[
                        styles.titleContainer,
                        isAdditionalButton && styles.titleInRow,
                        titleContainerStyle,
                    ]}>
                    <Text
                        style={[
                            typeStyles.title,
                            titleColor && { color: titleColor },
                            isOnlyWhite && styles.isOnlyWhite,
                            titleStyle,
                        ]}>
                        {titleComponent || title}
                        {!!additionalTitle && (
                            <Text style={styles.additionalTitle}>
                                {additionalTitle}
                            </Text>
                        )}
                    </Text>
                    {isAdditionalButton && (
                        <Button
                            size="md"
                            type="fill"
                            icon={
                                <Icon
                                    name={ICON_NAMES.PLUS}
                                    color={Colors[theme].text.onDark}
                                />
                            }
                            debouncedPress
                            loading={loading}
                            disabled={disabled}
                            style={styles.buttonMint}
                            onPress={onAdditionalButtonPress}
                        />
                    )}
                    {!!additionalComponent && additionalComponent}
                </View>
                {!!dropDownText && <DropdownText text={dropDownText} />}
                {(!!text || !!textComponent) && (
                    <Text
                        style={[
                            typeStyles.text,
                            isOnlyWhite && styles.isOnlyWhite,

                            textStyle,
                        ]}>
                        {textComponent || text}
                        {!!additionalText && (
                            <Text style={styles.additionalText}>
                                {additionalText}
                            </Text>
                        )}
                    </Text>
                )}
                {!!textInfoOnPress && (
                    <HowItWorks
                        onPress={textInfoOnPress}
                        textInfo={textInfo}
                        iconName={iconName}
                        iconSize={iconSize}
                        iconDirection={iconDirection}
                    />
                )}
            </View>
        </>
    );
};

export default BlockHeader;
