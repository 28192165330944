import { useCallback } from 'react';

import { useGamingWallet } from '@contexts/GamingWalletContext';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { errorsHandler } from '@helpers/errors';
import { isGraphqlError } from '@helpers/helpers';
import { toastError } from '@helpers/toastNotification';
import useBlockchainErrorsHandler from '@hooks/useBlockchainErrorsHandler';
import { useLoadingHook } from '@hooks/useLoadingHook';
import { useGamingBalances } from '@hooks/wallet/useGamingBalances';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

export const useWithdrawIgu = (modalClose: () => void) => {
    const navigation = useNavigation();

    const { isPendingWithdraw } = useGamingWallet();

    const { withdrawIgu, getWithdrawIguPendingBalance } = useGamingBalances();

    const { withdrawIGU } = useWallet();

    const {
        isLoading: withdrawLoading,
        startLoading: startWithdrawLoading,
        stopLoading: stopWithdrawLoading,
    } = useLoadingHook();

    const { handleBlockchainErrors, renderBlockchainErrorsModal } =
        useBlockchainErrorsHandler();

    const pendingWithdraw = async () => {
        const result = await getWithdrawIguPendingBalance();
        return result.data?.iguPendingWithdraw?.signedTransaction;
    };
    const normalWithdraw = async (amount: string) => {
        const result = await withdrawIgu({
            variables: {
                amount,
            },
        });
        return result.data?.withdrawIgu.signedTransaction;
    };

    const handleConfirmWithdraw = useCallback(
        async (
            amount: string,
            coin: Coin,
            commission: number,
            total: string
        ) => {
            try {
                modalClose();

                startWithdrawLoading();
                const result = isPendingWithdraw
                    ? await pendingWithdraw()
                    : await normalWithdraw(amount);

                if (result) {
                    const transactionResult = await withdrawIGU(result);
                    navigation.replace(ROUTES.WITHDRAW_IGU_SUCCESS, {
                        amount: total,
                        coin,
                        commission,
                        transactionResult,
                    });
                }
                stopWithdrawLoading();
            } catch (error) {
                errorsHandler(error, true);
                stopWithdrawLoading();
                handleBlockchainErrors(error);
                if (
                    isGraphqlError(error, 'VIRTUAL_WALLET_INSUFFICIENT_FUNDS')
                ) {
                    toastError(
                        undefined,
                        i18n.t('checkout.errors.insufficientVirtualBalance')
                    );
                }
            }
        },
        [isPendingWithdraw]
    );

    return {
        withdrawLoading,
        handleConfirmWithdraw,
        renderBlockchainErrorsModal,
    };
};
