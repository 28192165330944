import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { ICON_NAMES } from '@components/Icons';
import ModalLoader from '@components/ModalLoader';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import useCreateMysteryLikesGame from '../../hooks/useCreateMysteryLikesGame';
import useCreateNftRoyalGame from '../../hooks/useCreateNftRoyalGame';
import GamesNavigation from '../GamesNavigation';

const NftRoyalHeader = () => {
    const navigation = useNavigation();

    const handleOnInfoIconPress = () => {
        navigation.push(ROUTES.HOW_NFT_ROYAL_WORK);
    };

    const { renderPreviousGameModal, handleCreateGame, createGameLoading } =
        useCreateNftRoyalGame();

    return (
        <>
            <ModalLoader isVisible={createGameLoading} />
            <GamesNavigation
                type={MYSTERY_GAMES.nftRoyal}
                backIconName={ICON_NAMES.CLOSE}
                onPress={handleCreateGame}
                handleOnInfoIconPress={handleOnInfoIconPress}
                buttonTitle={i18n.t('general.BUTTONS.createNewGame')}
            />
            {renderPreviousGameModal()}
        </>
    );
};

export default NftRoyalHeader;
