import * as React from 'react';
import { KeyboardAvoidingView, ScrollView } from 'react-native';

import { CREATE_WHITE_LIST_ENTRIES } from '@Data/Requests';
import { useMutation } from '@apollo/client';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import CustomModal from '@components/CustomModal';
import ImageIguana from '@components/ImageIguana';
import { View } from '@components/Themed';
import { isIOS } from '@helpers/app';
import { errorsHandler } from '@helpers/errors';
import { isGraphqlError, waitForModal } from '@helpers/helpers';
import { toastError, toastSuccess } from '@helpers/toastNotification';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';

import { useRankQuantitySelect } from '../hooks/useRankQuantitySelect';
import { useRankSelect } from '../hooks/useRankSelect';
import { useWalletAddressInput } from '../hooks/useWalletAddressInput';
import stylesMain from './styles';

const AdminAssignWhiteListScreen = () => {
    const styles = useThemedStyles(stylesMain);

    const {
        renderWalletInput,
        checkWallet,
        walletError,
        walletValue,
        resetWalletInput,
    } = useWalletAddressInput();

    const {
        rankError,
        rankValue,
        renderRankSelect,
        resetRankSelect,
        checkRank,
    } = useRankSelect();

    const {
        quantityError,
        quantityValue,
        renderQuantityInput,
        resetQuantityInput,
    } = useRankQuantitySelect();

    const disableButton = React.useMemo(
        () =>
            !walletValue ||
            !!walletError ||
            !!rankError ||
            !rankValue ||
            !quantityValue ||
            !!quantityError,
        [
            walletError,
            walletValue,
            rankError,
            rankValue,
            quantityError,
            quantityValue,
        ]
    );

    const onButtonPress = React.useCallback(() => {
        if (checkWallet() && checkRank() && !quantityError) {
            open();
        }
    }, [checkWallet, checkRank, quantityError]);

    const { isVisible, close, open } = useVisibleHook();

    const reset = () => {
        resetWalletInput();
        resetRankSelect();
        resetQuantityInput();
    };

    const [createWhitelistEntries, { loading }] = useMutation(
        CREATE_WHITE_LIST_ENTRIES
    );

    const onSendPress = React.useCallback(async () => {
        try {
            await createWhitelistEntries({
                variables: {
                    assignInput: {
                        quantity: Number(quantityValue),
                        rankId: rankValue,
                        walletAddress: walletValue,
                    },
                },
            });
            reset();
            close();
            await waitForModal();
            toastSuccess(i18n.t('profile.adminAssignWhiteList.toastSuccess'));
        } catch (error) {
            reset();
            close();
            await waitForModal();
            if (isGraphqlError(error, 'NOT_FOUND')) {
                toastError(i18n.t('profile.adminAssignWhiteList.toastError'));
                return;
            }
            errorsHandler(error, true);
        }
    }, [walletValue, quantityValue, rankValue]);

    const onCancelPress = () => {
        reset();
        close();
    };
    return (
        <KeyboardAvoidingView
            behavior={isIOS ? 'padding' : 'height'}
            style={styles.mainContainer}>
            <NavigationBar
                backIcon
                filled={false}
                title={i18n.t('profile.adminAssignWhiteList.screenTitle')}
            />

            <ScrollView
                keyboardShouldPersistTaps="handled"
                style={styles.content}
                showsVerticalScrollIndicator={false}>
                <BlockHeader
                    containerStyle={styles.paddingHorizontal}
                    type="primary"
                    title={i18n.t('profile.adminAssignWhiteList.title')}
                    text={i18n.t('profile.adminAssignWhiteList.text')}
                />

                <View style={styles.inputWrapper}>{renderWalletInput()}</View>
                <View style={styles.inputWrapper}>{renderRankSelect()}</View>
                <View style={styles.inputWrapper}>{renderQuantityInput()}</View>
            </ScrollView>
            <ButtonsBottom
                containerStyle={styles.buttons}
                title={i18n.t('profile.adminAssignWhiteList.button')}
                onPress={onButtonPress}
                disabled={disableButton}
            />
            <CustomModal
                isVisible={isVisible}
                icon={<ImageIguana type="attention" />}
                titleText={i18n.t('profile.adminAssignWhiteList.modal.title')}
                infoText={i18n.t('profile.adminAssignWhiteList.modal.text')}
                firstButtonText={i18n.t(
                    'profile.adminAssignWhiteList.modal.button1'
                )}
                secondButtonText={i18n.t(
                    'profile.adminAssignWhiteList.modal.button2'
                )}
                firstButtonLoading={loading}
                firstButtonDisabled={loading}
                firstButtonType="critical"
                onFirstButtonClick={onSendPress}
                onSecondButtonClick={onCancelPress}
            />
        </KeyboardAvoidingView>
    );
};

export default AdminAssignWhiteListScreen;
