import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        navBg: {
            backgroundColor: Colors[theme].background,
        },
        container: {
            flex: 1,
            paddingHorizontal: scale(20),
        },
        wrapper: {
            flex: 1,
        },
        buttonWrapper: {
            paddingVertical: verticalScale(16),
        },
        flow: {
            paddingHorizontal: 0,
            marginLeft: verticalScale(16),
            marginRight: verticalScale(4),
            flex: 1,
        },
        social: {
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            marginBottom: verticalScale(16),
        },
        card: {
            paddingHorizontal: scale(16),
            paddingVertical: scale(16),
            borderRadius: moderateScale(12),
            borderColor: Colors[theme].border.primary,
            borderWidth: 1,
            backgroundColor: 'transparent',
        },
        colorPickerWrapper: {
            marginTop: scale(16),
        },
        viewShot: {
            position: 'absolute',
            width: '100%',
            zIndex: -9999,
            left: scale(16),
            // we need to generate additional PetID container for TikTok with paddings,
            // that's why set it invisible and make a screenshot.
            // On web we must put opacity 0, and on mobile we just move it out of viewport
            top: -5000,
            overflow: 'hidden',
        },
        alertCard: {
            marginTop: scale(16),
        },
    });

export default styles;
