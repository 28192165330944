import * as React from 'react';

import MarketPlaceBuyCheckoutScreen from '../../screens/TabBar/Marketplace/MarketPlaceBuyCheckout/MarketPlaceBuyCheckoutScreen';
import MarketPlaceBuySuccessScreen from '../../screens/TabBar/Marketplace/MarketPlaceBuySuccess/MarketPlaceBuySuccessScreen';
import MarketPlaceCreateOrderSuccessScreen from '../../screens/TabBar/Marketplace/MarketPlaceCreateOrderSuccess/MarketPlaceCreateOrderSuccessScreen';
import MarketPlaceDelistSuccessScreen from '../../screens/TabBar/Marketplace/MarketPlaceDelistSuccess/MarketPlaceDelistSuccessScreen';
import MarketPlaceProfileScreen from '../../screens/TabBar/Marketplace/MarketPlaceProfile/MarketPlaceProfileScreen';
import MarketPlaceSelectNftScreen from '../../screens/TabBar/Marketplace/MarketPlaceSelectNft/MarketPlaceSelectNftScreen';
import MarketPlaceSelectNftPriceScreen from '../../screens/TabBar/Marketplace/MarketPlaceSelectNftPrice/MarketPlaceSelectNftPriceScreen';
import MarketPlaceWelcomeCreateOrderScreen from '../../screens/TabBar/Marketplace/MarketPlaceWelcomeCreateOrder/MarketPlaceWelcomeCreateOrderScreen';
import ROUTES from '../routes';
import { MarketPlaceOverviewTabsNavigator } from './MarketPlaceOverviewTabsNavigator';

const MarketplaceGroup = (RootStack: any) => {
    return (
        <>
            <RootStack.Screen
                name={ROUTES.MARKETPLACE_WELCOME_CREATE_ORDER}
                component={MarketPlaceWelcomeCreateOrderScreen}
            />
            <RootStack.Screen
                name={ROUTES.MARKETPLACE_SELECT_NFT}
                component={MarketPlaceSelectNftScreen}
            />
            <RootStack.Screen
                name={ROUTES.MARKETPLACE_SELECT_NFT_PRICE}
                component={MarketPlaceSelectNftPriceScreen}
            />
            <RootStack.Screen
                name={ROUTES.MARKETPLACE_CREATE_ORDER_SUCCESS}
                component={MarketPlaceCreateOrderSuccessScreen}
                options={{ gestureEnabled: false }}
            />

            <RootStack.Screen
                name={ROUTES.MARKETPLACE_OVERVIEW_GROUP}
                component={MarketPlaceOverviewTabsNavigator}
            />
            <RootStack.Screen
                name={ROUTES.MARKETPLACE_PROFILE}
                component={MarketPlaceProfileScreen}
            />
            <RootStack.Screen
                name={ROUTES.MARKETPLACE_DELIST_SUCCESS}
                component={MarketPlaceDelistSuccessScreen}
                options={{ gestureEnabled: false }}
            />
            <RootStack.Screen
                name={ROUTES.MARKETPLACE_BUY_CHECKOUT}
                component={MarketPlaceBuyCheckoutScreen}
            />
            <RootStack.Screen
                name={ROUTES.MARKETPLACE_BUY_SUCCESS}
                component={MarketPlaceBuySuccessScreen}
                options={{ gestureEnabled: false }}
            />
        </>
    );
};

export default MarketplaceGroup;
