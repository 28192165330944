import * as Localization from 'expo-localization';

export interface ISocialMedia {
    id: string;
    url: string;
}

export const COMMUNITIES: ISocialMedia[] = [
    {
        id: 'discord',
        url: 'https://discord.com/invite/iguverse',
    },
    {
        id: 'telegram',
        url: 'https://t.me/iguverse',
    },
];

export const MEDIA: ISocialMedia[] = [
    {
        id: 'website',
        url: 'https://iguverse.com/',
    },
    {
        id: 'twitter',
        url: 'https://twitter.com/iguverse',
    },
    {
        id: 'instagram',
        url: 'https://www.instagram.com/iguverse/',
    },
    {
        id: 'youtube',
        url: 'https://www.youtube.com/c/IguVerse',
    },
    {
        id: 'tiktok',
        url: 'https://www.tiktok.com/@iguverse',
    },
];

export const TELEGRAM_CHATS: ISocialMedia[] = [
    {
        id: 'main',
        url: 'https://t.me/iguverse',
    },

    {
        id: 'en',
        url: 'https://t.me/iguverse_chat',
    },

    {
        id: 'ru',
        url: 'https://t.me/iguverse_chat_ru',
    },

    {
        id: 'uk',
        url: 'https://t.me/iguverse_chat_ua',
    },

    {
        id: 'pl',
        url: 'https://t.me/iguverse_pl',
    },

    {
        id: 'id',
        url: 'https://t.me/iguverse_chat_indonesia',
    },

    {
        id: 'es',
        url: 'https://t.me/iguverse_chat_spanish',
    },

    {
        id: 'tr',
        url: 'https://t.me/iguverse_chat_turkish',
    },

    {
        id: 'in',
        url: 'https://t.me/iguverse_india',
    },

    {
        id: 'fr',
        url: 'https://t.me/iguverse_chat_french',
    },

    {
        id: 'pt',
        url: 'https://t.me/iguverse_chat_portuguese',
    },

    {
        id: 'cn',
        url: 'https://t.me/iguverse_chat_chinese',
    },

    {
        id: 'kr',
        url: 'https://t.me/iguverse_chat_korean',
    },

    {
        id: 'jp',
        url: 'https://t.me/iguverse_chat_japanese',
    },

    {
        id: 'ab',
        url: 'https://t.me/iguverse_chat_arabic',
    },

    {
        id: 'pp',
        url: 'https://t.me/iguverse_philippines',
    },

    {
        id: 'vt',
        url: 'https://t.me/iguverse_vietnam',
    },
    {
        id: 'bg',
        url: 'https://t.me/iguverse_bangladesh',
    },
];

// recommended chats for certain locale
export const RECOMMENDED_CHATS = [
    {
        id: 'ru',
        resolve: ['ru', 'uz', 'be', 'ka', 'kk', 'mn', 'sr', 'tk'],
    },
];

// get chat by id
export const getChat = (id: string): ISocialMedia =>
    TELEGRAM_CHATS.find((item) => item.id === id) || TELEGRAM_CHATS[0];

// get recommended chat
export function getRecommendedChat(locale: string): ISocialMedia {
    const localeArray = locale.split('-');
    let item;

    for (const localePiece of localeArray) {
        // look for exact match of the chat
        item = getChat(localePiece.toLowerCase());
        if (item) {
            return item;
        }

        // look for resolve match of the chats (mass match, e.g. Russian language)
        item = RECOMMENDED_CHATS.find((item) =>
            item.resolve.includes(localePiece.toLowerCase())
        );
        if (item) {
            return getChat(item.id);
        }
    }

    return getChat('en');
}
