import * as React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import ICON from '@assets/icons/emptyState/nftRoyalGameSettings.svg';
import Difficulties from '@assets/icons/mysteryGames/difficulties.svg';
import LivesCount from '@assets/icons/mysteryGames/livesCount.svg';
import Mode from '@assets/icons/mysteryGames/mode.svg';
import AlertCard from '@components/AlertCard';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import CardFeatureInfo from '@components/CardFeatureInfo/CardFeatureInfo';
import ModalBottom from '@components/ModalBottom';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
}

const NftRoyalGameSettingsModal = ({ isVisible, onClose }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { nftRoyaleGameConfig } = useMysteryGames();

    return (
        <ModalBottom isVisible={isVisible} onClose={onClose} modalHeight="100%">
            <ScrollView
                style={styles.container}
                showsVerticalScrollIndicator={false}>
                <TouchableOpacity activeOpacity={1}>
                    <BlockHeader
                        containerStyle={styles.header}
                        emptyStateIcon={<ICON />}
                        type="emptyState"
                        title={i18n.t(
                            'HomeScreen.NftRoyal.NftRoyalGameSettingsModal.title'
                        )}
                        text={i18n.t(
                            'HomeScreen.NftRoyal.NftRoyalGameSettingsModal.text'
                        )}
                    />
                </TouchableOpacity>
                <CardFeatureInfo
                    containerStyle={styles.feature}
                    icon={<Mode width={48} height={48} />}
                    title={i18n.t('general.KEYS.mode')}
                    text={i18n.t(
                        'HomeScreen.NftRoyal.NftRoyalGameSettingsModal.feature.mode.description'
                    )}
                />
                <CardFeatureInfo
                    containerStyle={styles.feature}
                    icon={<Difficulties width={48} height={48} />}
                    title={i18n.t('general.KEYS.difficulty')}
                    text={i18n.t(
                        'HomeScreen.NftRoyal.NftRoyalGameSettingsModal.feature.difficulty.description'
                    )}
                />
                <CardFeatureInfo
                    icon={<LivesCount width={48} height={48} />}
                    title={i18n.t('general.KEYS.livesCount')}
                    text={i18n.t(
                        'HomeScreen.NftRoyal.NftRoyalGameSettingsModal.feature.livesCount.description'
                    )}
                />
            </ScrollView>

            <AlertCard
                containerStyle={styles.alert}
                text={i18n.t('HomeScreen.NftRoyal.lockPeriodWarning', {
                    lockedDays: nftRoyaleGameConfig?.rewardUnlockDaysCount || 0,
                })}
            />
        </ModalBottom>
    );
};

export default NftRoyalGameSettingsModal;
