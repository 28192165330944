import uuid from 'react-native-uuid';

import * as Application from 'expo-application';

import SecureStore from '../utils/SecureStore';
import { isAndroid } from './app';

export const getDeviceId = async () => {
    if (isAndroid) {
        return Application.androidId;
    } else {
        const deviceId = await SecureStore.getItemAsync('deviceId');

        // we have device ID from storage
        if (deviceId) {
            return deviceId;
        }

        let newDeviceId = '';

        // in some phones uuid crashes, that's why we need to provide fallback function just in case
        try {
            newDeviceId = uuid.v4().toString();
        } catch (error: any) {
            newDeviceId = getFallbackRandomId();
        }

        Console.info('[getDeviceId] Create new ID', newDeviceId);
        await SecureStore.setItemAsync('deviceId', newDeviceId);

        return newDeviceId;
    }
};

// fallback function if somehow normal one failed
function getFallbackRandomId(length = 32) {
    let result = '';
    const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }

    return result;
}
