import * as React from 'react';
import { ScrollView } from 'react-native';

import ICON from '@assets/icons/emptyState/createMysteryLikesGameWelcome.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import { View } from '@components/Themed';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';
import NavigationBar from '@navigation/NavigationBar';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import GameTermination from '../../components/GameTermination';
import MinimalBetFeature from '../../components/MinimalBetFeature';
import stylesMain from './styles';

const MysteryLikesCreateGameWelcomeScreen = () => {
    const styles = useThemedStyles(stylesMain);

    const navigation = useNavigation();

    const { mysteryLikesGameStats, clearMysteryLikes } = useMysteryGames();

    const navigateToInput = () => {
        navigation.replace(ROUTES.MYSTERY_LIKES_CREATE_GAME_INPUT);
    };
    React.useEffect(() => {
        clearMysteryLikes();
    }, []);

    return (
        <View style={styles.main}>
            <NavigationBar
                backIcon="leftArrow"
                filled={false}
                needTail={false}
                title={i18n.t('HomeScreen.GamesCreateWelcome.screenTitle', {
                    game: i18n.t(
                        `HomeScreen.GamesBlock.games.${MYSTERY_GAMES.mysteryLikes}.title`
                    ),
                })}
            />
            <ScrollView
                showsVerticalScrollIndicator={false}
                style={styles.container}>
                <BlockHeader
                    containerStyle={styles.header}
                    emptyStateIcon={<ICON />}
                    type="emptyState"
                    title={i18n.t('HomeScreen.GamesCreateWelcome.title')}
                    text={i18n.t('HomeScreen.GamesCreateWelcome.text')}
                />
                <MinimalBetFeature containerStyle={styles.gap} />
                <GameTermination
                    termination={mysteryLikesGameStats?.gameMaxLifetimeDays}
                    containerStyle={styles.feature}
                />
            </ScrollView>
            <ButtonsBottom
                title={i18n.t('general.BUTTONS.continue')}
                onPress={navigateToInput}
                safe
            />
        </View>
    );
};

export default MysteryLikesCreateGameWelcomeScreen;
