import * as React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import ICON from '@assets/icons/mysteryGames/mysteryLikesContinueIcon.svg';
import MysteryGamesContinueIcon from '@components/Icons/MysteryGamesContinueIcon';
import ProgressBar from '@components/ProgressBar';
import TypoText from '@components/TypoText';
import { TYPO_TEXT } from '@components/TypoText/types';
import { useDimensions } from '@contexts/DimensionsContext';
import { verticalScale } from '@helpers/dimensions';
import usePreventDoubleClick from '@hooks/usePreventDoubleClick';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import {
    MYSTERY_GAMES,
    MYSTERY_GAMES_CONTINUE_BANNER_RENDER_TYPE,
    MysteryLikesMyPendingGame,
} from '@models/mysteryGames';

import stylesMain from './styles';

interface IProps {
    onPress: () => void;
    mysteryLikes: MysteryLikesMyPendingGame | undefined;

    renderType: MYSTERY_GAMES_CONTINUE_BANNER_RENDER_TYPE;
}

const BannerMysteryGames = ({ onPress, mysteryLikes, renderType }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { windowWidth } = useDimensions();

    const { onClick, disabled } = usePreventDoubleClick(onPress, 3000);

    const itemWidth = React.useMemo(
        () => windowWidth - verticalScale(20) * 2,
        [windowWidth]
    );

    const renderBody = React.useCallback(() => {
        switch (renderType) {
            case MYSTERY_GAMES_CONTINUE_BANNER_RENDER_TYPE.MLG:
                return (
                    <>
                        <View style={styles.top}>
                            <MysteryGamesContinueIcon
                                slug={MYSTERY_GAMES.mysteryLikes}
                            />
                            <View style={styles.textBlock}>
                                <TypoText
                                    type={TYPO_TEXT.SUBHEAD_BIG_BOLD}
                                    additionalStyles={styles.textGap}>
                                    {i18n.t(
                                        'HomeScreen.MysteryGamesBanner.oneGame.title'
                                    )}
                                </TypoText>
                                <TypoText type={TYPO_TEXT.BODY_SMALL}>
                                    {i18n.t(
                                        'HomeScreen.MysteryGamesBanner.oneGame.text',
                                        {
                                            game: i18n.t(
                                                `HomeScreen.GamesBlock.games.${MYSTERY_GAMES.mysteryLikes}.title`
                                            ),
                                        }
                                    )}
                                </TypoText>
                            </View>
                        </View>
                        {!!mysteryLikes && (
                            <ProgressBar
                                amount={mysteryLikes.roundsCompletedCount}
                                maxAmount={mysteryLikes.roundsCount}
                                containerStyle={styles.progressBar}
                            />
                        )}
                    </>
                );

            case MYSTERY_GAMES_CONTINUE_BANNER_RENDER_TYPE.ROYAL:
                return (
                    <View style={styles.top}>
                        <MysteryGamesContinueIcon
                            slug={MYSTERY_GAMES.nftRoyal}
                        />
                        <View style={styles.textBlock}>
                            <TypoText
                                type={TYPO_TEXT.SUBHEAD_BIG_BOLD}
                                additionalStyles={styles.textGap}>
                                {i18n.t(
                                    'HomeScreen.MysteryGamesBanner.oneGame.title'
                                )}
                            </TypoText>
                            <TypoText type={TYPO_TEXT.BODY_SMALL}>
                                {i18n.t(
                                    'HomeScreen.MysteryGamesBanner.oneGame.text',
                                    {
                                        game: i18n.t(
                                            `HomeScreen.GamesBlock.games.${MYSTERY_GAMES.nftRoyal}.title`
                                        ),
                                    }
                                )}
                            </TypoText>
                        </View>
                    </View>
                );

            case MYSTERY_GAMES_CONTINUE_BANNER_RENDER_TYPE.BOTH:
                return (
                    <View style={styles.top}>
                        <MysteryGamesContinueIcon />
                        <View style={styles.textBlock}>
                            <TypoText
                                type={TYPO_TEXT.SUBHEAD_BIG_BOLD}
                                additionalStyles={styles.textGap}>
                                {i18n.t(
                                    'HomeScreen.MysteryGamesBanner.more.title'
                                )}
                            </TypoText>
                            <TypoText type={TYPO_TEXT.BODY_SMALL}>
                                {i18n.t(
                                    'HomeScreen.MysteryGamesBanner.more.text'
                                )}
                            </TypoText>
                        </View>
                    </View>
                );
            default:
                return null;
        }
    }, [renderType, mysteryLikes]);

    return (
        <TouchableOpacity
            activeOpacity={0.9}
            onPress={onClick}
            disabled={disabled}
            style={[styles.shadow, { width: itemWidth }]}>
            <View style={[styles.container]}>{renderBody()}</View>
        </TouchableOpacity>
    );
};
export default BannerMysteryGames;
