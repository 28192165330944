import { useMemo } from 'react';
import { Platform } from 'react-native';

import { AuthRequest } from 'expo-auth-session/src/AuthRequest';
import { AuthRequestConfig } from 'expo-auth-session/src/AuthRequest.types';
import {
    useAuthRequestResult,
    useLoadedAuthRequest,
} from 'expo-auth-session/src/AuthRequestHooks';
import {
    AuthRequestPromptOptions,
    AuthSessionRedirectUriOptions,
    AuthSessionResult,
    DiscoveryDocument,
    ResponseType,
    makeRedirectUri,
} from 'expo-auth-session/src/AuthSession';
import { generateHexStringAsync } from 'expo-auth-session/src/PKCE';
import { ProviderAuthRequestConfig } from 'expo-auth-session/src/providers/Provider.types';
import {
    applyRequiredScopes,
    useProxyEnabled,
} from 'expo-auth-session/src/providers/ProviderUtils';

import { projectNameForProxy } from '@helpers/app';

const settings = {
    windowFeatures: { width: 700, height: 600 },
    // These are required for Firebase to work properly which is a reasonable default.
    minimumScopes: ['user_profile'],
};

export const discovery: DiscoveryDocument = {
    authorizationEndpoint: 'https://api.instagram.com/oauth/authorize',
    tokenEndpoint: 'https://api.instagram.com/oauth/access_token',
};

// @needsAudit @docsMissing
export interface InstagramAuthRequestConfig extends ProviderAuthRequestConfig {
    webClientId?: string;
    iosClientId?: string;
    androidClientId?: string;
    expoClientId?: string;
    faceBookId?: string;
}

// @needsAudit
/**
 * Extends [`AuthRequest`](#authrequest) and accepts [`FacebookAuthRequest`](#facebookauthrequest) in the constructor.
 */
class InstagramAuthRequest extends AuthRequest {
    nonce?: string;

    constructor({
        language,
        // Account selection cannot be reliably emulated on Facebook.
        extraParams = {},
        clientSecret,
        ...config
    }: InstagramAuthRequestConfig) {
        const inputParams: Record<string, string> = {
            display: 'popup',
            ...extraParams,
        };
        if (language) {
            inputParams.locale = language;
        }

        // Apply the default scopes
        const scopes = applyRequiredScopes(
            config.scopes,
            settings.minimumScopes
        );
        let inputClientSecret: string | undefined;
        //  Facebook will throw if you attempt to use the client secret
        if (config.responseType && config.responseType !== ResponseType.Code) {
            // TODO: maybe warn that you shouldn't store the client secret on the client
            inputClientSecret = clientSecret;
        }
        // Default to implicit auth
        if (!config.responseType) {
            config.responseType = ResponseType.Code;
            Console.log('Default response type ', config.responseType);
        }
        super({
            ...config,
            clientSecret: inputClientSecret,
            scopes,
            extraParams: inputParams,
        });
    }

    /**
     * Load and return a valid auth request based on the input config.
     */
    async getAuthRequestConfigAsync(): Promise<AuthRequestConfig> {
        const { extraParams = {}, ...config } =
            await super.getAuthRequestConfigAsync();
        if (!extraParams.nonce && !this.nonce) {
            if (!this.nonce) {
                this.nonce = await generateHexStringAsync(16);
            }
            extraParams.auth_nonce = this.nonce;
        }
        return {
            ...config,
            extraParams,
        };
    }
}

/**
 * Load an authorization request.
 * Returns a loaded request, a response, and a prompt method.
 * When the prompt method completes then the response will be fulfilled.
 *
 * - [Get Started](https://docs.expo.dev/guides/authentication/#facebook)
 *
 * @param config
 * @param redirectUriOptions
 */
export function useAuthRequest(
    config: Partial<InstagramAuthRequestConfig> = {},
    redirectUriOptions: Partial<AuthSessionRedirectUriOptions> = {}
): [
    InstagramAuthRequest | null,
    AuthSessionResult | null,
    (options?: AuthRequestPromptOptions) => Promise<AuthSessionResult>
] {
    const useProxy = useProxyEnabled(redirectUriOptions);

    const clientId = useMemo((): string => {
        return config.clientId || '';
    }, [config?.clientId]);

    const redirectUri = useMemo((): string => {
        if (typeof config.redirectUri !== 'undefined') {
            return config.redirectUri;
        }

        return makeRedirectUri({
            // The redirect URI should be created using fb + client ID on native.
            native: `fb${config.faceBookId}://authorize`,
            useProxy,
            projectNameForProxy,
            ...redirectUriOptions,
        });
    }, [config.redirectUri, config.faceBookId, useProxy, redirectUriOptions]);

    const extraParams =
        useMemo((): InstagramAuthRequestConfig['extraParams'] => {
            const output = config.extraParams ? { ...config.extraParams } : {};

            if (config.language) {
                output.locale = config.language;
            }
            return output;
        }, [config.extraParams, config.language]);

    const request = useLoadedAuthRequest(
        {
            ...config,
            extraParams,
            clientId,
            redirectUri,
        },
        discovery,
        InstagramAuthRequest
    );

    // request?.url = "https://api.instagram.com/oauth/authorize\n" +
    //     "  ?client_id={app-id}\n" +
    //     "  &redirect_uri={redirect-uri}\n" +
    //     "  &scope=user_profile,user_media\n" +
    //     "  &response_type=code"
    const [result, promptAsync] = useAuthRequestResult(request, discovery, {
        windowFeatures: settings.windowFeatures,
        useProxy,
        projectNameForProxy,
    });

    return [request, result, promptAsync];
}
