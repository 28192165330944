import * as React from 'react';
import { useCallback } from 'react';

import BannersCarousel from '@components/BannersCarousel';
import LoadingComponent from '@components/LoadingComponent';
import PullToRefresh from '@components/PullToRefresh';
import SafeAreaCurvedTabContent from '@components/SafeAreaCurvedTabContent';
import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import RecentlyAchievements from '../../../Global/Achievements/components/RecentlyAchievements';
import DailyTasksBlock from '../components/DailyTasksBlock';
import GamesBlock from '../components/GamesBlock';
import useHomeMainScreen from './hooks/useHomeMainScreen';
import stylesMain from './styles';

const HomeMainScreen = () => {
    const styles = useThemedStyles(stylesMain);

    const {
        tasksConfig,
        additionalLoading,
        mysteryGamesLoading,
        mysteryGames,
        mysteryLikesMyPendingGames,
        isRefresh,
        handleRefresh,
        renderBanner,

        renderDailyRewardsModal,
        renderUpdateBanner,
        nftRoyalMyPendingGames,
        userAchievements,
        // renderPasscodeBanner,
        renderMemsModal,
        renderMemBanner,
        continueGameBannerVisible,
    } = useHomeMainScreen();

    const renderItem = useCallback(() => {
        return (
            <View style={styles.container}>
                {(!tasksConfig || additionalLoading) && (
                    <View style={styles.wrapper} />
                )}
                <GamesBlock
                    loading={mysteryGamesLoading}
                    games={mysteryGames}
                    isMysteryLikesPendingGames={
                        !!mysteryLikesMyPendingGames?.length
                    }
                    isNftRoyalPendingGames={!!nftRoyalMyPendingGames?.length}
                />
                {!!userAchievements && (
                    <RecentlyAchievements achievements={userAchievements} />
                )}

                {!!tasksConfig && <DailyTasksBlock data={tasksConfig} />}
                <BannersCarousel containerStyle={styles.banners} />
                <View style={styles.gap} />
            </View>
        );
    }, [
        tasksConfig,
        mysteryGames,
        mysteryGamesLoading,
        additionalLoading,
        mysteryLikesMyPendingGames,
        nftRoyalMyPendingGames,
        userAchievements,
    ]);

    return (
        <SafeAreaCurvedTabContent>
            {!tasksConfig && <LoadingComponent />}

            <PullToRefresh
                refreshing={isRefresh}
                textLoading={i18n.t('pullToRefresh.loading')}
                onRefresh={handleRefresh}
                renderItem={renderItem}
            />

            {renderBanner()}
            {renderDailyRewardsModal()}
            {renderUpdateBanner()}
            {!continueGameBannerVisible ? (
                <>
                    {renderMemsModal()}
                    {renderMemBanner()}
                </>
            ) : null}

            {/* {renderPasscodeBanner()} */}
        </SafeAreaCurvedTabContent>
    );
};

export default HomeMainScreen;
