import * as React from 'react';

import { ISocialToEarnCard } from '@constants/shareCardTemplatesNew';
import { useConfig } from '@contexts/ConfigContext';
import { usePlay } from '@contexts/PlayContext';

import BybitTemplate from './bybitTemplate';
import DefaultTemplate from './defaultTemplate';

interface IProps {
    sizeConfig: {
        width: number | string;
        height: number | string;
    };
    backGround: ISocialToEarnCard;
    withBorderRadius?: boolean;
}

const ShareTemplateCard = ({
    sizeConfig,
    backGround,
    withBorderRadius,
}: IProps) => {
    const { config } = useConfig();

    const { socialTask } = usePlay();

    const token = React.useMemo(
        () => socialTask?.latestTask?.token,
        [socialTask?.latestTask.token]
    );

    const renderContent = React.useCallback(() => {
        switch (config?.petIdType) {
            case 'Bybit':
                return (
                    <BybitTemplate
                        config={sizeConfig}
                        backGround={backGround}
                        token={token}
                        url={socialTask?.latestTask?.pet?.image.url}
                        withBorderRadius={withBorderRadius}
                    />
                );

            default:
                return (
                    <DefaultTemplate
                        config={sizeConfig}
                        backGround={backGround}
                        token={token}
                        url={socialTask?.latestTask?.pet?.image.url}
                        withBorderRadius={withBorderRadius}
                    />
                );
        }
    }, [
        sizeConfig,
        backGround,
        token,
        socialTask,
        config?.petIdType,
        withBorderRadius,
    ]);

    return renderContent();
};
export default ShareTemplateCard;
