import { StyleSheet } from 'react-native';

import Colors from '../../../../../../constants/Colors';
import { scale, verticalScale } from '../../../../../../helpers/dimensions';
import font from '../../../../../../helpers/font';
import { theme } from '../../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        textWrapper: {
            marginTop: verticalScale(8),
            marginBottom: verticalScale(20),
        },

        title: {
            marginTop: verticalScale(2),
            color: Colors[theme].text.primary,
            ...font({
                size: 22,
                height: 30,
                weight: '700',
            }),
            marginBottom: verticalScale(6),
        },
        text: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
        titleWrapper: {
            backgroundColor: 'transparent',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        infoWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: verticalScale(8),
        },
        info: {
            color: Colors[theme].text.accent,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
            letterSpacing: 0.08,
            marginLeft: scale(4),
        },
        flex: {
            flex: 1,
            justifyContent: 'flex-start',
        },
    });

export default styles;
