import React from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native';

import Discount from '@assets/icons/ages/discount.svg';
import Icon from '@assets/icons/emptyState/oldAge.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import CardFeatureInfo from '@components/CardFeatureInfo/CardFeatureInfo';
import ModalBottom from '@components/ModalBottom';
import { useConfig } from '@contexts/ConfigContext';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import mainStyles from './styles';

interface IProps {
    isVisible: boolean;
    closeModal: () => void;

    name: string;
}

const OverviewModalNFTisAgeDead = ({ isVisible, closeModal, name }: IProps) => {
    const styles = useThemedStyles(mainStyles);
    const { config } = useConfig();

    return (
        <ModalBottom
            modalHeight={750}
            isVisible={isVisible}
            onClose={closeModal}>
            <ScrollView
                style={styles.container}
                showsVerticalScrollIndicator={false}>
                <TouchableOpacity activeOpacity={1}>
                    <BlockHeader
                        title={i18n.t(
                            'detailedPet.age.modalNFTisAgeDead.title'
                        )}
                        text={i18n.t('detailedPet.age.modalNFTisAgeDead.text', {
                            name,
                        })}
                        type="emptyState"
                        emptyStateIcon={<Icon />}
                    />
                    {config && (
                        <View style={styles.featureWrapper}>
                            <CardFeatureInfo
                                icon={<Discount width={48} height={48} />}
                                title={i18n.t(
                                    'detailedPet.age.modalNFTisAgeDead.feature.title',
                                    {
                                        discount:
                                            config?.petAgeReviveBasePriceDiscountPercentage,
                                    }
                                )}
                                text={i18n.t(
                                    'detailedPet.age.modalNFTisAgeDead.feature.text',
                                    {
                                        discount:
                                            config?.petAgeReviveBasePriceDiscountPercentage,
                                    }
                                )}
                                warningText={i18n.t(
                                    'detailedPet.age.modalWhatIsAge.feature.alert'
                                )}
                            />
                        </View>
                    )}
                </TouchableOpacity>
            </ScrollView>
        </ModalBottom>
    );
};
export default OverviewModalNFTisAgeDead;
