import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { StyleProp, View, ViewProps } from 'react-native';
import { Direction } from 'react-native-modal/dist/types';
import Animated, {
    cancelAnimation,
    useAnimatedStyle,
    useSharedValue,
    withTiming,
} from 'react-native-reanimated';

import { useDimensions } from '@contexts/DimensionsContext';
import { useKeyboard } from '@contexts/KeyboardContext';
import { useTheme } from '@contexts/ThemeContext';
import { isDesktop, isWeb } from '@helpers/app';

import Colors from '../../constants/Colors';
import useThemedStyles from '../../hooks/useThemedStyles';
import CustomModalBackdrop from '../../web-src/components/CustomModalBackdrop';
import Button from '../Button';
import ExtendedModal from '../ExtendedModal';
import { ICON_NAMES } from '../Icons';
import { Text } from '../Themed';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
    modalHeight?: number | string;
    expandHeight?: boolean;
    containerStyle?: StyleProp<ViewProps>;
    swipeDirection?: Direction | Direction[];
    swipeThreshold?: number;
    animation?: any;
    backdropOpacity?: number;
    children: React.ReactNode;
    avoidKeyboard?: boolean;
    isNestedModal?: boolean;
    smartHandleExpand?: boolean;
    ignoreGlobalModal?: boolean;
    withToasts?: boolean;
    titleText?: string;
    additionalText?: string;
    changeHeightOnKeyboardVisible?: boolean;
    dynamicHeight?: boolean;
}

const config = {
    modalMaxHeight: 95 / 100, // 95% of height will not allow modal to cover entire screen
    expandThreshold: 20,
    modalAnimationDuration: 600,
};

const ModalBottom = ({
    isVisible,
    onClose,
    modalHeight = '30%',
    containerStyle,
    swipeDirection = ['down'],
    swipeThreshold = 100,
    animation,
    backdropOpacity,
    children,
    expandHeight = true,
    avoidKeyboard = false,
    isNestedModal = false,
    smartHandleExpand = false,
    ignoreGlobalModal = false,
    withToasts = true,
    titleText,
    additionalText,
    changeHeightOnKeyboardVisible = true,
    dynamicHeight,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { theme } = useTheme();
    const { keyboardHeight } = useKeyboard();
    const { windowWidth, windowHeight } = useDimensions();
    const modalHeightShared = useSharedValue(
        getDefaultAbsoluteHeight(modalHeight, windowHeight)
    ); // set some default value more than 0

    React.useEffect(() => {
        if (dynamicHeight) {
            modalHeightShared.value = withTiming(
                getDefaultAbsoluteHeight(modalHeight, windowHeight)
            );
        }
    }, [dynamicHeight, modalHeight]);
    const touchStart = useSharedValue(0);
    const touchEnd = useSharedValue(0);
    const touchDelta = React.useRef(0);
    const modalHeightBeforeKeyboard = React.useRef(
        getDefaultAbsoluteHeight(modalHeight, windowHeight)
    );
    const animatedStylesVal = useAnimatedStyle(() => {
        return {
            height: modalHeightShared.value,
        };
    });

    // Memorize useAnimatedStyle https://github.com/software-mansion/react-native-reanimated/issues/1767
    const animatedStyles = useMemo(() => animatedStylesVal, []);

    const animatedHeight = (height: number) => {
        return withTiming(height, {
            duration: config.modalAnimationDuration,
        });
    };

    const calculateHeight = useCallback(
        (heightValue: number | string) => {
            const visibleScreen = changeHeightOnKeyboardVisible
                ? windowHeight - keyboardHeight
                : windowHeight;
            let resultedHeight: number;

            if (typeof heightValue === 'string' && heightValue.includes('%')) {
                const heightPercentage = getHeightPercentage(heightValue);

                // parse % strings
                if (heightPercentage > config.modalMaxHeight) {
                    resultedHeight = visibleScreen * config.modalMaxHeight;
                } else {
                    resultedHeight = Math.min(
                        windowHeight * heightPercentage,
                        visibleScreen * config.modalMaxHeight
                    );
                }
            } else {
                // parse number
                resultedHeight = Math.min(
                    Number(heightValue),
                    visibleScreen * config.modalMaxHeight
                );
            }
            return resultedHeight;
        },
        [windowHeight, changeHeightOnKeyboardVisible, keyboardHeight]
    );

    // on keyboard change height
    React.useEffect(() => {
        if (isVisible) {
            const newValue = calculateHeight(modalHeightShared.value);
            const isKeyboardVisible = keyboardHeight > 0;
            if (modalHeightShared.value !== newValue && isKeyboardVisible) {
                modalHeightBeforeKeyboard.current = modalHeightShared.value;
                modalHeightShared.value = animatedHeight(newValue);
            }
            if (!isKeyboardVisible) {
                modalHeightShared.value = animatedHeight(
                    modalHeightBeforeKeyboard.current
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [keyboardHeight, isVisible]);

    // expand height
    const handleExpand = React.useCallback(
        (event) => {
            const modalMaxHeightCalculated = calculateHeight(
                `${config.modalMaxHeight * 100}%`
            );
            if (
                modalHeightShared.value < calculateHeight(modalHeight) ||
                !expandHeight
            ) {
                if (smartHandleExpand) {
                    const openedHeightNumber = heightToNumber(
                        modalHeight,
                        windowHeight
                    );
                    const difMax = Math.abs(
                        openedHeightNumber - modalHeightShared.value
                    );
                    const difMin = Math.abs(0 - modalHeightShared.value);
                    const animateTo = difMin < difMax ? 0 : openedHeightNumber;
                    modalHeightShared.value = animatedHeight(animateTo);
                    if (animateTo === 0)
                        setTimeout(() => {
                            onClose();
                        }, config.modalAnimationDuration);
                } else {
                    const defaultAbsoluteHeight = getDefaultAbsoluteHeight(
                        calculateHeight(modalHeight),
                        windowHeight
                    );

                    modalHeightShared.value = animatedHeight(
                        defaultAbsoluteHeight
                    );
                }
            } else if (modalHeightShared.value > modalMaxHeightCalculated) {
                // Высота модалки больше макс. высоты, анимировать к макс высоте
                modalHeightShared.value = animatedHeight(
                    modalMaxHeightCalculated
                );
            }
        },
        [
            modalHeightShared,
            calculateHeight,
            modalHeight,
            expandHeight,
            windowHeight,
            smartHandleExpand,
        ]
    );

    const onSwipeComplete = useCallback(
        (event) => {
            if (!smartHandleExpand) {
                onClose();
            }
        },
        [smartHandleExpand, onClose]
    );

    const renderCloseButton = () => {
        return (
            <View style={styles.closeButton}>
                <Button
                    type="outline"
                    size="sm"
                    iconName={ICON_NAMES.CLOSE}
                    onPress={onClose}
                />
            </View>
        );
    };

    // Clear animation on unmount
    React.useEffect(() => {
        return () => {
            if (modalHeightShared && cancelAnimation)
                cancelAnimation(modalHeightShared);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ExtendedModal
            testID={titleText}
            panResponderThreshold={10}
            isVisible={isVisible}
            isNestedModal={isNestedModal}
            backdropColor={Colors[theme].modal.modalOverlay}
            backdropOpacity={backdropOpacity || Colors[theme].modal.opacity}
            onBackdropPress={onClose}
            customBackdrop={isWeb && <CustomModalBackdrop onClose={onClose} />}
            onSwipeComplete={onSwipeComplete}
            animationOutTiming={1000}
            swipeDirection={
                swipeDirection.length ? swipeDirection : [swipeDirection]
            }
            style={styles.modal}
            propagateSwipe
            swipeThreshold={swipeThreshold}
            avoidKeyboard={avoidKeyboard}
            ignoreGlobalModal={ignoreGlobalModal}
            withToasts={withToasts}
            {...animation}>
            <View style={styles.centeredView}>
                <Animated.View style={[{ width: windowWidth }, animatedStyles]}>
                    <View
                        style={[styles.lineWrapper, { width: windowWidth }]}
                        onTouchStart={(event) => {
                            touchStart.value = event.nativeEvent.pageY;
                            touchDelta.current =
                                modalHeightShared.value -
                                windowHeight +
                                event.nativeEvent.pageY;
                        }}
                        onTouchMove={(event) => {
                            touchEnd.value = event.nativeEvent.locationY;

                            modalHeightShared.value =
                                windowHeight -
                                event.nativeEvent.pageY +
                                touchDelta.current;
                        }}
                        onTouchEnd={handleExpand}>
                        {/* Modal line, disable it for Desktop */}
                        {!isDesktop && (
                            <View
                                style={[
                                    styles.line,
                                    {
                                        left:
                                            windowWidth / 2 -
                                            styles.line.width / 2,
                                    },
                                ]}></View>
                        )}
                    </View>
                    <View style={[styles.container]}>
                        {/* Adding default container with title to simplify component usage */}
                        {titleText || additionalText ? (
                            <View
                                style={[
                                    styles.contentContainer,
                                    containerStyle,
                                ]}>
                                <View>
                                    {titleText && (
                                        <View
                                            style={[
                                                additionalText
                                                    ? styles.titleContainerBig
                                                    : styles.titleContainer,
                                            ]}>
                                            <Text
                                                style={[
                                                    additionalText
                                                        ? styles.titleTextBig
                                                        : styles.titleText,
                                                ]}>
                                                {titleText}
                                            </Text>
                                        </View>
                                    )}
                                    {/* Modal close button, only for web */}
                                    {isWeb && renderCloseButton()}
                                </View>
                                {additionalText && (
                                    <View
                                        style={styles.additionalTextContainer}>
                                        <Text style={styles.additionalText}>
                                            {additionalText}
                                        </Text>
                                    </View>
                                )}
                                <>{children}</>
                            </View>
                        ) : (
                            <>
                                {/* Modal close button, only for web */}
                                {isWeb && (
                                    <View style={styles.closeButtonWrapper}>
                                        {renderCloseButton()}
                                    </View>
                                )}
                                {children}
                            </>
                        )}
                    </View>
                </Animated.View>
            </View>
        </ExtendedModal>
    );
};
export default ModalBottom;

function getHeightPercentage(value: string): number {
    return Number(value.replace('%', '')) / 100;
}

function getDefaultAbsoluteHeight(
    heightValue: string | number,
    screenHeight: number
): number {
    if (typeof heightValue === 'string' && heightValue.includes('%')) {
        const heightPercentage = getHeightPercentage(heightValue);

        return Math.min(
            heightPercentage * screenHeight,
            screenHeight * config.modalMaxHeight
        );
    }
    return Math.min(Number(heightValue), screenHeight * config.modalMaxHeight);
}

function heightToNumber(heightValue: string | number, screenHeight: number) {
    if (typeof heightValue === 'string' && heightValue.includes('%')) {
        const heightPercentage = getHeightPercentage(heightValue);

        return heightPercentage * screenHeight;
    }

    return Number(heightValue);
}
