import { useState } from 'react';

import { RankupInfo, RankupInfoResponse } from '@Data/Models';
import { GET_RANK_UP_INFO } from '@Data/Requests';
import { useLazyQuery } from '@apollo/client';
import { errorsHandler } from '@helpers/errors';

export const useRanksUpgradeInfo = () => {
    const [rankUpgradeInfo, setRankUpgradeInfo] = useState<RankupInfo>();
    const [getRankUpgradeInfo, { loading: rankUpgradeInfoLoading }] =
        useLazyQuery<RankupInfoResponse>(GET_RANK_UP_INFO, {
            fetchPolicy: 'network-only',
            onError: (error) => errorsHandler(error, true),
            onCompleted: (data) => setRankUpgradeInfo(data.rankupInfo),
        });

    return { rankUpgradeInfo, getRankUpgradeInfo, rankUpgradeInfoLoading };
};
