import React from 'react';
import { Text, View } from 'react-native';

import LastRankIcon from '../../../assets/icons/ranks/rankLast.svg';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import Icon, { ICON_NAMES } from '../../Icons';
import RankIcon from '../../Icons/RankIcon';
import mainStyles from './styles';

interface IProps {
    previousRankSlug: string;
    nextRankSlug?: string;
    maxPetLevel: number;
}

const RankUpHeader = ({
    previousRankSlug,
    nextRankSlug,
    maxPetLevel,
}: IProps) => {
    const styles = useThemedStyles(mainStyles);

    return (
        <View style={styles.container}>
            <RankIcon slug={previousRankSlug} size={80} />

            <View style={styles.divider}></View>
            <View style={styles.info}>
                <Icon name={ICON_NAMES.LEVEL} size={32} />

                <Text style={styles.lvl}>
                    {i18n.t('ranks.level', {
                        level: maxPetLevel,
                    })}
                </Text>
            </View>
            {nextRankSlug ? (
                <>
                    <View style={styles.divider}></View>
                    <RankIcon slug={nextRankSlug} size={80} />
                </>
            ) : (
                <>
                    <View style={styles.divider}></View>
                    <LastRankIcon width={80} height={80} />
                </>
            )}
        </View>
    );
};
export default RankUpHeader;
