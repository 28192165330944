import * as React from 'react';

import useThemedStyles from '../../hooks/useThemedStyles';
import i18n from '../../i18n/i18n';
import CopyToClipboardButton from '../CopyToClipboardButton';
import Input from '../Input';
import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    privateKey: string | null | undefined;
    masked?: boolean;
}

const WalletPrivateKey = ({ privateKey, masked }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const maskedWord = '-'.repeat(64);

    return (
        <>
            <View style={[styles.item]}>
                <View style={styles.inputContainer}>
                    <Input
                        style={styles.textareaInput}
                        value={masked ? maskedWord : privateKey ?? ''}
                        placeholder={i18n.t(
                            'importWalletScreen.privateKeyModal.placeholder'
                        )}
                        maxLength={128}
                        multiline={true}
                        numberOfLines={10}
                        disabled={true}
                    />
                </View>
                <View style={styles.copyButton}>
                    <CopyToClipboardButton
                        value={privateKey ?? ''}
                        disabled={masked}
                    />
                </View>
            </View>
        </>
    );
};

export default WalletPrivateKey;
