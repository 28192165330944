import React, { useCallback } from 'react';

import _ from 'lodash';

import { GET_MARKETPLACE_NFT_TRANSACTION_HISTORY } from '@Data/Requests';
import { useApolloClient } from '@apollo/client';
import { errorsHandler } from '@helpers/errors';
import { useLoadingHook } from '@hooks/useLoadingHook';
import {
    MarketplaceNftTransactionsOutputResponse,
    MarketplaceTransactionOutput,
} from '@models/marketplace';

const useMarketplaceNftTransactionHistory = () => {
    const client = useApolloClient();

    const {
        isLoading: historyLoading,
        startLoading: startHistoryLoading,
        stopLoading: stopHistoryLoading,
    } = useLoadingHook(true);

    const {
        isLoading: refreshLoading,
        startLoading: startRefreshLoading,
        stopLoading: stopRefreshLoading,
    } = useLoadingHook();

    const [transactions, setTransactions] =
        React.useState<MarketplaceTransactionOutput[]>();
    const [totalVolumeIgu, setTotalVolumeIgu] = React.useState<string>();
    const [totalVolumeUsd, setTotalVolumeUsd] = React.useState<string>();

    const getMarketplaceNftTransactionHistory = useCallback(
        async (refresh: boolean, petId: string) => {
            try {
                refresh ? startRefreshLoading() : startHistoryLoading();

                const result =
                    await client.query<MarketplaceNftTransactionsOutputResponse>(
                        {
                            query: GET_MARKETPLACE_NFT_TRANSACTION_HISTORY,
                            fetchPolicy: 'network-only',
                            variables: {
                                petId,
                            },
                        }
                    );

                if (result.data) {
                    setTransactions(
                        result.data.marketplaceNftTransactionHistory
                            .transactions
                    );
                    setTotalVolumeIgu(
                        result.data.marketplaceNftTransactionHistory
                            .totalVolumeIgu
                    );
                    setTotalVolumeUsd(
                        result.data.marketplaceNftTransactionHistory
                            .totalVolumeUsd
                    );
                }
                stopRefreshLoading();
                stopHistoryLoading();
            } catch (error) {
                stopRefreshLoading();
                stopHistoryLoading();
                errorsHandler(error, true);
            }
        },
        [client]
    );

    return {
        refreshLoading,
        historyLoading,
        totalVolumeIgu,
        totalVolumeUsd,
        transactions,
        getMarketplaceNftTransactionHistory,
    };
};

export default useMarketplaceNftTransactionHistory;
