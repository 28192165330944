import { StyleSheet } from 'react-native';

import Colors from '../../../constants/Colors';
import { scale, verticalScale } from '../../../helpers/dimensions';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
        },
        flowContainer: {
            paddingBottom: 0,
        },
        verificationListWrapper: {
            marginHorizontal: scale(20),
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            borderWidth: 1,
            borderRadius: 12,
            borderColor: Colors[theme].border.primary,
        },
        totalSummaryWrapper: {
            paddingTop: verticalScale(16),
            paddingHorizontal: scale(16),
        },
    });

export default styles;
