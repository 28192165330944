import React from 'react';

import FaceId from '@assets/icons/passcode/faceId.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import Button from '@components/Button';
import ButtonsBottom from '@components/ButtonsBottom';
import { View } from '@components/Themed';
import { useLocalAuthentication } from '@hooks/useLocalAuthentication';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import mainStyles from './styles';

export const EnableFaceIdScreen = () => {
    const styles = useThemedStyles(mainStyles);

    const { handleEnableBiometric, authLoading } = useLocalAuthentication();
    const navigation = useNavigation();

    const handleFaceIdPress = async () => {
        await handleEnableBiometric(
            () => navigation.replace(ROUTES.PASSCODE_SUCCESS),
            true
        );
    };

    const handleSkip = () => {
        navigation.replace(ROUTES.PASSCODE_SUCCESS);
    };

    return (
        <View style={styles.container}>
            <View style={styles.center}>
                <BlockHeader
                    type={'emptyState'}
                    emptyStateIcon={<FaceId />}
                    title={i18n.t('passcode.faceId.title')}
                    text={i18n.t('passcode.faceId.subTitle')}
                />
            </View>
            <ButtonsBottom
                disabled={authLoading}
                safe
                title={i18n.t('general.BUTTONS.enableNow')}
                onPress={handleFaceIdPress}>
                <Button
                    disabled={authLoading}
                    debouncedPress
                    type="text"
                    onPress={handleSkip}
                    containerStyle={styles.button}
                    textStyles={styles.buttonColor}
                    title={i18n.t('general.BUTTONS.maybeLater')}
                />
            </ButtonsBottom>
        </View>
    );
};
