import * as React from 'react';

import { Text, View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MysteryLikesGameHistoryOutput } from '@models/mysteryGames';

import StatisticsGameItem from '../StatisticsGameItem';
import stylesMain from './styles';

interface IProps {
    data: MysteryLikesGameHistoryOutput[];
}

const StatisticsGames = ({ data }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={styles.container}>
            <Text style={styles.title}>
                {i18n.t(
                    `HomeScreen.MysteryLikes.MysteryLikesSelectRangeFinish.statistics`
                )}
            </Text>
            {data.map((item) => (
                <StatisticsGameItem key={item.index} item={item} />
            ))}
        </View>
    );
};
export default StatisticsGames;
