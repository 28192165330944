import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        shadow: {
            borderColor: Colors[theme].buttonShadowOutline,
            backgroundColor: 'transparent',
        },
        background: {
            alignItems: 'center',
            justifyContent: 'center',
        },
        whiteBackground: {
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
        },
        isOneColorShadow: {
            borderColor: Colors[theme].alwaysOneColor.shadow,
        },
        isOneColor: {
            backgroundColor: Colors[theme].alwaysOneColor.background,
            borderColor: Colors[theme].alwaysOneColor.border,
        },
    });

export default styles;
