import * as React from 'react';

import HowNftEvolutionWorkScreen from '../../screens/TabBar/Home/NftEvolutionFlow/HowNftEvolutionWork/HowNftEvolutionWorkScreen';
import NftEvolutionCheckoutScreen from '../../screens/TabBar/Home/NftEvolutionFlow/NftEvolutionCheckout/NftEvolutionCheckoutScreen';
import NftEvolutionMintPetScreen from '../../screens/TabBar/Home/NftEvolutionFlow/NftEvolutionMintPet/NftEvolutionMintPetScreen';
import NftEvolutionMintPetSuccessScreen from '../../screens/TabBar/Home/NftEvolutionFlow/NftEvolutionMintPetSuccess/NftEvolutionMintPetSuccessScreen';
import NftEvolutionSelectPetScreen from '../../screens/TabBar/Home/NftEvolutionFlow/NftEvolutionSelectPet/NftEvolutionSelectPetScreen';
import NftEvolutionSelectPetsScreen from '../../screens/TabBar/Home/NftEvolutionFlow/NftEvolutionSelectPets/NftEvolutionSelectPetsScreen';
import NftEvolutionSuccessScreen from '../../screens/TabBar/Home/NftEvolutionFlow/NftEvolutionSuccess/NftEvolutionSuccessScreen';
import WelcomeNftEvolutionScreen from '../../screens/TabBar/Home/NftEvolutionFlow/WelcomeNftEvolution/WelcomeNftEvolutionScreen';
import ROUTES from '../routes';

const NftEvolutionGroup = (RootStack: any) => {
    return (
        <>
            <RootStack.Screen
                name={ROUTES.NFT_EVOLUTION_WELCOME}
                component={WelcomeNftEvolutionScreen}
            />
            <RootStack.Screen
                name={ROUTES.HOW_NFT_EVOLUTION_WELCOME}
                component={HowNftEvolutionWorkScreen}
                options={{
                    presentation: 'modal',
                    headerShown: false,
                }}
            />
            <RootStack.Screen
                name={ROUTES.NFT_EVOLUTION_SELECT_PET}
                component={NftEvolutionSelectPetScreen}
                options={{
                    presentation: 'modal',
                    headerShown: false,
                }}
            />
            <RootStack.Screen
                name={ROUTES.NFT_EVOLUTION_SELECT_PETS}
                component={NftEvolutionSelectPetsScreen}
            />
            <RootStack.Screen
                name={ROUTES.NFT_EVOLUTION_CHECKOUT}
                options={{ gestureEnabled: false }}
                component={NftEvolutionCheckoutScreen}
            />
            <RootStack.Screen
                name={ROUTES.NFT_EVOLUTION_SUCCESS}
                component={NftEvolutionSuccessScreen}
                options={{ gestureEnabled: false }}
            />
            <RootStack.Screen
                name={ROUTES.NFT_EVOLUTION_MINT_PET}
                component={NftEvolutionMintPetScreen}
            />
            <RootStack.Screen
                name={ROUTES.NFT_EVOLUTION_MINT_PET_SUCCESS}
                component={NftEvolutionMintPetSuccessScreen}
            />
        </>
    );
};

export default NftEvolutionGroup;
