import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
            paddingHorizontal: scale(20),
        },

        loading: {
            flex: 1,
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
        },
        listWrapper: {
            flex: 1,
        },
        features: {
            flexDirection: 'row',
            backgroundColor: 'transparent',
            justifyContent: 'space-between',
        },
        feature: {
            width: '47%',
        },
        titleBlock: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: verticalScale(12),
        },
        title: {
            flexDirection: 'row',
            alignItems: 'center',

            marginRight: scale(16),
            flex: 1,
        },
    });

export default styles;
