import React from 'react';
import { View } from 'react-native';

import CustomHorizontalModal from '@components/CustomHorizontalModal';
import LinearLoaderProgress from '@components/LinearLoaderProgress';
import LoadingComponent from '@components/LoadingComponent';
import { cactusGameEndpoint, isDesktop, isWeb } from '@helpers/app';
import { stylesFlex } from '@helpers/style';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import { PlayToEarnProgressProps } from '../../../../../../types';
import { UnityWrapper } from '../../../UnityWrapper';
import { GameModal } from '../../components/GameModal';
import PauseGameTimer from '../../components/PauseGameTimer';
import { P2E_CONFIG } from './config';
import { usePlayToEarnProgress } from './hook';
import mainStyles from './styles';

export const PlayToEarnProgressScreen = ({
    route,
}: PlayToEarnProgressProps) => {
    const {
        modalVisible,
        gameVisible,
        gameState,
        gameData,
        handleOnMessage,
        handleOnDismiss,
        onModalAccept,
        unityWrapperRef,
        progress,
        loadingStatus,
        showLoader,
        onLoadingLineStop,
        timerVisible,
        onTimerEnd,
        errorModalOpen,
        renderAttempt,
        errorModal,
        handleOnErrorAgree,
        errorModalClose,
        errorText,
    } = usePlayToEarnProgress(route.params?.status);
    const styles = useThemedStyles(mainStyles);

    return (
        <View key={`renderAttempt-${renderAttempt}`} style={stylesFlex()}>
            {showLoader && (
                <View style={styles.container}>
                    <LinearLoaderProgress
                        progress={progress}
                        onLoadingLineStop={onLoadingLineStop}
                        statusText={loadingStatus}
                    />
                </View>
            )}

            {gameVisible && (
                <UnityWrapper
                    openErrorModal={() => errorModalOpen()}
                    // @ts-ignore
                    ref={unityWrapperRef}
                    uri={cactusGameEndpoint}
                    onMessage={handleOnMessage}
                />
            )}
            {!gameVisible && !showLoader && <LoadingComponent fullPage />}
            <GameModal
                onAccept={onModalAccept}
                onDismiss={handleOnDismiss}
                isVisible={modalVisible}
                modalStatus={gameState}
                gameData={gameData}
                livesCount={P2E_CONFIG.lives}
            />
            <PauseGameTimer
                visibility={timerVisible}
                onTimerEnd={onTimerEnd}
                startTimer
                gameTimerText={i18n.t(
                    `playToEarn.modals.gameTimer.text${
                        isWeb ? (isDesktop ? 'WebDesktop' : 'WebMobile') : ''
                    }`
                )}
            />
            <CustomHorizontalModal
                isVisible={errorModal}
                infoText={errorText}
                titleText={i18n.t('errorBoundary.title')}
                firstButtonText={i18n.t('errorBoundary.reloadApp')}
                secondButtonText={i18n.t(
                    'moveToEarn.modals.leaveWithOutFinishBtn1'
                )}
                onFirstButtonClick={handleOnErrorAgree}
                onSecondButtonClick={errorModalClose}
            />
        </View>
    );
};
