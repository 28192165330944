import * as React from 'react';
import { useMemo } from 'react';
import { View } from 'react-native';

import { ISocialToEarnCard } from '@constants/shareCardTemplatesNew';
import { useConfig } from '@contexts/ConfigContext';
import { useDimensions } from '@contexts/DimensionsContext';
import { PlayContext } from '@contexts/PlayContext';
import useThemedStyles from '@hooks/useThemedStyles';
import { getDeeplinkSocializeToEarn } from '@navigation/helpers';

import BybitId from './bybitId';
import DefaultId from './defaultId';
import stylesMain from './styles';

interface IProps {
    templateColor: ISocialToEarnCard;
    tiktok?: boolean;
}

const PetCardIdNew = ({ templateColor, tiktok = false }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { windowWidth } = useDimensions();
    const { config } = useConfig();

    const { socialTask } = React.useContext(PlayContext);

    // handle Deep linking
    const qrCodeLink = useMemo(
        () => getDeeplinkSocializeToEarn(socialTask?.latestTask?.token),
        [socialTask]
    );
    const token = useMemo(
        () => socialTask?.latestTask?.token,
        [socialTask?.latestTask.token]
    );

    const renderContent = React.useCallback(() => {
        switch (config?.petIdType) {
            case 'Bybit':
                return (
                    <BybitId
                        templateColor={templateColor}
                        token={token}
                        url={socialTask?.latestTask?.pet?.image.url}
                    />
                );

            default:
                return (
                    <DefaultId
                        templateColor={templateColor}
                        qrCodeLink={qrCodeLink}
                        token={token}
                        url={socialTask?.latestTask?.pet?.image.url}
                    />
                );
        }
    }, [qrCodeLink, config?.petIdType, socialTask, token, templateColor]);

    return (
        <View
            collapsable={false}
            style={[
                styles.container,
                { backgroundColor: templateColor.background },
                tiktok && {
                    ...styles.tiktok,
                    ...{ marginVertical: windowWidth / 2 },
                },
            ]}>
            {renderContent()}
        </View>
    );
};
export default PetCardIdNew;
