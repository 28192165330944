import * as React from 'react';

import Button from '@components/Button';
import { ICON_NAMES } from '@components/Icons';
import CoinIcon from '@components/Icons/CoinIcon';
import { Text, View } from '@components/Themed';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    onEditAmountPress?: () => void;
    amount: string;
    withContainer?: boolean;
}

const TransactionDetailsTopUp = ({
    onEditAmountPress,
    amount,
    withContainer,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={withContainer && styles.container}>
            <View style={styles.verificationItem}>
                <View style={styles.left}>
                    <View>
                        <CoinIcon size={64} slug={Coin.vigu} />
                    </View>
                    <View style={styles.verificationTextContainer}>
                        <Text style={styles.verificationTitle}>
                            {i18n.t('checkout.fields.amount')}
                        </Text>
                        <Text
                            style={styles.verificationSlug}
                            ellipsizeMode="middle"
                            numberOfLines={1}>
                            {`${amount} ${Coin.vigu}`}
                        </Text>
                    </View>
                </View>
                <View style={styles.right}>
                    <Text
                        style={styles.verificationSlug}
                        ellipsizeMode="tail"
                        numberOfLines={1}>
                        {`${amount} ${Coin.igu}`}
                    </Text>
                    {!!onEditAmountPress && (
                        <Button
                            containerStyle={styles.button}
                            type="outline"
                            iconName={ICON_NAMES.PENCIL}
                            size={'md'}
                            onPress={onEditAmountPress}
                        />
                    )}
                </View>
            </View>
        </View>
    );
};
export default TransactionDetailsTopUp;
