import React from 'react';

import {
    MysteryBoxOpenBoxesOutput,
    MysteryBoxOpenBoxesResponse,
} from '@Data/Models';
import { GET_MYSTERY_BOX_OPEN_BOXES } from '@Data/Requests';
import { useApolloClient } from '@apollo/client';
import { toastErrorUnknown } from '@helpers/toastNotification';

const useMysteryBoxOpenList = () => {
    const client = useApolloClient();

    const [openList, setOpenList] =
        React.useState<MysteryBoxOpenBoxesOutput[]>();
    const [loadingOpenList, setLoadingOpenList] = React.useState(false);
    const [errorOpenList, setErrorOpenList] = React.useState(false);

    const getAllOpenedMysteryBoxes = async (
        silent: boolean,
        withReturn?: boolean
    ) => {
        setErrorOpenList(false);
        silent ? setLoadingOpenList(false) : setLoadingOpenList(true);
        try {
            const result = await client.query<MysteryBoxOpenBoxesResponse>({
                query: GET_MYSTERY_BOX_OPEN_BOXES,
                fetchPolicy: 'no-cache',
            });

            if (result.data) {
                if (withReturn) {
                    return result.data.lootboxRewards;
                }
                setOpenList(result.data.lootboxRewards);
            }
        } catch (error) {
            Console.error(error);
            setErrorOpenList(true);
            toastErrorUnknown();
            setLoadingOpenList(false);
        }
        setLoadingOpenList(false);
    };

    return {
        loadingOpenList,
        openList,
        errorOpenList,
        getAllOpenedMysteryBoxes,
    };
};

export default useMysteryBoxOpenList;
