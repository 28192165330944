import * as React from 'react';
import { useMemo } from 'react';
import { TouchableOpacity } from 'react-native';

import { useConfig } from '@contexts/ConfigContext';
import { scale } from '@helpers/dimensions';
import { dateFormatterYear } from '@helpers/time';
import { truncateEthAddress } from '@helpers/wallet';

import { ReferralUsersItem } from '../../../../../Data/Models';
import IndirectUserIcon from '../../../../../assets/icons/referrals/indirectUser.svg';
import CircleDivider from '../../../../../components/CircleDivider';
import Icon, { ICON_NAMES } from '../../../../../components/Icons';
import OtherUserAvatar from '../../../../../components/OtherUserAvatar';
import { Text, View } from '../../../../../components/Themed';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import stylesMain from './styles';

interface IProps {
    item: ReferralUsersItem;
    onPress?: () => void;
}

const ReferralListItem = ({ item, onPress }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { type, createdAt, user, active } = item;

    const { config } = useConfig();

    const isDirect = type === 'Direct'; // add logic direct
    const date = dateFormatterYear(createdAt); // add logic date

    const energyThresholdReached = useMemo(
        () =>
            config &&
            Number(item?.user.energy) >= Number(config?.rewardEnergyThreshold),
        [item, config]
    );

    return (
        <TouchableOpacity
            style={[styles.shadow, active && styles.activeShadow]}
            activeOpacity={0.7}
            onPress={onPress}>
            <View style={[styles.itemContainer, active && styles.active]}>
                <View style={styles.left}>
                    <View style={styles.imageWrapper}>
                        <OtherUserAvatar
                            size={scale(40)}
                            imageUrl={user.mainPet?.image?.thumbnailUrl}
                            userId={user.walletAddress}
                        />
                        {!isDirect && (
                            <View style={styles.indirectIcon}>
                                <IndirectUserIcon width={20} height={20} />
                            </View>
                        )}
                    </View>
                    <View style={styles.textWrapper}>
                        <Text
                            style={styles.address}
                            ellipsizeMode="middle"
                            numberOfLines={1}>
                            {truncateEthAddress(
                                user.username || user.walletAddress
                            )}
                        </Text>
                        <View style={styles.footerContainer}>
                            <Text style={styles.date}>{date}</Text>
                            <CircleDivider />
                            <View style={styles.energyWrapper}>
                                <Icon name={ICON_NAMES.ENERGY} size={20} />
                                <Text
                                    style={[
                                        styles.energyText,
                                        energyThresholdReached &&
                                            styles.energyThresholdReached,
                                    ]}>
                                    {item?.user.energy || 0}
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        </TouchableOpacity>
    );
};
export default React.memo(ReferralListItem);
