import * as React from 'react';

import FruitSlasherActive from '@assets/icons/achievements/active/fruitSlasher.svg';
import GhostriderActive from '@assets/icons/achievements/active/ghostRider.svg';
import LeaderboardMasterActive from '@assets/icons/achievements/active/leaderBoardMaster.svg';
import LoyalFighterActive from '@assets/icons/achievements/active/loyalFighter.svg';
import MoneyBagActive from '@assets/icons/achievements/active/moneyBag.svg';
import LuckyStrokeActive from '@assets/icons/achievements/active/strokeOfLuck.svg';
import UndercoverMillionaireActive from '@assets/icons/achievements/active/undercoverMillionaire.svg';
import FruitSlasher from '@assets/icons/achievements/noActive/fruitSlasher.svg';
import Ghostrider from '@assets/icons/achievements/noActive/ghostRider.svg';
import LeaderboardMaster from '@assets/icons/achievements/noActive/leaderBoardMaster.svg';
import LoyalFighter from '@assets/icons/achievements/noActive/loyalFighter.svg';
import MoneyBag from '@assets/icons/achievements/noActive/moneyBag.svg';
import LuckyStroke from '@assets/icons/achievements/noActive/strokeOfLuck.svg';
import UndercoverMillionaire from '@assets/icons/achievements/noActive/undercoverMillionaire.svg';
import { USER_ACHIEVEMENTS } from '@models/achievements';

import { IconType } from '../types';

interface IProps extends IconType {
    slug: USER_ACHIEVEMENTS;
    achieved: boolean;
}

const UserAchievementsIcon = ({ slug, achieved }: IProps) => {
    switch (slug) {
        case USER_ACHIEVEMENTS.FruitSlasher:
            return achieved ? (
                <FruitSlasherActive width={44} height={56} />
            ) : (
                <FruitSlasher width={44} height={56} />
            );
        case USER_ACHIEVEMENTS.Ghostrider:
            return achieved ? (
                <GhostriderActive width={44} height={56} />
            ) : (
                <Ghostrider width={44} height={56} />
            );
        case USER_ACHIEVEMENTS.LeaderboardMaster:
            return achieved ? (
                <LeaderboardMasterActive width={56} height={46} />
            ) : (
                <LeaderboardMaster width={56} height={46} />
            );
        case USER_ACHIEVEMENTS.LoyalFighter:
            return achieved ? (
                <LoyalFighterActive width={56} height={36} />
            ) : (
                <LoyalFighter width={56} height={36} />
            );
        case USER_ACHIEVEMENTS.LuckyStroke:
            return achieved ? (
                <LuckyStrokeActive width={52} height={56} />
            ) : (
                <LuckyStroke width={52} height={56} />
            );
        case USER_ACHIEVEMENTS.MoneyBag:
            return achieved ? (
                <MoneyBagActive width={40} height={56} />
            ) : (
                <MoneyBag width={40} height={56} />
            );
        case USER_ACHIEVEMENTS.UndercoverMillionaire:
            return achieved ? (
                <UndercoverMillionaireActive width={48} height={56} />
            ) : (
                <UndercoverMillionaire width={48} height={56} />
            );

        default:
            return <></>;
    }
};

export default UserAchievementsIcon;
