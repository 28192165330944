import React from 'react';
import {
    Easing,
    runOnJS,
    useAnimatedStyle,
    useSharedValue,
    withDelay,
    withTiming,
} from 'react-native-reanimated';

import * as d3Shape from 'd3-shape';
import _ from 'lodash';

import { useMutation } from '@apollo/client';
import { useDimensions } from '@contexts/DimensionsContext';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { A } from '@expo/html-elements';
import { isWeb, isWebAndroid } from '@helpers/app';
import { errorsHandler } from '@helpers/errors';
import { toastOops, wait } from '@helpers/helpers';
import { useLoadingHook } from '@hooks/useLoadingHook';
import useOpenModalScreen from '@hooks/useOpenModalScreen';
import usePreventDoubleClick from '@hooks/usePreventDoubleClick';
import { useSaveHeight } from '@hooks/useSaveHeight';
import { useVisibleHook } from '@hooks/useVisibleHook';
import {
    QuantumSpinGameOutput,
    QuantumSpinGamePrizeOutput,
    QuantumSpinGameSpinOutputResponse,
} from '@models/mysteryGames';
import { QUANTUM_SPIN_GAME_SPIN } from '@requests/quantumSpin';

import { quantumSpinFindWinIndex } from '../QuantumSpinFlow/helpers';

const wheelConfig = {
    width: 556,
    innerWidth: 540,
    outerRadius: 80,
    wheelSpins: 10,
    wheelSpinAnimationDuration: 15000,
    wheelRotation: 360,
    maxOutPercentForSmallScreens: 15,
    wheelMarginTop: 52,
    narrowMarginTop: 30,
};

export const useQuantumSpinWheel = (
    prizes: QuantumSpinGamePrizeOutput[],
    setIsWheelAnimation: React.Dispatch<React.SetStateAction<boolean>>
) => {
    const { windowWidth, safeBottom } = useDimensions();

    const width = wheelConfig.width;
    const { getGamingAvailableBalance, availableBalance } = useMysteryGames();
    const leftSpace = React.useMemo(
        () => (windowWidth - width) / 2,
        [windowWidth]
    );

    const innerWidth = wheelConfig.innerWidth;

    const lengthOfData = prizes.length;

    const angleOfSegment = React.useMemo(
        () => wheelConfig.wheelRotation / lengthOfData,
        [lengthOfData]
    );

    const angleOffset = React.useMemo(
        () => angleOfSegment / 2,
        [angleOfSegment]
    );

    const rotation = useSharedValue(0);
    const easing = Easing.bezier(0.1, 1, 0.2, 1);
    const animatedStyles = useAnimatedStyle(() => {
        return {
            transform: [{ rotateZ: `${rotation.value}deg` }],
        };
    });

    const [winPrize, setWinPrize] = React.useState<QuantumSpinGameOutput>();

    const {
        isVisible: confirmationModal,
        open: openConfirmationModal,
        close: closeConfirmationModal,
    } = useVisibleHook();

    const {
        isVisible: prizeAnimation,
        open: openPrizeAnimation,
        close: closePrizeAnimation,
    } = useVisibleHook();

    React.useEffect(() => {
        if (prizeAnimation) getGamingAvailableBalance(false);
    }, [prizeAnimation]);

    const {
        isLoading: purchaseLoading,
        startLoading: startPurchaseLoading,
        stopLoading: stopPurchaseLoading,
    } = useLoadingHook();

    const handleCloseConfirmModal = React.useCallback(() => {
        if (prizeAnimation) {
            return;
        }
        closeConfirmationModal();
    }, [prizeAnimation]);

    const onSpinPress = async () => {
        openConfirmationModal();
        await wait(1000);
        rotation.value = 0;
    };

    const { onClick, disabled: clickDisabled } =
        usePreventDoubleClick(onSpinPress);

    const handleSetIsWheelAnimation = (status: boolean) => {
        setIsWheelAnimation(status);
    };

    const [spin] = useMutation<QuantumSpinGameSpinOutputResponse>(
        QUANTUM_SPIN_GAME_SPIN
    );

    const onPurchaseConfirm = React.useCallback(async () => {
        startPurchaseLoading();
        await wait(1000);
        try {
            const result = await spin();
            if (result.data?.quantumSpinGameSpin) {
                setWinPrize(result.data.quantumSpinGameSpin);
                const { iguAmountWei } = result.data.quantumSpinGameSpin;
                const index = quantumSpinFindWinIndex(prizes, iguAmountWei);
                if (index === -1) {
                    stopPurchaseLoading();
                    await toastOops();
                    return;
                }

                stopPurchaseLoading();
                closeConfirmationModal();
                handleSetIsWheelAnimation(true);
                const degree = -(angleOfSegment * index + angleOffset);
                const rotationDegree =
                    rotation.value +
                    wheelConfig.wheelSpins * wheelConfig.wheelRotation +
                    degree;

                rotation.value = withDelay(
                    500,
                    withTiming(
                        rotationDegree,
                        {
                            duration: wheelConfig.wheelSpinAnimationDuration,
                            easing,
                        },
                        () => {
                            runOnJS(handleSetIsWheelAnimation)(false);
                            runOnJS(openPrizeAnimation)();
                        }
                    )
                );
            }
        } catch (error) {
            stopPurchaseLoading();
            errorsHandler(error, true);
        }
    }, [prizes, angleOfSegment, angleOffset]);

    const makeWheel = (
        data: QuantumSpinGamePrizeOutput[],
        padAngle: number,
        outerRadius: number,
        innerRadius: number,
        secondColor = false
    ) => {
        const pieData: number[] = Array.from<any>({ length: data.length }).fill(
            1
        );
        const arcs = d3Shape.pie()(pieData);
        return arcs.map((arc, index) => {
            const instance = d3Shape
                .arc()
                .padAngle(padAngle)
                .outerRadius(outerRadius)
                .innerRadius(innerRadius);
            return {
                //@ts-ignore
                path: instance(arc),
                color: secondColor
                    ? data[index].borderColor
                    : data[index].backgroundColor,
                value: data[index],
                //@ts-ignore
                centroid: instance.centroid(arc),
            };
        });
    };

    const { handleOpenModalScreen } = useOpenModalScreen({
        modalClose: handleCloseConfirmModal,
        modalOpen: openConfirmationModal,
    });

    const isNarrowWeb = React.useMemo(
        () => isWeb && windowWidth < 400,
        [isWeb, windowWidth]
    );

    const { height, getHeight } = useSaveHeight('Wheel1');

    const newSizes: { top: number; scale: number } = React.useMemo(() => {
        if (!height) return { top: wheelConfig.wheelMarginTop, scale: 1 };

        if (isNarrowWeb) {
            const percent = 100 - (windowWidth / width) * 100;
            const difference =
                (percent - wheelConfig.maxOutPercentForSmallScreens) / 100;

            const scale = 1 - difference;
            const top =
                -(height * difference) +
                wheelConfig.narrowMarginTop +
                (isWebAndroid ? safeBottom : 0);

            return { top, scale };
        }

        return { top: wheelConfig.wheelMarginTop, scale: 1 };
    }, [width, height, isNarrowWeb, windowWidth, safeBottom, isWebAndroid]);

    return {
        animatedStyles,
        leftSpace,
        innerWidth,
        winPrize,
        closePrizeAnimation,
        purchaseLoading,
        onClick,
        clickDisabled,
        confirmationModal,
        onPurchaseConfirm,
        handleCloseConfirmModal,
        width,
        wheelConfig,
        lengthOfData,
        angleOffset,
        prizeAnimation,
        makeWheel,
        handleOpenModalScreen,
        getHeight,
        newSizes,
        isNarrowWeb,
    };
};
