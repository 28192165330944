import * as React from 'react';

import CheckoutSuccessScreen from '@components/CheckoutSuccessScreen';
import ImageWithName from '@components/ImageWithName';
import TransactionSummary from '@components/TransactionSummary';
import { useUser } from '@contexts/UserContext';
import useFeeInfo from '@hooks/useFeeInfo';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import { MarketPlaceDelistSuccessProps } from '../../../../types';

const SCREEN = 'MarketPlaceDelistSuccessScreen';
const MarketPlaceDelistSuccessScreen = ({
    route,
}: MarketPlaceDelistSuccessProps) => {
    const navigation = useNavigation();
    navigation.blockHardwareBack();

    const { reloadUser } = useUser();

    React.useEffect(() => {
        reloadUser();
    }, []);

    const { renderFeeInfoIcon } = useFeeInfo();

    const { petName, petImg, transactionResult } = route.params;

    const handleNavigateToMarketPlace = () => {
        navigation.navigate(ROUTES.MARKETPLACE, {
            screen: ROUTES.MARKETPLACE_ACTIVE_ORDERS,
        });
    };

    const transactionDetailsValues = [
        {
            key: i18n.t('checkout.fields.nft'),
            value: <ImageWithName petName={petName} petImg={petImg} />,
        },
        {
            key: i18n.t('checkout.fields.from'),
            value: i18n.t('checkout.fields.nftMarketplace'),
        },
        {
            key: i18n.t('checkout.fields.to'),
            value: i18n.t('checkout.fields.nftWallet'),
        },
        !!transactionResult?.transactionFeeFormatted
            ? {
                  key: i18n.t('checkout.fields.fee'),
                  value: transactionResult?.transactionFeeFormatted,
                  icon: renderFeeInfoIcon(true),
              }
            : undefined,
    ];

    return (
        <CheckoutSuccessScreen
            title={i18n.t(`MarketPlace.${SCREEN}.title`, {
                name: petName,
            })}
            text={''}
            onPressButtonMain={handleNavigateToMarketPlace}
            buttonMainText={i18n.t(`general.BUTTONS.viewMarketplace`)}
            TransactionDetailsComponent={
                <TransactionSummary values={transactionDetailsValues} />
            }
            explorerUrl={transactionResult?.explorerUrl}
        />
    );
};

export default MarketPlaceDelistSuccessScreen;
