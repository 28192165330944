import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { moderateScale, scale, verticalScale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        infoItem: {
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(16),
        },
        infoTitle: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
            marginBottom: verticalScale(4),
        },
        infoText: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
        },
        top: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        left: {
            flex: 1,
            marginLeft: scale(8),
        },
    });

export default styles;
