import { StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../../helpers/dimensions';
import font from '../../../../helpers/font';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        divider: {
            borderTopWidth: 0.5,
            borderTopColor: Colors[theme].border.primary,
            marginVertical: verticalScale(20),
        },
        title: {
            marginLeft: verticalScale(8),
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '600',
            }),
        },
        optionsContainer: {
            paddingHorizontal: scale(20),
        },
        optionContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            padding: scale(16),
            zIndex: 1,
        },
        firstOption: {
            borderTopLeftRadius: moderateScale(12),
            borderTopRightRadius: moderateScale(12),
        },
        lastOption: {
            borderBottomWidth: 1,
            borderBottomLeftRadius: moderateScale(12),
            borderBottomRightRadius: moderateScale(12),
        },
        activeOption: {
            borderColor: Colors[theme].radio.borderActive,
            backgroundColor: Colors[theme].backgroundSelectable,
            zIndex: 2,
        },
        circle: {
            width: 20,
            height: 20,
            borderRadius: 10,
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            alignItems: 'center',
            justifyContent: 'center',
        },
        contentContainer: {
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: 'transparent',
        },
    });

export default styles;
