import React from 'react';

export const useLoadingHook = (initialState?: boolean) => {
    const [isLoading, setIsLoading] = React.useState<boolean>(
        initialState || false
    );

    const startLoading = () => {
        setIsLoading(true);
    };
    const stopLoading = () => {
        setIsLoading(false);
    };

    return { isLoading, startLoading, stopLoading };
};
