import * as React from 'react';

import StepOneImage from '@assets/icons/mysteryGames/MysteryLikesHowItWorks/stepOne.svg';
import StepThreeImage from '@assets/icons/mysteryGames/MysteryLikesHowItWorks/stepThree.svg';
import StepTwoImage from '@assets/icons/mysteryGames/MysteryLikesHowItWorks/stepTwo.svg';
import ButtonsBottom from '@components/ButtonsBottom';
import Carousel from '@components/Carousel';
import CarouselScrollView from '@components/Carousel/components/CarouselScrollView';
import { Text, View } from '@components/Themed';
import { useDimensions } from '@contexts/DimensionsContext';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import GamesFeatures from '../GamesFeatures';
import stylesMain from './styles';

const data = ['stepOne', 'stepTwo', 'stepThree'];
const flow = 'MysteryLikesHowItWorks';

interface IProps {
    onButtonPress: () => void;
}

const MysteryLikesHowItWorks = ({ onButtonPress }: IProps) => {
    const { windowWidth } = useDimensions();
    const styles = useThemedStyles(stylesMain);

    const { mysteryLikesGameStats } = useMysteryGames();

    const renderItem = (item: string) => {
        const icon = () => {
            switch (item) {
                case 'stepOne':
                    return <StepOneImage />;
                case 'stepTwo':
                    return <StepTwoImage />;

                default:
                    return <StepThreeImage />;
            }
        };

        return (
            <CarouselScrollView bounces={false}>
                <View style={styles.itemContainer}>
                    <View style={styles.content}>
                        <View style={styles.image}>{icon()}</View>

                        <View style={styles.textBlock}>
                            <Text style={styles.title}>
                                {i18n.t(
                                    `HomeScreen.MysteryLikes.${flow}.${item}.title`
                                )}
                            </Text>

                            <Text style={styles.text}>
                                {i18n.t(
                                    `HomeScreen.MysteryLikes.${flow}.${item}.text`,
                                    {
                                        gamesAmount:
                                            mysteryLikesGameStats?.maxRoundsCount,
                                    }
                                )}
                            </Text>
                        </View>
                    </View>
                </View>
                <GamesFeatures
                    activeGamesCount={mysteryLikesGameStats?.activeGamesCount}
                    totalPricePoolIguAmountWei={
                        mysteryLikesGameStats?.totalPricePoolIguAmountWei
                    }
                />
            </CarouselScrollView>
        );
    };

    return (
        <>
            <Carousel
                data={data}
                sliderWidth={windowWidth}
                itemWidth={windowWidth}
                renderItem={renderItem}
                fullHeight
                pagination
                centredVertical
                bulletActiveStyle={styles.bulletActive}
                bulletStyle={styles.bulletStyle}
            />
            <View style={styles.buttonWrapper}>
                <ButtonsBottom
                    isOneColor
                    title={i18n.t('HomeScreen.exploreGames')}
                    onPress={onButtonPress}
                    safe
                    containerStyle={styles.button}
                    buttonType="outline"
                />
            </View>
        </>
    );
};

export default MysteryLikesHowItWorks;
