import * as React from 'react';
import { ScrollView, View } from 'react-native';

import ICON from '@assets/icons/emptyState/nftEvolutionNoPet.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import ModalBottom from '@components/ModalBottom';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { useNavigation } from '@navigation/useNavigation';

import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
}

const MarketPlaceModalNoPet = ({ isVisible, onClose }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const navigation = useNavigation();

    const handleMint = () => {
        onClose();
        navigation.navigateToMint();
    };

    return (
        <ModalBottom
            isVisible={isVisible}
            onClose={onClose}
            modalHeight={600}
            containerStyle={styles.containerStyle}>
            <ScrollView style={styles.container} bounces={false}>
                <BlockHeader
                    emptyStateIcon={<ICON />}
                    type="emptyState"
                    title={i18n.t('MarketPlace.MarketPlaceModalNoPet.title')}
                    text={i18n.t('MarketPlace.MarketPlaceModalNoPet.text')}
                />
            </ScrollView>
            <ButtonsBottom
                title={i18n.t('general.BUTTONS.mintNewNft')}
                onPress={handleMint}
                safe
            />
        </ModalBottom>
    );
};
export default MarketPlaceModalNoPet;
