import { StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import { verticalScale } from '../../../../helpers/dimensions';
import font from '../../../../helpers/font';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        verificationItem: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        verificationTitle: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '600',
            }),
        },
        verificationSlug: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '600',
            }),
            textTransform: 'capitalize',
        },
        verificationTextContainer: {
            marginLeft: verticalScale(12),
            flex: 1,
        },
        left: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
        },
        right: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        image: {
            width: 64,
            height: 64,
            borderRadius: 6,
        },
        divider: {
            marginVertical: verticalScale(16),
        },
        additionMargin: {
            marginRight: verticalScale(12),
        },
    });

export default styles;
