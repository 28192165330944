import * as React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import Divider from '@components/Divider';
import Icon, { ICON_NAMES } from '@components/Icons';
import MysteryGamesContinueIcon from '@components/Icons/MysteryGamesContinueIcon';
import ModalBottom from '@components/ModalBottom';
import ProgressBar from '@components/ProgressBar';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT } from '@components/TypoText/types';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import usePreventDoubleClick from '@hooks/usePreventDoubleClick';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';

import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
    onMysteryLikes: () => void;
    onNftRoyal: () => void;
}

const MysteryGamesContinueModal = ({
    isVisible,
    onClose,
    onMysteryLikes,
    onNftRoyal,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { mysteryLikesPendingGame } = useMysteryGames();

    const { onClick: handleOnMysteryLikes, disabled: disabledMysteryLikes } =
        usePreventDoubleClick(onMysteryLikes, 3000);
    const { onClick: handleOnNftRoyal, disabled: disableNftRoyal } =
        usePreventDoubleClick(onNftRoyal, 3000);

    return (
        <ModalBottom isVisible={isVisible} onClose={onClose} modalHeight={550}>
            <ScrollView
                style={styles.container}
                showsVerticalScrollIndicator={false}>
                <TouchableOpacity activeOpacity={1}>
                    <BlockHeader
                        containerStyle={styles.header}
                        type="primary"
                        title={i18n.t(
                            'HomeScreen.MysteryGamesContinueModal.title'
                        )}
                        text={i18n.t(
                            'HomeScreen.MysteryGamesContinueModal.text'
                        )}
                    />
                </TouchableOpacity>
                <View style={styles.itemWrapper}>
                    <TouchableOpacity
                        style={styles.itemContainer}
                        activeOpacity={0.7}
                        disabled={disabledMysteryLikes || disableNftRoyal}
                        onPress={handleOnMysteryLikes}>
                        <View style={styles.left}>
                            <MysteryGamesContinueIcon
                                slug={MYSTERY_GAMES.mysteryLikes}
                            />
                            <View style={styles.textBlock}>
                                <TypoText
                                    type={TYPO_TEXT.SUBHEAD_BIG_BOLD}
                                    additionalStyles={styles.textGap}>
                                    {i18n.t(
                                        `HomeScreen.GamesBlock.games.${MYSTERY_GAMES.mysteryLikes}.title`
                                    )}
                                </TypoText>
                                <TypoText type={TYPO_TEXT.BODY_SMALL}>
                                    {i18n.t(
                                        `HomeScreen.GamesBlock.games.${MYSTERY_GAMES.mysteryLikes}.text`
                                    )}
                                </TypoText>
                                {!!mysteryLikesPendingGame && (
                                    <ProgressBar
                                        amount={
                                            mysteryLikesPendingGame.roundsCompletedCount
                                        }
                                        maxAmount={
                                            mysteryLikesPendingGame.roundsCount
                                        }
                                        containerStyle={styles.progressBar}
                                    />
                                )}
                            </View>
                        </View>
                        <View style={styles.icon}>
                            <Icon
                                name={ICON_NAMES.ARROW_RIGHT_SECONDARY}
                                size={20}
                                color={styles.iconColor.color}
                            />
                        </View>
                    </TouchableOpacity>
                    <Divider customStyles={styles.divider} />
                    <TouchableOpacity
                        style={styles.itemContainer}
                        activeOpacity={0.7}
                        disabled={disableNftRoyal || disabledMysteryLikes}
                        onPress={handleOnNftRoyal}>
                        <View style={styles.left}>
                            <MysteryGamesContinueIcon
                                slug={MYSTERY_GAMES.nftRoyal}
                            />
                            <View style={styles.textBlock}>
                                <TypoText
                                    type={TYPO_TEXT.SUBHEAD_BIG_BOLD}
                                    additionalStyles={styles.textGap}>
                                    {i18n.t(
                                        `HomeScreen.GamesBlock.games.${MYSTERY_GAMES.nftRoyal}.title`
                                    )}
                                </TypoText>
                                <TypoText type={TYPO_TEXT.BODY_SMALL}>
                                    {i18n.t(
                                        `HomeScreen.GamesBlock.games.${MYSTERY_GAMES.nftRoyal}.text`
                                    )}
                                </TypoText>
                            </View>
                        </View>
                        <View style={styles.icon}>
                            <Icon
                                name={ICON_NAMES.ARROW_RIGHT_SECONDARY}
                                size={20}
                                color={styles.iconColor.color}
                            />
                        </View>
                    </TouchableOpacity>
                </View>
            </ScrollView>
        </ModalBottom>
    );
};

export default MysteryGamesContinueModal;
