import * as React from 'react';
import { ScrollView } from 'react-native';

import AnimatedTextLoading from '@components/AnimatedTextLoading';
import { Text, View } from '@components/Themed';
import { NFT_EVOLUTION_COLOR } from '@contexts/MysteryGamesContext';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import BreedingAnimatedCards from '../BreedingAnimatedCards';
import BreedingAnimationProcess from '../BreedingAnimationProcess';
import stylesMain from './styles';

const SCREEN = 'NftEvolutionCheckout';

interface IProps {
    breedLoaded: boolean;
}

const NftEvolutionBreedProcess = ({ breedLoaded }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={styles.main}>
            <ScrollView
                showsVerticalScrollIndicator={false}
                bounces={false}
                contentContainerStyle={styles.container}>
                <BreedingAnimatedCards
                    withAnimation
                    containerStyle={styles.containerStyle}
                />

                <View style={styles.content}>
                    {
                        <View style={styles.blockHeaderLoading}>
                            <AnimatedTextLoading
                                isLoading={true}
                                staticText={i18n.t(
                                    `HomeScreen.NftEvolution.${SCREEN}.loading.title`
                                )}
                                loadingText={'...'}
                                backgroundColor={NFT_EVOLUTION_COLOR}
                                textStyle={styles.title}
                            />
                            <Text style={styles.text}>
                                {i18n.t(
                                    `HomeScreen.NftEvolution.${SCREEN}.loading.text`
                                )}
                            </Text>
                        </View>
                    }
                </View>
                <BreedingAnimationProcess canFinishAnimation={breedLoaded} />
            </ScrollView>
        </View>
    );
};

export default NftEvolutionBreedProcess;
