import { StyleSheet } from 'react-native';
import { height } from 'react-native-size-scaling';

import Colors from '@constants/Colors';
import { NFT_EVOLUTION_COLOR } from '@contexts/MysteryGamesContext';
import { isWeb } from '@helpers/app';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';
import { stylesAlignCenter } from '@helpers/style';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
        },

        navigation: {
            backgroundColor: NFT_EVOLUTION_COLOR,
            borderBottomRightRadius: 0,
            borderBottomLeftRadius: 0,
        },
        content: {
            marginTop: verticalScale(32),
            paddingHorizontal: scale(20),
            paddingBottom: verticalScale(24),
            backgroundColor: 'transparent',
        },
        transparent: { backgroundColor: 'transparent' },

        white: {
            color: Colors[theme].text.onLight,
        },
        dna: {
            backgroundColor: Colors[theme].alwaysOneColor.background,
            padding: 10,
            borderRadius: 100,
            alignSelf: 'center',
            position: 'absolute',
            bottom: -26,
        },
        table: { marginVertical: verticalScale(20) },

        button: {
            marginTop: verticalScale(16),
            alignSelf: 'center',
        },
        blockHeaderLoading: {
            backgroundColor: 'transparent',
        },
        title: {
            textAlign: 'center',
            color: Colors[theme].text.onLight,
            ...font({
                size: 22,
                height: 30,
                weight: '800',
            }),
            marginBottom: verticalScale(8),
        },
        text: {
            textAlign: 'center',
            color: Colors[theme].text.onLight,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
        container: {
            paddingTop: isWeb ? verticalScale(60) : 0,
            justifyContent: 'center',
            flex: 1,
        },
        loading: {
            ...stylesAlignCenter(),
        },
        textStyle: {
            color: Colors[theme].text.onLight,
        },
        containerStyle: {
            marginLeft: '5%',
        },
    });

export default styles;
