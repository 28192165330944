import { StyleSheet } from 'react-native';

import Colors from '../../../../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../../../../helpers/dimensions';
import font from '../../../../../../helpers/font';
import { theme } from '../../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            minHeight: 200,
            justifyContent: 'space-between',
        },

        listStyle: {
            flex: 1,
            marginTop: verticalScale(4),
            marginBottom: verticalScale(8),
        },
        gap: {
            marginBottom: verticalScale(16),
        },
        termsWrapper: {
            marginTop: verticalScale(6),
        },
        term: {
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: verticalScale(6),
        },
        circle: {
            width: 6,
            height: 6,
            borderRadius: 1000,
            backgroundColor: Colors[theme].circle,
        },
        termText: {
            marginLeft: scale(6),
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
            letterSpacing: 0.08,
        },
        buttonWrapper: {
            marginTop: verticalScale(16),
        },
        active: {
            backgroundColor: Colors[theme].shadow.selected,
        },
        activeContainer: {
            borderColor: Colors[theme].border.accent,
        },
        buttons: {
            paddingHorizontal: 0,
        },
    });

export default styles;
