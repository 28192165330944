import * as React from 'react';

import ButtonsBottom from '@components/ButtonsBottom';
import Carousel from '@components/Carousel';
import { ICON_NAMES } from '@components/Icons';
import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBarModal from '@navigation/NavigationBar/NavigationBarModal';

import StakingDetailedInfo from '../components/StakingDetailedInfo/StakingDetailedInfo';
import { useStakingDetailedInfoScreen } from './hook';
import stylesMain from './styles';

const StakingDetailedInfoScreen = () => {
    const styles = useThemedStyles(stylesMain);
    const {
        windowWidth,
        navigation,
        setSnapItem,
        stakingPlans,
        handleSelectPlan,
        isDisabled,
        isIguDrop,
    } = useStakingDetailedInfoScreen();

    return (
        <View style={styles.main}>
            <View style={styles.header}>
                <NavigationBarModal
                    iconName={ICON_NAMES.CLOSE}
                    title={i18n.t('staking.plansScreen.selectPlan')}
                    onBack={() => navigation.pop()}
                />
            </View>

            {!!stakingPlans && (
                <>
                    <Carousel
                        data={stakingPlans}
                        sliderWidth={windowWidth}
                        itemWidth={windowWidth}
                        renderItem={(item, index) => (
                            <StakingDetailedInfo
                                item={item}
                                isLast={
                                    index === stakingPlans.length - 1 &&
                                    isIguDrop
                                }
                            />
                        )}
                        pagination
                        centredVertical
                        fullHeight
                        onSnapToItem={setSnapItem}
                    />
                    <ButtonsBottom
                        disabled={isDisabled}
                        onPress={handleSelectPlan}
                        title={i18n.t('staking.plansScreen.selectPlan')}
                        safe
                    />
                </>
            )}
        </View>
    );
};

export default StakingDetailedInfoScreen;
