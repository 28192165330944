import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            backgroundColor: Colors[theme].navigationBar.accent,
            borderBottomLeftRadius: 20,
            borderBottomRightRadius: 20,
        },
        lineBlock: {
            borderBottomRightRadius: 20,
            paddingVertical: verticalScale(12),
            overflow: 'hidden',
            backgroundColor: 'transparent',
            marginLeft: scale(20),
            flexDirection: 'row',
        },
        empty: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(34, 36, 42, 0.20)',
            borderBottomRightRadius: 20,
            borderBottomLeftRadius: 20,
        },
        line: {
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: 'transparent',
            height: verticalScale(24),
        },
        textGap: {
            marginLeft: scale(6),
        },
        hiddenBlock: {
            width: 10000,
            position: 'absolute',
            zIndex: -1,
            opacity: 0,
            right: 10000,
        },
        hidden: {
            alignSelf: 'flex-start',
            flexDirection: 'row',
        },
    });

export default styles;
