import * as React from 'react';
import { useCallback } from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import ModalBottom from '@components/ModalBottom';
import { waitForModal, waitForNavigation } from '@helpers/helpers';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import {
    LuckyStrikeAvailableGameOutput,
    MYSTERY_GAMES,
} from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { useFocusEffect } from '@react-navigation/native';

import LuckyStrikeSlots from '../LuckyStrikeSlots';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
    openModal: () => void;
    onPurchaseTicket: (games: LuckyStrikeAvailableGameOutput) => void;
    modalHeight?: number | string;
    games: LuckyStrikeAvailableGameOutput[];
    loading?: boolean;
    onEndTime?: () => void;
    routeBack: ROUTES;
}

const LuckyStrikeSlotsModal = ({
    isVisible,
    onClose,
    openModal,
    onPurchaseTicket,
    modalHeight = 999,
    onEndTime,
    games,
    loading,
    routeBack,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const [shouldShownModal, setShouldShownModal] =
        React.useState<boolean>(false);

    useFocusEffect(
        useCallback(() => {
            const showConfirmModal = async () => {
                await waitForNavigation();
                openModal();
                setShouldShownModal(false);
            };
            if (shouldShownModal) {
                showConfirmModal();
            }
        }, [shouldShownModal])
    );

    const handleOnHowItWorkPress = async () => {
        onClose();
        await waitForModal();
        navigation.navigate(ROUTES.HOW_LUCKY_STRIKE_WORK);
        setShouldShownModal(true);
    };

    return (
        <ModalBottom
            isVisible={isVisible}
            onClose={onClose}
            modalHeight={modalHeight}>
            <ScrollView
                style={styles.container}
                showsVerticalScrollIndicator={false}>
                <TouchableOpacity activeOpacity={1}>
                    <BlockHeader
                        containerStyle={styles.header}
                        type="primary"
                        title={i18n.t(
                            `HomeScreen.GamesBlock.games.${MYSTERY_GAMES.luckyStrike}.title`
                        )}
                        text={i18n.t(
                            `HomeScreen.GamesBlock.games.${MYSTERY_GAMES.luckyStrike}.text`
                        )}
                        textInfoOnPress={handleOnHowItWorkPress}
                    />
                    <LuckyStrikeSlots
                        routeBack={routeBack}
                        loading={loading}
                        containerStyle={styles.slots}
                        onPress={onPurchaseTicket}
                        games={games}
                        onEndTime={onEndTime}
                        secondModalClose={onClose}
                    />
                </TouchableOpacity>
            </ScrollView>
        </ModalBottom>
    );
};

export default LuckyStrikeSlotsModal;
