import React from 'react';

import { BanReasonsOutput, BanReasonsOutputResponse } from '@Data/Models';
import { GET_BAN_REASONS } from '@Data/Requests';
import { useQuery } from '@apollo/client';
import { errorsHandler } from '@helpers/errors';
import i18n from '@i18n/i18n';

import BanReasonSelect from '../components/BanReasonSelect';

export const useBanReasonSelect = () => {
    const [banReason, setBanReason] = React.useState<BanReasonsOutput>();
    const [banReasonError, setBanReasonError] = React.useState<string>('');

    const { data: banReasons } = useQuery<BanReasonsOutputResponse>(
        GET_BAN_REASONS,
        {
            onError: (error) => errorsHandler(error, true),
        }
    );

    const checkBanReason = React.useCallback(() => {
        if (!banReason) {
            setBanReasonError(i18n.t('profile.adminBlockUser.error.banReason'));
            return false;
        }
        return true;
    }, [banReason]);

    const handleSelect = (ban: BanReasonsOutput) => {
        setBanReason(ban);
    };

    const renderBanReasonSelect = React.useCallback(() => {
        return (
            <BanReasonSelect
                loading={!banReasons?.banReasons}
                error={banReasonError}
                selectedBanReason={banReason}
                banReasons={banReasons?.banReasons}
                onPress={handleSelect}
            />
        );
    }, [banReason, banReasonError, banReasons]);

    const resetBanReasonSelect = () => {
        setBanReason(undefined);
        setBanReasonError('');
    };

    return {
        resetBanReasonSelect,
        checkBanReason,
        banReason,
        banReasonError,
        renderBanReasonSelect,
    };
};
