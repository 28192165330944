import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        navBg: {
            backgroundColor: Colors[theme].navigationBar.background,
        },
        container: {
            flex: 1,
            paddingHorizontal: scale(20),
            paddingTop: verticalScale(24),
            paddingBottom: verticalScale(20),
            backgroundColor: Colors[theme].background,
        },
        energyTimeWrapper: {
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            marginBottom: verticalScale(16),
        },
        headerText: {
            paddingHorizontal: 0,
            marginBottom: verticalScale(20),
        },
        timeToComplete: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
        },
        timer: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
        },
        timerWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: verticalScale(16),
        },
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 22,
                height: 28,
                weight: '700',
            }),
            marginBottom: verticalScale(8),
        },
        socialTitle: {
            textAlign: 'center',
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
            marginTop: verticalScale(16),
            marginBottom: verticalScale(20),
        },
        text: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
        animationImage: {
            justifyContent: 'center',
            alignItems: 'center',
            width: 180,
            height: 180,
        },
    });

export default styles;
