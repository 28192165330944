import * as React from 'react';

import HowTokenTellerWorkScreen from '../../screens/TabBar/Home/TokenTellerFlow/HowTokenTellerWork/HowTokenTellerWorkScreen';
import TokenTellerAllParticipantsScreen from '../../screens/TabBar/Home/TokenTellerFlow/TokenTellerAllParticipants/TokenTellerAllParticipantsScreen';
import { TokenTellerChartScreen } from '../../screens/TabBar/Home/TokenTellerFlow/TokenTellerChart/TokenTellerChartScreen';
import TokenTellerGameHistoryScreen from '../../screens/TabBar/Home/TokenTellerFlow/TokenTellerGameHistory/TokenTellerGameHistoryScreen';
import TokenTellerWaitingForResultScreen from '../../screens/TabBar/Home/TokenTellerFlow/TokenTellerWaitingForResult/TokenTellerWaitingForResultScreen';
import WelcomeTokenTellerScreen from '../../screens/TabBar/Home/TokenTellerFlow/WelcomeTokenTeller/WelcomeTokenTellerScreen';
import ROUTES from '../routes';
import { TokenTellerTabsNavigator } from './TokenTellerTabsNavigator';

const TokenTellerGroup = (RootStack: any) => {
    return (
        <>
            <RootStack.Screen
                name={ROUTES.WELCOME_TOKEN_TELLER}
                component={WelcomeTokenTellerScreen}
            />
            <RootStack.Screen
                name={ROUTES.TOKEN_TELLER}
                component={TokenTellerTabsNavigator}
            />
            <RootStack.Screen
                name={ROUTES.HOW_TOKEN_TELLER_WORK}
                component={HowTokenTellerWorkScreen}
                options={{
                    presentation: 'modal',
                    headerShown: false,
                }}
            />
            <RootStack.Screen
                name={ROUTES.TOKEN_TELLER_CHART}
                component={TokenTellerChartScreen}
                options={{
                    presentation: 'modal',
                    headerShown: false,
                }}
            />
            <RootStack.Screen
                name={ROUTES.TOKEN_TELLER_ALL_PARTICIPANTS}
                component={TokenTellerAllParticipantsScreen}
                options={{
                    presentation: 'modal',
                    headerShown: false,
                }}
            />

            <RootStack.Screen
                name={ROUTES.TOKEN_TELLER_WAITING_FOR_RESULT}
                component={TokenTellerWaitingForResultScreen}
            />

            <RootStack.Screen
                name={ROUTES.TOKEN_TELLER_GAME_HISTORY}
                component={TokenTellerGameHistoryScreen}
                options={{ gestureEnabled: false }}
            />
        </>
    );
};

export default TokenTellerGroup;
