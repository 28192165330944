import * as React from 'react';

import EXPIRED from '@assets/icons/mysteryGames/gamesHistory/expired.svg';
import OPPONENT from '@assets/icons/mysteryGames/gamesHistory/opponent.svg';
import YOU from '@assets/icons/mysteryGames/gamesHistory/you.svg';
import { NFT_ROYAL_GAME_HISTORY_STATUS } from '@models/mysteryGames';

import { IconType } from '../types';

interface IProps extends IconType {
    slug: NFT_ROYAL_GAME_HISTORY_STATUS;
}

const NftRoyalGamesHistoryIcon = ({ slug, size = 40 }: IProps) => {
    switch (slug) {
        case NFT_ROYAL_GAME_HISTORY_STATUS.WaitingForMyself:
            return <YOU width={size} height={size} />;
        case NFT_ROYAL_GAME_HISTORY_STATUS.WaitingForOpponent:
            return <OPPONENT width={size} height={size} />;
        case NFT_ROYAL_GAME_HISTORY_STATUS.Refund:
            return <EXPIRED width={size} height={size} />;

        default:
            return <></>;
    }
};

export default NftRoyalGamesHistoryIcon;
