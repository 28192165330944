import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        textAmount: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
        textPrice: {
            textAlign: 'center',
            ...font({
                size: 28,
                height: 36,
                weight: '700',
            }),
            marginVertical: verticalScale(6),
        },
        textInfo: {
            textAlign: 'center',
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
        explorerContainer: {
            marginTop: verticalScale(12),
        },
        titleWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            marginRight: verticalScale(52),
        },
        walletBalanceWrapper: {},
        historyWrapper: {
            flex: 1,
            marginTop: verticalScale(20),
        },
        top: {
            alignItems: 'center',
            paddingHorizontal: scale(20),
            marginTop: verticalScale(8),
            marginBottom: verticalScale(24),
        },
        bottom: {
            borderTopWidth: 1,
            borderTopColor: Colors[theme].border.primary,

            paddingVertical: verticalScale(24),
        },
        transactionSummary: {
            paddingHorizontal: scale(20),
        },
        infoText: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 20,
                weight: '600',
            }),
            textAlign: 'right',
        },
        addressWrapper: {
            justifyContent: 'flex-end',
            flexDirection: 'row',
            alignItems: 'center',
        },
        textWidth: {
            marginRight: verticalScale(6),
        },
        statusWrapper: {
            paddingHorizontal: scale(12),
            paddingVertical: scale(6),
            borderRadius: 50,
            alignItems: 'center',
            flexDirection: 'row',
            marginBottom: verticalScale(12),
        },
        statusText: {
            color: Colors[theme].text.accent,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
            marginLeft: verticalScale(6),
        },
        PENDING: {
            backgroundColor: Colors[theme].statutes.pending.background,
            color: Colors[theme].statutes.pending.text,
        },
        FAILED: {
            backgroundColor: Colors[theme].statutes.failed.background,
            color: Colors[theme].statutes.failed.text,
        },
        CONFIRMED: {
            backgroundColor: Colors[theme].statutes.confirmed.background,
            color: Colors[theme].statutes.confirmed.text,
        },
        UNLOCK: {
            backgroundColor: Colors[theme].statutes.pending.background,
            color: Colors[theme].statutes.pending.text,
        },
        paymentColor: {
            color: Colors[theme].text.primary,
        },
        unlockTransactions: {
            flex: 1,
            borderTopWidth: 1,
            borderTopColor: Colors[theme].border.primary,
            marginBottom: verticalScale(16),
        },
        unlockTitle: {
            color: Colors[theme].text.primary,
            ...font({
                size: 18,
                height: 24,
                weight: '700',
            }),
            marginLeft: scale(20),
            marginTop: verticalScale(24),
        },
        knowledgebase: {
            marginTop: verticalScale(12),
        },
        info: {
            marginLeft: scale(4),
        },
    });

export default styles;
