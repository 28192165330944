import dayjs from 'dayjs';

export function timeFormatter(time: number, withLeadingZero: boolean) {
    if (Number.isNaN(time)) {
        return '';
    }

    return time <= 0
        ? `:${withLeadingZero ? '0' : ''}0`
        : time < 10
        ? `:${withLeadingZero ? '0' : ''}${time}`
        : `:${time}`;
}

export function addSeconds(numOfSeconds: number, date = new Date()): Date {
    date.setSeconds(date.getSeconds() + numOfSeconds);
    return date;
}

export function dateFormatter(date: string | number) {
    if (typeof date === 'string') {
        return dayjs(date).format('DD MMM, hh:mm a');
    }

    return dayjs(Number(date) * 1000).format('DD MMM, hh:mm a');
}

export function dateFormatterFull(date: string | number) {
    if (typeof date === 'string') {
        return dayjs(date).format('DD MMM YYYY, hh:mm:ss a');
    }

    return dayjs(Number(date) * 1000).format('DD MMM YYYY, hh:mm:ss a');
}
export function dateFormatterDayMonth(date: string | number) {
    if (typeof date === 'string') {
        return dayjs(date).format('DD MMMM');
    }

    return dayjs(Number(date) * 1000).format('DD MMMM');
}
export function dateFormatterYear(date: string | number | Date) {
    if (typeof date === 'number') {
        return dayjs(Number(date) * 1000).format('DD MMM, hh:mm a');
    }
    return dayjs(date).format('MMM DD, YYYY');
}

export function dateFormatterDayMonthYear(date: string | number | Date) {
    if (typeof date === 'number') {
        return dayjs(Number(date) * 1000).format('DD MMM, hh:mm a');
    }
    return dayjs(date).format('DD MMM, YYYY');
}

// helper function to handle cooldownExpireAt logic
export function dateCooldownExpired(date: string | null) {
    if (!date) {
        return true;
    }

    return new Date().getTime() >= new Date(date).getTime();
}

export function dateDiffInSeconds(
    date1: string | Date,
    date2: string | Date
): number {
    const dateObject1 = getDateObject(date1);
    const dateObject2 = getDateObject(date2);

    return dateObject1.getTime() - dateObject2.getTime();
}

export function dateDiffInDays(
    date1: string | Date,
    date2: string | Date
): number {
    const dateObject1 = getDateObject(date1);
    const dateObject2 = getDateObject(date2);
    const dateDiff = dateObject1.getTime() - dateObject2.getTime();

    return Math.ceil(dateDiff / (1000 * 3600 * 24));
}

export function dateFromNow(date: string | Date): string {
    return dayjs(date).fromNow();
}

export function dateDiffInMinutes(
    date1: string | Date,
    date2: string | Date
): number {
    const dateObject1 = getDateObject(date1);
    const dateObject2 = getDateObject(date2);
    const dateDiff = dateObject1.getTime() - dateObject2.getTime();

    return Math.ceil(dateDiff / (1000 * 60));
}

export function getDateObject(date: string | Date): Date {
    return typeof date === 'string' ? new Date(date) : date;
}

export const getFinishAt = (days: number) => {
    return new Date(new Date().setDate(new Date().getDate() + days));
};
