import { StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import { scale, verticalScale } from '../../../../helpers/dimensions';
import font from '../../../../helpers/font';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
        },
        walletCoinBalanceWrapper: {
            paddingHorizontal: scale(20),
            paddingTop: verticalScale(24),
        },
        walletAddressWrapper: {
            marginTop: verticalScale(20),
            marginHorizontal: scale(40),
            alignItems: 'center',
        },
        buttonsWrapper: {
            marginTop: verticalScale(20),
            alignItems: 'center',
        },
        balanceListWrapper: {
            borderTopWidth: 1,
            borderTopColor: Colors[theme].border.primary,
            paddingHorizontal: scale(20),
            paddingVertical: verticalScale(24),
            marginTop: verticalScale(24),
        },

        banners: {
            marginTop: verticalScale(16),
            marginBottom: verticalScale(24),
        },
    });

export default styles;
