import * as React from 'react';

import { createNativeStackNavigator } from '@react-navigation/native-stack';

import MintProvider from '../../contexts/MintContext';
import MintPetCheckoutScreen from '../../screens/MintNFT/MintPetCheckout/MintPetCheckoutScreen';
import MintPetCheckoutSuccessScreen from '../../screens/MintNFT/MintPetCheckoutSuccess/MintPetCheckoutSuccessScreen';
import MintPetEditPhotoScreen from '../../screens/MintNFT/MintPetEditPhoto/MintPetEditPhotoScreen';
import MintPetGenerationInfo from '../../screens/MintNFT/MintPetGenerationInfo/MintPetGenerationInfoScreen';
import MintPetNameScreen from '../../screens/MintNFT/MintPetName/MintPetNameScreen';
import MintPetPickPhotoScreen from '../../screens/MintNFT/MintPetPickPhoto/MintPetPickPhotoScreen';
import MintPetRanksScreen from '../../screens/MintNFT/MintPetRanks/MintPetRanksScreen';
import MintPetSelectGeneratedNFT from '../../screens/MintNFT/MintPetSelectGeneratedNFT/MintPetSelectGeneratedNFTScreen';
import MintPetSelectGeneration from '../../screens/MintNFT/MintPetSelectGeneration/MintPetSelectGenerationScreen';
import { MintStackParamList } from '../../types';
import ROUTES from '../routes';

const MintStack = createNativeStackNavigator<MintStackParamList>();

const MintNavigator = () => {
    return (
        <>
            <MintProvider>
                <MintStack.Navigator
                    initialRouteName={ROUTES.MINT_PET_RANKS}
                    screenOptions={{
                        headerShown: false,
                    }}>
                    <MintStack.Screen
                        name={ROUTES.MINT_PET_RANKS}
                        component={MintPetRanksScreen}
                        options={{ gestureEnabled: false }}
                    />
                    <MintStack.Screen
                        name={ROUTES.MINT_PET_SELECT_GENERATION}
                        component={MintPetSelectGeneration}
                        options={{ gestureEnabled: false }}
                    />
                    <MintStack.Screen
                        name={ROUTES.MINT_SELECT_GENERATED_NFT}
                        component={MintPetSelectGeneratedNFT}
                        options={{ gestureEnabled: false }}
                    />
                    <MintStack.Screen
                        name={ROUTES.MINT_GENERATION_INFO}
                        component={MintPetGenerationInfo}
                        options={{ gestureEnabled: false }}
                    />
                    <MintStack.Screen
                        name={ROUTES.MINT_PET_PICK_PHOTO}
                        component={MintPetPickPhotoScreen}
                        options={{ gestureEnabled: false }}
                    />
                    <MintStack.Screen
                        name={ROUTES.MINT_PET_EDIT_PHOTO}
                        component={MintPetEditPhotoScreen}
                        options={{ gestureEnabled: false }}
                    />
                    <MintStack.Screen
                        name={ROUTES.MINT_PET_NAME}
                        component={MintPetNameScreen}
                        options={{ gestureEnabled: false }}
                    />
                    <MintStack.Screen
                        name={ROUTES.MINT_PET_CHECKOUT_SUCCESS}
                        component={MintPetCheckoutSuccessScreen}
                        options={{ gestureEnabled: false }}
                    />
                    <MintStack.Screen
                        name={ROUTES.MINT_PET_CHECKOUT}
                        component={MintPetCheckoutScreen}
                        options={{ gestureEnabled: false }}
                    />
                </MintStack.Navigator>
            </MintProvider>
        </>
    );
};

export default MintNavigator;
