export const Links = {
    webSite: 'iguv.cc/d',
    applyReferral: '/referrals',
    socializeToEarnRequirements:
        '/game-mechanics/socialize-to-earn/account-requirements',
    rewardFormula:
        'https://whitepaper.iguverse.com/app-overview/reward-formula',
    Default: 'https://iguverse.com/',
    availableReward:
        'https://whitepaper.iguverse.com/app-overview/reward-formula',
};
