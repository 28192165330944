import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';
import {
    stylesAlignCenter,
    stylesContainerWithBorderDefault,
    stylesRowAlign,
} from '@helpers/style';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            ...stylesContainerWithBorderDefault(theme),
        },
        titleBlock: {
            ...stylesRowAlign(),
            marginBottom: verticalScale(12),
        },
        infoIcon: {
            marginLeft: scale(4),
        },
        item: { ...stylesRowAlign() },
        itemLeft: { ...stylesRowAlign(true) },
        icon: {
            marginRight: scale(8),
        },
        gap: {
            marginBottom: verticalScale(16),
        },

        shadow: {
            borderRadius: moderateScale(1000),
            backgroundColor: Colors[theme].shadow.default,
        },
        image: {
            width: '100%',
            height: '100%',
            borderRadius: moderateScale(1000),
        },
        imageWrapper: {
            width: 84,
            height: 84,
            alignContent: 'center',
            padding: 4,
            borderRadius: moderateScale(1000),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            marginBottom: 2,
        },
        amountBlock: {
            position: 'absolute',
            bottom: -15,
        },
        textWrapper: {
            borderRadius: moderateScale(1000),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            marginBottom: 2,
            flexDirection: 'row',
            paddingHorizontal: 4,
            paddingVertical: 2,
            alignItems: 'center',
        },
        text: {
            textAlign: 'center',
            color: Colors[theme].text.primary,
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),

            marginRight: scale(2),
        },
        Won: {
            color: Colors[theme].text.accent,
        },
        Lost: {
            color: Colors[theme].text.critical,
        },
    });

export default styles;
