import { useState } from 'react';

import { GET_NFT_ROYAL_GAME_CONFIG } from '@Data/Requests';
import { useApolloClient } from '@apollo/client';
import { errorsHandler } from '@helpers/errors';
import { useLoadingHook } from '@hooks/useLoadingHook';
import {
    NftRoyaleGameStatsOutput,
    NftRoyaleGameStatsOutputResponse,
} from '@models/mysteryGames';

export const useNftRoyaleGameConfig = () => {
    const client = useApolloClient();

    const [nftRoyaleGameConfig, setNftRoyaleGameConfig] =
        useState<NftRoyaleGameStatsOutput | null>(null);

    const {
        isLoading: nftRoyaleGameConfigLoading,
        startLoading: startLoadingNftRoyaleGameConfig,
        stopLoading: stopLoadingNftRoyaleGameConfig,
    } = useLoadingHook(true);

    const {
        isLoading: nftRoyaleGameConfigRefresh,
        startLoading: startNftRoyaleGameConfigRefresh,
        stopLoading: stopNftRoyaleGameConfigRefresh,
    } = useLoadingHook();

    const handleStopLoading = () => {
        stopLoadingNftRoyaleGameConfig();
        stopNftRoyaleGameConfigRefresh();
    };

    const getNftRoyaleGameConfig = async (silent: boolean) => {
        try {
            silent
                ? startLoadingNftRoyaleGameConfig()
                : startNftRoyaleGameConfigRefresh();
            const result = await client.query<NftRoyaleGameStatsOutputResponse>(
                {
                    query: GET_NFT_ROYAL_GAME_CONFIG,
                    fetchPolicy: 'network-only',
                }
            );
            if (result.data) {
                setNftRoyaleGameConfig(result.data.nftRoyaleGameConfig);
            }
            handleStopLoading();
        } catch (error) {
            handleStopLoading();
            errorsHandler(error, true);
        }
    };

    return {
        nftRoyaleGameConfig,
        nftRoyaleGameConfigLoading,
        nftRoyaleGameConfigRefresh,
        getNftRoyaleGameConfig,
    };
};
