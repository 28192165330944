import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            marginBottom: verticalScale(16),
        },
        top: {
            alignItems: 'center',
            flexDirection: 'row',
            marginBottom: verticalScale(16),
        },
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
            marginBottom: verticalScale(4),
        },
        info: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
        textWrapper: {
            marginRight: verticalScale(16),
            flex: 1,
            alignItems: 'flex-start',
        },
        energyCost: {
            color: Colors[theme].text.warning,
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),
            marginLeft: verticalScale(4),
        },
        energyBlock: {
            paddingVertical: verticalScale(2),
            paddingLeft: scale(6),
            paddingRight: scale(8),
        },
        statutes: {
            marginBottom: verticalScale(8),
        },
        completedEnergy: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
        },
    });

export default styles;
