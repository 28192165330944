import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 23,
                weight: '400',
            }),
            letterSpacing: 0.16,
            marginLeft: scale(8),
        },
    });

export default styles;
