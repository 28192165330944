import { StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../../helpers/dimensions';
import font from '../../../../helpers/font';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            backgroundColor: Colors[theme].background,
        },
        isError: {
            backgroundColor: Colors[theme].backgroundSelectable,
            borderColor: Colors[theme].border.accent,
        },
        label: {
            marginBottom: verticalScale(6),
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
        },
        required: {
            color: Colors[theme].text.critical,
        },
        value: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
        placeHolder: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
        inputWrapper: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(16),
        },
        right: {
            flexDirection: 'row',
            flex: 1,
        },
        gap: {
            marginBottom: verticalScale(20),
        },
        multiselectItem: {
            paddingHorizontal: scale(8),
            paddingVertical: verticalScale(4),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: 50,
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: verticalScale(12),
        },
        multiselectItemText: {
            color: Colors[theme].text.primary,
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),
            marginRight: verticalScale(4),
        },
        icon: {
            color: Colors[theme].icon.secondary,
        },
    });

export default styles;
