import * as React from 'react';

import Button from '@components/Button';
import Icon, { ICON_NAMES } from '@components/Icons';
import { Text, View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';

import stylesMain from './styles';

interface IProps {
    message?: string;
    isPaused: boolean;
    onStop: () => void;
    onPause: () => void;
}

export const ProgressFooter = ({
    onStop,
    onPause,
    isPaused,
    message,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={styles.container}>
            {!!message && <Text style={styles.message}>{message}</Text>}
            <View style={styles.btnsContainer}>
                <Button
                    type="critical"
                    onPress={onStop}
                    style={styles.button}
                    icon={
                        <Icon
                            name={ICON_NAMES.STOP}
                            color={styles.icon.color}
                            size={32}
                        />
                    }
                />
                <View style={styles.divider} />
                <Button
                    style={styles.button}
                    onPress={onPause}
                    icon={
                        <Icon
                            name={isPaused ? ICON_NAMES.PLAY : ICON_NAMES.PAUSE}
                            color={styles.icon.color}
                            size={32}
                        />
                    }
                />
            </View>
        </View>
    );
};

export default ProgressFooter;
