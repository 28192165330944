import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '../../../helpers/dimensions';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            marginHorizontal: scale(20),
            marginTop: verticalScale(32),
        },
    });

export default styles;
