import { StyleSheet } from 'react-native';

import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: { width: '100%', height: '100%' },
    });

export default styles;
