import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingTop: verticalScale(20),

            paddingHorizontal: scale(20),
        },
        cards: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            backgroundColor: 'transparent',
        },
        card: {
            width: '48%',
            backgroundColor: 'transparent',
            minHeight: 235,
        },
    });

export default styles;
