import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        features: {
            flexDirection: 'row',
            backgroundColor: 'transparent',
            paddingHorizontal: scale(20),
            justifyContent: 'space-between',
            marginTop: verticalScale(24),
        },
        feature: {
            width: '47%',
        },
    });

export default styles;
