import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';
import {
    stylesAlignCenter,
    stylesContainerWithBorderDefault,
    stylesRowAlign,
} from '@helpers/style';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: { ...stylesRowAlign(true) },
        icon: {
            marginRight: scale(8),
        },
        itemLeft: {
            ...stylesRowAlign(),

            marginRight: scale(16),
        },
        itemRight: {
            ...stylesRowAlign(true),
            zIndex: 1,
        },
        text: {
            textAlign: 'right',
            flex: 1,
        },
        additionalIcon: {
            marginLeft: scale(4),
        },

        link: {
            color: Colors[theme].icon.accent,
        },
    });

export default styles;
