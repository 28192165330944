import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { manipulateAsync } from 'expo-image-manipulator';
import * as ImagePicker from 'expo-image-picker';
import {
    ImageInfo,
    ImagePickerOptions,
    ImagePickerResult,
} from 'expo-image-picker';
import _ from 'lodash';

import Permissions from '@constants/Permissions';
import { getFileSizeFromBase64, getSizeInMb } from '@helpers/media';
import { toastError } from '@helpers/toastNotification';
import i18n from '@i18n/i18n';

import ModalCropImage from '../../web-src/components/ModalCropImage';
import { useHandlePermission } from '../useHandlePermission';
import { IOptions, ImagePickerType } from './types';

const config = {
    maxWidth: 1200,
};

const useImagePicker = (
    options?: ImagePickerOptions,
    additionalOptions?: IOptions
) => {
    const [image, setImage] = useState<ImagePicker.ImageInfo | undefined>();
    const [croppingImage, setCroppingImage] = useState<string | undefined>();
    const [croppedImage, setCroppedImage] = useState<string | undefined>();

    const [cropModalVisibility, setCropModalVisibility] =
        React.useState<boolean>(false);

    const imagePickerOptions = _.extend(options, {
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        quality: 0.9,
    });

    const initialAspectRatio = useMemo(() => {
        if (imagePickerOptions.aspect) {
            return imagePickerOptions.aspect[0] / imagePickerOptions.aspect[1];
        }

        return undefined;
    }, [imagePickerOptions]);

    const {
        openModalNoPermissions,
        openModalNoPermissionsGlobal,
        renderItem: renderCameraPermissions,
    } = useHandlePermission(Permissions.CAMERA);

    const openCamera = async (): Promise<ImagePickerResult | undefined> => {
        setImage(undefined);
        setCroppedImage(undefined);

        const result = await ImagePicker.requestCameraPermissionsAsync().then(
            (response) => {
                if (!response.canAskAgain) {
                    openModalNoPermissionsGlobal();
                    return;
                }

                try {
                    if (response.granted) {
                        return ImagePicker.launchCameraAsync(
                            imagePickerOptions
                        );
                    } else {
                        openModalNoPermissions();
                        return;
                    }
                } catch (error) {
                    Console.error(error);
                    toastError(
                        undefined,
                        'modal.askForCameraPermissions.title'
                    );
                }
            }
        );

        if (!result?.cancelled && result?.uri) {
            setCroppingImage(result?.uri);
            setCropModalVisibility(true);
        }

        return result;
    };

    const pickFromGallery = async (): Promise<
        ImagePickerResult | undefined
    > => {
        setImage(undefined);
        setCroppedImage(undefined);

        try {
            const result = await ImagePicker.launchImageLibraryAsync(
                imagePickerOptions
            );

            if (!result?.cancelled && result?.uri) {
                setCroppingImage(result?.uri);
                setCropModalVisibility(true);
            }

            return result;
        } catch (error) {
            Console.error(error);
            toastError(undefined, 'modal.askForCameraPermissions.title');
        }
    };

    const openCameraOrFromGallery = async (type: ImagePickerType) => {
        if (type === 'camera') {
            return openCamera();
        } else {
            return pickFromGallery();
        }
    };

    const renderCropper = () => {
        return (
            <>
                <ModalCropImage
                    src={croppingImage || ''}
                    initialAspectRatio={initialAspectRatio}
                    isVisible={cropModalVisibility}
                    setCropModalVisibility={setCropModalVisibility}
                    setCroppedImage={setCroppedImage}
                />
            </>
        );
    };

    useEffect(() => {
        async function loadImage() {
            if (croppedImage) {
                const resizedImage = await manipulateAsync(
                    croppedImage,
                    [
                        {
                            resize: { width: config.maxWidth },
                        },
                    ],
                    { compress: 0.9 }
                );

                const fileSize = getFileSizeFromBase64(resizedImage?.uri);
                const maxFileSize = additionalOptions?.fileSize;

                if (!maxFileSize || (maxFileSize && fileSize <= maxFileSize)) {
                    setImage({
                        uri: resizedImage?.uri,
                    } as ImageInfo);
                } else {
                    Console.log(
                        `[useImagePicker] File is too big, size is ${fileSize}, allowed size is ${maxFileSize}`
                    );
                    toastError(
                        i18n.t('general.errors.fileIsTooBig.title'),
                        i18n.t('general.errors.fileIsTooBig.text', {
                            size: getSizeInMb(maxFileSize),
                        })
                    );
                }
            }
        }

        loadImage();
    }, [croppedImage]);

    return {
        openCamera,
        pickFromGallery,
        openCameraOrFromGallery,
        renderCropper,
        renderCameraPermissions,
        image,
    };
};

export default useImagePicker;
