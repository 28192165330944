import * as React from 'react';

import { useTheme } from '@contexts/ThemeContext';

import Svg from '../../assets/icons/buttons/feed.svg';
import Colors from '../../constants/Colors';
import { IconType, iconSize } from './types';

const Feed: React.FunctionComponent<IconType> = ({
    color,
    size = iconSize.small,
}) => {
    const { theme } = useTheme();
    return (
        <Svg
            height={size}
            width={size}
            color={color ?? Colors[theme].icon.primary}
        />
    );
};

export default Feed;
