import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import Task from '@assets/icons/general/task.svg';
import Divider from '@components/Divider';
import Icon, { ICON_NAMES } from '@components/Icons';
import OtherUserAvatar from '@components/OtherUserAvatar';
import { View } from '@components/Themed';
import { useUser } from '@contexts/UserContext';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { RankingRow } from '@models/leaderboard';
import ROUTES from '@navigation/routes';

import UserAccount from '../UserAccount';
import UserItem from '../UserItem';
import stylesMain from './styles';

interface IProps {
    rankMe: RankingRow | null;
    containerStyle?: StyleProp<ViewStyle>;
}
const screen = ROUTES.LEADERBOARD_DAILY_TASKS;
const UserDailyTasks = ({ rankMe, containerStyle }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { user } = useUser();

    const address = React.useMemo(
        () =>
            rankMe?.user?.username ||
            user?.username ||
            rankMe?.user?.walletAddress ||
            user?.walletAddress ||
            '',
        [rankMe, user]
    );

    return (
        <View style={[styles.container, containerStyle]}>
            <View style={styles.imgWrapper}>
                <OtherUserAvatar
                    size={88}
                    imageUrl={user?.mainPet?.image?.thumbnailUrl}
                    userId={user?.walletAddress}
                />
            </View>

            <View style={styles.accountWrapper}>
                {!!user && <UserAccount address={address} />}
            </View>
            <Divider customStyles={styles.divider} />
            <View style={styles.statisticsWrapper}>
                <UserItem
                    title={i18n.t(`Leaderboard.${screen}.energy`)}
                    amount={rankMe?.totalEnergyRewarded || 0}
                    icon={<Icon name={ICON_NAMES.ENERGY} size={24} />}
                />
                <UserItem
                    title={i18n.t(`Leaderboard.${screen}.task`)}
                    amount={rankMe?.totalTasksCount || 0}
                    icon={<Task width={24} height={24} />}
                    containerStyle={styles.gap}
                />
                <UserItem
                    title={i18n.t('general.KEYS.leaderboardPlace')}
                    amount={rankMe?.rank || 'N/A'}
                    icon={<Icon name={ICON_NAMES.LEVEL} size={24} />}
                    containerStyle={styles.gap}
                />
            </View>
        </View>
    );
};
export default UserDailyTasks;
