import { StyleSheet } from 'react-native';

import Colors from '../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../helpers/dimensions';
import font from '../../../helpers/font';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        listStyle: {},
        labelWallet: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
            marginBottom: verticalScale(8),
        },
        itemContainer: {
            backgroundColor: Colors[theme].purchasingFrom.backgroundDefault,
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].purchasingFrom.borderColorDefault,
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            marginBottom: verticalScale(4),
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        shadow: {
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].purchasingFrom.shadowColorDefault,
        },
        gap: {
            marginBottom: scale(12),
        },
        coin: {
            backgroundColor: 'transparent',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        coinTitle: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                weight: '700',
            }),
            marginLeft: verticalScale(12),
        },
        coinAmount: {
            marginVertical: verticalScale(6),
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
        },
        selectedShadow: {
            backgroundColor: Colors[theme].purchasingFrom.shadowColorSelected,
        },
        selected: {
            backgroundColor: Colors[theme].purchasingFrom.backgroundSelected,
            borderColor: Colors[theme].purchasingFrom.borderColorSelected,
            shadowColor: Colors[theme].purchasingFrom.shadowColorSelected,
        },
    });

export default styles;
