import * as React from 'react';

import { SocialTaskStatus } from '@Data/Models';
import { useAppLoading } from '@contexts/AppLoading/AppLoadingContext';
import { useUser } from '@contexts/UserContext';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const useSocialTooltip = (
    modals: boolean,
    status: SocialTaskStatus | null,
    taskId: string
) => {
    const { user, isNewUser } = useUser();
    const { isSplashAnimationComplete } = useAppLoading();

    const [showTooltip, setShowTooltip] = React.useState<boolean>(false);

    const storageKey = React.useMemo(
        () => `SocialTooltip-${`${user?.id}`}`,
        [user?.id]
    );

    const canShow = React.useCallback(async () => {
        if (!isSplashAnimationComplete) {
            return false;
        }
        const key = await AsyncStorage.getItem(storageKey);

        if (!modals && status === 'FAILED' && key !== taskId && isNewUser) {
            return true;
        }

        return false;
    }, [
        isSplashAnimationComplete,
        storageKey,
        modals,
        status,
        taskId,
        isNewUser,
    ]);

    const setShown = React.useCallback(async () => {
        if (storageKey && taskId) {
            await AsyncStorage.setItem(storageKey, taskId);
        }
    }, [taskId]);

    React.useEffect(() => {
        const check = async () => {
            if (!user) {
                setShowTooltip(false);
                return;
            }
            const status = await canShow();
            if (status) {
                setShowTooltip(true);
                setShown();
                return;
            }
            setShowTooltip(false);
        };

        check();
    }, [canShow, user, setShowTooltip]);

    const closeTooltip = () => {
        setShowTooltip(false);
    };

    return { showTooltip, closeTooltip };
};
