import * as React from 'react';

import CheckoutSuccessScreen from '@components/CheckoutSuccessScreen';
import TransactionSummary from '@components/TransactionSummary';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { priceString } from '@helpers/payments';
import { toastErrorUnknown } from '@helpers/toastNotification';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import NftRoyalGamesSettings from '../../components/NftRoyalGamesSettings';
import stylesMain from './styles';

const SCREEN = 'NftRoyalCreateGameSuccess';

const NftRoyalCreateGameSuccessScreen = () => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    navigation.blockHardwareBack();
    const { nftRoyalCurrentGame } = useMysteryGames();

    const handleNavigatePlay = React.useCallback(() => {
        if (!nftRoyalCurrentGame) {
            navigation.pop();
            toastErrorUnknown();
            return;
        }
        navigation.replace(ROUTES.NFT_ROYAL_PLAY_GAME, {
            isNewCreated: true,
        });
    }, [nftRoyalCurrentGame]);

    const transactionDetailsValues = [
        {
            key: i18n.t('general.KEYS.type'),
            value: i18n.t(
                'HomeScreen.EnterCoinsAmountScreen.confirmTransaction.valueType',
                {
                    game: i18n.t(
                        `HomeScreen.GamesBlock.games.${MYSTERY_GAMES.nftRoyal}.title`
                    ),
                }
            ),
        },
        {
            key: i18n.t('checkout.fields.from'),
            value: i18n.t('checkout.fields.gamingWallet'),
        },

        {
            key: i18n.t('checkout.fields.totalSummary'),
            value: priceString(
                nftRoyalCurrentGame?.gameValue || '0',
                Coin.vigu
            ),
        },
    ];

    return (
        <CheckoutSuccessScreen
            title={i18n.t(`HomeScreen.NftRoyal.${SCREEN}.title`)}
            text={i18n.t(`HomeScreen.NftRoyal.${SCREEN}.text`)}
            onPressButtonMain={handleNavigatePlay}
            buttonMainText={i18n.t('general.BUTTONS.startPlaying')}
            AdditionalComponent={
                <>
                    {!!nftRoyalCurrentGame && (
                        <NftRoyalGamesSettings
                            mode={nftRoyalCurrentGame?.mode}
                            difficulty={nftRoyalCurrentGame?.complexity}
                            livesCount={nftRoyalCurrentGame?.livesCount}
                            containerStyle={styles.settings}
                        />
                    )}
                </>
            }
            TransactionDetailsComponent={
                <TransactionSummary values={transactionDetailsValues} />
            }
        />
    );
};

export default NftRoyalCreateGameSuccessScreen;
