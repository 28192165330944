/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */
import { getLinkingPrefixes } from '@helpers/app';
import { LinkingOptions } from '@react-navigation/native';

import { Charity, Pet, TasksHistoryResponseItem } from '../Data/Models';
import { CoinBalance } from '../contexts/Wallet/WalletHelpers';
import {
    IRewardsTransactionDetails,
    ITransactionDetails,
    RootStackParamList,
} from '../types';
import ROUTES from './routes';

const linking: LinkingOptions<RootStackParamList> = {
    prefixes: getLinkingPrefixes(false),

    config: {
        screens: {
            [ROUTES.PLAY]: '*',

            // SOCIALIZE_TO_EARN
            [ROUTES.SOCIALIZE_TO_EARN_VERIFY_USER]: {
                path: 'SocializeToEarnVerifyUser/:petId?',
                stringify: {
                    petId: (petId: string) => {
                        return petId || '';
                    },
                },
            },
            [ROUTES.SOCIALIZE_TO_EARN_VERIFY_USER_MATCH]: {
                path: 'SocializeToEarnVerifyUser/:currentSubtask?',
                stringify: {
                    currentSubtask: () => {
                        return '';
                    },
                },
            },
            [ROUTES.SOCIALIZE_TO_EARN_CREATE_SHARE_PHOTO]: {
                path: 'SocializeToEarnCreateSharePhoto/:uri/:type',
                stringify: {
                    uri: () => {
                        return '';
                    },
                    type: () => {
                        return '';
                    },
                },
            },
            // END OF SOCIALIZE_TO_EARN

            // REFERRALS
            [ROUTES.MAIN]: {
                screens: {
                    [ROUTES.REFERRALS]: {
                        screens: {
                            [ROUTES.REFERRALS_INVITES]: {
                                path: 'InviteNewUsers/:refId',
                                stringify: {
                                    refId: (refId: string) => {
                                        return refId || '';
                                    },
                                },
                            },
                        },
                    },
                },
            },
            [ROUTES.HOW_TO_REFERRALS]: {
                path: 'HowToReferrals/:direct/:inDirect',
                stringify: {
                    direct: () => {
                        return '';
                    },
                    inDirect: () => {
                        return '';
                    },
                },
            },
            //END OF REFERRALS

            // CHARITY
            [ROUTES.CHARITY_EDIT_SELECTED_ORGANIZATION]: {
                path: 'CharityEditSelectedOrganization/',
            },
            [ROUTES.CHARITY_EDIT_DETAILED_ORGANIZATION]: {
                path: 'CharityEditDetailedOrganization/:charity',
                stringify: {
                    charity: (charity: string) => charity,
                },
            },
            [ROUTES.CHARITY_ORGANIZATIONS]: {
                path: 'CharityOrganizations/',
            },
            [ROUTES.CHARITY_DETAILED_ORGANIZATION]: {
                path: 'CharityDetailedOrganization/:charity',
                stringify: {
                    charity: (charity: string) => charity,
                },
            },
            [ROUTES.CHARITY_ORGANIZATION_DONATIONS]: {
                path: 'CharityOrganizationDonations/:charity',
                stringify: {
                    charity: (charity: Charity) => {
                        return `${charity.name}`;
                    },
                },
            },
            // END OF CHARITY

            //MINT
            [ROUTES.MINT_ROOT]: {
                screens: {
                    [ROUTES.MINT_PET_RANKS]: {
                        path: 'MintPetRanks/:rank?/:skip?',
                        stringify: {
                            rank: () => {
                                return '';
                            },
                            skip: () => {
                                return '';
                            },
                        },
                    },
                },
            },

            [ROUTES.MINT_ROOT]: {
                screens: {
                    [ROUTES.MINT_PET_CHECKOUT_SUCCESS]: {
                        path: 'MintPetCheckoutSuccess/:transactionResult/:isFree/:payment',
                        stringify: {
                            transactionResult: () => {
                                return '';
                            },
                            isFree: () => {
                                return '';
                            },
                            payment: () => {
                                return '';
                            },
                        },
                    },
                },
            },

            [ROUTES.RANKS_DETAILED_INFO]: {
                path: 'RanksDetailedInfo/:screenConfig',
                stringify: {
                    screenConfig: () => {
                        return '';
                    },
                },
            },
            //END OF MINT

            //COLLECTION
            [ROUTES.COLLECTION_DETAILED_PET]: {
                path: 'CollectionDetailedPet/:pet/:navigateBack',
                stringify: {
                    pet: (pet: Pet) => {
                        return pet.name;
                    },
                    navigateBack: () => {
                        return '';
                    },
                },
            },
            [ROUTES.COLLECTION_PET_LEVEL_UP_CHECKOUT]: {
                path: 'CollectionPetLevelUpCheckout/:pet/:payments/:navigateBackOnUpgrade',
                stringify: {
                    pet: (pet: Pet) => {
                        return pet.name;
                    },
                    payments: () => {
                        return '';
                    },
                    navigateBackOnUpgrade: () => {
                        return '';
                    },
                },
            },
            [ROUTES.COLLECTION_PET_RANK_UP_CHECKOUT]: {
                path: 'CollectionPetRankUpCheckout/:pet/:payments/:navigateBackOnUpgrade',
                stringify: {
                    pet: (pet: Pet) => {
                        return pet.name;
                    },
                    payments: () => {
                        return '';
                    },
                    navigateBackOnUpgrade: () => {
                        return '';
                    },
                },
            },
            [ROUTES.COLLECTION_PET_HEAL_UP_CHECKOUT]: {
                path: 'CollectionPetHealUpCheckout/:pet/:payments/:navigateBackOnUpgrade/:healthPoints/:navigateBackFromCheckout?',
                stringify: {
                    pet: (pet: Pet) => {
                        return pet.name;
                    },
                    payments: () => {
                        return '';
                    },
                    navigateBackOnUpgrade: () => {
                        return '';
                    },
                    healthPoints: () => {
                        return '';
                    },
                    navigateBackFromCheckout: () => {
                        return '';
                    },
                },
            },
            [ROUTES.COLLECTION_MULTIPLE_PETS_HEAL_UP_PROCEEDING]: {
                path: 'CollectionMultiplePetsHealUpProceeding/:pets/:paymentOption',
                stringify: {
                    pets: (pets: Pet[]) => {
                        return pets.map((p) => p.tokenId).join(',');
                    },
                    paymentOption: () => {
                        return '';
                    },
                },
            },
            [ROUTES.COLLECTION_MULTIPLE_PETS_HEAL_UP_SUCCESS]: {
                path: 'CollectionMultiplePetsHealUpSuccess/:results/:payment',
                stringify: {
                    results: () => {
                        return '';
                    },
                    payment: () => {
                        return '';
                    },
                },
            },
            [ROUTES.COLLECTION_PET_REVIVE_CHECKOUT]: {
                path: 'CollectionPetReviveCheckout/:pet/:payments/:navigateBackOnUpgrade/:navigateBackFromCheckout?',
                stringify: {
                    pet: (pet: Pet) => {
                        return pet.name;
                    },
                    payments: () => {
                        return '';
                    },
                    navigateBackOnUpgrade: () => {
                        return '';
                    },
                    navigateBackFromCheckout: () => {
                        return '';
                    },
                },
            },
            [ROUTES.COLLECTION_PET_AGE_REVIVE_CHECKOUT]: {
                path: 'CollectionPetAgeReviveCheckout/:pet/:payments/:navigateBackOnUpgrade/:navigateBackFromCheckout?',
                stringify: {
                    pet: (pet: Pet) => {
                        return pet.name;
                    },
                    payments: () => {
                        return '';
                    },
                    navigateBackOnUpgrade: () => {
                        return '';
                    },
                    navigateBackFromCheckout: () => {
                        return '';
                    },
                },
            },
            [ROUTES.COLLECTION_PET_AGE_REVIVE_SUCCESS]: {
                path: 'CollectionPetAgeReviveSuccess/:pet/:payments/:navigateBackOnUpgrade/:navigateBackFromCheckout?',
                stringify: {
                    pet: (pet: Pet) => {
                        return pet.name;
                    },
                    payments: () => {
                        return '';
                    },
                    navigateBackOnUpgrade: () => {
                        return '';
                    },
                    navigateBackFromCheckout: () => {
                        return '';
                    },
                },
            },
            [ROUTES.COLLECTION_PET_LEVEL_UP_SUCCESS]: {
                path: 'CollectionPetLevelUpSuccess/:pet/:transactionResult/:payments/:navigateBackOnUpgrade',
                stringify: {
                    pet: () => {
                        return '';
                    },
                    payments: () => {
                        return '';
                    },
                    navigateBackOnUpgrade: () => {
                        return '';
                    },
                    transactionResult: () => {
                        return '';
                    },
                },
            },
            [ROUTES.COLLECTION_PET_RANK_UP_SUCCESS]: {
                path: 'CollectionPetRankUpSuccess/:pet/:transactionResult/:payments/:navigateBackOnUpgrade',
                stringify: {
                    pet: () => {
                        return '';
                    },
                    payments: () => {
                        return '';
                    },
                    navigateBackOnUpgrade: () => {
                        return '';
                    },
                    transactionResult: () => {
                        return '';
                    },
                },
            },
            [ROUTES.COLLECTION_PET_HEAL_UP_SUCCESS]: {
                path: 'CollectionPetHealUpSuccess/:pet/:transactionResult/:payments/:navigateBackOnUpgrade/:healthPoints',
                stringify: {
                    pet: () => {
                        return '';
                    },
                    payments: () => {
                        return '';
                    },
                    navigateBackOnUpgrade: () => {
                        return '';
                    },
                    transactionResult: () => {
                        return '';
                    },
                    healthPoints: () => {
                        return '';
                    },
                },
            },
            [ROUTES.COLLECTION_PET_REVIVE_SUCCESS]: {
                path: 'CollectionPetReviveSuccess/:pet/:transactionResult/:payments/:navigateBackOnUpgrade',
                stringify: {
                    pet: () => {
                        return '';
                    },
                    payments: () => {
                        return '';
                    },
                    navigateBackOnUpgrade: () => {
                        return '';
                    },
                    transactionResult: () => {
                        return '';
                    },
                },
            },
            //END OF COLLECTION

            //MOVE TO EARN
            [ROUTES.MOVE_TO_EARN_PROGRESS]: {
                path: 'MoveToEarnProgress/:permissions',
                stringify: {
                    permissions: () => {
                        return '';
                    },
                },
            },
            [ROUTES.MOVE_TO_EARN_SUMMARY]: {
                path: 'MoveToEarnSummary/:energyEarned/:averageSpeed/:distanceTravelled/:stepsCount/:status',
                stringify: {
                    energyEarned: () => {
                        return '';
                    },
                    averageSpeed: () => {
                        return '';
                    },
                    distanceTravelled: () => {
                        return '';
                    },
                    stepsCount: () => {
                        return '';
                    },
                    status: () => {
                        return '';
                    },
                },
            },
            //END OF MOVE TO EARN

            //PLAY
            [ROUTES.HOW_TO_PLAY]: {
                path: 'HowToPlay/:canStart',
                stringify: {
                    canStart: () => {
                        return '';
                    },
                },
            },
            [ROUTES.PLAY_TASKS_HISTORY_DETAILS]: {
                path: 'PlayTasksHistoryDetails/:item',
                stringify: {
                    item: (item: TasksHistoryResponseItem) => {
                        return item.status;
                    },
                },
            },
            [ROUTES.PLAY_COLLECTED_EXPERIENCE]: {
                path: 'PlayCollectedExperience/:pets',
                stringify: {
                    pets: () => {
                        return '';
                    },
                },
            },
            [ROUTES.PLAY_TASK_SUCCESS]: {
                path: 'PlaySuccessScreen/:maxEnergy?/:earnedEnergy?/:petExperienceRewards/:game/:walkGame?/:playGame?',
                stringify: {
                    maxEnergy: () => {
                        return '';
                    },
                    earnedEnergy: () => {
                        return '';
                    },
                    petExperienceRewards: () => {
                        return '';
                    },

                    game: () => {
                        return '';
                    },
                    walkGame: () => {
                        return '';
                    },
                    playGame: () => {
                        return '';
                    },
                },
            },
            //END OF PLAY

            //CREATE WALLET
            [ROUTES.CREATE_WALLET_SUCCESS]: {
                path: 'CreateWalletSuccess/:type',
                stringify: {
                    type: () => {
                        return '';
                    },
                },
            },
            //END OF CREATE WALLET

            //WALLET
            [ROUTES.WALLET_COINS_BALANCE]: {
                path: 'WalletCoinsBalance/:balance',
                stringify: {
                    balance: (balance: CoinBalance) => {
                        return balance.name;
                    },
                },
            },
            [ROUTES.WALLET_COINS_WITHDRAW]: {
                path: 'WalletCoinsWithdraw/:balance',
                stringify: {
                    balance: (balance: CoinBalance) => {
                        return balance.name;
                    },
                },
            },
            [ROUTES.WALLET_COINS_WITHDRAW_SUCCESS]: {
                path: 'WalletCoinsWithdrawSuccess/:transactionValue/:transactionCoin/:balance/:addressFrom/:addressTo/:transactionResult',
                stringify: {
                    transactionValue: () => {
                        return '';
                    },
                    transactionCoin: (transactionCoin: string) => {
                        return transactionCoin;
                    },
                    balance: () => {
                        return '';
                    },
                    addressFrom: () => {
                        return '';
                    },
                    addressTo: () => {
                        return '';
                    },
                    transactionResult: () => {
                        return '';
                    },
                },
            },
            [ROUTES.WALLET_COINS_TRANSACTION_DETAILS]: {
                path: 'WalletCoinsTransactionDetails/:transaction',
                stringify: {
                    transaction: (transaction: ITransactionDetails) => {
                        return transaction.status;
                    },
                },
            },
            //END OF WALLET

            //NFTS
            [ROUTES.WALLET_NFTS_WITHDRAW]: {
                path: 'WalletNFTSWithdraw/:pet',
                stringify: {
                    pet: (pet: Pet) => {
                        return pet.name;
                    },
                },
            },
            [ROUTES.WALLET_NFTS_WITHDRAW_SUCCESS]: {
                path: 'WalletNFTSWithdrawSuccess/:pet/:addressFrom/:addressTo/:transactionResult',
                stringify: {
                    pet: (pet: Pet) => {
                        return pet.name;
                    },
                    addressFrom: () => {
                        return '';
                    },
                    addressTo: () => {
                        return '';
                    },
                    transactionResult: () => {
                        return '';
                    },
                },
            },
            [ROUTES.WALLET_NFTS_TRANSACTION_DETAILS]: {
                path: 'WalletNFTSTransactionDetails/:transaction',
                stringify: {
                    transaction: (transaction: ITransactionDetails) => {
                        return transaction.status;
                    },
                },
            },
            // END OF NFTS

            [ROUTES.WALLET_REWARDS_CLAIM_SUCCESS]: {
                path: 'WalletRewardsClaimSuccess/:total/:transactionResult',
                stringify: {
                    total: () => {
                        return '';
                    },
                    transactionResult: () => {
                        return '';
                    },
                },
            },
            [ROUTES.WALLET_REWARDS_TRANSACTION_DETAILS]: {
                path: 'WalletRewardsTransactionDetails/:transaction',
                stringify: {
                    transaction: (transaction: IRewardsTransactionDetails) => {
                        return transaction.status;
                    },
                },
            },
            // END OF REWARD

            //GAMING

            [ROUTES.GAMING_WALLET_TRANSACTION_DETAILS]: {
                path: 'GamingWalletTransactionDetails/:transaction',
                stringify: {
                    transaction: (transaction: IRewardsTransactionDetails) => {
                        return transaction.status;
                    },
                },
            },
            [ROUTES.TOP_UP_SUCCESS]: {
                path: 'TopUpSuccessScreen/:transactionResult?',
                stringify: {
                    transactionResult: () => {
                        return '';
                    },
                },
            },
            [ROUTES.WITHDRAW_IGU_SUCCESS]: {
                path: 'WithdrawIguSuccessScreen/:amount?/:coin?/:commission?/:transactionResult?',
                stringify: {
                    amount: () => {
                        return '';
                    },
                    coin: () => {
                        return '';
                    },
                    commission: () => {
                        return '';
                    },
                    transactionResult: () => {
                        return '';
                    },
                },
            },

            //TRADE

            [ROUTES.WALLET_TRADE_MAIN]: {
                path: 'WalletTradeMain/:balance?',
                stringify: {
                    balance: () => {
                        return '';
                    },
                },
            },

            [ROUTES.WALLET_TRADE_CHART]: {
                path: 'WalletTradeMain/:from/:to',
                stringify: {
                    from: () => {
                        return '';
                    },
                    to: () => {
                        return '';
                    },
                },
            },

            [ROUTES.WALLET_TRADE_SUCCESS]: {
                path: 'WalletTradeSuccess/:estimate/:from/:to/:fee/:totalSummary/:explorerUrl/:balance',
                stringify: {
                    estimate: () => {
                        return '';
                    },
                    from: () => {
                        return '';
                    },
                    to: () => {
                        return '';
                    },
                    fee: () => {
                        return '';
                    },
                    totalSummary: () => {
                        return '';
                    },
                    explorerUrl: () => {
                        return '';
                    },
                    balance: () => {
                        return '';
                    },
                },
            },
            //END OF TRADE

            //STAKING

            [ROUTES.STAKING_SUCCESS]: {
                path: 'StakingSuccess/:transactionResult',
                stringify: {
                    transactionResult: () => {
                        return '';
                    },
                },
            },
            [ROUTES.UN_STAKING_SUCCESS]: {
                path: 'UnStakingSuccess/:transactionResult/:currentStakingPlan',
                stringify: {
                    transactionResult: () => {
                        return '';
                    },
                    currentStakingPlan: () => {
                        return '';
                    },
                },
            },
            [ROUTES.STAKING_OPTIONS]: {
                path: 'StakingOptions/:type?/:plan?',
                stringify: {
                    type: () => {
                        return '';
                    },
                    plan: () => {
                        return '';
                    },
                },
            },
            [ROUTES.STAKING_PLANS]: {
                path: 'StakingPlans/:plan?',
                stringify: {
                    plan: () => {
                        return '';
                    },
                },
            },
            [ROUTES.MYSTERY_BOX_PURCHASE]: {
                path: 'MysteryBoxPurchase/:amount',
                stringify: {
                    amount: () => {
                        return '';
                    },
                },
            },
            [ROUTES.MYSTERY_BOX_PURCHASE_CHECKOUT]: {
                path: 'MysteryBoxPurchaseCheckout/:amount/:payments',
                stringify: {
                    amount: () => {
                        return '';
                    },
                    payments: () => {
                        return '';
                    },
                },
            },
            [ROUTES.MYSTERY_BOX_PURCHASE_SUCCESS]: {
                path: 'MysteryBoxPurchaseCheckout/:transactionResult/:amount/:payments',
                stringify: {
                    transactionResult: () => {
                        return '';
                    },
                    amount: () => {
                        return '';
                    },
                    payments: () => {
                        return '';
                    },
                },
            },
            // @ts-ignore
            [ROUTES.MINT_PET_RANKS]: {
                path: 'MintPetRanks/:rank?/:skip?',
                stringify: {
                    rank: () => {
                        return '';
                    },
                    skip: () => {
                        return '';
                    },
                },
            },
            //
            [ROUTES.BONUS_MINT_RANKS_CAROUSEL]: {
                path: 'BonusMintRanksCarousel/:ranks/',
                stringify: {
                    ranks: () => {
                        return '';
                    },
                },
            },
            [ROUTES.BONUS_MINT_RANKS_INFO]: {
                path: 'BonusMintRanksInfo/:screenInfo/',
                stringify: {
                    screenInfo: () => {
                        return '';
                    },
                },
            },
            [ROUTES.UPDATE_APP]: {
                path: 'UpdateApp/:reset?',
                stringify: {
                    reset: () => {
                        return '';
                    },
                },
            },
            [ROUTES.MYSTERY_LIKES_SELECT_RANGE]: {
                path: 'MysteryLikesSelectRange/:isNewCreated?',
                stringify: {
                    isNewCreated: () => {
                        return '';
                    },
                },
            },
            [ROUTES.NFT_ROYAL_PLAY_GAME]: {
                path: 'NftRoyalPlayGame/:isNewCreated?',
                stringify: {
                    isNewCreated: () => {
                        return '';
                    },
                },
            },
            [ROUTES.MYSTERY_LIKES_SELECT_RANGE_FINISH]: {
                path: 'MysteryLikesSelectRangeFinish/:fromHistory?',
                stringify: {
                    fromHistory: () => {
                        return '';
                    },
                },
            },

            [ROUTES.NFT_EVOLUTION_SUCCESS]: {
                path: 'NftEvolutionSuccess/:id?',
                stringify: {
                    id: () => {
                        return '';
                    },
                },
            },
            [ROUTES.NFT_EVOLUTION_MINT_PET_SUCCESS]: {
                path: 'NftEvolutionMintPetSuccess/:transactionResult/:pet/:payment',
                stringify: {
                    transactionResult: () => {
                        return '';
                    },
                    pet: () => {
                        return '';
                    },
                    payment: () => {
                        return '';
                    },
                },
            },
            [ROUTES.MARKETPLACE_SELECT_NFT_PRICE]: {
                path: 'MarketPlaceSelectNftPrice/:pet',
                stringify: {
                    pet: () => {
                        return '';
                    },
                },
            },
            [ROUTES.MARKETPLACE_CREATE_ORDER_SUCCESS]: {
                path: 'MarketPlaceCreateOrderSuccess/:pet/:price/:transactionResult/:commission',
                stringify: {
                    pet: () => {
                        return '';
                    },
                    price: () => {
                        return '';
                    },
                    transactionResult: () => {
                        return '';
                    },
                    commission: () => {
                        return '';
                    },
                },
            },
            [ROUTES.MARKETPLACE_OVERVIEW]: {
                path: 'MarketPlaceOverview/:petId/:cancelTransactionValuesStringified',
                stringify: {
                    petId: () => {
                        return '';
                    },
                    cancelTransactionValuesStringified: () => {
                        return '';
                    },
                },
            },
            [ROUTES.MARKETPLACE_OVERVIEW_OWNER_HISTORY]: {
                path: 'MarketPlaceOverviewOwnerHistory/:pet/:order/:isOwner/:cancelTransactionValuesStringified/:owner',
                stringify: {
                    pet: () => {
                        return '';
                    },
                    order: () => {
                        return '';
                    },
                    isOwner: () => {
                        return '';
                    },
                    cancelTransactionValuesStringified: () => {
                        return '';
                    },
                    owner: () => {
                        return '';
                    },
                },
            },
            [ROUTES.MARKETPLACE_DELIST_SUCCESS]: {
                path: 'MarketPlaceDelistSuccess/:petName/:petImg/:transactionResult',
                stringify: {
                    petName: () => {
                        return '';
                    },
                    petImg: () => {
                        return '';
                    },
                    transactionResult: () => {
                        return '';
                    },
                },
            },
            [ROUTES.MARKETPLACE_PROFILE]: {
                path: 'MarketPlaceProfile/:walletAddress',
                stringify: {
                    walletAddress: () => {
                        return '';
                    },
                },
            },
            [ROUTES.MARKETPLACE_BUY_CHECKOUT]: {
                path: 'MarketPlaceBuyCheckout/:pet/:order/:owner',
                stringify: {
                    pet: () => {
                        return '';
                    },
                    order: () => {
                        return '';
                    },
                    owner: () => {
                        return '';
                    },
                },
            },
            [ROUTES.MARKETPLACE_BUY_SUCCESS]: {
                path: 'MarketPlaceBuySuccess/:pet/:total/:owner/:transactionResult',
                stringify: {
                    pet: () => {
                        return '';
                    },
                    total: () => {
                        return '';
                    },
                    owner: () => {
                        return '';
                    },
                    transactionResult: () => {
                        return '';
                    },
                },
            },
            [ROUTES.NFT_ROYAL_GAME_HISTORY]: {
                path: 'NftRoyalGameHistory/:fromHistory?',
                stringify: {
                    fromHistory: () => {
                        return '';
                    },
                },
            },
            [ROUTES.ACHIEVEMENTS_DETAILS]: {
                path: 'AchievementsDetails/:achievement',
                stringify: {
                    achievement: () => {
                        return '';
                    },
                },
            },

            [ROUTES.LUCKY_STRIKE_ALL_PARTICIPANTS]: {
                path: 'LuckyStrikeAllParticipants/:id',
                stringify: {
                    id: () => {
                        return '';
                    },
                },
            },

            [ROUTES.LUCKY_STRIKE_WAITING_FOR_RESULT]: {
                path: 'LuckyStrikeWaitingForResult/:id/:routeBack',
                stringify: {
                    id: () => {
                        return '';
                    },
                },
            },
            [ROUTES.LUCKY_STRIKE_USER_TICKETS]: {
                path: 'LuckyStrikeUserTickets/:id',
                stringify: {
                    id: () => {
                        return '';
                    },
                },
            },
            [ROUTES.LUCKY_STRIKE_USER_TICKETS_WAIT]: {
                path: 'LuckyStrikeUserTickets/:game',
                stringify: {
                    game: () => {
                        return '';
                    },
                },
            },
            [ROUTES.LUCKY_STRIKE_GAME_HISTORY]: {
                path: 'LuckyStrikeUserTickets/:game/:force/:id',
                stringify: {
                    game: () => {
                        return '';
                    },
                    force: () => {
                        return '';
                    },
                    id: () => {
                        return '';
                    },
                },
            },
            [ROUTES.LUCKY_STRIKE]: {
                screens: {
                    [ROUTES.LUCKY_STRIKE_MAIN]: {
                        path: 'LuckyStrikeMain/:game?',
                        stringify: {
                            game: () => {
                                return '';
                            },
                        },
                    },
                },
            },
            [ROUTES.LUCKY_STRIKE]: {
                screens: {
                    [ROUTES.LUCKY_STRIKE_HISTORY]: {
                        path: 'LuckyStrikeHistory/:game?',
                        stringify: {
                            game: () => {
                                return '';
                            },
                        },
                    },
                },
            },
            [ROUTES.QUANTUM_SPIN_GAME_HISTORY]: {
                path: 'LuckyStrikeUserTickets/:data',
                stringify: {
                    data: () => {
                        return '';
                    },
                },
            },
            [ROUTES.QUANTUM_SPIN_PROBABLY_FAIR_HISTORY]: {
                path: 'LuckyStrikeUserTickets/:history',
                stringify: {
                    history: () => {
                        return '';
                    },
                },
            },

            [ROUTES.TOKEN_TELLER]: {
                screens: {
                    [ROUTES.TOKEN_TELLER_MAIN]: {
                        path: 'TokenTellerMain/:game?',
                        stringify: {
                            game: () => {
                                return '';
                            },
                        },
                    },
                },
            },

            [ROUTES.TOKEN_TELLER_CHART]: {
                path: 'TokenTellerChart/:coin1/:coin2',
                stringify: {
                    coin1: () => {
                        return '';
                    },
                    coin2: () => {
                        return '';
                    },
                },
            },
            [ROUTES.TOKEN_TELLER_ALL_PARTICIPANTS]: {
                path: 'TokenTellerAllParticipants/:id',
                stringify: {
                    id: () => {
                        return '';
                    },
                },
            },
            [ROUTES.TOKEN_TELLER_WAITING_FOR_RESULT]: {
                path: 'TokenTellerWaitingForResult/:id/:routeBack',
                stringify: {
                    id: () => {
                        return '';
                    },
                    routeBack: () => {
                        return '';
                    },
                },
            },
            [ROUTES.TOKEN_TELLER_GAME_HISTORY]: {
                path: 'TokenTellerGameHistory/:id/:game/:force',
                stringify: {
                    id: () => {
                        return '';
                    },
                    game: () => {
                        return '';
                    },
                    force: () => {
                        return '';
                    },
                },
            },
        },
    },
};

export default linking;

export async function handleDeferDeepLink() {
    alert(window.location.href);
}
