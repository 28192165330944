import {
    MARKETPLACE_ORDERS_SORT_OPTION,
    MARKETPLACE_PET_TYPE,
} from '@models/marketplace';

export const TYPE_OF_NFT: MARKETPLACE_PET_TYPE[] = [
    MARKETPLACE_PET_TYPE.AISystem,
    MARKETPLACE_PET_TYPE.FromPhoto,
    MARKETPLACE_PET_TYPE.Alive,
    MARKETPLACE_PET_TYPE.Dead,
];

export const SORTED_BY: MARKETPLACE_ORDERS_SORT_OPTION[] = [
    MARKETPLACE_ORDERS_SORT_OPTION.FromHighestPrice,
    MARKETPLACE_ORDERS_SORT_OPTION.FromLowestPrice,
    MARKETPLACE_ORDERS_SORT_OPTION.RecentlyListed,
    MARKETPLACE_ORDERS_SORT_OPTION.EndingSoon,
];
