import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingVertical: verticalScale(20),
            borderWidth: 1,
            borderRadius: moderateScale(12),
            borderColor: Colors[theme].border.primary,
            marginVertical: verticalScale(16),
        },
        ownersProfileWrapper: {
            marginHorizontal: scale(16),
            marginBottom: verticalScale(16),
            flexDirection: 'row',
            alignItems: 'center',
        },
        ownersProfileLeft: {
            marginRight: scale(12),
        },
        ownersProfileRight: {
            flex: 1,
        },
        ownersProfileLabel: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 21,
                weight: '400',
            }),
            letterSpacing: 0.08,
        },
        ownersProfileTitle: {
            marginTop: verticalScale(4),
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '500',
            }),
            letterSpacing: 0.16,
        },
        button: {
            marginHorizontal: scale(16),
            marginBottom: verticalScale(16),
        },
        buttonText: {
            color: Colors[theme].text.accent,
        },
        summaryWrapper: {
            borderTopWidth: 1,
            borderColor: Colors[theme].border.primary,
            paddingTop: verticalScale(16),
            paddingHorizontal: scale(16),
        },
        summaryRow: {
            alignItems: 'center',
            flexDirection: 'row',
        },
        summaryLabel: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
            marginLeft: scale(8),
        },
        summaryTitle: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
            flex: 1,
            textAlign: 'right',
        },
        summaryLeft: {
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: scale(16),
        },
        gap: {
            marginBottom: verticalScale(16),
        },
    });

export default styles;
