import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { isWeb } from '@helpers/app';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';
import { stylesModalNavigationFix } from '@helpers/style';

import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
        },
        title: {
            textAlign: 'center',
            color: Colors[theme].text.white,
            ...font({
                size: 32,
                height: 40,
                weight: '800',
            }),
            letterSpacing: -0.4,
            marginBottom: verticalScale(12),
        },
        text: {
            textAlign: 'center',
            color: Colors[theme].text.white,
            ...font({
                size: 16,
                height: 24,
                weight: '500',
            }),
            letterSpacing: 0.16,
        },
        content: {
            backgroundColor: 'transparent',
        },
        itemContainer: {
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            marginHorizontal: scale(20),
            backgroundColor: 'transparent',
        },
        buttons: {
            backgroundColor: 'transparent',
        },
        bulletActiveStyle: {
            backgroundColor: '#fff',
        },
        bulletStyle: {
            backgroundColor: 'rgba(255, 255, 255, 0.4)',
        },
        button: {
            marginTop: verticalScale(16),
        },
        buttonColor: {
            color: '#fff',
        },
        spaceTop: {
            marginTop: isWeb ? 60 : 0,
        },
        justifyContent: {
            flex: 1,
            justifyContent: 'center',
        },
    });

export default styles;
