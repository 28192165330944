import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import AgeIndicator from '@components/AgeIndicator';
import CacheImage from '@components/CacheImage';
import HealthIndicator from '@components/HealthIndicator';
import RankIcon from '@components/Icons/RankIcon';
import LevelIndicator from '@components/LevelIndicator';
import { Text, View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import { BreedingPetParentOutput } from '@models/mysteryGames';

import stylesMain from './styles';

interface IProps {
    pet: BreedingPetParentOutput;
    onPress?: (pet: BreedingPetParentOutput) => void;
    disabled?: boolean;
}

const NftEvolutionPetCard = ({ pet, onPress, disabled }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <TouchableOpacity
            disabled={disabled}
            activeOpacity={0.7}
            style={styles.shadow}
            onPress={() => onPress?.(pet)}>
            <View style={styles.container}>
                <View style={styles.imgWrapper}>
                    <CacheImage
                        source={pet.imageUrl}
                        style={styles.image}
                        resizeMode="contain"
                    />
                    <View style={styles.rankWrapper}>
                        <RankIcon size={24} slug={pet.rank} />
                    </View>
                </View>

                <View style={styles.textWrapper}>
                    <Text
                        style={styles.name}
                        numberOfLines={1}
                        ellipsizeMode="tail">
                        {pet.name}
                    </Text>
                    <LevelIndicator
                        isOneColor
                        level={pet.level}
                        fillAmount={pet.levelProgressPercentage}
                    />
                    <View style={styles.footer}>
                        <HealthIndicator
                            isOneColor
                            healthPercentage={pet.healthPercentage}
                            badge
                            showMaxHealth={false}
                        />
                        <AgeIndicator
                            isOneColor
                            ageDays={pet.ageDays}
                            maxAgeDays={pet.maxAgeDays}
                            containerStyle={styles.ageWrapper}
                        />
                    </View>
                </View>
            </View>
        </TouchableOpacity>
    );
};
export default NftEvolutionPetCard;
