import { IStage } from '..';

export enum MINT_PET_STAGES_NAME {
    STEP_ONE = 'STEP_ONE',
    STEP_TWO = 'STEP_TWO',
    STEP_THREE = 'STEP_THREE',
}

type IMintNFTStages = {
    STEP_ONE: IStage;
    STEP_TWO: IStage;
    STEP_THREE: IStage;
};

export const mintNFTstages: IMintNFTStages = {
    STEP_ONE: {
        customFillIndex: 1,
        fill: ['100%', '0%', '0%'],
    },

    STEP_TWO: {
        customFillIndex: 2,
        fill: ['100%', '0%', '0%'],
    },

    STEP_THREE: {
        customFillIndex: 3,
        fill: ['100%', '100%', '100%'],
    },
};
