import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { scale, verticalScale } from '../../helpers/dimensions';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        singleButton: {
            paddingHorizontal: scale(8),
            paddingVertical: verticalScale(8),
            maxWidth: scale(32),
            maxHeight: scale(32),
        },
        container: {
            paddingHorizontal: scale(16),
            paddingBottom: verticalScale(16),
            flexDirection: 'row',
            alignItems: 'center',
            position: 'relative',
            zIndex: 10000,
        },
        innerContainer: {
            backgroundColor: 'transparent',
            flex: 1,
            flexDirection: 'row',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        settingsBLock: {
            backgroundColor: 'transparent',
            flexDirection: 'row',
        },
        navigationBlock: {
            backgroundColor: 'transparent',
        },
        middleBlock: {
            backgroundColor: 'transparent',
            flex: 1,
        },
        spaceBetween: {
            justifyContent: 'space-between',
        },
        containerFilled: {
            backgroundColor: Colors[theme].navigationBar.background,
        },
        buttonWrapper: {
            backgroundColor: 'transparent',
        },
        withChildren: {
            backgroundColor: Colors[theme].background,
        },
        buttonWrapperChildren: {
            backgroundColor: 'transparent',
        },
        emptyIcon: {
            width: scale(32),
            height: scale(32),
            backgroundColor: 'transparent',
        },
        avatar: {
            width: scale(32),
        },
        role: {
            width: scale(20),
        },
        roleWrapper: {
            position: 'absolute',
            bottom: '-5%',
            right: '-5%',
            backgroundColor: 'transparent',
        },
    });

export default styles;
