import { QuantumSpinGamePrizeOutput } from '@models/mysteryGames';

export const quantumSpinFindWinIndex = (
    prizes: QuantumSpinGamePrizeOutput[],
    prize: string | null
) => {
    if (!prize) {
        return -1;
    }
    return prizes.findIndex((i) => i.iguAmountWei === prize);
};
