import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
        },
        page: {},
        itemsGap: {
            marginBottom: verticalScale(20),
        },
        communitiesWrapper: {
            paddingHorizontal: scale(20),
        },
        mediaWrapper: {
            paddingHorizontal: scale(20),
            paddingBottom: verticalScale(36),
        },
        divider: {
            width: '100%',
            marginVertical: verticalScale(24),
        },
    });

export default styles;
