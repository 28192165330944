import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        modalContainer: {
            marginVertical: verticalScale(32),
            paddingHorizontal: scale(20),
        },

        cardWrapper: {
            marginTop: verticalScale(20),
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
        },
        pet: {
            paddingHorizontal: 0,
        },
        explore: {
            alignSelf: 'center',
            marginTop: verticalScale(16),
        },
        wallet: {
            alignSelf: 'center',
            marginTop: verticalScale(16),
        },
    });

export default styles;
