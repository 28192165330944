import { StyleSheet } from 'react-native';

import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        buttons: {
            paddingHorizontal: 0,
        },
    });

export default styles;
