import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {},
        flatList: {
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            paddingHorizontal: scale(16),
            paddingBottom: verticalScale(16),
        },
        itemStyle: {
            paddingHorizontal: 0,
            paddingVertical: 0,
            marginTop: verticalScale(16),
        },
        itemContainer: {
            paddingVertical: verticalScale(8),
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 18,
                height: 26,
                weight: '700',
            }),
            marginRight: verticalScale(4),
        },
        itemText: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
            marginLeft: verticalScale(8),
        },
        titleContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: verticalScale(16),
        },
        titleWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        iconWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        noNFTWrapper: {
            flex: 1,
            alignItems: 'center',
            paddingTop: verticalScale(32),
        },
        noNFTText: {
            textAlign: 'center',
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
            letterSpacing: 0.16,
            marginBottom: verticalScale(16),
        },
        noNFTTitle: {
            marginTop: verticalScale(20),
            color: Colors[theme].text.primary,
            ...font({
                size: 18,
                height: 26,
                weight: '700',
            }),
            marginBottom: verticalScale(6),
        },
        button: {
            width: '100%',
        },
        viewAllButton: {
            marginTop: verticalScale(5),
        },
        loadingWrapper: {},
    });

export default styles;
