import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import { stylesAlignCenter } from '@helpers/style';

import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            backgroundColor: Colors[theme].petRankItem.backgroundDefault,
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].petRankItem.borderColorDefault,
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(12),
            marginBottom: verticalScale(4),
        },
        shadow: {
            width: '48.5%',
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].petRankItem.shadowColorDefault,
            marginBottom: verticalScale(16),
        },
        selectedShadow: {
            backgroundColor: Colors[theme].petRankItem.shadowColorSelected,
        },
        selected: {
            backgroundColor: Colors[theme].petRankItem.backgroundSelected,
            borderColor: Colors[theme].petRankItem.borderColorSelected,
            shadowColor: Colors[theme].petRankItem.shadowColorSelected,
        },
        imgWrapper: {
            position: 'absolute',
            alignSelf: 'flex-start',
            right: 4,
            top: 4,
        },
        bottomWrapper: {
            flex: 1,
        },
        loading: {
            ...stylesAlignCenter(),
        },
        loadingWrapper: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: verticalScale(12),
            left: scale(12),
            zIndex: 10,
            backgroundColor: Colors[theme].background,
        },
    });

export default styles;
