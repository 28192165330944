import * as React from 'react';
import { SafeAreaView } from 'react-native';

import { isAvailableForMint } from '@helpers/ranks';

import { Rank } from '../../../Data/Models';
import BonusIcon from '../../../assets/icons/rankMintBonus/rankBonus.svg';
import BlockHeader from '../../../components/BlockHeader/BlockHeader';
import ButtonsBottom from '../../../components/ButtonsBottom';
import CardFeatureInfo from '../../../components/CardFeatureInfo/CardFeatureInfo';
import Carousel from '../../../components/Carousel';
import CarouselScrollView from '../../../components/Carousel/components/CarouselScrollView';
import Icon, { ICON_NAMES } from '../../../components/Icons';
import RankIcon from '../../../components/Icons/RankIcon';
import { View } from '../../../components/Themed';
import { useConfig } from '../../../contexts/ConfigContext';
import { useDimensions } from '../../../contexts/DimensionsContext';
import { useWallet } from '../../../contexts/Wallet/WalletContext';
import { Coin } from '../../../contexts/Wallet/WalletHelpers';
import { scale } from '../../../helpers/dimensions';
import { waitForNavigation } from '../../../helpers/helpers';
import { balanceFromWei } from '../../../helpers/wallet';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import NavigationBarModal from '../../../navigation/NavigationBar/NavigationBarModal';
import useNavigationListeners from '../../../navigation/hooks/useNavigationListeners';
import { useNavigation } from '../../../navigation/useNavigation';
import { BonusMintRanksCarouselProps } from '../../../types';
import MintPriceBackground from '../../MintNFT/components/MintPriceBackground';
import stylesMain from './styles';

const BonusMintRanksCarouselScreen = ({
    route,
}: BonusMintRanksCarouselProps) => {
    useNavigationListeners();
    const { windowWidth } = useDimensions();
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const { priceInUsd } = useWallet();
    const { canUsePayments } = useConfig();

    const { ranks } = route.params;
    const [snapItem, setSnapItem] = React.useState<number>(0);

    const handleMintRank = React.useCallback(async () => {
        navigation.pop();
        await waitForNavigation();
        navigation.navigateToMint({
            rank: ranks[snapItem],
            skip: true,
        });
    }, [snapItem]);

    const renderItem = (rank: Rank) => {
        const { slug, mintBonus } = rank;
        const isFreeRank = slug === 'free';

        if (!mintBonus) {
            return;
        }

        const bonusValueRank = balanceFromWei(
            mintBonus.igupAmountWei
        ).valueLong;
        const bonusValueRankUsd = priceInUsd(Coin.igup, bonusValueRank);

        return (
            <CarouselScrollView style={styles.content}>
                <View style={styles.imgWrapper}>
                    <RankIcon size={120} slug={slug} />
                </View>
                {(isAvailableForMint(rank, canUsePayments) || isFreeRank) && (
                    <MintPriceBackground
                        rank={rank}
                        iconSize={24}
                        textStyle={styles.textStyle}
                        customStyle={styles.mintPriceContainer}
                    />
                )}
                <BlockHeader
                    type={'emptyState'}
                    title={i18n.t('ranks.rankLongTitle', {
                        rank: i18n.t(`ranks.list.${slug}.title`),
                    })}
                />
                {mintBonus && (
                    <View style={{ width: windowWidth - scale(20) * 2 }}>
                        <CardFeatureInfo
                            containerStyle={styles.gap}
                            title={i18n.t(
                                'MintBonusScreen.BonusMintRanksCarouselScreen.features.bonus.key',
                                {
                                    amount: bonusValueRank,
                                    dollarValue: bonusValueRankUsd,
                                }
                            )}
                            text={i18n.t(
                                'MintBonusScreen.BonusMintRanksCarouselScreen.features.bonus.value'
                            )}
                            icon={<BonusIcon width={48} height={48} />}
                        />
                        <CardFeatureInfo
                            containerStyle={styles.gap}
                            title={i18n.t(
                                'MintBonusScreen.BonusMintRanksCarouselScreen.features.lock.key',
                                {
                                    days: mintBonus.lockedPeriodDays,
                                }
                            )}
                            text={i18n.t(
                                'MintBonusScreen.BonusMintRanksCarouselScreen.features.lock.value'
                            )}
                            icon={
                                <Icon name={ICON_NAMES.LOCK_FILL} size={48} />
                            }
                        />
                    </View>
                )}
            </CarouselScrollView>
        );
    };

    return (
        <View style={styles.main}>
            <SafeAreaView style={styles.safeArea}>
                <View style={styles.header}>
                    <NavigationBarModal
                        iconName={ICON_NAMES.CLOSE}
                        title={i18n.t(
                            'MintBonusScreen.BonusMintRanksCarouselScreen.screenTitle'
                        )}
                        onBack={() => navigation.pop()}
                    />
                </View>
                <Carousel
                    data={ranks}
                    sliderWidth={windowWidth}
                    itemWidth={windowWidth}
                    renderItem={(item) => renderItem(item)}
                    fullHeight
                    pagination
                    start
                    centredVertical
                    insideScrollView
                    onSnapToItem={(index: number) => setSnapItem(index)}
                    startIndex={0}
                />
                <ButtonsBottom
                    onPress={handleMintRank}
                    title={i18n.t(
                        'MintBonusScreen.BonusMintRanksCarouselScreen.button1'
                    )}
                />
            </SafeAreaView>
        </View>
    );
};

export default BonusMintRanksCarouselScreen;
