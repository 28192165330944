import * as React from 'react';

import { MarketplaceNftDetailsPetOutput } from '@models/marketplace';

import OverviewAgeDetailsBlock from '../OverviewAgeDetailsBlock';
import OverviewAnimalTypeDetailsBlock from '../OverviewAnimalTypeDetailsBlock';
import OverviewHealthDetailsBlock from '../OverviewHealthDetailsBlock';
import OverviewLevelDetailsBlock from '../OverviewLevelDetailsBlock';
import OverviewRankDetailsBlock from '../OverviewRankDetailsBlock';

interface IProps {
    pet: MarketplaceNftDetailsPetOutput;
}

const OverviewPetSection = ({ pet }: IProps) => {
    return (
        <>
            <OverviewLevelDetailsBlock pet={pet} />
            <OverviewRankDetailsBlock pet={pet} />
            <OverviewHealthDetailsBlock pet={pet} />
            <OverviewAgeDetailsBlock pet={pet} />
            {pet.isFromPhoto && <OverviewAnimalTypeDetailsBlock pet={pet} />}
        </>
    );
};

export default OverviewPetSection;
