import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
            backgroundColor: Colors[theme].surfaceDecorative_Blue_Strong,
        },
        title: {
            color: Colors[theme].text.onLight,
        },
    });

export default styles;
