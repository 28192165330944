import { StyleSheet } from 'react-native';

import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        input: {
            textAlignVertical: 'top',
        },
    });

export default styles;
