import { ReactElement } from 'react';
import { SvgProps } from 'react-native-svg';

import IGUI from '../../../assets/icons/staking/iguInterestSelect.svg';
import IGUR from '../../../assets/icons/staking/iguRewardSelect.svg';
import IGUP from '../../../assets/icons/staking/igupSelect.svg';

export type IconsData = {
    IGUP: ReactElement<SvgProps>;
    IGU_REWARD: ReactElement<SvgProps>;
    IGU_INTEREST: ReactElement<SvgProps>;
};

export const iconsData: IconsData = {
    IGUP: <IGUP />,
    IGU_REWARD: <IGUR />,
    IGU_INTEREST: <IGUI />,
};
