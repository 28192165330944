import * as React from 'react';

import { createNativeStackNavigator } from '@react-navigation/native-stack';

import { PlayToEarnWelcomeScreen } from '../../screens/TabBar/Play/PlayToEarn/screens/PlayToEarnWelcome/PlayToEarnWelcomeScreen';
import { PlayToEarnProgressScreen } from '../../screens/TabBar/Play/PlayToEarn/screens/playToEarnProgress/PlayToEarnProgressScreen';
import { PlayToEarnParamList } from '../../types';
import ROUTES from '../routes';

const PlayToEarnStack = createNativeStackNavigator<PlayToEarnParamList>();

const PlayToEarnNavigator = () => {
    return (
        <PlayToEarnStack.Navigator
            initialRouteName={ROUTES.PLAY_TO_EARN_WELCOME}
            screenOptions={{
                headerShown: false,
                gestureEnabled: false,
            }}>
            <PlayToEarnStack.Screen
                name={ROUTES.PLAY_TO_EARN_WELCOME}
                options={{ gestureEnabled: false }}
                component={PlayToEarnWelcomeScreen}
            />
            <PlayToEarnStack.Screen
                name={ROUTES.PLAY_TO_EARN_PROGRESS}
                options={{ gestureEnabled: false }}
                component={PlayToEarnProgressScreen}
            />
        </PlayToEarnStack.Navigator>
    );
};

export default PlayToEarnNavigator;
