import { useEffect, useState } from 'react';

import { User } from '@Data/Models';
import { MARK_REFERRER } from '@Data/Requests';
import { useApolloClient } from '@apollo/client';
import { ApolloClient } from '@apollo/client/core';
import { useAppState } from '@contexts/AppStateContext';
import { useSession } from '@contexts/SessionContext';
import { useUser } from '@contexts/UserContext';
import { errorsHandler } from '@helpers/errors';
import { waitForDeepLink } from '@helpers/helpers';
import usePersistNavigationState from '@navigation/hooks/usePersistNavigationState';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { State } from '../../../types';
import ROUTES from '../../routes';
import { useNavigation } from '../../useNavigation';
import { getRoute, matchState } from './helpers';
import { DeferDeepLinkType } from './types';

const Linking: Array<DeferDeepLinkType> = [
    {
        regex: /r\/.*/,
        state: [State.loggedInNoPet, State.loggedInWallet],
        beforeRedirect: async (
            client: ApolloClient<object>,
            user: User | undefined,
            path: string
        ) => {
            if (user?.isReferred) {
                return false;
            }

            const split = path ? path.split('/') : [];
            return client.mutate({
                mutation: MARK_REFERRER,
                variables: {
                    referralCode: split[split.length - 1] || '',
                },
            });
        },
        redirect: (navigation: any) => {
            navigation.navigate(ROUTES.INITIAL_REFERRAL);
        },
    },
    {
        regex: /u\/trade/,
        state: [State.loggedInWallet],
        redirect: (navigation: any) => {
            navigation.navigateToTrade();
        },
    },
    {
        regex: /download\/mystery-boxes/,
        state: [State.loggedInWallet],
        redirect: (navigation: any) => {
            navigation.navigate(ROUTES.MYSTERY_BOX_MAIN);
        },
    },
];

const config = {
    storageKey: 'useDeferDeepLinking.path',
};

export default function useDeferDeepLinking() {
    const { resetState } = usePersistNavigationState();
    const { setSessionToken } = useSession();
    const { state } = useAppState();
    const navigation = useNavigation();
    const client = useApolloClient();
    const { user } = useUser();
    const [deferUsed, setDeferUsed] = useState<boolean>(false);

    // Get path for mobile from clipboard, for web from url
    const getPath = async () => {
        return window.location.pathname;
    };

    const setSession = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const sessionToken = urlParams.get('s');
        if (sessionToken) {
            setSessionToken(sessionToken);
        }
    };

    useEffect(() => {
        async function setLinking() {
            const path = await getPath();
            const currentRoute = getRoute(Linking, path);

            if (!path || !currentRoute) {
                return;
            }

            // Set route to local storage
            Console.log(`[DeferDeepLinking] setRoute ${path}`);
            await AsyncStorage.setItem(config.storageKey, path);
        }

        setLinking();
        setSession();
    }, []);

    // handle defer deep linking
    useEffect(() => {
        async function handleLinking() {
            const deepLinkPath = await AsyncStorage.getItem(config.storageKey);
            const route = getRoute(Linking, deepLinkPath);

            if (!deepLinkPath || !route || deferUsed) {
                return;
            }

            Console.log(
                `[DeferDeepLinking] Found state: state=${deepLinkPath} ${state}`
            );

            if (matchState(state, route?.state)) {
                Console.log(
                    `[DeferDeepLinking] matchState state=${state} with ${route?.state}`
                );
                await AsyncStorage.setItem(config.storageKey, '');
                resetState(); // reset persiste state to remove bug with Trade screen
                setDeferUsed(true);

                // if we have beforeRedirect ,then perform action and redirect after
                if (route?.beforeRedirect) {
                    try {
                        const result = await route.beforeRedirect(
                            client,
                            user,
                            deepLinkPath
                        );

                        if (result.data && route?.redirect) {
                            await waitForDeepLink();
                            route.redirect(navigation);
                        } else {
                            Console.log(
                                `[DeferDeepLinking] Not apply state=${state} with deepLinkPath=${deepLinkPath}`
                            );
                        }
                    } catch (error) {
                        errorsHandler(error);
                    }
                    // just redirect
                } else if (route?.redirect) {
                    route.redirect(navigation);
                }
            }
        }

        handleLinking();
    }, [state, user, deferUsed, navigation]);

    return null;
}
