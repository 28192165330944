import React from 'react';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import { PassCode } from '@components/PassCode';
import { Text, View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';

import mainStyles from './styles';

type Props = {
    code: string;
    setCode: (code: string) => void;
    error?: string;
    title: string;
    text: string;
    maxLength?: number;
    absolute?: boolean;
    backIcon?: 'close' | 'leftArrow';
    onClose?: () => void;
    header?: string;
};

export const PassCodeWrapper = ({
    code,
    setCode,
    error,
    title,
    text,
    maxLength = 4,
    absolute,
    backIcon = 'leftArrow',
    onClose,
    header,
}: Props) => {
    const styles = useThemedStyles(mainStyles);

    return (
        <View style={[styles.wrapper, absolute && styles.absolute]}>
            <NavigationBar
                filled={false}
                backIcon={backIcon}
                onBack={onClose}
                title={header || i18n.t('passcode.passcode')}
            />
            <View style={styles.container}>
                <BlockHeader type={'primary'} title={title} text={text} />
                <View style={styles.passCode}>
                    <PassCode
                        maxLength={maxLength}
                        code={code}
                        setCode={setCode}
                    />
                </View>
                {!!error && <Text style={styles.error}>{error}</Text>}
            </View>
        </View>
    );
};
