import { MysteryBoxSaleStatsResponse } from '@Data/Models';
import { GET_MYSTERY_BOX_SALE_STATS } from '@Data/Requests';
import { useQuery } from '@apollo/client';
import { toastErrorUnknown } from '@helpers/toastNotification';

const useMysteryBoxStatSale = () => {
    const {
        data: statSaleData,
        loading: statLoading,
        refetch: statRefetch,
        error: statError,
    } = useQuery<MysteryBoxSaleStatsResponse>(GET_MYSTERY_BOX_SALE_STATS, {
        fetchPolicy: 'network-only',
        onError: (error) => {
            Console.error(error);
            toastErrorUnknown();
        },
    });

    return {
        statSaleData,
        statLoading,
        statRefetch,
        statError,
    };
};

export default useMysteryBoxStatSale;
