import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(16),

            backgroundColor: Colors[theme].backgroundPrimaryMinimal,
            borderRadius: moderateScale(12),
            alignItems: 'center',
            alignContent: 'center',
        },
        onLight: {
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            backgroundColor: 'transparent',
        },
        onLightTitle: {
            color: Colors[theme].text.secondary,
        },
        onLightText: {
            color: Colors[theme].text.primary,
        },
        imageWrapper: {
            backgroundColor: 'transparent',
        },
        title: {
            color: Colors[theme].text.onLight,
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
            marginTop: scale(2),
            textAlign: 'center',
        },
        amount: {
            color: Colors[theme].text.onLight,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
            marginTop: scale(6),
            textAlign: 'center',
        },
    });

export default styles;
