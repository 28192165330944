import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        title: {
            color: Colors[theme].text.accent,
            ...font({
                size: 15,
                height: 22,
                weight: '700',
            }),
            marginRight: scale(6),
        },
    });

export default styles;
