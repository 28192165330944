import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingTop: verticalScale(8),

            marginHorizontal: scale(20),
        },
        ownersProfileWrapper: {
            alignItems: 'center',
        },
        ownersProfile: {
            marginTop: verticalScale(16),
            alignItems: 'center',
            maxWidth: '90%',
        },
        ownersProfileTitle: {
            textAlign: 'center',
            marginBottom: verticalScale(6),
            color: Colors[theme].text.primary,
            ...font({
                size: 20,
                height: 26,
                weight: '700',
            }),
        },
        ownersProfileLabel: {
            textAlign: 'center',
            color: Colors[theme].text.secondary,
            ...font({
                size: 15,
                height: 22,
                weight: '600',
            }),
        },
        cardSize: {
            width: '48%',
        },
        summaryWrapper: {
            marginTop: verticalScale(20),
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    });

export default styles;
