import * as React from 'react';

import Svg from '../../assets/icons/general/dollar.svg';
import { IconType, iconSize } from './types';

const Dollar: React.FunctionComponent<IconType> = ({
    size = iconSize.default,
}) => <Svg width={size} height={size} />;

export default Dollar;
