import * as React from 'react';
import { useMemo } from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import { RejectReasonStatus, SocialTaskStatus } from '@Data/Models';
import ShareInSocialGameTaskIcon from '@assets/icons/play/taskSocial.svg';
import AlertCard from '@components/AlertCard';
import Animation from '@components/Animation';
import Button from '@components/Button';
import ButtonsBottom from '@components/ButtonsBottom';
import { ICON_NAMES } from '@components/Icons';
import ModalBottom from '@components/ModalBottom';
import { Text, View } from '@components/Themed';
import TooltipSocial from '@components/TooltipSocial';
import { Links } from '@constants/Links';
import { moderationRejectReasonsStatuses } from '@constants/ModerationRejectReasonsStatuses';
import { useConfig } from '@contexts/ConfigContext';
import { isAndroid } from '@helpers/app';
import { openUrlOrShowError } from '@helpers/linking';
import { dateDiffInSeconds } from '@helpers/time';
import { useSocialTooltip } from '@hooks/useSocialTooltip';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import EnergyBlock from '../EnergyBlock';
import TaskStatusBadge from '../TaskStatusBadge';
import stylesMain from './styles';

interface IProps {
    energyRewarded: number | null;
    maxEnergyReward: number | undefined;
    status: SocialTaskStatus | null;
    onButtonPress: () => void;
    socialConnected?: boolean | undefined;
    canStart: boolean | undefined;
    onCompletedPress: () => void;
    moderatorRejectReason: RejectReasonStatus | null;
    loading?: boolean;
    disabled?: boolean;
    isModalVisible: boolean;
    taskId: string;
}

// Whether to show warning about 12 hours for social task
const socialConfig = {
    verifyAlertThreshold: 12 * 1000 * 3600,
};

const SocialTaskCard = ({
    energyRewarded,
    status,
    moderatorRejectReason,
    maxEnergyReward,
    onButtonPress,
    socialConnected,
    canStart,
    onCompletedPress,
    loading,
    disabled,
    isModalVisible,
    taskId,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { config, isNewIosVersion } = useConfig();
    const [failedModal, setFailedModal] = React.useState(false);
    const rewardedEnergy = energyRewarded || 0;
    const maxEnergy = maxEnergyReward || 0;
    const buttonText =
        !socialConnected && canStart
            ? i18n.t('playScreen.playCards.addSocialAccount')
            : i18n.t('playScreen.playCards.SHARE_button');

    const handleOpenFailedModal = () => {
        setFailedModal(true);
    };

    const handleCloseFailedModal = () => {
        setFailedModal(false);
    };

    const RejectReasonText = {
        [moderationRejectReasonsStatuses.notEnoughFollowers]:
            'notEnoughFollowers',
        [moderationRejectReasonsStatuses.petIdNotFound]: 'petIdNotFound',
        [moderationRejectReasonsStatuses.petIdIncorrectPosition]:
            'petIdIncorrectPosition',
        [moderationRejectReasonsStatuses.userNotFound]: 'userNotFound',
    };

    const isLessThanVerifyThreshold = useMemo(() => {
        if (!config) {
            return false;
        }

        return (
            dateDiffInSeconds(config?.nextGameIterationDate, new Date()) <=
            socialConfig.verifyAlertThreshold
        );
    }, [config]);

    const { showTooltip, closeTooltip } = useSocialTooltip(
        isModalVisible,
        status,
        taskId
    );

    const renderStatus = React.useCallback(() => {
        if (!canStart) {
            switch (status) {
                case 'COMPLETED':
                    return (
                        <TaskStatusBadge
                            status={status}
                            onPress={onCompletedPress}
                            withInfoIcon
                        />
                    );

                case 'AWAITS_VERIFICATION':
                case 'MODERATOR_VERIFICATION_REQUIRED':
                    return <TaskStatusBadge status={status} />;

                case 'FAILED':
                    return (
                        <TooltipSocial
                            isVisible={showTooltip}
                            content={i18n.t('socialTooltip.text')}
                            placement="bottom"
                            onClose={closeTooltip}>
                            <TaskStatusBadge
                                status={status}
                                onPress={handleOpenFailedModal}
                                withInfoIcon
                            />
                        </TooltipSocial>
                    );

                default:
                    return (
                        <EnergyBlock
                            size={16}
                            energy={`${rewardedEnergy} / ${maxEnergy}`}
                            textStyles={styles.energyCost}
                            containerStyles={styles.energyBlock}
                        />
                    );
            }
        }

        return (
            <EnergyBlock
                size={16}
                energy={`${0} / ${maxEnergy}`}
                textStyles={styles.energyCost}
                containerStyles={styles.energyBlock}
            />
        );
    }, [
        maxEnergy,
        rewardedEnergy,
        status,
        showTooltip,
        canStart,
        onCompletedPress,
    ]);

    const onPressKnowledgeBase = () => {
        if (config?.knowledgeBaseUrl) {
            openUrlOrShowError(
                config.knowledgeBaseUrl + Links.socializeToEarnRequirements
            );
        }
    };

    const renderFailedModal = () => {
        return (
            <ModalBottom
                modalHeight={800}
                isVisible={failedModal}
                onClose={handleCloseFailedModal}>
                <ScrollView
                    contentContainerStyle={styles.failedModal}
                    showsVerticalScrollIndicator={false}>
                    <TouchableOpacity activeOpacity={1}>
                        <Animation animation="cry" style={styles.animation} />
                        <Text style={styles.failedModalTitle}>
                            {i18n.t('playScreen.playCards.SHARE_failed_title')}
                        </Text>
                        <Text style={styles.failedModalText}>
                            {i18n.t('playScreen.playCards.SHARE_failed_text')}
                        </Text>
                        <Button
                            onPress={onPressKnowledgeBase}
                            title={i18n.t('playScreen.playCards.knowledgeBase')}
                            containerStyle={styles.knowledge}
                            iconName={ICON_NAMES.EXPLORE}
                            type="text"
                            iconDirection="right"
                        />
                        {moderatorRejectReason && (
                            <AlertCard
                                containerStyle={styles.rejectReasonAlertCard}
                                text={i18n.t(
                                    `playScreen.playCards.SHARE_failed_reasons.${RejectReasonText[moderatorRejectReason]}`
                                )}
                            />
                        )}
                    </TouchableOpacity>
                </ScrollView>
                <ButtonsBottom
                    safe={!isAndroid}
                    title={i18n.t('playScreen.playCards.SHARE_failed_button')}
                    onPress={handleCloseFailedModal}
                />
            </ModalBottom>
        );
    };

    return (
        <View style={styles.container}>
            <View style={styles.top}>
                <View style={styles.textWrapper}>
                    <View style={styles.statutes}>{renderStatus()}</View>
                    <Text style={styles.title}>
                        {i18n.t('playScreen.playCards.SHARE_title')}
                    </Text>
                    <Text style={styles.info}>
                        {i18n.t('playScreen.playCards.SHARE_info')}
                    </Text>
                </View>
                <ShareInSocialGameTaskIcon />
            </View>
            {!canStart &&
            status !== 'CREATED' &&
            status !== 'AWAITS_VERIFICATION' &&
            status !== 'MODERATOR_VERIFICATION_REQUIRED' ? (
                <EnergyBlock
                    size={20}
                    energy={i18n.t('playScreen.playCards.completedEnergy', {
                        energyRewarded: rewardedEnergy,
                        maxEnergyReward: maxEnergy,
                    })}
                    textStyles={styles.energyCost}
                    containerStyles={styles.completedEnergy}
                />
            ) : (
                <View>
                    {!isNewIosVersion && isLessThanVerifyThreshold && (
                        <View style={styles.alertWrapper}>
                            <AlertCard
                                text={i18n.t(
                                    'playScreen.playCards.socialAlert'
                                )}
                            />
                        </View>
                    )}
                    <Button
                        title={buttonText}
                        onPress={onButtonPress}
                        loading={loading}
                        disabled={disabled}
                    />
                </View>
            )}
            {renderFailedModal()}
        </View>
    );
};

export default SocialTaskCard;
