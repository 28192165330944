import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(20),
            marginVertical: verticalScale(32),
        },
        main: {
            flex: 1,
        },
        header: {},

        slots: {
            marginTop: verticalScale(20),
        },
        loading: {
            flex: 1,

            height: 400,
        },
    });

export default styles;
