import React, { useState } from 'react';

import { PetIdType } from '@Data/Models';
import {
    ISocialToEarnCard,
    bybitSocialToEarnCardTemplates,
    defaultSocialToEarnCardTemplates,
} from '@constants/shareCardTemplatesNew';
import { useConfig } from '@contexts/ConfigContext';
import { usePlay } from '@contexts/PlayContext';
import { isWeb } from '@helpers/app';

import { usePersistStorage } from '../vendor/react-native-user-persist-storage';

const useTemplateColors = () => {
    const { templateColorSaved, setTemplateColorSaved } = usePlay();

    const { config } = useConfig();

    const [cardType, setCardType] = usePersistStorage<PetIdType>(
        'PlayProvider.cardType',
        'Default',
        {
            persist: isWeb,
        }
    );
    const [templatesColors, setTemplatesColors] = usePersistStorage<
        ISocialToEarnCard[]
    >('PlayProvider.templatesColors', defaultSocialToEarnCardTemplates, {
        persist: isWeb,
    });

    const shouldReset = React.useMemo(
        () => templateColorSaved.cardTypeId !== config?.petIdType,
        [templateColorSaved, config?.petIdType]
    );

    const set = React.useCallback(
        (backgrounds: ISocialToEarnCard[], type: PetIdType) => {
            if (templateColorSaved.cardTypeId !== type) {
                setTemplateColorSaved(backgrounds[0]);
            }

            setTemplatesColors(backgrounds);
            setCardType(type);
        },
        [templateColorSaved]
    );

    React.useEffect(() => {
        switch (config?.petIdType) {
            case 'Bybit':
                set(bybitSocialToEarnCardTemplates, 'Bybit');
                break;
            default:
                set(defaultSocialToEarnCardTemplates, 'Default');
                break;
        }
    }, [config?.petIdType, set, shouldReset]);

    const changeTemplateColor = (background: ISocialToEarnCard) => {
        setTemplateColorSaved(background);
    };

    return {
        templatesColors,
        templateColor: templateColorSaved,
        changeTemplateColor,
        cardType,
    };
};

export default useTemplateColors;
