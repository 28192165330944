import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import { TokenTransaction } from '../../../../../Data/Models';
import ExplorerLink from '../../../../../components/ExplorerLink';
import StatusIcon from '../../../../../components/Icons/StatusIcon';
import TransactionStatusIcon from '../../../../../components/Icons/TransactionTypeIcon';
import { Text, View } from '../../../../../components/Themed';
import Colors from '../../../../../constants/Colors';
import { useTheme } from '../../../../../contexts/ThemeContext';
import { dateFormatter } from '../../../../../helpers/time';
import { balanceFromWei } from '../../../../../helpers/wallet';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import ROUTES from '../../../../../navigation/routes';
import { useNavigation } from '../../../../../navigation/useNavigation';
import stylesMain from './styles';

interface IProps {
    item: TokenTransaction;
    address: string | undefined;
    coinName: string;
}

const WalletCoinsTransactionHistoryListItem = ({
    item,
    address,
    coinName,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { theme } = useTheme();

    const navigation = useNavigation();

    const {
        type,
        timeStamp,
        txCost,
        value,
        status,
        explorerUrl,
        from: addressFrom,
        to: addressTo,
    } = item;

    const fee = balanceFromWei(txCost).valueLong.toString();

    const priceColor = React.useCallback(() => {
        if (type === 'DEPOSIT' || type === 'CLAIM') {
            return Colors[theme].text.accent;
        }

        // Swap Deposit/Withdraw handle
        if (type === 'SWAP') {
            return address?.toLowerCase() === addressTo.toLowerCase()
                ? Colors[theme].text.accent
                : Colors[theme].text.primary;
        }

        return status === 'FAILED'
            ? Colors[theme].text.critical
            : Colors[theme].text.primary;
    }, [address, status, type, addressTo, theme]);

    const transactionValue = React.useCallback((): string => {
        if (type === 'UNKNOWN' && fee) {
            return `-${fee}`;
        }

        if (!Number(value)) {
            return '0';
        }
        const { valueLong } = balanceFromWei(value);

        // Swap Deposit/Withdraw handle
        if (type === 'SWAP') {
            return address?.toLowerCase() === addressTo.toLowerCase()
                ? `+${valueLong}`
                : `-${valueLong}`;
        }

        return type === 'DEPOSIT' || type === 'CLAIM'
            ? `+${valueLong}`
            : `-${valueLong}`;
    }, [address, type, fee, value, addressTo]);

    const date = dateFormatter(+timeStamp);

    const color = priceColor();

    // we do not display icons for Confirmed transactions
    const statusIcon = status !== 'CONFIRMED' && <StatusIcon slug={status} />;

    return (
        <TouchableOpacity
            activeOpacity={0.7}
            onPress={() =>
                navigation.navigate(ROUTES.WALLET_COINS_TRANSACTION_DETAILS, {
                    transaction: {
                        date,
                        value: transactionValue(),
                        color,
                        type,
                        addressFrom,
                        addressTo,
                        fee,
                        coinName,
                        status,
                        explorerUrl,
                    },
                })
            }>
            <View
                style={[
                    styles.itemContainer,
                    // index + 1 === tokenTransactions?.length && styles.gap,
                ]}>
                <View style={styles.left}>
                    <View>
                        <View style={styles.icon}>
                            <TransactionStatusIcon type={type} />
                            <View style={styles.statusIcon}>{statusIcon}</View>
                        </View>
                    </View>
                    <View style={styles.labelWrapper}>
                        <Text style={styles.label}>
                            {i18n.t(`wallet.transactions.coin.${type}.name`)}
                        </Text>
                        <Text style={styles.dateText}>{date}</Text>
                    </View>
                </View>
                <View style={styles.right}>
                    <Text style={[styles.price, { color }]}>
                        {transactionValue()}
                    </Text>
                    <ExplorerLink
                        explorerUrl={explorerUrl}
                        linkStyles={styles.bscScan}
                        iconType="secondary"
                    />
                </View>
                {/* <Text style={styles.itemText}></Text> */}
            </View>
        </TouchableOpacity>
    );
};

export const MemoizedWalletCoinsTransactionHistoryListItem = React.memo(
    WalletCoinsTransactionHistoryListItem
);
