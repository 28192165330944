import * as React from 'react';
import { ScrollView } from 'react-native';

import { View } from '@components/Themed';
import { useVisibleHook } from '@hooks/useVisibleHook';

import BlockHeader from '../../../components/BlockHeader/BlockHeader';
import Divider from '../../../components/Divider';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import NavigationBar from '../../../navigation/NavigationBar';
import LinkItem from './components/LinkItem';
import TelegramExtendedModal from './components/TelegramExtendedModal';
import { COMMUNITIES, MEDIA } from './socials';
import stylesMain from './styles';

const SocialMediaScreen = () => {
    const styles = useThemedStyles(stylesMain);
    const { isVisible, open, close } = useVisibleHook();

    return (
        <View style={styles.container}>
            <NavigationBar
                backIcon
                title={i18n.t('profile.socialMediaSettings.screenTitle')}
                filled={false}
            />
            <ScrollView
                style={styles.page}
                showsVerticalScrollIndicator={false}>
                <View style={styles.communitiesWrapper}>
                    <BlockHeader
                        type="primary"
                        title={i18n.t(
                            'profile.socialMediaSettings.communities.title'
                        )}
                        text={i18n.t(
                            'profile.socialMediaSettings.communities.text'
                        )}
                    />
                    <View style={styles.linksWrapper}>
                        {COMMUNITIES.map((item) => (
                            <LinkItem
                                key={item.id}
                                item={item}
                                type="communities"
                                onTelegramPress={open}
                            />
                        ))}
                    </View>
                </View>
                <Divider customStyles={styles.divider} />
                <View style={styles.mediaWrapper}>
                    <BlockHeader
                        type="primary"
                        title={i18n.t(
                            'profile.socialMediaSettings.media.title'
                        )}
                        text={i18n.t('profile.socialMediaSettings.media.text')}
                    />
                    <View style={styles.linksWrapper}>
                        {MEDIA.map((item) => (
                            <LinkItem key={item.id} item={item} type="media" />
                        ))}
                    </View>
                </View>
            </ScrollView>
            <TelegramExtendedModal visible={isVisible} onClose={close} />
        </View>
    );
};

export default SocialMediaScreen;
