import * as React from 'react';
import { useCallback } from 'react';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import EmptyList from '@components/EmptyList';
import LoadingComponent from '@components/LoadingComponent';
import ModalLoader from '@components/ModalLoader';
import PullToRefresh from '@components/PullToRefresh';
import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationMysteryLikes, {
    NAVIGATION_MYSTERY_LIKES,
} from '@navigation/NavigationBar/NavigationMysteryLikes';

import ActiveOrderFilter from '../../components/ActiveOrderFilter';
import ActiveOrdersListItemHandler from '../../components/ActiveOrdersListItemHandler';
import GamesFeatures from '../../components/GamesFeatures';
import MysteryLikesConfirmPlayModal from '../../components/MysteryLikesConfirmPlayModal';
import MysteryLikesHeader from '../../components/MysteryLikesHeader';
import useMysteryLikesActiveOrderScreen from './hooks/useMysteryLikesActiveOrderScreen';
import stylesMain from './styles';

const MysteryLikesActiveOrderScreen = () => {
    const styles = useThemedStyles(stylesMain);
    const {
        handleRefresh,
        isRefresh,
        handleChooseFilter,
        filter,
        filterLoading,
        orders,
        isSmallLayout,
        handleCreateGame,
        loadMore,
        getOrdersLoadMore,
        isLoading,
        renderPreviousGameModal,
        showModalLoader,
        renderPreviousGameModalOrder,
        onOrderListItemPress,
        confirmPlayModal,
        closeConfirmPlay,
        canLoadMore,
        mysteryLikesGameStats,
    } = useMysteryLikesActiveOrderScreen();

    const headerComponent = useCallback(() => {
        return !!orders && !!orders.length ? (
            <>
                <GamesFeatures
                    onLight
                    activeGamesCount={mysteryLikesGameStats?.activeGamesCount}
                    totalPricePoolIguAmountWei={
                        mysteryLikesGameStats?.totalPricePoolIguAmountWei
                    }
                    customStyle={styles.features}
                />
                <ActiveOrderFilter
                    onSelect={handleChooseFilter}
                    selectedItem={filter}
                />
            </>
        ) : null;
    }, [
        filter,
        handleChooseFilter,
        mysteryLikesGameStats?.activeGamesCount,
        mysteryLikesGameStats?.totalPricePoolIguAmountWei,
        orders,
        styles.features,
    ]);

    const emptyComponent = useCallback(() => {
        return (
            <View
                style={
                    isSmallLayout
                        ? styles.emptyStateWrapperSmall
                        : styles.emptyStateWrapper
                }>
                <EmptyList
                    containerStyle={styles.emptyList}
                    emptyTitle={i18n.t('HomeScreen.VacantRoomsEmpty.title')}
                    emptyText={i18n.t('HomeScreen.VacantRoomsEmpty.text')}
                    emptyButtonText={i18n.t('general.BUTTONS.createNewGame')}
                    disabled={showModalLoader}
                    loading={showModalLoader}
                    withAnimation="cry"
                    onPressButton={handleCreateGame}
                />
            </View>
        );
    }, [
        isSmallLayout,
        styles.emptyStateWrapperSmall,
        styles.emptyStateWrapper,
        styles.emptyList,
        showModalLoader,
        handleCreateGame,
    ]);

    return (
        <View style={styles.main}>
            <MysteryLikesHeader />
            <ModalLoader isVisible={showModalLoader} />
            <NavigationMysteryLikes
                currentNavigation={NAVIGATION_MYSTERY_LIKES.ORDERS}
            />
            <View style={styles.content}>
                {isLoading && !orders ? (
                    <LoadingComponent text={i18n.t('general.loading')} />
                ) : (
                    <PullToRefresh
                        refreshing={isRefresh}
                        textLoading={i18n.t('pullToRefresh.loading')}
                        onRefresh={handleRefresh}
                        HeaderComponent={headerComponent()}
                        EmptyComponent={emptyComponent()}
                        renderData={orders || []}
                        loadMore={loadMore}
                        onReachEnd={getOrdersLoadMore}
                        contentLoading={filterLoading}
                        //@ts-ignore ignore item type any
                        renderItem={({ item }) => (
                            <ActiveOrdersListItemHandler
                                loading={filterLoading}
                                action={onOrderListItemPress}
                                key={item.id}
                                item={item}
                            />
                        )}
                        canLoadMore={canLoadMore}
                        shouldUseEmptyComponentInFooter
                    />
                )}
            </View>
            {renderPreviousGameModal()}
            {renderPreviousGameModalOrder()}
            <MysteryLikesConfirmPlayModal
                isVisible={confirmPlayModal}
                onClose={closeConfirmPlay}
            />
        </View>
    );
};

export default MysteryLikesActiveOrderScreen;
