import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(20),
            marginTop: verticalScale(32),
        },
        button: {
            alignSelf: 'center',
            marginTop: verticalScale(16),
        },
        textStyle: {
            color: Colors[theme].text.secondary,
        },
    });

export default styles;
