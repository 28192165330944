import { useState } from 'react';

import { LevelupInfo, LevelupInfoResponse } from '@Data/Models';
import { GET_PET_NEXT_LEVEL_INFO } from '@Data/Requests';
import { useLazyQuery } from '@apollo/client';
import { errorsHandler } from '@helpers/errors';

export const useLevelUpBoostInfo = () => {
    const [levelUpBoostInfo, setLevelUpBoostInfo] = useState<number>(0);
    const [getLevelUpBoostInfo, { loading: levelUpBoostInfoLoading }] =
        useLazyQuery<LevelupInfoResponse>(GET_PET_NEXT_LEVEL_INFO, {
            fetchPolicy: 'network-only',
            onError: (error) => errorsHandler(error, true),
            onCompleted: (data) =>
                setLevelUpBoostInfo(
                    data.petNextLevelInfo.levelupRewardBoostPercentage || 0
                ),
        });

    return {
        levelUpBoostInfo,
        getLevelUpBoostInfo,
        levelUpBoostInfoLoading,
    };
};
