import React, { useCallback, useEffect, useState } from 'react';

import { useLocalAuthentication } from '@hooks/useLocalAuthentication';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useFocusEffect, useNavigation } from '@react-navigation/native';

import { PassCodeWrapper } from '../../components/PassCodeWrapper';

export const PassCodeNewScreen = () => {
    const [code, setCode] = useState<string>('');
    const navigation = useNavigation();
    const { savePassCodeToStorage } = useLocalAuthentication();
    useEffect(() => {
        if (code.length === 4) {
            savePassCodeToStorage(code);
            navigation.navigate(ROUTES.PASSCODE_CONFIRM, { change: false });
        }
    }, [code]);

    useFocusEffect(
        useCallback(() => {
            setCode('');
        }, [])
    );

    return (
        <PassCodeWrapper
            code={code}
            setCode={setCode}
            title={i18n.t('passcode.new.title')}
            text={i18n.t('passcode.new.subTitle')}
            header={i18n.t('passcode.new.title')}
        />
    );
};
