import * as React from 'react';

import HowToSocializeToEarnScreen from '../../screens/HowTo/HowToSocializeToEarn/HowToSocializeToEarnScreen';
import HowToVerifyUserScreen from '../../screens/HowTo/HowToVerifyUser/HowToVerifyUserScreen';
import CreateSharePhotoScreen from '../../screens/TabBar/Play/SocializeToEarn/CreateSharePhoto/CreateSharePhotoScreen';
import CreateShareTemplateScreen from '../../screens/TabBar/Play/SocializeToEarn/CreateShareTemplate/CreateShareTemplateScreen';
import ShareYourPhotoScreen from '../../screens/TabBar/Play/SocializeToEarn/ShareYourPhoto/ShareYourPhotoScreen';
import ShareYourPhotoSuccessScreen from '../../screens/TabBar/Play/SocializeToEarn/ShareYourPhotoSuccess/ShareYourPhotoSuccessScreen';
import VerifyUserScreen from '../../screens/TabBar/Play/SocializeToEarn/VerifyUser/VerifyUserScreen';
import VerifyUserAlreadyCompletedScreen from '../../screens/TabBar/Play/SocializeToEarn/VerifyUserAlreadyCompleted/VerifyUserAlreadyCompletedScreen';
import VerifyUserErrorScreen from '../../screens/TabBar/Play/SocializeToEarn/VerifyUserError/VerifyUserErrorScreen';
import VerifyUserFailedScreen from '../../screens/TabBar/Play/SocializeToEarn/VerifyUserFailed/VerifyUserFailedScreen';
import VerifyUserMatchScreen from '../../screens/TabBar/Play/SocializeToEarn/VerifyUserMatch/VerifyUserMatchScreen';
import VerifyUserNotFoundScreen from '../../screens/TabBar/Play/SocializeToEarn/VerifyUserNotFound/VerifyUserNotFoundScreen';
import WelcomeShareScreen from '../../screens/TabBar/Play/SocializeToEarn/WelcomeShare/WelcomeShareScreen';
import WelcomeVerifyScreen from '../../screens/TabBar/Play/SocializeToEarn/WelcomeVerify/WelcomeVerifyScreen';
import ROUTES from '../routes';

const SocializeToEarnGroup = (RootStack: any) => {
    return (
        <>
            <RootStack.Screen
                name={ROUTES.SOCIALIZE_TO_EARN_WELCOME_SHARE}
                component={WelcomeShareScreen}
            />
            <RootStack.Screen
                name={ROUTES.SOCIALIZE_TO_EARN_WELCOME_VERIFY}
                component={WelcomeVerifyScreen}
            />
            <RootStack.Screen
                name={ROUTES.SOCIALIZE_TO_EARN_SHARE_YOUR_PHOTO}
                component={ShareYourPhotoScreen}
            />
            <RootStack.Screen
                name={ROUTES.SOCIALIZE_TO_EARN_CREATE_SHARE_PHOTO}
                component={CreateSharePhotoScreen}
            />
            <RootStack.Screen
                name={ROUTES.SOCIALIZE_TO_EARN_SHARE_YOUR_PHOTO_SUCCESS}
                component={ShareYourPhotoSuccessScreen}
            />
            <RootStack.Screen
                name={ROUTES.SOCIALIZE_TO_EARN_VERIFY_USER}
                component={VerifyUserScreen}
            />

            <RootStack.Screen
                name={ROUTES.SOCIALIZE_TO_EARN_VERIFY_USER_ERROR}
                component={VerifyUserErrorScreen}
            />
            <RootStack.Screen
                name={ROUTES.SOCIALIZE_TO_EARN_VERIFY_USER_MATCH}
                component={VerifyUserMatchScreen}
            />
            <RootStack.Screen
                name={ROUTES.SOCIALIZE_TO_EARN_VERIFY_USER_NOT_FOUND}
                component={VerifyUserNotFoundScreen}
            />
            <RootStack.Screen
                name={ROUTES.SOCIALIZE_TO_EARN_VERIFY_USER_FAILED}
                component={VerifyUserFailedScreen}
            />
            <RootStack.Screen
                name={ROUTES.SOCIALIZE_TO_EARN_VERIFY_USER_ALREADY_COMPLETED}
                component={VerifyUserAlreadyCompletedScreen}
            />
            <RootStack.Screen
                name={ROUTES.HOW_TO_VERIFY_USER}
                component={HowToVerifyUserScreen}
                options={{
                    presentation: 'modal',
                }}
            />
            <RootStack.Screen
                name={ROUTES.HOW_TO_SOCIALIZE_TO_EARN}
                component={HowToSocializeToEarnScreen}
                options={{
                    presentation: 'modal',
                }}
            />
            <RootStack.Screen
                name={ROUTES.SOCIALIZE_TO_EARN_CREATE_SHARE_TEMPLATE}
                component={CreateShareTemplateScreen}
            />
        </>
    );
};

export default SocializeToEarnGroup;
