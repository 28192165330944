import React, { useCallback } from 'react';

import { useVisibleHook } from '@hooks/useVisibleHook';

import ModalSecureHashSystem from '../components/ModalSecureHashSystem';

const useSecureHashModal = () => {
    const { isVisible, open: openSecureHashModal, close } = useVisibleHook();

    const renderSecureHashModal = useCallback(() => {
        return <ModalSecureHashSystem isVisible={isVisible} onClose={close} />;
    }, [isVisible]);

    return {
        openSecureHashModal,
        renderSecureHashModal,
    };
};

export default useSecureHashModal;
