/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
    FC,
    PropsWithChildren,
    createContext,
    useEffect,
    useState,
} from 'react';
import { Keyboard } from 'react-native';

import { isIOS } from '../helpers/app';

type KeyboardContextType = {
    isKeyboardVisible: boolean;
    keyboardHeight: number;
};

export const KeyboardContext = createContext<KeyboardContextType>({
    isKeyboardVisible: false,
    keyboardHeight: 0,
});

export const useKeyboard = () => React.useContext(KeyboardContext);

const KeyboardProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const [keyboardHeight, setKeyboardHeight] = useState<number>(0);

    useEffect(() => {
        const keyboardDidShowListener = Keyboard.addListener(
            isIOS ? 'keyboardWillShow' : 'keyboardDidShow',
            (event) => {
                setKeyboardHeight(event.endCoordinates.height);
            }
        );
        const keyboardDidHideListener = Keyboard.addListener(
            isIOS ? 'keyboardWillHide' : 'keyboardDidHide',
            (event) => {
                setKeyboardHeight(0);
            }
        );

        return () => {
            keyboardDidHideListener.remove();
            keyboardDidShowListener.remove();
        };
    }, []);

    return (
        <KeyboardContext.Provider
            value={{
                isKeyboardVisible: Boolean(keyboardHeight),
                keyboardHeight,
            }}>
            {children}
        </KeyboardContext.Provider>
    );
};

export default KeyboardProvider;
