import * as React from 'react';
import { FlatList } from 'react-native';

import SocialIcons from '@components/Icons/SocialIcons';
import ModalBottom from '@components/ModalBottom';
import SelectorItem from '@components/SelectorItem';
import { Text } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    selectedSocial: string;
    socials: string[];
    onPress: (social: string) => void;
    modalHeight?: number | string;
    close: () => void;
}

const SocialSelectModal = ({
    isVisible,
    onPress,
    selectedSocial,
    socials,
    modalHeight = '70%',
    close,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const handleSelect = React.useCallback(
        (social: string) => {
            if (selectedSocial === social) {
                return;
            }
            onPress(social);
            close();
        },
        [selectedSocial]
    );

    return (
        <ModalBottom
            isVisible={isVisible}
            onClose={close}
            modalHeight={modalHeight}
            titleText={i18n.t(
                'profile.adminReverification.socialMediaModal.title'
            )}>
            <FlatList
                showsVerticalScrollIndicator={false}
                style={styles.listStyle}
                data={socials}
                renderItem={({ item }) => (
                    <SelectorItem
                        isSelected={item === selectedSocial}
                        children={
                            <>
                                <SocialIcons slug={item} size={32} />
                                <Text style={styles.text}>
                                    {i18n.t(
                                        `profile.socialMediaSettings.media.socials.${item}`
                                    )}
                                </Text>
                            </>
                        }
                        handleSelect={() => handleSelect(item)}
                    />
                )}
                keyExtractor={(item) => item}
            />
        </ModalBottom>
    );
};

export default SocialSelectModal;
