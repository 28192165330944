import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { MYSTERY_LIKES_COLOR } from '@contexts/MysteryGamesContext';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
            backgroundColor: MYSTERY_LIKES_COLOR,
        },
        header: {
            flexDirection: 'row',
            paddingHorizontal: scale(20),
            marginTop: verticalScale(16),
            marginBottom: verticalScale(20),
        },
        gap: {
            width: 64,
        },
        screenTitle: {
            flex: 1,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
            color: Colors[theme].text.primary,
            textAlign: 'center',
            marginHorizontal: scale(16),
        },
        blockHeader: {
            marginTop: verticalScale(8),
            paddingHorizontal: scale(20),
            marginBottom: verticalScale(16),
        },
        image: {
            borderRadius: moderateScale(12),
        },
        imageTooltipStyle: {
            borderRadius: moderateScale(12),
        },
        imageBlock: {
            left: scale(20),
            alignItems: 'center',
            borderRadius: moderateScale(12),
            marginBottom: verticalScale(4),
        },
        loading: {
            color: Colors[theme].alwaysOneColor.background,
        },
        loadingBlock: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: 10000,
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center',
            backgroundColor: 'transparent',
            borderRadius: moderateScale(12),
        },

        loadingFullScreen: {
            flex: 1,
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
        },
        titleContainerStyle: {
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
        },
        backgroundStyle: {
            backgroundColor: Colors[theme].backgroundPrimaryMinimal,
            alignSelf: 'center',
        },
        textStyle: {
            color: Colors[theme].text.onLight,
        },
        progressColor: {
            color: Colors[theme].alwaysOneColor.background,
        },
        progress: {
            backgroundColor: 'transparent',
            marginHorizontal: scale(16),
            flex: 1,
        },
        progressStyle: {
            backgroundColor: Colors[theme].backgroundPrimaryMinimal,
        },
        buttons: { backgroundColor: 'transparent' },
    });

export default styles;
