import * as React from 'react';
import { Image } from 'react-native';

import Button from '../../../../components/Button';
import Divider from '../../../../components/Divider';
import { ICON_NAMES } from '../../../../components/Icons';
import RankIcon from '../../../../components/Icons/RankIcon';
import { Text, View } from '../../../../components/Themed';
import { MintContext } from '../../../../contexts/MintContext';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import ROUTES from '../../../../navigation/routes';
import { useNavigation } from '../../../../navigation/useNavigation';
import MintMysteryBox from '../MintMysteryBox';
import MintPriceBackground from '../MintPriceBackground';
import MintWhiteList from '../MintWhiteList';
import stylesMain from './styles';

interface IProps {
    buttonsEdit?: boolean;
    onEditRankPress?: () => void;
    isLootBox?: boolean;
}

const TransactionDetailsMint = ({
    onEditRankPress,
    buttonsEdit = true,
    isLootBox = false,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { rank, savedPhoto, name, AIImage } = React.useContext(MintContext);

    const navigation = useNavigation();

    const handleEdit = () => {
        if (AIImage) {
            navigation.navigate(ROUTES.MINT_SELECT_GENERATED_NFT);
            return;
        }
        navigation.navigate(ROUTES.MINT_PET_EDIT_PHOTO);
    };
    return (
        <>
            {!!rank && (
                <View style={styles.verificationItem}>
                    <View style={styles.left}>
                        <View>
                            <RankIcon size={64} slug={rank.slug} />
                        </View>
                        <View style={styles.verificationTextContainer}>
                            <Text style={styles.verificationTitle}>
                                {i18n.t('mintCheckout.rank')}
                            </Text>
                            <Text style={styles.verificationSlug}>
                                {rank.slug}
                            </Text>
                        </View>
                    </View>
                    <View style={styles.right}>
                        {!!rank.whitelistFreeMintsCount ? (
                            <MintWhiteList
                                amount={1}
                                customStyle={styles.additionMargin}
                            />
                        ) : !!rank.mintPriceUsd ? (
                            isLootBox ? (
                                <MintMysteryBox
                                    customStyle={styles.additionMargin}
                                />
                            ) : (
                                <MintPriceBackground rank={rank} />
                            )
                        ) : null}

                        {buttonsEdit && (
                            <View>
                                <Button
                                    type="outline"
                                    iconName={ICON_NAMES.PENCIL}
                                    size={'md'}
                                    onPress={onEditRankPress!}
                                />
                            </View>
                        )}
                    </View>
                </View>
            )}
            <Divider customStyles={styles.divider} />
            <View style={styles.verificationItem}>
                <View style={styles.left}>
                    <View>
                        <Image
                            style={styles.image}
                            source={{
                                uri: AIImage?.url
                                    ? AIImage?.url
                                    : savedPhoto?.url,
                            }}
                            resizeMode={'contain'}
                        />
                    </View>
                    <View style={styles.verificationTextContainer}>
                        <Text style={styles.verificationTitle}>
                            {i18n.t('mintCheckout.nftCustomization')}
                        </Text>
                        <Text
                            style={styles.verificationSlug}
                            ellipsizeMode="tail"
                            numberOfLines={1}>
                            {name}
                        </Text>
                    </View>
                </View>
                {buttonsEdit && (
                    <Button
                        type="outline"
                        iconName={ICON_NAMES.PENCIL}
                        size={'md'}
                        onPress={handleEdit}
                    />
                )}
            </View>
        </>
    );
};
export default TransactionDetailsMint;
