import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import CoinIcon from '@components/Icons/CoinIcon';
import OtherUserAvatar from '@components/OtherUserAvatar';
import { Text, View } from '@components/Themed';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { scale } from '@helpers/dimensions';
import { dateFromNow } from '@helpers/time';
import { balanceFromWei, truncateEthAddress } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import { useTouchableOpacityDebounce } from '@hooks/useTouchableOpacityDebounce';
import { MarketplaceTransactionOutput } from '@models/marketplace';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import { toStringUSD } from '../../helpers/helper';
import stylesMain from './styles';

interface IProps {
    transaction: MarketplaceTransactionOutput;
}

const MarketPlacePurchaseHistoryItem = ({ transaction }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const { priceIguAmountWei, soldUsdPrice, boughtAt, buyerAddress, buyer } =
        transaction;
    const dateText = React.useMemo(() => dateFromNow(boughtAt), [boughtAt]);

    const onPress = React.useCallback(() => {
        navigation.push(ROUTES.MARKETPLACE_PROFILE, {
            walletAddress: buyerAddress,
        });
    }, [buyerAddress]);

    const { handleDebounce } = useTouchableOpacityDebounce(onPress);

    return (
        <TouchableOpacity
            style={styles.container}
            activeOpacity={0.7}
            onPress={handleDebounce}>
            <View style={styles.left}>
                <View style={styles.imageWrapper}>
                    <OtherUserAvatar
                        size={scale(40)}
                        imageUrl={buyer?.avatarThumbnailUrl}
                        userId={buyerAddress}
                    />
                </View>
                <View style={styles.addressBlock}>
                    <Text
                        style={styles.address}
                        numberOfLines={1}
                        ellipsizeMode="middle">
                        {truncateEthAddress(
                            buyer.username || buyer.walletAddress
                        )}
                    </Text>
                    <Text style={styles.dateText}>{dateText}</Text>
                </View>
            </View>
            <View style={styles.right}>
                <View style={styles.amountBlock}>
                    <Text style={styles.amount} numberOfLines={1}>
                        {balanceFromWei(priceIguAmountWei).valueLong}
                    </Text>
                    <CoinIcon slug={Coin.igu} size={22} />
                </View>

                <Text style={styles.usd} numberOfLines={1}>
                    {toStringUSD(soldUsdPrice)}
                </Text>
            </View>
        </TouchableOpacity>
    );
};
export default MarketPlacePurchaseHistoryItem;
