import * as React from 'react';
import { FlatList, KeyboardAvoidingView } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import ImageExtended from '@components/ImageExtended';
import MintGeneratedImage from '@components/MintGeneratedImage';
import ModalLoader from '@components/ModalLoader';
import PetName from '@components/PetName';
import { View } from '@components/Themed';
import { isIOS } from '@helpers/app';
import { moderateScale, scale } from '@helpers/dimensions';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';

import NftEvolutionConfirmationMintModal from '../../components/NftEvolutionConfirmationMintModal';
import useNftEvolutionMintPetScreen from './hooks/useNftEvolutionMintPetScreen';
import stylesMain from './styles';

const NftEvolutionMintPetScreen = () => {
    const styles = useThemedStyles(stylesMain);

    const {
        isLoading,
        breedingPetDraftDetails,
        isNew,
        windowWidth,
        name,
        setName,
        handleNameError,
        isNameError,
        imageId,
        handleOnSelectedImage,
        cantOpenConfirmationModal,
        openConfirmationModal,
        confirmationModal,
        confirmMint,
        closeConfirmationModal,
        renderFeeInfoIcon,
        isBNBEnough,
        estimatedFee,
    } = useNftEvolutionMintPetScreen();

    return (
        <SafeAreaView style={styles.safe} edges={['bottom']}>
            <ModalLoader isVisible={isLoading} />
            <KeyboardAvoidingView
                behavior={isIOS ? 'padding' : 'height'}
                style={styles.container}>
                <NavigationBar backIcon="leftArrow" wallet />
                {!!breedingPetDraftDetails && (
                    <View style={styles.flatListWrapper}>
                        <FlatList
                            ListHeaderComponent={
                                <>
                                    <BlockHeader
                                        containerStyle={styles.blockHeader}
                                        title={i18n.t(
                                            'HomeScreen.NftEvolution.NftEvolutionMintPet.title'
                                        )}
                                        type="primary"
                                        text={i18n.t(
                                            'HomeScreen.NftEvolution.NftEvolutionMintPet.text'
                                        )}
                                    />
                                    <View style={styles.petNameWrapper}>
                                        <PetName
                                            disabled={!isNew}
                                            setError={handleNameError}
                                            isNameError={isNameError}
                                            name={name}
                                            setName={setName}
                                        />
                                    </View>
                                    {breedingPetDraftDetails.imageUrl && (
                                        <ImageExtended
                                            borderRadius={moderateScale(12)}
                                            containerStyles={[
                                                styles.imageContainer,
                                                {
                                                    width:
                                                        windowWidth -
                                                        scale(20) * 2,
                                                },
                                            ]}
                                            uri={
                                                breedingPetDraftDetails.imageUrl
                                            }
                                        />
                                    )}
                                </>
                            }
                            numColumns={2}
                            columnWrapperStyle={styles.columnWrapperStyle}
                            showsVerticalScrollIndicator={false}
                            data={breedingPetDraftDetails.selectableImages}
                            renderItem={({ item }) => (
                                <MintGeneratedImage
                                    image={item.url}
                                    id={item.id}
                                    selectedImage={imageId}
                                    onSelectImage={handleOnSelectedImage}
                                />
                            )}
                        />
                    </View>
                )}
                <ButtonsBottom
                    disabled={cantOpenConfirmationModal}
                    onPress={openConfirmationModal}
                    title={i18n.t('general.BUTTONS.mintNft')}
                />
            </KeyboardAvoidingView>
            <NftEvolutionConfirmationMintModal
                isVisible={confirmationModal}
                onClose={closeConfirmationModal}
                onConfirm={confirmMint}
                infoIcon={renderFeeInfoIcon}
                isBNBEnough={isBNBEnough}
                estimatedFee={estimatedFee}
            />
        </SafeAreaView>
    );
};
export default NftEvolutionMintPetScreen;
