import * as React from 'react';

import { useMutation } from '@apollo/client';

import {
    CreateLevelupPaymentResponse,
    PaymentOptionOutput,
    Pet,
} from '../../../../Data/Models';
import { CREATE_LEVELUP_PAYMENT } from '../../../../Data/Requests';
import CheckoutScreen from '../../../../components/CheckoutScreen';
import { PlayContext } from '../../../../contexts/PlayContext';
import { useWallet } from '../../../../contexts/Wallet/WalletContext';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import NavigationBar from '../../../../navigation/NavigationBar';
import ROUTES from '../../../../navigation/routes';
import { CollectionPetLevelUpCheckoutProps } from '../../../../types';
import TransactionDetailsLevelUp from '../components/TransactionDetailsLevelUp';
import stylesMain from './styles';

const CollectionPetLevelUpCheckoutScreen = ({
    route,
    navigation,
}: CollectionPetLevelUpCheckoutProps) => {
    const styles = useThemedStyles(stylesMain);
    const { executeTransaction } = useWallet();
    const { refetchPets, pets } = React.useContext(PlayContext);
    const { pet, payments, navigateBackOnUpgrade } = route.params;
    const [createPaymentRequest, { loading }] =
        useMutation<CreateLevelupPaymentResponse>(CREATE_LEVELUP_PAYMENT);

    const createPayment = async (pet: Pet, payment: PaymentOptionOutput) => {
        const result = await createPaymentRequest({
            variables: {
                petId: pet.id,
                token: payment.token,
            },
        });
        return result.data?.createLevelUpPayment;
    };

    const handleOnPurchase = async (payment: PaymentOptionOutput) => {
        const paymentResponse = await createPayment(pet, payment);
        if (!paymentResponse) {
            return null;
        }
        const result = await executeTransaction(
            paymentResponse.payment,
            paymentResponse.signedTransaction
        );

        await refetchPets(true);

        navigation.navigate(ROUTES.COLLECTION_PET_LEVEL_UP_SUCCESS, {
            pet: pet,
            transactionResult: result,
            payment: paymentResponse.payment,
            navigateBackOnUpgrade: navigateBackOnUpgrade,
        });
        return result;
    };

    return (
        <>
            <NavigationBar backIcon wallet filled={true} />
            <CheckoutScreen
                payments={payments}
                TransactionDetailsComponent={
                    <TransactionDetailsLevelUp pet={pet} />
                }
                withConfirmation={i18n.t('petLevelUp.checkout.transaction', {
                    name: pet.name,
                })}
                onPurchase={handleOnPurchase}
            />
        </>
    );
};

export default CollectionPetLevelUpCheckoutScreen;
