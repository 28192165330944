import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';
import { stylesModalNavigationFix } from '@helpers/style';

import Colors from '../../../constants/Colors';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
            paddingBottom: verticalScale(16),
            ...stylesModalNavigationFix(),
        },
        wrapper: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            alignSelf: 'center',
        },
        itemContainer: {
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            paddingHorizontal: scale(20),
            backgroundColor: 'transparent',
        },
        imgWrapper: {
            alignSelf: 'center',

            marginBottom: verticalScale(24),
        },
        imgWrapperWithInfo: {
            alignSelf: 'center',
            marginBottom: verticalScale(12),
        },
        header: {
            paddingHorizontal: scale(16),
        },
        buttonWrapper: {
            marginTop: verticalScale(24),
            paddingHorizontal: scale(20),
        },
        statusWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: scale(8),
            paddingVertical: scale(6),
            borderRadius: 50,
            alignSelf: 'center',
            marginTop: verticalScale(24),
        },
        statusText: {
            color: Colors[theme].statutes.confirmed.text,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
            marginLeft: scale(4),
        },
        FAILED: {
            backgroundColor: Colors[theme].statutes.failed.background,
        },
        CONFIRMED: {
            backgroundColor: Colors[theme].statutes.confirmed.background,
        },
        FAILEDtext: {
            color: Colors[theme].statutes.failed.text,
        },
        CONFIRMEDtext: {
            color: Colors[theme].statutes.confirmed.text,
        },
    });

export default styles;
