import * as React from 'react';
import { TextStyle, TouchableOpacity, ViewStyle } from 'react-native';

import { ICON_NAMES } from '@components/Icons';
import { useTheme } from '@contexts/ThemeContext';

import ErrorIcon from '../../assets/icons/toastCard/error.svg';
import RedWarningIcon from '../../assets/icons/toastCard/red-warning.svg';
import SuccessIcon from '../../assets/icons/toastCard/success.svg';
import YellowClockIcon from '../../assets/icons/toastCard/yellow-clock.svg';
import YellowWarningIcon from '../../assets/icons/toastCard/yellow-warning.svg';
import Colors from '../../constants/Colors';
import useThemedStyles from '../../hooks/useThemedStyles';
import { ComponentAnyType } from '../../types';
import Button, { ButtonType } from '../Button';
import InfoIcon from '../InfoIcon/InfoIcon';
import { Text, View } from '../Themed';
import stylesMain from './styles';

export type AlertCardType =
    | 'error'
    | 'exclamation'
    | 'warning'
    | 'time'
    | 'success';

interface IProps {
    type?: AlertCardType;
    text?: string | ComponentAnyType;
    containerStyle?: ViewStyle | undefined;
    withIcon?: boolean;
    iconSize?: number;
    children?: React.ReactNode;
    onPress?: () => void;
    textStyles?: TextStyle | undefined;
    actionButtonIcon?: ICON_NAMES;
    onAction?: () => void;
    actionButtonType?: ButtonType;
    actionButtonIconSize?: number;
    actionButtonIconColor?: string;
}

const AlertCard: React.FunctionComponent<IProps> = ({
    type = 'warning',
    text,
    containerStyle,
    withIcon = true,
    iconSize = 20,
    children,
    textStyles,
    onPress,
    actionButtonIcon,
    onAction,
    actionButtonType = 'outline',
    actionButtonIconSize,
    actionButtonIconColor,
}) => {
    const { theme } = useTheme();

    const toastTypes = {
        error: {
            textColor: Colors[theme].text.critical,
            backgroundColor: Colors[theme].backgroundCritical,
            icon: <ErrorIcon width={iconSize} height={iconSize} />,
        },
        exclamation: {
            textColor: Colors[theme].text.critical,
            backgroundColor: Colors[theme].backgroundCritical,
            icon: <RedWarningIcon width={iconSize} height={iconSize} />,
        },
        warning: {
            textColor: Colors[theme].text.warning,
            backgroundColor: Colors[theme].backgroundWarning,
            icon: <YellowWarningIcon width={iconSize} height={iconSize} />,
        },
        time: {
            textColor: Colors[theme].text.warning,
            backgroundColor: Colors[theme].backgroundWarning,
            icon: <YellowClockIcon width={iconSize} height={iconSize} />,
        },
        success: {
            textColor: Colors[theme].text.accent,
            backgroundColor: Colors[theme].backgroundSuccess,
            icon: <SuccessIcon width={iconSize} height={iconSize} />,
        },
    };

    const styles = useThemedStyles(stylesMain);
    return (
        <TouchableOpacity
            activeOpacity={onPress ? 0.7 : 1}
            onPress={onPress}
            style={[
                styles.warningContainer,
                containerStyle,
                {
                    backgroundColor: toastTypes[type].backgroundColor,
                    cursor: onPress ? 'pointer' : 'auto',
                },
            ]}>
            <View style={styles.warningChildren}>
                {withIcon && toastTypes[type].icon}
                {children}
            </View>
            <View style={styles.textContainer}>
                {typeof text === 'string' && (
                    <Text
                        style={[
                            styles.text,
                            { color: toastTypes[type].textColor },
                            textStyles,
                        ]}>
                        {text}
                    </Text>
                )}
            </View>
            {!!onPress && (
                <View style={styles.infoIcon}>
                    <InfoIcon
                        disabled
                        iconColor={toastTypes[type].textColor}
                        iconSize={iconSize}
                    />
                </View>
            )}
            {actionButtonIcon && (
                <Button
                    debouncedPress
                    onPress={onAction}
                    iconName={actionButtonIcon}
                    size="md"
                    containerStyle={styles.button}
                    type={actionButtonType}
                    iconSize={actionButtonIconSize}
                    iconColor={actionButtonIconColor}
                />
            )}
        </TouchableOpacity>
    );
};

export default AlertCard;
