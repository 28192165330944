import * as React from 'react';

import { toastErrorUnknown } from '@helpers/toastNotification';
import { useNavigation } from '@navigation/useNavigation';

import { TokenTellerGameHistoryProps } from '../../../../../types';
import TokenTellerHistoryWrapper from '../../components/TokenTellerHistoryWrapper';

const TokenTellerGameHistoryScreen = ({
    route,
}: TokenTellerGameHistoryProps) => {
    const { game, force, id } = route.params;
    const navigation = useNavigation();
    navigation.blockHardwareBack();

    React.useEffect(() => {
        if (!game) {
            navigation.pop();
            toastErrorUnknown();
        }
    }, [game]);

    return <TokenTellerHistoryWrapper history={game} force={force} id={id} />;
};

export default TokenTellerGameHistoryScreen;
