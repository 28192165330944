import { useCallback } from 'react';

import {
    CreateMoveTaskResponse,
    MarkMoveTaskDoneResponse,
    MoveTasksResponse,
} from '@Data/Models';
import {
    CREATE_MOVE_TASK,
    GET_MOVE_TASK,
    MARK_MOVE_TASK_DONE,
} from '@Data/Requests';
import { useLazyQuery, useMutation } from '@apollo/client';
import { usePlay } from '@contexts/PlayContext';
import { errorsHandler } from '@helpers/errors';
import { moveTaskCanStart, playTaskInSandBox } from '@helpers/playTask';

interface CreateMoveTask {
    onError?: () => void;
    onCompleted?: () => void;
}

const useMoveTask = () => {
    const { updateMoveTask, setMoveTask } = usePlay();
    const [
        getMoveTask,
        {
            loading: moveTaskLoading,
            error: moveTaskError,
            refetch: refetchMoveTask,
        },
    ] = useLazyQuery<MoveTasksResponse>(GET_MOVE_TASK, {
        fetchPolicy: 'network-only',
        onError: (error) => errorsHandler(error),
    });

    const [createMoveTaskMutation, { loading: loadingCreateMove }] =
        useMutation<CreateMoveTaskResponse>(CREATE_MOVE_TASK);

    const [markMoveTaskDone] =
        useMutation<MarkMoveTaskDoneResponse>(MARK_MOVE_TASK_DONE);

    const createMoveTask = useCallback(
        async (arg: CreateMoveTask) => {
            try {
                const result = await getMoveTask();
                if (!result.data?.moveTaskConfig) {
                    arg?.onError?.();
                    return;
                }

                setMoveTask(result.data.moveTaskConfig);

                if (moveTaskCanStart(result.data?.moveTaskConfig)) {
                    createMoveTaskMutation({
                        onCompleted: (data) => {
                            updateMoveTask(data.createMoveTask);
                            arg?.onCompleted?.();
                        },
                        onError: (error) => {
                            taskWrongState(error);
                            arg?.onError?.();
                        },
                    });
                } else {
                    arg?.onCompleted?.();
                }
            } catch (e) {
                arg?.onError?.();
                taskWrongState(e);
            }
        },
        [createMoveTaskMutation, getMoveTask, setMoveTask, updateMoveTask]
    );

    return {
        createMoveTask,
        getMoveTask,
        moveTaskLoading,
        moveTaskError,
        refetchMoveTask,
        createMoveTaskMutation,
        markMoveTaskDone,
        loadingCreateMove,
    };
};

export default useMoveTask;
function taskWrongState(e: unknown) {
    throw new Error('Function not implemented.');
}
