import * as React from 'react';

import ComingSoonImage from '../../../../../assets/icons/mysteryBoxes/comingSoon.svg';
import BlockHeader from '../../../../../components/BlockHeader/BlockHeader';
import { Text, View } from '../../../../../components/Themed';
import TimerWithCaptions from '../../../../../components/TimerWithCaptions';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import stylesMain from './styles';

interface IProps {
    endOfTime: string;
    onEnd: () => void;
}

const MysteryBoxComingSoon = ({ endOfTime, onEnd }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={styles.container}>
            <View style={styles.image}>
                <ComingSoonImage />
            </View>
            <View>
                <BlockHeader
                    title={i18n.t('mysteryBoxes.comingSoon.title')}
                    text={i18n.t('mysteryBoxes.comingSoon.text')}
                    type="emptyState"
                />
            </View>
            <View style={styles.timer}>
                <Text style={styles.timerText}>
                    {i18n.t('mysteryBoxes.comingSoon.timer')}
                </Text>
                <TimerWithCaptions endOfTime={endOfTime} onEnd={onEnd} />
            </View>
        </View>
    );
};

export default MysteryBoxComingSoon;
