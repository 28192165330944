import * as React from 'react';
import { SafeAreaView } from 'react-native';

import StepFourImage from '../../../assets/icons/howTo/howToPlay/stepFour.svg';
import StepOneImage from '../../../assets/icons/howTo/howToPlay/stepOne.svg';
import StepThreeImage from '../../../assets/icons/howTo/howToPlay/stepThree.svg';
import StepTwoImage from '../../../assets/icons/howTo/howToPlay/stepTwo.svg';
import BlockHeader from '../../../components/BlockHeader/BlockHeader';
import Button from '../../../components/Button';
import Carousel from '../../../components/Carousel';
import { ICON_NAMES } from '../../../components/Icons';
import { View } from '../../../components/Themed';
import { useDimensions } from '../../../contexts/DimensionsContext';
import { waitForNavigation } from '../../../helpers/helpers';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import NavigationBarModal from '../../../navigation/NavigationBar/NavigationBarModal';
import { useNavigation } from '../../../navigation/useNavigation';
import { HowToPlayProps } from '../../../types';
import stylesMain from '../styles/styles';

const data = ['stepOne', 'stepTwo', 'stepThree', 'stepFour'];

const HowToPlayScreen = ({ route }: HowToPlayProps) => {
    const { windowWidth } = useDimensions();
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const { canStart } = route.params;

    const goPlay = () => {
        navigation.pop();
        waitForNavigation().then(() => navigation.navigateToTasks());
    };

    const renderItem = (item: string) => {
        const icon = () => {
            switch (item) {
                case 'stepOne':
                    return <StepOneImage />;
                case 'stepTwo':
                    return <StepTwoImage />;
                case 'stepThree':
                    return <StepThreeImage />;

                default:
                    return <StepFourImage />;
            }
        };

        return (
            <View style={styles.itemContainer}>
                <View style={styles.content}>
                    <View style={styles.imgWrapper}>{icon()}</View>
                    <BlockHeader
                        title={i18n.t(`howTo.play.${item}.title`)}
                        text={i18n.t(`howTo.play.${item}.text`)}
                        type="emptyState"
                    />
                </View>
            </View>
        );
    };

    return (
        <View style={styles.main}>
            <View style={styles.header}>
                <NavigationBarModal
                    iconName={ICON_NAMES.CLOSE}
                    title={''}
                    onBack={() => navigation.pop()}
                />
            </View>
            <Carousel
                data={data}
                sliderWidth={windowWidth}
                itemWidth={windowWidth}
                renderItem={(item) => renderItem(item)}
                fullHeight
                pagination
                centredVertical
            />
            <View style={styles.buttonWrapper}>
                <Button
                    title={i18n.t('howTo.play.button')}
                    onPress={goPlay}
                    disabled={!canStart}
                />
            </View>
            <SafeAreaView />
        </View>
    );
};

export default HowToPlayScreen;
