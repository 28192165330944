import * as React from 'react';

import Discord from '../../../assets/icons/social/discord.svg';
import Facebook from '../../../assets/icons/social/facebook.svg';
import Instagram from '../../../assets/icons/social/instagram.svg';
import Telegram from '../../../assets/icons/social/telegram.svg';
import TikTok from '../../../assets/icons/social/tikTok.svg';
import Twitter from '../../../assets/icons/social/twitter.svg';
import Website from '../../../assets/icons/social/website.svg';
import Youtube from '../../../assets/icons/social/youtube.svg';
import { IconType, iconSize } from '../types';

interface IProps extends IconType {
    slug: string;
}

const SocialIcons = ({ slug, size = iconSize.primary }: IProps) => {
    switch (slug) {
        case 'twitter':
            return <Twitter width={size} height={size} />;
        case 'tiktok':
            return <TikTok width={size} height={size} />;
        case 'instagram':
            return <Instagram width={size} height={size} />;
        case 'discord':
            return <Discord width={size} height={size} />;
        case 'telegram':
            return <Telegram width={size} height={size} />;
        case 'youtube':
            return <Youtube width={size} height={size} />;
        case 'website':
            return <Website width={size} height={size} />;
        case 'facebook':
            return <Facebook width={size} height={size} />;
        default:
            Console.error(`No coin icon with slug ${slug} is available`);
            return null;
    }
};

export default SocialIcons;
