import * as React from 'react';
import { SvgProps } from 'react-native-svg';

import AnimatedTextLoading from '@components/AnimatedTextLoading';
import Animation from '@components/Animation';
import { LottieAssets } from '@contexts/AppLoading/types';
import { useDimensions } from '@contexts/DimensionsContext';
import useThemedStyles from '@hooks/useThemedStyles';

import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    isLoading: boolean;
    staticTitle?: string;
    loadingTitle: string;
    text: string;
    backgroundColor?: string;
    emptyStateIcon?: React.ReactElement<SvgProps>;
    animationName?: keyof LottieAssets;
}

const EmptyStateTextAnimated = ({
    isLoading,
    staticTitle,
    loadingTitle,
    text,
    backgroundColor,
    emptyStateIcon,
    animationName,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { windowWidth } = useDimensions();

    return (
        <View style={styles.container}>
            <View style={styles.animationWrapper}>
                {animationName && (
                    <Animation
                        animation={animationName}
                        style={{ width: windowWidth - 80 }}
                    />
                )}
                {!!emptyStateIcon && emptyStateIcon}
            </View>
            <AnimatedTextLoading
                staticText={staticTitle}
                isLoading={isLoading}
                loadingText={loadingTitle}
                backgroundColor={backgroundColor}
                textStyle={styles.title}
            />
            <Text style={styles.text}>{text}</Text>
        </View>
    );
};

export default EmptyStateTextAnimated;
