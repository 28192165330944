import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import OtherUserAvatar from '@components/OtherUserAvatar';
import { Text, View } from '@components/Themed';
import { scale } from '@helpers/dimensions';
import { dateFromNow } from '@helpers/time';
import { balanceFromWei, truncateEthAddress } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import { PaginatedMysteryLikesAvailableGamesItem } from '@models/mysteryGames';

import GameAmount from '../GameAmount';
import stylesMain from './styles';

interface IProps {
    item: PaginatedMysteryLikesAvailableGamesItem;
    onPress: (item: PaginatedMysteryLikesAvailableGamesItem) => void;
}

const ActiveOrderItem = ({ item, onPress }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <TouchableOpacity
            style={styles.shadow}
            activeOpacity={0.7}
            onPress={() => onPress(item)}>
            <View style={[styles.itemContainer]}>
                <View style={styles.left}>
                    <View style={styles.imageWrapper}>
                        <OtherUserAvatar
                            size={scale(48)}
                            imageUrl={item.creator.mainPet?.image.thumbnailUrl}
                            userId={item.creator.walletAddress}
                        />
                    </View>
                    <View style={styles.infoBlock}>
                        <Text
                            style={styles.address}
                            ellipsizeMode={'middle'}
                            numberOfLines={1}>
                            {truncateEthAddress(
                                item.creator.username ||
                                    item.creator.walletAddress
                            )}
                        </Text>
                        <Text
                            style={styles.date}
                            ellipsizeMode={'middle'}
                            numberOfLines={1}>
                            {dateFromNow(item.createdAt)}
                        </Text>
                    </View>
                </View>
                <View style={styles.right}>
                    <GameAmount
                        amount={balanceFromWei(item.iguAmountWei).valueLong}
                    />
                </View>
            </View>
        </TouchableOpacity>
    );
};
export default ActiveOrderItem;
