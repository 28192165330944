import * as React from 'react';
import { useCallback } from 'react';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import EmptyList from '@components/EmptyList';
import { ICON_NAMES } from '@components/Icons';
import LoadingComponent from '@components/LoadingComponent';
import PullToRefresh from '@components/PullToRefresh';
import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';
import NavigationQuantumSpin, {
    NAVIGATION_QUANTUM_SPIN,
} from '@navigation/NavigationBar/NavigationQuantumSpin';

import GamesNavigation from '../../components/GamesNavigation';
import QuantumSpinHistoryItem from '../../components/QuantumSpinHistoryItem';
import useQuantumSpinHistoryScreen from './hooks/useQuantumSpinHistoryScreen';
import stylesMain from './styles';

const game = MYSTERY_GAMES.quantumSpin;
const flow = 'QuantumSpinHistory';

const QuantumSpinHistoryScreen = () => {
    const styles = useThemedStyles(stylesMain);
    const {
        handleRefresh,
        isRefresh,
        isSmallLayout,
        gamesHistory,
        getGamesHistoryLoadMore,
        canLoadMore,
        loadMore,
        historyLoading,
        onHowItWorksPress,
        onHistoryItemPress,
    } = useQuantumSpinHistoryScreen();

    const headerComponent = () => {
        return (
            <BlockHeader
                containerStyle={styles.header}
                type="primary"
                title={i18n.t('HomeScreen.GameHistoryScreen.title')}
                text={i18n.t('HomeScreen.GameHistoryScreen.text')}
                iconDirection="right"
            />
        );
    };
    const emptyComponent = useCallback(() => {
        return (
            <View
                style={
                    isSmallLayout
                        ? styles.emptyStateWrapperSmall
                        : styles.emptyStateWrapper
                }>
                <EmptyList
                    containerStyle={styles.emptyList}
                    emptyTitle={i18n.t(`HomeScreen.${game}.${flow}.title`)}
                    emptyText={i18n.t(`HomeScreen.${game}.${flow}.text`)}
                    withAnimation="cry"
                />
            </View>
        );
    }, [isSmallLayout]);

    return (
        <View style={styles.main}>
            <GamesNavigation
                backIconName={ICON_NAMES.CLOSE}
                type={MYSTERY_GAMES.quantumSpin}
                isOpacityBg
                handleOnInfoIconPress={onHowItWorksPress}
                buttonComponent={
                    <NavigationQuantumSpin
                        currentNavigation={NAVIGATION_QUANTUM_SPIN.HISTORY}
                    />
                }
            />

            <View style={styles.content}>
                {historyLoading ? (
                    <LoadingComponent text={i18n.t('general.loading')} />
                ) : (
                    <PullToRefresh
                        refreshing={isRefresh}
                        textLoading={i18n.t('pullToRefresh.loading')}
                        onRefresh={handleRefresh}
                        HeaderComponent={headerComponent()}
                        EmptyComponent={emptyComponent()}
                        renderData={gamesHistory || []}
                        //@ts-ignore ignore item type any
                        renderItem={({ item }) => (
                            <QuantumSpinHistoryItem
                                key={item.id}
                                item={item}
                                disabled={isRefresh}
                                onPress={onHistoryItemPress}
                            />
                        )}
                        shouldUseEmptyComponentInFooter
                        loadMore={loadMore}
                        onReachEnd={getGamesHistoryLoadMore}
                        canLoadMore={canLoadMore}
                    />
                )}
            </View>
        </View>
    );
};

export default QuantumSpinHistoryScreen;
