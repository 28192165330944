import * as React from 'react';

import { ICON_NAMES } from '@components/Icons';
import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';
import NavigationBarModal from '@navigation/NavigationBar/NavigationBarModal';
import useNavigationListeners from '@navigation/hooks/useNavigationListeners';
import { useNavigation } from '@navigation/useNavigation';

import { TokenTellerAllParticipantsProps } from '../../../../../types';
import TokenTellerAllParticipantsComponent from '../../components/TokenTellerAllParticipantsComponent';
import stylesMain from './styles';

const TokenTellerAllParticipantsScreen = ({
    route,
}: TokenTellerAllParticipantsProps) => {
    useNavigationListeners();

    const { id: gameId } = route.params;

    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const handleBack = () => {
        navigation.pop();
    };

    return (
        <View style={styles.main}>
            <View style={styles.header}>
                <NavigationBarModal
                    isOneColor
                    iconName={ICON_NAMES.CLOSE}
                    title={i18n.t(
                        `HomeScreen.${MYSTERY_GAMES.luckyStrike}.keys.participants`
                    )}
                    onBack={handleBack}
                    titleStyle={styles.title}
                />
            </View>

            <TokenTellerAllParticipantsComponent gameId={gameId} />
        </View>
    );
};

export default TokenTellerAllParticipantsScreen;
