/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AppState } from 'react-native';

import { ButtonType } from '@components/Button';
import { useNotification } from '@contexts/Notification/NotificationContext';
import { usePlay } from '@contexts/PlayContext';
import { isWeb } from '@helpers/app';
import useMoveTask from '@hooks/tasks/useMoveTask';
import useTasksErrorsHandler from '@hooks/tasks/useTasksErrorsHandler';
import { useVisibleHook } from '@hooks/useVisibleHook';
import { useNavigation } from '@navigation/useNavigation';

import { MODAL_TYPES } from '../constants';
import { useDetectShaking } from '../hooks/useDetectShaking';
import { useLocationProblem } from '../hooks/useLocationProblem';
import { useProgress } from '../hooks/useProgress';

export const useProgressScreen = (permissions: { pedometer: boolean }) => {
    const { pedometer } = permissions;

    // @contexts
    const { cancelMoveToEarnFinished } = useNotification();
    const { updateMoveTask, setMoveTask } = usePlay();
    const { moveTask } = usePlay();

    // @hooks
    const { taskWrongState } = useTasksErrorsHandler();
    const {
        msg,
        speed,
        isPaused,
        walkStatus,
        stepsWalked,
        setIsPaused,
        energyEarned,
        speedHistory,
        isSubscribed,
        shakeDetected,
        distanceTraveled,
        distanceTraveledToShow,
        finishTask,
        setRemainingTime,
        remainingTime,
        handleSubscribe,
        handleUnsubscribe,
    } = useProgress(pedometer);

    const navigation = useNavigation();
    navigation.blockHardwareBack();

    useDetectShaking(shakeDetected);
    const { isLocationProblem, problemWithLocationRenderModal } =
        useLocationProblem();
    const {
        isVisible: isVisibleModal,
        close: closeModal,
        open: openModal,
    } = useVisibleHook(true);
    const { moveTaskLoading, createMoveTask, loadingCreateMove } =
        useMoveTask();

    const [modalType, setModalType] = useState<keyof typeof MODAL_TYPES>(
        MODAL_TYPES.prepare
    );

    const [isPrepareTimerVisible, setIsPrepareTimerVisible] =
        useState<boolean>(false);

    // Pause move task on web when close the app
    useEffect(() => {
        if (isWeb) {
            const subscription = AppState.addEventListener(
                'change',
                (currentState) => {
                    if (currentState === 'background') {
                        setIsPaused(true);
                        return;
                    }
                    if (currentState === 'active') {
                        setIsPaused(false);
                    }
                }
            );
            return () => {
                subscription.remove();
            };
        }
    }, []);

    // If Detect location problem stop location reading
    useEffect(() => {
        if (isLocationProblem) {
            setIsPaused(true);
        }
    }, [isLocationProblem]);

    const modalFirstButtonType: ButtonType | undefined = useMemo(() => {
        if (
            modalType === MODAL_TYPES.leave ||
            modalType === MODAL_TYPES.leaveWithOutFinish
        ) {
            return 'critical';
        }
    }, [modalType]);

    const handleModalClose = useCallback(() => {
        setIsPaused(true);
        closeModal();
        cancelMoveToEarnFinished();
    }, [cancelMoveToEarnFinished, closeModal, setIsPaused]);

    const handleOnLeave = useCallback(() => {
        handleModalClose();
        finishTask();
    }, [finishTask, handleModalClose]);

    const handleBackFromTask = useCallback(() => {
        handleModalClose();
        navigation.pop();
    }, [handleModalClose, navigation]);

    const handleStartWalking = useCallback(async () => {
        closeModal();
        setIsPrepareTimerVisible(true);
        createMoveTask({
            onCompleted: () => {
                handleSubscribe();
            },
            onError: () => {
                setIsPrepareTimerVisible(false);
                handleBackFromTask();
            },
        });
    }, [closeModal, createMoveTask, handleBackFromTask, handleSubscribe]);

    const modalFirstButtonAction = useCallback(() => {
        switch (modalType) {
            case MODAL_TYPES.prepare:
                handleStartWalking();
                break;
            case MODAL_TYPES.leave:
                handleOnLeave();
                break;
            case MODAL_TYPES.leaveWithOutFinish:
                handleBackFromTask();
                break;
            default:
                Console.log('[M2E progres hook] modal issue');
        }
    }, [modalType, handleStartWalking, handleOnLeave, handleBackFromTask]);

    const handleModalSecondButton = useCallback(() => {
        if (modalType === MODAL_TYPES.prepare) {
            handleBackFromTask();
            return;
        }
        closeModal();
    }, [modalType]);

    const handleOpenModal = (modalType: keyof typeof MODAL_TYPES) => {
        setModalType(modalType);
        openModal();
    };

    const onStop = () => {
        handleOpenModal(MODAL_TYPES.leave);
    };

    const onLeaveWithOutFinish = () => {
        handleOpenModal(MODAL_TYPES.leaveWithOutFinish);
    };

    const hideTimer = () => {
        setIsPrepareTimerVisible(false);
    };

    const taskIsRunning = useMemo(() => {
        return !isPrepareTimerVisible && !isVisibleModal && !isPaused;
    }, [isPrepareTimerVisible, isVisibleModal, isPaused]);

    const togglePause = useCallback(() => setIsPaused(!isPaused), [isPaused]);

    return {
        problemWithLocationRenderModal,
        isVisibleModal,
        modalFirstButtonType,
        handleModalSecondButton,
        modalFirstButtonAction,
        modalType,
        moveTaskLoading,
        loadingCreateMove,
        onStop,
        onLeaveWithOutFinish,
        isPrepareTimerVisible,
        hideTimer,
        taskIsRunning,
        msg,
        speed,
        isPaused,
        walkStatus,
        stepsWalked,
        setIsPaused,
        energyEarned,
        speedHistory,
        isSubscribed,
        shakeDetected,
        distanceTraveled,
        distanceTraveledToShow,
        finishTask,
        setRemainingTime,
        remainingTime,
        handleSubscribe,
        handleUnsubscribe,
        togglePause,
        moveTask,
    };
};
