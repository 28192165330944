import { useCallback, useMemo } from 'react';

import { useDimensions } from '@contexts/DimensionsContext';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { errorsHandler } from '@helpers/errors';
import { waitForNavigation } from '@helpers/helpers';
import useMysteryLikesActiveOrders from '@hooks/useMysteryLikesActiveOrders';
import { useMysteryLikesMyPendingGames } from '@hooks/useMysteryLikesMyPendingGames';
import { useVisibleHook } from '@hooks/useVisibleHook';
import {
    ACTIVE_ORDER_LIST,
    PaginatedMysteryLikesAvailableGamesItem,
} from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { useFocusEffect } from '@react-navigation/native';

import useCreateMysteryLikesGame from '../../../hooks/useCreateMysteryLikesGame';
import usePreviousGame from '../../../hooks/usePreviousGame';

const useMysteryLikesActiveOrderScreen = () => {
    const navigation = useNavigation();
    const { isSmallLayout } = useDimensions();

    const {
        getMysteryLikesGameStats,
        mysteryLikesGameStatsLoading,
        mysteryLikesGameStatsRefreshLoading,
        setCurrentMysteryLikesGameId,
        setCurrentMysteryLikesGameToPlay,
        availableBalance,
        getGamingAvailableBalance,
        mysteryLikesGameStats,
    } = useMysteryGames();

    const {
        getOrders,
        getOrdersByFilter,
        getOrdersLoadMore,
        filter,
        filterLoading,
        loadMore,
        ordersLoading,
        refreshLoading,
        orders,
        canLoadMore,
    } = useMysteryLikesActiveOrders();

    const {
        isVisible: confirmPlayModal,
        open: openConfirmPlay,
        close: closeConfirmPlay,
    } = useVisibleHook();

    const {
        getMysteryLikesMyPendingGamesLazyLoading,
        getMysteryLikesMyPendingGamesLazy,
    } = useMysteryLikesMyPendingGames();

    const loadData = useCallback(
        async (silent: boolean) => {
            await Promise.all([
                getMysteryLikesGameStats(silent),
                getOrders(silent),
                getGamingAvailableBalance(silent),
            ]);
        },
        [getOrders]
    );

    const isLoading = useMemo(
        () => mysteryLikesGameStatsLoading || ordersLoading,
        [mysteryLikesGameStatsLoading, ordersLoading]
    );
    useFocusEffect(
        useCallback(() => {
            loadData(true);
        }, [loadData])
    );

    const isRefresh = useMemo(
        () => mysteryLikesGameStatsRefreshLoading || refreshLoading,
        [refreshLoading, mysteryLikesGameStatsRefreshLoading]
    );
    const handleRefresh = useCallback(async () => {
        loadData(false);
    }, [loadData]);

    const handleChooseFilter = (item: string) => {
        getOrdersByFilter(item as ACTIVE_ORDER_LIST);
    };

    const handleFirstModalButtonOrder = () => {
        closePreviousGameModalOrder();
        navigation.navigate(ROUTES.MYSTERY_LIKES_SELECT_RANGE);
    };
    const handleSecondModalButtonOrder = async () => {
        closePreviousGameModalOrder();
    };
    const {
        openPreviousGameModal: openPreviousGameModalOrder,
        closePreviousGameModal: closePreviousGameModalOrder,
        renderPreviousGameModal: renderPreviousGameModalOrder,
    } = usePreviousGame(
        handleFirstModalButtonOrder,
        handleSecondModalButtonOrder,
        mysteryLikesGameStats?.gameMaxLifetimeDays
    );

    const { renderPreviousGameModal, handleCreateGame, createGameLoading } =
        useCreateMysteryLikesGame();

    const onOrderListItemPress = async (
        game: PaginatedMysteryLikesAvailableGamesItem
    ) => {
        setCurrentMysteryLikesGameToPlay(game);
        try {
            const prevGames = await getMysteryLikesMyPendingGamesLazy();
            if (prevGames.data?.mysteryLikesMyPendingGames.length) {
                setCurrentMysteryLikesGameId(
                    prevGames.data?.mysteryLikesMyPendingGames[0].id
                );
                await waitForNavigation();
                openPreviousGameModalOrder();
                return;
            }

            openConfirmPlay();
        } catch (error) {
            errorsHandler(error, true);
        }
    };

    const showModalLoader = useMemo(
        () => getMysteryLikesMyPendingGamesLazyLoading || createGameLoading,
        [createGameLoading, getMysteryLikesMyPendingGamesLazyLoading]
    );
    return {
        handleRefresh,
        isRefresh,
        isLoading,
        getOrdersByFilter,
        getOrdersLoadMore,
        filter,
        filterLoading,
        loadMore,
        ordersLoading,
        handleChooseFilter,
        orders,
        isSmallLayout,
        handleCreateGame,
        renderPreviousGameModal,
        showModalLoader,
        renderPreviousGameModalOrder,
        onOrderListItemPress,
        availableBalance,
        confirmPlayModal,
        closeConfirmPlay,
        canLoadMore,
        mysteryLikesGameStats,
    };
};

export default useMysteryLikesActiveOrderScreen;
