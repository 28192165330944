import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { QUANTUM_SPIN_COLOR } from '@contexts/MysteryGamesContext';
import { scale, verticalScale } from '@helpers/dimensions';
import { stylesModalNavigationFix } from '@helpers/style';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
            backgroundColor: QUANTUM_SPIN_COLOR,
        },

        content: {
            flex: 1,
            paddingTop: verticalScale(20),
            backgroundColor: QUANTUM_SPIN_COLOR,
        },
    });

export default styles;
