import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';
import { stylesAlignCenter } from '@helpers/style';

import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
        },
        loading: {
            ...stylesAlignCenter(),
        },
        petsBlock: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
        },

        flowIndicator: {
            paddingHorizontal: scale(0),
        },
        content: {
            paddingHorizontal: scale(20),
        },
        gap: {
            marginBottom: verticalScale(12),
        },
    });

export default styles;
