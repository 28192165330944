import { StyleSheet } from 'react-native';

import { isWeb } from '@helpers/app';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        emptyList: {
            paddingBottom: isWeb ? 120 : 60,
        },
    });

export default styles;
