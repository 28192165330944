import { Image } from 'react-native';

import { GET_BREEDING_PET_DRAFTS_DETAILS } from '@Data/Requests';
import { useApolloClient } from '@apollo/client';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { errorsHandler } from '@helpers/errors';
import { BreedingPetDraftDetails } from '@models/mysteryGames';

import { useLoadingHook } from './useLoadingHook';

export const useBreedingPetDraftDetails = () => {
    const client = useApolloClient();

    const {
        setBreedingPetDraftDetails,
        setNftEvolutionFirstPet,
        setNftEvolutionSecondPet,
    } = useMysteryGames();
    const {
        isLoading: getBreedingPetDetailsLoading,
        startLoading,
        stopLoading,
    } = useLoadingHook(true);

    const getBreedingPetDetails = async (petDraftId: string) => {
        startLoading();
        try {
            const response = await client.query<BreedingPetDraftDetails>({
                query: GET_BREEDING_PET_DRAFTS_DETAILS,
                fetchPolicy: 'network-only',
                variables: {
                    petDraftId,
                },
            });
            if (response.data.breedingPetDraftDetails) {
                const { breedingPetDraftDetails } = response.data;
                await Image.prefetch(breedingPetDraftDetails.parent1.imageUrl);
                await Image.prefetch(breedingPetDraftDetails.parent2.imageUrl);
                setNftEvolutionFirstPet(breedingPetDraftDetails.parent1);
                setNftEvolutionSecondPet(breedingPetDraftDetails.parent2);
                setBreedingPetDraftDetails(breedingPetDraftDetails);
            }
            stopLoading();
        } catch (error) {
            stopLoading();
            errorsHandler(error, true);
        }
    };

    return {
        getBreedingPetDetails,
        getBreedingPetDetailsLoading,
    };
};
