import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import Pnl from '@assets/icons/leaderboard/pnl.svg';
import PvpGame from '@assets/icons/leaderboard/pvpGame.svg';
import Divider from '@components/Divider';
import Icon, { ICON_NAMES } from '@components/Icons';
import CoinIcon from '@components/Icons/CoinIcon';
import OtherUserAvatar from '@components/OtherUserAvatar';
import { View } from '@components/Themed';
import { useUser } from '@contexts/UserContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { LeaderboardRowOutput } from '@models/leaderboard';
import ROUTES from '@navigation/routes';

import UserAccount from '../UserAccount';
import UserItem from '../UserItem';
import stylesMain from './styles';

interface IProps {
    rankMe: LeaderboardRowOutput | null;
    containerStyle?: StyleProp<ViewStyle>;
}

const screen = ROUTES.LEADERBOARD_PVP_GAMES;

const UserPvpGames = ({ rankMe, containerStyle }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { user } = useUser();
    const address = React.useMemo(
        () => rankMe?.username || user?.username || user?.walletAddress || '',
        [rankMe, user]
    );

    return (
        <View style={[styles.container, containerStyle]}>
            <View style={styles.imgWrapper}>
                <OtherUserAvatar
                    size={88}
                    imageUrl={user?.mainPet?.image?.thumbnailUrl}
                    userId={user?.walletAddress}
                />
            </View>

            <View style={styles.accountWrapper}>
                <UserAccount address={address} />
            </View>
            <Divider customStyles={styles.divider} />
            <View style={styles.statisticsWrapper}>
                <UserItem
                    title={i18n.t(`Leaderboard.${screen}.turnover`)}
                    amount={
                        balanceFromWei(rankMe?.totalIguAmountWei).valueShort
                    }
                    icon={<CoinIcon slug={Coin.igu} size={24} />}
                />
                <UserItem
                    title={i18n.t(`Leaderboard.${screen}.pnl`)}
                    amount={`${rankMe?.winsCount || 0}/${
                        rankMe?.lossesCount || 0
                    }`}
                    icon={<Pnl width={24} height={24} />}
                    containerStyle={styles.gap}
                />
                <UserItem
                    title={i18n.t(`Leaderboard.${screen}.games`)}
                    amount={rankMe?.gamesCount || 0}
                    icon={<PvpGame width={24} height={24} />}
                    containerStyle={styles.gap}
                />
                <UserItem
                    title={i18n.t('general.KEYS.leaderboardPlace')}
                    amount={rankMe?.rank || 'N/A'}
                    icon={<Icon name={ICON_NAMES.LEVEL} size={24} />}
                    containerStyle={styles.gap}
                />
            </View>
        </View>
    );
};
export default UserPvpGames;
