import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import { StakingPlanOutput, UserStake } from '@Data/Models';
import { View } from '@components/Themed';
import { StakingCoin } from '@contexts/StakingContext';
import { isPlanDisabled } from '@helpers/staking';
import useThemedStyles from '@hooks/useThemedStyles';

import StakingCardPlan from '../StakingCardPlan/StakingCardPlan';
import stylesMain from './styles';

interface IProps {
    onPress: (id: string) => void;
    selectedPlan: string;
    data: StakingPlanOutput;
    coin: StakingCoin;
    activePlan: UserStake | null;
    isLast: boolean;
}

const StakingCardButton = ({
    data,
    onPress,
    selectedPlan,
    coin,
    activePlan,
    isLast,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const isDisabled = React.useMemo(() => {
        return isPlanDisabled(data, activePlan);
    }, [activePlan, data]);

    const isSelected = React.useMemo(
        () => selectedPlan === data.plan.id,
        [selectedPlan, data.plan.id]
    );

    return (
        <TouchableOpacity
            onPress={() => onPress(data.plan.id)}
            style={[styles.shadow, isSelected && styles.shadowActive]}
            activeOpacity={0.7}
            disabled={isDisabled}>
            <View style={[styles.container, isSelected && styles.active]}>
                <StakingCardPlan
                    data={data}
                    disabled={isDisabled}
                    coin={coin}
                    isLast={isLast}
                />
            </View>
        </TouchableOpacity>
    );
};
export default StakingCardButton;
