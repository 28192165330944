import { useState } from 'react';

import { GET_IGU_AVAILABLE_BALANCE } from '@Data/Requests';
import { useApolloClient } from '@apollo/client';
import { errorsHandler } from '@helpers/errors';
import { useLoadingHook } from '@hooks/useLoadingHook';
import {
    IguAvailableBalance,
    IguAvailableBalanceResponse,
} from '@models/mysteryGames';

export const useGameAvailableBalance = () => {
    const client = useApolloClient();

    const [availableBalance, setAvailableBalance] =
        useState<IguAvailableBalance>();

    const {
        isLoading: availableBalanceLoading,
        startLoading: startLoadingAvailableBalance,
        stopLoading: stopLoadingAvailableBalance,
    } = useLoadingHook();

    const getGamingAvailableBalance = async (withLoad = true) => {
        try {
            withLoad && startLoadingAvailableBalance();
            const result = await client.query<IguAvailableBalanceResponse>({
                query: GET_IGU_AVAILABLE_BALANCE,
                fetchPolicy: 'no-cache',
            });
            if (result.data) {
                setAvailableBalance(result.data.iguBalance);
            }
            stopLoadingAvailableBalance();
        } catch (error) {
            stopLoadingAvailableBalance();
            errorsHandler(error, true);
        }
    };

    return {
        getGamingAvailableBalance,
        availableBalance,
        availableBalanceLoading,
    };
};
