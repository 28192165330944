import * as React from 'react';
import { useCallback, useState } from 'react';

import Carousel from '@components/Carousel';
import InfoIcon from '@components/InfoIcon/InfoIcon';
import { Text, View } from '@components/Themed';
import { useDimensions } from '@contexts/DimensionsContext';
import { allGamesData, useMysteryGames } from '@contexts/MysteryGamesContext';
import { scale } from '@helpers/dimensions';
import { errorsHandler } from '@helpers/errors';
import { useBreedingRanksStats } from '@hooks/useBreedingRanksStats';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import {
    GameOutput,
    IAllGamesData,
    LUCKY_STRIKE_GAME_DURATION,
    MYSTERY_GAMES,
} from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import { maxParticipants } from '../../LuckyStrikeFlow/helpers';
import GameBannerBig from '../GameBannerBig';
import GameBannerSmall from '../GameBannerSmall';
import ModalCompetitiveGames from '../ModalCompetitiveGames';
import ModalGameComingSoon from '../ModalGameComingSoon';
import stylesMain from './styles';

interface IProps {
    games: GameOutput[] | undefined;
    loading: boolean;
    isMysteryLikesPendingGames: boolean;
    isNftRoyalPendingGames: boolean;
}

const GamesBlock = ({
    games,
    loading,
    isMysteryLikesPendingGames,
    isNftRoyalPendingGames,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { windowWidth } = useDimensions();
    const navigation = useNavigation();

    const {
        mysteryLikesGameStats,
        mysteryLikesGameStatsRefreshLoading,
        setBreedingRanksStats,
        nftRoyaleGameConfig,
        nftRoyaleGameConfigRefresh,
        fetchQuantumSpinGamePrizes,
        loadingQuantumSpinGamePrizes,
        luckyStrikeConfig,
    } = useMysteryGames();

    React.useEffect(() => {
        setButtonsEnabled();
    }, [loading]);

    const { getBreedingRanksStats, breedingRanksStatsLoading } =
        useBreedingRanksStats();

    const itemWidth = React.useMemo(
        () => windowWidth - scale(20 * 2),
        [windowWidth]
    );

    const itemCustomWidth = itemWidth - scale(12);

    const [height, setHeight] = useState<number>(0);

    const getHeight = (height: number) => {
        setHeight((prev) => (prev < height ? height : prev));
    };

    const {
        isVisible: modalComingSoon,
        open: openModalComingSoon,
        close: closeModalComingSoon,
    } = useVisibleHook();

    const {
        isVisible: isButtonsDisabled,
        open: setButtonsDisabled,
        close: setButtonsEnabled,
    } = useVisibleHook();

    const mysteryLikesButton = useCallback(() => {
        setButtonsDisabled();
        if (isMysteryLikesPendingGames) {
            navigation.navigate(ROUTES.MYSTERY_LIKES_MAIN);
            navigation.navigate(ROUTES.MYSTERY_LIKES_SELECT_RANGE);
            return;
        }
        navigation.navigate(ROUTES.WELCOME_HOW_MYSTERY_LIKES_WORK);
    }, [isMysteryLikesPendingGames]);

    const nftEvolutionButton = async () => {
        setButtonsDisabled();
        try {
            const result = await getBreedingRanksStats();
            if (result.data) {
                setBreedingRanksStats(result.data.breedingRanksStats);
                navigation.push(ROUTES.NFT_EVOLUTION_WELCOME);
            }
        } catch (error) {
            setButtonsEnabled();
            errorsHandler(error, true);
        }
    };

    const nftRoyalButton = useCallback(() => {
        setButtonsDisabled();
        if (isNftRoyalPendingGames) {
            navigation.navigate(ROUTES.NFT_ROYAL_MAIN);
            navigation.navigate(ROUTES.NFT_ROYAL_PLAY_GAME);
            return;
        }
        navigation.navigate(ROUTES.WELCOME_HOW_NFT_ROYAL_WORK);
    }, [isNftRoyalPendingGames]);

    const luckyStrikeButton = async () => {
        setButtonsDisabled();
        navigation.push(ROUTES.WELCOME_LUCKY_STRIKE);
    };

    const tokenTellerButton = async () => {
        setButtonsDisabled();
        navigation.push(ROUTES.WELCOME_TOKEN_TELLER);
    };

    const quantumSpinButton = async () => {
        setButtonsDisabled();
        try {
            await fetchQuantumSpinGamePrizes();
            navigation.push(ROUTES.WELCOME_QUANTUM_SPIN);
        } catch (error) {
            setButtonsEnabled();
            errorsHandler(error, true);
        }
    };

    const getLuckyStrikeUsersText = React.useMemo(() => {
        if (!games?.length) {
            return undefined;
        }
        const game = games.find((i) => i.type === MYSTERY_GAMES.luckyStrike);
        if (!game) {
            return undefined;
        }

        return `${game.playersCount} / ${maxParticipants(
            luckyStrikeConfig?.gameTypes,
            LUCKY_STRIKE_GAME_DURATION.FiveMinute
        )}`;
    }, [games, luckyStrikeConfig]);

    const carouselItem = useCallback(
        (item: IAllGamesData) => {
            const isEnabled = !!games?.find((i) => i.type === item.id)?.enabled;
            switch (item.id) {
                case MYSTERY_GAMES.mysteryLikes:
                    return (
                        <GameBannerBig
                            minHeight={height}
                            getHeight={getHeight}
                            item={item}
                            usersText={(
                                mysteryLikesGameStats?.activeGamesCount || 0
                            ).toString()}
                            loading={mysteryLikesGameStatsRefreshLoading}
                            width={itemCustomWidth}
                            onButtonAction={mysteryLikesButton}
                            buttonDisabled={
                                loading || !isEnabled || isButtonsDisabled
                            }
                            continuePlay={isMysteryLikesPendingGames}
                            showUsers
                        />
                    );

                case MYSTERY_GAMES.nftEvolution:
                    return (
                        <GameBannerBig
                            minHeight={height}
                            buttonDisabled={
                                loading ||
                                breedingRanksStatsLoading ||
                                !isEnabled ||
                                isButtonsDisabled
                            }
                            loading={breedingRanksStatsLoading}
                            getHeight={getHeight}
                            item={item}
                            width={itemCustomWidth}
                            onButtonAction={nftEvolutionButton}
                        />
                    );

                case MYSTERY_GAMES.nftRoyal:
                    return (
                        <GameBannerBig
                            usersText={(
                                nftRoyaleGameConfig?.activeGamesCount || 0
                            ).toString()}
                            minHeight={height}
                            getHeight={getHeight}
                            item={item}
                            loading={mysteryLikesGameStatsRefreshLoading}
                            width={itemCustomWidth}
                            onButtonAction={nftRoyalButton}
                            continuePlay={isNftRoyalPendingGames}
                            buttonDisabled={
                                loading || !isEnabled || isButtonsDisabled
                            }
                            showUsers
                        />
                    );

                case MYSTERY_GAMES.luckyStrike:
                    return (
                        <GameBannerBig
                            minHeight={height}
                            buttonDisabled={
                                loading || !isEnabled || isButtonsDisabled
                            }
                            getHeight={getHeight}
                            item={item}
                            width={itemCustomWidth}
                            usersText={getLuckyStrikeUsersText}
                            showUsers
                            onButtonAction={luckyStrikeButton}
                        />
                    );
                case MYSTERY_GAMES.quantumSpin:
                    return (
                        <GameBannerBig
                            minHeight={height}
                            buttonDisabled={
                                loading ||
                                !isEnabled ||
                                loadingQuantumSpinGamePrizes ||
                                isButtonsDisabled
                            }
                            loading={loadingQuantumSpinGamePrizes}
                            getHeight={getHeight}
                            item={item}
                            width={itemCustomWidth}
                            onButtonAction={quantumSpinButton}
                        />
                    );
                case MYSTERY_GAMES.tokenteller:
                    return (
                        <GameBannerBig
                            minHeight={height}
                            buttonDisabled={
                                loading || !isEnabled || isButtonsDisabled
                            }
                            getHeight={getHeight}
                            item={item}
                            width={itemCustomWidth}
                            showUsers
                            onButtonAction={tokenTellerButton}
                        />
                    );
                default:
                    return (
                        <GameBannerBig
                            minHeight={height}
                            getHeight={getHeight}
                            buttonDisabled={isButtonsDisabled}
                            item={item}
                            loading={nftRoyaleGameConfigRefresh}
                            width={itemCustomWidth}
                            isComingSoon
                            onButtonAction={openModalComingSoon}
                        />
                    );
            }
        },
        [
            itemWidth,
            mysteryLikesGameStats,
            mysteryLikesGameStatsRefreshLoading,
            itemCustomWidth,
            loading,
            games,
            height,
            isMysteryLikesPendingGames,
            isNftRoyalPendingGames,
            breedingRanksStatsLoading,
            nftRoyaleGameConfig,
            nftRoyaleGameConfigRefresh,
            loadingQuantumSpinGamePrizes,
            getLuckyStrikeUsersText,
            isButtonsDisabled,
        ]
    );
    const defaultItem = useCallback(
        (item: IAllGamesData) => {
            const isEnabled = !!games?.find((i) => i.type === item.id)?.enabled;
            switch (item.id) {
                case MYSTERY_GAMES.mysteryLikes:
                    return (
                        <GameBannerSmall
                            key={item.id}
                            item={item}
                            usersText={(
                                mysteryLikesGameStats?.activeGamesCount || 0
                            ).toString()}
                            loading={mysteryLikesGameStatsRefreshLoading}
                            onButtonAction={mysteryLikesButton}
                            buttonDisabled={
                                loading || !isEnabled || isButtonsDisabled
                            }
                            showUsers
                        />
                    );

                case MYSTERY_GAMES.nftEvolution:
                    return (
                        <GameBannerSmall
                            key={item.id}
                            buttonDisabled={
                                loading ||
                                breedingRanksStatsLoading ||
                                !isEnabled ||
                                isButtonsDisabled
                            }
                            loading={breedingRanksStatsLoading}
                            item={item}
                            onButtonAction={nftEvolutionButton}
                        />
                    );

                case MYSTERY_GAMES.nftRoyal:
                    return (
                        <GameBannerSmall
                            key={item.id}
                            usersText={(
                                nftRoyaleGameConfig?.activeGamesCount || 0
                            ).toString()}
                            item={item}
                            loading={mysteryLikesGameStatsRefreshLoading}
                            onButtonAction={nftRoyalButton}
                            buttonDisabled={
                                loading || !isEnabled || isButtonsDisabled
                            }
                            showUsers
                        />
                    );

                case MYSTERY_GAMES.luckyStrike:
                    return (
                        <GameBannerSmall
                            key={item.id}
                            buttonDisabled={
                                loading || !isEnabled || isButtonsDisabled
                            }
                            item={item}
                            showUsers
                            usersText={getLuckyStrikeUsersText}
                            onButtonAction={luckyStrikeButton}
                        />
                    );
                case MYSTERY_GAMES.quantumSpin:
                    return (
                        <GameBannerSmall
                            key={item.id}
                            buttonDisabled={
                                loading ||
                                !isEnabled ||
                                loadingQuantumSpinGamePrizes ||
                                isButtonsDisabled
                            }
                            loading={loadingQuantumSpinGamePrizes}
                            item={item}
                            onButtonAction={quantumSpinButton}
                        />
                    );

                case MYSTERY_GAMES.tokenteller:
                    return (
                        <GameBannerSmall
                            key={item.id}
                            buttonDisabled={
                                loading || !isEnabled || isButtonsDisabled
                            }
                            item={item}
                            showUsers
                            onButtonAction={tokenTellerButton}
                        />
                    );
                default:
                    return (
                        <GameBannerSmall
                            key={item.id}
                            item={item}
                            buttonDisabled={isButtonsDisabled}
                            loading={nftRoyaleGameConfigRefresh}
                            onButtonAction={openModalComingSoon}
                        />
                    );
            }
        },
        [
            itemWidth,
            mysteryLikesGameStats,
            mysteryLikesGameStatsRefreshLoading,
            itemCustomWidth,
            loading,
            games,
            height,
            isMysteryLikesPendingGames,
            isNftRoyalPendingGames,
            breedingRanksStatsLoading,
            nftRoyaleGameConfig,
            nftRoyaleGameConfigRefresh,
            loadingQuantumSpinGamePrizes,
            getLuckyStrikeUsersText,
            isButtonsDisabled,
        ]
    );

    const { isVisible, open, close } = useVisibleHook();

    const renderCarouselCards = useCallback(() => {
        return (
            <Carousel
                data={allGamesData}
                sliderWidth={windowWidth}
                itemWidth={itemWidth}
                renderItem={carouselItem}
                itemStyle={styles.itemStyle}
                height={height}
                pagination
                containerStyle={styles.carousel}
            />
        );
    }, [allGamesData, windowWidth, itemWidth, carouselItem, styles, height]);
    const renderDefaultCards = useCallback(() => {
        return (
            <View style={styles.defaultCards}>
                {allGamesData.map((i) => defaultItem(i))}
            </View>
        );
    }, [allGamesData, windowWidth, itemWidth, carouselItem, styles, height]);

    const isBigScreen = React.useMemo(() => windowWidth > 360, [windowWidth]);

    return (
        <View style={styles.container}>
            <View style={styles.headerBlock}>
                <Text style={styles.header}>
                    {i18n.t('HomeScreen.GamesBlock.header')}
                </Text>
                <InfoIcon onPress={open} />
            </View>

            {isBigScreen ? renderDefaultCards() : renderCarouselCards()}
            <ModalCompetitiveGames isVisible={isVisible} onClose={close} />
            <ModalGameComingSoon
                isVisible={modalComingSoon}
                onClose={closeModalComingSoon}
            />
        </View>
    );
};
export default React.memo(GamesBlock);
