import * as React from 'react';
import {
    ActivityIndicator as BaseActivityIndicator,
    ColorValue,
    ViewStyle,
} from 'react-native';

import Colors from '../../constants/Colors';
import { useTheme } from '../../contexts/ThemeContext';

interface IProps {
    loading: boolean;
    containerStyle?: ViewStyle;
    size?: number | 'small' | 'large' | undefined;
    color?: ColorValue | undefined;
}

const ActivityIndicator: React.FunctionComponent<IProps> = ({
    loading,
    containerStyle,
    size = 'small',
    color,
}) => {
    const { theme } = useTheme();

    return (
        <BaseActivityIndicator
            size={size}
            animating={loading}
            color={color ? color : Colors[theme].text.accent}
            style={containerStyle}
        />
    );
};

export default ActivityIndicator;
