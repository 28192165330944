import * as React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import { MainPetUserOutputReferral } from '@Data/Models';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import ExplorerLink from '@components/ExplorerLink';
import ModalBottom from '@components/ModalBottom';
import PetCollectionListItem from '@components/PetCollectionListItem';
import WalletAddress from '@components/WalletAddress';
import Colors from '@constants/Colors';
import { useTheme } from '@contexts/ThemeContext';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import ReferralExtendedInfo from '../ReferralExtendedInfo';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    closeAction: () => void;
    user: MainPetUserOutputReferral;
}

const ModalReferee = ({ isVisible, closeAction, user }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { theme } = useTheme();

    return (
        <ModalBottom
            modalHeight={700}
            isVisible={isVisible}
            backdropOpacity={Colors[theme].modal.opacity}
            onClose={closeAction}>
            <ScrollView
                style={styles.modalContainer}
                showsVerticalScrollIndicator={false}>
                <BlockHeader
                    type="primary"
                    title={i18n.t('referrals.modalReferee.title')}
                    text={i18n.t('referrals.modalReferee.text')}
                />
                <TouchableOpacity style={styles.cardWrapper} activeOpacity={1}>
                    {!!user.mainPet && (
                        <>
                            <PetCollectionListItem
                                disabled
                                pet={user.mainPet}
                                style={styles.pet}
                            />
                        </>
                    )}
                    <ReferralExtendedInfo
                        user={user}
                        withDivider={!!user.mainPet}
                    />
                </TouchableOpacity>
                <WalletAddress
                    address={user.walletAddress}
                    userName={user.username}
                    shouldUseUsername
                    containerStyle={styles.wallet}
                />
                <ExplorerLink
                    walletAddress={user.walletAddress}
                    containerStyles={styles.explore}
                />
            </ScrollView>
        </ModalBottom>
    );
};
export default ModalReferee;
