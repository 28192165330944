import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import ReadMore from 'react-native-read-more-text';

import { Charity } from '../../../../../Data/Models';
import AddressIcon from '../../../../../assets/icons/charity/address.svg';
import EmailIcon from '../../../../../assets/icons/charity/email.svg';
import WebsiteIcon from '../../../../../assets/icons/charity/website.svg';
import TwitterIcon from '../../../../../assets/icons/social/twitter.svg';
import Button from '../../../../../components/Button';
import CurrentLabel from '../../../../../components/CurrentLabel';
import Icon, { ICON_NAMES } from '../../../../../components/Icons';
import { Text, View } from '../../../../../components/Themed';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import InfoRow from '../InfoRow';
import stylesMain from './styles';

interface IProps {
    charity: Charity;
    isSelected?: boolean;
    containerStyles?: StyleProp<ViewStyle>;
}

const OrganizationInfo = ({
    charity,
    isSelected = false,
    containerStyles,
}: IProps) => {
    const iconSize = { height: 24, width: 24 };
    const styles = useThemedStyles(stylesMain);

    const renderReadMore = (handlePress: () => void) => (
        <Button
            type="text"
            onPress={handlePress}
            title={i18n.t('charity.readMore')}
            iconName={ICON_NAMES.ARROW_DROP_DOWN}
            iconSize={16}
            iconColor="green"
            containerStyle={styles.readMore}
            iconDirection="right"
        />
    );

    const renderHide = (handlePress: () => void) => (
        <Button
            type="text"
            onPress={handlePress}
            title={i18n.t('charity.hide')}
            iconName={ICON_NAMES.ARROW_DROP_UP}
            iconSize={16}
            iconColor="green"
            containerStyle={styles.readMore}
            iconDirection="right"
        />
    );

    const renderInfoRows = () => (
        <>
            <InfoRow
                image={<AddressIcon {...iconSize} />}
                title={i18n.t('charity.infoRows.address')}
                info={charity.friendlyAddress}
                containerStyle={[styles.infoRowMargin, styles.firstRow]}
            />
            <InfoRow
                image={<WebsiteIcon {...iconSize} />}
                title={i18n.t('charity.infoRows.website')}
                info={charity.website}
                containerStyle={styles.infoRowMargin}
                linkType="web"
            />
            <InfoRow
                image={<EmailIcon {...iconSize} />}
                title={i18n.t('charity.infoRows.email')}
                info={charity.email}
                containerStyle={styles.infoRowMargin}
                linkType="email"
            />
            <InfoRow
                image={<Icon name={ICON_NAMES.INSTAGRAM} size={24} />}
                title={i18n.t('charity.infoRows.instagram')}
                info={charity.instagram}
                containerStyle={styles.infoRowMargin}
                linkType="instagram"
            />
            <InfoRow
                image={<TwitterIcon {...iconSize} />}
                title={i18n.t('charity.infoRows.twitter')}
                info={charity.twitter}
                containerStyle={styles.infoRowMargin}
                linkType="twitter"
            />
        </>
    );

    return (
        <View style={[styles.container, containerStyles]}>
            <View style={styles.selectedContainer}>
                {isSelected && (
                    <CurrentLabel
                        text={i18n.t('charity.current')}
                        containerStyle={styles.selectedLabel}
                    />
                )}
            </View>

            <Text style={[styles.title, styles.titleMargin]}>
                {charity.name}
            </Text>
            <ReadMore
                numberOfLines={3}
                renderTruncatedFooter={renderReadMore}
                renderRevealedFooter={renderHide}>
                <Text style={styles.description}>{charity.description}</Text>
            </ReadMore>
            {renderInfoRows()}
        </View>
    );
};

export default OrganizationInfo;
