import React from 'react';

import { WhitelistRanksOutputResponse } from '@Data/Models';
import { GET_WHITE_LIST_RANKS } from '@Data/Requests';
import { useQuery } from '@apollo/client';
import { errorsHandler } from '@helpers/errors';
import i18n from '@i18n/i18n';

import RankSelect from '../components/RankSelect';

export const useRankSelect = () => {
    const [rankValue, setRankValue] = React.useState<string>('');
    const [rankError, setRankError] = React.useState<string>('');

    const { data: ranks } = useQuery<WhitelistRanksOutputResponse>(
        GET_WHITE_LIST_RANKS,
        {
            onError: (error) => errorsHandler(error, true),
        }
    );

    const checkRank = React.useCallback(() => {
        if (!rankValue) {
            setRankError(i18n.t('profile.adminAssignWhiteList.error.rank'));
            return false;
        }
        return true;
    }, [rankValue]);

    const handleSelect = (slug: string) => {
        setRankValue(slug);
    };

    const renderRankSelect = React.useCallback(() => {
        return (
            <RankSelect
                loading={!ranks?.whitelistRanks}
                error={rankError}
                selectedRank={rankValue}
                ranks={ranks?.whitelistRanks}
                onPress={handleSelect}
            />
        );
    }, [rankError, rankValue, ranks]);

    const resetRankSelect = () => {
        setRankValue('');
        setRankError('');
    };

    return {
        checkRank,
        resetRankSelect,
        renderRankSelect,
        rankValue,
        rankError,
    };
};
