import * as React from 'react';

import { IconType } from '../../../../../../../components/Icons/types';
import HeartSvg from '../images/heart.svg';
import HeartActiveSvg from '../images/heartActive.svg';

interface IProps extends IconType {
    isActive?: boolean;
}

const HeartIcon: React.FunctionComponent<IProps> = ({
    size = 28,
    isActive = false,
}) =>
    !isActive ? (
        <HeartSvg width={size} height={size} />
    ) : (
        <HeartActiveSvg width={size} height={size} />
    );

export default HeartIcon;
