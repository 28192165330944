import { useCallback, useMemo, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { useDimensions } from '@contexts/DimensionsContext';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { errorsHandler } from '@helpers/errors';
import { useLoadingHook } from '@hooks/useLoadingHook';
import { useVisibleHook } from '@hooks/useVisibleHook';
import {
    PaginatedTokentellerGamesHistoryOutput,
    TOKEN_TELLER_HISTORY_GAMES_STATUS,
    TokentellerGameHistoryOutputResponse,
} from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { useFocusEffect } from '@react-navigation/native';
import { GET_TOKEN_TELLER_GAME_HISTORY } from '@requests/tokenTeller';

import { useTokenTellerGamesHistory } from '../../../hooks/useTokenTellerGamesHistory';

// import { toastGameExpired, toastGamePending } from '../../helpers';

const useTokenTellerHistoryScreen = () => {
    const navigation = useNavigation();
    const { isSmallLayout } = useDimensions();

    const [firstLoad, setFirsLoad] = useState<boolean>(true);

    const { availableBalance, getGamingAvailableBalance } = useMysteryGames();
    const {
        gamesHistory,
        getGamesHistory,
        getGamesHistoryLoadMore,
        canLoadMore,
        loadMore,
        historyLoading,
        refreshLoading: historyRefreshLoading,
    } = useTokenTellerGamesHistory();

    const loadData = useCallback(async (silent: boolean) => {
        await Promise.all([getGamingAvailableBalance(silent)]);
    }, []);

    useFocusEffect(
        useCallback(() => {
            getGamesHistory(firstLoad);
            setFirsLoad(false);
        }, [firstLoad])
    );

    useFocusEffect(
        useCallback(() => {
            loadData(true);
        }, [loadData])
    );

    const isRefresh = useMemo(
        () => historyRefreshLoading,
        [historyRefreshLoading]
    );
    const handleRefresh = useCallback(async () => {
        loadData(false);
        await getGamesHistory(false);
    }, [loadData]);

    const handOnHowItWorks = () => {
        navigation.push(ROUTES.HOW_TOKEN_TELLER_WORK);
    };

    const isReloading = useMemo(
        () => isRefresh || historyLoading,
        [isRefresh, historyLoading]
    );

    const {
        isLoading: onPressLoading,
        startLoading,
        stopLoading,
    } = useLoadingHook();

    const [getHistoryItem] = useLazyQuery<TokentellerGameHistoryOutputResponse>(
        GET_TOKEN_TELLER_GAME_HISTORY,
        {
            fetchPolicy: 'network-only',
        }
    );

    const onHistoryItemPress = async (
        item: PaginatedTokentellerGamesHistoryOutput
    ) => {
        startLoading();
        try {
            if (
                item.status !==
                TOKEN_TELLER_HISTORY_GAMES_STATUS.WaitingForResults
            ) {
                const result = await getHistoryItem({
                    variables: {
                        gameId: item.id,
                    },
                });
                if (result.data?.tokentellerGameHistory) {
                    navigation.navigate(ROUTES.TOKEN_TELLER_GAME_HISTORY, {
                        game: result.data.tokentellerGameHistory,
                        force: true,
                        id: item.id,
                    });
                }
            } else {
                navigation.push(ROUTES.TOKEN_TELLER_WAITING_FOR_RESULT, {
                    id: item.id,
                    routeBack: ROUTES.TOKEN_TELLER_MAIN,
                });
            }
        } catch (error) {
            errorsHandler(error, true);
        }
        stopLoading();
    };

    const {
        isVisible: roundsModal,
        open: openRoundsModal,
        close: closeRoundsModal,
    } = useVisibleHook();

    const onPurchaseTicket = (id: string) => {
        closeRoundsModal();
        navigation.navigate(ROUTES.TOKEN_TELLER_MAIN, {
            game: { id },
        });
    };

    return {
        handleRefresh,
        isRefresh,
        isSmallLayout,
        availableBalance,
        handOnHowItWorks,
        gamesHistory,
        getGamesHistoryLoadMore,
        canLoadMore,
        loadMore,
        historyLoading,
        onHistoryItemPress,
        onPressLoading,
        isReloading,
        roundsModal,
        openRoundsModal,
        closeRoundsModal,
        onPurchaseTicket,
    };
};

export default useTokenTellerHistoryScreen;
