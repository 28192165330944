import * as React from 'react';
import { ScrollView } from 'react-native';

import Direct from '@assets/icons/referrals/direct.svg';
import Gaming from '@assets/icons/referrals/gaming.svg';
import Indirect from '@assets/icons/referrals/indirect.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import Button from '@components/Button';
import CardBaseInfo from '@components/CardBaseInfo/CardBaseInfo';
import { ICON_NAMES } from '@components/Icons';
import ReferralModal from '@components/ReferralModal';
import SafeAreaCurvedTabContent from '@components/SafeAreaCurvedTabContent';
import { View } from '@components/Themed';
import { Links } from '@constants/Links';
import { useConfig } from '@contexts/ConfigContext';
import { useUser } from '@contexts/UserContext';
import { waitForDeepLink } from '@helpers/helpers';
import { openUrlOrShowError } from '@helpers/linking';
import { toastError, toastSuccess } from '@helpers/toastNotification';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationReferrals from '@navigation/NavigationBar/NavigationReferrals';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import { ReferralsInvitesProps } from '../../../../types';
import EnterReferralCode from '../components/EnterReferralCode';
import ReferralCode from '../components/ReferralCode';
import ReferralLink from '../components/ReferralLink';
import stylesMain from './styles';

const ReferralsMainScreen = ({ route }: ReferralsInvitesProps) => {
    const styles = useThemedStyles(stylesMain);

    const navigation = useNavigation();

    const { user } = useUser();
    const { config } = useConfig();

    const direct = React.useMemo(
        () => user?.directReferralPercentage || 0,
        [user]
    );
    const inDirect = React.useMemo(
        () => user?.indirectReferralPercentage || 0,
        [user]
    );

    const handleHowItsWork = React.useCallback(() => {
        navigation.navigate(ROUTES.HOW_TO_REFERRALS, {
            direct,
            inDirect,
        });
    }, [direct, inDirect]);

    const handleOnSharePress = () => {
        navigation.navigate(ROUTES.REFERRALS_INVITE);
    };

    const isReferred = React.useMemo(() => user?.isReferred, [user]);

    const showSuccess = () => {
        toastSuccess(
            i18n.t('referrals.referrerModal.success.toastSuccess.title'),
            i18n.t('referrals.referrerModal.success.toastSuccess.text'),
            'top'
        );
    };
    const showError = () => {
        toastError(
            i18n.t('referrals.referrerModal.error.toastAlreadyLinked.title'),
            i18n.t('referrals.referrerModal.error.toastAlreadyLinked.text'),
            'top'
        );
    };

    const [isReferralModalVisible, setReferralModalVisible] =
        React.useState<boolean>(false);
    const handleCloseModal = (success: boolean) => {
        if (success) {
            showSuccess();
        }

        setReferralModalVisible(false);
    };
    const handleOpenModal = () => {
        setReferralModalVisible(true);
    };

    React.useEffect(() => {
        if (route.params?.refId && !isReferred) {
            waitForDeepLink().then(() => {
                handleOpenModal();
            });
        }
        if (route.params?.refId && isReferred) {
            waitForDeepLink().then(() => showError());
        }
    }, [route.params, isReferred]);

    const onApplyPress = async () => {
        if (config?.knowledgeBaseUrl) {
            return openUrlOrShowError(
                config.knowledgeBaseUrl + Links.applyReferral
            );
        }
    };

    return (
        <SafeAreaCurvedTabContent>
            <ScrollView
                keyboardShouldPersistTaps="handled"
                style={styles.container}
                showsVerticalScrollIndicator={false}>
                <NavigationReferrals currentNavigation="INVITES" />

                <View style={styles.content}>
                    <BlockHeader
                        type="primary"
                        title={i18n.t('referrals.header.title')}
                        text={i18n.t('referrals.header.text')}
                        textInfo={i18n.t('referrals.header.infoText')}
                        textInfoOnPress={handleHowItsWork}
                    />
                    {!isReferred && user?.referralUsageAvailable && (
                        <View style={styles.referralEnterWrapper}>
                            <EnterReferralCode
                                onButtonPress={handleOpenModal}
                            />
                        </View>
                    )}
                    <View style={styles.referralCodeWrapper}>
                        {!!user?.referralCode && (
                            <ReferralCode
                                code={user.referralCode}
                                text={i18n.t('referrals.referralCode')}
                            />
                        )}
                    </View>

                    <View style={styles.referralCodeWrapper}>
                        {!!user?.referralCode && (
                            <ReferralLink
                                code={user.referralCode}
                                text={i18n.t('referrals.referralLink')}
                            />
                        )}
                    </View>

                    <View style={styles.cardWrapper}>
                        <CardBaseInfo
                            icon={<Direct />}
                            title={i18n.t('referrals.direct.title', {
                                directPercentage: direct,
                            })}
                            text={i18n.t('referrals.direct.text', {
                                directPercentage: direct,
                            })}
                        />
                        <CardBaseInfo
                            icon={<Indirect />}
                            containerStyle={styles.gap}
                            title={i18n.t('referrals.indirect.title', {
                                inDirectPercentage: inDirect,
                            })}
                            text={i18n.t('referrals.indirect.text', {
                                inDirectPercentage: inDirect,
                            })}
                        />
                        <CardBaseInfo
                            icon={<Gaming />}
                            containerStyle={styles.gap}
                            title={i18n.t('referrals.gaming.title')}
                            text={i18n.t('referrals.gaming.text')}
                        />
                    </View>
                    <Button
                        title={i18n.t('referrals.buttonShare')}
                        iconName={ICON_NAMES.INVITE}
                        iconColor="onDark"
                        onPress={handleOnSharePress}
                    />
                </View>
            </ScrollView>

            <ReferralModal
                isVisible={isReferralModalVisible}
                onClose={handleCloseModal}
                onApplyPress={onApplyPress}
                deepLinkingCode={isReferred ? undefined : route.params?.refId}
            />
        </SafeAreaCurvedTabContent>
    );
};
export default ReferralsMainScreen;
