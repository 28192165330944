import * as React from 'react';

import { TasksHistoryResponseType } from '../../../Data/Models';
import MoveTaskIcon from '../../../assets/icons/playHistory/moveTask.svg';
import PlayTaskIcon from '../../../assets/icons/playHistory/playTask.svg';
import SocialTaskIcon from '../../../assets/icons/playHistory/socialTask.svg';
import VerificationTaskIcon from '../../../assets/icons/playHistory/verifyTask.svg';
import { IconType, iconSize } from '../types';

interface IProps extends IconType {
    slug: TasksHistoryResponseType;
}

const TaskHistoryIcon = ({ slug, size = iconSize.primary }: IProps) => {
    switch (slug) {
        case 'SocialTask':
            return <SocialTaskIcon width={size} height={size} />;

        case 'VerificationTask':
            return <VerificationTaskIcon width={size} height={size} />;

        case 'MoveTask':
            return <MoveTaskIcon width={size} height={size} />;

        case 'PlayTask':
            return <PlayTaskIcon width={size} height={size} />;

        default:
            Console.error(`No TaskHistory icon with slug ${slug} is available`);
            return <></>;
    }
};

export default TaskHistoryIcon;
