import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { scale, verticalScale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
        },
        animationWrapper: {
            marginBottom: verticalScale(5),
            left: scale(-10),
            backgroundColor: 'transparent',
        },
        animation: {
            width: 100,
            height: 40,
        },
        webWrapper: {
            marginTop: verticalScale(10),
            justifyContent: 'center',
            backgroundColor: 'transparent',
        },
        text: {
            textAlign: 'center',
            color: Colors[theme].text.primary,
            ...font({
                size: 12,
                height: 16,
                weight: '600',
            }),
        },
        fullPage: {
            backgroundColor: Colors[theme].background,
            flex: 1,
            paddingTop: verticalScale(20),
        },
    });

export default styles;
