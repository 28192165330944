import { StyleSheet } from 'react-native';

import Colors from '../../../constants/Colors';
import { scale, verticalScale } from '../../../helpers/dimensions';
import font from '../../../helpers/font';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: verticalScale(24),
        },
        divider: {
            borderWidth: 0.75,
            borderColor: Colors[theme].border.primary,
            borderStyle: 'dashed',
            marginHorizontal: scale(6),
            flex: 1,
        },
        info: {
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            width: scale(80),
            height: scale(80),
            backgroundColor: Colors[theme].backgroundWarning,
            borderRadius: 1000,
        },
        lvl: {
            color: Colors[theme].text.warning,
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),
            marginTop: verticalScale(4),
        },
        emptyIcon: {
            width: 80,
            marginHorizontal: scale(6),
            flex: 1,
        },
    });

export default styles;
