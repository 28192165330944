import * as React from 'react';
import { FlatList, StyleProp, ViewStyle } from 'react-native';

import { useQuery } from '@apollo/client';
import Participants from '@assets/icons/mysteryGames/LuckyStrike/participants.svg';
import EmptyList from '@components/EmptyList';
import CoinIcon from '@components/Icons/CoinIcon';
import LoadingComponent from '@components/LoadingComponent';
import { View } from '@components/Themed';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { statisticsFormatter } from '@helpers/helpers';
import { priceString } from '@helpers/payments';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import {
    MYSTERY_GAMES,
    TokentellerGameParticipantsOutputResponse,
} from '@models/mysteryGames';
import useNavigationListeners from '@navigation/hooks/useNavigationListeners';
import { useNavigation } from '@navigation/useNavigation';
import { GET_TOKENTELLER_PARTICIPANTS } from '@requests/tokenTeller';

import GamesStatsInfoCard from '../GamesStatsInfoCard';
import TokenTellerParticipantItem from '../TokenTellerParticipantItem';
import stylesMain from './styles';

interface IProps {
    gameId: string;
    containerStyle?: StyleProp<ViewStyle>;
    elementsToLoad?: number;
}

const TokenTellerAllParticipantsComponent = ({
    gameId,
    containerStyle,
    elementsToLoad,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    useNavigationListeners();

    const navigation = useNavigation();

    const { priceInUsd } = useWallet();

    Console.info(gameId);

    const { data, loading } =
        useQuery<TokentellerGameParticipantsOutputResponse>(
            GET_TOKENTELLER_PARTICIPANTS,
            {
                fetchPolicy: 'network-only',
                variables: {
                    gameId,
                },
            }
        );

    const amount = React.useMemo(
        () =>
            balanceFromWei(data?.tokentellerGameParticipants.iguAmountWei)
                .value,
        [data?.tokentellerGameParticipants.iguAmountWei]
    );

    return (
        <View style={[styles.main, containerStyle]}>
            {loading ? (
                <LoadingComponent containerStyles={styles.loading} />
            ) : (
                <>
                    <View style={styles.listWrapper}>
                        {data?.tokentellerGameParticipants && (
                            <FlatList
                                ListHeaderComponent={
                                    <View style={styles.features}>
                                        <GamesStatsInfoCard
                                            containerStyle={styles.feature}
                                            onLight
                                            title={`≈ ${priceInUsd(
                                                Coin.igu,
                                                amount
                                            )} $`}
                                            amount={priceString(
                                                statisticsFormatter(
                                                    Number(amount)
                                                ),
                                                Coin.vigu
                                            )}
                                            icon={
                                                <CoinIcon
                                                    slug={Coin.vigu}
                                                    size={32}
                                                />
                                            }
                                        />
                                        <GamesStatsInfoCard
                                            onLight
                                            containerStyle={styles.feature}
                                            title={i18n.t(
                                                `HomeScreen.${MYSTERY_GAMES.luckyStrike}.keys.participants`
                                            )}
                                            amount={`${data.tokentellerGameParticipants.userCount}`}
                                            icon={
                                                <Participants
                                                    width={32}
                                                    height={32}
                                                />
                                            }
                                        />
                                    </View>
                                }
                                showsVerticalScrollIndicator={false}
                                data={
                                    data.tokentellerGameParticipants
                                        .participants || []
                                }
                                initialNumToRender={10}
                                renderItem={({ item }) => (
                                    <TokenTellerParticipantItem item={item} />
                                )}
                                keyExtractor={(item) => item.id}
                                ListEmptyComponent={
                                    <EmptyList
                                        emptyTitle={i18n.t(
                                            'general.errors.emptyErrorScreen.title'
                                        )}
                                        emptyText={i18n.t(
                                            'general.errors.emptyErrorScreen.text'
                                        )}
                                        withAnimation="cry"
                                        tailTitle
                                    />
                                }
                            />
                        )}
                    </View>
                </>
            )}
        </View>
    );
};

export default TokenTellerAllParticipantsComponent;
