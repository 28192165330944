import * as React from 'react';
import { View } from 'react-native';

import { Pet } from '@Data/Models';
import useThemedStyles from '@hooks/useThemedStyles';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import PetCollectionListItem from '../PetCollectionListItem';
import styleFile from './styles';

interface IProps {
    item: Pet;
}

const PlayNFTBlock: React.FC<IProps> = ({ item }) => {
    const styles = useThemedStyles(styleFile);
    const navigation = useNavigation();

    const handlePressPet = React.useCallback(
        (pet: Pet) => {
            navigation.navigateToPetDetails(pet);
        },
        [navigation]
    );

    return (
        <View style={styles.item} key={item?.id}>
            <PetCollectionListItem
                pet={item}
                onPressPet={() => handlePressPet(item)}
                canLvlUp={item.levelUpAvailable}
                canRankUp={item.rankUpAvailable}
                isDead={item.reviveAvailable}
                isAgeDead={item.reviveAgeAvailable}
                isNeedToFeed={item.healUpAvailable}
                navigateBackOnUpgrade={ROUTES.PLAY}
                showAge
            />
        </View>
    );
};

export default PlayNFTBlock;
