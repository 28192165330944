import * as React from 'react';

import { useTheme } from '@contexts/ThemeContext';

import DarkSvg from '../../assets/icons/wallet/biswap-dark.svg';
import Svg from '../../assets/icons/wallet/biswap.svg';

const Biswap: React.FunctionComponent = () => {
    const { isLight } = useTheme();

    return isLight ? <Svg /> : <DarkSvg />;
};

export default Biswap;
