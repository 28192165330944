import { StyleSheet } from 'react-native';

import { moderateScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            borderRadius: moderateScale(12),
            paddingVertical: 16,
            paddingLeft: 16,
            overflow: 'hidden',
        },
    });

export default styles;
