import * as React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import Icon from '@assets/icons/emptyState/howToClaimOnButtonModal.svg';
import AlertCard from '@components/AlertCard';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonKnowledgeBase from '@components/ButtonKnowledgeBase';
import ButtonsBottom from '@components/ButtonsBottom';
import ModalBottom from '@components/ModalBottom';
import { Text } from '@components/Themed';
import { Links } from '@constants/Links';
import { REWARD_UNLOCK_PERCENT } from '@contexts/Wallet/WalletHelpers';
import { isAndroid } from '@helpers/app';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onPress: () => void;
    onClose: () => void;
    modalHeight?: number | string;
    disabled: boolean;
}

const HowToClaimOnButtonModal = ({
    isVisible,
    onPress,
    onClose,
    modalHeight = 700,
    disabled,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <ModalBottom
            isVisible={isVisible}
            onClose={onClose}
            modalHeight={modalHeight}>
            <ScrollView
                style={styles.container}
                showsVerticalScrollIndicator={false}>
                <TouchableOpacity activeOpacity={1}>
                    <BlockHeader
                        type="emptyState"
                        titleComponent={
                            <>
                                {i18n.t('howToClaimOnButton.titleOne')}
                                <Text
                                    style={
                                        styles.accent
                                    }>{`${REWARD_UNLOCK_PERCENT}%`}</Text>
                                {i18n.t('howToClaimOnButton.titleTwo')}
                            </>
                        }
                        text={i18n.t('howToClaimOnButton.text', {
                            percent: REWARD_UNLOCK_PERCENT,
                        })}
                        emptyStateIcon={<Icon />}
                    />
                </TouchableOpacity>
            </ScrollView>
            <AlertCard
                text={i18n.t('howToClaimOnButton.alert')}
                containerStyle={styles.alert}
            />
            <ButtonsBottom
                disabled={disabled}
                title={i18n.t('howToClaimOnButton.button')}
                onPress={onPress}
                safe={!isAndroid}>
                {/* TODO: CHECK LINK! */}
                <ButtonKnowledgeBase
                    url={Links.availableReward}
                    customStyles={styles.link}
                />
            </ButtonsBottom>
        </ModalBottom>
    );
};

export default HowToClaimOnButtonModal;
