import * as React from 'react';

import { IconsData, iconsData } from './iconsData';

interface IProps {
    slug: string;
}

const StatusIcon = ({ slug }: IProps) => {
    return iconsData[slug as keyof IconsData].icon;
};

export default StatusIcon;
