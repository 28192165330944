import React from 'react';

import { wait } from '@helpers/helpers';

import { useHaptic } from './useHaptic';

const usePreventDoubleClick = (
    action?: () => void,
    delay = 1000,
    withHaptic = true
) => {
    const { triggerHeavy } = useHaptic();

    const [disabled, setDisabled] = React.useState(false);

    const onClick = React.useCallback(async () => {
        setDisabled(true);
        withHaptic && triggerHeavy();
        action?.();
        await wait(delay);
        setDisabled(false);
    }, [action, triggerHeavy, delay, withHaptic]);
    return {
        onClick,
        disabled,
    };
};

export default usePreventDoubleClick;
