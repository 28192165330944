import * as React from 'react';
import { FC, PropsWithChildren, createContext, useMemo, useState } from 'react';

import _ from 'lodash';

import { GetRanksResponse, Rank } from '@Data/Models';
import { GET_RANKS } from '@Data/Requests';
import { useApolloClient } from '@apollo/client';
import { getRankBySlug } from '@helpers/ranks';

import ErrorReporting from '../utils/ErrorReporting';
import { useStorePurchase } from './StorePurchaseContext';

export type RankContextType = {
    ranks: Rank[];
    ranksLoading: boolean;
    refetch: () => void;
};

export const RankContext = createContext<RankContextType>({
    ranks: [],
    ranksLoading: false,
    refetch: () => null,
});

const RankProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const { getMintPrices, isConfigured } = useStorePurchase();
    const [ranksResult, setRanksResult] = useState<GetRanksResponse | null>(
        null
    );
    const [ranksLoading, setRanksLoading] = useState<boolean>(false);
    const client = useApolloClient();

    const refetch = async () => {
        setRanksLoading(true);
        try {
            const result = await client.query<GetRanksResponse>({
                query: GET_RANKS,
                fetchPolicy: 'network-only',
            });
            setRanksResult(result.data);
        } catch (error) {
            Console.error(error);
            ErrorReporting.report(error);
        } finally {
            setRanksLoading(false);
        }
    };

    const ranks = useMemo(() => {
        const result = ranksResult?.ranks ?? [];
        return _.sortBy(result, (rank) => +rank.mintPriceUsd);
    }, [ranksResult]);

    React.useEffect(() => {
        const loadRanksStorePrices = async () => {
            const ranksIds: string[] = ranks.flatMap((r) => {
                return r.payments
                    .filter((p) => p.productId)
                    .map((p) => p.productId!);
            });
            await getMintPrices(ranksIds);
        };
        if (ranks.length && isConfigured) {
            loadRanksStorePrices();
        }
    }, [ranks, isConfigured]);

    return (
        <RankContext.Provider
            value={{
                ranks,
                ranksLoading,
                refetch,
            }}>
            {children}
        </RankContext.Provider>
    );
};

export default RankProvider;

export const useRanks = () => {
    const { ranks, refetch, ranksLoading } = React.useContext(RankContext);

    const nextRankBySlug = React.useCallback(
        (slug) => getRankBySlug(ranks, slug, 1),
        [ranks]
    );

    const rankBySlug = React.useCallback(
        (slug) => getRankBySlug(ranks, slug),
        [ranks]
    );

    return {
        ranks,
        nextRankBySlug,
        rankBySlug,
        refetch,
        ranksLoading,
    };
};
