import * as React from 'react';

import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import { useNavigation } from '../../useNavigation';
import NavigationItem from '../NavigationItem';
import stylesMain from './styles';

interface IProps {
    currentNavigation: NAVIGATION_WALLET;
}

export enum NAVIGATION_WALLET {
    MAIN = 'MAIN',
    NFT = 'NFT',
    REWARDS = 'REWARDS',
    GAMING = 'GAMING',
}

const data: NAVIGATION_WALLET[] = [
    NAVIGATION_WALLET.MAIN,
    NAVIGATION_WALLET.NFT,
    NAVIGATION_WALLET.REWARDS,
    NAVIGATION_WALLET.GAMING,
];

const NavigationWallet = ({ currentNavigation }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const handleNavigation = (item: NAVIGATION_WALLET) => {
        switch (item) {
            case NAVIGATION_WALLET.MAIN:
                navigation.navigateToCoinsWallet();
                break;
            case NAVIGATION_WALLET.NFT:
                navigation.navigateToNFTSWallet();
                break;
            case NAVIGATION_WALLET.REWARDS:
                navigation.navigateToRewards();
                break;
            case NAVIGATION_WALLET.GAMING:
                navigation.navigateToGamingWallet();
                break;
        }
    };

    return (
        <View style={styles.main}>
            <View style={styles.shadow}>
                <View style={styles.container}>
                    {data.map((item) => (
                        <NavigationItem
                            key={item}
                            onPress={() => handleNavigation(item)}
                            title={i18n.t(`navigationBar.wallet.${item}`)}
                            isActive={currentNavigation === item}
                            containerStyles={styles.item}
                        />
                    ))}
                </View>
            </View>
        </View>
    );
};
export default NavigationWallet;
