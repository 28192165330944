import { StyleSheet } from 'react-native';
import { scale, verticalScale } from 'react-native-size-matters';

import Colors from '../../../constants/Colors';
import { MAX_WINDOW_WIDTH } from '../../../contexts/DimensionsContext';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        modal: {
            margin: 0,
            padding: 0,
            maxWidth: MAX_WINDOW_WIDTH,
            alignSelf: 'center',
        },
        container: {
            flex: 1,
        },
        title: {
            color: 'white',
        },
        navbar: {
            backgroundColor: 'transparent',
            paddingVertical: verticalScale(16),
            paddingHorizontal: scale(10),
        },
        buttonContainer: {
            width: '100%',
            position: 'absolute',
            bottom: verticalScale(10),
            paddingHorizontal: scale(10),
        },
        cropper: {
            width: '100%',
        },
        background: {
            justifyContent: 'center',
            alignItems: 'center',
        },
    });

export default styles;
