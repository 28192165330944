import React from 'react';

import { UserRole, UserRoleResponse } from '@Data/Models';
import { GET_USER_ROLES } from '@Data/Requests';
import { useQuery } from '@apollo/client';
import { errorsHandler } from '@helpers/errors';
import i18n from '@i18n/i18n';

import RoleSelect from '../components/RoleSelect';

export const useRoleSelect = () => {
    const [roleValue, setRoleValue] = React.useState<UserRole>();
    const [roleError, setRoleError] = React.useState<string>('');

    const { data: roles } = useQuery<UserRoleResponse>(GET_USER_ROLES, {
        onError: (error) => errorsHandler(error, true),
    });

    const checkRole = React.useCallback(() => {
        if (!roleValue) {
            setRoleError(i18n.t('profile.adminAssignRole.error.role'));
            return false;
        }
        return true;
    }, [roleValue]);

    const handleSelect = (role: UserRole) => {
        setRoleValue(role);
    };

    const renderRoleSelect = React.useCallback(() => {
        return (
            <RoleSelect
                loading={!roles?.userRoles}
                error={roleError}
                selectedRole={roleValue}
                roles={roles?.userRoles}
                onPress={handleSelect}
            />
        );
    }, [roleError, roleValue, roles]);

    const resetRoleSelect = () => {
        setRoleValue(undefined);
        setRoleError('');
    };

    return {
        checkRole,
        resetRoleSelect,
        renderRoleSelect,
        roleError,
        roleValue,
    };
};
