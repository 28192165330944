import { GET_MYSTERY_LIKES_GAME_LAST_GAME } from '@Data/Requests';
import { useQuery } from '@apollo/client';
import { errorsHandler } from '@helpers/errors';
import { MysteryLikesGameLastGameOutputResponse } from '@models/mysteryGames';

export const useMysteryLikesGameLastGame = () => {
    const {
        loading: MysteryLikesGameLastGameLoading,
        data: MysteryLikesGameLastData,
    } = useQuery<MysteryLikesGameLastGameOutputResponse>(
        GET_MYSTERY_LIKES_GAME_LAST_GAME,
        {
            onError: (error) => errorsHandler(error),
            pollInterval: 10000,
            fetchPolicy: 'no-cache',
        }
    );

    return { MysteryLikesGameLastGameLoading, MysteryLikesGameLastData };
};
