import React, { ForwardedRef, forwardRef, useMemo } from 'react';
import { View } from 'react-native';

import { useDimensions } from '@contexts/DimensionsContext';
import { isDesktop } from '@helpers/app';
import useThemedStyles from '@hooks/useThemedStyles';

import { UnityMessage } from '../PlayToEarn/screens/playToEarnProgress/config';
import { useUnityWrapper } from './hook';
import mainStyles from './styles';

type Props = {
    uri: string;
    onMessage: (message: UnityMessage) => void;
};

export const UnityWrapper = forwardRef(
    ({ uri, onMessage }: Props, ref: ForwardedRef<HTMLIFrameElement>) => {
        const styles = useThemedStyles(mainStyles);
        const { windowHeight, windowWidth } = useDimensions();
        const containerStyle = useMemo(() => {
            if (isDesktop) {
                return {
                    height: windowHeight,
                    width: windowWidth,
                };
            }
            return styles.container;
        }, [styles.container, windowHeight, windowWidth]);

        useUnityWrapper({ onMessage });

        return (
            <View style={containerStyle}>
                <iframe
                    frameBorder={0}
                    title="unity-wrapper"
                    ref={ref}
                    height="100%"
                    width="100%"
                    src={uri}
                />
            </View>
        );
    }
);
