import * as React from 'react';

import usePersistNavigationState from '@navigation/hooks/usePersistNavigationState';
import {
    NavigationContainer as DefaultNavigationContainer,
    createNavigationContainerRef,
} from '@react-navigation/native';

import AppStateHandler from '../../components/AppState/AppStateHandler';
import NotificationProvider from '../../contexts/Notification/NotificationContext';
import i18n from '../../i18n/i18n';
import RelatedApps from '../../web-src/components/RelatedApps/index.web';
import LinkingConfiguration from '../LinkingConfiguration';
import RootNavigator from '../stacks/RootNavigator';

export const navigationRef = createNavigationContainerRef();

export default function NavigationContainer() {
    const { isReady, initialState, restoreState, onStateChange } =
        usePersistNavigationState();

    React.useEffect(() => {
        if (!isReady) {
            restoreState();
        }
    }, [isReady]);

    if (!isReady) {
        return null;
    }

    return (
        <DefaultNavigationContainer
            linking={LinkingConfiguration}
            initialState={initialState}
            onStateChange={onStateChange}
            documentTitle={{
                formatter: (options, route) =>
                    `${
                        options?.title ?? route?.name
                            ? i18n.t(`title.${route?.name}`)
                            : ``
                    }${route?.name ? ` - ` : ``}${i18n.t(`title.suffix`)}`,
            }}>
            <NotificationProvider>
                <RootNavigator />
                <AppStateHandler />
                <RelatedApps />
            </NotificationProvider>
        </DefaultNavigationContainer>
    );
}
