import React, { useMemo } from 'react';

import { Pet } from '@Data/Models';
import Button from '@components/Button';
import Icon, { ICON_NAMES } from '@components/Icons';
import RankIcon from '@components/Icons/RankIcon';
import ModalNFTRankUp from '@components/Ranks/ModalNFTRankUp';
import ModalWhatIsRank from '@components/Ranks/ModalWhatIsRank';
import { View } from '@components/Themed';
import { useRanks } from '@contexts/RanksContext';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import DetailsBlock from '../DetailsBlock';
import Styles from './styles';

interface IProps {
    pet: Pet;
}

const RankDetailsBlock = ({ pet }: IProps) => {
    const styles = useThemedStyles(Styles);

    const navigation = useNavigation();
    const { rank, rankUpAvailable } = pet;
    const { nextRankBySlug } = useRanks();

    const nextRank = useMemo(() => nextRankBySlug(rank)?.slug || rank, [rank]);

    const [modalWhatIsRank, setModalWhatIsRankVisible] = React.useState(false);
    const [isOpenDetailedInfo, setIsOpenDetailedInfo] = React.useState(false);
    const [isVisibleRankUp, setIsVisibleRankUp] = React.useState(false);

    const handleOnInfoIconPress = () => {
        setIsOpenDetailedInfo(false);
        setModalWhatIsRankVisible(true);
    };
    const closeModalWhatIsRank = () => {
        setModalWhatIsRankVisible(false);
    };

    const navigateToRanksDetails = (slug?: string) => {
        navigation.navigate(ROUTES.RANKS_DETAILED_INFO, {
            screenConfig: {
                showAll: true,
                selectRank: false,
                paginated: true,
                slug,
            },
        });
    };

    const onDetailedInfo = () => {
        closeModalWhatIsRank();
        navigateToRanksDetails();
    };

    const handleOnIconPress = () => {
        navigateToRanksDetails(rank);
    };

    const handleCloseRankUpModal = () => {
        setIsVisibleRankUp(false);
    };
    const handleOpenRankUpModal = () => {
        setIsVisibleRankUp(true);
    };

    return (
        <DetailsBlock
            title={i18n.t('detailedPet.rank.title', {
                rank: i18n.t(`ranks.list.${rank}.title`),
            })}
            description={
                rankUpAvailable
                    ? i18n.t('detailedPet.rank.textUpgrade', {
                          rank: i18n.t(`ranks.list.${rank}.title`),
                      })
                    : i18n.t('detailedPet.rank.text')
            }
            icon={<RankIcon slug={rank} size={48} />}
            onInfoIconPress={handleOnInfoIconPress}
            onIconPress={handleOnIconPress}
            highlighted={rankUpAvailable}>
            {rankUpAvailable && (
                <View style={styles.buttonWrapper}>
                    <Button
                        icon={
                            <Icon
                                name={ICON_NAMES.ARROW_UPGRADE}
                                color={styles.iconAccent.color}
                            />
                        }
                        onPress={handleOpenRankUpModal}
                        type={'outline'}
                        style={styles.buttonStyle}
                        containerStyle={styles.buttonContainerStyle}
                        textStyles={styles.rankUpButton}
                        iconColor={styles.rankUpButton.color}
                        title={i18n.t('detailedPet.rank.upgrade', {
                            rank: i18n.t(`ranks.list.${nextRank}.title`),
                        })}
                    />
                    <ModalNFTRankUp
                        fetch
                        closeAction={handleCloseRankUpModal}
                        isVisible={isVisibleRankUp}
                        pet={pet}
                        navigateBackOnUpgrade={ROUTES.COLLECTION}
                    />
                </View>
            )}
            <ModalWhatIsRank
                buttonAllRanks
                isVisible={modalWhatIsRank}
                closeModal={closeModalWhatIsRank}
                actionModal={onDetailedInfo}
            />
        </DetailsBlock>
    );
};
export default RankDetailsBlock;
