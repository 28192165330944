import * as React from 'react';

import { useMutation } from '@apollo/client';

import {
    CreateHealUpPaymentResponse,
    PaymentOptionOutput,
    Pet,
} from '../../../../Data/Models';
import { CREATE_HEALUP_PAYMENT } from '../../../../Data/Requests';
import CheckoutScreen from '../../../../components/CheckoutScreen';
import { PlayContext } from '../../../../contexts/PlayContext';
import { useWallet } from '../../../../contexts/Wallet/WalletContext';
import { isGraphqlError } from '../../../../helpers/helpers';
import { toastError } from '../../../../helpers/toastNotification';
import i18n from '../../../../i18n/i18n';
import NavigationBar from '../../../../navigation/NavigationBar';
import ROUTES from '../../../../navigation/routes';
import { useNavigation } from '../../../../navigation/useNavigation';
import { CollectionPetHealUpCheckoutProps } from '../../../../types';
import TransactionDetailsHealUp from '../components/TransactionDetailsHealUp';

const CollectionPetHealUpCheckoutScreen = ({
    route,
}: CollectionPetHealUpCheckoutProps) => {
    const { executeTransaction } = useWallet();

    const navigation = useNavigation();

    const { refetchPets } = React.useContext(PlayContext);

    const {
        pet,
        payments,
        navigateBackOnUpgrade,
        healthPoints,
        navigateBackFromCheckout,
    } = route.params;
    const [createPaymentRequest] = useMutation<CreateHealUpPaymentResponse>(
        CREATE_HEALUP_PAYMENT
    );

    const createPayment = async (
        pet: Pet,
        payment: PaymentOptionOutput,
        healthPoints: number
    ) => {
        const result = await createPaymentRequest({
            variables: {
                petId: pet.id,
                token: payment.token,
                healthPoints,
            },
        });
        return result.data?.createHealUpPayment;
    };

    const handleOnPurchase = async (payment: PaymentOptionOutput) => {
        const paymentResponse = await createPayment(pet, payment, healthPoints);
        if (!paymentResponse) {
            return null;
        }

        const result = await executeTransaction(
            paymentResponse.payment,
            paymentResponse.signedTransaction
        );

        await refetchPets(true);

        navigation.navigate(ROUTES.COLLECTION_PET_HEAL_UP_SUCCESS, {
            pet,
            transactionResult: result,
            payment: paymentResponse.payment,
            healthPoints,
            navigateBackOnUpgrade,
        });

        return result;
    };

    const handleOnError = (error: any) => {
        if (isGraphqlError(error, 'PET_WRONG_STATE')) {
            toastError(
                undefined,
                i18n.t('detailedPet.health.checkoutHealUp.errors.wrongState')
            );
            refetchPets(true);
        }
    };

    const navigateBack = React.useCallback(() => {
        if (navigateBackFromCheckout) {
            navigation.pop();
            navigation.navigate(navigateBackFromCheckout);
            return;
        }
        navigation.goBack();
    }, [navigateBackFromCheckout]);

    return (
        <>
            <NavigationBar backIcon wallet filled onBack={navigateBack} />
            <CheckoutScreen
                title={i18n.t('detailedPet.health.checkoutHealUp.title')}
                text={i18n.t('detailedPet.health.checkoutHealUp.text')}
                payments={payments}
                TransactionDetailsComponent={
                    <TransactionDetailsHealUp
                        pet={pet}
                        healthUp={healthPoints}
                    />
                }
                withConfirmation={i18n.t(
                    'detailedPet.health.checkoutHealUp.transaction',
                    {
                        name: pet.name,
                    }
                )}
                onPurchase={handleOnPurchase}
                onPurchaseError={handleOnError}
            />
        </>
    );
};

export default CollectionPetHealUpCheckoutScreen;
