import { GET_MYSTERY_LIKES_GAME_HISTORY } from '@Data/Requests';
import { useLazyQuery } from '@apollo/client';
import { MysteryLikesGameHistoryResponse } from '@models/mysteryGames';

export const useGetMysteryLikeGameHistory = () => {
    const [
        getMysteryLikesGameHistory,
        { loading: getMysteryLikesGameHistoryLoading },
    ] = useLazyQuery<MysteryLikesGameHistoryResponse>(
        GET_MYSTERY_LIKES_GAME_HISTORY,
        {
            fetchPolicy: 'network-only',
        }
    );

    return {
        getMysteryLikesGameHistory,
        getMysteryLikesGameHistoryLoading,
    };
};
