import React from 'react';

import HealAllIcon from '@assets/icons/health/healAllInfo.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import Button from '@components/Button';
import ButtonsBottom from '@components/ButtonsBottom';
import ModalBottom from '@components/ModalBottom';
import VerticalCentredContent from '@components/VerticalCentredContent';
import { isAndroid } from '@helpers/app';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import mainStyles from './styles';

interface IProps {
    isVisible: boolean;
    closeModal: () => void;
    primaryActionModal: () => void;
    secondaryActionModal?: () => void;
}

const ModalCollectionMultiplePetsHealUpInfo = ({
    isVisible,
    closeModal,
    primaryActionModal,
    secondaryActionModal,
}: IProps) => {
    const styles = useThemedStyles(mainStyles);

    return (
        <ModalBottom
            modalHeight="95%"
            isVisible={isVisible}
            onClose={closeModal}>
            <VerticalCentredContent style={styles.container}>
                <BlockHeader
                    type="emptyState"
                    emptyStateIcon={<HealAllIcon />}
                    title={i18n.t(
                        'detailedPet.health.infoMultipleHealUp.title'
                    )}
                    text={i18n.t('detailedPet.health.infoMultipleHealUp.text')}
                />
            </VerticalCentredContent>
            <ButtonsBottom
                title={i18n.t(
                    'detailedPet.health.infoMultipleHealUp.primaryButton'
                )}
                onPress={primaryActionModal}
                safe={!isAndroid}>
                {secondaryActionModal && (
                    <Button
                        onPress={secondaryActionModal}
                        title={i18n.t(
                            'detailedPet.health.infoMultipleHealUp.secondaryButton'
                        )}
                        type="text"
                        size="md"
                        textStyles={styles.buttonLeave}
                        pressedStyle={false}
                        containerStyle={styles.buttonContainer}
                    />
                )}
            </ButtonsBottom>
        </ModalBottom>
    );
};
export default ModalCollectionMultiplePetsHealUpInfo;
