import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            marginTop: verticalScale(24),
        },
        titleBlock: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: verticalScale(16),
        },
        title: {
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: scale(16),
            flex: 1,
        },
        history: {
            minHeight: 40,
        },
        flex: {
            flex: 1,
        },
    });

export default styles;
