import * as React from 'react';

import ButtonsBottom from '@components/ButtonsBottom';
import { ICON_NAMES } from '@components/Icons';
import { View } from '@components/Themed';
import UpdateAppContent from '@components/UpdateAppContent';
import { waitForNavigation } from '@helpers/helpers';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBarModal from '@navigation/NavigationBar/NavigationBarModal';
import useNavigationListeners from '@navigation/hooks/useNavigationListeners';
import { useNavigation } from '@navigation/useNavigation';

import { UpdateAppProps } from '../../../types';
import stylesMain from './styles';

const UpdateAppScreen = ({ route }: UpdateAppProps) => {
    useNavigationListeners();
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const handlePress = async () => {
        navigation.pop();
        await waitForNavigation();
        navigation.navigateToTasks(route.params?.reset);
    };

    return (
        <View style={styles.main}>
            <View style={styles.header}>
                <NavigationBarModal
                    iconName={ICON_NAMES.CLOSE}
                    onBack={() => navigation.pop()}
                    title={i18n.t('updateModal.screen.screenTitle')}
                />
            </View>

            <UpdateAppContent />
            <ButtonsBottom
                title={i18n.t('updateModal.screen.button')}
                onPress={handlePress}
                safe
            />
        </View>
    );
};

export default UpdateAppScreen;
