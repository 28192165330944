import React, { useContext, useEffect, useState } from 'react';

import * as Font from 'expo-font';

import SplashState from '../../components/AppState/SplashState';
import ErrorReporting from '../../utils/ErrorReporting';
import { assetsFonts, assetsImages, assetsLotties } from './assets';
import { AppLoadingContextType, LottieAssets, SoundsPlayToEarn } from './types';

export const AppLoadingContext = React.createContext<
    AppLoadingContextType | undefined
>(undefined);

export const useAppLoading = () => {
    const context = useContext(AppLoadingContext);
    if (context === undefined) {
        throw new Error('useAppLoading must be within AppLoadingProvider');
    }

    return context;
};

const AppLoadingProvider: React.FC<React.PropsWithChildren<{}>> = ({
    children,
}) => {
    // Lottie animations loading state
    const [loadedLotties, setLoadedLotties] = useState<
        LottieAssets | undefined
    >(undefined);

    // App ready state
    const [isAppReady, setIsAppReady] = useState<boolean>(false);
    // Splash screen loaded
    const [isSplashReady, setIsSplashReady] = useState<boolean>(false);
    // System Splash Screen hidden
    const [isSplashScreenHidden, setIsSplashScreenHidden] =
        useState<boolean>(false);
    // Splash screen animation completed
    const [isSplashAnimationComplete, setIsSplashAnimationComplete] =
        useState<boolean>(false);

    // Load other assets
    useEffect(() => {
        async function loadAssets() {
            try {
                Font.loadAsync(assetsFonts()).catch((error) => {
                    Console.error(error);
                });
                // Load images
                await assetsImages();

                const loadSyncAnimations = await assetsLotties(true);
                // Load required lotties (only for login screen)
                setLoadedLotties(loadSyncAnimations);
                // Load other lotties
                assetsLotties(false).then((data) => {
                    setLoadedLotties({
                        ...loadSyncAnimations,
                        ...data,
                    });
                });

                Console.log('[LOADING] setIsAppReady');

                setIsAppReady(true);
            } catch (error) {
                ErrorReporting.report(error);
                console.warn(error);
            }
        }

        loadAssets();
    }, []);

    // App is ready, hide splash
    useEffect(() => {
        Console.log(
            `[LOADING] useEffect[isAppReady](${isAppReady}, ${isSplashReady})`
        );
        if (isAppReady && isSplashReady) {
            setIsSplashScreenHidden(true);
        }
    }, [isAppReady, isSplashReady]);

    Console.log('[AppLoadingContext] Render');

    return (
        <AppLoadingContext.Provider
            value={{
                isAppReady,
                isSplashScreenHidden,
                isSplashReady,
                setIsSplashReady,
                isSplashAnimationComplete,
                setIsSplashAnimationComplete,
                loadedLotties,
            }}>
            {isAppReady && children}
            {!isSplashAnimationComplete && (
                <SplashState
                    isSplashScreenHidden={isSplashScreenHidden}
                    setIsSplashReady={setIsSplashReady}
                    isSplashAnimationComplete={isSplashAnimationComplete}
                    setIsSplashAnimationComplete={setIsSplashAnimationComplete}
                />
            )}
        </AppLoadingContext.Provider>
    );
};

export default AppLoadingProvider;
