import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import { scale, verticalScale } from '../../../../../helpers/dimensions';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            height: '100%',
        },
        navBg: { backgroundColor: Colors[theme].background },
        safe: { backgroundColor: Colors[theme].background },
        main: {
            flex: 1,
            paddingHorizontal: scale(20),
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
        },

        buttonWrapper: {
            paddingVertical: verticalScale(16),
            paddingHorizontal: scale(20),
        },
        energyWrapper: {
            marginBottom: verticalScale(24),
        },
        refreshButton: {
            alignSelf: 'center',
        },
    });

export default styles;
