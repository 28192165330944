import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            aspectRatio: 1,
            backgroundColor: 'transparent',
            alignItems: 'center',
            justifyContent: 'center',

            position: 'absolute',
        },
        wrapper: {
            backgroundColor: 'transparent',
        },
        wheelBorder: {
            backgroundColor: 'transparent',
            position: 'absolute',
            width: '100%',
            aspectRatio: 1,
            zIndex: 1000,
        },
        pin: {
            backgroundColor: 'transparent',
            position: 'absolute',
            width: '100%',
            alignItems: 'center',
            top: -20,
            zIndex: 1001,
        },
        prize: {
            width: 56,
            height: 56,
            borderRadius: 100,
            backgroundColor: Colors[theme].fillPrimaryMinimal,
            alignItems: 'center',
            overflow: 'hidden',
            justifyContent: 'center',
        },
        opacity: {
            opacity: 0.5,
        },

        buttonWrapper: {
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            position: 'absolute',

            aspectRatio: 1,
            zIndex: 10001,
        },
        buttonIcon: {
            backgroundColor: 'transparent',
        },
        texts: {
            backgroundColor: 'transparent',
            position: 'absolute',
            zIndex: 1,
            width: '100%',
            alignContent: 'center',
            alignItems: 'center',
        },
        textColor: {
            color: '#5D7989',
        },
        amountWrapper: {
            marginTop: verticalScale(6),
            backgroundColor: Colors[theme].alwaysOneColor.background,
            flexDirection: 'row',
            alignItems: 'center',
            borderRadius: 50,
            paddingHorizontal: 6,
            paddingVertical: 4,
        },
        amountText: {
            color: '#5D7989',
            marginLeft: 4,

            maxWidth: 130,
        },
        spinning: {
            color: '#5D7989',
            ...font({
                size: 18,
                height: 26,
                weight: '700',
            }),
        },
        view: {
            backgroundColor: 'transparent',
            flex: 1,
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
        },
    });

export default styles;
