import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        shadow: {
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].shadow.default,
            marginBottom: verticalScale(16),
        },
        shadowActive: {
            backgroundColor: Colors[theme].shadow.selected,
        },
        active: {
            borderColor: Colors[theme].border.accent,
        },
        container: {
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(12),
            marginBottom: verticalScale(4),
            borderWidth: 1,
        },
    });

export default styles;
