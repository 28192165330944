import { IconsData, iconsData } from './iconsData';

interface IProps {
    slug: string;
}

const StakingCardIcon = ({ slug }: IProps) => {
    return iconsData[slug as keyof IconsData];
};

export default StakingCardIcon;
