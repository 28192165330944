import * as React from 'react';

import { StakingPlanOutput } from '@Data/Models';
import StakingCardIcon from '@components/Icons/StakingCardIcon';
import { Text, View } from '@components/Themed';
import { StakingCoin } from '@contexts/StakingContext';
import { dateFormatterYear, getFinishAt } from '@helpers/time';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    data: StakingPlanOutput;
    coin: StakingCoin;
    stakingBoost: number;
    stakingAmount: string | undefined;
    current: string | undefined;
}

const StakingCardSummary = ({
    data,
    coin,
    stakingBoost,
    stakingAmount,
    current,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { color: backgroundColor, name, durationDays: days } = data.plan;

    const summary = React.useMemo(() => {
        if (current) {
            return (
                Number(balanceFromWei(current).valueLong) +
                Number(stakingAmount)
            );
        }
        return stakingAmount || 0;
    }, [stakingAmount, current]);

    return (
        <View style={[styles.container, { backgroundColor }]}>
            <View style={styles.coin}>{<StakingCardIcon slug={coin} />}</View>
            <View style={styles.inner}>
                <View style={styles.left}>
                    <Text style={styles.title}>{name}</Text>
                    <Text style={styles.subTitle}>
                        {i18n.t('staking.general.days', {
                            days,
                        })}
                    </Text>
                    <Text style={[styles.title, styles.gap]}>
                        {i18n.t('staking.general.dailyBoost')}
                    </Text>
                    <Text style={styles.subTitle}>{`${stakingBoost}%`}</Text>
                </View>
                <View style={styles.right}>
                    <Text style={styles.title}>
                        {i18n.t('staking.general.ended')}
                    </Text>
                    <Text style={styles.subTitle}>
                        {dateFormatterYear(getFinishAt(days))}
                    </Text>
                    <Text style={[styles.title, styles.gap]}>
                        {i18n.t('staking.general.summary')}
                    </Text>
                    <Text
                        style={styles.subTitle}
                        ellipsizeMode="middle"
                        numberOfLines={1}>
                        {`${summary} ${coin}`}
                    </Text>
                </View>
            </View>
        </View>
    );
};
export default StakingCardSummary;
