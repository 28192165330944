import * as React from 'react';
import { useEffect, useState } from 'react';
import { SvgProps } from 'react-native-svg';

import AlertCard, { AlertCardType } from '@components/AlertCard';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import Checkbox from '@components/Checkbox';
import Colors from '@constants/Colors';
import { useTheme } from '@contexts/ThemeContext';
import { isWeb } from '@helpers/app';
import useThemedStyles from '@hooks/useThemedStyles';

import CustomModalBackdrop from '../../web-src/components/CustomModalBackdrop';
import Button, { ButtonType } from '../Button';
import ExtendedModal from '../ExtendedModal';
import { View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    icon: React.ReactElement<SvgProps>;
    titleText: string;
    infoText: string;
    firstButtonText?: string;
    secondButtonText?: string;
    onFirstButtonClick?: () => void;
    onSecondButtonClick?: () => void;
    firstButtonType?: ButtonType;
    isNestedModal?: boolean;
    useFullScreenOverlay?: boolean;
    firstButtonLoading?: boolean;
    firstButtonDisabled?: boolean;
    additionalText?: string;
    checkboxText?: string;
    disableFirstButtonIfNotCheckboxEnabled?: boolean;
    alertText?: string;
    alertType?: AlertCardType;
}

const CustomModal = ({
    isVisible,
    icon,
    titleText,
    infoText,
    firstButtonText,
    secondButtonText,
    onFirstButtonClick,
    onSecondButtonClick,
    firstButtonType,
    isNestedModal = false,
    useFullScreenOverlay = false,
    firstButtonLoading,
    firstButtonDisabled,
    additionalText,
    checkboxText,
    disableFirstButtonIfNotCheckboxEnabled,
    alertText,
    alertType = 'warning',
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { theme } = useTheme();
    const [checkboxEnabled, setCheckboxEnabled] = useState<boolean>(false);

    useEffect(() => {
        if (!isVisible) {
            setCheckboxEnabled(false);
        }
    }, [isVisible]);

    return (
        <ExtendedModal
            style={styles.modal}
            isVisible={isVisible}
            backdropColor={Colors[theme].modal.modalOverlay}
            backdropOpacity={Colors[theme].modal.opacity}
            customBackdrop={isWeb && <CustomModalBackdrop />}
            isNestedModal={isNestedModal}
            useFullScreenOverlay={useFullScreenOverlay}
            withToasts>
            <View style={styles.shadow}>
                <View style={styles.container}>
                    <BlockHeader
                        title={titleText}
                        text={infoText}
                        type="emptyState"
                        emptyStateIcon={icon}
                        additionalText={additionalText}
                    />
                    {alertText && (
                        <View style={styles.alert}>
                            <AlertCard text={alertText} type={alertType} />
                        </View>
                    )}
                    {checkboxText && (
                        <View style={styles.checkbox}>
                            <Checkbox
                                enabled={checkboxEnabled}
                                label={checkboxText}
                                onPress={() =>
                                    setCheckboxEnabled(!checkboxEnabled)
                                }
                            />
                        </View>
                    )}
                    <View style={styles.buttonWrapper}>
                        {firstButtonText && onFirstButtonClick && (
                            <Button
                                title={firstButtonText}
                                onPress={onFirstButtonClick}
                                debouncedPress
                                loading={firstButtonLoading}
                                disabled={
                                    firstButtonDisabled ||
                                    (disableFirstButtonIfNotCheckboxEnabled &&
                                        !checkboxEnabled)
                                }
                                type={firstButtonType}
                            />
                        )}
                        {secondButtonText && onSecondButtonClick && (
                            <Button
                                onPress={onSecondButtonClick}
                                title={secondButtonText}
                                type="outline"
                                size="md"
                                debouncedPress
                                textStyles={styles.buttonLeave}
                                style={styles.buttonGap}
                                containerStyle={styles.buttonContainer}
                            />
                        )}
                    </View>
                </View>
            </View>
        </ExtendedModal>
    );
};
export default CustomModal;
