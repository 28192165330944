import * as React from 'react';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

import Icon, { ICON_NAMES } from '../../../../../components/Icons';
import PlayEnergy from '../../../../../components/PlayEnergy';
import { Text, View } from '../../../../../components/Themed';
import { useUser } from '../../../../../contexts/UserContext';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import EnergyBlock from '../EnergyBlock';
import stylesMain from './styles';

interface IProps {
    earnedEnergy: number;
    maxEnergy: number;
    containerStyles?: StyleProp<ViewStyle>;
}

const SuccessTaskEnergyBlock = ({
    earnedEnergy,
    maxEnergy,
    containerStyles,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { user, reloadUser } = useUser();

    React.useEffect(() => {
        reloadUser();
    }, []);

    const userEnergy = React.useMemo(() => user?.energy || 0, [user]);

    return (
        <View style={[styles.container, containerStyles]}>
            <View style={styles.top}>
                <Text style={styles.text}>
                    {i18n.t('successTaskEnergyBlock.text')}
                </Text>
                <View style={styles.energyCollected}>
                    <Text style={styles.textEnergyCollected}>
                        {i18n.t('successTaskEnergyBlock.collectedEnergy', {
                            earnedEnergy,
                            maxEnergy,
                        })}
                    </Text>
                </View>
            </View>
            <PlayEnergy energy={userEnergy} />
        </View>
    );
};

export default SuccessTaskEnergyBlock;
