import * as React from 'react';

import HowMysteryLikesWorkScreen from '../../screens/TabBar/Home/MysteryLikesFlow/HowMysteryLikesWork/HowMysteryLikesWorkScreen';
import MysteryLikesCreateGameInputScreen from '../../screens/TabBar/Home/MysteryLikesFlow/MysteryLikesCreateGameInput/MysteryLikesCreateGameInputScreen';
import MysteryLikesCreateGameSuccessScreen from '../../screens/TabBar/Home/MysteryLikesFlow/MysteryLikesCreateGameSuccess/MysteryLikesCreateGameSuccessScreen';
import MysteryLikesCreateGameWelcomeScreen from '../../screens/TabBar/Home/MysteryLikesFlow/MysteryLikesCreateGameWelcome/MysteryLikesCreateGameWelcomeScreen';
import MysteryLikesSelectRangeScreen from '../../screens/TabBar/Home/MysteryLikesFlow/MysteryLikesSelectRange/MysteryLikesSelectRangeScreen';
import MysteryLikesSelectRangeFinishScreen from '../../screens/TabBar/Home/MysteryLikesFlow/MysteryLikesSelectRangeFinish/MysteryLikesSelectRangeFinishScreen';
import WelcomeHowMysteryLikesWorkScreen from '../../screens/TabBar/Home/MysteryLikesFlow/WelcomeHowMysteryLikesWork/WelcomeHowMysteryLikesWorkScreen';
import ROUTES from '../routes';
import { MysteryLikesTabsNavigator } from './MysteryLikesTabsNavigator';

const MysteryLikesGroup = (RootStack: any) => {
    return (
        <>
            <RootStack.Screen
                name={ROUTES.WELCOME_HOW_MYSTERY_LIKES_WORK}
                component={WelcomeHowMysteryLikesWorkScreen}
            />
            <RootStack.Screen
                name={ROUTES.HOW_MYSTERY_LIKES_WORK}
                component={HowMysteryLikesWorkScreen}
                options={{
                    presentation: 'modal',
                    headerShown: false,
                }}
            />
            <RootStack.Screen
                name={ROUTES.MYSTERY_LIKES_MAIN}
                component={MysteryLikesTabsNavigator}
            />
            <RootStack.Screen
                name={ROUTES.MYSTERY_LIKES_CREATE_GAME_WELCOME}
                component={MysteryLikesCreateGameWelcomeScreen}
            />
            <RootStack.Screen
                name={ROUTES.MYSTERY_LIKES_CREATE_GAME_INPUT}
                component={MysteryLikesCreateGameInputScreen}
            />
            <RootStack.Screen
                name={ROUTES.MYSTERY_LIKES_CREATE_GAME_SUCCESS}
                options={{ gestureEnabled: false }}
                component={MysteryLikesCreateGameSuccessScreen}
            />
            <RootStack.Screen
                name={ROUTES.MYSTERY_LIKES_SELECT_RANGE}
                options={{ gestureEnabled: false }}
                component={MysteryLikesSelectRangeScreen}
            />
            <RootStack.Screen
                name={ROUTES.MYSTERY_LIKES_SELECT_RANGE_FINISH}
                component={MysteryLikesSelectRangeFinishScreen}
                options={{ gestureEnabled: false }}
            />
        </>
    );
};

export default MysteryLikesGroup;
