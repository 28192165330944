import * as React from 'react';
import { SafeAreaView } from 'react-native';

import { Rank } from '@Data/Models';
import Button from '@components/Button';
import ButtonsBottom from '@components/ButtonsBottom';
import Carousel from '@components/Carousel';
import CarouselScrollView from '@components/Carousel/components/CarouselScrollView';
import CurrentLabel from '@components/CurrentLabel';
import { ICON_NAMES } from '@components/Icons';
import RankIcon from '@components/Icons/RankIcon';
import RankDetailedInfo from '@components/Ranks/RankDetailedInfo';
import { View } from '@components/Themed';
import { useConfig } from '@contexts/ConfigContext';
import { useDimensions } from '@contexts/DimensionsContext';
import { useRanks } from '@contexts/RanksContext';
import { waitForNavigation } from '@helpers/helpers';
import {
    formatBonusReward,
    formatDailyReward,
    getDailyFoodConsumption,
    isAvailableForMint,
    rankIndexBySlug,
    ranksConfig,
} from '@helpers/ranks';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBarModal from '@navigation/NavigationBar/NavigationBarModal';
import useNavigationListeners from '@navigation/hooks/useNavigationListeners';
import { useNavigation } from '@navigation/useNavigation';

import { RanksDetailedInfoProps } from '../../../types';
import MintPriceBackground from '../../MintNFT/components/MintPriceBackground';
import stylesMain from './styles';

const RanksDetailedInfoScreen = ({ route }: RanksDetailedInfoProps) => {
    useNavigationListeners();
    const { ranks, ranksLoading, refetch } = useRanks();
    const { windowWidth } = useDimensions();
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const { canUsePayments } = useConfig();
    const { screenConfig } = route.params;

    const [snapItem, setSnapItem] = React.useState(0);
    const ranksData = React.useMemo(() => {
        if (screenConfig.single) {
            const rank = ranks.find((r) => r.slug === screenConfig.slug);
            if (rank) {
                return [rank];
            }
        }
        return ranks;
    }, [screenConfig, ranks]);

    React.useEffect(() => {
        refetch();
    }, []);

    React.useEffect(() => {
        if (ranksData) {
            const index = rankIndexBySlug(ranksData, screenConfig.slug || '');
            setSnapItem(index === -1 ? 0 : index);
        }
    }, [ranksData, screenConfig]);

    const isAvailable = React.useMemo(() => {
        if (screenConfig.single && ranksData.length) {
            return isAvailableForMint(ranksData[snapItem], canUsePayments);
        }

        return isAvailableForMint(ranksData[snapItem], canUsePayments);
    }, [ranksData, snapItem, screenConfig, canUsePayments]);

    const startItem = React.useMemo(() => {
        if (ranksData.length) {
            const index = rankIndexBySlug(ranksData, screenConfig.slug || '');
            return index === -1 ? 0 : index;
        }
    }, [screenConfig, ranksData]);

    const handleSelectRank = React.useCallback(() => {
        navigation.navigateToMint({
            rank: ranksData[snapItem],
            goNext: true,
        });
    }, [snapItem, ranksData]);

    const handleMintRank = React.useCallback(async () => {
        navigation.pop();
        await waitForNavigation();
        navigation.navigateToMint({
            rank: ranksData[snapItem],
            skip: true,
        });
    }, [snapItem, ranksData]);

    const renderItem = (rank: Rank) => {
        const {
            slug,
            dailyUnlockRate,
            baseIgupRewardWei,
            rewardConsumptionPercentage,
            maxAgeDays,
        } = rank;

        const isCurrentRank = slug === route.params.screenConfig.slug;
        const isFreeRank = slug === 'free';
        const showPrice =
            isFreeRank || isAvailableForMint(rank, canUsePayments);

        return (
            <CarouselScrollView
                style={styles.itemWrapperWeb}
                contentContainerStyle={styles.itemWrapper}>
                <View style={styles.imgWrapper}>
                    <RankIcon size={120} slug={slug} />
                </View>
                {screenConfig.showAll ? (
                    isCurrentRank && (
                        <View style={styles.currentLabel}>
                            <CurrentLabel
                                text={i18n.t(
                                    'ranks.ranksDetailedInfoScreen.current'
                                )}
                                containerStyle={styles.currentRank}
                            />
                        </View>
                    )
                ) : (
                    <>
                        {showPrice && (
                            <MintPriceBackground
                                rank={rank}
                                iconSize={24}
                                textStyle={styles.textStyle}
                                customStyle={styles.mintPriceContainer}
                            />
                        )}
                    </>
                )}
                <RankDetailedInfo
                    maxAge={maxAgeDays}
                    rankSlug={slug}
                    title={i18n.t('ranks.rankLongTitle', {
                        rank: i18n.t(`ranks.list.${slug}.title`),
                    })}
                    text={
                        isFreeRank ? i18n.t(`ranks.list.${slug}.infoText`) : ''
                    }
                    reward={formatDailyReward(baseIgupRewardWei)}
                    rate={dailyUnlockRate}
                    consumption={getDailyFoodConsumption(
                        rewardConsumptionPercentage
                    )}
                    unlockReward={formatBonusReward(
                        dailyUnlockRate,
                        baseIgupRewardWei
                    )}
                />
            </CarouselScrollView>
        );
    };

    return (
        <View style={styles.main}>
            <SafeAreaView style={styles.safeArea}>
                <NavigationBarModal
                    iconName={ICON_NAMES.CLOSE}
                    containerStyle={styles.header}
                />
                {!ranksLoading && (
                    <>
                        <Carousel
                            data={ranksData}
                            sliderWidth={windowWidth}
                            itemWidth={windowWidth}
                            renderItem={(item) => renderItem(item)}
                            fullHeight
                            pagination={screenConfig.paginated}
                            start
                            centredVertical
                            insideScrollView
                            onSnapToItem={(index: number) => setSnapItem(index)}
                            startIndex={startItem}
                        />
                        {screenConfig.selectRank && (
                            <View style={styles.buttonWrapper}>
                                <Button
                                    disabled={
                                        !ranksData[snapItem] || !isAvailable
                                    }
                                    onPress={handleSelectRank}
                                    title={i18n.t(
                                        'ranks.ranksDetailedInfoScreen.button'
                                    )}
                                />
                            </View>
                        )}
                        {screenConfig.fromLootBox && (
                            <ButtonsBottom
                                title={i18n.t(
                                    'ranks.ranksDetailedInfoScreen.button'
                                )}
                                onPress={handleMintRank}
                                disabled={!isAvailable}
                                containerStyle={styles.buttonWrapper}
                            />
                        )}
                    </>
                )}
            </SafeAreaView>
        </View>
    );
};

export default RanksDetailedInfoScreen;
