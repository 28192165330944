import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../../../helpers/dimensions';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            backgroundColor: Colors[theme].background,
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            paddingHorizontal: scale(8),
            paddingBottom: verticalScale(8),
            paddingTop: verticalScale(20),
            marginBottom: verticalScale(2),
        },
        shadow: {
            width: '48.5%',
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].shadow.default,
            marginBottom: verticalScale(16),
        },
        image: {
            width: '100%',
            aspectRatio: 1,
            marginBottom: verticalScale(20),
        },
        rankIcon: {
            padding: scale(4),
            borderRadius: 1000,
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            position: 'absolute',
            zIndex: 1,
            top: verticalScale(6),
            left: scale(6),
        },
    });

export default styles;
