import * as React from 'react';
import { ScrollView } from 'react-native';

import ICON from '@assets/icons/emptyState/provablyFair.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import { ICON_NAMES } from '@components/Icons';
import { View } from '@components/Themed';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';
import NavigationBarModal from '@navigation/NavigationBar/NavigationBarModal';
import useNavigationListeners from '@navigation/hooks/useNavigationListeners';
import { useNavigation } from '@navigation/useNavigation';

import QuantumSpinProbablyFairAlgorithm from '../../components/QuantumSpinProbablyFairAlgorithm';
import QuantumSpinProbablyFairHistory from '../../components/QuantumSpinProbablyFairHistory';
import QuantumSpinProbablyFairInfo from '../../components/QuantumSpinProbablyFairInfo';
import QuantumSpinProbablyFairVerificationExample from '../../components/QuantumSpinProbablyFairVerificationExample';
import stylesMain from './styles';

const game = MYSTERY_GAMES.quantumSpin;

const QuantumSpinProbablyFairScreen = () => {
    useNavigationListeners();

    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const handleBack = () => {
        navigation.pop();
    };

    return (
        <View style={styles.main}>
            <View style={styles.header}>
                <NavigationBarModal
                    iconName={ICON_NAMES.CLOSE}
                    title={i18n.t(
                        `HomeScreen.${game}.QuantumSpinProvablyFair.title`
                    )}
                    onBack={handleBack}
                    titleStyle={styles.title}
                />
            </View>
            <ScrollView
                showsVerticalScrollIndicator={false}
                style={styles.container}>
                <BlockHeader
                    emptyStateIcon={<ICON />}
                    type={'emptyState'}
                    title={i18n.t(
                        `HomeScreen.${game}.QuantumSpinProvablyFair.title`
                    )}
                    text={i18n.t(
                        `HomeScreen.${game}.QuantumSpinProvablyFair.text`
                    )}
                />
                <QuantumSpinProbablyFairInfo />
                <QuantumSpinProbablyFairHistory />
                <QuantumSpinProbablyFairAlgorithm />
                <QuantumSpinProbablyFairVerificationExample />
            </ScrollView>
        </View>
    );
};

export default QuantumSpinProbablyFairScreen;
