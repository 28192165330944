import * as React from 'react';

import { SHOW_UPDATE } from '@components/UpdateAppContent/updates';
import { useAppLoading } from '@contexts/AppLoading/AppLoadingContext';
import { useAppState } from '@contexts/AppStateContext';
import { useModal } from '@contexts/ModalContext';
import { useUser } from '@contexts/UserContext';
import { appVersion, isWeb } from '@helpers/app';
import { dateDiffInMinutes } from '@helpers/time';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import AsyncStorage from '@react-native-async-storage/async-storage';

import AnimatedBannerUpdate from '../screens/TabBar/Play/components/AnimatedBannerUpdate';
import { useVisibleHook } from './useVisibleHook';

const config = {
    delayForNewUser: 240,
};

export const useUpdateModal = () => {
    const navigation = useNavigation();
    const { user } = useUser();
    const { isUpdateRequired } = useAppState();
    const { isSplashAnimationComplete } = useAppLoading();
    const { isVisible: isGlobalModalVisible } = useModal();
    const { open, close, isVisible } = useVisibleHook();

    const storageKey = React.useMemo(
        () => `updateBanner-${user?.id}`,
        [user?.id]
    );

    const canShow = React.useCallback(async () => {
        if (
            !isSplashAnimationComplete ||
            isUpdateRequired ||
            isGlobalModalVisible ||
            !SHOW_UPDATE
        ) {
            return false;
        }

        const status = await AsyncStorage.getItem(storageKey);

        return appVersion !== status;
    }, [
        isSplashAnimationComplete,
        isUpdateRequired,
        isGlobalModalVisible,
        storageKey,
    ]);

    const setShown = React.useCallback(async () => {
        if (storageKey) {
            await AsyncStorage.setItem(storageKey, appVersion);
        }
    }, [storageKey]);

    React.useEffect(() => {
        const check = async () => {
            if (!user) {
                return;
            }

            if (isWeb) {
                return;
            }

            const status = await canShow();
            if (status) {
                if (
                    dateDiffInMinutes(new Date(), user.createdAt) <
                    config.delayForNewUser
                ) {
                    setShown();
                    return;
                }
                open();
                setShown();
            }
        };

        check();
    }, [canShow, user]);

    const handleOnPress = React.useCallback(() => {
        close();
        navigation.navigate(ROUTES.UPDATE_APP);
    }, []);

    const renderUpdateBanner = React.useCallback(() => {
        if (isWeb) {
            return null;
        }

        return (
            <AnimatedBannerUpdate
                isVisible={isVisible}
                onCloseButton={close}
                onPress={handleOnPress}
            />
        );
    }, [isVisible, isWeb]);

    return { renderUpdateBanner };
};
