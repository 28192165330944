import * as React from 'react';
import { FlatList } from 'react-native';

import _ from 'lodash';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import FlowStepIndicator, {
    STEP_INDICATOR_FLOWS,
} from '@components/FlowStepIndicator';
import { STAKING_STAGE_NAME } from '@components/FlowStepIndicator/flows/stakingStages';
import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';

import { StakingPlansProps } from '../../../../types';
import StakingCardButton from '../components/StakingCardButton/StakingCardButton';
import { useStakingPlansScreen } from './hook';
import stylesMain from './styles';

const StakingPlansScreen = ({ route }: StakingPlansProps) => {
    const styles = useThemedStyles(stylesMain);

    const {
        stakingCoin,
        navigateToStakingInputScreen,
        handleInfoPress,
        stakingPlans,
        currentStakingPlan,
        stageFill,
        handleBack,
        onPlanPress,
        selectedPlan,
        isIguDrop,
    } = useStakingPlansScreen(route.params?.plan);

    return (
        <View style={styles.main}>
            <NavigationBar
                onBack={handleBack}
                filled={false}
                backIcon="leftArrow"
                title={i18n.t('staking.plansScreen.title')}
            />
            <FlowStepIndicator
                flow={STEP_INDICATOR_FLOWS.STAKING}
                stage={STAKING_STAGE_NAME.STEP_TWO}
                stageFill={stageFill}
            />

            <FlatList
                style={styles.container}
                ListHeaderComponent={
                    <BlockHeader
                        title={i18n.t('staking.plansScreen.headerTitle')}
                        text={i18n.t('staking.plansScreen.headerText')}
                        type={'primary'}
                        textInfo={i18n.t('staking.plansScreen.headerInfoText')}
                        textInfoOnPress={handleInfoPress}
                        containerStyle={styles.header}
                    />
                }
                showsVerticalScrollIndicator={false}
                data={stakingPlans}
                keyExtractor={(item) => item.plan.id}
                renderItem={({ item, index }) => (
                    <StakingCardButton
                        activePlan={currentStakingPlan}
                        coin={stakingCoin}
                        onPress={onPlanPress}
                        data={item}
                        selectedPlan={selectedPlan}
                        isLast={
                            stakingPlans
                                ? index === stakingPlans?.length - 1 &&
                                  isIguDrop
                                : false
                        }
                    />
                )}
            />

            <ButtonsBottom
                title={i18n.t('staking.welcomeScreen.button')}
                onPress={navigateToStakingInputScreen}
                safe
                disabled={!selectedPlan}
            />
        </View>
    );
};

export default StakingPlansScreen;
