import * as React from 'react';
import { useCallback, useState } from 'react';
import { StyleProp, ViewProps } from 'react-native';

import FlagIcons from '@components/Icons/FlagIcons';
import Selector from '@components/Selector';
import { Text, View } from '@components/Themed';
import useLanguage from '@hooks/useLanguage';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n, { LANGUAGES, setLanguage } from '@i18n/i18n';

import { ICON_NAMES } from '../Icons';
import LanguageSelectorModal from './LanguageSelectorModal';
import stylesMain from './styles';

interface IProps {
    containerStyle?: StyleProp<ViewProps>;
    onChange: () => Promise<void>;
    isLoading: boolean;
}

const LanguageSelector = ({ containerStyle, onChange, isLoading }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { locale, temporarySaveLanguage, setLocale } = useLanguage();

    const [selectedLang, setSelectedLang] = useState<string>(locale);

    const handleOnSelect = useCallback(
        (lang: string) => {
            if (selectedLang === lang) {
                return;
            }
            setSelectedLang(lang);
        },
        [selectedLang]
    );

    const { isVisible, open, close } = useVisibleHook();

    const onClose = () => {
        close();
        setSelectedLang(locale);
    };

    const changeLang = useCallback(async () => {
        await temporarySaveLanguage(selectedLang, true);
        await setLanguage(selectedLang);
        setLocale(selectedLang);
        await onChange();
        close();
    }, [selectedLang]);

    return (
        <View style={containerStyle}>
            <Selector
                containerStyle={styles.selector}
                iconName={ICON_NAMES.ARROW_DROP_DOWN}
                content={
                    <View style={styles.content}>
                        <FlagIcons slug={locale} size={24} />
                        <Text style={styles.title}>
                            {i18n.t(
                                `profile.languageSettings.options.${locale}.name`
                            )}
                        </Text>
                    </View>
                }
                onPress={open}>
                <LanguageSelectorModal
                    isVisible={isVisible}
                    selectedLang={selectedLang}
                    langs={LANGUAGES}
                    onSelect={handleOnSelect}
                    onPress={changeLang}
                    close={onClose}
                    disabled={selectedLang === locale}
                    isLoading={isLoading}
                />
            </Selector>
        </View>
    );
};
export default LanguageSelector;
