import { Asset } from 'expo-asset';

export const getBlob = async (fileUri: string) => {
    const resp = await fetch(fileUri);
    return resp.blob();
};

export const uploadImage = async (uploadUrl: string, data: string) => {
    const imageBody = await getBlob(data);

    return fetch(uploadUrl, {
        method: 'PUT',
        body: imageBody,
    });
};

export const prefetchImage = async (url: string | string[]) => {
    if (Array.isArray(url)) {
        return Promise.all(url.map(async (val) => Asset.loadAsync(val)));
    }

    return Asset.loadAsync(url);
};

// Takes a data URI and returns the Data URI corresponding to the resized image at the wanted size.
function resizeDataURL(dataUrl: string, wantedWidth: number) {
    return new Promise(async function (resolve) {
        // We create an image to receive the Data URI
        const img = document.createElement('img');

        // When the event "onload" is triggered we can resize the image.
        img.onload = () => {
            // We create a canvas and get its context.
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            const imageWidth = img.width;
            const imageHeight = img.height;
            let canvasWidth;
            let canvasHeight;

            // We set the dimensions at the max size only if bigger than our size
            if (imageWidth > wantedWidth) {
                canvasWidth = wantedWidth;
                canvasHeight = imageHeight * (wantedWidth / imageWidth);
            } else {
                canvasWidth = imageWidth;
                canvasHeight = imageHeight;
            }

            canvas.width = canvasWidth;
            canvas.height = canvasHeight;

            // We resize the image with the canvas method drawImage();
            ctx?.drawImage(img, 0, 0, canvasWidth, canvasHeight);

            const dataURI = canvas.toDataURL();

            // This is the return of the Promise
            resolve(dataURI);
        };

        // We put the Data URI in the image's src attribute
        img.src = dataUrl;
    });
}

export function getRandomFilename() {
    return `Image_${Date.now()}.png`;
}

export function getFileNameFromUrl(url: string) {
    return url.substring(url.lastIndexOf('/') + 1);
}

export function downloadCanvas(canvas: HTMLCanvasElement) {
    const link = document.createElement('a');
    link.download = getRandomFilename();
    canvas.toBlob((blob) => {
        if (blob) {
            link.href = URL.createObjectURL(blob);
            link.click();
        }
    });
}

export function getBase64Url(
    base64ImageData: string,
    contentType = 'image/png'
) {
    const byteCharacters = atob(
        base64ImageData.substr(`data:${contentType};base64,`.length)
    );
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });

    return URL.createObjectURL(blob);
}

export function getSizeInMb(size: number) {
    return (size / 1024 / 1024).toFixed(1);
}

export function getSizeInBytes(size: number) {
    return size * 1024 * 1024;
}

export function getFileSizeFromBase64(image: string | undefined) {
    if (!image) {
        return 0;
    }

    let y = 1;
    if (image.endsWith('==')) {
        y = 2;
    }
    const size = image.length * (3 / 4) - y;
    return size.toFixed(2);
}
