import * as React from 'react';
import { useMemo } from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import {
    DAILY_REWARD_STATUS,
    RewardsTransactionHistoryResponseItem,
} from '@Data/Models';
import Unlock from '@assets/icons/dailyRewards/unlock.svg';
import PvpBonus from '@assets/icons/pvpBonus/pvpBonus.svg';
import CardFeatureInfoButton from '@components/CardFeatureInfoButton';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { isAndroid } from '@helpers/app';
import { priceStringWithUSD } from '@helpers/payments';
import { balanceFromWei, roundDownSignificantDigits } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { IguWalletTransaction } from '@models/gamingWallet';
import { useNavigation } from '@navigation/useNavigation';

import Animation from '../Animation';
import BlockHeader from '../BlockHeader/BlockHeader';
import ButtonsBottom from '../ButtonsBottom';
import CardFeatureInfo from '../CardFeatureInfo/CardFeatureInfo';
import CoinIcon from '../Icons/CoinIcon';
import ModalBottom from '../ModalBottom';
import { View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onPress: () => void;
    onClose: () => void;
    modalHeight?: number | string;
    lastTransaction: RewardsTransactionHistoryResponseItem | undefined;
    pvpBonus: IguWalletTransaction | undefined;
    isDailyReward: boolean;
    isPvpBonus: boolean;
    loading: boolean;
}

const ModalDailyRewards = ({
    isVisible,
    onPress,
    onClose,
    modalHeight = '100%',
    lastTransaction,
    pvpBonus,
    isDailyReward,
    isPvpBonus,
    loading,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const navigation = useNavigation();

    const { priceInUsd } = useWallet();

    const status: DAILY_REWARD_STATUS = useMemo(() => {
        if (isDailyReward && isPvpBonus) {
            return DAILY_REWARD_STATUS.Both;
        }
        if (isDailyReward) {
            return DAILY_REWARD_STATUS.Daily;
        }
        if (isPvpBonus) {
            return DAILY_REWARD_STATUS.Bonus;
        }
        return DAILY_REWARD_STATUS.Unknown;
    }, [isDailyReward, isPvpBonus]);

    const bonus = React.useMemo(
        () =>
            priceStringWithUSD(
                priceInUsd,
                pvpBonus?.iguAmountWei,
                Coin.vigu,
                Coin.igu
            ),
        [pvpBonus, priceInUsd]
    );

    const handleNavigateRewardsWallet = () => {
        onPress();
        navigation.navigateToRewards();
    };

    const handleNavigateGamingWallet = () => {
        onPress();
        navigation.navigateToGaming();
    };

    const handleButtonPress = React.useCallback(() => {
        if (status === DAILY_REWARD_STATUS.Daily) {
            return handleNavigateRewardsWallet();
        }
        if (status === DAILY_REWARD_STATUS.Bonus) {
            return handleNavigateGamingWallet();
        }
    }, [status]);

    const availableAmount = useMemo(() => {
        if (!lastTransaction?.childTransactions) {
            return 0;
        }
        return balanceFromWei(
            lastTransaction.childTransactions.find((item) => !item.locked)
                ?.igupAmountWei
        ).valueLong;
    }, [lastTransaction]);

    const totalAmount = useMemo(() => {
        if (!lastTransaction?.childTransactions) {
            return 0;
        }

        return balanceFromWei(lastTransaction?.igupAmountWei).valueLong;
    }, [lastTransaction]);

    const restAmount = useMemo(() => {
        if (!lastTransaction?.childTransactions) {
            return 0;
        }

        return roundDownSignificantDigits(
            Number(totalAmount) - Number(availableAmount),
            4
        );
    }, [lastTransaction, totalAmount, availableAmount]);

    const renderCards = React.useCallback(() => {
        switch (status) {
            case DAILY_REWARD_STATUS.Daily:
                return (
                    <>
                        <CardFeatureInfo
                            containerStyle={styles.gap}
                            icon={<CoinIcon slug={Coin.vigup} size={48} />}
                            title={i18n.t(
                                'ModalDailyRewards.dailyReward.title',
                                {
                                    availableAmount,
                                    totalAmount,
                                }
                            )}
                            text={i18n.t('ModalDailyRewards.dailyReward.text', {
                                availableAmount,
                            })}
                        />
                        <CardFeatureInfo
                            containerStyle={styles.gap}
                            icon={<Unlock width={48} height={48} />}
                            title={i18n.t('ModalDailyRewards.unlocks.title')}
                            text={i18n.t('ModalDailyRewards.unlocks.text', {
                                restAmount,
                            })}
                        />
                    </>
                );
            case DAILY_REWARD_STATUS.Bonus:
                return (
                    <CardFeatureInfo
                        containerStyle={styles.gap}
                        icon={<PvpBonus width={48} height={48} />}
                        title={i18n.t(
                            'pvpBonus.pvpBonusModal.features.bonus.title',
                            {
                                amount: bonus,
                            }
                        )}
                        text={i18n.t(
                            'pvpBonus.pvpBonusModal.features.bonus.text'
                        )}
                    />
                );
            case DAILY_REWARD_STATUS.Both:
                return (
                    <>
                        <CardFeatureInfoButton
                            onPress={handleNavigateRewardsWallet}
                            containerStyle={styles.gap}
                            icon={<CoinIcon slug={Coin.vigup} size={48} />}
                            title={i18n.t(
                                'ModalDailyRewards.dailyReward.title',
                                {
                                    availableAmount,
                                    totalAmount,
                                }
                            )}
                            text={i18n.t('ModalDailyRewards.dailyReward.text', {
                                availableAmount,
                            })}
                            warningText={i18n.t('howToClaimOnButton.alert')}
                        />
                        <CardFeatureInfoButton
                            onPress={handleNavigateGamingWallet}
                            containerStyle={styles.both}
                            icon={<PvpBonus width={48} height={48} />}
                            title={i18n.t(
                                'pvpBonus.pvpBonusModal.features.bonus.title',
                                {
                                    amount: bonus,
                                }
                            )}
                            text={i18n.t(
                                'pvpBonus.pvpBonusModal.features.bonus.text'
                            )}
                        />
                    </>
                );

            default:
                return null;
        }
    }, [status, bonus, availableAmount, totalAmount, restAmount]);

    const showButton = useMemo(
        () =>
            status === DAILY_REWARD_STATUS.Bonus ||
            status === DAILY_REWARD_STATUS.Daily,
        [status]
    );

    if (status === DAILY_REWARD_STATUS.Unknown || loading) {
        return null;
    }

    return (
        <ModalBottom
            isVisible={isVisible}
            onClose={onClose}
            modalHeight={modalHeight}>
            <ScrollView
                style={styles.container}
                showsVerticalScrollIndicator={false}>
                <TouchableOpacity activeOpacity={1}>
                    <BlockHeader
                        emptyStateIcon={
                            <View style={styles.image}>
                                <Animation
                                    animation="money"
                                    style={styles.image}
                                />
                            </View>
                        }
                        type="emptyState"
                        title={i18n.t(`ModalDailyRewards.${status}.title`)}
                        text={i18n.t(`ModalDailyRewards.${status}.text`)}
                    />

                    {renderCards()}
                </TouchableOpacity>
            </ScrollView>
            {showButton && (
                <ButtonsBottom
                    title={i18n.t('general.BUTTONS.goToWallet')}
                    onPress={handleButtonPress}
                    safe={!isAndroid}
                    containerStyle={styles.button}
                />
            )}
        </ModalBottom>
    );
};

export default ModalDailyRewards;
