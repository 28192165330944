import * as React from 'react';
import { useState } from 'react';

import { TasksHistoryResponseType, TasksResponse } from '@Data/Models';
import Carousel from '@components/Carousel';
import { Text, View } from '@components/Themed';
import { useDimensions } from '@contexts/DimensionsContext';
import { scale, verticalScale } from '@helpers/dimensions';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { useNavigation } from '@navigation/useNavigation';

import EnergyBlock from '../../../Play/components/EnergyBlock';
import DailyTaskCard from '../DailyTaskCard';
import stylesMain from './styles';

interface IProps {
    data: TasksResponse;
}
interface IItems {
    id: TasksHistoryResponseType;
    collectedEnergy: number | null;
    maxEnergy: number | undefined;
}

const DailyTasksBlock = ({ data }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const { windowWidth } = useDimensions();
    const {
        socialTaskConfig,
        moveTaskConfig,
        playTaskConfig,
        verificationTaskConfig,
    } = data;

    const socialTaskEnergyRewarder = React.useMemo(
        () =>
            socialTaskConfig?.canStart
                ? 0
                : socialTaskConfig?.latestTask?.energyRewarded,
        [socialTaskConfig]
    );

    const items: IItems[] = React.useMemo(() => {
        return [
            {
                id: 'SocialTask',
                collectedEnergy: socialTaskEnergyRewarder,
                maxEnergy: socialTaskConfig.maxEnergyReward,
            },
            {
                id: 'VerificationTask',
                collectedEnergy: verificationTaskConfig?.totalEnergyRewarded,
                maxEnergy: verificationTaskConfig.maxEnergyReward,
            },
            {
                id: 'PlayTask',
                collectedEnergy: playTaskConfig?.currentTask?.energyRewarded,
                maxEnergy: playTaskConfig.maxEnergyReward,
            },
            {
                id: 'MoveTask',
                collectedEnergy: moveTaskConfig?.currentTask?.energyRewarded,
                maxEnergy: moveTaskConfig.maxEnergyReward,
            },
        ];
    }, [
        socialTaskConfig,
        moveTaskConfig,
        playTaskConfig,
        verificationTaskConfig,
        socialTaskEnergyRewarder,
    ]);

    const handleNavigation = () => {
        navigation.navigateToTasks();
    };

    const itemWidth = React.useMemo(() => {
        const clearWidth = windowWidth - scale(20);
        const width = clearWidth / 2;
        return width >= 160 ? width : 160;
    }, [windowWidth]);
    const collectedEnergy = React.useMemo(
        () =>
            (socialTaskEnergyRewarder || 0) +
            (verificationTaskConfig?.totalEnergyRewarded || 0) +
            (moveTaskConfig?.currentTask?.energyRewarded || 0) +
            (playTaskConfig?.currentTask?.energyRewarded || 0),

        [
            socialTaskConfig,
            verificationTaskConfig,
            moveTaskConfig,
            playTaskConfig,
            socialTaskEnergyRewarder,
        ]
    );

    const [height, setHeight] = useState<number>(0);

    const getHeight = (height: number) => {
        setHeight((prev) => (prev < height ? height : prev));
    };

    return (
        <View style={styles.container}>
            <View style={styles.headerBock}>
                <Text style={styles.header}>
                    {i18n.t('HomeScreen.DailyTasks.header')}
                </Text>
                <EnergyBlock
                    energy={i18n.t(`HomeScreen.DailyTasks.collected`, {
                        collectedEnergy,
                        maxEnergy: 100,
                    })}
                    containerStyles={styles.energyBlock}
                />
            </View>

            <Carousel
                data={items}
                renderItem={(item, index) => (
                    <DailyTaskCard
                        minHeight={height}
                        getHeight={getHeight}
                        onPress={handleNavigation}
                        taskName={item.id}
                        maxEnergy={item.maxEnergy}
                        collectedEnergy={item.collectedEnergy}
                        isLast={index === items.length - 1}
                    />
                )}
                itemWidth={itemWidth}
                sliderWidth={windowWidth}
                height={height + verticalScale(4)}
                pagingEnabled={false}
                snapEnabled={false}
                windowSize={10}
                carouselStyle={{ justifyContent: 'flex-start' }}
            />
        </View>
    );
};
export default React.memo(DailyTasksBlock);
