import { StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import { scale, verticalScale } from '../../../../helpers/dimensions';
import font from '../../../../helpers/font';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        verificationItem: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        verificationTitle: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '600',
            }),
        },
        verificationSlug: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '600',
            }),
            textTransform: 'capitalize',
            marginRight: scale(4),
        },
        verificationTextContainer: {
            marginLeft: verticalScale(12),
            flex: 1,
        },
        left: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
        },
        right: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        price: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
        },
        withInfo: {
            flexDirection: 'row',
        },
    });

export default styles;
