import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { LinearGradient } from 'expo-linear-gradient';

import { UserBoost } from '../../../../../Data/Models';
import BoostHigh from '../../../../../assets/icons/referrals/boostHigh.svg';
import BoostLow from '../../../../../assets/icons/referrals/boostLow.svg';
import BoostMid from '../../../../../assets/icons/referrals/boostMid.svg';
import Button from '../../../../../components/Button';
import { Text, View } from '../../../../../components/Themed';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import stylesMain from './styles';

interface IProps {
    boost: number;
    invites?: number;
    level: UserBoost;
    type: 'small' | 'big';
    onButtonPress?: () => void;
    containerStyle?: StyleProp<ViewStyle>;
}

const BoostCard = ({
    boost,
    invites,
    level,
    type,
    containerStyle,
    onButtonPress,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const levelMeta = React.useMemo(() => {
        const imageSize = {
            big: { width: 90, height: 101 },
            small: { width: 65, height: 73 },
        };

        switch (level) {
            case 'First':
                return {
                    icon: (
                        <BoostLow
                            width={imageSize[type].width}
                            height={imageSize[type].height}
                            style={styles.boostImage}
                        />
                    ),
                    colors: ['#FE8B43', '#FDDDAE'],
                };
            case 'Second':
                return {
                    icon: (
                        <BoostMid
                            width={imageSize[type].width}
                            height={imageSize[type].height}
                            style={styles.boostImage}
                        />
                    ),
                    colors: ['#B57CEA', '#FFCAF8'],
                };
            case 'Third':
                return {
                    icon: (
                        <BoostHigh
                            width={imageSize[type].width}
                            height={imageSize[type].height}
                            style={styles.boostImage}
                        />
                    ),
                    colors: ['#5CA3D6', '#E7FCE5'],
                };

            default:
                return {
                    icon: <></>,
                    colors: ['transparent', 'transparent'],
                };
        }
    }, [level, type]);

    const renderContent = React.useMemo(() => {
        switch (type) {
            case 'small':
                return (
                    <View style={styles.content}>
                        <Text style={styles.itemTitle}>
                            {i18n.t('referrals.boostCards.cardTitle', {
                                boost,
                            })}
                        </Text>
                        <Text style={styles.itemText}>
                            {i18n.t('referrals.boostCards.cardText')}
                            <Text style={styles.bold}>
                                {invites}
                                {i18n.t('referrals.boostCards.users')}
                            </Text>
                        </Text>
                        {levelMeta.icon}
                    </View>
                );

            default:
                return (
                    <View style={styles.content}>
                        <View style={styles.top}>
                            <Text style={styles.itemTitle}>
                                {i18n.t('referrals.boostCards.cardTitle', {
                                    boost,
                                })}
                            </Text>
                            <Text style={styles.itemTextBigCard}>
                                {i18n.t('referrals.boostCards.text')}
                            </Text>
                        </View>
                        <Button
                            containerStyle={styles.button}
                            style={styles.buttonStyle}
                            textStyles={styles.darkText}
                            type="outline"
                            size="md"
                            title={i18n.t('referrals.boostCards.button')}
                            onPress={onButtonPress}
                        />
                        {levelMeta.icon}
                    </View>
                );
        }
    }, [type, boost, invites, onButtonPress]);

    return (
        <LinearGradient
            colors={levelMeta.colors}
            style={[styles.gradientBackground, containerStyle]}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 1 }}>
            {renderContent}
        </LinearGradient>
    );
};
export default BoostCard;
