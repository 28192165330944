import * as React from 'react';
import { ScrollView } from 'react-native';

import { useMutation } from '@apollo/client';
import { useTheme } from '@contexts/ThemeContext';
import { useUser } from '@contexts/UserContext';
import { waitForNavigation, waitForToast } from '@helpers/helpers';
import { toastErrorUnknown, toastSuccess } from '@helpers/toastNotification';
import { useFocusEffect } from '@react-navigation/native';

import { DELETE_ACCOUNT } from '../../Data/Requests';
import Avatar from '../../components/Avatar';
import Button from '../../components/Button';
import CustomModal from '../../components/CustomModal';
import { ICON_NAMES } from '../../components/Icons';
import ImageIguana from '../../components/ImageIguana';
import Input from '../../components/Input';
import ModalBottom from '../../components/ModalBottom';
import ModalLoader from '../../components/ModalLoader';
import { Text, View } from '../../components/Themed';
import UserRole from '../../components/UserRole';
import Colors from '../../constants/Colors';
import useThemedStyles from '../../hooks/useThemedStyles';
import i18n from '../../i18n/i18n';
import NavigationBar from '../../navigation/NavigationBar';
import ProfileMenu from './components/ProfileMenu';
import stylesMain from './styles';

const config = {
    confirmationScreen: 'DELETE',
};

const ProfileScreen: React.FunctionComponent = () => {
    const { logout } = useUser();
    const [isLogoutModalVisible, setIsLogoutModalVisible] =
        React.useState<boolean>(false);
    const [isDeleteAccountModalVisible, setIsDeleteAccountModalVisible] =
        React.useState<boolean>(false);
    const [value, setValue] = React.useState('');
    const styles = useThemedStyles(stylesMain);

    const [deleteAccount, { loading: deleteAccountLoading }] =
        useMutation(DELETE_ACCOUNT);

    const { reloadUser, user, hasRole } = useUser();

    // Reload user with timeout so it's not blocking UI
    useFocusEffect(
        React.useCallback(() => {
            waitForNavigation().then(() => reloadUser());
        }, [])
    );

    const handleDeleteAccount = async () => {
        await deleteAccount({
            onCompleted: () => {
                setIsDeleteAccountModalVisible(false);
                logout();
                waitForToast().then(() =>
                    toastSuccess(i18n.t('profile.deleteAccountModal.success'))
                );
            },
            onError: (error) => {
                toastErrorUnknown();
                Console.log('[DELETE_ACCOUNT]', error);
            },
        });
    };

    const { theme } = useTheme();

    const renderUser = () => (
        <View style={styles.userContainer}>
            <Avatar size={styles.avatar.width} type="transparent" />
            <View style={styles.userWrapper}>
                <Text
                    style={styles.email}
                    ellipsizeMode="middle"
                    numberOfLines={1}>
                    {user?.email}
                </Text>
                {hasRole && <UserRole withName />}
            </View>
        </View>
    );

    const renderDeleteAccount = () => (
        <Button
            title={i18n.t('profile.deleteAccount')}
            type="text"
            onPress={() => setIsDeleteAccountModalVisible(true)}
            iconName={ICON_NAMES.BIN}
            iconColor={styles.deleteAccountText.color}
            textStyles={styles.deleteAccountText}
            containerStyle={styles.deleteAccountContainer}
            pressedStyle={false}
        />
    );

    const renderLogOutModal = () => (
        <CustomModal
            isVisible={isLogoutModalVisible}
            icon={<ImageIguana type="cry" />}
            titleText={i18n.t('profile.logOutModal.title')}
            infoText={i18n.t('profile.logOutModal.description')}
            firstButtonText={i18n.t('profile.logOutModal.okText')}
            secondButtonText={i18n.t('profile.logOutModal.cancelText')}
            onFirstButtonClick={() => {
                setIsLogoutModalVisible(false);
                logout();
            }}
            firstButtonType="outline"
            onSecondButtonClick={() => {
                setIsLogoutModalVisible(false);
            }}
        />
    );

    const renderDeleteAccountModal = () => (
        <ModalBottom
            isVisible={isDeleteAccountModalVisible}
            titleText={i18n.t('profile.deleteAccountModal.title')}
            additionalText={i18n.t('profile.deleteAccountModal.description')}
            onClose={() => setIsDeleteAccountModalVisible(false)}
            modalHeight="550"
            avoidKeyboard>
            <ScrollView
                keyboardShouldPersistTaps="handled"
                showsVerticalScrollIndicator={false}>
                <Text style={styles.confirmationText}>
                    {i18n.t('profile.deleteAccountModal.preConfirmationPhrase')}
                    <Text
                        style={
                            styles.confirmationPhrase
                        }>{`"${config.confirmationScreen}"`}</Text>
                    {i18n.t(
                        'profile.deleteAccountModal.afterConfirmationPhrase'
                    )}
                </Text>
                <Input
                    label={i18n.t('profile.deleteAccountModal.inputLabel')}
                    stylesContainer={styles.input}
                    onChangeText={setValue}
                    value={value}
                    placeholder={i18n.t(
                        'profile.deleteAccountModal.placeholder'
                    )}
                    placeholderTextColor={Colors[theme].text.secondary}
                    maxLength={20}
                />
                <Button
                    disabled={value !== config.confirmationScreen}
                    title={i18n.t('profile.deleteAccountModal.okText')}
                    onPress={handleDeleteAccount}
                    type="critical"
                />
            </ScrollView>
        </ModalBottom>
    );

    return (
        <View style={styles.container}>
            <NavigationBar
                backIcon
                title={i18n.t('profile.title')}
                filled={false}
            />
            <ScrollView
                style={styles.page}
                showsVerticalScrollIndicator={false}>
                {renderUser()}
                <View style={styles.divider} />
                <ProfileMenu />
                <View style={styles.buttonLogOut}>
                    <Button
                        title={i18n.t('profile.logOut')}
                        type="outline"
                        onPress={() => {
                            setIsLogoutModalVisible(true);
                        }}
                    />
                </View>
                {renderDeleteAccount()}
            </ScrollView>
            {renderLogOutModal()}
            {renderDeleteAccountModal()}
            <ModalLoader
                isVisible={deleteAccountLoading}
                text={i18n.t('profile.deleteAccountModal.loading')}
            />
        </View>
    );
};

export default ProfileScreen;
