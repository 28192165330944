import * as React from 'react';
import { ScrollView } from 'react-native';

import { FieldArrayRenderProps } from 'formik/dist/FieldArray';
import _ from 'lodash';

import { useHaptic } from '@hooks/useHaptic';

import ButtonsBottom from '../../../../components/ButtonsBottom';
import ModalBottom from '../../../../components/ModalBottom';
import TouchableOpacity from '../../../../components/TouchableOpacity';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n, { LANGUAGES } from '../../../../i18n/i18n';
import { IInitialMessage } from '../../types';
import LangItem from '../LangItem';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
    languages: string[];
    initialMessage: IInitialMessage;
    initialValues: IInitialMessage;
    arrayHelpers: FieldArrayRenderProps;
    updateSelectedLanguages: (ids: string[]) => void;
}

const SelectLanguageModal = ({
    isVisible,
    onClose,
    languages,
    initialMessage,
    arrayHelpers,
    updateSelectedLanguages,
    initialValues,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { triggerMedium } = useHaptic();

    const [temporaryLanguages, setTemporaryLanguages] =
        React.useState<string[]>(languages);

    React.useEffect(() => {
        setTemporaryLanguages(languages);
    }, [languages]);

    const isChanged = React.useMemo(
        () => _.isEqual(languages, temporaryLanguages),
        [languages, temporaryLanguages]
    );

    const handleUpdateTemporaryLanguages = React.useCallback(
        (id: string) => {
            triggerMedium();
            if (!!temporaryLanguages.find((val) => val === id)) {
                setTemporaryLanguages(
                    temporaryLanguages.filter((val) => val !== id)
                );
            } else {
                setTemporaryLanguages((prev) => [...prev, id]);
            }
        },
        [temporaryLanguages]
    );

    const handleSave = React.useCallback(() => {
        const langToDelete = _.difference(languages, temporaryLanguages);
        const langToAdd = _.difference(temporaryLanguages, languages);
        if (langToDelete.length) {
            deleteFromArray(langToDelete, arrayHelpers);
        }

        if (languages.length < temporaryLanguages.length) {
            langToAdd.map((language, index) => {
                if (!initialValues?.language && !index) {
                    arrayHelpers.replace(index, {
                        ...initialMessage,
                        language,
                    });
                } else {
                    arrayHelpers.push({ ...initialMessage, language });
                }
            });
        } else if (languages.length === temporaryLanguages.length) {
            temporaryLanguages.map((language, index) => {
                if (languages.find((v) => v === language)) {
                    return;
                }
                arrayHelpers.replace(index, {
                    ...initialMessage,
                    language,
                });
            });
        } else if (languages.length > temporaryLanguages.length) {
            langToAdd.map((language) =>
                arrayHelpers.push({ ...initialMessage, language })
            );
        }

        updateSelectedLanguages(temporaryLanguages);
        onClose();
    }, [temporaryLanguages, languages, arrayHelpers, initialValues]);

    const deleteFromArray = (
        array: string[],
        arrayHelpers: FieldArrayRenderProps
    ) => {
        array.map((language, index) => {
            const indexToDelete =
                languages.findIndex((item) => item === language) - index;

            arrayHelpers.remove(indexToDelete);
        });
    };

    const handleOnClose = React.useCallback(() => {
        onClose();
        setTemporaryLanguages(languages);
    }, [languages]);

    return (
        <ModalBottom
            isVisible={isVisible}
            onClose={handleOnClose}
            modalHeight={600}
            titleText={i18n.t(
                'profile.adminSendPushNotification.selectLanguage.modalTitle'
            )}>
            <ScrollView showsVerticalScrollIndicator={false}>
                <TouchableOpacity activeOpacity={1}>
                    {LANGUAGES.map((id) => (
                        <LangItem
                            isSelected={
                                !!temporaryLanguages.find((item) => item === id)
                            }
                            key={id}
                            id={id}
                            onPress={handleUpdateTemporaryLanguages}
                        />
                    ))}
                </TouchableOpacity>
            </ScrollView>
            <ButtonsBottom
                disabled={isChanged}
                containerStyle={styles.buttons}
                onPress={handleSave}
                title={i18n.t(
                    'profile.adminSendPushNotification.selectLanguage.button'
                )}
            />
        </ModalBottom>
    );
};
export default SelectLanguageModal;
