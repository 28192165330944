import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import CardFeatureInfo from '@components/CardFeatureInfo/CardFeatureInfo';
import CoinIcon from '@components/Icons/CoinIcon';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { priceString } from '@helpers/payments';
import { balanceFromWei } from '@helpers/wallet';
import i18n from '@i18n/i18n';

interface IProps {
    containerStyle?: StyleProp<ViewStyle>;
}

const MinimalBetFeature = ({ containerStyle }: IProps) => {
    const { mysteryLikesGameStats } = useMysteryGames();

    return (
        <CardFeatureInfo
            containerStyle={containerStyle}
            icon={<CoinIcon slug={Coin.vigu} size={48} />}
            title={i18n.t('HomeScreen.minimalBet.title', {
                amount: priceString(
                    balanceFromWei(
                        mysteryLikesGameStats?.minimalBetIguAmountWei
                    ).valueLong,
                    Coin.vigu
                ),
            })}
            text={i18n.t('HomeScreen.minimalBet.text')}
        />
    );
};
export default MinimalBetFeature;
