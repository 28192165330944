import * as React from 'react';
import { useCallback } from 'react';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import EmptyList from '@components/EmptyList';
import LoadingComponent from '@components/LoadingComponent';
import PullToRefresh from '@components/PullToRefresh';
import { View } from '@components/Themed';
import { useDimensions } from '@contexts/DimensionsContext';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationMarketPlace, {
    NAVIGATION_MARKETPLACE,
} from '@navigation/NavigationBar/NavigationMarketPlace';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { useFocusEffect } from '@react-navigation/native';

import MarketPlacePetCard from '../components/MarketPlacePetCard';
import MarketPlaceSearchBlock from '../components/MarketPlaceSearchBlock';
import NoResultFound from '../components/NoResultFound';
import useMarketplaceFilteredActiveOrders from '../hooks/useMarketplaceFilteredActiveOrders';
import stylesMain from './styles';

const SCREEN = 'MarketPlaceActiveOrdersScreen';

const MarketPlaceActiveOrdersScreen = () => {
    const styles = useThemedStyles(stylesMain);

    const { isSmallLayout } = useDimensions();

    const navigation = useNavigation();

    const {
        ordersLoading,
        refreshLoading,
        orders,
        getOrders,
        getOrdersLoadMore,
        canLoadMore,
        onApplyFilterPress,
        onClearPress,
        onTagClearPress,
        typeOfNft,
        selectedRanks,
        sortedBy,
        clearSearchKeyword,
        searchByKeyword,
        isNotFound,
    } = useMarketplaceFilteredActiveOrders();

    const handleCreateNewOrder = () => {
        navigation.navigate(ROUTES.MARKETPLACE_WELCOME_CREATE_ORDER);
    };

    useFocusEffect(
        useCallback(() => {
            getOrders({ refresh: false });
        }, [getOrders])
    );

    useFocusEffect(
        useCallback(() => {
            const interval = setInterval(
                () => getOrders({ refresh: false }),
                60000
            );
            return () => clearInterval(interval);
        }, [getOrders])
    );

    const handleRefresh = useCallback(() => {
        getOrders({ refresh: true });
    }, [getOrders]);

    const headerComponent = useCallback(() => {
        return (
            <>
                <BlockHeader
                    containerStyle={styles.header}
                    type="primary"
                    title={i18n.t(`MarketPlace.${SCREEN}.title`)}
                    text={i18n.t(`MarketPlace.${SCREEN}.text`)}
                    onAdditionalButtonPress={handleCreateNewOrder}
                />
                <MarketPlaceSearchBlock
                    containerStyle={styles.search}
                    typeOfNft={typeOfNft}
                    selectedRanks={selectedRanks}
                    sortedBy={sortedBy}
                    onApplyFilterPress={onApplyFilterPress}
                    onClearPress={onClearPress}
                    onTagClearPress={onTagClearPress}
                    onClearSearchKeywords={clearSearchKeyword}
                    searchByKeyword={searchByKeyword}
                />
            </>
        );
    }, [typeOfNft, selectedRanks, sortedBy]);

    const emptyComponent = useCallback(() => {
        return (
            <View
                style={
                    isSmallLayout
                        ? styles.emptyStateWrapperSmall
                        : styles.emptyStateWrapper
                }>
                {isNotFound ? (
                    <NoResultFound />
                ) : (
                    <EmptyList
                        containerStyle={styles.emptyList}
                        emptyTitle={i18n.t(`MarketPlace.${SCREEN}.empty.title`)}
                        emptyText={i18n.t(`MarketPlace.${SCREEN}.empty.text`)}
                        emptyButtonText={i18n.t(
                            'general.BUTTONS.createNewOrder'
                        )}
                        withAnimation="cry"
                        onPressButton={handleCreateNewOrder}
                    />
                )}
            </View>
        );
    }, [isSmallLayout, isNotFound]);

    const handleNavigateOnOverview = (
        petId: string,
        cancelTransactionValuesStringified: string | null
    ) => {
        navigation.push(ROUTES.MARKETPLACE_OVERVIEW_GROUP, {
            screen: ROUTES.MARKETPLACE_OVERVIEW,
            params: { petId, cancelTransactionValuesStringified },
        });
    };

    return (
        <View style={styles.main}>
            <NavigationMarketPlace
                currentNavigation={NAVIGATION_MARKETPLACE.ORDERS}
            />

            <View style={styles.content}>
                {ordersLoading && !orders ? (
                    <LoadingComponent text={i18n.t('general.loading')} />
                ) : (
                    <PullToRefresh
                        refreshing={refreshLoading}
                        textLoading={i18n.t('pullToRefresh.loading')}
                        onRefresh={handleRefresh}
                        HeaderComponent={headerComponent()}
                        EmptyComponent={emptyComponent()}
                        renderData={orders || []}
                        //@ts-ignore ignore item type any
                        renderItem={({ item }) => (
                            <MarketPlacePetCard
                                loading={ordersLoading}
                                data={item}
                                onPress={handleNavigateOnOverview}
                                showSellerAddress
                                itsMe={false}
                            />
                        )}
                        onReachEnd={getOrdersLoadMore}
                        contentLoading={ordersLoading}
                        moveItemFromScreen={ordersLoading}
                        canLoadMore={canLoadMore}
                        shouldUseEmptyComponentInFooter
                        numOfColumns={2}
                        columnWrapperStyle={styles.columnWrapperStyle}
                        FooterComponent={<View style={styles.footer} />}
                    />
                )}
            </View>
        </View>
    );
};

export default MarketPlaceActiveOrdersScreen;
