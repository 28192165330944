import React, { useState } from 'react';

import AsyncStorage from '@react-native-async-storage/async-storage';

export const useCarouselHeight = (storageKey: string) => {
    React.useLayoutEffect(() => {
        const getSavedHeight = async () => {
            setHeight(Number(await AsyncStorage.getItem(storageKey)));
        };
        getSavedHeight();
    }, []);

    const [height, setHeight] = useState<number>(0);

    const getHeight = (height: number) => {
        setHeight((prev) => (prev < height ? height : prev));
        AsyncStorage.setItem(storageKey, height.toString());
    };

    return { height, getHeight };
};
