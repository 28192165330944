import * as React from 'react';
import { ScrollView } from 'react-native';

import ActivityIndicator from '@components/ActivityIndicator';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import EmptyList from '@components/EmptyList';
import { View } from '@components/Themed';
import { stylesFlex } from '@helpers/style';
import useThemedStyles from '@hooks/useThemedStyles';
import { useUserAchievements } from '@hooks/useUserAchievements';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';

import Achievement from '../components/Achievement';
import stylesMain from './styles';

const AchievementsMainScreen = () => {
    const styles = useThemedStyles(stylesMain);

    const {
        getUserAchievements,
        loadingUserAchievements,
        errorUserAchievements,
        userAchievementsData,
    } = useUserAchievements();

    React.useEffect(() => {
        getUserAchievements();
    }, []);

    return (
        <View style={styles.main}>
            <NavigationBar
                filled={false}
                backIcon="leftArrow"
                title={i18n.t('Achievements.title')}
            />

            <View style={styles.listWrapper}>
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    contentContainerStyle={
                        loadingUserAchievements && stylesFlex()
                    }>
                    <BlockHeader
                        type={'primary'}
                        title={i18n.t('Achievements.title')}
                        text={i18n.t('Achievements.text')}
                    />
                    {loadingUserAchievements ? (
                        <ActivityIndicator
                            loading={loadingUserAchievements}
                            containerStyle={styles.loading}
                            size={'large'}
                        />
                    ) : userAchievementsData?.userAchievements.length &&
                      !errorUserAchievements ? (
                        <View style={styles.listBlock}>
                            {userAchievementsData.userAchievements.map(
                                (i, index) => (
                                    <Achievement
                                        key={i.type}
                                        achievement={i}
                                        isLast={
                                            index ===
                                            userAchievementsData
                                                .userAchievements.length -
                                                1
                                        }
                                    />
                                )
                            )}
                        </View>
                    ) : (
                        <EmptyList
                            big
                            containerStyle={styles.empty}
                            emptyTitle={i18n.t(
                                'general.errors.emptyErrorScreen.title'
                            )}
                            emptyText={i18n.t(
                                'general.errors.emptyErrorScreen.text'
                            )}
                            withAnimation="cry"
                        />
                    )}
                </ScrollView>
            </View>
        </View>
    );
};
export default AchievementsMainScreen;
