import { StyleSheet } from 'react-native';

import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import Colors from '../../constants/Colors';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(20),
            marginTop: verticalScale(32),
        },
        image: {
            alignSelf: 'center',
            width: 200,
            height: 200,
            marginBottom: verticalScale(24),
        },
        box: {
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(20),
            borderRadius: moderateScale(12),
        },
        timer: {
            marginTop: verticalScale(20),
            alignItems: 'center',
        },
        title: {
            textAlign: 'center',
            color: Colors[theme].text.primary,
            ...font({
                size: 18,
                height: 24,
                weight: '700',
            }),
            marginBottom: verticalScale(6),
        },
        text: {
            textAlign: 'center',
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 21,
                weight: '400',
            }),
            letterSpacing: 0.08,
            marginBottom: verticalScale(16),
        },
        timerAdditionalText: {
            color: Colors[theme].text.accent,
            ...font({
                size: 14,
                height: 21,
                weight: '700',
            }),
        },
        rankInfo: {
            marginTop: verticalScale(16),
        },
        rankItem: {
            alignContent: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        right: {
            flex: 1,
            flexDirection: 'row',
            marginRight: scale(6),
        },
        icon: {
            marginRight: scale(6),
        },
        rankText: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 21,
                weight: '400',
            }),
        },
        rankChance: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
        },
        gap: {
            marginTop: verticalScale(12),
        },
        pvpBonus: {
            marginHorizontal: 0,
            marginTop: verticalScale(20),
        },
        both: {
            marginTop: verticalScale(12),
            marginBottom: verticalScale(32),
        },
    });

export default styles;
