import * as React from 'react';

import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';

import { useNavigation } from '../../useNavigation';
import NavigationItem from '../NavigationItem';
import stylesMain from './styles';

interface IProps {
    currentNavigation: NAVIGATION_QUANTUM_SPIN;
    disabled?: boolean;
}

export enum NAVIGATION_QUANTUM_SPIN {
    WHEEL = 'WHEEL',
    HISTORY = 'HISTORY',
}

const data: NAVIGATION_QUANTUM_SPIN[] = [
    NAVIGATION_QUANTUM_SPIN.WHEEL,
    NAVIGATION_QUANTUM_SPIN.HISTORY,
];

const NavigationQuantumSpin = ({ currentNavigation, disabled }: IProps) => {
    const styles = useThemedStyles(stylesMain, true);
    const navigation = useNavigation();

    const handleNavigation = (item: NAVIGATION_QUANTUM_SPIN) => {
        switch (item) {
            case NAVIGATION_QUANTUM_SPIN.WHEEL:
                navigation.navigate(ROUTES.QUANTUM_SPIN_MAIN);
                break;
            case NAVIGATION_QUANTUM_SPIN.HISTORY:
                navigation.navigate(ROUTES.QUANTUM_SPIN_HISTORY);
                break;
        }
    };

    return (
        <View style={styles.shadow}>
            <View style={styles.container}>
                {data.map((item) => (
                    <NavigationItem
                        disabled={disabled}
                        oneColor
                        key={item}
                        onPress={() => handleNavigation(item)}
                        title={i18n.t(`HomeScreen.GamesNavigationBar.${item}`)}
                        isActive={currentNavigation === item}
                        containerStyles={styles.item}
                    />
                ))}
            </View>
        </View>
    );
};
export default NavigationQuantumSpin;
