import { StyleSheet } from 'react-native';

import Colors from '../../../constants/Colors';
import { scale, verticalScale } from '../../../helpers/dimensions';
import font from '../../../helpers/font';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
        },
        gap: {
            marginBottom: verticalScale(20),
        },
        form: {
            paddingHorizontal: scale(20),
        },
        headerButtons: {
            flex: 1,
            flexDirection: 'row',
        },
        headerButton: {
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: verticalScale(20),
            marginRight: verticalScale(12),
        },
        headerButtonText: {
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
            letterSpacing: 0.16,
            marginLeft: verticalScale(6),
        },
        buttonClear: {
            color: Colors[theme].text.secondary,
        },
        headerText: {
            marginBottom: verticalScale(12),
            paddingHorizontal: scale(0),
        },
        iconRandom: {
            color: Colors[theme].icon.green,
        },
        iconClear: {
            color: Colors[theme].icon.secondary,
        },
    });

export default styles;
