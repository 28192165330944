import { IconsData, iconsData } from './iconsData';

interface IProps {
    slug: string;
}

const StakingOptionsIcon = ({ slug }: IProps) => {
    return iconsData[slug as keyof IconsData];
};

export default StakingOptionsIcon;
