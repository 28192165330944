import * as React from 'react';

import Button from '@components/Button';
import ButtonCard from '@components/ButtonCard';
import Icon, { ICON_NAMES } from '@components/Icons';
import { View } from '@components/Themed';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import { useHowToPurchaseIGU } from '../../hooks/useHowToPurchaseIGU';
import stylesMain from './styles';

interface IProps {
    coin?: Coin;
    onDepositPress: () => void;
    onWithdrawPress: () => void;
    onTradePress?: () => void;
    disableWithdraw?: boolean;
}

const WalletButtons = ({
    coin,
    onDepositPress,
    onWithdrawPress,
    onTradePress,
    disableWithdraw = false,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const onPurchaseBNB = () => {
        navigation.push(ROUTES.HOW_TO_PURCHASE_BNB);
    };

    const { renderHowToPurchaseIGU } = useHowToPurchaseIGU();

    return (
        <View style={styles.outerContainer}>
            <View style={styles.container}>
                <ButtonCard
                    onPress={onDepositPress}
                    debouncedPress
                    icon={
                        <Icon
                            name={ICON_NAMES.DEPOSIT}
                            color={styles.icon.color}
                        />
                    }
                    title={i18n.t('wallet.deposit')}
                    containerStyle={styles.button}
                />
                <ButtonCard
                    disabled={disableWithdraw}
                    onPress={onWithdrawPress}
                    debouncedPress
                    icon={
                        <Icon
                            name={ICON_NAMES.WITHDRAW}
                            color={styles.icon.color}
                        />
                    }
                    title={i18n.t('wallet.withdraw')}
                    containerStyle={styles.button}
                />
                {onTradePress && (
                    <ButtonCard
                        onPress={onTradePress}
                        debouncedPress
                        icon={
                            <Icon
                                name={ICON_NAMES.TRADE}
                                color={styles.icon.color}
                            />
                        }
                        title={i18n.t('wallet.trade')}
                        containerStyle={styles.button}
                    />
                )}
            </View>
            {coin === Coin.bnb && (
                <View style={styles.purchaseBlock}>
                    <Button
                        onPress={onPurchaseBNB}
                        title={i18n.t('wallet.howToPurchaseBNB')}
                        type="text"
                        iconName={ICON_NAMES.QUESTION}
                        iconDirection="right"
                    />
                </View>
            )}
            {coin === Coin.igu && (
                <View style={styles.purchaseBlock}>
                    {renderHowToPurchaseIGU()}
                </View>
            )}
        </View>
    );
};
export default WalletButtons;
