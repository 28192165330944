import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        feature: {
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: verticalScale(12),
        },
        key: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 21,
                weight: '400',
            }),
            letterSpacing: 0.08,

            marginRight: scale(16),
        },
        value: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 23,
                weight: '600',
            }),
            flex: 1,
            textAlign: 'right',
        },
    });

export default styles;
