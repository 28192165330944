import * as React from 'react';
import { useCallback } from 'react';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import EmptyList from '@components/EmptyList';
import { ICON_NAMES } from '@components/Icons';
import LoadingComponent from '@components/LoadingComponent';
import ModalLoader from '@components/ModalLoader';
import PullToRefresh from '@components/PullToRefresh';
import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';
import NavigationTokenTeller, {
    NAVIGATION_TOKEN_TELLER,
} from '@navigation/NavigationBar/NavigationTokenTeller';
import ROUTES from '@navigation/routes';

import GamesNavigation from '../../components/GamesNavigation';
import TokenTellerHistoryItem from '../../components/TokenTellerHistoryItem';
import TokenTellerRoundsModal from '../../components/TokenTellerRoundsModal';
import useTokenTellerHistoryScreen from './hooks/useTokenTellerHistoryScreen';
import stylesMain from './styles';

const GAME = MYSTERY_GAMES.tokenteller;

const TokenTellerHistoryScreen = () => {
    const styles = useThemedStyles(stylesMain);
    const {
        handleRefresh,
        isRefresh,
        isSmallLayout,
        handOnHowItWorks,
        gamesHistory,
        getGamesHistoryLoadMore,
        canLoadMore,
        loadMore,
        historyLoading,
        onHistoryItemPress,
        onPressLoading,
        isReloading,
        roundsModal,
        openRoundsModal,
        closeRoundsModal,
        onPurchaseTicket,
    } = useTokenTellerHistoryScreen();

    const headerComponent = () => {
        return (
            <BlockHeader
                containerStyle={styles.header}
                type="primary"
                title={i18n.t('HomeScreen.GameHistoryScreen.title')}
                text={i18n.t('HomeScreen.GameHistoryScreen.text')}
                iconDirection="right"
            />
        );
    };
    const emptyComponent = useCallback(() => {
        return (
            <View
                style={
                    isSmallLayout
                        ? styles.emptyStateWrapperSmall
                        : styles.emptyStateWrapper
                }>
                <EmptyList
                    containerStyle={styles.emptyList}
                    emptyTitle={i18n.t('HomeScreen.EmptyGameHistory.title')}
                    emptyText={i18n.t('HomeScreen.EmptyGameHistory.text')}
                    withAnimation="cry"
                />
            </View>
        );
    }, [isSmallLayout]);

    return (
        <View style={styles.main}>
            <ModalLoader isVisible={onPressLoading} />
            <GamesNavigation
                backIconName={ICON_NAMES.CLOSE}
                type={MYSTERY_GAMES.tokenteller}
                onPress={openRoundsModal}
                loading={isReloading}
                handleOnInfoIconPress={handOnHowItWorks}
                buttonTitle={i18n.t(`HomeScreen.${GAME}.predictPrice`)}
            />
            <NavigationTokenTeller
                currentNavigation={NAVIGATION_TOKEN_TELLER.HISTORY}
            />
            <View style={styles.content}>
                {historyLoading ? (
                    <LoadingComponent text={i18n.t('general.loading')} />
                ) : (
                    <PullToRefresh
                        refreshing={isRefresh}
                        textLoading={i18n.t('pullToRefresh.loading')}
                        onRefresh={handleRefresh}
                        HeaderComponent={headerComponent()}
                        EmptyComponent={emptyComponent()}
                        renderData={gamesHistory || []}
                        //@ts-ignore ignore item type any
                        renderItem={({ item, index }) => (
                            <TokenTellerHistoryItem
                                key={index}
                                item={item}
                                onPress={onHistoryItemPress}
                                disabled={isReloading}
                            />
                        )}
                        shouldUseEmptyComponentInFooter
                        loadMore={loadMore}
                        onReachEnd={getGamesHistoryLoadMore}
                        canLoadMore={canLoadMore}
                    />
                )}
            </View>
            <TokenTellerRoundsModal
                isVisible={roundsModal}
                onClose={closeRoundsModal}
                openModal={openRoundsModal}
                onPurchaseTicket={onPurchaseTicket}
                loading={isReloading}
                routeBack={ROUTES.TOKEN_TELLER_MAIN}
            />
        </View>
    );
};

export default TokenTellerHistoryScreen;
