import * as React from 'react';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import LuckyStrikeHistoryScreen from '../../screens/TabBar/Home/LuckyStrikeFlow/LuckyStrikeHistory/LuckyStrikeHistoryScreen';
import LuckyStrikeMainScreen from '../../screens/TabBar/Home/LuckyStrikeFlow/LuckyStrikeMain/LuckyStrikeMainScreen';
import { LuckyStrikeTabsParamList } from '../../types';
import ROUTES from '../routes';

const LuckyStrikeTabsStack =
    createBottomTabNavigator<LuckyStrikeTabsParamList>();

export const LuckyStrikeTabsNavigator = () => {
    return (
        <LuckyStrikeTabsStack.Navigator
            screenOptions={{
                headerShown: false,
            }}
            initialRouteName={ROUTES.LUCKY_STRIKE_MAIN}
            backBehavior="none"
            tabBar={() => null}>
            <LuckyStrikeTabsStack.Screen
                name={ROUTES.LUCKY_STRIKE_MAIN}
                component={LuckyStrikeMainScreen}
            />
            <LuckyStrikeTabsStack.Screen
                name={ROUTES.LUCKY_STRIKE_HISTORY}
                component={LuckyStrikeHistoryScreen}
            />
        </LuckyStrikeTabsStack.Navigator>
    );
};
