import * as React from 'react';
import { useCallback } from 'react';

import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { MYSTERY_GAMES_CONTINUE_BANNER_RENDER_TYPE } from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { useFocusEffect } from '@react-navigation/native';

import { useVisibleHook } from '../../../../hooks/useVisibleHook';
import AnimatedBanner from '../components/AnimatedBanner';
import MysteryGamesContinueModal from '../components/MysteryGamesContinueModal';

export const useContinueMysteryGamesBanner = () => {
    const navigation = useNavigation();
    const { open, close, isVisible } = useVisibleHook();
    const {
        open: openBothModal,
        close: closeBothModal,
        isVisible: bothModal,
    } = useVisibleHook();

    const {
        currentMysteryLikesGameId,
        nftRoyalCurrentGame,
        mysteryLikesPendingGame,
    } = useMysteryGames();

    useFocusEffect(
        useCallback(() => {
            if (currentMysteryLikesGameId || nftRoyalCurrentGame) {
                open();
            }
        }, [currentMysteryLikesGameId, nftRoyalCurrentGame])
    );

    const renderType: MYSTERY_GAMES_CONTINUE_BANNER_RENDER_TYPE | null =
        React.useMemo(() => {
            if (mysteryLikesPendingGame && nftRoyalCurrentGame) {
                return MYSTERY_GAMES_CONTINUE_BANNER_RENDER_TYPE.BOTH;
            }
            if (mysteryLikesPendingGame) {
                return MYSTERY_GAMES_CONTINUE_BANNER_RENDER_TYPE.MLG;
            }
            if (nftRoyalCurrentGame) {
                return MYSTERY_GAMES_CONTINUE_BANNER_RENDER_TYPE.ROYAL;
            }
            return null;
        }, [mysteryLikesPendingGame, nftRoyalCurrentGame]);

    const navigateToMLG = () => {
        close();
        closeBothModal();
        navigation.navigate(ROUTES.MYSTERY_LIKES_MAIN);
        navigation.navigate(ROUTES.MYSTERY_LIKES_SELECT_RANGE);
    };
    const navigateToNftRoyal = () => {
        close();
        closeBothModal();
        navigation.navigate(ROUTES.NFT_ROYAL_MAIN);
        navigation.navigate(ROUTES.NFT_ROYAL_PLAY_GAME);
    };

    const onPress = useCallback(() => {
        switch (renderType) {
            case MYSTERY_GAMES_CONTINUE_BANNER_RENDER_TYPE.BOTH:
                openBothModal();
                break;
            case MYSTERY_GAMES_CONTINUE_BANNER_RENDER_TYPE.MLG:
                navigateToMLG();
                break;
            case MYSTERY_GAMES_CONTINUE_BANNER_RENDER_TYPE.ROYAL:
                navigateToNftRoyal();
                break;
            default:
                break;
        }
    }, [renderType]);

    const renderBanner = React.useCallback(() => {
        return renderType ? (
            <>
                <AnimatedBanner
                    mysteryLikes={mysteryLikesPendingGame}
                    renderType={renderType}
                    isVisible={isVisible}
                    onPress={onPress}
                />
                {renderType ===
                    MYSTERY_GAMES_CONTINUE_BANNER_RENDER_TYPE.BOTH && (
                    <MysteryGamesContinueModal
                        isVisible={bothModal}
                        onClose={closeBothModal}
                        onMysteryLikes={navigateToMLG}
                        onNftRoyal={navigateToNftRoyal}
                    />
                )}
            </>
        ) : null;
    }, [isVisible, bothModal, renderType, mysteryLikesPendingGame]);

    return { renderBanner, isVisible };
};
