import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { ScrollView } from 'react-native';

import { Text, View } from '@components/Themed';
import { useTrade } from '@contexts/Trade/TradeContext';
import { toastSuccess } from '@helpers/toastNotification';
import { inputNumberFormatter } from '@helpers/wallet';

import Button from '../../../../../components/Button';
import { ICON_NAMES } from '../../../../../components/Icons';
import Input from '../../../../../components/Input';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import stylesMain from './styles';

interface IProps {
    handleClose: () => void;
}

const config = {
    slippageOptions: [1, 2, 3],
    minSlippage: 1,
    maxSlippage: 5,
};

const TradeSetting = ({ handleClose }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { setSlippage, slippage } = useTrade();

    const [selected, setSelected] = React.useState<number>(0);
    const [currentSlip, setCurrentSlip] = React.useState<number>(0);
    const [value, setValue] = React.useState<string>('');
    const [error, setError] = React.useState<boolean>(false);

    const isCustomSlippage = useMemo(() => {
        return selected === 3;
    }, [selected]);

    const isCorrectSlippage = (slippage: number) => {
        return slippage <= config.maxSlippage && slippage >= config.minSlippage;
    };

    React.useEffect(() => {
        setCurrentSlip(slippage);
        if (config.slippageOptions.includes(slippage)) {
            setSelected(config.slippageOptions.indexOf(slippage));
        } else {
            setSelected(3);
            setValue(slippage.toString());
        }
    }, [slippage]);

    const handleSave = useCallback(() => {
        if (currentSlip !== slippage) {
            setSlippage(currentSlip);
            toastSuccess(undefined, undefined);
        }
        handleClose();
    }, [currentSlip, slippage, handleClose]);

    const onClickItem = (index: number) => {
        setCurrentSlip(config.slippageOptions[index]);
        setSelected(index);
        setValue('');
        setError(false);
    };

    const onFocusInput = () => {
        setSelected(3);
        setCurrentSlip(Number.isNaN(parseFloat(value)) ? 0 : parseFloat(value));
    };

    const onChange = (text: string) => {
        const convertedValue = inputNumberFormatter(text, 3);
        const number = parseFloat(convertedValue);
        if (number >= 10) {
            return;
        }
        if (number > config.maxSlippage || number < config.minSlippage) {
            setError(true);
        } else {
            setError(false);
        }

        setSelected(3);
        setValue(convertedValue);
        setCurrentSlip(number);
    };

    const handleCancel = () => {
        setValue('');
        setError(false);
    };

    return (
        <ScrollView
            style={styles.container}
            keyboardShouldPersistTaps="handled">
            <Text style={styles.title}>{i18n.t('trade.settingTitle')}</Text>
            <Text style={styles.mainText}>
                {i18n.t('trade.slippageMainText')}
            </Text>
            <View style={styles.inputsBox}>
                <View style={styles.inputsContainer}>
                    {config.slippageOptions.map((option, index) => (
                        <Button
                            key={option}
                            title={option.toString() + '%'}
                            style={{
                                ...styles.inputButton,
                                ...(selected === index
                                    ? styles.inputButtonFocus
                                    : {}),
                            }}
                            containerStyle={{
                                ...styles.inputButtonContainer,
                                ...(selected === index
                                    ? styles.inputButtonContainerFocus
                                    : {}),
                            }}
                            type="outline"
                            size="sm"
                            onPress={() => onClickItem(index)}
                        />
                    ))}
                    <Input
                        placeholder={i18n.t('trade.percentFour')}
                        style={{
                            ...styles.textInput,
                            ...(isCustomSlippage && !error
                                ? styles.textInputActive
                                : {}),
                        }}
                        stylesContainer={{
                            ...styles.textInputContainer,
                        }}
                        keyboardType="decimal-pad"
                        value={value}
                        onFocus={onFocusInput}
                        onChangeText={onChange}
                        error={error}
                        component={
                            <>
                                {isCustomSlippage && value !== '' && (
                                    <Button
                                        size="sm"
                                        type="outline"
                                        onPress={handleCancel}
                                        containerStyle={styles.clearInputButton}
                                        style={styles.clearInputButtonIcon}
                                        iconName={ICON_NAMES.CANCEL_ROUND}
                                    />
                                )}
                            </>
                        }
                    />
                </View>
            </View>
            {error && (
                <Text style={styles.textWarning}>
                    {i18n.t('trade.inputWarning', {
                        minAmount: config.minSlippage,
                        maxAmount: config.maxSlippage,
                    })}
                </Text>
            )}
            <Button
                disabled={error || (isCustomSlippage && value === '')}
                title={i18n.t('trade.save')}
                onPress={handleSave}
                containerStyle={styles.button}
            />
        </ScrollView>
    );
};
export default TradeSetting;
