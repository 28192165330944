import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        stakeButtonContainer: {
            position: 'absolute',
            top: verticalScale(15),
            backgroundColor: 'transparent',
            right: scale(12),
        },
        stakeButton: {
            paddingTop: verticalScale(4),
            paddingBottom: verticalScale(4),
            paddingLeft: scale(6),
            paddingRight: scale(8),
            backgroundColor: '#FFFFFF',
            borderColor: '#ECECEF',
        },
        stakeButtonContent: {
            backgroundColor: 'transparent',
            flexDirection: 'row',
            alignItems: 'center',
        },
        stakeButtonImg: {
            width: scale(20),
            height: scale(20),
            backgroundColor: 'transparent',
        },
        stakeButtonText: {
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),
            marginLeft: scale(4),
        },
    });

export default styles;
