import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import { scale, verticalScale } from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const stylesMain = (theme: theme) =>
    StyleSheet.create({
        description: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
        },
        percents: {
            color: Colors[theme].text.primary,
            ...font({
                size: 20,
                height: 26,
                weight: '700',
            }),
            marginLeft: scale(8),
        },
        percentsContainer: {
            borderWidth: 1,
            borderRadius: 12,
            borderColor: Colors[theme].border.primary,
            paddingVertical: verticalScale(20),
            paddingHorizontal: scale(16),
            alignItems: 'center',
        },
        percentsTitleContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingBottom: verticalScale(8),
        },
        seekBar: {
            paddingBottom: verticalScale(8),
            paddingTop: verticalScale(16),
        },
        percentsArrayContainer: {
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingHorizontal: scale(12),
        },
        percentsArrayText: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 11,
                height: 16,
                weight: '600',
            }),
        },
        cardDescriptionText: {
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            color: Colors[theme].text.secondary,
        },
        buttonSave: {
            marginTop: verticalScale(16),
            width: '100%',
        },
    });

export default stylesMain;
