import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import font from '@helpers/font';

import { theme } from '../../types';
import { TYPO_TEXT } from './types';

const styles = (theme: theme) =>
    StyleSheet.create({
        [TYPO_TEXT.DEFAULT]: {
            color: Colors[theme].text.primary,
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),
        },
        [TYPO_TEXT.SUBHEAD_BIG_BOLD]: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
        },
        [TYPO_TEXT.SUBHEAD_SMALL_SEMIBOLD]: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
        },
        [TYPO_TEXT.SUBHEAD_SMALL_BOLD]: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
        },
        [TYPO_TEXT.HEADING_H2]: {
            color: Colors[theme].text.primary,
            ...font({
                size: 32,
                height: 40,
                weight: '800',
            }),
            letterSpacing: -0.4,
            textAlign: 'center',
        },
        [TYPO_TEXT.HEADING_H2_SEMIBOLD]: {
            color: Colors[theme].text.primary,
            ...font({
                size: 32,
                height: 40,
                weight: '600',
            }),
            letterSpacing: -0.4,
            textAlign: 'center',
        },

        [TYPO_TEXT.HEADING_H4]: {
            color: Colors[theme].text.primary,
            ...font({
                size: 22,
                height: 30,
                weight: '700',
            }),
        },
        [TYPO_TEXT.HEADING_H5]: {
            color: Colors[theme].text.primary,
            ...font({
                size: 20,
                height: 26,
                weight: '700',
            }),
        },
        [TYPO_TEXT.BODY_BIG]: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '500',
            }),
            letterSpacing: 0.16,
            textAlign: 'center',
        },
        [TYPO_TEXT.BODY_SMALL]: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 21,
                weight: '400',
            }),
            letterSpacing: 0.08,
        },
        [TYPO_TEXT.BODY_LITTLE]: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
            textAlign: 'center',
        },
        [TYPO_TEXT.SUBHEAD_MEDIUM_BOLD]: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '700',
            }),
            textAlign: 'center',
        },

        [TYPO_TEXT.HEADING_H6]: {
            color: Colors[theme].text.primary,
            ...font({
                size: 18,
                height: 22,
                weight: '700',
            }),
        },
        [TYPO_TEXT.HEADING_H3]: {
            color: Colors[theme].text.primary,
            ...font({
                size: 28,
                height: 36,
                weight: '700',
            }),
            textAlign: 'center',
        },
        [TYPO_TEXT.SUBHEAD_LITTLE_BOLD]: {
            color: Colors[theme].text.primary,
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),
        },
        [TYPO_TEXT.SUBHEAD_LITTLE_SEMIBOLD]: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '600',
            }),
        },
        [TYPO_TEXT.BODY_MEDIUM]: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
        [TYPO_TEXT.SUBHEAD_MEDIUM_SEMIBOLD]: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '600',
            }),
        },
    });

export default styles;
