import { StyleSheet } from 'react-native';

import { isWeb } from '@helpers/app';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import { stylesModalNavigationFix } from '@helpers/style';

import Colors from '../../../../constants/Colors';
import font from '../../../../helpers/font';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
            paddingBottom: isWeb ? verticalScale(20) : 0,
            ...stylesModalNavigationFix(),
        },
        itemContainer: {
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            paddingHorizontal: scale(20),
            backgroundColor: 'transparent',
        },
        imgWrapper: {
            alignSelf: 'center',
            marginBottom: verticalScale(24),
        },
        header: {
            paddingHorizontal: scale(20),
        },
        price: {
            paddingVertical: verticalScale(6),
            paddingLeft: scale(12),
            paddingRight: scale(8),
            borderRadius: moderateScale(50),
            backgroundColor: Colors[theme].backGroundPriceMysteryBox,
            flexDirection: 'row',
            alignItems: 'center',
            alignSelf: 'center',
            marginBottom: verticalScale(16),
            minHeight: 36,
        },
        priceText: {
            marginRight: verticalScale(6),
            color: Colors[theme].text.accent,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
        },
        buttonContainer: {
            paddingTop: verticalScale(32),
        },
    });

export default styles;
