import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        rankOne: {
            backgroundColor: 'transparent',
        },
        rankTwo: {
            backgroundColor: 'transparent',
            zIndex: 10,
            left: -8,
        },
        oneColorArrow: {
            color: Colors[theme].alwaysOneColor.background,
        },
        arrow: {
            color: Colors[theme].icon.decorativePrimary,
        },
        rankThree: {
            backgroundColor: 'transparent',
            marginLeft: 8,
        },
    });

export default styles;
