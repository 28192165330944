import * as React from 'react';

import CustomHorizontalModal from '@components/CustomHorizontalModal';
import { View } from '@components/Themed';
import { cactusGameEndpoint, isDesktop, isWeb } from '@helpers/app';
import { stylesFlex } from '@helpers/style';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import { NftRoyalPlayGameProps } from '../../../../../types';
import PauseGameTimer from '../../../Play/PlayToEarn/components/PauseGameTimer';
import { UnityWrapper } from '../../../Play/UnityWrapper';
import NftRoyalLoadingScreen from '../../components/NftRoyalLoadingScreen';
import { useNftRoyalGameProgress } from './hook';
import stylesMain from './styles';

const NftRoyalPlayGameScreen = ({ route }: NftRoyalPlayGameProps) => {
    const styles = useThemedStyles(stylesMain);

    const {
        gameVisible,
        handleOnMessage,
        unityWrapperRef,
        onTimerEnd,
        errorModalOpen,
        renderAttempt,
        errorModal,
        handleOnErrorAgree,
        exitOnErrorModal,
        errorText,
        prepareLoading,
        showTimerLoading,
        finishLoading,
        absoluteLoading,
    } = useNftRoyalGameProgress(route.params?.isNewCreated);

    return (
        <View style={styles.main} key={`renderAttempt-${renderAttempt}`}>
            {prepareLoading && <NftRoyalLoadingScreen isLoading />}
            {!route.params?.isNewCreated && finishLoading && (
                <NftRoyalLoadingScreen isLoading type="complete" />
            )}
            {absoluteLoading && <View style={styles.absoluteLoading} />}
            <View style={stylesFlex()}>
                {gameVisible && (
                    <UnityWrapper
                        openErrorModal={() => errorModalOpen()}
                        // @ts-ignore
                        ref={unityWrapperRef}
                        uri={cactusGameEndpoint}
                        onMessage={handleOnMessage}
                    />
                )}
                <PauseGameTimer
                    skip
                    withOpacity
                    visibility={showTimerLoading}
                    onTimerEnd={onTimerEnd}
                    startTimer
                    gameTimerText={i18n.t(
                        `playToEarn.modals.gameTimer.text${
                            isWeb
                                ? isDesktop
                                    ? 'WebDesktop'
                                    : 'WebMobile'
                                : ''
                        }`
                    )}
                />

                <CustomHorizontalModal
                    isVisible={errorModal}
                    infoText={errorText}
                    titleText={i18n.t('errorBoundary.title')}
                    firstButtonText={i18n.t('errorBoundary.reloadApp')}
                    secondButtonText={i18n.t(
                        'moveToEarn.modals.leaveWithOutFinishBtn1'
                    )}
                    onFirstButtonClick={handleOnErrorAgree}
                    onSecondButtonClick={exitOnErrorModal}
                />
            </View>
        </View>
    );
};

export default NftRoyalPlayGameScreen;
