import { useState } from 'react';

import { GET_MYSTERY_LIKES_PENDING_GAMES } from '@Data/Requests';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import { errorsHandler } from '@helpers/errors';
import { useLoadingHook } from '@hooks/useLoadingHook';
import {
    MysteryLikesMyPendingGame,
    MysteryLikesMyPendingGamesResponse,
} from '@models/mysteryGames';

export const useMysteryLikesMyPendingGames = () => {
    const client = useApolloClient();

    const [mysteryLikesMyPendingGames, setMysteryLikesMyPendingGames] =
        useState<MysteryLikesMyPendingGame[]>();

    const {
        isLoading: mysteryLikesMyPendingGamesLoading,
        startLoading: startMysteryLikesMyPendingGames,
        stopLoading: stopMysteryLikesMyPendingGames,
    } = useLoadingHook();

    const [
        getMysteryLikesMyPendingGamesLazy,
        { loading: getMysteryLikesMyPendingGamesLazyLoading },
    ] = useLazyQuery<MysteryLikesMyPendingGamesResponse>(
        GET_MYSTERY_LIKES_PENDING_GAMES,
        {
            fetchPolicy: 'no-cache',
        }
    );

    const getMysteryLikesMyPendingGames = async (silent?: boolean) => {
        try {
            !silent && startMysteryLikesMyPendingGames();
            const result =
                await client.query<MysteryLikesMyPendingGamesResponse>({
                    query: GET_MYSTERY_LIKES_PENDING_GAMES,
                    fetchPolicy: 'no-cache',
                });
            if (result.data) {
                setMysteryLikesMyPendingGames(
                    result.data.mysteryLikesMyPendingGames
                );
            }
            stopMysteryLikesMyPendingGames();
        } catch (error) {
            stopMysteryLikesMyPendingGames();
            errorsHandler(error, true);
        }
    };

    return {
        getMysteryLikesMyPendingGames,
        mysteryLikesMyPendingGamesLoading,
        mysteryLikesMyPendingGames,
        getMysteryLikesMyPendingGamesLazy,
        getMysteryLikesMyPendingGamesLazyLoading,
    };
};
