import React, { useEffect, useRef } from 'react';
import { TextInput, TouchableOpacity } from 'react-native';

import { View } from '@components/Themed';
import Colors from '@constants/Colors';
import { useAppState } from '@contexts/AppStateContext';
import { useModal } from '@contexts/ModalContext';
import { useTheme } from '@contexts/ThemeContext';
import { waitForModal } from '@helpers/helpers';
import { useLoadingHook } from '@hooks/useLoadingHook';
import useThemedStyles from '@hooks/useThemedStyles';

import mainStyles from './styles';

type Props = {
    maxLength: number;
    code: string;
    setCode: (code: string) => void;
};

export const PassCode = ({ maxLength, code, setCode }: Props) => {
    const styles = useThemedStyles(mainStyles);
    const ref = useRef<HTMLInputElement>();
    const { theme } = useTheme();

    const { isLoading, stopLoading } = useLoadingHook(true);

    useEffect(() => {
        const keyBoard = async () => {
            await waitForModal();
            stopLoading();
            ref.current?.focus();
        };
        keyBoard();
    }, [ref]);

    const onChangeText = (val: string) => {
        if (!isNaN(Number(val))) {
            setCode(val);
        }
    };

    return (
        <>
            {isLoading ? null : (
                <TextInput
                    ref={ref}
                    autoFocus={true}
                    style={styles.input}
                    maxLength={maxLength}
                    keyboardType="numeric"
                    value={code}
                    onChangeText={onChangeText}
                />
            )}
            <View style={{ flexDirection: 'row' }}>
                {new Array(maxLength).fill('').map((_, id) => (
                    <TouchableOpacity
                        activeOpacity={1}
                        style={styles.container}
                        key={'dot-' + id}
                        onPress={() => ref.current?.focus()}>
                        <View
                            style={[
                                styles.dot,
                                {
                                    backgroundColor:
                                        id < code.length
                                            ? Colors[theme].icon.green
                                            : undefined,
                                },
                            ]}
                        />
                    </TouchableOpacity>
                ))}
            </View>
        </>
    );
};
