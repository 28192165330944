import * as React from 'react';

import {
    CreateMoveTaskResponse,
    CreatePlayTaskResponse,
    MarkMoveTaskDoneResponse,
    MarkPlayTaskDoneResponse,
} from '@Data/Models';
import {
    CREATE_MOVE_TASK,
    CREATE_PLAY_TASK,
    MARK_MOVE_TASK_DONE,
    MARK_PLAY_TASK_DONE,
} from '@Data/Requests';
import { useMutation } from '@apollo/client';
import { useUser } from '@contexts/UserContext';
import { errorsHandler } from '@helpers/errors';
import { playTaskDoneParameters } from '@helpers/playTask';
import { useLoadingHook } from '@hooks/useLoadingHook';

import { moveTaskDoneParameters } from '../../TabBar/Play/MoveToEarn/helpers/moveHelper';

export const useDevM2EP2EEarn = () => {
    const { user } = useUser();

    const {
        isLoading: isMoveLoading,
        startLoading: startLoadingMove,
        stopLoading: stopLoadingMove,
    } = useLoadingHook();
    const {
        isLoading: isPlayLoading,
        startLoading: startLoadingPlay,
        stopLoading: stopLoadingPlay,
    } = useLoadingHook();

    // TO GET MAX ENERGY FOR M2E, P2E
    const [createMoveTaskMutation] =
        useMutation<CreateMoveTaskResponse>(CREATE_MOVE_TASK);

    const [markMoveTaskDone] =
        useMutation<MarkMoveTaskDoneResponse>(MARK_MOVE_TASK_DONE);

    const [markPlayTaskDoneMutation] =
        useMutation<MarkPlayTaskDoneResponse>(MARK_PLAY_TASK_DONE);

    const [createPlayTaskMutation] =
        useMutation<CreatePlayTaskResponse>(CREATE_PLAY_TASK);

    const earnPlayEnergy = React.useCallback(async () => {
        try {
            if (!user) {
                return;
            }
            startLoadingPlay();
            const res = await createPlayTaskMutation();
            if (res.data) {
                const variables = playTaskDoneParameters(
                    user,
                    res.data.createPlayTask,
                    res.data.createPlayTask.maxPoints,
                    3,
                    10
                );
                await markPlayTaskDoneMutation({
                    variables,
                });
            }
        } catch (error) {
            errorsHandler(error, true);
            stopLoadingPlay();
        }
        stopLoadingPlay();
    }, [user]);

    const earnMoveEnergy = React.useCallback(async () => {
        if (!user) {
            return;
        }
        try {
            startLoadingMove();
            const res = await createMoveTaskMutation();
            if (res.data) {
                const variables = moveTaskDoneParameters(
                    user.id,
                    res.data.createMoveTask.maxTimeSeconds - 1,
                    res.data.createMoveTask,
                    res.data.createMoveTask.maxDistanceMeters / 1000
                );
                await markMoveTaskDone({
                    variables,
                });
            }
        } catch (error) {
            errorsHandler(error, true);
            stopLoadingMove();
        }
        stopLoadingMove();
    }, [user]);

    return { earnPlayEnergy, earnMoveEnergy, isMoveLoading, isPlayLoading };
};
