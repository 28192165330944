import * as React from 'react';
import { Image } from 'react-native';

import NftFeatures from '@assets/icons/marketplace/nftFeatures.svg';
import Divider from '@components/Divider';
import { Text, View } from '@components/Themed';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { priceString } from '@helpers/payments';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import {
    MarketplaceNftDetailsOrderOutput,
    MarketplaceNftDetailsPetOutput,
    MarketplaceNftDetailsSellerOutput,
} from '@models/marketplace';

import stylesMain from './styles';

interface IProps {
    pet: MarketplaceNftDetailsPetOutput;
    order: MarketplaceNftDetailsOrderOutput;
    owner: MarketplaceNftDetailsSellerOutput;
}

const TransactionDetailsBuy = ({ pet, order, owner }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const petFeatures = React.useMemo(() => {
        const rank = i18n.t('ranks.rankLongTitle', {
            rank: i18n.t(`ranks.list.${pet.rank}.title`),
        });
        const level = i18n.t('general.KEYS.numberAndLevel', {
            level: pet.level,
        });

        const health = i18n.t('general.KEYS.numberAndHealth', {
            health: pet.healthPercentage,
        });
        const age = pet.ageDead
            ? i18n.t('general.KEYS.deadByAge')
            : i18n.t('general.KEYS.numberAndDaysAge', {
                  days: pet.ageDays,
              });
        return `${rank}, ${level}, ${health}, ${age}`;
    }, [pet]);

    return (
        <View style={styles.container}>
            <View style={styles.verificationItem}>
                <View style={styles.left}>
                    <View>
                        <Image
                            style={styles.image}
                            source={{
                                uri: pet.imageUrl,
                            }}
                            resizeMode={'contain'}
                        />
                    </View>
                    <View style={styles.verificationTextContainer}>
                        <Text style={styles.verificationTitle}>
                            {i18n.t('checkout.fields.nft')}
                        </Text>
                        <Text style={styles.verificationSlug} numberOfLines={1}>
                            {pet.name}
                        </Text>
                    </View>
                </View>
                <View style={styles.right}>
                    <Text
                        style={styles.verificationSlug}
                        ellipsizeMode="middle"
                        numberOfLines={1}>
                        {priceString(
                            balanceFromWei(order.priceIguAmountWei).valueLong,
                            Coin.igu
                        )}
                    </Text>
                </View>
            </View>
            <Divider customStyles={styles.divider} />
            <View style={styles.verificationItem}>
                <View style={styles.left}>
                    <View>
                        <NftFeatures width={64} height={64} />
                    </View>
                    <View style={styles.verificationTextContainer}>
                        <Text style={styles.verificationTitle}>
                            {i18n.t('general.KEYS.details')}
                        </Text>
                        <Text style={styles.verificationSlug}>
                            {petFeatures}
                        </Text>
                    </View>
                </View>
            </View>
        </View>
    );
};
export default TransactionDetailsBuy;
