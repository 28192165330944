import * as React from 'react';
import { TouchableOpacity, View } from 'react-native';

import { Text2ImageQuotaOutput } from '../../../../Data/Models';
import AlertCard, { AlertCardType } from '../../../../components/AlertCard';
import { Text } from '../../../../components/Themed';
import { NFT_GENERATION_OPTIONS_NAME } from '../../../../constants/NFTGenerationOptions';
import { scale } from '../../../../helpers/dimensions';
import { ISystemLoadType, IUsagesLeft } from '../../../../hooks/useAIInfo';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import ModalAiInfo from '../ModalAiInfo';
import stylesMain from './styles';
import { ISelectableWay } from './types';

interface IProps {
    option: ISelectableWay;
    selectedOption: string | null;
    onPress: (id: NFT_GENERATION_OPTIONS_NAME) => void;
    usagesLeft?: IUsagesLeft | null;
    dailySystemLoad?: ISystemLoadType | null;
    currentSystemLoad?: ISystemLoadType | null;
    aiQuota?: Text2ImageQuotaOutput | null;
}

const SelectableWay = ({
    option,
    selectedOption,
    onPress,
    usagesLeft,
    dailySystemLoad,
    currentSystemLoad,
    aiQuota,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const [textLeft, setTextLeft] = React.useState(77.5);
    const [alertModal, setAlertModal] = React.useState(false);

    const { id, title, text, icon } = option;

    const withAlert = React.useMemo(() => id === 'IMAGINATION', [id]);

    const alertCardInfo: {
        text: string;
        type: AlertCardType;
    } | null = React.useMemo(() => {
        if (withAlert) {
            if (
                dailySystemLoad === 'overloaded' ||
                currentSystemLoad === 'overloaded'
            ) {
                return {
                    text: i18n.t('aiQuoteInfo.alertCard.overload.title'),
                    type: 'exclamation',
                };
            }
            if (usagesLeft?.usages === 0) {
                return {
                    text: i18n.t('aiQuoteInfo.alertCard.dailyAttempts.title', {
                        attempts: usagesLeft?.usages || 0,
                    }),
                    type: 'exclamation',
                };
            }
            return {
                text: i18n.t('aiQuoteInfo.alertCard.dailyAttempts.title', {
                    attempts: usagesLeft?.usages || '',
                }),
                type: 'warning',
            };
        }
        return null;
    }, [withAlert, usagesLeft, dailySystemLoad, currentSystemLoad]);

    const isDisabled = React.useMemo(
        () => alertCardInfo?.type === 'exclamation',
        [alertCardInfo]
    );

    const isSelected = React.useMemo(
        () => selectedOption === id && !isDisabled,
        [selectedOption, isDisabled]
    );

    const alertModalOpen = () => {
        setAlertModal(true);
    };
    const alertModalClose = () => {
        setAlertModal(false);
    };

    return (
        <>
            <TouchableOpacity
                activeOpacity={0.7}
                disabled={isDisabled}
                style={[
                    styles.shadow,
                    isSelected && styles.selectedShadow,
                    isDisabled && styles.disabled,
                ]}
                onPress={() => onPress(id)}>
                <View style={[styles.container, isSelected && styles.selected]}>
                    <View style={styles.wrapper}>
                        <View style={styles.imgWrapper}>{icon}</View>
                        <View
                            style={styles.textWrapper}
                            onLayout={(eve) =>
                                setTextLeft(eve.nativeEvent.layout.x)
                            }>
                            {withAlert && <View style={styles.gap} />}
                            <View style={styles.titleWrapper}>
                                <Text style={styles.title}>
                                    {i18n.t(title)}
                                </Text>
                            </View>
                            <View style={styles.infoWrapper}>
                                <Text style={styles.infoTitle}>
                                    {i18n.t(text)}
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
            </TouchableOpacity>
            {withAlert && (
                <>
                    <View
                        style={[
                            styles.alertCardWrapper,
                            { left: textLeft + scale(12) },
                        ]}>
                        <AlertCard
                            text={alertCardInfo?.text || ''}
                            type={alertCardInfo?.type || 'warning'}
                            containerStyle={styles.alertCard}
                            textStyles={styles.textStyles}
                            onPress={alertModalOpen}
                        />
                    </View>
                    <ModalAiInfo
                        isVisible={alertModal}
                        onClose={alertModalClose}
                        usagesLeft={usagesLeft}
                        dailySystemLoad={dailySystemLoad}
                        currentSystemLoad={currentSystemLoad}
                        aiQuota={aiQuota}
                    />
                </>
            )}
        </>
    );
};
export default SelectableWay;
