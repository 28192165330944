import React from 'react';

import { createNativeStackNavigator } from '@react-navigation/native-stack';

import AdminAssignRoleScreen from '../../screens/Admin/AdminAssignRole/AdminAssignRoleScreen';
import AdminAssignWhiteListScreen from '../../screens/Admin/AdminAssignWhiteList/AdminAssignWhiteListScreen';
import AdminBlockUserScreen from '../../screens/Admin/AdminBlockUser/AdminBlockUserScreen';
import AdminMaintenanceModeScreen from '../../screens/Admin/AdminMaintenanceMode/AdminMaintenanceModeScreen';
import AdminNftRoyaleRevokeBanScreen from '../../screens/Admin/AdminNftRoyaleRevokeBan/AdminNftRoyaleRevokeBanScreen';
import AdminReverificationScreen from '../../screens/Admin/AdminReverification/AdminReverificationScreen';
import AdminSendPushNotificationScreen from '../../screens/Admin/AdminSendPushNotification/AdminSendPushNotificationScreen';
import AccessibilitySettingsScreen from '../../screens/Profile/AccessibilitySettings/AccessibilitySettingsScreen';
import LanguagesSettingsScreen from '../../screens/Profile/LanguagesSettings/LanguagesSettingsScreen';
import NotificationsSettingsScreen from '../../screens/Profile/NotificationsSettings/NotificationsSettingsScreen';
import ProfileScreen from '../../screens/Profile/ProfileScreen';
import SocialAccountsSettingsScreen from '../../screens/Profile/SocialAccountsSettings/SocialAccountsSettingsScreen';
import SocialMediaScreen from '../../screens/Profile/SocialMedia/SocialMediaScreen';
import { ProfileStackParamList } from '../../types';
import ROUTES from '../routes';

const ProfileStack = createNativeStackNavigator<ProfileStackParamList>();

const ProfileNavigator = (RootStack: any) => {
    return (
        <ProfileStack.Navigator
            initialRouteName={ROUTES.PROFILE_MAIN}
            screenOptions={{
                headerShown: false,
            }}>
            <ProfileStack.Screen
                name={ROUTES.PROFILE_MAIN}
                component={ProfileScreen}
            />
            <ProfileStack.Screen
                name={ROUTES.PROFILE_LANGUAGES_SETTINGS}
                component={LanguagesSettingsScreen}
            />
            <ProfileStack.Screen
                name={ROUTES.PROFILE_NOTIFICATIONS_SETTINGS}
                component={NotificationsSettingsScreen}
            />
            <ProfileStack.Screen
                name={ROUTES.PROFILE_SOCIAL_ACCOUNTS_SETTINGS}
                component={SocialAccountsSettingsScreen}
            />
            <ProfileStack.Screen
                name={ROUTES.PROFILE_ACCESSIBILITY_SETTINGS}
                component={AccessibilitySettingsScreen}
            />
            <ProfileStack.Screen
                name={ROUTES.PROFILE_SOCIAL_MEDIA}
                component={SocialMediaScreen}
            />
            <ProfileStack.Screen
                name={ROUTES.ADMIN_SEND_PUSH_NOTIFICATION}
                component={AdminSendPushNotificationScreen}
            />
            <ProfileStack.Screen
                name={ROUTES.ADMIN_REVERIFICATION}
                component={AdminReverificationScreen}
            />
            <ProfileStack.Screen
                name={ROUTES.ADMIN_BLOCK_USER}
                component={AdminBlockUserScreen}
            />
            <ProfileStack.Screen
                name={ROUTES.ADMIN_MAINTENANCE_MODE}
                component={AdminMaintenanceModeScreen}
            />
            <ProfileStack.Screen
                name={ROUTES.ADMIN_ASSIGN_WHITE_LIST}
                component={AdminAssignWhiteListScreen}
            />
            <ProfileStack.Screen
                name={ROUTES.ADMIN_ASSIGN_ROLE}
                component={AdminAssignRoleScreen}
            />
            <ProfileStack.Screen
                name={ROUTES.ADMIN_NFT_ROYAL_REVOKE_BAN}
                component={AdminNftRoyaleRevokeBanScreen}
            />
        </ProfileStack.Navigator>
    );
};

export default ProfileNavigator;
