import * as React from 'react';
import { LayoutRectangle, TouchableOpacity } from 'react-native';

import _ from 'lodash';

import RankIcon from '@components/Icons/RankIcon';
import { View } from '@components/Themed';
import { useHaptic } from '@hooks/useHaptic';
import useThemedStyles from '@hooks/useThemedStyles';
import { BreedingPetDraftOutput } from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import Icon from '../../../../../assets/icons/mysteryGames/nftEvolution/draft.svg';
import stylesMain from './styles';

interface IProps {
    size: LayoutRectangle;
    pet: BreedingPetDraftOutput;
}

const PetCollectionBreedDraftCard = ({ size, pet }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const { triggerHeavy } = useHaptic();
    const { height, width } = size;

    const handleDebounce = React.useCallback(
        _.debounce(
            () => {
                triggerHeavy();
                navigation.push(ROUTES.NFT_EVOLUTION_SUCCESS, {
                    id: pet.id,
                });
            },
            2000,
            {
                leading: true,
                trailing: false,
            }
        ),
        []
    );

    return (
        <TouchableOpacity
            activeOpacity={0.7}
            style={[styles.container, { width, height }]}
            onPress={handleDebounce}>
            <View style={styles.rank}>
                <RankIcon slug={pet.rank} size={24} />
            </View>

            <Icon width={48} height={48} />
        </TouchableOpacity>
    );
};
export default React.memo(PetCollectionBreedDraftCard);
