import * as React from 'react';
import {
    StyleProp,
    TextStyle,
    TouchableOpacity,
    ViewStyle,
} from 'react-native';
import { SvgProps } from 'react-native-svg';

import _ from 'lodash';

import { useHaptic } from '@hooks/useHaptic';
import useThemedStyles from '@hooks/useThemedStyles';

import ActivityIndicator from '../ActivityIndicator';
import Icon, { ICON_NAMES } from '../Icons';
import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    title: string;
    text: string;
    onPress: () => void;
    disabled?: boolean;
    containerStyle?: StyleProp<ViewStyle>;
    iconName?: ICON_NAMES | null;
    icon?: React.ReactElement<SvgProps>;
    debouncedPress?: boolean;
    debounceTime?: number;
    loading?: boolean;
    loadingText?: string;
    withHaptic?: boolean;
    withArrow?: boolean;
    labelStyle?: StyleProp<TextStyle>;
    textStyle?: StyleProp<TextStyle>;
    isSelected?: boolean;
    iconNameText?: string;
}

const ClickableCard = ({
    title,
    onPress,
    disabled,
    containerStyle,
    iconName,
    icon,
    text,
    debouncedPress,
    debounceTime = 2000,
    loading,
    loadingText,
    withHaptic = true,
    withArrow,
    labelStyle,
    textStyle,
    isSelected,
    iconNameText,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { triggerMedium } = useHaptic();

    const onPressRef = React.useRef(onPress);

    React.useEffect(() => {
        onPressRef.current = onPress;
    }, [onPress]);

    const handlePress = async () => {
        if (withHaptic) await triggerMedium();
        onPressRef.current();
    };

    // debounce callback
    const handleDebounce = React.useCallback(
        _.debounce(
            () => {
                handlePress();
            },
            debounceTime,
            {
                leading: true,
                trailing: false,
            }
        ),
        [withHaptic, triggerMedium, debounceTime]
    );

    return (
        <TouchableOpacity
            activeOpacity={0.7}
            disabled={disabled || loading}
            style={[
                styles.shadow,
                disabled && styles.disabled,
                isSelected && styles.selectedShadow,
                containerStyle,
            ]}
            onPress={debouncedPress ? handleDebounce : handlePress}>
            {loading && (
                <View style={styles.overlay}>
                    <ActivityIndicator loading={loading} />
                    {loadingText && (
                        <Text style={[styles.text, styles.loadingText]}>
                            {loadingText}
                        </Text>
                    )}
                </View>
            )}
            <View
                style={[
                    styles.containerButton,
                    isSelected && styles.selectedContainer,
                ]}>
                <View style={styles.left}>
                    {iconName ? <Icon name={iconName} /> : icon}

                    {!!iconNameText && (
                        <Text style={styles.iconNameText}>{iconNameText}</Text>
                    )}
                </View>
                <View style={styles.right}>
                    <Text style={[styles.text, textStyle]}>{title}</Text>
                    <Text style={[styles.label, labelStyle]}>{text}</Text>
                </View>
                {withArrow && (
                    <View style={styles.arrow}>
                        <Icon
                            name={ICON_NAMES.ARROW_RIGHT_SECONDARY}
                            color={styles.arrowRight.color}
                            size={20}
                        />
                    </View>
                )}
            </View>
        </TouchableOpacity>
    );
};

export default ClickableCard;
