import * as React from 'react';
import { FlatList } from 'react-native-gesture-handler';

import { Pet, TokenTransaction } from '../../../../../Data/Models';
import Button from '../../../../../components/Button';
import EmptyList from '../../../../../components/EmptyList';
import Icon, { ICON_NAMES } from '../../../../../components/Icons';
import LoadingComponent from '../../../../../components/LoadingComponent';
import { Text, View } from '../../../../../components/Themed';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import { MemoizedNFTSTransactionItem } from '../NFTSTransactionItem';
import stylesMain from './styles';

interface IProps {
    transactions: Array<TokenTransaction> | undefined;
    pets: Array<Pet> | undefined;
    loading?: boolean;
    onDepositPress?: () => void;
    loadMore: boolean;
    onReachEnd: () => void;
}

interface IRenderItem {
    item: TokenTransaction;
    index: number;
}

const WalletNFTSTransactionHistoryList: React.FunctionComponent<IProps> = ({
    transactions = [],
    pets = [],
    loading = false,
    onDepositPress = () => null,
    loadMore,
    onReachEnd,
}) => {
    const styles = useThemedStyles(stylesMain);

    const renderItem = ({ item }: IRenderItem) => {
        return <MemoizedNFTSTransactionItem item={item} pets={pets} />;
    };

    return (
        <View>
            <View style={styles.titleContainer}>
                <View style={styles.titleWrapper}>
                    <Text style={styles.title}>
                        {i18n.t('wallet.nft.transactionHistory')}
                    </Text>
                </View>
            </View>
            <FlatList
                onEndReachedThreshold={0.1}
                initialNumToRender={10}
                onEndReached={onReachEnd}
                data={transactions}
                renderItem={renderItem}
                numColumns={1}
                keyExtractor={(_, index) =>
                    `{nft-transaction-history}-${index}`
                }
                listKey={'NFTSTransactionHistory'}
                ListEmptyComponent={
                    loading ? (
                        <LoadingComponent
                            containerStyles={styles.loadingWrapper}
                            text={i18n.t('general.loading')}
                        />
                    ) : (
                        <View style={styles.noTransactionWrapper}>
                            <EmptyList
                                emptyTitle={i18n.t(
                                    'wallet.nft.noTransactionYet'
                                )}
                                emptyText={i18n.t(
                                    'wallet.nft.noTransactionYetInfo'
                                )}
                                ButtonComponent={
                                    <Button
                                        type="text"
                                        title={i18n.t('wallet.deposit')}
                                        onPress={onDepositPress}
                                        icon={
                                            <Icon
                                                name={ICON_NAMES.DEPOSIT}
                                                color={styles.icon.color}
                                            />
                                        }
                                        containerStyle={styles.button}
                                    />
                                }
                                withAnimation="cry"
                            />
                        </View>
                    )
                }
            />
            {loadMore && (
                <LoadingComponent
                    containerStyles={styles.loadingWrapper}
                    text={i18n.t('general.loading')}
                />
            )}
        </View>
    );
};

export default WalletNFTSTransactionHistoryList;
