import * as React from 'react';
import { FlatList } from 'react-native';

import { WhitelistRanksOutput } from '@Data/Models';
import RankIcon from '@components/Icons/RankIcon';
import ModalBottom from '@components/ModalBottom';
import SelectorItem from '@components/SelectorItem';
import { Text } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    selectedRank: string;
    ranks: WhitelistRanksOutput[];
    onPress: (slug: string) => void;
    modalHeight?: number | string;
    close: () => void;
}

const RankSelectModal = ({
    isVisible,
    onPress,
    selectedRank,
    ranks,
    modalHeight = '70%',
    close,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const handleSelect = React.useCallback(
        (slug: string) => {
            if (selectedRank === slug) {
                return;
            }
            onPress(slug);
            close();
        },
        [selectedRank]
    );

    return (
        <>
            {!!ranks && (
                <ModalBottom
                    isVisible={isVisible}
                    onClose={close}
                    modalHeight={modalHeight}
                    titleText={i18n.t(
                        'profile.adminAssignWhiteList.modal.screenTitle'
                    )}>
                    <FlatList
                        showsVerticalScrollIndicator={false}
                        style={styles.listStyle}
                        data={ranks}
                        renderItem={({ item }) => (
                            <SelectorItem
                                isSelected={item.slug === selectedRank}
                                children={
                                    <>
                                        <RankIcon slug={item.slug} size={32} />
                                        <Text style={styles.rankTitle}>
                                            {i18n.t(
                                                `ranks.list.${item.slug}.title`
                                            )}
                                        </Text>
                                    </>
                                }
                                handleSelect={() => handleSelect(item.slug)}
                            />
                        )}
                        keyExtractor={({ slug }) => slug}
                    />
                </ModalBottom>
            )}
        </>
    );
};

export default RankSelectModal;
