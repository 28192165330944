enum ICON_NAMES {
    SETTINGS = 'settings',
    NFTS = 'nfts',
    GOOGLE = 'google',
    FACEBOOK = 'facebook',
    INSTAGRAM = 'instagram',
    TIKTOK = 'tikTok',
    APPLE = 'apple',
    CLOSE = 'close',
    ARROW_LEFT = 'arrowLeft',
    ARROW_RIGHT = 'arrowRight',
    ARROW_RIGHT_SECONDARY = 'arrowRightSecondary',
    ARROW_DROP_UP = 'arrowDropUp',
    ARROW_DROP_DOWN = 'arrowDropDown',
    ARROW_DROP_DOWN_SELECT = 'arrowDropDownSelect',
    ARROW_UP = 'arrowUp',
    ARROW_DOWN = 'arrowDown',
    DOWN_ARROW = 'downArrow',
    CAMERA = 'camera',
    PLUS = 'plus',
    MINUS = 'minus',
    BIN = 'bin',
    TWITTER = 'twitter',
    PENCIL = 'pencil',
    DOWNLOAD = 'download',
    FULL_SIZE = 'fullSize',
    DEPOSIT = 'deposit',
    WITHDRAW = 'withdraw',
    TRADE = 'trade',
    SCAN = 'scan',
    LOCK = 'lock',
    SEARCH = 'search',
    ENERGY = 'energy',
    BISWAP = 'biswap',
    PLAY = 'play',
    PAUSE = 'pause',
    STOP = 'stop',
    CANCEL_ROUND = 'cancelRound',
    HEART = 'heart',
    ARROW_UPGRADE = 'ArrowUpgrade',
    LEVEL = 'level',
    EXPLORE = 'explore',
    COOLDOWN = 'cooldown',
    QUESTION = 'question',
    SHARE = 'share',
    HEALTH = 'health',
    DOLLAR = 'dollar',
    TASK = 'task',
    REVEAL = 'reveal',
    REVEALED = 'revealed',
    MUTE_AUDIO = 'muteAudio',
    UNMUTE_AUDIO = 'unmuteAudio',
    FRUIT = 'fruit',
    KEY = 'key',
    RESTORE = 'restore',
    LIKE = 'like',
    LIKE_FILL = 'likeFill',
    DISLIKE = 'dislike',
    DISLIKE_FILL = 'dislikeFill',
    LOCK_FILL = 'lockFill',
    RANDOM = 'random',
    COPY = 'copy',
    CHECK = 'check',
    CLAIM = 'claim',
    STAKE = 'stake',
    SUCCESS = 'success',
    REFRESH = 'refresh',
    SHOP_BAG = 'shopBag',
    INVITE = 'invite',
    CHART = 'chart',
    FEED = 'feed',
    ERROR = 'error',
    IS_DEAD = 'isDead',
    IS_AGE_DEAD = 'isAgeDead',
    DNA = 'dna',
    DELETE_SEARCH = 'deleteSearch',
    FILTER = 'filter',
}

export default ICON_NAMES;
