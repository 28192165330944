import { StyleSheet } from 'react-native';

import { moderateScale, scale, verticalScale } from '@helpers/dimensions';

import Colors from '../../constants/Colors';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        itemContainer: {
            backgroundColor: Colors[theme].background,
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            marginBottom: verticalScale(4),
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        shadow: {
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].purchasingFrom.shadowColorDefault,
        },
        gap: {
            marginBottom: scale(12),
        },
        left: {
            flexDirection: 'row',
            backgroundColor: 'transparent',
        },
        textWrapper: {
            marginLeft: verticalScale(8),
            backgroundColor: 'transparent',
        },
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
            marginBottom: verticalScale(2),
            backgroundColor: 'transparent',
        },
        infoWrapper: {
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            alignSelf: 'center',
            backgroundColor: 'transparent',
        },
        iconGreen: {
            color: Colors[theme].icon.green,
        },
        infoText: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
        coinText: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
            letterSpacing: 0,
            marginLeft: scale(5),
        },
        coinItem: {
            flexDirection: 'row',
            marginRight: scale(5),
            backgroundColor: 'transparent',
        },
        exploreWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: 'transparent',
        },
        textExplore: {
            color: Colors[theme].text.accent,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
            letterSpacing: 0.16,
            marginRight: scale(2),
        },
    });

export default styles;
