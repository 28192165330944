import React, { useCallback } from 'react';

import _ from 'lodash';

import { useApolloClient } from '@apollo/client';
import { errorsHandler } from '@helpers/errors';
import { useLoadingHook } from '@hooks/useLoadingHook';
import {
    LuckyStrikeGamesStatisticOutput,
    LuckyStrikeGamesStatisticOutputResponse,
} from '@models/mysteryGames';
import { GET_LUCKY_STRIKE_GAMES_STATISTICS } from '@requests/luckyStrike';

export const useLuckyStrikeWinnersStatistics = () => {
    const client = useApolloClient();

    const {
        isLoading: statisticsLoading,
        startLoading: startStatisticsLoading,
        stopLoading: stopStatisticsLoading,
    } = useLoadingHook();

    const {
        isLoading: refreshStatisticsLoading,
        startLoading: startRefreshStatisticsLoading,
        stopLoading: stopRefreshStaticsLoading,
    } = useLoadingHook();

    const [statistics, setStatistics] =
        React.useState<LuckyStrikeGamesStatisticOutput>();

    const stop = () => {
        stopStatisticsLoading();
        stopRefreshStaticsLoading();
    };

    const getStatistics = useCallback(
        async (silent: boolean) => {
            try {
                silent
                    ? startStatisticsLoading()
                    : startRefreshStatisticsLoading();

                const result =
                    await client.query<LuckyStrikeGamesStatisticOutputResponse>(
                        {
                            query: GET_LUCKY_STRIKE_GAMES_STATISTICS,
                            fetchPolicy: 'network-only',
                        }
                    );

                if (result.data) {
                    setStatistics(result.data.luckyStrikeGamesStatistic);
                }
            } catch (error) {
                errorsHandler(error, true);
            }
            stop();
        },
        [client]
    );

    return {
        getStatistics,
        statistics,
        statisticsLoading,
        refreshStatisticsLoading,
    };
};
