import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { moderateScale, scale, verticalScale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        captionDividerStyle: {
            ...font({
                size: 28,
                height: 36,
                weight: '600',
            }),
            marginHorizontal: scale(4),

            color: Colors[theme].text.primary,
        },
        captionItemStyle: {},
        captionTextStyle: {
            ...font({
                size: 12,
                height: 16,
                weight: '600',
            }),

            color: Colors[theme].text.secondary,
        },
        isLight: {
            color: Colors[theme].text.onLight,
        },
        captionDateStyle: {
            ...font({
                size: 32,
                height: 40,
                weight: '600',
            }),
            letterSpacing: -0.4,
            color: Colors[theme].text.primary,
        },
        timerBlockThreshold: {
            color: Colors[theme].text.danger,
        },
        container: {
            flexDirection: 'row',
            backgroundColor: 'transparent',
        },
    });
export default styles;
