import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        titleWrapper: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
        },

        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '600',
            }),
        },
        titleContainer: {
            marginLeft: verticalScale(8),
        },
    });

export default styles;
