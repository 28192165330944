import * as React from 'react';
import { Image, View } from 'react-native';
import QRCode from 'react-native-qrcode-svg';

import { Text } from '@components/Themed';
import { ISocialToEarnCard } from '@constants/shareCardTemplatesNew';
import { isWeb } from '@helpers/app';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    templateColor: ISocialToEarnCard;
    qrCodeLink: string;
    token: string | undefined;
    url: string | undefined;
}

const DefaultId = ({ templateColor, qrCodeLink, token, url }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const {
        title,
        qrCodeBackground,
        qrCodeImageBackground,
        qrCodeImageColor,
        qrCodeTitle,
        cardTypeId,
    } = templateColor;

    return (
        <>
            <View style={styles.titleBlock}>
                <Text
                    style={[styles.title, { color: title }]}
                    allowFontScaling={false}>
                    {i18n.t(
                        `socializeToEarn.createShareTemplate.socialCardTemplate.${cardTypeId}`
                    )}
                </Text>
            </View>
            <View style={styles.content}>
                <View style={styles.imageBlock}>
                    {isWeb ? (
                        <img src={url} style={styles.webImage} alt="Pet ID" />
                    ) : (
                        <Image
                            source={{
                                uri: url,
                            }}
                            style={[styles.petImage]}
                        />
                    )}
                </View>
                <View style={styles.textsBlock}>
                    <View
                        style={[
                            styles.qrCodeWrapper,
                            { backgroundColor: qrCodeBackground },
                        ]}>
                        <Text
                            allowFontScaling={false}
                            style={[
                                styles.qrCode,
                                { color: qrCodeTitle },
                            ]}>{`#${token}`}</Text>
                    </View>
                    <View
                        style={[
                            styles.qrCodeImage,
                            { backgroundColor: qrCodeImageBackground },
                        ]}>
                        {!!qrCodeLink && (
                            <QRCode
                                value={qrCodeLink}
                                size={styles.qrCodeSize.width}
                                color={qrCodeImageColor}
                            />
                        )}
                    </View>
                </View>
            </View>
        </>
    );
};
export default DefaultId;
