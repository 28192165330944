import * as React from 'react';
import { useMemo } from 'react';
import { FlatList } from 'react-native';

import {
    RewardsTransactionHistoryResponseItem,
    UserBalanceResponse,
} from '../../../../../Data/Models';
import LoadingComponent from '../../../../../components/LoadingComponent';
import { View } from '../../../../../components/Themed';
import { stylesLoadMore } from '../../../../../helpers/style';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import { MemoizedRewardsTransactionHistoryItem } from '../RewardsTransactionHistoryItem';
import stylesMain from './styles';

interface IRenderItem {
    item: RewardsTransactionHistoryResponseItem;
}

interface IProps {
    userBalance: UserBalanceResponse | undefined;
    data: RewardsTransactionHistoryResponseItem[];
    onReachEnd: () => void;
    loadMore: boolean;
}

const RewardsTransactionHistory = ({
    userBalance,
    data,
    loadMore,
    onReachEnd,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    // find last pending claim
    const lastPendingClaimId = useMemo(() => {
        if (!userBalance?.pendingBalanceClaim) {
            return null;
        }

        const pendingClaim = data.find((item) => item.type === 'Claim');

        return pendingClaim?.id;
    }, [data, userBalance?.pendingBalanceClaim]);

    const renderItem = React.useCallback(
        ({ item }: IRenderItem) => {
            return (
                <MemoizedRewardsTransactionHistoryItem
                    item={item}
                    lastPendingClaimId={lastPendingClaimId}
                />
            );
        },
        [lastPendingClaimId, data]
    );

    return (
        <>
            <FlatList
                showsVerticalScrollIndicator={false}
                data={data}
                keyExtractor={(item) => item.id}
                renderItem={renderItem}
                onEndReached={onReachEnd}
                onEndReachedThreshold={0.1}
            />
            {loadMore && (
                <View style={stylesLoadMore()}>
                    <LoadingComponent />
                </View>
            )}
        </>
    );
};
export default React.memo(RewardsTransactionHistory);
