import * as React from 'react';
import { useContext } from 'react';

import PlayTimerBlock from '@components/PlayTimerBlock';
import { ConfigContext } from '@contexts/ConfigContext';
import { usePlay } from '@contexts/PlayContext';
import { useUser } from '@contexts/UserContext';
import { renderListLengthMax } from '@helpers/helpers';
import useShowPetOrderedIdModal from '@hooks/useShowPetOrderedIdModal';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { useNavigation } from '@navigation/useNavigation';

import Button from '../Button';
import InfoIcon from '../InfoIcon/InfoIcon';
import { Text, View } from '../Themed';
import stylesMain from './styles';

const PlayNFTBlockHeader = () => {
    const { pets, playablePets, petsLoaded } = usePlay();
    const { user } = useUser();
    const { config } = useContext(ConfigContext);
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const maxPets = config?.maxActiveNFTsPerUser;

    const { renderOrderModal, showOrderModal } = useShowPetOrderedIdModal();

    const handleOnEditPress = () => {
        navigation.navigatePlayEditNFTS();
    };

    const canEdit = React.useMemo(
        () => Boolean(playablePets?.length || pets?.length),
        [playablePets, pets]
    );

    if (!config || !user) {
        return null;
    }

    return (
        <View style={styles.container}>
            {petsLoaded && (
                <>
                    <View style={styles.timerBlockWrapper}>
                        <PlayTimerBlock />
                    </View>
                    <View style={styles.containerHead}>
                        <View style={styles.textWrapper}>
                            <View style={styles.textLeft}>
                                <Text style={styles.ntfTopPlay}>
                                    {i18n.t('playScreen.nftToPlay')}{' '}
                                    {renderListLengthMax(
                                        Math.min(
                                            playablePets?.length ?? 0,
                                            Number(maxPets)
                                        ),
                                        maxPets
                                    )}
                                </Text>
                                <InfoIcon onPress={showOrderModal} />
                            </View>
                            {canEdit && (
                                <Button
                                    type="text"
                                    onPress={handleOnEditPress}
                                    title={i18n.t('playScreen.edit')}
                                />
                            )}
                        </View>
                        <Text style={styles.ntfTopPlayInfo}>
                            {i18n.t('playScreen.nftToPlayInfo')}
                        </Text>
                    </View>
                </>
            )}

            {renderOrderModal()}
        </View>
    );
};
export default PlayNFTBlockHeader;
