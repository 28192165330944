import * as React from 'react';
import { Platform, StyleSheet, Text } from 'react-native';

import Animation from '../../../../components/Animation';
import { View } from '../../../../components/Themed';
import Colors from '../../../../constants/Colors';
import { useDimensions } from '../../../../contexts/DimensionsContext';
import { isIOS } from '../../../../helpers/app';
import { verticalScale } from '../../../../helpers/dimensions';
import font from '../../../../helpers/font';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import { theme } from '../../../../types';

const Welcome: React.FunctionComponent = () => {
    const styles = useThemedStyles(style);
    const { windowWidth } = useDimensions();
    return (
        <View style={styles.container}>
            <View style={[styles.image, { height: windowWidth * 0.85 }]}>
                <Animation animation="start" />
            </View>
            <Text style={styles.title}>
                {i18n.t('login.welcomeTo')}{' '}
                <Text style={styles.titleName}>IguVerse</Text>
            </Text>
        </View>
    );
};
const style = (theme: theme) =>
    StyleSheet.create({
        container: {
            alignItems: 'center',
        },
        image: {
            width: '100%',
            marginTop: Platform.select({
                ios: verticalScale(-10),
                android: verticalScale(32),
                web: verticalScale(10),
            }),
        },
        title: {
            textAlign: 'center',
            marginTop: verticalScale(12),
            color: Colors[theme].text.primary,
            ...font({
                size: 32,
                height: 40,
                weight: '800',
            }),
            letterSpacing: -0.4,
        },
        titleName: {
            color: Colors[theme].text.accent,
        },
    });

export default Welcome;
