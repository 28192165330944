import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import { SocialTaskStatus } from '../../../../../Data/Models';
import CompletedIcon from '../../../../../assets/icons/statuses/completed.svg';
import FailedIcon from '../../../../../assets/icons/statuses/failed.svg';
import AwaitsVerificationIcon from '../../../../../assets/icons/statuses/pending.svg';
import InfoIcon from '../../../../../components/InfoIcon/InfoIcon';
import { Text, View } from '../../../../../components/Themed';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import stylesMain from './styles';

const icons = {
    CREATED: <></>,
    COMPLETED: <CompletedIcon />,
    FAILED: <FailedIcon />,
    AWAITS_VERIFICATION: <AwaitsVerificationIcon />,
    MODERATOR_VERIFICATION_REQUIRED: <AwaitsVerificationIcon />,
};

interface IProps {
    status: SocialTaskStatus;
    onPress?: () => void;
    withInfoIcon?: boolean;
}

const TaskStatusBadge = ({ status, onPress, withInfoIcon }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <TouchableOpacity onPress={onPress} activeOpacity={onPress ? 0.7 : 1}>
            <View style={[styles.block, styles[`${status}_BG`]]}>
                <View style={styles.icon}>{icons[status]}</View>
                <Text style={[styles.text, styles[`${status}_TEXT`]]}>
                    {i18n.t(`statuses.${status}.title`)}
                </Text>
                {withInfoIcon && (
                    <InfoIcon
                        onPress={onPress}
                        iconColor={styles[`${status}_TEXT`].color}
                        iconSize={20}
                        containerStyle={styles.infoIcon}
                    />
                )}
            </View>
        </TouchableOpacity>
    );
};

export default TaskStatusBadge;
