import * as React from 'react';
import { useCallback } from 'react';
import { TouchableOpacity } from 'react-native';
import { CurvedBottomBar } from 'react-native-curved-bottom-bar';
import { scale } from 'react-native-size-scaling';

import NavigationIcon from '@components/Icons/NavigationIcon';
import { View } from '@components/Themed';
import Colors from '@constants/Colors';
import { useDimensions } from '@contexts/DimensionsContext';
import { useTheme } from '@contexts/ThemeContext';
import { isWeb } from '@helpers/app';
import { useHaptic } from '@hooks/useHaptic';
import useSocialProof from '@hooks/useSocialProof';
import useThemedStyles from '@hooks/useThemedStyles';

import CharityMainScreen from '../../../screens/TabBar/Charity/CharityMainScreen';
import HomeMainScreen from '../../../screens/TabBar/Home/HomeMain/HomeMainScreen';
import PlayScreen from '../../../screens/TabBar/Play/Play/PlayScreen';
import NavigationBar from '../../NavigationBar';
import ROUTES from '../../routes';
import { CollectionTabStackNavigator } from '../CollectionTabStack';
import { LeaderboardTabsNavigator } from '../LeaderBoardGroup';
import { MarketPlaceTabsNavigator } from '../MarketPlaceTabsNavigator';
import { ReferralsTabsNavigator } from '../ReferralsGroup';
import stylesMain from './styles';

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */

const BottomTabBar = () => {
    const styles = useThemedStyles(stylesMain);
    const { theme } = useTheme();
    const { windowWidth } = useDimensions();
    const { triggerLight, triggerMedium } = useHaptic();

    const renderIcon = (routeName: string, selectedTab: string) => {
        return (
            <NavigationIcon
                name={routeName}
                active={selectedTab === routeName}
            />
        );
    };

    const renderTabBar = ({ routeName, selectedTab, navigate }: any) => {
        return (
            <>
                <TouchableOpacity
                    onPress={() => {
                        triggerLight();
                        navigate(routeName);
                    }}
                    style={styles.routeLink}>
                    {renderIcon(routeName, selectedTab)}
                </TouchableOpacity>
            </>
        );
    };

    const getHeight = () => {
        if (!isWeb) {
            return undefined;
        }

        // Fix scaling issue for web which comes out in 2.1.4 version of the lib
        return (70 / scale(70)) * 70;
    };

    const { renderSocialProof } = useSocialProof();

    const renderBottomBar = useCallback(
        () => (
            <View style={styles.main}>
                <NavigationBar
                    wallet
                    account
                    fillColor={styles.headerBg.color}
                    isOneColor={true}
                />
                {renderSocialProof()}
                <CurvedBottomBar.Navigator
                    defaultScreenOptions={undefined}
                    type={'UP'}
                    style={styles.bottomBar}
                    strokeWidth={0.5}
                    strokeColor={Colors[theme].border.primary}
                    width={isWeb ? windowWidth : undefined}
                    height={getHeight()}
                    circleWidth={55}
                    bgColor={Colors[theme].background}
                    initialRouteName={ROUTES.HOME}
                    borderTopLeftRight
                    renderCircle={({ selectedTab, navigate, routeName }) => (
                        <View
                            style={[
                                styles.btnCircleContainer,
                                selectedTab === ROUTES.HOME
                                    ? styles.btnCircleContainerActive
                                    : {},
                            ]}>
                            {/* {showPin && <AnimatedBottomTabPin />} */}
                            <TouchableOpacity
                                style={[
                                    styles.btnCircle,
                                    selectedTab === ROUTES.HOME
                                        ? styles.btnCircleActive
                                        : {},
                                ]}
                                onPress={() => {
                                    triggerMedium();
                                    navigate(ROUTES.HOME);
                                }}>
                                {renderIcon(ROUTES.HOME, selectedTab)}
                            </TouchableOpacity>
                        </View>
                    )}
                    tabBar={renderTabBar}
                    screenOptions={{
                        tabBarShowLabel: false,
                        headerShown: false,
                        lazy: false,
                        freezeOnBlur: true,
                    }}>
                    <CurvedBottomBar.Screen
                        name={ROUTES.HOME}
                        component={HomeMainScreen}
                        position="CENTER"
                    />
                    <CurvedBottomBar.Screen
                        name={ROUTES.PLAY}
                        component={PlayScreen}
                        position="LEFT"
                    />

                    <CurvedBottomBar.Screen
                        name={ROUTES.COLLECTION}
                        component={CollectionTabStackNavigator}
                        position="LEFT"
                    />
                    <CurvedBottomBar.Screen
                        name={ROUTES.CHARITY}
                        component={CharityMainScreen}
                        position="LEFT"
                    />
                    <CurvedBottomBar.Screen
                        name={ROUTES.LEADERBOARD}
                        component={LeaderboardTabsNavigator}
                        position="RIGHT"
                    />
                    <CurvedBottomBar.Screen
                        name={ROUTES.REFERRALS}
                        component={ReferralsTabsNavigator}
                        position="RIGHT"
                    />
                    <CurvedBottomBar.Screen
                        name={ROUTES.MARKETPLACE}
                        component={MarketPlaceTabsNavigator}
                        position="RIGHT"
                    />
                </CurvedBottomBar.Navigator>
            </View>
        ),
        [windowWidth, theme, renderSocialProof, triggerMedium]
    );

    return renderBottomBar();
};

export default BottomTabBar;
