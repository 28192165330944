import { useState } from 'react';

import { GET_MYSTERY_GAMES } from '@Data/Requests';
import { useApolloClient } from '@apollo/client';
import { errorsHandler } from '@helpers/errors';
import { useLoadingHook } from '@hooks/useLoadingHook';
import { GameOutput, GameOutputResponse } from '@models/mysteryGames';

export const useMysteryGamesData = () => {
    const client = useApolloClient();

    const [mysteryGames, setMysteryGames] = useState<GameOutput[]>();

    const {
        isLoading: mysteryGamesLoading,
        startLoading: startLoadingMysteryGames,
        stopLoading: stopLoadingMysteryGames,
    } = useLoadingHook();

    const {
        isLoading: mysteryGamesRefreshLoading,
        startLoading: startMysteryGamesRefreshLoading,
        stopLoading: stopMysteryGamesRefreshLoading,
    } = useLoadingHook();

    const handleStopLoading = () => {
        stopLoadingMysteryGames();
        stopMysteryGamesRefreshLoading();
    };

    const getMysteryGames = async (silent: boolean) => {
        try {
            silent
                ? startLoadingMysteryGames()
                : startMysteryGamesRefreshLoading();
            const result = await client.query<GameOutputResponse>({
                query: GET_MYSTERY_GAMES,
                fetchPolicy: 'network-only',
            });

            if (result.data) {
                setMysteryGames(result.data.games);
            }
            handleStopLoading();
        } catch (error) {
            handleStopLoading();
            errorsHandler(error, true);
        }
    };

    return {
        mysteryGamesLoading,
        mysteryGames,
        mysteryGamesRefreshLoading,
        getMysteryGames,
    };
};
