import { StyleSheet } from 'react-native';

import Colors from '../../../../../../constants/Colors';
import { moderateScale, scale } from '../../../../../../helpers/dimensions';
import font from '../../../../../../helpers/font';
import { theme } from '../../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            width: '100%',
        },
        row: {
            width: '100%',
            flexDirection: 'row',
            marginBottom: scale(12),
            justifyContent: 'space-between',
        },
        itemContainer: {
            width: '48%',
            flexDirection: 'row',
            alignItems: 'center',
            borderWidth: 1,
            padding: scale(18),
            paddingRight: scale(14),
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
        },
        itemContainerDisabled: {
            opacity: 0.5,
        },
        itemDivider: {
            width: scale(12),
        },
        itemText: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
            marginLeft: scale(10),
        },
    });

export default styles;
