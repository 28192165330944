import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import { JOIN_NFT_ROYAL_GAME } from '@Data/Requests';
import { useMutation } from '@apollo/client';
import ICON from '@assets/icons/emptyState/mysteryLikesConfirmPlay.svg';
import AlertCard from '@components/AlertCard';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import GamingWalletChangeFeature from '@components/GamingWalletChangeFeature';
import { ICON_NAMES } from '@components/Icons';
import ModalBottom from '@components/ModalBottom';
import { Text } from '@components/Themed';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { errorsHandler } from '@helpers/errors';
import { isGraphqlError } from '@helpers/helpers';
import { priceString } from '@helpers/payments';
import { toastErrorUnknown } from '@helpers/toastNotification';
import { balanceFromWei } from '@helpers/wallet';
import { useLoadingHook } from '@hooks/useLoadingHook';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES, nftRoyalJoinGameResponse } from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import { toastBanned, toastGameAlreadyStarted } from '../../helpers';
import ConfirmationModal from '../ConfirmationModal';
import NftRoyalGamesSettings from '../NftRoyalGamesSettings';
import PlayVSBlock from '../PlayVSBlock';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
    modalHeight?: number | string;
}

const NftRoyalConfirmPlayModal = ({
    isVisible,
    onClose,
    modalHeight = 999,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const {
        nftRoyalCurrentAvailableGame,
        nftRoyaleGameConfig,
        availableBalance,
        availableBalanceLoading,
        getGamingAvailableBalance,
        setTopUpFromMysteryGames,
        setNftRoyalCurrentGame,
    } = useMysteryGames();

    React.useEffect(() => {
        if (isVisible) {
            getGamingAvailableBalance(true);
        }
    }, [isVisible]);

    const enoughBalance = useMemo(
        () =>
            Number(
                balanceFromWei(nftRoyalCurrentAvailableGame?.iguAmountWei).value
            ) <= Number(balanceFromWei(availableBalance?.available).value),
        [nftRoyalCurrentAvailableGame?.iguAmountWei, availableBalance]
    );
    const { isLoading, startLoading, stopLoading } = useLoadingHook();

    const {
        isVisible: isVisibleConfirmationModal,
        open: openConfirmationModal,
        close: closeConfirmationModal,
    } = useVisibleHook();

    const navigation = useNavigation();

    const [joinTheGame] =
        useMutation<nftRoyalJoinGameResponse>(JOIN_NFT_ROYAL_GAME);

    const handleConfirmJoin = useCallback(async () => {
        if (!nftRoyalCurrentAvailableGame) {
            toastErrorUnknown('top');
            return;
        }
        startLoading();
        try {
            const join = await joinTheGame({
                variables: { gameId: nftRoyalCurrentAvailableGame.id },
            });

            if (join.data?.nftRoyaleJoinGame) {
                onClose();
                setNftRoyalCurrentGame(join.data.nftRoyaleJoinGame);
                navigation.navigate(ROUTES.NFT_ROYAL_PLAY_GAME);
            }
            stopLoading();
        } catch (error: any) {
            stopLoading();

            if (isGraphqlError(error, 'NFTROYALE_GAME_UNJOINABLE')) {
                onClose();
                await toastGameAlreadyStarted();
                return;
            }

            if (isGraphqlError(error, 'NFTROYALE_BANNED')) {
                toastBanned();
                return;
            }
            errorsHandler(error, true);
        }
    }, [nftRoyalCurrentAvailableGame]);

    const topUp = () => {
        setTopUpFromMysteryGames(true);
        onClose();
        navigation.navigate(ROUTES.WALLET_ROOT, {
            screen: ROUTES.WALLET_GAMING_TAB,
            params: {
                screen: ROUTES.TOP_UP_GAMING_WALLET,
            },
        });
    };

    return (
        <ModalBottom
            isVisible={isVisible}
            onClose={onClose}
            modalHeight={modalHeight}>
            <ScrollView
                style={styles.container}
                showsVerticalScrollIndicator={false}>
                <TouchableOpacity activeOpacity={1}>
                    <BlockHeader
                        containerStyle={styles.header}
                        emptyStateIcon={<ICON />}
                        type="emptyState"
                        titleComponent={
                            <>
                                {i18n.t('HomeScreen.ConfirmPlayModal.title')}
                                <Text style={styles.accent}>
                                    {`${priceString(
                                        balanceFromWei(
                                            nftRoyalCurrentAvailableGame?.iguAmountWei
                                        ).valueLong,
                                        Coin.vigu
                                    )}`}
                                </Text>
                                {'?'}
                            </>
                        }
                        text={i18n.t('HomeScreen.ConfirmPlayModal.text')}
                    />

                    {!!nftRoyalCurrentAvailableGame && (
                        <>
                            <NftRoyalGamesSettings
                                containerStyle={styles.settings}
                                mode={nftRoyalCurrentAvailableGame.mode}
                                difficulty={
                                    nftRoyalCurrentAvailableGame.complexity
                                }
                                livesCount={
                                    nftRoyalCurrentAvailableGame.livesCount
                                }
                            />
                            <PlayVSBlock
                                containerStyle={styles.pvb}
                                userLabel={i18n.t('general.KEYS.yourNFT')}
                                creatorLabel={i18n.t(
                                    'general.KEYS.opponentsNFT'
                                )}
                                creatorUri={
                                    nftRoyalCurrentAvailableGame.creatorAvatarUrl
                                }
                                creatorWalletAddress={
                                    nftRoyalCurrentAvailableGame.creatorUsername
                                }
                                alert={i18n.t(
                                    'HomeScreen.NftRoyal.lockPeriodWarning',
                                    {
                                        lockedDays:
                                            nftRoyaleGameConfig?.rewardUnlockDaysCount,
                                    }
                                )}
                            />
                        </>
                    )}

                    <GamingWalletChangeFeature customStyles={styles.feature} />
                </TouchableOpacity>
            </ScrollView>

            {!enoughBalance && !availableBalanceLoading && (
                <AlertCard
                    containerStyle={styles.alert}
                    text={i18n.t(`general.ALERTS.notEnoughCoinsOnGamingWallet`)}
                    type="error"
                    onAction={topUp}
                    actionButtonIcon={ICON_NAMES.DEPOSIT}
                    actionButtonIconColor={styles.actionButton.color}
                    actionButtonType={'outline'}
                />
            )}
            <ButtonsBottom
                disabled={
                    availableBalanceLoading ||
                    !availableBalance ||
                    !enoughBalance ||
                    isLoading
                }
                loading={availableBalanceLoading || isLoading}
                title={i18n.t(`general.BUTTONS.startPlaying`)}
                onPress={openConfirmationModal}
                safe
            />
            {!!nftRoyalCurrentAvailableGame && (
                <ConfirmationModal
                    isVisible={isVisibleConfirmationModal}
                    onClose={closeConfirmationModal}
                    onConfirm={handleConfirmJoin}
                    amount={
                        balanceFromWei(
                            nftRoyalCurrentAvailableGame?.iguAmountWei
                        ).valueLong
                    }
                    game={MYSTERY_GAMES.nftRoyal}
                />
            )}
        </ModalBottom>
    );
};

export default NftRoyalConfirmPlayModal;
