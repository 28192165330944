import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '../../helpers/dimensions';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            paddingHorizontal: scale(20),
            marginTop: verticalScale(32),
        },
        image: {
            alignItems: 'center',
            marginBottom: verticalScale(24),
        },
        card: {
            marginTop: verticalScale(20),
        },

        gap: {
            marginTop: verticalScale(12),
        },
    });

export default styles;
