import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        tooltipText: {
            ...font({
                size: 15,
                height: 24,
                weight: '600',
            }),
            color: Colors[theme].text.primary,
        },
        hintText: {
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
            color: Colors[theme].text.secondary,
            width: '40%',
        },
        isOneColorText: {
            color: Colors[theme].text.onDark,
        },
        hintsBlock: {
            marginTop: verticalScale(12),
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        button: {
            width: '60%',
        },
    });

export default styles;
