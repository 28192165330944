import { StyleSheet } from 'react-native';

import Colors from '../../../../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../../../../helpers/dimensions';
import font from '../../../../../../helpers/font';
import { theme } from '../../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            marginTop: verticalScale(16),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
        },
        infoRow: {
            flexDirection: 'row',
            marginTop: scale(16),
            justifyContent: 'space-between',
        },
        title: {
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
            color: Colors[theme].text.primary,
        },
        measureText: {
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
            color: Colors[theme].text.secondary,
            marginHorizontal: scale(9),
        },
        valueText: {
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
            color: Colors[theme].text.primary,
        },
        measureContainer: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    });

export default styles;
