import { StyleSheet } from 'react-native';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        hiddenButton: {
            backgroundColor: 'transparent',
            borderRadius: 1000,
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: 100,
        },
    });

export default styles;
