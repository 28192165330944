import * as React from 'react';
import { useCallback } from 'react';

import Divider from '@components/Divider';
import EmptyList from '@components/EmptyList';
import LoadingComponent from '@components/LoadingComponent';
import PullToRefresh from '@components/PullToRefresh';
import { Text, View } from '@components/Themed';
import { useUser } from '@contexts/UserContext';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { useFocusEffect } from '@react-navigation/native';

import useMarketplaceProfile from '../../hooks/useMarketplaceProfile';
import MarketPlacePetCard from '../MarketPlacePetCard';
import ProfileUserBlock from '../ProfileUserBlock';
import stylesMain from './styles';

interface IProps {
    walletAddress: string | undefined;
    blockTitle: string;
    navigationComponent: React.ReactNode;
    margin?: boolean;
    shouldPoll?: boolean;
}

const MarketPlaceMyProfileRender = ({
    walletAddress,
    blockTitle,
    navigationComponent,
    margin,
    shouldPoll,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const navigation = useNavigation();

    const { user } = useUser();

    const itsMe = React.useMemo(
        () => walletAddress === user?.walletAddress,
        [user?.walletAddress]
    );

    const {
        getMarketplaceProfile,
        listerOrders,
        refreshLoading,
        userProfileLoading,
        userProfile,
        stats,
    } = useMarketplaceProfile();

    const dataLoaded = React.useMemo(
        () => !!listerOrders && !!userProfile && !!stats,
        [listerOrders, userProfile, stats]
    );

    const handleCreateNewOrder = () => {
        navigation.push(ROUTES.MARKETPLACE_WELCOME_CREATE_ORDER);
    };

    useFocusEffect(
        useCallback(() => {
            if (shouldPoll && walletAddress) {
                const interval = setInterval(
                    () => getMarketplaceProfile(false, walletAddress),
                    15000
                );

                return () => clearInterval(interval);
            }
        }, [walletAddress, shouldPoll])
    );

    useFocusEffect(
        useCallback(() => {
            walletAddress && getMarketplaceProfile(false, walletAddress);
        }, [walletAddress])
    );

    const handleRefresh = useCallback(async () => {
        walletAddress && getMarketplaceProfile(true, walletAddress);
    }, [walletAddress]);

    const headerComponent = useCallback(() => {
        return (
            <>
                {!!stats && !!userProfile && (
                    <ProfileUserBlock stats={stats} userProfile={userProfile} />
                )}

                {!!listerOrders?.length && (
                    <>
                        <Divider customStyles={styles.divider} />
                        <Text style={styles.title}>{blockTitle}</Text>
                    </>
                )}
            </>
        );
    }, [stats, userProfile, blockTitle]);

    const emptyComponent = useCallback(() => {
        if (itsMe) {
            return (
                <EmptyList
                    containerStyle={styles.emptyList}
                    emptyTitle={i18n.t(
                        `MarketPlace.MarketplaceProfile.emptyUser.title`
                    )}
                    emptyText={i18n.t(
                        `MarketPlace.MarketplaceProfile.emptyUser.text`
                    )}
                    emptyButtonText={i18n.t('general.BUTTONS.createNewOrder')}
                    withAnimation="cry"
                    onPressButton={handleCreateNewOrder}
                />
            );
        }

        return (
            <EmptyList
                containerStyle={styles.emptyList}
                emptyTitle={i18n.t(
                    `MarketPlace.MarketplaceProfile.empty.title`
                )}
                emptyText={i18n.t(`MarketPlace.MarketplaceProfile.empty.text`)}
                withAnimation="cry"
            />
        );
    }, [itsMe]);

    const handleNavigateOnOverview = (
        petId: string,
        cancelTransactionValuesStringified: string | null
    ) => {
        navigation.push(ROUTES.MARKETPLACE_OVERVIEW_GROUP, {
            screen: ROUTES.MARKETPLACE_OVERVIEW,
            params: { petId, cancelTransactionValuesStringified },
        });
    };

    return (
        <View style={styles.main}>
            {navigationComponent}
            {!!walletAddress && (
                <View style={[styles.content, margin && styles.margin]}>
                    {userProfileLoading && !dataLoaded ? (
                        <LoadingComponent text={i18n.t('general.loading')} />
                    ) : (
                        <PullToRefresh
                            refreshing={refreshLoading}
                            textLoading={i18n.t('pullToRefresh.loading')}
                            onRefresh={handleRefresh}
                            HeaderComponent={headerComponent()}
                            EmptyComponent={emptyComponent()}
                            renderData={listerOrders || []}
                            // @ts-ignore ignore item type any
                            renderItem={({ item }) => (
                                <MarketPlacePetCard
                                    data={item}
                                    onPress={handleNavigateOnOverview}
                                    itsMe={itsMe}
                                />
                            )}
                            shouldUseEmptyComponentInFooter
                            numOfColumns={2}
                            columnWrapperStyle={styles.columnWrapperStyle}
                            FooterComponent={<View style={styles.footer} />}
                        />
                    )}
                </View>
            )}
        </View>
    );
};

export default MarketPlaceMyProfileRender;
