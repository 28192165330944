import * as React from 'react';
import { FlatList } from 'react-native';

import { Pet } from '@Data/Models';
import ModalPetCooldown from '@components/ModalPetCooldown';
import PetCollectionListItem from '@components/PetCollectionListItem';
import { Text } from '@components/Themed';
import { dateCooldownExpired } from '@helpers/time';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';

import stylesMain from './styles';

interface IProps {
    pets: Pet[];
    tmpOrderedIds: string[];
    onPress: (id: string) => void;
    onDetailedPet: (pet: Pet) => void;
}
interface IRenderItem {
    item: Pet;
}

const EditPetsList = ({
    pets,
    tmpOrderedIds,
    onPress,
    onDetailedPet,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const [modalCooldown, setModalCoolDown] = React.useState<boolean>(false);
    const [currentPet, setCurrentPet] = React.useState<Pet>(pets[0]);

    return (
        <>
            <FlatList
                showsVerticalScrollIndicator={false}
                ListHeaderComponent={
                    <Text style={styles.headerText}>
                        {i18n.t('editNFTsScreen.selectList')}
                    </Text>
                }
                data={pets}
                renderItem={({ item }) => (
                    <PetCollectionListItem
                        pet={item}
                        withSelection
                        isSelected={tmpOrderedIds.includes(item.id)}
                        onPressPet={() => onPress(item.id)}
                        canLvlUp={item.levelUpAvailable}
                        canRankUp={item.rankUpAvailable}
                        isDead={item.reviveAvailable}
                        isOnCooldown={
                            !dateCooldownExpired(item.cooldownExpiresAt)
                        }
                        isNeedToFeed={item.healUpAvailable}
                        isAgeDead={item.reviveAgeAvailable}
                        navigateBackOnUpgrade={ROUTES.PLAY}
                        fromScreenModal
                        onArrowClick={() => onDetailedPet(item)}
                        onCooldownClick={() => {
                            setCurrentPet(item);
                            setModalCoolDown(true);
                        }}
                        border
                    />
                )}
                keyExtractor={(item) => item.id}
            />
            <ModalPetCooldown
                pet={currentPet}
                isVisible={modalCooldown}
                onClose={() => setModalCoolDown(false)}
            />
        </>
    );
};
export default EditPetsList;
