import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import Users from '@assets/icons/app/users.svg';
import CoinIcon from '@components/Icons/CoinIcon';
import { View } from '@components/Themed';
import { useDimensions } from '@contexts/DimensionsContext';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { scale } from '@helpers/dimensions';
import { statisticsFormatter } from '@helpers/helpers';
import { priceString } from '@helpers/payments';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import GamesStatsInfoCard from '../GamesStatsInfoCard';
import stylesMain from './styles';

interface IProps {
    customStyle?: StyleProp<ViewStyle>;
    onLight?: boolean;
    activeGamesCount: number | undefined;
    totalPricePoolIguAmountWei: string | undefined;
    coin?: Coin;
    exchangeCoin?: Coin;
}

const GamesFeatures = ({
    customStyle,
    onLight,
    activeGamesCount,
    totalPricePoolIguAmountWei,
    coin = Coin.vigu,
    exchangeCoin = Coin.igu,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { priceInUsd } = useWallet();

    const { windowWidth } = useDimensions();

    const itemWidth = (windowWidth - scale(20) * 2) / 2 - scale(6);

    return (
        <View style={[styles.features, customStyle]}>
            <GamesStatsInfoCard
                onLight={onLight}
                containerStyle={{ width: itemWidth }}
                title={i18n.t(`HomeScreen.GamesFeatures.orders`)}
                amount={`${statisticsFormatter(activeGamesCount || 0)}`}
                icon={<Users width={32} height={32} />}
            />
            <GamesStatsInfoCard
                onLight={onLight}
                containerStyle={{ width: itemWidth }}
                title={i18n.t(`HomeScreen.GamesFeatures.prizePool`)}
                amount={priceString(
                    statisticsFormatter(
                        Number(balanceFromWei(totalPricePoolIguAmountWei).value)
                    ),
                    coin
                )}
                usd={priceInUsd(
                    exchangeCoin,
                    balanceFromWei(totalPricePoolIguAmountWei).value
                )}
                icon={<CoinIcon slug={coin} size={32} />}
            />
        </View>
    );
};

export default GamesFeatures;
