import * as React from 'react';
import { ScrollView } from 'react-native';

import { useLazyQuery, useQuery } from '@apollo/client';
import ActivityIndicator from '@components/ActivityIndicator';
import Animation from '@components/Animation';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import Button from '@components/Button';
import ButtonsBottom from '@components/ButtonsBottom';
import { ICON_NAMES } from '@components/Icons';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT, TYPO_TEXT_COLOR } from '@components/TypoText/types';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { errorsHandler } from '@helpers/errors';
import { isGraphqlError, wait } from '@helpers/helpers';
import { toastErrorUnknown } from '@helpers/toastNotification';
import { useLoadingHook } from '@hooks/useLoadingHook';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import {
    LuckyStrikeGameHistoryOutputResponse,
    LuckyStrikeSingeAvailableGameOutput,
    LuckyStrikeUserTicketOutputResponse,
    MYSTERY_GAMES,
} from '@models/mysteryGames';
import NavigationBar from '@navigation/NavigationBar';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import {
    GET_LUCKY_STRIKE_AVAILABLE_GAME,
    GET_LUCKY_STRIKE_GAME_HISTORY,
    GET_LUCKY_STRIKE_USER_TICKETS,
} from '@requests/luckyStrike';

import { LuckyStrikeWaitingForResultProps } from '../../../../../types';
import LuckyStrikeParticipants from '../../components/LuckyStrikeParticipants';
import LuckyStrikePricePool from '../../components/LuckyStrikePricePool';
import LuckyStrikeTickets from '../../components/LuckyStrikeTickets';
import LuckyStrikeTimerBlock from '../../components/LuckyStrikeTimerBlock';
import useSecureHashModal from '../../hooks/useSecureHashModal';
import { maxParticipants, toastGamePending } from '../helpers';
import stylesMain from './styles';

const GAME = MYSTERY_GAMES.luckyStrike;
const SCREEN = 'LuckyStrikeWaitingForResult';

const LuckyStrikeWaitingForResultScreen = ({
    route,
}: LuckyStrikeWaitingForResultProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const { id: gameId, routeBack } = route.params;

    const { luckyStrikeConfig } = useMysteryGames();

    const { renderSecureHashModal, openSecureHashModal } = useSecureHashModal();

    const [getAvailableGame] =
        useLazyQuery<LuckyStrikeSingeAvailableGameOutput>(
            GET_LUCKY_STRIKE_AVAILABLE_GAME,
            {
                fetchPolicy: 'network-only',
                variables: {
                    gameId,
                },
            }
        );

    const [getHistoryItem] = useLazyQuery<LuckyStrikeGameHistoryOutputResponse>(
        GET_LUCKY_STRIKE_GAME_HISTORY,
        {
            fetchPolicy: 'network-only',
        }
    );

    const {
        data: tickets,
        loading: ticketsLoading,
        error: ticketsError,
    } = useQuery<LuckyStrikeUserTicketOutputResponse>(
        GET_LUCKY_STRIKE_USER_TICKETS,
        {
            fetchPolicy: 'network-only',
            variables: {
                gameId,
            },
        }
    );

    React.useEffect(() => {
        if (ticketsError) {
            navigation.pop();
            toastErrorUnknown();
        }
    }, [ticketsError]);

    const {
        isLoading: gameLoading,
        startLoading,
        stopLoading,
    } = useLoadingHook(true);
    const [currentGame, setCurrentGame] =
        React.useState<LuckyStrikeSingeAvailableGameOutput>();

    const onEndOfTime = React.useCallback(async () => {
        const result = await getHistoryItem({
            variables: {
                gameId,
            },
        });
        if (result.data?.luckyStrikeGameHistory) {
            navigation.replace(ROUTES.LUCKY_STRIKE_GAME_HISTORY, {
                game: result.data.luckyStrikeGameHistory,
                id: gameId,
            });
        } else {
            navigation.pop();
            toastGamePending();
        }
    }, [gameId]);

    const loadGame = React.useCallback(async () => {
        startLoading();
        try {
            const result = await getAvailableGame({
                variables: {
                    gameId,
                },
            });
            setCurrentGame(result.data);
            stopLoading();
        } catch (error) {
            if (isGraphqlError(error, 'NOT_FOUND')) {
                await onEndOfTime();
                stopLoading();
                return;
            }
            errorsHandler(error, true);
        }
    }, [gameId, onEndOfTime]);

    React.useEffect(() => {
        loadGame();
    }, [loadGame]);

    const isOneTicket = React.useMemo(() => {
        if (!tickets?.luckyStrikeUserTickets.length) return null;
        return tickets.luckyStrikeUserTickets.length === 1;
    }, [tickets]);

    const buttonTitle = React.useMemo(() => {
        if (!tickets?.luckyStrikeUserTickets.length) return null;

        return isOneTicket
            ? `#${tickets.luckyStrikeUserTickets[0].number}`
            : `${tickets.luckyStrikeUserTickets.length}/${
                  luckyStrikeConfig?.maxTicketAmount
              } ${i18n.t('general.KEYS.tickets')}`;
    }, [luckyStrikeConfig, tickets, isOneTicket]);

    const buttonIcon = React.useMemo(() => {
        if (!tickets?.luckyStrikeUserTickets.length) return null;
        return isOneTicket
            ? ICON_NAMES.QUESTION
            : ICON_NAMES.ARROW_RIGHT_SECONDARY;
    }, [tickets, isOneTicket]);

    const handleNavigateToTickets = React.useCallback(() => {
        if (!currentGame?.luckyStrikeAvailableGame.id) {
            return;
        }
        navigation.push(ROUTES.LUCKY_STRIKE_USER_TICKETS_WAIT, {
            game: currentGame.luckyStrikeAvailableGame,
        });
    }, [currentGame?.luckyStrikeAvailableGame.id]);

    const onButtonPress = React.useCallback(() => {
        if (isOneTicket) {
            return openSecureHashModal();
        }
        return handleNavigateToTickets();
    }, [isOneTicket, handleNavigateToTickets]);

    const maxCount = React.useMemo(() => {
        if (!currentGame?.luckyStrikeAvailableGame) return 0;

        return maxParticipants(
            luckyStrikeConfig?.gameTypes,
            currentGame.luckyStrikeAvailableGame.duration
        );
    }, [luckyStrikeConfig?.gameTypes, currentGame]);

    const participantsText = React.useMemo(
        () =>
            `${
                currentGame?.luckyStrikeAvailableGame.userCount || 0
            }/${maxCount}`,
        [currentGame?.luckyStrikeAvailableGame, maxCount]
    );

    const onViewAllParticipants = React.useCallback(() => {
        navigation.push(ROUTES.LUCKY_STRIKE_ALL_PARTICIPANTS, {
            id: currentGame?.luckyStrikeAvailableGame.id,
        });
    }, [currentGame?.luckyStrikeAvailableGame]);

    const canPurchaseMore = React.useMemo(() => {
        if (
            !tickets?.luckyStrikeUserTickets.length ||
            !luckyStrikeConfig?.maxTicketAmount
        )
            return false;

        return (
            tickets.luckyStrikeUserTickets.length <
            luckyStrikeConfig.maxTicketAmount
        );
    }, [tickets, luckyStrikeConfig]);

    const onPurchaseMore = React.useCallback(() => {
        navigation.navigate(routeBack, {
            game: currentGame?.luckyStrikeAvailableGame,
        });
    }, [currentGame?.luckyStrikeAvailableGame, routeBack]);

    return (
        <View style={[styles.main, !canPurchaseMore && styles.bottomGap]}>
            <NavigationBar
                isOneColor
                fillColor={styles.main.backgroundColor}
                filled={false}
                backIcon="close"
            />
            {gameLoading || ticketsLoading ? (
                <>
                    <ActivityIndicator
                        color={'white'}
                        size={'large'}
                        containerStyle={styles.loading}
                        loading={gameLoading || ticketsLoading}
                    />
                </>
            ) : (
                <>
                    <ScrollView
                        style={styles.container}
                        showsVerticalScrollIndicator={false}>
                        <BlockHeader
                            containerStyle={styles.header}
                            emptyStateIcon={
                                <Animation
                                    animation="WaitingForOpponentLuckyStrike"
                                    style={styles.animation}
                                />
                            }
                            type="emptyState"
                            titleComponent={
                                <TypoText
                                    type={TYPO_TEXT.HEADING_H2}
                                    colorType={TYPO_TEXT_COLOR.ON_DARK}>
                                    {i18n.t(
                                        `HomeScreen.${GAME}.${SCREEN}.title`
                                    )}
                                </TypoText>
                            }
                            textComponent={
                                <TypoText
                                    type={TYPO_TEXT.BODY_BIG}
                                    colorType={TYPO_TEXT_COLOR.ON_DARK}>
                                    {i18n.t(
                                        `HomeScreen.${GAME}.${SCREEN}.text`
                                    )}
                                </TypoText>
                            }
                            componentBetweenImageAndText={
                                !!buttonTitle ? (
                                    <Button
                                        type="outline"
                                        isOneColor
                                        size="md"
                                        title={buttonTitle}
                                        iconName={buttonIcon}
                                        textStyles={styles.buttonTextStyle}
                                        iconDirection="right"
                                        iconSize={24}
                                        onPress={onButtonPress}
                                        containerStyle={styles.button}
                                        iconColor={styles.buttonTextStyle.color}
                                        customIconStyles={styles.icon}
                                    />
                                ) : null
                            }
                        />
                        {currentGame && (
                            <>
                                <LuckyStrikeTimerBlock
                                    date={
                                        currentGame.luckyStrikeAvailableGame
                                            .endDate
                                    }
                                    onEndOfTime={onEndOfTime}
                                />
                                <LuckyStrikePricePool
                                    containerStyle={styles.feature}
                                    amount={
                                        currentGame.luckyStrikeAvailableGame
                                            .iguAmountWei
                                    }
                                    type="opacity"
                                />
                                <LuckyStrikeParticipants
                                    containerStyle={styles.feature}
                                    text={participantsText}
                                    handlePress={onViewAllParticipants}
                                    type="opacity"
                                />
                                {!!tickets?.luckyStrikeUserTickets.length && (
                                    <LuckyStrikeTickets
                                        containerStyle={styles.feature}
                                        currentTickets={
                                            tickets.luckyStrikeUserTickets
                                                .length
                                        }
                                        type="opacity"
                                    />
                                )}
                            </>
                        )}
                    </ScrollView>
                    {canPurchaseMore && (
                        <ButtonsBottom
                            safe
                            isOneColor
                            onPress={onPurchaseMore}
                            buttonType="outline"
                            containerStyle={styles.ButtonsBottom}
                            title={i18n.t('general.BUTTONS.purchaseMore')}
                        />
                    )}
                </>
            )}
            {renderSecureHashModal()}
        </View>
    );
};

export default LuckyStrikeWaitingForResultScreen;
