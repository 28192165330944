import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import Colors from '../../../../constants/Colors';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        outerContainer: {
            width: '100%',
        },
        container: {
            width: '100%',
            flexDirection: 'row',
            paddingHorizontal: scale(15),
            justifyContent: 'space-between',
        },
        button: {
            flex: 1,
            marginHorizontal: scale(5),
        },
        icon: {
            color: Colors[theme].icon.onDark,
        },
        purchaseBlock: {
            alignSelf: 'center',
            marginTop: verticalScale(20),
        },
    });

export default styles;
