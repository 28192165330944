import * as React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { SvgProps } from 'react-native-svg';

import { Text } from '@components/Themed';
import { StakingOptionsType, useStaking } from '@contexts/StakingContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { toastComingSoon } from '@helpers/toastNotification';
import useThemedStyles from '@hooks/useThemedStyles';

import ParticipateInAirDrop from '../ParticipateInAirDrop';
import stylesMain from './styles';

interface IProps {
    id: StakingOptionsType;
    title: string;
    text: string;
    disabled?: boolean;
    icon: React.ReactElement<SvgProps>;
    selectedOption: string | null;
    onPress: (id: StakingOptionsType) => void;
}

const SelectStakingCard = ({
    id,
    title,
    text,
    disabled,
    icon,
    selectedOption,
    onPress,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { isIguDrop } = useStaking();

    const isSelected = React.useMemo(
        () => selectedOption === id,
        [selectedOption]
    );

    const handlePress = React.useCallback(() => {
        if (disabled) {
            toastComingSoon();
            return;
        }
        onPress(id);
    }, [disabled, id]);

    return (
        <TouchableOpacity
            activeOpacity={0.7}
            style={[
                styles.shadow,
                isSelected && styles.selectedShadow,
                disabled && styles.disabled,
            ]}
            onPress={handlePress}>
            <View style={[styles.container, isSelected && styles.selected]}>
                <View style={styles.wrapper}>
                    <View style={styles.imgWrapper}>{icon}</View>
                    <View style={styles.textWrapper}>
                        <View style={styles.titleWrapper}>
                            <Text style={styles.title}>{title}</Text>
                        </View>
                        <View style={styles.infoWrapper}>
                            <Text style={styles.infoTitle}>{text}</Text>
                        </View>
                    </View>
                </View>
            </View>
        </TouchableOpacity>
    );
};
export default SelectStakingCard;
