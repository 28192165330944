import { ReactElement } from 'react';
import React from 'react';
import { SvgProps } from 'react-native-svg';

import Charity from '@assets/icons/tabbar/CharityIcon.svg';
import CharityActive from '@assets/icons/tabbar/CharityIconActive.svg';
import Collection from '@assets/icons/tabbar/CollectionIcon.svg';
import CollectionActive from '@assets/icons/tabbar/CollectionIconActive.svg';
import Home from '@assets/icons/tabbar/HomeIcon.svg';
import HomeActive from '@assets/icons/tabbar/HomeIconActive.svg';
import Leaderboard from '@assets/icons/tabbar/LeaderboardIcon.svg';
import LeaderboardActive from '@assets/icons/tabbar/LeaderboardIconActive.svg';
import Marketplace from '@assets/icons/tabbar/MarketplaceIcon.svg';
import MarketplaceActive from '@assets/icons/tabbar/MarketplaceIconActive.svg';
import Play from '@assets/icons/tabbar/PlayIcon.svg';
import PlayActive from '@assets/icons/tabbar/PlayIconActive.svg';
import Referrals from '@assets/icons/tabbar/ReferralsIcon.svg';
import ReferralsActive from '@assets/icons/tabbar/ReferralsIconActive.svg';

interface IIconsData {
    icon: ReactElement<SvgProps>;
    iconActive: ReactElement<SvgProps>;
}

export type IconsData = {
    Play: IIconsData;
    Charity: IIconsData;
    Leaderboard: IIconsData;
    Collection: IIconsData;
    Marketplace: IIconsData;
    Referrals: IIconsData;
    Home: IIconsData;
};

export const iconsData: IconsData = {
    Play: {
        icon: <Play width={25} height={25} />,
        iconActive: <PlayActive width={25} height={25} />,
    },
    Charity: {
        icon: <Charity width={25} height={25} />,
        iconActive: <CharityActive width={25} height={25} />,
    },
    Leaderboard: {
        icon: <Leaderboard width={25} height={25} />,
        iconActive: <LeaderboardActive width={25} height={25} />,
    },
    Collection: {
        icon: <Collection width={25} height={25} />,
        iconActive: <CollectionActive width={25} height={25} />,
    },
    Marketplace: {
        icon: <Marketplace width={25} height={25} />,
        iconActive: <MarketplaceActive width={25} height={25} />,
    },
    Referrals: {
        icon: <Referrals width={25} height={25} />,
        iconActive: <ReferralsActive width={25} height={25} />,
    },
    Home: {
        icon: <Home width={25} height={25} />,
        iconActive: <HomeActive width={25} height={25} />,
    },
};
