import * as React from 'react';

import LOSE from '@assets/icons/wallet/transactions/tokenTellerPayment.svg';
import WAIT from '@assets/icons/wallet/transactions/tokenTellerRefund.svg';
import DRAW from '@assets/icons/wallet/transactions/tokenTellerRefund.svg';
import WIN from '@assets/icons/wallet/transactions/tokenTellerWin.svg';
import { TOKEN_TELLER_HISTORY_GAMES_STATUS } from '@models/mysteryGames';

import { IconType } from '../types';

interface IProps extends IconType {
    slug: TOKEN_TELLER_HISTORY_GAMES_STATUS;
}

const TokenTellerHistoryIcon = ({ slug, size = 40 }: IProps) => {
    switch (slug) {
        case TOKEN_TELLER_HISTORY_GAMES_STATUS.Won:
            return <WIN width={size} height={size} />;
        case TOKEN_TELLER_HISTORY_GAMES_STATUS.Lost:
            return <LOSE width={size} height={size} />;
        case TOKEN_TELLER_HISTORY_GAMES_STATUS.WaitingForResults:
            return <WAIT width={size} height={size} />;
        case TOKEN_TELLER_HISTORY_GAMES_STATUS.Refund:
            return <DRAW width={size} height={size} />;

        default:
            return <></>;
    }
};

export default TokenTellerHistoryIcon;
