import * as React from 'react';
import { TouchableOpacity } from 'react-native';
import { SvgUri } from 'react-native-svg';

import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT } from '@components/TypoText/types';
import { isWeb } from '@helpers/app';
import useThemedStyles from '@hooks/useThemedStyles';
import { NftRoyalGameLives } from '@models/mysteryGames';

import stylesMain from './styles';

interface IProps {
    lives: NftRoyalGameLives;
    onPress: () => void;
    index: number;
    isSelected: boolean;
    getHeight: (height: number) => void;

    isLast: boolean;
    minHeight: number;
}

const LivesCountItem = ({
    lives,
    onPress,
    index,
    isSelected,
    getHeight,
    isLast,
    minHeight,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <TouchableOpacity
            style={[
                styles.shadow,
                isSelected && styles.active,
                !isLast && styles.margin,
                { minHeight },
            ]}
            activeOpacity={0.7}
            onPress={onPress}
            onLayout={({ nativeEvent }) => {
                getHeight(nativeEvent.layout.height);
            }}>
            <View
                style={[
                    styles.itemContainer,

                    isSelected && styles.activeContainer,
                ]}>
                <View style={styles.left}>
                    <TypoText
                        type={TYPO_TEXT.SUBHEAD_BIG_BOLD}
                        numberOfLines={1}
                        additionalStyles={styles.text}>
                        {lives.count}
                    </TypoText>
                </View>
                <View style={styles.right}>
                    {isWeb ? (
                        <img
                            src={lives.imageUrl}
                            style={styles.image}
                            alt="Lives"
                        />
                    ) : (
                        <SvgUri width="20" height="20" uri={lives.imageUrl} />
                    )}
                </View>
            </View>
        </TouchableOpacity>
    );
};
export default LivesCountItem;
