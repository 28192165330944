import * as React from 'react';
import { Image, ImageBackground, StyleProp, ViewStyle } from 'react-native';
import Animated from 'react-native-reanimated';

import { Pet } from '../../../../../Data/Models';
import RankIcon from '../../../../../components/Icons/RankIcon';
import LevelIndicator from '../../../../../components/LevelIndicator';
import { Text, View } from '../../../../../components/Themed';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import stylesMain from './styles';

interface IProps {
    pet: Pet;
    containerStyle?: StyleProp<ViewStyle>;
}

const MatchCard = ({ pet, containerStyle }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { image, id, level, levelProgressPercentage, name, rank } = pet;

    return (
        <Animated.View style={[styles.shadow, containerStyle]} key={id}>
            <View style={styles.container}>
                <ImageBackground
                    borderRadius={6}
                    resizeMode="contain"
                    source={{ uri: image.thumbnailUrl }}
                    style={styles.imgBg}>
                    <View style={styles.imgWrapper}>
                        <RankIcon size={24} slug={pet.rank} />
                    </View>
                </ImageBackground>
                <View style={styles.textWrapper}>
                    <LevelIndicator
                        level={level}
                        fillAmount={levelProgressPercentage}
                    />
                </View>
            </View>
        </Animated.View>
    );
};
export default MatchCard;
