import * as React from 'react';
import { KeyboardAvoidingView, ScrollView } from 'react-native';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import Button from '@components/Button';
import ButtonsBottom from '@components/ButtonsBottom';
import GamingWalletChangeFeature from '@components/GamingWalletChangeFeature';
import CoinIcon from '@components/Icons/CoinIcon';
import Input from '@components/Input';
import SafeAreaTabContent from '@components/SafeAreaTabContent';
import { Text, View } from '@components/Themed';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { isIOS, isWeb } from '@helpers/app';
import { priceString } from '@helpers/payments';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';

import HowToTopUpWallet from '../components/HowToTopUpWallet';
import SelectByCardAmountTopUp from '../components/SelectByCardAmountTopUp';
import { useTopUpGamingWalletScreen } from './hooks/useTopUpGamingWalletScreen';
import stylesMain from './styles';

const TopUpGamingWalletScreen = () => {
    const styles = useThemedStyles(stylesMain);

    const {
        submitForm,
        values,
        isValid,
        handleChangeAmount,
        onSelectCardPress,
        handleOnBlur,
        errors,
        closeHowToTopUp,
        openHowToTopUp,
        howToTopUpVisible,
        handleOnBack,
        walletBalance,
        handleClickAll,
    } = useTopUpGamingWalletScreen();

    return (
        <KeyboardAvoidingView
            behavior={isIOS ? 'padding' : 'height'}
            style={styles.container}>
            <SafeAreaTabContent>
                <NavigationBar
                    onBack={handleOnBack}
                    filled={false}
                    backIcon="leftArrow"
                    title={i18n.t(
                        'wallet.gaming.TopUpGamingWalletScreen.screenTitle'
                    )}
                />
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    scrollEventThrottle={16}
                    keyboardShouldPersistTaps="handled"
                    contentContainerStyle={styles.page}>
                    <BlockHeader
                        title={i18n.t(
                            'wallet.gaming.TopUpGamingWalletScreen.title'
                        )}
                        text={i18n.t(
                            'wallet.gaming.TopUpGamingWalletScreen.text'
                        )}
                        type="primary"
                        textInfo={i18n.t(
                            'wallet.gaming.TopUpGamingWalletScreen.infoText'
                        )}
                        iconDirection={'right'}
                        textInfoOnPress={openHowToTopUp}
                    />
                    <View style={styles.inputMainContainer}>
                        <View style={styles.inputWrapper}>
                            <View style={styles.icon}>
                                <CoinIcon slug={Coin.vigu} size={32} />
                            </View>
                            <Input
                                style={[
                                    styles.inputContainer,
                                    isWeb && {
                                        width: `${Math.max(
                                            values.amount.length,
                                            3
                                        )}ch`,
                                    },
                                ]}
                                value={values.amount}
                                keyboardType="decimal-pad"
                                onBlur={handleOnBlur}
                                onChangeText={handleChangeAmount}
                                placeholder="0"
                                maxLength={11}
                                isAvailableExternalStyle
                                autoComplete="off"
                                importantForAutofill="no"
                                autoCorrect={false}
                            />
                        </View>
                        <View style={styles.availableWrapper}>
                            <Text style={styles.available}>
                                {i18n.t('staking.coinsInputScreen.available')}
                                <Text style={styles.amountAll}>
                                    {priceString(
                                        walletBalance?.igu
                                            .presentationValueShort || '0',
                                        Coin.igu
                                    )}
                                </Text>
                            </Text>
                            <Button
                                onPress={handleClickAll}
                                title={i18n.t(
                                    'staking.coinsInputScreen.maxButton'
                                )}
                                containerStyle={styles.buttonAll}
                                type="outline"
                                size="sm"
                            />
                        </View>
                        <Text
                            style={[
                                styles.infoText,
                                !!errors.amount && styles.infoTextError,
                            ]}>
                            {errors.amount ||
                                i18n.t(
                                    'wallet.gaming.TopUpGamingWalletScreen.inputSection.infoText'
                                )}
                        </Text>
                        <SelectByCardAmountTopUp
                            amount={values.amount}
                            onPress={onSelectCardPress}
                        />
                    </View>
                    <GamingWalletChangeFeature customStyles={styles.feature} />
                </ScrollView>

                <ButtonsBottom
                    title={i18n.t(
                        'wallet.gaming.TopUpGamingWalletScreen.button'
                    )}
                    onPress={submitForm}
                    safe
                    disabled={!isValid || Number(values.amount) === 0}
                />
                <HowToTopUpWallet
                    isVisible={howToTopUpVisible}
                    onClose={closeHowToTopUp}
                />
            </SafeAreaTabContent>
        </KeyboardAvoidingView>
    );
};

export default TopUpGamingWalletScreen;
