import React from 'react';

import { GetMyReferrerResponse } from '@Data/Models';
import { GET_MY_REFERRER } from '@Data/Requests';
import { useLazyQuery } from '@apollo/client';
import { useUser } from '@contexts/UserContext';
import { errorsHandler } from '@helpers/errors';

const useGetMyReferrer = () => {
    const { user } = useUser();
    const [
        getMyReferrer,
        {
            data: myReferrer,
            error: myReferrerError,
            refetch: refetchMyReferrer,
        },
    ] = useLazyQuery<GetMyReferrerResponse>(GET_MY_REFERRER, {
        fetchPolicy: 'network-only',
        onError: (error) => errorsHandler(error),
    });

    React.useEffect(() => {
        getMyReferrer();
    }, [user?.isReferred]);

    return {
        myReferrer,
        myReferrerError,
        refetchMyReferrer,
    };
};

export default useGetMyReferrer;
