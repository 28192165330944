import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import { moderateScale } from '../../../../../helpers/dimensions';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            position: 'absolute',
            top: -1,
            left: -1,
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
        },
        opacityBlock: {
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: Colors[theme].background,
            opacity: 0.6,
            borderRadius: moderateScale(12),
        },
    });

export default styles;
