import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        item: {
            borderLeftWidth: 1,
            borderRightWidth: 1,
            borderColor: Colors[theme].border.primary,
        },
    });
export default styles;
