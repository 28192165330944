import * as React from 'react';

import HowNftRoyalWorkScreen from '../../screens/TabBar/Home/NftRoyalFlow/HowNftRoyalWork/HowNftRoyalWorkScreen';
import NftRoyalCreateGameSuccessScreen from '../../screens/TabBar/Home/NftRoyalFlow/NftRoyalCreateGameSuccess/NftRoyalCreateGameSuccessScreen';
import NftRoyalCreateGameWelcomeScreen from '../../screens/TabBar/Home/NftRoyalFlow/NftRoyalCreateGameWelcome/NftRoyalCreateGameWelcomeScreen';
import NftRoyalEnterCoinsAmountScreen from '../../screens/TabBar/Home/NftRoyalFlow/NftRoyalEnterCoinsAmount/NftRoyalEnterCoinsAmountScreen';
import NftRoyalGameConfigurationsScreen from '../../screens/TabBar/Home/NftRoyalFlow/NftRoyalGameConfigurations/NftRoyalGameConfigurationsScreen';
import NftRoyalGameHistoryScreen from '../../screens/TabBar/Home/NftRoyalFlow/NftRoyalGameHistory/NftRoyalGameHistoryScreen';
import NftRoyalPlayGameScreen from '../../screens/TabBar/Home/NftRoyalFlow/NftRoyalPlayGame/NftRoyalPlayGameScreen';
import WelcomeHowNftRoyalWorkScreen from '../../screens/TabBar/Home/NftRoyalFlow/WelcomeHowNftRoyalWork/WelcomeHowNftRoyalWorkScreen';
import ROUTES from '../routes';
import { NftRoyalTabsNavigator } from './NftRoyalTabsNavigator';

const NftRoyalGroup = (RootStack: any) => {
    return (
        <>
            <RootStack.Screen
                name={ROUTES.WELCOME_HOW_NFT_ROYAL_WORK}
                component={WelcomeHowNftRoyalWorkScreen}
            />
            <RootStack.Screen
                name={ROUTES.HOW_NFT_ROYAL_WORK}
                component={HowNftRoyalWorkScreen}
                options={{
                    presentation: 'modal',
                    headerShown: false,
                }}
            />
            <RootStack.Screen
                name={ROUTES.NFT_ROYAL_MAIN}
                component={NftRoyalTabsNavigator}
            />

            <RootStack.Screen
                name={ROUTES.NFT_ROYAL_CREATE_GAME_WELCOME}
                component={NftRoyalCreateGameWelcomeScreen}
            />
            <RootStack.Screen
                name={ROUTES.NFT_ROYAL_GAME_CONFIGURATIONS}
                component={NftRoyalGameConfigurationsScreen}
            />
            <RootStack.Screen
                name={ROUTES.NFT_ROYAL_ENTER_COINS_AMOUNT}
                component={NftRoyalEnterCoinsAmountScreen}
            />
            <RootStack.Screen
                name={ROUTES.NFT_ROYAL_CREATE_GAME_SUCCESS}
                options={{ gestureEnabled: false }}
                component={NftRoyalCreateGameSuccessScreen}
            />
            <RootStack.Screen
                name={ROUTES.NFT_ROYAL_PLAY_GAME}
                options={{ gestureEnabled: false }}
                component={NftRoyalPlayGameScreen}
            />
            <RootStack.Screen
                name={ROUTES.NFT_ROYAL_GAME_HISTORY}
                component={NftRoyalGameHistoryScreen}
                options={{ gestureEnabled: false }}
            />
        </>
    );
};

export default NftRoyalGroup;
