import _ from 'lodash';

import { useLazyQuery } from '@apollo/client';
import { isWeb } from '@helpers/app';
import { errorsHandler } from '@helpers/errors';
import {
    QuantumSpinGameConfigOutput,
    QuantumSpinGameConfigOutputResponse,
    QuantumSpinGamePrizeOutput,
    QuantumSpinGamePrizeOutputResponse,
} from '@models/mysteryGames';
import {
    GET_QUANTUM_SPIN_GAME_CONFIG,
    GET_QUANTUM_SPIN_GAME_PRIZES,
} from '@requests/quantumSpin';

import { usePersistStorage } from '../../../../vendor/react-native-user-persist-storage';

export const useQuantumSpin = () => {
    const [quantumSpinGameConfig, setQuantumSpinGameConfig] = usePersistStorage<
        QuantumSpinGameConfigOutput | undefined
    >('MysteryGamesProvider.quantumSpinGameConfig', undefined, {
        persist: isWeb,
    });

    const [getQuantumSpinConfig] =
        useLazyQuery<QuantumSpinGameConfigOutputResponse>(
            GET_QUANTUM_SPIN_GAME_CONFIG,
            {
                fetchPolicy: 'cache-and-network',
            }
        );

    const fetchQuantumSpinConfig = async () => {
        try {
            const res = await getQuantumSpinConfig();
            if (res.data?.quantumSpinGameConfig) {
                setQuantumSpinGameConfig(res.data.quantumSpinGameConfig);
            }
        } catch (error) {
            errorsHandler(error);
        }
    };

    const [quantumSpinGamePrizes, setQuantumSpinGamePrizes] = usePersistStorage<
        QuantumSpinGamePrizeOutput[] | undefined
    >('MysteryGamesProvider.quantumSpinGamePrizes', undefined, {
        persist: isWeb,
    });

    const [
        getQuantumSpinGamePrizes,
        { loading: loadingQuantumSpinGamePrizes },
    ] = useLazyQuery<QuantumSpinGamePrizeOutputResponse>(
        GET_QUANTUM_SPIN_GAME_PRIZES,
        {
            fetchPolicy: 'cache-and-network',
        }
    );
    const fetchQuantumSpinGamePrizes = async () => {
        try {
            const res = await getQuantumSpinGamePrizes();

            if (!!res.data?.quantumSpinGamePrizes.length) {
                setQuantumSpinGamePrizes(res.data.quantumSpinGamePrizes);
            }
        } catch (error) {
            errorsHandler(error);
        }
    };

    return {
        fetchQuantumSpinConfig,
        fetchQuantumSpinGamePrizes,
        quantumSpinGameConfig,
        quantumSpinGamePrizes,
        loadingQuantumSpinGamePrizes,
    };
};
