import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const stylesMain = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: Colors[theme].background,
        },
        inputWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            marginHorizontal: scale(40),
        },
        inputContainer: {
            ...font({
                size: 28,
                // height: 36,
                weight: '700',
            }),
            marginLeft: scale(8),
            minWidth: 45,
            color: Colors[theme].text.primary,
        },
        icon: {
            marginTop: verticalScale(6),
        },
        inputWebContainer: {
            marginLeft: 0,
            textAlign: 'center',
        },
        page: {
            paddingTop: verticalScale(16),
            alignItems: 'center',
        },
        availableWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: verticalScale(16),
            marginHorizontal: scale(60),
        },
        buttonAll: { marginLeft: scale(8) },
        available: {
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
            letterSpacing: 0.08,
            color: Colors[theme].text.secondary,
        },
        amount: {
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),

            color: Colors[theme].text.primary,
        },
        gap: {
            marginTop: verticalScale(16),
        },
        cardWrapper: {
            marginHorizontal: scale(20),
            marginBottom: verticalScale(8),
        },

        errorsWrapper: {
            marginTop: verticalScale(16),
            paddingHorizontal: scale(20),
        },
        error: {
            textAlign: 'center',
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
            color: Colors[theme].incorrect,
        },
        topUp: {
            alignSelf: 'center',
            marginTop: verticalScale(16),
        },
        feature: {
            width: '90%',
            marginTop: verticalScale(24),
            marginHorizontal: scale(20),
        },
    });

export default stylesMain;
