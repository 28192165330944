import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(20),
            marginTop: verticalScale(32),
        },
        main: {
            flex: 1,
        },
        header: {
            backgroundColor: 'transparent',
        },
        accent: {
            color: Colors[theme].text.accent,
        },

        gap: {
            marginTop: verticalScale(16),
        },
        video: {
            marginVertical: verticalScale(20),
            width: '100%',
            overflow: 'hidden',
            borderRadius: moderateScale(8),
        },
    });

export default styles;
