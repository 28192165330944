import { useEffect, useState } from 'react';

import { Wallet, ethers } from 'ethers';
import _ from 'lodash';

import { IWalletBalances, WalletData } from '@contexts/Wallet/WalletHelpers';
import { balanceFromWei } from '@helpers/wallet';

import { Config, User } from '../../Data/Models';
import { busd } from '../../Data/busd.abi';
import { igu } from '../../Data/erc20.abi';
import { igup } from '../../Data/igup.abi';
import { abi } from '../../Data/nft.abi';

const settings = {
    reloadInterval: 15 * 1000, // how often to reload balance
};

const useLoadBalances = (
    reloadDisabled: boolean,
    user: User | undefined,
    walletData: WalletData | null,
    config?: Config
) => {
    const [balances, setBalances] = useState<IWalletBalances | null>(null);

    useEffect(() => {
        if (!walletData?.wallet || !config || !user) {
            return;
        }

        loadBalances(walletData.wallet, config)
            .then((newBalance) => {
                setBalances(newBalance);
            })
            .catch((error) => Console.error(error));
    }, [user, walletData, config]);

    useEffect(() => {
        let isMounted = true;

        if (!user || !walletData?.wallet || !config) {
            return;
        }

        if (reloadDisabled) {
            return;
        }

        const interval = setInterval(() => {
            Console.log('Updating balances..');

            loadBalances(walletData.wallet, config)
                .then((newBalance) => {
                    if (!_.isEqual(balances, newBalance)) {
                        if (isMounted) setBalances(newBalance);
                        Console.log(
                            '[useLoadBalances] Balances NOT equal, updating'
                        );
                    } else {
                        Console.log(
                            '[useLoadBalances] Balances equal, NOT updating'
                        );
                    }
                })
                .catch((error) => Console.error(error));
        }, settings.reloadInterval);

        return () => {
            isMounted = false;
            clearInterval(interval);
        };
    }, [user, walletData, config, balances, reloadDisabled]);

    async function refetchWallet() {
        if (!user || !walletData?.wallet || !config) {
            return;
        }

        await loadBalances(walletData.wallet, config)
            .then((newBalance) => {
                if (!_.isEqual(balances, newBalance)) {
                    setBalances(newBalance);
                }
            })
            .catch((error) => Console.error(error));
    }

    return { balances, refetchWallet };
};

async function loadBalances(wallet: Wallet, config: Config) {
    const busdAddress = config.busdContractAddress;
    const igupAddress = config.igupContractAddress;
    const iguAddress = config.iguTokenContractAddress;
    const nftAddress = config.nftIguverseContractAddress;
    const nftContract = new ethers.Contract(nftAddress, abi, wallet);

    const bnbPromise = wallet.provider.getBalance(wallet.address);
    const busdPromise = new ethers.Contract(
        busdAddress,
        busd,
        wallet
    ).balanceOf(wallet.address);
    const igupPromise = new ethers.Contract(
        igupAddress,
        igup,
        wallet
    ).balanceOf(wallet.address);
    const iguPromise = new ethers.Contract(iguAddress, igu, wallet).balanceOf(
        wallet.address
    );
    const nftTokensPromise = nftContract.tokensOfOwner(wallet.address);

    const values: any[] = await Promise.all([
        bnbPromise,
        busdPromise,
        iguPromise,
        igupPromise,
        nftTokensPromise,
    ]);

    const tokens = values[4].toString().split(',');
    const bnbBalance = balanceFromWei(values[0]);
    const busdBalance = balanceFromWei(values[1]);
    const iguBalance = balanceFromWei(values[2]);
    const igupBalance = balanceFromWei(values[3]);
    const nftsCount = tokens.length.toString();

    return {
        bnb: bnbBalance,
        busd: busdBalance,
        igu: iguBalance,
        igup: igupBalance,
        nftsCount: Number(nftsCount),
        tokens,
    };
}

export default useLoadBalances;
