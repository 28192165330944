import * as React from 'react';

import ChainIcon from '@assets/icons/mysteryGames/QuantumSpin/chain.svg';
import SeedIcon from '@assets/icons/mysteryGames/QuantumSpin/seed.svg';
import { View } from '@components/Themed';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { statisticsFormatter } from '@helpers/helpers';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';

import QuantumSpinInfoItem from '../QuantumSpinInfoItem';
import stylesMain from './styles';

const game = MYSTERY_GAMES.quantumSpin;

const QuantumSpinProbablyFairInfo = () => {
    const styles = useThemedStyles(stylesMain);

    const { quantumSpinGameConfig } = useMysteryGames();

    return !!quantumSpinGameConfig ? (
        <View style={styles.info}>
            <QuantumSpinInfoItem
                icon={<ChainIcon width={24} height={24} />}
                title={i18n.t('general.KEYS.chainResults')}
                text={`${statisticsFormatter(
                    quantumSpinGameConfig.pregeneratedHashesCount
                )} / ${i18n.t(
                    'profile.socialMediaSettings.media.socials.twitter'
                )}`}
                link={quantumSpinGameConfig.twitterBlockPostUrl}
            />
            <QuantumSpinInfoItem
                containerStyle={styles.gap}
                icon={<SeedIcon width={24} height={24} />}
                title={i18n.t('general.KEYS.globalSeed')}
                text={quantumSpinGameConfig.globalSeed}
                copyText={quantumSpinGameConfig.globalSeed}
            />
        </View>
    ) : null;
};

export default QuantumSpinProbablyFairInfo;
