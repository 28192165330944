import * as React from 'react';
import { useContext } from 'react';

import { CreatePetResponse, PaymentOptionOutput, Pet } from '@Data/Models';
import { CREATE_PET } from '@Data/Requests';
import { useMutation } from '@apollo/client';
import { STEP_INDICATOR_FLOWS } from '@components/FlowStepIndicator';
import { MINT_PET_STAGES_NAME } from '@components/FlowStepIndicator/flows/mintNFTStages';
import { useAppState } from '@contexts/AppStateContext';
import { MintContext } from '@contexts/MintContext';
import { usePlay } from '@contexts/PlayContext';
import { useStorePurchase } from '@contexts/StorePurchaseContext';
import { useUser } from '@contexts/UserContext';
import { ITransactionResult, useWallet } from '@contexts/Wallet/WalletContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import {
    isGraphqlError,
    isServerError,
    wait,
    waitForNavigation,
} from '@helpers/helpers';
import { toastError, toastErrorUnknown } from '@helpers/toastNotification';
import { useNavigation } from '@navigation/useNavigation';

import CheckoutScreen from '../../../components/CheckoutScreen';
import StorePurchase from '../../../components/CheckoutScreen/StorePurchase/StorePurchase';
import CustomModal from '../../../components/CustomModal';
import ImageIguana from '../../../components/ImageIguana';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import ROUTES from '../../../navigation/routes';
import MintContainer from '../components/MintContainer';
import TransactionDetailsMint from '../components/TransactionDetailsMint';
import stylesMain from './styles';

const MintPetCheckoutScreen = ({ route }: any) => {
    const styles = useThemedStyles(stylesMain);

    const {
        rank,
        setDisabledModalGenerateNewNft,
        setChangeRankCheckOut,
        savedPhoto,
        name,
        AIImage,
    } = useContext(MintContext);
    const { refetchPets } = usePlay();
    const { reloadUser } = useUser();
    const { executeTransaction } = useWallet();
    const [createPetRequest] = useMutation<CreatePetResponse>(CREATE_PET);
    const [isModalVisibleRank, setModalVisibleRank] =
        React.useState<boolean>(false);
    const navigation = useNavigation();
    const {
        purchasePet,
        reconfigureStoreWithItemId,
        isStoreError,
        isStoreLoading,
        hideModal,
        setError,
        stopLoading,
        setLoading,
        storeErrorCode,
        storeErrorType,
        storeLoadingType,
    } = useStorePurchase();
    const { setIsMintForceSuccessScreen } = useAppState();
    const { user, petsCount } = useUser();

    const payments = React.useMemo(() => rank?.payments || [], [rank]);

    const isFromLootBox = React.useMemo(
        () => !!payments.find((o) => o.token === 'LOOTBOX'),
        [payments]
    );

    const isFree =
        !Number(rank?.mintPriceUsd) ||
        !!rank?.whitelistFreeMintsCount ||
        isFromLootBox;

    const handleChangeRank = () => {
        setChangeRankCheckOut(true);
        setDisabledModalGenerateNewNft(true);
        setModalVisibleRank(true);
    };

    const handleCloseModalRank = () => {
        setChangeRankCheckOut(false);
        setDisabledModalGenerateNewNft(false);
        setModalVisibleRank(false);
    };

    const createPet = React.useCallback(
        async (payment: PaymentOptionOutput) => {
            const createPetResult = await createPetRequest({
                variables: {
                    createPetInput: {
                        token: payment.token,
                        fileId: savedPhoto?.id ?? AIImage?.id,
                        name: name?.trim(),
                        rank: rank?.id,
                    },
                },
            });
            return createPetResult.data?.createPet;
        },
        [savedPhoto, AIImage, name, rank]
    );

    const handleOnSuccess = async (
        payment: PaymentOptionOutput,
        isFree: boolean,
        result: ITransactionResult | null
    ) => {
        // this variable will keep user on the success state instead of redirect because of change State.loggedNoPet to State.loggedInWallet
        if (!petsCount) {
            setIsMintForceSuccessScreen(true);
        }
        reloadUser();
        refetchPets(true);

        await waitForNavigation();
        navigation.popToTop();
        navigation.navigate(ROUTES.MINT_PET_CHECKOUT_SUCCESS, {
            transactionResult: result,
            payment,
            isFree,
        });
    };

    const handlePlayStorePurchase = async (
        productId: string,
        petId: string
    ) => {
        const config = await reconfigureStoreWithItemId(petId);
        if (config === false) {
            return config;
        }
        const purchase = await purchasePet(productId, petId);
        if (purchase === false) {
            return purchase;
        }
        setLoading('FINALIZE');
        let pet: Pet | undefined;
        let saveStop = 0;

        do {
            const result = await refetchPets(true);

            pet = result.pets.find((pet) => pet.id === petId);

            saveStop++;
            await wait(3000);
        } while (!pet && saveStop !== 20);

        if (saveStop === 20) {
            setError('FINALIZE');
            return false;
        }
        stopLoading();
        return true;
    };

    const handleOnPurchase = React.useCallback(
        async (
            payment: PaymentOptionOutput
        ): Promise<ITransactionResult | null> => {
            const paymentResponse = await createPet(payment);

            if (!paymentResponse) {
                return null;
            }

            if (paymentResponse.signedTransaction) {
                const result = await executeTransaction(
                    paymentResponse.payment,
                    paymentResponse.signedTransaction
                );
                await handleOnSuccess(payment, false, result);
                return result;
            }

            if (paymentResponse.payment.productId && user) {
                const result = await handlePlayStorePurchase(
                    paymentResponse.payment.productId,
                    paymentResponse.petId
                );
                if (result) {
                    await handleOnSuccess(payment, false, null);
                }

                return null;
            }

            await handleOnSuccess(payment, true, null);
            return null;
        },
        [user, createPet]
    );

    const handleOnError = (error: any) => {
        if (isGraphqlError(error, 'PET_WRONG_STATE')) {
            toastError(
                i18n.t('mintNamePhoto.errors.mint.title'),
                i18n.t('mintNamePhoto.errors.mint.petRankWrongState')
            );
        } else if (isGraphqlError(error, 'OBSCENE_PET_NAME')) {
            toastError(
                i18n.t('mintNamePhoto.errors.mint.title'),
                i18n.t('mintNamePhoto.errors.mint.obscenePetName')
            );
        } else if (isServerError(error)) {
            // add server error logic
        } else {
            Console.log(error);
            toastErrorUnknown();
        }
    };

    return (
        <>
            <MintContainer
                icon="leftArrow"
                flow={STEP_INDICATOR_FLOWS.MINT_PET}
                stage={MINT_PET_STAGES_NAME.STEP_THREE}
                stageFill="50%"
                flowStyle={styles.flowContainer}>
                <CheckoutScreen
                    payments={payments}
                    defaultPayment={payments.find((p) => p.token === Coin.igu)}
                    isFree={isFree}
                    TransactionDetailsComponent={
                        <TransactionDetailsMint
                            isLootBox={isFromLootBox}
                            onEditRankPress={handleChangeRank}
                        />
                    }
                    withConfirmation={i18n.t('mintCheckout.transaction')}
                    onPurchase={handleOnPurchase}
                    onPurchaseError={handleOnError}
                />
            </MintContainer>
            {/* Modal Rank */}
            <CustomModal
                isVisible={isModalVisibleRank}
                icon={<ImageIguana type="attention" />}
                titleText={i18n.t('modal.changeRank')}
                infoText={i18n.t('modal.changeRankInfo')}
                firstButtonText={i18n.t('modal.changeRankGo')}
                secondButtonText={i18n.t('modal.cancel')}
                onFirstButtonClick={() => {
                    navigation.navigate(ROUTES.MINT_PET_RANKS);
                }}
                onSecondButtonClick={handleCloseModalRank}
            />
            <StorePurchase
                isStoreError={isStoreError}
                isStoreLoading={isStoreLoading}
                errorType={storeErrorType}
                loadingType={storeLoadingType}
                errorCode={storeErrorCode}
                action={hideModal}
            />
        </>
    );
};

export default MintPetCheckoutScreen;
