import * as React from 'react';

import { BanReasonsOutput } from '@Data/Models';
import SelectorV2 from '@components/SelectorV2';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';

import BanReasonSelectModal from '../BanReasonSelectModal';

interface IProps {
    error: string | undefined;
    selectedBanReason: BanReasonsOutput | undefined;
    banReasons: BanReasonsOutput[] | undefined;
    onPress: (ban: BanReasonsOutput) => void;
    loading?: boolean;
}

const BanReasonSelect = ({
    error,
    onPress,
    selectedBanReason,
    banReasons,
    loading,
}: IProps) => {
    const { isVisible, open, close } = useVisibleHook();

    return (
        <SelectorV2
            loading={loading}
            label={i18n.t('profile.adminBlockUser.banReason.label')}
            onPress={open}
            selectedItem={selectedBanReason?.label}
            placeHolder={i18n.t('profile.adminBlockUser.banReason.placeHolder')}
            error={error}
            modal={
                <>
                    {!!banReasons && (
                        <BanReasonSelectModal
                            isVisible={isVisible}
                            close={close}
                            selectedBanReason={selectedBanReason}
                            banReasons={banReasons}
                            onPress={onPress}
                            modalHeight="96%"
                        />
                    )}
                </>
            }
        />
    );
};

export default BanReasonSelect;
