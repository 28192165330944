import * as React from 'react';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

import WhiteListIcon from '@assets/icons/mint/whiteList.svg';
import { Text, View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    amount: number;
    customStyle?: StyleProp<ViewStyle>;
    textStyle?: StyleProp<TextStyle>;
}

const MintWhiteList = ({ amount, customStyle, textStyle }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <>
            <View style={[styles.container, customStyle]}>
                <Text style={[styles.text, textStyle]}>
                    {i18n.t('mintPetRank.whiteList', {
                        amount,
                    })}
                </Text>
                <View style={styles.image}>
                    <WhiteListIcon />
                </View>
            </View>
        </>
    );
};
export default MintWhiteList;
