import * as React from 'react';

import VIGU1K from '@assets/icons/gaming/vigu1K.svg';
import VIGU5K from '@assets/icons/gaming/vigu5K.svg';
import VIGU10K from '@assets/icons/gaming/vigu10K.svg';
import VIGU50K from '@assets/icons/gaming/vigu50K.svg';
import VIGU100 from '@assets/icons/gaming/vigu100.svg';
import VIGU500 from '@assets/icons/gaming/vigu500.svg';

import { IconType, iconSize } from '../types';

export enum VIGU_TOP_UP {
    VIGU100 = 'vigu100',
    VIGU500 = 'vigu500',
    VIGU1K = 'vigu1k',
    VIGU5K = 'vigu5k',
    VIGU10K = 'vigu10k',
    VIGU50K = 'vigu50k',
}

interface IVIGUTopUpArray {
    id: VIGU_TOP_UP;
    text: string;
    amount: string;
}

export const VIGU_TOP_UP_ARRAY: IVIGUTopUpArray[] = [
    {
        id: VIGU_TOP_UP.VIGU100,
        text: '100',
        amount: '100',
    },
    {
        id: VIGU_TOP_UP.VIGU500,
        text: '500',
        amount: '500',
    },
    {
        id: VIGU_TOP_UP.VIGU1K,
        text: '1K',
        amount: '1000',
    },
    {
        id: VIGU_TOP_UP.VIGU5K,
        text: '5K',
        amount: '5000',
    },
    {
        id: VIGU_TOP_UP.VIGU10K,
        text: '10K',
        amount: '10000',
    },
    {
        id: VIGU_TOP_UP.VIGU50K,
        text: '50K',
        amount: '50000',
    },
];

interface IProps extends IconType {
    slug: VIGU_TOP_UP;
}

const VIGUTopUpIcon = ({ slug, size = iconSize.primary }: IProps) => {
    switch (slug) {
        case VIGU_TOP_UP.VIGU100:
            return <VIGU100 width={size} height={size} />;
        case VIGU_TOP_UP.VIGU500:
            return <VIGU500 width={size} height={size} />;
        case VIGU_TOP_UP.VIGU1K:
            return <VIGU1K width={size} height={size} />;
        case VIGU_TOP_UP.VIGU5K:
            return <VIGU5K width={size} height={size} />;
        case VIGU_TOP_UP.VIGU10K:
            return <VIGU10K width={size} height={size} />;
        case VIGU_TOP_UP.VIGU50K:
            return <VIGU50K width={size} height={size} />;
        default:
            return null;
    }
};

export default VIGUTopUpIcon;
