import * as React from 'react';
import { ScrollView, TouchableOpacity, View } from 'react-native';

import ICON from '@assets/icons/emptyState/aiOverload.svg';
import High from '@assets/icons/mint/aiQuote/high.svg';
import Low from '@assets/icons/mint/aiQuote/low.svg';
import Medium from '@assets/icons/mint/aiQuote/medium.svg';
import Overloaded from '@assets/icons/mint/aiQuote/overloaded.svg';
import System from '@assets/icons/mint/aiQuote/system.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import CardFeatureInfo from '@components/CardFeatureInfo/CardFeatureInfo';
import ModalBottom from '@components/ModalBottom';
import { useAIInfo } from '@hooks/useAIInfo';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
}

const ModalAiInfo = ({ isVisible, onClose }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { aiQuota, currentSystemLoad } = useAIInfo();

    const systemLoadIcon = React.useMemo(() => {
        switch (currentSystemLoad) {
            case 'overloaded':
                return <Overloaded />;
            case 'medium':
                return <Medium />;
            case 'high':
                return <High />;

            default:
                return <Low />;
        }
    }, [currentSystemLoad]);

    return (
        <ModalBottom
            isVisible={isVisible}
            onClose={onClose}
            modalHeight="96%"
            containerStyle={styles.containerStyle}
            swipeDirection={['down']}>
            <ScrollView
                style={[styles.container]}
                showsVerticalScrollIndicator={false}
                bounces={false}>
                <TouchableOpacity activeOpacity={1}>
                    <BlockHeader
                        emptyStateIcon={<ICON />}
                        type="emptyState"
                        title={i18n.t('aiQuoteInfo.modalInfo.title')}
                        text={i18n.t('aiQuoteInfo.modalInfo.text')}
                    />
                    <View style={styles.cardWrapper}>
                        <CardFeatureInfo
                            containerStyle={styles.gap}
                            icon={systemLoadIcon}
                            title={i18n.t('aiQuoteInfo.modalInfo.systemLoad', {
                                load: i18n.t(
                                    `aiQuoteInfo.modalInfo.load.${currentSystemLoad}`
                                ),
                            })}
                            text={i18n.t(
                                'aiQuoteInfo.modalInfo.systemLoadText'
                            )}
                        />
                        <CardFeatureInfo
                            containerStyle={styles.gap}
                            icon={<System />}
                            title={i18n.t('aiQuoteInfo.modalInfo.systemUsage', {
                                percentage:
                                    aiQuota?.dailySystemUsagePercentage || 0,
                            })}
                            text={i18n.t(
                                'aiQuoteInfo.modalInfo.systemUsageText'
                            )}
                        />
                    </View>
                </TouchableOpacity>
            </ScrollView>
        </ModalBottom>
    );
};
export default ModalAiInfo;
