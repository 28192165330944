import * as React from 'react';
import { ScrollView, StyleProp, ViewStyle } from 'react-native';

import * as Clipboard from 'expo-clipboard';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import Button from '@components/Button';
import Icon, { ICON_NAMES } from '@components/Icons';
import { View } from '@components/Themed';
import Tooltip from '@components/Tooltip';
import TypoText from '@components/TypoText';
import { TYPO_TEXT, TYPO_TEXT_COLOR } from '@components/TypoText/types';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';

import { VERIFICATION_EXAMPLE_CODE } from './code';
import stylesMain from './styles';

interface IProps {
    containerStyles?: StyleProp<ViewStyle>;
}

const game = MYSTERY_GAMES.quantumSpin;

const QuantumSpinProbablyFairVerificationExample = ({
    containerStyles,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const [copied, setCopied] = React.useState<boolean>(false);
    const copyToClipboard = async () => {
        await Clipboard.setStringAsync(VERIFICATION_EXAMPLE_CODE);
        setCopied(true);
    };
    React.useEffect(() => {
        if (!copied) return;
        const timer = setTimeout(() => {
            setCopied(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, [copied]);

    return (
        <View style={styles.container}>
            <BlockHeader
                type="primary"
                title={i18n.t(
                    `HomeScreen.${game}.QuantumSpinProvablyFair.verification.title`
                )}
                text={i18n.t(
                    `HomeScreen.${game}.QuantumSpinProvablyFair.verification.text`
                )}
            />
            <View style={styles.codeWrapper}>
                <View style={styles.button}>
                    <Tooltip
                        isVisible={copied}
                        text={
                            <TypoText
                                type={TYPO_TEXT.BODY_SMALL}
                                colorType={TYPO_TEXT_COLOR.PRIMARY}>
                                {i18n.t('general.copiedToClipboard')}
                            </TypoText>
                        }
                        placement="top"
                        onClose={() => {
                            setCopied(false);
                        }}>
                        <Button
                            type="outline"
                            icon={
                                copied ? (
                                    <Icon
                                        name={ICON_NAMES.COPY}
                                        size={20}
                                        color="green"
                                    />
                                ) : (
                                    <Icon
                                        name={ICON_NAMES.COPY}
                                        size={20}
                                        color="primary"
                                    />
                                )
                            }
                            iconSize={20}
                            size="md"
                            onPress={copyToClipboard}
                        />
                    </Tooltip>
                </View>
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    nestedScrollEnabled>
                    <TypoText
                        type={TYPO_TEXT.BODY_SMALL}
                        colorType={TYPO_TEXT_COLOR.PRIMARY}>
                        {VERIFICATION_EXAMPLE_CODE}
                    </TypoText>
                </ScrollView>
            </View>
        </View>
    );
};

export default QuantumSpinProbablyFairVerificationExample;
