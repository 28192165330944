import * as React from 'react';
import { FlatList } from 'react-native';

import FirstPlace from '@assets/icons/leaderboard/firstPlace.svg';
import SecondPlace from '@assets/icons/leaderboard/secondPlace.svg';
import ThirdPlace from '@assets/icons/leaderboard/thirdPlace.svg';
import Icon, { ICON_NAMES } from '@components/Icons';
import OtherUserAvatar from '@components/OtherUserAvatar';
import { Text, View } from '@components/Themed';
import { scale } from '@helpers/dimensions';
import { statisticsFormatter } from '@helpers/helpers';
import { truncateEthAddress } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import { RankingRow } from '@models/leaderboard';

import stylesMain from './styles';

interface IProps {
    topPlayers: RankingRow[];
    userAddress: string;
}

const DailyTasksBoard = ({ topPlayers, userAddress }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const placeView = (place: string) => {
        switch (place) {
            case '1':
                return <FirstPlace width={24} height={24} />;
            case '2':
                return <SecondPlace width={24} height={24} />;
            case '3':
                return <ThirdPlace width={24} height={24} />;

            default:
                return <Text style={styles.place}>{place}</Text>;
        }
    };

    const renderItem = React.useCallback(
        (item: RankingRow) => {
            const { rank, totalEnergyRewarded } = item;
            const inBoard = userAddress === item.user?.walletAddress;

            return (
                <View style={[styles.shadow, inBoard && styles.active]}>
                    <View
                        style={[
                            styles.itemContainer,
                            inBoard && styles.activeContainer,
                        ]}>
                        <View style={styles.left}>
                            {placeView(rank)}
                            <View style={styles.imageWrapper}>
                                <OtherUserAvatar
                                    size={scale(40)}
                                    imageUrl={
                                        item.user?.mainPet?.image?.thumbnailUrl
                                    }
                                    userId={item.user?.walletAddress}
                                />
                            </View>
                            <Text
                                style={styles.address}
                                ellipsizeMode={'middle'}
                                numberOfLines={1}>
                                {truncateEthAddress(
                                    item.user?.username ||
                                        item.user?.walletAddress
                                )}
                            </Text>
                        </View>
                        <View style={styles.right}>
                            <Text style={styles.energy}>
                                {statisticsFormatter(
                                    Number(totalEnergyRewarded)
                                )}
                            </Text>
                            <Icon name={ICON_NAMES.ENERGY} />
                        </View>
                    </View>
                </View>
            );
        },
        [userAddress, styles]
    );

    return (
        <FlatList
            showsVerticalScrollIndicator={false}
            style={styles.list}
            data={topPlayers}
            initialNumToRender={10}
            renderItem={({ item }) => renderItem(item)}
            keyExtractor={(item) => item.rank.toString()}
        />
    );
};
export default DailyTasksBoard;
