import { IStage } from '..';

export enum STAKING_ADD_MORE_NAME {
    STEP_ONE = 'STEP_ONE',
    STEP_TWO = 'STEP_TWO',
}

type IStakingAddMore = {
    STEP_ONE: IStage;
    STEP_TWO: IStage;
};

export const stakingAddMore: IStakingAddMore = {
    STEP_ONE: {
        customFillIndex: 1,
        fill: ['100%', '0%'],
    },

    STEP_TWO: {
        customFillIndex: 2,
        fill: ['100%', '100%'],
    },
};
