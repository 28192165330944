import * as React from 'react';

import CheckoutSuccessScreen from '@components/CheckoutSuccessScreen';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import { StakingSuccessProps } from '../../../../types';
import StakingCardSummary from '../components/StakingCardSummary/StakingCardSummary';
import { useStakingSuccessScreen } from './hook';
import stylesMain from './styles';

const StakingSuccessScreen = ({ route }: StakingSuccessProps) => {
    const styles = useThemedStyles(stylesMain);

    const {
        currentPlan,
        stakingBoost,
        stakingAmount,
        stakingCoin,
        currentStakingPlan,
        handleBackToWallet,
    } = useStakingSuccessScreen();

    const renderTransactionDetailComponent = React.useCallback(() => {
        if (!currentPlan) {
            return null;
        }
        return (
            <StakingCardSummary
                data={currentPlan}
                coin={stakingCoin}
                stakingAmount={stakingAmount}
                stakingBoost={stakingBoost}
                current={currentStakingPlan?.amountWei}
            />
        );
    }, [
        currentPlan,
        stakingCoin,
        stakingAmount,
        stakingBoost,
        currentStakingPlan,
    ]);

    return (
        <CheckoutSuccessScreen
            title={i18n.t('staking.successScreen.title')}
            text={i18n.t('staking.successScreen.text')}
            onPressButtonMain={handleBackToWallet}
            buttonMainText={i18n.t('staking.successScreen.button')}
            TransactionDetailsComponent={renderTransactionDetailComponent()}
            transactionDetailsStyle={styles.container}
            explorerUrl={route.params.transactionResult.explorerUrl}
        />
    );
};

export default StakingSuccessScreen;
