import * as React from 'react';

import CopyToClipboardButton from '@components/CopyToClipboardButton';
import { Text, View } from '@components/Themed';
import { scale } from '@helpers/dimensions';
import useThemedStyles from '@hooks/useThemedStyles';
import {
    ReferralsInviteLink,
    getDeeplinkReferralsInvite,
} from '@navigation/helpers';

import stylesMain from './styles';

interface IProps {
    code: string;
    text?: string;
    defaultBg?: boolean;
}

const ReferralLink = ({ code, text, defaultBg = false }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const link = getDeeplinkReferralsInvite(code).toLowerCase();
    const [width, setWidth] = React.useState(0);

    return (
        <View
            style={[styles.container, defaultBg && styles.defaultBg]}
            onLayout={({
                nativeEvent: {
                    layout: { width },
                },
            }) => {
                setWidth(width);
            }}>
            {!!text && <Text style={styles.text}>{text}</Text>}
            <View style={[styles.right, !text ? styles.withoutText : {}]}>
                <Text
                    style={[styles.link]}
                    numberOfLines={1}
                    ellipsizeMode="middle">
                    {ReferralsInviteLink(link)}
                </Text>
                <CopyToClipboardButton value={link} />
            </View>
        </View>
    );
};
export default ReferralLink;
