import React, { useMemo } from 'react';

import { useTheme } from '@contexts/ThemeContext';

import Colors, { IconColorType } from '../../constants/Colors';
import AppleIcon from './Apple';
import ArrowDownIcon from './ArrowDown';
import ArrowDropDownIcon from './ArrowDropDown';
import ArrowDropDownSelectIcon from './ArrowDropDownSelect';
import ArrowDropUpIcon from './ArrowDropUp';
import ArrowLeftIcon from './ArrowLeft';
import ArrowRightIcon from './ArrowRight';
import ArrowRightSecondaryIcon from './ArrowRightSecondary';
import ArrowUpIcon from './ArrowUp';
import ArrowUpgrade from './ArrowUpgrade';
import BinIcon from './Bin';
import BiSwap from './Biswap';
import CameraIcon from './Camera';
import CancelBlack from './CancelRound';
import Chart from './Chart';
import CheckIcon from './Check';
import ClaimIcon from './Claim';
import CloseIcon from './Close';
import CooldownIcon from './Cooldown';
import CopyIcon from './Copy';
import DNAIcon from './DNA';
import DeleteSearchIcon from './DeleteSearch';
import DepositIcon from './Deposit';
import DislikeIcon from './Dislike';
import DislikeFillIcon from './DislikeFill';
import DollarIcon from './Dollar';
import DownArrow from './DownArrow';
import DownloadIcon from './Download';
import Energy from './Energy';
import ErrorIcon from './Error';
import ExploreIcon from './Explore';
import FacebookIcon from './Facebook';
import FeedIcon from './Feed';
import FilterIcon from './Filter';
import Fruit from './Fruit';
import FullSizeIcon from './FullSize';
import GoogleIcon from './Google';
import HealthIcon from './Health';
import Heart from './Heart';
import InstagramIcon from './Instagram';
import InviteIcon from './Invite';
import IsAgeDeadIcon from './IsAgeDead';
import IsDeadIcon from './IsDead';
import KeyIcon from './Key';
import LevelIcon from './Level';
import LikeIcon from './Like';
import LikeFillIcon from './LikeFill';
import LockIcon from './Lock';
import LockFillIcon from './LockFill';
import MinusIcon from './Minus';
import MuteAudio from './MuteAudio';
import Pause from './Pause';
import PencilIcon from './Pencil';
import Play from './Play';
import PlusIcon from './Plus';
import QuestionIcon from './Question';
import RandomIcon from './Random';
import RefreshIcon from './Refresh';
import RestoreIcon from './Restore';
import RevealIcon from './Reveal';
import RevealedIcon from './Revealed';
import ScanIcon from './Scan';
import Search from './Search';
import SettingsIcon from './Settings';
import ShareIcon from './Share';
import ShopBagIcon from './ShopBag';
import StakeIcon from './Stake';
import Stop from './Stop';
import SuccessIcon from './Success';
import TaskIcon from './Task';
import TikTokIcon from './TikTok';
import TradeIcon from './Trade';
import TwitterIcon from './Twitter';
import UnmuteAudio from './UnmuteAudio';
import WithdrawIcon from './Withdraw';
import ICON_NAMES from './iconNames';

const Icons = {
    [ICON_NAMES.SETTINGS]: SettingsIcon,
    [ICON_NAMES.GOOGLE]: GoogleIcon,
    [ICON_NAMES.APPLE]: AppleIcon,
    [ICON_NAMES.FACEBOOK]: FacebookIcon,
    [ICON_NAMES.INSTAGRAM]: InstagramIcon,
    [ICON_NAMES.TIKTOK]: TikTokIcon,
    [ICON_NAMES.CLOSE]: CloseIcon,
    [ICON_NAMES.ARROW_UP]: ArrowUpIcon,
    [ICON_NAMES.ARROW_DOWN]: ArrowDownIcon,
    [ICON_NAMES.ARROW_LEFT]: ArrowLeftIcon,
    [ICON_NAMES.ARROW_RIGHT]: ArrowRightIcon,
    [ICON_NAMES.ARROW_RIGHT_SECONDARY]: ArrowRightSecondaryIcon,
    [ICON_NAMES.ARROW_DROP_UP]: ArrowDropUpIcon,
    [ICON_NAMES.ARROW_DROP_DOWN]: ArrowDropDownIcon,
    [ICON_NAMES.ARROW_DROP_DOWN_SELECT]: ArrowDropDownSelectIcon,
    [ICON_NAMES.DOWN_ARROW]: DownArrow,
    [ICON_NAMES.CAMERA]: CameraIcon,
    [ICON_NAMES.PLUS]: PlusIcon,
    [ICON_NAMES.MINUS]: MinusIcon,
    [ICON_NAMES.BIN]: BinIcon,
    [ICON_NAMES.TWITTER]: TwitterIcon,
    [ICON_NAMES.PENCIL]: PencilIcon,
    [ICON_NAMES.DOWNLOAD]: DownloadIcon,
    [ICON_NAMES.DEPOSIT]: DepositIcon,
    [ICON_NAMES.WITHDRAW]: WithdrawIcon,
    [ICON_NAMES.TRADE]: TradeIcon,
    [ICON_NAMES.SCAN]: ScanIcon,
    [ICON_NAMES.LOCK]: LockIcon,
    [ICON_NAMES.SEARCH]: Search,
    [ICON_NAMES.ENERGY]: Energy,
    [ICON_NAMES.BISWAP]: BiSwap,
    [ICON_NAMES.STOP]: Stop,
    [ICON_NAMES.CHART]: Chart,
    [ICON_NAMES.PAUSE]: Pause,
    [ICON_NAMES.PLAY]: Play,
    [ICON_NAMES.HEART]: Heart,
    [ICON_NAMES.CANCEL_ROUND]: CancelBlack,
    [ICON_NAMES.ARROW_UPGRADE]: ArrowUpgrade,
    [ICON_NAMES.FULL_SIZE]: FullSizeIcon,
    [ICON_NAMES.LEVEL]: LevelIcon,
    [ICON_NAMES.EXPLORE]: ExploreIcon,
    [ICON_NAMES.COOLDOWN]: CooldownIcon,
    [ICON_NAMES.QUESTION]: QuestionIcon,
    [ICON_NAMES.SHARE]: ShareIcon,
    [ICON_NAMES.HEALTH]: HealthIcon,
    [ICON_NAMES.DOLLAR]: DollarIcon,
    [ICON_NAMES.TASK]: TaskIcon,
    [ICON_NAMES.REVEAL]: RevealIcon,
    [ICON_NAMES.REVEALED]: RevealedIcon,
    [ICON_NAMES.MUTE_AUDIO]: MuteAudio,
    [ICON_NAMES.UNMUTE_AUDIO]: UnmuteAudio,
    [ICON_NAMES.FRUIT]: Fruit,
    [ICON_NAMES.KEY]: KeyIcon,
    [ICON_NAMES.RESTORE]: RestoreIcon,
    [ICON_NAMES.LIKE]: LikeIcon,
    [ICON_NAMES.LIKE_FILL]: LikeFillIcon,
    [ICON_NAMES.DISLIKE]: DislikeIcon,
    [ICON_NAMES.DISLIKE_FILL]: DislikeFillIcon,
    [ICON_NAMES.LOCK_FILL]: LockFillIcon,
    [ICON_NAMES.RANDOM]: RandomIcon,
    [ICON_NAMES.COPY]: CopyIcon,
    [ICON_NAMES.CHECK]: CheckIcon,
    [ICON_NAMES.CLAIM]: ClaimIcon,
    [ICON_NAMES.STAKE]: StakeIcon,
    [ICON_NAMES.REFRESH]: RefreshIcon,
    [ICON_NAMES.SHOP_BAG]: ShopBagIcon,
    [ICON_NAMES.INVITE]: InviteIcon,
    [ICON_NAMES.FEED]: FeedIcon,
    [ICON_NAMES.SUCCESS]: SuccessIcon,
    [ICON_NAMES.ERROR]: ErrorIcon,
    [ICON_NAMES.IS_DEAD]: IsDeadIcon,
    [ICON_NAMES.IS_AGE_DEAD]: IsAgeDeadIcon,
    [ICON_NAMES.DNA]: DNAIcon,
    [ICON_NAMES.DELETE_SEARCH]: DeleteSearchIcon,
    [ICON_NAMES.FILTER]: FilterIcon,
};

export { ICON_NAMES };

interface IIcon {
    name: ICON_NAMES;
    color?: IconColorType | string;
    [property: string]: any;
}

const Icon: React.FunctionComponent<IIcon> = ({ name, ...rest }) => {
    const { theme } = useTheme();
    const { color, size, ...other } = rest;
    const IconComponent = Icons[name as keyof typeof Icons];

    // get color from Colors array, or pass it as a string
    const themedColor =
        color && color in Colors[theme].icon
            ? Colors[theme].icon[color as IconColorType]
            : color;

    return useMemo(
        () => <IconComponent color={themedColor} size={size} {...other} />,
        [IconComponent, themedColor, size, other]
    );
};

export default Icon;
