import * as React from 'react';

import Easy from '@assets/icons/mysteryGames/gameDifficulty/easy.svg';
import Hard from '@assets/icons/mysteryGames/gameDifficulty/hard.svg';
import Medium from '@assets/icons/mysteryGames/gameDifficulty/medium.svg';
import { GAME_DIFFICULTY } from '@models/mysteryGames';

import { IconType } from '../types';

interface IProps extends IconType {
    slug: GAME_DIFFICULTY;
}

const DifficultyIcon = ({ slug, size = 48 }: IProps) => {
    switch (slug) {
        case GAME_DIFFICULTY.Hard:
            return <Hard width={size} height={size} />;
        case GAME_DIFFICULTY.Medium:
            return <Medium width={size} height={size} />;
        case GAME_DIFFICULTY.Easy:
            return <Easy width={size} height={size} />;

        default:
            return <></>;
    }
};

export default DifficultyIcon;
