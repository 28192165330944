import * as React from 'react';

import { useMutation } from '@apollo/client';
import { useRanks } from '@contexts/RanksContext';

import {
    CreateRankUpPaymentResponse,
    PaymentOptionOutput,
    Pet,
} from '../../../../Data/Models';
import { CREATE_RANK_UP_PAYMENT } from '../../../../Data/Requests';
import CheckoutScreen from '../../../../components/CheckoutScreen';
import { PlayContext } from '../../../../contexts/PlayContext';
import { useWallet } from '../../../../contexts/Wallet/WalletContext';
import i18n from '../../../../i18n/i18n';
import NavigationBar from '../../../../navigation/NavigationBar';
import ROUTES from '../../../../navigation/routes';
import { CollectionPetRankUpCheckoutProps } from '../../../../types';
import TransactionDetailsRankUp from '../components/TransactionDetailsRankUp';

const CollectionPetRankUpCheckoutScreen = ({
    route,
    navigation,
}: CollectionPetRankUpCheckoutProps) => {
    const { executeTransaction } = useWallet();
    const { refetchPets } = React.useContext(PlayContext);

    const { nextRankBySlug } = useRanks();
    const { pet, payments, navigateBackOnUpgrade } = route.params;
    const [createPaymentRequest] = useMutation<CreateRankUpPaymentResponse>(
        CREATE_RANK_UP_PAYMENT
    );

    const nextRank = nextRankBySlug(pet.rank);

    const createPayment = async (pet: Pet, payment: PaymentOptionOutput) => {
        const result = await createPaymentRequest({
            variables: {
                petId: pet.id,
                token: payment.token,
            },
        });
        return result.data?.createRankUpPayment;
    };

    const handleOnPurchase = async (payment: PaymentOptionOutput) => {
        const paymentResponse = await createPayment(pet, payment);
        if (!paymentResponse) {
            return null;
        }
        const result = await executeTransaction(
            paymentResponse.payment,
            paymentResponse.signedTransaction
        );

        await refetchPets(true);

        navigation.navigate(ROUTES.COLLECTION_PET_RANK_UP_SUCCESS, {
            pet: pet,
            transactionResult: result,
            payment: paymentResponse.payment,
            navigateBackOnUpgrade: navigateBackOnUpgrade,
        });

        return result;
    };

    return (
        <>
            <NavigationBar backIcon wallet filled={true} />
            <CheckoutScreen
                title={i18n.t('ranks.checkoutUpgradeRank.title')}
                text={i18n.t('ranks.checkoutUpgradeRank.text')}
                payments={payments}
                TransactionDetailsComponent={
                    <TransactionDetailsRankUp pet={pet} />
                }
                withConfirmation={i18n.t(
                    'ranks.checkoutUpgradeRank.transaction',
                    {
                        rank: i18n.t(`ranks.list.${nextRank?.slug}.title`),
                        name: pet.name,
                    }
                )}
                onPurchase={handleOnPurchase}
            />
        </>
    );
};

export default CollectionPetRankUpCheckoutScreen;
