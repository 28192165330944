import { Platform, StyleSheet } from 'react-native';

import Colors from '../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../helpers/dimensions';
import font from '../../../helpers/font';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            marginTop: Platform.OS === 'android' ? verticalScale(20) : 0,
            flex: 1,
        },
        content: {
            paddingHorizontal: scale(20),
            flex: 1,
        },

        imgWrapper: {
            alignSelf: 'center',
            marginBottom: verticalScale(24),
        },
        timer: {
            alignItems: 'center',
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(20),
            borderRadius: moderateScale(12),
            marginTop: verticalScale(20),
        },
        timerText: {
            textAlign: 'center',
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),

            color: Colors[theme].text.secondary,
            marginBottom: verticalScale(8),
        },

        gap: {
            marginTop: verticalScale(12),
        },

        textStyle: {
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
            marginRight: scale(6),
            marginLeft: scale(2),
        },
        mintPriceContainer: {
            paddingHorizontal: scale(8),
            paddingVertical: verticalScale(6),
            marginBottom: verticalScale(12),

            alignSelf: 'center',
        },
        currentRank: {
            marginBottom: verticalScale(12),
        },
        buttonWrapper: {
            paddingVertical: verticalScale(16),
            paddingHorizontal: scale(20),
        },
        header: {
            paddingHorizontal: scale(20),
        },
        safeArea: {
            flex: 1,
            justifyContent: 'space-between',
        },
    });

export default styles;
