import * as React from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';

import _ from 'lodash';

import usePreventDoubleClick from '@hooks/usePreventDoubleClick';
import useThemedStyles from '@hooks/useThemedStyles';

import Icon, { ICON_NAMES } from '../Icons';
import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    title: string;
    onPress: () => void;
    disabled?: boolean;
    containerStyle?: StyleProp<ViewStyle>;
    iconName?: ICON_NAMES | null;
    icon?: React.ReactElement<SvgProps>;
    debounceTime?: number;
    isSelect?: boolean;
}

const ClickableSelectCard = ({
    title,
    onPress,
    disabled,
    containerStyle,
    iconName,
    icon,
    debounceTime = 2000,
    isSelect,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { onClick, disabled: isClicked } = usePreventDoubleClick(
        onPress,
        debounceTime
    );

    return (
        <TouchableOpacity
            activeOpacity={0.7}
            disabled={disabled || isClicked}
            style={[
                styles.shadow,
                disabled && styles.disabled,
                !disabled && isSelect && styles.selectS,
                containerStyle,
            ]}
            onPress={onClick}>
            <View
                style={[
                    styles.containerButton,
                    !disabled && isSelect && styles.selectC,
                ]}>
                <View style={styles.top}>
                    {iconName ? <Icon name={iconName} /> : icon}
                </View>
                <Text style={styles.title} numberOfLines={1}>
                    {title}
                </Text>
            </View>
        </TouchableOpacity>
    );
};

export default ClickableSelectCard;
