import * as React from 'react';

import ChainIcon from '@assets/icons/mysteryGames/QuantumSpin/chain.svg';
import DateIcon from '@assets/icons/mysteryGames/QuantumSpin/date.svg';
import HashIcon from '@assets/icons/mysteryGames/QuantumSpin/hash.svg';
import RoundIdIcon from '@assets/icons/mysteryGames/QuantumSpin/roundId.svg';
import StarsIcon from '@assets/icons/mysteryGames/QuantumSpin/stars.svg';
import CoinIcon from '@components/Icons/CoinIcon';
import QuantumSpinProvablyFair from '@components/QuantumSpinProvablyFair';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT, TYPO_TEXT_COLOR } from '@components/TypoText/types';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { statisticsFormatter } from '@helpers/helpers';
import { priceString } from '@helpers/payments';
import { dateFormatterFull } from '@helpers/time';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES, QuantumSpinGameOutput } from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import QuantumSpinInfoItem from '../QuantumSpinInfoItem';
import stylesMain from './styles';

interface IProps {
    data: QuantumSpinGameOutput;
    withProvably?: boolean;
}

const QuantumSpinPrizeInfo = ({ data, withProvably }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { iguAmountWei, id, hash } = data;
    const { quantumSpinGameConfig } = useMysteryGames();
    const navigation = useNavigation();
    const onInfoIconPress = () => {
        navigation.push(ROUTES.QUANTUM_SPIN_PROBABLY_FAIR);
    };

    return (
        <>
            <View style={styles.iconWrapper}>
                <View style={styles.stars}>
                    <StarsIcon />
                </View>
                <View style={styles.icon}>
                    <CoinIcon slug={Coin.vigu} size={110} />
                </View>
            </View>
            <TypoText
                textAlign="center"
                type={TYPO_TEXT.HEADING_H4}
                colorType={TYPO_TEXT_COLOR.ACCENT}>
                {priceString(
                    balanceFromWei(iguAmountWei).valueShort,
                    Coin.vigu
                )}
                <TypoText type={TYPO_TEXT.HEADING_H4}>
                    {` ${i18n.t('general.KEYS.won')}!`}
                </TypoText>
            </TypoText>
            <TypoText
                additionalStyles={styles.text}
                textAlign="center"
                type={TYPO_TEXT.BODY_MEDIUM}>
                {i18n.t(
                    `HomeScreen.${MYSTERY_GAMES.quantumSpin}.QuantumSuccess.coin`,
                    {
                        coin: priceString(
                            balanceFromWei(iguAmountWei).valueShort,
                            Coin.vigu
                        ),
                    }
                )}
            </TypoText>
            {withProvably && (
                <QuantumSpinProvablyFair
                    onInfoIconPress={onInfoIconPress}
                    customStyles={styles.feature}
                />
            )}
            <View style={[styles.info, withProvably && styles.withProvably]}>
                {!!quantumSpinGameConfig && (
                    <QuantumSpinInfoItem
                        icon={<ChainIcon width={24} height={24} />}
                        title={i18n.t('general.KEYS.chainResults')}
                        text={`${statisticsFormatter(
                            quantumSpinGameConfig.pregeneratedHashesCount
                        )} / ${i18n.t(
                            'profile.socialMediaSettings.media.socials.twitter'
                        )}`}
                        link={quantumSpinGameConfig.twitterBlockPostUrl}
                    />
                )}
                <QuantumSpinInfoItem
                    containerStyle={styles.gap}
                    icon={<RoundIdIcon width={24} height={24} />}
                    title={i18n.t('general.KEYS.roundId')}
                    text={`#${id}`}
                    copyText={id}
                />
                <QuantumSpinInfoItem
                    containerStyle={styles.gap}
                    icon={<HashIcon width={24} height={24} />}
                    title={i18n.t('general.KEYS.hash')}
                    text={hash}
                    copyText={hash}
                />

                <QuantumSpinInfoItem
                    containerStyle={styles.gap}
                    icon={<DateIcon width={24} height={24} />}
                    title={i18n.t('general.KEYS.date')}
                    text={dateFormatterFull(data.date)}
                />
            </View>
        </>
    );
};

export default QuantumSpinPrizeInfo;
