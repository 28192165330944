import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        content: {
            position: 'absolute',
            backgroundColor: 'transparent',
            height: '100%',
            left: 0,
            top: 0,
            width: '100%',

            paddingHorizontal: scale(20),
            paddingTop: verticalScale(20),
            paddingBottom: verticalScale(28),
            justifyContent: 'space-between',
        },
        container: {
            backgroundColor: 'transparent',
        },
        top: {
            backgroundColor: 'transparent',
        },
        infoIcon: {
            color: 'white',
        },
        title: {
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
            color: Colors[theme].text.onLight,
            marginLeft: scale(6),
        },
        amount: {
            marginTop: verticalScale(6),
            ...font({
                size: 28,
                height: 36,
                weight: '700',
            }),
            color: Colors[theme].text.onLight,
        },
        usd: {
            marginTop: verticalScale(6),
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
            color: Colors[theme].text.onLight,
        },
        info: {
            marginTop: verticalScale(6),
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
            color: Colors[theme].text.onLight,
            width: '67%',
        },
        titleWrapper: {
            flexDirection: 'row',
            backgroundColor: 'transparent',
            alignItems: 'center',
        },
        faq: {
            marginRight: scale(4),
        },
        itemStyle: {
            alignItems: 'center',
        },
    });

export default styles;
