import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import CoinIcon from '@components/Icons/CoinIcon';
import { Text, View } from '@components/Themed';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { dateFormatter } from '@helpers/time';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import { QuantumSpinGameOutput } from '@models/mysteryGames';

import GameAmount from '../GameAmount';
import stylesMain from './styles';

interface IProps {
    item: QuantumSpinGameOutput;
    onPress: (item: QuantumSpinGameOutput) => void;
    disabled?: boolean;
}

const QuantumSpinHistoryItem = ({ item, onPress, disabled }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <TouchableOpacity
            style={[styles.itemContainer]}
            disabled={disabled}
            activeOpacity={0.7}
            onPress={() => onPress(item)}>
            {disabled && <View style={styles.opacity} />}
            <View style={styles.left}>
                <View style={styles.imageWrapper}>
                    <CoinIcon size={24} slug={Coin.vigu} />
                </View>
                <View style={styles.infoBlock}>
                    <Text style={styles.address} numberOfLines={1}>
                        {Coin.vigu}
                    </Text>

                    <Text style={styles.date} numberOfLines={1}>
                        {dateFormatter(item.date)}
                    </Text>
                </View>
            </View>
            <View style={styles.right}>
                <GameAmount
                    amount={balanceFromWei(item.iguAmountWei).valueLong}
                />
            </View>
        </TouchableOpacity>
    );
};
export default QuantumSpinHistoryItem;
