import * as React from 'react';

import { useAppState } from '@contexts/AppStateContext';
import { useApollo } from '@contexts/IguApolloContext';
import { useUser } from '@contexts/UserContext';
import useDeferDeepLinking from '@navigation/hooks/useDeferDeepLinking/useDeferDeepLinking';
import { createNavigationContainerRef } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';

import BonusMintRanksCarouselScreen from '../../screens/BonusMintRanks/BonusMintRanksCarousel/BonusMintRanksCarouselScreen';
import BonusMintRanksDetailedInfoScreen from '../../screens/BonusMintRanks/BonusMintRanksDetailedInfo/BonusMintRanksDetailedInfoScreen';
import DevScreen from '../../screens/Dev/DevScreen';
import EnergyInfoScreen from '../../screens/Energy/EnergyInfo/EnergyInfoScreen';
import AchievementDetailsScreen from '../../screens/Global/Achievements/AchievementDetails/AchievementDetailsScreen';
import AchievementsMainScreen from '../../screens/Global/Achievements/AchievementsMain/AchievementsMainScreen';
import UpdateAppScreen from '../../screens/Global/UpdateApp/UpdateAppScreen';
import YourPetsAreHungryScreen from '../../screens/Global/YourPetsAreHungry/YourPetsAreHungryScreen';
import { HowToCollectionsScreen } from '../../screens/HowTo/HowToCollections/HowToCollectionsScreen';
import { HowToCommissionFeeScreen } from '../../screens/HowTo/HowToCommissionFee/HowToCommissionFeeScreen';
import { HowToFeeScreen } from '../../screens/HowTo/HowToFee/HowToFeeScreen';
import HowToGamingAvailableWalletScreen from '../../screens/HowTo/HowToGamingAvailableWallet/HowToGamingAvailableWalletScreen';
import HowToPlayScreen from '../../screens/HowTo/HowToPlay/HowToPlayScreen';
import HowToReferralsScreen from '../../screens/HowTo/HowToReferrals/HowToReferralsScreen';
import InitialMintScreen from '../../screens/Initial/InitialMint/InitialMintScreen';
import InitialReferralScreen from '../../screens/Initial/InitialReferral/InitialReferralScreen';
import LoginScreen from '../../screens/Login/LoginScreen';
import LoginOnBoardingScreen from '../../screens/OnBoarding/LoginOnBoarding/LoginOnBoardingScreen';
import RanksDetailedInfoScreen from '../../screens/Ranks/RanksDetailedInfo';
import MysteryBoxPurchaseCheckoutScreen from '../../screens/TabBar/MysteryBoxes/MysteryBoxPurchaseCheckout/MysteryBoxPurchaseCheckoutScreen';
import MysteryBoxPurchaseSuccessScreen from '../../screens/TabBar/MysteryBoxes/MysteryBoxPurchaseSuccess/MysteryBoxPurchaseSuccessScreen';
import MysteryBoxesPurchaseScreen from '../../screens/TabBar/MysteryBoxes/MysteryBoxesPurchase/MysteryBoxesPurchaseScreen';
import MysteryBoxesPurchaseWelcomeScreen from '../../screens/TabBar/MysteryBoxes/MysteryBoxesPurchaseWelcome/MysteryBoxesPurchaseWelcomeScreen';
import ReferralsInviteUserScreen from '../../screens/TabBar/Referrals/ReferralsInviteUser/ReferralsInviteUserScreen';
import { RootStackParamList, State } from '../../types';
import ROUTES from '../routes';
import AdminStack from './AdminStack';
import BottomTabBar from './BottomTabBar/BottomTabBar';
import CharityGroup from './CharityGroup';
import CollectionGroup from './CollectionGroup';
import CreateWalletGroup from './CreateWalletGroup';
import LuckyStrikeGroup from './LuckyStrikeGroup';
import MarketplaceGroup from './MarketplaceGroup';
import MintStack from './MintStack';
import MoveToEarnGroup from './MoveToEarnGroup';
import MysteryLikesGroup from './MysteryLikesGroup';
import NftEvolutionGroup from './NftEvolutionGroup';
import NftRoyalGroup from './NftRoyalGroup';
import PassCodeGroup from './PassCodeGroup';
import PlayGroup from './PlayGroup';
import PlayToEarnStack from './PlayToEarnStack';
import ProfileStack from './ProfileStack';
import QuantumSpinGroup from './QuantumSpinGroup';
import SocializeToEarnGroup from './SocializeToEarnGroup';
import TokenTellerGroup from './TokenTellerGroup';
import WalletGroup from './WalletGroup';

export const navigationRef = createNavigationContainerRef();
const RootStack = createNativeStackNavigator<RootStackParamList>();

const RootNavigator = () => {
    const { hasRole } = useUser();
    const { state } = useAppState();
    const { isGameMaintenance } = useApollo();
    useDeferDeepLinking();

    const timeStateKey = React.useMemo(
        () => `${state}-${Date.now().toString()}`,
        [state]
    );

    const renderLoggedIn = React.useCallback(() => {
        return (
            <RootStack.Group key={`renderLoggedIn${timeStateKey}`}>
                <RootStack.Screen name={ROUTES.MAIN} component={BottomTabBar} />

                {WalletGroup(RootStack)}

                <RootStack.Screen
                    name={ROUTES.PROFILE_ROOT}
                    component={ProfileStack}
                />

                <RootStack.Screen
                    name={ROUTES.MINT_ROOT}
                    component={MintStack}
                    options={{ gestureEnabled: false }}
                />

                {hasRole && (
                    <RootStack.Screen
                        name={ROUTES.ADMIN_ROOT}
                        component={AdminStack}
                    />
                )}

                <RootStack.Group
                    screenOptions={{
                        headerShown: false,
                    }}>
                    {CharityGroup(RootStack)}
                </RootStack.Group>

                <RootStack.Group
                    screenOptions={{
                        headerShown: false,
                    }}>
                    {CollectionGroup(RootStack)}
                </RootStack.Group>

                <RootStack.Group
                    screenOptions={{
                        headerShown: false,
                    }}>
                    {PassCodeGroup(RootStack)}
                </RootStack.Group>

                {!isGameMaintenance && (
                    <>
                        <RootStack.Group
                            screenOptions={{
                                headerShown: false,
                            }}>
                            {PlayGroup(RootStack)}
                        </RootStack.Group>

                        <RootStack.Group
                            screenOptions={{
                                headerShown: false,
                            }}>
                            {SocializeToEarnGroup(RootStack)}
                        </RootStack.Group>

                        <RootStack.Group
                            screenOptions={{
                                headerShown: false,
                            }}>
                            {MoveToEarnGroup(RootStack)}
                        </RootStack.Group>

                        <RootStack.Screen
                            name={ROUTES.PLAY_TO_EARN}
                            component={PlayToEarnStack}
                            options={{ gestureEnabled: false }}
                        />
                    </>
                )}
                <RootStack.Screen
                    name={ROUTES.HOW_TO_PLAY}
                    component={HowToPlayScreen}
                    options={{
                        presentation: 'modal',
                        headerShown: false,
                    }}
                />
                <RootStack.Screen
                    name={ROUTES.DEV}
                    options={{ gestureEnabled: false }}
                    component={DevScreen}
                />
                <RootStack.Screen
                    name={ROUTES.RANKS_DETAILED_INFO}
                    component={RanksDetailedInfoScreen}
                    options={{ presentation: 'modal' }}
                />

                <RootStack.Screen
                    name={ROUTES.INITIAL_REFERRAL}
                    component={InitialReferralScreen}
                    options={{
                        gestureEnabled: false,
                    }}
                />
                <RootStack.Screen
                    name={ROUTES.REFERRALS_INVITE}
                    component={ReferralsInviteUserScreen}
                />
                <RootStack.Screen
                    name={ROUTES.HOW_TO_REFERRALS}
                    component={HowToReferralsScreen}
                    options={{ presentation: 'modal' }}
                />

                <RootStack.Screen
                    name={ROUTES.HOW_TO_FEE}
                    component={HowToFeeScreen}
                    options={{ presentation: 'modal' }}
                />
                <RootStack.Screen
                    name={ROUTES.HOW_COMMISSION_FEE}
                    component={HowToCommissionFeeScreen}
                    options={{ presentation: 'modal' }}
                />
                <RootStack.Screen
                    name={ROUTES.HOW_TO_COLLECTIONS}
                    component={HowToCollectionsScreen}
                    options={{ presentation: 'modal' }}
                />

                <RootStack.Screen
                    name={ROUTES.ENERGY_INFO_SCREEN}
                    component={EnergyInfoScreen}
                    options={{ presentation: 'modal' }}
                />

                <RootStack.Screen
                    name={ROUTES.MYSTERY_BOX_PURCHASE_WELCOME}
                    component={MysteryBoxesPurchaseWelcomeScreen}
                    options={{ presentation: 'modal' }}
                />
                <RootStack.Screen
                    name={ROUTES.MYSTERY_BOX_PURCHASE}
                    component={MysteryBoxesPurchaseScreen}
                    options={{ presentation: 'modal' }}
                />
                <RootStack.Screen
                    name={ROUTES.MYSTERY_BOX_PURCHASE_CHECKOUT}
                    component={MysteryBoxPurchaseCheckoutScreen}
                />
                <RootStack.Screen
                    name={ROUTES.MYSTERY_BOX_PURCHASE_SUCCESS}
                    component={MysteryBoxPurchaseSuccessScreen}
                />

                <RootStack.Screen
                    name={ROUTES.BONUS_MINT_RANKS_INFO}
                    component={BonusMintRanksDetailedInfoScreen}
                    options={{ presentation: 'modal' }}
                />
                <RootStack.Screen
                    name={ROUTES.BONUS_MINT_RANKS_CAROUSEL}
                    component={BonusMintRanksCarouselScreen}
                    options={{ presentation: 'modal' }}
                />
                <RootStack.Screen
                    name={ROUTES.YOUR_PETS_ARE_HUNGRY}
                    component={YourPetsAreHungryScreen}
                    options={{ presentation: 'modal' }}
                />
                <RootStack.Screen
                    name={ROUTES.UPDATE_APP}
                    component={UpdateAppScreen}
                    options={{ presentation: 'modal' }}
                />
                <RootStack.Screen
                    name={ROUTES.ACHIEVEMENTS}
                    component={AchievementsMainScreen}
                />
                <RootStack.Screen
                    name={ROUTES.ACHIEVEMENTS_DETAILS}
                    component={AchievementDetailsScreen}
                />
                <RootStack.Screen
                    name={ROUTES.HOW_TO_GAMING_AVAILABLE_WALLET}
                    component={HowToGamingAvailableWalletScreen}
                    options={{
                        presentation: 'modal',
                    }}
                />
                <RootStack.Group
                    screenOptions={{
                        headerShown: false,
                    }}>
                    {MysteryLikesGroup(RootStack)}
                </RootStack.Group>
                <RootStack.Group
                    screenOptions={{
                        headerShown: false,
                    }}>
                    {NftEvolutionGroup(RootStack)}
                </RootStack.Group>

                <RootStack.Group
                    screenOptions={{
                        headerShown: false,
                    }}>
                    {NftRoyalGroup(RootStack)}
                </RootStack.Group>
                <RootStack.Group
                    screenOptions={{
                        headerShown: false,
                    }}>
                    {LuckyStrikeGroup(RootStack)}
                </RootStack.Group>
                <RootStack.Group
                    screenOptions={{
                        headerShown: false,
                    }}>
                    {QuantumSpinGroup(RootStack)}
                </RootStack.Group>
                <RootStack.Group
                    screenOptions={{
                        headerShown: false,
                    }}>
                    {TokenTellerGroup(RootStack)}
                </RootStack.Group>

                <RootStack.Group
                    screenOptions={{
                        headerShown: false,
                    }}>
                    {MarketplaceGroup(RootStack)}
                </RootStack.Group>
            </RootStack.Group>
        );
    }, [timeStateKey, hasRole, isGameMaintenance]);

    const renderNotLoggedIn = () => {
        return (
            <RootStack.Group
                screenOptions={{
                    headerShown: false,
                }}>
                <RootStack.Screen name={ROUTES.LOGIN} component={LoginScreen} />
            </RootStack.Group>
        );
    };
    const renderNotLoggedInOnBoarding = () => {
        return (
            <RootStack.Group
                screenOptions={{
                    headerShown: false,
                }}>
                <RootStack.Screen
                    name={ROUTES.LOGIN_ONBOARDING_SCREEN}
                    component={LoginOnBoardingScreen}
                />
                <RootStack.Screen name={ROUTES.LOGIN} component={LoginScreen} />
            </RootStack.Group>
        );
    };

    const renderLoggedInNoWallet = () => {
        return (
            <RootStack.Group
                screenOptions={{
                    headerShown: false,
                }}>
                {CreateWalletGroup(RootStack)}
            </RootStack.Group>
        );
    };

    const renderLoggedInNoPet = React.useCallback(() => {
        return (
            <RootStack.Group key={`renderLoggedInNoPet${timeStateKey}`}>
                <RootStack.Screen
                    name={ROUTES.INITIAL_MINT}
                    component={InitialMintScreen}
                    options={{
                        gestureEnabled: false,
                    }}
                />
                <RootStack.Screen
                    name={ROUTES.INITIAL_REFERRAL}
                    component={InitialReferralScreen}
                    options={{
                        gestureEnabled: false,
                    }}
                />
                <RootStack.Screen
                    name={ROUTES.MINT_ROOT}
                    component={MintStack}
                    options={{ gestureEnabled: false }}
                />
                <RootStack.Screen
                    name={ROUTES.ENERGY_INFO_SCREEN}
                    component={EnergyInfoScreen}
                    options={{ presentation: 'modal' }}
                />
                <RootStack.Screen
                    name={ROUTES.RANKS_DETAILED_INFO}
                    component={RanksDetailedInfoScreen}
                    options={{ presentation: 'modal' }}
                />
                <RootStack.Screen
                    name={ROUTES.BONUS_MINT_RANKS_INFO}
                    component={BonusMintRanksDetailedInfoScreen}
                    options={{ presentation: 'modal' }}
                />
                <RootStack.Screen
                    name={ROUTES.BONUS_MINT_RANKS_CAROUSEL}
                    component={BonusMintRanksCarouselScreen}
                    options={{ presentation: 'modal' }}
                />
                {WalletGroup(RootStack)}
                {PassCodeGroup(RootStack)}
                <RootStack.Screen name={ROUTES.MAIN} component={BottomTabBar} />
            </RootStack.Group>
        );
    }, [timeStateKey]);

    if (state === State.loading) {
        return null;
    }

    const renderScreens = () => {
        switch (state) {
            case State.notLoggedIn:
                return renderNotLoggedIn();
            case State.loggedInNoWallet:
                return renderLoggedInNoWallet();
            case State.loggedInNoPet:
                return renderLoggedInNoPet();
            case State.loggedInWallet:
                return renderLoggedIn();
            case State.notLoggedInOnboarding:
                return renderNotLoggedInOnBoarding();
            default:
                Console.error(
                    'Nothing to render, if you see this error, it is a bug in navigator'
                );
                return null;
        }
    };

    return (
        <RootStack.Navigator
            screenOptions={{
                headerShown: false,
            }}>
            {renderScreens()}
        </RootStack.Navigator>
    );
};

export default RootNavigator;
