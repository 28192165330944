import * as React from 'react';
import { useState } from 'react';
import { ScrollView } from 'react-native';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useMutation } from '@apollo/client';
import { SessionContext } from '@contexts/SessionContext';
import { isGraphqlError, isServerError } from '@helpers/helpers';
import {
    toastError,
    toastErrorUnknown,
    toastSuccess,
} from '@helpers/toastNotification';

import { LoginWithOTPResponse, SendOTPResponse } from '../../../../Data/Models';
import { LOGIN_WITH_OTP, SEND_OTP } from '../../../../Data/Requests';
import MaintenanceModal from '../../../../components/AppState/FullMaintenanceState/components/MaintenanceModal';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import ModalBottom from '../../../../components/ModalBottom';
import ModalLoader from '../../../../components/ModalLoader';
import { Text, View } from '../../../../components/Themed';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import ErrorReporting from '../../../../utils/ErrorReporting';
import Agreement from '../Agreement';
import style from './styles';

interface ILoginWithEmail {
    showEmail: boolean;
    toggleEmail: (state: boolean) => void;
}

const LoginWithEmail: React.FunctionComponent<ILoginWithEmail> = ({
    showEmail = false,
    toggleEmail,
}) => {
    const styles = useThemedStyles(style);
    const [isLoginMaintenance, setIsLoginMaintenance] =
        useState<boolean>(false);

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isLogged, setIsLogged] = React.useState<boolean>(false);
    const [loadingMessage, setLoadingMessage] = React.useState<string>('');
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const [counter, setCounter] = React.useState<number>(0);
    const [sendOTPrequest, { data: otpResponse }] =
        useMutation<SendOTPResponse>(SEND_OTP);
    const [loginRequest] = useMutation<LoginWithOTPResponse>(LOGIN_WITH_OTP);
    const { setSessionToken } = React.useContext(SessionContext);
    const verificationCodeId = otpResponse?.sendOTP?.id;

    React.useEffect(() => {
        let timer: ReturnType<typeof setTimeout>;
        if (counter >= 0) {
            timer = setTimeout(() => setCounter(counter - 1), 1000);
        }

        return () => clearTimeout(timer);
    }, [counter]);

    const login = async (values: any) => {
        setErrorMessage(null);
        setLoadingMessage(i18n.t('login.loading.loggingIn'));
        setIsLoading(true);
        const result = await loginRequest({
            variables: {
                loginWithOtpInput: {
                    id: verificationCodeId,
                    token: values.emailVerificationCode,
                },
            },
        }).catch((error: any) => {
            Console.error(error.graphQLErrors);
            if (
                isGraphqlError(error, 'OTP_LOGIN_FAILED') ||
                isGraphqlError(error, 'BAD_USER_INPUT') ||
                isGraphqlError(error, 'INVALID_LOGIN_CREDENTIALS')
            ) {
                toastError(
                    undefined,
                    i18n.t('login.errors.verificationCodeIsBadOrExpired'),
                    'top'
                );
                setErrorMessage(
                    i18n.t('login.errors.verificationCodeIsBadOrExpired')
                );
            } else if (isGraphqlError(error, 'FULL_MAINTENANCE')) {
                setIsLoginMaintenance(true);
            } else {
                ErrorReporting.report(error);
                toastErrorUnknown('top');
            }
        });

        setIsLoading(false);

        // login user
        if (result?.data) {
            setIsLogged(true);
            result?.data &&
                setSessionToken(result.data.loginWithOTP.access_token);
        }
    };

    const formikLoginData = useFormik({
        initialValues: {
            email: '',
            emailVerificationCode: '',
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email(i18n.t('login.errors.invalidEmailAddress'))
                .required(i18n.t('login.errors.emailIsRequired')),
            emailVerificationCode: Yup.string().required(
                i18n.t('login.errors.verificationCodeIsRequired')
            ),
        }),
        onSubmit: (values) => login(values),
        enableReinitialize: true,
    });

    const {
        submitForm,
        values,
        errors,
        handleChange,
        touched,
        isValid,
        dirty,
        setTouched,
    } = formikLoginData;

    const handleSendVerificationCode = async (email: string) => {
        setLoadingMessage(i18n.t('login.loading.sendingCode'));
        setIsLoading(true);

        try {
            Console.log('send verification code');
            const result = await sendOTPrequest({
                variables: { email },
            });

            setIsLoading(false);

            if (result.data) {
                setCounter(result.data?.sendOTP?.resendInSeconds);
                toastSuccess(undefined, i18n.t('login.codeSent'), 'top');
            }
        } catch (error: any) {
            Console.log(error);
            setIsLoading(false);
            if (isGraphqlError(error, 'OTP_ALREADY_SENT')) {
                toastError(
                    undefined,
                    i18n.t('login.errors.verificationCodeTooMuchAttempts'),
                    'top'
                );
            } else if (isServerError(error)) {
                toastErrorUnknown('top');
            }
            setErrorMessage(
                i18n.t('login.errors.verificationCodeTooMuchAttempts')
            );
        }
    };

    const handleClose = () => {
        toggleEmail(false);
        setErrorMessage('');
    };

    return (
        <ModalBottom
            isVisible={showEmail && !isLogged}
            titleText={i18n.t('login.emailTitle')}
            onClose={handleClose}
            avoidKeyboard
            modalHeight={500}>
            <ScrollView
                style={styles.contentContainer}
                keyboardShouldPersistTaps="handled"
                showsVerticalScrollIndicator={false}>
                <View style={styles.formWrapper}>
                    <Input
                        value={values.email}
                        error={touched.email ? errors.email : undefined}
                        onChangeText={handleChange('email')}
                        onBlur={() => setTouched({ email: true })}
                        placeholder={i18n.t('login.placeholderEmail')}
                        label={i18n.t('login.labelEmail')}
                        keyboardType="email-address"
                        textContentType="emailAddress"
                    />
                    <Input
                        value={values.emailVerificationCode}
                        error={
                            errorMessage
                                ? errorMessage
                                : touched.emailVerificationCode
                                ? errors.emailVerificationCode
                                : undefined
                        }
                        onChangeText={handleChange('emailVerificationCode')}
                        placeholder={i18n.t(
                            'login.placeholderVerificationCode'
                        )}
                        stylesContainer={styles.inputGap}
                        style={styles.inputCode}
                        label={i18n.t('login.labelVerificationCode')}
                        keyboardType={'numeric'}
                        maxLength={12}
                        // disabled={!verificationCodeId}
                        component={
                            counter <= 0 ? (
                                <Button
                                    onPress={() => {
                                        handleSendVerificationCode(
                                            values.email
                                        );
                                    }}
                                    title={i18n.t('login.sendCode')}
                                    size={'sm'}
                                    containerStyle={styles.sendCodeButton}
                                    disabled={
                                        !values.email ||
                                        errors.hasOwnProperty('email')
                                    }
                                />
                            ) : (
                                <Text style={styles.sendCodeText}>
                                    {i18n.t('login.resendIn')}{' '}
                                    <Text style={styles.sendCodeTextBold}>
                                        {counter} {i18n.t('login.seconds')}
                                    </Text>
                                </Text>
                            )
                        }
                    />
                    <Button
                        onPress={submitForm}
                        title={i18n.t('login.email')}
                        containerStyle={styles.buttonGap}
                        disabled={!(isValid && dirty && verificationCodeId)}
                    />
                </View>
                <Agreement />
            </ScrollView>
            <ModalLoader isVisible={isLoading} text={loadingMessage} />
            <MaintenanceModal
                isVisible={isLoginMaintenance}
                setIsVisible={setIsLoginMaintenance}
                canClose
            />
        </ModalBottom>
    );
};

export default LoginWithEmail;
