import * as React from 'react';

import CustomModal from '../components/CustomModal';
import ImageIguana, { ImageIguanaType } from '../components/ImageIguana';
import { waitForToast } from '../helpers/helpers';
import i18n from '../i18n/i18n';

export const useConfirmationModal = (
    title: string,
    text: string,
    iconType: ImageIguanaType = 'success'
) => {
    const [isVisibleModalConfirm, setIsVisibleModalConfirm] =
        React.useState<boolean>(false);

    const renderItem = (onConfirm: () => void, onCancel?: () => void) => {
        return (
            <>
                <CustomModal
                    isVisible={isVisibleModalConfirm}
                    icon={<ImageIguana type={iconType} />}
                    titleText={title ?? i18n.t(`modal.confirmation.title`)}
                    infoText={text ?? i18n.t(`modal.confirmation.text`)}
                    firstButtonText={i18n.t('modal.confirmation.yes')}
                    secondButtonText={i18n.t('modal.confirmation.cancel')}
                    onFirstButtonClick={() => {
                        setIsVisibleModalConfirm(false);
                        waitForToast().then(onConfirm);
                        onConfirm();
                    }}
                    onSecondButtonClick={() => {
                        setIsVisibleModalConfirm(false);
                        onCancel?.();
                    }}
                />
            </>
        );
    };

    return { isVisibleModalConfirm, setIsVisibleModalConfirm, renderItem };
};
