import React from 'react';

import { useQuery } from '@apollo/client';
import { useApollo } from '@contexts/IguApolloContext';

import { ME } from '../../../Data/Requests';
import MaintenanceModal from './components/MaintenanceModal';

const FullMaintenanceState = () => {
    const { isFullMaintenance } = useApollo();

    useQuery(ME, {
        pollInterval: 10000,
        fetchPolicy: 'network-only',
    });

    return <MaintenanceModal isVisible={isFullMaintenance} />;
};

export default FullMaintenanceState;
