import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        shadow: {
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].shadow.default,
        },
        container: {
            backgroundColor: Colors[theme].background,
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(16),
            marginBottom: verticalScale(4),
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        shadowSelected: {
            backgroundColor: Colors[theme].shadow.selected,
        },
        containerSelected: {
            borderColor: Colors[theme].border.accent,
        },
        textWrapper: {
            marginTop: verticalScale(8),
            backgroundColor: 'transparent',
        },
        textGap: {
            marginBottom: verticalScale(2),
            textAlign: 'center',
        },
        margin: {
            marginRight: scale(12),
        },
        top: {
            alignItems: 'center',
            backgroundColor: 'transparent',
        },
        flex: {
            flex: 1,
        },
    });

export default styles;
