import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { statisticsFormatter } from '@helpers/helpers';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';

import stylesMain from './styles';

interface IProps {
    containerStyles?: StyleProp<ViewStyle>;
}

const game = MYSTERY_GAMES.quantumSpin;

const QuantumSpinProbablyFairAlgorithm = ({ containerStyles }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { quantumSpinGameConfig } = useMysteryGames();

    return (
        <BlockHeader
            title={i18n.t(
                `HomeScreen.${game}.QuantumSpinProvablyFair.algorithm.title`
            )}
            type={'primary'}
            dropDownText={i18n.t(
                `HomeScreen.${game}.QuantumSpinProvablyFair.algorithm.text`,
                {
                    rounds: statisticsFormatter(
                        quantumSpinGameConfig?.pregeneratedHashesCount ||
                            10000000
                    ),
                }
            )}
            containerStyle={[styles.container, containerStyles]}
        />
    );
};

export default QuantumSpinProbablyFairAlgorithm;
