import React from 'react';
import { Text, View } from 'react-native';

import { Pet } from '../../../../../Data/Models';
import Divider from '../../../../../components/Divider';
import Icon, { ICON_NAMES } from '../../../../../components/Icons';
import PetCollectionListItem from '../../../../../components/PetCollectionListItem';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import Styles from './styles';

interface IProps {
    pet: Pet;
    healthUp: number;
}

const TransactionDetailsHealUp = ({ pet, healthUp }: IProps) => {
    const styles = useThemedStyles(Styles);

    return (
        <>
            <>
                <View style={styles.container}>
                    <View style={styles.icon}>
                        <Icon name={ICON_NAMES.HEALTH} size={64} />
                    </View>
                    <View style={styles.right}>
                        <Text style={styles.title}>
                            {i18n.t(
                                'detailedPet.health.checkoutHealUp.restoreTo'
                            )}
                        </Text>

                        <Text style={styles.text}>
                            {i18n.t('detailedPet.health.checkoutHealUp.max', {
                                amount: healthUp,
                            })}
                        </Text>
                    </View>
                </View>
                <Divider customStyles={styles.divider} />
                <PetCollectionListItem
                    style={styles.petContainer}
                    health={pet.health + healthUp}
                    pet={pet}
                />
            </>
        </>
    );
};
export default TransactionDetailsHealUp;
