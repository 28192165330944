import React, { useMemo } from 'react';
import { ScrollView, View } from 'react-native';

import { GET_MARKETPLACE_NFT_DETAILS } from '@Data/Requests';
import { useQuery } from '@apollo/client';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import CacheImage from '@components/CacheImage';
import LoadingComponent from '@components/LoadingComponent';
import { useDimensions } from '@contexts/DimensionsContext';
import { useUser } from '@contexts/UserContext';
import { errorsHandler } from '@helpers/errors';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MarketplaceNftDetailsOutputResponse } from '@models/marketplace';
import NavigationMarketPlaceOverview, {
    NAVIGATION_MARKETPLACE_OVERVIEW,
} from '@navigation/NavigationBar/NavigationMarketPlaceOverview';
import { useNavigation } from '@navigation/useNavigation';

import { MarketPlaceOverviewProps } from '../../../../types';
import OverviewButtons from '../components/OverviewButtons';
import OverviewNavigation from '../components/OverviewNavigation';
import OverviewOwnerSection from '../components/OverviewOwnerSection';
import OverviewPetSection from '../components/OverviewPetSection';
import Styles from './styles';

const MarketPlaceOverviewScreen = ({ route }: MarketPlaceOverviewProps) => {
    const styles = useThemedStyles(Styles);

    const { petId, cancelTransactionValuesStringified } = route.params;
    const navigation = useNavigation();
    const { windowWidth } = useDimensions();
    const { user } = useUser();

    const { data, loading } = useQuery<MarketplaceNftDetailsOutputResponse>(
        GET_MARKETPLACE_NFT_DETAILS,
        {
            variables: { petId },
            fetchPolicy: 'no-cache',
            onError: (error) => {
                errorsHandler(error, true), navigation.pop();
            },
        }
    );

    const isOwner = useMemo(
        () =>
            data?.marketplaceNftDetails.owner.profile.walletAddress ===
            user?.walletAddress,
        [
            data?.marketplaceNftDetails.owner.profile.walletAddress,
            user?.walletAddress,
        ]
    );

    return (
        <>
            {loading && !data ? (
                <LoadingComponent
                    text={i18n.t('general.loading')}
                    containerStyles={styles.loading}
                />
            ) : (
                <>
                    <OverviewNavigation />
                    <ScrollView
                        style={styles.container}
                        bounces={false}
                        showsVerticalScrollIndicator={false}>
                        <View>
                            <CacheImage
                                resizeMode="cover"
                                style={{ height: windowWidth }}
                                source={
                                    data?.marketplaceNftDetails.pet.imageUrl
                                }
                            />
                        </View>

                        <View style={styles.content}>
                            <NavigationMarketPlaceOverview
                                currentNavigation={
                                    NAVIGATION_MARKETPLACE_OVERVIEW.OVERVIEW
                                }
                                pet={data?.marketplaceNftDetails.pet}
                                order={data?.marketplaceNftDetails.order}
                                isOwner={isOwner}
                                cancelTransactionValuesStringified={
                                    cancelTransactionValuesStringified
                                }
                                owner={data?.marketplaceNftDetails.owner}
                            />
                            <BlockHeader
                                containerStyle={[
                                    styles.header,
                                    isOwner && styles.additionalMargin,
                                ]}
                                type="primary"
                                title={data?.marketplaceNftDetails.pet.name}
                                text={
                                    isOwner
                                        ? i18n.t(
                                              'MarketPlace.MarketPlaceOverview.textOwner'
                                          )
                                        : i18n.t(
                                              'MarketPlace.MarketPlaceOverview.text'
                                          )
                                }
                            />

                            {!!data?.marketplaceNftDetails.owner &&
                                !isOwner && (
                                    <OverviewOwnerSection
                                        owner={data.marketplaceNftDetails.owner}
                                    />
                                )}
                            {!!data?.marketplaceNftDetails.pet && (
                                <OverviewPetSection
                                    pet={data.marketplaceNftDetails.pet}
                                />
                            )}
                        </View>
                    </ScrollView>
                    {!!data?.marketplaceNftDetails.order &&
                        !!data.marketplaceNftDetails.pet &&
                        !!data.marketplaceNftDetails.owner && (
                            <OverviewButtons
                                order={data.marketplaceNftDetails.order}
                                pet={data.marketplaceNftDetails.pet}
                                isOwner={isOwner}
                                cancelTransactionValuesStringified={
                                    cancelTransactionValuesStringified
                                }
                                owner={data.marketplaceNftDetails.owner}
                            />
                        )}
                </>
            )}
        </>
    );
};
export default MarketPlaceOverviewScreen;
