import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import InfoIcon from '@components/InfoIcon/InfoIcon';
import { useUser } from '@contexts/UserContext';

import { truncateEthAddress } from '../../helpers/wallet';
import useThemedStyles from '../../hooks/useThemedStyles';
import CopyToClipboardButton from '../CopyToClipboardButton';
import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    address: string;
    containerStyle?: StyleProp<ViewStyle>;
    textStyle?: StyleProp<ViewStyle>;
    shouldTruncate?: boolean;
    shouldUseUsername?: boolean;
    onInfoIcon?: () => void;
    children?: React.ReactNode;
    userName?: string | null;
}

const WalletAddress = ({
    address,
    containerStyle,
    textStyle,
    shouldTruncate = true,
    shouldUseUsername,
    onInfoIcon,
    children,
    userName,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { user } = useUser();

    const formattedAddress = React.useMemo(
        () =>
            shouldUseUsername
                ? truncateEthAddress(
                      userName ? userName || address : user?.username || address
                  )
                : truncateEthAddress(address),
        [user?.username, address]
    );

    const shouldShowInfoIcon = React.useMemo(
        () =>
            shouldUseUsername
                ? address.toLowerCase() !== user?.username?.toLocaleLowerCase()
                : false,
        [address, user?.username]
    );

    return (
        <View style={[styles.container, containerStyle]}>
            {shouldTruncate ? (
                <Text
                    style={[styles.text, textStyle]}
                    numberOfLines={1}
                    ellipsizeMode="middle">
                    {formattedAddress}
                </Text>
            ) : (
                <Text
                    style={[styles.text, textStyle]}
                    numberOfLines={1}
                    ellipsizeMode={'tail'}>
                    {address}
                </Text>
            )}
            {!!onInfoIcon && shouldShowInfoIcon && (
                <InfoIcon containerStyle={styles.icon} onPress={onInfoIcon} />
            )}
            <CopyToClipboardButton value={address} />
            {children}
        </View>
    );
};
export default WalletAddress;
