import React from 'react';
import { StyleProp, ViewProps } from 'react-native';
import Animated, {
    Extrapolate,
    interpolate,
    useAnimatedStyle,
    useSharedValue,
} from 'react-native-reanimated';

interface AnimatedProps {
    animationValue: Animated.SharedValue<number>;
    children: React.ReactNode;
    inactiveSlideOpacity: number;
    inactiveSlideScale: number;
    centredVertical: boolean;
    insideScrollView?: boolean;
    itemStyle?: StyleProp<ViewProps>;
}

const AnimatedItem = ({
    animationValue,
    children,
    inactiveSlideOpacity,
    inactiveSlideScale,
    centredVertical,
    itemStyle,
}: AnimatedProps) => {
    const translateY = useSharedValue(0);

    const containerStyle = useAnimatedStyle(() => {
        const opacity = interpolate(
            animationValue.value,
            [-1, 0, 1],
            [inactiveSlideOpacity, 1, inactiveSlideOpacity],
            Extrapolate.CLAMP
        );

        const scale = interpolate(
            animationValue.value,
            [-1, 0, 1],
            [inactiveSlideScale, 1, inactiveSlideScale],
            Extrapolate.CLAMP
        );

        return {
            opacity,
            transform: [{ scale }, { translateY: translateY.value }],
        };
    }, [animationValue]);

    return (
        <Animated.View
            style={[
                containerStyle,
                centredVertical && {
                    flex: 1,
                    justifyContent: 'center',
                },
                itemStyle,
            ]}>
            {children}
        </Animated.View>
    );
};

export default React.memo(AnimatedItem);
