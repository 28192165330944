import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import { scale, verticalScale } from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { stylesLoadMore } from '../../../../../helpers/style';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        list: {},
        buttonWrapper: {
            alignItems: 'center',
            marginBottom: verticalScale(24),
        },

        titleWrapper: {
            paddingHorizontal: scale(20),
        },
        divider: {
            height: 1,
            backgroundColor: Colors[theme].divider,
        },

        title: {
            marginTop: verticalScale(24),
            color: Colors[theme].text.primary,
            ...font({
                size: 18,
                height: 26,
                weight: '700',
            }),
            marginBottom: verticalScale(4),
        },
        noTransactionWrapper: {
            flex: 1,
            alignItems: 'center',
            paddingTop: verticalScale(32),
        },
        button: {
            alignSelf: 'auto',
        },
        loadingWrapper: {
            ...stylesLoadMore(),
        },
    });

export default styles;
