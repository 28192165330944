import * as React from 'react';
import { ScrollView } from 'react-native';

import { StatusBar } from 'expo-status-bar';
import _ from 'lodash';

import Button from '@components/Button';
import { ICON_NAMES } from '@components/Icons';
import PetCollectionListItem from '@components/PetCollectionListItem';
import { Text, View } from '@components/Themed';
import { waitForNavigation } from '@helpers/helpers';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { StackActions } from '@react-navigation/native';

import { PlayCollectedExperienceProps } from '../../../../types';
import stylesMain from './styles';

export const PlayCollectedExperienceScreen = ({
    route,
}: PlayCollectedExperienceProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const disabled = React.useRef(false);
    const { pets } = route.params;

    const handleOnClose = () => {
        if (!disabled.current) {
            navigation.dispatch(StackActions.pop());
        }
        disabled.current = true;
    };

    return (
        <View style={styles.container}>
            <StatusBar style="light" />
            <View style={styles.header}>
                <Button
                    containerStyle={styles.buttonExit}
                    disabled={disabled.current}
                    onPress={handleOnClose}
                    type={'outline'}
                    size={'md'}
                    iconName={ICON_NAMES.CLOSE}
                    iconSize={16}
                />
                <Text style={styles.headerText}>
                    {i18n.t('playCollectedExperience.screenTitle')}
                </Text>
            </View>
            <ScrollView style={styles.content}>
                <Text style={styles.title}>
                    {i18n.t('playCollectedExperience.title')}
                </Text>
                <Text style={styles.text}>
                    {i18n.t('playCollectedExperience.text')}
                </Text>
                <View style={styles.petsWrapper}>
                    {pets.map((pet, index) => (
                        <PetCollectionListItem
                            key={pet.id}
                            pet={pet}
                            fullViewLvl
                            fromScreenModal
                            showDivider={pets.length - 1 !== index}
                            style={styles.petItem}
                            canLvlUp={pet.levelUpAvailable}
                            canRankUp={pet.rankUpAvailable}
                            isDead={pet.reviveAvailable}
                            isNeedToFeed={pet.healUpAvailable}
                            isAgeDead={pet.reviveAgeAvailable}
                            onPressPet={() => {
                                navigation.dispatch(StackActions.pop());
                                waitForNavigation().then(() =>
                                    navigation.navigate(
                                        ROUTES.COLLECTION_DETAILED_PET,
                                        {
                                            pet: pet,
                                        }
                                    )
                                );
                            }}
                        />
                    ))}
                </View>
            </ScrollView>
        </View>
    );
};
export default PlayCollectedExperienceScreen;
