import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            marginBottom: verticalScale(16),
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
        },
        titleWrapper: {
            flexDirection: 'row',
        },
        titleTextWrapper: {
            marginLeft: scale(8),
            flex: 1,
        },
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
            marginBottom: verticalScale(2),
        },
        text: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
        },
        timerWrapper: {
            marginTop: verticalScale(16),
            marginBottom: verticalScale(4),
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            backgroundColor: Colors[theme].controlSecondary,
            borderRadius: moderateScale(12),
            alignItems: 'center',
        },
        timeTitle: {
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),

            color: Colors[theme].text.secondary,
        },
        timerBlock: {
            marginTop: verticalScale(8),
            backgroundColor: 'transparent',
        },

        buttonsWrapper: {
            marginTop: verticalScale(16),
        },
        gap: {
            marginTop: verticalScale(16),
        },
        buttons: {
            paddingHorizontal: 0,
            paddingBottom: 0,
        },
    });

export default styles;
