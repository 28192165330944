export const config = {
    tradingViewUri: 'https://charts.iguverse.com/',
    igupbnb: 'IGUPBUSD_8F2007',
    igupbusd: 'IGUPBUSD_8F2007',
    bnbigu: '',
    bnbbusd: 'BNBBUSD',
};

export const getPair = (from: string, to: string) => {
    const checkValue = (value: string) => from === value || to === value;
    if (checkValue('IGUP')) {
        if (checkValue('BUSD')) return config.igupbusd;
        if (checkValue('BNB')) return config.igupbnb;
    } else if (checkValue('BNB')) {
        if (checkValue('IGU')) return config.bnbigu;
        if (checkValue('BUSD')) return config.bnbbusd;
    }
    return '';
};
