import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import ClickableSelectCard from '@components/ClickableSelectCard';
import VIGUTopUpIcon, {
    VIGU_TOP_UP_ARRAY,
} from '@components/Icons/VIGUTopUpIcon';
import { View } from '@components/Themed';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { priceString } from '@helpers/payments';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';

import stylesMain from './styles';

interface IProps {
    onPress: (amount: string) => void;
    amount: string;
    containerStyle?: StyleProp<ViewStyle>;
    fee?: number;
    availableBalance?: string;
}

const SelectByCardAmountTopUp = ({
    onPress,
    amount,
    containerStyle,
    fee,
    availableBalance,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={[styles.container, containerStyle]}>
            {VIGU_TOP_UP_ARRAY.map((item) => {
                return (
                    <ClickableSelectCard
                        key={item.id}
                        disabled={
                            Number(item.amount) >
                            Number(balanceFromWei(availableBalance).value)
                        }
                        isSelect={item.amount === amount}
                        containerStyle={styles.card}
                        title={priceString(item.text, Coin.vigu)}
                        icon={<VIGUTopUpIcon slug={item.id} size={32} />}
                        onPress={() => onPress(item.amount)}
                    />
                );
            })}
        </View>
    );
};
export default React.memo(SelectByCardAmountTopUp);
