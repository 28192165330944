import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import Colors from '../../../../constants/Colors';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: { flex: 1 },
        header: {
            paddingHorizontal: scale(20),
            marginBottom: verticalScale(20),
        },
        summary: {
            marginTop: verticalScale(16),
            paddingHorizontal: scale(20),
        },
        summaryItem: {
            alignContent: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
        },
        summaryTitle: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 21,
                weight: '400',
            }),
            letterSpacing: 0.08,
            marginRight: scale(8),
        },
        summaryText: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 23,
                weight: '600',
            }),
        },
        fee: {
            flexDirection: 'row',
            alignContent: 'center',
            alignItems: 'center',
        },
    });

export default styles;
