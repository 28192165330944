import * as React from 'react';
import { ScrollView } from 'react-native';

import AlertCard from '@components/AlertCard';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import { ICON_NAMES } from '@components/Icons';
import ModalCompleteVerification from '@components/ModalCompleteVerification';
import ConnectFacebookByUsername from '@components/SocialMedia/ConnectFacebookByUsername';
import ConnectInstagramByUsername from '@components/SocialMedia/ConnectInstagramByUsername';
import ConnectTikTok from '@components/SocialMedia/ConnectTikTok';
import ConnectTwitter from '@components/SocialMedia/ConnectTwitter';
import { View } from '@components/Themed';
import { Links } from '@constants/Links';
import { useConfig } from '@contexts/ConfigContext';
import { waitForModal } from '@helpers/helpers';
import { openUrlOrShowError } from '@helpers/linking';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';
import { useNavigation } from '@navigation/useNavigation';

import stylesMain from '../styles';
import SocialAccountsSettingsStyles from './styles';

const SocialAccountsSettingsScreen = () => {
    const mainStyles = useThemedStyles(stylesMain);
    const styles = useThemedStyles(SocialAccountsSettingsStyles);
    const { config, isNewIosVersion } = useConfig();
    const navigation = useNavigation();

    const { isVisible, open, close } = useVisibleHook();

    const handleCompleteLinking = (status: boolean) => {
        status && waitForModal().then(open);
    };

    const handleNavigate = () => {
        close();
        waitForModal().then(() => navigation.navigateToTasks());
    };

    const onPressKnowledgeBase = () => {
        if (config?.knowledgeBaseUrl) {
            openUrlOrShowError(
                config.knowledgeBaseUrl + Links.socializeToEarnRequirements
            );
        }
    };

    return (
        <View style={mainStyles.container}>
            <NavigationBar
                backIcon
                title={i18n.t('profile.socialAccountsSettings.title')}
                filled={false}
            />
            <ScrollView
                style={mainStyles.page}
                keyboardShouldPersistTaps="always"
                showsVerticalScrollIndicator={false}>
                <BlockHeader
                    containerStyle={styles.header}
                    type="primary"
                    title={i18n.t('profile.socialAccountsSettings.headerTitle')}
                    text={i18n.t('profile.socialAccountsSettings.headerText')}
                    textInfo={
                        !isNewIosVersion
                            ? i18n.t(
                                  'profile.socialAccountsSettings.headerInfo'
                              )
                            : ''
                    }
                    textInfoOnPress={onPressKnowledgeBase}
                    iconName={ICON_NAMES.EXPLORE}
                    iconSize={20}
                    iconDirection="right"
                />
                <View style={styles.contentContainer}>
                    <ConnectInstagramByUsername
                        onCompletedLinking={handleCompleteLinking}
                        containerStyle={styles.itemsGap}
                    />

                    <ConnectFacebookByUsername
                        containerStyle={styles.itemsGap}
                        onCompletedLinking={handleCompleteLinking}
                    />

                    <ConnectTikTok
                        containerStyle={styles.itemsGap}
                        onCompletedLinking={handleCompleteLinking}
                    />
                    <ConnectTwitter
                        onCompletedLinking={handleCompleteLinking}
                    />
                </View>
            </ScrollView>
            <AlertCard
                text={i18n.t('profile.socialAccountsSettings.alert')}
                containerStyle={styles.alert}
            />
            <ModalCompleteVerification
                isVisible={isVisible}
                onClose={close}
                onPress={handleNavigate}
            />
        </View>
    );
};

export default SocialAccountsSettingsScreen;
