import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { moderateScale, scale, verticalScale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        containerHead: {
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderBottomWidth: 0,
            borderTopLeftRadius: moderateScale(12),
            borderTopRightRadius: moderateScale(12),
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
        },
        container: {
            backgroundColor: Colors[theme].background,
        },
        textWrapper: {
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        ntfTopPlayInfo: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
            letterSpacing: 0.16,
            marginTop: verticalScale(4),
        },
        ntfTopPlay: {
            color: Colors[theme].text.primary,
            ...font({
                size: 18,
                height: 26,
                weight: '700',
            }),
            marginRight: scale(4),
        },
        textLeft: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        timerBlockWrapper: {
            paddingVertical: verticalScale(20),
        },
    });
export default styles;
