import * as React from 'react';

import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import { MYSTERY_LIKES_STATUS } from '@models/mysteryGames';

import GamesHistoryButtons from '../GamesHistoryButtons';
import MysteryLikesHistoryBlockHeader from '../MysteryLikesHistoryBlockHeader';
import stylesMain from './styles';

interface IProps {
    status: MYSTERY_LIKES_STATUS;
    exploreAction: () => void;
    toHistoryAction: () => void;
}

const MysteryLikesHistoryWaiting = ({
    status,
    exploreAction,
    toHistoryAction,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={styles.main}>
            <View style={styles.block}>
                <MysteryLikesHistoryBlockHeader status={status} isBigHeader />
            </View>
            <GamesHistoryButtons
                firstButtonAction={exploreAction}
                secondButtonAction={toHistoryAction}
                white
                firstButtonType="outline"
                isOneTheme
            />
        </View>
    );
};

export default MysteryLikesHistoryWaiting;
