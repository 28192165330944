import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            paddingHorizontal: scale(20),
            marginBottom: verticalScale(20),
        },
        imgWrapper: {
            alignItems: 'center',
            marginTop: verticalScale(32),
            marginBottom: verticalScale(24),
        },
        gap: { marginTop: verticalScale(12) },
        cardsInfoWrapper: {
            marginTop: verticalScale(16),
        },
        header: {},
        title: {
            textAlign: 'center',
            color: Colors[theme].text.primary,
            ...font({
                size: 22,
                height: 30,
                weight: '800',
            }),
            marginBottom: verticalScale(8),
        },
        text: {
            textAlign: 'center',
            color: Colors[theme].text.secondary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
        },
        bold: {
            color: Colors[theme].text.accent,
        },
    });

export default styles;
