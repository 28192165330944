import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import { verticalScale } from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { stylesLoadMore } from '../../../../../helpers/style';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            alignItems: 'center',
        },
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 18,
                height: 26,
                weight: '700',
            }),
            marginRight: verticalScale(4),
        },
        itemText: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
            marginLeft: verticalScale(8),
        },
        titleContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: verticalScale(8),
        },
        titleWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        noTransactionWrapper: {
            flex: 1,
            alignItems: 'center',
            paddingTop: verticalScale(32),
        },
        loadingWrapper: {
            ...stylesLoadMore(),
        },
        icon: {
            color: Colors[theme].icon.onDark,
        },
    });

export default styles;
