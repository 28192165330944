import { useCallback, useMemo } from 'react';

import {
    CompleteVerificationTaskResponse,
    CreateVerificationTaskResponse,
    VerificationTasksResponse,
} from '@Data/Models';
import {
    COMPLETE_VERIFICATION_SUBTASK,
    CREATE_VERIFICATION_TASK,
    GET_VERIFICATION_TASK,
} from '@Data/Requests';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { usePlay } from '@contexts/PlayContext';
import { errorsHandler } from '@helpers/errors';
import { toastError } from '@helpers/toastNotification';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import useTasksErrorsHandler from './useTasksErrorsHandler';

interface IProps {
    onError?: () => void;
    onCompleted?: () => void;
    withNavigation?: boolean;
}

const useVerifyTask = () => {
    const { taskWrongState } = useTasksErrorsHandler();
    const navigation = useNavigation();
    const { updateVerifyTask, verifyTask } = usePlay();
    const [
        getVerifyTask,
        {
            loading: verifyTaskLoading,
            error: verifyTaskError,
            refetch: refetchVerifyTask,
        },
    ] = useLazyQuery<VerificationTasksResponse>(GET_VERIFICATION_TASK, {
        fetchPolicy: 'network-only',
        onError: (error) => errorsHandler(error),
    });

    const [
        createVerificationTaskMutation,
        { loading: createVerificationTaskLoading },
    ] = useMutation<CreateVerificationTaskResponse>(CREATE_VERIFICATION_TASK);

    const [completeVerificationTask, { loading: verificationLoading }] =
        useMutation<CompleteVerificationTaskResponse>(
            COMPLETE_VERIFICATION_SUBTASK
        );

    const canStartNextTask = useMemo(
        () => verifyTask?.canStart && verifyTask?.status !== 'COMPLETED',
        [verifyTask]
    );

    const goToWelcomeScreen = useMemo(() => verifyTask?.canStart, [verifyTask]);

    const taskReadyToVerify = useMemo(
        () => !verifyTask?.canStart && verifyTask?.tasks.length,
        [verifyTask]
    );

    const handleErrors = (error: ApolloError, withNavigation?: boolean) => {
        errorsHandler(error);
        taskWrongState(error);
        const noSocialTask = error.graphQLErrors.find(
            (err) => err.extensions.code === 'VERIFICATION_TASK_NO_SOCIAL_TASKS'
        );
        if (noSocialTask) {
            withNavigation
                ? navigation.replace(ROUTES.SOCIALIZE_TO_EARN_VERIFY_USER_ERROR)
                : toastError(
                      i18n.t('socializeToEarn.verifyUser.errors.noTasksText1'),
                      i18n.t('socializeToEarn.verifyUser.errors.noTasksText2')
                  );
            return;
        }
    };

    const createVerifyTask = useCallback(
        async (arg: IProps) => {
            createVerificationTaskMutation({
                onCompleted: (data) => {
                    updateVerifyTask(data.createVerificationTask);
                    arg?.onCompleted?.();
                },
                onError: (error) => {
                    if (arg?.onError) {
                        arg.onError();
                        return;
                    }
                    handleErrors(error, arg?.withNavigation);
                },
            });
        },
        [createVerificationTaskMutation, updateVerifyTask]
    );

    const handleFinish = useCallback(() => {
        if (!verifyTask) {
            navigation.navigateToTasks(true);
            return;
        }
        navigation.replace(ROUTES.PLAY_TASK_SUCCESS, {
            maxEnergy: verifyTask.maxEnergyReward || 0,
            earnedEnergy: verifyTask.totalEnergyRewarded || 0,
            petExperienceRewards: [],
            game: 'VERIFY',
        });
    }, [verifyTask]);

    const verifyActionStatusButton = useCallback(async () => {
        if (canStartNextTask) {
            await createVerifyTask({
                onCompleted: () =>
                    navigation.replace(ROUTES.SOCIALIZE_TO_EARN_VERIFY_USER),
                withNavigation: true,
            });
            return;
        }
        handleFinish();
    }, [canStartNextTask, handleFinish, createVerifyTask]);

    return {
        verifyActionStatusButton,
        createVerificationTaskLoading,
        canStartNextTask,
        createVerifyTask,
        completeVerificationTask,
        getVerifyTask,
        refetchVerifyTask,
        goToWelcomeScreen,
        taskReadyToVerify,
        verifyTaskLoading,
    };
};

export default useVerifyTask;
