import React from 'react';
import { Image } from 'react-native';

import { isIOS } from '../../../helpers/app';
import useThemedStyles from '../../../hooks/useThemedStyles';
import { useVisibleHook } from '../../../hooks/useVisibleHook';
import i18n from '../../../i18n/i18n';
import Animation from '../../Animation';
import CustomModal from '../../CustomModal';
import { View } from '../../Themed';
import stylesMain from './styles';

const loadedImages = {
    splashFull: require('../../../assets/icons/app/splashFull.png'),
};

const config = {
    resizeMode: 'cover',
} as const;

const GlobalErrorState = () => {
    const styles = useThemedStyles(stylesMain);
    const { isVisible } = useVisibleHook(true);

    return (
        <>
            <Image
                source={loadedImages.splashFull}
                resizeMode={config.resizeMode}
                style={styles.bgImage}
            />
            <CustomModal
                isVisible={isVisible}
                icon={
                    <View style={styles.image}>
                        <Animation
                            animation="maintenance"
                            style={styles.image}
                        />
                    </View>
                }
                titleText={i18n.t('AppState.GlobalErrorState.title')}
                infoText={i18n.t('AppState.GlobalErrorState.text')}
            />
        </>
    );
};

export default GlobalErrorState;
