import * as React from 'react';
import { useState } from 'react';
import { SafeAreaView, ScrollView } from 'react-native';

import Button from '@components/Button';
import Icon, { ICON_NAMES } from '@components/Icons';
import LoadingComponent from '@components/LoadingComponent';
import ModalShareOrSave from '@components/ModalShareOrSave';
import ReferralShareCard from '@components/ReferralShareCard';
import { View } from '@components/Themed';
import ViewShot from '@components/ViewShot';
import Colors from '@constants/Colors';
import { ReferralCardBackgrounds } from '@constants/referralCardTemplates';
import { useTheme } from '@contexts/ThemeContext';
import { useUser } from '@contexts/UserContext';
import useRankMe from '@hooks/useRankMe';
import useSharePhoto from '@hooks/useShare';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';
import { getDeeplinkReferralsInvite } from '@navigation/helpers';

import ReferralCode from '../components/ReferralCode';
import ReferralColorPicker from '../components/ReferralColorPicker';
import ReferralLink from '../components/ReferralLink';
import stylesMain from './styles';

const ReferralsInviteUserScreen = () => {
    const styles = useThemedStyles(stylesMain);
    const { theme } = useTheme();

    const { user } = useUser();
    const viewShotRef = React.useRef<any>(null);
    const { rankMeData, getRankMe, loading } = useRankMe();

    const {
        handleShareViewShot,
        handleSaveViewShot,
        renderCameraRollPermissions,
    } = useSharePhoto();

    React.useEffect(() => {
        getRankMe();
    }, []);

    const [bgColors, setBgColors] = React.useState(ReferralCardBackgrounds[0]);

    const noPet = React.useMemo(() => !user?.mainPet, [user]);

    const handleBgColor = (id: string) => {
        const index = ReferralCardBackgrounds.findIndex((val) => val.id === id);
        setBgColors(ReferralCardBackgrounds[index]);
    };

    const [isVisibleModalShare, setIsVisibleModalShare] =
        useState<boolean>(false);

    const onShare = () => {
        setIsVisibleModalShare(true);
    };

    const handleSaveToCameraRoll = async () => {
        return handleSaveViewShot(viewShotRef);
    };

    const handleShare = async () => {
        return handleShareViewShot(
            viewShotRef,
            `${i18n.t(
                'referrals.inviteUsersScreen.download'
            )} ${getDeeplinkReferralsInvite(user?.referralCode)}`,
            'Share'
        );
    };

    return (
        <View style={styles.mainContainer}>
            <NavigationBar
                title={i18n.t('referrals.inviteUsersScreen.title')}
                backIcon="close"
                filled={false}
                fillColor={!loading ? bgColors.opacity : undefined}
            />
            {loading && <LoadingComponent />}
            {!!user && !!rankMeData && (
                <View style={styles.container}>
                    <View
                        style={[
                            noPet ? styles.noPetContainer : styles.wrapper,
                            {
                                backgroundColor: bgColors.opacity,
                            },
                        ]}>
                        <ScrollView
                            showsVerticalScrollIndicator={false}
                            bounces={false}>
                            <View style={styles.top}>
                                <View>
                                    <ViewShot
                                        ref={viewShotRef}
                                        options={{
                                            format: 'jpg',
                                            quality: 1,
                                        }}>
                                        <View
                                            style={[
                                                styles.viewShot,
                                                {
                                                    backgroundColor:
                                                        bgColors.opacity,
                                                },
                                                noPet && styles.noPetPadding,
                                            ]}>
                                            <ReferralShareCard
                                                noPet={noPet}
                                                backgroundColor={bgColors}
                                                petImage={
                                                    user.mainPet?.image
                                                        .thumbnailUrl
                                                }
                                                petName={user.mainPet?.name}
                                                collectedEnergy={
                                                    rankMeData.rankingMe
                                                        ?.totalEnergyRewarded ||
                                                    0
                                                }
                                                leaderBoardPlace={
                                                    rankMeData.rankingMe
                                                        ?.rank || 'N/A'
                                                }
                                                code={user.referralCode}
                                            />
                                        </View>
                                    </ViewShot>
                                </View>
                            </View>

                            <View style={styles.codeContainer}>
                                <View style={styles.codeWrapper}>
                                    <ReferralCode
                                        code={user.referralCode}
                                        defaultBg
                                    />
                                </View>

                                <View style={styles.codeWrapper}>
                                    <ReferralLink
                                        code={user.referralCode}
                                        defaultBg
                                    />
                                </View>
                            </View>
                        </ScrollView>
                    </View>
                    <View style={styles.bottom}>
                        <ReferralColorPicker
                            selectedColorID={bgColors.id}
                            backgrounds={ReferralCardBackgrounds}
                            handleBgColor={handleBgColor}
                        />
                        <View style={styles.buttonWrapper}>
                            <Button
                                icon={
                                    <Icon
                                        name={ICON_NAMES.SHARE}
                                        color={Colors[theme].text.onDark}
                                    />
                                }
                                onPress={onShare}
                                title={i18n.t(
                                    'referrals.inviteUsersScreen.button'
                                )}
                            />
                        </View>
                    </View>
                </View>
            )}
            <SafeAreaView />
            <ModalShareOrSave
                isVisible={isVisibleModalShare}
                setIsVisible={setIsVisibleModalShare}
                handleSaveToCameraRoll={handleSaveToCameraRoll}
                handleShare={handleShare}
                renderPermissions={renderCameraRollPermissions}
            />
        </View>
    );
};
export default ReferralsInviteUserScreen;
