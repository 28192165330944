import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            marginBottom: verticalScale(12),
        },
        row: {
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: verticalScale(16),
        },
        left: {
            flexDirection: 'row',
            alignItems: 'center',
            marginRight: scale(16),
        },
        iconBlock: {
            marginRight: scale(8),
        },
        label: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
            textTransform: 'capitalize',
        },

        title: {
            textAlign: 'right',
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
            flex: 1,
        },
    });

export default styles;
