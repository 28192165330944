import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '../../../helpers/dimensions';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        languageDescription: {
            marginTop: verticalScale(4),
            marginBottom: verticalScale(16),
        },
    });

export default styles;
