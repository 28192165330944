import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { scale, verticalScale } from '../../helpers/dimensions';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(20),
            paddingVertical: verticalScale(16),
            flexDirection: 'row',
            backgroundColor: Colors[theme].background,
        },
    });

export default styles;
