import { Pet, RewardsTransactionHistoryResponseItem } from '../Data/Models';

export const sortPetsByLastTransferAndCreated = (a: Pet, b: Pet) => {
    const aDate = new Date(a.lastTransferAt || a.createdAt);
    const bDate = new Date(b.lastTransferAt || b.createdAt);
    return bDate > aDate ? 1 : -1;
};

export const sortByDate = (
    a: RewardsTransactionHistoryResponseItem,
    b: RewardsTransactionHistoryResponseItem
) => {
    const aDate = new Date(a.unlockAt || a.createdAt);
    const bDate = new Date(b.unlockAt || a.createdAt);
    return bDate > aDate ? 1 : -1;
};
export const sortByDateReverse = (
    a: RewardsTransactionHistoryResponseItem,
    b: RewardsTransactionHistoryResponseItem
) => {
    const aDate = new Date(a.unlockAt || a.createdAt);
    const bDate = new Date(b.unlockAt || a.createdAt);
    return bDate < aDate ? 1 : -1;
};
