import React, { useState } from 'react';

import { useLazyQuery } from '@apollo/client';

import {
    ModerationSocialTask,
    ModerationSocialTaskResponse,
} from '../../Data/Models';
import { GET_MODERATION_SOCIAL_TASK } from '../../Data/Requests';

const useGetModerationSocialTask = () => {
    const [moderationSocialTask, { loading: moderationSocialTaskLoading }] =
        useLazyQuery<ModerationSocialTaskResponse>(GET_MODERATION_SOCIAL_TASK);

    const [
        moderationSocialTaskRefreshLoading,
        setModerationSocialTaskRefreshLoading,
    ] = useState<boolean>(false);

    const [moderationTask, setModerationTask] =
        React.useState<ModerationSocialTask | null>(null);

    const getModerationSocialTask = async (refresh?: boolean) => {
        refresh && setModerationSocialTaskRefreshLoading(true);
        await moderationSocialTask({
            fetchPolicy: 'no-cache',
            onError: (error) => {
                Console.error(error);
                setModerationTask(null);
            },
            onCompleted: ({ moderationSocialTask }) => {
                Console.log(
                    'moderationSocialTask',
                    moderationSocialTask?.pet?.name
                );
                setModerationTask(moderationSocialTask);
            },
        });
        refresh && setModerationSocialTaskRefreshLoading(false);
    };
    return {
        getModerationSocialTask,
        moderationTask,
        moderationSocialTaskLoading,
        moderationSocialTaskRefreshLoading,
    };
};

export default useGetModerationSocialTask;
