import * as React from 'react';

import CheckoutSummary from '../../../../components/CheckoutScreen/CheckoutSummary';
import CheckoutSuccessScreen from '../../../../components/CheckoutSuccessScreen';
import Divider from '../../../../components/Divider';
import ModalLoader from '../../../../components/ModalLoader';
import { PlayContext } from '../../../../contexts/PlayContext';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import { useNavigation } from '../../../../navigation/useNavigation';
import { CollectionPetHealUpSuccessProps } from '../../../../types';
import TransactionDetailsHealUp from '../components/TransactionDetailsHealUp';
import stylesMain from './styles';

const CollectionHealUpSuccessScreen = ({
    route,
}: CollectionPetHealUpSuccessProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const { refetchPets, petsLoading } = React.useContext(PlayContext);

    const {
        pet,
        payment,
        transactionResult,
        navigateBackOnUpgrade,
        healthPoints,
    } = route.params;

    const handleNavigateToPet = async () => {
        const newPets = await refetchPets(false);

        if (newPets.pets) {
            const updatedPet = newPets.pets.find((p) => p.id === pet.id);

            if (updatedPet) {
                navigation.navigateToPetDetails(
                    updatedPet,
                    navigateBackOnUpgrade
                );
            }
        }
    };

    return (
        <>
            <CheckoutSuccessScreen
                title={i18n.t('detailedPet.health.successHealUp.title')}
                text={i18n.t('detailedPet.health.successHealUp.text')}
                onPressButtonMain={handleNavigateToPet}
                buttonMainText={i18n.t(
                    'detailedPet.health.successHealUp.backToPet'
                )}
                buttonAdditionalText={
                    !!navigateBackOnUpgrade ? i18n.t('general.back') : ''
                }
                onPressButtonAdditional={
                    navigateBackOnUpgrade
                        ? () => {
                              navigation.navigate(navigateBackOnUpgrade);
                          }
                        : undefined
                }
                TransactionDetailsComponent={
                    <>
                        <TransactionDetailsHealUp
                            pet={pet}
                            healthUp={healthPoints}
                        />

                        <Divider customStyles={styles.divider} />
                        <CheckoutSummary
                            payment={payment}
                            transactionResult={transactionResult ?? undefined}
                        />
                    </>
                }
                explorerUrl={transactionResult?.explorerUrl}
            />
            <ModalLoader
                isVisible={petsLoading}
                text={i18n.t('petLevelUp.petLevelUpSuccess.loading')}
            />
        </>
    );
};

export default CollectionHealUpSuccessScreen;
