import * as React from 'react';
import { Image, View } from 'react-native';

import BybitBackground from '@components/Icons/BybitBackground';
import BybitLogo from '@components/Icons/BybitLogo';
import { Text } from '@components/Themed';
import { ISocialToEarnCard } from '@constants/shareCardTemplatesNew';
import { isWeb } from '@helpers/app';
import useLanguage from '@hooks/useLanguage';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

const FONT_SIZE = {
    en: {
        fontSize: 100,
        lineHeight: 120,
    },
    uk: {
        fontSize: 100,
        lineHeight: 120,
    },
    ru: {
        fontSize: 100,
        lineHeight: 120,
    },
    pl: {
        fontSize: 100,
        lineHeight: 120,
    },
    id: {
        fontSize: 100,
        lineHeight: 120,
    },
    es: {
        fontSize: 100,
        lineHeight: 120,
    },
    tr: {
        fontSize: 100,
        lineHeight: 120,
    },
};

interface IProps {
    config: {
        width: number | string;
        height: number | string;
    };
    backGround: ISocialToEarnCard;
    token: string | undefined;
    withBorderRadius?: boolean;
    url: string | undefined;
}

const BybitTemplate = ({
    config,
    backGround,
    token,
    withBorderRadius,
    url,
}: IProps) => {
    const { width, height } = config;

    const {
        cardTypeId,
        title,
        background,
        qrCodeBackground,
        qrCodeTitle,
        id,
        logo,
    } = backGround;
    const styles = useThemedStyles(stylesMain);

    const { locale } = useLanguage();

    const fontStyle = React.useMemo(
        () => FONT_SIZE[locale as keyof object],
        [locale]
    );

    return (
        <View
            style={[
                {
                    width,
                    height,
                    overflow: 'hidden',
                    backgroundColor: background,
                },

                withBorderRadius && { borderRadius: 100 },
            ]}
            collapsable={false}>
            <View style={styles.imageBlock}>
                {isWeb ? (
                    <img src={url} style={styles.petImage} alt="Pet ID" />
                ) : (
                    <Image
                        source={{
                            uri: url,
                        }}
                        style={styles.petImage}
                    />
                )}
            </View>
            <View style={styles.bg}>
                <BybitBackground slug={id} />
            </View>
            <View style={styles.content}>
                <View>
                    <BybitLogo width={684} height={59} color={logo} />
                </View>
                <View style={styles.titleBlock}>
                    <Text
                        style={[styles.title, { color: title }, fontStyle]}
                        allowFontScaling={false}>
                        {i18n.t(
                            `socializeToEarn.createShareTemplate.shareTemplate.${cardTypeId}`
                        )}
                    </Text>
                </View>
                <View
                    style={[
                        styles.qrCodeWrapper,
                        { backgroundColor: qrCodeBackground },
                    ]}>
                    <Text
                        allowFontScaling={false}
                        style={[
                            styles.qrCode,
                            { color: qrCodeTitle },
                        ]}>{`#${token}`}</Text>
                </View>
            </View>
        </View>
    );
};
export default BybitTemplate;
