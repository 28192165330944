import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import Button from '@components/Button';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT, TYPO_TEXT_COLOR } from '@components/TypoText/types';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import {
    LuckyStrikeGamesStatisticOutput,
    MYSTERY_GAMES,
} from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import LuckyStrikeFeatures from '../LuckyStrikeFeatures';
import stylesMain from './styles';

interface IProps {
    containerStyle?: StyleProp<ViewStyle>;
    statistics?: LuckyStrikeGamesStatisticOutput;
}

const LuckyStrikeWinners = ({ containerStyle, statistics }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const navigation = useNavigation();

    const handleAllWinners = () => {
        navigation.push(ROUTES.LUCKY_STRIKE_ALL_WINNERS);
    };

    return (
        <View style={[styles.container, containerStyle]}>
            <View style={styles.titleBlock}>
                <View style={styles.title}>
                    <TypoText type={TYPO_TEXT.HEADING_H4}>
                        {i18n.t(
                            `HomeScreen.${MYSTERY_GAMES.luckyStrike}.LuckyStrikeWinners.title`
                        )}
                    </TypoText>
                </View>
                {!!statistics && (
                    <View>
                        <Button
                            title={i18n.t('general.BUTTONS.viewAll')}
                            type="text"
                            onPress={handleAllWinners}
                            debouncedPress
                        />
                    </View>
                )}
            </View>
            <TypoText
                type={TYPO_TEXT.BODY_MEDIUM}
                colorType={TYPO_TEXT_COLOR.SECONDARY}>
                {i18n.t(
                    `HomeScreen.${MYSTERY_GAMES.luckyStrike}.LuckyStrikeWinners.text`
                )}
            </TypoText>
            {!!statistics && (
                <LuckyStrikeFeatures
                    totalSlots={statistics.totalGamesCount}
                    totalAmount={statistics.totalIguAmountWei}
                />
            )}
        </View>
    );
};
export default LuckyStrikeWinners;
