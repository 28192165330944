import React from 'react';

import { useAppLoading } from '@contexts/AppLoading/AppLoadingContext';
import { useModal } from '@contexts/ModalContext';
import { isIOS } from '@helpers/app';

import { useVisibleHook } from '../../../../hooks/useVisibleHook';
import MemBanner from '../components/MemBanner';
import ModalMems from '../components/ModalMems';

const useMemsModal = () => {
    const {
        isVisible: isGlobalModalVisible,
        setModalClosed,
        setModalOpened,
    } = useModal();

    const { isVisible, close, open } = useVisibleHook(
        undefined,
        setModalOpened,
        setModalClosed
    );

    const { isSplashAnimationComplete } = useAppLoading();

    const canShow = React.useCallback(() => {
        if (!isSplashAnimationComplete) {
            return false;
        }
        if (isGlobalModalVisible && isIOS) {
            return false;
        }

        return true;
    }, [isSplashAnimationComplete, isGlobalModalVisible]);

    const [isShown, setIsShown] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (canShow() && !isShown) {
            setIsShown(true);
            open();
        }
    }, [canShow, isShown]);

    const renderMemsModal = React.useCallback(() => {
        return <ModalMems isVisible={isVisible} onClose={close} />;
    }, [isVisible]);

    const {
        isVisible: memBanner,
        close: closeMemBanner,
        open: openMemBanner,
    } = useVisibleHook();

    const onBannerPress = () => {
        closeMemBanner();
        open();
    };
    React.useEffect(() => {
        openMemBanner();
    }, []);

    const renderMemBanner = React.useCallback(() => {
        return <MemBanner onPress={onBannerPress} isVisible={memBanner} />;
    }, [memBanner]);

    return {
        renderMemsModal,
        renderMemBanner,
        openMemBanner,
    };
};

export default useMemsModal;
