import * as React from 'react';
import { useMemo } from 'react';

import CheckoutSuccessScreen from '@components/CheckoutSuccessScreen';
import { useGamingWallet } from '@contexts/GamingWalletContext';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { priceString } from '@helpers/payments';
import useFeeInfo from '@hooks/useFeeInfo';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import { TopUpSuccessScreenProps } from '../../../../types';
import GamingWalletCheckoutSummary from '../components/GamingWalletCheckoutSummary';

const TopUpSuccessScreen = ({ route }: TopUpSuccessScreenProps) => {
    const navigation = useNavigation();
    navigation.blockHardwareBack();
    const { resetGamingWallet, topUpAmount, isLoading, loadData } =
        useGamingWallet();
    const { topUpFromMysteryGames, setTopUpFromMysteryGames } =
        useMysteryGames();

    const { renderFeeInfoIcon } = useFeeInfo();

    const { transactionResult } = route.params;

    const handleNavigateBack = async () => {
        setTopUpFromMysteryGames(false);
        await loadData(true);
        resetGamingWallet();
        navigation.pop();
        navigation.replace(ROUTES.WALLET_ROOT, {
            screen: ROUTES.WALLET_GAMING_TAB,
        });
    };
    const handleNavigatePlay = React.useCallback(async () => {
        await loadData(true);
        topUpFromMysteryGames
            ? navigation.pop()
            : navigation.navigate(ROUTES.HOME);
        setTopUpFromMysteryGames(false);
        resetGamingWallet();
    }, [topUpFromMysteryGames]);

    const summaryValues = useMemo(() => {
        return [
            {
                key: i18n.t('checkout.fields.from'),
                value: i18n.t('checkout.fields.mainWallet'),
            },
            {
                key: i18n.t('checkout.fields.to'),
                value: i18n.t('checkout.fields.gamingWallet'),
            },
            {
                key: i18n.t('checkout.fields.fee'),
                value: transactionResult?.transactionFeeFormatted,
                icon: renderFeeInfoIcon(),
            },
            {
                key: i18n.t('checkout.fields.totalSummary'),
                value: priceString(topUpAmount, Coin.vigu),
            },
        ];
    }, [topUpAmount, transactionResult]);

    return (
        <CheckoutSuccessScreen
            buttonDisabled={isLoading}
            buttonLoading={isLoading}
            title={i18n.t('wallet.gaming.TopUpSuccessScreen.title')}
            text={i18n.t('wallet.gaming.TopUpSuccessScreen.text')}
            onPressButtonMain={handleNavigatePlay}
            buttonMainText={
                topUpFromMysteryGames
                    ? i18n.t('wallet.gaming.TopUpSuccessScreen.buttonBack')
                    : i18n.t('wallet.gaming.TopUpSuccessScreen.button')
            }
            buttonAdditionalText={i18n.t(
                'wallet.gaming.TopUpSuccessScreen.button2'
            )}
            onPressButtonAdditional={handleNavigateBack}
            TransactionDetailsComponent={
                <GamingWalletCheckoutSummary values={summaryValues} />
            }
            explorerUrl={transactionResult?.explorerUrl}
        />
    );
};

export default TopUpSuccessScreen;
