import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            alignItems: 'center',
            paddingHorizontal: scale(20),
            paddingVertical: verticalScale(20),

            borderRadius: moderateScale(12),

            backgroundColor: Colors[theme].fillPrimaryMinimal,
            marginTop: verticalScale(20),
        },

        gap: {
            marginBottom: verticalScale(6),
        },
        gapBott: {
            marginBottom: verticalScale(16),
        },
    });

export default styles;
