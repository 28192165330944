import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';

import InfoIcon from '../../../../../components/InfoIcon/InfoIcon';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import { ComponentAnyType } from '../../../../../types';
import Styles from './styles';

interface IProps {
    title?: string;
    description?: string;
    icon?: ComponentAnyType;
    highlighted?: boolean;
    children?: React.ReactNode;
    onInfoIconPress?: () => void;
    onIconPress?: () => void;
    isDead?: boolean;
}

const DetailsBlock = ({
    title,
    description,
    highlighted,
    icon,
    children,
    onInfoIconPress,
    onIconPress,
    isDead,
}: IProps) => {
    const styles = useThemedStyles(Styles);

    return (
        <View
            style={[
                styles.container,
                highlighted && styles.highlightedContainer,
                isDead && styles.dead,
            ]}>
            <View style={styles.card}>
                <View style={styles.cardWrapper}>
                    <View style={styles.cardHeader}>
                        <Text
                            style={[
                                styles.cardTitle,
                                highlighted && styles.highlightedText,
                                isDead && styles.deadText,
                            ]}>
                            {title}
                        </Text>
                        {!!onInfoIconPress && (
                            <InfoIcon
                                onPress={onInfoIconPress}
                                containerStyle={styles.infoIcon}
                                iconColor={
                                    highlighted || isDead
                                        ? styles.highlightedInfoIcon.color
                                        : undefined
                                }
                            />
                        )}
                    </View>
                    <View style={styles.cardBody}>
                        {!!description && (
                            <Text
                                style={[
                                    styles.textDescription,
                                    highlighted && styles.highlightedText,
                                    isDead && styles.deadText,
                                ]}>
                                {description}
                            </Text>
                        )}
                    </View>
                </View>
                {!!icon && (
                    <TouchableOpacity
                        disabled={!onIconPress}
                        activeOpacity={0.7}
                        onPress={onIconPress}
                        style={styles.cardIcon}>
                        {icon}
                    </TouchableOpacity>
                )}
            </View>
            <View>{children}</View>
        </View>
    );
};
export default DetailsBlock;
