import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        withCaptionsItem: {
            alignItems: 'center',
            backgroundColor: 'transparent',
        },
        container: {},
        text1: {
            ...font({
                size: 15,
                weight: '700',
            }),
            color: Colors[theme].text.primary,
        },
        text2: {
            ...font({
                size: 12,
            }),
            color: Colors[theme].text.secondary,
        },
    });

export default styles;
