import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            marginHorizontal: scale(20),
            borderWidth: 1,
            borderRadius: moderateScale(12),
            borderColor: Colors[theme].border.primary,
        },
        verificationItem: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        verificationTitle: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '600',
            }),
        },
        verificationSlug: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
        },
        button: {
            marginLeft: scale(12),
        },
        verificationTextContainer: {
            marginLeft: verticalScale(12),
            flex: 1,
        },
        left: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
        },
        right: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    });

export default styles;
