import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import Colors from '../../../constants/Colors';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        mainContainer: {
            flex: 1,
            backgroundColor: Colors[theme].background,
        },
        paddingHorizontal: {
            paddingHorizontal: scale(20),
        },
        content: {},
        inputWrapper: {
            paddingHorizontal: scale(20),
            marginTop: verticalScale(20),
        },
        buttons: {
            marginBottom: verticalScale(16),
        },
    });

export default styles;
