import * as React from 'react';
import { FlatList, TouchableOpacity } from 'react-native';

import ExchangesContent from '@components/ExchangesContent';
import CoinIcon from '@components/Icons/CoinIcon';
import InfoIcon from '@components/InfoIcon/InfoIcon';
import ModalBottom from '@components/ModalBottom';
import { Text, View } from '@components/Themed';
import { useTheme } from '@contexts/ThemeContext';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { CoinBalance } from '@contexts/Wallet/WalletHelpers';
import { stylesPriceInUsd } from '@helpers/style';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import stylesMain from './styles';

interface IProps {
    onCoinPress: (item: CoinBalance) => void;
}
interface IRenderItem {
    item: CoinBalance;
}

const WalletBalances = ({ onCoinPress }: IProps) => {
    const { theme } = useTheme();
    const styles = useThemedStyles(stylesMain);
    const { getCoinBalances } = useWallet();

    const navigation = useNavigation();

    const [isVisibleModalExchanges, setIsVisibleModalExchanges] =
        React.useState<boolean>(false);

    const dataSource = getCoinBalances();

    const handleCloseModalExchanges = () => {
        setIsVisibleModalExchanges(false);
    };

    const handleOnQuestionPress = () => {
        navigation.push(ROUTES.MAIN_WALLET_ONBOARDING_SCREEN);
    };

    const renderItem = ({ item }: IRenderItem) => (
        <TouchableOpacity
            activeOpacity={0.7}
            style={styles.itemContainer}
            onPress={() => onCoinPress(item)}>
            <View style={styles.iconWrapper}>
                <CoinIcon slug={item.name} size={32} />
                <Text style={styles.itemText}>{item.name}</Text>
            </View>
            <View style={styles.priceWrapper}>
                <Text style={styles.price}>{item.presentationValueLong}</Text>
                <Text
                    style={stylesPriceInUsd(
                        theme
                    )}>{`≈ ${item.valueDollars} $`}</Text>
            </View>
        </TouchableOpacity>
    );

    return (
        <View>
            <View style={styles.titleWrapper}>
                <Text style={styles.title}>{i18n.t('wallet.balances')}</Text>
                <InfoIcon onPress={handleOnQuestionPress} />
            </View>
            <FlatList
                data={dataSource}
                renderItem={renderItem}
                keyExtractor={(item) => item.name}
                numColumns={1}
                listKey="WalletBalancesKey"
            />
            <ModalBottom
                isVisible={isVisibleModalExchanges}
                titleText={i18n.t('wallet.exchanges')}
                additionalText={i18n.t('wallet.exchangesInfo')}
                onClose={handleCloseModalExchanges}
                modalHeight="80%">
                <ExchangesContent />
            </ModalBottom>
        </View>
    );
};
export default WalletBalances;
