import * as React from 'react';
import { useMemo } from 'react';

import { useAppState } from '@contexts/AppStateContext';
import { useConfig } from '@contexts/ConfigContext';
import { Coin, WalletBalance } from '@contexts/Wallet/WalletHelpers';

import { User } from '../../../Data/Models';
import Button from '../../../components/Button';
import Icon, { ICON_NAMES } from '../../../components/Icons';
import CoinIcon from '../../../components/Icons/CoinIcon';
import { Text, View } from '../../../components/Themed';
import TooltipGuide from '../../../components/TooltipGuide';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import { State } from '../../../types';
import { useNavigation } from '../../useNavigation';
import stylesMain from './styles';

interface IProps {
    user: User | undefined;
    walletBalance: WalletBalance | null;
    isOneColor?: boolean;
}

const Wallet = ({ user, walletBalance, isOneColor }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const { config } = useConfig();
    // show tooltip for the wallet only if there is one pet
    const { state } = useAppState();

    const energyThresholdReached = useMemo(
        () =>
            user &&
            config &&
            Number(user?.energy) >= Number(config?.rewardEnergyThreshold),
        [user, config]
    );

    const handleCoinsPress = () => {
        navigation.navigateToWallet();
    };

    const handleEnergyPress = () => {
        navigation.navigateToEnergy();
    };

    return (
        <View style={styles.coinsWrapper}>
            <Button
                isOneColor={isOneColor}
                containerStyle={styles.gap}
                style={styles.itemPadding}
                size="md"
                type="outline"
                onPress={handleEnergyPress}>
                <View style={styles.energyWrapper}>
                    <View style={styles.item}>
                        <Icon
                            name={ICON_NAMES.ENERGY}
                            size={styles.icon.width}
                        />
                        <Text
                            style={[
                                styles.text,
                                energyThresholdReached &&
                                    styles.energyThresholdReached,
                                isOneColor && styles.isOneColorText,
                            ]}>
                            {user?.energy || 0}
                        </Text>
                    </View>
                </View>
            </Button>
            <TooltipGuide
                shouldShow={state === State.loggedInWallet}
                content={i18n.t('navigationBar.tooltip')}
                placement="bottom"
                showKey="Wallet">
                <Button
                    size="md"
                    type="outline"
                    style={styles.itemPadding}
                    isOneColor={isOneColor}
                    onPress={handleCoinsPress}>
                    <View style={styles.coinWrapper}>
                        <View style={[styles.item, styles.itemGap]}>
                            <CoinIcon
                                slug={Coin.bnb}
                                size={styles.icon.width}
                            />
                            <Text
                                style={[
                                    styles.text,
                                    isOneColor && styles.isOneColorText,
                                ]}>
                                {walletBalance?.bnb.presentationValueShort ??
                                    '-'}
                            </Text>
                        </View>
                        <View style={[styles.item, styles.itemGap]}>
                            <CoinIcon
                                slug={Coin.igup}
                                size={styles.icon.width}
                            />
                            <Text
                                style={[
                                    styles.text,
                                    isOneColor && styles.isOneColorText,
                                ]}>
                                {walletBalance?.igup.presentationValueShort ??
                                    '-'}
                            </Text>
                        </View>
                        <View style={styles.item}>
                            <CoinIcon
                                slug={Coin.igu}
                                size={styles.icon.width}
                            />
                            <Text
                                style={[
                                    styles.text,
                                    isOneColor && styles.isOneColorText,
                                ]}>
                                {walletBalance?.igu.presentationValueShort ??
                                    '-'}
                            </Text>
                        </View>
                    </View>
                </Button>
            </TooltipGuide>
        </View>
    );
};

export default Wallet;
