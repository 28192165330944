import { useEffect, useMemo, useState } from 'react';

import {
    LootboxSaleOption,
    MysteryBoxPaymentsOptionsResponse,
} from '@Data/Models';
import { GET_MYSTERY_BOX_PAYMENTS_OPTIONS } from '@Data/Requests';
import { useQuery } from '@apollo/client';
import { useStorePurchase } from '@contexts/StorePurchaseContext';
import { balanceFromWei } from '@helpers/wallet';

const useMysteryBox = () => {
    const { getMysteyboxPrices } = useStorePurchase();
    const [paymentOptions, setPaymentOptions] = useState<LootboxSaleOption[]>(
        []
    );
    const {
        data: paymentOptionsResponse,
        loading,
        error,
    } = useQuery<MysteryBoxPaymentsOptionsResponse>(
        GET_MYSTERY_BOX_PAYMENTS_OPTIONS
    );

    useEffect(() => {
        async function load() {
            const lootboxSaleOptions =
                paymentOptionsResponse?.lootboxSaleOptions;
            if (!lootboxSaleOptions) return;

            const productIds = lootboxSaleOptions.flatMap((option) => {
                return option.payments
                    .map((payment) => {
                        return payment.productId ?? '';
                    })
                    .filter((p) => p);
            });
            Console.log('productIds', productIds);
            const products = await getMysteyboxPrices(productIds);

            const updatedOptions: LootboxSaleOption[] = lootboxSaleOptions.map(
                (option) => {
                    const payments = option.payments.map((payment) => {
                        if (payment.productId) {
                            const priceString = products.find(
                                (product) =>
                                    product.identifier == payment.productId
                            )?.priceString;
                            return { ...payment, priceString };
                        } else {
                            return payment;
                        }
                    });
                    return { ...option, payments };
                }
            );
            setPaymentOptions(updatedOptions);
        }
        load();
    }, [paymentOptionsResponse]);

    const payment = useMemo(() => {
        return paymentOptions.find((option) => option.count == 1)?.payments[0];
    }, [paymentOptions]);

    const parsedPrice = useMemo(() => {
        if (!payment) return '';
        return (
            payment.priceString ?? balanceFromWei(payment.amountWei).valueLong
        );
    }, [payment]);

    const parsedToken = useMemo(() => {
        if (payment) {
            return payment.token;
        }
        return 'IGU';
    }, [payment]);

    const parsedTokenString = useMemo(() => {
        if (parsedToken === 'STORE') {
            return '';
        }
        return parsedToken;
    }, [parsedToken]);

    return {
        loading,
        parsedPrice,
        parsedToken,
        parsedTokenString,
        paymentOptions,
        error,
    };
};

export default useMysteryBox;
