import * as React from 'react';
import { useState } from 'react';
import { LayoutRectangle, Platform, TouchableOpacity } from 'react-native';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import ColorPicker from '@components/ColorPicker';
import { ICON_NAMES } from '@components/Icons';
import { View } from '@components/Themed';
import ViewShot from '@components/ViewShot';
import { useDimensions } from '@contexts/DimensionsContext';
import { verticalScale } from '@helpers/dimensions';
import useSharePhoto from '@hooks/useShare';
import useTemplateColors from '@hooks/useTemplateColors';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';

import ShareTemplateCardNew from '../../components/ShareTemplateCardNew';
import ModalShareYourPhoto from '../components/ModalShareYourPhoto';
import stylesMain from './styles';

const configTemplateCard = {
    width: 1080,
    height: 1920,
};

export const CreateShareTemplateScreen = () => {
    const styles = useThemedStyles(stylesMain);
    const { safeBottom, windowHeight, windowWidth } = useDimensions();
    const { width, height } = configTemplateCard;

    const viewShotRef = React.useRef<any>(null);

    const {
        handleShareViewShot,
        handleSaveViewShot,
        renderCameraRollPermissions,
    } = useSharePhoto();

    const { templatesColors, changeTemplateColor, templateColor, cardType } =
        useTemplateColors();

    const handleShare = async () => {
        return handleShareViewShot(viewShotRef);
    };

    const handleSave = async () => {
        return handleSaveViewShot(viewShotRef);
    };

    const renderViewShotTemplate = React.useCallback(() => {
        const scale = Math.min(windowWidth / width, windowHeight / height);

        return (
            <ViewShot
                ref={viewShotRef}
                options={{
                    format: 'png',
                    quality: 1,
                }}
                style={{
                    transform: [{ scale }],
                    position: 'absolute',
                    bottom: -5000,
                }}>
                <ShareTemplateCardNew
                    sizeConfig={configTemplateCard}
                    backGround={templateColor}
                />
            </ViewShot>
        );
    }, [templateColor, windowWidth, windowHeight, height, width, viewShotRef]);

    const [blockSize, setBlockSize] = React.useState<LayoutRectangle>();

    const renderTemplate = React.useCallback(() => {
        if (!blockSize) {
            return null;
        }

        const scale = Math.min(
            blockSize.width / width,
            blockSize.height / height
        );
        const left = Math.round((blockSize.width - width) / 2);
        const top = Math.round((blockSize.height - height) / 2);

        return (
            <View
                style={[
                    styles.template,
                    { transform: [{ scale }], top, left },
                ]}>
                <ShareTemplateCardNew
                    sizeConfig={configTemplateCard}
                    backGround={templateColor}
                    withBorderRadius
                />
            </View>
        );
    }, [blockSize, templateColor, height, width]);

    const [isVisibleModalShare, setIsVisibleModalShare] =
        useState<boolean>(false);

    const handleShareYourPhotoModalOpen = () => {
        setIsVisibleModalShare(true);
    };

    return (
        <>
            <NavigationBar
                backIcon="leftArrow"
                settingsIcon={ICON_NAMES.SHARE}
                settingsIconType="fill"
                settingsIconColor="onDark"
                onPressSettings={handleShareYourPhotoModalOpen}
                filled={false}
                title={i18n.t(
                    'socializeToEarn.createShareTemplate.screenTitle'
                )}
            />
            <View style={styles.container}>
                <BlockHeader
                    type="primary"
                    title={i18n.t('socializeToEarn.createShareTemplate.title')}
                    text={i18n.t('socializeToEarn.createShareTemplate.text')}
                />
                <TouchableOpacity
                    onPress={handleShareYourPhotoModalOpen}
                    activeOpacity={0.8}
                    style={styles.templateBlock}
                    onLayout={({ nativeEvent }) => {
                        setBlockSize(nativeEvent.layout);
                    }}>
                    {renderTemplate()}
                    {renderViewShotTemplate()}
                </TouchableOpacity>
                <View
                    style={[
                        styles.colorPickerWrapper,
                        {
                            paddingBottom:
                                Platform.OS === 'android'
                                    ? verticalScale(safeBottom + 16)
                                    : verticalScale(safeBottom),
                        },
                    ]}>
                    <ColorPicker
                        backgrounds={templatesColors}
                        templateColor={templateColor}
                        handleBgColor={changeTemplateColor}
                    />
                </View>
                <ModalShareYourPhoto
                    modalHeight={450}
                    isSmallCard
                    isVisible={isVisibleModalShare}
                    setIsVisible={setIsVisibleModalShare}
                    handleSave={handleSave}
                    handleShare={handleShare}>
                    {renderCameraRollPermissions(handleSave)}
                </ModalShareYourPhoto>
            </View>
        </>
    );
};
export default CreateShareTemplateScreen;
