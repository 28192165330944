import * as React from 'react';
import {
    ReturnKeyTypeOptions,
    Text,
    TextInput,
    TextInputProps,
    TextStyle,
    View,
    ViewStyle,
} from 'react-native';
import { SvgProps } from 'react-native-svg';

import Colors from '../../constants/Colors';
import { useTheme } from '../../contexts/ThemeContext';
import { isWeb } from '../../helpers/app';
import { stylesWebOutlines } from '../../helpers/style';
import useThemedStyles from '../../hooks/useThemedStyles';
import Icon, { ICON_NAMES } from '../Icons';
import stylesMain from './styles';

export interface IInput extends TextInputProps {
    value?: string;
    error?: string | undefined | boolean | null;
    label?: string | undefined;
    disabled?: boolean;
    placeholder?: string | undefined;
    component?: JSX.Element | undefined;
    stylesContainer?: ViewStyle | undefined;
    placeholderTextColor?: string;
    stylesLabel?: TextStyle | undefined;
    componentType?: 'regular' | 'bottom-sheet';
    returnKeyType?: ReturnKeyTypeOptions | undefined;
    blurOnSubmit?: boolean | undefined;
    onSubmitEditing?: () => void;
    onFocus?: () => void;
    forwardedRef?: any;
    secondLabel?: JSX.Element | string | undefined;
    stylesSecondLabel?: TextStyle | undefined;
    iconName?: ICON_NAMES | null;
    icon?: React.ReactElement<SvgProps>;
    onBlur?: () => void;
    isAvailableExternalStyle?: boolean;
}

const Input: React.FunctionComponent<IInput> = ({
    label = undefined,
    error = undefined,
    disabled = false,
    onChangeText,
    value,
    autoCapitalize = 'none',
    autoCorrect = false,
    placeholder = undefined,
    keyboardType,
    textContentType,
    component = undefined,
    secureTextEntry = false,
    stylesContainer = undefined,
    multiline = false,
    numberOfLines = 1,
    style = undefined,
    stylesLabel = undefined,
    placeholderTextColor,
    isAvailableExternalStyle = false,
    componentType = 'regular',
    returnKeyType = 'default',
    blurOnSubmit = true,
    onSubmitEditing = () => null,
    forwardedRef = undefined,
    secondLabel = undefined,
    stylesSecondLabel = undefined,
    iconName = null,
    onFocus = () => null,
    onBlur = () => null,
    icon,
    ...props
}: IInput) => {
    const styles = useThemedStyles(stylesMain);
    const { theme } = useTheme();
    const [isFocused, setIsFocused] = React.useState<boolean>(false);

    return (
        <View style={[styles.container, stylesContainer]}>
            <View style={styles.labelWrapper}>
                {label && (
                    <Text style={[styles.label, stylesLabel]} numberOfLines={1}>
                        {label}
                    </Text>
                )}
                {typeof secondLabel === 'string' ? (
                    <Text
                        style={[styles.secondLabel, stylesSecondLabel]}
                        numberOfLines={1}>
                        {secondLabel}
                    </Text>
                ) : (
                    secondLabel
                )}
            </View>
            <View style={styles.inputContainer}>
                {(icon || iconName) && (
                    <View style={styles.icon}>
                        {!!icon && icon}
                        {!!iconName && <Icon name={iconName} />}
                    </View>
                )}
                <TextInput
                    ref={forwardedRef}
                    multiline={multiline}
                    editable={!disabled}
                    onBlur={() => {
                        setIsFocused(false);
                        onBlur();
                    }}
                    onFocus={() => {
                        setIsFocused(true);
                        onFocus();
                    }}
                    autoComplete="off"
                    selectionColor={Colors[theme].inputActiveBorder}
                    style={[
                        !isAvailableExternalStyle && styles.input,
                        !isAvailableExternalStyle &&
                            component &&
                            styles.paddingRight,
                        !isAvailableExternalStyle &&
                            isFocused &&
                            styles.inputFocused,
                        !isAvailableExternalStyle && error && styles.inputError,
                        numberOfLines > 1 ? { height: numberOfLines * 15 } : {},
                        isWeb && stylesWebOutlines(),
                        style,
                    ]}
                    onChangeText={onChangeText}
                    value={value}
                    autoCapitalize={autoCapitalize}
                    autoCorrect={autoCorrect}
                    keyboardType={keyboardType}
                    textContentType={textContentType}
                    secureTextEntry={secureTextEntry}
                    numberOfLines={numberOfLines}
                    returnKeyType={returnKeyType}
                    blurOnSubmit={blurOnSubmit}
                    onSubmitEditing={onSubmitEditing}
                    {...(placeholder && { placeholder })}
                    placeholderTextColor={
                        placeholderTextColor || Colors[theme].text.secondary
                    }
                    {...props}
                />
                {component}
            </View>
            {error && error !== true ? (
                <Text style={styles.error}>{error}</Text>
            ) : null}
        </View>
    );
};

export default Input;
