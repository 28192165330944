import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        amountBlock: {
            flexDirection: 'row',
            alignContent: 'center',
            alignItems: 'center',
            borderRadius: 50,
            paddingHorizontal: scale(6),
            paddingVertical: verticalScale(4),
        },

        amount: {
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),
            marginRight: scale(4),
        },
        wonBg: {
            backgroundColor: Colors[theme].backgroundSurfaceStatusSuccess,
        },
        wonText: {
            color: Colors[theme].text.completed,
        },
        lostBg: {
            backgroundColor: Colors[theme].backgroundSurfaceStatusCritical,
        },
        lostText: {
            color: Colors[theme].text.critical,
        },
        waitBg: {
            backgroundColor: Colors[theme].surfaceDecorativeYellowMedium,
        },
        waitText: {
            color: Colors[theme].text.warning,
        },
        elseBg: {
            backgroundColor: Colors[theme].surfaceDecorativeGrayMinimal,
        },
        elseText: {
            color: Colors[theme].text.gray,
        },
    });

export default styles;
