import * as React from 'react';
import { SvgProps } from 'react-native-svg';

import SocialIcons from '@components/Icons/SocialIcons';

export type SocialsTypes = 'instagram' | 'facebook' | 'tiktok' | 'twitter';

export const SOCIALS_IDS = ['instagram', 'facebook', 'tiktok', 'twitter'];
export const SOCIAL_BASE_LINKS = {
    INSTAGRAM: 'https://www.instagram.com/',
    TIKTOK: 'https://www.tiktok.com/@',
    TWITTER: 'https://twitter.com/',
    FACEBOOK: 'https://www.facebook.com/',
};

export interface ISocial {
    id: string;
    text: string;
    icon: React.ReactElement<SvgProps>;
    terms: string[];
}

export const SOCIALS = {
    instagram: {
        id: 'instagram',
        text: 'Instagram',
        icon: <SocialIcons slug="instagram" size={32} />,
        terms: [
            'socialMediaConnect.terms.instagram.one',
            'socialMediaConnect.terms.instagram.two',
            'socialMediaConnect.terms.instagram.three',
        ],
    },
    tiktok: {
        id: 'tiktok',
        text: 'TikTok',
        icon: <SocialIcons slug="tiktok" size={32} />,
        terms: [
            'socialMediaConnect.terms.tikTok.one',
            'socialMediaConnect.terms.tikTok.two',
            'socialMediaConnect.terms.tikTok.three',
        ],
    },
    twitter: {
        id: 'twitter',
        text: 'Twitter',
        icon: <SocialIcons slug="twitter" size={32} />,
        terms: [
            'socialMediaConnect.terms.twitter.one',
            'socialMediaConnect.terms.twitter.two',
            'socialMediaConnect.terms.twitter.three',
        ],
    },
    facebook: {
        id: 'facebook',
        text: 'Facebook',
        icon: <SocialIcons slug="facebook" size={32} />,
        terms: [
            'socialMediaConnect.terms.facebook.one',
            'socialMediaConnect.terms.facebook.two',
            'socialMediaConnect.terms.facebook.three',
            'socialMediaConnect.terms.facebook.four',
        ],
    },
};
