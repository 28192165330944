import React from 'react';

import { useApolloClient } from '@apollo/client';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import VerticalCentredContent from '@components/VerticalCentredContent';
import { isAndroid } from '@helpers/app';

import { GetRevivePaymentsResponse, Pet } from '../../Data/Models';
import { GET_REVIVE_PAYMENTS } from '../../Data/Requests';
import NFTisDead from '../../assets/icons/health/nftIsDead.svg';
import { usePlay } from '../../contexts/PlayContext';
import { isGraphqlError } from '../../helpers/helpers';
import { toastError, toastErrorUnknown } from '../../helpers/toastNotification';
import useThemedStyles from '../../hooks/useThemedStyles';
import i18n from '../../i18n/i18n';
import ROUTES from '../../navigation/routes';
import { useNavigation } from '../../navigation/useNavigation';
import ModalBottom from '../ModalBottom';
import mainStyles from './styles';

interface IProps {
    isVisible: boolean;
    closeModal: () => void;
    navigateBackOnUpgrade?: ROUTES;
    fromScreenModal?: boolean;
    pet: Pet;
    navigateBackFromCheckout?: ROUTES;
}

const ModalNFTisDead = ({
    isVisible,
    closeModal,
    fromScreenModal = false,
    pet,
    navigateBackOnUpgrade,
    navigateBackFromCheckout,
}: IProps) => {
    const styles = useThemedStyles(mainStyles);

    const client = useApolloClient();
    const navigation = useNavigation();

    const { refetchPets } = usePlay();

    const fetchRevivePayments = async () => {
        const result = await client.query<GetRevivePaymentsResponse>({
            query: GET_REVIVE_PAYMENTS,
            variables: {
                petId: pet.id,
            },
        });
        return result.data.revivePayments;
    };

    const onRestorePress = async () => {
        if (fromScreenModal) {
            navigation.pop();
        }
        closeModal();
        try {
            const payments = await fetchRevivePayments();

            navigation.navigate(ROUTES.COLLECTION_PET_REVIVE_CHECKOUT, {
                pet,
                payments,
                navigateBackOnUpgrade,
                navigateBackFromCheckout,
            });
        } catch (error: any) {
            if (isGraphqlError(error, 'PET_WRONG_STATE')) {
                toastError(
                    undefined,
                    i18n.t('petIsDead.errors.PET_WRONG_STATE')
                );
                refetchPets(true);
            } else {
                toastErrorUnknown();
            }
        }
    };

    return (
        <ModalBottom
            modalHeight="75%"
            isVisible={isVisible}
            onClose={closeModal}>
            <VerticalCentredContent style={styles.container}>
                <BlockHeader
                    type="emptyState"
                    emptyStateIcon={<NFTisDead width={215} height={180} />}
                    title={i18n.t('detailedPet.health.modalNFTisDead.title')}
                    text={i18n.t('detailedPet.health.modalNFTisDead.text', {
                        petName: pet.name,
                    })}
                />
            </VerticalCentredContent>
            {pet.reviveAvailable && (
                <ButtonsBottom
                    title={i18n.t('detailedPet.health.modalNFTisDead.button')}
                    onPress={onRestorePress}
                    safe={!isAndroid}
                />
            )}
        </ModalBottom>
    );
};
export default ModalNFTisDead;
