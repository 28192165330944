import * as React from 'react';
import { FlatList } from 'react-native';

import { BanReasonsOutput } from '@Data/Models';
import ModalBottom from '@components/ModalBottom';
import SelectorItem from '@components/SelectorItem';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    selectedBanReason: BanReasonsOutput | undefined;
    banReasons: BanReasonsOutput[];
    onPress: (ban: BanReasonsOutput) => void;
    modalHeight?: number | string;
    close: () => void;
}

const BanReasonSelectModal = ({
    isVisible,
    onPress,
    selectedBanReason,
    banReasons,
    modalHeight = '70%',
    close,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const handleSelect = React.useCallback(
        (ban: BanReasonsOutput) => {
            if (selectedBanReason?.reason === ban.reason) {
                return;
            }
            onPress(ban);
            close();
        },
        [selectedBanReason]
    );

    return (
        <ModalBottom
            isVisible={isVisible}
            onClose={close}
            modalHeight={modalHeight}
            titleText={i18n.t('profile.adminBlockUser.banReason.screenTitle')}>
            <FlatList
                showsVerticalScrollIndicator={false}
                style={styles.listStyle}
                data={banReasons}
                renderItem={({ item }) => (
                    <SelectorItem
                        isSelected={item.reason === selectedBanReason?.reason}
                        text={item.label}
                        handleSelect={() => handleSelect(item)}
                    />
                )}
                keyExtractor={({ reason }) => reason}
            />
        </ModalBottom>
    );
};

export default BanReasonSelectModal;
