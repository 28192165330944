import * as React from 'react';
import { useContext } from 'react';
import { LayoutChangeEvent, ScrollView } from 'react-native';
import { SvgProps } from 'react-native-svg';

import _ from 'lodash';

import { useRanks } from '@contexts/RanksContext';

import CustomModal from '../../../components/CustomModal';
import { STEP_INDICATOR_FLOWS } from '../../../components/FlowStepIndicator';
import { MINT_PET_STAGES_NAME } from '../../../components/FlowStepIndicator/flows/mintNFTStages';
import RankIcon from '../../../components/Icons/RankIcon';
import ImageIguana from '../../../components/ImageIguana';
import { View } from '../../../components/Themed';
import { useDimensions } from '../../../contexts/DimensionsContext';
import { MintContext } from '../../../contexts/MintContext';
import { verticalScale } from '../../../helpers/dimensions';
import { waitForClick } from '../../../helpers/helpers';
import { useImageDraggable } from '../../../hooks/useImageDraggable';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import ROUTES from '../../../navigation/routes';
import { useNavigation } from '../../../navigation/useNavigation';
import BackgroundPicker from '../components/BackgroundPicker';
import DraggableImage from '../components/DraggableImage';
import MintContainer from '../components/MintContainer';
import MintEditPhotoButtons from '../components/MintEditPhotoButtons';
import MintHeaderText from '../components/MintHeaderText';
import ModalLeave from '../components/ModalLeave';
import { SaveEditedPhoto } from './SaveEditedPhoto';
import stylesMain from './styles';

const MintPetEditPhotoScreen = ({ route }: any) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const {
        editPhoto,
        backgrounds,
        background,
        setBackground,
        rank,
        setRank,
        currentFlow,
        setEditPhoto,
        resetPet,
    } = useContext(MintContext);

    const {
        gesture,
        gesturePan,
        gesturePinch,
        animatedStyles,
        imageDefaultStyles,
        calculateImageDataToServer,
        setImageBackgroundSize,
        setPetImageSize,
        imageBackgroundSize,
        zoomPlus,
        zoomMinus,
        calculateEditPhotoSavedSize,
    } = useImageDraggable({
        savedTopPosition: editPhoto?.position?.top,
        savedLeftPosition: editPhoto?.position?.left,
        savedScale: editPhoto?.position?.scale,
    });

    const { safeBottom } = useDimensions();
    const { ranks } = useRanks();
    const [isModalVisible, setModalVisible] = React.useState<boolean>(false);
    const handleCloseModal = () => {
        setModalVisible(false);
    };
    const [isModalLeaveVisible, setModalLeaveVisible] =
        React.useState<boolean>(false);
    const [isImageLoading, setIsImageLoading] = React.useState<boolean>(true);
    const handleCloseLeaveModal = () => {
        setModalLeaveVisible(false);
    };

    const handleGoBack = () => {
        resetPet();
        setModalLeaveVisible(false);
        navigation.goBack();
    };

    const [isModalRankChangeVisible, setModalRankChangeVisible] =
        React.useState<boolean>(false);
    const handleCloseRankChangeModal = () => {
        setModalRankChangeVisible(false);
    };

    const viewLayout = (event: LayoutChangeEvent) => {
        const { width } = event.nativeEvent.layout;
        setImageBackgroundSize({
            width,
            height: width,
        });
    };

    React.useLayoutEffect(() => {
        setPetImageSize({
            width: editPhoto?.position
                ? editPhoto.position.width
                : imageBackgroundSize.width,
            height: editPhoto?.position
                ? editPhoto.position.height
                : imageBackgroundSize.height,
        });
    }, [imageBackgroundSize, editPhoto]);

    const handleBackGroundImage = (id: string) => {
        const backgroundFounded = backgrounds.find((value) => value.id === id);
        if (backgroundFounded) setBackground(backgroundFounded);
    };

    const [firstRightRank, setFirstRightRank] = React.useState(rank);
    const [rankDataModal, setRankDataModal] = React.useState<{
        icon: React.ReactElement<SvgProps>;
        slug: string;
    }>();

    const initialBackground = React.useMemo(() => {
        const isBackgroundFromRank = background?.ranks.find(
            (val) => val === rank?.slug
        );
        return isBackgroundFromRank
            ? background
            : backgrounds[backgrounds.length - 1];
    }, [background, backgrounds, rank]);

    const handleUnlockBackground = (bgRanks: string[]) => {
        const firstRightRankIndex = bgRanks[bgRanks.length - 1];
        const firstRightRank = ranks.find(
            (val) => val.slug === firstRightRankIndex
        );

        setFirstRightRank(firstRightRank || null);

        if (firstRightRank) {
            setRankDataModal({
                icon: <RankIcon slug={firstRightRank.slug} size={64} />,
                slug: _.capitalize(firstRightRank.slug),
            });
            setModalRankChangeVisible(true);
        }
    };
    const handleChangeRank = () => {
        if (firstRightRank) setRank(firstRightRank);
        setModalRankChangeVisible(false);
    };
    const handleDelete = React.useCallback(() => {
        if (currentFlow === 'PHOTO') {
            setModalVisible(false);
            setEditPhoto(null);
            waitForClick().then(() =>
                navigation.replace(ROUTES.MINT_PET_PICK_PHOTO)
            );
            return;
        }
    }, [currentFlow]);
    const handleBinPress = () => {
        setModalVisible(true);
    };
    const handleOnBackPress = () => {
        setModalLeaveVisible(true);
    };

    return (
        <>
            <MintContainer
                icon="leftArrow"
                flow={STEP_INDICATOR_FLOWS.MINT_PET}
                stage={MINT_PET_STAGES_NAME.STEP_TWO}
                stageFill={'50%'}
                onBack={handleOnBackPress}>
                <ScrollView showsHorizontalScrollIndicator={false}>
                    <MintHeaderText
                        title={i18n.t('mintEditPhoto.title')}
                        text={i18n.t('mintEditPhoto.text')}
                    />
                    <View
                        style={[
                            styles.backgroundImageWrapper,
                            { height: imageBackgroundSize.width },
                        ]}
                        onLayout={viewLayout}>
                        {!!initialBackground &&
                            !!editPhoto?.file &&
                            !!imageDefaultStyles.width && (
                                <DraggableImage
                                    onImageLoadEnd={() =>
                                        setIsImageLoading(false)
                                    }
                                    gesture={gesture}
                                    gesturePan={gesturePan}
                                    gesturePinch={gesturePinch}
                                    animatedStyles={animatedStyles}
                                    imageDefaultStyles={imageDefaultStyles}
                                    petImageUri={editPhoto.uri}
                                    backgroundImageUri={
                                        initialBackground?.original
                                    }
                                    imageBackgroundSize={imageBackgroundSize}
                                />
                            )}
                        <MintEditPhotoButtons
                            onPressPlus={zoomPlus}
                            onPressMinus={zoomMinus}
                            onPressBin={handleBinPress}
                        />
                    </View>
                    {backgrounds && rank ? (
                        <View style={styles.carouselWrapper}>
                            <BackgroundPicker
                                currentRank={rank}
                                initialBackground={initialBackground}
                                backgrounds={[...backgrounds]}
                                handleBg={handleBackGroundImage}
                                handleUnlock={handleUnlockBackground}
                            />
                        </View>
                    ) : null}
                </ScrollView>
            </MintContainer>
            {editPhoto?.file && background && (
                <View
                    style={[
                        styles.buttonWrapper,
                        {
                            paddingBottom: verticalScale(safeBottom + 8),
                        },
                    ]}>
                    <SaveEditedPhoto
                        navigation={navigation}
                        loading={isImageLoading}
                        calculateImageDataToServer={calculateImageDataToServer}
                        calculateEditPhotoSavedSize={
                            calculateEditPhotoSavedSize
                        }
                    />
                </View>
            )}
            <CustomModal
                isVisible={isModalVisible}
                icon={<ImageIguana type="attention" />}
                titleText={i18n.t('modal.deletePhoto')}
                infoText={i18n.t('modal.deletePhotoInfo')}
                firstButtonType={'critical'}
                firstButtonText={i18n.t('modal.yesDelete')}
                secondButtonText={i18n.t('modal.noCancel')}
                onFirstButtonClick={handleDelete}
                onSecondButtonClick={handleCloseModal}
            />
            <ModalLeave
                isVisible={isModalLeaveVisible}
                actionFirst={handleCloseLeaveModal}
                actionSecond={handleGoBack}
            />
            {!!rankDataModal && (
                <CustomModal
                    isVisible={isModalRankChangeVisible}
                    icon={rankDataModal.icon}
                    titleText={i18n.t('modal.upgradeRank.title', {
                        slug: rankDataModal.slug,
                    })}
                    infoText={i18n.t('modal.upgradeRank.text')}
                    firstButtonText={i18n.t('modal.upgradeRank.upgradeToRank')}
                    secondButtonText={i18n.t('modal.upgradeRank.cancel')}
                    onFirstButtonClick={handleChangeRank}
                    onSecondButtonClick={handleCloseRankChangeModal}
                />
            )}
        </>
    );
};
export default MintPetEditPhotoScreen;
