import * as React from 'react';

import Fruit from '@assets/icons/mysteryGames/nftRoyalGameMods/fruit.svg';
import Score from '@assets/icons/mysteryGames/nftRoyalGameMods/score.svg';
import { NFT_ROYAL_GAME_MODE } from '@models/mysteryGames';

import { IconType } from '../types';

interface IProps extends IconType {
    slug: NFT_ROYAL_GAME_MODE;
}

const NftRoyalGameModsIcon = ({ slug, size = 48 }: IProps) => {
    switch (slug) {
        case NFT_ROYAL_GAME_MODE.Fruit:
            return <Fruit width={size} height={size} />;
        case NFT_ROYAL_GAME_MODE.Score:
            return <Score width={size} height={size} />;

        default:
            return <></>;
    }
};

export default NftRoyalGameModsIcon;
