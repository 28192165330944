import * as React from 'react';
import { KeyboardAvoidingView } from 'react-native';

import Button from '@components/Button';
import ButtonsBottom from '@components/ButtonsBottom';
import FlowStepIndicator from '@components/FlowStepIndicator';
import PullToRefresh from '@components/PullToRefresh';
import SafeAreaTabContent from '@components/SafeAreaTabContent';
import { View } from '@components/Themed';
import { isIOS } from '@helpers/app';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';

import StakingCardCoin from '../components/StakingCardCoin/StakingCardCoin';
import { useStakingCoinsInputScreen } from './hooks/useStakingCoinsInputScreen';
import StakingCoinsInputScreenRenderItem from './render';
import stylesMain from './styles';

const StakingCoinsInputScreen = () => {
    const styles = useThemedStyles(stylesMain);

    const {
        currentPlan,
        calculateBoostLoading,
        stakingCoin,
        currentStakingPlan,
        isAddMoreCoins,
        onRefresh,
        submitForm,
        values,
        isValid,
        dirty,
        onSkipPress,
        handleChangeAmount,
        handleClickAll,
        handleClickMaxBoost,
        coinWallet,
        balanceReloading,
        stageFill,
        indicatorFlow,
        indicatorStage,
        handleInputFocus,
        handleOnBlur,
        isHideSummary,
        errors,
        openDepositModal,
        closeDepositModal,
        depositModal,
        enoughFunds,
    } = useStakingCoinsInputScreen();

    return (
        <KeyboardAvoidingView
            behavior={isIOS ? 'padding' : 'height'}
            style={styles.container}>
            <SafeAreaTabContent>
                <NavigationBar
                    filled={false}
                    backIcon="leftArrow"
                    title={i18n.t('staking.coinsInputScreen.title')}
                />
                <FlowStepIndicator
                    flow={indicatorFlow}
                    stage={indicatorStage}
                    stageFill={stageFill}
                    isDynamicStageFill={stageFill !== '0%'}
                />
                <PullToRefresh
                    refreshing={balanceReloading}
                    textLoading={i18n.t('pullToRefresh.reloadBalance.text')}
                    onRefresh={() => onRefresh()}
                    renderItem={() => (
                        <StakingCoinsInputScreenRenderItem
                            stakingCoin={stakingCoin}
                            handleInputFocus={handleInputFocus}
                            amount={values.amount}
                            handleOnBlur={handleOnBlur}
                            handleChangeAmount={handleChangeAmount}
                            coinWallet={coinWallet}
                            handleClickAll={handleClickAll}
                            currentPlan={currentPlan}
                            errorAmount={errors.amount}
                            handleClickMaxBoost={handleClickMaxBoost}
                            openDepositModal={openDepositModal}
                            closeDepositModal={closeDepositModal}
                            depositModal={depositModal}
                            showDepositButton={!enoughFunds}
                        />
                    )}
                />

                {!!currentPlan && isHideSummary && (
                    <View style={styles.cardWrapper}>
                        <StakingCardCoin
                            data={currentPlan}
                            coin={stakingCoin}
                            prevStaking={currentStakingPlan?.amountWei}
                        />
                    </View>
                )}

                <ButtonsBottom
                    title={i18n.t('staking.coinsInputScreen.button')}
                    onPress={submitForm}
                    safe
                    disabled={
                        !(isValid && dirty) ||
                        Number(values.amount) === 0 ||
                        calculateBoostLoading
                    }
                    loading={calculateBoostLoading}>
                    {!isAddMoreCoins && !!currentStakingPlan && (
                        <Button
                            containerStyle={styles.gap}
                            title={i18n.t('staking.coinsInputScreen.button2')}
                            onPress={onSkipPress}
                            debouncedPress
                            type="outline"
                            loading={calculateBoostLoading}
                            disabled={calculateBoostLoading}
                        />
                    )}
                </ButtonsBottom>
            </SafeAreaTabContent>
        </KeyboardAvoidingView>
    );
};

export default StakingCoinsInputScreen;
