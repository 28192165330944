import * as React from 'react';
import { Gesture } from 'react-native-gesture-handler';
import {
    Extrapolation,
    interpolate,
    useAnimatedStyle,
    useSharedValue,
} from 'react-native-reanimated';

import { useDimensions } from '@contexts/DimensionsContext';

interface IProps {
    colorPickerHeight: number;
}

export const usePetIdDraggable = ({ colorPickerHeight }: IProps) => {
    const { safeTop, windowWidth, windowHeight } = useDimensions();
    const translateX = useSharedValue(0);
    const translateY = useSharedValue(0);

    const offset = useSharedValue({
        x: 0,
        y: 0,
    });
    const start = useSharedValue({
        x: 0,
        y: 0,
    });

    const petIdGesture = Gesture.Pan()
        .onUpdate((event) => {
            offset.value = {
                x: event.translationX + start.value.x,
                y: event.translationY + start.value.y,
            };
        })
        .onEnd(() => {
            start.value = {
                x: offset.value.x,
                y: offset.value.y,
            };
        });

    const [cardDefaultSize, setCardDefaultPosition] = React.useState({
        x: 0,
        y: 0,
        width: 0,
        height: 0,
    });
    const calculateMovedRegionStart = (defaultPosition: number, out = 0) => {
        'worklet';
        return out - defaultPosition;
    };

    const calculateMovedRegionEnd = (
        containerSize: number,
        defaultImageSize: number,
        defaultPosition: number,
        out = 0
    ) => {
        'worklet';

        return containerSize - defaultImageSize - defaultPosition - out;
    };

    const calculateInputRangeX = React.useCallback(() => {
        'worklet';
        const leftMovedOut = calculateMovedRegionEnd(
            windowWidth,
            cardDefaultSize.width,
            cardDefaultSize.x
        );

        const rightMovedOut = calculateMovedRegionStart(cardDefaultSize.x);

        const start = leftMovedOut > rightMovedOut ? rightMovedOut : 0;
        const end = leftMovedOut > rightMovedOut ? leftMovedOut : 0;

        return [start, end];
    }, [windowWidth, cardDefaultSize]);

    const calculateInputRangeY = React.useCallback(() => {
        'worklet';
        const rightMovedOut = calculateMovedRegionStart(
            cardDefaultSize.y,
            safeTop
        );
        const leftMovedOut = calculateMovedRegionEnd(
            windowHeight,
            cardDefaultSize.height,
            cardDefaultSize.y,
            colorPickerHeight
        );

        const start = leftMovedOut > rightMovedOut ? rightMovedOut : 0;
        const end = leftMovedOut > rightMovedOut ? leftMovedOut : 0;

        return [start, end];
    }, [windowHeight, cardDefaultSize, colorPickerHeight, safeTop]);
    const animatedPetIdStyles = useAnimatedStyle(() => {
        const rangeX = calculateInputRangeX();
        const rangeY = calculateInputRangeY();

        const interpolateX = interpolate(
            offset.value.x,
            rangeX,
            rangeX,
            Extrapolation.CLAMP
        );
        translateX.value = interpolateX;

        const interpolateY = interpolate(
            offset.value.y,
            rangeY,
            rangeY,
            Extrapolation.CLAMP
        );
        translateY.value = interpolateY;

        return {
            transform: [
                { translateX: translateX.value },
                { translateY: translateY.value },
            ],
        };
    });

    return {
        petIdGesture,
        animatedPetIdStyles,
        setCardDefaultPosition,
    };
};
