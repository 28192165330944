import { StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import { scale, verticalScale } from '../../../../helpers/dimensions';
import font from '../../../../helpers/font';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        image: {
            width: scale(120),
            height: scale(120),
            borderRadius: 12,
        },
        imageWrapper: {
            alignItems: 'center',
            marginBottom: verticalScale(20),
        },
        buttons: {
            position: 'absolute',
            right: 0,
            backgroundColor: 'transparent',
            top: verticalScale(-4),
        },
        singleButton: {
            marginBottom: verticalScale(5),
        },
        infoWrapper: { marginTop: verticalScale(4) },
        infoText: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
        },
    });

export default styles;
