export const REGEX_PATTERNS = {
    // petName: /(^\s|\s{2,}.*)|([“‘!#$%&`()*+,-./:;<=>?@[\]^_`{|}~"№«»0-9])/,
    petName: {
        startSpace: /^\s/,
        doubleSpaces: /\s{2,}.*/,
        specialCharacters: /[“‘!#$%&`()*+,-./:;<=>?@[\]^_`{|}~"№«»]/,
        numbers: /[0-9]/,
        allowedCharacter: /^[a-zA-Z\s]*$/,
    },
    verifyId: {
        regex: /^[a-zA-Z0-9]*$/,
    },
    verifySocialAccountName: {
        regex: /^[a-zA-Z0-9._@:\/]*$/,
    },
    verifySocialAdmin: {
        regex: /^[a-zA-Z0-9._@]*$/,
    },
    verifyMarketplaceSearch: {
        regex: /^[a-zA-Z0-9]*$/,
    },
};
