import * as React from 'react';
import { Image, StyleProp, ViewStyle } from 'react-native';

import Participants from '@assets/icons/mysteryGames/LuckyStrike/participants.svg';
import Time from '@assets/icons/mysteryGames/LuckyStrike/time.svg';
import DOWN from '@assets/icons/mysteryGames/TokenTeller/down.svg';
import UP from '@assets/icons/mysteryGames/TokenTeller/up.svg';
import Button from '@components/Button';
import { ICON_NAMES } from '@components/Icons';
import CoinIcon from '@components/Icons/CoinIcon';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT, TYPO_TEXT_COLOR } from '@components/TypoText/types';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { priceString } from '@helpers/payments';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import { useTimer } from '@hooks/useTimer';
import i18n from '@i18n/i18n';
import {
    MYSTERY_GAMES,
    TOKEN_TELLER_GAME_PREDICTION_DIRECTION,
    TOKEN_TELLER_GAME_STATUS,
    TokentellerAvailableGameOutput,
} from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import {
    getTokenTellerAmountOfWei,
    getTokenTellerCoefficient,
    getTokenTellerUserCount,
} from '../../TokenTellerFlow/helpers';
import DecorativeTag from '../DecorativeTag';
import stylesMain from './styles';

interface IProps {
    containerStyle?: StyleProp<ViewStyle>;

    game: TokentellerAvailableGameOutput;
    onPress: (id: string) => void;
    loading?: boolean;
    onEndTime?: () => void;
    secondModalClose?: () => void;
    routeBack: ROUTES;
    inBackground?: boolean;
}

const GAME = MYSTERY_GAMES.tokenteller;

const timeInBg = '00:00:00';

const TokenTellerRound = ({
    containerStyle,
    game,
    loading,
    onPress,
    onEndTime,
    secondModalClose,
    routeBack,
    inBackground,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const navigation = useNavigation();

    const {
        id,
        iguAmountWei,
        userCount,
        status,
        backgroundColor,
        imageUrl,
        title,
        text,
        upCoefficient,
        downCoefficient,
        endDate,
        isPredicted,
    } = game;
    const isClosedRound = React.useMemo(
        () => status === TOKEN_TELLER_GAME_STATUS.Closed,
        [status]
    );

    const { timeText } = useTimer({
        endOfTime: endDate,
        onEndTime: isClosedRound ? undefined : onEndTime,
        delay: 2000,
    });

    const buttonTitle = React.useMemo(() => {
        if (isPredicted) return i18n.t(`HomeScreen.${GAME}.addMoreTokens`);
        return i18n.t(`HomeScreen.${GAME}.predictPrice`);
    }, [isPredicted]);

    const openUsers = React.useCallback(() => {
        navigation.push(ROUTES.TOKEN_TELLER_ALL_PARTICIPANTS, { id });
    }, [id]);

    const onViewPress = React.useCallback(() => {
        secondModalClose?.();
        navigation.push(ROUTES.TOKEN_TELLER_WAITING_FOR_RESULT, {
            id,
            routeBack,
        });
    }, [id, routeBack]);

    const onPricePredict = React.useCallback(() => {
        onPress(game.id);
    }, [game]);

    const { tokenTellerCoefficients } = useMysteryGames();

    const currentUpCoefficient = React.useMemo(
        () =>
            getTokenTellerCoefficient(
                tokenTellerCoefficients,
                id,
                upCoefficient,
                status,
                TOKEN_TELLER_GAME_PREDICTION_DIRECTION.Up
            ),
        [tokenTellerCoefficients, id, upCoefficient, status]
    );
    const currentDownCoefficient = React.useMemo(
        () =>
            getTokenTellerCoefficient(
                tokenTellerCoefficients,
                id,
                downCoefficient,
                status,
                TOKEN_TELLER_GAME_PREDICTION_DIRECTION.Down
            ),
        [tokenTellerCoefficients, id, downCoefficient, status]
    );

    const currentUserCount = React.useMemo(
        () =>
            getTokenTellerUserCount(
                tokenTellerCoefficients,
                id,
                userCount,
                status
            ),
        [tokenTellerCoefficients, id, userCount, status]
    );

    const currentAmountOfWei = React.useMemo(
        () =>
            getTokenTellerAmountOfWei(
                tokenTellerCoefficients,
                id,
                iguAmountWei,
                status
            ),
        [tokenTellerCoefficients, id, iguAmountWei, status]
    );

    return (
        <View style={[styles.container, { backgroundColor }, containerStyle]}>
            <View style={styles.headerBlock}>
                <View style={styles.header}>
                    <TypoText
                        type={TYPO_TEXT.HEADING_H5}
                        colorType={TYPO_TEXT_COLOR.ON_DARK}
                        additionalStyles={styles.textGap}>
                        {title}
                    </TypoText>
                    <TypoText
                        type={TYPO_TEXT.BODY_SMALL}
                        colorType={TYPO_TEXT_COLOR.ON_DARK}>
                        {text}
                    </TypoText>
                </View>

                <Image
                    style={styles.image}
                    resizeMode="contain"
                    source={{ uri: imageUrl }}
                />
            </View>

            <View style={styles.tagsBlock}>
                <DecorativeTag
                    containerStyle={[styles.tag, styles.gap]}
                    icon={<Time width={20} height={20} />}
                    title={inBackground ? timeInBg : timeText}
                />
                <DecorativeTag
                    containerStyle={[styles.tag, styles.gap]}
                    icon={<CoinIcon slug={Coin.vigu} size={20} />}
                    title={priceString(currentAmountOfWei, Coin.vigu)}
                />
                <DecorativeTag
                    onPress={currentUserCount ? openUsers : undefined}
                    containerStyle={[styles.tag, styles.gap]}
                    icon={<Participants width={20} height={20} />}
                    title={`${currentUserCount} ${i18n.t(
                        'general.KEYS.users'
                    )}`}
                />
                <DecorativeTag
                    containerStyle={[styles.tag, styles.gap]}
                    icon={<UP width={20} height={20} />}
                    title={`${currentUpCoefficient}X ${i18n.t(
                        `HomeScreen.${GAME}.${TOKEN_TELLER_GAME_PREDICTION_DIRECTION.Up}`
                    )}`}
                />
                <DecorativeTag
                    containerStyle={styles.tag}
                    icon={<DOWN width={20} height={20} />}
                    title={`${currentDownCoefficient}X ${i18n.t(
                        `HomeScreen.${GAME}.${TOKEN_TELLER_GAME_PREDICTION_DIRECTION.Down}`
                    )}`}
                />
            </View>
            {!isClosedRound && (
                <Button
                    disabled={loading}
                    loading={loading}
                    size="md"
                    isOneColor
                    type="outline"
                    onPress={onPricePredict}
                    title={buttonTitle}
                    debouncedPress
                    containerStyle={styles.button}
                />
            )}

            {!!isPredicted && (
                <Button
                    disabled={loading}
                    size="md"
                    isOneColor
                    type="text"
                    onPress={onViewPress}
                    iconName={ICON_NAMES.ARROW_RIGHT_SECONDARY}
                    iconColor={styles.viewButtonColor.color}
                    iconDirection="right"
                    iconSize={20}
                    title={i18n.t('general.BUTTONS.view')}
                    debouncedPress
                    textStyles={styles.viewButtonColor}
                    containerStyle={styles.viewButton}
                />
            )}
        </View>
    );
};
export default React.memo(TokenTellerRound);
