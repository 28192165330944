import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            backgroundColor: Colors[theme].controlSecondary,
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            borderStyle: 'dashed',
            marginBottom: verticalScale(16),
        },
        rank: {
            position: 'absolute',
            width: scale(32),
            height: scale(32),
            borderRadius: 1000,
            top: verticalScale(6),
            left: scale(6.5),
            padding: scale(4),
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
        },
    });

export default styles;
