import { StyleSheet } from 'react-native';

import Colors from '../../../../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../../../../helpers/dimensions';
import { theme } from '../../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
        },
        buttonWrapper: {
            width: '100%',
            marginBottom: verticalScale(20),
        },
        button: {
            marginBottom: verticalScale(16),
        },
        buttonSmall: {
            flex: 1,
            marginHorizontal: scale(5),
        },
        buttonWrapperSmall: {
            flexDirection: 'row',
            marginBottom: verticalScale(20),
        },
        buttonBottomWrapper: {
            paddingHorizontal: 0,
        },
    });

export default styles;
