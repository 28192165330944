import * as React from 'react';
import { SafeAreaView, ScrollView } from 'react-native';

import { useUser } from '@contexts/UserContext';

import WalletImportIcon from '../../../assets/icons/createImportWallet/walletImport.svg';
import Avatar from '../../../components/Avatar';
import Button from '../../../components/Button';
import { Text, View } from '../../../components/Themed';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import NavigationBar from '../../../navigation/NavigationBar';
import ROUTES from '../../../navigation/routes';
import { useNavigation } from '../../../navigation/useNavigation';
import Slider from '../components/Slider';
import stylesMain from './styles';

const AddWalletInitialScreen: React.FunctionComponent = () => {
    const navigation = useNavigation();
    const styles = useThemedStyles(stylesMain);
    const { user, petsCount, logout } = useUser();
    const canCreateWallet = !user?.walletAddress;

    const handlePressCreate = () => {
        navigation.navigate(ROUTES.CREATE_WALLET_FIRST);
    };
    const handlePressImport = () => {
        navigation.navigate(ROUTES.IMPORT_WALLET);
    };

    const renderCreateWallet = () => (
        <>
            <Slider />
            <View style={styles.buttonContainer}>
                <Button
                    title={i18n.t('createWalletInit.createButton')}
                    onPress={handlePressCreate}
                    containerStyle={styles.buttonGap}
                />
                <Button
                    title={i18n.t('createWalletInit.importButton')}
                    onPress={handlePressImport}
                    type="outline"
                />
            </View>
        </>
    );

    const renderImportWallet = () => (
        <>
            <ScrollView contentContainerStyle={styles.scrollViewContent}>
                <WalletImportIcon />
                <Text style={styles.title}>
                    {i18n.t('importWalletInit.title')}
                </Text>
                <Text style={styles.text}>
                    {i18n.t('importWalletInit.text')}
                </Text>
                <View style={styles.userWrapper}>
                    <Avatar size={48} />
                    <View style={styles.userInfo}>
                        <Text style={styles.textEmail}>{user?.email}</Text>
                        <Text style={styles.textPets}>
                            {i18n.t('importWalletInit.pets')}
                            <Text style={styles.textPetsCount}>
                                {`: ${petsCount}`}
                            </Text>
                        </Text>
                    </View>
                </View>
            </ScrollView>
            <View style={styles.buttonContainer}>
                <Button
                    title={i18n.t('importWalletInit.import')}
                    onPress={handlePressImport}
                />
            </View>
        </>
    );

    return (
        <>
            <NavigationBar filled={false} wallet={false} onBack={logout} />
            <View style={styles.wrapper}>
                {canCreateWallet ? renderCreateWallet() : renderImportWallet()}
            </View>
            <SafeAreaView style={styles.safe} />
        </>
    );
};

export default AddWalletInitialScreen;
