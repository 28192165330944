import * as React from 'react';
import { SafeAreaView } from 'react-native';

import Button from '@components/Button';
import ImageIguana from '@components/ImageIguana';
import ModalLoader from '@components/ModalLoader';
import PlayEnergy from '@components/PlayEnergy';
import { View } from '@components/Themed';
import { PlayContext } from '@contexts/PlayContext';
import useVerifyTask from '@hooks/tasks/useVerifyTask';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';

import EmptyStatePlay from '../../components/EmptyStatePlay';
import stylesMain from './styles';

export const VerifyUserAlreadyCompletedScreen = () => {
    const styles = useThemedStyles(stylesMain);
    const { verifyTask } = React.useContext(PlayContext);
    const {
        verifyActionStatusButton,
        createVerificationTaskLoading,
        canStartNextTask,
    } = useVerifyTask();

    return (
        <>
            <NavigationBar backIcon={'close'} containerStyle={styles.navBg} />
            <View style={styles.main}>
                <EmptyStatePlay
                    icon={<ImageIguana type={'cry'} size={'big'} />}
                    title={i18n.t(
                        'socializeToEarn.verifyUser.verifyAlreadyCompleted.title'
                    )}
                    text={i18n.t(
                        'socializeToEarn.verifyUser.verifyAlreadyCompleted.text'
                    )}
                />
                {!!verifyTask && (
                    <PlayEnergy
                        energy={verifyTask.totalEnergyRewarded || 0}
                        maxEnergy={verifyTask.maxEnergyReward}
                        containerStyle={styles.energyWrapper}
                    />
                )}
            </View>
            {!!verifyTask && (
                <View style={styles.buttonWrapper}>
                    <Button
                        title={
                            canStartNextTask
                                ? i18n.t(
                                      'socializeToEarn.verifyUser.verifyAlreadyCompleted.button1'
                                  )
                                : i18n.t(
                                      'socializeToEarn.verifyUser.verifyAlreadyCompleted.button2'
                                  )
                        }
                        onPress={verifyActionStatusButton}
                        debouncedPress
                        containerStyle={styles.gap}
                    />
                </View>
            )}
            <ModalLoader
                isVisible={createVerificationTaskLoading}
                text={i18n.t('general.loading')}
            />
            <SafeAreaView style={styles.safe} />
        </>
    );
};
export default VerifyUserAlreadyCompletedScreen;
