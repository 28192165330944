import React from 'react';
import { View } from 'react-native';

import { MarketplaceNftDetailsPetOutput } from '@models/marketplace';

import RecognitionResults from '../../../../../components/RecognitionResults';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import DetailsBlock from '../../../Collection/components/DetailsBlock';
import Styles from './styles';

interface IProps {
    pet: MarketplaceNftDetailsPetOutput;
}
const OverviewAnimalTypeDetailsBlock = ({ pet }: IProps) => {
    const styles = useThemedStyles(Styles);

    const { nftDetectionResult } = pet;

    return nftDetectionResult ? (
        <DetailsBlock
            title={i18n.t('detailedPet.animalType.title')}
            description={i18n.t('detailedPet.animalType.text')}>
            <View style={styles.metadataContainer}>
                {nftDetectionResult && (
                    <RecognitionResults detectionResult={nftDetectionResult} />
                )}
            </View>
        </DetailsBlock>
    ) : (
        <></>
    );
};
export default OverviewAnimalTypeDetailsBlock;
