import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import Slots from '@assets/icons/mysteryGames/LuckyStrike/slots.svg';
import CoinIcon from '@components/Icons/CoinIcon';
import { View } from '@components/Themed';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { statisticsFormatter } from '@helpers/helpers';
import { priceString } from '@helpers/payments';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import GamesStatsInfoCard from '../GamesStatsInfoCard';
import stylesMain from './styles';

interface IProps {
    customStyle?: StyleProp<ViewStyle>;
    totalSlots: number | undefined;
    totalAmount: string | undefined;
    coin?: Coin;
}

const LuckyStrikeFeatures = ({
    customStyle,
    totalAmount,
    totalSlots,
    coin = Coin.vigu,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={[styles.features, customStyle]}>
            <GamesStatsInfoCard
                containerStyle={styles.feature}
                onLight
                title={i18n.t(`HomeScreen.GamesFeatures.turnover`, {
                    coin,
                })}
                amount={priceString(
                    statisticsFormatter(
                        Number(balanceFromWei(totalAmount).value)
                    ),
                    coin
                )}
                icon={<CoinIcon slug={coin} size={32} />}
            />
            <GamesStatsInfoCard
                onLight
                containerStyle={styles.feature}
                title={i18n.t(`HomeScreen.GamesFeatures.totalSlots`)}
                amount={`${statisticsFormatter(totalSlots || 0)}`}
                icon={<Slots width={32} height={32} />}
            />
        </View>
    );
};

export default LuckyStrikeFeatures;
