import { useCallback } from 'react';

import { Wallet, ethers } from 'ethers';

import { User } from '../../Data/Models';
import ErrorReporting from '../../utils/ErrorReporting';
import SecureStore from '../../utils/SecureStore';

const useGetWalletFromStore = (
    user: User | undefined,
    provider: ethers.providers.JsonRpcProvider | undefined
) => {
    const saveWallet = useCallback(
        async (mnemonicValue: string, privateKeyValue: string) => {
            if (!user) {
                Console.log('[useGetWalletFromStore] saveWallet no user');
                return undefined;
            }

            return saveWalletInfo(user, mnemonicValue, privateKeyValue);
        },
        [user]
    );

    const clearWalletInfo = useCallback(async () => {
        if (!user) {
            Console.log('[useGetWalletFromStore] clearWalletInfo no user');
            return false;
        }

        Console.log('[useGetWalletFromStore] Clearing wallet');

        await saveToStorage(getStorageKey(user.id, 'private_key'), '');
        await saveToStorage(getStorageKey(user.id, 'mnemonic'), '');

        Console.log('[useGetWalletFromStore] Wallet cleared');

        return true;
    }, [user]);

    const getMnemonic = useCallback(async () => {
        if (!user) {
            Console.log('[useGetWalletFromStore] getMnemonic no user');
            return;
        }

        return getFromStorage(getStorageKey(user.id, 'mnemonic'));
    }, [user]);

    const getPrivateKey = useCallback(async () => {
        if (!user) {
            Console.log('[useGetWalletFromStore] getPrivateKey no user');
            return undefined;
        }

        return getFromStorage(getStorageKey(user.id, 'private_key'));
    }, [user]);

    // Load wallet from private key or mnemonic
    const loadWalletFromPrivateKeyOrMnemonic =
        useCallback(async (): Promise<Wallet | null> => {
            if (!provider) {
                Console.log(
                    '[loadWalletFromPrivateKeyOrMnemonic] No provider, skipping'
                );
                return null;
            }
            const privateKey = await getPrivateKey();

            /*Console.log(
                `[loadWalletFromPrivateKeyOrMnemonic] Private key from store=${privateKey}. Loading from Private key..`
            );*/

            if (privateKey) {
                try {
                    return createWalletFromPrivateKey(privateKey, provider);
                } catch (error) {
                    Console.log(
                        '[useGetWalletFromStore] Cant load wallet from privateKey. Fallback to mnemonic..',
                        error
                    );

                    // fallback try to get from mnemonic
                    const mnemonic = await getMnemonic();
                    if (mnemonic) {
                        return createWalletFromMnemonic(mnemonic, provider);
                    }

                    return null;
                }
            }

            const mnemonic = await getMnemonic();

            /*Console.log(
                `[loadWalletFromPrivateKeyOrMnemonic] mnemonic from store=${mnemonic}. Loading from mnemonic..`
            );*/

            if (mnemonic) {
                return createWalletFromMnemonic(mnemonic, provider);
            }

            return null;
        }, [provider, getPrivateKey, getMnemonic]);

    return {
        saveWallet,
        clearWalletInfo,
        getMnemonic,
        getPrivateKey,
        loadWalletFromPrivateKeyOrMnemonic,
    };
};

async function saveWalletInfo(
    user: User | undefined,
    mnemonic: string,
    privateKey: string
) {
    if (!user) {
        Console.log('[useGetWalletFromStore] saveWalletInfo no user');
        return false;
    }

    /*Console.log(
        `[useGetWalletFromStore] Saving wallet with mnemonic=${mnemonic}, privateKey=${privateKey}`
    );*/

    return Promise.all([
        mnemonic
            ? saveToStorage(getStorageKey(user.id, 'mnemonic'), mnemonic)
            : Promise.resolve(),
        privateKey
            ? saveToStorage(getStorageKey(user.id, 'private_key'), privateKey)
            : Promise.resolve(),
    ]);
}

async function getFromStorage(key: string) {
    try {
        return SecureStore.getItemAsync(key);
    } catch (error) {
        Console.error('[useGetWalletFromStore] getFromStorage', error);
        ErrorReporting.report(error);
    }

    return undefined;
}

async function saveToStorage(key: string, value: string) {
    try {
        return SecureStore.setItemAsync(key, value);
    } catch (error) {
        Console.error('[useGetWalletFromStore] saveToStorage', error);
        ErrorReporting.report(error);
    }

    return undefined;
}

export function createWalletFromMnemonic(
    mnemonic: string,
    provider: ethers.providers.JsonRpcProvider
): Wallet {
    return Wallet.fromMnemonic(mnemonic).connect(provider);
}

export function createWalletFromPrivateKey(
    privateKey: string,
    provider: ethers.providers.JsonRpcProvider
): Wallet {
    return new Wallet(privateKey, provider);
}

// we store private_key without specifying it, and mnemonic with specifying key
function getStorageKey(identifier: string, type: 'mnemonic' | 'private_key') {
    return `WALLET_KEY${type === 'mnemonic' ? `-${type}-` : ''}${identifier}`;
}

export default useGetWalletFromStore;
