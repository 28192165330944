import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { scale, verticalScale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            alignSelf: 'flex-start',
            paddingHorizontal: scale(8),
            paddingVertical: verticalScale(6),
            backgroundColor: Colors[theme].backgroundCritical,
            borderRadius: 50,
            alignItems: 'center',
            flexDirection: 'row',
        },
        text: {
            color: Colors[theme].text.critical,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
            marginLeft: scale(6),
        },
    });

export default styles;
