import * as React from 'react';
import { FlatList } from 'react-native';

import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import { TokentellerGameHistoryOutput } from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import GamesHistoryButtons from '../GamesHistoryButtons';
import TokenTellerGameHistoryInfo from '../TokenTellerGameHistoryInfo';
import TokenTellerHistoryBlockHeader from '../TokenTellerHistoryBlockHeader';
import stylesMain from './styles';

interface IProps {
    history: TokentellerGameHistoryOutput;
    force?: boolean | undefined;
    id: string;
}

const TokenTellerHistoryWrapper = ({ history, force, id }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const navigateToGame = () => {
        navigation.navigate(ROUTES.TOKEN_TELLER_MAIN);
    };
    const backToHistory = () => {
        navigation.navigate(ROUTES.TOKEN_TELLER_HISTORY);
    };

    const retry = () => {
        navigateToGame();
    };

    const { status, winDirection, userDirection, userAffectedIguAmountWei } =
        history;

    const isUserWinner = React.useMemo(
        () => winDirection === userDirection,
        [winDirection, userDirection]
    );

    const { isVisible, close } = useVisibleHook(true);

    const renderFirstState = () => {
        if (force) {
            return null;
        }

        return (
            <View style={[styles.firstState, styles[status]]}>
                <View style={styles.block}>
                    <TokenTellerHistoryBlockHeader
                        status={status}
                        iguAmountWei={userAffectedIguAmountWei}
                        isBigHeader
                    />
                </View>
                <GamesHistoryButtons
                    firstButtonAction={!isUserWinner ? retry : navigateToGame}
                    firstButtonTitle={
                        !isUserWinner
                            ? i18n.t('general.BUTTONS.tryAgain')
                            : i18n.t('general.BUTTONS.exploreMoreGames')
                    }
                    secondButtonTitle={i18n.t('general.BUTTONS.viewStatistics')}
                    secondButtonAction={close}
                    firstButtonType="outline"
                    isOneTheme
                    white
                />
            </View>
        );
    };

    return (
        <View style={styles.safe}>
            {isVisible && renderFirstState()}
            <View style={styles.main}>
                <FlatList
                    showsVerticalScrollIndicator={false}
                    style={styles.container}
                    ListHeaderComponent={
                        <>
                            <TokenTellerHistoryBlockHeader
                                status={status}
                                iguAmountWei={userAffectedIguAmountWei}
                            />
                            <TokenTellerGameHistoryInfo
                                containerStyle={styles.gap}
                                data={history}
                                gameId={id}
                            />
                        </>
                    }
                    data={null}
                    renderItem={null}
                />
            </View>
            <GamesHistoryButtons
                firstButtonAction={navigateToGame}
                secondButtonAction={backToHistory}
            />
        </View>
    );
};

export default TokenTellerHistoryWrapper;
