import { StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../../helpers/dimensions';
import font from '../../../../helpers/font';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            backgroundColor: Colors[theme].background,
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            paddingHorizontal: scale(16),
            paddingLeft: scale(12),
            paddingVertical: verticalScale(16),
            marginBottom: verticalScale(4),
        },
        shadow: {
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].shadow.default,
        },
        selectedShadow: {
            backgroundColor: Colors[theme].shadow.selected,
        },
        selected: {
            backgroundColor: Colors[theme].backgroundSelectable,
            borderColor: Colors[theme].border.accent,
        },
        wrapper: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        textWrapper: {
            marginLeft: scale(12),
            flex: 1,
        },
        title: {
            marginBottom: verticalScale(2),
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
        },
        infoTitle: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
        },
        infoWrapper: {},
        alertCard: {
            alignSelf: 'flex-start',
            paddingHorizontal: scale(6),

            paddingVertical: verticalScale(4),

            borderRadius: 50,
        },
        disabled: {
            opacity: 0.7,
        },
        gap: {
            marginTop: verticalScale(28),
            marginBottom: verticalScale(6),
        },
        alertCardWrapper: {
            position: 'absolute',

            top: verticalScale(16),
        },
        textStyles: {
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),
        },
    });

export default styles;
