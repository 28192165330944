import * as React from 'react';

import Button from '@components/Button';
import InfoIcon from '@components/InfoIcon/InfoIcon';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT } from '@components/TypoText/types';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import { UserAchievementOutput } from '@models/achievements';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import Achievement from '../Achievement';
import WhatIsAchievementsModal from '../WhatIsAchievementsModal';
import stylesMain from './styles';

interface IProps {
    achievements: UserAchievementOutput[];
}

const RecentlyAchievements = ({ achievements }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const navigation = useNavigation();

    const navigateToAchievements = () => {
        navigation.push(ROUTES.ACHIEVEMENTS);
    };

    const { isVisible, open, close } = useVisibleHook();

    const onAction = () => {
        close();
        navigateToAchievements();
    };

    return (
        <View style={styles.main}>
            <View style={styles.titleBlock}>
                <View style={styles.title}>
                    <TypoText
                        type={TYPO_TEXT.HEADING_H6}
                        additionalStyles={styles.gap}>
                        {i18n.t('Achievements.title')}
                    </TypoText>
                    <InfoIcon onPress={open} />
                </View>
                <Button
                    title={i18n.t('wallet.nft.viewAll')}
                    type="text"
                    debouncedPress
                    onPress={navigateToAchievements}
                />
            </View>
            <View style={styles.listBlock}>
                {achievements.map((i, index) => (
                    <Achievement
                        key={i.type}
                        achievement={i}
                        isLast={index === achievements.length - 1}
                    />
                ))}
            </View>
            <WhatIsAchievementsModal
                isVisible={isVisible}
                onClose={close}
                onAction={onAction}
            />
        </View>
    );
};
export default RecentlyAchievements;
