import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import Colors from '../../constants/Colors';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        buttonWrapper: {
            paddingHorizontal: scale(20),
            paddingTop: verticalScale(16),
            paddingBottom: verticalScale(16),
            backgroundColor: Colors[theme].background,
        },
    });

export default styles;
