import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(12),
            paddingVertical: scale(12),
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: verticalScale(12),
            backgroundColor: 'transparent',
        },
        left: {
            flexDirection: 'row',
            marginRight: scale(22),
            flex: 1,
            backgroundColor: 'transparent',
        },
        imageWrapper: {
            marginRight: scale(12),
            width: scale(40),
            height: scale(40),
            borderRadius: moderateScale(6),
            backgroundColor: 'transparent',
        },
        addressBlock: {
            flex: 1,
            backgroundColor: 'transparent',
        },
        address: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.16,
            marginBottom: verticalScale(2),
        },
        dateText: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
        },
        right: {
            maxWidth: '35%',
            backgroundColor: 'transparent',
        },
        amountBlock: {
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginBottom: verticalScale(4),
            backgroundColor: 'transparent',
        },

        amount: {
            color: Colors[theme].text.primary,
            ...font({
                size: 13,
                height: 18,
                weight: '700',
            }),
            marginRight: verticalScale(4),
            textAlign: 'right',
        },
        usd: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 12,
                height: 16,
                weight: '600',
            }),
            letterSpacing: 0.08,
            textAlign: 'right',
        },
    });

export default styles;
