import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { LinearGradient } from 'expo-linear-gradient';

import SpaceId from '@assets/icons/wallet/spaceId.svg';
import Button from '@components/Button';
import StakingCardIcon from '@components/Icons/StakingCardIcon';
import { Text, View } from '@components/Themed';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import stylesMain from './styles';

interface IProps {
    getHeight?: (height: number) => void;
    itemWidth?: number;
    minHeight?: number;
    containerStyle?: StyleProp<ViewStyle>;
}

const SpaceIdCard = ({
    getHeight,
    itemWidth,
    minHeight,
    containerStyle,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const navigation = useNavigation();

    const onPress = () => {
        navigation.navigate(ROUTES.HOW_TO_PURCHASE_SPACE_ID);
    };

    return (
        <LinearGradient
            colors={['#354A4A', '#101B3C']}
            style={[
                styles.gradientBackground,
                { width: itemWidth, minHeight },
                containerStyle,
            ]}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 1 }}
            onLayout={({ nativeEvent }) => {
                getHeight?.(nativeEvent.layout.height);
            }}>
            <View style={styles.texts}>
                <Text style={styles.title}>{i18n.t('SpaceId.card.title')}</Text>
                <Text style={styles.text} numberOfLines={3}>
                    {i18n.t('SpaceId.card.text')}
                </Text>
            </View>
            <Button
                title={i18n.t('general.BUTTONS.learnMore')}
                type="outline"
                size="md"
                onPress={onPress}
                containerStyle={styles.button}
                debouncedPress
                isOneColor
            />

            <SpaceId
                width={106}
                height={118}
                style={styles.referralCodeImage}
            />
        </LinearGradient>
    );
};
export default SpaceIdCard;
