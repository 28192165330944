import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale } from '@helpers/dimensions';

import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            borderRadius: 1000,
            borderWidth: 1,
            borderColor: Colors[theme].icon.green,
            marginHorizontal: scale(6),
            alignItems: 'center',
            justifyContent: 'center',
        },
        dot: {
            borderRadius: 1000,
            width: moderateScale(12),
            height: moderateScale(12),
            margin: moderateScale(4),
            borderColor: Colors[theme].icon.green,
        },
        input: {
            position: 'absolute',
            top: -500,
        },
    });

export default styles;
