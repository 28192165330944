import * as React from 'react';

import Button from '../../components/Button';
import { ICON_NAMES } from '../../components/Icons';
import { View } from '../../components/Themed';
import useThemedStyles from '../../hooks/useThemedStyles';
import stylesMain from './styles';

interface IProps {
    onPressPlus: () => void;
    onPressMinus: () => void;
}

const ZoomButtons = ({ onPressPlus, onPressMinus }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    return (
        <View style={styles.buttonsContainer}>
            <View style={styles.button}>
                <Button
                    type="outline"
                    iconName={ICON_NAMES.PLUS}
                    size={'md'}
                    onPress={onPressPlus}
                />
            </View>
            <View style={styles.button}>
                <Button
                    type="outline"
                    iconName={ICON_NAMES.MINUS}
                    size={'md'}
                    onPress={onPressMinus}
                />
            </View>
        </View>
    );
};
export default ZoomButtons;
