import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { KeyboardAvoidingView, SafeAreaView, TextInput } from 'react-native';

import { useFormik } from 'formik';
import { debounce } from 'lodash';
import * as Yup from 'yup';

import Button from '@components/Button';
import Divider from '@components/Divider';
import Icon, { ICON_NAMES } from '@components/Icons';
import ModalBottom from '@components/ModalBottom';
import ModalCoinSelect from '@components/ModalCoinSelect';
import ModalConfirmTransaction from '@components/ModalConfirmTransaction';
import ModalDeposit from '@components/ModalDeposit';
import ModalLoader from '@components/ModalLoader';
import PullToRefresh from '@components/PullToRefresh';
import { Text, View } from '@components/Themed';
import ToastNotification from '@components/ToastNotification';
import TransactionSummary from '@components/TransactionSummary';
import { useKeyboard } from '@contexts/KeyboardContext';
import { tradeConfig, useTrade } from '@contexts/Trade/TradeContext';
import { useWallet } from '@contexts/Wallet/WalletContext';
import {
    Coin,
    CoinBalance,
    WalletBalance,
    getMaxValue,
} from '@contexts/Wallet/WalletHelpers';
import { isAndroid, isDesktop, isIOS } from '@helpers/app';
import { toastError } from '@helpers/toastNotification';
import {
    exchangeRateNumberFormatter,
    feeWithCurrency,
    inputNumberFormatter,
    isBiggerThanMax,
    numberFormatter,
} from '@helpers/wallet';
import useBlockchainErrorsHandler from '@hooks/useBlockchainErrorsHandler';
import useFeeInfo from '@hooks/useFeeInfo';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import { TradeMainProps } from '../../../../types';
import BiswapLogo from '../components/BiswapLogo';
import TradeCoinInput from '../components/TradeCoinInput';
import TradeSetting from '../components/TradeSetting';
import stylesMain from './styles';

const screenConfig = {
    debounceInterval: 400,
    quickToastVisibilityInterval: 2000,
    toastVisibilityInterval: 10000,
};

interface ITrade {
    value: string;
    tradeFrom?: CoinBalance;
    tradeTo?: CoinBalance;
    isUpdate?: boolean;
}

const TradeMainScreen = ({ route }: TradeMainProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const {
        getAmountsOut,
        getAmountsIn,
        handleSwap,
        mainToken,
        tradeTokens,
        getMinTradeAmount,
    } = useTrade();
    const {
        walletBalance,
        reloadBalance,
        reloadExchanges,
        fee,
        balanceReloading,
        priceInUsd,
    } = useWallet();
    const balance = route.params?.balance || mainToken;

    const [tradeFromAvailableToken, setTradeFromAvailableToken] =
        React.useState<CoinBalance>();
    const [tradeToAvailableToken, setTradeToAvailableToken] =
        React.useState<CoinBalance>();

    const [isVisibleModalTradeFrom, setIsVisibleModalTradeFrom] =
        React.useState<boolean>(false);
    const [isVisibleModalTradeTo, setIsVisibleModalTradeTo] =
        React.useState<boolean>(false);
    const [isVisibleModalDeposit, setIsVisibleModalDeposit] =
        React.useState<boolean>(false);

    const [isVisibleModalSetting, setIsVisibleModalSetting] =
        React.useState<boolean>(false);

    const {
        isVisible: isVisibleConfirmationModal,
        close: closeConfirmationModal,
        open: openConfirmationModal,
    } = useVisibleHook();

    // fee info modalScreen
    const { renderFeeInfoIcon } = useFeeInfo(
        closeConfirmationModal,
        openConfirmationModal
    );

    const [isVisibleError, setIsVisibleError] = React.useState<boolean>(true);
    const [estimatedField, setEstimatedField] = React.useState<string>('to');
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isLoadingEstimate, setIsLoadingEstimate] =
        React.useState<boolean>(false);
    const [disableButton, setDisableButton] = React.useState<boolean>(false);
    const [fromTokenUsdPrice, setFromTokenUsdPrice] =
        React.useState<string>('0.00');
    const [toTokenUsdPrice, setToTokenUsdPrice] =
        React.useState<string>('0.00');
    const [estimatedRate, setEstimatedRate] = React.useState<string>('0.00');

    // keyboardIsVisible
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [focusedIndex, setFocusedIndex] = useState<number>(0);
    const { isKeyboardVisible } = useKeyboard();
    // use blockchain errors
    const { handleBlockchainErrors, renderBlockchainErrorsModal } =
        useBlockchainErrorsHandler(
            () => {
                setDisableButton(true);
            },
            () => {
                setDisableButton(false);
            }
        );

    const inputsRef = React.useRef<React.RefObject<TextInput>[]>([
        React.createRef<TextInput>(),
        React.createRef<TextInput>(),
    ]);
    const [isSummaryHide, setIsSummaryHide] = React.useState<boolean>(false);

    const estimatedTransactionFee = React.useMemo(() => {
        if (!tradeFromAvailableToken?.name) {
            return '';
        }
        return fee?.trade[tradeFromAvailableToken.name];
    }, [tradeFromAvailableToken, fee]);

    const isBNBEnough = React.useMemo(() => {
        return Number(mainToken?.value) >= Number(estimatedTransactionFee);
    }, [estimatedTransactionFee, mainToken]);

    const baseSchema = Yup.string().required(i18n.t('trade.errors.isRequired'));

    const bnbSchema = Yup.object({
        from: baseSchema
            .test(
                'maxAmount',
                i18n.t('trade.errors.maxAmountError'),
                (value) => {
                    return (
                        Number(value) <=
                        Number(tradeFromAvailableToken?.value) -
                            Number(estimatedTransactionFee)
                    );
                }
            )
            .test(
                'minLimit',
                i18n.t('trade.errors.minLimit', {
                    limit: tradeConfig.minTradeAmounts.BNB,
                    coin: 'BNB',
                }),
                (value) => Number(value) >= tradeConfig.minTradeAmounts.BNB
            ),
    });

    const tokenSchema = Yup.object({
        from: baseSchema
            .test(
                'maxAmount',
                i18n.t('trade.errors.maxAmountError'),
                (value) =>
                    Number(value) <= Number(tradeFromAvailableToken?.value)
            )
            .test(
                'minLimit',
                i18n.t('trade.errors.minLimit', {
                    limit: getMinTradeAmount(tradeFromAvailableToken?.name),
                    coin: tradeFromAvailableToken?.name,
                }),
                (value) =>
                    Number(value) >=
                    getMinTradeAmount(tradeFromAvailableToken?.name)
            )
            .test(
                'bnbNotEnough',
                i18n.t('trade.errors.bnbNotEnough'),
                () => isBNBEnough
            ),
    });

    const formikData = useFormik({
        initialValues: {
            from: '0.0',
            to: '0.0',
        },
        validationSchema:
            tradeFromAvailableToken?.name === mainToken?.name
                ? bnbSchema
                : tokenSchema,
        onSubmit: () => openConfirmationModal(),
        enableReinitialize: true,
    });

    const getValue = (value: string) => {
        return value === '0.0' || value === '0' ? '' : value;
    };

    const { submitForm, values, errors, setFieldValue, isValid, dirty } =
        formikData;

    // watch token pairs
    React.useEffect(() => {
        if (
            !walletBalance ||
            !balance ||
            (tradeToAvailableToken && tradeFromAvailableToken)
        ) {
            return;
        }

        const firstAvailableToken = tradeTokens[0];

        // if token is available for trading return it, otherwise return first available
        const tokenBalance = [...tradeTokens, mainToken].find(
            (item) => item?.name === balance?.name
        )
            ? balance
            : firstAvailableToken;

        setTradeFromAvailableToken(tokenBalance);

        if (tokenBalance?.name === 'BNB') {
            setTradeToAvailableToken(firstAvailableToken);
        } else {
            setTradeToAvailableToken(mainToken);
        }
    }, [
        balance,
        walletBalance,
        tradeFromAvailableToken,
        tradeToAvailableToken,
        tradeTokens,
        mainToken,
    ]);

    // watch USD price
    useEffect(() => {
        if (tradeFromAvailableToken) {
            setFromTokenUsdPrice(
                priceInUsd(
                    tradeFromAvailableToken.name,
                    numberFormatter(values.from)
                )
            );
        }

        if (tradeToAvailableToken) {
            setToTokenUsdPrice(
                priceInUsd(
                    tradeToAvailableToken.name,
                    numberFormatter(values.to)
                )
            );
        }
    }, [tradeFromAvailableToken, tradeToAvailableToken, values, priceInUsd]);

    // watch estimation rate
    useEffect(() => {
        if (tradeToAvailableToken && tradeFromAvailableToken)
            getAmountsOut(
                tradeFromAvailableToken.name,
                tradeToAvailableToken.name,
                1
            ).then((data) =>
                setEstimatedRate(exchangeRateNumberFormatter(data))
            );
    }, [getAmountsOut, tradeFromAvailableToken, tradeToAvailableToken]);

    const updateCoinBalance = (
        prevValue: CoinBalance | undefined,
        walletBalance: WalletBalance | null
    ) => {
        return prevValue
            ? walletBalance?.[prevValue.name.toLowerCase() as keyof object]
            : undefined;
    };

    React.useEffect(() => {
        setTradeFromAvailableToken((prev) =>
            updateCoinBalance(prev, walletBalance)
        );
        setTradeToAvailableToken((prev) =>
            updateCoinBalance(prev, walletBalance)
        );
    }, [walletBalance]);

    const onRefresh = React.useCallback(() => {
        reloadBalance(false);
        reloadExchanges();
    }, [reloadBalance, reloadExchanges]);

    const handleCloseModalTradeFrom = () => {
        setIsVisibleModalTradeFrom(false);
    };

    const handleOnTradeFromPress = () => {
        setIsVisibleModalTradeFrom(true);
    };

    const handleCloseModalTradeTo = () => {
        setIsVisibleModalTradeTo(false);
    };

    const handleOnTradeToPress = () => {
        setIsVisibleModalTradeTo(true);
    };

    const handleOnDepositPress = () => {
        setIsVisibleModalDeposit(true);
    };

    const handleTokenPairs = async (coinPurpose: string, coin: CoinBalance) => {
        let tradeFrom = tradeFromAvailableToken;
        let tradeTo = tradeToAvailableToken;
        if (coinPurpose === 'from') {
            if (coin.name === tradeToAvailableToken?.name) {
                setTradeToAvailableToken(tradeFromAvailableToken);
                tradeTo = tradeFromAvailableToken;
            }
            setTradeFromAvailableToken(coin);
            tradeFrom = coin;
        } else if (coinPurpose === 'to') {
            if (coin.name === tradeFromAvailableToken?.name) {
                setTradeFromAvailableToken(tradeToAvailableToken);
                tradeFrom = tradeToAvailableToken;
            }
            setTradeToAvailableToken(coin);
            tradeTo = coin;
        }

        if (estimatedField === 'from') {
            await handleTradeTo({ value: values.to, tradeFrom, tradeTo });
        } else {
            await handleTradeFrom({ value: values.from, tradeFrom, tradeTo });
        }
    };

    const setEstimatedAmount = useCallback(
        async (
            numStr: string,
            type: string,
            fromToken?: CoinBalance,
            toToken?: CoinBalance
        ) => {
            setIsLoadingEstimate(true);
            const value = Number(numStr);

            try {
                let amount = '';
                if (value !== 0.0 && fromToken?.name && toToken?.name) {
                    if (type === 'to') {
                        amount = await getAmountsIn(
                            fromToken?.name,
                            toToken?.name,
                            value
                        );
                    } else if (type === 'from') {
                        amount = await getAmountsOut(
                            fromToken?.name,
                            toToken?.name,
                            value
                        );
                    }
                }

                await setFieldValue(
                    type === 'to' ? 'from' : 'to',
                    numberFormatter(amount)
                );
            } catch (error: any) {
                // Error when not enough tokens in the pool
                if (error?.reason === 'ds-math-sub-underflow') {
                    toastError(
                        undefined,
                        i18n.t('trade.errors.DS_MATH_SUB_UNDERFLOW'),
                        'bottom',
                        screenConfig.quickToastVisibilityInterval
                    );
                } else {
                    toastError(
                        undefined,
                        error.toString(),
                        'bottom',
                        screenConfig.toastVisibilityInterval
                    );
                }
            } finally {
                setIsLoadingEstimate(false);
            }
        },
        [getAmountsIn, getAmountsOut, estimatedTransactionFee]
    );

    const handleEstimatedAmount = useMemo(
        () => debounce(setEstimatedAmount, screenConfig.debounceInterval),
        [setEstimatedAmount]
    );

    const handleTradeFrom = async ({
        value,
        tradeFrom,
        tradeTo,
        isUpdate,
    }: ITrade) => {
        const numStr = inputNumberFormatter(value);
        if (!isBiggerThanMax(numStr)) {
            Console.info(
                'handleTradeFrom - tradeFromAvailableToken',
                tradeFromAvailableToken
            );
            Console.info(
                'handleTradeFrom - tradeToAvailableToken',
                tradeToAvailableToken
            );
            const fromToken = tradeFrom ?? tradeFromAvailableToken;
            const toToken = tradeTo ?? tradeToAvailableToken;
            !isUpdate && setEstimatedField('to');

            await setFieldValue('from', numStr);
            handleEstimatedAmount(numStr, 'from', fromToken, toToken);
        }
    };

    const handleTradeTo = async ({
        value,
        tradeFrom,
        tradeTo,
        isUpdate,
    }: ITrade) => {
        const numStr = inputNumberFormatter(value);
        if (!isBiggerThanMax(numStr)) {
            Console.info(
                'handleTradeTo - tradeFromAvailableToken',
                tradeFromAvailableToken
            );
            Console.info(
                'handleTradeTo - tradeToAvailableToken',
                tradeToAvailableToken
            );
            const fromToken = tradeFrom ?? tradeFromAvailableToken;
            const toToken = tradeTo ?? tradeToAvailableToken;
            await setFieldValue('to', numStr);
            !isUpdate && setEstimatedField('from');
            handleEstimatedAmount(numStr, 'to', fromToken, toToken);
        }
    };

    const onPressAvailable = React.useCallback(() => {
        return handleTradeFrom({
            value: getMaxValue(
                tradeFromAvailableToken,
                estimatedTransactionFee
            ),
            tradeFrom: tradeFromAvailableToken,
            tradeTo: tradeToAvailableToken,
        });
    }, [
        tradeFromAvailableToken,
        tradeToAvailableToken,
        estimatedTransactionFee,
    ]);

    const handleChangeTokens = async (
        tradeFromAvailableToken: CoinBalance | undefined,
        tradeToAvailableToken: CoinBalance | undefined
    ) => {
        setTradeFromAvailableToken(tradeToAvailableToken);
        setTradeToAvailableToken(tradeFromAvailableToken);

        setIsVisibleError(false);

        if (estimatedField === 'from') {
            await setFieldValue('to', '');
            setFromTokenUsdPrice('0.00');
            await handleTradeFrom({
                value: values.to,
                tradeFrom: tradeToAvailableToken,
                tradeTo: tradeFromAvailableToken,
            });
        } else {
            await setFieldValue('from', '');
            setToTokenUsdPrice('0.00');
            await handleTradeTo({
                value: values.from,
                tradeFrom: tradeToAvailableToken,
                tradeTo: tradeFromAvailableToken,
            });
        }

        setIsVisibleError(true);
    };

    const handleOpenSettings = () => {
        setIsVisibleModalSetting(true);
    };

    const handleCloseSetting = () => {
        setIsVisibleModalSetting(false);
    };

    const handleGoToCharts = () => {
        navigation.navigate(ROUTES.WALLET_TRADE_CHART, {
            from: `${tradeFromAvailableToken?.name}`,
            to: `${tradeToAvailableToken?.name}`,
        });
    };

    const [isFirstLoading, setIsFirstLoading] = React.useState<boolean>(true);

    //refresh form on balance change
    React.useEffect(() => {
        if (isFirstLoading) {
            setIsFirstLoading((prev) => !prev);
            return;
        }
        const update = () => {
            handleTradeFrom({ value: values.to, isUpdate: true });
            handleTradeTo({ value: values.to, isUpdate: true });
        };
        update();
    }, [walletBalance]);

    const isSummaryVisible = useMemo(() => {
        if (isAndroid) {
            return isFocused && !isDesktop;
        }
        return (isKeyboardVisible || isFocused) && !isDesktop;
    }, [isFocused, isKeyboardVisible]);

    React.useEffect(() => {
        if (isAndroid) {
            inputsRef.current[focusedIndex].current?.blur();
            if (isSummaryHide) {
                setTimeout(
                    () => inputsRef.current[focusedIndex].current?.focus(),
                    0
                );
            }
        }
    }, [isSummaryHide]);

    React.useEffect(() => {
        if (!isKeyboardVisible && isAndroid) {
            setIsSummaryHide(false);
            setIsFocused(false);
        }
    }, [isKeyboardVisible]);

    const handleTrade = React.useCallback(async () => {
        if (tradeFromAvailableToken?.name && tradeToAvailableToken?.name) {
            setDisableButton(true);
            setIsLoading(true);
            Console.info('estimatedField', estimatedField);

            try {
                const result = await handleSwap(
                    tradeFromAvailableToken?.name,
                    tradeToAvailableToken?.name,
                    estimatedField === 'from' ? 'to' : 'from',
                    estimatedField === 'from' ? values.to : values.from
                );
                setIsLoading(false);
                setDisableButton(false);
                formikData.resetForm();
                navigation.navigate(ROUTES.WALLET_TRADE_SUCCESS, {
                    estimate: estimatedField,
                    from: `${values.from} ${tradeFromAvailableToken.name}`,
                    to: `${values.to} ${tradeToAvailableToken.name}`,
                    fee: feeWithCurrency(result?.gasFee),
                    totalSummary: `${values.from} ${tradeFromAvailableToken.name}`,
                    explorerUrl: result?.explorerUrl,
                    balance: tradeToAvailableToken,
                });
            } catch (error: any) {
                Console.error(error);
                setIsLoading(false);
                setDisableButton(false);
                handleBlockchainErrors(error, true);
            }
        }
    }, [
        formikData,
        handleSwap,
        estimatedField,
        values.from,
        values.to,
        tradeFromAvailableToken,
        tradeToAvailableToken,
        setIsLoading,
        setDisableButton,
        estimatedTransactionFee,
        handleBlockchainErrors,
    ]);

    const renderItem = React.useCallback(() => {
        return (
            <View style={styles.mainContent}>
                <TradeCoinInput
                    type="from"
                    isEstimated={estimatedField === 'from'}
                    amount={values.from}
                    error={!!errors.from && isVisibleError && !isLoading}
                    token={tradeFromAvailableToken}
                    tokenUsdPrice={fromTokenUsdPrice}
                    onChangeText={(text) => handleTradeFrom({ value: text })}
                    onPressAvailableLabel={onPressAvailable}
                    onPressSwapItem={handleOnTradeFromPress}
                    forwardedRef={inputsRef.current[0]}
                    showKeyboard={isSummaryHide || !isAndroid}
                    onFocus={() => {
                        setIsFocused(true);
                        setFocusedIndex(0);
                        setFieldValue('from', getValue(values.from));
                    }}
                    onBlur={() => !isAndroid && setIsFocused(false)}
                />
                {isVisibleError && errors.from && (
                    <View style={styles.errorContainer}>
                        <Text style={styles.errorText}>{errors.from}</Text>
                    </View>
                )}
                {isVisibleError &&
                    (errors.from === i18n.t('trade.errors.maxAmountError') ||
                        errors.from ===
                            i18n.t('trade.errors.bnbNotEnough')) && (
                        <View style={styles.newDeposit}>
                            <Button
                                onPress={handleOnDepositPress}
                                type="text"
                                style={styles.depositButtonStyle}
                                icon={
                                    <Icon
                                        name={ICON_NAMES.PLUS}
                                        color={styles.icon.color}
                                    />
                                }
                                title={i18n.t('trade.deposit')}
                            />
                        </View>
                    )}
                <View style={styles.tradeButtonContainer}>
                    <Button
                        disabled={isLoadingEstimate}
                        onPress={() =>
                            handleChangeTokens(
                                tradeFromAvailableToken,
                                tradeToAvailableToken
                            )
                        }
                        debouncedPress
                        type="outline"
                        icon={
                            <Icon
                                name={ICON_NAMES.TRADE}
                                color={styles.icon.color}
                            />
                        }
                        style={styles.tradeButton}
                    />
                </View>
                <TradeCoinInput
                    type="to"
                    isEstimated={estimatedField === 'to'}
                    amount={values.to}
                    token={tradeToAvailableToken}
                    tokenUsdPrice={toTokenUsdPrice}
                    onChangeText={(text) => handleTradeTo({ value: text })}
                    onPressAvailableLabel={onPressAvailable}
                    onPressSwapItem={handleOnTradeToPress}
                    forwardedRef={inputsRef.current[1]}
                    showKeyboard={isSummaryHide || !isAndroid}
                    onFocus={() => {
                        setIsFocused(true);
                        setFocusedIndex(1);
                        setFieldValue('to', getValue(values.to));
                    }}
                    onBlur={() => !isAndroid && setIsFocused(false)}
                />
                {!!tradeFromAvailableToken && tradeToAvailableToken && (
                    <View style={styles.estimatedRateContainer}>
                        <Text style={styles.estimatedRatePrice}>
                            {i18n.t('trade.rate')}:
                        </Text>
                        <View style={styles.estimatedContainer}>
                            <Text
                                style={
                                    styles.estimatedRateText
                                }>{`1 ${tradeFromAvailableToken?.name} = ${estimatedRate} ${tradeToAvailableToken?.name}`}</Text>
                            <Button
                                onPress={handleGoToCharts}
                                debouncedPress
                                type="outline"
                                size="sm"
                                iconName={ICON_NAMES.CHART}
                                style={styles.estimatedButton}
                            />
                        </View>
                    </View>
                )}
            </View>
        );
    }, [
        isLoading,
        isLoadingEstimate,
        tradeFromAvailableToken,
        tradeToAvailableToken,
        values,
        errors,
        isVisibleError,
        estimatedField,
        toTokenUsdPrice,
        fromTokenUsdPrice,

        handleTradeFrom,
        handleTradeTo,
        onPressAvailable,
    ]);

    const renderConfirmModal = () => {
        const transactionDetailsValues = [
            {
                key: i18n.t('checkout.fields.transaction'),
                value: i18n.t('trade.confirmTrade'),
            },
            {
                key: i18n.t('trade.checkout.estimate'),
                value: `${numberFormatter(values.from ?? '')} ${
                    tradeFromAvailableToken?.name
                }`,
            },
            {
                key: i18n.t('trade.checkout.to'),
                value: `${numberFormatter(values.to ?? '')} ${
                    tradeToAvailableToken?.name
                }`,
            },
            {
                key: i18n.t('checkout.fields.fee'),
                value: feeWithCurrency(estimatedTransactionFee),
                icon: renderFeeInfoIcon(),
            },

            {
                key: i18n.t('checkout.fields.totalSummary'),
                value: `${values.from} ${tradeFromAvailableToken?.name}`,
            },
        ];

        return (
            <ModalConfirmTransaction
                isVisible={isVisibleConfirmationModal}
                close={closeConfirmationModal}
                TransactionDetailsComponent={
                    <TransactionSummary values={transactionDetailsValues} />
                }
                modalHeight={transactionDetailsValues.length * 110}
                onConfirm={handleTrade}
            />
        );
    };

    const renderDepositModal = React.useCallback(() => {
        return (
            <ModalDeposit
                isVisible={isVisibleModalDeposit}
                setIsVisible={setIsVisibleModalDeposit}
                coin={
                    isBNBEnough || isVisibleError
                        ? tradeFromAvailableToken?.name
                        : Coin.bnb
                }
            />
        );
    }, [
        isBNBEnough,
        isVisibleError,
        tradeFromAvailableToken,
        isVisibleModalDeposit,
    ]);

    const renderSettingsModal = () => {
        return (
            <ModalBottom
                isVisible={isVisibleModalSetting}
                titleText={i18n.t('trade.settings')}
                modalHeight="50%"
                onClose={handleCloseSetting}
                avoidKeyboard
                withToasts={false}>
                <TradeSetting handleClose={handleCloseSetting} />
            </ModalBottom>
        );
    };

    const renderLoadingModal = React.useCallback(() => {
        return (
            <ModalLoader
                isVisible={isLoading}
                text={i18n.t('trade.processingTrade')}
            />
        );
    }, [isLoading]);

    const renderTradeTokenModals = () => {
        if (!mainToken) {
            return null;
        }

        return (
            <>
                <ModalCoinSelect
                    isVisible={isVisibleModalTradeFrom}
                    setIsVisible={setIsVisibleModalTradeFrom}
                    titleText={i18n.t('trade.selectToken')}
                    modalHeight={500}
                    onPress={(coin) => {
                        handleTokenPairs('from', coin);
                        handleCloseModalTradeFrom();
                    }}
                    selectedCoin={tradeFromAvailableToken}
                    balances={[mainToken, ...tradeTokens]}
                />
                <ModalCoinSelect
                    isVisible={isVisibleModalTradeTo}
                    setIsVisible={setIsVisibleModalTradeTo}
                    titleText={i18n.t('trade.selectToken')}
                    modalHeight={500}
                    onPress={(coin) => {
                        handleTokenPairs('to', coin);
                        handleCloseModalTradeTo();
                    }}
                    selectedCoin={tradeToAvailableToken}
                    balances={[mainToken, ...tradeTokens]}
                />
            </>
        );
    };

    const transactionSummaryValues = [
        {
            key: i18n.t('checkout.fields.fee'),
            value: feeWithCurrency(estimatedTransactionFee),
            icon: renderFeeInfoIcon(true),
        },
        {
            key: i18n.t('checkout.fields.totalSummary'),
            value: `${numberFormatter(values.from)} ${
                tradeFromAvailableToken?.name
            }`,
        },
    ];

    return (
        <View style={styles.container}>
            <NavigationBar
                backIcon
                filled={false}
                settingsIcon
                containerStyle={styles.containerStyle}
                title={i18n.t('wallet.trade')}
                onPressSettings={handleOpenSettings}
            />

            <KeyboardAvoidingView
                behavior={isIOS ? 'padding' : 'height'}
                style={styles.flex}>
                <PullToRefresh
                    refreshing={balanceReloading}
                    textLoading={i18n.t('pullToRefresh.reloadBalance.text')}
                    onRefresh={() => onRefresh()}
                    renderItem={renderItem}
                    keyboardDismiss
                />
                <View
                    style={[isSummaryVisible ? styles.keyboardVisible : {}]}
                    onLayout={(e) => {
                        setIsSummaryHide(e.nativeEvent.layout.height === 0);
                    }}>
                    <BiswapLogo />
                    <Divider customStyles={styles.divider} />
                    <View style={styles.feePanel}>
                        <TransactionSummary values={transactionSummaryValues} />
                    </View>
                </View>
                <Button
                    loading={isLoadingEstimate}
                    loadingText={i18n.t('trade.calculatingTrade')}
                    disabled={
                        !(isValid && dirty) ||
                        !Number(values?.from) ||
                        !Number(values?.to) ||
                        disableButton
                    }
                    title={i18n.t('trade.trade')}
                    containerStyle={styles.button}
                    onPress={submitForm}
                    debouncedPress
                />
                <SafeAreaView />
                {renderBlockchainErrorsModal()}
                {renderConfirmModal()}
                {renderTradeTokenModals()}
                {renderDepositModal()}
                {renderSettingsModal()}
                {renderLoadingModal()}
                <ToastNotification />
            </KeyboardAvoidingView>
        </View>
    );
};

export default TradeMainScreen;
