import * as React from 'react';

import { StakingPlanOutput } from '@Data/Models';
import StakingCardIcon from '@components/Icons/StakingCardIcon';
import { Text, View } from '@components/Themed';
import { StakingCoin } from '@contexts/StakingContext';
import { dateFormatterYear, getFinishAt } from '@helpers/time';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    data: StakingPlanOutput;
    coin: StakingCoin;
    prevStaking: string | undefined;
}

const StakingCardCoin = ({ data, coin, prevStaking }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { color: backgroundColor, name, durationDays: days } = data.plan;

    return (
        <View style={[styles.container, { backgroundColor }]}>
            <View style={styles.coin}>{<StakingCardIcon slug={coin} />}</View>
            <View style={styles.inner}>
                {/* TODO:// typography component just for tech dept */}
                <View style={styles.base}>
                    <View style={styles.left}>
                        <Text style={styles.title}>{name}</Text>
                        <Text style={styles.subTitle}>
                            {i18n.t('staking.general.days', {
                                days,
                            })}
                        </Text>
                    </View>
                    <View style={styles.right}>
                        <Text style={styles.title}>
                            {i18n.t('staking.general.ended')}
                        </Text>
                        <Text style={styles.subTitle}>
                            {dateFormatterYear(getFinishAt(days))}
                        </Text>
                    </View>
                </View>
                {!!prevStaking && (
                    <View style={styles.old}>
                        <Text style={styles.title}>
                            {i18n.t('staking.general.current')}
                        </Text>
                        <Text style={styles.subTitle}>
                            {`${balanceFromWei(prevStaking).valueLong} ${coin}`}
                        </Text>
                    </View>
                )}
            </View>
        </View>
    );
};
export default StakingCardCoin;
