import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(20),
        },
        playToEarnButton: {
            position: 'absolute',
            bottom: 100,
            right: 20,
            borderRadius: 1000,
            zIndex: 100000000000,
        },
    });

export default styles;
