import * as React from 'react';

import CheckoutSuccessScreen from '@components/CheckoutSuccessScreen';
import ImageWithName from '@components/ImageWithName';
import TransactionSummary from '@components/TransactionSummary';
import { useUser } from '@contexts/UserContext';
import useFeeInfo from '@hooks/useFeeInfo';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import { MarketPlaceBuySuccessProps } from '../../../../types';

const SCREEN = 'MarketPlaceBuySuccessScreen';
const MarketPlaceBuySuccessScreen = ({ route }: MarketPlaceBuySuccessProps) => {
    const navigation = useNavigation();
    navigation.blockHardwareBack();

    const { reloadUser } = useUser();

    React.useEffect(() => {
        reloadUser();
    }, []);
    const { renderFeeInfoIcon } = useFeeInfo();

    const { pet, owner, total, transactionResult } = route.params;

    const handleNavigateToCollections = () => {
        navigation.navigate(ROUTES.COLLECTION);
    };
    const handleNavigateToMarketPlace = () => {
        navigation.navigate(ROUTES.MARKETPLACE);
    };

    const transactionDetailsValues = [
        {
            key: i18n.t('checkout.fields.nft'),
            value: <ImageWithName petImg={pet.imageUrl} petName={pet.name} />,
        },
        {
            key: i18n.t('wallet.fromAddress'),
            value: owner.profile.username || owner.profile.walletAddress,
        },
        {
            key: i18n.t('checkout.fields.to'),
            value: i18n.t('checkout.fields.nftWallet'),
        },
        !!transactionResult?.transactionFeeFormatted
            ? {
                  key: i18n.t('checkout.fields.fee'),
                  value: transactionResult?.transactionFeeFormatted,
                  icon: renderFeeInfoIcon(true),
              }
            : undefined,
        {
            key: i18n.t('checkout.fields.totalSummary'),
            value: total,
        },
    ];

    return (
        <CheckoutSuccessScreen
            title={i18n.t(`MarketPlace.${SCREEN}.title`)}
            text={i18n.t(`MarketPlace.${SCREEN}.text`)}
            onPressButtonMain={handleNavigateToCollections}
            buttonMainText={i18n.t(`mintCheckout.backToCollection`)}
            onPressButtonAdditional={handleNavigateToMarketPlace}
            buttonAdditionalText={i18n.t(`general.BUTTONS.continueShopping`)}
            TransactionDetailsComponent={
                <TransactionSummary values={transactionDetailsValues} />
            }
            explorerUrl={transactionResult?.explorerUrl}
        />
    );
};

export default MarketPlaceBuySuccessScreen;
