import React, { useCallback } from 'react';

import _ from 'lodash';

import { GET_MARKETPLACE_PROFILE } from '@Data/Requests';
import { useApolloClient } from '@apollo/client';
import { errorsHandler } from '@helpers/errors';
import { useLoadingHook } from '@hooks/useLoadingHook';
import {
    MarketplaceOrderDataOutput,
    MarketplaceOrdersStatsOutput,
    MarketplaceProfileOutputResponse,
    UserPublicProfileOutput,
} from '@models/marketplace';

const useMarketplaceProfile = () => {
    const client = useApolloClient();

    const {
        isLoading: userProfileLoading,
        startLoading: startUserProfileLoading,
        stopLoading: stopUserProfileLoading,
    } = useLoadingHook(true);

    const {
        isLoading: refreshLoading,
        startLoading: startRefreshLoading,
        stopLoading: stopRefreshLoading,
    } = useLoadingHook();

    const [listerOrders, setListedOrders] =
        React.useState<MarketplaceOrderDataOutput[]>();
    const [stats, setStats] = React.useState<MarketplaceOrdersStatsOutput>();
    const [userProfile, setUserProfile] =
        React.useState<UserPublicProfileOutput>();

    const getMarketplaceProfile = useCallback(
        async (refresh: boolean, walletAddress: string) => {
            try {
                refresh ? startRefreshLoading() : startUserProfileLoading();

                const result =
                    await client.query<MarketplaceProfileOutputResponse>({
                        query: GET_MARKETPLACE_PROFILE,
                        fetchPolicy: 'network-only',
                        variables: {
                            walletAddress,
                        },
                    });

                if (result.data) {
                    const reversedList = [
                        ...result.data.marketplaceProfile.listedOrders,
                    ].reverse();
                    setListedOrders(reversedList);
                    setStats(result.data.marketplaceProfile.stats);
                    setUserProfile(result.data.marketplaceProfile.user);
                }
                stopRefreshLoading();
                stopUserProfileLoading();
            } catch (error) {
                stopRefreshLoading();
                stopUserProfileLoading();
                errorsHandler(error, true);
            }
        },
        [client]
    );

    return {
        refreshLoading,
        listerOrders,
        stats,
        userProfile,
        userProfileLoading,
        getMarketplaceProfile,
    };
};

export default useMarketplaceProfile;
