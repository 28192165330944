import { StyleSheet } from 'react-native';

import { verticalScale } from '@helpers/dimensions';

import { theme } from '../../types';

const stylesMain = (theme: theme) =>
    StyleSheet.create({
        infoWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: 'transparent',
            marginTop: verticalScale(12),
        },
        container: {
            flex: 1,
            backgroundColor: 'transparent',
        },
    });

export default stylesMain;
