import { Image, ImageSourcePropType } from 'react-native';
import { Image as ExpoImageCache } from 'react-native-expo-image-cache';

import Colors from '../../constants/Colors';
import { useTheme } from '../../contexts/ThemeContext';
import { isWeb } from '../../helpers/app';
import { View } from '../Themed';

interface IProps {
    source: string | undefined;
    style?: any;
    resizeMode?: string;
}

const CacheImage = ({ source, resizeMode = 'cover', ...props }: IProps) => {
    const { theme } = useTheme();

    return (
        <View
            style={[
                props.style,
                {
                    backgroundColor: Colors[theme].buttonShadowDisabledOutline,
                },
            ]}>
            {isWeb ? (
                <Image source={{ uri: source }} {...props} />
            ) : (
                <>{source && <ExpoImageCache uri={source} {...props} />}</>
            )}
        </View>
    );
};

export default CacheImage;
