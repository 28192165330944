import * as React from 'react';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import CollectionAllScreen from '../../screens/TabBar/Collection/CollectionAll/CollectionAllScreen';
import MysteryBoxesMainScreen from '../../screens/TabBar/MysteryBoxes/MysteryBoxesMain/MysteryBoxesMainScreen';
import { RootStackParamList } from '../../types';
import ROUTES from '../routes';

const CollectionTabStack = createBottomTabNavigator<RootStackParamList>();

export const CollectionTabStackNavigator = () => {
    return (
        <CollectionTabStack.Navigator
            screenOptions={{
                headerShown: false,
            }}
            initialRouteName={ROUTES.COLLECTION_MAIN}
            backBehavior="none"
            tabBar={() => null}>
            <CollectionTabStack.Screen
                name={ROUTES.COLLECTION_MAIN}
                component={CollectionAllScreen}
            />
            <CollectionTabStack.Screen
                name={ROUTES.MYSTERY_BOX_MAIN}
                component={MysteryBoxesMainScreen}
            />
        </CollectionTabStack.Navigator>
    );
};
