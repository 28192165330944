import React from 'react';

import { useUser } from '@contexts/UserContext';
import { isWeb } from '@helpers/app';

import { View } from '../../../../components/Themed';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import AccessibilityMenuItem from './components/AccessibilityMenuItem';
import AdminAssignWhiteListMenuItem from './components/AdminAssignWhiteListMenuItem';
import AdminBlockUserMenuItem from './components/AdminBlockUserMenuItem';
import AdminMaintenanceModeMenuItem from './components/AdminMaintenanceModeMenuItem';
import AdminNftRoyaleRevokeBan from './components/AdminNftRoyaleRevokeBan';
import AdminReverificationMenuItem from './components/AdminReverificationMenuItem';
import AdminRoleMenuItem from './components/AdminRoleMenuItem';
import AdminSendPushNotificationMenuItem from './components/AdminSendPushNotificationMenuItem';
import AdminVerifyUsersMenuItem from './components/AdminVerifyUsersMenuItem';
import AppVersionMenuItem from './components/AppVersionMenuItem';
import DevMenuItem from './components/DevMenuItem';
import KnowledgeBaseMenuItem from './components/KnowledgeBaseMenuItem';
import LanguagesMenuItem from './components/LanguagesMenuItem';
import NotificationsMenuItem from './components/NotificationsMenuItem';
import PrivacyPolicyMenuItem from './components/PrivacyPolicyMenuItem';
import SocialAccountsMenuItem from './components/SocialAccountsMenuItem';
import SocialMediaMenuItem from './components/SocialMediaMenuItem';
import SupportEmailMenuItem from './components/SupportEmailMenuItem';
import SupportMenuItem from './components/SupportMenuItem';
import TermsOfUseMenuItem from './components/TermsOfUseMenuItem';
import ThemeMenuItem from './components/ThemeMenuItem';
import WalletMenuItem from './components/WalletMenuItem';
import WebVersionMenuItem from './components/WebVersionMenuItem';
import stylesMain from './styles';

const ProfileMenu = () => {
    const styles = useThemedStyles(stylesMain);
    const { hasRole, isAdmin } = useUser();

    return (
        <>
            {hasRole && (
                <>
                    <AdminVerifyUsersMenuItem />
                    <AdminReverificationMenuItem />
                    {isAdmin && (
                        <>
                            <AdminSendPushNotificationMenuItem />
                            <AdminBlockUserMenuItem />
                            <AdminMaintenanceModeMenuItem />
                            <AdminAssignWhiteListMenuItem />
                            <AdminRoleMenuItem />
                            <AdminNftRoyaleRevokeBan />
                        </>
                    )}

                    <View style={styles.divider} />
                </>
            )}

            <SocialAccountsMenuItem />
            <LanguagesMenuItem />
            <ThemeMenuItem />
            {!isWeb && <NotificationsMenuItem />}
            {!isWeb && <AccessibilityMenuItem />}
            <WalletMenuItem />
            <View style={styles.divider} />
            <SocialMediaMenuItem />
            <SupportMenuItem />
            <SupportEmailMenuItem />
            <KnowledgeBaseMenuItem />
            <PrivacyPolicyMenuItem />
            <TermsOfUseMenuItem />
            <AppVersionMenuItem />
            {!isWeb && <WebVersionMenuItem />}
            <View style={styles.divider} />
            <DevMenuItem />
            <View style={styles.divider} />
        </>
    );
};

export default ProfileMenu;
