import * as React from 'react';
import { LayoutRectangle, TouchableOpacity } from 'react-native';

import { Pet } from '@Data/Models';
import AgeIndicator from '@components/AgeIndicator';
import Button from '@components/Button';
import CacheImage from '@components/CacheImage';
import HealthIndicator from '@components/HealthIndicator';
import Icon, { ICON_NAMES } from '@components/Icons';
import RankIcon from '@components/Icons/RankIcon';
import LevelIndicator from '@components/LevelIndicator';
import ModalChooseAmountOfHealUp from '@components/ModalChooseAmountOfHealUp';
import ModalNFTLevelUp from '@components/ModalNFTLevelUp';
import ModalNFTisAgeDead from '@components/ModalNFTisAgeDead';
import ModalNFTisDead from '@components/ModalNFTisDead';
import ModalNFTRankUp from '@components/Ranks/ModalNFTRankUp';
import { Text, View } from '@components/Themed';
import { useTheme } from '@contexts/ThemeContext';
import { dateCooldownExpired } from '@helpers/time';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';

import stylesMain from './styles';

interface IProps {
    pet: Pet;
    onPressPet: (id: string) => void;
    onLayout?: (size: LayoutRectangle) => void;
}

const PetCollectionCard = ({ pet, onPressPet, onLayout }: IProps) => {
    const { theme } = useTheme();
    const styles = useThemedStyles(stylesMain);

    const [isVisibleLevelUp, setIsVisibleLevelUp] = React.useState(false);
    const [isVisibleRankUp, setIsVisibleRankUp] = React.useState(false);
    const [isVisibleRestore, setIsVisibleRestore] = React.useState(false);
    const [isVisibleFeed, setIsVisibleFeed] = React.useState(false);

    const hasCooldown = !dateCooldownExpired(pet.cooldownExpiresAt);

    const handleCloseLevelUpModal = () => {
        setIsVisibleLevelUp(false);
    };
    const handleOpenLevelUpModal = () => {
        setIsVisibleLevelUp(true);
    };

    const handleCloseRankUpModal = () => {
        setIsVisibleRankUp(false);
    };
    const handleOpenRankUpModal = () => {
        setIsVisibleRankUp(true);
    };

    const handleCloseRestoreModal = () => {
        setIsVisibleRestore(false);
    };
    const handleOpenRestoreModal = () => {
        setIsVisibleRestore(true);
    };

    const handleCloseFeedModal = () => {
        setIsVisibleFeed(false);
    };
    const handleOpenFeedModal = () => {
        setIsVisibleFeed(true);
    };

    const {
        isVisible: ageReviveModal,
        close: closeAgeReviveModal,
        open: openAgeReviveModal,
    } = useVisibleHook();

    const renderLevelUp = () => {
        if (
            pet.levelUpAvailable &&
            !pet.rankUpAvailable &&
            !pet.dead &&
            !pet.healUpAvailable &&
            !pet.ageDead
        ) {
            return (
                <View style={styles.wrapper}>
                    <View style={styles.opacityBlock}></View>
                    <View style={styles.lvlWrapper}>
                        <Button
                            tailTitle
                            size="md"
                            type="fill"
                            icon={
                                <Icon
                                    name={ICON_NAMES.ARROW_UPGRADE}
                                    color={styles.iconDark.color}
                                />
                            }
                            style={styles.buttonMint}
                            onPress={handleOpenLevelUpModal}
                            title={i18n.t('collectionsScreen.levelUp')}
                        />
                    </View>
                    <ModalNFTLevelUp
                        handleCloseLevelUpModal={handleCloseLevelUpModal}
                        isVisible={isVisibleLevelUp}
                        pet={pet}
                        navigateBackOnUpgrade={ROUTES.COLLECTION}
                    />
                </View>
            );
        }
    };

    const renderRevive = () => {
        if (pet.dead && !pet.ageDead) {
            return (
                <View style={styles.wrapper}>
                    <View style={styles.opacityBlock}></View>
                    <View style={styles.lvlWrapper}>
                        <Button
                            tailTitle
                            size="md"
                            type="critical"
                            iconName={ICON_NAMES.IS_DEAD}
                            iconColor={styles.iconDark.color}
                            style={styles.buttonMint}
                            onPress={handleOpenRestoreModal}
                            title={i18n.t('collectionsScreen.restore')}
                        />
                    </View>
                    <ModalNFTisDead
                        closeModal={handleCloseRestoreModal}
                        isVisible={isVisibleRestore}
                        pet={pet}
                        navigateBackOnUpgrade={ROUTES.COLLECTION}
                    />
                </View>
            );
        }
    };

    const renderAgeRevive = () => {
        if (pet.ageDead) {
            return (
                <View style={styles.wrapper}>
                    <View style={styles.opacityBlock}></View>
                    <View style={styles.lvlWrapper}>
                        <Button
                            tailTitle
                            size="md"
                            type="critical"
                            iconName={ICON_NAMES.IS_AGE_DEAD}
                            iconColor={styles.iconDark.color}
                            style={styles.buttonMint}
                            onPress={openAgeReviveModal}
                            title={i18n.t('collectionsScreen.restore')}
                        />
                    </View>
                    <ModalNFTisAgeDead
                        closeModal={closeAgeReviveModal}
                        isVisible={ageReviveModal}
                        pet={pet}
                        navigateBackOnUpgrade={ROUTES.COLLECTION}
                    />
                </View>
            );
        }
    };

    const renderRankUp = () => {
        if (
            pet.rankUpAvailable &&
            !pet.dead &&
            !pet.healUpAvailable &&
            !pet.ageDead
        ) {
            return (
                <View style={styles.wrapper}>
                    <View style={styles.opacityBlock}></View>
                    <View style={styles.lvlWrapper}>
                        <Button
                            tailTitle
                            size="md"
                            type="fill"
                            icon={
                                <Icon
                                    name={ICON_NAMES.ARROW_UPGRADE}
                                    color={styles.iconDark.color}
                                />
                            }
                            style={styles.buttonMint}
                            onPress={handleOpenRankUpModal}
                            title={i18n.t('collectionsScreen.rankUp')}
                        />
                    </View>
                    <ModalNFTRankUp
                        closeAction={handleCloseRankUpModal}
                        isVisible={isVisibleRankUp}
                        pet={pet}
                        navigateBackOnUpgrade={ROUTES.COLLECTION}
                    />
                </View>
            );
        }
    };

    const renderFeed = () => {
        if (pet.healUpAvailable && !pet.dead && !pet.ageDead) {
            return (
                <View style={styles.wrapper}>
                    <View style={styles.opacityBlock}></View>
                    <View style={styles.lvlWrapper}>
                        <Button
                            tailTitle
                            size="md"
                            type="fill"
                            iconName={ICON_NAMES.FEED}
                            iconColor={styles.iconDark.color}
                            containerStyle={styles.buttonMint}
                            onPress={handleOpenFeedModal}
                            title={i18n.t('collectionsScreen.feed')}
                        />
                    </View>
                    <ModalChooseAmountOfHealUp
                        closeModal={handleCloseFeedModal}
                        isVisible={isVisibleFeed}
                        pet={pet}
                        navigateBackOnUpgrade={ROUTES.COLLECTION}
                    />
                </View>
            );
        }
    };

    const renderCooldown = () => {
        if (hasCooldown) {
            return (
                <>
                    <View style={styles.opacityBlock}></View>
                    <View style={styles.cooldownWrapper}>
                        <Icon
                            name={ICON_NAMES.COOLDOWN}
                            width={20}
                            height={20}
                        />
                    </View>
                </>
            );
        }
    };

    const isOnCooldown = React.useMemo(
        () => !dateCooldownExpired(pet.cooldownExpiresAt),
        [pet]
    );

    return (
        <>
            <TouchableOpacity
                onLayout={({ nativeEvent }) => onLayout?.(nativeEvent.layout)}
                activeOpacity={0.7}
                style={styles.shadow}
                onPress={() => onPressPet(pet.id)}>
                <View style={styles.container}>
                    <View style={styles.imgWrapper}>
                        <CacheImage
                            source={pet.image.thumbnailUrl}
                            style={styles.image}
                            resizeMode="contain"
                        />
                        <View style={styles.rankWrapper}>
                            <RankIcon size={24} slug={pet.rank} />
                        </View>
                        {isOnCooldown && (
                            <View style={styles.pendingWrapper}>
                                <View style={styles.pendingBlock}>
                                    <Icon name={ICON_NAMES.COOLDOWN} />
                                </View>
                            </View>
                        )}
                        {renderCooldown()}
                        {renderLevelUp()}
                        {renderRankUp()}
                        {renderRevive()}
                        {renderFeed()}
                        {renderAgeRevive()}
                    </View>

                    <View style={styles.textWrapper}>
                        <Text
                            style={styles.name}
                            numberOfLines={1}
                            ellipsizeMode="tail">
                            {pet.name}
                        </Text>
                        <LevelIndicator
                            level={pet.level}
                            fillAmount={pet.levelProgressPercentage}
                        />
                        <View style={styles.footer}>
                            <HealthIndicator
                                healthPercentage={pet.health}
                                badge
                                showMaxHealth={false}
                            />
                            <AgeIndicator
                                ageDays={pet.ageDays}
                                maxAgeDays={pet.maxAgeDays}
                                containerStyle={styles.ageWrapper}
                            />
                        </View>
                    </View>
                </View>
            </TouchableOpacity>
        </>
    );
};
export default React.memo(PetCollectionCard);
