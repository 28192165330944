import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { Text, View } from '@components/Themed';

import Heart from '../../assets/icons/general/health.svg';
import useThemedStyles from '../../hooks/useThemedStyles';
import i18n from '../../i18n/i18n';
import stylesMain from './styles';

interface IProps {
    text?: string;
    withIcon?: boolean;
    containerStyle?: StyleProp<ViewStyle>;
    children?: React.ReactNode;
}

const CurrentLabel = ({
    text,
    withIcon = true,
    containerStyle,
    children,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    return (
        <View style={[styles.container, containerStyle]}>
            {withIcon && <Heart width={20} height={20} />}
            <Text style={styles.text}>{text || i18n.t('general.current')}</Text>
            {children}
        </View>
    );
};

export default CurrentLabel;
