import * as React from 'react';

import Animation from '@components/Animation';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import TypoText from '@components/TypoText';
import { TYPO_TEXT, TYPO_TEXT_COLOR } from '@components/TypoText/types';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { priceString } from '@helpers/payments';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { LUCKY_STRIKE_GAMES_STATUS, MYSTERY_GAMES } from '@models/mysteryGames';

import stylesMain from './styles';

const SCREEN = 'LuckyStrikeHistoryBlockHeader';
const GAME = MYSTERY_GAMES.luckyStrike;

interface IProps {
    status: LUCKY_STRIKE_GAMES_STATUS;
    iguAmountWei?: string;
    isBigHeader?: boolean;
    componentBetweenImageAndText?: React.ReactElement;
}

const LuckyStrikeHistoryBlockHeader = ({
    status,
    iguAmountWei,
    isBigHeader,
    componentBetweenImageAndText,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const renderBigHeader = () => {
        const textComponent = () => {
            if (status === LUCKY_STRIKE_GAMES_STATUS.Won) {
                return (
                    <TypoText
                        type={TYPO_TEXT.BODY_BIG}
                        colorType={TYPO_TEXT_COLOR.ON_DARK}>
                        {i18n.t(
                            `HomeScreen.${GAME}.${SCREEN}.${status}.textOne`
                        )}
                        <TypoText
                            type={TYPO_TEXT.SUBHEAD_MEDIUM_BOLD}
                            colorType={TYPO_TEXT_COLOR.ON_DARK}>
                            {priceString(
                                balanceFromWei(iguAmountWei).valueLong,
                                Coin.vigu
                            )}
                        </TypoText>
                        {i18n.t(
                            `HomeScreen.${GAME}.${SCREEN}.${status}.textTwo`
                        )}
                    </TypoText>
                );
            }
            return (
                <TypoText
                    type={TYPO_TEXT.BODY_BIG}
                    colorType={TYPO_TEXT_COLOR.ON_DARK}>
                    {i18n.t(`HomeScreen.${GAME}.${SCREEN}.${status}.text`)}
                </TypoText>
            );
        };

        return (
            <BlockHeader
                containerStyle={styles.header}
                emptyStateIcon={
                    <Animation
                        animation={status as keyof object}
                        style={styles.animation}
                    />
                }
                type="emptyState"
                titleComponent={
                    <TypoText
                        type={TYPO_TEXT.HEADING_H2}
                        colorType={TYPO_TEXT_COLOR.ON_DARK}>
                        {i18n.t(`HomeScreen.${GAME}.${SCREEN}.${status}.title`)}
                    </TypoText>
                }
                textComponent={textComponent()}
                componentBetweenImageAndText={componentBetweenImageAndText}
            />
        );
    };

    const renderDefaultHeader = () => {
        const textComponent = () => {
            if (status === LUCKY_STRIKE_GAMES_STATUS.Won) {
                return (
                    <>
                        {i18n.t(
                            `HomeScreen.${GAME}.${SCREEN}.${status}.textOne`
                        )}
                        <TypoText
                            type={TYPO_TEXT.SUBHEAD_MEDIUM_BOLD}
                            colorType={TYPO_TEXT_COLOR.ACCENT}>
                            {priceString(
                                balanceFromWei(iguAmountWei).valueLong,
                                Coin.vigu
                            )}
                        </TypoText>
                        {i18n.t(
                            `HomeScreen.${GAME}.${SCREEN}.${status}.textTwo`
                        )}
                    </>
                );
            }
            return <>{i18n.t(`HomeScreen.${GAME}.${SCREEN}.${status}.text`)}</>;
        };
        return (
            <BlockHeader
                emptyStateIcon={
                    <Animation
                        animation={status as keyof object}
                        style={styles.animation}
                    />
                }
                type="emptyState"
                title={i18n.t(`HomeScreen.${GAME}.${SCREEN}.${status}.title`)}
                textComponent={textComponent()}
            />
        );
    };

    return isBigHeader ? renderBigHeader() : renderDefaultHeader();
};

export default LuckyStrikeHistoryBlockHeader;
