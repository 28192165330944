import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';
import { stylesModalNavigationFix } from '@helpers/style';

import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,

            ...stylesModalNavigationFix(),
            paddingHorizontal: scale(20),
        },
        container: {
            marginBottom: verticalScale(36),
        },
        img: {
            alignItems: 'center',
            marginBottom: verticalScale(24),
        },
        top: {
            marginBottom: verticalScale(20),
        },
    });

export default styles;
