import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        itemContainer: {
            flexDirection: 'row',
            backgroundColor: 'transparent',
            paddingHorizontal: scale(20),
            paddingVertical: verticalScale(12),
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        left: {
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: 'transparent',
        },
        status: {
            backgroundColor: 'transparent',
            borderRadius: 1000,
            position: 'absolute',
            right: -2,
            bottom: -6,
        },
        statusIcon: {
            position: 'absolute',
            left: 44, // lock size + TransactionStatusIcon/2 size
            top: 44, // lock size + TransactionStatusIcon/2 size
            backgroundColor: 'transparent',
            zIndex: 100,
        },
        opacity: {
            opacity: 0.4,
            position: 'absolute',
            zIndex: 3,
        },
        textWrapper: {
            marginLeft: scale(12),
        },
        title: {
            ...font({
                size: 15,
                height: 22,
                weight: '600',
            }),
            color: Colors[theme].text.primary,
        },
        date: {
            marginTop: verticalScale(2),
            ...font({
                size: 12,
                height: 16,
                weight: '400',
            }),
            color: Colors[theme].text.secondary,
        },
        right: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        value: {
            ...font({
                size: 15,
                height: 22,
                weight: '600',
            }),
            marginRight: scale(4),
        },
        paymentColor: {
            color: Colors[theme].text.primary,
        },
        PENDING: {
            color: Colors[theme].text.warning,
        },
        CONFIRMED: {
            color: Colors[theme].text.completed,
        },
        UNLOCK: {
            color: Colors[theme].text.completed,
        },
    });

export default styles;
