import * as StoreReview from 'expo-store-review';

import AsyncStorage from '@react-native-async-storage/async-storage';

import { useUser } from '../contexts/UserContext';
import { isWeb } from '../helpers/app';

const config = {
    reviewTimeout: 5000,
};

export const useShowAppReview = () => {
    const { user } = useUser();

    const storageKey = `useShowReview.showReviewModal.${user?.id}`;

    const showReviewModal = async () => {
        try {
            if (isWeb) {
                return;
            }

            const actionState = await StoreReview.hasAction();
            const isAvailable = await StoreReview.isAvailableAsync();
            const reviewData = await AsyncStorage.getItem(storageKey);

            if (actionState && isAvailable && !reviewData) {
                setTimeout(() => {
                    AsyncStorage.setItem(storageKey, 'true');
                    StoreReview.requestReview();
                }, config.reviewTimeout);
            }
        } catch (error) {
            Console.log(
                `[StoreReview] Can't open the StoreReview modal`,
                error
            );
        }
    };

    return {
        showReviewModal,
    };
};
