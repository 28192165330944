import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            paddingTop: verticalScale(32),
            paddingHorizontal: scale(20),
        },
        content: {
            flex: 1,
        },
        buttonWrapper: {
            paddingVertical: verticalScale(16),
        },
    });

export default styles;
