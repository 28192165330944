import * as React from 'react';

import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import { useNavigation } from '../../useNavigation';
import NavigationItem from '../NavigationItem';
import stylesMain from './styles';

interface IProps {
    currentNavigation: NAVIGATION_MYSTERY_LIKES;
}

export enum NAVIGATION_MYSTERY_LIKES {
    ORDERS = 'ORDERS',
    HISTORY = 'HISTORY',
}

const data: NAVIGATION_MYSTERY_LIKES[] = [
    NAVIGATION_MYSTERY_LIKES.ORDERS,
    NAVIGATION_MYSTERY_LIKES.HISTORY,
];

const NavigationMysteryLikes = ({ currentNavigation }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const handleNavigation = (item: NAVIGATION_MYSTERY_LIKES) => {
        switch (item) {
            case NAVIGATION_MYSTERY_LIKES.ORDERS:
                navigation.navigateToMysteryLikesActiveOrder();
                break;
            case NAVIGATION_MYSTERY_LIKES.HISTORY:
                navigation.navigateToMysteryLikesHistory();
                break;
        }
    };

    return (
        <View style={styles.main}>
            <View style={styles.shadow}>
                <View style={styles.container}>
                    {data.map((item) => (
                        <NavigationItem
                            key={item}
                            onPress={() => handleNavigation(item)}
                            title={i18n.t(
                                `HomeScreen.GamesNavigationBar.${item}`
                            )}
                            isActive={currentNavigation === item}
                            containerStyles={styles.item}
                        />
                    ))}
                </View>
            </View>
        </View>
    );
};
export default NavigationMysteryLikes;
