import * as React from 'react';
import { useMemo } from 'react';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

import CoinIcon from '@components/Icons/CoinIcon';
import { Text, View } from '@components/Themed';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import useThemedStyles from '@hooks/useThemedStyles';

import stylesMain from './styles';

interface IProps {
    amount: string;
    coin: Coin;
    backgroundStyle?: StyleProp<ViewStyle>;
    textStyle?: StyleProp<TextStyle>;
    maxWidth?: number;
}

const MarketPlacePriceBlock = ({
    amount,
    coin,
    backgroundStyle,
    textStyle,
    maxWidth,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const newStyles = useMemo(() => {
        switch (coin) {
            case Coin.igu:
                return {
                    background: styles.iguBg,
                    color: styles.iguText,
                };
            default:
                return {
                    background: styles.iguBg,
                    color: styles.iguText,
                };
        }
    }, [coin, styles]);

    return (
        <View style={[styles.container, newStyles.background, backgroundStyle]}>
            <CoinIcon slug={coin} size={20} />
            <Text
                style={[
                    styles.amount,
                    newStyles.color,
                    maxWidth && { maxWidth },
                    textStyle,
                ]}
                numberOfLines={1}>
                {amount}
            </Text>
        </View>
    );
};
export default MarketPlacePriceBlock;
