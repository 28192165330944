import { createNativeStackNavigator } from '@react-navigation/native-stack';

import AdminProvider from '../../contexts/AdminContext';
import AdminVerifyScreen from '../../screens/Admin/AdminVerify/AdminVerifyScreen';
import { AdminStackParamList } from '../../types';
import ROUTES from '../routes';

const AdminStack = createNativeStackNavigator<AdminStackParamList>();

const AdminNavigator = () => {
    return (
        <>
            <AdminProvider>
                <AdminStack.Navigator
                    initialRouteName={ROUTES.ADMIN_VERIFY}
                    screenOptions={{
                        headerShown: false,
                    }}>
                    <AdminStack.Screen
                        name={ROUTES.ADMIN_VERIFY}
                        component={AdminVerifyScreen}
                    />
                </AdminStack.Navigator>
            </AdminProvider>
        </>
    );
};

export default AdminNavigator;
