import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        item: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        gap: {
            marginTop: verticalScale(16),
        },
        left: {
            flexDirection: 'row',
            marginRight: scale(16),
            flex: 1,
        },
        icon: {
            marginRight: scale(8),
        },
        title: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
        },
        username: {
            color: Colors[theme].text.accent,
            ...font({
                size: 15,
                height: 23,
                weight: '600',
            }),
        },
        divider: {
            marginVertical: verticalScale(16),
        },
        energy: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
        },
    });

export default styles;
