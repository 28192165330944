import { StyleSheet } from 'react-native';

import { isWeb } from '@helpers/app';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import Colors from '../../constants/Colors';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
        },
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
            marginBottom: verticalScale(4),
        },
        tips: {
            marginBottom: verticalScale(16),
        },
        item: {
            flexDirection: 'row',

            marginTop: verticalScale(12),
        },
        icon: {
            top: isWeb ? 0 : 2,
            marginRight: scale(6),
        },
        text: {
            flex: 1,
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 21,
                weight: '400',
            }),
            letterSpacing: 0.08,
        },
    });

export default styles;
