import * as React from 'react';
import { useCallback } from 'react';

import EmptyList from '@components/EmptyList';
import LoadingComponent from '@components/LoadingComponent';
import ModalLoader from '@components/ModalLoader';
import PullToRefresh from '@components/PullToRefresh';
import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationNftRoyal, {
    NAVIGATION_NFT_ROYAL,
} from '@navigation/NavigationBar/NavigationNftRoyal';

import ActiveOrderFilter from '../../components/ActiveOrderFilter';
import GamesFeatures from '../../components/GamesFeatures';
import NftRoyalActiveOrdersListItemHandler from '../../components/NftRoyalActiveOrdersListItemHandler';
import NftRoyalConfirmPlayModal from '../../components/NftRoyalConfirmPlayModal';
import NftRoyalHeader from '../../components/NftRoyalHeader';
import useNftRoyalActiveOrderScreen from './hooks/useNftRoyalActiveOrderScreen';
import stylesMain from './styles';

const NftRoyalActiveOrderScreen = () => {
    const styles = useThemedStyles(stylesMain);
    const {
        handleRefresh,
        isRefresh,

        handleChooseFilter,
        filter,
        filterLoading,
        orders,
        isSmallLayout,
        handleCreateGame,
        loadMore,
        getOrdersLoadMore,
        isLoading,
        renderPreviousGameModal,
        renderPreviousGameModalOrder,
        onOrderListItemPress,
        nftRoyaleGameConfig,
        confirmPlayModal,
        closeConfirmPlay,
        canLoadMore,
        showModalLoader,
    } = useNftRoyalActiveOrderScreen();

    const headerComponent = useCallback(() => {
        return (
            <>
                {!!orders && !!orders.length && (
                    <>
                        <GamesFeatures
                            onLight
                            activeGamesCount={
                                nftRoyaleGameConfig?.activeGamesCount
                            }
                            totalPricePoolIguAmountWei={
                                nftRoyaleGameConfig?.totalPricePoolIguAmountWei
                            }
                            customStyle={styles.feature}
                        />
                        <ActiveOrderFilter
                            onSelect={handleChooseFilter}
                            selectedItem={filter}
                        />
                    </>
                )}
            </>
        );
    }, [orders, nftRoyaleGameConfig, filter, handleChooseFilter]);

    const emptyComponent = useCallback(() => {
        return (
            <View
                style={
                    isSmallLayout
                        ? styles.emptyStateWrapperSmall
                        : styles.emptyStateWrapper
                }>
                <EmptyList
                    containerStyle={styles.emptyList}
                    emptyTitle={i18n.t('HomeScreen.VacantRoomsEmpty.title')}
                    emptyText={i18n.t('HomeScreen.VacantRoomsEmpty.text')}
                    emptyButtonText={i18n.t('general.BUTTONS.createNewGame')}
                    withAnimation="cry"
                    onPressButton={handleCreateGame}
                    loading={showModalLoader}
                    disabled={showModalLoader}
                />
            </View>
        );
    }, [isSmallLayout, handleCreateGame, showModalLoader]);

    return (
        <View style={styles.main}>
            <NftRoyalHeader />

            <ModalLoader isVisible={showModalLoader} />
            <NavigationNftRoyal
                currentNavigation={NAVIGATION_NFT_ROYAL.ORDERS}
            />
            <View style={styles.content}>
                {isLoading && !orders ? (
                    <LoadingComponent text={i18n.t('general.loading')} />
                ) : (
                    <PullToRefresh
                        refreshing={isRefresh}
                        textLoading={i18n.t('pullToRefresh.loading')}
                        onRefresh={handleRefresh}
                        HeaderComponent={headerComponent()}
                        EmptyComponent={emptyComponent()}
                        renderData={orders || []}
                        loadMore={loadMore}
                        onReachEnd={getOrdersLoadMore}
                        contentLoading={filterLoading}
                        //@ts-ignore ignore item type any
                        renderItem={({ item }) => (
                            <NftRoyalActiveOrdersListItemHandler
                                loading={filterLoading}
                                action={onOrderListItemPress}
                                key={item.id}
                                item={item}
                            />
                        )}
                        canLoadMore={canLoadMore}
                        shouldUseEmptyComponentInFooter
                    />
                )}
            </View>
            {renderPreviousGameModal()}
            {renderPreviousGameModalOrder()}
            <NftRoyalConfirmPlayModal
                isVisible={confirmPlayModal}
                onClose={closeConfirmPlay}
            />
        </View>
    );
};

export default NftRoyalActiveOrderScreen;
