import React from 'react';

import ModalStakingInfo from '@components/Staking/ModalStakingInfo';
import { useAppLoading } from '@contexts/AppLoading/AppLoadingContext';
import { useConfig } from '@contexts/ConfigContext';
import { useModal } from '@contexts/ModalContext';
import { useStaking } from '@contexts/StakingContext';
import { useUser } from '@contexts/UserContext';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { useVisibleHook } from './useVisibleHook';

const configHook = {
    storageKey: 'useStakingStartInfo.showModal',
};

const useStakingStartInfo = () => {
    const { user } = useUser();
    const { isNewIosVersion, config } = useConfig();
    const { stakingPlans, isIguDrop } = useStaking();
    const { isSplashAnimationComplete } = useAppLoading();
    const {
        isVisible: isGlobalModalVisible,
        setModalClosed,
        setModalOpened,
    } = useModal();

    const { isVisible, close, open } = useVisibleHook(
        undefined,
        setModalOpened,
        setModalClosed
    );

    const storageKey = React.useMemo(
        () => `${configHook.storageKey}-${`${user?.id}`}`,
        [user?.id]
    );

    const canShow = React.useCallback(async () => {
        if (!isSplashAnimationComplete) {
            return false;
        }
        if (config?.listing) {
            return false;
        }
        if (isGlobalModalVisible) {
            return false;
        }

        const key = await AsyncStorage.getItem(storageKey);
        return !key;
    }, [
        isSplashAnimationComplete,
        storageKey,
        isGlobalModalVisible,
        config?.listing,
    ]);

    const setAlreadyShown = React.useCallback(async () => {
        if (storageKey) {
            await AsyncStorage.setItem(storageKey, 'shown');
        }
    }, [storageKey]);

    React.useEffect(() => {
        const check = async () => {
            if (!user) {
                return;
            }

            const status = await canShow();
            if (status) {
                setAlreadyShown();
                open();
            }
        };

        check();
    }, [canShow, user]);

    const renderStakingModal = React.useCallback(() => {
        if (!stakingPlans?.length) {
            return;
        }

        return (
            <ModalStakingInfo
                isVisible={isVisible}
                closeAction={close}
                withNavigation
            />
        );
    }, [isVisible, stakingPlans, isIguDrop, isNewIosVersion]);

    return {
        renderStakingModal,
    };
};

export default useStakingStartInfo;
