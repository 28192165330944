import { Rank } from '@Data/Models';
import { toFixedIfNecessary } from '@helpers/helpers';

import { balanceFromWei } from './wallet';

export const ranksConfig = {
    diamondIguReward: 500,
};

export const rankIndexBySlug = (ranks: Rank[], slug: string) =>
    ranks.findIndex((val) => val.slug === slug);

export const getRankBySlug = (ranks: Rank[], rankSlug: string, next = 0) => {
    const index = rankIndexBySlug(ranks, rankSlug);
    return index !== -1 ? ranks[index + next] : undefined;
};

export const isAvailableForMint = (
    rank: Rank | undefined,
    canUsePayments: boolean
) => {
    // Fix Sentry error:  undefined is not an object (evaluating 'n.whitelistFreeMintsCount')
    if (!rank) {
        return false;
    }

    if (rank.whitelistFreeMintsCount) {
        return true;
    }

    // free rank condition
    if (rank.slug === 'free') {
        return rank.available;
    }

    if (rank.lootboxFreeMintsCount) {
        return true;
    }

    // can use payments condition
    if (!canUsePayments) {
        return false;
    }

    // otherwise return backend availability
    return rank.available;
};

export const getHighestAvailableRank = (
    ranks: Rank[],
    canUsePayments: boolean
) =>
    [...ranks]
        .reverse()
        .find((item) => isAvailableForMint(item, canUsePayments));

export const formatDailyReward = (amount?: string, full?: boolean) => {
    if (!amount) {
        return '';
    }

    return full
        ? balanceFromWei(amount).value
        : balanceFromWei(amount).valueLong;
};
export const formatBonusReward = (
    percentage: number,
    amount?: string,
    full?: boolean
) => {
    if (!amount) {
        return '';
    }

    return formatDailyReward(
        ((Number(amount) * percentage) / 100).toFixed().toString(),
        full
    );
};

export const getDailyFoodConsumption = (num: number | undefined) => {
    if (!num) {
        return '';
    }
    return `${num}%`;
};

export const formatRewardModifier = (amount?: number) => {
    if (!amount) {
        return `0%`;
    }

    return `+${toFixedIfNecessary(amount, 2)}%`;
};
