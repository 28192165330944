import * as React from 'react';

import Animation from '@components/Animation';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import ModalShareOrSave from '@components/ModalShareOrSave';
import { View } from '@components/Themed';
import { useDimensions } from '@contexts/DimensionsContext';
import useSharePhoto from '@hooks/useShare';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';

import { AchievementsDetailsProps } from '../../../../types';
import stylesMain from './styles';

const AchievementDetailsScreen = ({ route }: AchievementsDetailsProps) => {
    const styles = useThemedStyles(stylesMain);

    const { windowWidth } = useDimensions();

    const { backgroundColor, templateUrl, type, title } =
        route.params.achievement;

    const {
        isVisible: shareModal,
        open: openShareModal,
        close: closeShareModal,
    } = useVisibleHook();

    const { handleShareLink, handleSaveLink, renderCameraRollPermissions } =
        useSharePhoto();

    const handleSaveToCameraRoll = async () => {
        return handleSaveLink(templateUrl);
    };

    const handleShare = async () => {
        return handleShareLink(templateUrl);
    };

    return (
        <View style={[styles.main, { backgroundColor }]}>
            <NavigationBar
                filled={false}
                isOneColor
                backIcon="leftArrow"
                fillColor={'transparent'}
            />

            <View style={styles.content}>
                <BlockHeader
                    emptyStateIcon={
                        <Animation
                            animation={type}
                            style={{ width: windowWidth / 1.7 }}
                        />
                    }
                    titleStyle={styles.titleEmptyState}
                    textStyle={styles.textEmptyState}
                    type={'emptyState'}
                    title={i18n.t('Achievements.unlocked', {
                        achievement: title,
                    })}
                    text={i18n.t('Achievements.unlockedText')}
                />
            </View>
            <ButtonsBottom
                isOneColor
                buttonType="outline"
                containerStyle={styles.transparent}
                title={i18n.t('Achievements.button')}
                onPress={openShareModal}
                safe
            />
            <ModalShareOrSave
                isVisible={shareModal}
                setIsVisible={closeShareModal}
                handleSaveToCameraRoll={handleSaveToCameraRoll}
                handleShare={handleShare}
                renderPermissions={renderCameraRollPermissions}
            />
        </View>
    );
};
export default AchievementDetailsScreen;
