import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import { verticalScale } from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        headerText: {
            marginTop: verticalScale(20),
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
            marginBottom: verticalScale(16),
        },
    });

export default styles;
