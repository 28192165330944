// eslint-disable-next-line import/extensions
import basicFile from './index.js';

const es = {
    ...basicFile,
    title: {
        suffix: 'IguVerse',
        Main: 'Principal',
        Play: 'Jugar',
        Charity: 'Caridad',
        Collection: 'Colección',
        Marketplace: 'Mercado',
        Home: 'Inicio',
        //MysteryLikes
        HowMysteryLikesWork: 'The Mystery Of Likes - ¿Cómo funciona?',
        WelcomeHowMysteryLikesWork: 'The Mystery Of Likes - Bienvenido',
        MysteryLikesMain: 'The Mystery Of Likes - Principal',
        MysteryLikesActiveOrder: 'The Mystery Of Likes - Órdenes activas',
        MysteryLikesHistory: 'The Mystery Of Likes - Historia de los juegos',
        MysteryLikesCreateGameWelcome:
            'The Mystery Of Likes - Crear bienvenida',
        MysteryLikesCreateGameInput: 'The Mystery Of Likes - Cree',
        MysteryLikesCreateGameSuccess: 'The Mystery Of Likes - Éxito',
        MysteryLikesSelectRange: 'The Mystery Of Likes - Seleccionar gama',
        MysteryLikesSelectRangeFinish:
            'The Mystery Of Likes - Información sobre el juego',

        //
        // NFT Evolution
        HowNftEvolutionWelcome: 'NFT Evolution - ¿Cómo funciona?',
        NftEvolutionWelcome: 'NFT Evolution - Bienvenido',
        NftEvolutionSelectPets: 'NFT Evolution',
        NftEvolutionCheckout: 'NFT Evolution - Oportunidades',
        NftEvolutionSuccess: 'NFT Evolution - Éxito',
        NftEvolutionSelectPet: 'NFT Evolution',
        NftEvolutionMintPet: 'NFT Evolution - Mint',
        NftEvolutionMintPetSuccess: 'NFT Evolution - Éxito',
        //
        //NftRoyal
        HowNftRoyalWork: 'The Grand Race - Cómo funciona',
        WelcomeNftRoyalWork: 'The Grand Race - Bienvenida',
        NftRoyalMain: 'The Grand Race - Principal',
        NftRoyalActiveOrder: 'The Grand Race - Habitaciones Vacantes',
        NftRoyalCreateGameWelcome: 'The Grand Race - Crear',
        NftRoyalGameConfigurations:
            'The Grand Race - Configuraciones del juego',
        NftRoyalEnterCoinsAmount:
            'The Grand Race - Seleccionar cantidad de monedas',
        NftRoyalCreateGameSuccess: 'The Grand Race - Crear partida con éxito',
        NftRoyalHistory: 'The Grand Race - Historia',
        NftRoyalGameHistory: 'The Grand Race - Historia del Juego',
        NftRoyalPlayGame: 'The Grand Race',
        //
        AdminRoot: 'Admin',
        AdminVerify: 'Admin - Verificar',
        AdminSendPushNotification: 'Admin - Enviar Notificación Push',
        AdminReverification: 'Admin - Reverificación',
        AdminBlockUser: 'Admin - Bloquear usuario',
        AdminMaintenanceMode: 'Admin - Modo de mantenimiento',
        AdminAssignWhiteList: 'Admin - Asignar lista blanca',
        AdminAssignRole: 'Admin - Asignar función',
        MintRoot: 'Menta',
        MintPetRanks: 'Mint elige rangos',
        MintPetSelectGeneration: 'Método Mint Select',
        MintGenerationInfo: 'Información',
        MintSelectGeneratedNft: 'Mint Select NFT',
        MintPetPickPhoto: 'Mint Escoger Foto',
        MintPetEditPhoto: 'Mint Editar Foto',
        MintPetName: 'Mint Elija el Nombre de tu Mascota',
        MintPetCheckout: 'Mint Verificar',
        MintPetCheckoutSuccess: 'Mint Pago Exitoso',
        AddWalletInitial: 'Añadir Cartera',
        ImportWallet: 'Importar Cartera',
        CreateWalletFirst: 'Crear Cartera',
        CreateWalletPassphrase: 'Crear Semilla de Cartera',
        CreateWalletVerification: 'Verificar Semilla',
        CreateWalletSuccess: 'Éxito de la Cartera',
        Login: 'Inicio de Sesión',
        WalletRoot: 'Cartera',
        WalletCoinsMain: 'Cartera - Monedas',
        WalletNFTSMain: 'Cartera - NFT',
        WalletRewardsMain: 'Cartera - Recompensas',
        //Gaming
        WalletGamingTab: 'Cartera - Juego',
        WalletGamingMain: 'Cartera - Juego',
        TopUpGamingWallet: 'Cartera - Completar',
        WithdrawInputAmount: 'Cartera - Retirar',
        TopUpCheckoutScreen: 'Cartera - Completar Verificar',
        TopUpSuccessScreen: 'Cartera - Completar Pago Exitoso',
        WithdrawIguSuccessScreen: 'Cartera - Retirar Éxito',
        GamingWalletTransactionDetails: 'Cartera - Detalles de la Transacción',
        //
        WalletCoinsBalance: 'Cartera - Saldo de Monedas',
        WalletCoinsWithdraw: 'Cartera - Retirar',
        WalletCoinsWithdrawSuccess: 'Cartera - Retirar Activo con Éxito',
        WalletCoinsTransactionDetails: 'Cartera - Detalles de la Transacción',
        WalletNFTSList: 'Cartera - Lista de NFT',
        WalletNFTSWithdraw: 'Cartera - Retirada de NFT',
        WalletNFTSWithdrawSuccess: 'Cartera - NFTs Retirada con éxito',
        WalletNFTSTransactionDetails:
            'Cartera - Detalles de la Transacción NFT',
        WalletRewardsClaimSuccess: 'Cartera - Cobro de Recompensas',
        WalletRewardsTransactionDetails: 'Cartera - Detalles de la Transacción',
        WalletRewardsTab: 'Cartera - Recompensas',
        WalletTradeMain: 'Cartera - Operación Principal',
        WalletTradeChart: 'Cartera - Cuadro de Operaciones',
        WalletTradeSuccess: 'Cartera - Éxito de la Operación',
        WalletSettings: 'Cartera - Ajustes',
        WalletSettingsBackup: 'Cartera - Copia de Seguridad de Ajustes',
        StakingWelcome: 'Cartera - Impulse de Bienvenida',
        StakingOptions: 'Cartera - Opciones de Impulse',
        StakingPlans: 'Cartera - Impulse Planes',
        StakingDetailedInfo:
            'Cartera - Información detallada sobre operaciones de Impulse',
        StakingCoinsInput: 'Cartera - Ingreso de Monedas',
        StakingDailyBoost: 'Cartera - Boost Diario de Impulse',
        StakingSuccess: 'Cartera - Éxito',
        StakingAlready: 'Cartera - Realizar Impulse ya',
        UnStakingSuccess: 'Cartera - Éxito',
        PlayTasks: 'Tareas',
        PlayTasksActive: 'Tareas Activas',
        PlayTasksHistory: 'Historial de Tareas',
        PlayEditNFTs: 'Editar NFT',
        PlayTasksHistoryDetails: 'Detalles del Historial de Tareas',
        PlayCollectedExperience: 'Experiencia Acumulada',
        PlaySuccessScreen: 'Éxito de la Tarea',
        SocializeToEarnWelcomeShare: 'SocializeToEarn - Bienvenido',
        SocializeToEarnShareYourPhoto: 'SocializeToEarn - Comparte tu Foto',
        SocializeToEarnCreateSharePhoto:
            'SocializeToEarn - Crear Compartir Foto',
        SocializeToEarnVerifyUser: 'SocializeToEarn - Verificar Usuario',
        SocializeToEarnCreateShareTemplate:
            'SocializeToEarn - Compartir con Plantilla',
        SocializeToEarnVerifyUserError:
            'SocializeToEarn - Verificar Error de Usuario',
        SocializeToEarnShareYourPhotoSuccess:
            'SocializeToEarn - Comparte el Éxito fotográfico',
        SocializeToEarnWelcomeVerify: 'SocializeToEarn - Bienvenido Verificar',
        SocializeToEarnVerifyUserMatch:
            'SocializeToEarn - Verificar Coincidencia de Usuario',
        SocializeToEarnVerifyUserNotFound:
            'SocializeToEarnVerify - Usuario no Encontrado',
        SocializeToEarnVerifyUserFailed:
            'SocializeToEarn - Error al Verificar el Usuario',
        SocializeToEarnVerifyUserAlreadyCompleted:
            'SocializeToEarn - Verificar Usuario ya Completado',
        MoveToEarnWelcome: 'MoveToEarn - Bienvenido',
        MoveToEarnProgress: 'MoveToEarn - Progreso',
        MoveToEarnSummary: 'MoveToEarn - Resumen',
        PlayToEarn: 'PlayToEarn',
        PlayToEarnWelcome: 'PlayToEarn - Bienvenido',
        PlayToEarnProgress: 'PlayToEarn - Jugar',
        CharityOrganizations: 'Caridad - Organizaciones',
        CharityDetailedOrganization: 'Caridad - Organización Detallada',
        CharityOrganizationDonations: 'Caridad - Organizaciones Benéficas',
        CharityEditSelectedOrganization:
            'Caridad - Editar la Organización Seleccionada',
        CharityEditDetailedOrganization:
            'Caridad - Editar Organización Detallada',
        CollectionMain: 'Colección',
        CollectionDetailedPet: 'Colección - Mi Mascota',
        CollectionPetLevelUpCheckout:
            'Colección - Comprobar Subida de Nivel de Mascota',
        CollectionPetLevelUpSuccess:
            'Colección - Éxito de la Subida de Nivel de Mascota',
        CollectionPetRankUpCheckout:
            'Colección - Comprobar Subida de Rango de Mascota',
        CollectionPetRankUpSuccess:
            'Colección - Éxito de la Subida de Rango de Mascota',
        CollectionPetHealUpCheckout:
            'Colección - Comprobar Subida de Rango de Mascota',
        CollectionPetHealUpSuccess:
            'Colección - Éxito de la Subida de Salud de Mascota',
        CollectionPetReviveCheckout:
            'Colección - Comprobar Restauración de Mascta',
        CollectionPetReviveSuccess: 'Colección - Éxito Restauración de Mascota',
        CollectionPetAgeReviveCheckout:
            'Colección - Comprobar Restauración de Edad de Mascota',
        CollectionPetAgeReviveSuccess:
            'Colección - Éxito Restauración Edad de Mascota',
        CollectionMultiplePetsHealUpProceeding:
            'Colección - Curar Todas las Mascotas',
        CollectionMultiplePetsHealUpCheckout:
            'Colección - Comprobar Alimentación de Mascotas',
        CollectionMultiplePetsHealUpSuccess:
            'Colección - Éxito de Alimentación de Mascotas',
        ProfileRoot: 'Perfil',
        ProfileMain: 'Perfil - Principal',
        ProfileLanguagesSettings: 'Perfil - Configuración de Idiomas',
        ProfileNotificationsSettings:
            'Perfil - Configuración de Notificaciones',
        ProfileSocialAccountsSettings: 'Perfil - Ajustes de Redes Sociales',
        ProfileSocialAccessibilitySettings: 'Perfil - Ajustes de Accesibilidad',
        ProfileSocialMedia: 'Perfil - Redes Sociales',
        Dev: 'Dev',
        RanksDetailedInfo: 'Información Detallada de Rangos',
        EnergyInfoScreen: 'Pantalla con Información de Energía',
        Referrals: 'Referencias',
        ReferralsAll: 'Referencias - Todos',
        ReferralsInvites: 'Referencias - Invitaciones',
        ReferralsInvite: 'Referencias - Invitar',
        ReferralsHowItsWork: 'Referencias - Cómo funciona',
        // LEADERBOARD
        Leaderboard: 'Clasificación',
        LeaderboardDailyTasks: 'Clasificación - Tarea',
        LeaderboardPvpGames: 'Clasificación - PvP',
        //
        MysteryBoxMain: 'Cajas Misteriosas',
        MysteryBoxPurchaseWelcome: 'Cajas Misteriosas - Bienvenida a la Compra',
        MysteryBoxPurchase: 'Cajas Misteriosas - Comprar',
        MysteryBoxPurchaseCheckout: 'Cajas Misteriosas - Pago de la Compra',
        MysteryBoxPurchaseSuccess: 'Cajas Misteriosas - Éxito de la Compra',
        HowToCharity: 'Cómo funciona la Beneficencia',
        HowToPlay: 'Cómo Jugar',
        HowToReferrals: 'Referencias - Cómo Funciona',
        HowToAvailableRewards: 'Cómo Funcionan las Recompensas Disponibles',
        HowToLockedRewards: 'Cómo Funciona el Bloqueo Disponible',
        HowToGamingAvailableWallet: 'Cómo funciona la Monedero disponible',
        HowToGamingPendingWallet: 'Cómo funciona la Cartera pendiente',
        HowToSocializeToEarn: 'Cómo Compartir',
        HowToVerifyUser: 'Cómo Encontrar Pet ID',
        HowToPurchaseBNB: 'Cómo comprar BNB',
        HowToPurchaseSpadeId: 'Cómo usar ID de SPACE ID',
        HowToCommissionFee: 'Tasa',
        HowToFee: 'Tasa',
        BonusMintRanksInfo: 'Mint - Bonus de Rangos',
        BonusMintRanksCarousel: 'Mint - Bonus para Todos los Rangos',
        InitialMint: 'Mintear a tu Primera Mascota',
        InitialReferral: 'Has sido Invitado a IguVerse',
        YourPetsAreHungry: 'Sus Mascotas Tienen Hambre',
        UpdateApp: 'Nueva Actualización',

        //ONBOARDING
        MainWalletOnBoardingScreen: 'Principal Cartera',
        LoginOnBoardingScreen: 'Bienvenida',

        //MARKETPLACE
        MarketPlaceActiveOrders: 'Mercado - Pedidos activos',
        MarketPlaceMyProfile: 'Mercado - Mi perfil',
        MarketPlaceProfile: 'Mercado - Perfil',
        MarketPlaceWelcomeCreateOrder: 'Mercado - Bienvenido',
        MarketPlaceSelectNft: 'Mercado - Seleccionar NFT',
        MarketPlaceSelectNftPrice: 'Mercado - Seleccionar precio',
        MarketPlaceOverviewGroup: 'Mercado - Visión general',
        MarketPlaceOverview: 'Mercado - Visión general',
        MarketPlaceOverviewOwnerHistory: 'Mercado - Historial de propietarios',
        MarketPlaceBuyCheckout: 'Mercado - Pago',
        MarketPlaceCreateOrderSuccess: 'Mercado - Éxito',
        MarketPlaceDelistSuccess: 'Mercado - Éxito',
        MarketPlaceBuySuccess: 'Mercado - Éxito',

        //Achievements
        Achievements: 'Logros',
        AchievementsDetails: 'Logros - Información',

        //PassCode
        PassCodeFaceId: 'Código de acceso - Biometría',
        PassCodeNew: 'Código de acceso - Activar',
        PassCodeChange: 'Código de acceso - Cambiar',
        PassCodeSuccess: 'Código de acceso - Éxito',
        PassCodeSettings: 'Código de acceso - Configuración',
        PassCodeConfirm: 'Código de acceso - Confirmar',

        //Lucky Strike
        WelcomeLuckyStrike: 'Lucky Strike - Bienvenido',
        LuckyStrike: 'Lucky Strike',
        LuckyStrikeMain: 'Lucky Strike - Rondas',
        LuckyStrikeHistory: 'Lucky Strike - Historia',
        HowLuckyStrikeWork: 'Lucky Strike - Cómo funciona',
        LuckyStrikeAllParticipants: 'Lucky Strike - Participantes',
        LuckyStrikeWaitingForResult: 'Lucky Strike - Resultado',
        LuckyStrikeUserTickets: 'Lucky Strike - Entradas',
        LuckyStrikeUserTicketsWait: 'Lucky Strike - Entradas',
        LuckyStrikeAllWinners: 'Lucky Strike - Ganadores',
        LuckyStrikeGameHistory: 'Lucky Strike - Historia',

        //Quantum Disk
        WelcomeQuantumSpin: 'Quantum Disk - Bienvenido',
        HowQuantumSpinWork: 'Quantum Disk - Cómo funciona',
        QuantumSpin: 'Quantum Disk',
        QuantumSpinMain: 'Quantum Disk - Rueda',
        QuantumSpinHistory: 'Quantum Disk - Historia',
        QuantumSpinGameHistory: 'Quantum Disk - Historia',
        QuantumSpinProbablyFair: 'Quantum Disk - Provablemente Justo',
        QuantumSpinProbablyFairHistory: 'Quantum Disk - Historia',
        //TokenTeller
        WelcomeTokenTeller: 'Tokenteller - Bienvenido',
        TokenTeller: 'Tokenteller',
        TokenTellerMain: 'Tokenteller - Rondas',
        TokenTellerHistory: 'Tokenteller - Historia',
        HowTokenTellerWork: 'Tokenteller - Cómo funciona',
        TokenTellerChart: 'Tokenteller - Gráfico',
        TokenTellerAllParticipants: 'Tokenteller - Participantes',
        TokenTellerWaitingForResult: 'Tokenteller - Esperar',
        TokenTellerGameHistory: 'Tokenteller - Historia',
    },
};

export default es;
