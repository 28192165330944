import * as React from 'react';
import { Image } from 'react-native';

import Colors from '../../constants/Colors';
import { useTheme } from '../../contexts/ThemeContext';

interface IProps {
    type: 'default' | 'blur' | 'glare' | 'cut';
}
export const TipsImages = ({ type }: IProps) => {
    const { theme } = useTheme();
    const iguanaBlur = require('../../assets/icons/mint/tipsImageBlur.png');
    const iguanaGlare = require('../../assets/icons/mint/tipsImageGlare.png');
    const iguanaCut = require('../../assets/icons/mint/tipsImageCut.png');
    const iguanaDefault = require('../../assets/icons/mint/tipsImageDefault.png');
    switch (type) {
        case 'blur':
            return (
                <Image
                    source={iguanaBlur}
                    resizeMode="contain"
                    style={{ height: 62, width: 53 }}
                />
            );
        case 'glare':
            return (
                <Image
                    source={iguanaGlare}
                    resizeMode="contain"
                    style={{ height: 62, width: 62 }}
                />
            );
        case 'cut':
            return (
                <Image
                    source={iguanaCut}
                    resizeMode="contain"
                    style={{
                        height: 62,
                        width: 53,
                        backgroundColor: Colors[theme].surface.tertiary,
                    }}
                />
            );
        default:
            return (
                <Image
                    source={iguanaDefault}
                    style={{ height: 62, width: 53 }}
                />
            );
    }
};
