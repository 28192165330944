import * as React from 'react';
import { useMemo } from 'react';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

import Colors from '@constants/Colors';
import { useTheme } from '@contexts/ThemeContext';
import useThemedStyles from '@hooks/useThemedStyles';

import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    amount: number;
    maxAmount: number;
    containerStyle?: StyleProp<ViewStyle>;
    progressStyle?: StyleProp<ViewStyle>;
    progressColor?: string;
    textStyle?: StyleProp<TextStyle>;
}

const ProgressBar = ({
    amount,
    maxAmount,
    containerStyle,
    progressColor,
    progressStyle,
    textStyle,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { theme } = useTheme();

    const percent = useMemo(
        () => (amount / maxAmount) * 100,
        [amount, maxAmount]
    );

    const progressColored = React.useMemo(() => {
        if (percent > 0 && percent < 25) {
            return Colors[theme].energyProgress.low;
        }
        if (percent >= 25 && percent < 50) {
            return Colors[theme].energyProgress.high;
        }

        return Colors[theme].energyProgress.full;
    }, [percent]);

    return (
        <View style={[styles.container, containerStyle]}>
            <View style={[styles.progress, progressStyle]}>
                <View
                    style={[
                        styles.fill,

                        {
                            width: `${percent}%`,
                            backgroundColor: progressColor || progressColored,
                        },
                    ]}></View>
            </View>
            <Text
                style={[
                    styles.energy,
                    textStyle,
                ]}>{`${amount} / ${maxAmount}`}</Text>
        </View>
    );
};
export default ProgressBar;
