import * as React from 'react';
import { Dispatch, SetStateAction, useState } from 'react';

import { isWeb } from '@helpers/app';
import { waitForShare } from '@helpers/helpers';
import { toastSuccess } from '@helpers/toastNotification';

import useThemedStyles from '../../hooks/useThemedStyles';
import i18n from '../../i18n/i18n';
import ButtonCard from '../ButtonCard';
import Icon, { ICON_NAMES } from '../Icons';
import ModalBottom from '../ModalBottom';
import { View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    setIsVisible: Dispatch<SetStateAction<boolean>>;
    handleSaveToCameraRoll: () => Promise<any>;
    handleShare: () => Promise<any>;
    renderPermissions: (action: () => void) => React.ReactNode;
    children?: React.ReactNode;
}

const ModalShareOrSave = ({
    isVisible,
    setIsVisible,
    handleSaveToCameraRoll,
    handleShare,
    renderPermissions,
    children,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const [isLoadingShare, setIsLoadingShare] = useState<boolean>(false);
    const [isLoadingDownload, setIsLoadingDownload] = useState<boolean>(false);

    const handleClose = () => {
        setIsVisible(false);
    };

    const onCameraRoll = async () => {
        try {
            setIsLoadingDownload(true);
            await waitForShare();
            await handleSaveToCameraRoll();
            toastSuccess(
                undefined,
                i18n.t('ModalShareOrSave.saveSuccess'),
                'top'
            );
        } catch (error) {
            Console.error(error);
        } finally {
            setIsLoadingDownload(false);
        }
    };

    const onShare = async () => {
        setIsLoadingShare(true);
        await waitForShare();
        await handleShare().finally(() => setIsLoadingShare(false));
    };

    return (
        <ModalBottom
            isVisible={isVisible}
            onClose={handleClose}
            titleText={i18n.t('ModalShareOrSave.title')}
            additionalText={i18n.t('ModalShareOrSave.text')}
            modalHeight={400}>
            <View style={styles.container}>
                <View style={styles.buttonWrapper}>
                    {!isWeb && (
                        <ButtonCard
                            onPress={onCameraRoll}
                            debouncedPress
                            containerStyle={styles.button}
                            icon={
                                <Icon
                                    name={ICON_NAMES.DOWNLOAD}
                                    size={16}
                                    color="onDark"
                                />
                            }
                            title={i18n.t('ModalShareOrSave.save')}
                            loading={isLoadingDownload}
                            loadingText={i18n.t('ModalShareOrSave.loading')}
                        />
                    )}
                    <ButtonCard
                        onPress={onShare}
                        debouncedPress
                        containerStyle={styles.button}
                        icon={
                            <Icon
                                name={ICON_NAMES.SHARE}
                                size={16}
                                color="onDark"
                            />
                        }
                        title={i18n.t('ModalShareOrSave.share')}
                        loading={isLoadingShare}
                        loadingText={i18n.t('ModalShareOrSave.loading')}
                    />
                </View>
            </View>
            {renderPermissions(handleSaveToCameraRoll)}
            {children}
        </ModalBottom>
    );
};

export default ModalShareOrSave;
