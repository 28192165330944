import { StyleSheet } from 'react-native';

import Colors from '../../../constants/Colors';
import { scale } from '../../../helpers/dimensions';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        dash: {
            flex: 1,
            height: scale(4),
            marginRight: scale(6),
            borderRadius: 1000,
            backgroundColor: Colors[theme].stageIndicatorDefault,
        },
        dashFill: {
            height: scale(4),
            backgroundColor: Colors[theme].stageIndicatorActive,
            width: 0,
            borderRadius: 1000,
        },
        lastChild: {
            marginRight: 0,
        },
    });

export default styles;
