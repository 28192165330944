import * as React from 'react';
import {
    Image,
    ImageStyle,
    StyleProp,
    TextStyle,
    ViewStyle,
} from 'react-native';

import ActivityIndicator from '@components/ActivityIndicator';
import { isWeb } from '@helpers/app';
import { useLoadingHook } from '@hooks/useLoadingHook';

import useThemedStyles from '../../hooks/useThemedStyles';
import { View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    uri: string;
    containerStyles?: StyleProp<ViewStyle>;
    imageStyles?: StyleProp<ImageStyle>;
    isOneTheme?: boolean;
    borderRadius?: number;
}

const ImageExtended = ({
    uri,
    containerStyles,
    imageStyles,
    isOneTheme,
    borderRadius,
}: IProps) => {
    const styles = useThemedStyles(stylesMain, isOneTheme);

    const { isLoading, startLoading, stopLoading } = useLoadingHook(!isWeb);

    return (
        <View style={containerStyles}>
            {isLoading && (
                <View style={styles.loadingWrapper}>
                    <ActivityIndicator
                        loading={true}
                        size={'large'}
                        containerStyle={styles.loading}
                    />
                </View>
            )}
            <Image
                resizeMode="contain"
                style={[styles.image, { borderRadius }, imageStyles]}
                source={{ uri }}
                onLoadStart={!isWeb ? startLoading : undefined}
                onLoad={!isWeb ? stopLoading : undefined}
            />
        </View>
    );
};
export default ImageExtended;
