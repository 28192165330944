import Toast, { ToastPosition } from 'react-native-toast-message';

import i18n from '../i18n/i18n';
import { verticalScale } from './dimensions';

export function toastSuccess(
    text1?: string,
    text2?: string,
    position?: ToastPosition
) {
    Toast.show({
        type: 'success',
        text1: text1 ?? i18n.t('general.success.title'),
        text2,
        position: position || undefined,
        visibilityTime: 2000,
    });
}

export function toastError(
    text1?: string,
    text2?: string,
    position?: ToastPosition,
    visibilityTime?: number
) {
    Toast.show({
        type: 'error',
        text1: text1 ?? i18n.t('general.errors.error.title'),
        text2,
        position: position || undefined,
        visibilityTime: visibilityTime || 3000,
    });
}

export function toastComingSoon(text?: string) {
    Toast.show({
        type: 'info',
        text1: i18n.t('general.comingSoon'),
        text2: text || i18n.t('general.comingSoonDesc'),
        visibilityTime: 2000,
    });
}

export function toastErrorUnknown(position?: ToastPosition) {
    Toast.show({
        type: 'error',
        text1: i18n.t('general.errors.unknown.title'),
        text2: i18n.t('general.errors.unknown.message'),
        position: position || undefined,
    });
}

export function toastNotAllowedInExpo() {
    Console.error(
        'This feature is not available in Expo due to managed Expo workflow. Please test it in Development build'
    );
    toastError('Not allowed in Expo');
}

export const toastHide = () => {
    Toast.hide();
};

export const toastBannerMysteryBox = ({
    bottomOffset = verticalScale(110),
    position = 'bottom',
    visibilityTime = 10000,
    onPress = () => undefined,
    onHide = () => undefined,
}: {
    bottomOffset?: number;
    position?: ToastPosition;
    visibilityTime?: number;
    onPress?: () => void;
    onHide?: () => void;
}) => {
    Toast.show({
        type: 'mysteryBoxBanner',
        bottomOffset,
        position,
        visibilityTime,
        onPress,
        onHide,
    });
};
