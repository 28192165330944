import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            backgroundColor: 'transparent',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: verticalScale(12),
        },
        prizesColumn: {
            backgroundColor: 'transparent',
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
            marginRight: scale(12),
        },
        chanceColumn: {
            backgroundColor: 'transparent',
        },
        prize: {
            marginLeft: scale(6),
            flex: 1,
        },

        amountBlock: {
            flexDirection: 'row',
            alignItems: 'center',
            borderRadius: 50,
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(6),
            alignSelf: 'flex-end',
            maxWidth: 100,
        },
        default: {
            backgroundColor: Colors[theme].surfaceDecorativeGrayMinimal,
        },
        opacity: {
            backgroundColor: Colors[theme].background,
        },
        chance: {
            color: Colors[theme].text.gray,
        },
        darkDefault: {
            color: Colors[theme].text.white,
        },
    });

export default styles;
