import * as React from 'react';
import { TouchableOpacity } from 'react-native';
import { SvgProps } from 'react-native-svg';

import SuccessIcon from '@assets/icons/toastCard/success.svg';
import YellowClockIcon from '@assets/icons/toastCard/yellow-clock.svg';
import FacebookInfo from '@components/FacebookInfo';
import { useConfig } from '@contexts/ConfigContext';
import { useUser } from '@contexts/UserContext';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import AlertCard from '../../AlertCard';
import Button from '../../Button';
import { Text, View } from '../../Themed';
import Tooltip from '../../Tooltip';
import stylesMain from './styles';

interface ISocial {
    id: string;
    text: string;
    icon: React.ReactElement<SvgProps>;
    terms: string[];
}

interface IProps {
    onConnectPress?: () => void;
    onDisconnectPress?: () => void;
    social: ISocial;
}

interface StatusIconProps {
    text: string;
    children: React.ReactNode;
}

const SocialMediaItem = ({
    onConnectPress,
    onDisconnectPress,
    social,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const [isTooltipVisible, setIsTooltipVisible] = React.useState(false);
    const iconSize = 20;
    const { user } = useUser();
    const { config, isNewIosVersion } = useConfig();

    const renderConnect = (item: ISocial) => {
        const isEnabled = config?.enabledSocialPlatforms.includes(
            item.id.toUpperCase()
        );

        return (
            <>
                <View style={styles.left}>
                    <View
                        style={{
                            width: styles.icon.width,
                            height: styles.icon.height,
                        }}>
                        {item.icon}
                    </View>
                    <Text style={styles.itemText}>{item.text}</Text>
                </View>
                <Button
                    size="sm"
                    onPress={() => onConnectPress?.()}
                    disabled={!isEnabled}
                    title={i18n.t('socialMediaConnect.connect')}
                />
            </>
        );
    };

    const StatusIcon = ({ children, text }: StatusIconProps) => (
        <Tooltip
            timeout={3000}
            isVisible={isTooltipVisible}
            text={<Text style={styles.tooltipText}>{text}</Text>}
            placement="top"
            onClose={() => {
                setIsTooltipVisible(false);
            }}>
            <TouchableOpacity onPress={() => setIsTooltipVisible(true)}>
                {children}
            </TouchableOpacity>
        </Tooltip>
    );

    const renderStatusIcon = (isVerified: boolean | undefined) => {
        if (isVerified === undefined) {
            return null;
        }

        if (isVerified) {
            return (
                <StatusIcon text={i18n.t('socialMediaConnect.status.success')}>
                    <SuccessIcon width={iconSize} height={iconSize} />
                </StatusIcon>
            );
        }

        return (
            <StatusIcon text={i18n.t('socialMediaConnect.status.moderating')}>
                <YellowClockIcon width={iconSize} height={iconSize} />
            </StatusIcon>
        );
    };

    const renderDisconnect = (item: ISocial) => {
        const socialInfo = (() => {
            switch (item.id) {
                case 'instagram':
                    return user?.instagram;
                case 'tiktok':
                    return user?.tiktok;
                case 'twitter':
                    return user?.twitter;
                case 'facebook':
                    return user?.facebook;
                default:
                    return null;
            }
        })();

        const userAccount = socialInfo?.username;
        const isVerified = socialInfo?.isVerified;

        return (
            <>
                <View style={[styles.left, styles.alignStart]}>
                    <View
                        style={{
                            width: styles.icon.width,
                            height: styles.icon.height,
                        }}>
                        {item.icon}
                    </View>
                    <View style={styles.textWrapper}>
                        <View style={styles.socialContainer}>
                            <Text
                                style={[
                                    styles.itemText,
                                    styles.itemTextMargin,
                                ]}>
                                {item.text}
                            </Text>
                            {renderStatusIcon(isVerified)}
                        </View>
                        <Text style={styles.account}>{`@${userAccount}`}</Text>
                    </View>
                </View>

                <Button
                    size="sm"
                    type="critical"
                    onPress={() => onDisconnectPress?.()}
                    title={i18n.t('socialMediaConnect.disconnect')}
                />
            </>
        );
    };

    const renderItem = (item: ISocial) => {
        const isConnected = user?.[item.id as keyof object];
        const isFacebook = item.id === 'facebook';
        const isEnabled = config?.enabledSocialPlatforms.includes(
            item.id.toUpperCase()
        );

        // Disable not active Social media
        if (!isEnabled) {
            return null;
        }

        return (
            <View key={item.id} style={styles.shadow}>
                <View style={styles.itemContainer}>
                    <View style={styles.main}>
                        {isConnected
                            ? renderDisconnect(item)
                            : renderConnect(item)}
                    </View>
                    {!isNewIosVersion &&
                        !!item.terms &&
                        !isConnected &&
                        item.terms.map((val) => (
                            <View style={styles.termsWrapper} key={val}>
                                <View style={styles.term}>
                                    <View style={styles.circle} />
                                    <Text style={styles.termText}>
                                        {i18n.t(val)}
                                    </Text>
                                </View>
                            </View>
                        ))}
                    {!isConnected && isFacebook && (
                        <FacebookInfo
                            customStyles={styles.facebookInfo}
                            onAction={onConnectPress}
                        />
                    )}
                    {!isEnabled && (
                        <View style={styles.alertDisabled}>
                            <AlertCard
                                text={i18n.t('socialMediaConnect.unavailable')}
                            />
                        </View>
                    )}
                </View>
            </View>
        );
    };

    return renderItem(social);
};

export default SocialMediaItem;
