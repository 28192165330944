import React from 'react';

import { SOCIALS_IDS } from '@constants/Socials';

import SocialSelect from '../components/SocialSelect';

const socialSelectConfig = {
    initialSocial: SOCIALS_IDS[0],
};

export const useSocialSelect = () => {
    const [socialValue, setSocialValue] = React.useState<string>(
        socialSelectConfig.initialSocial
    );

    const onSocialSelect = (id: string) => {
        setSocialValue(id);
    };

    const renderSocialSelect = React.useCallback(() => {
        return (
            <SocialSelect
                selectedSocial={socialValue}
                socials={SOCIALS_IDS}
                onPress={onSocialSelect}
            />
        );
    }, [socialValue]);

    const resetSocialSelect = () => {
        setSocialValue(socialSelectConfig.initialSocial);
    };

    return {
        renderSocialSelect,
        resetSocialSelect,
        socialValue,
    };
};
