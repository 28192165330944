// eslint-disable-next-line import/extensions
import basicFile from './index.js';

const uk = {
    ...basicFile,
    title: {
        suffix: 'IguVerse',
        /**
         * BottomTab
         */
        Main: 'Головна',
        Play: 'Play',
        Charity: 'Благодийність',
        Collection: 'Колекція',
        Marketplace: 'Маркетплейс',
        Feed: 'Стіна',
        Home: 'Головна',
        //MysteryLikes
        HowMysteryLikesWork: 'Загадкові лайки - Як це працює',
        WelcomeHowMysteryLikesWork: 'Загадкові лайки - Ласкаво просимо',
        MysteryLikesMain: 'Загадкові лайки - Головна',
        MysteryLikesActiveOrder: 'Загадкові лайки - Активні замовлення',
        MysteryLikesHistory: 'Загадкові лайки - Історія ігор',
        MysteryLikesCreateGameWelcome: 'Загадкові лайки - Створити',
        MysteryLikesCreateGameInput: 'Загадкові лайки - Створити',
        MysteryLikesCreateGameSuccess: 'Загадкові лайки - Успіх',
        MysteryLikesSelectRange: 'Загадкові лайки - Виберіть діапазон',
        MysteryLikesSelectRangeFinish: 'Загадкові лайки - Інформація про гру',

        //
        // NFT - еволюція
        HowNftEvolutionWelcome: 'NFT - еволюція - Як це працює',
        NftEvolutionWelcome: 'NFT - еволюція - Ласкаво просимо',
        NftEvolutionSelectPets: 'NFT - еволюція',
        NftEvolutionCheckout: 'NFT - еволюція - Шанси',
        NftEvolutionSuccess: 'NFT - еволюція - Успіх',
        NftEvolutionSelectPet: 'NFT - еволюція',
        NftEvolutionMintPet: 'NFT - еволюція - Mint',
        NftEvolutionMintPetSuccess: 'NFT - еволюція - Успіх',

        //NftRoyal
        HowNftRoyalWork: 'Фруктові перегони - Як це працює',
        WelcomeNftRoyalWork: 'Фруктові перегони - Ласкаво просимо',
        NftRoyalMain: 'Фруктові перегони - Головна',
        NftRoyalActiveOrder: 'Фруктові перегони - Вільні кiмнати',
        NftRoyalCreateGameWelcome: 'Фруктові перегони - Створити',
        NftRoyalGameConfigurations: 'Фруктові перегони - Налаштування гри',
        NftRoyalEnterCoinsAmount: 'Фруктові перегони - Вказати кількість монет',
        NftRoyalCreateGameSuccess: 'Фруктові перегони - Успіх',
        NftRoyalHistory: 'Фруктові перегони - Iсторія',
        NftRoyalGameHistory: 'Фруктові перегони - Iсторія гри',
        NftRoyalPlayGame: 'Фруктові перегони',
        //

        /**
         * Admin
         */
        AdminRoot: 'Адмін',
        AdminVerify: 'Адмін - Верифікація',
        AdminSendPushNotification: 'Адмін - Надіслати Push-повідомлення',
        AdminReverification: 'Адмін - Реверифікація',
        AdminBlockUser: 'Адмін - Заблокувати користувача',
        AdminMaintenanceMode: 'Admin - Режим обслуговування',
        AdminAssignWhiteList: 'Admin - Надіслати вайтлист',
        AdminAssignRole: 'Admin - Призначити роль',
        /**
         * Mint
         */
        MintRoot: 'Мінт',
        MintPetRanks: 'Мінт - Вибір Рангів',
        MintPetSelectGeneration: 'Мінт - Вибір способvу',
        MintGenerationInfo: 'Мінт - Інформація',
        MintSelectGeneratedNft: 'Мінт - Вибрати NFT',
        MintPetPickPhoto: 'Мінт - Вибрати Фото',
        MintPetEditPhoto: 'Мінт - Редагувати Фото',
        MintPetName: 'Мінт - Вибрати Імя',
        MintPetCheckout: 'Мінт - Оплата',
        MintPetCheckoutSuccess: 'Мінт - Успіх',

        /**
         * CreateWallet
         */
        AddWalletInitial: 'Додати Гаманець',
        ImportWallet: 'Імпортувати Гаманець',
        CreateWalletFirst: 'Створити Гаманець',
        CreateWalletPassphrase: 'Створити Пароль Гаманця',
        CreateWalletVerification: 'Верифікація Гаманця',
        CreateWalletSuccess: 'Успішне створення Гаманця',
        /**
         * LOGIN
         */
        Login: 'Вхід',
        /**
         * Wallet
         */
        WalletRoot: 'Гаманець',
        // Tabs
        WalletCoinsMain: 'Гаманець - монети',
        WalletNFTSMain: 'Гаманець - NFTs',
        WalletRewardsMain: 'Гаманець - Нагороди',
        //Gaming
        WalletGamingTab: 'Гаманець - Ігровий',
        WalletGamingMain: 'Гаманець - Ігровий',
        TopUpGamingWallet: 'Гаманець - Поповнити',
        TopUpCheckoutScreen: 'Гаманець - Оплата',
        TopUpSuccessScreen: 'Гаманець - Успіх',
        WithdrawIguSuccessScreen: 'Гаманець - Успіх',
        GamingWalletTransactionDetails: 'Гаманець - Деталі транзакції ',
        WithdrawInputAmount: 'Гаманець - Вилучити',
        // Coins
        WalletCoinsBalance: 'Гаманець - Монетний баланс',
        WalletCoinsWithdraw: 'Гаманець - Вилучити',
        WalletCoinsWithdrawSuccess: 'Гаманець - Вилучення успішно',
        WalletCoinsTransactionDetails: 'Гаманець -Деталі транзакції ',
        // NFT
        WalletNFTSList: 'Гаманець - NFTs Список',
        WalletNFTSWithdraw: 'Гаманець - Вилучити NFTs',
        WalletNFTSWithdrawSuccess: 'Гаманець - Виведення NFTs успішно',
        WalletNFTSTransactionDetails: 'Гаманець - Деталі NFTs транзакції',
        // Rewards
        WalletRewardsClaimSuccess: 'Гаманець- Успішне отримання винагороди',
        WalletRewardsTransactionDetails:
            'Гаманець - Деталі отримання винагороди',
        WalletRewardsTab: 'Гаманець - Винагороди',
        // Trade
        WalletTradeMain: 'Гаманець - Торгівля головна',
        WalletTradeChart: 'Гаманець - Графік торгівлі',
        WalletTradeSuccess: 'Гаманець - Торгівля успішно',
        // Settings
        WalletSettings: 'Гаманець - налаштування',
        WalletSettingsBackup: 'Гаманець- Налаштування резервного копіювання',
        // Staking
        StakingWelcome: 'Гаманець - Буст',
        StakingOptions: 'Гаманець - Опції Бусту',
        StakingPlans: 'Гаманець - плани Бусту',
        StakingDetailedInfo: 'Гаманець - Детальна інформація Бусту',
        StakingCoinsInput: 'Гаманець - виведення монетних ставок',
        StakingDailyBoost: 'Гаманець - Щоденне збільшення ставок',
        StakingSuccess: 'Гаманець - успішна ставка',
        StakingAlready: 'Гаманець - Ставка зроблена',

        UnStakingSuccess: 'Гаманець - Успішно зняти ставку',

        /**
         * Play
         */
        PlayTasks: 'Завдання',
        PlayTasksActive: 'Активні Завдання',
        PlayTasksHistory: 'Історія Завдань',
        PlayEditNFTs: 'Редагувати NFT',
        PlayTasksHistoryDetails: 'Подробиці історії завдань',
        PlayCollectedExperience: 'Зібраний досвід',
        PlaySuccessScreen: 'Успіх завдання',
        /**
         * Socialize To Earn
         */

        SocializeToEarnWelcomeShare: 'Спілкуватися Щоб Заробляти',
        SocializeToEarnShareYourPhoto:
            'Спілкуватися Щоб Заробляти - Поділитися своїм фото',
        SocializeToEarnCreateSharePhoto:
            'Спілкуватися Щоб Заробляти - Поділитися Фото',
        SocializeToEarnCreateShareTemplate:
            'SocializeToEarn - Поділитись за допомогою шаблону',
        SocializeToEarnVerifyUser:
            'Спілкуватися Щоб Заробляти - Підтвердити Користувача',
        SocializeToEarnVerifyUserError:
            ' Спілкуватися Щоб Заробляти - Помилка Підтвердженя Користувача',
        SocializeToEarnShareYourPhotoSuccess:
            'Спілкуватися Щоб Заробляти - Поділитися Своїм Фото Успіх',
        SocializeToEarnWelcomeVerify:
            'Спілкуватися Щоб Заробляти - Підтвердити',
        SocializeToEarnVerifyUserMatch:
            'Спілкуватися Щоб Заробляти - Перевірте Збіг Користувача',
        SocializeToEarnVerifyUserNotFound:
            'Спілкуватися Щоб Заробляти - Користувача Не Знайдено',
        SocializeToEarnVerifyUserFailed:
            'Спілкуватися Щоб Заробляти - Помилка Підтверження Користувача',
        SocializeToEarnVerifyUserAlreadyCompleted:
            'Спілкуватися Щоб Заробляти - Перевірка Користувача Завершена',
        /**
         * Move To Earn
         */
        MoveToEarnWelcome: ' Гуляти Щоб Заробити',
        MoveToEarnProgress: 'Гуляти Щоб Заробити - Прогрес',
        MoveToEarnSummary: 'Гуляти Щоб Заробити - Підсумок',
        /**
         * Play to Earn
         */
        PlayToEarn: 'Грати Щоб Заробити',
        PlayToEarnWelcome: 'Грати Щоб Заробити Головна',
        PlayToEarnProgress: 'Грати Щоб Заробити Прогрес',
        /**
         * Charity
         */
        CharityOrganizations: 'Благодійні - Організації',
        CharityDetailedOrganization: 'Благодійні - Деталі Організації',
        CharityOrganizationDonations: 'Благодійні - Пожертви Організації',
        CharityEditSelectedOrganization:
            'Благодійність - Редагувати Вибрану Організацію',
        CharityEditDetailedOrganization:
            'Благодійність - Редагувати Деталі Організації',
        /**
         * Collection
         */
        CollectionMain: 'Колекція',
        CollectionDetailedPet: 'Колекція - Мій домашній улюбленець',
        CollectionPetLevelUpCheckout:
            'Колекція - Перевірка домашнього улюбленця',
        CollectionPetLevelUpSuccess:
            'Колекція - Успіх підвищення рівня вихованця',
        CollectionPetRankUpCheckout:
            'Колекція - Оплата Підвищення Рейтингу Домашнього Улюбленця',
        CollectionPetRankUpSuccess: 'Колекція - Підвищення Рейтингу Вихованця',
        CollectionPetHealUpCheckout:
            'Колекція - Оплата Лікування Домашнього Улюбленця',
        CollectionPetHealUpSuccess:
            'Колекція - Лікування Домашнього Улюбленця Успішно',
        CollectionPetReviveCheckout:
            'Колекція - Оживити Домашнього Улюбленця оплата',
        CollectionPetReviveSuccess:
            'Колекція - Оживити Домашнього Улюбленця Успішно',
        CollectionPetAgeReviveCheckout:
            'Колекція - Оживити Домашнього Улюбленця оплата',
        CollectionPetAgeReviveSuccess:
            'Колекція - Оживити Домашнього Улюбленця Успішно',
        CollectionMultiplePetsHealUpProceeding:
            'Колекція - Годувати всіх улюбленців',
        CollectionMultiplePetsHealUpCheckout: 'Колекція - Оплата годівлі',
        CollectionMultiplePetsHealUpSuccess:
            'Колекція - Успіх годівлі улюбленців',
        /**
         * Profile
         */
        ProfileRoot: 'Профіль',
        ProfileMain: 'Профіль - Головна',
        ProfileLanguagesSettings: 'Профіль - Налаштування Мови',
        ProfileNotificationsSettings: 'Профіль - Налаштування Сповішення',
        ProfileSocialAccountsSettings:
            'Профіль - Налаштування Соціальних Акаунтів',

        ProfileSocialAccessibilitySettings:
            'Профіль - Налаштування Доступоностi Акаунтів',
        ProfileSocialMedia: 'Профіль - Соціальні мережі',

        /**
         * Others
         */
        Dev: 'Для Розробника',
        RanksDetailedInfo: 'Ранги Детальна інформація',
        EnergyInfoScreen: 'Екран інформації про Енергію',

        // REFERRALS

        Referrals: 'Реферали',
        ReferralsAll: 'Реферали - всі',
        ReferralsInvites: 'Реферали - Запрошення',
        ReferralsInvite: 'Реферали - Запроити',
        ReferralsHowItsWork: 'Реферали - Як це працює',

        // LEADERBOARD
        Leaderboard: 'Таблиця лідерів',
        LeaderboardDailyTasks: 'Таблиця лідерів - Завдання',
        LeaderboardPvpGames: 'Таблиця лідерів - PvP',
        //

        //Mystery Box
        MysteryBoxMain: 'Містері Бокси',
        MysteryBoxPurchaseWelcome: 'Містері Бокси - Приготування до купівлі',
        MysteryBoxPurchase: 'Містері Бокси - Купити',
        MysteryBoxPurchaseCheckout: 'Містері Бокси - Підсумування',
        MysteryBoxPurchaseSuccess: 'Містері Бокси - Успіх',

        //HOW
        HowToCharity: 'Як працює благодійність',
        HowToPlay: 'Як Грати',
        HowToReferrals: 'Реферали -як працють',
        HowToAvailableRewards: 'Як отримати доступні винагороди',
        HowToLockedRewards: 'Як Заблокувати Винагороди',
        HowToGamingAvailableWallet: 'Як працює Доступний гаманець',
        HowToGamingPendingWallet: 'Як працює Гаманець очікування',
        HowToSocializeToEarn: 'Як Спілкуватися Щоб Заробляти',
        HowToVerifyUser: 'Як Підтвердити Користувача',
        HowToPurchaseBNB: 'Як купити BNB',
        HowToCommissionFee: 'Плата',
        HowToFee: 'Плата',
        HowToPurchaseSpadeId: 'Як використовувати SPACE ID',

        // BONUS MINT RANK
        BonusMintRanksInfo: 'Мінт - Бонус',
        BonusMintRanksCarousel: 'Мінт - Бонуси За Всі Ранги',

        InitialMint: 'Придбайте Свого Першого Улюбленця',
        InitialReferral: 'Вас запросили в IguVerse',
        YourPetsAreHungry: 'Ваші улюбленці голодують!',
        UpdateApp: 'Нове оновлення',
        //ONBOARDING
        MainWalletOnBoardingScreen: 'Головний Гаманець',
        LoginOnBoardingScreen: 'Ласкаво просимо',

        //MARKETPLACE
        MarketPlaceActiveOrders: 'Маркетплейс - Активні ордера',
        MarketPlaceMyProfile: 'Маркетплейс - Мій профіль',
        MarketPlaceProfile: 'Маркетплейс - Профіль',
        MarketPlaceWelcomeCreateOrder: 'Маркетплейс - Ласкаво просимо',
        MarketPlaceSelectNft: 'Маркетплейс - Вибрати NFT',
        MarketPlaceSelectNftPrice: 'Маркетплейс - Вибрати ціну',
        MarketPlaceOverviewGroup: 'Маркетплейс - Огляд',
        MarketPlaceOverview: 'Маркетплейс - Огляд',
        MarketPlaceOverviewOwnerHistory: 'Маркетплейс - Історія власників',
        MarketPlaceBuyCheckout: 'Маркетплейс - Оформлення замовлення',
        MarketPlaceCreateOrderSuccess: 'Маркетплейс - Успіх',
        MarketPlaceDelistSuccess: 'Маркетплейс - Успіх',
        MarketPlaceBuySuccess: 'Маркетплейс - Успіх',

        //Achievements
        Achievements: 'Досягнення',
        AchievementsDetails: 'Досягнення - Деталі',

        //PassCode
        PassCodeFaceId: 'Код доступу - Біометричний',
        PassCodeNew: 'Код доступу - Увімкнути',
        PassCodeChange: 'Код доступу - Змінити',
        PassCodeSuccess: 'Код доступу - Успіх',
        PassCodeSettings: 'Код доступу - Налаштування',
        PassCodeConfirm: 'Код доступу - Підтвердити',

        //Lucky Strike
        WelcomeLuckyStrike: 'Lucky Strike - Ласкаво просимо',
        LuckyStrike: 'Lucky Strike',
        LuckyStrikeMain: 'Lucky Strike - Раунди',
        LuckyStrikeHistory: 'Lucky Strike - Історія',
        HowLuckyStrikeWork: 'Lucky Strike - Як це працює',
        LuckyStrikeAllParticipants: 'Lucky Strike - Учасники',
        LuckyStrikeWaitingForResult: 'Lucky Strike - Результат',
        LuckyStrikeUserTickets: 'Lucky Strike - Квитки',
        LuckyStrikeUserTicketsWait: 'Lucky Strike - Квитки',
        LuckyStrikeAllWinners: 'Lucky Strike - Переможці',
        LuckyStrikeGameHistory: 'Lucky Strike - Історія',

        //Quantum Spin
        WelcomeQuantumSpin: 'Квантовий диск - Ласкаво просимо',
        HowQuantumSpinWork: 'Квантовий диск - Як це працює',
        QuantumSpin: 'Квантовий диск',
        QuantumSpinMain: 'Квантовий диск - Колесо',
        QuantumSpinHistory: 'Квантовий диск - Історія',
        QuantumSpinGameHistory: 'Квантовий диск - Історія',
        QuantumSpinProbablyFair: 'Квантовий диск - Чесна рандомізація',
        QuantumSpinProbablyFairHistory: 'Квантовий диск - Історія',

        //TokenTeller
        WelcomeTokenTeller: 'Ігуманія - Ласкаво просимо',
        TokenTeller: 'Ігуманія',
        TokenTellerMain: 'Ігуманія - Раунди',
        TokenTellerHistory: 'Ігуманія - Історія',
        HowTokenTellerWork: 'Ігуманія - Як це працює',
        TokenTellerChart: 'Ігуманія - Діаграма',
        TokenTellerAllParticipants: 'Ігуманія - Учасники',
        TokenTellerWaitingForResult: 'Ігуманія - Зачекайте',
        TokenTellerGameHistory: 'Ігуманія - Історія',
    },
};

export default uk;
