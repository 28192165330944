import React, { useEffect, useRef, useState } from 'react';

import Button from '@components/Button';
import { Text, View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import { PAUSE_GAME_TIMER_COUNT } from '../../screens/playToEarnProgress/config';
import stylesMain from './style';

type Props = {
    onTimerEnd: () => void;
    visibility: boolean;
    startTimer: boolean;
    gameTimerText?: string;
    withOpacity?: boolean;
    skip?: boolean;
};

const PauseGameTimer = ({
    onTimerEnd,
    visibility,
    startTimer,
    gameTimerText,
    withOpacity,
    skip,
}: Props) => {
    const styles = useThemedStyles(stylesMain);
    const countRef = useRef(PAUSE_GAME_TIMER_COUNT);
    const timerRef = useRef<ReturnType<typeof setInterval>>();
    const [timer, setTimer] = useState(countRef.current);

    useEffect(() => {
        if (startTimer)
            if (!visibility) {
                clearInterval(timerRef.current);
            } else {
                countRef.current = PAUSE_GAME_TIMER_COUNT;
                setTimer(PAUSE_GAME_TIMER_COUNT);
                timerRef.current = setInterval(() => {
                    if (countRef.current < 1) {
                        onTimerEnd();
                    } else {
                        countRef.current -= 1;
                        setTimer(countRef.current);
                    }
                }, 1000);
            }

        return () => {
            clearInterval(timerRef.current);
        };
    }, [visibility, startTimer]);

    if (!visibility) {
        return null;
    }
    return (
        <View
            pointerEvents={skip ? 'auto' : 'box-only'}
            style={styles.container}
            accessible={skip}>
            <View
                style={[styles.innerContainer, withOpacity && styles.withoutBg]}
                accessible={skip}>
                {withOpacity && (
                    <View style={styles.opacity} accessible={skip} />
                )}
                <Text style={styles.timer}>{timer}</Text>
                {!!gameTimerText && (
                    <Text style={styles.text}>{gameTimerText}</Text>
                )}
                {skip && (
                    <Button
                        size="md"
                        type="text"
                        onPress={onTimerEnd}
                        textStyles={styles.btnText}
                        containerStyle={styles.buttonContainer}
                        title={i18n.t('moveToEarn.skip')}
                    />
                )}
            </View>
        </View>
    );
};

export default PauseGameTimer;
