import * as React from 'react';

import { RewardsTransactionType, TokenTransactionType } from '@Data/Models';
import Breeding from '@assets/icons/wallet/transactions/breeding.svg';
import CharityDonationIcon from '@assets/icons/wallet/transactions/charityDonation.svg';
import ClaimRewardIcon from '@assets/icons/wallet/transactions/claimReward.svg';
import DailyRewardIcon from '@assets/icons/wallet/transactions/dailyReward.svg';
import DepositIcon from '@assets/icons/wallet/transactions/deposit.svg';
import DrawIcon from '@assets/icons/wallet/transactions/draw.svg';
import ExpiredIcon from '@assets/icons/wallet/transactions/expired.svg';
import FeeIcon from '@assets/icons/wallet/transactions/fee.svg';
import GameRewardStakeBonus from '@assets/icons/wallet/transactions/gameRewardStakeBonus.svg';
import LoseIcon from '@assets/icons/wallet/transactions/lose.svg';
import LuckyStrikePayment from '@assets/icons/wallet/transactions/luckyStrikePayment.svg';
import LuckyStrikeWin from '@assets/icons/wallet/transactions/luckyStrikeWin.svg';
import MintBonusIcon from '@assets/icons/wallet/transactions/mintBonus.svg';
import NftPaymentIcon from '@assets/icons/wallet/transactions/nftPayment.svg';
import NftRoyal from '@assets/icons/wallet/transactions/nftRoyal.svg';
import NftRoyalDraw from '@assets/icons/wallet/transactions/nftRoyalDraw.svg';
import NftRoyalLoss from '@assets/icons/wallet/transactions/nftRoyalLoss.svg';
import NftRoyalWin from '@assets/icons/wallet/transactions/nftRoyalWin.svg';
import OtherIcon from '@assets/icons/wallet/transactions/other.svg';
import PaymentIcon from '@assets/icons/wallet/transactions/payment.svg';
import PvpBonus from '@assets/icons/wallet/transactions/pvpBonus.svg';
import QuantumSpin from '@assets/icons/wallet/transactions/quantumSpin.svg';
import QuantumSpinReward from '@assets/icons/wallet/transactions/quantumSpinReward.svg';
import ReferralRewardIcon from '@assets/icons/wallet/transactions/referralReward.svg';
import ReferralRewardV2 from '@assets/icons/wallet/transactions/referralRewardV2.svg';
import SwapIcon from '@assets/icons/wallet/transactions/swap.svg';
import TokenTellerFee from '@assets/icons/wallet/transactions/tokenTellerFee.svg';
import TokenTellerPayment from '@assets/icons/wallet/transactions/tokenTellerPayment.svg';
import TokenTellerRefund from '@assets/icons/wallet/transactions/tokenTellerRefund.svg';
import TokenTellerWin from '@assets/icons/wallet/transactions/tokenTellerWin.svg';
import TopUpBonus from '@assets/icons/wallet/transactions/topUpBonus.svg';
import WinIcon from '@assets/icons/wallet/transactions/win.svg';
import WithdrawIcon from '@assets/icons/wallet/transactions/withdraw.svg';
import { IGU_WALLET_TRANSACTIONS } from '@models/gamingWallet';

import { IconType } from '../types';

interface ITransactionTypeIcon extends IconType {
    type:
        | TokenTransactionType
        | RewardsTransactionType
        | IGU_WALLET_TRANSACTIONS;
}

const TransactionTypeIcon: React.FunctionComponent<ITransactionTypeIcon> = ({
    type,
    size = 40,
}) => {
    switch (type) {
        case 'DEPOSIT':
            return <DepositIcon width={size} height={size} />;
        case 'WITHDRAW':
            return <WithdrawIcon width={size} height={size} />;
        case 'NFT_PAYMENT':
            return <PaymentIcon width={size} height={size} />;
        case 'MINT':
            return <NftPaymentIcon width={size} height={size} />;
        case 'SWAP':
            return <SwapIcon width={size} height={size} />;
        case 'CLAIM':
            return <ClaimRewardIcon width={size} height={size} />;
        case 'GameIterationReward':
            return <DailyRewardIcon width={size} height={size} />;
        case 'ReferralReward':
            return <ReferralRewardIcon width={size} height={size} />;
        case 'Claim':
            return <ClaimRewardIcon width={size} height={size} />;
        case 'PetLevelUpPayment':
            return <PaymentIcon width={size} height={size} />;
        case 'PetRankUpPayment':
            return <PaymentIcon width={size} height={size} />;
        case 'PetRevivePayment':
            return <PaymentIcon width={size} height={size} />;
        case 'PetHealUpPayment':
            return <PaymentIcon width={size} height={size} />;
        case 'MintBonus':
            return <MintBonusIcon width={size} height={size} />;
        case 'CharityDonation':
            return <CharityDonationIcon width={size} height={size} />;
        case 'GameIterationRewardSubtransaction':
            return <DailyRewardIcon width={size} height={size} />;
        case 'GameRewardStakeBonus':
            return <GameRewardStakeBonus width={size} height={size} />;
        case 'GameRewardStakeBonusSubtransaction':
            return <GameRewardStakeBonus width={size} height={size} />;

        case IGU_WALLET_TRANSACTIONS.Deposit:
            return <DepositIcon width={size} height={size} />;
        case IGU_WALLET_TRANSACTIONS.Withdraw:
            return <WithdrawIcon width={size} height={size} />;
        case IGU_WALLET_TRANSACTIONS.RevertWithdraw:
            return <WithdrawIcon width={size} height={size} />;
        case IGU_WALLET_TRANSACTIONS.MysteryLikesGameDraw:
            return <DrawIcon width={size} height={size} />;
        case IGU_WALLET_TRANSACTIONS.MysteryLikesGamePayment:
            return <LoseIcon width={size} height={size} />;
        case IGU_WALLET_TRANSACTIONS.MysteryLikesGameRefund:
            return <ExpiredIcon width={size} height={size} />;
        case IGU_WALLET_TRANSACTIONS.MysteryLikesGameWin:
            return <WinIcon width={size} height={size} />;
        case IGU_WALLET_TRANSACTIONS.DepositBonus:
            return <TopUpBonus width={size} height={size} />;
        case IGU_WALLET_TRANSACTIONS.DirectReferralDepositBonus:
            return <TopUpBonus width={size} height={size} />;
        case IGU_WALLET_TRANSACTIONS.IndirectReferralDepositBonus:
            return <TopUpBonus width={size} height={size} />;
        case IGU_WALLET_TRANSACTIONS.MysteryLikesGameFee:
            return <FeeIcon width={size} height={size} />;
        case IGU_WALLET_TRANSACTIONS.MysteryLikesGameDirectReferralBonus:
            return <ReferralRewardV2 width={size} height={size} />;
        case IGU_WALLET_TRANSACTIONS.MysteryLikesGameIndirectReferralBonus:
            return <ReferralRewardV2 width={size} height={size} />;

        case IGU_WALLET_TRANSACTIONS.Breeding:
            return <Breeding width={size} height={size} />;
        case IGU_WALLET_TRANSACTIONS.GamesTurnoverReward:
            return <PvpBonus width={size} height={size} />;
        case IGU_WALLET_TRANSACTIONS.NftRoyaleGameDraw:
            return <NftRoyalDraw width={size} height={size} />;
        case IGU_WALLET_TRANSACTIONS.NftRoyaleGameFee:
            return <NftRoyal width={size} height={size} />;
        case IGU_WALLET_TRANSACTIONS.NftRoyaleGamePayment:
            return <NftRoyalLoss width={size} height={size} />;
        case IGU_WALLET_TRANSACTIONS.NftRoyaleGameRefund:
            return <NftRoyal width={size} height={size} />;
        case IGU_WALLET_TRANSACTIONS.NftRoyaleGameWin:
            return <NftRoyalWin width={size} height={size} />;
        case IGU_WALLET_TRANSACTIONS.LuckyStrikeGamePayment:
            return <LuckyStrikePayment width={size} height={size} />;
        case IGU_WALLET_TRANSACTIONS.LuckyStrikeGameWin:
            return <LuckyStrikeWin width={size} height={size} />;
        case IGU_WALLET_TRANSACTIONS.QuantumSpin:
            return <QuantumSpin width={size} height={size} />;
        case IGU_WALLET_TRANSACTIONS.QuantumSpinReward:
            return <QuantumSpinReward width={size} height={size} />;
        case IGU_WALLET_TRANSACTIONS.TokentellerGamePredictWin:
            return <TokenTellerWin width={size} height={size} />;
        case IGU_WALLET_TRANSACTIONS.TokentellerGamePredict:
            return <TokenTellerPayment width={size} height={size} />;
        case IGU_WALLET_TRANSACTIONS.TokentellerGamePredictFee:
            return <TokenTellerFee width={size} height={size} />;
        case IGU_WALLET_TRANSACTIONS.TokentellerGamePredictRefund:
            return <TokenTellerRefund width={size} height={size} />;
        // OTHER
        default:
            return <OtherIcon width={size} height={size} />;
    }
};

export default TransactionTypeIcon;
