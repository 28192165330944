import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';
import { stylesRowAlign } from '@helpers/style';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        itemContainer: {
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',

            marginBottom: verticalScale(12),
        },
        imageWrapper: {
            width: 40,
            height: 40,
            padding: 8,
            backgroundColor: Colors[theme].surfaceDecorativeAccentMinimal,
            borderRadius: 100,
        },
        image: {
            width: '100%',
            height: '100%',
            borderRadius: 6,
        },
        infoBlock: {
            marginLeft: scale(12),
            flex: 1,
        },
        left: {
            ...stylesRowAlign(true),
        },
        top: {
            ...stylesRowAlign(true),
        },
        flex: {
            flex: 1,
        },
        bot: {
            flex: 1,
        },
    });

export default styles;
