import { useCallback, useEffect, useRef, useState } from 'react';
import BackgroundGeolocation, {
    Location,
} from 'react-native-background-geolocation';

import { LocationSubscription } from 'expo-location';
import { Subscription } from 'expo-modules-core';
import { Pedometer } from 'expo-sensors';
import { getPreciseDistance } from 'geolib';

import { MoveTaskResponseItem } from '@Data/Models';
import { useAppState } from '@contexts/AppStateContext';
import { useNotification } from '@contexts/Notification/NotificationContext';
import { usePlay } from '@contexts/PlayContext';
import { useUser } from '@contexts/UserContext';
import { isIOS, isWeb } from '@helpers/app';
import { errorsHandler, isNetworkServerError } from '@helpers/errors';
import useMoveTask from '@hooks/tasks/useMoveTask';
import useTasksErrorsHandler from '@hooks/tasks/useTasksErrorsHandler';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import {
    IWalkStatus,
    MAX_INTERVAL_DISTANCE,
    MAX_SPEED,
    MIN_SPEED,
    WALK_STATUSES,
} from '../constants';
import {
    calculateAverageSpeed,
    calculateEnergy,
    countSteps,
    formatDistanceTraveled,
    moveTaskDoneParameters,
} from '../helpers/moveHelper';

const config = {
    shouldStartBackground: !isWeb,
    stepCount: 0.78,
    enableHighAccuracy: !0,
    timeout: 3e4,
    maximumAge: 0,
};

type Coordinates = { latitude: number; longitude: number };

export const useProgress = (pedometer: boolean) => {
    const { isConnected } = useAppState();
    const { moveTask, updateMoveTask } = usePlay();
    const { user } = useUser();
    const { taskWrongState, taskLostConnection } = useTasksErrorsHandler();
    const { markMoveTaskDone } = useMoveTask();
    const { scheduleMoveToEarnFinished } = useNotification();
    const navigation = useNavigation();

    const [msg, setMsg] = useState<string>('');
    const [walkStatus, setWalkStatus] = useState<IWalkStatus>(
        WALK_STATUSES.walking
    );

    const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
    const [isPaused, setIsPaused] = useState<boolean>(true);

    const [remainingTime, setRemainingTime] = useState<number>(0);
    const [energyEarned, setEnergyEarned] = useState<number>(0);
    const [speed, setSpeed] = useState<number>(0);
    const [stepsWalked, setStepsWalked] = useState<number>(0);
    const [currStepCount, setCurrStepCount] = useState<number>(0);
    const [prevStepCount, setPrevStepCount] = useState<number>(0);
    const [location, setLocation] = useState<Location>();
    const [distanceTraveledToShow, setDistanceTraveledToShow] =
        useState<number>(0);

    const lastPosition = useRef<Coordinates | null>(null);

    const speedHistory = useRef<Array<number>>([]);
    const distanceTraveled = useRef<number>(0);
    const isFinish = useRef<boolean>(false);
    const shakeDetected = useRef<boolean>(false);
    const pedometerSubscription = useRef<LocationSubscription>();
    const webWatchSubscription = useRef<number>();
    const locationSubscriber = useRef<Subscription>();

    const subscribe = async () => {
        if (isWeb) {
            if (navigator.geolocation) {
                webWatchSubscription.current =
                    navigator.geolocation.watchPosition(
                        (position) => {
                            //@ts-ignore for web
                            setLocation(position);
                        },
                        (error) => {
                            alert(error);
                        },
                        {
                            enableHighAccuracy: config.enableHighAccuracy,
                            timeout: config.timeout,
                            maximumAge: config.maximumAge,
                        }
                    );
            } else {
                alert('Sorry, browser does not support geolocation!');
            }
            return;
        }

        if (pedometer && isIOS) {
            pedometerSubscription.current = Pedometer.watchStepCount(
                (result) => {
                    setCurrStepCount((prevStepCount) => {
                        setPrevStepCount(prevStepCount);
                        return result.steps;
                    });
                }
            );
        }

        if (config.shouldStartBackground) {
            locationSubscriber.current = BackgroundGeolocation.onLocation(
                setLocation,
                (error) => {
                    Console.error('[onLocation] ERROR: ', error);
                }
            );

            BackgroundGeolocation.watchPosition(
                (location) => {
                    Console.log('[watchPosition] -', location);
                },
                (errorCode) => {
                    Console.error('[watchPosition] ERROR -', errorCode);
                },
                {
                    interval: 1000,
                }
            );
            const state = await BackgroundGeolocation.getState();

            if (!state.enabled) {
                BackgroundGeolocation.start();
            }
        }
    };

    const unsubscribe = async () => {
        if (isWeb) {
            navigator.geolocation.clearWatch(webWatchSubscription.current || 0);
            return;
        }

        pedometerSubscription.current?.remove();
        locationSubscriber.current?.remove();
        BackgroundGeolocation.stopWatchPosition();
        BackgroundGeolocation.stop();
        setSpeed(0);
        setWalkStatus(WALK_STATUSES.paused);
    };

    const handleSubscribe = () => {
        subscribe()
            .catch((error) => {
                errorsHandler(error);
            })
            .then(() => {
                setIsPaused(false);
                setIsSubscribed(true);
            });
    };

    const handleUnsubscribe = () => {
        setIsSubscribed(false);
        unsubscribe().catch((error) => {
            errorsHandler(error);
        });
    };

    const finishTask = useCallback(async () => {
        if (!moveTask?.currentTask || !user?.id) {
            return;
        }
        if (isFinish.current || !isConnected) {
            return;
        }
        handleUnsubscribe();
        await handleMarkMoveTaskDone(
            user.id,
            remainingTime,
            moveTask.currentTask,
            distanceTraveled.current,
            stepsWalked,
            speedHistory.current
        );
    }, [
        moveTask.currentTask,
        user.id,
        isConnected,
        handleUnsubscribe,
        handleMarkMoveTaskDone,
        remainingTime,
        stepsWalked,
    ]);

    const update = useCallback(
        (location: Location) => {
            if (
                !shakeDetected.current &&
                moveTask?.currentTask &&
                isSubscribed
            ) {
                const { maxDistanceMeters, maxEnergyReward } =
                    moveTask.currentTask;
                const { speed, latitude, longitude } = location.coords;
                const kphSpeed = Number(((speed || 0) * 3.6).toFixed(2));
                setSpeed(kphSpeed || 0);
                const currPosition: Coordinates = {
                    latitude,
                    longitude,
                };

                if (walkStatus != WALK_STATUSES.walking) {
                    return;
                }

                const distance = Math.min(
                    getPreciseDistance(
                        lastPosition.current || currPosition,
                        currPosition
                    ) / 1000,
                    MAX_INTERVAL_DISTANCE / 1000
                );
                const newDistanceTraveled = Math.min(
                    distance + distanceTraveled.current,
                    maxDistanceMeters / 1000
                );

                distanceTraveled.current = newDistanceTraveled;
                setDistanceTraveledToShow(newDistanceTraveled);

                setEnergyEarned(
                    calculateEnergy(
                        maxEnergyReward,
                        maxDistanceMeters,
                        newDistanceTraveled
                    )
                );

                if (!isIOS) {
                    setStepsWalked(
                        countSteps(newDistanceTraveled, config.stepCount)
                    );
                }

                const taskKm = maxDistanceMeters / 1000;
                if (taskKm <= newDistanceTraveled) {
                    finishTask();
                    distanceTraveled.current = taskKm;
                    setDistanceTraveledToShow(taskKm);
                    return;
                }

                lastPosition.current = currPosition;
                speedHistory.current = [...speedHistory.current, kphSpeed];
            } else {
                setSpeed(0);
                setWalkStatus(WALK_STATUSES.paused);
            }
        },
        [moveTask?.currentTask, walkStatus, isSubscribed, finishTask]
    );

    // Set time
    useEffect(() => {
        if (moveTask?.currentTask) {
            setRemainingTime(moveTask.currentTask.maxTimeSeconds);
        }
    }, [moveTask?.currentTask]);

    // handle walk status
    useEffect(() => {
        if (!isPaused) {
            if (speed < MIN_SPEED) {
                setWalkStatus(WALK_STATUSES.stopped);
            } else if (speed > MAX_SPEED) {
                setWalkStatus(WALK_STATUSES.tooFast);
            } else {
                setWalkStatus(WALK_STATUSES.walking);
            }
        }
    }, [speed, isPaused]);

    // handle unsubscribe when turnOnPause
    useEffect(() => {
        if (isPaused) {
            handleUnsubscribe();
        }
    }, [isPaused]);
    // handle subscribe when turnOffPause
    useEffect(() => {
        if (!isPaused && !isSubscribed) {
            handleSubscribe();
        }
    }, [isPaused, isSubscribed]);

    // update location
    useEffect(() => {
        if (location) {
            update(location);
        }
    }, [location, update]);

    // unsubscribe on unmount
    useEffect(() => {
        return () => {
            handleUnsubscribe();
        };
    }, []);

    // handle steps walked
    useEffect(() => {
        if (
            isSubscribed &&
            walkStatus === WALK_STATUSES.walking &&
            prevStepCount > 0 &&
            currStepCount > prevStepCount &&
            !shakeDetected.current
        ) {
            setStepsWalked(
                (prevStepsWalked) =>
                    prevStepsWalked + (currStepCount - prevStepCount)
            );
        }
    }, [prevStepCount, currStepCount, isSubscribed, walkStatus]);

    // handle info message
    useEffect(() => {
        if (speed < MIN_SPEED && !isPaused) {
            setMsg(i18n.t('moveToEarn.progress.walkStoppedMessage'));
        } else if (speed > MAX_SPEED) {
            setMsg(i18n.t('moveToEarn.progress.tooFastMessage'));
        } else {
            setMsg('');
        }
    }, [speed, isPaused]);

    const handleMarkMoveTaskDone = async (
        id: string,
        remainingTime: number,
        task: MoveTaskResponseItem,
        distance: number,
        stepsWalked: number,
        speedHistory: number[]
    ) => {
        try {
            isFinish.current = true;
            const variables = moveTaskDoneParameters(
                id,
                remainingTime,
                task,
                distance
            );
            const result = await markMoveTaskDone({
                variables,
            });
            const moveTaskDone = result.data?.markMoveTaskDone;

            if (moveTaskDone) {
                updateMoveTask(moveTaskDone);
            }
            scheduleMoveToEarnFinished(3);

            const maxEnergyEarned =
                moveTaskDone?.maxEnergyReward === moveTaskDone?.energyRewarded;

            navigation.replace(ROUTES.PLAY_TASK_SUCCESS, {
                maxEnergy: moveTaskDone?.maxEnergyReward,
                earnedEnergy: moveTaskDone?.energyRewarded || 0,
                petExperienceRewards: moveTaskDone?.petExperienceRewards,
                game: 'MOVE',
                walkGame: {
                    stepsWalked,
                    distanceTraveled: maxEnergyEarned
                        ? formatDistanceTraveled(task.maxDistanceMeters, true)
                        : formatDistanceTraveled(distance),
                    averageSpeed: calculateAverageSpeed(speedHistory),
                },
            });
        } catch (error: any) {
            if (isNetworkServerError(error)) {
                isFinish.current = false;
                taskLostConnection();
            } else {
                taskWrongState(error);
            }
        }
    };

    return {
        msg,
        speed,
        isPaused,
        walkStatus,
        stepsWalked,
        setIsPaused,
        isSubscribed,
        energyEarned,
        speedHistory,
        distanceTraveled,
        handleSubscribe,
        handleUnsubscribe,
        shakeDetected,
        distanceTraveledToShow,
        remainingTime,
        setRemainingTime,
        finishTask,
    };
};
