import React from 'react';
import Animated, {
    useAnimatedStyle,
    useSharedValue,
    withSpring,
} from 'react-native-reanimated';

import BannerMysteryGames from '@components/BannerMysteryGames';
import { isIOS, isWeb } from '@helpers/app';
import { verticalScale } from '@helpers/dimensions';
import useThemedStyles from '@hooks/useThemedStyles';
import {
    MYSTERY_GAMES_CONTINUE_BANNER_RENDER_TYPE,
    MysteryLikesMyPendingGame,
} from '@models/mysteryGames';

import stylesMain from './styles';

interface IProps {
    onPress: () => void;
    isVisible: boolean;
    mysteryLikes: MysteryLikesMyPendingGame | undefined;
    renderType: MYSTERY_GAMES_CONTINUE_BANNER_RENDER_TYPE;
}

const config = {
    startPosition: -110,
    endPosition: isWeb
        ? verticalScale(125)
        : isIOS
        ? verticalScale(96)
        : verticalScale(116),
};

const AnimatedBanner = ({
    onPress,
    isVisible,
    mysteryLikes,
    renderType,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const bannerShare = useSharedValue(config.startPosition);

    React.useEffect(() => {
        if (isVisible) {
            bannerShare.value = withSpring(verticalScale(config.endPosition));
            return;
        }
        bannerShare.value = withSpring(verticalScale(config.startPosition));
    }, [isVisible]);

    const buttonSharedStyle = useAnimatedStyle(() => {
        return {
            bottom: bannerShare.value,
            zIndex: 1,
        };
    });

    return (
        <Animated.View style={[styles.container, buttonSharedStyle]}>
            <BannerMysteryGames
                onPress={onPress}
                mysteryLikes={mysteryLikes}
                renderType={renderType}
            />
        </Animated.View>
    );
};

export default AnimatedBanner;
