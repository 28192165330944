import * as React from 'react';
import Animated from 'react-native-reanimated';

import useThemedStyles from '../../hooks/useThemedStyles';
import { Text, View } from '../Themed';
import { useLinearLoader } from './hook';
import stylesMain from './styles';

export interface IProps {
    progress: number;
    onLoadingLineStop?: () => void;
    statusText: string;
}

const LinearLoaderProgress = ({
    progress,
    onLoadingLineStop,
    statusText,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { status, progressStyle } = useLinearLoader({
        progress,
        onLoadingLineStop,
        statusText,
    });

    return (
        <View style={styles.container}>
            <Text style={styles.text}>{status}</Text>
            <View style={styles.progress}>
                <Animated.View style={[styles.fill, progressStyle]} />
            </View>
        </View>
    );
};

export default LinearLoaderProgress;
