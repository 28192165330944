import React, { FC, PropsWithChildren, createContext } from 'react';

import { useUser } from './UserContext';

type AdminContextType = {};

export const AdminContext = createContext<AdminContextType>({});
export const useAdmin = () => React.useContext(AdminContext);

const AdminProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
    const { user } = useUser();

    return <AdminContext.Provider value={{}}>{children}</AdminContext.Provider>;
};

export default AdminProvider;
