import * as React from 'react';
import { useContext, useEffect } from 'react';
import { ScrollView } from 'react-native';

import * as ImagePicker from 'expo-image-picker';

import {
    ImageUploadUrlResponse,
    MarkImageUploadedResponse,
} from '@Data/Models';
import { IMAGE_UPLOAD_URL, MARK_IMAGE_UPLOADED } from '@Data/Requests';
import { useMutation } from '@apollo/client';
import { STEP_INDICATOR_FLOWS } from '@components/FlowStepIndicator';
import { MINT_PET_STAGES_NAME } from '@components/FlowStepIndicator/flows/mintNFTStages';
import { View } from '@components/Themed';
import { MintContext } from '@contexts/MintContext';
import { isGraphqlError } from '@helpers/helpers';
import { getSizeInBytes, prefetchImage, uploadImage } from '@helpers/media';
import { toastError, toastErrorUnknown } from '@helpers/toastNotification';
import { ImagePickerType } from '@hooks/useImagePicker/types';
import { useNavigation } from '@navigation/useNavigation';

import CustomModal from '../../../components/CustomModal';
import Divider from '../../../components/Divider';
import ImageIguana from '../../../components/ImageIguana';
import ModalLoader from '../../../components/ModalLoader';
import useImagePicker from '../../../hooks/useImagePicker';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import ROUTES from '../../../navigation/routes';
import ErrorReporting from '../../../utils/ErrorReporting';
import MintContainer from '../components/MintContainer';
import MintHeaderText from '../components/MintHeaderText';
import MintPhotoTips from '../components/MintPhotoTips';
import MintPickPhotoButtons from '../components/MintPickPhotoButtons';
import stylesMain from './styles';

export default function MintPetPickPhotoScreen() {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const {
        openCamera,
        pickFromGallery,
        openCameraOrFromGallery,
        renderCropper,
        renderCameraPermissions,
        image,
    } = useImagePicker(
        {
            allowsEditing: true,
            aspect: [1, 1],
        },
        {
            fileSize: getSizeInBytes(10),
        }
    );

    const {
        setEditPhoto,
        setDetectionResult,
        backgrounds,
        setChangeRankCheckOut,
    } = useContext(MintContext);

    const [getImageUploadUrlRequest] =
        useMutation<ImageUploadUrlResponse>(IMAGE_UPLOAD_URL);
    const [markImageUploadedUrlRequest] =
        useMutation<MarkImageUploadedResponse>(MARK_IMAGE_UPLOADED);

    const [imagePickerType, setImagePickerType] =
        React.useState<ImagePickerType>('camera');

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isNoAnimalError, setIsNoAnimalError] =
        React.useState<boolean>(false);

    const handleCloseErrorModal = () => {
        setIsNoAnimalError(false);
    };

    const handleOpenErrorModal = () => {
        setIsNoAnimalError(true);
    };

    const prefetchBackgrounds = () => {
        // prefetch backgrounds
        for (let i = 0; i < backgrounds.length; ++i) {
            prefetchImage(backgrounds[i].icon).catch((error) =>
                Console.error(error)
            );
            prefetchImage(backgrounds[i].original).catch((error) =>
                Console.error(error)
            );
        }
    };

    const processImage = async (image: ImagePicker.ImageInfo) => {
        handleCloseErrorModal();

        setIsLoading(true);

        try {
            const result = await getImageUploadUrlRequest();

            if (!result.data?.imageUploadUrl) {
                toastErrorUnknown();
                return;
            }

            const uploadedImage = await uploadImage(
                result.data?.imageUploadUrl.signedUploadUrl,
                image.uri
            );

            const fileId = result.data?.imageUploadUrl.id;
            if (!uploadedImage.ok || !fileId) {
                handleOpenErrorModal();
                toastError(
                    i18n.t('mintPickPhoto.errors.uploadError.title'),
                    i18n.t('mintPickPhoto.errors.uploadError.text')
                );
                return;
            }

            const requestResponse = await markImageUploadedUrlRequest({
                variables: {
                    fileId,
                    metadata: {
                        isFromCamera: false,
                        isFromGallery: true,
                    },
                },
            });

            const detectionResult =
                requestResponse.data?.markImageUploaded.metadata
                    .detectionResult;

            if (detectionResult) {
                setDetectionResult(detectionResult);
            }

            const file = requestResponse.data?.markImageUploaded;
            if (file) {
                // prefetch image
                await prefetchImage(file.url);
                setIsLoading(false);
                handleCloseErrorModal();
                setEditPhoto({
                    file,
                    position: null,
                    uri: file.url,
                });
                setChangeRankCheckOut(false);
                navigation.replace(ROUTES.MINT_PET_EDIT_PHOTO);
            }
        } catch (error) {
            Console.error(error);
            setIsLoading(false);
            if (isGraphqlError(error, 'REMOVE_BG_FAILED')) {
                toastError(
                    i18n.t('mintPickPhoto.errors.processImageFailed.title'),
                    i18n.t('mintPickPhoto.errors.processImageFailed.text')
                );
            } else if (isGraphqlError(error, 'IMAGE_EXPLICIT_CONTENT')) {
                toastError(
                    i18n.t('mintPickPhoto.errors.explicitContent.title'),
                    i18n.t('mintPickPhoto.errors.explicitContent.text'),
                    'bottom',
                    10000
                );
            } else if (isGraphqlError(error, 'IMAGE_NOT_ANIMAL')) {
                handleOpenErrorModal();
            } else {
                toastErrorUnknown();
                ErrorReporting.report(error);
            }
        }
    };

    useEffect(() => {
        if (image) {
            prefetchBackgrounds();
            processImage(image);
        }
    }, [image]);

    return (
        <>
            <MintContainer
                icon="leftArrow"
                flow={STEP_INDICATOR_FLOWS.MINT_PET}
                stage={MINT_PET_STAGES_NAME.STEP_TWO}>
                <ScrollView showsHorizontalScrollIndicator={false}>
                    <MintHeaderText
                        title={i18n.t('mintPickPhoto.title')}
                        text={i18n.t('mintPickPhoto.text')}
                    />
                    <View style={styles.buttonWrapper}>
                        <MintPickPhotoButtons
                            openCamera={() => {
                                openCamera();
                                setImagePickerType('camera');
                            }}
                            pickFromGallery={() => {
                                pickFromGallery();
                                setImagePickerType('gallery');
                            }}
                        />
                    </View>
                    <Divider customStyles={styles.divider} />
                    <View style={styles.tipsWrapper}>
                        <MintPhotoTips />
                    </View>
                </ScrollView>
            </MintContainer>
            <ModalLoader
                isVisible={isLoading}
                text={i18n.t('mintPickPhoto.analyzingPhoto')}
            />
            <CustomModal
                isVisible={isNoAnimalError}
                icon={<ImageIguana type="redX" />}
                titleText={i18n.t('mintPickPhoto.errors.notAnimal.title')}
                infoText={i18n.t('mintPickPhoto.errors.notAnimal.text')}
                firstButtonText={i18n.t('mintPickPhoto.errors.notAnimal.try')}
                secondButtonText={i18n.t(
                    'mintPickPhoto.errors.notAnimal.cancel'
                )}
                onFirstButtonClick={() => {
                    openCameraOrFromGallery(imagePickerType).then(() => {
                        handleCloseErrorModal();
                    });
                }}
                onSecondButtonClick={handleCloseErrorModal}
            />
            {renderCameraPermissions(() =>
                openCameraOrFromGallery(imagePickerType)
            )}
            {renderCropper()}
        </>
    );
}
