import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { useQuery } from '@apollo/client';
import IGU_Before from '@assets/icons/mysteryGames/TokenTeller/history/iguBefore.svg';
import Prediction from '@assets/icons/mysteryGames/TokenTeller/history/prediction.svg';
import Button from '@components/Button';
import { ICON_NAMES } from '@components/Icons';
import CoinIcon from '@components/Icons/CoinIcon';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT, TYPO_TEXT_COLOR } from '@components/TypoText/types';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { priceString } from '@helpers/payments';
import { balanceFromWei } from '@helpers/wallet';
import usePreventDoubleClick from '@hooks/usePreventDoubleClick';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import {
    MYSTERY_GAMES,
    TOKEN_TELLER_GAME_PREDICTION_DIRECTION,
    TokenPriceHistoryOutput,
} from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { GET_PAYMENT_TOKEN_PRICE_HISTORY } from '@requests/tokenTeller';

import TokenTellerGameHistoryInfoItem from '../TokenTellerGameHistoryInfoItem';
import stylesMain from './styles';

interface IProps {
    containerStyle?: StyleProp<ViewStyle>;
    type?: 'default' | 'opacity';
    onOpenChart?: () => void;
    predictedAmount?: string | null;
    predictedDirection?: TOKEN_TELLER_GAME_PREDICTION_DIRECTION | null;
    withBottomGap?: boolean;
    initialPrice?: string | null;
}

const GAME = MYSTERY_GAMES.tokenteller;

const TokenTellerPriceStatistic = ({
    containerStyle,
    type = 'default',
    onOpenChart,
    predictedAmount,
    predictedDirection,
    withBottomGap,
    initialPrice,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { priceInUsd } = useWallet();

    const { data } = useQuery<TokenPriceHistoryOutput>(
        GET_PAYMENT_TOKEN_PRICE_HISTORY,
        {
            fetchPolicy: 'cache-and-network',
            pollInterval: 60000,
        }
    );

    const percent = React.useMemo(() => {
        return `${
            data?.paymentsTokensPriceHistory.iguusdt.percent_change_24h?.toFixed(
                2
            ) || 0
        }%`;
    }, [data]);
    const isUpTrend = React.useMemo(
        () =>
            !data?.paymentsTokensPriceHistory.iguusdt.percent_change_24h
                .toString()
                .includes('-'),
        [data]
    );

    const price = React.useMemo(
        () => data?.paymentsTokensPriceHistory.iguusdt.price.toFixed(10) || 0,
        [data]
    );

    const usd = React.useMemo(
        () => `≈ ${priceInUsd(Coin.igu, '1')} $`,
        [priceInUsd]
    );

    const isDefault = React.useMemo(() => type === 'default', [type]);

    const containerCurrentStyle = React.useMemo(
        () => (isDefault ? styles.defaultContainer : styles.opacityContainer),
        [isDefault]
    );

    const titleColor = React.useMemo(
        () => (isDefault ? TYPO_TEXT_COLOR.PRIMARY : TYPO_TEXT_COLOR.ON_DARK),
        [isDefault]
    );
    const textColor = React.useMemo(
        () => (isDefault ? TYPO_TEXT_COLOR.SECONDARY : TYPO_TEXT_COLOR.ON_DARK),
        [isDefault]
    );

    const priceChangeContainerStyle = React.useMemo(() => {
        if (isDefault) {
            return isUpTrend
                ? styles.defaultPriceChangeWrapperUp
                : styles.defaultPriceChangeWrapperDown;
        }
        return styles.opacityPriceChangeWrapper;
    }, [isUpTrend]);

    const priceChangeColor = React.useMemo(
        () => (isUpTrend ? TYPO_TEXT_COLOR.ACCENT : TYPO_TEXT_COLOR.INCORRECT),
        [isUpTrend]
    );

    const buttonTextColor = React.useMemo(
        () => (isDefault ? undefined : styles.onDark),
        [isDefault]
    );
    const iconColor = React.useMemo(
        () => (isDefault ? undefined : styles.onDark.color),
        [isDefault]
    );

    const navigate = useNavigation();

    const onViewChart = () => {
        navigate.push(ROUTES.TOKEN_TELLER_CHART, {
            coin1: Coin.igu,
            coin2: Coin.usdt,
        });
    };

    const { onClick, disabled } = usePreventDoubleClick(
        onOpenChart || onViewChart
    );

    return (
        <View
            style={[
                styles.container,
                containerCurrentStyle,
                withBottomGap && styles.bottomGap,
                containerStyle,
            ]}>
            <View style={styles.top}>
                <View style={styles.coinsNameWrapper}>
                    {isDefault ? (
                        <CoinIcon slug={Coin.igu} size={32} />
                    ) : (
                        <CoinIcon slug={Coin.iguGold} size={32} />
                    )}
                    <TypoText
                        type={TYPO_TEXT.SUBHEAD_BIG_BOLD}
                        colorType={titleColor}
                        additionalStyles={
                            styles.coinsName
                        }>{`${Coin.igu}/${Coin.usdt}`}</TypoText>
                </View>
                <View
                    style={[
                        styles.priceChangeWrapper,
                        priceChangeContainerStyle,
                    ]}>
                    <TypoText
                        type={TYPO_TEXT.SUBHEAD_SMALL_BOLD}
                        colorType={priceChangeColor}>
                        {percent}
                    </TypoText>
                </View>
            </View>
            <View style={styles.bottom}>
                <TypoText
                    textAlign="left"
                    numberOfLines={1}
                    type={TYPO_TEXT.HEADING_H3}
                    colorType={titleColor}>
                    {price}
                </TypoText>
                <View style={styles.btm}>
                    <TypoText
                        textAlign="left"
                        numberOfLines={1}
                        type={TYPO_TEXT.SUBHEAD_SMALL_SEMIBOLD}
                        colorType={textColor}
                        additionalStyles={styles.usd}>
                        {usd}
                    </TypoText>
                    <Button
                        onPress={onClick}
                        disabled={disabled}
                        debouncedPress
                        textStyles={buttonTextColor}
                        type="text"
                        title={i18n.t('general.BUTTONS.viewChart')}
                        size="md"
                        iconName={ICON_NAMES.ARROW_RIGHT}
                        iconDirection="right"
                        iconColor={iconColor}
                    />
                </View>
            </View>
            {!!predictedAmount && !!predictedDirection && !!initialPrice && (
                <View style={[styles.predictions, containerCurrentStyle]}>
                    <TokenTellerGameHistoryInfoItem
                        textColor={textColor}
                        titleColor={titleColor}
                        type={'iguBefore'}
                        text={initialPrice.slice(0, 10)}
                        icon={<IGU_Before width={24} height={24} />}
                    />

                    <TokenTellerGameHistoryInfoItem
                        textColor={textColor}
                        titleColor={titleColor}
                        withGap={false}
                        type={'prediction'}
                        text={`${priceString(
                            balanceFromWei(predictedAmount).valueLong,
                            Coin.vigu
                        )} / ${i18n.t(
                            `HomeScreen.${GAME}.${predictedDirection}`
                        )}`}
                        icon={<Prediction width={24} height={24} />}
                    />
                </View>
            )}
        </View>
    );
};
export default TokenTellerPriceStatistic;
