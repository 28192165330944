import { useCallback, useEffect, useMemo, useState } from 'react';
import { Image, Vibration } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { SET_VOTE_GAME_ROUND } from '@Data/Requests';
import { useMutation } from '@apollo/client';
import { useDimensions } from '@contexts/DimensionsContext';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { isWeb } from '@helpers/app';
import { scale } from '@helpers/dimensions';
import { errorsHandler } from '@helpers/errors';
import { isGraphqlError, wait, waitForModal } from '@helpers/helpers';
import { toastError, toastErrorUnknown } from '@helpers/toastNotification';
import { useGetMysteryLikeGameHistory } from '@hooks/useGetMysteryLikeGameHistory';
import { useGetMysteryLikeGameRound } from '@hooks/useGetMysteryLikeGameRound';
import { useLoadingHook } from '@hooks/useLoadingHook';
import { useMysteryLikesMyPendingGames } from '@hooks/useMysteryLikesMyPendingGames';
import { useShowOnBoarding } from '@hooks/useShowOnBoarding';
import { useVibration } from '@hooks/useVibration';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { useFocusEffect } from '@react-navigation/native';

import { usePersistStorage } from '../../../../../../vendor/react-native-user-persist-storage';
import { getCurrentMysteryLikesGameById } from '../../../helpers';

const WAIT = 4000;

const useMysteryLikesSelectRangeScreen = (
    isNewCreated: boolean | undefined
) => {
    const navigation = useNavigation();
    const { windowWidth } = useDimensions();
    const { top } = useSafeAreaInsets();
    navigation.blockHardwareBack();
    const {
        currentMysteryLikesRound,
        mysteryLikesGameStats,
        setCurrentMysteryLikesRound,
        setCurrentMysteryLikesGameHistory,
        currentMysteryLikesGameId,
        mysteryLikesPendingGame,
        setMysteryLikesPendingGame,
    } = useMysteryGames();

    const { vibrate } = useVibration();

    const { getMysteryLikesGameHistory } = useGetMysteryLikeGameHistory();

    const {
        mysteryLikesMyPendingGames,
        mysteryLikesMyPendingGamesLoading,
        getMysteryLikesMyPendingGamesLazy,
    } = useMysteryLikesMyPendingGames();

    const { getMysteryLikeGameRound } = useGetMysteryLikeGameRound();
    const [setVoteRound] = useMutation(SET_VOTE_GAME_ROUND);

    const imageWidth = useMemo(
        () => windowWidth - scale(20 * 2),
        [windowWidth]
    );

    const { isLoading, startLoading, stopLoading } = useLoadingHook();
    const {
        isLoading: lastLoading,
        startLoading: startLastLoading,
        stopLoading: stopLastLoading,
    } = useLoadingHook();

    const [selectedRange, setSelectedRange] = useState<string>('');

    const onSelectRange = useCallback(
        (range: string) => {
            if (selectedRange === range) {
                return;
            }
            setSelectedRange(range);
        },
        [selectedRange]
    );

    const [taskNumber, setTaskNumber] = usePersistStorage<number>(
        'useMysteryLikesSelectRangeScreen.taskNumber',
        0,
        { persist: isWeb }
    );

    const fetchGameHistory = async (gameId: string) => {
        const res = await getMysteryLikesGameHistory({
            variables: { gameId },
        });
        if (res.data?.mysteryLikesGameHistory) {
            setCurrentMysteryLikesGameHistory(res.data.mysteryLikesGameHistory);
            navigation.replace(ROUTES.MYSTERY_LIKES_SELECT_RANGE_FINISH);
        }
    };
    const fetchGameRound = async (gameId: string) => {
        const res = await getMysteryLikeGameRound({
            variables: { gameId },
        });
        if (res.data?.mysteryLikesGameRound) {
            setCurrentMysteryLikesRound(res.data.mysteryLikesGameRound);
            await Image.prefetch(res.data.mysteryLikesGameRound.petImageUrl);
        }
    };

    const {
        isLoading: initialDataLoading,
        startLoading: startInitialDataLoading,
        stopLoading: stopInitialDataLoading,
    } = useLoadingHook(true);

    useFocusEffect(
        useCallback(() => {
            const fetchInitialData = async () => {
                try {
                    startInitialDataLoading();

                    const pendingGamesData =
                        await getMysteryLikesMyPendingGamesLazy();

                    if (
                        pendingGamesData.data?.mysteryLikesMyPendingGames
                            .length &&
                        currentMysteryLikesGameId
                    ) {
                        const game = getCurrentMysteryLikesGameById(
                            pendingGamesData.data.mysteryLikesMyPendingGames,
                            currentMysteryLikesGameId
                        );
                        if (game) {
                            setTaskNumber(game.roundsCompletedCount);
                            setMysteryLikesPendingGame(game);
                            await fetchGameRound(currentMysteryLikesGameId);
                        } else {
                            await fetchGameHistory(currentMysteryLikesGameId);
                        }
                    }

                    await wait(WAIT);
                    vibrate();
                    stopInitialDataLoading();
                } catch (error) {
                    stopInitialDataLoading();
                    navigation.pop();
                    errorsHandler(error, true);
                }
            };
            if (currentMysteryLikesGameId) {
                fetchInitialData();
            }
        }, [currentMysteryLikesGameId, vibrate])
    );

    const isLastGame = useMemo(
        () => taskNumber + 1 === mysteryLikesGameStats?.maxRoundsCount,
        [taskNumber, mysteryLikesGameStats?.maxRoundsCount]
    );
    const handleLastGame = async (
        gameRoundId: string,
        choice: string,
        currentMysteryLikesGameId: string,
        isNewCreated: boolean | undefined
    ) => {
        startLastLoading();
        setTaskNumber((prev) => prev + 1);
        setSelectedRange('');
        try {
            await setVoteRound({
                variables: {
                    input: {
                        choice,
                        gameRoundId,
                    },
                },
            });
            const result = await getMysteryLikesMyPendingGamesLazy();
            if (result.data?.mysteryLikesMyPendingGames) {
                !isNewCreated && (await wait(WAIT));
                await fetchGameHistory(currentMysteryLikesGameId);
            }

            stopLastLoading();
        } catch (error) {
            stopLastLoading();
            navigation.pop();
            if (isGraphqlError(error, 'MYSTERYLIKES_ROUND_NON_VOTABLE')) {
                toastError(
                    i18n.t(
                        `HomeScreen.MysteryLikes.MysteryLikesSelectRange.toastError`
                    ),
                    undefined,
                    'top',
                    12000
                );
                return;
            }
            errorsHandler(error, true);
        }
    };

    const handleNextGame = async (
        gameRoundId: string,
        choice: string,
        currentMysteryLikesGameId: string
    ) => {
        startLoading();
        setTaskNumber((prev) => prev + 1);
        setSelectedRange('');
        try {
            await setVoteRound({
                variables: {
                    input: {
                        choice,
                        gameRoundId,
                    },
                },
            });
            const result = await getMysteryLikesMyPendingGamesLazy();
            if (result.data?.mysteryLikesMyPendingGames) {
                const game = getCurrentMysteryLikesGameById(
                    result.data.mysteryLikesMyPendingGames,
                    currentMysteryLikesGameId
                );
                if (game) {
                    setTaskNumber(game.roundsCompletedCount);
                    await fetchGameRound(currentMysteryLikesGameId);
                } else {
                    await fetchGameHistory(currentMysteryLikesGameId);
                }
            }

            stopLoading();
        } catch (error) {
            stopLoading();
            navigation.pop();
            if (isGraphqlError(error, 'MYSTERYLIKES_ROUND_NON_VOTABLE')) {
                toastError(
                    i18n.t(
                        `HomeScreen.MysteryLikes.MysteryLikesSelectRange.toastError`
                    ),
                    undefined,
                    'top',
                    12000
                );
                return;
            }
            errorsHandler(error, true);
        }
    };

    const handleButton = useCallback(async () => {
        if (
            !currentMysteryLikesRound ||
            !selectedRange ||
            !currentMysteryLikesGameId
        ) {
            toastErrorUnknown('top');
            return;
        }
        isLastGame
            ? handleLastGame(
                  currentMysteryLikesRound.id,
                  selectedRange,
                  currentMysteryLikesGameId,
                  isNewCreated
              )
            : handleNextGame(
                  currentMysteryLikesRound.id,
                  selectedRange,
                  currentMysteryLikesGameId
              );
    }, [
        currentMysteryLikesRound,
        selectedRange,
        currentMysteryLikesGameId,
        isLastGame,
        handleLastGame,
        isNewCreated,
        handleNextGame,
    ]);
    const handleBack = () => {
        navigation.pop();
    };

    const buttonText = useMemo(() => {
        return isLastGame && selectedRange
            ? i18n.t(
                  `HomeScreen.MysteryLikes.MysteryLikesSelectRange.buttonFinish`
              )
            : i18n.t(`HomeScreen.MysteryLikes.MysteryLikesSelectRange.button`);
    }, [isLastGame, selectedRange]);

    const { canShow } = useShowOnBoarding({
        key: 'mysteryLikesOnboarding123',
    });

    const {
        isVisible: firstTip,
        open: openFirstTip,
        close: closeFirstTip,
    } = useVisibleHook();

    const {
        isVisible: secondTip,
        open: openSecondTip,
        close: closeSecondTip,
    } = useVisibleHook();

    useEffect(() => {
        if (!initialDataLoading && canShow) {
            openFirstTip();
        }
    }, [canShow, initialDataLoading]);

    const actionFirstTip = async () => {
        closeFirstTip();
        await waitForModal();
        openSecondTip();
    };

    return {
        isLoading,
        onSelectRange,
        mysteryLikesGameStats,
        imageWidth,
        handleButton,
        currentMysteryLikesRound,
        selectedRange,
        taskNumber,
        mysteryLikesMyPendingGamesLoading,
        mysteryLikesMyPendingGames,
        mysteryLikesPendingGame,
        initialDataLoading,
        top,
        handleBack,
        buttonText,
        lastLoading,
        firstTip,
        actionFirstTip,
        secondTip,
        closeSecondTip,
    };
};

export default useMysteryLikesSelectRangeScreen;
