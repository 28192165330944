import * as React from 'react';

import { Pet } from '../../Data/Models';
import useThemedStyles from '../../hooks/useThemedStyles';
import i18n from '../../i18n/i18n';
import Button from '../Button';
import ModalBottom from '../ModalBottom';
import PetCollectionListItem from '../PetCollectionListItem';
import { View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    pet: Pet;
    onClose: () => void;
    onPress: () => void;
    modalHeight?: number | string;
}

const ModalPetIsPlaying = ({
    isVisible,
    pet,
    onClose,
    onPress,
    modalHeight = 500,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <ModalBottom
            isVisible={isVisible}
            onClose={onClose}
            modalHeight={modalHeight}
            titleText={i18n.t('petIsPlaying.title')}
            additionalText={i18n.t('petIsPlaying.text')}>
            <View style={styles.petContainer}>
                <PetCollectionListItem pet={pet} />
            </View>
            <View style={styles.buttonContainerModal}>
                <Button
                    onPress={async () => {
                        onClose();

                        onPress?.();
                    }}
                    title={i18n.t('petIsPlaying.button')}
                />
            </View>
        </ModalBottom>
    );
};

export default ModalPetIsPlaying;
