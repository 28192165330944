import * as React from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';

import ICON from '@assets/icons/emptyState/LuckyStrikePurchaseTicket.svg';
import Time from '@assets/icons/mysteryGames/LuckyStrike/time.svg';
import ActivityIndicator from '@components/ActivityIndicator';
import AlertCard from '@components/AlertCard';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import Button from '@components/Button';
import ButtonsBottom from '@components/ButtonsBottom';
import { ICON_NAMES } from '@components/Icons';
import CoinIcon from '@components/Icons/CoinIcon';
import Input from '@components/Input';
import ModalBottom from '@components/ModalBottom';
import TagStatus from '@components/TagStatus';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT, TYPO_TEXT_COLOR } from '@components/TypoText/types';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { isWeb } from '@helpers/app';
import { priceString } from '@helpers/payments';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import {
    MYSTERY_GAMES,
    TOKEN_TELLER_GAME_PREDICTION_DIRECTION,
} from '@models/mysteryGames';
import ROUTES from '@navigation/routes';

import TokenTellerConfirmationModal from '../TokenTellerConfirmationModal';
import TokenTellerDownPayout from '../TokenTellerDownPayout';
import TokenTellerParticipants from '../TokenTellerParticipants';
import TokenTellerPricePool from '../TokenTellerPricePool';
import TokenTellerPriceStatistic from '../TokenTellerPriceStatistic';
import TokenTellerSelectByCardAmount from '../TokenTellerSelectByCardAmount';
import TokenTellerUpPayout from '../TokenTellerUpPayout';
import { useTokenTellerPurchase } from './hook/useTokenTellerPurchase';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onClose: () => void;
    modalHeight?: number | string;
    id: string;
    onViewAllParticipants: (route: ROUTES, params?: any) => Promise<void>;
    routeBack: ROUTES;
    onOpenChartScreen: (route: ROUTES, params?: any) => Promise<void>;
}
const GAME = MYSTERY_GAMES.tokenteller;
const FLOW = 'TokenTellerPurchaseModal';

const TokenTellerPurchaseModal = ({
    isVisible,
    onClose,
    modalHeight = 999,
    id,
    onViewAllParticipants,
    onOpenChartScreen,
    routeBack,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const {
        values,
        errors,
        handleChangeAmount,
        handleOnBlur,
        onSelectCardPress,
        topUp,
        closeConfirmModal,
        confirmModal,
        purchaseLoading,
        handleOnClose,
        time,
        gameLoading,
        onConfirmPress,
        maxAmountShort,
        handleClickAll,
        buttonDisabled,
        isWarningTime,
        direction,
        onButtonsPress,
        game,
        buttonPredictionTextAndStyle,
        commission,
        totalSummary,
        availableBalance,
        isEnoughBalance,
    } = useTokenTellerPurchase(id, isVisible, onClose, routeBack);

    return (
        <ModalBottom
            avoidKeyboard
            isVisible={isVisible}
            onClose={handleOnClose}
            modalHeight={modalHeight}>
            {gameLoading ? (
                <ActivityIndicator
                    loading={true}
                    size={'large'}
                    containerStyle={styles.loading}
                />
            ) : (
                !!game && (
                    <>
                        <ScrollView
                            style={styles.container}
                            keyboardShouldPersistTaps="never"
                            showsVerticalScrollIndicator={false}>
                            <TouchableOpacity activeOpacity={1}>
                                <BlockHeader
                                    componentBetweenImageAndText={
                                        <TagStatus
                                            bottomGap={16}
                                            text={time}
                                            status="DEFAULT"
                                            icon={
                                                <Time width={24} height={24} />
                                            }
                                            alignSelf="center"
                                        />
                                    }
                                    emptyStateIcon={<ICON />}
                                    containerStyle={styles.header}
                                    type="emptyState"
                                    title={i18n.t(
                                        `HomeScreen.${GAME}.${FLOW}.title`
                                    )}
                                    text={i18n.t(
                                        `HomeScreen.${GAME}.${FLOW}.text`,
                                        {
                                            coin: Coin.igu,
                                        }
                                    )}
                                />
                                <View style={styles.inputMainContainer}>
                                    <View style={styles.inputWrapper}>
                                        <View style={styles.icon}>
                                            <CoinIcon
                                                slug={Coin.vigu}
                                                size={32}
                                            />
                                        </View>
                                        <Input
                                            style={[
                                                styles.inputContainer,
                                                isWeb && {
                                                    width: `${Math.max(
                                                        values.amount.length,
                                                        3
                                                    )}ch`,
                                                },
                                            ]}
                                            value={values.amount}
                                            keyboardType="decimal-pad"
                                            onBlur={handleOnBlur}
                                            onChangeText={handleChangeAmount}
                                            placeholder="0"
                                            maxLength={11}
                                            isAvailableExternalStyle
                                            autoComplete="off"
                                            importantForAutofill="no"
                                            autoCorrect={false}
                                        />
                                    </View>
                                    <View style={styles.availableWrapper}>
                                        <TypoText
                                            additionalStyles={styles.flex}
                                            type={TYPO_TEXT.BODY_SMALL}
                                            numberOfLines={1}>
                                            {i18n.t(
                                                'staking.coinsInputScreen.available'
                                            )}
                                            <TypoText
                                                colorType={
                                                    TYPO_TEXT_COLOR.PRIMARY
                                                }
                                                type={
                                                    TYPO_TEXT.SUBHEAD_SMALL_SEMIBOLD
                                                }>
                                                {priceString(
                                                    maxAmountShort,
                                                    Coin.vigu
                                                )}
                                            </TypoText>
                                        </TypoText>
                                        <Button
                                            onPress={handleClickAll}
                                            title={i18n.t(
                                                'staking.coinsInputScreen.maxButton'
                                            )}
                                            containerStyle={styles.buttonAll}
                                            type="outline"
                                            size="sm"
                                        />
                                    </View>
                                    {!!errors.amount && (
                                        <TypoText
                                            additionalStyles={styles.error}
                                            type={TYPO_TEXT.BODY_LITTLE}
                                            colorType={
                                                TYPO_TEXT_COLOR.INCORRECT
                                            }>
                                            {errors.amount}
                                        </TypoText>
                                    )}

                                    <Button
                                        onPress={topUp}
                                        title={i18n.t(
                                            'general.BUTTONS.topUpBalance'
                                        )}
                                        containerStyle={styles.topUp}
                                        type="text"
                                        size="md"
                                        iconName={ICON_NAMES.DEPOSIT}
                                    />

                                    <TokenTellerSelectByCardAmount
                                        containerStyle={styles.cards}
                                        amount={values.amount}
                                        onPress={onSelectCardPress}
                                        availableBalance={
                                            availableBalance?.available
                                        }
                                    />
                                </View>
                                <TokenTellerPriceStatistic
                                    predictedDirection={game.predictedDirection}
                                    predictedAmount={game.predictedAmountWei}
                                    initialPrice={game.beforePrice}
                                    containerStyle={styles.feature}
                                    onOpenChart={() =>
                                        onOpenChartScreen(
                                            ROUTES.TOKEN_TELLER_CHART,
                                            {
                                                coin1: Coin.igu,
                                                coin2: Coin.usdt,
                                            }
                                        )
                                    }
                                />
                                <TokenTellerPricePool
                                    containerStyle={styles.feature}
                                    amount={game.iguAmountWei}
                                />
                                <TokenTellerUpPayout
                                    containerStyle={styles.feature}
                                    game={game}
                                />
                                <TokenTellerDownPayout
                                    containerStyle={styles.feature}
                                    game={game}
                                />

                                <TokenTellerParticipants
                                    containerStyle={styles.feature}
                                    text={`${game.userCount}`}
                                    handlePress={
                                        game.userCount
                                            ? () =>
                                                  onViewAllParticipants(
                                                      ROUTES.TOKEN_TELLER_ALL_PARTICIPANTS,
                                                      { id: game.id }
                                                  )
                                            : undefined
                                    }
                                />
                            </TouchableOpacity>
                        </ScrollView>
                        {game.isPredicted ? (
                            <>
                                <AlertCard
                                    containerStyle={styles.alert}
                                    text={i18n.t(
                                        `HomeScreen.${GAME}.${FLOW}.alert`,
                                        {
                                            direction: i18n.t(
                                                `HomeScreen.${GAME}.${game.predictedDirection}`
                                            ),
                                        }
                                    )}
                                />
                                <ButtonsBottom
                                    safe
                                    loading={purchaseLoading}
                                    title={i18n.t(
                                        `HomeScreen.${GAME}.${buttonPredictionTextAndStyle.text}`
                                    )}
                                    // @ts-ignore
                                    buttonType={
                                        buttonPredictionTextAndStyle.type
                                    }
                                    disabled={buttonDisabled}
                                    onPress={() =>
                                        onButtonsPress(game.predictedDirection)
                                    }
                                />
                            </>
                        ) : (
                            <ButtonsBottom
                                safe
                                loading={purchaseLoading}
                                containerStyle={styles.buttons}
                                title={i18n.t(
                                    `HomeScreen.${GAME}.${TOKEN_TELLER_GAME_PREDICTION_DIRECTION.Down}`
                                )}
                                buttonType="critical"
                                disabled={buttonDisabled}
                                buttonStyle={styles.buttonWidth}
                                onPress={() =>
                                    onButtonsPress(
                                        TOKEN_TELLER_GAME_PREDICTION_DIRECTION.Down
                                    )
                                }>
                                <Button
                                    disabled={buttonDisabled}
                                    type="success"
                                    debouncedPress
                                    title={i18n.t(
                                        `HomeScreen.${GAME}.${TOKEN_TELLER_GAME_PREDICTION_DIRECTION.Up}`
                                    )}
                                    loading={purchaseLoading}
                                    containerStyle={styles.buttonWidth}
                                    onPress={() =>
                                        onButtonsPress(
                                            TOKEN_TELLER_GAME_PREDICTION_DIRECTION.Up
                                        )
                                    }
                                />
                            </ButtonsBottom>
                        )}

                        {direction && (
                            <TokenTellerConfirmationModal
                                isAddTokens={game.isPredicted}
                                isVisible={confirmModal}
                                onClose={closeConfirmModal}
                                onConfirm={onConfirmPress}
                                amount={values.amount}
                                commission={commission}
                                totalSummary={totalSummary}
                                direction={direction}
                                game={MYSTERY_GAMES.tokenteller}
                                disabled={isWarningTime}
                                isEnoughBalance={isEnoughBalance}
                                onTopUp={topUp}
                            />
                        )}
                    </>
                )
            )}
        </ModalBottom>
    );
};

export default TokenTellerPurchaseModal;
