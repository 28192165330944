import * as React from 'react';

import OtherUserAvatar from '@components/OtherUserAvatar';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT } from '@components/TypoText/types';
import { scale } from '@helpers/dimensions';
import { balanceFromWei, truncateEthAddress } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import {
    MYSTERY_GAMES,
    TokentellerGameParticipantRowOutput,
} from '@models/mysteryGames';

import GameAmount from '../GameAmount';
import stylesMain from './styles';

interface IProps {
    item: TokentellerGameParticipantRowOutput;
}

const GAME = MYSTERY_GAMES.tokenteller;

const TokenTellerParticipantItem = ({ item }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={[styles.itemContainer]}>
            <View style={styles.left}>
                <View style={styles.imageWrapper}>
                    <OtherUserAvatar
                        size={scale(48)}
                        imageUrl={item.avatarUrl}
                    />
                </View>
                <View style={styles.infoBlock}>
                    <TypoText
                        type={TYPO_TEXT.BODY_BIG}
                        ellipsizeMode={'middle'}
                        numberOfLines={1}
                        additionalStyles={styles.removeAlign}>
                        {truncateEthAddress(item.username)}
                    </TypoText>
                    <View style={styles.sub}>
                        <TypoText
                            type={TYPO_TEXT.SUBHEAD_LITTLE_SEMIBOLD}
                            ellipsizeMode={'middle'}
                            numberOfLines={1}>
                            {`${i18n.t(`HomeScreen.${GAME}.priceDirection`)}: `}
                            <TypoText
                                type={TYPO_TEXT.SUBHEAD_LITTLE_BOLD}
                                ellipsizeMode={'middle'}
                                numberOfLines={1}>
                                {`${i18n.t(
                                    `HomeScreen.${GAME}.${item.direction}`
                                )}`}
                            </TypoText>
                        </TypoText>
                    </View>
                </View>
            </View>

            <View style={styles.right}>
                <GameAmount
                    amount={balanceFromWei(item.iguAmountWei).valueLong}
                />
            </View>
        </View>
    );
};
export default TokenTellerParticipantItem;
