import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { theme } from '../../types';

const stylesMain = (theme: theme) =>
    StyleSheet.create({
        slider: {
            width: '100%',
        },
        thumb: {
            width: 24,
            height: 24,
            borderRadius: 12,
            borderWidth: 1,
            backgroundColor: Colors[theme].buttonBackgroundOutline,
            borderColor: Colors[theme].border.primary,
        },
        shadow: {
            width: 24,
            height: 24,
            borderRadius: 12,
            borderBottomWidth: 26,
            borderColor: Colors[theme].buttonShadowOutline,
        },
        rail: {
            height: 12,
            backgroundColor: Colors[theme].buttonBorderOutline,
            borderRadius: 6,
            flex: 1,
        },
        railSelected: {
            height: 12,
            borderRadius: 6,
            backgroundColor: Colors[theme].levelProgress.full,
        },
    });

export default stylesMain;
