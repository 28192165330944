import * as React from 'react';
import { useCallback } from 'react';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import EmptyList from '@components/EmptyList';
import { ICON_NAMES } from '@components/Icons';
import LoadingComponent from '@components/LoadingComponent';
import PullToRefresh from '@components/PullToRefresh';
import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';
import { NAVIGATION_LUCKY_STRIKE } from '@navigation/NavigationBar/NavigationLuckyStrike';
import NavigationLuckyStrike from '@navigation/NavigationBar/NavigationLuckyStrike';
import ROUTES from '@navigation/routes';

import { LuckyStrikeMainProps } from '../../../../../types';
import GamesNavigation from '../../components/GamesNavigation';
import LuckyStrikeSlots from '../../components/LuckyStrikeSlots';
import LuckyStrikeSlotsModal from '../../components/LuckyStrikeSlotsModal';
import LuckyStrikeWinnerItem from '../../components/LuckyStrikeWinnerItem';
import LuckyStrikeWinners from '../../components/LuckyStrikeWinners';
import useLuckyStrikeMainScreen from './hooks/useLuckyStrikeMainScreen';
import stylesMain from './styles';

const LuckyStrikeMainScreen = ({ route }: LuckyStrikeMainProps) => {
    const styles = useThemedStyles(stylesMain);
    const {
        handleRefresh,
        isRefresh,
        isSmallLayout,
        isLoading,
        handOnHowItWorks,
        slotsModal,
        openSlotsModal,
        closeSlotsModal,
        onPurchaseTicket,
        renderPurchaseModal,
        luckyStrikeGames,
        currentGame,
        onEndTime,
        statistics,
        winners,
        isReloading,
    } = useLuckyStrikeMainScreen(route.params?.game);

    const headerComponent = useCallback(() => {
        return (
            <>
                <BlockHeader
                    containerStyle={styles.header}
                    type="primary"
                    title={i18n.t(
                        `HomeScreen.${MYSTERY_GAMES.luckyStrike}.LuckyStrikeMain.title`
                    )}
                    text={i18n.t(
                        `HomeScreen.${MYSTERY_GAMES.luckyStrike}.LuckyStrikeMain.text`
                    )}
                />
                {!!luckyStrikeGames?.length && (
                    <LuckyStrikeSlots
                        routeBack={ROUTES.LUCKY_STRIKE_MAIN}
                        games={luckyStrikeGames}
                        onPress={onPurchaseTicket}
                        containerStyle={styles.slots}
                        loading={isReloading}
                        onEndTime={onEndTime}
                    />
                )}
                {!!statistics && <LuckyStrikeWinners statistics={statistics} />}
            </>
        );
    }, [
        luckyStrikeGames,
        onPurchaseTicket,
        statistics,
        isReloading,
        onEndTime,
    ]);

    const emptyComponent = useCallback(() => {
        return (
            <EmptyList
                containerStyle={styles.emptyList}
                emptyTitle={i18n.t(
                    `HomeScreen.${MYSTERY_GAMES.luckyStrike}.LuckyStrikeMain.emptyList.title`
                )}
                emptyText={i18n.t(
                    `HomeScreen.${MYSTERY_GAMES.luckyStrike}.LuckyStrikeMain.emptyList.text`
                )}
                withAnimation="cry"
            />
        );
    }, [isSmallLayout]);

    return (
        <View style={styles.main}>
            <GamesNavigation
                backIconName={ICON_NAMES.CLOSE}
                type={MYSTERY_GAMES.luckyStrike}
                onPress={openSlotsModal}
                loading={isReloading}
                handleOnInfoIconPress={handOnHowItWorks}
                buttonTitle={i18n.t(
                    `HomeScreen.${MYSTERY_GAMES.luckyStrike}.purchaseTicket`
                )}
            />
            <NavigationLuckyStrike
                currentNavigation={NAVIGATION_LUCKY_STRIKE.ROUNDS}
            />
            <View style={styles.content}>
                {isLoading && !luckyStrikeGames ? (
                    <LoadingComponent text={i18n.t('general.loading')} />
                ) : (
                    <PullToRefresh
                        refreshing={isRefresh}
                        textLoading={i18n.t('pullToRefresh.loading')}
                        onRefresh={handleRefresh}
                        HeaderComponent={headerComponent()}
                        EmptyComponent={emptyComponent()}
                        renderData={winners || []}
                        //@ts-ignore ignore item type any
                        renderItem={({ item, index }) => (
                            <LuckyStrikeWinnerItem key={index} item={item} />
                        )}
                        shouldUseEmptyComponentInFooter
                    />
                )}
            </View>
            {luckyStrikeGames && (
                <LuckyStrikeSlotsModal
                    isVisible={slotsModal}
                    onClose={closeSlotsModal}
                    openModal={openSlotsModal}
                    onPurchaseTicket={onPurchaseTicket}
                    loading={isReloading}
                    games={luckyStrikeGames}
                    routeBack={ROUTES.LUCKY_STRIKE_MAIN}
                />
            )}
            {!!currentGame && renderPurchaseModal(currentGame)}
        </View>
    );
};

export default LuckyStrikeMainScreen;
