import { StyleSheet } from 'react-native';

import { moderateScale, scale, verticalScale } from '@helpers/dimensions';

import Colors from '../../../constants/Colors';
import font from '../../../helpers/font';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        safe: {
            backgroundColor: Colors[theme].background,
        },
        container: {
            flex: 1,
        },
        wrapper: {
            flex: 1,
            justifyContent: 'space-between',
            paddingBottom: verticalScale(16),
        },
        scrollViewContent: {
            alignItems: 'center',
            backgroundColor: Colors[theme].background,
            paddingHorizontal: scale(20),
        },
        buttonContainer: {
            paddingTop: verticalScale(20),
            paddingHorizontal: scale(20),
        },
        buttonGap: {
            marginBottom: verticalScale(12),
        },
        title: {
            color: Colors[theme].text.primary,
            textAlign: 'center',
            marginTop: verticalScale(24),
            marginBottom: verticalScale(8),
            ...font({
                size: 22,
                height: 28,
                weight: '800',
            }),
        },
        text: {
            color: Colors[theme].text.secondary,
            textAlign: 'center',
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            letterSpacing: 0.1,
        },
        userWrapper: {
            marginTop: verticalScale(16),
            width: '100%',
            borderRadius: moderateScale(12),
            padding: moderateScale(16),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            flexDirection: 'row',
        },
        userInfo: {
            marginLeft: scale(12),
        },
        textEmail: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '600',
            }),
        },
        textPets: {
            color: Colors[theme].text.primary,
            marginTop: verticalScale(4),
            ...font({
                size: 13,
                height: 18,
            }),
        },
        textPetsCount: {
            color: Colors[theme].text.primary,
            ...font({
                size: 13,
                height: 18,
            }),
        },
    });

export default styles;
