import React from 'react';

import NftRoyalRevokeBanIcon from '@assets/icons/profile/admin/NftRoyalRevokeBan.svg';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import MenuItem from '../../../MenuItem';
import { RightComponent, iconSize } from '../../helpers';
import stylesMain from './styles';

const AdminNftRoyaleRevokeBan = () => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    return (
        <MenuItem
            leftIcon={
                <NftRoyalRevokeBanIcon width={iconSize} height={iconSize} />
            }
            title={i18n.t('profile.menuItems.nftRoyaleRevokeBan.title')}
            description={i18n.t(
                'profile.menuItems.nftRoyaleRevokeBan.description'
            )}
            rightComponent={<RightComponent type="arrowRight" />}
            onPress={() =>
                navigation.navigate(ROUTES.ADMIN_NFT_ROYAL_REVOKE_BAN)
            }
            titleStyle={styles.titleStyle}
            textStyle={styles.textStyle}
        />
    );
};

export default AdminNftRoyaleRevokeBan;
