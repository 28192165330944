import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        amountBlock: {
            flexDirection: 'row',
            alignItems: 'center',
            borderRadius: 50,
            paddingHorizontal: scale(8),
            paddingVertical: verticalScale(6),
            backgroundColor: Colors[theme].surfaceDecorativeGrayMinimal,
            alignSelf: 'flex-end',
            maxWidth: 100,
        },
        isOneColor: {
            backgroundColor: Colors[theme].alwaysOneColor.background,
        },
        amount: {
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
            color: Colors[theme].text.gray,
            marginLeft: scale(4),
        },
        isOneColorText: {
            color: Colors[theme].alwaysOneColor.text,
        },
    });

export default styles;
