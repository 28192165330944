import React from 'react';

import * as Device from 'expo-device';

import { useConfig } from '@contexts/ConfigContext';
import { useUser } from '@contexts/UserContext';
import {
    appVersion,
    getFullDeviceName,
    isIOS,
    otaUpdateGroup,
} from '@helpers/app';
import { getDeviceId } from '@helpers/deviceId';
import { toastError, toastSuccess } from '@helpers/toastNotification';

import SupportEmailIcon from '../../../../../../assets/icons/profile/supportEmail.svg';
import useSendEmail from '../../../../../../hooks/useSendEmail';
import i18n from '../../../../../../i18n/i18n';
import MenuItem from '../../../MenuItem';
import { RightComponent, iconSize } from '../../helpers';

const SupportEmailMenuItem = () => {
    const { user } = useUser();
    const { config } = useConfig();
    const { send: sendEmail } = useSendEmail();

    const handleOnSupport = async () => {
        if (user && config) {
            const deviceId = await getDeviceId();

            const result = await sendEmail(
                config.supportEmail,
                user.email,
                'Support Request',
                [
                    {
                        name: 'App Version',
                        value: appVersion,
                    },
                    {
                        name: 'Update Version',
                        value: otaUpdateGroup,
                    },
                    {
                        name: 'Platform',
                        value: isIOS ? 'ios' : 'android',
                    },
                    {
                        name: 'Wallet',
                        value: user.walletAddress,
                    },
                    {
                        name: 'Device ID',
                        value: deviceId ?? '-',
                    },
                    {
                        name: 'Device Model',
                        value: getFullDeviceName(),
                    },
                    {
                        name: 'Device OS',
                        value: Device.osName,
                    },
                    {
                        name: 'Main Pet ID',
                        value: user.mainPet?.id ? user.mainPet?.id : '-',
                    },
                ]
            );

            if (result === 'sent') {
                toastSuccess(
                    i18n.t('profile.support.success.title'),
                    i18n.t('profile.support.success.text')
                );
            } else if (result === 'cancelled' || result === 'saved') {
                toastError(i18n.t('profile.support.notSent.text'));
            } else if (result === 'error') {
                toastError(i18n.t('profile.support.errorOpen.text'));
            }
        }
    };

    return (
        <MenuItem
            leftIcon={<SupportEmailIcon width={iconSize} height={iconSize} />}
            title={i18n.t('profile.menuItems.supportEmail.title')}
            description={i18n.t('profile.menuItems.supportEmail.description')}
            rightComponent={<RightComponent type="arrowRight" />}
            onPress={handleOnSupport}
        />
    );
};

export default SupportEmailMenuItem;
