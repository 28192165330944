import React from 'react';
import { StyleProp } from 'react-native';
import { AnimateProps, StyleProps } from 'react-native-reanimated';
import ReanimatedCarousel, {
    ICarouselInstance,
} from 'react-native-reanimated-carousel';

import useThemedStyles from '../../hooks/useThemedStyles';
import GestureRootView from '../GestureRootView';
import { View, ViewProps } from '../Themed';
import TouchableOpacity from '../TouchableOpacity/index.web';
import AnimatedItem from './components/AnimatedView';
import stylesMain from './styles';

interface IProps {
    sliderWidth?: number | string;
    itemWidth: number;
    renderItem: (item: any, index?: number) => unknown;
    data: any[];
    height?: number | string;
    containerStyle?: StyleProp<ViewProps>;
    pagination?: boolean;
    fullHeight?: boolean;
    centredVertical?: boolean;
    insideScrollView?: boolean;
    carouselStyle?: StyleProps;
    itemStyle?: StyleProp<ViewProps>;
    startIndex?: number;
    bulletActiveStyle?: StyleProp<ViewProps>;
    bulletStyle?: StyleProp<ViewProps>;
    onIndexChange?: (index: number) => void;
}

const Carousel = ({
    sliderWidth = 'auto',
    itemWidth,
    renderItem,
    inactiveSlideOpacity = 1,
    inactiveSlideScale = 1,
    height,
    containerStyle,
    fullHeight = false,
    pagination = false,
    centredVertical = false,
    insideScrollView = true,
    carouselStyle = {},
    itemStyle = {},
    startIndex,
    bulletActiveStyle,
    bulletStyle,
    onIndexChange,
    ...props
}: IProps & AnimateProps<any>) => {
    const styles = useThemedStyles(stylesMain);
    const [activeStep, setActiveStep] = React.useState(0);

    const carouselRef = React.useRef<ICarouselInstance>(null);

    React.useEffect(() => {
        startIndex &&
            carouselRef.current?.scrollTo({
                index: startIndex,
                animated: false,
            });
    }, [startIndex]);

    return (
        <View
            style={[
                styles.container,
                { height },
                pagination && {
                    paddingBottom: 30,
                },
                fullHeight && { flex: 1 },
                containerStyle,
            ]}>
            <GestureRootView>
                <ReanimatedCarousel
                    ref={carouselRef}
                    style={[
                        {
                            width: sliderWidth,
                            justifyContent: 'center',
                            alignItems: 'center',
                        },
                        carouselStyle,
                    ]}
                    panGestureHandlerProps={
                        insideScrollView
                            ? {
                                  failOffsetY: [-15, 15],
                                  activeOffsetX: [-15, 15],
                              }
                            : {}
                    }
                    width={itemWidth}
                    renderItem={({ item, animationValue, index }) => (
                        <AnimatedItem
                            itemStyle={itemStyle}
                            animationValue={animationValue}
                            inactiveSlideOpacity={inactiveSlideOpacity}
                            centredVertical={centredVertical}
                            inactiveSlideScale={inactiveSlideScale}>
                            {renderItem(item, index)}
                        </AnimatedItem>
                    )}
                    loop={false}
                    onProgressChange={(offsetProgress, absoluteProgress) => {
                        setActiveStep(Math.round(absoluteProgress));
                        onIndexChange?.(Math.round(absoluteProgress));
                    }}
                    {...props}
                />
            </GestureRootView>
            {pagination && (
                <View style={styles.paginationContainer}>
                    {props.data.map((item, index) => (
                        <TouchableOpacity
                            onPress={() =>
                                carouselRef.current?.scrollTo({
                                    index,
                                    animated: true,
                                })
                            }
                            key={'pagination' + index}
                            style={[
                                styles.paginationItem,
                                bulletStyle,
                                activeStep === index &&
                                    styles.paginationItemActive,
                                activeStep === index && bulletActiveStyle,
                            ]}
                        />
                    ))}
                </View>
            )}
        </View>
    );
};

export default Carousel;
