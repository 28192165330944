import React, { useContext } from 'react';

import { useCharity } from '@contexts/CharityContexts';
import { useFocusEffect } from '@react-navigation/native';

import { DonationsHistoryResponseItem } from '../../../../Data/Models';
import Button from '../../../../components/Button';
import Divider from '../../../../components/Divider';
import ImageHeaderScreen from '../../../../components/ImageHeaderScreen';
import { Text, View } from '../../../../components/Themed';
import { UserContext } from '../../../../contexts/UserContext';
import useGetDonationsHistory from '../../../../hooks/useGetDonationsHistory';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import NavigationBar from '../../../../navigation/NavigationBar';
import ROUTES from '../../../../navigation/routes';
import { useNavigation } from '../../../../navigation/useNavigation';
import { CharityDetailedOrganizationProps } from '../../../../types';
import OrganizationInfo from '../components/OrganizationInfo';
import TotalOrganizationDonations from '../components/TotalOrganizationDonations';
import UserDonate from '../components/UserDonate';
import stylesMain from './styles';

const CharityDetailedOrganizationScreen = ({
    route,
}: CharityDetailedOrganizationProps) => {
    const { getCharityById, charities } = useCharity();
    const { charity: id } = route.params;
    const charity = getCharityById(id);
    console.log({ charities, charity, id });
    const navigation = useNavigation();
    const styles = useThemedStyles(stylesMain);
    const [historyData, setHistoryData] = React.useState<
        DonationsHistoryResponseItem[]
    >([]);
    const { user } = useContext(UserContext);
    const getDonationsHistory = useGetDonationsHistory();

    useFocusEffect(
        React.useCallback(() => {
            getDonationsHistory({
                page: 1,
                limit: 3,
                charityId: charity.id,
                onCompleted: ({ donationsHistory }) => {
                    setHistoryData(donationsHistory.items);
                },
            });
        }, [])
    );

    const renderUsersDonations = () => {
        return historyData.map((donation) => (
            <UserDonate donation={donation} key={donation.id} />
        ));
    };

    return (
        <>
            <NavigationBar containerStyle={styles.navBar} backIcon />
            <ImageHeaderScreen image={charity.logoUrl} imageHeight={300}>
                <OrganizationInfo
                    charity={charity}
                    isSelected={user?.charity?.id === charity.id}
                />
                <Divider customStyles={styles.divider} />
                <View style={styles.donationsTitleContainer}>
                    <Text style={styles.subtitle}>
                        {i18n.t('charity.donations')}
                    </Text>
                    <Button
                        type="text"
                        title={i18n.t('charity.viewAll')}
                        onPress={() =>
                            navigation.navigate(
                                ROUTES.CHARITY_ORGANIZATION_DONATIONS,
                                { charity }
                            )
                        }
                    />
                </View>
                <TotalOrganizationDonations
                    walletAddress={charity.ethAddress}
                    totalDonations={charity.totalDonationsIgupWei}
                />
                {renderUsersDonations()}
            </ImageHeaderScreen>
        </>
    );
};

export default CharityDetailedOrganizationScreen;
