import { useLazyQuery } from '@apollo/client';

import { ImaginationFormResponse } from '../Data/Models';
import { GET_IMAGINATION_FORM_DATA } from '../Data/Requests';

const useImaginationFormData = () => {
    const [getImaginationFormData, { data, error, loading }] =
        useLazyQuery<ImaginationFormResponse>(GET_IMAGINATION_FORM_DATA, {
            onError: () => {
                Console.log('[GET_IMAGINATION_FORM_DATA]', error);
            },
        });

    return { getImaginationFormData, data, error, loading };
};

export default useImaginationFormData;
