import * as React from 'react';

import CoinBNB from '@assets/icons/coins/BNB.svg';
import CoinBUSD from '@assets/icons/coins/BUSD.svg';
import CoinIGU from '@assets/icons/coins/IGU.svg';
import CoinIGUP from '@assets/icons/coins/IGUP.svg';
import CoinIGU_GOLD from '@assets/icons/coins/IGU_GOLD.svg';
import CoinVIGU from '@assets/icons/coins/VIGU.svg';
import CoinVIGUP from '@assets/icons/coins/VIGUP.svg';
import ApplePayDark from '@assets/icons/payments/applePayDark.svg';
import ApplePayLight from '@assets/icons/payments/applePayLight.svg';
import GooglePay from '@assets/icons/payments/googlePay.svg';
import { useTheme } from '@contexts/ThemeContext';
import { isAndroid, isIOS } from '@helpers/app';

import { IconType, iconSize } from '../types';

interface IProps extends IconType {
    slug: string;
}

const CoinIcon = ({ slug, size = iconSize.primary }: IProps) => {
    const { isLight } = useTheme();

    switch (slug) {
        case 'BNB':
            return <CoinBNB width={size} height={size} />;

        case 'BUSD':
            return <CoinBUSD width={size} height={size} />;

        case 'IGU':
            return <CoinIGU width={size} height={size} />;
        case 'IGU_GOLD':
            return <CoinIGU_GOLD width={size} height={size} />;

        case 'IGUP':
            return <CoinIGUP width={size} height={size} />;

        case 'VIGUP':
            return <CoinVIGUP width={size} height={size} />;
        case 'VIGU':
            return <CoinVIGU width={size} height={size} />;

        case 'STORE':
            if (isIOS) {
                return isLight ? (
                    <ApplePayLight width={size} height={size} />
                ) : (
                    <ApplePayDark width={size} height={size} />
                );
            }

            if (isAndroid) {
                return <GooglePay width={size} height={size} />;
            }

            return null;

        default:
            Console.error(`No coin icon with slug ${slug} is available`);
            return null;
    }
};

export default CoinIcon;
