import * as React from 'react';
import Animated, {
    cancelAnimation,
    useAnimatedStyle,
    useSharedValue,
} from 'react-native-reanimated';

import useThemedStyles from '../../../../hooks/useThemedStyles';
import Icon, { ICON_NAMES } from '../../../Icons';
import { Text } from '../../../Themed';
import mainStyles from './styles';

interface IProps {
    isRefreshing?: boolean;
    textLoading?: string;
    textReleasing?: string;
    textPulling?: string;
    offsetY: React.MutableRefObject<number>;

    arrowType: boolean;
}

const PullToRefreshArrow: React.FC<IProps> = ({
    isRefreshing,
    offsetY,

    textReleasing,
    textPulling,
    arrowType,
}) => {
    const styles = useThemedStyles(mainStyles);

    const textPullToRefreshDownText = arrowType ? textReleasing : textPulling;

    const offset = useSharedValue(0);

    React.useEffect(() => {
        offset.value = offsetY.current;
    }, [offsetY.current]);

    const animatedStyles = useAnimatedStyle(() => {
        return {
            transform: [{ translateY: -offset.value * 0.02 }],
        };
    });

    // Clear animation on unmount
    React.useEffect(() => {
        return () => {
            if (offset && cancelAnimation) cancelAnimation(offset);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return !isRefreshing ? (
        <Animated.View style={[styles.arrowIcon, animatedStyles.transform]}>
            <Icon
                name={arrowType ? ICON_NAMES.ARROW_UP : ICON_NAMES.ARROW_DOWN}
                size={16}
                color="secondary"
            />
            <Text style={styles.text}>{textPullToRefreshDownText}</Text>
        </Animated.View>
    ) : (
        <></>
    );
};

export default PullToRefreshArrow;
