import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        shadow: {
            borderRadius: 1000,
            backgroundColor: Colors[theme].shadow.default,
        },
        container: {
            paddingHorizontal: scale(4),
            paddingVertical: verticalScale(3),
            backgroundColor: Colors[theme].background,
            borderWidth: 1,
            borderRadius: 1000,
            marginBottom: verticalScale(3),
            borderColor: Colors[theme].border.primary,
            flexDirection: 'row',
        },
        main: {
            paddingHorizontal: scale(20),
            paddingTop: verticalScale(20),
            borderBottomRightRadius: moderateScale(20),
            borderBottomLeftRadius: moderateScale(20),
        },
        item: {
            flex: 1,
            alignItems: 'center',
        },
    });

export default styles;
