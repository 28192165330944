import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            marginTop: verticalScale(8),
            flexDirection: 'row',
            alignItems: 'center',
        },
        circle: {
            marginHorizontal: 0,
            marginRight: scale(6),
        },
        text: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 21,
                weight: '400',
            }),
            letterSpacing: 0.08,
        },
    });

export default styles;
