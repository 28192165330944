import * as React from 'react';

import Svg from '../../assets/icons/login/facebook.svg';
import { IconType, iconSize } from './types';

const Facebook: React.FunctionComponent<IconType> = ({
    size = iconSize.default,
}) => {
    return <Svg width={size} height={size} />;
};

export default Facebook;
