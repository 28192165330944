import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { verticalScale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        buttonWrapper: {},
        buttonInner: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        buttonLink: {
            color: Colors[theme].text.accent,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
            letterSpacing: 0.16,
            marginRight: verticalScale(6),
        },
    });

export default styles;
