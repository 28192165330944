import { StyleSheet } from 'react-native';

import { verticalScale } from '../../helpers/dimensions';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
        },
        topWrapper: {
            alignItems: 'center',
        },
        walletAddressWrapper: { marginVertical: verticalScale(20) },
        network: {
            alignItems: 'center',
            marginBottom: verticalScale(20),
        },
        button: {
            alignSelf: 'center',
            marginVertical: verticalScale(20),
        },
    });

export default styles;
