import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        mainContainer: {
            position: 'relative',
            flex: 1,
        },
        avoidingView: {
            flex: 1,
        },
        container: {
            flex: 1,
            paddingHorizontal: scale(20),
        },
        wrapper: {
            flex: 1,
        },
        main: {
            flex: 1,
            backgroundColor: 'transparent',
        },
        gap: {
            marginBottom: verticalScale(16),
        },
        socialCardWrapper: {
            borderWidth: 1,
            backgroundColor: Colors[theme].background,
            borderColor: Colors[theme].border.primary,
            borderTopLeftRadius: moderateScale(12),
            borderTopRightRadius: moderateScale(12),
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
        },
        petIdWrapper: {
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderBottomLeftRadius: moderateScale(12),
            borderBottomRightRadius: moderateScale(12),
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            borderTopWidth: 0,
        },
        buttonWrapper: {
            paddingVertical: verticalScale(16),

            justifyContent: 'flex-end',
        },
        playCardWrapper: {
            borderWidth: 1,
            borderRadius: 12,
            borderColor: Colors[theme].border.primary,
            zIndex: 2,
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            backgroundColor: Colors[theme].background,
        },
        questionWrapper: {
            marginTop: verticalScale(8),
        },
        questionIcon: {
            marginRight: scale(6),
        },
        questionText: {
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
            letterSpacing: 0.08,
        },
        flow: {
            paddingHorizontal: 0,
            marginLeft: verticalScale(16),
            marginRight: verticalScale(4),
            flex: 1,
        },
        navBg: {
            backgroundColor: 'transparent',
        },
        shadow: {
            position: 'absolute',
            bottom: -4,
            height: 24,
            width: '100%',
            borderBottomLeftRadius: 12,
            borderBottomRightRadius: 12,
            backgroundColor: Colors[theme].shadow.default,
            zIndex: 1,
        },
        shadowContainer: {
            backgroundColor: 'transparent',
            marginBottom: verticalScale(24),
        },
        gradientBackground: {
            width: '100%',
            height: verticalScale(164),
            borderBottomLeftRadius: 20,
            borderBottomRightRadius: 20,
            position: 'absolute',
        },
        header: {
            backgroundColor: 'transparent',
            marginTop: 0,
        },
        playCard: {
            paddingHorizontal: 0,
            paddingVertical: 0,
        },
        tips: { marginTop: verticalScale(16) },
    });

export default styles;
