import * as React from 'react';
import { StyleProp, TextStyle, ViewStyle } from 'react-native';

import Icon, { ICON_NAMES } from '../../../../../components/Icons';
import { Text, View } from '../../../../../components/Themed';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import stylesMain from './styles';

interface IProps {
    energy?: number | string;
    text?: string;
    size?: number;
    textStyles?: StyleProp<TextStyle>;
    containerStyles?: StyleProp<ViewStyle>;
    iconRight?: boolean;
    displayIcon?: boolean;
}

const EnergyBlock = ({
    energy,
    text,
    size = 20,
    containerStyles,
    textStyles,
    iconRight,
    displayIcon = true,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={[styles.container, containerStyles]}>
            {displayIcon && !iconRight && (
                <Icon name={ICON_NAMES.ENERGY} size={size} />
            )}
            <Text
                style={[
                    styles.energyText,
                    iconRight && styles.textIconRight,
                    textStyles,
                ]}>
                {energy}
                {!!text && text}
            </Text>
            {displayIcon && iconRight && (
                <Icon name={ICON_NAMES.ENERGY} size={size} />
            )}
        </View>
    );
};

export default EnergyBlock;
