import { BigNumber, Wallet, ethers } from 'ethers';
import _, { isNumber } from 'lodash';

import { GasPriceConfig } from '@Data/Models';

export interface IBalance {
    value: string;
    valueLong: string;
    valueShort: string;
}

export declare type BalanceNumber =
    | string
    | number
    | BigNumber
    | undefined
    | null;

export function numberToString(number: BalanceNumber): string {
    if (!number || _.isNaN(number) || number === 'NaN') {
        return '0';
    }

    if (isNumber(number)) {
        return number.toString();
    }

    return number.toString();
}

// balance format
export function balanceFromWei(wei: BalanceNumber): IBalance {
    const weiValue = numberToString(wei);
    const ether = ethers.utils.formatEther(weiValue);
    const number = Number(ether) ?? 0;
    const valueLong = roundDownSignificantDigits(number, 4);

    return {
        value: ether,
        valueLong: valueLong.toString(),
        valueShort: balanceShortFormatter(number) ?? '',
    };
}

// sum balances in wei
export function balanceWeiSum(balanceL: IBalance, balanceR: IBalance) {
    const difference = Number(balanceL.valueLong) + Number(balanceR.valueLong);
    return balanceFromWei(valueToWei(difference.toString()));
}

// subtract balances in wei
export function balanceWeiSubtract(balanceL: IBalance, balanceR: IBalance) {
    const difference = Number(balanceL.valueLong) - Number(balanceR.valueLong);
    return balanceFromWei(valueToWei(difference.toString()));
}

export function valueToWei(value: BalanceNumber) {
    return ethers.utils.parseEther(numberToString(value)).toString();
}

export function valueFromWei(wei: BalanceNumber): string {
    return balanceFromWei(numberToString(wei)).value;
}

export function inputNumberFormatter(text = '', maximumFractionDigits = 4) {
    const amount = (text.replace(/,/g, '.').match(/([0-9]*[.])?[0-9]*/g) || [
        '0',
    ])[0];
    const pointIndex = amount.indexOf('.');
    let convertedValue;
    if (pointIndex < 0) {
        convertedValue = Number.isNaN(parseFloat(amount)) ? '' : amount;
    } else if (pointIndex === 0) {
        convertedValue = `0${amount.slice(0, 1 + maximumFractionDigits)}`;
    } else {
        const intStr = amount.slice(0, pointIndex);
        convertedValue = `${intStr}.${amount.slice(
            pointIndex + 1,
            pointIndex + 1 + maximumFractionDigits
        )}`;
    }
    return convertedValue;
}

// if token amount is bigger than maximum possible number
export function isBiggerThanMax(text: BalanceNumber) {
    return Number(text) > 100000000000;
}

export function numberFormatter(
    balance: BalanceNumber,
    maximumFractionDigits = 4
) {
    return Number(
        inputNumberFormatter(numberToString(balance), maximumFractionDigits)
    ).toString();
}

export function exchangeRateNumberFormatter(balance: BalanceNumber) {
    const value = numberToString(balance);
    // if number is 0.0xxxx then we display more characters after 0
    if (value.startsWith('0.')) {
        return numberFormatter(balance, 6);
    }

    return numberFormatter(balance, 4);
}

// format fee
export function feeFormatter(balance: BalanceNumber) {
    return numberFormatter(balance, 8);
}

// format fee with currency
export function feeWithCurrency(balance: BalanceNumber, currency = 'BNB') {
    return `${feeFormatter(numberToString(balance))} ${currency}`;
}

// format balance in K, M notations
export function balanceShortFormatter(balance: BalanceNumber) {
    const num = Number(numberToString(balance));

    if (num >= 1000000) {
        return `${Number((num / 1000000).toFixed(2))}M`; // convert to M for number from > 1 million
    }

    if (num >= 1000 && num < 1000000) {
        return `${Number((num / 1000).toFixed(2))}K`; // convert to K for number from > 1000 < 1 million
    }

    if (num < 1000 && num >= 10) {
        return Math.floor(num).toString();
    }

    if (num < 10) {
        return (Math.trunc(num * 100) / 100).toString();
    }

    return '';
}

export function balanceShortFormatterFromWei(wei: BalanceNumber) {
    return balanceShortFormatter(Number(balanceFromWei(String(wei)).value));
}

export function roundDownSignificantDigits(
    balance: BalanceNumber,
    decimals: number
) {
    const number = Number(numberToString(balance));

    const significantDigits =
        parseInt(number.toExponential().split('e-')[1], 10) || 0;

    const decimalsUpdated = (decimals || 0) + significantDigits;
    const finalDecimals = Math.min(decimalsUpdated, number.toString().length);

    const result =
        Math.floor(number * 10 ** finalDecimals) / 10 ** finalDecimals;

    return roundAfterComma(result, decimals);
}

export function roundAfterComma(
    value: BalanceNumber,
    digitsAfterComma: number
) {
    const numberValue = Number(numberToString(value));
    const exponent = 10 ** digitsAfterComma;

    return parseFloat(
        (Math.floor(numberValue * exponent) / exponent).toFixed(
            digitsAfterComma
        )
    );
}

export function truncateEthAddress(address: string | null) {
    if (!address) {
        return '';
    }
    const match = address.match(
        /^(0x[a-zA-Z0-9]{7})[a-zA-Z0-9]+([a-zA-Z0-9]{9})$/
    );
    if (!match) return address;
    return `${match[1]}…${match[2]}`;
}

export async function getGasPrice(
    wallet: Wallet,
    config: GasPriceConfig
): Promise<string> {
    try {
        const providerGasPrice = await wallet.provider.getGasPrice();

        const multiplier = ethers.utils.parseUnits(
            config.gasPriceMiltiplier,
            'ether'
        );

        const adjustedGasPrice = providerGasPrice
            .mul(multiplier)
            .div(ethers.utils.parseUnits('1', 'ether'));

        return adjustedGasPrice.toString();
    } catch (error) {
        Console.error('Can not estimate gas price', error);

        return config.defaultGasPriceGwei;
    }
}
