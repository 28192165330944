import { StyleSheet } from 'react-native';
import { height } from 'react-native-size-scaling';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';
import { stylesAlignCenter } from '@helpers/style';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            borderRadius: 1000,
        },
        inside: {
            width: 52,
            height: 52,
            ...stylesAlignCenter(),
            position: 'absolute',
            borderRadius: 1000,
        },
        loading: {
            backgroundColor: Colors[theme].backgroundPrimaryMinimal,
        },
        loaded: {
            backgroundColor: Colors[theme].background,
        },
    });

export default styles;
