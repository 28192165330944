import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import * as Clipboard from 'expo-clipboard';

import { useHaptic } from '../../hooks/useHaptic';
import useThemedStyles from '../../hooks/useThemedStyles';
import i18n from '../../i18n/i18n';
import Icon, { ICON_NAMES } from '../Icons';
import { Text } from '../Themed';
import Tooltip from '../Tooltip';
import stylesMain from './styles';

interface IProps {
    value: string;
    disabled?: boolean;
}

const CopyToClipboardButton: React.FunctionComponent<IProps> = ({
    value,
    disabled,
}) => {
    const styles = useThemedStyles(stylesMain);
    const [copied, setCopied] = React.useState<boolean>(false);
    const { triggerHeavy } = useHaptic();

    const copyToClipboard = async () => {
        if (disabled) {
            return;
        }
        triggerHeavy();
        await Clipboard.setStringAsync(value);
        setCopied(true);
    };

    React.useEffect(() => {
        if (!copied) return;
        const timer = setTimeout(() => {
            setCopied(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, [copied]);

    return (
        <Tooltip
            isVisible={copied}
            text={
                <Text style={styles.contentText}>
                    {i18n.t('general.copiedToClipboard')}
                </Text>
            }
            placement="top"
            onClose={() => {
                setCopied(false);
            }}>
            <TouchableOpacity
                onPress={copyToClipboard}
                style={[styles.icon, disabled ? styles.disabled : {}]}
                disabled={disabled}>
                {copied ? (
                    <Icon name={ICON_NAMES.COPY} size={20} color="green" />
                ) : (
                    <Icon name={ICON_NAMES.COPY} size={20} color="secondary" />
                )}
            </TouchableOpacity>
        </Tooltip>
    );
};

export default CopyToClipboardButton;
