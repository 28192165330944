import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
        },
        listWrapper: {
            paddingHorizontal: scale(20),
            flex: 1,
            paddingBottom: verticalScale(20),
        },
        listBlock: {
            marginTop: verticalScale(20),
            borderWidth: 1,
            borderRadius: moderateScale(12),
            borderColor: Colors[theme].border.primary,
        },
        loading: {
            flex: 1,
        },
        empty: {
            height: '100%',
            marginTop: verticalScale(30),
        },
    });

export default styles;
