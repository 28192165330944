import { StyleSheet } from 'react-native';

import { verticalScale } from '@helpers/dimensions';

import Colors from '../../../../../constants/Colors';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            alignItems: 'center',
        },
        itemContainer: {
            paddingVertical: verticalScale(8),
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 18,
                height: 26,
                weight: '700',
            }),
            marginRight: verticalScale(4),
        },
        itemText: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
            marginLeft: verticalScale(8),
        },
        titleWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        iconWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        priceWrapper: { alignItems: 'flex-end' },
        price: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '600',
            }),
            marginBottom: verticalScale(2),
        },
    });

export default styles;
