import React, { useState } from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';

import mainStyles from './styles';

interface IProps {
    children: React.ReactNode;
    style?: StyleProp<ViewStyle>;
}

const VerticalCentredContent = ({ children, style }: IProps) => {
    const [contentHeight, setContentHeight] = useState(0);
    const [isContentHeightBigger, setIsContentHeightBigger] = useState(false);
    const styles = useThemedStyles(mainStyles);
    return (
        <View
            style={[
                styles.container,
                {
                    justifyContent: isContentHeightBigger
                        ? 'flex-start'
                        : 'center',
                },
                style,
            ]}
            onLayout={({ nativeEvent }) => {
                setIsContentHeightBigger(
                    nativeEvent.layout.height < contentHeight
                );
            }}>
            <View
                style={styles.contentContainer}
                onLayout={({ nativeEvent }) => {
                    setContentHeight(Math.floor(nativeEvent.layout.height));
                }}>
                {children}
            </View>
        </View>
    );
};

export default VerticalCentredContent;
