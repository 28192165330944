import { waitForToast } from '@helpers/helpers';
import { toastError } from '@helpers/toastNotification';
import i18n from '@i18n/i18n';
import {
    MysteryLikesGameHistoryOutput,
    MysteryLikesMyPendingGame,
} from '@models/mysteryGames';

export const getCurrentMysteryLikesGameById = (
    mysteryLikesMyPendingGames: MysteryLikesMyPendingGame[],
    id: string
): MysteryLikesMyPendingGame | undefined => {
    return mysteryLikesMyPendingGames.find((i) => i.id === id);
};

export const toastGameAlreadyStarted = async () => {
    await waitForToast();
    return toastError(
        i18n.t('general.errors.gameAlreadyStarted.text1'),
        i18n.t('general.errors.gameAlreadyStarted.text2'),
        'top',
        12000
    );
};
export const toastBanned = async () => {
    await waitForToast();
    return toastError(
        i18n.t('general.errors.gameBanned.text1'),
        i18n.t('general.errors.gameBanned.text2'),
        'top',
        12000
    );
};

export const calculateRounds = (rounds: MysteryLikesGameHistoryOutput[]) => {
    return rounds.filter((item) => item.correctChoice).length || 0;
};
