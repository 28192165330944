import * as React from 'react';

import ProvablyFairHistoryIcon from '@assets/icons/mysteryGames/QuantumSpin/provablyFairHistory.svg';
import CircleDivider from '@components/CircleDivider';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT } from '@components/TypoText/types';
import { dateFormatterFull } from '@helpers/time';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { QuantumSpinGameProvableFairGameOutput } from '@models/mysteryGames';

import stylesMain from './styles';

interface IProps {
    item: QuantumSpinGameProvableFairGameOutput;
}

const QuantumSpinProbablyFairHistoryItem = ({ item }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { id, hash, date } = item;
    return (
        <View style={[styles.itemContainer]}>
            <View style={styles.left}>
                <View style={styles.imageWrapper}>
                    <ProvablyFairHistoryIcon width={24} height={24} />
                </View>
                <View style={styles.infoBlock}>
                    <View style={styles.top}>
                        <TypoText
                            type={TYPO_TEXT.SUBHEAD_MEDIUM_SEMIBOLD}
                            numberOfLines={1}>
                            {`#${id}`}
                        </TypoText>
                        <CircleDivider />
                        <TypoText
                            type={TYPO_TEXT.SUBHEAD_LITTLE_SEMIBOLD}
                            numberOfLines={1}
                            additionalStyles={styles.flex}>
                            {dateFormatterFull(date)}
                        </TypoText>
                    </View>
                    <View style={styles.bot}>
                        <TypoText type={TYPO_TEXT.BODY_SMALL} numberOfLines={1}>
                            {`${i18n.t('general.KEYS.hash')}: ${hash}`}
                        </TypoText>
                    </View>
                </View>
            </View>
        </View>
    );
};
export default QuantumSpinProbablyFairHistoryItem;
