import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { useQuery } from '@apollo/client';
import ActivityIndicator from '@components/ActivityIndicator';
import Button from '@components/Button';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT } from '@components/TypoText/types';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import {
    MYSTERY_GAMES,
    QuantumSpinGameProvableFairGameOutputResponse,
} from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { GET_QUANTUM_SPIN_LAST_GAMES } from '@requests/quantumSpin';

import QuantumSpinProbablyFairHistoryItem from '../QuantumSpinProbablyFairHistoryItem';
import stylesMain from './styles';

const game = MYSTERY_GAMES.quantumSpin;

interface IProps {
    containerStyles?: StyleProp<ViewStyle>;
}

const QuantumSpinProbablyFairHistory = ({ containerStyles }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const navigation = useNavigation();

    const { data, loading, error } =
        useQuery<QuantumSpinGameProvableFairGameOutputResponse>(
            GET_QUANTUM_SPIN_LAST_GAMES,
            {
                fetchPolicy: 'cache-and-network',
            }
        );

    const onInfoPress = React.useCallback(() => {
        navigation.push(ROUTES.QUANTUM_SPIN_PROBABLY_FAIR_HISTORY, {
            history: data?.quantumSpinGamesLastGames,
        });
    }, [data?.quantumSpinGamesLastGames]);

    if (error || !data?.quantumSpinGamesLastGames.length) {
        return null;
    }

    return (
        <View style={[styles.container, containerStyles]}>
            <View style={styles.titleBlock}>
                <View style={styles.title}>
                    <TypoText
                        type={TYPO_TEXT.HEADING_H6}
                        additionalStyles={styles.gap}>
                        {i18n.t('general.KEYS.gamesHistory')}
                    </TypoText>
                </View>
                <Button
                    title={i18n.t('general.BUTTONS.viewAll')}
                    type="text"
                    debouncedPress
                    onPress={onInfoPress}
                />
            </View>
            <View style={styles.history}>
                {loading ? (
                    <ActivityIndicator
                        loading={loading}
                        containerStyle={styles.flex}
                    />
                ) : (
                    [...data.quantumSpinGamesLastGames]
                        .slice(0, 3)
                        .map((i) => (
                            <QuantumSpinProbablyFairHistoryItem
                                key={i.id}
                                item={i}
                            />
                        ))
                )}
            </View>
        </View>
    );
};

export default QuantumSpinProbablyFairHistory;
