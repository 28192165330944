import React, {
    FC,
    PropsWithChildren,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { isEqual } from 'lodash';

import { MeResponse, User } from '@Data/Models';
import { ME } from '@Data/Requests';
import { useApolloClient } from '@apollo/client';
import { dateDiffInMinutes } from '@helpers/time';
import { useAnalytics } from '@hooks/useAnalytics';

import ErrorReporting from '../utils/ErrorReporting';
import { useApollo } from './IguApolloContext';
import { SessionContext, useSession } from './SessionContext';

type UserContextType = {
    isLoading: boolean;
    isLoaded: boolean;
    userLoading: boolean;
    userUpdated: boolean;
    petsCount: number;
    user: User | undefined;
    hasRole: boolean;
    isPlayer: boolean;
    isModerator: boolean;
    isAdmin: boolean;
    isLeader: boolean;
    isAdvancedLeader: boolean;
    isCharityOrganization: boolean;
    setUser: (user: User) => void;
    reloadUser: () => void;
    logout: () => void;
    isNewUser: boolean;
};

export const UserContext = createContext<UserContextType>({
    isLoading: false,
    isLoaded: false,
    userLoading: false,
    userUpdated: false,
    petsCount: 0,
    user: undefined,
    hasRole: false,
    isPlayer: false,
    isModerator: false,
    isAdmin: false,
    isLeader: false,
    isAdvancedLeader: false,
    isCharityOrganization: false,
    setUser: () => undefined,
    reloadUser: () => undefined,
    logout: () => undefined,
    isNewUser: false,
});

const config = {
    delayForNewUser: 240,
};

export const useUser = () => React.useContext(UserContext);

const UserProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const { setSessionToken } = useSession();
    const { setUser: setUserAnalytics } = useAnalytics();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [userLoading, setUserLoading] = useState<boolean>(false);
    const [userUpdated, setUserUpdated] = useState<boolean>(false);
    const { sessionToken, isLoaded: isSessionLoaded } =
        useContext(SessionContext);
    const [user, setUser] = useState<User | undefined>();
    const [petsCount, setPetsCount] = useState<number>(0);
    const client = useApolloClient();
    const { isFullMaintenance } = useApollo();

    const isPlayer = useMemo(() => user?.role === 'PLAYER', [user]);
    const isAdmin = useMemo(() => user?.role === 'ADMIN', [user]);
    const isModerator = useMemo(() => user?.role === 'MODERATOR', [user]);
    const isCharityOrganization = useMemo(
        () => user?.role === 'CHARITY',
        [user]
    );
    const hasRole = useMemo(
        () => isModerator || isAdmin,
        [isAdmin, isModerator]
    );
    const isAdvancedLeader = useMemo(
        () => user?.role === 'ADVANCED_LEADER',
        [user]
    );
    const isLeader = useMemo(
        () => user?.role === 'LEADER' || isAdvancedLeader,
        [user, isAdvancedLeader]
    );

    const isNewUser = React.useMemo(() => {
        if (user) {
            return (
                dateDiffInMinutes(new Date(), user.createdAt) <
                config.delayForNewUser
            );
        }
        return false;
    }, [user]);

    const reloadUser = useCallback(async () => {
        setUserLoading(true);
        setUserUpdated(false);
        try {
            Console.log('[UserContext] Reloading user..');

            const result = await client.query<MeResponse>({
                query: ME,
                fetchPolicy: 'network-only',
            });

            if (result.data) {
                ErrorReporting.setUser({
                    email: result.data.me.email,
                    id: result.data.me.id,
                });
                setUser((prevState) => {
                    if (!isEqual(result.data.me, prevState)) {
                        Console.info('[UserContext] User updated');
                        setUserUpdated(true);
                        return result.data.me;
                    }
                    Console.info('[UserContext] User stale');
                    return prevState;
                });
                setPetsCount(result.data.petsCount);
            }
        } catch (error: any) {
            Console.log('[UserContext] Reload error', error);
            ErrorReporting.report(error);
        } finally {
            setUserLoading(false);
        }
    }, [client]);

    const logout = async () => {
        setSessionToken(null);
        await client.clearStore();
    };

    useEffect(() => {
        async function loadUser() {
            if (!isSessionLoaded) {
                return;
            }

            setIsLoading(true);

            if (sessionToken) {
                Console.log('[USER] Fetching user');
                await reloadUser();
            } else {
                Console.log('[USER] Load empty user as sessionToken is empty');
                setUser(undefined);
                setPetsCount(0);
            }

            setIsLoading(false);
            setIsLoaded(true);
        }

        loadUser();
    }, [sessionToken, isSessionLoaded, reloadUser, isFullMaintenance]);

    useEffect(() => {
        if (user?.id) {
            setUserAnalytics(user?.id);
        }
    }, [setUserAnalytics, user?.id]);

    return (
        <UserContext.Provider
            value={{
                isLoading,
                isLoaded,
                userLoading,
                userUpdated,
                petsCount,
                user,
                hasRole,
                isPlayer,
                isModerator,
                isAdmin,
                isCharityOrganization,
                setUser,
                reloadUser,
                logout,
                isLeader,
                isAdvancedLeader,
                isNewUser,
            }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;
