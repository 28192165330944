import { StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import { scale, verticalScale } from '../../../../helpers/dimensions';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        buttonsContainer: {
            position: 'absolute',
            backgroundColor: 'transparent',
            right: scale(16),
            top: verticalScale(4),
        },
        button: {
            backgroundColor: 'transparent',
            marginTop: verticalScale(12),
        },
        binIcon: {
            color: Colors[theme].icon.warning,
        },
    });

export default styles;
