import React from 'react';
import { Text, View } from 'react-native';

import { useRanks } from '@contexts/RanksContext';

import { Pet } from '../../../../../Data/Models';
import Divider from '../../../../../components/Divider';
import RankIcon from '../../../../../components/Icons/RankIcon';
import PetCollectionListItem from '../../../../../components/PetCollectionListItem';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import Styles from './styles';

interface IProps {
    pet: Pet;
    rankUp?: boolean;
}

const TransactionDetailsRankUp = ({ pet, rankUp }: IProps) => {
    const styles = useThemedStyles(Styles);
    const { nextRankBySlug } = useRanks();
    const nextRank = nextRankBySlug(pet.rank);

    return (
        <>
            {!!nextRank ? (
                <>
                    <View style={styles.container}>
                        <View style={styles.icon}>
                            <RankIcon size={64} slug={nextRank.slug} />
                        </View>
                        <View style={styles.right}>
                            <Text style={styles.title}>
                                {i18n.t('ranks.checkoutUpgradeRank.upgradeTo')}
                            </Text>

                            <Text style={styles.text}>
                                {i18n.t('ranks.rankLongTitle', {
                                    rank: i18n.t(
                                        `ranks.list.${nextRank.slug}.title`
                                    ),
                                })}
                            </Text>
                        </View>
                    </View>
                    <Divider customStyles={styles.divider} />
                    <PetCollectionListItem
                        fullViewLvl={!rankUp}
                        style={styles.petContainer}
                        nextRank={rankUp ? nextRank : undefined}
                        pet={pet}
                    />
                </>
            ) : null}
        </>
    );
};
export default TransactionDetailsRankUp;
