import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        item: {
            flex: 1,
            alignContent: 'center',
            alignItems: 'center',
        },
        image: {
            width: scale(100),
            height: scale(100),
            borderRadius: moderateScale(8),
        },
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
            marginTop: verticalScale(12),
            textAlign: 'center',
        },
        text: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
            marginTop: verticalScale(2),
            textAlign: 'center',
        },

        win: {
            color: Colors[theme].text.accent,
        },
    });

export default styles;
