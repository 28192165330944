import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { isWeb } from '@helpers/app';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        gradientBackground: {
            borderRadius: moderateScale(12),
            paddingVertical: verticalScale(16),
            paddingHorizontal: scale(16),
        },
        texts: {
            backgroundColor: 'transparent',
            marginBottom: verticalScale(16),
        },
        title: {
            ...font({
                size: 18,
                height: 24,
                weight: '700',
            }),

            color: Colors[theme].text.onLight,
        },
        text: {
            marginTop: verticalScale(4),
            ...font({
                size: 14,
                height: 21,
                weight: '400',
            }),
            letterSpacing: 0.08,
            color: Colors[theme].text.onLight,
            width: '70%',
        },
        button: {
            zIndex: 1,
            alignSelf: 'flex-start',
        },
        referralCodeImage: {
            position: 'absolute',
            bottom: 0,
            right: verticalScale(8),
        },
    });

export default styles;
