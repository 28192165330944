import { StyleSheet } from 'react-native';

import font from '@helpers/font';

import Colors from '../../../constants/Colors';
import { isWeb } from '../../../helpers/app';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../helpers/dimensions';
import { stylesModalNavigationFix } from '../../../helpers/style';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,

            ...stylesModalNavigationFix(),
            paddingHorizontal: scale(20),
        },
        container: {
            marginBottom: verticalScale(36),
        },
        video: {
            marginTop: verticalScale(20),
            width: '100%',
            overflow: 'hidden',
            borderRadius: moderateScale(8),
        },
        borderRadius: {
            borderRadius: moderateScale(8),
            borderWidth: 0,
        },
        guideWrapper: {
            marginTop: verticalScale(20),
        },
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 18,
                height: 24,
                weight: '700',
            }),
        },
        text: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 21,
                weight: '400',
            }),
            letterSpacing: 0.08,
        },
        bold: {
            ...font({
                size: 14,
                height: 21,
                weight: '700',
            }),
        },
        guideBlock: {
            marginTop: verticalScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
            paddingHorizontal: scale(20),
            paddingBottom: verticalScale(16),
            paddingTop: verticalScale(8),
        },
        guide: {
            marginTop: verticalScale(8),
            flexDirection: 'row',
            alignItems: 'center',
        },
        circle: {
            top: verticalScale(8),
            alignSelf: 'flex-start',
            marginRight: scale(6),
            width: 6,
            height: 6,
            borderRadius: 1000,
            backgroundColor: Colors[theme].circle,
        },
        walletAddressContainer: {
            marginTop: verticalScale(8),
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(8),
        },
        walletAddressText: {
            ...font({
                size: 14,
                height: 21,
                weight: '400',
            }),
            flex: 1,
            letterSpacing: 0.08,
        },
        resourceWrapper: {
            marginTop: verticalScale(20),
        },
        resourceBlock: {
            marginTop: verticalScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
            paddingHorizontal: scale(20),
            paddingBottom: verticalScale(16),
        },
        iconGreen: {
            color: Colors[theme].icon.green,
        },
        resource: {
            marginTop: verticalScale(16),
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        right: {
            flexDirection: 'row',
            flex: 1,
        },
        resourceText: {
            flex: 1,
            color: Colors[theme].text.accent,
            ...font({
                size: 15,
                height: 23,
                weight: '600',
            }),
        },
        img: {
            marginRight: scale(6),
        },
        linkImg: {
            marginLeft: scale(16),
        },
    });

export default styles;
