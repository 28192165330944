import * as React from 'react';

import { IguWalletTransaction } from '@models/gamingWallet';

import { MemoizedGamingWalletTransactionHistoryItem } from '../GamingWalletTransactionHistoryItem';

interface IProps {
    lastPendingId: string | null | undefined;
    item: IguWalletTransaction;
    loading: boolean;
}

const GamingTransactionItemHandler = ({
    lastPendingId,
    item,
    loading,
}: IProps) => {
    if (loading) {
        return <></>;
    }
    return (
        <MemoizedGamingWalletTransactionHistoryItem
            item={item}
            lastPendingClaimId={lastPendingId}
        />
    );
};
export default GamingTransactionItemHandler;
