import { ServerParseError } from '@apollo/client';
import { NetworkError } from '@apollo/client/errors';

import ErrorReporting from '../utils/ErrorReporting';
import { toastErrorUnknown } from './toastNotification';

export const errorsHandler = (error: any, withToast?: boolean) => {
    Console.error(error);
    ErrorReporting.report(error);
    if (withToast) toastErrorUnknown();
};

export const isNetworkServerError = (error: NetworkError) =>
    (error as ServerParseError).response !== undefined;
