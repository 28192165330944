import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            marginTop: verticalScale(32),
        },
        header: {
            marginHorizontal: scale(20),
        },
        timerContainer: {
            marginTop: verticalScale(20),
            marginHorizontal: scale(20),
            alignItems: 'center',
            paddingHorizontal: scale(20),
            paddingVertical: verticalScale(20),
            borderColor: Colors[theme].border.primary,
            borderWidth: 1,
            borderRadius: moderateScale(12),
        },

        title: {
            textAlign: 'center',
            color: Colors[theme].text.primary,
            ...font({
                size: 18,
                height: 24,
                weight: '700',
            }),
            marginBottom: verticalScale(6),
        },
        text: {
            textAlign: 'center',
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 21,
                weight: '400',
            }),
            letterSpacing: 0.08,
            marginBottom: verticalScale(16),
        },
        pvpBonus: {
            marginTop: verticalScale(20),
        },
        feature: {
            marginTop: verticalScale(12),
            marginHorizontal: scale(20),
        },
        accent: {
            color: Colors[theme].text.accent,
        },
    });

export default styles;
