import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import Icon, { ICON_NAMES } from '../../../../components/Icons';
import FlagIcons from '../../../../components/Icons/FlagIcons';
import { Text, View } from '../../../../components/Themed';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import stylesMain from './styles';

interface IProps {
    id: string;
    onPress: (id: string) => void;
    isSelected: boolean;
}

const LangItem = ({ id, onPress, isSelected }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <TouchableOpacity
            style={[styles.shadow, isSelected && styles.shadowActive]}
            onPress={() => onPress(id)}
            activeOpacity={0.8}>
            <View
                style={[
                    styles.itemContainer,
                    isSelected && styles.itemContainerActive,
                ]}>
                <View style={styles.right}>
                    <View style={[styles.radio, isSelected && styles.active]}>
                        {isSelected && (
                            <Icon name={ICON_NAMES.CHECK} size={12} />
                        )}
                    </View>
                    <View style={styles.icon}>
                        <FlagIcons slug={id} size={24} />
                    </View>
                    <Text style={styles.title}>
                        {i18n.t(`profile.languageSettings.options.${id}.name`)}
                    </Text>
                </View>
            </View>
        </TouchableOpacity>
    );
};

export default LangItem;
