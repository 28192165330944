import * as React from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';

import useThemedStyles from '../../hooks/useThemedStyles';
import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    title: string;
    text: string;
    icon?: React.ReactElement<SvgProps>;
    containerStyle?: StyleProp<ViewStyle>;
}

const CardBaseInfo = ({
    title,
    text,
    icon,

    containerStyle,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <TouchableOpacity
            style={[styles.infoItem, containerStyle]}
            activeOpacity={1}>
            <View style={styles.top}>
                {!!icon && icon}
                <View style={styles.left}>
                    <Text style={styles.infoTitle}>{title}</Text>
                    <Text style={styles.infoText}>{text}</Text>
                </View>
            </View>
        </TouchableOpacity>
    );
};

export default CardBaseInfo;
