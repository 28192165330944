import { StyleSheet } from 'react-native';

import { scale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const stylesMain = (theme: theme) =>
    StyleSheet.create({
        container: {
            position: 'absolute',
            left: scale(20),
        },
    });

export default stylesMain;
