import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '@helpers/dimensions';

import Colors from '../../../../constants/Colors';
import font from '../../../../helpers/font';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        navBg: {
            backgroundColor: Colors[theme].navigationBar.elementsBackground,
        },
        container: {
            flex: 1,

            backgroundColor: Colors[theme].background,
        },
        top: {
            paddingHorizontal: scale(20),
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
        },
        energyIcon: {
            backgroundColor: Colors[theme].backgroundWarning,
            paddingHorizontal: 8,
            paddingVertical: 8,
            borderRadius: 1000,
            marginTop: verticalScale(8),
        },
        energyCollectedWrapper: {
            marginTop: verticalScale(12),
        },
        energyCollectedText: {
            color: Colors[theme].text.warning,
            ...font({
                size: 22,
                height: 30,
                weight: '700',
            }),
        },
        text: {
            textAlign: 'center',
            marginTop: verticalScale(6),
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
            letterSpacing: 0.08,
        },

        viewRewardWrapper: {
            marginTop: verticalScale(12),
            flexDirection: 'row',
            alignItems: 'center',
        },
        viewRewardText: {
            color: Colors[theme].text.accent,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
            letterSpacing: 0.08,
            marginRight: scale(4),
        },

        detailsWrapper: {
            marginTop: verticalScale(24),
            borderTopColor: Colors[theme].border.primary,
            borderTopWidth: 1,
            paddingTop: verticalScale(24),
            paddingHorizontal: scale(20),
        },
        detailsName: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
            letterSpacing: 0.08,
        },
        detailsInfo: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '600',
            }),
        },

        detailsIcon: {
            marginLeft: scale(6),
        },
        detailsInfoAccent: {
            color: Colors[theme].text.accent,
        },
        detailsInfoWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'center',
        },
        singleDetail: {
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
            marginBottom: verticalScale(16),
        },
        icon: {
            color: Colors[theme].icon.green,
        },
    });

export default styles;
