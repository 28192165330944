import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';
import { stylesModalNavigationFix } from '@helpers/style';

import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            ...stylesModalNavigationFix(),
            flex: 1,
        },
        safeArea: {
            flex: 1,
        },
        content: {
            flex: 1,
            paddingHorizontal: scale(20),
            marginVertical: verticalScale(16),
        },
        title: {
            paddingHorizontal: scale(16),
        },
        imgWrapper: {
            alignSelf: 'center',
            marginBottom: verticalScale(24),
        },
        energyTimeWrapper: {
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            borderRadius: moderateScale(12),
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            marginBottom: verticalScale(16),
            marginTop: verticalScale(20),
        },
        headerText: {
            paddingHorizontal: 0,
            marginBottom: verticalScale(20),
        },
        timeToComplete: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
        },
        timer: {
            color: Colors[theme].text.primary,
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
        },
        timerWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: verticalScale(16),
        },
        gap: { marginBottom: verticalScale(12) },
    });

export default styles;
