import * as React from 'react';

import Input from '@components/Input';
import { Text } from '@components/Themed';
import Colors from '@constants/Colors';
import { REGEX_PATTERNS } from '@constants/Regex';
import { MintContext } from '@contexts/MintContext';
import { useTheme } from '@contexts/ThemeContext';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

interface IProps {
    // value: string;
    setError: (status: string | undefined) => void;
    isNameError: string | undefined;
    name: string | null;
    setName: (name: string) => void;
    disabled?: boolean;
}

const PetName = ({
    name,
    setName,
    isNameError,
    setError,
    disabled,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { theme } = useTheme();

    const handleChangeName = (text: string) => {
        if (text.length > 20) {
            setError(i18n.t(`mintNamePhoto.errors.maxLength`));
            setName(name || '');
            return;
        }
        if (text.match(REGEX_PATTERNS.petName.startSpace)) {
            setError(i18n.t(`mintNamePhoto.errors.startSpace`));
            setName(name || '');
            return;
        }
        if (text.match(REGEX_PATTERNS.petName.doubleSpaces)) {
            setError(i18n.t(`mintNamePhoto.errors.doubleSpaces`));
            setName(name || '');
            return;
        }
        if (text.match(REGEX_PATTERNS.petName.numbers)) {
            setError(i18n.t(`mintNamePhoto.errors.numbers`));
            setName(name || '');
            return;
        }
        if (text.match(REGEX_PATTERNS.petName.specialCharacters)) {
            setError(i18n.t(`mintNamePhoto.errors.specialCharacters`));
            setName(name || '');
            return;
        }
        if (
            text.length &&
            !text.match(REGEX_PATTERNS.petName.allowedCharacter)
        ) {
            setError(i18n.t(`mintNamePhoto.errors.latinCharacters`));
            setName(name || '');
            return;
        }

        setError(undefined);
        setName(text);

        // Add min length validation
        if (text.length < 2) {
            setError(i18n.t(`mintNamePhoto.errors.minLength`));
        }
    };

    return (
        <>
            <Text style={styles.label}></Text>
            <Input
                label={i18n.t('mintNamePhoto.inputLabel')}
                autoCapitalize="words"
                style={styles.input}
                value={name || ''}
                onChangeText={handleChangeName}
                placeholder={i18n.t('mintNamePhoto.placeHolder')}
                placeholderTextColor={Colors[theme].text.secondary}
                maxLength={20}
                error={isNameError}
                disabled={disabled}
            />
        </>
    );
};
export default PetName;
