import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { scale, verticalScale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
            backgroundColor: Colors[theme].background,
        },
        headerContainer: { paddingHorizontal: scale(20) },
        page: {
            paddingTop: scale(16),
            flex: 1,
        },
        buttonLogOut: {
            marginVertical: verticalScale(20),
            paddingHorizontal: scale(20),
        },
        userContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: scale(20),
            overflow: 'hidden',
        },
        divider: {
            borderTopWidth: 0.5,
            borderTopColor: Colors[theme].divider,
            marginTop: verticalScale(20),
            marginBottom: verticalScale(12),
        },
        userWrapper: {
            marginLeft: verticalScale(12),
        },
        email: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '600',
            }),
        },
        deleteAccountContainer: {
            alignSelf: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: verticalScale(50),
        },
        deleteAccountText: {
            color: Colors[theme].text.critical,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
            marginLeft: verticalScale(8),
        },
        confirmationText: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
        },
        confirmationPhrase: {
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 22,
                weight: '600',
            }),
        },
        input: {
            marginVertical: verticalScale(24),
        },
        subtitle: {
            color: Colors[theme].text.primary,
            ...font({
                size: 18,
                height: 24,
                weight: '700',
            }),
        },
        description: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
        },
        textContainer: {
            paddingHorizontal: scale(20),
            paddingBottom: scale(20),
        },
        itemTitle: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '600',
            }),
        },
        avatar: {
            width: 56,
        },
    });

export default styles;
