import * as React from 'react';

import TransactionSummary from '@components/TransactionSummary';
import { ITransactionResult } from '@contexts/Wallet/WalletContext';

interface IProps {
    values: {
        key: string;
        value: any;
        divider?: boolean;
        icon?: React.ReactChild;
    }[];
    transactionResult?: ITransactionResult;
    estimatedFee?: string;
}

const GamingWalletCheckoutSummary = ({ values }: IProps) => {
    return <TransactionSummary values={values} />;
};
export default GamingWalletCheckoutSummary;
