import React, { useState } from 'react';

import CustomModal from '../components/CustomModal';
import ImageIguana from '../components/ImageIguana';
import i18n from '../i18n/i18n';

export declare type WalletErrorType =
    | 'INSUFFICIENT_FUNDS'
    | 'UNPREDICTABLE_GAS_LIMIT';

const useBlockchainErrorsHandler = (
    onModalOpen?: () => void,
    onModalClose?: () => void
) => {
    const [isBlockchainError, setIsBlockchainError] = useState<boolean>(false);
    const [blockchainError, setBlockchainError] = useState<
        WalletErrorType | any
    >();

    const isTypedError = (error: any) => {
        return ['INSUFFICIENT_FUNDS', 'UNPREDICTABLE_GAS_LIMIT'].includes(
            error?.code
        );
    };

    const handleCloseBlockchainErrorModal = () => {
        if (onModalClose) onModalClose();
        setIsBlockchainError(false);
    };

    const handleBlockchainErrors = (error: any, catchAll = false) => {
        if (isTypedError(error) || catchAll) {
            if (onModalOpen) {
                onModalOpen();
            }
            setIsBlockchainError(true);

            if (isTypedError(error)) {
                setBlockchainError(error);
            } else {
                setBlockchainError(error.toString());
            }

            return true;
        }

        return false;
    };

    const renderBlockchainErrorsModal = React.useCallback(() => {
        const errorDefined = isTypedError(blockchainError);

        return (
            <CustomModal
                isVisible={isBlockchainError}
                icon={<ImageIguana type="redX" />}
                titleText={i18n.t(
                    errorDefined
                        ? `general.errors.${blockchainError?.code}.title`
                        : 'general.errors.error.title'
                )}
                infoText={
                    errorDefined
                        ? i18n.t(
                              `general.errors.${blockchainError?.code}.message`
                          )
                        : blockchainError
                }
                firstButtonText={i18n.t('checkout.try')}
                secondButtonText={i18n.t('checkout.cancel')}
                onFirstButtonClick={handleCloseBlockchainErrorModal}
                onSecondButtonClick={handleCloseBlockchainErrorModal}
            />
        );
    }, [isBlockchainError, blockchainError]);

    return { handleBlockchainErrors, renderBlockchainErrorsModal };
};

export default useBlockchainErrorsHandler;
