import React, { useContext, useEffect } from 'react';
import { FlatList, Image, TouchableOpacity } from 'react-native';

import { useCharity } from '@contexts/CharityContexts';

import { Charity } from '../../../../Data/Models';
import Button from '../../../../components/Button';
import CurrentLabel from '../../../../components/CurrentLabel';
import { ICON_NAMES } from '../../../../components/Icons';
import ModalLoader from '../../../../components/ModalLoader';
import { Text, View } from '../../../../components/Themed';
import { UserContext } from '../../../../contexts/UserContext';
import useSetCharity from '../../../../hooks/useSetCharity';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import NavigationBarModal from '../../../../navigation/NavigationBar/NavigationBarModal';
import useNavigationListeners from '../../../../navigation/hooks/useNavigationListeners';
import ROUTES from '../../../../navigation/routes';
import { useNavigation } from '../../../../navigation/useNavigation';
import stylesMain from './styles';

const CharityEditSelectedOrganizationScreen = () => {
    useNavigationListeners();
    const { charities } = useCharity();
    const navigation = useNavigation();
    const styles = useThemedStyles(stylesMain);
    const [selectedCharity, setSelectedCharity] = React.useState<Charity>();
    const [isDisabled, setIsDisabled] = React.useState(false);
    const { user } = useContext(UserContext);
    const { saveOrganization, isSetCharityLoading } = useSetCharity();

    useEffect(() => {
        if (user) {
            setSelectedCharity(user.charity);
        }
    }, [user]);

    const onSavePress = () => {
        if (!selectedCharity) {
            setIsDisabled(true);
            return;
        }
        saveOrganization({
            charityId: selectedCharity.id,
        });
    };

    useEffect(() => {
        if (selectedCharity) {
            setIsDisabled(false);
        }
    }, [selectedCharity]);

    const renderOrganization = (organization: Charity) => (
        <TouchableOpacity
            activeOpacity={0.8}
            style={styles.organizationContainer}
            onPress={() => setSelectedCharity(organization)}>
            <View
                style={[
                    styles.borderOrganizationContainer,
                    organization.id === selectedCharity?.id &&
                        styles.selectedOrganizationContainer,
                ]}>
                <View style={styles.organizationTitleContainer}>
                    <Image
                        source={{ uri: organization.logoUrl }}
                        style={styles.organizationImage}
                    />
                    <Text style={styles.organizationTitle}>
                        {organization.name}
                    </Text>
                </View>
                <View
                    style={[
                        styles.buttonContainer,
                        organization.id === selectedCharity?.id &&
                            styles.selectedOrganizationContainer,
                    ]}>
                    {user?.charity?.id === organization.id && <CurrentLabel />}
                    <Button
                        containerStyle={styles.buttonCharity}
                        onPress={() =>
                            navigation.navigate(
                                ROUTES.CHARITY_EDIT_DETAILED_ORGANIZATION,
                                { charity: organization.id }
                            )
                        }
                        type={'outline'}
                        size={'md'}
                        iconName={ICON_NAMES.ARROW_RIGHT}
                        iconSize={16}
                        iconColor={styles.icon.color}
                    />
                </View>
            </View>
            <View
                style={[
                    styles.shadow,
                    organization.id === selectedCharity?.id &&
                        styles.selectedShadow,
                ]}
            />
        </TouchableOpacity>
    );

    const renderUsersDonations = () => (
        <FlatList
            pagingEnabled={false}
            initialNumToRender={5}
            contentContainerStyle={[styles.paddingBottom]}
            data={charities}
            showsVerticalScrollIndicator={false}
            keyExtractor={(item) => item.id}
            renderItem={({ item }) => renderOrganization(item)}
        />
    );
    return (
        <View style={[styles.container, styles.contentContainer]}>
            <NavigationBarModal
                iconName={ICON_NAMES.CLOSE}
                title={i18n.t('charity.editOrganization')}
            />
            <Text style={styles.selectDescription}>
                {i18n.t('charity.selectDescription')}
            </Text>
            {renderUsersDonations()}
            <View style={styles.buttonSaveContainer}>
                {isDisabled && (
                    <Text style={styles.failedText}>
                        {i18n.t('charity.editOrganizationNotChosen')}
                    </Text>
                )}
                <Button
                    disabled={isDisabled}
                    title={i18n.t('charity.buttonSave')}
                    onPress={onSavePress}
                />
            </View>
            <ModalLoader
                isVisible={isSetCharityLoading}
                text={i18n.t('charity.loadingOrganizationChange')}
            />
        </View>
    );
};

export default CharityEditSelectedOrganizationScreen;
