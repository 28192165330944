import { useState } from 'react';

import { TasksResponse } from '@Data/Models';
import { GET_PlAY_TASKS_CONFIG } from '@Data/Requests';
import { useQuery } from '@apollo/client';

const useGetPlayTasks = () => {
    const [tasksConfigLoading, setTasksConfigLoading] = useState(false);

    const { data: tasksConfig, refetch } = useQuery<TasksResponse>(
        GET_PlAY_TASKS_CONFIG
    );

    async function refetchPlayTasks(silent: boolean) {
        if (!silent) setTasksConfigLoading(true);
        await refetch();
        if (!silent) setTasksConfigLoading(false);
    }

    return {
        tasksConfig,
        tasksConfigLoading,
        refetchPlayTasks,
    };
};

export default useGetPlayTasks;
