import * as React from 'react';

import { LinearGradient } from 'expo-linear-gradient';

import ReferralCodeBanner from '@assets/icons/referrals/referralCode.svg';
import Button from '@components/Button';
import { Text, View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import stylesMain from './styles';

const percent = 5;

interface IProps {
    getHeight: (height: number) => void;
    itemWidth: number;
    minHeight: number;
}

const ReferralBanner = ({ getHeight, itemWidth, minHeight }: IProps) => {
    const navigation = useNavigation();
    const styles = useThemedStyles(stylesMain);

    const onPress = () => {
        navigation.navigate(ROUTES.REFERRALS_INVITE);
    };

    return (
        <LinearGradient
            colors={['#5CA3D6', '#E7FCE5']}
            onLayout={({ nativeEvent }) => {
                getHeight?.(nativeEvent.layout.height);
            }}
            style={[styles.gradientBackground, { width: itemWidth, minHeight }]}
            start={{ x: 0, y: 0 }}
            end={{ x: 1.2, y: 1 }}>
            <View style={styles.texts}>
                <Text style={styles.title}>
                    {i18n.t('HomeScreen.ReferralCard.title', {
                        percent,
                    })}
                </Text>
                <Text style={styles.text} numberOfLines={3}>
                    {i18n.t('HomeScreen.ReferralCard.text')}
                </Text>
            </View>
            <Button
                title={i18n.t('HomeScreen.ReferralCard.button')}
                type="outline"
                size="md"
                onPress={onPress}
                containerStyle={styles.button}
                debouncedPress
                isOneColor
            />
            <ReferralCodeBanner
                height={118}
                width={100}
                style={styles.referralCodeImage}
            />
        </LinearGradient>
    );
};
export default ReferralBanner;
