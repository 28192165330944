import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import useThemedStyles from '../../hooks/useThemedStyles';
import { View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    customStyles?: StyleProp<ViewStyle>;
}

const CircleDivider = ({ customStyles }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return <View style={[styles.divider, customStyles]} />;
};
export default CircleDivider;
