import { StyleSheet } from 'react-native';

import Colors from '../../../../constants/Colors';
import { scale, verticalScale } from '../../../../helpers/dimensions';
import font from '../../../../helpers/font';
import { stylesModalNavigationFix } from '../../../../helpers/style';
import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        subtitle: {
            color: Colors[theme].text.primary,
            ...font({
                size: 18,
                height: 24,
                weight: '700',
            }),
        },
        donationsTitleContainer: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingBottom: verticalScale(16),
        },
        divider: {
            borderTopWidth: 0.5,
            borderTopColor: Colors[theme].border.primary,
            marginTop: verticalScale(4),
            marginBottom: verticalScale(16),
        },
        container: {
            flex: 1,
            ...stylesModalNavigationFix(),
        },
        paddingHorizontal: {
            paddingHorizontal: scale(20),
        },
        paddingBottom: {
            paddingBottom: verticalScale(34),
        },
        charityImage: {
            paddingHorizontal: scale(20),
            width: '100%',
            borderRadius: 12,
        },
        buttonSaveContainer: {
            paddingTop: verticalScale(32),
            paddingBottom: verticalScale(32),
        },
    });

export default styles;
