import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import { HealTransactionData } from '@hooks/usePetsHeal';

import useThemedStyles from '../../hooks/useThemedStyles';
import i18n from '../../i18n/i18n';
import ActivityIndicator from '../ActivityIndicator';
import Button from '../Button';
import CacheImage from '../CacheImage';
import CurrentLabel from '../CurrentLabel';
import ExplorerLink from '../ExplorerLink';
import HealthIndicator from '../HealthIndicator';
import { ICON_NAMES } from '../Icons';
import RankIcon from '../Icons/RankIcon';
import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    data: HealTransactionData;
    style?: StyleProp<ViewStyle>;
}

const HealthPetCollectionListItem = ({ data, style }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { pet, state, transactionResult, healthToRestore } = data;
    const { image, name, health } = pet;

    const explorerUrl = transactionResult?.explorerUrl;

    const renderPet = () => {
        return (
            <>
                <View style={styles.imageWrapper}>
                    <CacheImage
                        source={image.thumbnailUrl}
                        style={styles.image}
                        resizeMode="contain"
                    />
                    {state === 'finished' ? (
                        <View style={styles.overlayButton}>
                            <View style={styles.opacityBlock} />
                            <Button
                                iconName={ICON_NAMES.SUCCESS}
                                iconColor={styles.restoreIcon.color}
                                iconSize={24}
                                size="md"
                                type="outline"
                            />
                        </View>
                    ) : state === 'loading' ? (
                        <View style={styles.overlayButton}>
                            <View style={styles.opacityBlock} />
                            <View style={styles.indicator}>
                                <ActivityIndicator loading />
                            </View>
                        </View>
                    ) : state === 'error' ? (
                        <View style={styles.overlayButton}>
                            <View style={styles.opacityBlock} />
                            <Button
                                iconName={ICON_NAMES.ERROR}
                                iconColor={styles.restoreIcon.color}
                                iconSize={24}
                                size="md"
                                type="outline"
                            />
                        </View>
                    ) : (
                        <></>
                    )}
                </View>
                <View style={styles.textWrapper}>
                    <View style={styles.titleWrapper}>
                        <RankIcon slug={pet.rank} size={24} />
                        <Text
                            style={styles.petName}
                            numberOfLines={1}
                            ellipsizeMode="tail">
                            {name}
                        </Text>
                    </View>
                    <HealthIndicator healthPercentage={health} />
                    <View style={styles.itemContainer}>
                        <CurrentLabel
                            containerStyle={styles.healthTag}
                            withIcon
                            text={`+${healthToRestore}`}></CurrentLabel>
                        {explorerUrl && (
                            <ExplorerLink
                                explorerUrl={explorerUrl}
                                showIcon={false}
                                linkStyles={styles.explorerUrl}
                            />
                        )}
                    </View>
                </View>
            </>
        );
    };

    return (
        <>
            <View
                style={[
                    styles.itemContainer,
                    styles.borderPaddings,
                    styles.itemContainerWithBorder,
                    style,
                ]}>
                <View style={styles.leftWrapper}>{renderPet()}</View>
            </View>
            <View style={styles.divider} />
        </>
    );
};
export default HealthPetCollectionListItem;
