import { StyleSheet } from 'react-native';

import { MAX_WINDOW_WIDTH } from '../../../../../contexts/DimensionsContext';
import { scale, verticalScale } from '../../../../../helpers/dimensions';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        modal: {
            padding: 0,
            margin: 0,
        },
        shadow: {
            flex: 1,
            width: '100%',
            backgroundColor: 'transparent',
            maxWidth: MAX_WINDOW_WIDTH,
            alignSelf: 'center',
            paddingHorizontal: scale(16),
        },
        container: {
            backgroundColor: 'transparent',
            flex: 1,
            width: '100%',
            marginHorizontal: scale(40),
            paddingVertical: verticalScale(40),
            alignSelf: 'center',
            alignItems: 'center',
            justifyContent: 'center',
        },
        box: {
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
        },

        stars: {
            backgroundColor: 'transparent',
            position: 'absolute',
        },
        button: {
            paddingHorizontal: 0,
            backgroundColor: 'transparent',
        },
        header: {
            backgroundColor: 'transparent',
            top: verticalScale(20),
        },
    });

export default styles;
