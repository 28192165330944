import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { LUCKY_STRIKE_COLOR } from '@contexts/MysteryGamesContext';
import { scale, verticalScale } from '@helpers/dimensions';
import { stylesModalNavigationFix } from '@helpers/style';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        main: {
            flex: 1,
            ...stylesModalNavigationFix(),
        },
        texts: {
            marginTop: 0,
            marginBottom: verticalScale(16),
        },
        header: {
            paddingHorizontal: scale(20),
            backgroundColor: 'transparent',
        },
        loading: {
            flex: 1,
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'center',
        },
        listWrapper: {
            flex: 1,
            // paddingHorizontal: scale(20),
        },
    });

export default styles;
