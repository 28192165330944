import { StyleSheet } from 'react-native';

import { verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
        },
        card: { width: '31%', marginTop: verticalScale(8) },
    });

export default styles;
