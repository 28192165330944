import { useCallback, useEffect, useMemo, useState } from 'react';
import React from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useLazyQuery, useMutation } from '@apollo/client';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { errorsHandler } from '@helpers/errors';
import { isGraphqlError, wait } from '@helpers/helpers';
import { priceString } from '@helpers/payments';
import {
    balanceFromWei,
    inputNumberFormatter,
    isBiggerThanMax,
} from '@helpers/wallet';
import { useLoadingHook } from '@hooks/useLoadingHook';
import { useTimer } from '@hooks/useTimer';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import {
    LuckyStrikeAvailableGameOutput,
    LuckyStrikeGameHistoryOutputResponse,
} from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import {
    GET_LUCKY_STRIKE_GAME_HISTORY,
    LUCKY_STRIKE_GAME_BUY_TICKET,
} from '@requests/luckyStrike';

import {
    maxParticipants,
    toastGameExpired,
    toastGamePending,
    toastSoldOut,
} from '../../../LuckyStrikeFlow/helpers';

export const useLuckyStrikePurchase = (
    game: LuckyStrikeAvailableGameOutput,
    isVisible: boolean,
    onClose: () => void,
    routeBack: ROUTES
) => {
    const navigation = useNavigation();

    const { luckyStrikeConfig, refetchLuckyStrikeGames } = useMysteryGames();

    const max = useMemo(
        () => luckyStrikeConfig?.maxTicketAmount || 0,
        [luckyStrikeConfig?.maxTicketAmount]
    );
    const min = useMemo(
        () => luckyStrikeConfig?.minTicketAmount || 0,
        [luckyStrikeConfig?.minTicketAmount]
    );

    const boughtTickets = game?.boughtTickets || 0;

    const schema = Yup.object({
        amount: Yup.string()
            .transform((_, value) => {
                if (value && value.includes('.')) {
                    return value;
                }
                return value && value.replace(/,/, '.');
            })

            .matches(
                /^\d*(\.\d+)?$/,
                i18n.t(
                    'wallet.gaming.TopUpGamingWalletScreen.inputSection.errors.digits'
                )
            )
            .required(i18n.t('withdrawScreen.errors.amountRequired'))
            .test(
                'maxAmount',
                i18n.t(
                    'wallet.gaming.TopUpGamingWalletScreen.inputSection.errors.max',
                    {
                        limit: max - boughtTickets,
                        coin: '',
                    }
                ),
                (value) => Number(value) <= Number(max - boughtTickets)
            )
            .test(
                'minAmount',
                i18n.t(
                    'wallet.gaming.TopUpGamingWalletScreen.inputSection.errors.min',
                    {
                        limit: min,
                        coin: '',
                    }
                ),
                (value) => Number(value) >= Number(min)
            )
            .test(
                'integer',
                i18n.t(
                    'wallet.gaming.TopUpGamingWalletScreen.inputSection.errors.digits'
                ),
                (value) => Number.isInteger(Number(value))
            ),
    });

    const {
        submitForm,
        values,
        errors,
        setFieldValue,
        setTouched,
        touched,
        isValid,
        dirty,
        resetForm,
    } = useFormik({
        initialValues: {
            amount: '',
        },
        validationSchema: schema,
        onSubmit: () => openConfirmModal(),
        enableReinitialize: true,
    });

    const handleChangeAmount = async (amount: string) => {
        setTouched({ amount: false });

        const convertedText = inputNumberFormatter(amount);
        if (!isBiggerThanMax(convertedText)) {
            await setFieldValue('amount', convertedText);
        }
    };

    const handleOnBlur = () => {
        setTouched({ amount: true });
    };

    const onSelectCardPress = (amount: number) => {
        setFieldValue('amount', amount.toString());
    };

    const handleOnBack = () => {
        navigation.pop();
    };

    const [currentGame, setCurrentGame] =
        useState<LuckyStrikeAvailableGameOutput>(game);

    useEffect(() => {
        setCurrentGame(game);
    }, [game]);

    const { iguAmountWei, userCount, duration, endDate, texts } = currentGame;

    const maxCount = useMemo(
        () => maxParticipants(luckyStrikeConfig?.gameTypes, duration),
        [luckyStrikeConfig?.gameTypes, duration]
    );

    const participantsText = useMemo(
        () => `${userCount}/${maxCount}`,
        [userCount, maxCount]
    );

    const showButton = useMemo(() => {
        if (!max || !min) {
            return false;
        }
        return Number(values.amount) <= max && Number(values.amount) >= min;
    }, [max, min, values]);

    const price = useMemo(
        () =>
            (Number(balanceFromWei(luckyStrikeConfig?.ticketPriceWei).value) ||
                0) * (Number(values.amount) || 0),
        [luckyStrikeConfig?.ticketPriceWei, values]
    );

    const buttonText = useMemo(() => {
        const amount = priceString(price, Coin.vigu);

        return i18n.t('general.BUTTONS.purchaseFor', {
            amount: amount,
        });
    }, [values, price]);

    const {
        availableBalance,
        getGamingAvailableBalance,
        setTopUpFromMysteryGames,
    } = useMysteryGames();

    useEffect(() => {
        if (isVisible) {
            getGamingAvailableBalance(true);
        } else {
            resetForm();
        }
    }, [isVisible]);

    const enoughBalance = useMemo(
        () =>
            Number(price) <=
            Number(balanceFromWei(availableBalance?.available).value),
        [availableBalance, price]
    );

    const topUp = () => {
        setTopUpFromMysteryGames(true);
        onClose();
        navigation.navigate(ROUTES.WALLET_ROOT, {
            screen: ROUTES.WALLET_GAMING_TAB,
            params: {
                screen: ROUTES.TOP_UP_GAMING_WALLET,
            },
        });
    };

    const {
        isVisible: confirmModal,
        open: openConfirmModal,
        close: closeConfirmModal,
    } = useVisibleHook();

    const [buyTicket] = useMutation(LUCKY_STRIKE_GAME_BUY_TICKET);

    const [isTicketBought, setIsTicketBought] = useState<boolean>(false);

    const {
        isLoading: purchaseLoading,
        startLoading,
        stopLoading,
    } = useLoadingHook();

    const onConfirmPress = useCallback(async () => {
        startLoading();
        try {
            await buyTicket({
                variables: {
                    input: {
                        amount: Number(values.amount),
                        gameId: currentGame.id,
                    },
                },
            });
            await refetchLuckyStrikeGames();
            setIsTicketBought(true);
            stopLoading();
        } catch (error) {
            stopLoading();
            if (isGraphqlError(error, 'LUCKYSTRIKE_MAX_USERS')) {
                toastSoldOut();
                return;
            }
            if (isGraphqlError(error, 'LUCKYSTRIKE_MAX_TICKETS')) {
                toastSoldOut();
                return;
            }
            onClose();
            await toastGameExpired();
            errorsHandler(error);
        }
    }, [values, currentGame, routeBack]);

    const [getHistoryItem] = useLazyQuery<LuckyStrikeGameHistoryOutputResponse>(
        GET_LUCKY_STRIKE_GAME_HISTORY,
        {
            fetchPolicy: 'network-only',
        }
    );

    const navigateToHistory = useCallback(async () => {
        startLoading();
        await wait(1000);
        try {
            const result = await getHistoryItem({
                variables: {
                    gameId: currentGame.id,
                },
            });
            if (result.data?.luckyStrikeGameHistory) {
                onClose();
                navigation.navigate(ROUTES.LUCKY_STRIKE_GAME_HISTORY, {
                    game: result.data.luckyStrikeGameHistory,
                    id: currentGame.id,
                });
            } else {
                onClose();
                toastGamePending();
            }
        } catch (error) {
            onClose();
            await toastGameExpired();
            errorsHandler(error);
        }
        stopLoading();
    }, [currentGame]);

    const handleOnClose = useCallback(() => {
        if (purchaseLoading) {
            return;
        }
        onClose();
    }, [purchaseLoading]);

    const handleTimerEnd = useCallback(async () => {
        if (isVisible && !isTicketBought && !purchaseLoading) {
            onClose();
            await toastGameExpired();
        }
    }, [isVisible, isTicketBought, purchaseLoading]);

    const { timeText, isTimerEnd, isWarningTime } = useTimer({
        endOfTime: endDate,
        onEndTime: handleTimerEnd,
    });

    const time = useMemo(
        () => `${timeText} ${i18n.t('general.KEYS.timeLeft')}`,
        [timeText]
    );

    const onMaxPress = useCallback(() => {
        setFieldValue('amount', (max - boughtTickets).toString());
    }, [boughtTickets, max]);

    const ticketsGrabbedText = useMemo(
        () => `${boughtTickets} / ${max}`,
        [boughtTickets, max]
    );
    useEffect(() => {
        if (purchaseLoading) {
            return;
        }
        if (isTicketBought && !isTimerEnd && !isWarningTime) {
            onClose();
            navigation.navigate(ROUTES.LUCKY_STRIKE_WAITING_FOR_RESULT, {
                id: currentGame.id,
                routeBack,
            });
            return;
        }

        if (isTicketBought && isTimerEnd) {
            navigateToHistory();
        }
    }, [
        isTicketBought,
        isTimerEnd,
        navigateToHistory,
        purchaseLoading,
        isWarningTime,
    ]);

    return {
        submitForm,
        values,
        errors,
        setFieldValue,
        setTouched,
        touched,
        isValid,
        dirty,
        handleChangeAmount,
        handleOnBlur,
        onSelectCardPress,
        handleOnBack,
        max,
        participantsText,
        iguAmountWei,
        buttonText,
        time,
        duration,
        showButton,
        enoughBalance,
        topUp,
        price,
        confirmModal,
        closeConfirmModal,
        openConfirmModal,
        purchaseLoading,
        onConfirmPress,
        userCount,
        texts,
        ticketsGrabbedText,
        onMaxPress,
        handleOnClose,
        isWarningTime,
    };
};
