import * as React from 'react';
import { useEffect, useMemo } from 'react';

import { useAppLoading } from '@contexts/AppLoading/AppLoadingContext';
import { useModal } from '@contexts/ModalContext';
import { useUser } from '@contexts/UserContext';
import AsyncStorage from '@react-native-async-storage/async-storage';

interface IProps {
    key: string;
}

export const useShowOnBoarding = ({ key }: IProps) => {
    const { isSplashAnimationComplete } = useAppLoading();
    const { user } = useUser();

    const storageKey = `useShowOnBoarding-${user?.id}-${key}`;

    const [show, setShow] = React.useState<boolean>(false);

    const canShow = useMemo(
        () => isSplashAnimationComplete && show,
        [show, isSplashAnimationComplete]
    );

    useEffect(() => {
        async function check() {
            const status = await AsyncStorage.getItem(storageKey);
            setShow(!status);
            AsyncStorage.setItem(storageKey, 'shown');
        }

        check();
    }, [storageKey]);

    return { canShow };
};
