import * as React from 'react';

import _ from 'lodash';

import CircleDivider from '@components/CircleDivider';
import { Text, View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';

import stylesMain from './styles';

interface IProps {
    text: string;
}

const UpdateRow = ({ text }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={styles.container}>
            <CircleDivider customStyles={styles.circle} />
            <Text style={styles.text}>{text}</Text>
        </View>
    );
};

export default UpdateRow;
