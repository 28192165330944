import React, { useCallback } from 'react';

import useOpenModalScreen from '@hooks/useOpenModalScreen';
import { useVisibleHook } from '@hooks/useVisibleHook';
import ROUTES from '@navigation/routes';

import TokenTellerPurchaseModal from '../components/TokenTellerPurchaseModal';

const useTokenTellerPurchaseModal = (
    routeBack: ROUTES,
    action?: () => void
) => {
    const {
        isVisible: purchaseModal,
        open,
        close: closePurchaseModal,
    } = useVisibleHook();

    const openPurchaseModal = async (handleClosePrevModal?: () => void) => {
        if (handleClosePrevModal) {
            handleClosePrevModal?.();
        }

        open();
    };

    const { handleOpenModalScreen } = useOpenModalScreen({
        modalOpen: open,
        modalClose: closePurchaseModal,
        route: ROUTES.TOKEN_TELLER_ALL_PARTICIPANTS,
    });

    const { handleOpenModalScreen: handleOpenChartScreen } = useOpenModalScreen(
        {
            modalOpen: open,
            modalClose: closePurchaseModal,
            route: ROUTES.TOKEN_TELLER_CHART,
        }
    );

    const handleClose = () => {
        closePurchaseModal();
        action?.();
    };

    const renderPurchaseModal = useCallback(
        (id: string) => {
            return (
                <TokenTellerPurchaseModal
                    id={id}
                    isVisible={purchaseModal}
                    onClose={handleClose}
                    onViewAllParticipants={handleOpenModalScreen}
                    onOpenChartScreen={handleOpenChartScreen}
                    routeBack={routeBack}
                />
            );
        },
        [purchaseModal, routeBack]
    );

    return {
        openPurchaseModal,
        closePurchaseModal,
        renderPurchaseModal,
        handleOpenModalScreen,
        purchaseModal,
    };
};

export default useTokenTellerPurchaseModal;
