import React, { FC, PropsWithChildren, createContext, useEffect } from 'react';

import { isWeb } from '@helpers/app';
import { useBreedingPetDrafts } from '@hooks/useBreedingPetDrafts';
import { useGameAvailableBalance } from '@hooks/useGameAvailableBalance';
import { useLuckyStrikeConfig } from '@hooks/useLuckyStrikeConfig';
import { useMysteryLikesData } from '@hooks/useMysteryLikesData';
import { useTokenTellerConfig } from '@hooks/useTokenTellerConfig';
import {
    BreedingPetDraftDetailsOutput,
    BreedingPetDraftOutput,
    BreedingPetParentOutput,
    BreedingPetsPairStatsOutput,
    BreedingRanksStatsOutput,
    GAME_DIFFICULTY,
    IAllGamesData,
    IguAvailableBalance,
    LuckyStrikeAvailableGameOutput,
    LuckyStrikeGameConfigOutput,
    MYSTERY_GAMES,
    MysteryLikesCreateGame,
    MysteryLikesGameHistory,
    MysteryLikesGameRoundOutput,
    MysteryLikesGameStats,
    MysteryLikesMyPendingGame,
    NFT_ROYAL_GAME_MODE,
    NftRoyalGameConfigurations,
    NftRoyaleAvailableGameOutput,
    NftRoyaleGameHistoryOutput,
    NftRoyaleGameStatsOutput,
    NftRoyalePendingGameOutput,
    PaginatedMysteryLikesAvailableGamesItem,
    QuantumSpinGameConfigOutput,
    QuantumSpinGamePrizeOutput,
    TokentellerAvailableGameOutput,
    TokentellerGameCoefficientOutputResponse,
    TokentellerGameConfigOutput,
} from '@models/mysteryGames';

import { useLuckyStrikeAvailableGames } from '../screens/TabBar/Home/hooks/useLuckyStrikeAvailableGames';
import { useNftRoyaleGameConfig } from '../screens/TabBar/Home/hooks/useNftRoyaleGameConfig';
import { useQuantumSpin } from '../screens/TabBar/Home/hooks/useQuantumSpin';
import { useTokenTellerAvailableGames } from '../screens/TabBar/Home/hooks/useTokenTellerAvailableGames';
import { usePersistStorage } from '../vendor/react-native-user-persist-storage';
import { useUser } from './UserContext';

export type MysteryGamesContextType = {
    mysteryLikesGameStats: MysteryLikesGameStats | undefined;
    getMysteryLikesGameStats: (silent: boolean) => Promise<void>;
    mysteryLikesGameStatsLoading: boolean;
    mysteryLikesGameStatsRefreshLoading: boolean;
    availableBalance: IguAvailableBalance | undefined;
    availableBalanceLoading: boolean;
    getGamingAvailableBalance: (withLoad: boolean) => Promise<void>;
    createdMysteryLikesGame: MysteryLikesCreateGame | undefined;
    setCreatedMysteryLikesGame: (game: MysteryLikesCreateGame) => void;
    currentMysteryLikesRound: MysteryLikesGameRoundOutput | undefined;
    setCurrentMysteryLikesRound: (round: MysteryLikesGameRoundOutput) => void;
    currentMysteryLikesGameHistory: MysteryLikesGameHistory | undefined;
    setCurrentMysteryLikesGameHistory: (game: MysteryLikesGameHistory) => void;
    currentMysteryLikesGameToPlay:
        | PaginatedMysteryLikesAvailableGamesItem
        | undefined;
    setCurrentMysteryLikesGameToPlay: (
        game: PaginatedMysteryLikesAvailableGamesItem
    ) => void;
    mysteryLikesPendingGame: MysteryLikesMyPendingGame | undefined;
    setMysteryLikesPendingGame: (
        round: MysteryLikesMyPendingGame | undefined
    ) => void;
    currentMysteryLikesGameId: string | undefined;
    currentMysteryLikesGameValue: string;
    setCurrentMysteryLikesGameId: (value: string | undefined) => void;
    setCurrentMysteryLikesGameValue: (value: string) => void;
    topUpFromMysteryGames: boolean;
    setTopUpFromMysteryGames: (status: boolean) => void;

    clearMysteryLikes: () => void;

    //NFT_EVOLUTION - BREEDING
    breedingRanksStats: BreedingRanksStatsOutput[] | null;
    setBreedingRanksStats: (stats: BreedingRanksStatsOutput[] | null) => void;
    breedingPetsPairStats: BreedingPetsPairStatsOutput | null;
    setBreedingPetsPairStats: (
        stats: BreedingPetsPairStatsOutput | null
    ) => void;
    nftEvolutionCurrentPetForChoose: 'first' | 'second';
    setNftEvolutionCurrentPetForChoose: (type: 'first' | 'second') => void;
    nftEvolutionFirstPet: BreedingPetParentOutput | undefined;
    setNftEvolutionFirstPet: (pet: BreedingPetParentOutput | undefined) => void;
    nftEvolutionSecondPet: BreedingPetParentOutput | undefined;
    setNftEvolutionSecondPet: (
        pet: BreedingPetParentOutput | undefined
    ) => void;
    clearNftEvolution: () => void;
    breedingPetDraftDetails: BreedingPetDraftDetailsOutput | null | undefined;
    setBreedingPetDraftDetails: (
        stats: BreedingPetDraftDetailsOutput | null | undefined
    ) => void;
    getBreedingPetDrafts: (silent: boolean) => Promise<void>;
    breedingPetDraftsLoading: boolean;
    breedingPetDrafts: BreedingPetDraftOutput[] | undefined;
    //

    // NFT ROYAL
    nftRoyaleGameConfig: NftRoyaleGameStatsOutput | null;
    nftRoyaleGameConfigLoading: boolean;
    nftRoyaleGameConfigRefresh: boolean;
    getNftRoyaleGameConfig: (silent: boolean) => Promise<void>;
    nftRoyalGameConfiguration: NftRoyalGameConfigurations | null;
    handleSaveNftRoyalGameConfiguration: (
        mode: NFT_ROYAL_GAME_MODE,
        complexity: GAME_DIFFICULTY,
        livesCount: number
    ) => void;
    nftRoyalCurrentGame: NftRoyalePendingGameOutput | null;
    setNftRoyalCurrentGame: (game: NftRoyalePendingGameOutput | null) => void;
    nftRoyalCurrentAvailableGame: NftRoyaleAvailableGameOutput | null;
    setNftRoyalCurrentAvailableGame: (
        game: NftRoyaleAvailableGameOutput | null
    ) => void;
    currentNftRoyalGameHistory: NftRoyaleGameHistoryOutput | null;
    setCurrentNftRoyalGameHistory: (game: NftRoyaleGameHistoryOutput) => void;
    clearNftRoyal: () => void;
    clearNftRoyalGameConfiguration: () => void;

    // Lucky strike
    luckyStrikeConfig: LuckyStrikeGameConfigOutput | undefined;
    getLuckyStrikeConfig: (silent: boolean) => Promise<void>;
    luckyStrikeConfigLoading: boolean;
    luckyStrikeConfigRefreshLoading: boolean;

    getLuckyStrikeGames: (
        silent: boolean
    ) => Promise<LuckyStrikeAvailableGameOutput[] | undefined>;
    refetchLuckyStrikeGames: () => Promise<
        LuckyStrikeAvailableGameOutput[] | undefined
    >;
    luckyStrikeGames: LuckyStrikeAvailableGameOutput[] | undefined;
    luckyStrikeGamesLoading: boolean;
    luckyStrikeGamesRefreshLoading: boolean;
    luckyStrikeGamesRefetchLoading: boolean;

    // QuantumSpin

    fetchQuantumSpinConfig: () => Promise<void>;
    fetchQuantumSpinGamePrizes: () => Promise<void>;
    quantumSpinGameConfig: QuantumSpinGameConfigOutput | undefined;
    quantumSpinGamePrizes: QuantumSpinGamePrizeOutput[] | undefined;
    loadingQuantumSpinGamePrizes: boolean;

    // TokenTeller
    tokenTellerConfig: TokentellerGameConfigOutput | undefined;
    getTokenTellerConfig: (silent: boolean) => Promise<void>;
    tokenTellerConfigLoading: boolean;
    tokenTellerConfigRefreshLoading: boolean;

    getTokenTellerGames: (
        silent: boolean
    ) => Promise<TokentellerAvailableGameOutput[] | undefined>;
    refetchTokenTellerGames: () => Promise<
        TokentellerAvailableGameOutput[] | undefined
    >;
    tokenTellerGames: TokentellerAvailableGameOutput[] | undefined;
    tokenTellerGamesLoading: boolean;
    tokenTellerGamesRefreshLoading: boolean;
    tokenTellerGamesRefetchLoading: boolean;
    tokenTellerCoefficients:
        | TokentellerGameCoefficientOutputResponse
        | undefined;
    setTokenTellerCoefficients: (
        value: TokentellerGameCoefficientOutputResponse | undefined
    ) => void;
};

export const useMysteryGames = () => React.useContext(MysteryGamesContext);

export const MysteryGamesContext = createContext<MysteryGamesContextType>({
    mysteryLikesGameStats: undefined,
    getMysteryLikesGameStats: async (silent: boolean) => undefined,
    mysteryLikesGameStatsLoading: true,
    mysteryLikesGameStatsRefreshLoading: false,
    availableBalance: undefined,
    availableBalanceLoading: false,
    getGamingAvailableBalance: async (withLoad: boolean) => undefined,
    createdMysteryLikesGame: undefined,
    setCreatedMysteryLikesGame: (game: MysteryLikesCreateGame) => undefined,
    currentMysteryLikesRound: undefined,
    setCurrentMysteryLikesRound: (round: MysteryLikesGameRoundOutput) =>
        undefined,
    currentMysteryLikesGameHistory: undefined,
    setCurrentMysteryLikesGameHistory: (game: MysteryLikesGameHistory) =>
        undefined,
    currentMysteryLikesGameToPlay: undefined,
    setCurrentMysteryLikesGameToPlay: (
        game: PaginatedMysteryLikesAvailableGamesItem
    ) => undefined,
    currentMysteryLikesGameId: '',
    currentMysteryLikesGameValue: '',
    setCurrentMysteryLikesGameId: (value: string | undefined) => undefined,
    setCurrentMysteryLikesGameValue: (value: string) => undefined,
    mysteryLikesPendingGame: undefined,
    setMysteryLikesPendingGame: (
        round: MysteryLikesMyPendingGame | undefined
    ) => undefined,
    topUpFromMysteryGames: false,
    setTopUpFromMysteryGames: (status: boolean) => undefined,
    clearMysteryLikes: () => undefined,

    breedingRanksStats: null,
    setBreedingRanksStats: (stats: BreedingRanksStatsOutput[] | null) =>
        undefined,
    nftEvolutionCurrentPetForChoose: 'first',
    setNftEvolutionCurrentPetForChoose: (type: 'first' | 'second') => undefined,
    nftEvolutionFirstPet: undefined,
    setNftEvolutionFirstPet: (pet: BreedingPetParentOutput | undefined) =>
        undefined,
    nftEvolutionSecondPet: undefined,
    setNftEvolutionSecondPet: (pet: BreedingPetParentOutput | undefined) =>
        undefined,
    clearNftEvolution: () => undefined,
    breedingPetsPairStats: null,
    setBreedingPetsPairStats: (stats: BreedingPetsPairStatsOutput | null) =>
        undefined,
    breedingPetDraftDetails: null,
    setBreedingPetDraftDetails: (
        stats: BreedingPetDraftDetailsOutput | null | undefined
    ) => undefined,
    getBreedingPetDrafts: async (silent: boolean) => undefined,
    breedingPetDraftsLoading: false,
    breedingPetDrafts: undefined,

    //NFT ROYAL
    nftRoyaleGameConfig: null,
    nftRoyaleGameConfigLoading: true,
    nftRoyaleGameConfigRefresh: false,
    getNftRoyaleGameConfig: async (silent: boolean) => undefined,
    nftRoyalGameConfiguration: null,
    handleSaveNftRoyalGameConfiguration: (
        mode: NFT_ROYAL_GAME_MODE,
        complexity: GAME_DIFFICULTY,
        livesCount: number
    ) => undefined,
    nftRoyalCurrentGame: null,
    setNftRoyalCurrentGame: (game: NftRoyalePendingGameOutput | null) =>
        undefined,
    nftRoyalCurrentAvailableGame: null,
    setNftRoyalCurrentAvailableGame: (
        game: NftRoyaleAvailableGameOutput | null
    ) => undefined,
    currentNftRoyalGameHistory: null,
    setCurrentNftRoyalGameHistory: (game: NftRoyaleGameHistoryOutput) =>
        undefined,
    clearNftRoyalGameConfiguration: () => undefined,
    clearNftRoyal: () => undefined,

    // Lucky strike
    luckyStrikeConfig: undefined,
    getLuckyStrikeConfig: async (silent: boolean) => undefined,
    luckyStrikeConfigLoading: true,
    luckyStrikeConfigRefreshLoading: false,

    getLuckyStrikeGames: async (silent: boolean) => undefined,
    refetchLuckyStrikeGames: async () => undefined,
    luckyStrikeGames: undefined,
    luckyStrikeGamesLoading: true,
    luckyStrikeGamesRefreshLoading: false,
    luckyStrikeGamesRefetchLoading: false,

    //QuantumSpin
    fetchQuantumSpinConfig: async () => undefined,
    fetchQuantumSpinGamePrizes: async () => undefined,
    quantumSpinGameConfig: undefined,
    quantumSpinGamePrizes: undefined,
    loadingQuantumSpinGamePrizes: false,

    // TokenTeller
    tokenTellerConfig: undefined,
    getTokenTellerConfig: async (silent: boolean) => undefined,
    tokenTellerConfigLoading: true,
    tokenTellerConfigRefreshLoading: false,

    getTokenTellerGames: async (silent: boolean) => undefined,
    refetchTokenTellerGames: async () => undefined,
    tokenTellerGames: undefined,
    tokenTellerGamesLoading: true,
    tokenTellerGamesRefreshLoading: false,
    tokenTellerGamesRefetchLoading: false,
    tokenTellerCoefficients: undefined,
    setTokenTellerCoefficients: (
        value: TokentellerGameCoefficientOutputResponse | undefined
    ) => undefined,
});

export const TERMINATION_DAYS = 7;

const MysteryGamesProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const { user } = useUser();
    const {
        getMysteryLikesGameStats,
        mysteryLikesGameStats,
        mysteryLikesGameStatsLoading,
        mysteryLikesGameStatsRefreshLoading,
    } = useMysteryLikesData();

    const {
        availableBalance,
        availableBalanceLoading,
        getGamingAvailableBalance,
    } = useGameAvailableBalance();

    useEffect(() => {
        if (user) {
            getMysteryLikesGameStats(true);
        }
    }, [user?.id]);

    const [createdMysteryLikesGame, setCreatedMysteryLikesGame] =
        usePersistStorage<MysteryLikesCreateGame | undefined>(
            'MysteryGamesProvider.createdMysteryLikesGame',
            undefined,
            { persist: isWeb }
        );
    const [currentMysteryLikesRound, setCurrentMysteryLikesRound] =
        usePersistStorage<MysteryLikesGameRoundOutput | undefined>(
            'MysteryGamesProvider.currentMysteryLikesRound',
            undefined,
            { persist: isWeb }
        );

    const [currentMysteryLikesGameHistory, setCurrentMysteryLikesGameHistory] =
        usePersistStorage<MysteryLikesGameHistory | undefined>(
            'MysteryGamesProvider.currentMysteryLikesGameHistory',
            undefined,
            { persist: isWeb }
        );
    const [currentMysteryLikesGameToPlay, setCurrentMysteryLikesGameToPlay] =
        usePersistStorage<PaginatedMysteryLikesAvailableGamesItem | undefined>(
            'MysteryGamesProvider.currentMysteryLikesGameToPlay',
            undefined,
            { persist: isWeb }
        );

    const [currentMysteryLikesGameId, setCurrentMysteryLikesGameId] =
        usePersistStorage<string | undefined>(
            'MysteryGamesProvider.currentMysteryLikesGameId',
            '',
            {
                persist: isWeb,
            }
        );

    const [mysteryLikesPendingGame, setMysteryLikesPendingGame] =
        usePersistStorage<MysteryLikesMyPendingGame | undefined>(
            'MysteryGamesProvider.mysteryLikesPendingGame',
            undefined,
            {
                persist: isWeb,
            }
        );

    const [currentMysteryLikesGameValue, setCurrentMysteryLikesGameValue] =
        usePersistStorage<string>(
            'MysteryGamesProvider.currentMysteryLikesGameValue',
            '',
            {
                persist: isWeb,
            }
        );
    const [topUpFromMysteryGames, setTopUpFromMysteryGames] =
        usePersistStorage<boolean>(
            'MysteryGamesProvider.topUpFromMysteryGames',
            false,
            {
                persist: isWeb,
            }
        );
    const clearMysteryLikes = () => {
        setCurrentMysteryLikesGameId(undefined);
        setMysteryLikesPendingGame(undefined);
    };

    const {
        breedingPetDrafts,
        breedingPetDraftsLoading,
        getBreedingPetDrafts,
    } = useBreedingPetDrafts();

    useEffect(() => {
        if (user) {
            getBreedingPetDrafts(true);
        }
    }, [user?.id]);

    const [breedingRanksStats, setBreedingRanksStats] = usePersistStorage<
        BreedingRanksStatsOutput[] | null
    >('MysteryGamesProvider.breedingRanksStats', null, {
        persist: isWeb,
    });
    const [
        nftEvolutionCurrentPetForChoose,
        setNftEvolutionCurrentPetForChoose,
    ] = usePersistStorage<'first' | 'second'>(
        'MysteryGamesProvider.nftEvolutionCurrentPetForChoose',
        'first',
        {
            persist: isWeb,
        }
    );
    const [nftEvolutionFirstPet, setNftEvolutionFirstPet] = usePersistStorage<
        BreedingPetParentOutput | undefined
    >('MysteryGamesProvider.nftEvolutionFirstPet', undefined, {
        persist: isWeb,
    });
    const [nftEvolutionSecondPet, setNftEvolutionSecondPet] = usePersistStorage<
        BreedingPetParentOutput | undefined
    >('MysteryGamesProvider.nftEvolutionSecondPet', undefined, {
        persist: isWeb,
    });
    const [breedingPetsPairStats, setBreedingPetsPairStats] =
        usePersistStorage<BreedingPetsPairStatsOutput | null>(
            'MysteryGamesProvider.breedingPetsPairStats',
            null,
            {
                persist: isWeb,
            }
        );
    const [breedingPetDraftDetails, setBreedingPetDraftDetails] =
        usePersistStorage<BreedingPetDraftDetailsOutput | null | undefined>(
            'MysteryGamesProvider.breedingPetDraftDetails',
            null,
            {
                persist: isWeb,
            }
        );

    const clearNftEvolution = () => {
        setNftEvolutionCurrentPetForChoose('first');
        setNftEvolutionFirstPet(undefined);
        setNftEvolutionSecondPet(undefined);
        setTopUpFromMysteryGames(false);
        setBreedingPetsPairStats(null);
        setBreedingPetDraftDetails(null);
    };

    const {
        getNftRoyaleGameConfig,
        nftRoyaleGameConfig,
        nftRoyaleGameConfigLoading,
        nftRoyaleGameConfigRefresh,
    } = useNftRoyaleGameConfig();

    useEffect(() => {
        if (user) {
            getNftRoyaleGameConfig(true);
        }
    }, [user?.id]);

    const [nftRoyalGameConfiguration, setNftRoyalGameConfiguration] =
        usePersistStorage<NftRoyalGameConfigurations | null>(
            'MysteryGamesProvider.NftRoyalGameConfigurations',
            null,
            {
                persist: isWeb,
            }
        );

    const handleSaveNftRoyalGameConfiguration = (
        mode: NFT_ROYAL_GAME_MODE,
        complexity: GAME_DIFFICULTY,
        livesCount: number
    ) => {
        setNftRoyalGameConfiguration({ mode, complexity, livesCount });
    };

    const [nftRoyalCurrentGame, setNftRoyalCurrentGame] =
        usePersistStorage<NftRoyalePendingGameOutput | null>(
            'MysteryGamesProvider.nftRoyalCurrentGame',
            null,
            {
                persist: isWeb,
            }
        );

    const clearNftRoyalGameConfiguration = () => {
        setNftRoyalGameConfiguration(null);
    };

    const [nftRoyalCurrentAvailableGame, setNftRoyalCurrentAvailableGame] =
        usePersistStorage<NftRoyaleAvailableGameOutput | null>(
            'MysteryGamesProvider.nftRoyalCurrentAvailableGame',
            null,
            {
                persist: isWeb,
            }
        );
    const [currentNftRoyalGameHistory, setCurrentNftRoyalGameHistory] =
        usePersistStorage<NftRoyaleGameHistoryOutput | null>(
            'MysteryGamesProvider.currentNftRoyalGameHistory',
            null,
            {
                persist: isWeb,
            }
        );

    const clearNftRoyal = () => {
        clearNftRoyalGameConfiguration();
        setNftRoyalCurrentGame(null);
        setNftRoyalCurrentAvailableGame(null);
    };

    useEffect(() => {
        clearMysteryLikes();
        clearNftEvolution();
        clearNftRoyal();
    }, [user?.id]);

    const {
        luckyStrikeConfig,
        luckyStrikeConfigLoading,
        luckyStrikeConfigRefreshLoading,
        getLuckyStrikeConfig,
    } = useLuckyStrikeConfig();

    const {
        getGames: getLuckyStrikeGames,
        games: luckyStrikeGames,
        gamesLoading: luckyStrikeGamesLoading,
        refreshLoading: luckyStrikeGamesRefreshLoading,
        refetchGames: refetchLuckyStrikeGames,
        refetchLoading: luckyStrikeGamesRefetchLoading,
    } = useLuckyStrikeAvailableGames();

    useEffect(() => {
        if (user) {
            getLuckyStrikeConfig(true);
        }
    }, [user?.id]);

    const {
        fetchQuantumSpinConfig,
        fetchQuantumSpinGamePrizes,
        quantumSpinGameConfig,
        quantumSpinGamePrizes,
        loadingQuantumSpinGamePrizes,
    } = useQuantumSpin();

    useEffect(() => {
        if (user) {
            fetchQuantumSpinConfig();
            fetchQuantumSpinGamePrizes();
        }
    }, [user?.id]);

    const {
        tokenTellerConfig,
        tokenTellerConfigLoading,
        tokenTellerConfigRefreshLoading,
        getTokenTellerConfig,
    } = useTokenTellerConfig();

    const {
        getGames: getTokenTellerGames,
        games: tokenTellerGames,
        gamesLoading: tokenTellerGamesLoading,
        refreshLoading: tokenTellerGamesRefreshLoading,
        refetchGames: refetchTokenTellerGames,
        refetchLoading: tokenTellerGamesRefetchLoading,
    } = useTokenTellerAvailableGames();

    useEffect(() => {
        if (user) {
            getTokenTellerConfig(true);
        }
    }, [user?.id]);

    const [tokenTellerCoefficients, setTokenTellerCoefficients] =
        usePersistStorage<TokentellerGameCoefficientOutputResponse | undefined>(
            'MysteryGamesProvider.tokenTellerCoefficients',
            undefined,
            {
                persist: isWeb,
            }
        );

    return (
        <MysteryGamesContext.Provider
            value={{
                getMysteryLikesGameStats,
                mysteryLikesGameStats,
                mysteryLikesGameStatsLoading,
                mysteryLikesGameStatsRefreshLoading,
                availableBalance,
                availableBalanceLoading,
                getGamingAvailableBalance,
                setCreatedMysteryLikesGame,
                createdMysteryLikesGame,
                currentMysteryLikesGameHistory,
                currentMysteryLikesGameId,
                currentMysteryLikesGameToPlay,
                currentMysteryLikesGameValue,
                currentMysteryLikesRound,
                setCurrentMysteryLikesGameHistory,
                setCurrentMysteryLikesGameId,
                setCurrentMysteryLikesGameToPlay,
                setCurrentMysteryLikesGameValue,
                setCurrentMysteryLikesRound,
                setTopUpFromMysteryGames,
                topUpFromMysteryGames,
                nftEvolutionCurrentPetForChoose,
                nftEvolutionFirstPet,
                nftEvolutionSecondPet,
                setNftEvolutionCurrentPetForChoose,
                setNftEvolutionFirstPet,
                setNftEvolutionSecondPet,
                clearNftEvolution,
                breedingRanksStats,
                setBreedingRanksStats,
                breedingPetsPairStats,
                setBreedingPetsPairStats,
                setBreedingPetDraftDetails,
                breedingPetDraftDetails,
                getBreedingPetDrafts,
                breedingPetDrafts,
                breedingPetDraftsLoading,
                nftRoyaleGameConfig,
                nftRoyaleGameConfigLoading,
                nftRoyaleGameConfigRefresh,
                getNftRoyaleGameConfig,
                nftRoyalGameConfiguration,
                clearNftRoyalGameConfiguration,
                handleSaveNftRoyalGameConfiguration,
                nftRoyalCurrentGame,
                setNftRoyalCurrentGame,
                nftRoyalCurrentAvailableGame,
                setNftRoyalCurrentAvailableGame,
                clearNftRoyal,
                currentNftRoyalGameHistory,
                setCurrentNftRoyalGameHistory,
                mysteryLikesPendingGame,
                setMysteryLikesPendingGame,
                clearMysteryLikes,
                getLuckyStrikeConfig,
                luckyStrikeConfig,
                luckyStrikeConfigLoading,
                luckyStrikeConfigRefreshLoading,
                getLuckyStrikeGames,
                refetchLuckyStrikeGames,
                luckyStrikeGames,
                luckyStrikeGamesRefreshLoading,
                luckyStrikeGamesLoading,
                luckyStrikeGamesRefetchLoading,
                fetchQuantumSpinConfig,
                fetchQuantumSpinGamePrizes,
                quantumSpinGameConfig,
                quantumSpinGamePrizes,
                loadingQuantumSpinGamePrizes,
                tokenTellerConfig,
                tokenTellerConfigLoading,
                tokenTellerConfigRefreshLoading,
                getTokenTellerConfig,
                getTokenTellerGames,
                tokenTellerGames,
                tokenTellerGamesRefetchLoading,
                refetchTokenTellerGames,
                tokenTellerGamesRefreshLoading,
                tokenTellerGamesLoading,
                tokenTellerCoefficients,
                setTokenTellerCoefficients,
            }}>
            {children}
        </MysteryGamesContext.Provider>
    );
};

export default MysteryGamesProvider;

export const MYSTERY_LIKES_COLOR = '#8099FF';
export const NFT_EVOLUTION_COLOR = '#D5A881';
export const NFT_EVOLUTION_COLOR_SUCCESS = '#6BC79B';
export const NFT_ROYAL_COLOR = '#A1BF69';
export const LUCKY_STRIKE_COLOR = '#F08975';
export const QUANTUM_SPIN_COLOR = '#9683FC';
export const TOKENTELLER_COLOR = '#6BC79B';

export const allGamesData: IAllGamesData[] = [
    {
        id: MYSTERY_GAMES.mysteryLikes,
        color: MYSTERY_LIKES_COLOR,
    },
    {
        id: MYSTERY_GAMES.nftEvolution,
        color: NFT_EVOLUTION_COLOR,
    },
    {
        id: MYSTERY_GAMES.nftRoyal,
        color: NFT_ROYAL_COLOR,
    },
    {
        id: MYSTERY_GAMES.luckyStrike,
        color: LUCKY_STRIKE_COLOR,
    },
    {
        id: MYSTERY_GAMES.quantumSpin,
        color: QUANTUM_SPIN_COLOR,
    },
    {
        id: MYSTERY_GAMES.tokenteller,
        color: TOKENTELLER_COLOR,
    },
];

export const MYSTERY_LIKES_WAIT_COLOR = '#F08975';
export const MYSTERY_LIKES_WON_COLOR = '#6BC79B';
export const MYSTERY_LIKES_DRAW_COLOR = '#C086EA';
export const MYSTERY_LIKES_LOST_COLOR = '#D5A881';

export const LUCKY_STRIKE_LOST_COLOR = '#D5A881';
export const LUCKY_STRIKE_WON_COLOR = '#6BC79B';
