import React, { useCallback, useEffect, useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { Text, View } from '@components/Themed';
import { useConfig } from '@contexts/ConfigContext';
import { useUser } from '@contexts/UserContext';
import { isWeb, reloadApp } from '@helpers/app';
import { errorsHandler } from '@helpers/errors';
import { toastSuccess } from '@helpers/toastNotification';
import { useConfirmationModal } from '@hooks/useConfirmationModal';

import Icon, { ICON_NAMES } from '../../../../components/Icons';
import FlagIcons from '../../../../components/Icons/FlagIcons';
import ModalLoader from '../../../../components/ModalLoader';
import useLanguage from '../../../../hooks/useLanguage';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n, {
    LANGUAGES,
    getLanguage,
    setLanguage,
} from '../../../../i18n/i18n';
import stylesMain from './styles';

const LanguageOptions = () => {
    const styles = useThemedStyles(stylesMain);
    const { refetchConfig } = useConfig();
    const { reloadUser } = useUser();
    const { saveLanguageToBackend } = useLanguage();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [chosenLanguage, setChosenLanguage] = useState<string | undefined>();
    const [activeLanguage, setActiveLanguage] = useState<string | undefined>();
    const { setIsVisibleModalConfirm, renderItem: confirmationModal } =
        useConfirmationModal(
            i18n.t('profile.languageSettings.switchLanguageModal.title', {
                localeId: i18n.t(
                    `profile.languageSettings.options.${chosenLanguage}.change`
                ),
            }),
            i18n.t('profile.languageSettings.switchLanguageModal.description', {
                localeId: i18n.t(
                    `profile.languageSettings.options.${chosenLanguage}.change`
                ),
            }),
            'attention'
        );

    useEffect(() => {
        async function loadLanguage() {
            const language = await getLanguage();

            setActiveLanguage(language);
        }

        loadLanguage();
    }, []);

    const saveLanguage = useCallback(async () => {
        try {
            setIsLoading(true);
            await setLanguage(chosenLanguage);
            setActiveLanguage(chosenLanguage);
            refetchConfig();
            reloadUser();
            await saveLanguageToBackend(chosenLanguage, true);
            toastSuccess();
            setIsLoading(false);
            if (isWeb) {
                reloadApp();
            }
        } catch (error) {
            setIsLoading(false);
            errorsHandler(error, true);
        }
    }, [chosenLanguage]);

    const renderOption = useCallback(
        (item: string, index: number) => {
            const isActive = item === activeLanguage;

            return (
                <TouchableOpacity
                    activeOpacity={1}
                    key={item}
                    onPress={() => {
                        if (isActive) {
                            return;
                        }
                        setChosenLanguage(item);
                        setIsVisibleModalConfirm(true);
                    }}
                    style={[
                        styles.optionContainer,
                        isActive && styles.activeOption,
                        index === 0 && styles.firstOption,
                        index === LANGUAGES.length - 1 && styles.lastOption,
                    ]}>
                    <FlagIcons slug={item} size={24} />
                    <View style={styles.contentContainer}>
                        <Text style={styles.title}>
                            {i18n.t(
                                `profile.languageSettings.options.${item}.name`
                            )}
                        </Text>
                        <View
                            style={[
                                styles.circle,
                                item === activeLanguage && styles.activeOption,
                            ]}>
                            {isActive && (
                                <Icon name={ICON_NAMES.CHECK} size={12} />
                            )}
                        </View>
                    </View>
                </TouchableOpacity>
            );
        },
        [activeLanguage]
    );

    return (
        <>
            <View style={styles.optionsContainer}>
                {LANGUAGES.map((item, index) => renderOption(item, index))}
                {confirmationModal(saveLanguage)}
            </View>
            <ModalLoader isVisible={isLoading} />
        </>
    );
};

export default LanguageOptions;
