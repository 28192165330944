import { useCallback, useEffect, useRef } from 'react';

import { debounce } from 'lodash';

import { useHaptic } from './useHaptic';

export const useTouchableOpacityDebounce = (onPress?: () => void) => {
    const { triggerMedium } = useHaptic();
    const onPressRef = useRef(onPress);

    useEffect(() => {
        onPressRef.current = onPress;
    }, [onPress]);

    const handlePress = async () => {
        await triggerMedium();
        onPressRef.current?.();
    };

    // debounce callback
    const handleDebounce = useCallback(
        debounce(
            () => {
                handlePress();
            },
            2000,
            {
                leading: true,
                trailing: false,
            }
        ),
        [triggerMedium]
    );

    return {
        handleDebounce,
    };
};
