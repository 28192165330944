import * as React from 'react';

import CheckoutSummary from '../../../../components/CheckoutScreen/CheckoutSummary';
import CheckoutSuccessScreen from '../../../../components/CheckoutSuccessScreen';
import Divider from '../../../../components/Divider';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import { useNavigation } from '../../../../navigation/useNavigation';
import { MysteryBoxPurchaseSuccessProps } from '../../../../types';
import TransactionDetailsPurchaseBox from '../components/TransactionDetailsPurchaseBox';
import stylesMain from './styles';

const MysteryBoxPurchaseSuccessScreen = ({
    route,
}: MysteryBoxPurchaseSuccessProps) => {
    const { transactionResult, payment, amount } = route.params;

    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const handleBackToMysteryBoxes = () => {
        navigation.pop();
    };

    navigation.blockHardwareBack();

    return (
        <CheckoutSuccessScreen
            title={i18n.t('mysteryBoxes.purchaseSuccess.title')}
            text={i18n.t('mysteryBoxes.purchaseSuccess.text')}
            onPressButtonMain={handleBackToMysteryBoxes}
            buttonMainText={i18n.t('mysteryBoxes.purchaseSuccess.button')}
            explorerUrl={transactionResult?.explorerUrl}
            TransactionDetailsComponent={
                <>
                    <TransactionDetailsPurchaseBox amount={amount} />
                    <Divider customStyles={styles.divider} />
                    <CheckoutSummary
                        payment={payment}
                        transactionResult={transactionResult ?? undefined}
                    />
                </>
            }
        />
    );
};

export default MysteryBoxPurchaseSuccessScreen;
