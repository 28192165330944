import { StyleSheet } from 'react-native';

import Colors from '../../../../../../constants/Colors';
import { verticalScale } from '../../../../../../helpers/dimensions';
import { theme } from '../../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        centralContainer: {
            marginHorizontal: 8,
        },
        content: {
            flexDirection: 'row',
        },
        padding: {
            marginBottom: verticalScale(20),
        },
        errorBox: {
            alignItems: 'center',
            paddingVertical: verticalScale(2),
        },
        error: {
            color: Colors[theme].text.danger,
        },
    });

export default styles;
