import * as React from 'react';

import { useMutation } from '@apollo/client';

import { MarkReferrerResponse } from '../../Data/Models';
import { MARK_REFERRER } from '../../Data/Requests';
import Colors from '../../constants/Colors';
import { useTheme } from '../../contexts/ThemeContext';
import { useUser } from '../../contexts/UserContext';
import {
    isGraphqlError,
    sliceLink,
    waitForDeepLink,
} from '../../helpers/helpers';
import { toastError } from '../../helpers/toastNotification';
import useQrCodeScanner from '../../hooks/useQrCodeScanner';
import useThemedStyles from '../../hooks/useThemedStyles';
import i18n from '../../i18n/i18n';
import { useNavigation } from '../../navigation/useNavigation';
import Button from '../Button';
import Input from '../Input';
import ModalBottom from '../ModalBottom';
import ModalLoader from '../ModalLoader';
import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    isVisible: boolean;
    onClose: (success: boolean) => void;
    onApplyPress: () => void;
    deepLinkingCode: string | undefined;
}

const ReferralModal = ({
    isVisible,
    onClose,
    onApplyPress,
    deepLinkingCode,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { theme } = useTheme();
    const [isError, setIsError] = React.useState('');
    const [value, setValue] = React.useState(deepLinkingCode || '');
    const navigation = useNavigation();

    const scanAction = (code: string) => {
        const slicedLink = sliceLink(code);
        if (!slicedLink) {
            toastError(
                undefined,
                i18n.t('referrals.referrerModal.error.empty'),
                'top'
            );
        } else {
            setValue(slicedLink);
            handleSendRef(slicedLink);
        }
    };

    const { scanButton, scannerModal } = useQrCodeScanner(
        'referral',
        scanAction
    );

    const [request, { loading }] =
        useMutation<MarkReferrerResponse>(MARK_REFERRER);
    const { setUser } = useUser();

    React.useEffect(() => {
        setIsError('');
    }, [value]);

    React.useEffect(() => {
        if (deepLinkingCode && isVisible) {
            setValue(deepLinkingCode);
            navigation.setParams({ refId: null });
            waitForDeepLink().then(() => handleSendRef(deepLinkingCode));
        }
    }, [isVisible, deepLinkingCode]);

    const onTextChange = (text: string) => {
        if (text === value) {
            return;
        }
        const extractFromLink = sliceLink(text);
        setValue(extractFromLink || text);
    };

    const handleSendRef = async (referralCode: string) => {
        try {
            const result = await request({
                variables: { referralCode },
            });
            if (result.data) {
                setUser(result.data.markReferrer);
                Console.log(
                    'result.data.markReferrer',
                    result.data.markReferrer
                );
                onClose(true);
            }
        } catch (error: any) {
            if (isGraphqlError(error, 'FORBIDDEN')) {
                setIsError(i18n.t('referrals.referrerModal.error.forbidden'));
                toastError(
                    i18n.t(
                        'referrals.referrerModal.error.toastForbidden.title'
                    ),
                    i18n.t('referrals.referrerModal.error.toastForbidden.text'),
                    'top'
                );
            } else {
                setIsError(
                    i18n.t('referrals.referrerModal.error.incorrectCode')
                );
                toastError(
                    i18n.t('referrals.referrerModal.error.toastError.title'),
                    i18n.t('referrals.referrerModal.error.toastError.text'),
                    'top'
                );
            }
        }
    };

    const handleClose = () => {
        setValue('');
        onClose(false);
    };

    return (
        <ModalBottom
            titleText={i18n.t('referrals.referrerModal.title')}
            additionalText={i18n.t('referrals.referrerModal.text')}
            isVisible={isVisible}
            avoidKeyboard
            onClose={handleClose}
            modalHeight={500}>
            <View>
                <ModalLoader isVisible={loading} />
                <View style={styles.inputWrapper}>
                    <Input
                        label={i18n.t('referrals.referrerModal.label')}
                        style={styles.input}
                        onChangeText={(text) => onTextChange(text)}
                        value={value.toUpperCase()}
                        placeholder={i18n.t(
                            'referrals.referrerModal.placeHolder'
                        )}
                        placeholderTextColor={Colors[theme].text.secondary}
                        error={isError}
                        autoCapitalize="characters"
                        component={scanButton()}
                    />
                    <View style={styles.noCodeWrapper}>
                        <Text style={styles.noCode}>
                            {i18n.t('referrals.referrerModal.noCode')}
                        </Text>

                        <Button
                            title={i18n.t('referrals.referrerModal.apply')}
                            type="text"
                            onPress={onApplyPress}
                            textStyles={styles.apply}
                        />
                    </View>
                </View>
                <View style={styles.buttonWrapper}>
                    <Button
                        title={i18n.t('referrals.referrerModal.button')}
                        disabled={!value}
                        onPress={() => handleSendRef(value.replace('#', ''))}
                    />
                </View>
                {scannerModal()}
            </View>
        </ModalBottom>
    );
};
export default ReferralModal;
