import React from 'react';
import { Text, View } from 'react-native';

import { Pet } from '../../../../../Data/Models';
import Divider from '../../../../../components/Divider';
import LevelIcon from '../../../../../components/Icons/Level';
import PetCollectionListItem from '../../../../../components/PetCollectionListItem';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import Styles from './styles';

interface IProps {
    pet: Pet;
    lvlUp?: boolean;
}

const TransactionDetailsLevelUp = ({ pet, lvlUp }: IProps) => {
    const styles = useThemedStyles(Styles);

    return (
        <>
            <View style={styles.levelUpWrapper}>
                <View style={styles.levelUpIcon}>
                    <LevelIcon size={64} />
                </View>
                <View style={styles.levelUpRight}>
                    <View style={styles.levelUpTitleWrapper}>
                        <Text style={styles.levelUpTitleText}>
                            {i18n.t('petLevelUp.checkout.upgrade')}
                        </Text>
                    </View>
                    <View style={styles.levelUpTextWrapper}>
                        <Text style={styles.levelUpText}>
                            {i18n.t('petLevelUp.checkout.level', {
                                level: pet.level + 1,
                            })}
                        </Text>
                    </View>
                </View>
            </View>
            <Divider customStyles={styles.divider} />
            <PetCollectionListItem
                style={styles.petContainer}
                pet={pet}
                lvlUp={lvlUp}
            />
        </>
    );
};
export default TransactionDetailsLevelUp;
