import * as React from 'react';
import { useMemo } from 'react';

import ButtonKnowledgeBase from '@components/ButtonKnowledgeBase';
import StatusIcon from '@components/Icons/StatusIcon';
import TagStatus from '@components/TagStatus';
import { Text, View } from '@components/Themed';
import TransactionSummary from '@components/TransactionSummary';
import { Links } from '@constants/Links';
import { useConfig } from '@contexts/ConfigContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { balanceFromWei, feeWithCurrency } from '@helpers/wallet';
import useFeeInfo from '@hooks/useFeeInfo';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import {
    IGU_WALLET_TRANSACTIONS,
    IGamingWalletTransactionDetails,
} from '@models/gamingWallet';
import ROUTES from '@navigation/routes';

import stylesMain from './styles';

interface IProps {
    transaction: IGamingWalletTransactionDetails;
}

const GamingWalletTransactionDetailsContent = ({ transaction }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { type, status, value, color, date, iguAmountWei } = transaction;
    const coinName = Coin.vigu;

    const { renderFeeInfoIcon } = useFeeInfo(
        undefined,
        undefined,
        ROUTES.HOW_COMMISSION_FEE
    );

    const { config } = useConfig();

    const commissionFee = useMemo(() => {
        if (!config?.iguWalletWithdrawFeePercentage) {
            return 0;
        }
        if (
            type === IGU_WALLET_TRANSACTIONS.Withdraw ||
            type === IGU_WALLET_TRANSACTIONS.MysteryLikesGamePayment ||
            type === IGU_WALLET_TRANSACTIONS.MysteryLikesGameWin
        ) {
            return (
                Number(balanceFromWei(iguAmountWei.replace('-', '')).value) *
                (config.iguWalletWithdrawFeePercentage / 100)
            );
        }
        return 0;
    }, [config?.iguWalletWithdrawFeePercentage, iguAmountWei]);

    const textInfo = React.useMemo(() => {
        return i18n.t(
            `wallet.gaming.transactionHistory.transactions.${type}.text`,
            {
                defaultValue: i18n.t('wallet.transactions.reward.UNKNOWN.text'),
            }
        );
    }, [type]);

    const transactionSummary = [
        {
            key: i18n.t('wallet.type'),
            value: i18n.t(
                `wallet.gaming.transactionHistory.transactions.${type}.title`,
                {
                    defaultValue: i18n.t(
                        'wallet.transactions.reward.UNKNOWN.name'
                    ),
                }
            ),
        },
        {
            key: i18n.t('wallet.date'),
            value: date,
        },
        !!commissionFee
            ? {
                  key: i18n.t('checkout.fields.commissionFee'),
                  value: feeWithCurrency(commissionFee, Coin.vigu),
                  icon: renderFeeInfoIcon(),
              }
            : undefined,
        {
            key:
                type === IGU_WALLET_TRANSACTIONS.Deposit
                    ? i18n.t('wallet.toAddress')
                    : i18n.t('wallet.fromAddress'),
            value: i18n.t('checkout.fields.gamingWallet'),
        },
    ];

    const renderTransactionValue = () => {
        return (
            <Text
                style={[
                    styles.textPrice,
                    { color },
                ]}>{`${value} ${coinName}`}</Text>
        );
    };

    const statusText = React.useMemo(() => {
        return i18n.t(`wallet.transactions.reward.${status}.title`);
    }, [status]);

    return (
        <>
            <View style={styles.top}>
                <TagStatus
                    icon={<StatusIcon slug={status} />}
                    status={status}
                    text={statusText}
                    bottomGap={12}
                />
                <Text style={styles.textAmount}>{i18n.t('wallet.amount')}</Text>
                {renderTransactionValue()}
                <Text style={styles.textInfo}>{textInfo}</Text>
                <ButtonKnowledgeBase
                    customStyles={styles.knowledgebase}
                    url={Links.rewardFormula}
                />
            </View>
            <View style={styles.bottom}>
                <TransactionSummary
                    containerStyle={styles.transactionSummary}
                    values={transactionSummary}
                />
            </View>
        </>
    );
};

export default GamingWalletTransactionDetailsContent;
