import React from 'react';

import Icon, { ICON_NAMES } from '../../../../components/Icons';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import stylesMain from './styles';

export const iconSize = 28;

interface IProps {
    type: 'arrowRight' | 'explore';
}

export const RightComponent = ({ type }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return React.useMemo(
        () =>
            type === 'arrowRight' ? (
                <Icon
                    name={ICON_NAMES.ARROW_RIGHT_SECONDARY}
                    color={styles.icon.color}
                />
            ) : (
                <Icon
                    name={ICON_NAMES.EXPLORE}
                    color={styles.icon.color}
                    size={16}
                />
            ),
        [type, styles]
    );
};
