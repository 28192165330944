import React, { useCallback, useMemo } from 'react';

import { useLazyQuery } from '@apollo/client';
import { useDimensions } from '@contexts/DimensionsContext';
import { useMysteryGames } from '@contexts/MysteryGamesContext';
import { useVisibleHook } from '@hooks/useVisibleHook';
import {
    TOKEN_TELLER_GAME_STATUS,
    TokentellerAvailableGameOutput,
    TokentellerGameCoefficientOutputResponse,
} from '@models/mysteryGames';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { useFocusEffect } from '@react-navigation/native';
import { GET_TOKEN_TELLER_GAME_COEFFICIENTS } from '@requests/tokenTeller';

import { toastGameExpired } from '../../../LuckyStrikeFlow/helpers';
import useTokenTellerPurchaseModal from '../../../hooks/useTokenTellerPurchaseModal';

const useTokenTellerMainScreen = (
    game?: TokentellerAvailableGameOutput | undefined
) => {
    const navigation = useNavigation();
    const { isSmallLayout } = useDimensions();

    const {
        availableBalance,
        getGamingAvailableBalance,
        tokenTellerGames,
        tokenTellerGamesLoading,
        tokenTellerGamesRefetchLoading,
        tokenTellerGamesRefreshLoading,
        getTokenTellerGames,
        refetchTokenTellerGames,
        setTokenTellerCoefficients,
    } = useMysteryGames();

    const [getCoefficients, { data: coefficientData }] =
        useLazyQuery<TokentellerGameCoefficientOutputResponse>(
            GET_TOKEN_TELLER_GAME_COEFFICIENTS,
            {
                pollInterval: 10000,
                fetchPolicy: 'cache-and-network',
            }
        );

    React.useEffect(() => {
        if (coefficientData) {
            setTokenTellerCoefficients(coefficientData);
        }
    }, [coefficientData]);

    const loadData = useCallback(
        async (silent: boolean) => {
            await Promise.all([
                getTokenTellerGames(silent),
                getGamingAvailableBalance(silent),
                getCoefficients(),
            ]);
        },
        [getTokenTellerGames]
    );

    useFocusEffect(
        useCallback(() => {
            loadData(true);
        }, [loadData])
    );

    const handleRefresh = useCallback(async () => {
        loadData(false);
    }, [loadData]);

    const onEndTime = useCallback(() => {
        if (tokenTellerGamesRefetchLoading) {
            return;
        }
        refetchTokenTellerGames();
    }, [refetchTokenTellerGames, tokenTellerGamesRefetchLoading]);

    const [currentGameId, setCurrentGameId] = React.useState<string>('');

    const {
        isVisible: roundsModal,
        open: openRoundsModal,
        close: closeRoundsModal,
    } = useVisibleHook();

    const resetCurrentGame = () => {
        setCurrentGameId('');
    };

    const handOnHowItWorks = () => {
        navigation.push(ROUTES.HOW_TOKEN_TELLER_WORK);
    };

    const { renderPurchaseModal, openPurchaseModal, purchaseModal } =
        useTokenTellerPurchaseModal(ROUTES.TOKEN_TELLER_MAIN, resetCurrentGame);

    const onPurchaseTicket = useCallback(
        (id: string) => {
            setCurrentGameId(id);
            openPurchaseModal(roundsModal ? closeRoundsModal : undefined);
        },
        [roundsModal]
    );

    React.useEffect(() => {
        const check = async () => {
            const currentGameId = tokenTellerGames?.find(
                (i) => i.id === game?.id
            )?.id;
            if (!currentGameId) {
                await toastGameExpired();
            } else {
                setCurrentGameId(currentGameId);
                openPurchaseModal();
            }
            navigation.setParams({
                game: undefined,
            });
        };

        if (game) {
            check();
        }
    }, [game, tokenTellerGames]);

    const isReloading = useMemo(
        () =>
            tokenTellerGamesLoading ||
            tokenTellerGamesRefetchLoading ||
            tokenTellerGamesRefreshLoading,
        [
            tokenTellerGamesLoading,
            tokenTellerGamesRefetchLoading,
            tokenTellerGamesRefreshLoading,
        ]
    );

    React.useEffect(() => {
        if (tokenTellerGames?.length) {
            const res = tokenTellerGames?.find(
                (i) => i.status === TOKEN_TELLER_GAME_STATUS.Pending
            );
            if (!res && !isReloading) {
                refetchTokenTellerGames();
            }
        }
    }, [tokenTellerGames, refetchTokenTellerGames, isReloading]);

    return {
        handleRefresh,
        isRefresh: tokenTellerGamesRefreshLoading,
        isLoading: tokenTellerGamesLoading,
        isSmallLayout,
        availableBalance,
        handOnHowItWorks,
        roundsModal,
        openRoundsModal,
        closeRoundsModal,
        renderPurchaseModal,
        onPurchaseTicket,
        tokenTellerGames,
        currentGameId,
        onEndTime,
        isReloading,
        purchaseModal,
    };
};

export default useTokenTellerMainScreen;
