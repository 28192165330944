import * as React from 'react';

import BannersCarousel from '@components/BannersCarousel';
import EmptyList from '@components/EmptyList';
import LoadingComponent from '@components/LoadingComponent';
import ModalCoinSelect from '@components/ModalCoinSelect';
import ModalDeposit from '@components/ModalDeposit';
import ModalMainnet from '@components/ModalMainnet';
import ModalSpaceId from '@components/ModalSpaceId';
import PullToRefresh from '@components/PullToRefresh';
import SafeAreaTabContent from '@components/SafeAreaTabContent';
import { View } from '@components/Themed';
import WalletAddress from '@components/WalletAddress';
import { useConfig } from '@contexts/ConfigContext';
import { useTrade } from '@contexts/Trade/TradeContext';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { CoinBalance } from '@contexts/Wallet/WalletHelpers';
import { openUrlOrShowError } from '@helpers/linking';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';
import NavigationWallet, {
    NAVIGATION_WALLET,
} from '@navigation/NavigationBar/NavigationWallet';
import { getWebAppLinkTrade } from '@navigation/helpers';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { useFocusEffect } from '@react-navigation/native';

import WalletButtons from '../../components/WalletButtons';
import WalletBalances from '../components/WalletBalances';
import WalletCoinBalance from '../components/WalletCoinBalance';
import stylesMain from './styles';

declare type BalanceType = 'DEPOSIT' | 'WITHDRAW' | undefined;

const CoinsMainScreen = () => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const {
        balanceLoaded,
        walletBalance,
        walletData,
        reloadBalance,
        reloadExchanges,
        balanceReloading,
        getCoinBalances,
    } = useWallet();

    const [viewHeight, setHeightView] = React.useState<number>(0);

    const [isVisibleModalDeposit, setIsVisibleModalDeposit] =
        React.useState<boolean>(false);
    const [isVisibleModalCoinSelect, setIsVisibleModalCoinSelect] =
        React.useState<boolean>(false);
    const [actionSelect, setActionSelect] = React.useState<BalanceType>();
    const { isTradeAvailable } = useConfig();
    const { tradeTokens } = useTrade();

    const {
        isVisible: isVisibleMainnetModal,
        open: openMainnetModal,
        close: closeMainnetModal,
    } = useVisibleHook();

    const [coinSelected, setCoinSelected] = React.useState<
        CoinBalance | undefined
    >();

    const onRefresh = React.useCallback(() => {
        reloadBalance(false);
        reloadExchanges();
    }, []);

    const handleOnDepositPress = () => {
        setActionSelect('DEPOSIT');
        setIsVisibleModalCoinSelect(true);
    };

    const handleOnWithdrawPress = () => {
        setActionSelect('WITHDRAW');
        setIsVisibleModalCoinSelect(true);
    };

    const handleOnCoinSelect = (coinSelected: CoinBalance | undefined) => {
        if (actionSelect === 'DEPOSIT') {
            setCoinSelected(coinSelected);
            setIsVisibleModalDeposit(true);
        } else if (actionSelect === 'WITHDRAW') {
            setIsVisibleModalCoinSelect(false);
            navigation.navigate(ROUTES.WALLET_COINS_WITHDRAW, {
                balance: coinSelected,
            });
        }
    };

    const handleOnTradePress = async () => {
        if (!tradeTokens.length) {
            openMainnetModal();
            return;
        }

        if (!isTradeAvailable) {
            const url = await getWebAppLinkTrade();
            await openUrlOrShowError(url);
            return;
        }

        navigation.navigateToTrade();
    };

    const handleOnCoinPress = (item: CoinBalance) => {
        setIsVisibleModalCoinSelect(false);
        navigation.navigate(ROUTES.WALLET_COINS_BALANCE, { balance: item });
    };

    const handleSettingsPress = () => {
        navigation.navigate(ROUTES.WALLET_SETTINGS);
    };

    useFocusEffect(
        React.useCallback(() => {
            reloadBalance(true);
        }, [])
    );

    const {
        isVisible: spaceIdModal,
        open: openSpaceIdModal,
        close: closeSpaceIdModal,
    } = useVisibleHook();

    const renderItem = React.useCallback(() => {
        if (!walletBalance || !walletData) {
            return null;
        }

        return (
            <>
                <View style={styles.walletCoinBalanceWrapper}>
                    <WalletCoinBalance balance={getCoinBalances()} />
                </View>
                {!!walletData?.wallet?.address && (
                    <View style={styles.walletAddressWrapper}>
                        <WalletAddress
                            address={walletData.wallet.address}
                            shouldUseUsername
                            onInfoIcon={openSpaceIdModal}
                            children={
                                <ModalSpaceId
                                    isVisible={spaceIdModal}
                                    onClose={closeSpaceIdModal}
                                />
                            }
                        />
                    </View>
                )}
                <View style={styles.buttonsWrapper}>
                    <WalletButtons
                        onDepositPress={handleOnDepositPress}
                        onWithdrawPress={handleOnWithdrawPress}
                        onTradePress={handleOnTradePress}
                    />
                </View>
                <BannersCarousel containerStyle={styles.banners} />

                <View style={styles.balanceListWrapper}>
                    <WalletBalances onCoinPress={handleOnCoinPress} />
                </View>
            </>
        );
    }, [walletBalance, walletData, isTradeAvailable, spaceIdModal]);

    return (
        <SafeAreaTabContent>
            <View
                style={styles.container}
                onLayout={(event) =>
                    setHeightView(event.nativeEvent.layout.height)
                }>
                <NavigationBar
                    backIcon
                    title={i18n.t('wallet.screenTitle')}
                    settingsIcon
                    onPressSettings={handleSettingsPress}
                />
                <NavigationWallet currentNavigation={NAVIGATION_WALLET.MAIN} />

                <PullToRefresh
                    refreshing={balanceReloading}
                    textLoading={i18n.t('pullToRefresh.reloadBalance.text')}
                    onRefresh={onRefresh}
                    renderItem={renderItem}
                    FooterComponent={() => (
                        <>
                            {!balanceLoaded && (
                                <LoadingComponent
                                    text={i18n.t('wallet.loading')}
                                />
                            )}

                            {balanceLoaded &&
                                (!walletBalance || !walletData) && (
                                    <EmptyList
                                        emptyTitle={i18n.t('wallet.emptyTitle')}
                                        emptyText={i18n.t('wallet.emptyText')}
                                        viewHeight={viewHeight}
                                        withAnimation="cry"
                                    />
                                )}
                        </>
                    )}
                />
                <ModalCoinSelect
                    isVisible={isVisibleModalCoinSelect}
                    setIsVisible={setIsVisibleModalCoinSelect}
                    onPress={handleOnCoinSelect}>
                    <ModalDeposit
                        closeFirstModal={setIsVisibleModalCoinSelect}
                        isVisible={isVisibleModalDeposit}
                        setIsVisible={setIsVisibleModalDeposit}
                        coin={coinSelected?.name}
                        secondModal
                    />
                </ModalCoinSelect>
                <ModalMainnet
                    isVisible={isVisibleMainnetModal}
                    onClose={closeMainnetModal}
                />
            </View>
        </SafeAreaTabContent>
    );
};

export default CoinsMainScreen;
