import * as React from 'react';

import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';

import { useNavigation } from '../../useNavigation';
import NavigationItem from '../NavigationItem';
import stylesMain from './styles';

interface IProps {
    currentNavigation: NAVIGATION_LUCKY_STRIKE;
}

export enum NAVIGATION_LUCKY_STRIKE {
    ROUNDS = 'ROUNDS',
    HISTORY = 'HISTORY',
}

const data: NAVIGATION_LUCKY_STRIKE[] = [
    NAVIGATION_LUCKY_STRIKE.ROUNDS,
    NAVIGATION_LUCKY_STRIKE.HISTORY,
];

const NavigationLuckyStrike = ({ currentNavigation }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const handleNavigation = (item: NAVIGATION_LUCKY_STRIKE) => {
        switch (item) {
            case NAVIGATION_LUCKY_STRIKE.ROUNDS:
                navigation.navigate(ROUTES.LUCKY_STRIKE_MAIN);
                break;
            case NAVIGATION_LUCKY_STRIKE.HISTORY:
                navigation.navigate(ROUTES.LUCKY_STRIKE_HISTORY);
                break;
        }
    };

    return (
        <View style={styles.main}>
            <View style={styles.shadow}>
                <View style={styles.container}>
                    {data.map((item) => (
                        <NavigationItem
                            key={item}
                            onPress={() => handleNavigation(item)}
                            title={i18n.t(
                                `HomeScreen.GamesNavigationBar.${item}`
                            )}
                            isActive={currentNavigation === item}
                            containerStyles={styles.item}
                        />
                    ))}
                </View>
            </View>
        </View>
    );
};
export default NavigationLuckyStrike;
