import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(20),
            marginTop: verticalScale(32),
        },

        text: {
            textAlign: 'right',
            color: Colors[theme].text.primary,
            ...font({
                size: 15,
                height: 23,
                weight: '600',
            }),
            marginBottom: verticalScale(2),
        },
        label: {
            textAlign: 'right',
            color: Colors[theme].text.secondary,
            ...font({
                size: 12,
                height: 16,
                weight: '700',
            }),
        },
        header: {
            marginBottom: verticalScale(20),
        },
        card: {
            marginBottom: verticalScale(16),
        },
    });

export default styles;
