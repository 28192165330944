import * as React from 'react';
import { SafeAreaView } from 'react-native';

import StepFourImage from '@assets/icons/howTo/howToAvailableRewards/stepFour.svg';
import StepOneImage from '@assets/icons/howTo/howToAvailableRewards/stepOne.svg';
import StepThreeImage from '@assets/icons/howTo/howToAvailableRewards/stepThree.svg';
import StepTwoImage from '@assets/icons/howTo/howToAvailableRewards/stepTwo.svg';
import AlertCard from '@components/AlertCard';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonKnowledgeBase from '@components/ButtonKnowledgeBase';
import Carousel from '@components/Carousel';
import { ICON_NAMES } from '@components/Icons';
import { Text, View } from '@components/Themed';
import { Links } from '@constants/Links';
import { useDimensions } from '@contexts/DimensionsContext';
import { Coin, REWARD_UNLOCK_PERCENT } from '@contexts/Wallet/WalletHelpers';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBarModal from '@navigation/NavigationBar/NavigationBarModal';
import useNavigationListeners from '@navigation/hooks/useNavigationListeners';
import { useNavigation } from '@navigation/useNavigation';

import { HowToAvailableRewardsProps } from '../../../types';
import stylesMain from '../styles/styles';

export const howToSlideAboutNegativeBalance = 3;

const data = ['stepOne', 'stepTwo', 'stepThree'];

const HowToAvailableRewardsScreen = ({ route }: HowToAvailableRewardsProps) => {
    useNavigationListeners();
    const { windowWidth } = useDimensions();
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const renderItem = (item: string) => {
        const icon = () => {
            switch (item) {
                case 'stepOne':
                    return <StepOneImage />;
                case 'stepTwo':
                    return <StepTwoImage />;
                case 'stepThree':
                    return <StepThreeImage />;
                case 'stepFour':
                    return <StepFourImage />;
                default:
                    return null;
            }
        };

        const component = () => {
            switch (item) {
                case 'stepOne':
                    return (
                        <BlockHeader
                            titleComponent={
                                <>
                                    {i18n.t(
                                        `howTo.availableRewards.${item}.titleOne`
                                    )}
                                    <Text
                                        style={
                                            styles.accent
                                        }>{`${REWARD_UNLOCK_PERCENT}%`}</Text>
                                    {i18n.t(
                                        `howTo.availableRewards.${item}.titleTwo`
                                    )}
                                </>
                            }
                            title={i18n.t(
                                `howTo.availableRewards.${item}.title`
                            )}
                            text={i18n.t(
                                `howTo.availableRewards.${item}.text`,
                                {
                                    percent: REWARD_UNLOCK_PERCENT,
                                }
                            )}
                            type="emptyState"
                        />
                    );

                case 'stepThree':
                    return (
                        <BlockHeader
                            title={i18n.t(
                                `howTo.availableRewards.${item}.title`
                            )}
                            additionalTitle={Coin.iguai}
                            text={i18n.t(`howTo.availableRewards.${item}.text`)}
                            type="emptyState"
                        />
                    );

                default:
                    return (
                        <BlockHeader
                            title={i18n.t(
                                `howTo.availableRewards.${item}.title`
                            )}
                            text={i18n.t(`howTo.availableRewards.${item}.text`)}
                            type="emptyState"
                        />
                    );
            }
        };

        return (
            <View style={styles.itemContainer}>
                <View style={styles.content}>
                    <View style={styles.imgWrapper}>{icon()}</View>
                    {component()}
                </View>
            </View>
        );
    };

    return (
        <View style={styles.main}>
            <View style={styles.header}>
                <NavigationBarModal
                    iconName={ICON_NAMES.CLOSE}
                    title=""
                    onBack={() => navigation.pop()}
                />
            </View>
            <Carousel
                data={data}
                sliderWidth={windowWidth}
                itemWidth={windowWidth}
                renderItem={(item) => renderItem(item)}
                fullHeight
                pagination
                centredVertical
                startIndex={route?.params?.slide || 0}
            />
            <AlertCard
                text={i18n.t('howTo.availableRewards.alert')}
                containerStyle={styles.alert}
            />
            {/* TODO: CHECK LINK! */}
            <ButtonKnowledgeBase
                url={Links.availableReward}
                customStyles={styles.link}
            />
            <SafeAreaView />
        </View>
    );
};

export default HowToAvailableRewardsScreen;
