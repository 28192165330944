import * as React from 'react';
import { useCallback } from 'react';

import EmptyList from '@components/EmptyList';
import GestureRootView from '@components/GestureRootView';
import ListFilter from '@components/ListFilter';
import LoadingComponent from '@components/LoadingComponent';
import ModalLoader from '@components/ModalLoader';
import ModalMainnet from '@components/ModalMainnet';
import PullToRefresh from '@components/PullToRefresh';
import SafeAreaTabContent from '@components/SafeAreaTabContent';
import { Text, View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBar from '@navigation/NavigationBar';
import NavigationWallet, {
    NAVIGATION_WALLET,
} from '@navigation/NavigationBar/NavigationWallet';

import GamingTransactionItemHandler from '../components/GamingTransactionItemHandler';
import GamingWalletCarousel from '../components/GamingWalletCarousel';
import SelectWithdrawTokenModal from '../components/SelectWithdrawTokenModal';
import WalletGamingButtons from '../components/WalletGamingButtons/WalletGamingButtons';
import WithdrawConfirmationModal from '../components/WithdrawConfirmationModal';
import { useGamingMainScreen } from './hooks/useGamingMainScreen';
import stylesMain from './styles';

const GamingMainScreen = () => {
    const styles = useThemedStyles(stylesMain);

    const {
        gamingWalletCarouselData,
        handleTopUpPress,
        handleSettingsPress,
        handleWithdrawPress,
        isSmallLayout,
        canWithdraw,
        isVisibleMainnetModal,
        closeMainnetModal,
        gamingBalance,
        closeWithdrawModal,
        withdrawModalVisible,
        withdrawConfirmationVisible,
        closeWithdrawConfirmationModal,
        handleOpenWithdrawConfirmModal,
        selectedWithdrawToken,
        estimatedFee,
        isBNBEnough,
        renderFeeInfoIcon,
        renderCommissionFeeInfoIcon,
        renderBlockchainErrorsModal,
        handleConfirmWithdraw,
        withdrawLoading,
        isLoading,
        isRefresh,
        handleRefresh,
        transactionHistory,
        getIguTransactionHistoryLoadMore,
        loadMore,
        handleChooseFilter,
        filter,
        filterLoading,
        filterData,
        lastPendingId,
        canLoadMore,
        estimatedTurnoverData,
    } = useGamingMainScreen();

    const headerComponent = useCallback(() => {
        return (
            <>
                <View style={styles.carouselWrapper}>
                    <GamingWalletCarousel
                        gamingWalletCarouselData={gamingWalletCarouselData}
                        pvpBonus={
                            estimatedTurnoverData?.estimatedGamesTurnover
                                ?.iguAmountWei
                        }
                    />
                </View>
                <View style={styles.buttonsWrapper}>
                    <WalletGamingButtons
                        onTopUp={handleTopUpPress}
                        onWithdraw={handleWithdrawPress}
                        withdrawDisabled={!canWithdraw}
                    />
                </View>
                <View style={styles.headerText}>
                    <Text style={styles.headerTitle}>
                        {i18n.t('wallet.rewards.transactionHistoryText')}
                    </Text>
                    <ListFilter
                        titleText={i18n.t(
                            'wallet.gaming.transactionHistory.filter.title'
                        )}
                        onSelect={handleChooseFilter}
                        selectedItem={filter}
                        data={filterData}
                    />
                </View>
            </>
        );
    }, [
        gamingWalletCarouselData,
        canWithdraw,
        handleWithdrawPress,
        filter,
        filterData,
        estimatedTurnoverData,
    ]);
    const emptyComponent = useCallback(() => {
        return (
            <View
                style={
                    isSmallLayout
                        ? styles.emptyStateWrapperSmall
                        : styles.emptyStateWrapper
                }>
                <EmptyList
                    containerStyle={styles.emptyList}
                    emptyTitle={i18n.t('wallet.rewards.empty.title')}
                    emptyText={i18n.t('wallet.rewards.empty.text')}
                    withAnimation="cry"
                />
            </View>
        );
    }, [isSmallLayout]);

    return (
        <SafeAreaTabContent>
            <View style={styles.container}>
                <ModalLoader
                    isVisible={withdrawLoading}
                    text={i18n.t('checkout.loadingPurchasing')}
                    timeout={0}
                />
                <NavigationBar
                    backIcon
                    title={i18n.t('wallet.screenTitle')}
                    settingsIcon
                    onPressSettings={handleSettingsPress}
                />
                <NavigationWallet
                    currentNavigation={NAVIGATION_WALLET.GAMING}
                />
                {isLoading ? (
                    <LoadingComponent containerStyles={styles.loading} />
                ) : (
                    <GestureRootView style={styles.gestureContainer}>
                        <PullToRefresh
                            canLoadMore={canLoadMore}
                            shouldUseEmptyComponentInFooter
                            refreshing={isRefresh}
                            textLoading={i18n.t('pullToRefresh.loading')}
                            onRefresh={handleRefresh}
                            HeaderComponent={headerComponent()}
                            EmptyComponent={emptyComponent()}
                            renderData={transactionHistory || []}
                            onReachEnd={getIguTransactionHistoryLoadMore}
                            loadMore={loadMore}
                            contentLoading={filterLoading}
                            //@ts-ignore ignore item type any
                            renderItem={({ item }) => (
                                <GamingTransactionItemHandler
                                    loading={filterLoading}
                                    key={item.id}
                                    item={item}
                                    lastPendingId={lastPendingId}
                                />
                            )}
                        />
                    </GestureRootView>
                )}
                <ModalMainnet
                    isVisible={isVisibleMainnetModal}
                    onClose={closeMainnetModal}
                />
                {!!gamingBalance?.iguBalance.available && (
                    <>
                        <SelectWithdrawTokenModal
                            isVisible={withdrawModalVisible}
                            onClose={closeWithdrawModal}
                            available={gamingBalance.iguBalance.available}
                            onConfirm={handleOpenWithdrawConfirmModal}
                        />
                        <WithdrawConfirmationModal
                            isVisible={withdrawConfirmationVisible}
                            onClose={closeWithdrawConfirmationModal}
                            onConfirm={handleConfirmWithdraw}
                            fee={estimatedFee}
                            coin={selectedWithdrawToken}
                            isBNBEnough={isBNBEnough}
                            infoIcon={renderFeeInfoIcon}
                            commissionIcon={renderCommissionFeeInfoIcon}
                        />
                    </>
                )}
                {renderBlockchainErrorsModal()}
            </View>
        </SafeAreaTabContent>
    );
};

export default GamingMainScreen;
