import React, {
    FC,
    PropsWithChildren,
    createContext,
    useEffect,
    useState,
} from 'react';

import {
    CurrentStakingPlanRewardBoostResponse,
    StakingPlanOutput,
    StakingPlanRewardBoostResponse,
    StakingPlansResponse,
    UserStake,
    UserStakeResponse,
} from '@Data/Models';
import {
    GET_STAKING_PLANS,
    GET_STAKING_PLAN_REWARD_BOOST,
    GET_STAKING_PLAN_REWARD_BOOSTS,
    GET_USER_STAKING,
} from '@Data/Requests';
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client';
import { isWeb } from '@helpers/app';
import { errorsHandler } from '@helpers/errors';
import { dateCooldownExpired } from '@helpers/time';
import { toastErrorUnknown } from '@helpers/toastNotification';
import { valueToWei } from '@helpers/wallet';
import useLanguage from '@hooks/useLanguage';
import i18n from '@i18n/i18n';

import { usePersistStorage } from '../vendor/react-native-user-persist-storage';
import { useUser } from './UserContext';
import { Coin } from './Wallet/WalletHelpers';

export interface IStakingOption {
    id: StakingOptionsType;
    disabled: boolean;
    plans?: string[];
}

export const MIN_STACK_VALUE = 1;

export type StakingOptionsType = 'IGUP' | 'IGU_REWARD' | 'IGU_INTEREST';
export type StakingCoin = 'IGU' | 'IGUP';
export type AirdropModalOptions = 'OPTION' | 'PLANS' | 'MAIN';

export enum AIRDROP_MODAL_OPTIONS {
    OPTION = 'OPTION',
    PLANS = 'PLANS',
    MAIN = 'MAIN',
}

export enum STAKING_OPTIONS {
    IGUP = 'IGUP',
    IGU_REWARD = 'IGU_REWARD',
    IGU_INTEREST = 'IGU_INTEREST',
}

export type StakingContextType = {
    stakingOption: StakingOptionsType | null;
    setStakingOption: (option: StakingOptionsType | null) => void;
    stakingPlans: StakingPlanOutput[] | null;
    setStakingPlans: (plans: StakingPlanOutput[] | null) => void;
    currentPlan: StakingPlanOutput | undefined;
    setCurrentPlan: (plan: StakingPlanOutput | undefined) => void;
    stakingBoost: number;
    setStakingBoost: (boost: number) => void;
    stakingAmount: string | undefined;
    setStakingAmount: (cost: string | undefined) => void;

    clearStaking: () => void;
    getStakingPlanRewardBoost: (
        stakingPlanId: string,
        amount: string,
        action: () => void
    ) => void;
    calculateBoostLoading: boolean;
    currentStakingPlan: UserStake | null;
    stakingPlansLoading: boolean;
    userStakeLoading: boolean;
    stakingCoin: StakingCoin;
    isAddMoreCoins: boolean;
    setIsAddMoreCoins: (status: boolean) => void;
    refetchStakingPlans: () => Promise<void>;
    refetchCurrentBoost: () => Promise<void>;

    currentPlanBoost: number | null;
    setCurrentPlanBoost: (boost: number | null) => void;
    isIguDrop: boolean;
    currentStakingBoostLoading: boolean;
};

export const useStaking = () => React.useContext(StakingContext);

export const StakingContext = createContext<StakingContextType>({
    stakingOption: null,
    setStakingOption: () => undefined,
    stakingPlans: null,
    setStakingPlans: () => undefined,
    currentPlan: undefined,
    setCurrentPlan: () => undefined,
    stakingBoost: 0,
    setStakingBoost: () => undefined,
    stakingAmount: undefined,
    setStakingAmount: () => undefined,

    clearStaking: () => undefined,
    getStakingPlanRewardBoost: () => undefined,
    calculateBoostLoading: false,
    currentStakingPlan: null,
    stakingPlansLoading: true,
    userStakeLoading: true,
    stakingCoin: Coin.igu,
    isAddMoreCoins: false,
    setIsAddMoreCoins: () => undefined,
    refetchStakingPlans: async () => undefined,
    refetchCurrentBoost: async () => undefined,
    currentPlanBoost: null,
    setCurrentPlanBoost: () => undefined,
    isIguDrop: false,
    currentStakingBoostLoading: false,
});

export const stakingConfig = {
    amount: 100000,
    igup: 50,
    igu: 1,
    deadline: '2023-02-12T23:59:59.000Z',
};

const StakingProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const { user } = useUser();
    const client = useApolloClient();
    const [stakingPlans, setStakingPlans] = useState<
        StakingPlanOutput[] | null
    >(null);

    const [
        getStakingPlans,
        { data: stakingPlansData, loading: stakingPlansLoading },
    ] = useLazyQuery<StakingPlansResponse>(GET_STAKING_PLANS, {
        fetchPolicy: 'network-only',
        onError: (error) => Console.error(error),
    });

    const [
        getCurrentStakingBoostData,
        { data: currentStakingBoostData, loading: currentStakingBoostLoading },
    ] = useLazyQuery<CurrentStakingPlanRewardBoostResponse>(
        GET_STAKING_PLAN_REWARD_BOOST,
        {
            fetchPolicy: 'network-only',
            onError: (error) => Console.error(error),
        }
    );

    const [getUserStaking, { data: userStake, loading: userStakeLoading }] =
        useLazyQuery<UserStakeResponse>(GET_USER_STAKING, {
            fetchPolicy: 'network-only',
            onError: (error) => Console.error(error),
        });

    useEffect(() => {
        if (stakingPlansData)
            setStakingPlans(
                stakingPlansData.stakingMaxRewardPlanBoostsRequirements
            );
    }, [stakingPlansData]);

    const [currentStakingPlan, setCurrentStakingPlan] =
        usePersistStorage<UserStake | null>(
            'StakingProvider.currentStakingPlan',
            null,
            {
                persist: isWeb,
            }
        );

    const [currentPlanBoost, setCurrentPlanBoost] = usePersistStorage<
        number | null
    >('StakingProvider.currentPlanBoost', null, {
        persist: isWeb,
    });

    const refetchStakingPlans = async () => {
        try {
            await Promise.all([
                getStakingPlans(),
                getUserStaking(),
                getCurrentStakingBoostData(),
            ]);
        } catch (error) {
            errorsHandler(error, true);
        }
    };

    const refetchCurrentBoost = async () => {
        try {
            await getCurrentStakingBoostData();
        } catch (error) {
            errorsHandler(error, true);
        }
    };

    useEffect(() => {
        if (currentStakingBoostData?.stakingRewardBoost) {
            setCurrentPlanBoost(
                currentStakingBoostData.stakingRewardBoost.estimatedPercentage
            );
        }
    }, [currentStakingBoostData]);

    const [stakingOption, setStakingOption] =
        usePersistStorage<StakingOptionsType | null>(
            'StakingProvider.stakingOption',
            null,
            {
                persist: isWeb,
            }
        );

    const [isAddMoreCoins, setIsAddMoreCoins] = usePersistStorage<boolean>(
        'StakingProvider.isAddMoreCoins',
        false,
        {
            persist: isWeb,
        }
    );

    const [currentPlan, setCurrentPlan] = usePersistStorage<
        StakingPlanOutput | undefined
    >('StakingProvider.currentPlan', undefined, {
        persist: isWeb,
    });
    const [stakingAmount, setStakingAmount] = usePersistStorage<
        string | undefined
    >('StakingProvider.stakingAmount', '', {
        persist: isWeb,
    });
    const [stakingBoost, setStakingBoost] = usePersistStorage<number>(
        'StakingProvider.stakingBoost',
        0,
        {
            persist: isWeb,
        }
    );

    React.useEffect(() => {
        if (userStake) setCurrentStakingPlan(userStake.staked);
    }, [userStake]);

    React.useEffect(() => {
        return () => {
            clearStaking();
        };
    }, []);

    React.useEffect(() => {
        if (!user) {
            clearStaking();
            return;
        }
        getStakingPlans();
        getCurrentStakingBoostData();
        getUserStaking();
    }, [user?.id]);

    const [calculateBoostLoading, setCalculateBoostLoading] =
        useState<boolean>(false);

    const clearStaking = () => {
        setCurrentStakingPlan(null);
        setCurrentPlan(undefined);
        setStakingAmount(undefined);
        setCurrentPlanBoost(null);
        setStakingBoost(0);
        setIsAddMoreCoins(false);
    };

    const getStakingPlanRewardBoost = async (
        stakingPlanId: string,
        amount: string,
        action: () => void
    ) => {
        setCalculateBoostLoading(true);
        try {
            const result = await client.query<StakingPlanRewardBoostResponse>({
                query: GET_STAKING_PLAN_REWARD_BOOSTS,
                fetchPolicy: 'network-only',
                variables: {
                    toStakeWei: valueToWei(amount),
                },
            });

            if (result.data) {
                const index = result.data.stakingRewardPlanBoosts.findIndex(
                    (i) => i.plan.id === stakingPlanId
                );

                if (index !== -1) {
                    setStakingBoost(
                        result.data.stakingRewardPlanBoosts[index]
                            .estimatedPercentage
                    );
                    setStakingAmount(amount);
                    action();
                } else {
                    toastErrorUnknown();
                }
            }
            setCalculateBoostLoading(false);
        } catch (error) {
            Console.error(error);
            setCalculateBoostLoading(false);
        }
    };

    const stakingCoin = React.useMemo(
        () => (stakingOption?.includes(Coin.igup) ? Coin.igup : Coin.igu),
        [stakingOption]
    );

    const isIguDrop = React.useMemo(() => {
        return !dateCooldownExpired(stakingConfig.deadline);
    }, [stakingConfig]);

    return (
        <StakingContext.Provider
            value={{
                stakingOption,
                setStakingOption,
                stakingPlans,
                currentPlan,
                stakingAmount,
                stakingBoost,
                setStakingPlans,
                setCurrentPlan,
                setStakingAmount,
                setStakingBoost,
                clearStaking,
                getStakingPlanRewardBoost,
                calculateBoostLoading,
                currentStakingPlan,
                stakingPlansLoading,
                userStakeLoading,
                stakingCoin,
                isAddMoreCoins,
                setIsAddMoreCoins,
                refetchStakingPlans,
                currentPlanBoost,
                setCurrentPlanBoost,
                refetchCurrentBoost,
                isIguDrop,
                currentStakingBoostLoading,
            }}>
            {children}
        </StakingContext.Provider>
    );
};

export default StakingProvider;

export const StakingOptions: IStakingOption[] = [
    {
        id: STAKING_OPTIONS.IGUP,
        disabled: false,
    },
    // {
    //     id: STAKING_OPTIONS.IGU_REWARD,
    //     disabled: true,
    // },
    // {
    //     id: STAKING_OPTIONS.IGU_INTEREST,
    //     disabled: true,
    // },
];
