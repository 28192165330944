import { moderationRejectReasonsStatuses } from '../../constants/ModerationRejectReasonsStatuses';

export const moderationRejectReasons = [
    {
        text: 'notEnoughFollowers',
        status: moderationRejectReasonsStatuses.notEnoughFollowers,
    },
    {
        text: 'petIdNotFound',
        status: moderationRejectReasonsStatuses.petIdNotFound,
    },
    {
        text: 'petIdIncorrectPosition',
        status: moderationRejectReasonsStatuses.petIdIncorrectPosition,
    },
    {
        text: 'userNotFound',
        status: moderationRejectReasonsStatuses.userNotFound,
    },
];
