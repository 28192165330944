import * as React from 'react';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import HowToPlayScreen from '../../screens/HowTo/HowToPlay/HowToPlayScreen';
import EditNFTsScreen from '../../screens/TabBar/Play/EditNFTS/EditNFTSScreen';
import PlayCollectedExperienceScreen from '../../screens/TabBar/Play/PlayCollectedExperience/PlayCollectedExperienceScreen';
import PlaySuccessScreen from '../../screens/TabBar/Play/PlaySuccess/PlaySuccessScreen';
import PlayTasksActiveScreen from '../../screens/TabBar/Play/PlayTasksActive/PlayTasksActiveScreen';
import PlayTasksHistoryScreen from '../../screens/TabBar/Play/PlayTasksHistory/PlayTasksHistoryScreen';
import PlayTasksHistoryDetails from '../../screens/TabBar/Play/PlayTasksHistoryDetails/PlayTasksHistoryDetailsScreen';
import { TasksTabsParamList } from '../../types';
import ROUTES from '../routes';

const TasksStack = createBottomTabNavigator<TasksTabsParamList>();

const TasksNavigator = () => {
    return (
        <TasksStack.Navigator
            initialRouteName={ROUTES.PLAY_TASKS_ACTIVE}
            screenOptions={{
                headerShown: false,
            }}
            backBehavior="none"
            tabBar={() => null}>
            <TasksStack.Screen
                name={ROUTES.PLAY_TASKS_ACTIVE}
                component={PlayTasksActiveScreen}
            />
            <TasksStack.Screen
                name={ROUTES.PLAY_TASKS_HISTORY}
                component={PlayTasksHistoryScreen}
            />
        </TasksStack.Navigator>
    );
};

const PlayGroup = (RootStack: any) => {
    return (
        <>
            <RootStack.Screen
                name={ROUTES.PLAY_TASKS}
                component={TasksNavigator}
            />
            <RootStack.Screen
                name={ROUTES.PLAY_TASKS_HISTORY_DETAILS}
                component={PlayTasksHistoryDetails}
            />
            <RootStack.Screen
                name={ROUTES.PLAY_TASK_SUCCESS}
                component={PlaySuccessScreen}
                options={{
                    headerShown: false,
                    gestureEnabled: false,
                }}
            />
            <RootStack.Screen
                name={ROUTES.PLAY_COLLECTED_EXPERIENCE}
                component={PlayCollectedExperienceScreen}
                options={{
                    presentation: 'modal',
                    headerShown: false,
                }}
            />
            <RootStack.Screen
                name={ROUTES.PLAY_EDIT_NFTS}
                component={EditNFTsScreen}
                options={{
                    presentation: 'modal',
                    headerShown: false,
                }}
            />
        </>
    );
};

export default PlayGroup;
