import * as React from 'react';
import {
    ReturnKeyTypeOptions,
    StyleProp,
    TextInput,
    TextInputProps,
    TouchableOpacity,
    View,
    ViewStyle,
} from 'react-native';

import Icon, { ICON_NAMES } from '@components/Icons';
import Colors from '@constants/Colors';
import { useTheme } from '@contexts/ThemeContext';
import { isWeb } from '@helpers/app';
import { stylesWebOutlines } from '@helpers/style';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import stylesMain from './styles';

export interface IInput extends TextInputProps {
    value: string;
    onClearPress: () => void;
    returnKeyType?: ReturnKeyTypeOptions | undefined;
    blurOnSubmit?: boolean | undefined;
    stylesContainer?: StyleProp<ViewStyle>;
    onSubmitEditing?: () => void;
    onFocus?: () => void;
    forwardedRef?: any;
    onBlur?: () => void;
}

const Input: React.FunctionComponent<IInput> = ({
    onChangeText,
    onClearPress,
    stylesContainer,
    value,
    autoCapitalize = 'none',
    autoCorrect = false,
    placeholder = undefined,
    keyboardType,
    textContentType,
    secureTextEntry = false,
    multiline = false,
    numberOfLines = 1,
    style = undefined,
    placeholderTextColor,
    returnKeyType = 'default',
    blurOnSubmit = true,
    onSubmitEditing = () => null,
    forwardedRef = undefined,
    onFocus = () => null,
    onBlur = () => null,
    ...props
}: IInput) => {
    const styles = useThemedStyles(stylesMain);
    const { theme } = useTheme();
    const [isFocused, setIsFocused] = React.useState<boolean>(false);

    return (
        <View style={[styles.container, stylesContainer]}>
            <View style={[styles.inputContainer, isFocused && styles.focused]}>
                <View style={styles.icon}>
                    <Icon
                        name={ICON_NAMES.SEARCH}
                        size={20}
                        color={styles.searchIconColor.color}
                    />
                </View>

                <TextInput
                    ref={forwardedRef}
                    multiline={multiline}
                    onBlur={() => {
                        setIsFocused(false);
                        onBlur();
                    }}
                    onFocus={() => {
                        setIsFocused(true);
                        onFocus();
                    }}
                    selectionColor={Colors[theme].inputActiveBorder}
                    style={[styles.input, isWeb && stylesWebOutlines(), style]}
                    onChangeText={onChangeText}
                    value={value}
                    autoCapitalize={autoCapitalize}
                    autoCorrect={autoCorrect}
                    keyboardType={keyboardType}
                    textContentType={textContentType}
                    secureTextEntry={secureTextEntry}
                    numberOfLines={1}
                    returnKeyType={returnKeyType}
                    blurOnSubmit={blurOnSubmit}
                    onSubmitEditing={onSubmitEditing}
                    placeholder={i18n.t(
                        'MarketPlace.MarketPlaceSearch.placeHolder'
                    )}
                    placeholderTextColor={
                        placeholderTextColor || Colors[theme].text.secondary
                    }
                    {...props}
                />
                {!!value?.length && (
                    <TouchableOpacity
                        activeOpacity={0.8}
                        style={styles.delete}
                        onPress={onClearPress}>
                        <Icon
                            name={ICON_NAMES.DELETE_SEARCH}
                            size={20}
                            color={styles.searchIconColor.color}
                        />
                    </TouchableOpacity>
                )}
            </View>
        </View>
    );
};

export default Input;
