import { StyleSheet } from 'react-native';

import { moderateScale, scale, verticalScale } from '@helpers/dimensions';

import Colors from '../../../constants/Colors';
import font from '../../../helpers/font';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        safe: {
            backgroundColor: Colors[theme].background,
        },
        container: {
            flex: 1,
        },
        wrapper: {
            flex: 1,
            paddingHorizontal: scale(20),
            paddingBottom: verticalScale(16),
        },
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 22,
                height: 28,
                weight: '700',
            }),
            marginTop: verticalScale(4),
            marginBottom: verticalScale(8),
        },
        text: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            marginBottom: verticalScale(20),
        },
        textError: {
            color: Colors[theme].text.critical,
            ...font({
                size: 12,
                height: 16,
                weight: '600',
            }),
            marginBottom: verticalScale(16),
        },
        listWrapper: {
            flex: 1,
        },
        indexesItem: {
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            color: Colors[theme].text.secondary,
            width: scale(26),
            marginRight: scale(16),
        },
        itemsWrapper: {
            flexDirection: 'row',
            flex: 1,
            justifyContent: 'space-between',
            backgroundColor: 'transparent',
        },
        itemText: {
            textAlign: 'center',
            ...font({
                size: 15,
                height: 22,
                weight: '600',
            }),
            color: Colors[theme].text.primary,
        },
        listRow: {
            borderColor: Colors[theme].border.primary,
            borderWidth: 1,
            borderRadius: moderateScale(16),
            padding: moderateScale(20),
            marginBottom: verticalScale(16),
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: Colors[theme].surface.tertiary,
        },
        listRowIncorrect: {
            borderColor: Colors[theme].border.critical,
        },
        itemSelected: {
            borderColor: Colors[theme].border.accent,
        },
        shadowSelected: {
            backgroundColor: Colors[theme].shadow.selected,
        },
        item: {
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(8),
            backgroundColor: Colors[theme].buttonBackgroundOutline,
            borderWidth: 1,
            borderRadius: 1000,
            marginBottom: verticalScale(2),
            borderColor: Colors[theme].buttonBorderOutline,
            minWidth: scale(80),
        },
        shadow: {
            backgroundColor: Colors[theme].buttonShadowOutline,
            borderRadius: 1000,
        },
    });

export default styles;
