import React from 'react';
import { Text, View } from 'react-native';

import { Pet } from '@Data/Models';
import Divider from '@components/Divider';
import AgeIcon from '@components/Icons/AgeIcon';
import RankIcon from '@components/Icons/RankIcon';
import PetCollectionListItem from '@components/PetCollectionListItem';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import Styles from './styles';

interface IProps {
    pet: Pet;
    healthUp?: number;
}

const TransactionDetailsAgeRevive = ({ pet, healthUp }: IProps) => {
    const styles = useThemedStyles(Styles);

    return (
        <>
            <View style={styles.container}>
                <View style={styles.icon}>
                    <RankIcon slug={pet.rank} size={64} />
                </View>
                <View style={styles.right}>
                    <Text style={styles.title}>
                        {i18n.t('detailedPet.age.checkoutAgeRevive.restoreTo')}
                    </Text>
                    <Text style={styles.text}>
                        {i18n.t(`ranks.list.${pet.rank}.title`)}
                    </Text>
                </View>
                <AgeIcon slug="Baby" size={40} />
            </View>
            <Divider customStyles={styles.divider} />
            <PetCollectionListItem
                style={styles.petContainer}
                health={healthUp || pet.health}
                pet={pet}
            />
        </>
    );
};
export default TransactionDetailsAgeRevive;
