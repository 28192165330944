import * as React from 'react';
import { SafeAreaView, ScrollView } from 'react-native';

import ChangeIcon from '@assets/icons/energy/change.svg';
import EnergyInfoIcon from '@assets/icons/energy/energyInfo.svg';
import ExchangeIcon from '@assets/icons/energy/exchange.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import CardFeatureInfo from '@components/CardFeatureInfo/CardFeatureInfo';
import { ICON_NAMES } from '@components/Icons';
import LoadingComponent from '@components/LoadingComponent';
import PlayEnergy from '@components/PlayEnergy';
import { Text, View } from '@components/Themed';
import Timer from '@components/Timer';
import ToastNotification from '@components/ToastNotification';
import { useConfig } from '@contexts/ConfigContext';
import { usePlay } from '@contexts/PlayContext';
import { useUser } from '@contexts/UserContext';
import { waitForNavigation } from '@helpers/helpers';
import { balanceFromWei } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import NavigationBarModal from '@navigation/NavigationBar/NavigationBarModal';
import useNavigationListeners from '@navigation/hooks/useNavigationListeners';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import stylesMain from './styles';

const EnergyInfoScreen = () => {
    useNavigationListeners();
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const { config, refetchConfig } = useConfig();
    const { user, petsCount } = useUser();
    const rewardEnergyThreshold = config?.rewardEnergyThreshold;

    const { refetchEnergyReward, petsEstimatedGameRewardData } = usePlay();

    React.useEffect(() => {
        refetchEnergyReward();
    }, []);

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const handlePlay = React.useCallback(async () => {
        setIsLoading(true);
        await waitForNavigation();
        if (user?.petOrderedIds?.length) {
            navigation.navigateToTasks(true);
        } else {
            navigation.navigate(ROUTES.PLAY);
        }
        setIsLoading(false);
    }, [user]);

    return (
        <View style={styles.main}>
            <SafeAreaView style={styles.safeArea}>
                <NavigationBarModal
                    iconName={ICON_NAMES.CLOSE}
                    onBack={() => navigation.pop()}
                    containerStyle={styles.title}
                />
                {petsEstimatedGameRewardData?.singleEnergyEstimatedGameReward ? (
                    <>
                        <ScrollView
                            showsVerticalScrollIndicator={false}
                            style={styles.content}>
                            <View style={styles.imgWrapper}>
                                <EnergyInfoIcon />
                            </View>
                            <BlockHeader
                                type="emptyState"
                                title={i18n.t('energyInfoScreen.title')}
                                text={i18n.t('energyInfoScreen.text')}
                            />
                            <View style={styles.energyTimeWrapper}>
                                <View style={styles.timerWrapper}>
                                    <Text style={styles.timeToComplete}>
                                        {i18n.t(
                                            'playScreen.timeLeftToComplete'
                                        )}
                                    </Text>
                                    <Timer
                                        onEndTime={refetchConfig}
                                        timerStyles={styles.timer}
                                        endOfTime={
                                            config?.nextGameIterationDate
                                        }
                                        thresholdTime={60}
                                        thresholdReachedStyles={
                                            styles.timerDanger
                                        }
                                    />
                                </View>
                                <PlayEnergy energy={user?.energy || 0} />
                            </View>
                            <View>
                                <CardFeatureInfo
                                    containerStyle={styles.gap}
                                    title={i18n.t(
                                        'energyInfoScreen.features.first.title',
                                        {
                                            amount: balanceFromWei(
                                                petsEstimatedGameRewardData.singleEnergyEstimatedGameReward
                                            ).valueLong,
                                        }
                                    )}
                                    text={i18n.t(
                                        'energyInfoScreen.features.first.text'
                                    )}
                                    icon={<ChangeIcon width={48} height={48} />}
                                />
                                <CardFeatureInfo
                                    title={i18n.t(
                                        'energyInfoScreen.features.second.title',
                                        {
                                            energy: rewardEnergyThreshold,
                                        }
                                    )}
                                    text={i18n.t(
                                        'energyInfoScreen.features.second.text',
                                        {
                                            energy: rewardEnergyThreshold,
                                        }
                                    )}
                                    icon={
                                        <ExchangeIcon width={48} height={48} />
                                    }
                                />
                            </View>
                        </ScrollView>
                        <ButtonsBottom
                            disabled={!petsCount}
                            title={i18n.t('energyInfoScreen.button')}
                            loading={isLoading}
                            onPress={handlePlay}
                        />
                    </>
                ) : (
                    <LoadingComponent />
                )}
            </SafeAreaView>
            <ToastNotification />
        </View>
    );
};

export default EnergyInfoScreen;
