import { StyleSheet } from 'react-native';

import { moderateScale, scale, verticalScale } from '@helpers/dimensions';

import Colors from '../../../constants/Colors';
import font from '../../../helpers/font';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        safe: {
            backgroundColor: Colors[theme].background,
        },
        container: {
            backgroundColor: Colors[theme].background,
            flex: 1,
        },
        wrapper: {
            backgroundColor: Colors[theme].background,
            paddingHorizontal: scale(20),
        },
        title: {
            ...font({
                size: 22,
                height: 28,
                weight: '700',
            }),
            color: Colors[theme].text.primary,
            marginBottom: verticalScale(8),
        },
        text: {
            ...font({
                size: 15,
                height: 22,
                weight: '400',
            }),
            color: Colors[theme].text.secondary,
        },
        buttonWrapper: {
            marginTop: verticalScale(8),
        },
        listHeader: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: verticalScale(20),
            marginBottom: verticalScale(6),
        },
        listTitle: {
            ...font({
                size: 14,
                height: 20,
                weight: '600',
            }),
            color: Colors[theme].text.primary,
        },
        list: {
            padding: moderateScale(16),
            borderRadius: moderateScale(12),
            borderColor: Colors[theme].border.primary,
            borderWidth: moderateScale(1),
            backgroundColor: Colors[theme].controlSecondary,
            marginBottom: verticalScale(20),
        },
        input: {
            marginBottom: verticalScale(12),
            backgroundColor: Colors[theme].background,
        },
        buttonContainer: {
            paddingBottom: verticalScale(16),
            paddingHorizontal: scale(20),
            borderTopColor: Colors[theme].border.primary,
            borderTopWidth: 1,
        },
        button: {
            marginTop: verticalScale(16),
        },
        textError: {
            marginTop: verticalScale(16),
            color: Colors[theme].text.critical,
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
        },
        textareaInput: {
            marginBottom: verticalScale(8),
            textAlignVertical: 'top',
        },
        buttons: {
            paddingHorizontal: 0,
        },
        privateKeyModal: {
            flex: 1,
        },
        modal: {
            paddingBottom: 0,
        },
    });

export default styles;
