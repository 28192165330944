import { StyleSheet } from 'react-native';

import { scale, verticalScale } from '../../helpers/dimensions';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        petContainer: {},
        buttonContainerModal: {
            marginTop: verticalScale(16),
        },
    });

export default styles;
