import * as React from 'react';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { FlatList } from 'react-native';

import { Rank } from '@Data/Models';
import Button from '@components/Button';
import ButtonsBottom from '@components/ButtonsBottom';
import {
    IStageState,
    STEP_INDICATOR_FLOWS,
} from '@components/FlowStepIndicator';
import { MINT_PET_STAGES_NAME } from '@components/FlowStepIndicator/flows/mintNFTStages';
import { ICON_NAMES } from '@components/Icons';
import InfoIcon from '@components/InfoIcon/InfoIcon';
import LoadingComponent from '@components/LoadingComponent';
import ModalWhatIsRank from '@components/Ranks/ModalWhatIsRank';
import { useConfig } from '@contexts/ConfigContext';
import { MintContext } from '@contexts/MintContext';
import { useRanks } from '@contexts/RanksContext';
import { isWeb } from '@helpers/app';
import { waitForNavigation } from '@helpers/helpers';
import { getHighestAvailableRank } from '@helpers/ranks';
import { dateCooldownExpired } from '@helpers/time';
import { useHandleNavigationBack } from '@hooks/useHandleNavigationBack';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';
import { useFocusEffect } from '@react-navigation/native';

import { MintPetRanksProps } from '../../../types';
import { usePersistStorage } from '../../../vendor/react-native-user-persist-storage';
import MintContainer from '../components/MintContainer';
import MintHeaderText from '../components/MintHeaderText';
import ModalFreeRank from '../components/ModalFreeRank';
import ModalLeave from '../components/ModalLeave';
import PetRankItem from '../components/PetRankItem';
import stylesMain from './styles';

const MintPetRanksScreen = ({ route }: MintPetRanksProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const { canUsePayments } = useConfig();
    const { ranks, ranksLoading, refetch: refetchRank } = useRanks();
    const highestRank = useMemo(
        () => getHighestAvailableRank(ranks, canUsePayments),
        [ranks, canUsePayments]
    );

    const {
        setRank,
        rank: selectedRank,
        editPhoto,
        AIImage,
        setDisabledModalGenerateNewNft,
    } = useContext(MintContext);

    const [isAutoSkip, setIsAutoSkip] = usePersistStorage<boolean>(
        'Mint.isAutoSkip',
        false,
        {
            persist: isWeb,
        }
    );
    useEffect(() => {
        refetchRank();
    }, []);
    useFocusEffect(
        React.useCallback(() => {
            setIsAutoSkip(false);
            return () => setIsAutoSkip(true);
        }, [])
    );

    useEffect(() => {
        if (route.params?.goNext) {
            navigation.setParams({
                goNext: false,
            });
            if (editPhoto) {
                navigation.navigate(ROUTES.MINT_PET_EDIT_PHOTO);
                return;
            }
            navigation.navigate(ROUTES.MINT_PET_SELECT_GENERATION);
        }
    }, [route.params, editPhoto]);

    const [stageFill, setStageFill] = React.useState<IStageState>(
        selectedRank ? '50%' : '25%'
    );
    const [isModalVisible, setModalVisible] = React.useState<boolean>(false);
    const [isModalFreeRankVisible, setIsModalFreeRankVisible] =
        React.useState<boolean>(false);
    const [isModalWhatIsRankVisible, setIsModalWhatIsRankVisible] =
        React.useState<boolean>(false);

    const handleSelectRank = React.useCallback(
        (rank: Rank) => {
            if (JSON.stringify(rank) === JSON.stringify(selectedRank)) {
                return;
            }
            setIsAutoSkip(false);
            setStageFill('50%');
            setRank(rank);
        },
        [selectedRank]
    );

    const handleOpenFullDescription = React.useCallback(
        (rank: Rank) => {
            navigation.navigate(ROUTES.RANKS_DETAILED_INFO, {
                screenConfig: {
                    showAll: false,
                    paginated: true,
                    slug: rank.slug,
                    selectRank: true,
                },
            });
        },
        [selectedRank]
    );

    const { setCanGoBack } = useHandleNavigationBack({
        setModalVisible,
        data: selectedRank,
        autoSkip: isAutoSkip,
    });

    const handleCloseModal = () => {
        setModalVisible(false);
        setCanGoBack(false);
    };

    const handleGoBack = async () => {
        setModalVisible(false);
        await waitForNavigation();
        navigation.goBack();
    };

    const navigateToBonusRankInfo = (rank: Rank) => {
        if (!rank?.mintBonus) {
            return;
        }

        if (!dateCooldownExpired(rank?.mintBonus?.endsAt)) {
            navigation.navigate(ROUTES.BONUS_MINT_RANKS_INFO, {
                screenInfo: {
                    mintBonus: rank?.mintBonus,
                    slug: rank?.slug,
                    withBtn: true,
                },
            });
        }
    };

    const handleGoNext = React.useCallback(
        (skip?: boolean) => {
            if (editPhoto) {
                navigation.navigate(ROUTES.MINT_PET_EDIT_PHOTO);
                return;
            }

            if (AIImage) {
                navigation.navigate(ROUTES.MINT_SELECT_GENERATED_NFT);
                setDisabledModalGenerateNewNft(false);
                return;
            }

            // bonus logic: If there is available mint bonus, and we are not skipping the step, navigate to bonus screen
            const selectedRankHasMintBonus =
                selectedRank?.mintBonus &&
                !dateCooldownExpired(selectedRank?.mintBonus?.endsAt);

            // if highest available rank has mint bonus
            const highestRankHasMintBonus =
                highestRank?.mintBonus &&
                !dateCooldownExpired(highestRank?.mintBonus?.endsAt);

            if (selectedRankHasMintBonus && !skip) {
                // if we choose free rank, show the modal, but do not show it if there are no other options
                navigateToBonusRankInfo(selectedRank);
                return;
            }

            // Free rank bonus modal
            if (
                highestRankHasMintBonus &&
                !skip &&
                selectedRank?.slug === 'free' &&
                canUsePayments
            ) {
                setIsModalFreeRankVisible(true);
                return;
            }

            navigation.navigate(ROUTES.MINT_PET_SELECT_GENERATION);
        },
        [editPhoto, AIImage, selectedRank, canUsePayments, highestRank]
    );

    React.useEffect(() => {
        if (route.params?.rank) {
            handleSelectRank(route.params.rank);
            navigation.setParams({
                rank: null,
            });
        }
        if (route.params?.skip) {
            handleGoNext(true);
            navigation.setParams({
                skip: false,
            });
        }
    }, [route.params, handleGoNext, handleSelectRank]);

    const closeModalWhatIsRank = () => {
        setIsModalWhatIsRankVisible(false);
    };
    const openModalWhatIsRank = () => {
        setIsModalWhatIsRankVisible(true);
    };

    const handleFreeRankProceed = () => {
        setIsModalFreeRankVisible(false);
        navigation.navigate(ROUTES.MINT_PET_SELECT_GENERATION);
    };

    const handleFreeRankToHighest = useCallback(async () => {
        setIsModalFreeRankVisible(false);

        // navigate to the highest possible available for mint rank
        if (
            highestRank &&
            highestRank.mintBonus &&
            !dateCooldownExpired(highestRank?.mintBonus?.endsAt) &&
            highestRank.slug !== 'free'
        ) {
            setRank(highestRank);
            navigateToBonusRankInfo(highestRank);
        } else {
            navigation.navigate(ROUTES.MINT_PET_SELECT_GENERATION);
        }
    }, [highestRank]);

    const onDetailedInfo = () => {
        closeModalWhatIsRank();
        navigation.navigate(ROUTES.RANKS_DETAILED_INFO, {
            screenConfig: {
                showAll: false,
                selectRank: true,
                paginated: true,
            },
        });
    };

    return (
        <>
            <MintContainer
                icon="close"
                flow={STEP_INDICATOR_FLOWS.MINT_PET}
                stage={MINT_PET_STAGES_NAME.STEP_ONE}
                stageFill={stageFill}>
                <FlatList
                    showsVerticalScrollIndicator={false}
                    ListHeaderComponent={
                        <>
                            <MintHeaderText
                                containerStyle={styles.headerText}
                                title={i18n.t('mintPetRank.title')}
                                text={i18n.t('mintPetRank.text')}
                            />
                            <Button
                                title={i18n.t('mintPetRank.info')}
                                iconName={ICON_NAMES.QUESTION}
                                icon={
                                    <InfoIcon
                                        disabled
                                        iconColor={styles.rankInfo.color}
                                    />
                                }
                                type="text"
                                style={styles.rankInfoWrapper}
                                onPress={openModalWhatIsRank}
                            />
                        </>
                    }
                    style={styles.listStyle}
                    data={ranks}
                    renderItem={({ item }) => (
                        <PetRankItem
                            rank={item}
                            selectedRank={selectedRank}
                            onSelectRank={handleSelectRank}
                            onOpenModal={handleOpenFullDescription}
                        />
                    )}
                    ListEmptyComponent={
                        <>{ranksLoading && <LoadingComponent />}</>
                    }
                />
                <ButtonsBottom
                    title={i18n.t('mintPetRank.buttonNext')}
                    onPress={handleGoNext}
                    disabled={!selectedRank}
                />
            </MintContainer>
            <ModalLeave
                isVisible={isModalVisible}
                actionFirst={handleCloseModal}
                actionSecond={handleGoBack}
            />
            <ModalFreeRank
                isVisible={isModalFreeRankVisible}
                highestRank={highestRank}
                actionFirst={handleFreeRankToHighest}
                actionSecond={handleFreeRankProceed}
            />
            <ModalWhatIsRank
                isVisible={isModalWhatIsRankVisible}
                closeModal={closeModalWhatIsRank}
                actionModal={onDetailedInfo}
            />
        </>
    );
};
export default MintPetRanksScreen;
