import { useState } from 'react';

import { useApolloClient } from '@apollo/client';

import { GetRankMeResponse } from '../Data/Models';
import { GET_RANK_ME } from '../Data/Requests';
import { toastErrorUnknown } from '../helpers/toastNotification';

const DEFAULT_FILTER = 'Month';

const useRankMe = () => {
    const client = useApolloClient();

    const [rankMeData, setRankMeData] = useState<GetRankMeResponse>();
    const [loading, setLoading] = useState(true);

    const getRankMe = async () => {
        setLoading(true);
        const result = await client.query<GetRankMeResponse>({
            query: GET_RANK_ME,
            fetchPolicy: 'network-only',
            variables: {
                rankingInterval: DEFAULT_FILTER,
            },
        });
        if (result.error) {
            Console.error(result.error);
            toastErrorUnknown();
        }
        if (result.data) {
            setRankMeData(result.data);
        }
        setLoading(false);
    };

    return {
        getRankMe,
        rankMeData,
        loading,
    };
};

export default useRankMe;
