import * as React from 'react';

import AgeIcon from '@assets/icons/ranks/age.svg';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { Coin, REWARD_UNLOCK_PERCENT } from '@contexts/Wallet/WalletHelpers';
import { priceString } from '@helpers/payments';
import { priceStringWithUSD } from '@helpers/payments';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import CardFeatureInfo from '../../CardFeatureInfo/CardFeatureInfo';
import Icon, { ICON_NAMES } from '../../Icons';
import CoinIcon from '../../Icons/CoinIcon';
import { Text, View } from '../../Themed';
import stylesMain from './styles';

interface IProps {
    title: string;
    text: string;
    reward: string;
    rate: number | string | undefined;
    consumption: number | string;
    rankSlug: string;
    maxAge: number | undefined;
    featureLoading?: boolean;
    unlockReward: string;
}

const RankDetailedInfo = ({
    title,
    text,
    reward,
    rate = 0,
    consumption,
    rankSlug,
    maxAge = 0,
    featureLoading,
    unlockReward,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { priceInUsd } = useWallet();

    const usdPrice = React.useCallback(
        (amount: string, coin: Coin) => {
            return `($${priceInUsd(coin, amount)})`;
        },
        [priceInUsd]
    );

    const isFreeRank = rankSlug === 'free';

    return (
        <View style={styles.container}>
            <View style={styles.textWrapper}>
                <Text style={styles.title}>{title}</Text>
                <Text style={styles.text}>{text}</Text>
            </View>
            <View style={styles.infoWrapper}>
                <CardFeatureInfo
                    loading={featureLoading}
                    containerStyle={styles.gap}
                    title={i18n.t('ranks.rankDetailedInfo.reward.title', {
                        reward: priceString(reward, Coin.igup),
                        usd: usdPrice(reward, Coin.igup),
                    })}
                    text={i18n.t('ranks.rankDetailedInfo.reward.text')}
                    icon={<CoinIcon slug={Coin.igup} size={44} />}
                    warningText={
                        isFreeRank
                            ? i18n.t(
                                  'ranks.rankDetailedInfo.reward.warningFree'
                              )
                            : i18n.t('ranks.rankDetailedInfo.reward.warning')
                    }
                />
                <CardFeatureInfo
                    loading={featureLoading}
                    containerStyle={styles.gap}
                    title={i18n.t('ranks.rankDetailedInfo.rate.title', {
                        reward: priceString(unlockReward, Coin.igup),
                        usd: usdPrice(unlockReward, Coin.igup),
                        rate: `${rate}%`,
                    })}
                    text={i18n.t('ranks.rankDetailedInfo.rate.text', {
                        percent: rate,
                    })}
                    warningText={i18n.t('ranks.rankDetailedInfo.rate.alert')}
                    icon={<Icon name={ICON_NAMES.LOCK_FILL} size={44} />}
                />
                <CardFeatureInfo
                    loading={featureLoading}
                    containerStyle={styles.gap}
                    title={i18n.t('ranks.rankDetailedInfo.consumption.title', {
                        consumption,
                    })}
                    text={i18n.t('ranks.rankDetailedInfo.consumption.text')}
                    icon={<Icon name={ICON_NAMES.HEALTH} size={44} />}
                />
                <CardFeatureInfo
                    loading={featureLoading}
                    containerStyle={styles.gap}
                    title={i18n.t('ranks.rankDetailedInfo.age.title', {
                        maxAge,
                    })}
                    text={i18n.t('ranks.rankDetailedInfo.age.text')}
                    icon={<AgeIcon width={44} height={44} />}
                    warningText={i18n.t('ranks.rankDetailedInfo.age.alert')}
                />
            </View>
        </View>
    );
};

export default RankDetailedInfo;
