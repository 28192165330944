import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';

import { Text, View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';

import stylesMain from './styles';

interface IProps {
    title: string;
    amount: string;
    usdPrice?: string;
    icon: React.ReactElement<SvgProps>;
    containerStyle?: StyleProp<ViewStyle>;
}

const StatisticsCard = ({
    title,
    amount,
    icon,
    containerStyle,
    usdPrice,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={[styles.container, containerStyle]}>
            <View style={styles.imageWrapper}>{icon}</View>
            <Text style={styles.amount} numberOfLines={1}>
                {amount}
            </Text>
            {!!usdPrice && (
                <Text
                    style={styles.usdPrice}
                    numberOfLines={1}>{`≈ ${usdPrice} $`}</Text>
            )}
            <Text style={styles.title}>{title}</Text>
        </View>
    );
};
export default StatisticsCard;
