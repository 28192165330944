import * as React from 'react';
import { Image, StyleProp } from 'react-native';
import { ViewStyle } from 'react-native';

import AlertCard from '@components/AlertCard';
import { Text, View } from '@components/Themed';
import { useUser } from '@contexts/UserContext';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import VSItem from '../VSItem';
import stylesMain from './styles';

interface IProps {
    containerStyle?: StyleProp<ViewStyle>;
    creatorUri: string | undefined | null;
    creatorTitle?: string | undefined;
    creatorLabel: string;
    userTitle?: string;
    userLabel: string;
    userWin?: boolean;
    creatorWin?: boolean;
    creatorWalletAddress: string | undefined;
    alert?: string;
}

const PlayVSBlock = ({
    creatorUri,
    creatorTitle,
    creatorLabel,
    userTitle,
    userLabel,
    userWin,
    creatorWin,
    containerStyle,
    creatorWalletAddress,
    alert,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { user } = useUser();

    return (
        <View style={[styles.container, containerStyle]}>
            <View style={styles.row}>
                {!!user && (
                    <VSItem
                        win={userWin}
                        imageUrl={user.mainPet?.image.thumbnailUrl}
                        walletAddress={user.walletAddress}
                        title={userTitle || user.mainPet?.name}
                        label={userLabel}
                    />
                )}
                <View style={styles.vs}>
                    <Text style={styles.textVs}>
                        {i18n.t('general.KEYS.vs')}
                    </Text>
                </View>
                <VSItem
                    win={creatorWin}
                    imageUrl={creatorUri}
                    walletAddress={creatorWalletAddress}
                    title={creatorTitle}
                    label={creatorLabel}
                />
            </View>
            {!!alert && (
                <AlertCard containerStyle={styles.alert} text={alert} />
            )}
        </View>
    );
};
export default PlayVSBlock;
