import React from 'react';
import { ScrollView, StyleProp } from 'react-native';

import { useDimensions } from '../../contexts/DimensionsContext';
import useThemedStyles from '../../hooks/useThemedStyles';
import CacheImage from '../CacheImage';
import { View, ViewProps } from '../Themed';
import Styles from './styles';

interface IProps {
    children?: React.ReactNode;
    image?: string;
    imageHeight?: number | string;
    contentStyle?: StyleProp<ViewProps>;
}

const ImageHeaderScreen = ({ children, image, imageHeight }: IProps) => {
    const styles = useThemedStyles(Styles);
    const { windowWidth } = useDimensions();

    return (
        <ScrollView
            style={styles.container}
            bounces={false}
            showsVerticalScrollIndicator={false}>
            <View>
                <CacheImage
                    resizeMode="cover"
                    style={{ height: imageHeight ?? windowWidth }}
                    source={image}
                />
            </View>
            <View style={styles.content}>{children}</View>
        </ScrollView>
    );
};
export default ImageHeaderScreen;
