import { StyleSheet } from 'react-native';

import Colors from '../../constants/Colors';
import { moderateScale, scale, verticalScale } from '../../helpers/dimensions';
import font from '../../helpers/font';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(16),
            paddingTop: scale(24),
            paddingBottom: scale(20),
            borderRadius: moderateScale(12),
            alignItems: 'center',
        },
        noPetContainer: {
            paddingTop: scale(48),
        },
        headerText: {
            marginTop: verticalScale(12),
        },
        qrCodeImage: {
            width: 148,
            height: 148,
            padding: 14,
            backgroundColor: 'white',
            borderRadius: moderateScale(12),
        },
        qrCodeTitle: {
            textAlign: 'center',
            color: Colors[theme].text.onLight,
            ...font({
                size: 22,
                height: 30,
                weight: '800',
            }),
            marginBottom: verticalScale(6),
            textTransform: 'uppercase',
        },
        qrCodeText: {
            textAlign: 'center',
            color: Colors[theme].text.onLight,
            ...font({
                size: 13,
                height: 18,
                weight: '400',
            }),
        },

        cardWrapper: {
            width: '100%',
            marginTop: verticalScale(12),
        },
        card: {
            width: '100%',
            paddingHorizontal: scale(10),
            paddingVertical: verticalScale(10),
            backgroundColor: Colors[theme].backgroundOpacity,
            borderRadius: moderateScale(8),
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        petImage: {
            borderRadius: 6,
            width: 40,
            height: 40,
        },
        textWrapper: { marginLeft: scale(12), flex: 1, alignItems: 'flex-end' },
        value: {
            color: Colors[theme].text.onLight,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
        },
        key: {
            color: Colors[theme].text.onLight,
            ...font({
                size: 12,
                height: 16,
                weight: '600',
            }),
        },
        gap: {
            marginTop: verticalScale(6),
        },
        shadow: {
            backgroundColor: Colors[theme].shadow.default,
            borderRadius: 1000,
            position: 'absolute',
            top: verticalScale(-32),
        },
        avatar: {
            paddingHorizontal: scale(10),
            paddingVertical: verticalScale(4),
            borderRadius: 1000,
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            marginBottom: verticalScale(2),
            backgroundColor: Colors[theme].background,
        },
    });

export default styles;
