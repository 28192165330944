import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import {
    moderateScale,
    scale,
    verticalScale,
} from '../../../../../helpers/dimensions';
import font from '../../../../../helpers/font';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            backgroundColor: Colors[theme].background,
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            paddingHorizontal: scale(12),
            paddingVertical: verticalScale(12),
            marginBottom: verticalScale(4),
        },
        shadow: {
            width: 150,
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].shadow.default,
            marginBottom: verticalScale(16),
        },
        imgWrapper: {
            backgroundColor: Colors[theme].background,
            borderWidth: 1,
            borderRadius: 50,
            borderColor: Colors[theme].border.primary,
            paddingHorizontal: scale(4),
            paddingVertical: verticalScale(4),
            alignSelf: 'flex-start',
            marginTop: verticalScale(6),
            marginLeft: scale(6),
        },
        textWrapper: {
            marginTop: verticalScale(8),
        },

        imgBg: {
            height: 120,
            borderRadius: moderateScale(6),
        },
        wrapper: {},

        infoTitle: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '700',
            }),
        },
        infoAmount: {
            color: Colors[theme].text.primary,
            ...font({
                size: 13,
                height: 18,
                weight: '600',
            }),
        },
        infoWrapper: {
            flexDirection: 'row',
            alignItems: 'center',
        },
        roiWrapper: {
            flexDirection: 'row',
            alignContent: 'center',
        },
        divider: {
            width: 5,
            height: 5,
            borderRadius: 1000,
            backgroundColor: Colors[theme].petRankItem.divider,
            marginHorizontal: scale(6),
        },
        mintPriceContainer: {
            position: 'absolute',
            top: verticalScale(-4),
            right: scale(-4),
            paddingHorizontal: scale(6),
            paddingVertical: verticalScale(2),
            flexDirection: 'row',
            borderRadius: 50,
        },
    });

export default styles;
