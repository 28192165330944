import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            marginTop: verticalScale(24),
        },
        headerText: {
            marginBottom: verticalScale(12),
        },
        title: {
            color: Colors[theme].text.primary,
            ...font({
                size: 18,
                height: 24,
                weight: '700',
            }),
            marginBottom: verticalScale(6),
        },
        text: {
            color: Colors[theme].text.secondary,
            ...font({
                size: 14,
                height: 20,
                weight: '400',
            }),
            letterSpacing: 0.08,
        },

        cardWrapper: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        cardWidth: {
            width: '48%',
        },
        content: {
            paddingHorizontal: 0,
            marginTop: verticalScale(16),
        },
    });

export default styles;
