import React from 'react';
import { Linking } from 'react-native';

import Constants from 'expo-constants';

import { openUrlOrShowError } from '@helpers/linking';

import WebVersionIcon from '../../../../../../assets/icons/profile/webVersion.svg';
import { toastErrorUnknown } from '../../../../../../helpers/toastNotification';
import i18n from '../../../../../../i18n/i18n';
import { getWebAppAuthUrl } from '../../../../../../navigation/helpers';
import MenuItem from '../../../MenuItem';
import { iconSize } from '../../helpers';

export const webAppUrl = Constants?.expoConfig?.extra?.webAppUrl;

const WebVersionMenuItem = () => {
    const openWebVersion = async () => {
        const url = await getWebAppAuthUrl();
        openUrlOrShowError(url).catch(() => {
            toastErrorUnknown();
        });
    };

    return (
        <MenuItem
            leftIcon={<WebVersionIcon width={iconSize} height={iconSize} />}
            title={i18n.t('profile.menuItems.webVersion.title')}
            description={i18n.t('profile.menuItems.webVersion.description')}
            onPress={openWebVersion}
        />
    );
};

export default WebVersionMenuItem;
