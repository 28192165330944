import { StyleSheet } from 'react-native';

import { stylesAlignCenter } from '@helpers/style';

import Colors from '../../constants/Colors';
import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        loadingWrapper: {
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: 10,
            backgroundColor: Colors[theme].background,
        },
        image: {
            width: '100%',
            aspectRatio: 1,
        },
        loading: {
            ...stylesAlignCenter(),
        },
    });

export default styles;
