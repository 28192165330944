import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import { Pet, TokenTransaction } from '../../../../../Data/Models';
import ExplorerLink from '../../../../../components/ExplorerLink';
import StatusIcon from '../../../../../components/Icons/StatusIcon';
import TransactionStatusIcon from '../../../../../components/Icons/TransactionTypeIcon';
import { Text, View } from '../../../../../components/Themed';
import { dateFormatter } from '../../../../../helpers/time';
import { balanceFromWei } from '../../../../../helpers/wallet';
import useThemedStyles from '../../../../../hooks/useThemedStyles';
import i18n from '../../../../../i18n/i18n';
import ROUTES from '../../../../../navigation/routes';
import { useNavigation } from '../../../../../navigation/useNavigation';
import stylesMain from './styles';

interface IProps {
    item: TokenTransaction;
    pets: Array<Pet> | undefined;
}

const NFTSTransactionItem = ({ item, pets }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const {
        type,
        timeStamp,
        tokenID,
        txCost,
        from: addressFrom,
        to: addressTo,
        status,
    } = item;

    const date = dateFormatter(+timeStamp);

    const pet = pets?.find((pet) => pet.tokenId === tokenID);
    const fee = balanceFromWei(txCost).valueLong.toString();

    const statusIcon = status !== 'CONFIRMED' && <StatusIcon slug={status} />;

    return (
        <TouchableOpacity
            activeOpacity={0.7}
            onPress={() =>
                navigation.navigate(ROUTES.WALLET_NFTS_TRANSACTION_DETAILS, {
                    transaction: {
                        date,
                        type,
                        addressFrom,
                        addressTo,
                        fee,
                        status: item.status,
                        explorerUrl: item.explorerUrl,
                        pet,
                        tokenID,
                    },
                })
            }>
            <View style={styles.itemContainer}>
                <View style={styles.left}>
                    <View style={styles.icon}>
                        <TransactionStatusIcon type={type} />
                        <View style={styles.statusIcon}>{statusIcon}</View>
                    </View>
                </View>
                <View style={styles.right}>
                    <Text style={styles.label} numberOfLines={2}>
                        {i18n.t(`wallet.transactions.nft.${item.type}.name`)}
                        {` #${tokenID}`}
                    </Text>
                    <View style={styles.bottom}>
                        <Text style={styles.dateText}>{date}</Text>
                        <ExplorerLink
                            explorerUrl={item.explorerUrl}
                            linkStyles={styles.bscScan}
                            iconType={'secondary'}
                        />
                    </View>
                </View>
            </View>
        </TouchableOpacity>
    );
};

export const MemoizedNFTSTransactionItem = React.memo(NFTSTransactionItem);
