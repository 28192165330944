import * as React from 'react';
import { Linking, StyleProp, TouchableOpacity, ViewStyle } from 'react-native';

import { A } from '@expo/html-elements';

import { Text, View } from '../../components/Themed';
import Colors from '../../constants/Colors';
import { useConfig } from '../../contexts/ConfigContext';
import { useTheme } from '../../contexts/ThemeContext';
import useThemedStyles from '../../hooks/useThemedStyles';
import i18n from '../../i18n/i18n';
import Icon, { ICON_NAMES } from '../Icons';
import stylesMain from './styles';

interface IProps {
    explorerUrl?: string;
    walletAddress?: string;
    linkStyles?: StyleProp<ViewStyle>;
    containerStyles?: StyleProp<ViewStyle>;
    iconType?: 'secondary' | 'accent';
    showIcon?: boolean;
    title?: string;
}

const ExplorerLink = ({
    explorerUrl,
    walletAddress,
    linkStyles,
    containerStyles,
    iconType = 'accent',
    showIcon = true,
    title,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { theme } = useTheme();
    const { config } = useConfig();

    const link =
        explorerUrl || `${config?.blockExplorerURL}/address/${walletAddress}`;

    return (
        <View style={[styles.buttonWrapper, containerStyles]}>
            <TouchableOpacity style={styles.buttonInner}>
                <A href={link} style={[styles.buttonLink, linkStyles]}>
                    {title || i18n.t('wallet.viewOnBscScan')}
                </A>
                {showIcon && (
                    <Icon
                        name={ICON_NAMES.EXPLORE}
                        size={16}
                        color={Colors[theme].text[iconType]}
                    />
                )}
            </TouchableOpacity>
        </View>
    );
};
export default ExplorerLink;
