import * as React from 'react';

import Svg from '../../assets/icons/general/cooldown.svg';
import { IconType, iconSize } from './types';

const Cooldown: React.FunctionComponent<IconType> = ({
    size = iconSize.default,
}) => {
    return <Svg width={size} height={size} />;
};

export default Cooldown;
