import React from 'react';

import AdminBlockUserIcon from '@assets/icons/profile/admin/blockUser.svg';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import MenuItem from '../../../MenuItem';
import { RightComponent, iconSize } from '../../helpers';
import stylesMain from './styles';

const AdminBlockUserMenuItem = () => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    return (
        <MenuItem
            leftIcon={<AdminBlockUserIcon width={iconSize} height={iconSize} />}
            title={i18n.t('profile.menuItems.adminBlockUser.title')}
            description={i18n.t('profile.menuItems.adminBlockUser.description')}
            rightComponent={<RightComponent type="arrowRight" />}
            onPress={() => navigation.navigate(ROUTES.ADMIN_BLOCK_USER)}
            titleStyle={styles.titleStyle}
            textStyle={styles.textStyle}
        />
    );
};

export default AdminBlockUserMenuItem;
