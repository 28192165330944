import * as React from 'react';

import CircleDivider from '@components/CircleDivider';
import OtherUserAvatar from '@components/OtherUserAvatar';
import { View } from '@components/Themed';
import TypoText from '@components/TypoText';
import { TYPO_TEXT } from '@components/TypoText/types';
import { DECLINATIONS, getDeclination } from '@helpers/declination';
import { scale } from '@helpers/dimensions';
import { balanceFromWei, truncateEthAddress } from '@helpers/wallet';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import {
    LUCKY_STRIKE_GAMES_STATUS,
    LuckyStrikeGameParticipantRowOutput,
} from '@models/mysteryGames';

import GameAmount from '../GameAmount';
import stylesMain from './styles';

interface IProps {
    item: LuckyStrikeGameParticipantRowOutput;
    prize: string;
}

const LuckyStrikeParticipantItem = ({ item, prize }: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const subText = React.useMemo(
        () =>
            `${item.ticketsCount} ${getDeclination(
                item.ticketsCount,
                DECLINATIONS.Ticket
            )}`,
        [item]
    );

    const isWinner = React.useMemo(() => item.isWinner, [item.isWinner]);

    return (
        <View style={[styles.itemContainer]}>
            <View style={styles.left}>
                <View style={styles.imageWrapper}>
                    <OtherUserAvatar
                        size={scale(48)}
                        imageUrl={item.avatarUrl}
                    />
                </View>
                <View style={styles.infoBlock}>
                    <TypoText
                        type={TYPO_TEXT.BODY_BIG}
                        ellipsizeMode={'middle'}
                        numberOfLines={1}
                        additionalStyles={styles.removeAlign}>
                        {truncateEthAddress(item.username)}
                    </TypoText>
                    <View style={styles.sub}>
                        {isWinner && (
                            <>
                                <TypoText
                                    type={TYPO_TEXT.SUBHEAD_LITTLE_SEMIBOLD}
                                    ellipsizeMode={'middle'}
                                    numberOfLines={1}>
                                    {i18n.t('general.KEYS.winner')}
                                </TypoText>
                                <CircleDivider />
                            </>
                        )}
                        <TypoText
                            type={TYPO_TEXT.SUBHEAD_LITTLE_SEMIBOLD}
                            ellipsizeMode={'middle'}
                            numberOfLines={1}>
                            {subText}
                        </TypoText>
                    </View>
                </View>
            </View>

            <View style={styles.right}>
                <GameAmount
                    status={
                        isWinner ? LUCKY_STRIKE_GAMES_STATUS.Won : undefined
                    }
                    amount={
                        balanceFromWei(isWinner ? prize : item.iguAmountWei)
                            .valueLong
                    }
                />
            </View>
        </View>
    );
};
export default LuckyStrikeParticipantItem;
