import * as React from 'react';
import { SafeAreaView } from 'react-native';

import { useWallet } from '@contexts/Wallet/WalletContext';
import { waitWallet } from '@helpers/helpers';

import Button from '../../../components/Button';
import ModalLoader from '../../../components/ModalLoader';
import { Text, View } from '../../../components/Themed';
import WalletSeedPhrase from '../../../components/WalletSeedPhrase';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import NavigationBar from '../../../navigation/NavigationBar';
import ROUTES from '../../../navigation/routes';
import { useNavigation } from '../../../navigation/useNavigation';
import stylesMain from './styles';

const CreateWalletPassphraseScreen: React.FunctionComponent = () => {
    const styles = useThemedStyles(stylesMain);
    const { tmpWalletData, generate } = useWallet();
    const navigation = useNavigation();
    const [isLoading, setIsLoading] = React.useState(true);

    const [data, setData] = React.useState<string[] | undefined>([]);

    const generateWords = async () => {
        await waitWallet();
        generate();
        setIsLoading(false);
    };

    React.useEffect(() => {
        generateWords();
        return () => setData([]);
    }, [generate]);

    React.useEffect(() => {
        if (isLoading) return;
        const dataSource = tmpWalletData?.wallet?.mnemonic.phrase.split(/\s+/);
        setData(dataSource);
    }, [tmpWalletData, isLoading]);

    return (
        <>
            <NavigationBar filled={false} wallet={false} backIcon />
            <View style={styles.wrapper}>
                <Text style={styles.title}>
                    {i18n.t('createWalletPassphrase.title')}
                </Text>
                <Text style={styles.text}>
                    {i18n.t('createWalletPassphrase.text')}
                </Text>
                <WalletSeedPhrase mnemonic={data} />
                <Button
                    title={i18n.t('createWalletPassphrase.button')}
                    onPress={() =>
                        navigation.push(ROUTES.CREATE_WALLET_VERIFICATION)
                    }
                    disabled={isLoading}
                />
            </View>
            <ModalLoader
                isVisible={isLoading}
                text={i18n.t('createWalletPassphrase.loading')}
            />
            <SafeAreaView style={styles.safe} />
        </>
    );
};

export default CreateWalletPassphraseScreen;
