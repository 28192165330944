import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        itemContainer: {
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: moderateScale(12),
            marginBottom: verticalScale(12),
            marginHorizontal: scale(20),
        },

        left: {
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
            backgroundColor: 'transparent',
        },
        right: {
            flexDirection: 'row',
            alignItems: 'center',

            backgroundColor: 'transparent',
        },
        sub: {
            backgroundColor: 'transparent',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: verticalScale(2),
        },
        roundName: {
            maxWidth: '70%',
        },
        tickets: {
            maxWidth: '30%',
        },

        imageWrapper: {
            width: scale(48),
            height: scale(48),
            borderRadius: moderateScale(6),
            backgroundColor: 'transparent',
        },
        infoBlock: {
            marginLeft: scale(12),
            marginRight: scale(12),
            flex: 1,
            backgroundColor: 'transparent',
        },

        list: {
            marginTop: verticalScale(12),
        },

        active: {
            backgroundColor: Colors[theme].shadow.selected,
        },
        activeContainer: {
            borderColor: Colors[theme].border.accent,
        },
    });

export default styles;
