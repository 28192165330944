import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import Icon from '@assets/icons/mysteryGames/LuckyStrike/participants.svg';
import CardFeatureInfo from '@components/CardFeatureInfo/CardFeatureInfo';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';

interface IProps {
    containerStyle?: StyleProp<ViewStyle>;
    type?: 'default' | 'opacity';
    text: string;
    handlePress?: () => void;
}

const LuckyStrikeParticipants = ({
    containerStyle,
    type = 'default',
    text,
    handlePress,
}: IProps) => {
    return (
        <CardFeatureInfo
            type={type}
            containerStyle={containerStyle}
            icon={<Icon width={48} height={48} />}
            title={i18n.t(
                `HomeScreen.${MYSTERY_GAMES.luckyStrike}.LuckyStrikeParticipants.title`,
                {
                    text,
                }
            )}
            text={i18n.t(
                `HomeScreen.${MYSTERY_GAMES.luckyStrike}.LuckyStrikeParticipants.text`
            )}
            onAdditionalButton={handlePress}
        />
    );
};
export default LuckyStrikeParticipants;
