import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import CardFeatureInfo from '@components/CardFeatureInfo/CardFeatureInfo';
import CoinIcon from '@components/Icons/CoinIcon';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { priceString } from '@helpers/payments';
import i18n from '@i18n/i18n';

interface IProps {
    containerStyle?: StyleProp<ViewStyle>;
    amount?: string;
}

const BreedCost = ({ containerStyle, amount }: IProps) => {
    return (
        <CardFeatureInfo
            containerStyle={containerStyle}
            icon={<CoinIcon slug={Coin.vigu} size={48} />}
            title={i18n.t('HomeScreen.NftEvolution.breedCost.title', {
                amount: amount ? ` = ${priceString(amount, Coin.vigu)}` : '',
            })}
            text={i18n.t('HomeScreen.NftEvolution.breedCost.text')}
            warningText={i18n.t('HomeScreen.NftEvolution.breedCost.alert')}
        />
    );
};
export default BreedCost;
