import { statisticsFormatter } from '@helpers/helpers';
import { balanceFromWei, valueToWei } from '@helpers/wallet';

export const calculateTotalSummary = (
    boughtValue: string,
    soldValue: string
) => {
    const value =
        Number(balanceFromWei(boughtValue).value) +
        Number(balanceFromWei(soldValue).value);

    return balanceFromWei(valueToWei(value)).valueShort;
};

export const calculateTotalSummaryUSD = (
    boughtValueUSD: string,
    soldValueUSD: string
) => {
    return (Number(boughtValueUSD) + Number(soldValueUSD))
        .toFixed(2)
        .toString();
};

export const calculateTotalOrders = (
    boughtCount: number,
    soldCount: number
) => {
    return statisticsFormatter(boughtCount + soldCount);
};

export const toStringUSD = (usd: string) => {
    return `≈ ${Number(usd).toFixed(2)} $`;
};
