/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';

import LoadingComponent from '@components/LoadingComponent';
import PlayNFTBlock from '@components/PlayNFTBlock';
import PlayNFTBlockFooter from '@components/PlayNFTBlockFooter';
import PlayNFTBlockHeader from '@components/PlayNFTBlockHeader';
import PullToRefresh from '@components/PullToRefresh';
import SafeAreaCurvedTabContent from '@components/SafeAreaCurvedTabContent';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';

import { usePlayScreen } from './hook';
import stylesMain from './styles';

export const PlayScreen = () => {
    const styles = useThemedStyles(stylesMain);
    const { petsLoaded, userLoading, onRefresh, playablePets } =
        usePlayScreen();

    const renderHeader = () => <PlayNFTBlockHeader />;
    const renderFooter = () => <PlayNFTBlockFooter />;

    return (
        <SafeAreaCurvedTabContent>
            {!petsLoaded && <LoadingComponent />}

            <PullToRefresh
                contentContainerStyle={styles.container}
                refreshing={userLoading}
                textLoading={i18n.t('pullToRefresh.reloadPets.text')}
                onRefresh={onRefresh}
                renderItem={({ item }) => (
                    <PlayNFTBlock key={item.id} item={item} />
                )}
                renderData={playablePets}
                canRefresh={petsLoaded}
                HeaderComponent={renderHeader()}
                FooterComponent={renderFooter()}
            />
        </SafeAreaCurvedTabContent>
    );
};
export default PlayScreen;
