import React from 'react';

import CustomModal from '@components/CustomModal';
import ImageIguana from '@components/ImageIguana';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';

export const useModalsHook = () => {
    const {
        isVisible: visibilityAddMoreCoinsModal,
        open: openAddMoreCoinsModal,
        close: closeAddMoreCoinsModal,
    } = useVisibleHook();
    const {
        isVisible: visibilityUpgradePlanModal,
        open: openUpgradePlanModal,
        close: closeUpgradePlanModal,
    } = useVisibleHook();

    const renderCoinsModal = React.useCallback(
        (action: () => void) => {
            return (
                <CustomModal
                    isVisible={visibilityAddMoreCoinsModal}
                    icon={<ImageIguana type="attention" />}
                    titleText={i18n.t(
                        'staking.alreadyScreen.staked.modalAddCoins.title'
                    )}
                    infoText={i18n.t(
                        'staking.alreadyScreen.staked.modalAddCoins.text'
                    )}
                    firstButtonText={i18n.t(
                        'staking.alreadyScreen.staked.modalAddCoins.button1'
                    )}
                    secondButtonText={i18n.t(
                        'staking.alreadyScreen.staked.modalAddCoins.button2'
                    )}
                    onFirstButtonClick={action}
                    onSecondButtonClick={closeAddMoreCoinsModal}
                />
            );
        },
        [visibilityAddMoreCoinsModal]
    );
    const renderUpgradeModal = React.useCallback(
        (action: () => void) => {
            return (
                <CustomModal
                    isVisible={visibilityUpgradePlanModal}
                    icon={<ImageIguana type="attention" />}
                    titleText={i18n.t(
                        'staking.alreadyScreen.staked.modalUpgrade.title'
                    )}
                    infoText={i18n.t(
                        'staking.alreadyScreen.staked.modalUpgrade.text'
                    )}
                    firstButtonText={i18n.t(
                        'staking.alreadyScreen.staked.modalUpgrade.button1'
                    )}
                    secondButtonText={i18n.t(
                        'staking.alreadyScreen.staked.modalUpgrade.button2'
                    )}
                    onFirstButtonClick={action}
                    onSecondButtonClick={closeUpgradePlanModal}
                />
            );
        },
        [visibilityUpgradePlanModal]
    );

    return {
        openUpgradePlanModal,
        openAddMoreCoinsModal,
        renderCoinsModal,
        renderUpgradeModal,
        closeAddMoreCoinsModal,
        closeUpgradePlanModal,
    };
};
