import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            paddingHorizontal: scale(20),
            marginBottom: 32,
        },
        headerBock: {
            flexDirection: 'row',
            alignItems: 'center',
            alignContent: 'center',
            marginBottom: verticalScale(12),
            justifyContent: 'space-between',
        },
        header: {
            flex: 1,
            ...font({
                size: 18,
                height: 24,
                weight: '700',
            }),
            color: Colors[theme].text.primary,
            marginRight: scale(12),
        },
    });

export default styles;
