import * as React from 'react';

import { ICON_NAMES } from '@components/Icons';
import { View } from '@components/Themed';
import useThemedStyles from '@hooks/useThemedStyles';
import { MYSTERY_GAMES } from '@models/mysteryGames';
import NavigationQuantumSpin, {
    NAVIGATION_QUANTUM_SPIN,
} from '@navigation/NavigationBar/NavigationQuantumSpin';

import GamesNavigation from '../../components/GamesNavigation';
import QuantumSpinWheel from '../../components/QuantumSpinWheel';
import useQuantumSpinMainScreen from './hooks/useQuantumSpinMainScreen';
import stylesMain from './styles';

const QuantumSpinMainScreen = () => {
    const styles = useThemedStyles(stylesMain);
    const {
        onHowItWorksPress,
        wheelVisible,
        isWheelAnimation,
        setIsWheelAnimation,
        quantumSpinGameConfig,
        quantumSpinGamePrizes,
    } = useQuantumSpinMainScreen();

    return (
        <View style={styles.main}>
            <GamesNavigation
                backIconName={ICON_NAMES.CLOSE}
                type={MYSTERY_GAMES.quantumSpin}
                isOpacityBg
                disabled={isWheelAnimation}
                handleOnInfoIconPress={onHowItWorksPress}
                buttonComponent={
                    <NavigationQuantumSpin
                        disabled={isWheelAnimation}
                        currentNavigation={NAVIGATION_QUANTUM_SPIN.WHEEL}
                    />
                }
            />
            {!!quantumSpinGameConfig?.spinPriceIguAmountWei &&
                !!quantumSpinGamePrizes && (
                    <QuantumSpinWheel
                        wheelVisible={wheelVisible}
                        prizes={quantumSpinGamePrizes}
                        spinAmount={quantumSpinGameConfig.spinPriceIguAmountWei}
                        isWheelAnimation={isWheelAnimation}
                        setIsWheelAnimation={setIsWheelAnimation}
                    />
                )}
        </View>
    );
};

export default QuantumSpinMainScreen;
