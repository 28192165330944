import React, { useCallback } from 'react';

import _ from 'lodash';

import { useApolloClient, useLazyQuery } from '@apollo/client';
import { errorsHandler } from '@helpers/errors';
import { wait } from '@helpers/helpers';
import { useLoadingHook } from '@hooks/useLoadingHook';
import {
    TokentellerAvailableGameOutput,
    TokentellerAvailableGameOutputResponse,
    TokentellerPendingGameOutputResponse,
} from '@models/mysteryGames';
import {
    GET_TOKEN_TELLER_AVAILABLE_GAMES,
    GET_TOKEN_TELLER_PENDING_GAMES,
} from '@requests/tokenTeller';

export const useTokenTellerAvailableGames = () => {
    const client = useApolloClient();

    const {
        isLoading: gamesLoading,
        startLoading: startGamesLoading,
        stopLoading: stopGamesLoading,
    } = useLoadingHook();

    const {
        isLoading: refreshLoading,
        startLoading: startRefreshLoading,
        stopLoading: stopRefreshLoading,
    } = useLoadingHook();

    const {
        isLoading: refetchLoading,
        startLoading: startRefetchLoading,
        stopLoading: stopRefetchLoading,
    } = useLoadingHook();

    const [games, setGames] =
        React.useState<TokentellerAvailableGameOutput[]>();
    const [getPendingGames] =
        useLazyQuery<TokentellerPendingGameOutputResponse>(
            GET_TOKEN_TELLER_PENDING_GAMES,
            {
                fetchPolicy: 'no-cache',
            }
        );

    const updateGame = async (games: TokentellerAvailableGameOutput[]) => {
        const pendingGames = await getPendingGames();
        if (!!pendingGames.data?.tokentellerMyPendingGames.length) {
            return games.map((game) => {
                const pendingGame =
                    pendingGames.data?.tokentellerMyPendingGames.find(
                        (i) => i.id === game.id
                    );

                return !!pendingGame?.prediction
                    ? {
                          ...game,
                          isPredicted: true,

                          predictedDirection: pendingGame.prediction.direction,

                          predictedAmountWei:
                              pendingGame.prediction.iguAmountWei,
                      }
                    : {
                          ...game,
                          isPredicted: false,
                          predictedDirection: null,
                          predictedAmountWei: null,
                      };
            });
        }
        return games;
    };

    const getGames = useCallback(
        async (silent: boolean) => {
            try {
                silent ? startGamesLoading() : startRefreshLoading();

                const result =
                    await client.query<TokentellerAvailableGameOutputResponse>({
                        query: GET_TOKEN_TELLER_AVAILABLE_GAMES,
                        fetchPolicy: 'no-cache',
                    });

                if (result.data) {
                    const games = await updateGame(
                        result.data.tokentellerAvailableGames
                    );

                    setGames(games);
                    stopRefreshLoading();
                    stopGamesLoading();
                    return games;
                }
                stopRefreshLoading();
                stopGamesLoading();
            } catch (error) {
                stopRefreshLoading();
                stopGamesLoading();
                errorsHandler(error, true);
            }
        },
        [client]
    );
    const refetchGames = useCallback(async () => {
        try {
            startRefetchLoading();
            Console.info('fetch');
            const result =
                await client.query<TokentellerAvailableGameOutputResponse>({
                    query: GET_TOKEN_TELLER_AVAILABLE_GAMES,
                    fetchPolicy: 'no-cache',
                });

            if (result.data) {
                const games = await updateGame(
                    result.data.tokentellerAvailableGames
                );
                setGames(games);
                stopRefetchLoading();
                return games;
            }
            stopRefetchLoading();
        } catch (error) {
            stopRefetchLoading();
            errorsHandler(error, true);
        }
    }, [client]);

    return {
        getGames,
        games,
        gamesLoading,
        refreshLoading,
        refetchGames,
        refetchLoading,
    };
};
