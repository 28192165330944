import * as React from 'react';
import { useEffect, useRef } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

import Lottie, { LottieRefCurrentProps } from 'lottie-react';

import { useAppLoading } from '../../contexts/AppLoading/AppLoadingContext';
import { AnimationType } from './types';

interface IProps {
    animation: AnimationType;
    style?: StyleProp<ViewStyle>;
    isPlaying?: boolean;
}

const Animation = ({ animation, style = {}, isPlaying = true }: IProps) => {
    const animationRef = useRef<LottieRefCurrentProps | null>(null);
    const { loadedLotties } = useAppLoading();

    useEffect(() => {
        if (loadedLotties && loadedLotties[animation]?.speed) {
            animationRef?.current?.setSpeed(loadedLotties[animation].speed);
        }
    }, [loadedLotties, animationRef, animation]);

    if (!loadedLotties || !loadedLotties[animation]) {
        return <></>;
    }

    return (
        <View style={[style, { flex: 1 }]}>
            <Lottie
                lottieRef={animationRef}
                animationData={loadedLotties[animation].asset}
                loop={isPlaying}
            />
        </View>
    );
};

export default Animation;
