import { CREATE_MYSTERY_LIKE_GAME } from '@Data/Requests';
import { useMutation } from '@apollo/client';
import { MysteryLikesCreateGameResponse } from '@models/mysteryGames';

export const useCreateMysteryLikeGame = () => {
    const [createMysteryGame, { loading: createdMysteryLikesGameLoading }] =
        useMutation<MysteryLikesCreateGameResponse>(CREATE_MYSTERY_LIKE_GAME);

    return {
        createMysteryGame,
        createdMysteryLikesGameLoading,
    };
};
