import { StyleSheet } from 'react-native';

import Colors from '../../../../../constants/Colors';
import { verticalScale } from '../../../../../helpers/dimensions';
import { theme } from '../../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        bottomLevelIndicator: {
            marginVertical: verticalScale(16),
            backgroundColor: 'transparent',
        },
        rankUpButton: {
            color: Colors[theme].text.accent,
        },
        buttonStyle: {
            backgroundColor: Colors[theme].text.onLight,
            borderColor: Colors[theme].border.white,
        },
        buttonWrapper: {
            marginTop: verticalScale(16),
            backgroundColor: 'transparent',
        },
        buttonContainerStyle: {
            backgroundColor: Colors[theme].shadow.white,
        },
        iconAccent: {
            color: Colors[theme].text.accent,
        },
    });

export default styles;
