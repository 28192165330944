import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { moderateScale, scale, verticalScale } from '@helpers/dimensions';
import font from '@helpers/font';

import { theme } from '../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        shadow: {
            borderRadius: moderateScale(12),
            backgroundColor: Colors[theme].shadow.default,
            marginBottom: verticalScale(16),
        },
        selectedShadow: {
            backgroundColor: Colors[theme].shadow.selected,
        },

        itemContainer: {
            backgroundColor: Colors[theme].background,
            borderRadius: moderateScale(12),
            borderWidth: 1,
            borderColor: Colors[theme].border.primary,
            paddingHorizontal: scale(16),
            paddingVertical: verticalScale(16),
            marginBottom: verticalScale(4),
            flexDirection: 'row',
            alignItems: 'center',
        },

        selected: {
            borderColor: Colors[theme].border.accent,
        },
        text: {
            color: Colors[theme].text.primary,
            ...font({
                size: 16,
                height: 24,
                weight: '700',
            }),
        },
    });

export default styles;
