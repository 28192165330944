import { useCallback } from 'react';

import { useDimensions } from '@contexts/DimensionsContext';
import { isWeb } from '@helpers/app';
import { useFocusEffect } from '@react-navigation/native';

import ScreenOrientationManager from '../utils/ScreenOrientationManager';

export const useDefaultScreenOrientation = () => {
    const { setIsRotated, setWebContainerIsVisible } = useDimensions();
    useFocusEffect(
        useCallback(() => {
            if (isWeb) {
                ScreenOrientationManager.setDefault();
                setIsRotated(false);
                setWebContainerIsVisible(true);
            }
        }, [])
    );

    return {};
};
