import { StyleSheet } from 'react-native';

import Colors from '../../../constants/Colors';
import { scale, verticalScale } from '../../../helpers/dimensions';
import { theme } from '../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        container: {
            flex: 1,
        },
        backgroundImageWrapper: {
            marginHorizontal: scale(20),
            marginBottom: verticalScale(16),
            // fix for https://github.com/iguverse/tasks-app/issues/590, disables text selection on quick clicks on the zoom buttons or the background
            userSelect: 'none',
        },
        buttonWrapper: {
            paddingHorizontal: scale(16),
            paddingTop: verticalScale(16),
        },
        carouselWrapper: {
            paddingHorizontal: scale(20),
        },
    });

export default styles;
