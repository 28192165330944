import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import TooltipGuideRN from 'react-native-walkthrough-tooltip';

import { isString } from 'lodash';

import { scale } from '@helpers/dimensions';

import useThemedStyles from '../../hooks/useThemedStyles';
import { ComponentAnyType } from '../../types';
import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    children: React.PropsWithChildren<unknown>['children'];
    content: string | ComponentAnyType;
    placement?: 'top' | 'bottom';
    showChildInTooltip?: boolean;
    closeOnChildInteraction?: boolean;
    childrenWrapperStyle?: StyleProp<ViewStyle>;
    isOneColor?: boolean;
    isVisible: boolean;
    onClose: () => void;
    parentWrapperStyle?: StyleProp<ViewStyle>;
}

const TooltipOnBoarding = ({
    children,
    content = '',
    placement = 'top',
    showChildInTooltip = false,
    closeOnChildInteraction = false,
    childrenWrapperStyle,
    isOneColor,
    isVisible,
    onClose,
    parentWrapperStyle,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const [width, setWidth] = React.useState<number>(0);

    return (
        <TooltipGuideRN
            isVisible={isVisible}
            onClose={onClose}
            content={
                <>
                    <View
                        style={[
                            styles.shadow,
                            isOneColor && styles.isOneColorShadow,
                        ]}></View>
                    <View
                        style={[
                            styles.contentView,
                            isOneColor && styles.isOneColorContent,
                        ]}>
                        {content}
                    </View>
                </>
            }
            placement={placement}
            contentStyle={[
                styles.content,
                { width: 'auto' },
                isOneColor && styles.isOneColorContent,
            ]}
            arrowStyle={styles.arrow}
            childContentSpacing={7}
            showChildInTooltip={showChildInTooltip}
            closeOnChildInteraction={closeOnChildInteraction}
            childrenWrapperStyle={childrenWrapperStyle}
            disableShadow
            parentWrapperStyle={parentWrapperStyle}>
            {children}
        </TooltipGuideRN>
    );
};

export default TooltipOnBoarding;
