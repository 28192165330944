import * as React from 'react';
import { useMemo } from 'react';
import { View } from 'react-native';

import { Rank } from '@Data/Models';
import Health from '@assets/icons/general/health.svg';
import AgeIcon from '@assets/icons/ranks/age.svg';
import Button from '@components/Button';
import CircleDivider from '@components/CircleDivider';
import Icon, { ICON_NAMES } from '@components/Icons';
import CoinIcon from '@components/Icons/CoinIcon';
import RankIcon from '@components/Icons/RankIcon';
import InfoIcon from '@components/InfoIcon/InfoIcon';
import ModalNFTRankLocked from '@components/Ranks/ModalNFTRankLocked';
import { Text } from '@components/Themed';
import TouchableOpacity from '@components/TouchableOpacity';
import { useConfig } from '@contexts/ConfigContext';
import { useTheme } from '@contexts/ThemeContext';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import {
    formatBonusReward,
    formatDailyReward,
    getDailyFoodConsumption,
    isAvailableForMint,
    ranksConfig,
} from '@helpers/ranks';
import { stylesPriceInUsd } from '@helpers/style';
import { balanceFromWei } from '@helpers/wallet';
import { useHaptic } from '@hooks/useHaptic';
import useThemedStyles from '@hooks/useThemedStyles';
import { useVisibleHook } from '@hooks/useVisibleHook';
import i18n from '@i18n/i18n';

import MintMysteryBox from '../MintMysteryBox';
import MintPriceBackground from '../MintPriceBackground';
import MintWhiteList from '../MintWhiteList';
import stylesMain from './styles';

interface IProps {
    rank: Rank;
    selectedRank: Rank | null;
    onSelectRank: (rank: Rank) => void;
    onOpenModal: (rank: Rank) => void;
}

const PetRankItem = ({
    rank,
    selectedRank,
    onSelectRank,
    onOpenModal,
}: IProps) => {
    const { theme } = useTheme();
    const styles = useThemedStyles(stylesMain);

    const {
        isVisible: modalRankLocked,
        open: openModalRankLocked,
        close: closeModalRankLocked,
    } = useVisibleHook();

    const [isTooltipOpened, setIsTooltipOpened] =
        React.useState<boolean>(false);

    const { canUsePayments } = useConfig();
    const { walletBalance, priceInUsd } = useWallet();
    const { triggerHeavy } = useHaptic();

    const { slug, available } = rank;

    const isFreeRank = slug === 'free';

    const isAvailable = isAvailableForMint(rank, canUsePayments);
    const showPrice = isFreeRank || (Number(rank.mintPriceUsd) && isAvailable);

    const isFromLootBox = React.useMemo(
        () => !!rank.payments.find((o) => o.token === 'LOOTBOX'),
        [rank]
    );
    const igupUsdPrice = useMemo(() => {
        if (!walletBalance) {
            return 0;
        }

        return priceInUsd(
            walletBalance?.igup.name,
            balanceFromWei(rank.baseIgupRewardWei).value
        );
    }, [walletBalance, rank, priceInUsd]);

    const unlockAmount = useMemo(
        () => formatBonusReward(rank.dailyUnlockRate, rank.baseIgupRewardWei),
        [rank]
    );

    const unlockUsdPrice = useMemo(() => {
        if (!walletBalance) {
            return 0;
        }

        return priceInUsd(walletBalance?.igup.name, unlockAmount);
    }, [walletBalance, rank, priceInUsd, unlockAmount]);

    const isSelected = React.useMemo(
        () => selectedRank?.slug === rank.slug,
        [selectedRank, rank.slug]
    );

    return (
        <TouchableOpacity
            activeOpacity={0.7}
            style={[styles.shadow, isSelected && styles.selectedShadow]}
            onPress={() => isAvailable && onSelectRank(rank)}
            onLongPress={() => {
                triggerHeavy();
                onOpenModal(rank);
            }}>
            <View style={[styles.container, isSelected && styles.selected]}>
                <View style={styles.wrapper}>
                    {rank.whitelistFreeMintsCount ? (
                        <MintWhiteList
                            amount={rank.whitelistFreeMintsCount}
                            customStyle={styles.mintPriceContainer}
                        />
                    ) : showPrice ? (
                        isFromLootBox ? (
                            <MintMysteryBox
                                amount={rank.lootboxFreeMintsCount}
                                customStyle={styles.mintPriceContainer}
                            />
                        ) : (
                            <MintPriceBackground
                                rank={rank}
                                customStyle={styles.mintPriceContainer}
                                withMargin={false}
                            />
                        )
                    ) : null}

                    <View style={styles.imgWrapper}>
                        <RankIcon size={64} slug={slug} />
                    </View>

                    <View style={styles.textWrapper}>
                        <View style={styles.titleWrapper}>
                            <Text style={styles.title}>
                                {i18n.t(`ranks.list.${slug}.title`)}
                            </Text>
                        </View>
                        <View style={styles.infoWrapper}>
                            <View style={styles.infoBlock}>
                                <CoinIcon slug={Coin.igup} size={20} />
                                <Text style={styles.infoAmount}>
                                    {`+${formatDailyReward(
                                        rank.baseIgupRewardWei
                                    )}`}
                                    <Text style={stylesPriceInUsd(theme)}>
                                        {` ≈ ${igupUsdPrice} $`}
                                    </Text>
                                </Text>
                                <CircleDivider />
                                <Text style={styles.textDesc}>
                                    {i18n.t('ranks.perDay')}
                                </Text>
                            </View>
                            <View style={styles.infoBlock}>
                                <Icon name={ICON_NAMES.LOCK_FILL} />
                                <Text style={styles.infoAmount}>
                                    {`+${unlockAmount} (${rank.dailyUnlockRate}%)`}
                                    <Text style={stylesPriceInUsd(theme)}>
                                        {` ≈ ${unlockUsdPrice} $`}
                                    </Text>
                                </Text>

                                <CircleDivider />
                                <Text style={styles.textDesc} numberOfLines={1}>
                                    {i18n.t('ranks.dailyUnlock')}
                                </Text>
                            </View>
                            <View style={styles.infoBlock}>
                                <Health width={20} height={20} />
                                <Text style={styles.infoAmount}>
                                    {getDailyFoodConsumption(
                                        rank.rewardConsumptionPercentage
                                    )}
                                </Text>
                                <CircleDivider />
                                <Text style={styles.textDesc}>
                                    {i18n.t('ranks.foodConsumption')}
                                </Text>
                            </View>
                            <View style={styles.infoBlock}>
                                <AgeIcon width={20} height={20} />
                                <Text style={styles.infoAmount}>
                                    {`${rank.maxAgeDays} ${i18n.t(
                                        'ranks.days'
                                    )}`}
                                </Text>
                                <CircleDivider />
                                <Text style={styles.textDesc}>
                                    {i18n.t('ranks.maxAge')}
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
            {isFreeRank ? (
                <>
                    {!available && (
                        <View style={styles.rankLocked}>
                            <View style={styles.opacityBlock} />

                            <Button
                                onPress={() => setIsTooltipOpened(true)}
                                type="outline"
                                size="md"
                                icon={
                                    <InfoIcon
                                        isOpened={isTooltipOpened}
                                        setIsTooltipOpened={setIsTooltipOpened}
                                        tooltipContent={i18n.t(
                                            'mintPetRank.disabled'
                                        )}
                                        iconSize={20}
                                    />
                                }
                            />
                        </View>
                    )}
                </>
            ) : (
                <>
                    {!isAvailable && (
                        <>
                            <View style={styles.rankLocked}>
                                <View style={styles.opacityBlock} />

                                <Button
                                    onPress={openModalRankLocked}
                                    iconName={ICON_NAMES.LOCK}
                                    size="md"
                                    type="outline"
                                />
                            </View>
                            <ModalNFTRankLocked
                                closeAction={closeModalRankLocked}
                                isVisible={modalRankLocked}
                                rankSlug={slug}
                            />
                        </>
                    )}
                </>
            )}
        </TouchableOpacity>
    );
};
export default PetRankItem;
