import * as React from 'react';

import { useConfig } from '@contexts/ConfigContext';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import MarketPlaceActiveOrdersScreen from '../../screens/TabBar/Marketplace/MarketPlaceActiveOrders/MarketPlaceActiveOrdersScreen';
import MarketPlaceMyProfileScreen from '../../screens/TabBar/Marketplace/MarketPlaceMyProfile/MarketPlaceMyProfileScreen';
import MarketPlaceNoTradeAvalibleScreen from '../../screens/TabBar/Marketplace/MarketPlaceNoTradeAvalible/MarketPlaceNoTradeAvalibleScreen';
import { MarketPlaceTabsParamList } from '../../types';
import ROUTES from '../routes';

const MarketPlaceTabsStack =
    createBottomTabNavigator<MarketPlaceTabsParamList>();

export const MarketPlaceTabsNavigator = () => {
    const { isTradeAvailable } = useConfig();

    return (
        <MarketPlaceTabsStack.Navigator
            screenOptions={{
                headerShown: false,
            }}
            initialRouteName={ROUTES.MARKETPLACE_ACTIVE_ORDERS}
            backBehavior="none"
            tabBar={() => null}>
            {isTradeAvailable ? (
                <>
                    <MarketPlaceTabsStack.Screen
                        name={ROUTES.MARKETPLACE_ACTIVE_ORDERS}
                        component={MarketPlaceActiveOrdersScreen}
                    />
                    <MarketPlaceTabsStack.Screen
                        name={ROUTES.MARKETPLACE_MY_PROFILE}
                        component={MarketPlaceMyProfileScreen}
                    />
                </>
            ) : (
                <MarketPlaceTabsStack.Screen
                    name={ROUTES.MARKETPLACE_ACTIVE_ORDERS}
                    component={MarketPlaceNoTradeAvalibleScreen}
                />
            )}
        </MarketPlaceTabsStack.Navigator>
    );
};
