import { StyleSheet } from 'react-native';

import Colors from '@constants/Colors';
import { scale, verticalScale } from '@helpers/dimensions';

import { theme } from '../../../../types';

const styles = (theme: theme) =>
    StyleSheet.create({
        scrollContainer: {
            backgroundColor: Colors[theme].background,
        },
        container: {
            flex: 1,
            backgroundColor: Colors[theme].background,
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            padding: scale(20),
        },
        node: {
            marginVertical: verticalScale(20),
        },
        icon: {
            color: Colors[theme].icon.onDark,
        },
    });

export default styles;
