import * as React from 'react';

import StepOneImage from '@assets/icons/mysteryGames/LuckyStrike/howLuckyStrikeWork/stepOne.svg';
import StepThreeImage from '@assets/icons/mysteryGames/LuckyStrike/howLuckyStrikeWork/stepThree.svg';
import StepTwoImage from '@assets/icons/mysteryGames/LuckyStrike/howLuckyStrikeWork/stepTwo.svg';
import BlockHeader from '@components/BlockHeader/BlockHeader';
import ButtonsBottom from '@components/ButtonsBottom';
import Carousel from '@components/Carousel';
import CarouselScrollView from '@components/Carousel/components/CarouselScrollView';
import { View } from '@components/Themed';
import { useDimensions } from '@contexts/DimensionsContext';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';

import HashSecuritySystem from '../HashSecuritySystem';
import stylesMain from './styles';

const data = ['stepOne', 'stepTwo', 'stepThree'];
const flow = 'LuckyStrikeHowItWorks';

interface IProps {
    onButtonPress: () => void;
}

const LuckyStrikeHowItWorks = ({ onButtonPress }: IProps) => {
    const { windowWidth } = useDimensions();
    const styles = useThemedStyles(stylesMain);

    const renderItem = (item: string) => {
        const icon = () => {
            switch (item) {
                case 'stepOne':
                    return <StepOneImage />;
                case 'stepTwo':
                    return <StepTwoImage />;

                default:
                    return <StepThreeImage />;
            }
        };

        return (
            // <CarouselScrollView bounces={false}>
            <>
                <View style={styles.itemContainer}>
                    <View style={styles.content}>
                        <BlockHeader
                            isOnlyWhite
                            type="emptyState"
                            emptyStateIcon={icon()}
                            title={i18n.t(
                                `HomeScreen.${MYSTERY_GAMES.luckyStrike}.${flow}.${item}.title`
                            )}
                            text={i18n.t(
                                `HomeScreen.${MYSTERY_GAMES.luckyStrike}.${flow}.${item}.text`
                            )}
                        />
                    </View>
                </View>
                <HashSecuritySystem
                    type="opacity"
                    containerStyle={styles.feature}
                />
            </>
            // </CarouselScrollView>
        );
    };

    return (
        <>
            <Carousel
                data={data}
                sliderWidth={windowWidth}
                itemWidth={windowWidth}
                renderItem={renderItem}
                fullHeight
                pagination
                centredVertical
                bulletActiveStyle={styles.bulletActive}
                bulletStyle={styles.bulletStyle}
            />
            <View style={styles.buttonWrapper}>
                <ButtonsBottom
                    isOneColor
                    title={i18n.t('HomeScreen.exploreGames')}
                    onPress={onButtonPress}
                    safe
                    containerStyle={styles.button}
                    buttonType="outline"
                />
            </View>
        </>
    );
};

export default LuckyStrikeHowItWorks;
