import * as React from 'react';

import { View } from '../../../components/Themed';
import useThemedStyles from '../../../hooks/useThemedStyles';
import i18n from '../../../i18n/i18n';
import ROUTES from '../../routes';
import { useNavigation } from '../../useNavigation';
import NavigationItem from '../NavigationItem';
import stylesMain from './styles';

interface IProps {
    currentNavigation: NavigationReferralsType;
}

export type NavigationReferralsType = 'COLLECTIONS' | 'MYSTERY';

const NavigationCollections = ({ currentNavigation }: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const handleOnCollectionPress = () => {
        if (currentNavigation === 'COLLECTIONS') {
            return;
        }

        navigation.navigate(ROUTES.COLLECTION_MAIN);
    };

    const handleOnMysteryPress = () => {
        if (currentNavigation === 'MYSTERY') {
            return;
        }

        navigation.navigate(ROUTES.MYSTERY_BOX_MAIN);
    };

    return (
        <View style={styles.shadow}>
            <View style={styles.container}>
                <NavigationItem
                    onPress={handleOnCollectionPress}
                    title={i18n.t('navigationBar.collections.collection')}
                    isActive={currentNavigation === 'COLLECTIONS'}
                    containerStyles={styles.containerItem}
                />
                <NavigationItem
                    onPress={handleOnMysteryPress}
                    title={i18n.t('navigationBar.collections.mystery')}
                    isActive={currentNavigation === 'MYSTERY'}
                    containerStyles={styles.containerItem}
                />
            </View>
        </View>
    );
};
export default NavigationCollections;
