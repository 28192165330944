import { IStage } from '..';

export enum MARKETPLACE_CREATE_ORDER_NAME {
    STEP_ONE = 'STEP_ONE',
    STEP_TWO = 'STEP_TWO',
}

type IMarketplaceCreateOrder = {
    STEP_ONE: IStage;
    STEP_TWO: IStage;
};

export const marketplaceCreateOrder: IMarketplaceCreateOrder = {
    STEP_ONE: {
        customFillIndex: 1,
        fill: ['0%', '0%'],
    },

    STEP_TWO: {
        customFillIndex: 2,
        fill: ['100%', '0%'],
    },
};
