import { ApolloError } from '@apollo/client';
import { isGraphqlError } from '@helpers/helpers';
import { toastError } from '@helpers/toastNotification';
import { useNavigation } from '@navigation/useNavigation';

import i18n from '../../i18n/i18n';

const useTasksErrorsHandler = () => {
    const navigation = useNavigation();

    const navigateToTasks = () => {
        navigation.navigateToTasks();
    };

    const taskWrongState = (error: ApolloError) => {
        if (isGraphqlError(error, 'TASK_WRONG_STATE')) {
            navigateToTasks();
            toastError(
                i18n.t('general.errors.TASK_WRONG_STATE.title'),
                i18n.t('general.errors.TASK_WRONG_STATE.message')
            );
            return true;
        }

        if (isGraphqlError(error, 'TASK_CREATE_FORBIDDEN')) {
            navigateToTasks();
            toastError(
                i18n.t('general.errors.TASK_CREATE_FORBIDDEN.title'),
                i18n.t('general.errors.TASK_CREATE_FORBIDDEN.message')
            );
            return true;
        }

        return false;
    };

    const taskLostConnection = () => {
        toastError(
            i18n.t('AppState.NoNetworkConnectionState.title'),
            i18n.t('AppState.NoNetworkConnectionState.text')
        );
    };

    return { taskWrongState, taskLostConnection };
};

export default useTasksErrorsHandler;
