import React, { useMemo } from 'react';
import { ScrollView } from 'react-native';

import BlockHeader from '@components/BlockHeader/BlockHeader';
import Button from '@components/Button';
import Carousel from '@components/Carousel';
import GestureRootView from '@components/GestureRootView';
import ModalLoader from '@components/ModalLoader';
import SafeAreaCurvedTabContent from '@components/SafeAreaCurvedTabContent';
import { Text, View } from '@components/Themed';
import { useCharity } from '@contexts/CharityContexts';
import { useDimensions } from '@contexts/DimensionsContext';
import { useUser } from '@contexts/UserContext';
import { isWeb } from '@helpers/app';
import { scale } from '@helpers/dimensions';
import useSetCharity from '@hooks/useSetCharity';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import CharityPercent from '../components/CharityPercent';
import OrganizationCard from '../components/OrganizationCard';
import stylesMain from './styles';

const CharityMainScreen = () => {
    const { charities } = useCharity();
    const { user } = useUser();
    const userCharity = useMemo(() => user?.charity, [user]);
    const userPercents = useMemo(() => user?.charityDonationPercentage, [user]);

    const styles = useThemedStyles(stylesMain);
    const { windowWidth } = useDimensions();
    const navigation = useNavigation();
    const [isScroll, setIsScroll] = React.useState(false);

    const { savePercents, isSetCharityLoading } = useSetCharity();

    return (
        <SafeAreaCurvedTabContent>
            <GestureRootView>
                <ScrollView
                    style={styles.scrollContainer}
                    showsVerticalScrollIndicator={false}>
                    <View style={styles.container}>
                        <BlockHeader
                            title={i18n.t('charity.title')}
                            text={i18n.t('charity.description')}
                            textInfo={i18n.t('charity.howItWorks')}
                            type="primary"
                            containerStyle={styles.containerStyle}
                            textInfoOnPress={() =>
                                navigation.navigate(ROUTES.HOW_TO_CHARITY)
                            }
                        />

                        {userPercents && (
                            <CharityPercent
                                defaultPercents={userPercents}
                                onSave={savePercents}
                            />
                        )}

                        <View style={styles.selectedOrganizationContainer}>
                            <Text style={styles.subtitle}>
                                {i18n.t('charity.selectedOrganization')}
                            </Text>
                            <Button
                                type={'text'}
                                title={i18n.t('charity.edit')}
                                onPress={() =>
                                    navigation.navigate(
                                        ROUTES.CHARITY_EDIT_SELECTED_ORGANIZATION
                                    )
                                }
                            />
                        </View>
                        {userCharity && (
                            <OrganizationCard charity={userCharity} />
                        )}
                    </View>
                    <View style={styles.divider} />
                    <View
                        style={[
                            styles.selectedOrganizationContainer,
                            styles.paddingHorizontal,
                        ]}>
                        <Text style={styles.subtitle}>
                            {i18n.t('charity.organizations')}
                        </Text>
                        <Button
                            type="text"
                            title={i18n.t('charity.viewAll')}
                            onPress={() =>
                                navigation.navigate(
                                    ROUTES.CHARITY_ORGANIZATIONS
                                )
                            }
                        />
                    </View>
                    <Carousel
                        containerStyle={styles.marginBottom}
                        data={charities}
                        renderItem={(item) => (
                            <OrganizationCard
                                disabled={isScroll}
                                key={item.id}
                                charity={item}
                            />
                        )}
                        sliderWidth={windowWidth}
                        height={430}
                        itemWidth={windowWidth - scale(20 * 2)}
                        inactiveSlideOpacity={0.3}
                        inactiveSlideScale={0.925}
                        onScrollBegin={() => isWeb && setIsScroll(true)}
                        onScrollEnd={() => isWeb && setIsScroll(false)}
                    />
                </ScrollView>
            </GestureRootView>

            <ModalLoader
                isVisible={isSetCharityLoading}
                text={i18n.t('charity.loadingPercentage')}
            />
        </SafeAreaCurvedTabContent>
    );
};

export default CharityMainScreen;
