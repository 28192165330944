import * as React from 'react';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import ReferralsAllInvitedScreen from '../../screens/TabBar/Referrals/ReferralsAllInvited/ReferralsAllInvitedScreen';
import ReferralsMainScreen from '../../screens/TabBar/Referrals/ReferralsMain/ReferralsMainScreen';
import { ReferralsTabsParamList } from '../../types';
import ROUTES from '../routes';

const ReferralsTabsStack = createBottomTabNavigator<ReferralsTabsParamList>();

export const ReferralsTabsNavigator = () => {
    return (
        <ReferralsTabsStack.Navigator
            screenOptions={{
                headerShown: false,
            }}
            initialRouteName={ROUTES.REFERRALS_INVITES}
            backBehavior="none"
            tabBar={() => null}>
            <ReferralsTabsStack.Screen
                initialParams={{ refId: undefined }}
                name={ROUTES.REFERRALS_INVITES}
                component={ReferralsMainScreen}
            />
            <ReferralsTabsStack.Screen
                name={ROUTES.REFERRALS_ALL}
                component={ReferralsAllInvitedScreen}
            />
        </ReferralsTabsStack.Navigator>
    );
};
