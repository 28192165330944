import * as React from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';

import { debounce } from 'lodash';

import ActivityIndicator from '@components/ActivityIndicator';
import { useHaptic } from '@hooks/useHaptic';

import useThemedStyles from '../../hooks/useThemedStyles';
import AlertCard from '../AlertCard';
import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    title?: string;
    text: string;
    icon?: React.ReactElement<SvgProps>;
    warningText?: string;
    containerStyle?: StyleProp<ViewStyle>;
    children?: React.ReactNode;
    loading?: boolean;
    onPress: () => void;
}

const CardFeatureInfoButton = ({
    title,
    text,
    icon,
    warningText,
    containerStyle,
    children,
    loading,
    onPress,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    const { triggerMedium } = useHaptic();
    const onPressRef = React.useRef(onPress);

    React.useEffect(() => {
        onPressRef.current = onPress;
    }, [onPress]);

    const handlePress = async () => {
        await triggerMedium();
        onPressRef.current();
    };

    // debounce callback
    const handleDebounce = React.useCallback(
        debounce(
            () => {
                handlePress();
            },
            2000,
            {
                leading: true,
                trailing: false,
            }
        ),
        [triggerMedium]
    );

    return (
        <TouchableOpacity
            style={[styles.shadow, containerStyle]}
            activeOpacity={0.8}
            onPress={handleDebounce}>
            <View style={styles.infoItem}>
                {loading && (
                    <View style={styles.loading}>
                        <ActivityIndicator loading={loading} size="large" />
                    </View>
                )}
                <View style={styles.top}>
                    <View style={styles.left}>
                        <Text style={styles.infoTitle}>
                            {children || title}
                        </Text>
                        <Text style={styles.infoText}>{text}</Text>
                    </View>
                    {!!icon && icon}
                </View>
                {!!warningText && (
                    <AlertCard
                        containerStyle={styles.warningBlock}
                        text={warningText}
                    />
                )}
            </View>
        </TouchableOpacity>
    );
};

export default CardFeatureInfoButton;
