import React, { useEffect } from 'react';
import { SafeAreaView, ScrollView, StyleProp, ViewProps } from 'react-native';

import ButtonsBottom from '@components/ButtonsBottom';
import { useWallet } from '@contexts/Wallet/WalletContext';
import useThemedStyles from '@hooks/useThemedStyles';

import { ComponentAnyType } from '../../types';
import Animation from '../Animation';
import { AnimationType } from '../Animation/types';
import Button from '../Button';
import ExplorerLink from '../ExplorerLink';
import { Text, View } from '../Themed';
import stylesMain from './styles';

interface IProps {
    title: string;
    text: string;
    buttonMainText: string;
    buttonAdditionalText?: string;
    TransactionDetailsComponent?: ComponentAnyType;
    transactionDetailsStyle?: StyleProp<ViewProps>;
    explorerUrl?: string;
    onPressButtonMain: () => void;
    onPressButtonAdditional?: () => void;
    containerStyle?: StyleProp<ViewProps>;
    animation?: AnimationType;
    buttonLoading?: boolean;
    buttonDisabled?: boolean;
    AdditionalComponent?: ComponentAnyType;
}

const CheckoutSuccessScreen = ({
    title,
    text,
    buttonMainText,
    buttonAdditionalText,
    TransactionDetailsComponent,
    explorerUrl,
    transactionDetailsStyle,
    onPressButtonMain,
    onPressButtonAdditional,
    containerStyle,
    animation = 'dancing',
    buttonDisabled,
    buttonLoading,
    AdditionalComponent,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const { reloadBalance, getUserBalance } = useWallet();

    const refreshBalances = () => {
        reloadBalance(true);
        getUserBalance(true);
    };

    useEffect(() => {
        refreshBalances();

        return () => {
            refreshBalances();
        };
    }, []);

    const handlePressButtonMain = () => {
        onPressButtonMain();
    };

    const handlePressButtonAdditional = () => {
        if (onPressButtonAdditional) {
            onPressButtonAdditional();
        }
    };

    return (
        <SafeAreaView style={[styles.safe, containerStyle]}>
            <ScrollView showsVerticalScrollIndicator={false}>
                <View style={styles.animationContainer}>
                    <Animation animation={animation} style={styles.animation} />
                </View>

                <View style={styles.textWrapper}>
                    <Text style={[styles.title, styles.align]}>{title}</Text>
                    <Text style={[styles.text, styles.align]}>{text}</Text>
                </View>
                {AdditionalComponent}
                <View style={styles.container}>
                    <View
                        style={[
                            styles.transactionDetailsWrapper,
                            transactionDetailsStyle,
                        ]}>
                        {TransactionDetailsComponent}
                        {!!explorerUrl && (
                            <ExplorerLink
                                explorerUrl={explorerUrl}
                                containerStyles={styles.explorerLinkContainer}
                            />
                        )}
                    </View>
                </View>
            </ScrollView>
            <ButtonsBottom
                onPress={handlePressButtonMain}
                title={buttonMainText}
                loading={buttonDisabled}
                disabled={buttonLoading}>
                {/* Additional button if exists */}
                {onPressButtonAdditional && (
                    <View style={styles.additionalButton}>
                        <Button
                            loading={buttonDisabled}
                            disabled={buttonLoading}
                            debouncedPress
                            type="outline"
                            onPress={handlePressButtonAdditional}
                            title={buttonAdditionalText}
                        />
                    </View>
                )}
            </ButtonsBottom>
        </SafeAreaView>
    );
};

export default CheckoutSuccessScreen;
