import * as React from 'react';

import FlagIcons from '../../../../components/Icons/FlagIcons';
import Input from '../../../../components/Input';
import { Text, View } from '../../../../components/Themed';
import useThemedStyles from '../../../../hooks/useThemedStyles';
import i18n from '../../../../i18n/i18n';
import stylesMain from './styles';

const config = {
    maxTitleLength: 20,
    maxBodyLength: 100,
    numberOfLines: 10,
};

interface IProps {
    valueLanguage: string;
    valueTitle: string | undefined;
    onChangeTitle: (text: string) => void;
    valueBody: string | undefined;
    onChangeBody: (text: string) => void;
    errorTitle: string | boolean | null | undefined;
    errorBody: string | boolean | null | undefined;
    onTouchedTitle: () => void;
    onTouchedBody: () => void;
}

const NotificationForm = ({
    valueLanguage,
    valueTitle,
    valueBody,
    onChangeBody,
    onChangeTitle,
    errorTitle,
    errorBody,
    onTouchedTitle,
    onTouchedBody,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);

    return (
        <View style={styles.container}>
            <View>
                <Text style={styles.label}>
                    {i18n.t(
                        'profile.adminSendPushNotification.selectLanguage.label'
                    )}
                </Text>
                <View style={styles.langBlock}>
                    <View style={styles.right}>
                        <View style={styles.icon}>
                            <FlagIcons slug={valueLanguage} size={24} />
                        </View>
                        <Text style={styles.placeHolder}>
                            {i18n.t(
                                `profile.languageSettings.options.${valueLanguage}.name`
                            )}
                        </Text>
                    </View>
                </View>
            </View>
            <View style={styles.titleInputWrapper}>
                <Input
                    value={valueTitle}
                    error={errorTitle}
                    onChangeText={onChangeTitle}
                    onBlur={onTouchedTitle}
                    placeholder={i18n.t(
                        'profile.adminSendPushNotification.titleInput.placeHolder'
                    )}
                    label={i18n.t(
                        'profile.adminSendPushNotification.titleInput.label',
                        {
                            amount: config.maxTitleLength,
                        }
                    )}
                    maxLength={config.maxTitleLength}
                />
            </View>
            <View style={styles.bodyInputWrapper}>
                <Input
                    value={valueBody}
                    error={errorBody}
                    onChangeText={onChangeBody}
                    onBlur={onTouchedBody}
                    placeholder={i18n.t(
                        'profile.adminSendPushNotification.bodyInput.placeHolder'
                    )}
                    label={i18n.t(
                        'profile.adminSendPushNotification.bodyInput.label',
                        {
                            amount: config.maxBodyLength,
                        }
                    )}
                    maxLength={config.maxBodyLength}
                    numberOfLines={config.numberOfLines}
                    multiline
                    style={styles.input}
                />
            </View>
        </View>
    );
};

export default NotificationForm;
