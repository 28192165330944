import * as React from 'react';
import { FlatList, Pressable } from 'react-native';

import Carousel from '@components/Carousel';
import { View } from '@components/Themed';
import { isDesktop } from '@helpers/app';
import useThemedStyles from '@hooks/useThemedStyles';

import stylesMain from './styles';

interface IProps {
    backgrounds: {
        id: string;
        color: string;
    }[];
    selectedColorID: string;
    handleBgColor: (id: string) => void;
}

interface IItem {
    item: {
        id: string;
        color: string;
    };
}

const ReferralColorPicker = ({
    backgrounds,
    selectedColorID,
    handleBgColor,
}: IProps) => {
    const styles = useThemedStyles(stylesMain);
    const [isScroll, setIsScroll] = React.useState(false);
    const [isPressIn, setIsPressIn] = React.useState(false);

    const onItemPress = ({ item }: IItem) => {
        if (!isScroll) {
            handleBgColor(item.id);
        }
        setIsScroll(false);
        setIsPressIn(false);
    };

    const renderItem = ({ item }: IItem) => {
        const isSelected = item.id === selectedColorID;
        return (
            <Pressable
                onPress={() => onItemPress({ item })}
                onPressIn={() => setIsPressIn(true)}
                style={[styles.shadow, isSelected && styles.activeShadow]}>
                <View style={[styles.renderItem, isSelected && styles.active]}>
                    <View
                        style={[
                            styles.fill,
                            { backgroundColor: item.color },
                        ]}></View>
                </View>
            </Pressable>
        );
    };

    return (
        <>
            {isDesktop ? (
                <Carousel
                    data={[...backgrounds]}
                    renderItem={(item) => renderItem({ item })}
                    itemWidth={51}
                    height={45}
                    pagingEnabled={false}
                    snapEnabled={false}
                    windowSize={10}
                    panGestureProps={{
                        onBegan: () =>
                            isDesktop && isPressIn && setIsScroll(true),
                    }}
                    carouselStyle={{ justifyContent: 'flex-start' }}
                />
            ) : (
                <FlatList
                    pagingEnabled={false}
                    initialNumToRender={backgrounds.length}
                    horizontal
                    showsHorizontalScrollIndicator={false}
                    data={[...backgrounds]}
                    renderItem={({ item }) => renderItem({ item })}
                />
            )}
        </>
    );
};
export default ReferralColorPicker;
