import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import CardFeatureInfo from '@components/CardFeatureInfo/CardFeatureInfo';
import CoinIcon from '@components/Icons/CoinIcon';
import { useWallet } from '@contexts/Wallet/WalletContext';
import { Coin } from '@contexts/Wallet/WalletHelpers';
import { priceStringWithUSD } from '@helpers/payments';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';

interface IProps {
    containerStyle?: StyleProp<ViewStyle>;
    type?: 'default' | 'opacity';
    amount: string;
}

const LuckyStrikePricePool = ({
    containerStyle,
    type = 'default',
    amount,
}: IProps) => {
    const { priceInUsd } = useWallet();

    const text = React.useMemo(
        () => priceStringWithUSD(priceInUsd, amount, Coin.vigu, Coin.igu),
        [amount, priceInUsd]
    );

    return (
        <CardFeatureInfo
            type={type}
            containerStyle={containerStyle}
            icon={<CoinIcon slug={Coin.vigu} size={48} />}
            title={i18n.t(
                `HomeScreen.${MYSTERY_GAMES.luckyStrike}.LuckyStrikePricePool.title`,
                {
                    text,
                }
            )}
            text={i18n.t(
                `HomeScreen.${MYSTERY_GAMES.luckyStrike}.LuckyStrikePricePool.text`
            )}
        />
    );
};
export default LuckyStrikePricePool;
