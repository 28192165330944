import * as React from 'react';
import { FlatList } from 'react-native';

import EmptyList from '@components/EmptyList';
import { ICON_NAMES } from '@components/Icons';
import LoadingComponent from '@components/LoadingComponent';
import { View } from '@components/Themed';
import { stylesAlignCenter, stylesLoadMore } from '@helpers/style';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import { MYSTERY_GAMES } from '@models/mysteryGames';
import NavigationBarModal from '@navigation/NavigationBar/NavigationBarModal';
import useNavigationListeners from '@navigation/hooks/useNavigationListeners';
import { useNavigation } from '@navigation/useNavigation';

import LuckyStrikeWinnerItem from '../../components/LuckyStrikeWinnerItem';
import LuckyStrikeWinners from '../../components/LuckyStrikeWinners';
import { useLuckyStrikeGamesWinners } from '../../hooks/useLuckyStrikeGamesWinners';
import stylesMain from './styles';

const LuckyStrikeAllWinnersScreen = () => {
    useNavigationListeners();

    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();

    const {
        getWinners,
        winners,
        winnersLoading,
        loadMoreWinners,
        getWinnersLoadMore,
    } = useLuckyStrikeGamesWinners();

    React.useEffect(() => {
        getWinners(true);
    }, []);

    const handleBack = () => {
        navigation.pop();
    };

    return (
        <View style={styles.main}>
            <View style={styles.header}>
                <NavigationBarModal
                    isOneColor
                    iconName={ICON_NAMES.CLOSE}
                    title={i18n.t(
                        `HomeScreen.${MYSTERY_GAMES.luckyStrike}.keys.winners`
                    )}
                    onBack={handleBack}
                    titleStyle={styles.title}
                />
            </View>
            {winnersLoading ? (
                <LoadingComponent containerStyles={styles.loading} />
            ) : (
                <View style={styles.listWrapper}>
                    {!!winners?.length && (
                        <>
                            <FlatList
                                onEndReached={getWinnersLoadMore}
                                ListHeaderComponent={
                                    <LuckyStrikeWinners
                                        containerStyle={styles.texts}
                                    />
                                }
                                showsVerticalScrollIndicator={false}
                                data={winners}
                                initialNumToRender={10}
                                renderItem={({ item, index }) => (
                                    <LuckyStrikeWinnerItem
                                        key={index}
                                        item={item}
                                    />
                                )}
                                contentContainerStyle={
                                    !winners.length
                                        ? {
                                              ...stylesAlignCenter(),
                                          }
                                        : null
                                }
                                ListEmptyComponent={
                                    <EmptyList
                                        emptyTitle={i18n.t(
                                            'general.errors.emptyErrorScreen.title'
                                        )}
                                        emptyText={i18n.t(
                                            'general.errors.emptyErrorScreen.text'
                                        )}
                                        withAnimation="cry"
                                        tailTitle
                                    />
                                }
                            />
                            {loadMoreWinners && (
                                <View style={stylesLoadMore()}>
                                    <LoadingComponent />
                                </View>
                            )}
                        </>
                    )}
                </View>
            )}
        </View>
    );
};

export default LuckyStrikeAllWinnersScreen;
