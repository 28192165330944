import * as React from 'react';
import { FlatList } from 'react-native';

import { CreatePetImageFromUrlResponse } from '@Data/Models';
import { CREATE_NFT_IMAGE_FROM_GENERATED_IMAGE } from '@Data/Requests';
import { useMutation } from '@apollo/client';
import Button from '@components/Button';
import ButtonsBottom from '@components/ButtonsBottom';
import CustomModal from '@components/CustomModal';
import { STEP_INDICATOR_FLOWS } from '@components/FlowStepIndicator';
import { MINT_PET_STAGES_NAME } from '@components/FlowStepIndicator/flows/mintNFTStages';
import ImageIguana from '@components/ImageIguana';
import MintGeneratedImage from '@components/MintGeneratedImage';
import ModalLoader from '@components/ModalLoader';
import { View } from '@components/Themed';
import { MintContext } from '@contexts/MintContext';
import { toastErrorUnknown } from '@helpers/toastNotification';
import useThemedStyles from '@hooks/useThemedStyles';
import i18n from '@i18n/i18n';
import ROUTES from '@navigation/routes';
import { useNavigation } from '@navigation/useNavigation';

import MintContainer from '../components/MintContainer';
import MintHeaderText from '../components/MintHeaderText';
import stylesMain from './styles';

const MintPetSelectGeneratedNFT = ({ route }: any) => {
    const styles = useThemedStyles(stylesMain);
    const navigation = useNavigation();
    const {
        setAIImage,
        AIImages: nfts,
        AIImage,
        changeRankCheckOut,
        setIsRetry,
    } = React.useContext(MintContext);
    const [selectedId, setSelectedId] = React.useState<string>();
    const handleOnSelectedImage = (id: string) => {
        setSelectedId(id);
    };
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isModalVisible, setModalVisible] = React.useState<boolean>(false);
    const [request] = useMutation<CreatePetImageFromUrlResponse>(
        CREATE_NFT_IMAGE_FROM_GENERATED_IMAGE
    );
    const handleCloseModal = () => {
        setModalVisible(false);
    };

    React.useEffect(() => {
        setSelectedId(undefined);
    }, [AIImage]);

    const handleGenerateNewNft = React.useCallback(() => {
        setIsRetry(true);
        setModalVisible(false);
        if (changeRankCheckOut) {
            navigation.replace(ROUTES.MINT_GENERATION_INFO);
            return;
        }
        navigation.pop();
    }, [changeRankCheckOut]);

    const handleNextStep = async () => {
        if (nfts) {
            setIsLoading(true);
            const createNftImageFromGeneratedImageId = nfts.find(
                (val) => val.id === selectedId
            )?.id;
            try {
                const result = await request({
                    variables: {
                        createNftImageFromGeneratedImageId,
                    },
                });
                setIsLoading(false);
                const image = result.data?.createNftImageFromGeneratedImage;
                if (image) {
                    setAIImage(image);
                    navigation.navigate(ROUTES.MINT_PET_NAME);
                }
            } catch (error) {
                setIsLoading(false);
                Console.log(error);
                toastErrorUnknown();
            }
        }
    };

    const handleRetry = () => {
        setModalVisible(true);
    };

    return (
        <>
            <MintContainer
                icon={'leftArrow'}
                flow={STEP_INDICATOR_FLOWS.MINT_PET}
                stage={MINT_PET_STAGES_NAME.STEP_TWO}
                stageFill={'75%'}>
                <View style={styles.flatListWrapper}>
                    <FlatList
                        ListHeaderComponent={
                            <MintHeaderText
                                containerStyle={styles.headerText}
                                title={i18n.t(
                                    'nftSelectGeneration.selectGeneratedNFT'
                                )}
                                text={i18n.t(
                                    'nftSelectGeneration.selectGeneratedNFTInfo'
                                )}
                            />
                        }
                        numColumns={2}
                        columnWrapperStyle={styles.columnWrapperStyle}
                        showsVerticalScrollIndicator={false}
                        data={nfts}
                        renderItem={({ item, index }) => (
                            <MintGeneratedImage
                                image={item.localUrl}
                                id={item.id}
                                selectedImage={selectedId}
                                onSelectImage={handleOnSelectedImage}
                            />
                        )}
                    />
                </View>
                <ButtonsBottom
                    disabled={!selectedId}
                    onPress={handleNextStep}
                    title={i18n.t('nftSelectGeneration.continueNextStep')}>
                    <Button
                        containerStyle={styles.gap}
                        type="outline"
                        title={i18n.t('nftSelectGeneration.generateNewNFT')}
                        onPress={handleRetry}
                    />
                </ButtonsBottom>
            </MintContainer>
            <CustomModal
                isVisible={isModalVisible}
                icon={<ImageIguana type="attention" />}
                titleText={i18n.t('modal.generateNewNFT.title')}
                infoText={i18n.t('modal.generateNewNFT.text')}
                firstButtonText={i18n.t('modal.generateNewNFT.yesGenerate')}
                secondButtonText={i18n.t('modal.generateNewNFT.noKeep')}
                onFirstButtonClick={handleGenerateNewNft}
                onSecondButtonClick={handleCloseModal}
            />
            <ModalLoader
                isVisible={isLoading}
                text={i18n.t('mintEditPhoto.loading')}
            />
        </>
    );
};
export default MintPetSelectGeneratedNFT;
